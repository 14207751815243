import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';

const Option = Select.Option;

class SiteAutocomplete extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.list.isRequired,
    selectSites: PropTypes.func.isRequired,
    hasAllSites: PropTypes.bool,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
  }

  static defaultProps = {
    hasAllSites: true,
    defaultValue: '',
    value: 'All sites',
  }

  render() {
    return (
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(i,o) => o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0}
        className='site-select'
        defaultValue={ this.props.hasAllSites ? 'All Sites' : this.props.defaultValue }
        dropdownMatchSelectWidth={ false }
        onChange={this.props.selectSites}
        value={this.props.value}
      >
        { this.props.hasAllSites ? (
          <Option key={ 'all sites' } value={ 'all sites' }>All properties</Option>
        ) : (
          null
        )}

        { this.props.sites.map((site) => {
          if (typeof site === 'string') return <Option key={ site } value={ site }>{ site }</Option>;
          return <Option key={ site.id } value={ site.id.toString() }>{ site.domain }</Option>;
        })}

      </Select>
    );
  }
}

export default SiteAutocomplete;
