import * as types from '../actions/action_types';

import { Map, OrderedSet } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
    scenarios: new StoreRecord({ value: OrderedSet([]) }),
    importPending: false,
});

export default (state = initialState, action) => {
    const scenarios = state.getIn(['scenarios', 'value']);
    let updatedScenarios;

    switch(action.type) {

        case types.SCENARIOS_PENDING:
            return setPending(state, ['scenarios']);

        case types.SCENARIOS_CACHED:
            return stopPending(state, ['scenarios']);

        case types.SCENARIOS_LOADED:
            return setValue(state, ['scenarios'], scenarios.concat(action.scenarios).sortBy(s => s.description.toLowerCase()));

        case types.SCENARIOS_ERROR:
            return setError(state, ['scenarios'], action.errorMessage);

        case types.CREATE_SCENARIO_LOADED:
            if (OrderedSet.isOrderedSet(action.scenario)) {
              updatedScenarios = scenarios.merge(action.scenario);
            } else {
              updatedScenarios = scenarios.add(action.scenario);
            }
            return setValue(state, ['scenarios'], updatedScenarios.sortBy(scenario => scenario.get('description').toLowerCase())).set('importPending', false);

        case types.UPDATE_SCENARIO_LOADED:
            if (OrderedSet.isOrderedSet(action.scenario)) {
              updatedScenarios = scenarios.filterNot(scenario => action.scenario.map(s => s.id).includes(scenario.id)).merge(action.scenario);
            } else {
              updatedScenarios = scenarios.filterNot(s => s.id === action.scenario.id).add(action.scenario);
            }
            return setValue(state, ['scenarios'], updatedScenarios.sortBy(scenario => scenario.get('description').toLowerCase()));

        case types.SCENARIO_DELETED:
            const withoutDeleted = scenarios.filterNot(s => action.ids.includes(s.get('id')));
            return setValue(state, ['scenarios'], withoutDeleted);

        case types.SCENARIO_IMPORT_PENDING:
            return state.set('importPending', true);

        case types.SCENARIO_IMPORTED:
            const withImported = scenarios.add(action.scenario).sortBy(scenario => scenario.get('description').toLowerCase());
            return setValue(state, ['scenarios'], withImported).set('importPending', false);

        case types.ARCHIVE_SCENARIOS:
            const updated = scenarios.map(s => {
                if (action.scenarioIds.has(s.id)) {
                    return s.set('live', false);
                } else {
                    return s;
                }
            })
            return setValue(state, ['scenarios'], updated);
    }

    return state;
};
