import PropTypes from 'prop-types';
import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { ConfigProvider, Table } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { connect } from 'react-redux';
import CSVLink from "../common/CSVLink.js";
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';

import { GRAPH_COLORS } from '../../constants.js';
import { getWhitelistGraphData } from '../../actions/whitelist_actions.js';
import { WhitelistRecord, GraphFilters } from '../../records/whitelist_records.js';
import { getAllSites } from '../../actions/site_actions.js';
import Loading from '../common/Loading.js.jsx';
import SiteDropdown from '../common/SiteDropdown.jsx';
import CustomRangePicker from './CustomRangePicker';
import { RangeDates } from '../../records/reports_records.js';
import { getLastWeekDates } from '../utils';
import { User } from '../../records/account_records';

let graphData, tableData, newGraphFilters;

export class Whitelist extends React.Component {
  static propTypes = {
    whitelistData: PropTypes.instanceOf(WhitelistRecord).isRequired,
    getWhitelistGraphData: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    sites: ImmutablePropTypes.list.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    const lastWeekDates = getLastWeekDates();
    const newGraphFiltersRecord = new GraphFilters({
      start_date: lastWeekDates.start,
      end_date: lastWeekDates.end,
    });

    this.updateState = this.updateState.bind(this);
    this.selectSites = this.selectSites.bind(this);
    this.selectDates = this.selectDates.bind(this);

    this.state = {
      graphFilters: newGraphFiltersRecord,
    };
  }

  componentWillMount() {
    if (this.state.graphFilters.domain) {
      this.props.getWhitelistGraphData(this.state.graphFilters);
    }
  }

  componentDidMount() {
    this.props.getAllSites();
  }

  updateState(newGraphFilters) {
    this.setState({ graphFilters: newGraphFilters }, function () {
      this.props.getWhitelistGraphData(this.state.graphFilters);
    });
  }

  selectDates(dates) {
    newGraphFilters = this.state.graphFilters.set('start_date', dates.start).set('end_date', dates.end);
    this.updateState(newGraphFilters);
  }

  selectSites(event) {
    let site = this.props.sites
      .filter((site) => site.id === Number(event))
      .first()
      .get('domain');

    if (site.includes('*')) {
      site = site.replace('*', '%');
    }

    newGraphFilters = this.state.graphFilters.set('domain', site);
    this.updateState(newGraphFilters);
  }

  render() {
    let table,
      graph,
      noData,
      exportCSV;

    if (this.props.whitelistData && this.props.whitelistData.get('tableData').size) {
      tableData = this.props.whitelistData.tableData.toJS();
      graphData = tableData.map((data) => _.omit(data, 'key'));

      graph = (
        <ResponsiveContainer width='100%' height={395}>
          <BarChart data={graphData}>
            <Bar
              dataKey='whitelist'
              fill={ GRAPH_COLORS.lightBlue }
            />
            <XAxis dataKey="dates" />
            <CartesianGrid stroke="#ccc" />
            <YAxis />
            <Tooltip
              cursor={{ fill: GRAPH_COLORS.teal, opacity: .5 }}
            />
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      );

      const date = new Date();
      exportCSV = (
        <div className='export-csv-container'>
          <CSVLink
            data={graphData}
            className='export-csv'
            filename={date + ' data.csv'}
            target=""
          >
            Export Data
          </CSVLink>
        </div>
      );

      const dayTitle = this.state.graphFilters.granularity.charAt(0).toUpperCase() + this.state.graphFilters.granularity.slice(1);
      const columns = [{
        title: dayTitle,
        dataIndex: 'dates',
        key: 'dates',
        width: '20%',
        sorter: (a, b) => new Date(a.dates).getTime() - new Date(b.dates).getTime(),
      }, {
        title: 'Whitelisted PVs',
        dataIndex: 'whitelist',
        key: 'whitelist',
        width: '20%',
        sorter: (a, b) => parseFloat(a['whitelist']) - parseFloat(b['whitelist']),
      }];
      table = (
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={{ pageSize: 30 }}
        />
      );
    } else {
      noData = <h4 className='no-data'>No data to display</h4>;
    }

    let loading;
    if (this.props.pendingRequestsMap.some(request => request)) {
      loading = <Loading />;
    }

    return (
      <div className='metrics-graph'>
        <ConfigProvider locale={ enUS }>
          <SiteDropdown
            sites={ this.props.currentUser.siteAccess ? this.props.sites.filter(s => this.props.currentUser.siteAccess.includes(s.id)) : this.props.sites }
            selectSites={ this.selectSites }
            hasAllSites={ false }
            defaultValue='Select a property'
          />
        </ConfigProvider>
        <div className='external filters'>
          <CustomRangePicker
            selectDates={ this.selectDates }
            limit={ 60 }
            disabled={ this.props.pendingRequestsMap.some(request => request) }
            defaultValue={ new RangeDates({ start: this.state.graphFilters.start_date, end: this.state.graphFilters.end_date}) }
          />
        </div>
        <div className='adblock card'>
          { loading }
          { graph }
          { noData }
        </div>
        <div className='data-table card'>
          { table }
          { exportCSV }
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    whitelistData: store.whitelistState.getIn(['whitelistData', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    pendingRequestsMap: Map({
      pending: store.whitelistState.getIn(['whitelistData', 'pending']),
      sites: store.siteState.getIn(['sites', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getWhitelistGraphData,
    getAllSites,
  },
)(Whitelist);
