import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Tooltip, Button, Toggle } from '../../../../styleguide';
import { Link } from 'react-router';
import CustomButton from '../../../common/CustomButton.js.jsx';
import { CONSENT_SCOPES } from '../../../../constants';
import { VendorList } from '../../../../records/vendor_list_records';

const Option = Select.Option;

export default class Header extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleSelectScope: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    toggleIsActive: PropTypes.func.isRequired,
    toggleShareRootDomain: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    vl: PropTypes.instanceOf(VendorList).isRequired,
    v2: PropTypes.bool.isRequired,
  }
  render() {

    const tooltipText = (
      <React.Fragment>
        <p className="tooltip-title">Single site</p>
        <p>Consent will only be set for the site a user has consented on.</p>
        <p className="tooltip-title">Shared site</p>
        <p>Consent will be shared across all sites within your account.</p>
        <p className="tooltip-title">Global IAB</p>
        <p>Utilizes consent through the IAB framework, setting all consent globally through their network of sites</p>
      </React.Fragment>
    );

    const shareRootDomain = (
      <div className="share-root-domain-switch">
        <span>Write 1st Party Cookies To Root Domain</span>
        <Toggle
          onChange={ this.props.toggleShareRootDomain }
          checked={ this.props.vl.shareRootDomain }
          disabled={ this.props.readOnly }
        />
      </div>
    );

    let saveButton;
    if (!this.props.readOnly) {
      saveButton = (
        <CustomButton
          type={ CustomButton.types.SUCCESS }
          onClick={ this.props.handleSave }
          disabled={ this.props.readOnly }
        >
          Save
        </CustomButton>
      );
    }
    const rightTitle = (
      <React.Fragment>
        <div className="switchContainer">
          <Tooltip title={ tooltipText } >
            <div className="title">
              <i className="fas fa-info-circle" />Consent scope:
            </div>
          </Tooltip>
          <Select
            disabled={ this.props.readOnly }
            value={ CONSENT_SCOPES.get(this.props.vl.consentScope) }
            onChange={ this.props.handleSelectScope }
            size="large"
          >
            { CONSENT_SCOPES.toList().map(s => <Option key={ s } >{ s }</Option>)}
          </Select>
        </div>
        { saveButton }
      </React.Fragment>
    );

    const activeSwitch = (
      <div className="active-switch">
        <div>
          { this.props.vl.isActive ? 'Active' : 'Inactive' }
        </div>
        <Toggle
          onChange={ this.props.toggleIsActive }
          checked={ this.props.vl.isActive }
          disabled={ this.props.readOnly }
        />
      </div>
    );

    const v2 = this.props.v2 ? '_v2' : '';

    return (
      <div className="vendor-header">
        { activeSwitch }

        <div className="vendor-header-nav">
          <Link to={`/consent${v2}/vendor_lists`}>&lt;&nbsp;&nbsp;Back to Vendor Lists</Link>
        </div>

        <div className="vendor-top">
          <div className="vendor-top-left">
            <div className="title-editor">
              <i className="fas fa-pen" />
              <Input
                value={ this.props.vl.name }
                onChange={ (e) => this.props.handleChange(e, 'name') }
                placeholder="Vendor List Name..."
                className="vendor-top-title"
                disabled={ this.props.readOnly }
              />
            </div>
          </div>
          <div className="vendor-top-right">
            { rightTitle }
          </div>
        </div>
      </div>
    );
  }
}
