import React, { Component } from 'react';
import { ExecJS } from '../../../../../../../records/choice_config_records';
import { Select } from '../../../../../../../styleguide';
import CustomJS from './ChoiceOptions/Types/CustomJS';

const { Option } = Select;

class ActionOptions extends Component {
  updateSetting(value) {
    if (value === 9) {
      value = {
        type: 9,
        data: { ...new ExecJS({}).toJS(), button_text: JSON.stringify(Date.now()) }
      };

      delete value.data.enabled
    }
    this.props.updateSetting(this.props.settingKey, value)
  }
  render() {
    const { value, settingsTitle, isApp } = this.props;
    const newValue = value !== null && typeof value === 'object' ? value.type : value

    return (
      <div className="four-options">
        <h4>{settingsTitle}</h4>
        <div className="option-inputs">
          <Select
            value={newValue}
            style={{ width: '100%' }}
            onChange={(value) => this.updateSetting(value)}
            options={[
              {
                label: "Choice Options",
                options: [
                  { label: (<i>None</i>), value: null },
                  { label: 'Accept Legitimate Interest Only', value: 'ACCEPT_LI_ONLY' },
                  { label: 'Save & exit', value: 'SAVE_AND_EXIT' },
                  { label: 'Accept All', value: 'ACCEPT_ALL' },
                  { label: 'Reject All', value: 'REJECT_ALL' },
                  { label: 'Cancel', value: 'CANCEL' },
                  { label: isApp ? 'Custom Action' : 'Custom JS', value: 9 },
                ]
              },
              {
                label: "Local Actions",
                options: [
                  { label: 'Go to Custom View', value: 99 },
                  { label: 'Go to Default PM View', value: 100 },
                ]
              }
            ]}
          />
        </div>
        <div>
          {newValue === 9 && (
            <CustomJS
              changeValue={(_, data) => this.props.updateSetting(this.props.settingKey, { type: 9, data })}
              value={value}
              isApp={isApp}
            />
          )}
        </div>
      </div>
    );
  }
};

export default ActionOptions;