import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Collapse } from 'antd';
import { Popconfirm } from '../../../../styleguide'

import { SortableStepElement } from './SortableStepElement';
import EditableStepName from './EditableStepName';
import CustomIcon from '../../../common/CustomIconScenario';
import { styles } from './styles';

export const CollapseSortableContainer = SortableContainer(
  ({
    activeKeys,
    scenarioEdited,
    copyStep,
    preventPanelToggle,
    deleteStep,
    updateActiveStep,
    setActiveKey,
    showConditionPanel,
    onChange,
    showConditionDropdown,
    showConditionDropdownState,
    activeStep,
    renderConditionDropDownAndPanel,
    messages,
    onMessageListChange,
    stepIndex,
    showErrorMessage,
    updateStepIndex,
    isTemplate,
  }) => (
    <Collapse activeKey={activeKeys.toJS()}>
      {scenarioEdited &&
        scenarioEdited.steps.size > 0 &&
        scenarioEdited.steps.map((item, index, steps) => {
          const isOnlyStep = steps.size === 1;
          const header = (
            <div
              style={{
                display: 'flex',
                flex: '1',
                flexDirection: 'row',
              }}
            >
              <EditableStepName index={index} key={index} step={item} />
              <div style={styles.stepHeader}>
                <CustomIcon
                  type={CustomIcon.types.COPY}
                  onClick={(e) => copyStep(index, e)}
                  tooltip="copy"
                />
                &nbsp;
                {!isOnlyStep && (
                  <Popconfirm
                    overlayClassName="delete-step-popup"
                    title={`Step ${index + 1} will be deleted`}
                    okText="YES"
                    cancelText="NO"
                    onCancel={(e) => preventPanelToggle(e)}
                    onConfirm={(e) => deleteStep(index, e)}
                    trigger="click"
                    placement="bottomRight"
                  >
                    <CustomIcon
                      type={CustomIcon.types.DELETE}
                      tooltip="delete"
                      onClick={(e) => preventPanelToggle(e)}
                    />
                  </Popconfirm>
                )}
              </div>
            </div>
          );

          return (
            <SortableStepElement
              key={`step-${index}`}
              index={index}
              ind={index}
              item={item}
              updateActiveStep={updateActiveStep}
              setActiveKey={setActiveKey}
              header={header}
              showConditionPanel={showConditionPanel}
              onChange={onChange}
              showConditionDropdown={showConditionDropdown}
              showConditionDropdownState={showConditionDropdownState}
              activeKeys={activeKeys}
              renderConditionDropDownAndPanel={renderConditionDropDownAndPanel}
              activeStep={activeStep}
              scenarioEdited={scenarioEdited}
              messages={messages}
              onMessageListChange={onMessageListChange}
              stepIndex={stepIndex}
              showErrorMessage={showErrorMessage}
              updateStepIndex={updateStepIndex}
              isTemplate={isTemplate}
            />
          );
        })}
    </Collapse>
  )
);
