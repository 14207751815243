import React from 'react'
import { Input, Select } from 'antd'

const rowItem = (props) => {
    const {data, handleChange, index, errors} = props
    return (
        <React.Fragment>
            <div style={{ display: "flex", flexWrap: "nowrap" }} >
                <Input style={{ marginRight: "5px" }} placeholder="Name" value={data.name} onChange={(e) => handleChange(index, "name", e.target.value)} />
                <Input style={{ marginRight: "5px" }} placeholder="Privay Url" value={data.policyUrl} onChange={(e) => handleChange(index, "policyUrl", e.target.value)} />
            </div>
            { errors[index].length > 0 && <div style={{ color: "red" }}>{errors[index]}</div> }
        </React.Fragment>

    )
}

export default rowItem