import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { browserHistory } from 'react-router';
import {
  getVendorLists,
  deleteVendorList,
  clearVendorListError,
  cloneVendorList,
} from '../../../actions/vendor_list_actions';
import Loading from '../../common/Loading.js.jsx';
import VendorListRow from './VendorListRow';
import { VendorList as VendorListRecord } from '../../../records/vendor_list_records';
import { User } from '../../../records/account_records';
import CustomButton from '../../common/CustomButton.js.jsx';
import CreateButton from '../../common/CreateButton.js.jsx';

class VendorLists extends React.Component {
  static propTypes = {
    vendorLists: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(VendorListRecord)).isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      v2: PropTypes.bool,
    }).isRequired,
    getVendorLists: PropTypes.func.isRequired,
    deleteVendorList: PropTypes.func.isRequired,
    clearVendorListError: PropTypes.func.isRequired,
    cloneVendorList: PropTypes.func.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      if (!this.props.currentUser.siteAccess) {
        this.props.getVendorLists(this.props.currentUser.accountId);
      } else {
        this.props.getVendorLists(this.props.currentUser.accountId, this.props.currentUser.siteAccess);
      }
    }
  }

  handleCreate = () => {
    const v2 = this.props.route.v2 ? '_v2' : '';
    browserHistory.push(`/consent${v2}/vendor_lists/create`);
  };

  render() {
    if (this.props.pendingRequestsMap.some(request => request)) {
      return <Loading />;
    }

    let readOnly = false;
    if (this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('Vendor List - GDPR')) {
      readOnly = true;
    }

    let content;
    if (this.props.vendorLists.size) {
      content = (
        <ul>
          {
            this.props.vendorLists.map(l => (
              <li key={ l.id }>
                <VendorListRow
                  vendorList={ l }
                  handleDelete={ this.props.deleteVendorList }
                  cloneVendorList={ this.props.cloneVendorList }
                  accountId={ this.props.currentUser.accountId }
                  clearVendorListError={ this.props.clearVendorListError }
                  pending={ this.props.savePending }
                  readOnly={ readOnly }
                  v2={ this.props.route.v2 }
                />
              </li>
            ))
          }
        </ul>
      );
    } else {
      if (!readOnly) {
        content = (
          <div className='no-lists-message-container'>
            <p className='title'>
              You haven’t created a Vendor List yet.
            </p>
            <p className='descr'>
              We use vendor lists to make updating easy. Any changes you make, update automatically across all Privacy Managers using that list.
            </p>
            <CustomButton className='create-button' onClick={ this.handleCreate }>Get Started</CustomButton>
          </div>
        );
      } else {
        content = (
          <div className='no-lists-message-container'>
            <p className='title'>
              There are no Vendor Lists available.
            </p>
          </div>
        );
      }
    }

    let createButton;
    if (!readOnly) {
      createButton = <CreateButton onClick={ this.handleCreate }>NEW</CreateButton>;
    }
    return (
      <div className='vendor-lists-container'>
        { createButton }
        <p className='main-title'>Vendor Lists</p>
        { content }
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    vendorLists: store.vendorListState.getIn(['vendorLists', 'value']),
    pendingRequestsMap: new Map({
      vendorLists: store.vendorListState.getIn(['vendorLists', 'pending']),
    }),
    savePending: store.vendorListState.get('savePending'),
  };
};

export default connect(
  mapStateToProps, {
    getVendorLists,
    deleteVendorList,
    cloneVendorList,
    clearVendorListError,
  },
)(VendorLists);
