import React, { Component } from 'react';
import { Radio } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faAngleDoubleDown, faAlignCenter } from '@fortawesome/free-solid-svg-icons';


class FlexAlign extends Component {
  updateAlign = (e) => {
    this.props.updateSetting(this.props.settingKey, e.target.value);
  }
  render() {
    return (
      <div className="set-flexalign">
        <h4>{this.props.settingsTitle}</h4>
        <div>
          <Radio.Group value={this.props.value} onChange={this.updateAlign}>
            <Radio.Button value="flex-start">
              <FontAwesomeIcon icon={faAngleDoubleUp} />
            </Radio.Button>
            <Radio.Button value="center">
              <FontAwesomeIcon icon={faAlignCenter} />
            </Radio.Button>
            <Radio.Button value="flex-end">
              <FontAwesomeIcon icon={faAngleDoubleDown} />
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
    );
  }
}

export default FlexAlign;
