import React from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';

import { Select, Button } from 'antd';

const Option = Select.Option;

export default function PerformanceHeader(props) {

  const goldenSets = useSelector(state => state.goldenSetState.getIn(['goldenSets', 'value']));

  const reroute = (goldenSetId, rulePerformance) => {
    let tab;
    if (rulePerformance) {
      if (rulePerformance === 'rulePerformance') {
        tab = 'rule_performance';
      } else {
        tab = 'golden_set_comparison'
      }
    } else {
      if (props.rulePerformance) {
        tab = 'rule_performance';
      } else {
        tab = 'golden_set_comparison'
      }
    }

    const url = `/privacy_lens/golden_score/${tab}?id=${goldenSetId}`;
    browserHistory.push(url);
  };

  const choseTab = (tabName) => {
    if (tabName === 'rulePerformance') {
      if (!props.rulePerformance) {
        reroute(props.goldenSetId, 'rulePerformance');
      }
    } else {
      if (props.rulePerformance) {
        reroute(props.goldenSetId, 'comparison');
      }
    }
  };

  let content;
  if (props.goldenSetId) {
    content = (
      <div>
        <Button
          onClick={() => choseTab('rulePerformance') }
          className={ props.rulePerformance ? 'selected' : null }
        >
          Rule Performance
        </Button>
        <Button
          onClick={() => choseTab('Comparison') }
          className={ props.rulePerformance ? null : 'selected' }
        >
          Golden Set Comparison
        </Button>
      </div>
    );
  }


  let value = props.goldenSetId;
  if (!value && goldenSets.size) {
    value = goldenSets.sort((a, b) => {
      if (new Date(b.dateUpdated) < new Date(a.dateUpdated)) {
        return -1;
      } else {
        return 0;
      }
    }).first().id;
    reroute(value, 'rulePerformance');
  }

  return (
    <div className='privacy-lens golden-set-performance'>
      <div className='select-title'>Select Golden Set for view the performance</div>
      <Select
        onChange={ (id) => reroute(id) }
        value={ value }
      >
        {
          goldenSets.map(gs => (
            <Option value={ gs.id } key={ gs.id }>{ gs.name }</Option>
          ))
        }
      </Select>

      { content }

    </div>
  );
}