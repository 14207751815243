import * as types from './action_types';
import { actionCreator } from './helpers';
import {
    activateDeactivateRegulationAjax,
    getAccountsListAjax,
    cloneRegulationListAjax,
    deleteRegulationAjax,
    getSystemPurposesAjax,
    getSystemStacksAjax,
    createUSPrivacyRegulationAjax,
    updateUSPrivacyRegulationAjax,
    getUSPrivacyRegulationAjax,
    getGlobalVendorsAjax
  } from '../api/usnat/vendor_list';
import { getAllSitesAjax } from '../api/dialogue/sites';
import { createUspRegulationLog, editUspRegulationLog, deleteUspRegulationLog } from '../api/change_log';

export const getAccountList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(vendorListPending());
      let regulationsList = await getAccountsListAjax();
      if (regulationsList === "fetched") {
        dispatch(regulationCached('vendorList'));
        regulationsList = getState().usPrivacyReducerState.getIn(['vendorList', 'value']);
      } 
      let sitesNameMap = getState().siteState.getIn(['sitesNameMap', 'value']);
      let updatedRegulationsList = regulationsList.map((regulation) => {
        const siteIdsFiltered = regulation.siteIds.filter(id => Boolean(sitesNameMap.get(String(id))));
        return regulation.set('siteIds', siteIdsFiltered);
      });
      dispatch(vendorListLoaded(updatedRegulationsList));
    } catch (err) {
      dispatch(vendorListError(err))
    }
  }
}

export const createRegulation = actionCreator(
  createUSPrivacyRegulationAjax,
  regulationSavePending,
  regulationCreated,
  regulationError,
  null,
  createUspRegulationLog,
);

export const updateRegulation = actionCreator(
  updateUSPrivacyRegulationAjax,
  regulationSavePending,
  regulationUpdated,
  regulationError,
  null,
  editUspRegulationLog,
);

export const cloneRegulationList = actionCreator(
  cloneRegulationListAjax,
  vendorListPending,
  regulationCreated,
  regulationError,
  null,
  createUspRegulationLog
);

  export const deleteRegulationList = actionCreator(
    deleteRegulationAjax,
    vendorListPending,
    regulationDeleted,
    vendorListError,
    null,
    deleteUspRegulationLog
  );

export const activateDeactivateRegulationList = actionCreator(
  activateDeactivateRegulationAjax,
  vendorListPending,
  regulationUpdated,
  vendorListError,
  null,
  editUspRegulationLog,
);

export const getSystemPurposes = actionCreator(
  getSystemPurposesAjax,
  purposesPending,
  purposesLoaded,
  purposesError,
  regulationCached.bind(null, 'purposes'),
);

export const getSystemStacks = actionCreator(
  getSystemStacksAjax,
  stacksPending,
  stacksLoaded,
  stacksError,
  regulationCached.bind(null, 'stacks'),
);

export function getUSPrivacyRegulation(id, accountId) {
  return (dispatch, getState) => {
    dispatch(vendorListPending());

    return getUSPrivacyRegulationAjax(id, accountId).then((vl) => {
      let vendorList = vl;
      return getAllSitesAjax().then((s) => {
        let sites = s;
        if (sites === 'fetched') {
          sites = getState().siteState.getIn(['sites', 'value']).filter(s => vendorList.siteIds.includes(s.id));
        }

        const sitesWithoutDeleted = vendorList.siteIds.filter(id => sites.map(s => s.id).includes(id));
        dispatch(regulationLoaded(vendorList.set('siteIds', sitesWithoutDeleted)));
        return vendorList.set('siteIds', sitesWithoutDeleted)
      });

    }).catch((resp) => dispatch(regulationError(resp)));
  };
}

export const getGlobalVendors = actionCreator(
  getGlobalVendorsAjax,
  globalVendorsPending,
  globalVendorsLoaded,
  regulationError,
  regulationCached.bind(null, 'mspsSystemVendors')
)

function regulationLoaded(usPrivacyRegulation) {
  return {
    type: types.REGULATION_LOADED,
    usPrivacyRegulation
  }
}

function vendorListPending() {
  return {
    type: types.US_PRIVACY_ACCOUNT_LIST_PENDING,
  };
}

function vendorListLoaded(accounts) {
  return {
    type: types.US_PRIVACY_ACCOUNT_LIST_LOADED,
    accounts
  };
}

function vendorListError(error) {
  return {
    type: types.US_PRIVACY_ACCOUNT_LIST_ERROR,
    error
  };
}

function regulationCached(property) {
  return {
      type: types.US_PRIVACY_ACCOUNT_LIST_CACHED,
      property,
  }
}

function regulationPending() {
  return {
    type: types.REGULATION_PENDING,
  };
}

function regulationCreated(regulation) {
  return {
    type: types.REGULATION_CREATED,
    regulation,
  };
}

function regulationError(error) {
  return {
    type: types.REGULATION_ERROR,
    error,
  };
}

export function clearRegulationError() {
  return {
    type: types.CLEAR_REGULATIN_ERROR
  }
}
function purposesPending() {
  return {
    type: types.SYSTEM_PURPOSES_PENDING,
  };
}

function purposesLoaded(purposes) {
  return {
    type: types.SYSTEM_PURPOSES_LOADED,
    purposes,
  };
}

function regulationDeleted(regulation) {
  return {
    type: types.REGULATION_DELETED,
    id: regulation.id,
  };
}

function regulationUpdated(regulation) {
  return {
    type: types.REGULATION_UPDATED,
    regulation
  };
}
function purposesError(errorMessage) {
  return {
    type: types.SYSTEM_PURPOSES_ERROR,
    errorMessage,
  };
}

function stacksPending() {
  return {
    type: types.SYSTEM_STACKS_PENDING,
  };
}

function stacksLoaded(stacks) {
  return {
    type: types.SYSTEM_STACKS_LOADED,
    stacks,
  };
}

function stacksError(errorMessage) {
  return {
    type: types.SYSTEM_STACKS_ERROR,
    errorMessage,
  };
}

function regulationSavePending() {
  return {
    type: types.REGULATION_SAVE_PENDING,
  };
}

function globalVendorsPending() {
  return {
    type: types.MSPS_SYSTEM_VENDORS_PENDING,
  };
}

function globalVendorsLoaded(vendors) {
  return {
    type: types.MSPS_SYSTEM_VENDORS_LOADED,
    vendors,
  };
}
