import React from 'react';
import PropTypes from 'prop-types';
import { FileTextOutlined, GlobalOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Vendor, VendorCookie as Cookie } from '../../../records/vendor_list_records';

const Panel = Collapse.Panel;

export default class VendorCookiesTab extends React.Component {
  static propTypes = {
    vendor: PropTypes.instanceOf(Vendor).isRequired,
    cookies: ImmutablePropTypes.listOf(PropTypes.instanceOf(Cookie)).isRequired,
  }

  render() {

    return (
      <div className='cookie-detail-tab'>
        <div className='vendor-title'>
          <div className='vendor-name'>{ this.props.vendor.name }</div>
          <div className='icons'>
            <FileTextOutlined onClick={ () => window.open(this.props.vendor.policyUrl) } />
            <GlobalOutlined onClick={ () => window.open(this.props.vendor.siteUrl) } />
          </div>
        </div>
        <p className='vendor-id'>ID: { this.props.vendor.id }</p>

        <p className='vendor-description'>{ this.props.vendor.description}</p>

      </div>
    );
  }
}
