import React, { useMemo } from 'react';
import { Button, Tooltip } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import SVG from 'react-inlinesvg';

import allVendorsIcon from '../../../../assets/icons/all_vendors.svg';
import searchedVendorsIcon from '../../../../assets/icons/searched_vendors.svg';
import collapseVendorTraceIcon from '../../../../assets/icons/collapse_vendor_trace.svg';
import expandVendorTraceIcon from '../../../../assets/icons/expand_vendor_trace.svg';
import { CONDENCE_MODE, useVendorTraceContext } from './ChartFiltersContext';

const VendorTraceChartActions = ({ scaleValue }) => {
  const {
    vendorName,
    collapse,
    isTreeStateCondenced,
    isBeforeActionConsentCategory,
    isAllCollapsed,
    changeVendorNameSearchAppearance,
    setCollapse,
    setCollapseAll,
  } = useVendorTraceContext();
  const fillColor = '#6565A9';
  const isScaleLess = scaleValue >= 1;
  const scaleAction = isScaleLess
    ? {
        tooltipMessage: 'Zoom Out',
      }
    : {
        tooltipMessage: 'Zoom In',
      };
  const tooltipMessage = useMemo(() => {
    if (isTreeStateCondenced !== CONDENCE_MODE.ALL) {
      return 'Show all vendors';
    }
    if (isTreeStateCondenced === CONDENCE_MODE.ALL && vendorName) {
      return 'Show only searched vendors';
    }
    return 'Show only “after action” vendors';
  }, [isTreeStateCondenced]);
  return (
    <div className={'vendor-trace-zoom-container'}>
      {vendorName || !isBeforeActionConsentCategory ? (
        <Tooltip
          placement="top"
          overlayClassName="diagnose--tooltip"
          title={() => {
            return (
              <div className={'tooltip-content'}>
                <div>{tooltipMessage}</div>
              </div>
            );
          }}
        >
          {/* TODO: there is odd flickering effect on svg, so we need to keep both buttons in place */}
          <Button
            style={{ display: isTreeStateCondenced !== CONDENCE_MODE.ALL ? 'block' : 'none' }}
            className={!isBeforeActionConsentCategory && !vendorName && 'purple'}
            id={'show_all_vendors'}
            icon={<SVG src={allVendorsIcon} />}
            onClick={() => changeVendorNameSearchAppearance(CONDENCE_MODE.ALL)}
          />
          <Button
            style={{ display: isTreeStateCondenced !== CONDENCE_MODE.SEARCHED ? 'block' : 'none' }}
            className={!isBeforeActionConsentCategory && !vendorName && 'purple'}
            id={'show_only_searched_vendors'}
            icon={<SVG src={searchedVendorsIcon} />}
            onClick={() => changeVendorNameSearchAppearance(CONDENCE_MODE.SEARCHED)}
          />
        </Tooltip>
      ) : null}
      <Tooltip
        placement="top"
        overlayClassName="diagnose--tooltip"
        title={() => {
          return (
            <div className={'tooltip-content'}>
              <div>{scaleAction.tooltipMessage} </div>
            </div>
          );
        }}
      >
        <Button
          id={'zoom_in'}
          icon={<ZoomInOutlined fill={fillColor} />}
          style={{ display: scaleValue >= 1 ? 'none' : 'inline-block' }}
        />
        <Button
          id={'zoom_out'}
          icon={<ZoomOutOutlined fill={fillColor} />}
          style={{ display: scaleValue < 1 ? 'none' : 'inline-block' }}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        overlayClassName="diagnose--tooltip"
        title={() => {
          return (
            <div className={'tooltip-content'}>
              <div>{collapse?.length ? 'Expand All' : 'Collapse All'}</div>
            </div>
          );
        }}
      >
        <Button
          id={'expand_chart_button'}
          icon={<SVG src={expandVendorTraceIcon} />}
          onClick={() => setCollapse(null)}
          disabled={!!vendorName || collapse === null}
          style={{ display: !collapse?.length ? 'none' : 'inline-block' }}
        />
        <Button
          id={'collapse_chart_button'}
          icon={<SVG src={collapseVendorTraceIcon} />}
          onClick={() => setCollapseAll()}
          disabled={!!vendorName || isAllCollapsed}
          style={{ display: collapse?.length ? 'none' : 'inline-block' }}
        />
      </Tooltip>
    </div>
  );
};

export default VendorTraceChartActions;
