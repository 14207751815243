import PropTypes from 'prop-types';
import React from 'react';
import StepSatisfyOption from './StepSatisfyOption';
import { DesktopUserAgentGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js'
export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(DesktopUserAgentGate).isRequired,
        onChange: PropTypes.func.isRequired
    };

    handleChangeSatisfy = (continueOnSatisfy) => {
        const data = this.props.data.set('continue_on_satisfy', continueOnSatisfy);
        this.props.onChange(data);
    };

    render() {
        // steps created in the past have no data
        var continueBool = this.props.data && this.props.data.has("continue_on_satisfy") ? this.props.data.continue_on_satisfy : true;
        var continueOnSatisfy = continueBool ? "Yes" : "No";

        if (!this.props.editing) {
            return (
                <div>
                  Continue If Desktop UA: { continueOnSatisfy }
                </div>
            );
        } else {
            return (
                <div>
                  <label>Continue : If Desktop UA:
                    <StepSatisfyOption
                      value={ continueBool }
                      onChange={ this.handleChangeSatisfy }
                      focusOnMount={ true }
                    />
                  </label>
                </div>
            )
        }
    }
}
