import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { getParameterByName } from '../utils';

import { Button, Chip, Input } from '../../styleguide'
import { SearchOutlined } from '@ant-design/icons';

function PropertiesUnderGroupRedirection({ properties, siteGroup, pageKind }) {

  const [ isShowAll, updateShowAll ] = useState(false);
  const [ searchText, updateSearchInput ] = useState('');

  const defaultCount = 3;

  const propertiesUnderPg = properties && properties.length ? properties : [];
  let allProperties = propertiesUnderPg.filter((p, pi) => isShowAll ? p : pi < defaultCount);
  allProperties = allProperties.filter((p) => p.domain.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);

  const onChangeActionBtn = () => {
    updateShowAll(prevState => !prevState);
    updateSearchInput('');
  }

  const onChangeSearch = (e) => {
    updateSearchInput(e.target.value);
  }

  const redirectToSite = (id) => {
    let siteGroupId= '';
    if(siteGroup){
      siteGroupId = `&sitegroup_id=${siteGroup.id}`;
    }
    browserHistory.push(`/dialogue/${pageKind}?site_id=${id}${siteGroupId}`);
  }

  const viewAllLabel = isShowAll ? 'View Less' : 'View All';
 
  const searchInput = isShowAll && <Input placeholder="Search Properties" value={searchText} onChange={onChangeSearch} suffix={<SearchOutlined />}/>

  const showCount = propertiesUnderPg.length <= defaultCount ? null : propertiesUnderPg.length - defaultCount;

  return (<div className="campaigns-applied-properties">
                       <div className="properties-wrapper">
                           <div className="properties-block">
                             <div><label><b>Properties  ({propertiesUnderPg.length})</b>: </label></div>
                             <div className="search-wrapper">
                               {searchInput}
                               <div>
                                 {allProperties.map((property, i) => <span className="property-tag" key={i} onClick={() => redirectToSite(property.id)}>{property.domain}</span>)}
                                 { showCount && <Button onClick={onChangeActionBtn}>{`${isShowAll ? '...Less' : '+' + showCount}`}</Button> }
                               </div>
                             </div>
                           </div>
                       </div>
                    </div>);
}

const mapStateToProps = function (store) {
  const sites = store.siteState.getIn(['sites', 'value']);
  let properties = [];
  const siteGroup = store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('site_group_id', window.location));
  if(siteGroup && sites.size) {
    properties = siteGroup.siteIds.map(id => sites.find(s => s.id === id)).toJS();
  }
  return {
    siteGroup,
    properties
  };
};

export default connect(
  mapStateToProps
)(PropertiesUnderGroupRedirection);