import PropTypes from 'prop-types';
import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';

import { Table, Tooltip } from 'antd';
import { choicesRateValueShape } from '../../../../custom_prop_types.js'

export default class extends React.Component {
    static propTypes = {
        choicesRateValueArray: PropTypes.arrayOf(choicesRateValueShape).isRequired,
        valuesPresent: PropTypes.bool
    };

    static defaultProps = { valuesPresent: true };

    render() {
        var icon = <InfoCircleOutlined />

        var valueTooltip = "Number of engagements after choice was selected";
        var rateTooltip = "Percentage representing how often this choice was selected when displayed to user";

        var choiceNameClass = "choice-name"
        if (!this.props.valuesPresent) {
            choiceNameClass += " beta"
        }

        var columns = [{
          title: 'Choice',
          dataIndex: 'choice',
          key: 'choice',
          className: choiceNameClass,
        }, {
          title: 'Clicks',
          dataIndex: 'clicks',
          key: 'clicks',
          className: 'clicks'
        }, {
          title:<Tooltip placement="top" title={ rateTooltip }>CTR { icon }</Tooltip>,
          dataIndex: 'rate',
          key: 'rate',
          className: 'rate'
        }];

        if (this.props.valuesPresent) {
            columns = columns.concat([{
              title: <Tooltip placement="top" title={ valueTooltip }>Value { icon }</Tooltip>,
              dataIndex: 'value',
              key: 'value',
              className: 'value'
            }, {
              title: "",
              dataIndex: 'description',
              key: 'description',
              className: 'description'
            }])
        }

        return (
            <Table
              className="choice-breakdown-table"
              dataSource={ this.props.choicesRateValueArray }
              columns={ columns }
              pagination={ false }
            />
        )
    }
}
