import { Record, List } from "immutable";
import moment from 'moment';

export const PropertiesReportV2Record = Record({
  key: null,
  date: "",
  fancyDate: "",
  siteid: null,
  propertyname: null,
  totalpageviews: 0,
  messagedusers: 0,
  fullconsent: 0,
  fullconsentper: "",
  partialconsent: 0,
  partialconsentper: "",
  rejectedall: 0,
  rejectedallper: "",
  legitimateintobj: 0,
  legitimateintobjper: "",
  aggData: List(),
  exportdata: List(),
});

export const VendorsConsentReportRecord = Record({
  key: null,
  name: "",
  consentcount: 0,
  unconsentcount: 0,
  pvcount: 0,
  consentrate: 0,
  rejectionrate: 0,
  legintobj: 0,
});

export const MessageTableReportV2Record = Record({
  key: null,
  date: "",
  messageid: "",
  partitionname: "",
  totalmsgs: 0,
  consentall: 0,
  consentallper: "",
  consentsome: 0,
  consentsomeper: "",
  noconsent: 0,
  noconsentper: "",
  rejectall: 0,
  rejectallper: "",
  legitimateintobj: 0,
  legitimateintobjper: "",
  siteid: null,
  aggData: List(),
});

export const GDPRConsentFilters = Record({
  startDate: moment()
    .subtract(1, "weeks")
    .startOf("isoWeek"),
  endDate: moment()
    .subtract(1, "weeks")
    .endOf("isoWeek"),
  siteId: List([]),
  geo: List([]),
  device: List([]),
  browser: List([]),
  os: List([]),
});

export const VendorFilters = Record({
  startDate: moment(),
  endDate: moment(),
  siteId: "",
  geo: List([]),
});
