import PropTypes from 'prop-types';
import React from 'react';

import BooleanStep from './BooleanStep';
import CommonNumCount from './CommonNumCount';

import { IntRangeKVRecord } from '../../../../../constants.js';

export default class MatchIntRange extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(IntRangeKVRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleNumChange = (value, propType) => {
    this.props.onChange(this.props.data.set(propType, value));
  }

  render() {
    const labelText = (
      'Key ' + this.props.data.k +
      ' Start Value: ' + this.props.data.v_start +
      ' End Value: ' + this.props.data.v_end
    )
    return (
      <BooleanStep
        { ...this.props }
        labelText={ labelText }
        propertyName={ 'allow_on_satisfy' }
      >
        { this.props.children }
        <CommonNumCount
          value={ this.props.data.k }
          onChange={ (value) => this.handleNumChange(value, 'k') }
          label={ 'Key' }
        />
        <CommonNumCount
          value={ parseInt(this.props.data.v_start) }
          onChange={ (value) => this.handleNumChange(value, 'v_start') }
          label={ 'Start Value' }
        />
        <CommonNumCount
          value={ parseInt(this.props.data.v_end) }
          onChange={ (value) => this.handleNumChange(value, 'v_end') }
          label={ 'End Value' }
        />
      </BooleanStep>
    );
  }
}
