import React from 'react'
import SVG from 'react-inlinesvg';
import { Modal } from '../../styleguide';
import  alertIcon  from '../../assets/images/privacy-lens/alert.svg';

const CustomDeleteModal = (props) => {
    let deleteMsg;
    let warningMsg;
    if (props.id) {
        deleteMsg =
        " Are you sure you want to delete '" +
        props.name +
        "'?";
        warningMsg = <p>All data and configurations will be lost.</p>
    }
    return (
        <Modal
        wrapClassName="property-delete-modal"
        title={props.title ? props.title : null}
        visible={props.id? true : false}
        okText="Delete"
        onOk={(e) => props.handleDelete(e)}
        onCancel={(e) => props.handleCancel(e)}
        >
            <div className="property-delete-icon">
                <SVG src={alertIcon} />
            </div>
            <div className="propert-delete-content">
                <div className="property-delete-header"><div className='header-title'>{deleteMsg}</div></div>
                <div className="property-delete-note">
                    <div>{warningMsg}</div>
                </div>
            </div>
        </Modal>
    );
}

export default CustomDeleteModal;
