import React from 'react';
import { Button as AntdButton } from 'antd';

const Button = antdProps => {
  const props = { ...antdProps };
  props.className = `${props.className || ''} spsg-btn`;

  // FONT: oswald, opensans
  if (props.font) {
    props.className += ' btn-' + props.font;
    delete props.font;
  }

  // TYPE: default, primary, secondary, tertiary
  if (props.type) {
    props.className += ' btn-' + props.type;
    delete props.type;
  }

  if (props.color) {
    props.className += ' btn-' + props.color;
    delete props.color;
  }

  return <AntdButton {...props} />;
};

export default Button;
