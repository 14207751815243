import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { browserHistory } from 'react-router';
import {
  getVendorLists,
  deleteVendorList,
  clearVendorListError,
  cloneVendorList,
} from '../../../actions/vendor_list_actions_ccpa';
import Loading from '../../common/Loading.js.jsx';
import VendorListRow from './VendorListRow';
import { VendorList as VendorListRecord } from '../../../records/vendor_list_records';
import { User } from '../../../records/account_records';
import CustomButton from '../../common/CustomButton.js.jsx';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, Select } from '../../../styleguide';

const SEARCH_OPTIONS = ['Name', 'Property'];

class VendorLists extends React.Component {
  static propTypes = {
    vendorLists: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(VendorListRecord)).isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    route: PropTypes.shape({
      v2: PropTypes.bool,
    }).isRequired,
    currentUser: PropTypes.instanceOf(User),
    getVendorLists: PropTypes.func.isRequired,
    deleteVendorList: PropTypes.func.isRequired,
    clearVendorListError: PropTypes.func.isRequired,
    cloneVendorList: PropTypes.func.isRequired,
  }

  state = {
    searchText : '',
    searchBy: 'Name'
  }

  componentDidMount = () => {
    if (this.props.currentUser) {
      this.props.getVendorLists();
    }
  }

  componentWillUnmount = () => {
    this.props.clearVendorListError();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getVendorLists();
    }
  }

  handleCreate = () => {
    const v2 = this.props.route.v2 ? '_v2' : '';
    browserHistory.push(`/us_privacy/vendor_lists/create`);
  };

  setSearch = (value) => {
    this.setState({ searchText : value })
  }

  handleSearchBy = (value) => {
    this.setState({searchBy : value})
  }

  clearSearch = () => {
    this.setState({ searchText : '' })
  }

  getVlNameError = (oldVlName, newVlName) => {
    let nameError = null;
    const duplicateName = this.props.vendorLists.map(vl => vl.name).find(vl => vl === newVlName.trim());
    if(newVlName.trim().length === 0){
      nameError = <>Name field can not be empty</>
    }else if(duplicateName && duplicateName !== oldVlName) {
      nameError = <>Vendor List with name '<strong>{newVlName}</strong>' already exists</>
    }
    return nameError; 
  }

  render() {
    if (this.props.pendingRequestsMap.some(request => request)) {
      return <Loading />;
    }

    let readOnly = false;
    if (this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('Vendor List - CCPA')) {
      readOnly = true;
    }

    let vendorLists;
    let { searchText, searchBy } = this.state;
    if(searchText.trim() === '') {
      vendorLists = this.props.vendorLists;
    } else {
      switch(searchBy) {
        case 'Name':
          vendorLists = this.props.vendorLists.filter(vl => vl.name.toLowerCase().indexOf(searchText.trim().toLowerCase()) !== -1)
          break;
        case 'Property':
          let searchedSiteIds = this.state.searchText.trim() == '' ? this.props.sites.map(s => s.id) : this.props.sites.filter(s => s.domain.toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) !== -1).map(s => s.id);
          vendorLists = this.props.vendorLists.filter(vl => vl.siteIds.some(id => searchedSiteIds.includes(id)));
          break;
        default :
          vendorLists = this.props.vendorLists;
      }
    }

    let content;
    if (this.props.vendorLists.size) {
      content = (
        <ul>
          {
            vendorLists.map(l => (
              <li key={ l.id }>
                <VendorListRow
                  vendorList={ l }
                  handleDelete={ this.props.deleteVendorList }
                  cloneVendorList={ this.props.cloneVendorList }
                  accountId={ this.props.currentUser.accountId }
                  clearVendorListError={ this.props.clearVendorListError }
                  pending={ this.props.savePending }
                  readOnly={ readOnly }
                  v2={ this.props.route.v2 }
                  getVlNameError={this.getVlNameError}
                  vendorListError={this.props.vendorListError}
                />
              </li>
            ))
          }
        </ul>
      );
    } else {
      if (!readOnly) {
        content = (
          <div className='no-lists-message-container'>
            <p className='title'>
              You haven’t created a Vendor List yet.
            </p>
            <p className='descr'>
              We use vendor lists to make updating easy. Any changes you make, update automatically across all Privacy Managers using that list.
            </p>
            <CustomButton className='create-button' onClick={ this.handleCreate }>Get Started</CustomButton>
          </div>
        );
      } else {
        content = (
          <div className='no-lists-message-container'>
            <p className='title'>
              There are no Vendor Lists available.
            </p>
          </div>
        );
      }
    }

    let createButton;
    if (!readOnly) {
      createButton = <Button type={"primary"} onClick={this.handleCreate}>NEW</Button>;
    }
    return (
      <div className='vendor-lists-container'>
        <p className='main-title'><div>U.S. Privacy Standard - Vendor Lists</div> </p>
        <div className="vl-search-container">
        <div className="vl-search">
        <div>
            Search by
          </div>
          <Select
            defaultValue={SEARCH_OPTIONS[0]}
            value={this.state.searchBy}
            onChange={this.handleSearchBy}
            options={SEARCH_OPTIONS.map(o =>( {label: o, value: o}))}
          />
          <Input
            type="search"
            placeholder="Search"
            onChange={(e) => {
              this.setSearch(e.target.value);
            }}
            value={this.state.searchText}
            suffix={searchText.trim() === '' ? <SearchOutlined /> : <CloseOutlined onClick={this.clearSearch}/>}
          />
        </div>
          {createButton}
        </div>
        <div className='vl-search-result'>
          {searchText.trim() !== ''
          ? <><b>{vendorLists.size} result(s)</b> found with maching {searchBy !== 'Name' ? <><b> {searchBy} </b> in </> : null}<b> Vendor List</b></>
          : null}
        </div>
        { content }
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    vendorLists: store.vendorListState.getIn(['vendorLists', 'value']),
    vendorListError: store.vendorListState.getIn(['vendorLists', 'error']),
    pendingRequestsMap: new Map({
      vendorLists: store.vendorListState.getIn(['vendorLists', 'pending']),
    }),
    savePending: store.vendorListState.get('savePending'),
    sites: store.siteState.getIn(['sites', 'value']),
  };
};

export default connect(
  mapStateToProps, {
    getVendorLists,
    deleteVendorList,
    cloneVendorList,
    clearVendorListError,
  },
)(VendorLists);
