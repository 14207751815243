
import React from 'react';

class SdkLists extends React.Component {

  render() {
    return (
      <div className='main-sdk'>
        <div className='help-paragraph'>
            <p className='help-paragraph-title'>Welcome to Sourcepoint’s SDK portal.</p>
            <p className='help-paragraph-desc'>
              Here, you’ll find information and important links to access Sourcepoint’s SDKs and key documents to enable successful integration of Sourcepoint technology. You can also access our SDK knowledge base
              <a href="https://documentation.sourcepoint.com/"><i> here</i>. </a>
            </p>
         
            <div className='sdks-list'>
              <h3>Platforms</h3>
              <ul className='sdk-list'>
                <li><a href="/sdk/sdk_android">Android</a></li>
                <li><a href="/sdk/sdk_ios">iOS</a></li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
  }

export default SdkLists;
