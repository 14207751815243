import PropTypes from 'prop-types';
import React from 'react';

import AntdFormItem from '../../../common/AntdFormItem.jsx';
import { MessageConfigRecord } from '../../../../records/message_config_records.js';

export default class MessageEditIsConsentStyles extends React.Component {
  static propTypes = {
    messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
    updateMessageConfig: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
  }

  onUpdate = (event) => {
    const templateType = event.target.checked ? 'consent' : 'base';
    this.props.updateMessageConfig(
      this.props.messageConfig.setIn(
        [ 'data', 'style', 'template' ],
        templateType,
      ),
    );
  }

  render() {
    const isConsentStyles =
      this.props.messageConfig.getIn([ 'data', 'style', 'template' ]);

    return (
      <div className='message-edit-include-default-styles'>
        <h3>Include Consent Styles</h3>
        <div className='options-styles-property'>
          <div className='options-include-default-styles'>
            <AntdFormItem
              fieldName='message-is-consent-styles'
              initialValue={ isConsentStyles }
              onChange={ this.onUpdate }
              form={ this.props.form }
              editing={ this.props.editing }
              type={ 'checkbox' }
            />
          </div>
        </div>
      </div>
    );
  }
}
