export function setValue(state, keyPath, value) {
  return state.setIn(keyPath.concat('value'), value)
    .setIn(keyPath.concat('error'), null)
    .setIn(keyPath.concat('pending'), false);
}

export function setError(state, keyPath, error) {
  return state.setIn(keyPath.concat('error'), error)
    .setIn(keyPath.concat('pending'), false);
}

export function setPending(state, keyPath) {
  return state.setIn(keyPath.concat('pending'), true);
}

export function stopPending(state, keyPath) {
  return state.setIn(keyPath.concat('pending'), false);
}
