
import React, { useState } from 'react';
import { Modal, Table, Input, Select } from '../../../../styleguide'
import { useSelector } from "react-redux";
import moment from 'moment';
import { RightOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import { getTypes, interval, campaignTypesCodes, getCampaignNameLatestEnv } from '../helper';
import PartitionDetails from "./PartitionDetails";
import { numberWithCommas, capitalizeFirstLetter } from "../../../utils";

const { Option } = Select;

function EndedCampaigns({ 
  showEndedCampaigns, 
  campaigns, 
  onCancel, 
  isMultiCampaignEnabled,
  endCampaignsType,
  scenarios,
  currentUser,
  statsLoading,
  site
}) {

  const statsData = useSelector(state => state.campaignState.get('statsData'));

  const campaignsWithStats = campaigns.map((c, ci) => {
    let campaignObj = c;
    let totalPV = 0;
    let messagedUsers = 0;
    const stats = statsData[c.id];
    if(stats && stats.value && stats.value != null) {
       totalPV = stats.value.campaign_stats.total_pv;
       messagedUsers = stats.value.campaign_stats.total_msg;
    }
    campaignObj.totalPV = totalPV;
    campaignObj.messagedUsers = messagedUsers;
    return campaignObj;
  });

  const defaultFilter = isMultiCampaignEnabled ? 'type' : 'endDate';

  const [ searchText, updateSearchText ] = useState('');
  const [ filterValue, updateFilter ] = useState();
  const [ filterType, UpdateFilterType ] = useState(defaultFilter);
  const [ expandedRowKeys, updateExpandedRows ] = useState([]);

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'campaign-link',
      sorter: (a, b) => (a.name || a.description).localeCompare((b.name || b.description)),
      render: (value, row) => getCampaignNameLatestEnv(value || row.description)
    },
    {
      title: 'Type',
      dataIndex: 'campaign_type_id',
      key: 'campaign_type_id',
      sorter: (a, b) => campaignTypesCodes[a.campaign_type_id].localeCompare(campaignTypesCodes[b.campaign_type_id]),
      render: (value, row) => campaignTypesCodes[value]
    },
    {
      title: 'Start Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) =>  moment(a.created_at).valueOf() - moment(b.created_at).valueOf(),
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'End Date',
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      sorter: (a, b) =>  moment(a.deleted_at).valueOf() - moment(b.deleted_at).valueOf(),
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm')
    },
    // {
    //   title: 'Total PV',
    //   dataIndex: 'totalPV',
    //   key: 'totalPV',
    //   sorter: (a, b) => a.totalPV - b.totalPV,
    //   render: (value) => numberWithCommas(value)
    // },
    // {
    //   title: 'Messaged Users',
    //   dataIndex: 'messagedUsers',
    //   key: 'messagedUsers',
    //   sorter: (a, b) => a.messagedUsers - b.messagedUsers,
    //   render: (value) => numberWithCommas(value)
    // },
    {
      title: 'Partition Set',
      dataIndex: 'partition_set',
      key: 'partition_set',
      sorter: (a, b) => a.partition_set.description.localeCompare(b.partition_set.description),
      render: (text, record) => record.partition_set.description
    },
   ];

   const getColumns = () => {
     let cols = columns;
     if(!isMultiCampaignEnabled) {
       cols.splice(1, 1);
     }
     return cols;
   }

  const onSearch = e => {
    updateSearchText(e.target.value);
  };

  const getFilteredCampaigns = () => {
    let filteredCampaigns = campaignsWithStats.filter((campaign) => (campaign.name || campaign.description).toLowerCase().includes(searchText.toLowerCase()));
    if(filterValue || filterValue === 0) {
       switch(filterType) {
         case 'type':
         const isAllSelected = filterValue === 'all';
         filteredCampaigns = filteredCampaigns.filter(c => isAllSelected ? true : parseInt(c.campaign_type_id) === filterValue);
         break;

         case 'endDate':
         const limit = moment().subtract(filterValue, 'day').format('YYYY-MM-DD');
         filteredCampaigns = filteredCampaigns.filter(c => { 
           const campaignEndDate =  moment(c.deleted_at).format('YYYY-MM-DD');
           const isSame = moment(campaignEndDate).isSame(moment(limit));
           const isAfter = moment(campaignEndDate).isAfter(moment(limit));

           return isAfter || isSame;
		 });
         break;
       }
    }
    
    return filteredCampaigns;
  }

  const handleFilterChoice =  (choice) => {
    UpdateFilterType(choice);
    updateFilter();
  }

  const skipCustom = false;
  const campnTypes = getTypes(currentUser, site.type, skipCustom);
  const selectOptions = filterType === 'type' ? campnTypes : interval;

  const filteredCampaigns = getFilteredCampaigns();

  const endedCampaignsColumns = getColumns();

  return <Modal className="ended-campaigns-modal" 
           visible={showEndedCampaigns} 
           title={`Ended Campaigns - ${capitalizeFirstLetter(endCampaignsType)}`} 
           footer={null} 
           onCancel={() => onCancel()}>
        <div className="ended-campaign-header">
          <Input suffix={<SearchOutlined />} value={searchText} placeholder="Search campaigns" onChange={ onSearch } style={{ width: 200 }} /> 
          <div className="filter-wrapper">
            Filter By
            <div className="filter-selection">
              { isMultiCampaignEnabled && <span className={`filter-option type ${filterType == 'type' ? 'active' : ''}`} onClick={() => handleFilterChoice('type')}>Type</span> }
              <span className={`filter-option ${filterType == 'endDate' ? 'active' : ''}`} onClick={() => handleFilterChoice('endDate')}>End Date</span>
           </div>&nbsp;&nbsp;
           <Select placeholder="Select" value={filterValue} onChange={(value) => updateFilter(value)}>
            {filterType === 'type' && <Option key="all" value="all">All</Option>}
            {selectOptions.map((option, i) => <Option key={i} value={option.value}>{option.label}</Option>)}
           </Select>
          </div>
        </div>
        <Table 
           rowKey={record => record.id}
           rowClassName={(record, index) => expandedRowKeys.includes(record.id) ? 'expanded-parent' : ''}
           expandedRowKeys={expandedRowKeys}
           expandable={{
             onExpandedRowsChange: (expandedRows) => updateExpandedRows(expandedRows),
             expandedRowClassName: (record, index, ident) => 'expanded-child',
             expandedRowRender: record => <PartitionDetails key={record.id+'-'+record.id} currentUser={currentUser} scenarios={scenarios} campaign={record}/>,
           }} 
           dataSource={filteredCampaigns} 
           columns={endedCampaignsColumns}
           loading={false}
           pagination={{
                      position: ['bottomCenter'],
                      showTotal: (total) => `Total: ${total}`,
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '30'],
                      locale: { items_per_page: ' Records per page' },
                    }}/>
      </Modal>
}

export default EndedCampaigns;