import React, { Component } from 'react';
import { Select } from "../../../../../../../../styleguide";
import ReactQuill from 'react-quill';
import { COUNTRY_LANGUAGES } from '../../../../../../../../constants.js';
import VendorCount from '../TextLanguage/VendorCount';

const { Option } = Select;

const vCountRef = React.createRef();

const CustomToolbar = () => (
  <div id="toolbar">
    <VendorCount ref={vCountRef} />
  </div >
);

async function vendorCountHandler(modal) {
  const result = await modal.show();

  const { index: selectionIndex, length: selectionLength } = this.quill.getSelection(true);
  this.quill.deleteText(selectionIndex, selectionLength)
  this.quill.insertText(selectionIndex, result)
}

class TextLanguageLimited extends Component {
  state = {
    visible: false,
    showQuill: true,
  }

  modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        ['vendor-count']: function () {
          vendorCountHandler.call(this, vCountRef.current)
        }
      }
    }
  }

  componentDidMount = () => {
    const { languages } = this.props.allSettings;
    if (!languages || Object.keys(languages).length === 0) {
      this.updateText(this.props.value);
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentComponentIndex !== this.props.currentComponentIndex) {
      this.forceQuillRemount();
    };
  }

  forceQuillRemount = (values = {}) => this.setState({ showQuill: false, ...values }, () => this.setState({ showQuill: true }))

  updateText = (value) => {
    const {
      settingKey,
      defaultLanguage,
      updateSetting,
      allSettings,
      allSettings: {
        languages = {},
      },
    } = this.props;
    const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;
    const languagesClone = Object.assign({}, languages || {});
    if (!languagesClone[selectedLanguage]) {
      languagesClone[selectedLanguage] = {};
    };

    languagesClone[selectedLanguage][settingKey] = value;

    if (defaultLanguage === selectedLanguage) {
      return updateSetting('languagesAll', { languagesClone, text: value });
    };

    updateSetting('languages', languagesClone);
  }

  updateLanguage = (language) => {
    this.props.updateSetting('selectedLanguage', language);
    this.forceQuillRemount({ language });
  }

  render() {
    const {
      value,
      defaultLanguage,
      allSettings,
      allSettings: {
        languages = {},
        isDynamic,
      },
    } = this.props;

    const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;
    const currentValue = (defaultLanguage === selectedLanguage)
      ? value
      : (languages[selectedLanguage] !== undefined)
        ? languages[selectedLanguage].text
        : '';

    return (
      <div className="text-editor">
        <div className="quill-container">
          <div className="screen" style={{ display: !isDynamic && 'none' }} />
          <div className="native-ott-warning">
            *OTT devices may display your message differently due to differences in platform restrictions
          </div>
          {this.state.showQuill && (
            <>
              <CustomToolbar />
              <ReactQuill
                disabled={isDynamic}
                onChange={this.updateText}
                defaultValue={currentValue}
                modules={this.modules}
              />
            </>
          )}
        </div>
        <div className="text-language">
          <h4>Language: </h4>
          <Select disabled={isDynamic} onChange={this.updateLanguage} value={selectedLanguage}>
            {COUNTRY_LANGUAGES.map(({ code, language }) => <Option key={code}>{language}</Option>)}
          </Select>
        </div>
      </div>
    );
  }
}

export default TextLanguageLimited;