import React, { Component } from 'react';
import { InputNumber as AntInput } from 'antd';

const MIN_ZINDEX = -1;
const MAX_ZINDEX = 2147483647;

class ZIndex extends Component {
  updateInput = (value) => {
    this.props.updateSetting(this.props.settingKey, value);
  }
  render() {
    return (
      <div className="set-inputnumber">
        <h4>{this.props.settingsTitle}</h4>
        <AntInput style={{width: '100%'}} min={MIN_ZINDEX} max={MAX_ZINDEX} value={this.props.value} onChange={(value) => this.updateInput(value)} />
      </div>
    );
  }
}

export default ZIndex;

