/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Tabs } from '../../../../styleguide';
import ChartCard from './ChartCard';
import OverviewCount from './OverviewCount';
import useSearchParams from '../shared/useSearchParams';
import moment from 'moment';
import { getSummaryData } from '../../../../api/diagnose_dashboard/diagnose_dashboard';

function useRelativeScoreHighlightData(filters, skip) {
  const [relativeHighlightData, setRelativeHighligtData] = useState({});
  const [isLoading, setLoading] = useState(false);
  async function getRelativeComparisonData() {
    try {
      setLoading(true);
      const rangeData = await Promise.all([
        getSummaryData({
          ...filters,
          start: moment(filters.start).subtract(1, 'week'),
          end: filters.start,
        }),
        getSummaryData(filters),
      ]);
      const resultData = rangeData.reduce(
        ({ average, range }, list, idx) => {
          let averageSum = 0;
          const result = list.map((period) => {
            averageSum += period?.overall_relative_score;
            return {
              date: moment(period?.timestamp).format('MMM DD, YYYY'),
              'Relative Score': period?.overall_relative_score,
            };
          });
          const date = !idx
            ? `${moment(filters.start).subtract(1, 'week').format('MMM DD')} - ${moment(filters.start).format('MMM DD')}`
            : `${moment(filters.start).format('MMM DD')} - ${moment(filters.end).format('MMM DD, YYYY')}`;

          return {
            average: [
              ...average,
              {
                date,
                'Relative Score': averageSum / result.length,
              },
            ],
            range: [...range, ...result],
          };
        },
        { average: [], range: [] },
      );
      setRelativeHighligtData(resultData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (!skip) {
      getRelativeComparisonData();
    }
  }, []);

  return { isLoading, relativeHighlightData };
}

const Overview = ({ summaryData, description }) => {
  const searchParams = useSearchParams();
  const { isLoading, relativeHighlightData } = useRelativeScoreHighlightData(
    {
      metrics: [searchParams.metric],
      websites: [searchParams.property],
      start: moment.utc(searchParams.start_date),
      end: moment.utc(searchParams.end_date),
      regions: [searchParams.region],
      benchmark_websites: [],
    },
    searchParams.type !== 'RELATIVE_SCORE',
  );

  const getInitialTab = () => {
    if (searchParams.type === 'RELATIVE_SCORE') {
      return 'relative_highlight';
    }
    if (searchParams.type === 'AVERAGE_COUNT') {
      return 'average';
    }
    return 'all';
  };
  const [activeTab, setActiveTab] = useState(getInitialTab());

  const dataEvolution = summaryData.map((period) => ({
    date: moment(period?.timestamp).format('MMM DD, YYYY'),
    'Relative Score': period?.overall_relative_score,
    'Average Count (My properties)': period?.metrics[0].value,
    'Average Count (Benchmark)': period?.metrics[0].benchmark_value,
  }));

  const dataFrom = dataEvolution[0];
  const dataTo = dataEvolution[dataEvolution.length - 1];

  const relativeItem =
    searchParams.type === 'RELATIVE_SCORE'
      ? {
          key: 'relative_highlight',
          label: 'Relative Score',
          children: (
            <Spin spinning={isLoading}>
              <div className={'summary_charts_container overview_container'}>
                <div style={{ width: '100%', paddingBottom: '30px' }}> {description.relative}</div>
                <ChartCard
                  title={'Relative Score Evolution'}
                  titleTooltip={
                    'This graph shows how the relative score changes over time. The time period corresponds to the time period chosen.'
                  }
                  dataEvolution={relativeHighlightData?.range}
                  showRelative
                />
                <OverviewCount
                  showRelative
                  dataFrom={relativeHighlightData?.average?.[0] || {}}
                  dataTo={relativeHighlightData?.average?.[1] || {}}
                  isHighlighted
                  showDiff
                />
              </div>
            </Spin>
          ),
        }
      : {
          key: 'relative',
          label: 'Relative Score',
          children: (
            <div className={'summary_charts_container overview_container'}>
              <div style={{ width: '100%', paddingBottom: '30px' }}> {description.relative}</div>
              <ChartCard
                title={'Relative Score Evolution'}
                titleTooltip={
                  'This graph shows how the relative score changes over time. The time period corresponds to the time period chosen.'
                }
                dataEvolution={dataEvolution}
                showRelative
              />
              <OverviewCount showRelative dataFrom={dataFrom} dataTo={dataTo} />
            </div>
          ),
        };
  const items = [
    {
      key: 'all',
      label: 'All',
      children: (
        <div className={'summary_charts_container overview_container'}>
          <div style={{ width: '100%', paddingBottom: '30px' }}> {description.all}</div>
          <ChartCard
            title={'Relative Score & Average Count Evolution'}
            titleTooltip={
              'This graph shows how the relative score and average counts change over time. The time period corresponds to the time period chosen.'
            }
            dataEvolution={dataEvolution}
            showRelative
            showAverage
          />
          <OverviewCount showRelative showAverage dataFrom={dataFrom} dataTo={dataTo} />
        </div>
      ),
    },
    {
      key: 'average',
      label: 'Average Count',
      children: (
        <div className={'summary_charts_container overview_container'}>
          <div style={{ width: '100%', paddingBottom: '30px' }}> {description.average}</div>
          <ChartCard
            title={'Average Count Evolution'}
            titleTooltip={
              'This graph shows how the average counts change over time. The time period corresponds to the time period chosen.'
            }
            dataEvolution={dataEvolution}
            showAverage
          />
          <OverviewCount
            showAverage
            dataFrom={dataFrom}
            dataTo={dataTo}
            isHighlighted={searchParams.type === 'AVERAGE_COUNT'}
          />
        </div>
      ),
    },
    relativeItem,
  ];

  return <Tabs className="diagnose-overview" onChange={setActiveTab} activeKey={activeTab} items={items} />;
};

export default Overview;
