import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { PlusCircleOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Layout, Collapse, Alert } from 'antd';
import { Button, Popconfirm, message, Select } from '../../../../styleguide';
import { List, Map, OrderedSet, fromJS } from 'immutable';

import TemplateHeader from './TemplateHeader';
import {
  stepList,
  listData,
  dataTemplates,
  consentGateClass,
  consentGateGdprV2Class,
  ConsentGateCcpaClass,
  ConsentGateUsnatClass,
} from './Data';
import { getParameterByName, hasFeature } from '../../../utils.js';
import { styles } from './styles';
import { connect } from 'react-redux';
import { User } from '../../../../records/account_records.js';
import { newScenarioMsgToRecord } from '../../../../api/dialogue/messages';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

import {
  createScenario,
  createUpdateScenario,
  getDraftScenarios,
  updateScenario,
  createSiteGroupScenario,
  createUpdateSiteGroupScenario,
  updateSiteGroupScenario,
} from '../../../../actions/scenario_actions';
import {
  SCENARIO_STEPS,
  ScenarioV2,
  ScenarioRowRecord,
} from '../../scenarios/versionTwo/components/steps';
import { MessageIdRecord } from '../../../../scenario_constants.js';

import { getDraftMessages } from '../../../../actions/message_actions';
import { getAllSites } from '../../../../actions/site_actions';
import { STATUS_TYPES as STATUS } from '../../../../constants';
import {
  populateTemplates,
  messageDelay,
  messageConditions,
  calculateInSeconds,
} from '../helper';
import ConditionCommonWrapper from './ConditionCommonWrapper';
import DismissErrorModal from '../../../common/DismissErrorModal';
import { CollapseSortableContainer } from './CollapseSortableContainer';
import Loading from '../../../common/Loading.js.jsx';

const { Panel } = Collapse;
const { Content } = Layout;
const { Option } = Select;

class TemplatePage extends Component {
  static propTypes = {
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,

    createScenario: PropTypes.func.isRequired,
    getDraftMessages: PropTypes.func.isRequired,
    getDraftScenarios: PropTypes.func.isRequired,
    createSiteGroupScenario: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    let isTemplate;
    if (this.props.location.query.id) {
      isTemplate = JSON.parse(
        getParameterByName('template', window.location).toLowerCase()
      );
    }
    this.state = {
      selectedOption: '',
      scenarioName: 'New Scenario',
      toggleScenarioName: false,
      createNew: false,
      selectedCondition: '',
      stepList: stepList,
      activeStep: 0,
      buttonTitle: 'Add',
      deleteConditionPopupVisible: false,
      messages: List(),
      stepName: 'Step 1',
      activeKeys: List(['0']),
      scenarioEdited: new ScenarioV2({
        description: 'New Scenario',
        site_id: parseInt(this.props.location.query.site_id),
        schema_version: 2,
        steps: newRow(),
        campaign_type_id: this.props.location.query.campaign_type_id || 0,
      }),
      isTemplate: isTemplate,
      activePanelId: '',
      initialLoad: true,
      allMessageData: List(),
      delConditionVisible: false,
      deleteConditionDet: Map({}),
      showCloseModalErr: false,
      showConditionDropdown: '',
      conditionIndex: '',
      msgsLoading: false,
      stepIndex: [],
      showErrorMessage: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.scenarioEdited && props.scenarioEdited.id && state.initialLoad) {
      return {
        scenarioEdited: props.scenarioEdited,
        initialLoad: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const { site_id } = this.props.location.query;
    this.getMessages();
    this.props.getDraftMessages(site_id);
    this.props.getDraftScenarios(site_id);
    this.props.getAllSites();
  }

  getMessages = () => {
    this.setState({ msgsLoading: true });
    const siteId = this.props.location.query.site_id;
    fetch(
      `${permissionsSvsBase}/msg/get_all_message_list?object_status=1&site_id=${siteId}`,
      {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((r) => r.json())
      .then((resp) => {
        const messages = OrderedSet(
          resp.data.message_list.map((message) => newScenarioMsgToRecord(message, 'draft'))
        );
        this.setState({ messages, msgsLoading: false });
      });
  };

  onMessageListChange = (messages, index) => {
    this.setState((state) => {
      let msgList = state.allMessageData;
      if (msgList.size < 0) {
        msgList = msgList.push(messages);
      } else {
        msgList = msgList.set(index, messages);
      }
      return { allMessageData: msgList };
    }, () => {
      const updated = this.updateMessagesInScenario();
      this.setState({ scenarioEdited: updated });
    });
  };

  onChange = (rowIndex, data) => {
    let steps = this.state.scenarioEdited.get('steps');
    steps = steps.setIn([rowIndex, 'rowData'], data);
    const updated = this.state.scenarioEdited.set('steps', steps);
    this.setState({
      scenarioEdited: updated,
      activePanelId: '',
      buttonTitle: 'Add',
      showConditionDropdown: '',
    });
  };

  goBack = () => browserHistory.goBack();

  close = () => {
    const beforeUpdate = this.props.scenarioEdited.toJS();
    let afterUpdate = this.state.scenarioEdited.toJS();

    let messageList = this.state.allMessageData;
    let steps = this.state.scenarioEdited.get('steps');
    if (messageList.size > 0 && steps.size > 0) {
      afterUpdate = this.updateMessagesInScenario().toJS();
    }
    const beforeObjOmitDates = _.omit(beforeUpdate, ['created_at', 'updated_at']);
    const afterObjOmitDates = _.omit(afterUpdate, ['created_at', 'updated_at']);

    const isScenarioUpdated = JSON.stringify(beforeObjOmitDates) === JSON.stringify(afterObjOmitDates);

    if(!isScenarioUpdated) {
      this.toggleCloseModalErr();
      return;
    }

    this.goBack();
  };

  closePanel = () => {
    this.setState({ activePanelId: '', showConditionDropdown: '' });
  };

  renderCondition = (activePanelId) => {
    const selectedPanel = listData.find(
      (sp) => sp.get('id') === activePanelId
    );
    let conditionProps = {
      conditionLabel: selectedPanel.get('title'),
      activeStep: this.state.activeStep,
      buttontitle: this.state.buttonTitle,
      isUpdate: this.state.buttonTitle === 'Update',
      selectedCondition: selectedPanel,
      selectedOption: this.state.selectedOption,
      clear: this.state.clear,
      onChange: this.onChange,
      scenarioEdited: this.state.scenarioEdited,
      closePanel: this.closePanel,
      conditionIndex: this.state.conditionIndex,
    };

    const ConditionComponent = selectedPanel.get('conditionComponent');   
    const extraProps = selectedPanel.get('extraProps');
    return <ConditionComponent {...extraProps} {...conditionProps} />
  };

  addNewRow = () => {
    let steps = this.state.scenarioEdited
      .get('steps')
      .push(new ScenarioRowRecord());
    let stepBlockData = new SCENARIO_STEPS[
      'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways'
    ].Record();
    stepBlockData = stepBlockData.set('data', new MessageIdRecord());
    const rowData = steps.get(steps.size - 1).rowData.push(stepBlockData);
    return steps.setIn([steps.size - 1, 'rowData'], rowData);
  };

  addStep = () => {
    const steps = this.addNewRow();
    const scenarioEdited = this.state.scenarioEdited.set('steps', steps);
    const newKey = (steps.size - 1).toString();
    this.setState((prevState) => ({
      scenarioEdited: scenarioEdited,
      activeKeys: prevState.activeKeys.push(newKey),
      createNew: true,
    }));
  };

  updateActiveStep = (index, e) => {
    e.stopPropagation();
    const ind = parseInt(index);
    if(this.state.activeStep != ind) {
      this.setState({ activeStep: ind });
    }
  };

  deleteStep = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.activeStep >= index) {
      this.setState({
        activeStep:
          this.state.activeStep - 1 <= -1 ? 0 : this.state.activeStep - 1,
      });
    }
    const steps = this.state.scenarioEdited.get('steps').delete(index);
    const messageData =
      this.state.allMessageData.get(index) && this.state.allMessageData.get(index).size > 0
        ? this.state.allMessageData.delete(index)
        : this.state.messageList;
    const updatedStepList = this.state.scenarioEdited.set('steps', steps);
    this.setState({
      scenarioEdited: updatedStepList,
      allMessageData: messageData,
      showConditionDropdown: '',
      activePanelId: '', 
    });
    this.updateStepIndex(index, true);
  };

  copyStep = (index, e) => {
    e.stopPropagation();
    const step = this.state.scenarioEdited.get('steps').get(index);
    const updatedStepList = this.state.scenarioEdited.steps.push(step);
    const scenario = this.state.scenarioEdited.set('steps', updatedStepList);
    this.setState({ scenarioEdited: scenario });
  };

  setActiveKey = (index, e) => {
    this.updateActiveStep(index, e);
    e.stopPropagation();
    e.preventDefault();
    let ind = this.state.activeKeys.findIndex((item) => item == index);
    this.setState({
      activeKeys: this.state.activeKeys.includes(index)
        ? this.state.activeKeys.delete(ind)
        : this.state.activeKeys.push(index),
    });
  };

  combineAllMessages = (item, index) => {
    let activeIndex = index;
    let msgData = item;

    let rowData = this.state.scenarioEdited.steps
      .get(activeIndex)
      .rowData.filter((item) => item.get('category') === 'condition');

    let rowDataList = List();
    let allRowMesages;
    msgData.map((item, index) => {
      let msgDelivery = item.get('msgDelivery');
      let msg = item.get('msg');
      let msgDelay = item.get('msgDelay');
      let msgDelayUnit = item.get('msgDelayUnit');
      let msgTimeSpan = item.get('msgTimeSpan');
      let msgRepeat = item.get('msgRepeat');
      let msgMax = item.get('msgMax');
      let msgRepeatSecs = item.get('msgRepeatSecs');

      allRowMesages = List();
      //handle delay

      if (msgDelay === 0) {
        msgTimeSpan = calculateInSeconds(msgTimeSpan, msgDelayUnit);
      }

      if (msgDelay != 3) {
        const className = messageDelay.get(msgDelay).get('className');
        const dataRecord = messageDelay.get(msgDelay).get('dataRecord');
        const record_val = msgDelay === 0 ? 'delay_secs' : 'num_pv';
        let stepBlockData = new SCENARIO_STEPS[className].Record();
        stepBlockData = stepBlockData.set(
          'data',
          new dataRecord({ [record_val]: msgTimeSpan })
        );
        allRowMesages = allRowMesages.push(stepBlockData);
      }

      const className = messageConditions.get(msgDelivery).get('className');
      const dataRecord = messageConditions.get(msgDelivery).get('dataRecord');
      let stepBlockData = new SCENARIO_STEPS[className].Record();
      const recordObj = msgDelivery === 2 ? { message_id: msg, n: msgMax, t: msgRepeatSecs } : msgDelivery === 3 ? { limit: msgMax } : msgDelivery === 4 ? { message_id: msg, n: msgMax } : { message_id: msg };
      stepBlockData = stepBlockData.set(
        'data',
        msg == '-1' ? new dataRecord() : new dataRecord(recordObj)
      );
      allRowMesages = allRowMesages.push(stepBlockData);
      if (rowDataList.size > 0) {
        rowDataList = rowDataList.set(index, allRowMesages);
      } else {
        rowDataList = rowDataList.push(allRowMesages);
      }
      if (msgRepeat > 0) {
        let repeatedList = List();
        for (let i = 0; i < msgRepeat; i++) {
          repeatedList = repeatedList.push(rowDataList.get(index));
        }
        repeatedList.map((item) => {
          allRowMesages = allRowMesages.concat(item);
        });
        rowDataList = rowDataList.set(index, allRowMesages);
      }
    });

    rowDataList = rowDataList
      .map((item) => item)
      .reduce((a, b) => a.concat(b), List());

    rowData = rowData.concat(rowDataList);

    return rowData;
  };

  updateMessagesInScenario = () => {
    let messageList = this.state.allMessageData;
    let steps = this.state.scenarioEdited.get('steps');
    messageList.map((item, index) => {
      let rowData = this.combineAllMessages(item, index);
      steps = steps.setIn([index, 'rowData'], rowData);
    });
    const updated = this.state.scenarioEdited.set('steps', steps);
    return updated;
  }

  createNewScenario = () => {
    const duplicateNameScenario = this.props.scenarios.find(s => s.description === this.state.scenarioEdited.description);
    if(duplicateNameScenario && this.state.scenarioEdited.id != duplicateNameScenario.id) {
      message.warning(`Scenario with name '${this.state.scenarioEdited.description}' already exist. Please edit scenario name or update existing one from scenario's listing page.`);
      return;
    }
    let messageList = this.state.allMessageData;
    let steps = this.state.scenarioEdited.get('steps');
    let campaignTypeId = this.state.scenarioEdited.get('campaign_type_id');
    if(steps.size > 0) {
      let flag = false;
      let consentToAllIndex = [];
      let indexArray = [...this.state.stepIndex];
      if(campaignTypeId === 1) {
        steps.forEach((step,index) => {
          const conditionsCount = step.rowData.filter(s => {
              if(s?.get('data')?.get('status') == 3) {
                consentToAllIndex.push(index);
              }
              return s.category === 'condition' && (s.className === consentGateGdprV2Class || s.className === consentGateClass)
            }
            );
          if(conditionsCount.size === 0) {
            flag = true;
            if(!this.state.stepIndex.includes(index)) {
              indexArray.push(index);
              this.setState({stepIndex : [...indexArray]})
            }
          }else if(!consentToAllIndex.includes(index) && this.state.stepIndex.includes(index)) {
            const position = indexArray.indexOf(index);
            if(position !== -1) {
              indexArray.splice(position, 1);
              this.setState({stepIndex: [...indexArray], showErrorMessage: false})
            }
          }
        });
      }else if(campaignTypeId === 0) {
        let msgId;
        let conditionsCount = true;
        
        const gdprMessageArray = messageList.reduce((message, msgArray) => {
            message.push(...msgArray);
            return message;
        },[]).filter(value => value.get('msgType') === 1);
          
        steps.forEach((step,index)=>{
          step.rowData.forEach(s => {
            msgId = (s.category === 'message') && gdprMessageArray.find(val => val.get('msg') === s.data.get('message_id')); 
            if(msgId) conditionsCount = true;        
          });
          step.rowData.forEach(s => {
            if(msgId && s.category === 'condition' ) {
              if(((s.className === consentGateGdprV2Class || s.className === consentGateClass) || s.className === ConsentGateCcpaClass || s.className === ConsentGateUsnatClass)) {
                conditionsCount = false;
              }
            }
          })
          if(msgId && conditionsCount) {
            flag = true;
            if(!this.state.stepIndex.includes(index)) {
              indexArray.push(index);
              this.setState({stepIndex : [...indexArray]})
            }
          }else if(this.state.stepIndex.includes(index)) {
            const position = indexArray.indexOf(index);
            if(position !== -1) {
              indexArray.splice(position, 1);
              this.setState({stepIndex: [...indexArray], showErrorMessage: false})
            }
          }
        })
      }
      if(consentToAllIndex.length !== 0) flag = true;
      if(flag) {
        this.setState({showErrorMessage : true})
        return;
      }
    }

    if (messageList.size > 0 && steps.size > 0) {
      const updated = this.updateMessagesInScenario();
      const scenario = updated.toJS();
      let forceConditionAddRule = false;
      scenario.steps.forEach((step) => {
        const stepData = step.rowData;
        const conditionsCount = stepData.filter(s => s.category === 'condition').length;
        const msgsCount = stepData.filter(s => s.category === 'message').length; 
        if(msgsCount >= 2 && conditionsCount === 0) {
          forceConditionAddRule = true;
          return;
        }
      });
      if(forceConditionAddRule) {
        message.error("Please add at least one condition to step");
        return;
      }

      this.setState({ scenarioEdited: updated }, () => {
        this.createScenario(this.state.scenarioEdited);
      });
    } else {
      this.createScenario(this.state.scenarioEdited);
    }
  };

  updateStepIndex = (index, removeIndex = false) => {
    const currentIndexArray = this.state.stepIndex;
    if(removeIndex) {
      const position = currentIndexArray.indexOf(index);
      if(position !== -1) {
        currentIndexArray.splice(position, 1);
        this.setState({stepIndex: [...currentIndexArray], showErrorMessage: currentIndexArray.length !== 0 ? true : false})
      }
    }else if(!currentIndexArray.includes(index)) {
      currentIndexArray.push(index);
      this.setState({stepIndex: [...currentIndexArray], showErrorMessage: true});
    }
  }

  createScenario = (scenario) => {
    const siteGroupId = this.props.location.query.site_group_id;
    if (siteGroupId) {
      let action = 'created';
      const accountId = this.props.currentUser.accountId;
      let siteGroupPromise;
      if (this.props.location.query.id && !this.state.isTemplate) {
        action = 'updated';
        siteGroupPromise = new Promise((resolve) => {
          this.props.updateSiteGroupScenario(siteGroupId, accountId, scenario);
          resolve();
        });       
      } else {
        siteGroupPromise = new Promise((resolve) => {
          this.props.createUpdateSiteGroupScenario(accountId, siteGroupId, scenario);
          resolve();
        });
      }
      siteGroupPromise.then(() => {
        message.success(`Scenario '${scenario.description}' ${action} successfully`);
      });
    } else {
      if (this.props.location.query.id && !this.state.isTemplate) {
        this.props.updateScenario(scenario).then(() => message.success(`Scenario '${scenario.description}' updated successfully`));
      } else {
        this.props.createUpdateScenario(scenario).then(() => message.success(`Scenario '${scenario.description}' created successfully`));
      }
    }
    browserHistory.goBack();
  };

  confirmDeleteCondition = () => {
    let rowData = this.state.scenarioEdited
      .get('steps')
      .get(this.state.activeStep).rowData;
    rowData = rowData
      .filterNot(
        (r) =>
          r.get('className') === this.state.deleteConditionDet.get('className')
      )
      .filterNot(
        (r) =>
          r.get('className') === consentGateClass &&
          r.get('data').get('version') &&
          r.get('data').get('version') ===
            this.state.deleteConditionDet.get('version')
      );
    this.setState({ delConditionVisible: false }, () => {
      this.onChange(this.state.activeStep, rowData);
    });
  };

  addNewCondition = (id) => {
    this.setState({ activePanelId: '', buttonTitle: 'Add' }, () => {
      this.setState({ activePanelId: id });
    });
  };

  showConditionPanel = (e, className, version = '', activeStep, conditionIndex) => {
    e.stopPropagation();
    if(className === consentGateClass && version === 1) {
      return;
    }

    const id = listData
      .find(
        (sp) =>
          sp.get('className') == className ||
          (consentGateClass == className &&
            sp.get('version') &&
            sp.get('version') == version)
      )
      ?.get('id');
    this.setState({ activePanelId: '', buttonTitle: 'Update', activeStep, conditionIndex }, () => this.setState({ activePanelId: id }));
  };

  editHeaderScenario = (text = '') => {
    const updated = this.state.scenarioEdited.set('description', text);
    this.setState((prevState) => ({
      scenarioName: text,
      scenarioEdited: updated,
    }));
  };

  closeDelConditionPopup = () => {
    this.setState({ delConditionVisible: false });
  };

  toggleCloseModalErr = () => {
    this.setState((prevState) => ({ showCloseModalErr: !prevState.showCloseModalErr }));
  }

  showConditionDropdown = (index = '') => {
    this.setState({ showConditionDropdown: index, activePanelId: '' });
  }

  getConditionOptions = () => {
    const { currentUser } = this.props;
    let conditions = listData.toJS();
    const tcfFlag = 'tcf_v2';
    const ccpaFlag = 'ccpa';
    const usnatFlag = 'usnat';
    const preferencesFlag = 'preferences';
    const adblockFlag = 'adblock_v2';
    const iOSFlag = 'apple_messaging';

    const hasTcfv2 = hasFeature(currentUser, tcfFlag);
    const hasCcpa = hasFeature(currentUser, ccpaFlag);
    const hasUsnat = hasFeature(currentUser, usnatFlag);
    const hasPreferences = hasFeature(currentUser, preferencesFlag);
    const hasAdBlock = hasFeature(currentUser, adblockFlag);
    const hasiOSTracking = hasFeature(currentUser, iOSFlag);

    const campaignTypeId = this.state.scenarioEdited.campaign_type_id;

    if(!hasTcfv2 || ( campaignTypeId !== 1 && campaignTypeId !== 0)) {
	   conditions = conditions.filter(c => c.featureFlag != tcfFlag);
    }

    if(!hasCcpa || (campaignTypeId !== 2 && campaignTypeId !== 0)) {
	   conditions = conditions.filter(c => c.featureFlag != ccpaFlag);
    }

    if(!hasUsnat || (campaignTypeId !== 6 && campaignTypeId !== 0)) {
      conditions = conditions.filter(c => c.featureFlag != usnatFlag);
     }

     if(!hasPreferences || (campaignTypeId !== 7 && campaignTypeId !== 0)) {
      conditions = conditions.filter(c => c.featureFlag != preferencesFlag);
     }

    if(!hasAdBlock || (campaignTypeId !== 3 && campaignTypeId !== 0)) {
	   conditions = conditions.filter(c => c.featureFlag != adblockFlag);
    }

    if(!hasiOSTracking || (campaignTypeId !== 4 && campaignTypeId !== 0)) {
	   conditions = conditions.filter(c => c.featureFlag != iOSFlag);
    }

    return conditions;
  }

  renderConditionDropDownAndPanel = (index) => {
    const showConditionAddPanel = this.state.showConditionDropdown === index;

    const conditions = this.getConditionOptions();
    const conditionsDropdown = showConditionAddPanel && <div className="condition-dropdown-container">
        <label className="label-style">Conditions</label>
        <Select placeholder="Select" onChange={(value) => this.addNewCondition(value)}>
          {conditions.map((c, ci) => <Option key={ci} value={c.id}>{c.title}</Option> )}
        </Select>
      </div>;

    return <div className="add-condition-block">
        { conditionsDropdown }
        { this.state.activeStep === index && this.state.activePanelId ? <ConditionCommonWrapper> {this.renderCondition(this.state.activePanelId)} </ConditionCommonWrapper> : null }
      </div>;
  }

  preventPanelToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  reorderSteps = (list, startIndex, endIndex) => {
    let result = list;
    const splicedList = result.splice(startIndex, 1);
    result = splicedList.splice(endIndex, 0, list.get(startIndex));
    return result;
  };

  sortSteps = ({ oldIndex, newIndex }) => {
    if(oldIndex === newIndex) {
      return;
    }

    const allMessageData = this.reorderSteps(this.state.allMessageData, oldIndex, newIndex);
    const steps = this.reorderSteps(this.state.scenarioEdited.steps, oldIndex, newIndex);
    const updatedScenario = this.state.scenarioEdited.set('steps', steps);
    this.setState({ allMessageData, scenarioEdited: updatedScenario, showConditionDropdown: '', activePanelId: '' }, () => {
      const updated = this.updateMessagesInScenario();
      this.setState({ scenarioEdited: updated });
    });
  };

  render() {

    let loading;
    if (this.props.pendingRequestsMap.some(request => request) || this.state.msgsLoading) {
      loading = <Loading />;
    }

    const site = this.props.site ? this.props.site: '';
    const isMultiCampaignEnabled = site ? site.multiCampaignEnabled : false;

    const addStep = (
      <div className="add-step-wrapper">
        <Button id='add-step' type='primary' onClick={this.addStep}>
          <PlusCircleOutlined /> Add Step
        </Button>
      </div>
    );
    const deleteConditionPopup = (
      <Popconfirm
        title={`Condition will be deleted`}
        okText="YES"
        visible={this.state.delConditionVisible}
        cancelText="NO"
        onCancel={this.closeDelConditionPopup}
        onConfirm={this.confirmDeleteCondition}
      ></Popconfirm>
    );

    const closeModalErrorMsg = <React.Fragment><b>Are you sure you want to close the scenario builder?</b><br/>Some of the steps in this scenario have been updated. Clicking on "Yes" will ignore the changes and close the scenario builder.</React.Fragment>
    const closeModalErr = this.state.showCloseModalErr && <DismissErrorModal
      renderOk
      okText="Yes"
      cancelText="No"
      title="Confirmation"
      error={closeModalErrorMsg}
      isModalVisible={this.state.showCloseModalErr}
      primaryAction="cancel"
      handleOk={this.goBack}
      handleCancel={this.toggleCloseModalErr}/>;
    const showInitialMessage = (this.state.scenarioEdited.campaign_type_id === 1 && !(this.state.isTemplate === false || this.state.isTemplate === true))
    return (
      <div className="scenario-v2">
        <div className="template-edit-page">
          <React.Fragment>
            {deleteConditionPopup}
            <TemplateHeader
              scenarioName={this.state.scenarioEdited.description}
              campaignType={this.state.scenarioEdited.campaign_type_id}
              onClose={this.close}
              createNewScenario={this.createNewScenario}
              actionButtonText={
                this.props.location.query.id && !this.state.isTemplate
                  ? 'Update'
                  : 'Create'
              }
              editHeaderScenario={this.editHeaderScenario}
              isMultiCampaignEnabled={isMultiCampaignEnabled}
              showErrorMessage={this.state.showErrorMessage}
            />
            {showInitialMessage && <Alert
                className='initial-alert-message'
                message={<>Don't forget to add a <strong>Consent Status</strong> condition to each step in this scenario.</>}
                type="info"
                showIcon
                closable
                closeIcon={<CloseCircleFilled />}
            />}
              <Layout style={styles.stepLayout} className="custom-layout">
                <Layout style={styles.stepInnerLayout}>
                  <Content style={styles.stepContent}>
                    <CollapseSortableContainer
                      activeKeys={this.state.activeKeys}
                      scenarioEdited={this.state.scenarioEdited}
                      copyStep={this.copyStep}
                      preventPanelToggle={this.preventPanelToggle}
                      deleteStep={this.deleteStep}
                      updateActiveStep={this.updateActiveStep}
                      setActiveKey={this.setActiveKey}
                      showConditionPanel={this.showConditionPanel}
                      onChange={this.onChange}
                      showConditionDropdown={this.showConditionDropdown}
                      showConditionDropdownState={this.state.showConditionDropdown}
                      activeStep={this.state.activeStep}
                      renderConditionDropDownAndPanel={this.renderConditionDropDownAndPanel}
                      messages={this.state.messages}
                      onMessageListChange={this.onMessageListChange}
                      onSortEnd={this.sortSteps}
                      pressDelay={250}
                      helperClass='sortableHelper'
                      stepIndex={this.state.stepIndex}
                      showErrorMessage={this.state.showErrorMessage}
                      updateStepIndex={this.updateStepIndex}
                      isTemplate={this.state.isTemplate}
                    />
                    {addStep}
                  </Content>
                </Layout>
              </Layout>
          </React.Fragment>
          { closeModalErr }
          { loading }
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(store) {
  const siteId = parseInt(getParameterByName('site_id', window.location));
  const site = store.siteState.getIn(['sites', 'value']).find((s) => s.id === parseInt(siteId));

  const isMultiCampaignEnabled = site ? site.multiCampaignEnabled : false;

  const campaignTypeId = parseInt(getParameterByName('campaign_type_id', window.location)) || 0;

  const scenarios = store.scenarioState
    .getIn(['scenarios', 'value'])
    .filter((s) => s.status === STATUS.DRAFT && s.site_id === siteId);
  const scenarioId = getParameterByName('id', window.location);
  let scenarioEdited = new ScenarioV2({
    description: 'New Scenario',
    site_id: siteId,
    schema_version: 2,
    steps: newRow(),
    campaign_type_id: campaignTypeId,
  });
  if (window.location.pathname === '/dialogue/scenarios/edit') {
    const preDefinedTemplate = JSON.parse(
      getParameterByName('template', window.location).toLowerCase()
    );
    if (scenarioId && scenarios.size && !preDefinedTemplate) {
      scenarioEdited = scenarios.find((sl) => sl.id === parseInt(scenarioId));
      if (scenarioEdited && scenarioEdited.steps.size == 0) {
        let steps = scenarioEdited.get('steps').push(new ScenarioRowRecord());
        let stepBlockData = new SCENARIO_STEPS[
          'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways'
        ].Record();
        stepBlockData = stepBlockData.set('data', new MessageIdRecord());
        const rowData = steps.get(steps.size - 1).rowData.push(stepBlockData);
        steps = steps.setIn([steps.size - 1, 'rowData'], rowData);
        scenarioEdited = scenarioEdited.set('steps', steps);
      }
    } else if (preDefinedTemplate) {
      let data = dataTemplates.find((item) => item.get('key') === scenarioId);
      let title = data.get('type');
      let stepData = data.get('stepList');
      let stepList = populateTemplates(stepData);
      scenarioEdited = new ScenarioV2({
        id: scenarioId,
        description: title,
        site_id: siteId,
        schema_version: 2,
        steps: stepList,
        campaign_type_id: isMultiCampaignEnabled ? data.get('campaignType') : 0, 
      });
    }
  }
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    scenarioEdited,
    scenarioData: store.scenariosState.getIn(['scenarioData', 'value']),
    accountId: store.accountState.getIn(['accountId', 'value']),
    isSPUser: store.accountState.get('isSPUser'),
    scenarios,
    site,
    pendingRequestsMap: Map({
      scenarios: store.scenarioState.getIn(['scenarios', 'pending']),
    }),
  };
};

export default connect(mapStateToProps, {
  createScenario,
  createUpdateScenario,
  getDraftMessages,
  getDraftScenarios,
  updateScenario,
  createSiteGroupScenario,
  createUpdateSiteGroupScenario,
  updateSiteGroupScenario,
  getAllSites,
})(TemplatePage);

function newRow(){
  let steps = List([new ScenarioRowRecord()]);
  let stepBlockData = new SCENARIO_STEPS['com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways'].Record();
  stepBlockData = stepBlockData.set('data', new MessageIdRecord());
  const rowData = steps.get(0).rowData.push(stepBlockData);
  return steps.setIn([0, 'rowData'], rowData);
};