import { Record, List } from 'immutable';


export const SubjectAccessRecord = Record({
  accountId: null,
  siteId: null,
  type: null, // one of DSAR, DELETE
  requestId: null,
  responseFileStatus: 'INITIAL', // INITIAL, FILE_UPLOADED, FILE_DELIVERED
  phoneNumber: null,
  dateCreated: null,
  mfaCode: null,
  mfaVerificationAttempts: null,
  file: '',
});