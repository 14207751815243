import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';
import { TYPES, SUBTYPE } from '../../../../constants.js';

export default class extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    type: PropTypes.oneOf([
      TYPES.LOCK,
      TYPES.NOTICE,
      TYPES.REDIRECT,
      TYPES.CUSTOM,
    ]).isRequired,
    subType: PropTypes.oneOf([
      SUBTYPE.MODAL,
      SUBTYPE.INLINE,
      SUBTYPE.TOP,
      SUBTYPE.BOTTOM,
      TYPES.REDIRECT,
      SUBTYPE.HTML,
    ]).isRequired,
    onClick: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
  };

  render() {
    let className = 'positions';
    if (this.props.active) {
      className += ' active';
      className.trim();
    }

    if (this.props.editing) {
      className += ' editing';
      className.trim();
    }

    let active = this.props.active ? 'active' : 'default';
    const imgSrc = '/images/messaging/' + this.props.subType + '-' + active + '.svg';
    const type = this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1);
    const subType = this.props.subType.charAt(0).toUpperCase() + this.props.subType.slice(1);
    const label = this.props.type === TYPES.REDIRECT ? 'Redirect' : type + ' ' + subType;

    return (
      <li className='type-pos-tiles-tile' onClick={ this.props.editing ? this.props.onClick : null }>
        <Button disabled={ !this.props.editing } className={ className }>
          <img src={ imgSrc } />
          <h5>{ label }</h5>
        </Button>
      </li>
    );
  }
}
