/* eslint-disable import/prefer-default-export */
/* eslint-disable no-alert */
import { browserHistory } from 'react-router';

function debounce(timer) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timer);
  });
}

export function fetchRetryWrapper(url, config, outerRetryCount = null) {
  let retryCount = outerRetryCount || 0;
  // eslint-disable-next-line consistent-return
  async function checkStatus(response) {
    if (response.status >= 200 && response.status < 300 && response.status !== 202) {
      return response;
      // Request Manager implementation
    } else if (response.status === 202) {
      const serverDelay = response?.headers?.['Retry-After'] || 5000;
      const retryTimes = 30000 /* 30 sec */ / serverDelay;
      if (retryCount < retryTimes) {
        await debounce(serverDelay);
        // eslint-disable-next-line no-plusplus
        return fetchRetryWrapper(url, config, ++retryCount);
      }
      alert('Your query has timed out. Please try again later. Consider revisiting your criteria by choosing a smaller date range, fewer websites, and fewer regions.');
      return response;
    } else if (response.status === 418 && window.location.pathname !== '/users/sign_in') {
      const signInAndRedirect = `/users/sign_in?redirect_to=${encodeURIComponent(window.location.pathname + window.location.search)}`;
      browserHistory.push(signInAndRedirect);
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  return fetch(
      url,
      config,
  )
  .then(checkStatus)
  .catch((error) => {
    console.log('Error:', error);
    throw error;
  });
}
