import * as types from './action_types';
import { actionCreator } from './helpers';
import { getAndParseDataRequestData } from '../api/ccpa/data_request';


export const getDataRequestData = actionCreator(
    getAndParseDataRequestData,
    dataRequestPending,
    dataRequestLoaded,
    dataRequestError,
    dataRequestCached
);

function dataRequestPending() {
    return {
      type: types.DATA_REQUEST_CCPA_PENDING,
    };
}

function dataRequestLoaded(data) {
    return {
        type: types.DATA_REQUEST_CCPA_LOADED,
        data,
    };
}

function dataRequestError(error) {
    return {
        type: types.DATA_REQUEST_CCPA_ERROR,
        error,
    };
}

function dataRequestCached() {
    return {
      type: types.DATA_REQUEST_CCPA_CACHED,
    };
}