import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Button } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { VendorList } from '../../../../records/vendor_list_records';
import { Site } from '../../../../records/site_records';
import SelectSitesAndSiteGroups from '../../../common/SelectSitesAndSiteGroups';

export default class ManageSites extends React.Component {
  static propTypes = {
    vendorScanning: PropTypes.bool.isRequired,
    siteAccess: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    vl: PropTypes.instanceOf(VendorList).isRequired,
    toggleScanVendors: PropTypes.func.isRequired,
    handleSelectSite: PropTypes.func.isRequired,
    removeSiteByName: PropTypes.func.isRequired,
    toggleAdvancedSettings: PropTypes.func.isRequired,
  };

  render() {

    let sitesValue = this.props.vl.siteIds;
    if (this.props.siteAccess) {
      sitesValue = sitesValue.filter(id => this.props.siteAccess.includes(id));
    }
    sitesValue = sitesValue.map(id => this.props.sites.find(s => s.id === id ).domain);

    const selectSites = (
      <div className='select-sites-container'>
        <SelectSitesAndSiteGroups
          value={ this.props.vl.siteIds }
          sites={ this.props.sites }
          siteGroups={ this.props.siteGroups }
          onSelect={ this.props.handleSelectSite }
          multiple
          disabled={ this.props.readOnly }
          siteAccess={ this.props.siteAccess }
        />
      </div>
    );

    return (
      <div className="vendor-sites">
        <div className="top-of-the-table-container">
          { selectSites }
        </div>
        <div className="vendor-site-list">
          <span>Properties this list applies to: </span>
          { sitesValue.map(s => {
            return <Tag key={ 'tag-' + s } color="#E0EDFE" closable={ !this.props.readOnly } onClose={()=>this.props.removeSiteByName(s)}>{s}</Tag>;
          })}
        </div>
      </div>
    );
  }
}
