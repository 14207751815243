import React from 'react';
import { Select } from '../../../../styleguide';
import { GEO_FILTER_LIST_DETAILS, LEGACY_EU_COUNTRY_CODES } from '../../../../constants';

const geoFilter = GEO_FILTER_LIST_DETAILS
  .map(geo => ({
    ...geo,
    region: typeof (geo.region) === 'string' ? [geo.region] : geo.region
  }))
  .filter(({ region, code }) => code === 'US' || region.includes('EEA') || LEGACY_EU_COUNTRY_CODES.includes(code)).toJS();

function makeCodesArray(regionCode, codes) {
  const updatedCodes = [];
  updatedCodes.push(...geoFilter.filter(({ region }) => region.includes(regionCode)).map(({ code }) => code));
  updatedCodes.push(...(codes
    .filter(code => code !== regionCode)
    .filter(code => !geoFilter.find(geo => geo.code === code).region.includes(regionCode))
  ));

  return updatedCodes;
}

const GeoTargetingSelect = ({
  geoTargeting,
  geoTargeting: { ruleBoolean, geoList },
  updateGeoTargeting,
  readOnly
}) => {
  const updateCodes = (codes) => {
    let updatedCodes;
    if (codes.includes('EEA')) {
      updatedCodes = makeCodesArray('EEA', codes);
    } else {
      updatedCodes = codes
    };

    updateGeoTargeting({ ...geoTargeting, geoList: updatedCodes.sort((a, b) => a > b ? 1 : -1) });
  }

  return (
    <div className="geotargeting-container">
      <label className="title">
        Geolocation
      </label>
      <div className="geotargeting-select-container">
        <Select
          className="geotargeting-bool"
          value={ruleBoolean}
          onChange={value => updateGeoTargeting({ ...geoTargeting, ruleBoolean: value })}
          disabled={readOnly}
        >
          <Select.Option value={true}>
            IN
          </Select.Option>
          <Select.Option value={false}>
            NOT IN
          </Select.Option>
        </Select>
        <Select
          className="geotargeting-list"
          mode="multiple"
          value={geoList}
          onChange={updateCodes}
          filterOption
          optionFilterProp="searchValue"
          disabled={readOnly}
        >
          <Select.OptGroup label="Regions">
            <Select.Option value="EEA" searchValue="EEA">
              EEA
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Countries">
            {geoFilter.map((option) => (
              <Select.Option value={option.code} searchValue={option.name}>
                {option.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>
      </div>
    </div>
  );
};

export default GeoTargetingSelect;