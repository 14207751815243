import moment from 'moment';

export const defaultWeekStart =
  moment().day() == 1
    ? moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
    : moment().startOf('isoWeek');

export const defaultWeekEnd =
  moment().day() == 1
    ? moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
    : moment().diff(moment().endOf('isoWeek')) < 0
    ? moment().subtract(1, 'days')
    : moment().endOf('isoWeek');

export const defaultMonthStart = moment().startOf('month');

export const defaultMonthEnd =
  moment().diff(moment().endOf('month')) < 0
    ? moment().subtract(1, 'days')
    : moment().endOf('month');

export const defaultQuarterStart = moment(new Date().getFullYear(), 'YYYY')
  .quarter(moment().quarter())
  .startOf('quarter');

export const defaultQuarterEnd =
  moment().quarter() ==
  moment()
    .subtract(1, 'days')
    .quarter()
    ? moment().subtract(1, 'days')
    : moment();
