import React from 'react';
import { Link } from 'react-router';

export default class extends React.Component{
  render() {
    return (
      <div className="dialogue subnav">
        <ul className="list-inline">
          <li><Link to="/sdks/sdk_lists" activeClassName="active" >SDKs</Link></li>
          <li><Link to="/sdks/#help" activeClassName="active" ></Link>
          <a href='http://help.sourcepoint.com/sdks' id='help'>Help</a>
          </li>
        </ul>
      </div>
    );
  }


}
