import React, { Component } from 'react';
import { Collapse } from 'antd';
import { CustomHeader } from './CustomHeader';
const { Panel } = Collapse;

export const CustomPanel = (props) => {
  const {
    children,
    header,
    onPanelClick,
    onClick,
    index,
    activeStep,
    collapseOpen,
    headerConditions,
    conditionPanel,
    stepIndex,
    ...res
  } = props;
  const customHeader = (
    <CustomHeader
      onPanelClick={onPanelClick}
      collapseOpen={collapseOpen}
      onClick={onClick}
      headerConditions={headerConditions}
      conditionPanel={conditionPanel}
    >
      {header}
    </CustomHeader>
  );
  const flag = (stepIndex.length) ? stepIndex.includes(index) : false;
  return (
    <Panel
      {...res}
      key={index.toString()}
      header={customHeader}
      className={`step-${index} ${activeStep == index ? 'active' : ''}`}
      forceRender={true}
      style={{borderColor: (flag) ? "#FF2626": "#D4D4D4"}}
    >
      {children}
    </Panel>
  );
};
