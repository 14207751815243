import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { User } from '../../records/account_records';
import { Button, ConfigProvider, Input, message, Popover, Table } from 'antd';
import CustomIcon from '../common/CustomIcon.js.jsx';
import enUS from 'antd/lib/locale/en_US.js';
import { updateAccount } from '../../api/admin_consent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimesCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

export class ZendeskOrganizations extends React.Component {
    static propTypes = {
        accountOwners: ImmutablePropTypes.listOf(PropTypes.instanceOf(User)).isRequired,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.clients.size && nextProps.accountOwners.size) {
            const newClients = nextProps.accountOwners;
            return {
                clients: newClients,
            };
        } else {
            return null
        }
    }

    state = {
        searchText: '',
        clients: this.props.accountOwners,
    };

    reduceDataByColumn = (column) => {
        const { searchText } = this.state;
        let reg;
        try {
            reg = new RegExp(searchText, 'gi');
        } catch (e) {
            console.log(e);
            message.error('Invalid search parameters');
        }

        return this.state.clients.toJS()
            .map((record) => {
                const match = record[column].match(reg);
                if (!match) {
                    return null;
                }
                return {
                    ...record,
                    name: record[column],
                };
            }).filter(record => !!record);
    }

    handleAlphabeticalSort(a, b, key) {
        const first = a[key].toUpperCase();
        const second = b[key].toUpperCase();

        if (first < second) return -1;
        if (first > second) return 1;
        return 0;
    }

    handleNumberSort = (a, b, key) => {
        return parseFloat(a[key]) - parseFloat(b[key]);
    };

    onInputChangeSearch = (e) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    onClear = () => {
        this.setState({
            searchText: '',
            data: this.props.accountOwners,
        });
    }

    handleSave = () => {
        const index = this.state.clients.findIndex(client => client.get('accountId') === this.state.editingAccountId);
        const clients = this.state.clients.setIn([index, 'zendeskOrganization'], this.state.zendeskOrganizationName);

        updateAccount(this.state.editingAccountId, null, this.state.zendeskOrganizationName);
        message.success('Organization name has been updated and saved');

        this.setState({ clients, rowEditingIndex: null, zendeskOrganizationName: null })
    }

    render() {
        if (!this.props.currentUser) { return null; };

        if (this.props.currentUser.accountId !== 22 && this.props.currentUser.accountId !== 808) {
            browserHistory.replace('/');
        }

        const OwnerColumn = (users, acctObj, index) => {
            return (<React.Fragment>
                {(this.state.rowEditingIndex !== index)
                    ? (
                        <div className="owner-cell-container">
                            <div>
                                {acctObj.zendeskOrganization}
                            </div>
                            <CustomIcon onClick={() => { this.setState({ rowEditingIndex: index, editingAccountId: acctObj.accountId, zendeskOrganizationName: acctObj.zendeskOrganization }) }} />
                        </div>
                    )
                    : (
                        <div className="owner-cell-container">
                            <Input value={this.state.zendeskOrganizationName} onChange={(e) => { this.setState({ zendeskOrganizationName: e.target.value, zendeskOrganizationId: acctObj.accountId }) }} />
                            <div className="save-cancel-icons-holder">
                                <div onClick={() => this.handleSave()}>
                                    <FontAwesomeIcon size="lg" icon={faCheck} color="green" />
                                </div>
                                <div className="icon" onClick={() => this.setState({ rowEditingIndex: null, zendeskOrganizationName: null })}>
                                    <FontAwesomeIcon size="lg" icon={faTimesCircle} color="red" />
                                </div>
                            </div>
                        </div>
                    )}
            </React.Fragment>)
        }

        let table;
        if (this.state.clients) {
            const columns = [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    fixed: 'left',
                    width: 200,
                    sorter: (a, b) => this.handleAlphabeticalSort(a, b, 'name'),
                },
                {
                    title: 'Account ID',
                    dataIndex: 'accountId',
                    key: 'accountId',
                    fixed: 'left',
                    width: 130,
                    sorter: (a, b) => this.handleNumberSort(a, b, 'accountId'),
                },
                {
                    title: (
                        <React.Fragment>
                            Zendesk Organization External ID&nbsp;&nbsp;
                            <Popover content="Populate this field with the account's SalesForce ID">
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </Popover>
                        </React.Fragment>
                    ),
                    dataIndex: 'organization',
                    key: 'organization',
                    render: OwnerColumn
                }
            ];

            const data = this.reduceDataByColumn('accountName');

            table = (
                <ConfigProvider locale={enUS}>
                    <div class="zendesk-organization-container">
                        <Table
                            className='features-table'
                            scroll={{ x: 'max-content' }}
                            dataSource={data}
                            columns={columns}
                            pagination={30}
                            onChange={this.onClick}
                        />
                    </div>
                </ConfigProvider>
            );
        }
        return (
            <div>
                <div className='admin-settings-page'>
                    <div className="search-container">
                        <Input
                            className='search-bar'
                            ref={el => this.searchInput = el}
                            placeholder="Search"
                            value={this.state.searchText}
                            onChange={this.onInputChangeSearch}
                        />
                        <Button
                            className='clear-button'
                            type="primary"
                            onClick={this.onClear}>
                            Clear
                        </Button>
                    </div>
                    {table}
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (store) {
    return {
      currentUser: store.accountState.getIn(['userDetails', 'value']),
    };
  };
  
export default connect(mapStateToProps)(ZendeskOrganizations);

