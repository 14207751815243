import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { useActions } from '../../../hooks';

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List } from 'immutable';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Input } from 'antd';
import { browserHistory } from 'react-router';
import CreateButton from '../../common/CreateButton.js.jsx';
import GoldenScoreTabs from './GoldenScoreTabs';
import { 
  getGoldenSets as getGoldenSetsAction,
  deleteGoldenSet as deleteGoldenSetAction,
} from '../../../actions/golden_set_actions';

const Search = Input.Search;

function ManageGoldenSet(props) {
  const deleteGoldenSet = useActions(deleteGoldenSetAction);

  const [search, setSearch] = useState('');

  let goldenSets = useSelector(state => state.goldenSetState.getIn(['goldenSets', 'value']));

  const generateTable = () => {

    const columns = List([
      Map({
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '100px',
      }),
      Map({
        title: 'Policy',
        dataIndex: 'policy',
        key: 'policy',
        width: '100px',
      }),
      Map({
        title: 'Created On',
        dataIndex: 'createdOn',
        key: 'createdOn',
      }),
      Map({
        title: 'Last Updated',
        dataIndex: 'lastUpdated',
        key: 'lastUpdated',
      }),
      Map({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: '10px',
      }),
    ]);

    if (search) {
      goldenSets = goldenSets.filter(gs => gs.name.toLowerCase().includes(search.toLowerCase()));
    }

    goldenSets = goldenSets.sort((a, b) => {
      if (new Date(b.dateUpdated) < new Date(a.dateUpdated)) {
        return -1;
      } else {
        return 0;
      }
    });

    const dataSource = goldenSets.map((gs) => {

      const deleteIcon = (
        <DeleteOutlined onClick={ () => deleteGoldenSet(gs.id, props.currentUser.get('accountId')) } />
      );
      const editIcon = (
        <EditOutlined onClick={ () => { browserHistory.push(`/privacy_lens/golden_score/golden_set?id=${gs.id}`); } } />
      );

      return Map({
        key: gs.id,
        name: gs.name,
        policy: gs.policy,
        createdOn: moment(gs.dateCreated).format("LLL"),
        lastUpdated: moment(gs.dateUpdated).format("LLL"),
        actions: (<div className='actions'>
          { editIcon }
          { deleteIcon }
        </div>),
      });
    });

    return new Map({ dataSource, columns })
  };

  const createButton = (
    <CreateButton onClick={() => { browserHistory.push('/privacy_lens/golden_score/golden_set')}} >
      + Add New Golden Set
    </CreateButton>
  );
  
  const tableData = generateTable();


  return (
    <div className='domain-sets-container privacy-lens golden-set'>
      <GoldenScoreTabs />

      { createButton }

      <p className='title'>Golden Sets</p>

      <div className='table-box-shadow'>
        <div className='search-container'>
          <Search
            placeholder="Search Golden Set"
            onChange={ (e) => { setSearch(e.target.value) } }
            className="vendor-filter"
            style={{ width: 190 }}
          />
        </div>

        <Table
          bordered={ false }
          dataSource={ tableData.get('dataSource').toJS() }
          columns={ tableData.get('columns').toJS() }
          className='golden-sets-table'
        />
      </div>
    </div>
  );
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
  };
};

export default connect(mapStateToProps)(ManageGoldenSet);