import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { Purpose } from '../../../../../records/vendor_list_records';

const Option = Select.Option;

export default class TagSelect extends React.Component {
  static propTypes = {
    readOnly: PropTypes.bool.isRequired,
    vendorId: PropTypes.string.isRequired,
    purpose: PropTypes.instanceOf(Purpose).isRequired,
    purposeIndex: PropTypes.number.isRequired,
    updateCustomVendor: PropTypes.func.isRequired,
  };
  handleChange = (value) => {
    this.props.updateCustomVendor(value, this.props.purpose.id, this.props.vendorId, this.props.purposeIndex);
  };
  render() {

    const consentVendors = this.props.purpose.get('vendorIds').toJS();
    const legitimateVendors = this.props.purpose.get('customVendorsRelyingOnLegInt').toJS();
    const vendorId = this.props.vendorId;

    let vendorValue = '';
    if (consentVendors.indexOf(vendorId) !== -1) vendorValue = 'consent';
    if (legitimateVendors.indexOf(vendorId) !== -1) vendorValue = 'legitimate';

    return (
      <Select
        className="vlist-li-select"
        defaultValue=""
        value={ vendorValue }
        style={{ width: '100%' }}
        onChange={ this.handleChange }
        disabled={ this.props.readOnly }
      >
        <Option value="consent">
          <span className="li-tag li-consent">User Consent</span>
        </Option>
        <Option value="">
          <span className="li-tag li-applicable">Not Applicable</span>
        </Option>
      </Select>
    );
  }
}

{/* <Option value="legitimate">
<span className="li-tag li-interest">Legitimate Interest</span>
</Option> */}
