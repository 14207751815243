import React, { Component } from 'react';
import { Button } from 'antd';
import { CheckOutlined } from "@ant-design/icons";
import { propertyTableCSV } from '../scoring_results/CSV/generateCSV';
import { getDomainsWithScores } from '../../../api/consent_quality/domain_set';
import { message } from '../../../styleguide'
//import { getStandardScores } from '../../../actions/domain_score_actions';

export default class PropertyTable extends Component {
  state = {
    showSuccessMessage: 'hidden'
  }

  getDomainScores = async () => {
    const domains = await getDomainsWithScores(this.props.propertySet.id || this.props.propertySet._id);
    const rules = this.props.propertySet.standard.rules;

    const domainsForCSV = domains.map((d) => {
      const domainInfo = {...d};

      const ruleScores = d.ruleScores || {};
      let passedCount = 0;
      Object.keys(ruleScores).forEach(key => {
        if (ruleScores[key] === 1) passedCount++;
      });

      /* HANDLE GENERAL INFO */
      domainInfo.score = d.overAllScore || 0;
      domainInfo.scored = d.scored || false;
      domainInfo.dateScanned = d.lastScanned;
      domainInfo.rulesPassed = passedCount;

      /* HANDLE RULES */
      domainInfo.rules = {};
      rules.forEach(r => {
        domainInfo.rules[r.rule.ruleId] = ruleScores[r.rule.ruleId];
      });

      return domainInfo;
    });
   return domainsForCSV
  }

  downloadScoringResults = () => {
    this.getDomainScores().then((domains)=>{
      if(domains) {
        propertyTableCSV(domains, this.props.propertySet.standard.rules, this.props.propertySet.name)
        this.setState({showSuccessMessage: 'visible'})
      }
    }).catch(()=>{
      message.error("Something went wrong")
    });
  }
    
  setFilter = filter => this.setState({ filter })
  updateSearch = (e) => this.setState({ search: e.target.value });
  render() {
    return (
      <div className='download-scoring-result-container'>
        <div className="success-alert" style={{visibility : this.state.showSuccessMessage}}>
          <CheckOutlined />
          <span className="success-text">Successfully Downloaded</span>
        </div>
        <Button onClick={this.downloadScoringResults}>
          Download Scoring Results <span>(.csv)</span>
        </Button>
      </div>

    );
  }
}
