import 'whatwg-fetch';
import { List } from 'immutable';
import { checkStatus } from '../helper.js';


const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;
const getConsentBaseUrl = () => {
  return `${permissionsSvcBaseUrl}/tcfv2`;
}

export function getPublisherCmpIdAjax() {
  const url = `/cmp-id`;

  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => resp.publisherCmpId2 || '');
}

export function getPublishersCmpIdsAjax() {
  const url = `/cmp-id/list`;

  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => List(resp));
}

export function createPublisherCmpIdAjax(accountId, publisherCmpId2) {
    const url = '/cmp-id';
    const reqBody = { accountId: accountId.toString(), publisherCmpId2: publisherCmpId2.toString() };

    return fetch(getConsentBaseUrl() + url, {
              method: 'POST',
              body: JSON.stringify(reqBody),
              credentials: 'include',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(checkStatus)
            .then((resp) => resp.json())
            .then((resp) => resp);
}

export function deletePublisherCmpIdAjax(accountId, id) {
   const url = '/cmp-id';
   const reqBody = {  _id: id.toString(), accountId: accountId.toString() };

   return fetch(getConsentBaseUrl() + url, {
      method: 'DELETE',
      body: JSON.stringify(reqBody),
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(() => id);
}

export function updatePublisherCmpIdAjax(id, accountId, publisherCmpId2) {
    const url = '/cmp-id';
    const reqBody = { _id: id, accountId: accountId.toString(), publisherCmpId2: publisherCmpId2.toString() };

    return fetch(getConsentBaseUrl() + url, {
            method: 'PUT',
            body: JSON.stringify(reqBody),
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            })
            .then(checkStatus)
            .then((resp) => resp.json())
            .then((resp) => resp);
}