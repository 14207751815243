import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  Input,
  Button,
  Alert,
} from 'antd';
import { Map } from 'immutable';
import { getUserDetails } from '../../actions/account_actions';
import { validateEmail, getParameterByName } from '../utils';
import { User } from '../../records/account_records';

const permissionsSvcBase = process.env.REACT_APP_PERMISSIONS_API_URL;

class SignUp extends React.Component {
  state = {
    password: '',
    passwordConfirmation: '',
    passwordErrors: [],
  };

  handleInputChange = (e, prop) => {
    let stateUpdate = new Map();
    stateUpdate = stateUpdate.set(prop, e.target.value);
    this.setState(stateUpdate.toJS());
  }

  validatePasswords = () => {
    const { password, passwordConfirmation } = this.state

    const errors = []
    if (password !== passwordConfirmation) {
      errors.push('Passwords do not match')
    }
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters')
    }
    if (password.length > 100) {
      errors.push('Password must no more than 100 characters')
    }
    if (!(/[a-z]/.test(password))) {
      errors.push('Password must contain at least 1 lower case character')
    }
    if (!(/[A-Z]/.test(password))) {
      errors.push('Password must contain at least 1 upper case character')
    }
    if (!(/\d/.test(password))) {
      errors.push('Password must contain at least 1 number')
    }
    if (/\s/g.test(password)) {
      errors.push('Password can not include spaces')
    }

    return errors
  }

  signUp = () => {
    const token = getParameterByName('token', window.location);

    this.setState({ passwordErrors: [] })
    const errors = this.validatePasswords()

    if (errors.length) {
      return this.setState({ passwordErrors: errors })
    }

    fetch(`${permissionsSvcBase}/authentication/sign-up?token=${token}`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password: this.state.password }),
    }).then(async r => {
      if (r.status === 200) {
        browserHistory.push('/users/sign_in');
      } else if(r.status === 418) {
        this.setState({ passwordErrors: ["Invite has expired, please request another invite."] })
      }
    });
  }

  render() {
    const { passwordErrors } = this.state

    let alert;
    if (this.props.createdUserCredsError) {
      alert = (
        <Alert
          message={this.props.createdUserCredsError}
          type="error"
          onClose={() => this.setState({ emailValidationError: '' })}
        />
      );
    }

    let alert2;
    if (this.state.emailValidationError) {
      alert2 = (
        <Alert
          message={this.state.emailValidationError}
          type="error"
          onClose={() => this.setState({ emailValidationError: '' })}
        />
      );
    }

    const text = 'You will receive an email with instructions on how to reset your password in a few minutes.'

    return (
      <div className='auth-container'>
        <div className='sign-in-user-container'>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
            <img style={{ width: 200 }} src="/images/app-logo-large.png" />
          </div>
          <p>Select a password to complete your registration</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: 600 }}>
              {this.state.passwordErrors.map((err, i) => (
                <Alert
                  key={err}
                  style={{ marginBottom: 5 }}
                  showIcon
                  closable
                  message={err}
                  type="error"
                  onClose={() => {
                    this.setState({
                      passwordErrors: [...this.state.passwordErrors.slice(0, i), ...this.state.passwordErrors.slice(i + 1)]
                    })
                  }}
                />
              ))}
            </div>
          </div>
          <Input
            style={{ marginBottom: 10 }}
            type="password"
            placeholder="Password"
            value={this.state.password}
            defaultValue='password'
            onChange={(e) => this.handleInputChange(e, 'password')}
          />
          <Input
            style={{ marginBottom: 10 }}
            type="password"
            placeholder="Confirm Password"
            value={this.state.passwordConfirmation}
            defaultValue='password'
            onChange={(e) => this.handleInputChange(e, 'passwordConfirmation')}
          />
          <Button
            onClick={this.signUp}
            type="primary"
          >
            Accept Invitation
          </Button>
        </div>
        <div style={{
          width: '100%',
          height: 75,
          position: 'absolute',
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20
        }}>
          <div style={{ marginBottom: 10 }}>
            © {new Date().getFullYear()} Sourcepoint Technologies Inc
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: 100, textAlign: 'center' }}>
              <a href="http://sourcepoint.com/about-us">
                About Us
              </a>
            </div>
            <div style={{ width: 100, textAlign: 'center' }}>
              <a href="http://sourcepoint.com/privacy-policy">
                Privacy Policy
              </a>
            </div>
            <div style={{ width: 100, textAlign: 'center' }}>
              <a href="http://sourcepoint.com/terms-of-use">
                Terms Of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
  };
};

export default connect(
  mapStateToProps, {
  getUserDetails,
},
)(SignUp);
