import PropTypes from 'prop-types';
import React from 'react'

export default class extends React.Component {
    static propTypes = {
        labelClass: PropTypes.string,
        text: PropTypes.string
    };

    render() {
        var classNames = "label label-" + this.props.labelClass;
        return (<span className={ classNames }>{ this.props.text }</span>);
    }
}
