import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Message } from '../../../records/message_records';

export default class extends React.Component {
    static propTypes = {
        messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        value: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    state = {
        value: this.props.value
    };

    handleChange = (event) => {
        this.setState({ value: parseInt(event.target.value) });
        this.props.onChange(event);
    };

    render() {
        return (
            <select value={ this.state.value } onChange={ this.handleChange }>
              <option value="-1">[Unset]</option>
              { this.props.messages.map(function(message) {
                  return (
                    <option
                      key={ message.get('id') }
                      value={ message.get('id') }
                    >
                      { message.get('description') }
                    </option>
                  );
              }.bind(this))}
            </select>
        );
    }
}
