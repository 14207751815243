import React from "react";
import { DownloadOutlined } from '@ant-design/icons';
import { Modal, Table } from "../../../styleguide";
import CSVLink from "../../common/CSVLink.js";
import { Map, List } from "immutable";
import moment from 'moment';
const columns = List([
  Map({
    title: "Site Group Name",
    dataIndex: "sitegroupname",
    className: "left-align",
    width: "15%",
    sorter: (a, b) => a.sitegroupname.localeCompare(b.sitegroupname)
  }),
  Map({
    title: "Operation Type",
    dataIndex: "operationtype",
    className: "right-align",
    width: "15%",
    sorter: (a, b) => a.operationtype.localeCompare(b.operationtype)
  }),
  Map({
    title: "Entity Type",
    dataIndex: "entitytype",
    className: "right-align",
    width: "15%",
    sorter: (a, b) => a.entitytype.localeCompare(b.entitytype)
  }),
  Map({
    title: "Entity Name",
    dataIndex: "entitynames",
    className: "right-align",
    width: "15%",
    sorter: (a, b) => a.entitynames.localeCompare(b.entitynames)
  }),
  Map({
    title: "Properties",
    dataIndex: "properties",
    className: "right-align",
    width: "20%",
    sorter: (a, b) => a.properties.localeCompare(b.properties)
  }),
  Map({
    title: "User",
    dataIndex: "user",
    className: "right-align",
    width: "10%",
    sorter: (a, b) => a.user.localeCompare(b.user)
  }),
  Map({
    title: "Date",
    dataIndex: "date",
    className: "right-align",
    width: "10%",
    sorter: (a, b) =>
      moment(a.date, "YYYY-MM-DD").valueOf() -
      moment(b.date, "YYYY-MM-DD").valueOf()
  })
]);

export default class RecentChangesModal extends React.Component {
  render() {
    const changeLogHeaders = columns.map((item, i) => {
      return Map({ label: item.get("title"), key: item.get("dataIndex") });
    });
    return (
      <Modal
        title={
          <span>
            Recent Changes{" "}
            <CSVLink
              data={this.props.changeLogData.toJS()}
              filename={"change_log.csv"}
              headers={changeLogHeaders.toJS()}
            >
              <DownloadOutlined />
            </CSVLink>
          </span>
        }
        visible={this.props.recentChangesModal}
        onCancel={this.props.handleCancel}
        footer={null}
        className="recentChangeModal"
      >
        <Table
          columns={columns.toJS()}
          dataSource={this.props.changeLogData.toJS()}
          className="consnet-tableCSS"
          pagination={{
            position: ['bottomCenter'],
            showTotal: (total) => `Total: ${total}`,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            locale: { items_per_page: " Records per page" }
          }}
          scroll={{ y: 450}}
          />
      </Modal>
    );
  }
}
