import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsAltH,
  faColumns,
  faMousePointer,
  faFont,
  faImage,
  faStream,
  faKeyboard,
  faCheckSquare,
  faExclamationCircle,
  faParagraph,
  faDotCircle,
  faCaretSquareDown,
  faCalendarAlt,
  faPaperPlane,
  faEnvelopeOpenText,
  faIdCard,
  faRoute,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';

export const publicComponents = [
  {
    title: 'Row',
    description: 'Add a new row to the message container to start a new section.',
    icon: <FontAwesomeIcon icon={faArrowsAltH} />,
    parents: new Set([
      'Column',
      'IOS',
      'Custom',
      'Notice',
      'Adblock',
      'PrivacyManager',
      'PrivacyManagerCCPA',
      'PrivacyManagerUS',
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
      'PrivacyManagerOTTPrivacyPolicyView',
      'PrivacyManagerOTTCustomView',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView',
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTPrivacyPolicyView',
      'CCPAPrivacyManagerOTTCustomView',
      'CustomPmView',
      'Preferences'
    ]),
  }, {
    title: 'Column',
    description: 'Add a new column to the message container to start a new section.',
    icon: <FontAwesomeIcon icon={faColumns} />,
    parents: new Set(['Row']),
  }, {
    title: 'PrivacyManagerTCFv2',
    description: 'Add TCF v2 compliant privacy manager to manager proper consent information.',
    icon: <FontAwesomeIcon icon={faEnvelopeOpenText} />,
    parents: new Set([
      'PrivacyManager',
    ]),
    grandparents: new Set(['PrivacyManager']),
  }, {
    title: 'PMCCPA',
    presentationalTitle: 'U.S. Privacy Manager',
    description: 'Add CCPA compliant privacy manager to manager proper consent information.',
    icon: <FontAwesomeIcon icon={faEnvelopeOpenText} />,
    parents: new Set([
      'Row',
      'PrivacyManagerCCPA',
      'Column',
    ]),
    grandparents: new Set(['PrivacyManagerCCPA']),
  },
  {
    title: 'PMUS',
    presentationalTitle: 'Privacy Choices',
    description: 'Add US privacy compliant privacy manager to manage proper consent information.',
    icon: <FontAwesomeIcon icon={faEnvelopeOpenText} />,
    parents: new Set([
      'Row',
      'PrivacyManagerUS',
      'Column',
    ]),
    grandparents: new Set(['PrivacyManagerUS']),
  },
  {
    title: 'PMTCFv2Inline',
    description: 'Add TCF v2 compliant privacy manager to manager proper consent information.',
    icon: <FontAwesomeIcon icon={faEnvelopeOpenText} />,
    parents: new Set([
      'PrivacyManager',
    ]),
    grandparents: new Set(['PrivacyManager']),
  },
  {
    title: 'PMTCFv2InlineNeutral',
    description: 'Add Neutral State TCF v2 compliant privacy manager to manager proper consent information.',
    icon: <FontAwesomeIcon icon={faEnvelopeOpenText} />,
    parents: new Set([
      'PrivacyManager',
    ]),
    grandparents: new Set(['PrivacyManager']),
  },
  {
    title: 'IOSButton',
    presentationalTitle: 'Button',
    description: 'Add a button to your iOS Tracking notice to continue or dismiss the message.',
    icon: <FontAwesomeIcon icon={faMousePointer} />,
    parents: new Set([
      'Column',
      'Row',
      'IOS'
    ]),
    grandparents: new Set([
      'IOS'
    ]),
  }, {
    title: 'PMButton',
    description: 'Add a button to your Privacy Manager to dictate Saving, Accept All, Reject All or Cancel.',
    icon: <FontAwesomeIcon icon={faMousePointer} />,
    parents: new Set([
      'Column',
      'Row',
      'PrivacyManager',
      'PrivacyManagerCCPA',
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
      'PrivacyManagerOTTPrivacyPolicyView',
      'PrivacyManagerOTTCustomView',
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTPrivacyPolicyView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView',
      'CustomPmView',
    ]),
    grandparents: new Set([
      'PrivacyManager',
      'PrivacyManagerCCPA',
      'PrivacyManagerUS',
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
      'PrivacyManagerOTTPrivacyPolicyView',
      'PrivacyManagerOTTCustomView',
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTPrivacyPolicyView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView',
      'CustomPmView',
    ]),
  }, {
    title: 'Stacks',
    presentationalTitle: 'Vendor List Stacks/Purposes',
    description: 'Add TCF v2 compliant stacks to display proper consent information.',
    icon: <FontAwesomeIcon icon={faStream} />,
    parents: new Set([
      'Row',
      'Notice',
      'PrivacyManager',
      'Column',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView'
    ]),
    grandparents: new Set(['Notice']),
  },
  //  {
  //   title: 'Categories',
  //   presentationalTitle: 'Vendor List Stacks/Purposes with Opt-in Controls',
  //   description: 'Add TCF v2 compliant opt-in categories to the first layer message.',
  //   icon: <FontAwesomeIcon icon={faClipboardList} />,
  //   parents: new Set([
  //     'Row',
  //     'Notice',
  //     'PrivacyManager',
  //     'Column',
  //     'Sar',
  //     'SuccessView',
  //     'ErrorView',
  //     'DsarRetrieval',
  //     'OneTimeCodeView',
  //     'CodeSuccessView',
  //     'RequestPendingView'
  //   ]),
  //   grandparents: new Set(['Notice']),
  // },
   {
    title: 'Button',
    description: 'Add a basic button which can have text or add child components.',
    icon: <FontAwesomeIcon icon={faMousePointer} />,
    parents: new Set([
      'Column',
      'Row',
      'Notice',
      'PrivacyManagerUS',
      'Custom',
      'Adblock',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView',
    ]),
    grandparents: new Set(['Notice', 'Adblock', 'Custom', 'Sar', 'DsarRetrieval', 'PrivacyManagerUS'])
  }, {
    title: 'Text',
    description: 'Add text as a child to many of the different components.',
    icon: <FontAwesomeIcon icon={faFont} />,
    parents: new Set([
      'Column',
      'Row',
      'Notice',
      'Adblock',
      'IOS',
      'Custom',
      'PrivacyManager',
      'PrivacyManagerCCPA',
      'PrivacyManagerUS',
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
      'PrivacyManagerOTTPrivacyPolicyView',
      'PrivacyManagerOTTCustomView',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView',
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTPrivacyPolicyView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView',
      'CustomPmView',
      'Preferences'
    ]),
  }, {
    title: 'Image',
    description: 'Add an image to the notice by passing in a URL.',
    icon: <FontAwesomeIcon icon={faImage} />,
    parents: new Set([
      'Column',
      'Row',
      'Notice',
      'Adblock',
      'IOS',
      'Custom',
      'PrivacyManager',
      'PrivacyManagerCCPA',
      'PrivacyManagerUS',
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
      'PrivacyManagerOTTPrivacyPolicyView',
      'PrivacyManagerOTTCustomView',
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTPrivacyPolicyView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView',
      'CustomPmView',
      'Preferences'
    ]),
  },
  {
    title: 'Input',
    description: 'Add an input to the form',
    icon: <FontAwesomeIcon icon={faKeyboard} />,
    parents: new Set([
      'Column',
      'Row',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView'
    ]),
    grandparents: new Set(['Sar']),
  },
  {
    title: 'Radio',
    description: 'Add a radio to the form',
    icon: <FontAwesomeIcon icon={faDotCircle} />,
    parents: new Set([
      'Column',
      'Row',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView'
    ]),
    grandparents: new Set(['Sar', 'DsarRetrieval']),
  },
  {
    title: 'Dropdown',
    description: 'Add a dropdown to the form',
    icon: <FontAwesomeIcon icon={faCaretSquareDown} />,
    parents: new Set([
      'Column',
      'Row',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView'
    ]),
    grandparents: new Set(['Sar', 'DsarRetrieval']),
  },
  {
    title: 'DatePicker',
    presentationalTitle: 'Date Picker',
    description: 'Add a date picker to the form',
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    parents: new Set([
      'Column',
      'Row',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView'
    ]),
    grandparents: new Set(['Sar', 'DsarRetrieval']),
  },
  {
    title: 'CheckboxGroup',
    presentationalTitle: 'Checkbox Group',
    description: 'Add a group of checkboxes (or single checkbox) to the form',
    icon: <FontAwesomeIcon icon={faCheckSquare} />,
    parents: new Set([
      'Column',
      'Row',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView'
    ]),
    grandparents: new Set(['Sar', 'DsarRetrieval']),
  },

  {
    title: 'TextArea',
    presentationalTitle: 'Text Area',
    description: 'Add a text area',
    icon: <FontAwesomeIcon icon={faParagraph} />,
    parents: new Set([
      'Column',
      'Row',
      'Sar',
      'SuccessView',
      'ErrorView',
      'DsarRetrieval',
      'OneTimeCodeView',
      'CodeSuccessView',
      'RequestPendingView'
    ]),
    grandparents: new Set(['Sar'])
  },
  {
    title: 'OTTCards',
    presentationalTitle: 'Cards',
    description: 'Add Cards to your view',
    icon: <FontAwesomeIcon icon={faIdCard} />,
    parents: new Set([
      'Column',
      'Row',
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
    ]),
    grandparents: new Set([
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
    ])
  },
  {
    title: 'OTTNavButton',
    presentationalTitle: 'Navigation Button',
    description: 'Add a Navigation Button to your view',
    icon: <FontAwesomeIcon icon={faRoute} />,
    parents: new Set([
      'Column',
      'Row',
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
      'PrivacyManagerOTTPrivacyPolicyView',
      'PrivacyManagerOTTCustomView',
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTPrivacyPolicyView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView'
    ]),
    grandparents: new Set([
      'PrivacyManagerOTT',
      'PrivacyManagerOTTCategoriesView',
      'PrivacyManagerOTTVendorsView',
      'PrivacyManagerOTTCardDetailsView',
      'PrivacyManagerOTTPrivacyPolicyView',
      'PrivacyManagerOTTCustomView',
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTPrivacyPolicyView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView'
    ])
  },
  {
    title: 'OTTSwitchCard',
    description: 'Add a "do not sell me personal information" toggle card',
    icon: <FontAwesomeIcon icon={faIdCard} />,
    parents: new Set([
      'Column',
      'Row',
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTCustomView'
    ]),
    grandparents: new Set([
      'CCPAPrivacyManagerOTT',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTPrivacyPolicyView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView'
    ])
  },
  {
    title: 'OTTCCPACards',
    presentationalTitle: 'Cards CCPA',
    description: 'Add Cards to your view',
    icon: <FontAwesomeIcon icon={faIdCard} />,
    parents: new Set([
      'Column',
      'Row',
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView',
    ]),
    grandparents: new Set([
      'CCPAPrivacyManagerOTTVendorsView',
      'CCPAPrivacyManagerOTTPreferencesView',
      'CCPAPrivacyManagerOTTCardDetailsView',
      'CCPAPrivacyManagerOTTCustomView'
    ])
  },
  {
    title: 'PreferencesCenter',
    presentationalTitle: 'Preferences Center',
    description: 'Controls and Displays for your Preferences App',
    icon: <FontAwesomeIcon icon={faEnvelopeOpenText} />,
    parents: new Set([
      'Column',
      'Row',
      'Preferences',
    ]),
    grandparents: new Set([
      'Preferences',
    ]),
  },
  {
    title: 'PreferencesButton',
    presentationalTitle: 'PreferencesButton',
    description: 'Actionable Buttons for your Preferences App',
    icon: <FontAwesomeIcon icon={faMousePointer} />,
    parents: new Set([
      'Column',
      'Row',
      'Preferences',
    ]),
    grandparents: new Set([
      'Preferences',
    ]),
  },
];

export const privateComponentsList = [
  {
    title: 'SubmitButton',
    presentationalTitle: 'Submit Button',
    description: 'Add a submit button to the form',
    icon: <FontAwesomeIcon icon={faPaperPlane} />,
    parents: new Set(['Column', 'Row', 'Sar']),
    grandparents: new Set(['Sar'])
  },
  {
    title: 'ErrorBlock',
    presentationalTitle: 'Error Block',
    description: 'Add a submit button to the form',
    icon: <FontAwesomeIcon icon={faExclamationCircle} />,
    parents: new Set(['Column', 'Row', 'Sar']),
    grandparents: new Set(['Sar'])
  },
  {
    title: 'CustomButton',
    presentationalTitle: 'Button',
    description: 'Add a basic button which can have text or add child components.',
    icon: <FontAwesomeIcon icon={faMousePointer} />,
    parents: new Set(['Column', 'Row', 'Sar']),
    grandparents: new Set(['Sar'])
  }
];