import React, { useState, useEffect, useRef } from 'react';

import StandardsCard from './StandardsCard';

import Pagination from './Pagination';
import MoveToDraftModal from './MoveToDraftModal';
import PublishModal from './PublishModal';
import DeleteNotLinked from './DeleteNotLinked';
import DeleteWithLinkedSets from './DeleteWithLinkedSets';
import { message } from '../../../styleguide';
import CloneModal from '../../common/CloneModal';

const StandardsGrouped = ({ standards, updateStandard, accountId, openStandardModal, createStandard, draft, deleteStandard, standardScores, type, filterType, domainSets, switchStandardsTab, readOnly, getStandardNameError, standardsType }) => {

  const [pages, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const showCards = 6;
  const [showFrom, setShowFrom] = useState(0);
  const [showTo, setShowTo] = useState(showCards - 1)
  const [moveToDraftModalOpen, setMoveToDraftModal] = useState(false);
  const [publishModalOpen, setPublishModal] = useState(false);
  const [selectedStandard, setSelectedStandard] = useState(null);
  const [deleteStandardNotLinkedModalOpen, setDeleteStandardNotLinkedModal] = useState(false);
  const [deleteStandardWithSetsModalOpen, setDeleteStandardWithSetsModal] = useState(false);
  const [cloneStandardModalOpen, setCloneStandardModalOpen] = useState(false);

  useEffect(() => {
    const totalPages = Math.ceil(standards.size / showCards);
    setPage(totalPages);
  }, [standards])

  const handlePageClick = (data) => {
    setCurrentPage(data.selected+ 1);
  }

  useEffect(() => {
    const from = (currentPage - 1) * showCards;
    const to = from + showCards - 1
    setShowFrom(from);
    setShowTo(to);
  }, [currentPage, standards])

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };


  const editStandard = (standard) => {
    openStandardModal(standard);
  }

  const copyStandard = (standard) => {
    setCloneStandardModalOpen(true);
    setSelectedStandard(standard);
  };

  const cloneStandard = (newStandardName) => {
    const draftStandard = selectedStandard.set('name', newStandardName);
    createStandard(accountId, draftStandard).then((standard) => {
        message.success(<>Standard <strong>{standard.name}</strong> was successfully cloned</>)
      });
    setCloneStandardModalOpen(false);
    setSelectedStandard(null);
  }

  const moveToDraft = (standard) => {
    setMoveToDraftModal(true);
    setSelectedStandard(standard);
  }

  const deleteStandardModal = (standard) => {
    if (standard.appliedToDomainSetIds.map(appliedId => domainSets.find(ds => ds.id === appliedId || ds._id === appliedId)).length) {
      setDeleteStandardWithSetsModal(true);
    } else {
      setDeleteStandardNotLinkedModal(true);
    }
    setSelectedStandard(standard);
  }

  const closeStandardNotLinkedModalOpen = () => {
    setDeleteStandardNotLinkedModal(false);
    setSelectedStandard(null);
  }
  
  const closeStandardWithSetsModalOpen = () => {
    setDeleteStandardWithSetsModal(false);
    setSelectedStandard(null);
  }

  const moveToDraftUpdateStandard = () => {
    switchStandardsTab();
    updateStandard(accountId, selectedStandard.set('isActive', false)).then((standard) =>{
      message.success(<><strong>{standard.name}</strong> moved to draft successfully</>)
    });
    setSelectedStandard(null);
    setMoveToDraftModal(false);
  }

  const publishStandard = (standard) => {
    setPublishModal(true);
    setSelectedStandard(standard);
  }

  const publishUpdateStandard = () => {
    switchStandardsTab();
    updateStandard(accountId, selectedStandard.set('isActive', true)).then((standard) =>{
      message.success(<><strong>{standard.name}</strong> standard was successfully published</>)
    });
    setSelectedStandard(null);
    setPublishModal(false);
  }

  const closeToDraftModal = () => {
    setMoveToDraftModal(false);
    setSelectedStandard(null);
  }

  const closePublishModal = () => {
    setPublishModal(false);
    setSelectedStandard(null);
  }

  const closeCloneModal = () => {
    setCloneStandardModalOpen(false);
    setSelectedStandard(null);
  }

  const hideStandardsSection = (e, type) => {
    const cards = document.getElementById('standards-' + type);
    const icon = document.getElementById("dropdown-icon-" + type)
    const cardsStyle = cards.style.display;
    if (cardsStyle === 'block') {
      cards.style.display = 'none';
      icon.style.transform = 'rotate(135deg)';
      icon.style.margin = '0 10px 0 7px';
    } else {
      cards.style.display = 'block';
      icon.style.transform = 'rotate(-45deg)';
      icon.style.margin = '0 10px 5px 7px';
    }
  }

  return (
    <React.Fragment>
      { moveToDraftModalOpen ? <MoveToDraftModal domainSets={domainSets} standard={selectedStandard} moveToDraft={moveToDraftUpdateStandard} closeModal={closeToDraftModal} /> : null}
      { publishModalOpen ? <PublishModal standardName={selectedStandard.name} publishStandard={publishUpdateStandard} closeModal={closePublishModal} /> : null}
      { deleteStandardNotLinkedModalOpen ? <DeleteNotLinked standard={selectedStandard} moveToDraft={moveToDraftUpdateStandard} closeModal={closeStandardNotLinkedModalOpen} deleteStandard={deleteStandard} accountId={accountId} /> : null}
      { deleteStandardWithSetsModalOpen ? <DeleteWithLinkedSets domainSets={domainSets} standard={selectedStandard} moveToDraft={moveToDraftUpdateStandard} closeModal={closeStandardWithSetsModalOpen} /> : null}
      { cloneStandardModalOpen ? <CloneModal entity={"Standard"} entityType={standardsType === "1" ? "Published" : standardsType === "2"? "Draft" : null} cloneMethod={cloneStandard} name={selectedStandard.name} closeModal={closeCloneModal} getNameError={getStandardNameError}/> : null}
      <div className="standards-types" onClick={(e) => hideStandardsSection(e, type)}>
        <span className="dropdown-icon" id={"dropdown-icon-" + type} style={{ transform: 'rotate(-45deg)' }}></span>{type === 'account' ? 'Account Standards' : 'SourcePoint Default Standards'}<span className="standards-size"> ({standards.size})</span>
      </div>
      <div id={"standards-" + type} style={{ display: 'block' }}>
        <div className="privacy-lens-grid">
          {standards.map((s, i) => {
            return <StandardsCard
              key={'standard ' + i}
              standard={s}
              standardScores={standardScores}
              editStandard={editStandard}
              copyStandard={copyStandard}
              publishStandard={publishStandard}
              moveToDraft={moveToDraft}
              deleteStandardModal={deleteStandardModal}
              deleteStandard={deleteStandard}
              showCards={i >= showFrom && i <= showTo ? true : false}
              type={type}
              domainSets={domainSets}
              openStandardModal={openStandardModal}
              accountId={accountId}
              readOnly={readOnly}
            />
          })}
        </div>
        { pages > 1 && <Pagination
          pages={pages}
          handlePageClick={handlePageClick}
        /> }
      </div>
    </React.Fragment>
  )
}

export default StandardsGrouped;

