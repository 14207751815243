import { Map, List } from "immutable";

import * as types from "../actions/action_types";
import { StoreRecord } from "../records/store_records.js";
import { setValue, setError, setPending, stopPending } from "./helpers.js";

const initialState = Map({
  publisherCmpId: StoreRecord({ value: '' }),
  publishersCmpIds: StoreRecord({ value: List([]) }),
  savePending: false,
});

export default (state = initialState, action) => {
  const lists = state.getIn(['publishersCmpIds', 'value']);
  let updatedList;

  switch (action.type) {
    case types.PUBLISHER_CMP_ID_PENDING:
      return setPending(state, ["publisherCmpId"]);

    case types.PUBLISHER_CMP_ID_LOADED:
      return setValue(state, ["publisherCmpId"], action.data);

    case types.PUBLISHER_CMP_ID_ERROR:
      return setError(state, ["publisherCmpId"], action.errorMessage);

    case types.PUBLISHER_CMP_ID_CACHED:
      return stopPending(state, ["publisherCmpId"]);

    case types.PUBLISHERS_CMP_IDS_PENDING:
      return setPending(state, ["publishersCmpIds"]);

    case types.PUBLISHERS_CMP_IDS_LOADED:
      return setValue(state, ["publishersCmpIds"], action.data);

    case types.PUBLISHERS_CMP_IDS_ERROR:
      return setError(state, ["publishersCmpIds"], action.errorMessage);

    case types.PUBLISHERS_CMP_IDS_CACHED:
      return stopPending(state, ["publishersCmpIds"]);

    case types.PUBLISHER_CMP_ID_DELETED:
      updatedList = lists.filter(l => l._id !== action.id);
      return setValue(state, ['publishersCmpIds'], updatedList);

    case types.PUBLISHER_CMP_ID_SAVE_PENDING:
      return state.set('savePending', true);

    case types.PUBLISHER_CMP_ID_UPDATED:
      updatedList = lists.filterNot(l => l._id === action.data._id).push(action.data);
      return setValue(state, ['publishersCmpIds'], updatedList).set('savePending', false);

    case types.PUBLISHER_CMP_ID_CREATED:
      updatedList = lists.push(action.data);
      return setValue(state, ['publishersCmpIds'], updatedList).set('savePending', false);    
  }
  return state;
};
