import * as types from './action_types';

import { Set } from 'immutable';
import {
  getMessageWithDetailAjax,
  getMessages, fetchMessages,
  deleteMessageAjax,
  getMessagesAfterImportAjax,
  createMessageAjax,
  updateMessageAjax,
  createSiteGroupMessageAjax,
  updateSiteGroupMessageAjax,
  deleteSiteGroupMessageAjax,
} from '../api/dialogue/messages';
import { importMessageAjax } from '../api/dialogue/import';
import { actionCreator } from './helpers';
import { setSiteGroupSitesWithErrors } from './site_actions';
import {
  createMessageLog,
  deleteMessageLog,
  editMessageLog,
  createSiteGroupMessageLog,
  deleteSiteGroupMessageLog,
  editSiteGroupMessageLog
} from '../api/change_log'

export const getMessageWithDetail = actionCreator(
  getMessageWithDetailAjax,
  messagesPending,
  messageWithDetailLoaded,
  messagesError,
  messagesCached,
)

export const createMessage = actionCreator(
  createMessageAjax,
  createPending,
  messageCreated,
  messagesError,
  null,
  createMessageLog
)

export const updateMessage = actionCreator(
  updateMessageAjax,
  updatePending,
  messageUpdated,
  messagesError,
  null,
  editMessageLog
)

export function createSiteGroupMessage(accountId, siteGroupId, message, willImport) {
  return (dispatch, getState) => {
    dispatch(createPending());
    if (willImport) dispatch(messageImportPending());
    createSiteGroupMessageAjax(accountId, siteGroupId, message)
      .then((resp) => {
        createSiteGroupMessageLog(getState(), siteGroupId, resp.get('messages'))
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(messageCreated(resp.get('messages')));
      })
      .catch((resp) => dispatch(messagesError(resp)));
  }
}

export function updateSiteGroupMessage(accountId, siteGroupId, message) {
  return (dispatch, getState) => {
    dispatch(updatePending());
    updateSiteGroupMessageAjax(accountId, siteGroupId, message)
      .then((resp) => {
        editSiteGroupMessageLog(getState(), siteGroupId, message)
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(messageUpdated(resp.get('messages')));
      })
      .catch((resp) => {
        dispatch(messagesError(resp));
      });
  }
}

export const getDraftMessages = actionCreator(
  getMessages.bind(null, 'draft'),
  messagesPending,
  messagesLoaded,
  messagesError,
  messagesCached,
)

export const getPublicMessages = actionCreator(
  getMessages.bind(null, 'public'),
  messagesPending,
  messagesLoaded,
  messagesError,
  messagesCached,
)

export const fetchPublicMessages = actionCreator(
  fetchMessages.bind(null, 'public'),
  messagesPending,
  messagesLoaded,
  messagesError,
)

export const getStageMessages = actionCreator(
  getMessages.bind(null, 'stage'),
  messagesPending,
  messagesLoaded,
  messagesError,
  messagesCached,
)

export const fetchStageMessages = actionCreator(
  fetchMessages.bind(null, 'stage'),
  messagesPending,
  messagesLoaded,
  messagesError,
)

export const getPublicOldMessages = actionCreator(
  getMessages.bind(null, 'publicOld'),
  messagesPending,
  messagesLoaded,
  messagesError,
  messagesCached,
)

export const getStageOldMessages = actionCreator(
  getMessages.bind(null, 'stageOld'),
  messagesPending,
  messagesLoaded,
  messagesError,
  messagesCached,
)

export const deleteMessage = actionCreator(
  deleteMessageAjax,
  messagesPending,
  messagesDeleted,
  messagesError,
  null,
  deleteMessageLog
)

export function deleteSiteGroupMessage(
  siteGroupId,
  accountId,
  messageId,
  messageName
) {
  return (dispatch, getState) => {
    dispatch(messagesPending());
    deleteSiteGroupMessageAjax(siteGroupId, accountId, messageId)
      .then((resp) => {
        deleteSiteGroupMessageLog(
          getState(),
          siteGroupId,
          messageId,
          messageName
        )
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(messagesDeleted(resp.get('messageIds')));
      })
      .catch((resp) => dispatch(messagesError(resp)));
  }
}

export const importMessage = actionCreator(
  importMessageAjax,
  messageImportPending,
  messageImported,
  messagesError,
)

export const getMessagesAfterImport = actionCreator(
  getMessagesAfterImportAjax,
  messagesPending,
  messagesLoaded,
  messagesError,
)

function messageWithDetailLoaded(message) {
  return {
    type: types.MESSAGE_WITH_DETAIL_LOADED,
    message
  }
}

function messageCreated(message) {
  return {
    type: types.MESSAGE_CREATED,
    message
  }
}

function messageUpdated(message) {
  return {
    type: types.MESSAGE_UPDATED,
    message
  }
}

function messagesPending() {
  return {
    type: types.MESSAGES_PENDING,
  }
}

function createPending() {
  return {
    type: types.MESSAGE_CREATE_PENDING,
  }
}

function updatePending() {
  return {
    type: types.MESSAGE_UPDATE_PENDING,
  }
}

function messageImportPending() {
  return {
    type: types.MESSAGE_IMPORT_PENDING,
  }
}

function messagesCached() {
  return {
    type: types.MESSAGES_CACHED,
  }
}

function messagesLoaded(messages) {
  return {
    type: types.MESSAGES_LOADED,
    messages,
  }
}

function messagesError(errorMessage) {
  return {
    type: types.MESSAGES_ERROR,
    errorMessage,
  }
}

export function messagesDeleted(messageIdOrMessageIdsSet) {
  const messageIds = (new Set([messageIdOrMessageIdsSet])).flatten();
  return { type: types.MESSAGES_DELETED, messageIds };
}

function messageImported(message) {
  return {
    type: types.DRAFT_MESSAGE_IMPORTED,
    message
  }
}
