import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';

import CampaignTableRow from './CampaignTableRow.js.jsx';
import StopCurrentCampaign from './StopCurrentCampaign.js.jsx';
import CustomButton from '../../common/CustomButton.js.jsx';
import { Campaign } from '../../../records/campaign_records';
import { Message } from '../../../records/message_records';
import { Scenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2';
import { getParameterByName } from '../../utils';

class CampaignsOverviewTable extends React.Component {
    static propTypes = {
        campaigns: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Campaign)).isRequired,
        showCampaignStats: PropTypes.func.isRequired,
        headerPresent: PropTypes.bool,
        campaignsStats: PropTypes.object,
        getCampaignStats: PropTypes.func.isRequired,
        env: PropTypes.oneOf(["production", "stage"]),
        copyAndActivateInEnv: PropTypes.func,
        readOnly: PropTypes.bool.isRequired,
        messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        scenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
    };

    static defaultProps = {
        headerPresent: true,
        campaignsStats: {},
        activateInDiffEnv: _.noop
    };

    render() {
        let header;

        if (this.props.env) {

            let currentEnv;
            let envToActivate;
            if (this.props.env === "stage") {
                currentEnv = "STAGE";
                envToActivate = "PUBLIC";
            } else {
                currentEnv = "PUBLIC";
                envToActivate = "STAGE";
            }
            let activateDiffEnvButtonName = "ACTIVATE IN " + envToActivate;

            let buttons;
            if (!this.props.readOnly) {
              buttons = (
                <React.Fragment>
                  <CustomButton
                    className="activate-diff-env"
                    onClick={ this.props.copyAndActivateInEnv.bind(null, this.props.env) }
                    type={ CustomButton.types.PLAIN }
                    disabled={ this.props.readOnly }
                  >
                    { activateDiffEnvButtonName }
                  </CustomButton>
                  <StopCurrentCampaign
                    campaign={ this.props.campaigns.first() }
                    readOnly={ this.props.readOnly }
                    accountId={ this.props.accountId }
                  />
                </React.Fragment>
              )
            }
            header = (
                <div className="clearfix">
                  <div className="campaigns-table-header"><h4>{ currentEnv + " CAMPAIGN" }</h4></div>
                  { buttons }
                </div>
            )
        } else {
          header = <div><h4>ENDED CAMPAIGNS</h4></div>
        }

        let wrapperClassName = "campaigns-table-wrapper";
        if (this.props.env) wrapperClassName += " active";

        let totalPvHeader;
        if (!getParameterByName('site_group_id', window.location)) {
          totalPvHeader = <th>Total PV</th>;
        }
        return (
            <div className={ wrapperClassName }>
              { header }

              <table className="tiny table campaign" id="campaigns-table">

                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    { totalPvHeader }
                    <th></th>
                  </tr>
                </thead>

                { this.props.campaigns.map(campaign => {
                    return (
                        <CampaignTableRow
                          key={ campaign.id }
                          active={ !campaign.deleted_at }
                          campaign={ campaign }
                          messages={ this.props.messages }
                          scenarios={ this.props.scenarios }
                          summaryStats={ this.props.campaignsStats.get(campaign.id) && this.props.campaignsStats.getIn([campaign.id, 'value']) }
                          {...(_.omit(this.props, ['parseCampaignData', 'campaignsStats']))}
                          readOnly={ this.props.readOnly }
                        />
                    )
                })}
              </table>
            </div>
        );
    }
}

export default CampaignsOverviewTable;