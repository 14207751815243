import React, { Component } from 'react';
import { Radio } from '../../../../../../../../styleguide';

class Align extends Component {
  updateAlign = (e) => {
    this.props.updateSetting(this.props.settingKey, e.target.value);
  }
  render() {
    return (
      <div className="set-align">
        <h4>{this.props.settingsTitle}</h4>
        <div>
          <Radio.Group value={this.props.value} onChange={this.updateAlign}>
            <Radio.Button value="left">Left</Radio.Button>
            <Radio.Button value="center">Center</Radio.Button>
            <Radio.Button value="right">Right</Radio.Button>
          </Radio.Group>
        </div>
      </div>
    );
  }
}

export default Align;
