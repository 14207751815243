import { Map, List } from "immutable";

import * as types from "../actions/action_types";
import { StoreRecord } from "../records/store_records.js";
import { setValue, setError, setPending, stopPending } from "./helpers.js";

const initialState = Map({
  propertyData: StoreRecord({ value: List([]) }),
  vendorsData: StoreRecord({ value: List([]) }),
  specialFeatureData: StoreRecord({ value: List([]) }),
  messageData: StoreRecord({ value: List([]) }),
  purposeData: StoreRecord({ value: List([]) })
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GDPRV2_V2_REPORT_PVUSERS_PENDING:
      return setPending(state, ["propertyData"]);

    case types.GDPRV2_V2_REPORT_PVUSERS_LOADED:
      return setValue(state, ["propertyData"], action.data);

    case types.GDPRV2_V2_REPORT_PVUSERS_ERROR:
      return setError(state, ["propertyData"], action.errorMessage);

    case types.GDPRV2_V2_REPORT_PVUSERS_CACHED:
      return stopPending(state, ["propertyData"]);

    case types.GDPRV2_V2_REPORT_VENDOR_PENDING:
      return setPending(state, ["vendorsData"]);

    case types.GDPRV2_V2_REPORT_VENDOR_LOADED:
      return setValue(state, ["vendorsData"], List(action.data));

    case types.GDPRV2_V2_REPORT_VENDOR_ERROR:
      return setError(state, ["vendorsData"], action.errorMessage);

    case types.GDPRV2_V2_REPORT_VENDOR_CACHED:
      return setError(state, ["vendorsData"]);

    case types.GDPRV2_V2_REPORT_SPECIAL_FEATURE_PENDING:
      return setPending(state, ["specialFeatureData"]);

    case types.GDPRV2_V2_REPORT_SPECIAL_FEATURE_LOADED:
      return setValue(state, ["specialFeatureData"], List(action.data));

    case types.GDPRV2_V2_REPORT_SPECIAL_FEATURE_ERROR:
      return setError(state, ["specialFeatureData"], action.errorMessage);

    case types.GDPRV2_V2_REPORT_SPECIAL_FEATURE_CACHED:
      return setError(state, ["specialFeatureData"]);

    case types.GDPRV2_V2_REPORT_MESSAGE_PENDING:
      return setPending(state, ["messageData"]);

    case types.GDPRV2_V2_REPORT_MESSAGE_LOADED:
      return setValue(state, ["messageData"], action.data);

    case types.GDPRV2_V2_REPORT_MESSAGE_ERROR:
      return setError(state, ["messageData"], action.errorMessage);

    case types.GDPRV2_V2_REPORT_MESSAGE_CACHED:
      return setError(state, ["messageData"]);

    case types.GDPRV2_V2_REPORT_PURPOSE_PENDING:
      return setPending(state, ["purposeData"]);

    case types.GDPRV2_V2_REPORT_PURPOSE_LOADED:
      return setValue(state, ["purposeData"], action.data);

    case types.GDPRV2_V2_REPORT_PURPOSE_ERROR:
      return setError(state, ["purposeData"], action.errorMessage);

    case types.GDPRV2_V2_REPORT_PURPOSE_CACHED:
      return setError(state, ["purposeData"]);
  }
  return state;
};
