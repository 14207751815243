import React from 'react';
import PropTypes from 'prop-types';
import ReportsSubnav from './ReportsSubnav';
import { connect } from 'react-redux';
import { User } from '../../records/account_records';

class ReportsLayaout extends React.Component {
  static propTypes = {
    currentUser: PropTypes.instanceOf(User).isRequired,
  }

  render() {
    if (!this.props.currentUser) return null;
    return (
      <div id='avocado' className='layout-container consent-layout reports-layout'>

        <div className="navbar navbar-default sitesentry-subnav">
          <ReportsSubnav
            id="subnav"
            currentUser={ this.props.currentUser }
            isMasq={ this.props.route.isMasq }
          />
        </div>

        {this.props.children && React.cloneElement(this.props.children, {
          location: this.props.location
        })}

      </div>
    )
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
  };
};

export default connect(mapStateToProps)(ReportsLayaout);
