import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Select, Input } from 'antd';

import { urlMappingPatterns } from '../../../constants';
import { useAutoScroll } from '../../../hooks';
import { useState } from 'react';

const {
  MATCHES,
  BEGINS_WITH,
  ENDS_WITH,
  CONTAINS,
} = urlMappingPatterns;

const UrlMappingTab = ({ patterns, updatePatterns, readOnly }) => {
  //patternNumbers is for titling patterns
  const [patternNumbers, setPatternNumbers] = useState([...new Array(patterns.length + 1).keys()].map(k => k + 1));
  const autoScrollRef = useAutoScroll(patterns.length);

  const createNewStatement = () => ({ ruleBoolean: true, type: MATCHES.key, urlPattern: '' });
  const addPattern = () => {
    setPatternNumbers([
      ...patternNumbers,
      patternNumbers[patternNumbers.length - 1] + 1
    ]);
    updatePatterns([...patterns, [createNewStatement()]]);
  };

  const removePattern = (i) => () => {
    setPatternNumbers([
      ...patternNumbers.slice(0, i),
      ...patternNumbers.slice(i + 1),
    ]);
    updatePatterns([
      ...patterns.slice(0, i),
      ...patterns.slice(i + 1)
    ]);
  };

  const addStatement = (i) => () => {
    updatePatterns([
      ...patterns.slice(0, i),
      [...patterns[i], createNewStatement()],
      ...patterns.slice(i + 1)
    ]);
  };

  const removeStatement = (patternIndex, statementIndex) => () => {
    const updatedPattern = [
      ...patterns[patternIndex].slice(0, statementIndex),
      ...patterns[patternIndex].slice(statementIndex + 1)
    ];
    updatePatterns(
      updatedPattern.length
        ? [
          ...patterns.slice(0, patternIndex),
          updatedPattern,
          ...patterns.slice(patternIndex + 1)
        ]
        : [
          ...patterns.slice(0, patternIndex),
          ...patterns.slice(patternIndex + 1)
        ]
    );
  };

  const updateStatement = (patternIndex, statementIndex, field) => (value) => {
    const updatedStatement = {
      ...patterns[patternIndex][statementIndex],
      [field]: value
    };

    const updatedPattern = [
      ...patterns[patternIndex].slice(0, statementIndex),
      updatedStatement,
      ...patterns[patternIndex].slice(statementIndex + 1)
    ];

    updatePatterns([
      ...patterns.slice(0, patternIndex),
      updatedPattern,
      ...patterns.slice(patternIndex + 1)
    ]);
  };

  return (
    <div>
      <div className="url-mapping-container">
        {patterns.length
          ? (
            <div className="patterns-container" ref={autoScrollRef}>
              {patterns.map((pattern, i) => (
                <div key={pattern[0].type + i} className="pattern-container">
                  <div className="header-container">
                    <h4>Pattern {patternNumbers[i]}</h4>
                    <div className="addremove-item-container fixed-height">
                    {!readOnly ? (
                      <div data-testid="rm-pattern" className="icon" onClick={removePattern(i)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    ) : null}
                    </div>
                  </div>
                  <div className="pattern-headers-container">
                    <div className="boolean-header">
                      boolean
                    </div>
                    <div className="rule-header">
                      rule
                    </div>
                    <div className="url-header">
                      url
                    </div>
                  </div>
                  {pattern.map(({
                    ruleBoolean,
                    type,
                    urlPattern,
                  }, j) => (
                      <div key={type + j} className="statement-container">
                        <div className="bool-container">
                          <Select value={ruleBoolean} onChange={updateStatement(i, j, 'ruleBoolean')} disabled={readOnly}>
                            <Select.Option style={{ textTransform: 'uppercase' }} value={true}>
                              must
                            </Select.Option>
                            <Select.Option style={{ textTransform: 'uppercase' }} value={false}>
                              must not
                            </Select.Option>
                          </Select>
                        </div>
                        <div className="type-container">
                          <Select value={type} onChange={updateStatement(i, j, 'type')} disabled={readOnly}>
                            <Select.Option style={{ textTransform: 'uppercase' }} value={MATCHES.key}>
                              {MATCHES.text}
                            </Select.Option>
                            <Select.Option style={{ textTransform: 'uppercase' }} value={BEGINS_WITH.key}>
                              {BEGINS_WITH.text}
                            </Select.Option>
                            <Select.Option style={{ textTransform: 'uppercase' }} value={ENDS_WITH.key}>
                              {ENDS_WITH.text}
                            </Select.Option>
                            <Select.Option style={{ textTransform: 'uppercase' }} value={CONTAINS.key}>
                              {CONTAINS.text}
                            </Select.Option>
                          </Select>
                        </div>
                        <div className="url-pattern-container">
                          <Input
                            data-testid="pattern-input"
                            value={urlPattern}
                            onChange={(({ target: { value } }) => updateStatement(i, j, 'urlPattern')(value))}
                            disabled={readOnly}
                          />
                        </div>
                        <div className="remove-statement-container">
                          <div className="addremove-item-container position-left">
                          {!readOnly ? (
                            <div data-testid="rm-statement" className="icon" onClick={removeStatement(i, j)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </div>
                          ) : null}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="addremove-item-container fixed-height">
                  {!readOnly ? (
                    <div onClick={addStatement(i)}>
                      <FontAwesomeIcon icon={faPlusCircle} color="#3bd55c" />
                      <div>Add Statement</div>
                    </div>
                  ) : null}
                  </div>
                </div>
              ))}
            </div>
          )
          : (
            <div className="no-patterns-msg-container">
              You haven't added any mappings
            </div>
          )}
      </div>
      <div className="addremove-item-container">
      {!readOnly ? (
        <div data-testid="add-pattern" className="icon" onClick={addPattern}>
          <FontAwesomeIcon icon={faPlusCircle} color="#3bd55c" />
          <div>Add Pattern</div>
        </div>
        ) : null}
      </div>
    </div>
  );
};

export default UrlMappingTab;