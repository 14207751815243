import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { Popover, Button } from 'antd';

import TypeSelector from './components/TypeSelector';
import CancelAndConfirm from './components/CancelAndConfirm';
import { SCENARIO_STEPS } from './components/steps';
import { Message } from '../../../../records/message_records';

class StepBlock extends React.Component {
  static propTypes = {
    record: ImmutablePropTypes.record.isRequired,
    onStepSelect: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    onChange: PropTypes.func.isRequired,
    types: ImmutablePropTypes.list.isRequired,
    onUpdateType: PropTypes.func.isRequired,
    deleteStepBlock: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    classKey: null,
  };

  state = {
    editing: this.props.editing,
    showDeleteWarning: false,
  }

  onStepSelect = () => {
    this.props.onStepSelect;
  }

  toggleBlockEdit = () => {
    if (this.props.readOnly) return;
    this.setState({
      editing: !this.state.editing,
    });
  }

  toggleShowWarning = (e) => {
    e.stopPropagation();
    this.setState({
      showDeleteWarning: !this.state.showDeleteWarning,
    });
  }

  deleteStepBlock = (e) => {
    this.setState({
      editing: false,
    });
    this.toggleShowWarning(e);
    this.props.deleteStepBlock();
  }

  renderStepBlock = (step) => {
    let className = step.className;

    if (className === 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate') {
      if (step.data.version === 2) {
        className = 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2';
      } else if (step.data.version === 'ccpa') {
        className = 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA';
      } else if (step.data.version === 'usnat') {
        className = 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateUSNAT';
      } else if (step.data.version === 'preferences') {
        className = 'com.sourcepoint.msgsvc.scenario.steps.ConsentGatePreferences';
      }
    }

    const Component = SCENARIO_STEPS[className].component;
    const data = step.data;
    const editing = this.state.editing;

    const props = {
      editing,
      messages: this.props.messages,
      onChange: this.props.onChange,
      onShowMessagePreview: this.props.onShowMessagePreview,
      data,
    };

    return (
      <Component {...props} >
        <TypeSelector
          types={this.props.types}
          selectValue={className}
          handleTypeSelect={(value) => this.props.onUpdateType(value)}
        />
      </Component>
    );
  }

  render() {
    const editing = this.state.editing;
    const stepBlockComp = this.renderStepBlock(this.props.record);
    const stepBlockClass = classNames(
      'scenario-block-container',
      { 'block-editing': editing },
    );

    const deleteClass = classNames('delete-button', { editing });
    const blockEdit = editing ? null : this.toggleBlockEdit;
    const deleteWarning = (
      <div>
        <p>Are you sure you want to delete this condition?</p>
        <CancelAndConfirm
          toggleCancel={this.toggleShowWarning}
          onConfirm={this.deleteStepBlock}
          confirmText='Yes, Delete'
        />
      </div>
    );
    return (
      <td className={stepBlockClass}>
        <Popover
          content={deleteWarning}
          open={this.state.showDeleteWarning}
        >
          <Button
            className={deleteClass}
            onClick={this.toggleShowWarning}
            shape="circle"
            icon={<CloseOutlined />}
          />
        </Popover>
        <div
          onClick={blockEdit}
          className='block-internal'
        >
          {stepBlockComp}
          {editing ? (
            <CancelAndConfirm
              toggleCancel={this.toggleBlockEdit}
              onConfirm={this.toggleBlockEdit}
            />
          ) : null}
        </div>
      </td>
    );
  }
}

export default StepBlock;
