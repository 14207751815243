import 'whatwg-fetch';
import { fromJS, List, Map } from 'immutable';
import { checkStatus, buildURL, removeParam, CHANGE_LOG_PROD_URL, CONSENT_PERFORMANCE_REPORT_BASEURL,divideByZeroErrChk } from '../helper.js';
import { DataRequestReport,DataRequestReportAll,DataRequestReportExport } from '../../records/datarequest_records.js';
import { DATEPICKERS} from '../../constants';
import moment from 'moment';


export async function getAndParseDataRequestData(data, rangeSelect ,requestType,selectedTab) {
    const propertyPromises = data.map((d, i)=>{
        const formattedData = d
        .set("startDate", rangeSelect.get("startDate").format("YYYY-MM-DD"))
        .set("endDate", rangeSelect.get("endDate").format("YYYY-MM-DD"))
        .toMap()
        .filter(v => (List.isList(v) ? v.size : v))
        .toJS();
       
        
        let fetchUrl =
        buildURL(
          CONSENT_PERFORMANCE_REPORT_BASEURL + "/dsar",
          formattedData
        );
        return fetch(fetchUrl)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(resp =>  aggregateRequestData(fromJS(resp), i,requestType,selectedTab));
    });
    
    return Promise.all(propertyPromises).then(res => {return List(res)});
}


function aggregateRequestData( data, index,requestType,selectedTab){
    let groupedByYmdOrderedMap;
    let groupedByYmdOrderedMapByType;
    let groupByYmdTypeAndDate;
    let allRequestData = List();
    let exportData= List();
    let aggRequestGraphData = List();
    let aggTotal = 0;
    let aggTotalAll = 0;
    let aggTotalRecieved = 0;
    let aggTotalUploaded = 0;
    let aggTotalCompleted = 0;
    let aggTotalDelievered = 0;
    let aggTotalValid = 0;
    let aggTotalInValid = 0;
    let aggTotalWithIndue = 0;
    let aggTotalOverDue = 0;
    let aggTotalUploadedOverDue = 0;
    let aggTotalUploadedWithInDue = 0;

    let aggTotalCompletedOverDue = 0;
    let aggTotalCompletedWithInDue = 0;
  
    let aggTotalDelieveredOverDue = 0;
    let aggTotalDelieveredWithInDue = 0;
    let dsarResult = 0;
    let dnsResult = 0;
    let deleteResult = 0;
    let aggRequestTableData;
   
    let graphKey = 0;
    let dsar_data = List();
    let dns_data = List();
    let delete_data = List();
    let request_type
    let total_request = 0;
   
    aggTotal = data.get('performanceData').get('total');
   
    dsarResult = data.get('performanceData').get('dsarResults');
   
  
    groupedByYmdOrderedMapByType = data.get('results').groupBy((dataPoint) => dataPoint.get('type'));
    
    groupedByYmdOrderedMapByType.map((item,i) => {
           groupByYmdTypeAndDate = item.groupBy(dataPoint => dataPoint.get('ymd'))
          
           
           groupByYmdTypeAndDate.map((p,i) => {
            
            let requestType = p.map(entry => entry.get('type')).first()
           
            let total_valid = p.map(entry => Number(entry.get('total_valid'))).reduce((prev, current) => prev + current);
            let total_invalid = p.map(entry => Number(entry.get('total_invalid'))).reduce((prev, current) => prev + current);
            let total_withduedate = p.map(entry => Number(entry.get('total_received_within_duedate'))).reduce((prev, current) => prev + current);
            let total_over_due = p.map(entry => Number(entry.get('total_received_overdue'))).reduce((prev, current) => prev + current);

            let total_recieved = total_valid + total_invalid + total_withduedate + total_over_due;

            //total uploaded
            let total_uploaded_withdue = p.map(entry => Number(entry.get('total_uploaded_within_duedate'))).reduce((prev, current) => prev + current);
            let total_uploaded_over_due = p.map(entry => Number(entry.get('total_uploaded_overdue'))).reduce((prev, current) => prev + current);
            let total_uploaded = total_uploaded_withdue + total_uploaded_over_due;

             //total completed
             let total_completed_withdue = p.map(entry => Number(entry.get('total_completed_within_duedate'))).reduce((prev, current) => prev + current);
             let total_completed_over_due = p.map(entry => Number(entry.get('total_completed_overdue'))).reduce((prev, current) => prev + current);
            let total_completed = total_completed_withdue + total_completed_over_due;

              //total delievered
              let total_delievered_withdue = p.map(entry => Number(entry.get('total_delivered_within_duedate'))).reduce((prev, current) => prev + current);
              let total_delievered_over_due = p.map(entry => Number(entry.get('total_delivered_overdue'))).reduce((prev, current) => prev + current);
              let total_delievered = total_delievered_withdue + total_delievered_over_due

              let total_request_withdue = total_withduedate + total_uploaded_withdue + total_completed_withdue + total_delievered_withdue;
              let total_request_overdue = total_over_due + total_uploaded_over_due + total_completed_over_due + total_delievered_over_due;
              
              if(requestType === 'DSAR'){
                total_request = total_recieved + total_uploaded + total_completed + total_delievered;
              }else if(requestType === 'dns'){
                total_request = total_recieved;
              }else if(requestType === 'DELETE'){
                total_request = total_recieved;
              }else{
                total_request = total_recieved;
              }
              
              graphKey++;

              let total_request_value = total_request;
              
              if(selectedTab === "1"){
                allRequestData =  allRequestData.push(Map({
                    date:i,
                    total:total_request,
                    totalrequest:total_request,
                    requestType:requestType,
                    valid: total_valid,
                    invalid: total_invalid,
                    withduedate: total_request_withdue,
                    overdue: total_request_overdue,
                    uploaded:total_uploaded,
                    completed:total_completed,
                    delievered:total_delievered,
                    totalRecieved:total_recieved
                }))
                total_request_value = total_request;
                aggRequestGraphData = aggRequestGraphData.push(Map({
                                                                    key:graphKey,
                                                                    requestType:requestType,
                                                                    fancyDate: moment(i, "YYYY-MM-DD").format("MMM Do"),
                                                                    date: i,
                                                                    ['totalrequest'+index]: total_request,
                                                                    ['totalvalid'+index]: Number(((total_valid/total_request)*100).toFixed(2)),
                                                                    ['totalinvalid'+index]: Number(((total_invalid/total_request)*100).toFixed(2)),
                                                                    ['totalwithduedate'+index]: Number(((total_request_withdue/total_request)*100).toFixed(2)),
                                                                    ['totaloverdue'+index]: Number(((total_request_overdue/total_request)*100).toFixed(2)),
                                                                    ['siteIds'+index]: p.map(entry => [(entry.get('site_id'))] ).reduce((prev, current) => prev.concat(current)),
                                                                }));
              }else if(selectedTab === "3"){
                allRequestData =  allRequestData.push(Map({
                    date:i,
                    total:total_request,
                    totalrequest:total_uploaded,
                    requestType:requestType,
                    valid: total_valid,
                    invalid: total_invalid,
                    totalRecieved:total_recieved,
                    withduedate: total_uploaded_withdue,
                    overdue: total_uploaded_over_due,
                    uploaded:total_uploaded,
                    completed:total_completed,
                    delievered:total_delievered,
    
    
                }));
                total_request_value = total_uploaded;
                aggRequestGraphData = aggRequestGraphData.push(Map({
                                                                    key:graphKey,
                                                                    requestType:requestType,
                                                                    fancyDate: moment(i, "YYYY-MM-DD").format("MMM Do"),
                                                                    date: i,
                                                                    ['totalrequest'+index]: total_uploaded,
                                                                    ['totalvalid'+index]: Number(((total_valid/total_uploaded)*100).toFixed(2)),
                                                                    ['totalinvalid'+index]: Number(((total_valid/total_uploaded)*100).toFixed(2)),
                                                                    ['totalwithduedate'+index]: Number(((total_uploaded_withdue/total_uploaded)*100).toFixed(2)),
                                                                    ['totaloverdue'+index]: Number(((total_uploaded_over_due/total_uploaded)*100).toFixed(2)),
                                                                    ['siteIds'+index]: p.map(entry => [(entry.get('site_id'))] ).reduce((prev, current) => prev.concat(current)),
                                                                }));
              }else if(selectedTab === "2"){
                allRequestData =  allRequestData.push(Map({
                    date:i,
                    total:total_request,
                    totalrequest:total_recieved,
                    requestType:requestType,
                    valid: total_valid,
                    invalid: total_invalid,
                    totalRecieved:total_recieved,
                    withduedate: total_withduedate,
                    overdue: total_over_due,
                    uploaded:total_uploaded,
                    completed:total_completed,
                    delievered:total_delievered,
    
    
                }));
                total_request_value = total_recieved;
                aggRequestGraphData = aggRequestGraphData.push(Map({
                                                                    key:graphKey,
                                                                    requestType:requestType,
                                                                    fancyDate: moment(i, "YYYY-MM-DD").format("MMM Do"),
                                                                    date: i,
                                                                    ['totalrequest'+index]: total_recieved,
                                                                    ['totalvalid'+index]: Number(((total_valid/total_recieved)*100).toFixed(2)),
                                                                    ['totalinvalid'+index]: Number(((total_invalid/total_recieved)*100).toFixed(2)),
                                                                    ['totalwithduedate'+index]: Number(((total_withduedate/total_recieved)*100).toFixed(2)),
                                                                    ['totaloverdue'+index]: Number(((total_over_due/total_recieved)*100).toFixed(2)),
                                                                    ['siteIds'+index]: p.map(entry => [(entry.get('site_id'))] ).reduce((prev, current) => prev.concat(current)),
                                                                }));

              }else if(selectedTab === "4"){
                allRequestData =  allRequestData.push(Map({
                    date:i,
                    total:total_request,
                    totalrequest:total_completed,
                    requestType:requestType,
                    valid: total_valid,
                    invalid: total_invalid,
                    totalRecieved:total_recieved,
                    withduedate: total_completed_withdue,
                    overdue: total_completed_over_due,
                    uploaded:total_uploaded,
                    completed:total_completed,
                    delievered:total_delievered,
    
    
                }));
                total_request_value = total_completed;
                aggRequestGraphData = aggRequestGraphData.push(Map({
                                                                    key:graphKey,
                                                                    requestType:requestType,
                                                                    fancyDate: moment(i, "YYYY-MM-DD").format("MMM Do"),
                                                                    date: i,
                                                                    ['totalrequest'+index]: total_completed,
                                                                    ['totalvalid'+index]: Number(((total_completed_withdue/total_completed)*100).toFixed(2)),
                                                                    ['totalinvalid'+index]: Number(((total_completed_over_due/total_completed)*100).toFixed(2)),
                                                                    ['totalwithduedate'+index]: Number(((total_withduedate/total_completed)*100).toFixed(2)),
                                                                    ['totaloverdue'+index]: Number(((total_over_due/total_completed)*100).toFixed(2)),
                                                                    ['siteIds'+index]: p.map(entry => [(entry.get('site_id'))] ).reduce((prev, current) => prev.concat(current)),
                                                                }));

            }else if(selectedTab === "5"){
                allRequestData =  allRequestData.push(Map({
                    date:i,
                    total:total_request,
                    totalrequest:total_delievered,
                    requestType:requestType,
                    valid: total_valid,
                    invalid: total_invalid,
                    totalRecieved:total_recieved,
                    withduedate: total_delievered_withdue,
                    overdue: total_delievered_over_due,
                    uploaded:total_uploaded,
                    completed:total_completed,
                    delievered:total_delievered,
    
                }));
                total_request_value = total_delievered;
                aggRequestGraphData = aggRequestGraphData.push(Map({
                  key:graphKey,
                  requestType:requestType,
                  fancyDate: moment(i, "YYYY-MM-DD").format("MMM Do"),
                  date: i,
                  ['totalrequest'+index]: total_delievered,
                  ['totalvalid'+index]: Number(((total_valid/total_delievered)*100).toFixed(2)),
                  ['totalinvalid'+index]: Number(((total_valid/total_delievered)*100).toFixed(2)),
                  ['totalwithduedate'+index]: Number(((total_delievered_withdue/total_delievered)*100).toFixed(2)),
                  ['totaloverdue'+index]: Number(((total_delievered_over_due/total_delievered)*100).toFixed(2)),
                  ['siteIds'+index]: p.map(entry => [(entry.get('site_id'))] ).reduce((prev, current) => prev.concat(current)),
              }));

            }

            exportData = exportData.push(Map({
              date:i,
              total:total_request_value,
              totalrequest:total_request_value,
              requestType:requestType,
              valid: total_valid,
              valid_per: divideByZeroErrChk(Number(total_valid) , Number(total_request_value))  + "%",
              invalid: total_invalid,
              invalid_per:divideByZeroErrChk(Number(total_invalid) , Number(total_request_value)) + "%",
              withduedate: total_request_withdue,
              withduedate_per:divideByZeroErrChk(Number(total_request_withdue) , Number(total_request_value)) + "%",
              overdue: total_request_overdue,
              overdue_per: divideByZeroErrChk(Number(total_request_overdue) , Number(total_request_value)) + "%",
             
          }))
            
            aggTotalAll += total_request;
            aggTotalValid += total_valid;
            aggTotalInValid += total_invalid;
            aggTotalWithIndue += total_withduedate;
            aggTotalOverDue += total_over_due;
            aggTotalUploaded +=total_uploaded
            aggTotalCompleted +=total_completed
            aggTotalDelievered +=total_delievered
            aggTotalRecieved+=total_recieved
           })

         
          
           
          
        
    })

    aggRequestTableData = Map({
        key: index,
        date: 'Criteria '+Number(index+1),
        total:aggTotal,
        totalDsar:dsarResult,
        totalDns:dnsResult,
        totalDeleteMyData:deleteResult,
        totalAll:aggTotalAll,
        totalRecieved:aggTotalRecieved,
        dataArr: allRequestData
        .sort(
        (a, b) =>
          moment(b.get("date"), "YYYY-MM-DD").valueOf() -
          moment(a.get("date"), "YYYY-MM-DD").valueOf()
        )
        .map((s, i) => dataRequestReportToRecord(s, i)),
        exportdata: exportData
        .sort(
          (a, b) =>
            moment(b.get("date"), "YYYY-MM-DD").valueOf() -
            moment(a.get("date"), "YYYY-MM-DD").valueOf()
        )
        .map((s, i) => dataRequestReportToExport(s, i))
       
       });
       
   
    
    return Map({ dataRequestTableData: dataRequestReportToRecordAll(aggRequestTableData, index), 'dataGraph': aggRequestGraphData });
    
    
    
}

function dataRequestReportToRecord(data, index) {
    return DataRequestReport({ 
                                key: index,
                                date: data.get("date"),
                                requestType:data.get("requestType"), 
                                validrequest: data.get("valid"),
                                validrequestper:data.get("valid_per"),
                                invalidrequest: data.get("invalid"),
                                invalidrequestper: data.get("invalid_per"),
                                overdue: data.get("overdue"),
                                overdueper: data.get("overdue_per"),
                                total:data.get("total"),
                                totalrequest:data.get("totalrequest"),
                                withindue: data.get("withduedate"),
                                withindueper: data.get("withduedate_per"),
                                totaluploaded:data.get("uploaded"),
                                totalRecieved:data.get("totalRecieved"),
                                totalcompleted:data.get("completed"), 
                                totaldelievered:data.get("delievered"),
                                aggData: data.get('dataArr') ? data.get('dataArr') : List(),
                            });
}

function dataRequestReportToExport(data, index) {
  return DataRequestReportExport({ 
                              key: index,
                              date: data.get("date"),
                              totalrequest:data.get("totalrequest"),
                              requestType:data.get("requestType"), 
                              validrequest: data.get("valid"),
                              validrequestper:data.get("valid_per"),
                              invalidrequest: data.get("invalid"),
                              invalidrequestper: data.get("invalid_per"),
                              overdue: data.get("overdue"),
                              overdueper: data.get("overdue_per"),
                              withindue: data.get("withduedate"),
                              withindueper: data.get("withduedate_per"),
                          });
}



function dataRequestReportToRecordAll(data, index) {
    return DataRequestReportAll({ 
                                key: index,
                                date: data.get("date"),
                                totalRequest:data.get("total"),
                                totalDSAR:data.get("totalDsar"),
                                totalDelete:data.get("totalDeleteMyData"),
                                totalAll:data.get("totalAll"),
                                totalRecieved:data.get("totalRecieved"),
                                aggData: data.get('dataArr') ? data.get('dataArr') : List(),
                                exportData: data.get('exportdata') ? data.get('exportdata') : List(),
                            });
}