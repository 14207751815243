import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PageHeader from '../../common/PageHeader.js.jsx';
import SortFilter from '../../common/SortFilter.js.jsx';
import CreateForm from '../../common/CreateForm.js.jsx';
import CreateButton from '../../common/CreateButton.js.jsx';
import PartitionsetList from "./PartitionsetList.js.jsx";
import ImportButton from '../../common/ImportButton.js.jsx';
import ImportForm from '../../common/ImportForm.jsx';
import Loading from '../../common/Loading.js.jsx';
import SitesRedirect from '../campaigns/SitesRedirect';
import PropertyTag from '../../common/PropertyTag';
import TemplateButton from '../scenariosNew/common/TemplateButton.jsx';

import { getAllSites, getSiteGroup, getSiteGroups } from '../../../actions/site_actions';
import { getDraftScenarios } from '../../../actions/scenario_actions';
import {
  getPartitionsets,
  createPartitionset,
  createSiteGroupPartitionset,
  updatePartitionset,
  updateSiteGroupPartitionset,
  deletePartitionset,
  deleteSiteGroupPartitionset,
  importPartitionset,
} from '../../../actions/partitionset_actions';
import { Partitionset } from '../../../records/partitionset_records.js';
import { Scenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { getParameterByName } from '../../utils.js';
import { Site } from '../../../records/site_records.js';
import { User } from '../../../records/account_records';

import { STATUS_TYPES as STATUS, CAMPAIGN_CODES } from '../../../constants';
import { siteAndGroupLabel } from '../helper';
import SiteGroupRedirect from '../../common/SiteGroupRedirect';
import PropertiesUnderGroupRedirection from '../../common/PropertiesUnderGroupRedirection';
import CampaignsCheckboxFilter from '../../common/CampaignsCheckboxFilter';
import CreatePartitionSet from './CreatePartitionSet';
import { message, Button, Headline } from '../../../styleguide';

export class PartitionsetsPage extends React.Component {
    static propTypes = {
        partitionsets: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Partitionset)).isRequired,
        scenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
        allSites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
        pendingRequestsMap: ImmutablePropTypes.map.isRequired,
        partitionsetsForImport: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Partitionset)).isRequired,
        route: PropTypes.shape({
          currentUser: PropTypes.instanceOf(User).isRequired,
        }).isRequired,

        getPartitionsets: PropTypes.func.isRequired,
        getDraftScenarios: PropTypes.func.isRequired,
        updatePartitionset: PropTypes.func.isRequired,
        updateSiteGroupPartitionset: PropTypes.func.isRequired,
        getAllSites: PropTypes.func.isRequired,
        createPartitionset: PropTypes.func.isRequired,
        createSiteGroupPartitionset: PropTypes.func.isRequired,
        importPartitionset: PropTypes.func.isRequired,
        getSiteGroup: PropTypes.func.isRequired,
        getSiteGroups: PropTypes.func.isRequired,
    };

    state = {
      displayCreateForm: false,
      displayImportMenu: false,
      filteredPartitionsets: this.props.partitionsets,
      copyScenarios: false,
      copyMessages: false,
      checkedCampaignTypes: null,
    };

    componentDidMount() {
        const siteGroupId = this.props.location.query.site_group_id;
        if (siteGroupId) {
          this.props.getSiteGroup(siteGroupId);
        }
        const redirectSiteGroupId = this.props.location.query.sitegroup_id;
        if(redirectSiteGroupId){
         this.props.getSiteGroup(redirectSiteGroupId);
        }
        this.props.getAllSites();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.pendingRequestsMap) {
            if (this.props.pendingRequestsMap.get('partitionsets') && !newProps.pendingRequestsMap.get('partitionsets')) {
                this.setState({ displayCreateForm: false });
            }
            if (this.props.pendingRequestsMap.get('import') && !newProps.pendingRequestsMap.get('import')) {
                this.setState({ displayImportMenu: false });
            }
        }

        if (this.props.location.query.site_id !== newProps.location.query.site_id) {
          this.props.getPartitionsets(newProps.location.query.site_id)
          this.props.getDraftScenarios(newProps.location.query.site_id);
        }

        if (!this.state.checkedCampaignTypes && newProps.allSites.size) {
          const site = newProps.allSites.find((s) => s.id === parseInt(newProps.siteId));
          const isMultiCampaignEnabled = site ? site.multiCampaignEnabled : false;

          this.setState({checkedCampaignTypes: isMultiCampaignEnabled ? CAMPAIGN_CODES : [0]})
        }
    }

    toggleImportMenu = () => {
        this.setState({ displayImportMenu: !this.state.displayImportMenu })
    };

    toggleCreateForm = () => {
        this.setState({ displayCreateForm: !this.state.displayCreateForm });
    };

    getPartitionByName = (partitionset) => {
      return this.props.partitionsets.find(p => p.description === partitionset.description);
    }

    importPartitionset = (partitionset, options = {copyScenarios: false, copyMessages: false}, skipValidation) => {
        const siteGroupId = this.props.location.query.site_group_id;
        let updatedPartitionSet;
        if(!skipValidation) {
          let description = partitionset.get('description');
          const copiedDescription = description + ' (copy)';
          updatedPartitionSet = partitionset.set('description', copiedDescription);
          const warningMessage = `New Partition set name will be '${copiedDescription}' which is already exist. Please update name of partition set '${description}' or '${copiedDescription}'.`;
          const duplicateName = this.getPartitionByName(updatedPartitionSet);
          if(duplicateName) {
            message.warning(warningMessage);
            return;
          }
        }

        if (siteGroupId) {
            const willImport = true;
            this.props.createSiteGroupPartitionset(siteGroupId, this.props.currentUser.accountId, updatedPartitionSet, willImport);
        } else {
            this.props.importPartitionset(this.props.location.query.site_id, partitionset.id, options);
        }
    };

    onCheckedMessages = (checked) => {
      this.setState({
        copyMessages: checked,
      });
    };

    onCheckedScenarios = (checked) => {
      this.setState({
        copyScenarios: checked,
      });
    };

    createPartitionset = async (partitionSet) => {
      const duplicateName = this.getPartitionByName(partitionSet);
      if(duplicateName) {
        message.warning(`Partition set with name '${partitionSet.description}' already exist`);
        return;
      }

      const partitionset = new Partitionset({ description: partitionSet.description, site_id: this.props.location.query.site_id, campaign_type_id: partitionSet.campaign_type_id, partitions: partitionSet.partitions });
      const siteGroupId = this.props.location.query.site_group_id;
      if (siteGroupId) {
        const accountId = this.props.currentUser.accountId;
          const status = await this.props.createSiteGroupPartitionset(siteGroupId, accountId, partitionset);
          const notice = status === "success" ? `Partition set '${partitionSet.description}' created sucessfully` : `Unable to create partition set for property group, please try again.`;
          message[status](notice);
      } else {
        this.props.createPartitionset(partitionset).then(() => message.success(`Partition set '${partitionSet.description}' created sucessfully`));
      }
    }

    redirectToGroup = () => browserHistory.push(`${this.props.location.pathname}?site_id=${this.props.siteGroupRedirect.siteId}&site_group_id=${this.props.siteGroupRedirect.id}`);

    setCampaignTypes = (checkedCampaignTypes) => {
      this.setState({ checkedCampaignTypes });
    }

    getFilteredPartitions = () => {
      return this.state.filteredPartitionsets.filter(ps => this.state.checkedCampaignTypes && this.state.checkedCampaignTypes.includes(ps.campaign_type_id));
    }

    render() {

      const { currentUser } = this.props;

      let readOnly = false;
      if (this.props.currentUser.featureAccess &&
        !this.props.currentUser.featureAccess.includes('Campaign Entities')) {
        readOnly = true;
      }

      const siteId = this.props.location.query.site_id;
      let propertyType;
      let domain;
      const site = this.props.allSites.size ? this.props.allSites.find(s => s.id === parseInt(siteId)) : '';
      const isMultiCampaignEnabled = site ? site.multiCampaignEnabled : false;
      if (!this.props.location.query.site_group_id) {
        domain = site ? site.domain : '';
        propertyType = site ? site.type : '';
      } else {
        domain = this.props.siteGroup && this.props.siteGroup.name;
      }

      let createForm;
      let importMenuForm;

      let switchOptions;
      if (!this.props.location.query.site_group_id) {
        switchOptions = [
          {
            isChecked: this.state.copyScenarios,
            onChecked: this.onCheckedScenarios,
            popupText: 'Copy with Scenarios',
            disabled: false,
          },
          {
            // isChecked considers the state of copyScenarios because when
            // copyScenarios is false, then copyMessages is automatically false,
            // and disabled
            isChecked: this.state.copyScenarios && this.state.copyMessages,
            onChecked: this.onCheckedMessages,
            popupText: 'Copy with Messages',
            disabled: !this.state.copyScenarios,
          },
        ];
      }

      if (this.state.displayImportMenu) {
          let sites = this.props.allSites;
          if (this.props.currentUser.siteAccess) {
            sites = this.props.allSites.filter(s => this.props.currentUser.siteAccess.includes(s.id));
          }
          importMenuForm = (
              <ImportForm
                siteId={ siteId }
                type="partition set"
                sites={ sites }
                fetchItemsForTheSite={ this.props.getPartitionsets }
                storedItems={ this.props.partitionsetsForImport }
                importItem={ this.importPartitionset }
                closeForm={ this.toggleImportMenu }
                switchOptions={ switchOptions }
                visible={ this.state.displayImportMenu }
                isMultiCampaignEnabled={isMultiCampaignEnabled}
              />
          );
      }

      const pending = this.props.pendingRequestsMap.some(value => value);

      if (this.state.displayCreateForm) {
          createForm = (
              <CreatePartitionSet
                isModalVisible={ this.state.displayCreateForm }
                handleCancel={ this.toggleCreateForm }
                handleOk={ this.createPartitionset }
                propertyType={ propertyType}
                currentUser= { currentUser }
                scenarios={ this.props.scenarios }
                isMultiCampaignEnabled={ isMultiCampaignEnabled }
              />
          );
      }

      const containerClass = "partitionsets-container";

      let loading;

      if (pending) loading = <Loading />;

      let importButton;
      let createButton;
      if (!readOnly) {
        importButton = <ImportButton onClick={ this.toggleImportMenu } disabled={ this.state.displayCreateForm || this.state.displayImportMenu }>IMPORT</ImportButton>;
        createButton = <CreateButton onClick={ this.toggleCreateForm } disabled={ this.state.displayCreateForm || this.state.displayImportMenu } >NEW</CreateButton>;
      }

      let updatePartitionset;
      let deletePartitionset;
      let siteGroupSiteList;
      let siteGroupSitesWithErrors;
      const siteGroupId = this.props.location.query.site_group_id;
      if (siteGroupId) {
        const accountId = this.props.currentUser.accountId;
        updatePartitionset = this.props.updateSiteGroupPartitionset.bind(null, siteGroupId, accountId);
        deletePartitionset = this.props.deleteSiteGroupPartitionset.bind(null, siteGroupId, accountId);
        siteGroupSiteList = <PropertiesUnderGroupRedirection pageKind='partitions'/>;
        if (this.props.siteGroupSitesWithErrors.size) {
          siteGroupSitesWithErrors = (
            <SitesRedirect
              pageKind='partitions'
              sites={ this.props.siteGroupSitesWithErrors.map(options => this.props.allSites.find(s => s.id === options.get('siteId')))}
              isForErrors
            />
          )
        }
      } else {
        updatePartitionset = this.props.updatePartitionset;
        deletePartitionset = this.props.deletePartitionset;
      }

      let backToSitesGroup;
      if(this.props.siteGroupRedirect){
        backToSitesGroup = <SiteGroupRedirect redirectToGroup={this.redirectToGroup} siteGroup={this.props.siteGroupRedirect}/>;
      }
 
      const campaignFilters = isMultiCampaignEnabled && <CampaignsCheckboxFilter currentUser={currentUser} propertyType={propertyType} setCampaignTypes={this.setCampaignTypes}/>;

      const partitionSets = this.getFilteredPartitions();

      const propertyTag = propertyType && <PropertyTag type={ propertyType }/>;

     return (
        <div className={ containerClass }>
          { loading }
          { createForm }
          { backToSitesGroup }
          <Headline>Partition Sets</Headline>
          <div className="sceanrio-domain-wrapper">
            <span className="domain-name">
              <span className="page-label">{siteAndGroupLabel(this.props.location.query.site_group_id)}</span>
              <span className="page-title">{domain}</span>
              { propertyTag }
            </span>
            { !readOnly && (
              <div className="scenario-add-options">
                <Button type="secondary" disabled={ this.state.displayCreateForm || this.state.displayImportMenu } onClick={this.toggleImportMenu}>
                  Import
                </Button>
                <Button type="primary" disabled={ this.state.displayCreateForm || this.state.displayImportMenu } onClick={this.toggleCreateForm} disabled={false} style={{marginLeft: '20px'}}>
                  New
                </Button>
              </div>
            )}
          </div>

          { siteGroupSiteList }
          <SortFilter
            data={ this.props.partitionsets }
            searchField={ 'description' }
            sorters={ [
              SortFilter.dateSorter,
              SortFilter.descriptionSorter,
            ] }
            onUpdate={ (filteredPartitionsets) => {
              this.setState({ filteredPartitionsets });
            } }
          />
          { importMenuForm }
          { siteGroupSitesWithErrors }
          { campaignFilters }
          <PartitionsetList
            partitionsets={ partitionSets }
            scenarios={ this.props.scenarios }
            duplicatePartition={ this.importPartitionset }
            updatePartitionset={ updatePartitionset }
            pendingRequestsMap={ this.props.pendingRequestsMap }
            deletePartitionset={ deletePartitionset }
            getSiteGroups={ this.props.getSiteGroups }
            currentUser={ this.props.currentUser }
            createSiteGroupPartitionset={ this.props.createSiteGroupPartitionset }
            readOnly={ readOnly }
            renderActiveCampaignWarning={ this.props.renderActiveCampaignWarning }
            propertyType={ propertyType }
            isMultiCampaignEnabled={ isMultiCampaignEnabled }
            getPartitionByName={ this.getPartitionByName }
            error={ this.props.error }
          />
        </div>
      );
    }
}

const mapStateToProps = function(store){

    const siteId = parseInt(getParameterByName('site_id', window.location));

    return {
        siteId,
        currentUser: store.accountState.getIn(['userDetails', 'value']),
        partitionsets: store.partitionsetState.getIn(['partitionsets', 'value']).filter(p => p.site_id === siteId),
        scenarios: store.scenarioState.getIn(['scenarios', 'value']).filter(s => s.status === STATUS.DRAFT && s.site_id === siteId),
        allSites: store.siteState.getIn(['sites', 'value']),
        partitionsetsForImport: store.partitionsetState.getIn(['partitionsets', 'value']).filter(p => p.site_id !== siteId),
        siteGroup: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('site_group_id', window.location)),
        siteGroupRedirect: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('sitegroup_id', window.location)),
        siteGroupSitesWithErrors: store.siteState.get('siteGroupSitesWithErrors'),
        error: store.partitionsetState.getIn(['partitionsets', 'error']),
        pendingRequestsMap: Map({
            partitionsets: store.partitionsetState.getIn(['partitionsets', 'pending']),
            scenarios: store.scenarioState.getIn(['scenarios', 'pending']),
            allSites: store.siteState.getIn(['sites', 'pending']),
            import: store.partitionsetState.get('importPending'),
        }),
    };
}

export default connect(
    mapStateToProps, {
        getPartitionsets,
        getDraftScenarios,
        createPartitionset,
        createSiteGroupPartitionset,
        updatePartitionset,
        updateSiteGroupPartitionset,
        deletePartitionset,
        deleteSiteGroupPartitionset,
        getAllSites,
        importPartitionset,
        getSiteGroup,
        getSiteGroups,
    }
)(PartitionsetsPage);
