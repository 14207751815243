import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { Map } from 'immutable';
import { Select } from 'antd';

import AntdFormItem from '../../../common/AntdFormItem.jsx';
import { ChoiceConfigRecord } from '../../../../records/choice_config_records.js';
import { CONSTANTS, COUNTRY_LANGUAGES } from '../../../../constants.js';

const Option = Select.Option;

export default class extends React.Component {
    static propTypes = {
        choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
        editing: PropTypes.bool.isRequired,
        form: PropTypes.object.isRequired,
        listIndex: PropTypes.number.isRequired,
        onChoiceConfigChange: PropTypes.func.isRequired,
    };

    handleLanguage = (languageCode) => {
        if(languageCode) {
            const oldChoiceConfig = this.props.choiceConfig
            const choiceConfig = oldChoiceConfig.setIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA, CONSTANTS.CONSENT_LANGUAGE],  languageCode)
            this.props.onChoiceConfigChange(choiceConfig);
        }
    }

    onChange = (newVal) => {
        let optionData = this.props.choiceConfig.getIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA]);
        optionData = optionData.merge(newVal);
        const oldChoiceConfig = this.props.choiceConfig;
        const choiceConfig = oldChoiceConfig.setIn(['option_list', this.props.listIndex, 'option_data'], optionData);
        this.props.onChoiceConfigChange(choiceConfig);
    };

    render() {
        const optionData = this.props.choiceConfig.getIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA]);
        const classes = classNames('label-title');

        return (
            <div className='choice-option'>
                <div className={ classes }>
                    <label>Consent Language</label>
                    <Select
                        defaultValue={optionData.get('consent_language') || 'EN'}
                        onChange={ this.handleLanguage }
                        placeholder='Select a language...'
                    >
                        { COUNTRY_LANGUAGES.map((o) => <Option key={o.code} >{o.language}</Option>) }
                    </Select>
                    <AntdFormItem
                        fieldLabel='Button Text'
                        fieldName={ 'consent_button_text_' + this.props.listIndex }
                        form={ this.props.form }
                        initialValue={ optionData.get('button_text') }
                        onChange={ (event) => this.onChange(Map({ button_text: event.target.value })) }
                        placeholder=''
                        editing={ this.props.editing }
                    />
                </div>
            </div>
        );
    }
}
