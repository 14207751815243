import 'whatwg-fetch';
import { List } from 'immutable';
import { checkStatus, toFormData, isFetched, addToFetched } from '../helper';
import { FETCHED } from '../../constants';
import { Site, SiteGroup } from '../../records/site_records';

// const siteGroupUrl = window.CONFIG && window.CONFIG.siteGroupServiceEndpoint;
// const siteGroupUrl = 'https://msg-api.sp-prod.net';
const siteGroupUrl = 'https://msg-api.sp-stage.net';
// const siteGroupUrl = '/msg-api.sp-stage.net';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export function getSiteGroupsAjax(user) {
  return fetch(`${permissionsSvsBase}/site-group/get-site-group`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp).map(s => new SiteGroup(s));
    })
}

export function getSiteGroupAjax(siteGroupId) {
  return fetch(`${permissionsSvsBase}/site-group/${siteGroupId}`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {return new SiteGroup(resp)});
}

export function createSiteGroupAjax(accountId, siteGroup) {
  return fetch(`${permissionsSvsBase}/site-group`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      name: siteGroup.name,
      description: siteGroup.description,
      siteIds: siteGroup.siteIds.toJS(),
      _id: siteGroup.id,
      accountId,
      multiCampaignEnabled: siteGroup.multiCampaignEnabled
    }),
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => new SiteGroup(resp));
}

export function updatePropertyGroupAjax(accountId, siteGroup) {

  const fieldsTobeUpdated = getSiteGroupFieldsTobeUpdated(siteGroup);

  return fetch(`${permissionsSvsBase}/site-group/site?siteGroupId=${ siteGroup.siteGroupId || siteGroup.id }&siteId=${ siteGroup.siteId }`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({ ...fieldsTobeUpdated, accountId }),
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => siteToRecord(resp.siteGroupSite));
}

export function updateSiteGroupAjax(accountId, siteGroup, startCampaigns) {
  let setCampaign = '';
  if(startCampaigns){
    setCampaign = '&startCampaigns=true';
  }
  return fetch(`${ permissionsSvsBase }/site-group?siteGroupId=${ siteGroup.id }${setCampaign}`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({
      _id: siteGroup.id,
      accountId,
      name: siteGroup.name,
      description: siteGroup.description,
      siteIds: siteGroup.siteIds,
    }),
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => new SiteGroup(resp));
}

export function deleteSiteGroupAjax(accountId, siteGroupId, siteId) {
  return fetch(`${ permissionsSvsBase }/site-group?siteGroupId=${ siteGroupId }&siteId=${ siteId }`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    body: JSON.stringify({
      siteGroupId,
      accountId,
    }),
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => resp._id);
}

export function getAllSitesAjax(options) {
    let url = `${permissionsSvsBase}/msg/get_site_list`;

    if (typeof options === 'object' && options !== null && options.allSites) {
      url += '?allSites=true';
    }
    // if (isFetched(url)) {
    //     return Promise.resolve(FETCHED);
    // } else {
        return fetch(url, { credentials: 'include' })
            .then(checkStatus)
            .then(resp => resp.json())
            .then(resp => {
                // addToFetched(url);
                return List(resp.data.site_list).map(s => siteToRecord(s));
            });
    // }
}

export function createSiteAjax(domain, type, multiCampaignEnabled) {
  const queryString = `?type=${type}&multi_campaign_enabled=${multiCampaignEnabled}&domain=${domain}`;
  
  return fetch(`${permissionsSvsBase}/msg/create_site` + queryString, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ domain }),
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {; return siteToRecord(resp.data.site)})
}

export function deleteSiteAjax(site_id) {
    return fetch(`${permissionsSvsBase}/msg/delete_site?site_id=${site_id}`, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ site_id }),
    }).then(checkStatus)
        // this is a hack but we need to get the error message from
        // the response rather than from the status text
        .catch(err => {
            return err.response.text().then(text => {
                if (text) {
                    throw new Error(text);
                } else {
                    throw err;
                }
            });
        })
        .then(() => site_id)
}

export function updateSiteAjax(siteId, options) {
  /* options (fields need to be updated) - domain, type, public_campaign_type_priority, stage_campaign_type_priority, public_message_limit, stage_message_limit, multi_campaign_enabled */

  let queryString = '?site_id=' + siteId;

  Object.keys(options).map(function(key) {
    const isArray = Array.isArray(options[key]);
    const value = isArray ? encodeURIComponent(JSON.stringify(options[key])) : options[key];
    queryString += `&${key}=${value}`;
  });

  return fetch(`${permissionsSvsBase}/msg/update_site` + queryString, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => siteToRecord(resp.data.site))
}

function siteToRecord(site) {
    return Site({ 
      id: site.id, 
      domain: site.domain, 
      type: site.type, 
      createdAt: site.created_at,
      multiCampaignEnabled: site.multi_campaign_enabled,
      publicCampaignTypePriority: site.public_campaign_type_priority,
      publicMessageLimit: site.public_message_limit,
      stageCampaignTypePriority: site.stage_campaign_type_priority,
      stageMessageLimit: site.stage_message_limit
    });
}

function getSiteGroupFieldsTobeUpdated(siteGroup) {
   const orign = [ 
      'publicMessageLimit', 
      'stageMessageLimit', 
      'multiCampaignEnabled', 
      'publicCampaignPriority', 
      'stageCampaignPriority' 
    ];
   let updated = {};
   for(var i=0; i<orign.length; i++) {
     const field = orign[i];
     if(siteGroup.hasOwnProperty(field)) {
       updated[field] = siteGroup[field];
     }
   }
   return updated;
}