import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import CSVLink from "../../../common/CSVLink.js";
import { Input, Table } from 'antd';
import moment from 'moment';
import {
  getDomainSets,
  getAccuracySets,
} from '../../../../actions/domain_set_actions';
import { getSystemAccuracy, getSystemAccuracyTimeline } from '../../../../actions/accuracy_actions';
import Loading from '../../../common/Loading.js.jsx';
import { getRules } from '../../../../actions/standard_actions';
import { getAccountOwners } from '../../../../actions/account_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { regionsMap } from '../../../../constants';

const Search = Input.Search;


const languagesMap = {
  EN: 'English',
  DE: 'German',
  FR: 'French',
  SP: 'Spanish', 
  IT: 'Italian', 
  DU: 'Dutch', 
  SW: 'Sweedish',
};

const languageFilters = Object.values(languagesMap).map(language => {
  return ({
    text: language,
    value: language,
  });
});

const regionFilters = Object.values(regionsMap).map(region => {
  return ({
    text: region,
    value: region,
  });
});

class PropertySetEvaluation extends React.Component {
  static propTypes = {
    getAccountOwners: PropTypes.func.isRequired,
    goToManualScoring: PropTypes.func.isRequired,
    getSystemAccuracy: PropTypes.func.isRequired,
    getAccuracySets: PropTypes.func.isRequired,
  }

  state = {
    properties: {},
    search: '',
  }

  onCurrentUserAvailableDo = async () => {
    if (this.props.currentUser.accountId === 22) {
      this.props.getSystemAccuracy();
      this.props.getRules();
      this.props.getAccuracySets();
    }
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  getRuleFilters = () => {
    return this.props.rules.map(rule => {
      return ({
        text: `${rule.ruleId}: ${rule.name}`,
        value: rule.name,
      });
    });
  }

  generateAccuracyPerLanguageTable = () => {
    const columns = [
      {
        title: 'Languages',
        dataIndex: 'languages',
        key: 'languages',
        filters: languageFilters,
        onFilter: (value, record) => record.languages.startsWith(value),
        filterSearch: true,
      },  
      {
        title: 'Accuracy',
        dataIndex: 'accuracy',
        key: 'accuracy',
        sorter: (a, b) => {
          const num1 = parseInt(a.accuracy.slice(0,-1))
          const num2 = parseInt(b.accuracy.slice(0,-1))
          return num1 - num2;
        },
      },
      {
        title: 'Rule Breakdown',
        dataIndex: 'rules',
        key: 'rules',
      },
    ];

    const allRules = Object.values(this.props.systemAccuracy).filter(val => !(val === 200 || val === "application/json")).flat();
    const dataSource = [];

    Object.keys(languagesMap).forEach(lang => {
      const ruleCountMap = {};
      let rulesString = '';
      const langRules = allRules.filter(r => r.language === lang);
      const accuracy = `${((langRules.map(r => r.overallAccuracy).reduce((a, b) => a + b, 0) / langRules.length) * 100).toFixed()}% `;

      langRules.forEach(rule => {
        if (ruleCountMap[rule.ruleId]) {
          ruleCountMap[rule.ruleId] += rule.overallAccuracy;
        } else {
          ruleCountMap[rule.ruleId] = rule.overallAccuracy;
        }
      });

      ///XXX
      for (const ruleId in ruleCountMap) {
        const ruleName = this.props.rules.find(r => r.ruleId === ruleId).name;
        rulesString = rulesString + `${ruleId.toUpperCase()}: ${ruleName} - ${((ruleCountMap[ruleId] / langRules.filter(r => r.ruleId === ruleId).length) * 100).toFixed()}%, `
      }
      rulesString = rulesString.trim();

      dataSource.push({
        key: Math.random().toString(),
        languages: languagesMap[lang],
        accuracy,
        rules: rulesString,
      });
    });

    return { columns, dataSource };
  }

  generateAccuracyPerRuleTable = () => {
    const columns = [
      {
        title: 'Rule',
        children: [
          {
            title: 'ID',
            dataIndex: 'ruleId',
            key: 'ruleId',
          },
          {
            title: 'Summary',
            dataIndex: 'ruleName',
            key: 'ruleName',
            filters: this.getRuleFilters(),
            onFilter: (value, record) => record.ruleName.startsWith(value),
            filterSearch: true,
          },
        ]
      },   
      {
        title: 'Accuracy',
        dataIndex: 'accuracy',
        key: 'accuracy',
        sorter: (a, b) => {
          const num1 = parseInt(a.accuracy.slice(0,-1))
          const num2 = parseInt(b.accuracy.slice(0,-1))
          return num1 - num2;
        },
      },
      {
        title: 'Consent Language Breakdown',
        dataIndex: 'language',
        key: 'language',
      },
    ];
    
    const ruleIds = Object.keys(this.props.systemAccuracy).filter(id => !(id === 'status' || id === 'contentType'));
    const dataSource = [];
    
    ruleIds.forEach(ruleId => {
      const rules = this.props.systemAccuracy[ruleId];
      const accuracy = ` ${((rules.map(r => r.overallAccuracy).reduce((a, b) => a + b, 0) / rules.length) * 100).toFixed()}% `;
      let languagesString = '';
      const languageCountMap = {};
      if (rules[0] && rules[0].language) {
        rules.forEach(rule => {
          if (languageCountMap[rule.language]) {
            languageCountMap[rule.language] += rule.overallAccuracy;
          } else {
            languageCountMap[rule.language] = rule.overallAccuracy;
          }
        });
        for (const lang in languageCountMap) {
          languagesString = languagesString + languagesMap[lang] + ` ${((languageCountMap[lang] / (rules.filter(r => r.language === lang).length)) * 100).toFixed()}%, `
        }
        languagesString = languagesString.trim();
      }

      dataSource.push({
        key: Math.random().toString(),
        ruleId,
        ruleName: this.props.rules.find(r => r.ruleId === ruleId).name,
        accuracy,
        language: languagesString,
      });
    });

    return { columns, dataSource };
  }


  generateTableDataRuleOptimization = () => {
    const columns = [
      {
        title: 'Properties',
        dataIndex: 'properties',
        key: 'properties',
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        filters: regionFilters,
        onFilter: (value, record) => record.region.startsWith(value),
        filterSearch: true,
      }, 
      {
        title: 'Rule',
        children: [     
          {
            title: 'ID',
            dataIndex: 'ruleId',
            key: 'ruleId',
          },
          {
            title: 'Name',
            dataIndex: 'ruleName',
            key: 'ruleName',
            filters: this.getRuleFilters(),
            onFilter: (value, record) => record.ruleName.startsWith(value),
            filterSearch: true,
          },
        ]
      },
      {
        title: 'Last Scanned On',
        dataIndex: 'lastScanned',
        key: 'lastScanned',
        sorter: (a, b) => {
          return (
            new Date(b.lastScanned).valueOf() <
            new Date(a.lastScanned).valueOf()
          );
        },
      },
      {
        title: 'Last Manually Scanned On',
        dataIndex: 'lastManuallyScanned',
        key: 'lastManuallyScanned',
        sorter: (a, b) => {
          return (
            new Date(b.lastScanned).valueOf() <
            new Date(a.lastScanned).valueOf()
          );
        },
      },         
      {
        title: 'Consent Language',
        dataIndex: 'language',
        key: 'language',
        filters: languageFilters,
        onFilter: (value, record) => record.language && record.language.startsWith(value),
        filterSearch: true,
      },
      {
        title: 'CMP Type',
        dataIndex: 'cmpType',
        key: 'cmpType',
        filters: [
          { 
            text: 'IAB CMP',
            value: 'IAB CMP',
          },
          {
            text: 'No IAB CMP Detected',
            value: 'No IAB CMP Detected',
          },
        ],
        onFilter: (value, record) => record.cmpType.startsWith(value),
        filterSearch: true,
      },
      {
        title: 'Bot Detection',
        dataIndex: 'botDetection',
        key: 'botDetection',
        filters: [
          { 
            text: 'True',
            value: 'True',
          },
          {
            text: 'False',
            value: 'False',
          },
        ],
        onFilter: (value, record) => record.botDetection.startsWith(value),
        filterSearch: true,
      },
      {
        title: 'Reason for Inaccuracy',
        dataIndex: 'reason',
        key: 'reason',
        filters: [
          { 
            text: 'FP',
            value: 'FP',
          },
          {
            text: 'FN',
            value: 'FN',
          },
          {
            text: 'FUD',
            value: 'FUD',
          },
        ],
        onFilter: (value, record) => record.reason.startsWith(value),
        filterSearch: true,
      },
    ];

    const dataSource = [];
    const properties = this.props.accuracySets.map(set => set.properties).flat();

    let filteredProperties = properties;
    if (this.state.search) {
      filteredProperties = properties.filter(p => p.url.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    filteredProperties.forEach(property => {
      if (!property.manualRegionBasedScores) return;
      const regions = Object.keys(property.manualRegionBasedScores).filter(id => id !== '_id');
      regions.forEach(region => {
        const ruleIds = Object.keys(property.manualRegionBasedScores[region]).filter(id => id !== '_id' && id !== 'lastScored');
        ruleIds.forEach(ruleId => {
          const scannedScore = property.regionBasedScores[region][ruleId];
          const manualScore = property.manualRegionBasedScores[region][ruleId];
          const lastScored = property.manualRegionBasedScores[region].lastScored;

          let reason;
          if (scannedScore === undefined || manualScore === undefined || scannedScore === manualScore) {
            return;
          } else {
            if (scannedScore === 1) {
              reason = 'FP';
            } else if (scannedScore === 0) {
              reason = 'FN';
            } else if (scannedScore === -1) {
              reason = 'FUD';
            } else {
              if (!scannedScore) reason = 'not scanned yet';
              if (!manualScore) reason = 'not manually scored yet';
            }
          }
          dataSource.push({
            key: Math.random().toString(),
            properties: property.url,
            region: regionsMap[region],
            ruleId,
            ruleName: this.props.rules.find(r => r.ruleId === ruleId).name,
            lastScanned: moment(property.lastScanned).format('MMM DD, YYYY'),
            lastManuallyScanned: lastScored ? moment(lastScored).format('MMM DD, YYYY') : 'N/A',
            language: languagesMap[property.consentLanguage],
            cmpType: property.cmpId ? 'IAB CMP' : 'No IAB CMP Detected',
            botDetection: property.botDetection ? 'True' : 'False',
            reason,
          });
        });
      });
    });

    return { columns, dataSource };
  }

  generateDataForCsvReport = () => {
    const columns = [
      'Properties',
      'Region',
      'Rule ID',
      'Rule Name',
      'Last Scanned On',
      'Last Manually Scanned On',
      'Consent Language',
      'CMP Type',
      'Bot Detection',
      'Reason for Inaccuracy',
    ];

    const tableData = this.generateTableDataRuleOptimization();

    const dataSource = tableData.dataSource;

    const data = dataSource.map(d => {
      return [ 
        d.properties, 
        d.region, 
        d.ruleId,
        d.ruleName,
        d.lastScanned, 
        d.lastManuallyScanned, 
        d.language,
        d.cmpType,
        d.botDetection,
        d.reason,
      ];
    });

    return [columns, ...data];
  };

  render() {
    if (!this.props.rules.size || !this.props.accuracySets.length) return null;
    const tableDataAccuracyPerRule = this.generateAccuracyPerRuleTable();
    const tableDataAccuracyPerLanguage = this.generateAccuracyPerLanguageTable();
    const tableDataRuleOptimization = this.generateTableDataRuleOptimization();

    const downloadReport = (
      <CSVLink
        data={ this.generateDataForCsvReport(tableDataRuleOptimization) }
        filename='rule_optimization.csv'
        className='export-csv'
        target=""
      >
        <span className='download'>
          <FontAwesomeIcon icon={faDownload} />
        </span>
      </CSVLink>
    );

    return (
      <div className='inacuracy-container'>
        <div className='flex-row'>
          <div>
            <p className='subtitle-privacy-lens'>Accuracy per Rule</p>
            <div className='summary-card'>
              <Table
                dataSource={ tableDataAccuracyPerRule.dataSource }
                columns={ tableDataAccuracyPerRule.columns }
                bordered
              />
            </div>
          </div>

          <div className='language-table-container'>
            <p className='subtitle-privacy-lens'>Accuracy per Language</p>
            <div className='summary-card'>
              <Table
                dataSource={ tableDataAccuracyPerLanguage.dataSource }
                columns={ tableDataAccuracyPerLanguage.columns }
                bordered
              />
            </div>
          </div>
        </div>

        <p className='subtitle-privacy-lens'>Rule Optimization</p>
        <div className='summary-card'>
          <div className='search-container p-l-admin flex-row'>
            <Search
              placeholder="Search Properties"
              onChange={ (e) => { this.setState({ search: e.target.value }) } }
              className="vendor-filter"
              style={{ width: 190 }}
            />
            { downloadReport }
          </div>
          <Table
            dataSource={ tableDataRuleOptimization.dataSource }
            columns={ tableDataRuleOptimization.columns }
            bordered
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountOwners: store.accountState.getIn(['accountOwners', 'value']),
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    rules: store.standardState.getIn(['rules', 'value']),
    systemAccuracy: store.scanAccuracyState.getIn(['systemAccuracy', 'value']),
    systemAccuracyTimeline: store.scanAccuracyState.getIn(['systemAccuracyTimeline', 'value']),
    accuracySets: store.domainSetState.getIn(['accuracySets', 'value']),
    pendingRequestsMap: new Map({
      accuracySets: store.domainSetState.getIn(['accuracySets', 'pending']),
      domainSets: store.domainSetState.getIn(['domainSets', 'pending']),
      accountOwnersPending: store.accountState.getIn(['accountOwners', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
  getAccountOwners,
  getDomainSets,
  getRules,
  getSystemAccuracy,
  getSystemAccuracyTimeline,
  getAccuracySets,
},
)(PropertySetEvaluation);
