import React from 'react';

const Headline = (incomingProps) => {

  const props = {...incomingProps};
  props.className = 'spsg-headline ' + (props.className || '');

  // Oswald, Open Sans, Paragraph
  if (props.type) {
    props.className += ' headline-'+props.type;
    delete props.type;
  }

  // 1, 2, 3, 4
  if (props.size) {
    props.className += ' headline-'+props.size;
    delete props.size;
  }

  return <div {...props} />;
};

export default Headline;
