/* eslint-disable */
export default class VendorHighlightRule {
  isSupport(filters, node) {
    return filters.highlight && node.data?.attributes?.highlighted;
  }
  getTextColor() {
    return '#2D2D2D';
  }
  getNodeBackgroundColor() {
    return 'rgba(255, 255, 154, .65)';
  }
  getOpacity() {
    return 0.75;
  }
}
