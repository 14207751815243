import PropTypes from 'prop-types';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Checkbox, Tooltip, Select } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Purpose, Vendor, Translations } from '../../../records/vendor_list_records';
import { CategoryView } from '../../../records/privacy_manager_records';
import { COUNTRY_LANGUAGES } from '../../../constants.js';

export default class ConfigurePurposeTab extends React.Component {
  static propTypes = {
    overrideNameAndDescriptionOnly: PropTypes.bool,
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)),
    purpose: PropTypes.instanceOf(Purpose).isRequired,
    categoryView: PropTypes.instanceOf(CategoryView),
    updatePurpose: PropTypes.func.isRequired,
    allListVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)),
    globalVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)),
    readOnly: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    overrideNameAndDescriptionOnly: false,
    categoryView: null,
    iabPurposes: null,
    allListVendors: null,
    globalVendors: null,
    readOnly: false,
  }

  state = {
    translateLanguage: 'default',
  }

  handlePurposeChange = (e, prop) => {
    e.stopPropagation();
    let purpose = this.props.purpose;
    if (this.props.categoryView) {
      purpose = this.props.categoryView;
    }
    this.props.updatePurpose(purpose.set(prop, e.target.value));
  }

  toggleIabMap = (e, iabPurposeIdx) => {
    let iabPurposeRefs = this.props.purpose.get('iabPurposeRefs');

    const checked = e.target.checked;
    if (checked) {
      iabPurposeRefs = iabPurposeRefs.push(iabPurposeIdx);
    } else {
      iabPurposeRefs = iabPurposeRefs.filterNot(idx => idx === iabPurposeIdx);
    }

    const purposeWithUpdatedRefs = this.props.purpose.set('iabPurposeRefs', iabPurposeRefs);

    this.props.updatePurpose(purposeWithUpdatedRefs.set('vendorIds', this.getVendorIdsForPurpose(purposeWithUpdatedRefs)));
  }

  //TODO: move to helper
  getVendorIdsForPurpose = (purpose) => {

    const nonIABVendorIds = purpose.vendorIds.map(id => this.findVendorById(id)).filter(v => v.vendorType !== 'IAB').map(v => v.id);

    return this.props.allListVendors.filter(v => v.purposes.map(p => typeof p === 'string' ? p : p.id).some(id => purpose.iabPurposeRefs.includes(id))).map(v => v.id).concat(nonIABVendorIds);
  }

  findVendorById = (id) => {
    return this.props.globalVendors.find(v => v.id === id);
  }

  renderTitle = (title, name, isDisabled = false) => {
    let onChange = (e) => this.handlePurposeChange(e, 'name');
    if (this.state.translateLanguage !== 'default') {
      const translations = this.props.purpose.translations || new Translations({});
      name = translations.getIn(['names', this.state.translateLanguage]) || '';
      onChange = (e) => {
        const updatedTranslations = translations.setIn(['names', this.state.translateLanguage], e.target.value);
        this.props.updatePurpose(this.props.purpose.set('translations', updatedTranslations));
      };
    }
    return (
      <React.Fragment>
        <label className='title' >{ title }</label>
        <Input
          value={ name }
          disabled={ isDisabled || this.props.readOnly }
          onChange={ onChange }
        />
      </React.Fragment>
    );
  }

  renderDescription = (title, description, isDisabled = false) => {
    if (this.state.translateLanguage !== 'default') {
      const translations = this.props.purpose.translations || new Translations({});
      description = translations.getIn(['descriptions', this.state.translateLanguage]) || '';
    }

    const onChange = (e) => {
      if (this.state.translateLanguage !== 'default') {
        const translations = this.props.purpose.translations || new Translations({});
        const updatedTranslations = translations
          .setIn(['descriptions', this.state.translateLanguage], e.target.value)
        this.props.updatePurpose(this.props.purpose.set('translations', updatedTranslations));
      } else {
        this.handlePurposeChange(e, 'description');
      }
    }
    
    return (
      <React.Fragment>
        <label className='title' >{ title }</label>
        <Input.TextArea
          autosize={{ minRows: 6, maxRows: 100 }}
          disabled={ isDisabled || this.props.readOnly }
          value={ description }
          onChange={ onChange }
        />
      </React.Fragment>
    );
  }

  handleTranslationChange = (language) => {
    this.setState({ translateLanguage: language });
  }

  render() {
    const p = this.props.purpose;

    let nonChangableName;
    let changableName;
    let nonChangableDescription;
    let changableDescription;

    if (this.props.overrideNameAndDescriptionOnly) {
      const isDisabled = true;
      nonChangableName = this.renderTitle('Vendor List Purpose Name', this.props.purpose.name, isDisabled);
      changableName = this.renderTitle('Override Purpose Name', this.props.categoryView.name);
      nonChangableDescription = this.renderDescription('Vendor List Purpose Description', this.props.purpose.description, isDisabled);
      changableDescription = this.renderDescription('Override Description Name', this.props.categoryView.description);
    } else {
      changableName = this.renderTitle('Purpose title', this.props.purpose.name);
      changableDescription = this.renderDescription('Description', this.props.purpose.description);
    }

    let iabMapping;
    // if (!this.props.overrideNameAndDescriptionOnly) {
    //   iabMapping = (
    //     <React.Fragment>

    //       <div className='iab-map-title'><label className='title' >IAB purpose mapping</label><Tooltip title='Map multiple purposes together in a single group' ><Icon type="info-circle" /></Tooltip></div>

    //       <div className='checkbox-container'>
    //         { this.props.iabPurposes.sort((p1, p2) => {
    //           // sort for "Content selection, delivery, reporting" purpose to be the last one
    //           if (p2.iabId === 4) {
    //             return -1;
    //           } else {
    //             return p1.iabId - p2.iabId;
    //           }
    //         }).map(iabP => {
    //           return (
    //             <Checkbox
    //               onChange={ (e) => this.toggleIabMap(e, iabP.id) }
    //               checked={ p.iabPurposeRefs.includes(iabP.id) }
    //               className='iab-purpose-checkbox'
    //               disabled={ this.props.readOnly }
    //             >
    //               { iabP.name }
    //             </Checkbox>
    //           );
    //         })}
    //       </div>

    //     </React.Fragment>
    //   )
    // }

    const translations = (
      <div className='translations'>
        <p>Language translation: </p>
        <Select
          value={this.state.translateLanguage}
          onChange={this.handleTranslationChange}
          placeholder='Select a language for translation...'
        >
          <Select.Option key={'default'}>Default</Select.Option>
          {COUNTRY_LANGUAGES.map((o) => <Select.Option key={o.code.toLowerCase()}>{o.language}</Select.Option>)}
        </Select>
      </div>
    );

    return (
      <div className='edit-purpose-tab-container'>

        <p className='purpose-id'>ID: { this.props.purpose.id }</p>
        <div className='purpose-opt-in'>
          <Checkbox
            onChange={ (e) => this.props.updatePurpose(p.set('defaultOptedIn', e.target.checked)) }
            checked={ p.defaultOptedIn }
            disabled={ this.props.readOnly }
          >
            Opt-in to purpose by default
            <Tooltip title="Overrides the purpose opt-in setting in the privacy manager." placement="right">
              <InfoCircleOutlined />
            </Tooltip>
          </Checkbox>
        </div>
        { nonChangableName }
        { changableName }
        { nonChangableDescription }
        { changableDescription }

        { iabMapping }
        { translations }

      </div>
    );
  }
}
