import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { ConfigProvider, Input, Select, Table, Tabs } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { Map, List } from 'immutable';
import CustomPopover from '../../common/CustomPopover';
import {
  vendorTooltipContent,
  purposesTooltipContent,
  specialFeaturesTooltipContent,
} from './instructionalText';
import { createCSVExportData, absDataFormater } from '../performanceNew/helper';
import ExportPopoverContent from '../../ccpa/ccpa_reporting/ExportPopoverContent';
import FilterColumns from './FilterColumns';

const colMap = {
   Vendors: 'vendorCols',
   Purposes: 'purposesCols',
   'Special Features': 'splFeaturesCols'
};
const { TabPane } = Tabs;
const { Option } = Select;

export default class ConsentPVVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchPurposesText: '',
      searchSpecialFeatures: '',
      omitCols: List(['name']),
      vendorCols: this.cols(),
      purposesCols: this.cols().updateIn([0, 'title'], (value) => 'Purpose'),
      splFeaturesCols: this.cols().updateIn([0, 'title'], (value) => 'Special Features'),
    };
  }

  setCols = (cols, filteredCols) => {
    const columns =  cols == 'purposesCols' ? this.cols().updateIn([0, 'title'], (value) => 'Purpose') : cols == 'splFeaturesCols' ?  this.cols().updateIn([0, 'title'], (value) => 'Special Features') : this.cols();
    this.setState({[cols]: columns.filter((cols) => filteredCols.includes(cols.get('dataIndex')))});
  }

  tableTitle = (
    tableTitle,
    tooltipContent,
    exportFilename,
    exportHeader,
    exportTableData,
    exportPDFTitle,
    searchPlaceholder,
    stateName
  ) => (
    <div className="table-title-container">
      <div className="table-title">
        {tableTitle}
        <CustomPopover theme="filled" tooltipContent={tooltipContent} />
        <CustomPopover
          tooltipContent={
            <ExportPopoverContent
              filename={exportFilename}
              exportData={createCSVExportData(exportHeader, exportTableData)}
              pdfTitle={exportPDFTitle}
            />
          }
          style="export-popover"
          icon="download"
          iconColor="#108ee9"
          title={<span className="popover-title">Export Type Selection</span>}
        />
      </div>
      <div className="vendors-table-search">
        <FilterColumns columns={this.cols()} omitCols={this.state.omitCols} setCols={(filteredCols) => this.setCols(colMap[searchPlaceholder] ,filteredCols)}/>
        <div className="search-container">
          <Input
            placeholder={`Search ${searchPlaceholder}`}
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            onChange={(event) => {
              this.setState({ [stateName]: event.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
  cols = () => {
  return List([
      Map({
        title: 'Vendor',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        className: 'left-align',
      }),
      Map({
        title: 'Total Views',
        dataIndex: 'totalViews',
        key: 'totalViews',
        sorter: (a, b) => a.totalViews - b.totalViews,
        className: 'right-align',
        render: (text) => absDataFormater(text),
      }),
      Map({
        title: 'Full Opt-in',
        dataIndex: 'fullOptIn',
        key: 'fullOptIn',
        sorter: (a, b) => a.fullOptIn - b.fullOptIn,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                    <span className="tbl-metric-per">{record.fullOptInPer}</span> <br/>
                                    {absDataFormater(text)}
                                  </div>,
      }),
      Map({
        title: 'Legitimate Interest Only',
        dataIndex: 'legIntOnly',
        key: 'legIntOnly',
        sorter: (a, b) => a.legIntOnly - b.legIntOnly,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.legIntOnlyPer}</span> <br/>
                                   {absDataFormater(text)}
                                  </div>,      
      }),
      Map({
        title: 'Reject All',
        dataIndex: 'rejectAll',
        key: 'rejectAll',
        sorter: (a, b) => a.rejectAll - b.rejectAll,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                    <span className="tbl-metric-per">{record.rejectAllPer}</span> <br/>
                                    {absDataFormater(text)}
                                  </div>,
      }),
    ]);
  }
  render() {
    let tableData = this.props.consentVendorData;
    tableData = tableData.filter((v) =>
      v.name.toLowerCase().includes(this.state.searchText)
    );
    let purposeTableData = this.props.purposeConsentData;
    purposeTableData = purposeTableData.filter((p) =>
      p.name.toLowerCase().includes(this.state.searchPurposesText)
    );
    let specialFeaturesTableData = this.props.specialFeatureConsentData;
    specialFeaturesTableData = specialFeaturesTableData.filter((s) =>
      s.name.toLowerCase().includes(this.state.searchSpecialFeatures)
    );

    const vendorHeaders = this.cols()
                            .map((item) => Map({ label: item.get('title'), key: item.get('dataIndex') }) )
                            .insert(3, Map({ label: "Full Opt-in %", key: "fullOptInPer" }))
                            .insert(5, Map({ label: "Legitimate Interest Only %", key: "legIntOnlyPer" }))
                            .insert(7, Map({ label: "Reject All %", key: "rejectAllPer" }));
    const purposesHeader = vendorHeaders.updateIn([0, 'label'], (value) => 'Purpose');
    const specialFeaturesHeader = vendorHeaders.updateIn([0, 'label'], (value) => 'Special Features');

    return (
      <React.Fragment>
        <div className="vendors-filter-container">
          <div className="vendor-property-select-container">
            <div>Property</div>
            <ConfigProvider locale={enUS}>
              <Select
                placeholder="Select Property"
                className="vendor-property-filter"
                optionFilterProp="children"
                disabled={false}
                onChange={this.props.selectSingleSite}
                value={this.props.vendorProperty}
                showSearch={true}
                filterOption={(i, o) =>
                  o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                }
              >
                {this.props.sitesOptions}
              </Select>
            </ConfigProvider>
          </div>
          <div className="vendor-geo-select-container">
            <div>Region</div>
            <ConfigProvider locale={enUS}>
              <Select
                placeholder="Select Region"
                maxTagCount={
                  this.props.maxSize == this.props.vendorRegions.size ? 0 : 1
                }
                maxTagTextLength={2}
                maxTagPlaceholder={
                  this.props.maxSize == this.props.vendorRegions.size
                    ? 'All'
                    : `+${
                        this.props.vendorRegions.size
                          ? this.props.vendorRegions.size - 1
                          : 0
                      }`
                }
                className="vendor-property-filter"
                optionFilterProp="children"
                mode="multiple"
                disabled={false}
                value={this.props.vendorRegions.toJS()}
                onChange={this.props.selectVendorRegion}
                filterOption={(i, o) =>
                  o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                }
              >
                <Option
                  key="All"
                  value="All"
                  className={
                    this.props.maxSize == this.props.vendorRegions.size
                      ? 'select-all-style'
                      : ''
                  }
                >
                  All
                </Option>
                {this.props.regionsOptions}
              </Select>
            </ConfigProvider>
          </div>
        </div>

        <div className="consent-tabs-container gdpr-vendor-tab-container">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Vendors" key="1">
              <div className="consentblock">
                <Table
                  columns={this.state.vendorCols.toJS()}
                  dataSource={tableData.toJS()}
                  title={() =>
                    this.tableTitle(
                      'Vendor Performance',
                      vendorTooltipContent,
                      'consented_pageviews_vendors',
                      vendorHeaders,
                      tableData,
                      'Consented Views by Vendors',
                      'Vendors',
                      'searchText'
                    )
                  }
                  pagination={{
                    itemRender: this.itemRender,
                    total: tableData.toJS(),
                    showTotal: (total) => `Total: ${total}`,
                    size: 'small',
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    locale: { items_per_page: ' / page' },
                  }}
                  className="consnet-tableCSS"
                  rowKey={(r) => r.key}
                  loading={this.props.isVendorDataLoading}
                />
              </div>
            </TabPane>
            <TabPane tab="Purposes" key="2">
              <div className="consentblock">
                <Table
                  columns={this.state.purposesCols.toJS()}
                  dataSource={purposeTableData.toJS()}
                  title={() =>
                    this.tableTitle(
                      'Performance by Purpose',
                      purposesTooltipContent,
                      'performance_by_purposes',
                      purposesHeader,
                      purposeTableData,
                      'Performance by Purpose',
                      'Purposes',
                      'searchPurposesText'
                    )
                  }
                  pagination={{
                    itemRender: this.itemRender,
                    total: purposeTableData.toJS(),
                    showTotal: (total) => `Total: ${total}`,
                    size: 'small',
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    locale: { items_per_page: ' / page' },
                  }}
                  className="consnet-tableCSS"
                  rowKey={(r) => r.key}
                  loading={this.props.isPurposesDataLoading}
                />
              </div>
            </TabPane>
            <TabPane tab="Special Features" key="3">
              <div className="consentblock">
                <Table
                  columns={this.state.splFeaturesCols.toJS()}
                  dataSource={specialFeaturesTableData.toJS()}
                  title={() =>
                    this.tableTitle(
                      'Performance By Special Features',
                      specialFeaturesTooltipContent,
                      'performance_special_features',
                      specialFeaturesHeader,
                      specialFeaturesTableData,
                      'Performance By Special Features',
                      'Special Features',
                      'searchSpecialFeatures'
                    )
                  }
                  pagination={{
                    itemRender: this.itemRender,
                    total: specialFeaturesTableData.toJS(),
                    showTotal: (total) => `Total: ${total}`,
                    size: 'small',
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    locale: { items_per_page: ' / page' },
                  }}
                  className="consnet-tableCSS"
                  rowKey={(r) => r.key}
                  loading={this.props.isSplFeaturesDataLoading}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}
