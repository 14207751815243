import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Map } from 'immutable';
import { Input } from 'antd';
import { message } from '../../../styleguide'
import {
  createDomainSet,
  updateDomainSet,
  deleteDomainSet,
} from '../../../actions/domain_set_actions';
import { DomainSet } from '../../../records/domain_set_records';
import Pagination from '../standard/Pagination';
import PropertyCard from './PropertyCard.jsx';
import { getDomainsForDomainSet, getAllAccountDomainsPerRegion } from '../../../api/consent_quality/domain_set';
import { getStandards } from '../../../actions/standard_actions';
import DeactivateModal from './DeactivateModal';
import DeleteModal from './DeleteModal';
import DeleteInactiveModal from './DeleteInactiveModal';
import CannotActivateModal from './CannotActivateModal';

import { getAccountAjax } from '../../../api/consent_quality/account';

class DomainSetsGrouped extends React.Component {
  static propTypes = {
    createDomainSet: PropTypes.func.isRequired,
    updateDomainSet: PropTypes.func.isRequired,
    deleteDomainSet: PropTypes.func.isRequired,
    getStandards: PropTypes.func.isRequired,
    setLoadingModal: PropTypes.func.isRequired,
  }

  state = {
    newDomainSetModal: false,
    currentPage: 1,
    showCards: 6,
    showFrom: 0,
    showTo: 5,
    propertySets: [],
    deactivateModalOpen: false,
    deleteModalOpen: false,
    deleteInactiveModalOpen: false,
    cannotActivateModalNumProperties: false,
    selectedPropertySet: null,
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.domainSets.length !== prevProps.domainSets.length) {
      this.setState({currentPage: 1});
    }
  }

  getPlAllowanceLeft = async (propertySet) => {
    return getAccountAjax(this.props.currentUser.accountId).then(account => {
      if (account.scanAllowance === -1) return 'unlimited';
      return getAllAccountDomainsPerRegion(this.props.currentUser.accountId, propertySet.region).then((resp) => {
        const domainsInOterSets = new Set();
        resp.domains.forEach(url => {
          domainsInOterSets.add(url);
        });
        let numDomainsInOtherSets = 0;
        return getDomainsForDomainSet(propertySet._id).then(domains => {
          domains.map(d => d.url).forEach(url => {
            if (domainsInOterSets.has(url)) {
              numDomainsInOtherSets += 1;
            }
          });
          return (account.scanAllowance - resp.totalCount) + numDomainsInOtherSets;
        });
      });
    });
  }

  updateSearch = (e) => {
    this.setState({ search: e.target.value, currentPage: 1 });
  }

  setPage = (num) => {
    this.setState({ currentPage: num });
  }

  deleteDomainSet = () => {
    const setToUpdate = { ...this.state.selectedPropertySet };
    if (this.state.selectedPropertySet.domainSetStatus === 'Disabled') {
      this.props.deleteDomainSet(this.state.selectedPropertySet._id).then(resp => {
        message.success(<><strong>{this.state.selectedPropertySet.name}</strong> was successfully deleted</>);
        this.setState({ selectedPropertySet: null, deleteModalOpen: false });
      });
      return;
    }
    
    if (this.state.selectedPropertySet.domainSetStatus === 'Active') {
      setToUpdate.deactivateDate = this.getDeactivateDate(new Date(setToUpdate.activateDate));
      setToUpdate.domainSetStatus = 'DeletedPending';
    } else {
      setToUpdate.domainSetStatus = 'Disabled';
    }
    this.setState({ selectedPropertySet: null, deleteModalOpen: false });
    this.props.updateDomainSet(this.props.currentUser.accountId, setToUpdate).then(resp => {
      message.success(<><strong>{resp.name}</strong> was successfully deleted</>);
      this.props.setPlUsage();
    });
  }

  deactivateDomainSetModal = (propertySet) => {
    this.setState({ deactivateModalOpen: true });
    this.setState({ selectedPropertySet: propertySet });
  }

  activateDomainSetModal = async (propertySet) => {
    if (propertySet.domainSetStatus === 'DisabledPending') {
      this.editDomainSet(propertySet, true);
      return;
    }
    const plAllowanceLeft = await this.getPlAllowanceLeft(propertySet);
    if (plAllowanceLeft === 'unlimited') {
      this.editDomainSet(propertySet, true);
      return;
    }
    const plAllowanceLeftWithSet = plAllowanceLeft - propertySet.numProperties;

    if (plAllowanceLeftWithSet >= 0) {
      this.editDomainSet(propertySet, true);
    } else {
      this.setState({ selectedPropertySet: propertySet, cannotActivateModalNumProperties: plAllowanceLeftWithSet * -1 });
    }
  }

  deleteDomainSetModal = (propertySet) => {
    if (propertySet.domainSetStatus !== 'Disabled') {
      this.setState({ deleteModalOpen: true });
    } else {
      this.setState({ deleteInactiveModalOpen: true });
    }
    this.setState({ selectedPropertySet: propertySet });
  }

  closeDeactivateModal = () => {
    this.setState({ deactivateModalOpen: false });
    this.setState({ selectedPropertySet: null });
  }

  closeDeleteModal = () => {
    this.setState({ deleteModalOpen: false });
    this.setState({ selectedPropertySet: null });
  }

  closeDeleteInactiveModal = () => {
    this.setState({ deleteInactiveModalOpen: false });
    this.setState({ selectedPropertySet: null });
  }

  closeCannotActivateModal = () => {
    this.setState({ cannotActivateModalNumProperties: false });
    this.setState({ selectedPropertySet: null });
  }

  deleteToDeactivateModalChange = () => {
    this.setState({ 
      deactivateModalOpen: true,
      deleteModalOpen: false,
    });
  }

  deactivateDomainSet = () => {
    const setToUpdate = { ...this.state.selectedPropertySet };
    setToUpdate.domainSetStatus = 'DisabledPending';
    setToUpdate.deactivateDate = this.getDeactivateDate(new Date(setToUpdate.activateDate));
    this.setState({ selectedPropertySet: null });
    this.props.updateDomainSet(this.props.currentUser.accountId, setToUpdate).then(resp => {
      message.success(<><strong>{resp.name}</strong> was successfully deactivated</>);
      this.props.setPlUsage();
    });
  }

  getDeactivateDate = (activateDate) => {
    const PERIOD = 30;
    const todayDate = moment();
    const acviteDays = todayDate.diff(moment(activateDate), 'days');
    const daysBeforePeriodEnds = PERIOD - (acviteDays % PERIOD);
    const datePeriodEnds = todayDate.add(daysBeforePeriodEnds, 'days');
    return datePeriodEnds._d;
  }

  activateDomainSet = (set) => {
    const setToUpdate = { ...set };
    setToUpdate.domainSetStatus = 'Active';
    this.props.updateDomainSet(this.props.currentUser.accountId, setToUpdate);
  }

  editDomainSet = async (domainSet, activate) => {
    //XXX have all immmutable
    this.props.setLoadingModal(true);
    const domains = await getDomainsForDomainSet(domainSet._id);
    this.props.setLoadingModal(false);
    const domainSetData = {...domainSet};
    domainSetData.standards = domainSet.standard ? [domainSet.standard] : domainSet.standards;
    domainSetData.domains = [...domains];
    const converted = new DomainSet(domainSetData);
    this.props.setOpenedDomainSet(converted, activate);
  };

  handlePageClick = (data) => {
    this.setState({ currentPage: data.selected + 1 })
  }

  filterSetsForStandard = (standardId, propertySets) => {
    return propertySets.filter((set) => set.standard._id === standardId);
  }

  render() {
    const { search, currentPage, showCards } = this.state;

    let sortedPropertySets = this.props.domainSets.map(obj => {
      return {...obj, dateUpdated: new Date(obj.dateUpdated)}
    }).sort((objA, objB) => Number(objB.dateUpdated) - Number(objA.dateUpdated)).
    map(obj => {
      return {...obj, dateUpdated: obj.dateUpdated.toISOString()}
    })

    // Filter by Page
    const totalPages = Math.ceil(sortedPropertySets.length / showCards);
    const minIndex = (currentPage - 1) * showCards;
    const maxIndex = minIndex + showCards;
    sortedPropertySets = sortedPropertySets.filter((p, i) => {
      return i >= minIndex && i < maxIndex;
    });

    return (
      <div>
        <div className='text-message-wrapper'>
          {this.props.active && <p>Active property sets are scanned and generate Scoring Results.</p>}
          {!this.props.active && <p>Inactive property sets will not be scanned for the current cycle and so no scoring results will be generated.</p>}
        </div>
        <div className='search-container'>
        { this.state.deactivateModalOpen ? <DeactivateModal propertySet={this.state.selectedPropertySet} deactivate={this.deactivateDomainSet} closeModal={this.closeDeactivateModal} /> : null}
        { this.state.deleteModalOpen ? <DeleteModal propertySet={this.state.selectedPropertySet} deactivate={this.deleteToDeactivateModalChange} closeModal={this.closeDeleteModal} delete={this.deleteDomainSet}/> : null}
        { this.state.deleteInactiveModalOpen ? <DeleteInactiveModal propertySet={this.state.selectedPropertySet} closeModal={this.closeDeleteInactiveModal} delete={this.deleteDomainSet}/> : null}
        { this.state.cannotActivateModalNumProperties ? <CannotActivateModal numPropertiesNeeded={this.state.cannotActivateModalNumProperties} propertySet={this.state.selectedPropertySet} openEdit={this.editDomainSet} closeModal={this.closeCannotActivateModal}/> : null}
        </div>
        <div className="privacy-lens-grid-properties">
          {sortedPropertySets.map((ps, i) => {
            return <PropertyCard
              readOnly={this.props.readOnly}
              key={'standard ' + i}
              set={ps}
              editDomainSet={this.editDomainSet}
              deleteDomainSet={() => this.deleteDomainSetModal(ps)}
              deactivateDomainSet={this.deactivateDomainSetModal}
              activateDomainSet={(ds) => this.activateDomainSetModal(ds)}
              showCards={i >= this.state.showFrom && i <= this.state.showTo ? true : false}
              type="account"
              accountId={this.props.currentUser.accountId}
              contractEndDate={this.props.contractEndDate}
              active={this.props.active}
            />
          })}
        </div>
       { totalPages > 1 &&
        <Pagination
          pages={totalPages}
          handlePageClick={this.handlePageClick}
          current={currentPage}
        />
        }
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    rules: store.standardState.getIn(['rules', 'value']),
    standards: store.standardState.getIn(['standards', 'value']),
    pendingRequestsMap: new Map({
      standards: store.standardState.getIn(['standards', 'pending']),
      domainSetCreateOrUpdate: store.domainSetState.get('createOrUpdatePending'),
    }),
  };
};

export default connect(
  mapStateToProps, {
  getStandards,
  createDomainSet,
  updateDomainSet,
  deleteDomainSet,
},
)(DomainSetsGrouped);