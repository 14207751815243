import React, { Component } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const  FontHeadingOptions = ({ value, settingsTitle, settingKey, updateSetting }) => {

    return (
      <div className="four-options">
      <h4>{settingsTitle}</h4>
      <div className="option-inputs">
        <Select value={value} style={{ width: '100%' }} onChange={(value) => updateSetting(settingKey, value)}>
          <Option value="div"><i>div</i></Option>
          <Option value="h1">h1</Option>
          <Option value="h2">h2</Option>
          <Option value="h3">h3</Option>
          <Option value="p"><i>p</i></Option>
        </Select>
      </div>
    </div>
    );
  }

export default FontHeadingOptions;
