import React from 'react';
import { browserHistory } from 'react-router';

class ErrorBoundary extends React.Component {
  componentDidCatch() {
    return browserHistory.push(`/500?redirect_to=${location.pathname}`);
  }

  render() {
    return this.props.children && React.cloneElement(this.props.children, { location: this.props.location })
  }
};

export default ErrorBoundary;
