import { Record } from 'immutable';

export const ContentData = Record({
  title: "We've noticed you are using an ad blocker.",
  body: '<p>We rely on advertising to offer our journalism for free.</p><p>Please whitelist us or disable your ad blocker, and reload the page.</p><p>Thank you for supporting us.</p>',
});

export const MsgContent = Record({
  contentType: 'html',
  contentData: new ContentData(),
});

export const Title = Record({
  fontSize: '16px',
  color: '#606060',
  backgroundColor: 'transparent',
});

export const Body = Record({
  fontSize: '14px',
  color: '#303030',
  backgroundColor: 'transparent',
});

export const Backdrop = Record({
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
});

export const General = Record({
  backgroundColor: '#F4F4F4',
  border: '1px solid #DDDDDD',
  closeButtonColor: '#666666',
  zIndex: 10000,
});

export const Buttons = Record({
  backgroundColor: '#3B7BB3',
  border: '0 none',
  color: '#FFF',
  fontSize: '14px',
  hoverBackgroundColor: '#27669D',
  hoverColor: '#FFF',
});

export const Style = Record({
  includeDefaultStyles: true,
  template: 'base',
  definedStyles: false,
  title: new Title(),
  body: new Body(),
  backdrop: new Backdrop(),
  general: new General(),
  buttons: new Buttons(),
  customStyles: '',
});

export const customHTMLCallback = Record({
  invokeJS: true,
});

export class CustomHTML extends Record({
  html: '',
  callback: new customHTMLCallback(),
}) {
  constructor(customHTML) {
    const updatedCustomHTML = Object.assign({}, customHTML);
    if (customHTML) updatedCustomHTML.callback = new customHTMLCallback(customHTML.callback);
    super(updatedCustomHTML);
  }
}

export const Options = Record({
  destinationSelector: '',
  removePageElementsSelector: '',
  destination: '',
  delayType: 'none',
  delayAfterSeconds: 0,
  delayAfterScroll: 0,
  detectExitIntent: false,
  custom: new CustomHTML(),
});

export const UserData = Record({
  customScreenshotUrl: null,
});

export const Data = Record({
  subType: 'modal',
  msgContent: new MsgContent(),
  theme: 'default',
  style: new Style(),
  options: new Options(),
  userData: new UserData(),
});

export const ServerSideHTMLRecord = Record({
  html: '',
});

export const ServerSideMessageHTML = Record({
  msg: new ServerSideHTMLRecord(),
  veil: new ServerSideHTMLRecord(),
  css: '',
  messageConfigData: null,
});

export class MessageConfigRecord extends Record({
  version: 1,
  type: 'notice',
  data: new Data(),
}) {
  getServerSideHTML(choiceConfig) {

    let option_list = choiceConfig.get('option_list');
    option_list = option_list.filter((list_option) => list_option.get('choice_option_type'));
    choiceConfig = choiceConfig.set('option_list', option_list);

    return new ServerSideMessageHTML({
      msg: new ServerSideHTMLRecord({ html: window.messagingLibServerSideRendering.msg.getHTML(this.toJS(), choiceConfig.toJS()) }),
      veil: new ServerSideHTMLRecord({ html: window.messagingLibServerSideRendering.veil.getHTML(this.toJS(), choiceConfig.toJS()) }),
      css: window.messagingLibServerSideRendering.getCSS(this.toJS(), choiceConfig.toJS()),
      messageConfigData: window.messagingLibServerSideRendering.getMessageConfigData(this.toJS(), choiceConfig.toJS()),
    });
  }
}
