
import React from 'react';
import NumberStep from './NumberStep';

export default class DelaySeconds extends React.Component {
  render() {
    return (
      <NumberStep
        {...this.props}
        label={ 'Time Delay in seconds' }
        propertyName={ 'delay_secs' }
      />
    );
  }
}
