import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntRangeKVGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js'

import StepSatisfyOption from './StepSatisfyOption'

export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(IntRangeKVGate).isRequired,
        onChange: PropTypes.func.isRequired
    };

    state = {
        v_start: this.props.data.v_start,
        v_end: this.props.data.v_end,
    };

    componentDidMount() {
        if (this.props.editing) ReactDOM.findDOMNode(this.refs.key).focus();
    }

    handleChange = (newData) => {
        this.props.onChange(this.props.data.merge(newData))
    };

    handleChangeSatisfy = (continueOnSatisfy) => {
        this.handleChange({ allow_on_satisfy: continueOnSatisfy })
    };

    handleChangeStartValue = (event) => {
        var value = parseInt(event.target.value);

        if (isNaN(value)) {
            this.setState({ v_start: '' })
        } else {
            this.setState({ v_start: value })
            this.handleChange({ v_start: value })
        }
    };

    handleChangeEndValue = (event) => {
        var value = parseInt(event.target.value);

        if (isNaN(value)) {
            this.setState({ v_end: '' })
        } else {
            this.setState({ v_end: value })
            this.handleChange({ v_end: value })
        }
    };

    render() {
        var continueOnSatisfy = this.props.data.allow_on_satisfy ? "Yes" : "No";

        if (!this.props.editing) {
            return (
                <div>
                  Key: { this.props.data.k }, Start Value: { this.props.data.v_start }, End Value: { this.props.data.v_end }, Continue On Match: { continueOnSatisfy }
                </div>
            );
        } else {
            return (
                <div className="step kv-gate continue-halt">
                  <label>Key:</label>
                  <input ref="key" type="text" value={ this.props.data.k } onChange={ (e) => this.handleChange({ k: e.target.value }) }></input>

                  <label>Start Value:</label>
                  <input type="text" value={ this.state.v_start } onChange={ this.handleChangeStartValue }></input>

                  <label>End Value:</label>
                  <input type="text" value={ this.state.v_end } onChange={ this.handleChangeEndValue }></input>

                  <label>Continue On Match:
                    <StepSatisfyOption
                      value={ this.props.data.allow_on_satisfy }
                      onChange={ this.handleChangeSatisfy }
                    />
                  </label>

                </div>
            );
        }
    }
}
