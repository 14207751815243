import { Map } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const initialState = Map({
    siteStyleData: new StoreRecord({ }),
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SITE_FONTS_PENDING:
            return setPending(state, ['siteStyleData']);

        case types.SITE_FONTS_LOADED:
            return setValue(state, ['siteStyleData'], action.importedFonts);

        case types.SITE_FONTS_ERROR:
            return setError(state, ['siteStyleData'], action.errorMessage);
    }
    return state;
};
