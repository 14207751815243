import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { Modal, Button, Alert } from 'antd';
import DownloadScoringResults from './DownloadScoringResults';

export default class DeactivateModal extends React.Component {
  static propTypes = {
    moveToDrafts: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  handleApply = () => {
    this.props.deactivate();
    this.props.closeModal();
  }

  goToPropertySets = () => {
    browserHistory.push(`/privacy_lens/domain_sets?standardId=${this.props.standard.id}`);
  }

  render() {
    return (
      <Modal
        onOk={this.handleApply}
        okText='Deactivate'
        onCancel={this.props.closeModal}
        className='purple new-design deactvate-ps'
        visible
        footer={[
          <Button onClick={this.props.closeModal} key="1">Cancel</Button>,
          this.props.propertySet.domainSetStatus === 'Active' ?
          <Button onClick={this.props.deactivate} key="2">Deactivate</Button> : null,
          <Button onClick={this.props.delete} key="3" type="primary">
            DELETE PERMANENTLY
          </Button>
        ]}
      >
        <p className="title">{`${this.props.propertySet.name} - Delete`}</p>

        <div className='modal-container'>
          <p>
            {`If you choose to delete ${this.props.propertySet.name}, scanning for this property set will stop immediately. Once deleted, all associated data with the property set will be deleted permanently and cannot be retrieved at all.`}
          </p>
          <p>You will also lose access to the scoring results generated from the property set. You can download the generated scoring results by clicking on the button below.</p>
          <DownloadScoringResults propertySet={this.props.propertySet} />
          <p className='question'>
            Are you sure you want to <span>Delete</span>? {this.props.propertySet.domainSetStatus === 'Active' && <>You can also choose to deactivate it.</>}
          </p>
          <Alert message='For current period, PL allowance is already consumed for this property set. Change in PL allowance will be reflected in next period.' type="warning" showIcon />
        </div>
      </Modal>
    );
  }
}
