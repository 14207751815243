import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';

import StatusBadge from './StatusBadge.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import { Message } from '../../../records/message_records';
import moment from 'moment';
import { getParameterByName } from '../../utils';

export default class extends React.Component {
    static propTypes = {
        message: PropTypes.instanceOf(Message).isRequired,
        siteId: PropTypes.string.isRequired,
        duplicateMessage: PropTypes.func,
        createSiteGroupMessage: PropTypes.func,
        getMessageWithDetail: PropTypes.func,
    };

    static defaultProps = {
        duplicateMessage: _.noop,
        createSiteGroupMessage: _.noop,
        getMessageWithDetail: _.noop,
    };

    state = {
        hovered: false,
    };

    appendSiteGroupId = (url) => {
      const siteGroupId = getParameterByName('site_group_id', window.location);
      if (siteGroupId) {
        url += `&site_group_id=${ siteGroupId }`;
      }
      return url;
    }

    handleEditMessage = () => {
      const url = '/dialogue/messages/edit?message_id=' + this.props.message.id + '&site_id=' + this.props.siteId;
      browserHistory.push(this.appendSiteGroupId(url));
    };

    handleViewMessage = () => {
      const url = '/dialogue/messages/view?message_id=' + this.props.message.id + '&site_id=' + this.props.siteId;
      browserHistory.push(this.appendSiteGroupId(url));
    };

    copyMessage = (message) => {
      const siteGroupId = getParameterByName('site_group_id', window.location);
      if (siteGroupId) {
        this.props.getMessageWithDetail(getParameterByName('site_id', window.location), message.id, true).then(message => {
          this.props.createSiteGroupMessage(this.props.accountId, siteGroupId, message.set('description', message.get('description') + ' (copy)'));
        })
      } else {
        this.props.duplicateMessage(this.props.message);
      }
    }

    renderButtonsCell = (message) => {

        if (message.status === "draft" && !this.props.readOnly) {
            return (
                <td className="buttons-group">
                  <CustomIcon
                    type={ CustomIcon.types.EDIT }
                    onClick={ this.handleEditMessage.bind(this, message.id) }
                    tooltip="edit"
                  />
                  <CustomIcon
                    type={ CustomIcon.types.COPY }
                    onClick={ this.copyMessage.bind(null, message)}
                    tooltip="copy"
                  />
                  <CustomIcon
                    type={ CustomIcon.types.DELETE }
                    onClick={ this.props.setMessageToDelete.bind(null, this.props.message.description, this.props.message.id) }
                    tooltip="delete"
                  />
                </td>
            );
        } else {
            return (
                <td>
                  <CustomIcon type={ CustomIcon.types.VIEW } onClick={ this.handleViewMessage.bind(this, message.id) } ></CustomIcon>
                </td>
            );
        }
    };

    render() {
        let previewIcon;
        if (this.state.hovered && this.props.message.msg_metadata.type !== "redirect") {
            const allowEdit = !this.props.message.live;
            previewIcon = (
              <CustomIcon
                type={ CustomIcon.types.PREVIEW }
                onClick={ () => {
                  const url = '/dialogue/message_preview?message_id=' + this.props.message.id + '&site_id=' + this.props.siteId + '&allow_edit=' + allowEdit;
                  browserHistory.push(this.appendSiteGroupId(url));
                }}
                tooltip="preview"
              />
            );
        }

        return (
            <tr key={ this.props.message.id }
              onMouseEnter={ () => this.setState({ hovered: true }) }
              onMouseLeave={ () => this.setState({ hovered: false }) }
            >
              <td>{ this.props.message.description }{ previewIcon }</td>
              <td><StatusBadge status={ this.props.message.status } /></td>
              <td>{ moment(this.props.message.created_at).format('YYYY-MM-DD') }</td>
              <td>{ this.props.message.id }</td>
              { this.renderButtonsCell(this.props.message) }
            </tr>
        )
    }
}
