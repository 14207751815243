import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        altpay: PropTypes.bool.isRequired,
    };

    static defaultProps = { value: "" };

    render() {
        return (
            <select value={ this.props.value } onChange={ (e) => this.props.onChange(e.target.value) }>
              <option value="">Choose...</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate">Continue/Halt : If Adblocked PV</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate">Continue/Halt : If Desktop UA</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate">Continue/Halt : If Match String Value (K/V)</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.IntRangeKVGate">Continue/Halt : If Match In Integer Range (K/V)</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch">Continue/Halt : If Page URL Regex Match</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.SearchReferrerGate">Continue/Halt : If Search Referrer Gate</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.SocialReferrerGate">Continue/Halt : If Social Referrer Gate</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.SessionSearchReferrerGate">Continue/Halt : If Session Search Referrer Gate</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.SessionSocialReferrerGate">Continue/Halt : If Session Social Referrer Gate</option>
              { this.props.altpay ?
                  <option value="com.sourcepoint.msgsvc.scenario.steps.AltPayCompGate">Continue/Halt : If AltPay Comp Gate</option> :
                  null
              }
              }
              { this.props.welect ?
                  <option value="com.sourcepoint.msgsvc.scenario.steps.WelectCompGate">Continue/Halt : If Welect Comp Gate</option> :
                  null
              }
              <option value="com.sourcepoint.msgsvc.scenario.steps.CustomCompGate">Continue/Halt : If Custom Comp Gate</option> :
              <option value="com.sourcepoint.msgsvc.scenario.steps.ChoiceOptionOIRGate">Continue/Halt : If Opt-In Recovery</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.GeoGate">Continue/Halt : If in Country</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.ClientIPGate">Continue/Halt : If Client IP in List</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways">Message : Show Always</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce">Message : Show Once</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes">Message : Show N Times</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap">Message : Frequency Cap</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews">Delay PV : N Simple </option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.ModNDelay">Delay PV : N Circular Repeat</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.TimeDelay">Time Delay</option>
              <option value="com.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit">Session Message Number Limit</option>
              <option value="com.sourcepoint.msgsvc.scenario.IfStatement">If Then Else Step Branching</option>
            </select>
        );
    }
}
