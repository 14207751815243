import React, {useEffect} from "react";
import { preferences_steps } from "../helper";
import { getOrderedStepsKeys, getStepsDataFieldMap } from "../../../common/SequentialNavigationWizard/SequentialLeftNav";
import AddCategories from "./AddCategories";
import LanguageAndTranslations from "./LanguageAndTranslations";
import IntegrationCategoryMapping from "./IntegrationCategoryMapping";
import SVG from "react-inlinesvg";
import { RenderLimitedChips } from "../../../../styleguide";
import { Divider, Tag, Typography } from "antd";
import { fromJS } from "immutable";
import redirectIcon from "../../../../assets/icons/link.svg";

function scrollToElement2(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'instant'});
  }
}

const Summary = (props) => {
  const stepsKeyNameMap = getStepsDataFieldMap(fromJS(preferences_steps), 'title')
  delete stepsKeyNameMap.get('summary');
  const selectedProperties = props.configuration?.propertyIds.map(
    (siteId) => props.sitesNameMap.get(String(siteId))
  );
  const propertyChips = selectedProperties.map((s) => (<Tag>{s}</Tag>))

  const generateStepSummray = (stepKey) => {
    let component;
    switch (stepKey) {
      case "name_properties":
        component = (
          <div className="step" id="name_properties">
            <div className="field">
              <div className="heading">
                <div>{stepsKeyNameMap.get(stepKey)}</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                 <SVG src={redirectIcon}/>
                </div>
              </div>
              <div className="field-value">{props.configuration.name}</div>
            </div>
            <div className="field">
              <div className="heading">Property Selection</div>
              <div className="context">
                Select the properties or property groups you wish these
                regulation settings to apply to.
              </div>
              <div className="content">
                <RenderLimitedChips chips={propertyChips} cutOff={10}/>
              </div>
            </div>
            <Divider />
          </div>
        );
        break;
      case "add_categories":
        component = (
          <div className="step" id="add_categories">
            <div className="field">
              <div className="heading">
                <div>{stepsKeyNameMap.get(stepKey)}</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <AddCategories
                configuration={props.configuration}
                categories={props.configuration?.categories}
                updateConfiguration={()=>{}}
                readOnly={true}
              />
            </div>
            <Divider />
          </div>
        );
        break;
      case "language_translations":
        component = (
          <div className="step" id="language_translations">
            <div className="field">
              <div className="heading">
                <div>{stepsKeyNameMap.get(stepKey)}</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <LanguageAndTranslations
                readOnly={true}
                regulation={props.configuration}
                categories={props.configuration?.categories}
                updateConfiguration={() => {}}
              />
            </div>
            <Divider />
          </div>
        );
        break;
      case "category_mapping":
        component = (
          <div className="step" id="category_mapping">
            <div className="field">
              <div className="heading">
                <div>{stepsKeyNameMap.get(stepKey)}</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <IntegrationCategoryMapping
                configuration={props.configuration}
                updateConfiguration={() => {}}
                readOnly={true}
              />
            </div>
          </div>
        );
        break;
    }
    return component;
  };

  const components = props.stepArray.map((stepKey) => {
    return generateStepSummray(stepKey);
  });

  const highlightStep = (idx) => {
    let navLinks = document.querySelectorAll('.summary-nav .nav-item');
    navLinks.forEach(function(navLink) {
      navLink.classList.remove('highlighted');
    });
    navLinks[idx].classList.add('highlighted');
    navLinks[idx]?.scrollIntoView({ behavior: "smooth", block: "start" })    
  };

  const handleSummaryTopNav = (key, idx) => {
    scrollToElement2(key);
    setTimeout(()=>{
      highlightStep(idx)
    } , 100)
  }

  useEffect(()=>{
    const summaryContainer = document.getElementById('usp-summary-page')
    summaryContainer.addEventListener('scroll', function() {
      let sections = document.querySelectorAll('.summary-components .step');
      
      for (let index = 0; index < sections.length; index++) {
        let section = sections[index];
        let rect = section.getBoundingClientRect();

        if (rect.top >= 0 && rect.top <= summaryContainer.clientHeight) {
          highlightStep(index);
          if(index !== sections.length - 2) break;
        }
      }
    });
    const firstNavLink = document.querySelectorAll('.summary-nav .nav-item')[0];
    firstNavLink.classList.add('highlighted')
  },[])
  
  return (
    <div>
      <div className="summary-header">
        <div className="step-header">
          <Typography.Title level={5}>Summary</Typography.Title>
        </div>
        <nav className="summary-nav">
          {getOrderedStepsKeys(fromJS(preferences_steps)).filter(a => a != "summary").map( (key, idx) => (<div className="nav-item pointer" onClick={()=> handleSummaryTopNav(key, idx)} title={stepsKeyNameMap.get(key)}>{stepsKeyNameMap.get(key)}</div>))}
        </nav>
      </div>
      <div className="summary-components"  id="usp-summary-page">
        {components}
      </div>
    </div>
  );
};

export default Summary;