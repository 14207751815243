import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getStandardsAjax,
  createStandardAjax,
  updateStandardAjax,
  deleteStandardAjax,
  getRulesAjax,
  getTopTenRulesAjax,
} from '../api/consent_quality/standard';

export const getRules = actionCreator(
  getRulesAjax,
  rulesPending,
  rulesLoaded,
  rulesError,
);

export const getTopTenRules = actionCreator(
  getTopTenRulesAjax,
  topTenRulesPending,
  topTenRulesLoaded,
  topTenRulesError,
  topTenRulesError,
  topTenRulesCached,
);

export const getStandards = actionCreator(
  getStandardsAjax,
  standardsPending,
  standardsLoaded,
  standardsError,
  standardsCached,
);

export const createStandard = actionCreator(
  createStandardAjax,
  standardPending,
  standardCreated,
  standardsError,
);

export const updateStandard = actionCreator(
  updateStandardAjax,
  standardPending,
  standardUpdated,
  standardsError,
);

export const deleteStandard = actionCreator(
  deleteStandardAjax,
  standardsPending,
  standardDeleted,
  standardsError,
);

function rulesPending() {
  return {
    type: types.RULES_PENDING,
  };
}

function rulesLoaded(rules) {
  return {
    type: types.RULES_LOADED,
    rules,
  };
}

function rulesError(error) {
  return {
    type: types.RULES_ERROR,
    error,
  };
}

function topTenRulesPending() {
  return {
    type: types.TOP_TEN_RULES_PENDING,
  };
}

function topTenRulesLoaded(rules) {
  return {
    type: types.TOP_TEN_RULES_LOADED,
    rules,
  };
}

function topTenRulesError(error) {
  return {
    type: types.TOP_TEN_RULES_ERROR,
    error,
  };
}

function standardsPending() {
  return {
    type: types.STANDARDS_PENDING,
  };
}

function standardsLoaded(standards) {
  return {
    type: types.STANDARDS_LOADED,
    standards,
  };
}

function standardsError(error) {
  return {
    type: types.STANDARDS_ERROR,
    error,
  };
}

function standardCreated(standard) {
  return {
    type: types.STANDARD_CREATED,
    standard,
  };
}

function standardUpdated(standard) {
  return {
    type: types.STANDARD_UPDATED,
    standard,
  };
}

function standardDeleted(deletedStandard) {
  return {
    type: types.STANDARD_DELETED,
    id: deletedStandard._id,
  };
}

function standardPending() {
  return {
    type: types.STANDARDS_PENDING,
  };
}

function topTenRulesCached() {
  return {
    type: types.TOP_TEN_RULES_CACHED,
  };
}

function standardsCached() {
  return {
    type: types.STANDARDS_CACHED,
  };
}
