import PropTypes from 'prop-types';
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { PRIVACY_MANAGER_URL, NO_PRIVACY_MANAGER_ERROR, NO_PRIVACY_MANAGER_ERROR_SITE_GROUP} from '../../../../constants';
import { getParameterByName } from '../../../utils';
import { MessageVariables, IdNameValue } from '../../../../records/message_records';

const FormItem = Form.Item;
const Option = Select.Option;

export default class PrivacyManagerSelector extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    initialValue: PropTypes.string,
    fieldLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    isDataLoaded: PropTypes.bool.isRequired,
    variables: PropTypes.instanceOf(MessageVariables).isRequired,
    updateParentState: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: ""
  };

  handleChange = (value) => {
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      let variables = this.props.variables || new MessageVariables({});
      const defalutPmIdVar = new IdNameValue({ name: '$$privacyManagerIdHiddenVarriable$$', value: value.split('privacy_manager_id=')[1] });
      const defaultVariables = variables
        .get('defaultVariables')
        .filterNot(v => v.name === '$$privacyManagerIdHiddenVarriable$$')
        .push(defalutPmIdVar);

      variables = variables.set('defaultVariables', defaultVariables);
      this.props.updateParentState({ variables });
    }
    this.props.onChange(value);
  };

  generateUrl = (id) => `${PRIVACY_MANAGER_URL}?privacy_manager_id=${id}`;

  render() {
    const isDataLoaded = this.props.isDataLoaded;
    const options = this.props.options.toJS();
    // NOTE: Do not specify an initial value if we are not getting one
    // from props. We need the user to actually select a privacy
    // manager so we can trigger onChange for a new message
    let initialValue = this.props.initialValue;
    let pmError = NO_PRIVACY_MANAGER_ERROR;
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      pmError = NO_PRIVACY_MANAGER_ERROR_SITE_GROUP;
      const siteGroupPmVar = this.props.variables.defaultVariables.find(v => v.get('name') === '$$privacyManagerIdHiddenVarriable$$');
      if (siteGroupPmVar) initialValue = this.generateUrl(siteGroupPmVar.get('value'));
    }
    const errorMsg = options.length ? '' : pmError;
    return (
      <div className={ this.props.className }>
        <label className="choice-option-select-label">{ this.props.fieldLabel }</label>
        <FormItem
          validateStatus={ !isDataLoaded && errorMsg ? 'error' : '' }
          help={ !isDataLoaded && errorMsg }
        >
          {this.props.form.getFieldDecorator('privacyManager', {
            initialValue: initialValue,
            rules: [{ required: true, message: NO_PRIVACY_MANAGER_ERROR }],
            validateTrigger: ['onChange'],
          })(
          <Select
            size="large"
            onChange={ this.handleChange }
            disabled={ !this.props.editing }
            placeholder="[Privacy Manager]"
          >
          { options.map((opt) =>
            <Option key={ opt.id } value={ this.generateUrl(opt.id) }>{ opt.name }</Option>) }
          </Select>)}
        </FormItem>
      </div>
    )
  }
}
