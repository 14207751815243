import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Icon } from 'antd';
import { Select, Infotip, Button, Radio } from '../../../styleguide';
import moment from 'moment';
import { PropertyFilter } from '../../../records/site_records';
//import { COUNTRY_LANGUAGES } from '../../../constants';
import { timeIntervals } from './helper';
import { Site } from '../../../records/site_records';
import { FilterFilled, CheckCircleFilled } from '@ant-design/icons';
const { Option } = Select;

export default class Filter extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    setProperties: PropTypes.func.isRequired,
  };
  state = {
    filter: new PropertyFilter(),
    visible: false,
    filterTick: false,
    tempFilter: new PropertyFilter(),
    isApplied: false,
  };
  handleVisibleChange = (visible) =>
    this.setState(
      (prevState) => ({
        visible,
        filter: this.state.isApplied
          ? prevState.tempFilter
          : new PropertyFilter(),
      }),
      () => this.setState({ filterTick: this.filterSelected() })
    );
  setFilterValues = (field, value) => {
    let filter = this.state.filter;
    filter = filter.merge({
      [field]: value,
    });
    this.setState({ filter });
  };
  sortProperties = (sortOption, properties) => {
    let filteredProperties = properties;
    switch (sortOption) {
      case 'recent':
        filteredProperties = filteredProperties.sort(
          (a, b) =>
            moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
        );
        break;
      case 'old':
        filteredProperties = filteredProperties.sort(
          (a, b) =>
            moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
        );
        break;
      case 'des':
        filteredProperties = filteredProperties.sort((a, b) =>
          b.domain.localeCompare(a.domain)
        );
        break;
      case 'asc':
        filteredProperties = filteredProperties.sort((a, b) =>
          a.domain.localeCompare(b.domain)
        );
        break;
    }
    return filteredProperties;
  };
  clearFilters = () => this.setState({ filter: new PropertyFilter() });
  applyFilter = () => {
    const type = this.state.filter.type;
    const sortOption = this.state.filter.sortBy;
    const timespan = this.state.filter.timespan;
    let properties = this.props.properties;
    if (type) {
      properties = properties.filter((p) => type === p.type);
    }
    if (sortOption) {
      properties = this.sortProperties(sortOption, properties);
    }
    if (timespan) {
      properties = properties.filter(
        (p) =>
          moment(p.createdAt).isAfter(moment().subtract(timespan, 'd')) ||
          moment(p.createdAt).isSame(moment().subtract(timespan, 'd'))
      );
    }
    this.setState(
      (prevState) => ({
        filterTick: this.filterSelected(),
        tempFilter: prevState.filter,
        isApplied: true,
      }),
      () => this.handleVisibleChange(false)
    );
    this.props.setProperties(properties);
  };
  filterSelected = () =>
    this.state.filter.type ||
    this.state.filter.language ||
    this.state.filter.createdBy ||
    this.state.filter.timespan ||
    this.state.filter.sortBy;

  render() {
    return (
      <Infotip
        placement="rightBottom"
        className="property-filter-popover"
        content={
          <div>
            Type
            <Select
              placeholder="Select"
              onChange={(value) => this.setFilterValues('type', value)}
              value={this.state.filter.type}
            >
              <Option value="" disabled={true}>
                Select
              </Option>
              {this.props.propertyTypesNum.map((p, i) => (
                <Option key={i} value={p.get('code')}>
                  {p.get('label')}
                </Option>
              ))}
            </Select>
            {/* Created By
            <Select
              style={{ width: '100%' }}
              placeholder="Select"
              onChange={(value) => this.setFilterValues('createdBy', value)}
              value={
                this.state.clearFilter
                  ? new PropertyFilter().createdBy
                  : this.state.filter.createdBy
              }
            >
              <Option value="all">All</Option>
              <Option value="Name 1">Name 1</Option>
              <Option value="Name 2">Name 2</Option>
              <Option value="Name 3">Name 3</Option>
              <Option value="Name 4">Name 4</Option>
              <Option value="Name 5">Name 5</Option>
              <Option value="Name 6">Name 6</Option>
            </Select> */}
            Created in past
            <Select
              placeholder="Select"
              onChange={(value) => this.setFilterValues('timespan', value)}
              value={this.state.filter.timespan}
            >
              {timeIntervals.map((t, i) => (
                <Option key={i} value={t.get('value')}>
                  {t.get('label')}
                </Option>
              ))}
            </Select>
            Sort By
            <br />
            <Radio.Group
              onChange={(e) => this.setFilterValues('sortBy', e.target.value)}
              value={this.state.filter.sortBy}
            >
              <Radio.Button value="recent">Most recent first </Radio.Button>
              <Radio.Button value="old">Oldest first</Radio.Button>
              <Radio.Button value="asc">Alphabetical (A - Z)</Radio.Button>
              <Radio.Button value="des">Alphabetical (Z - A)</Radio.Button>
            </Radio.Group>
            {/* Default Language
            <Select
              style={{ width: '100%' }}
              placeholder="Select"
              onChange={(value) => this.setFilterValues('language', value)}
              value={
                this.state.clearFilter
                  ? new PropertyFilter().language
                  : this.state.filter.language
              }
            >
              <Option value="all">All</Option>
              {COUNTRY_LANGUAGES.map((lng) => (
                <Option key={lng.code} value={lng.code}>
                  {lng.language} ({lng.code})
                </Option>
              ))}
            </Select> */}
            <div>Note: Property can be a part of only one property group</div>
            <div className="property-filter-btn">
              <Button onClick={() => this.handleVisibleChange(false)}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={this.applyFilter}
              >
                Apply
              </Button>
            </div>
          </div>
        }
        title={
          <React.Fragment>
            <span>Select Filters</span>
            <Button type="link" onClick={this.clearFilters}>
              Clear Filters
            </Button>
          </React.Fragment>
        }
        trigger="click"
        open={this.state.visible}
        onOpenChange={this.handleVisibleChange}
      >
        {this.state.filterTick ? <CheckCircleFilled /> : null}
        <FilterFilled /> <span className='filter-title'>Filter</span>
      </Infotip>
    );
  }
}
