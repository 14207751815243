import { Record, List } from 'immutable';

export const SiteToMessageMap = Record({
  siteId: null,
  messageId: null,
  messageSettings: null,
})
//TODO: change message settings

export class Partition extends Record({
  weight: 100,
  geoTargeting: 'ALL US',
  siteToMessageMap: List([]),
}) {
  constructor(partition) {
    const updatedPartition = Object.assign({}, partition);
    if (partition.siteToMessageMap) {
      updatedPartition.siteToMessageMap = List(partition.siteToMessageMap.map(obj => new SiteToMessageMap(obj)));
    }
    super(updatedPartition);
  }
};

export class PartitionSet extends Record({
  partitions: List([new Partition({ weight: 100, geoTargeting: 'ALL US'})]),
}) {
  constructor(pSet) {
    const updatedPSet = Object.assign({}, pSet);
    if (pSet.partitions) {
      updatedPSet.partitions = List(pSet.partitions.map(p => new Partition(p)));
    }
    super(updatedPSet);
  }
}

export const DnsMessage = Record({
  id: null, 
  description: '',
  messageSettings: null,
})

export class SiteToMessages extends Record({
  siteId: null,
  messages: List([]),
}) {
  constructor(obj) {
    const updatedObj = Object.assign({}, obj);
    updatedObj.messages = List(obj.messages.map(m => new DnsMessage(m)));
    super(updatedObj);
  }
}