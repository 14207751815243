import React from 'react';
import { Select } from '../../../../styleguide'
const { Option } = Select;

export default class MessageDropDown extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { options, title, onSelect, defaultValue, value } = this.props;
    return (
      <div className="select-container">
        <label className="label-style">{title}</label>
        <Select
          defaultValue={defaultValue}
          onChange={onSelect}
          value={value}
        >
          {options.map((item, index) => (
            <Option key={index} value={item.get('key')}>
              {item.get('label')}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}
