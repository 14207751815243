import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Table, Input, Checkbox, Button, Modal } from 'antd';
import {
  getDsarRequests,
  getRequestFile,
  completeRequest,
  documentUploaded,
} from '../../../actions/dsar_actions';
import {
  getAllSites,
} from '../../../actions/site_actions';
import { SubjectAccessRecord } from '../../../records/dsar_records';
import { User } from '../../../records/account_records';
import DataModal from './DataModal';

class RequestsList extends React.Component {
  static propTypes = {
    dsarRequests: ImmutablePropTypes.listOf(PropTypes.instanceOf(SubjectAccessRecord)).isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
    getDsarRequests: PropTypes.func.isRequired,
    getRequestFile: PropTypes.func.isRequired,
    completeRequest: PropTypes.func.isRequired,
    documentUploaded: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const update = {};
    if (prevState.dsarRequests !== nextProps.dsarRequests) {
      update.dsarRequests = nextProps.dsarRequests;
    }
    return update;
  }

  state = {
    requestIdForDataModal: null,
    requestFilter: '',
    dsarRequests: this.props.dsarRequests,
  };

  componentDidMount() {
    this.props.getAllSites();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getDsarRequests(this.props.currentUser.accountId);
    }
  }

  siteFilterHandler = (key) =>{
    this.setState({filterKey:key});
  }

  getReadableDate(time) {
    return moment.utc(time).format('MMM D YYYY');
  }

  competeRequest = (e, requestId) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Mark request as completed.',
      content: 'Once request is marked as completed attached file can no longer be modified.',
      onOk: () => {
        this.props.completeRequest(requestId)
      },
    });
  }

  generateDataSource(requests) {
    return requests.map(request => {
      const date = this.getReadableDate(request.dateCreated);
      let completed;
      if (request.responseFileStatus === 'COMPLETED') {
        completed = <Checkbox checked></Checkbox>      
      } else if (request.responseFileStatus === 'UPLOADED') {
        completed = <Button onClick={ (e) => this.competeRequest(e, request.requestId) }>Mark Completed</Button>
      }

      return Map({
        id: request.requestId,
        date,
        status: request.responseFileStatus,
        property: this.getSiteDomain(request.siteId),
        files: request.responseFileStatus === 'INITIAL' ? 'No' : 'Yes',
        completed: completed,
      });
    });
  }

  generateColumns = () => {
    return List([Map({
      title: 'Request Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.status.localeCompare(b.status),
    }), Map({
      title: 'Date Created',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateA - dateB;
      },
    }), Map({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      filters: new List([
        Map({ text: 'Initial', value: 'INITIAL' }),
        Map({ text: 'Uploaded', value: 'UPLOADED' }),
        Map({ text: 'Completed', value: 'COMPLETED' }),
        Map({ text: 'Delivered', value: 'DLIVERED' }),
      ]),
      onFilter: (value, record) => {
        return record.status === value;
      },
    }),Map({
      title: 'Propoerty',
      dataIndex: 'property',
      key: 'property',
      className: 'property',
      sorter: (a, b) => a.status.localeCompare(b.status),
    }),Map({
      title: 'Files',
      dataIndex: 'files',
      key: 'files',
      sorter: (a, b) => a.status.localeCompare(b.status),
    }),Map({
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      sorter: (a, b) => a.status.localeCompare(b.status),
    })]);
  }

  getSiteDomain = (id) => {
    const site = this.props.sites.find(s => s.id === id)
    return site ? site.domain : `no site with id ${id} found`;
  }

  handleFilterREquest = (e) => {
    this.setState({ requestFilter: e.target.value.trim() });
  }

  getRequestSiteId = (requestId) => {
    return this.props.dsarRequests.find(r => r.requestId === requestId).siteId;
  }

  rowClassName = (record) => {
    if (record.status === 'INITIAL' || record.status === 'UPLOADED') {
      return 'clickable';
    } else {
      return 'not-clickable';
    }
  }

  onRowClick = (record) => {
    if (record.status === 'INITIAL' || record.status === 'UPLOADED') {
      this.setState({ requestIdForDataModal: record.id});
    }
  }

  render() {
    let modal;
    if (this.state.requestIdForDataModal) {
      const hasFileToRetrieve = this.props.dsarRequests.find(r => r.requestId === this.state.requestIdForDataModal).responseFileStatus !== 'INITIAL';

      modal = (
        <DataModal
          closeModal={ () => this.setState({ requestIdForDataModal: null }) }
          requestId={ this.state.requestIdForDataModal }
          hasFileToRetrieve={ hasFileToRetrieve }
          retrieveFile={ () => this.props.getRequestFile(this.props.currentUser.accountId, this.getRequestSiteId(this.state.requestIdForDataModal), this.state.requestIdForDataModal) }
          accountId={ this.props.currentUser.accountId }
          siteId={ this.getRequestSiteId(this.state.requestIdForDataModal) }
          documentUploaded={ this.props.documentUploaded }
        />
      );
    }
      
    let errorMessage;
    // if (this.props.error) {
    //   errorMessage = <p className="error">{ this.props.error.message.slice(0,1).toUpperCase() + this.props.error.message.slice(1) }</p>;
    // }
    let requests = this.props.dsarRequests;
    if (requests) {
      requests = requests.filter(r => {
        return (r.requestId && r.requestId.includes(this.state.requestFilter.toLowerCase())) ||
        (r.siteId && this.getSiteDomain(r.siteId).toLowerCase().includes(this.state.requestFilter.toLowerCase()));
      });
    }

    return (
      <div>
        { modal }
        { errorMessage }
        <div className='header-container'>
          <div><p className='title'>Data Requests</p><p>{`(${requests.size})`}</p></div>
          <Input
            className='search-users'
            placeholder='Search request'
            value={ this.state.requestFilter }
            onChange={ this.handleFilterREquest }
          />
          </div>
        <Table
          className="dsar-requests card altpay-table"
          dataSource={ this.generateDataSource(requests).toJS() }
          columns={ this.generateColumns().toJS() }
          rowClassName={ this.rowClassName }
          onChange={ this.onChange }
          onRowClick={ this.onRowClick }
        />
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    dsarRequests: store.dsarState.getIn(['dsarRequests', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
  };
};

export default connect(mapStateToProps, {
  getDsarRequests,
  getRequestFile,
  completeRequest,
  documentUploaded,
  getAllSites,
})(RequestsList);