import { OrderedSet } from 'immutable';

import { actionCreator } from './helpers';
import { changeLogsAjax } from '../api/change_log';
import { CHANGE_LOGS_PENDING, CHANGE_LOGS_LOADED, CHANGE_LOGS_ERROR } from '../actions/action_types';
import { ChangeLog } from '../records/changelog_records';

export const getChangeLogs = actionCreator(
  changeLogsAjax,
  changeLogsPending,
  changeLogsLoaded,
  changeLogsError,
);

function changeLogsLoaded(logs) {
  return {
    type: CHANGE_LOGS_LOADED,
    payload: new OrderedSet(logs.map(log => new ChangeLog(log)))
  };
}

function changeLogsError(err) {
  console.log('error fetching logs:', err)
  return { type: CHANGE_LOGS_ERROR };
}

function changeLogsPending() {
  return { type: CHANGE_LOGS_PENDING };
}