import PropTypes from 'prop-types';
import React from 'react';
import StepSatisfyOption from './StepSatisfyOption';
import { CustomCompGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';

export default class StepCustomCompGate extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    data: PropTypes.instanceOf(CustomCompGate).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
  }

  handleChangeSatisfy = (continueOnSatisfy) => {
    const data = this.props.data.set('continue', continueOnSatisfy);
    this.props.onChange(data);
  }

  render() {
    const continueOnSatisfy = this.props.data.continue ? 'Yes' : 'No';

    if (!this.props.editing) {
      return (<div>Continue if Custom Comp: { continueOnSatisfy }</div>);
    } else {
      return (
        <div className="step continue-halt">
          <label>Continue if Custom Comp:
            <StepSatisfyOption
              value={ this.props.data.continue }
              onChange={ this.handleChangeSatisfy }
              focusOnMount
            />
          </label>
        </div>
      );
    }
  }
}
