import PropTypes from 'prop-types';
import React from 'react';
import { List, Map } from 'immutable';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Button, Select } from 'antd';
import classNames from 'classnames';

export default class ChangeScoreModal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    changeScores: PropTypes.func.isRequired,
  }

  state = {
    rules: new List([new Map({ ruleId: null, score: null }) ]),
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  handleApply = () => {
    let rules = new Map({});
    this.state.rules.forEach(rule => {
      rules = rules.set(rule.get('ruleId'), rule.get('score'));
    });
    this.props.changeScores(rules);
    this.props.closeModal();
  }

  selectRule = (ruleId, idx) => {
    const updatedRules = this.state.rules.setIn([idx, 'ruleId'], ruleId);
    this.setState({ rules: updatedRules });
  }

  addRule = () => {
    const updatedRules = this.state.rules.push(new Map({ ruleId: null, score: null }) );
    this.setState({ rules: updatedRules });
  }

  setRuleScore = (idx, score) => {
    const updatedRules = this.state.rules.setIn([idx, 'score'], score);
    this.setState({ rules: updatedRules });
  }

  renderRule = (rule, idx) => {
    return (
      <div>
        <Select
          onChange={ (e) => this.selectRule(e, idx) }
          defaultValue='Select rule to score'
          value={ rule.get('ruleId') || 'Select rule to score' }
        >
          { this.props.rules.map(r => (
            <Select.Option key={ r.ruleId } value={ r.ruleId }>{r.name}</Select.Option>
          ))}
        </Select>
        <Button className={ classNames({ selected: rule.get('score') === 1 })} onClick={ () => this.setRuleScore(idx, 1) }><CheckOutlined style={{ color: 'green' }} /></Button>
        <Button className={ classNames({ selected: rule.get('score') === 0 })} onClick={ () => this.setRuleScore(idx, 0) }><CloseOutlined style={{ color: 'red' }} /></Button>
        <Button className={ classNames({ selected: rule.get('score') === -1 })} onClick={ () => this.setRuleScore(idx, -1) }>N/A</Button>
      </div>
    );
  }
 
  render() {
    return (
      <Modal
        onOk={ this.handleApply }
        okText='Save'
        onCancel={ this.handleCancelModalChanges }
        className='new-domain-set new-standard batch-edit'
        closable={ false }
        destroyOnClose
        visible
      >
        <p className='title'>Batch Edit</p>
        <div className='modal-container'>
          <p>Chose rule to edit</p>
          {
            this.state.rules.map((rule, idx) => (
              this.renderRule(rule, idx)
            ))
          }
          {
            <Button onClick={ this.addRule }>+</Button>
          }
        </div>
      </Modal>
    );
  }
}