/* eslint-disable react/prop-types */
import { Collapse, Empty, Image, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setMetricsList, summaryLoaded } from '../../../actions/diagnose_dashboard_actions';
import arrowDown from '../../../assets/icons/arrow_down.svg';
import ComplianceMetrics from './ComplianceMetrics';
import { mapSummaryToMetricsList } from './details_pages/mapSummaryToMetricsList';
import SummaryBlock from './summary_block/SummaryBlock';

const DiagnoseDashboard = ({
  metricsList,
  summaryDiagnose,
  selectedMetrics,
  setMetricsList,
  summaryPending,
  summaryLoaded,
}) => {
  const handleSetMetricList = (metrics) => {
    setMetricsList(metrics);
  };

  return (
    <div>
      <Spin
        spinning={summaryPending}
        className="diagnose-spinning"
        tip="Processing a large query; please wait or leave it open and check later."
        size="large"
      >
        {summaryDiagnose?.length ? (
          <Collapse
            expandIcon={(props) => <Image className={props.isActive ? 'active' : ''} src={arrowDown} preview={false} />}
            accordion
            defaultActiveKey={['1']}
          >
            <Collapse.Panel header={<div className="headers secondary_header">Summary</div>}>
              <SummaryBlock
                summaryData={summaryDiagnose}
                metricsList={metricsList}
                selectedMetrics={selectedMetrics}
                onMetricsSelect={handleSetMetricList}
              />
            </Collapse.Panel>
            <Collapse.Panel header={<div className="headers secondary_header">Compliance Metrics</div>}>
              <ComplianceMetrics resetSummary={summaryLoaded} metricsList={mapSummaryToMetricsList(summaryDiagnose)} />
            </Collapse.Panel>
          </Collapse>
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    summaryDiagnose: store.diagnoseDashboardState.getIn(['summaryDiagnose', 'value']),
    selectedMetrics: store.diagnoseDashboardState.getIn(['selectedMetrics', 'value']),
    metricsList: store.diagnoseDashboardState.getIn(['metricsList', 'value']),
    summaryPending: store.diagnoseDashboardState.get('summaryPending'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      summaryLoaded,
      setMetricsList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DiagnoseDashboard);
