import PropTypes from 'prop-types';
import React from 'react';
import { Select, Tabs } from 'antd';
import { connect } from 'react-redux';
import { Map, List, fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { getAllSites, getSiteGroups } from '../../../actions/site_actions.js';

import {
  getGdprV2ReportPropertyData,
  getGdprV2ReportVendorData,
  getGdprV2ReportMessageData,
  getGdprV2ReportPurposeData,
  getGdprV2ReportSpecialFeaturesData,
} from '../../../actions/gdpr_performance_actions_v2.js';
import { getConsentReportChangeLogData } from '../../../actions/consent_performance_actions_v1.js';
import { VendorFilters } from '../../../records/gdpr_performance_records_v2.js';
import Loading from '../../common/Loading.js.jsx';

import CustomDateRangeSelect from '../../common/CustomDateRangeSelect';
import DatePickerReports from '../../common/DatePickerReports';
import { User } from '../../../records/account_records';

import ConsentPerformanceGraph from './ConsentPerformanceGraph';
import ConsentPerformanceMsg from './ConsentPerformanceMsg';
import ConsentPVVendor from './ConsentPVVendor';
import { GEO_FILTER_LIST } from '../../../constants';

import CustomCriteriaSelect from '../../ccpa/ccpa_reporting/CustomCriteriaSelect';
import CustomPopover from '../../common/CustomPopover';
import { tabTooltipContentV2 } from '../performanceNew/intructionalText';
import { defaultWeekStart, defaultWeekEnd } from './helper.js';
import { CurrentUserContext } from '../../contexts';

const { TabPane } = Tabs;
const { Option } = Select;
let newVendorFilters;
const regions = GEO_FILTER_LIST.filter((_, i) => i !== 0);

class ConsentPerformanceReportV2 extends React.PureComponent {
  static propTypes = {
    getAllSites: PropTypes.func.isRequired,
    sites: ImmutablePropTypes.list.isRequired,
    getGdprV2ReportPropertyData: PropTypes.func.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    currentUser: PropTypes.instanceOf(User),
  };

  constructor(props) {
    super(props);
    const rangeSelect = Map({
      startDate: defaultWeekStart,
      endDate: defaultWeekEnd,
      datePicker: 'WEEK',
    });
    const newVendorFilters = new VendorFilters({
      startDate: defaultWeekStart,
      endDate: defaultWeekEnd,
    });

    this.state = {
      vendorFilters: newVendorFilters,
      datePicker: 'WEEK',
      selectedSite: 'Select a property',
      criteriaList: List(),
      messagedTableData: List(),
      rangeSelect: rangeSelect,
      siteId: List(),
      geo: regions,
      vendorRegions: List(),
      vendorProperty: 'Select Property',
      showTopLevelCriteria: true,
      tabActiveKey: '1',
    };
  }

  componentDidMount() {
    this.props.getAllSites();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getSiteGroups(this.props.currentUser.accountId); 
    }
  }

  selectDates = (dates, datePicker) => {
    let rangeSelect = this.state.rangeSelect
      .set('startDate', dates.start)
      .set('endDate', dates.end)
      .set('datePicker', datePicker);
    let criteriaList = this.state.criteriaList.map((c) =>
      c.set('startDate', dates.start).set('endDate', dates.end)
    );
    this.setState({ datePicker, rangeSelect, criteriaList }, () => {
      if (
        this.state.criteriaList.size <= 3 &&
        this.state.criteriaList.size > 0
      ) {
        let getSelectedSiteIds = List(
          this.state.criteriaList
            .map((c) => List(c.get('siteId')))
            .reduce((prev, current) =>
              prev
                .toSet()
                .union(current.toSet())
                .toList()
            )
        );
        this.props.getGdprV2ReportMessageData(
          this.state.criteriaList,
          this.state.rangeSelect
        );
        this.props.getGdprV2ReportPropertyData(
          this.state.criteriaList,
          this.state.rangeSelect
        );
        this.props.getConsentReportChangeLogData(
          getSelectedSiteIds,
          this.props.currentUser.accountId,
          this.state.rangeSelect
        );
      }
      if (
        this.state.vendorFilters.get('siteId') &&
        this.state.vendorFilters.get('siteId') != ''
      ) {
        this.props.getGdprV2ReportVendorData(
          this.state.vendorFilters,
          this.state.rangeSelect
        );
        this.props.getGdprV2ReportSpecialFeaturesData(
          this.state.vendorFilters,
          this.state.rangeSelect
        );
        this.props.getGdprV2ReportPurposeData(
          this.state.vendorFilters,
          this.state.rangeSelect
        );
      }
    });
  };

  selectDatePicker = (event, dates) => {
    this.selectDates(dates, event.target.value);
  };

  getSiteGroupSiteIds = (id) => {
    return this.props.siteGroups.find((sg) => sg.siteId === id).siteIds;
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  selectSingleSite = (value) => {
    newVendorFilters = this.state.vendorFilters.set('siteId', value);
    this.setState(
      {
        vendorProperty: value.toString(),
      },
      () => {
        this.updateVendorState(newVendorFilters);
      }
    );
  };

  selectVendorRegion = (value) => {
    const checkForAll = value.some((id) => id === 'All');
    let valueList;
    if (value && value.size && checkForAll) {
      if (value.size === regions.size + 1) {
        valueList = List();
      } else {
        valueList = regions;
      }
    } else {
      valueList = value;
    }

    this.setState({ vendorRegions: valueList }, () => {
      newVendorFilters = this.state.vendorFilters.set('geo', valueList);
      this.updateVendorState(newVendorFilters);
    });
  };

  updateVendorState = (newVendorFilters) => {
    this.setState({ vendorFilters: newVendorFilters }, () => {
      if (this.state.vendorFilters.get('siteId')) {
        this.props.getGdprV2ReportVendorData(
          this.state.vendorFilters,
          this.state.rangeSelect
        );
        this.props.getGdprV2ReportSpecialFeaturesData(
          this.state.vendorFilters,
          this.state.rangeSelect
        );
        this.props.getGdprV2ReportPurposeData(
          this.state.vendorFilters,
          this.state.rangeSelect
        );
      }
    });
  };

  loadCriteria = (loadComponent) => {
    if (this.state.criteriaList.size === 0) {
      return (
        <div className="adblock card no-sites-message">
          <p>
            Please add the criteria(s) to generate a GDPR performance report.
          </p>
        </div>
      );
    } else {
      return loadComponent;
    }
  };

  vendorSelect = (value) => {
    let newList = List();
    if (value != 'All') {
      newList = this.state.vendorRegions.push(value);
      if (newList.size == regions.size - 1) {
        newList = newList.push('All');
      }
    } else {
      newList = regions;
    }
    this.setState({ vendorRegions: newList }, () => {
      newVendorFilters = this.state.vendorFilters.set('geo', newList);
      this.updateVendorState(newVendorFilters);
    });
  };
  vendorDeselect = (value) => {
    let newList = List();
    if (value != 'All') {
      newList = this.state.vendorRegions.filter(
        (x) => ['All', value].indexOf(x) < 0
      );
    }
    this.setState({ vendorRegions: newList }, () => {
      newVendorFilters = this.state.vendorFilters.set('geo', newList);
      this.updateVendorState(newVendorFilters);
    });
  };
  showTopLevelCriteria = (key) => {
    this.setState(
      {
        tabActiveKey: key == '4' ? this.state.tabActiveKey : key,
      },
      () => {
        this.setState({
          showTopLevelCriteria:
            key == '3' || this.state.tabActiveKey == '3' ? false : true,
        });
      }
    );
  };
  getSitesWithoutSiteGroups = (sites) =>
    sites.filterNot((s) => s.type === 'property_group');
  updateCriteriaListCb = (criteriaList) => {
    this.setState({ criteriaList }, () => {
      let getSelectedSiteIds = List(
        this.state.criteriaList
          .map((c, i) => List(c.get('siteId')))
          .reduce((prev, current) =>
            prev
              .toSet()
              .union(current.toSet())
              .toList()
          )
      );
      this.props.getGdprV2ReportMessageData(
        this.state.criteriaList,
        this.state.rangeSelect
      );
      this.props.getGdprV2ReportPropertyData(
        this.state.criteriaList,
        this.state.rangeSelect
      );
      this.props.getConsentReportChangeLogData(
        getSelectedSiteIds,
        this.props.currentUser.accountId,
        this.state.rangeSelect
      );
    });
  };

  render() {

    if (!this.props.currentUser) {
      return null;
    }
    
    let { sites, siteGroups } = this.props;
    if (this.props.currentUser.siteAccess) {
      sites = sites.filter((s) =>
        this.props.currentUser.siteAccess.includes(s.id)
      );
      siteGroups = siteGroups
        .filter(({ siteIds }) => siteIds.toJS().every((id) => sites.toJS().find(site => site.id == id)));
    }

    sites = this.getSitesWithoutSiteGroups(sites);

    let sitesOptions = sites.map((site) => (
      <Option key={site.id} value={site.id.toString()}>
        {site.domain}
      </Option>
    ));

    let regionsOptions = regions.map((region, i) => (
      <Option key={i} value={region}>
        {region}
      </Option>
    ));

    const operations = (
      <div className="external filters">
        <CustomDateRangeSelect
          selectDatePicker={this.selectDatePicker}
          showQuarterly={true}
        />
        <DatePickerReports
          className="cp-picker"
          selectDates={this.selectDates}
          format="MM/DD/YY"
          limit={60}
          disabled={this.props.pendingRequestsMap.some((request) => request)}
          disabledDateOffset={1}
          selectedDatePicker={this.state.rangeSelect.get('datePicker')}
        />
      </div>
    );

    let loading;
    let isLoading = false;
    if (
      this.props.pendingRequestsMap.get('sites') ||
      this.props.pendingRequestsMap.get('accountId')
    ) {
      loading = <Loading />;
      isLoading = true;
    }
    let propertyTableGraph =
      this.props.pendingRequestsMap.get('propertyData') ||
        this.props.pendingRequestsMap.get('changeLogData') ? (
          <Loading />
        ) : (
          <ConsentPerformanceGraph
            changeLogData={this.props.changeLogData}
            consentPropertyData={this.props.propertyData}
            sites={sites}
            criteriaList={this.state.criteriaList}
            siteGroups={siteGroups}
          />
        );
    let messageTableGraph =
      this.props.pendingRequestsMap.get('messageConsentData') ||
        this.props.pendingRequestsMap.get('changeLogData') ? (
          <Loading />
        ) : (
          <ConsentPerformanceMsg
            changeLogData={this.props.changeLogData}
            messagedTableData={this.state.messagedTableData}
            messageConsentData={this.props.messageConsentData}
            sites={sites}
            criteriaList={this.state.criteriaList}
          />
        );
    let vendorPurposeTable =
      this.props.pendingRequestsMap.get('vendorConsentData') ||
        this.props.pendingRequestsMap.get('specialFeatureConsentData') ||
        this.props.pendingRequestsMap.get('purposeConsentData') ? (
          <Loading />
        ) : (
          <ConsentPVVendor
            sitesOptions={sitesOptions}
            regionsOptions={regionsOptions}
            vendorRegions={this.state.vendorRegions}
            selectSingleSite={(value) => this.selectSingleSite(value)}
            consentVendorData={this.props.vendorConsentData}
            maxSize={regions.size}
            vendorProperty={this.state.vendorProperty}
            selectVendorRegion={(value) => this.selectVendorRegion(fromJS(value))}
            purposeConsentData={this.props.purposeConsentData}
            specialFeatureConsentData={this.props.specialFeatureConsentData}
          />
        );
    return (
      <div className="consent-performance">
        <CustomCriteriaSelect
          sites={sites}
          siteGroups={siteGroups}
          showTopLevelCriteria={this.state.showTopLevelCriteria}
          updateCriteriaListCb={this.updateCriteriaListCb}
          rangeSelect={this.state.rangeSelect}
          showState={false}
          accountFeatures={this.props.currentUser.accountFeatures}
        />
        {isLoading ? loading : ''}

        <div className="consent-tabs-container gdpr-tab-container">
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={operations}
            onChange={this.showTopLevelCriteria}
            activeKey={this.state.tabActiveKey}
          >
            <TabPane tab="Property" key="1">
              <div className="consentblock card">
                {this.loadCriteria(propertyTableGraph)}
              </div>
            </TabPane>
            <TabPane tab="Messages" key="2">
              <div className="consentblock card">
                {this.loadCriteria(messageTableGraph)}
              </div>
            </TabPane>
            <TabPane tab="Vendors/Purposes/Special Features" key="3">
              <div className="consentblock card">{vendorPurposeTable}</div>
            </TabPane>
            <TabPane
              tab={<CustomPopover tooltipContent={tabTooltipContentV2} />}
              key="4"
            ></TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountId: store.accountState.getIn(['accountId', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    propertyData: store.gdprV2ReportState.getIn(['propertyData', 'value']),
    vendorConsentData: store.gdprV2ReportState.getIn(['vendorsData', 'value']),
    specialFeatureConsentData: store.gdprV2ReportState.getIn([
      'specialFeatureData',
      'value',
    ]),
    messageConsentData: store.gdprV2ReportState.getIn(['messageData', 'value']),
    purposeConsentData: store.gdprV2ReportState.getIn(['purposeData', 'value']),
    changeLogData: store.consentPerformanceReportState.getIn([
      'changeLogData',
      'value',
    ]),
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      propertyData: store.gdprV2ReportState.getIn(['propertyData', 'pending']),
      vendorConsentData: store.gdprV2ReportState.getIn([
        'vendorsData',
        'pending',
      ]),
      specialFeatureConsentData: store.gdprV2ReportState.getIn([
        'specialFeatureData',
        'pending',
      ]),
      messageConsentData: store.gdprV2ReportState.getIn([
        'messageData',
        'pending',
      ]),
      purposeConsentData: store.gdprV2ReportState.getIn([
        'purposeData',
        'pending',
      ]),
      changeLogData: store.consentPerformanceReportState.getIn([
        'changeLogData',
        'pending',
      ]),
    }),
  };
};

export default connect(mapStateToProps, {
  getAllSites,
  getGdprV2ReportPropertyData,
  getGdprV2ReportVendorData,
  getGdprV2ReportSpecialFeaturesData,
  getGdprV2ReportMessageData,
  getGdprV2ReportPurposeData,
  getConsentReportChangeLogData,
  getSiteGroups,
})(ConsentPerformanceReportV2);