import React, { Component } from 'react';
import { Table as AntdTable } from 'antd';

class Table extends Component {
  render() {
    const props = {...this.props};
    props.className = 'spsg-table ' + (props.className || '');

    return (
      <div className="spsg-table-wrapper">
        <AntdTable {...props} />
      </div>
    );
  }
}

export default React.memo(Table);