import React, { useEffect, useState } from "react";
import _ from "lodash";
import RegionSelectorTree from "./RegionSelectorTree";
import SelectedRegionsView from "./SelectedRegionsView";

const RegionSelector = (props) => {
  const [ selectedCodes, setSelectedCodes ] = useState([]);

  useEffect(()=>{
    if(props.setSelectedCodes){
      props.setSelectedCodes(selectedCodes);
    }
  },[selectedCodes])

  return (
    <>
      <div className={`regions-section`}>
        <div className="regions-selection">
          <RegionSelectorTree
             isIncludeExcludeStructure={props.isIncludeExcludeStructure}
             setGeoScope={props.setGeoScope}
             appliesGeos={props.appliesGeos}
             exclusionGeos={props.exclusionGeos}
             updatedCodes={props.updatedCodes}
             codes={props.codes}
             selectedCodes={selectedCodes}
             setSelectedCodes={setSelectedCodes}
             addIconsToTheseCodes={props.addIconsToTheseCodes} // to add icons to option labels
             entity="regions"
          />
        </div>
        <SelectedRegionsView
          selectedCodes={selectedCodes}
          setSelectedCodes={setSelectedCodes}
          setSelectedTerritoriesData={props.setSelectedTerritoriesData}
          addIconsToTheseCodes={props.addIconsToTheseCodes} // to add icons to option labels
          selectedRegionsLegend={props.selectedRegionsLegend}
        />
      </div>
    </>
  );
}
export default React.memo(RegionSelector, (prevProps, nextProps) => _.isEqual(prevProps.codes, nextProps.codes))
