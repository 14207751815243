import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Checkbox } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { Alert } from '../../../../../styleguide';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { PurposeV2 as Purpose, VendorList, IabSpecialFeature } from '../../../../../records/vendor_list_records';

export default class IabSpecialFeaturesModal extends React.Component {
  static propTypes = {
    iabSpecialFeatures: ImmutablePropTypes.listOf(PropTypes.instanceOf(IabSpecialFeature)).isRequired,
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    closeModal: PropTypes.func.isRequired,
    handleApply: PropTypes.func.isRequired,
  }

  state = {
    vl: this.props.vendorList,
    isStackOneAdded: this.props.vendorList.categories
      .filter((c) => c.type === "IAB_STACK")
      .map((c) => {
        const stackIabId = this.props.iabStacks.find(
          (s) => s.iabStackRef == c.iabStackRef
        ).iabId;
        return c.iabId || stackIabId;
      })
      .includes(1),
  };

  componentDidMount() {
    if(this.state.isStackOneAdded){
      this.setState({ vl: this.state.vl.set('iabSpecialFeatures', this.props.iabSpecialFeatures.map(s => s.id)) });
    }
  }

 toggleFeature = (checked, featureId) => {
    const features = this.state.vl.iabSpecialFeatures;
    let updatedFeatures;
    if (checked) {
      updatedFeatures = features.push(featureId);
    } else {
      if(this.state.isStackOneAdded) return;  // if stack 1 is selected, iab special features are mandatory 
      updatedFeatures = features.filterNot(id => id === featureId);
    }
    const updatedVl = this.state.vl.set('iabSpecialFeatures', updatedFeatures);
    this.setState({ vl: updatedVl });
  }

  handleOnOk = () => {
    this.props.handleApply(this.state.vl);
    this.props.closeModal();
  }

  render() {
    return (
      <Modal
        className='add-custom-purpose'
        destroyOnClose
        visible={ this.props.visible }
        closable={ false }
        okText='Apply Changes to Iab Special Features'
        onOk={ this.handleOnOk }
        onCancel={ this.props.closeModal }
      >
        {
          this.props.iabSpecialFeatures.map(f => (
            <div className='purpose-container flex-row'>
              <Checkbox
                onChange={ ({target : { checked}}) => this.toggleFeature(checked, f.id) }
                checked={ this.state.vl.iabSpecialFeatures.includes(f.id) }
              />
              <div className='purpose'>
                <p className='title'>{ f.name }</p>
                <p>{ f.description} </p>
              </div>
            </div>
          ))
        }
        {this.state.isStackOneAdded ? (<Alert showIcon={true} type="warning" message="Stack one has been chosen and has special features on by default. In order to remove special features, please pick a different stack." icon={<WarningFilled />}/>): null}
      </Modal>
    );
  }
}
