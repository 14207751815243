import 'whatwg-fetch';
import { checkStatus } from './helper.js';

export function getUnresolvedIncidentCount() {
    return fetch('https://api.statuspage.io/v1/pages/lv1g23zm5qjc/incidents/unresolved', {
        headers: {
          Authorization: 'OAuth c4d366e1-2ab1-412a-9349-6561acf2cbd4'
        },
    }).then(checkStatus)
        .then(resp => resp.json())
}
