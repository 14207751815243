import PropTypes from 'prop-types';
import React from 'react';

export default class PropertyFormRow extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    component: PropTypes.element.isRequired,
    extraComponent: PropTypes.element,
  };
  render() {
    return (
      <div className="property-row">
        <span>{this.props.label}</span>
        {this.props.component}
        {this.props.extraComponent || null}
      </div>
    );
  }
}
