import React from 'react';
import { Button} from '../../../../../styleguide';
import { WarningFilled } from '@ant-design/icons';

export const ConfirmAddVendorsFooter = ({handleConfirmAddVendors}) => {
  return (
    <div className="confirmation-footer">
      <div>
        <WarningFilled />
        Do you want to move checked items to <b>&nbsp;Selected Vendors?</b>
        <hr/>
      </div>
      <div className='button-group'>
        <div>
          <Button onClick={() => handleConfirmAddVendors('cancel')}  className="cancel" type="secondary">Cancel</Button>
        </div>
        <div>
          <Button onClick={() => handleConfirmAddVendors('no')} type="secondary"> No</Button>
          <Button onClick={() => handleConfirmAddVendors('yes')} type="primary">Yes, Add to Selected Vendors</Button>
        </div>
      </div>
    </div>
  );
};
