import React from 'react';

import { Select } from 'antd';
const { Option } = Select;

const CardDetailsPreview = ({
  updateSetting,
  settingKey,
  value,
  pmData,
  subviewIndex,
}) => {
  const updatedValue = pmData[subviewIndex === 'vendor' ? 'vendors' : 'categories'][value] ? value : 0;
  return (
    <div>
      <div className="four-options">
        <h4>Select {subviewIndex === 'category' ? 'Category' : 'Vendor'}</h4>
        <div className="option-inputs">
          <Select
            value={updatedValue}
            style={{ width: '100%' }}
            onChange={(value) => updateSetting(settingKey, value)}
          >
            {(subviewIndex === 'vendor')
              ? (
                pmData.vendors.map(({ name }, i) => (
                  <Option value={i}>{name}</Option>
                ))
              )
              : (
                pmData.categories.map(({ name }, i) => (
                  <Option value={i}>{name}</Option>
                ))
              )}
          </Select>
        </div>
      </div>
    </div>
  )
};

export default CardDetailsPreview;