import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { regionsMap } from '../../../../../constants';
import { Chip, TableFilterDropdown } from '../../../../../styleguide';

const ruleStatusToShow = ['Alpha', 'Beta+', 'Beta', 'GA'];

const scanDateFilterOptions = [
  {
    text: "Last Week",
    value: moment().subtract(1, 'weeks').format()
  },
  {
    text: "Last 2 Weeks",
    value: moment().subtract(2, 'weeks').format()
  },
  {
    text: "Last Month",
    value: moment().subtract(1, 'months').format()
  },
  {
    text: "Last Quarter",
    value: moment().subtract(1, 'quarters').format()
  },
  {
    text: "Last 6 Months",
    value: moment().subtract(6, 'months').format()
  },
  {
    text: "Last Year",
    value: moment().subtract(1, 'years').format()
  },
  {
    text: "Older",
    value: "older"
  },
]

const isValidRender = (score) => {
  let icon = <img src={require('../../../../../assets/images/privacy-lens/hyphen-black.svg')}/>;
  if (score === 1) {
    icon = <img src={require('../../../../../assets/images/privacy-lens/green-check.svg')}/> ;
  } else if (score === 0) {
    icon = <img src={require('../../../../../assets/images/privacy-lens/red-clear-X.svg')}/> ;
  } else if (score === -1) {
    icon = <span className='rules-icon not-applicable'>NA</span>;
  }
  return icon;
}

const getRules = ( rules ) => 
   rules.map(r => {
    const name = (
      <>
        <div>
          {ruleStatusToShow.includes(r.rule.productStatus) ? (
            <div><Tag color="volcano">{r.rule.productStatus}</Tag></div>
          ) : null}
          {r.rule.name}
        </div>
        <div className='rule-weight'>
          <Chip>
            Weight: {r.weight === 0 ? r.rule.systemWeight : r.weight}
          </Chip>
        </div>
      </>
    );
    return {
      title: name,
      dataIndex: r.rule.ruleId,
      align: 'center',
      width: '80px',
      filters: [
        {
          icon: isValidRender(1),
          text: "Pass",
          value: 1
        },
        {
          icon: isValidRender(0),
          text: "Fail",
          value: 0
        },
        {
          icon: isValidRender(undefined),
          text:  "Undetermined",
          value: undefined
        },
        {
          icon: isValidRender(-1),
          text: "Not Applicable",
          value: -1
        }
      ],
      onFilter: (value, record) => record.rules[r.rule.ruleId] === value,
      filterDropdown :(props)=><TableFilterDropdown {...props} selectAllOption dropdownClassName="scoring-results-rules" multiple />, 
      render: (col, data) => isValidRender(data.rules[r.rule.ruleId])
    }
  })

export const getColumns = (rules) => {
  const columns = [
    {
      title: 'Property',
      dataIndex: 'url',
      width: '180px',
      fixed: 'left',
      sorter: (a, b) => a.url.localeCompare(b.url),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      width: '60px',
      fixed: 'left',
      sorter: (a, b) => regionsMap[a.region].localeCompare(regionsMap[b.region]),
      filters: Object.entries(regionsMap).map((region)=>({text: region[1], value: region[0]})),
      filterSearch: true,
      onFilter: (value, record) => record.region === value,
      filterDropdown :(props)=><TableFilterDropdown {...props} showSearch selectAllOption multiple/>, 
      render: region => regionsMap[region] || 'N/A'
    },
    {
      title: 'Rules Passed',
      dataIndex: 'rulesPassed',
      width: '60px',
      fixed: 'left',
      sorter : (a,b) => ((a.rulesPassed || 0) - (b.rulesPassed || 0)),
      render: (rulesPassed) => `${rulesPassed} / ${rules.length}`
    },
    {
      title: 'Date Scanned',
      dataIndex: 'dateScanned',
      width: '130px',
      fixed: 'left',
      filters: scanDateFilterOptions,
      onFilter: (value, record) => {
        if(value === "older") 
          return moment(record.dateScanned).isBefore(moment().subtract(1, 'years'))
        else
          return moment(record.dateScanned).isAfter(moment(value))
      },
      filterMultiple: false,
      sorter : (a, b) => ((moment(a.dateScanned).valueOf() || 0) - (moment(b.dateScanned).valueOf() || 0)),
      filterDropdown :(props)=><TableFilterDropdown {...props} selectAllOption/>, 
      render: dateScanned => dateScanned ? moment(dateScanned).format("LLL") : 'Not scanned'
    },
    {
      title: 'Score',
      dataIndex: 'score',
      width: '60px',
      fixed: 'left',
      sorter : (a,b) => ((a.score || 0) - (b.score || 0)),
    }
  ];

  const mustHaveRules = rules.filter(r => !r.niceToHave);
  const niceToHaveRules = rules.filter(r => r.niceToHave === true);

  if (mustHaveRules.length > 0) {
    columns.push({
      title: 'Must Have Rules',
      className: 'must-have',
      children: getRules(mustHaveRules)
    });
  }

  if (niceToHaveRules.length > 0) {
    columns.push({
      title: 'Nice to Have Rules',
      className: 'nice-to-have',
      children: getRules(niceToHaveRules)
    });
  }

  return columns;
};
