import React from 'react';
import BooleanStep from './BooleanStep';

export default class CustomCompGate extends React.Component {
  render() {
    return (
      <BooleanStep
        {...this.props}
        labelText={ 'Custom Comp Gate' }
      />
    );
  }
}
