import React, { Component } from 'react';
import { Radio, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignRight, faAlignLeft, faAlignCenter } from '@fortawesome/free-solid-svg-icons';


class FlexJustify extends Component {
  updateAlign = (e) => {
    this.props.updateSetting(this.props.settingKey, e.target.value);
  }

  applySpreadAlign = ({ target: { value, checked }}) => {
    this.props.updateSetting(this.props.settingKey, checked ? value : 'flex-start');
  }

  render() {
    return (
      <div className="set-flexjustify">
        <h4>{this.props.settingsTitle}</h4>
        <div>
          <Radio.Group value={this.props.value} onChange={this.updateAlign}>
            <Radio.Button value="flex-start">
              <FontAwesomeIcon icon={faAlignLeft} />
            </Radio.Button>
            <Radio.Button value="center">
              <FontAwesomeIcon icon={faAlignCenter} />
            </Radio.Button>
            <Radio.Button value="flex-end">
              <FontAwesomeIcon icon={faAlignRight} />
            </Radio.Button>
          </Radio.Group>
          <div>
          <Checkbox 
            style={{ marginLeft: 5 }}
            checked={this.props.value === 'space-between'}
            value="space-between" 
            onChange={this.applySpreadAlign}
          >
            Spread
          </Checkbox>
          </div>
        </div>
      </div>
    );
  }
}

export default FlexJustify;
