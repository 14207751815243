import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Select, Button, Tooltip } from 'antd';

import MessageEditWhitelistOption from './MessageEditWhitelistOption.js.jsx';
import MessageEditRecoveryOption from './MessageEditRecoveryOption.js.jsx';
import MessageEditAltAdVideoOption from './MessageEditAltAdVideoOption.js.jsx';
import MessageEditPubCustomOption from './MessageEditPubCustomOption.js.jsx';
import MessageEditRedirectOption from './MessageEditRedirectOption.js.jsx';
import MessageEditContinueWithAdblockerOption from './MessageEditContinueWithAdblockerOption.js.jsx';
import MessageEditAltPayOption from './MessageEditAltPayOption.jsx';
import MessageEditExecJSOption from './MessageEditExecJSOption.jsx';
import MessageEditSambaOption from './MessageEditSambaOption.jsx';
import MessageEditWelectOption from './MessageEditWelectOption.jsx';
import MessageEditConsentOption from './MessageEditConsentOption.jsx';
import MessageEditPrivacyManagerOption from './MessageEditPrivacyManagerOption.jsx';
import CustomIcon from '../../../common/CustomIcon.js.jsx';

import {
  AcceptAll,
  AltAdVideo,
  AltPay,
  ChoiceConfigRecord,
  ContinueWithAdblocker,
  ExecJS,
  ListOption,
  PrivacyManager,
  PubCustom,
  Recovery,
  Redirect,
  RejectAll,
  Samba,
  Whitelist,
  Welect,
} from '../../../../records/choice_config_records.js';
import { CHOICE_TYPES, CHOICE_NAMES_READABLE } from '../../../../constants.js';

const { Option, OptGroup } = Select;

export default class MessageEditChoiceSelectItem extends React.Component {
  static propTypes = {
    choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
    choiceOptionType: PropTypes.number,
    editing: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    listIndex: PropTypes.number.isRequired,
    selectOptions: PropTypes.array.isRequired,
    updateChoiceConfig: PropTypes.func.isRequired,
    navUp: PropTypes.func,
    navDown: PropTypes.func,
  }

  static defaultProps = {
    choiceOptionType: null,
  }

  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    const optionChoices = _.invert(CHOICE_TYPES);
    const oldChoiceConfig = this.props.choiceConfig;
    let newOptions = oldChoiceConfig.options;

    if (this.props.choiceOptionType) {
      newOptions = oldChoiceConfig.options.setIn([optionChoices[this.props.choiceOptionType], 'enabled'], false);
    }
    const newOptionList = oldChoiceConfig.option_list.delete(this.props.listIndex);

    const choiceConfig = oldChoiceConfig.set('option_list', newOptionList).set('options', newOptions);
    this.props.updateChoiceConfig(choiceConfig);
  }

  handleSelect(choiceOption) {
    choiceOption = parseInt(choiceOption);
    let optionData, optionListItem;
    optionListItem = new ListOption();
    optionListItem = optionListItem.set('choice_option_type', choiceOption);
    switch(choiceOption) {
      case CHOICE_TYPES.whitelist:
        optionData = new Whitelist();
        break;
      case CHOICE_TYPES.recovery:
        optionData = new Recovery();
        break;
      case CHOICE_TYPES.altAdVideo:
        optionData = new AltAdVideo();
        break;
      case CHOICE_TYPES.pubCustom:
        optionData = new PubCustom();
        break;
      case CHOICE_TYPES.redirect:
        optionData = new Redirect();
        break;
      case CHOICE_TYPES.continueWithAdblocker:
        optionData = new ContinueWithAdblocker();
        break;
      case CHOICE_TYPES.altPay:
        optionData = new AltPay();
        break;
      case CHOICE_TYPES.execJS:
        optionData = new ExecJS();
        break;
      case CHOICE_TYPES.welect:
        optionData = new Welect();
        break;
      case CHOICE_TYPES.acceptAll:
        optionData = new AcceptAll();
        break;
      case CHOICE_TYPES.privacyManager:
        optionData = new PrivacyManager();
        break;
      case CHOICE_TYPES.rejectAll:
        optionData = new RejectAll();
        break;
      case CHOICE_TYPES.samba:
        optionData = new Samba();
        break;
      default:
    }
    optionData = optionData.set('enabled', true);
    optionListItem = optionListItem.set('option_data', optionData);

    const oldChoiceConfig = this.props.choiceConfig;
    const choiceConfig = oldChoiceConfig.setIn(['option_list', this.props.listIndex], optionListItem);
    this.props.updateChoiceConfig(choiceConfig);
  }

  render() {
    const editing = this.props.editing;
    const { selectOptions } = this.props;
    const optionGroups = _.groupBy(selectOptions, 'group');
    const options = _.map(optionGroups, (group, label) => {
      return (
        <OptGroup key={ label } label={ label }>
          { group.map((option) => <Option key={ option.value }>{ option.label }</Option>) }
        </OptGroup>
      )
    });
    const choiceMenu = (
      <Select
        disabled={ !this.props.editing }
        placeholder='Select a choice option'
        onChange={ this.handleSelect }
        value={ CHOICE_NAMES_READABLE[this.props.choiceOptionType] }
      >
        { options }
      </Select>
    );

    let choiceOption;
    switch(this.props.choiceOptionType) {
      case CHOICE_TYPES.whitelist:
        choiceOption = (
          <MessageEditWhitelistOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.recovery:
        choiceOption = (
          <MessageEditRecoveryOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.altAdVideo:
        choiceOption = (
          <MessageEditAltAdVideoOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.pubCustom:
        choiceOption = (
          <MessageEditPubCustomOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.redirect:
        choiceOption = (
          <MessageEditRedirectOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.continueWithAdblocker:
        choiceOption = (
          <MessageEditContinueWithAdblockerOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.altPay:
        choiceOption = (
          <MessageEditAltPayOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.execJS:
        choiceOption = (
          <MessageEditExecJSOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.welect:
        choiceOption = (
          <MessageEditWelectOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.acceptAll:
        choiceOption = (
          <MessageEditConsentOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.privacyManager:
        choiceOption = (
          <MessageEditPrivacyManagerOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
            variables={ this.props.variables }
            updateParentState={ this.props.updateParentState }
            siteGroup={ this.props.siteGroup }
            accountId={ this.props.accountId }
          />
        );
        break;
      case CHOICE_TYPES.rejectAll:
        choiceOption = (
          <MessageEditConsentOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      case CHOICE_TYPES.samba:
        choiceOption = (
          <MessageEditSambaOption
            choiceConfig={ this.props.choiceConfig }
            editing={ editing }
            form={ this.props.form }
            listIndex={ this.props.listIndex }
            onChoiceConfigChange={ this.props.updateChoiceConfig }
          />
        );
        break;
      default:
    }

    let positionArrowContainer;
    if (this.props.choiceOptionType && this.props.editing) {
      positionArrowContainer = (
        <div className='position-arrow-container'>
          {this.props.navUp ? (
            <Tooltip title="move up">
              <Button
                ghost={ true }
                icon={<ArrowUpOutlined />}
                onClick={ () => { this.props.navUp(this.props.listIndex);} }
              />
            </Tooltip>
          ) : null}
          {
            this.props.navDown ? (
              <Tooltip title="move down">
                <Button
                  ghost={ true }
                  icon={<ArrowDownOutlined />}
                  onClick={ () => {this.props.navDown(this.props.listIndex);} }
                />
              </Tooltip>
            ) : null
          }
        </div>
      );
    }

    const deleteIcon = this.props.editing ? (
      <CustomIcon
        type={ CustomIcon.types.DELETE }
        onClick={ this.handleDelete }
        tooltip="delete"
      />
    ) : null;
    return (
      <div className='choice-option-item'>
        <h4 className='choice-option-generic-title'>Choice Option</h4>
        { deleteIcon }
        { positionArrowContainer }
        { choiceMenu }
        { choiceOption }
      </div>
    );
  }
}
