import { checkStatus, CSRF_TOKEN, toFormData } from './helper.js';
import { CustomReport } from '../records/reports_records.js';
import { List } from 'immutable';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export function getListOfReportsAjax() {
  return fetch(`${permissionsSvsBase}/jobs/get_job_list`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => List(resp.data).map((report) => new CustomReport(report)));
}

export function emailCustomReportAjax(url, params) {
  return fetch(`${permissionsSvsBase}/jobs${url}`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(params)
  }).then(checkStatus)
    .then(resp => resp.json())
    .then((resp) => {
      console.log(resp);
      if (resp.code !== 200) {
        throw new Error(resp.msg);
      } else {
        return true;
      }
    });
}

export const getScheduledJobs = async (accountId, jobId) => {
  let url = '/jobs/get_scheduled_jobs?account_id=' + accountId;
  if (jobId) url += '&scheduled_job_id=' + jobId;
  const resp = await fetch(`${permissionsSvsBase}${url}`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
  .then(resp => {
    let jobs = resp?.data?.scheduled_jobs || [];
    jobs = jobs.map(j => {
      let conf = null;
      try {
        conf = JSON.parse(j.conf);
      } catch (err) {
        throw new Error('Error parsing response');
      }
      if (conf) {
        delete j.conf;
        j = {...j, ...conf};
      }
      return j;
    });
    return jobId && jobs?.length > 0 ? jobs[0] : jobs;
  });
  return resp;
}

export const getJobDefinitions = async (accountId) => {
  let url = '/jobs/get_scheduled_job_list?account_id=' + accountId;
  const resp = await fetch(`${permissionsSvsBase}${url}`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
  .then(resp => {
    const def = resp?.data || [];
    const defMap = {};
    def.forEach(d => {
      defMap[d.job_def_id] = {...d, filter: true};
    })
    return defMap;
  });
  return resp;
};

export const deleteScheduledJob = async (accountId, jobId) => {
  let url = '/jobs/delete_scheduled_job?scheduled_job_id='+jobId+'&account_id=' + accountId;
  try {
    const result = await fetch(`${permissionsSvsBase}${url}`, {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => response.json());
    if (result?.status === 'error') throw new Error('Error deleting job');
    return true;
  } catch (err) {
    return false;
  }
};

export const getJobDoneOnceReport = async (accountId) => {
  let url = '/jobs/get_one_time_runs_per_month?account_id=' + accountId;
  const resp = await fetch(`${permissionsSvsBase}${url}`, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json())
  .then(resp => {
    const count = resp?.data || [];
    return count;
  });
  return resp
};


export const createScheduledJob = async (obj) => {
  let url = '/jobs/scheduled_job_create';
  try {
    const result = await fetch(`${permissionsSvsBase}${url}`, {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    }).then(response => response.json());
    if (result?.status === 'error') throw new Error(result?.msg);
    return true;
  } catch (err) {
    return { error: true, msg: err.message};
  }
}

export const updateScheduledJob = async (obj) => {
  let url = '/jobs/scheduled_job_update';
  try {
    const result = await fetch(`${permissionsSvsBase}${url}`, {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    }).then(response => response.json());
    if (result?.status === 'error') throw new Error(result?.msg);
    return true;
  } catch (err) {
    return { error: true, msg: err.message};
  }
}
