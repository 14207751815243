import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { Modal, Button, Alert } from 'antd';

export default class DeactivateModal extends React.Component {
  static propTypes = {
    moveToDrafts: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  handleApply = () => {
    window.open("https://docs.sourcepoint.com/hc/en-us/requests/new");
    this.props.closeModal();
  }

  goToPropertySets = () => {
    browserHistory.push(`/privacy_lens/domain_sets?standardId=${this.props.standard.id}`);
  }

  render() {
    return (
      <Modal
        onOk={this.handleApply}
        okText='SUPPORT REQUEST'
        onCancel={this.props.closeModal}
        className='purple new-design deactvate-ps'
        visible
      >
        <p className="title">{`${this.props.propertySet.name} - Activation`}</p>

        <div className='modal-container'>
          <p>
            {`You cannot activate ${this.props.propertySet.name} !`}
          </p>
          <p>This action cannot be completed as it will cross your PL usage for the current period.</p>
          <p>{`You can remove ${this.props.numPropertiesNeeded} properties from the property set to activate it`}.</p>
          <Button onClick={() => this.props.openEdit(this.props.propertySet)}>
            Edit Property Set
          </Button>
          <p>If you need to increase your PL usage, get in touch with Account Manager.</p>
        </div>
      </Modal>
    );
  }
}
