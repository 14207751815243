import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List } from 'immutable';
import classNames from 'classnames';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Input, Button, Tooltip, Alert } from 'antd';
import { SiteGroup, Site } from '../../../../records/site_records';
import { MessageVariables as MessageVariablesRecord, SiteVariables, IdNameValue } from '../../../../records/message_records';
import { getParameterByName } from '../../../utils';
import CustomButton from '../../../common/CustomButton.js.jsx';

export default class MessageVariables extends React.Component {
  static propTypes = {
    variables: PropTypes.instanceOf(MessageVariablesRecord),
    updateParentState: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    siteGroup: PropTypes.instanceOf(SiteGroup),
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    getSiteGroup: PropTypes.func.isRequired,
    toggleShowVariablesModal: PropTypes.func.isRequired,
    variablesModalVisible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    variables: new MessageVariablesRecord({}),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.variables !== nextProps.variables && !prevState.edited) {
      return { variables: nextProps.variables };
    } else {
      return null;
    }
  }

  state = {
    newVarName: '$$var_name$$',
    newVarValue: '',
    selectedSite: 'master',
    variables: this.props.variables,
    hoveringVariableIdx: -1,
    showClosingWarning: false,
    newVarError: null,
  }

  componentDidMount() {
    this.props.getSiteGroup(getParameterByName('site_group_id', window.location));
  }

  addVariable = () => {
    let newVarError;
    if (this.state.newVarName === '$$$$') {
      newVarError = 'variable name cannot be blank';
    } else if (this.state.newVarName.includes(' ')) {
      newVarError = 'variable name cannot include spaces';
    } else if (this.state.variables.defaultVariables.map(v => v.get('name')).includes(this.state.newVarName)) {
      newVarError = `variable with name ${ this.state.newVarName } already exists`;
    } else if (!this.state.newVarValue.trim().length) {
      newVarError = 'variable value cannot be blank';
    }
    if (newVarError) {
      this.setState({ newVarError });
      return;
    }
    let variables = this.state.variables || new MessageVariablesRecord({});
    const variable = new IdNameValue({ name: this.state.newVarName, value: this.state.newVarValue }).toMap().delete('id');
    variables = variables.set('defaultVariables', variables.get('defaultVariables').unshift(variable));

    this.setState({
      variables,
      newVarName: '$$var_name$$',
      newVarValue: '',
      newVarError: null,
      edited: true
    });
  }

  handleSave = () => {
    this.props.updateParentState({
      variables: this.state.variables,
    });
    this.props.toggleShowVariablesModal();
  }

  handleCancel = () => {
    if (this.state.variables !== this.props.variables) {
      this.setState({ showClosingWarning: true });
    } else {
      this.closeModalWithoutSaving();
    }
  }

  closeModalWithoutSaving = () => {
    this.props.toggleShowVariablesModal();
    this.setState({
      variables: this.props.variables,
      showClosingWarning: false,
    });
  }

  getSiteDomain = (id) => {
    return this.props.sites.find(s => s.id === id).domain;
  }

  replaceVariable = (variables, name, updater) => {
    return variables.map(v => {
      if (v.get('name') === name) {
        return v.merge(updater);
      } else {
        return v;
      }
    });
  }

  handleDeleteVariable = (e, variable) => {
    e.stopPropagation();
    const variables = this.state.variables;
    this.setState({
      variables: variables.set('defaultVariables', variables.defaultVariables.filterNot(v => v.get('name') === variable.get('name'))),
      edited: true
    });
  }

  updateVariable = (variable, updater) => {
    const variables = this.state.variables;
    if (this.state.selectedSite === 'master') {
      this.setState({
        variables: variables.set('defaultVariables', this.replaceVariable(variables.defaultVariables, variable.get('name'), updater)),
        edited: true
      });
    } else {
      const newVariable = new IdNameValue({ name: variable.get('name') }).merge(updater);
      let sitesVariables;
      const selectedSiteVariables = variables.get('sitesVariables').find(sv => sv.siteId === this.state.selectedSite.id);
      if (selectedSiteVariables) {
        if (selectedSiteVariables.get('variables').some(v => v.get('name') === variable.get('name'))) {
          sitesVariables = variables.get('sitesVariables').map(sv => {
            if (sv.siteId === this.state.selectedSite.id) {
              const variables = this.replaceVariable(sv.variables, variable.get('name'), updater);
              return sv.set('variables', variables);
            } else {
              return sv;
            }
          });
        } else {
          sitesVariables = variables.get('sitesVariables').map(sv => {
            if (sv.siteId === this.state.selectedSite.id) {
              return sv.set('variables', sv.get('variables').push(newVariable));
            } else {
              return sv;
            }
          });
        }

      } else {
        sitesVariables = variables.get('sitesVariables').push(new SiteVariables({ siteId: this.state.selectedSite.id, variables: List([newVariable])}));
      }

      this.setState({
        variables: variables.set('sitesVariables', sitesVariables),
        edited: true
      });
    }
  }

  updateNewVarName = (e) => {
    const val = e.target.value;
    if (val.slice(0,2) !== '$$' || val.slice(-2) !== '$$' || val.length < 4) {
      return;
    } else {
      this.setState({ newVarName: val });
    }
  }

  handleNameChange = (v, e) => {
    const val = e.target.value;
    if (val.slice(0,2) !== '$$' || val.slice(-2) !== '$$' || val.length < 4) {
      return;
    } else {
      this.updateVariable(v, Map({ name: val }));
    }
  }

  render() {
    if (!this.props.sites.size || !this.props.siteGroup) return null;

    let addEditOrView;
    if (this.props.editing) {
      addEditOrView = (this.state.variables && this.state.variables.defaultVariables.filterNot(v => v.get('name') === '$$privacyManagerIdHiddenVarriable$$').size) ? 'Edit' : 'Add';
    } else {
      addEditOrView = 'View';
    }

    let siteTitle;
    let description;
    let variables = this.state.variables && this.state.variables.defaultVariables;
    if (this.state.selectedSite === 'master') {
      siteTitle = 'Master Variable List';
      description = 'When a variable is created, all sites will default to the Master Variable List. For more control, change values in the individual site tab.';
      description = <p className='description'>{ description }</p>;
    } else {
      siteTitle = this.state.selectedSite && this.state.selectedSite.domain;
      let selectedSiteVariables = (this.state.variables && this.state.variables.sitesVariables.find(sv => sv.siteId === this.state.selectedSite.id));
      selectedSiteVariables = selectedSiteVariables && selectedSiteVariables.variables || List([]);
      variables = variables.map(v => {
        const siteVariable = selectedSiteVariables.find(sv => sv.get('name') === v.get('name'));
        if (siteVariable) {
          return v.set('value', siteVariable.get('value'));
        } else {
          return v;
        }
      });
    }

    const tooltipText = (
      <p>all variables have to start and end with <span style={{ fontWeight: 'strong' }}>$$</span> when using in the message</p>
    );

    let unsavedChangesModal;

    if (this.state.showClosingWarning) {

      unsavedChangesModal = (
        <div className='unsaved-changes'>
          <h2>Are you sure you want to close without saving?</h2>
          <div className='buttons'>
            <CustomButton
              className='close-without-saving button'
              size={ CustomButton.sizes.MEDIUM }
              onClick={ this.closeModalWithoutSaving }
            >
              Close without saving
            </CustomButton>
            <CustomButton
              className='css-back-to-editor button'
              size={ CustomButton.sizes.MEDIUM }
              onClick={ () => this.setState({ showClosingWarning: false }) }
            >
              Go back to variables editor
            </CustomButton>
          </div>
        </div>
      );
    }

    let alert;
    if (this.state.newVarError) {
      alert = <Alert message={ this.state.newVarError } type="error" />;
    }

    const variablesModal = (
      <Modal
        visible={ this.props.variablesModalVisible }
        onOk={ this.handleSave }
        onCancel={ this.handleCancel }
        okText={ 'Save Changes' }
        destroyOnClose
        className='shared-modal message-variables'
        closable={ false }
        maskClosable={ false }
      >
        <div>
          { unsavedChangesModal }
          <label className='title'><p>Manage Variables</p></label>
          <div className='add-variable-container'>
            <label><div>Variable Name<Tooltip placement="right" title={ tooltipText }><InfoCircleOutlined /></Tooltip></div>
            <Input value={ this.state.newVarName } onChange={ this.updateNewVarName } />
            </label>
            <label>Default Value
            <Input value={ this.state.newVarValue } onChange={ (e) => this.setState({ newVarValue: e.target.value })} />
            </label>
            <Button
              type="primary"
              onClick={ this.addVariable }
            >
              + Add Variable
            </Button>
            { alert }
          </div>

          <div className='main-container'>
            <div className='site-list'>
              <ul>
                <li
                  onClick={ () => this.setState({ selectedSite: 'master'}) }
                  className={classNames({'highlighted': this.state.selectedSite === 'master'})}
                >
                  Master Variable List
                </li>
                {
                  this.props.siteGroup.siteIds.map(id => {
                    const site = this.props.sites.find(s => s.id === id);
                    return <li
                            className={classNames({'highlighted': site.id === this.state.selectedSite.id})}
                            onClick={ () => this.setState({ selectedSite: site }) }
                            key={ id }
                          >
                            { site.domain }
                          </li>
                  })
                }
              </ul>
            </div>
            <div className='variable-list-container'>
              <p className='title'>{ siteTitle }</p>
              { description }
              {
                variables.filterNot(v => v.get('name') === '$$privacyManagerIdHiddenVarriable$$').map((v,i) => {
                  let deleteButton;
                  if (this.state.hoveringVariableIdx === i && !this.props.readOnly && this.state.selectedSite === 'master') {
                    deleteButton = <Button shape="circle" icon={<CloseOutlined />} onClick={ (e) => this.handleDeleteVariable(e, v) } />;
                  }

                  return (
                    <label
                      onMouseEnter={ () => this.setState({ hoveringVariableIdx: i })}
                      onMouseLeave={ () => this.setState({ hoveringVariableIdx: -1 })}
                    >
                      <div className='name-container'>
                        <Input
                          value={ v.get('name') }
                          onChange={ (e) => this.handleNameChange(v, e) }
                          disabled={ this.state.selectedSite !== 'master'}
                        />
                        { deleteButton }
                      </div>
                      <Input value={ v.get('value') } onChange={ (e) => this.updateVariable(v, Map({ value: e.target.value }))} />
                    </label>
                  )
                })
              }
            </div>
          </div>
        </div>
      </Modal>
    )

    const variablesButton = (
      <CustomButton
        className='add-custom-css-button'
        size={ CustomButton.sizes.MEDIUM }
        onClick={ this.props.toggleShowVariablesModal }
      >
        { addEditOrView } Message Variables
      </CustomButton>
    );

    return (
      <div className='custom-css'>
        { variablesButton }
        { variablesModal }
      </div>
    );
  }
}
