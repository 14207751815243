import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Alert } from "antd";
import Logo from "../../assets/images/spt-logo.png";
import { PROXY_LOCATIONS } from "../../constants";
import DropdownSearch from "../common/DropdownSearch";
import { Input, Checkbox, Button } from "../../styleguide/";
import { freeScanDetails } from "../../actions/account_actions";
import { useSelector } from "react-redux";
import Loading from "../common/Loading.js.jsx";
import { useActions } from "../../hooks";

const SITE_URL_REGEX= /^(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:.\d{1,3}){3})(?!(?:169.254|192.168)(?:.\d{1,3}){2})(?!172.(?:1[6-9]|2\d|3[0-1])(?:.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}.?))(?::\d{2,5})?(?:[/?#]\S*)?$/

//validation rules generator
const generateValidationRules = (fieldName) => {
  switch (fieldName) {
    case "siteUrl":
      return [
        {
          required: true,
          message: "Please enter site url for scanning",
        },
        {
          pattern: SITE_URL_REGEX,
          message: "Please enter a valid Site Url.",
        },
        {
          max: 255,
          message: "Site Url should contain a maximum of 255 characters",
        },
      ];
    case "location":
      return [
        {
          required: true,
          message: "Please select atleast one location",
        },
      ];
    case "firstName":
      return [
        {
          required: true,
          message: "Please enter your First name",
        },
        {
          min: 1,
          max: 30,
          message: "First name should be in the range of 1 to 30 characters",
        },
      ];
    case "lastName":
      return [
        {
          required: true,
          message: "Please enter your last Name",
        },
        {
          min: 1,
          max: 30,
          message: "Last name should be in the range of 1 to 30 characters",
        },
      ];
    case "email":
      return [
        {
          required: true,
          message: "Please enter your email",
        },
        {
          type: "email",
          message: "Please enter a valid email address",
        },
        {
          min: 6,
          max: 50,
          message: "email should be in the range of 6 to 50 characters",
        },
      ];
  }
};

const FreeScanForm = (props) => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const freeScan = useSelector((state) => state.accountState);
  const freeScanDetailsValue = freeScan?.getIn(["freeScanDetails", "value"]);
  const freeScanDetailsPending = freeScan?.getIn([
    "freeScanDetails",
    "pending",
  ]);
  const freeScanDetailsError = freeScan?.getIn(["freeScanDetails", "error"]);

  const postFormDetails = useActions(freeScanDetails);

  const onFinish = (values) => {
    const siteUrl= `https://${values?.siteUrl}`;
    values.siteUrl = siteUrl;
    if(values.isTnC)
      postFormDetails(values);
    else 
      setErrorMessage("Please accept the Terms and Conditions");
  };

  useEffect(() => {
    try {
    if (freeScanDetailsValue) {
      if (freeScanDetailsValue?.status === "Success") {
        props.setIsSubmitted(true);
      } else if (freeScanDetailsValue?.status === "Failure") {
        let errorResult = freeScanDetailsValue?.result?.replace(/^"(.*)"$/, '$1');
        errorResult = errorResult?.substring(errorResult?.indexOf(":") + 1);
        setErrorMessage(
          errorResult ? errorResult : "Something went wrong"
        );
      } else {
        setErrorMessage("Something went wrong");
      }
      document.getElementById("free-scan-container").scrollTop = 0;
    }
  } catch {
    setErrorMessage("Something went wrong");
    document.getElementById("free-scan-container").scrollTop = 0;
  }
  }, [freeScanDetailsValue, freeScanDetailsError]);

  const onFormValuesChange = (changedValues, allValues) => {
    setDisableSubmitButton(!allValues.isTnC)
  }

  return (
    <>
      {freeScanDetailsPending ?
      <div className="disable-background">
        <Loading />
      </div>: ""}
      <div id="free-scan-container">
        <div id="free-scan-form-container">
          <div className="success-flex">
            <div className="spl-logo">
              <img src={Logo} alt="" />
            </div>{" "}
            {errorMessage ? (
              <div
                 style={{
                   marginBottom: 20,
                 }}
              >
                <div >
                  <Alert showIcon message={errorMessage} type="error"/>
                </div>
              </div>
            ) : null}
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ isTnC: false, isPromotionalEmails: false }}
            scrollToFirstError={true}
            onValuesChange={onFormValuesChange}
          >
            <div className="free-scan-info scan-label">
              Enter the <b>Site URL</b> you wish to scan.
            </div>
            <div className="free-scan-info">
              Site URL<span className="star">*</span>
            </div>
            <Form.Item
              validateFirst
              name="siteUrl"
              label=""
              rules={generateValidationRules("siteUrl")}
            >
              <Input
                className="free-scan-input"
                placeholder="domainName.com"
                addonBefore="https://"
              />
            </Form.Item>
            <div className="free-scan-info">
              Location<span className="star">*</span>
            </div>
            <Form.Item
              validateFirst
              name="location"
              rules={generateValidationRules("location")}
              className="free-scan-select"
            >
              <DropdownSearch
                options={PROXY_LOCATIONS}
                selectClassName="free-scan-select"
                setFieldValue={form.setFieldsValue}
                dropdownClassName="dropdownLocations"
                searchInputClassName="free-scan-input"
                selectPlaceholder="Select Location"
                searchInputPlaceholder="Search"
                dropDownMenuListHeight={200}
              />
            </Form.Item>
            <div className="free-scan-info scan-label">
              Where should we send the scan results?
            </div>
            <div className="free-scan-info">
              First Name<span className="star">*</span>
            </div>
            <Form.Item
              validateFirst
              name="firstName"
              rules={generateValidationRules("firstName")}
            >
              <Input className="free-scan-input" placeholder="John" />
            </Form.Item>
            <div className="free-scan-info">
              Surname<span className="star">*</span>
            </div>
            <Form.Item
              validateFirst
              name="lastName"
              rules={generateValidationRules("lastName")}
            >
              <Input className="free-scan-input" placeholder="Doe" />
            </Form.Item>
            <div className="free-scan-info">
              Email<span className="star">*</span>
            </div>
            <Form.Item
              validateFirst
              name="email"
              rules={generateValidationRules("email")}
              style={{ marginBottom: 0 }}
            >
              <Input className="free-scan-input" placeholder="user@xyz.com" />
            </Form.Item>
            <div className="free-scan-info right-align">
              <span className="star">*</span> Required
            </div>
            <Form.Item
              validateFirst
              name="isPromotionalEmails"
              valuePropName="checked"
              noStyle
            >
              <Checkbox className="free-scan-info scan-checkbox">
              I would like to receive emails from Sourcepoint about educational resources, products, and services.
              </Checkbox>
            </Form.Item>
            <Form.Item validateFirst name="isTnC" valuePropName="checked">
              <Checkbox className="free-scan-info scan-checkbox">
              I have read and agree to Sourcepoint’s{" "}
                <a href="https://www.sourcepoint.com/diagnose-terms-and-conditions/" target="_blank"> Terms and Conditions</a>.
                <span className="star">*</span>
              </Checkbox>
            </Form.Item>
            <Form.Item validateFirst>
              <Button
                className="free-scan-btn"
                type="primary"
                htmlType="submit"
                disabled={disableSubmitButton}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

FreeScanForm.propTypes = {
  setIsSubmitted: PropTypes.func.isRequired,
};

export default FreeScanForm;
