import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Message } from '../../../../../records/message_records';

const Option = Select.Option;

export default class extends React.Component {
    static propTypes = {
      messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
      value: PropTypes.number.isRequired,
      onChange: PropTypes.func.isRequired,
    };

    state = {
      value: this.props.value,
    };

    handleChange = (value) => {
      this.setState({ value: parseInt(value) });
      this.props.onChange(value);
    };

    render() {
      return (
        <Select value={ this.state.value } onChange={ this.handleChange }>
          <Option value="-1">[Unset]</Option>
          { this.props.messages.map(function (message) {
            return (
              <Option
                key={ message.get('id') }
                value={ message.get('id') }
              >
                { message.get('description') }
              </Option>
            );
          }.bind(this))}
        </Select>
      );
    }
}
