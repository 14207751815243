import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';

class PrivacyEditor extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline','strike','link','clean'],
    ],
  };

  formats = [
    'bold', 'italic', 'underline', 'strike', 'link',
  ];

  render() {
    return (
      <div>
        <ReactQuill
          theme="snow"
          value={this.props.value}
          onChange={this.props.onChange}
          modules={this.modules}
          formats={this.formats}
          readOnly={ this.props.readOnly }
        />
      </div>
    );
  }
}

export default PrivacyEditor;
