import { List, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';
import { DomainSetScore, DomainScore, StandardScore, SetScoreIncluded } from '../../records/domain_score_records';
import { GoldenSet } from '../../records/golden_set_records';
import { DomainAdmin } from '../../records/domain_set_records';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;
// const permissionsSvcBaseUrl = 'http://localhost:5000'

const getBaseUrl = () => {
  return `${permissionsSvcBaseUrl}/consent-quality`;
};

export function getSetScoresIncludedAjax(accountId, refresh) {
  const datePropertySetUpdated = localStorage.getItem('datePropertySetUpdated');
  let url = `/domain-set/list?accountId=${accountId}&includeScores=true`;
  if (!refresh && isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return List(resp.filter(s => s.domainSetStatus === 'Active' || s.domainSetStatus === 'DisabledPending').map(s => new SetScoreIncluded(s) ));
      });
  }
}

export function getDomainSetScoresAjax(accountId, refresh) {
  const datePropertySetUpdated = localStorage.getItem('datePropertySetUpdated');
  let url = `/score/domain-set-scores?accountId=${accountId}`;
  if (datePropertySetUpdated) url += `?datePropertySetUpdated=${datePropertySetUpdated}`;
  if (!refresh && isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return List(resp.map(s => new DomainSetScore(s) ));
      });
  }
}

export function getAdminDomainsAjax(accountId) {
  const datePropertySetUpdated = localStorage.getItem('datePropertySetUpdated');
  let url = `/score/domain-data-admin?accountId=${accountId}`;
  if (datePropertySetUpdated) url += `?datePropertySetUpdated=${datePropertySetUpdated}`;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return List(resp.map(d => new DomainAdmin(d) ));
      });
  }
}

export function changeScanPeriodAjax(accountId, period, domainIds) {
  const url = `/score/change-scan-period`;
  return fetch(getBaseUrl() + url, {
    method: 'PUT',
    body: JSON.stringify({ accountId, period, domainIds }),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return resp;
    })
}

export function rescanPropertiesAjax(accountId, domainIds) {
  const url = `/score/rescan`;
  return fetch(getBaseUrl() + url, {
    method: 'PUT',
    body: JSON.stringify({ accountId, domainIds }),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      console.log('resp: ', resp)
      return resp;
    })
}


export function getDomainScoresAjax(accountId, domainSetId) {
  const datePropertySetUpdated = localStorage.getItem('datePropertySetUpdated');
  let url = `/score/domain-scores/?domainSetId=${domainSetId}&withoutStandards=true`;
  if (datePropertySetUpdated) url += `?datePropertySetUpdated=${datePropertySetUpdated}`;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new DomainScore(resp);
      });
  }
}

export function changeDomainScoresAjax(domainSetId, domainIds, rules) {
  const url = `/score/change-scores?domainSetId=${domainSetId}`;
  const data = {
    domainIds,
    rules,
  };

  return fetch(getBaseUrl() + url, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new DomainScore(resp);
    });
}

export function getAllDomainScoresAjax(accountId, refresh) {
  const url = `/score/domain-scores-all`;

  if (!refresh && isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new DomainScore(resp);
      });
  }
}

export function getStandardScoresAjax(obj) {
  const url = '/standard/get-scores';
  return fetch(getBaseUrl() + url, {
    method: 'post',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      addToFetched(url);
      return resp;
    });
}
