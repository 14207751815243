import React, { useState } from 'react';

const LinesEllipsis = ({ children, maxLines }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleLines = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const wrapperStyle = {
    overflow: expanded ? 'visible' : 'hidden',
    textOverflow: expanded ? 'clip' : 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: expanded ? 'unset' : maxLines,
    WebkitBoxOrient: 'vertical',
  };

  return (
    <div>
      <div style={wrapperStyle}>{children}</div>
      {!expanded && (
        <button onClick={toggleLines} className="view-more-btn">
          View More
        </button>
      )}
      {expanded && (
        <button onClick={toggleLines} className="view-less-btn">
          View Less
        </button>
      )}
    </div>
  );
};

export default LinesEllipsis;
