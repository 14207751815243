import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from './Tooltip.jsx';

export default class extends React.Component {
    static propTypes = {
        hover: PropTypes.string.isRequired,
    };

    render() {

        return (
            <Tooltip className="info" hover={ this.props.hover } > &nbsp;(<span className="info-tooltip">i</span>) </Tooltip>
        );
    }
}
