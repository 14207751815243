import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';

const Option = Select.Option;

export default class ContinueSatisfy extends React.Component {
    static propTypes = {
      value: PropTypes.bool.isRequired,
      onChange: PropTypes.func.isRequired,
      style: PropTypes.object,
      affirmativeText: PropTypes.string,
      negativeText: PropTypes.string,
    };

    static defaultProps = {
      style: {},
      affirmativeText: 'Yes',
      negativeText: 'No',
    }

    handleChangeSatisfy = (value) => {
      var continueOnSatisfy = value === "Yes" ? true : false;
      this.props.onChange(continueOnSatisfy);
    };

    render() {
      var continueOnSatisfy = this.props.value ? "Yes" : "No";
      const style = Object.assign({ width: 270 }, this.props.style);

      return (
        <Select
          value={ continueOnSatisfy }
          onChange={ this.handleChangeSatisfy }
          className='continue-satisfy'
          style={style}
        >
          <Option value="Yes">{ this.props.affirmativeText }</Option>
          <Option value="No">{ this.props.negativeText }</Option>
        </Select>
      );
    }
}
