import React, { Component } from 'react';
import { Input as AntInput } from '../../../../../../../../styleguide';

class Input extends Component {
  updateInput = (e) => {
    this.props.updateSetting(this.props.settingKey, e.target.value);
  }
  render() {
    let value = this.props.value;
    if (this.props.settingKey === 'iframeTitle') {
      value = value.replace(/^\<p\>/, "").replace(/\<\/p\>$/, "");
    }
    let errors = []
    if (this.props.validate) {
      errors = this.props.validate(this.props.value)
    }
    return (
      <React.Fragment>
        <div className="set-input">
          <h4>{this.props.settingsTitle}</h4>
          <AntInput
            value={value}
            onChange={this.updateInput}
            error={errors.length ? errors.map((e) => (<div>{ e }</div>)) : null}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Input;
