import React from 'react';
import { List } from 'immutable';
import { InputNumber } from 'antd';

import { Select, Tooltip, Input } from '../../../../../styleguide';
const Option = Select.Option;

import SelectSitesAndSiteGroups from '../../../../common/SelectSitesAndSiteGroups';
import CustomGeoScopeDropDown from '../../../../common/CustomGeoScopeDropDown';
import { GEO_LIST_MERGED, CONSENT_SCOPES } from '../../../../../constants';
const regionsAndCountries = GEO_LIST_MERGED.filter((c) => c.type == 'country' || c.type == 'region');

function ManagePropertiesAndScope(props) {
  const { vendorList, updateVendorList, readOnly, sites, siteGroups, siteAccess, mandatoryFieldError } = props;

  const handleUpdateVendorList = (value, field) => {
    const updatedVendorList = vendorList.set(field, value);
    updateVendorList(updatedVendorList);
  };

  const handleSelectScope = (scope) => {
    const updatedVendorList = vendorList.set('consentScope', _.invert(CONSENT_SCOPES.toJS())[scope]);
    updateVendorList(updatedVendorList);
  };

  const getRegionNames = (regions) => {
    // filtering for all regions from list
    return regions.filter((r) => r.type === 'region');
  };

  const setCookieMaxAge = (v) => {
    let value = parseInt(v);
    if (isNaN(value)) {
      value = '';
    } else if (value < 0) {
      value = 0;
    } else if (value > 365) {
      value = 365;
    }
    const updatedVendorList = vendorList.set('cookieMaxAge', value);
    updateVendorList(updatedVendorList);
  };

  const handleLocationSave = (value) => {
    // gdprApplyLocation = gdprApplyLocation != '' ? gdprApplyLocation.split(',') : List([]);
    // exclusionLocation = exclusionLocation != '' ? exclusionLocation.split(',') : List([]);

    const updatedVendorList = vendorList.set('appliesGeos', value.appliesGeos)
    .set('exclusionGeos', value.exclusionGeos);
    updateVendorList(updatedVendorList);
  };
  
  const extraProps = {
    placement: 'top',
  };
  const geoScope = (
    <CustomGeoScopeDropDown
      // options={getRegionNames(regionsAndCountries)}
      // type="gdpr"
      handleLocationSave={handleLocationSave}
      vl={vendorList}
      placement="bottom"
      {...extraProps}
    />
  );

  const geoAppliesScopeInfo =
    'When a user comes to your properties, we use their IP address to locate the region they are in. The "GDPR Applies Scope" determines in which regions a GDPR consent signal should be set on the user. NB: Please note that this do not control which regions the GDPR message is shown in (this setting is within the campaign scenarios section).';

  const propertyListScopeInfo =
    'Please select the properties which this vendor list should apply to';

  const cookieToolTipInfo =
    'The consent cookie expiration is the setting for the number of days which the cookie in which we store the consent string on a users browser exists for. At the point when the cookie expires, the user will be shown the GDPR message again.';

  const consentScopeInfo = (
    <React.Fragment>
      <p className="tooltip-title">Single property</p>
      <p> Consent will only be applied to the same property a user has consented on.</p>
      <p className="tooltip-title">Shared property</p>
      <p>
        Consent will be shared across all properties on the vendor list that implemented the authenticated consent
        feature and website properties that share the same top level domain.
      </p>
    </React.Fragment>
  );

  const showError = (fieldName) => {
    return mandatoryFieldError && <span className="error-message">{mandatoryFieldError[fieldName]}</span>;
  }
 
  const showErrorClass = (fieldName) => {
    return mandatoryFieldError[fieldName] ? 'error-input' : '';
  }

  return (
    <div className="properties-and-scope-wrapper">
      <span className="mandatory-fields-notice">
        <span className="asterisk">*</span>Mandatory Fields
      </span>

      <label>
        Name<span className="asterisk">*</span>
      </label>
      <Input
        className={showErrorClass("name")}
        value={vendorList.name}
        onChange={(e) => handleUpdateVendorList(e.target.value, 'name')}
      />
      {showError("name")}

      <label>
        GDPR Applies Scope<span className="asterisk">*</span>
        <Tooltip overlayClassName="vl-wizard-tooltip" title={geoAppliesScopeInfo}>
          <i className="fas fa-info-circle" />
        </Tooltip>
      </label>
      {geoScope}
      {showError("geoScope")}
      <label>
        Consent Scope
        <Tooltip overlayClassName="vl-wizard-tooltip" title={consentScopeInfo}>
          <i className="fas fa-info-circle" />
        </Tooltip>
      </label>
      <Select
        disabled={readOnly}
        value={CONSENT_SCOPES.get(vendorList.consentScope)}
        onChange={(value) => handleSelectScope(value)}
        size="large"
      >
        {CONSENT_SCOPES.toList().map((s) => (
          <Option key={s}>{s}</Option>
        ))}
      </Select>

      <label>Properties<span className="asterisk">*</span>
      <Tooltip title={propertyListScopeInfo}>
          <i className="fas fa-info-circle" />
      </Tooltip>
      </label>
      <SelectSitesAndSiteGroups
        value={vendorList.siteIds}
        sites={sites}
        siteGroups={siteGroups}
        onSelect={(siteIds) => handleUpdateVendorList(List(siteIds), 'siteIds')}
        multiple
        disabled={readOnly}
        siteAccess={siteAccess}
        placeholder="none"
        customDropdownClass="custom-dropdown"
      />
      {showError("properties")}
      <label>
        Consent Cookie Expiration
        <Tooltip overlayClassName="vl-wizard-tooltip" title={cookieToolTipInfo}>
          <i className="fas fa-info-circle" />
        </Tooltip>
      </label>
      <InputNumber
        type="number"
        min={1}
        max={365}
        value={vendorList.cookieMaxAge}
        disabled={readOnly}
        onChange={setCookieMaxAge}
      />
    </div>
  );
}

export default ManagePropertiesAndScope;
