import * as types from "./action_types";

export function setSelectedVendorTraceFilters (vendorTraceFilters) {
    return {
        type: types.SET_SELECTED_VENDOR_TRACE_FILTERS,
        vendorTraceFilters,
    };
}

export function setConsentTypeFilter (consentTypeFilter) {
    return {
        type: types.SET_CONSENT_TYPE_FILTER,
        consentTypeFilter,
    };
}

export function resetVendorTrace () {
    return {
        type: types.RESET_VENDOR_TRACE,
    };
}
