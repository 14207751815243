import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { ConfigProvider, Input, Select, Table, Tabs } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { Map, List } from 'immutable';
import CustomPopover from '../../common/CustomPopover';
import {
  vendorsTooltipContentV2,
  purposesTooltipContentV2,
  specialPurposesTooltipContent,
} from '../performanceNew/intructionalText';
import { createCSVExportData } from '../performanceNew/helper';
import ExportPopoverContent from '../../ccpa/ccpa_reporting/ExportPopoverContent';

const { TabPane } = Tabs;
const { Option } = Select;

export default class ConsentPVVendor extends React.Component {
  state = {
    searchText: '',
    searchPurposesText: '',
    searchSpecialFeatures: '',
  };

  tableTitle = (
    tableTitle,
    tooltipContent,
    exportFilename,
    exportHeader,
    exportTableData,
    exportPDFTitle,
    searchPlaceholder,
    stateName
  ) => (
    <div className="table-title-container">
      <div className="table-title">
        {tableTitle}
        <CustomPopover tooltipContent={tooltipContent} />
        <CustomPopover
          tooltipContent={
            <ExportPopoverContent
              filename={exportFilename}
              exportData={createCSVExportData(exportHeader, exportTableData)}
              pdfTitle={exportPDFTitle}
            />
          }
          style="export-popover"
          icon="download"
          iconColor="#108ee9"
          title={<span className="popover-title">Export Type Selection</span>}
        />
      </div>
      <div className="vendors-table-search">
        <div className="search-container">
          <Input
            placeholder={`Search ${searchPlaceholder}`}
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            onChange={(event) => {
              this.setState({ [stateName]: event.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
  render() {
    let tableData = this.props.consentVendorData;
    tableData = tableData.filter((v) =>
      v.name.toLowerCase().includes(this.state.searchText)
    );
    let purposeTableData = this.props.purposeConsentData;
    purposeTableData = purposeTableData.filter((p) =>
      p.name.toLowerCase().includes(this.state.searchPurposesText)
    );
    let specialFeaturesTableData = this.props.specialFeatureConsentData;
    specialFeaturesTableData = specialFeaturesTableData.filter((s) =>
      s.name.toLowerCase().includes(this.state.searchSpecialFeatures)
    );
    const columns = List([
      Map({
        title: 'Vendor',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        className: 'left-align',
      }),
      Map({
        title: 'Consent Rate',
        dataIndex: 'consentrate',
        key: 'consentrate',
        sorter: (a, b) => a.consentrate - b.consentrate,
        className: 'right-align',
        render: (text) => <span>{Number(text).toFixed(2) + '%'}</span>,
      }),
      Map({
        title: 'Unconsented Rate',
        dataIndex: 'rejectionrate',
        key: 'rejectionrate',
        sorter: (a, b) => a.rejectionrate - b.rejectionrate,
        className: 'right-align',
        render: (text) => <span>{Number(text).toFixed(2) + '%'}</span>,
      }),
      Map({
        title: 'Consented Views',
        dataIndex: 'consentcount',
        key: 'consentcount',
        sorter: (a, b) => a.consentcount - b.consentcount,
        className: 'right-align',
        render: (text) => <span>{text.toLocaleString()}</span>,
      }),
      Map({
        title: 'Unconsented Views',
        dataIndex: 'unconsentcount',
        key: 'unconsentcount',
        sorter: (a, b) => a.unconsentcount - b.unconsentcount,
        className: 'right-align',
        render: (text) => <span>{text.toLocaleString()}</span>,
      }),
      Map({
        title: 'Total Views',
        dataIndex: 'pvcount',
        key: 'pvcount',
        sorter: (a, b) => a.pvcount - b.pvcount,
        className: 'right-align',
        render: (text) => <span>{text.toLocaleString()}</span>,
      }),
    ]);
    const vendorHeaders = columns.map((item) =>
      Map({ label: item.get('title'), key: item.get('dataIndex') })
    );
    const purposesHeader = vendorHeaders
      .updateIn([0, 'label'], (value) => 'Purpose')
      .insert(
        5,
        Map({ label: 'Legitimate Interest Objection Rate', key: 'legintobj' })
      );
    const specialFeaturesHeader = vendorHeaders.updateIn(
      [0, 'label'],
      (value) => 'Special Features'
    );
    const legIntObjection = Map({
      title: 'Legitimate Interest Objection Rate',
      dataIndex: 'legintobj',
      key: 'legintobj',
      sorter: (a, b) => a.legintobj - b.legintobj,
      className: 'right-align',
      render: (text) => <span>{Number(text).toFixed(2) + '%'}</span>,
    });
    return (
      <React.Fragment>
        <div className="vendors-filter-container">
          <div className="vendor-property-select-container">
            <div>Property</div>
            <ConfigProvider locale={enUS}>
              <Select
                placeholder="Select Property"
                className="vendor-property-filter"
                optionFilterProp="children"
                disabled={false}
                onChange={this.props.selectSingleSite}
                value={this.props.vendorProperty}
                showSearch={true}
                filterOption={(i, o) =>
                  o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                }
              >
                {this.props.sitesOptions}
              </Select>
            </ConfigProvider>
          </div>
          <div className="vendor-geo-select-container">
            <div>Region</div>
            <ConfigProvider locale={enUS}>
              <Select
                placeholder="Select Region"
                maxTagCount={
                  this.props.maxSize == this.props.vendorRegions.size ? 0 : 1
                }
                maxTagTextLength={2}
                maxTagPlaceholder={
                  this.props.maxSize == this.props.vendorRegions.size
                    ? 'All'
                    : `+${
                        this.props.vendorRegions.size
                          ? this.props.vendorRegions.size - 1
                          : 0
                      }`
                }
                className="vendor-property-filter"
                optionFilterProp="children"
                mode="multiple"
                disabled={false}
                value={this.props.vendorRegions.toJS()}
                onChange={this.props.selectVendorRegion}
                filterOption={(i, o) =>
                  o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                }
              >
                <Option
                  key="All"
                  value="All"
                  className={
                    this.props.maxSize == this.props.vendorRegions.size
                      ? 'select-all-style'
                      : ''
                  }
                >
                  All
                </Option>
                {this.props.regionsOptions}
              </Select>
            </ConfigProvider>
          </div>
        </div>

        <div className="consent-tabs-container gdpr-vendor-tab-container">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Vendors" key="1">
              <div className="consentblock">
                <Table
                  columns={columns.toJS()}
                  dataSource={tableData.toJS()}
                  title={() =>
                    this.tableTitle(
                      'Consented Views by Vendors',
                      vendorsTooltipContentV2,
                      'consented_pageviews_vendors',
                      vendorHeaders,
                      tableData,
                      'Consented Views by Vendors',
                      'Vendors',
                      'searchText'
                    )
                  }
                  pagination={{
                    itemRender: this.itemRender,
                    size: 'small',
                    defaultPageSize: 6,
                    showSizeChanger: true,
                    pageSizeOptions: ['4', '8', '10'],
                    locale: { items_per_page: '' },
                  }}
                  className="consnet-tableCSS"
                  rowKey={(r) => r.key}
                />
              </div>
            </TabPane>
            <TabPane tab="Purposes" key="2">
              <div className="consentblock">
                <Table
                  columns={columns
                    .updateIn([0, 'title'], (value) => 'Purpose')
                    .insert(5, legIntObjection)
                    .toJS()}
                  dataSource={purposeTableData.toJS()}
                  title={() =>
                    this.tableTitle(
                      'Performance by Purpose',
                      purposesTooltipContentV2,
                      'performance_by_purposes',
                      purposesHeader,
                      purposeTableData,
                      'Performance by Purpose',
                      'Purposes',
                      'searchPurposesText'
                    )
                  }
                  pagination={{
                    itemRender: this.itemRender,
                    size: 'small',
                    defaultPageSize: 6,
                    showSizeChanger: true,
                    pageSizeOptions: ['4', '8', '10'],
                    locale: { items_per_page: '' },
                  }}
                  className="consnet-tableCSS"
                  rowKey={(r) => r.key}
                />
              </div>
            </TabPane>
            <TabPane tab="Special Features" key="3">
              <div className="consentblock">
                <Table
                  columns={columns
                    .updateIn([0, 'title'], (value) => 'Special Features')
                    .toJS()}
                  dataSource={specialFeaturesTableData.toJS()}
                  title={() =>
                    this.tableTitle(
                      'Performance By Special Features',
                      specialPurposesTooltipContent,
                      'performance_special_features',
                      specialFeaturesHeader,
                      specialFeaturesTableData,
                      'Performance By Special Features',
                      'Special Features',
                      'searchSpecialFeatures'
                    )
                  }
                  pagination={{
                    itemRender: this.itemRender,
                    size: 'small',
                    defaultPageSize: 6,
                    showSizeChanger: true,
                    pageSizeOptions: ['4', '8', '10'],
                    locale: { items_per_page: '' },
                  }}
                  className="consnet-tableCSS"
                  rowKey={(r) => r.key}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}
