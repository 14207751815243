import React from 'react';
import { Link } from 'react-router';

export default class CcpaSubnav extends React.Component {
  render() {
    const { currentUser: { accountId, accountFeatures } } = this.props;
    let goldeScoreLink;
    let adminLink;
    if (this.props.currentUser.account_id === 22) {
      goldeScoreLink = <li><Link to='/privacy_lens/golden_score' activeClassName="active" >Golden Score</Link></li>;
    }
    if (this.props.currentUser.accountFeatures.includes('privacy_lens_admin')) {
      adminLink = <li><Link to='/privacy_lens/admin' activeClassName="active" >Admin</Link></li>;
    }
    return (
      <div className="dialogue subnav">
        <ul className="list-inline">
          <li><Link to='/privacy_lens/scoring_results' activeClassName="active" >Scoring Results</Link></li>
          <li><Link to='/privacy_lens/domain_sets' activeClassName="active" >Property Sets</Link></li>
          <li><Link to='/privacy_lens/standards' activeClassName="active" >Standards</Link></li>
          { adminLink }
          { goldeScoreLink }
        </ul>
      </div>
    );
  }
}
