import { List, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';
import { GoldenSet } from '../../records/golden_set_records';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;
// const permissionsSvcBaseUrl = 'http://localhost:5000'

const getBaseUrl = () => {
  return `${permissionsSvcBaseUrl}/consent-quality`;
};

export function getGoldenSetsAjax() {
  const url = '/golden-set/';
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return List(resp.map(s => new GoldenSet(s) ));
      });
  }
}

export function getGoldenSetAjax(id) {
  const url = `/golden-set/${id}`;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return new GoldenSet(resp);
      });
  }
}

export function createGoldenSetAjax(accountId, goldenSet) {
  const ruleIds = goldenSet.rules.map(r => r.id);
  const data = goldenSet.toMap().delete('id').set('rules', ruleIds).set('accountId', accountId);
  return fetch(getBaseUrl() + '/golden-set/', {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      localStorage.setItem('datePropertySetUpdated', JSON.stringify(new Date()));
      return new GoldenSet(resp);
    });
}

export function updateGoldenSetAjax(accountId, goldenSet) {
  const ruleIds = goldenSet.rules.map(r => r.id);
  const data = goldenSet.toMap().set('_id', goldenSet.id).delete('id').set('rules', ruleIds).set('accountId', accountId);
  return fetch(getBaseUrl() + '/golden-set/', {
    method: 'PUT',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      localStorage.setItem('datePropertySetUpdated', JSON.stringify(new Date()));
      return new GoldenSet(resp);
    });
}

export function deleteGoldenSetAjax(id, accountId) {
  return fetch(getBaseUrl() + '/golden-set/' + id, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(() => id);
}

export function getRulePepformanceDataAjax(id) {
  const url = '/golden-set/rule-performance-data/' + id;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return resp;
      });
  }
}

export function changeGoldenScoresAjax(goldenSetId, propertyIds, rules) {
  const url = `/score/change-golden-scores?goldenSetId=${goldenSetId}`;
  const data = {
    propertyIds,
    rules,
  };

  return fetch(getBaseUrl() + url, {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new GoldenSet(resp);
    });
}