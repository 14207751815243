import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import ReactDOM from 'react-dom';

import Label from '../../../common/Label.js.jsx';
import CustomIcon from '../../../common/CustomIcon.js.jsx';

import MessageSelector from '../../messages/MessageSelector.js.jsx';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { ShowMessageNTimes } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { Message } from '../../../../records/message_records';
import MessageDescription from './MessageDescription';

export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data:PropTypes.instanceOf(ShowMessageNTimes).isRequired,
        onChange: PropTypes.func.isRequired,
        messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    };

    state = {
        n: this.props.data.n
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            this.setState({
                n: nextProps.data.n,
            })
        }
    }

    componentDidMount() {
        if (this.props.editing) ReactDOM.findDOMNode(this.refs.message).focus();
    }

    handleMessageChange = (event) => {
        const data = this.props.data.set('message_id', (parseInt(event.target.value) || undefined));
        this.props.onChange(data);
    };

    handleNChange = (event) => {
        var value = parseInt(event.target.value);

        if (isNaN(value)) {
            this.setState({ n: '' })
        } else {
            this.setState({ n: value });
            this.props.onChange(this.props.data.set('n', value));
        }
    };

    render() {

        var messageID = this.props.data.message_id;

        const message = this.props.messages.find(message => message.get('id') === messageID);

        let previewIcon;
        if (message && message.getIn(['msg_metadata', 'type']) !== "redirect") {
            previewIcon = (
                <CustomIcon
                  type={ CustomIcon.types.PREVIEW }
                  onClick={ () => browserHistory.push('/dialogue/message_preview?message_id=' + messageID + '&site_id=' + message.site_id) }
                />
            )
        }

        if (!this.props.editing) {
            return (
                <div>
                  <span className="message-to-preview">Message: {
                    message ?
                      <MessageDescription message={ message } /> :
                      "UNKNOWN"
                    }, Times: { this.state.n || this.props.data.n }
                  </span>
                  { previewIcon }
                </div>
            );
        } else {
            return (
                <div className="step message-show-n-times">
                  <label>Message:</label>
                  <MessageSelector ref="message" value={ message ? messageID : -1 } messages={ this.props.messages } onChange={ this.handleMessageChange }/>

                  <label>Times:</label>
                  <input type="text" value={ this.state.n } onChange={ this.handleNChange }></input>
                </div>
            );
        }
    }
}
