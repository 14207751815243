import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Tabs, Button } from 'antd';
import { Vendor, VendorWrapper } from '../../../../../records/vendor_list_records';
import ActionsTab from '../../../../common/ActionsTab';
import VendorCookiesTab from '../../VendorCookiesTab';

const TabPane = Tabs.TabPane;

export default class VendorDetailModal extends React.Component {
  static propTypes = {
    vendorWrapper: PropTypes.instanceOf(VendorWrapper).isRequired,
    vendor: PropTypes.instanceOf(Vendor).isRequired,
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    handleApply: PropTypes.func.isRequired,
    deleteVendor: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  }

  state = {
    vendorWrapper: this.props.vendorWrapper,
  }

  updateVendorsActions = (actions, actionType) => {
    const vendorWrapper = this.state.vendorWrapper.set(actionType, actions);
    this.setState({ vendorWrapper });
  }

  handleCancelModalChanges = () => {
    this.setState({ vendorWrapper: this.props.vendorWrapper });
    this.props.closeModal();
  }

  handleApply = () => {
    this.props.handleApply(this.state.vendorWrapper);
  }

  handleRemove = () => {
    this.props.deleteVendor(this.props.vendor);
  }

  renderFooter = () => {
    if (this.props.readOnly) {
      return null;
    } else {
      return (
        [
          <Button key="back" onClick={this.handleCancelModalChanges}>Cancel</Button>,
          <Button className="purpose-delete" key="delete" onClick={this.handleRemove}>Remove</Button>,
          <Button key="submit" type="primary" onClick={this.handleApply}>Apply changes</Button>,
        ]
      );
    }
  }

  updateActionExtension = (type, key) => (value) => {
    const updatedWrapper = this.state.vendorWrapper.update(`${type}ActionExtension`, (ext) => ({ ...ext, [key]: value }));
    this.setState({ vendorWrapper: updatedWrapper });
  }

  render() {
    return (
      <Modal
        onOk={this.handleApply}
        visible={this.props.visible}
        onCancel={this.handleCancelModalChanges}
        className='edit-purpose'
        closable={false}
        destroyOnClose
        footer={this.renderFooter()}
        width={700}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Vendor Details" key="1">
            <VendorCookiesTab
              cookies={this.props.vendorWrapper.cookies}
              vendor={this.props.vendor}
            />
          </TabPane>
          <TabPane tab="Consent Actions" key="2">
            <ActionsTab
              isCcpa
              readOnly={this.props.readOnly}
              actions={this.state.vendorWrapper.consentActions}
              geoTargeting={this.state.vendorWrapper.consentActionExtension.geoTargeting}
              onStatusChangeOnly={this.state.vendorWrapper.consentActionExtension.onStatusChangeOnly}
              actionCookies={this.state.vendorWrapper.consentActionExtension.cookies}
              updateActions={(actions) => this.updateVendorsActions(actions, 'consentActions')}
              updateGeoTargeting={this.updateActionExtension('consent', 'geoTargeting')}
              updateOnStatusChangeOnly={this.updateActionExtension('consent', 'onStatusChangeOnly')}
              updateActionCookies={this.updateActionExtension('consent', 'cookies')}
            />
          </TabPane>
          <TabPane tab="Reject Actions" key="3">
          <ActionsTab
              isCcpa
              readOnly={this.props.readOnly}
              actions={this.state.vendorWrapper.rejectActions}
              geoTargeting={this.state.vendorWrapper.rejectActionExtension.geoTargeting}
              onStatusChangeOnly={this.state.vendorWrapper.rejectActionExtension.onStatusChangeOnly}
              actionCookies={this.state.vendorWrapper.rejectActionExtension.cookies}
              updateActions={(actions) => this.updateVendorsActions(actions, 'rejectActions')}
              updateGeoTargeting={this.updateActionExtension('reject', 'geoTargeting')}
              updateOnStatusChangeOnly={this.updateActionExtension('reject', 'onStatusChangeOnly')}
              updateActionCookies={this.updateActionExtension('reject', 'cookies')}
            />
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}
