import React, {useState,useEffect} from 'react';
import { Menu } from 'antd';
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"
import TranslationTable from './TranslationTable';


const TanslationComponent = (props) => {
    const { 
        menuTitle,
        selectedKey,
        selectedSubMenuKey, 
        handleMenuClick,
        purposes, 
        activeTab,
        selectedLang,
        selectedPurpose,
        handleTranslationChanges,
        readOnly,
        data
    } = props
    const [openKeys, setOpenKeys] = useState([])

    const collapse = (e, selKey) => {
        e.stopPropagation(); 
        setOpenKeys([]); 
        handleMenuClick(selKey,activeTab)
    }

    return(
        <>
            <div className="menu">
                <div className="menu-title">{menuTitle}</div>
                <hr />
                <Menu
                    defaultSelectedKeys={[selectedKey]}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    inlineCollapsed={false}
                    selectedKeys={selectedSubMenuKey}
                    onClick={({ key }) => handleMenuClick(key, activeTab)}
                    expandIcon={(props) => {
                        return props.isOpen ? <MinusOutlined className="expand-icon" onClick={(e) => collapse(e,selectedKey)} /> : <PlusOutlined className="expand-icon" onClick={(e) => { e.stopPropagation(); setOpenKeys([props.eventKey]) }} />
                    }}
                    openKeys={openKeys}
                >
                    {purposes}
                </Menu>
                </div>
                <div className="content">
                    <TranslationTable
                        selLanguages={selectedLang}
                        dataSource={data}
                        readOnly={readOnly}
                        selectedSubMenuKey={selectedSubMenuKey}
                        handleTranslationChanges={handleTranslationChanges}
                        selectedPurpose={selectedPurpose}
                        activeTab={activeTab}
                        onEditScreen={props.onEditScreen}
                    />
                </div>
        </>
    )
}

export default TanslationComponent;