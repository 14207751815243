/* eslint-disable camelcase */
/* eslint-disable no-alert */
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch, { retries: 2, retryDelay: 500 });

import { checkStatus, buildUrlWithVersion, DIAGNOSE_DASHBOARD_BASEURL } from '../helper.js';


const GET_NOTIFICATIONS = `${DIAGNOSE_DASHBOARD_BASEURL}/notification/get/`;
const UPDATE_NOTIFICATIONS = `${DIAGNOSE_DASHBOARD_BASEURL}/notification/read/`;
const GET_NOTIFICATIONS_EMAIL_PREFERENCES = `${DIAGNOSE_DASHBOARD_BASEURL}/notification/preferences/users/`;
const CREATE_NOTIFICATIONS_EMAIL_PREFERENCES = `${DIAGNOSE_DASHBOARD_BASEURL}/notification/preferences/users/`;
const UPDATE_NOTIFICATIONS_EMAIL_PREFERENCES = `${DIAGNOSE_DASHBOARD_BASEURL}/notification/preferences/users/`;

// TODO: put under permissions service
export function getNotificationsData({ page = 1, metric = '', type = '', isSeen = '', pageSize, ...rest }) {
    return fetch(
        buildUrlWithVersion(
            GET_NOTIFICATIONS,
            {
                ...type ? { type } : {},
                ...metric ? { metric } : {},
                ...page ? { page } : {},
                ...typeof isSeen === 'boolean' ? { is_seen: isSeen } : {},
                page_size: pageSize,
                ...rest,
            },
            'notifications',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {},
        },
    )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
        console.log(err);
        alert(err.response ? `Error on get 'Alerts' data: status ${err.response.status}` : err);
    });
}

export function markNotificationsRead(body) {
    return fetch(
        buildUrlWithVersion(
            UPDATE_NOTIFICATIONS,
            {},
            'notifications',
        ),
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        },
    )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
        console.log(err);
        alert(err.response ? `Error on update 'Alerts' data: status ${err.response.status}` : err);
    });
}

export function getNotificationEmailPreferences({ email }) {
    return fetch(
        buildUrlWithVersion(
            GET_NOTIFICATIONS_EMAIL_PREFERENCES,
            {
                page_size: 1,
                email,
            },
            'notifications',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {},
        },
    )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
        console.log(err);
    });
}


export function createNotificationEmailPreferences({ email, first_name, last_name, emailing_enabled, preferences }, accountId) {
    return fetch(
        buildUrlWithVersion(
            CREATE_NOTIFICATIONS_EMAIL_PREFERENCES,
            {},
            'notifications',
        ),
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    email,
                    first_name,
                    last_name,
                    emailing_enabled,
                    preferences,
                },
                accountId,
            }),
        },
    )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
        console.log(err);
        alert(err.response ? `Error on create 'Alerts Email Preferences' data: status ${err.response.status}` : err);
    });
}


export function updateNotificationEmailPreferences({ email, first_name, last_name, emailing_enabled, preferences }, accountId) {
    return fetch(
        buildUrlWithVersion(
            UPDATE_NOTIFICATIONS_EMAIL_PREFERENCES,
            {},
            'notifications',
        ),
        {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    email,
                    first_name,
                    last_name,
                    emailing_enabled,
                    preferences,
                },
                accountId,
            }),
        },
    )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
        console.log(err);
        alert(err.response ? `Error on update 'Alerts Email Preferences' data: status ${err.response.status}` : err);
    });
}


export default null;
