import PropTypes from 'prop-types';
import React from 'react';
import AceEditor from 'react-ace';
import { Button } from 'antd';
import 'brace/mode/css';
import 'brace/theme/twilight';
import 'brace/ext/searchbox';

import CustomButton from '../../../common/CustomButton.js.jsx';
import { MessageConfigRecord } from '../../../../records/message_config_records.js';
import AddVariableDropdownModal from './AddVariableDropdownModal';
import { getParameterByName } from '../../../utils';

export default class extends React.Component {
  static propTypes = {
      messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
      updateMessageConfig: PropTypes.func.isRequired,
      editing: PropTypes.bool.isRequired,
      editorOpen: PropTypes.bool.isRequired,
      toggleCustomCSS: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: '',
  };

  state = {
    newCSSval: this.props.messageConfig.getIn(['data', 'style', 'customStyles']),
    unsavedChanges: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.messageConfig && !this.props.editorOpen) {
      this.setState({
        newCSSval: nextProps.messageConfig.getIn(['data', 'style', 'customStyles']),
      });
    }
  }

  handleUpdate = () => {
    const newVal = this.customEditor.editor.getValue();
    const newMessageConfig = this.props.messageConfig.setIn(['data', 'style', 'customStyles'], newVal);
    this.props.updateMessageConfig(newMessageConfig);
  };

  backtoEditor = () => {
    this.setState({
      unsavedChanges: false,
    });
  };

  handleClose = () => {
    if (this.state.newCSSval !== this.props.messageConfig.getIn(['data', 'style', 'customStyles'])) {
      this.setState({
        unsavedChanges: true,
        newCSSval: this.customEditor.editor.getValue(),
      });
    }
     else {
      this.toggleOpen();
    }
  };

  toggleOpen = () => {
    this.props.toggleCustomCSS();
    this.setState({
      newCSSval: this.props.messageConfig.getIn(['data', 'style', 'customStyles']),
      unsavedChanges: false,
    });
  };

  handleEditorChange = (string) => {
    this.setState({
      newCSSval: string,
    });
  }

  render() {
    let variables;
    if (getParameterByName('site_group_id', window.location)) {
      if (this.props.variables && this.props.variables.defaultVariables.size) {
        variables = (
          <React.Fragment>
            <p className='variables-header'>Custom Variables</p>
            <AddVariableDropdownModal
              variables={ this.props.variables }
              openVariablesModal={ this.props.openVariablesModal }
              inCustomCSS
            />
          </React.Fragment>
        );
      } else {
        variables = (
          <div className='no-variables-container'>
            <p className='no-variables-header'>No variables created</p>
            <p>Use variables to speed up your workflow by adding logos, images, colors etc.. to specific sites all at once.</p>
            <Button type='primary' onClick={ this.props.openVariablesModal }>Get started</Button>
          </div>
        )
      }
    }

    const editor = (
      <div className='custom-css-editor'>
        <div className='bumper' />
        <AceEditor
          ref={AceEditor => { this.customEditor = AceEditor; }}
          value={ this.state.newCSSval }
          mode='css'
          theme='twilight'
          readOnly={ this.state.unsavedChanges || !this.props.editing }
          width='100%'
          height='340px'
          focus={true}
          onChange={ this.handleEditorChange }
          commands={[
            {
              name: 'update on cmd+enter',
              exec: this.handleUpdate,
              bindKey: {mac: 'cmd-enter', win: 'ctrl-f'},
            },
          ]}
        />
        <div className='veil' />
        <div className='save-and-close-container'>
          <CustomButton
            className='css-close button'
            size={ CustomButton.sizes.MEDIUM }
            onClick={ this.handleClose }
          >
            Close
          </CustomButton>
          <CustomButton
            className='save button'
            size={ CustomButton.sizes.MEDIUM }
            onClick={ this.handleUpdate }
          >
            Update
          </CustomButton>
          <p className='custom-style-warning-message'>
              NOTE: PAGE STYLES WILL BE AFFECTED BY CUSTOM CSS
              We recommend prepending #sp_message_id to any style
          </p>
        </div>
        <div className='variables-container'>
          { variables }
        </div>
      </div>
    );

    let unsavedChangesModal;
    if (this.state.unsavedChanges) {
      unsavedChangesModal = (
        <div className='unsaved-changes'>
          <h2>Are you sure you want to close without saving?</h2>
          <CustomButton
            className='close-without-saving button'
            size={ CustomButton.sizes.MEDIUM }
            onClick={ this.toggleOpen }
          >
            Close editor without saving
          </CustomButton>
          <CustomButton
            className='css-back-to-editor button'
            size={ CustomButton.sizes.MEDIUM }
            onClick={ this.backtoEditor }
          >
            Go back to editor
          </CustomButton>
        </div>
      );
    }

    let addEditOrView;
    if (this.props.editing) {
      addEditOrView = this.props.value === '' ? 'Add' : 'Edit';
    } else {
      addEditOrView = 'View';
    }
    const openEditorButton = (
      <CustomButton
        className='add-custom-css-button'
        size={ CustomButton.sizes.MEDIUM }
        onClick={ this.toggleOpen }
      >
        { addEditOrView } Custom CSS
      </CustomButton>
    );

    const renderEditor = (
      this.props.editorOpen ? editor : openEditorButton
    );

    return (
      <div className='custom-css'>
        {unsavedChangesModal}
        {renderEditor}
      </div>
    );
  }
}
