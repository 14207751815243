import React, { Component } from 'react';
import { Slider } from '../../../../../../../styleguide';

const MAX_PADDING = 50;


class Padding extends Component {
  updatePadding = (type, value) => {
    const padding = JSON.parse(JSON.stringify(this.props.value));
    padding[type] = value;
    this.props.updateSetting(this.props.settingKey, padding);
  }
  render() {

    const { value, settingsTitle } = this.props;

    return (
      <div>
        <div className="four-options">
          <h4>{settingsTitle}</h4>
          
          <div className="padding-flex">
            <div className="padding-label">Top</div>
            <Slider min={0} max={MAX_PADDING} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updatePadding('paddingTop', val)}
              value={typeof value.paddingTop === 'number' ? value.paddingTop : 0}
            />
          </div>
          <div className="padding-flex">
            <div className="padding-label">Left</div>
            <Slider min={0} max={MAX_PADDING} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updatePadding('paddingLeft', val)}
              value={typeof value.paddingLeft === 'number' ? value.paddingLeft : 0}
            />
          </div>
          <div className="padding-flex">
            <div className="padding-label">Bottom</div>
            <Slider min={0} max={MAX_PADDING} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updatePadding('paddingBottom', val)}
              value={typeof value.paddingBottom === 'number' ? value.paddingBottom : 0}
            />
          </div>
          <div className="padding-flex">
            <div className="padding-label">Right</div>
            <Slider min={0} max={MAX_PADDING} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updatePadding('paddingRight', val)}
              value={typeof value.paddingRight === 'number' ? value.paddingRight : 0}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Padding;