import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Button, Tag, Table } from 'antd';
import { rulePerformanceCSV } from '../../CSV/generateCSV';
import { getColumns } from './RulePerformanceColumns';

export default class RulePerformance extends Component {
  state = {
    ruleData: [],
    propertyCount: 0,
    filter: 'all'
  }
  componentDidMount = () => {
    this.generateRuleData();
  }
  generateRuleData = () => {
    const scoreData = this.props.scoreData;

    const ruleScores = scoreData.scores.byRule;
    const ruleData = scoreData.standard.rules.map(r => {
      const rs = ruleScores[r.rule.ruleId];
      return {
        id: r._id,
        name: r.rule.name,
        ruleId: r.rule.ruleId,
        percentNum: rs.passPercent,
        percent: rs.passPercent + '%',
        total: rs.totalPass,
        systemWeight: r.weight === 0 ? r.rule.systemWeight : r.weight,
        mustHave: r.niceToHave !== true,
        niceToHave: r.niceToHave === true,
        productStatus: r.rule.productStatus,
      };
    }).sort((a, b) => b.percentNum - a.percentNum);

    this.setState({
      ruleData: ruleData,
      propertyCount: scoreData.numProperties
    })
  }
  setFilter = filter => this.setState({ filter })
  getFilteredData = () => {
    const { filter, ruleData } = this.state;
    return ruleData.filter(r => {
      if (filter === 'all') return true;
      if (filter === 'must' && r.mustHave) return true;
      if (filter === 'nice' && r.niceToHave) return true;
      return false;
    });
  }
  getExport = (type) => {
    rulePerformanceCSV(this.getFilteredData(),this.props.selectedSetName);
  }
  render() {
    const { propertyCount, filter } = this.state;
    const filteredData = this.getFilteredData();
    const ruleStatusToShow = ['Alpha', 'Beta+', 'Beta'];

    const columns = getColumns();

    return (
      <div className="performance">
        <div className="performance-title">
          <h2><b>Rule Performance</b> - % of properties that pass the rules mentioned below.</h2>
          <span className="download" onClick={this.getExport}>
            <FontAwesomeIcon icon={faDownload} />
          </span>
        </div>

        <div className="rule-filters">
          <Button className={filter === 'must' ? 'active' : ''} onClick={() => this.setFilter('must')}>Must Have</Button>
          <Button className={filter === 'nice' ? 'active' : ''} onClick={() => this.setFilter('nice')}>Nice to Have</Button>
          <Button className={filter === 'all' ? 'active' : ''} onClick={() => this.setFilter('all')}>All Rules</Button>
        </div>

        <div className="rule-antd">
          <Table
            bordered
            columns={columns}
            rowKey={record => record._id}
            dataSource={filteredData}
            pagination={false}
          />
          <div className="pscore">
            <div><span>*</span>Must have rules</div>
            <div>Properties scored: <b>{propertyCount}</b></div>
          </div>
        </div>

      </div>
    );
  }
}
