import ImmutablePropTypes from 'react-immutable-proptypes';
import { OrderedSet } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import CustomButton from '../../../common/CustomButton.js.jsx';
import { Message } from '../../../../records/message_records';
import ScenarioStep from './ScenarioStep.js.jsx';
import { IfStatement as IfStatementRecord, ScenarioStep as StepRecord  } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';

export default class StepIfStatement extends React.Component {

    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(IfStatementRecord).isRequired,
        onChange: PropTypes.func.isRequired,
        messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        altpay: PropTypes.bool.isRequired,
        welect: PropTypes.bool.isRequired,
        leftCellStyles: PropTypes.object,
    };

    static defaultProps = {
        leftCellStyles: {
            position: 'relative',
            left: '0px',
        },
    };

    render() {
        const thenButtons = this.props.editing && (
            <div className="panel-buttons">
                { this.renderAddButton(this.handleAddThenStep) }
            </div>
        );

        const elseButtons = this.props.editing && (
            <div className="panel-buttons">
                { this.renderAddButton(this.handleAddElseStep) }
            </div>
        );

        const leftCellStyles = this.getLeftCellStyles();

        return (
            <React.Fragment>
                <tr>
                    <th style={ leftCellStyles }>If</th>
                    <th style={ leftCellStyles } />
                    <th />
                    { this.props.editing && <th /> }
                </tr>
                <ScenarioStep
                    step={ this.props.data.test }
                    editing={ this.props.editing }
                    messages={ new OrderedSet() }
                    altpay={ false }
                    welect={ false }
                    onEvent={ this.handleTestScenarioStepEvent }
                    canDelete={ false }
                    leftCellStyles={ leftCellStyles }
                />
                <tr>
                    <th style={ leftCellStyles }>Then</th>
                    <th style={ leftCellStyles }>{ thenButtons }</th>
                    <th />
                    { this.props.editing && <th /> }
                </tr>
                { this.props.data.then_seq.steps.map(this.renderThenStep) }
                <tr>
                    <th style={ leftCellStyles }>Else</th>
                    <th style={ leftCellStyles }>{ elseButtons }</th>
                    <th />
                    { this.props.editing && <th /> }
                </tr>
                { this.props.data.else_seq.steps.map(this.renderElseStep) }
            </React.Fragment>
        );
    }

    getLeftCellStyles = () => {
        return {
            ...this.props.leftCellStyles,
            position: 'relative',
            left: `${ parseInt(this.props.leftCellStyles.left || 0) + 50 }px`,
        };
    };

    renderAddButton(callback) {
        return (
            <CustomButton
                type={ CustomButton.types.DEFAULT }
                size={ CustomButton.sizes.MEDIUM }
                onClick={ callback }
                className="add-then-else-step"
            >
                +&nbsp;&nbsp;ADD STEP
            </CustomButton>
        );
    }

    handleAddThenStep = () => {
        this.handleAddStep(this.props.data.then_seq.steps, [ 'then_seq', 'steps' ]);
    };

    handleAddElseStep = () => {
        this.handleAddStep(this.props.data.else_seq.steps, [ 'else_seq', 'steps' ]);
    };

    handleAddStep(sequence, keyPath) {
        this.props.onChange(
            this.props.data.setIn(keyPath, sequence.push(new StepRecord())),
        );
    }

    handleTestScenarioStepEvent = (eventType, eventData) => {
        if (eventType === 'stepUpdate') {
            this.props.onChange(
                this.props.data.set('test', eventData),
            );
        }
    };

    renderThenStep = (stepRecord, i, steps) => (
        this.renderStep(steps, [ 'then_seq', 'steps' ], stepRecord, i)
    );

    renderElseStep = (stepRecord, i, steps) => (
        this.renderStep(steps, [ 'else_seq', 'steps' ], stepRecord, i)
    );

    renderStep(sequence, keyPath, stepRecord, i) {
        return (
            <ScenarioStep
                key={ i }
                index={ i }
                total={ sequence.size }
                step={ stepRecord }
                editing={ this.props.editing }
                messages={ this.props.messages }
                onEvent={ (eventType, step) => {
                    this.handleScenarioStepEvent(
                        sequence, keyPath, i, eventType, step);
                }}
                altpay={ this.props.altpay }
                welect={ this.props.welect }
                leftCellStyles={ this.getLeftCellStyles() }
            />
        );
    }

    handleScenarioStepEvent(sequence, keyPath, index, eventType, eventData) {
        if (eventType === "delete") {
            this.props.onChange(
                this.props.data.setIn(keyPath, sequence.delete(index)),
            );
        } else if (eventType === "stepUpdate") {
            this.props.onChange(
                this.props.data.setIn(keyPath, sequence.set(index, eventData)),
            );
        } else if (eventType === "sortUp" || eventType === "sortDown") {
            const targetIndex =
                eventType === "sortUp" ? index - 1 : index + 1;

            this.props.onChange(
                this.props.data.setIn(keyPath, sequence.set(
                    targetIndex,
                    sequence.get(index),
                ).set(
                    index,
                    sequence.get(targetIndex),
                )),
            );
        }
    }

}
