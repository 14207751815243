import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onUpdate: PropTypes.func.isRequired,
        editing: PropTypes.bool.isRequired,
    };

    state = {
        value: this.props.value
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value });
    }

    handleChange = (event) => {
        var valueWithPx = event.target.value + 'px';
        this.setState({ value: valueWithPx });
        this.props.onUpdate(valueWithPx);
    };

    render() {
        return (
            <div className="options-copy-font-size-and-opacity">
              <input data-toggle="tooltip" title="Font size" value={ this.state.value.replace('px', '') } onChange={ this.handleChange }  disabled={ !this.props.editing }/>
            </div>
        );
    }
}
