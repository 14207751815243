import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Table, Collapse, Row, Col, Tabs, Popover } from 'antd';
import moment from 'moment';
import { Map, List, fromJS, is } from 'immutable';
import CSVLink from "../../common/CSVLink.js";
import RecentChangesModal from '../../consent/performanceNew/RecentChangesModal';
import { createCSVExportData } from '../../consent/performanceNew/helper';
import ExportToolTip from './ExportToolTip';
import { ResponsiveContainer, ReferenceLine, Label, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, BarChart, Line, Bar, Legend } from 'recharts';
import { LINE_GRAPH_COLOR_LIST, LINE_GRAPH_STROKE_PATTERN_LIST, LINE_GRAPH_STROKE_WIDTH_LIST, LINE_GRAPH_LEGEND_PATTERN_LIST  } from '../../../constants';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const columnsData = List([ 
  Map({
    title: 'Date',
    dataIndex: 'date',
    sorter: (a, b) => {},
    className: 'left-align',
    width:'10%',
  }),
  Map({
    title: 'Total Page Views',
    dataIndex: 'totalpageviews',
    sorter: (a, b) => {},
    className: 'right-align',
    width:'20%',
  }),
  Map({
    title: 'Messaged Users',
    dataIndex: 'messagedusers',
    sorter: (a, b) => {},
    className: 'right-align',
    width:'20%',
  }),
  Map({
    title: 'Full Consent',
    dataIndex: 'fullconsent',
    sorter: (a, b) => {},
    className: 'right-align',
    width:'15%',
  }),
  Map({
    title: 'Partial Consent',
    dataIndex: 'partialconsent',
    sorter: (a, b) => {},
    className: 'right-align',
    width:'20%',
  }),
  Map({
    title: 'Reject All',
    dataIndex: 'rejectedall',
    sorter: (a, b) => {},
    className: 'right-align',
    width:'15%',
  })
]);

export default class RequestPerformanceReportGraph extends React.PureComponent {

  state = {
    recentChangesModal: false, 
    lineToggle: Map({
      total:true,
      valid:false,
      invalid:false,
      withdue:false,
      overdue:false,
    }),
    propertyTableData: List(),
    rechartMargin: { top: 5, right: 30, left: 20, bottom: 15 },
    tooltipVisibility : 'visible',
    proValConvert: 'number',
    selectedTabIndex:1
  };


  changeLine(){
    this.setState({
        lineToggle:Map({
          total:true,
          valid:false,
          invalid:false,
          withdue:false,
          overdue:false,
        })
    })
  }
 

  toggleLine = (tKey) => {

    let toggles = this.state.lineToggle;
    const inverse = !toggles.get(tKey);
    toggles = toggles.set(tKey, inverse);
    this.setState({lineToggle: toggles});
  };

  setGraphType = (graphType) =>{
    this.setState({graphType});
  }

  itemRender = (current, type, originalElement) =>{
    return originalElement;
  }
  showModal = () => {
    this.setState({
      recentChangesModal: true,
    });
  };
  handleCancel = e => {
      this.setState({
        recentChangesModal: false,
      });
  }; 
  capitalize = (s)=> {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  static getDerivedStateFromProps(nextProps, state) {
  
    let stateData = fromJS(state.propertyTableData);
    stateData = stateData.map((item, i) => {
      let sortedArr = item
        .get("aggData")
        .sort(
          (x, y) =>
            moment(x.get("date"), "YYYY-MM-DD").valueOf() -
            moment(y.get("date"), "YYYY-MM-DD").valueOf()
        );
      item = item.set("aggData", sortedArr);
      return item;
    });
    if (nextProps.consentPropertyData) {
      let propsData = fromJS(
        nextProps.consentPropertyData.map((items, i) =>
          items.get("dataRequestTableData")
        )
      );
      propsData = propsData.map((item, i) => {
        let sortedArr = item
          .get("aggData")
          .sort(
            (x, y) =>
              moment(x.get("date"), "YYYY-MM-DD").valueOf() -
              moment(y.get("date"), "YYYY-MM-DD").valueOf()
          );
        item = item.set("aggData", sortedArr);
        return item;
      });
      let isEqual = is(propsData, stateData);
      if (!isEqual) {
        return {
          propertyTableData: nextProps.consentPropertyData.map((items, i) =>
            items.get("dataRequestTableData")
          )
        };
      }
    }
    return null;
  }
  renderTooltip = (props) => {
    let propertySiteIds;
    let propertyDate;
    let logData;
    let tooltipData;
    if(props.payload && props.payload.length>0 && props.payload[0]){
     
      let arrList = [];
      props.payload.map((item, i)=>{ 
        arrList = arrList.concat(item.payload['siteIds'+i]);
      });
      let uniqueArr = [...new Set(arrList)]; 
      propertySiteIds = uniqueArr;
      propertyDate = props.payload[0].payload.date;
      logData = this.props.changeLogData.filter((item, i) => {
        var common = item.get('siteIds').filter(element => propertySiteIds.includes(element));
        return item.get('date') == moment(propertyDate, 'MMM Do').format('YYYY-MM-DD') && common.length>0;
      });
      tooltipData = logData.get(0);
    }

    if(tooltipData){
      return (
        <div className="tooltip-custom">
          <div className="tooltip-entity-container"><div className="tooltip-entities">Change Type : </div><span>{this.capitalize(tooltipData.get('operationtype'))}</span></div>
          <div className="tooltip-entity-container"><div className="tooltip-entities">Date : </div><span> {tooltipData.get('date')} </span></div>
          <div className="tooltip-entity-container"><div className="tooltip-entities">Entity Type : </div><span>{tooltipData.get('entitytype')}</span> </div>
          <div className="tooltip-entity-container"><div className="tooltip-entities">Entity : </div><span>{tooltipData.get('entitynames')}</span></div>
          <div className="tooltip-entity-container"><div className="tooltip-entities">Properties: </div><span>{tooltipData.get('properties')}</span></div>
          <div className="tooltip-entity-container"><div className="tooltip-entities">User : </div><span>{tooltipData.get('user')}</span></div>
         
        </div>
       );
    }
    
    return null;
  };

  showTooltip = ()=>{
    return (<Tooltip content={this.renderTooltip} filterNull={true} active={false} cursor={false}/>);
  }

  drawGraph = (graphData)=>{

 
    let lineArr = [];
    graphData.map((d, j)=>{
      if(this.state.lineToggle.get('total')){
        lineArr.push(<Line  key={'t-'+j} 
                            dot={false}
                            yAxisId="left"
                            yAxisId={this.state.lineToggle.get('valid')||this.state.lineToggle.get('invalid')||this.state.lineToggle.get('withdue')||this.state.lineToggle.get('overdue')?"right":"left"}
                            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)} 
                            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)} 
                            type="monotone" 
                            // activeDot={{ onClick:this.showModal }} 
                            dataKey={'totalrequest'+j} 
                            stroke={LINE_GRAPH_COLOR_LIST.get(0)} />);
      }
      if(this.state.lineToggle.get('valid')){
        lineArr.push(<Line  key={'v-'+j}  
                            dot={false}
                            yAxisId="left"
                            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)} 
                            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)} 
                            type="monotone" 
                            // activeDot={{ onClick:this.showModal }} 
                            dataKey={'totalvalid'+j} 
                            stroke={LINE_GRAPH_COLOR_LIST.get(1)}/>);
      }
      if(this.state.lineToggle.get('invalid')){
        lineArr.push(<Line  key={'iv-'+j}  
                            dot={false}
                            yAxisId="left"
                            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)} 
                            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)} 
                            type="monotone" 
                            // activeDot={{ onClick:this.showModal }} 
                            dataKey={'totalinvalid'+j} 
                            stroke={LINE_GRAPH_COLOR_LIST.get(2)} />);
      }
      if(this.state.lineToggle.get('withdue')){
        lineArr.push(<Line  key={'wd-'+j} 
                            dot={false}
                            yAxisId="left"
                            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)} 
                            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)} 
                            type="monotone" 
                            dataKey={'totalwithduedate'+j} 
                            // activeDot={{ onClick:this.showModal }} 
                            stroke={LINE_GRAPH_COLOR_LIST.get(3)} />);
      }
      if(this.state.lineToggle.get('overdue')){
        lineArr.push(<Line  key={'od-'+j} 
                            dot={false}
                            yAxisId="left"
                            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)} 
                            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)} 
                            type="monotone" 
                            dataKey={'totaloverdue'+j} 
                            // activeDot={{ onClick:this.showModal }} 
                            stroke={LINE_GRAPH_COLOR_LIST.get(4)} />);
      }
    });
   
    return lineArr;
  }
  onChange = (pagination, filters, sorter) =>{
    let sortPropertyArr = this.props.consentPropertyData.map((items, i)=>items.get('propertyTableData')); 
    switch(sorter.columnKey+'-'+sorter.order) {

      case 'date-ascend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=>{
            return moment(x.get('date'), 'YYYY-MM-DD').valueOf() - moment(y.get('date'), 'YYYY-MM-DD').valueOf();
          });
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'date-descend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=>moment(y.get('date'), 'YYYY-MM-DD').valueOf() - moment(x.get('date'), 'YYYY-MM-DD').valueOf());
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'totalrequest-ascend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> x.get('totalrequest') - y.get('totalrequest'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'totalpageviews-descend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> y.get('totalrequest') - x.get('totalrequest'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;     
      case 'messagedusers-ascend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> x.get('messagedusers') - y.get('messagedusers'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'messagedusers-descend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> y.get('messagedusers') - x.get('messagedusers'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'fullconsent-ascend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> x.get('fullconsent') - y.get('fullconsent'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'fullconsent-descend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> y.get('fullconsent') - x.get('fullconsent'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'partialconsent-ascend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> x.get('partialconsent') - y.get('partialconsent'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'partialconsent-descend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> y.get('partialconsent') - x.get('partialconsent'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'rejectedall-ascend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> x.get('rejectedall') - y.get('rejectedall'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
      case 'rejectedall-descend':
        sortPropertyArr = sortPropertyArr.map((item, i)=>{
          let sortedArr = item.get('aggData').sort((x,y)=> y.get('rejectedall') - x.get('rejectedall'));
          item = item.set('aggData', sortedArr);
          return item;
        });
        break;
    }
    
    this.setState({
      propertyTableData : sortPropertyArr,
    });
  }
  dataFormater = (number) => {
    return number+'%';
  } 
  setPropertyValConvert = (value) => {
    this.setState({ 'proValConvert': value });
  }

  

onChangeTab = (index) => {
    this.setState({selectedTabIndex:index})
    
  }


  renderSubTabs = (section,tab) => {
    let view = (tab === '2' || tab === '1' )?  
    (<React.Fragment>
    <div className={`property-params ${this.state.lineToggle.get('valid')?'toggle-line2-active':'toggle-line2'}`} onClick={()=>this.toggleLine('valid')} >Valid Request</div>
    <div className={`property-params ${this.state.lineToggle.get('invalid')?'toggle-line3-active':'toggle-line3'}`} onClick={()=>this.toggleLine('invalid')}>Invalid Request</div></React.Fragment>) : null
     if(section === 'DSAR'){
         return (
             <React.Fragment>
              <div className={`property-params ${this.state.lineToggle.get('total')?'toggle-line1-active':'toggle-line1'}`} onClick={()=>this.toggleLine('total')} >Total Request</div>
              {view}
              <div className={`property-params ${this.state.lineToggle.get('withdue')?'toggle-line4-active':'toggle-line4'}`} onClick={()=>this.toggleLine('withdue')} >Within due</div>
              <div className={`property-params ${this.state.lineToggle.get('overdue')?'toggle-line5-active':'toggle-line5'}`} onClick={()=>this.toggleLine('overdue')} >Over Due</div>
            </React.Fragment>
         )
     }else{
      
      return (
           <React.Fragment>
           <div className={`property-params ${this.state.lineToggle.get('total')?'toggle-line1-active':'toggle-line1'}`} onClick={()=>this.toggleLine('total')} >Total Request</div>
            {view}
            </React.Fragment>
      )
     }
  } 
    render(){
        var tableData = this.props.consentPropertyData.map((items, i)=> items.get('dataRequestTableData'));
      
        var graphData = this.props.consentPropertyData.map((items, i)=> items.get('dataGraph'));
    
        
        const exportData =
          tableData.size > 0 && tableData != null
            ? tableData
                .filter((value, i) => i === this.state.selectedTabIndex - 1)
                .map((item, i) => item.get("exportData"))
                .get(0)
            : null;
    
      
        var columnsDSAR;
        
        if(this.props.selectedTab === '2' || this.props.selectedTab === '1'){
          columnsDSAR = List([ 
            Map({
              title: 'Date',
              dataIndex: 'date',
              sorter: (a, b) => moment(a.date, 'YYYY-MM-DD').valueOf()-moment(b.date, 'YYYY-MM-DD').valueOf(),
              className: 'center',
              width:'10%',
            }),
            Map({
              title: 'Total Requests',
              dataIndex: 'totalrequest',
              sorter: (a, b) => a.totalrequest - b.totalrequest,
              className: 'center',
              width:'20%',
              render: text => <span>{text.toLocaleString()}</span>
            }),
            Map({
              title: 'Valid Requests',
              dataIndex: 'validrequest',
              sorter: (a, b) => a.validrequest - b.validrequest,
              className: 'center',
              width:'20%',
              render: text => <span>{text.toLocaleString()}</span>
            }),
            Map({
              title: 'Invalid Requests',
              dataIndex: 'invalidrequest',
              sorter: (a, b) => a.invalidrequest - b.invalidrequest,
              className: 'center',
              width:'15%',
              render: (text) => this.state.proValConvert == 'number' ? text.toLocaleString() : text + '%',
            }),
            
            Map({
              title: 'Within due',
              dataIndex: 'withindue',
              sorter: (a, b) => a.withindue - b.withindue,
              className: 'center',
              width:'15%',
              render: (text) => this.state.proValConvert == 'number' ? text.toLocaleString() : text + '%',
            }),
            Map({
              title: 'Over Due',
              dataIndex: 'overdue',
              sorter: (a, b) => a.overdue - b.overdue,
              className: 'center',
              width:'15%',
              render: (text) => this.state.proValConvert == 'number' ? text.toLocaleString() : text + '%',
            }),
           
          ]);
        }else{
          columnsDSAR = List([ 
            Map({
              title: 'Date',
              dataIndex: 'date',
              sorter: (a, b) => moment(a.date, 'YYYY-MM-DD').valueOf()-moment(b.date, 'YYYY-MM-DD').valueOf(),
              className: 'center',
              width:'10%',
            }),
            Map({
              title: 'Total Requests',
              dataIndex: 'totalrequest',
              sorter: (a, b) => a.totalrequest - b.totalrequest,
              className: 'center',
              width:'20%',
              render: text => <span>{text.toLocaleString()}</span>
            }),
            
            Map({
              title: 'Within due',
              dataIndex: 'withindue',
              sorter: (a, b) => a.withindue - b.withindue,
              className: 'center',
              width:'15%',
              render: (text) => this.state.proValConvert == 'number' ? text.toLocaleString() : text + '%',
            }),
            Map({
              title: 'Over Due',
              dataIndex: 'overdue',
              sorter: (a, b) => a.overdue - b.overdue,
              className: 'center',
              width:'15%',
              render: (text) => this.state.proValConvert == 'number' ? text.toLocaleString() : text + '%',
            }),
           
          ]);
        }
   
        
        var columns = List([ 
          Map({
            title: 'Date',
            dataIndex: 'date',
            sorter: (a, b) => moment(a.date, 'YYYY-MM-DD').valueOf()-moment(b.date, 'YYYY-MM-DD').valueOf(),
            className: 'center',
            width:'10%',
          }),
          Map({
            title: 'Total Requests',
            dataIndex: 'totalrequest',
            sorter: (a, b) => a.totalrequest - b.totalrequest,
            className: 'center',
            width:'20%',
            render: text => <span>{text.toLocaleString()}</span>
          }),
          Map({
            title: 'Valid Requests',
            dataIndex: 'validrequest',
            sorter: (a, b) => a.validrequest - b.validrequest,
            className: 'center',
            width:'20%',
            render: text => <span>{text.toLocaleString()}</span>
          }),
          Map({
            title: 'Invalid Requests',
            dataIndex: 'invalidrequest',
            sorter: (a, b) => a.invalidrequest - b.invalidrequest,
            className: 'center',
            width:'15%',
            render: (text) => this.state.proValConvert == 'number' ? text.toLocaleString() : text + '%',
          }),
         
        ]);

      

        
       
       
        let graphList = List();
        let unionList = List();
        let unionList_all = List()
        let allRequestData = List();
       
        let index = 0;
        let groupedByDates_All,groupedByDates = List();


        
        graphData.map((item,i) => {

          groupedByDates_All = item.groupBy(entry => entry.get('date'))
         
          groupedByDates_All.map((p,i) => {
            let date = i
            let index = graphData.size - 1;
            let totalrequest = p.map((entry) => Number(entry.get('totalrequest'+index))).reduce((prev, current) => prev + current);
            let totalvalid = p.map((entry) => Number(entry.get('totalvalid'+index))).reduce((prev, current) => prev + current);
            let totalinvalid = p.map((entry) => Number(entry.get('totalinvalid'+index))).reduce((prev, current) => prev + current);
            let siteIds = p.map(entry => [(entry.get('siteIds'+index))] ).reduce((prev, current) => prev.concat(current))
  
            allRequestData =  allRequestData.push(Map({
              date:date,
              fancyDate: moment(date, "YYYY-MM-DD").format("MMM Do"),
              ['totalrequest'+index]:totalrequest,
              ['totalvalid'+index]: totalvalid,
              ['totalinvalid'+index]: totalinvalid,
              ['siteIds'+index]: siteIds
            
          }));
          })
          
        })
      
        
       
        graphData.map((g, i)=>{
          unionList = unionList.toSet().union(g.toSet()).toList();
        });
      
        let groupedByDates_DSAR = unionList.filter((entry) => entry.get('requestType') === 'DSAR')
        
       
        let groupedByDates_DNS = unionList.filter((entry) => entry.get('requestType') === 'dns')
        let groupedByDates_Delete = unionList.filter((entry) => entry.get('requestType') === 'DELETE')
       
        switch(this.props.section){
          case 'All':
              groupedByDates = allRequestData.groupBy((dataPoint) => dataPoint.get('date'));
             
          break;
          case 'DSAR':
              groupedByDates = groupedByDates_DSAR.groupBy((dataPoint) => dataPoint.get('date'));;
          break;
          case 'Do not sell':
              groupedByDates = groupedByDates_DNS.groupBy((dataPoint) => dataPoint.get('date'));;
          break;
          case 'Delete my data':
              groupedByDates = groupedByDates_Delete.groupBy((dataPoint) => dataPoint.get('date'));;
          break;
          default:
              switch(this.props.selectedDefaultTab){
                case 'All':
                    groupedByDates = allRequestData.groupBy((dataPoint) => dataPoint.get('date'));
                   
                break;
                case 'DSAR':
                    groupedByDates = groupedByDates_DSAR.groupBy((dataPoint) => dataPoint.get('date'));;
                break;
                case 'Do not sell':
                    groupedByDates = groupedByDates_DNS.groupBy((dataPoint) => dataPoint.get('date'));;
                break;
                case 'Delete my data':
                    groupedByDates = groupedByDates_Delete.groupBy((dataPoint) => dataPoint.get('date'));;
                break;
                default:
                    groupedByDates = allRequestData.groupBy((dataPoint) => dataPoint.get('date'));
                   
                    break;
              }
              
            break;
        }
       
      
        groupedByDates.map((item, i )=>{
          let mergeMap = Map();
          item.map((m,j)=>{ mergeMap = mergeMap.merge(m);});
          graphList = graphList.push(mergeMap);
        });
       
        graphList = graphList.sort(
          (a, b) =>
            moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
        );
      
        
        let graphTitle = graphList.size?moment(graphList.first().get('date'), 'MMM Do').format('MMMM YYYY'):'';
        const renderLegend = () => {
          return (
            <React.Fragment>
              {
                graphData.map((entry, index) => (
                  <div className="legend-custom" key={`legend-${index}`}>
                  <svg height="5" width="28">
                    <line
                      x1="0"
                      y1="0"
                      x2="200"
                      y2="0"
                      style={{
                        stroke: "#555758",
                        strokeWidth: "5",
                        strokeDasharray: LINE_GRAPH_STROKE_PATTERN_LIST.get(index)
                      }}
                    />
                  </svg>
                  {` Criteria ${index + 1}`}
                </div>
    
                ))
              }
            </React.Fragment>
          );
        }

        const headerCustom = (heading,data,requestHeaders) =>{
          return (
            <span>
            {data.get('date')} 
            <CSVLink data={data.get('aggData').toJS()} 
                    filename={"request.csv"} 
                    headers={requestHeaders.toJS()}>
                    <DownloadOutlined /></CSVLink>
            </span>
          );
        }
        

        const tableTitle = (<div className="table-title-container">
                              <div className="table-title-convert">
                                <button className={this.state.proValConvert == 'number' ? 'active' : ''} onClick={() => this.setPropertyValConvert('number')}>Number</button>
                                <button className={this.state.proValConvert == 'percentage' ? 'active' : ''} onClick={() => this.setPropertyValConvert('percentage')}>Percentage</button>
                              </div>
                            </div>);


       

        let requestHeaders = ''
        

        if(this.props.section === 'DSAR' || this.props.section === 'All'){
          requestHeaders = columnsDSAR
          .map(item =>
            Map({ label: item.get("title"), key: item.get("dataIndex") })
          )
          .insert(3, Map({ label: "valid request %", key: "validrequestper" }))
          .insert(5, Map({ label: "Invalid request %", key: "invalidrequestper" }))
          .insert(9, Map({ label: "Over Due  %", key: "overdueper" }))
          .insert(7, Map({ label: "Within due  %", key: "withindueper" }));
        
          
        }else{
          requestHeaders = columns
          .map(item =>
            Map({ label: item.get("title"), key: item.get("dataIndex") })
          )
          .insert(3, Map({ label: "valid request %", key: "validrequestper" }))
          .insert(5, Map({ label: "Invalid request %", key: "invalidrequestper" }))
        }


       
        return (
          <React.Fragment>
            <div className="property-tabs"> 
             
              
             {this.renderSubTabs(this.props.section,this.props.selectedTab+'')}
              <div className="property-action"><a onClick={this.showModal}>View Recent Changes <span className="glyphicon glyphicon-menu-right consent-right-arrow"></span></a></div>
            </div>
            <div className="property-title-container">
              <div className="graph-desc-container"> 
                <span className="property-title">Request Performance</span><br/>
                <span className="property-desc">Trend over time, can be viewed on monthly or weekly basis.</span>
              </div>
            </div>

            <ResponsiveContainer width='99%' height={395}>
              <LineChart 
              width={730} 
              height={250} 
              data={graphList.toJS()}
              margin={this.state.rechartMargin}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="fancyDate">
                    <Label value={graphTitle} offset={-10} position="insideBottom" />
                  </XAxis>
                  <YAxis type="number" yAxisId="left" domain={[0, 'dataMax']} tickFormatter={this.state.lineToggle.get('valid')||this.state.lineToggle.get('invalid')||this.state.lineToggle.get('withdue')?this.dataFormater:null}/>
                  <YAxis type="number" yAxisId="right" orientation="right" domain={[0, 'dataMax']}/>
                  {this.showTooltip()}
                  {/* <ReferenceLine x={moment().format('MMM Do')} stroke="#000" isFront={true} label="Today" strokeDasharray="1 1" /> */}
                  <Legend verticalAlign="top" content={renderLegend} />
                  {this.drawGraph(graphData)}
              </LineChart>
            </ResponsiveContainer>
          {(!this.props.firstLoad)?  tableTitle : null}
           
           <div className="data-request-tab-container">
           <Tabs type="card" defaultActiveKey="1"  onChange={this.onChangeTab}  tabBarExtraContent=
           {(tableData.size>1 && !this.props.firstLoad )?<span style={{marginRight:"20px"}}>
                <ExportToolTip
                exportText="Download"
                filename="Subject Access Request Details"
                selectedTabIndex={this.state.selectedTabIndex}
                exportData={createCSVExportData(
                  requestHeaders,
                  exportData
                ).toJS()}
                criterialist={this.props.criteriaList}
                sites={this.props.sites}
                pdfTitle={"Subject Access Request Details"}
                
                /></span>:null}>
           { tableData.size > 1 && !this.props.firstLoad && tableData.map((item, i)=>{ 
                let data_dsar = ""
                let data_dns = ""
                let data_delete = ""
                let total_request_data = ""
                let percentage_dsar = ""
                let percentage_dns = ""
                let percentage_delete = ""
                let data = item.get('aggData')
                
                let dsar_array = data.filter((entry) => entry.get('requestType') === 'DSAR')
                let dns_array = data.filter((entry) => entry.get('requestType') === 'dns')
                let delete_array = data.filter((entry) => entry.get('requestType') === 'DELETE')

               percentage_dsar = dsar_array.map((c, j) => {
                    let n = c;
                    n = c.set('withindue', 
                    Number(((c.get('withindue') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('totalrequest', c.get('totalrequest').toFixed(2))
                    .set('overdue', Number(((c.get('overdue') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('invalidrequest', Number(((c.get('invalidrequest') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('validrequest', Number(((c.get('validrequest') / c.get('totalrequest')) * 100).toFixed(2)));
                    return n;
                  });
                  percentage_dns = dns_array.map((c, j) => {
                    let n = c;
                    n = c
                    .set('totalrequest', c.get('totalrequest').toFixed(2))
                    .set('invalidrequest', Number(((c.get('invalidrequest') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('validrequest', Number(((c.get('validrequest') / c.get('totalrequest')) * 100).toFixed(2)));
                    return n;
                  });
                  percentage_delete = delete_array.map((c, j) => {
                    let n = c;
                    n = c
                    .set('totalrequest', c.get('totalrequest').toFixed(2))
                    .set('invalidrequest', Number(((c.get('invalidrequest') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('validrequest', Number(((c.get('validrequest') / c.get('totalrequest')) * 100).toFixed(2)));
                    return n;
                  });
                

                switch(this.props.section){
                  case 'All':
                      data_dsar = this.state.proValConvert == 'number' ? dsar_array.toJS() : percentage_dsar.toJS();
                      data_dns = this.state.proValConvert == 'number' ? dns_array.toJS() : percentage_dns.toJS();
                      data_delete = this.state.proValConvert == 'number' ? delete_array.toJS() : percentage_delete.toJS();
                   break;
                   case 'DSAR':
                      total_request_data = this.state.proValConvert == 'number' ? dsar_array.toJS() : percentage_dsar.toJS();
                   break;
                   case 'Do not sell':
                      total_request_data = this.state.proValConvert == 'number' ? dns_array.toJS() : percentage_dns.toJS();
                   break;
                   case 'Delete my data':
                      total_request_data = this.state.proValConvert == 'number' ? delete_array.toJS() : percentage_delete.toJS();
                   break;
                   default:
                     break;
                }
                
             
                
                const headerCustom = (<span>{item.get('date')}</span>);
                return(
                
                    <TabPane tab={headerCustom} key={i+1}>
                      {(this.props.section === 'All') ? (
                      <div className="collapsibleTable">
                      <Collapse defaultActiveKey={['1']} bordered={false}>
                      <Panel header={"DSAR"} key={i+1}>
                       <Table columns={columnsDSAR.toJS()} 
                        dataSource={data_dsar} 
                        pagination={{  itemRender:this.itemRender, size:"small", defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], locale: {items_per_page: ""} }}
                        className="consnet-table"
                        rowKey={r => r.key}/>
                      </Panel>
                      <Panel header={"Do not Sell"} key={i+2}>
                      <Table columns={columns.toJS()} 
                      dataSource={data_dns} 
                       pagination={{  itemRender:this.itemRender, size:"small", defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], locale: {items_per_page: ""} }}
                       className="consnet-table"
                      rowKey={r => r.key}/>
                    </Panel>
                    <Panel header={"Delete My data"} key={i+3}>
                    <Table columns={columns.toJS()} 
                    dataSource={data_delete} 
                    pagination={{  itemRender:this.itemRender, size:"small", defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], locale: {items_per_page: ""} }}
                    className="consnet-table"
                    rowKey={r => r.key}/>
                    </Panel>
                    </Collapse>
                      </div>
                      ):(
                        <div className="collapsibleTable">
                        <Collapse defaultActiveKey={['1']} bordered={false}>
                        <Panel header={this.props.section} key={1}>
                       <Table columns={(this.props.section === 'DSAR') ? columnsDSAR.toJS() : columns.toJS()} 
                                 dataSource={total_request_data} 
                                 pagination={{  itemRender:this.itemRender, size:"small", defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], locale: {items_per_page: ""} }}
                                 className="consnet-table"
                                 rowKey={r => r.key}/>
                       </Panel>
                       </Collapse>
                       </div>
                      )}
                   
                  </TabPane>)
                })}
               
            </Tabs>
            { tableData.size === 1 && !this.props.firstLoad && tableData.map((item, i)=>{ 
                  
                  
                  let data_dsar = ""
                  let data_dns = ""
                  let data_delete = ""
                  let total_request_data = ""
                  let percentage_dsar = ""
                  let percentage_dns = ""
                  let percentage_delete = ""
                  let data = item.get('aggData')
                 

                  let dsar_array = data.filter((entry) => entry.get('requestType') === 'DSAR')
                  let dns_array = data.filter((entry) => entry.get('requestType') === 'dns')
                  let delete_array = data.filter((entry) => entry.get('requestType') === 'DELETE')

                  percentage_dsar = dsar_array.map((c, j) => {
                    let n = c;
                    n = c.set('withindue', 
                    Number(((c.get('withindue') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('totalrequest', c.get('totalrequest').toFixed(2))
                    .set('overdue', Number(((c.get('overdue') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('invalidrequest', Number(((c.get('invalidrequest') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('validrequest', Number(((c.get('validrequest') / c.get('totalrequest')) * 100).toFixed(2)));
                    return n;
                  });
                  percentage_dns = dns_array.map((c, j) => {
                    let n = c;
                    n = c
                    .set('totalrequest', c.get('totalrequest').toFixed(2))
                    .set('invalidrequest', Number(((c.get('invalidrequest') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('validrequest', Number(((c.get('validrequest') / c.get('totalrequest')) * 100).toFixed(2)));
                    return n;
                  });
                  percentage_delete = delete_array.map((c, j) => {
                    let n = c;
                    n = c
                    .set('totalrequest', c.get('totalrequest').toFixed(2))
                    .set('invalidrequest', Number(((c.get('invalidrequest') / c.get('totalrequest')) * 100).toFixed(2)))
                    .set('validrequest', Number(((c.get('validrequest') / c.get('totalrequest')) * 100).toFixed(2)));
                    return n;
                  });
                 
                 

                  switch(this.props.section){
                    case 'All':
                        data_dsar = this.state.proValConvert == 'number' ? dsar_array.toJS() : percentage_dsar.toJS();
                        data_dns = this.state.proValConvert == 'number' ? dns_array.toJS() : percentage_dns.toJS();
                        data_delete = this.state.proValConvert == 'number' ? delete_array.toJS() : percentage_delete.toJS();
                     break;
                     case 'DSAR':
                        total_request_data = this.state.proValConvert == 'number' ? dsar_array.toJS() : percentage_dsar.toJS();
                     break;
                     case 'Do not sell':
                        total_request_data = this.state.proValConvert == 'number' ? dns_array.toJS() : percentage_dns.toJS();
                     break;
                     case 'Delete my data':
                        total_request_data = this.state.proValConvert == 'number' ? delete_array.toJS() : percentage_delete.toJS();
                     break;
                     default:
                       break;
                  }

                  
                  const headerCustom = (<span style={{marginLeft:"20px",fontSize:"16px", fontFamily: 'Proxima-Nova'}}>
                  <ExportToolTip
                  exportText="All Request"
                  filename="Subject Access Request Details"
                  selectedTabIndex={this.state.selectedTabIndex}
                  exportData={createCSVExportData(
                    requestHeaders,
                    exportData
                  ).toJS()}
                  pdfTitle={"Subject Access Request Details"}
                  criterialist={this.props.criteriaList}
                  sites={this.props.sites}
                  /></span>);
                  const view = (this.props.section === 'All') ? 
                  ( 
                 <div style={{marginTop:"20px"}}>
                  <span>{headerCustom}</span>
                  <div className="collapsibleTable">
                  <Collapse defaultActiveKey={['1']} bordered={false}>
                  <Panel header={"DSAR"} key={i+1}>
                  <Table columns={columnsDSAR.toJS()} 
                            dataSource={data_dsar} 
                            pagination={{  itemRender:this.itemRender, size:"small", defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], locale: {items_per_page: ""} }}
                            className="consnet-table"
                            rowKey={r => r.key}/>
                  </Panel>
                  <Panel header={"Do not Sell"} key={i+2}>
                  <Table columns={columns.toJS()} 
                            dataSource={data_dns} 
                            pagination={{  itemRender:this.itemRender, size:"small", defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], locale: {items_per_page: ""} }}
                            className="consnet-table"
                            rowKey={r => r.key}/>
                  </Panel>
                  <Panel header={"Delete My data"} key={i+3}>
                  <Table columns={columns.toJS()} 
                            dataSource={data_delete} 
                            pagination={{  itemRender:this.itemRender, size:"small", defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], locale: {items_per_page: ""} }}
                            className="consnet-table"
                            rowKey={r => r.key}/>
                  </Panel>
                  </Collapse>
              
                  </div></div>) : (
                   <div>
                  
                   <div className="collapsibleTable">
                   <Collapse defaultActiveKey={['1']} bordered={false}>
                  <Panel header={<span>{this.props.section} <CSVLink data={total_request_data} filename={"request.csv"} headers={requestHeaders.toJS()}><DownloadOutlined /></CSVLink></span>} key={1}>
                  <Table columns={(this.props.section === 'DSAR') ? columnsDSAR.toJS() : columns.toJS()} 
                            dataSource={total_request_data} 
                            pagination={{  itemRender:this.itemRender, size:"small", defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'], locale: {items_per_page: ""} }}
                            className="consnet-table"
                            rowKey={r => r.key}/>
                  </Panel>
                  </Collapse>
                  </div>
                  </div>
                  )
                 return view;
                })}
           </div>

            <RecentChangesModal changeLogData={this.props.changeLogData} recentChangesModal={this.state.recentChangesModal} handleCancel={this.handleCancel}/>
          </React.Fragment>
        );
    }
}