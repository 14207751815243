import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Tooltip } from 'antd';

export default function (props) {
  const infoText = <div className={classNames('flex-row', (props.color ? 'tooltip-color' : null))}><span className='icon green'>TP</span><p>: True Positive</p><span className='icon green'>TN</span><p>: True Negative</p><span className='icon green'>TU</span><p>: True Undetermined</p><span className='icon red'>FP</span><p>: False Positive</p><span className='icon red'>FN</span><p>: False Negative</p><span className='icon red'>FU</span><p>: False Undetermined</p></div>;
  const tooltipText = (
    <div className='tultip'>
      <p className='tultip-title'>True Positive</p>
      <p>When the scoring says the rule is passed and the golden set has the rule passing</p>
      <p className='tultip-title'>True Negative</p>
      <p>When the scoring says the rule failed and the golden set has the rule failed</p>
      <p className='tultip-title'>True Undetermined</p>
      <p>When the scoring says the rule is undetermined and the golden set has the rule undetermined</p>

      <p className='tultip-title'>False Positive</p>
      <p>When the scoring says the rule is passed and the golden set has the rule failing or undetermined</p>
      <p className='tultip-title'>False Negative</p>
      <p>When the scoring says the rule failed and the golden set has the rule passing or undetermined</p>
      <p className='tultip-title'>False Undetermined</p>
      <p>When the scoring says the rule is undetermined and the golden set has the rule passing or failing</p>
    </div>
  );
  return (
    <div className='golden-set-tooltip-wrapper'>
      { infoText }
      <Tooltip title={ tooltipText } placement="left" overlayClassName='golden-set-tooltip' >
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
}