import PropTypes from 'prop-types';
import React from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/css';
import 'brace/theme/twilight';
import 'brace/ext/searchbox';


export default class extends React.Component {
  state = {
    isResizing: false,
    lastDownY: 0,
    height: 300,
  }

  handleMousedown = e => {
    this.setState({ isResizing: true, lastDownX: e.clientY });
  }

  handleMousemove = e => {
    // we don't want to do anything if we aren't resizing.
    if (!this.state.isResizing) return;
    let offSetBottom = document.body.offsetHeight - e.clientY;
    let minHeight = 50;
    let maxHeight = 400;
    if (offSetBottom > minHeight && offSetBottom < maxHeight) {
      this.setState({ height: offSetBottom });
    }
  }

  handleMouseup = e => {
    this.setState({ isResizing: false });
  }

  componentDidMount() {
    document.addEventListener('mousemove', e => this.handleMousemove(e));
    document.addEventListener('mouseup', e => this.handleMouseup(e));
  }

  render() {

    const { height } = this.state;
    const { value, updateCSS } = this.props;

    return (
      <div className='custom-css'>
        <div className='custom-css-editor'>
          <div style={{ width: '100%', height: '20px' }} />
          <div id="custom-css-dragger" onMouseDown={this.handleMousedown} />
          <AceEditor
            ref={AceEditor => { this.customEditor = AceEditor; }}
            value={value}
            mode='css'
            theme='twilight'
            readOnly={ false }
            width='100%'
            height={`${height}px`}
            focus={true}
            onChange={updateCSS}
          />
        </div>
      </div>
    );
  }
}
