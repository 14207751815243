import Immutable, { Record, List } from 'immutable';

export const Site = Record({
  id: null,
  domain: '',
  type: '',
  createdAt: '',
  multiCampaignEnabled: false,
  publicCampaignTypePriority: List([]),
  publicMessageLimit: 1,
  stageCampaignTypePriority: List([]),
  stageMessageLimit: 1
});

export class SiteGroup extends Record({
  siteIds: List([]),
  id: '',
  accountId: '',
  name: '',
  siteId: null,
  description: '',
  associations: List([]),
  multiCampaignEnabled: false,
}) {
  constructor(sg) {
    const updatedSG = Object.assign({}, sg);
    updatedSG.id = sg._id;
    super(Immutable.fromJS(updatedSG));
  }
}

export const SiteWithErrors = new Record({
  siteId: '',
});

export const PropertyFilter = Record({
  type: '',
  createdBy: '',
  timespan: '',
  sortBy: '',
  language: '',
});