import * as types from "./action_types";
import { actionCreator } from "./helpers";
import { getAndParseCcpaDnsReportingData } from "../api/ccpa/ccpa_dns_actions_reporting";

export const getCcpaDnsReportingAction = actionCreator(
  getAndParseCcpaDnsReportingData,
  ccpaReportingPending,
  ccpaReportingLoaded,
  ccpaReportingError,
  ccpaReportingCached
);

function ccpaReportingPending() {
  return {
    type: types.CCPA_REPORTING_PENDING
  };
}

function ccpaReportingLoaded(data) {
  return {
    type: types.CCPA_REPORTING_LOADED,
    data
  };
}

function ccpaReportingError(error) {
  return {
    type: types.CCPA_REPORTING_ERROR,
    error
  };
}

function ccpaReportingCached() {
  return {
    type: types.CCPA_REPORTING_CACHED
  };
}
