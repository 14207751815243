
import React, { useState } from 'react';
import { List } from 'immutable';
import classNames from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal, Button, notification, Collapse, Select } from 'antd';
import { Input } from '../../../../../styleguide'

import { PurposeV2 as Purpose, PurposeV2 } from '../../../../../records/vendor_list_records';
import { useSelector } from 'react-redux';
import { addIabDeclaredCookiesToPurpose, getVendorIdsForPurpose } from '../wizard/helper';

const { Panel } = Collapse;
const { confirm } = Modal;

const CustomStacksTab = ({ vendorList, updateCategoriesLocally, iabPurposes, isNonIab, validateHandler, iabPurposesNotInThelist }) => {
  const [newStackName, setNewStackName] = useState('')
  const [newStackDescription, setNewStackDescription] = useState('')
  const [customPurposesCreatedAndNotAddedToStack, setCustomPurposesCreatedAndNotAddedToStack] = useState(List([]))
  const [customPurposeName, setCustomPurposeName] = useState('')
  const [purposesToAddToNewStack, setPurposesToAddToNewStack] = useState(List([]))
  const [editingStacks, setEditingStacks] = useState(List([]))
  const [error, setError] = useState(null)

  const iabCustomStackEnabled = useSelector(({ accountState }) => {
    const { accountFeatures, accountId } = accountState.toJS().userDetails.value
    return accountFeatures.includes('iab_custom_stack') || accountId === 22
  });
  
  const updateStack = (stack, oldStackName) => {
    //TODO: change search by name by clientSideId
    const stackIdxInCategories = vendorList.get('categories').findIndex(c => c.name === oldStackName);
    const updatedCategories = vendorList.setIn(['categories', stackIdxInCategories], stack).get('categories')
    updateCategoriesLocally(rearrangeCategories(stack, updatedCategories));
  }

  const deleteStack = (e, stack) => {
    e.stopPropagation();
    confirm({
      title: `Are you sure you want to delete custom stack ${stack.name}?`,
      content: 'Custom purposes from this stack will be added to the vendor list',
      onOk: () => {
        updateCategoriesLocally(vendorList.categories
          .filterNot(c => c.name === stack.name)
          .concat(stack.categories));
      },
      onCancel() { },
    });
  }

  const createCustomPurpose = () => {
    const isValiadate = validateHandler(customPurposeName);
    if(!isValiadate) {
       const newPurpose = new Purpose({ id: `temp-${new Date().getTime()}`, name: customPurposeName, type: 'CUSTOM' })
      setPurposesToAddToNewStack(purposesToAddToNewStack.push(newPurpose))
      setCustomPurposeName('')
      setError(null)
    } else setError(isValiadate)
  }

  const addCustomStack = () => {
    if (!newStackName || !purposesToAddToNewStack.size) {
      if (!newStackName) {
        notification.error({
          message: 'assign a name to custom stack before creating',
          duration: 4,
        });
      }

      if (!purposesToAddToNewStack.size) {
        notification.error({
          message: 'select at least one custom purpose to add to the custom stack before creating',
          duration: 4,
        });
      }
      return;
    }


    const stack = new Purpose({
      name: newStackName,
      description: newStackDescription,
      categories: purposesToAddToNewStack,
      type: 'CUSTOM_STACK',
    });

    let customPurposesCreatedAndNotAddedToStack = List([]);
    customPurposesCreatedAndNotAddedToStack.forEach(p => {
      if (!purposesToAddToNewStack.map(p => p.name).includes(p.name)) {
        customPurposesCreatedAndNotAddedToStack = customPurposesCreatedAndNotAddedToStack.push(p);
      }
    });

    setNewStackName('')
    setNewStackDescription('')
    setCustomPurposeName('')
    setPurposesToAddToNewStack(List([]))
    setCustomPurposesCreatedAndNotAddedToStack(customPurposesCreatedAndNotAddedToStack)

    //TODO: change filter by name by client side id
    const filteredCategories = vendorList.categories.filterNot(category => purposesToAddToNewStack.map(c => c.name).includes(category.name));
    updateCategoriesLocally(filteredCategories.push(stack));
  }

  const rearrangeCategories = (stack, updatedCategories) => {
    const filteredCategories = updatedCategories.toJS().filter(({ name }) => !stack.toJS().categories.find((c) => c.name === name))
    return new List(filteredCategories.map(c => new PurposeV2(c)))
  }

  const addOrRemovePurposeFromStack = (inStack, stackIdx) => {
    let existingCategories = vendorList.categories;
    let newPurposesToAddToNewStack = purposesToAddToNewStack;
    let allPurposes = customPurposesCreatedAndNotAddedToStack
      .concat(existingCategories)
      .concat(purposesToAddToNewStack)
      .concat(
        iabPurposesNotInThelist.map((p) => {
          if (p.id === p.iabPurposeRef) {
            return p
              .set("id", null)
              .set("vendorIds", getVendorIdsForPurpose(vendorList.vendors, p))
              .set(
                "vendorCategorization",
                addIabDeclaredCookiesToPurpose(
                  vendorList.vendors,
                  p,
                  vendorList.defaultLegalBasis
                )
              );
          } else {
            return p;
          }
        })
      );

    if (stackIdx === 'new') {
      newPurposesToAddToNewStack = List(inStack).map(name => allPurposes.find(p => p.name === name)).filter(value => !!value)
      setPurposesToAddToNewStack(newPurposesToAddToNewStack)
    } else {
      let updatingStack = vendorList.categories.filter(c => c.type === 'CUSTOM_STACK').get(stackIdx);
      allPurposes = allPurposes.concat(updatingStack.categories);
      const purposeToDelete = updatingStack.categories.find(c => !inStack.includes(c.name));
      if (purposeToDelete) {
        existingCategories = existingCategories.push(purposeToDelete);
      }

      updatingStack = updatingStack.set('categories', List(inStack).map(name => allPurposes.find(p => p.name === name)).filter(value => !!value));
      if(updatingStack?.categories?.size === 0 ) {
        notification.error({
          message: 'A Stack should have at least ONE purpose',
          duration: 4,
        });
        return;
      }
      const rearrangedCategories = rearrangeCategories(updatingStack, existingCategories)
      const stackIdxForAllStacks = rearrangedCategories.findIndex(stack => { return stack.name === updatingStack.name });
      updateCategoriesLocally(rearrangedCategories.set(stackIdxForAllStacks, updatingStack));
    }
  }

  const purposesToSelect = vendorList
    .categories
    .filter(c => c.type === 'CUSTOM')
    .concat(customPurposesCreatedAndNotAddedToStack);
  const noStacksMessage = vendorList.categories.filter(c => c.type === 'CUSTOM_STACK').size
    ? null
    : (
      <p className='no-iab-stacks-added'>
        There are no custom stacks in this vendor list
      </p>
    )

  const isConflictingPurpose = (purposeName, stackId) => {
    return !!vendorList.toJS().categories.find((category) => {
      if ((category.type === 'IAB_STACK' || category.type === 'CUSTOM_STACK') && (!stackId || category.id !== stackId)) {
        return category.categories.find(({ name }) => name === purposeName)
      }
    })
  }

  return (
    <React.Fragment>
      <div className='title'>Custom Stacks in your list</div>
      {noStacksMessage}
      <div>
        <Collapse
          defaultActiveKey={[]}
          onChange={(editingStacks) => setEditingStacks(List(editingStacks))}
        >
          {vendorList.categories
            .filter(c => c.type === 'CUSTOM_STACK')
            .map((stack, i) => {
              const editing = editingStacks.includes(i.toString());
              let header;

              header = (
                <div style={{ width: '100%' }}>
                  <div className='stack-container custom' style={{ flexDirection: 'row' }}>
                    <div>
                      <b>Name:</b> {editing
                        ? (
                          <Input
                            style={{ marginLeft: 5, width: 200 }}
                            value={stack.name}
                            onChange={(e) => updateStack(stack.set('name', e.target.value), stack.name)}
                          />
                        )
                        : stack.name}
                    </div>
                    <DeleteOutlined
                      className='closed'
                      style={{}}
                      onClick={(e) => deleteStack(e, stack)}
                    />
                  </div>
                </div>
              );

              const selectedPurposes = stack.categories;

              return (
                <Panel header={header} className={classNames({ 'editing': editing })}>
                  {editing && (
                    <div onClick={e => e.stopPropagation()} >
                      <b>Description:</b>
                      <Input
                        style={{ marginLeft: 5, width: 400 }}
                        placeholder="No description provided"
                        value={stack.description}
                        onChange={(e) => updateStack(stack.set('description', e.target.value), stack.name)}
                      />
                    </div>
                  )}
                  <Select
                    value={selectedPurposes.map(s => s.name).toJS()}
                    mode='multiple'
                    tokenSeparators={[',']}
                    placeholder='Choose one or more purposes to create custom stack'
                    onChange={(inStack) => addOrRemovePurposeFromStack(inStack, i)}
                  >
                    {selectedPurposes
                      .concat(purposesToSelect)
                      .map(p => p.name)
                      .filterNot(name => purposesToAddToNewStack.map(p => p.name).includes(name))
                      .toSet()
                      .map(p => (
                        <Select.Option key={p}>
                          {p}
                        </Select.Option>
                      ))}
                    {!isNonIab && iabCustomStackEnabled && iabPurposes
                      .toJS()
                      .filter(({ name }) => !selectedPurposes.toJS().map((p) => p.name).includes(name))
                      .map(({ name }) => isConflictingPurpose(name, stack.id)
                        ? (
                          <Select.Option key={name} disabled>
                            {name} -  <i>Can't add - being used in another stack)</i>
                          </Select.Option>
                        )
                        : (
                          <Select.Option key={name} >
                            {name}
                          </Select.Option>
                        )
                      )}
                  </Select>
                </Panel>
              )
            })
          }
        </Collapse>
      </div>

      <div className='new-stack'>
        <div className='title'>New Stack</div>
        <label>Name:
          <Input
            className='name'
            onChange={(e) => setNewStackName(e.target.value)}
            value={newStackName}
          />
        </label>
        <label>Description (optional):
          <Input
            className='description'
            onChange={(e) => setNewStackDescription(e.target.value)}
            value={newStackDescription}
          />
        </label>
        <label className='title-2'>Select purposes to add to the new stack:</label>
        <div>
          <Select
            value={purposesToAddToNewStack.map(s => s.name).toJS()}
            mode='multiple'
            tokenSeparators={[',']}
            placeholder='Choose one or more purposes to create a new custom stack'
            onChange={(inStack) => addOrRemovePurposeFromStack(inStack, 'new')}
          >
            {purposesToAddToNewStack
              .concat(purposesToSelect)
              .filterNot(toFilter => vendorList.categories.filter(c => c.type === 'CUSTOM_STACK')
                .map(s => s.categories)
                .flatten(true)
                .map(c => c.name)
                .includes(toFilter.name))
              .map(p => p.name)
              .toSet()
              .map(p => (
                <Select.Option key={p}>{p}</Select.Option>
              ))}
            {!isNonIab && iabCustomStackEnabled && iabPurposes
              .toJS()
              .filter(({ name }) => !purposesToAddToNewStack.toJS().map((p) => p.name).includes(name))
              .map(({ name }) => isConflictingPurpose(name, null)
                ? (
                  <Select.Option key={name} disabled>
                    {name} -  <i>Can't add - being used in another stack)</i>
                  </Select.Option>
                )
                : (
                  <Select.Option key={name} >
                    {name}
                  </Select.Option>
                )
              )}
          </Select>
        </div>

        <div className='add-purpose'>
          <div className='title-2'>Create new custom purpose to include to the stacks:</div>
          <div className='add-purpose-row'>
            <label>Custom purpose name:
              <Input
                onChange={(e) => {
                  setCustomPurposeName(e.target.value)
                  e.target.value === '' && setError(null)
                }}
                value={customPurposeName}
                error={error}
              />
            </label>
            <Button
              onClick={createCustomPurpose}
              disabled={!customPurposeName.trim()}
            >
              Add custom purpose
            </Button>
          </div>
        </div>

        <Button
          className='create-custom-stack'
          onClick={addCustomStack}
        >
          Create Custom Stack
        </Button>
      </div>

    </React.Fragment>
  );
}

export default CustomStacksTab;