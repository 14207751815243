import PropTypes from 'prop-types';
import React from 'react';
import ChoiceStatsRow from './ChoiceStatsRow.js.jsx';
import CustomTable from '../../../common/CustomTable.js.jsx';
import TableBody from '../../../common/TableBody.js.jsx';
import TableRow from '../../../common/TableRow.js.jsx';
import TableCell from '../../../common/TableCell.js.jsx';


export default class extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        viewCount: PropTypes.number.isRequired,
        totalChoices: PropTypes.number.isRequired,
        totalChoicesCompleted: PropTypes.number.isRequired,
        messageHasWhiteListChoice: PropTypes.bool.isRequired,
    };

    render() {

        return (
            <div className="choice-table">

              <CustomTable className="choice">

                <TableBody>
                    { Object.keys(this.props.data).map(key => this.props.data[key]).map((choice) => {
                        return <ChoiceStatsRow key={ choice.choice_type_id } title={ choice.choice_name } totalChoices={ choice.count } totalChoicesCompleted={ choice.count_complete } viewCount={ this.props.viewCount } whiteListPresent={ choice.choice_type_id === 1 }/>
                    })}
                </TableBody>

                { Object.keys(this.props.data).length > 1 ?
                    <TableBody>
                      <ChoiceStatsRow className="total message" title="Total" totalChoices={ this.props.totalChoices } totalChoicesCompleted={ this.props.totalChoicesCompleted } viewCount={ this.props.viewCount } whiteListPresent={ this.props.messageHasWhiteListChoice }/>
                    </TableBody> :
                    Object.keys(this.props.data).length === 1 ?
                    null :
                    <TableBody><TableRow><TableCell colSpan="5"></TableCell></TableRow></TableBody>
                }

              </CustomTable>
            </div>
        )
    }
}
