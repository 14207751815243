import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Campaign } from '../../../records/campaign_records';
import CampaignGraphAdblock from './CampaignGraphAdblock';
import CampaignGraphMessages from './CampaignGraphMessages';
import { CLIENTS_WITH_ADBLOCK_GRAPH_AND_POP_UP_STATS } from '../../../constants';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;

class CampaignOverview extends React.Component {
    static propTypes = {
        campaign: PropTypes.instanceOf(Campaign).isRequired,
        graphData: ImmutablePropTypes.map,
    };

    static defaultProps = { graphData: null };

    state = {
        width: 0,
    };

    shouldComponentUpdate(nextProps) {
        if (this.props.graphData) {
            return false;
        } else {
            return true;
        }
    }

    componentDidMount() {
        // won't rerender on setState without forceUpdate
        this.setState({ width: this.refs["campaign-overview"].clientWidth - 100 }, this.forceUpdate
    )}

    render() {
        let graphs;
        if (this.props.graphData && this.props.graphData.size && this.state.width) {

            const graphData = this.props.graphData.mapKeys(k => {
              const partition = this.props.campaign.partition_set.partitions.find(p => p.uuid === k);
              return partition ? partition.name : "Control";
            });

            if (CLIENTS_WITH_ADBLOCK_GRAPH_AND_POP_UP_STATS.includes(this.props.accountId)) {
                graphs = (
                    <div className="campaign-graphs card-container" >
                       <Tabs type="card">
                         <TabPane tab="Number Messages Shown" key="1"><CampaignGraphMessages data={ graphData.map(v => v.messages) } width={ this.state.width } /></TabPane>
                         <TabPane tab="Adblock Rate" key="2"><CampaignGraphAdblock data={ graphData.map(v => v.adblock) } width={ this.state.width } /></TabPane>
                       </Tabs>
                    </div>
                )
            } else {
                graphs = (
                    <div className="card" >
                      <h4>Number Messages Shown</h4>
                      <CampaignGraphMessages data={ graphData.map(v => v.messages) } width={ this.state.width } />
                    </div>
                )
            }
        }

        return (
            <div className="campaign-overview" ref="campaign-overview">
              { graphs }
            </div>
        );
    }
}

export default CampaignOverview;