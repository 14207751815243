import moment from 'moment';
import { absDataFormater } from '../performanceNew/helper';
import React from 'react';

export const defaultWeekStart =
  moment().day() == 1
    ? moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
    : moment().startOf('isoWeek');

export const defaultWeekEnd =
  moment().day() == 1
    ? moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
    : moment().diff(moment().endOf('isoWeek')) < 0
    ? moment().subtract(1, 'days')
    : moment().endOf('isoWeek');

export const defaultMonthStart = moment().startOf('month');

export const defaultMonthEnd =
  moment().diff(moment().endOf('month')) < 0
    ? moment().subtract(1, 'days')
    : moment().endOf('month');

export const defaultQuarterStart = moment(new Date().getFullYear(), 'YYYY')
  .quarter(moment().quarter())
  .startOf('quarter');

export const defaultQuarterEnd =
  moment().quarter() ==
  moment()
    .subtract(1, 'days')
    .quarter()
    ? moment().subtract(1, 'days')
    : moment();

export function mapReduceSum(arr, key) {
  return absDataFormater(arr.map((entry) => entry[key] || 0).reduce((prev, current) => prev + current, 0));
}

export function mapReduceAvg(arr, key) {
  const percentage = (arr.map((entry) => entry[key] || 0).reduce((prev, current) => prev + current, 0)/arr.size).toFixed(2);
  return `${Math.max(percentage, 0)}%`;
}

export function trimString(string, length){
  const strLength = length || 50;
  return string.substring(0, strLength) + (string.length > strLength ? '...' : '');
}

const message1 = <><div>Due to a production issue with the reporting pipeline, the unique user reporting may show incorrect numbers for the timeframe <span className="timeframe">August 8 - September 12 2022</span> for certain properties.</div><br/></>
const message2 = <><div>During the period from <span className="timeframe">September 12 - September 22 2022</span> multi-campaign web implementations may show no message actions due to a production issue with the reporting pipeline.</div><br/></>
const message3 = <><div>Due to a production issue with the reporting pipeline geo filters may return incorrect results for the timeframe <span className="timeframe"> August 1 - September 30 2022.</span></div><br/></>
const commonMessage = <div>There is no correlation between incorrect reporting numbers and the correct collection of user consent decisions. The CMP core service is unaffected by the reporting issues</div>

export const getErrorMessageBasesOnTimeframe = (startDate, endDate) => {
  //High Unique User counts | August 8 - September 12 2022
  const timeframe1 = {
    startDate: moment("2022-8-8"),
    endDate: moment("2022-9-12")
  }
  //High No Action Rate in message action Tab | September 12 - September 22 2022
  const timeframe2 = {
    startDate: moment("2022-9-12"),
    endDate: moment("2022-9-22")
  }
  //Geo Filters not working in the Message Action of a reporting dashboard /  Pubdata get sent through as empty object | August and September 2022.
  const timeframe3 = {
    startDate: moment("2022-8-1"),
    endDate: moment("2022-9-30")
  }

  let alert = [];
  if((startDate.isSameOrBefore(timeframe1.endDate) && endDate.isSameOrAfter(timeframe1.startDate))) {
    alert.push(message1)
  }
  if((startDate.isSameOrBefore(timeframe2.endDate) && endDate.isSameOrAfter(timeframe2.startDate))) {
    alert.push(message2)
  }
  if((startDate.isSameOrBefore(timeframe3.endDate) && endDate.isSameOrAfter(timeframe3.startDate))){
    alert.push(message3)
    alert.push(commonMessage)
  }
  return alert;
}
