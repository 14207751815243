import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router";
import { Headline, Button, Filterbox, Table, Modal, message, Input } from '../../../styleguide';
import { loadColumns, testData } from './manageColumns';
import { getScheduledJobs, getJobDefinitions, deleteScheduledJob, updateScheduledJob, createScheduledJob } from '../../../api/custom_report';
import Loading from '../../common/Loading.js.jsx';
import PropertiesDetailModal from '../../common/PropertiesDetailModal.jsx';
import { SearchOutlined } from '@ant-design/icons';

let filterKeys = [
  {"US Privacy Performance (CCPA)": "US Privacy (Legacy)"},
  {"GDPR Performance (TCF v2)": "GDPR"},
  {"US Privacy Performance (US NAT)" : "US Multi State Privacy"}
]

export class ManageReports extends React.Component {
  state = {
    loading: true,
    jobs: [],
    definitions: {},
    filterAll: true,
    search: '',
    showPropertiesDetail: false,
    currentRecord: null,
    filteredSiteIds: [],
  }
  componentDidMount = () => {
    this.loadScheduledJobs();
  }

  /**
   * to fetch only object for GDPR TCFv2 and US Privacy
   */
  filterBasedOnCampaignType =  (definitions) => {
    const fixedFilters = filterKeys.map((val) =>  Object.keys(val)).flat()
    const filteredDefinitions = Object.keys(definitions)
    .filter(key => fixedFilters.includes(definitions[key].campaign_type))
    .reduce((obj,key) => {
      obj[key] = definitions[key];
      return obj;
    },{})
    return filteredDefinitions;
  }

  loadScheduledJobs = async () => {
    const accountId = this.props.currentUser.accountId;
    const jobs = await getScheduledJobs(accountId);
    const definitions = await getJobDefinitions(accountId);
    const filteredDefinitions = this.filterBasedOnCampaignType(definitions)
    this.setState({ loading: false, jobs, definitions:filteredDefinitions })
  }
  filterChange = (field, value) => {
    const filters = {...this.state.definitions};
    filters[field].filter = value;
    const checkAllChecked = Object.keys(filters).map(f => filters[f].filter).every(val => val)
    this.setState({ definitions: filters,filterAll:checkAllChecked });
  }
  toggleAll = (checked) => {
    const filters = {...this.state.definitions};
    Object.keys(filters).forEach(f => {
      filters[f].filter = checked;
    });
    this.setState({ 
      definitions: filters,
      filterAll: checked
    });
  }
  deleteReport = async (jobId) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this scheduled job?',
      onOk: async () => {
        try {
          const accountId = this.props.currentUser.accountId;
          const creating = await deleteScheduledJob(accountId, jobId);
          if (!creating) throw new Error();
          message.success('Scheduled job successfully deleted');
          this.loadScheduledJobs();
        } catch (err) {
          message.error('Could not remove scheduled job, try again');
        }
      },
      onCancel() {}
    });
  }
  toggleActive = async (scheduledJob) => {
    Modal.confirm({
      title: 'Are you sure you want to toggle this scheduled job?',
      onOk: async () => {
        try {
          const accountId = this.props.currentUser.accountId;
          const jobData = {...scheduledJob};
          jobData.is_active = !jobData.is_active;
          const updating = await updateScheduledJob(jobData);
          if (!updating) throw new Error();
          if (jobData.is_active) {
            message.success('Scheduled job successfully activated');
          } else {
            message.success('Scheduled job successfully deactivated');
          }
          this.loadScheduledJobs();
        } catch (err) {
          console.log(err);
          message.error('Could not toggle scheduled job, try again');
        }
      },
      onCancel() {}
    });
  }
  duplicateReport = async (scheduledJob) => {
    try {
      const jobData = {...scheduledJob};
      jobData.report_name = jobData.report_name + ' (Copy)';
      jobData.is_active = false;
      const exists = this.state.jobs?.filter(j => j.report_name.includes(jobData.report_name));
      if (exists?.length > 0) jobData.report_name = jobData.report_name + ' ' + exists.length;
      delete jobData.id;
      delete jobData.created_at;
      const creating = await createScheduledJob(jobData);
      if (creating?.error || !creating) throw new Error(creating.msg);
      message.success('Scheduled job successfully duplicated');
      this.loadScheduledJobs();
    } catch (err) {
      message.error(err?.message || 'Could not duplicate scheduled job, try again');
    }
  }
  updateSearch = (e) => this.setState({ search: e.target.value });

  getFilterLabel = (campType) => {
    const filterDetails = filterKeys.find(val => val[campType])
    if(filterDetails[campType]){
      return filterDetails[campType];
    }else{
      return campType
    }
  }
  setShowPropertiesDetailsModal = (record) => {
    this.setState({
      currentRecord: record,
      showPropertiesDetail: !this.state.showPropertiesDetail
    })
  }
  setFilteredSiteIds = (siteIds) => {
    this.setState({
      filteredSiteIds: siteIds
    })
  }

  render() {
    const { loading, jobs, definitions, search } = this.state;
    const { currentUser } = this.props;
    const activeFilters = Object.keys(definitions).filter(d => definitions[d].filter === true);
  
    const filteredJobs = jobs.filter(j => {
      const recipients = (j?.user_email || '').toString();
      const searchName = search ? j.report_name.toLowerCase().includes(search.toLowerCase()) : true;
      const searchRecipients = search ? recipients.toLowerCase().includes(search.toLowerCase()) : true;
      const nonAdminRecipientFilter = currentUser?.isAdmin ? true : recipients === currentUser?.email;

      return activeFilters.includes(j.job_def_id.toString()) && nonAdminRecipientFilter && (searchName || searchRecipients);
    });

    const indexSiteNameMap = this.props.sites.toJS().reduce((map, obj) => (map[obj.id] = obj.domain, map));
    const allReportsSiteIds = Array.from(new Set(filteredJobs?.map(acc => acc.inclusion_filters.site_id)?.flat()));
    const propertyFilterOptions = allReportsSiteIds.filter(id => indexSiteNameMap[id]).map((id) => {
      return { text: indexSiteNameMap[id], value: id };
    });
    propertyFilterOptions.unshift({text: "All properties", value: "all_property"});

    const appliedSiteIdsForRecord = this.state.currentRecord?.inclusion_filters?.site_id?.length ? this.state.currentRecord?.inclusion_filters?.site_id : this.props.sites.map(p => p.get("id"))?.toJS() 

    return loading ? <Loading /> : (
      <div className="manage-v2">
        <Headline>MANAGE REPORTS</Headline>

        { /* FILTERS */ }
        <div className="filter-bar">
          <Input
            placeholder="Search Report Name or Recipient"
            onChange={this.updateSearch}
            value={search}
            style={{ width: 280 }}
            suffix={<SearchOutlined/>}
          />
          <Link to="/manage_reports/new"><Button type="primary">New Report</Button></Link>
        </div>

        { /* TABLE */ }
        <div className="manage-table">
          <Table
            bordered
            loading={ loading }
            locale={{ emptyText: loading ? 'Loading reports...' : 'No reports found' }}
            scroll={{ x: 'min-content' }}
            tableLayout = 'auto'
            dataSource={filteredJobs.map(d => {
              d.key = d.id;
              return d;
            })}
            pagination={{ 
              position: ['bottomCenter'],
              defaultPageSize: 10, 
              showSizeChanger: true, 
              pageSizeOptions: ['10', '15', '20', '25']
            }}
            columns={loadColumns(this.deleteReport, this.toggleActive, this.duplicateReport, definitions, propertyFilterOptions, this.setFilteredSiteIds, this.setShowPropertiesDetailsModal )}
          />
          { filteredJobs.length > 0 && <div className="totals">Total Reports: {filteredJobs.length}</div> }
        </div>
        {this.state.showPropertiesDetail ?
        <PropertiesDetailModal
          siteIds={appliedSiteIdsForRecord}
          sites={this.props.sites}
          siteGroups={this.props.siteGroups}
          showPropertiesDetailModal={this.state.showPropertiesDetail}
          setShowPropertiesDetailsModal={this.setShowPropertiesDetailsModal}
          setShowActivateErrorModal={this.setShowPropertiesDetailsModal}
          indexSiteMap={indexSiteNameMap}
          filteredSiteIds={this.state.filteredSiteIds}
        /> : null}
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountId: store.accountState.getIn(['accountId', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
  };
};

export default connect(
  mapStateToProps, {},
)(ManageReports);
