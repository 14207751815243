import React from 'react';
import { List, Map } from 'immutable';
import { Select, Radio, Button } from '../../../../styleguide';
import { styles } from './styles';
import { SCENARIO_STEPS } from '../../scenarios/versionTwo/components/steps';
import { statusesGDPR, statusesCCPA, statusesUSNAT, statusesPreferences } from '../helper';
import {
  ConsentGateV2,
  ConsentGateCCPA,
  ConsentGateUSNAT,
  ConsentGatePreferences,
} from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { consentGateClass } from '../TemplateBuilder/Data';
const { Option } = Select;

export default class ConsentStatus extends React.Component {
  onSelectFilter = (inputString, option) => {
    return (
      inputString.toLowerCase() ===
      option.props.children.toLowerCase().slice(0, inputString.length)
    );
  };
  state = {
    selectedOption: this.props.act === 'usnat' || this.props.act === 'preferences' ? 0 : 4,
  };

  componentWillMount() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .find((r, ind) => ((r.get('className') == className) || (r.get('className') == consentGateClass)) && ind == this.props.conditionIndex);
    let data;
    if (getSavedData && this.props.isUpdate) {
      data = getSavedData;
    }
    this.setState({
      selectedOption:
        getSavedData && data
          ? data.get('data').get('status')
          : ((this.props.act === 'usnat' || this.props.act === 'preferences') ? 0 : 4),
    });
  }

  cancelCondition = () => {
    this.props.closePanel();
  };

  onSelectOption = (option) => {
    this.setState({ selectedOption: Number(option) });
  };

  getConsentGate = (act) => {
    if (this.props.act === 'CCPA') {
      return new ConsentGateCCPA({
        status: this.state.selectedOption,
      });
    } else if (this.props.act === 'usnat') {
      return new ConsentGateUSNAT({
        status: this.state.selectedOption,
      });
    } else if (this.props.act === 'preferences') {
      return new ConsentGatePreferences({
        status: this.state.selectedOption,
      });
    } 
    return new ConsentGateV2({
      status: this.state.selectedOption,
    });
  }

  onAddEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set('data', this.getConsentGate());
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.unshift(stepBlockData);
    this.props.onChange(this.props.activeStep, rowData);
  };

  onUpdateEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set('data', this.getConsentGate());
    const index = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.findIndex(
        (r, ind) =>
          (r.get('className') == className ||
          (r.get('className') == consentGateClass &&
            r.get('data').get('version') ==
              this.props.selectedCondition.get('version'))) &&
          ind == this.props.conditionIndex
      );
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.set(index, stepBlockData);
    this.props.onChange(this.props.activeStep, rowData);
  };
  render() {
    let consentStatus;
    if (this.props.act === 'CCPA') {
      consentStatus = statusesCCPA;
    } else if (this.props.act === 'usnat') {
      consentStatus = statusesUSNAT;
    } else if (this.props.act === 'preferences') {
      consentStatus = statusesPreferences;
    } else {
      consentStatus = statusesGDPR;
    }
    const options = consentStatus.map((s) => (
      <Option key={s.get('value')} value={s.get('value')}>
        {s.get('label')}
      </Option>
    ));
    const selectedOption = this.state.selectedOption;
    let conditionLabel;
    if (this.props.act === 'CCPA') {
      conditionLabel = 'U.S.Privacy';
    } else if (this.props.act === 'usnat') {
      conditionLabel = 'U.S. MultiState Privacy';
        } else if (this.props.act === 'preferences') {
      conditionLabel = 'Preferences';
    } else {
      conditionLabel = this.props.act;
    }
    return (
      <div style={styles.scenarioConditionPanelTwo}>
        <div style={styles.scenarioInnerWrapper}>
          <span className="condition-label">Condition: </span>
          <span className="condition-title">{`Consent Status (${conditionLabel})`}</span>
          <div id="condition-dropdown">
            <label>{`Consent Status (${conditionLabel})`}</label>
            <Select
              placeholder="Please select"
              onChange={this.onSelectOption}
              filterOption={this.onSelectFilter}
              value={selectedOption !== 3 ? selectedOption : '' }
              showArrow={true}
              status={selectedOption === 3 ? 'error' : ''}
            >
              {options.toJS()}
            </Select>
          </div>
        </div>
        <div style={styles.scenarioButtonWrapper}>
          <Button
            type="primary"
            size='small'
            onClick={
              this.props.buttontitle == 'Add'
                ? this.onAddEvent
                : this.onUpdateEvent
            }
            disabled={selectedOption === 3 ? true : false}
          >
            {this.props.buttontitle}
          </Button>

          <Button
            style={styles.scenarioMarginLeft}
            onClick={this.cancelCondition}
            type="secondary"
            font="oswald"
            size='small'
            ghost
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
