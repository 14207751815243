import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'antd';

const types = {};
types.EDIT = "avo-sp-edit";
types.DELETE = "avo-sp-delete";
types.COPY = "avo-sp-copy";

types.VIEW = "avo-sp-view";

types.COLLAPSE = "avo-sp-collapse";
types.EXPAND = "avo-sp-expand";

types.CHART = "avo-sp-reports";
types.PREVIEW = 'avo-sp-preview';

// not sure where we use these anymore, the goal is to move all to `avo-sp`
const sizes = {};
sizes.SMALL = 'i-sm';
sizes.MEDIUM = 'i-md';
sizes.LARGE = 'i-lg';
types.ARROW_UP = "glyphicon-arrow-up";
types.ARROW_DOWN = "glyphicon-arrow-down";

class CustomIcon extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        type: PropTypes.oneOf(Object.keys(types).map(key => types[key])),
        size: PropTypes.oneOf([sizes.SMALL, sizes.MEDIUM, sizes.LARGE]),
        className: PropTypes.string,
        disabled: PropTypes.bool,
        tooltip: PropTypes.string
    };

    static defaultProps = {
        type: types.EDIT,
        size: sizes.MEDIUM,
        className: "",
        tooltip: "",
    };

    render() {
        var icon;
        if (this.props.type === types.ARROW_UP || this.props.type === types.ARROW_DOWN){
            icon = (
                <div className="arrow-container" onClick={ this.props.onClick } ><i className={"sortButton glyphicon action " + this.props.type }></i></div>
            )
        } else {
            var className = 'avo-dialogue-icons ' + this.props.type;
            className.trim();
            icon = <button className={ className } onClick={ this.props.onClick }></button>
        }

        return (
            <Tooltip title={ this.props.tooltip }>{ icon }</Tooltip>
        )
    }
}

CustomIcon.sizes = sizes;
CustomIcon.types = types;

export default CustomIcon;
