import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';

import CreateButton from '../../common/CreateButton.js.jsx';
import ImportButton from '../../common/ImportButton.js.jsx';
import SectionHeader from '../../common/SectionHeader.js.jsx';
import PageHeader from '../../common/PageHeader.js.jsx';
import SortFilter from '../../common/SortFilter.js.jsx';
import MessageList from './MessageList.js.jsx';
import ImportForm from '../../common/ImportForm.jsx';
import Loading from '../../common/Loading.js.jsx';
import { getAllSites, getSiteGroup } from '../../../actions/site_actions';
import {
  getDraftMessages,
  getPublicMessages,
  getStageMessages,
  importMessage,
  createSiteGroupMessage,
  getMessageWithDetail,
} from '../../../actions/message_actions';
import { Message } from '../../../records/message_records';
import { getParameterByName } from '../../utils.js';
import { STATUS_TYPES as STATUS } from '../../../constants';
import { Site } from '../../../records/site_records.js';
import SitesRedirect from '../campaigns/SitesRedirect';
import { User } from '../../../records/account_records';
import { siteAndGroupLabel } from '../helper';
import SiteGroupRedirect from '../../common/SiteGroupRedirect';
import PropertiesUnderGroupRedirection from '../../common/PropertiesUnderGroupRedirection';

export class MessagesPage extends React.Component {
    static propTypes = {
        draftMessages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        publicMessages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        stageMessages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        messagesForImport: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        allSites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
        pendingRequestsMap: ImmutablePropTypes.map.isRequired,
        route: PropTypes.shape({
          currentUser: PropTypes.instanceOf(User).isRequired,
        }).isRequired,

        getPublicMessages: PropTypes.func.isRequired,
        getStageMessages: PropTypes.func.isRequired,
        getDraftMessages: PropTypes.func.isRequired,
        getAllSites: PropTypes.func.isRequired,
        getSiteGroup: PropTypes.func.isRequired,
        createSiteGroupMessage: PropTypes.func.isRequired,
        getMessageWithDetail: PropTypes.func.isRequired,
    };

    state = {
        displayImportMenu: false,
        filteredDraftMessages: this.props.draftMessages,
    };

    componentDidMount() {
        const siteId = this.props.location.query.site_id;

        this.props.getDraftMessages(siteId);
        this.props.getPublicMessages(siteId);
        this.props.getStageMessages(siteId);

        this.props.getAllSites();

        const siteGroupId = this.props.location.query.site_group_id;
        if (siteGroupId) {
          this.props.getSiteGroup(siteGroupId);
        }
        const redirectSiteGroupId = this.props.location.query.sitegroup_id;
        if(redirectSiteGroupId){
          this.props.getSiteGroup(redirectSiteGroupId);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.pendingRequestsMap) {
           if (this.props.pendingRequestsMap.get('import') && !newProps.pendingRequestsMap.get('import')) {
                this.setState({ displayImportMenu: false });
            }
        }

        if (this.props.location.query.site_id !== newProps.location.query.site_id) {
          this.props.getDraftMessages(newProps.location.query.site_id);
          this.props.getPublicMessages(newProps.location.query.site_id);
          this.props.getStageMessages(newProps.location.query.site_id);
        }
    }

    toggleImportMenu = () => {
        this.setState({ displayImportMenu: !this.state.displayImportMenu })
    };

    handleCreateMessage = () => {
        let url = `/dialogue/messages/edit?site_id=${ this.props.location.query.site_id }`
        const siteGroupId = this.props.location.query.site_group_id;
        if (siteGroupId) {
          url += `&site_group_id=${ siteGroupId }`;
        }
        browserHistory.push(url);
    };

    importMessage = (message) => {
        const siteGroupId = this.props.location.query.site_group_id;
        if (siteGroupId) {
          this.props.getMessageWithDetail(this.props.location.query.site_id, message.id).then(message => {
            const willImport = true;
            this.props.createSiteGroupMessage(this.props.currentUser.accountId, siteGroupId, message,  willImport);
          })
        } else {
          this.props.importMessage(this.props.location.query.site_id, message.id);
        }
    };

    redirectToGroup = () => browserHistory.push(`${this.props.location.pathname}?site_id=${this.props.siteGroupRedirect.siteId}&site_group_id=${this.props.siteGroupRedirect.id}`);

    render() {

        let readOnly = false;
        if (this.props.currentUser.featureAccess &&
          !this.props.currentUser.featureAccess.includes('Campaign Entities')) {
          readOnly = true;
        }

        const siteId = this.props.location.query.site_id;
        let domain;
        let siteGroupSiteList;
        let siteGroupSitesWithErrors;
        let propertyType;
        if (this.props.location.query.site_group_id) {
          domain = this.props.siteGroup && this.props.siteGroup.name;
          siteGroupSiteList = (
            <PropertiesUnderGroupRedirection pageKind='messages-adblock' />
          );
          if (this.props.siteGroupSitesWithErrors.size) {
            siteGroupSitesWithErrors = (
              <SitesRedirect
                pageKind='messages'
                sites={ this.props.siteGroupSitesWithErrors.map(options => this.props.allSites.find(s => s.id === options.get('siteId')))}
                isForErrors
              />
            )
          }
        } else {
          const site = this.props.allSites.size ? this.props.allSites.find(s => s.id === parseInt(siteId)) : '';
          domain = site ? site.domain : '';
          propertyType = site ? site.type : '';
        }

        let sites = this.props.allSites;
        if (this.props.currentUser.siteAccess) {
          sites = this.props.allSites.filter(s => this.props.currentUser.siteAccess.includes(s.id));
        }
        const importMenuForm = this.state.displayImportMenu && (
          <ImportForm
            siteId={ siteId }
            sites={ sites }
            type="message"
            fetchItemsForTheSite={ this.props.getDraftMessages }
            storedItems={ this.props.messagesForImport }
            importItem={ this.importMessage }
            closeForm={ this.toggleImportMenu }
            visible={ this.state.displayImportMenu }
          />
        );

        var containerClassName = "messages-container";

        var loading;
        if (this.props.pendingRequestsMap.find((value) => true )) {
            loading = <Loading/>
        }

        let importButton;
        let createButton;
        if (!readOnly) {
          importButton = <ImportButton onClick={ this.toggleImportMenu } disabled={ this.state.displayImportMenu }>IMPORT</ImportButton>;
          createButton = <CreateButton onClick={ this.handleCreateMessage } disabled={ this.state.displayImportMenu }>NEW</CreateButton>;
        }
        let backToSitesGroup;
        if(this.props.siteGroupRedirect){
          backToSitesGroup = <SiteGroupRedirect redirectToGroup={this.redirectToGroup} siteGroup={this.props.siteGroupRedirect}/>;
        }

        return (
            <div className={ containerClassName }>
              { loading }
              { importButton }
              { createButton }
              { backToSitesGroup }
              <PageHeader title={domain} propertyType={propertyType} label={siteAndGroupLabel(this.props.location.query.site_group_id)}/>
              { siteGroupSiteList }
              <SortFilter
                data={ this.props.draftMessages }
                searchField={ 'description' }
                sorters={ [
                  SortFilter.dateSorter,
                  SortFilter.descriptionSorter,
                ] }
                onUpdate={ (filteredDraftMessages) => {
                  this.setState({ filteredDraftMessages })
                } }
              />
              { importMenuForm }
              <div>
                { siteGroupSitesWithErrors }
                <SectionHeader title={<span className="notice-title">Draft Messages</span>} tooltipText="These are messages that can be edited - they are not in any active messaging campaign." />
                <MessageList
                  siteId={ siteId }
                  messages={ this.state.filteredDraftMessages }
                  duplicateMessage={ this.importMessage }
                  createSiteGroupMessage={ this.props.createSiteGroupMessage }
                  getMessageWithDetail={ this.props.getMessageWithDetail }
                  accountId={ this.props.currentUser.accountId }
                  readOnly={ readOnly }
                />
              </div>
              <div>
                <SectionHeader title={<span className="notice-title">Live Messages</span>} tooltipText="These are messages that are currently in an active messaging campaign.  They cannot be changed." />
                <MessageList
                  siteId={ siteId }
                  messages={ this.props.publicMessages.concat(this.props.stageMessages) }
                />
              </div>
            </div>
        );
    }
}

const mapStateToProps = function (store){

    const siteId = parseInt(getParameterByName('site_id', window.location));

    const messages = store.messageState.getIn(['messages', 'value']).filter(m => m.site_id === siteId);
    return {
        currentUser: store.accountState.getIn(['userDetails', 'value']),
        draftMessages: messages.filter(m => m.status === STATUS.DRAFT),
        publicMessages: messages.filter(m => m.status === STATUS.PUBLIC && m.live),
        stageMessages: messages.filter(m => m.status === STATUS.STAGE && m.live),
        messagesForImport: store.messageState.getIn(['messages', 'value']).filter(m => m.site_id !== siteId && m.status === STATUS.DRAFT),
        allSites: store.siteState.getIn(['sites', 'value']),
        siteGroup: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('site_group_id', window.location)),
        siteGroupRedirect: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('sitegroup_id', window.location)),       
        siteGroupSitesWithErrors: store.siteState.get('siteGroupSitesWithErrors'),
        pendingRequestsMap: Map({
            messages: store.messageState.getIn(['messages', 'pending']),
            allSites: store.siteState.getIn(['sites', 'pending']),
            import: store.messageState.get('importPending'),
        }),
    };
}

export default connect(
    mapStateToProps, {
        getDraftMessages,
        getAllSites,
        importMessage,
        getPublicMessages,
        getStageMessages,
        getSiteGroup,
        createSiteGroupMessage,
        getMessageWithDetail,
    }
)(MessagesPage);
