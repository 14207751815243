import React, { useEffect, useState } from "react";
import { Toggle } from "../../../../../../styleguide"

const SignalSharing = (props) => {
  const [shareRootDomain, setShareRootDomain] = useState(props.usPrivacyRegulation.shareRootDomain);
  const [writeFirstPartyCookiesFromServer, setWriteFirstPartyCookiesFromServer] = useState(props.usPrivacyRegulation.writeFirstPartyCookiesFromServer);
  
  const handleRootDomainChange = (checked, e) => {
    setShareRootDomain(checked)
  }

  const handleFirstPartyCookiesChange = (checked, e) => {
    setWriteFirstPartyCookiesFromServer(checked)
  }

  useEffect(()=>{
    props.usPrivacyRegulation.shareRootDomain = shareRootDomain;
    props.usPrivacyRegulation.writeFirstPartyCookiesFromServer = writeFirstPartyCookiesFromServer;
  },[shareRootDomain,writeFirstPartyCookiesFromServer]);

  return (
    <>
      {!props.readOnly ? (
      <div className="step-header">
        <h4>Advanced Settings</h4>
      </div>
      ) : null}
      <div className="step-brief top-margin">
        <Toggle checked={shareRootDomain} disabled={props.readOnly} onChange={handleRootDomainChange} className="right-margin" /><span>Write 1st Party Cookies To Root Domain</span>  
      </div>
      <div className="step-section sharing-section">Toggling this setting ensures that the signal will be stored/persist across both the property's root domain and subdomains. In practice, this will ensure that users won't see the same message when moving from root to subdomain or vice versa.
      </div>
      <div className="step-brief top-margin">
        <Toggle checked={writeFirstPartyCookiesFromServer} disabled={props.readOnly} onChange={handleFirstPartyCookiesChange} className="right-margin" /><span>Write 1st party cookies from the server to the property</span>  
      </div>
      <div className="step-section sharing-section">
        Toggling this setting on means that the server will set a 1st party cookie on your property, as opposed to the code on page setting the cookie which is the default state.
      </div>
    </>
  )
}

export default SignalSharing;