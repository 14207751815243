import { RangeDates } from '../records/reports_records.js';
import moment from 'moment';
import { CHOICE_TYPES, DATEPICKERS, NOTICE_URL, CAMPAIGN_TYPES } from '../constants';
import { List } from 'immutable';

export function decimalRound(v) {
    return Math.round(v * 100) / 100;
}

export function validateEmail(string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const found = string.match(re);
  return (found && found[0].length === string.length);
}

export function validateURL(string) {
  // Regular expression for a simple URL validation
  const re = /^http(s)?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
  const found = string.match(re);
  return found !== null;
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function replaceParameterByName(name, url, replacement) {
  url = url || '';
  name = name.replace(/[\[\]]/g, "\\$&");
  return (url.replace(new RegExp("[?&]" + name + "=([^&#]*)|&|#|$"), '') + (replacement ? `&${name}=${replacement}` : ''));
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function percentOfNum(num1, num2) {
    var num = num2 ? (num1 * 100 / num2) : 0;
    return parseFloat(num).toFixed(2) + "%";
}

export function cleanPercent(num, tot) {
  const percent = ( num / tot ) * 100;
  return Number(percent.toFixed(2));
}

//Finds y value of given elelment
export function findPosition(el) {
    var curtop = 0;
    if (el.offsetParent) {
        do {
            curtop += el.offsetTop;
        } while (el = el.offsetParent);
    return [curtop];
    }
}

export function generateMessagePreviewUrl(url) {
  // for some sites that have multiple sites under umbrella like '*.amc.com'
  // or '*.cbs.com', the '*' causes issues. When it is
  // turned into a document anchor tag in 'updateDefaultScreenshotUrl',
  // that gets converted to '%2A.'
  // here we check for '%2A.' and eliminate it.
  if (url.includes('%2A.')) {
    url = url.replace('%2A.', '');
  }
  return 'https://image.thum.io/get/width/1200/' + url;
}

export function findMessageIdsFromScenario(obj) {
    // get possibly nested message ids caused by branching of the scenario
    return obj.map((value, key) => {
        if (key === "message_id") {
            return value
        } else if (value && typeof value === "object") {
            return findMessageIdsFromScenario(value);
        }
    //XXX look into why getting undefined in a first place
    }).toList().filterNot(id => typeof(id) === 'undefined').flatten();
}

export function getLastWeekDates() {
  const now = new Date();
  const today = moment(now);

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(now.getDate() - 7);
  const oneWeekAgoMoment = moment(oneWeekAgo);
  return RangeDates({ start: oneWeekAgoMoment, end: today });
}

export function getDateRange(amount, type, offset) {
  let lastDay = moment(new Date());
  if (offset) {
    lastDay = lastDay.subtract(offset, 'day');
  }
  const past = moment(new Date()).subtract(amount, type);
  return RangeDates({ start: past, end: lastDay });
}

export function getDomainsForSiteAccess(siteAccess, sites, clientDomains) {
  const siteAccessSites = siteAccess
    .map(id => sites.find(s => s.id === id)).filter(s => !!s);

  const rootDomains = siteAccessSites
    .map(s => s.domain || s.name || '')
    .filter(d => d.includes('*.'))
    .map(d => {
      const domain = d.slice(2);
      const rootLength = domain.split('.')[0].length;
      return domain.slice(0, rootLength);
    });

  const subDomains = clientDomains.filter(domain => {
    let isSubdomain = false;
    rootDomains.forEach(rootDomain => {
      if (domain.includes(rootDomain)) {
        isSubdomain = true;
        return false;
      }
    });
    return isSubdomain;
  });

  return siteAccessSites
    .map(s => {
      let domain = s.domain || s.name || '';
      if (domain.slice(0,4) === 'www.') {
        domain = domain.slice(4);
      }
      return domain;
    })
    .filter(d => clientDomains.includes(d))
    .concat(subDomains);
}

export const getDateRangeFromPicker = (selectedDatePicker, date) => {

  let start;
  let end;
  if (selectedDatePicker === DATEPICKERS.day) {
    start = date;
    end = date;
  }
  else if (selectedDatePicker === DATEPICKERS.week) {
    start = moment(date).startOf("isoWeek")
    end = moment(date).endOf("isoWeek")
  }
  else if (selectedDatePicker === DATEPICKERS.month) {
    start = moment(date).startOf("month")
    end = moment(date).endOf("month")
  }
  return {
    start,
    end
  }
}

const textTruncator = (text, characters = 30) => {
  return text.replace( /(<([^>]+)>)/ig, '').slice(0, characters) + (text.length > characters ? '...' : '');
}

const errorDescriptor = (child, choice_option) => {
  if(child.type === "Text") {
    let matches = child.settings.text.match(new RegExp(`.*['"]${('$$$$:' + choice_option.data.button_text).replace(/\$/g, "\\$")}['"][^>]*>(?<link_text>[^<]*)<`));

    if(matches && matches.groups && matches.groups.link_text) {
      return `"${matches.groups.link_text}" link within "${textTruncator(child.settings.text)}" ${child.type.toLowerCase()}`;
    }
  }

  return `"${textTruncator(child.settings.text || (child.name) || '')}" ${child.type.toLowerCase()}`;
};

// Validate choice options are set correctly
export const choiceValidators = {
  [CHOICE_TYPES.redirect] : (choice_option, child) => {
    if(!choice_option.data.redirect_url 
      || !choice_option.data.redirect_url.length) {
        return [false, `${errorDescriptor(child, choice_option)} has no redirect url configured.`]
    }
    return [true];
  },
  [CHOICE_TYPES.execJS]: (choice_option, child, isApp) => { 
    if(!choice_option.data.js_fn_name 
      || !choice_option.data.js_fn_name.length) {
        return [false, `${errorDescriptor(child, choice_option)} ${isApp ? 'has a custom action without a value' : 'has no custom javascript configured'}.`]
    }
    return [true];
  },
  [CHOICE_TYPES.privacyManager]: (choice_option, child, pmMessages, pmListLegacy, sitesInSiteGroup) => {
    let valid = true;
    let errorMessage = null;

    // if there is a pm, see if it's valid
    if (choice_option.data.privacy_manager_iframe_url) {
      let pmUrl = choice_option.data.privacy_manager_iframe_url;
      if(choice_option.master_privacy_manager_iframe_url) {
        pmUrl = choice_option.master_privacy_manager_iframe_url;
      }

      if(!pmMessages.some((pm) => ((new RegExp(`.*=${pm.id}(&|$)`)).test(pmUrl)))) {
        valid = false
        errorMessage = `${errorDescriptor(child, choice_option)} is connected to an invalid (possibly deleted) Privacy Manager.`
      }

      if(pmListLegacy.some((pm) => ((new RegExp(`.*=${pm.id}(&|$)`)).test(pmUrl)))) {
        valid = true;
      }
    }
    // if no pm or it's invalid, set it under certain circumstances, or return error
    if (!choice_option.data.privacy_manager_iframe_url || !valid) {
      if(pmMessages.length === 1 && (!sitesInSiteGroup || !sitesInSiteGroup.size)) {
        // if there's only one PM and we're not in a site group (which requires extra logic), we should just default to that
        choice_option.data.privacy_manager_iframe_url = `${NOTICE_URL}/privacy-manager/index.html?message_id=${pmMessages[0].id}`;
        valid = true
        errorMessage = null
      } else if (!choice_option.data.privacy_manager_iframe_url) {
        valid = false
        errorMessage = `${errorDescriptor(child, choice_option)} is not connected to a Privacy Manager.`
      }
    }

    return [valid, errorMessage]
  }
}

/** Merge PM choice option - we want to use the top level PM but preserve the component's pmTab */
export const mergePmChoiceOptions = (topLevelPm, componentPm) => {
  console.log('hererererer')
  let { type, data, ...optionalKeypairs} = componentPm;

  const tabOrView = componentPm.data.privacy_manager_iframe_url
    ? componentPm.data.privacy_manager_iframe_url.includes('view') ? 'view' : 'pmTab'
    :'pmTab'
  if(topLevelPm.master_privacy_manager_iframe_url) {
    // add site group master pm if it exists
    optionalKeypairs = { 
      ...optionalKeypairs, 
      master_privacy_manager_iframe_url: replaceParameterByName(
        tabOrView, 
        topLevelPm.master_privacy_manager_iframe_url, 
        getParameterByName(tabOrView, componentPm.master_privacy_manager_iframe_url)
      )
    }
  }

  topLevelPm.data = topLevelPm.data || {};
  componentPm.data = componentPm.data || {};

  return {
    type, 
    data: {
      ...data,
      consent_origin: topLevelPm.data.consent_origin,
      privacy_manager_iframe_url: replaceParameterByName(
        tabOrView, 
        topLevelPm.data.privacy_manager_iframe_url, 
        getParameterByName(tabOrView, componentPm.data.privacy_manager_iframe_url)
      )
    },
    ...optionalKeypairs
  };
}

/** 
 * Ensure all pm choice options share the same PM (the top level PM setting)
 * This duplicates copmonent logic so that existing messages are updated to use the new top level setting
 */
export const updatePmChoiceOptions = (component, topLevelPm) => {
  if(component.children && component.children.length) {
    for(let i=0; i<component.children.length; i++) {
      component.children[i] = updatePmChoiceOptions(component.children[i], topLevelPm);
    }
  }

  let settings = component.settings;
  if(settings) {
    if(settings.choice_option
      && settings.choice_option.type === 12) {
        // update pm iframe url, preserving the vendor tab
        settings.choice_option = mergePmChoiceOptions(topLevelPm, settings.choice_option);
    } else if(settings.choice_options) {
      let choiceOptions = settings.choice_options;
      for(let i=0; i<choiceOptions.length; i++) {
        if(choiceOptions[i].type === 12) {
          choiceOptions[i].data = choiceOptions[i].data || {};
          // update pm iframe url, preserving the vendor tab
          choiceOptions[i] = mergePmChoiceOptions(topLevelPm, choiceOptions[i]);
        }
      }
    }
  }

  return component;
}

export function hasFeature(currentUser, feature){
  return ( currentUser && (currentUser.accountFeatures.includes(feature)));
};

export function getFlagBasedCampaignTypes(currentUser, propertyType, skipNoType = false){
  let types = CAMPAIGN_TYPES;

  const hasTcfv2 = hasFeature(currentUser, 'tcf_v2');
  const hasccpa = hasFeature(currentUser, 'ccpa');
  const hasusnat = hasFeature(currentUser, 'usnat');
  const hasPreferences = hasFeature(currentUser, 'preferences');
  const hasAdblock = hasFeature(currentUser, 'adblock_v2');
  const hasAppleMessaging = hasFeature(currentUser, 'apple_messaging');

  if(!hasTcfv2) {
	types = types.filter(t => t.value != 1);
  }
  if(!hasccpa) {
	types = types.filter(t => t.value != 2);
  }
  if(!hasusnat) {
    types = types.filter(t => t.value != 6);
  }
  if(!hasPreferences) {
    types = types.filter(t => t.value != 7);
  }
  if(!hasAdblock || propertyType === 'ott' || propertyType === 'app') {
	types = types.filter(t => t.value != 3);
  }
  if(!hasAppleMessaging || propertyType === 'ott' || propertyType === 'web') {
	types = types.filter(t => t.value != 4);
  }
  if(skipNoType) {
	types = types.filter(t => t.value != 0);
  }

  return types;
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function trimString(string, length = 10) {
  const stringLength = string.length;
  return string.substring(0, length) + (stringLength <= length ? '' : '...');
}

export const keyByVal = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

export function findCommonElementsInTwoArrays(array1, array2) {
  return (array1 && array2) ? array1.filter(element => array2.includes(element)) : [];
}

export function arraySubtraction(array1, array2) {
  return (array1 && array2) ? array1.filter(element => !array2.includes(element)): [];
}

// Function to escape CSV values (in this case, HTML content) and wrap them in double quotes
const escapeCSVValue = (value) => {
  return `"${value.replace(/"/g, '""')}"`;
};

export function convertToCSV(data) {
  const keys = Object.keys(data[0]);
  const header = keys.join(',');
  const rows = data.map((item) =>
    keys.map((key) => escapeCSVValue(item[key])).join(",")
  );
  return [header, ...rows].join('\n');
}

export function generateRandomString(length) {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }

  return randomString;
}
/* written exclusively to find unsaved changes in us privacy regulation 
skips the _id and undefined values for comaprison
*/
export function findObjectDifferences(obj1, obj2) {
  const differences = {};

  // Compare each key in obj1
  _.forEach(obj1, (value, key) => {
    // Ignore '_id'
    if (key === '_id' || value === undefined) return;

    // Check if the key exists in obj2
    if (_.has(obj2, key)) {
      // If values are different, add to differences
      if (!_.isEqual(value, obj2[key])) {
        // If both values are objects, recursively compare
        if (_.isObject(value) && _.isObject(obj2[key])) {
          const nestedDiff = findObjectDifferences(value, obj2[key]);
          if (!_.isEmpty(nestedDiff)) {
            differences[key] = nestedDiff;
          }
        } else {
          differences[key] = obj2[key];
        }
      }
    } else {
      // If key doesn't exist in obj2, add to differences
      differences[key] = value;
    }
  });

  // Check for keys in obj2 that don't exist in obj1
  _.forEach(obj2, (value, key) => {
    if (!_.has(obj1, key) && value !== undefined) {
      differences[key] = value;
    }
  });

  return differences;
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const capitalizeFirstLetterAndSplitUnderscore = (str) => {
  return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const validateVendorCount = (message, settings) => {
  const defaultLang = settings.defaultLanguage || settings.language || 'EN';
  const supportedLanguages = settings.supportedLanguages || (settings.defaultLanguage ? [settings.defaultLanguage] : ['EN']);
  const languages = {};

  function traverseAndCollectTexts(node) {

    if (node.type === 'Text') {
        supportedLanguages.forEach(lang => {
        if (!languages[lang]) {
          languages[lang] = [];
        }
        
        const text = node.settings.languages?.[lang]?.text || node.settings.languages?.[defaultLang]?.text || node.settings.text
        if (text) {
          languages[lang].push(text);
        }
      });
    }

    if (node.children) {
      node.children.forEach(traverseAndCollectTexts);
    }
  }
  traverseAndCollectTexts(message);
  const vendorCountStrings = ['$$!VC-ALL!$$', '$$!VC-IAB!$$'];
  const languagesWithoutVendorCountStrings = [];

  Object.keys(languages).forEach(lang => {
    const isVendorCountStringPresent = languages[lang].some(text =>
      vendorCountStrings.some(vendorCountString => text.includes(vendorCountString))
    );

    if (!isVendorCountStringPresent) {
      languagesWithoutVendorCountStrings.push(lang);
    }
  });

  return languagesWithoutVendorCountStrings;
};

export const findNthTermInAnEntityList = (list, commonText ) => {
  let n = 0;
  let NthTerm = commonText + " (" + n + ")";
  do{
    n++
    NthTerm = commonText + " (" + n + ")";
  }while(list.includes(NthTerm))
  return NthTerm;
};