import React, { Component } from 'react';
import { Select } from 'antd';

import PmOverrideSelect from './PmOverridesSelect';
import { PRIVACY_MANAGER_URL, CCPA_PRIVACY_MANAGER_URL } from '../../../../../../../../../../constants.js';
import { SitesContext } from '../../../../../../../../contexts';
import { getParameterByName } from '../../../../../../../../../utils';

const { Option } = Select;

class PrivacyManager extends Component {
  state = {
    selection: null
  }

  componentDidMount = () => {
    //this will fire and will set the dropdown to the first available pm
    if (this.props.options.size === 1 && !this.props.value.data.privacy_manager_iframe_url) {
      this.changeValue(this.generateUrl(this.props.options.toJS()[0].id));
    };
  }

  componentDidUpdate = (prevProps) => {
    //in case pms are loaded after mount, this will fire and will set the dropdown to the first available pm
    if (prevProps.options.size === 0 && this.props.options.size > 0 && !this.props.value.data.privacy_manager_iframe_url) {
      this.changeValue(this.generateUrl(this.props.options.toJS()[0].id));
    };
    //after pms are loaded, this will fire and set the dropdown to the correct pm if there is one selected
    const selection = getParameterByName('privacy_manager_id', this.props.value.data.privacy_manager_iframe_url);
    if (this.state.selection !== selection) {
      this.setState({ selection });
    };
  }

  changeValue = (value) => {
    const form = Object.assign({}, this.props.value.data);
    debugger
    form.privacy_manager_iframe_url = (value !== undefined) ? value : null;

    if (this.props.isSiteGroup) {
      this.props.setDefaultPrivacyManagerVariable(getParameterByName('privacy_manager_id', value));
      this.props.changeValue(
        this.props.value.type,
        {
          ...form,
          privacy_manager_iframe_url: this.generateUrl('$$privacyManagerIdHiddenVarriable$$'),
        },
        { master_privacy_manager_iframe_url: form.privacy_manager_iframe_url }
      );
    } else {
      this.props.changeValue(this.props.value.type, form);
    };

    this.setState({ selection: getParameterByName('privacy_manager_id', value) });
  }

  changeDefaultTab = (tab, url) => {
    const newUrl = url.replace('&default_tab=vendors', '');
    if (tab === 'vendors') {
      this.changeValue(`${newUrl}&default_tab=vendors`);
    } else {
      this.changeValue(newUrl);
    }
  }

  generateUrl = (id) => `${this.props.category === 'gdpr' ? PRIVACY_MANAGER_URL : CCPA_PRIVACY_MANAGER_URL}?privacy_manager_id=${id}`

  render() {
    const iframeUrl = (
      (this.props.isSiteGroup ? this.props.value.master_privacy_manager_iframe_url : '')
      || this.props.value.data.privacy_manager_iframe_url
      || ''
    );

    const urlValue = this.props.options.find(({ id }) => this.generateUrl(id) === iframeUrl.replace('&default_tab=vendors', ''))
      ? iframeUrl.replace('&default_tab=vendors', '')
      : null;
console.log('this.props.options: ', this.props.options)
    return (
      <div className="four-options">
        <h4>Privacy Manager</h4>
        <div className="option-inputs">
          <Select
            style={{ width: '100%' }}
            value={urlValue}
            onChange={this.changeValue}
          >
            <Option value={null}><i>None</i></Option>
            {this.props.options.map((opt) => (
              <Option key={opt.id} value={this.generateUrl(opt.id)}>{opt.name}</Option>
            ))}
          </Select>
        </div>
        {urlValue && (
          <React.Fragment>
            <h4>Default PM Tab</h4>
            <div className="option-inputs">
              <Select
                style={{ width: '100%' }}
                value={urlValue.includes('&default_tab=vendors') ? 'vendors' : 'purposes'}
                onChange={val => this.changeDefaultTab(val, urlValue)}
              >
                <Option value="purposes">Purposes</Option>
                <Option value="vendors">Vendors</Option>
              </Select>
            </div>
          </React.Fragment>
        )}
        {(this.props.isSiteGroup && urlValue) && (
          <SitesContext.Consumer>
            {({ sites }) => (
              <React.Fragment>
                <h4>Overrides</h4>
                {sites.map(site => (
                  <PmOverrideSelect
                    key={site.id}
                    site={site}
                    privacyManagers={this.props.options.toJS()}
                    masterOption={getParameterByName('privacy_manager_id', this.props.value.master_privacy_manager_iframe_url) || this.state.selection}
                  />
                ))}
              </React.Fragment>
            )}
          </SitesContext.Consumer>
        )}
      </div>
    );
  }
}

export default PrivacyManager;



