import PropTypes from 'prop-types';
import React from 'react';
import { List, Map } from 'immutable';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Button, Select } from 'antd';

export default class ChangeScoreModal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    updateProperties: PropTypes.func.isRequired,
    selectedDomainIds: PropTypes.arrayOf(String).isRequired,
    accountId: PropTypes.number.isRequired,
  }

  state = {
    frequency: "90",
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  handleApply = () => {
    this.props.updateProperties(this.props.accountId, this.state.frequency, this.props.selectedDomainIds);
    this.props.closeModal();
  }
 
  render() {
    const frequencies = ["30", "45", "60", "75", "90"]
    return (
      <Modal
        onOk={ this.handleApply }
        okText='Update'
        onCancel={ this.handleCancelModalChanges }
        className='new-domain-set new-standard'
        closable={ false }
        destroyOnClose
        visible
      >
        <p className='title'>{`Edit Scan Frequency (${this.props.selectedDomainIds.size} Properties Selected)`}</p>
        <div className='modal-container'>
          <p>Change Scan Frequency in days</p>
          <Select onChange={ (val) => this.setState({ frequency: val})} value={ this.state.frequency }>
            { 
              frequencies.map(f => (
                <Select.Option value={f}>{f}</Select.Option>
              ))
            }
          </Select>
        </div>
      </Modal>
    );
  }
}