import React, { useState } from 'react';
import { PlusSquareOutlined } from '@ant-design/icons';
import { Input, Tabs } from '../../../../styleguide';
import { Button } from 'antd';
import { useAutoScroll } from '../../../../hooks';
import ActionCookies from './ActionCookies';
import AceEditor from 'react-ace';

const ImplementationType = ({
  handleCustomJSChange,
  handleTagManagerChange,
  handleUrlActionChange,
  handleActionCookiesChange,
  addUrlInput,
  readOnly,
  customJsValue,
  tagManagerValue,
  urlConsentActions,
  actionCookies,
  isUSPRegulation
}) => {
  const autoScrollRef = useAutoScroll(urlConsentActions.size);
  const [activeTabKey, setActiveTabKey] = useState("1");

  return (
    <div className="implementation-type-container">
      <label className="title">
          {isUSPRegulation ? "Hook Configuration": "Implementation Type"}
      </label>
      <Tabs defaultActiveKey="1" className="spsg-normal" onChange={setActiveTabKey} activeKey={activeTabKey}>
        <Tabs.TabPane tab="Custom JS" key="1">
          <AceEditor
            mode="javascript"
            theme="twilight"
            width="100%"
            height="250px"
            value={customJsValue}
            onChange={(value) => handleCustomJSChange(value, 'js')}
            //removes annoying warning msg
            editorProps={{ $blockScrolling: Infinity }}
            readOnly={readOnly}
            key={`just-to-force-rerender-${activeTabKey}`}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Google Tag Manager" key="2">
          {/* <label className="tag-manager title">
            Google Tag Manager
          </label> */}
          <div className="tag-manager-key-val-container">
            <label>
              Key
            </label>
            <Input disabled value="event" />
          </div>
          <div className="tag-manager-key-val-container">
            <label for="tag-manager">
              Value
            </label>
            <Input.TextArea
              id="tag-manager"
              value={tagManagerValue}
              onChange={handleTagManagerChange}
              placeholder='Add Value...'
              disabled={readOnly}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Inline URL" key="3">
          <div ref={autoScrollRef} className="url-consent-actions-container">
            {urlConsentActions.map((actions, i) => (
              <Input
                key={i}
                value={actions.url}
                onChange={(e) => handleUrlActionChange(e, i)}
                disabled={readOnly}
              />
            ))}
          </div>
          <div className="button-container">
            <Button
              shape="circle"
              icon={<PlusSquareOutlined />}
              onClick={addUrlInput} disabled={readOnly}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cookies" key="4">
          <ActionCookies cookies={actionCookies} updateActionCookies={handleActionCookiesChange} readOnly={readOnly}/>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default ImplementationType;