import PropTypes from 'prop-types';
import React from 'react';
import MessageViewCountTable from './MessageViewCountTable.js.jsx';
import MessageChoicesTable from './MessageChoicesTable.js.jsx';

export default class extends React.Component {
    static propTypes = {
        message: PropTypes.object.isRequired,
    };

    render() {
        var message = this.props.message;

        var totalChoices = 0;
        var totalChoicesCompleted = 0;
        var messageHasWhiteListChoice = false;

        for (var key in message.choice_data) {
            var choice = message.choice_data[key];
            totalChoices += choice.count;
            totalChoicesCompleted += choice.count;
            if (choice.choice_type_id === 1) messageHasWhiteListChoice = true;
        }

        return (
            <div className="message-table clearfix">

              <MessageViewCountTable viewCount={ message.view_count } messageName={ message.name }/>

              <MessageChoicesTable data={ message.choice_data } viewCount={ message.view_count } totalChoices={ totalChoices } totalChoicesCompleted={ totalChoicesCompleted } messageHasWhiteListChoice={ messageHasWhiteListChoice } />

            </div>
        )
    }
}
