import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { message, Modal } from 'antd';
import _ from 'lodash';

import MessageRow from './MessageRow.js.jsx';
import WarningMessage from '../../common/WarningMessage.js.jsx';

import { deleteMessage, deleteSiteGroupMessage } from '../../../actions/message_actions';
import { getSiteGroups } from '../../../actions/site_actions';
import { Message } from '../../../records/message_records';
import { getParameterByName } from '../../utils';

class MessageList extends React.Component {
    static propTypes = {
        siteId: PropTypes.string.isRequired,
        messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        deleteMessage: PropTypes.func.isRequired,
        deleteSiteGroupMessage: PropTypes.func.isRequired,
        getSiteGroups: PropTypes.func.isRequired,
    };

    static defaultProps = {
        messages: [],
    };

    state = {
        messageToDelete: null,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.messages.size < this.props.messages.size && this.state.messageToDelete) {
            this.setState({ messageToDelete: null });
            message.success(`Your message has been deleted.`, 3);
        }
    }

    handleDeleteMessage = (e) => {
      e.stopPropagation();
      const siteGroupId = getParameterByName('site_group_id', window.location);
      if (siteGroupId) {
        this.props.deleteSiteGroupMessage(
          siteGroupId, 
          this.props.accountId, 
          this.state.messageToDelete.id, 
          this.state.messageToDelete.name
        );
      } else {
        this.props.deleteMessage(this.props.siteId, this.state.messageToDelete);
      }
    };

    setMessageToDelete = (messageName, messageId) => {
      if (getParameterByName('site_group_id', window.location)) {
        this.setState({ messageToDelete: { name: messageName, id: messageId } });
      } else {
        this.props.getSiteGroups(this.props.accountId, this.props.siteId).then((siteGroups) => {
          if (siteGroups.size) {
            const siteGroupNames = siteGroups.map(sg => sg.name).join(', ').trim();

            Modal.error({
              title: 'Error',
              content: `Message ${ messageName } cannot be deleted because the site is part of site group ${ siteGroupNames }.`,
              className: 'site-group-error',
            });
          } else {
            this.setState({ messageToDelete: { name: messageName, id: messageId } });
          }
        });
      }
    };

    clearMessageToDelete = () => {
      this.setState({ messageToDelete: null });
    };

    render() {
        var warningMessage;
        if (this.state.messageToDelete) {
            warningMessage = <WarningMessage handleDelete={ this.handleDeleteMessage } name={ this.state.messageToDelete.name } handleCancel={ this.clearMessageToDelete } />
        }

        return (
            <div>
              <table className="table card">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Date Created</th>
                    <th>ID</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  { this.props.messages.map(m =>
                      <MessageRow
                        key={ m.id }
                        message={ m }
                        setMessageToDelete={ this.setMessageToDelete }
                        {..._.omit(this.props, 'messages')}
                      />
                  )}
                </tbody>
              </table>
              { warningMessage }
            </div>
        );
    }
}

export default connect(function(){return {}}, { deleteMessage, deleteSiteGroupMessage, getSiteGroups })(MessageList);
