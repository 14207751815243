import React from 'react';
import AntdFormItem from './AntdFormItem.jsx';

export default class extends React.Component {
    render() {
        return (
            <AntdFormItem
              type='textarea'
              {...this.props}
            />
        );
    }
}
