import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';
import { browserHistory } from 'react-router';
import CustomButton from '../../common/CustomButton.js.jsx';
import { PrivacyManager } from '../../../records/privacy_manager_records';

const Option = Select.Option;

const initialURL = (window.CONFIG && window.CONFIG.consentOrigin === 'https://cmp.sp-stage.net') ?
  'https://pm.cmp.sp-stage.net' :
  'https://pm.sourcepoint.mgr.consensu.org';

export default class PrivacyPreview extends React.Component {
  static propTypes = {
    closePreview: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    privacyManager: PropTypes.instanceOf(PrivacyManager).isRequired,
    vendorListId: PropTypes.string,
  }

  state = {
    warningMessagePresent: false,
    currentURL: initialURL,
  };

  handleEdit = () => {
    browserHistory.push('/consent/privacy_manager/edit?id=' + this.props.pm.id);
  };

  handleDelete = () => {
    //get rid of then
    this.props.deletePrivacyManager(this.props.accountId, this.props.pm.id).then(this.setState({ warningMessagePresent: false }));
  }

  toggleWarningMessage = () => {
    this.setState({ warningMessagePresent: !this.state.warningMessagePresent });
  }

  sendDataToPrivacyManager = () => {
    const frame = document.getElementById('privacy-manager-preview');
    const data = {
      data: this.props.privacyManager.toJS(),
      consent_origin: window.CONFIG.consentOrigin,
      vendor_list_id: this.props.vendorListId,
    };
    frame.contentWindow.postMessage(data, '*');
  }

  handleSelect = (currentURL) => {
    this.setState({ currentURL });
  }

  render() {
    const buttons = (
      <div className="panel-buttons">
        <CustomButton type={ CustomButton.types.PLAIN } onClick={ this.props.closePreview }>Close Preview</CustomButton>
        <CustomButton type={ CustomButton.types.SUCCESS } onClick={ this.props.handleSave }>Save</CustomButton>
      </div>
    );

    return (
      <div className='preview-container'>
        <div className='preview-header'>
          <div className='environment-container'>
            <Select
              value={ this.state.currentURL }
              onChange={ this.handleSelect }
              placeholder='Environment...'
            >
              <Option value="https://pm.sourcepoint.mgr.consensu.org">Production</Option>
              <Option value="https://pm.cmp.sp-stage.net">Staging</Option>
            </Select>
          </div>
          <p>{ this.props.name }</p>
          <div className='buttons-container'>
            { buttons }
          </div>
        </div>
        <div className="iframe-container">
          <iframe
            title="privacy-manager-iframe"
            onLoad={this.sendDataToPrivacyManager}
            id="privacy-manager-preview"
            src={`${this.state.currentURL}?preview=true&getDataFromMessage=true`}
          />
        </div>
      </div>
    );
  }
}
