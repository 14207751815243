import { Collapse, Empty, Image, Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import arrowDown from '../../../../../assets/icons/arrow_down.svg';
import Overview from '../Overview';
import DetailedBreakdown from './DetailedBreakdown';
import getSectionByHash, { defaultDetailedPageSectionIndices } from '../getSectionByHash';
import isSameSummaryData from '../isSameSummaryData';

const description = {
  all: 'The graph below demonstrates how your selected properties compare to the selected benchmark properties in relation to how many vendors we have observed that have servers located outside of the EEA. For more details please see the “Detailed Breakdown” section below.',
  average:
    "The average count shows the number of times on average per scan that we have observed the compliance metric you're viewing on your selected properties (average count my properties) and on your benchmark properties (average count benchmark)",
  relative:
    'The relative score is a percentile rank that looks at the specified compliance metric and compares the average performance across your properties with the average performance of your benchmark properties. The higher the relative score the better your privacy and data ethics.',
};

const DataLeavingTheEEA = ({ summaryDiagnose, summaryPending, selectedFilters }) => {
  const isBefore24Jan = moment(selectedFilters?.end).isBefore(moment('2022-01-24'));
  return (
    <div>
      <Spin spinning={summaryPending} size="large">
        {isSameSummaryData(summaryDiagnose, 'Data leaving the EEA') && !isBefore24Jan ? (
          <Collapse
            expandIcon={(props) => (
              <Image className={props?.isActive ? 'active' : ''} src={arrowDown} preview={false} />
            )}
            accordion
            defaultActiveKey={getSectionByHash(location?.hash, defaultDetailedPageSectionIndices)}
          >
            <Collapse.Panel header={<div className="headers secondary_header">Overview</div>}>
              <Overview description={description} summaryData={summaryDiagnose} />
            </Collapse.Panel>
            <Collapse.Panel header={<div className="headers secondary_header">Detailed Breakdown</div>}>
              <DetailedBreakdown />
            </Collapse.Panel>
          </Collapse>
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    summaryDiagnose: store.diagnoseDashboardState.getIn(['summaryDiagnose', 'value']),
    summaryPending: store.diagnoseDashboardState.get('summaryPending'),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
  };
};

export default connect(mapStateToProps, null)(DataLeavingTheEEA);
