import { Map, List, OrderedSet } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

export const initialState = Map({
  privacyManagerList: new StoreRecord({ value: List([]) }),
  savePending: false,
  globalVendors: new StoreRecord({ value: List([]) }),
  sitePrivacyManagerList: new StoreRecord({ value: OrderedSet([]) }),
  sitePrivacyManagersLoaded: false,
});

export default (state = initialState, action) => {
  const lists = state.getIn(['privacyManagerList', 'value']);
  let updatedList;

  switch (action.type) {
    case types.PRIVACY_MANAGER_LIST_PENDING:
      return setPending(state, ['privacyManagerList']);

    case types.PRIVACY_MANAGER_LIST_LOADED:
      return setValue(state, ['privacyManagerList'], action.privacyManagerList.sortBy(l => l.name.toLowerCase()));

    case types.PRIVACY_MANAGER_LIST_ERROR:
      return setError(state, ['privacyManagerList'], action.errorMessage);

    case types.PRIVACY_MANAGER_LOADED:
      updatedList = lists.push(action.privacyManager).sortBy(list => list.name.toLowerCase());
      return setValue(state, ['privacyManagerList'], updatedList);

    case types.PRIVACY_MANAGER_SAVE_PENDING:
      return state.set('savePending', true);

    case types.PRIVACY_MANAGER_CREATED:
      updatedList = lists.push(action.privacyManager).sortBy(list => list.name.toLowerCase());
      return setValue(state, ['privacyManagerList'], updatedList).set('savePending', false);

    case types.PRIVACY_MANAGER_UPDATED:
      updatedList = lists.filterNot(l => l.id === action.privacyManager.id).push(action.privacyManager).sortBy(l => l.name.toLowerCase());
      return setValue(state, ['privacyManagerList'], updatedList).set('savePending', false);

    case types.PRIVACY_MANAGER_DELETED:
      updatedList = lists.filter(l => l.id !== action.id);
      return setValue(state, ['privacyManagerList'], updatedList);

    case types.SITE_PRIVACY_MANAGER_LIST_PENDING:
      return setPending(state, ['sitePrivacyManagerList']);

    case types.SITE_PRIVACY_MANAGER_LIST_LOADED:
      const updatedSitePrivacyMangerList = state.getIn(['sitePrivacyManagerList', 'value']).merge(action.sitePrivacyManagerList).sortBy(l => l.name.toLowerCase());   
      return setValue(state, ['sitePrivacyManagerList'], updatedSitePrivacyMangerList).set('sitePrivacyManagersLoaded', true);

    case types.DUMP_SITE_PRIVACY_MANAGER_LIST:
      const clearedState = state.getIn(['sitePrivacyManagerList', 'value']).clear();
      return setValue(state, ['sitePrivacyManagerList'], clearedState);

    case types.SITE_PRIVACY_MANAGER_LIST_ERROR:
      return setError(state, ['sitePrivacyManagerList'], action.errorMessage).set('sitePrivacyManagersLoaded', true);
  }

  return state;
};
