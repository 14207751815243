import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'antd';

export default class CommonStringInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    value: '',
  }

  render() {
    return (
      <div>
        <label>{ this.props.label }</label>
        <Input
          value={ this.props.value }
          onChange={ this.props.onChange }
        />
      </div>
    );
  }
}
