import React from 'react';
import SVG from 'react-inlinesvg';
import { Modal } from '../../../styleguide';
import  alertIcon  from '../../../assets/images/privacy-lens/alert.svg';
export default class DeletePropertyModal extends React.Component {
  render() {
    let deleteMsg;
    let warningMsg;
    if (this.props.siteToDelete.name) {
      deleteMsg =
        " Are you sure you want to delete Property Group '" +
        this.props.siteToDelete.name +
        "'?";
      warningMsg =
        'Property Group ' +
        this.props.siteToDelete.name +
        ' with ID ' +
        this.props.siteToDelete.siteId +
        ' will be deleted from the records. All the shared campaigns, messages and scenarios will be still available for use within the individual properties that where in this group.';
    } else {
      deleteMsg =
        " Are you sure you want to delete Property '" +
        this.props.siteToDelete.domain +
        "'?";
      warningMsg =
        'Property ' +
        this.props.siteToDelete.domain +
        ' with ID ' +
        this.props.siteToDelete.id +
        ' will be deleted from the records. Data and configurations will be saved if you recreate the site.';
    }
    return (
      <Modal
        wrapClassName="property-delete-modal"
        title={null}
        visible={this.props.siteToDelete ? true : false}
        okText="Delete"
        onOk={() => this.props.handleDelete()}
        onCancel={(e) => this.props.handleCancel(e)}
      >
        <div className="property-delete-icon">
          <SVG src={alertIcon} />
        </div>
        <div className="propert-delete-content">
          <div className="property-delete-header">{deleteMsg}</div>
          <div className="property-delete-note">
            <div>{warningMsg}</div>
          </div>
        </div>
      </Modal>
    );
  }
}
