import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import AntdFormItem from '../../../common/AntdFormItem.jsx'

export default class extends React.Component {
    render() {
        var content = (
            <div>
                1 DAY = 86400s
                <br />
                1 HOUR = 3600s
            </div>
        );

        return (
            <div className="comp-timing-wrapper">
                <AntdFormItem
                  className="comp-timing"
                  isNumeric={true}
                  {...this.props}
                />
                <div className="comp-timing-icon">
                    <Popover placement="right" title="Conversion Tables from Seconds" content={content}>
                        <QuestionCircleOutlined />
                    </Popover>
                </div>
            </div>
        );
    }
}
