import React from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { FilePdfFilled } from '@ant-design/icons';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class ExportPDF extends React.Component {
  printPdf = () => {
    var pdf = pdfMake.createPdf(this.props.docDefinition);
    pdf.download(this.props.filename + ".pdf" || "document.pdf");
  };

  render() {
    return <FilePdfFilled title="PDF" onClick={ () => { this.printPdf(); if (this.props.onClick) this.props.onClick(); } } className={this.props.iconClass || ""} />;
  }
}
