import PropTypes from 'prop-types';
import React from 'react';

export default class DropdownPagesHeader extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div className='dropdown-pages-header'>
        <p>{ this.props.name }</p>
      </div>
    );
  }
}
