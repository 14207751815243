import React from 'react';
import { Table, Select } from 'antd';
import PropTypes from 'prop-types';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line } from 'recharts';
import moment from 'moment';
import { Map, List } from 'immutable';

import { ConsentPerformanceRecord } from '../../../records/consent_performance_records.js';

const Option = Select.Option;
const colors = List(['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabebe', '#469990', '#e6beff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9', '#ffffff', '#000000']);

export default class ConsentVendor extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(ConsentPerformanceRecord),
    loading: PropTypes.bool,
  };

  static defaultProps = {
    data: new ConsentPerformanceRecord(),
    loading: true,
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.data) {
      const data = nextProps.data.vendorData;
      let vData = Map();
      data.forEach((row) => {
        const name = row.get('name');
        if (vData.has(name)) {
          const consent = vData.getIn([name, 'consent']) + Number(row.get('consent_count'));
          const pv = vData.getIn([name, 'pv']) + Number(row.get('pv_count'));
          vData = vData.set(name, Map({ name, chart: false, consent, pv, percent : Number(consent / pv * 100) }));
        } else {
          vData = vData.set(name, Map({
            name,
            chart: false,
            consent: Number(row.get('consent_count')),
            pv: Number(row.get('pv_count')),
            percent: Number(row.get('consent_count')) / Number(row.get('pv_count')) * 100,
          }));
        }
      });

      vData = vData.toList().sort((a,b) => b.get('percent') - a.get('percent'));
      vData = vData.map((v,k) => k < 5 ? v.set('chart', true) : v);

      return {
        tableData : vData,
        pageViews : vData.size ? vData.getIn([0, 'pv']) : 0,
      };
    }
    return null;
  }

  state = {
    tableData : new List(),
    pageViews : 0,
  };

  sortTable = (a,b,key) => {
    return a[key]-b[key];
  };

  getChartData = (arr, tbl) => {
    const chartVendors = this.getChartVendors(tbl);
    let chartData = Map();

    arr.forEach((row) => {
      const name = row.get('name');
      if (chartVendors.has(name)) {
        chartData = chartData.setIn([row.get('ymd'), name], Number(row.get('consent_rate')));
      }
    });

    chartData = chartData.map((v,k) => {
      let obj = v;
      obj = obj.set('date', moment.utc(k).format('MMM Do.'));
      obj = obj.set('utc_date', k);
      return obj;
    });

    return chartData.toList().sort((a, b) => {
      return moment.utc(a.get('utc_date')).diff(moment.utc(b.get('utc_date')));
    });
  };

  getChartVendors = (tbl) => {
    let vendors = Map();
    tbl.forEach((row) => {
      vendors = vendors.set(row.get('name'), true);
    });
    return vendors;
  };

  toggleChart = (vendor, action) => {
    const tbl = this.state.tableData.map((v) => {
      if (v.get('name') === vendor) {
        return v.set('chart', action);
      }
      return v;
    });
    this.setState({tableData: tbl});
  };

  render() {
    let table, chart;
    if (this.props.data) {
      const chartTable = this.state.tableData.filter((r) => r.get('chart'));
      const chartData = this.getChartData(this.props.data.vendorData, chartTable);

      const columns = List([ Map({
        title: 'Vendor',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        sorter: (a,b) => this.sortTable(a,b,'name'),
        render: (text) => <b>{text}</b>,
      }), Map({
        title: 'Consent Rate',
        dataIndex: 'percent',
        key: 'percent',
        width: '20%',
        sorter: (a,b) => this.sortTable(a,b,'percent'),
        render: (text) => text.toFixed(2)+'%',
      }), Map({
        title: 'Consent amount',
        dataIndex: 'consent',
        key: 'consent',
        width: '20%',
        sorter: (a,b) => this.sortTable(a,b,'consent'),
        render: (text) => text.toLocaleString(),
      }), Map({
        title: 'Page Views',
        dataIndex: 'pv',
        key: 'pv',
        width: '20%',
        sorter: (a,b) => this.sortTable(a,b,'pv'),
        render: (text) => text.toLocaleString(),
      }), Map({
        title: '',
        dataIndex: 'name',
        key: 'remove',
        width: '10%',
        render: (text, other) => other.chart ? (
          <div className="chart-toggle" onClick={()=>this.toggleChart(text,false)} role="presentation">
            <i className="fas fa-times-circle fa-lg" />
          </div>
        ) : (
          <div className="table-toggle" onClick={()=>this.toggleChart(text,true)} role="presentation">
            <i className="fas fa-plus-circle fa-lg" />
          </div>
        ),
      })]);

      const chartColumns = List([ Map({
        dataIndex: 'name',
        key: 'circle',
        width: '5%',
        align: 'center',
        render: (text, record, index) => (
          <div className="chart-circle" style={{background: colors.get(index)}} />
        ),
      }), Map({
        title: 'Vendors',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        sorter: (a, b) => parseFloat(a['vendor_name']) - parseFloat(b['vendor_name']),
        render: (text) => <b>{text}</b>,
      }), Map({
        title: 'Consentented PVs',
        dataIndex: 'percent',
        key: 'percent',
        width: '20%',
        sorter: (a, b) => parseFloat(a['percent']) - parseFloat(b['percent']),
        render: (text, other) => <span><b>{text.toFixed(2)+'%'}</b> ({other.consent.toLocaleString()})</span>,
      }), Map({
        dataIndex: 'name',
        key: 'remove',
        width: '5%',
        align: 'center',
        render: (text) => (
          <div className="chart-toggle" onClick={()=>this.toggleChart(text,false)} role="presentation">
            <i className="fas fa-times-circle fa-lg" />
          </div>
        ),
      })]);

      table = <Table bordered dataSource={this.state.tableData.toJS()} columns={columns.toJS()} pagination={{pageSize: 10}} rowKey={r => r.name} />;
      chart = (
        <div className="vendor-chart">
          <div className="vendor-graph">
            <div className="vendor-banner">
              <h1>Consented pageviews by vendor</h1>
              <h2>Total Pageviews<span>{this.state.pageViews.toLocaleString()}</span></h2>
            </div>
            <ResponsiveContainer width='99%' height={250}>
              <LineChart data={chartData.toJS()}>
                <XAxis dy={15} dataKey="date" />
                <YAxis dx={-15} tickFormatter={(t) => t+'%'} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip formatter={(v) => v+'%'} />
                {chartTable.reverse().map((line, i) => {
                  const revIndex = Math.abs(i-chartTable.size+1);
                  return <Line key={'line-'+line.get('name')} strokeWidth={2} dot={false} type="monotone" dataKey={line.get('name')} stroke={colors.get(revIndex)} />;
                })}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="vendor-legend">
            <Table
              dataSource={chartTable.toJS()}
              columns={chartColumns.toJS()}
              pagination={false}
              scroll={{ y: 271 }}
              rowKey={r => 'vc-'+r.name}
            />
            <div className="vendor-srch">
              <i className="fas fa-search fa-lg" />
              <Select
                showSearch
                showArrow={false}
                optionFilterProp="children"
                filterOption={(i,o) => o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0}
                defaultValue="Search vendors..."
                onChange={(event) => this.toggleChart(event, true)}
                value="Search vendors..."
              >
                {this.state.tableData.map((vendor) => {
                  return vendor.get('chart') ? '' : <Option key={'select-'+vendor.get('name')} value={vendor.get('name')}>{vendor.get('name')}</Option>;
                })}
              </Select>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="adblock card vendor-chart-container" style={{display: (this.props.loading ? 'none' : 'block')}}>
        {chart}
        {table}
      </div>
    );
  }
}
