import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';
import PropertySelect from './PropertySelect';
//import Language from './Language';
import PropertyFormRow from './PropertyFormRow';
import { Site, SiteGroup } from '../../../records/site_records';

import { Modal, Input, Chip as Tag, message } from '../../../styleguide';


export default class CreateEditProperty extends React.Component {
  static propTypes = {
    visibleAddProperty: PropTypes.bool.isRequired,
    toggleAddPropertyModal: PropTypes.func.isRequired,
    propertyGroups: ImmutablePropTypes.listOf(PropTypes.instanceOf(SiteGroup))
      .isRequired,
    createOrUpdateSite: PropTypes.func.isRequired,
    editProperty: PropTypes.instanceOf(Site),
    propertyGroup: PropTypes.func,
    multiCampaign: PropTypes.bool.isRequired,
    propertyTypesNum: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  };
  getTypeCode = (type) =>
    this.props.propertyTypesNum.find((pt) => pt.get('code') === type).get('value');

  state = {
    domain: this.props.editProperty.domain,
    type: this.props.editProperty.id
      ? this.getTypeCode(this.props.editProperty.type)
      : '1',
    propertyGroup: this.props.propertyGroup || new SiteGroup({}),
    groupId: this.props.propertyGroup
      ? this.props.propertyGroup.get('siteId')
      : '',
    defaultLanguage: 'EN',
  };

  setValues = (field, value) => {
    if (field === 'domain') {
      if (/^[a-zA-Z0-9-._~*]*$/i.test(value)) {
        this.setState({ [field]: value });
      }
    } else {
      this.setState({ [field]: value });
    }
  };

  setPropertyGroup = (field, value) => {
    const selectedGroup =
      this.props.propertyGroups.find((pg) => pg.siteId === value) ||
      new SiteGroup({});
    this.setState({ propertyGroup: selectedGroup, [field]: value });
  };
  handleOnClick = (e) => {
    e.preventDefault();
    const isDuplicateProperty = this.props.sites
      .map((p) => p.domain)
      .includes(this.state.domain);
    if (isDuplicateProperty) {
      message.warning(`Property already exist`);
    } else {
      message.success(`Property added`);
      this.props.createOrUpdateSite(
        this.state.domain,
        this.state.type,
        this.state.propertyGroup,
        !!this.props.multiCampaign,
      );
      this.props.toggleAddPropertyModal();
    }
  };

  handleUpdate = () => {
    this.props.createOrUpdateSite(
      this.props.editProperty.id,
      this.state.domain,
      this.state.type,
      this.state.propertyGroup,
    );
    this.props.toggleAddPropertyModal();
  }


  onTagClose = () =>
    this.setState({ propertyGroup: new SiteGroup({}), groupId: '' });

  render() {
    let propertyRows = List([
      Map({
        label: 'Property',
        component: (
          <Input
            value={this.state.domain}
            onChange={(e) => this.setValues('domain', e.target.value)}
          />
        ),
      }),
      Map({
        label: 'Type',
        component: (
          <PropertySelect
            value={this.state.type}
            defaultValue="web"
            options={this.props.propertyTypesNum}
            onChange={this.setValues}
            state="type"
          />
        ),
      }),
    ]);
      // Map({
      //   label: 'Default Language',
      //   component: <Language defaultLanguage="EN" />,
      // }),

    if (!this.props.accountFeatures.includes('hide_property_groups')) {
      propertyRows = propertyRows.push(
        Map({
          label: 'Property Group',
          component: (
            <div className="property-row-custom">
              <PropertySelect
                options={this.props.propertyGroups}
                onChange={this.setPropertyGroup}
                value={this.state.groupId}
                state="groupId"
              />
              {this.state.propertyGroup.siteId ? (
                <div className="property-group-selected">
                  <Tag closable onClose={this.onTagClose}>
                    {this.state.propertyGroup.name}
                  </Tag>
                </div>
              ) : null}
            </div>
          ),
        })
      )
    }

    const title = this.props.editProperty.id
      ? `Edit Property (ID: ${this.props.editProperty.id})`
      : 'Add New Property';
    return (
      <Modal
        title={title}
        visible={this.props.visibleAddProperty}
        onOk={this.props.editProperty.id? this.handleUpdate: this.handleOnClick}
        okText="Save"
        okButtonProps={{ disabled: !this.state.domain }}
        onCancel={this.props.toggleAddPropertyModal}
      >
        {propertyRows.map((p, pi) => (
          <PropertyFormRow
            key={pi}
            label={p.get('label')}
            component={p.get('component')}
          />
        ))}
      </Modal>
    );
  }
}
