import React from 'react';
import { Card } from 'antd';

class SdkAndroid extends React.Component {


  render() {
   return (
        <div>
          <Card>
            <h2>Android</h2>
            <h3>How To Install</h3>
            <p>On your app's <code>build.gradle</code> file add the following to the dependencies:</p>
            <pre>{`implementation 'com.sourcepoint.cmplibrary:cmplibrary:2.3.4'`}</pre>
            <p>Android Studio should take care of fetching the <code>cmplibrary</code> and installing it on your project.</p>

            <h3>Quick start</h3>
            <p>You'll first have to set up a campaign on <a href='https://analytics.sourcepoint.com/'>SourcePoint's portal</a>. Ask one of our Sales team members and they will be happy to help you there. Also, if you have any question, our <a href='https://help.sourcepoint.com/dialogue'>Help Portal</a> might have the answer for you.</p>
            <p>On your app, all you need to do is build the <code>ConsentLib</code> with your account id and site name, set its callbacks and run it. For example:</p>
            <pre>{`
              // on an Activity code such as MainActivity.java for example
              @Override
              protected void onCreate(Bundle savedInstanceState) {
                  super.onCreate(savedInstanceState);
                  setContentView(R.layout.activity_main);
                  ConsentLib.newBuilder(<YOUR ACCOUNT ID>, "YOUR SITE NAME", this)
                  .setViewGroup(findViewById(android.R.id.content))
                  .setOnInteractionComplete(new ConsentLib.Callback() {
                      @Override
                      public void run(ConsentLib c) {
                      String aVendorId = "5bf7f5c5461e09743fe190b3";
                      try {
                          c.getCustomVendorConsents([aVendorId], new 
                          ConsentLib.OnLoadComplete() {
                              @Override
                              public void onSuccess(Object result) {
                                  HashSet<CustomVendorConsent> consents = (HashSet) 
                                  result;
                                  Consent consent = consents[0];
                                  if (consent.id.equals(aVendorId))
                                      Log.i("TAG", "Consented to non-IAB vendor: " + 
                                      consent.name);
                                }
                            });
                            } catch (ConsentLibException e) { e.printStackTrace(); }
                      }
                })
                .setOnErrorOccurred(new ConsentLib.Callback() {
                    @Override
                    public void run(ConsentLib c) {
                        Log.d("TAG", "Something went wrong: ", c.error);
                    }
                }).build().run();
              }
            `}</pre>
          <h3>Getting help</h3>
          <p>The complete code can be found in our public <a href='https://github.com/SourcePointUSA/android-cmp-app'>GitHub repository</a></p>
          </Card>
        </div>
    );
  }
}

export default SdkAndroid