import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Checkbox, Tooltip, Tabs, Collapse, Select, Input, Radio, notification } from 'antd';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Vendor, VendorCookie, VendorCookies } from '../../../records/vendor_list_records';
import { Site } from '../../../records/site_records';
import Loading from '../../common/Loading.js.jsx';
import { getScannedVendorsV2, clearScannedVendors } from '../../../actions/vendor_list_actions_v2.js';
import AutoAddNewVendorsCheckbox from './AutoAddNewVendorsCheckbox';
import SelectSitesAndSiteGroups from '../../common/SelectSitesAndSiteGroups';

import { WebsiteScanTable } from './WebsiteScanTable';
import { durationTypes, legalBasis } from './helper';
import VendorCategorization from './VendorCategorization';
import { categorize } from './util.js';
import { InfoCircleFilled, PlusCircleFilled, BorderOutlined, WarningOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

class WebsiteVendorScan extends React.Component {
  static propTypes = {
    vendorListId: PropTypes.string.isRequired,
    existingSiteIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    scannedVendors: ImmutablePropTypes.listOf(PropTypes.instanceOf(Vendor)).isRequired,
    autoScanOn: PropTypes.bool.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,

    getScannedVendorsV2: PropTypes.func.isRequired,
    handleAddVendors: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    toggleAutoScan: PropTypes.func.isRequired,
    clearScannedVendors: PropTypes.func.isRequired,
  };
 
  newRow = () => Map({ purpose: undefined, legalBasis: undefined, vendorScanCategory: undefined });

  getDefConfig = () => {
    let categories = List([]);
    if(this.props.vl.categories.size){
		this.props.vl.categories.map((c) => {
			if (c.categories.size) {
				c.categories.map((subCat) => {
				  categories = categories.push(Map({ purpose: subCat.id || subCat.name, legalBasis: subCat.defaultCustomLegalBasis || 'NOT_APPLICABLE', vendorScanCategory: 'Uncategorized' }));
				});
			} else {
				categories = categories.push(Map({ purpose: c.id || c.name, legalBasis: c.defaultCustomLegalBasis || 'NOT_APPLICABLE', vendorScanCategory: 'Uncategorized' }));
			}
		});
	} else {
		this.props.iabPurposes.map((c) => {
			categories = categories.push(Map({ purpose: c.name, legalBasis: c.defaultCustomLegalBasis || 'NOT_APPLICABLE', vendorScanCategory: 'Uncategorized' }));
		});
	}
    return categories;
  };

  getMappedVendorScanCategorization = () => {
    return this.props.vl.vendorScanCategorizations.filter((vc) => {   
            const vendorScanPurpose = vc.get('vendorListCategoryId') || vc.get('vendorListCategoryName');
            const purposeVl = this.getSubPurposes().find((p)=> (p.get('id') || p.get('name')) == vendorScanPurpose);
            return purposeVl;
           }).map((vc) => {
            const vendorScanPurpose = vc.get('vendorListCategoryId') || vc.get('vendorListCategoryName');
            return Map({ purpose: vendorScanPurpose, legalBasis: vc.get('legalBasis'), vendorScanCategory: vc.get('vendorScanCategory') })
           });
  }

  getDefaultCategorization = () => {
    return this.props.vl.vendorScanCategorizations.size
      ? List([this.newRow(), ...this.getMappedVendorScanCategorization()])
      : List([this.newRow(), ...this.getDefConfig()]);
  };

  constructor(props) {
    super(props);
    this.state = {
      openedDetailVendorIds: List([]),
      siteIdsToScan: this.props.existingSiteIds,
      selectedVendorIds: List([]),
      vendorType: 'IAB',
      showCategorization: false,
      scannedVendors: List([]),
      vendorActiveKey: '1',
      vendorScanCategorizations: this.getDefaultCategorization(),
      useGATPDefaults: false,
      isGoogleAdProdAdded: false,
      gAtpErrorShow: false,
      gATPConfig: List([]),
      modifiedCount: Map({ IAB: 0, CUSTOM: 0, 'CUSTOM-ATP': 0 }),
      noVendorBannerShow : false
    };
    this.toggleVendorSelect = this.toggleVendorSelect.bind(this);
  }

  emptyCookieRow = () => {
    return new VendorCookies({
      key: Math.random(),
      name: undefined,
      domain: undefined,
      cookieLifeSpan: undefined,
      isNew: true,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const update = {};
    const emptyCookieRow = new VendorCookies({
      key: Math.random(),
      name: undefined,
      domain: undefined,
      cookieLifeSpan: undefined,
      isNew: true,
    });
    if (prevState.scannedVendors.size !== nextProps.scannedVendors.size) {
      const vendors = nextProps.vl.vendors.map((v) => v.id);
      update.selectedVendorIds = nextProps.scannedVendors.filterNot((v) => vendors.includes(v.id)).filter((v) => v.isNew).map((v) => v.id);
      const parseCategorization = prevState.vendorScanCategorizations.groupBy((c) => c.get('purpose'));
      const vendorsWrappers = nextProps.vl.vendorsWrappers;
      let iab = 0;
      let custom = 0;
      let customAtp = 0;
      const categorizations = parseCategorization
          .map((gc, gci) =>
            Map({
              name: gci,
              defaultLegalBasis: gc.get(0).get('legalBasis'),
              categories: gc.map((ct) => ct.get('vendorScanCategory') || 'Uncategorized'),
            })
          ).toList();
      update.scannedVendors = nextProps.scannedVendors.map((sv) => {
        let existingVendor = vendorsWrappers.find((v) => v.vendorId == sv.id) || { cookies: List() };
        const nonScanCookies = existingVendor.cookies
          .filterNot((ec) => sv.cookies.map((svc) => svc.name).includes(ec.name))
          .map((nsc) => new VendorCookies(nsc).set('key', Math.random()));
        const updatedCookies = sv.cookies.map((c) => {
          let cookieMod = new VendorCookies(c)
            .set('cookieLifeSpan', 'day' + ' ' + c.cookieLifeSpan)
            .set('key', Math.random())
            .set('colorSchemeApplies', !sv.isNew ? true : false);
          const isOld = existingVendor.cookies.find((ec) => ec.name == c.name);
          if (isOld) {
            if (cookieMod.cookieLifeSpan != isOld.cookieLifeSpan || cookieMod.domain != isOld.domain) {
              cookieMod = cookieMod
                .set('colorSchemeApplies', true)
                .set('modified', true)
                .set('scannedNew', false)
                .set('isNew', false)
                .set('id', isOld.id);
              if (sv.vendorType == 'IAB') {
                iab++;
              }
              if (sv.vendorType == 'CUSTOM' && !sv.isGoogleAtp) {
                custom++;
              }
              if (sv.vendorType == 'CUSTOM' && sv.isGoogleAtp) {
                customAtp++;
              }
            }
          } else {
            cookieMod = cookieMod.set('scannedNew', true).set('isNew', false);
          }
          return cookieMod;
        });
        update[sv.id] = categorizations;
        const isNewVendor = vendors.includes(sv.id);
        return nextProps.globalVendors
          .find((gV) => gV.id == sv.id)
          .set('isNew', isNewVendor ? false : sv.isNew)
          .set('cookies', List([emptyCookieRow, ...updatedCookies.concat(nonScanCookies)]));
      });
      update.modifiedCount = Map({
        IAB: iab,
        CUSTOM: custom,
        'CUSTOM-ATP': customAtp,
      });
    }
    return update;
  }

  handleSelectSite = (siteIds) => this.setState({ siteIdsToScan: List(siteIds) });

  tabChange = (key) => this.setState({ vendorActiveKey: key });

  toggleVendorSelect = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    let selectedVendorIds = List([]);
    const isSelectedAll = typeof e.target.value === 'boolean';
    if (isSelectedAll) {
      const selectedVendorsCountByType = this.selectedVendorsCountByType(e.target.value);
      const rmVendorChk = this.filterVendors(e.target.value).size === selectedVendorsCountByType.size;
      const getSelectedIds = selectedVendorsCountByType.map((sv) => sv.id);
      const selectAll = this.filterVendors(e.target.value).map((v) => v.id).concat(this.state.selectedVendorIds).toSet().toList();
      selectedVendorIds = rmVendorChk ? this.state.selectedVendorIds.filterNot((v) => getSelectedIds.includes(v)): selectAll;
    } else {
      const index = this.state.selectedVendorIds.indexOf(e.target.value);
      selectedVendorIds =
        index != -1
          ? this.state.selectedVendorIds.delete(
              this.state.selectedVendorIds.indexOf(e.target.value)
            )
          : this.state.selectedVendorIds.push(e.target.value);
    }
    this.setState({ selectedVendorIds });
  };

  onVendorTypeChange = (e) => {
    this.setState({
      vendorType: e.target.value,
      vendorActiveKey: '1',
      gAtpErrorShow: false,
    });
  };

  updateVendorCategorization = (vendorId, field, value, index) => {
    const purposeCategories = this.state.vendorScanCategorizations.filter((dc) => dc.get('purpose') == value).map((c) => c.get('vendorScanCategory'));
    let categorization =
      index == 0 && field == 'name'
        ? this.state[vendorId]
            .setIn([index, field], value)
            .setIn([index, 'categories'], purposeCategories.size ? purposeCategories : this.state.useGATPDefaults ? List([]) : List(['Uncategorized']))
        : this.state[vendorId].setIn([index, field], value);
    this.setState({ [vendorId]: categorization });
  };

  openNotification = (message, description) => {
    notification.open({
      message,
      description,
      icon: <WarningOutlined style={{ color: 'rgb(255, 151, 61)' }} />,
    });
  };

  errorCheck = (vendorId) => {
    const checkRowData =
      this.state[vendorId].get(0) ||
      Map({ name: undefined, defaultLegalBasis: undefined });
    if (!checkRowData.get('name') || !checkRowData.get('defaultLegalBasis')) {
      this.openNotification("Can't Add", "Can't add empty categorization");
    } else {
      this.checkFoDuplicates(vendorId)
        ? this.addNewPurposeToVendor(vendorId)
        : this.openNotification(
            'Duplicate',
            "Can't add duplicate categorization"
          );
    }
  };

  addEmptyRow = () => Map({ name: undefined, defaultLegalBasis: undefined, categories: List(['Uncategorized'])});

  addNewPurposeToVendor = (vendorId) => {
    const checkRowData = this.state[vendorId].get(0) || this.addEmptyRow();
    if (checkRowData.get('name') && checkRowData.get('defaultLegalBasis')) {
      this.setState({ [vendorId]: List([this.addEmptyRow(), ...this.state[vendorId]]) });
    }
  };

  checkFoDuplicates = (vendorId) => this.state[vendorId].map((v) => v.get('name')).size === this.state[vendorId].map((v) => v.get('name')).toSet().toList().size;

  deleteVendorPurpose = (vendorId, index) => this.setState({ [vendorId]: this.state[vendorId].delete(index) });

  getSubPurposes = () => {
    let categories = List([]);
    if(this.props.vl.categories.size){
		this.props.vl.categories.map((p, pi) => {
		if (p.categories.size) {
			p.categories.map((subCat) => {
			categories = categories.push(subCat);
			});
		} else {
			categories = categories.push(p);
		}
		});
	}else{
        categories = this.props.iabPurposes.map(iab => iab.set('id', null));
    }
    return categories;
  };

  getIABLegalBasis = (record, vendor) => {
   return categorize(
                    record,
                    {
                      id: vendor.id,
                      vendorType: vendor.vendorType,
                      purposes: vendor.purposes.map(({ id }) => id),
                      legIntPurposes: vendor.legIntPurposes.map(({ id }) => id),
                    },
                    this.props.vl.defaultLegalBasis
                  ).type
  }

  legalBasisWrapper = (record, vendor) => {
    const legalBasisVal = this.getIABLegalBasis(record, vendor);
    const legalBasisObj = legalBasis.find((lb)=> lb.get('value') == legalBasisVal);
    return <div className={`lb-tag ${legalBasisObj.get('customClass')}`}>{legalBasisObj.get('label')}</div>;
  }

  getConfigCols = (vendor) => {
    return List([
      Map({
        title: 'Purpose',
        dataIndex: 'name',
        key: 'name',
        render: (text, record, index) => 
        this.state.vendorType == 'IAB' ? text :
        (
          <Select
            onChange={(value) => this.updateVendorCategorization(vendor.id, 'name', value, index)}
            placeholder="Select"
            value={text}
          >
            {this.getSubPurposes().map((p, pi) => (
              <Option key={pi} value={p.get('id') || p.get('name')}>
                {p.get('name')}
              </Option>
            ))}
          </Select>
        ),
        width: '70%',
      }),
      Map({
        title: 'Legal Basis',
        dataIndex: 'defaultLegalBasis',
        key: 'defaultLegalBasis',
        render: (text, record, index) => 
          this.state.vendorType == 'IAB' ?  this.legalBasisWrapper(record, vendor):
          (<Select
            className="categorization-legal-basis"
            onChange={(value) => this.updateVendorCategorization(vendor.id, 'defaultLegalBasis', value, index)}
            placeholder="Select"
            value={this.state.vendorType == 'IAB' ? this.getIABLegalBasis(record, vendor) : text}
          >
            {legalBasis.map((lb, lbi) => (
              <Option key={lbi} value={lb.get('value')}>
                <span className={`li-tag ${lb.get('customClass')}`}>{lb.get('label')}</span>
              </Option>
            ))}
          </Select>
        ),
        width: '25%',
      }),
      Map({
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text, record, index) =>
          index == 0 ? (
            <Button
              icon={<PlusCircleFilled />}
              className="btn-add-row"
              onClick={() => this.errorCheck(vendor.id)}
            />
          ) : (
            <button
              className="avo-dialogue-icons avo-sp-delete"
              onClick={() => this.deleteVendorPurpose(vendor.id, index)}
            ></button>
          ),
        width: '5%',
      }),
    ]);
  };

  setDuration = (vendorId, unitInterval, index, value, cookieDuration) => {
    const cookieLifeSpan =
      unitInterval == 'unit'
        ? value == 'session'
          ? value
          : value + ' ' + (cookieDuration && cookieDuration.split(' ')[1] ? cookieDuration.split(' ')[1] : 1)
        : cookieDuration.split(' ')[0] + ' ' + value;
    const vendorCookies = this.state.scannedVendors.find((v) => v.id == vendorId).cookies.setIn([index, 'cookieLifeSpan'], cookieLifeSpan);
    const scannedVendors = this.state.scannedVendors.update(
      this.state.scannedVendors.findIndex((item) => item.get('id') === vendorId),
      (item) => item.set('cookies', vendorCookies)
    );
    this.setState({ scannedVendors });
  };

  deleteVendorCookie = (vendorId, index) => {
    const vendorCookies = this.state.scannedVendors.find((v) => v.id == vendorId).cookies.delete(index);
    const scannedVendors = this.state.scannedVendors.update(
      this.state.scannedVendors.findIndex((item) => item.get('id') === vendorId),
      (item) => item.set('cookies', vendorCookies)
    );
    this.setState({ scannedVendors });
  };

  updateCookieField = (vendorId, field, index, e) => {
    e.preventDefault();
    const vendorCookies = this.state.scannedVendors.find((v) => v.id == vendorId).cookies.setIn([index, field], e.target.value);
    const scannedVendors = this.state.scannedVendors.update(
      this.state.scannedVendors.findIndex((item) => item.get('id') === vendorId),
      (item) => item.set('cookies', vendorCookies)
    );
    this.setState({ scannedVendors });
  };

  addNewCookieToVendor = (vendorId) => {
    const vendorCookies = this.state.scannedVendors.find((v) => v.id == vendorId).cookies;
    const scannedVendors = this.state.scannedVendors.update(
      this.state.scannedVendors.findIndex((item) => item.get('id') === vendorId),
      (item) => item.set('cookies', List([this.emptyCookieRow(), ...vendorCookies.setIn([0, 'colorSchemeApplies'], true).setIn([0, 'isNew'], true),]))
    );
    this.setState({ scannedVendors: scannedVendors });
  };

  checkFoDuplicates = (vendorId) => {
    const vendorCookies = this.state.scannedVendors.find((v) => v.id == vendorId).cookies;
    const notDuplicate = vendorCookies.map((c) => c.name).size === vendorCookies.map((c) => c.name).toSet().toList().size;
    return notDuplicate;
  };

  handleCookieErr = (vendorId) => {
    const vendorCookies = this.state.scannedVendors.find((v) => v.id == vendorId).cookies.get(0);
    if (!vendorCookies.get('name') || !vendorCookies.get('domain') || !vendorCookies.get('cookieLifeSpan')) {
      this.openNotification("Can't Add", "Can't add empty cookie");
    } else {
      this.checkFoDuplicates(vendorId) ? this.addNewCookieToVendor(vendorId) : this.openNotification("Duplicate", "Can't add cookies with same name");
    }
  };

  getCookiesCols = (vendorId) => {
    return List([
      Map({
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record, index) => (
          <Input
            placeholder="Name"
            value={text}
            disabled={record.isNew ? false : true}
            className={record.isNew ? '' : 'disabled-input'}
            onChange={(e) => this.updateCookieField(vendorId, 'name', index, e)}
          />
        ),
      }),
      Map({
        title: 'Domain',
        dataIndex: 'domain',
        key: 'domain',
        render: (text, record, index) => (
          <Input
            placeholder="URL"
            value={text}
            onChange={(e) => this.updateCookieField(vendorId, 'domain', index, e)}
          />
        ),
      }),
      Map({
        title: 'Duration',
        dataIndex: 'cookieLifeSpan',
        key: 'cookieLifeSpan',
        render: (text, record, index) => (
          <div className="duration-wrapper">
            <Select
              placeholder="Duration"
              dropdownClassName="cookie-duration"
              value={text ? text.split(' ')[0] : undefined}
              onChange={(value) => this.setDuration(vendorId, 'unit', index, value, text)}
            >
              {durationTypes.map((d, di) => (<Option key={di} value={d}>{d.slice(0, 1).toUpperCase() + d.slice(1)}</Option>))}
            </Select>
            {text && text.split(' ')[0] != 'session' ? (
              <Input
                key={[this.state.vendorType, index].join('_')}
                placeholder="Interval"
                value={text ? text.split(' ')[1] : undefined}
                onChange={(e) => {
                  e.preventDefault();
                  this.setDuration(vendorId, 'interval', index, e.target.value, text);
                }}
              />
            ) : null}
          </div>
        ),
      }),
      Map({
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text, record, index) =>
          index == 0 ? (
            <Button icon={<PlusCircleFilled />}className="btn-add-row" onClick={() => this.handleCookieErr(vendorId)} />
          ) : (
            <button className="avo-dialogue-icons avo-sp-delete" onClick={() => this.deleteVendorCookie(vendorId, index)} />
          ),
        width: '5%',
      }),
    ]);
  };

  toggleCategorization = () => this.setState((prevState) => ({ showCategorization: !prevState.showCategorization, vendorScanCategorizations: this.getDefaultCategorization() }));

  getDomainClassification = (vendorId) => this.state[vendorId].map((vc) => vc.get('categories')).flatten().toSet().toList();

  getSelectedScannedVendors = () => {
    return this.state.scannedVendors
           .filter((v) => this.state.selectedVendorIds.includes(v.id))
           .map((sv) => sv.set('categorization', this.state[sv.get('id')].delete(0)))
           .map((sv) => sv.vendorType == 'IAB' ? sv.set('cookies', List( sv.cookies.delete(0).map((ck) => new VendorCookie(ck.toJS())).toJS() )) : sv.set('domainClassification', this.getDomainClassification(sv.get('id'))).set('cookies', List( sv.cookies.delete(0).map((ck) => new VendorCookie(ck.toJS())).toJS() ) ));
  }

  handleScanOrAddVendors = (e, rescan = false) => {
    const siteIdsToScan = this.state.siteIdsToScan.filterNot((el) => el === 'allSites');
    if (!rescan && this.props.scannedVendors.size) {
      this.props.handleAddVendors(this.getSelectedScannedVendors(), siteIdsToScan);
      this.props.clearScannedVendors();
    } else {
      this.setState({ noVendorBannerShow: true });
      this.props.getScannedVendorsV2(siteIdsToScan.toJS(), this.props.vendorListId, this.props.currentUser.accountId);
    }
  };

  filterVendors = (isNew) => this.state.scannedVendors.filter((v) => (isNew ? v.isNew : !v.isNew) && v.vendorType == this.state.vendorType.split('-')[0] && (this.state.vendorType.split('-')[1] ? v.isGoogleAtp : !v.isGoogleAtp));

  handleCancelModalChanges = () => {
    this.props.clearScannedVendors();
    this.props.handleCloseModal();
  };

  selectedVendorsCountByType = (isNew) => this.state.scannedVendors.filter((v) => this.state.selectedVendorIds.includes(v.id) && (isNew ? v.isNew : !v.isNew) && v.vendorType == this.state.vendorType.split('-')[0] && (this.state.vendorType.split('-')[1] ? v.isGoogleAtp : !v.isGoogleAtp));

  updateDefaultCategorization = (field, value, index, purpose = '') => {
    let categorization = this.state.vendorScanCategorizations;
    switch (field) {
      case 'purpose':
          const findPurposeInstance = categorization.filter(
            (p) => p.get('purpose') == value
          );
          const purposeExist = findPurposeInstance.size > 0;
          if (purposeExist) {
            categorization = categorization.setIn(
              [index, 'legalBasis'],
              findPurposeInstance.get(0).get('legalBasis')
            );
          }
        break;
      case 'legalBasis':
        categorization = categorization.map((c) =>
          c.get('purpose') === purpose ? c.set('legalBasis', value) : c
        );
        break;
    }
    categorization = categorization.setIn([index, field], value);
    this.setState({ vendorScanCategorizations: categorization });
  };

  addNewRow = () => {
    const checkRowData = this.state.vendorScanCategorizations.get(0) || this.newRow();
    if (
      checkRowData.get('vendorScanCategory') &&
      checkRowData.get('purpose') &&
      checkRowData.get('legalBasis')
    ) {
      this.setState({
        vendorScanCategorizations: List([
          this.newRow(),
          ...this.state.vendorScanCategorizations,
        ]),
      });
    }
  };

  deleteDefCategoryRow = (index) => this.setState({ vendorScanCategorizations: this.state.vendorScanCategorizations.delete(index) });

  saveDefCategorization = () => {
    this.setState((prevState) => ({ showCategorization: !prevState.showCategorization }));
    const vendorScanCategorizations = this.state.vendorScanCategorizations.delete(0).map((vc) =>{ 
             const purposeId = this.getSubPurposes().find((p)=> { 
                return (p.get('id') || p.get('name')) == vc.get('purpose')
              }).get('id');
             return Map({ 
                 [purposeId ? 'vendorListCategoryId' : 'vendorListCategoryName']: vc.get('purpose'), 
                 legalBasis: vc.get('legalBasis'), 
                 vendorScanCategory: vc.get('vendorScanCategory')})
	});
    this.props.saveVendorCategorization(vendorScanCategorizations);
    if ( this.state.scannedVendors.filter((sv) => sv.get('vendorType') == 'CUSTOM').size ) {
      this.applyCategorization();
    }
  };

  applyCategorization = () => {
    const parseCategorization = this.state.vendorScanCategorizations.delete(0).groupBy((c) => c.get('purpose'));
    this.state.scannedVendors.filter((sv) => sv.get('vendorType') == 'CUSTOM' && (this.state.useGATPDefaults ? !sv.get('isGoogleAtp') : true)).map((sv) => {
      this.setState({
        [sv.get('id')]: List([this.newRow(), ...parseCategorization
          .map((gc, gci) =>
            Map({
              name: gci,
              defaultLegalBasis: gc.get(0).get('legalBasis'),
              categories: gc.map((ct) => ct.get('vendorScanCategory') || 'Uncategorized'),
            })
          ).toList()])
      });
    });
  };

  handleGoogleAtpDefaults = () => {
    this.setState(
      {
        useGATPDefaults: this.state.isGoogleAdProdAdded ? !this.state.useGATPDefaults : false,
        gAtpErrorShow: this.state.isGoogleAdProdAdded ? false : true,
      },
      () => this.state.useGATPDefaults ? this.setGConfigToVendorLevelCategorization() : this.applyCategorization()
    );
  };

  componentDidMount() {
    this.getGATPConfig();
  }

  emptyConfigRow = () => Map({ name: undefined, defaultLegalBasis: undefined, categories: List([]) });

  setGConfigToVendorLevelCategorization = () => {
    this.state.scannedVendors.filter((sv) => sv.get('isGoogleAtp'))
      .map((v) => {
        this.setState({ [v.get('id')]: List([ this.emptyConfigRow(), ...this.state.gATPConfig.map((gc) => Map({ name: gc.get('purpose'), defaultLegalBasis: gc.get('legalBasis'), categories: List([]) }) ) ]) });
      });
  };

  getGATPConfig = () => {
    let gATPConfig = List([]);
    const isGoogleAdproductPresent = this.props.vl.vendors.find((item) => item.get('iab') && item.get('iabId') === 755);
    if (isGoogleAdproductPresent != undefined) {
      this.getSubPurposes().map((item) => {
        const vendorDetail = item.get('vendorCategorization').find((purpose) => purpose.get('vendorId') === isGoogleAdproductPresent.get('id'));
        gATPConfig = gATPConfig.push(
          Map({
            purpose: item.get('id') || item.get('name'),
            legalBasis: vendorDetail != undefined && vendorDetail.get('type') != 'NOT_ALLOWED' ? vendorDetail.get('type') : 'NOT_APPLICABLE',
          })
        );
      });
      this.setState({ gATPConfig, isGoogleAdProdAdded: true });
    }
  };

  gAtpError = () => this.state.gAtpErrorShow && this.state.vendorType == 'CUSTOM-ATP' ? (<div className="gAtpErr"> Add 'Google Advertising Product' vendor to the list to enable and apply Google ATP vendor defaults.</div>) : null;

  collapseContent = (vendors, isNew) => {
    return (
      <Collapse bordered={false} key={this.state.vendorType + '-' + isNew} accordion={false} expandIconPosition='right'>
        {vendors.map((v) => (
          <Panel
            header={
              <div className="collapse-name" key={v.id}>
                <span onClick={(e) => e.stopPropagation()}>
                  <Checkbox value={v.id} onChange={this.toggleVendorSelect} checked={this.state.selectedVendorIds.includes(v.id)}>
                    {v.get('name')}
                  </Checkbox>
                </span>
              </div>
            }
            key={v.id}
          >
            Vendor Configuration
            <WebsiteScanTable
              dataSource={this.state.vendorType == 'IAB' ? this.getSubPurposes().toJS() : this.state[v.id].toJS()}
              columns={this.state.vendorType == 'IAB' ? this.getConfigCols(v).pop().toJS() : this.getConfigCols(v).toJS()}
            />
            Vendor Cookies
            <WebsiteScanTable dataSource={v.cookies.toJS()} columns={this.getCookiesCols(v.id).toJS()}/>
          </Panel>
        ))}
      </Collapse>
    );
  };

  renderSelectAllOption = (vendors, isNew, vcChoice) => {
    return (
      <React.Fragment>
        {this.gAtpError()}
        {vendors.size ? (
          <div className="collapse-name-all">
            <Checkbox onChange={this.toggleVendorSelect} checked={this.selectedVendorsCountByType(isNew).size === vendors.size} value={isNew}>
              Select All
            </Checkbox>
            {vcChoice}
          </div>
        ) : null}
      </React.Fragment>
    );
  };
  
  noVendorsFound = (vendors, isNew) => vendors.size ? null : <div className="no-vendor-found">No {isNew ? 'new' : 'existing'} {this.state.vendorType.split('-').join(' ')} vendor(s) found in scan</div>;

  render() {
    let loading;
    const isScanning = this.props.pendingRequestsMap.get('scannedVendors');
    if (this.props.pendingRequestsMap.get('sites') || isScanning) {
      loading = <Loading />;
    }

    const useGATPDefaults =
      this.state.vendorType == 'CUSTOM-ATP' ? (
        <Checkbox className="google-atp-def" onChange={this.handleGoogleAtpDefaults} checked={this.state.useGATPDefaults}>
          Use Google ATP Vendor Defaults
        </Checkbox>
      ) : null;

    const vendorCategorization = (
        <Button className="vendor-cat" onClick={this.toggleCategorization}>
          Vendor Categorization
        </Button>
    );

    const iabWarning = (<span className="iab-warning"> We recommend that you set your purpose defaults before adding vendors <InfoCircleFilled /></span>);

    const vcChoice = this.state.vendorType == 'IAB' ? iabWarning : useGATPDefaults;

    const tooltipText = 'If a site does not appear in a dropdown, it means that it has not been set up for vendor scanning. Please contact support to have it set up.';
    const desc = (
      <p className="descr">
        Add a few sites and we will search for all active 3rd party vendors{' '}
        <Tooltip title={tooltipText}><InfoCircleFilled /></Tooltip>
      </p>
    );

    const propertyAndGroupSelection = (
      <SelectSitesAndSiteGroups
        value={this.state.siteIdsToScan.size ? this.state.siteIdsToScan : List([])}
        sites={this.props.sites}
        siteGroups={this.props.siteGroups}
        onSelect={this.handleSelectSite}
        multiple
        siteAccess={this.props.siteAccess}
        disabled={!this.props.vl.vendorScanCategorizations.size}
        customDropdownClass="scan-site-selection"
      />
    );

    const autoAddVendors = (
      <div className="auto-add-rescan-container">
        <AutoAddNewVendorsCheckbox
          checked={this.props.autoScanOn}
          onChange={this.props.toggleAutoScan}
          tooltipPlacement="right"
          icon={<InfoCircleFilled />}
        />
        {this.state.scannedVendors.size ? null : vendorCategorization}
      </div>
    );

    const vendorTypeSelection = (
      <div className="auto-add-rescan-container">
        <Radio.Group onChange={this.onVendorTypeChange} value={this.state.vendorType}>
          <Radio value="IAB">IAB Vendors</Radio>
          <Radio value="CUSTOM">Custom Vendors</Radio>
          <Radio value="CUSTOM-ATP">Custom ATP Vendors</Radio>
        </Radio.Group>
      </div>
    );

    const existingVendors = this.filterVendors(false);
    const newVendors = this.filterVendors(true);

    const modCount = this.state.modifiedCount.get(this.state.vendorType);

    const existingVendorTitle = (
      <React.Fragment>
        Existing Vendors ({existingVendors.size}){' '}
        {modCount != 0 ? (
          <span className="cookie-modified-count">
            {this.state.modifiedCount.get(this.state.vendorType)}
          </span>
        ) : null}
      </React.Fragment>
    );
    const legend = (
      <div className="legend-wrapper">
        <BorderOutlined className="new" /> New Cookies Added
        <BorderOutlined className="old"/> Modified Cookies
      </div>
    );
    const newExistingVendorsTabs = (
      <Tabs onChange={this.tabChange} type="card" activeKey={this.state.vendorActiveKey} tabBarExtraContent={this.state.vendorType != 'IAB' ?  vendorCategorization : null}>
        <TabPane tab={`New Vendors (${newVendors.size})`} key="1">
          {this.renderSelectAllOption(newVendors, true, vcChoice)}
          {this.collapseContent(newVendors, true, vcChoice)}
          {this.noVendorsFound(newVendors, true)}
        </TabPane>
        <TabPane tab={existingVendorTitle} key="2">
          {legend}
          {this.renderSelectAllOption(existingVendors, false, vcChoice)}
          {this.collapseContent(existingVendors, false, vcChoice)}
          {this.noVendorsFound(existingVendors, false)}
        </TabPane>
      </Tabs>
    );

    const content = this.state.showCategorization ? (
      <VendorCategorization
        toggleCategorization={this.toggleCategorization}
        updateDefaultCategorization={this.updateDefaultCategorization}
        vendorScanCategorizations={this.state.vendorScanCategorizations}
        addNewRow={this.addNewRow}
        newRow={this.newRow}
        deleteDefCategoryRow={this.deleteDefCategoryRow}
        isGATP={this.state.vendorType === 'CUSTOM-ATP'}
        useGATPDefaults={this.state.useGATPDefaults}
        gATPConfig={this.state.gATPConfig}
        getSubPurposes={this.getSubPurposes}
      />
    ) : (
      <React.Fragment>
        {loading}
        {desc}
        {propertyAndGroupSelection}
        {this.props.vl.vendorScanCategorizations.size ? (
          <React.Fragment>
            {autoAddVendors}
            {this.props.scannedVendors.size ? (
              <React.Fragment>
                {vendorTypeSelection}
                {newExistingVendorsTabs}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : (
          <div className="no-vendor-categorization-error">Vendor Categorization is not set for this vendor list, it is recommended to set the vendor categorization to begin the scan <InfoCircleFilled /></div>
        )}
      </React.Fragment>
    );

    let rescanButton;
    if (this.state.siteIdsToScan.size && this.props.scannedVendors.size && !this.state.showCategorization) {
      rescanButton = (
        <Button className="rescan" disabled={isScanning} onClick={(e) => this.handleScanOrAddVendors(e, true)}>
          Rescan
        </Button>
      );
    }
    const okText = this.props.vl.vendorScanCategorizations.size == 0 && !this.state.showCategorization ? 'Vendor Categorization' : this.state.showCategorization ? 'Apply' : this.props.scannedVendors.size ? 'Save' : 'Begin Scan';
    const footer = (
      <React.Fragment>
        {rescanButton}
        <Button onClick={this.state.showCategorization ? this.toggleCategorization : this.handleCancelModalChanges} disabled={isScanning}>
          Cancel
        </Button>
        {this.state.showCategorization && this.state.useGATPDefaults && this.state.vendorType == 'CUSTOM-ATP' ? null : (
          <Button
            type="primary"
            onClick={this.props.vl.vendorScanCategorizations.size == 0 && !this.state.showCategorization ? this.toggleCategorization : this.state.showCategorization ? this.saveDefCategorization : this.handleScanOrAddVendors}
            disabled={ (this.state.showCategorization && this.state.vendorScanCategorizations.size === 1) ? true : !this.props.vl.vendorScanCategorizations.size ? false : isScanning ? true : (!this.state.showCategorization && !this.state.siteIdsToScan.size) ? true : false}
          >
            {okText}
          </Button>
        )}
      </React.Fragment>
    );

    const noVendorBannerShow = !isScanning && this.state.noVendorBannerShow && !this.state.scannedVendors.size && !this.state.showCategorization ? <div className="no-vendor-found">No vendors found in scan</div> : (this.props.vl.vendorScanCategorizations.size && !this.props.scannedVendors.size && !this.state.showCategorization) ? <div className="vendor-cat-warning">Note: Vendor Categorization will be applied to custom vendors only.</div> : null;

    return (
      <Modal
        title="Website Vendor Scan"
        wrapClassName="website-vendor-scan-modal"
        visible={this.props.scanVendorsOpen}
        onCancel={this.handleCancelModalChanges}
        footer={footer}
      >
        {content}
        {noVendorBannerShow}
      </Modal>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    scannedVendors: store.vendorListState.getIn(['scannedVendors', 'value']),
    pendingRequestsMap: new Map({
      scannedVendors: store.vendorListState.getIn(['scannedVendors', 'pending']),
    }),
  };
};

export default connect(mapStateToProps, {
  getScannedVendorsV2,
  clearScannedVendors,
})(WebsiteVendorScan);
