import React from 'react';
import BooleanStep from './BooleanStep';

export default class Desktop extends React.Component {
  render() {
    return (
      <BooleanStep
        {...this.props}
        labelText={ 'Device' }
        affirmativeText={ 'Desktop' }
        negativeText={ 'Mobile' }
        propertyName={ 'continue_on_satisfy' }
      />
    );
  }
}
