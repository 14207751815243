import moment from 'moment';
import SiteColumnContent from './SiteColumnContent';

export default (changeLogs) => {
  const width = `${10}%`
  return [
    {
      title: 'Site Group Name',
      dataIndex: 'parent_name',
      width,
      sorter: alphaSorter('parent_name'),
      filters: makeDynamicFilter(changeLogs, 'parent_name'),
      onFilter: onFilter('parent_name')
    },
    {
      title: 'Operation Type',
      dataIndex: 'op_name',
      width,
      sorter: alphaSorter('op_name'),
      filters: makeOpFilters(),
      onFilter: onFilter('op_name')
    },
    {
      title: 'Entity Type',
      dataIndex: 'entity_type',
      width,
      sorter: alphaSorter('entity_type'),
      filters: makeEntityTypeFilters(),
      onFilter: onFilter('entity_type')
    },
    {
      title: 'Entity Name',
      dataIndex: 'entity_name',
      width,
      sorter: alphaSorter('entity_name'),
      filters: makeDynamicFilter(changeLogs, 'entity_name'),
      onFilter: onFilter('entity_name')
    },
    {
      title: 'Properties',
      dataIndex: 'site_names',
      width: '20%',
      filters: makeDistinctNamesFilter(changeLogs),
      onFilter: (value, log) => log.site_names.includes(value),
      // Have to invoke SiteColumnContent as function instead of JSX
      render: (sites) => sites.length ? SiteColumnContent(sites) : 'N/A'
    },
    {
      title: 'User',
      dataIndex: 'username',
      width,
      sorter: alphaSorter('username'),
      filters: makeDynamicFilter(changeLogs, 'username'),
      onFilter: onFilter('username')
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSortOrder: 'descend'
    }
  ];

  function makeOpFilters() {
    return [
      {
        text: 'Create',
        value: 'Create'
      },
      {
        text: 'Edit',
        value: 'Edit'
      },
      {
        text: 'Delete',
        value: 'Delete'
      }
    ];
  }

  function makeEntityTypeFilters() {
    return [
      { text: 'Site Group', value: 'Site Group' },
      { text: 'Site', value: 'Site' },
      { text: 'Stage-campaign', value: 'Stage-campaign' },
      { text: 'Public-campaign', value: 'Public-campaign' },
      { text: 'Parition Log', value: 'Parition Log' },
      { text: 'Scenario', value: 'Scenario' },
      { text: 'Message', value: 'Message' },
      { text: 'Vendor List', value: 'Vendor List' },
      { text: 'Privacy Manager', value: 'Privacy Manager' },
      { text: 'US Privacy Regulation', value: 'US Privacy Regulation'},
      { text: 'Preferences Configuration', value: 'Preferences Configuration'},
      { text: 'Vendor Connection', value: 'Vendor Connection'},
    ];
  }

  function alphaSorter(key) {
    return (a, b) => a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0);
  }

  function onFilter(key) {
    return (value, log) => log[key] === value;
  }

  function makeDynamicFilter(logs, key) {
    return logs.reduce((filters, log) => (
      !filters.some(filter => filter.value === log[key])
        ? [...filters, { text: log[key], value: log[key] }]
        : filters
    ), []);
  }

  function makeDistinctNamesFilter(logs) {
    const distinctNames = {};
    logs.forEach(({ site_names }) => site_names.forEach(siteName => (
      !distinctNames[siteName] && (distinctNames[siteName] = { text: siteName, value: siteName })
    )))
    return Object.values(distinctNames)
  }
}