import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import MessagePreviewHeader from './MessagePreviewHeader';
import { getMessageWithDetail, deleteMessage, importMessage } from '../../../actions/message_actions';
import MessagePreview from './MessagePreview';
import { Message } from '../../../records/message_records.js';
import Loading from '../../common/Loading.js.jsx';
import { getParameterByName } from '../../utils';

export class MessagePreviewPage extends React.Component{

  static propTypes = {
      messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
      getMessageWithDetail: PropTypes.func.isRequired,
      importMessage: PropTypes.func.isRequired,
      deleteMessage: PropTypes.func.isRequired,
  }

  static defaultProps = {
      message: null
  }

  componentDidMount() {
      const isSiteGroup = !!getParameterByName('site_group_id', window.location);
      this.props.getMessageWithDetail(this.props.location.query.site_id, this.props.location.query.message_id, isSiteGroup);
  }

  render() {
    const message = this.props.messages.find(m => m.id === parseInt(this.props.location.query.message_id));

    if (!message || !message.isDetailLoaded()) return <Loading />;

    return (
        <div className="message-preview-page">
          <MessagePreviewHeader
              duplicateMessage={ this.props.importMessage }
              deleteMessage={ this.props.deleteMessage }
              message={ message }
              messageList={ this.props.messages }
              allowEdit={ this.props.location.query.allow_edit === "true" }
          />
          <MessagePreview
            choiceConfig={ message.choice_config }
            messageConfig={ message.message_config }
            variables={ message.variables }
            siteScreenshotUrl={ message.message_config.data.userData.customScreenshotUrl }
          />
        </div>
    )
  }
}

const mapStateToProps = function(store){
    return {
        messages: store.messageState.getIn(['messages', 'value']),
    };
};

export default connect(
    mapStateToProps, {
        getMessageWithDetail,
        deleteMessage,
        importMessage
    },
)(MessagePreviewPage);
