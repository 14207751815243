import PropTypes from 'prop-types';
import React from 'react';
import { List, Map } from 'immutable';
import { Modal, Button, Checkbox } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { PurposeV2 as Purpose, VendorList } from '../../../../../records/vendor_list_records';
import { addIabDeclaredCookiesToPurpose, getVendorIdsForPurpose } from '../wizard/helper';

export default class CreateIabPurposeModal extends React.Component {
  static propTypes = {
    iabPurposesNotInThelist: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)).isRequired,
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    closeModal: PropTypes.func.isRequired,
    handleApply: PropTypes.func.isRequired,
  }

  state = {
    purposesToAdd: List([]),
  }

  togglePurposeToAdd = (e, purpose) => {
    let updatedPurposes = this.state.purposesToAdd;
    if (e.target.checked) {
      updatedPurposes = updatedPurposes.push(purpose);
    } else {
      updatedPurposes = updatedPurposes.filterNot(p => p.id === purpose.id);
    }
    this.setState({ purposesToAdd: updatedPurposes });
  }

  handleOnOk = () => {
    const purposesToAdd = this.state.purposesToAdd.map(p=> p.id === p.iabPurposeRef ? p.set("id", null) : p);
    const purposesToAddWithVendorIds = purposesToAdd.map(p => {
    return p
      .set('vendorIds', getVendorIdsForPurpose(this.props.vendorList.vendors, p))
      .set('vendorCategorization', addIabDeclaredCookiesToPurpose(this.props.vendorList.vendors, p, this.props.vendorList.defaultLegalBasis));
    });
    const updatedCategories = this.props.vendorList.categories.concat(purposesToAddWithVendorIds);
    let stacks = List([]);
    let categories = List([]);
    updatedCategories.forEach(category => {
      if (category.type.includes('STACK')) {
        stacks = stacks.push(category);
      } else {
        categories = categories.push(category);
      }
    });
    const sortedCategories = categories.sort((a, b) => {
      if (a.iabId < b.iabId) { return -1; }
      if (a.iabId > b.iabId) { return 1; }
      if (a.iabId === b.iabId) { return 0; }
    });
    this.props.handleApply(this.props.vendorList.set('categories', stacks.concat(sortedCategories)));
    this.props.closeModal();
  }



  render() {
    return (
      <Modal
        className='add-custom-purpose'
        destroyOnClose
        visible={ this.props.visible }
        closable={ false }
        okText='Add Selected IAB Purposes'
        onOk={ this.handleOnOk }
        onCancel={ this.props.closeModal }
      >
        {
          this.props.iabPurposesNotInThelist.map(p => (
            <div className='purpose-container flex-row'>
              <Checkbox
                onChange={ (e) => this.togglePurposeToAdd(e, p) }
                checked={ this.state.purposesToAdd.map(purpose => purpose.id).includes(p.id)}
              />
              <div className='purpose'>
                <p className='title'>{ p.name }</p>
                <p>{ p.description} </p>
              </div>
            </div>
          ))
        }
      </Modal>
    );
  }
}
