import 'whatwg-fetch';

import { checkStatus, addToFetched } from './helper.js';
import { User } from '../records/account_records';
import { updateAccountFeatures } from '../actions/account_actions.js';
const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

export function updateAccount(accountId, features, zendeskOrganization) {

  const url = `${permissionsSvcBaseUrl}/accounts`;

  let body = {
    accountId
  };

  if (typeof features === "object" && features !== null) { body.features = features }
  if (typeof zendeskOrganization === "string") { body.zendeskOrganization = zendeskOrganization }

  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      addToFetched(url);
      updateAccountFeatures(resp);
      return new User(resp);
    });
}
