export const styles = {
  scenarioConditionPanel: {
    display: 'flex',
    flex: 1,
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FAFBFC',
    padding: '10px',
    position: 'fixed',
    zIndex: 1,
    width: '75%',
  },
  scenarioConditionPanelCommon: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    alignItems: 'flex-start',
    padding: '5px',
    borderLeft: '1px solid #ddd',
  },
  scenarioConditionPanelTwo: {
    display: 'flex',
    flex: 1,
    padding: '10px',
    width: '100%',
    background: '#F4F6F8 0% 0% no-repeat padding-box',
    marginLeft: '1%',
    border: '1px solid #D4D4D4',
    borderRadius: '6px',
  },
  scenarioConditionPanelCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '10px',
    width: '100%',
    background: '#F4F6F8 0% 0% no-repeat padding-box',
    marginLeft: '1%',
    border: '1px solid #D4D4D4',
    borderRadius: '6px',
    marginLeft: '1%',
  },
  conditionHeader: {
    padding: '10px',
    background: '#F9FAFB',
  },
  scenarioInnerWrapper: {
    display: 'flex',
    flex: 0.7,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '10px',
  },
  scenarioInnerWrapperIP: {
    display: 'flex',
    flex: 0.7,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '10px',
  },
  IPInputContainer: {
    marginTop: '10px',
    width: '50%',
    marginLeft: '3%',
  },
  IPInput: {
    marginTop: '10px',
    border: '0px',
    borderBottom: '1px solid #DFDFDF',
    background: 'transparent',
  },
  tagsWrap: {
    marginTop: '10px',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  tagsContainerIP: {
    backgroundColor: '#E0EDFE',
    color: '#1C6BE3',
  },
  scenarioButtonWrapper: {
    width: '100%',
    display: 'flex',
    flex: 0.3,
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    marginRight: '25px',
  },
  scenarioMarginLeft: { marginLeft: '10px' },

  scenarioInnerWrapperTwo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: "center",
    marginTop: '10px',
    width: '100%',
  },

  scenarioItemWrapper: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    marginLeft: '20px',
    flex: 1,
  },
  scenarioItemWrapperRow: {
    marginTop: '10px',
    display: 'flex',
    // flexDirection: "column",
    alignSelf: 'flex-end',
    marginLeft: '20px',
  },
  scenarioItemWrapperFirst: {
    // width: "162px",
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    marginLeft: '10px',
    flex: 1,
  },
  valueWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    marginLeft: '10px',
    flex: 0.3,
  },
  stringWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    marginLeft: '10px',
    flex: 0.8,
  },
  scenarioItemFirst: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    marginTop: '10px',
    flex: 0.3,
  },

  scenarioItemNumRng: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    marginTop: '10px',
    flex: 0.1,
  },
  itemStyleGeneral: {
    marginTop: '15px',
  },
  itemStyleGeneralNumRng: {
    marginTop: '15px',
    width: 'auto',
    outline: 'none',
  },
  scenarioButtonWrapperTwo: {
    width: '100%',
    display: 'flex',
    flex: 0.3,
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  inputStyle: {
    paddingRight: '30px',
  },
  conditionDropdown: {
    width: '280px'
  }
};
