import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';
import { GEO_FILTER_LIST } from '../../constants';

const Option = Select.Option;
const geoFilter = GEO_FILTER_LIST;

class AllLocations extends React.Component {
  static propTypes = {
    selectCountry: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(i,o) => o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0}
        className='locations-select'
        defaultValue='All'
        style={{width: 120}}
        onChange={this.props.selectCountry}
      >
        {geoFilter.map((country) => <Option key={country} value={country}>{country}</Option>)}
      </Select>
    );
  }
}

export default AllLocations;