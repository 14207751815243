import React from "react";
import { Collapse } from "antd";
import { Table } from "../../../styleguide";
import { RightOutlined } from '@ant-design/icons';
import { Map, List } from "immutable";
import moment from 'moment';
import RecentChangesModal from "../../consent/performanceNew/RecentChangesModal";
import ExportToolTip from "../../ccpa/dataprivacy/ExportToolTip";

import {
  ResponsiveContainer,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend,
} from "recharts";
import {
  LINE_GRAPH_COLOR_LIST,
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST,
} from "../../../constants";
import {
  absDataFormater,
  percentDataFormater,
  createCSVExportData,
} from "../performanceNew/helper";
import CustomPopover from "../../common/CustomPopover";
import { viewsStatusTooltipContent, userStatusTooltipContent } from "./instructionalText.js";
import FilterColumns from './FilterColumns';
import { mapReduceSum, mapReduceAvg, trimString } from './helper';

const { Panel } = Collapse;

export const TooltipRow = (props) => (
  <div className="tooltip-entity-container">
    <div className="tooltip-entities">{props.label} : </div>
    <span className={props.customClass}>{props.value}</span>
  </div>
);

export default class ConsentPerformanceGraph extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      recentChangesModal: false,
      lineToggle: Map({
        total: true,
        fullconsent: true,
        rejectedall: false,
        legitimateintobj: false,
        prevfullconsent: false,
        other: false,
        noaction: false,
      }),
      propertyTableData: List(),
      rechartMargin: { top: 5, right: 30, left: 20, bottom: 15 },
      tooltipVisibility: "visible",
      proValConvert: "number",
      omitCols: List(['date']),
      viewsCols: this.viewsCols(),
    };
  }

  toggleLine = (tKey) => {
    let toggles = this.state.lineToggle;
    const inverse = !toggles.get(tKey);
    toggles = toggles.set(tKey, inverse);
    this.setState({ lineToggle: toggles });
  };

  setGraphType = (graphType) => {
    this.setState({ graphType });
  };

  showModal = () => {
    this.setState({
      recentChangesModal: true,
    });
  };
  handleCancel = (e) => {
    this.setState({
      recentChangesModal: false,
    });
  };
  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  renderTooltip = (props) => {
    let propertyDate;
    let logData;
    let tooltipData;
    if (props.payload && props.payload.length > 0 && props.payload[0]) {
      propertyDate = props.payload[0].payload.date;
      logData = this.props.changeLogData.filter((item) => {
        return item.get("date") == propertyDate;
      });
      tooltipData = logData.get(0);
    }

    if (tooltipData) {
      return (
        <div className="tooltip-custom">
          <TooltipRow
            label="Change Type"
            value={this.capitalize(tooltipData.get("operationtype"))}
            customClass="tooltip-custom-text-bold"
          />
          <TooltipRow label="Date" value={tooltipData.get("date")} />
          <TooltipRow
            label="Entity Type"
            value={tooltipData.get("entitytype")}
          />
          <TooltipRow label="Entity" value={tooltipData.get("entitynames")} />
          <TooltipRow
            label="Properties"
            value={trimString(tooltipData.get("properties"))}
            customClass="tooltip-custom-text-style"
          />
          <TooltipRow label="User" value={tooltipData.get("user")} />
        </div>
      );
    }
    return null;
  };

  showTooltip = () => {
    return (
      <Tooltip
        content={this.renderTooltip}
        filterNull={true}
        active={false}
        cursor={false}
      />
    );
  };

  drawGraph = (graphData) => {
    let lineArr = [];
    graphData.map((d, j) => {
      if (this.state.lineToggle.get("total")) {
        lineArr.push(
          <Line
            key={"t-" + j}
            dot={false}
            yAxisId={
              this.state.lineToggle.get("fullconsent") ||
              this.state.lineToggle.get("rejectedall") ||
              this.state.lineToggle.get("legitimateintobj") ||
              this.state.lineToggle.get("prevfullconsent") ||
              this.state.lineToggle.get("other") ||
              this.state.lineToggle.get("noaction")
                ? "right"
                : "left"
            }
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={(this.props.isViewComp ? 'totalpageviews' : 'totalusers') + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(0)}
          />
        );
      }
      if (this.state.lineToggle.get("fullconsent")) {
        lineArr.push(
          <Line
            key={"s-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"fullconsent" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(1)}
          />
        );
      }
      if (this.state.lineToggle.get("legitimateintobj")) {
        lineArr.push(
          <Line
            key={"l-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"legitimateintobj" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(2)}
          />
        );
      }
      if (this.state.lineToggle.get("rejectedall")) {
        lineArr.push(
          <Line
            key={"r-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"rejectedall" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(3)}
          />
        );
      }
      if (this.state.lineToggle.get("prevfullconsent")) {
        lineArr.push(
          <Line
            key={"n-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"prevfullconsent" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(4)}
          />
        );
      }
      if (this.state.lineToggle.get("other")) {
        lineArr.push(
          <Line
            key={"o-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"other" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(5)}
          />
        );
      }
      if (this.state.lineToggle.get("noaction")) {
        lineArr.push(
          <Line
            key={"a-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"noaction" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(6)}
          />
        );
      }
    });
    return lineArr;
  };
  setPropertyValConvert = (value) => {
    this.setState({ proValConvert: value });
  };
  getPropertyName = (siteId) => {
    let indexGroup = this.props.siteGroups.findIndex((item) => {
      return item.get("siteId") === siteId;
    });
    let index = this.props.sites.findIndex((item) => {
      return item.get("id") === siteId;
    });
    let value =
      indexGroup >= 0
        ? this.props.siteGroups.getIn([indexGroup, "name"])
        : this.props.sites.getIn([index, "domain"]);
    return value;
  };
  viewsCols = () => {
    return List([
      Map({
        title: () => <React.Fragment>Date{this.props.datePicker != 'QUARTER' ? <React.Fragment><br/>(YYYY-MM-DD)</React.Fragment> : null}</React.Fragment>,
        titleText: 'Date',
        dataIndex: "date",
        sorter: (a, b) =>
          moment(a.date, "YYYY-MM-DD").valueOf() -
          moment(b.date, "YYYY-MM-DD").valueOf(),
        className: "left-align",
        defaultSortOrder: "descend",
        render: (text, record) => <span>{record.fancyDate}</span>,
      }),
      Map({
        title: 'Total',
        dataIndex: this.props.isViewComp ? "totalpageviews" : "totalusers",
        sorter: (a, b) => this.props.isViewComp ? a.totalpageviews - b.totalpageviews : a.totalusers - b.totalusers,
        className: "right-align",
        render: (text) => <span>{absDataFormater(text)}</span>,
      }),
      Map({
        title: 'Accept All', 
        dataIndex: "fullconsent",
        sorter: (a, b) => a.fullconsent - b.fullconsent,
        className: "right-align",
        render: (text, record) => <div className="consent-table-metrics">
                            <span className="tbl-metric-per">{record.fullconsentper}</span> <br/>
                            {absDataFormater(text)}
                          </div>,
      }),
      Map({
        title: 'Legitimate Interest Only',
        dataIndex: "legitimateintobj",
        sorter: (a, b) => a.legitimateintobj - b.legitimateintobj,
        className: "right-align",
        render: (text, record) => <div className="consent-table-metrics">
                            <span className="tbl-metric-per">{record.legitimateintobjper}</span> <br/>
                            {absDataFormater(text)}
                          </div>,
      }),
      Map({
        title: 'Reject All',
        dataIndex: "rejectedall",
        sorter: (a, b) => a.rejectedall - b.rejectedall,
        className: "right-align",
        render: (text, record) => <div className="consent-table-metrics">
                          <span className="tbl-metric-per">{record.rejectedallper}</span> <br/>
                            {absDataFormater(text)}
                          </div>,
      }),
      Map({
        title: 'Previous Accept All',
        dataIndex: "prevfullconsent",
        sorter: (a, b) => a.prevfullconsent - b.prevfullconsent,
        className: "right-align",
        render: (text, record) => <div className="consent-table-metrics">
                            <span className="tbl-metric-per">{record.prevfullconsentper}</span> <br/>
                            {absDataFormater(text)}
                          </div>,
      }),
      Map({
        title: 'All other choices',
        dataIndex: "other",
        sorter: (a, b) => a.other - b.other,
        className: "right-align",
        render: (text, record) => <div className="consent-table-metrics">
                            <span className="tbl-metric-per">{record.otherper}</span> <br/>
                            {absDataFormater(text)}
                          </div>,
      }),
      Map({
        title: 'No choices made',
        dataIndex: "noaction",
        sorter: (a, b) => a.noaction - b.noaction,
        className: "right-align",
        render: (text, record) => <div className="consent-table-metrics">
          <span className="tbl-metric-per">{record.noactionper}</span> <br/>
          {absDataFormater(text)}
        </div>,
      }),
    ]);
  }
  setViewCols = (filteredCols) => {
    this.setState({viewsCols: this.viewsCols().filter((cols) => filteredCols.includes(cols.get('dataIndex')))});
  }
  render() {
    var tableData = this.props.consentPropertyData.map((items) =>
      items.get(this.props.isViewComp ? "propertyTableData" : "userStatusTableData")
    );
    var graphData = this.props.consentPropertyData.map((items) =>
      items.get(this.props.isViewComp ? "propertyGraph" : "userStatusGraph")
    );

    let graphList = List();
    let unionList = List();
    graphData.map((g) => {
      unionList = unionList
        .toSet()
        .union(g.toSet())
        .toList();
    });

    let groupedByDates = unionList.groupBy((dataPoint) =>
      dataPoint.get("date")
    );
    groupedByDates.map((item) => {
      let mergeMap = Map();
      item.map((m) => {
        mergeMap = mergeMap.merge(m);
      });
      graphList = graphList.push(mergeMap);
    });
    graphList = graphList.sort(
      (a, b) =>
        moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
    );

    let graphTitle = graphList.size
      ? moment(graphList.first().get("date")).format("MMMM YYYY")
      : "";
    const renderLegend = () => {
      return (
        <React.Fragment>
          {graphData.map((entry, index) => (
            <div className="legend-custom" key={`legend-${index}`}>
              <svg height="5" width="28">
                <line
                  x1="0"
                  y1="0"
                  x2="200"
                  y2="0"
                  style={{
                    stroke: "#555758",
                    strokeWidth: "5",
                    strokeDasharray: LINE_GRAPH_STROKE_PATTERN_LIST.get(index),
                  }}
                />
              </svg>
              {this.props.criteriaNames.get(index)}
            </div>
          ))}
        </React.Fragment>
      );
    };

    const tableTitle = (
      <div className="table-title-wrapper">
        <div className="table-title">
          {this.props.isViewComp ? "Page Views" : "Unique Users"} Performance Details{" "}
          <CustomPopover theme="filled" tooltipContent={this.props.isViewComp ? viewsStatusTooltipContent : userStatusTooltipContent} />
        </div>
        <div className="msg-action-wrapper">
          <FilterColumns columns={this.viewsCols()} omitCols={this.state.omitCols} setCols={this.setViewCols}/>
          <a onClick={this.showModal}>
            View Recent Changes{" "}
            <RightOutlined/>
          </a>
        </div>
      </div>
    );

    let propertyHeaders = this.viewsCols()
      .map((item) => Map({ label: item.get("titleText") || item.get("title"), key: item.get("dataIndex") }))
      .insert(1, Map({ label: "Property/Property Group", key: "propertyname" }))
      .insert(4, Map({ label: "Accept All %", key: "fullconsentper" }))
      .insert(6, Map({ label: "Legitimate Interest Only %", key: "legitimateintobjper"}))
      .insert(8, Map({ label: "Reject All %", key: "rejectedallper" }))
      .insert(10, Map({ label: "Previous Accept All %", key: "prevfullconsentper" }))
      .insert(12, Map({ label: "All other choices %", key: "otherper" }))
      .insert(14, Map({ label: "No choices made %", key: "noactionper" }));

    const metrics = List([
                         {'title': "Total", dataIndex: "total", metricPerVal: mapReduceSum(tableData, this.props.isViewComp ? 'totalpageviews' : 'totalusers'), metricAbsVal: ''},
                         {'title': "Accept All", dataIndex: 'fullconsent', metricPerVal: mapReduceAvg(tableData, 'fullconsentper'), metricAbsVal: mapReduceSum(tableData, 'fullconsent')},
                         {'title': "Legitimate Interest Only", dataIndex: 'legitimateintobj', metricPerVal: mapReduceAvg(tableData, 'legitimateintobjper'), metricAbsVal: mapReduceSum(tableData, 'legitimateintobj')},
                         {'title': "Reject All", dataIndex: 'rejectedall', metricPerVal: mapReduceAvg(tableData, 'rejectedallper'), metricAbsVal: mapReduceSum(tableData, 'rejectedall')},
                         {'title': "Previous Accept All", dataIndex: 'prevfullconsent', metricPerVal: mapReduceAvg(tableData, 'prevfullconsentper'), metricAbsVal: mapReduceSum(tableData, 'prevfullconsent')},
                         {'title': "All other choices", dataIndex: 'other', metricPerVal: mapReduceAvg(tableData, 'otherper'), metricAbsVal: mapReduceSum(tableData, 'other')},
                         {'title': "No choices made", dataIndex: 'noaction', metricPerVal: mapReduceAvg(tableData, 'noactionper'), metricAbsVal: mapReduceSum(tableData, 'noaction')},
                    ]);
    return (
      <React.Fragment>
        <div className="property-tabs">
          {metrics.map((mt, index) =>
            <div
              key={index}
              className={`metrices-toggle-wrapper ${this.state.lineToggle.get(mt.dataIndex)? `toggle-line${index+1}-active`: `toggle-line${index+1}`}`}
              onClick={() => this.toggleLine(mt.dataIndex)}
            >
              <div className="metric-label" style={this.state.lineToggle.get(mt.dataIndex) ? { fontWeight: 600} : null}>
                {mt.title}
                {mt.titleNote ? <React.Fragment><br/><span className="metric-label-note">{mt.titleNote}</span></React.Fragment> : null}
              </div>
              <div className="metric-total">
                <span className="metric-per">{mt.metricPerVal}</span><br/>
                {mt.metricAbsVal ? <span className="metric-abs">{mt.metricAbsVal}</span> : <span className="metric-abs">&nbsp;</span>}
              </div>
            </div>)}
          <div className="instructional-text-icon">
            <CustomPopover theme="filled" tooltipContent={this.props.isViewComp ? viewsStatusTooltipContent : userStatusTooltipContent} />
          </div>
        </div>
        <div className="property-title-container">
          <div className="graph-desc-container">
            <span className="property-title">{this.props.isViewComp ? "Page Views" : "Unique Users"} Performance Details</span>
            <br />
            <span className="property-desc">
              Trend over time, can be viewed on weekly, monthly or quarterly basis.
            </span>
          </div>
        </div>

        <ResponsiveContainer height={300}>
          <LineChart
            width={730}
            height={270}
            data={graphList.toJS()}
            margin={this.state.rechartMargin}
          >
            <CartesianGrid strokeDasharray="1 1" vertical={false}/>
            <XAxis dataKey="fancyDate" tickLine={false}>
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              axisLine={false}
              tickLine={false}
              type="number"
              yAxisId="left"
              domain={[0, "dataMax"]}
              tickFormatter={
                this.state.lineToggle.get("fullconsent") ||
                this.state.lineToggle.get("rejectedall") ||
                this.state.lineToggle.get("legitimateintobj") ||
                this.state.lineToggle.get("prevfullconsent") ||
                this.state.lineToggle.get("other") ||
                this.state.lineToggle.get("noaction")
                  ? percentDataFormater
                  : absDataFormater
              }
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              type="number"
              yAxisId="right"
              orientation="right"
              domain={[0, "dataMax"]}
              tickFormatter={absDataFormater}
            />
            {this.showTooltip()}
            <Legend verticalAlign="top" content={renderLegend} />
            {this.drawGraph(graphData)}
          </LineChart>
        </ResponsiveContainer>

        {tableTitle}
        <div className="collapsibleTable">
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            {tableData.map((item, i) => {
              const exportData = item.get("exportdata").map((e) => {
                let exp = e.set(
                  "propertyname",
                  this.getPropertyName(e.get("siteid"))
                );
                return exp;
              });
              const headerCustom = (
                <React.Fragment>
                  {`Criteria ${i+1}: ${this.props.criteriaNames.get(i)}`}
                  <ExportToolTip
                    filename={this.props.isViewComp? "Page Views Performance Details" : "Unique Users Performance Details"}
                    selectedTabIndex={i + 1}
                    exportData={createCSVExportData(
                      propertyHeaders,
                      exportData
                    ).toJS()}
                    criterialist={this.props.criteriaList}
                    criteriaNames={this.props.criteriaNames}
                    sites={this.props.sites}
                    pdfTitle={this.props.isViewComp? "Page Views Performance Details" : "Unique Users Performance Details"}
                  />
                </React.Fragment>
              );
              return (
                <Panel header={headerCustom} key={i + 1}>
                  <Table
                    columns={this.state.viewsCols.toJS()}
                    dataSource={item.get("aggData").toJS()}
                    pagination={{
                      total: item.get("aggData").size,
                      position: ['bottomCenter'],
                      showTotal: (total) => `Total: ${total}`,
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                      locale: { items_per_page: "  Records per page" }
                    }}
                    className="consnet-tableCSS"
                    rowKey={(r) => r.key}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>

        <RecentChangesModal
          changeLogData={this.props.changeLogData}
          recentChangesModal={this.state.recentChangesModal}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    );
  }
}
