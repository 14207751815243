const OLD_REDBUD_CONSENT_TYPE_MAPPING = {
  /*Accept*/ "d515efc9-69a9-4688-9690-a5eb1cbb219e": [
    "4d8f5ba6-550b-4dd0-9e33-0c2e4b4bbe86",/*All*/ 
    "f1141f5f-b623-437f-b79b-1f8241717f4b",/*AllCustom*/ 
    "2c552348-c76c-4e53-b3ca-666e5b50a8b9",/*AllLogin*/ 
    "78cca17a-1555-461a-b64e-a459ccd36b02",/*ConsInfoStorAcc*/ 
    "f543168e-ba21-426f-b5c8-928bbd86b857",/*PersOnly*/ 
  ],
  /*Opt Out*/ "ed712e85-6f6c-4f09-a914-1a8d11a8cdd7": [
    "6ad64e0f-4a84-4b4f-bab2-d5b3e2b3d061",/*DoNotSell*/ 
    "5c2a5dd3-2c33-4a71-b1db-3478ed991afa",/*DoNotSellOrShare*/ 
    "b4e0659a-1bb2-4efd-a800-92b0f5b8279b",/*DoNotShare*/ 
    "6435d3b8-f046-4a98-b612-812efec9c4a6",/*NoSellInfo*/ 
    "26e5f19d-993a-4199-a158-4f9859096a7b",/*NoSellInfoCustom*/ 
  ],
  /*Other Action*/ "fb16e81d-b596-49bb-8653-46200f0c9d45": [
    "f7be7dc6-42d3-43c1-9de3-1ed4a2d3bbc5",/*AllPrev*/ 
    "203e34da-56f7-4feb-8f64-59c74236c880",/*BefConsent*/ 
    "092a4481-9c47-41bf-b616-faaff2f17a2c",/*ContScansAccept*/ 
    "96c7261b-b9f2-4e89-9dcc-032fbecd2917",/*Custom*/ 
    "de57ca8f-f80b-4f7c-9ec2-647b8663a3e3",/*Login*/ 
    "edd306d2-325b-4dd1-95da-2ae087f08600",/*LoginBeforeCons*/ 
    "95e40f42-a0c9-4467-b074-6609338f7903",/*NewCMP*/ 
    "046d5958-3405-4850-ad3d-a14373bd1a3c",/*NoOptOut*/ 
    "e9672edf-5907-42cf-8bce-02912a40171b",/*scanondemand*/ 
  ],
  /*Reject*/ "b6a6532c-955b-43bb-a0ef-1a8ab551ef65": [
    "610435fd-00a7-4b78-9f5e-4c9226c2f624",/*DeclineLogin*/ 
    "e6616730-eae5-41ab-9295-bdba0bdfdce8",/*NoCons*/ 
    "6761a91d-c33d-4b7d-bce6-dcca82b770ea",/*NoConsAdv*/ 
    "4752bd45-d340-4d74-b421-8fc36b9268f3",/*NoConsAdvPartners*/ 
    "60a37a6c-b684-45da-a21d-15f30e2a8c5a",/*NoConsAppNexus*/ 
    "ab707e05-4ad3-4dc8-b217-749ccd07b1be",/*NoConsCookies*/ 
    "7ee29fd3-1d13-46e8-889d-0d23a79d6df3",/*NoConsCustom*/ 
    "6942ab37-51db-4abf-9047-8e7418fca751",/*NoConsPersAds*/ 
    "26c16062-eb83-445d-a697-3625e8791ed4",/*NoConsPersPurpose*/ 
  ],
};

export function getConsentTypeParent(consent_type) {
  let parentId = null;
  if(consent_type){
    for (const [key, values] of Object.entries(OLD_REDBUD_CONSENT_TYPE_MAPPING)) {
      if (values.includes(consent_type)) {
        parentId =  key;
      }
    }
  }
  return parentId;
}

export function getScanAndConsentType(
  scan_type_binary,
  consent_type_parent,
  consentCategories
) {
  let scan_type = null;
  let consent_type = null;
  consentCategories.forEach((parentConsent) => {
    if (parentConsent.id === consent_type_parent) {
      if (scan_type_binary === "custom") {
        scan_type = "custom";
        let ct = parentConsent.consent_types.find((consent) =>
          consent.name.includes("Custom")
        );
        if (ct) {
          consent_type = ct.id;
        }
      } else if (scan_type_binary === "all") {
        let ct = parentConsent.consent_types.find(
          (consent) => !consent.name.includes("Custom")
        );
        if (ct) {
          consent_type = ct.id;
        }
        if (parentConsent.name.includes("Accept")) {
          scan_type = "accept_all";
        } else {
          scan_type = "reject_all";
        }
      }
    }
  });

  return {
    scan_type,
    consent_type,
  };
}

export const HOURS_UNIT_MAP = {
  Hours: 1,
  Days: 1 * 24,
  Weeks: 1 * 24 * 7,
  Months: 1 * 24 * 7 * 30,
};

export function getScanFrequency(hours) {
  if (hours % HOURS_UNIT_MAP["Months"] == 0) {
    const months = hours / HOURS_UNIT_MAP["Months"];
    return { value: months, unit: "Months" };
  } else if (hours % HOURS_UNIT_MAP["Weeks"] == 0) {
    const weeks = hours / HOURS_UNIT_MAP["Weeks"];
    return { value: weeks, unit: "Weeks" };
  } else if (hours % HOURS_UNIT_MAP["Days"] == 0) {
    const days = hours / HOURS_UNIT_MAP["Days"];
    return { value: days, unit: "Days" };
  } else {
    return { value: hours, unit: "Hours" };
  }
}

export const CUSTOM_ACTIONS = [
  "wildcard",
  "delay",
  "click",
  "refresh",
  "insert",
  "scrolling",
  "cmp_search",
];

export const CUSTOM_SCENARIOS_MAP = {
  "Accept all + wildcard": [
    { type: "wildcard" },
    { type: "delay" },
    { type: "cmp_search", defaultValues: { action: "accept_all" } },
    { type: "delay" },
    { type: "refresh" },
    { type: "delay" },
  ],
  "Reject all + wildcard": [
    { type: "wildcard" },
    { type: "delay" },
    { type: "cmp_search", defaultValues: { action: "reject_all" } },
    { type: "delay" },
    { type: "refresh" },
    { type: "delay" },
  ],
  "Opt out all + wildcard": [
    { type: "wildcard" },
    { type: "delay" },
    { type: "cmp_search", defaultValues: { action: "opt_out" } },
    { type: "delay" },
    { type: "refresh" },
    { type: "delay" },
  ],
  "Age gating": [
    { type: "delay" },
    {
      type: "click",
      defaultValues: { xpath: "/path_to_year", name: "Generic" },
    },
    {
      type: "click",
      defaultValues: { xpath: "/path_to_month", name: "Generic" },
    },
    {
      type: "click",
      defaultValues: { xpath: "/path_to_date", name: "Generic" },
    },
    {
      type: "click",
      defaultValues: { xpath: "/path_to_submit_form", name: "Generic" },
    },
    { type: "delay" },
    { type: "refresh" },
    { type: "delay" },
  ],
  Login: [
    { type: "delay" },
    {
      type: "insert",
      defaultValues: {
        xpath: '//*[@id="freemailLoginUsername"]',
        text: "email",
      },
    },
    {
      type: "insert",
      defaultValues: {
        xpath: '//*[@id="freemailLoginPassword"]',
        text: "password",
      },
    },
    {
      type: "click",
      defaultValues: {
        xpath: '//*[@id="freemailLoginForm"]/button',
        name: "Generic",
      },
    },
    { type: "delay" },
    { type: "refresh" },
    { type: "delay" },
  ],
};

export function getScenarioOptions(consentType) {
  let customScenarioOptions = Object.keys(CUSTOM_SCENARIOS_MAP);

  if (consentType == "Accept") {
    customScenarioOptions = customScenarioOptions.filter((opt) =>
      opt.includes("Accept")
    );
  } else if (consentType == "Reject") {
    customScenarioOptions = customScenarioOptions.filter((opt) =>
      opt.includes("Reject")
    );
  } else if (consentType == "Opt out") {
    customScenarioOptions = customScenarioOptions.filter((opt) =>
      opt.includes("Opt out")
    );
  } else {
    customScenarioOptions = customScenarioOptions.filter(
      (opt) =>
        !(
          opt.includes("Opt out") ||
          opt.includes("Accept") ||
          opt.includes("Reject")
        )
    );
  }
  return customScenarioOptions;
}

export function generateAction(type, defaultValues) {
  let actionObj = null;
  switch (type) {
    case "wildcard":
      actionObj = {
        type: "wildcard",
      };
      break;
    case "delay":
      actionObj = {
        type: "delay",
        duration: 30,
      };
      break;
    case "click":
      actionObj = {
        type: "click",
        xpath: "",
        name: "",
        click_all: true,
      };
      break;
    case "refresh":
      actionObj = {
        type: "refresh",
      };
      break;
    case "insert":
      actionObj = {
        type: "insert",
        xpath: "",
        text: "",
      };
      break;
    case "scrolling":
      actionObj = {
        type: "scrolling",
      };
      break;
    case "cmp_search":
      actionObj = {
        type: "cmp_search",
        action: "",
      };
      break;
    default:
      break;
  }

  if (defaultValues) {
    actionObj = {
      ...actionObj,
      ...defaultValues,
    };
  }
  return actionObj;
}