import React, { useState } from 'react';
import { Button, Tooltip } from '../../styleguide';
import { WarningOutlined } from '@ant-design/icons';

import CampaignTypeDropdown from './CampaignTypeDropdown';
import { CAMPAIGNS_MAP as CAMPAIGNS } from '../../constants';

function UpdateCampaignType({ currentUser, propertyType, campaignTypeCode, recordId, editId, setEditId, updateCampaignType, disableSetTypeBtn }) {
  const [campaignTypeId, setCampaignType] = useState(0);

  const handleCampaignType = (typeId) => {
    setCampaignType(typeId);
  };

  const isNoType = campaignTypeCode === 0;
  const isEdit = editId === recordId;
  const buttonText = isEdit ? 'Update' : 'Set Type';
  const readOnly = isEdit ? isEdit && !campaignTypeId : !!disableSetTypeBtn;
  const setButton = isNoType && (
    <Button
      disabled={readOnly}
      className="set-type-btn"
      type='secondary'
      font='opensans'
      onClick={() => (isEdit ? updateCampaignType(editId, campaignTypeId) : setEditId(recordId))}>
      {buttonText}
    </Button>
  );
  const tooltipContent = `Click on "Set Type" to update the campaign type`;
  const campaignType = isNoType ? (
    <Tooltip title={tooltipContent}>
      <span className="no-type-warning">
        <WarningOutlined /> {CAMPAIGNS[campaignTypeCode]}
      </span>
    </Tooltip>
  ) : (
    CAMPAIGNS[campaignTypeCode]
  );

  return editId === recordId ? (
    <div id='set-campaign-type'>
      <CampaignTypeDropdown currentUser={currentUser} propertyType={propertyType} updateCampaignType={handleCampaignType} />
      {setButton}
      <Button className="edit-cancel-btn" size='samll' type="default" font="opensans" onClick={() => setEditId()}>
        Cancel
      </Button>
    </div>
  ) : (
    <div id='set-type'>
      {campaignType}
      {setButton}
    </div>
  );
}

export default UpdateCampaignType;
