import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Spin } from 'antd';
import { Tabs, Input, message, Button, Toggle, Tooltip } from '../../../styleguide'
import { User } from '../../../records/account_records';
import AddPropertyGroup from './AddPropertyGroup';
import AddPropertiesToPG from './AddPropertiesToPG';
import DeletePropertyModal from './DeletePropertyModal';
import { browserHistory } from 'react-router';
import { Site, SiteGroup } from '../../../records/site_records';
import { PlusOutlined, EditFilled, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import CustomIcon from '../../common/CustomIcon.js';

const { Search } = Input;
const { TabPane } = Tabs;

export default class PropertyGroups extends React.Component {
  static propTypes = {
    updateSites: PropTypes.func.isRequired,
    sitesWithSiteGroups: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    siteGroups: ImmutablePropTypes.listOf(PropTypes.instanceOf(SiteGroup)).isRequired,
    loading: PropTypes.bool.isRequired,
    getPropertyName: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    createSiteGroup: PropTypes.func.isRequired,
    updatePropertyGroup: PropTypes.func.isRequired,
    deleteSiteGroup: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    currentUser: PropTypes.instanceOf(User).isRequired,
    isMultiCampaignAvailable: PropTypes.bool.isRequired,
    propertyTypesNum: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  };
  state = {
    activeKey: '0',
    searchPropertyGroup: '',
    searchProperty: '',
    showAddPropertyGroup: '',
    editPropertyGroup: new SiteGroup({}),
    editing: false,
    showAddProperties: false,
    siteToDelete: null,
    siteGroup: new SiteGroup({}),
  };

  onTabChange = (activeKey) => {
    this.setState((prevState) => ({
      activeKey: activeKey == '0' ? prevState.activeKey : activeKey,
      searchProperty: '',
    }));
  };

  getFilteredPropertyGroup = () =>
    this.props.siteGroups.filter(
      (pg) =>
        pg
          .get('name')
          .toLowerCase()
          .indexOf(this.state.searchPropertyGroup.toLowerCase()) !== -1
    );

  onPropertyGroupSearch = (e) => this.setState({ searchPropertyGroup: e.target.value });

  toggleAddPropertyGroup = () => {
    this.setState((prevState) => ({
      showAddPropertyGroup: !prevState.showAddPropertyGroup,
      editing: false,
    }));
  };

  editPropertyGroup = (propertyGroup) => {
    this.setState({
      showAddPropertyGroup: true,
      editPropertyGroup: propertyGroup,
      editing: true,
    });
  };

  toggleAddProperties = (sg) => {
    this.setState((prevState) => ({
      showAddProperties: !prevState.showAddProperties,
      siteGroup: sg,
    }));
  };

  getPropertyGroup = (propertyId) => this.props.siteGroups.find((pg) => pg.siteIds.includes(propertyId));

  propertiesWithoutPG = () => {
    return this.props.sites.filterNot((p, pi) => this.getPropertyGroup(p.id));
  };

  onSearchProperties = (e) => {
    this.setState({ searchProperty: e.target.value });
  };

  getFilteredProperties = (propertyIds) => {
    return this.props.sites
      .filter((p) => propertyIds.includes(p.id))
      .filter(p => !this.props.currentUser.siteAccess || this.props.currentUser.siteAccess.includes(p.id))
      .filter(
        (p) =>
          p
            .get('domain')
            .toLowerCase()
            .indexOf(this.state.searchProperty.toLowerCase()) !== -1
      );
  };

  userAllowedToEdit = (siteGroup) => {
    const siteAccess = !this.props.currentUser.siteAccess || siteGroup.siteIds.every(id => this.props.currentUser.siteAccess.includes(id));
    const featureAccess = !this.props.currentUser.featureAccess || this.props.currentUser.featureAccess.includes('Campaign Entities');
    return siteAccess && featureAccess;
  }

  setSiteGroupToDelete = (e, siteToDelete) => {
    if (e) e.stopPropagation();
    this.setState({ siteToDelete });
  };

  deletePropertyGroup = () => {
    message.error(`Property group ${this.state.siteToDelete.name} with ID ${this.state.siteToDelete.siteId} is deleted`);
    this.props.deleteSiteGroup(this.props.accountId, this.state.siteToDelete.id, this.state.siteToDelete.siteId, this.state.siteToDelete.name);
    this.setSiteGroupToDelete(null, null);
  };

  handleManageSiteGrop = (e, sg) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.selectSite(sg.siteId, sg.id);
  };

  switchMultiCampaign = (e, sg, sites) => {
    e.preventDefault();
    e.stopPropagation();
    const site = sites.find(s => s.id === sg.siteId);
    const isEnabled = site ? site.multiCampaignEnabled : false;
    const updatedSiteGroup = sg.set('multiCampaignEnabled', !isEnabled);
    this.props.updateMultiCampaignPropertyGroup(this.props.accountId, updatedSiteGroup.toJS()).then(() => {
      this.props.updateSites();
    });
  };

  render() {
    var containerClass = 'property-groups-content-container';
    let addPropertyGroupButton;
    if (!this.props.currentUser.featureAccess || (this.props.currentUser.featureAccess && this.props.currentUser.featureAccess.includes('Property Managment'))) {
      addPropertyGroupButton = (
        <Button type="primary" size='small' icon={<PlusOutlined />} onClick={this.toggleAddPropertyGroup}>
          Add New Property Group
        </Button>
      );
    }
    const customTab = (
      <div className="add-property-group">
        { addPropertyGroupButton }
        <div>Total {this.props.siteGroups.size} Property Groups</div>
        <Input placeholder="Search for Property Groups" onChange={this.onPropertyGroupSearch} suffix={<SearchOutlined />} />
      </div>
    );
    const warningMessage = this.state.siteToDelete != null && <DeletePropertyModal handleDelete={this.deletePropertyGroup} siteToDelete={this.state.siteToDelete} handleCancel={() => this.setSiteGroupToDelete(null, null)} />;
    let addPropertyGroupLink;
    if (this.props.isAdmin) {
      addPropertyGroupLink = (
        <div>
          To add new property group click on{' '}
          <Button type="link" size='small' onClick={this.toggleAddPropertyGroup}>
            Add New Property Group
          </Button>
        </div>
      );
    }

    const siteGroups = this.getFilteredPropertyGroup();
    const selectedSiteGroupIndex = this.state.activeKey - 1;
    const siteGroup = siteGroups.get(selectedSiteGroupIndex);
    let editButton;
    let addPropertiesButton;
    const userAllowedToEdit = siteGroup && this.userAllowedToEdit(siteGroup);
    if (userAllowedToEdit && siteGroup) {
      editButton = (
        <Button onClick={() => this.editPropertyGroup(siteGroup)} icon={<EditFilled />} type="link" size='small' className='edit-property-group'>
          Edit
        </Button>
      );
    }
    if (userAllowedToEdit && this.props.isAdmin && siteGroup) {
      addPropertiesButton = (
        <Button onClick={() => this.toggleAddProperties(siteGroup)} type='secondary' size='small' font='opensans'>Add Properties</Button>
      );
    }

    const multiCampaingTootlipText = (
      <div>
        Switch the toggle <span>on</span> if you would like to have the ability to <span>run multiple campaign</span> types on your <span>property</span>.
        Please note that you will need to also <span>update the script</span> on the property to take advantage of the multi-campaign feature.
      </div>
    );
    const sitesWithSiteGroups = this.props.sitesWithSiteGroups;

    const createPropertyGroup = this.state.showAddPropertyGroup && (
      <AddPropertyGroup 
        sites={this.props.sites} 
        showAddPropertyGroup={this.state.showAddPropertyGroup} 
        toggleAddPropertyGroup={this.toggleAddPropertyGroup} 
        createPropertyGroup={this.props.createSiteGroup} 
        updatePropertyGroup={this.props.updatePropertyGroup} 
        accountId={this.props.accountId} 
        editPropertyGroup={this.state.editPropertyGroup} 
        editing={this.state.editing} 
        getPropertyName={this.props.getPropertyName} 
        siteGroups={this.props.siteGroups}
        updateMultiCampaignPropertyGroup={this.props.updateMultiCampaignPropertyGroup}
        isMultiCampaignAvailableDefault={true} 
        updateSites={this.props.updateSites}
        propertyTypesNum={this.props.propertyTypesNum}
      />
	);

    return (
      <div className={containerClass}>
        {this.props.loading ? (
          <div className="properties-loading">
            <Spin size="large" />
          </div>
        ) : null}
        {warningMessage}
        <Tabs tabPosition="left" defaultActiveKey="0" onChange={this.onTabChange} activeKey={this.state.activeKey}>

          <TabPane tab={customTab} key="0">
            Currently no property group is selected. Select property group to view the list of properties.
            <br />
            { addPropertyGroupLink }
          </TabPane>

          {this.getFilteredPropertyGroup().map((sg, sgi) => {
           let multiCampaignEnabled = false;
           const site = sitesWithSiteGroups.find(s => s.id === sg.siteId);
           if(site) {
              multiCampaignEnabled = site.multiCampaignEnabled;
           }
           return (
            <TabPane
              tab={
                <div className="property-custom-tab">
                  <div className="property-group-name">
                    <a onClick={(e) => this.handleManageSiteGrop(e, sg)}>
                      {sg.name}
                      <CustomIcon type={CustomIcon.types.PREVIEW}/>
                    </a>
                    <br />
                    <span>{sg.siteIds.size} properties</span>
                  </div>
                  {this.props.isAdmin ? <button className="avo-dialogue-icons avo-sp-delete" onClick={(e) => this.setSiteGroupToDelete(e, sg)}></button> : null}
                </div>
              }
              key={(sgi + 1).toString()}
            >
              {this.state.showAddProperties ? <AddPropertiesToPG showAddProperties={this.state.showAddProperties} toggleAddProperties={this.toggleAddProperties} sites={this.propertiesWithoutPG()} updatePropertyGroup={this.props.updatePropertyGroup} accountId={this.props.accountId} siteGroup={this.state.siteGroup} propertyTypesNum={this.props.propertyTypesNum}/> : null}
              <div className="pg-name">
                <span className="pg-directto-campagins" onClick={(e) => this.handleManageSiteGrop(e, sg)}>
                  {sg.name}
                </span>
                {
                  this.props.isMultiCampaignAvailable && !this.props.isMultiCampaignAvailableDefault ?
                    (
                      <div className='flex-row multi-campaign-swithch-group'>
                        <Toggle
                          checked={ multiCampaignEnabled }
                          onChange={ (c, e) => this.switchMultiCampaign(e, sg, sitesWithSiteGroups) }
                        />
                        <div>
                          Multi-Campaign Enabled
                          <Tooltip placement="bottom" title={multiCampaingTootlipText} overlayClassName='multi-campaign-tooltip'>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                      </div>
                    ) :
                    null
                }
                { editButton }
              </div>
              <div className="pg-desc">{sg.description}</div>
              <div className="pg-header-sub">
                List of properties in Property Group
                { addPropertiesButton }
              </div>
              {sg.siteIds.size ? (
                <React.Fragment>
                  <Input className="search-properties" placeholder="Search Properties" value={this.state.searchProperty} onChange={this.onSearchProperties} suffix={<SearchOutlined />} />
                  <div className="properties-list">
                    {this.getFilteredProperties(sg.siteIds).map((s, si) => (
                      <div key={si}>{s.domain}</div>
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                  <div className="no-properties-wrapper">
                    Currently there are no properties in the property group.
                    <br />
                    <span>
                      To add properties click on <a onClick={() => this.toggleAddProperties(sg)}>Add Properties</a>
                    </span>
                  </div>
                )}
            </TabPane>
          )})}
        </Tabs>
        {createPropertyGroup}
      </div>
    );
  }
}
