import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Tooltip, Button, Toggle } from '../../../../styleguide';
import { Link } from 'react-router';
import CustomButton from '../../../common/CustomButton.js.jsx';
import CustomGeoScopeDropDown from '../../../common/CustomGeoScopeDropDown';
import { CONSENT_SCOPES } from '../../../../constants';
import { VendorList } from '../../../../records/vendor_list_records';
import { GEO_LIST_MERGED } from '../../../../constants';
import { CopyFilled, DeleteFilled } from '@ant-design/icons';

const regionsAndCountries = GEO_LIST_MERGED.filter(
  c => c.type == 'country' || c.type == 'region'
);

const { Option } = Select;

export default class Header extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleSelectScope: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    toggleIsActive: PropTypes.func.isRequired,
    toggleShareRootDomain: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    vl: PropTypes.instanceOf(VendorList).isRequired,
    v2: PropTypes.bool.isRequired
  };

  getRegionNames = regions => {
    //filtering for all regions from list
    return regions.filter(r => r.type === 'region');
  };

  render() {
    const tooltipText = (
      <React.Fragment>
        <p className="tooltip-title">Single site</p>
        <p>Consent will only be set for the site a user has consented on.</p>
        <p className="tooltip-title">Shared site</p>
        <p>Consent will be shared across all sites within your account.</p>
        <p className="tooltip-title">Global IAB</p>
        <p>
          Utilizes consent through the IAB framework, setting all consent
          globally through their network of sites
        </p>
      </React.Fragment>
    );

    const shareRootDomain = (
      <div className="share-root-domain-switch">
        <span>Write 1st Party Cookies To Root Domain</span>
        <Toggle
          onChange={this.props.toggleShareRootDomain}
          checked={this.props.vl.shareRootDomain}
          disabled={this.props.readOnly}
        />
      </div>
    );

    let saveButton;
    if (!this.props.readOnly) {
      saveButton = (
        <CustomButton
          type={CustomButton.types.SUCCESS}
          onClick={this.props.handleSave}
          disabled={this.props.readOnly}
        >
          Save
        </CustomButton>
      );
    }
    const geoScope = (
      <div className="geoContainer">
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <div className="geoTitle" style={{ marginRight: 5 }}>U.S. Privacy Applies Scope:</div>
          <Tooltip title='This setting determines the geographic regions where our system will provide a US Privacy String that includes the determination of opt status [Y/N]. Outside this area we will provide "1___" for the US Privacy String. Note this setting will not impact message targeting which is managed in campaign scenarios area.'>
            <div className="title">
              <i className="fas fa-info-circle" />
            </div>
          </Tooltip>
        </div>
        <CustomGeoScopeDropDown
          // options={this.getRegionNames(regionsAndCountries)}
          // type="ccpa"
          handleLocationSave={this.props.handleLocationSave}
          vl={this.props.vl}
        />
      </div>
    );

    const rightTitle = (
      <React.Fragment>
        <div className="switchContainer">
          {geoScope}
          <div style={{ display: 'none' }}>
            <Tooltip title={tooltipText}>
              <div className="title">
                <i className="fas fa-info-circle" />
              Consent scope:
            </div>
            </Tooltip>
            <Select
              disabled={this.props.readOnly}
              value={CONSENT_SCOPES.get(this.props.vl.consentScope)}
              onChange={this.props.handleSelectScope}
              size="large"
            >
              {CONSENT_SCOPES.toList().map(s => (
                <Option key={s}>{s}</Option>
              ))}
            </Select>
          </div>
        </div>
      </React.Fragment>
    );

    const activeSwitch = (
      <div className="active-switch">
        <div>{this.props.vl.isActive ? 'Active' : 'Inactive'}</div>
        <Toggle
          onChange={this.props.toggleIsActive}
          checked={this.props.vl.isActive}
          disabled={this.props.readOnly}
        />
      </div>
    );

    const v2 = this.props.v2 ? '_v2' : '';

    return (
      <div className="vendor-header">
        <div className="vendor-header-nav">
          <Link to={`/us_privacy/vendor_lists`}>
            &lt;&nbsp;&nbsp;Back to Vendor Lists
          </Link>
          <div className="actions-wrapper">
          <div className='menu-container'>
            {!this.props.readOnly && this.props.route.path.includes('/edit') ? (
                <>
                  <div className={"edit"} onClick={this.props.handleCopy}>
                    <Tooltip title={<>Clone</>} trigger="hover">
                      <CopyFilled rotate={180} />
                    </Tooltip>
                  </div>
                  <div className={"edit"} onClick={this.props.onDelete}>
                    <Tooltip title={<>Delete</>} trigger="hover">
                      <DeleteFilled />
                    </Tooltip>
                  </div>
                </>
              ) : null}
          </div>
            <Button
            type="secondary"
            size="small"
            onClick={ this.props.toggleAdvancedSettings }
            className='advanced-settings'
            >
            * Advanced settings *
            </Button>
            {activeSwitch}
            {saveButton}
          </div>
        </div>

        <div className="vendor-top">
          <div className="vendor-top-left">
            <div className="title-editor">
              <Input
                prefix={<i className="fas fa-pen" />}
                value={this.props.vl.name}
                onChange={e => this.props.handleChange(e, 'name')}
                placeholder="Vendor List Name..."
                className="vendor-top-title"
                disabled={this.props.readOnly}
              />
            </div>
          </div>
          <div className="vendor-top-right">{rightTitle}</div>
        </div>
        {this.props.vl && <div className='vl-id'>ID: {this.props.vl.id}</div>}
      </div>
    );
  }
}
