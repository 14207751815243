import React, { Component } from 'react';
import _ from 'lodash';
import { Table, Chip, Tooltip, Infotip } from '../../../styleguide';
import { EyeFilled, EditFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { getTagData, trimTag, messageConditions } from './helper';
import { styles } from './TemplateBuilder/styles';
import { kvStringClass, urlMatchClass } from './TemplateBuilder/Data';
import MessagePreviewModal from './MessagePreviewModal';
import { getMessageWithDetailAjax } from '../../../api/dialogue/messages';
import { getParameterByName } from '../../utils.js';
import {
  consentGateClass,
  consentGateGdprV2Class,
  ConsentGateCcpaClass,
  ConsentGateUsnatClass,
} from './TemplateBuilder/Data';

class ScenarioList extends Component {
  state = {
    showMessagePreview: false,
    viewMsg: '',
  };

  togglePreviewModal = () => this.setState((prevState) => ({ showMessagePreview: !prevState.showMessagePreview }));

  getMsgDetails = (id) => this.props.messages.find((m, i) => m.id == id) || {};

  viewMessage = (id) => {
    const viewMsg = this.getMsgDetails(id);

    this.setState({ showMessagePreview: true, viewMsg });
    if (viewMsg.message_category_id == 0) {
      const siteId = parseInt(getParameterByName('site_id', window.location));
      const isSiteGroup = !!getParameterByName('site_group_id', window.location);
      getMessageWithDetailAjax(
        siteId,
        viewMsg.id,
        isSiteGroup
      ).then((resp) => this.setState({ showMessagePreview: true, viewMsg: resp }));
    } else {
      this.setState({ showMessagePreview: true, viewMsg });
    }
  };

  updatedDescription = (description) => (<>
    {description}
    <Infotip
      className='action-msg'
      content='Action required'
      trigger="hover"
      placement="bottomRight"
      >
      <ExclamationCircleFilled/>
    </Infotip>
  </>)

  indexArray = (value, sceanrioId, stepIdx) => {
    if(value.length === 0) {
      return [{[sceanrioId]: [stepIdx]}]
    }else {
      return value.map((item) => {
        if(Object.keys(item) == sceanrioId) {
        const indexArr = item[sceanrioId];
          if(!(item[sceanrioId].includes(stepIdx))){
            indexArr.push(stepIdx);
            return {
              ...item,
              [sceanrioId] : indexArr
            }
          }
        }else {
          return {
            ...item,
            [sceanrioId] : [stepIdx]
          }
        }
      });

    }
  }

  findIndexIsPresent = (value, id, index) => {
    return value.find((item) => {
      const keys = _.keys(item);
      if(keys.includes(String(id))) {
        return item[id].includes(index);
      }
    })
  }

  
  render() {
    let consentStatusStepIndex = [];
    let consentToAllStatusStepIndex = []; 

    const modifyDataSource = this.props.dataSource.map((sceanrio, index) => {
      let isConsentPresent = false;    
      let isConsentToAllPresent = false; 

        sceanrio.steps.forEach((step, stepIdx) => {
          step.rowData.forEach((row, idx) => {
            if(row?.data?.status === 3) {
              isConsentToAllPresent = true;
              consentToAllStatusStepIndex = this.indexArray(consentToAllStatusStepIndex,sceanrio.id, stepIdx);
            }
            else if(sceanrio.campaign_type_id === 1 && (idx === 0 && row.category !== 'condition')) {
              isConsentPresent = true;  
              consentStatusStepIndex = this.indexArray(consentStatusStepIndex, sceanrio.id, stepIdx);
            }
            else if(sceanrio.campaign_type_id === 0) {
              const message = this.props.messages.find(m => (m.id === row.data.message_id && m.message_category_id === 1))
              if(message) {
                if((idx === 0 && row.category !== 'condition')) {
                  isConsentPresent = true;
                } else if(row.category === 'condition'){
                  isConsentPresent = ((row.className !== consentGateGdprV2Class || row.className !== consentGateClass) || row.className !== ConsentGateCcpaClass || row.className !== ConsentGateUsnatClass);
                }

                if (isConsentPresent) {
                  consentStatusStepIndex = this.indexArray(consentStatusStepIndex, sceanrio.id, stepIdx);
                } 
              }
            }
          })
        })
        return {
          ...sceanrio,
          description : (isConsentPresent || isConsentToAllPresent) ? this.updatedDescription(sceanrio.description) : sceanrio.description,
        }
    })

    const deliveryMesssages = messageConditions.toJS();
    
    const messagePreview = this.state.showMessagePreview && 
      <MessagePreviewModal showMessagePreview={this.state.showMessagePreview} viewMsg={this.state.viewMsg} togglePreviewModal={this.togglePreviewModal}/>;    

    return (
      <React.Fragment>
      <Table
        className="newScenarioTable"
        columns={this.props.columns}
        pagination={false}
        onChange={(pagination, filters, sorter) =>
          this.props.handleSortChange(pagination, filters, sorter)
        }
        rowKey={(record) => record.id}
        // rowClassName={(record, index) => record.campaign_type_id === 0 ? 'no-type-row' : ''}
        expandedRowRender={(record) => {
          return record.steps.length == 0 ? (
            <span>No steps added</span>
          ) : (
            record.steps.map((row, rowKey) => {
              const filterConditions = row.rowData.filter(
                (r) => r.category == 'condition'
              );
              const kvConditions = row.rowData.filter(
                (r) => r.category == 'condition' && r.className == kvStringClass
              );
              const urlConditions = row.rowData.filter(
                (r) => r.category == 'condition' && r.className == urlMatchClass
              );
              const timeDelay = row.rowData.filter(
                (r) => r.category == "delay" 
              );
              const messages = row.rowData.filter(
                (r) => r.category == 'message'
              ).map(r => {
                const message = this.props.messages.find(m => m.id === r.data.message_id);
                const deliveryMsg = deliveryMesssages.find(dm => dm.className === r.className).label;
                return Object.assign({}, r, message ? message.toJS() : {}, { deliveryMsg });
              });
              const stepLabelShow = messages.length > 0 || filterConditions.length > 0;
              const consentStatusFlag = this.findIndexIsPresent(consentStatusStepIndex, record.id, rowKey);
              const consentToAllStatusFlag = this.findIndexIsPresent(consentToAllStatusStepIndex, record.id, rowKey);
              return (
                <div className="stepContainer" key={rowKey}>
                  <p className="step-title">
                    {stepLabelShow && 'Step ' + (rowKey + 1)}
                  </p>
                  <div>
                    {urlConditions && urlConditions.length > 0 && (
                      <Tooltip
                        title={getTagData('urlMultipleRaw', urlConditions)}
                      >
                        <Chip>
                          {trimTag(getTagData('urlMultipleRaw', urlConditions))}
                        </Chip>
                      </Tooltip>
                    )}
                    {kvConditions && kvConditions.length > 0 && (
                      <Tooltip
                        title={getTagData('kvMultipleRaw', kvConditions)}
                      >
                        <Chip>
                          {trimTag(getTagData('kvMultipleRaw', kvConditions))}
                        </Chip>
                      </Tooltip>
                    )}
                    {filterConditions.map(
                      (tag, ind) =>
                        tag.className != kvStringClass &&
                        tag.className != urlMatchClass && (
                          <Tooltip
                            key={ind}
                            title={getTagData(tag.className, tag.data)}
                          >
                            <Chip style={(tag.data.status === 3 && {border:  '2px solid #FF2626', backgroundColor: '#FFD2D2'})}>
                              {trimTag(getTagData(tag.className, tag.data))}
                            </Chip>
                          </Tooltip>
                        )
                    )}
                    {timeDelay && timeDelay.length > 0 && (
                      <Tooltip
                      title={getTagData('timeDelay', timeDelay)}
                      >
                        <Chip>
                          {trimTag(getTagData('timeDelay', timeDelay))}
                        </Chip>
                      </Tooltip>                     
                    )}
                    {messages && messages.length > 0 && messages.map((m, mi) => {
                      return(
                      <Tooltip key={mi} title={`${m.deliveryMsg}: ${m.description ? m.description : 'No Message'}`}>
                        <Chip title={`${m.deliveryMsg}: ${m.description ? m.description : 'No Message'}`} onClick={() => m.description ? this.viewMessage(m.id) : null}>
                          {
                            m.description || m.data.limit ? 
                            <React.Fragment>{`${m.deliveryMsg}: ${m.description || m.data.limit}`} {m.description && <EyeFilled style={styles.previewIcon}/>}</React.Fragment>
                            : `${m.deliveryMsg}: No Message`
                          }
                        </Chip>
                      </Tooltip>
                    )})}
                    {consentToAllStatusFlag && <p className='error-message'>The <strong>Consent to All</strong> option has been deprecated and will no longer be supported for the GDPR TCF consent gate. To ensure that this step is not skipped, go to <strong>Edit</strong> <EditFilled /> to choose a different consent gate option.</p>}
                    {consentStatusFlag && <div className='error-message'>Consent Status is missing in this scenario. To ensure that this step is not skipped, go to <strong>Edit</strong> <EditFilled /> to add a consent status step under conditions.</div>}
                  </div>
                </div>
              );
            })
          );
        }}
        dataSource={modifyDataSource}
      />
      { messagePreview }
      </React.Fragment>
    );
  }
}

export default ScenarioList;
