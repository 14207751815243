import PropTypes from 'prop-types';
import React from 'react';
import ColorPickerInput from '../../../common/ColorPickerInput.js.jsx';

export default class extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onUpdate: PropTypes.func.isRequired,
        editing: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        var borderParts = props.value.split(' ');

        this.state = {
            size: borderParts[0].replace('px', ''),
            style: borderParts[1],
            color: borderParts[2]
        };
    }

    componentWillReceiveProps(nextProps) {
        var borderParts = nextProps.value.split(' ');
        this.setState({
            size: borderParts[0].replace('px', ''),
            style: borderParts[1],
            color: borderParts[2]
        });
    }

    handleSizeChange = (event) => {
        this.setState({ size: event.target.value }, this.computeConfigValue);
    };

    handleStyleChange = (style) => {
        this.setState({ style: style }, this.computeConfigValue);
    };

    handleColorChange = (event) => {
        this.setState({ color: event }, this.computeConfigValue);
    };

    computeConfigValue = () => {
        var configValue = this.state.size + 'px ' + this.state.style + ' ' + this.state.color;
        this.props.onUpdate(configValue);
    };

    render() {
        return (
            <div>
              <h3>Message Border</h3>
              <div className='style-color-font-pickers'>
                <div className="options-styles-property">
                  <ColorPickerInput
                    value={ this.state.color }
                    onUpdate={ this.handleColorChange }
                    editing={ this.props.editing }
                    />
                </div>
                <div className="options-styles-property">
                  <div className="options-copy-font-size-and-opacity">
                    <input value={ this.state.size } onChange={ this.handleSizeChange } disabled={ !this.props.editing }/>
                  </div>
                </div>
              </div>

            </div>
        );
    }
}
