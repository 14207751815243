import * as types from './action_types';
import { actionCreator } from './helpers';
import { getAndParseConsentReportActionStatusData, getAndParseConsentReportVendorsData } from '../api/consent/consent_performance_report';

export const getConsentReportActionStatusData = actionCreator(
    getAndParseConsentReportActionStatusData,
    consentReportActionStatusPending,
    consentReportActionStatusLoaded,
    consentReportActionStatusError,
)

export const getConsentReportVendorsData = actionCreator(
    getAndParseConsentReportVendorsData,
    consentReportVendorsPending,
    consentReportVendorsLoaded,
    consentReportVendorsError,
)

function  consentReportActionStatusPending() {
  return {
    type: types.CONSENT_REPORT_ACTION_STATUS_PENDING,
  };
}

function consentReportActionStatusLoaded(data) {
  return {
    type: types.CONSENT_REPORT_ACTION_STATUS_LOADED,
    data,
  };
}

function consentReportActionStatusError(error) {
  return {
    type: types.CONSENT_REPORT_ACTION_STATUS_ERROR,
    error,
  };
}

function  consentReportVendorsPending() {
  return {
    type: types.CONSENT_REPORT_VENDORS_PENDING,
  };
}

function consentReportVendorsLoaded(data) {
  return {
    type: types.CONSENT_REPORT_VENDORS_LOADED,
    data,
  };
}

function consentReportVendorsError(error) {
  return {
    type: types.CONSENT_REPORT_VENDORS_ERROR,
    error,
  };
}
