import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button, Modal, message } from '../../../styleguide';

import CustomButton from '../../common/CustomButton.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import PartitionRow from './PartitionRow.js.jsx';
import WarningMessage from '../../common/WarningMessage.js.jsx';
import { Partitionset as PartitionsetRecord, Partition } from '../../../records/partitionset_records.js';
import { Scenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2';
import PopoverButton from '../../common/PopoverButton.jsx';
import { getParameterByName, decimalRound } from '../../utils';
import { dialogueTypes, CAMPAIGNS_MAP } from '../../../constants.js';
import UpdateCampaignType from '../../common/UpdateCampaignType';
import CustomDeleteModal from '../../common/CustomDeleteModal';

class Partitionset extends React.Component {
  static propTypes = {
    partitionset: PropTypes.instanceOf(PartitionsetRecord).isRequired,
    scenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
    deletePartitionset: PropTypes.func.isRequired,
    duplicatePartition: PropTypes.func.isRequired,
    updatePartitionset: PropTypes.func.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  state = {
    editing: false,
    psetEdited: this.props.partitionset,
    errorMessage: null,
    warningMessagePresent: false,
    copyMessages: false,
    copyScenarios: false,
    partitionPercentages: [],
  };

  getBucketPartitionPercentage = () => {
    let partitionPercentages = [];
    this.state.psetEdited.partitions.forEach((partition) => {
      const bucketStart = partition.getIn(['ruleset', 'bucket_rules', 0, 'bucket_start']);
      const bucketEnd = partition.getIn(['ruleset', 'bucket_rules', 0, 'bucket_end']);

      var bucketPercentage;
      if (bucketEnd !== "" && bucketStart !== "") {
        bucketPercentage = ((bucketEnd - bucketStart + 1) / 1000) * 100;
        if (bucketPercentage < 0) bucketPercentage = 0;
        bucketPercentage = decimalRound(bucketPercentage);
      }

      partitionPercentages.push(bucketPercentage);
    });

   this.setState({ partitionPercentages });
  }

  componentDidMount() {
    this.getBucketPartitionPercentage();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.pendingRequestsMap) {
      if (newProps.pendingRequestsMap.every(value => value === false)) {

        if (newProps.partitionset) {
          this.setState({ psetEdited: newProps.partitionset });
        }

        this.setState({
          editing: false,
          errorMessage: false,
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.editing && this.state.editing) {
      ReactDOM.findDOMNode(this.refs.partitonsetName).focus();
    }
  }

  toggleWarningMessage = () => {
    if (getParameterByName('site_group_id', window.location)) {
      this.setState({ warningMessagePresent: !this.state.warningMessagePresent })
    } else {
      let siteId = getParameterByName('site_id', window.location);
      this.props.getSiteGroups(this.props.currentUser.accountId, getParameterByName('site_id', window.location)).then((siteGroups) => {
        if (siteGroups.size) {
          const siteGroupNames = siteGroups.filter(sg => {
            if(sg?.siteIds?.length !== 0) {
              return sg.siteIds.find(id => id === parseInt(siteId))
            }
          }).map(sg => sg.name).join(', ').trim();

          siteGroupNames ? Modal.error({
            title: 'Error',
            content: `Partitionset ${this.props.partitionset.description} cannot be deleted because the site is part of site group ${siteGroupNames}.`,
            className: 'site-group-error',
          }) 
          : this.setState({ warningMessagePresent: !this.state.warningMessagePresent }); 
        } else {
          this.setState({ warningMessagePresent: !this.state.warningMessagePresent })
        }
      });
    }
  };

  handleSave = () => {
    const { renderActiveCampaignWarning } = this.props;
    const { psetEdited, psetEdited: { description } } = this.state;

    if (psetEdited.get('partitions').some((p) => {
      return !(p.get('name') &&
        typeof p.getIn(['ruleset', 'bucket_rules', 0, 'bucket_start']) === 'number' &&
        typeof p.getIn(['ruleset', 'bucket_rules', 0, 'bucket_end']) === 'number' &&
        p.get('scenario_id') && p.get('scenario_id') !== -1);
    })) {
      this.setState({ errorMessage: "Please fill in all the partition's details" });
      return;
    }

    const checkPercentage = this.state.partitionPercentages.reduce((pp, cpv) => pp + cpv, 0);
    if (checkPercentage > 100) {
      this.setState({ errorMessage: "Please make sure addition of partition % should be equal to 100" });
      return;
    }

    this.props.updatePartitionset(psetEdited).then(() => message.success(`Partition '${description}' saved successfully`));
    renderActiveCampaignWarning(psetEdited.description, dialogueTypes.PARTITION);
    if(this.props.isMultiCampaignEnabled) this.props.setEditId();
  };

  handleNameChange = (event) => {
    this.setState({ psetEdited: this.state.psetEdited.set('description', event.target.value) });
  };

  handleAdd = () => {
    const partition = new Partition({ scenario_id: this.props.scenarios.getIn([0, 'id']) });
    const withNewPartition = this.state.psetEdited.update('partitions', (ps => ps.push(partition)));

    let partitionPercentages = this.state.partitionPercentages;
    partitionPercentages.push('');

    this.setState({ editing: true, psetEdited: withNewPartition, partitionPercentages });
  };

  handleEdit = () => {
    if (this.state.editing) return;
    this.setState({ editing: true });
  };

  handleDelete = (e) => {
    e.stopPropagation();
    const { id, description, site_id } = this.props.partitionset
    this.props.deletePartitionset(id, description, site_id);
  };

  handleCancelEditing = () => {
    this.setState({
      psetEdited: this.props.partitionset,
      editing: false,
      errorMessage: null
    }, () => {
      if(this.props.isMultiCampaignEnabled) this.props.setEditId();
    });
  };

  deletePatitionRow = (index) => {
    const withoutPartition = this.state.psetEdited.update('partitions', (ps => ps.delete(index)));

    let partitionPercentages = this.state.partitionPercentages;
    partitionPercentages.splice(index, 1);

    this.setState({ psetEdited: withoutPartition, partitionPercentages });
  };

  updatePartitionRow = (index, partition) => {
    const psetEdited = this.state.psetEdited.setIn(['partitions', index], partition);
    this.setState({ psetEdited });
  };

  onCheckedMessages = (checked) => {
    this.setState({
      copyMessages: checked,
    });
  };

  onCheckedScenarios = (checked) => {
    this.setState({
      copyScenarios: checked,
    });
  };

  copyPartitionset = () => {
    const updatedPartitionSet = this.props.partitionset.set('description', this.props.partitionset.get('description') + ' (copy)');
    const duplicateName = this.props.getPartitionByName(updatedPartitionSet);
    if(duplicateName) {
      message.warning(`New Partition set name will be '${updatedPartitionSet.description}' which is already exist`);
      return;
    }

    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      this.props.createSiteGroupPartitionset(siteGroupId, this.props.currentUser.accountId, updatedPartitionSet);
    } else {
      const skipValidation = true;
      this.props.duplicatePartition(this.props.partitionset, { copyScenarios: this.state.copyScenarios, copyMessages: this.state.copyMessages }, skipValidation);
    }
  }

  updateCampaignType = (editId, campaignTypeId) => {
    const updatedPartitionSet = this.state.psetEdited.set('campaign_type_id', campaignTypeId);
    
    let campaignTypeMismatch = false;
    updatedPartitionSet.partitions.forEach(p => {
	  const scenario = this.props.scenarios.find(s => s.id === p.scenario_id);
      if(scenario && scenario.campaign_type_id !== campaignTypeId) {
        campaignTypeMismatch = true;
        return;
      }
	});

    if(campaignTypeMismatch) {
      this.props.toggleCampaignError(campaignTypeId);
      return;
    }
    
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if(siteGroupId) {
      this.props.updatePartitionset(updatedPartitionSet)
      .then((status) => {
        if(status === "success") {
          const notice = `Partition Set '${this.state.psetEdited.description}' updated with campaign type '${CAMPAIGNS_MAP[campaignTypeId]}' successfully`;
          message.success(notice);
          this.setState({ psetEdited: updatedPartitionSet });
          this.props.setEditId();
        } else {
          message.error(`Unable to update campaign type to partition set, please try again.`);
        }
      });
    } else {
      this.props.updatePartitionset(updatedPartitionSet)
      .then(() => {
        if(this.props.error) {
          message.error(`Unable to update campaign type to partition set, please try again.`);
        } else {
          this.setState({ psetEdited: updatedPartitionSet });
          this.props.setEditId();
          message.success(`Partition Set '${this.state.psetEdited.description}' updated with campaign type '${CAMPAIGNS_MAP[campaignTypeId]}' successfully`);
        }
      })
      .catch(() => message.error(`Unable to update campaign type to partition set, please try again.`));
    }
  }

  updatePartitionPercentage = (index, value) => {
    let partitionSet = this.state.psetEdited;
    var v = parseInt(value) || '';
    if (v < 0) {
      v = 0;
    } else if (v > 100) {
      v = 100;
    }
    let partitionPercentages = this.state.partitionPercentages;
    partitionPercentages.splice(index, 1, v);

    partitionPercentages.forEach((per, i) => {
      let bucketStart = 0;
      if(i > 0) {
        const prevBucketEnd = partitionSet.getIn(['partitions', i - 1, 'ruleset', 'bucket_rules', 0, 'bucket_end']);
        bucketStart = (prevBucketEnd ? (prevBucketEnd + 1) : 0) || 0;
      }
      let bucketEnd = (per || 0) * 10 + bucketStart - 1;
      if (isNaN(bucketEnd)) {
        bucketEnd = '';
      } else if (bucketEnd < 0) {
        bucketEnd = 0;
      } else if (bucketEnd > 999) {
        bucketEnd = 999;
      }

      if(bucketStart === 0 && bucketEnd === 0) {
        bucketStart = '';
        bucketEnd = '';
      }

      partitionSet = partitionSet.setIn(['partitions', i, 'ruleset', 'bucket_rules', 0, 'bucket_start'], bucketStart).setIn(['partitions', i, 'ruleset', 'bucket_rules', 0, 'bucket_end'], bucketEnd);
    });

    this.setState({ partitionPercentages, psetEdited: partitionSet });
  }

  render() {
    var warningMessage;
    if (this.state.warningMessagePresent) {
      warningMessage = <CustomDeleteModal handleDelete={this.handleDelete} id={this.state.psetEdited.id} name={this.state.psetEdited.description} handleCancel={this.toggleWarningMessage} />
    }
    var errorMessage;
    var editButtonClassName = "";
    var editingButtons;
    var name = this.state.psetEdited.description;
    var campaignTypeCode = this.state.psetEdited.campaign_type_id;

    const campaignType = this.props.isMultiCampaignEnabled && (
      <UpdateCampaignType 
        currentUser={this.props.currentUser} 
        propertyType={this.props.propertyType}
        campaignTypeCode={campaignTypeCode}
        recordId={this.state.psetEdited.id}
        editId={this.state.editing ? '' : this.props.editId}
        setEditId={this.props.setEditId}
        updateCampaignType={this.updateCampaignType}
        disableSetTypeBtn={this.state.editing}
      />
    );
    const campaignTypeWithLabel = this.props.isMultiCampaignEnabled && (
      <React.Fragment> 
       | <span className="campaign-type-label">&nbsp; Campaign Type : &nbsp;</span><span className="campaign-type-value">{campaignType}</span>
      </React.Fragment>
    );

    if (this.state.editing) {
      editingButtons = (
        <div className="panel-buttons">
          <Button type='tertiary' size='small' onClick={this.handleAdd}>+&nbsp;&nbsp;Add Partition</Button>
          <Button type='secondary' size='small' onClick={this.handleCancelEditing}>Cancel</Button>
          <Button type='primary' size='small' onClick={this.handleSave}>Save</Button>
        </div>
      );
      editButtonClassName = "active";
      name = <React.Fragment><input ref="partitonsetName" type="text" value={name} onChange={this.handleNameChange} /> <span className="partition-campaign-type">{campaignTypeWithLabel}</span></React.Fragment>;
    } else {
      name = <span className="partition-campaign-type"><h3>Partition Set :</h3>&nbsp; <span className='partitonset-name'> {name} </span> &nbsp; {campaignTypeWithLabel} </span>;
    }

    if (this.state.errorMessage) errorMessage = <p className="error">{this.state.errorMessage}</p>

    let switchOptions;
    if (!getParameterByName('site_group_id', window.location)) {
      switchOptions = [
        {
          isChecked: this.state.copyScenarios,
          onChecked: this.onCheckedScenarios,
          popupText: 'Copy with Scenarios',
          disabled: false,
        },
        {
          // isChecked considers the state of copyScenarios because when
          // copyScenarios is false, then copyMessages is automatically false,
          // and disabled
          isChecked: this.state.copyScenarios && this.state.copyMessages,
          onChecked: this.onCheckedMessages,
          popupText: 'Copy with Messages',
          disabled: !this.state.copyScenarios,
        },
      ];
    }

    let buttons;
    if (!this.props.readOnly) {
      buttons = (
        <div className="buttons-group">
          <CustomIcon
            type={CustomIcon.types.EDIT}
            onClick={this.handleEdit}
            className={editButtonClassName}
            tooltip="edit"
          />
          <PopoverButton
            onClick={this.copyPartitionset}
            switchOptions={switchOptions}
            type={PopoverButton.types.COPY}
          />
          <CustomIcon
            type={CustomIcon.types.DELETE}
            onClick={this.toggleWarningMessage}
            tooltip="delete"
          />
        </div>
      )
    }

    return (
      <div className="partitionset panel panel-default card">
        <div className="panel-heading">
          {name}
          {buttons}
        </div>

        <div className="panel-body">
          <table className="tiny table partition panel-table table" id='partition-table'>
            <thead>
              <tr>
                <th className="long-col">Name</th>
                <th className="short-col">Partition %</th>
                <th className="long-col" colSpan={2}>Scenario</th>
              </tr>
            </thead>
            <tbody>
              {this.state.psetEdited.partitions.map(function (partition, i) {
                var key = this.state.psetEdited.id + '_' + i;
                var last = false;
                if (i === this.state.psetEdited.partitions.length - 1) last = true;
                return (
                  <PartitionRow
                    key={key}
                    index={i}
                    partition={partition}
                    updatePartitionRow={this.updatePartitionRow.bind(this, i)}
                    deletePatitionRow={this.deletePatitionRow.bind(this, i)}
                    scenarios={this.props.scenarios}
                    editing={this.state.editing}
                    last={last}
                    partitionPercentage={this.state.partitionPercentages[i]}
                    updatePartitionPercentage={this.updatePartitionPercentage.bind(this, i)}
                  />
                );
              }.bind(this))}
            </tbody>
          </table>
        </div>
        {editingButtons}
        {errorMessage}
        {warningMessage}
      </div>
    );
  }
}

export default Partitionset;