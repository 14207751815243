import React from "react";
import { ConfigProvider } from 'antd';
import moment from "moment";
import enUS from "antd/lib/locale/en_US";
import PropTypes from "prop-types";
import { RangeDates } from "../../records/reports_records.js";
import { DATEPICKERS } from "../../constants";
import AntdDatePicker from "../common/AntdDatePicker.jsx";

export default class CustomDatePicker extends React.Component {
  static propTypes = {
    selectDates: PropTypes.func.isRequired,
    limit: PropTypes.number,
    defaultValue: PropTypes.instanceOf(RangeDates),
    disabled: PropTypes.bool,
    disabledDateOffset: PropTypes.number,
    selectedDatePicker: PropTypes.string
  };

  static defaultProps = {
    limit: Infinity,
    disabled: false,
    disabledDateOffset: 0
  };

  handleRangeChange = date => {
    let start;
    let end;
    const { selectedDatePicker } = this.props;
    if (selectedDatePicker === DATEPICKERS.day) {
      start = date;
      end = date;
    } else if (selectedDatePicker === DATEPICKERS.week) {
      start = moment(date).startOf("isoWeek");
      end = moment(date).endOf("isoWeek");
    } else if (selectedDatePicker === DATEPICKERS.month) {
      start = moment(date).startOf("month");
      end = moment(date).endOf("month");
    }
    this.props.selectDates({ start, end }, this.props.selectedDatePicker);
  };

  disabledDate = current => {
    return (
      moment()
        .subtract(this.props.disabledDateOffset, "days")
        .diff(current) < 0
    );
  };

  render() {
    let Picker;
    let defaultValue;
    if (this.props.selectedDatePicker === "WEEK") {
      Picker = AntdDatePicker.WeekPicker;
      defaultValue = moment().subtract(1, "weeks");
    } else if (this.props.selectedDatePicker === "MONTH") {
      Picker = AntdDatePicker.MonthPicker;
      defaultValue = moment().subtract(1, "months");
    } else {
      Picker = AntdDatePicker;
      defaultValue = moment().subtract(1, "days");
    }
    const pickerProps = {
      disabled: this.props.disabled,
      allowClear: false,
      size: "large",
      onChange: this.handleRangeChange,
      disabledDate: this.disabledDate,
      defaultValue: defaultValue
    };

    return (
      <ConfigProvider locale={enUS}>
        <Picker {...pickerProps} />
      </ConfigProvider>
    );
  }
}
