import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AceEditor from 'react-ace';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Select, Checkbox, Button, Collapse, message, Modal, Tooltip, Radio } from 'antd';
import { List, Map } from 'immutable';
import {
  getPrivacyManager,
  updatePrivacyManager,
  createPrivacyManager,
  getPrivacyManagerList
} from '../../../actions/privacy_manager_actions.js';
import { getVendorLists, getVendorList } from '../../../actions/vendor_list_actions';
import { getAllSites } from '../../../actions/site_actions.js';

import Loading from '../../common/Loading.js.jsx';
import { PrivacyManager, CategoryView } from '../../../records/privacy_manager_records';
import CustomButton from '../../common/CustomButton.js.jsx';
import ColorPickerInput from '../../common/ColorPickerInput.js.jsx';
import PrivacyPreview from './PrivacyPreview';
import PrivacyEditor from './PrivacyEditor';
import OverridePurposeNamingModal from './OverridePurposeNamingModal';
import { getParameterByName } from '../../utils.js';
import { COUNTRY_LANGUAGES } from '../../../constants';
import { User } from '../../../records/account_records';

const Panel = Collapse.Panel;
const Option = Select.Option;
const { TextArea } = Input;

class PrivacyManagerEdit extends React.Component {
  static propTypes = {
    privacyManager: PropTypes.instanceOf(PrivacyManager).isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      v2: PropTypes.bool,
    }).isRequired,

    getPrivacyManager: PropTypes.func.isRequired,
    getVendorLists: PropTypes.func.isRequired,
    updatePrivacyManager: PropTypes.func.isRequired,
    createPrivacyManager: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    getVendorList: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const update = {};
    if (!prevState.pm.id && nextProps.privacyManager.id) {
      update.pm = nextProps.privacyManager;
    }
    //need to keep track when data was initially fetched
    //because redux store pending statuses sometimes available aftere initial render
    if (prevState.pendingRequestsMap !== nextProps.pendingRequestsMap) {
      update.fetchedData = true;
    }
    return update;
  }

  state = {
    pm: this.props.privacyManager,
    showPreview: false,
    siteId: null,
    modalVisible: false,
    editingPurposeIdx: -1,
    querySelector: '',
    showAsModal: true,
    fetchedData: false,
    pendingRequestsMap: this.props.pendingRequestsMap,
    pmv : 'v1',
  }

  componentDidMount() {
    this.props.getAllSites();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      if (this.props.location.query.id) {
        this.props.getPrivacyManager(this.props.location.query.id, this.props.currentUser.accountId);
      }
      
      if (!this.props.currentUser.siteAccess) {
        this.props.getVendorLists(this.props.currentUser.accountId);
      } else {
        this.props.getVendorLists(this.props.currentUser.accountId, this.props.currentUser.siteAccess);
      }
  
      //TODO: implement cache
      this.props.getPrivacyManagerList(this.props.currentUser.accountId);
    }

    if (!prevProps.location.query.id && this.props.location.query.id) {
      this.props.getPrivacyManager(this.props.location.query.id, this.props.currentUser.accountId);
    }

    if (prevProps.pendingRequestsMap.get('privacyManager') && !this.props.pendingRequestsMap.get('privacyManager') &&
      this.props.privacyManager.vendorListId) {
      this.props.getVendorList(this.props.privacyManager.vendorListId, this.props.currentUser.accountId);
    }
  }

  handleSelect = (vendorListId) => {
    this.setState({
      pm: this.state.pm.set('vendorListId', vendorListId),
      siteId: null,
    });
    this.props.getVendorList(vendorListId, this.props.currentUser.accountId);
  }

  handleLanguage = (country) => {
    this.setState({ pm: this.state.pm.set('consentLanguage', country) });
  }

  handleChange = (e, property) => {
    this.setState({ pm: this.state.pm.set(property, e.target ? e.target.value : e) });
  }

  handleRadioChange = (e, property) => {
    let val = e.target ? e.target.value : e;
    if (val === 'null') val = null;
    this.setState({ pm: this.state.pm.set(property, val) });
  }

  handleRadioPMVChange = (v) => {
    this.setState({ pmv: v });
  }

  handleVendorListCheckBox = (e) => {
    const checked = e.target.checked;
    let pm = this.state.pm.set('hideVendorList', !checked);
    if (!checked) {
      pm = pm.set('enableVendorOptIn', false);
    }
    this.setState({ pm });
  }

  handleStyleChange = (e, property) => {
    this.setState({ pm: this.state.pm.setIn(['cssStyles', property], e.target ? e.target.value : e) });
  }

  handleTogglePreview = () => {
    this.setState({ showPreview: !this.state.showPreview });
  }

  handleUpdateCategoryView = (categoryView) => {
    let categoryViews = this.state.pm.categoryViews;
    categoryViews = categoryViews
      .filterNot(cv => cv.categoryId === categoryView.categoryId)
      .push(categoryView);

    this.setState({ pm: this.state.pm.set('categoryViews', categoryViews) });
  }

  handleSave = () => {
    //TODO: add validations
    let errorMessages = new List([]);
    const pm = this.state.pm.set('name', this.state.pm.name.trim());
    if (this.props.privacyManagerList.filterNot(p => p.id === pm.id).some(p => p.name === pm.name)) {
      errorMessages = errorMessages.push(`Privacy manager with name "${pm.name}" already exists. Please rename this privacy manager.`)
    } else if (!pm.name) {
      errorMessages = errorMessages.push(`Privacy manager has no name. Please name this privacy manager.`)
    }

    if (!pm.vendorListId) {
      errorMessages = errorMessages.push(`Privacy manager has no vendor list. Please select vendor list for this privacy manager.`)
    }

    if (errorMessages.size) {
      const content = errorMessages.map((m, i) => <div key={'e' + i}><p>{m}</p></div>)

      Modal.warning({
        title: 'Privacy manager has not been saved',
        content,
      });
      return;
    }

    message.success('Your privacy manager has been saved.', 5);

    if (this.props.location.pathname === "/consent/privacy_manager/create") {
      this.props.createPrivacyManager(this.props.currentUser.accountId, pm).then((resp) => {
        //when saving newly created list redirect to edit route
        //TODO: use client side ids and get rid of then
        browserHistory.push('/consent/privacy_manager/edit?id=' + resp.id);
      });
    } else {
      this.props.updatePrivacyManager(this.props.currentUser.accountId, pm)
    }
  }

  handleCustomStylesUpdate = () => {
    const newVal = this.customEditor.editor.getValue();
    this.setState({ pm: this.state.pm.setIn(['cssStyles', 'customCss'], newVal) });
  };

  handleQuerySelectorChange = (e) => this.setState({ querySelector: e.target.value })

  copyContent = (url) => {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.success('Copied to clipboard');
  };

  handleSelectSiteId = (siteId) => {
    this.setState({
      siteId,
    });
  }

  handleChangeShowAsModal = (e) => {
    e.stopPropagation();
    this.setState({ showAsModal: e.target.checked });
  }

  toggleModal = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  }

  setEditingPurpose = (e, idx) => {
    e.stopPropagation();
    this.setState({ editingPurposeIdx: idx });
  }

  closeEditPuposeModal = () => {
    this.setState({ editingPurposeIdx: -1 });
  }
  render() {
    if (!this.state.fetchedData || this.props.pendingRequestsMap.some(request => request)) {
      return <Loading />;
    }

    let readOnly = false;
    if (this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('privacy_manager_mgmt')) {
      readOnly = true;
    }

    const vendor = this.props.vendorLists
      .find(vendorList => vendorList.id === this.state.pm.vendorListId)
    const siteIds = vendor == null ? List([]) : vendor.get('siteIds');
    const showableSiteIds = siteIds.map(siteId => {
      return this.props.sites.find(site => site.id === siteId)
    }).filterNot(site => site == null);

    const funcName = this.state.showAsModal ? 'loadPrivacyManagerModal' : 'loadPrivacyManager';
    const baseLink = this.state.pmv == 'v1' ? 'window._sp_.privacyManager' : 'window._sp_';
    const baseCode = `${baseLink}.${funcName}(${this.state.siteId }, '${this.state.pm.id}'`;
    const previewCode = !this.state.showAsModal && this.state.querySelector ?
      `${baseCode}, '${this.state.querySelector}')` : `${baseCode})`;

    const content = (
      <div>
        <div>
          <Checkbox
            onChange={this.handleChangeShowAsModal}
            checked={this.state.showAsModal}
            disabled={readOnly}
          >Show as a modal
          </Checkbox>
        </div>
        <label>Select Property Domain (required)</label>
        <Select
          onChange={this.handleSelectSiteId}
          value={this.state.siteId}
          disabled={readOnly}
        >
          {
            showableSiteIds.map((site) => {
              return <Option key={site.id}>{site.domain}</Option>;
            })
          }
        </Select>
        <label>Privacy Manager Version</label>
        <div>
          <Radio.Group
            value={this.state.pmv}
            defaultValue="v1"
            onChange={(e) => this.handleRadioPMVChange(e.target.value)}
            buttonStyle="solid">
              <Radio.Button value="v1">V1</Radio.Button>
              <Radio.Button value="v2">V2</Radio.Button>
          </Radio.Group>
        </div><br/>
        <label>Query Selector for attaching the iframe (defaults to body. Unused if shown as a modal)</label>
        <Input
          disabled={this.state.showAsModal}
          value={this.state.querySelector}
          onChange={(e) => this.handleQuerySelectorChange(e)}
          disabled={readOnly}
        />
        {this.state.siteId?
          <React.Fragment>
          <label>Click to copy this code. Put into an event handler (onclick, etc.) for a control on the page</label>
          <TextArea
            className='copy-field'
            onFocus={() => this.copyContent(previewCode)}
            value={previewCode}
            rows={2}
            readOnly={true}
            disabled={readOnly}
          />
        </React.Fragment>
        :null}

      </div>
    );

    const copyUrl = (
      <div className='copy-url-modal'>
        <CustomButton type={CustomButton.types.PLAIN} onClick={this.toggleModal}>Get JS Code</CustomButton>
        <Modal
          title='Copy to clipboard'
          visible={this.state.modalVisible}
          onCancel={this.toggleModal}
          footer={[<Button key='cancel' onClick={this.toggleModal}>Close</Button>]}>
          {content}
        </Modal>
      </div>
    );

    let saveButton;
    if (!readOnly) {
      saveButton = (
        <CustomButton
          type={CustomButton.types.SUCCESS}
          onClick={this.handleSave}
          disabled={readOnly}
        >
          Save
        </CustomButton>
      );
    }
    const buttons = (
      <div className="panel-buttons">
        {copyUrl}
        <CustomButton type={CustomButton.types.PLAIN} onClick={this.handleTogglePreview}>Show Preview</CustomButton>
        {saveButton}
      </div>
    );

    const styles = this.state.pm.cssStyles;

    let preview;
    if (this.state.showPreview) {
      preview = (
        <PrivacyPreview
          privacyManager={this.state.pm}
          closePreview={this.handleTogglePreview}
          name={this.state.pm.name}
          handleSave={this.handleSave}
          vendorListId={this.state.pm.vendorListId || ''}
        />
      );
    }

    const advanced = (
      <Collapse>
        <Panel header="Advanced" key="1">
          <Button
            onClick={this.handleCustomStylesUpdate}
            disabled={readOnly}
          >
            Save
          </Button>
          <AceEditor
            ref={AceEditor => { this.customEditor = AceEditor; }}
            value={this.state.pm.cssStyles.customCss}
            mode='css'
            theme='twilight'
            width='100%'
            height='340px'
            focus
            readOnly={readOnly}
            commands={[
              {
                name: 'update on cmd+enter',
                exec: this.handleCustomStylesUpdate,
                bindKey: { mac: 'cmd-enter', win: 'ctrl-f' },
              },
            ]}
          />
        </Panel>
      </Collapse>
    );

    let vlPurposes;
    let overridePurposeNamingModal;

    if (this.state.pm.vendorListId) {
      const categories = this.props.vendorLists.find(vl => vl.id === this.state.pm.vendorListId).categories;

      const categoryViews = categories.map(c => {
        if (this.state.pm.categoryViews.map(cv => cv.categoryId).includes(c.id)) {
          return this.state.pm.categoryViews.find(cv => cv.categoryId === c.id);
        } else {
          return new CategoryView({ categoryId: c.id, name: c.name, description: c.description });
        }
      });

      const tooltipText = 'optional - used to override the purpose names, and descriptions from the vendor list.'

      vlPurposes = (
        <div className='container'>
          <div className='container-left'>
            <Tooltip title={tooltipText} ><p className='title'>Override purposes naming</p><InfoCircleOutlined /></Tooltip>
          </div>
          <table>
            <thead className="ant-table-thead">
              <tr>
                {categoryViews.map((c, i) => <th key={'cv-' + i} onClick={(e) => this.setEditingPurpose(e, i)} >{c.name}</th>)}
              </tr>
            </thead>
          </table>
        </div>
      );

      if (this.state.editingPurposeIdx !== -1) {

        overridePurposeNamingModal = (
          <OverridePurposeNamingModal
            purpose={categories.get(this.state.editingPurposeIdx)}
            closeModal={this.closeEditPuposeModal}
            handleApply={this.handleUpdateCategoryView}
            categoryView={categoryViews.get(this.state.editingPurposeIdx)}
            readOnly={readOnly}
          />
        );
      }
    }

    const neutralText = 'This changes the toggles to active buttons where you can have Opt-in, Opt-out or Neutral by default.'
    let neutralValue = this.state.pm.defaultPurposeSelectionNeutral;
    if (neutralValue === null) neutralValue = 'null';

    const v2 = this.props.route.v2 ? '_v2' : '';

    return (
      <div className='privacy-manager-container'>
        { preview }
        <Link to={`/consent${v2}/privacy_manager`}>{'< Back to Privacy Managers'}</Link>
        <Input
          value={this.state.pm.name}
          onChange={(e) => this.handleChange(e, 'name')}
          placeholder='Privacy Manager Name...'
          className='main-title'
          disabled={readOnly}
        />
        {buttons}
        <div className='main-container'>
          {overridePurposeNamingModal}
          <div className='container'>
            <p className='title'>Select a vendor list</p>
            <div className='selectors select'>
              <Select
                value={this.state.pm.vendorListId || null}
                onChange={this.handleSelect}
                placeholder='Select a vendor list...'
                disabled={readOnly}
              >
                {this.props.vendorLists.map(vendor => <Option value={vendor.id} key={vendor.id} >{vendor.name}</Option>)}
              </Select>
              <div className='checkboxes-container'>
                <Checkbox
                  onChange={(e) => this.handleChange(!e.target.checked, 'hideVendorListUnderPurpose')}
                  checked={!this.state.pm.hideVendorListUnderPurpose}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Show Vendor names under Purpose info</p>
                  <p className='checkbox-descr'>Show all vendors that are associated to a purpose</p>
                </Checkbox>
                <Checkbox
                  onChange={this.handleVendorListCheckBox}
                  checked={!this.state.pm.hideVendorList}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Show &quot;Vendors on site&quot; button</p>
                  <p className='checkbox-descr'>When clicked, this button will display all vendors on site and the ability to turn off the vendor across all associated purposes.</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'enableVendorOptIn')}
                  checked={this.state.pm.enableVendorOptIn}
                  disabled={this.state.pm.hideVendorList || readOnly}
                >
                  <p className='checkbox-title'>Enable vendor level opt-in</p>
                  <p className='checkbox-descr'>Users can opt-in or out of particular vendors. Deselecting will hide toggle switch in the “Vendors on site” section.</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'defaultOptedIn')}
                  checked={this.state.pm.defaultOptedIn}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Opt-in to all vendors/purposes by default</p>
                  <p className='checkbox-descr'>Have all vendors and purposes turned on or off by default</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'showRejectAll')}
                  checked={this.state.pm.showRejectAll}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Show reject all purposes button</p>
                  <p className='checkbox-descr'>When enabled, a button will show next to enable all that will allow the user to reject all purposes and exit.</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'showConsentButtonsBottom')}
                  checked={this.state.pm.showConsentButtonsBottom}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Show consent buttons on bottom</p>
                  <p className='checkbox-descr'>When enabled, the consent all and reject all buttons will be displayed in the footer at the bottom instead of at the top.</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'displaySubVendorsUrl')}
                  checked={this.state.pm.displaySubVendorsUrl}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Show sub-vendors url</p>
                  <p className='checkbox-descr'>If a vendor such as Google Ad manager provides a url that links to the sub-vendors it uses, the url will be shown in the vendors section of the privacy manager.</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'displayLegInt')}
                  checked={this.state.pm.displayLegInt}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Show legitimate-interest</p>
                  <p className='checkbox-descr'>Display the vendors requiring legitimate interest within the Purposes tab, as well as display Legitimate interest purposes per vendor within the Site Vendors tab.</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'hidePrivacyUrls')}
                  checked={this.state.pm.hidePrivacyUrls}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Hide privacy urls</p>
                  <p className='checkbox-descr'>When enabled, the list of vendors under the purposes tab will not be clickable, even if their privacy url is provided.</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'displayFeatures')}
                  checked={this.state.pm.displayFeatures}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Show features</p>
                  <p className='checkbox-descr'>Show vendor features - Display features per vendor within the Site Vendors tab.</p>
                </Checkbox>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'tabLevelVendorToggle')}
                  checked={this.state.pm.tabLevelVendorToggle}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Toggle vendors without expanding tabs</p>
                  <p className='checkbox-descr'>By default, you have to expand a vendor tab to reveal the toggle button.  Enabling this will let you toggle the vendor without expanding the tab.</p>
                </Checkbox>
              </div>
            </div>
          </div>

          <div className='container-neutral'>
            <div className='container-left'>
              <Tooltip title={neutralText} ><p className='title'>Activate button purpose controls</p><InfoCircleOutlined /></Tooltip>
            </div>
            <div className='selectors select'>
              <div className='checkboxes-container' style={{ marginTop: 0 }}>
                <Checkbox
                  onChange={(e) => this.handleChange(e.target.checked, 'triStatePurposes')}
                  checked={this.state.pm.triStatePurposes}
                  disabled={readOnly}
                >
                  <p className='checkbox-title'>Use activated button controls</p>
                  <p className='checkbox-descr'>
                    When enabled, the default switches for the Privacy Manager will changed to three state toggles that can have
                    three different states: Opt-in, Opt-out, and Neutral
                  </p>
                </Checkbox>

                <div className='radio-container' style={{ display: this.state.pm.triStatePurposes ? 'block' : 'none' }}>
                  <p>Default consent state for active button controls</p>
                  <Radio.Group
                    value={neutralValue}
                    onChange={(e) => this.handleRadioChange(e.target.value, 'defaultPurposeSelectionNeutral')}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="null">Neutral</Radio.Button>
                    <Radio.Button value={true}>Opt-in</Radio.Button>
                    <Radio.Button value={false}>Opt-out</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </div>

          {vlPurposes}

          <div className='container'>
            <p className='title'>Configure text inputs</p>
            <div className='selectors text'>
              <label>Select a default consent language</label>
              <Select
                value={this.state.pm.consentLanguage}
                onChange={this.handleLanguage}
                placeholder='Select a language...'
                disabled={readOnly}
              >
                {COUNTRY_LANGUAGES.map((o) => <Option key={o.code}>{o.language}</Option>)}
              </Select>
              <label>Logo Url (optional)</label>
              <TextArea
                value={this.state.pm.logoUrl}
                onChange={(e) => this.handleChange(e, 'logoUrl')}
                autosize={{ minRows: 1, maxRows: 6 }}
                disabled={readOnly}
              />
              <label>Title</label>
              <Input
                value={this.state.pm.title}
                onChange={(e) => this.handleChange(e, 'title')}
                disabled={readOnly}
              />
              <label>Description</label>
              <PrivacyEditor
                value={this.state.pm.description}
                onChange={(e) => this.handleChange(e, 'description')}
                readOnly={readOnly}
              />
              <label>Privacy policy text</label>
              <Input
                value={this.state.pm.privacyPolicyText}
                onChange={(e) => this.handleChange(e, 'privacyPolicyText')}
                disabled={readOnly}
              />
              <label>Privacy policy URL</label>
              <Input
                value={this.state.pm.privacyPolicyUrl}
                onChange={(e) => this.handleChange(e, 'privacyPolicyUrl')}
                disabled={readOnly}
              />
              <label>Enable all button text</label>
              <Input
                value={this.state.pm.enableAllText}
                onChange={(e) => this.handleChange(e, 'enableAllText')}
                disabled={readOnly}
              />
              <label>Reject all button text</label>
              <Input
                value={this.state.pm.rejectAllText}
                onChange={(e) => this.handleChange(e, 'rejectAllText')}
                disabled={readOnly}
              />
              <label>Consent toggle text</label>
              <Input
                value={this.state.pm.consentToggleText}
                onChange={(e) => this.handleChange(e, 'consentToggleText')}
                disabled={readOnly}
              />
              <label>Reject toggle text</label>
              <Input
                value={this.state.pm.rejectToggleText}
                onChange={(e) => this.handleChange(e, 'rejectToggleText')}
                disabled={readOnly}
              />
              <label>Purposes tab text</label>
              <Input
                value={this.state.pm.purposeTabText}
                onChange={(e) => this.handleChange(e, 'purposeTabText')}
                disabled={readOnly}
              />
              <label>Purpose tab - Legitimate interest title</label>
              <Input
                value={this.state.pm.purposeTabLegIntTitle}
                onChange={(e) => this.handleChange(e, 'purposeTabLegIntTitle')}
                disabled={readOnly}
              />
              <label>Purposes tab - Legitimate interest description</label>
              <TextArea
                value={this.state.pm.purposeTabLegIntDescription}
                onChange={(e) => this.handleChange(e, 'purposeTabLegIntDescription')}
                autosize={{ minRows: 2, maxRows: 6 }}
                disabled={readOnly}
              />
              <label>Vendors tab text</label>
              <Input
                value={this.state.pm.vendorTabText}
                onChange={(e) => this.handleChange(e, 'vendorTabText')}
                disabled={readOnly}
              />
              <label>Vendors tab - Purpose title</label>
              <Input
                value={this.state.pm.vendorTabPurposeTitle}
                onChange={(e) => this.handleChange(e, 'vendorTabPurposeTitle')}
                disabled={readOnly}
              />
              <label>Vendors tab - Legitimate interest title</label>
              <Input
                value={this.state.pm.vendorTabLegIntPurposeTitle}
                onChange={(e) => this.handleChange(e, 'vendorTabLegIntPurposeTitle')}
                disabled={readOnly}
              />
              <label>Vendors - Legitimate interest description</label>
              <TextArea
                value={this.state.pm.vendorTabLegIntPurposetDescription}
                onChange={(e) => this.handleChange(e, 'vendorTabLegIntPurposetDescription')}
                autosize={{ minRows: 2, maxRows: 6 }}
                disabled={readOnly}
              />
              <label>Save & Exit button text</label>
              <Input
                value={this.state.pm.saveAndExitText}
                onChange={(e) => this.handleChange(e, 'saveAndExitText')}
                disabled={readOnly}
              />
              <label>Cancel button text</label>
              <Input
                value={this.state.pm.cancelText}
                onChange={(e) => this.handleChange(e, 'cancelText')}
                disabled={readOnly}
              />
              <label>Vendors on site button</label>
              <Input
                value={this.state.pm.vendorListTitleText}
                onChange={(e) => this.handleChange(e, 'vendorListTitleText')}
                disabled={readOnly}
              />
            </div>
          </div>

          <div className='container'>
            <div className='title'><p className='title'>Style editor</p></div>
            <div className='selectors styles'>
              <div className='fontFamily'>
                <label>Font Family</label>
                <Input
                  value={styles.fontFamily}
                  onChange={(e) => this.handleStyleChange(e, 'fontFamily')}
                  disabled={readOnly}
                />
              </div>
              <div className='group'>
                <div>
                  <label>Save Button BG</label>
                  <ColorPickerInput
                    value={styles.saveButtonBG}
                    onUpdate={(e) => this.handleStyleChange(e, 'saveButtonBG')}
                    editing={!readOnly}
                  />
                </div>
                <div>
                  <label>Cancel Button BG</label>
                  <ColorPickerInput
                    value={styles.cancelButtonBG}
                    onUpdate={(e) => this.handleStyleChange(e, 'cancelButtonBG')}
                    editing={!readOnly}
                  />
                </div>
                <div>
                  <label>Enable all BG</label>
                  <ColorPickerInput
                    value={styles.enableAllBg}
                    onUpdate={(e) => this.handleStyleChange(e, 'enableAllBg')}
                    editing={!readOnly}
                  />
                </div>
                <div>
                  <label>Reject all BG</label>
                  <ColorPickerInput
                    value={styles.rejectAllBg}
                    onUpdate={(e) => this.handleStyleChange(e, 'rejectAllBg')}
                    editing={!readOnly}
                  />
                </div>
                <div>
                  <label>Button Radius</label>
                  <div className="options-copy-font-size-and-opacity">
                    <Input
                      value={styles.buttonRadius}
                      onChange={(e) => this.handleStyleChange(e, 'buttonRadius')}
                      disabled={readOnly}
                    />
                  </div>
                </div>
                <div>
                  <label>Save Text</label>
                  <ColorPickerInput
                    value={styles.saveText}
                    onUpdate={(e) => this.handleStyleChange(e, 'saveText')}
                    editing={!readOnly}
                  />
                </div>
                <div>
                  <label>Cancel Text</label>
                  <ColorPickerInput
                    value={styles.cancelText}
                    onUpdate={(e) => this.handleStyleChange(e, 'cancelText')}
                    editing={!readOnly}
                  />
                </div>
                <div>
                  <label>Enable all text</label>
                  <ColorPickerInput
                    value={styles.enableAllText}
                    onUpdate={(e) => this.handleStyleChange(e, 'enableAllText')}
                    editing={!readOnly}
                  />
                </div>
                <div>
                  <label>Reject all text</label>
                  <ColorPickerInput
                    value={styles.rejectAllText}
                    onUpdate={(e) => this.handleStyleChange(e, 'rejectAllText')}
                    editing={!readOnly}
                  />
                </div>
                <div>
                  <label>Modal Radius</label>
                  <div className="options-copy-font-size-and-opacity">
                    <Input
                      value={styles.modalRadius}
                      onChange={(e) => this.handleStyleChange(e, 'modalRadius')}
                      disabled={readOnly}
                    />
                  </div>
                </div>
              </div>
              {advanced}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  const privacyManagerList = store.privacyManagerState.getIn(['privacyManagerList', 'value']);
  const privacyManagerId = getParameterByName('id', window.location);
  let privacyManager;
  if (privacyManagerId && privacyManagerList.size) {
    privacyManager = privacyManagerList.find(pm => pm.id === privacyManagerId);
  } else {
    const autoNameRegex = /(New Privacy Manager)($| \([0-9]+\))/;
    const autoNameCount = privacyManagerList.filter(pm => { return pm.name.match(autoNameRegex) }).size;
    privacyManager = new PrivacyManager({});
    if (autoNameCount) {
      privacyManager = privacyManager.set('name', `New Privacy Manager (${autoNameCount + 1})`);
    }
  }

  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    privacyManager,
    privacyManagerList: store.privacyManagerState.getIn(['privacyManagerList', 'value']),
    vendorLists: store.vendorListState.getIn(['vendorLists', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    pendingRequestsMap: new Map({
      privacyManager: store.privacyManagerState.getIn(['privacyManagerList', 'pending']),
      privacyManagerSave: store.privacyManagerState.get('savePending'),
      vendorLists: store.vendorListState.getIn(['vendorLists', 'pending']),
      sites: store.siteState.getIn(['sites', 'pending']),
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getPrivacyManager,
    getVendorLists,
    updatePrivacyManager,
    createPrivacyManager,
    getAllSites,
    getPrivacyManagerList,
    getVendorList,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyManagerEdit);
