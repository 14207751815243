import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { message } from 'antd';
import { List } from 'immutable';

import TypePositionChoice from './TypePositionChoice.js.jsx';
import AntdFormItem from '../../../common/AntdFormItem.jsx';
import AntdTextarea from '../../../common/AntdTextarea.jsx';
import { CONSTANTS, TYPES, SUBTYPE } from '../../../../constants.js';
import { MessageConfigRecord } from '../../../../records/message_config_records.js';
import { ChoiceConfigRecord, ExecJS, ListOption } from '../../../../records/choice_config_records.js';

export default class extends React.Component {
  static propTypes = {
      className: PropTypes.string,
      messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
      updateMessageConfig: PropTypes.func.isRequired,
      choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
      updateChoiceConfig: PropTypes.func.isRequired,
      editing: PropTypes.bool.isRequired,
      isSPUser: PropTypes.bool.isRequired,
  };

  componentWillReceiveProps(nextProps) {
      if (this.props.messageConfig.type !== TYPES.LOCK && this.props.messageConfig.data.subType !== SUBTYPE.INLINE) {
          if (nextProps.messageConfig) {
              if (nextProps.messageConfig.type === TYPES.LOCK && nextProps.messageConfig.data.subType === SUBTYPE.INLINE)
              message.warning('For Inline Lock, a demo site is being used in place of your site.', 5);
          }
      }
  }

  onMessageConfigChange = (e) => {
    let newMessageConfig;
    if (CONSTANTS.SUBTYPE in e) {
      newMessageConfig = this.props.messageConfig.set(CONSTANTS.TYPE, e.type)
        .setIn(['data', 'subType'], e.subType);

      if (e.subType === 'html') {
        newMessageConfig = newMessageConfig.setIn(['data', 'style', 'includeDefaultStyles'], false);

        const newCustomOption = new ExecJS({
          button_text: 'Custom JS',
          js_fn_name: '_sp_customHTMLCallback',
          comp_period_sec: 120,
          enabled: true,
        });

        const newCC = this.props.choiceConfig
          .set('option_list', List([new ListOption()]))
          .setIn(['option_list', 0, 'choice_option_type'], 9)
          .setIn(['option_list', 0, 'option_data'], newCustomOption);

        this.props.updateChoiceConfig(newCC);
      }
      else {
        newMessageConfig = newMessageConfig.setIn(['data', 'style', 'includeDefaultStyles'], true);
      }

    } else {
      const destination = {
        'destination-selector': 'data.options.destinationSelector',
        'remove-page-elements-selector': 'data.options.removePageElementsSelector',
        'redirect-destination': 'data.options.destination',
        'custom-html': 'data.options.custom.html',
      };

      const path = destination[e.target.id].split('.');
      newMessageConfig = this.props.messageConfig.setIn(path, e.target.value);
    }

    this.props.updateMessageConfig(newMessageConfig);
  };

  renderTypeAndPos = (type, subType) => {
      const active = (
        type === this.props.messageConfig.get('type') && subType === this.props.messageConfig.getIn(['data','subType'])
      ) ? true : false;

      return (
        <TypePositionChoice
            active={ active }
            type={ type }
            subType={ subType }
            onClick={ () => this.onMessageConfigChange({ type: type, subType: subType }) }
            editing={ this.props.editing }
        />
      );
  };

  render() {
      const type = this.props.messageConfig.get('type');
      const subType = this.props.messageConfig.getIn(['data','subType']);

      const selectors = this.props.messageConfig.getIn(['data','options']);
      const destinationSelector = selectors.get('destinationSelector');
      const removePageElementsSelector = selectors.get('removePageElementsSelector');
      const destination = selectors.get('destination');
      const customHTML = this.props.messageConfig.data.options.custom.html;

      const { getFieldDecorator } = this.props.form;
      const className = classNames(this.props.className, 'type-and-position', { 'bottom-card': type === TYPES.REDIRECT });

      return (
          <div className={ className }>
              <h3>Message Type</h3>
              <div className="type-tiles">
                <div className="tiles-col">
                  <ul className="options-tiles">
                    { this.renderTypeAndPos(TYPES.LOCK, SUBTYPE.MODAL) }
                    { this.renderTypeAndPos(TYPES.LOCK, SUBTYPE.INLINE) }
                  </ul>
                </div>
                <div className="tiles-col">
                  <ul className="options-tiles">
                    { this.renderTypeAndPos(TYPES.NOTICE, SUBTYPE.MODAL) }
                    { this.renderTypeAndPos(TYPES.NOTICE, SUBTYPE.TOP) }
                  </ul>
                </div>
                <div className="tiles-col">
                  <ul className="options-tiles">
                    { this.renderTypeAndPos(TYPES.NOTICE, SUBTYPE.BOTTOM) }
                    { this.renderTypeAndPos(TYPES.REDIRECT, TYPES.REDIRECT) }
                  </ul>
                </div>
                {
                  this.props.isSPUser ? (
                    <div className="tiles-col">
                      <ul className="options-tiles">
                        { this.renderTypeAndPos(TYPES.CUSTOM, SUBTYPE.HTML) }
                      </ul>
                    </div>
                  ) : null
                }
              </div>
              {
                type === TYPES.LOCK && subType === SUBTYPE.INLINE ? (
                  <div className="inline-lock">
                    <div className='destination-selector'>
                      <h3 ref="destinationSelector">Destination Selector</h3>
                      <h4>The message type you have chosen requires a Destination Selector - where to inject message onto page (CSS Selector). Please specify it below.</h4>
                        <AntdFormItem
                            fieldName="destination-selector"
                            initialValue={ destinationSelector }
                            onChange={ this.onMessageConfigChange }
                            form={ this.props.form }
                            editing={ this.props.editing }
                            placeholder="Destination Selector"
                        />
                    </div>
                    <div className="remove-page-elements-selector">
                      <h3>Remove Page Elements Selector</h3>
                      <h4>Elements on the page to remove (CSS Selector) when the message is shown</h4>
                      <AntdFormItem
                          fieldName="remove-page-elements-selector"
                          initialValue={removePageElementsSelector}
                          onChange={ this.onMessageConfigChange }
                          form={ this.props.form }
                          editing={ this.props.editing }
                          placeholder="Add Page Elements to Remove Here"
                          required={false}
                      />
                    </div>
                  </div>
                ) : null
              }
              {
                type === TYPES.REDIRECT && subType === TYPES.REDIRECT ? (
                  <div className="inline-lock">
                    <div className="redirect-destination-url">
                      <h3>Redirect URL</h3>
                      <h4>The message type you have chosen requires a Redirect URL. Please specify it below.</h4>
                      <AntdFormItem
                          onChange={ this.onMessageConfigChange }
                          fieldName="redirect-destination"
                          initialValue={ destination }
                          form={ this.props.form }
                          editing={ this.props.editing }
                          placeholder="Redirect URL"
                      />
                    </div>
                  </div>
                ) : null
              }
              {
                type === TYPES.CUSTOM && subType === SUBTYPE.HTML ? (
                  <div className="inline-lock">
                    <div className="custom-html">
                      <h3>Custom HTML</h3>
                      <AntdTextarea
                          onChange={ this.onMessageConfigChange }
                          fieldName="custom-html"
                          initialValue={ customHTML }
                          form={ this.props.form }
                          editing={ this.props.editing }
                          placeholder="Custom HTML"
                      />
                    </div>
                  </div>
                ) : null
              }
          </div>
          );
  }
}
