import React from 'react';

import { getUnresolvedIncidentCount } from '../../api/status';

const STATUS_PAGE_URL = "https://916bc570-ef98-0138-256a-2cde48001122.statuspage.io/";

const StatusPage = () => {
    return (
      <div style={{left: '10%', top: '80px', right: '10%'}} className='status-page'>
        <iframe style={{width: '100%', height: '10000px'}} frameBorder="0" scrolling="no" src={STATUS_PAGE_URL}/>
      </div>
    );
}

export default StatusPage;
