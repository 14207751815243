import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = { className: "" };

    render() {
        return (
            <tr className={ ("table-row " + this.props.className).trim() }>
              { this.props.children }
            </tr>
        )
    }
}
