import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getVendorListsAjax,
  getIABPurposesAjax,
  getIABStacksAjax,
  getIabSpecialFeaturesAndPurposesAjax,
  getScannedVendorsAjax,
  getScannedVendorsV2Ajax,
  getSitesEnabledForScanAjax,
  getVendorListAjax,
  getGlobalVendorsAjax,
  getGlobalVendorsHashAjax,
  createVendorListAjax,
  updateVendorListAjax,
  deleteVendorListAjax,
  cloneVendorListAjax,
  activateDeactivateVendorListAjax,
} from '../api/consent_v2/vendor_list';
import { getAllSitesAjax } from '../api/dialogue/sites';
import { createVendorListLog, editVendorListLog, deleteVendorListLog } from '../api/change_log';

export const cloneVendorList = actionCreator(
  cloneVendorListAjax,
  vendorListsPending,
  vendorListCreated,
  vendorListsError
);

export const getScannedVendors = actionCreator(
  getScannedVendorsAjax,
  scannedVendorsPending,
  scannedVendorsLoaded,
  vendorListsScanError,
);

export const getScannedVendorsV2 = actionCreator(
  getScannedVendorsV2Ajax,
  scannedVendorsPending,
  scannedVendorsAllLoaded,
  vendorListsScanError
);

export const getSitesEnabledForScan = actionCreator(
  getSitesEnabledForScanAjax,
  scannedVendorsPending,
  enabledSitesForScanLoaded,
  vendorListsError,
);

export const getVendorLists = (onlyCustom) => {
  return async (dispatch, getState) => {
    try {
      dispatch(vendorListsPending());
      let vendorLists = await getVendorListsAjax(onlyCustom);
      if (vendorLists === "fetched") {
        dispatch(vendorListsCached('vendorLists'));
        vendorLists = getState().usPrivacyReducerState.getIn(['vendorList', 'value']);
      } 
      let sitesNameMap = getState().siteState.getIn(['sitesNameMap', 'value']);
      let updatedVendorLists = vendorLists.map((vl) => {
        const siteIdsFiltered = vl.siteIds.filter(id => Boolean(sitesNameMap.get(String(id))));
        return vl.set('siteIds', siteIdsFiltered);
      });
      dispatch(vendorListsLoaded(updatedVendorLists));
    } catch (err) {
      dispatch(vendorListsError(err))
    }
  }
}

export const getIABPurposes = actionCreator(
  getIABPurposesAjax,
  purposesPending,
  purposesLoaded,
  purposesError,
  vendorListsCached.bind(null, 'purposes'),
);

export const getIABStacks = actionCreator(
  getIABStacksAjax,
  stacksPending,
  stacksLoaded,
  stacksError,
  vendorListsCached.bind(null, 'stacks'),
);

export const getIabSpecialFeaturesAndPurposes = actionCreator(
  getIabSpecialFeaturesAndPurposesAjax,
  iabSpecialFeaturesPending,
  iabSpecialFeaturesAndPurposesLoaded,
  iabSpecialFeaturesError,
  vendorListsCached.bind(null, 'iabSpecialFeatures'),
);



export function getVendorList(id, accountId) {
  return (dispatch, getState) => {

    dispatch(vendorListsPending());

    getVendorListAjax(id, accountId).then((vl) => {
      let vendorList = vl;
      const sites = getState().siteState.getIn(['sites', 'value']);
      //const sitesWithoutDeleted = vendorList.siteIds.filter(id => sites.map(s => s.id).includes(id));
      dispatch(vendorListLoaded(vendorList.set('siteIds', vendorList.siteIds)));
      /*
      getAllSitesAjax({ allSites: true }).then((s) => {
        let sites = s;
        if (sites === 'fetched') {
          sites = getState().siteState.getIn(['sites', 'value']).filter(s => vendorList.siteIds.includes(s.id));
        }

        const sitesWithoutDeleted = vendorList.siteIds.filter(id => sites.map(s => s.id).includes(id));
        dispatch(vendorListLoaded(vendorList.set('siteIds', sitesWithoutDeleted)));
      });
      */
    }).catch((resp) => dispatch(vendorListsError(resp)));
  };
}

export const getGlobalVendors = actionCreator(
  getGlobalVendorsAjax,
  globalVendorsPending,
  globalVendorsLoaded,
  vendorListsError,
  vendorListsCached.bind(null, 'globalVendors'),
);

export const getGlobalVendorsHash = actionCreator(
  getGlobalVendorsHashAjax,
  globalVendorsHashPending,
  globalVendorsHashLoaded,
  vendorListsError,
  vendorListsCached.bind(null, 'globalVendorsHash'),
);

export const createVendorList = actionCreator(
  createVendorListAjax,
  vendorListSavePending,
  vendorListCreated,
  vendorListsError,
  null,
  createVendorListLog,
);

export const updateVendorList = actionCreator(
  updateVendorListAjax,
  vendorListSavePending,
  vendorListUpdated,
  vendorListsError,
  null,
  editVendorListLog,
);

export const deleteVendorList = actionCreator(
  deleteVendorListAjax,
  vendorListsPending,
  vendorListDeleted,
  vendorListsError,
  null,
  deleteVendorListLog,
);

export const activateDeactivateVendorList = actionCreator(
  activateDeactivateVendorListAjax,
  vendorListsPending,
  vendorListActivation,
  vendorListsError,
  null,
  editVendorListLog
)

function vendorListsPending() {
  return {
    type: types.VENDOR_LISTS_PENDING,
  };
}

function globalVendorsPending() {
  return {
    type: types.GLOBAL_VENDORS_PENDING,
  };
}

function globalVendorsLoaded(vendors) {
  return {
    type: types.GLOBAL_VENDORS_LOADED,
    vendors,
  };
}

function globalVendorsHashPending() {
  return {
    type: types.GLOBAL_VENDORS_HASH_PENDING,
  };
}

function globalVendorsHashLoaded(vendors) {
  return {
    type: types.GLOBAL_VENDORS_HASH_LOADED,
    vendors,
  };
}

function scannedVendorsPending() {
  return {
    type: types.SCANNED_VENDORS_PENDING,
  };
}

function enabledSitesForScanLoaded(siteIds) {
  return {
    type: types.ENABLED_SITES_FOR_SCAN_LOADED,
    siteIds,
  };
}

function scannedVendorsLoaded(vendors) {
  return {
    type: types.SCANNED_VENDORS_LOADED,
    vendors,
  };
}

function unknownVendorsLoaded(vendors) {
  return {
    type: types.UNKNOWN_VENDORS_LOADED,
    vendors,
  };
}

export function clearScannedVendors() {
  return {
    type: types.CLEAR_SCANNED_VENDORS,
  };
}

function vendorListsLoaded(vendorLists) {
  return {
    type: types.VENDOR_LISTS_LOADED,
    vendorLists,
  };
}

function vendorListsError(error) {
  return {
    type: types.VENDOR_LISTS_ERROR,
    error,
  };
}

function vendorListsScanError(error) {
  return {
    type: types.VENDOR_LISTS_SCAN_ERROR,
    error,
  };
}

export function clearVendorListError() {
  return {
    type: types.CLEAR_VENDOR_LIST_ERROR,
  };
}

function vendorListCreated(vendorList) {
  return {
    type: types.VENDOR_LIST_CREATED,
    vendorList,
  };
}

function vendorListUpdated(vendorList) {
  return {
    type: types.VENDOR_LIST_UPDATED,
    vendorList,
  };
}

function vendorListActivation(vendorList) {
  return {
    type: types.VENDOR_LIST_ACTIVATION,
    vendorList,
  };
}


function vendorListDeleted(vendorList) {
  return {
    type: types.VENDOR_LIST_DELETED,
    id: vendorList.id,
  };
}

function vendorListSavePending() {
  return {
    type: types.VENDOR_LIST_SAVE_PENDING,
  };
}

function vendorListLoaded(vendorList) {
  return {
    type: types.VENDOR_LIST_LOADED,
    vendorList,
  };
}

function vendorListsCached(property) {
    return {
        type: types.VENDOR_LISTS_CACHED,
        property,
    }
}

function purposesPending() {
  return {
    type: types.PURPOSES_PENDING,
  };
}

function purposesLoaded(purposes) {
  return {
    type: types.PURPOSES_LOADED,
    purposes,
  };
}

function purposesError(errorMessage) {
  return {
    type: types.PURPOSES_ERROR,
    errorMessage,
  };
}

function stacksPending() {
  return {
    type: types.STACKS_PENDING,
  };
}

function stacksLoaded(stacks) {
  return {
    type: types.STACKS_LOADED,
    stacks,
  };
}

function stacksError(errorMessage) {
  return {
    type: types.STACKS_ERROR,
    errorMessage,
  };
}

function iabSpecialFeaturesPending() {
  return {
    type: types.IAB_SPECIAL_FEATURES_PENDING,
  };
}

function iabSpecialFeaturesLoaded(features) {
  return {
    type: types.IAB_SPECIAL_FEATURES_LOADED,
    features,
  };
}

function iabSpecialPurposesLoaded(purposes) {
  return {
    type: types.IAB_SPECIAL_PURPOSES_LOADED,
    purposes,
  };
}

function iabFeaturesLoaded(features) {
  return {
    type: types.IAB_FEATURES_LOADED,
    features,
  };
}

export function scannedVendorsAllLoaded(vendors) {
  return (dispatch) => {
          dispatch( scannedVendorsLoaded(vendors.scannedVendors))
          dispatch( unknownVendorsLoaded(vendors.vendorWithProblems))
  }
}

export function iabSpecialFeaturesAndPurposesLoaded(FeaturesAndPurposes) {
    return (dispatch) => {
            dispatch( iabSpecialFeaturesLoaded(FeaturesAndPurposes.specialFeatures))
            dispatch( iabSpecialPurposesLoaded(FeaturesAndPurposes.specialPurposes))
            dispatch( iabFeaturesLoaded(FeaturesAndPurposes.features))
    }
}

function iabSpecialFeaturesError(errorMessage) {
  return {
    type: types.IAB_SPECIAL_FEATURES_ERROR,
    errorMessage,
  };
}