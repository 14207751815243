import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Select, Button } from 'antd';
import { Map, List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import MessageEditChoiceSelectItem from './MessageEditChoiceSelectItem.jsx';
import { Site, SiteGroup } from '../../../../records/site_records';
import { SitePrivacyManager } from '../../../../records/privacy_manager_records';
import { ListOption, ChoiceConfigRecord } from '../../../../records/choice_config_records.js';
import { 
  CHOICE_GROUPS, 
  CHOICE_TYPES, 
  CHOICE_NAMES_READABLE, 
  NO_PRIVACY_MANAGER_ERROR_SITE_GROUP, 
  NO_PRIVACY_MANAGER_ERROR 
} from '../../../../constants.js';
import CustomButton from '../../../common/CustomButton.js.jsx';
import { getParameterByName } from '../../../utils';
import { MessageVariables, SiteVariables, IdNameValue } from '../../../../records/message_records';

const WELECT_CLIENTS = Map({ burda: 217, 'gruner&jahr': 212, 'SPON': 331, 'gutefrage': 270 });

export default class MessageEditChoiceSelect extends React.Component {
  static propTypes = {
    altpay: PropTypes.bool.isRequired,
    choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
    editing: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    updateChoiceConfig: PropTypes.func.isRequired,
    isSPUser: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    consent: PropTypes.bool.isRequired,
    samba: PropTypes.bool.isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    siteGroup: PropTypes.instanceOf(SiteGroup),
    getSitePrivacyManagerList: PropTypes.func.isRequired,
    getSiteGroupPrivacyManagerList: PropTypes.func.isRequired,
    sitePrivacyManagerList: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(SitePrivacyManager)).isRequired,
    variables: PropTypes.instanceOf(MessageVariables).isRequired,
    updateParentState: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleAddAnotherChoice = this.handleAddAnotherChoice.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.navUp = this.navUp.bind(this);
    this.navDown = this.navDown.bind(this);
    this.state = {
      pmModalOpen: false,
      variablesEditing: this.props.variables || new MessageVariables({}),
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.variables) {
      this.setState({ variablesEditing: newProps.variables });
    }
  }

  componentDidMount() {
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      if (this.props.consent) {
        this.props.getSiteGroupPrivacyManagerList(this.props.accountId, this.props.siteGroup.siteIds, siteGroupId);
        //fetch privacy managers for each site
        this.props.siteGroup.siteIds.forEach(id => {
          this.props.getSitePrivacyManagerList(this.props.accountId, id);
        });
      }
    }
  }

  handleAddAnotherChoice() {
    const oldChoiceConfig = this.props.choiceConfig;
    const newOptionList = oldChoiceConfig.option_list.push(new ListOption());
    const choiceConfig = oldChoiceConfig.set('option_list', newOptionList);
    this.props.updateChoiceConfig(choiceConfig);
  }

  handleOrderChange(listIndex, indexToBeReplaced) {
    let optionList = this.props.choiceConfig.option_list;
    const originalCC = optionList.get(listIndex);
    const CCtoBeReplaced = optionList.get(indexToBeReplaced);

    optionList = optionList.set((indexToBeReplaced), originalCC);
    optionList = optionList.set(listIndex, CCtoBeReplaced);

    const choiceConfig = this.props.choiceConfig.set('option_list', optionList);
    this.props.updateChoiceConfig(choiceConfig);
  }

  navUp(listIndex) {
    const indexToBeReplaced = listIndex - 1;
    this.handleOrderChange(listIndex, indexToBeReplaced);
  }

  navDown(listIndex) {
    const indexToBeReplaced = listIndex + 1;
    this.handleOrderChange(listIndex, indexToBeReplaced);
  }

  togglePmModal = () => {
    this.setState({
      pmModalOpen: !this.state.pmModalOpen,
    });
  }

  selectPm = (siteId, pmId) => {
    if (pmId === NO_PRIVACY_MANAGER_ERROR || pmId === NO_PRIVACY_MANAGER_ERROR_SITE_GROUP) return;
    let variables = this.state.variablesEditing;

    variables = variables || new MessageVariables({});
    const sitePmIdVar = new IdNameValue({ name: '$$privacyManagerIdHiddenVarriable$$', value: pmId });
    const selectedSiteVariables = variables.get('sitesVariables').find(sv => sv.siteId === siteId);
    let sitesVariables;
    if (selectedSiteVariables) {
      sitesVariables = variables.get('sitesVariables').map(sv => {
        if (sv.siteId === siteId) {
          let siteVariables = sv.variables.filterNot(v => v.name === '$$privacyManagerIdHiddenVarriable$$');
          siteVariables = siteVariables.push(sitePmIdVar);
          return sv.set('variables', siteVariables);
        } else {
          return sv;
        }
      });
    } else {
      sitesVariables = variables
        .get('sitesVariables')
        .push(new SiteVariables({ siteId, variables: List([sitePmIdVar])}));
    }
    this.setState({ variablesEditing: variables.set('sitesVariables', sitesVariables) });
  }

  getSiteGroupPrivacyManagerId = () => {
    let siteGroupPmId = this.props.choiceConfig
      .get('option_list').find(choice => choice.choice_option_type === 12)
      .getIn(['option_data', 'privacy_manager_iframe_url'])
      .split('privacy_manager_id=')[1];
    if (!siteGroupPmId || siteGroupPmId === '$$privacyManagerIdHiddenVarriable$$') {
      const siteGroupPmIdVariable = this.props.variables
        && this.props.variables.defaultVariables
        .find(v => v.get('name') === '$$privacyManagerIdHiddenVarriable$$');
      if (siteGroupPmIdVariable) {
        siteGroupPmId = siteGroupPmIdVariable.get('value');
      }
    }

    return siteGroupPmId;
  }

  handleSave = () => {
    this.props.updateParentState({ variables: this.state.variablesEditing });
    this.togglePmModal();
  }

  handleCancel = () => {
    this.setState({
      pmModalOpen: false,
      variablesEditing: this.props.variables,
    });
  }

  render() {
    const selectOptions = (
      [
        {value: CHOICE_TYPES.whitelist, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.whitelist], group: CHOICE_GROUPS.choice},
        {value: CHOICE_TYPES.recovery, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.recovery], group: CHOICE_GROUPS.choice},
        {value: CHOICE_TYPES.altAdVideo, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.altAdVideo], group: CHOICE_GROUPS.choice},
        {value: CHOICE_TYPES.pubCustom, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.pubCustom], group: CHOICE_GROUPS.choice},
        {value: CHOICE_TYPES.redirect, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.redirect], group: CHOICE_GROUPS.choice},
        {value: CHOICE_TYPES.continueWithAdblocker, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.continueWithAdblocker], group: CHOICE_GROUPS.choice},
      ]
    );

    if (this.props.consent) {
      selectOptions.unshift({value: CHOICE_TYPES.acceptAll, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.acceptAll], group: CHOICE_GROUPS.consent});
      selectOptions.unshift({value: CHOICE_TYPES.rejectAll, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.rejectAll], group: CHOICE_GROUPS.consent});
      selectOptions.unshift({value: CHOICE_TYPES.privacyManager, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.privacyManager], group: CHOICE_GROUPS.consent});
    }

    if (this.props.altpay) {
      selectOptions.push({value: CHOICE_TYPES.altPay, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.altPay], group: CHOICE_GROUPS.choice});
    }

    if (this.props.isSPUser || this.props.accountId === 52 || this.props.accountId === 352) {
      selectOptions.push({value: CHOICE_TYPES.execJS, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.execJS], group: CHOICE_GROUPS.choice});
    }

    if (this.props.isSPUser || this.props.samba) {
      selectOptions.push({value: CHOICE_TYPES.samba, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.samba], group: CHOICE_GROUPS.choice});
    }

    if (this.props.isSPUser || WELECT_CLIENTS.includes(this.props.accountId)) {
      selectOptions.push({value: CHOICE_TYPES.welect, label: CHOICE_NAMES_READABLE[CHOICE_TYPES.welect], group: CHOICE_GROUPS.choice});
    }

    const optionListLength = this.props.choiceConfig.option_list.size;
    const selectItemsList = this.props.choiceConfig.option_list.map((selectItem, i) => {
      return (
        <MessageEditChoiceSelectItem
          key={ i }
          listIndex={ i }
          selectOptions={ selectOptions }
          choiceOptionType={ selectItem.choice_option_type }
          editing={ this.props.editing }
          choiceConfig={ this.props.choiceConfig }
          updateChoiceConfig={ this.props.updateChoiceConfig }
          form={ this.props.form }
          navUp={ i === 0 ? null : this.navUp }
          navDown={ i + 1 === optionListLength ? null : this.navDown }
          variables={ this.props.variables }
          updateParentState={ this.props.updateParentState }
          siteGroup={ this.props.siteGroup }
          accountId={ this.props.accountId }
        />
      );
    }, this);
    const addButton = (
      this.props.editing ? (
        <Button
          onClick={ this.handleAddAnotherChoice }
          type="primary"
        >
          + Add a choice
        </Button>
      ) : null
    );

    const siteGroupId = getParameterByName('site_group_id', window.location);
    let managePmButton, pmModal;
    if (siteGroupId) {
      if (this.props.choiceConfig.option_list.some(option => option.choice_option_type === 12)) {

        const siteGroupPrivacyManagerId = this.getSiteGroupPrivacyManagerId();
        const siteGroupPm = this.props.sitePrivacyManagerList.find(pm => pm.id === siteGroupPrivacyManagerId);
        managePmButton = (
          <CustomButton
            className='manage-pm-button'
            size={ CustomButton.sizes.MEDIUM }
            onClick={ this.togglePmModal }
            disabled={ !siteGroupPm }
          >
            Privacy Managers Overrides
          </CustomButton>
       );

      pmModal = (
        <Modal
          visible={ this.state.pmModalOpen }
          onOk={ this.handleSave }
          onCancel={ this.handleCancel }
          okText={ 'Apply Changes' }
          destroyOnClose
          className='shared-modal privacy-manager-override'
          closable={ false }
          maskClosable={ false }
        >
          <div>
            <label className='title'><p>Manage Privacy Managers</p></label>
          </div>

          <div>
            { this.props.siteGroup.siteIds.map(id => {
              const siteVariables = this.state.variablesEditing
                .sitesVariables
                .find(obj => obj.get('siteId') === id);

              const sitePmVariable = siteVariables && siteVariables
                .variables
                .find(v => v.get('name') === '$$privacyManagerIdHiddenVarriable$$');

              const sitePms = this.props.sitePrivacyManagerList.filter(pm => pm.siteId === id);

              let selectValue;
              if (sitePmVariable) {
                selectValue = sitePmVariable.get('value');
              } else if (siteGroupPm) {
                selectValue = siteGroupPm.id;
              } else if (sitePms.size) {
                selectValue = sitePms.first().id
              } else {
                selectValue = NO_PRIVACY_MANAGER_ERROR;
              }

              return (
                <div>
                  <div>{ this.props.sites.find(s => s.id === id).domain }</div>
                  <Select
                    onChange={ (selected) => this.selectPm(id, selected) }
                    value={ selectValue }
                  >
                    { sitePms.map(pm => (
                      <Select.Option key={ pm.id }>
                        { pm.name }
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )
            })}
          </div>
        </Modal>
      )
    }}

    return (
      <div className='choice-options-list'>
        <div className="select-list">
          { selectItemsList }
        </div>
        { addButton }
        { managePmButton }
        { pmModal }
      </div>
    );
  }
}
