import PropTypes from 'prop-types';
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Checkbox } from 'antd';
import classNames from 'classnames';

const { TextArea } = Input;
const FormItem = Form.Item;

export default class extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        fieldLabel: PropTypes.string,
        fieldName: PropTypes.string.isRequired,
        form: PropTypes.object.isRequired,
        initialValue: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string,
            PropTypes.number,
        ]),
        isNumeric: PropTypes.bool,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        type: PropTypes.oneOf(['text', 'textarea', 'checkbox']),
        editing: PropTypes.bool.isRequired,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onPressEnter: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number,
    };

    static defaultProps = {
        className: '',
        fieldLabel: '',
        initialValue: '',
        isNumeric: false,
        placeholder: '',
        required: true,
        type: 'text',
        min: null,
        max: null,
    };

    checkIsInt = (rule, value, callback) => {
        if (!isNaN(value) && parseFloat(value) === parseInt(value)) {
          callback();
        } else {
          callback('Must be integer');
        }
    };

    checkMin = (rule, value, callback) => {
        if (parseInt(value) >= this.props.min) {
          callback();
        } else {
          callback('Cannot be less than ' + this.props.min);
        }
    };

    checkMax = (rule, value, callback) => {
        if (parseInt(value) <= this.props.max) {
          callback();
        } else {
          callback('Cannot be greater than ' + this.props.max);
        }
    };

    render() {
        const className = classNames(this.props.className, 'antd-form-item');
        const { getFieldDecorator } = this.props.form;
        const label = this.props.fieldLabel ? <label>{this.props.fieldLabel}</label> : '';
        const inputProps = {
          onChange: this.props.onChange,
          onBlur: this.props.onBlur,
          onPressEnter: this.props.onPressEnter,
          placeholder: this.props.placeholder,
        };

        if (!this.props.editing) {
          inputProps.disabled = true;
        }

        const valuePropName = this.props.type === 'checkbox' ? 'checked' : 'value';

        const rules = [];

        if (this.props.required) {
            rules.push({
                required: true, message: 'Cannot be blank',
            });
        }

        if (this.props.isNumeric) {
            rules.push({
                validator: this.checkIsInt,
            });
        }

        if (this.props.min != null) {
            rules.push({
                validator: this.checkMin,
            });
        }

        if (this.props.max != null) {
            rules.push({
                validator: this.checkMax,
            });
        }

        return (
            <FormItem className={className}>
                {label}
                {getFieldDecorator(this.props.fieldName, {
                    initialValue: this.props.initialValue,
                    rules,
                    validateTrigger: ['onChange', 'onBlur', 'onFocus'],
                    valuePropName,
                })({
                  'text': <Input {...inputProps} />,
                  'textarea': <TextArea {...inputProps} />,
                  'checkbox': <Checkbox {...inputProps} />,
                }[this.props.type])}
            </FormItem>
        );
    }
}
