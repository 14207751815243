import { useMemo } from 'react';

function getSearchParams(searchString) {
  const searchParams = new URLSearchParams(searchString);
  // eslint-disable-next-line prefer-const
  let searchObject = {};

  searchParams.forEach((value, key) => {
    searchObject[key] = value;
  });

  return searchObject;
}

export default function useSearchParams() {
  const searchParams = useMemo(() => {
    return getSearchParams(location.search);
  }, [location.search]);

  return searchParams;
}
