import PropTypes from 'prop-types';
import React from 'react';
import ColorPickerInput from '../../../common/ColorPickerInput.js.jsx';

var rgba2hex = function(rgb) {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
        ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
};

var rgba2opacity = function(rgb) {
    var opacity = rgb.split(",");
    return (opacity && opacity.length === 4) ? opacity[3].replace(")","") : '';
};

var hexToRgb = function(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
};

export default class extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onUpdate: PropTypes.func.isRequired,
        editing: PropTypes.bool.isRequired,
    };

    state = {
        color: rgba2hex(this.props.value),
        opacity: rgba2opacity(this.props.value) * 100
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            color: rgba2hex(nextProps.value),
            opacity: Math.round(rgba2opacity(nextProps.value) * 100)
        });
    }

    handleColorChange = (event) => {
        this.setState({ color: event }, this.computeValue);
    };

    handleOpacityChange = (event) => {
        if (isNaN(parseInt(event.target.value))) {
            this.setState({ opacity: '' });
            return;
        }
        this.setState({ opacity: event.target.value }, this.computeValue);
    };

    computeValue = () => {
        var hex = hexToRgb(this.state.color);
        var opacity = parseInt(this.state.opacity) / 100;
        var computedValue = 'rgba(' + hex[0] + ', ' + hex[1] + ', ' + hex[2] + ', ' + opacity + ')';

        this.props.onUpdate(computedValue);
    };

    render() {
        return (
            <div>
              <h3>Background Color</h3>
              <div className='style-color-font-pickers'>
                <div className="options-styles-property">
                  <ColorPickerInput
                    value={ this.state.color }
                    onUpdate={ this.handleColorChange }
                    editing={ this.props.editing }
                    />
                </div>

                <div className="options-styles-property options-styles-backdrop-opacity">
                  <div className="options-copy-font-size-and-opacity opacity">
                    <input type="text" value={ this.state.opacity } onChange={ this.handleOpacityChange } disabled={ !this.props.editing }/>
                    <span className='percent'>%</span>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}
