import { List } from "immutable";
export function errExcep(n, d) {
  return d != 0 ? Number((n / d) * 100) : 0;
}
export function absDataFormater(number) {
  const convertedNum = convertToMillion(number);
  return convertedNum >= 1 ? convertedNum.toFixed(2) + 'MM' : number.toLocaleString();
}
export function convertToMillion(number) {
  return number/1000000 ;
}
export function percentDataFormater(number) {
  return number.toFixed(2) + "%";
}
export function getMsgPercentageTable(tableData) {
  return tableData.map((c) => {
    let n = c;
    n = c
      .set("consentall", errExcep(c.get("consentall"), c.get("totalmsgs")))
      .set("consentsome", errExcep(c.get("consentsome"), c.get("totalmsgs")))
      .set("noconsent", errExcep(c.get("noconsent"), c.get("totalmsgs")))
      .set("rejectall", errExcep(c.get("rejectall"), c.get("totalmsgs")));
    return n;
  });
}
export function showState() {
  return window.location.pathname != "/consent_performance";
}
export function createCSVExportData(headers, exportData) {
  return exportData
    .map((item) =>
      headers.map((ph) => item[ph.get("key")] || item.get(ph.get("key")) || "")
    )
    .unshift(headers.map((ph) => ph.get("label")));
}
export function getPropertyNameFromSites(sites, siteId) {
  return sites.getIn([
    sites.findIndex((item) => item.get("id") === Number(siteId)),
    "domain",
  ]);
}
export function formatArray(list) {
  return list
    .toString()
    .split(",")
    .join(",\n");
}
