import React, { useState } from "react";
import { List } from "immutable";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { Select, Input } from "../../styleguide";
import { SearchOutlined } from "@ant-design/icons";

const DropdownSearch = (props) => {
  const {
    options,
    selectClassName,
    setFieldValue,
    dropdownClassName,
    searchInputClassName,
    selectPlaceholder,
    searchInputPlaceholder,
    dropDownMenuListHeight
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(options);
  const handleOnInputChange = (e) => {
    let value = e.target.value.toLowerCase();
    const result = options.filter(
      (option) => option.name.toLowerCase().indexOf(value) === 0
    );
    setFilteredData(result);
    setSearchValue(value);
  };
  return (
    <React.Fragment>
      <Select
        onChange={(value) => {
          setFieldValue({ location: value });
        }}
        onDropdownVisibleChange={() => {
          setFilteredData(options);
          setSearchValue("");
        }}
        style={{ width: "100%" }}
        placeholder={selectPlaceholder}
        className={selectClassName}
        dropdownRender={(menu) => (
          <>
            <Input
              icon={<SearchOutlined />}
              iconRight={true}
              className={searchInputClassName}
              value={searchValue}
              placeholder={searchInputPlaceholder}
              onChange={handleOnInputChange}
            />
            {menu}
          </>
        )}
        dropdownClassName={dropdownClassName}
        getPopupContainer={trigger => trigger.parentNode}
        listHeight={dropDownMenuListHeight}
      >
        {filteredData.map((item) => (
          <Select.Option key={item.code}>{item.name}</Select.Option>
        ))}
      </Select>
    </React.Fragment>
  );
};

DropdownSearch.propTypes = {
  options: ImmutablePropTypes.listOf(
    PropTypes.shape({ code: PropTypes.string, name: PropTypes.string })
  ),
  setFieldValue: PropTypes.func.isRequired,
  selectClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  searchInputClassName: PropTypes.string,
  searchInputPlaceholder: PropTypes.string,
  selectPlaceholder: PropTypes.string,
};

DropdownSearch.defaultProps = {
  options: List([{ code: "", name: "No Locations" }]),
  selectClassName: "",
  dropdownClassName: "",
  searchInputClassName: "",
  searchInputPlaceholder: "",
  selectPlaceholder: "",
};
export default DropdownSearch;
