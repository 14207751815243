import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { getParameterByName } from '../../utils';
import { getSiteGroup, getAllSites } from '../../../actions/site_actions';
import { SiteGroup, Site, SiteWithErrors } from '../../../records/site_records';
import SitesRedirect from './SitesRedirect';

class SiteGroupSitesRedirect extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    getSiteGroup: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    inCampaignTable: PropTypes.bool,
    pageKind: PropTypes.string.isRequired,
  }

  static defaultProps = {
    inCampaignTable: false,
  }

  componentDidMount() {
    this.props.getAllSites();
    this.props.getSiteGroup(getParameterByName('site_group_id', window.location));
  }

  render() {
    if (!this.props.siteGroup || !this.props.sites || !this.props.sites.size) return null;
    return (
      <SitesRedirect
        sites={ this.props.siteGroup.siteIds.map(id => this.props.sites.find(s => s.id === id)) }
        inCampaignTable={ this.props.inCampaignTable }
        pageKind={ this.props.pageKind }
        siteGroup= {this.props.siteGroup}
      />
    );
  }
}

const mapStateToProps = function (store) {
  return {
    siteGroup: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('site_group_id', window.location)),
    sites: store.siteState.getIn(['sites', 'value']),
  };
};

export default connect(
  mapStateToProps, {
    getSiteGroup,
    getAllSites,
  }
)(SiteGroupSitesRedirect);
