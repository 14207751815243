import React, { useState } from 'react';
import { Select } from 'antd';
import countriesList from './countriesList';
import usStatesList from './usStatesList';

const CommonDropdowns = ({
  settingsKey,
  updateSetting,
  allSettings: {
    isBooleanOption,
    commonDropdownSelection,
  },
}) => {
  const [choice, selectChoice] = useState(commonDropdownSelection || 'default');

  const updateChoice = (value) => {
    if (value === 'countries') {
      updateSetting('inputs', countriesList.map(c => ({ label: c === null ? '-Select Country-' : c, value: c })));
      updateSetting('commonDropdownSelection', value);
    } else if (value === 'states') {
      updateSetting('inputs', usStatesList.map(s => ({ label: s === null ? '-Select State-' : s, value: s })));
      updateSetting('commonDropdownSelection', value);
    } else {
      updateSetting('inputs', [
        { label: 'Option 1', value: 'Value 1' },
        { label: 'Option 2', value: 'Value 2' },
        { label: 'Option 3', value: 'Value 3' },
      ]);
    };

    selectChoice(value);
  };
  return (
    <div className="select-common-dropdown">
      <h4>Common Dropdown</h4>
      <Select
        value={choice}
        onChange={v => updateChoice(v)}
        disabled={isBooleanOption}
      >
        <Select.Option value="default">Default</Select.Option>
        <Select.Option value="countries">Countries</Select.Option>
        <Select.Option value="states">States</Select.Option>
      </Select>
    </div>
  )
};

export default CommonDropdowns;