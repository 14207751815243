import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, OrderedSet, fromJS, List } from 'immutable';
import { Select, Modal, Button } from 'antd';
import CustomButton from '../../common/CustomButton.js.jsx';
import { Partitionset } from '../../../records/partitionset_records';
import { findMessageIdsFromScenario } from '../../utils.js';
import { categoryMap, subcategoryMap } from '../../../constants'
import { browserHistory } from 'react-router';

const Option = Select.Option;

let privacyUrl = ""

class CampaignAdd extends React.Component {
  static propTypes = {
    partitions: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Partitionset)).isRequired,
    hideActivateMenu: PropTypes.func.isRequired,
    handleSaveAndActivate: PropTypes.func.isRequired,
    activateError: PropTypes.string
  };

  static defaultProps = {
    activeError: null
  };

  state = {
    campaignName: null,
    partitionsetId: -1,
    partition: '',
    pending: false,
    env: "stage",
    showPrivacyCheckModal: false,
    firstLayerMessages: List(),
    pmMessages: List(),
    pmOttMessages: List(),
  };

  handleNameChange = (event) => {
    this.setState({ campaignName: event.target.value });
  };

  setSelectedPartition = (partitionsetId) => {
    this.handlePrivacyModalVisibility(true, partitionsetId)
  };

  isActivationDisabled = () => {
    return (this.state.pending || !this.state.campaignName || this.state.campaignName.trim().length === 0 || this.state.partitionsetId === -1)
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!this.isActivationDisabled()) this.handleSaveAndActivate()
    }
  };

  setEnv = (env) => {
    this.setState({ env })
  };

  goToMessages = (url, message) => {
    browserHistory.push({
      pathname: `/dialogue/${url}`,
      query: { site_id: this.props.siteId },
      state: { message: message }
    })
  }

  getPrivacyUrl = (children) => {
    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (c.type !== 'Button') {
        this.getPrivacyUrl(c.children)
      } else {
        let choiceData = c.settings.choice_option.data
        if (choiceData.privacy_manager_iframe_url != undefined) {
          privacyUrl = choiceData.privacy_manager_iframe_url
        }
      }
    }
    return privacyUrl
  }

  getPrivacyIdMessage = (component) => {
    const message_json = JSON.parse(component.message_json)
    const children = message_json.children
    let privacyId = 0;
    let privacyUrl = this.getPrivacyUrl(children)
    if (privacyUrl != '') {
      privacyId = privacyUrl.split("=")[1]
    }
    return privacyId
  }

  handlePrivacyModalVisibility = (visible, partitionsetId) => {

    let selectedPartitionSet = this.props.partitions.find(partition => partition.id === parseInt(partitionsetId))
    let pmMessagesFiltered = List()
    let pmOttMessagesFiltered = List()

    let partitionsScenarioId = (selectedPartitionSet != null || selectedPartitionSet != undefined) ?
      selectedPartitionSet.partitions.map(p => p.scenario_id) : [];
    let scenarios = this.props.scenarios.filter((scenario) => partitionsScenarioId.includes(scenario.id));

    const messageIds = scenarios.map(s => {
      return findMessageIdsFromScenario(fromJS(JSON.parse(s.scenario_json)));
    }).flatten();

    let messages = this.props.messages;
    let firstLayerMessages = messages.filter(msg => msg.message_sub_category_id === subcategoryMap['notice-tcf-v2'] &&
      msg.message_category_id === categoryMap.gdpr && messageIds.includes(msg.id)
    )
    let pmMessages = messages.filter((msg) =>
      (msg.message_sub_category_id === subcategoryMap['privacy-manager'])
      && msg.message_category_id === categoryMap.gdpr && messageIds.includes(msg.id)
    )
    let pmOttMessages = messages.filter((msg) => (
      msg.message_sub_category_id === subcategoryMap['privacy-manager-ott'])
      && msg.message_category_id === categoryMap.gdpr && messageIds.includes(msg.id)
    )

    let filteredFirstLayerMessage = firstLayerMessages.filter((msg) => {
      let compliance_json = JSON.parse(fromJS(msg.compliance_json));
      let count = (compliance_json && compliance_json.length > 0) ? compliance_json.filter((item, index) => !item[index + 1]).length : 0
      let privacyIdMessage = this.getPrivacyIdMessage(msg)
      let pmMessage = messages.find((msg) => msg.id === parseInt(privacyIdMessage))
      if (pmMessage) {
        let compliance_json_pm = JSON.parse(fromJS(pmMessage.compliance_json));
        let pmCount = (compliance_json_pm && compliance_json_pm.length > 0) ? compliance_json_pm.filter((item, index) => !item[index + 1]).length : 0
        if (pmCount > 0) {
          pmMessagesFiltered = pmMessagesFiltered.push(pmMessage)
        }
      }
      if (count > 0) {
        return msg
      }
    })

    pmMessages.filter((msg) => {
      let compliance_json = JSON.parse(fromJS(msg.compliance_json));
      let count = (compliance_json && compliance_json.length > 0) ? compliance_json.filter((item, index) => !item[index + 1]).length : 0
      if (count > 0 && !pmMessagesFiltered.includes(msg)) {
        pmMessagesFiltered = pmMessagesFiltered.push(msg)
      }
    })

    pmOttMessages.filter((msg) => {
      let compliance_json = JSON.parse(fromJS(msg.compliance_json));
      let count = (compliance_json && compliance_json.length > 0) ? compliance_json.filter((item, index) => !item[index + 1]).length : 0
      if (count > 0 && !pmOttMessagesFiltered.includes(msg)) {
        pmOttMessagesFiltered = pmOttMessagesFiltered.push(msg)
      }
    })

    if (filteredFirstLayerMessage.length === 0 && pmMessagesFiltered.size === 0 && pmOttMessagesFiltered.size === 0) {
      let partitionSet = this.props.partitions.find((partition) => partition.id === parseInt(partitionsetId))
      let partitionName = (partitionSet != null || partitionSet != undefined) ? partitionSet.description : ''
      this.setState({ partitionsetId: parseInt(partitionsetId), partition: partitionName, showPrivacyCheckModal: false })
    } else {
      this.setState({
        firstLayerMessages: filteredFirstLayerMessage,
        pmMessages: pmMessagesFiltered,
        pmOttMessages: pmOttMessagesFiltered,
        showPrivacyCheckModal: visible,
        partitionsetId: ''
      })
    }
  }

  render() {

    var disableSaveAndActivate = this.isActivationDisabled();

    var envDropdown = (
      <Select
        dropdownClassName="campaign-add"
        defaultValue="stage"
        onChange={this.setEnv}
        disabled={this.props.stageOnly}
      >
        <Option value="stage">stage</Option>
        <Option value="public">public</Option>
      </Select>
    );

    var partitionDropdown = (
      <Select value={this.state.partition} dropdownClassName="campaign-add" defaultValue='select...' onChange={this.setSelectedPartition}>
        { this.props.partitions.map(partition => {
          return (
            <Option key={partition.id} value={partition.id.toString()}>{partition.description}</Option>
          )
        })}
      </Select>
    )

    var activateError;
    if (this.props.activateError) {
      activateError = (
        <div className='error-container'><p className="error activate-campaign">{this.props.activateError}</p></div>
      )
    }

    let privacyModal = (
      <Modal
        title={<p style={{ fontSize: '14px' }}>Please perform 'Privacy Check' before launching the campaign that are related to
        IAB TcF Policies
          </p>}
        visible={this.state.showPrivacyCheckModal}
        onCancel={() => this.handlePrivacyModalVisibility(false)}
        footer={null}
        className="campaign-privacy"
      >
        <p className="campaign-title">All Campaigns with TCFv2 messages must have privacy check review completed</p>
        <div style={{ marginTop: '10px' }}>
          <div className="subheading-container">
            <div className="campaign-subheading">Message Type</div>
            <div className="campaign-subheading">Privacy Check Required</div>
            <div className="blank-div"></div>
          </div>

          {this.state.firstLayerMessages.map((msg) => {
            let compliance_json = JSON.parse(fromJS(msg.compliance_json));
            let count = (compliance_json.length > 0) ? compliance_json.filter((item, index) => !item[index + 1]).length : 0

            return (
              <div className="content-container">
                <div className="content-title">{msg.description}</div>
                <div className="content-description">{count}</div>
                <div className="blank-div">
                  <Button
                    type="primary"
                    onClick={() => this.goToMessages('messages/tcfv2', msg)}
                  >
                    View Status
                  </Button>
                </div>
              </div>
            )
          })}

          {this.state.pmMessages.map((msg) => {
            let compliance_json = JSON.parse(fromJS(msg.compliance_json));
            let count = (compliance_json.length > 0) ? compliance_json.filter((item, index) => !item[index + 1]).length : 0
            return (
              <div className="content-container">
                <div className="content-title">Privacy Manager</div>
                <div className="content-description">{count}</div>
                <div className="blank-div">
                  <Button onClick={() => this.goToMessages('privacy-manager/tcfv2', msg)} type="primary">
                    View Status
                  </Button>
                </div>
              </div>
            )
          })}

          {this.state.pmOttMessages.map((msg) => {
            let compliance_json = JSON.parse(fromJS(msg.compliance_json));
            let count = (compliance_json.length > 0) ? compliance_json.filter((item, index) => !item[index + 1]).length : 0
            return (
              <div className="content-container">
                <div className="content-title">Privacy Manager OTT</div>
                <div className="content-description">{count}</div>
                <div className="blank-div">
                  <Button onClick={() => this.goToMessages('privacy-manager/ott', msg)} type="primary">
                    View Status
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </Modal>
    )



    return (
      <div className='add-import-menu-container'>
        { activateError}
        <table className="table add-campaign">
          <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Partition Set</th>
              <th>Environment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="text" placeholder="Name your campaign..." onChange={this.handleNameChange} onKeyPress={this.handleKeyPress} />
              </td>
              <td>
                {partitionDropdown}
              </td>
              <td>
                {envDropdown}
              </td>
              <td className='create-cancel-button-container'>
                <CustomButton className="cancel-activate-campaign" type={CustomButton.types.PLAIN} onClick={this.props.hideActivateMenu} disabled={this.state.pending}>CANCEL</CustomButton>
                <CustomButton
                  className="activate-campaign"
                  disabled={disableSaveAndActivate}
                  onClick={this.props.handleSaveAndActivate.bind(null, this.state.env, this.state.campaignName, this.state.partitionsetId)}
                >
                  {(this.state.pending) ?
                    "ACTIVATING..." :
                    "PUBLISH"
                  }
                </CustomButton>
              </td>
            </tr>
          </tbody>
        </table>
        {privacyModal}
      </div>

    );
  }
}

export default CampaignAdd