import React from "react";
import { Modal, Table } from "../../../styleguide";
import moment from 'moment';
import { Map, List, fromJS } from "immutable";
import {
  ResponsiveContainer,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend
} from "recharts";
import {
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST,
  LINE_GRAPH_COLOR_LIST
} from "../../../constants";
import {
  getMsgPercentageTable,
  createCSVExportData,
  getPropertyNameFromSites
} from "../performanceNew/helper";
import CustomPopover from "../../common/CustomPopover";
import ExportPopoverContent from "../../ccpa/ccpa_reporting/ExportPopoverContent";
import FilterColumns from './FilterColumns';
import { trimString } from './helper';

export default class MessagePerformaceModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      msgValConvert: "number",
      omitCols: List(['criteriaId', 'messagename', 'siteid']),
      msgCols: this.msgPerformanceCols(),
    };
  }

  drawGraph = tableData => {
    let lineArr = [];
    tableData.map((d, j) => {
      lineArr.push(
        <Line
          key={"c-" + j}
          strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(
            d.get("criteriaId") - 1
          )}
          strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(
            d.get("criteriaId") - 1
          )}
          type="monotone"
          dataKey={d.get("messageid") + "-" + d.get("criteriaId")}
          stroke={LINE_GRAPH_COLOR_LIST.get(j) || "#" + ((Math.random() * 0xffffff) << 0).toString(16)}
        />
      );
    });
    return lineArr;
  };

  setMsgValConvert = value => {
    this.setState({ msgValConvert: value });
  };
  dataFormater = number => {
    return number + "%";
  };
  msgPerformanceCols = () => {
    return List([
      Map({
        title: "Criteria",
        dataIndex: "criteriaId",
        className: "left-align no-trim-td",
        render: (text, record) => typeof text == 'number' ? record.criterianame : text,
        fixed: 'left',
      }),
       Map({
        title: "Message ID/Name",
        titleText: "Message Name",
        dataIndex: 'messagename',
        sorter: (a, b) => a.messagename.localeCompare(b.messagename),
        className: 'left-align rpt-trim-text',
        fixed: 'left',
        render: (text, record) => <>{`${record.messageid}/${text}`}</>,
        fixed: 'left',
      }),
      Map({
        title: 'Property',
        dataIndex: 'siteid',
        render: (text) => (getPropertyNameFromSites(this.props.sites, text)),
        sorter: (a, b) =>
          getPropertyNameFromSites(this.props.sites, a).localeCompare(
            getPropertyNameFromSites(this.props.sites, b)
          ),
        className: 'left-align rpt-trim-text',
        fixed: 'left',
      }),
      Map({
        title: 'Total Users',
        dataIndex: 'totalusers',
        sorter: (a, b) => a.totalusers - b.totalusers,
        className: 'right-align',
        render: (text) => text.toLocaleString(),
      }),
      Map({
        title: 'Messaged Users',
        dataIndex: 'totalmsgs',
        sorter: (a, b) => a.totalmsgs - b.totalmsgs,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.totalmsgsper}</span> <br/>
                                   {text.toLocaleString()}
                                  </div>,
      }),
      Map({
        title: 'Accept All',
        dataIndex: 'consentall',
        sorter: (a, b) => a.consentall - b.consentall,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.consentallper}</span> <br/>
                                   {text.toLocaleString()}
                                  </div>,
      }),
      Map({
        title: 'Legitimate Interest Only',
        dataIndex: 'legitimateintobj',
        sorter: (a, b) => a.legitimateintobj - b.legitimateintobj,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.legitimateintobjper}</span> <br/>
                                   {text.toLocaleString()}
                                  </div>,
      }),
      Map({
        title: 'Reject All',
        dataIndex: 'rejectall',
        sorter: (a, b) => a.rejectall - b.rejectall,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.rejectallper}</span> <br/>
                                   {text.toLocaleString()}
                                  </div>,
      }),
      Map({
        title: 'Bounces',
        dataIndex: 'bounces',
        sorter: (a, b) => a.bounces - b.bounces,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.bouncesper}</span> <br/>
                                   {text.toLocaleString()}
                                  </div>,
      }),
      Map({
        title: 'All other choices',
        dataIndex: 'other',
        sorter: (a, b) => a.other - b.other,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.otherper}</span> <br/>
                                   {text.toLocaleString()}
                                  </div>,
      }),
    ]);

  }
  setMsgCols = (filteredCols) => {
    this.setState({msgCols: this.msgPerformanceCols().filter((cols) => filteredCols.includes(cols.get('dataIndex')))});
  }

  criteriaLegendLine = (index, color) => {
    return  <svg height="5" width="28">
                <line
                  x1="0"
                  y1="0"
                  x2="200"
                  y2="0"
                  style={{
                    stroke: color,
                    strokeWidth: "5",
                    strokeDasharray: LINE_GRAPH_STROKE_PATTERN_LIST.get(Number(index == 0 ? index : index-1)),
                  }}
                />
            </svg>
  }
  renderLegend = (props, msgData) => {
    const { payload } = props;
    return (
      <div className="custom-legend">
        {
          payload.map((entry, index) => {
            const messageId = entry.value.split('-')[0];
            const criteriaId = Number(entry.value.split('-')[1] - 1);
            const messageName = msgData.find((m) => m.get('messageid') == Number(messageId)).get('messagename');
            return (<span style={{color: entry.color}} key={`item-${index}`}>{this.criteriaLegendLine(criteriaId, entry.color)} {`${messageId}/${messageName} (${trimString(this.props.criteriaNames.get(criteriaId), 10)})`} </span>);
          })
        }
      </div>
    );
  }
  renderTooltip = (props, msgData) => {
    const { payload, label } = props;
    return (
      <div className="custom-msg-tooltip">
        {label}<br/>  
        {
          payload && payload.map((entry, index) => {
            const messageId = entry.dataKey.split('-')[0];
            const criteriaId = Number(entry.dataKey.split('-')[1] - 1);
            const messageName = msgData.find((m) => m.get('messageid') == Number(messageId)).get('messagename'); 
            return (<div key={`item-${index}`}>
                       <span style={{color: entry.color}}>{`${messageId}/${messageName} (${trimString(this.props.criteriaNames.get(criteriaId), 10)}): ${entry.value}%`}</span><br/>
                   </div>);
          })
        }
      </div>
    );
  };

  showTooltip = (msgData) => {
    return (
      <Tooltip
        content={(tooltipData) => this.renderTooltip(tooltipData, msgData)}
        filterNull={true}
        active={false}
        cursor={false}
      />
    );
  };
  render() {

    let tableData = List();
    this.props.selectedMsgs.map((item, i) => {
      item.get("msgs").map((m, j) => {
        tableData = tableData.push(
          Map({
            key: i + "-" + j,
            criteriaId: i + 1,
            criterianame: this.props.criteriaNames.get(i),
            messageid: m.get("messageid"),
            messagename: m.get('messagename'),
            partitionname: m.get("partitionname"),
            totalusers: m.get("totalusers"),
            totalmsgs: m.get("totalmsgs"),
            totalmsgsper: m.get("totalmsgsper"),
            consentall: m.get("consentall"),
            consentallper: m.get("consentallper"),
            consentsome: m.get("consentsome"),
            consentsomeper: m.get("consentsomeper"),
            noconsent: m.get("noconsent"),
            noconsentper: m.get("noconsentper"),
            rejectall: m.get("rejectall"),
            rejectallper: m.get("rejectallper"),
            legitimateintobj: m.get("legitimateintobj"),
            legitimateintobjper: m.get("legitimateintobjper"),
            bounces: m.get("bounces"),
            bouncesper: m.get("bouncesper"),
            other: m.get("other"),
            otherper: m.get("otherper"),
            siteid: m.get("siteid"),
            children: m.get("aggData")
          })
        );
      });
    });

    let graphData = List();
    tableData.map(t => {
      const criteriaId = t.get("criteriaId");
      const messageid = t.get("messageid").toString();
      let getCriteriaData = this.props.msgPerformance.get(criteriaId - 1);

      getCriteriaData.map(p => {
        if (p.get(messageid)) {
          const index = graphData.findIndex(
            r => r.get("date") == p.get("date")
          );
          if (index >= 0) {
            graphData = graphData.setIn(
              [index, [messageid + "-" + criteriaId]],
              p.get(messageid)
            );
          } else {
            graphData = graphData.push(
              Map({
                date: p.get("date"),
                fancyDate: p.get("fancyDate"),
                [messageid + "-" + criteriaId]: p.get(messageid)
              })
            );
          }
        }
      });
    });

    graphData = graphData.sort(
      (a, b) =>
        moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
    );
    let graphTitle = graphData.size
      ? moment(graphData.first().get("date")).format("MMMM YYYY")
      : "";
    const msgHeaders = this.msgPerformanceCols()
      .map((item) => Map({ label: item.get('titleText') || item.get('title'), key: item.get('dataIndex') }))
      .setIn([0, 'key'], 'criterianame')
      .insert(1, Map({ label: 'Messaged ID', key: 'messageid' }))
      .insert(6, Map({ label: "Messaged Users %", key: "totalmsgsper" }))
      .insert(8, Map({ label: "Accept All %", key: "consentallper" }))
      .insert(10, Map({ label: "Legitimate Interest Only %", key: "legitimateintobjper" }))
      .insert(12, Map({ label: "Reject All %", key: "rejectallper" }))
      .insert(14, Map({ label: "Bounces %", key: "bouncesper" }))
      .insert(16, Map({ label: "All other choices %", key: "otherper"}));

    const exportReport = <CustomPopover
                            tooltipContent={
                              <ExportPopoverContent
                              filename="message_performance"
                              exportData={createCSVExportData(msgHeaders, tableData)}
                              pdfTitle={"Message Performance"}
                              />
                            }
                            style="export-popover"
                            icon="download"
                            iconColor="#108ee9"
                            title={<span style={{fontSize: "11px", display: "flex", justifyContent: "center", fontWeight: 100}}>Export Type Selection</span>}
                          />;
    const title = <div className="msg-filtercols-wrapper"><FilterColumns columns={this.msgPerformanceCols()} omitCols={this.state.omitCols} setCols={this.setMsgCols}/> {exportReport}</div>;
    return (
      <Modal
        title="Messaged Users Performance Details"
        visible={this.props.recentChangesModal}
        onCancel={this.props.handleCancel}
        footer={null}
        className="compareMsgsModal"
      >
        <span className="modal-title">Accept All</span>
        <ResponsiveContainer height={395}>
          <LineChart
            width={730}
            height={250}
            data={graphData.toJS()}
            margin={{ top: 5, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="1 1" vertical={false}/>
            <XAxis dataKey="fancyDate" tickLine={false}>
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              axisLine={false}
              tickLine={false}
              type="number"
              domain={[0, "dataMax"]}
              tickFormatter={this.dataFormater}
            />
            {this.showTooltip(tableData)}
            <Legend content={(legData) => this.renderLegend(legData, tableData)} verticalAlign="top" height={36}/>
            {this.drawGraph(tableData)}
          </LineChart>
        </ResponsiveContainer>
        <Table
          title={() => title}
          columns={this.state.msgCols.toJS()}
          dataSource={tableData.toJS()}
          className="consnet-tableCSS"
          scroll={{ x: 'max-content' }}
          pagination={{
            total: tableData.size,
            position: ['bottomCenter'],
            showTotal: (total) => `Total: ${total}`,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            locale: { items_per_page: "  Records per page" }
          }}
          expandIconColumnIndex={0}
        />
      </Modal>
    );
  }
}
