import React from 'react';
import { Input as AntdInput, Typography } from 'antd';
import classNames from 'classnames';

const Input = React.forwardRef((antdProps, ref) => {
  const props = { ...antdProps };
  const classes = {
    'spsg-left': props.iconLeft,
    'spsg-right': props.iconRight,
    'spsg-error': props.error,
    'purple':props.purple
  };
  let className = classNames('spsg-input', props.className,classes)
  const icon = props.icon;
  
  delete props.iconLeft;
  delete props.iconRight;
  delete props.icon;

  return (
    <div className={className}>
      <AntdInput ref={ref} {...props} />
      { icon && <span className="spsg-icn">{ icon }</span> }
      { props.error && <Typography.Text type='danger'>{ props.error }</Typography.Text> }
    </div>
  );
});

Input.Search = AntdInput.Search;
Input.TextArea = AntdInput.TextArea;
Input.Password = AntdInput.Password
export default Input;
