import React, { useState } from 'react';
import { Input, Radio, Switch, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useAutoScroll } from '../../../../../../../../hooks';
import { COUNTRY_LANGUAGES } from '../../../../../../../../constants';

export default function SetInputOptions({
  settingKey: inputsKey,
  updateSetting,
  defaultLanguage,
  allSettings,
  allSettings: {
    inputs,
    isBooleanOption: initialIsBooleanOption,
  },
}) {
  const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;

  const [isBooleanOption, setIsBooleanOption] = useState(initialIsBooleanOption);
  const optionInputTableEl = useAutoScroll(inputs.length)

  const changeOptionType = () => {
    isBooleanOption
      ? updateSetting(inputsKey, [
        { label: 'Option 1', value: 'Value 1', isDefaultSelection: true, languages: {}, },
        { label: 'Option 2', value: 'Value 2', languages: {}, },
        { label: 'Option 3', value: 'Value 3', languages: {}, },
      ])
      : updateSetting(inputsKey, [
        { label: 'Yes', value: true, isDefaultSelection: true, languages: {}, },
        { label: 'No', value: false, languages: {}, }
      ]);
    updateSetting('isBooleanOption', !isBooleanOption);
    setIsBooleanOption(!isBooleanOption);
  };

  const addOption = () => {
    updateSetting(
      inputsKey,
      [
        ...inputs,
        {
          label: `Option ${inputs.length + 1}`,
          value: `Value ${inputs.length + 1}`,
          languages: {},
        }
      ]
    )
  };

  const updateOption = (index, field) => ({ target: { value } }) => {
    const updatedInput = { ...inputs[index] };
    if (field === 'label') {
      updatedInput.label = (defaultLanguage === selectedLanguage) ? value : updatedInput.label;
      updatedInput.languages = updatedInput.languages
        ? {
          ...updatedInput.languages,
          [selectedLanguage]: { label: value }
        }
        : { [selectedLanguage]: { label: value } };
    } else {
      updatedInput[field] = value;
    };

    updateSetting(inputsKey, [
      ...inputs.slice(0, index),
      updatedInput,
      ...inputs.slice(index + 1)
    ]);
  };

  const updateDefault = (index) => (checked) => {
    const updatedInputs = inputs.map((input, i) => ({
      ...input,
      isDefaultSelection: (i === index) && checked
    }));
    updateSetting(inputsKey, updatedInputs);
  }

  const removeOption = (index) => {
    if (inputs.length === 2) return;

    updateSetting(inputsKey, [
      ...inputs.slice(0, index),
      ...inputs.slice(index + 1)
    ]);
  };

  const makeLabel = (label, languages = {}) => {
    return (defaultLanguage === selectedLanguage)
      ? label
      : (languages[selectedLanguage] !== undefined)
        ? languages[selectedLanguage].label : '';
  }

  return (
    <div>
      <div className="set-option-type">
        <h4>Option Group Type</h4>
        <Radio.Group value={isBooleanOption} onChange={changeOptionType}>
          <Radio.Button value={true}>Yes / No</Radio.Button>
          <Radio.Button value={false}>Multi Option</Radio.Button>
        </Radio.Group>
      </div>
      <React.Fragment>
        <div className="four-options">
          <div className="text-language">
            <h4>Language </h4>
            <Select
              value={selectedLanguage}
              onChange={(language => updateSetting('selectedLanguage', language))}
            >
              {COUNTRY_LANGUAGES.map(({ code, language }) => <Select.Option key={code}>{language}</Select.Option>)}
            </Select>
          </div>
        </div>
        <div
          className="checkbox-input-options-table"
          style={isBooleanOption ? { height: 200 } : { minHeight: 210, maxHeight: 320 }}
          ref={optionInputTableEl}
        >
          {inputs.map(({
            label,
            value,
            languages,
            isDefaultSelection
          }, i) => {
            return (
              <div key={i} className="row-container">
                <div
                  className={`rm-btn ${inputs.length === 2 ? 'disabled' : ''}`}
                  onClick={() => removeOption(i)}
                >
                  {'\u2715' /* unicode for X symbol */}
                </div>
                <div className="inputs-container" >
                  <div className="label-value-input">
                    <Input
                      value={makeLabel(label, languages)}
                      placeholder="Label"
                      onChange={updateOption(i, 'label')} />
                  </div>
                  <div className="label-value-input">
                    <Input
                      value={value}
                      placeholder="Value"
                      disabled={isBooleanOption}
                      onChange={updateOption(i, 'value')}
                    />
                  </div>
                </div>
                <div className="switch-container">
                  <div className="switch-item">
                    <div className="large">Default</div>
                    <Switch
                      size="large"
                      checked={isDefaultSelection}
                      onChange={updateDefault(i)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {!isBooleanOption && (
          <div className="add-input" onClick={addOption}>
            <div>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div>
              Add Another Option
            </div>
          </div>
        )}
      </React.Fragment>
    </div>
  );
}