import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  Input,
  Button,
  Alert,
} from 'antd';
import { Map } from 'immutable';
import { getUserDetails } from '../../actions/account_actions';
import { validateEmail, getParameterByName } from '../utils';
import Logo from '../../assets/images/spt-logo.png';
import Form from 'antd/lib/form/Form';

const permissionsSvcBase = process.env.REACT_APP_PERMISSIONS_API_URL;

class SignIn extends React.Component {
  state = {
    email: '',
    password: '',
    passwordConfirmation: '',
    emailValidationError: '',
    codeValidationError: '',
    code: '',
    codeSent: false,
    forgotPassword: false,
    resetPassword: window.location.search && window.location.search.indexOf('token=') >= 0 ? true : false,
    passwordErrors: [],
    emailNotExist: '',
  };

  handleInputChange = (e, prop) => {
    let stateUpdate = new Map();
    stateUpdate = stateUpdate.set(prop, e.target.value);
    this.setState(stateUpdate.toJS());
  }

  validatePasswords = () => {
    const { password, passwordConfirmation } = this.state

    const errors = []
    if (password !== passwordConfirmation) {
      errors.push('Passwords do not match')
    }
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters')
    }
    if (password.length > 100) {
      errors.push('Password must no more than 100 characters')
    }
    if (!(/[a-z]/.test(password))) {
      errors.push('Password must contain at least 1 lower case character')
    }
    if (!(/[A-Z]/.test(password))) {
      errors.push('Password must contain at least 1 upper case character')
    }
    if (!(/\d/.test(password))) {
      errors.push('Password must contain at least 1 number')
    }
    if (/\s/g.test(password)) {
      errors.push('Password can not include spaces')
    }

    return errors
  }

  trySignIn = () => {
    if (this.state.codeSent) {
      fetch(permissionsSvcBase + '/authentication/authenticate/code', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: this.state.code }),
      }).then(r => {
        if (r.status === 200) {
          this.props.getUserDetails();

          let newLocation = '/'
          const { query: { redirect_to } } = browserHistory.getCurrentLocation();
          if (redirect_to) {
            newLocation = redirect_to
          }

          browserHistory.push(newLocation);
        } else if (r.status === 423) {
          this.setState({ 
            codeValidationError: ["Account has been locked after too many attempted logins, please contact support."] 
          })
        } else {
          this.setState({
            codeValidationError: 'Invalid Code',
          });
        }
      });
    } else {
      this.setState({ emailValidationError: '' })
      fetch(permissionsSvcBase + '/authentication/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({ username: this.state.email, password: this.state.password }),
      }).then(r => {
        if (r.status === 200) {
          this.setState({ codeSent: true });
        } else if (r.status === 423) {
          this.setState({ emailValidationError: ["Account has been locked after too many attempted logins, please contact support."] })
        } else {
          this.setState({ emailValidationError: 'Invalid Email or Password' });
        }
      });
    }
  }

  resetPassword = () => {
    if (this.state.resetPassword) {

      this.setState({ passwordErrors: [] })
      const errors = this.validatePasswords()
  
      if (errors.length) {
        return this.setState({ passwordErrors: errors })
      }

      const token = getParameterByName('token', window.location);

      fetch(permissionsSvcBase + `/authentication/reset-password?token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        // check if matches to confirmation
        body: JSON.stringify({ password: this.state.password }),
      }).then(r => {
        if (r.status === 200) {
          //redirect to login page 
          this.setState({
            resetPassword: false,
          });
        } else {
          //
        }
      });

    } else {
      this.setState({ emailValidationError: '', emailNotExist: '' })
      if (!validateEmail(this.state.email)) {
        return this.setState({ emailValidationError: "Email doesn't appear valid" })
      }

      fetch(permissionsSvcBase + '/authentication/reset-password-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: this.state.email }),
      }).then(r => {
        const { email } = this.state;
        if (r.status === 200) {
          debugger
          this.setState({
            resetPassword: false,
            resetLinkSent: `Reset link was sent to ${email}`,
          });
        } else if(r.status === 401){
          this.setState({
            resetLinkSent: '',
            emailNotExist: `No Account found for ${email}`,
          });
        } else {
          //
        }
      });
    }
  }

  render() {
    let alert;
    if (this.props.createdUserCredsError) {
      alert = (
        <Alert
          message={this.props.createdUserCredsError}
          type="error"
          onClose={() => this.setState({ emailValidationError: '' })}
        />
      );
    }

    let alert2;
    if (this.state.emailValidationError) {
      alert2 = (
        <Alert
          showIcon
          message={this.state.emailValidationError}
          type="error"
          onClose={() => this.setState({ emailValidationError: '' })}
        />
      );
    }

    let alert3;
    if (this.state.resetLinkSent) {
      alert3 = (
        <Alert
          showIcon
          message={this.state.resetLinkSent}
          type="success"
        />
      );
    }
    let alert4;
    if (this.state.codeValidationError) {
      alert4 = (
        <Alert
          showIcon
          message={this.state.codeValidationError}
          type="error"
        />
      );
    }
    let alert5;
    if (this.state.emailNotExist){
      alert5 = (
        <Alert
          showIcon
          message={this.state.emailNotExist}
          type="error"
          onClose={() => this.setState({ emailNotExist: '' })}
        />
      );
    }
    const hasAlert = alert2 || alert3 || alert4 || alert5;

    const text = 'You will receive an email with instructions on how to reset your password in a few minutes.'
    return (
      <div className='auth-container'>
        <div className='sign-in-user-container'>
          <div className="spl-logo"><img src={Logo} alt="" /></div>

          { hasAlert ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }} >
              <div style={{ flex: 1 }}>
                {alert2}
                {alert3}
                {alert4}
                {alert5}
              </div>
            </div>
          ) : null }
          {this.state.forgotPassword ? <p className="spl-info">Enter Email for Reset Password Link</p> : null}
          {
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: 600 }}>
                {this.state.passwordErrors.map((err, i) => (
                  <Alert
                    key={err}
                    style={{ marginBottom: 5 }}
                    showIcon
                    closable
                    message={err}
                    type="error"
                    onClose={() => {
                      this.setState({
                        passwordErrors: [...this.state.passwordErrors.slice(0, i), ...this.state.passwordErrors.slice(i + 1)]
                      })
                    }}
                  />
                ))}
              </div>
            </div>
          }
          <Form onFinish={this.state.forgotPassword || this.state.resetPassword ? this.resetPassword : this.trySignIn}>
            <div className="spl-inputs">
              {
                this.state.codeSent ?
                  <React.Fragment>
                    <p className="spl-info">{`The code was sent to ${this.state.email}. Please enter below`}</p>
                    <Input style={{ marginBottom: 10 }} placeholder="Enter Code" value={this.state.code} defaultValue='code ' onChange={(e) => {
                      this.handleInputChange(e, 'code')
                    }} />
                  </React.Fragment> :
                  <React.Fragment>
                    {this.state.resetPassword
                      ? null
                      : (
                        <Input
                          style={{ marginBottom: 10 }}
                          placeholder="Email"
                          value={this.state.email}
                          defaultValue='email'
                          onChange={(e) => { this.handleInputChange(e, 'email') }}
                        />
                      )}
                    {this.state.forgotPassword
                      ? null
                      : (
                        <Input
                          style={{ marginBottom: 10 }}
                          type="password"
                          placeholder="Password"
                          value={this.state.password}
                          defaultValue='password'
                          onChange={(e) => this.handleInputChange(e, 'password')}
                        />
                      )}
                    {this.state.resetPassword
                      ? <Input type="password" value={this.state.passwordConfirmation} defaultValue='password' placeholder="Password Confirmation" onChange={(e) => this.handleInputChange(e, 'passwordConfirmation')} />
                      : null
                    }
                  </React.Fragment>
              }
            </div>

            <Button
              className="spl-btn"
              type="primary"
              htmlType="submit"
              >
              {this.state.forgotPassword || this.state.resetPassword ? 'Reset Password' : 'Login'}
            </Button>
          </Form>
          {this.state.forgotPassword
            ? (
              <p className="spl-bottom-link" onClick={() => { this.setState({ forgotPassword: false, emailNotExist: '' }) }}>
                &lt; Back to Login
              </p>
            )
            : (
              <p className="spl-bottom-link" onClick={() => { this.setState({ forgotPassword: true, emailValidationError: '' }) }}>
                Forgot password?
              </p>
            )}
        </div>
        <div className="spl-footer" style={{
          width: '100%',
          height: 75,
          position: 'absolute',
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20
        }}>
          <div className="spl-copy">
            © <b>Sourcepoint {new Date().getFullYear()}</b>. All Rights Reserved.
          </div>
          <div style={{ display: 'flex' }} className="spl-links">
            <div>
              <a href="http://sourcepoint.com/about-us">
                About Us
              </a>
            </div>
            <div>
              <a href="http://sourcepoint.com/privacy-policy">
                Privacy Policy
              </a>
            </div>
            <div>
              <a href="http://sourcepoint.com/terms-of-use">
                Terms Of Use
              </a>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    createdUserCreds: store.accountState.getIn(['createdUserCreds', 'value']),
    createdUserCredsError: store.accountState.getIn(['createdUserCreds', 'error']),
  };
};

export default connect(
  mapStateToProps, {
  getUserDetails,
},
)(SignIn);
