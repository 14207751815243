import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Form } from "antd";
import { InfoCircleFilled } from '@ant-design/icons';
import { Input, Select, Button, TreeSelect, Infotip } from '../../../../../../styleguide';
import { getVendorTypeIcon, RULES, getIabPurposesForGvlVendor } from '../helper';

const { SHOW_PARENT } = TreeSelect;

const DurationInput = ({ value = {}, onChange }) => {
  const [number, setNumber] = useState();
  const [suffix, setSuffix] = useState('day');

  const triggerChange = (changedValue) => {
    onChange?.({
      number,
      suffix,
      ...value,
      ...changedValue,
    });
  };

  const onNumberChange = (e) => {
    let newNumber = parseInt(e.target.value || '-1');
    newNumber = newNumber !== -1 ? newNumber : ''; 

    if (Number.isNaN(number)) {
      return;
    }

    if (!('number' in value)) {
      setNumber(newNumber);
    }

    triggerChange({
      number: newNumber,
    });
  };

  const onSuffixChange = (newSuffix) => {
    if (!('suffix' in value)) {
      setSuffix(newSuffix);
    }

    triggerChange({
      suffix: newSuffix,
    });
  };

  const selectAfter = (
      <Select
        defaultValue="day"
        value={value.suffix || suffix}
        onChange={onSuffixChange}
      >
        <Option value="session">Session</Option>
        <Option value="minute">Minute(s)</Option>
        <Option value="hour">Hour(s)</Option>
        <Option value="day">Day(s)</Option>
        <Option value="month">Month(s)</Option>
        <Option value="year">Year(s)</Option>
      </Select>
  );

  return (
    <Input 
      className='set-duration'
      value={value.suffix === 'session' ? '' : value.number || number}
      onChange={onNumberChange}
      addonAfter={selectAfter} 
      disabled={value.suffix === 'session' ? true : false}
    />
  );
};

const CookieForm = (props) => {

  const [vendorId,setVendorId] = useState();
  const [options, setOptions] = useState(props.selectedVendors);
  const [purposeLabelForIabVendors, setPurposeLabelForIabVendors] = useState(<>Purpose(s)</>)
  useEffect(()=>{
    setVendorId(props.form.getFieldValue('vendor'))
  },[props.form.getFieldValue('vendor')]);

  const onVendorChange = (value) => {
    props.form.setFieldValue('purposes',[])
    setVendorId(value);
    setOptions(props.selectedVendors);
  }

  useEffect(()=>{
    let selectedVendor = props.selectedVendors.find(v => v.id === vendorId)
    if(selectedVendor && selectedVendor.vendorType === 'IAB') {
      let vendor = selectedVendor.toJS();
      let selectedVendorPurposes = getIabPurposesForGvlVendor(vendor);
      props.setPurposeOptions([
        {
          title: "All",
          value: "all",
          children: props.purposes[0].children.filter((purposeOption) =>
            purposeOption.type === 'CUSTOM' ||
            selectedVendorPurposes.includes(purposeOption.iabPurposeRef)
          ),
        },
      ]);
      setPurposeLabelForIabVendors(
        <>
          Purpose(s)
          <Infotip
            content={<p>Purpose list is based on the GVL-defined purposes for IAB vendors.</p>}
            arrowPointAtCenter={true}
            trigger="hover"
            className="vendor-list-wizard small"
          >
           &nbsp;<InfoCircleFilled style={{ color: "#FCA015", fontSize:'10px' }} />
          </Infotip>
        </>
      );
    } else {
      props.setPurposeOptions(props.purposes)
      setPurposeLabelForIabVendors(<>Purpose(s)</>)
    }
  },[vendorId]);

  const TREE_PROPS = {
    treeData : props.purposeOptions,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    maxTagCount: 1,
    dropdownClassName: 'purpose-dropdown',
    showSearch: false,
    showArrow: true,
    treeDefaultExpandAll: true,
    tagRender: (value) => <p className='selected-purpose-tag'>{value.label}</p>,
    maxTagPlaceholder: (value) => value.length && <span className='purpose-count'>+{value.length}</span>,
    disabled: !vendorId
  }
  const validateDuration = (_, value) => {
    const pattern = /^[1-9]+[0-9]*$/;
    const durations = ['day','year','month','minute','hour','session'];
    if(value.number === '' && value.suffix !== 'session') {
      return Promise.reject(new Error('This field cannot be empty.'))
    }else if(!durations.includes(value.suffix)){
      return Promise.reject(new Error('Invalid data.'))
    }else if(value.suffix === 'session') {
      return Promise.resolve();
    }else if (!(pattern.test(value.number)) || Number.isNaN(value.number)) {
      return Promise.reject(new Error('Invalid data.'))
    }else if(value.number !== '' && value.suffix !== 'session') {
      return Promise.resolve();
    } 
  };

  const validateCookieName = (_, value) => {
    if(typeof value !== 'string') 
      return Promise.reject(new Error('Invalid data.'));

    let newCookieName = value.trim();

    if(newCookieName === '') 
      return Promise.reject( new Error('This field cannot be empty'))
    
    if(props.selectedCookies.filter(c => c.manuallyAdded === true && c.vendorId === vendorId).map(c => c.name).includes(newCookieName)) 
      return Promise.reject(new Error('Cookie name already exists.'))
    
    return Promise.resolve();
  };

  const validateVendorName = (_, value) => {
    let vendorExists = options.map((v) => v.id).includes(value);
  
    if(!vendorExists){
      return Promise.reject( new Error('Invalid data'))
    }
    
    return Promise.resolve();
  };

  const onSearch = (value) => {
    setOptions(props.selectedVendors.filter(vendor => vendor.name.toLowerCase().includes(value.toLowerCase())))
  }

  const selectOptions = options.map(vendor => {
    const icon = getVendorTypeIcon(vendor);
    return <Select.Option key={vendor.id} value={vendor.id}>{icon} {vendor.name}</Select.Option>
  });

  return (
    <div className='form-container'>
      <Form name="add-cookie-form" form={props.form} initialValues={props.initialValues} onFinish={props.onFinish} layout="vertical">
        <div className='main-container'>
          <Form.Item name="cookieName" rules={[{validator: validateCookieName}]} label="Cookie Name">
            <Input />
          </Form.Item>
          <Form.Item name="vendor" rules={[{validator: validateVendorName}]} label="Vendor">
            <Select dropdownClassName='selected-vendors-options' disabled={props.isDisabled} value={vendorId} onChange={onVendorChange} showSearch filterOption={false} onSearch={onSearch}>
              {selectOptions}
            </Select> 
          </Form.Item>
          <Form.Item name="domain" rules={RULES.domain} label="Domain (Optional)">
            <Input />
          </Form.Item>
          <Form.Item name="purposes" rules={RULES.purpose} label={purposeLabelForIabVendors}>
            <TreeSelect {...TREE_PROPS}/>   
          </Form.Item>
          <Form.Item name="duration" rules={[{validator: validateDuration,}]} label="Duration">
            <DurationInput />
          </Form.Item>
        </div>
        <div className='form-footer'>
          {props.cancel && <Form.Item>
              <Button  htmlType="cancel" onClick={props.onCancel}>Cancel</Button>
          </Form.Item>}
          {props.save && <Form.Item>
            <Button type={props.type} onClick={() => {
              props.form.submit()
              props.setSubmitBtnType('save')
            }}>Save</Button>
          </Form.Item>}
          <Form.Item>
            <Button type="primary" onClick={() => {
              props.form.submit()
              props.setSubmitBtnType('submit')
            }} >{props.buttonTitle}</Button>
          </Form.Item>
        </div>
        </Form>
    </div>
  )
}

CookieForm.defaultProps = {
  selectedVendors : [],
  buttonTitle: 'Add to Selected Cookies',
  htmlType: 'submit',
  type: 'secondary',
}

CookieForm.propTypes = {
    onFinish: PropTypes.func.isRequired,
};

export default CookieForm;
  
