import React, { useState, useEffect } from "react";
import { Input, Popover, Checkbox } from 'antd';
import { ExportOutlined, FileTextOutlined } from '@ant-design/icons';
import CSVLink from "../../../common/CSVLink.js";
const { TextArea } = Input;

const legalBasisMap = {
   'CONSENT': 'Consent',
   'LEGITIMATE_INTEREST': 'Legitimate Interest',
   'NOT_ALLOWED': 'Not Allowed',
   'DISCLOSURE_ONLY': 'Disclosure Only'
};

function ExportVendorList(props) {
  const [name, updateName] = useState(props.vl.name);
  const [description, updateDescription] = useState('');
  const [showPurposeLevelActions, updatePurposeLevelActions] = useState(false);
  const [showVendorLevelActions, updateVendorLevelPurpose] = useState(false);
  const [exportData, updateExportData] = useState([]);

  useEffect(() => {
    updateName(props.vl.name);
  }, [props.vl.name]);

  const exportParams = {
    vendorList: props.vl.toJS(), 
    description, 
    siteNames: props.siteNames, 
    iabSpecialFeatures: props.iabSpecialFeatures.toJS(), 
    showPurposeLevelActions, 
    showVendorLevelActions, 
    iabSpecialPurposes: props.iabSpecialPurposes.toJS(), 
    iabFeatures: props.iabFeatures.toJS()
    };

  const content = (
      <React.Fragment>
        <div className="export-row">
          <label>Name</label><br/>
          <Input placeholder="Name" value={name} onChange={e => updateName(e.target.value)}/>
          <label>Description</label><br/>
          <TextArea rows={3} placeholder="(Optional)" value={description} onChange={e => updateDescription(e.target.value)}/>
          <Checkbox checked={showPurposeLevelActions} onChange={(e) => updatePurposeLevelActions(e.target.checked)}>Show consent/reject actions per purpose</Checkbox>
          <br/>
          <Checkbox checked={showVendorLevelActions} onChange={(e) => updateVendorLevelPurpose(e.target.checked)}>Show consent/reject actions per vendor</Checkbox>
        </div>
        <hr/>
        <div className={`export-row download ${!name || !exportParams.vendorList.categories.length > 0 ? 'disabled' : ''}`}>
        <CSVLink onClick={() => updateExportData(generateExportData(exportParams))} filename={`${name}.csv`} data={exportData}>
            <FileTextOutlined /> CSV
          </CSVLink>
        </div>
      </React.Fragment>
  );

  return <Popover overlayClassName="export-vendor-list" placement="bottom" content={content} trigger="hover"><ExportOutlined /> Export</Popover>;
}
export default ExportVendorList;

function caseSensitiveCompare(a, b) {
  // Sort character by character, return early if possible
  for (let ii = 0; ii < Math.max(a.name.length, b.name.length); ii++) {
    const aChar = a.name.charAt(ii);
    const bChar = b.name.charAt(ii);

    // If inputs match up to here, but lengths don't match, sort by length
    if (!(aChar && bChar)) {
      return a.name.length - b.name.length;
    }

    // If we meet a differing character, return early
    const comp = aChar.localeCompare(bChar);
    if (comp !== 0) {
      return comp;
    }
  }
  // If we found nothing to do, the strings are equal
  return 0;
}

function generateExportData({ vendorList, description, siteNames, iabSpecialFeatures, showPurposeLevelActions, showVendorLevelActions, iabSpecialPurposes, iabFeatures }) {
  let data = [];
  let purposes = [];
  const iabSpecialFeaturesLabels = iabSpecialFeatures.map(iabSF => iabSF.name);
  const iabSpecialPurposesLabels = iabSpecialPurposes.map(iabSP => iabSP.name);
  const iabFeaturesLabels = iabFeatures.map(iabFeature => iabFeature.name);
  let purposeLevelActions = [[ 'Vendor List Category' ], [ 'Category type', 'Category ID', 'Name', 'Consent Action', 'Reject Action' ]]; 
  const stackLabels = [ 'Used', 'Stack Name(s)' ];
  const cookiesTitle = [ '', 'Cookies' ];
  const cookiesLabels = [ 'Name', 'Domain', 'Duration', 'Purpose' ];
  const categories = vendorList.categories;
  const vendorsWrappers = vendorList.vendorsWrappers;
  const vendorConsentActionsLabel = showVendorLevelActions ? [ 'Consent Actions', 'Reject Actions' ] : [];
  let vendorHeaders = [ 'Vendor Name', 'Vendor ID', 'IAB Vendor', 'ATP Vendor', 'Custom Vendor', 'IAB ID', 'Google ATP ID', 'Privacy Policy', 'Details' ].concat(vendorConsentActionsLabel);

  const vlGeneralDetails = [[ 'Vendor List Name', vendorList.name, 'Vendor List ID', vendorList.id, 'Properties Applied', siteNames.join(' ') ], [ 'Description', description ], [], []];

  categories.map((category) => {
    if(category.categories.length > 0) {
      const stackPurposeType = category.type == 'CUSTOM_STACK' ? 'Custom Stack' : 'IAB Stack';
      const stackConsentActions = category.consentActions.length > 0 ? category.consentActions[0].js.replace(/\"/g, "\"\"") : '';
      const stackRejectActions = category.rejectActions.length > 0 ? category.rejectActions[0].js.replace(/\"/g, "\"\""): '';
      purposeLevelActions.push([ stackPurposeType, category.id, category.name, stackConsentActions, stackRejectActions ]);
      category.categories.map(cat => {
        purposes.push([cat.name]);
        const purposeType = cat.type == 'CUSTOM' ? 'Custom Purpose' : 'IAB Purpose';
        const catgConsentActions = cat.consentActions.length > 0 ? cat.consentActions[0].js.replace(/\"/g, "\"\"") : '';
        const catgRejectActions = cat.rejectActions.length > 0 ? cat.rejectActions[0].js.replace(/\"/g, "\"\""): '';
        purposeLevelActions.push([ purposeType, cat.id, cat.name, catgConsentActions, catgRejectActions ]);
      });
    } else {
      const purposeType = category.type == 'CUSTOM' ? 'Custom Purpose' : 'IAB Purpose';
      const catgConsentActions = category.consentActions.length > 0 ? category.consentActions[0].js.replace(/\"/g, "\"\"") : '';
      const catgRejectActions = category.rejectActions.length > 0 ? category.rejectActions[0].js.replace(/\"/g, "\"\""): '';
      purposeLevelActions.push([ purposeType, category.id, category.name, catgConsentActions, catgRejectActions ]);
      purposes.push([ category.name ]);
    }
  });
  purposeLevelActions.push([]);
  purposeLevelActions.push([]);

  const flattenedPurposes = [].concat.apply([], purposes);

  let vendorDetHeaders = vendorHeaders.map((vh, vhi) => vhi == 0 ? 'Vendor List' : '');
  vendorDetHeaders.push('Purposes');

  flattenedPurposes.forEach((p, pi) => vendorDetHeaders.push(flattenedPurposes.length === (pi+1) ? 'Special Purposes' : ''));
  iabSpecialPurposes.forEach((sp, spi) => vendorDetHeaders.push(iabSpecialPurposes.length === (spi+1) ? 'Features' : ''));
  iabFeatures.forEach((f, fi) => vendorDetHeaders.push(iabFeatures.length === (fi+1) ? 'Special Features' : ''));
  iabSpecialFeaturesLabels.forEach((sf, sfi) => vendorDetHeaders.push(iabSpecialFeaturesLabels.length === (sfi+1) ? 'Stacks' : ''));
  data.push(vendorDetHeaders.concat(cookiesTitle));

  const vHeaders = vendorHeaders.concat(flattenedPurposes, iabSpecialPurposesLabels, iabFeaturesLabels, iabSpecialFeaturesLabels, stackLabels, cookiesLabels);
  data.push(vHeaders);

  const vendors = vendorList.vendors.sort( caseSensitiveCompare );
  vendors.map((vendor) => { 
   let vendorPurposes = [];
   let stacksUsed = [];
   let cookies = [];
   let vendorIabSF = [];
   let vendorIabSP = [];
   let vendorIabFeatures = [];

   const iabId = vendor.iab ? vendor.iabId : '';
   const googleAtpId = vendor.isGoogleAtp ? vendor.googleId : '';
   const isIab = (vendor.iab).toString().toUpperCase();
   const isCustom = (!vendor.isGoogleAtp && !vendor.iab).toString().toUpperCase();
   const isAtp = (vendor.isGoogleAtp || false).toString().toUpperCase();
   const vendorWrapper = vendorsWrappers.find(vw => vw.vendorId === vendor.id) || { description: '', consentActions: '', rejectActions: '' };
   const description = vendorWrapper && vendorWrapper.description ? vendorWrapper.description.replace(/<\/?[^>]+(>|$)/g, "") : '';
   const consentActions = vendorWrapper && vendorWrapper.consentActions.length > 0 ? vendorWrapper.consentActions[0].js.replace(/\"/g, "\"\"") : '';
   const rejectActions = vendorWrapper && vendorWrapper.rejectActions.length > 0 ? vendorWrapper.rejectActions[0].js.replace(/\"/g, "\"\""): '';
   const vendorConsentActions = showVendorLevelActions ? [consentActions, rejectActions] : [];
   let vendorDetails = [ vendor.name, vendor.id, isIab, isAtp, isCustom, iabId, googleAtpId, vendor.policyUrl, description].concat(vendorConsentActions);

   categories.map((category) => {
    if(category.categories.length > 0) {
      const isStacksUsed = category.categories.filter(cat => {
        const catLegalBasis = cat.vendorCategorization.find((lb) => lb.vendorId === vendor.id);
        if(catLegalBasis) {
          const cookieWithPurpose = catLegalBasis.cookies.map(c => { return {...c, purpose: cat.name}; });
          cookies = cookies.concat(cookieWithPurpose);
          vendorPurposes.push([ legalBasisMap[catLegalBasis.type ]]);
        } else {
          vendorPurposes.push([ 'Not Applicable' ]);
        }
        return catLegalBasis != undefined;
      });
      if(isStacksUsed.length > 0) {
        stacksUsed.push(category.name);
      }
    } else {
        const catLegalBasis = category.vendorCategorization.find((lb) => lb.vendorId === vendor.id);
        if(catLegalBasis) {
          const cookieWithPurpose = catLegalBasis.cookies.map(c => { return {...c, purpose: category.name} });
          cookies = cookies.concat(cookieWithPurpose);
          vendorPurposes.push([ legalBasisMap[catLegalBasis.type]]);
        } else {
          vendorPurposes.push([ 'Not Applicable' ]);
        }
    }
   });
   iabSpecialPurposes.map(vsp => vendorIabSP.push(vendor.iabSpecialPurposes.includes(vsp.id) ? 'Used' : 'Not Used'));
   iabFeatures.map(vf => vendorIabFeatures.push(vendor.iabFeatures.includes(vf.id) ? 'Used' : 'Not Used'));
   iabSpecialFeatures.map(vsf => vendorIabSF.push(vendor.iabSpecialFeatures.includes(vsf.id) && vendorList.iabSpecialFeatures.includes(vsf.id) ? 'Active' : 'Inactive'));
   const vendorInfo = vendorDetails.concat([].concat.apply([], vendorPurposes), vendorIabSP, vendorIabFeatures, vendorIabSF, [stacksUsed.length, stacksUsed.join(' ')]);

   if(cookies.length > 0) {
      cookies.map((cookie) => {
        const cookieData = [ cookie.name, cookie.domain, formatCookieLifeSpan(cookie.cookieLifeSpan), cookie.purpose ];
        data.push(vendorInfo.concat(cookieData));         
      });
   } else {
        data.push(vendorInfo);         
   }
  });
  return vlGeneralDetails.concat((showPurposeLevelActions ? purposeLevelActions : []), data);
}

function formatCookieLifeSpan(cookieLifeSpan) {
  let unit = cookieLifeSpan ? cookieLifeSpan.split(' ')[0] : '';
  const interval = cookieLifeSpan ? (cookieLifeSpan.split(' ')[1] || '') : '';
  if( interval && Number(interval) > 1 ) {
    unit = unit + 's';
  }
  return interval + ' ' + unit;
}