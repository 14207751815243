import 'whatwg-fetch';
import { fromJS, List, Map } from 'immutable';
import {
  checkStatus,
  buildURL,
  divideByZeroErrChk,
} from '../helper.js';
import { DATEPICKERS } from '../../constants';
import moment from 'moment';
import {
  propertyDnsaRecord,
  MessageDnsaRecord,
} from '../../records/ccpa_dnsa_reporting_records.js';
import { isIncompleteDateRange } from '../gdpr_v2/gdpr_performance_report_v2';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export async function getAndParseCcpaDnsReportingData(data, rangeSelect) {
  const dnsaPromises = data.map((d, i) => {
    const formattedData = d
      .set('startDate', rangeSelect.get('startDate').format('YYYY-MM-DD'))
      .set('endDate', rangeSelect.get('endDate').format('YYYY-MM-DD'))
      .toMap()
      .filter((v) => (List.isList(v) ? v.size : v))
      .toJS();
    let fetchUrl = buildURL(
      `${permissionsSvsBase}/reports/ccpa/daily`,
      formattedData
    );
    return fetch(fetchUrl, {
      method: 'GET',
      credentials: 'include',
      mote: 'cors',
      headers: { 
        'Content-Type': 'application/json'
      }
    })
      .then(checkStatus)
      .then((resp) => resp.json())
      .then((resp) =>
        aggregateDnsaData(fromJS(resp.results), rangeSelect, formattedData, i)
      );
  });
  return Promise.all(dnsaPromises).then((res) => List(res));
}

async function aggregateDnsaData(data, rangeSelect, formattedData, index) {
  let groupedByYmdOrderedMap;
  let auxData;
  let groupedByYmdOrderedMapMsg;
  let groupedByYmdOrderedMapMsgPerformance;
  let propertyTableData = List();
  let messageTableData = List();
  let key = 0;
  let msgKey = 0;
  let aggPropertyGraphData = List();
  let aggMsgGraphData = List();
  let aggTotalPV = 0;
  let aggPVOptIn = 0;
  let aggPVOptOut = 0;
  let aggTotalPVMsg = 0;
  let aggActionOptIn = 0;
  let aggActionOptOut = 0;
  let aggPropertyTableData;
  let aggMsgTableData;
  let messagePerformanceList = List();
  let datePicker = rangeSelect.get('datePicker');

  groupedByYmdOrderedMap = data.groupBy((dataPoint) => dataPoint.get('ymd'));

  let auxUrl = datePicker === DATEPICKERS.week ? 'weekly' : 'monthly';
  let fetchUrl = buildURL(
    `${permissionsSvsBase}/reports/ccpa/${auxUrl}`,
    formattedData
  );
  const isIncompleteDate = isIncompleteDateRange(
    datePicker,
    formattedData.endDate
  );
  auxData = isIncompleteDate
    ? data
    : await fetch(fetchUrl, {})
        .then(checkStatus)
        .then((resp) => resp.json())
        .then((resp) => fromJS(resp.results));

  groupedByYmdOrderedMapMsg = auxData.groupBy((dataPoint) => dataPoint.get('message_id'));

  groupedByYmdOrderedMapMsgPerformance = data.groupBy(
    (dataPoint) => dataPoint.get('ymd') + '-' + dataPoint.get('message_id')
  );
  // Calculate message compare graph data
  groupedByYmdOrderedMapMsgPerformance.map((p) => {
    let stpv = p
      .map((entry) => Number(entry.get('total_pageviews')))
      .reduce((prev, current) => prev + current);
    let saopto = p
      .map((entry) => Number(entry.get('action_opt_out_count')))
      .reduce((prev, current) => prev + current);
    let optOutRate = divideByZeroErrChk(saopto, stpv);
    if (optOutRate > 0) {
      messagePerformanceList = messagePerformanceList.push(
        Map({
          date: p.get(0).get('ymd'),
          [p
            .get(0)
            .get('message_id')
            .toString()]: optOutRate,
        })
      );
    }
  });
  groupedByYmdOrderedMapMsg.map((p) => {
    let stpv = p
      .map((entry) => Number(entry.get('total_pageviews')))
      .reduce((prev, current) => prev + current);
    let saopti = p
      .map((entry) => Number(entry.get('action_opt_in_count')))
      .reduce((prev, current) => prev + current);
    let saopto = p
      .map((entry) => Number(entry.get('action_opt_out_count')))
      .reduce((prev, current) => prev + current);

    aggTotalPVMsg += stpv;
    aggActionOptIn += saopti;
    aggActionOptOut += saopto;

    messageTableData = messageTableData.push(
      Map({
        key: msgKey,
        totalpageviews: stpv,
        actionoptin: saopti,
        actionoptout: saopto,
        actionoptinPer: divideByZeroErrChk(saopti, stpv),
        actionoptoutPer: divideByZeroErrChk(saopto, stpv),
        siteid: p.get(0).get('site_id'),
        messageid: Number(p.get(0).get('message_id')),
      })
    );
    msgKey++;
  });

  aggMsgTableData = Map({
    key: index,
    messageid: 'Criteria ' + Number(index + 1),
    totalpageviews: aggTotalPVMsg,
    actionoptin: aggActionOptIn,
    actionoptout: aggActionOptOut,
    actionoptinPer:  divideByZeroErrChk(aggActionOptIn, aggTotalPVMsg),
    actionoptoutPer:  divideByZeroErrChk(aggActionOptOut, aggTotalPVMsg),
    aggData: messageTableData.map((s, i) => MessageDnsaRecord(s, i)),
  });

  groupedByYmdOrderedMap.map((p, i) => {
    let stpv = p
      .map((entry) => Number(entry.get('total_pageviews')))
      .reduce((prev, current) => prev + current);
    let spvoi = p
      .map((entry) => Number(entry.get('pv_opt_in_count')))
      .reduce((prev, current) => prev + current);
    let spvoo = p
      .map((entry) => Number(entry.get('pv_opt_out_count')))
      .reduce((prev, current) => prev + current);
    let saopti = p
      .map((entry) => Number(entry.get('action_opt_in_count')))
      .reduce((prev, current) => prev + current);
    let saopto = p
      .map((entry) => Number(entry.get('action_opt_out_count')))
      .reduce((prev, current) => prev + current);

    propertyTableData = propertyTableData.push(
      Map({
        date: i,
        key: key,
        totalpageviews: stpv,
        pvoptin: spvoi,
        pvoptout: spvoo,
        pvoptinPer:  divideByZeroErrChk(spvoi, stpv),
        pvoptoutPer:  divideByZeroErrChk(spvoo, stpv),
      })
    );

    aggTotalPV += stpv;
    aggPVOptIn += spvoi;
    aggPVOptOut += spvoo;

    aggPropertyGraphData = aggPropertyGraphData.push(
      Map({
        key: key,
        date: i,
        fancyDate: moment(i, 'YYYY-MM-DD').format('MMM Do'),
        ['totalpageviews' + index]: stpv,
        ['pvoptin' + index]: divideByZeroErrChk(spvoi, stpv),
        ['pvoptout' + index]: divideByZeroErrChk(spvoo, stpv),
        ['siteIds' + index]: p
          .map((entry) => [entry.get('site_id')])
          .reduce((prev, current) => prev.concat(current)),
      })
    );

    aggMsgGraphData = aggMsgGraphData.push(
      Map({
        key: key,
        date: i,
        fancyDate: moment(i, 'YYYY-MM-DD').format('MMM Do'),
        ['totalpageviews' + index]: stpv,
        ['actionoptin' + index]: divideByZeroErrChk(saopti, stpv),
        ['actionoptout' + index]: divideByZeroErrChk(saopto, stpv),
        ['siteIds' + index]: p
          .map((entry) => [entry.get('site_id')])
          .reduce((prev, current) => prev.concat(current)),
      })
    );

    key++;
  });

  aggPropertyTableData = Map({
    key: index,
    date: 'Criteria ' + Number(index + 1),
    totalpageviews: aggTotalPV,
    pvoptin: aggPVOptIn,
    pvoptout: aggPVOptOut,
    pvoptinPer: divideByZeroErrChk(aggPVOptIn, aggTotalPV),
    pvoptoutPer: divideByZeroErrChk(aggPVOptOut, aggTotalPV),
    aggData: propertyTableData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyDnsaRecord(s, i)),
  });
  return Map({
    propertyTable: propertyDnsaRecord(aggPropertyTableData, index),
    propertyGraph: aggPropertyGraphData,
    messageGraph: aggMsgGraphData,
    messageTable: MessageDnsaRecord(aggMsgTableData, index),
    messagePerformanceList: messagePerformanceList,
  });
}
