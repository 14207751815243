import PropTypes from 'prop-types';
import React from 'react';
import { message } from 'antd';

import BooleanStep from './BooleanStep';
import CommonStringInput from './CommonStringInput';
import { RegexMatchRecord } from '../../../../../scenario_constants.js';

export default class RegexMatchGate extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(RegexMatchRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  onChange = (event) => {
    const value = event.target.value;
    let isValidRegex = true;

    try {
      new RegExp(value);
    } catch (e) {
      isValidRegex = false;
      message.error('Invalid Regex');
    }

    if (isValidRegex) {
      const data = this.props.data.set('regex', value);
      this.props.onChange(data);
    }
  }

  render() {
    return (
      <BooleanStep
        { ...this.props }
        labelText={ 'URL Regex Match ' + this.props.data.regex }
        propertyName={ 'continue_on_satisfy' }
      >
        { this.props.children }
        <CommonStringInput
          label={ 'Regex' }
          value={ this.props.data.regex }
          onChange={ (event) => this.onChange(event) }
        />
      </BooleanStep>
    );
  }
}
