import PropTypes from 'prop-types';
import React from 'react'

import CustomButton from './CustomButton.js.jsx';

export default class extends React.Component {
    static propTypes = {
        handleDelete: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        isCampaign: PropTypes.bool,
        dataLossWarning: PropTypes.string,
    };

    static defaultProps = {
        isCampaign: false,
        dataLossWarning: <p>All data and configurations will be lost.</p>,
    };

    render() {

        var deleteOrDeactivate;
        var dataLossWarning;

        if (this.props.isCampaign) {
            deleteOrDeactivate = "deactivate";
        } else {
            deleteOrDeactivate = "delete";
            dataLossWarning = this.props.dataLossWarning;
        }


        var message = "Are you sure you want to " + deleteOrDeactivate + " " + this.props.name + " ?";

        return (
          <div className="warning-container">
            <div className="darkness"></div>
            <div className="warning-window-container">
              <div className="warning-window">
                <p>{ message }</p>
                { dataLossWarning }
                <div className="buttons-container">
                  <CustomButton className='cancel-button' type={ CustomButton.types.PRIMARY } onClick={ (e) => this.props.handleCancel(e) } >CANCEL</CustomButton>
                  <CustomButton className='delete-button' type={ CustomButton.types.DANGER } onClick={ (e) => this.props.handleDelete(e) } >{ deleteOrDeactivate.toUpperCase() }</CustomButton>
                </div>
              </div>
            </div>
          </div>
        )
    }
}
