import React from 'react';
import moment from 'moment';

export const NotificationTypes = [
  { value: 'all', label: 'All alerts' },
  { value: 'NEW_VENDOR', label: 'New Vendor Found' },
  { value: 'CHANGES_DETECTED', label: 'Changes Detected' },
  { value: 'CHANGED_STATUS', label: 'Status Changed' },
  { value: 'RELATIVE_SCORE', label: 'Worse Relative Score' },
  // TODO: temporary hided
  // { value: 'AVERAGE_COUNT', label: 'Worse Average Count' },
  { value: 'HIGHER_PREVALENCE', label: 'Higher Prevalence' },
  { value: 'VENDORS_CALLS', label: 'More Vendors Called Directly' },
];

export function formatNotificationsData(newData, currentList = {}) {
  const currentDate = moment();
  const yesterdayDate = moment(currentDate).subtract(1, 'days');
  const classifyDate = (createdAt) => {
    const date = moment(createdAt);
    if (currentDate.isSame(date, 'day')) {
      return 'Today';
    } else if (yesterdayDate.isSame(date, 'day')) {
      return 'Yesterday';
    }
    return 'Older';
  };

  if (!newData?.results) {
    return currentList;
  }

  const groupByDate = newData?.results?.reduce((grouped, notification) => {
    const dateGroup = classifyDate(notification.created_at);
    const newGroup = [...(grouped?.[dateGroup] || []), notification];
    return { ...grouped, [dateGroup]: newGroup };
  }, currentList);

  return {
    ...groupByDate,
    count: newData?.count,
    page: (currentList?.page || 0) + 1,
  };
}

export function generateTitle(type, parameters) {
  return {
    NEW_VENDOR: (
      <span title={`New vendor found on property ${parameters.website_name}`}>
        <b>New vendor</b> found on property <b>{parameters.website_name}</b>
      </span>
    ),
    RELATIVE_SCORE: (
      <span title={`Relative score dropped for property ${parameters.website_name}`}>
        <b>Relative score dropped</b> for property <b>{parameters.website_name}</b>
      </span>
    ),
    AVERAGE_COUNT: (
      <span title={`Average count decreased for property ${parameters.website_name}`}>
        <b>Average count decreased</b> for property <b>{parameters.website_name}</b>
      </span>
    ),
    CHANGED_STATUS: (
      <span title={`Vendor status changed on property ${parameters.website_name}`}>
        <b>Vendor status changed</b> on property <b>{parameters.website_name}</b>
      </span>
    ),
    CHANGES_DETECTED: (
      <span title={`Change detected on property ${parameters.website_name}`}>
        <b>Change detected </b> on property <b>{parameters.website_name}</b>
      </span>
    ),
    VENDORS_CALLS: (
      <span title={`More vendors called directly on property ${parameters.website_name}`}>
        <b>More vendors called directly</b> on property <b>{parameters.website_name}</b>
      </span>
    ),
    HIGHER_PREVALENCE: (
      <span title={`Vendor prevalence increased on property ${parameters.website_name}`}>
        <b>Vendor prevalence increased</b> on property <b>{parameters.website_name}</b>
      </span>
    ),
  }?.[type];
}

function formatNote(parameters) {
  const noteWithProperty = parameters.note
    ?.replaceAll('on this property', `on property ${parameters.website_name}`)
    .replaceAll('Vendor', `Vendor ${parameters.vendor_name}`);
  if (!noteWithProperty.includes('\\n')) {
    return noteWithProperty;
  }
  const lines = noteWithProperty.replace(':\\n ', ': ').split('\\n');
  const lastLine = lines.pop();
  return lines.join(', ') + (lines.length ? '. ' : '') + lastLine + '.';
}

function formatValue(val, prec = 1) {
  return (+val).toFixed(prec);
}

function getRelativeScoreReason(reason) {
  if (reason?.length === 2) {
    return 'due to a change in both property and benchmark averages.';
  }
  if (reason?.includes('property')) {
    return 'due to a change in property average.';
  }
  if (reason?.includes('benchmark')) {
    return 'due to a change in benchmark average.';
  }
  return '-';
}
export function generateDescription(type, parameters) {
  return {
    NEW_VENDOR: () => (
      <span>
        New Vendor <b>{parameters.vendor_name}</b> found on property <b>{parameters.website_name}</b>.
      </span>
    ),
    RELATIVE_SCORE: () => (
      <span>
        Relative score for property <b>{parameters.website_name}</b> decreased{' '}
        <b>{formatValue(+(parameters.diff || +parameters.prev_value - parameters.current_value), 0)}%</b> from high{' '}
        <b>{formatValue(parameters.prev_value, 0)}%</b> to low <b>{formatValue(parameters.current_value, 0)}%</b> in{' '}
        <b>{parameters?.region_name}</b> comparing to last week {getRelativeScoreReason(parameters?.reason)}
      </span>
    ),
    AVERAGE_COUNT: () => (
      <span>
        Average count for property <b>{parameters.website_name}</b> decreased{' '}
        <b>{formatValue(+parameters.diff || +parameters.prev_value - parameters.current_value)}</b> from high{' '}
        <b>{formatValue(parameters.prev_value)}</b> to low <b>{formatValue(parameters.current_value)}</b> in{' '}
        <b>{parameters?.region_name}</b> comparing to last week
      </span>
    ),
    CHANGED_STATUS: () => (
      <span>
        Status of vendor <b>{parameters.vendor_name}</b> has been changed from {parameters.prev_status} to{' '}
        <b>{parameters.status}</b> by <b>{parameters.user_dialogue_id}</b> on property {parameters.website_name}.
      </span>
    ),
    CHANGES_DETECTED: () => <span>{formatNote(parameters, type)}</span>,
    VENDORS_CALLS: () => (
      <span>
        Vendor <b>{parameters.vendor_name}</b> (Layer {parameters.trace_level}, {parameters.vendor_domain}) has started
        calling {formatValue(parameters.diff, 0)} more vendors directly on property {parameters.website_name} (
        {parameters.website_url}) in the <b>{'last 7 days'}</b> in <b>{parameters.region_name}</b> region. Click to view
        the vendors contacted this week that were not contacted last week.
      </span>
    ),
    HIGHER_PREVALENCE: () => (
      <span>
        Vendor <b>{parameters.vendor_name}</b> (Layer {parameters.trace_level}, {parameters.vendor_domain}) has
        increased prevalence by <b>{formatValue(+parameters.diff, 0)}%</b> on property {parameters.website_name} (
        {parameters.website_url}) in the <b>{'last 7 days'}</b> from <b>low {formatValue(+parameters.prev_value, 0)}</b>{' '}
        to <b>high {formatValue(+parameters.current_value, 0)}</b> in <b>{parameters.region_name}</b> region.
      </span>
    ),
  }?.[type]();
}

const getMetric = (metric) =>
  'Vendors after targeted advertising opt out' === metric ? 'Vendors after opt out' : metric;

export const getType = (type, metric) => {
  const getTypeColor = () => {
    if (['HIGHER_PREVALENCE', 'VENDORS_CALLS'].includes(type)) {
      return '#FFD2FF';
    }
    return '#C6FFF2';
  };

  const getTypeMessage = () => {
    if (metric === 'Vendors after targeted advertising opt out') {
      return 'Vendors after opt out';
    }
    if (['HIGHER_PREVALENCE', 'VENDORS_CALLS'].includes(type)) {
      return 'Vendor Trace';
    }
    return metric;
  };

  return (
    <div className="diagnose-notification-type" style={{ backgroundColor: getTypeColor() }}>
      {getTypeMessage()}
    </div>
  );
};

export function createNotificationLink(type, parameters) {
  const pathname = {
    ['Non disclosed vendors observed']: '/diagnose_dashboard/non_disclosed_vendors_observed',
    ['Vendors triggered prior to consent']: '/diagnose_dashboard/vendors_triggered_prior_to_consent',
    ['Disclosed Vendors']: '/diagnose_dashboard/disclosed_vendors',
    ['Possible Fingerprinting']: '/diagnose_dashboard/possible_fingerprinting',
    ['Data leaving the EEA']: '/diagnose_dashboard/data_leaving_the_EEA',
    ['Cookies with long lifespans']: '/diagnose_dashboard/cookies_with_long_lifespans',
    ['All Cookies Found']: '/diagnose_dashboard/all_cookies_found',
    ['Vendors after targeted advertising opt out']: '/diagnose_dashboard/vendors_after_opt_out',
    ['Vendors after opt out']: '/diagnose_dashboard/vendors_after_opt_out',
  }?.[parameters?.metric];

  function formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  if (['AVERAGE_COUNT', 'RELATIVE_SCORE'].includes(type)) {
    const query = {
      type,
      vendor: parameters.vendor,
      start_date: formatDate(parameters.start_date),
      end_date: formatDate(parameters.end_date),
      property: parameters.website,
      metric: getMetric(parameters.metric),
      region: parameters.region,
    };

    return { pathname, hash: '#overview', query };
  }

  if (['NEW_VENDOR', 'CHANGES_DETECTED', 'CHANGED_STATUS'].includes(type)) {
    let query = {
      type,
      vendor: parameters.vendor,
      end_date: formatDate(parameters.created_at),
      property: parameters.website,
      metric: getMetric(parameters.metric),
    };

    if (['CHANGES_DETECTED', 'CHANGED_STATUS'].includes(type)) {
      query.activity = parameters.id;
    }

    return { pathname, query };
  }
  if (['HIGHER_PREVALENCE', 'VENDORS_CALLS'].includes(type)) {
    let query = {
      type,
      start_date: formatDate(parameters.start_date),
      end_date: formatDate(parameters.end_date),
      property: parameters.website,
      property_name: parameters.website_name,
      url: parameters.website_url,
      key: parameters.map_key,
      parental: parameters.parental_map_key,
      region: parameters.region,
      scheduled_scan_ids: parameters?.scheduled_scan_ids?.join(','),
      consent_type: parameters.consent_type,
      vendor_calls: parameters?.vendors_diff?.join(','),
      vendor: parameters.vendor,
      vendor_domain: parameters.vendor_domain,
      trace_level: parameters.trace_level,
    };
    return {
      pathname: '/vendor_trace',
      query,
    };
  }
  return '/';
}

export function checkNotificationTypesToPermissions(accountPermissions) {
  return NotificationTypes.filter((type) => {
    if (
      ['RELATIVE_SCORE', 'AVERAGE_COUNT'].includes(type.value) &&
      !accountPermissions?.includes('diagnose_dashboard')
    ) {
      return false;
    }
    if (
      ['HIGHER_PREVALENCE', 'VENDORS_CALLS'].includes(type.value) &&
      !accountPermissions?.includes('diagnose_vendor_trace')
    ) {
      return false;
    }
    if (
      ['CHANGES_DETECTED', 'CHANGED_STATUS', 'NEW_VENDOR'].includes(type.value) &&
      !accountPermissions?.includes('diagnose_vendor_management')
    ) {
      return false;
    }
    return true;
  });
}
