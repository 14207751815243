import { CloseOutlined, DownloadOutlined, GlobalOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Collapse, Empty, Menu, Modal, Spin, Tooltip, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  exportCSVVendorsAfterOptOutVendor,
  getVendorAfterOptOutVendorData,
  getVendorAfterOptOutVendorWebsite,
  getVendorDetails,
  getVendorWebsiteDetails,
  vendorDetailsClear,
  vendorDetailsExport,
  vendorWebsiteDetailsClear,
  vendorsAfterOptOutVendorDataClear,
  vendorsAfterOptOutVendorWebsiteClear,
} from '../../../../actions/diagnose_dashboard_actions';
import { formatRegions } from '../../../../helpers/diagnose/utils';
import DashboardSelectDropdown, { regionsTagRenderer } from '../shared/DashboardSelect';

const VendorEvidenceModal = ({
  metric,
  data,
  selectedFilters,
  vendorDetails,
  vendorDetailsPending,
  vendorWebsiteDetails,
  vendorWebsiteDetailsPending,
  getVendorDetails,
  regionsData,
  getVendorWebsiteDetails,
  vendorDetailsClear,
  vendorWebsiteDetailsClear,
  vendorDetailsExport,
  vendorDetailsExportPending,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState([]);
  const [activePageUrls, setActivePageUrls] = useState([]);
  const [regions, setRegions] = useState(
    metric === 'Vendors after opt out'
      ? selectedFilters?.regions.filter((selected) => selected.includes('US'))
      : selectedFilters?.regions,
  );
  const regionsList = useMemo(
    () =>
      metric === 'Vendors after opt out'
        ? formatRegions(regionsData).filter((zone) => zone.value === 'US')
        : formatRegions(regionsData),
    [metric, regionsData],
  );
  const plainRegionList = regionsList.flatMap((zone) => zone?.children.map((region) => region.value));

  const getVendorDetailsData = () => {
    getVendorDetails({
      name: data?.name,
      vendorId: data?.vendor_id,
      dateFrom: moment(selectedFilters?.start).format('YYYY-MM-DD'),
      dateTo: moment(selectedFilters?.end).format('YYYY-MM-DD'),
      metric: metric,
      region: regions.join(';'),
    });
  };
  const getVendorWebsiteDetailsData = (key) => {
    if (regions.length) {
      getVendorWebsiteDetails({
        name: data?.name,
        vendorId: data?.vendor_id,
        dateFrom: moment(selectedFilters?.start).format('YYYY-MM-DD'),
        dateTo: moment(selectedFilters?.end).format('YYYY-MM-DD'),
        metric: metric,
        region: regions.join(';'),
        website: key,
      });
    } else {
      message.warning('Please, select region');
    }
  };
  useEffect(() => {
    isModalVisible && regions.length && getVendorDetailsData();
  }, [regions]);

  const showModal = () => {
    setIsModalVisible(true);
    getVendorDetailsData();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    vendorDetailsClear();
    vendorWebsiteDetailsClear();
    setSelectedWebsite([]);
  };

  const { Panel } = Collapse;

  const handleSelectRegions = (value) => {
    const [newZone, newRegion] = value.split(':');
    if (value === 'All') {
      setRegions(regions.length !== plainRegionList.length ? plainRegionList : []);
    } else if (!newRegion && value !== 'All') {
      const zoneReions = plainRegionList.filter((region) => region.startsWith(`${newZone}:`));
      const [selectedFromZone, selectedInAnotherZone] = regions.reduce(
        ([selectedZone, anotherZone], region) => {
          if (region.startsWith(`${newZone}:`)) {
            selectedZone.push(region);
          } else {
            anotherZone.push(region);
          }
          return [selectedZone, anotherZone];
        },
        [[], []],
      );
      setRegions(
        zoneReions?.length === selectedFromZone.length
          ? selectedInAnotherZone
          : [...regions, ...zoneReions.filter((child) => !regions.includes(child))],
      );
    } else {
      setRegions(regions.includes(value) ? regions.filter((region) => region !== value) : [...regions, value]);
    }
  };

  const handleClearRegions = () => {
    setRegions([]);
  };

  const handleSelectWebsite = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    setSelectedWebsite(selectedKeys);
    getVendorWebsiteDetailsData(key);
    setActivePageUrls([]);
  };

  const exportCsv = () => {
    vendorDetailsExport({
      name: data?.name,
      vendorId: data?.vendor_id,
      dateFrom: moment(selectedFilters?.start).format('YYYY-MM-DD'),
      dateTo: moment(selectedFilters?.end).format('YYYY-MM-DD'),
      metric: metric,
      region: regions.join(';'),
      website: selectedWebsite.join(';'),
    });
  };

  const genExtra = (prevalence) => <div>{Math.round(+prevalence * 100)}%</div>;
  const handleChangeActivePageUrls = (key) => {
    setActivePageUrls(key);
  };

  const regionsProps = useMemo(() => {
    const isAllSelected = regions.length === plainRegionList?.length;
    const extractZoneFromRegion = (region) => region.split(':')[0];
    const uniqueZones = new Set(regions?.map(extractZoneFromRegion));

    let isUniqueZoneSelected = false;
    let uniqueZone = '';

    if (uniqueZones.size === 1) {
      const selectedZone = [...uniqueZones][0];
      const elementsFromSelectedZone = plainRegionList.filter((region) => region.startsWith(`${selectedZone}:`));
      const allFromZoneSelected = elementsFromSelectedZone.every((element) => regions.includes(element));

      if (allFromZoneSelected) {
        isUniqueZoneSelected = true;
        uniqueZone = selectedZone;
      }
    }

    const maxTagPlaceholder = (omitted) => {
      if (isAllSelected) {
        return 'All';
      }

      if (isUniqueZoneSelected) {
        return uniqueZone;
      }

      return `+${omitted.length}...`;
    };

    const filterOption = (input, option) => {
      const isFound =
        option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      return isFound;
    };

    return {
      selected: [...regions, ...(isAllSelected ? ['All'] : [])],
      maxTagCount: isAllSelected || isUniqueZoneSelected ? 0 : 1,
      maxTagPlaceholder,
      filterOption,
    };
  }, [regionsList, regions]);

  return (
    <>
      <button className={'anchor-button'} onClick={showModal}>
        View
      </button>
      <Modal
        wrapClassName={'avocado-modal vendor-evidence-modal'}
        closeIcon={<CloseOutlined style={{ color: 'blue' }} />}
        title={
          <div>
            Vendor details - <b>{data?.name}</b>{' '}
            <span>
              [{moment(selectedFilters?.start).format('ll')} - {moment(selectedFilters?.end).format('ll')}]
            </span>
          </div>
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={850}
      >
        <Spin spinning={vendorDetailsPending} size="small">
          {!_.isEmpty(vendorDetails) ? (
            <div>
              <div className={'info-block'}>
                <div className={'info-block-title'}>Technology Categorization</div>
                <div className={'info-block-content'}>{vendorDetails?.technologies?.join(', ')}</div>
              </div>
              {/*<div className={'info-block'} style={{display: 'flex'}}>*/}
              {/*    <div style={{marginRight: '20px'}}>*/}
              {/*        <div className={'info-block-title'}>Prevalence (my properties)</div>*/}
              {/*        <div className={'info-block-content'}>{data?.prevalence}%</div>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*        <div className={'info-block-title'}>Prevalence (benchmark)</div>*/}
              {/*        <div className={'info-block-content'}>{data?.prevalence_benchmark}%</div>*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className={'info-block info-block-flex'}>
                <div style={{ maxWidth: '35%' }}>
                  <div className={'info-block-title'}>Privacy Policy</div>
                  <div>
                    <a
                      className={'privacy-policy-link'}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={vendorDetails?.policy_url}
                    >
                      {vendorDetails?.policy_url}
                    </a>
                  </div>
                </div>
                {vendorDetails?.iab_id ? (
                  <div>
                    <div className={'info-block-title'}>IAB GVL ID</div>
                    <div>{vendorDetails?.iab_id ? vendorDetails?.iab_id : 'n/a'}</div>
                  </div>
                ) : null}
                <div>
                  {regions.length && selectedWebsite.length ? (
                    <Tooltip
                      title={() => {
                        return (
                          <div className="vendor-details-tooltip">
                            To export data to CSV about this vendor on this website & region including information about
                            the cookie path and query click here
                          </div>
                        );
                      }}
                      overlayClassName="diagnose--tooltip vendor-details-tooltip-overlay"
                    >
                      <Button
                        icon={<DownloadOutlined />}
                        onClick={exportCsv}
                        loading={vendorDetailsExportPending}
                        className={'download-csv-btn'}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div className={'detailed-info-block'}>
                <div className={'detailed-info-filters'}>
                  <div className={'detailed-info-regions-filter'}>
                    <div style={{ marginBottom: '8px' }}>
                      <b>REGIONS ({regions?.length}) </b>
                      <Tooltip
                        title={() => {
                          return (
                            <div className="vendor-details-tooltip">
                              {' '}
                              Select the regions you would like to see a detailed breakdown of this vendor on.
                            </div>
                          );
                        }}
                        overlayClassName="diagnose--tooltip vendor-details-tooltip-overlay"
                      >
                        <InfoCircleFilled style={{ marginLeft: '5px', color: '#808080' }} />
                      </Tooltip>
                    </div>
                    <DashboardSelectDropdown
                      options={regionsList}
                      selected={regionsProps.selected}
                      onSelect={handleSelectRegions}
                      onClear={handleClearRegions}
                      placeholder={'Regions'}
                      maxTagCount={regionsProps.maxTagCount}
                      tagRender={regionsTagRenderer(regionsList)}
                      maxTagPlaceholder={regionsProps.maxTagPlaceholder}
                      filterOption={regionsProps.filterOption}
                    />
                  </div>
                  <div className={'detailed-info-websites-filter'}>
                    <div style={{ marginBottom: '8px' }}>
                      <b>WEBSITES ({vendorDetails?.websites ? vendorDetails.websites.length : '0'})</b>
                      <Tooltip
                        title={() => {
                          return (
                            <div className="vendor-details-tooltip">
                              Select the website to show on which urls we have located this vendor.
                            </div>
                          );
                        }}
                        overlayClassName="diagnose--tooltip vendor-details-tooltip-overlay"
                      >
                        <InfoCircleFilled style={{ marginLeft: '5px', color: '#808080' }} />
                      </Tooltip>
                    </div>
                    <div className={'detailed-info-websites-filter-items'}>
                      {vendorDetails?.websites?.length ? (
                        <Menu onSelect={handleSelectWebsite} selectedKeys={selectedWebsite}>
                          {vendorDetails?.websites?.map((website) => (
                            <Menu.Item key={website.website_id} icon={<GlobalOutlined />} title={website.website_url}>
                              {website.website_name}
                            </Menu.Item>
                          ))}
                        </Menu>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={'detailed-info-results'}>
                  <Spin spinning={vendorWebsiteDetailsPending} size="small">
                    {vendorWebsiteDetails?.length ? (
                      <div>
                        <div className={'detailed-info-results-header'}>
                          <div>
                            <b>PAGE URLS ({vendorWebsiteDetails?.length})</b>
                            <Tooltip
                              title={() => {
                                return (
                                  <div className="vendor-details-tooltip">
                                    Each page url corresponds to a url where we have located this vendor on your
                                    website. Expand the selection to show the host domains we have found that have been
                                    associated with this vendor. Host domains are servers that are making network calls
                                    to your website that we have mapped with this vendor. You are able to see more
                                    information, including the full URL path used by the vendor using the “Download All”
                                    button above
                                  </div>
                                );
                              }}
                              overlayClassName="diagnose--tooltip vendor-details-tooltip-overlay"
                            >
                              <InfoCircleFilled style={{ marginLeft: '5px', color: '#808080' }} />
                            </Tooltip>
                          </div>
                          <div>
                            <b>PREVALENCE</b>
                          </div>
                          {/*<div style={{width: '50%'}}>*/}
                          {/*    <Input placeholder="Search" prefix={<SearchOutlined />} />*/}
                          {/*</div>*/}
                        </div>
                        <div className={'detailed-info-results-websites'}>
                          <Collapse activeKey={activePageUrls} onChange={handleChangeActivePageUrls}>
                            {vendorWebsiteDetails?.map((website, index) => (
                              <Panel
                                key={`website-item-${index}`}
                                header={`${website.url} (${website.hosts.length})`}
                                extra={genExtra(website.prevalence)}
                              >
                                {website.hosts.map((host, index) => (
                                  <div key={host} className={'detailed-info-host-item'}>
                                    {host}
                                  </div>
                                ))}
                              </Panel>
                            ))}
                          </Collapse>
                        </div>
                      </div>
                    ) : (
                      <Empty
                        imageStyle={{ height: 60 }}
                        description={'Please select any website to view more information about this vendor'}
                      />
                    )}
                  </Spin>
                </div>
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = function (store, props) {
  if (props.metric === 'Vendors after opt out') {
    return {
      vendorDetails: store.diagnoseDashboardState.getIn(['vendorsAfterOptOutVendorData', 'value']),
      vendorDetailsPending: store.diagnoseDashboardState.get('vendorsAfterOptOutVendorDataPending'),
      vendorDetailsExportPending: store.diagnoseDashboardState.get('vendorsAfterOptOutWebsitesExportPending'),
      vendorWebsiteDetails: store.diagnoseDashboardState.getIn(['vendorsAfterOptOutWebsites', 'value']).results,
      vendorWebsiteDetailsPending: store.diagnoseDashboardState.get('vendorsAfterOptOutWebsitePending'),
      selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
      regionsData: store.diagnoseDashboardState.getIn(['regionsList', 'value']),
    };
  }
  return {
    vendorDetails: store.diagnoseDashboardState.getIn(['vendorDetails', 'value']),
    vendorDetailsPending: store.diagnoseDashboardState.get('vendorDetailsPending'),
    vendorDetailsExportPending: store.diagnoseDashboardState.get('vendorDetailsExportPending'),
    vendorWebsiteDetails: store.diagnoseDashboardState.getIn(['vendorWebsiteDetails', 'value']),
    vendorWebsiteDetailsPending: store.diagnoseDashboardState.get('vendorWebsiteDetailsPending'),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
    regionsData: store.diagnoseDashboardState.getIn(['regionsList', 'value']),
  };
};
const mapDispatchToProps = (dispatch, props) => {
  if (props.metric === 'Vendors after opt out') {
    return bindActionCreators(
      {
        getVendorDetails: getVendorAfterOptOutVendorData,
        getVendorWebsiteDetails: getVendorAfterOptOutVendorWebsite,
        vendorDetailsExport: exportCSVVendorsAfterOptOutVendor,
        vendorWebsiteDetailsClear: vendorsAfterOptOutVendorWebsiteClear,
        vendorDetailsClear: vendorsAfterOptOutVendorDataClear,
      },
      dispatch,
    );
  }
  return bindActionCreators(
    {
      getVendorDetails,
      getVendorWebsiteDetails,
      vendorDetailsClear,
      vendorWebsiteDetailsClear,
      vendorDetailsExport,
    },
    dispatch,
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorEvidenceModal);
