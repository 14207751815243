import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';

import Tooltip from '../../common/Tooltip.jsx';
import PartitionsetTableRow from './PartitionsetTableRow.js.jsx';
import { Message } from '../../../records/message_records';
import { Scenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2';

export default class extends React.Component {
  static propTypes = {
      partitionsetData: PropTypes.object,
      manifestId: PropTypes.number,
      campaignRowStats: PropTypes.object,
      messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
      scenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
      active: PropTypes.bool,
  };

  render() {
      return (
          <div>
            <h5>Partition Set: { this.props.partitionsetData.name }</h5>
            <table className="tiny table partitionset">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Bucket Range</th>
                  <th>Partition %</th>
                  <th>Scenario</th>
                  <th><Tooltip hover="Num Pageviews" ># PV</Tooltip></th>
                  <th><Tooltip hover="Num Messages Shown" ># MSG</Tooltip></th>
                  <th><Tooltip hover="Adblock Rate" >AB%</Tooltip></th>
                  <th></th>
                </tr>
              </thead>
                { this.props.partitionsetData.partitions.map(function(partition, i) {
                    var statsForRow = this.props.campaignRowStats && this.props.campaignRowStats[partition.uuid]
                    var key = this.props.manifestId + '_' + i;
                    return (
                      <PartitionsetTableRow
                        key={ key }
                        {...(_.omit(this.props, ['partitionsetData', 'scenarios', 'stats']))}
                        row={ partition }
                        scenario={ this.props.scenarios.find(scenario => scenario.id === partition.scenario_id) }
                        statsForRow={ statsForRow }
                      />
                    )
                }.bind(this))}
            </table>
          </div>
      );
  }
}
