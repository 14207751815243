import { Record, List, fromJS } from 'immutable';

import SessionSearchReferrerGate from './components/SessionSearchReferrerGate';
import SessionSocialReferrerGate from './components/SessionSocialReferrerGate';
import SearchReferrerGate from './components/SearchReferrerGate';
import SocialReferrerGate from './components/SocialReferrerGate';
import CustomCompGate from './components/CustomCompGate';
import OptInRecoveryGate from './components/OptInRecoveryGate';
import Countries from './components/Countries';
import USStates from './components/USStates';
import ConsentGate from './components/ConsentGate';
import ConsentGateV2 from './components/ConsentGateV2';
import ConsentGateCCPA from './components/ConsentGateCCPA';
import ConsentGateUSNAT from './components/ConsentGateUSNAT';
import ConsentGatePreferences from './components/ConsentGatePreferences';
import AdblockGate from './components/AdblockGate';
import DesktopGate from './components/DesktopGate';
import AltpayGate from './components/AltpayGate';
import SambaGate from './components/SambaGate';
import WelectGate from './components/WelectGate';
import ClientIPGate from './components/ClientIPGate';
import RegexMatchGate from './components/RegexMatchGate';
import MatchIntRange from './components/MatchIntRange';
import MatchStringValue from './components/MatchStringValue';

import ShowMessageOnce from './components/ShowMessageOnce';
import ShowMessageAlways from './components/ShowMessageAlways';
import MessageFreqCap from './components/MessageFreqCap';
import MessageShowNumTimes from './components/MessageShowNumTimes';
import MessageSessionNumLimit from './components/MessageSessionNumLimit';

import DelayNumPageviews from './components/DelayNumPageviews';
import DelayCircularRepeat from './components/DelayCircularRepeat';
import DelaySeconds from './components/DelaySeconds';

import { 
  ContinueRecord,
  ContinueOnSatisfyRecord,
  RegexMatchRecord,
  MessageIdRecord,
  ShowMessageFreqCapRecord,
  IntRangeKVRecord,
  StringValueKVRecord,
  ShowMessageNTimesRecord,
  GeoGateRecord,
  USStateGateRecord,
  IPGateRecord,
  PageviewsRecord,
  TimeDelayRecord,
  SessionMessageNLimit,
  ConsentData,
  ConsentDataV2,
  ConsentDataCCPA,
  ConsentDataUSNAT,
  ConsentDataPreferences,
  allEEA,
  allEU,
} from '../../../../constants.js';



export class CountriesRecord extends Record({
  data: GeoGateRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
  category: 'condition',
  toBackendStep() {
    const updatedData = Object.assign({}, this.data);
    let updatedList = this.data.geo_list.toString();
    if (updatedList.includes('allEEA')) {
      updatedList = updatedList.replace('allEEA', allEEA);
    }
    if (updatedList.includes('allEU')) {
      updatedList = updatedList.replace('allEU', allEU);
    }
    updatedData.geo_list = updatedList;
    return {
      class: this.className,
      data: updatedData,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      let geoList = updatedObject.data.geo_list;
      if (geoList.includes(allEEA)) {
        geoList = geoList.replace(allEEA,'allEEA');
      }
      if (geoList.includes(allEU)) {
        geoList = geoList.replace(allEU,'allEU');
      }
      const geoListArray = object.data.geo_list === '' ?
        List([]) :
        fromJS(geoList
          .split(',')
          .filter( v => v != '' ));


      updatedObject.data = GeoGateRecord({
        continue: object.data.continue,
        geo_list: geoListArray,
      });
    }

    super(updatedObject);
  }
}

export const allStates = 'AL,AK,AS,AZ,AR,CA,CO,CT,DE,DC,FM,FL,GA,GU,HI,ID,IL,IN,IA,KS,KY,LA,ME,MH,MD,MA,MI,MN,MS,MO,MT,NE,NV,NH,NJ,NM,NY,NC,ND,MP,OH,OK,OR,PW,PA,PR,RI,SC,SD,TN,TX,UT,VT,VI,VA,WA,WV,WI,WY';

export class USStatesRecord extends Record({
  data: USStateGateRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.USStateGate',
  category: 'condition',
  toBackendStep() {
    const updatedData = Object.assign({}, this.data);
    let updatedList = this.data.state_list.toString();
    if (updatedList.includes('All')) {
      updatedList = allStates;
    }
    updatedData.state_list = updatedList;
    return {
      class: this.className,
      data: updatedData,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      let stateList = updatedObject.data.state_list;
      if (stateList.includes('All') || stateList === allStates) {
        stateList = 'All';
      }
      const stateListArray = object.data.state_list === '' ?
        List([]) :
        fromJS(stateList
          .split(',')
          .filter( v => v != '' ));


      updatedObject.data = USStateGateRecord({
        continue: object.data.continue,
        state_list: stateListArray,
      });
    }

    super(updatedObject);
  }
}

export class ClientIPGateRecord extends Record({
  data: IPGateRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ClientIPGate',
  category: 'condition',
  toBackendStep() {
    const updatedData = Object.assign({}, this.data);
    const updatedList = this.data.ip_list.toString();
    updatedData.ip_list = updatedList;
    return {
      class: this.className,
      data: updatedData,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      const IPListArray = object.data.ip_list === '' ? List([]) : fromJS(object.data.ip_list.split(','));

      updatedObject.data = IPGateRecord({
        continue: object.data.continue,
        ip_list: IPListArray,
      });
    }

    super(updatedObject);
  }
}

export class IntRangeKVGateRecord extends Record({
  data: IntRangeKVRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.IntRangeKVGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = IntRangeKVRecord(object.data);
    }

    super(updatedObject);
  }
}

const matchStringValueRecordDefaults = {
  data: StringValueKVRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate', // keep this hardcoded, we will manipulate this.className
      data: this.data,
    };
  },
};

const matchStringValueRecordClassFactory = (recordDefaults) => {
  return class MatchStringValueRecord extends Record(recordDefaults) {
    constructor(object) {
      let updatedObject;
  
      if (object) {
        updatedObject = Object.assign({}, object);
        updatedObject.data = StringValueKVRecord(object.data);
      }
  
      super(updatedObject);
    }
  }
}

export class ConsentGateRecord extends Record({
  data: ConsentData(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentData(object.data);
    }

    super(updatedObject);
  }
}

export class ConsentGateRecordV2 extends Record({
  data: ConsentDataV2(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentDataV2(object.data);
    }

    super(updatedObject);
  }
}

export class ConsentGateRecordCCPA extends Record({
  data: ConsentDataCCPA(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentDataCCPA(object.data);
    }

    super(updatedObject);
  }
}

export class ConsentGateRecordUSNAT extends Record({
  data: ConsentDataUSNAT(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateUSNAT',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentDataUSNAT(object.data);
    }

    super(updatedObject);
  }
}

export class ConsentGateRecordPreferences extends Record({
  data: ConsentDataPreferences(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGatePreferences',
  category: 'condition',
  toBackendStep() {
    return {
      class: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ConsentDataPreferences(object.data);
    }

    super(updatedObject);
  }
}

export class RegexMatchGateRecord extends Record({
  data: RegexMatchRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = RegexMatchRecord(object.data);
    }

    super(updatedObject);
  }
}

export class AdblockGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class DesktopGateRecord extends Record({
  data: ContinueOnSatisfyRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueOnSatisfyRecord(object.data);
    }

    super(updatedObject);
  }
}

export class AltpayGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.AltPayCompGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SambaGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SambaCompGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class WelectGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.WelectCompGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SessionSearchReferrerGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SessionSearchReferrerGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SessionSocialReferrerGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SessionSocialReferrerGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SearchReferrerGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SearchReferrerGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class SocialReferrerGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SocialReferrerGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class CustomCompGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.CustomCompGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class OptInRecoveryGateRecord extends Record({
  data: ContinueRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ChoiceOptionOIRGate',
  category: 'condition',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ContinueRecord(object.data);
    }

    super(updatedObject);
  }
}

export class ShowMessageAlwaysRecord extends Record({
  data: MessageIdRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = MessageIdRecord(object.data);
    }

    super(updatedObject);
  }
}

export class ShowMessageOnceRecord extends Record({
  data: MessageIdRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = MessageIdRecord(object.data);
    }

    super(updatedObject);
  }
}

export class MessageFreqCapRecord extends Record({
  data: ShowMessageFreqCapRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ShowMessageFreqCapRecord(object.data);
    }

    super(updatedObject);
  }
}

export class MessageShowNumTimesRecord extends Record({
  data: ShowMessageNTimesRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = ShowMessageNTimesRecord(object.data);
    }

    super(updatedObject);
  }
}

export class MessageSessionNumLimitRecord extends Record({
  data: SessionMessageNLimit(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit',
  category: 'message',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = SessionMessageNLimit(object.data);
    }

    super(updatedObject);
  }
}

export class DelayNumPageviewsRecord extends Record({
  data: PageviewsRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews',
  category: 'delay',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = PageviewsRecord(object.data);
    }

    super(updatedObject);
  }
}

export class DelayCircularRepeatRecord extends Record({
  data: PageviewsRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.ModNDelay',
  category: 'delay',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = PageviewsRecord(object.data);
    }

    super(updatedObject);
  }
}

export class DelaySecondsRecord extends Record({
  data: TimeDelayRecord(),
  className: 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay',
  category: 'delay',
  toBackendStep() {
    return {
      class: this.className,
      data: this.data,
    };
  },
}) {
  constructor(object) {
    let updatedObject;

    if (object) {
      updatedObject = Object.assign({}, object);
      updatedObject.data = TimeDelayRecord(object.data);
    }

    super(updatedObject);
  }
}

export const COUNTRIES = {
  label: 'Country',
  component: Countries,
  Record: CountriesRecord,
};

export const STATES = {
  label: 'US State',
  component: USStates,
  Record: USStatesRecord,
};

export const CONSENT_GATE = {
  label: 'Consent Gate',
  component: ConsentGate,
  Record: ConsentGateRecord,
};

export const CONSENT_GATE_V2 = {
  label: 'Consent Gate (TCF v2)',
  component: ConsentGateV2,
  Record: ConsentGateRecordV2,
};

export const CONSENT_GATE_CCPA = {
  label: 'Consent Gate (U.S.Privacy)',
  component: ConsentGateCCPA,
  Record: ConsentGateRecordCCPA,
};

export const CONSENT_GATE_USNAT = {
  label: 'Consent Gate (U.S. MultiState Privacy)',
  component: ConsentGateUSNAT,
  Record: ConsentGateRecordUSNAT,
};

export const CONSENT_GATE_PREFERENCES = {
  label: 'Consent Gate (Preferences)',
  component: ConsentGatePreferences,
  Record: ConsentGateRecordPreferences,
};

export const ADBLOCK_GATE = {
  label: 'Adblock',
  component: AdblockGate,
  Record: AdblockGateRecord,
};

export const DESKTOP_GATE = {
  label: 'Device',
  component: DesktopGate,
  Record: DesktopGateRecord,
};

export const ALTPAY_GATE = {
  label: 'Altpay',
  component: AltpayGate,
  Record: AltpayGateRecord,
};

export const SAMBA_GATE = {
  label: 'Samba',
  component: SambaGate,
  Record: SambaGateRecord,
};

export const WELECT_GATE = {
  label: 'Welect',
  component: WelectGate,
  Record: WelectGateRecord,
};

export const SESSION_SEARCH_REFERRER_GATE = {
  label: 'Session Search Referrer Gate',
  component: SessionSearchReferrerGate,
  Record: SessionSearchReferrerGateRecord,
};

export const SESSION_SOCIAL_REFERRER_GATE = {
  label: 'Session Social Referrer Gate',
  component: SessionSocialReferrerGate,
  Record: SessionSocialReferrerGateRecord,
};

export const SEARCH_REFERRER_GATE = {
  label: 'Search Referrer Gate',
  component: SearchReferrerGate,
  Record: SearchReferrerGateRecord,
};

export const SOCIAL_REFERRER_GATE = {
  label: 'Social Referrer Gate',
  component: SocialReferrerGate,
  Record: SocialReferrerGateRecord,
};

export const CUSTOM_COMP_GATE = {
  label: 'Custom Comp Gate',
  component: CustomCompGate,
  Record: CustomCompGateRecord,
};

export const OPT_IN_RECOVERY_GATE = {
  label: 'Opt In Recovery Gate',
  component: OptInRecoveryGate,
  Record: OptInRecoveryGateRecord,
};

export const MESSAGE_ALWAYS = {
  label: 'Show Message Always',
  component: ShowMessageAlways,
  Record: ShowMessageAlwaysRecord,
};

export const MESSAGE_ONCE = {
  label: 'Show Message Once',
  component: ShowMessageOnce,
  Record: ShowMessageOnceRecord,
};

export const MESSAGE_FREQ_CAP = {
  label: 'Message Frequency Cap',
  component: MessageFreqCap,
  Record: MessageFreqCapRecord,
};

export const MESSAGE_SHOW_NUM_TIMES = {
  label: 'Show Message Num Times',
  component: MessageShowNumTimes,
  Record: MessageShowNumTimesRecord,
};

export const MESSAGE_SESSION_NUM_LIMIT = {
  label: 'Session Message Number Limit',
  component: MessageSessionNumLimit,
  Record: MessageSessionNumLimitRecord,
};

export const DELAY_NUM = {
  label: 'Number of Pageviews Delay',
  component: DelayNumPageviews,
  Record: DelayNumPageviewsRecord,
};

export const DELAY_CIRCULAR_REPEAT = {
  label: 'Number of Times Repeat',
  component: DelayCircularRepeat,
  Record: DelayCircularRepeatRecord,
};

export const DELAY_TIME = {
  label: 'Time Delay',
  component: DelaySeconds,
  Record: DelaySecondsRecord,
};

export const CLIENT_IP_GATE = {
  label: 'Client IP List',
  component: ClientIPGate,
  Record: ClientIPGateRecord,
};

export const URL_REGEX_MATCH = {
  label: 'Page URL Regex Match',
  component: RegexMatchGate,
  Record: RegexMatchGateRecord,
};

export const INT_RANGE_KV_GATE = {
  label: 'Integer In Range',
  component: MatchIntRange,
  Record: IntRangeKVGateRecord,
};


export const STRING_MATCH_KV_GATE = {
  label: 'String Match',
  component: MatchStringValue,
  Record: matchStringValueRecordClassFactory(matchStringValueRecordDefaults),
};

export const SCENARIO_STEPS = {
  'com.sourcepoint.msgsvc.scenario.steps.GeoGate': COUNTRIES,
  'com.sourcepoint.msgsvc.scenario.steps.USStateGate': STATES,
  'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate': ADBLOCK_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate': DESKTOP_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.AltPayCompGate': ALTPAY_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.SambaCompGate': SAMBA_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.WelectCompGate': WELECT_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGate': CONSENT_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2': CONSENT_GATE_V2,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA': CONSENT_GATE_CCPA,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateUSNAT': CONSENT_GATE_USNAT,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGatePreferences': CONSENT_GATE_PREFERENCES,
  'com.sourcepoint.msgsvc.scenario.steps.SessionSearchReferrerGate': SESSION_SEARCH_REFERRER_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.SessionSocialReferrerGate': SESSION_SOCIAL_REFERRER_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.SearchReferrerGate': SEARCH_REFERRER_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.SocialReferrerGate': SOCIAL_REFERRER_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.CustomCompGate': CUSTOM_COMP_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ChoiceOptionOIRGate': OPT_IN_RECOVERY_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ClientIPGate': CLIENT_IP_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch': URL_REGEX_MATCH,
  'com.sourcepoint.msgsvc.scenario.steps.IntRangeKVGate': INT_RANGE_KV_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate': STRING_MATCH_KV_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways': MESSAGE_ALWAYS,
  'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce': MESSAGE_ONCE,
  'com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes': MESSAGE_SHOW_NUM_TIMES,
  'com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap': MESSAGE_FREQ_CAP,
  'com.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit': MESSAGE_SESSION_NUM_LIMIT,
  'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews': DELAY_NUM,
  'com.sourcepoint.msgsvc.scenario.steps.ModNDelay': DELAY_CIRCULAR_REPEAT,
  'com.sourcepoint.msgsvc.scenario.steps.TimeDelay': DELAY_TIME
};

// Enumerate our system key vals
// Add to this list to create a gate with system defined key, and system limited options
export const systemKeyVals = {
  '_sp_idfa_status' : { 
    featureFlag: 'apple_messaging',
    label: 'IDFA Gate', 
    options: [
      { label: 'Unset (No response registered)', value: 'unknown' },
      { label: 'Yes (Tracking allowed)', value: 'accepted' },
      { label: 'No (Tracking not allowed)', value: 'denied' }
    ]
  }
}

// For every system key val, create a scenario step where the user chooses from our options
Object.entries(systemKeyVals).forEach(([key, { label }]) => {
  let className = `com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate:${key}`;
  SCENARIO_STEPS[className] = {
    label: label,
    component: MatchStringValue,
    Record: matchStringValueRecordClassFactory({
      ...matchStringValueRecordDefaults,
      data: StringValueKVRecord({k: key}),
      className,
    }),
  }
});

export const LIST_OF_STEP_TYPES = fromJS(Object.keys(SCENARIO_STEPS));
