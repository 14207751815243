import React from 'react';

export function displayErrors(errors) {
  return (
    <div>
      {
        errors.map(m => {
          if (typeof m === 'string') {
            return <div><p>{ m }</p></div>;
          } else {
            return (
              <div className='mult-errors'>
                {
                  m.map((err,i) => {
                    if (i === m.size - 1) {
                      return <p>{ err }</p>;
                    } else {
                      return <p>{`- ${err}`}</p>;
                    }
                  })
                }
              </div>
            );
          }
        })
      }
    </div>
  );
}
