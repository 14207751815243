import { List, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';
import { Standard, Rule, TopTenRule } from '../../records/standard_records';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;
// const permissionsSvcBaseUrl = 'http://localhost:5000'

const getBaseUrl = () => {
  return `${permissionsSvcBaseUrl}/consent-quality`;
};

export function getRulesAjax() {
  const url = '/rule';
  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp.map(r => new Rule(r) ));
    });
}

export function getTopTenRulesAjax() {
  const url = '/rule/top-ten';

  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        return List(resp.map(r => new TopTenRule(r) ));
      });
  }
}

export function getStandardsAjax(accountId, omitScores, refetch) {
  const dateStandardUpdated = localStorage.getItem('dateStandardUpdated');
  let url = `/standard/list?accountId=${accountId}'`
  if (!omitScores) url += `&includeScores=true`;
  if (dateStandardUpdated) url += `&dateStandardUpdated=${dateStandardUpdated}`;
  if (!refetch && isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        let standards = resp.map(s => new Standard(s))
        return List(standards);
      });
  }
}

export const getStandardsApi = async (accountId) => {
  const dateStandardUpdated = localStorage.getItem('dateStandardUpdated');
  let url = `/standard/list?accountId=${accountId}'`
  if (dateStandardUpdated) url += `&dateStandardUpdated=${dateStandardUpdated}`;
  const resp = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json());
  return resp;
}

export function createStandardAjax(accountId, standard) {
  if (standard.share === "SYSTEM") {
    standard = standard.set('isSystemStandard', false);
    standard = standard.set('share', 'WITH_NAME');
  }
  const data = parseStandardData(accountId, standard, true).delete('id');

  return fetch(getBaseUrl() + '/standard?includeScores=true', {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      localStorage.setItem('dateStandardUpdated', JSON.stringify(new Date()));
      return new Standard(resp);
    });
}

export function updateStandardAjax(accountId, standard) {
  const data = parseStandardData(accountId, standard).set('_id', standard.id).delete('id');
  return fetch(getBaseUrl() + '/standard?includeScores=true', {
    method: 'PUT',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      localStorage.setItem('dateStandardUpdated', JSON.stringify(new Date()));
      return new Standard(resp);
    });
}

export function deleteStandardAjax(accountId, id) {
  return fetch(getBaseUrl() + `/standard?standardId=${id}`, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
}

function parseStandardData(accountId, standard, newStandard) {
  const updatedRules = standard.rules.map(r => r.set('rule', r.rule.id));
  let updateStandard = standard.toMap().set('rules', updatedRules).set('accountId', accountId);
  if (newStandard) {
    updateStandard = updateStandard.delete('appliedToDomainSetIds').delete('appliedToDomainSets').set('dateUpdated', '');
  }
  return updateStandard;
}

