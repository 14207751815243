import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import Label from '../../../common/Label.js.jsx';
import { DelayNPageviews } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js'

export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(DelayNPageviews).isRequired,
        onChange: PropTypes.func.isRequired
    };

    state = {
        num_pv: this.props.data.num_pv
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            this.setState({
                num_pv: nextProps.data.num_pv,
            })
        }
    }

    componentDidMount() {
        if (this.props.editing) ReactDOM.findDOMNode(this.refs.num).focus();
    }

    handleChange = (event) => {
        var value = parseInt(event.target.value);

        if (isNaN(value)) {
            this.setState({ num_pv: '' })
        } else {
            this.setState({ num_pv: value });
            this.props.onChange(this.props.data.set('num_pv', value));
        }
    };

    render() {
        if (!this.props.editing) {
            return (<div>N Pageviews: { this.state.num_pv || this.props.data.num_pv }</div>);
        } else {
            return (
                <div>
                  <label>N Pageviews:</label>
                  <input ref="num" type="text" value={ this.state.num_pv } onChange={ this.handleChange }></input>
                </div>
            );
        }
    }
}
