import * as types from '../actions/action_types';

import { Map, List, OrderedSet } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';
import moment from 'moment';

const initialState = Map({
  vendorList: new StoreRecord({ value: List([]) }),
  purposes: new StoreRecord({ value: List([]) }),
  stacks: new StoreRecord({ value: List([]) }),
  mspsSystemVendors: new StoreRecord({ value: OrderedSet([]) }),
  savePending: false
});

export default (state = initialState, action) => {

  const lists = state.getIn(['vendorList', 'value']);
  let updatedList;
  let indexOfUpdatedRegulation;
  switch(action.type) {
    case types.US_PRIVACY_ACCOUNT_LIST_PENDING:
      return setPending(state, ['vendorList']);

    case types.REGULATION_CREATED:
      updatedList = lists.push(action.regulation).sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated)));
      return setValue(state, ['vendorList'], updatedList).set('savePending', false);
    
    case types.REGULATION_DELETED:
      updatedList = lists.filter(l => l.id !== action.id);
      return setValue(state, ['vendorList'], updatedList);

    case types.REGULATION_UPDATED:
      indexOfUpdatedRegulation = lists.findIndex((regulation) => regulation.id == action.regulation.id)
      updatedList = lists.set(indexOfUpdatedRegulation, action.regulation)
      return setValue(state, ['vendorList'], updatedList).set('savePending', false);
      
    case types.US_PRIVACY_ACCOUNT_LIST_LOADED:
      return setValue(state, ['vendorList'], action.accounts.sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated))));

    case types.REGULATION_LOADED:
      indexOfUpdatedRegulation = lists.findIndex((regulation) => regulation.id == action.usPrivacyRegulation.id)
      updatedList = lists.set(indexOfUpdatedRegulation, action.usPrivacyRegulation)
      return setValue(state, ['vendorList'], updatedList);

    case types.US_PRIVACY_ACCOUNT_LIST_ERROR:
      return setError(state, ['vendorList'], action.error).set('savePending', false);;

    case types.US_PRIVACY_ACCOUNT_LIST_CACHED:
      return stopPending(state, [action.property]);

    case types.REGULATION_ERROR:
      return setError(state, ['vendorList'], action.error).set('savePending', false);

    case types.CLEAR_REGULATIN_ERROR:
      return setError(state, ['vendorList'], null)

    case types.REGULATION_SAVE_PENDING:
      return state.set('savePending', true);

    case types.SYSTEM_PURPOSES_PENDING:
      return setPending(state, ['purposes']);

    case types.SYSTEM_PURPOSES_LOADED:
      return setValue(state, ['purposes'], action.purposes);

    case types.SYSTEM_PURPOSES_ERROR:
      return setError(state, ['purposes'], action.errorMessage);

    case types.SYSTEM_STACKS_PENDING:
      return setPending(state, ['stacks']);

    case types.SYSTEM_STACKS_LOADED:
      return setValue(state, ['stacks'], action.stacks);

    case types.SYSTEM_STACKS_ERROR:
      return setError(state, ['stacks'], action.errorMessage);

    case types.MSPS_SYSTEM_VENDORS_PENDING:
      return setPending(state, ['mspsSystemVendors']);

    case types.MSPS_SYSTEM_VENDORS_LOADED:
      return setValue(state, ['mspsSystemVendors'], action.vendors);
  }
  return state;
  };
  