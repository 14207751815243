import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router';

import { Button } from '../../../../styleguide';

const getStatus = (status) => {
  return {
    new: <span className="diagnose-notification-status new" />,
  }?.[status];
};

const getDate = (date) => {
  const DATE_FORMAT = 'MMMM D, YYYY hh:mm a';
  return date ? moment(date).format(DATE_FORMAT) : undefined;
};

// TODO: Vendor Trace finish expiration
export const NotificationDetailsPage = ({
  date,
  title,
  description,
  status = null,
  type,
  onNotificationClick,
  link,
}) => {
  return (
    <Link className="diagnose-notification_details" to={link} onClick={onNotificationClick}>
      <div className="heading">
        <p className="title">{title}</p>
        <div>
          {type}
          {getStatus(status)}
        </div>
      </div>
      <div className="content">
        <span className="description">{description}</span>
        <span className="date">{getDate(date)}</span>
      </div>
    </Link>
  );
};

NotificationDetailsPage.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  type: PropTypes.node.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  link: PropTypes.shape({}),
  status: PropTypes.string,
};

NotificationDetailsPage.defaultProps = {
  status: undefined,
  link: {},
};

// TODO: Vendor Trace finish expiration
export const NotificationDetailsPopover = ({
  date,
  title,
  description,
  status,
  type,
  onNotificationClick,
  link = { pathname: '/', query: { metric: '', vendor: '', date: '', property: '' } },
}) => {
  const descriptionRef = useRef(null);
  const [isReadMoreActive, setReadMoreActive] = useState(0); /* 0 -hide, 1 - active, truncated, 2 - active, full */

  useEffect(() => {
    setReadMoreActive(Number(descriptionRef.current.scrollWidth > descriptionRef.current.clientWidth));
  }, [descriptionRef]);

  const isExpanded = isReadMoreActive === 2;
  const descriptionStyle = {
    whiteSpace: isExpanded ? 'normal' : 'nowrap',
    overflow: isExpanded ? 'visible' : 'hidden',
    textOverflow: isExpanded ? 'clip' : 'ellipsis',
  };

  const handleReadMore = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setReadMoreActive(isReadMoreActive === 2 ? 1 : 2);
  };

  return (
    <Link className="diagnose-popover-notification_details" to={link} onClick={onNotificationClick}>
      <div className="heading gap">
        <p className="title">{title}</p>
        <div>{getStatus(status)}</div>
      </div>
      <div className="gap">{type}</div>
      <div className="content gap">
        <span className="description" style={descriptionStyle} ref={descriptionRef}>
          {description}
        </span>
      </div>
      <div className="footer-container">
        <span className="date">{getDate(date)}</span>
        {isReadMoreActive !== 0 && (
          <Button onClick={handleReadMore} type="link" size="small">
            {isReadMoreActive === 2 ? 'Read less' : 'Read more'}
          </Button>
        )}
      </div>
    </Link>
  );
};

NotificationDetailsPopover.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  type: PropTypes.node.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  link: PropTypes.shape({}),
  status: PropTypes.string,
};

NotificationDetailsPopover.defaultProps = {
  status: undefined,
  link: {},
};
