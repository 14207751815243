import PropTypes from 'prop-types';
import React from 'react';

import { StringValueKVRecord, systemKeyVals } from '../../../../../scenario_constants.js';

import CustomKeyValue from './MatchStringValue/CustomKeyValue';
import SystemKeyValue from './MatchStringValue/SystemKeyValue';

export default class MatchStringValue extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(StringValueKVRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    if(this.props.data.k && systemKeyVals[this.props.data.k]) {
      let systemKeyVal = systemKeyVals[this.props.data.k];
      return (
        <SystemKeyValue 
          { ...this.props } 
          label={  systemKeyVal.label } 
          options={ systemKeyVal.options ? systemKeyVal.options : [] } 
        />);
    }
    return (<CustomKeyValue { ...this.props } />);
  }
}
