import * as types from './action_types';
import { getAndParseWhitelistGraphData } from '../api/whitelist';

const whitelistGraphPending = () => ({
  type: types.WHITELIST_PENDING,
});

const whitelistGraphLoaded = data => ({
  type: types.WHITELIST_LOADED,
  data,
});

const whitelistGraphLoadError = errorMessage => ({
  type: types.WHITELIST_ERROR,
  errorMessage,
});

export const getWhitelistGraphData = (data) => {
  return (dispatch) => {
    dispatch(whitelistGraphPending());
    return getAndParseWhitelistGraphData(data).then(data => {
      dispatch(whitelistGraphLoaded(data));
    }).catch(reason => {
      return dispatch(whitelistGraphLoadError(reason));
    });
  };
};
