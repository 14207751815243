import PropTypes from 'prop-types';
import React from 'react';
import { is } from 'immutable';
import moment from 'moment';
import { Modal, Button, Radio, Select, Switch, Input } from 'antd';
import { Vendor } from '../../../../records/vendor_list_records';
import { VendorWrapper } from '../../../../records/consent_compensation_records';
import HistoryTable from './HistoryTable';
const Option = Select.Option;

export default class EditVendorModal extends React.Component {
  static propTypes = {
    vendorWrapper: PropTypes.instanceOf(VendorWrapper).isRequired,
    vendor: PropTypes.instanceOf(Vendor).isRequired,
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    setChangedVendorState: PropTypes.func.isRequired,
  }

  state = {
    currentState: this.props.currentState,
  }

  handleCancelModalChanges = () => {
    this.setState({ currentState: this.props.currentState });
    this.props.closeModal();
  }

  handleApply = () => {
    if (this.hasChanges()) {
      this.props.setChangedVendorState(this.props.vendorWrapper.vendorId, this.state.currentState);
    }
    this.props.closeModal();
  }

  hasChanges = () => {
    const updated = this.deleteIncomparableHistoryVariables(this.state.currentState)
    const previous = this.deleteIncomparableHistoryVariables(this.props.currentState)
    return !is(updated, previous);
  }

  deleteIncomparableHistoryVariables = (h) => {
    return h.toMap().delete('id').delete('startDate').delete('endDate');
  }

  onStatusChange = () => {
    this.setState({ currentState: this.state.currentState.update('isActive', s => !s) })
  }

  onChange = (e, propName, value) => {
    value = value || e.target.value;
    this.setState({ currentState: this.state.currentState.set(propName, value) });
  }

  render() {
    return (
      <Modal
        onOk={ this.handleApply }
        okText='Save'
        visible={ true }
        onCancel={ this.handleCancelModalChanges }
        className='edit-vendor-compensation'
        closable={ false }
        destroyOnClose
      >
          
        <p className='title'>{ this.props.vendor.name }</p>

        <div className='container'>

          <div className='row-1 flex-row'>
            <div className='vendor-id'>Vendor ID: <span className='bold'>{ this.props.vendorWrapper.vendorId }</span></div>
            <div>
              Active: <Switch checked={ this.state.currentState.isActive } onChange={ this.onStatusChange } />
            </div>
          </div>

          <div className='flex-row row-2'>
            <div className='start-date flex-row'>
              Start Date:  <span className='bold'>{ moment(this.state.currentState.startDate).format('lll') || "n/a" }</span>
            </div>
            <div className='category flex-row'>
              Category:  
              <Select
                value={ this.state.currentState.category }
                onChange={ (e) => this.onChange(null, 'category', e) }
              >
                <Option value='DATA_PARTNER' key='DATA_PARTNER'>Data Partner</Option>
                <Option value='SSP'>SSP</Option>
                <Option value='DSP'>DSP</Option>
              </Select>
            </div>
          </div>

          <div className='deal-info flex-row'>

            <div className='flex-row'>
              <p>Deal Type:</p>
              <Radio.Group onChange={ (e) => this.onChange(e, 'dealType') } value={ this.state.currentState.dealType }>
                <Radio value='DIRECT'>Direct</Radio>
                <Radio value='PROGRAMMATIC'>Programmatic</Radio>
              </Radio.Group>
            </div>

            <div className='flex-row'>
              <p>Rate Type:</p>
              <Radio.Group onChange={ (e) => this.onChange(e, 'rateType') } value={ this.state.currentState.rateType }>
                <Radio value='CPM'>CPM</Radio>
                <Radio value='PERCENTAGE'>Percentage(%)</Radio>
              </Radio.Group>
            </div>

            {
              this.state.currentState.rateType === 'CPM' ?

                (<div className='flex-row'>
                  <p>Currency:</p>
                  <Select onChange={ (e) => this.onChange(e, 'currency', e) } value={ this.state.currentState.currency }>
                    <Select.Option key='DOLLARS'>Dollars</Select.Option>
                    <Select.Option key='EUROS'>Euros</Select.Option>
                    <Select.Option key='POUNDS'>Pounds</Select.Option>
                  </Select>
                  <Input onChange={ (e) => this.onChange(e, 'cpmAmount') } value={ this.state.currentState.cpmAmount } />
                </div>) :

                (<div className='flex-row'>
                  <p>Percent:</p>
                  <Input onChange={ (e) => this.onChange(e, 'percentage') } value={ this.state.currentState.percentage } />
                </div>)
            }

          </div>
          
          { 
            this.props.vendor.description ?
              (<div className="description">
                <p className="title-2">Description</p>
                <p>{ this.props.vendor.description }</p>
              </div>) :
              null
          }
          
          { 
            this.props.vendorWrapper.history.size > 1 ?
              (<div className="history">
                <p className="title-2">History</p>
                <HistoryTable history={ this.props.vendorWrapper.history.pop() } />
              </div>) :
              null
          }
        </div>

      </Modal>
    );
  }
}