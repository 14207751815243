export const styles = {
  stepHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '12px'
  },
  stepContent: {
    background: '#F1F3F6',
    flexDirection: 'column',
    alignItems: 'center'
  },
  stepLayout: {
    minHeight: 'calc(100vh - 68px)',
    zIndex: -1,
    padding: '10px 15px',
  },
  stepInnerLayout: {
    display: 'flex',
    flexDirection: 'row'
  },
  scenarioTags: {
    background: '#E2F4E4',
    color: '#272727',
    borderColor: 'transparent',
    padding: '0px 7px',
    marginBottom: '7px',
    fontSize: '12px',
    lineHeight: '28px'
  },
  scenarioTagMsg: {
    background: '#E2F4E4',
    color: '#272727',
    borderColor: 'transparent',
    padding: '0px 7px',
    marginBottom: '7px',
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '28px'
  },
  previewIcon: {
    background:"#E9DFFF"
  },
  conditionHeader: {
    background: '#F9FAFB',
    padding: '10px',
    fontSize: '18px',
    fontWeight: '500'
  },
  collapseStepLayout: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 12px',
    background: '#fff'
  },
  conditionDiv: {
    marginTop: '5px'
  },
  tagStyle: {
    backgroundColor: '#E0EDFE',
    color: '#1C6BE3',
    margin: '5px'
  },
  emptyConditionStyle: {
    color: '#1C6BE3',
    fontSize: '11px',
    marginLeft: '5px'
  },
  errorConditionStyle: {
    color: 'red',
    fontSize: '12px',
    fontWeight: '600',
    marginLeft: '5px',
    cursor: 'default'
  },
  iconStyle: {
    position: 'relative',
    left: '98%'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  btnStyle: {
    width: '150px'
  },
  selectContainerMessageDelay: {
    width: 'auto'
  },

  selectContainerMessageDelivery: {
    width: '22%'
  },
  selectContainerMsgCapAndLimit: {
    width: '20%'
  },
  btnAddNewMessage: {
    marginLeft: '5px'
  },
  dividerStyle: {
    height: '180px'
  },
  astrik: { color: 'red', fontSize: '15px' },
  editNameIcon: { marginLeft: '5px' },
  showMore: {
    border: '1px solid rgb(24, 144, 255)',
    padding: '0px 2px',
    color: '#1C6BE3'
  },

  templateHeaderBtn: {
    border: '1px solid #D9D9D9',
    color: '#000000A6',
    marginRight: '5px',
    marginBottom: '0px'
  },
  conditionInCard: {
    padding: '16px',
    background: '#fff',
    borderRadius: '18px',
  },
};
