import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
    };

    getDefaulProps = () => {
        return {
            className: "",
            onClick: function() {}
        };
    };

    render() {
        return (
            <th colSpan={ this.props.colSpan } onClick={ this.props.onClick } className={ ("table-cell-header " + this.props.className).trim() }>
              { this.props.children }
            </th>
        )
    }
}
