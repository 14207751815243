import PropTypes from 'prop-types';
import React from 'react';

import CommonNumCount from './CommonNumCount';
import MessageStep from './MessageStep';
import { ShowMessageFreqCapRecord } from '../../../../../scenario_constants.js';

export default class MessageFreqCap extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(ShowMessageFreqCapRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleNumChange = (value, propType) => {
    this.props.onChange(this.props.data.set(propType, value));
  }

  render() {
    const label = 'Show message ' + this.props.data.n + ' times every ' + this.props.data.t + ' seconds';
    return (
      <MessageStep
        {...this.props}
        label={ label }
        className='message-freq-cap'
      >
        { this.props.children }
        <CommonNumCount
          value={ this.props.data.n }
          onChange={ (value) => this.handleNumChange(value, 'n') }
          label={ 'max' }
          className='limit'
        />
        <CommonNumCount
          value={ this.props.data.t }
          onChange={ (value) => this.handleNumChange(value, 't') }
          label={ 'times every' }
          className='freq'
        />
        <label className='seconds'>seconds</label>
        <label className='show-message'>Show Message</label>
      </MessageStep>
    );
  }
}
