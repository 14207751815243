/**
 * Finds the value associated with a given hash key in an object.
 * If the hash key is found, the corresponding value is returned.
 * If the hash key is not found, the function returns the value associated with the "default" key in the object.
 *
 * @param {string} hash - The hash key to search for in the sectionIndices object.
 * @param {object} sectionIndices - An object containing hash keys and their corresponding values.
 * @returns {*} - The value associated with the hash key if found, otherwise the value associated with the "default" key.
 */
const getSectionByHash = (hash, sectionIndices) => {
  return sectionIndices[hash] || sectionIndices.default;
};

export const defaultDetailedPageSectionIndices = {
  '#overview': ['0'],
  '#details': ['1'],
  default: ['1'],
};

export default getSectionByHash;
