import React, { Component } from 'react';
import { Input } from 'antd';

class FourOptions extends Component {
  updateValue = (index, amount) => {
    let values = this.props.value.split(' ');
    values[index] = amount;
    this.props.updateSetting(this.props.settingKey, values.join(' '));
  }
  render() {
    const values = this.props.value.split(' ');
    return (
      <div className="four-options">
        <h4>{this.props.settingsTitle}</h4>
        <div className="option-inputs">
          <div>
            <Input value={values[0]} onChange={(e) => this.updateValue(0, e.target.value)} />
            <h5>Top</h5>
          </div>
          <div>
            <Input value={values[1]} onChange={(e) => this.updateValue(1, e.target.value)} />
            <h5>Right</h5>
          </div>
          <div>
            <Input value={values[2]} onChange={(e) => this.updateValue(2, e.target.value)} />
            <h5>Bottom</h5>
          </div>
          <div>
            <Input value={values[3]} onChange={(e) => this.updateValue(3, e.target.value)} />
            <h5>Left</h5>
          </div>
        </div>
      </div>
    );
  }
}

export default FourOptions;
