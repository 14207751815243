import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Collapse } from 'antd';

import VariableList from './VariableList';

import { SiteVariables, MessageVariables, IdNameValue } from '../../../../../../records/message_records';

const SitePanel = ({ 
  id, 
  domain, 
  variables,
  updateVariables,
  handleDelete,
  handleBlur
}) => {
  const overrides = variables.get('sitesVariables').find(site => site.get('siteId') === id);
  let vars = variables.get('defaultVariables');
  if (overrides) {
    vars = variables.defaultVariables.map(defaultVariable => {
      const override = overrides.variables.find(variable => variable.get('name') === defaultVariable.get('name'))
      const value = override ? override.get('value') : defaultVariable.get('value');
      const name = defaultVariable.get('name');
      return new Map({ name, value });
    })
  };
  return (
    <Collapse.Panel key={domain} header={domain}>
      <VariableList
        variableList={vars}
        handleChange={handleSiteVariableChange(id)}
        handleBlur={handleBlur}
        handleDelete={handleDelete}
      />
    </Collapse.Panel>
  );

  function handleSiteVariableChange(id) {
    const site = variables
      .get('sitesVariables')
      .find(site => site.get('siteId') === id)
      || new SiteVariables({ siteId: id });

    return ({ value, variableName }) => {
      const indexOfSite = variables.get('sitesVariables').findIndex(site => site.get('siteId') === id);
      const updatedSiteVariablesList = site.get('variables').push(new IdNameValue({ name: variableName, value }).toMap().delete('id'));
      const updatedSite = site.set('variables', updatedSiteVariablesList);

      let newlyUpdatedVariables;
      if (indexOfSite === -1) {//no custom vars for this site
        newlyUpdatedVariables = variables.setIn(['sitesVariables', variables.get('sitesVariables').size], updatedSite);
      } else {//custom vars for this site
        const indexOfVariable = site.get('variables').findIndex(variable => variable.get('name') === variableName);
        if (indexOfVariable === -1) {//specified custom var doesn't exist
          newlyUpdatedVariables = variables.setIn(['sitesVariables', indexOfSite, 'variables'], updatedSiteVariablesList);
        } else {//specified custom var does exist
          newlyUpdatedVariables = variables.setIn(['sitesVariables', indexOfSite, 'variables', indexOfVariable, 'value'], value);
        }
      }
      updateVariables(newlyUpdatedVariables);
    }
  }
};

SitePanel.propTypes = {
  id: PropTypes.number.isRequired,
  domain: PropTypes.string.isRequired,
  variables: PropTypes.instanceOf(MessageVariables).isRequired,
  updateVariables: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
};

export default SitePanel;