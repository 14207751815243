import 'whatwg-fetch';
import { List } from 'immutable';
import { checkStatus } from '../helper.js';
import { SubjectAccessRecord } from '../../records/dsar_records';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const ccpaBaseUrl = `${permissionsSvcBaseUrl}/ccpa`;

export function getDsarRequestsAjax(accountId) {
  const url = `/subject-access-request/dsar/${accountId}`;

  return fetch(ccpaBaseUrl + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp.map(obj => new SubjectAccessRecord(obj)));
    });
}

export function getRequestFileAjax(accountId, siteId, requestId) {
  // const fileName = requestId + '_dsar'
  const url = `/subject-access-request/secure-storage/document?siteId=${siteId}&requestId=${requestId}`;
  let filename;
  return fetch(ccpaBaseUrl + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => {
      const disposition = resp.headers.get('Content-Disposition')

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) { 
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      return resp.blob()
    })
    .then(blob => {
      // 1. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      
      link.setAttribute('download', filename);
      // 2. Append to html page
      document.body.appendChild(link);
      // 3. Force download
      link.click();
      // 4. Clean up and remove the link
      link.parentNode.removeChild(link);
    }).catch(e => {
      console.error(e)
    });
}

export function completeRequestAjax(requestId) {
  const url = `/subject-access-request/dsar/set_status_completed/${requestId}`;
  return fetch(ccpaBaseUrl + url, { 
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new SubjectAccessRecord(resp);
    });
}