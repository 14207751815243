import React from 'react'
import { Icon} from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export default function TooltipContents({message}) {
  const {nameLong, invalidCharacters, duplicateProperty, emptyType, invalidType, OttFeatureNotEnabled} = message;
  return (
    <>
    {nameLong &&<> <CloseOutlined color='red'/>{nameLong}<br /></>}
    {invalidCharacters && <> <CloseOutlined color='red'/>{invalidCharacters}<br /></>}
    {duplicateProperty && <> <CloseOutlined color='red'/>{duplicateProperty}<br /></>}
    {emptyType && <> <CloseOutlined color='red'/>{emptyType}<br /></>}
    {invalidType && <> <CloseOutlined color='red'/>{invalidType}</>}
    {OttFeatureNotEnabled && <><CloseOutlined color='red'/>{OttFeatureNotEnabled}</>}
    </>
  )
}
