import PropTypes from 'prop-types';
import React from 'react';
import { InputNumber } from 'antd';

export default class CommonNumCount extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  }

  onChange = (value) => {
    value = parseInt(value);

    if (isNaN(value)) {
      return;
    } else {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <div className={ this.props.className }>
        <label>{ this.props.label }</label>
        <InputNumber
          min={ 0 }
          value={ this.props.value }
          onChange={ this.onChange }
        />
      </div>
    );
  }
}
