import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { List, Map } from 'immutable';
import classNames from 'classnames';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import { Modal, Button, Select, Input, Table, Tabs, Tooltip } from 'antd';
import { Domain } from '../../../records/domain_set_records';
import DomainsFileUpload from '../domain_set/DomainsFileUpload';

const Option = Select.Option;
const Search = Input.Search;
const { TabPane } = Tabs;

export default function NewGoldenSetModal(props) {

  const [goldenSet, setGoldenSet] = useState(props.goldenSet);
  const [addDomainManually, setAddDomainManually] = useState(false);
  const [propertiesInput, setPropertiesInput] = useState('');
  const [search, setSearch] = useState(null);
  const [selectedTab, setSelectedTab] = useState("1");

  const handleCancelModalChanges = () => {
    props.closeModal();
  }

  const handleApply = () => {
    const rules = goldenSet.rules.filter(r => r !== 'all');
    props.setGoldenSet(goldenSet.set('rules', rules));
    props.closeModal();
  }

  const selectRules = (ids) => {
    let rules;
    if (ids.includes('all')) {
      rules = props.rules.push('all');
    } else if (goldenSet.rules.includes('all') && !ids.includes('all')) {
      rules = [];
    } else {
      rules = props.rules.filter(r => ids.includes(r.id));
    }
    setGoldenSet(goldenSet.set('rules', new List(rules)));
  };

  const addProperties = (properties) => {
    setGoldenSet(goldenSet.update('properties', p => p.concat(properties)));
    setPropertiesInput('');
  }

  const addPropertiesManually = (e) => {
    e.stopPropagation();
    const date = new Date();

    const propertiesToAdd = propertiesInput.split(',').map(url => {

      const manualScore = new Map({ 
        scores: new Map({}),
        userId: props.currentUser.id,
        userName: props.currentUser.name,
        dateScored: date,
      });

      return new Domain({ url, manualScore });
    });

    addProperties(propertiesToAdd);
  }

  const deleteProperty = (url) => {
    const filtered = goldenSet.properties.filterNot(p => p.url === url);
    setGoldenSet(goldenSet.set('properties', filtered));
  }

  const generateTable = () => {

    const columns = List([
      Map({
        title: 'Url',
        dataIndex: 'url',
        key: 'url',
      }),
      Map({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
      }),
    ]);

    let properties = goldenSet.properties;

    if (search) {
      properties = properties.filter(d => d.url.toLowerCase().includes(search.toLowerCase()));
    }

    const dataSource = properties.map((d, i) => {

      return Map({
        key: d.url,
        url: d.url,
        actions: (<div className='actions'>
          <DeleteOutlined onClick={ () => deleteProperty(d.url) } />
        </div>),
      });
    });

    return new Map({ dataSource, columns });
  }

  let addPropertiesContent;
  if (addDomainManually) {
    addPropertiesContent = (
      <div className='manual-add-properties'>
        <p className='properties-title' key='properties-title'>
          { `Properties (${ goldenSet.properties.size} Properties Added)`}
        </p>
        <div className='flex-row' key='flex-row'>
          <p key='add-properties'>Add properties.</p>
          <p className='comment' key='comment'>(To add multiple properties separate by comma.)</p>
        </div>
        <div className='flex-row' key='flex-row2'>
          <Input 
            value={ propertiesInput }
            onChange={ (e) => setPropertiesInput(e.target.value) }
          />
          <button 
            onClick={ (e) => addPropertiesManually(e) }
            className={ addDomainManually ? 'selected' : null}
          >
            Add
          </button>
        </div>
      </div>
    )
  } else {
    addPropertiesContent = <DomainsFileUpload addDomains={ addProperties } goldenSet currentUser={ props.currentUser } />;
  }

  const tableData = generateTable();

  return (
    <Modal
      onOk={ handleApply }
      okText='Apply'
      okButtonProps={{ disabled: !goldenSet.rules.size || !goldenSet.properties.size }}
      onCancel={ handleCancelModalChanges }
      className='new-domain-set new-rule golden-set'
      closable={ false }
      destroyOnClose
      visible
    >
      <p className='title'>Select Rules/Properties</p>
        <div className='modal-container'>

        <Tabs defaultActiveKey="1" onChange={ (tab) => { setSelectedTab(tab)}}>
          <TabPane tab="Add Rules" key="1">
            <div className='add-rules-container'>
              <div className='left' key='left'>
                <div className='rules'>
                  <div><p>Rules <span>{ `(${goldenSet.rules.filter(r => r !== 'all').size} Selected)` }</span></p></div>
                  <Select
                    value={ goldenSet.rules.map(r => r === 'all' ? r : r.id).toJS() }
                    onChange={ selectRules }
                    mode='multiple'
                    tokenSeparators={[',']}
                    open
                    dropdownClassName={classNames('golden-set-rules', {'hidden': selectedTab === '2'})}
                  >
                    <Option value={'all'} key={'all'}>Select All</Option>
                    { props.rules.map(r => (
                      r === 'all' ? null : <Option value={r.id} key={r.id}>{ r.name }</Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Add Properties" key="2">
            <div className='add-domains-container'>

              <Button 
                onClick={ () => setAddDomainManually(false) }
                className={ addDomainManually ? null : 'selected' }
              >
                Import File
              </Button>
              <Button 
                onClick={() => setAddDomainManually(true) }
                className={ addDomainManually ? 'selected' : null }
              >
                + Add Manually
              </Button>
              { addPropertiesContent }
                <div className='table-box-shadow'>
                  <div className='search-container'>
                    <Search
                      placeholder="Search Property..."
                      onChange={ (e) => { setSearch(e.target.value) }}
                      className="vendor-filter"
                      style={{ width: 190 }}
                    />
                  </div>
                  <Table
                    className='domain-sets-table'
                    bordered={ false }
                    dataSource={ tableData.get('dataSource').toJS() }
                    columns={ tableData.get('columns').toJS() }
                  />
                </div>
              </div>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
}