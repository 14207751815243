import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Checkbox, Divider, Button } from 'antd';
import SVG from 'react-inlinesvg';

import unreviewedStatusIcon from '../../../../assets/icons/unreviwed-status.svg';
import underReviewStatusIcon from '../../../../assets/icons/under-review-status.svg';
import allowedStatusIcon from '../../../../assets/icons/allowed-status.svg';
import actionRequiredStatusIcon from '../../../../assets/icons/action-required.svg';
import allowedRiskStatusIcon from '../../../../assets/icons/allowed-risk-status.svg';

import { setVendorStatusFilterList } from '../../../../actions/diagnose_dashboard_actions';
import { plainOptionsStatuses, plainOptionsTags } from './helpers';

const VendorStatusFilters = ({ setVendorStatusFilterList }) => {
  const [checkedStatusesList, setCheckedStatusesList] = useState([]);
  const [checkedTagsList, setCheckedTagsList] = useState([]);
  const flattenedData = [...checkedStatusesList, ...checkedTagsList];
  const indeterminate = !!(flattenedData.length && flattenedData.length < 6);
  const checkAll = flattenedData.length === 6;

  const onChangeStatus = (statusesList) => {
    setCheckedStatusesList(statusesList);
  };
  const onChangeTag = (tagsList) => {
    setCheckedTagsList(tagsList);
  };
  const onCheckAllChange = (e) => {
    setCheckedStatusesList(e.target.checked ? plainOptionsStatuses : []);
    setCheckedTagsList(e.target.checked ? plainOptionsTags : []);
  };

  const onSubmitFilter = () => {
    setVendorStatusFilterList(flattenedData.length ? flattenedData : [...plainOptionsStatuses, ...plainOptionsTags]);
  };

  const onResetFilter = () => {
    setVendorStatusFilterList([...plainOptionsStatuses, ...plainOptionsTags]);
    setCheckedStatusesList([]);
    setCheckedTagsList([]);
  };

  return (
    <div className={'vendor-status-filter-container'}>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        <span style={{ marginLeft: '28px' }}>All</span>
      </Checkbox>
      <Checkbox.Group onChange={onChangeStatus} value={checkedStatusesList}>
        <Checkbox value="Unreviewed">
          <SVG src={unreviewedStatusIcon} />
          Unreviewed
        </Checkbox>
        <Checkbox value="Under Review">
          <SVG src={underReviewStatusIcon} />
          Under Review
        </Checkbox>
        <Checkbox value="Approved">
          <SVG src={allowedStatusIcon} />
          Approved
        </Checkbox>
        <Checkbox value="Action Required">
          <SVG src={actionRequiredStatusIcon} />
          Action Required
        </Checkbox>
        <Checkbox value="Approved with risk">
          <SVG src={allowedRiskStatusIcon} />
          Approved with risk
        </Checkbox>
      </Checkbox.Group>
      <Divider />
      <Checkbox.Group onChange={onChangeTag} value={checkedTagsList}>
        <Checkbox value="New">
          <div className={'vendor-status-tag'} style={{ backgroundColor: '#00B0FF' }} />
          New
        </Checkbox>
        <Checkbox value="Change Detected">
          <div className={'vendor-status-tag'} style={{ backgroundColor: '#FFD500' }} />
          Change detected
        </Checkbox>
      </Checkbox.Group>
      <Divider />
      <div className={'vendor-status-filter-buttons'}>
        <Button onClick={onResetFilter}>Reset</Button>
        <Button type={'primary'} onClick={onSubmitFilter}>
          Save
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    vendorStatusFilterList: store.diagnoseDashboardState.getIn(['vendorStatusFilterList', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setVendorStatusFilterList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorStatusFilters);
