import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router';
import classNames from 'classnames';
import { DownOutlined } from '@ant-design/icons';

import NavbarItem from './NavbarItem.jsx';
import NavbarDropdownMenu from './NavbarDropdownMenu.jsx';
import CautionBar from './CautionBar';

import { User } from '../../records/account_records';
import { getUnresolvedIncidentCount } from '../../api/status';
import { useInterval } from '../../hooks';
import { Dropdown, Menu } from 'antd';
import { accountHasPrivacyLens } from '../../util';

const StatusNavItem = ({ active }) => {
  const [incident, setIncident] = useState(false);

  const fetchIncidents = async () => {
    try {
      const result = await getUnresolvedIncidentCount();
      setIncident(result.length > 0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => { fetchIncidents(); });
  // Every 2 minutes
  useInterval(() => { fetchIncidents(); }, 120 * 1000);

  return (
    <NavbarItem
      className={classNames('status', { 'status-incident': incident })}
      href='/status'
      text='Status'
      active={active}
    />
  );
}

export default class Navbar extends React.Component {
  static propTypes = {
    currentUser: PropTypes.instanceOf(User).isRequired,
    isMasq: PropTypes.bool.isRequired,
    isLocalEnv: PropTypes.bool.isRequired,
    isCanary: PropTypes.bool.isRequired,
  }

  isActive(link) {
    if (link === '/') {
      if (window.location.pathname === '/') {
        return true;
      } else {
        return false;
      }
    }
    if (link === 'consent' && window.location.pathname === '/consent_performance') {
      return false;
    } else if (link === 'consent' && window.location.pathname.includes('/consent_v2')) {
      return false;
    }
    else {
      return (
        window.location.pathname.includes(link)
        && !window.location.pathname.includes('messages')
        && !window.location.pathname.includes('datarequest')
        && !window.location.pathname.includes('us_privacy_report')
      );
    };
  }

  hasFeature = (feature) => {
    // if (this.props.currentUser.accountId === 22 && feature !== 'recovery') return true;
    return this.props.currentUser.accountFeatures.includes(feature);
  }

  render() {
    const recoveryHref = '/recovery';
    const recoveryLink = this.hasFeature('recovery') ? (
      <NavbarItem
        className='recovery'
        href={recoveryHref}
        text='Recovery'
        active={this.isActive(recoveryHref)}
      />
    ) : null;

    const dialogueHref = '/dialogue/sites';
    const messagingLink = this.hasFeature('messaging') ? (
      <NavbarItem
        className='dialogue'
        href={dialogueHref}
        text='Dialogue'
        active={this.isActive('dialogue')}
      />
    ) : null;

    const CCPAHref = '/us_privacy/vendor_lists';
    const CCPALink = this.hasFeature('ccpa') ? (
      <NavbarItem
        className='CCPA'
        href={CCPAHref}
        text='CCPA'
        active={this.isActive('ccpa')}
      />
    ) : null;

    const sdksHref = '/sdks/sdk_lists';
    const sdksLink = (this.hasFeature('consent') || this.hasFeature('tcf_v2')) && this.hasFeature('sdk') ? (
      <NavbarItem
        className='sdks'
        href={sdksHref}
        text='SDKs'
        active={this.isActive('sdks')}
      />
    ) : null;

    const privacyLensHref = '/privacy_lens/scoring_results';
    const privacyLensLink = accountHasPrivacyLens(this.props.currentUser.accountFeatures) ? (
      <NavbarItem
        className='privacy-lens'
        href={privacyLensHref}
        text='Privacy Lens'
        active={this.isActive('privacy_lens')}
      />
    ) : null;

    const reportsHref = '/';
    const reportsLink = this.props.currentUser.accountId === 22 || !accountHasPrivacyLens(this.props.currentUser.accountFeatures) ? (
      <NavbarItem
        className='reports'
        href={reportsHref}
        text='Reports'
        active={this.isActive(reportsHref) || this.isActive('altpay') || this.isActive('whitelist') || this.isActive('custom_report') || this.isActive('consent_performance') || this.isActive('/')}
      />
    ) : null;

    const statusLink = this.hasFeature('status_page') ?
      <StatusNavItem className='status-item' active={this.isActive('status')} />
      : null;

    const diagnoseDashboardHref = '/diagnose_dashboard';
    const diagnoseDashboardLink = this.props.currentUser.accountId === 22 || this.hasFeature('diagnose_dashboard') ? (
      <NavbarItem
        className="diagnose-dashboard"
        href={diagnoseDashboardHref}
        text="Diagnose Dashboard"
        active={this.isActive('diagnose_dashboard')}
      />
    ) : null;

    const gdprMenu = (
      <Menu>
        {this.hasFeature('tcf_v2') && (
          <Menu.Item>
            <div key="2">
              <Link style={{color: 'black'}} to="/consent_v2/vendor_lists">
                TCF v2
              </Link>
            </div>
          </Menu.Item>
        )}
        {this.hasFeature('non_iab_vl') && (
          <Menu.Item>
            <div key="2">
              <Link style={{ color: 'black' }} to="/non-iab/vendor_lists">
                Non IAB
              </Link>
            </div>
          </Menu.Item>
        )}
      </Menu>
    );

    const gdprActive = this.isActive('consent') || this.isActive('consent_v2') || this.isActive('non-iab');
    const gdprDropdown = (this.hasFeature('consent') || this.hasFeature('tcf_v2')) ? (
      <Dropdown overlay={gdprMenu}>
        <li className={`consent ${gdprActive ? 'active' : null}`} style={{ fontSize: 14 }}>
          <a>
            GDPR <DownOutlined/>
          </a>
        </li>
      </Dropdown>
    ) : null;
    
    return (
      <div className='navbar-container'>
        <CautionBar
          isMasq={!!this.props.isMasq}
          isCanary={!!this.props.isCanary}
          isLocalEnv={!!this.props.isLocalEnv}
          accountName={this.props.currentUser.accountName}
        />
        <nav className='main-navbar'>
          <ul className='dashboard-recovery-message-group'>
            <li className='logo'>
              <a href='/'>
                <Isvg src='/images/SP-icon.svg'>
                  <img src='/images/SP-icon.svg' />
                </Isvg>
              </a>
            </li>
            {reportsLink}
            {recoveryLink}
            {messagingLink}
            {gdprDropdown}
            {CCPALink}
            {sdksLink}
            {privacyLensLink}
            {statusLink}
            {diagnoseDashboardLink}
          </ul>
          <NavbarDropdownMenu
            className='account-group'
            isAdmin={this.props.currentUser.isAdmin}
            isSPUser={this.props.currentUser.accountId === 22}
            featureAccess={this.props.currentUser.featureAccess}
            features={this.props.currentUser.accountFeatures}
            masqed={this.props.currentUser.masqed}
            isMasqAccount={this.props.currentUser.accountId === 375}

          />
        </nav>
      </div>
    );
  }
}
