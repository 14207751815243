import React, { Component } from 'react';
import { Input } from 'antd';

class Pixels extends Component {
  updatePixels = (e) => {
    this.props.updateSetting(this.props.settingKey, e.target.value);
  }
  render() {
    return (
      <div className="pixels">
        <Input type="number" addonAfter="px" value={this.props.value} onChange={this.updatePixels} />
      </div>
    );
  }
}

export default Pixels;
