import { List, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;
const getBaseUrl = () => {
  return `${permissionsSvcBaseUrl}/consent-quality`;
}

export const getSystemAccuracyTimelineAjax = (accountId = 22) => {
  let url = `/accuracy/system/timeline?accountId=${accountId}`;
  
  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json());
}

export const getSystemAccuracyAjax = (accountId = 22) => {
  let url = `/accuracy/system?accountId=${accountId}`;
  
  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json());
}