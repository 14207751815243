import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';

import { ConsentGateUSNAT as ConsentRecord } from './scenario_records_2.js';

const Option = Select.Option;

class ConsentGateUSNAT extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    data: PropTypes.instanceOf(ConsentRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleChangeSatisfy = (continueOnSatisfy) => {
    let data = this.props.data.set('status', continueOnSatisfy);
    this.props.onChange(data);
  }

  static statuses = [
    { value: 0, label: 'No Action' },
    { value: 7, label: 'Vendor List Additions' },
  ];

  render() {
    const continueOnSatisfy = ConsentGateUSNAT.statuses
        .filter(status => status.value === this.props.data.status)
        .map(status => status.label)[0];

    const options = ConsentGateUSNAT.statuses.map(
      status => <Option key={ status.value } value={ status.value }>{ status.label }</Option>);

    if (!this.props.editing) {
      return (
        <div>
          Consent Multi State U.S. Privacy: { continueOnSatisfy }
        </div>
      );
    } else {
      return (
        <div className="step-block-editing">
          { this.props.children }
          <Select
            onChange={ this.handleChangeSatisfy }
            value={ this.props.data.status }
          >
            { options }
          </Select>
        </div>
      );
    }
  }
}

export default ConsentGateUSNAT;
