import React from 'react';
import PropTypes from 'prop-types';
import {
  Table as AntTable,
} from 'antd';
import moment from 'moment';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { History } from '../../../../records/consent_compensation_records';
import { CURRENCY_TO_SYMBOL } from '../../../../constants';

export default class Table extends React.Component {
  static propTypes = {
    history: ImmutablePropTypes.listOf(PropTypes.instanceOf(History)).isRequired,
  };

  render() {
    const columns = List([
      Map({
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      }),
      Map({
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
      }),
      Map({
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
      }),
      Map({
        title: 'Deal Type',
        dataIndex: 'dealType',
        key: 'dealType',
      }),
      Map({
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
      }),
    ]);

    const dataSource = this.props.history.reverse().map((h) => {
      let rate;
      let dealType;
      if (h.isActive) {
        if (h.rateType === 'CPM') {
          rate = CURRENCY_TO_SYMBOL.get(h.currency) + ` ${h.cpmAmount.toString()}`;
        } else {
          rate = `${h.percentage.toString()} %`;
        }
        dealType = h.dealType;
      }

      return (
        Map({
          key: h.id,
          status: <i className={`fas fa-circle ${ h.isActive ? 'green' : 'gray'}`} />,
          startDate: moment(h.startDate).format("MMM Do YY"),
          endDate: h.endDate ? moment(h.endDate).format("MMM Do YY") : '',
          dealType,
          rate,
        })
      )
    });

    return (
      <div className="vendor-edit-table">
        <AntTable
          bordered={ false }
          dataSource={ dataSource.toJS() }
          columns={ columns.toJS() }
        />
      </div>
    );
  }
}
