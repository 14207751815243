import PropTypes from 'prop-types';
import React from 'react'

const sizes = {};
sizes.SMALL = 'btn-sm';
sizes.MEDIUM = 'btn-md';
sizes.LARGE = 'btn-lg';

const types = {};
types.PRIMARY = "btn-primary";
types.DEFAULT = "btn-default";
types.SUCCESS = "btn-success";
types.DANGER = "btn-danger";
types.PLAIN = "btn-plain";

class CustomButton extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        type: PropTypes.oneOf(Object.keys(types).map(key => types[key])),
        size: PropTypes.oneOf([sizes.SMALL, sizes.MEDIUM, sizes.LARGE]),
        className: PropTypes.string,
        disabled: PropTypes.bool,
        htmlType: PropTypes.string,
    };

    static defaultProps = {
        type: types.PRIMARY,
        size: sizes.SMALL,
        className: "",
        disabled: false,
        htmlType: '',
    };

    render() {
        var className = 'btn';

        className = 'btn ' + this.props.type + ' ' + this.props.size + ' ' + this.props.className;
        className.trim();

        return (
            <button className={ className } disabled={ this.props.disabled } onClick={ this.props.onClick } type={ this.props.htmlType }>{ this.props.children }</button>
        )
    }
}

CustomButton.sizes = sizes;
CustomButton.types = types;

export default CustomButton;
