export const campaignTypes = [
  { label: 'GDPR Compliance', value: 1 }, 
  { label: 'Ad-block Messaging', value: 3 },
  { label: 'U.S. Privacy Compliance (legacy)', value: 2 }, 
  { label: 'U.S. MultiState Privacy Compliance', value: 6 },
  { label: 'Preferences', value: 7 },
  { label: 'iOS Tracking Authorization', value: 4 },
  { label: 'Custom Messaging', value: 5 },
  { label: 'Legacy', value: 0 }
];

export const getCampaignType = (campaignTypeId) => {
  return campaignTypes.find(c => c.value === parseInt(campaignTypeId)).label;
}

export const getCampaignNameLatestEnv = (campaignName) => {
    if(campaignName && campaignName?.match(/\(([^)]+)\)/g)){
        const matches = [...campaignName?.match(/\(([^)]+)\)/g)];
        const updatedCampaignName = `${campaignName?.split("(")[0]} ${matches[matches.length - 1]}` 
        return updatedCampaignName
    }
    return campaignName;
}

export const campaignTypesCodes = campaignTypes.reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {});

export const environment = [ { label: 'Public', value: 1 }, { label: 'Stage', value: 2 } ];

export const envOptions = [ { label: 'Public', value: 'public' }, { label: 'Stage', value: 'stage' } ];

export const interval = [ 
  { label: 'Last 7 days', value: 7 }, 
  { label: 'Last Month', value: 30 },
  { label: 'Last 6 Months', value: 180 }, 
  { label: 'Last Year', value: 365 }
];

export const maxMsgs = [ 1, 2, 3, 4, 5 ];

export function hasFeature(currentUser, feature){
  return ( currentUser && (currentUser.accountFeatures.includes(feature)));
};

export function getTypes(currentUser, propertyType, skipLegacy = true){
  /* skipLegacy = true; Will remove CUSTOM campaign types till we start supporting them again*/
  let types = campaignTypes;

  const hasTcfv2 = hasFeature(currentUser, 'tcf_v2');
  const hasccpa = hasFeature(currentUser, 'ccpa');
  const hasusnat = hasFeature(currentUser, 'usnat');
  const hasPreferences = hasFeature(currentUser, 'preferences');
  const hasAdblock = hasFeature(currentUser, 'adblock_v2');
  const hasAppleMessaging = hasFeature(currentUser, 'apple_messaging');

  if(!hasTcfv2) {
	types = types.filter(t => t.value != 1);
  }
  if(!hasccpa) {
	types = types.filter(t => t.value != 2);
  }
  if(!hasusnat) {
    types = types.filter(t => t.value != 6);
  }
  if(!hasPreferences) {
    types = types.filter(t => t.value != 7);
  }
  if(!hasAdblock || propertyType === 'ott' || propertyType === 'app') {
	types = types.filter(t => t.value != 3);
  }
  if(!hasAppleMessaging || propertyType === 'ott' || propertyType === 'web') {
	types = types.filter(t => t.value != 4);
  }
  if(skipLegacy) {
	types = types.filter(t => t.value != 0);
  }

  return types;
}

export function redirectToVl(vl){
    const isNonIab = vl?.nonIab;
    switch(vl.type) {
      case 'tcfv2' :
        if (isNonIab) {
          return `/consent_v2/vendor_lists/edit?id=${vl.vendorListId}&non_iab=true`
        } else {
          return `/consent_v2/vendor_lists/edit?id=${vl.vendorListId}&non_iab=false`
        }
      case 'ccpa' :
        return `/us_privacy/vendor_lists/edit?id=${vl.vendorListId}`
      case 'usnat' :
        return `/us_privacy/regulations/edit?regulationId=${vl.vendorListId}`
      case 'preferences' :
        return `/preferences/edit?configurationId=${vl.configurationId}`
    }
  };
