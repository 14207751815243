import PropTypes from 'prop-types';
import React from 'react';

import { browserHistory } from 'react-router';
import { Modal } from 'antd';
import { Button } from '../../../styleguide';
import { WarningOutlined } from '@ant-design/icons';

export default class DetailStandardModal extends React.Component {
  static propTypes = {
    moveToDraft: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }
  state = {
    linkedDomainSets : this.props.standard.appliedToDomainSetIds?.map(appliedId => this.props.domainSets?.find(ds => ds.id === appliedId || ds._id === appliedId))
  }

  goToPropertySets = () => {
    browserHistory.push(`/privacy_lens/domain_sets?standardId=${this.props.standard.id}`);
  }

  render() {
    const footer = [
      <Button key="1" onClick={this.props.closeModal} className="cancel">
        Cancel
      </Button>
    ];
    if (this.state.linkedDomainSets?.length) {
      footer.push(
        <Button key="3" onClick={ this.goToPropertySets } type="primary">
          Open Property Sets
        </Button>
      );
    } else {
      footer.push(
        <Button key="2" onClick={this.props.moveToDraft} type="primary">
          Move to Drafts
        </Button>
      );
    }

    return (
      <Modal
        onCancel={this.props.closeModal}
        className='purple new-design'
        visible
        footer={footer}
      >
        <p className="title">Standard - Move to Draft</p>

        <div className='modal-container'>
          <p>If you choose to move <span>{this.props.standard.name}</span> standard to draft, you will not be able to apply this to any new property sets.</p>
          {!this.state.linkedDomainSets?.length ? (
            <p>
              Are you sure you want to <span>MOVE TO DRAFT</span>?
            </p>
          ) : (
            <>
              <p>
                First, unlink associated Property sets before you can move
                standard to draft.
              </p>
              <p>
                Press the <span>Open Property Sets</span> button to Open linked
                property sets. Unlink the property sets by changing standard
                applied to the set.
              </p>

              <div className="linked-sets">
                <p>
                  <WarningOutlined />
                  Following Property sets are still linked to the
                  standard.
                </p>
                <div className="property-sets">
                  {this.state.linkedDomainSets?.map((ds) => (
                    <div className="domain-set">{ds.name}</div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }
}
