import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';

import MessageCompensationTiming from './MessageCompensationTiming.jsx';
import AntdFormItem from '../../../common/AntdFormItem.jsx';
import { ChoiceConfigRecord } from '../../../../records/choice_config_records.js';
import { CONSTANTS } from '../../../../constants.js';

class MessageEditSambaOption extends React.Component {
  static propTypes = {
    choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
    editing: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    listIndex: PropTypes.number.isRequired,
    onChoiceConfigChange: PropTypes.func.isRequired,
  }

  onChange(newVal) {
    let optionData = this.props.choiceConfig.getIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA]);
    optionData = optionData.merge(newVal);
    const oldChoiceConfig = this.props.choiceConfig;
    const choiceConfig = oldChoiceConfig.setIn(['option_list', this.props.listIndex, 'option_data'], optionData);
    this.props.onChoiceConfigChange(choiceConfig);
  }

  render() {
    const optionData = this.props.choiceConfig.getIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA]);
    const classes = classNames('label-title');

    return (
      <div className="choice-option">
        <div className={ classes }>
          <AntdFormItem
            fieldLabel="Button Text"
            fieldName={ 'samba_button_text_' + this.props.listIndex }
            form={ this.props.form }
            initialValue={ optionData.get('button_text') }
            onChange={ (event) => this.onChange(Map({ button_text: event.target.value })) }
            placeholder=""
            editing={ this.props.editing }
          />
          <MessageCompensationTiming
            fieldLabel='Compensation Timing'
            fieldName={ 'samba_comp_period_sec_' + this.props.listIndex }
            form={ this.props.form }
            initialValue={ optionData.get('comp_period_sec') }
            onChange={ (event) => this.onChange(Map({ comp_period_sec: event.target.value })) }
            placeholder='120'
            editing={ this.props.editing }
          />
        </div>
      </div>
    );
  }
}

export default MessageEditSambaOption;
