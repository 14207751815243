

import { Record, List, fromJS } from 'immutable';

export const TopTenRule = Record({
  ruleId: null,
});

export class Rule extends Record({
  id: null,
  name: '',
  ruleId: null,
  description: '',
  type: '',
  regulations: new List(['GDPR']),
  productStatus: '',
  supportedRegions: new List(['eu']),
  systemWeight: 0,
  category: '',
}) {
  constructor(rule) {
    const updatedRule = Object.assign({}, rule);

    const regulationsMap = {
      gdpr: 'GDPR',
      GDPR: 'GDPR',
      ccpa: 'CCPA',
      CCPA: 'CCPA',
      ePrivacy: 'ePrivacy',
      eprivacy: 'ePrivacy',
    };
    if (updatedRule.regulations) {
      updatedRule.regulations = rule.regulations.map(r => regulationsMap[r]);
    }
    if (updatedRule.supportedRegions) {
      updatedRule.supportedRegions = rule.supportedRegions;
    }

    if (rule._id) updatedRule.id = rule._id;
    super(fromJS(updatedRule));
  }
}

export class StandardRule extends Record({
  rule: null,
  isActive: true,
  niceToHave: null, 
  weight: 0,
}) {
  constructor(standardRule) {
    const updatedStandardRule = Object.assign({}, standardRule, { isActive: true });
    updatedStandardRule.rule = new Rule(standardRule.rule);
    super(updatedStandardRule);
  }
}

export class Standard extends Record({
  id: null,
  accountId: null,
  name: '',
  description: '',
  type: '',
  rules: List([]),
  isActive: false,
  share: 'WITH_NAME',
  policy: 'GDPR',
  isSystemStandard: false,
  scores: {},
  appliedToDomainSets: [],
  appliedToDomainSetIds: [],
  dateUpdated : ''
}) {
  constructor(standard) {
    const updatedStandard = Object.assign({}, standard);
    if (standard._id) updatedStandard.id = standard._id;

    if (updatedStandard.rules) {
      updatedStandard.rules = List(standard.rules.map(r => new StandardRule(r)));
    }
    super(updatedStandard);
  }
}