import React, { useMemo, useState } from "react";
import { Select } from "../../../styleguide";

const SelectPropertiesPermissions = (props) => {
  
  const SiteGroupIndexMap = useMemo(()=> props.siteGroups.reduce((map, obj) => (map[obj.siteId] = obj, map), {}), props.siteGroups)

  const onChange = (siteIds) => {
    if (Array.isArray(siteIds)) {
      let sitesWithinSelectedGroups = []
      siteIds.forEach((id)=> {
        let siteGroup = SiteGroupIndexMap[id];
  
        if(siteGroup){
          sitesWithinSelectedGroups = sitesWithinSelectedGroups.concat(siteGroup.siteIds?.toJS());
        }
      })
      const newValue = [...new Set(siteIds.concat(sitesWithinSelectedGroups))]
      props.onChange(newValue)
    } else {
      props.onChange([siteIds])
    }
  }

  const onDeselect = (siteId) => {
     let siteGroup = SiteGroupIndexMap[siteId];
     if(siteGroup){
      props.onChange(props.value.filter(id => !siteGroup.siteIds.includes(id) && id !== siteId))
     }
  }

  const options = useMemo(() =>{
    const sanitizedSites = props.sites.toJS().map(site => {
      if(site.type == 'property_group'){
        let siteGroup = props.siteGroups.find(sg => sg.siteId == site.id);
        if(siteGroup){
          site.domain = siteGroup.name;
          return site;
        } else {
          return null;
        }
      }
      return site;
    }).filter( s => !!s);

    let options = [];
    sanitizedSites.forEach(site => {
      const { type, domain, id } = site;
      const OptionGroup = options.find(o => o.label === type);
    
      const newOption = { label: domain, value: id, searchValue: domain?.toLowerCase() };
      
      if (OptionGroup) {
        OptionGroup.options.push(newOption);
      } else {
        const newGroup = { label: type, options: [newOption] };
        type === "property_group" ? options.unshift(newGroup) : options.push(newGroup);
      }
    });
    const siteTypeLabels = {
      "web" : "Web Properties",
      "property_group" : "Grouped properties",
      "ott": "OTT properties",
      "amp": "AMP properties",
      "app": "App properties",
    }

    options = options.map(option => ({ ...option, label: siteTypeLabels[option.label] }));
    options.unshift({label : "Access to all propeties", value: 'all access'});

    return options;

  }, [props.sites, props.siteGroups]);

  return (
    <Select
      value={props.value}
      onChange={onChange}
      onDeselect={onDeselect}
      options={options}
      placeholder={props.placeholder}
      disabled={props.disabled}
      mode={'multiple'}
      showSearch
      optionFilterProp={"searchValue"}
    />
  );
}

export default SelectPropertiesPermissions;
