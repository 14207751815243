import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { Tooltip } from 'antd';

import CustomIcon from '../../../../common/CustomIcon.js.jsx';

import MessageSelector from './MessageSelector';
import { Message } from '../../../../../records/message_records';
// import {
//   MessageIdRecord,
//   ShowMessageFreqCapRecord,
//   ShowMessageNTimesRecord,
// } from '../../../../../scenario_constants.js';
import {
  MessageIdRecord,
  ShowMessageFreqCapRecord,
  ShowMessageNTimesRecord,
} from '../../../../../scenario_constants.js';


export default class MessageStep extends React.Component {
    static propTypes = {
      editing: PropTypes.bool.isRequired,
      data: PropTypes.oneOfType([
        PropTypes.instanceOf(MessageIdRecord),
        PropTypes.instanceOf(ShowMessageFreqCapRecord),
        PropTypes.instanceOf(ShowMessageNTimesRecord),
      ]).isRequired,
      onChange: PropTypes.func.isRequired,
      messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      onShowMessagePreview: PropTypes.func.isRequired,
    };

    static defaultProps = {
      className: '',
    }

    handleChange = (value) => {
      const data = this.props.data.set('message_id', (parseInt(value) || undefined));
      this.props.onChange(data);
    };

    onShowMessagePreview = () => {
      var messageID = this.props.data.message_id;
      const message = this.props.messages.find(message => message.get('id') === messageID);
      browserHistory.push('/dialogue/message_preview?message_id=' + messageID + '&site_id=' + message.site_id);
      this.props.onShowMessagePreview();
    }

    render() {
      var messageID = this.props.data.message_id;
      const message = this.props.messages.find(message => message.get('id') === messageID);

      let previewIcon;
      if (message && message.getIn(['msg_metadata', 'type']) !== "redirect") {
        previewIcon = (
          <CustomIcon
            type={ CustomIcon.types.PREVIEW }
            onClick={ () => this.onShowMessagePreview() }
          />
        );
      }

      const className = classNames('step-block-editing', this.props.className);

      if (!this.props.editing) {
        let fullDescription = 'No message selected';
        let shortDescription = 'No message selected';
        if (message) {
          fullDescription = message.description;
          shortDescription = fullDescription.length > 15 ? fullDescription.slice(0, 15) + '...' : fullDescription;
        }
        return (
          <Tooltip title={ this.props.label + ': ' + fullDescription }>
            <div>
              { this.props.label.slice(0, 20) }: {' '}
              <span className="message-to-preview">
                { shortDescription }
              </span>
              { previewIcon }
            </div>
          </Tooltip>
        );
      } else {
        return (
          <div className={ className }>
            { this.props.children }
            <MessageSelector
              value={ message ? messageID : -1 }
              messages={ this.props.messages }
              onChange={ this.handleChange }
            />
          </div>
        );
      }
    }
}
