import React, { Component } from 'react';
import { CheckSquareOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, List as ListView, Switch, Popover } from 'antd';
import { List, Map } from 'immutable';
import { styles } from './PrivacyStyle';
import { complianceList, privacyManagerComplianceList, ottComplianceList } from '../../../../constants';

export default class PrivacyComplianceModal extends Component {
    constructor(props) {
        super();
        let complianceUpdatedList;
        if ('privacy-manager' === props.messageSubcategory) {
            complianceUpdatedList = privacyManagerComplianceList
        } else if (['native-ott', 'privacy-manager-ott'].includes(props.messageSubcategory)) {
            complianceUpdatedList = ottComplianceList
        } else {
            complianceUpdatedList = complianceList
        }

        this.state = {
            showPopup: false,
            complianceUpdatedList,
            complianceStatus: false
        }
    }

    componentDidMount() {
        const complianceStatus = this.props.complianceStatus;

        const complianceMessages = this.props.complianceMessages;
        let list = this.state.complianceUpdatedList;

        if (complianceMessages != undefined && complianceMessages.length === list.size) {
            complianceMessages.length > 0 && complianceMessages.map((updatedItem, index) => {
                list = list.setIn([index, "status"], updatedItem[index + 1]);
            })
        }
        this.setState({ complianceUpdatedList: list, complianceStatus });
    }

    componentDidUpdate(prevProps) {

        if (this.props.complianceStatus != prevProps.complianceStatus) {
            const complianceStatus = this.props.complianceStatus;
            this.setState({ complianceStatus })
        }
        if (this.props.complianceMessages != prevProps.complianceMessages) {
            const complianceMessages = this.props.complianceMessages;
            let list = this.state.complianceUpdatedList;

            if (complianceMessages != undefined && complianceMessages.length === list.size) {
                complianceMessages.length > 0 && complianceMessages.map((updatedItem, index) => {
                    list = list.setIn([index, "status"], updatedItem[index + 1]);
                })
            }
            this.setState({ complianceUpdatedList: list });
        }
    }

    changeStatus = (id, status) => {
        let list = this.state.complianceUpdatedList;
        var index = list.findIndex(item => item.get('id') === id);

        list = list.setIn([index, "status"], !status);
        this.setState({ complianceUpdatedList: list }, () => {
            let newlist = List()
            this.state.complianceUpdatedList.map((item) => {
                let obj = new Map();
                obj = obj.set(item.get('id'), item.get('status'));
                newlist = newlist.push(obj);
            })
            this.props.saveCompliance(newlist);
        })
    }

    handleVisibleChange = (visible) => this.setState({ showPopup: visible });

    hidePopup = () => this.setState({ showPopup: false })


    render() {
        if (this.props.messageSubcategory === 'us_pm') return null;

        const complianceUpdatedList = this.state.complianceUpdatedList;
        let uncheckedPrivacyCount = complianceUpdatedList.filter((item) => !item.get('status')).size
        let checkedPrivacyCount = complianceUpdatedList.filter((item) => item.get('status')).size
        let completePrivacyChkStatus = (checkedPrivacyCount === complianceUpdatedList.size) ? false : true;
        const btnStyle = (checkedPrivacyCount !== complianceUpdatedList.size) ?
            styles.btnPrivacyStyleOther : (this.state.complianceStatus) ? styles.btnPrivacyStyleConf :
                styles.btnPrivacyStyleUnConf

        const content = (
            <React.Fragment>
                <span style={styles.popupTitle}>Manual Check</span>
                <ListView
                    style={styles.listContentStyle}
                    itemLayout="vertical"
                >
                    {this.state.complianceUpdatedList.map((item, index) => {
                        let privacyItemStatus = item.get('status');
                        return (<ListView.Item key={index} style={(privacyItemStatus) ? styles.itemPaddingActive : styles.itemPaddingInActive}>
                            <div style={styles.listItemContainer}>
                                <div style={styles.listItemContainerDesc}>
                                    <div>
                                        <span style={styles.listItemTitle}>{item.get("title")}</span>
                                    </div>
                                    <span style={styles.listItemDescription}>{item.get("description")}</span>
                                </div>

                                <div style={styles.listItemStatus}>
                                    <Switch checked={item.get('status')} size="small" onChange={() => this.changeStatus(item.get('id'), item.get('status'))} />
                                </div>
                            </div>
                        </ListView.Item>)
                    })}
                </ListView>
                <div style={styles.footerStyle}>
                    <Button onClick={() => {
                        this.handleVisibleChange(false)
                        this.props.handleCompPrivacyModal(true)
                    }} disabled={completePrivacyChkStatus} style={completePrivacyChkStatus ? styles.cmpPrivacyDisabledStyle : styles.cmpPrivacyStyle}>
                        Complete Privacy Check
                    </Button>
                </div>
            </React.Fragment>
        );
        return (
            <Popover
                overlayClassName="message-popover"
                placement="bottom"
                title={<div style={styles.headingTitle}><span style={styles.popupMainTitle}>{this.props.title}</span><CloseOutlined style={styles.closeIcon} onClick={() => this.hidePopup()}></CloseOutlined></div>}
                content={content}
                open={this.state.showPopup}
                onOpenChange={(visible) => this.handleVisibleChange(visible)}
                trigger="click">
                <Button
                    onClick={this.showSaveTemplateModal}
                    className="btn-privacyCheck"
                    style={btnStyle}
                >
                    <div style={styles.checkContainerStyle}>
                        <CheckSquareOutlined style={styles.iconStyle} />
                        <div>Privacy Check</div>
                    </div>
                    {(uncheckedPrivacyCount > 0) && (
                        <span style={styles.circle}>{uncheckedPrivacyCount}</span>
                    )}
                </Button>
            </Popover>
        );
    }
}
