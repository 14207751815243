import React, { Component } from 'react';
import { Tag } from 'antd';

class Chips extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'spsg-chip ' + (props.className || '');

    return <Tag {...props} />;
  }
}

export default Chips;
