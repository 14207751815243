import React from 'react';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import enUS from 'antd/lib/locale/en_US';
import PropTypes from 'prop-types';
import { RangeDates } from '../../records/reports_records.js';
import AntdDatePicker from '../common/AntdDatePicker.jsx';

const { RangePicker } = AntdDatePicker;

export default class CustomRangePicker extends React.Component {
  static propTypes = {
    selectDates: PropTypes.func.isRequired,
    limit: PropTypes.number,
    defaultValue: PropTypes.instanceOf(RangeDates),
    disabled: PropTypes.bool,
    format: PropTypes.string,
    disabledDateOffset: PropTypes.number,
  }

  static defaultProps = {
    limit: Infinity,
    defaultValue: null,
    disabled: false,
    format: 'MMM D, YYYY',
    disabledDateOffset: 0,
  }

  constructor(props) {
    super(props);
  }

  handleRangeChange = (event) => {
    const start = event[0];
    const end = event[1];
    if (end.diff(start, 'days') > this.props.limit) {
      alert(`Please select a range of less than ${ this.props.limit } days`);
    } else {
      this.props.selectDates(new RangeDates({ start, end }) );
    }
  }

  disabledDate = (current) => {
    return moment().subtract(this.props.disabledDateOffset, 'days').diff(current) < 0;
  }

  render() {
    const rangePickerProps = {
      disabled: this.props.disabled,
      allowClear: false,
      size: 'large',
      className: 'range-picker',
      onChange: this.handleRangeChange,
      format: this.props.format,
      disabledDate: this.disabledDate,
    };
    if (this.props.defaultValue) {
      rangePickerProps.defaultValue = [this.props.defaultValue.start, this.props.defaultValue.end];
      rangePickerProps.value = [this.props.defaultValue.start, this.props.defaultValue.end];
    }

    return (
      <ConfigProvider locale={enUS}>
        <RangePicker
          { ...rangePickerProps }
        />
      </ConfigProvider>
    );
  }
}
