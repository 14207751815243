require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch, { retries: 2, retryDelay: 500 });

import { checkStatus, buildUrlWithVersion, DIAGNOSE_DASHBOARD_BASEURL, exportCSVResponse } from '../helper.js';

export function getVendorTracePropertiesData({ dateFrom, dateTo } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-trace/properties/`,
            {
                date_from: dateFrom,
                date_to: dateTo,
            },
            'vendor-trace',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on get 'Vendor Trace Properties' data: status ${err.response.status}`) : err);
        });
}

export function getVendorTraceUrlsData({ dateFrom, dateTo, websiteId } = {}) {
    return fetch(
        buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-trace/urls/`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                website_id: websiteId,
            },
            'vendor-trace',
        ),
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on get 'Vendor Trace Urls' data: status ${err.response.status}`) : err);
        });
}

export function getVendorTraceRegionsData({ dateFrom, dateTo, scheduledScanId } = {}) {
    return fetch(
        `${buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-trace/regions/`,
            {
                date_from: dateFrom,
                date_to: dateTo,
            },
            'vendor-trace',
        )}&${scheduledScanId.map((v) => `scheduled_scan_ids=${v}`).join('&')}`,
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on get 'Vendor Trace Regions' data: status ${err.response.status}`) : err);
        });
}

export function getMaxTraceLevelData({ dateFrom, dateTo, scheduledScanId, regions, consentFilter } = {}) {
    return fetch(
        `${buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-trace/max-trace-level/`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                regions,
            },
            'vendor-trace',
        )}&${
            scheduledScanId.map((v) => `scheduled_scan_ids=${v}`).join('&')
        }&${
            consentFilter.map((v) => `consent_filter=${v}`).join('&')
        }`,
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on get 'Max trace level' data: status ${err.response.status}`) : err);
        });
}

export function getConsentTypesData({ dateFrom, dateTo, scheduledScanId, regions } = {}) {
    return fetch(
        `${buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-trace/consent-categories/`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                regions,
            },
            'vendor-trace',
        )}&${scheduledScanId.map((v) => `scheduled_scan_ids=${v}`).join('&')}`,
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on get 'Consent types' data: status ${err.response.status}`) : err);
        });
}

export function getVendorTraceData({ dateFrom, dateTo, scheduledScanId, traceLevel, regions, consentFilter } = {}) {
    return fetch(
        `${buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-trace/trace-data/`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                trace_level: traceLevel,
                regions,
            },
            'vendor-trace',
        )}&${
            scheduledScanId.map((v) => `scheduled_scan_ids=${v}`).join('&')
        }&${
            consentFilter.map((v) => `consent_filter=${v}`).join('&')
        }`,
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.json())
        .catch((err) => {
            // console.log(err);
            alert(err.response ? (`Error on get 'Vendor Trace Data' data: status ${err.response.status}`) : err);
            throw(err);
        });
}

export function exportVendorDataFromVendorTrace ({
    dateFrom,
    dateTo,
    regions,
    consentFilter,
    scheduledScanId,
    vendorId,
    vendorName,
    mapKey,
    traceLevel,
}) {
    return fetch(
        `${buildUrlWithVersion(
            `${DIAGNOSE_DASHBOARD_BASEURL}/vendor-trace/export/`,
            {
                date_from: dateFrom,
                date_to: dateTo,
                regions,
                vendor_id: vendorId,
                trace_level: traceLevel,
                map_key: mapKey,
            },
            'vendor-trace',
        )}&${
            scheduledScanId.map((v) => `scheduled_scan_ids=${v}`).join('&')
        }&${
            consentFilter.map((v) => `consent_filter=${v}`).join('&')
        }`,
        {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            },
        },
    )
        .then(checkStatus)
        .then((resp) => resp.blob())
        .then(exportCSVResponse(`vendor_trace_(${vendorName})_(${dateFrom}/${dateTo})`))
        .catch((err) => {
            console.log(err);
            alert(err.response ? (`Error on export 'Vendor Data' data: status ${err.response.status}`) : err);
        });
}
