import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const initialState = Map({
  dsarRequests: new StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  let updatedRequests;
  switch(action.type) {

    case types.DSAR_REQUESTS_PENDING:
      return setPending(state, ['dsarRequests']);

    case types.DSAR_REQUESTS_LOADED:
      return setValue(state, ['dsarRequests'], action.requests);

    case types.DSAR_REQUESTS_ERROR:
      return setError(state, ['dsarRequests'], action.errorMessage);

    case types.DSAR_FILE_LOADED:
    case types.DSAR_FILE_UPLOADED:
      updatedRequests = state.get('dsarRequests').value.update(list => {
        return list.map(r => {
          if (r.requestId === action.requestId) {
            return r.set('responseFileStatus', 'UPLOADED');
          } else {
            return r;
          }
        })
      });
      return setValue(state, ['dsarRequests'], updatedRequests);

    case types.DSAR_COMPLETED:
      updatedRequests = state.get('dsarRequests').value.update(list => {
        return list.map(r => {
          if (r.requestId === action.request.requestId) {
            return action.request;
          } else {
            return r;
          }
        })
      })
      return setValue(state, ['dsarRequests'], updatedRequests);
  }

  return state;
};
