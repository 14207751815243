import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';

import Scenario from './Scenario.js.jsx';
import ScenarioV2 from './versionTwo/ScenarioV2';
import { Scenario as ScenarioRecord } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { ScenarioV2 as ScenarioV2Record } from '../../../components/dialogue/scenarios/versionTwo/components/steps';


export default class ScenarioList extends React.Component {
    static propTypes = {
        scenarios: ImmutablePropTypes.orderedSetOf(
          PropTypes.oneOfType([
            PropTypes.instanceOf(ScenarioV2Record),
            PropTypes.instanceOf(ScenarioRecord),
          ]),
        ).isRequired,
    };

    constructor(props) {
      super(props);
      const activeId = localStorage.getItem('activeId') || '';
      this.state = {
        activeId: activeId,
      };
    }

    componentDidMount() {
      localStorage.setItem('activeId', '');
    }

    onShowMessagePreview = (id) => {
      localStorage.setItem('activeId', id);
      this.setState({
        activeId: id,
      });
    }

    render() {
      // if (this.props.scenarios.size) debugger
        return (
            <div>
              { this.props.scenarios.map(function (scenario) {
                if(scenario.schema_version === 2) {
                  const activeKey = this.state.activeId == scenario.get('id') ? 'key' : null
                  return (
                    <ScenarioV2
                      key={ scenario.get('id') }
                      scenario={ scenario }
                      onShowMessagePreview={ () => this.onShowMessagePreview(scenario.get('id')) }
                      activeKey={ activeKey }
                      {..._.omit(this.props, 'scenarios')}
                      renderActiveCampaignWarning={this.props.renderActiveCampaignWarning}
                      draftScenarios={this.props.scenarios}
                    />
                );
                }
                  return (
                      <Scenario
                        key={ scenario.get('id') }
                        scenario={ scenario }
                        {..._.omit(this.props, 'scenarios')}
                      />
                  );
              }.bind(this))}
            </div>
        );
    }
}
