import * as types from './action_types';
import { List } from 'immutable';
import {
  getScenarios,
  fetchScenarios,
  getScenariosAfterImportAjax,
  createScenarioAjax,
  createUpdateScenarioAjax,
  createSiteGroupScenarioAjax,
  createUpdateSiteGroupScenarioAjax,
  updateScenarioAjax,
  updateSiteGroupScenarioAjax,
  deleteScenarioAjax,
  deleteSiteGroupScenarioAjax,
} from '../api/dialogue/scenarios';
import { importScenarioAjax } from '../api/dialogue/import';
import { getMessagesAfterImport } from './message_actions.js';
import { actionCreator } from './helpers';
import { setSiteGroupSitesWithErrors } from './site_actions';
import {
  createScenarioLog,
  deleteScenarioLog,
  editScenarioLog,
  createSiteGroupScenarioLog,
  deleteSiteGroupScenarioLog,
  editSiteGroupScenarioLog,
} from '../api/change_log';

export const getDraftScenarios = actionCreator(
  getScenarios.bind(null, 'draft'),
  scenariosPending,
  scenariosLoaded,
  scenariosError,
  scenariosCached
);

export const getPublicScenarios = actionCreator(
  getScenarios.bind(null, 'public'),
  scenariosPending,
  scenariosLoaded,
  scenariosError,
  scenariosCached
);

export const fetchPublicScenarios = actionCreator(
  fetchScenarios.bind(null, 'public'),
  scenariosPending,
  scenariosLoaded,
  scenariosError
);

export const getStageScenarios = actionCreator(
  getScenarios.bind(null, 'stage'),
  scenariosPending,
  scenariosLoaded,
  scenariosError,
  scenariosCached
);

export const fetchStageScenarios = actionCreator(
  fetchScenarios.bind(null, 'stage'),
  scenariosPending,
  scenariosLoaded,
  scenariosError
);

export const getPublicOldScenarios = actionCreator(
  getScenarios.bind(null, 'publicOld'),
  scenariosPending,
  scenariosLoaded,
  scenariosError,
  scenariosCached
);

export const getStageOldScenarios = actionCreator(
  getScenarios.bind(null, 'stageOld'),
  scenariosPending,
  scenariosLoaded,
  scenariosError,
  scenariosCached
);

export const createScenario = actionCreator(
  createScenarioAjax,
  scenariosPending,
  createScenarioLoaded,
  scenariosError,
  null,
  createScenarioLog
);

export const createUpdateScenario = actionCreator(
  createUpdateScenarioAjax,
  scenariosPending,
  createScenarioLoaded,
  scenariosError,
  null,
  createScenarioLog
);

export function createUpdateSiteGroupScenario(
  accountId,
  siteGroupId,
  scenario,
  willImport
) {
  return (dispatch, getState) => {
    dispatch(scenariosPending());
    if (willImport) dispatch(scenarioImportPending());
    createUpdateSiteGroupScenarioAjax(accountId, siteGroupId, scenario)
      .then((resp) => {
        createSiteGroupScenarioLog(getState(), siteGroupId, resp);
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(createScenarioLoaded(resp.get('scenarios')));
      })
      .catch((resp) => {
        dispatch(scenariosError(resp));
      });
  };
}

export function createSiteGroupScenario(
  accountId,
  siteGroupId,
  scenario,
  willImport
) {
  return (dispatch, getState) => {
    dispatch(scenariosPending());
    if (willImport) dispatch(scenarioImportPending());
    createSiteGroupScenarioAjax(accountId, siteGroupId, scenario)
      .then((resp) => {
        createSiteGroupScenarioLog(getState(), siteGroupId, resp);
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(createScenarioLoaded(resp.get('scenarios')));
      })
      .catch((resp) => {
        dispatch(scenariosError(resp));
      });
  };
}

export const updateScenario = actionCreator(
  updateScenarioAjax,
  scenariosPending,
  updateScenarioLoaded,
  scenariosError,
  null,
  editScenarioLog
);

export function updateSiteGroupScenario(siteGroupId, accountId, scenario) {
  return async (dispatch, getState) => {
    try {
      dispatch(scenariosPending());
      await updateSiteGroupScenarioAjax(siteGroupId, accountId, scenario)
      .then((resp) => {
        editSiteGroupScenarioLog(getState(), siteGroupId, scenario);
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(updateScenarioLoaded(resp.get('scenarios')));
      });
      return 'success';
    } catch(error) {
      dispatch(scenariosError(error));
      return 'error';
    }
  };
}

export const deleteScenario = actionCreator(
  deleteScenarioAjax,
  scenariosPending,
  scenarioDeleted,
  scenariosError,
  null,
  deleteScenarioLog
);

export function deleteSiteGroupScenario(siteGroupId, accountId, scenario) {
  const scenarioId = scenario.id;
  return (dispatch, getState) => {
    dispatch(scenariosPending());
    deleteSiteGroupScenarioAjax(siteGroupId, accountId, scenarioId)
      .then((resp) => {
        deleteSiteGroupScenarioLog(getState(), siteGroupId, scenario);
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(scenarioDeleted(resp.get('scenarioIds')));
      })
      .catch((resp) => {
        dispatch(scenariosError(resp));
      });
  };
}

export const getScenariosAfterImport = actionCreator(
  getScenariosAfterImportAjax,
  scenariosPending,
  scenariosLoaded,
  scenariosError
);

export function importScenario(siteId, scenarioId, options) {
  return (dispatch) => {
    dispatch(scenarioImportPending());
    return importScenarioAjax(siteId, scenarioId, options)
      .then((scenario) => {
        getMessagesAfterImport(siteId)(dispatch)
          .then(() => {
            dispatch(scenarioImported(scenario));
          })
          .catch((reason) => {
            dispatch(scenariosError(reason));
          });
      })
      .catch((reason) => {
        dispatch(scenariosError(reason));
      });
  };
}

function scenariosPending() {
  return {
    type: types.SCENARIOS_PENDING,
  };
}

function scenariosCached() {
  return {
    type: types.SCENARIOS_CACHED,
  };
}

function scenariosLoaded(scenarios) {
  return {
    type: types.SCENARIOS_LOADED,
    scenarios,
  };
}

function scenariosError(errorMessage) {
  return {
    type: types.SCENARIOS_ERROR,
    errorMessage,
  };
}

function createScenarioLoaded(scenario) {
  return {
    type: types.CREATE_SCENARIO_LOADED,
    scenario,
  };
}

function updateScenarioLoaded(scenario) {
  return {
    type: types.UPDATE_SCENARIO_LOADED,
    scenario,
  };
}

function scenarioDeleted(IdOrIds) {
  const ids = new List([IdOrIds]).flatten();
  return {
    type: types.SCENARIO_DELETED,
    ids,
  };
}

function scenarioImportPending() {
  return {
    type: types.SCENARIO_IMPORT_PENDING,
  };
}

function scenarioImported(scenario) {
  return {
    type: types.SCENARIO_IMPORTED,
    scenario,
  };
}
