import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Table } from 'antd';
import { browserHistory } from 'react-router';

import {
  CookieNameColumn,
  FirstAppearedScanColumn,
  LastAppearedScanColumn,
  PrevalenceColumn,
  VendorDetailsColumn,
  VendorNameColumn,
  VendorReferenceColumn,
  VendorStatusColumn,
  VendorTypeColumn,
} from '../../shared/DiagnoseColumns';

import {
  cookiesWithLongLifespansExport,
  getCookiesWithLongLifespansFilters,
  getCookiesWithLongLifespansList,
  getVendorManagementStatusesList,
} from '../../../../../actions/diagnose_dashboard_actions';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import {
  getUniqueVendorIdsList,
  isFiltersProvided,
  mapSelectedFilters,
  requestDataDependOnTableFilters,
} from '../helpers';

const DetailedBreakdown = ({
  getCookiesWithLongLifespansList,
  cookiesWithLongLifespansList,
  cookiesWithLongLifespansListPending,
  cookiesWithLongLifespansFilter,
  getCookiesWithLongLifespansFilters,
  cookiesWithLongLifespansExportPending,
  selectedFilters,
  cookiesWithLongLifespansExport,
  userDetails,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const [tableFilters, setTableFilters] = useState(null);
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');

  const getCookiesWithLongLifespansListData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getCookiesWithLongLifespansList({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
    return res;
  };

  const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
    const query = {
      metric: 'Cookies with long lifespans',
      vendors,
      status,
      tag,
      websites: selectedFilters?.websites,
    };
    const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
    if (isCacheValid) {
      return vendorManagementStatuses;
    }
    const res = await getVendorManagementStatusesList(query);
    return res;
  };

  const getCookiesWithLongLifespansFiltersData = () => {
    getCookiesWithLongLifespansFilters(filters);
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      requestDataDependOnTableFilters(
        { filters: tableFilters || {}, isEnabledVendorStatusManagementFeature },
        (f, vendorId) =>
          getCookiesWithLongLifespansListData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
        getVendorManagementStatusesListData,
      );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      getCookiesWithLongLifespansFiltersData();
    }
  }, [filters]);

  useEffect(() => {
    if (vendorManagementActivities?.length) {
      getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsList(cookiesWithLongLifespansList?.results) }, false);
    }
  }, [vendorManagementActivities]);

  const vendorsFilter = cookiesWithLongLifespansFilter?.vendors?.map((filter) => ({ text: filter, value: filter }));
  const cookiesFilter = cookiesWithLongLifespansFilter?.cookies?.map((filter) => ({ text: filter, value: filter }));
  const referenceVendorsFilter = cookiesWithLongLifespansFilter?.reference_vendors?.map((filter) => ({
    text: filter,
    value: filter,
  }));

  const columns = [
    VendorTypeColumn({}),
    VendorNameColumn({ filters: vendorsFilter || [] }),
    ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    CookieNameColumn({ filters: cookiesFilter || [] }),
    {
      title: 'Cookie Lifespan',
      dataIndex: 'cookie_lifespan',
      key: 'cookie_lifespan',
      sorter: (a, b) => a.cookie_lifespan.years - b.cookie_lifespan.years,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div>
          {text.years ? (
            <span style={{ marginRight: '5px' }}>
              {text.years} {text.years > 1 ? 'years' : 'year'}
            </span>
          ) : null}
          {text.months ? (
            <span>
              {text.months} {text.months > 1 ? 'months' : 'month'}
            </span>
          ) : null}
        </div>
      ),
    },
    {
      ...PrevalenceColumn,
      dataIndex: 'cookie_prevalence',
      key: 'cookie_prevalence',
    },
    FirstAppearedScanColumn,
    LastAppearedScanColumn,
    VendorReferenceColumn({ filters: referenceVendorsFilter || [] }),
    VendorDetailsColumn({ metric: 'Cookies with long lifespans' }),
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const tableData = useMemo(() => {
    if (!isEnabledVendorStatusManagementFeature) {
      return cookiesWithLongLifespansList?.results;
    }

    return cookiesWithLongLifespansList?.results?.map((row) => {
      const rowWithVendorStatus = {
        ...row,
        vendor_status:
          vendorManagementStatuses?.filter((vendorStatus) => {
            return vendorStatus.vendor === row.vendor_id;
          }) || [],
      };
      return rowWithVendorStatus;
    });
  }, [isEnabledVendorStatusManagementFeature, cookiesWithLongLifespansList?.results, vendorManagementStatuses]);

  const onPaginationChange = (pageNumber, pageSize) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    cookiesWithLongLifespansExport(filters);
  };

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The table below lists the vendors that we have observed on your properties that are dropping cookies with
        lifespans over 13 months in the period of time selected.
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container">
        <div className={'filters_block'}>
          <div className={'quick_filters'} />
          <div className={'downloadCSV'}>
            <Button icon={<DownloadOutlined />} onClick={exportCsv} loading={cookiesWithLongLifespansExportPending} />
          </div>
        </div>
        <Table
          id="detailed-breakdown"
          scroll={{ x: 'max-content', y: 430 }}
          dataSource={tableData}
          columns={columns}
          showSorterTooltip={false}
          pagination={{
            current: page,
            pageSize: limit,
            total: tableFilters?.vendor_status?.length && !vendorManagementStatuses.length ? 0 : cookiesWithLongLifespansList?.total_count,
            position: ['bottomCenter'],
            onChange: onPaginationChange,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
          }}
          rowKey={(record) => record?.vendor_id}
          rowClassName={(record) =>
            record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
              ? 'diagnose-notification-highlight'
              : ''
          }
          onChange={onTableChange}
          loading={{
            className: 'diagnose-spinning',
            spinning: cookiesWithLongLifespansListPending || vendorManagementStatusesPending,
            tip: cookiesWithLongLifespansList?.results?.length
              ? ''
              : 'Processing a large query; please wait or leave it open and check later.',
            size: 'large',
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    cookiesWithLongLifespansList: store.diagnoseDashboardState.getIn(['cookiesWithLongLifespansList', 'value']),
    cookiesWithLongLifespansListPending: store.diagnoseDashboardState.get('cookiesWithLongLifespansListPending'),
    cookiesWithLongLifespansExportPending: store.diagnoseDashboardState.get('cookiesWithLongLifespansExportPending'),
    cookiesWithLongLifespansFilter: store.diagnoseDashboardState.getIn(['cookiesWithLongLifespansFilter', 'value']),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
    vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
    vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCookiesWithLongLifespansList,
      getCookiesWithLongLifespansFilters,
      cookiesWithLongLifespansExport,
      getVendorManagementStatusesList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
