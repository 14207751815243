import React, { useState} from "react";
import SubmissionMessage from "./SubmissionMessage";
import FreeScanForm from "./FreeScanForm";

const ScanningSignUp = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);


  return isSubmitted ? (
    <SubmissionMessage />
  ) : (
    <FreeScanForm setIsSubmitted={setIsSubmitted}/>
  );
};

export default ScanningSignUp
