import { Map, List } from "immutable";

import * as types from "../actions/action_types";
import { StoreRecord } from "../records/store_records.js";
import { setValue, setError, setPending, stopPending } from "./helpers.js";

const initialState = Map({
  ccpaDnsaData: StoreRecord({ value: List([]) })
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CCPA_REPORTING_PENDING:
      return setPending(state, ["ccpaDnsaData"]);

    case types.CCPA_REPORTING_LOADED:
      return setValue(state, ["ccpaDnsaData"], action.data);

    case types.CCPA_REPORTING_ERROR:
      return setError(state, ["ccpaDnsaData"], action.errorMessage);

    case types.CCPA_REPORTING_CACHED:
      return stopPending(state, ["ccpaDnsaData"]);
  }
  return state;
};
