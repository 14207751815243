import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { User } from '../../records/account_records';
import DialogueSubnav from '../navigation/DialogueSubnav';

import { getParameterByName } from "../utils.js";

class DialogueLayout extends React.Component {
  static propTypes = {
    currentUser: PropTypes.instanceOf(User).isRequired,
  }

  render() {
    if (!this.props.currentUser) return null;
    const showSubNav = !this.props.location?.pathname?.includes('dialogue/sites');

    return (
      <div id='avocado' className={`layout-container dialogue-layout ${showSubNav ? 'has-subnav' : ''}`}>
        { showSubNav && (
          <DialogueSubnav
            id="subnav"
            currentUser={this.props.currentUser}
            isMasq={this.props.route.isMasq}
            menuHidden={this.props.menuHidden}
            menuOpen={this.props.menuOpen}
            location={this.props.location}
            propertyType={this.props.propertyType}
            siteGroupId={this.props.siteGroupId}
            siteId={this.props.siteId}
          />
        )}
        {this.props.children && React.cloneElement(this.props.children, { 
          location: this.props.location,
          selectSite: this.props.selectSite
        })}
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  const siteId = parseInt(getParameterByName('site_id', window.location));
  const allSites = store.siteState.getIn(['sites', 'value']);
  const site = allSites.find(s => s.id === parseInt(siteId));

  let propertyType;
  if (site && site.type !== 'property_group') {
    propertyType = site.type;
  }

  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    propertyType,
  };
};

export default connect(mapStateToProps)(DialogueLayout);
