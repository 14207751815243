import React from 'react';
import { SearchOutlined, RightOutlined } from '@ant-design/icons';
import {  Collapse } from 'antd';
import { Table, Input, message, Button } from "../../../styleguide";
import { Map, List, fromJS } from 'immutable';
import moment from 'moment';
import RecentChangesModal from '../../consent/performanceNew/RecentChangesModal';
import MessagePerformaceModal from './MessagePerformaceModal';
import ExportToolTip from '../../ccpa/dataprivacy/ExportToolTip';
import {
  ResponsiveContainer,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend,
} from 'recharts';
import {
  LINE_GRAPH_COLOR_LIST,
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST,
  DATEPICKERS
} from '../../../constants';
import {
  absDataFormater,
  percentDataFormater,
  createCSVExportData,
  getPropertyNameFromSites,
} from '../performanceNew/helper';
import CustomPopover from '../../common/CustomPopover';
import { messageTooltipContent } from './instructionalText.js';
import MessagePerformanceDetails from './MessagePerformanceDetails';
import { TooltipRow } from './ConsentPerformanceGraph';
import FilterColumns from './FilterColumns';
import { mapReduceSum, mapReduceAvg, trimString } from './helper';
const { Panel } = Collapse;

export default class ConsentPerformanceMsg extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      recentChangesModal: false,
      msgPerformanceVisible: false,
      messageSearch: '',
      searchText: '',
      lineToggle: Map({
        totalUsers: true,
        totalMsgs: true,
        consentAll: false,
        rejectAll: false,
        legitimateintobj: false,
        bounces: false,
        other: false
      }),
      messagedTableData: List(),
      msgValConvert: 'number',
      selectedRowKeys: List([ Map({ key: 0, msgs: List() }), Map({ key: 1, msgs: List() }), Map({ key: 2, msgs: List() })]),
      rechartMargin: { top: 5, right: 30, left: 20, bottom: 15 },
      detMsgPerModalStatus: false,
      msgRowSelected: Map({ siteid: '', messagename: '', aggData : List() }),
      omitCols: List(['messagename', 'siteid']),
      msgCols: this.msgActionCols(),
    };
  }

  toggleLine = (tKey) => {
    let toggles = this.state.lineToggle;
    const inverse = !toggles.get(tKey);
    toggles = toggles.set(tKey, inverse);
    this.setState({ lineToggle: toggles });
  };

  setGraphType = (graphType) => {
    this.setState({ graphType });
  };

  showModal = (smm) => {
    this.setState({
      [smm]: true,
    });
  };

  handleCancel = (cmm) =>
    this.setState({
      [cmm]: false,
    });

  renderTooltip = (props) => {
    let propertyDate;
    let logData;
    let tooltipData;
    if (props.payload && props.payload.length > 0 && props.payload[0]) {
      propertyDate = props.payload[0].payload.date;
      logData = this.props.changeLogData.filter((item) => {
        return item.get("date") == propertyDate;
      });
      tooltipData = logData.get(0);
    }

    if (tooltipData) {
      return (
        <div className="tooltip-custom">
          <TooltipRow
            label="Change Type"
            value={this.capitalize(tooltipData.get('operationtype'))}
            customClass="tooltip-custom-text-bold"
          />
          <TooltipRow label="Date" value={tooltipData.get('date')} />
          <TooltipRow
            label="Entity Type"
            value={tooltipData.get('entitytype')}
          />
          <TooltipRow label="Entity" value={tooltipData.get('entitynames')} />
          <TooltipRow
            label="Properties"
            value={trimString(tooltipData.get("properties"))}
            customClass="tooltip-custom-text-style"
          />
          <TooltipRow label="User" value={tooltipData.get('user')} />
        </div>
      );
    }
    return null;
  };

  showTooltip = () => {
    return (
      <Tooltip
        content={this.renderTooltip}
        filterNull={true}
        active={false}
        cursor={false}
      />
    );
  };
  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  drawGraph = (graphData) => {
    let lineArr = [];
    graphData.map((d, j) => {
      if (this.state.lineToggle.get('totalUsers')) {
        lineArr.push(
          <Line
            key={'tu-' + j}
            dot={false}
            yAxisId={
              this.state.lineToggle.get('totalMsgs') ||
              this.state.lineToggle.get('consentAll') ||
              this.state.lineToggle.get('rejectAll') ||
              this.state.lineToggle.get('legitimateintobj') ||
              this.state.lineToggle.get('other') ||
              this.state.lineToggle.get('bounces')
                ? 'right'
                : 'left'
            }
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={'totalUsers' + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(0)}
          />
        );
      }
      if (this.state.lineToggle.get('totalMsgs')) {
        lineArr.push(
          <Line
            key={'tm-' + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={'totalMsgs' + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(1)}
          />
        );
      }
      if (this.state.lineToggle.get('consentAll')) {
        lineArr.push(
          <Line
            key={'ca-' + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={'consentAll' + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(2)}
          />
        );
      }
      if (this.state.lineToggle.get('legitimateintobj')) {
        lineArr.push(
          <Line
            key={'l-' + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={'legitimateintobj' + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(3)}
          />
        );
      }
      if (this.state.lineToggle.get('rejectAll')) {
        lineArr.push(
          <Line
            key={'r-' + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={'rejectAll' + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(4)}
          />
        );
      }
      if (this.state.lineToggle.get('other')) {
        lineArr.push(
          <Line
            key={'o-' + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={'other' + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(5)}
          />
        );
      }
      if (this.state.lineToggle.get('bounces')) {
        lineArr.push(
            <Line
                key={'b-' + j}
                dot={false}
                yAxisId="left"
                strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
                strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
                type="monotone"
                dataKey={'bounces' + j}
                stroke={LINE_GRAPH_COLOR_LIST.get(6)}
            />
        );
      }
    });
    return lineArr;
  };

  setMsgValConvert = (value) => this.setState({ msgValConvert: value });
  dataFormater = (number) => {
    return number + '%';
  };
  showMsgPerfromanceDetModal = (selectedMsgData) => {
    const msgData = selectedMsgData.set('siteid', getPropertyNameFromSites(this.props.sites, selectedMsgData.get('siteid')));
    this.setState({
      detMsgPerModalStatus: !this.state.detMsgPerModalStatus,
      msgRowSelected:  msgData ? msgData : Map({}),
    });
  }
  msgActionCols = () => {
    return List([
      Map({
        title: 'Message ID/Name',
        titleText: 'Message Name',
        dataIndex: 'messagename',
        sorter: (a, b) => a.messagename.localeCompare(b.messagename),
        className: 'left-align',
        fixed: 'left',
        render: (text, record) => <a onClick={() => this.showMsgPerfromanceDetModal(fromJS(record))}>{`${record.messageid}/${text}`}</a>
      }),
      Map({
        title: 'Property',
        dataIndex: 'siteid',
        render: (text) => (getPropertyNameFromSites(this.props.sites, text)),
        sorter: (a, b) =>
          getPropertyNameFromSites(this.props.sites, a).localeCompare(
            getPropertyNameFromSites(this.props.sites, b)
          ),
        className: 'left-align rpt-trim-text',
        fixed: 'left',
      }),
      Map({
        title: 'Total Users',
        dataIndex: 'totalusers',
        sorter: (a, b) => a.totalusers - b.totalusers,
        className: 'right-align',
        render: (text) => absDataFormater(text),
      }),
      Map({
        title: 'Messaged Users',
        dataIndex: 'totalmsgs',
        sorter: (a, b) => a.totalmsgs - b.totalmsgs,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.totalmsgsper}</span> <br/>
                                   {absDataFormater(text)}
                                  </div>,
      }),
      Map({
        title: 'Accept All',
        dataIndex: 'consentall',
        sorter: (a, b) => a.consentall - b.consentall,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.consentallper}</span> <br/>
                                   {absDataFormater(text)}
                                  </div>,
      }),
      Map({
        title: 'Legitimate Interest Only',
        dataIndex: 'legitimateintobj',
        sorter: (a, b) => a.legitimateintobj - b.legitimateintobj,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.legitimateintobjper}</span> <br/>
                                   {absDataFormater(text)}
                                  </div>,
      }),
      Map({
        title: 'Reject All',
        dataIndex: 'rejectall',
        sorter: (a, b) => a.rejectall - b.rejectall,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.rejectallper}</span> <br/>
                                   {absDataFormater(text)}
                                  </div>,
      }),
      Map({
        title: 'All other choices',
        dataIndex: 'other',
        sorter: (a, b) => a.other - b.other,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
                                   <span className="tbl-metric-per">{record.otherper}</span> <br/>
                                   {absDataFormater(text)}
                                  </div>,
      }),
      Map({
        title: 'No Action',
        dataIndex: 'bounces',
        sorter: (a, b) => a.bounces - b.bounces,
        className: 'right-align',
        render: (text, record) => <div className="consent-table-metrics">
          <span className="tbl-metric-per">{record.bouncesper}</span> <br/>
          {absDataFormater(text)}
        </div>,
      })
    ]);
  }
  setMsgCols = (filteredCols) => {
    this.setState({msgCols: this.msgActionCols().filter((cols) => filteredCols.includes(cols.get('dataIndex')))});
  }

  handleCompareMsgs = () => {
    let msgCount = 0;
    this.state.selectedRowKeys.map((cm) => msgCount += cm.get('msgs').size);
    if(msgCount >= 2) {
       this.showModal('msgPerformanceVisible');
    }else{
        message.warning('Select at least 2 messages');
    }
  };

  render() {
    var tableData = this.props.messageConsentData.map((items, i) =>
      items.get('messageData')
    );
    var graphData = this.props.messageConsentData.map((items, i) =>
      items.get('messageGraph')
    );
    var msgPerformance = this.props.messageConsentData.map((items, i) =>
      items.get('messagePerformanceList')
    );

    let graphList = List();
    let unionList = List();
    graphData.map((g, i) => {
      unionList = unionList
        .toSet()
        .union(g.toSet())
        .toList();
    });
    let groupedByDates = unionList.groupBy((dataPoint) =>
      this.props.datePicker === DATEPICKERS.quarter ? dataPoint.get('fancyDate') : dataPoint.get('date')
    );
    groupedByDates.map((item, i) => {
      let mergeMap = Map();
      item.map((m, j) => {
        mergeMap = mergeMap.merge(m);
      });
      graphList = graphList.push(mergeMap);
    });
    graphList = graphList.sort(
      (a, b) =>
        moment(a.get('date')).valueOf() - moment(b.get('date')).valueOf()
    );

    const renderLegend = (props) => {
      return (
        <React.Fragment>
          {graphData.map((entry, index) => (
            <div className="legend-custom" key={`legend-${index}`}>
              <svg height="5" width="28">
                <line
                  x1="0"
                  y1="0"
                  x2="200"
                  y2="0"
                  style={{
                    stroke: '#555758',
                    strokeWidth: '5',
                    strokeDasharray: LINE_GRAPH_STROKE_PATTERN_LIST.get(index),
                  }}
                />
              </svg>
              {this.props.criteriaNames.get(index)}
            </div>
          ))}
        </React.Fragment>
      );
    };

    const filterCols = this.msgActionCols();
    const tableTitle = (
      <React.Fragment>
        <div className="table-title-wrapper">
            <div className="table-title">Messaged Users Performance Details</div>
                <a onClick={() => this.showModal('recentChangesModal')}>
                  View Recent Changes <RightOutlined />
                </a>
        </div>
        <div className="action-wrapper">
            <div className="compare-msgs">
              <Button type="primary" onClick={this.handleCompareMsgs}>
                Compare Messages
              </Button>
               <CustomPopover theme="filled" tooltipContent={messageTooltipContent} />
            </div>
            <div className="msg-action-wrapper">
              <FilterColumns columns={filterCols} omitCols={this.state.omitCols} setCols={this.setMsgCols}/>
              <Input
                placeholder="Search Messages"
                prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                onChange={(event) => this.setState({ searchText: event.target.value })}
               />
            </div>
        </div>

      </React.Fragment>
    );

    let msgHeaders = this.msgActionCols()
      .map((item) => Map({ label: item.get('titleText') || item.get('title'), key: item.get('dataIndex') }))
      .insert(0, Map({ label: 'Messaged ID', key: 'messageid' }))
      .insert(4, Map({ label: 'Messaged Users %', key: 'totalmsgsper' }))
      .insert(6, Map({ label: 'Accept All %', key: 'consentallper' }))
      .insert(8, Map({ label: 'Legitimate Interest Only %', key: 'legitimateintobjper' }))
      .insert(10, Map({ label: 'Reject All %', key: 'rejectallper' }))
      .insert(12, Map({ label: 'All other choices %', key: 'otherper'}))
      .insert(14, Map({ label: 'No Action %', key: 'bouncesper' }));

    let graphTitle = graphList.size
      ? moment(graphList.first().get('date')).format('MMMM YYYY')
      : '';

    const dateCol = Map({
                        title: () => <React.Fragment>Date<br/>(YYYY-MM-DD)</React.Fragment>,
                        dataIndex: 'date',
                        sorter: (a, b) => moment(a.date, 'YYYY-MM-DD').valueOf() - moment(b.date, 'YYYY-MM-DD').valueOf(),
                        className: 'left-align',
                        defaultSortOrder: 'descend',
                        fixed: 'left',
                    });
    const msgPerDetColumns = this.msgActionCols().delete(0).delete(0).insert(0, dateCol);
    const msgDetColumnsExport = msgHeaders.insert(0, Map({ label: "Date", key: "date" }));
    const metrics = List([
                         {'title': 'Total Users', dataIndex: 'totalUsers', metricPerVal: mapReduceSum(tableData, 'totalusers'), metricAbsVal: ''},
                         {'title': 'Messaged Users', dataIndex: 'totalMsgs', metricPerVal: mapReduceAvg(tableData, 'totalmsgsper'), metricAbsVal: mapReduceSum(tableData, 'totalmsgs')},
                         {'title': 'Accept All', dataIndex: 'consentAll', metricPerVal: mapReduceAvg(tableData, 'consentallper'), metricAbsVal: mapReduceSum(tableData, 'consentall')},
                         {'title': 'Legitimate Interest Only', dataIndex: 'legitimateintobj', metricPerVal: mapReduceAvg(tableData, 'legitimateintobjper'), metricAbsVal: mapReduceSum(tableData, 'legitimateintobj')},
                         {'title': 'Reject All', dataIndex: 'rejectAll', metricPerVal: mapReduceAvg(tableData, 'rejectallper'), metricAbsVal: mapReduceSum(tableData, 'rejectall')},
                         {'title': 'All other choices', dataIndex: 'other', metricPerVal: mapReduceAvg(tableData, 'otherper'), metricAbsVal: mapReduceSum(tableData, 'other')},
                          {'title': 'No Action', dataIndex: 'bounces', metricPerVal: mapReduceAvg(tableData, 'bouncesper'), metricAbsVal: mapReduceSum(tableData, 'bounces')},
                    ]);
    return (
      <React.Fragment>
        <div className="property-tabs">
          {metrics.map((mt, index) =>
            <div
              key={index}
              className={`metrices-toggle-wrapper ${this.state.lineToggle.get(mt.dataIndex)? `toggle-line${index+1}-active`: `toggle-line${index+1}`}`}
              onClick={() => this.toggleLine(mt.dataIndex)}
            >
              <div className="metric-label" style={this.state.lineToggle.get(mt.dataIndex) ? { fontWeight: 600} : null}>{mt.title}</div>
              <div className="metric-total">
                <span className="metric-per">{mt.metricPerVal}</span><br/>
                {mt.metricAbsVal ? <span className="metric-abs">{mt.metricAbsVal}</span> : <span className="metric-abs">&nbsp;</span>}
              </div>
            </div>)}
          <div className="instructional-text-icon">
            <CustomPopover theme="filled" tooltipContent={messageTooltipContent} />
          </div>
        </div>
        <div className="property-title-container">
          <div className="graph-desc-container">
            <span className="property-title">Messaged Users Performance Details </span>
            <br />
            <span className="property-desc">
              Trend over time, can be viewed on weekly, monthly or quarterly basis.
            </span>
          </div>
        </div>

        <ResponsiveContainer height={300}>
          <LineChart
            width={730}
            height={270}
            data={graphList.toJS()}
            margin={this.state.rechartMargin}
          >
            <CartesianGrid strokeDasharray="1 1" vertical={false}/>
            <XAxis dataKey="fancyDate" tickLine={false}>
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              axisLine={false}
              tickLine={false}
              type="number"
              yAxisId="left"
              domain={[0, 'dataMax']}
              tickFormatter={
                this.state.lineToggle.get('totalMsgs') ||
                this.state.lineToggle.get('consentAll') ||
                this.state.lineToggle.get('rejectAll') ||
                this.state.lineToggle.get('legitimateintobj') ||
                this.state.lineToggle.get('other') ||
                this.state.lineToggle.get('bounces')
                  ? percentDataFormater
                  : absDataFormater
              }
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              type="number"
              yAxisId="right"
              orientation="right"
              domain={[0, 'dataMax']}
              tickFormatter={absDataFormater}
            />
            {this.showTooltip()}
            <Legend verticalAlign="top" content={renderLegend} />
            {this.drawGraph(graphData)}
          </LineChart>
        </ResponsiveContainer>

        {tableTitle}

        <div className="collapsibleTable">
          <Collapse defaultActiveKey={['1']} bordered={false}>
            {tableData.map((item, i) => {
              const data = item.get('aggData').toJS().filter((m) =>
                m.messagename.toLowerCase().includes(this.state.searchText.toLowerCase())
              );
              return (
                <Panel
                  header={
                    <span>
                      {`Criteria ${i+1}: ${this.props.criteriaNames.get(i)}`}
                      <ExportToolTip
                        filename="Messaged Users Performance Details"
                        selectedTabIndex={i + 1}
                        exportData={createCSVExportData(
                          msgHeaders,
                          item.get('aggData')
                        ).toJS()}
                        criterialist={this.props.criteriaList}
                        criteriaNames={this.props.criteriaNames}
                        sites={this.props.sites}
                        pdfTitle={'Messaged Users Performance Details'}
                      />
                    </span>
                  }
                  key={i + 1}
                >
                  <Table
                    columns={this.state.msgCols.toJS()}
                    dataSource={data}
                    scroll={{ x: 'max-content' }}
                    pagination={{
                      position: ['bottomCenter'],
                      total: data.length,
                      showTotal: (total) => `Total: ${total}`,
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                      locale: { items_per_page: "  Records per page" }
                    }}
                    className="consnet-tableCSS"
                    rowKey={(r) => r.key}
                    rowSelection={{
                      onChange: (selectedRowKeys) => {
                        let list = this.state.selectedRowKeys;
                        const index = list.findIndex((r) => r.get('key') == i);
                          var getRowAbsolute = item
                            .get('aggData')
                            .filter((l, i) => selectedRowKeys.indexOf(i) != -1);
                            list = list.setIn([index, 'msgs'], getRowAbsolute);
                        this.setState({ selectedRowKeys: list });
                      },
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>

        <RecentChangesModal
          changeLogData={this.props.changeLogData}
          recentChangesModal={this.state.recentChangesModal}
          handleCancel={() => this.handleCancel('recentChangesModal')}
        />
        <MessagePerformaceModal
          columns={this.msgActionCols()}
          msgPerformance={msgPerformance}
          selectedMsgs={this.state.selectedRowKeys}
          recentChangesModal={this.state.msgPerformanceVisible}
          handleCancel={() => this.handleCancel('msgPerformanceVisible')}
          sites={this.props.sites}
          criteriaNames={this.props.criteriaNames}
        />
        <MessagePerformanceDetails
          status={this.state.detMsgPerModalStatus}
          columns={msgPerDetColumns}
          msgData={this.state.msgRowSelected}
          handleCancel={() => this.handleCancel('detMsgPerModalStatus')}
          msgHeaders={msgDetColumnsExport}
        />
      </React.Fragment>
    );
  }
}
