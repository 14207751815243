import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

export const initialState = Map({
    accounts: new StoreRecord({ value: [] }),
});

export default (state = initialState, action) => {

    switch(action.type) {

        case types.PRIVACY_LENS_ACCOUNTS_PENDING:
            return setPending(state, ['accounts']);

        case types.PRIVACY_LENS_ACCOUNTS_LOADED:
            return setValue(state, 'accounts', action.accounts);

        case types.PRIVACY_LENS_ACCOUNTS_ERROR:
            return setError(state, [accounts], action.errors);
    }

    return state;
}

            