import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import CSVLink from "../../common/CSVLink.js";
import { useActions } from '../../../hooks';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List } from 'immutable';
import moment from 'moment';

import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  FileOutlined,
  MinusOutlined,
  SaveOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { browserHistory } from 'react-router';
import { Table, Input, Select, Checkbox, Button, message } from 'antd';
import CreateButton from '../../common/CreateButton.js.jsx';
import { getRules as getRulesAction } from '../../../actions/standard_actions';
import { 
  getGoldenSet as getGoldenSetAction,
  createGoldenSet as createGoldenSetAction,
  updateGoldenSet as updateGoldenSetAction,
} from '../../../actions/golden_set_actions';
import GoldenSetModal from './GoldenSetModal';
import { GoldenSet } from '../../../records/golden_set_records';
import ChangeScoreModal from '../scoring_results/ChangeScoreModal';
import GoldenScoreTabs from './GoldenScoreTabs';

const Search = Input.Search;

export default function GoldenSetDetail(props) {

  if (!props.currentUser) {
    return null;
  }

  const goldenSetFromRedux = useSelector(state => state.goldenSetState.getIn(['goldenSets', 'value'])).find(gs => gs.id === props.location.query.id);
  const getRules = useActions(getRulesAction);
  const [createGoldenSet, updateGoldenSet] = useActions([createGoldenSetAction, updateGoldenSetAction]);

  const [goldenSet, setGoldenSet] = useState(new GoldenSet({}));

  useEffect(() => {
    getRules();
  }, []);

  useEffect(() => {
    setGoldenSet(goldenSetFromRedux || new GoldenSet({}));
  }, [goldenSetFromRedux]);

  const [addRulesAndPropertiesOpen, setAddRulesAndPropertiesOpen] = useState(false);

  const [editingPropertyUrl, setEditingPropertyUrl] = useState(null);
  const [editedScoresForSingleProperty, setEditedScoresForSingleProperty] = useState({});
  const [selectedPropertyUrls, setSelectedPropertyUrls] = useState([]);
  const [batchEditModalOpen, setBatchEditModalOpen] = useState(false);
  const [policyEstablished, setPolicyEstablished] = useState(!!props.location.query.id);

  const [search, setSearch] = useState('');

  const allRules = useSelector(state => state.standardState.getIn(['rules', 'value']));

  const changeGoldenScores = (urls, scores) => {
    const updatedProperties = goldenSet.properties.map(property => {
      if (urls.includes(property.url)) {
        const currentUser = props.currentUser;
        const date = new Date();
        const manualScore = property.manualScore
          .set('scores', property.manualScore.scores.merge(scores))
          .set('userId', currentUser.id)
          .set('userName', currentUser.name)
          .set('dateScored', date);
        return property.set('manualScore', manualScore);
      } else {
        return property;
      }
    });
    setGoldenSet(goldenSet.set('properties', updatedProperties));
  };

  const saveScoresForSingleProperty = () => {
    for (const key in editedScoresForSingleProperty) {
      if (editedScoresForSingleProperty[key] === 'notSet') {
        editedScoresForSingleProperty[key] = null;
      }
    }
    changeGoldenScores([editingPropertyUrl], editedScoresForSingleProperty);
    setEditingPropertyUrl(null);
    setEditedScoresForSingleProperty({});
  };

  const toggleSelectedProperty = (checked, url) => {
    if (checked) {
      selectedPropertyUrls.push(url);
    } else {
      selectedPropertyUrls.filter(selectedUrl => selectedUrl ==! url);
    }
    setSelectedPropertyUrls([...selectedPropertyUrls]);
  };

  const scoreRule = (score, ruleId) => {
    const scoreToSave = score === 'notSet' ? 'notSet' : parseInt(score);
    editedScoresForSingleProperty[ruleId] = scoreToSave;
    setEditedScoresForSingleProperty({...editedScoresForSingleProperty});
  };

  const saveGoldenSet = () => {

    if (goldenSet.id) {
      updateGoldenSet(props.currentUser.get('accountId'), goldenSet);
      message.success('Golden Set Saved')
    } else {
      createGoldenSet(props.currentUser.get('accountId'), goldenSet).then(({ id }) => {
        message.success('Golden Set Saved')
        browserHistory.push(`/privacy_lens/golden_score/golden_set?id=${id}`)
      });
    }
  };

  const generateTable = () => {

    let columns = List([
      Map({
        title: 'Properties',
        dataIndex: 'properties',
        key: 'properties',
        width: '300px',
        fixed: 'left',
      }),
    ]);

    columns = columns.concat(goldenSet.rules.map(r => {
      return Map({
        title: r.name,
        dataIndex: r.ruleId,
        key: r.ruleId,
        className: classNames('rule-column', {'more-than-four': goldenSet.rules.size > 4 } ),
      });
    }));

    columns = columns.concat([
      Map({
        title: 'Last Scanned',
        dataIndex: 'lastScanned',
        key: 'lastScanned',
        fixed: 'right',
        width: '200px',
      }),
      Map({
        title: 'Last Updated',
        dataIndex: 'lastUpdated',
        key: 'lastUpdated',
        fixed: 'right',
        width: '200px',
      }),
      Map({
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        width: '100px',
      }),
    ]);

    let properties = goldenSet.properties;
    if (search) {
      properties = properties.filter(p => p.url.toLowerCase().includes(search.toLowerCase()));
    }

    const dataSource = properties.map((p) => {
  
      let detailsIcon = (
        <EditOutlined onClick={ () => { setEditingPropertyUrl(p.url) }} />
      );
  
      if (editingPropertyUrl && editingPropertyUrl === p.url) {
        detailsIcon = (
          <div>
            <CloseOutlined
              onClick={ () => { setEditingPropertyUrl(null); setEditedScoresForSingleProperty({}); }} />
            <SaveOutlined onClick={ saveScoresForSingleProperty } />
          </div>
        );
      }

      const dateScored = p.manualScore.dateScored ? moment(p.manualScore.dateScored).format("LLL") : 'no score date';
      let row = Map({
        key: p.url,
        properties: <Checkbox onChange={ (e) => toggleSelectedProperty(e.target.checked, p.url) }>{ p.url }</Checkbox>,
        lastScanned: (
          <div>
            <div>{ moment(p.lastScanned).format("LLL") }</div>
          </div>
        ),
        lastUpdated: (
          <div>
            <div>{ dateScored }</div>
            <div>{ `by ${p.manualScore.userName}` }</div>
          </div>
        ),
        actions: (<div className='actions'>
          { detailsIcon }
        </div>),
      });
      
      
      let icon;
      goldenSet.rules.forEach(r => {  
        const propertyScore = p.manualScore.get('scores').get(r.ruleId) == null ? 'notSet' : p.manualScore.get('scores').get(r.ruleId);

        if (propertyScore === 1) {
          icon = <CheckOutlined style={{ color: 'green' }} />;
        } else if (propertyScore === 0) {
          icon = <CloseOutlined style={{ color: 'red' }} />;
        } else if (propertyScore === -1) {
          icon = 'N/A';
        } else {
          icon = <MinusOutlined style={{ color: 'gray' }} />;
        }
        if (editingPropertyUrl && editingPropertyUrl === p.url) {
          let value = editedScoresForSingleProperty[r.ruleId];
          value = typeof value === 'undefined' ? propertyScore : value;
          icon = (
            <Select
              onChange={ (score) => scoreRule(score, r.ruleId) }
              defaultValue='Select score'
              value={ value === 'notSet' ? value : value.toString() }
              className='select-score'
              dropdownClassName='select-score-dropdown'
            >
              <Select.Option key={ '1' } value={ '1' }><CheckOutlined style={{ color: 'green' }} /></Select.Option>
              <Select.Option key={ '0' } value={ '0' }><CloseOutlined style={{ color: 'red' }} /></Select.Option>
              <Select.Option key={ '-1' } value={ '-1' }>N/A</Select.Option>
              <Select.Option key={ 'notSet' } value={ 'notSet' }><MinusOutlined style={{ color: 'gray' }} /></Select.Option>
            </Select>
          );
        }
  
        row = row.set(r.ruleId, icon);
      });
     
      return row;
    });
  
    return new Map({ dataSource, columns });
  };
  
  const tableData = generateTable();

  const closeModal= () => {
    setAddRulesAndPropertiesOpen(false);
  }

  let goldenSetModal;
  if (addRulesAndPropertiesOpen) {
    const ruleType = goldenSet.policy === 'GDPR' ? 'GDPR' : 'CCPA';
    const policyRules = allRules.filter(r => r.type === ruleType); 
    goldenSetModal = (
      <GoldenSetModal
        closeModal={ closeModal }
        setGoldenSet={ setGoldenSet }
        goldenSet={ goldenSet }
        rules={ policyRules }
        currentUser={ props.currentUser }
      />
    );
  }

  const addRulesAndPropertiesButton = (
    <Button 
      className='add-rules-and-props'
      onClick={() => { setAddRulesAndPropertiesOpen(true); if (goldenSet.policy) { setPolicyEstablished(true);} }} 
      disabled={ !goldenSet.policy }
    >
      Select Rules / Properties
    </Button>
  );

  const saveButton = (
    <CreateButton 
      className='save-golden-set' 
      onClick={ saveGoldenSet } 
      disabled={ !goldenSet.name || !goldenSet.policy }
    >
      Save Golden Set
    </CreateButton>
  );

  let changeScoreModal;
  if (batchEditModalOpen) {
    const scores = goldenSet.properties.filter(p => selectedPropertyUrls.includes(p.url));
    changeScoreModal = (
      <ChangeScoreModal
        closeModal={() => setBatchEditModalOpen(false)}
        domainScores={ scores }
        rules={ goldenSet.rules }
        changeScores={ (scores) => changeGoldenScores(selectedPropertyUrls, scores) }
        goldenSet
      />
    );
  }

  const generateDataForCsvReport = () => {

    const tableData = generateTable();

    let dataForCSV = new List([]);
    const dataSource = tableData.get('dataSource');
    const columns = tableData.get('columns');

    const columnsIdx = columns.map(c => c.get('dataIndex')).filterNot(idx => idx === 'actions');

    const columnNamesWithoutRuleIds = new List(['properties', 'lastScanned', 'lastUpdated']);

    dataForCSV = dataForCSV.push(columns.map(c => c.get('title')).filterNot(name => name === 'Actions'));
    
    dataSource.forEach(ds => {
      let row = new List();

      columnsIdx.forEach(idx => {

        let value = ds.get(idx);
        if (!ds.get(idx)) {
          value = '';
        } else if (typeof(ds.get(idx).type) === "function") {
          value = value.props.children;
          if (value.props) {
            value = value.props.children;
          }
        }

        if (!columnNamesWithoutRuleIds.includes(idx)) {
          value = value.type && value.type.render().props.icon.name;
          if (value === 'check') {
            value = 'passed';
          } else if (value === 'close') {
            value = 'X';
          } else if (value === 'minus') {
            value = '';
          } else {
            value = 'n/a';
          }
        } else {
          if (idx === 'lastScanned') {
            value = value.props.children.props.children;
          }
          if (idx === 'lastUpdated') {
            value = value.props.children[0].props.children + ' ' + value.props.children[1].props.children;
          }
        }

        row = row.push(value);
      });
      
      dataForCSV = dataForCSV.push(row);
    });

    return dataForCSV.toJS();
  };

  const downloadReport = (
    <div>
      <CSVLink
        data={ generateDataForCsvReport(tableData) }
        className='export-csv'
        filename={new Date() + ' data.csv'}
        target=""
      >
        <p>Download CSV Report</p>
      </CSVLink>
    </div>
  );

  return (
    <div className='domain-sets-container privacy-lens golden-set golden-set-detail'>

      <GoldenScoreTabs />

      { downloadReport }

      { saveButton }
      
      { goldenSetModal }

      { changeScoreModal }

      <div className='top-lane flex-row'>
        <Input placeholder='New Golden Set Name' value={ goldenSet.name } onChange={ (e) => setGoldenSet(goldenSet.set('name', e.target.value))} />
        <div>
          {
            goldenSet.policy
              ? <div className='policy'>Policy</div>
              : null
          }
          <Select
            value={ goldenSet.policy || 'Select Policy' }
            onChange={ (e) => setGoldenSet(goldenSet.set('policy', e))}
            disabled={ goldenSet.id || policyEstablished }
          >
            <Select.Option value='GDPR'>European (GDPR)</Select.Option>
            <Select.Option value='US'>US (US Privacy)</Select.Option>
          </Select>
        </div>

        { addRulesAndPropertiesButton }
      </div>

      <div className='table-box-shadow'>
        <div className='search-container'>
          <Search
            placeholder="Search Properties"
            onChange={ (e) => { setSearch(e.target.value) } }
            className="vendor-filter"
            style={{ width: 190 }}
          />
          <Button
            disabled={ !selectedPropertyUrls.length }
            onClick={ () => setBatchEditModalOpen(true) } 
          >
            Batch Edit
          </Button>
        </div>

        <Table
          className='golden-set-detail-table'
          bordered={ false }
          dataSource={ tableData.get('dataSource').toJS() }
          columns={ tableData.get('columns').toJS() }
        />
      </div>
    </div>
  );
}
