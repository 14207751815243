import PropTypes from 'prop-types';
import React from 'react';
import { InputNumber } from 'antd';

import {
  PageviewsRecord,
  TimeDelayRecord,
  SessionMessageNLimit,
} from '../../../../../scenario_constants.js';

export default class NumberStep extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    data: PropTypes.oneOfType([
      PropTypes.instanceOf(PageviewsRecord),
      PropTypes.instanceOf(TimeDelayRecord),
      PropTypes.instanceOf(SessionMessageNLimit),
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    propertyName: PropTypes.string,
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    propertyName: 'num_pv',
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.data) {
      return {
        propertyName: nextProps.data.get(nextProps.propertyName),
      };
    }
    return null;
  }

  state = {
    propertyName: this.props.data.get(this.props.propertyName),
  };

  handleChange = (value) => {
    value = parseInt(value);

    if (isNaN(value)) {
      this.setState({ propertyName: '' })
    } else {
      this.setState({ num_pv: value });
      this.props.onChange(this.props.data.set(this.props.propertyName, value));
    }
  };

  render() {
    if (!this.props.editing) {
      return (<div>{ this.props.label }: { this.state.propertyName }</div>);
    } else {
      return (
        <div>
          { this.props.children }
          <InputNumber
            min={ 0 }
            value={ this.state.propertyName }
            onChange={ this.handleChange }
          />
        </div>
      );
    }
  }
}
