import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const initialState = Map({
  dsarRequests: new StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  switch(action.type) {

    case types.DNS_MESSAGES_PENDING:
      return setPending(state, ['messagesBySite']);

    case types.DNS_MESSAGES_LOADED:
      return setValue(state, ['messagesBySite'], action.messages);

    case types.DNS_MESSAGES_ERROR:
      return setError(state, ['messagesBySite'], action.errorMessage);
  }

  return state;
};
