import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from './CustomButton.js.jsx';

export default class extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    render() {
        return (
            <div className="create-button-container">
              <CustomButton {...this.props} className="create-button">{ this.props.children }</CustomButton>
            </div>
        )
    }
}
