import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { Modal } from 'antd';
import { Button } from '../../../styleguide';
import { WarningOutlined } from '@ant-design/icons';

export default class DeleteStandardModal extends React.Component {
  static propTypes = {
    moveToDraft: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  goToPropertySets = () => {
    browserHistory.push(`/privacy_lens/domain_sets?standardId=${this.props.standard.id}`);
  }

  render() {
    const footer = [
      <Button key="1" onClick={this.props.closeModal} className="cancel">
        Cancel
      </Button>
    ];
    footer.push(          
      <Button key="2" onClick={ this.goToPropertySets } type="primary">
        Open Property Sets
      </Button>
    );

    return (
      <Modal
        onOk={this.handleApply}
        okText='Save'
        onCancel={this.props.closeModal}
        className='purple new-design'
        visible
        footer={footer}
      >
        <p className="title">Standard - Delete</p>

        <div className='modal-container'>
          <p>
            Once deleted, all associated data with <span>{this.props.standard.name}</span> will be deleted permanently and cannot be retrieved at all. Instead, you can also opt to move standard to DRAFT.
          </p>
          <p>
            First, unlink associated Active property sets before you can delete standard.
          </p>
          <p>
            Press the <span>Open Property Sets</span> button to Open linked property sets. Unlink the property sets by changing standard applied to the set.
          </p>
          <div className='linked-sets'>
            <p>
              <WarningOutlined />Following Property Sets are still linked to the standard.
            </p>
            <div className='property-sets'>
              { this.props.standard.appliedToDomainSetIds.map(appliedId => this.props.domainSets.find(ds => ds.id === appliedId || ds._id === appliedId)).map(ds => <div className='domain-set'>{ds.name}</div>) }
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
