import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import MessageEditBackdrop from './MessageEditBackdrop.js.jsx';
import MessageEditBorderStyles from './MessageEditBorderStyles.js.jsx';
import MessageEditZIndex from './MessageEditZIndex';

import ColorPickerInput from '../../../common/ColorPickerInput.js.jsx';
import { Style, MessageConfigRecord } from '../../../../records/message_config_records.js';

export default class extends React.Component {
  static propTypes = {
      className: PropTypes.string,
      messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
      updateMessageConfig: PropTypes.func.isRequired,
      editing: PropTypes.bool.isRequired,
  };

  onUpdate = (target, value) => {
      const path = target.split('.');
      this.props.updateMessageConfig(this.props.messageConfig.setIn(path, value));
  };

  render() {
      const { getFieldDecorator } = this.props.form;
      const style = this.props.messageConfig.getIn(['data', 'style']);
      const className = classNames(this.props.className, 'styles-table');

      return (
          <div className={ className }>
            <MessageEditBackdrop
              value={ style.getIn(['backdrop', 'backgroundColor']) }
              onUpdate={ this.onUpdate.bind(this, 'data.style.backdrop.backgroundColor') }
              editing={ this.props.editing }
            />
            <h3>Message Color</h3>
            <div className='single style-color-font-pickers'>
              <div className="options-styles-property">
                <ColorPickerInput
                  value={ style.getIn(['general', 'backgroundColor']) }
                  onUpdate={ this.onUpdate.bind(this, 'data.style.general.backgroundColor') }
                  editing={ this.props.editing }
                  />
              </div>
            </div>
            <MessageEditBorderStyles
              value={ style.getIn(['general', 'border']) }
              onUpdate={ this.onUpdate.bind(this, 'data.style.general.border') }
              editing={ this.props.editing }
            />
            <h3>Close Button</h3>
            <div className='single style-color-font-pickers'>
              <div className="options-styles-property">
                <ColorPickerInput
                  value={ style.getIn(['general', 'closeButtonColor']) }
                  onUpdate={ this.onUpdate.bind(this, 'data.style.general.closeButtonColor') }
                  editing={ this.props.editing }
                />
              </div>
            </div>
            <MessageEditZIndex
              value={ style.getIn(['general', 'zIndex']).toString() }
              onUpdate={ (e) => this.onUpdate('data.style.general.zIndex', parseInt(e.target.value)) }
              editing={ this.props.editing }
              form={ this.props.form }
            />
          </div>
      );
  }
}
