import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button } from 'antd';
import { styles } from './styles';

export const CustomHeader = props => {
  return (
    <div onClick={props.onPanelClick} className="step-header">
      <div
        onClick={props.onClick}
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin:'5px 8px 8px 8px',
          borderBottom: '0.5px solid #D4D4D4',
          color: '#411F90',
        }}
        className="step-header-arrow"
      >
        <Button
          icon={<LegacyIcon type={props.collapseOpen ? 'down' : 'right'} />}
          className="custom-collapse-icon"
        ></Button>
        {props.children}
      </div>
      {props.headerConditions}
      {props.conditionPanel && <div className="hide-condition-panel" style={styles.conditionInCard}>{props.conditionPanel}</div>}
    </div>
  );
};
