import PropTypes from 'prop-types';
import React from 'react';
import { List, OrderedSet } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Checkbox, Modal, Infotip, Select } from '../../styleguide'
import _ from 'lodash';
import { Site } from '../../records/site_records.js';
import { toTitleCase } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

const Option = Select.Option;
export default class extends React.Component {
  static propTypes = {
      type: PropTypes.oneOf(["message", "scenario", "partition set"]).isRequired,
      sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
      siteId: PropTypes.string.isRequired,
      fetchItemsForTheSite: PropTypes.func.isRequired,
      storedItems: ImmutablePropTypes.orderedSet,
      importItem: PropTypes.func.isRequired,
      closeForm: PropTypes.func.isRequired,
      switchOptions: PropTypes.array,
      isMultiCampaignEnabled: PropTypes.boolean
  };

  static defaultProps = {
      sites: List([]),
      storedItems: OrderedSet([]),
      switchOptions: null,
  };

  state = {
      selectedSite: null,
      selectedItem: null,
  };

  handleSelectSite = (value) => {
      if (!value) {
          this.setState({ selectedSite: "" });
          return;
      }

      var selectedSite = this.props.sites.find(site => site.id === parseInt(value))
      this.setState(
          { selectedSite },
          () => this.props.fetchItemsForTheSite(selectedSite && selectedSite.id)
      );
  };

  handleSelectItem = (value) => {
      var selectedItem = this.props.storedItems.find(item => item.id === parseInt(value));

      this.setState({ selectedItem });
  };

  filteredSites = () => {
    return this.props.sites
      .filterNot(s => s.id === parseInt(this.props.siteId))
      .filterNot(s => s.domain.includes('-group-'));
  }

  render() {

      var dropdownExplanatoryText;

      const listForImport = this.props.storedItems.filter(el => el.site_id === (this.state.selectedSite && this.state.selectedSite.id));

      if (this.state.selectedSite) {
          if (listForImport.size) {
              dropdownExplanatoryText = "Choose...";
          } else {
              dropdownExplanatoryText = "Selected site has no " + this.props.type + "s";
          }
      } else {
          dropdownExplanatoryText = "Please choose the site first";
      }
      let copyMessagesSwitch;
      let importOptions;

      if (this.props.switchOptions) {
        copyMessagesSwitch = this.props.switchOptions.map((obj) => {
          return (
            <div className="import-toggle-wrapper" key={ obj.popupText }>
              <Checkbox
                purple
                checked={ obj.isChecked }
                onChange={ (e) => obj.onChecked(e.target.checked) }
                disabled={ obj.disabled }
              />
              <span>{ obj.popupText }</span>
            </div>
          );
        });

        if (this.props.type === 'scenario') {
          importOptions = { copyMessages: this.props.switchOptions[0].isChecked };
        }
        if (this.props.type === 'partition set') {
          importOptions = {
            copyScenarios: this.props.switchOptions[0].isChecked,
            copyMessages: this.props.switchOptions[1].isChecked,
          };
        }
      }

      let sitePopover = !this.props.isMultiCampaignEnabled 
        ? (<Infotip content={ `Legacy campaign sites can not import ${this.props.type}s from multi campaign sites` } title="Warning">
                <FontAwesomeIcon icon={faInfoCircle} />
            </Infotip>)
        : null;

      return (
            <Modal
              wrapClassName="import-form-modal"
              title={toTitleCase("import\xa0" + this.props.type)}
              visible={this.props.visible}
              onOk={this.state.selectedItem ? () => this.props.importItem(this.state.selectedItem, importOptions) :  _.noop} 
              okText="Import"
              okButtonProps={{ disabled: !this.state.selectedItem }}
              onCancel={this.props.closeForm}>
             
              <span>Select the site</span>&nbsp;{sitePopover}
              <br/>
              <Select value={ (this.state.selectedSite && this.state.selectedSite.id) || "" } onChange={ this.handleSelectSite }>
                <Option value="">Choose...</Option>
                { this.filteredSites().toArray().map(site =>
                  <Option key={ site.id } value={ site.id } disabled={ !this.props.isMultiCampaignEnabled && site.multiCampaignEnabled }>{ site.domain }</Option>
				)}
              </Select>
              <br/>

              <span>{ "Select the " + this.props.type + " to import" }</span><br/>
              <Select value={ this.state.selectedItem && this.state.selectedItem.id || "" } onChange={ this.handleSelectItem } disabled={ !listForImport.size }>
                <Option value="">{ dropdownExplanatoryText }</Option>
                { listForImport.map(item => <Option key={ item.id } value={ item.id }>{ item.description || item.name }</Option>) }
              </Select>

              { copyMessagesSwitch }
    
            </Modal>
      )
  }
}
