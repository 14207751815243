import React from "react";
import PDFDownload from "./PDFDownload";
import CSVLink from "../../common/CSVLink.js";
import { FileTextOutlined } from '@ant-design/icons';

export default class ExportPopoverContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CSVLink
          data={this.props.exportData.toJS()}
          filename={this.props.filename + ".csv"}
        >
          <FileTextOutlined className="iconcsv" title="CSV"/>
        </CSVLink>
        <PDFDownload
          content={this.props.exportData.toJS()}
          title={this.props.pdfTitle}
          filename={this.props.filename}
        />
      </React.Fragment>
    );
  }
}
