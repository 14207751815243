import React from 'react'
import {Alert as AntdAlert } from 'antd'

const Alert = (incomingProps) => {
  const props = {...incomingProps};

  props.className =  'spsg-alert ' + (props.className || '');
  
  return (
    <div style={{ display: "inline-block", minWidth: "100px" }}>
      <AntdAlert {...props} />
    </div>
  );
}

export default Alert; 
