import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import FontSizeInput from './FontSizeInput.js.jsx';
import ColorPickerInput from '../../../common/ColorPickerInput.js.jsx';
import { MessageConfigRecord } from '../../../../records/message_config_records.js';

import AntdTextarea from '../../../common/AntdTextarea.jsx';
import CustomButton from '../../../common/CustomButton.js.jsx';

import { MessageVariables } from '../../../../records/message_records';
import AddVariableDropdownModal from './AddVariableDropdownModal';
import { getParameterByName } from '../../../utils';

export default class extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
    updateMessageConfig: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    variables: PropTypes.instanceOf(MessageVariables),
    openVariablesModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    variables: new MessageVariables({}),
  };

  onUpdate = (target, value) => {
    const path = target.split('.');
    this.props.updateMessageConfig(this.props.messageConfig.setIn(path, value));
  };

  onInputEvent = (target, event) => {
    this.onUpdate(target, event.target.value);
  };

  handleMessageBodyUpdate = (e) => {
    this.onUpdate('data.msgContent.contentData.body', e.target.value);
  };

  handleMessageTitleUpdate = (e) => {
    this.onUpdate('data.msgContent.contentData.title', e.target.value);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const style = this.props.messageConfig.getIn(['data', 'style']);
    const contentData = this.props.messageConfig.getIn(['data', 'msgContent', 'contentData']);
    const className = classNames(this.props.className, 'headline-message');

    let isUpdateDisabled = true;
    if (this.messageHeadline && this.messageBody) {
      const headlineValDisabled = contentData.title === this.messageHeadline.props.form.getFieldValue('copy-styles-headline');
      const bodyValDisabled = contentData.body === this.messageBody.props.form.getFieldValue('copy-styles-message-body');
      isUpdateDisabled = headlineValDisabled && bodyValDisabled;
    }

    let addVariableDropdown;
    if (getParameterByName('site_group_id', window.location)) {
      addVariableDropdown = (
        <AddVariableDropdownModal
          variables={ this.props.variables }
          openVariablesModal={ this.props.openVariablesModal }
        />
      );
    }

    return (
      <div className={ className }>
        <div className="copy-block">
          <div className='header'>
            <h3>Headline</h3>
            { addVariableDropdown }
          </div>
          <div className="headline-color-font-pickers">
            <ColorPickerInput
              value={ style.getIn(['title', 'color']) }
              onUpdate={ this.onUpdate.bind(this, 'data.style.title.color') }
              editing={ this.props.editing }
            />
            <FontSizeInput
              value={ style.getIn(['title', 'fontSize']) }
              onUpdate={ this.onUpdate.bind(this, 'data.style.title.fontSize') }
              editing={ this.props.editing }
            />
          </div>
          <AntdTextarea
              ref={AntdTextarea => { this.messageHeadline = AntdTextarea; }}
              className="headline"
              fieldName="copy-styles-headline"
              initialValue={ contentData.get('title') }
              form={ this.props.form }
              editing={ this.props.editing }
              placeholder="We've noticed you are using an ad blocker."
              required={false}
              onBlur={ this.handleMessageTitleUpdate }
              onPressEnter={ this.handleMessageTitleUpdate }
          />
        </div>

        <div className="copy-block">
          <div className='header'>
            <h3>Body</h3>
            { addVariableDropdown }
          </div>
          <div className="headline-color-font-pickers">
            <ColorPickerInput
              value={ style.getIn(['body', 'color']) }
              onUpdate={ this.onUpdate.bind(this, 'data.style.body.color') }
              editing={ this.props.editing }
            />
            <FontSizeInput
              value={ style.getIn(['body', 'fontSize']) }
              onUpdate={ this.onUpdate.bind(this, 'data.style.body.fontSize') }
              editing={ this.props.editing }
            />
          </div>
          <AntdTextarea
              ref={AntdTextarea => { this.messageBody = AntdTextarea; }}
              className="textarea"
              fieldName="copy-styles-message-body"
              initialValue={ contentData.get('body') }
              form={ this.props.form }
              editing={ this.props.editing }
              placeholder="<p>We rely on advertising to offer our journalism for free.</p><p>Please whitelist us or disable your ad blocker, and reload the page.</p><p>Thank you for supporting us.</p>"
              required={false}
              onBlur={ this.handleMessageBodyUpdate }
              onPressEnter={ this.handleMessageBodyUpdate }
          />
        </div>
        <div className='update-message-button-container'>
          <CustomButton
            className='update-message button'
            size={ CustomButton.sizes.MEDIUM }
            onClick={ _.noop }
            disabled={ isUpdateDisabled }
            >
            Update Preview
          </CustomButton>
        </div>
      </div>
    );
  }
}
