import { nativeOtt, Settings, Types, } from 'message-preact';

export const getLanguageTypes = () => {
  const languageTypes = {}
  const types = { ...Types, ...nativeOtt.types };
  Object.keys(types).forEach(t => {
    languageTypes[t] = [];
    languageTypes[t + 'Titles'] = {};
    Object.keys(types[t]).forEach(c => {
      for (var key in types[t][c]) {
        if (['InputLanguage', 'TextLanguage'].includes(types[t][c][key].type)) {
          languageTypes[t].push(key);
          languageTypes[t + 'Titles'][key] = types[t][c][key].title;
        }
      }
    })
  });

  return languageTypes;
}

export const SettingsMap = {
  NoticeSettings: Settings.Notice,
  NoticeTypes: Types.Notice,

  IOSSettings: Settings.IOS,
  IOSTypes: Types.IOS,

  CustomSettings: Settings.Custom,
  CustomTypes: Types.Custom,

  IOSButtonSettings: Settings.IOSButton,
  IOSButtonTypes: Types.IOSButton,

  PrivacyManagerSettings: Settings.PrivacyManager,
  PrivacyManagerTypes: Types.PrivacyManager,

  CustomPmViewSettings: Settings.PrivacyManager,
  CustomPmViewTypes: Types.PrivacyManager,

  PrivacyManagerTCFv2Settings: Settings.PrivacyManagerTCFv2,
  PrivacyManagerTCFv2Types: Types.PrivacyManagerTCFv2,

  PMTCFv2InlineSettings: Settings.PMTCFv2Inline,
  PMTCFv2InlineTypes: Types.PMTCFv2Inline,

  PMTCFv2InlineNeutralSettings: Settings.PMTCFv2InlineNeutral,
  PMTCFv2InlineNeutralTypes: Types.PMTCFv2InlineNeutral,

  PMCCPASettings: Settings.PMCCPA,
  PMCCPATypes: Types.PMCCPA,

  PMUSSettings: Settings.PMUS,
  PMUSTypes: Types.PMUS,

  PrivacyManagerCCPASettings: Settings.PrivacyManagerCCPA,
  PrivacyManagerCCPATypes: Types.PrivacyManagerCCPA,

  PrivacyManagerUSSettings: Settings.PrivacyManagerUS,
  PrivacyManagerUSTypes: Types.PrivacyManagerUS,

  PreferencesSettings: Settings.Preferences,
  PreferencesTypes: Types.Preferences,

  PrivacyManagerOTTSettings: Settings.PrivacyManagerOTT,
  PrivacyManagerOTTTypes: Types.PrivacyManagerOTT,
  PrivacyManagerOTTCategoriesViewSettings: Settings.PrivacyManagerOTT,
  PrivacyManagerOTTCategoriesViewTypes: Types.PrivacyManagerOTT,
  PrivacyManagerOTTVendorsViewSettings: Settings.PrivacyManagerOTT,
  PrivacyManagerOTTVendorsViewTypes: Types.PrivacyManagerOTT,
  PrivacyManagerOTTCardDetailsViewSettings: Settings.PrivacyManagerOTT,
  PrivacyManagerOTTCardDetailsViewTypes: Types.PrivacyManagerOTT,
  PrivacyManagerOTTPrivacyPolicyViewSettings: Settings.PrivacyManagerOTT,
  PrivacyManagerOTTPrivacyPolicyViewTypes: Types.PrivacyManagerOTT,
  PrivacyManagerOTTCustomViewSettings: Settings.PrivacyManagerOTT,
  PrivacyManagerOTTCustomViewTypes: Types.PrivacyManagerOTT,

  CCPAPrivacyManagerOTTSettings: Settings.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTTypes: Types.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTVendorsViewSettings: Settings.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTVendorsViewTypes: Types.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTPrivacyPolicyViewSettings: Settings.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTPrivacyPolicyViewTypes: Types.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTPreferencesViewSettings: Settings.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTPreferencesViewTypes: Types.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTCardDetailsViewSettings: Settings.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTCardDetailsViewTypes: Types.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTCustomViewSettings: Settings.CCPAPrivacyManagerOTT,
  CCPAPrivacyManagerOTTCustomViewTypes: Types.CCPAPrivacyManagerOTT,

  PMButtonSettings: Settings.PMButton,
  PMButtonTypes: Types.PMButton,

  SarSettings: Settings.Sar,
  SarTypes: Types.Sar,

  DsarRetrievalSettings: Settings.DsarRetrieval,
  DsarRetrievalTypes: Types.DsarRetrieval,
  OneTimeCodeViewSettings: Settings.OneTimeCodeView,
  OneTimeCodeViewTypes: Types.OneTimeCodeView,
  CodeSuccessViewSettings: Settings.CodeSuccessView,
  CodeSuccessViewTypes: Types.CodeSuccessView,
  RequestPendingViewSettings: Settings.RequestPendingView,
  RequestPendingViewTypes: Types.RequestPendingView,
  SuccessViewSettings: Settings.Success,
  SuccessViewTypes: Types.Sar,
  ErrorViewSettings: Settings.Error,
  ErrorViewTypes: Types.Error,

  RowSettings: Settings.Row,
  RowTypes: Types.Row,

  ColumnSettings: Settings.Column,
  ColumnTypes: Types.Column,

  StacksSettings: Settings.Stacks,
  StacksTypes: Types.Stacks,

  CategoriesSettings: Settings.Categories,
  CategoriesTypes: Types.Categories,

  ButtonSettings: Settings.Button,
  ButtonTypes: Types.Button,

  CustomButtonSettings: Settings.CustomButton,
  CustomButtonTypes: Types.CustomButton,

  SubmitButtonSettings: Settings.SubmitButton,
  SubmitButtonTypes: Types.SubmitButton,

  TextSettings: Settings.Text,
  TextTypes: Types.Text,

  ImageSettings: Settings.Image,
  ImageTypes: Types.Image,

  InputSettings: Settings.Input,
  InputTypes: Types.Input,

  ErrorBlockSettings: Settings.ErrorBlock,
  ErrorBlockTypes: Types.ErrorBlock,

  TextAreaSettings: Settings.TextArea,
  TextAreaTypes: Types.TextArea,

  RadioSettings: Settings.Radio,
  RadioTypes: Types.Radio,

  DropdownSettings: Settings.Dropdown,
  DropdownTypes: Types.Dropdown,

  DatePickerSettings: Settings.DatePicker,
  DatePickerTypes: Types.DatePicker,

  CheckboxGroupSettings: Settings.CheckboxGroup,
  CheckboxGroupTypes: Types.CheckboxGroup,

  OTTCardsSettings: Settings.OTTCards,
  OTTCardsTypes: Types.OTTCards,

  OTTCCPACardsSettings: Settings.OTTCCPACards,
  OTTCCPACardsTypes: Types.OTTCCPACards,

  OTTNavButtonSettings: Settings.OTTNavButton,
  OTTNavButtonTypes: Types.OTTNavButton,

  OTTSwitchCardSettings: Settings.OTTSwitchCard,
  OTTSwitchCardTypes: Types.OTTSwitchCard,

  PreferencesCenterSettings: Settings.PreferencesCenter,
  PreferencesCenterTypes: Types.PreferencesCenter,

  PreferencesButtonSettings: Settings.PreferencesButton,
  PreferencesButtonTypes: Types.PreferencesButton,

  ...nativeOtt.settings,
  ...Object.entries(nativeOtt.types).reduce((types, [key, value]) => ({ ...types, [key + 'Types']: value }), {}),
};