import React from 'react';
import BooleanStep from './BooleanStep';

export default class SearchReferrerGate extends React.Component {
  render() {
    return (
      <BooleanStep
        {...this.props}
        labelText={ 'Search Referrer Gate' }
      />
    );
  }
}
