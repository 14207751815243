import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import AntdFormItem from '../../../common/AntdFormItem.jsx';
import PrivacyManagerSelector from './PrivacyManagerSelector.jsx';
import { getSitePrivacyManagerList, getSiteGroupPrivacyManagerList } from '../../../../actions/privacy_manager_actions';
import { ChoiceConfigRecord } from '../../../../records/choice_config_records.js';
import { CONSTANTS } from '../../../../constants.js';
import { getParameterByName } from '../../../utils';

class MessageEditPrivacyManagerOption extends React.Component {
    static propTypes = {
        choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
        editing: PropTypes.bool.isRequired,
        form: PropTypes.object.isRequired,
        listIndex: PropTypes.number.isRequired,
        onChoiceConfigChange: PropTypes.func.isRequired,
    };

    onChange = (newVal) => {
        let optionData = this.props.choiceConfig.getIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA]);
        optionData = optionData.merge(newVal);
        const oldChoiceConfig = this.props.choiceConfig;
        const choiceConfig = oldChoiceConfig.setIn(['option_list', this.props.listIndex, 'option_data'], optionData);
        this.props.onChoiceConfigChange(choiceConfig);
    };

    componentDidMount() {
        const siteGroupId = getParameterByName('site_group_id', window.location);
        if (siteGroupId) {
          this.props.getSiteGroupPrivacyManagerList(this.props.accountId, this.props.siteGroup.siteIds, siteGroupId);
          //fetch privacy managers for each site
          this.props.siteGroup.siteIds.forEach(id => {
            this.props.getSitePrivacyManagerList(this.props.accountId, id);
          });
        } else {
          this.props.getSitePrivacyManagerList(this.props.accountId, this.props.siteId);
        }
    }

    render() {
        const optionData = this.props.choiceConfig.getIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA]);
        const classes = classNames('label-title');
        let { value: managers, error, pending } = this.props.sitePrivacyManagerList;
        const siteGroupId = getParameterByName('site_group_id', window.location);
        if (siteGroupId) {
          managers = managers.filter(m => m.siteGroupId === siteGroupId);
        }

        return this.props.sitePrivacyManagersLoaded ? (
            <div className='choice-option'>
                <div className={ classes }>
                    <AntdFormItem
                        fieldLabel='Button Text'
                        fieldName={ 'privacy_manager_' + this.props.listIndex }
                        form={ this.props.form }
                        initialValue={ optionData.get('button_text') }
                        onChange={ (event) => this.onChange(Map({ button_text: event.target.value })) }
                        placeholder='Show Purposes'
                        editing={ this.props.editing }
                    />
                    <PrivacyManagerSelector
                        form={ this.props.form }
                        className='ant-row ant-form-item antd-form-item messaging-select'
                        fieldLabel='Privacy Manager'
                        initialValue={ optionData.get('privacy_manager_iframe_url') }
                        onChange={ (value) => this.onChange(Map({ privacy_manager_iframe_url: value })) }
                        editing={ this.props.editing }
                        options={ managers }
                        error={ error }
                        isDataLoaded={ pending }
                        variables={ this.props.variables }
                        updateParentState={ this.props.updateParentState }
                    />
                </div>
            </div>
        ) : <div />;
    }
}

const mapStateToProps = function(store){
    return {
        accountId: store.accountState.getIn(['accountId', 'value']),
        siteId: store.siteState.getIn(['currentSite', 'value']),
        sitePrivacyManagerList: store.privacyManagerState.get('sitePrivacyManagerList'),
        sitePrivacyManagersLoaded: store.privacyManagerState.get('sitePrivacyManagersLoaded'),
    };
};

export default connect(mapStateToProps, { getSitePrivacyManagerList, getSiteGroupPrivacyManagerList })(MessageEditPrivacyManagerOption);
