import PropTypes from 'prop-types';
import React from 'react';
import StepSatisfyOption from './StepSatisfyOption';
import { AltPayCompGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';

export default class StepAltPayCompGate extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(AltPayCompGate).isRequired,
        onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
      super(props);
      this.handleChangeSatisfy = this.handleChangeSatisfy.bind(this);
    }

    handleChangeSatisfy(continueOnSatisfy) {
        const data = this.props.data.set('continue', continueOnSatisfy);
        this.props.onChange(data);
    }

    render() {
        var continueOnSatisfy = this.props.data.continue ? "Yes" : "No";

        if (!this.props.editing) {
            return (<div>Continue if AltPay Comp: { continueOnSatisfy }</div>);
        } else {
            return (
                <div className="step continue-halt">
                    <label>Continue if AltPay Comp:
                      <StepSatisfyOption
                        value={ this.props.data.continue }
                        onChange={ this.handleChangeSatisfy }
                        focusOnMount
                      />
                    </label>
                </div>
            );
        }
    }
}
