import * as types from './action_types';
import { getAndParseDashboardGraphData } from '../api/dashboard';

const graphPending = () => ({
  type: types.DASHBOARD_PENDING,
});

const graphLoaded = data => ({
  type: types.DASHBOARD_LOADED,
  data,
});

const graphLoadError = errorMessage => ({
  type: types.DASHBOARD_ERROR,
  errorMessage,
});

export const getDashboardGraphData = (data) => {
  return (dispatch) => {
    dispatch(graphPending());
    return getAndParseDashboardGraphData(data).then(data => {
      dispatch(graphLoaded(data));
    }).catch(reason => {
      return dispatch(graphLoadError(reason));
    });
  };
};
