import { Record, List, Map } from 'immutable';

export const Whitelist = Record({
  button_text: 'Disable Adblocker',
  whitelist_url: '//d1sga4e4j5xr2k.cloudfront.net/2/',
  enabled: false,
});

export const Recovery = Record({
  button_text: 'Continue with Ads',
  enabled: false,
});

export const AltAdVideo = Record({
  button_text: 'Watch Video Ad',
  adframe_url: '//altad.global.ssl.fastly.net/2/',
  adcall_url: '',
  comp_period_sec: 86400,
  language: 'en',
  v: 2,
  enabled: false,
});

export const PubCustom = Record({
  button_text: 'Custom',
  iframe_url: '',
  comp_period_sec: 86400,
  enabled: false,
});

export const Redirect = Record({
  button_text: 'Redirect',
  redirect_url: '',
  enabled: false,
});

export const ContinueWithAdblocker = Record({
  button_text: 'Continue with Adblocker',
  enabled: false,
});

export const Login = Record({
  button_text: 'Login',
  enabled: false,
});

export const AltPay = Record({
  button_text: 'Alt Pay',
  altpay_iframe_url: '',
  offer_ids: List([1]),
  enabled: false,
});

export const ExecJS = Record({
  button_text: 'Custom JS',
  js_fn_name: '',
  comp_period_sec: 120,
  enabled: false,
});

export const Samba = Record({
  button_text: 'Samba',
  comp_period_sec: 120,
  enabled: false,
});

export const Welect = Record({
  button_text: 'Welect',
  api_key: '',
  product_id: '',
  layout: '',
  comp_period_sec: 120,
  enabled: false,
});

export const AcceptAll = Record({
  button_text: 'Accept all cookies',
  enabled: false,
  consent_origin: window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org',
  consent_language: 'EN'
});

export const RejectAll = Record({
  button_text: 'Reject all cookies',
  enabled: false,
  consent_origin: window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org',
  consent_language: 'EN'
});

export const PrivacyManager = Record({
  button_text: 'Show Purposes',
  privacy_manager_iframe_url: '',
  enabled: false,
  consent_origin: window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org',
});

export const Dismiss = Record({
  button_text: 'Dismiss',
  enabled: false,
  consent_origin: window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org',
  consent_language: 'EN',
  hideDismiss: true,
});

export const Cancel = Record({
  button_text: 'Cancel',
  enabled: false,
  consent_language: 'EN',
});

export const SaveAndExit = Record({
  button_text: 'Save & Exit',
  enabled: false,
  consent_origin: window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org',
  consent_language: 'EN',
});

export const Accept = Record({
  button_text: 'Accept',
  enabled: false,
  consent_origin: window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org',
  consent_language: 'EN',
});

export const Reject = Record({
  button_text: 'Reject',
  enabled: false,
  consent_origin: window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org',
  consent_language: 'EN',
});

export const ChoiceOptions = Record({
  whitelist: new Whitelist(),
  recovery: new Recovery(),
  altAdVideo: new AltAdVideo(),
  pubCustom: new PubCustom(),
  redirect: new Redirect(),
  continueWithAdblocker: new ContinueWithAdblocker(),
  login: new Login(),
  altPay: new AltPay(),
  execJS: new ExecJS(),
  samba: new Samba(),
  welect: new Welect(),
  acceptAll: new AcceptAll(),
  rejectAll: new RejectAll(),
  privacyManager: new PrivacyManager(),
});

export const ListOption = Record ({
  choice_option_type: null,
  option_data: new Map({}),
});

export const ChoiceConfigRecord = Record({
  message_id: null,
  render_style: 1,
  options: new ChoiceOptions(),
  option_list: List([]),
});
