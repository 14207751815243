import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
  domainSets: new StoreRecord({ value: List([]) }),
  averageImpressions: new StoreRecord({ value: null }),
  createOrUpdatePending: false,
  accuracySets: new StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  let updatedDomainSets;
  const sets = state.getIn(['domainSets', 'value']);
  switch(action.type) {

    case types.DOMAIN_SETS_PENDING:
      return setPending(state, ['domainSets']);

    case types.DOMAIN_SETS_LOADED:
      return setValue(state, ['domainSets'], action.domainSets);

    case types.DOMAIN_SETS_ERROR:
      return setError(state, ['domainSets'], action.errorMessage);

    case types.DOMAIN_SETS_CACHED:
      return stopPending(state, ['domainSets']);

    case types.DOMAIN_SET_CREATE_OR_UPDATE_PENDING:
      return state.set('createOrUpdatePending', true);

    case types.DOMAIN_SET_CREATED:
      sets.push(action.domainSet);
      updatedDomainSets = sets.sort((p1, p2) => {
        return new Date(p2.dateCreated) - new Date(p1.dateCreated);
      });

      return setValue(state, ['domainSets'], updatedDomainSets).set('createOrUpdatePending', false);

    case types.DOMAIN_SET_UPDATED:
      updatedDomainSets = sets.filter(s => s._id !== action.domainSet._id)
      updatedDomainSets.push(action.domainSet);
      updatedDomainSets = updatedDomainSets.sort((p1, p2) => {
        return new Date(p2.dateCreated) - new Date(p1.dateCreated);
      });
      return setValue(state, ['domainSets'], updatedDomainSets).set('createOrUpdatePending', false);

    case types.DOMAIN_SET_DELETED:
      updatedDomainSets = sets.filter(s => s._id !== action.id);
      return setValue(state, ['domainSets'], updatedDomainSets);

    case types.GET_AVERAGE_IMPRESSIONS_PENDING:
      return setPending(state, ['averageImpressions']);

    case types.AVERAGE_IMPRESSIONS_LOADED:
      return setValue(state, ['averageImpressions'], action.impressions);

    case types.AVERAGE_IMPRESSIONS_ERROR:
      return setError(state, ['averageImpressions'], action.errorMessage);

    case types.AVERAGE_IMPRESSIONS_CACHED:
      return stopPending(state, ['averageImpressions']);

    case types.ACCURACY_SETS_PENDING:
      return setPending(state, ['accuracySets']);

    case types.ACCURACY_SETS_LOADED:
      return setValue(state, ['accuracySets'], action.domainSets);

    case types.ACCURACY_SETS_ERROR:
      return setError(state, ['accuracySets'], action.errorMessage);
  }

  return state;
};