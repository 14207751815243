import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CampaignGraph from './CampaignGraph';
import { List } from 'immutable';


export default class CampaignGraphMessages extends CampaignGraph {

  static propTypes = {
    data: ImmutablePropTypes.map.isRequired,
    width: PropTypes.number.isRequired,
  }

  tickToNum(num) {
      if (Math.floor(num) === num) {
          return num;
      }
  }

  generateYAxes() {
    let yAxes = super.generateYAxes();
    return List([yAxes.first().setIn(['ticks', 'callback'], num => this.tickToNum(num))]);
  }

  generateOptions() {
    let options = super.generateOptions();

    return options.setIn(['tooltips', 'callbacks', 'label'], ((item, data) => {
      const name = data.datasets[item.datasetIndex].label;
      return name + ': ' + item.yLabel + ' messages';
    }));
  }
}
