import React from 'react';

export const getColumns = (rules) => {
  const columns = [
    {
      title: 'Rules',
      dataIndex: 'url',
      align: 'right',
      width: '400px',
      render: (col, data) => (
        <div><h4>{data?.mustHave ? <span className="mustave">*</span> : null }{data?.name}</h4><p>Id: {data?.ruleId}</p></div>
      )
    },
    {
      title: '',
      dataIndex: 'rules',
      width: '400px',
      render: (col, data) => (
        <div><div className="progress"><div style={{width: data?.percent || 0}} /></div><p>{data.total} Properties</p></div>
      )
    },
    {
      title: 'Percentage',
      dataIndex: 'other',
      render: (col, data) => (
        <h2>{data?.percent || 'N/A'}</h2>
      ),
      sorter: (a, b) => a.percentNum - b.percentNum,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      render: (col, data) => (
        <h2>{data.systemWeight}</h2>
      ),
      sorter: (a, b) => a.systemWeight - b.systemWeight,
    },
  ];


  return columns;
};
