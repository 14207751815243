import React from 'react';
import { Button, Select, Divider, notification } from 'antd';
import { List, Map } from 'immutable';
import { WebsiteScanTable } from './WebsiteScanTable';
import { vendorCategories, legalBasis } from './helper';
import { LeftOutlined, PlusCircleFilled, WarningOutlined } from '@ant-design/icons';
const { Option } = Select;

export default class VendorCategorization extends React.Component {
  state = {
    emptyRow: '',
  };

  openNotification = (message, description) => notification.open({ message, description, icon: <WarningOutlined style={{ color: 'rgb(255, 151, 61)' }} /> });

  createMapByVendorType = (vendorScanCategorizations) => {
    return vendorScanCategorizations.map((dc) =>
      Map({
        purpose: dc.get('purpose'),
        vendorScanCategory: dc.get('vendorScanCategory'),
        legalBasis: dc.get('legalBasis'),
      })
    );
  };

  checkFoDuplicates = () => this.createMapByVendorType(this.props.vendorScanCategorizations).size === this.createMapByVendorType(this.props.vendorScanCategorizations).toSet().toList().size;

  errorCheck = () => {
    const checkRowData =
      this.props.vendorScanCategorizations.get(0) || this.props.newRow();
    if (
      !checkRowData.get('vendorScanCategory') ||
      !checkRowData.get('purpose') ||
      !checkRowData.get('legalBasis')
    ) {
      this.setState({ emptyRow: 'empty-row-error' });
      this.openNotification("Can't Add", "Can't add empty categorization");
    }
    this.checkFoDuplicates()
      ? this.props.addNewRow()
      : this.openNotification(
          'Duplicate',
          "Can't add duplicate categorization"
        );
  };

  getDuplicatePurposeIndex = (purpose, index) => {
    const purposeInds = this.props.vendorScanCategorizations
      .map((c, i) => (c.get('purpose') === purpose ? i : null))
      .filter((i) => i !== null)
      .pop();
    return purposeInds.includes(index);
  };
 
  updateCategorization = (field, value, index, purpose = '') => {
    this.setState({ emptyRow: '' });
    let preSetCat = this.props.vendorScanCategorizations.setIn([index, field], value);
    const purposeExist = this.props.vendorScanCategorizations.filter((p) => p.get('purpose') == value);
    if(field == 'purpose' && purposeExist.size) {
      preSetCat = preSetCat.setIn([index, 'legalBasis'], purposeExist.get(0).get('legalBasis'));
    }
    preSetCat.size === preSetCat.toSet().toList().size
      ? this.props.updateDefaultCategorization(field, value, index, purpose)
      : this.openNotification('Duplicate', "Can't add duplicate categorization");
  };

  getPurpose = (text) => this.props.getSubPurposes().find((p)=> (p.get('id') || p.get('name')) == text).get('name');

  legalBasisWrapper = (legalBasisVal) => {
    const legalBasisObj = legalBasis.find((lb)=> lb.get('value') == legalBasisVal);
    return <div className={`li-tag ${legalBasisObj.get('customClass')}`}>{legalBasisObj.get('label')}</div>;
  }

  getCatCols = () => {
    return List([
      Map({
        title: 'Vendor Scan Category',
        dataIndex: 'vendorScanCategory',
        key: 'vendorScanCategory',
        render: (text, record, index) => (
          <Select
            onChange={(value) =>
              this.updateCategorization('vendorScanCategory', value, index)
            }
            placeholder="Select"
            value={text}
          >
            {vendorCategories.map((p, pi) => (
              <Option key={pi} value={p}>
                {p}
              </Option>
            ))}
          </Select>
        ),
        width: '20%',
      }),
      Map({
        title: 'Purposes',
        dataIndex: 'purpose',
        key: 'purpose',
        render: (text, record, index) => 
          this.props.useGATPDefaults && this.props.isGATP ? this.getPurpose(text) : (
          <Select
            onChange={(value) => this.updateCategorization('purpose', value, index)}
            placeholder="Select"
            value={text}
          >
            {this.props.getSubPurposes().map((p, pi) => (
              <Option key={pi} value={p.get('id') || p.get('name')}>
                {p.get('name')}
              </Option>
            ))}
          </Select>
        ),
        width: '50%',
      }),
      Map({
        title: 'Legal Basis',
        dataIndex: 'legalBasis',
        key: 'legalBasis',
        render: (text, record, index) => 
          ((this.props.useGATPDefaults && this.props.isGATP) || this.getDuplicatePurposeIndex(record['purpose'], index)) ? 
            this.legalBasisWrapper(text) :
            <Select
              onChange={(value) => this.updateCategorization('legalBasis', value, index, record['purpose'])}
              placeholder="Select"
              value={text}
              disabled={!record['purpose']}
              className="categorization-legal-basis"
            >
            {legalBasis.map((lb, lbi) => (
              <Option key={lbi} value={lb.get('value')}>
                <span className={`li-tag ${lb.get('customClass')}`}>{lb.get('label')}</span>
              </Option>
            ))}
            </Select>,
        width: '25%',
      }),
      Map({
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text, record, index) =>
          index == 0 ? (
            <Button
              icon={<PlusCircleFilled />}
              className="btn-add-row"
              onClick={() => this.errorCheck()}
            />
          ) : (
            <button
              className="avo-dialogue-icons avo-sp-delete"
              onClick={() => this.props.deleteDefCategoryRow(index)}
            ></button>
          ),
        width: '5%',
      }),
    ]);
  };

  emptyRowErr = (record, index) => index === 0 ? this.state.emptyRow : '';

  render() {
    const columns = this.props.useGATPDefaults && this.props.isGATP ? this.getCatCols().delete(0).pop() : this.getCatCols();
    const categorizationData = this.props.useGATPDefaults && this.props.isGATP ? this.props.gATPConfig : this.props.vendorScanCategorizations;
    return (
      <div className="vendor-cat-wrapper">
        <Button type="link" className="back-to-scan" onClick={() => this.props.toggleCategorization()}>
          <LeftOutlined /> Website Vendor Scan
        </Button>
        <Divider />
        <p>
          In this section you will create the default vendor mapping for new
          vendors categories to custom purposes. You must have a set of custom
          purposes defined to map the scanned purposes to in order to complete
          this section. Whenever you do a vendor scan, the mapping you define
          here will be the default for mapping that will be used when adding or
          updating vendors.
        </p>
        <WebsiteScanTable
          columns={columns.toJS()}
          dataSource={categorizationData.toJS()}
          rowClassName={this.emptyRowErr}
        />
      </div>
    );
  }
}
