import PropTypes from 'prop-types';
import React from 'react';
import StepSatisfyOption from './StepSatisfyOption';
import { SearchReferrerGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js'

export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(SearchReferrerGate).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    handleChangeSatisfy = (continueOnSatisfy) => {
        const data = this.props.data.set('continue', continueOnSatisfy);
        this.props.onChange(data);
    };

    render() {

        var continueOnSatisfy = this.props.data.continue ? "Yes" : "No";

        if (!this.props.editing) {
            return (<div>Continue if Search Referrer: { continueOnSatisfy }</div>);
        } else {
            return (
                <div className="step continue-halt">
                    <label>Continue if Search Referrer: </label>
                      <StepSatisfyOption
                        value={ this.props.data.continue }
                        onChange={ this.handleChangeSatisfy }
                        focusOnMount={ true }
                      />
                </div>
            );
        }
    }
}
