import * as types from './action_types';
import { getDnsMessagesAjax } from '../api/ccpa/dns';
import { actionCreator } from './helpers';

export const getDnsMessages = actionCreator(
    getDnsMessagesAjax,
    dnsMessagesPending,
    dnsMessagesLoaded,
    dnsMessagesError,
  );

function dnsMessagesPending() {
  return {
    type: types.DNS_MESSAGES_PENDING,
  };
}

function dnsMessagesLoaded(messages) {
  return {
    type: types.DNS_MESSAGES_LOADED,
    messages,
  };
}

function dnsMessagesError(errorMessage) {
  return {
    type: types.DNS_MESSAGES_ERROR,
    errorMessage,
  };
}