import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import TypeSelector from './components/TypeSelector';
import CancelAndConfirm from './components/CancelAndConfirm';

class GenericStepBlock extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    onAddEvent: PropTypes.func.isRequired,
    types: ImmutablePropTypes.list.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      selectValue: '',
    };
  }

  onAddEvent = () => {
    this.toggleEditing();
    if (!this.state.selectValue) return;
    this.props.onAddEvent(this.state.selectValue);
  };

  toggleEditing = () => {
    this.setState({
      editing: !this.state.editing,
    });
  };

  handleTypeSelect = (value) => {
    this.setState({
      editing: false,
    });
    this.props.onAddEvent(value);
  };

  render() {
    const containerClass = classNames('generic-step-block-container', { editing: this.state.editing });
    let addOrReadable;

    if (this.props.editing) {
      addOrReadable = this.state.editing ? (
        <div className='event-container'>
          <label>add event</label>
          <TypeSelector
            types={ this.props.types }
            selectValue={ this.state.selectValue }
            handleTypeSelect={ this.handleTypeSelect }
          />
          <CancelAndConfirm
            toggleCancel={ this.toggleEditing }
            onConfirm={ this.onAddEvent }
          />
        </div>
      ) : (
        <Button
          onClick={ this.toggleEditing }
          type='dashed'
          className='add-event'
        >
          + add an event
        </Button>
      );
    }

    return (
      <td className={ containerClass }>
        { addOrReadable }
      </td>
    );
  }
}

export default GenericStepBlock;
