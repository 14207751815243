import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Table } from 'antd';
import { browserHistory } from 'react-router';

import {
  CookieNameColumn,
  FirstAppearedScanColumn,
  InformationStorageColumn,
  LastAppearedScanColumn,
  PrevalenceColumn,
  TechnologyCategorisationColumn,
  VendorDetailsColumn,
  VendorDisclosedColumn,
  VendorNameColumn,
  VendorReferenceColumn,
  VendorStatusColumn,
  VendorTypeColumn,
} from '../../shared/DiagnoseColumns';

import {
  getVendorManagementStatusesList,
  getVendorsPriorToConsentFilter,
  getVendorsPriorToConsentList,
  vendorsPriorToConsentExport,
} from '../../../../../actions/diagnose_dashboard_actions';
import TableFilterDropdown from '../../shared/TableFilterDropdown';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import {
  isFiltersProvided,
  mapSelectedFilters,
  requestDataDependOnTableFilters,
  getUniqueVendorIdsList,
} from '../helpers';

const DetailedBreakdown = ({
  getVendorsPriorToConsentList,
  vendorsPriorToConsentList,
  vendorsPriorToConsentListPending,
  vendorsPriorToConsentFilter,
  getVendorsPriorToConsentFilter,
  selectedFilters,
  vendorsPriorToConsentExportPending,
  vendorsPriorToConsentExport,
  userDetails,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const [tableFilters, setTableFilters] = useState(null);
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');

  const getVendorsPriorToConsentListData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getVendorsPriorToConsentList({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
    return res;
  };

  const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
    const query = {
      metric: 'Vendors triggered prior to consent',
      vendors,
      status,
      tag,
      websites: selectedFilters?.websites,
    };
    const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
    if (isCacheValid) {
      return vendorManagementStatuses;
    }
    const res = await getVendorManagementStatusesList(query);
    return res;
  };

  const getVendorsPriorToConsentFiltersData = () => {
    getVendorsPriorToConsentFilter(filters);
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      requestDataDependOnTableFilters(
        { filters: tableFilters || {}, isEnabledVendorStatusManagementFeature },
        (f, vendorId) => getVendorsPriorToConsentListData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
        getVendorManagementStatusesListData,
      );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      getVendorsPriorToConsentFiltersData();
    }
  }, [filters]);

  useEffect(() => {
    if (vendorManagementActivities?.length) {
      getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsList(vendorsPriorToConsentList?.results) }, false);
    }
  }, [vendorManagementActivities]);

  const vendorsFilter = vendorsPriorToConsentFilter?.vendors?.map((filter) => ({ text: filter, value: filter }));
  const technologiesFilter = vendorsPriorToConsentFilter?.technologies?.map((filter) => ({
    text: filter,
    value: filter,
  }));
  const cookiesFilter = vendorsPriorToConsentFilter?.cookies?.map((filter) => ({ text: filter, value: filter }));
  const referenceVendorsFilter = vendorsPriorToConsentFilter?.reference_vendors?.map((filter) => ({
    text: filter,
    value: filter,
  }));
  const legalBasisFilter = vendorsPriorToConsentFilter?.legal_basis?.map((filter) => ({ text: filter, value: filter }));

  const columns = [
    VendorTypeColumn({}),
    VendorNameColumn({ filters: vendorsFilter || [] }),
    ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    PrevalenceColumn,
    {
      title: 'Registered legal bases on IAB TCF',
      dataIndex: 'legal_basis',
      key: 'legal_basis',
      sorter: (a, b) => a.legal_basis.localeCompare(b.legal_basis),
      filters: legalBasisFilter || [],
      filterDropdown: (props) => (
        <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} showSearch multiple />
      ),
      width: 120,
    },
    FirstAppearedScanColumn,
    LastAppearedScanColumn,
    TechnologyCategorisationColumn({ filters: technologiesFilter || [] }),
    InformationStorageColumn,
    CookieNameColumn({ filters: cookiesFilter || [] }),
    VendorDisclosedColumn,
    VendorReferenceColumn({ filters: referenceVendorsFilter || [] }),
    VendorDetailsColumn({ metric: 'Vendors triggered prior to consent' }),
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    vendorsPriorToConsentExport(filters);
  };

  const tableData = useMemo(() => {
    if (!isEnabledVendorStatusManagementFeature) {
      return vendorsPriorToConsentList?.results;
    }

    return vendorsPriorToConsentList?.results?.map((row) => {
      const rowWithVendorStatus = {
        ...row,
        vendor_status:
          vendorManagementStatuses?.filter((vendorStatus) => {
            return vendorStatus.vendor === row.vendor_id;
          }) || [],
      };
      return rowWithVendorStatus;
    });
  }, [isEnabledVendorStatusManagementFeature, vendorsPriorToConsentList?.results, vendorManagementStatuses]);

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The table below lists the vendors that we have observed on your properties triggering prior to consent. The
        information detailed should help you determine whether to review the configuration order of your on page vendors
        with your CMP. Triggered prior to consent refers to scans that observe a network call being made by the vendors
        listed below before the user has taken an action on the CMP. This does not necessarily mean the vendor is
        accessing information or storing a cookie, however the recommendation is to scrutinise those that do this in
        line with regulatory guidance.
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container">
        <div className={'filters_block'}>
          <div className={'quick_filters'} />
          <div className={'downloadCSV'}>
            <Button icon={<DownloadOutlined />} onClick={exportCsv} loading={vendorsPriorToConsentExportPending} />
          </div>
        </div>
        <Table
          id="detailed-breakdown"
          scroll={{ x: 'max-content', y: 430 }}
          rowKey={(record) => record?.vendor_id}
          rowClassName={(record) =>
            record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
              ? 'diagnose-notification-highlight'
              : ''
          }
          dataSource={tableData}
          columns={columns}
          showSorterTooltip={false}
          pagination={{
            current: page,
            pageSize: limit,
            total: tableFilters?.vendor_status?.length && !vendorManagementStatuses.length ? 0 : vendorsPriorToConsentList?.total_count,
            position: ['bottomCenter'],
            onChange: onPaginationChange,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
          }}
          onChange={onTableChange}
          loading={{
            className: 'diagnose-spinning',
            spinning: vendorsPriorToConsentListPending || vendorManagementStatusesPending,
            tip: vendorsPriorToConsentList?.results?.length
              ? ''
              : 'Processing a large query; please wait or leave it open and check later.',
            size: 'large',
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    vendorsPriorToConsentList: store.diagnoseDashboardState.getIn(['vendorsPriorToConsentList', 'value']),
    vendorsPriorToConsentListPending: store.diagnoseDashboardState.get('vendorsPriorToConsentListPending'),
    vendorsPriorToConsentExportPending: store.diagnoseDashboardState.get('vendorsPriorToConsentExportPending'),
    vendorsPriorToConsentFilter: store.diagnoseDashboardState.getIn(['vendorsPriorToConsentFilter', 'value']),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
    vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVendorsPriorToConsentList,
      getVendorsPriorToConsentFilter,
      vendorsPriorToConsentExport,
      getVendorManagementStatusesList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
