import React, { Component } from 'react';
import { Radio } from 'antd';

class Location extends Component {
  updateLocation = (e) => {
    this.props.updateSetting(this.props.settingKey, e.target.value);
  }
  render() {
    return (
      <div className="set-location">
        <h4>{this.props.settingsTitle}</h4>
        <div>
          <Radio.Group value={this.props.value} onChange={this.updateLocation}>
          <Radio.Button value="none">Default</Radio.Button>
          <Radio.Button value="right">Right</Radio.Button>
          <Radio.Button value="left">Left</Radio.Button>
        </Radio.Group>
        </div>
      </div>
    );
  }
}

export default Location;
