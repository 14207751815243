import PropTypes from 'prop-types';
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import RowWithSortableSteps from './RowWithSortableSteps';
import { ScenarioRowRecord } from './components/steps.js';

const SortableRowElement = SortableElement(RowWithSortableSteps);

class SortableRowList extends React.Component {
  static propTypes = {
    steps: PropTypes.instanceOf(ScenarioRowRecord.rowData).isRequired,
    onChange: PropTypes.func.isRequired,
    deleteRow: PropTypes.func.isRequired,
  };

  renderRows = (row, rowIndex) => {
    return (
      <SortableRowElement
        {...this.props}
        index={ rowIndex }
        key={ rowIndex }
        rowIndex={ rowIndex }
        scenarioRow={ row }
        onChange={ data => this.props.onChange(rowIndex, data) }
        deleteRow={ () => this.props.deleteRow(rowIndex) }
      />
    );
  };

  render() {
    return (
      <div>
        {this.props.steps.map(this.renderRows)}
      </div>
    );
  }
}

export default SortableRowList;
