import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { browserHistory } from 'react-router';
import {
  getVendorLists,
  deleteVendorList,
  clearVendorListError,
  activateDeactivateVendorList,
  cloneVendorList,
} from '../../../actions/vendor_list_actions_v2';
import { getAllSites } from '../../../actions/site_actions';
import { VendorList as VendorListRecord } from '../../../records/vendor_list_records';
import { User } from '../../../records/account_records';
import RegulationListGeneric from './RegulationListGeneric';
import { hasFeature } from '../../utils';

class VendorListsV3 extends React.Component {
  static propTypes = {
    vendorLists: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(VendorListRecord)).isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      v2: PropTypes.bool,
    }).isRequired,
    getVendorLists: PropTypes.func.isRequired,
    deleteVendorList: PropTypes.func.isRequired,
    clearVendorListError: PropTypes.func.isRequired,
    activateDeactivateVendorLis: PropTypes.func.isRequired,
    cloneVendorList: PropTypes.func.isRequired,
  }

  componentDidMount = () => {
    if (this.props.currentUser) {
      this.fetchVendorLists();
    }
  }

  componentDidUpdate(prevProps) {
    //fetches VLs if user switches between v2 and nonIab views in sequence
    if(prevProps.route.nonIab !== this.props.route.nonIab || !prevProps.currentUser && this.props.currentUser) {
      this.fetchVendorLists();
    }
  }

  fetchVendorLists = () => {
    const { path } = this.props.route;
    this.props.getVendorLists(path.includes('non-iab'));
  }

  handleCreate = () => {
    const v2 = this.props.route.v2 ? '_v2' : '';
    browserHistory.push(`/consent${v2}/vendor_lists/create?non_iab=${Boolean(this.props.route.nonIab)}`);
  };

  handleEdit = (id) => {
    const v2 = this.props.route.v2 ? '_v2' : '';
    browserHistory.push(`/consent${v2}/vendor_lists/edit?id=${id}&non_iab=${Boolean(this.props.route.nonIab)}`);
  };

  calculateAccess = (sites, vlSites) => {
    if (this.props.currentUser?.allSiteAccess) return false;
    const siteSet = new Set(sites);
    let viewOnly = false;
    vlSites.forEach(s => {
      if (!siteSet.has(s)) viewOnly = true;
    });
    return viewOnly;
  };

  getVlNameError = (oldVlName, newVlName) => {
    let nameError = null;
    const duplicateName = this.props.vendorLists.map(vl => vl.name).find(vl => vl === newVlName.trim());
    if(newVlName.trim().length === 0){
      nameError = <>Name field can not be empty</>
    }else if(duplicateName && duplicateName !== oldVlName) {
      nameError = <>Vendor List with name '<strong>{newVlName}</strong>' already exists</>
    }
    return nameError; 
  }

  render() {
    // if (this.props.pendingRequestsMap.some(request => request) || !this.props.currentUser || !this.props.sites) {
    //   return <Loading />;
    // }

    /* GET ACCOUNT SITES */
    let sites = this.props.sites;
    if (this.props.currentUser?.siteAccess) {
      sites = sites.filter((s) =>
        this.props.currentUser?.siteAccess.includes(s.id)
      );
    }
    sites = sites.filter(
      (s) =>
        s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')
    );
    // sites = sites.map(s => s.id);

    let readOnly = false;
    if (this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('Vendor List - GDPR')) {
      readOnly = true;
    }

    const isAccountPartOfWave1 = this.props.route.nonIab ? true : hasFeature(this.props.currentUser, 'tcf2.2');

    return (
      <div className='vendor-lists-container'>
        <RegulationListGeneric
          currentUser={this.props.currentUser}
          vendorLists={this.props.vendorLists}
          vendorListError={this.props.vendorListError}
          sites={sites}
          siteGroups={this.props.siteGroups}
          vendorListsPending={this.props.pendingRequestsMap.get('vendorLists')}
          usnat={this.props.route.usnat}
          nonIab={this.props.route.nonIab}
          readOnly={readOnly}
          calculateAccess={this.calculateAccess}
          entity={"Vendor List"}
          handleCreate={this.handleCreate}
          handleEdit={this.handleEdit}
          cloneVendorList={this.props.cloneVendorList}
          deleteVendorList={this.props.deleteVendorList}
          clearVendorListError={this.props.clearVendorListError}
          activateDeactivateVendorList={this.props.activateDeactivateVendorList}
          isAccountPartOfWave1={isAccountPartOfWave1}
          gdpr
        />
      </div>
    );
  }
}
const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    vendorLists: store.vendorListState.getIn(['vendorLists', 'value']),
    vendorListError: store.vendorListState.getIn(['vendorLists', 'error']),
    pendingRequestsMap: new Map({
      vendorLists: store.vendorListState.getIn(['vendorLists', 'pending']),
    }),
    sites: store.siteState.getIn(['sites', 'value']),
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    savePending: store.vendorListState.get('savePending'),
  };
};

export default connect(
  mapStateToProps, {
  getVendorLists,
  deleteVendorList,
  cloneVendorList,
  clearVendorListError,
  activateDeactivateVendorList,
  getAllSites
},
)(VendorListsV3);
