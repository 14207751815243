/* eslint-disable default-case */
import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const emptyPaginationResponse = { results: [], total_count: 0 };
export const initialState = Map({
    summaryDiagnose: new StoreRecord({ value: new List([]) }),
    summaryPending: false,
    vendorsNotOnVendorList: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    vendorsNotOnVendorListPending: false,
    vendorsNotOnVendorFilter: new StoreRecord({ value: {} }),
    vendorsNotOnVendorExportPending: false,
    vendorsPriorToConsentList: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    vendorsPriorToConsentListPending: false,
    vendorsPriorToConsentExportPending: false,
    vendorsPriorToConsentFilter: new StoreRecord({ value: {} }),
    trackingTechDetectedList: new StoreRecord({ value:{ results: new List([]), total_count: 0}}),
    trackingTechDetectedListPending: false,
    disclosedVendors: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    disclosedVendorsPending: false,
    disclosedVendorsFilter: new StoreRecord({ value: {} }),
    disclosedVendorsExportPending: false,
    selectedMetrics: new StoreRecord({ value: [
            'Disclosed Vendors',
            'Non disclosed vendors observed',
            'Vendors triggered prior to consent',
            'Cookies with long lifespans',
            'Possible Fingerprinting',
            'Data leaving the EEA',
            'All Cookies Found',
            'Vendors after opt out',
            'Tracking Tech',
        ],
    }),
    metricsList: new StoreRecord({ value: [
            'Disclosed Vendors',
            'Non disclosed vendors observed',
            'Vendors triggered prior to consent',
            'Cookies with long lifespans',
            'Possible Fingerprinting',
            'Data leaving the EEA',
            'All Cookies Found',
            'Vendors after opt out',
            'Tracking Tech',
        ],
    }),
    selectedFilters: new StoreRecord({ value: {} }),
    regionsList: new StoreRecord({ value: [] }),
    possibleFingerprintingList: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    possibleFingerprintingListPending: false,
    possibleFingerprintingFilter: new StoreRecord({ value: {} }),
    possibleFingerprintingExportPending: false,
    dataLeavingEeaList: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    dataLeavingEeaListPending: false,
    dataLeavingEeaFilter: new StoreRecord({ value: {} }),
    dataLeavingEeaExportPending: false,
    cookiesWithLongLifespansList: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    cookiesWithLongLifespansListPending: false,
    cookiesWithLongLifespansFilter: new StoreRecord({ value: {} }),
    cookiesWithLongLifespansExportPending: false,
    allCookiesFoundList: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    allCookiesFoundListPending: false,
    allCookiesFoundFilter: new StoreRecord({ value: {} }),
    allCookiesFoundExportPending: false,
    vendorDetails: new StoreRecord({ value: {} }),
    vendorDetailsPending: false,
    vendorWebsiteDetails: new StoreRecord({ value: new List([]) }),
    vendorWebsiteDetailsPending: false,
    vendorDetailsExportPending: false,
    cookieDetailsList: new StoreRecord({ value: new List([]) }),
    cookieDetailsListPending: false,
    cookieDetailsListFilter: new StoreRecord({ value: {} }),
    cookieDetailsExportPending: false,
    websitePropertiesList: new StoreRecord({ value: new List([]) }),
    websitePropertiesListPending: false,
    vendorStatusSelectedPropertyList: new StoreRecord({ value: new List([])}),
    vendorStatusFilterList: new StoreRecord({ value: ['Unreviewed', 'Under Review', 'Approved', 'Action Required', 'New', 'Change Detected', 'Approved with risk']}),
    vendorManagementStatuses: new StoreRecord({ value: new List([]) }),
    vendorManagementStatusesParams: new StoreRecord({ value: {} }),
    vendorManagementStatusesPending: false,
    vendorManagementActivities: new StoreRecord({ value: new List([]) }),
    vendorManagementActivitiesList: new StoreRecord({ value: new List([]) }),
    getVendorManagementActivitiesListPending: new StoreRecord({ value: false }),
    addVendorManagementActivitiesPending: false,
    isVendorStatusMultiselectOperationActive: new StoreRecord({ value: false }),
    vendorsAfterOptOut: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    vendorsAfterOptOutPending: false,
    vendorsAfterOptOutFilter: new StoreRecord({ value: {} }),
    vendorsAfterOptOutFilterPending: false,
    vendorsAfterOptOutExportPending: false,
    vendorsAfterOptOutWebsites: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    vendorsAfterOptOutWebsitePending: false,
    vendorsAfterOptOutVendorData: new StoreRecord({ value: {} }),
    vendorsAfterOptOutVendorDataPending: false,
    vendorsAfterOptOutWebsitesExportPending: false,
    vendorsAfterOptOutCookie: new StoreRecord({ value: { results: new List([]), total_count: 0 } }),
    vendorsAfterOptOutCookiePending: false,
    vendorsAfterOptOutCookieFilter: new StoreRecord({ value: {} }),
    vendorsAfterOptOutCookieFilterPending: false,
    vendorsAfterOptOutCookieExportPending: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SUMMARY_PENDING:
            return setValue(state, ['summaryDiagnose'], []).set('summaryPending', true);

        case types.SUMMARY_LOADED:
            return setValue(state, ['summaryDiagnose'], action.summaryDiagnose).set('summaryPending', false);

        case types.SUMMARY_ERROR:
            return setError(state, ['summaryDiagnose'], action.errorMessage).set('summaryPending', false);

        case types.SET_METRICS_LIST:
            return setValue(state, ['selectedMetrics'], action.selectedMetrics);

        case types.SET_AVAILABLE_METRICS:
                return setValue(state, ['metricsList'], action.metricsList);

        case types.SET_SELECTED_FILTERS:
            return setValue(state, ['summaryDiagnose'], [])
            .setIn(['selectedFilters'].concat('value'), action.selectedFilters)
            .setIn(['vendorsNotOnVendorList'].concat('value'), { results: new List([]), total_count: 0 })
            .setIn(['cookiesWithLongLifespansList'].concat('value'), { results: new List([]), total_count: 0 })
            .setIn(['allCookiesFoundList'].concat('value'), { results: new List([]), total_count: 0 })
            .setIn(['dataLeavingEeaList'].concat('value'), { results: new List([]), total_count: 0 })
            .setIn(['possibleFingerprintingList'].concat('value'), { results: new List([]), total_count: 0 })
            .setIn(['vendorsPriorToConsentList'].concat('value'), { results: new List([]), total_count: 0 })
            .setIn(['disclosedVendors'].concat('value'), { results: new List([]), total_count: 0 })
            .setIn(['vendorsAfterOptOut'].concat('value'), { results: new List([]), total_count: 0 })
            .setIn(['trackingTechDetectedList'].concat('value'),{ results: new List([]), total_count: 0});

        case types.SET_REGIONS_LIST:
            return setValue(state, ['regionsList'], action.regionsList);


        case types.VENDORS_NOT_ON_VENDORS_LIST_PENDING:
            return setPending(state, ['vendorsNotOnVendorList']).set('vendorsNotOnVendorListPending', true);

        case types.VENDORS_NOT_ON_VENDORS_LIST_LOADED:
            return setValue(state, ['vendorsNotOnVendorList'], action.vendorsNotOnVendorList).set('vendorsNotOnVendorListPending', false);

        case types.VENDORS_NOT_ON_VENDORS_LIST_ERROR:
            return setValue(state, ['vendorsNotOnVendorList'], emptyPaginationResponse).set('vendorsNotOnVendorListPending', false);

        case types.VENDORS_NOT_ON_VENDOR_EXPORT_PENDING:
            return setPending(state, ['vendorsNotOnVendorExport']).set('vendorsNotOnVendorExportPending', true);

        case types.VENDORS_NOT_ON_VENDOR_EXPORT_LOADED:
            return setValue(state, ['vendorsNotOnVendorExport'], action.vendorsNotOnVendorExport).set('vendorsNotOnVendorExportPending', false);

        case types.VENDORS_NOT_ON_VENDOR_EXPORT_ERROR:
            return setError(state, ['vendorsNotOnVendorExport'], action.errorMessage).set('vendorsNotOnVendorExportPending', false);

        case types.VENDORS_NOT_ON_VENDORS_FILTERS_PENDING:
            return setPending(state, ['vendorsNotOnVendorFilter']);

        case types.VENDORS_NOT_ON_VENDORS_FILTERS_LOADED:
            return setValue(state, ['vendorsNotOnVendorFilter'], action.vendorsNotOnVendorFilter);

        case types.VENDORS_NOT_ON_VENDORS_FILTERS_ERROR:
            return setValue(state, ['vendorsNotOnVendorFilter'], {});


        case types.DISCLOSED_VENDORS_PENDING:
            return setPending(state, ['disclosedVendors']).set('disclosedVendorsPending', true);

        case types.DISCLOSED_VENDORS_LOADED:
            return setValue(state, ['disclosedVendors'], action.disclosedVendors).set('disclosedVendorsPending', false);

        case types.DISCLOSED_VENDORS_ERROR:
            return setValue(state, ['disclosedVendors'], emptyPaginationResponse).set('disclosedVendorsPending', false);

        case types.DISCLOSED_VENDORS_FILTERS_PENDING:
            return setPending(state, ['disclosedVendorsFilter']);

        case types.DISCLOSED_VENDORS_FILTERS_LOADED:
            return setValue(state, ['disclosedVendorsFilter'], action.disclosedVendorsFilter);

        case types.DISCLOSED_VENDORS_FILTERS_ERROR:
            return setValue(state, ['disclosedVendorsFilter'], {});

        case types.DISCLOSED_VENDORS_EXPORT_PENDING:
            return setPending(state, ['disclosedVendorsExport']).set('disclosedVendorsExportPending', true);

        case types.DISCLOSED_VENDORS_EXPORT_LOADED:
            return setValue(state, ['disclosedVendorsExport'], action.disclosedVendorsExport).set('disclosedVendorsExportPending', false);

        case types.DISCLOSED_VENDORS_EXPORT_ERROR:
            return setError(state, ['disclosedVendorsExport'], action.errorMessage).set('disclosedVendorsExportPending', false);


        case types.VENDORS_PRIOR_TO_CONSENT_LIST_PENDING:
            return setPending(state, ['vendorsPriorToConsentList']).set('vendorsPriorToConsentListPending', true);

        case types.VENDORS_PRIOR_TO_CONSENT_LIST_LOADED:
            return setValue(state, ['vendorsPriorToConsentList'], action.vendorsPriorToConsentList).set('vendorsPriorToConsentListPending', false);

        case types.VENDORS_PRIOR_TO_CONSENT_LIST_ERROR:
            return setValue(state, ['vendorsPriorToConsentList'], emptyPaginationResponse).set('vendorsPriorToConsentListPending', false);

        case types.VENDORS_PRIOR_TO_CONSENT_EXPORT_PENDING:
            return setPending(state, ['vendorsPriorToConsentExport']).set('vendorsPriorToConsentExportPending', true);

        case types.VENDORS_PRIOR_TO_CONSENT_EXPORT_LOADED:
            return setValue(state, ['vendorsPriorToConsentExport'], action.vendorsPriorToConsentExport).set('vendorsPriorToConsentExportPending', false);

        case types.VENDORS_PRIOR_TO_CONSENT_EXPORT_ERROR:
            return setError(state, ['vendorsPriorToConsentExport'], action.errorMessage).set('vendorsPriorToConsentExportPending', false);

        case types.VENDORS_PRIOR_TO_CONSENT_FILTERS_PENDING:
            return setPending(state, ['vendorsPriorToConsentFilter']);

        case types.VENDORS_PRIOR_TO_CONSENT_FILTERS_LOADED:
            return setValue(state, ['vendorsPriorToConsentFilter'], action.vendorsPriorToConsentFilter);

        case types.VENDORS_PRIOR_TO_CONSENT_FILTERS_ERROR:
            return setValue(state, ['vendorsPriorToConsentFilter'], {});


        case types.POSSIBLE_FINGERPRINTING_LIST_PENDING:
            return setPending(state, ['possibleFingerprintingList']).set('possibleFingerprintingListPending', true);

        case types.POSSIBLE_FINGERPRINTING_LIST_LOADED:
            return setValue(state, ['possibleFingerprintingList'], action.possibleFingerprintingList).set('possibleFingerprintingListPending', false);

        case types.POSSIBLE_FINGERPRINTING_LIST_ERROR:
            return setValue(state, ['possibleFingerprintingList'], emptyPaginationResponse).set('possibleFingerprintingListPending', false);

        case types.POSSIBLE_FINGERPRINTING_FILTERS_PENDING:
            return setPending(state, ['possibleFingerprintingFilter']);

        case types.POSSIBLE_FINGERPRINTING_FILTERS_LOADED:
            return setValue(state, ['possibleFingerprintingFilter'], action.possibleFingerprintingFilter);

        case types.POSSIBLE_FINGERPRINTING_FILTERS_ERROR:
            return setValue(state, ['possibleFingerprintingFilter'], {});

        case types.POSSIBLE_FINGERPRINTING_EXPORT_PENDING:
            return setPending(state, ['possibleFingerprintingExport']).set('possibleFingerprintingExportPending', true);

        case types.POSSIBLE_FINGERPRINTING_EXPORT_LOADED:
            return setValue(state, ['possibleFingerprintingExport'], action.possibleFingerprintingExport).set('possibleFingerprintingExportPending', false);

        case types.POSSIBLE_FINGERPRINTING_EXPORT_ERROR:
            return setError(state, ['possibleFingerprintingExport'], action.errorMessage).set('possibleFingerprintingExportPending', false);


        case types.DATA_LEAVING_EEA_LIST_PENDING:
            return setPending(state, ['dataLeavingEeaList']).set('dataLeavingEeaListPending', true);

        case types.DATA_LEAVING_EEA_LIST_LOADED:
            return setValue(state, ['dataLeavingEeaList'], action.dataLeavingEeaList).set('dataLeavingEeaListPending', false);

        case types.DATA_LEAVING_EEA_LIST_ERROR:
            return setValue(state, ['dataLeavingEeaList'], emptyPaginationResponse).set('dataLeavingEeaListPending', false);

        case types.DATA_LEAVING_EEA_FILTERS_PENDING:
            return setPending(state, ['dataLeavingEeaFilter']);

        case types.DATA_LEAVING_EEA_FILTERS_LOADED:
            return setValue(state, ['dataLeavingEeaFilter'], action.dataLeavingEeaFilter);

        case types.DATA_LEAVING_EEA_FILTERS_ERROR:
            return setValue(state, ['dataLeavingEeaFilter'], {});

        case types.DATA_LEAVING_EEA_EXPORT_PENDING:
            return setPending(state, ['dataLeavingEeaExport']).set('dataLeavingEeaExportPending', true);

        case types.DATA_LEAVING_EEA_EXPORT_LOADED:
            return setValue(state, ['dataLeavingEeaExport'], action.dataLeavingEeaExport).set('dataLeavingEeaExportPending', false);

        case types.DATA_LEAVING_EEA_EXPORT_ERROR:
            return setError(state, ['dataLeavingEeaExport'], action.errorMessage).set('dataLeavingEeaExportPending', false);


        case types.COOKIES_WITH_LONG_LIFESPANS_LIST_PENDING:
            return setPending(state, ['cookiesWithLongLifespansList']).set('cookiesWithLongLifespansListPending', true);

        case types.COOKIES_WITH_LONG_LIFESPANS_LIST_LOADED:
            return setValue(state, ['cookiesWithLongLifespansList'], action.cookiesWithLongLifespansList).set('cookiesWithLongLifespansListPending', false);

        case types.COOKIES_WITH_LONG_LIFESPANS_LIST_ERROR:
            return setValue(state, ['cookiesWithLongLifespansList'], emptyPaginationResponse).set('cookiesWithLongLifespansListPending', false);

        case types.COOKIES_WITH_LONG_LIFESPANS_FILTERS_PENDING:
            return setPending(state, ['cookiesWithLongLifespansFilter']);

        case types.COOKIES_WITH_LONG_LIFESPANS_FILTERS_LOADED:
            return setValue(state, ['cookiesWithLongLifespansFilter'], action.cookiesWithLongLifespansFilter);

        case types.COOKIES_WITH_LONG_LIFESPANS_FILTERS_ERROR:
            return setValue(state, ['cookiesWithLongLifespansFilter'], {});

        case types.COOKIES_WITH_LONG_LIFESPANS_EXPORT_PENDING:
            return setPending(state, ['cookiesWithLongLifespansExport']).set('cookiesWithLongLifespansExportPending', true);

        case types.COOKIES_WITH_LONG_LIFESPANS_EXPORT_LOADED:
            return setValue(state, ['cookiesWithLongLifespansExport'], action.cookiesWithLongLifespansExport).set('cookiesWithLongLifespansExportPending', false);

        case types.COOKIES_WITH_LONG_LIFESPANS_EXPORT_ERROR:
            return setError(state, ['cookiesWithLongLifespansExport'], action.errorMessage).set('cookiesWithLongLifespansExportPending', false);


        case types.ALL_COOKIES_FOUND_LIST_PENDING:
            return setPending(state, ['allCookiesFoundList']).set('allCookiesFoundListPending', true);

        case types.ALL_COOKIES_FOUND_LIST_LOADED:
            return setValue(state, ['allCookiesFoundList'], action.allCookiesFoundList).set('allCookiesFoundListPending', false);

        case types.ALL_COOKIES_FOUND_LIST_ERROR:
            return setValue(state, ['allCookiesFoundList'], emptyPaginationResponse).set('allCookiesFoundListPending', false);

        case types.ALL_COOKIES_FOUND_FILTERS_PENDING:
            return setPending(state, ['allCookiesFoundFilter']);

        case types.ALL_COOKIES_FOUND_FILTERS_LOADED:
            return setValue(state, ['allCookiesFoundFilter'], action.allCookiesFoundFilter);

        case types.ALL_COOKIES_FOUND_FILTERS_ERROR:
            return setValue(state, ['allCookiesFoundFilter'], {});

        case types.ALL_COOKIES_FOUND_EXPORT_PENDING:
            return setPending(state, ['allCookiesFoundExport']).set('allCookiesFoundExportPending', true);

        case types.ALL_COOKIES_FOUND_EXPORT_LOADED:
            return setValue(state, ['allCookiesFoundExport'], action.allCookiesFoundExport).set('allCookiesFoundExportPending', false);

        case types.ALL_COOKIES_FOUND_EXPORT_ERROR:
            return setError(state, ['allCookiesFoundExport'], action.errorMessage).set('allCookiesFoundExportPending', false);

        case types.VENDORS_DETAILS_PENDING:
            return setPending(state, ['vendorDetails']).set('vendorDetailsPending', true);

        case types.VENDORS_DETAILS_LOADED:
            return setValue(state, ['vendorDetails'], action.vendorDetails).set('vendorDetailsPending', false);

        case types.VENDORS_DETAILS_ERROR:
            return setError(state, ['vendorDetails'], action.errorMessage).set('vendorDetailsPending', false);

        case types.VENDORS_DETAILS_CLEAR:
            return setValue(state, ['vendorDetails'], {});

        case types.VENDORS_WEBSITE_DETAILS_PENDING:
            return setPending(state, ['vendorWebsiteDetails']).set('vendorWebsiteDetailsPending', true);

        case types.VENDORS_WEBSITE_DETAILS_LOADED:
            return setValue(state, ['vendorWebsiteDetails'], action.vendorWebsiteDetails).set('vendorWebsiteDetailsPending', false);

        case types.VENDORS_WEBSITE_DETAILS_ERROR:
            return setError(state, ['vendorWebsiteDetails'], action.errorMessage).set('vendorWebsiteDetailsPending', false);

        case types.VENDORS_WEBSITE_DETAILS_CLEAR:
            return setValue(state, ['vendorWebsiteDetails'], {});

        case types.VENDOR_DETAILS_EXPORT_PENDING:
            return setPending(state, ['vendorDetailsExport']).set('vendorDetailsExportPending', true);

        case types.VENDOR_DETAILS_EXPORT_LOADED:
            return setValue(state, ['vendorDetailsExport'], action.vendorDetailsExport).set('vendorDetailsExportPending', false);

        case types.VENDOR_DETAILS_EXPORT_ERROR:
            return setError(state, ['vendorDetailsExport'], action.errorMessage).set('vendorDetailsExportPending', false);


        case types.COOKIE_DETAILS_LIST_PENDING:
            return setPending(state, ['cookieDetailsList']).set('cookieDetailsListPending', true);

        case types.COOKIE_DETAILS_LIST_LOADED:
            return setValue(state, ['cookieDetailsList'], action.cookieDetailsList).set('cookieDetailsListPending', false);

        case types.COOKIE_DETAILS_LIST_ERROR:
            return setError(state, ['cookieDetailsList'], action.errorMessage).set('cookieDetailsListPending', false);

        case types.COOKIE_DETAILS_LIST_FILTERS_PENDING:
            return setPending(state, ['cookieDetailsListFilter']);

        case types.COOKIE_DETAILS_LIST_FILTERS_LOADED:
            return setValue(state, ['cookieDetailsListFilter'], action.cookieDetailsListFilter);

        case types.COOKIE_DETAILS_LIST_FILTERS_ERROR:
            return setError(state, ['cookieDetailsListFilter'], action.errorMessage);

        case types.COOKIE_DETAILS_EXPORT_PENDING:
            return setPending(state, ['cookieDetailsExport']).set('cookieDetailsExportPending', true);

        case types.COOKIE_DETAILS_EXPORT_LOADED:
            return setValue(state, ['cookieDetailsExport'], action.cookieDetailsExport).set('cookieDetailsExportPending', false);

        case types.COOKIE_DETAILS_EXPORT_ERROR:
            return setError(state, ['cookieDetailsExport'], action.errorMessage).set('cookieDetailsExportPending', false);

        case types.SET_WEBSITE_PROPERTIES_LIST:
            return setValue(state, ['websitePropertiesList'], action.websitePropertiesList);

        case types.SET_VENDOR_STATUS_SELECTED_PROPERTY_LIST:
            return setValue(state, ['vendorStatusSelectedPropertyList'], action.vendorStatusSelectedPropertyList);

        case types.GET_VENDOR_MANAGEMENT_ACTIVITIES_LIST:
            return setValue(state, ['vendorManagementActivitiesList'], action.vendorManagementActivitiesList);

        case types.VENDOR_MANAGEMENT_ACTIVITIES_LIST_PENDING:
            return setValue(state, ['getVendorManagementActivitiesListPending'], action.getVendorManagementActivitiesListPending);

        case types.GET_VENDOR_MANAGEMENT_ACTIVITIES_LIST_CLEAR:
            return setValue(state, ['vendorManagementActivitiesList'], []);

        case types.VENDOR_MANAGEMENT_STATUSES_PENDING:
            return setPending(state, ['vendorManagementStatuses']).set('vendorManagementStatusesPending', true);

        case types.VENDOR_MANAGEMENT_STATUSES_LOADED:
            return setValue(state, ['vendorManagementStatuses'], action.vendorManagementStatuses)
                .set('vendorManagementStatusesPending', false)
                .set('vendorManagementStatusesParams', action.query);

        case types.VENDOR_MANAGEMENT_STATUSES_ERROR:
            return setError(state, ['vendorManagementStatuses'], action.errorMessage).set('vendorManagementStatusesPending', false);

        case types.ADD_VENDOR_MANAGEMENT_ACTIVITIES_PENDING:
            return setPending(state, ['vendorManagementActivities']).set('addVendorManagementActivitiesPending', true);

        case types.ADD_VENDOR_MANAGEMENT_ACTIVITIES_LOADED:
            return setValue(state, ['vendorManagementActivities'], action.vendorManagementActivities).set('addVendorManagementActivitiesPending', false);

        case types.ADD_VENDOR_MANAGEMENT_ACTIVITIES_ERROR:
            return setError(state, ['vendorManagementActivities'], action.errorMessage).set('addVendorManagementActivitiesPending', false);

        case types.VENDOR_MANAGEMENT_ACTIVITIES_LIST_CLEAR:
            return setValue(state, ['vendorManagementActivities'], []);

        case types.SET_IS_VENDOR_STATUS_MULTISELECT_OPERATION_ACTIVE:
            return setValue(state, ['isVendorStatusMultiselectOperationActive'], action.isVendorStatusMultiselectOperationActive);

        case types.SET_VENDOR_STATUS_FILTER_LIST:
            return setValue(state, ['vendorStatusFilterList'], action.vendorStatusFilterList);

        case types.VENDORS_AFTER_OPT_OUT_PENDING:
            return setPending(state, ['vendorsAfterOptOut']).set('vendorsAfterOptOutPending', true);

        case types.VENDORS_AFTER_OPT_OUT_LOADED:
            return setValue(state, ['vendorsAfterOptOut'], action.vendorsAfterOptOut).set('vendorsAfterOptOutPending', false);

        case types.VENDORS_AFTER_OPT_OUT_ERROR:
            return setValue(state, ['vendorsAfterOptOut'], emptyPaginationResponse).set('vendorsAfterOptOutPending', false);

        case types.VENDORS_AFTER_OPT_OUT_FILTERS_PENDING:
            return setPending(state, ['vendorsAfterOptOutFilter']);

        case types.VENDORS_AFTER_OPT_OUT_FILTERS_LOADED:
            return setValue(state, ['vendorsAfterOptOutFilter'], action.vendorsAfterOptOutFilter);

        case types.VENDORS_AFTER_OPT_OUT_FILTERS_ERROR:
            return setValue(state, ['vendorsAfterOptOutFilter'], {});

        case types.VENDORS_AFTER_OPT_OUT_VENDOR_DATA_PENDING:
            return setPending(state, ['vendorsAfterOptOutVendorData']).set('vendorsAfterOptOutVendorDataPending', true);

        case types.VENDORS_AFTER_OPT_OUT_VENDOR_DATA_LOADED:
            return setValue(state, ['vendorsAfterOptOutVendorData'], action.vendorsAfterOptOutVendorData).set('vendorsAfterOptOutVendorDataPending', false);

        case types.VENDORS_AFTER_OPT_OUT_VENDOR_DATA_ERROR:
            return setError(state, ['vendorsAfterOptOutVendorData'], action.errorMessage).set('vendorsAfterOptOutVendorDataPending', false);

        case types.VENDORS_AFTER_OPT_OUT_VENDOR_DATA_CLEAR:
            return setValue(state, ['vendorsAfterOptOutVendorData'], {});

        case types.VENDORS_AFTER_OPT_OUT_WEBSITES_PENDING:
            return setPending(state, ['vendorsAfterOptOutWebsites']).set('vendorsAfterOptOutWebsitePending', true);

        case types.VENDORS_AFTER_OPT_OUT_WEBSITES_LOADED:
            return setValue(state, ['vendorsAfterOptOutWebsites'], action.vendorsAfterOptOutWebsites).set('vendorsAfterOptOutWebsitePending', false);

        case types.VENDORS_AFTER_OPT_OUT_WEBSITES_ERROR:
            return setError(state, ['vendorsAfterOptOutWebsites'], action.errorMessage).set('vendorsAfterOptOutWebsitePending', false);

        case types.VENDORS_AFTER_OPT_OUT_WEBSITES_CLEAR:
            return setValue(state, ['vendorsAfterOptOutWebsites'], {});

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_PENDING:
            return setPending(state, ['vendorsAfterOptOutCookie']).set('vendorsAfterOptOutCookiePending', true);

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_LOADED:
            return setValue(state, ['vendorsAfterOptOutCookie'], action.vendorsAfterOptOutCookie).set('vendorsAfterOptOutCookiePending', false);

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_ERROR:
            return setError(state, ['vendorsAfterOptOutCookie'], action.errorMessage).set('vendorsAfterOptOutCookiePending', false);

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_PENDING:
            return setPending(state, ['vendorsAfterOptOutCookieFilter']);

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_LOADED:
            return setValue(state, ['vendorsAfterOptOutCookieFilter'], action.vendorsAfterOptOutCookieFilter);

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_ERROR:
            return setError(state, ['vendorsAfterOptOutCookieFilter'], action.errorMessage);
        
        case types.VENDORS_AFTER_OPT_OUT_EXPORT_PENDING:
            return setPending(state, ['vendorsAfterOptOutExport']).set('vendorsAfterOptOutExportPending', true);

        case types.VENDORS_AFTER_OPT_OUT_EXPORT_LOADED:
            return setValue(state, ['vendorsAfterOptOutExport'], action.vendorsAfterOptOutExport).set('vendorsAfterOptOutExportPending', false);

        case types.VENDORS_AFTER_OPT_OUT_EXPORT_ERROR:
            return setError(state, ['vendorsAfterOptOutExport'], action.errorMessage).set('vendorsAfterOptOutExportPending', false);

        case types.VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_PENDING:
            return setPending(state, ['vendorsAfterOptOutWebsitesExport']).set('vendorsAfterOptOutWebsitesExportPending', true);

        case types.VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_LOADED:
            return setValue(state, ['vendorsAfterOptOutWebsitesExport'], action.vendorsAfterOptOutWebsitesExport).set('vendorsAfterOptOutWebsitesExportPending', false);

        case types.VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_ERROR:
            return setError(state, ['vendorsAfterOptOutWebsitesExport'], action.errorMessage).set('vendorsAfterOptOutWebsitesExportPending', false);

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_PENDING:
            return setPending(state, ['vendorsAfterOptOutCookieExport']).set('vendorsAfterOptOutCookieExportPending', true);

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_LOADED:
            return setValue(state, ['vendorsAfterOptOutCookieExport'], action.vendorsAfterOptOutCookieExport).set('vendorsAfterOptOutCookieExportPending', false);

        case types.VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_ERROR:
            return setError(state, ['vendorsAfterOptOutCookieExport'], action.errorMessage).set('vendorsAfterOptOutCookieExportPending', false);

        case types.TRACKING_TECH_DETECTED_LIST_PENDING:
            return setPending(state, ['trackingTechDetectedList']).set('trackingTechDetectedListPending', true);
        
        case types.TRACKING_TECH_DETECTED_LIST_LOADED:
            return setValue(state, ['trackingTechDetectedList'], action.trackingTechDetectedList).set('trackingTechDetectedListPending', false);
        
        case types.TRACKING_TECH_DETECTED_LIST_ERROR: 
            return setError(state, ['trackingTechDetectedList'],action.errorMessage).set('trackingTechDetectedListPending', false)

        case types.TRACKING_TECH_DETECTED_FILTERS_PENDING:
            return setPending(state, ['trackingTechDetectedFilters']).set('trackingTechDetectedFiltersPending', true);
        
        case types.TRACKING_TECH_DETECTED_FILTERS_LOADED:
            return setValue(state, ['trackingTechDetectedFilters'], action.trackingTechDetectedFilters).set('trackingTechDetectedFiltersPending', false);
        
        case types.TRACKING_TECH_DETECTED_FILTERS_ERROR: 
            return setError(state, ['trackingTechDetectedFilters'],action.errorMessage).set('trackingTechDetectedFiltersPending', false)

        case types.COOKIELESS_TECH_DETECTED_LIST_PENDING:
            return setPending(state, ['cookielessTechDetectedList']).set('cookielessTechDetectedListPending', true);
        
        case types.COOKIELESS_TECH_DETECTED_LIST_LOADED:
            return setValue(state, ['cookielessTechDetectedList'], action.cookielessTechDetectedList).set('cookielessTechDetectedListPending', false);
        
        case types.COOKIELESS_TECH_DETECTED_LIST_ERROR: 
            return setError(state, ['cookielessTechDetectedList'],action.errorMessage).set('cookielessTechDetectedListPending', false)
            
        case types.PIXELS_TECH_DETECTED_LIST_PENDING:
            return setPending(state, ['pixelsTechDetectedList']).set('pixelsTechDetectedListPending', true);
        
        case types.PIXELS_TECH_DETECTED_LIST_LOADED:
            return setValue(state, ['pixelsTechDetectedList'], action.pixelsTechDetectedList).set('pixelsTechDetectedListPending', false);
        
        case types.PIXELS_TECH_DETECTED_LIST_ERROR: 
            return setError(state, ['pixelsTechDetectedList'],action.errorMessage).set('pixelsTechDetectedListPending', false)
        
        case types.PIXELS_PARAMS_TECH_DETECTED_LIST_PENDING:
            return setPending(state, ['pixelsParamsTechDetectedList']).set('pixelsParamsTechDetectedListPending', true);
        
        case types.PIXELS_PARAMS_TECH_DETECTED_LIST_LOADED:
            return setValue(state, ['pixelsParamsTechDetectedList'], action.pixelsParamsTechDetectedList).set('pixelsParamsTechDetectedListPending', false);
        
        case types.PIXELS_PARAMS_TECH_DETECTED_LIST_ERROR: 
            return setError(state, ['pixelsParamsTechDetectedList'],action.errorMessage).set('pixelsParamsTechDetectedListPending', false)

        case types.CHATBOTS_TECH_DETECTED_LIST_PENDING:
            return setPending(state, ['chatbotsTechDetectedList']).set('chatbotsTechDetectedListPending', true);
        
        case types.CHATBOTS_TECH_DETECTED_LIST_LOADED:
            return setValue(state, ['chatbotsTechDetectedList'], action.chatbotsTechDetectedList).set('chatbotsTechDetectedListPending', false);
        
        case types.CHATBOTS_TECH_DETECTED_LIST_ERROR: 
            return setError(state, ['chatbotsTechDetectedList'],action.errorMessage).set('chatbotsTechDetectedListPending', false)

        case types.SESSION_REPLAY_TECH_DETECTED_LIST_PENDING:
            return setPending(state, ['sessionReplaysTechDetectedList']).set('sessionReplaysTechDetectedListPending', true);
        
        case types.SESSION_REPLAY_TECH_DETECTED_LIST_LOADED:
            return setValue(state, ['sessionReplaysTechDetectedList'], action.sessionReplaysTechDetectedList).set('sessionReplaysTechDetectedListPending', false);
        
        case types.SESSION_REPLAY_TECH_DETECTED_LIST_ERROR: 
            return setError(state, ['sessionReplaysTechDetectedList'],action.errorMessage).set('sessionReplaysTechDetectedListPending', false)

        case types.PIXELS_TECH_HAR_FILE_EXPORT_PENDING:
            return setPending(state, ['pixelsHarFileData']).set('pixelsHarFileDataPending', true);
        
        case types.PIXELS_TECH_HAR_FILE_EXPORT_LOADED:
            return setValue(state, ['pixelsHarFileData'], action.pixelsHarFileData).set('pixelsHarFileDataPending', false);
        
        case types.PIXELS_TECH_HAR_FILE_EXPORT_PENDING: 
            return setError(state, ['pixelsHarFileData'],action.errorMessage).set('pixelsHarFileDataPending', false)
        
        case types. COOKIELESS_TECH_HAR_FILE_EXPORT_PENDING:
            return setPending(state, ['cookielessHarFileData']).set('cookielessHarFileDataPending', true);
        
        case types. COOKIELESS_TECH_HAR_FILE_EXPORT_LOADED:
            return setValue(state, ['cookielessHarFileData'], action.cookielessHarFileData).set('cookielessHarFileDataPending', false);
        
        case types. COOKIELESS_TECH_HAR_FILE_EXPORT_ERROR: 
            return setError(state, ['cookielessHarFileData'],action.errorMessage).set('cookielessHarFileDataPending', false)
        
        case types. CHATBOT_TECH_HAR_FILE_EXPORT_PENDING:
            return setPending(state, ['chatbotHarFileData']).set('chatbotHarFileDataPending', true);
        
        case types. CHATBOT_TECH_HAR_FILE_EXPORT_LOADED:
            return setValue(state, ['chatbotHarFileData'], action.chatbotHarFileData).set('chatbotHarFileDataPending', false);
        
        case types. CHATBOT_TECH_HAR_FILE_EXPORT_PENDING: 
            return setError(state, ['chatbotHarFileData'],action.errorMessage).set('chatbotHarFileDataPending', false)

        case types. SESSION_REPLAY_TECH_HAR_FILE_EXPORT_PENDING:
            return setPending(state, ['sessionReplayHarFileData']).set('sessionReplayHarFileDataPending', true);
        
        case types. SESSION_REPLAY_TECH_HAR_FILE_EXPORT_LOADED:
            return setValue(state, ['sessionReplayHarFileData'], action.sessionReplayHarFileData).set('sessionReplayHarFileDataPending', false);
        
        case types. SESSION_REPLAY_TECH_HAR_FILE_EXPORT_PENDING: 
            return setError(state, ['sessionReplayHarFileData'],action.errorMessage).set('sessionReplayHarFileDataPending', false)
        
    }

    return state;
};
