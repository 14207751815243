import moment from 'moment';
import { buildUrl, CHANGE_LOG_URL_V2 } from './helper'
import { changelog } from '../constants';
import { log, safeFunction } from '../util';

/*
  View valid params and body at https://github.com/SourcePointUSA/sp-logging-service
*/

export const changeLogsAjax = async ({ account_id, start, end }) => {
  start = start ? new Date(start).getTime() : moment().subtract(6, 'days').startOf('day').toDate().getTime();
  end = end ? new Date(end).getTime() : new Date().getTime();

  const queryString = Object.entries({ start, end }).reduce((body, [key, val]) => val ? { ...body, [key]: val } : body, {});
  return await (await fetch(buildUrl(CHANGE_LOG_URL_V2, queryString), {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })).json();
}

const postLogs = async (body) => {
  log('change log body: ', body);
  delete body.site_id;
  try {
    const init = { 
      method: 'POST', 
      body: JSON.stringify(body),
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    // dont send vendor list change logs in preprod
    if(!(CHANGE_LOG_URL_V2.includes('preprod') && body.entity_type === 'vendor_list')) {
      const res = await (await fetch(CHANGE_LOG_URL_V2, init)).json();
      log('successfully posted log: %o', res);
    }
  } catch (err) {
    log('error posting log: %o', err);
  };
}

export const createSiteLog = safeFunction(({ accountState }, site) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSiteProps(site),
    op_name: changelog.opcodes.CREATE,
    entity_id: site.id,
    entity_type: changelog.entityTypes.SITE,
    entity_name: site.get('domain')
  });
})

export const updateSiteLog = safeFunction(({ accountState }, site) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSiteProps(site),
    op_name: changelog.opcodes.EDIT,
    entity_id: site.id,
    entity_type: changelog.entityTypes.SITE,
    entity_name: site.get('domain')
  });
})

export const deleteSiteLog = safeFunction(({ accountState }, siteId, args) => {
  postLogs({
    ...addAccountInfo(accountState),
    parent_id: changelog.NO_PARENT,
    parent_name: changelog.NO_PARENT,
    site_ids: [siteId],
    site_names: [args[1]],
    op_name: changelog.opcodes.DELETE,
    entity_id: siteId,
    entity_type: changelog.entityTypes.SITE,
    entity_name: args[1]
  });
})

// campaignLog is for DELETE and CREATE ops
export const campaignLog = safeFunction(({ accountState, siteState }, campaign) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(campaign.site_id, siteState),
    op_name: campaign.deleted_at ? changelog.opcodes.DELETE : changelog.opcodes.CREATE,
    entity_id: campaign.id,
    entity_type: (campaign.status === 'stage')
      ? changelog.entityTypes.campaign.STAGE
      : changelog.entityTypes.campaign.PUBLIC,
    entity_name: campaign.description,
  });
})

export const createPartitionLog = safeFunction(({ accountState, siteState }, partition) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(partition.site_id, siteState),
    op_name: changelog.opcodes.CREATE,
    entity_id: partition.id,
    entity_type: changelog.entityTypes.PARTITION,
    entity_name: partition.description
  });
})

export const deletePartitionLog = safeFunction(({ accountState, siteState }, partitionId, [, description, siteId]) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(siteId, siteState),
    op_name: changelog.opcodes.DELETE,
    entity_id: partitionId,
    entity_type: changelog.entityTypes.PARTITION,
    entity_name: description
  });
})

export const editPartitionLog = safeFunction(({ accountState, siteState }, partition) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(partition.site_id, siteState),
    op_name: changelog.opcodes.EDIT,
    entity_id: partition.id,
    entity_type: changelog.entityTypes.PARTITION,
    entity_name: partition.description
  });
})

export const createScenarioLog = safeFunction(({ accountState, siteState }, scenario) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(scenario.site_id, siteState),
    op_name: changelog.opcodes.CREATE,
    entity_id: scenario.id,
    entity_type: changelog.entityTypes.SCENARIO,
    entity_name: scenario.description
  });
})

export const deleteScenarioLog = safeFunction(({ accountState, siteState }, scenarioId, [, description, siteId]) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(siteId, siteState),
    op_name: changelog.opcodes.DELETE,
    entity_id: scenarioId,
    entity_type: changelog.entityTypes.SCENARIO,
    entity_name: description
  });
})

export const editScenarioLog = safeFunction(({ accountState, siteState }, scenario) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(scenario.site_id, siteState),
    op_name: changelog.opcodes.EDIT,
    entity_id: scenario.id,
    entity_type: changelog.entityTypes.SCENARIO,
    entity_name: scenario.description
  });
})


export const createMessageLog = safeFunction(({ accountState, siteState, isV2 }, message) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(message.site_id, siteState),
    op_name: changelog.opcodes.CREATE,
    entity_id: message.id,
    entity_type: isV2 ? changelog.entityTypes.MESSAGE_V2 : changelog.entityTypes.MESSAGE,
    entity_name: message.description
  });
})

export const deleteMessageLog = safeFunction(({ accountState, siteState, isV2 }, messageId, [siteId, { name }]) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(siteId, siteState),
    op_name: changelog.opcodes.DELETE,
    entity_id: messageId,
    entity_type: isV2 ? changelog.entityTypes.MESSAGE_V2 : changelog.entityTypes.MESSAGE,
    entity_name: name
  });
})


export const editMessageLog = safeFunction(({ accountState, siteState, isV2 }, message) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getNonSgProps(message.site_id, siteState),
    op_name: changelog.opcodes.EDIT,
    entity_id: message.id,
    entity_type: isV2 ? changelog.entityTypes.MESSAGE_V2 : changelog.entityTypes.MESSAGE,
    entity_name: message.description
  });
})

export const createVendorListLog = safeFunction(({ accountState, siteState, isV2 }, vendorList) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(vendorList, siteState),
    op_name: changelog.opcodes.CREATE,
    entity_id: vendorList.id,
    entity_type: changelog.entityTypes.VENDOR_LIST,
    entity_name: vendorList.name
  });
})

export const deleteVendorListLog = safeFunction(({ accountState, siteState }, vendorList, [, vendorListId]) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(vendorList, siteState),
    op_name: changelog.opcodes.DELETE,
    entity_id: vendorListId,
    entity_type: changelog.entityTypes.VENDOR_LIST,
    entity_name: vendorList.name
  });
})

export const editVendorListLog = safeFunction(({ accountState, siteState }, vendorList) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(vendorList, siteState),
    op_name: changelog.opcodes.EDIT,
    entity_id: vendorList.id,
    entity_type: changelog.entityTypes.VENDOR_LIST,
    entity_name: vendorList.name
  });
})

//<us msps regulations>
export const createUspRegulationLog = safeFunction(({ accountState, siteState, isV2 }, regulation) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(regulation, siteState),
    op_name: changelog.opcodes.CREATE,
    entity_id: regulation.id,
    entity_type: changelog.entityTypes.USP_REGULATION,
    entity_name: regulation.name
  });
})

export const deleteUspRegulationLog = safeFunction(({ accountState, siteState }, regulation, [, regulationId]) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(regulation, siteState),
    op_name: changelog.opcodes.DELETE,
    entity_id: regulationId,
    entity_type: changelog.entityTypes.USP_REGULATION,
    entity_name: regulation.name
  });
})

export const editUspRegulationLog = safeFunction(({ accountState, siteState }, regulation) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(regulation, siteState),
    op_name: changelog.opcodes.EDIT,
    entity_id: regulation.id,
    entity_type: changelog.entityTypes.USP_REGULATION,
    entity_name: regulation.name
  });
})
//</us msps regulation>

//<prefereneces>
export const createPreferenceConfigurationLog = safeFunction(({ accountState, siteState, isV2 }, configuration) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(configuration, siteState),
    op_name: changelog.opcodes.CREATE,
    entity_id: configuration.id,
    entity_type: changelog.entityTypes.PREFERENCE_CONFIGURATIN,
    entity_name: configuration.name
  });
})

export const deletePreferenceConfigurationLog = safeFunction(({ accountState, siteState }, configuration, [, configurationId]) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(configuration, siteState),
    op_name: changelog.opcodes.DELETE,
    entity_id: configurationId,
    entity_type: changelog.entityTypes.PREFERENCE_CONFIGURATIN,
    entity_name: configuration.name
  });
})

export const editPreferenceConfigurationLog = safeFunction(({ accountState, siteState }, configuration) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getVendorListProps(configuration, siteState),
    op_name: changelog.opcodes.EDIT,
    entity_id: configuration.id,
    entity_type: changelog.entityTypes.PREFERENCE_CONFIGURATIN,
    entity_name: configuration.name
  });
})

export const createVendorConnectionLog = safeFunction(({ accountState}, vendorConnection) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...noSitesNoParent(),
    op_name: changelog.opcodes.CREATE,
    entity_id: vendorConnection.id,
    entity_type: changelog.entityTypes.VENDOR_CONNECTION,
    entity_name: vendorConnection.name
  });
})

export const deleteVendorConnectionLog = safeFunction(({ accountState }, vendorConnection, [, vendorConnectionId]) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...noSitesNoParent(),
    op_name: changelog.opcodes.DELETE,
    entity_id: vendorConnectionId,
    entity_type: changelog.entityTypes.VENDOR_CONNECTION,
    entity_name: vendorConnection.name
  });
})

export const editVendorConnectionLog = safeFunction(({ accountState }, vendorConnection) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...noSitesNoParent(),
    op_name: changelog.opcodes.EDIT,
    entity_id: vendorConnection.id,
    entity_type: changelog.entityTypes.VENDOR_CONNECTION,
    entity_name: vendorConnection.name
  });
})
//</preferences>

export const createPrivacyManagerLog = safeFunction(({ accountState }, privacyManager) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...noSitesNoParent(),
    op_name: changelog.opcodes.CREATE,
    entity_id: privacyManager.id,
    entity_type: changelog.entityTypes.PRIVACY_MANAGER,
    entity_name: privacyManager.name
  });
})

export const deletePrivacyManagerLog = safeFunction(({ accountState }, privacyManagerId, [, privacyManager]) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...noSitesNoParent(),
    op_name: changelog.opcodes.DELETE,
    entity_id: privacyManagerId,
    entity_type: changelog.entityTypes.PRIVACY_MANAGER,
    entity_name: privacyManager.name
  });
})

export const editPrivacyManagerLog = safeFunction(({ accountState }, privacyManager) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...noSitesNoParent(),
    op_name: changelog.opcodes.EDIT,
    entity_id: privacyManager.id,
    entity_type: changelog.entityTypes.PRIVACY_MANAGER,
    entity_name: privacyManager.name
  });
})

export const createSiteGroupLog = safeFunction(({ accountState, siteState }, { id, name, siteIds }) => {
  const sites = siteState.getIn(['sites', 'value'])
  const siteNames = siteIds.map(id => sites.find(site => site.id === id).domain).toJS()
  postLogs({
    ...addAccountInfo(accountState),
    op_name: changelog.opcodes.CREATE,
    entity_id: id,
    entity_type: changelog.entityTypes.SITE_GROUP,
    entity_name: name,
    parent_id: changelog.NO_PARENT,
    parent_name: changelog.NO_PARENT,
    site_ids: siteIds.toJS(),
    site_names: siteNames
  });
})

export const updateSiteGroupLog = safeFunction(({ accountState, siteState }, { id, name, siteIds }) => {
  const sites = siteState.getIn(['sites', 'value'])
  const siteNames = siteIds.map(id => sites.find(site => site.id === id).domain).toJS()
  postLogs({
    ...addAccountInfo(accountState),
    op_name: changelog.opcodes.EDIT,
    entity_id: id,
    entity_type: changelog.entityTypes.SITE_GROUP,
    entity_name: name,
    parent_id: changelog.NO_PARENT,
    parent_name: changelog.NO_PARENT,
    site_ids: siteIds.toJS(),
    site_names: siteNames
  });
})

export const deleteSiteGroupLog = safeFunction(({ accountState, siteState }, siteGroupId, args) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSgProps(siteState, siteGroupId),
    op_name: changelog.opcodes.DELETE,
    entity_id: siteGroupId,
    entity_name: args[3],
    entity_type: changelog.entityTypes.SITE_GROUP,
    parent_id: changelog.NO_PARENT,
    parent_name: changelog.NO_PARENT,
  });
})

export const createSiteGroupMessageLog = safeFunction((
  { accountState, siteState, isV2 },
  siteGroupId,
  messages
) => {
  const namesAndIds = getSgProps(siteState, siteGroupId);
  const message = messages.find(({ site_id }) => site_id === namesAndIds.site_id);
  postLogs({
    ...addAccountInfo(accountState),
    ...namesAndIds,
    op_name: changelog.opcodes.CREATE,
    entity_id: message.id,
    entity_type: isV2 ? changelog.entityTypes.MESSAGE_V2 : changelog.entityTypes.MESSAGE,
    entity_name: message.description,
    parent_id: siteGroupId
  });
})

export const deleteSiteGroupMessageLog = safeFunction((
  { accountState, siteState, isV2 },
  siteGroupId,
  messageId,
  messageName
) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSgProps(siteState, siteGroupId),
    op_name: changelog.opcodes.DELETE,
    entity_id: messageId,
    entity_type: isV2 ? changelog.entityTypes.MESSAGE_V2 : changelog.entityTypes.MESSAGE,
    entity_name: messageName,
    parent_id: siteGroupId
  });
})

export const editSiteGroupMessageLog = safeFunction((
  { accountState, siteState, isV2 },
  siteGroupId,
  message
) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSgProps(siteState, siteGroupId),
    op_name: changelog.opcodes.EDIT,
    entity_id: message.id,
    entity_type: isV2 ? changelog.entityTypes.MESSAGE_V2 : changelog.entityTypes.MESSAGE,
    entity_name: message.description,
    parent_id: siteGroupId,
  });
})

export const createSiteGroupScenarioLog = safeFunction(({ accountState, siteState }, siteGroupId, scenarios) => {
  const namesAndIds = getSgProps(siteState, siteGroupId)
  const scenario = scenarios.get('scenarios').find(({ site_id }) => site_id == namesAndIds.site_id)
  postLogs({
    ...addAccountInfo(accountState),
    ...namesAndIds,
    op_name: changelog.opcodes.CREATE,
    entity_id: scenario.id,
    entity_type: changelog.entityTypes.SCENARIO,
    entity_name: scenario.description,
    parent_id: siteGroupId,
  });
})

export const deleteSiteGroupScenarioLog = safeFunction(({ accountState, siteState }, siteGroupId, scenario) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSgProps(siteState, siteGroupId),
    op_name: changelog.opcodes.DELETE,
    entity_id: scenario.id,
    entity_type: changelog.entityTypes.SCENARIO,
    entity_name: scenario.description,
    parent_id: siteGroupId
  });
})

export const editSiteGroupScenarioLog = safeFunction(({ accountState, siteState }, siteGroupId, scenario) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSgProps(siteState, siteGroupId),
    op_name: changelog.opcodes.EDIT,
    entity_id: scenario.id,
    entity_type: changelog.entityTypes.SCENARIO,
    entity_name: scenario.description,
    parent_id: siteGroupId
  });
})

export const createSiteGroupPartitionLog = safeFunction(({ accountState, siteState }, siteGroupId, partitionSets) => {
  const namesAndIds = getSgProps(siteState, siteGroupId)
  const partitionSet = partitionSets.get('partitionsets').find(({ site_id }) => site_id == namesAndIds.site_id)
  postLogs({
    ...addAccountInfo(accountState),
    ...namesAndIds,
    op_name: changelog.opcodes.CREATE,
    entity_id: partitionSet.id,
    entity_type: changelog.entityTypes.PARTITION,
    entity_name: partitionSet.description,
    parent_id: siteGroupId
  })
})

export const deleteSiteGroupPartitionLog = safeFunction((
  { accountState, siteState },
  siteGroupId,
  partitionId,
  partitionName
) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSgProps(siteState, siteGroupId),
    op_name: changelog.opcodes.DELETE,
    entity_id: partitionId,
    entity_type: changelog.entityTypes.PARTITION,
    entity_name: partitionName,
    parent_id: siteGroupId
  });
})

export const editSiteGroupPartitionLog = safeFunction(({ accountState, siteState }, siteGroupId, partitionSet) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSgProps(siteState, siteGroupId),
    op_name: changelog.opcodes.EDIT,
    entity_id: partitionSet.id,
    entity_type: changelog.entityTypes.PARTITION,
    entity_name: partitionSet.description,
    parent_id: siteGroupId
  });
})

export const createSiteGroupCampaignLog = safeFunction(({ accountState, siteState }, { siteGroupId }, campaigns) => {
  const namesAndIds = getSgProps(siteState, siteGroupId)
  const campaign = campaigns.get('campaigns').find(({ site_id }) => site_id == namesAndIds.site_id)
  postLogs({
    ...addAccountInfo(accountState),
    ...namesAndIds,
    op_name: changelog.opcodes.CREATE,
    entity_id: campaign.id,
    entity_type: (campaign.status === 'stage')
      ? changelog.entityTypes.campaign.STAGE
      : changelog.entityTypes.campaign.PUBLIC,
    entity_name: campaign.description,
    parent_id: siteGroupId
  });
})

export const deleteSiteGroupCampaignLog = safeFunction(({ accountState, siteState }, { campaign, siteGroupId }) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...getSgProps(siteState, siteGroupId),
    op_name: changelog.opcodes.DELETE,
    entity_id: campaign.id,
    entity_type: (campaign.status === 'stage')
      ? changelog.entityTypes.campaign.STAGE
      : changelog.entityTypes.campaign.PUBLIC,
    entity_name: campaign.description,
    parent_id: siteGroupId
  });
})

export const editAcctOwnerLog = safeFunction((accountState, newOwner) => {
  postLogs({
    ...addAccountInfo(accountState),
    ...noSitesNoParent(),
    op_name: changelog.opcodes.EDIT,
    entity_id: null,
    entity_type: changelog.entityTypes.ACCOUNT_OWNER,
    entity_name: newOwner,
    parent_id: changelog.NO_PARENT,
  });
})

function addAccountInfo(accountState) {
  const userDetails = accountState.getIn(['userDetails', 'value']);
  const accountId = accountState.getIn(['userDetails', 'value']).get('accountId');
  const masqUserName = accountState.get('masqUserName');
  return {
    account_id: accountId,
    username: masqUserName || userDetails.name,
  };
}

function getNonSgProps(siteId, siteState) {
  const sites = siteState.getIn(['sites', 'value']);
  return {
    site_ids: [siteId],
    site_names: [sites.find(site => site.id == siteId).domain],
    parent_id: changelog.NO_PARENT,
    parent_name: changelog.NO_PARENT,
  };
}

function getSgProps(siteState, siteGroupId) {
  const { siteIds, name: siteGroupName, siteId } = siteState.getIn(['siteGroups', 'value']).find(({ id }) => id === siteGroupId);
  const sites = siteState.getIn(['sites', 'value']);
  const siteNames = siteIds.map(id => sites.find(site => site.id === id).domain).toJS();
  return {
    site_id: siteId,
    parent_name: siteGroupName,
    site_names: siteNames,
    site_ids: siteIds.toJS()
  };
}

function noSitesNoParent() {
  return {
    site_ids: [],
    site_names: [],
    parent_id: changelog.NO_PARENT,
    parent_name: changelog.NO_PARENT
  };
}

function getVendorListProps(vendorList, siteState) {
  const sites = siteState.getIn(['sites', 'value']);
  const siteNames = vendorList.siteIds.map(id => sites.find(site => site.id === id).domain).toJS();
  return {
    parent_id: changelog.NO_PARENT,
    parent_name: changelog.NO_PARENT,
    site_ids: vendorList.siteIds.toJS(),
    site_names: siteNames
  };
}

function getSiteProps(site) {
  return {
    parent_id: changelog.NO_PARENT,
    parent_name: changelog.NO_PARENT,
    site_ids: [site.id],
    site_names: [site.get('domain')],
  }
}
