import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Tabs } from 'antd';

import AccountFeatures from './AccountFeatures'
import ZendeskOrganizations from './ZendeskOrganizations'
import PlAccountManagement from './PlAccountManagement'
import VendorManagement from './VendorManagement'
import DiagnoseScanSetupTable from './DiagnoseScanSetup/ScanTable'

import { getAccountOwners } from '../../actions/account_actions';
import { User } from '../../records/account_records';

const { TabPane } = Tabs;

class AdminSettingsPage extends React.Component {
  static propTypes = {
    accountOwners: ImmutablePropTypes.listOf(PropTypes.instanceOf(User)).isRequired,
    getAccountOwners: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getAccountOwners();
  }

  render() {
    if (!this.props.currentUser) return null;
    
    if (this.props.currentUser.accountId !== 22 && this.props.currentUser.accountId !== 808) {
      browserHistory.replace('/');
    }

    const { query: { tab = '' } } = browserHistory.getCurrentLocation();
    const addKeyToRoute = (key) => {
      browserHistory.replace(`/admin/admin_settings?tab=${key}`);
    };
    return(
      <div className='admin-settings-page-new'>
        <div className="title-admin-settings">SUPER ADMIN<span> - ADMIN SETTINGS</span></div>

        <Tabs onChange={addKeyToRoute} defaultActiveKey={ tab || 'account'}>
        <TabPane tab="Account Features" key="account">
            <AccountFeatures {...this.props} />
          </TabPane>
          <TabPane tab="GDPR Vendor Management" key="gdpr">
            <VendorManagement category="gdpr" {...this.props} />
          </TabPane>
          <TabPane tab="GDPR (TCF v2) Vendor Management" key="gdprV2">
            <VendorManagement category="gdprV2" {...this.props} />
          </TabPane>
          <TabPane tab="U.S. Privacy Vendor Management" key="us_privacy">
            <VendorManagement category="ccpa" {...this.props} />
          </TabPane>
          <TabPane tab="PL Account Management" key="pl_account">
            <PlAccountManagement {...this.props} />
          </TabPane>
          <TabPane tab="Zendesk Organization" key="zendesk">
            <ZendeskOrganizations {...this.props} />
          </TabPane>
          <TabPane tab="Diagnose Scan Setup" key="diagnose_scan">
            <DiagnoseScanSetupTable {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountOwners: store.accountState.getIn(['accountOwners', 'value']),
    accountOwnersPending: store.accountState.getIn(['accountOwners', 'pending']),
  };
};

export default connect(
  mapStateToProps, {
    getAccountOwners,
  },
)(AdminSettingsPage);
