import React, { useState } from 'react';
import { Input, Radio, Switch, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { useAutoScroll } from '../../../../../../../../hooks';
import { COUNTRY_LANGUAGES } from '../../../../../../../../constants';

export default function SetCheckboxOptions({
  settingKey: inputsKey,
  updateSetting,
  value: inputs,
  defaultLanguage,
  allSettings,
  allSettings: {
    isAgreeToTermsCheckbox: initialAgreeToTermsValue,
  },
}) {
  const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;

  const [isAgreeToTermsCheckbox, setIsAgreeToTermsCheckbox] = useState(initialAgreeToTermsValue);
  const optionInputTableEl = useAutoScroll(inputs.length);

  const changeCheckboxType = () => {
    updateSetting(
      inputsKey,
      isAgreeToTermsCheckbox ? defaultCheckboxSets() : defaultAgreeToTermsCheckbox()
    );
    updateSetting('isAgreeToTermsCheckbox', !isAgreeToTermsCheckbox);
    setIsAgreeToTermsCheckbox(!isAgreeToTermsCheckbox);
  };

  const updateOption = (index, field) => ({ target: { value } }) => {
    const updatedInput = { ...inputs[index] };
    if (field === 'label') {
      updatedInput.label = (defaultLanguage === selectedLanguage) ? value : updatedInput.label;
      updatedInput.languages = updatedInput.languages
        ? {
          ...updatedInput.languages,
          [selectedLanguage]: { label: value }
        }
        : { [selectedLanguage]: { label: value } };
    } else {
      updatedInput[field] = value;
    };

    updateSetting(inputsKey, [
      ...inputs.slice(0, index),
      updatedInput,
      ...inputs.slice(index + 1)
    ]);
  };

  const addOption = () => {
    updateSetting(inputsKey, [
      ...inputs,
      {
        fieldName: '',
        label: `Label ${inputs.length + 1}`,
        value: false,
        required: false,
        hasTextField: false,
      }
    ])
  };

  const removeOption = (index) => {
    if (inputs.length !== 1) {
      updateSetting(inputsKey, [
        ...inputs.slice(0, index),
        ...inputs.slice(index + 1)
      ]);
    };
  };

  const makeLabel = (label, languages = {}) => {
    return (defaultLanguage === selectedLanguage)
      ? label
      : (languages[selectedLanguage] !== undefined)
        ? languages[selectedLanguage].label : '';
  }

  return (
    <div>
      <div>
        <div className="set-option-type">
          <h4>Type of Checkbox Group</h4>
          <Radio.Group value={isAgreeToTermsCheckbox} onChange={changeCheckboxType}>
            <Radio.Button value={false}>Multi</Radio.Button>
            <Radio.Button value={true}>Agree to Terms</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className="four-options">
        <div className="text-language">
          <h4>Language </h4>
          <Select
            value={selectedLanguage}
            onChange={(language => updateSetting('selectedLanguage', language))}
          >
            {COUNTRY_LANGUAGES.map(({ code, language }) => <Select.Option key={code}>{language}</Select.Option>)}
          </Select>
        </div>
      </div>
      <div
        className="checkbox-input-options-table"
        style={isAgreeToTermsCheckbox ? { height: 105 } : { minHeight: 100, maxHeight: 320 }}
        ref={optionInputTableEl}
      >
        {inputs.map(({
          label,
          fieldName,
          hasTextField,
          required,
          languages = {},
        }, i) => (
            <div key={i} className="row-container">
              <div
                className={`rm-btn ${inputs.length === 1 ? 'disabled' : ''}`}
                onClick={() => removeOption(i)}
              >
                {'\u2715' /* unicode for X symbol */}
              </div>
              <div className="inputs-container" >
                <div className="label-value-input">
                  <Input
                    placeholder="Field Name"
                    value={fieldName}
                    onChange={updateOption(i, 'fieldName')}
                  />
                </div>
                <div className="label-value-input">
                  <Input
                    placeHolder="Label"
                    value={makeLabel(label, languages)}
                    onChange={updateOption(i, 'label')}
                  />
                </div>
              </div>
              <div className="switch-container">
                <div className="switch-item">
                  <div>Required</div>
                  <Switch
                    size="small"
                    disabled={isAgreeToTermsCheckbox}
                    checked={required}
                    onChange={value => updateOption(i, 'required')({ target: { value } })}
                  />
                </div>
                <div className="switch-item">
                  <div>Text Field</div>
                  <Switch
                    size="small"
                    disabled={isAgreeToTermsCheckbox}
                    checked={hasTextField}
                    onChange={value => updateOption(i, 'hasTextField')({ target: { value } })}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      {!isAgreeToTermsCheckbox && (
        <div className="add-input" onClick={addOption}>
          <div>
            <FontAwesomeIcon icon={faPlusCircle} />
          </div>
          <div>
            Add Another Checkbox
          </div>
        </div>
      )}
    </div>
  );
};

const defaultAgreeToTermsCheckbox = () => [{
  fieldName: '',
  label: 'I agree to these terms',
  value: false,
  required: true,
  hasTextField: false,
}];

const defaultCheckboxSets = () => (
  [...Object.keys(Array(3).fill())].map(n => ({
    fieldName: '',
    label: `Label ${Number(n) + 1}`,
    value: false,
    required: false,
    hasTextField: false,
  }))
);