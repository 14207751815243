import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import classNames from 'classnames';

import AntdFormItem from '../../../common/AntdFormItem.jsx';
import { ChoiceConfigRecord } from '../../../../records/choice_config_records.js';
import { CONSTANTS } from '../../../../constants.js';

export default class extends React.Component {
  static propTypes = {
    choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
    editing: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    listIndex: PropTypes.number.isRequired,
    onChoiceConfigChange: PropTypes.func.isRequired,
  };

  onChange = (newVal) => {
      let optionData = this.props.choiceConfig.getIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA]);
      optionData = optionData.merge(newVal);
      const oldChoiceConfig = this.props.choiceConfig;
      const choiceConfig = oldChoiceConfig.setIn(['option_list', this.props.listIndex, 'option_data'], optionData);
      this.props.onChoiceConfigChange(choiceConfig);
  };

  render() {
      const optionData = this.props.choiceConfig.getIn([CONSTANTS.OPTION_LIST, this.props.listIndex, CONSTANTS.OPTION_DATA]);
      const classes = classNames('label-title');

      return (
          <div className="choice-option">
            <div className={ classes }>
              <AntdFormItem
                fieldLabel="Button Text"
                fieldName={ 'redirect_button_text_' + this.props.listIndex }
                form={ this.props.form }
                initialValue={ optionData.get('button_text') }
                onChange={ (event) => this.onChange(Map({ button_text: event.target.value })) }
                placeholder="Redirect"
                editing={ this.props.editing }
              />
              <AntdFormItem
                fieldLabel="Redirect URL"
                fieldName={ 'redirect_url_' + this.props.listIndex }
                form={ this.props.form }
                initialValue={ optionData.get('redirect_url') }
                onChange={ (event) => this.onChange(Map({ redirect_url: event.target.value })) }
                placeholder=""
                editing={ this.props.editing }
              />
            </div>
          </div>
      );
  }
}
