import PropTypes from 'prop-types';
import React from 'react';
import { CloseCircleFilled,SearchOutlined,CheckOutlined } from '@ant-design/icons';
import { List as ListView, } from 'antd';
import { Input, Button,Select, Modal, Checkbox, Tooltip } from "../../styleguide"
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, OrderedSet } from 'immutable';
import { VendorList, Vendor, Purpose, VendorWrapper, CategorizationSchema } from '../../records/vendor_list_records';
import { categorize } from '../consent/vendor_list_v2/util';
import { CurrentUserContext } from '../contexts';

const Search = Input.Search;
const Option = Select.Option;

export default class AddVendorsModal extends React.Component {
  static propTypes = {
    vl: PropTypes.instanceOf(VendorList).isRequired,
    vendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    visible: PropTypes.bool.isRequired,
    updateVendorList: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    iabPurposes: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    ccpa: PropTypes.bool.isRequired,
    v2: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    consentCompensationList: null,
  }

  state = {
    vendorsToAdd: OrderedSet([]),
    vl: this.props.vl,
    search: '',
    selectIabVendors: true,
    selectedVendorIds: List(),
  }

  updateSearch = (e) => this.setState({ search: e.target.value });

  getVendorByName = (name) => {
    const vendor = this.props.vendors.find(v => v.get('name') === name);
    return vendor;
  }

  handleSelect = (vendorId) => {
    let ids = this.state.selectedVendorIds;
    if (!ids.includes(vendorId)) {
      ids = ids.push(vendorId);
    } else {
      let index = ids.findIndex((id) => id === vendorId);
      ids = ids.remove(index);
    }

    const vendors = ids.map((id) =>
      this.props.vendors.find((v) => v.id === id)
    );
    this.setState({
      vendorsToAdd: vendors,
      selectedVendorIds: ids,
    });
  }

  getVendorIdsForPurpose = (purpose, vendors) => {
    const nonIABVendorIds = purpose.vendorIds.map(id => this.props.vendors.find(v => v.id === id)).map(v => v.id);

    return vendors.filter(v => {
      const purposeIdsOfVendor = v.purposes.map(p => typeof p === 'string' ? p : p.id);
      if (this.props.v2) {
        return purposeIdsOfVendor.some(id => id === purpose.iabPurposeRef);
      } else {
        return purposeIdsOfVendor.some(id => purpose.iabPurposeRefs.includes(id));
      }
    }).map(v => v.id)
      .concat(nonIABVendorIds);
  }

  handleAddVendors = (scannedVendors = OrderedSet([]), scannedSitesIds = List([])) => {
    let categories;
    if (this.state.vl.vendors.size) {
      categories = this.state.vl.categories;
    } else {
      if (this.props.ccpa) {
        categories = new List([new Purpose({ name: 'Category 1', type: 'CUSTOM' }), new Purpose({ name: 'Category 2', type: 'CUSTOM' }), new Purpose({ name: 'Category 3', type: 'CUSTOM' })]);
      } else {
        categories = this.props.iabPurposes.map(iabP => {
          return iabP.set('id', null);
        });
      };
    };

    const listVendorsWithoutRescanned = this.state.vl.vendors.filterNot(v => scannedVendors.map(sv => sv.id).includes(v.id));
    const allVendors = listVendorsWithoutRescanned.concat(this.state.vendorsToAdd).concat(scannedVendors);

    if (this.props.ccpa) {
      categories = categories.map(c => c.set('vendorIds', this.getVendorIdsForPurpose(c, allVendors)));
    } else {
      const { getVendorIdsForPurpose, state } = this;
      categories = categories.map(function updateCats(c) {
        const updatedCategory = c
          .set('vendorIds', getVendorIdsForPurpose(c, allVendors))
          .update('vendorCategorization', vc => {
            const newCategorizations = state.vendorsToAdd.map(vendor => categorize(
              c,
              {
                id: vendor.id,
                vendorType: vendor.vendorType,
                purposes: vendor.purposes.map(({ id }) => id),
                legIntPurposes: vendor.legIntPurposes.map(({ id }) => id),
              },
              state.vl.defaultLegalBasis
            ))
              .filterNot(({ type }) => type === 'NOT_APPLICABLE');
            return vc.concat(newCategorizations)
          })
          .update('categories', cats => cats.map(updateCats));

        return updatedCategory
      });
    }

    const newVendorWrappersForScanned = scannedVendors.filter(v => v.isNew).map(v => new VendorWrapper({ vendorId: v.id, cookies: v.cookies.toJS() }));
    const vendorsWithoutScanned = this.state.vendorsToAdd.filterNot(v => scannedVendors.map(sv => sv.id).includes(v.id));
    const allNewVendorWrappers = vendorsWithoutScanned.map(v => new VendorWrapper({ vendorId: v.id })).concat(newVendorWrappersForScanned);

    const updatedOldVendorWrappers = this.state.vl.vendorsWrappers.map(vw => {
      if (scannedVendors.map(v => v.id).includes(vw.vendorId)) {
        return vw.set('cookies', scannedVendors.find(v => v.id === vw.vendorId).cookies);
      } else {
        return vw;
      }
    });

    const updatedVendorList = this.props.vl
      .set('vendors', allVendors.sortBy(v => v.name.toLowerCase()).sortBy(v => v.vendorType))
      .set('categories', categories)
      .set('siteIds', scannedSitesIds.concat(this.state.vl.siteIds).toSet())
      .set('vendorsWrappers', updatedOldVendorWrappers.concat(allNewVendorWrappers));

    this.setState({
      vendorsToAdd: OrderedSet([]),
    });
    this.props.updateVendorList(updatedVendorList);
    this.props.closeModal();
  }

  handleCloseAddVendorsModal = () => {
    this.setState({
      vendorsToAdd: OrderedSet([]),
    });
    this.props.closeModal();
  }

  getFilteredVendors = (vendors) => {
    return vendors.filter(vendor => {

      const name = vendor.get('name');
      if (this.state.search) {
        if (!name.toLowerCase().includes(this.state.search.toLowerCase())) {
          return false;
        }
      }
      return name !== '' && name !== null && name !== undefined
    });
  }

  addAllIab = () => {
    const allIabVendorsWithoutSelected = this.props.vendors
      .filterNot((gv) => this.props.vl.vendors.map((v) => v.id).includes(gv.id))
      .filter((v) => v.vendorType === 'IAB');

    let vendorsToAdd = this.state.vendorsToAdd;

    vendorsToAdd = vendorsToAdd.concat(allIabVendorsWithoutSelected).toOrderedSet().toList();;
    const ids = vendorsToAdd.map((v) => v.id);
    this.setState({
      vendorsToAdd,
      selectedVendorIds: ids.toList(),
    });
  };

  render() {
    const vendorsToAdd = this.state.vendorsToAdd.map(v => v.id)
    const vendorsWithoutSelected = this.props.vendors.filterNot(gv => this.state.vl.vendors.map(v => v.id).includes(gv.id));

    let selectContent;
    let searchBar;
    let iabToggleButton;
    let selectIabButton;
    let googleAtpCheck;

    if (vendorsWithoutSelected.size) {
      const allVendors = this.getFilteredVendors(vendorsWithoutSelected);
      let iabVendors = List([]);
      let customVendors = List([]);
      allVendors.forEach(vendor => {
        if (vendor.vendorType === 'IAB') {
          iabVendors = iabVendors.push(vendor);
        } else if (vendor.vendorType === 'CUSTOM') {
          customVendors = customVendors.push(vendor);
        }
      });

      if (this.state.selectIabVendors) {
        const allIabSelected = iabVendors.map(v => v.id).every(id => this.state.vendorsToAdd.map(v => v.id).includes(id));
        selectIabButton = (
          <Button
            type="secondary"
            size="small"
            onClick={this.addAllIab}
            disabled={allIabSelected}
          >
            Add All IAB Vendors
          </Button>
        )

        selectContent = (
          <div>
            <ListView className="vendor-v2-listview">
                      {iabVendors.map((vendor, vi) => (
                        <ListView.Item
                          key={vi}
                          onClick={() => this.handleSelect(vendor.get('id'))}
                        >
                          <div className="vendor-tab-container">
                            <div className="vendor-item-container">
                              {vendor.get('name')}
                            </div>
                            <div>
                              <CheckOutlined
                                style={{
                                  color:  this.state.vendorsToAdd.some(
                                    (v) => v.get('id') === vendor.get('id')
                                  )
                                    ? '#411F90'
                                    : 'lightgray',
                                  fontWeight:  this.state.vendorsToAdd.some(
                                    (v) => v.get('id') === vendor.get('id')
                                  )
                                    ? 'bolder'
                                    : 'light',
                                }}
                              />
                            </div>
                          </div>
                        </ListView.Item>
                      ))}
                    </ListView>
          </div>
        );
      } else {
        const atpEnabled = (this.props.currentUser.accountId == 22 || this.props.currentUser.accountFeatures.includes('tcf_v2'));
        googleAtpCheck =  atpEnabled && (
          <Checkbox
            disabled={this.state.selectIabVendors}
            checked={this.state.selectIabVendors ? false : this.state.atpOnly}
            onChange={({ target: { checked } }) => this.setState({ atpOnly: checked })}
          >
            Google ATP Only
          </Checkbox>
        )
        selectContent = (
          <div>
            <ListView className="vendor-v2-listview">
                      {customVendors.filter(vendor => this.state.atpOnly ? vendor.get('isGoogleAtp') : true).map((vendor, vi) => (
                        <ListView.Item
                          key={vi}
                          onClick={() => this.handleSelect(vendor.get('id'))}
                        >
                          <div className="vendor-tab-container">
                            <div className="vendor-item-container">
                              {vendor.get('name')}
                              {atpEnabled && vendor.get('isGoogleAtp') && (
                              <Tooltip title="Google ATP">
                              <img style={{ width: 10, height: 10, marginLeft: 5 }} src="/images/google-icon.svg" />
                              </Tooltip>
                              )}
                            </div>
                            <div>
                              <CheckOutlined
                                style={{
                                  color:  this.state.vendorsToAdd.some(
                                    (v) => v.get('id') === vendor.get('id')
                                  )
                                    ? '#411F90'
                                    : 'lightgray',
                                  fontWeight:  this.state.vendorsToAdd.some(
                                    (v) => v.get('id') === vendor.get('id')
                                  )
                                    ? 'bolder'
                                    : 'light',
                                }}
                              />
                            </div>
                          </div>
                        </ListView.Item>
                      ))}
            </ListView>
          </div>
        );
      }

      searchBar = (
        <Input
          placeholder={this.state.selectIabVendors ? 'Search available IAB vendors ...' : 'Search available custom vendors ...'}
          onChange={this.updateSearch}
          suffix={<SearchOutlined />}
          className="vendor-search"
        />
      );

      iabToggleButton = (
        <Button
          type="primary"
          size="small"
          onClick={() => this.setState({ selectIabVendors: !this.state.selectIabVendors })}
          disabled={this.state.loading}
        >
          {this.state.selectIabVendors ? 'Switch to Select Custom Vendors' : 'Switch to Select IAB Vendors'}
        </Button>
      )

    } else {
      selectContent = <div className='no-vendors-message'>No Vendors to Add, all are already in your vendor list</div>
    }

    return (
      <Modal
        title={`Add Vendors`}
        visible={this.props.visible}
        onOk={() => this.handleAddVendors()}
        onCancel={this.handleCloseAddVendorsModal}
        okText='+ Add Vendors'
        wrapClassName='add-vendors edit-vendor-compensation'
        closable={true}
        width={850}
      >
      <div className="main-container">
        <div className='flex-row' ref={this.myRef} >
          <div className="flex-row-sub">
          <div className="switch-vendors">
            {
              this.state.selectIabVendors ?
                <p className='select-title'>Select IAB Vendors</p> :
                <p className='select-title'>Select Custom Vendors</p>
            }
            {iabToggleButton}
          </div>
          <div className="btn-container">
          {searchBar}
          {selectIabButton}
          {googleAtpCheck}
          </div>
         
          {selectContent}
          </div>
        </div>
        <div className='selected-vendors-container'>
            <p className='title-3'>Selected Vendors</p>
            <div className='selected-vendors'>
              {
                this.state.vendorsToAdd.size ?
                  this.state.vendorsToAdd.map(v => {
                    return (
                      <div className='vendor-container flex-row'>
                        <div className="vendor-name">{v.name}</div>
                        <CloseCircleFilled
                          onClick={() => this.setState({ vendorsToAdd: this.state.vendorsToAdd.filterNot(vendor => vendor.name === v.name) })} />
                      </div>
                    );
                  }) :
                  <p>Currently there are no vendors selected</p>
              }
            </div>
          </div>
        </div>
      
      </Modal>
    );
  }
}