import { Record, List } from "immutable";
import moment from 'moment';

export const PropertiesReportV2Record = Record({
  key: null,
  date: "",
  fancyDate: "",
  siteid: null,
  propertyname: null,
  totalpageviews: 0,
  totalusers: 0,
  fullconsent: 0,
  fullconsentper: "",
  rejectedall: 0,
  rejectedallper: "",
  legitimateintobj: 0,
  legitimateintobjper: "",
  prevfullconsent: 0,
  prevfullconsentper: "",
  other: 0,
  otherper: "",
  noaction: 0,
  noactionper: "",
  aggData: List(),
  exportdata: List(),
});

export const VendorsConsentReportRecord = Record({
  key: null,
  name: "",
  totalViews: 0,
  fullOptIn: 0,
  legIntOnly: 0,
  rejectAll: 0,
  fullOptInPer: 0,
  legIntOnlyPer: 0,
  rejectAllPer: 0,
});

export const MessageTableReportV2Record = Record({
  key: null,
  date: "",
  criteriaId: "",
  messageid: "",
  messagename: "",
  partitionname: "",
  totalusers: 0,
  totalmsgs: 0,
  totalmsgsper: 0,
  consentall: 0,
  consentallper: "",
  rejectall: 0,
  rejectallper: "",
  legitimateintobj: 0,
  legitimateintobjper: "",
  bounces: 0,
  bouncesper: "",
  other: 0,
  otherper: "",
  siteid: null,
  aggData: List(),
});

export const GDPRConsentFilters = Record({
  startDate: moment()
    .subtract(1, "weeks")
    .startOf("isoWeek"),
  endDate: moment()
    .subtract(1, "weeks")
    .endOf("isoWeek"),
  siteId: List([]),
  geo: List([]),
  device: List([]),
  browser: List([]),
  os: List([]),
});

export const VendorFilters = Record({
  startDate: moment(),
  endDate: moment(),
  siteId: "",
  geo: List([]),
});
