import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map, List as ImList } from 'immutable';
import CreateButton from '../../common/CreateButton.js.jsx';
import DetailStandardModal from './DetailStandardModal';
import StandardsGrouped from './StandardsGrouped';
import { Standard } from '../../../records/standard_records';
import { User } from '../../../records/account_records';
import { SearchOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import Loading from '../../common/Loading.js.jsx';
import { getDomainSets } from '../../../actions/domain_set_actions';
import { Input } from '../../../styleguide';
import { 
  getRules, 
  getTopTenRules, 
  getStandards, 
  createStandard, 
  updateStandard,
  deleteStandard,
} from '../../../actions/standard_actions';
import { 
  getStandardScores,
  getAllDomainScores,
  getSetScoresIncluded,
} from '../../../actions/domain_score_actions';

const TabPane = Tabs.TabPane;


class StandardsPage extends React.Component { 
  static propTypes = {
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
    getRules: PropTypes.func.isRequired, 
    getTopTenRules: PropTypes.func.isRequired, 
    getStandards: PropTypes.func.isRequired,
    createStandard: PropTypes.func.isRequired,
    updateStandard: PropTypes.func.isRequired,
    deleteStandard: PropTypes.func.isRequired,
    getStandardScores: PropTypes.func.isRequired,
    getAllDomainScores: PropTypes.func.isRequired,
  }

  state = {
    openedStandard: null,
    newStandardModal: false,
    ruleExplainersOpen: false,
    search: '',
    standardsType: "1",
    readOnly: true,
  }

  setReadOnly = () => {
    const readOnly = (
      !this.props.currentUser.masqed && (this.props.currentUser.accountFeatures.includes('privacy_lens_read_only')
      || (!this.props.currentUser.allFeatureAccess && (this.props.currentUser.featureAccess && !this.props.currentUser.featureAccess.includes('Privacy Lens - Advanced'))))
    )
    if (!readOnly) {
      this.setState({ readOnly: false });
    }
  }

  onCurrentUserAvailableDo = () => {
    this.setReadOnly();
    const accountId = this.props.currentUser.accountId;
    this.props.getRules();
    this.props.getStandards(accountId, null, true);
    this.props.getDomainSets(accountId);
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  openStandardModal = (standard) => {
    this.setState({ openedStandard: standard });
  }

  updateSearch = (e) => {
    const value = e.target.value;
    this.setState({ search: value });
  };

  changeTab = (activeKey) => {
    this.setState({ standardsType: activeKey });
  }

  getStandardNameError = (oldStandardName, newStandardName) => {
    let nameError = null;
    const duplicateName = this.props.standards.map(s => s.name).find(s => s === newStandardName);
    if(newStandardName.trim().length === 0){
      nameError = <>Name field can not be empty</>
    }else if(duplicateName && duplicateName !== oldStandardName) {
      nameError = <>Standard with name '<strong>{newStandardName}</strong>' already exists</>
    }
    return nameError; 
  }

  render() {
    if (this.props.pendingRequestsMap.get('rulesRending') || this.props.pendingRequestsMap.get('standardsPending')) {
      return <Loading/>;
    }

    let detailStandardModal;
    if (this.state.openedStandard) {

      detailStandardModal = (
        <DetailStandardModal
          standard={this.state.openedStandard}
          closeModal={() => this.setState({ openedStandard: null, newStandardModal: false })}
          rules={this.props.rules}
          createStandard={this.props.createStandard}
          updateStandard={this.props.updateStandard}
          accountId={this.props.currentUser.accountId}
          visible={!!this.state.openedStandard}
          new={this.state.newStandardModal}
          getStandardScores={this.props.getStandardScores}
          standardScores={this.props.standardScores}
          standardScoresPending={this.props.pendingRequestsMap.get('standardScoresPending')}
          changeStandardsTypeTab={this.changeTab}
          readOnly={this.state.readOnly || this.state.openedStandard.isSystemStandard}
          getSetScoresIncluded={this.props.getSetScoresIncluded}
          getStandardNameError={this.getStandardNameError}
        />
      );
    }

    const searchAndFilter = (
    <div className='search-container'>
      <Input
        placeholder="Search Standard"
        onChange={this.updateSearch}
        value={this.state.search}
        suffix={<SearchOutlined/>}
      />
    </div>);

    let allStandards = ImList(this.props.standards.map(obj => {
      return obj.update('dateUpdated', (val)=> new Date(val))
    })
    .sort((objA, objB) => Number(objB.dateUpdated) - Number(objA.dateUpdated))
    .map(obj => {
      return obj.update('dateUpdated', (val)=> val.toISOString())
    }))
    if (this.state.search) {
      allStandards = allStandards.filter(s => s.name.toLowerCase().includes(this.state.search.toLowerCase()));
    }
    const publishedStandards = allStandards.filter(s => s.isActive && !s.isSystemStandard);
    const draftStandards = allStandards.filter(s => !s.isActive && !s.isSystemStandard);
    const spStandards = allStandards.filter(s => s.isSystemStandard);

    let publishedStandardsContainer;
    let draftStandardsContainer;

    if (allStandards) {
      publishedStandardsContainer = (
        <div className='standards'>
          <StandardsGrouped
            standards={publishedStandards}
            updateStandard={this.props.updateStandard}
            accountId={this.props.currentUser.accountId}
            standardScores={this.props.standardScores}
            openStandardModal={this.openStandardModal}
            createStandard={this.props.createStandard}
            deleteStandard={ this.props.deleteStandard }
            type="account"
            domainSets={this.props.domainSets}
            switchStandardsTab={() => this.changeTab('2')}
            readOnly={this.state.readOnly}
            getStandardNameError={this.getStandardNameError}
            standardsType={this.state.standardsType}
          />
          <StandardsGrouped
            standards={spStandards}
            updateStandard={this.props.updateStandard}
            accountId={this.props.currentUser.accountId}
            standardScores={this.props.standardScores}
            openStandardModal={this.openStandardModal}
            createStandard={this.props.createStandard}
            deleteStandard={this.props.deleteStandard}
            domainSets={this.props.domainSets}
            readOnly={this.state.readOnly}
            getStandardNameError={this.getStandardNameError}
            standardsType={this.state.standardsType}
          />
        </div>
      );

      draftStandardsContainer = (
        <div className='standards'>
          <StandardsGrouped
            standards={draftStandards}
            updateStandard={this.props.updateStandard}
            accountId={this.props.currentUser.accountId}
            standardScores={this.props.standardScores}
            openStandardModal={this.openStandardModal}
            createStandard={this.props.createStandard}
            deleteStandard={ this.props.deleteStandard }
            type="account"
            domainSets={this.props.domainSets}
            switchStandardsTab={() => this.changeTab('1')}
            readOnly={this.state.readOnly}
            getStandardNameError={this.getStandardNameError}
            standardsType={this.state.standardsType}
          />
        </div>
      );
    }

    return (
      <div className='standards-container privacy-lens'>
        <div className='title-privacy-lens'>STANDARDS</div>
        <CreateButton disabled={this.state.readOnly} onClick={() => this.setState({ openedStandard: new Standard({}), newStandardModal: true })}>NEW STANDARD
        </CreateButton>
        {searchAndFilter}
        {detailStandardModal}
        <Tabs activeKey={this.state.standardsType} onChange={this.changeTab}>
          <TabPane tab={`Published (${publishedStandards.size + spStandards.size})`} key="1">
            {publishedStandardsContainer} 
          </TabPane>
          <TabPane tab={`Draft (${draftStandards.size})`} key="2">
            {draftStandardsContainer}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    rules: store.standardState.getIn(['rules', 'value']),
    standards: store.standardState.getIn(['standards', 'value']),
    standardScores: store.domainScoreState.getIn(['standardScores', 'value']),
    pendingRequestsMap: new Map({
      rulesRending: store.standardState.getIn(['rules', 'pending']),
      standardsPending: store.standardState.getIn(['standards', 'pending']),
      standardScoresPending: store.domainScoreState.getIn(['standardScores', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getRules,
    getTopTenRules,
    getStandards,
    createStandard,
    updateStandard,
    deleteStandard,
    getStandardScores,
    getAllDomainScores,
    getDomainSets,
    getSetScoresIncluded,
  },
)(StandardsPage);
