import React from 'react';
import { List, Map } from 'immutable';
import { InputNumber, Divider } from 'antd';
import { Select, Button, Radio, Input, Chip } from '../../../../styleguide';
import { styles } from './styles';
import { IntRangeKVGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { SCENARIO_STEPS } from '../../scenarios/versionTwo/components/steps';
const { Option } = Select;

export default class NumRangeTargeting extends React.Component {
  onSelectFilter = (inputString, option) => {
    return (
      inputString.toLowerCase() ===
      option.props.children.toLowerCase().slice(0, inputString.length)
    );
  };

  state = {
    page: 'Include',
    value: '',
    key: '',
    minValue: 0,
    maxValue: 0,
    showError: false,
  };

  componentWillMount() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .find((r, ind) => r.get('className') == className && ind == this.props.conditionIndex);
    let data;
    if (getSavedData && this.props.isUpdate) {
      data = getSavedData;
    }

    this.setState({
      page:
        getSavedData && data
          ? data.get('data').get('allow_on_satisfy')
            ? 'Include'
            : 'Exclude'
          : 'Include',
      key: getSavedData && data ? data.get('data').get('k') : '',
      minValue: getSavedData && data ? data.get('data').get('v_start') : 0,
      maxValue: getSavedData && data ? data.get('data').get('v_end') : 0,
    });
  }

  onAddEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set(
      'data',
      new IntRangeKVGate({
        k: this.state.key,
        v_start: this.state.minValue,
        v_end: this.state.maxValue,
        allow_on_satisfy: this.state.page === 'Include' ? true : false,
      })
    );
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.unshift(stepBlockData);
    this.state.minValue < this.state.maxValue
      ? this.props.onChange(this.props.activeStep, rowData)
      : this.setState({ maxValue: 0, showError: true });
  };

  onUpdateEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set(
      'data',
      new IntRangeKVGate({
        k: this.state.key,
        v_start: this.state.minValue,
        v_end: this.state.maxValue,
        allow_on_satisfy: this.state.page === 'Include' ? true : false,
      })
    );
    const index = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.findIndex((r, ind) => r.get('className') == className && ind == this.props.conditionIndex);
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.set(index, stepBlockData);
    this.state.minValue < this.state.maxValue
      ? this.props.onChange(this.props.activeStep, rowData)
      : this.setState({ maxValue: 0, showError: true });
  };

  onChangeText = (e) => {
    const { value } = e.target;
    let stringTagsarr = value.split(',');
    this.setState({ tags: stringTagsarr });
  };

  onRemoveTag = (value) => {
    let tags = this.state.tags.filter((item) => item !== value);
    this.setState({ tags: tags });
  };

  onChangePageValue = (e) => {
    const { value } = e.target;
    this.setState({ page: value });
  };

  onSelectValueOption = (option) => {
    this.setState({ value: option });
    if (option === 'between') {
      this.setState({ showRange: true });
    } else {
      this.setState({ showRange: false });
    }
  };

  onChangeKeyName = (e) => {
    const { value } = e.target;
    this.setState({ key: value });
  };

  cancelCondition = () => {
    this.props.closePanel();
  };

  onChangeNumberValue = (value, title) => {
    switch (title) {
      case 'min':
        this.setState({ minValue: value });
        break;
      case 'max':
        this.setState({ maxValue: value });
        break;
    }
  };

  render() {
    const { minValue, maxValue, key } = this.state;
    const readOnly = !(minValue >= 0 && maxValue >= 0 && key);

    return (
      <div style={styles.scenarioConditionPanelCol}>
        <div style={styles.scenarioInnerWrapperTwo}>
          <span className="condition-label-two">Condition:&nbsp;</span>
          <span className="condition-title"> {this.props.conditionLabel}</span>
        </div>
        <div>
          <div style={styles.scenarioInnerWrapperTwo}>
            <div style={styles.scenarioItemNumRng}>
              <span className="condition-label-two">Pages </span>
              <Radio.Group
                value={this.state.page}
                onChange={this.onChangePageValue}
                style={{ marginTop: '10px', width: '200px' }}
                defaultValue={'Include'}
                buttonStyle="solid"
              >
                <Radio.Button value="Include">Include</Radio.Button>
                <Radio.Button value="Exclude">Exclude</Radio.Button>
              </Radio.Group>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px',
                flex: 0.9,
              }}
            >
              <div style={{ flexDirection: 'row', display: 'flex', flex: 1 }}>
                <div style={styles.scenarioItemWrapperFirst}>
                  <span className="condition-label-two">Key</span>
                  <Input
                    value={this.state.key}
                    type="number"
                    onChange={(e) => this.onChangeKeyName(e)}
                    style={{ marginTop: '10px' }}
                  />
                </div>
                <div style={styles.scenarioItemWrapper}>
                  <span className="condition-label-two">Value Range </span>
                  <InputNumber
                    value={this.state.minValue}
                    onChange={(e) => this.onChangeNumberValue(e, 'min')}
                    style={styles.itemStyleGeneralNumRng}
                    placeholder="min"
                    step={1}
                    min={0}
                    max={1000}
                  />
                </div>
                <div style={styles.scenarioItemWrapper}>
                  <InputNumber
                    value={this.state.maxValue}
                    onChange={(e) => this.onChangeNumberValue(e, 'max')}
                    placeholder="max"
                    style={styles.itemStyleGeneralNumRng}
                    step={1}
                    min={0}
                    max={1000}
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ flex: 0.3 }}>
                  {this.state.showError ? (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '11px',
                        marginTop: '10px',
                      }}
                    >
                      Enter value greater than min
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div style={styles.scenarioButtonWrapperTwo}>
          <Button
            onClick={
              this.props.buttontitle == 'Add'
                ? this.onAddEvent
                : this.onUpdateEvent
            }
            type="primary"
            size='small'
            disabled={readOnly}
          >
            {this.props.buttontitle}
          </Button>{' '}
          <Button
            onClick={() => this.cancelCondition()}
            style={styles.scenarioMarginLeft}
            type="secondary"
            font="oswald"
            size='small'
            ghost
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
