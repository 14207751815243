import 'whatwg-fetch';
import { fromJS, List, Map } from 'immutable';
import { checkStatus, buildUrl } from '../helper.js';

import { ConsentPerformanceRecord } from '../../records/consent_performance_records.js';
import { DATEPICKERS} from '../../constants'

export async function getAndParseConsentReportActionStatusData(data) {
  const datePicker = data.get('datePicker')
  const formattedData = data.update('startDate', startDate => startDate.format('YYYY-MM-DD'))
    .update('endDate', endDate => endDate.format('YYYY-MM-DD'))
    .toMap()
    .filter(v => List.isList(v) ? v.size : v)
    .toJS();
  return fetch(buildUrl('/api/v1/consent_reports/consent_report_action_status', formattedData), {
    credentials: 'include',
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => parseActionStatusData(fromJS(resp), datePicker, formattedData))
}

export function getAndParseConsentReportVendorsData(data) {
  const formattedData = data.update('startDate', startDate => startDate.format('YYYY-MM-DD'))
    .update('endDate', endDate => endDate.format('YYYY-MM-DD'))
    .toMap()
    .filter(v => List.isList(v) ? v.size : v)
    .toJS();
  return fetch(buildUrl('/api/v1/consent_reports/consent_report_vendors', formattedData), {
    credentials: 'include',
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => ConsentPerformanceRecord({ vendorData: fromJS(resp.vendorResults) }))
}

function getAggregateData(data, datePicker) {
  let totalUsers = 0;
  let totalConsentedUsers = 0;
  let totalRejectedUsers = 0;
  let totalNoActionUsers;
  let totalMessages = 0;
  let consentedMessages = 0;
  let rejectedMessages = 0;
  let total_mv_map = new Map();
  let total_pv_count = 0;
  let usersAction = 0;
  let totalAutoConsent = 0;
  let totalAlreadyConsented = 0
  let totalIncompleteConsent = 0;
  let totalRejectedAll = 0;
  let totalConsentNone = 0;
  let totalPotentialUsers = 0;
  let consentSome = 0;
  let rejectSome = 0;
  let noAction = 0;
  let totalMessagedUsers = 0;

  data.get('statusResults').forEach(dataPoint => {
    total_pv_count += parseInt(dataPoint.get('total_pageviews'));
    totalUsers += parseInt(dataPoint.get('total_users'));
    const messagedUsers = parseInt(dataPoint.get('total_messaged_users'));
    if (messagedUsers === 0) {
      totalMessagedUsers = 0;
    } else {
      totalMessagedUsers += parseInt(dataPoint.get('total_messaged_users'));
    }
    totalAlreadyConsented += parseInt(dataPoint.get('user_status_consent_all'));
    totalIncompleteConsent += parseInt(dataPoint.get('user_status_consent_some'));
    totalRejectedAll += parseInt(dataPoint.get('user_status_rejected_all'));
    totalConsentNone += parseInt(dataPoint.get('user_status_no_consent_data'));
    totalPotentialUsers += parseInt(dataPoint.get('total_messaged_users'));
  });

  data.get('actionResults').forEach(dataPoint => {
    if (dataPoint.get('message_id') !== null && dataPoint.get('message_id') !== 0 && dataPoint.get('message_id') != "") {
      let key = datePicker === DATEPICKERS.day ? dataPoint.get('ymd') : '' + dataPoint.get('geo') + dataPoint.get('site_id') + dataPoint.get('message_id');
      let total_mv_count = parseInt(dataPoint.get('total_mv_count')); //TODO: double check this.
      if (!Number.isNaN(total_mv_count)) total_mv_map.set(key, total_mv_count);
      consentedMessages += parseInt(dataPoint.get('action_consent_all'));
      rejectedMessages += parseInt(dataPoint.get('action_reject_all'));
    }
    if ((dataPoint.get('message_id') == null || dataPoint.get('message_id') == "")  && dataPoint.get('privacy_manager_id') !== null) {
      consentSome += parseInt(dataPoint.get('action_consent_some'));
      rejectSome += parseInt(dataPoint.get('action_consent_some'));

    } if ((dataPoint.get('message_id') == null || dataPoint.get('message_id') == "" || dataPoint.get('message_id') == 0 )&& dataPoint.get('privacy_manager_id') == null){
      totalAutoConsent += parseInt(dataPoint.get('consent_all_count'));
    }
    usersAction += parseInt(dataPoint.get('total_actions'));
    totalConsentedUsers += parseInt(dataPoint.get('action_consent_all'));
    totalRejectedUsers += parseInt(dataPoint.get('action_reject_all'));
  });

  consentSome = Math.max(0, consentSome)

  if (totalMessagedUsers) {
    noAction = totalMessagedUsers - usersAction;
  }

  totalNoActionUsers =  Math.max(0, totalPotentialUsers - (totalConsentedUsers + totalRejectedUsers + consentSome ));

  for (let value of total_mv_map.values()) {
    totalMessages += value;
  }

  const totalConsentedPercent = ((totalConsentedUsers/totalPotentialUsers) * 100).toFixed(2) + '%';
  const consentSomePercent = ((consentSome/totalPotentialUsers) * 100).toFixed(2) + '%';
  const totalRejectedPercent = ((totalRejectedUsers/totalPotentialUsers) * 100).toFixed(2) + '%';
  const totalNoActionPercent = ((totalNoActionUsers/totalPotentialUsers) * 100).toFixed(2) + '%';
  const consentedMessagesPercent = ((consentedMessages/totalMessages) * 100).toFixed(2) + '%';
  const rejectedMessagesPercent = ((rejectedMessages/totalMessages) * 100).toFixed(2) + '%';
  const totalIncompleteConsentPercent = ((totalIncompleteConsent/totalMessages) * 100).toFixed(2) + '%';
  const totalAlreadyConsentedPercent = ((totalAlreadyConsented/totalUsers) * 100).toFixed(2) + '%';
  const totalPotentialUsersPercent = ((totalPotentialUsers/totalUsers) * 100).toFixed(2) + '%';

  const totalUsersChoice = usersAction;

  let aggregateData = {
    total: {
      totalUsers,
      totalUsersChoice,
      totalConsentedUsers,
      totalConsentedPercent,
      totalRejectedUsers,
      totalRejectedPercent,
      totalNoActionUsers,
      totalNoActionPercent,
      totalIncompleteConsentPercent,
      totalAlreadyConsentedPercent,
      totalPotentialUsersPercent,
      totalPotentialUsers,
      totalAlreadyConsented,
      totalIncompleteConsent,
      totalRejectedAll,
      totalConsentNone,
      rejectSome,
      consentSome,
      noAction,
      consentSomePercent,
      total_pv_count,
      totalAutoConsent,
      totalMessagedUsers,
    },
    messages: {
      totalMessages,
      consentedMessages,
      consentedMessagesPercent,
      rejectedMessages,
      rejectedMessagesPercent,
    },
  };
  aggregateData = fromJS(aggregateData);
  return aggregateData;
}

function accumulateMessageAndPM(groupedByDatesOrderedMap) {
  return groupedByDatesOrderedMap.map(day => {
    let mapWithEssentialInfo = {};
    let consent_message = 0;
    let reject_message = 0;
    let consent_privacy_manager = 0;
    let reject_privacy_manager = 0;
    let consent_total = 0;
    let reject_total = 0;
    let totalUsers = 0;
    let auto_consent = 0;
    let consent_some = 0;
    let usersActions = 0;

    day.forEach(data => {
      consent_total += parseInt(data.get('action_consent_all'));
      reject_total += parseInt(data.get('action_reject_all'));


      if (data.get('message_id') == null && data.get('message_id') == "" && data.get('privacy_manager_id') !== null) {
        consent_some += parseInt(data.get('action_consent_some'));
      }

      if (data.get('message_id') !== null && data.get('message_id') != ""  && data.get('message_id') !== 0) {
        consent_message += parseInt(data.get('action_consent_all'));
        reject_message += parseInt(data.get('action_reject_all'));
      } else if (data.get('privacy_manager_id') !== null) {
        consent_privacy_manager += parseInt(data.get('action_consent_all'));
        reject_privacy_manager += parseInt(data.get('action_reject_all'));
      } else {
        auto_consent = parseInt(data.get('action_consent_all'));
      }

      usersActions += parseInt(data.get('total_actions'));
    });

    totalUsers += consent_some + consent_total + reject_total;

    const consentTotalPercent = ((consent_total/totalUsers) * 100).toFixed(2) || 0;
    const consentTotal = consent_total;
    const consentMessagePercent = consent_message +' (' + (((consent_message/consent_total) * 100).toFixed(2) || 0) + ')%';
    const consentMessage = consent_message;
    const consentPrivacyMangerPercent = consent_privacy_manager + ' (' + (((consent_privacy_manager/consent_total) * 100).toFixed(2) ||  0) + ')%';
    const consentPrivacyManger = consent_privacy_manager;
    const rejectTotalPercent = reject_total + ' (' + (((reject_total/totalUsers) * 100).toFixed(2) || 0)  + ')%';
    const rejectTotal = reject_total;
    const rejectMessagePercent = reject_message + ' (' + (((reject_message/reject_total) * 100).toFixed(2) || 0)  + ')%';
    const rejectMessage = reject_message;
    const rejectPrivacyManagerPercent = reject_privacy_manager + ' (' + (((reject_privacy_manager/reject_total) * 100).toFixed(2) || 0)  + ')%';
    const rejectPrivacyManager= reject_privacy_manager;
    const noAction = ((1 - ((consent_total/totalUsers) + (reject_total/totalUsers))) * 100).toFixed(2);
    const consentSome = consent_some;

    mapWithEssentialInfo = {
      consentTotalPercent,
      consentMessagePercent,
      consentPrivacyMangerPercent,
      rejectTotalPercent,
      rejectMessagePercent,
      rejectPrivacyManagerPercent,
      totalUsers,
      noAction,
      consentTotal,
      consentMessage,
      consentPrivacyManger,
      rejectTotal,
      rejectMessage,
      rejectPrivacyManager,
      consentSome,
      usersActions,
      'date': day.first().get('ymd').slice(0, 10),
    };

    return mapWithEssentialInfo;
  });
}

function accumulatePVAggregates(groupedByDatesOrderedPVMap) {
  return groupedByDatesOrderedPVMap.map(day=>{
    let mapWithEssentialInfo = {};
    let total_pv_count = 0;
    let total_pv_consent_all = 0;
    let total_pv_consent_none = 0;
    let total_pv_consent_some = 0;
    let total_pv_rejected = 0;
    let totalUsers = 0;
    let previouslyConsentedAll = 0;
    let previouslyIncompleteConsent = 0;
    let previouslyRejectedAll = 0;
    let previouslyConsentedNone = 0;
    let totalPotentialUsers = 0;
    let totalMessagedUsers = 0;
    day.forEach(dataPoint => {
      totalUsers += parseInt(dataPoint.get('total_users'));
      total_pv_count += parseInt(dataPoint.get('total_pageviews'));
      total_pv_consent_all += parseInt(dataPoint.get('pv_status_consent_all'));
      total_pv_consent_some += parseInt(dataPoint.get('pv_status_consent_some'));
      total_pv_rejected += parseInt(dataPoint.get('pv_status_rejected_all'));
      total_pv_consent_none += parseInt(dataPoint.get('pv_status_no_consent_data'));
      previouslyConsentedAll += parseInt(dataPoint.get('user_status_consent_all'));
      previouslyIncompleteConsent += parseInt(dataPoint.get('user_status_consent_some'));
      previouslyRejectedAll += parseInt(dataPoint.get('user_status_rejected_all'));
      totalMessagedUsers += parseInt(dataPoint.get('total_messaged_users'));
    });

    totalPotentialUsers = totalUsers - previouslyConsentedAll;

    previouslyConsentedNone = totalUsers - previouslyConsentedAll - previouslyIncompleteConsent - previouslyRejectedAll;

    mapWithEssentialInfo = Map({
      total_pv_count,
      total_pv_consent_all,
      total_pv_consent_none,
      total_pv_consent_some,
      total_pv_rejected,
      totalUsers,
      previouslyConsentedAll,
      previouslyIncompleteConsent,
      previouslyRejectedAll,
      previouslyConsentedNone,
      totalPotentialUsers,
      totalMessagedUsers,
      'date': day.first().get('ymd').slice(0, 10),
    });

    return mapWithEssentialInfo;
  });
}

function accumulateWeeklyAndMonthlyPVAggregates(data) {
  let mapWithEssentialInfo = {};
  let total_pv_count = 0;
  let total_pv_consent_all = 0;
  let total_pv_consent_none = 0;
  let total_pv_consent_some = 0;
  let total_pv_rejected = 0;
  let totalUsers = 0;
  let previouslyConsentedAll = 0;
  let previouslyIncompleteConsent = 0;
  let previouslyRejectedAll = 0;
  let previouslyConsentedNone = 0;
  let totalPotentialUsers = 0;
  let totalMessagedUsers = 0;
  data.forEach(dataPoint => {
    totalUsers += parseInt(dataPoint.get('total_users'));
    total_pv_count += parseInt(dataPoint.get('total_pageviews'));
    total_pv_consent_all += parseInt(dataPoint.get('pv_status_consent_all'));
    total_pv_consent_some += parseInt(dataPoint.get('pv_status_consent_some'));
    total_pv_rejected += parseInt(dataPoint.get('pv_status_rejected_all'));
    total_pv_consent_none += parseInt(dataPoint.get('pv_status_no_consent_data'));
    previouslyConsentedAll += parseInt(dataPoint.get('user_status_consent_all'));
    previouslyIncompleteConsent += parseInt(dataPoint.get('user_status_consent_some'));
    previouslyRejectedAll += parseInt(dataPoint.get('user_status_rejected_all'));
    totalMessagedUsers += parseInt(dataPoint.get('total_messaged_users'));
  });

  totalPotentialUsers = totalUsers - previouslyConsentedAll;

  previouslyConsentedNone = totalUsers - previouslyConsentedAll - previouslyIncompleteConsent - previouslyRejectedAll;

  mapWithEssentialInfo = Map({
    total_pv_count,
    total_pv_consent_all,
    total_pv_consent_none,
    total_pv_consent_some,
    total_pv_rejected,
    totalUsers,
    previouslyConsentedAll,
    previouslyIncompleteConsent,
    previouslyRejectedAll,
    previouslyConsentedNone,
    totalPotentialUsers,
    totalMessagedUsers,
  });

  return mapWithEssentialInfo;
}


async function parseActionStatusData(data, datePicker, formattedData) {
  let auxData
  let groupedByDatesOrderedMap
  let groupedByDatesOrderedPVMap
  let orderedMapFlattened
  let PVorderedMapFlattened
  let tableData
  let PVtableData
  let PVGraphData
  if (datePicker === DATEPICKERS.week || datePicker === DATEPICKERS.month) {
    let auxUrl = datePicker === DATEPICKERS.week ? 'weekly' : 'monthly'
    auxData = await fetch(buildUrl(`/api/v1/consent_reports/consent_report_action_status_${auxUrl}`, formattedData), {
      credentials: 'include',
    }).then(checkStatus)
      .then(resp => resp.json())
      .then(resp => fromJS(resp));

    groupedByDatesOrderedMap = data.get('actionResults').groupBy((dataPoint) => {
      return dataPoint.get('ymd');
    });
    groupedByDatesOrderedPVMap = data.get('statusResults').groupBy((dataPoint) => {
      return dataPoint.get('ymd');
    });
    orderedMapFlattened = accumulateMessageAndPM(groupedByDatesOrderedMap);
    tableData = orderedMapFlattened.valueSeq().sort((a, b) => a.date >= b.date);
    PVorderedMapFlattened = accumulateWeeklyAndMonthlyPVAggregates(auxData.get('statusResults'))
    PVtableData = PVorderedMapFlattened
    PVGraphData = accumulatePVAggregates(groupedByDatesOrderedPVMap);

  }
  else {
    groupedByDatesOrderedMap = data.get('actionResults').groupBy((dataPoint) => {
      return dataPoint.get('ymd');
    });
    groupedByDatesOrderedPVMap = data.get('statusResults').groupBy((dataPoint) => {
      return dataPoint.get('ymd');
    });
    orderedMapFlattened = accumulateMessageAndPM(groupedByDatesOrderedMap);
    PVorderedMapFlattened = accumulatePVAggregates(groupedByDatesOrderedPVMap);
    tableData = orderedMapFlattened.valueSeq().sort((a, b) => a.date >= b.date);
    PVtableData = PVorderedMapFlattened.valueSeq().sort((a, b) => a.get('date') >= b.get('date'));
    PVGraphData = PVtableData
  }
  
  const aggregateData = getAggregateData(datePicker === DATEPICKERS.day ? data : auxData, datePicker);

  return ConsentPerformanceRecord({
    tableData,
    PVtableData,
    aggregateData,
    PVGraphData
  });
}
