import * as types from '../actions/action_types';

import { Map, OrderedSet } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';
import { ViewState } from '../records/campaign_records.js';

const initialState = Map({
    campaigns: new StoreRecord({ value: OrderedSet([]) }),
    statsData: Map({}),
    graphData: Map({}),
    viewState: new ViewState(),
});

export default (state = initialState, action) => {

    switch(action.type) {

        case types.CAMPAIGNS_PENDING:
            return setPending(state, ['campaigns']);

        case types.CAMPAIGNS_CACHED:
            return stopPending(state, ['campaigns']);

        case types.CAMPAIGNS_LOADED:
            return setValue(state, ['campaigns'], state.getIn(['campaigns', 'value']).concat(action.campaigns));

        case types.CAMPAIGNS_ERROR:
            return setError(state, ['campaigns'], action.errorMessage);

        case types.CAMPAIGN_STOPPED:
            let campaigns = action.campaign
            if (!OrderedSet.isOrderedSet(action.campaign)) {
              campaigns = OrderedSet([action.campaign]);
            }
            const updatedCampaigns = campaigns.concat(state.getIn(['campaigns', 'value']));
            return setValue(state, ['campaigns'], updatedCampaigns);

        case types.CAMPAIGN_STOPPED_UPDATE:
            let newCampaigns = action.campaign
            if (!OrderedSet.isOrderedSet(action.campaign)) {
              newCampaigns = OrderedSet([action.campaign]);
            }
            let prevCampaigns = state.getIn(['campaigns', 'value']);
            let updatedCampaignList = prevCampaigns.filterNot(c => c.id === action.campaign.id);
            updatedCampaignList = newCampaigns.concat(updatedCampaignList);
            return setValue(state, ['campaigns'], updatedCampaignList);            

        case types.CAMPAIGN_STATS_PENDING:
            const newStats = new StoreRecord({ pending: true });
            return state.setIn(['statsData', action.campaignId], newStats);

        case types.CAMPAIGN_STATS_CACHED:
            const updatedStats = this.state.statsData.update(action.id, record => record.set('pending', false));
            return state.set('statsData', updatedStats);

        case types.CAMPAIGN_STATS_LOADED:
            return setValue(state, ['statsData', action.campaignId], action.statsData);

        case types.CAMPAIGN_STATS_ERROR:
            return setError(state, ['statsData', action.campaignId], action.errorMessage);

        case types.CAMPAIGN_GRAPH_PENDING:
            const newGraph = new StoreRecord({ pending: true });
            return state.setIn(['graphData', action.campaignId], newGraph);

        case types.CAMPAIGN_GRAPH_CACHED:
            const updatedGraph = this.state.graphData.update(action.id, record => record.set('pending', false));
            return state.set('statsData', updatedGraph);

        case types.CAMPAIGN_GRAPH_LOADED:
            return setValue(state, ['graphData', action.campaignId], action.graphData);

        case types.CAMPAIGN_GRAPH_ERROR:
            return setError(state, ['graphData', action.campaignId], action.errorMessage);

        case types.UPDATE_CAMPAIGNS_VIEW_STATE:
            return state.set('viewState', action.viewState);

        case types.CLEAR_CAMPAIGN_VIEW_STATE:
            return state.set('viewState', new ViewState());
    }
    return state;
};
