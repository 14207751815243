import * as types from './action_types';
import { actionCreator } from './helpers';
import { getAndParseConsentReportChangeLogData, getAndParseConsentReportPropertyData, getAndParseConsentReportVendorData, getAndParseConsentReportMessagedData, getAndParseConsentReportPurposeData } from '../api/consent_latest/consent_performance_report';

export const getConsentReportChangeLogData = actionCreator(
    getAndParseConsentReportChangeLogData,
    consentReportChangeLogPending,
    consentReportChangeLogLoaded,
    consentReportChangeLogError,
    consentReportChangeLogCached
);

export const getConsentReportPurposeData = actionCreator(
    getAndParseConsentReportPurposeData,
    consentReportPurposePending,
    consentReportPurposeLoaded,
    consentReportPurposeError,
    consentReportPurposeCached
);

export const getConsentReportPropertyActionData = actionCreator(
    getAndParseConsentReportPropertyData,
    consentReportPropertyPending,
    consentReportPropertyLoaded,
    consentReportPropertyError,
    consentReportPropertyCached
);

export const getConsentReportVendorData = actionCreator(
    getAndParseConsentReportVendorData,
    consentReportVendorPending,
    consentReportVendorLoaded,
    consentReportVendorError,
    consentReportVendorCached
);

export const getConsentReportMessageData = actionCreator(
    getAndParseConsentReportMessagedData,
    consentReportMessagePending,
    consentReportMessageLoaded,
    consentReportMessageError,
    consentReportMessageCached
);

function consentReportChangeLogPending() {
    return {
      type: types.CONSENT_REPORT_CHANGELOG_PENDING,
    };
}
  
function consentReportChangeLogLoaded(data) {
    return {
        type: types.CONSENT_REPORT_CHANGELOG_LOADED,
        data,
    };
}

function consentReportChangeLogError(error) {
    return {
        type: types.CONSENT_REPORT_CHANGELOG_ERROR,
        error,
    };
}

function consentReportChangeLogCached() {
    return {
      type: types.CONSENT_REPORT_CHANGELOG_CACHED,
    };
}

function consentReportPurposePending() {
    return {
      type: types.CONSENT_REPORT_PURPOSE_PENDING,
    };
}
  
function consentReportPurposeLoaded(data) {
    return {
        type: types.CONSENT_REPORT_PURPOSE_LOADED,
        data,
    };
}

function consentReportPurposeError(error) {
    return {
        type: types.CONSENT_REPORT_PURPOSE_ERROR,
        error,
    };
}

function consentReportPurposeCached() {
    return {
      type: types.CONSENT_REPORT_PURPOSE_CACHED,
    };
}

function consentReportMessagePending() {
    return {
      type: types.CONSENT_REPORT_MESSAGE_PENDING,
    };
}
  
function consentReportMessageLoaded(data) {
    return {
        type: types.CONSENT_REPORT_MESSAGE_LOADED,
        data,
    };
}

function consentReportMessageError(error) {
    return {
        type: types.CONSENT_REPORT_MESSAGE_ERROR,
        error,
    };
}

function consentReportMessageCached() {
    return {
      type: types.CONSENT_REPORT_MESSAGE_CACHED,
    };
}

function consentReportVendorPending() {
    return {
      type: types.CONSENT_REPORT_VENDOR_PENDING,
    };
}
  
function consentReportVendorLoaded(data) {
    return {
        type: types.CONSENT_REPORT_VENDOR_LOADED,
        data,
    };
}

function consentReportVendorError(error) {
    return {
        type: types.CONSENT_REPORT_VENDOR_ERROR,
        error,
    };
}

function consentReportVendorCached() {
    return {
      type: types.CONSENT_REPORT_VENDOR_CACHED,
    };
}

function consentReportPropertyPending() {
    return {
      type: types.CONSENT_REPORT_PROPERTY_PENDING,
    };
}
  
function consentReportPropertyLoaded(data) {
    return {
        type: types.CONSENT_REPORT_PROPERTY_LOADED,
        data,
    };
}

function consentReportPropertyError(error) {
    return {
        type: types.CONSENT_REPORT_PROPERTY_ERROR,
        error,
    };
}

function consentReportPropertyCached() {
    return {
      type: types.CONSENT_REPORT_PROPERTY_CACHED,
    };
}