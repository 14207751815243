import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { VendorList } from '../../../../../records/vendor_list_records';
import AllIabCheckbox from '../../AllIabCheckbox';
import AutoAddNewVendorsCheckbox from '../../AutoAddNewVendorsCheckbox';

export default class Table extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    readOnly: PropTypes.bool.isRequired,
    vendorScanningEnabled: PropTypes.bool.isRequired,
    hasIabPurposesToAdd: PropTypes.bool.isRequired,

    toggleAddVendors: PropTypes.func.isRequired,
    openCreateCustomPurposeModal: PropTypes.func.isRequired,
    openCreateIabPurposeModal: PropTypes.func.isRequired,
    openIabSpecialFeaturesModal: PropTypes.func.isRequired,
    openCreateStackModal: PropTypes.func.isRequired,
    toggleAutoScan: PropTypes.func.isRequired,
    toggleAllIabAndUpdate: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
  };

  render() {
    const { filterElement } = this.props;
    if (this.props.readOnly) {
      return null;
    } else {
      return (
        <div className="table-footer">
          <Button
            size="large"
            className="add-vendor-btn add-purpose-btn"
            onClick={this.props.openCreateCustomPurposeModal}
            disabled={this.props.readOnly}
          >
            + Add Custom Purpose
          </Button>
          {filterElement(
            <React.Fragment>
              <Button
                size="large"
                className="add-vendor-btn add-purpose-btn"
                onClick={this.props.openCreateIabPurposeModal}
                disabled={this.props.readOnly || !this.props.hasIabPurposesToAdd}
              >
                + Add IAB Purpose
              </Button>
              <Button
                size="large"
                className="add-vendor-btn add-purpose-btn"
                onClick={this.props.openIabSpecialFeaturesModal}
                disabled={this.props.readOnly}
              >
                Manage IAB Special Features
              </Button>
            </React.Fragment>
          )}
          <Button
            size="large"
            className="add-vendor-btn add-purpose-btn"
            onClick={this.props.openCreateStackModal}
            disabled={this.props.readOnly}
          >
            Manage Stacks
          </Button>
        </div>
      );
    }
  }
}