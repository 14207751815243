import PropTypes from 'prop-types';
import React from 'react';
import { Map, List } from 'immutable';
import * as d3 from "d3";
import { numberWithCommas } from '../../../utils.js';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Table, Tooltip, Input, Alert } from 'antd';  
import { campaignValueDataShape } from '../../../../custom_prop_types.js';

import { getRpmCookie, setRpmCookie } from '../../../../api/dialogue/campaigns';
import { getUserDetailsAjax } from '../../../../api/account';

const TYPE_TO_NAME = {
    "totalWLPV": "Whitelist",
    "totalRAPV": "Recovery",
    "totalAltAd": "Alt-Ad Video",
    "totalAltPay": "Alt Pay"
};

const TYPE_TO_VALUE_DESCRIPTION = {
    "totalWLPV": "Whitelist PVs",
    "totalRAPV": "Recovered PVs",
    "totalAltAd": "Videos",
    "totalAltPay": "Alt Pay Purchases"
};

const WHITELIST_TO_DESCRIPTION = {
    0: "Whitelist without choice, message seen",
    1: '"Disable Adblocker"',
    2: '"Redirect"',
    3: '"Continue With Adblocker"',
    4: '"Watch Video"',
    5: '"Pub Custom"'
};

export default class extends React.Component {
    static propTypes = {
        totalValues: campaignValueDataShape.isRequired,
        whitelistValues: ImmutablePropTypes.list.isRequired,
        siteId: PropTypes.string.isRequired,
        campaignCreateDate: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        const createDate = new Date(props.campaignCreateDate);
        const cutOfDate = new Date(2017, 2, 17);

        this.state = {
            rpm: Map({
                totalWLPV: 12.00,
                totalRAPV: 3.00,
                totalAltAd: 15.00,
                totalAltPay: 1000.00,
            }),
            incompleteDataWarning: createDate < cutOfDate,
            timeoutId: null,
            accountId: null,
            masquerading: false,
            whitelistExpanded: false,
        };
    }

    componentDidMount() {
        getRpmCookie(this.props.siteId).then((rpm) => {
            if (rpm && rpm.totalWLPV) {
                // In case old cookie doesn't have Alt Pay
                if (!rpm.totalAltPay) rpm.totalAltPay = 1000;
                this.setState({ rpm: Map(rpm) });
            }
        });

        getUserDetailsAjax().then(currentUser => this.setState({ accountId: currentUser.accountId }));
    }

    toggleWhitelistExpanded = () => {
        this.setState({ whitelistExpanded: !this.state.whitelistExpanded })
    };

    onRpmChange = (type, e) => {
        const value = e.target.value;
        // only track mixpanel rpm change when finished typing
        clearTimeout(this.state.timeoutId);
        const id = setTimeout(this.mixpanelTrack.bind(null, type, value), 3000);
        const rpm = this.state.rpm.set(type, value);
        this.setState({ rpm, timeoutId: id });
        setRpmCookie(this.props.siteId, rpm);
    };

    mixpanelTrack = (type, value) => {
        const rpmData = {
            accountID: this.state.accountId,
            siteID: this.props.siteId,
            campaignID: this.props.campaignId,
            valueType: type,
            valueRPM: value,
            masquerading: this.state.masquerading,
            version: 1
        }
        // mixpanel.track("reporting.updateRPM", rpmData);
    };

    onWhitelistExpand = () => {
        this.toggleWhitelistExpanded();
    };

    onRowClick = (record, index) => {
        if (record.key === "totalWLPV" && this.props.whitelistValues.reduce((t, i) => t + i, 0) ) this.toggleWhitelistExpanded();
    };

    rowClassName = (record, index) => {
        if (this.state.whitelistExpanded && (record.key === "totalWLPV" || typeof(record.key) === "number")) {
            return "highlighted";
        } else if (record.count === "0") {
            return "low-opacity";
        } else {
            return "";
        }
    };

    render() {

        const columns = [{
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          className: 'type'
        }, {
          title: 'Count',
          dataIndex: 'count',
          key: 'count',
          className: 'count',
          render: (value, row, index) => {
              const obj = {
                  children: value,
                  props: {},
              };
              if (!row.rpm) {
                obj.props.className = "count blue";
              }
              return obj;
          }
        }, {
          title: "",
          dataIndex: 'description',
          key: 'description',
          className: 'description',
          render: (value, row, index) => {
              const obj = {
                  children: value,
                  props: {},
              };
              if (!row.rpm) {
                obj.props.colSpan = 2;
              }
              return obj;
          }
        }, {
          title: 'RPM',
          dataIndex: 'rpm',
          key: 'rpm',
          className: 'rpm',
          render: (value, row, index) => {
              const obj = {
                  children: value,
                  props: {},
              };
              if (!row.rpm) {
                obj.props.colSpan = 0;
              }
              return obj;
          }
        }, {
          title: 'Est. Rev.',
          dataIndex: 'value',
          key: 'value',
          className: 'value',
          render: (value, row, index) => {
              const obj = {
                  children: value,
                  props: {},
              };
              if (!row.rpm && row.key !== "total") {
                obj.props.className = "value blue";
              }
              return obj;
          }
        }];

        const values = this.props.totalValues;
        let totalValue = 0;
        let whiteListDetail = this.props.whitelistValues.map((value, i) => {
            let obj = Map();
            obj = obj.set("key", i)
                     .set("type", "")
                     .set("count", numberWithCommas(value));
            let description;
            if (i === 0) {
                description = WHITELIST_TO_DESCRIPTION[i];
            } else {
                description = <span>after <strong>{ WHITELIST_TO_DESCRIPTION[i] }</strong> choice</span>;
            }
            obj = obj.set("description", description);
            value = (this.state.rpm.get("totalWLPV") / 1000) * value;
            obj = obj.set("value", d3.format("$,.2f")(value));
            return obj;
        })

        whiteListDetail = whiteListDetail.filter(el => el.get("value") !== "$0.00");
        let dataSource = List([]);
        let zeroValueRows = List([]);

        values.forEach((value, type) => {
            let obj = Map();
            obj = obj.set("key", type)
                     .set("type", TYPE_TO_NAME[type])
                     .set("count", numberWithCommas(value))
                     .set("description", TYPE_TO_VALUE_DESCRIPTION[type])
                     .set("rpm", (
                        <span>
                          <span className="currencySign">$</span><input
                            type="number"
                            step="0.01"
                            value={ this.state.rpm.get(type) }
                            onChange={ this.onRpmChange.bind(null, type) }
                          />
                        </span>
                    ))
            value = (this.state.rpm.get(type) / 1000) * value;
            totalValue += value;
            obj = obj.set("value", d3.format("$,.2f")(value));
            if (type === "totalWLPV" && value ) {
                obj = obj.set("children", whiteListDetail);
            }
            if (value === 0) {
                zeroValueRows = zeroValueRows.push(obj);
            } else {
                dataSource = dataSource.push(obj);
            }
        })

        dataSource = dataSource.concat(zeroValueRows);

        let total = Map();
        total = total.set("key", "total")
                     .set("type", "Total")
                     .set("count", "")
                     .set("description", "")
                     .set("rpm", "")
                     .set("value", d3.format("$,.2f")(totalValue));

        dataSource = dataSource.push(total);

        let warning;
        if (this.state.incompleteDataWarning) {
            const warningMessage = "Value data is incomplete for campaigns started before Saturday, March 18, 2017.";
            warning = <Alert className="incomplete-data-alert" message={ warningMessage } type="warning" showIcon />;
        }

        return (
            <div>
              { warning }
              <Table
                className="summary-breakdown-table"
                dataSource={ dataSource.toJS() }
                columns={ columns }
                pagination={ false }
                onExpand={ this.onWhitelistExpand }
                onRowClick={ this.onRowClick }
                rowClassName={ this.rowClassName }
                expandedRowKeys={ this.state.whitelistExpanded ? ["totalWLPV"] : [] }
              />
            </div>
        )
    }
}
