import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import {
  createSite,
  createProperty,
  updateProperty,
  createSiteGroup,
  getSiteGroups,
  updateSiteGroup,
  updatePropertyGroup,
  getAllSites,
  deleteSiteGroup,
  deleteSite,
  updateSite,
  dismissError,
} from '../../../actions/site_actions';
import { User } from '../../../records/account_records';
import { Site } from '../../../records/site_records';
import { Tabs, Button, Headline,message } from '../../../styleguide';
import Properties from './Properties';
import CreateEditProperty from './CreateEditProperty';
import PropertyGroups from './PropertyGroups';
import { PlusOutlined } from '@ant-design/icons';
import UploadBulkProperties from './UploadBulkProperties';
import { propertyTypesNum, hasFeature } from './helper';

const { TabPane } = Tabs;

const defaultTab = (location) => {
  if (location?.query?.tab === 'groups') return '2';
  return '1';
}

class PropertiesPage extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    createSite: PropTypes.func.isRequired,
    createProperty: PropTypes.func.isRequired,
    pending: PropTypes.bool.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      isMasq: PropTypes.bool.isRequired,
    }).isRequired,
    createSiteGroup: PropTypes.func.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
    updateSiteGroup: PropTypes.func.isRequired,
    updatePropertyGroup: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    deleteSiteGroup: PropTypes.func.isRequired,
    deleteSite: PropTypes.func.isRequired,
    updateSite: PropTypes.func.isRequired,
    dismissError: PropTypes.func.isRequired,
  };

  state = {
    visibleAddProperty: false,
    visibleBulkProperty: false,
    editProperty: new Site(),
    tabActiveKey: defaultTab(this.props.location),
  };

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.getAllSites();
      this.props.getSiteGroups(this.props.currentUser);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getSiteGroups(this.props.currentUser);
    }
  }

  updateSites = () => {
    this.props.getAllSites();
  }

  onTabChange = (key) => this.setState({ tabActiveKey: key });

  toggleAddPropertyModal = (e) =>
    this.setState((prevState) => ({
      visibleAddProperty: !prevState.visibleAddProperty,
      editProperty: new Site(),
    }));

  toggleBulkPropertyModal = (e) => 
    this.setState((prevState)=>({
      visibleBulkProperty: !prevState.visibleBulkProperty,
      editProperty: new Site(),
    }))

  getUserSiteGroups = () => {
    if (this.props.currentUser.siteAccess) {
      return this.props.siteGroups.filter((sg) => {
        return sg.siteIds.every((id) => {
          return this.props.currentUser.siteAccess.includes(id);
        });
      });
    } else {
      return this.props.siteGroups;
    }
  };

  handleAddSite = (name, type, propertyGroup, multiCampaignEnabled) => 
    this.props.createProperty(name, type, this.props.currentUser.accountId, propertyGroup, multiCampaignEnabled);

  handleBulkSites = (properties, propertyGroup) => {
    properties.forEach((property)=>{
        this.handleAddSite(property.name, property.type, propertyGroup , true)
    })
  }    

  handleUpdateSite = (id, name, type, propertyGroup) =>{
    const existingPropertyGroup = this.getPropertyGroup(id);
    this.props.updateProperty(id, name, type,propertyGroup,existingPropertyGroup, this.props.currentUser.accountId).then(() => {
      message.success("Property updated")
    });
  }

  editProperty = (property) =>
    this.setState({ visibleAddProperty: true, editProperty: property });

  getPropertyGroup = () =>
    this.props.siteGroups.find((pg) =>
      pg.siteIds.includes(this.state.editProperty.id)
    ) || null;

  getPropertyName = (siteId) =>
    this.props.sites.find((s) => s.id === siteId)
      ? this.props.sites.find((s) => s.id === siteId).domain
      : null;

  render() {
    if (!this.props.currentUser) return null;

    let sites = this.props.sites;
    const { accountFeatures } = this.props.currentUser;
    const hasPrivacyManagerOTT = hasFeature(accountFeatures, "privacy_manager_ott");
    let propertyTypesNumFiltered = propertyTypesNum;
    propertyTypesNumFiltered = hasPrivacyManagerOTT ? propertyTypesNumFiltered : propertyTypesNumFiltered.filterNot(p=>p.get('code') === 'ott');
    sites = sites.filterNot((s) => {
      return this.props.siteGroups.map((sg) => sg.siteId).includes(s.id);
    });
    if (this.props.currentUser.siteAccess) {
      sites = sites.filter((s) =>
        this.props.currentUser.siteAccess.includes(s.id)
      );
    }
    sites = sites.filter(
      (s) =>
        s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')
    );

    var containerClass = 'redesign properties-container';
    let addPropertyBtn;
    const currUser = this.props.currentUser;
    if (!currUser.featureAccess || (currUser.featureAccess && currUser.featureAccess.includes('Property Managment'))) {
      addPropertyBtn = (
        <>
          <Button type="secondary" size="small" onClick={this.toggleBulkPropertyModal}>
            <PlusOutlined /> Bulk Upload Properties
          </Button>
          <Button type="primary" size="small" onClick={this.toggleAddPropertyModal}>
            <PlusOutlined /> Add New Property
          </Button>
        </>
      );
    }

    const isMultiCampaignAvailable = accountFeatures.includes('tcf_v2') || accountFeatures.includes('non_iab_vl') || accountFeatures.includes('ccpa') || accountFeatures.includes('usnat')  ;
    const isMultiCampaignAvailableDefault = accountFeatures.includes('multi_campaign_default');

    const addPropertyModal = this.state.visibleAddProperty && (
      <CreateEditProperty
        visibleAddProperty={this.state.visibleAddProperty}
        toggleAddPropertyModal={this.toggleAddPropertyModal}
        propertyGroups={this.getUserSiteGroups()}
        createOrUpdateSite={
          this.state.editProperty.id ? this.handleUpdateSite : this.handleAddSite
        }
        editProperty={this.state.editProperty}
        propertyGroup={this.getPropertyGroup()}
        sites={sites}
        multiCampaign={true}
        propertyTypesNum={propertyTypesNumFiltered}
        accountFeatures={accountFeatures}
        />
    );

    const addBulkPropertyModal = this.state.visibleBulkProperty && (
      <UploadBulkProperties 
        visibleBulkProperty={this.state.visibleBulkProperty}
        toggleBulkPropertyModal={this.toggleBulkPropertyModal}
        sites={sites}
        createOrUpdateSite={this.handleBulkSites}
        propertyTypesNum={propertyTypesNumFiltered}
      />
    );

    return (
      <div className={containerClass}>
        {addPropertyModal}
        {addBulkPropertyModal}
        <Headline>PROPERTIES</Headline>
        <Tabs
          onChange={this.onTabChange}
          type="card"
          defaultActiveKey="1"
          tabBarExtraContent={this.state.tabActiveKey == '1' ? addPropertyBtn : null}
          activeKey={this.state.tabActiveKey}
        >
          <TabPane tab="Properties" key="1">
            <Properties
              sites={sites}
              siteGroups={this.props.siteGroups}
              deleteSite={this.props.deleteSite}
              updateSite={this.props.updateSite}
              accountId={this.props.currentUser.accountId}
              editProperty={this.editProperty}
              error={this.props.error}
              loading={this.props.pendingRequestsMap.get('sites')}
              isMultiCampaignAvailable={isMultiCampaignAvailable}
              isMultiCampaignAvailableDefault={isMultiCampaignAvailableDefault}
              accountFeatures={accountFeatures}
              selectSite={this.props.selectSite}
              propertyTypesNum={propertyTypesNumFiltered}
            />
          </TabPane>
          { !hasFeature(accountFeatures, "hide_property_groups") && (
            <TabPane tab="Property Groups" key="2">
              <span className="property-group-heading">
                Note: At a time, Property can exist only in one Property Group.
              </span>
              <PropertyGroups
                updateSites={this.updateSites}
                sitesWithSiteGroups={this.props.sites}
                sites={sites}
                siteGroups={this.props.siteGroups}
                loading={this.props.pendingRequestsMap.get('siteGroups')}
                getPropertyName={this.getPropertyName}
                accountId={this.props.currentUser.accountId}
                createSiteGroup={this.props.createSiteGroup}
                updatePropertyGroup={this.props.updateSiteGroup}
                updateMultiCampaignPropertyGroup={this.props.updatePropertyGroup}
                deleteSiteGroup={this.props.deleteSiteGroup}
                isAdmin={this.props.currentUser.isAdmin}
                currentUser={this.props.currentUser}
                isMultiCampaignAvailable={isMultiCampaignAvailable}
                isMultiCampaignAvailableDefault={isMultiCampaignAvailableDefault}
                selectSite={this.props.selectSite}
                propertyTypesNum={propertyTypesNumFiltered}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    pending: store.siteState.getIn(['sites', 'pending']),
    error: store.siteState.getIn(['sites', 'error']),
    siteGroups: store.siteState
      .getIn(['siteGroups', 'value'])
      .sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      ),
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      siteGroups: store.siteState.getIn(['siteGroups', 'pending']),
    }),
  };
};

export default connect(mapStateToProps, {
  createSite,
  createProperty,
  updateProperty,
  createSiteGroup,
  getSiteGroups,
  updateSiteGroup,
  updatePropertyGroup,
  getAllSites,
  deleteSiteGroup,
  deleteSite,
  updateSite,
  dismissError,
})(PropertiesPage);
