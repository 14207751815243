import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getConsentCompensationListsAjax,
  getConsentCompensationListAjax,
  createConsentCompensationListAjax,
  updateConsentCompensationListAjax,
  deleteConsentCompensationListAjax,
  getPartnersAjax,
} from '../api/consent_compensation';
import { getAllSitesAjax } from '../api/dialogue/sites';

export const getPartners = actionCreator(
  getPartnersAjax,
  getPartnersPending,
  partnersLoaded,
  partnersError,
);

export const getConsentCompensationLists = actionCreator(
  getConsentCompensationListsAjax,
  consentCompensationListsPending,
  consentCompensationListsLoaded,
  consentCompensationListsError,
);

export function getConsentCompensationList(id, accountId) {
  return (dispatch, getState) => {

    dispatch(consentCompensationListsPending());

    getConsentCompensationListAjax(id, accountId).then((vl) => {
      let consentCompensationList = vl;
      // if (consentCompensationList === 'fetched') {
      //   consentCompensationList = getState().consentCompensationListState.getIn(['consentCompensationLists', 'value']).find(vl => vl.id === id);
      // }

      getAllSitesAjax().then((s) => {
        let sites = s;
        if (sites === 'fetched') {
          sites = getState().siteState.getIn(['sites', 'value']).filter(s => consentCompensationList.siteIds.includes(s.id));
        }

        const sitesWithoutDeleted = consentCompensationList.siteIds.filter(id => sites.map(s => s.id).includes(id));
        dispatch(consentCompensationListLoaded(consentCompensationList.set('siteIds', sitesWithoutDeleted)));
      });

    }).catch((resp) => dispatch(consentCompensationListsError(resp)));
  };
}

export const createConsentCompensationList = actionCreator(
  createConsentCompensationListAjax,
  consentCompensationListSavePending,
  consentCompensationListCreated,
  consentCompensationListsError,
  null,
);

export const updateConsentCompensationList = actionCreator(
  updateConsentCompensationListAjax,
  consentCompensationListSavePending,
  consentCompensationListUpdated,
  consentCompensationListsError,
  null,
);

export const deleteConsentCompensationList = actionCreator(
  deleteConsentCompensationListAjax,
  consentCompensationListsPending,
  consentCompensationListDeleted,
  consentCompensationListsError,
  null,
);

function getPartnersPending() {
  return {
    type: types.GET_PARTNERS_PENDING,
  };
}

function partnersLoaded(partners) {
  return {
    type: types.PARTNERS_LOADED,
    partners,
  };
}

function partnersError(error) {
  return {
    type: types.GET_PARTNERS_ERROR,
    error,
  };
}

function consentCompensationListsPending() {
  return {
    type: types.CONSENT_COMPENSATION_LISTS_PENDING,
  };
}

function consentCompensationListsLoaded(consentCompensationLists) {
  return {
    type: types.CONSENT_COMPENSATION_LISTS_LOADED,
    consentCompensationLists,
  };
}

function consentCompensationListsError(error) {
  return {
    type: types.CONSENT_COMPENSATION_LISTS_ERROR,
    error,
  };
}

function consentCompensationListCreated(consentCompensationList) {
  return {
    type: types.CONSENT_COMPENSATION_LIST_CREATED,
    consentCompensationList,
  };
}

function consentCompensationListUpdated(consentCompensationList) {
  return {
    type: types.CONSENT_COMPENSATION_LIST_UPDATED,
    consentCompensationList,
  };
}

function consentCompensationListDeleted(id) {
  return {
    type: types.CONSENT_COMPENSATION_LIST_DELETED,
    id,
  };
}

function consentCompensationListSavePending() {
  return {
    type: types.CONSENT_COMPENSATION_LIST_SAVE_PENDING,
  };
}

function consentCompensationListLoaded(consentCompensationList) {
  return {
    type: types.CONSENT_COMPENSATION_LIST_LOADED,
    consentCompensationList,
  };
}

export function clearConsentCompensationListError() {
  return {
    type: types.CLEAR_CONSENT_COMPENSATION_LIST_ERROR,
  };
}