import { FETCHED } from '../constants.js';

export function actionCreator(
  ajaxRequest,
  pendingCb,
  loadCb,
  errorCb,
  cachedCb,
  logCb
) {
  return (...args) => {
    return (dispatch, getState) => {
      dispatch(pendingCb(...args));
      return ajaxRequest(...args)
        .then((resp) => {
          if (logCb) logCb(getState(), resp, args);
          if (resp === FETCHED) {
            dispatch(cachedCb(...args));
          } else {
            dispatch(loadCb(resp, ...args));
            return resp;
          }
        })
        .catch((resp) => {
          dispatch(errorCb(resp, ...args))
        });
    }
  }
}
