import React from "react";
import { Collapse, Row, Col } from "antd";
import { Table } from "../../../styleguide";
import { Map, List, fromJS, is } from "immutable";
import moment from 'moment';
import RecentChangesModal from "./RecentChangesModal";
import {
  ResponsiveContainer,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend
} from "recharts";
import {
  LINE_GRAPH_COLOR_LIST,
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST,
  LINE_GRAPH_LEGEND_PATTERN_LIST
} from "../../../constants";
import { createCSVExportData } from "../../consent/performanceNew/helper";
import ExportToolTip from "../dataprivacy/ExportToolTip";
import { mapReduceAvg, mapReduceSum } from "../../consent/gdpr_performance_tcfv2_v2/helper";

const { Panel } = Collapse;

export default class CCPADnsaProperty extends React.PureComponent {
  state = {
    recentChangesModal: false,
    lineToggle: Map({
      totalpageviews: true,
      pvoptin: true,
      pvoptout: false
    }),
    rechartMargin: { top: 5, right: 30, left: 20, bottom: 15 },
    tooltipVisibility: "visible",
  };

  toggleLine = tKey => {
    let toggles = this.state.lineToggle;
    const inverse = !toggles.get(tKey);
    toggles = toggles.set(tKey, inverse);
    this.setState({ lineToggle: toggles });
  };

  setGraphType = graphType => {
    this.setState({ graphType });
  };

  showModal = () => {
    this.setState({
      recentChangesModal: true
    });
  };
  handleCancel = e => {
    this.setState({
      recentChangesModal: false
    });
  };
  capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  renderTooltip = props => {
    let propertySiteIds;
    let propertyDate;
    let logData;
    let tooltipData;
    if (props.payload && props.payload.length > 0 && props.payload[0]) {
      let arrList = [];
      props.payload.map((item, i) => {
        arrList = arrList.concat(item.payload["siteIds" + i]);
      });
      let uniqueArr = [...new Set(arrList)];
      propertySiteIds = uniqueArr;
      propertyDate = props.payload[0].payload.date;
      logData = this.props.changeLogData.filter((item, i) => {
        var common = item
          .get("siteIds")
          .filter(element => propertySiteIds.includes(element));
        return (
          item.get("date") ==
            moment(propertyDate, "MMM Do").format("YYYY-MM-DD") &&
          common.length > 0
        );
      });
      tooltipData = logData.get(0);
    }

    if (tooltipData) {
      return (
        <div className="tooltip-custom">
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Change Type : </div>
            <span>{this.capitalize(tooltipData.get("operationtype"))}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Date : </div>
            <span> {tooltipData.get("date")} </span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Entity Type : </div>
            <span>{tooltipData.get("entitytype")}</span>{" "}
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Entity : </div>
            <span>{tooltipData.get("entitynames")}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Properties: </div>
            <span>{tooltipData.get("properties")}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">User : </div>
            <span>{tooltipData.get("user")}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  showTooltip = () => {
    return (
      <Tooltip
        content={this.renderTooltip}
        filterNull={true}
        active={false}
        cursor={false}
      />
    );
  };

  drawGraph = graphData => {
    let lineArr = [];
    graphData.map((d, j) => {
      if (this.state.lineToggle.get("totalpageviews")) {
        lineArr.push(
          <Line
            key={"t-" + j}
            dot={false}
            yAxisId={
              this.state.lineToggle.get("pvoptin") ||
              this.state.lineToggle.get("pvoptout")
                ? "right"
                : "left"
            }
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"totalpageviews" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(0)}
          />
        );
      }
      if (this.state.lineToggle.get("pvoptin")) {
        lineArr.push(
          <Line
            key={"a-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"pvoptin" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(1)}
          />
        );
      }
      if (this.state.lineToggle.get("pvoptout")) {
        lineArr.push(
          <Line
            key={"s-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"pvoptout" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(2)}
          />
        );
      }
    });
    return lineArr;
  };

  dataFormater = number => {
    return number + "%";
  };

  render() {
    var tableData = this.props.ccpaDnsaData.map((items, i) =>
      items.get("propertyTable")
    );
    var graphData = this.props.ccpaDnsaData.map((items, i) =>
      items.get("propertyGraph")
    );

    let graphList = List();
    let unionList = List();
    graphData.map((g, i) => {
      unionList = unionList
        .toSet()
        .union(g.toSet())
        .toList();
    });

    let groupedByDates = unionList.groupBy(dataPoint => dataPoint.get("date"));
    groupedByDates.map((item, i) => {
      let mergeMap = Map();
      item.map(m => {
        mergeMap = mergeMap.merge(m);
      });
      graphList = graphList.push(mergeMap);
    });
    graphList = graphList.sort(
      (a, b) =>
        moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
    );

    let graphTitle = graphList.size
      ? moment(graphList.first().get("date"), "YYYY-MM-DD").format("MMMM YYYY")
      : "";
    const renderLegend = () => {
      return (
        <React.Fragment>
          {graphData.map((entry, index) => (
            <div className="legend-custom" key={`legend-${index}`}>
              <svg height="5" width="28">
                <line
                  x1="0"
                  y1="0"
                  x2="200"
                  y2="0"
                  style={{
                    stroke: "#555758",
                    strokeWidth: "5",
                    strokeDasharray: LINE_GRAPH_STROKE_PATTERN_LIST.get(index)
                  }}
                />
              </svg>
              {` Criteria ${index + 1}`}
            </div>
          ))}
        </React.Fragment>
      );
    };

    const columns = List([
      Map({
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) =>
          moment(a.date, "YYYY-MM-DD").valueOf() -
          moment(b.date, "YYYY-MM-DD").valueOf(),
        className: "left-align",
      }),
      Map({
        title: "Total Page Views",
        dataIndex: "totalpageviews",
        sorter: (a, b) => a.totalpageviews - b.totalpageviews,
        className: "right-align",
        render: (text) => text.toLocaleString(),
      }),
      Map({
        title: "Opted In",
        dataIndex: "pvoptin",
        sorter: (a, b) => a.pvoptin - b.pvoptin,
        className: "right-align",
        render: (text, record) => (
          <div className="consent-table-metrics">
            <span className="tbl-metric-per">{record.pvoptinPer}%</span> <br />
            {text}
          </div>
        ),
      }),
      Map({
        title: "Opted Out",
        dataIndex: "pvoptout",
        sorter: (a, b) => a.pvoptout - b.pvoptout,
        className: "right-align",
        render: (text, record) => (
          <div className="consent-table-metrics">
            <span className="tbl-metric-per">{record.pvoptoutPer}%</span> <br />
            {text}
          </div>
        ),
      }),
    ]);
    const propertyHeaders = columns
      .map((item, i) => Map({ label: item.get("title"), key: item.get("dataIndex") }))
      .insert(3, Map({ label: "Opted In %", key: "pvoptinPer" }))
      .insert(5, Map({ label: "Opted Out %", key: "pvoptoutPer"}))
    
    const metrics = List([
      {'title': "Total Page Views", dataIndex: "totalpageviews", metricPerVal: mapReduceSum(tableData, 'totalpageviews'), metricAbsVal: ''},
      {'title': "Opted In", dataIndex: 'pvoptin', metricPerVal: mapReduceAvg(tableData, 'pvoptinPer'), metricAbsVal: mapReduceSum(tableData, 'pvoptin')},
      {'title': "Opted Out", dataIndex: 'pvoptout', metricPerVal: mapReduceAvg(tableData, 'pvoptoutPer'), metricAbsVal: mapReduceSum(tableData, 'pvoptout')},
     
    ]);
    return (
      <React.Fragment>
        <div className="property-tabs">
          {metrics.map((mt, index) =>
            <div
              key={index}
              className={`metrices-toggle-wrapper ${this.state.lineToggle.get(mt.dataIndex)? `toggle-line${index+1}-active`: `toggle-line${index+1}`}`}
              onClick={() => this.toggleLine(mt.dataIndex)}
            >
              <div className="metric-label" style={this.state.lineToggle.get(mt.dataIndex) ? { fontWeight: 600} : null}>
                {mt.title}
                {mt.titleNote ? <React.Fragment><br/><span className="metric-label-note">{mt.titleNote}</span></React.Fragment> : null}
              </div>
              <div className="metric-total">
                <span className="metric-per">{mt.metricPerVal}</span><br/>
                {mt.metricAbsVal ? <span className="metric-abs">{mt.metricAbsVal}</span> : <span className="metric-abs">&nbsp;</span>}
              </div>
            </div>)}
          <div className="property-action">
            <a onClick={this.showModal}>
              View Recent Changes{" "}
              <span className="glyphicon glyphicon-menu-right consent-right-arrow"></span>
            </a>
          </div>
        </div>
        <div className="property-title-container">
          <div className="graph-desc-container">
            <span className="property-title">DO NOT SELL ACTIONS</span>
            <br />
            <span className="property-desc">
              Trend over time, can be viewed on monthly, weekly or quarterly
              basis.
            </span>
          </div>
        </div>

        <ResponsiveContainer width="99%" height={395}>
          <LineChart
            width={730}
            height={250}
            data={graphList.toJS()}
            margin={this.state.rechartMargin}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="fancyDate">
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              type="number"
              yAxisId="left"
              domain={[0, "dataMax"]}
              tickFormatter={
                this.state.lineToggle.get("pvoptin") ||
                this.state.lineToggle.get("pvoptout")
                  ? this.dataFormater
                  : null
              }
            />
            <YAxis
              type="number"
              yAxisId="right"
              orientation="right"
              domain={[0, "dataMax"]}
            />
            {this.showTooltip()}
            {/* <ReferenceLine x={moment().format('MMM Do')} stroke="#000" isFront={true} label="Today" strokeDasharray="1 1" /> */}
            <Legend verticalAlign="top" content={renderLegend} />
            {this.drawGraph(graphData)}
          </LineChart>
        </ResponsiveContainer>

        <div className="collapsibleTable">
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            {tableData.map((item, i) => {
              let data = item.get("aggData").toJS()
              const headerCustom = (
                <span>
                  {item.get("date")}
                  <ExportToolTip
                    filename="property"
                    selectedTabIndex={i + 1}
                    exportData={createCSVExportData(
                      propertyHeaders,
                      item.get("aggData")
                    ).toJS()}
                    criterialist={this.props.criteriaList}
                    sites={this.props.sites}
                    pdfTitle={""}
                  />
                </span>
              );
              return (
                <Panel header={headerCustom} key={i + 1}>
                  <Table
                    columns={columns.toJS()}
                    dataSource={data}
                    pagination={{
                      position: ['bottomCenter'],
                      showTotal: (total) => `Total: ${total}`,
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                      locale: { items_per_page: " Records per page" }
                    }}
                    className="consnet-tableCSS"
                    rowKey={r => r.key}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>

        <RecentChangesModal
          changeLogData={this.props.changeLogData}
          recentChangesModal={this.state.recentChangesModal}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    );
  }
}
