export const assembleSingleRow = (step) => {
  let row = [];
  try {
    if (step === null) return;
    if (Array.isArray(step)) {
      step.forEach(singleStep => {
        row = row.concat(assembleSingleRow(singleStep));
      });
    }
    else if (step.class.includes('IfStatement')) {
      row = row.concat(step.data.test);
      row = row.concat(assembleSingleRow(step.data.then_seq.steps));
    }
    else {
      row.push(step);
    }
  }
  catch(error) {
    console.log('error assembling rows: ',error)
  }
  return row;
};

export const assembleAllRows = (allSteps) => {
  const completedRows = [];
  allSteps.forEach(step => {
    const singleRow = assembleSingleRow(step);
    completedRows.push(singleRow);
  });

  return completedRows;
};

export const serializeRows = (steps) => {
  const ObjectToBackend = { steps : [] };

  steps.forEach(row => {
    let accumulatedSteps = [];

    row.rowData.reverse().forEach(block => {
      block = block.toJS();
      if (block.category === 'message') {
        accumulatedSteps.unshift(block.toBackendStep());
      }
      else {
        accumulatedSteps = [{
          class: 'com.sourcepoint.msgsvc.scenario.IfStatement',
          data: {
            test: block.toBackendStep(),
            then_seq: {
              steps: accumulatedSteps,
            },
          },
        }];
      }
    });

    accumulatedSteps.forEach(step => {
      ObjectToBackend.steps.push(step);
    });
  });

  return JSON.stringify(ObjectToBackend);
};
