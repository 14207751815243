import React, { Component } from 'react';
import { FilterFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import { Button, Checkbox} from "../../../../styleguide";
const CheckboxGroup = Checkbox.Group;


 const CustomFilter = (props) => {
 
 const [checkedList, setCheckedList] = React.useState(props.defaultCheckedList);
 const [indeterminate, setIndeterminate] = React.useState(true);
 const [checkAll, setCheckAll] = React.useState(false);
 const [visible, setVisible] = React.useState(false)

  const onApplyChanges = () => {
    props.filterCondition(checkedList);
    setVisible(false)
  };

  const onChange = list => {
    let options = props.options.map(o => o.value)
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = e => {
    let options = props.options.map(o => o.value)
    setCheckedList(e.target.checked ? options : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible)
  }

  const onCancel = () => {
    setVisible(false)
  }

  const content = (
    <div className="popover-content-container">
      <Checkbox 
      indeterminate={indeterminate} 
      onChange={onCheckAllChange} 
      checked={checkAll}>
        Select All
      </Checkbox>
      <CheckboxGroup
        options={props.options}
        value={checkedList}
        onChange={onChange}
      />
      <div className="btnContainer">
        <Button type='secondary' size='small' onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={onApplyChanges}>
          Apply
        </Button>
      </div>
    </div>
  );


  return (
      <Popover
        overlayClassName="filter-popup-new"
        content={content}
        open={visible}
        onOpenChange={handleVisibleChange}
        title={
          <div className="titleContainer">
            <div className="title1">Filter</div>         
          </div>
        }
        placement="bottom"
      >
        <FilterFilled
          style={{ float: 'right', margin: '20px' }}
          className="icon-style"
        />
      </Popover>
    );
  }
  export default CustomFilter