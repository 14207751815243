import {
  addVendorManagementActivities,
  exportChatbotHarFile,
  exportCookielessHarFile,
  exportCookilessHarFile,
  exportCsvAllCookiesFoundData,
  exportCsvCookieDetailsData,
  exportCsvCookiesWithLongLifespansData,
  exportCsvDataLeavingEeaData,
  exportCsvDisclosedVendorsData,
  exportCsvPossibleFingerprintingData,
  exportCsvVendorDetailsData,
  exportCsvVendorNotOnVendorListData,
  exportCsvVendorsPriorToConsentData,
  exportPixelsHarFile,
  exportSessionReplayHarFile,
  getAllCookiesFoundData,
  getAllCookiesFoundListFiltersData,
  getCookieDetailsData,
  getCookieDetailsListFiltersData,
  getCookiesWithLongLifespansData,
  getCookiesWithLongLifespansFiltersData,
  getDataLeavingEeaData,
  getDataLeavingEeaFiltersData,
  getDisclosedVendorsData,
  getDisclosedVendorsFilters,
  getPossibleFingerprintingData,
  getPossibleFingerprintingFiltersData,
  getPropertiesData,

  getSummaryData,
  getTrackingTechDetectedData,
  getTrackingTechDetectedFiltersData,
  getTrackingTechDetectedListData,
  getVendorDetailsData,
  getVendorDetailsForChatbotsTech,
  getVendorDetailsForCookielessTech,
  getVendorDetailsForPixelsParams,
  getVendorDetailsForPixelsTech,
  getVendorDetailsForSessionReplaysTech,
  getVendorManagementStatuses,
  getVendorWebsiteDetailsData,
  getVendorsNotOnVendorListData,
  getVendorsNotOnVendorListFilters,
  getVendorsPriorToConsentFilters,
  getVendorsPriorToConsentListData,
} from '../api/diagnose_dashboard/diagnose_dashboard';
import {
  getVendorAfterOptOutVendorData as getVendorAfterOptOutVendorDataRequest,
  getVendorAfterOptOutVendorWebsite as getVendorAfterOptOutVendorWebsiteRequest,
  getVendorsAfterOptOutCookiesFilters as getVendorsAfterOptOutCookiesFiltersRequest,
  getVendorsAfterOptOutCookiesData as getVendorsAfterOptOutCookiesDataRequest,
  getVendorsAfterOptOutData,
  getVendorsAfterOptOutFilters,
  exportCSVVendorsAfterOptOut as exportCSVVendorsAfterOptOutRequest,
  exportCSVVendorsAfterOptOutVendorWebsite as exportCSVVendorsAfterOptOutVendorRequest,
  exportCSVVendorsAfterOptOutCookies as exportCSVVendorsAfterOptOutCookiesRequest,
} from '../api/diagnose_dashboard/vendor_after_opt_out';

import * as types from './action_types';
import { actionCreator } from './helpers';

const propertiesPending = () => ({
  type: types.PROPERTIES_PENDING,
});

const propertiesLoaded = (data) => ({
  type: types.PROPERTIES_LOADED,
  data,
});

const propertiesError = (errorMessage) => ({
  type: types.PROPERTIES_ERROR,
  errorMessage,
});

export const getProperties = (spClientId) => {
  return (dispatch) => {
    dispatch(propertiesPending());
    return getPropertiesData(data)
      .then((data) => {
        dispatch(propertiesLoaded(data));
      })
      .catch((reason) => {
        return dispatch(propertiesError(reason));
      });
  };
};

const summaryPending = () => ({
  type: types.SUMMARY_PENDING,
});

export const summaryLoaded = (summaryDiagnose) => ({
  type: types.SUMMARY_LOADED,
  summaryDiagnose,
});

const summaryError = (errorMessage) => ({
  type: types.SUMMARY_ERROR,
  errorMessage,
});

export const getDiagnoseSummary = actionCreator(
    getSummaryData,
    summaryPending,
    summaryLoaded,
    summaryError,
);

const vendorsNotOnVendorListPending = () => ({
  type: types.VENDORS_NOT_ON_VENDORS_LIST_PENDING,
});

const vendorsNotOnVendorListLoaded = (vendorsNotOnVendorList) => ({
  type: types.VENDORS_NOT_ON_VENDORS_LIST_LOADED,
  vendorsNotOnVendorList,
});

const vendorsNotOnVendorListError = (errorMessage) => ({
  type: types.VENDORS_NOT_ON_VENDORS_LIST_ERROR,
  errorMessage,
});

export const getVendorsNotOnVendorList = actionCreator(
    getVendorsNotOnVendorListData,
    vendorsNotOnVendorListPending,
    vendorsNotOnVendorListLoaded,
    vendorsNotOnVendorListError,
);

const vendorsNotOnVendorExportPending = () => ({
  type: types.VENDORS_NOT_ON_VENDOR_EXPORT_PENDING,
});

const vendorsNotOnVendorExportLoaded = (vendorsNotOnVendorExport) => ({
  type: types.VENDORS_NOT_ON_VENDOR_EXPORT_LOADED,
  vendorsNotOnVendorExport,
});

const vendorsNotOnVendorExportError = (errorMessage) => ({
  type: types.VENDORS_NOT_ON_VENDOR_EXPORT_ERROR,
  errorMessage,
});

export const vendorsNotOnVendorExport = actionCreator(
    exportCsvVendorNotOnVendorListData,
    vendorsNotOnVendorExportPending,
    vendorsNotOnVendorExportLoaded,
    vendorsNotOnVendorExportError,
);

const disclosedVendorsPending = () => ({
  type: types.DISCLOSED_VENDORS_PENDING,
});

const disclosedVendorsLoaded = (disclosedVendors) => ({
  type: types.DISCLOSED_VENDORS_LOADED,
  disclosedVendors,
});

const disclosedVendorsError = (errorMessage) => ({
  type: types.DISCLOSED_VENDORS_ERROR,
  errorMessage,
});

export const getDisclosedVendors = actionCreator(
    getDisclosedVendorsData,
    disclosedVendorsPending,
    disclosedVendorsLoaded,
    disclosedVendorsError,
);

const disclosedVendorsFilterPending = () => ({
  type: types.DISCLOSED_VENDORS_FILTERS_PENDING,
});

const disclosedVendorsFilterLoaded = (disclosedVendorsFilter) => ({
  type: types.DISCLOSED_VENDORS_FILTERS_LOADED,
  disclosedVendorsFilter,
});

const disclosedVendorsFilterError = (errorMessage) => ({
  type: types.DISCLOSED_VENDORS_FILTERS_ERROR,
  errorMessage,
});

export const getDisclosedVendorsFilter = actionCreator(
    getDisclosedVendorsFilters,
    disclosedVendorsFilterPending,
    disclosedVendorsFilterLoaded,
    disclosedVendorsFilterError,
);

const disclosedVendorsExportPending = () => ({
  type: types.DISCLOSED_VENDORS_EXPORT_PENDING,
});

const disclosedVendorsExportLoaded = (disclosedVendorsExport) => ({
  type: types.DISCLOSED_VENDORS_EXPORT_LOADED,
  disclosedVendorsExport,
});

const disclosedVendorsExportError = (errorMessage) => ({
  type: types.DISCLOSED_VENDORS_EXPORT_ERROR,
  errorMessage,
});

export const disclosedVendorsExport = actionCreator(
    exportCsvDisclosedVendorsData,
    disclosedVendorsExportPending,
    disclosedVendorsExportLoaded,
    disclosedVendorsExportError,
);

const vendorsPriorToConsentListPending = () => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_LIST_PENDING,
});

const vendorsPriorToConsentListLoaded = (vendorsPriorToConsentList) => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_LIST_LOADED,
  vendorsPriorToConsentList,
});

const vendorsPriorToConsentListError = (errorMessage) => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_LIST_ERROR,
  errorMessage,
});

export const getVendorsPriorToConsentList = actionCreator(
    getVendorsPriorToConsentListData,
    vendorsPriorToConsentListPending,
    vendorsPriorToConsentListLoaded,
    vendorsPriorToConsentListError,
);

const vendorsPriorToConsentExportPending = () => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_EXPORT_PENDING,
});

const vendorsPriorToConsentExportLoaded = (vendorsPriorToConsentExport) => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_EXPORT_LOADED,
  vendorsPriorToConsentExport,
});

const vendorsPriorToConsentExportError = (errorMessage) => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_EXPORT_ERROR,
  errorMessage,
});

export const vendorsPriorToConsentExport = actionCreator(
    exportCsvVendorsPriorToConsentData,
    vendorsPriorToConsentExportPending,
    vendorsPriorToConsentExportLoaded,
    vendorsPriorToConsentExportError,
);

const vendorsPriorToConsentFilterPending = () => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_FILTERS_PENDING,
});

const vendorsPriorToConsentFilterLoaded = (vendorsPriorToConsentFilter) => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_FILTERS_LOADED,
  vendorsPriorToConsentFilter,
});

const vendorsPriorToConsentFilterError = (errorMessage) => ({
  type: types.VENDORS_PRIOR_TO_CONSENT_FILTERS_ERROR,
  errorMessage,
});

export const getVendorsPriorToConsentFilter = actionCreator(
    getVendorsPriorToConsentFilters,
    vendorsPriorToConsentFilterPending,
    vendorsPriorToConsentFilterLoaded,
    vendorsPriorToConsentFilterError,
);

const vendorsNotOnVendorFilterPending = () => ({
  type: types.VENDORS_NOT_ON_VENDORS_FILTERS_PENDING,
});

const vendorsNotOnVendorFilterLoaded = (vendorsNotOnVendorFilter) => ({
  type: types.VENDORS_NOT_ON_VENDORS_FILTERS_LOADED,
  vendorsNotOnVendorFilter,
});

const vendorsNotOnVendorFilterError = (errorMessage) => ({
  type: types.VENDORS_NOT_ON_VENDORS_FILTERS_ERROR,
  errorMessage,
});

export const getVendorsNotOnVendorFilter = actionCreator(
    getVendorsNotOnVendorListFilters,
    vendorsNotOnVendorFilterPending,
    vendorsNotOnVendorFilterLoaded,
    vendorsNotOnVendorFilterError,
);

const possibleFingerprintingListPending = () => ({
  type: types.POSSIBLE_FINGERPRINTING_LIST_PENDING,
});

const possibleFingerprintingListLoaded = (possibleFingerprintingList) => ({
  type: types.POSSIBLE_FINGERPRINTING_LIST_LOADED,
  possibleFingerprintingList,
});

const possibleFingerprintingListError = (errorMessage) => ({
  type: types.POSSIBLE_FINGERPRINTING_LIST_ERROR,
  errorMessage,
});

export const getPossibleFingerprintingList = actionCreator(
    getPossibleFingerprintingData,
    possibleFingerprintingListPending,
    possibleFingerprintingListLoaded,
    possibleFingerprintingListError,
);

const possibleFingerprintingFilterPending = () => ({
  type: types.POSSIBLE_FINGERPRINTING_FILTERS_PENDING,
});

const possibleFingerprintingFilterLoaded = (possibleFingerprintingFilter) => ({
  type: types.POSSIBLE_FINGERPRINTING_FILTERS_LOADED,
  possibleFingerprintingFilter,
});

const possibleFingerprintingFilterError = (errorMessage) => ({
  type: types.POSSIBLE_FINGERPRINTING_FILTERS_ERROR,
  errorMessage,
});

export const getPossibleFingerprintingFilters = actionCreator(
    getPossibleFingerprintingFiltersData,
    possibleFingerprintingFilterPending,
    possibleFingerprintingFilterLoaded,
    possibleFingerprintingFilterError,
);

const possibleFingerprintingExportPending = () => ({
  type: types.POSSIBLE_FINGERPRINTING_EXPORT_PENDING,
});

const possibleFingerprintingExportLoaded = (possibleFingerprintingExport) => ({
  type: types.POSSIBLE_FINGERPRINTING_EXPORT_LOADED,
  possibleFingerprintingExport,
});

const possibleFingerprintingExportError = (errorMessage) => ({
  type: types.POSSIBLE_FINGERPRINTING_EXPORT_ERROR,
  errorMessage,
});

export const possibleFingerprintingExport = actionCreator(
    exportCsvPossibleFingerprintingData,
    possibleFingerprintingExportPending,
    possibleFingerprintingExportLoaded,
    possibleFingerprintingExportError,
);

export function setMetricsList(selectedMetrics) {
  return {
    type: types.SET_METRICS_LIST,
    selectedMetrics,
  };
}

export function setSelectedFilters(selectedFilters) {
  return {
    type: types.SET_SELECTED_FILTERS,
    selectedFilters,
  };
}

export function setRegionsList(regionsList) {
  return {
    type: types.SET_REGIONS_LIST,
    regionsList,
  };
}

const dataLeavingEeaListPending = () => ({
  type: types.DATA_LEAVING_EEA_LIST_PENDING,
});

const dataLeavingEeaListLoaded = (dataLeavingEeaList) => ({
  type: types.DATA_LEAVING_EEA_LIST_LOADED,
  dataLeavingEeaList,
});

const dataLeavingEeaListError = (errorMessage) => ({
  type: types.DATA_LEAVING_EEA_LIST_ERROR,
  errorMessage,
});

export const getDataLeavingEeaList = actionCreator(
    getDataLeavingEeaData,
    dataLeavingEeaListPending,
    dataLeavingEeaListLoaded,
    dataLeavingEeaListError,
);

const dataLeavingEeaFilterPending = () => ({
  type: types.DATA_LEAVING_EEA_FILTERS_PENDING,
});

const dataLeavingEeaFilterLoaded = (dataLeavingEeaFilter) => ({
  type: types.DATA_LEAVING_EEA_FILTERS_LOADED,
  dataLeavingEeaFilter,
});

const dataLeavingEeaFilterError = (errorMessage) => ({
  type: types.DATA_LEAVING_EEA_FILTERS_ERROR,
  errorMessage,
});

export const getDataLeavingEeaFilters = actionCreator(
    getDataLeavingEeaFiltersData,
    dataLeavingEeaFilterPending,
    dataLeavingEeaFilterLoaded,
    dataLeavingEeaFilterError,
);

const dataLeavingEeaExportPending = () => ({
  type: types.DATA_LEAVING_EEA_EXPORT_PENDING,
});

const dataLeavingEeaExportLoaded = (dataLeavingEeaExport) => ({
  type: types.DATA_LEAVING_EEA_EXPORT_LOADED,
  dataLeavingEeaExport,
});

const dataLeavingEeaExportError = (errorMessage) => ({
  type: types.DATA_LEAVING_EEA_EXPORT_ERROR,
  errorMessage,
});

export const dataLeavingEeaExport = actionCreator(
    exportCsvDataLeavingEeaData,
    dataLeavingEeaExportPending,
    dataLeavingEeaExportLoaded,
    dataLeavingEeaExportError,
);

const cookiesWithLongLifespansListPending = () => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_LIST_PENDING,
});

const cookiesWithLongLifespansListLoaded = (cookiesWithLongLifespansList) => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_LIST_LOADED,
  cookiesWithLongLifespansList,
});

const cookiesWithLongLifespansListError = (errorMessage) => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_LIST_ERROR,
  errorMessage,
});

export const getCookiesWithLongLifespansList = actionCreator(
    getCookiesWithLongLifespansData,
    cookiesWithLongLifespansListPending,
    cookiesWithLongLifespansListLoaded,
    cookiesWithLongLifespansListError,
);

const cookiesWithLongLifespansFilterPending = () => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_FILTERS_PENDING,
});

const cookiesWithLongLifespansFilterLoaded = (cookiesWithLongLifespansFilter) => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_FILTERS_LOADED,
  cookiesWithLongLifespansFilter,
});

const cookiesWithLongLifespansFilterError = (errorMessage) => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_FILTERS_ERROR,
  errorMessage,
});

export const getCookiesWithLongLifespansFilters = actionCreator(
    getCookiesWithLongLifespansFiltersData,
    cookiesWithLongLifespansFilterPending,
    cookiesWithLongLifespansFilterLoaded,
    cookiesWithLongLifespansFilterError,
);

const cookiesWithLongLifespansExportPending = () => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_EXPORT_PENDING,
});

const cookiesWithLongLifespansExportLoaded = (cookiesWithLongLifespansExport) => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_EXPORT_LOADED,
  cookiesWithLongLifespansExport,
});

const cookiesWithLongLifespansExportError = (errorMessage) => ({
  type: types.COOKIES_WITH_LONG_LIFESPANS_EXPORT_ERROR,
  errorMessage,
});

export const cookiesWithLongLifespansExport = actionCreator(
    exportCsvCookiesWithLongLifespansData,
    cookiesWithLongLifespansExportPending,
    cookiesWithLongLifespansExportLoaded,
    cookiesWithLongLifespansExportError,
);

const allCookiesFoundListPending = () => ({
  type: types.ALL_COOKIES_FOUND_LIST_PENDING,
});

const allCookiesFoundListLoaded = (allCookiesFoundList) => ({
  type: types.ALL_COOKIES_FOUND_LIST_LOADED,
  allCookiesFoundList,
});

const allCookiesFoundListError = (errorMessage) => ({
  type: types.ALL_COOKIES_FOUND_LIST_ERROR,
  errorMessage,
});

export const getAllCookiesFoundList = actionCreator(
    getAllCookiesFoundData,
    allCookiesFoundListPending,
    allCookiesFoundListLoaded,
    allCookiesFoundListError,
);

const allCookiesFoundFilterPending = () => ({
  type: types.ALL_COOKIES_FOUND_FILTERS_PENDING,
});

const allCookiesFoundFilterLoaded = (allCookiesFoundFilter) => ({
  type: types.ALL_COOKIES_FOUND_FILTERS_LOADED,
  allCookiesFoundFilter,
});

const allCookiesFoundFilterError = (errorMessage) => ({
  type: types.ALL_COOKIES_FOUND_FILTERS_ERROR,
  errorMessage,
});

export const getAllCookiesFoundFilters = actionCreator(
    getAllCookiesFoundListFiltersData,
    allCookiesFoundFilterPending,
    allCookiesFoundFilterLoaded,
    allCookiesFoundFilterError,
);

const allCookiesFoundExportPending = () => ({
  type: types.ALL_COOKIES_FOUND_EXPORT_PENDING,
});

const allCookiesFoundExportLoaded = (allCookiesFoundExport) => ({
  type: types.ALL_COOKIES_FOUND_EXPORT_LOADED,
  allCookiesFoundExport,
});

const allCookiesFoundExportError = (errorMessage) => ({
  type: types.ALL_COOKIES_FOUND_EXPORT_ERROR,
  errorMessage,
});

export const allCookiesFoundExport = actionCreator(
    exportCsvAllCookiesFoundData,
    allCookiesFoundExportPending,
    allCookiesFoundExportLoaded,
    allCookiesFoundExportError,
);

const vendorDetailsPending = () => ({
  type: types.VENDORS_DETAILS_PENDING,
});

const vendorDetailsLoaded = (vendorDetails) => ({
  type: types.VENDORS_DETAILS_LOADED,
  vendorDetails,
});

const vendorDetailsError = (errorMessage) => ({
  type: types.VENDORS_DETAILS_ERROR,
  errorMessage,
});

export const getVendorDetails = actionCreator(
    getVendorDetailsData,
    vendorDetailsPending,
    vendorDetailsLoaded,
    vendorDetailsError,
);

export const vendorDetailsClear = () => ({
  type: types.VENDORS_DETAILS_CLEAR,
});

const vendorWebsiteDetailsPending = () => ({
  type: types.VENDORS_WEBSITE_DETAILS_PENDING,
});

const vendorWebsiteDetailsLoaded = (vendorWebsiteDetails) => ({
  type: types.VENDORS_WEBSITE_DETAILS_LOADED,
  vendorWebsiteDetails,
});

const vendorWebsiteDetailsError = (errorMessage) => ({
  type: types.VENDORS_WEBSITE_DETAILS_ERROR,
  errorMessage,
});

export const getVendorWebsiteDetails = actionCreator(
    getVendorWebsiteDetailsData,
    vendorWebsiteDetailsPending,
    vendorWebsiteDetailsLoaded,
    vendorWebsiteDetailsError,
);

export const vendorWebsiteDetailsClear = () => ({
  type: types.VENDORS_WEBSITE_DETAILS_CLEAR,
});

const vendorDetailsExportPending = () => ({
  type: types.VENDOR_DETAILS_EXPORT_PENDING,
});

const vendorDetailsExportLoaded = (vendorDetailsExport) => ({
  type: types.VENDOR_DETAILS_EXPORT_LOADED,
  vendorDetailsExport,
});

const vendorDetailsExportError = (errorMessage) => ({
  type: types.VENDOR_DETAILS_EXPORT_ERROR,
  errorMessage,
});

export const vendorDetailsExport = actionCreator(
    exportCsvVendorDetailsData,
    vendorDetailsExportPending,
    vendorDetailsExportLoaded,
    vendorDetailsExportError,
);

const cookieDetailsListPending = () => ({
  type: types.COOKIE_DETAILS_LIST_PENDING,
});

const cookieDetailsListLoaded = (cookieDetailsList) => ({
  type: types.COOKIE_DETAILS_LIST_LOADED,
  cookieDetailsList,
});

const cookieDetailsListError = (errorMessage) => ({
  type: types.COOKIE_DETAILS_LIST_ERROR,
  errorMessage,
});

export const getCookieDetailsList = actionCreator(
    getCookieDetailsData,
    cookieDetailsListPending,
    cookieDetailsListLoaded,
    cookieDetailsListError,
);

const cookieDetailsListFilterPending = () => ({
  type: types.COOKIE_DETAILS_LIST_FILTERS_PENDING,
});

const cookieDetailsListFilterLoaded = (cookieDetailsListFilter) => ({
  type: types.COOKIE_DETAILS_LIST_FILTERS_LOADED,
  cookieDetailsListFilter,
});

const cookieDetailsListFilterError = (errorMessage) => ({
  type: types.COOKIE_DETAILS_LIST_FILTERS_ERROR,
  errorMessage,
});

export const getCookieDetailsListFilters = actionCreator(
    getCookieDetailsListFiltersData,
    cookieDetailsListFilterPending,
    cookieDetailsListFilterLoaded,
    cookieDetailsListFilterError,
);

const cookieDetailsExportPending = () => ({
  type: types.COOKIE_DETAILS_EXPORT_PENDING,
});

const cookieDetailsExportLoaded = (cookieDetailsExport) => ({
  type: types.COOKIE_DETAILS_EXPORT_LOADED,
  cookieDetailsExport,
});

const cookieDetailsExportError = (errorMessage) => ({
  type: types.COOKIE_DETAILS_EXPORT_ERROR,
  errorMessage,
});

export const cookieDetailsExport = actionCreator(
    exportCsvCookieDetailsData,
    cookieDetailsExportPending,
    cookieDetailsExportLoaded,
    cookieDetailsExportError,
);

export function setWebsitePropertiesListFilters(websitePropertiesList) {
  return {
    type: types.SET_WEBSITE_PROPERTIES_LIST,
    websitePropertiesList,
  };
}

export function setVendorStatusSelectedPropertyList(vendorStatusSelectedPropertyList) {
  return {
    type: types.SET_VENDOR_STATUS_SELECTED_PROPERTY_LIST,
    vendorStatusSelectedPropertyList,
  };
}

const vendorManagementStatusesPending = () => ({
  type: types.VENDOR_MANAGEMENT_STATUSES_PENDING,
});

const vendorManagementStatusesLoaded = (vendorManagementStatuses, query) => ({
  type: types.VENDOR_MANAGEMENT_STATUSES_LOADED,
  vendorManagementStatuses,
  query,
});

const vendorManagementStatusesError = (errorMessage) => ({
  type: types.VENDOR_MANAGEMENT_STATUSES_ERROR,
  errorMessage,
});

export const getVendorManagementStatusesList = actionCreator(
    getVendorManagementStatuses,
    vendorManagementStatusesPending,
    vendorManagementStatusesLoaded,
    vendorManagementStatusesError,
);

const addVendorManagementActivitiesPending = () => ({
  type: types.ADD_VENDOR_MANAGEMENT_ACTIVITIES_PENDING,
});

const addVendorManagementActivitiesLoaded = (vendorManagementActivities) => ({
  type: types.ADD_VENDOR_MANAGEMENT_ACTIVITIES_LOADED,
  vendorManagementActivities,
});

const addVendorManagementActivitiesError = (errorMessage) => ({
  type: types.ADD_VENDOR_MANAGEMENT_ACTIVITIES_ERROR,
  errorMessage,
});

export const addVendorManagementActivitiesList = actionCreator(
    addVendorManagementActivities,
    addVendorManagementActivitiesPending,
    addVendorManagementActivitiesLoaded,
    addVendorManagementActivitiesError,
);

export const vendorManagementActivitiesListClear = () => ({
  type: types.VENDOR_MANAGEMENT_ACTIVITIES_LIST_CLEAR,
});

export function setIsVendorStatusMultiselectOperationActive(isVendorStatusMultiselectOperationActive) {
  return {
    type: types.SET_IS_VENDOR_STATUS_MULTISELECT_OPERATION_ACTIVE,
    isVendorStatusMultiselectOperationActive,
  };
}

export function getVendorManagementActivitiesList(vendorManagementActivitiesList) {
  return {
    type: types.GET_VENDOR_MANAGEMENT_ACTIVITIES_LIST,
    vendorManagementActivitiesList,
  };
}

export function vendorManagementActivitiesListPending(getVendorManagementActivitiesListPending) {
  return {
    type: types.VENDOR_MANAGEMENT_ACTIVITIES_LIST_PENDING,
    getVendorManagementActivitiesListPending,
  };
}

export const getVendorManagementActivitiesListClear = () => ({
  type: types.GET_VENDOR_MANAGEMENT_ACTIVITIES_LIST_CLEAR,
});

export function setVendorStatusFilterList(vendorStatusFilterList) {
  return {
    type: types.SET_VENDOR_STATUS_FILTER_LIST,
    vendorStatusFilterList,
  };
}

export function setAvailableMetrics(metricsList) {
  return {
    type: types.SET_AVAILABLE_METRICS,
    metricsList,
  };
}

const vendorsAfterOptOutPending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_PENDING,
});

const vendorsAfterOptOutLoaded = (vendorsAfterOptOut) => ({
  type: types.VENDORS_AFTER_OPT_OUT_LOADED,
  vendorsAfterOptOut,
});

const vendorsAfterOptOutError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_ERROR,
  errorMessage,
});

export const getVendorsAfterOptOut = actionCreator(
    getVendorsAfterOptOutData,
    vendorsAfterOptOutPending,
    vendorsAfterOptOutLoaded,
    vendorsAfterOptOutError,
);

const vendorsAfterOptOutFilterPending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_FILTERS_PENDING,
});

const vendorsAfterOptOutFilterLoaded = (vendorsAfterOptOutFilter) => ({
  type: types.VENDORS_AFTER_OPT_OUT_FILTERS_LOADED,
  vendorsAfterOptOutFilter,
});

const vendorsAfterOptOutFilterError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_FILTERS_ERROR,
  errorMessage,
});

export const getVendorsAfterOptOutFilter = actionCreator(
    getVendorsAfterOptOutFilters,
    vendorsAfterOptOutFilterPending,
    vendorsAfterOptOutFilterLoaded,
    vendorsAfterOptOutFilterError,
);

const vendorsAfterOptOutVendorDataPending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_VENDOR_DATA_PENDING,
});

const vendorsAfterOptOutVendorDataLoaded = (vendorsAfterOptOutVendorData) => ({
  type: types.VENDORS_AFTER_OPT_OUT_VENDOR_DATA_LOADED,
  vendorsAfterOptOutVendorData,
});

const vendorsAfterOptOutVendorDataError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_VENDOR_DATA_ERROR,
  errorMessage,
});

export const vendorsAfterOptOutVendorDataClear = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_VENDOR_DATA_CLEAR,
});

export const getVendorAfterOptOutVendorData = actionCreator(
  getVendorAfterOptOutVendorDataRequest,
  vendorsAfterOptOutVendorDataPending,
  vendorsAfterOptOutVendorDataLoaded,
  vendorsAfterOptOutVendorDataError,
);

const vendorsAfterOptOutVendorWebsitePending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_WEBSITES_PENDING,
});

const vendorsAfterOptOutVendorWebsiteLoaded = (vendorsAfterOptOutWebsites) => ({
  type: types.VENDORS_AFTER_OPT_OUT_WEBSITES_LOADED,
  vendorsAfterOptOutWebsites,
});

const vendorsAfterOptOutVendorWebsiteError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_WEBSITES_ERROR,
  errorMessage,
});

export const vendorsAfterOptOutVendorWebsiteClear = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_WEBSITES_CLEAR,
});

export const getVendorAfterOptOutVendorWebsite = actionCreator(
  getVendorAfterOptOutVendorWebsiteRequest,
  vendorsAfterOptOutVendorWebsitePending,
  vendorsAfterOptOutVendorWebsiteLoaded,
  vendorsAfterOptOutVendorWebsiteError,
);

const vendorsAfterOptOutCookiesDataPending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_PENDING,
});

const vendorsAfterOptOutCookiesDataLoaded = (vendorsAfterOptOutCookie) => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_LOADED,
  vendorsAfterOptOutCookie,
});

const vendorsAfterOptOutCookiesDataError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_ERROR,
  errorMessage,
});

export const getVendorsAfterOptOutCookiesData = actionCreator(
  getVendorsAfterOptOutCookiesDataRequest,
  vendorsAfterOptOutCookiesDataPending,
  vendorsAfterOptOutCookiesDataLoaded,
  vendorsAfterOptOutCookiesDataError,
);

const vendorsAfterOptOutCookiesFiltersPending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_PENDING,
});

const vendorsAfterOptOutCookiesFiltersLoaded = (vendorsAfterOptOutCookieFilter) => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_LOADED,
  vendorsAfterOptOutCookieFilter,
});

const vendorsAfterOptOutCookiesFiltersError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_ERROR,
  errorMessage,
});

export const getVendorsAfterOptOutCookiesFilters = actionCreator(
  getVendorsAfterOptOutCookiesFiltersRequest,
  vendorsAfterOptOutCookiesFiltersPending,
  vendorsAfterOptOutCookiesFiltersLoaded,
  vendorsAfterOptOutCookiesFiltersError,
);


const vendorsAfterOptOutExportPending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_EXPORT_PENDING,
});

const vendorsAfterOptOutExportLoaded = (vendorsAfterOptOutExport) => ({
  type: types.VENDORS_AFTER_OPT_OUT_EXPORT_LOADED,
  vendorsAfterOptOutExport,
});

const vendorsAfterOptOutExportError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_EXPORT_ERROR,
  errorMessage,
});

export const exportCSVVendorsAfterOptOut = actionCreator(
  exportCSVVendorsAfterOptOutRequest,
  vendorsAfterOptOutExportPending,
  vendorsAfterOptOutExportLoaded,
  vendorsAfterOptOutExportError,
);


const vendorsAfterOptOutWebsitesExportPending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_PENDING,
});

const vendorsAfterOptOutWebsitesExportLoaded = (vendorsAfterOptOutWebsitesExport) => ({
  type: types.VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_LOADED,
  vendorsAfterOptOutWebsitesExport,
});

const vendorsAfterOptOutWebsitesExportError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_ERROR,
  errorMessage,
});

export const exportCSVVendorsAfterOptOutVendor = actionCreator(
  exportCSVVendorsAfterOptOutVendorRequest,
  vendorsAfterOptOutWebsitesExportPending,
  vendorsAfterOptOutWebsitesExportLoaded,
  vendorsAfterOptOutWebsitesExportError,
);

const vendorsAfterOptOutCookiesExportPending = () => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_PENDING,
});

const vendorsAfterOptOutCookiesExportLoaded = (vendorsAfterOptOutCookieExport) => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_LOADED,
  vendorsAfterOptOutCookieExport,
});

const vendorsAfterOptOutCookiesExportError = (errorMessage) => ({
  type: types.VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_ERROR,
  errorMessage,
});

export const exportCSVVendorsAfterOptOutCookies = actionCreator(
  exportCSVVendorsAfterOptOutCookiesRequest,
  vendorsAfterOptOutCookiesExportPending,
  vendorsAfterOptOutCookiesExportLoaded,
  vendorsAfterOptOutCookiesExportError,
);


const trackingTechDetectedListPending = () => ({
  type: types.TRACKING_TECH_DETECTED_LIST_PENDING,
})

const trackingTechDetectedListLoaded = (trackingTechDetectedList) => ({
  type: types.TRACKING_TECH_DETECTED_LIST_LOADED,
  trackingTechDetectedList,
})

const trackingTechDetectedListError = (errorMessage) => ({
  type: types.TRACKING_TECH_DETECTED_LIST_ERROR,
  errorMessage,
})

export const getTrackingTechDetectedList = actionCreator (
  getTrackingTechDetectedListData,
  trackingTechDetectedListPending,
  trackingTechDetectedListLoaded,
  trackingTechDetectedListError,
)

const trackingTechDetectedFiltersPending = () => ({
  type: types.TRACKING_TECH_DETECTED_FILTERS_PENDING,
})

const trackingTechDetectedFiltersLoaded = (trackingTechDetectedFilters) => ({
  type: types.TRACKING_TECH_DETECTED_FILTERS_LOADED,
  trackingTechDetectedFilters,
})

const trackingTechDetectedFiltersError = (errorMessage) => ({
  type: types.TRACKING_TECH_DETECTED_FILTERS_ERROR,
  errorMessage,
})

export const getTrackingTechDetectedFilters = actionCreator (
  getTrackingTechDetectedFiltersData,
  trackingTechDetectedFiltersPending,
  trackingTechDetectedFiltersLoaded,
  trackingTechDetectedFiltersError,
)

const cookielessTechDetectedListPending = () => ({
  type: types.COOKIELESS_TECH_DETECTED_LIST_PENDING,
})

const cookielessTechDetectedListLoaded= (cookielessTechDetectedList) => ({
  type: types.COOKIELESS_TECH_DETECTED_LIST_LOADED,
  cookielessTechDetectedList,
})

const cookielessTechDetectedListError = (errorMessage) => ({
  type: types.COOKIELESS_TECH_DETECTED_LIST_ERROR,
  errorMessage,
})

export const getCookielessTechDetectedList = actionCreator (
  getVendorDetailsForCookielessTech,
  cookielessTechDetectedListPending,
  cookielessTechDetectedListLoaded,
  cookielessTechDetectedListError,
)

const pixelsTechDetectedListPending = () => ({
  type: types.PIXELS_TECH_DETECTED_LIST_PENDING,
})

const pixelsTechDetectedListLoaded= (pixelsTechDetectedList) => ({
  type: types.PIXELS_TECH_DETECTED_LIST_LOADED,
  pixelsTechDetectedList,
})

const pixelsTechDetectedListError = (errorMessage) => ({
  type: types.PIXELS_TECH_DETECTED_LIST_ERROR,
  errorMessage,
})

export const getPixelsTechDetectedList = actionCreator (
  getVendorDetailsForPixelsTech,
  pixelsTechDetectedListPending,
  pixelsTechDetectedListLoaded,
  pixelsTechDetectedListError,
)

const chatbotsTechDetectedListPending = () => ({
  type: types.CHATBOTS_TECH_DETECTED_LIST_PENDING,
})

const chatbotsTechDetectedListLoaded= (chatbotsTechDetectedList) => ({
  type: types.CHATBOTS_TECH_DETECTED_LIST_LOADED,
  chatbotsTechDetectedList,
})

const chatbotsTechDetectedListError = (errorMessage) => ({
  type: types.CHATBOTS_TECH_DETECTED_LIST_ERROR,
  errorMessage,
})

export const getChatbotsTechDetectedList = actionCreator (
  getVendorDetailsForChatbotsTech,
  chatbotsTechDetectedListPending,
  chatbotsTechDetectedListLoaded,
  chatbotsTechDetectedListError,
)

const sessionReplaysTechDetectedListPending = () => ({
  type: types.SESSION_REPLAY_TECH_DETECTED_LIST_PENDING,
})

const sessionReplaysTechDetectedListLoaded= (sessionReplaysTechDetectedList) => ({
  type: types.SESSION_REPLAY_TECH_DETECTED_LIST_LOADED,
  sessionReplaysTechDetectedList,
})

const sessionReplaysTechDetectedListError = (errorMessage) => ({
  type: types.CHATBOTS_TECH_DETECTED_LIST_ERROR,
  errorMessage,
})

export const getSessionReplaysTechDetectedList = actionCreator (
  getVendorDetailsForSessionReplaysTech,
  sessionReplaysTechDetectedListPending,
  sessionReplaysTechDetectedListLoaded,
  sessionReplaysTechDetectedListError,
)

const pixelsParamsTechDetectedListPending = () => ({
  type: types.PIXELS_PARAMS_TECH_DETECTED_LIST_PENDING,
})

const pixelsParamsTechDetectedListLoaded= (pixelsParamsTechDetectedList) => ({
  type: types.PIXELS_PARAMS_TECH_DETECTED_LIST_LOADED,
  pixelsParamsTechDetectedList,
})

const pixelsParamsTechDetectedListError = (errorMessage) => ({
  type: types.PIXELS_PARAMS_TECH_DETECTED_LIST_ERROR,
  errorMessage,
})

export const getParamsPixelsTechDetectedList = actionCreator (
  getVendorDetailsForPixelsParams,
  pixelsParamsTechDetectedListPending,
  pixelsParamsTechDetectedListLoaded,
  pixelsParamsTechDetectedListError,
)

const pixelsHarFileDataPending = () => ({
  type: types.PIXELS_TECH_HAR_FILE_EXPORT_PENDING,
})

const pixelsHarFileDataLoaded= (pixelsHarFileData) => ({
  type: types.PIXELS_TECH_HAR_FILE_EXPORT_LOADED,
  pixelsHarFileData,
})

const pixelsHarFileDataError = (errorMessage) => ({
  type: types.PIXELS_TECH_HAR_FILE_EXPORT_ERROR,
  errorMessage,
})

export const getPixelsHarFileData = actionCreator (
  exportPixelsHarFile,
  pixelsHarFileDataPending,
  pixelsHarFileDataLoaded,
  pixelsHarFileDataError,
)

const  cookielessHarFileDataPending = () => ({
  type: types.COOKIELESS_TECH_HAR_FILE_EXPORT_PENDING,
})

const  cookielessHarFileDataLoaded= (cookielessHarFileData) => ({
  type: types.COOKIELESS_TECH_HAR_FILE_EXPORT_LOADED,
  cookielessHarFileData,
})

const  cookielessHarFileDataError = (errorMessage) => ({
  type: types.COOKIELESS_TECH_HAR_FILE_EXPORT_ERROR,
  errorMessage,
})

export const getCookielessHarFileData = actionCreator (
  exportCookielessHarFile,
  cookielessHarFileDataPending,
  cookielessHarFileDataLoaded,
  cookielessHarFileDataError,
)

const  chatbotHarFileDataPending = () => ({
  type: types.CHATBOT_TECH_HAR_FILE_EXPORT_PENDING,
})

const  chatbotHarFileDataLoaded= (chatbotHarFileData) => ({
  type: types.CHATBOT_TECH_HAR_FILE_EXPORT_LOADED,
  chatbotHarFileData,
})

const  chatbotHarFileDataError = (errorMessage) => ({
  type: types.CHATBOT_TECH_HAR_FILE_EXPORT_ERROR,
  errorMessage,
})

export const getChatbotHarFileData = actionCreator (
  exportChatbotHarFile,
  chatbotHarFileDataPending,
  chatbotHarFileDataLoaded,
  chatbotHarFileDataError,
)

const  sessionReplayHarFileDataPending = () => ({
  type: types.SESSION_REPLAY_TECH_HAR_FILE_EXPORT_PENDING,
})

const  sessionReplayHarFileDataLoaded=  (sessionReplayHarFileData) => ({
  type: types.SESSION_REPLAY_TECH_HAR_FILE_EXPORT_LOADED,
  sessionReplayHarFileData,
})

const  sessionReplayHarFileDataError = (errorMessage) => ({
  type: types.SESSION_REPLAY_TECH_HAR_FILE_EXPORT_ERROR,
  errorMessage,
})

export const getSessionReplayHarFileData = actionCreator (
  exportSessionReplayHarFile,
  sessionReplayHarFileDataPending,
  sessionReplayHarFileDataLoaded,
  sessionReplayHarFileDataError,
)