import React, { useEffect, useMemo, useState } from "react";
import { Chip,  RenderLimitedChips} from "../../../../../../styleguide";
import { Divider } from "antd";
import { stepsData,  OPERATE_MODE_OPTIONS, GPPMSPS_FEATURES, renderInfoTip, FRAMEWORK_TERRITORIES_TOOLTIP, COOKIE_EXPIRATION_TOOLTIP } from "../../../helper";
import { OptInAndOptOut, AdvancedSettings, LanguageTranslations, VendorManagement} from "../../Steps";
import { region_title_index_map } from "../../../../../../constants";
import PrivacyChoicesTable from "./PrivacyChoicesTable";
import SVG from "react-inlinesvg";
import redirectIcon from "../../../../../../assets/icons/link.svg";
import { getOrderedStepsKeys, getStepsDataFieldMap } from "../../../../../common/SequentialNavigationWizard/SequentialLeftNav";
import { fromJS } from "immutable";

function scrollToElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'instant'});
  }
}


const Summary = (props) => {
  const stepsKeyNameMap = getStepsDataFieldMap(fromJS(stepsData), 'title')
  delete stepsKeyNameMap.get('summary');

//selected properties --
  const indexSiteNameMap = useMemo(() =>
      props.sites.toJS().reduce(function (map, obj) {
        map[obj.id] = obj.domain;
        return map;
      }, {}),
    [props.sites]
  );
  const selectedProperties = props.usPrivacyRegulation.siteIds.map(
    (siteId) => indexSiteNameMap[siteId]
  );
  const propertyChips = selectedProperties.map((s) => (<Chip>{s}</Chip>))
//--selected properties
//just to redeploy
//selected territories--
  const territoriesChips = props.summaryPageData.geoTerritories
    .map( territory => {
      if(territory.key !== 'OTHER' && territory.included.length) {
        return (
          <Chip className="usp-summary">
            {territory.key}
            <b>{territory.included.length}</b>
          </Chip>
        )
      } else if(territory.included.length) {
        return territory.included.map(code => <Chip>{region_title_index_map[code]}</Chip> )
      }
    }).flat()

  const operationMode = OPERATE_MODE_OPTIONS.find( o => o.value === (props.usPrivacyRegulation.metaData.MspaOptOutOptionMode ? 'MspaOptOutOptionMode' : 'MspaServiceProviderMode'))?.label
//--selected territories

  const generateStepSummray = (stepKey) => {
    let component;
    switch (stepKey) {
      case "name_property_selection":
        component = (
          <div className="step" id="name_property_selection">
            <div className="field">
              <div className="heading">
                <div>Regulation Name</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                 <SVG src={redirectIcon}/>
                </div>
              </div>
              <div className="field-value">{props.usPrivacyRegulation.name}</div>
            </div>
            <div className="field">
              <div className="heading">Property Selection</div>
              <div className="context">
                Select the properties or property groups you wish these
                regulation settings to apply to.
              </div>
              <div className="content">
                <RenderLimitedChips chips={propertyChips} cutOff={10}/>
              </div>
            </div>
            <Divider />
          </div>
        );
        break;
      case "industry_framework":
        component = (
          <div className="step" id="industry_framework">
            <div className="field">
              <div className="heading">
                <div>Industry Framework Signals</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <div className="context">
                The US state regulation settings support the IAB Tech Lab's
                National Multi-State Privacy String. If you are a signatory of
                the Multi-State Privacy Agreement you can add your ID below.
              </div>
              <div className="content">
                {props.usPrivacyRegulation.signatoryId ? (
                  <>
                    <Chip className="usp-summary">
                      GPP MSPS Identification ID:{" "}
                      <b>{props.usPrivacyRegulation.signatoryId}</b>{" "}
                      {props.usPrivacyRegulation.signatoryName ? <b>{props.usPrivacyRegulation.signatoryName}</b> : null}
                    </Chip>
                    {/* Commenting out as per https://sourcepoint.atlassian.net/browse/DIA-2945 */}
                    {/* 
                    <Chip>
                      MSPACoveredTransaction = "{props.usPrivacyRegulation.metaData.MspaCoveredTransaction ? 'Yes' : 'No'}"
                    </Chip>
                    {props.usPrivacyRegulation.metaData.MspaCoveredTransaction ? (
                      <Chip>
                        {operationMode}
                      </Chip>
                    ): null} */}
                  </>
                ) : <Chip className="usp-summary">No Identification ID added</Chip>}
                {/* Commenting out as per https://sourcepoint.atlassian.net/browse/DIA-2945 */}
                {/* {GPPMSPS_FEATURES.map((f) => (
                  <Chip>
                    {f.title}
                    {renderInfoTip(f.description)}
                  </Chip>
                ))} */}
              </div>
            </div>
            <div className="field">
              <div className="heading">
                <div>Framework Territories{renderInfoTip(FRAMEWORK_TERRITORIES_TOOLTIP)}</div>
              </div>
              <div className="context">
                Choose the territories where these privacy regulation settings
                should apply.
              </div>
              <div className="content">
                <RenderLimitedChips chips={territoriesChips} cutOff={15}/>
              </div>
            </div>
            <Divider />
          </div>
        );
        break;
      case "select_puropses":
        component = (
          <div className="step" id="select_puropses">
            <div className="field">
              <div className="heading">
                <div>Privacy Choices</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <div className="context">
                Select the data processing activities for which your users will
                be able to make choices over.
              </div>
                <PrivacyChoicesTable usPrivacyRegulation={props.usPrivacyRegulation}/>
            </div>
            <div className="field">
              <div className="heading">
                <div>Consent & Opt-out Privacy choices Cookie Expiration {renderInfoTip(COOKIE_EXPIRATION_TOOLTIP)}</div>
              </div>
              <div className="field-value">{props.usPrivacyRegulation.cookieMaxAge}</div>
              <label>Days</label>
            </div>
            <Divider />
          </div>
        );
        break;
      case "language_translations":
        component = (
          <div className="step" id="language_translations">
            <div className="field">
              <div className="heading">
                <div>Language & Translation Settings</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <LanguageTranslations
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
              />
            </div>
            <Divider />
          </div>
        );
        break;
      case "vendor_management":
        component = (
          <div className="step" id="vendor_management">
            <div className="field">
              <div className="heading">
                <div>Vendor Management</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <VendorManagement
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
              />
            </div>
            <Divider />
          </div>
        );
        break;
      case "opt_in_opt_out":
        component = (
          <div className="step" id="opt_in_opt_out">
            <div className="field">
              <div className="heading">
                <div>Opt In/Opt Out Events</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <OptInAndOptOut
                readOnly={true}
                usPrivacyRegulation={props.usPrivacyRegulation}
              />
            </div>
            <Divider />
          </div>
        );
        break;
      case "signal_settings":
        component = (
          <div className="step" id="signal_settings">
            <div className="field">
              <div className="heading">
                <div>Advanced Settings</div>
                <div className="go-to-step pointer" onClick={() => props.goToStep(stepKey)}>
                  Go to step
                  <SVG src={redirectIcon}/>
                </div>
              </div>
              <AdvancedSettings usPrivacyRegulation={props.usPrivacyRegulation} readOnly={true} />
            </div>
          </div>
        );
        break;
    }
    return component;
  };

  const components = props.stepArray.map((stepKey) => {
    return generateStepSummray(stepKey);
  });

  const highlightStep = (idx) => {
    let navLinks = document.querySelectorAll('.summary-nav .nav-item');
    navLinks.forEach(function(navLink) {
      navLink.classList.remove('highlighted');
    });
    navLinks[idx].classList.add('highlighted');
    navLinks[idx]?.scrollIntoView({ behavior: "smooth", block: "start" })    
  };

  const handleSummaryTopNav = (key, idx) => {
    scrollToElement(key);
    setTimeout(()=>{
      highlightStep(idx)
    } , 100)
  }

  useEffect(()=>{
    const summaryContainer = document.getElementById('usp-summary-page')
    summaryContainer.addEventListener('scroll', function() {
      let sections = document.querySelectorAll('.summary-components .step');
      
      for (let index = 0; index < sections.length; index++) {
        let section = sections[index];
        let rect = section.getBoundingClientRect();

        if (rect.top >= 0 && rect.top <= summaryContainer.clientHeight) {
          highlightStep(index);
          if(index !== sections.length - 2) break;
        }
      }
    });
    const firstNavLink = document.querySelectorAll('.summary-nav .nav-item')[0];
    firstNavLink.classList.add('highlighted')
  },[])
  
  return (
    <div>
      <div className="summary-header">
        <div className="step-header">
          <h4>Summary</h4>
        </div>
        <nav className="summary-nav">
          {getOrderedStepsKeys(fromJS(stepsData)).map( (key, idx) => (<div className="nav-item pointer" onClick={()=> handleSummaryTopNav(key, idx)} title={stepsKeyNameMap.get(key)?.replace('Selection', '')}>{stepsKeyNameMap.get(key)?.replace('Selection', '')}</div>))}
        </nav>
      </div>
      <div className="summary-components"  id="usp-summary-page">
        {components}
      </div>
    </div>
  );
};

export default Summary;
