import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import { List } from 'immutable';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { GRAPH_COLORS } from '../../../constants.js';

export default class CampaignGraph extends React.Component {

  static propTypes = {
    data: ImmutablePropTypes.map.isRequired,
  }

  generateData() {
    const data = this.filterOutExtraControlData(this.props.data);
    let finalData = List([]);
    let lineVarNames = List([]);

    data.forEach((list, lineVar) => {
      list = list.map((el) => {

        const date = moment(el.get('x')).utc().format('MMM D, YYYY');

        el = el.set('dates', date)
          .set(lineVar, el.get('y'))
          .delete('x')
          .delete('y');

        // collect all var names needed for graphs
        const varNames = Object.keys(el.toJS());
        varNames.forEach((key) => {
          if (key !== 'dates' && !lineVarNames.includes(key)) {
            lineVarNames = lineVarNames.push(key);
          }
        });

        return el;
      });

      return finalData = finalData.size ? (
        finalData.zipWith((el, newVal) => (el.merge(newVal)), list)
      ) : list;
    });

    return {finalData, lineVarNames};
  }

  filterOutExtraControlData(data) {
    if (!data.get('Control')) return data;
    const maxNonControlTick = data.delete('Control').toList().flatten(true).map(v => v.get('x')).max();

    return data.update('Control', (val => val.filter(v => v.get('x') <= maxNonControlTick)));
  }

  render() {

    if (!this.props.data.size) return null;
    const genData = this.generateData();
    const data = genData.finalData.toJS();
    const lineVarNames = genData.lineVarNames.toJS();

    const graph = (
      <ResponsiveContainer width='100%' height={395}>
        <LineChart
          data={ data }
          width={ 1100 }
          height={ 395 }
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          {
            lineVarNames.map((lineVar, index) => (
              <Line
                type="monotone"
                key={ lineVar }
                dataKey={ lineVar }
                stroke={ Object.keys(GRAPH_COLORS)[index] }
                dot={ false }
              />
            ))
          }
          <XAxis dataKey="dates" />
          <CartesianGrid
            stroke="#ccc"
          />
          <YAxis />
          <Tooltip />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    );
    return (
      <div className="graph-container campaigns">
        { graph }
      </div>
    );
  }
}
