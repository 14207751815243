/* eslint-disable react/prop-types */
import { Button, ConfigProvider, Dropdown, Form } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { Tag } from '../../../../styleguide';
import { setSelectedFilters, summaryLoaded } from '../../../../actions/diagnose_dashboard_actions';
import { getBenchmarksData, getLatestVersionDate } from '../../../../api/diagnose_dashboard/diagnose_dashboard';
import { compareStr, formatRegions } from '../../../../helpers/diagnose/utils.js';
import DashboardSelectDropdown, { regionsTagRenderer } from '../shared/DashboardSelect.jsx';
import DatePickerFormat, { usePickerFormat, DateFormatDatePicker, customPanelRender } from '../shared/DatePicker.jsx';
import AntdDatePicker from '../../../common/AntdDatePicker.jsx';
import RequestReportModal from './RequestReportModal.jsx';

const { RangePicker } = AntdDatePicker;

const DashboardFilters = ({
  // props
  onApply,
  storedFilters,
  searchParams,
  regionsList,
  propertiesList,
  selectedFilters,
  // store action
  setSelectedFilters,
  summaryLoaded,
}) => {
  const accountState = useSelector((state) => state.accountState);
  const currentUser = accountState?.getIn(['userDetails', 'value']);
  const [regions, setRegions] = useState([]);
  const [pickerFormat, setPickerFormat] = usePickerFormat();
  const [range, setRange] = useState([moment().subtract(1, 'week'), moment()]);
  const [properties, setProperties] = useState([]);
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const [latestVersionDate, setLatestVersionDate] = useState({});
  const [benchmarks, setBenchmarks] = useState([]);
  const [benchmarksList, setBenchmarksList] = useState([]);
  const [isReportModalVisible, toggleReportModalVisibility] = useState(false);
  const isRangeLessThan30Days = useMemo(() => {
    const rangeInDays = range[1].diff(range[0], 'days');
    return rangeInDays < 30;
  }, [range]);
  const formattedRegions = useMemo(() => formatRegions(regionsList), [regionsList]);
  const plainRegionList = useMemo(
    () => formattedRegions.flatMap((zone) => zone?.children.map((region) => region.value)),
    [formattedRegions],
  );

  const onSetRegions = (selectedRegions) => {
    setRegions(selectedRegions);
    if (selectedRegions.length) {
      getBenchmarksData({ regions: selectedRegions.join(';') }).then((resp) => {
        setBenchmarks([]);
        setBenchmarksList(resp);
        if (localStorage.getItem('diagnoseSelectedBenchmark') !== null) {
          const storedBenchmarks = JSON.parse(localStorage.getItem('diagnoseSelectedBenchmark'));
          setBenchmarks(storedBenchmarks === 'all' ? resp.map((benchmark) => benchmark.value) : storedBenchmarks);
        }
        if (searchParams.property && searchParams.end_date) {
          setBenchmarks(resp.map((benchmark) => benchmark.value));
        }
      });
    } else {
      setBenchmarksList([]);
    }
  };

  const onSetProperties = (selectedProperties) => {
    setProperties(selectedProperties);
    if (selectedProperties.length && !compareStr(location.pathname, '/vendors_after_opt_out')) {
      getLatestVersionDate({ websites: selectedProperties.join(',') }).then((resp) => {
        if (resp) {
          setLatestVersionDate(resp);
          setNotificationVisible(true);
        } else {
          setNotificationVisible(false);
        }
      });
    } else {
      setLatestVersionDate({});
    }
  };

  // Apply Local Storage Data for same AccountId
  useEffect(() => {
    if (!Object.keys(searchParams).length) {
      const filters = {
        websites: storedFilters.properties,
        regions: storedFilters.regions,
        benchmark_websites: storedFilters.benchmarks,
        start: moment.utc(storedFilters.dates[0]),
        end: moment.utc(storedFilters.dates[1]),
      };
      setBenchmarks(filters.benchmark_websites);
      onSetProperties(filters.websites);
      onSetRegions(filters.regions);
      setRange([filters.start, filters.end]);
      setSelectedFilters(filters);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (Object.keys(searchParams).length) {
        const filters = {
          websites: storedFilters.properties,
          regions: storedFilters.regions,
          benchmark_websites: storedFilters.benchmarks,
          start: moment.utc(storedFilters.dates[0]),
          end: moment.utc(storedFilters.dates[1]),
        };
        setSelectedFilters(filters);
        summaryLoaded([]);
      }
    };
  }, []);

  useEffect(() => {
    if (Object.keys(searchParams).length && searchParams.property && searchParams.end_date) {
      const filters = {
        websites: [searchParams.property],
        regions: searchParams?.region ? [searchParams?.region] : plainRegionList,
        benchmark_websites: [],
        start: searchParams?.start_date
          ? moment.utc(searchParams.start_date)
          : moment.utc(searchParams.end_date).subtract(1, 'week'),
        end: moment.utc(searchParams.end_date),
      };
      setBenchmarks(filters.benchmark_websites);
      onSetProperties(filters.websites);
      onSetRegions(filters.regions);
      setRange([filters.start, filters.end]);
      onApply(filters);
      setSelectedFilters(filters);
    }
  }, []);

  const handleSelectProperties = (value) => {
    const isPropertyAdded = properties.includes(value);
    if (value === 'All') {
      return onSetProperties(
        properties.length === propertiesList?.length ? [] : propertiesList.map((site) => site?.value),
      );
    } else if (properties.length + 1 > 20 && !isPropertyAdded) {
      return alert(
        'Please reduce the amount of sites selected in the property dropdown. You can select up to 20 sites at a time.',
      );
    } else if (isPropertyAdded) {
      return onSetProperties(properties?.filter((property) => property !== value));
    }
    return onSetProperties([...properties, value]);
  };

  const handleSelectBenchmark = (value) => {
    if (value === 'All') {
      setBenchmarks(
        benchmarks.length === benchmarksList?.length ? [] : benchmarksList.map((benchmark) => benchmark.value),
      );
    } else {
      setBenchmarks(
        benchmarks.includes(value) ? benchmarks.filter((region) => region !== value) : benchmarks.concat(value),
      );
    }
  };

  const handleSelectRegions = (value) => {
    const [newZone, newRegion] = value.split(':');
    if (value === 'All') {
      onSetRegions(regions.length !== plainRegionList.length ? plainRegionList : []);
    } else if (!newRegion && value !== 'All') {
      const zoneReions = plainRegionList.filter((region) => region.startsWith(`${newZone}:`));
      const [selectedFromZone, selectedInAnotherZone] = regions.reduce(
        ([selectedZone, anotherZone], region) => {
          if (region.startsWith(`${newZone}:`)) {
            selectedZone.push(region);
          } else {
            anotherZone.push(region);
          }
          return [selectedZone, anotherZone];
        },
        [[], []],
      );

      onSetRegions(
        zoneReions?.length === selectedFromZone.length
          ? selectedInAnotherZone
          : [...regions, ...zoneReions.filter((child) => !regions.includes(child))],
      );
    } else {
      onSetRegions(regions.includes(value) ? regions.filter((region) => region !== value) : [...regions, value]);
    }
    localStorage.setItem('diagnoseSelectedBenchmark', JSON.stringify([]));
  };

  const handleDateChange = (dates) => {
    setRange(
      pickerFormat === 'month'
        ? [moment.utc(dates[0]).startOf('month'), moment.utc(dates[1]).endOf('month')]
        : [...dates],
    );
  };

  const handleApply = () => {
    const filters = {
      websites: properties,
      regions,
      benchmark_websites: benchmarks.length === benchmarksList?.length ? [] : benchmarks,
      start: range[0].utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(),
      end: range[1].utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(),
    };
    onApply(filters);
    localStorage.setItem('diagnoseSelectedProperties', JSON.stringify(properties));
    localStorage.setItem('diagnoseSelectedRegions', JSON.stringify(regions));
    localStorage.setItem('diagnoseSelectedBenchmark', JSON.stringify(benchmarks.length === benchmarksList?.length ? benchmarks : 'all'));
    localStorage.setItem('diagnoseSelectedDates', JSON.stringify([filters.start, filters.end]));
    browserHistory.replace(location.pathname);
    setSelectedFilters(filters);
  };

  const setDateFrom = (setRange) => {
    Object.entries(latestVersionDate).forEach(([key, value]) => {
      setRange([moment.utc(value), moment.utc()]);
    });
    setNotificationVisible(false);
  };

  const minimumDateForMetric = useMemo(() => {
    const metric = location.pathname.split('diagnose_dashboard')[1];
    if (!metric) {
      /* Dashboard Summary */
      return moment('2021-10-01');
    }
    return {
      '/non_disclosed_vendors_observed': moment('2021-10-01'),
      '/vendors_triggered_prior_to_consent': moment('2021-10-01'),
      '/disclosed_vendors': moment('2021-10-01'),
      '/possible_fingerprinting': moment('2021-10-01'),
      '/data_leaving_the_EEA': moment('2022-01-24'),
      '/cookies_with_long_lifespans': moment('2021-10-01'),
      '/all_cookies_found': moment('2021-10-01'),
      '/vendors_after_opt_out': moment('2023-08-01'),
      '/tracking_tech_detected': moment('2024-09-01'),
    }[metric];
  }, [location.pathname]);

  const regionsProps = useMemo(() => {
    const isAllSelected = plainRegionList?.length && regions.length === plainRegionList?.length;
    const extractZoneFromRegion = (region) => region.split(':')[0];
    const uniqueZones = new Set(regions?.map(extractZoneFromRegion));

    let isUniqueZoneSelected = false;
    let uniqueZone = '';

    if (uniqueZones.size === 1) {
      const selectedZone = [...uniqueZones][0];
      const elementsFromSelectedZone = plainRegionList.filter((region) => region.startsWith(`${selectedZone}:`));
      const allFromZoneSelected = elementsFromSelectedZone.every((element) => regions.includes(element));

      if (allFromZoneSelected) {
        isUniqueZoneSelected = true;
        uniqueZone = selectedZone;
      }
    }

    const maxTagPlaceholder = (omitted) => {
      const value = omitted
        ?.map((omit) => omit.title)
        ?.filter((title) => title?.toLowerCase() !== 'all')
        ?.join(', ');

      if (isAllSelected) {
        return <Tag label={'All'} value={value} noClass />;
      }

      if (isUniqueZoneSelected) {
        const zoneValue = formattedRegions
          ?.find((regionsWithZones) => regionsWithZones.value === uniqueZone)
          ?.children?.map((region) => region?.title)
          ?.join(', ');
        return <Tag label={uniqueZone} value={zoneValue} noClass />;
      }

      return <Tag label={`+${omitted.length}...`} value={value} noClass />;
    };

    const filterOption = (input, option) => {
      const isFound =
        option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      return isFound;
    };

    return {
      selected: [...regions, ...(isAllSelected ? ['All'] : [])],
      maxTagCount: isAllSelected || isUniqueZoneSelected ? 0 : 1,
      maxTagPlaceholder,
      filterOption,
    };
  }, [formattedRegions, regions]);

  const isPropertyHighlighted = useCallback((v) => v === searchParams?.property, [searchParams]);
  const isRegionHighlighted = useCallback(() => regionsProps.selected.includes(searchParams.region), [searchParams]);
  const propertiesTagRenderer = (props) => {
    return (
      <Tag
        {...props}
        className={`${props.className || ''} ${
          isPropertyHighlighted(props.value) ? 'diagnose-notification-highlight' : ''
        }`}
      />
    );
  };
  const isDateHighlighted =
    searchParams?.end_date && moment.utc(range[1]).isSame(moment.utc(searchParams?.end_date), 'days');
  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);
  const handleClearProperties = () => onSetProperties([]);
  const handleClearRegions = () => onSetRegions([]);
  const handleResetBenchmarks = () => setBenchmarks([]);
  const rangePickerFooter = useCallback(() => {
    return <DatePickerFormat pickerFormat={pickerFormat} changePickerFormat={setPickerFormat} />;
  }, [pickerFormat, setPickerFormat]);
  const onToggleReportModal = () => toggleReportModalVisibility((t) => !t);

  return (
    <div>
      <Form
        name="dashboard"
        onFinish={handleApply}
        className={classNames('diagnose_filters', !isRangeLessThan30Days && 'extended-grid')}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        initialValues={{
          dates: range,
        }}
        fields={[
          { name: ['properties'], value: properties },
          { name: ['regions'], value: regions },
          { name: ['benchmark_properties'], value: benchmarks },
        ]}
        requiredMark={'optional'}
      >
        {/* properties */}
        <Form.Item
          label="Properties:"
          name="properties"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <DashboardSelectDropdown
            options={propertiesList}
            tagRender={propertiesTagRenderer}
            selected={[
              ...properties,
              ...(propertiesList?.length && properties.length === propertiesList?.length ? ['All'] : []),
            ]}
            onSelect={handleSelectProperties}
            onClear={handleClearProperties}
            placeholder={'Properties'}
            showAll={propertiesList?.length < 21}
          />
        </Form.Item>

        {/* regions */}
        <Form.Item
          label="Regions:"
          name="regions"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <DashboardSelectDropdown
            showSearch={false}
            options={formattedRegions}
            selected={regionsProps.selected}
            onSelect={handleSelectRegions}
            onClear={handleClearRegions}
            tagRender={regionsTagRenderer(
              formattedRegions,
              isRegionHighlighted() ? 'diagnose-notification-highlight' : '',
            )}
            placeholder={'Regions'}
            maxTagCount={regionsProps.maxTagCount}
            maxTagPlaceholder={regionsProps.maxTagPlaceholder}
            filterOption={regionsProps.filterOption}
          />
        </Form.Item>

        {/* daterange */}
        <Form.Item
          label="Date range:"
          name="dates"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          extra={'For date ranges exceeding 30 days, you can request a report.'}
        >
          <ConfigProvider locale={enUS}>
            <RangePicker
              getPopupContainer={(trigger) => trigger.parentElement}
              disabled={false}
              allowClear={false}
              picker={pickerFormat}
              size="medium"
              className={`diagnose-range-input ${isDateHighlighted && 'diagnose-notification-highlight'}`}
              onChange={handleDateChange}
              renderExtraFooter={rangePickerFooter}
              defaultValue={range}
              value={range}
              format={DateFormatDatePicker}
              panelRender={customPanelRender}
              minDate={minimumDateForMetric}
              maxDate={moment()}
            />
          </ConfigProvider>
        </Form.Item>

        {/* benchmark properties */}
        <Form.Item
          label="Benchmark properties:"
          name="benchmark_properties"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <DashboardSelectDropdown
            options={benchmarksList}
            selected={[
              ...benchmarks,
              ...(benchmarksList?.length && benchmarks.length === benchmarksList?.length ? ['All'] : []),
            ]}
            onSelect={handleSelectBenchmark}
            onClear={handleResetBenchmarks}
            placeholder={'Benchmark properties'}
          />
        </Form.Item>
        {/* eslint-disable-next-line no-nested-ternary */}
            <Dropdown.Button
              menu={{
                items: [
                  {
                    label: 'Request Report',
                    key: '1',
                  },
                ],
                onClick: onToggleReportModal,
              }}
              htmlType="submit"
              className="btn-primary"
              type="primary"
              icon={<DownOutlined />}
              getPopupContainer={(trigger) => trigger.parentElement}
              destroyPopupOnHide
            >
              Apply
            </Dropdown.Button>
      </Form>
      {isNotificationVisible && properties.length && !compareStr(location.pathname, '/vendors_after_opt_out') ? (
        <div className={'set-date-range-notification'}>
          <div>
            {' '}
            Do you want the date range to set the last time that a Vendor List on one of their properties was last
            updated?
          </div>
          <Button type="link" onClick={() => setNotificationVisible(false)}>
            Ignore
          </Button>
          <Button onClick={() => setDateFrom(setRange)}>Yes</Button>
        </div>
      ) : null}
      {compareStr(location.pathname, '/data_leaving_the_EEA') ||
      compareStr(location.pathname, '/vendors_after_opt_out') ? (
        <div className={'set-date-range-notification'}>
          <div> Data for this metric is available from {minimumDateForMetric.format('Do MMMM YYYY')} onwards.</div>
        </div>
      ) : null}
        <RequestReportModal
          open={isReportModalVisible}
          toggle={onToggleReportModal}
          formattedRegions={formattedRegions}
          plainRegionList={plainRegionList}
          propertiesList={propertiesList}
          selectedFilters={{
            start: range[0],
            end: range[1],
            websites: properties,
            regions,
          }}
        />
    
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedFilters,
      summaryLoaded,
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(memo(DashboardFilters));
