import React from 'react'
import { Select } from 'antd'

const PreferencesActionOptions = ({ settingsTitle, updateSetting, value, settingKey }) => {
  return (
    <div className="four-options">
      <h4>{settingsTitle}</h4>
      <div className="option-inputs">
        <Select
          value={value}
          onChange={(value) => updateSetting(settingKey, value)}
          options={[
            { value: 0, label: 'Cancel' },
            { value: 1, label: 'Save and Exit' }
          ]}
        />
      </div>
    </div>
  )
}

export default PreferencesActionOptions