import PropTypes from 'prop-types';
import React from 'react';

import InfoTooltip from './InfoTooltip.js.jsx';

export default class extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        tooltipText: PropTypes.string
    };

    static defaultProps = {
        tooltipText: null,
    };

    render() {

        var tooltip;
        if ( this.props.tooltipText ) tooltip = <InfoTooltip hover={ this.props.tooltipText } />;

        return (
            <h3>
              <span>{ this.props.title }</span>
              { tooltip }
           </h3>
        );
    }
}
