import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
  goldenSets: new StoreRecord({ value: List([]) }),
  createOrUpdatePending: false,
  rulePerformanceData: new StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  let updatedDomainSets;
  const sets = state.getIn(['goldenSets', 'value']);
  switch(action.type) {

    case types.GOLDEN_SETS_PENDING:
      return setPending(state, ['goldenSets']);

    case types.GOLDEN_SET_LOADED:
      const updatedSets = sets.filterNot(s => s.id === action.goldenSet.id).push(action.goldenSet);
      return setValue(state, ['goldenSets'], updatedSets);

    case types.GOLDEN_SETS_LOADED:
      return setValue(state, ['goldenSets'], action.goldenSets);

    case types.GOLDEN_SETS_ERROR:
      return setError(state, ['goldenSets'], action.errorMessage);

    case types.GOLDEN_SETS_CACHED:
      return stopPending(state, ['goldenSets']);

    case types.GOLDEN_SET_CREATE_OR_UPDATE_PENDING:
      return state.set('createOrUpdatePending', true);

    case types.GOLDEN_SET_CREATED:
      updatedDomainSets = sets.push(action.goldenSet).sortBy(s => s.name.toLowerCase());
      return setValue(state, ['goldenSets'], updatedDomainSets).set('createOrUpdatePending', false);

    case types.GOLDEN_SET_UPDATED:
      updatedDomainSets = sets.filterNot(s => s.id === action.goldenSet.id).push(action.goldenSet).sortBy(l => l.name.toLowerCase());
      return setValue(state, ['goldenSets'], updatedDomainSets).set('createOrUpdatePending', false);

    case types.GOLDEN_SET_DELETED:
      updatedDomainSets = sets.filterNot(s => s.id === action.id);
      return setValue(state, ['goldenSets'], updatedDomainSets);

    case types.RULE_PERFORMANCE_DATA_PENDING:
      return setPending(state, ['rulePerformanceData']);

    case types.RULE_PERFORMANCE_DATA_LOADED:
      const withNewData = state.getIn(['rulePerformanceData', 'value']).filterNot(data => data.goldenSetId === action.data.goldenSetId).push(action.data);
      return setValue(state, ['rulePerformanceData'], withNewData);

    case types.RULE_PERFORMANCE_DATA_ERROR:
      return setError(state, ['rulePerformanceData'], action.errorMessage);

    case types.RULE_PERFORMANCE_DATA_CACHED:
      return stopPending(state, ['rulePerformanceData']);
  }
  return state;
};