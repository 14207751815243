/* eslint-disable camelcase */
import React from 'react';
import { Layout, Result } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { browserHistory } from 'react-router';
import { Button } from '../../styleguide';

export default function ErrorPage() {
    const { query: { redirect_to } } = browserHistory.getCurrentLocation();

    const handleGoBack = () => {
        browserHistory.replace(redirect_to);
    };

    const routeTitle = {
        '/vendor_trace': 'Vendor trace',
        '/diagnose_dashboard': 'Diagnose Dashboard',
        '/diagnose_dashboard/non_disclosed_vendors_observed': 'Non disclosed vendors observed',
        '/diagnose_dashboard/vendors_triggered_prior_to_consent': 'Vendors triggered prior to consent',
        '/diagnose_dashboard/disclosed_vendors': 'Disclosed Vendors',
        '/diagnose_dashboard/possible_fingerprinting': 'Possible Fingerprinting',
        '/diagnose_dashboard/data_leaving_the_EEA': 'Data leaving the EEA',
        '/diagnose_dashboard/cookies_with_long_lifespans': 'Cookies with long lifespans',
        '/diagnose_dashboard/all_cookies_found': 'All Cookies Found',
        '/diagnose_dashboard/vendors_after_opt_out': 'Vendors after opt out',
        '/diagnose/alerts': 'Diagnose Alerts',
    }[redirect_to];
    const hasRedirectBackAction = !!routeTitle;
    const title = `Uncaught error${routeTitle ? `: ${routeTitle}` : ''}`;

    return (
        <div className="uncaught-error-page">
            <Layout.Content className="uncaught-error-container">
                <Result
                    status="error"
                    icon={<CloseCircleFilled style={{ color: '#C02822' }} />}
                    title={title}
                    subTitle="Please try again later or reach out to your account manager."
                    extra={(
                        <>
                            <a href="https://docs.sourcepoint.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
                                <Button
                                    size="small"
                                    type="secondary"
                                >Contact support</Button>
                            </a>
                            {hasRedirectBackAction ? (
                                <Button
                                    size="small"
                                    type="primary"
                                    onClick={handleGoBack}
                                >Try again</Button>
                            ) : null}
                        </>
                )}
                />
            </Layout.Content>
        </div>
    );
}
