/* eslint-disable react/prop-types */
import React from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  ComposedChart,
  Bar,
  Legend,
} from 'recharts';
import { InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';

import { Tooltip } from 'antd';

const tooltipLabelFormatter = (value) => {
  return moment(value).format('DD MMM');
};

const ChartCard = ({ title, titleTooltip, dataEvolution, showRelative = false, showAverage = false }) => (
  <div className={'card'}>
    <div className={'card_title'}>
      <b>{title}</b>
      <Tooltip overlayClassName="diagnose--tooltip" title={titleTooltip}>
        <InfoCircleFilled />
      </Tooltip>
    </div>
    <ResponsiveContainer width="100%" height="90%" className={'relative-score-and-average-count-evolution-chart'}>
      <ComposedChart width={500} height={200} data={dataEvolution}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" interval={0} tickFormatter={tooltipLabelFormatter} tick={{ fontSize: 10 }} />
        <YAxis unit="%" yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" stroke="#3CB6FC" />
        {showAverage && <Bar yAxisId="right" dataKey={'Average Count (My properties)'} barSize={20} fill="#3CB6FC" />}
        {showAverage && <Bar yAxisId="right" dataKey={'Average Count (Benchmark)'} barSize={20} fill="#CFE4FF" />}
        {showRelative && (
          <Line type="monotone" dataKey={'Relative Score'} stroke="#000000" fill="#000000" yAxisId="left" />
        )}
        <RechartTooltip labelFormatter={tooltipLabelFormatter} allowEscapeViewBox={{ x: false, y: true }} />
        <Legend />
      </ComposedChart>
    </ResponsiveContainer>
  </div>
);

export default ChartCard;
