import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, Upload, message } from 'antd';
import { SSL_OP_NO_TICKET } from 'constants';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

export default class DataModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    vendorScanningEnabled: PropTypes.bool.isRequired,
    getVendorsAndCategoriesForToggleIab: PropTypes.func.isRequired,
    updateParentState: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
  }

  state = {
    uploadAction: {},
    fileMessage: null,
  }

  handleOnOk = () => {
    this.props.updateFile()
    this.props.closeModal();
  }

  onFileUpload = (info, b, c) => {
    if (info.file.status === 'done') {
      this.props.documentUploaded(this.props.requestId);
      message.success(`${info.file.name} file uploaded successfully`);
      const fileMessage = `${info.file.name} file was successfully uploaded as a request response`;
      this.setState({ fileMessage })
    } else if (info.file.status === 'error') {
      Modal.error({
        title: `${info.file.name} file upload failed.`,
        content: 'Please try again.',
        footer: null,
      });
    }
  }

  retrieveFile = () => {
    this.props.retrieveFile().then((r) => {
      let fileMessage;
      if (r instanceof Error) {
        fileMessage = 'There was an error downloading the file';
      } else {
        fileMessage = 'The file was successfully downloaded';
      }
      this.setState({ fileMessage })
    });
  }

  beforeUpload = async (file) => {
    const ccpaUrl = permissionsSvcBaseUrl;

    const uploadAction = `${ccpaUrl}/ccpa/subject-access-request/secure-storage/document?accountId=` + this.props.accountId + '&siteId=' + this.props.siteId + '&requestId=' + this.props.requestId + '&fileName=' + file.name

    this.setState({
      uploadAction,
    });

    return true;
  };

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  render() {
    const uploadProps = {
      name: 'file',
      action: this.state.uploadAction,
      onChange: this.onFileUpload,
      onDownload: this.onFileDownload,
      beforeUpload: this.beforeUpload,
    };
    return (
      <Modal
        onOk={this.handleOnOk}
        onCancel={this.handleCancelModalChanges}
        okText='Apply changes'
        className='do-not-sell-modal dsar-document-modal'
        closable
        destroyOnClose
        footer={null}
        visible={!!this.props.requestId}
      >
        <p className='title'>DATA RESPONSE DOCUMENT</p>
        <p className='request-id'>{`Request Id: ${this.props.requestId}`}</p>
        {
          this.state.fileMessage ?
            <p className='file-message'>{this.state.fileMessage}</p> :
            null
        }
        <p>Data request need to be securely stored. All requests responses need to be attached to a valid request ticket.</p>
        <p>Do you want to?</p>
        <div>
          {
            this.props.hasFileToRetrieve ?
              <Button onClick={this.retrieveFile}>Retrieve a Response File</Button> :
              null
          }
          <Upload {...uploadProps}>
            <Button>Upload a Response File</Button>
          </Upload>
        </div>
      </Modal>
    );
  }
}