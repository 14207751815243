import React from 'react';
import PropTypes from 'prop-types';
import { FileTextOutlined, GlobalOutlined, InfoCircleFilled } from '@ant-design/icons';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Vendor, VendorCookie as Cookie } from '../../../records/vendor_list_records';
import ReactQuill from 'react-quill';
import { Checkbox, Infotip } from '../../../styleguide';

export default class VendorDetailsTab extends React.Component {
  static propTypes = {
    vendor: PropTypes.instanceOf(Vendor).isRequired,
    cookies: ImmutablePropTypes.listOf(PropTypes.instanceOf(Cookie)).isRequired,
    updateExcludeFromVendorCount: PropTypes.func.isRequired
  }

  formats = ['italic', 'underline', 'strike', 'list', 'bullet', 'link', 'code-block', 'bold']

  render() {
    const globalVendor = this.props.globalVendors.find(v => v.id === this.props.vendor.id)
    const excludeVendorFromCountLabel = (
      <>
        Exclude this vendor from the vendor count{" "}
        <Infotip
          className="maxWidth"
          trigger="hover"
          placement="right"
          title={null}
          content={
            "Selecting this option will only affect the vendor count disclosed on the first layer and privacy manager of your message and won't change the number of total vendors displayed on this vendor list."
          }
          onClick={() => togglePropertiesDetail(record)}
        >
          <InfoCircleFilled style={{color:"#939393"}} />
        </Infotip>
      </>
    );

    return (
      <div style={{ height: 300 }} className='cookie-detail-tab'>
        <div className='vendor-title'>
          <div className='vendor-name'>{this.props.vendor.name}</div>
          <div className='icons'>
            <FileTextOutlined onClick={() => window.open(this.props.vendor.policyUrl)} />
            <GlobalOutlined onClick={() => window.open(this.props.vendor.siteUrl)} />
          </div>
        </div>
        <p className='vendor-id'>ID: {this.props.vendor.id}</p>
        {this.props.vendor.iabId && (
          <p className="vendor-id">
            IAB ID: {this.props.vendor.iabId}
          </p>
        )}
        <div className="exclude-vendor-count-checkbox">
          <Checkbox
            className="vendor-id"
            checked={this.props.excludeFromCount}
            onChange={(e) => this.props.updateExcludeFromVendorCount(e.target.checked)}
            disabled={this.props.readOnly}
          >
            {excludeVendorFromCountLabel}
          </Checkbox>
        </div>
        <div className='vendor-id'><b>Custom Labels</b></div>
        {this.props.isAppleMessagingEnabled && (
          <Checkbox
            id="on-status-change"
            className="vendor-id"
            checked={this.props.isApple}
            onChange={(e) => this.props.updateVendorAppleDataBroker(e.target.checked)}
            disabled={this.props.readOnly}
          >
            Apple Data Broker
          </Checkbox>
        )}
        {this.props.isCustomVendorLabelsEnabled || true && (
          <React.Fragment>
            <Checkbox
              id="custom-label-1"
              className="vendor-id"
              checked={this.props.isCustomVendorLabel1}
              onChange={(e) => this.props.updateCustomLabel(1, e.target.checked)}
              disabled={this.props.readOnly}
            >
              Custom Vendor Label 1
            </Checkbox>
            <Checkbox
              id="custom-label-2"
              className="vendor-id"
              checked={this.props.isCustomVendorLabel2}
              onChange={(e) => this.props.updateCustomLabel(2, e.target.checked)}
              disabled={this.props.readOnly}
            >
              Custom Vendor Label 2
            </Checkbox>
            <Checkbox
              id="custom-label-3"
              className="vendor-id"
              checked={this.props.isCustomVendorLabel3}
              onChange={(e) => this.props.updateCustomLabel(3, e.target.checked)}
              disabled={this.props.readOnly}
            >
              Custom Vendor Label 3
            </Checkbox>
          </React.Fragment>
        )}
        {this.props.vendor.googleId && (
          <p className="vendor-id">
            Google ATP ID: {this.props.vendor.googleId}
          </p>
        )}
        <div className="description-container">
          <label className="title" htmlFor="vendor-description">
            Description
          </label>
          <ReactQuill
            style={{ height: 140, marginBottom: 40 }}
            onChange={this.props.updateVendorDescription}
            value={this.props.description}
            formats={this.formats}
            readOnly={this.props.readOnly}
          />
        </div>

        <div className='details-container'>

          {
            globalVendor.purposes && globalVendor.purposes.size
              ? (
                <React.Fragment>
                  <div className='title'>IAB Purposes</div>
                  {
                    globalVendor.purposes && globalVendor.purposes.map(p => (
                      <div key={p.name}>{p.name}</div>
                    ))
                  }
                </React.Fragment>
              )
              : null
          }

          {
            globalVendor.legIntPurposes && globalVendor.legIntPurposes.size
              ? (
                <React.Fragment>
                  <div className='title'>Legitimate Interest Purposes</div>
                  {
                    globalVendor.legIntPurposes && globalVendor.legIntPurposes.map(p => (
                      <div key={p.name}>{p.name}</div>
                    ))
                  }
                </React.Fragment>
              )
              : null
          }

          {
            globalVendor.iabSpecialPurposes && globalVendor.iabSpecialPurposes.size
              ? (
                <React.Fragment>
                  <div className='title'>IAB Special Purposes</div>
                  {
                    globalVendor.iabSpecialPurposes && globalVendor.iabSpecialPurposes.map(p => (
                      <div key={p.name}>{p.name}</div>
                    ))
                  }
                </React.Fragment>
              )
              : null
          }

          {
            globalVendor.iabSpecialFeatures && globalVendor.iabSpecialFeatures.size
              ? (
                <React.Fragment>
                  <div className='title'>IAB Special Features</div>
                  {
                    globalVendor.iabSpecialFeatures && globalVendor.iabSpecialFeatures.map(p => (
                      <div key={p.name}>{p.name}</div>
                    ))
                  }
                </React.Fragment>
              )
              : null
          }

        </div>

      </div>
    );
  }
}
