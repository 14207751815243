import React from "react";
import { Modal, Table } from "antd";
import { createCSVExportData } from "../performanceNew/helper";
import CustomPopover from "../../common/CustomPopover";
import ExportPopoverContent from "../../ccpa/ccpa_reporting/ExportPopoverContent";

export default class MessagePerformanceDetails extends React.Component {
  render() {
    return (
      <Modal
        title={
          <span>
            Message Performance of{" "}
            {this.props.tableData.size > 0
              ? this.props.tableData.get(0).get("messageid")
              : ""}
            <CustomPopover
              tooltipContent={
                <ExportPopoverContent
                  filename="message_performance"
                  exportData={createCSVExportData(
                    this.props.columns,
                    this.props.tableData
                  )}
                  pdfTitle={"Message Performance"}
                />
              }
              style="export-popover"
              icon="download"
              iconColor="#108ee9"
              title={
                <span
                  style={{
                    fontSize: "11px",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 100
                  }}
                >
                  Export Type Selection
                </span>
              }
            />
          </span>
        }
        visible={this.props.status}
        onCancel={this.props.handleCancel}
        footer={null}
        className="msgDesModal"
      >
        <Table
          columns={this.props.columns.toJS()}
          dataSource={this.props.tableData.toJS() || []}
          className="consnet-tableCSS"
          pagination={{
            size: "small",
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            locale: { items_per_page: "" }
          }}
        />
      </Modal>
    );
  }
}
