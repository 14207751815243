import { Map, List } from "immutable";
import { StoreRecord } from "../records/store_records.js";
import { setValue } from "./helpers.js";

const initialState = Map({
  scenarioData: StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  const lists = state.getIn(["scenarioData", "value"]);
  let updatedList;
  switch (action.type) {
    case "ADD_SCENARIO":
      updatedList = lists.push(action.data);
      return setValue(state, ["scenarioData"], updatedList);
    case "REMOVE_SCENARIO":
      updatedList = lists.delete(action.data);
      return setValue(state, ["scenarioData"], updatedList);
    case "COPY_SCENARIO":
      let scenario = lists.get(action.data);
      scenario = scenario.set("name", scenario.get("name") + " (Copy)");
      updatedList = lists.push(scenario);
      return setValue(state, ["scenarioData"], updatedList);
  }
  return state;
};
