import React from 'react';
import { message as AntdMessage } from 'antd';

const PostMessage = (props) => {
  const settings = {
    type: props.type || 'default',
    content: props.content,
    duration: props.duration || 3,
    className: 'spsg-message ' + props.className
  };

  AntdMessage.open(settings)
};

const message = {
  open: (content, duration) => PostMessage({content, duration}),
  success: (content, duration) => PostMessage({type: 'success', className: 'msg-success',  content, duration}),
  error: (content, duration) => PostMessage({type: 'error', className: 'msg-error', content, duration}),
  info: (content, duration) => PostMessage({type: 'info', className: 'msg-info', content, duration}),
  warning: (content, duration) => PostMessage({type: 'warning', className: 'msg-warning', content, duration})
};

export default message;
