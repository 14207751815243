import React from 'react';
import { Modal as AntdModal } from 'antd';

const Close = (incomingProps) => (
  <div className="spsg-modal-close" onClick={incomingProps.onClick}>X</div>
);

const Actions = (incomingProps) => {
  const props = {...incomingProps};
  props.className = 'spsg-modal-actions ' + (props.className || '');
  return <div {...props} />;
};

const Header = (incomingProps) => {
  const props = {...incomingProps};
  props.className = 'spsg-modal-header ' + (props.className || '');
  return <div {...props} />;
};

const Modal = (incomingProps) => {
  const props = {...incomingProps};
  props.wrapClassName = 'spsg-modal ' + (props.wrapClassName || '');
  return <AntdModal {...props} />;
};

const Warning = (incomingProps) => {
  const props = {...incomingProps};
  props.wrapClassName = 'spsg-modal ' + (props.wrapClassName || '');
  return AntdModal.warning({...props})
}

const Confirm = (incomingProps) => {
  const props = {...incomingProps};
  props.wrapClassName = 'spsg-modal ' + (props.wrapClassName || '');
  return AntdModal.confirm({...props})
}

Modal.Close = Close;
Modal.Header = Header;
Modal.Actions = Actions;
Modal.confirm = Confirm;
Modal.error = AntdModal.error;
Modal.warning = Warning;
export default Modal;
