import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { ConfigProvider, Input, Select, Table } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { Map, List } from 'immutable';
import CustomPopover from '../../common/CustomPopover';
import {
  vendorsTooltipContent,
  purposesTooltipContent,
} from './intructionalText';
import { createCSVExportData } from './helper';
import ExportPopoverContent from '../../ccpa/ccpa_reporting/ExportPopoverContent';
const { Option } = Select;

export default class ConsentPVVendor extends React.Component {
  state = {
    searchText: '',
    searchPurposesText: '',
  };

  render() {
    let tableData = this.props.consentVendorData;
    tableData = tableData.filter((v) =>
      v.name.toLowerCase().includes(this.state.searchText)
    );
    let purposeTableData = this.props.purposeConsentData;
    purposeTableData = purposeTableData.filter((p) =>
      p.name.toLowerCase().includes(this.state.searchPurposesText)
    );
    const columns = List([
      Map({
        title: 'Vendor',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        className: 'left-align',
      }),
      Map({
        title: 'Consent Rate',
        dataIndex: 'consentrate',
        key: 'consentrate',
        sorter: (a, b) => a.consentrate - b.consentrate,
        className: 'right-align',
        render: (text) => <span>{Number(text).toFixed(2) + '%'}</span>,
      }),
      Map({
        title: 'Unconsented Rate',
        dataIndex: 'rejectionrate',
        key: 'rejectionrate',
        sorter: (a, b) => a.rejectionrate - b.rejectionrate,
        className: 'right-align',
        render: (text) => <span>{Number(text).toFixed(2) + '%'}</span>,
      }),
      Map({
        title: 'Consented Views',
        dataIndex: 'consentcount',
        key: 'consentcount',
        sorter: (a, b) => a.consentcount - b.consentcount,
        className: 'right-align',
        render: (text) => <span>{text.toLocaleString()}</span>,
      }),
      Map({
        title: 'Total Views',
        dataIndex: 'pvcount',
        key: 'pvcount',
        sorter: (a, b) => a.pvcount - b.pvcount,
        className: 'right-align',
        render: (text) => <span>{text.toLocaleString()}</span>,
      }),
    ]);
    const vendorHeaders = columns.map((item, i) =>
      Map({ label: item.get('title'), key: item.get('dataIndex') })
    );
    const purposesHeader = vendorHeaders.updateIn(
      [0, 'label'],
      (value) => 'Purpose'
    );
    const tableTitle = (
      <div className="table-title-container">
        <div className="table-title">
          Consented Views by Vendors{' '}
          <CustomPopover tooltipContent={vendorsTooltipContent} />{' '}
          <CustomPopover
            tooltipContent={
              <ExportPopoverContent
                filename="consented_pageviews_vendors"
                exportData={createCSVExportData(vendorHeaders, tableData)}
                pdfTitle={'Consented Views by Vendors'}
              />
            }
            style="export-popover"
            icon="download"
            iconColor="#108ee9"
            title={
              <span
                style={{
                  fontSize: '11px',
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: 100,
                }}
              >
                Export Type Selection
              </span>
            }
          />
        </div>
        <div className="vendors-table-search">
          <div className="search-container">
            <Input
              placeholder="Search Vendors"
              prefix={
                <SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              onChange={(event) => {
                this.setState({ searchText: event.target.value });
              }}
            />
          </div>
        </div>
      </div>
    );
    const purposesTableTitle = (
      <div className="table-title-container">
        <div className="table-title">
          Performance by Purpose{' '}
          <CustomPopover tooltipContent={purposesTooltipContent} />{' '}
          <CustomPopover
            tooltipContent={
              <ExportPopoverContent
                filename="performance_by_purposes"
                exportData={createCSVExportData(
                  purposesHeader,
                  purposeTableData
                )}
                pdfTitle={'Performance by Purpose'}
              />
            }
            style="export-popover"
            icon="download"
            iconColor="#108ee9"
            title={<span className="popover-title">Export Type Selection</span>}
          />
        </div>
        <div className="vendors-table-search">
          <div className="search-container">
            <Input
              placeholder="Search Purposes"
              prefix={
                <SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              onChange={(event) => {
                this.setState({ searchPurposesText: event.target.value });
              }}
            />
          </div>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        <div className="vendors-filter-container">
          <div className="vendor-property-select-container">
            <div>Property</div>
            <ConfigProvider locale={enUS}>
              <Select
                placeholder="Select Property"
                className="vendor-property-filter"
                optionFilterProp="children"
                disabled={false}
                onChange={this.props.selectSingleSite}
                value={this.props.vendorProperty}
                showSearch={true}
                filterOption={(i, o) =>
                  o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                }
              >
                {this.props.sitesOptions}
              </Select>
            </ConfigProvider>
          </div>
          <div className="vendor-geo-select-container">
            <div>Region</div>
            <ConfigProvider locale={enUS}>
              <Select
                placeholder="Select Region"
                maxTagCount={
                  this.props.maxSize == this.props.vendorRegions.size ? 0 : 1
                }
                maxTagTextLength={2}
                maxTagPlaceholder={
                  this.props.maxSize == this.props.vendorRegions.size
                    ? 'All'
                    : `+${
                        this.props.vendorRegions.size
                          ? this.props.vendorRegions.size - 1
                          : 0
                      }`
                }
                className="vendor-property-filter"
                optionFilterProp="children"
                mode="multiple"
                disabled={false}
                value={this.props.vendorRegions.toJS()}
                onChange={this.props.selectVendorRegion}
                filterOption={(i, o) =>
                  o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                }
              >
                <Option
                  key="All"
                  value="All"
                  className={
                    this.props.maxSize == this.props.vendorRegions.size
                      ? 'select-all-style'
                      : ''
                  }
                >
                  All
                </Option>
                {this.props.regionsOptions}
              </Select>
            </ConfigProvider>
          </div>
        </div>
        <Table
          columns={columns.toJS()}
          dataSource={tableData.toJS()}
          title={() => tableTitle}
          pagination={{
            itemRender: this.itemRender,
            size: 'small',
            defaultPageSize: 6,
            showSizeChanger: true,
            pageSizeOptions: ['4', '8', '10'],
            locale: { items_per_page: '' },
          }}
          className="consnet-tableCSS"
          rowKey={(r) => r.key}
        />
        <Table
          columns={columns.updateIn([0, 'title'], (value) => 'Purpose').toJS()}
          dataSource={purposeTableData.toJS()}
          title={() => purposesTableTitle}
          pagination={{
            itemRender: this.itemRender,
            size: 'small',
            defaultPageSize: 6,
            showSizeChanger: true,
            pageSizeOptions: ['4', '8', '10'],
            locale: { items_per_page: '' },
          }}
          className="consnet-tableCSS"
          rowKey={(r) => r.key}
        />
      </React.Fragment>
    );
  }
}
