import PropTypes from 'prop-types';
import React from 'react';
import { Cascader } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { SCENARIO_STEPS } from './steps';

class TypeSelector extends React.Component {
  static propTypes = {
    types: ImmutablePropTypes.list.isRequired,
    selectValue: PropTypes.string,
    handleTypeSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectValue: '',
  }

  handleTypeSelect = ([, value]) => {
    this.props.handleTypeSelect(value);
  }

  assembleOptions = () => {
    // const filteredScenarioSteps = Object.assign({}, SCENARIO_STEPS);
    // delete filteredScenarioSteps['com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2'];
    const options = [
      {
        value: 'condition',
        label: 'Condition',
        children: [],
      },
      {
        value: 'message',
        label: 'Message',
        children: [],
      },
      {
        value: 'delay',
        label: 'Delay',
        children: [],
      },
    ];

    this.props.types.forEach(type => {
      const child = {
        value: '',
        label: '',
      };
      child.value = type;
      child.label = SCENARIO_STEPS[type].label;
      let optionIdx = 0;

      if (type.includes('Message')) {
        optionIdx = 1;
      }
      else if (type.includes('Delay')) {
        optionIdx = 2;
      }
      else {
        optionIdx = 0;
      }
      options[optionIdx].children.push(child);
    })

    return options;
  }

  getSelectValue = () => {
    const category = this.props.selectValue !== '' ? new SCENARIO_STEPS[this.props.selectValue].Record().category : '';
    const selectValue = [category, this.props.selectValue];
    return selectValue;
  }

  render() {
    const options = this.assembleOptions();
    const selectValue = this.getSelectValue();

    return (
      <div>
        <Cascader
          allowClear={false}
          value={selectValue}
          onChange={this.handleTypeSelect}
          options={options}
        />
      </div>
    );
  }
}

export default TypeSelector;
