import React from 'react';
import { Link } from 'react-router';

export default class CcpaSubnav extends React.Component {
  render() {
    const { currentUser: { accountId, accountFeatures } } = this.props;
    return (
      <div className="dialogue subnav">
        <ul className="list-inline">
          <li><Link to='/us_privacy/vendor_lists' activeClassName="active" >Vendor List</Link></li>
          <li><Link to='/us_privacy/privacy_manager' activeClassName="active" >Privacy Manager</Link></li>
          {(accountFeatures.includes('sar') || accountId === 22) && (
            <li>
              <Link to='/us_privacy/requests' activeClassName="active">
                Subject Access Requests
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }
}
