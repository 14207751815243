import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../../common/CustomButton.js.jsx';
import { browserHistory } from 'react-router';
import { getParameterByName } from '../../utils';

export default class extends React.Component {
  static propTypes = {
      savePending: PropTypes.bool.isRequired,
      editing: PropTypes.bool.isRequired,
      handleSave: PropTypes.func.isRequired,
      form: PropTypes.object.isRequired,
      messageConfigType: PropTypes.string.isRequired,
      siteId: PropTypes.string.isRequired,
  };

  hasErrors = (fieldsError) => {
      return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleClose = () => {
    let url = '/dialogue/messages?site_id=' + this.props.siteId;
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      url += '&site_group_id=' + siteGroupId;
    }
    browserHistory.push(url);
  };

  render() {
      const { getFieldDecorator, getFieldsError } = this.props.form;

      let saveButton;
      if (this.props.editing) {
          saveButton = (
              <CustomButton
                className="save-button"
                disabled={ this.props.savePending || this.hasErrors(getFieldsError()) }
                size={ CustomButton.sizes.LARGE }
                onClick={ this.props.handleSave }
              >
                Save
              </CustomButton>
          );
      }

      let disableRedirectPreview = false;
      if (this.props.messageConfigType === 'redirect') {
        disableRedirectPreview = true;
      }

      const backToMessageListButton = (
        <CustomButton
          className="back"
          size={ CustomButton.sizes.LARGE }
          onClick={ this.handleClose }
        >
          Close
        </CustomButton>
      );

      return (
          <div className="options-header-bar">
            <div className="container">
              { backToMessageListButton }
              { saveButton }
            </div>
          </div>
      );
  }
}
