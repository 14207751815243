import * as types from './action_types';
import { phantomFontGrabber } from '../api/dialogue/messages';
import { actionCreator } from './helpers';

export const getSiteFonts = actionCreator(
  phantomFontGrabber,
  siteFontsPending,
  siteFontsLoaded,
  siteFontsError,
);

function siteFontsPending() {
  return {
    type: types.SITE_FONTS_PENDING,
  };
}

function siteFontsLoaded(importedFonts) {
  return {
    type: types.SITE_FONTS_LOADED,
    importedFonts,
  };
}
function siteFontsError(errorMessage) {
  return {
    type: types.SITE_FONTS_ERROR,
    errorMessage,
  };
}
