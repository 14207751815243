import PropTypes from 'prop-types';
import React from 'react';
import {
  Badge,
} from 'antd';

export default class IsActive extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
  }

  render() {
    return (
      <div className='active-status'>
        { this.props.isActive ?
          <Badge status='success' text='Active' />
          :
          <Badge status='default' text='Inactive' />
        }
      </div>
    );
  }
}
