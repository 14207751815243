import * as types from "./action_types";
import { actionCreator } from "./helpers";
import {
  getAndParseGdprV2ReportVendorData,
  getAndParseGdprV2ReportMessagedData,
  getAndParseGdprV2ReportPVUsersData,
  getAndParseGdprV2ReportPurposeData,
  getAndParseGdprV2ReportSpecialFeaturesData
} from "../api/gdpr_v2/gdpr_tcfv2_performance_report_v2";

export const getGdprV2ReportPurposeData = actionCreator(
  getAndParseGdprV2ReportPurposeData,
  gdprV2ReportPurposePending,
  gdprV2ReportPurposeLoaded,
  gdprV2ReportPurposeError,
  gdprV2ReportPurposeCached
);

export const getGdprV2ReportVendorData = actionCreator(
  getAndParseGdprV2ReportVendorData,
  gdprV2ReportVendorPending,
  gdprV2ReportVendorLoaded,
  gdprV2ReportVendorError,
  gdprV2ReportVendorCached
);

export const getGdprV2ReportSpecialFeaturesData = actionCreator(
  getAndParseGdprV2ReportSpecialFeaturesData,
  gdprV2ReportSpecialFeaturePending,
  gdprV2ReportSpecialFeatureLoaded,
  gdprV2ReportSpecialFeatureError,
  gdprV2ReportSpecialFeatureCached
);

export const getGdprV2ReportMessageData = actionCreator(
  getAndParseGdprV2ReportMessagedData,
  gdprV2ReportMessagePending,
  gdprV2ReportMessageLoaded,
  gdprV2ReportMessageError,
  gdprV2ReportMessageCached
);

export const getGdprV2ReportPVUsersData = actionCreator(
  getAndParseGdprV2ReportPVUsersData,
  gdprV2ReportPVUsersPending,
  gdprV2ReportPVUsersLoaded,
  gdprV2ReportPVUsersError,
  gdprV2ReportPVUsersCached
);

function gdprV2ReportSpecialFeaturePending() {
  return {
    type: types.GDPRV2_V2_REPORT_SPECIAL_FEATURE_PENDING
  };
}

function gdprV2ReportSpecialFeatureLoaded(data) {
  return {
    type: types.GDPRV2_V2_REPORT_SPECIAL_FEATURE_LOADED,
    data
  };
}

function gdprV2ReportSpecialFeatureError(error) {
  return {
    type: types.GDPRV2_V2_REPORT_SPECIAL_FEATURE_ERROR,
    error
  };
}

function gdprV2ReportSpecialFeatureCached() {
  return {
    type: types.GDPRV2_V2_REPORT_SPECIAL_FEATURE_CACHED
  };
}

function gdprV2ReportPurposePending() {
  return {
    type: types.GDPRV2_V2_REPORT_PURPOSE_PENDING
  };
}

function gdprV2ReportPurposeLoaded(data) {
  return {
    type: types.GDPRV2_V2_REPORT_PURPOSE_LOADED,
    data
  };
}

function gdprV2ReportPurposeError(error) {
  return {
    type: types.GDPRV2_V2_REPORT_PURPOSE_ERROR,
    error
  };
}

function gdprV2ReportPurposeCached() {
  return {
    type: types.GDPRV2_V2_REPORT_PURPOSE_CACHED
  };
}

function gdprV2ReportMessagePending() {
  return {
    type: types.GDPRV2_V2_REPORT_MESSAGE_PENDING
  };
}

function gdprV2ReportMessageLoaded(data) {
  return {
    type: types.GDPRV2_V2_REPORT_MESSAGE_LOADED,
    data
  };
}

function gdprV2ReportMessageError(error) {
  return {
    type: types.GDPRV2_V2_REPORT_MESSAGE_ERROR,
    error
  };
}

function gdprV2ReportMessageCached() {
  return {
    type: types.GDPRV2_V2_REPORT_MESSAGE_CACHED
  };
}

function gdprV2ReportPVUsersPending() {
  return {
    type: types.GDPRV2_V2_REPORT_PVUSERS_PENDING
  };
}

function gdprV2ReportPVUsersLoaded(data) {
  return {
    type: types.GDPRV2_V2_REPORT_PVUSERS_LOADED,
    data
  };
}

function gdprV2ReportPVUsersError(error) {
  return {
    type: types.GDPRV2_V2_REPORT_PVUSERS_ERROR,
    error
  };
}

function gdprV2ReportPVUsersCached() {
  return {
    type: types.GDPRV2_V2_REPORT_PVUSERS_CACHED
  };
}

function gdprV2ReportVendorPending() {
  return {
    type: types.GDPRV2_V2_REPORT_VENDOR_PENDING
  };
}

function gdprV2ReportVendorLoaded(data) {
  return {
    type: types.GDPRV2_V2_REPORT_VENDOR_LOADED,
    data
  };
}

function gdprV2ReportVendorError(error) {
  return {
    type: types.GDPRV2_V2_REPORT_VENDOR_ERROR,
    error
  };
}

function gdprV2ReportVendorCached() {
  return {
    type: types.GDPRV2_V2_REPORT_VENDOR_CACHED
  };
}