import { OrderedSet, fromJS } from 'immutable';
import * as types from './action_types';
import { actionCreator } from './helpers';
import { FETCHED } from '../constants.js';
import store from '../store.js';
import {
  getProdCampaignsForMultSitesAjax,
  getActiveCampaignData,
  getLiveCampaignsData,
  getCampaignGraphData,
  getCampaignStatsAjax,
  activateCampaignAjax,
  activateSiteGroupCampaignAjax,
  getOldCampaignsAjax,
  copyAndActivateCampaignInEnvAjax,
  copyAndActivateSiteGroupCampaignInEnvAjax,
  stopLiveCampaignAjax,
  stopSiteGroupLiveCampaignAjax
} from '../api/dialogue/campaigns';
import { fetchPublicScenarios, fetchStageScenarios } from './scenario_actions.js';
import { fetchPublicMessages, fetchStageMessages } from './message_actions';
import { findMessageIdsFromScenario } from '../components/utils.js';
import { setSiteGroupSitesWithErrors, updateSite, updatePropertyGroup } from './site_actions';
import { campaignLog, createSiteGroupCampaignLog, deleteSiteGroupCampaignLog } from '../api/change_log'

export const getProdCampaignsForMultSites = actionCreator(
  getProdCampaignsForMultSitesAjax,
  campaignsPending,
  campaignsLoaded,
  campaignsError,
  campaignsCached,
)

export function getLiveStageCampaign(siteId) {
  return (dispatch) => {
    return _getLiveCampaign(dispatch, siteId, 'stage')
  }
}

export function getLivePublicCampaign(siteId) {
  return (dispatch) => {
    return _getLiveCampaign(dispatch, siteId, 'public')
  }
}

function _getLiveCampaign(dispatch, siteId, env) {
  dispatch(campaignsPending());
  getActiveCampaignData(siteId, env).then((resp) => {
    if (resp === FETCHED) {
      dispatch(campaignsCached())
    } else {
      dispatch(campaignsLoaded(resp));
      if (resp) _getCampaignsGraphAndStats(dispatch, resp.id);
    }
  }).catch((resp) => {
    dispatch(campaignsError(resp));
  });
}

export function getAllPublicLiveCampaigns(siteId, campaignTypes) {
  return (dispatch) => {
    return _getLiveCampaigns(dispatch, siteId, campaignTypes, 1)
  }
}

export function getAllStageLiveCampaigns(siteId, campaignTypes) {
  return (dispatch) => {
    return _getLiveCampaigns(dispatch, siteId, campaignTypes, 2)
  }
}

function _getLiveCampaigns(dispatch, siteId, campaignTypes, env) {
  dispatch(campaignsPending());
  getLiveCampaignsData(siteId, campaignTypes, env).then((resp) => {
    if (resp === FETCHED) {
      dispatch(campaignsCached())
    } else {
      dispatch(campaignsLoaded(resp));
    }
  }).catch((resp) => {
    dispatch(campaignsError(resp));
  });
}

export const getOldCampaigns = actionCreator(
  getOldCampaignsAjax,
  campaignsPending,
  campaignsLoaded,
  campaignsError,
  campaignsCached,
)

export function getCampaignStats(campaignId) {
  return (dispatch) => {
    _getCampaignStats(campaignId)(dispatch)
  }
}

const _getGraphData = actionCreator(
  getCampaignGraphData,
  campaignGraphPending,
  campaignGraphLoaded,
  campaignGraphError,
  campaignGraphCached,
)

const _getCampaignStats = actionCreator(
  getCampaignStatsAjax,
  campaignStatsPending,
  campaignStatsLoaded,
  campaignStatsError,
  campaignStatsCached,
)

function _getCampaignsGraphAndStats(dispatch, campaignId) {
  _getGraphData(campaignId)(dispatch);
  _getCampaignStats(campaignId)(dispatch);
}

function activateLegacyCampaigns (dispatch, getState, options) {
  return _stopPreviousCampaign(dispatch, getState, options)
    .then(() => activateCampaignAjax(options))
    .then(resp => {
      campaignLog(getState(), resp)
      _getCampaignsGraphAndStats(dispatch, resp.id);
        return (options.env === "stage")
          ? _activateStageCampaign(dispatch, resp, options.siteId)
          : _activatePublicCampaign(dispatch, resp, options.siteId);
      })
      .catch((resp) => dispatch(campaignsError(resp)));
}

export function activateCampaign(options) {
  return (dispatch, getState) => {
    dispatch(campaignsPending());
        return (options.isMultiCampaignEnabled)
          ? activateMultiCampaign(dispatch, getState, options)
          : activateLegacyCampaigns(dispatch, getState, options);
  };
}

export function stopPreviouslyActiveCampaign(dispatch, getState, previousCampaign, isSiteGroup) {
  return new Promise(
    function (resolve) {
      if (previousCampaign) {
        if(isSiteGroup) {
          stopSiteGroupLiveCampaign(previousCampaign)(dispatch, getState).then(() => resolve());
        } else {
          stopLiveCampaign(previousCampaign)(dispatch, getState).then(() => resolve());
        }
      } else {
        resolve();
      }
    }
  )
}

function stopPreviouslyLiveCampaigns(dispatch, getState, options,isSiteGroup=false) {
  const campaignType = options.deleteCampaign.campaign_type_id;
  const currentEnv = options.deleteCampaign.environment;
  const newEnv = currentEnv === 1 ? 2 : 1;
  const previousCampaign = getState().campaignState.getIn(['campaigns', 'value']).find(c => c.site_id === parseInt(options.siteId) && c.environment === newEnv && c.live && c.campaign_type_id === campaignType);
  options.campaign = previousCampaign;
  return new Promise(
    function (resolve) {
      if(previousCampaign) {
        if (isSiteGroup) {
          stopSiteGroupLiveCampaign(options)(dispatch, getState).then(() => resolve());
        }else{
          stopLiveCampaign(previousCampaign)(dispatch, getState).then(() => {resolve()});
        } 
      }else{
        resolve();
      }
    }
  );
}

export function stopAndCopyCampaign(options) {
  return (dispatch, getState) => {
    dispatch(campaignsPending());
    const campaignType = options.deleteCampaign.campaign_type_id;
    const currentEnv = options.deleteCampaign.environment === 1 ? "public" : "stage";
    return stopPreviouslyLiveCampaigns(dispatch, getState, options)
      .then(() => copyAndActivateCampaignInEnvAjax(currentEnv, options.siteId,campaignType))
      .then(resp => {
        campaignLog(getState(), resp)
        dispatch(updateSite(options.siteId, options.priorityLists));
        _getCampaignsGraphAndStats(dispatch, resp.id);
        return (currentEnv === "stage")
          ? _activatePublicCampaign(dispatch, resp, options.siteId)
          : _activateStageCampaign(dispatch, resp, options.siteId);

      })
      .catch((resp) => dispatch(campaignsError(resp)));
  }
  //   return copyAndActivateCampaignInEnvAjax(currentEnv, options.siteId, campaignType)
  //     .then(resp => {
  //       stopPreviouslyLiveCampaigns(dispatch, getState, options);
  //       campaignLog(getState(), resp);
  //       dispatch(updateSite(options.siteId, options.priorityLists));
  //       _getCampaignsGraphAndStats(dispatch, resp.id);
  //       return (currentEnv === "stage")
  //         ? _activatePublicCampaign(dispatch, resp, options.siteId)
  //         : _activateStageCampaign(dispatch, resp, options.siteId);

  //     })
  //     .catch((resp) => dispatch(campaignsError(resp)));
  // };
  
}

export function stopAndCopySiteGroupCampaign(options) {
  const delCampaignType = options.deleteCampaign.campaign_type_id;
  const delCurrentEnv = options.deleteCampaign.environment;
  const delNewEnv = delCurrentEnv === 1 ? 2 : 1;
  return (dispatch, getState) => {
    const previousCampaign = getState().campaignState.getIn(['campaigns', 'value']).find(c => c.site_id === parseInt(options.siteId) && c.environment === delNewEnv && c.live && c.campaign_type_id === delCampaignType);
    if(previousCampaign) { 
       dispatch(updateStoppedcampaign(previousCampaign));
	}
    dispatch(campaignsPending());
    const campaignType = options.deleteCampaign.campaign_type_id;
    const env = options.deleteCampaign.environment;
    const currentEnv = env === 1 ? "public" : "stage";
    options.currentEnv = currentEnv;
    options.campaignType = campaignType;
    return stopPreviouslyLiveCampaigns(dispatch,getState,options,true)
      .then(() => copyAndActivateSiteGroupCampaignInEnvAjax(options))
      .then(resp => {
        if(options.siteGroup) {
          setDeletedCampaigns(dispatch, getState, fromJS(options.siteGroup), options);
        }
        createSiteGroupCampaignLog(getState(), options, resp);
        dispatch(updatePropertyGroup(options.accountId, options));
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        options.currentEnv === "stage"
          ? resp.get('campaigns').forEach(c => _activatePublicCampaign(dispatch, c, c.site_id))
          : resp.get('campaigns').forEach(c => _activateStageCampaign(dispatch, c, c.site_id));
        return resp
      })
      .catch((resp) => dispatch(campaignsError(resp)));
  };
}

export function activateMultiCampaign(dispatch, getState, options) {
  return (options.deleteCampaign)
    ? stopAndReplaceCampaign(dispatch, getState, options)
    : activateTypedPropertyCampaigns(dispatch, getState, options);
}

export function activateTypedPropertyCampaigns(dispatch, getState, options) {
  return activateCampaignAjax(options)
    .then(resp => {
      dispatch(updateSite(options.siteId, options.priorityList))
      campaignLog(getState(), resp)
      _getCampaignsGraphAndStats(dispatch, resp.id);
        return (options.env === 'stage')
          ? _activateStageCampaign(dispatch, resp, options.siteId)
          : _activatePublicCampaign(dispatch, resp, options.siteId);
      })
      .catch((resp) => dispatch(campaignsError(resp)));
}

export function stopAndReplaceCampaign(dispatch, getState, options) {
  return stopPreviouslyActiveCampaign(dispatch, getState, options.deleteCampaign)
    .then(resp => activateCampaignAjax(options))
    .then(resp => {
      dispatch(updateSite(options.siteId, options.priorityList))
      campaignLog(getState(), resp)
      _getCampaignsGraphAndStats(dispatch, resp.id);
        return (options.env === 'stage')
          ? _activateStageCampaign(dispatch, resp, options.siteId)
          : _activatePublicCampaign(dispatch, resp, options.siteId);
      })
      .catch((resp) => dispatch(campaignsError(resp)));
}

export function legacyActivateSiteGroupCampaign(dispatch, getState, options) {
    const isSiteGroup = true;
    return _stopPreviousCampaign(dispatch, getState, options, isSiteGroup)
      .then(() => activateSiteGroupCampaignAjax(options))
      .then((resp) => {
        createSiteGroupCampaignLog(getState(), options, resp)
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        options.env === "stage"
          ? resp.get('campaigns').forEach(c => _activateStageCampaign(dispatch, c, c.site_id))
          : resp.get('campaigns').forEach(c => _activatePublicCampaign(dispatch, c, c.site_id));
      })
      .catch((resp) => dispatch(campaignsError(resp)));
}

function setDeletedCampaigns(dispatch, getState, siteGroup, options) {
  const sitesUnderPg = siteGroup.get('siteIds');
  const campaignType = options.campaignType;
  const env = options.env === 'public' ? 1 : 2;
  const previousCampaigns = getState().campaignState.getIn(['campaigns', 'value']).filter(c => sitesUnderPg.includes(c.site_id) && c.environment === env && c.live && c.campaign_type_id === campaignType);
  return new Promise(
    function (resolve) {
      if (previousCampaigns.size) {
        previousCampaigns.forEach(c => dispatch(updateStoppedcampaign(c)));
        resolve();
      } else {
        resolve();
      }
    }
  );
}

export function activateTypedPropertyGroupCampaign(dispatch, getState, options) {
    return activateSiteGroupCampaignAjax(options)
      .then((resp) => {
        dispatch(updatePropertyGroup(options.accountId, options));
        setDeletedCampaigns(dispatch, getState, resp.get('siteGroup'), options);
        createSiteGroupCampaignLog(getState(), options, resp);
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        options.env === "stage"
          ? resp.get('campaigns').forEach(c => _activateStageCampaign(dispatch, c, c.site_id))
          : resp.get('campaigns').forEach(c => _activatePublicCampaign(dispatch, c, c.site_id));
      })
      .catch((resp) => dispatch(campaignsError(resp)));
}

export function stopAndReplacePropertyGroupCampaign(dispatch, getState, options) {
  const isSiteGroup = true;
  return stopPreviouslyActiveCampaign(dispatch, getState, options, isSiteGroup)
    .then(resp => activateSiteGroupCampaignAjax(options))
    .then((resp) => {
      dispatch(updatePropertyGroup(options.accountId, options));
      setDeletedCampaigns(dispatch, getState, resp.get('siteGroup'), options);
      createSiteGroupCampaignLog(getState(), options, resp);
      dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
      options.env === "stage"
        ? resp.get('campaigns').forEach(c => _activateStageCampaign(dispatch, c, c.site_id))
        : resp.get('campaigns').forEach(c => _activatePublicCampaign(dispatch, c, c.site_id));
    })
    .catch((resp) => dispatch(campaignsError(resp)));
}

export function activateSiteGroupMultiCampaign(dispatch, getState, options) {
  return (options.campaign)
    ? stopAndReplacePropertyGroupCampaign(dispatch, getState, options)
    : activateTypedPropertyGroupCampaign(dispatch, getState, options);
}

export function activateSiteGroupCampaign(options) {
  return (dispatch, getState) => {
    dispatch(campaignsPending());
    return options.isMultiCampaignEnabled ? 
      activateSiteGroupMultiCampaign(dispatch, getState, options) :
      legacyActivateSiteGroupCampaign(dispatch, getState, options);
  };
}

function _stopPreviousCampaign(dispatch, getState, options, isSiteGroup) {
  const previousCampaign = getState().campaignState.getIn(['campaigns', 'value']).find(c => c.site_id === parseInt(options.siteId) && c.status === options.env && c.live);
  options.campaign = previousCampaign;
  return new Promise(
    function (resolve) {
      if (previousCampaign) {
        debugger
        if (isSiteGroup) {
          debugger
          stopSiteGroupLiveCampaign(options)(dispatch, getState).then(() => resolve());
        } else {
          debugger
          stopLiveCampaign(previousCampaign)(dispatch, getState).then(() => {debugger; resolve()});
        }
      } else {
        resolve();
      }
    }
  )
}

export function copyAndActivateCampaignInEnv(currentEnv, siteId) {
  return (dispatch, getState) => {
    dispatch(campaignsPending());
    const newEnv = currentEnv === "stage" ? "public" : "stage";
    return _stopPreviousCampaign(dispatch, getState, { env: newEnv, siteId })
      .then(() => copyAndActivateCampaignInEnvAjax(currentEnv, siteId))
      .then(resp => {
        campaignLog(getState(), resp)
        _getCampaignsGraphAndStats(dispatch, resp.id);
        return (currentEnv === "stage")
          ? _activatePublicCampaign(dispatch, resp, siteId)
          : _activateStageCampaign(dispatch, resp, siteId);

      })
      .catch((resp) => dispatch(campaignsError(resp)));
  };
}

export function copyAndActivateSiteGroupCampaignInEnv(options) {
  return (dispatch, getState) => {
    dispatch(campaignsPending());
    const newEnv = options.currentEnv === "stage" ? "public" : "stage";
    return _stopPreviousCampaign(dispatch, getState, { env: newEnv, siteId: options.siteId }).then(() => {
      return copyAndActivateSiteGroupCampaignInEnvAjax(options);
    })
      .then((resp) => {
        createSiteGroupCampaignLog(getState(), options, resp)
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        options.currentEnv === "stage"
          ? resp.get('campaigns').forEach(c => _activatePublicCampaign(dispatch, c, c.site_id))
          : resp.get('campaigns').forEach(c => _activateStageCampaign(dispatch, c, c.site_id));
        return resp
      })
      .catch((resp) => dispatch(campaignsError(resp)));
  };
}

function _activatePublicCampaign(dispatch, campaign, siteId) {
  return Promise.all([fetchPublicScenarios(siteId)(dispatch), fetchPublicMessages(siteId)(dispatch)]).then(() => {
    return dispatch(campaignsLoaded(campaign));
  });
}

function _activateStageCampaign(dispatch, campaign, siteId) {
  return Promise.all([fetchStageScenarios(siteId)(dispatch), fetchStageMessages(siteId)(dispatch)]).then(() => {
    return dispatch(campaignsLoaded(campaign));
  });
}

export function stopLiveCampaign(campaign, options) {
  return (dispatch, getState) => {
    dispatch(campaignsPending());
    _archiveScenariosAndMessages(dispatch, getState, campaign);
    return stopLiveCampaignAjax(campaign)
      .then(campaign => {
        if(options && options.isMultiCampaignEnabled) {
          dispatch(updateSite(campaign.site_id, options.priorityList))
        }
        campaignLog(getState(), campaign)
        dispatch(campaignStopped(campaign))
      })
      .catch((resp) => dispatch(campaignsError(resp)));
  }
}

export function stopSiteGroupLiveCampaign(options) {
  return (dispatch, getState) => {
    dispatch(campaignsPending());
    _archiveScenariosAndMessages(dispatch, getState, options.campaign);
    return stopSiteGroupLiveCampaignAjax(options)
      .then((resp) => {
        if(options && options.isMultiCampaignEnabled && !options.isCreate) {
          dispatch(updatePropertyGroup(options.accountId, options.siteGroup))
        }
        deleteSiteGroupCampaignLog(getState(), options, resp)
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(campaignStopped(resp.get('campaigns')));
      })
      .catch((resp) => dispatch(campaignsError(resp)));
  }
}

function _archiveScenariosAndMessages(dispatch, getState, campaign) {

  // archive messages
  // scenarios??

  const liveScenarios = getState().scenarioState.getIn(['scenarios', 'value']).filter(s => s.live);

// scenarios??
  const scenarios = campaign.partition_set.get('partitions').map(partition => liveScenarios.find(s => {
    return s.id === partition.get('scenario_id')
  }));

  const messageIds = scenarios.map(s => {
    // have to deal with JSON format to support custom scenarios
    return findMessageIdsFromScenario(fromJS(JSON.parse(s.scenario_json)));
  }).flatten().toSet();

  dispatch({ type: types.ARCHIVE_MESSAGES, messageIds });

  // archive scenarios
  const scenarioIds = campaign.partition_set.get('partitions').map(p => p.get('scenario_id')).toSet();

  dispatch({ type: types.ARCHIVE_SCENARIOS, scenarioIds });
}

function campaignsPending() {
  return {
    type: types.CAMPAIGNS_PENDING
  }
}

function campaignsCached() {
  return {
    type: types.CAMPAIGNS_CACHED
  }
}

function campaignsLoaded(campaigns) {
  let campaignSet = campaigns;

  if (campaigns) {
    if (!OrderedSet.isOrderedSet(campaigns)) {
      campaignSet = (OrderedSet([campaigns]));
    }
  } else {
    // if there are no active or old campaigns, campaignsList is empty
    campaignSet = OrderedSet([]);
  }

  return {
    type: types.CAMPAIGNS_LOADED,
    campaigns: campaignSet,
  };
}

function campaignsError(errorMessage) {
  return {
    type: types.CAMPAIGNS_ERROR,
    errorMessage
  }
}

function updateStoppedcampaign(campaign) {
 /*will not affect functionality; temporary hack to remove campaign from stage/public list, on reload db date will be used;*/
  const updatedCampaign = campaign.set('deleted_at', new Date().toISOString());
  return {
    type: types.CAMPAIGN_STOPPED_UPDATE,
    campaign: updatedCampaign
  }
}

function campaignStopped(campaign) {
  return {
    type: types.CAMPAIGN_STOPPED,
    campaign
  }
}

function campaignGraphPending(campaignId) {
  return {
    type: types.CAMPAIGN_GRAPH_PENDING,
    campaignId,
  }
}

function campaignGraphCached(campaignId) {
  return {
    type: types.CAMPAIGN_GRAPH_CACHED,
    campaignId,
  }
}

function campaignGraphLoaded(graphData, campaignId) {
  return {
    type: types.CAMPAIGN_GRAPH_LOADED,
    graphData,
    campaignId,
  }
}

function campaignGraphError(errorMessage, campaignId) {
  return {
    type: types.CAMPAIGN_GRAPH_ERROR,
    errorMessage,
    campaignId,
  }
}

function campaignStatsPending(campaignId) {
  return {
    type: types.CAMPAIGN_STATS_PENDING,
    campaignId,
  }
}

function campaignStatsCached(campaignId) {
  return {
    type: types.CAMPAIGN_STATS_CACHED,
    campaignId,
  }
}

function campaignStatsLoaded(statsData, campaignId) {
  return {
    type: types.CAMPAIGN_STATS_LOADED,
    statsData,
    campaignId,
  }
}

function campaignStatsError(errorMessage, campaignId) {
  return {
    type: types.CAMPAIGN_STATS_ERROR,
    errorMessage,
    campaignId,
  }
}

// SAVE CAMPAIGNS PAGE STATE

export function updateCampaignsViewState(viewState) {
  store.dispatch({ type: types.UPDATE_CAMPAIGNS_VIEW_STATE, viewState });
}

export function clearCampaignViewState() {
  store.dispatch({ type: types.CLEAR_CAMPAIGN_VIEW_STATE });
}
