import React, { Component } from 'react';
import { Tabs as AntdTabs } from 'antd';

class Tabs extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'spsg-inline-tabs ' + (props.className || '');

    return <AntdTabs {...props} />;
  }
}

Tabs.TabPane = AntdTabs.TabPane;
export default Tabs;
