import PropTypes from 'prop-types';
import React from 'react';
import SummaryHeaderTable from './SummaryHeaderTable';
import { CLIENTS_WITH_ADBLOCK_GRAPH_AND_POP_UP_STATS } from '../../../../constants';

import { numberWithCommas, percentOfNum } from '../../../utils.js'

export default class extends React.Component {
    static propTypes = {
        totalChoices: PropTypes.number.isRequired,
        campaignStats: PropTypes.object.isRequired,
    };

    render() {

        var stats = this.props.campaignStats;

        var totalValues = [];
        totalValues.push([numberWithCommas(stats.total_pv), "Total PVs"]);
        totalValues.push([numberWithCommas(stats.total_msg), "Messages Shown"]);
        totalValues.push([numberWithCommas(this.props.totalChoices), "Choices Made"]);

        var percentageValues = [];
        percentageValues.push([
            percentOfNum(stats.abp_desktop_pv, stats.desktop_pv),
            "Desktop AB %"
        ])
        percentageValues.push([
            percentOfNum(stats.abp_mobile_pv, stats.mobile_pv),
            "Mobile AB %"
        ])
        percentageValues.push([
            percentOfNum(stats.abp_desktop_pv + stats.abp_mobile_pv, stats.total_pv),
            "Total AB %"
        ])

        let summaryTable;

        if (CLIENTS_WITH_ADBLOCK_GRAPH_AND_POP_UP_STATS.includes(this.props.accountId)) {
            summaryTable = (
                <div className="header-tables-container adblock-included clearfix">
                  <SummaryHeaderTable values={ totalValues } />
                  <SummaryHeaderTable values={ percentageValues } />
                </div>
            )
        } else {
            summaryTable = (
                <div className="header-tables-container no-adblock">
                  <SummaryHeaderTable values={ totalValues } />
                </div>
            )
        }

        return (
          <div>
              { summaryTable }
          </div>
        )
    }
}
