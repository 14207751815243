import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Checkbox } from '../../styleguide';
import { InfoCircleFilled } from '@ant-design/icons';
import { Action, TagManager } from '../../records/vendor_list_records';
import GeoTargetingSelect from '../consent/vendor_list_v2/ActionsTab/GeoTargetingSelect';
import GeoTargetingSelectCcpa from '../ccpa/vendor_list/GeoTargetingSelect';
import ImplementationType from '../consent/vendor_list_v2/ActionsTab/ImplementationType';
import { renderInfoTip } from '../consent/us_privacy_regulation/helper';

export default class ActionsTab extends React.Component {
  static propTypes = {
    updateActions: PropTypes.func.isRequired,
    actionType: PropTypes.string,
    actions: ImmutablePropTypes.listOf(PropTypes.instanceOf(Action)).isRequired,
    readOnly: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    actionType: 'consentActions',
  }
  //

  handleCustomJSChange = (value) => {
    const actionIdx = this.props.actions.findIndex(a => a.type === 'inline' && !a.tagManager);

    if (!value) {
      this.removeAction(actionIdx);
      return;
    }

    if (actionIdx > -1) {
      this.updateAction(actionIdx, 'js', value);
    } else {
      const newActions = this.props.actions.push(new Action({ type: 'inline', js: value }));
      this.props.updateActions(newActions);
    }
  }

  handleTagManagerChange = (e) => {
    const value = e.target.value;
    const actionIdx = this.props.actions.findIndex(a => a.type === 'inline' && a.tagManager);
    const googleTagManagerJS = `window.dataLayer && window.dataLayer.push({ 'event': ${value} });`;

    if (!value) {
      this.removeAction(actionIdx);
      return;
    }

    if (actionIdx > -1) {
      let actions = this.props.actions;
      actions = actions.update(actionIdx, action => action.set('js', googleTagManagerJS).set('tagManager', action.tagManager.set('value', value)));

      this.props.updateActions(actions);
    } else {
      const newActions = this.props.actions.push(new Action({ type: 'inline', js: googleTagManagerJS, tagManager: new TagManager({ name: 'google', key: 'event', value }) }));
      this.props.updateActions(newActions);
    }
  }

  handleUrlActionChange = (e, actionIdx) => {
    const value = e.target.value;
    if (!value) {
      this.removeAction(actionIdx);
      return;
    }
    this.updateAction(actionIdx, 'url', value);
  }

  addUrlInput = () => {
    // find index of first inline type and insert before it in the end of external types
    let actionIdx = this.props.actions.findIndex(a => a.type === 'inline');
    if (actionIdx < 0) actionIdx = this.props.actions.size;
    const newActions = this.props.actions.insert(actionIdx, new Action({ type: 'external' }).set('url', ''));
    this.props.updateActions(newActions);
  }

  updateAction = (actionIdx, propertyName, value) => {
    let actions = this.props.actions;

    if (value) {
      actions = actions.update(actionIdx, action => action.set(propertyName, value));
    }

    this.props.updateActions(actions);
  }

  removeAction = (actionIdx) => {
    let actions = this.props.actions;
    actions = actions.remove(actionIdx);

    this.props.updateActions(actions);
  }

  render() {
    const customJSActions = this.props.actions
      .filter(customAction => customAction.type === 'inline' && !customAction.tagManager);

    const customJsValue = customJSActions.size ? customJSActions.first().js : '';

    let tagManagerValue;
    if (this.props.actionType === 'consentActions') {
      const tagManagerActions = this.props.actions.filter(ca => ca.type === 'inline' && ca.tagManager);
      tagManagerValue = tagManagerActions.size ? tagManagerActions.first().tagManager.value : '';
    };

    const GeoTargetingComponent = this.props.isCcpa ? GeoTargetingSelectCcpa : GeoTargetingSelect;
    return (
      <div className='edit-purpose-tab-container'>
        <div className='consent-actions'>
          <div className="geo-consent-container">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label className="title" htmlFor="on-status-change">
              {/* Execute Custom {this.props.isRejectActions ? 'Reject' : 'Accept'} Action */}
              Only trigger hook when
            </label>
            <Checkbox
              purple
              id="on-status-change"
              checked={this.props.onStatusChangeOnly}
              onChange={(e) => this.props.updateOnStatusChangeOnly(e.target.checked)}
              disabled={this.props.readOnly}
            >
              User has changed state
              {renderInfoTip("This option prevents the hook from being triggered on the default state of the user. E.g. the user visits the property for the first time and the hook is not triggered, but if they opted out and then opted back in the hook would be triggered then.")}
            </Checkbox>
          </div>
          <GeoTargetingComponent
            geoTargeting={this.props.geoTargeting}
            updateGeoTargeting={this.props.updateGeoTargeting}
            readOnly={this.props.readOnly}
          />
          </div>
          {(this.props.isRejectActions && !this.props.isCcpa) && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                purple
                id="on-new-user"
                checked={this.props.onNewUser}
                onChange={(e) => this.props.updateOnNewUser(e.target.checked)}
                disabled={this.props.readOnly}
              >
                When new user
              </Checkbox>
            </div>
          )}
          <ImplementationType
            customJsValue={customJsValue}
            tagManagerValue={tagManagerValue}
            actionCookies={this.props.actionCookies}
            urlConsentActions={this.props.actions.filter(ca => ca.type === 'external')}
            handleCustomJSChange={this.handleCustomJSChange}
            handleTagManagerChange={this.handleTagManagerChange}
            handleUrlActionChange={this.handleUrlActionChange}
            handleActionCookiesChange={this.props.updateActionCookies}
            addUrlInput={this.addUrlInput}
            readOnly={this.props.readOnly}
            isUSPRegulation={this.props.isUSPRegulation}
          />
        </div>
      </div>
    );
  }
}
