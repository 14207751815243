import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { BACKEND_STATUS_TYPES } from '../../../constants.js';
import classNames from 'classnames';

export default class extends React.Component {
    static propTypes = {
        status: PropTypes.oneOf(_.keys(BACKEND_STATUS_TYPES)).isRequired,
    };

    render() {

        const clazz = classNames('badge', this.props.status === "draft" ? "draft" : "published");

        return (<span className={ clazz }>{ this.props.status }</span>);
    }
}
