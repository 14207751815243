import React from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';

const range = ['#cb483a', '#fe6600', '#ffc62c', '#a2df53', '#05a233'];

const Ranges = ({ value: pointerVal }) => {
  const colorPointer = (val) => {
    if (val < 21) {
      return range[0];
    } else if (val >= 21 && val < 41) {
      return range[1];
    } else if (val >= 41 && val < 61) {
      return range[2];
    } else if (val >= 61 && val < 81) {
      return range[3];
    } else if (val >= 81) {
      return range[4];
    }
    return null;
  };

  return (
    <div className="linear-gauge-chart-container">
      <div className="linear-gauge-chart-value" style={{ color: colorPointer(pointerVal) }}>
        {pointerVal}
      </div>
      <div className="linear-gauge-chart-name">Compliance score vs. benchmark</div>
      <div className="linear-gauge-chart-overlay">
        <div className="linear-gauge-chart-pointer" style={{ left: `calc(${pointerVal}% - 10px)` }}>
          <ArrowDownOutlined style={{ fontSize: '22px', color: colorPointer(pointerVal) }} />
        </div>
        <div
          className="linear-gauge-chart-step"
          style={{ backgroundColor: '#cb483a', borderRadius: '10px 0 0 10px' }}
        />
        <div className="linear-gauge-chart-step" style={{ backgroundColor: '#fe6600' }} />
        <div className="linear-gauge-chart-step" style={{ backgroundColor: '#ffc62c' }} />
        <div className="linear-gauge-chart-step" style={{ backgroundColor: '#a2df53' }} />
        <div
          className="linear-gauge-chart-step"
          style={{ backgroundColor: '#05a233', borderRadius: '0 10px 10px 0' }}
        />
      </div>
      <div className="linear-gauge-chart-legend">
        <div className="linear-gauge-chart-legend-item">
          <div className="linear-gauge-chart-legend-icon" style={{ backgroundColor: '#cb483a' }} />
          <b> 1-20 </b> Very low
        </div>
        <div className="linear-gauge-chart-legend-item">
          <div className="linear-gauge-chart-legend-icon" style={{ backgroundColor: '#fe6600' }} />
          <b> 21-40 </b> Low
        </div>
        <div className="linear-gauge-chart-legend-item">
          <div className="linear-gauge-chart-legend-icon" style={{ backgroundColor: '#ffc62c' }} />
          <b> 41-60 </b> Medium
        </div>
        <div className="linear-gauge-chart-legend-item">
          <div className="linear-gauge-chart-legend-icon" style={{ backgroundColor: '#a2df53' }} />
          <b> 61-80 </b> High
        </div>
        <div className="linear-gauge-chart-legend-item">
          <div className="linear-gauge-chart-legend-icon" style={{ backgroundColor: '#05a233' }} />
          <b> 81-100 </b> Very high
        </div>
      </div>
    </div>
  );
};

export default Ranges;
