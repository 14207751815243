import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getPrivacyManagerListAjax,
  getPrivacyManagerAjax,
  createPrivacyManagerAjax,
  updatePrivacyManagerAjax,
  deletePrivacyManagerAjax,
  clonePrivacyManagerAjax,
  getSitePrivacyManagerListAjax,
  getSiteGroupPrivacyManagerListAjax,
} from '../api/ccpa/privacy_manager';
import { createPrivacyManagerLog, editPrivacyManagerLog, deletePrivacyManagerLog } from '../api/change_log';

export const getPrivacyManagerList = actionCreator(
  getPrivacyManagerListAjax,
  privacyManagerListPending,
  privacyManagerListLoaded,
  privacyManagerListError,
);

export const getPrivacyManager = actionCreator(
  getPrivacyManagerAjax,
  privacyManagerListPending,
  privacyManagerLoaded,
  privacyManagerListError,
);

export const createPrivacyManager = actionCreator(
  createPrivacyManagerAjax,
  privacyManagerSavePending,
  privacyManagerCreated,
  privacyManagerListError,
  null,
  createPrivacyManagerLog
)

export const updatePrivacyManager = actionCreator(
  updatePrivacyManagerAjax,
  privacyManagerSavePending,
  privacyManagerUpdated,
  privacyManagerListError,
  null,
  editPrivacyManagerLog
)

export const deletePrivacyManager = actionCreator(
  deletePrivacyManagerAjax,
  privacyManagerListPending,
  privacyManagerDeleted,
  privacyManagerListError,
  null,
  deletePrivacyManagerLog
)

export const clonePrivacyManager = actionCreator(
  clonePrivacyManagerAjax,
  privacyManagerListPending,
  privacyManagerCreated,
  privacyManagerListError,
)

export const getSitePrivacyManagerList = actionCreator(
  getSitePrivacyManagerListAjax,
  sitePrivacyManagerListPending,
  sitePrivacyManagerListLoaded,
  sitePrivacyManagerListError,
);

export const getSiteGroupPrivacyManagerList = actionCreator(
  getSiteGroupPrivacyManagerListAjax,
  sitePrivacyManagerListPending,
  sitePrivacyManagerListLoaded,
  sitePrivacyManagerListError,
);

function privacyManagerListPending() {
  return {
    type: types.PRIVACY_MANAGER_LIST_PENDING,
  };
}

function privacyManagerListLoaded(privacyManagerList) {
  return {
    type: types.PRIVACY_MANAGER_LIST_LOADED,
    privacyManagerList,
  };
}

function privacyManagerListError(errorMessage) {
  return {
    type: types.PRIVACY_MANAGER_LIST_ERROR,
    errorMessage,
  };
}

function privacyManagerLoaded(privacyManager) {
  return {
    type: types.PRIVACY_MANAGER_LOADED,
    privacyManager,
  };
}

function privacyManagerCreated(privacyManager) {
  return {
    type: types.PRIVACY_MANAGER_CREATED,
    privacyManager,
  };
}

function privacyManagerUpdated(privacyManager) {
  return {
    type: types.PRIVACY_MANAGER_UPDATED,
    privacyManager,
  };
}

function privacyManagerDeleted(id) {
  return {
    type: types.PRIVACY_MANAGER_DELETED,
    id,
  };
}

function privacyManagerSavePending() {
  return {
    type: types.PRIVACY_MANAGER_SAVE_PENDING,
  };
}

function sitePrivacyManagerListPending() {
  return {
    type: types.SITE_PRIVACY_MANAGER_LIST_PENDING,
  };
}

function sitePrivacyManagerListLoaded(sitePrivacyManagerList) {
  return {
    type: types.SITE_PRIVACY_MANAGER_LIST_LOADED,
    sitePrivacyManagerList,
  };
}

function sitePrivacyManagerListError(errorMessage) {
  return {
    type: types.SITE_PRIVACY_MANAGER_LIST_ERROR,
    errorMessage,
  };
}
