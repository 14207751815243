import { Map } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const initialState = Map({
  consentReportData: new StoreRecord(),
});

export default (state = initialState, action) => {
  switch(action.type) {
    case types.CONSENT_REPORT_PENDING:
      return setPending(state, ['consentReportData']);

    case types.CONSENT_REPORT_LOADED:
      return setValue(state, ['consentReportData'], action.data);

    case types.CONSENT_REPORT_ERROR:
      return setError(state, ['consentReportData'], action.errorMessage);
  }
  return state;
};
