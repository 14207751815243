import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { EllipsisOutlined, WarningOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Button } from 'antd';
import { VendorList } from '../../../records/vendor_list_records';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import DismissErrorModal from '../../common/DismissErrorModal.jsx';
import { displayErrors } from '../helper';
import IsActive from '../../common/IsActive';
import Loading from '../../common/Loading.js.jsx';

export default class VendorListRow extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    handleDelete: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    clearVendorListError: PropTypes.func.isRequired,
    cloneVendorList: PropTypes.func.isRequired,
    v2: PropTypes.bool.isRequired,
  }

  state = {
    warningMessagePresent: false,
  };

  handleEdit = () => {
    const v2 = this.props.v2 ? '_v2' : '';
    browserHistory.push(`/consent${v2}/vendor_lists/edit?id=` + this.props.vendorList.id);
  };

  handleDelete = (e) => {
    //get rid of then
    e.stopPropagation();
    this.props.handleDelete(this.props.accountId, this.props.vendorList)
      .then((resp) => {
        if (resp.error) {
          this.handleServerSideErrors(resp.error);
        }
        this.setState({ warningMessagePresent: false });
      });
    }

  handleServerSideErrors = (errors) => {
    // at the moment only expecting PRIVACY_MANAGERS_ASSOCIATED type of error
    const errorMessages = errors.map(err => err.msg);

    Modal.warning({
      title: 'Vendor list cannot be deleted',
      content: displayErrors(errorMessages),
      className: 'save-warning',
      onOk: () => { this.props.clearVendorListError(); },
    });
  }

  toggleWarningMessage = (e) => {
    e.stopPropagation();
    this.setState({ warningMessagePresent: !this.state.warningMessagePresent });
  }

  render() {
    let editButtonText;
    let editDropdown
    if (!this.props.readOnly) {
      const editMenu = (
        <Menu className="buttons-group">
          <Menu.Item>
            <div
              className='menu-item-container'
              onClick={ (e) => {
                e.stopPropagation();
                this.props.cloneVendorList(this.props.accountId, this.props.vendorList.id);
              }}
            >
              <CustomIcon
                type={ CustomIcon.types.COPY }
              />
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              onClick={ this.toggleWarningMessage }
              className='menu-item-container'
            >
              <CustomIcon
                type={ CustomIcon.types.DELETE }
                onClick={ this.toggleWarningMessage }
              />
            </div>
          </Menu.Item>
        </Menu>
      );
      editDropdown = (
        <Dropdown
          overlay={ editMenu }
          onOpenChange={ this.handleVisibleChange }
        >
          <EllipsisOutlined
            style={{
              float: 'right',
            }} />
        </Dropdown>
      );
      editButtonText = 'Edit';
    } else {
      editButtonText = 'View';
    }

    const headingMessage = `Are you sure you want to delete ${this.props.vendorList.name} ?`;
    const errorNote = <p>All data and configurations will be lost.</p>;
    const okText = "Delete";
    const cancelText = "Cancel";

    const errorContent = (
      <React.Fragment>
        <div className="error-wrapper">
          <WarningOutlined />
          <div className="message-block">
            {headingMessage}
            <div className="note">{errorNote}</div>
          </div>
        </div>
      </React.Fragment>
    );

    let warningMessage;
    if (this.state.warningMessagePresent) {
       warningMessage = <DismissErrorModal
       modalWrapperClass="operation-confirmation"
       title={null}
       error={errorContent}
       isModalVisible={this.state.warningMessagePresent}
       handleCancel={this.toggleWarningMessage}
       renderOk
       okText={okText}
       cancelText={cancelText}
       handleOk={this.handleDelete}
       primaryAction="submit"
     />
    }

    const activeStatus = (
      <IsActive
        isActive={ this.props.vendorList.isActive }
      />
    );

    let loading;
    if (this.props.pending) {
      loading = <Loading />;
    }

    return (
      <div
        className='vendor-list-container'
        onClick={ this.handleEdit }
      >
        { loading }
        { warningMessage }
        <div className='name-container pm'>
          <p className='name'>{ this.props.vendorList.name }</p>
          { activeStatus }
        </div>
        <div className='menu-container'>
          <Button
            onClick={ this.handleEdit }
            className='edit'
          >
            { editButtonText }
          </Button>
          { editDropdown }
        </div>
      </div>
    );
  }
}
