import React, { useEffect, useState } from "react";
import { EditFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Table, Infotip, TableFilterDropdown } from "../../../../../../styleguide";
import { isArray } from "lodash";
import { modifiyCookieLifeSpan, handleDurationSort} from "../helper";

function reduceCookiesForTableData(cookies) {
    const reducedCookies = cookies?.reduce((acc, obj) => {
      const key = obj["vendorId"];
      const curGroup = acc[key] ?? [];
  
      return { ...acc, [key]: [...curGroup, obj] };
    }, {});
    return reducedCookies;
  }

function populateCookieTableData(reducedCookiesForTableData, selectedGloablVendors) {
  const tableData = [];
  for (const [vendorId, cookies] of Object.entries(reducedCookiesForTableData)){
    const hasError = cookies.length === cookies.filter(cookie => cookie?.errors && Object.values(cookie?.errors).some(err => err)).length
    tableData.push({
        key: vendorId,
        name: cookies.length >= 1 && cookies[0].vendorName,
        disabled: hasError || cookies.length === cookies.filter(cookie => cookie.gvlDefined === true).length,
        vendorIcon: cookies.length >= 1 && cookies[0].vendorIcon,
        errors: cookies.length >= 1 && cookies[0].errors,
        children:  cookies.length >= 1 && cookies.map((cookie)=>({
          ...cookie,
          key: cookie.cookieId,
          name: cookie.name,
          domain: cookie.domain,
          duration: cookie.cookieLifeSpan,
          purpose: cookie.categories,
          disabled: cookie?.errors && Object.values(cookie?.errors).some(err => err) ? true : cookie.gvlDefined === true,
          isChild: true,
          added_via: cookie.added_via,
          vendorId: cookie.vendorId,
          errors: cookie.errors
        }))
      })
    }
  return tableData;
}

const errorMessage = (customErrorMsg) => {
  if(!customErrorMsg) return null;
  return (
    <Infotip
      content={typeof customErrorMsg === "string" ? customErrorMsg : `Empty or invalid data.`}
      trigger="hover"
      placement="bottomRight"
      className="errors-info"
    >
      <ExclamationCircleFilled style={{color: 'red'}}/>
    </Infotip>
  )
}

const CookieTable = (props) => {
  const { cookies, checkedCookieIds,  setCheckedCookieIds, hasAddedViaColum, allCategories, showEditModal, tableId, showManuallyAddedCookies, searchText, isNonIab, defaultExpandedRowKeys } = props;

  const [expandedRowKeys, setExpandedRowKeys]  = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredCookies, setFilteredCookies] = useState(cookies);
  const [filteredPurposes, setFilteredPurposes] = useState([]);
  const [filteredAddedVia, setFilteredAddedVia] = useState([]);
  const [cookiesInTable, setCookiesInTable] = useState([]);

  useEffect(()=>{
    const reducedCookies = reduceCookiesForTableData(showManuallyAddedCookies ? cookies : cookies.filter(c => !c.manuallyAdded));
    setCookiesInTable( !!reducedCookies ? populateCookieTableData(reducedCookies) : null);
  },[cookies])

  useEffect(() => {
    setCheckedCookieIds(selectedRowKeys);
  }, [selectedRowKeys]);

  useEffect(()=>{
    setExpandedRowKeys( defaultExpandedRowKeys ?? [])
  },[])

  useEffect(()=>{
    if(checkedCookieIds.length == 0) {
      setSelectedRowKeys([]);
      return;
    }
    const disabledKeys = cookies.length ? cookies.filter(cookie => cookie?.errors && Object.values(cookie?.errors).some(err => err)).map(c => c.cookieId) : [];
    setSelectedRowKeys((selectedKeys) => selectedKeys.filter( key => !disabledKeys.includes(key)))
  },[cookies])

  useEffect(() => {
    setFilteredCookies(
      cookies.filter((cookie) => {
        const search = searchText.trim();
        let isSearchText = false;
        let isFilteredPurposes = false;
        let isFilteredAddedVia = false;
        if(search === '' || String(cookie.vendorName).toLowerCase().indexOf(search.toLowerCase()) !== -1 || String(cookie.name).toLowerCase().indexOf(search.toLowerCase()) !== -1) {
          isSearchText = true;
        }
        if(filteredPurposes.length === 0 || isArray(cookie.categories) && cookie.categories.some(category => filteredPurposes.includes(category))) {
          isFilteredPurposes = true;
        }
        if(filteredAddedVia.length === 0 || filteredAddedVia.includes(cookie.added_via)){
          isFilteredAddedVia = true;
        }
      return isSearchText && isFilteredPurposes && isFilteredAddedVia
      })
    );
  }, [searchText, filteredPurposes, filteredAddedVia, cookies]);

  useEffect(()=>{
    const reducedCookies = reduceCookiesForTableData(showManuallyAddedCookies ? filteredCookies : filteredCookies.filter(c => !c.manuallyAdded));

    setTableData( !!reducedCookies ? populateCookieTableData(reducedCookies) : null);
  },[filteredCookies])

  const handleAlphabeticalSort = (a, b, key) => { 
    const first = typeof(a[key]) === 'string' ? a[key].toUpperCase() : a[key]?.props?.children[0];
    const second = typeof(b[key]) === 'string' ? b[key].toUpperCase() : b[key]?.props?.children[0];

    if (first < second) return -1;
    if (first > second) return 1;
    return 0;
  }

  const handlePurposeFilter = (purposes) => {
    setFilteredPurposes(purposes);
  }
  const handleAddedViaFilter = (addedVia) => {
    setFilteredAddedVia(addedVia);
  }
  const columns = [
    {
      title: "Vendor/Cookie",
      dataIndex: "name",
      key: "name",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => handleAlphabeticalSort(a, b, 'name'),
      render: (value, record, index) => {
        if (record.isChild) {
          return {
            props: {
              colSpan: 0,
            },
            children: null,
          };
        } else {
          return (
            <div className="cookie-td">
                <span className="cookie-td-content vendor-name" title={value}>{record.vendorIcon}<div>{value}</div>{errorMessage(record.errors?.vendorName)}</span>
                {record?.children?.length ? (
                  <Infotip
                    content={() =>
                      record.children.map((cookie) => (
                        <p key={cookie.key}>{cookie.name}</p>
                      ))
                    }
                    trigger="click"
                    placement="bottomRight"
                    className="vendor-list-wizard"
                  >
                    {" "}
                    <span className="more-tag">{record?.children?.length}</span>
                  </Infotip>
                ) : null}
            </div>
          );
        }
      },
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      width: 170,
      ellipsis: true,
      render: (value, record, index) => {
        if (record.expanded) return null;
        return record?.isChild ? (
          <span className="cookie-td-content">{value}{errorMessage(record.errors?.domain)}</span>
        ) : (
          <div className="cookie-td">
              <span className="cookie-td-content">
                {record?.children && record?.children?.find(c => c.domain?.length)?.domain}
                {errorMessage(record.errors?.domain)}
              </span>
              {record?.children?.length > 1 ? (
                <Infotip
                  content={() =>
                    record.children.map((cookie) => (
                      <p key={cookie.key}>{cookie.domain}</p>
                    ))
                  }
                  trigger="click"
                  placement="bottomRight"
                  className="vendor-list-wizard"
                >
                  {" "}
                  <span className="more-tag">
                    +{record?.children?.length - 1}
                  </span>
                </Infotip>
              ) : null}
          </div>
        );
      },
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      width: hasAddedViaColum ? 200 : 300,
      ellipsis: true,
      key: "purpose",
      filterDropdown :(props)=><TableFilterDropdown {...props} selectAllOption multiple handleSelectedKeysOnSave={handlePurposeFilter}/>, 
      filters: allCategories.toJS().map(c => ({text: c, value: c})),
      render: (value, record, index) => {
        if (record.expanded) return null;
        let allPurposesForVendor = [];
        if(record.children?.length){
          record.children.forEach(cookie => {
           isArray(cookie.purpose) ? allPurposesForVendor.push(...cookie.purpose) : allPurposesForVendor.push(cookie?.purpose?.props?.children[1]);
          })
        }
        allPurposesForVendor = [... new Set(allPurposesForVendor)];
        return record?.isChild ? (
          <div className="cookie-td">
              <span className="cookie-td-content">
                {(value !== undefined && !isArray(value)) && value}
                {value?.length!==0 && value[0]}
                {errorMessage(record.errors?.categories)}
              </span>
              {value.length > 1 ? (
                <Infotip
                  content={() => value.map((purpose) => <p>{purpose}</p>)}
                  trigger="click"
                  placement="bottomRight"
                  className="vendor-list-wizard"
                >
                  {" "}
                  <span className="more-tag">+{value?.length - 1}</span>
                </Infotip>
              ) : null}
          </div>
        ) : (
          <div className="cookie-td">
              <span className="cookie-td-content">
                {allPurposesForVendor?.length ? allPurposesForVendor[0] : null}
                {errorMessage(record.errors?.categories)}
              </span>
              {allPurposesForVendor?.length > 1? (
                <Infotip
                  content={() => {
                    return allPurposesForVendor.map((pur) => <p>{pur}</p>);
                  }}
                  trigger="click"
                  placement="bottomRight"
                  className="vendor-list-wizard"
                >
                  {" "}
                  <span className="more-tag">
                    +{allPurposesForVendor?.length - 1}
                  </span>
                </Infotip>
              ) : null}
          </div>
        );
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: 100,
      // ellipsis: true,
      key: "duration",
      sorter: (a, b) => handleDurationSort(a, b, 'duration'),
      render: (value, record, index) => {
        const updatedValue = value && modifiyCookieLifeSpan(value);
        let updatedChildrenValue = null;
        if(record?.children) {
          updatedChildrenValue = record?.children[0]?.duration ? modifiyCookieLifeSpan(record?.children[0]?.duration) : '';
        }
        if (record.expanded) return null;
        return record?.isChild ? (
          <span className="cookie-td-content">{updatedValue}{errorMessage(record.errors?.cookieLifeSpan)}</span>
        ) : (
          <div className="cookie-td">
              <span className="cookie-td-content">
                {updatedChildrenValue}{errorMessage(record.errors?.cookieLifeSpan)}
              </span>
              {record?.children?.length > 1 ? (
                <Infotip
                  content={() =>
                    record.children.map((cookie) => {
                      const durationType = cookie?.duration?.split(' ')[0]
                      const cookieLifeSpan = durationType === 'session'?durationType:cookie.duration;
                      return (<p key={cookie.key}>{cookieLifeSpan}</p>)
                    })
                  }
                  trigger="click"
                  placement="bottomRight"
                  className="vendor-list-wizard"
                >
                  {" "}
                  <span className="more-tag">
                    +{record?.children?.length - 1}
                  </span>
                </Infotip>
              ) : null}
          </div>
        );
      },
    },
  ];

  if(hasAddedViaColum) {
    columns.push(
      {
        title: "Added via",
        dataIndex: "added_via",
        width: 130,
        // ellipsis: true,
        key: "added_via",
        filters: [
          {
            text: 'Manual',
            value: 'Manual',
          },
          {
            text: 'Scan Results',
            value: 'Scan Results',
          },
          {
            text: 'IAB Declared',
            value: 'IAB Declared',
          },
        ].filter( f => !(isNonIab && f.value === 'IAB Declared')),
        filterDropdown :(props)=><TableFilterDropdown {...props} selectAllOption multiple handleSelectedKeysOnSave={handleAddedViaFilter}/>, 
        sorter: (a, b) => handleAlphabeticalSort(a,b, 'added_via'),
        render: (value, record, index) => {
          if (!record.isChild) return null;
          return <span className="cookie-td-content">{value}</span>
        },
      },
    )
  }
  const rowSelection = {
    selectedRowKeys,
    // onChange: (selectedRowKeys, selectedRows, info) => {
    //   if (info.type !== "all") {
    //     setSelectedRowKeysDuringFilter(selectedRows.filter(d=> !d.disabled && !d.children?.length).map(d => d.key));
    //   }
    // },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if(selected) {
        setSelectedRowKeys((selectedKeys) => [... new Set(selectedKeys.concat(selectedRows.filter(d=> !d.disabled && !d.children?.length).map(d => d.key)))])
      } else {
        setSelectedRowKeys((selectedKeys) =>  {
          if(record.children?.length){
            return selectedKeys.filter(key => !record.children.filter( d => !d.disabled).map(r => r.key).includes(key))
          }
          return selectedKeys.filter(key => key !== record.key)
        })
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        setExpandedRowKeys(tableData.filter(d => d.children?.length).map(d => d.key));
        setSelectedRowKeys((selectedKeys) => [...new Set(selectedKeys.concat(tableData.map(d => [...d.children]).flat().filter(d => !d.disabled).map((d) => d.key)))]);
      } else {
        setSelectedRowKeys((selectedKeys) => selectedKeys.filter(key => !tableData.map(d => [...d.children]).flat().filter(d => !d.disabled).map((d) => d.key).includes(key)));
      }
    },
    renderCell: (checked, record, index, originNode) => {
      if (record.isChild)
        return {
          props: {
            colSpan: 2,
          },
          children: (
            <div className="cookie-td">
                <span style={{ marginLeft: "25px" }}>{originNode}</span>
                <span
                  className="cookie-td-content"
                  style={{ marginLeft: "25px" }}
                >
                  {record.name}{errorMessage(record.errors?.cookieName)}
                </span>
              {record.added_via !== 'IAB Declared' && record.added_via !== 'Scan Results' && <EditFilled className={'cookie-table-edit-icon'} onClick={()=> showEditModal(record,index)} />}
            </div>
          ),
        };
      else return originNode;
    },
  };

  const expandable = {
    onExpand: (expanded, record) => {
      if(expanded) {
        setExpandedRowKeys( [...expandedRowKeys, record.key])
      } else {
        setExpandedRowKeys( expandedRowKeys.filter( key => key !== record.key))
      }
      setTableData(
        tableData.map((d) => {
          if (d.key === record.key) d.expanded = expanded;
          return d;
        })
      );
    },
    expandedRowClassName: () => "cookie-table-expanded-row",
    expandedRowKeys: expandedRowKeys,
    defaultExpandedRowKeys: defaultExpandedRowKeys ?? []
  };

  const rowClassName = (record, index) => {
    if(record.disabled) return 'iab-declared'
    return null;
  }
 
  const totalCookiesCount = cookiesInTable?.reduce((count,current) => count + current?.children?.length,0);

  return (
    <Table
      id= {tableId ? tableId : "cookies-config-table"}
      className="cookie-table"
      columns={columns}
      rowSelection={{ ...rowSelection, checkStrictly: false }}
      dataSource={tableData}
      width={800}
      scroll={{ y: 300 }}
      expandable={expandable}
      rowClassName={rowClassName}
      pagination={{
        position: ["bottomCenter"],
        showTotal: (total) => <div>Total Vendors/Cookies: <b>{total}</b>/<b>{totalCookiesCount}</b></div>,
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '50', '75', '100'],
        locale: { items_per_page: " Vendors per page" },
      }}
    />
  );
};

export default CookieTable;
