

import { Record, List, fromJS, Map } from 'immutable';
import { Standard } from './standard_records';

export class ManualScore extends Record({
  userId: null,
  userName: '',
  dateScored: '',
  scores: new Map({}),
}) {
  constructor(ms) {
    super(fromJS(ms));
  }
}

export class Domain extends Record({
  id: null,
  siteId: null,
  url: '',
  accountIds: List([]),
  impressions: null,
  scores: new Map({}),
  manualScore: new ManualScore(),
  lastScanned: '',
}) {
  constructor(domain) {
    const updatedDomain = Object.assign({}, domain);
    if (domain._id) updatedDomain.id = domain._id;
    if (domain.manualScore) updatedDomain.manualScore = new ManualScore(domain.manualScore);
    super(fromJS(updatedDomain));
  }
}

export class DomainAdmin extends Record({
  id: null,
  siteId: null,
  url: '',
  accountIds: List([]),
  scores: new Map({}),
  lastScanned: '',
  period: '90',
  numDomainSets: 1,
}) {
  constructor(domain) {
    const updatedDomain = Object.assign({}, domain);
    if (domain._id) updatedDomain.id = domain._id;
    super(fromJS(updatedDomain));
  }
}

export class DomainSet extends Record({
  id: null,
  accountId: null,
  name: '',
  description: '',
  domains: List([]),
  standards: List([]),
  dateUpdated: '',
  dateCreated: '',
  domainSetStatus: '',
  activateDate: '',
  deactivateDate: '',
  region: '',
}) {
  constructor(domainSet) {

    const updatedDomainSet = Object.assign({}, domainSet);
    if (domainSet._id) updatedDomainSet.id = domainSet._id;

    if (domainSet.domains) {
      updatedDomainSet.domains = List(domainSet.domains.map(d => new Domain(d)));
    }
    if (domainSet.standards) {
      updatedDomainSet.standards = List(domainSet.standards.map(s => new Standard(s)));
    }
    super(updatedDomainSet);
  }
}