import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { browserHistory } from 'react-router';
import classNames from 'classnames';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import { Site } from '../../../records/site_records';

export default class SitesRedirect extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    pageKind: PropTypes.string.isRequired,
    isForErrors: PropTypes.bool,
    inCampaignTable: PropTypes.bool,
  }

  static defaultProps = {
    isForErrors: false,
    inCampaignTable: false,
  }

  redirectToSite = (id) => {
    let siteGroupId= '';
    if(this.props.siteGroup){
      siteGroupId = `&sitegroup_id=${this.props.siteGroup.id}`;
    }
    browserHistory.push(`/dialogue/${this.props.pageKind}?site_id=${id}${siteGroupId}`);
  }

  render() {
    let title;
    if (!this.props.inCampaignTable) {
      if (this.props.isForErrors) {
        title = <p className='errors'>Sites with errors</p>;
      } else {
        title = <p>All properties</p>;
      }
    }

    const siteClassName = classNames('site', {
      'no-campaign': !this.props.inCampaignTable,
    });

    return (
      <div className='site-group-campaign-summary'>
        {title}
        {this.props.sites.map(site => (
          <div key={site.id} className={siteClassName}>
            {site.domain}
            <CustomIcon
              type={CustomIcon.types.PREVIEW}
              onClick={() => {
                this.redirectToSite(site.id);
                window.scrollTo(0, 0);
              }} />
          </div>
        ))}
      </div>
    )
  }
}
