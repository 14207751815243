import React, { useState,useEffect } from "react";
import {usePrevious} from "../../../hooks";
import { Checkbox,TreeSelect } from '../../../styleguide'
import { getTypes } from './helper';

function CampaignType(props){
  const types = getTypes(props.currentUser, props.site.type);
  const defaultCheckedCampaignTypes = types.map(t => t.value);
  const [ checkedList, setCheckedList ] = useState(defaultCheckedCampaignTypes);
  const previousTypes = usePrevious(defaultCheckedCampaignTypes)

  useEffect(() => {
    if(JSON.stringify(previousTypes) !== JSON.stringify(defaultCheckedCampaignTypes)){
      setCheckedList(defaultCheckedCampaignTypes);
      props.setCampaignTypes(defaultCheckedCampaignTypes);
    }
  }, [defaultCheckedCampaignTypes]);


  const { SHOW_PARENT } = TreeSelect;

  const renderTreeData = ( types ) => {
    let treeData = [];
    let children = [];
    types.map((t,index) => {
      let obj = {
        title: t.label,
        value: t.value,
        key: index,
      }
      children.push(obj)
    })
    let obj = {
        title: "All",
        value: "all",
        key:"all",
        children : children
     };
     treeData.push(obj)

    return treeData;
 }; // Try edit me

  
  const treeData = renderTreeData(types);

  const onChange = list => {
    const checkedlist = list[0] === 'all'? defaultCheckedCampaignTypes: list;
    setCheckedList(checkedlist);
    props.setCampaignTypes(checkedlist);
  };

  const TREE_PROPS = {
    treeData : treeData,
    treeCheckable: true,
    value: checkedList,
    onChange: onChange,
    showCheckedStrategy: SHOW_PARENT,
    maxTagCount: 1,
    dropdownClassName: 'purpose-dropdown',
    showSearch: false,
    showArrow: true,
    treeDefaultExpandAll: true,
    tagRender: (value) => <p className='selected-purpose-tag'>{value.label}</p>,
    maxTagPlaceholder: (value) => value.length && <span className='purpose-count'>+{value.length}</span>,
  }

  return (
    <div className="compliance-dropdown"><span className="dropdown-title">Compliance Type:</span><TreeSelect {...TREE_PROPS}/></div>
  );
};

export default CampaignType;
