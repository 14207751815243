import React from 'react';
import { Map, List, Iterable } from 'immutable';
import { notification } from 'antd';
import { capitalizeFirstLetter } from '../../../../utils';
import { categorize } from '../../util.js';
import { VendorWrapper, CategorizationSchema } from '../../../../../records/vendor_list_records';

function checkIfPurposesExistInOtherStacks(vendorList, newStack, showErrors = true) {
  let intersections = new Map({});
  newStack.categories.forEach((newStackPurpose) => {
    vendorList.categories
      .filter((c) => c.type === 'IAB_STACK')
      .forEach((exisitngStack) => {
        if (!intersections.get(exisitngStack.name)) {
          intersections = intersections.set(exisitngStack.name, new List([]));
        }
        exisitngStack.categories.forEach((existingPurpose) => {
          if (newStackPurpose.iabPurposeRef === existingPurpose.iabPurposeRef) {
            intersections = intersections.update(exisitngStack.name, (purposes) => purposes.push(existingPurpose));
          }
        });
      });
  });

  intersections = intersections.filter((i) => i.size > 0);

  if (intersections.size) {
    let errorMessage = `Cannot add ${newStack.name} stack. Purpose `;
    intersections.forEach((purposes, stackName) => {
      purposes.forEach((purpose, idx) => {
        errorMessage += `${purpose.name}`;
        if (purposes.size > 1) {
          if (idx === purposes.size - 2) {
            errorMessage += ' and ';
          } else if (idx < purposes.size - 2) {
            errorMessage += ', ';
          }
        }
      });
      errorMessage = errorMessage += ` already exist in ${stackName} stack.`;
      if (showErrors) {
        notification.error({
          message: errorMessage,
          duration: null,
        });
      }
    });
    return true;
  } else {
    return false;
  }
}

export function addStack(vendorList, stack, iabPurposes, showErrors = true) {
  if (checkIfPurposesExistInOtherStacks(vendorList, stack, showErrors)) {
    return;
  }

  let categories;
  if (vendorList.categories.size) {
    categories = vendorList.categories;
  } else {
    categories = iabPurposes.map((iabP) => {
      return iabP.set('id', null);
    });
  }

  const filteredCategories = categories.filterNot((existingPurpose) =>
    stack.categories.map((c) => c.iabPurposeRef).includes(existingPurpose.iabPurposeRef)
  );

  const existingCategoriesForStack = categories.filter((existingPurpose) =>
    stack.categories.map((c) => c.iabPurposeRef).includes(existingPurpose.iabPurposeRef)
  );

  const allCategoriesForStack = stack.categories.map((stackCategory) => {
    if (
      existingCategoriesForStack
        .map((existingCategory) => existingCategory.iabPurposeRef)
        .includes(stackCategory.iabPurposeRef)
    ) {
      return existingCategoriesForStack.find((s) => s.iabPurposeRef === stackCategory.iabPurposeRef);
    } else {
      return stackCategory;
    }
  });

  const lastStackIndex = categories.findLastIndex((c) => c.type === 'IAB_STACK');
  const categoriesWithNewStack = filteredCategories.insert(
    lastStackIndex + 1,
    stack.set('categories', allCategoriesForStack)
  );

  return categoriesWithNewStack;
}

function updateCategoryNames(categories, newStacksTcfv22, newPurposesTcfv22) {
  function setCat(cat, newCat) {
    return cat
    .set("name", newCat.name)
    .set("description", newCat.description)
    .set("descriptionLegal", newCat.descriptionLegal)
    .set("illustrations", newCat.illustrations);
  }

  let updatedCategories = categories;
  return updatedCategories.map((category)=>{
    if(category.type === 'IAB_STACK') {
      let newCat = category;
      let stackTcfv22 = newStacksTcfv22.find(s => s.iabStackRef == category.iabStackRef);
      if(stackTcfv22){
        newCat = setCat(category, stackTcfv22)
      }
      newCat = newCat.set('categories', category.categories.map( c => {
        let purposeTcfv22 = newPurposesTcfv22.find((p) => p.iabPurposeRef == c.iabPurposeRef);
        if(purposeTcfv22) {
         return setCat(c, purposeTcfv22)
        }
      }))
      return newCat;
    } else if(category.type === 'IAB_PURPOSE') {
      let purposeTcfv22 = newPurposesTcfv22.find((p) => p.iabPurposeRef == category.iabPurposeRef);
      if(purposeTcfv22) {
       return setCat(category, purposeTcfv22)
      }
    } else {
      return category;
    }
  })
}

function removeLegIntFromCategories3456(categories, iabPurposes, vendorsIndexMap) {
  let updatedCategories = categories;
  return updatedCategories.map( cat => {
    if(cat.categories?.size){
      return cat.set('categories', removeLegIntFromCategories3456(cat.categories, iabPurposes, vendorsIndexMap))
    } else if([3,4,5,6].includes(iabPurposes.find(iabP => iabP.iabPurposeRef === cat.iabPurposeRef)?.iabId)){
        return cat.update('vendorCategorization', (vc) => {
          return vc.map( vCat => {
            if(vCat.type == "LEGITIMATE_INTEREST" && vendorsIndexMap[vCat.vendorId]?.vendorType == 'CUSTOM') {
              return vCat.set('type','CONSENT').set('legalBasisChange', true)
            } else {
              return vCat;
            }
          })
        } )
    } else {
      return cat; 
    }
  });
}

export function addIabDeclaredCookiesToPurpose(vendors, purpose, defaultLegalBasis = 'CONSENT') {
  let categorization = List();
  const vendorsForThisPurpose = vendors.filter(v =>  v.purposes.map(p => typeof p === 'string' ? p : p.id).concat(v.legIntPurposes.map(p => typeof p === 'string' ? p : p.id)).some(id => id === purpose.iabPurposeRef));

  vendorsForThisPurpose.forEach(v => {
    let cookies = v.cookies;
    if(cookies) {
      cookies = cookies.map((ck) => {
        let newCk = Iterable.isIterable(ck) ?  ck.toJS() : ck;
        return {
          ...newCk,
          cookieLifeSpan: ck.cookieLifeSpan,
          key: Math.random(),
          id: Math.random(),
          vendorId: v.id,
          category: purpose.name,
          domain: newCk.domain ?? ''
        };
      });
    }
    const mappedCategorization = categorize(
      purpose,
      {
        id: v.id,
        vendorType: v.vendorType,
        name: v.name,
        purposes: v.purposes.map((p) => typeof p === 'string' ? p : p.id ),
        legIntPurposes: v.legIntPurposes.map((p) => typeof p === 'string' ? p : p.id ),
        cookies,
      },
      defaultLegalBasis
    );
   categorization = categorization.push(mappedCategorization.get('type') === 'NOT_APPLICABLE' ? null : mappedCategorization);
  })
  return categorization;
}

export function getVendorIdsForPurpose(vendors, purpose) {
  return vendors.filter(v => {
    const purposeIdsOfVendor = v.purposes.map(p => typeof p === 'string' ? p : p.id);
    return purposeIdsOfVendor.some(id => id === purpose.iabPurposeRef);
  }).map(v => v.id);
}

export function addPurpose11( vendorList, stacks, purposes, vendors) {
  const indexedVendors = vendorList.vendors.toJS().reduce(function (map, obj) {
    map[obj.id] = obj;
    return map;
  }, {});

  let updatedCategories = updateCategoryNames(vendorList.categories, stacks, purposes);
  updatedCategories = removeLegIntFromCategories3456(updatedCategories, purposes, indexedVendors)  
  const flatCats = flattenCategories(updatedCategories);
  let purpose11 = purposes.find( p => p.iabId == 11);

  //extra check to see if purpose 11 is already part of the VL
  if(flatCats.some( cat => cat.iabPurposeRef == purpose11.iabPurposeRef)){
    //purpose11 is already present in the VL
    return vendorList.set('categories', updatedCategories);
  }
  const purpose11Vendors = vendors.filter(v => (
    v.purposes.concat(v.legIntPurposes)
      .flatMap(p => typeof p === 'string' ? [p] : [p.id])
      .some(id => id === purpose11.iabPurposeRef)
  ));

  purpose11 = purpose11
    .set('id', null)
    .set('vendorIds', purpose11Vendors.map(v => v.id))
    .set('vendorCategorization', addIabDeclaredCookiesToPurpose(purpose11Vendors, purpose11, vendorList.defaultLegalBasis))

  let stackRefsWithPurpose11 =  stacks.filter( s => s.categories.some(cat => cat.iabPurposeRef === purpose11.iabPurposeRef)).map(s => s.iabStackRef);

  // check if there an existing stack which should include purpose 11
  let stackWithPurpose11Idx = vendorList.categories.findIndex( s => stackRefsWithPurpose11.includes(s.iabStackRef));
  if(stackWithPurpose11Idx > -1){
    if(vendorList.categories.get(stackWithPurpose11Idx)?.requireConsent){
      purpose11 = purpose11.set('requireConsent', true) //if stack require for consent, then purpose 11 should be too
    }
    updatedCategories = updatedCategories.update(stackWithPurpose11Idx, (stack) => stack.set('categories', stack.categories.push(purpose11)))
  } else {
    updatedCategories = updatedCategories.push(purpose11)
  }
  return vendorList.set('categories', updatedCategories);
}

export function diffArray(arr1, arr2) {
  function diff(a, b) {
    return a.filter((item) => b.indexOf(item) === -1);
  }

  var diff1 = diff(arr1, arr2);
  var diff2 = diff(arr2, arr1);
  return [].concat(diff1, diff2);
}

export const preDefinedCustomPurposes = [
  {
    key: 'temp1',
    title: 'Necessary Cookies',
    description:
      'These cookies are needed for essential functions such as security network management and accessibility. Standard cookies can’t be switched off.',
    legalBasis: 'DISCLOSURE_ONLY',
    languageTranslations: [
      {
        key: '1a',
        title: 'Necessary Cookies',
        description:
          'These cookies are needed for essential functions such as security network management and accessibility. Standard cookies can’t be switched off.',
        language: 'EN',
      },
      {
        key: '1b',
        title: 'Notwendige Cookies',
        description:
          'Diese Cookies werden für wichtige Funktionen, wie Sicherheit, Netzwerkverwaltung und den Zugriff auf die Webseite benötigt. Standard-Cookies können nicht ausgeschaltet werden.',
        language: 'DE',
      },
      {
        key: '1c',
        title: 'Cookies strictement nécessaires',
        description:
          "Ces cookies sont strictement nécessaires pour opérer des fonctions essentielles du site telles que la gestion de la sécurité réseau ou l'accessibilité. Ces cookies essentiels ne peuvent pas être désactivés.",
        language: 'FR',
      },
    ],
    cookieCategory: [
      {
        "vendorScanCategory": "Strictly Necessary",
        "vendorListCategoryName": "Necessary Cookies",
        "legalBasis": ""
      }
    ],
    type: 'CUSTOM'
  },
  {
    key: 'temp2',
    title: 'Analytics Cookies',
    description:
      'These cookies gather information such as how many users are using our site or which pages are popular to help us improve user experience. Switching off these cookies means we can’t gather information to improve the experience.',
    legalBasis: 'CONSENT',
    languageTranslations: [
      {
        key: '2a',
        title: 'Analytics Cookies',
        description:
          'These cookies gather information such as how many users are using our site or which pages are popular to help us improve user experience. Switching off these cookies means we can’t gather information to improve the experience.',
        language: 'EN',
      },
      {
        key: '2b',
        title: 'Analyse Cookies',
        description:
          'Diese Cookies sammeln Informationen darüber, wie viele Benutzer unsere Webseite besuchen oder welche Seiteninhalte beliebt sind, um die Benutzererfahrung zu verbessern. Wenn du diese Cookies deaktivierst, können wir keine Informationen sammeln, um deine Benutzererfahrung zu verbessern.​',
        language: 'DE',
      },
      {
        key: '2c',
        title: "Mesures d'audience",
        description:
          "Ces cookies recueillent des informations telles que le nombre d'utilisateurs de notre site ou les pages les plus populaires afin d’aider à améliorer l'expérience utilisateur ou mesurer la performance du site. La désactivation de ces cookies signifie que nous ne pouvons pas recueillir ces informations.",
        language: 'FR',
      },
    ],
    cookieCategory: [
      {
        "vendorScanCategory": "Performance",
        "vendorListCategoryName": "Analytics Cookies",
        "legalBasis": ""
      }
    ],
    type: 'CUSTOM'
  },
  {
    key: 'temp3',
    title: 'Advertising',
    description:
      'These cookies are set by us and/or our partners and help us build a profile of your interests based on your browsing profile. If you accept these cookies, you will be shown Coca-Cola advertisements that match your interests as you browse other sites.',
    legalBasis: 'CONSENT',
    languageTranslations: [
      {
        key: '3a',
        title: 'Advertising',
        description:
          'These cookies are set by us and/or our partners and help us build a profile of your interests based on your browsing profile. If you accept these cookies, you will be shown Coca-Cola advertisements that match your interests as you browse other sites.',
        language: 'EN',
      },
      {
        key: '3b',
        title: 'Werbung',
        description:
          'Diese Cookies werden von uns und / oder unseren Partnern gesetzt und helfen uns, ein Profil deiner Interessen basierend auf deinem Browserprofil zu erstellen. Wenn du diese Cookies akzeptierst, werden dir beim Surfen auf anderen Webseiten Coca-Cola-Anzeigen angezeigt, die deinen Interessen entsprechen.',
        language: 'DE',
      },
      {
        key: '3c',
        title: 'Publicités',
        description:
          'Ces cookies sont définis pour nous aider à connaître vos centres d’intérêts en fonction de votre profil de navigation, afin de vous présenter des publicités plus pertinentes. Si vous acceptez ces cookies, les publicités qui vous seront présentées lors de votre navigation sur internet pourront être en lien avec nos produits et services et correspondront plus à vos centres d’intérêts.',
        language: 'FR',
      },
    ],
    cookieCategory: [
      {
        "vendorScanCategory": "Targeting/Advertising",
        "vendorListCategoryName": "Advertising",
        "legalBasis": ""
      }
    ],
    type: 'CUSTOM'
  },
  {
    key: 'temp4',
    title: 'Personalization',
    description:
      'These cookies help us personalize your experience on our site with features like relevant content (e.g. videos) or products you could be interested (e.g. based on what you previously viewed). These cookies will be set by us or by third party providers who add services/features to our sites (e.g. videos).',
    legalBasis: 'CONSENT',
    languageTranslations: [
      {
        key: '4a',
        title: 'Personalization',
        description:
          'These cookies help us personalize your experience on our site with features like relevant content (e.g. videos) or products you could be interested (e.g. based on what you previously viewed). These cookies will be set by us or by third party providers who add services/features to our sites (e.g. videos).',
        language: 'EN',
      },
      {
        key: '4b',
        title: 'Personalisierung Cookies',
        description:
          'Diese Cookies helfen uns dabei, deine Erfahrung auf unserer Webseite mit relevanten Inhalten (z. B. Videos) oder Produkten zu personalisieren, die dich interessieren könnten (z. B. basierend auf dem, was du zuvor angesehen hast). Diese Cookies werden von uns oder von Drittanbietern gesetzt, die unseren Webseiten Dienste / Funktionen hinzufügen (z. B. Videos).​',
        language: 'DE',
      },
      {
        key: '4c',
        title: 'Contenus personnalisés',
        description:
          'Ces cookies nous aident à personnaliser votre expérience sur le site, à vous présenter ou vous recommander des contenus (ex : des vidéos) ou des produits ou services adaptés à votre profil et susceptibles de vous intéresser, ce notamment en fonction de vos habitudes ou votre historique de navigation sur le site.',
        language: 'FR',
      },
    ],
    cookieCategory: [
      {
        "vendorScanCategory": "Targeting/Advertising",
        "vendorListCategoryName": "Personalization",
        "legalBasis": ""
      }
    ],
    type: 'CUSTOM'
  },
];

const frequencies = [
  { label: 'Very Low', value: 'veryLow', className: 'freq-very-low' },
  { label: 'Low', value: 'low', className: 'freq-low' },
  { label: 'Medium', value: 'medium', className: 'freq-medium' },
  { label: 'High', value: 'high', className: 'freq-high' },
  { label: 'Not Seen', value: 'notSeen', className: 'freq-not-seen' },
];

export const FREQUENCY_MAP_LABELS = frequencies.reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {});

export const FREQUENCY_MAP_CLASSNAMES = frequencies.reduce((acc, cur) => ({ ...acc, [cur.value]: cur.className }), {});

export const filtersOptions = {
  frequencySite: frequencies,
  frequencyDataset: frequencies,
  riskScore: [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
  ],
  lastAppearedInScan: [
    { label: 'Last Week', value: 7 },
    { label: 'Last 2 Weeks', value: 14 },
    { label: 'Last Month', value: 30 },
    { label: 'Last Quarter', value: 90 },
    { label: 'Last 6 Months', value: 180 },
    { label: 'Last Year', value: 365 },
  ],
  technology: [
    { label: 'SSP Header', value: 'SSP Header' },
    { label: 'Insight and Analytics', value: 'Insight and Analytics' },
    { label: 'Technology 3', value: 'Technology 3' },
    { label: 'Technology 4', value: 'Technology 4' },
    { label: 'Technology 5', value: 'Technology 5' },
    { label: 'Technology 6', value: 'Technology 6' },
    { label: 'Technology 7', value: 'Technology 7' },
  ],
  infoStorageAccess: [
    { label: 'Cookies', value: 'Cookies' },
    { label: 'Persistent Cookies', value: 'Persistent Cookies' },
    { label: 'Local Storage', value: 'Local Storage' },
    { label: 'Fingerprint', value: 'Finger Printing' },
  ],
  cookiesCategory: [
    { label: 'All', value: 'ALL'},
    { label: 'Performance', value: 'Performance'},
    { label: 'Strictly Neccessary', value: 'Strictly Neccessary'},
    { label: 'Targeting/Advertiseing', value: 'Targeting/Advertiseing'},
    { label: 'Functionality', value: 'Functionality'},
    { label: 'Uncategorised', value: 'Uncategorised'},
  ]
};

export const VENDOR_SCAN_CATEGORIZATION = [
  {
    vendorScanCategory: 'Performance',
    associatedIabPurposesIds: [1, 7, 8, 9, 10],
    legalBasis: 'User Consent',
    legalBasisValue: 'CONSENT',
    cssClass: 'lb-consent',
  },
  {
    vendorScanCategory: 'Strictly Necessary',
    associatedIabPurposesIds: [],
    legalBasis: 'Disclosure Only',
    legalBasisValue: 'DISCLOSURE_ONLY',
    cssClass: 'lb-disclosure',
  },
  {
    vendorScanCategory: 'Targeting/Advertising',
    associatedIabPurposesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    legalBasis: 'User Consent',
    legalBasisValue: 'CONSENT',
    cssClass: 'lb-consent',
  },
  {
    vendorScanCategory: 'Functionality',
    associatedIabPurposesIds: [1],
    legalBasis: 'User Consent',
    legalBasisValue: 'CONSENT',
    cssClass: 'lb-consent',
  },
  {
    vendorScanCategory: 'Uncategorised',
    associatedIabPurposesIds: [1],
    legalBasis: 'User Consent',
    legalBasisValue: 'CONSENT',
    cssClass: 'lb-consent',
  },
];

export const VLW_STEPS = [
  { title: 'Select Properties & Scope', subTitle: null, id: "properties" },
  { title: 'IAB Settings', subTitle: '(Optional)', id: 'iabSettings' },
  { title: 'Custom Purposes Settings', subTitle: '(Optional)', id: 'customPurpose' },
  { title: 'Add Vendors', subTitle: null, id: 'addVendors' },
  { title: 'Add Cookies', subTitle: null, id: 'addCookies' },
];

export const getVCLegalBasisByPurpose = (iabId) => {
  return (
    VENDOR_SCAN_CATEGORIZATION.find((vcc) => {
      return vcc.associatedIabPurposesIds.includes(iabId);
    }) || { legalBasisValue: undefined, vendorScanCategory: undefined }
  );
};

export const calculateCookieAddedVia = (cookie) => {
  return (
    cookie.gvlDefined === true
      ? "IAB Declared"
      : cookie.fromScan === true
      ? "Scan Results"
      : "Manual"
  )
}

function getATPLegalBasisFromGoogleAdProducts(googleAdproductPresent, category, defaultLegalBasis) {
  let scanVCLegalBasis;
  if (
    googleAdproductPresent.purposes.map(({ id }) => id).find((pId) => pId === category.iabPurposeRef)
    && googleAdproductPresent.legIntPurposes.map(({ id }) => id).find((pId) => pId === category.iabPurposeRef)
  ) {
    scanVCLegalBasis = category.defaultLegalBasis || defaultLegalBasis || "CONSENT";
  } else {
    scanVCLegalBasis = googleAdproductPresent.purposes.map(({ id }) => id).find((pId) => pId === category.iabPurposeRef)
      ? "CONSENT"
      : googleAdproductPresent.legIntPurposes.map(({ id }) => id).find((pId) => pId === category.iabPurposeRef)
      ? "LEGITIMATE_INTEREST"
      : "NOT_APPLICABLE";
  }
  return scanVCLegalBasis;
}
export function addVendorsToVl(args) {
  const {
    savedVl,
    vendorList,
    getVendorIdsForPurpose,
    vendors,
    isVendorScanV2Enabled,
    addCookieToDisclosure,
    selectedVendors,
    scannedVendors,
    checkIfVendorScanMapping,
  } = args;
  
  let domainClassfications = {};
  const isGoogleAdproductPresent = vendors.find((vendor) => vendor.iab && vendor.iabId === 755);
  const newVendors = vendors.filterNot((v) => vendorList.vendors.map(oldV => oldV.id).includes(v.id)).map((v) => v.set('isNew', true));
  const oldVendors = vendorList.vendors.map((v) => v.set('isNew', false));
  // const newVendorsIds = newVendors.map((v) => v.id);
  // let existingScannedVendorsIds = [];
  // const oldVendors = vendorList.vendors.map((v) => {
  //   const existingScannedVendor = vendors.find((nv) => nv.id == v.id);
  //   if (existingScannedVendor || selectedVendors.includes(v.id)) {
  //     // existingScannedVendorsIds.push(v.id);
  //     v = v.set('isNew', false);
  //   } else {
  //     v = v.set('isNew', true);
  //   }
  //   return v;
  // });
  const combinedVendors = oldVendors.concat(newVendors);

  let categories = vendorList.categories;
  const v2 = true;

  categories = categories.map(function updateCats(c) {
    const updatedCategory = c
      .set('vendorIds', getVendorIdsForPurpose(c, combinedVendors, v2))
      .update('vendorCategorization', (vc) => {
        const newCategorizations = combinedVendors
          .map((vendor) => {

            //setting legalbasis for new CategorizationSchema OR if old vendor returning old VendorCategorization (object of type CategorizationSchema)
            //if no condition is satisfied then scanVCLegalBasis (legal basis) is kept undefined and is set to NOT_APPLICABLE in the categorize method (from Utils.js) 
            let scanVCLegalBasis;
            if(isGoogleAdproductPresent && vendor.isGoogleAtp) {
              if(c.type === 'CUSTOM') {
                const oldVendorCategorization = vc.find((catg) => vendor.id == catg.vendorId);
                if (oldVendorCategorization) {
                  return oldVendorCategorization;
                }
              } else {
                const atpDefault = vc.find((catg) => catg.vendorId === isGoogleAdproductPresent.id); //look in vendorCategorizatino for GAP
                if (!!atpDefault) { //if found use that to set legal basis
                  if (atpDefault.type != "NOT_ALLOWED") {
                    scanVCLegalBasis = atpDefault.type;
                  } else {
                    scanVCLegalBasis = "NOT_APPLICABLE";
                  }
                } else { // not found then use GAP purpose and legIntPurpose to set legal basis
                  scanVCLegalBasis = getATPLegalBasisFromGoogleAdProducts(isGoogleAdproductPresent, c, vendorList.defaultLegalBasis);
                }
              }
            } else if(vendor.isNew) {
              if(isVendorScanV2Enabled && vendor.vendorType == "CUSTOM" && c.iabId === 1) {
                scanVCLegalBasis = "CONSENT"
              }
            } else {
              const oldVendorCategorization = vc.find((catg) => vendor.id == catg.vendorId);
              if (oldVendorCategorization) {
                return oldVendorCategorization.get('type') === 'NOT_APPLICABLE' ? null : oldVendorCategorization;
              } else if(c.type === 'CUSTOM') {
                return null;
              }
            }

            /*
            At this point, if the vendor is old and its categorization already exists for category 'c', the map('vendors') has already returned.
            So, the map('vendors') code will only reach this point is in the following scenarios,
              1. a new vendor is being added
                1.1 isGoogleAtp and isGoogleAdProductPresent (scanVCLegalBasis != undefined) :
                            NO COOKIES must be added as no GVL defined cookies
                1.2 isGoogleAtp || custom vendor with scanVCLegalBasis = 'CONSENT' for purpose 1 (c.iabId = 1) and scanVCLegalBasis = undefined for rest
                            NO COOKIES must be added as no GVL defined cookies
                1.3 IAB vendor with scanVCLegalBasis = undefined
                            GVL COOKIES must be added to CategorizationSchema (simply vendor.cookies)
              2. an old vendor
                2.1 isGoogleAtp and isGoogleAdProductPresent (scanVCLegalBasis != undefined)
                  2.1.1 categorizatin object FOUND for this category 'c' and vendorId 
                            simply edit the type of categorization object and do not touch cookies i.e. type = scanVCLegalBasis 
                  2.1.2 categorizatin object NOT FOUND for this category 'c' and vendorId
                            NO COOKIES must be added as no GVL defined cookies AND no previously added cookies
                2.2 isGoogleAtp || custom vendor || IAB vendor with no existing categorization for category 'c' and scanVCLegalBasis = undefined
                            NO COOKIES must be added as ATP and CUSTOM vendors do not have GVL defined cookies 
                            and as no old categorization was found for this vendorId then no cookies must have been added previously 
            */
            
            //setting cookies
            let cookies;
            if(vendor.isNew) {
              // 1
              if(vendor.vendorType === 'IAB') {
                // 1.3
                cookies = vendor.cookies
              } else {
                // 1.1 and 1.2
              }
            } else {
              // 2
              if(isGoogleAdproductPresent && vendor.isGoogleAtp) {
                // 2.1
                const oldVendorCategorization = vc.find((catg) => vendor.id == catg.vendorId);
                if (oldVendorCategorization) {
                  // 2.1.1
                  return oldVendorCategorization.set('type', scanVCLegalBasis);
                } else {
                  // 2.1.2
                }
              } else {
                // 2.2
              }
            }

            //creating new CategorizationSchema
            if (cookies) {
              cookies = cookies.map((ck) => {
                let newCk = Iterable.isIterable(ck) ?  ck.toJS() : ck;
                return {
                  ...newCk,
                  cookieLifeSpan: /*'day ' +*/ ck.cookieLifeSpan,
                  key: Math.random(),
                  id: Math.random(),
                  vendorId: vendor.id,
                  category: c.name,
                  added_via: calculateCookieAddedVia(ck)
                };
              });
            }
            const mappedCategorization = categorize(
              c,
              {
                id: vendor.id,
                vendorType: vendor.vendorType,
                name: vendor.name,
                purposes: vendor.purposes.map((p) => typeof p === 'string' ? p : p.id ),
                legIntPurposes: vendor.legIntPurposes.map((p) => typeof p === 'string' ? p : p.id ),
                cookies,
              },
              vendorList.defaultLegalBasis,
              scanVCLegalBasis
            );
            return mappedCategorization.get('type') === 'NOT_APPLICABLE' ? null : mappedCategorization;
            //-----------------------------------------------------
          })
          .filterNot(vendorCategorization => vendorCategorization === null);
        return newCategorizations.toList();
      })
      .update('categories', (cats) => cats.map(updateCats));
    return updatedCategory;
  });
  const newVendorWrappers = newVendors.map((v) => {
    let wrapper = { vendorId: v.id };
    if (addCookieToDisclosure) {
      wrapper.cookies = v.cookies.toJS();
    }
    return new VendorWrapper(wrapper);
  });

  const oldVendorWrappers = vendorList.vendorsWrappers.map((vw) => {
    const scannedVendor = vendors.find((nv) => nv.id == vw.vendorId);
    if (scannedVendor) {
      const filteredCookies = vw.cookies.filterNot((c) => scannedVendor.cookies.find((sc) => sc.name == c.name));
      vw = vw.set('cookies', filteredCookies.concat(scannedVendor.cookies));
    }
    return vw;
  });

  const updatedVendorList = vendorList
    .set(
      'vendors',
      combinedVendors
        .map((v) => {
          if (domainClassfications[v.id]) {
            v = v.set('domainClassification', List(domainClassfications[v.id]));
          }
          return v;
        })
        .sortBy((v) => v.name.toLowerCase())
        .sortBy((v) => v.vendorType)
    )
    .set('categories', categories)
    .set('vendorsWrappers', oldVendorWrappers.concat(newVendorWrappers));

  return updatedVendorList;
}

export const getVendorTypeIcon = (vendor) => {
  let icon;
  if (vendor?.isGoogleAtp) {
    icon = <img key={vendor.id} style={{ width: 10, height: 10, marginRight: 5 }} src="/images/google-icon.svg" />;
  } else if (vendor?.iab || vendor?.isApple) {
    icon = [
      vendor.iab && <i key={vendor.id} className="fas fa-circle" />,
      vendor.isApple && <i key={vendor.id} className="fas fa-circle is-apple-broker" />,
    ];
  }else{
    icon =  <i className="fas fa-circle" style={{color:'#0098FF',width: 10, height: 10,  marginRight: 5}} />
  }
  return icon;
};

export const RULES = {
  "cookieName": [
    {
      required: true,
      message: 'This field cannot be empty.',
    },
  ],
  "vendor": [
    {
      required: true,
      message: 'This field cannot be empty.',
    },
  ],
  "domain" : [
    {
      pattern: /^[a-zA-Z0-9-._~*]*$/i,
      message: 'Invalid format.',
    },
  ],
  "purpose" : [
    {
      required: true,
      message: 'This field cannot be empty.',
    }, 
  ],
  "duration": [
    {
      required: true,
      message: 'This field cannot be empty.',
    },
    {
      type: 'number',
      message: 'Invalid data.',
    },
  ]

}

export function updateCategoryCookies(cookies, categories, vendor, defaultLegalBasis, vendorCategory, vendorScanCategorizations) {
  // let updatedCategories = deleteCookies(cookies, categories, vendorId);
  const {id: vendorId} = vendor;
  let updatedCategories = categories;
  //Adds cookies back
  for (let cookie of cookies) {
    const categoryIndex = updatedCategories.findIndex(({ id, name }) => id ? id === cookie.cookieCategoryId : name === cookie.category);
    if (categoryIndex === -1) {
      updatedCategories = updatedCategories.map(cat => {
        return cat.set('categories', cat.categories.size
          ? updateCategoryCookies([cookie], cat.categories, vendor, defaultLegalBasis, vendorCategory, vendorScanCategorizations)
          : cat.categories)
      });
      continue;
    }
    let donotUpdateLegalBasis = (vendor.vendorType == 'IAB' && updatedCategories.get(categoryIndex).type == "IAB_PURPOSE") || (vendor.vendorType == 'CUSTOM' && updatedCategories.get(categoryIndex).iabId == 1);

    // set legal basis if cookies are added for a particular purpose
    let customLegalBasis;
    if (updatedCategories.get(categoryIndex).type === "CUSTOM") { // custom purpose
      customLegalBasis = updatedCategories.get(categoryIndex).disclosureOnly ? 'DISCLOSURE_ONLY' : updatedCategories.get(categoryIndex).defaultCustomLegalBasis;
    } else { // iab purpose. from cookieCategory a.k.a vendorScanCategorization
      customLegalBasis =
        vendorCategory && vendorCategory.length
          ? vendorScanCategorizations
              .toJS()
              .find(
                (sc) =>
                  (sc.vendorListCategoryId
                    ? sc.vendorListCategoryId === cookie.cookieCategoryId
                    : sc.vendorListCategoryName === cookie.category) &&
                  vendorCategory.includes(sc.vendorScanCategory)
              )?.legalBasis
          : null;
    }

    const vendorCategorizationItemIndex = updatedCategories
      .get(categoryIndex).vendorCategorization
      .findIndex((v) => v.vendorId === vendorId);

    const vendorCategorizationItem = (vendorCategorizationItemIndex !== -1)
      ? updatedCategories
        .get(categoryIndex).vendorCategorization
        .get(vendorCategorizationItemIndex)
      : null;

    if (vendorCategorizationItem && vendorCategorizationItem.cookies) {
      if (vendorCategorizationItem.cookies.findIndex((existingCookie) => {
        let added_via = calculateCookieAddedVia(existingCookie)
        return cookie.name === existingCookie.name && cookie.added_via === added_via
      }) !== -1) {
        continue;
      } else {

        const updatedCookies = vendorCategorizationItem.cookies.push(cookie);
        let updatedVendorCategorizationItem = vendorCategorizationItem.set('cookies', updatedCookies)
        if(!donotUpdateLegalBasis){
          updatedVendorCategorizationItem = updatedVendorCategorizationItem.set('type', customLegalBasis ?? defaultLegalBasis);
        }

        const updatedVendorCategorization = updatedCategories
          .get(categoryIndex).vendorCategorization
          .set(vendorCategorizationItemIndex, updatedVendorCategorizationItem);
        const updatedCategory = updatedCategories
          .get(categoryIndex)
          .set('vendorCategorization', updatedVendorCategorization);
        updatedCategories = updatedCategories.set(categoryIndex, updatedCategory);
      }
    } else {
      const newVendorCategorizationItem = new CategorizationSchema({
        vendorId,
        cookies: List([cookie]),
        type: customLegalBasis ?? defaultLegalBasis,
      });
      const updatedVendorCategorization = updatedCategories
        .get(categoryIndex).vendorCategorization
        .push(newVendorCategorizationItem);
      const updatedCategory = updatedCategories
        .get(categoryIndex)
        .set('vendorCategorization', updatedVendorCategorization);
      updatedCategories = updatedCategories.set(categoryIndex, updatedCategory);
    }
  }

  return updatedCategories;
}

export function deleteCookies(cookiesToDelete, categories, vendorId) {
  let updatedCategories = categories;
  for (let cookie of cookiesToDelete) {
    updatedCategories = updatedCategories.map(category => {
      if (category.categories.size) {
        return category.set('categories', deleteCookies([cookie], category.categories, vendorId));
      } else {
        const updatedVendorCategorizations = category.vendorCategorization.map((vendorCategorizationUnit) => {
          let updatedCookies = vendorCategorizationUnit.cookies || new List([]);
          if (vendorCategorizationUnit.cookies) {
            updatedCookies = vendorCategorizationUnit.cookies.filterNot((existingCookie) => {
              let added_via = calculateCookieAddedVia(existingCookie)
              return ((cookie.added_via && added_via === cookie.added_via) && (cookie.name && existingCookie.name === cookie.name)
              && vendorCategorizationUnit.vendorId === cookie.vendorId)
          });
          }
          return vendorCategorizationUnit.set('cookies', updatedCookies);
        });
        return category.set('vendorCategorization', updatedVendorCategorizations);
      };
    })
  };
  return updatedCategories;
}
export const addCookiesToVl = async (cookies, cookiesToDelete, vendorList, updateVendorList, vendorId) => {
  const { categories, vendorsWrappers, defaultLegalBasis, vendors, vendorScanCategorizations } = vendorList;
  const flatCategories = flattenCategories(categories);
  const indexedVendors = vendors.toJS().reduce(function (map, obj) {
    map[obj.id] = obj;
    return map;
  }, {});

  const updatedCookies = [];
  const updatedCookiesToDelete = [];
  let updatedVendorsWrappers = vendorsWrappers;
  cookies.forEach(cookie => {
    cookie.categories.forEach(category => {
      let updatedCookie = {
        ...cookie,
        category,
        key: cookie.cookieId,
        id: cookie.cookieId,
        gvlDefined: cookie.gvlDefined ?? false,
        fromScan: cookie.fromScan ?? false,
        manuallyAdded: cookie.manuallyAdded ?? false,
        cookieCategoryId: (flatCategories.find(({ name }) => name === category) || { id: '' }).id,
      }
      delete updatedCookie.categories;
      delete updatedCookie.vendorName;
      delete updatedCookie.cookieId;
      updatedCookies.push(updatedCookie);
  })
  })
  const updatedCookiesReduced = updatedCookies?.filter(c => Boolean(indexedVendors[c.vendorId]))?.reduce((acc, obj) => {
    const key = obj["vendorId"];
    const curGroup = acc[key] ?? [];
    acc[key] = [...curGroup, obj];
    return acc;
  }, {});

  //cookies to delte
  if(cookiesToDelete?.length) { 
    cookiesToDelete.forEach(cookie => {
      cookie.categories.forEach(category => {
        let updatedCookie = {
          ...cookie,
          category,
          key: cookie.cookieId,
          cookieCategoryId: (categories.find(({ name }) => name === category) || { id: '' }).id,
        }
        delete updatedCookie.categories;
        delete updatedCookie.vendorName;
        delete updatedCookie.cookieId;
        updatedCookiesToDelete.push(updatedCookie);
      })
    })
  }
  const updatedCookiesToDeleteReduced = updatedCookiesToDelete?.reduce((acc, obj) => {
    const key = obj["vendorId"];
    const curGroup = acc[key] ?? [];
    acc[key] = [...curGroup, obj];
    return acc;
  }, {});


  for (const [vendorId, cookies] of Object.entries(updatedCookiesReduced)){
    let updatedVendorWrapper = vendorsWrappers.find(vendor => vendor.vendorId === vendorId)?.set('cookies', new List(cookies));
    if(updatedVendorWrapper) {
      if (updatedVendorsWrappers.find(vendor => vendor.vendorId === updatedVendorWrapper.vendorId)) {
        const index = updatedVendorsWrappers.findIndex(vendor => vendor.vendorId === updatedVendorWrapper.vendorId);
        updatedVendorsWrappers = updatedVendorsWrappers.set(index, updatedVendorWrapper);
      } else {
        updatedVendorsWrappers = updatedVendorsWrappers.push(updatedVendorWrapper);
      };
    };
  }
  
  let updatedCategories = categories;

  if(cookiesToDelete?.length) {
    for (const [vendorId, cookies] of Object.entries(updatedCookiesToDeleteReduced)){
     updatedCategories = await deleteCookies(cookies, updatedCategories, vendorId)
    }
  }
  for (const [vendorId, cookies] of Object.entries(updatedCookiesReduced)){
    let vendorCategory = indexedVendors[vendorId] && indexedVendors[vendorId]?.vendorAdditionalDetails?.vendorCategory
    updatedCategories = updateCategoryCookies(cookies, updatedCategories, indexedVendors[vendorId], defaultLegalBasis, vendorCategory, vendorScanCategorizations);
  }

  updateVendorList(vendorList.merge({categories: updatedCategories, vendorsWrappers: updatedVendorsWrappers}))
  let updatedVendorList = vendorList.merge({categories: updatedCategories, vendorsWrappers: updatedVendorsWrappers});
  return updatedVendorList;
}

export function flattenCategories(categories = [], flattened = []) {
  return List(categories.reduce((flattened, category) => {
    return category?.categories?.size
      ? [...flattened, ...flattenCategories(category.categories)]
      : [...flattened, category]
  }, flattened));
};

function convertLifeSpanToSeconds(cookieLifeSpan) {
  let number = cookieLifeSpan.split(' ')[1];
  let unit = cookieLifeSpan.split(' ')[0];
  let valueInSeconds = 0;
  switch(unit) {
      case 'minute':
          valueInSeconds = number * 60;
          break;
      case 'hour':
          valueInSeconds = number * 3600;
          break;
      case 'day':
          valueInSeconds = number * 24 * 3600;
          break;
      case 'month':
          valueInSeconds = number * 3600 * 24 * 30;
          break;
      case 'year':
          valueInSeconds = number * 3600 * 24 * 365;
          break;
      case 'session':
        valueInSeconds = 0;
          break;
      default:
        valueInSeconds = 0;
  }
  return valueInSeconds
}

export function modifiyCookieLifeSpan(duration) {
  if(typeof(duration) !== 'string') return duration;
  const value = duration.split(' ');
  const hasNumber = /\d/;   
  hasNumber.test(value[0]);
  let str;
  if(hasNumber.test(value[0])) {
    let val = value[0]?.charAt(0) == 0 ? value[0]?.slice(1) : value[0];
    str = `${val} ${capitalizeFirstLetter(value[1])}(s)`
  }else {
    let val = value[1]?.charAt(0) == 0 ? value[1]?.slice(1) : value[1];
    str = (val!=='')?`${val} ${capitalizeFirstLetter(value[0])}(s)`:''
  }
  return value[0] === 'session' ? 'Session' : str;
}

export const handleDurationSort = (a, b, key) => {
  if(typeof(a[key]) !== 'string' || typeof(b[key]) !== 'string') return 0;

  const first = a[key];
  const second = b[key];
  const num1 = first && convertLifeSpanToSeconds(first);
  const num2 = second && convertLifeSpanToSeconds(second);
  return num1 - num2;
}

export const isGoogleAdproductPresent = (vendors) => {
  const isGoogleAdproductPresent = vendors.find((vendor) => vendor.iab && vendor.iabId === 755) ?? false;
  return isGoogleAdproductPresent
}

export const reduceCookiesForTableDate = (cookies) =>{
  const reducedCookies = cookies.filter(c => !c.gvlDefined)?.reduce((acc, obj) => {
    const key = obj["name"];
    const curGroup = acc[`${key};added_via=${obj['added_via']};vendorId=${obj['vendorId']}`] ?? null;
    if(curGroup) {
      const categories = curGroup.categories ? [...curGroup.categories] : [];
      categories.push(obj.category)
      const newObj = {...curGroup,  categories};
      acc[`${key};added_via=${newObj['added_via']};vendorId=${obj['vendorId']}`] = newObj
      return acc;
    }else {
      const categories = [];
      categories.push(obj.category)
      const ob = {...obj, categories};
      acc[`${key};added_via=${ob['added_via']};vendorId=${obj['vendorId']}`] = ob;
      return acc;
    }

  }, {});

  const reducedCookiesIAB = cookies.filter(c => c.gvlDefined)?.reduce((acc, obj) => {
    const key = obj["name"];
    const curGroup = acc[`${key};vendorId=${obj['vendorId']};domain=${obj['domain'] ?? ''};cookieLifeSpan=${obj['cookieLifeSpan']}`] ?? null;
    if(curGroup) {
      const categories = curGroup.categories ? [...curGroup.categories] : [];
      categories.push(obj.category)
      const newObj = {...curGroup,  categories};
      acc[`${key};vendorId=${obj['vendorId']};domain=${newObj['domain'] ?? ''};cookieLifeSpan=${newObj['cookieLifeSpan']}`] = newObj
      return acc;
    }else {
      const categories = [];
      categories.push(obj.category)
      const ob = {...obj, categories};
      acc[`${key};vendorId=${obj['vendorId']};domain=${ob['domain'] ?? ''};cookieLifeSpan=${ob['cookieLifeSpan']}`] = ob;
      return acc;
    }

  }, {});

  return {...reducedCookies,...reducedCookiesIAB};
}

export const getIabPurposesForGvlVendor = (vendor) => {
  let vendorIabPurposeRefs = [];
  vendor?.purposes?.forEach( p => {
    vendorIabPurposeRefs.push( typeof p === 'string' ? p : p.id )
  })
  vendor?.legIntPurposes?.forEach( p => {
    vendorIabPurposeRefs.push( typeof p === 'string' ? p : p.id )
  })
  vendorIabPurposeRefs = [...new Set(vendorIabPurposeRefs)];
  return vendorIabPurposeRefs;
}
