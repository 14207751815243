import PropTypes from 'prop-types';
import React from 'react';
import { ResponsiveContainer, ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { ConfigProvider, Radio, Select, Table } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { connect } from 'react-redux';
import { List } from 'immutable';
import CSVLink from "../common/CSVLink.js";
import _ from 'lodash';

import { GRAPH_COLORS } from '../../constants.js';
import { getAdblockGraphData } from '../../actions/adblock_actions.js';
import { AdblockRecord, GraphFilters } from '../../records/adblock_records.js';
import Loading from '../common/Loading.js.jsx';
import SiteDropdown from '../common/SiteDropdown.jsx';
import CustomRangePicker from './CustomRangePicker';
import { RangeDates } from '../../records/reports_records.js';
import { getLastWeekDates, getDomainsForSiteAccess } from '../utils';
import { getAllSites } from '../../actions/site_actions';
import { User } from '../../records/account_records';
import { getUserDomains } from '../../actions/account_actions';
import { Headline } from '../../styleguide';


const Option = Select.Option;

const geoFilter = ['ALL', 'US', 'GB', 'PK', 'JP', 'CA', 'FR', 'NL', 'DE', 'NG', 'IN', 'ID', 'TR', 'AU', 'EG', 'BR', 'IT', 'RU', 'MX', 'ES', 'PL', 'SA', 'VN', 'TH', 'SE', 'MY', 'BE', 'GH', 'AR', 'KE', 'PH', 'ZA', 'TW', 'SG', 'AE', 'CO', 'CH'];
let graphData, tableData, newGraphFilters;

export class Adblock extends React.Component {
  static propTypes = {
    graphData: PropTypes.instanceOf(AdblockRecord).isRequired,
    getAdblockGraphData: PropTypes.func.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      clientDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    getAllSites: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const lastWeekDates = getLastWeekDates();
    const newGraphFiltersRecord = new GraphFilters({
      start_date: lastWeekDates.start,
      end_date: lastWeekDates.end,
    });

    this.state = { graphFilters: newGraphFiltersRecord };
  }

  componentDidMount() {
    if (this.props.currentUser) {
      if (!this.props.currentUser.siteAccess) {
        this.props.getAdblockGraphData(this.state.graphFilters);
      }
      this.props.getUserDomains();
      this.props.getAllSites();
    }

  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getUserDomains();
      this.props.getAllSites();
      if (!this.props.currentUser.siteAccess) {
        this.props.getAdblockGraphData(this.state.graphFilters);
        
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.sites.size && newProps.sites.size) {
      let graphFilters = this.state.graphFilters;
      if (this.props.currentUser.siteAccess) {
        graphFilters = graphFilters.set('client_domain', getDomainsForSiteAccess(List(this.props.currentUser.siteAccess), newProps.sites, List(this.props.domains)));
      }
      this.props.getAdblockGraphData(graphFilters);
    }
  }

  updateState = (newGraphFilters) => {
    this.setState({ graphFilters: newGraphFilters }, function () {
      this.props.getAdblockGraphData(this.state.graphFilters);
    });
  };

  selectCountry = (event) => {
    const geoFilter = event === 'ALL' ? [] : event;
    newGraphFilters = this.state.graphFilters.set('geo', geoFilter);
    this.updateState(newGraphFilters);
  };

  selectSites = (event) => {
    if (event === 'all properties') {
      if (this.props.currentUser.siteAccess){
        event = getDomainsForSiteAccess(List(this.props.currentUser.siteAccess), this.props.sites, List(this.props.domains));
      } else {
        event = '';
      }
    } else {
      event = this.props.domains.find(d => d.id === parseInt(event)).name;
    }
    debugger
    newGraphFilters = this.state.graphFilters.set('client_domain', event);
    this.updateState(newGraphFilters);
  };

  selectDates = (dates) => {
    newGraphFilters = this.state.graphFilters.set('start_date', dates.start).set('end_date', dates.end);
    this.updateState(newGraphFilters);
  };

  handleDesktopMobile = (event) => {
    newGraphFilters = this.state.graphFilters.set('traffic_type', event.target.value);
    this.updateState(newGraphFilters);
  };

  handleGranularity = (event) => {
    newGraphFilters = this.state.graphFilters.set('granularity', event.target.value);
    this.updateState(newGraphFilters);
  };

  handleNumberSort = (a, b, key) => {
    return parseFloat(a[key]) - parseFloat(b[key]);
  };

  render() {
    let table,
      graph,
      desktopOrMobile,
      noData,
      granularity,
      exportCSV;

    if (this.props.graphData) {
      tableData = this.props.graphData.tableData.toJS();
      graphData = tableData.map((data) => _.omit(data, 'key'));
      graph = (
        <ResponsiveContainer width='100%' height={395}>
          <ComposedChart
            data={graphData}
            margin={{ top: 40, right: 20, left: 30, bottom: 5 }}
          >
            <Bar
              yAxisId='left'
              dataKey='blockedPVs'
              stackId='pv'
              barSize={20}
              fill={ GRAPH_COLORS.lightBlue }
            />
            <Bar
              yAxisId='left'
              dataKey='nonBlockedPVs'
              stackId='pv'
              barSize={20}
              fill={ GRAPH_COLORS.teal }
            />
            <Line
              yAxisId='right'
              type='monotone'
              dataKey='blockRate'
              stroke={ GRAPH_COLORS.purple }
            />
            <XAxis dataKey="dates" />
            <YAxis yAxisId='left' />
            <YAxis yAxisId='right' orientation='right' />
            <CartesianGrid stroke="#ccc" />
            <Tooltip />
            <Legend />
          </ComposedChart>
        </ResponsiveContainer>
      ); 

      const date = new Date();
      exportCSV = (
        <div className='export-csv-container'>
          <CSVLink
            data={graphData}
            className='export-csv'
            filename={date + ' data.csv'}
            target=""
          >
            Export Data
          </CSVLink>
        </div>
      );

      const dayTitle = this.state.graphFilters.granularity.charAt(0).toUpperCase() + this.state.graphFilters.granularity.slice(1);
      const columns = [{
        title: dayTitle,
        dataIndex: 'dates',
        key: 'dates',
        width: '20%',
        sorter: (a, b) => new Date(a.dates).getTime() - new Date(b.dates).getTime(),
      }, {
        title: 'Blocked PVs',
        dataIndex: 'blockedPVs',
        key: 'blockedPVs',
        width: '20%',
        sorter: (a, b) => this.handleNumberSort(a, b, 'blockedPVs'),
      }, {
        title: 'Non Blocked PVs',
        dataIndex: 'nonBlockedPVs',
        key: 'nonBlockedPVs',
        width: '20%',
        sorter: (a, b) => this.handleNumberSort(a, b, 'nonBlockedPVs'),
      }, {
        title: 'Total PVs',
        dataIndex: 'totalPVs',
        key: 'totalPVs',
        width: '20%',
        sorter: (a, b) => this.handleNumberSort(a, b, 'totalPVs'),
      }, {
        title: 'Ad Block %',
        dataIndex: 'blockRate',
        key: 'blockRate',
        width: '20%',
        sorter: (a, b) => this.handleNumberSort(a, b, 'blockRate'),
      }];
      table = (
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={{ pageSize: 30 }}
        />
      );

      desktopOrMobile = (
        <Radio.Group className='desktop-mobile' onChange={this.handleDesktopMobile} value={this.state.graphFilters.traffic_type}>
          <Radio.Button value='desktop'>Desktop</Radio.Button>
          <Radio.Button value='mobile'>Mobile</Radio.Button>
          <Radio.Button value='total'>Total</Radio.Button>
        </Radio.Group>
      );
      granularity = (
        <Radio.Group className='granularity' onChange={this.handleGranularity} value={this.state.graphFilters.granularity}>
          <Radio.Button value='day'>Day</Radio.Button>
          <Radio.Button value='week'>Week</Radio.Button>
          <Radio.Button value='month'>Month</Radio.Button>
        </Radio.Group>
      );
    } else {
      noData = <h4 className='no-data'>No data to display</h4>;
    }

    let loading;
    if (this.props.pending) {
      loading = <Loading />;
    }

    let domains = List(this.props.domains);

    if (this.props.currentUser.siteAccess) {
      domains = getDomainsForSiteAccess(List(this.props.currentUser.siteAccess), this.props.sites, domains);
    }

    return (
      <div className='metrics-graph'>
        <Headline>Adblock Report</Headline>
        <div id="adblock-report-actions">
          <SiteDropdown
            sites={ domains }
            selectSites={ this.selectSites }
            hasAllAccess={ !this.props.currentUser.siteAccess }
          />
          <div className='external filters'>
            <ConfigProvider locale={enUS}>
              <CustomRangePicker
                selectDates={ this.selectDates }
                disabled={ this.props.pending }
                defaultValue={ new RangeDates({ start: this.state.graphFilters.start_date, end: this.state.graphFilters.end_date}) }
              />
            </ConfigProvider>
            <Select
              className='country-select'
              defaultValue='All Countries'
              style={{ width: 120 }}
              onChange={ this.selectCountry }
            >
              {geoFilter.map(function (country) {
                return <Option key={country} value={country}>{country}</Option>;
              })}
            </Select>
          </div>
        </div>
        <div className='adblock card'>
          <div className='internal filters'>
            { desktopOrMobile }
            { granularity }
          </div>
          { loading }
          { graph }
          { noData }
        </div>
        <div className='data-table card'>
          {table}
          {exportCSV}
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store){
    return {
        currentUser: store.accountState.getIn(['userDetails', 'value']),
        domains: store.accountState.getIn(['domains','value']),
        graphData: store.adblockState.getIn(['graphData', 'value']),
        pending: store.adblockState.getIn(['graphData', 'pending']),
        sites: store.siteState.getIn(['sites', 'value']),
    };
};

export default connect(
    mapStateToProps, {
      getAdblockGraphData,
      getAllSites,
      getUserDomains,
    },
)(Adblock);
