import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { message, Modal, Alert } from "../../../../styleguide"
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List, OrderedSet } from 'immutable';
import {
  getGlobalVendors,
  getGlobalVendorsHash,
  getSitesEnabledForScan,
  getVendorList,
  updateVendorList,
  createVendorList,
  getIABPurposes,
  getIABStacks,
  getIabSpecialFeaturesAndPurposes,
  getVendorLists,
  clearVendorListError,
  deleteVendorList,
  cloneVendorList
} from '../../../../actions/vendor_list_actions_v2.js';
import { getPublisherCmpId } from '../../../../actions/publisher_cmp_ids_actions.js';
import {
  getSiteGroups,
} from '../../../../actions/site_actions.js';
import Loading from '../../../common/Loading.js.jsx';
import {
  VendorList,
  Vendor,
  Purpose,
  VendorListError,
  VendorWrapper,
  CategorizationSchema,
  PurposeV2
} from '../../../../records/vendor_list_records';
import { Site } from '../../../../records/site_records';
import { displayErrors } from '../../helper';
import { CONSENT_SCOPES } from '../../../../constants';
import VendorScanModal from '../VendorScanModal';
import AdvancedSettingsModal from '../AdvancedSettingsModal';
import PublisherPurposesModal from '../PublisherPurposesModal';
import Header from './Header';
import ManageSites from './ManageSites';
import Table from './table/Table';
import { User } from '../../../../records/account_records';
import { categorize, fixUncategorizedVendors } from '../util.js';
import WebsiteVendorScan from '../WebsiteVendorScan';
import { getParameterByName, hasFeature } from '../../../utils.js';
import VendorListWizard from './wizard/VendorListWizard';
import { addStack, addPurpose11 } from './wizard/helper';
import AddVendorsModal from './table/AddVendorsModal';
import AddVendorModalV2 from '../../../common/AddVendorModalV2';
import CloneModal from '../../../common/CloneModal';
import DismissErrorModal from '../../../common/DismissErrorModal';

class VendorListEdit extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList),
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    globalVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor))
      .isRequired,
    globalVendorsHash: ImmutablePropTypes.mapOf(PropTypes.instanceOf(Vendor))
      .isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(PurposeV2))
      .isRequired,
    vendorLists: ImmutablePropTypes.orderedSetOf(
      PropTypes.instanceOf(VendorList)
    ).isRequired,
    vendorListError: PropTypes.instanceOf(VendorListError),
    enabledSitesForScan: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired
    }).isRequired,

    getVendorList: PropTypes.func.isRequired,
    updateVendorList: PropTypes.func.isRequired,
    getGlobalVendors: PropTypes.func.isRequired,
    getGlobalVendorsHash: PropTypes.func.isRequired,
    getIABPurposes: PropTypes.func.isRequired,
    getIABStacks: PropTypes.func.isRequired,
    getIabSpecialFeaturesAndPurposes: PropTypes.func.isRequired,
    createVendorList: PropTypes.func.isRequired,
    clearVendorListError: PropTypes.func.isRequired,
    getSitesEnabledForScan: PropTypes.func.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
    getPublisherCmpId: PropTypes.func.isRequired,
  };

  static defaultProps = {
    vendorListError: null,
    vendorList: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const update = {};

    if (
      !prevState.vlPending &&
      nextProps.pendingRequestsMap.get('vendorList')
    ) {
      update.vlPending = true;
    }

    if (
      prevState.vlPending &&
      !nextProps.pendingRequestsMap.get('vendorList')
    ) {
      update.vl = nextProps.vendorList;
      update.allIabAndAutoUpdate = nextProps.vendorList?.allIabAndAutoUpdate;
      update.vlPending = false;
      update.showChangedVendorsOnly = nextProps.vendorList?.vendorsWithLegalBasisChanges && nextProps.vendorList?.vendorsWithLegalBasisChanges.size > 0;
      update.showDeletedVendorsOnly = nextProps.vendorList?.deletedIabVendors && nextProps.vendorList?.deletedIabVendors.size > 0;
    }

    if (
      !prevState.cmpIdPending &&
      nextProps.pendingRequestsMap.get('publisherCmpId')
    ) {
      update.cmpIdPending = true;
    }

    if (
      prevState.cmpIdPending &&
      !nextProps.pendingRequestsMap.get('publisherCmpId')
    ) {
      update.vl = prevState.vl.set('publisherCmpId2', nextProps.publisherCmpId);
      update.cmpIdPending = false;
    }

    return update;
  }

  createNewVl = () => {
    const autoNameRegex = /(New Vendor List)($| \([0-9]+\))/;
    const autoNameCount = this.props.vendorLists.filter(vl =>
      vl.name.match(autoNameRegex)
    ).size;
    let vendorList = new VendorList({
      onlyCustom: this.isNonIab,
      specialTreatmentP1Geo: List(["EEA"]),
    });
    if (autoNameCount) {
      vendorList = vendorList.set(
        'name',
        `New Vendor List (${autoNameCount + 1})`
      );
    }
    return vendorList;
  };

  getVendorList = () => {
    if (this.props.location.pathname === '/consent_v2/vendor_lists/edit') {
      return this.props.vendorList;
    } else if (
      this.props.location.pathname === '/consent_v2/vendor_lists/create'
    ) {
      return this.createNewVl();
    }
  };

  getWizardState = () => {
    let showWizard = false;
    if (this.props.location.pathname === '/consent_v2/vendor_lists/create') {
      showWizard = true;
    }
    return showWizard;
  }

  state = {
    vl: this.getVendorList(),
    addVendorsOpen: false,
    saveErrorMessage: null,
    allIabAndAutoUpdate: this.props.vendorList
      ? this.props.vendorList.allIabAndAutoUpdate
      : false,
    scanVendorsOpen: false,
    scanningEnabled: false,
    scanningChosen: false,
    advancedSettingsOpen: false,
    originalVendors: null,
    vlPending: false,
    showPublisherPurposeModal: false,
    showChangedVendorsOnly: false,
    cmpIdPending: false,
    showWizard: this.getWizardState(),
    createState: this.props.location?.pathname === '/consent_v2/vendor_lists/create',
    showDeletedVendorsOnly: false,
    activeTab: 'all',
    isSaveExitInCreate: false,
    hasExitedWizard: false,
    cloneVlModalOpen: false,
    deleteVlModalOpen: false,
    visitedSteps: [0],
    purposesToFocus: []
  };

  componentDidMount() {
    this.props.getGlobalVendors();
    this.props.getGlobalVendorsHash();
    this.props.getIABPurposes();
    this.props.getIABStacks();
    this.props.getIabSpecialFeaturesAndPurposes();
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }

    if (prevState.vlPending && !this.state.vlPending) {
      this.setState(({ vl, defaultLegalBasis }) => ({ vl: vl?fixUncategorizedVendors(vl, defaultLegalBasis):null }))
    }
    // need for redirecting from create route to edit
    if (!prevProps.location.query.id && this.props.location.query.id) {
      this.props.getVendorList(
        this.props.location.query.id,
        this.props.currentUser.accountId
      );
    }

    if (
      this.props.location.pathname === '/consent_v2/vendor_lists/create' &&
      !this.props.pendingRequestsMap.get('iabStacks') &&
      !this.props.pendingRequestsMap.get('iabPurposes') &&
      (prevProps.pendingRequestsMap.get('iabStacks') || prevProps.pendingRequestsMap.get('iabPurposes')) &&
      !this.isNonIab
    ) {
      const stack = this.props.iabStacks.find(s => s.iabId === 42);
      
      const categoriesWithStack42 = addStack(this.state.vl, stack, this.props.iabPurposes, false);
      if(categoriesWithStack42) {
        this.setState({
          vl: this.state.vl.set('categories', categoriesWithStack42)
        });
      }
    }

    //tcfv2.2 migration
    if (
      this.props.location.pathname === '/consent_v2/vendor_lists/edit' && this.props.vendorList?.tcfVersion == 2.1 &&
      !this.props.pendingRequestsMap.get('iabStacks') &&
      !this.props.pendingRequestsMap.get('iabPurposes') &&
      !this.props.pendingRequestsMap.get('vendorList') &&
      !this.props.pendingRequestsMap.get('globalVendors') &&
      (prevProps.pendingRequestsMap.get('iabStacks') || prevProps.pendingRequestsMap.get('iabPurposes')  || prevProps.pendingRequestsMap.get('vendorList') || prevProps.pendingRequestsMap.get('globalVendors')) &&
      !this.isNonIab
    ) {
      const globalVendorsInVl = this.props.globalVendors.filter((vendor) => this.props.vendorList.vendors.some( v => v.id == vendor.get('id')))
      let newVl = addPurpose11(this.state.vl, this.props.iabStacks, this.props.iabPurposes, globalVendorsInVl)
      this.setState({
        vl: newVl
      })
    }

    if (prevProps.pendingRequestsMap.get('saveVendorList') && !this.props.pendingRequestsMap.get('saveVendorList')) {
      if (this.props.vendorListError) {
        this.handleServerSideErrors(this.props.vendorListError);
      }
      this.setState({ activeTab: 'all' })
    }
  }

  onCurrentUserAvailableDo = () => {
    this.setState({
      scanningEnabled: this.props.currentUser.accountFeatures.includes(
        'vendor_scanning'
      ),
      scanningChosen: this.props.currentUser.accountFeatures.includes(
        'vendor_scanning'
      ),
    });

    this.props.getSitesEnabledForScan(this.props.currentUser.accountId);
    this.props.getSiteGroups(this.props.currentUser.accountId);

    if (this.props.location.query.id) {
      this.props.getVendorList(
        this.props.location.query.id,
        this.props.currentUser.accountId
      );
    }

    if (!this.props.location.query.id) {
      const accountId = this.props.currentUser.accountId;
      this.props.getPublisherCmpId(accountId);
    }
  }

  parseServerSideError = data => {
    let messages = new List([]);

    if (data.errorType === 'SITE_VALIDATION_FAILED') {
      data.data.forEach(vl => {
        messages = messages.push(
          `Vendor list "${vl.name}" already applies to ${vl.intersection
            .map(id => this.props.sites.find(s => s.id === id).domain)
            .join(' and ')}.`
        );
      });

      messages = messages.push(
        'Please ensure that each site is only used in one vendor list.'
      );
    } else {
      messages = messages.push('This vendor list is invalid');
    }

    return messages;
  };

  // try to do ids and still searchable by name, name + id
  getVendorByName = name => {
    const vendor = this.props.globalVendors.find(v => v.get('name') === name);
    return vendor;
  };

  handleChange = (e, property) => {
    this.setState({
      vl: this.state.vl.set(property, e.target ? e.target.value : e)
    });
  };

  updateState = async (obj) => {
    this.setState(obj);
  };

  updateStateOfVendorList = vl => {
    this.setState({ vl });
  };

  capitalize = s => {
    return s[0].toUpperCase() + s.slice(1);
  };

  handleSave = async () => {
    let errorMessages = new List([]);
    let errorType = '';
    const { state } = this;
    let vl = state.vl
      .set('name', state.vl.name.trim())
      .set('allIabAndAutoUpdate', state.allIabAndAutoUpdate)
      .update('categories', cats =>
        cats.map(function updateCats(cat) {
          return cat
            .update('id', id => (id && id.slice(0, 4) == 'temp') ? null : id)
            .update('categories', cats => cats.map(updateCats))
            .update('vendorCategorization', vCats => {
              return vCats
                .filterNot(({ type }) => type === 'NOT_APPLICABLE')
                .map((vc) => {
                  return (cat.type === 'CUSTOM' && vc.type === 'NOT_ALLOWED')
                    ? categorize(
                      cat,
                      state.vl.vendors.find(({ id }) => vc.vendorId === id),
                      state.defaultLegalBasis
                    )
                    : vc
                })
                .filter(vCat => {
                  //this filter is to remove now invalid categorizations of vendors whose consent options have been removed
                  //see https://sourcepoint.atlassian.net/browse/SP-4894
                  const vendor = state.vl.vendors.find(v => v.id === vCat.vendorId);
                  if (!vendor) return false;
                  if (cat.type === 'IAB_PURPOSE' && vendor.vendorType === 'IAB') {
                    //newly added vendors purposes and legIntpurposes hold objs instead of strings
                    if (typeof vendor.purposes.get(0) === 'object' || typeof vendor.legIntPurposes.get(0) === 'object') {
                      return (vendor.purposes.find(p => p.id === cat.iabPurposeRef) || vendor.legIntPurposes.find(p => p.id === cat.iabPurposeRef))
                    } else {
                      return (vendor.purposes.includes(cat.iabPurposeRef) || vendor.legIntPurposes.includes(cat.iabPurposeRef));
                    }
                  } else {
                    return true;
                  }
                });
            });
        })
      )

    if(this.props.currentUser?.allSiteAccess){
      vl = vl.update('siteIds', (siteIds) => siteIds.filter(id => this.props.sites.map(site => site?.id).includes(id)));
    }

    if (
      this.props.vendorLists
        .filterNot(v => v.id === vl.id)
        .some(v => v.name === vl.name)
    ) {
      errorMessages = errorMessages.push(
        `Vendor list with name "${vl.name}" already exists. Please rename this vendor list.`
      );
      errorType = 'name';
    } else if (!vl.name) {
      errorMessages = errorMessages.push(
        'Vendor list has no name. Please name this vendor list.'
      );
      errorType = 'name';
    }

    if (!vl.vendors.size) {
      errorMessages = errorMessages.push(
        'Vendor list has no vendors. Please add at least one vendor to vendor list.'
      );
    }

    if (vl.isActive && !vl.siteIds.size) {
      errorMessages = errorMessages.push(
        'Active vendor list must have one or more properties.'
      );
    }

    if(vl.appliesGeos.length === 0 || vl?.appliesGeos === ""){
      errorMessages = errorMessages.push('Please select Geo Applies Scope');
    }
    
    if (errorMessages.size) {
      Modal.warning({
        title: 'Vendor list has not been saved',
        content: displayErrors(errorMessages),
        wrapClassName: 'warning-message',
        onOk: () => this.handleSaveErrorModalClose(errorType)
      });
      return;
    }

    if (this.props.location.pathname === '/consent_v2/vendor_lists/create') {
      this.props.createVendorList(this.props.currentUser.accountId, vl).then(resp => {
        if(resp){
          browserHistory.replace(`/consent_v2/vendor_lists/edit?id=${resp.id}&non_iab=${this.isNonIab}`)
          message.success('Your vendor list has been saved.', 5);
          if (resp.isActive) {
            message.success('Vendor list is now active');
          }
        }
      });
    } else {
      const deletedSiteIds = this.props.vendorList.siteIds.filter(
        id => !vl.siteIds.includes(id)
      );

      const deletedSiteDomains = [];
      if (deletedSiteIds.size) {
        deletedSiteIds.forEach((deletedSiteId) => {
          const site = this.props.sites.find(s => s.id === deletedSiteId);
          if(site) deletedSiteDomains.push(site.domain);
        });
      }

      if (this.props.vendorList.siteIds.size && deletedSiteDomains.length > 0) {
        let warningMessage;
        if (deletedSiteDomains.size === 1) {
          warningMessage = `Property ${deletedSiteDomains.first()} will be removed`;
        } else {
          warningMessage = `Properties ${deletedSiteDomains.join(
            ', '
          )} will be removed`;
        }
        Modal.confirm({
          title: 'Comfirm removal of properties',
          content: warningMessage,
          wrapClassName: 'warning-message',
          onOk: () =>
            this.props.updateVendorList(
              this.props.currentUser.accountId,
              vl
            ).then(resp => {
              if (resp) {
                message.success('Your vendor list has been saved.', 5);
              }
            }),
          onCancel: () => {
            this.setState({
              vl: vl.set('siteIds', vl.siteIds.concat(deletedSiteIds))
            });
            return;
          },
          cancelText: 'Cancel and Add Back Properties ',
          okText: 'Confirm and Save'
        });
      } else {
        await this.props.updateVendorList(
          this.props.currentUser.accountId,
          vl
        ).then(resp => {
          if (resp) {
            message.success('Your vendor list has been saved.', 5);
          }
        });
        this.props.getVendorList(
          this.props.location.query.id,
          this.props.currentUser.accountId
        );
      }
    }
  };

  handleServerSideErrors = errors => {
    if(errors?.response?.status === 502){
      message.success('Your vendor list has been saved.', 5);
      return null;
    }

    let errorMessages = null;
    if(List.isList(errors) || Array.isArray(errors)){
      errorMessages =  errors?.map(err => {
        if (err.msg) {
          return err.msg;
        } else {
          return this.parseServerSideError(err);
        }
      });
    }

    
    Modal.warning({
      title: 'Vendor list has not been saved',
      content: errorMessages && displayErrors(errorMessages),
      wrapClassName: 'warning-message',
      onOk: () =>
        this.handleSaveErrorModalClose()
    });
  };

  nameRef = React.createRef();

  handleSaveErrorModalClose = () => {
    this.props.clearVendorListError();
  };

  toggleAddVendors = () => {
    this.setState({ addVendorsOpen: !this.state.addVendorsOpen });
  };

  toggleScanVendors = () => {
    this.setState({ scanVendorsOpen: !this.state.scanVendorsOpen });
  };

  toggleAdvancedSettings = () => {
    this.setState({ advancedSettingsOpen: !this.state.advancedSettingsOpen });
  };

  togglePublisherPurposeModal = () =>
    this.setState({
      showPublisherPurposeModal: !this.state.showPublisherPurposeModal
    });

  getVendorIdsForPurpose = (purpose, vendors, v2 = false) => {
    const nonIABVendorIds = purpose?.vendorIds
      .map(id => this.props.globalVendors.find(v => v.id === id))
      .map(v => v.id);

    return vendors
      .filter(v => {
        const vendorsPurposesIds = v.purposes.map(p =>
          typeof p === 'string' ? p : p.id
        );
        if (v2) {
          return vendorsPurposesIds.some((id) => id === purpose.iabPurposeRef);
        } else {
          return vendorsPurposesIds.some((id) =>
            purpose.iabPurposeRefs.includes(id)
          );
        }
      })
      .map(v => v.id)
      .concat(nonIABVendorIds);
  };

  handleSelectScope = scope => {
    const newVl = this.state.vl.set(
      'consentScope',
      _.invert(CONSENT_SCOPES.toJS())[scope]
    );
    this.setState({
      vl: newVl
    });
  };

  handleSelectSite = siteIds => {
    this.setState({
      vl: this.state.vl.set('siteIds', List(siteIds))
    });
  };

  generateSearchableStringValue(string) {
    if (!string) return '';
    return string + string.toLowerCase();
  }

  toggleIsActive = checked => {
    this.setState({
      vl: this.state.vl.set('isActive', checked)
    });
  };

  toggleShareRootDomain = checked => {
    this.setState({
      vl: this.state.vl.set('shareRootDomain', checked)
    });
  };

  handleCloseScanVendorsModal = () => {
    this.setState({
      scanVendorsOpen: false
    });
  };

  toggleAutoScan = () => {
    this.setState({
      vl: this.state.vl.set(
        'autoUpdateVendorScan',
        !this.state.vl.autoUpdateVendorScan
      )
    });
  };

  removeSite = id => {
    this.setState({
      vl: this.state.vl.update('siteIds', ids =>
        ids.filterNot(idx => idx === id)
      )
    });
  };

  updateVisitedSteps = steps => {
    this.setState({
      visitedSteps: steps
    })
  }

  updatePuposestoFocus = purposes => {
    this.setState((prevState) =>( {
      ...prevState,
      purposesToFocus: purposes
    }))
    const purposeToFocus = purposes[0] ?? null;
    if(purposeToFocus){
      const ele = document.getElementsByClassName(`purpose-${purposeToFocus}`)[0]
      if(ele) ele.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  removeSiteByName = site => {
    const siteId = this.props.sites.find(s => s.domain === site).id;
    this.removeSite(siteId);
  };

  handleLocationSave = (value) => {
    // gdprApplyLocation =
    //   gdprApplyLocation != '' ? gdprApplyLocation.split(',') : List([]);
    // exclusionLocation =
    //   exclusionLocation != '' ? exclusionLocation.split(',') : List([]);

    const newVl = this.state.vl
      .set('appliesGeos', value.appliesGeos)
      .set('exclusionGeos', value.exclusionGeos);
    this.setState({
      vl: newVl
    });
  };

  getVendorsAndCategoriesForToggleIab = (vl, defaultLegalBasis) => {
    const globalVendors = this.props.globalVendors.filter(
      v => v.vendorType === 'IAB'
    );

    let vendors = vl.vendors;
    let newIabVendors = List([]);

    if (!globalVendors.isSubset(vl.vendors)) {
      newIabVendors = globalVendors.filterNot(gV =>
        vl.vendors.map(v => v.id).includes(gV.id)
      );
      vendors = vl.vendors.concat(newIabVendors);
    }

    let categories;
    if (vl.vendors.size) {
      categories = vl.categories;
    } else {
      categories = this.props.iabPurposes.map(iabP => iabP.set('id', null));
    }

    categories = categories.map(c => {
      return c
        .set('vendorIds', this.getVendorIdsForPurpose(c, vendors))
        .update('vendorCategorization', vc => {
          const newCategorizations = newIabVendors
            .map(vendor =>
              categorize(
                c,
                {
                  id: vendor.id,
                  vendorType: vendor.vendorType,
                  purposes: vendor.purposes.map(({ id }) => id),
                  legIntPurposes: vendor.legIntPurposes.map(({ id }) => id)
                },
                defaultLegalBasis
              )
            )
            .filterNot(({ type }) => type === 'NOT_APPLICABLE');
          return vc.concat(newCategorizations);
        });
    });

    return Map({ vendors, categories });
  };

  updatePublisherPurposesConsent = publisherPurposes => {
    const updatedVendorlist = this.state.vl.set(
      'publisherPurposes',
      publisherPurposes
    );
    this.setState({ vl: updatedVendorlist });
  };

  updateVendorCategorization = (type, purposeName, vendorId) => {
    let categories = new List([]);
    this.state.vl.categories.forEach(c => {
      if (c.type === 'IAB_STACK' || c.type === 'CUSTOM_STACK') {
        categories = categories.concat(c.categories);
      } else {
        categories = categories.push(c);
      }
    });

    const categoryIndex = categories.findIndex(
      item => item.name === purposeName
    );
    let category = categories.get(categoryIndex);

    category = category.update('vendorCategorization', vendorCategorization => {
      const vendorCategorizationItemIndex = vendorCategorization.findIndex(
        obj => obj.vendorId === vendorId
      );
      if (vendorCategorizationItemIndex !== -1) {
        vendorCategorization = vendorCategorization.update(
          vendorCategorizationItemIndex,
          vc => {
            return new CategorizationSchema({
              type,
              vendorId,
              cookies: vc.cookies || []
            });
          }
        );
      } else {
        vendorCategorization = vendorCategorization.push(
          new CategorizationSchema({ type, vendorId, cookies: []})
        );
      }

      return vendorCategorization;
    });

    const vendorIds = category.get('vendorIds');

    if (type === 'CONSENT' || type === 'LEGITIMATE_INTEREST') {
      category = category.set('vendorIds', vendorIds.add(vendorId));
    } else {
      category = category.set('vendorIds', vendorIds.delete(vendorId));
    }

    categories = categories.set(categoryIndex, category);

    categories = this.state.vl.categories.map(vlC => {
      if (vlC.type === 'IAB_STACK' || vlC.type === 'CUSTOM_STACK') {
        const stackCategories = vlC.categories;

        return vlC.set(
          'categories',
          stackCategories.map(stackCategory => {
            return categories.find(c => c.name === stackCategory.name);
          })
        );
      } else {
        return categories.find(c => c.name === vlC.name);
      }
    });

    this.setState({
      vl: this.state.vl.set('categories', categories)
    });
  };

  handleAddScannedVendors = (
    scannedVendors = OrderedSet([]),
    scannedSitesIds = List([])
  ) => {
    scannedVendors = scannedVendors.map(scanned => {
      const iabVendor = this.props.globalVendors.find(v => v.id === scanned.id);
      return iabVendor || scanned;
    });
    let categories;
    if (this.state.vl.vendors.size) {
      categories = this.state.vl.categories;
    } else {
      if (this.props.ccpa) {
        categories = new List([
          new Purpose({ name: 'Category 1', type: 'CUSTOM' }),
          new Purpose({ name: 'Category 2', type: 'CUSTOM' }),
          new Purpose({ name: 'Category 3', type: 'CUSTOM' })
        ]);
      } else {
        categories = this.props.iabPurposes.map(iabP => {
          return iabP.set('id', null);
        });
      }
    }

    const listVendorsWithoutRescanned = this.state.vl.vendors.filterNot(v =>
      scannedVendors.map(sv => sv.id).includes(v.id)
    );
    const allVendors = listVendorsWithoutRescanned.concat(scannedVendors);

    if (this.props.ccpa) {
      /// XXX check if it needs to be done
      categories = categories.map(c =>
        c.set('vendorIds', this.getVendorIdsForPurpose(c, allVendors))
      );
    } else {
      categories = categories.map(c => {
        return c
          .set('vendorIds', this.getVendorIdsForPurpose(c, allVendors))
          .update('vendorCategorization', vc => {
            const newCategorizations = allVendors
              .map(vendor =>
                categorize(
                  c,
                  {
                    id: vendor.id,
                    vendorType: vendor.vendorType,
                    name: vendor.name,
                    purposes: vendor.purposes.map(({ id }) => id),
                    legIntPurposes: vendor.legIntPurposes.map(({ id }) => id)
                  },
                  this.state.vl.defaultLegalBasis
                )
              )
              .filterNot(({ type }) => type === 'NOT_APPLICABLE');
            return vc.concat(newCategorizations);
          });
      });
    }

    const newVendorWrappersForScanned = scannedVendors
      .filter(v => v.isNew)
      .map(
        v => new VendorWrapper({ vendorId: v.id, cookies: v.cookies.toJS() })
      );

    const updatedOldVendorWrappers = this.state.vl.vendorsWrappers.map(vw => {
      if (scannedVendors.map(v => v.id).includes(vw.vendorId)) {
        return vw.set(
          'cookies',
          scannedVendors.find(v => v.id === vw.vendorId).cookies
        );
      } else {
        return vw;
      }
    });
    
    const updatedVendorList = this.state.vl
      .set(
        'vendors',
        allVendors.sortBy(v => v.name.toLowerCase()).sortBy(v => v.vendorType)
      )
      .set('categories', categories)
      .set('siteIds', scannedSitesIds.concat(this.state.vl.siteIds).toSet())
      .set(
        'vendorsWrappers',
        updatedOldVendorWrappers.concat(newVendorWrappersForScanned)
      );

    this.setState({
      vl: updatedVendorList,
      scanVendorsOpen: false
    });
  };

  saveVendorCategorization = (vendorScanCategorizations) => {
    const updatedVendorList = this.state.vl.set('vendorScanCategorizations', vendorScanCategorizations);
    this.setState({ vl: updatedVendorList });
  };

  addScannedVendors = (
    scannedVendors = OrderedSet([]),
    scannedSitesIds = List([])
  ) => {
    let categories;
    let updatedVendorsWrappers = this.state.vl.vendorsWrappers;
    if (this.state.vl.vendors.size) {
      categories = this.state.vl.categories;
    } else {
      if (this.props.ccpa) {
        categories = new List([
          new Purpose({ name: 'Category 1', type: 'CUSTOM' }),
          new Purpose({ name: 'Category 2', type: 'CUSTOM' }),
          new Purpose({ name: 'Category 3', type: 'CUSTOM' }),
        ]);
      } else {
        categories = this.props.iabPurposes.map((iabP) => {
          return iabP.set('id', null);
        });
      }
    }
    const listVendorsWithoutRescanned = this.state.vl.vendors.filterNot((v) =>
      scannedVendors.map((sv) => sv.id).includes(v.id)
    ).map((vwc) => vwc.set('cookies', this.state.vl.vendorsWrappers.find(vw => vw.vendorId == vwc.id).cookies));
    const allVendors = listVendorsWithoutRescanned.concat(scannedVendors);
    const vendorIdsWithoutScanning = listVendorsWithoutRescanned.map(v => v.id);
    if (this.props.ccpa) {
      /// XXX check if it needs to be done
      categories = categories.map((c) =>
        c.set('vendorIds', this.getVendorIdsForPurpose(c, allVendors))
      );
    } else {
      const { getVendorIdsForPurpose, state } = this;
      categories = categories.map(function updateCats(c) {
        const v2 = true;
        const updatedCategory = c
          .set('vendorIds', getVendorIdsForPurpose(c, allVendors, v2))
          .update('vendorCategorization', (vc) => {
            const newCategorizations = allVendors
              .map((vendor) => {
                const isVendorWithoutScan = vendorIdsWithoutScanning.includes(vendor.id);
                if (isVendorWithoutScan) {
                  const nonScannedCategorization = vc.find(c => c.vendorId);
                  if (nonScannedCategorization) {
                    return nonScannedCategorization;
                  } else {
                    return categorize(
                      c,
                      {
                        id: vendor.id,
                        vendorType: vendor.vendorType,
                        name: vendor.name,
                        purposes: vendor.purposes.map(({ id }) => id),
                        legIntPurposes: vendor.legIntPurposes.map(({ id }) => id)
                      },
                      state.vl.defaultLegalBasis
                    )
                  }
                }

                let vendorCookies = vendor.cookies.map(ck => { ck.category = c.name; return ck; });
                vendorCookies = vendorCookies.map((vck) => { return { ...vck, vendorId: vendor.id }; });
                const legalBasis = vendor.categorization.find((cl) => cl.get('name') == (c.id || c.name));
                const categorization = categorize(
                  c,
                  {
                    id: vendor.id,
                    vendorType: vendor.vendorType,
                    purposes: vendor.purposes.map(({ id }) => id),
                    legIntPurposes: vendor.legIntPurposes.map(({ id }) => id),
                    cookies: vendorCookies,
                  },
                  state.vl.defaultLegalBasis,
                  legalBasis ? legalBasis.get('defaultLegalBasis') : undefined
                );
                if (!isVendorWithoutScan) {
                  const isVendorExist = updatedVendorsWrappers.find(v => v.vendorId === vendor.id);
                  if (isVendorExist) {
                    const cookies = categorization.type != 'NOT_APPLICABLE' ? vendorCookies : List();
                    updatedVendorsWrappers = updatedVendorsWrappers.update(
                      updatedVendorsWrappers.findIndex((item) => {
                        return item.vendorId === vendor.id;
                      }), (item) => {
                        return item.set("cookies", item.cookies.concat(cookies));
                      });
                  } else {
                    const cookies = categorization.type != 'NOT_APPLICABLE' ? vendorCookies.toJS() : [];
                    const wrapper = new VendorWrapper({ vendorId: vendor.id, cookies });
                    updatedVendorsWrappers = updatedVendorsWrappers.push(wrapper);
                  }
                }
                return categorization;
              })
              .filterNot(({ type }) => type === 'NOT_APPLICABLE');
            const newCategorizationsIds = newCategorizations.map((nc) => nc.vendorId);
            return vc.filterNot((v) => newCategorizationsIds.includes(v.get('vendorId'))).concat(newCategorizations);
          })
          .update('categories', (cats) => cats.map(updateCats));

        return updatedCategory;
      });
    }


    const updatedVendorList = this.state.vl
      .set(
        'vendors',
        allVendors
          .map((v) => v.set('cookies', List([])))
          .sortBy((v) => v.name.toLowerCase())
          .sortBy((v) => v.vendorType)
      )
      .set('categories', categories)
      .set('siteIds', scannedSitesIds.concat(this.state.vl.siteIds).toSet())
      .set('vendorsWrappers', updatedVendorsWrappers);

    this.setState({
      vl: updatedVendorList,
      scanVendorsOpen: false,
    });
  };

  setFilterChangedVendors = (val) => {
    this.setState({ showChangedVendorsOnly: val, showDeletedVendorsOnly: false });
  }

  get isNonIab() { return getParameterByName('non_iab') === 'true'; }

  filterElement = (element, replacement) => this.isNonIab ? replacement : element;

  toggleWizard = () => {
    this.setState((prevState) => ({ showWizard: !prevState.showWizard }));
  }

  setFilterDeletedVendors = (val) => {
    this.setState({ showDeletedVendorsOnly: val, showChangedVendorsOnly: false });
  }

  updateActiveTab = (activeTab) => {
    if (this.state.activeTab == activeTab) { return; }

    let states = { activeTab };
    this.updatePuposestoFocus([]);
    if (activeTab == 'iab-deleted') {
      states.showDeletedVendorsOnly = true;
    } else if (activeTab == 'iab-updated') {
      states.showChangedVendorsOnly = true;
      this.updatePuposestoFocus(this.props.iabPurposes?.map(p => p.iabId)?.toJS());
    }
    this.setState(states);
  }

  calculateAccess = (sites, vendorList) => {
    if (this.props.currentUser?.allSiteAccess || this.state.createState) return false;
    if (!vendorList) return true;

    // GET VENDOR LIST SITES
    const vlSites = vendorList?.siteIds || [];
    const siteSet = new Set(sites);
    let viewOnly = false;
    vlSites.forEach(s => {
      if (!siteSet.has(s)) viewOnly = true;
    });
    return viewOnly;
  };

  handleDelete = (e) => {
    e.stopPropagation();
    let vlName = this.props.vendorList.name;
    this.props.deleteVendorList(this.props.currentUser.accountId, this.props.vendorList.id).then((resp) => {
      if (resp.error) {
        this.handleServerSideErrorsForDelete(resp.error);
      } else {
        message.success(<>Vendor List <b>{vlName}</b> was successfully deleted</>)
        browserHistory.goBack();
      }
      this.setState({ deleteVlModalOpen: false });
    });
  }
  handleServerSideErrorsForDelete = (errors) => {
    // at the moment only expecting PRIVACY_MANAGERS_ASSOCIATED type of error
    const errorMessages = errors.map(err => err.msg);

    Modal.warning({
      title: 'Vendor list cannot be deleted',
      content: displayErrors(errorMessages),
      className: 'save-warning',
      onOk: () => { this.props.clearVendorListError(); },
    });
  }
  handleCopy = (e) => {
    this.setState({cloneVlModalOpen : true})
  }
  onDelete = (e) => {
    this.setState({deleteVlModalOpen : true})
  }
  getVlNameError = (oldVlName, newVlName) => {
    let nameError = null;
    const duplicateName = this.props.vendorLists.map(vl => vl.name).find(vl => vl === newVlName.trim());
    if(newVlName.trim().length === 0){
      nameError = <>Name field can not be empty</>
    }else if(duplicateName && duplicateName !== oldVlName) {
      nameError = <>Vendor List with name '<strong>{newVlName}</strong>' already exists</>
    }
    return nameError; 
  }
  cloneVl = (newVlName) => {
    this.props.cloneVendorList(this.props.currentUser.accountId, this.props.vendorList.id, newVlName).then((vendorList) => {
      if(!!this.props.vendorListError) {
        // message.error(this.props.vendorListError)
        this.handleServerSideErrors(this.props.vendorListError);
      } else {
        browserHistory.replace(`/consent_v2/vendor_lists/edit?id=${vendorList.id}&non_iab=${this.isNonIab}`);
        message.success(<>Vendor List <b>{this.props.vendorList.name}</b> has been successfully cloned</>)
        this.props.getVendorList(
          vendorList.id,
          this.props.currentUser.accountId
        );
      }
    });
    this.setState({cloneVlModalOpen : false})
  }

  render() {

    let sites = this.props.sites;
    let queryParams = new URLSearchParams(window.location.search);
    const vendorListId = queryParams?.get("id")

    if (this.props.currentUser?.siteAccess) {
      sites = sites.filter((s) =>
        this.props.currentUser?.siteAccess.includes(s.id)
      );
    }
    sites = sites.filter(
      (s) =>
        s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')
    ).toJS();
    sites = sites.map(s => s.id);


    if (
      this.props.pendingRequestsMap.some(request => request) ||
      !this.props.sites.size ||
      !this.state.vl ||
      (this.props.location.pathname === '/consent_v2/vendor_lists/edit' &&
        this.props.vendorList &&
        this.props.vendorList.vendors &&
        !this.props.vendorList.vendors.size)
    ) {
      return <Loading />;
    }

    const viewOnlyMode = this.calculateAccess(sites, this.props.vendorList?.toJS());
    const isAccountPartOfWave1 = this.filterElement(hasFeature(this.props.currentUser, 'tcf2.2'), true);
    const userAccess =  this.props.currentUser.featureAccess && !this.props.currentUser.featureAccess.includes('Vendor List - GDPR');
    const readOnly = userAccess || viewOnlyMode || !isAccountPartOfWave1;

    let addVendorsModal;
    if (this.state.addVendorsOpen) {
      addVendorsModal = 
        <AddVendorsModal
          visible={this.state.addVendorsOpen}
          onCancel={() => this.setState({ addVendorsOpen: false })}
          vl={this.state.vl}
          allVendors={this.props.globalVendors}
          getVendorIdsForPurpose={this.getVendorIdsForPurpose}
          currentUser={this.props.currentUser}
          saveVendorList={this.updateState}
          filterElement={this.filterElement}
          isNonIab={this.isNonIab}
        />
    }

    let vendorScanModal;
    if (this.state.scanVendorsOpen && this.props.currentUser.accountFeatures.includes('vendor_scanning_v2')) {
      vendorScanModal = (
        <WebsiteVendorScan
          vendorListId={this.state.vl.id}
          existingSiteIds={this.state.vl.siteIds.filter((id) => this.props.enabledSitesForScan.includes(id))}
          sites={this.props.sites}
          handleAddVendors={this.addScannedVendors}
          handleCloseModal={this.handleCloseScanVendorsModal}
          toggleAutoScan={this.toggleAutoScan}
          autoScanOn={this.state.vl.autoUpdateVendorScan}
          siteAccess={this.props.currentUser.siteAccess}
          scanVendorsOpen={this.state.scanVendorsOpen}
          iabPurposes={this.props.iabPurposes}
          vl={this.state.vl}
          saveVendorCategorization={this.saveVendorCategorization}
          globalVendors={this.props.globalVendors}
          siteGroups={this.props.siteGroups}
        />
      );
    } else if (this.state.scanVendorsOpen && this.props.currentUser.accountFeatures.includes('vendor_scanning')) {
      vendorScanModal = (
        <VendorScanModal
          vendorListId={this.state.vl.id}
          existingSiteIds={this.state.vl.siteIds.filter(id =>
            this.props.enabledSitesForScan.includes(id)
          )}
          sites={this.props.sites}
          sitesForScan={this.props.sites.filter(s =>
            this.props.enabledSitesForScan.includes(s.id)
          )}
          handleAddVendors={this.handleAddScannedVendors}
          handleCloseModal={this.handleCloseScanVendorsModal}
          toggleAutoScan={this.toggleAutoScan}
          autoScanOn={this.state.vl.autoUpdateVendorScan}
          siteAccess={this.props.currentUser.siteAccess}
        />
      );
    }

    let advancedSettingsModal;
    if (this.state.advancedSettingsOpen) {
      advancedSettingsModal = (
        <AdvancedSettingsModal
          vendorList={this.state.vl}
          closeModal={this.toggleAdvancedSettings}
          togglePublisherPurposeModal={this.togglePublisherPurposeModal}
          visible={this.state.advancedSettingsOpen}
          readOnly={readOnly}
          vendorScanningEnabled={this.state.scanningEnabled}
          getVendorsAndCategoriesForToggleIab={
            this.getVendorsAndCategoriesForToggleIab
          }
          updateParentState={this.updateState}
          allIabAndAutoUpdate={this.state.allIabAndAutoUpdate}
          filterElement={this.filterElement}
          currentUser={this.props.currentUser}
        />
      );
    }

    let publisherPurposeModal;
    if (this.state.showPublisherPurposeModal) {
      publisherPurposeModal = (
        <PublisherPurposesModal
          vendorList={this.state.vl}
          closeModal={this.togglePublisherPurposeModal}
          visible={this.state.showPublisherPurposeModal}
          iabPurposes={this.props.iabPurposes}
          updatePublisherPurposesConsent={this.updatePublisherPurposesConsent}
          readOnly={readOnly}
        />
      );
    }

    let deletedVendorDetails;
    if (this.state.vl && this.props.globalVendors) {
      deletedVendorDetails = this.props.globalVendors.filter(gv => this.state.vl.deletedIabVendors.find(v => v.customVendorId == gv.id));
    }
    const updatedVendorIds = this.state.vl.vendorsWithLegalBasisChanges;
    const iabUpdatedVendors = this.state.vl.vendors.filter(v => updatedVendorIds.includes(v.id)).size;

    const deletedVendorIds = this.state.vl.deletedIabVendors.map(v => v.iabVendorId);
    const iabDeletedVendors = this.state.vl.vendors.filter(v => deletedVendorIds.includes(v.id)).size;

    const mainTable = (
      <Table
        vendorList={this.state.vl}
        allVendors={this.props.globalVendorsHash}
        allIabAndAutoUpdate={this.state.allIabAndAutoUpdate}
        toggleAddVendors={this.toggleAddVendors}
        vendorScanningEnabled={this.state.scanningEnabled}
        toggleAutoScan={this.toggleAutoScan}
        readOnly={readOnly}
        updateVendorCategorization={this.updateVendorCategorization}
        iabPurposes={this.props.iabPurposes}
        iabStacks={this.props.iabStacks}
        iabSpecialFeatures={this.props.iabSpecialFeatures}
        globalVendors={this.props.globalVendors}
        updateVendorList={this.updateStateOfVendorList}
        atpEnabled={this.props.currentUser.accountId == 22 || this.props.currentUser.accountFeatures.includes('tcf_v2')}
        showChangedVendorsOnly={this.state.showChangedVendorsOnly}
        filterElement={this.filterElement}
        isAppleMessagingEnabled={this.props.currentUser.accountId == 22 || this.props.currentUser.accountFeatures.includes('apple_messaging')}
        updateActiveTab={this.updateActiveTab}
        activeTab={this.state.activeTab}
        handleAddScannedVendors={this.handleAddScannedVendors}
        deletedVendorDetails={deletedVendorDetails}
        iabUpdatedVendors={iabUpdatedVendors}
        iabDeletedVendors={iabDeletedVendors}
        updateState={this.updateState}
        handleSave={this.handleSave}
        isNonIab={this.isNonIab}
        purposesToFocus={this.state.purposesToFocus}
      />
    );

    let mainContent = mainTable;
    if (
      this.state.vl.vendors.size &&
      !this.state.addVendorsOpen &&
      !this.state.scanVendorsOpen
    ) {
      mainContent = mainTable;
    } else if (!this.state.vl.vendors.size) {
      mainContent = (
        <div className="add-vendors-container">
          <div className="scan-buttons">
            <div
              className="add-vendors-button"
              onClick={this.toggleAddVendors}
              role="presentation"
            >
              <p>+ Add Vendors Manually</p>
            </div>
          </div>
        </div>
      );
    }

    const siteNames = this.props.sites.filter(s => this.state.vl && this.state.vl.siteIds.includes(s.id)).map(s => s.domain);

    const wizard = this.state.showWizard && (
      <VendorListWizard
        currentUser={this.props.currentUser}
        getVendorIdsForPurpose={this.getVendorIdsForPurpose}
        allVendors={this.props.globalVendors}
        visible={this.state.showWizard}
        readOnly={readOnly && !viewOnlyMode}
        vl={this.state.vl}
        sites={this.props.sites}
        siteGroups={this.props.siteGroups}
        siteAccess={this.props.currentUser.siteAccess}
        handleLocationSave={this.handleLocationSave}
        saveVendorList={this.updateState}
        iabSpecialFeatures={this.props.iabSpecialFeatures}
        iabPurposes={this.props.iabPurposes}
        iabStacks={this.props.iabStacks}
        onCancel={this.toggleWizard}
        hasExitedWizard={this.state.hasExitedWizard}
        isSaveExitInCreate={this.state.isSaveExitInCreate}
        updateVisitedSteps={this.updateVisitedSteps}
        visitedSteps={this.state.visitedSteps}
        filterElement={this.filterElement}
        isNonIab={this.isNonIab}
        />
    );

    const cloneVlModal = this.state.cloneVlModalOpen
    ? <CloneModal
        type="vendor-management"
        cloneMethod={this.cloneVl}
        name={this.props.vendorList.name}
        closeModal={ () => this.setState({cloneVlModalOpen: false})}
        getNameError={this.getVlNameError}
        entity={"Vendor List"}
        entityType={"Inactive"}
      />
    : null;
    const deleteVlModal = this.state.deleteVlModalOpen
    ?  <DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title={"Vendor List - Delete"}
        error={
        <div className="error-wrapper">
          <div className="message-block">
          Once deleted, all associated data with <b>{this.props.vendorList.name}</b> will be deleted permanently and cannot be retrieved at all.
            <div className="note"> Are you sure you want to <b>DELETE</b> ?</div>
          </div>
        </div>
        }
        isModalVisible={this.state.deleteVlModalOpen}
        handleCancel={() => this.setState({deleteVlModalOpen : false})}
        renderOk
        okText={"Delete"}
        cancelText={"Cancel"}
        handleOk={this.handleDelete}
        primaryAction="submit"
      />
    : null;

    let viewOnlyModeAlert = userAccess ? [<><b>View Only:</b> User does not has access to GDPR Vendor Lists</>] : viewOnlyMode ? [<><b>View Only:</b> Access to this vendor list is partially restricted due to this account not having access to ALL sites in this specific vendor list.</>] : [];
    if(!isAccountPartOfWave1){
      viewOnlyModeAlert.splice(0,0, <><b>View Only:</b> Edit access to this vendor list is restricted since this account is not enabled for the TCFV2.2 transition.</>)
    };

    return (
      <div className="privacy-manager-container">
        { readOnly ? (
          <div className="view-only-mode">
           {readOnly ? viewOnlyModeAlert.map( m => <><Alert style={{marginBottom: '10px'}} type="error" message={m} showIcon /><br/></>) : null}
          </div>
        ) : null}
        <Header
          route={this.props.route}
          vl={this.state.vl}
          dateBaseTcfVersion={this.props.vendorList ? this.props.vendorList.tcfVersion : 2.1}
          id={vendorListId}
          handleChange={this.handleChange}
          handleSelectScope={this.handleSelectScope}
          handleSave={this.handleSave}
          handleLocationSave={this.handleLocationSave}
          toggleIsActive={this.toggleIsActive}
          toggleShareRootDomain={this.toggleShareRootDomain}
          readOnly={readOnly}
          v2={this.props.route.v2}
          filterElement={this.filterElement}
          siteNames={siteNames}
          iabSpecialFeatures={this.props.iabSpecialFeatures}
          iabSpecialPurposes={this.props.iabSpecialPurposes}
          iabFeatures={this.props.iabFeatures}
          toggleWizard={this.toggleWizard}
          updateState={this.updateState}
          toggleAdvancedSettings={this.toggleAdvancedSettings}
          showWizard={this.state.showWizard}
          isSaveExitInCreate={this.state.isSaveExitInCreate}
          handleCopy={this.handleCopy}
          onDelete={this.onDelete}
          activeTab={this.state.activeTab}
          updateActiveTab={this.updateActiveTab}
          isNonIab={this.isNonIab}
          updatePuposestoFocus={this.updatePuposestoFocus}
          iabPurposes={this.props.iabPurposes}
          iabDeletedVendors={iabDeletedVendors}
          iabUpdatedVendors={iabUpdatedVendors}
        />

        <ManageSites
          vl={this.state.vl}
          sites={this.props.sites}
          siteIds={sites}
          toggleScanVendors={this.toggleScanVendors}
          handleSelectSite={this.handleSelectSite}
          removeSiteByName={this.removeSiteByName}
          readOnly={readOnly}
          filterVendorList={this.filterVendorList}
          siteGroups={this.props.siteGroups}
          siteAccess={this.props.currentUser.siteAccess}
          vendorScanning={this.props.currentUser.accountFeatures.includes(
            'vendor_scanning'
          )}
          vendorScanningV2={this.props.currentUser.accountFeatures.includes('vendor_scanning_v2')}
          setFilterChangedVendors={this.setFilterChangedVendors}
          filterElement={this.filterElement}
          isAppleMessagingEnabled={this.props.currentUser.accountId == 22 || this.props.currentUser.accountFeatures.includes('apple_messaging')}
          setFilterDeletedVendors={this.setFilterDeletedVendors}
        />

        {mainContent}

        {vendorScanModal}
        {addVendorsModal}
        {advancedSettingsModal}
        {publisherPurposeModal}
        {wizard}
        {cloneVlModal}
        {deleteVlModal}
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  const vendorLists = store.vendorListState.getIn(['vendorLists', 'value']);
  const vendorListId = getParameterByName('id');
  const sites = store.siteState.getIn(['sites', 'value']);
  let vendorList;
  if (vendorListId && vendorLists.size && sites.size) {
    vendorList = vendorLists.find(vl => vl.id === vendorListId);
  }

  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    vendorList,
    vendorLists: store.vendorListState.getIn(['vendorLists', 'value']) ?? List([]),
    vendorListError: store.vendorListState.getIn(['vendorLists', 'error']),
    globalVendors: store.vendorListState.getIn(['globalVendors', 'value']) ?? OrderedSet([]),
    globalVendorsHash: store.vendorListState.getIn([
      'globalVendorsHash',
      'value'
    ]),
    iabPurposes: store.vendorListState.getIn(['purposes', 'value']) ?? List([]),
    iabStacks: store.vendorListState.getIn(['stacks', 'value']) ?? List([]),
    iabSpecialFeatures: store.vendorListState.getIn([
      'iabSpecialFeatures',
      'value'
    ]) ?? List([]),
    iabSpecialPurposes: store.vendorListState.getIn([
      'iabSpecialPurposes',
      'value'
    ]) ?? List([]),
    iabFeatures: store.vendorListState.getIn([
      'iabFeatures',
      'value'
    ]) ?? List([]),
    sites,
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    enabledSitesForScan: store.vendorListState.getIn([
      'enabledSitesForScan',
      'value'
    ]) ?? List([]),
    publisherCmpId: store.publisherCmpIdsState.getIn(['publisherCmpId', 'value']),
    pendingRequestsMap: new Map({
      vendorList: store.vendorListState.getIn(['vendorLists', 'pending']),
      saveVendorList: store.vendorListState.get('savePending'),
      globalVendors: store.vendorListState.getIn(['globalVendors', 'pending']),
      globalVendorsHash: store.vendorListState.getIn([
        'globalVendorsHash',
        'pending'
      ]),
      iabPurposes: store.vendorListState.getIn(['purposes', 'pending']),
      iabStacks: store.vendorListState.getIn(['stacks', 'pending']),
      iabSpecialFeatures: store.vendorListState.getIn([
        'iabSpecialFeatures',
        'pending'
      ]),
      sites: store.siteState.getIn(['sites', 'pending']),
      publisherCmpId: store.publisherCmpIdsState.getIn(['publisherCmpId', 'pending']),
    })
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getGlobalVendors,
      getGlobalVendorsHash,
      getVendorList,
      getIABPurposes,
      getIABStacks,
      getIabSpecialFeaturesAndPurposes,
      updateVendorList,
      createVendorList,
      getVendorLists,
      clearVendorListError,
      getSitesEnabledForScan,
      getSiteGroups,
      getPublisherCmpId,
      deleteVendorList,
      cloneVendorList
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorListEdit);
