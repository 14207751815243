import React from 'react';
import { Dropdown, Menu, Popover } from 'antd';
import { EllipsisOutlined, CopyOutlined } from '@ant-design/icons';

const StandardsCard = ({ standard, standardScores, editStandard, copyStandard, moveToDraft, publishStandard, deleteStandardModal, showCards, domainSets, type, openStandardModal, accountId, readOnly }) => {

  const editMenu = (
    <Menu className="buttons-group" style={{ zIndex: 100 }}>
      <Menu.Item className='menu-item-standard'
        onClick={() => editStandard(standard)}>
        { readOnly ? 'View' : 'Edit' }
      </Menu.Item>
      { readOnly ? null :
        <Menu.Item className='menu-item-standard'
          onClick={() => copyStandard(standard)}>
          Clone
        </Menu.Item>
      }
      { standard.isActive && !readOnly ? 
        <Menu.Item className='menu-item-standard'
          onClick={() => moveToDraft(standard)}>
          Move to Drafts
        </Menu.Item> : 
        null 
      }
      { readOnly ? null :
        <Menu.Item className='menu-item-standard'
          onClick={() => deleteStandardModal(standard)}>
          Delete
        </Menu.Item>
      }
    </Menu>
  );

  const copyIcon = (
    <div className='copy-icon-card'>
    <CopyOutlined 
      onClick={() => copyStandard(standard)}
    /><span className='test'>copy</span>
    </div>
  );

  return (
    <div className={"standards-card " + type}
      style={{
        display: showCards ? 'block' : 'none', border: '1px solid #D4D4D4'
      }} >
      <div className="standards-card-header"
        style={{ background: type === 'account' ? '#E9DFFF' : '#6565A9', color: type === 'account' ? ' #2D2D2D' : 'white', display: type === 'account' ? 'block' : 'flex' }}
      >
       <div className='title-container flex-row'>
          <div className="standards-card-title" title={standard.name}>{standard.name}</div>
          <div className='flex-row'>
          { standard.isActive || readOnly ? 
            null: 
            <div onClick={() => publishStandard(standard)} className='activate-button'>Publish</div>
          }
        {type === 'account' ? (
          <Dropdown
            overlay={editMenu}
            className='standard-menu-container'
          >
            <EllipsisOutlined
              style={{
                float: 'right',
                transform: 'rotate(90deg)'
              }} />
          </Dropdown>) : copyIcon
        }
        </div>
      </div>
        {/* <img src={flagTest} alt="flag" className="flag-img" /> */}
      </div>
      <div className="standards-card-body" onClick={() => openStandardModal(standard)}>
        <div className="property-applied">
          Property Sets this standard is applied to:
          {standard.appliedToDomainSets && standard.appliedToDomainSets.length ? (<div className='sets-container'>
            {standard.appliedToDomainSets.length <= 2 ? <div><span>{
            standard.appliedToDomainSets.join(', ')}</span></div> : <Popover content={<ul>{standard.appliedToDomainSets.map(set => (<li>{set}</li>))}</ul>} overlayClassName="more-property-sets-popover" getPopupContainer={()=>document.getElementById("avocado")}><span>
              {standard.appliedToDomainSets.slice(0, 2).join(', ')}<span className="extra-hidden-properties"> <strong className="bold-number"> +{standard.appliedToDomainSets.slice(2, standard.appliedToDomainSets.length).length}</strong></span></span></Popover>}
          </div>) : (
            <div className="no-property">
               <img src={require('../../../assets/images/privacy-lens/warning-icon.svg')}
                alt="warning"
              />
              <span> Not applied to any property set yet</span>
            </div>)
          }
        </div>
      </div>
    </div>
  )
}

export default StandardsCard;