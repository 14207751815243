import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Provider } from 'react-redux';
import { unregisterAllWorkers } from './registerServiceWorker.js';
import store from './store';
import { ConfigProvider, List } from 'antd';

unregisterAllWorkers();

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        cssVar: { key: 'avocado' },
        hashed: false,
        token: {
          // Seed Token
          fontFamily: "Open Sans",
          colorPrimary: "#4f28b3",
          colorInfo: "#4f28b3",
          colorSuccess: "#36b44a",
          colorError: "#c02822",
          colorTextBase: "#272727",
          colorText: "#272727",
          colorTextSecondary: "#727272",
          colorTextTertiary: "#8f8f8f",
          colorTextQuaternary: "#c9c9c9",
          colorBorder: "#d3d9dd",
          colorBorderSecondary: "#e9e9e9",
          colorFill: "#dfdfdf",
          colorFillSecondary: "#f2f2f2",
          colorFillTertiary: "#f7f7f7",
          colorFillQuaternary: "#FBFBFB",
          colorBgSpotlight: "#474747",
          controlItemBgActive: "#EAE4F2",
          controlItemBgActiveDisabled: "#DFDFDF",
          controlItemBgActiveHover: "#D3C5E6",
          controlItemBgHover: "#F7F7F7",
          controlOutline: "#ECE8F3",
          controlTmpOutline: "#FBFBFB",
          colorSuccessTextHover: "#236625",
          colorSuccessText: "#236625",
          colorSuccessTextActive: "#236625",
          colorErrorTextHover: "#ab231f",
          colorErrorText: "#ab231f",
          colorErrorTextActive: "#ab231f",
          wireframe: true,
        },
        components: {
          Modal: {
            titleFontSize: '20px',
            titleLineHeight: 1.4,
            paddingLG: 16
          },
          Tag : {
            fontSizeSM: 14,
            marginXS: 12
          },
          Layout: {
            bodyBg: "#fff"
          },
          Menu: {
            itemBorderRadius: 0,
            itemHeight: 64,
            itemSelectedColor: '#272727'
          },
          Divider: {
            verticalMarginInline: 12,
            colorSplit: "#d3d9dd"
          },
          Typography: {
            titleMarginBottom: 0,
          },
          Tree: {
            colorBgTextHover: "rgba(242,242,242,0.60)"
          },
          Button: {
            dangerShadow: 0,
            defaultShadow: 0,
            primaryShadow: 0
          }
        }
      }}
    >
        <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById("avocado")
);