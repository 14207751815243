import React, { Component } from 'react';
import { Modal, Select, message } from 'antd';

const { Option } = Select;

class SaveTemplate extends Component {
  state = {
    type: undefined,
    visible: true
  }

  isGdprNotice = this.props.messageCategory === 'gdpr' && this.props.messageSubcategory === 'notice';

  componentDidMount() {
    const { messageCategory, messageSubcategory } = this.props;
    if (messageCategory === 'ccpa') {
      switch (messageSubcategory) {
        case 'ccpa_pm':
          return this.setState({ type: 'ccpa-ccpa_pm' });
        case 'dsarRetrieval':
          return this.setState({ type: 'dsar' });
        case 'notice':
          return this.setState({ type: 'ccpa' });
        case 'ccpa_ott':
          return this.setState({type: 'ccpa_ott'})
      }
      this.setState({ type: messageSubcategory });
    } else if (messageCategory === 'usnat' && messageSubcategory === 'notice') {
      return this.setState({ type: 'usnat_notice' });
    } else {
      switch (messageSubcategory) {
        case 'privacy-manager':
          return this.setState({ type: 'gdpr-tcfv2-privacy-manager' });
        case 'us_pm':
            return this.setState({ type: 'usnat-us_pm' });
        case 'privacy-manager-ott':
          return this.setState({ type: 'gdpr-tcfv2-privacy-manager-ott' });
        case 'ios':
          return this.setState({ type: 'ios14-ios' });
        case 'custom':
          return this.setState({ type: 'custom-custom' });
        case 'notice-tcf-v2':
          return this.setState({ type: 'gdpr-tcfv2-notice' });
        case 'notice_non_iab':
          return this.setState({ type: 'gdpr-notice_non_iab' });
        case 'usnat_notice':
          return this.setState({ type: 'usnat_notice' });
        case 'privacy_manager_non_iab':
          return this.setState({ type: 'gdpr-privacy_manager_non_iab' });
      };
    };
  }

  hideModal = () => this.setState({ visible: false })

  saveTemplate = () => {
    if (this.state.type) {
      this.props.createTemplate(this.state.type);
      this.hideModal();
    } else {
      return message.error('Type is required to save a template');
    }
  }

  render() {
    return (
      <Modal
        title="Save as a Template"
        className="save-template-modal"
        visible={this.state.visible}
        onOk={this.saveTemplate}
        onCancel={this.hideModal}
        afterClose={this.props.removeModal}
        destroyOnClose={true}
        centered
      >
        <div>
          <p>
            {`Would you like to save this message as a template so you can re-use it later?`}
            {this.isGdprNotice && 'Please select a template type below to save as a template:'}
          </p>
          {this.isGdprNotice && (
            <Select value={this.state.type} size="large" onChange={type => this.setState({ type })} placeholder="Select a type...">
              <Option value="adblock">Adblock Template</Option>
              <Option value="consent">Consent Template</Option>
            </Select>
          )}
        </div>
      </Modal>
    );
  }
}

export default SaveTemplate;
