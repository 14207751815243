import React, { useState } from 'react';
import { Input, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAutoScroll } from '../../../../hooks';
import moment from 'moment';

const SESSION = 'session';
const DAYS = 'days';

const ActionCookies = ({ cookies, updateActionCookies, readOnly }) => {
  const autoScrollRef = useAutoScroll(cookies.length);

  const addCookie = () => updateActionCookies([
    ...cookies,
    {
      key: '',
      value: '',
      maxAge: 0,
      shareRootDomain: true,
      session: false
    }
  ]);

  const deleteCookie = (cookieIndex) => () => updateActionCookies([
    ...cookies.slice(0, cookieIndex),
    ...cookies.slice(cookieIndex + 1),
  ]);

  const updateCookie = (field, cookieIndex) => (value) => {
    updateActionCookies([
      ...cookies.slice(0, cookieIndex),
      { ...cookies[cookieIndex], [field]: value },
      ...cookies.slice(cookieIndex + 1)
    ]);
  };

  const updateDuration = (cookieIndex, value) => {
    if (isNaN(value)) {
      return;
    };

    updateCookie('maxAge', cookieIndex)(daysToSeconds(value));
  };

  const daysToSeconds = (days) => {
    return moment
      .duration(Number(days), 'days')
      .asSeconds();
  }

  const secondsToDays = (seconds) => {
    return moment
      .duration(seconds, 'seconds')
      .asDays();
  }

  const addCookieButton = (
    <div className="icon" onClick={addCookie} disabled={readOnly}>
      <FontAwesomeIcon
        icon={faPlusCircle}
        size="18"
        color="#3bd55c"
        disabled={readOnly}
      />
      <div className="add-cookie-text" disabled={readOnly}>
        Add Cookie
      </div>
    </div>
  );

  return (
    <div className="cookies-container" ref={autoScrollRef}>
      {cookies.length
        ? (
          <div className="cookies-table-container">
            <div className="table-headers-container">
              <div className="name-header">
                Key
              </div>
              <div className="value-header">
                Value
              </div>
              <div className="domain-header">
                Domain
              </div>
              <div className="expiration-header">
                Expiration
              </div>
            </div>
            {cookies.map(({
              key,
              maxAge,
              value,
              shareRootDomain,
              session,
            }, i) => (
                <div className="cookie-container">
                  <Input className="name-input" value={key} onChange={(e) => updateCookie('key', i)(e.target.value)}  disabled={readOnly}/>
                  <Input className="value-input" value={value} onChange={(e) => updateCookie('value', i)(e.target.value)} disabled={readOnly}/>
                  <Select className="domain-select" value={shareRootDomain} onChange={updateCookie('shareRootDomain', i)} disabled={readOnly}>
                    <Select.Option value={true}>
                      Root
                    </Select.Option>
                    <Select.Option value={false}>
                      Current Domain
                    </Select.Option>
                  </Select>
                  <Select
                    className="expiration-select"
                    value={session ? SESSION : DAYS}
                    onChange={value => updateCookie('session', i)(value === SESSION)}
                    disabled={readOnly}
                  >
                    <Select.Option value={SESSION}>
                      Session
                    </Select.Option>
                    <Select.Option value={DAYS}>
                      Days
                    </Select.Option>
                  </Select>
                  <Input
                    style={{ width: 55 }}
                    disabled={session || readOnly}
                    value={session ? '' : secondsToDays(maxAge)}
                    // onChange={({ target: { value } }) => !isNaN(value) && updateCookie('maxAge', i)(value)}
                    onChange={(e) => updateDuration(i, e.target.value)}
                  />
                  <div className="icon" style={{ fontSize: 18 }} onClick={deleteCookie(i)} disabled={readOnly}>
                    <FontAwesomeIcon icon={faTrash} disabled={readOnly} />
                  </div>
                </div>
              ))}
            {addCookieButton}
          </div>
        )
        : (
          <div className="no-cookies-msg-container">
            <div>
              You haven't added any cookies
            </div>
            {addCookieButton}
          </div>
        )}
    </div>
  );
};

export default ActionCookies;