import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import { Select, Button, Checkbox, Input } from "../../styleguide";

const regex = /^[0-9]\d*$/

const DropdownScanAllowance = (props) => {
  const { onChange, scanAllowance } = props;
  const [value, setValue] = useState(regex.test(scanAllowance) ? scanAllowance : 0);
  const [mainValue, setMainValue] = useState(scanAllowance);
  const [noLimit, setNoLimit] = useState(scanAllowance === -1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [valueIsInteger, setValueIsInteger] = useState(regex.test(scanAllowance));
  const [errorMessage, setErrorMessage] = useState(null);

  const handleInputChange = ({target: {value}}) => {
    setValue(value);
  };

  const handleOnApply = (e) => {
    let currValue;
    if (noLimit) {
      currValue = -1;
    } else {
      currValue = value;
    }
    setMainValue(currValue);
    onChange(currValue);
    setIsDropdownOpen(false);
  };

  const handleNoLimitChange = () => {
    if (noLimit) {
      setValue(0);
    }
    setNoLimit(!noLimit);
  };

  const handleOnCancel = (e) => {
    setNoLimit(scanAllowance === -1);
    setValue(regex.test(scanAllowance) ? scanAllowance : 0);
    setIsDropdownOpen(false);
  };

  const onDropdownVisibleChange = (open) => {
    if(!open) {
      setNoLimit(scanAllowance === -1);
      setValue(regex.test(scanAllowance) ? scanAllowance : 0);
    }
    setIsDropdownOpen(open);
  };

  useEffect(() => {
    if (noLimit)
      setValue(-1)
    else
      setValue(scanAllowance === -1 ? 0 : (regex.test(scanAllowance) ? scanAllowance : 0))
  }, [noLimit]);
  
  useEffect(() => {
    setValue(regex.test(scanAllowance) ? scanAllowance : 0)
    setMainValue(scanAllowance);
    setNoLimit(scanAllowance === -1)
  }, [scanAllowance]);

  useEffect(()=>{
   setValueIsInteger(regex.test(value));
  },[value])

  useEffect(()=>{
    if(!noLimit && !valueIsInteger){
      setErrorMessage("Please enter a postive integer")
    } else {
      setErrorMessage(null);
    }
  },[valueIsInteger])

  return (
    <>
      <Select
        className={"scan-allowance-select"}
        dropdownClassName={"scan-allowance-dropdown"}
        style={{ width: "100%" }}
        value={mainValue === -1 ? 'No Limit' : mainValue}
        open={isDropdownOpen}
        onDropdownVisibleChange={onDropdownVisibleChange}
        dropdownRender={() => (
          <>
            <div className="scan-allowance-dropdown-row">
              <Input
                defaultValue={0}
                value={noLimit ? '----' : value}
                disabled={noLimit}
                onChange={handleInputChange}
                error={!noLimit && !valueIsInteger}
              />
              <Checkbox purple checked={noLimit} onChange={handleNoLimitChange}>
                No Limit
              </Checkbox>
            </div>
            <div className="scan-allowance-dropdown-row scan-allowance-error-message">
              {errorMessage}
            </div>
            <Divider />
            <div className="scan-allowance-dropdown-row">
              <Button onClick={handleOnCancel} className="cancel">Cancel</Button>
              <Button onClick={handleOnApply} type="primary" disabled={!noLimit && !valueIsInteger}>Apply</Button>
            </div>
          </>
        )}
      >
      </Select>
    </>
  );
};
export default DropdownScanAllowance;
