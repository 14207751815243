import React, { useState, useEffect } from 'react';
import { EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Input } from '../../../../styleguide'
import { styles } from './styles';

import { CAMPAIGNS_MAP } from '../../../../constants';

const TemplateHeader = (props) => {
  const [scenarioName, setscenarioName] = useState(props.scenarioName);

  const setName = (name) => {
    setscenarioName(name)
    props.editHeaderScenario(name);
  };

  useEffect(() => {
    setscenarioName(props.scenarioName);
  }, [props.scenarioName]);

  const campaignType = props.isMultiCampaignEnabled && <div style={{ marginLeft: '1%', borderLeft: "2px solid #bbb", padding: "5px" }}>{CAMPAIGNS_MAP[props.campaignType]}</div>;

  return (
    <div className="template-header">
      <div className="template-title">
        <React.Fragment>
          Scenario Builder:&nbsp;
          <Input
              placeholder="Name"
              value={scenarioName}
              onChange={(e) => setName(e.target.value)}
              prefix={<EditOutlined />}
              style={{ width: ((scenarioName.length + 10) * 7) + 'px'}}
            />
        </React.Fragment>
        {campaignType}
      </div>
      <div className="template-button">
        {props.showErrorMessage && <span style={{color: 'red', marginRight: '30px'}}><ExclamationCircleFilled style={{cursor: 'default'}}/> <strong>Fix all the highlighted issues before proceeding.</strong>&nbsp;&nbsp;</span>}
        <Button
          onClick={props.onClose}
          type="secondary"
          ghost
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: '0px' }}
          type="primary"
          onClick={props.createNewScenario}
        >
          {props.actionButtonText}
        </Button>
      </div>
    </div>
  );
};

export default TemplateHeader;
