import React from 'react';
import { Table } from '../../../../styleguide'
import { browserHistory } from 'react-router';
import { getParameterByName } from '../../../utils.js';

function PartitionDetails({ campaign, scenarios, currentUser }) {
  const parsedPartitions = campaign.partition_set;

  const redirectToScenario = (scenarioId) => {
    const currentUserFeatures = currentUser && currentUser.accountFeatures
    const scenarioV2Flag = currentUserFeatures.includes("tcf_v2") || currentUserFeatures.includes("non_iab_vl") || currentUserFeatures.includes("ccpa");

    const liveOrEndedScenario = scenarios.find(s => s.id === scenarioId);
    const eqDraftSceanrio = scenarios.find(s => s.description == liveOrEndedScenario.description && !s.live && s.status == 'draft');
    const siteId = parseInt(getParameterByName('site_id', window.location));
    const siteGroupId = getParameterByName('site_group_id', window.location);
    let siteGroupParam = '';
    if(siteGroupId) {
      siteGroupParam = `&site_group_id=${siteGroupId}`;
    }
    if(eqDraftSceanrio) {
      browserHistory.push(`/dialogue/scenarios/edit?site_id=${siteId}${siteGroupParam}&id=${eqDraftSceanrio.id}&template=false`);
    } else {
      browserHistory.push(`/dialogue/scenariosv3?site_id=${siteId}${siteGroupParam}`);
    }
  }

  const redirectToPartition = () => {
    const siteId = parseInt(getParameterByName('site_id', window.location));
    browserHistory.push(`/dialogue/partitions?site_id=${siteId}`);
  }
  
  const dataSource = parsedPartitions && parsedPartitions.partitions.length ? parsedPartitions.partitions.map(partition => {
    const scenarioName = scenarios.find(s => partition.scenario_id === s.id).description;
    return {
            setName: campaign.partition_set.description,
            name: partition.name,
            partition: `${partition.ruleset.bucket_rules[0].bucket_start} - ${partition.ruleset.bucket_rules[0].bucket_end}`,
            scenarioId: partition.scenario_id,
            scenarioName: scenarioName,
        };
    }) : [];

  const columns = [
    {
      title: 'Set Name',
      dataIndex: 'setName',
      key: 'setName',
      render: (text) => <span><a onClick={redirectToPartition}>{text}</a></span>
    },
    {
      title: 'Partition Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Partition',
      dataIndex: 'partition',
      key: 'partition',
    },
    {
      title: 'Scenario',
      dataIndex: 'scenarioId',
      key: 'scenarioId',
      render: (sceanrioId, row) => <span><a onClick={() => redirectToScenario(sceanrioId)}>{row.scenarioName}</a></span>
    },
];

  return <React.Fragment>
         <Table 
           className="campaigns-partition-details" 
           title={() => 'Partition Details'} 
           pagination={false} 
           dataSource={dataSource} 
           columns={columns} />
    </React.Fragment>
}

export default PartitionDetails;