import React, { Component } from 'react';
import ColorPickerInput from '../../../../../../../common/ColorPickerInput.js.jsx';


class ColorPicker extends Component {
  render() {
    return (
      <div className="set-color">
        <h4>{this.props.settingsTitle}</h4>
        <div>
          <ColorPickerInput
            value={ this.props.value }
            onUpdate={(value) => this.props.updateSetting(this.props.settingKey, value)}
            editing={ true }
          />
        </div>
      </div>
    );
  }
}

export default ColorPicker;