import { Record, List, Map, OrderedSet, fromJS } from 'immutable';
import { difference } from 'lodash'
import { PartitionSet, Partition } from './dns_records';
import { GCM_ACTION_TYPE, GCM_CATEGORIES } from '../constants';

export const PrivacyManager = Record({
  id: '',
  name: ''
});

export class VendorCookie extends Record({
  id: null,
  name: '',
  foundOnUrls: List([]),
  storesTrackableData: false,
  category: '',
  cookieCategoryId: '',
  domain: '',
  cookieLifeSpan: '',
  cookieMaxAgeSeconds: Number,
  gvlDefined: Boolean,
  fromScan: Boolean,
  manuallyAdded: Boolean,
  iabPurposeRefs: List([])
}) {
  constructor(vc) {
    const updatedVC = Object.assign({}, vc);
    updatedVC.id = vc._id;
    updatedVC.foundOnUrls = List(vc.foundOnUrls);
    super(updatedVC);
  }
}

export class IdAndName extends Record({
  id: null,
  name: ''
}) {
  constructor(obj) {
    const updatedObj = Object.assign({}, obj);
    updatedObj.id = obj._id;
    super(updatedObj);
  }
}

export class Vendor extends Record({
  id: null,
  name: '',
  description: '',
  vendorType: '',
  iab: false,
  iabId: null,
  purposes: List([]),
  legIntPurposes: List([]),
  iabSpecialPurposes: List([]),
  iabSpecialFeatures: List([]),
  iabFeatures: List([]),
  cookies: List([]),
  siteUrl: '',
  policyUrl: '',
  domainClassification: List([]),
  isNew: true,
  googleId: null,
  isGoogleAtp: false,
  categorization: List([]),
  isApple: false,
  isCustomVendorLabel1: false,
  isCustomVendorLabel2: false,
  isCustomVendorLabel3: false,
  isDeletedFromGvl: null,
  vendorAdditionalDetails: null
}) {
  constructor(vendor) {
    const updatedVendor = Object.assign({}, vendor);
    if (vendor.iabPurposes) {
      updatedVendor.purposes = List(
        vendor.iabPurposes.map(p =>
          typeof p === 'string' ? p : new IdAndName(p)
        )
      );
    }
    if (vendor.purposes) {
      updatedVendor.purposes = List(vendor.purposes);
    }

    if (vendor.legIntPurposes) {
      updatedVendor.legIntPurposes = List(vendor.legIntPurposes);
    }

    if (vendor.iabLegIntPurposes) {
      updatedVendor.legIntPurposes = List(
        vendor.iabLegIntPurposes.map(p =>
          typeof p === 'string' ? p : new IdAndName(p)
        )
      );
    }

    if (vendor.iabSpecialPurposes) {
      updatedVendor.iabSpecialPurposes = List(
        vendor.iabSpecialPurposes.map(p =>
          typeof p === 'string' ? p : new IdAndName(p)
        )
      );
    }

    if (vendor.iabSpecialFeatures) {
      updatedVendor.iabSpecialFeatures = List(
        vendor.iabSpecialFeatures.map(p =>
          typeof p === 'string' ? p : new IdAndName(p)
        )
      );
    }

    if (vendor.iabFeatures) {
      updatedVendor.iabFeatures = List(
        vendor.iabFeatures.map(p =>
          typeof p === 'string' ? p : new IdAndName(p)
        )
      );
    }

    updatedVendor.id = vendor._id;
    updatedVendor.iab = vendor.vendorType === 'IAB';

    if (vendor.cookies) {
      updatedVendor.cookies = List(
        vendor.cookies.map(cookie => new VendorCookie(cookie))
      );
    }

    if (vendor.domainClassification) {
      updatedVendor.domainClassification = List(vendor.domainClassification);
    }

    if(vendor.vendorAdditionalDetails) {
      updatedVendor.vendorAdditionalDetails = vendor.vendorAdditionalDetails
    }

    super(updatedVendor);
  }

  equals(vendor) {
    return this.id === vendor.id;
  }

  hashCode() {
    // Convert id to 32bit integer to avoid "maximum call stack size exceeded"
    // error caused by OrderedSet comparison
    return convertToHashCode(this.id);
  }
}

export const TagManager = Record({
  name: '',
  key: '',
  value: ''
});

export class Action extends Record({
  id: '',
  type: '',
  url: '',
  js: '',
  tagManager: null
}) {
  constructor(ca) {
    const updatedCa = Object.assign({}, ca);
    if (ca.tagManager) {
      updatedCa.tagManager = new TagManager(ca.tagManager);
    }
    if (ca._id) {
      updatedCa.id = ca._id;
    }
    super(updatedCa);
  }
}

export class CategorizationSchema extends Record({
  type: null,
  vendorId: null,
  cookies: List([]),
  legalBasisChange: false,
}) {
  constructor(cat) {
    const updatedCat = Object.assign({}, cat);
    updatedCat.cookies = Array.isArray(cat.cookies)
      ? new List(cat.cookies)
      : cat.cookies;

    super(updatedCat);
  }
}

export class Purpose extends Record({
  id: null,
  iabId: null,
  name: '',
  description: '',
  vendorIds: OrderedSet([]),
  consentActions: List([]),
  rejectActions: List([]),
  iabPurposeRefs: List([]),
  customVendorsRelyingOnLegInt: OrderedSet([]),
  defaultOptedIn: false,
  vendorCategorization: List([]),//DIA-2660 - need to vendor categorization while cloning from /non-iab URL
  consentActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  rejectActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  translations: null,
}) {
  constructor(purpose) {
    const updatedPurpose = Object.assign({}, purpose);
    if (purpose._id) updatedPurpose.id = purpose._id;
    if (
      purpose &&
      Object.keys(purpose).length &&
      purpose.vendors &&
      purpose.vendors.length
    ) {
      updatedPurpose.vendorIds = OrderedSet(
        purpose.vendors.map(v => (typeof v === 'string' ? v : v._id))
      );
    }
    if (purpose.consentActions) {
      updatedPurpose.consentActions = List(
        purpose.consentActions.map(ca => new Action(ca))
      );
    }
    if (purpose.rejectActions) {
      updatedPurpose.rejectActions = List(
        purpose.rejectActions.map(ca => new Action(ca))
      );
    }

    updatedPurpose.consentActionExtension = new  ActionExtension(purpose.consentActionExtension).toJS();
    updatedPurpose.rejectActionExtension = new ActionExtension(purpose.rejectActionExtension).toJS();

    updatedPurpose.iabPurposeRefs = List(purpose.iabPurposeRefs);
    updatedPurpose.customVendorsRelyingOnLegInt = OrderedSet(
      purpose.customVendorsRelyingOnLegInt
    );

    if (purpose.translations) {
      updatedPurpose.translations = new Translations(purpose.translations);
    }

    if (purpose.vendorCategorization) {
      updatedPurpose.vendorCategorization = List(
        purpose.vendorCategorization.map(obj => new CategorizationSchema(obj))
      );
    }

    super(updatedPurpose);
  }

  equals(messageRecord) {
    return this.id === messageRecord.id;
  }

  googleConsentModeCategory() {
    let gcmConsentAction = this.consentActions.find((a) => ( a.type === GCM_ACTION_TYPE ))

    return gcmConsentAction ? gcmConsentAction.getIn(['tagManager', 'key']) : null;
  }

  hashCode() {
    return this.id;
  }
}

export class IabSpecialFeature extends Record({
  id: null,
  name: '',
  description: ''
}) {
  constructor(se) {
    const updatedSe = Object.assign({}, se);
    if (se._id) updatedSe.id = se._id;
    super(updatedSe);
  }
}

export class Translations extends Record({
  names: new Map({}),
  descriptions: new Map({}),
  privacyPolicySectionText: null,
  descriptionsLegal: new Map({}),
  illustrations: new Map({}),
  identificationList: null
}) {
  constructor(translations) {
    super(fromJS(translations));
  }
}

export class PurposeV2 extends Record({
  id: null,
  iabId: null,
  iabStackRef: null,
  iabPurposeRef: null,
  name: '',
  description: '',
  descriptionLegal: '',
  vendorIds: OrderedSet([]),
  consentActions: List([]),
  rejectActions: List([]),
  rejectActionExtension: List([]),
  consentActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  rejectActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  iabPurposeRefs: List([]),
  customVendorsRelyingOnLegInt: OrderedSet([]),
  vendorCategorization: List([]),
  type: 'IAB_PURPOSE',
  categories: List([]),
  defaultLegalBasis: null,
  defaultCustomLegalBasis: null,
  disclosureOnly: false,
  translations: null,
  requireConsent: null,
  illustrations: [],
  customIllustrations: List([]),
  useIabIllustration1: true,
  useIabIllustration2: true
}) {
  constructor(purpose) {
    const updatedPurpose = Object.assign({}, purpose);
    if (purpose._id) updatedPurpose.id = purpose._id;
    if (
      purpose &&
      Object.keys(purpose).length &&
      purpose.vendors &&
      purpose.vendors.length
    ) {
      updatedPurpose.vendorIds = OrderedSet(
        purpose.vendors.map(v => (typeof v === 'string' ? v : v._id))
      );
    }
    if (purpose.vendorIds && !purpose.vendorIds.toJS) {
      updatedPurpose.vendorIds = OrderedSet(purpose.vendorIds);
    }
    if (purpose.consentActions) {
      updatedPurpose.consentActions = List(
        purpose.consentActions.map(ca => new Action(ca))
      );
    }
    if (purpose.rejectActions) {
      updatedPurpose.rejectActions = List(
        purpose.rejectActions.map(ca => new Action(ca))
      );
    }
    if (purpose.categories) {
      let categories = purpose.categories;
      if (categories.toJS) {
        categories = categories.toJS();
      }
      updatedPurpose.categories = List(categories.map(ca => new PurposeV2(ca)));
    }
    if (purpose.vendorCategorization) {
      updatedPurpose.vendorCategorization = List(
        purpose.vendorCategorization.map(obj => new CategorizationSchema(obj))
      );
    }
    if (purpose.translations) {
      updatedPurpose.translations = new Translations(purpose.translations);
    }
    if(purpose.customIllustrations) {
      updatedPurpose.customIllustrations = List(purpose.customIllustrations);
    }
    updatedPurpose.useIabIllustration1 = purpose.useIabIllustration1 ?? true;
    updatedPurpose.useIabIllustration2 = purpose.useIabIllustration2 ?? true;
    // XXX put back when not mocked
    // if (purpose.categories) {
    //   updatedPurpose.categories = List(purpose.categories.map(c => new Purpose(c)));
    // }

    //remove from v2
    if (purpose.iabPurposeRefs) {
      updatedPurpose.iabPurposeRefs = List(purpose.iabPurposeRefs);
    }

    updatedPurpose.rejectActionExtension = new ActionExtension(
      purpose.rejectActionExtension
    ).toJS();
    updatedPurpose.consentActionExtension = new ActionExtension(
      purpose.consentActionExtension
    ).toJS();
    updatedPurpose.customVendorsRelyingOnLegInt = OrderedSet(
      purpose.customVendorsRelyingOnLegInt
    );

    super(updatedPurpose);
  }

  equals(messageRecord) {
    return this.id === messageRecord.id;
  }

  googleConsentModeCategory() {
    let gcmConsentAction = this.consentActions.find((a) => ( a.type === GCM_ACTION_TYPE ))

    return gcmConsentAction ? gcmConsentAction.getIn(['tagManager', 'key']) : null;
  }

  hashCode() {
    return this.id;
  }
}

let idCounter = 0;

export class VendorWrapper extends Record({
  id: null,
  vendorId: null,
  consentActions: List([]),
  rejectActions: List([]),
  consentActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  rejectActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  cookies: List([]),
  description: null,
  urlMappingRules: [],
  isApple: false,
  isCustomVendorLabel1: false,
  isCustomVendorLabel2: false,
  isCustomVendorLabel3: false,
  excludeFromCount: false,
}) {
  constructor(vendorWrapper) {
    const updatedVendorWrapper = Object.assign({}, vendorWrapper);

    if (vendorWrapper._id) updatedVendorWrapper.id = vendorWrapper._id;

    if (vendorWrapper.consentActions) {
      updatedVendorWrapper.consentActions = List(
        vendorWrapper.consentActions.map(ca => new Action(ca))
      );
    }
    if (vendorWrapper.rejectActions) {
      updatedVendorWrapper.rejectActions = List(
        vendorWrapper.rejectActions.map(ca => new Action(ca))
      );
    }
    if (vendorWrapper.cookies) {
      updatedVendorWrapper.cookies = List(
        vendorWrapper.cookies.map(cookie => new VendorCookie(cookie))
      );
    }
    if (vendorWrapper.urlMappingRules) {
      updatedVendorWrapper.urlMappingRules = vendorWrapper.urlMappingRules.map(
        ({ patterns }) => patterns
      );
    }

    updatedVendorWrapper.rejectActionExtension = new ActionExtension(vendorWrapper.rejectActionExtension).toJS();
    updatedVendorWrapper.consentActionExtension = new ActionExtension(vendorWrapper.consentActionExtension).toJS();

    super(updatedVendorWrapper);
  }
}

export class VendorList extends Record({
  id: null,
  name: 'New Vendor List',
  description: '',
  vendors: OrderedSet([]),
  categories: List([]),
  consentScope: 'SINGLE',
  siteIds: List([]),
  intersection: List([]),
  clientSideId: null,
  isActive: false,
  vendorsWrappers: List([]),
  allIabAndAutoUpdate: false,
  autoUpdateVendorScan: true,
  shareRootDomain: false,
  cookieMaxAge: 365,
  writeFirstPartyCookiesFromServer: false,
  defaultLegalBasis: 'CONSENT',
  publisherCmpId: null,
  publisherCmpId2: null,
  hasConsentCompensation: false,
  useSpecialTreatmentP1: false,
  specialTreatmentP1Geo: List([]),
  iabSpecialFeatures: List([]),
  displaySpecialPurposesNFeatures: false,
  customElementsFirst: false,
  publisherPurposes: Map({}),
  appliesGeos: '',
  exclusionGeos: '',
  useVendorActionsV2: false,
  setEuconsentCookie: false,
  vendorScanCategorizations: List([]),
  vendorsWithLegalBasisChanges: List([]),
  onlyCustom: false,
  deletedIabVendors: List([]),
  includeDisclosedVendors: false,
  noServerStorage: false,
  noUniqueIdReporting: false,
  googleConsentModeV2: false,
  dateUpdated: null,
  tcfVersion: 2.2
}) {
  constructor(vendorList) {
    const updatedVendorList = Object.assign({}, vendorList);
    if (!vendorList.clientSideId) {
      updatedVendorList.clientSideId = idCounter++;
    }
    if (Object.keys(vendorList).length) {
      updatedVendorList.vendors = OrderedSet(vendorList.vendors)
        .map(v => new Vendor(v))
        .sortBy(v => v.name.toLowerCase())
        .sortBy(v => v.vendorType);
      updatedVendorList.categories = List(vendorList.categories).map(p =>
        window.location.pathname.includes('v2') ? new PurposeV2(p) : new Purpose(p)
      );
      updatedVendorList.id = vendorList._id;
      updatedVendorList.siteIds = List(vendorList.siteIds);
      updatedVendorList.vendorsWrappers =
        vendorList.vendorsWrappers === null
          ? List([])
          : (updatedVendorList.vendorsWrappers = List(
            vendorList.vendorsWrappers
          ).map(vw => new VendorWrapper(vw)));
      if (vendorList.intersection) {
        updatedVendorList.intersection = List(vendorList.intersection);
      }
      if (vendorList.specialTreatmentP1Geo) {
        updatedVendorList.specialTreatmentP1Geo = List(
          vendorList.specialTreatmentP1Geo
        );
      }
      if (vendorList.specialTreatmentP1Geo) {
        updatedVendorList.iabSpecialFeatures = List(
          vendorList.iabSpecialFeatures
        );
      }
      updatedVendorList.allIabAndAutoUpdate = vendorList.autoUpdateIabVendors2;
      updatedVendorList.publisherPurposes = vendorList.publisherPurposes;
    }
    
    if (updatedVendorList.appliesGeos && updatedVendorList.appliesGeos.length) {
      updatedVendorList.appliesGeos = updatedVendorList.appliesGeos.map(geo => geo === 'CA' ? 'CAN' : geo);
    }
    if (updatedVendorList.exclusionGeos && updatedVendorList.exclusionGeos.length) {
      updatedVendorList.exclusionGeos = updatedVendorList.exclusionGeos.map(geo => geo === 'CA' ? 'CAN' : geo);
    }

    updatedVendorList.vendorScanCategorizations = updatedVendorList.vendorScanCategorizations ? fromJS(updatedVendorList.vendorScanCategorizations) : List([]);

    if (vendorList.vendorsWithLegalBasisChanges) {
      updatedVendorList.vendorsWithLegalBasisChanges = List(vendorList.vendorsWithLegalBasisChanges);
    }

    updatedVendorList.onlyCustom = vendorList.onlyCustom;

    if(vendorList.deletedIabVendors) {
      updatedVendorList.deletedIabVendors = List(vendorList.deletedIabVendors);
    }

    updatedVendorList.tcfVersion = vendorList.tcfVersion ?? 2.1;

    super(updatedVendorList);
  }

  equals(vendorList) {
    return this.id === vendorList.id;
  }

  getUnusedGcmCategories(excludeCategoryId) {
    const usedCategories = this.categories.filter(c => c.id !== excludeCategoryId)
      .map(c => c.googleConsentModeCategory())
      .filter(c => !!c)

    return difference(GCM_CATEGORIES, usedCategories.toJS());
  }

  hashCode() {
    // Convert id to 32bit integer to avoid "maximum call stack size exceeded"
    // error caused by OrderedSet comparison
    return convertToHashCode(this.id);
  }
}

export class VendorListCCPA extends Record({
  id: null,
  partitionSet: new PartitionSet({}),
  name: 'New Vendor List',
  description: '',
  vendors: OrderedSet([]),
  categories: List([]),
  consentScope: 'SINGLE',
  siteIds: List([]),
  intersection: List([]),
  clientSideId: null,
  isActive: false,
  vendorsWrappers: List([]),
  allIabAndAutoUpdate: false,
  autoUpdateVendorScan: true,
  shareRootDomain: false,
  cookieMaxAge: 365,
  appliesGeos: '',
  exclusionGeos: '',
  writeFirstPartyCookiesFromServer: false,
  defaultLegalBasis: 'CONSENT',
  publisherCmpId: null,
  respectGPC: null
}) {
  constructor(vendorList) {
    const updatedVendorList = Object.assign({}, vendorList);
    if (!vendorList.clientSideId) {
      updatedVendorList.clientSideId = idCounter++;
    }
    if (Object.keys(vendorList).length) {
      updatedVendorList.vendors = OrderedSet(vendorList.vendors)
        .map(v => new Vendor(v))
        .sortBy(v => v.name.toLowerCase())
        .sortBy(v => v.vendorType);
      updatedVendorList.categories = List(vendorList.categories).map(p =>
        window.location.pathname.includes('v2') ? new PurposeV2(p) : new Purpose(p)
      );
      updatedVendorList.id = vendorList._id;
      updatedVendorList.siteIds = List(vendorList.siteIds);
      updatedVendorList.vendorsWrappers =
        vendorList.vendorsWrappers === null
          ? List([])
          : (updatedVendorList.vendorsWrappers = List(
            vendorList.vendorsWrappers
          ).map(vw => new VendorWrapper(vw)));
      if (vendorList.intersection) {
        updatedVendorList.intersection = List(vendorList.intersection);
      }
      updatedVendorList.allIabAndAutoUpdate = vendorList.autoUpdateIabVendors2;
      if (vendorList.partitionSet) {
        updatedVendorList.partitionSet = new PartitionSet(
          vendorList.partitionSet
        );
      }
    }
    if (updatedVendorList.appliesGeos && updatedVendorList.appliesGeos.length) {
      updatedVendorList.appliesGeos = updatedVendorList.appliesGeos.map(geo => geo === 'CA' ? 'CAN' : geo);
    }
    if (updatedVendorList.exclusionGeos && updatedVendorList.exclusionGeos.length) {
      updatedVendorList.exclusionGeos = updatedVendorList.exclusionGeos.map(geo => geo === 'CA' ? 'CAN' : geo);
    }

    super(updatedVendorList);
  }

  equals(vendorList) {
    return this.id === vendorList.id;
  }

  hashCode() {
    // Convert id to 32bit integer to avoid "maximum call stack size exceeded"
    // error caused by OrderedSet comparison
    return convertToHashCode(this.id);
  }
}

class ActionExtension extends Record({
  geoTargeting: { ruleBoolean: true, geoList: [] },
  cookies: [],
  onStatusChangeOnly: false,
  onNewUser: false
}) {
  constructor(ext) {
    super(ext);
  }
}

export class VendorCookies extends Record({
  id: null,
  key: null,
  name: '',
  foundOnUrls: List([]),
  storesTrackableData: false,
  category: '',
  cookieCategoryId: '',
  domain: '',
  cookieLifeSpan: '',
  isNew: false,
  scannedNew: false,
  modified: false,
  colorSchemeApplies: false,
}) {
  constructor(vc) {
    const updatedVC = Object.assign({}, vc);
    if (vc._id) updatedVC.id = vc._id;
    if (vc.name) updatedVC.name = vc.name;
    if (vc.storesTrackableData)
      updatedVC.storesTrackableData = vc.storesTrackableData;
    if (vc.category) updatedVC.category = vc.category;
    if (vc.cookieCategoryId) updatedVC.cookieCategoryId = vc.cookieCategoryId;
    if (vc.domain) updatedVC.domain = vc.domain;
    if (vc.cookieLifeSpan) updatedVC.cookieLifeSpan = vc.cookieLifeSpan;
    if (vc.isNew) updatedVC.isNew = vc.isNew;
    if (vc.foundOnUrls) updatedVC.foundOnUrls = List(vc.foundOnUrls);
    super(updatedVC);
  }
}

export class VendorListError extends Record({
  errorType: '',
  msg: '',
  data: null
}) {
  constructor(err) {
    const updatedErr = Object.assign({}, err);

    if (err.errorType === 'SITE_VALIDATION_FAILED') {
      updatedErr.data = List(err.data).map(vl => {
        const vendorList = Object.assign({}, vl.vendorList);
        vendorList.intersection = vl.intersection;
        return new VendorList(vendorList);
      });
    }
    super(updatedErr);
  }
}

function convertToHashCode(string) {
  // Convert string to 32bit integer
  var hash = 0,
    i,
    chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
}

export class IabSpecialFeatureAndPurposes extends Record({
  specialPurposes: List(),
  features: List(),
  specialFeatures: List(),
}) {
  constructor(fp) {
    const updatedFP = Object.assign({}, fp);
    if (fp.specialPurposes) updatedFP.specialPurposes = fp.specialPurposes;
    if (fp.features) updatedFP.features = fp.features;
    if (fp.specialFeatures) updatedFP.specialFeatures = fp.specialFeatures;
    super(updatedFP);
  }
}

export class VendorScanningKnownAndUnknown extends Record({
  scannedVendors: List([]),
  vendorWithProblems: List([])
}) {
  constructor(fp) {
    const updatedFP = Object.assign({}, fp);
    if (fp.scannedVendors) updatedFP.scannedVendors = fp.scannedVendors;
    if (fp.vendorWithProblems) updatedFP.vendorWithProblems = fp.vendorWithProblems;
    super(updatedFP);
  }
}

export class VendorListMetaData extends Record({
  siteId: null,
  vendorListId: null,
  name: null,
  hasActiveVendorList: false,
  hasSourcepoint: false,
  type: null
}) {
  constructor(md) {
    const updatedMD = Object.assign({}, md);
    updatedMD.siteId = Number(md.siteId);
    if (md.vendorListId) updatedMD.vendorListId = md.vendorListId;
    if (md.name) updatedMD.name = md.name;
    super(updatedMD);
  }
}