import React, { useState, useEffect } from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { message, Modal, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CopyUrlModal = ({
  visible,
  onClose,
  messages,
  isSar,
  createUrl,
}) => {
  const [selectedMsgId, setSelectedMsgId] = useState('');
  const [selectedSarType, setSelectedSarType] = useState('delete');

  useEffect(setToDefault, [messages.length]);

  function setToDefault() {
    if (messages.length) {
      setSelectedMsgId(messages[0].id);
    };
    setSelectedSarType('delete');
  };

  const copyUrlToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = createUrl(selectedMsgId, selectedSarType);
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success('Copied to clipboard');
  };

  return (
    <Modal
      title='Copy URL to clipboard'
      visible={visible}
      onCancel={() => {
        onClose();
        setToDefault()
      }}
      footer={null}
    >
      <div className="url-modal-container">
        <div>
          <label>Select Message</label>
          <Select
            onChange={setSelectedMsgId}
            value={selectedMsgId}
          >
            {messages.map(({ id, description }) => (
              <Select.Option key={description} value={id} id={id}>
                {description}
              </Select.Option>
            ))}
          </Select>
        </div>
        {isSar && (
          <div>
            <label>Select SAR Form Type</label>
            <Select
              onChange={setSelectedSarType}
              value={selectedSarType}
            >
              <Select.Option value="delete" id="delete">
                Delete My Data Form
              </Select.Option>
              <Select.Option value="dsar" id="dsar">
                Data Subject Access Request Form
              </Select.Option>
            </Select>
          </div>
        )}
        <div>
          <label>Generated URL</label>
          <div className="url-container">
            <div>
              {messages.length ? createUrl(selectedMsgId, selectedSarType) : ''}
            </div>
            <FontAwesomeIcon
              icon={faCopy}
              size="lg"
              style={{ cursor: 'pointer' }}
              onClick={copyUrlToClipboard}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CopyUrlModal;