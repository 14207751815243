import React, { Component } from "react";
import { DownloadOutlined, FileTextOutlined } from '@ant-design/icons';
import { Popover } from "antd";
import { List, fromJS } from "immutable";
import CSVLink from "../../common/CSVLink.js";
import PDFDownload from "../ccpa_reporting/PDFDownload";
import {
  BROWSERS_LIST,
  DEVICES_LIST,
  OS_FAMILY_LIST,
  GEO_FILTER_LIST_DETAILS as GEO_LIST,
  US_STATES
} from "../../../constants";
import { formatArray } from "../../consent/performanceNew/helper";
const browsers = BROWSERS_LIST;
const devices = DEVICES_LIST;
const os = OS_FAMILY_LIST;
const GEO_FILTER_LIST_DETAILS = new List([...GEO_LIST.filterNot(c => c.type === 'state' || c.type == 'province'),...US_STATES]);
const regionsAndCountries = GEO_FILTER_LIST_DETAILS;

export default class ExportToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportCriteriaHeader: List([
        "Criteria",
        "Properties",
        "Region",
        "Devices",
        "Browsers",
        "OS Family"
      ])
    };
  }
  getPropertyName = siteId => {
    let index = this.props.sites.findIndex(item => {
      return item.get("id") === Number(siteId);
    });
    let value = this.props.sites.getIn([index, "domain"]);
    return value;
  };

  getCountryNameByCode = code =>
    regionsAndCountries.find(c => c.code === code && c.type === 'country').name;

  getStateNameByCode = code => 
  regionsAndCountries.find(c => c.code === code && c.type === 'state').name;

  //filtering for all countries from list
  getCountryNames = countries => countries.filter(c => c.type === "country");

  //filtering for all regions from list
  getRegionNames = regions => regions.filter(r => r.type === "region");

  getCriteriaDetails = criterias =>
    criterias.map((c, i) => {
      let criteriaDetails = List();
      criteriaDetails = criteriaDetails
        .push(this.props.criteriaNames && this.props.criteriaNames.size ? this.props.criteriaNames.get(i) : `Criteria ${i + 1}`)
        .push(formatArray(c.get("siteId").map(s => this.getPropertyName(s))))
        .push(
          this.getCountryNames(regionsAndCountries).size == c.get("geo").length
            ? "All regions"
            : c.get("state").size > 0
            ? formatArray(c.get("state").map(g => this.getStateNameByCode(g)).toJS()) + ',\n' +
              formatArray(c.get("geo").map(g => this.getCountryNameByCode(g)))
            : formatArray(c.get("geo").map(g => this.getCountryNameByCode(g)))
        )
        .push(
          devices.size == c.get("device").length
            ? "All devices"
            : formatArray(c.get("device"))
        )
        .push(
          browsers.size == c.get("browser").length
            ? "All Browsers"
            : formatArray(c.get("browser"))
        )
        .push(
          os.size == c.get("os").length
            ? "All OS Families"
            : formatArray(c.get("os"))
        );
      return criteriaDetails;
    });

  showExportTooltip = (index = 1, criteriaDetails, exportData, filename) => {
    const citeriaExportData = List([this.state.exportCriteriaHeader]).concat(
      criteriaDetails.filter((value, i) => i === index - 1)
    );
    const exportFormattedData = citeriaExportData.push([]).concat(exportData);
    return (
      <div className="export-dialog">
        <CSVLink data={exportFormattedData.toJS()} filename={filename + ".csv"}>
          <FileTextOutlined className="iconcsv" title="CSV"/>
        </CSVLink>
        <PDFDownload
          filename={this.props.filename}
          title={this.props.pdfTitle}
          content={this.props.exportData}
          citeriaExportData={citeriaExportData}
        />
      </div>
    );
  };

  render() {
    const { criterialist, selectedTabIndex, exportData, filename } = this.props;
    const criteriaDetails = this.getCriteriaDetails(criterialist);
    return (
      <Popover
        content={this.showExportTooltip(
          selectedTabIndex,
          criteriaDetails,
          exportData,
          filename
        )}
        title={
          <span className="popover-title">
            {`Export Type Selection for Criteria ${selectedTabIndex}`}
          </span>
        }
        trigger="hover"
        placement="topRight"
      >
        {this.props.exportText || ""} <DownloadOutlined />
      </Popover>
    );
  }
}
