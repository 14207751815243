import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Select, Button } from 'antd';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend
} from "recharts";
import { regionsMap } from '../../../../constants';

const languagesMap = {
  GB: 'English',
  DE: 'German',
  FR: 'French',
  SP: 'Spanish', 
  IT: 'Italian', 
  DU: 'Dutch', 
  SW: 'Sweedish',
};

const cmpTypesMap = {
  iab: 'IAB CMP',
  nonIab: 'Non IAB CMP',
  notDetected: 'No Notice Detected',
};

export default class AccuracyPerformanceGraph extends React.Component {
  static propTypes = {
    getAccountOwners: PropTypes.func.isRequired,
    goToManualScoring: PropTypes.func.isRequired,
  }

  state = {
    selectedRegions: ['all'],
    selectedScanRecency: '1',
    selectedRules: ['r2', 'r6', 'r13'],
    selectedConsentLanguages: ['all'],
    selectedCmpTypes: ['all'],
    selectedRuleProductStatus: ['GA', 'Alpha', 'Beta'],
    selectedRulePerformance: [],
  }

  generateWorstAndBest = (rulesMapByUniq) => {
    const filteredRulesIds = Object.keys(rulesMapByUniq);
    let allSystemRules = Object.values(this.props.systemAccuracy).flat();
    allSystemRules = allSystemRules.map(rule => {
      rule.uniqId = `${rule.ruleId}: ${this.props.rules.find(r => r.ruleId === rule.ruleId).name}-${rule.region}-${rule.cmpType ? rule.cmpType : ''}-${rule.language ? rule.language : ''}`;
      return rule;
    });
    const filteredSystemRules = allSystemRules.filter(rule => filteredRulesIds.includes(rule.uniqId));

    let worst1;
    let worst2;
    let worst3;
    let best1;
    let best2;
    let best3;
    if (this.props.na) {
      filteredSystemRules.forEach(rule => {
        if (worst1 === undefined || this.getNaPercent(rule) >= this.getNaPercent(worst1)) {
          worst3 = worst2;
          worst2 = worst1;
          worst1 = rule;
        } else if (worst2 === undefined || this.getNaPercent(rule) >= this.getNaPercent(worst2)) {
          worst3 = worst2;
          worst2 = rule;
        } else if (worst3 === undefined || this.getNaPercent(rule) >= this.getNaPercent(worst3)) {
          worst3 = rule;
        }
  
        if (best1 === undefined || this.getNaPercent(rule) <= this.getNaPercent(best1)) {
          best3 = best2;
          best2 = best1;
          best1 = rule;
        } else if (best2 === undefined || this.getNaPercent(rule) <= this.getNaPercent(best2)) {
          best3 = best2;
          best2 = rule;
        } else if (best3 === undefined || this.getNaPercent(rule) <= this.getNaPercent(best3)) {
          best3 = rule;
        }
      });
    } else {
      filteredSystemRules.forEach(rule => {
        if (worst1 === undefined || rule.overallAccuracy < worst1.overallAccuracy) {
          worst3 = worst2;
          worst2 = worst1;
          worst1 = rule;
        } else if (worst2 === undefined || rule.overallAccuracy < worst2.overallAccuracy) {
          worst3 = worst2;
          worst2 = rule;
        } else if (worst3 === undefined || rule.overallAccuracy < worst3.overallAccuracy) {
          worst3 = rule;
        }
  
        if (best1 === undefined || rule.overallAccuracy > best1.overallAccuracy) {
          best3 = best2;
          best2 = best1;
          best1 = rule;
        } else if (best2 === undefined || rule.overallAccuracy > best2.overallAccuracy) {
          best3 = best2;
          best2 = rule;
        } else if (best3 === undefined || rule.overallAccuracy > best3.overallAccuracy) {
          best3 = rule;
        }
      });
    }

    const worst = [];
    if (worst1) worst.push(worst1.uniqId);
    if (worst2) worst.push(worst2.uniqId);
    if (worst3) worst.push(worst3.uniqId);
    const best = [];
    if (best1) best.push(best1.uniqId);
    if (best2) best.push(best2.uniqId);
    if (best3) best.push(best3.uniqId);
    return { worst, best };
  }

  handleSelectRules = (value) => {
    if (value.includes('all')) {
      this.setState({ selectedRules: ['all'] });
    } else if (this.state.selectedRules.includes('all') && !value.includes('all')) {
      this.setState({ selectedRules: [] });
    } else {
      this.setState({ selectedRules: value });
    }
  }

  handleSelectRegions = (value) => {
    if (value.includes('all')) {
      this.setState({ selectedRegions: ['all'] });
    } else if (this.state.selectedRegions.includes('all') && !value.includes('all')) {
      this.setState({ selectedRegions: [] });
    } else {
      this.setState({ selectedRegions: value });
    }
  }

  handleSelectConsentLanguages = (value) => {
    if (value.includes('all')) {
      this.setState({ selectedConsentLanguages: ['all'] });
    } else if (this.state.selectedConsentLanguages.includes('all') && !value.includes('all')) {
      this.setState({ selectedConsentLanguages: [] });
    } else {
      this.setState({ selectedConsentLanguages: value });
    }
  }

  handleSelectCmpTypes = (value) => {
    if (value.includes('all')) {
      this.setState({ selectedCmpTypes: ['all'] });
    } else if (this.state.selectedCmpTypes.includes('all') && !value.includes('all')) {
      this.setState({ selectedCmpTypes: [] });
    } else {
      this.setState({ selectedCmpTypes: value });
    }
  }

  handleSelectScanRecency = (value) => {
    this.setState({ selectedScanRecency: value });
  }

  toggleSelectedRuleProductStatus = (value) => {
    let selectedRuleProductStatus = this.state.selectedRuleProductStatus;
    if (this.state.selectedRuleProductStatus.includes(value)) {
      selectedRuleProductStatus = selectedRuleProductStatus.filter(val => val !== value);
    } else {
      selectedRuleProductStatus.push(value);
    }
    this.setState({ selectedRuleProductStatus });
  }

  toggleSelectedRulePerformance = (value) => {
    let selectedRulePerformance = this.state.selectedRulePerformance;
    if (this.state.selectedRulePerformance.includes(value)) {
      selectedRulePerformance = selectedRulePerformance.filter(val => val !== value);
    } else {
      selectedRulePerformance.push(value);
    }
    this.setState({ selectedRulePerformance });
  }
  
  getNaPercent = (rule) => {
    return rule.falseUndetermined / (rule.falseUndetermined + rule.trueUndetermined + rule.falseNegative + rule.falsePositive + rule.trueNegative + rule.truePositive);
  }

  renderGraph = () => {
    const ruleIds = Object.keys(this.props.systemAccuracyTimeline);

    let allRules = ruleIds.map(ruleId => {
      return this.props.systemAccuracyTimeline[ruleId];
    }).flat();

    allRules = allRules.filter(val => !(val === 200 || val === "application/json")).map(rule => {
      rule.uniqId = `${rule.ruleId}: ${this.props.rules.find(r => r.ruleId === rule.ruleId).name}-${rule.region}-${rule.cmpType ? rule.cmpType : ''}-${rule.language ? rule.language : ''}`;
      return rule;
    });

    allRules = allRules.filter(rule => moment().diff(rule.dateCalculated, 'month') < parseInt(this.state.selectedScanRecency));

    if (!this.state.selectedRegions.includes('all')) {
      allRules = allRules.filter(rule => this.state.selectedRegions.includes(rule.region));
    }
    if (!this.state.selectedRules.includes('all')) {
      allRules = allRules.filter(rule => this.state.selectedRules.includes(rule.ruleId));
    }
    if (!this.state.selectedConsentLanguages.includes('all')) {
      allRules = allRules.filter(rule => this.state.selectedConsentLanguages.includes(rule.language));
    }
    if (!this.state.selectedCmpTypes.includes('all')) {
      allRules = allRules.filter(rule => this.state.selectedCmpTypes.includes(rule.cmpType));
    }
    allRules = allRules.filter(rule => {
      const ps = this.props.rules.find(r => rule.ruleId === r.ruleId).productStatus;
      return this.state.selectedRuleProductStatus.includes(ps);
    });

    let rulesMapByUniq = {};
    allRules.forEach(rule => {
      if (rulesMapByUniq[rule.uniqId]) {
        rulesMapByUniq[rule.uniqId].push(rule);
      } else {
        rulesMapByUniq[rule.uniqId] = [rule];
      }
    });

    const selectedRulePerformance = this.state.selectedRulePerformance;
    if (selectedRulePerformance.length) {
      const worstAndBest = this.generateWorstAndBest(rulesMapByUniq);
      if (selectedRulePerformance.includes('Worst') && selectedRulePerformance.includes('Best')) {
        allRules = allRules.filter(rule => worstAndBest.worst.concat(worstAndBest.best).includes(rule.uniqId));
      } else if (selectedRulePerformance.includes('Worst')) {
        allRules = allRules.filter(rule => worstAndBest.worst.includes(rule.uniqId));
      } else if (selectedRulePerformance.includes('Best')) {
        allRules = allRules.filter(rule => worstAndBest.best.includes(rule.uniqId));
      }

      rulesMapByUniq = {};
      allRules.forEach(rule => {
        if (rulesMapByUniq[rule.uniqId]) {
          rulesMapByUniq[rule.uniqId].push(rule);
        } else {
          rulesMapByUniq[rule.uniqId] = [rule];
        }
      });
    }

    const ruleNamesToShow = Object.keys(rulesMapByUniq).slice(0, 10);
    const rulesToShow = Object.values(rulesMapByUniq).slice(0, 10).flat();

    const ruleMapByDate = {};
    rulesToShow.forEach(rule => {
      if (ruleMapByDate[rule.dateCalculated]) {
        ruleMapByDate[rule.dateCalculated].push(rule);
      } else {
        ruleMapByDate[rule.dateCalculated] = [rule];
      }
    });

    const data = [];
    for (const date of Object.keys(ruleMapByDate)) {
      const rules = ruleMapByDate[date];
      const dataPoint = {
        name: moment(date).format('MMM DD, YYYY'),
        amt: new Date(date).getTime(),
      }
      rules.forEach(rule => {
        dataPoint[rule.uniqId] = this.props.na ? this.getNaPercent(rule) : rule.overallAccuracy;
      })
      data.push(dataPoint);
    }
    
    const colors = ['#EB3223', '#62B341', '#5AA5E6', '#FF8B07', '#A248F6', '#60D4FB', '#ECB92E', '#EB23D7', '#5B51EB', '#7FF351']

    return (
      <LineChart width={1100} height={300} data={data.reverse()}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {
          ruleNamesToShow.map((name, i) => (
            <Line type="monotone" dataKey={name} stroke={colors[i]} />
          ))
        }
      </LineChart>
    )
  }

  render() {
    if (!this.props.systemAccuracyTimeline || !Object.keys(this.props.systemAccuracyTimeline).length || !this.props.rules.size) return null;
    return (
      <div>
        <div className='filters flex-row'>
          <p className='filters-title'>Filters:</p>

          <div className='filter-select'>
            <p>Region(s)</p>
            <Select
              value={ this.state.selectedRegions }
              mode='multiple'
              showSearch
              onChange={ this.handleSelectRegions }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option key={ 'all' } value={ 'all' } >{ 'All' }</Select.Option>
              {
                Object.keys(regionsMap).map((region) => {
                  return <Select.Option key={ region } value={ region } >{ regionsMap[region] }</Select.Option>
                })
              }
            </Select>
          </div>

          <div className='filter-select'>
            <p>Scan Recency</p>
            <Select
              value={ this.state.selectedScanRecency }
              showSearch
              onChange={ this.handleSelectScanRecency }
              placeholder='Select Region'
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option key={ '1' } value={ '1' } >{ 'Last 1 month' }</Select.Option>
              <Select.Option key={ '2' } value={ '2' } >{ 'Last 2 months' }</Select.Option>
              <Select.Option key={ '3' } value={ '3' } >{ 'Last 3 months' }</Select.Option>
              <Select.Option key={ '4' } value={ '4' } >{ 'Last 4 months' }</Select.Option>
              <Select.Option key={ '5' } value={ '5' } >{ 'Last 5 month' }</Select.Option>
            </Select>
          </div>
            
          <div className='filter-select'>
            <p>Rules</p>
            <Select
              value={ this.state.selectedRules }
              mode='multiple'
              showSearch
              onChange={ this.handleSelectRules }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option key={ 'all' } value={ 'all' } >{ 'All' }</Select.Option>
              {
                this.props.rules.map((rule) => {
                  return <Select.Option key={ rule.ruleId } value={ rule.ruleId } >{ `${rule.ruleId}: ${rule.name}` }</Select.Option>
                })
              }
            </Select>
          </div>

          <div className='filter-select'>
            <p>Consent Language</p>
            <Select
              value={ this.state.selectedConsentLanguages }
              mode='multiple'
              showSearch
              onChange={ this.handleSelectConsentLanguages }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option key={ 'all' } value={ 'all' } >{ 'All' }</Select.Option>
              {
                Object.keys(languagesMap).map((languageShort) => {
                  return <Select.Option key={ languageShort } value={ languageShort } >{languagesMap[languageShort]}</Select.Option>
                })
              }
            </Select>
          </div>

          <div className='filter-select'>
            <p>CMP Type</p>
            <Select
              value={ this.state.selectedCmpTypes }
              mode='multiple'
              showSearch
              onChange={ this.handleSelectCmpTypes }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option key={ 'all' } value={ 'all' } >{ 'All' }</Select.Option>
              {
                Object.keys(cmpTypesMap).map((cmpTypeShort) => {
                  return <Select.Option key={ cmpTypeShort } value={ cmpTypeShort } >{cmpTypesMap[cmpTypeShort]}</Select.Option>
                })
              }
            </Select>
          </div>
        </div>

        <div className='quick-filters flex-row'>
          <p className='filters-title'>Quick Filters:</p>

          <Button className={classNames({ selected: this.state.selectedRuleProductStatus.includes('GA')})} onClick={() => this.toggleSelectedRuleProductStatus('GA')}>GA Rules</Button>
          <Button className={classNames({ selected: this.state.selectedRuleProductStatus.includes('Alpha')})}onClick={() => this.toggleSelectedRuleProductStatus('Alpha')}>Alpha Rules</Button>
          <Button className={classNames({ selected: this.state.selectedRuleProductStatus.includes('Beta')})}onClick={() => this.toggleSelectedRuleProductStatus('Beta')}>Beta Rules</Button>
          <div className='perform flex-row'>
            <Button className={classNames({ selected: this.state.selectedRulePerformance.includes('Worst')})} onClick={() => this.toggleSelectedRulePerformance('Worst')}>Worst Performing Rules</Button>
            <Button className={classNames({ selected: this.state.selectedRulePerformance.includes('Best')})} onClick={() => this.toggleSelectedRulePerformance('Best')}>Best Performing Rules</Button>
          </div>
          <p className='max-rule'>Maximum 10 rules of matching filtered results will be displayed</p>
        </div>
        
        <div className='accuracy-graph'>
          { this.renderGraph() }
        </div>

      </div>
    );
  }
}
