import PropTypes from 'prop-types';
import React from 'react';
import StepSatisfyOption from './StepSatisfyOption';
import { SessionSearchReferrerGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';

class StepSessionSearchReferrerGate extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    data: PropTypes.instanceOf(SessionSearchReferrerGate).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleChangeSatisfy = (continueOnSatisfy) => {
    const data = this.props.data.set('continue', continueOnSatisfy);
    this.props.onChange(data);
  }

  render() {
    const continueOnSatisfy = this.props.data.continue ? "Yes" : "No";

    if (!this.props.editing) {
      return (<div>Continue if Session begins with Search Referrer: { continueOnSatisfy }</div>);
    } else {
      return (
        <div className="step continue-halt">
          <label>Continue if Session begins with Search Referrer: </label>
          <StepSatisfyOption
            value={ this.props.data.continue }
            onChange={ this.handleChangeSatisfy }
            focusOnMount={ true }
          />
        </div>
      );
    }
  }
}

export default StepSessionSearchReferrerGate;
