import * as types from '../actions/action_types';

import { Map, OrderedSet } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
    messages: new StoreRecord({ value: OrderedSet([]) }),
    importPending: false,
    createPending: false,
    updatePending: false,
});

export default (state = initialState, action) => {
    const messages = state.getIn(['messages', 'value']);
    let updatedMessages;
    let withoutDeleted;

    switch(action.type) {

        case types.MESSAGE_WITH_DETAIL_LOADED:
            updatedMessages = messages.filterNot(m => m.id === action.message.id).add(action.message).sortBy(m => m.description.toLowerCase());
            return setValue(state, ['messages'], updatedMessages);

        case types.MESSAGE_CREATED:
            if (OrderedSet.isOrderedSet(action.message)) {
              updatedMessages = messages.merge(action.message);
            } else {
              updatedMessages = messages.add(action.message);
            }
            return setValue(state, ['messages'], updatedMessages.sortBy(m => m.description.toLowerCase())).set('createPending', false).set('importPending', false);

        case types.MESSAGE_UPDATED:
            if (OrderedSet.isOrderedSet(action.message)) {
              updatedMessages = messages.filterNot(message => action.message.map(m => m.id).includes(message.id)).merge(action.message);
            } else {
              updatedMessages = messages.filterNot(m => m.id === action.message.id).add(action.message);
            }
            return setValue(state, ['messages'], updatedMessages.sortBy(m => m.description.toLowerCase())).set('updatePending', false);

        case types.MESSAGES_PENDING:
            return setPending(state, ['messages']);

        case types.MESSAGE_CREATE_PENDING:
            return state.set('createPending', true);

        case types.MESSAGE_UPDATE_PENDING:
            return state.set('updatePending', true);

        case types.MESSAGE_IMPORT_PENDING:
            return state.set('importPending', true);

        case types.MESSAGES_CACHED:
            return stopPending(state, ['messages']);

        case types.MESSAGES_LOADED:
            let newMessages = action.messages;
            return setValue(state, ['messages'], messages.concat(newMessages).sortBy(m => m.description.toLowerCase()));

        case types.MESSAGES_ERROR:
            return setError(state, ['messages'], action.errorMessage);

        case types.MESSAGES_DELETED:
            withoutDeleted = messages.filterNot(message => action.messageIds.has(message.get('id')));
            return setValue(state, ['messages'], withoutDeleted);

        case types.DRAFT_MESSAGE_IMPORTED:
            const withImported = messages.add(action.message).sortBy(message => message.get('description').toLowerCase());
            return setValue(state, ['messages'], withImported).set('importPending', false);

        case types.ARCHIVE_MESSAGES:
            const updated = messages.map(m => {
                if (action.messageIds.has(m.id)) {
                    return m.set('live', false);
                } else {
                    return m;
                }
            })
            return setValue(state, ['messages'], updated);
    }

    return state;
};
