import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { DownloadOutlined, InfoCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Tooltip } from 'antd';
import { hasFlag } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { browserHistory } from 'react-router';

import {
  InformationStorageColumn,
  PrevalenceBenchMarkColumn,
  PrevalenceColumn,
  VendorDisclosedColumn,
  VendorNameColumn,
  VendorReferenceColumn,
  VendorStatusColumn,
  VendorTypeColumn,
} from '../../shared/DiagnoseColumns';

import {
  dataLeavingEeaExport,
  getDataLeavingEeaFilters,
  getDataLeavingEeaList,
  getVendorManagementStatusesList,
} from '../../../../../actions/diagnose_dashboard_actions';
import TableFilterDropdown from '../../shared/TableFilterDropdown';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import {
  getUniqueVendorIdsList,
  isFiltersProvided,
  mapSelectedFilters,
  requestDataDependOnTableFilters,
} from '../helpers';

const DetailedBreakdown = ({
  getDataLeavingEeaList,
  dataLeavingEeaList,
  dataLeavingEeaListPending,
  dataLeavingEeaFilter,
  getDataLeavingEeaFilters,
  selectedFilters,
  dataLeavingEeaExport,
  dataLeavingEeaExportPending,
  userDetails,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const [tableFilters, setTableFilters] = useState(null);
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');

  const getDataLeavingEeaListData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getDataLeavingEeaList({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
    return res;
  };

  const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
    const query = {
      metric: 'Data leaving the EEA',
      vendors,
      status,
      tag,
      websites: selectedFilters?.websites,
    };
    const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
    if (isCacheValid) {
      return vendorManagementStatuses;
    }
    const res = await getVendorManagementStatusesList(query);
    return res;
  };

  const getDataLeavingEeaFiltersData = () => {
    getDataLeavingEeaFilters(filters);
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      requestDataDependOnTableFilters(
        { filters: tableFilters || {}, isEnabledVendorStatusManagementFeature },
        (f, vendorId) => getDataLeavingEeaListData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
        getVendorManagementStatusesListData,
      );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      getDataLeavingEeaFiltersData();
    }
  }, [filters]);

  useEffect(() => {
    if (vendorManagementActivities?.length) {
      getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsList(dataLeavingEeaList?.results) }, false);
    }
  }, [vendorManagementActivities]);

  const vendorsFilter = dataLeavingEeaFilter?.vendors?.map((filter) => ({ text: filter, value: filter }));
  const serverLocationFilter = dataLeavingEeaFilter?.server_location?.map((filter) => ({
    text: filter,
    value: filter,
  }));
  const referenceVendorsFilter = dataLeavingEeaFilter?.reference_vendors?.map((filter) => ({
    text: filter,
    value: filter,
  }));

  const columns = [
    VendorTypeColumn({}),
    VendorNameColumn({ filters: vendorsFilter || [] }),
    ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    {
      title: () => {
        return (
          <div>
            Server location
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip server-location-tooltip">
                    Server location is found by taking the IP address of the vendor’s server and using an IP lookup tool
                    to locate the country the server is in. In some cases, the server may use a service called
                    “Anycast”, meaning that the same IP address can physically exist in many different locations. As we
                    cannot say either way if these servers are within the EEA or not, we have marked them with{' '}
                    <QuestionCircleOutlined /> so you can investigate further with the vendor in question.
                  </div>
                );
              }}
              overlayClassName="diagnose--tooltip information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      key: 'server_location',
      dataIndex: 'server_location',
      filters: serverLocationFilter || [],
      filterDropdown: (props) => {
        const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
        const getName = (code) => {
          try {
            return regionNamesInEnglish.of(code);
          } catch (err) {
            return 'Unknown';
          }
        };
        return (
          <TableFilterDropdown
            {...props}
            dropdownClassName={'dianose-dashboard_filter'}
            filters={props.filters.map((filter) => ({ ...filter, text: getName(filter.text) }))}
            showSearch
            multiple
          />
        );
      },
      render: (text) => {
        const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
        const getName = (code) => {
          try {
            return regionNamesInEnglish.of(code);
          } catch (err) {
            return 'Unknown';
          }
        };
        const getFlagIcon = (code) => {
          try {
            if (hasFlag(code)) {
              return getUnicodeFlagIcon(code);
            } else {
              return <QuestionCircleOutlined />;
            }
          } catch (err) {
            return null;
          }
        };
        return (
          <div style={{ display: 'flex' }}>
            {text.map((d) => (
              <Tooltip
                key={getName(d)}
                placement="leftTop"
                title={() => {
                  return <div className="detailed-breakdown-cell-tooltip-content">{getName(d)}</div>;
                }}
                overlayClassName="diagnose--tooltip detailed-breakdown-cell-tooltip-overlay"
              >
                <div style={{ cursor: 'pointer', marginRight: '5px', fontSize: '18px' }}>{getFlagIcon(d)}</div>
              </Tooltip>
            ))}
          </div>
        );
      },
      width: 100,
    },
    PrevalenceColumn,
    PrevalenceBenchMarkColumn,
    InformationStorageColumn,
    VendorDisclosedColumn,
    VendorReferenceColumn({ filters: referenceVendorsFilter || [] }),
    // TODO uncomment when vendor details will be fixed
    // {
    //     title: 'Vendor Details',
    //     key: 'vendor_details',
    //     dataIndex: 'vendor_details',
    //     render: (text, record) => {
    //         return (
    //             <div>
    //                 <VendorEvidenceModal data={record} regionsList={regionsList} />
    //             </div>
    //         );
    //     },
    // },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const tableData = useMemo(() => {
    if (!isEnabledVendorStatusManagementFeature) {
      return dataLeavingEeaList?.results;
    }

    return dataLeavingEeaList?.results?.map((row) => {
      const rowWithVendorStatus = {
        ...row,
        vendor_status:
          vendorManagementStatuses?.filter((vendorStatus) => {
            return vendorStatus.vendor === row.vendor_id;
          }) || [],
      };
      return rowWithVendorStatus;
    });
  }, [isEnabledVendorStatusManagementFeature, dataLeavingEeaList?.results, vendorManagementStatuses]);

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    dataLeavingEeaExport(filters);
  };

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The table below lists the vendors that we have observed on your properties that have servers in locations
        outside of the EEA in the period of time selected. Associating a server with a geolocation relies on
        probabilistic methodologies and therefore may not always be accurate. Some server implementations (eg. Anycast
        DNS) make the geolocation resolution more challenging. In these cases where inaccuracy is likely, we return the
        result "unknown" for the server location. We are currently working with several providers to improve the
        accuracy of the insights. If you wish to challenge any of the results, please reach out to your account manager.
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container">
        <div className={'filters_block'}>
          <div className={'quick_filters'} />
          <div className={'downloadCSV'}>
            <Button icon={<DownloadOutlined />} onClick={exportCsv} loading={dataLeavingEeaExportPending} />
          </div>
        </div>
        <Table
          id="detailed-breakdown"
          scroll={{ x: 'max-content', y: 430 }}
          dataSource={tableData}
          columns={columns}
          showSorterTooltip={false}
          pagination={{
            current: page,
            pageSize: limit,
            total: tableFilters?.vendor_status?.length && !vendorManagementStatuses.length ? 0 : dataLeavingEeaList?.total_count,
            position: ['bottomCenter'],
            onChange: onPaginationChange,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
          }}
          onChange={onTableChange}
          rowKey={(record) => record?.vendor_id}
          rowClassName={(record) =>
            record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
              ? 'diagnose-notification-highlight'
              : ''
          }
          loading={{
            className: 'diagnose-spinning',
            spinning: dataLeavingEeaListPending || vendorManagementStatusesPending,
            tip: dataLeavingEeaList?.results?.length
              ? ''
              : 'Processing a large query; please wait or leave it open and check later.',
            size: 'large',
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    dataLeavingEeaList: store.diagnoseDashboardState.getIn(['dataLeavingEeaList', 'value']),
    dataLeavingEeaListPending: store.diagnoseDashboardState.get('dataLeavingEeaListPending'),
    dataLeavingEeaExportPending: store.diagnoseDashboardState.get('dataLeavingEeaExportPending'),
    dataLeavingEeaFilter: store.diagnoseDashboardState.getIn(['dataLeavingEeaFilter', 'value']),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
    vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDataLeavingEeaList,
      getDataLeavingEeaFilters,
      dataLeavingEeaExport,
      getVendorManagementStatusesList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
