import { Record, List, Map } from 'immutable';

export const RangeDates = Record({
  start: null,
  end: null,
});

export const UrlParams = Record({
  user_name: null,
  user_email: null,
  args: null,
});

export const RequestWithParams = Record({
  endpoint: '',
  params: new UrlParams({}),
});

export const ReportParams = Record({
  site_id: null,
  manifest_id: null,
  multi_site_id: null,
  multi_manifest_id: null,
  start_date: null,
  end_date: null,
  account_id: null,
  multi_domain_name: null,
});

export class CustomReport extends Record({
  endpoint: null,
  job_name: '',
  description: '',
  day_limit: null,
  params: List([]),
  is_public: false,
}){
  constructor(report) {
    report.params = List(report.params);
    super(report);
  }
}
