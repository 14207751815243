import React from 'react';
import { Modal, Button, Chip as Tag } from '../../../styleguide';
const CantDeleteModal = ({ siteGroupNames, closeModal }) => {
  return (
    <Modal
      wrapClassName="property-modal"
      title="Can't Delete"
      visible={siteGroupNames.size !== 0}
      onCancel={closeModal}
      footer={[
        <Button key="close" onClick={closeModal}>
          Cancel
        </Button>,
      ]}
    >
      <div className="cant-delete-container-modal">
        <div>
          This property can't be deleted because it falls under the following
          property groups:
        </div>
        <div className="sg-names-container">
          {siteGroupNames.map((name) => (
            <Tag key={name}>{name}</Tag>
          ))}
        </div>
        <div>
          Please remove the property from all property groups it falls under
          before attemping to delete
        </div>
      </div>
    </Modal>
  );
};

export default CantDeleteModal;
