import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import StepSatisfyOption from './StepSatisfyOption';
import { ReferrerRegexMatch } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js'

export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(ReferrerRegexMatch).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    state = {
      isValidRegex: true,
    };

    componentDidMount() {
        if (this.props.editing) ReactDOM.findDOMNode(this.refs.regex).focus();
    }

    handleChangeRegex = (event) => {
      let isValidRegex = true;

      try {
          new RegExp(event.target.value);
      } catch(e) {
          isValidRegex = false;
      }

      this.setState({
        isValidRegex,
      });

      if(isValidRegex) {
        const data = this.props.data.set('regex', event.target.value);
        this.props.onChange(data);
      }
    };

    handleChangeSatisfy = (continueOnSatisfy) => {
        const data = this.props.data.set('continue_on_satisfy', continueOnSatisfy);
        this.props.onChange(data);
    };

    render() {

        var continueOnSatisfy = this.props.data.continue_on_satisfy ? "Yes" : "No";

        const className = classNames('regex-input', { 'invalid-regex': !this.state.isValidRegex });

        if (!this.props.editing) {
            return (<div>Regex: { this.props.data.regex }, Continue On Match: { continueOnSatisfy }</div>);
        } else {
            return (
                <div className="step continue-halt">
                  <label>Regex: </label>
                  <input
                    className={ className }
                    ref="regex"
                    type="text"
                    value={ this.props.data.regex || "" }
                    onChange={ this.handleChangeRegex }
                  >
                  </input>

                  <label>Continue On Match:
                    <StepSatisfyOption
                      value={ this.props.data.continue_on_satisfy }
                      onChange={ this.handleChangeSatisfy }
                    />
                  </label>

                </div>
            );
        }
    }
}
