import React, { useContext, useEffect, useMemo, useState } from "react";
import ConsentAndRejectModal from "./ConsentAndRejectModal";
import { SystemPurpose, VendorWrapper, ActionExtension } from "../../../../../../records/us_privacy_regulation_records";
import {  Table, Button, Tabs, Input, TableFilterDropdown, message, Popconfirm } from "../../../../../../styleguide";
import { CopyOutlined, DeleteFilled, EditFilled, SearchOutlined } from "@ant-design/icons";
import { renderTruncatedTags, getVendorIcon, wrapInInfotip, getVendorType } from "../../../helper";
import { useDebouncedSearch } from "../../../../../../hooks";
import { trimString } from "../../../../../utils";
import { usPrivacyRegulationContext } from "../../../contexts";
import classNames from "classnames";
import DismissErrorModal from "../../../../../common/DismissErrorModal";

const getActionsArray = (consentActions, extenstions) => {
  let arr = [];
  consentActions.forEach((action) => {
    if (action.url) {
      arr.push("Inline URL");
    } else if (action.tagManager) {
      arr.push("Google Tag Manager");
    } else if (action.type == "inline" && Boolean(action.js)) {
      arr.push("Custom JS");
    }
  });
  if (extenstions.cookies.length) {
    arr.push("Cookies");
  }
  return [...new Set(arr)];
};

const recordHasHooks = (record) => {
  return getActionsArray(record.consentActions, record.consentActionExtension).length || getActionsArray(record.rejectActions, record.rejectActionExtension).length 
}

const OptInAndOptOut = (props) => {
  const [makeDataSourceRender, setMakeDataSourceRender] = useState(false);

  const [activeTabKey, setActiveTabKey] = useState("Vendor");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editModal, showEditModal] = useState(false);
  const [editPurpose, setEditPurpose] = useState({});
  const [editPurposeId, setEditPurposeId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [addNewHookModal, showAddNewHookModal] = useState(false);
  const [removeHooksPrompt, setRemoveHooksPrompt] = useState(false);
  const [vendorTypeFilter, setVendorTypeFilter] = useState([]);
  
  const { vendorsIdsMap } = useContext(usPrivacyRegulationContext);

  const onEditPurpose = (record) => {
    showEditModal(true);
    setEditPurpose(activeTabKey == "Vendor" ? new VendorWrapper(record) : new SystemPurpose(record));
    setEditPurposeId(activeTabKey == "Vendor" ? record.vendorId : record.id ?? record.privacyChoice)
  };

  const deleteHooks = (record) => {
    const recordId = activeTabKey == "Vendor" ? record.vendorId : record.id ?? record.privacyChoice;
    record.consentActions = [];
    record.rejectActions = [];
    record.consentActionExtension = new ActionExtension({})?.toJS();
    record.rejectActionExtension = new ActionExtension({})?.toJS();

    if(activeTabKey == "Vendor"){
      const updatedVendorsWrappers = _.cloneDeep(props.usPrivacyRegulation.vendorsWrappers);
      const idx = updatedVendorsWrappers.findIndex(vw => vw.vendorId == recordId);
      if(idx !== -1){
        props.usPrivacyRegulation.vendorsWrappers = [
          ...updatedVendorsWrappers.slice(0, idx),
          record,
          ...updatedVendorsWrappers.slice(idx + 1),
        ];
      }
    } else {
      const updatedCategories = _.cloneDeep(props.usPrivacyRegulation.categories);
      const idx = updatedCategories.findIndex(cat => (cat.id ?? cat.privacyChoice) == recordId);
      if(idx !== -1){
        props.usPrivacyRegulation.categories = [
          ...updatedCategories.slice(0, idx),
          record,
          ...updatedCategories.slice(idx + 1),
        ];
      }
    }
    setMakeDataSourceRender(!makeDataSourceRender)
    setSelectedRowKeys(selectedRowKeys.filter( key => key != recordId))
  }

  const closeModal = () => {
    showEditModal(false);
    showAddNewHookModal(false);
  };

  const handlePurposeSave = (purpose, purposeId) => {
    setEditPurpose(purpose);
    if(activeTabKey == "Vendor"){
      const updatedVendorsWrappers = _.cloneDeep(props.usPrivacyRegulation.vendorsWrappers);
      const idx = updatedVendorsWrappers.findIndex(vw => vw.vendorId == purposeId);
      if(idx !== -1){
        props.usPrivacyRegulation.vendorsWrappers = [
          ...updatedVendorsWrappers.slice(0, idx),
          purpose.toJS(),
          ...updatedVendorsWrappers.slice(idx + 1),
        ];
      }
    } else {
      const updatedCategories = _.cloneDeep(props.usPrivacyRegulation.categories);
      const idx = updatedCategories.findIndex(cat => (cat.id ?? cat.privacyChoice) == purposeId);
      if(idx !== -1){
        props.usPrivacyRegulation.categories = [
          ...updatedCategories.slice(0, idx),
          purpose.toJS(),
          ...updatedCategories.slice(idx + 1),
        ];
      }
    }
    showEditModal(false);
    showAddNewHookModal(false);
    setMakeDataSourceRender(!makeDataSourceRender)
  };

  const removeHooks = () => {
    if(activeTabKey == "Vendor"){
      let updatedVendorsWrappers = _.cloneDeep(props.usPrivacyRegulation.vendorsWrappers);
      updatedVendorsWrappers = updatedVendorsWrappers.map(vw => {
        let updatedVw = _.cloneDeep(vw);
        if(selectedRowKeys.includes(updatedVw.vendorId)){
          updatedVw.consentActions = [];
          updatedVw.rejectActions = [];
          updatedVw.consentActionExtension = new ActionExtension({})?.toJS();
          updatedVw.rejectActionExtension = new ActionExtension({})?.toJS();
        }
        return updatedVw;
      })
      props.usPrivacyRegulation.vendorsWrappers = updatedVendorsWrappers;
      message.success(<>Successfully removed hooks for <b>{selectedRowKeys.length}</b> vendors.</>)
    } else {
      let updatedCategories = _.cloneDeep(props.usPrivacyRegulation.categories);
      updatedCategories = updatedCategories.map(cat => {
        let updatedCat = _.cloneDeep(cat);
        if(selectedRowKeys.includes(updatedCat.id ?? updatedCat.privacyChoice)){
          updatedCat.consentActions = [];
          updatedCat.rejectActions = [];
          updatedCat.consentActionExtension = new ActionExtension({})?.toJS();
          updatedCat.rejectActionExtension = new ActionExtension({})?.toJS();
        }
        return updatedCat;
      })
      props.usPrivacyRegulation.categories = updatedCategories;
      message.success(<>Successfully removed hooks for <b>{selectedRowKeys.length}</b> privacy choices.</>)
    }
    setSelectedRowKeys([])
    setMakeDataSourceRender(!makeDataSourceRender)
    setRemoveHooksPrompt(false);
  }

  const handleCopyClick = async (elementId) => {
    try {
      const textToCopy = document.getElementById(elementId).innerText;
      await navigator.clipboard.writeText(textToCopy);
      message.info('Vendor Id copied', 1)
    } catch (err) {
      message.info('Failed to copy Vendor Id', 1)
    }
  };

  let columns = [
    {
      title: "Vendor",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: [
        {
          icon: <i  className="fas fa-circle" style={{color:'#FCA015',width: 10, height: 10,  marginRight: 10}} />,
          text: 'IAB',
          value: 'IAB',
        },
        {
          icon: <i  className="fas fa-circle" style={{color:'#FF5D89',width: 10, height: 10,  marginRight: 10}} />,
          text: 'MSPA Signatory / Certified',
          value: 'MSPA',
        },
        {
          icon: <i className="fas fa-circle" style={{color:'#C7C7C7',width: 10, height: 10,  marginRight: 10}} />,
          text: 'Custom',
          value: 'CUSTOM',
        },
      ],
      filterDropdown: (props) => <TableFilterDropdown {...props} selectAllOption dropdownClassName="add-vendors-table-filter" handleSelectedKeysOnSave={setVendorTypeFilter}/>,
      render: (name, vendorRecord) => {
        const icon = getVendorIcon(vendorRecord);
        const elementId = `vendor_id_${vendorRecord.vendorId}`
        const content = <div className='vendor-name-infotip'><p>{icon} {vendorRecord.ids?.mspaId ? "MSPA Signatory / Certified" : vendorRecord.ids?.iabId ? "IAB" : "Custom"}</p><span>ID: <span id={elementId} className='vendor-id'>{vendorRecord.vendorId}</span></span><CopyOutlined onClick={() => handleCopyClick(elementId)}/></div>
        return (
          wrapInInfotip(<span>
            {icon} {trimString(name, 20)}
          </span> , content)
        )
      }
    },
    {
      title: "Privacy Choice",
      dataIndex: "privacyChoice",
      key: "privacyChoice",
      render: (text, record) => record.privacyChoice ?? record.name,
    },
    {
      title: "Opt In Hooks",
      dataIndex: "consentActions",
      key: "consentActions",
      render: (consentActions, PurposeRecord) =>
        renderTruncatedTags(
          getActionsArray(consentActions, PurposeRecord.consentActionExtension)
        ),
    },
    {
      title: "Opt Out Hooks",
      dataIndex: "rejectActions",
      key: "rejectActions",
      render: (rejectActions, PurposeRecord) =>
        renderTruncatedTags(
          getActionsArray(rejectActions, PurposeRecord.rejectActionExtension)
        ),
    },
  ];

  let hooksModalRecords = []
  switch(activeTabKey) {
    case 'Vendor':
      columns =  columns.filter(column => column.dataIndex !== 'privacyChoice');
      hooksModalRecords = props.usPrivacyRegulation.vendorsWrappers.map(vw => ({ ...vw, ids: vendorsIdsMap[vw.vendorId] }))
      break;
    case 'Privacy Choice':
      columns = columns.filter(column => column.dataIndex !== 'name');
      hooksModalRecords = props.usPrivacyRegulation.categories;
      break;
  }
  

  if (!props.readOnly) {
    columns.push({
      title: "Actions",
      dataIndex: activeTabKey == 'Vendor' ? "vendorId" : 'name',
      key: "name",
      width: '200px',
      align: 'right',
      className: 'actions',
      render: (id, record, idx) => {
        return (
          <>
            <Button className="icon" type="primary" onClick={() => onEditPurpose(record)}>
              <EditFilled />
            </Button>
            <Popconfirm
              title={`Are you sure remove hooks for the ${activeTabKey}?`}
              trigger="click"
              icon={null}
              onConfirm={() => deleteHooks(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" className="icon">
                {wrapInInfotip(<DeleteFilled />, "Remove Hooks")}
              </Button>
            </Popconfirm>
          </>
        );
      },
    });
  }

  let modal = editModal ? (
    <ConsentAndRejectModal
      visible={editModal}
      purpose={editPurpose}
      purposeId={editPurposeId}
      handlePurposeSave={handlePurposeSave}
      closeModal={closeModal}
      readOnly={false}
      records={hooksModalRecords}
      label={activeTabKey}
    />
  ) : null;
  
  modal = addNewHookModal ? (
    <ConsentAndRejectModal
      isAddNew={true}
      visible={addNewHookModal}
      purpose={editPurpose}
      purposeId={editPurposeId}
      handlePurposeSave={handlePurposeSave}
      closeModal={closeModal}
      readOnly={false}
      records={hooksModalRecords?.filter(record => !recordHasHooks(record))}
      label={activeTabKey}
    />
  ) : modal;
  //debouncing search for performance
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  const applyVendorTypeFilter = (record, vendorTypeFilter) => {
    if (vendorTypeFilter.length === 0) return true;
    const vendorType = getVendorType(record);
    return vendorTypeFilter[0] === vendorType;
  };
  
  const filterRecordsBySearch = (records, searchValue, fieldNames) => {
    if (searchValue.trim().length === 0) return records;
    return records.filter(record =>
      fieldNames.some(fieldName => record[fieldName].toLowerCase().includes(searchValue.trim().toLowerCase()))
    );
  };
  
  const dataSource = useMemo(() => {
    let dataSource = [];
  
    if (activeTabKey === "Vendor") {
      dataSource = props.usPrivacyRegulation.vendorsWrappers
        .map(vw => ({ ...vw, ids: vendorsIdsMap[vw.vendorId] }))
        .filter(record => applyVendorTypeFilter(record, vendorTypeFilter));
    } else if (activeTabKey === "Privacy Choice") {
      dataSource = props.usPrivacyRegulation.categories;
    }
  
    dataSource = dataSource.filter(record => recordHasHooks(record));
  
    switch (activeTabKey) {
      case 'Vendor':
        dataSource = filterRecordsBySearch(dataSource, searchValue, ['vendorId', 'name']);
        break;
      case 'Privacy Choice':
        dataSource = filterRecordsBySearch(dataSource, searchValue, ['privacyChoice']);
        break;
    }
  
    return dataSource;
  }, [searchValue, activeTabKey, makeDataSourceRender, vendorTypeFilter]) 

  const rowSelection = {
    selectedRowKeys,
    onSelect : (record, selected, selectedRows, nativeEvent) => {
      if(selected) {
        setSelectedRowKeys((selectedKeys) => [... new Set(selectedKeys.concat(activeTabKey == "Vendor" ? record.vendorId : record.id ?? record.privacyChoice))])
      } else {
        setSelectedRowKeys((selectedKeys) => selectedKeys.filter(key => key !== (activeTabKey == "Vendor" ? record.vendorId : (record.id ?? record.privacyChoice))))
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if(selected){
        setSelectedRowKeys((selectedKeys) => [...new Set(selectedKeys.concat(dataSource.map(v => activeTabKey == "Vendor" ? v.vendorId : v.id ?? v.privacyChoice)))]);
      }else{
        setSelectedRowKeys((selectedKeys) => selectedKeys.filter((key) => !dataSource.map(v => activeTabKey == "Vendor" ? v.vendorId : v.id ?? v.privacyChoice).includes(key)));
      }
    },
    checkStrictly: false
  };

  useEffect(()=>{
    setSelectedRowKeys([])
  },[activeTabKey])

  return (
    <>
      {props.changesDetectedWarning}
      {!props.readOnly ? (
        <div className="step-header">
          <h4>Opt In/Opt Out Hooks</h4>
        </div>
      ) : null}
      <div className="step-breif">
        This section enables you to employ code hooks that are triggered when a user is in either an opted in or opted out state for each of the privacy choices or vendors that you have selected.
        <br/>
        {!props.readOnly
          ? "For example, if a user makes a choice to opt into the processing of their sensitive category data, you may decide to trigger custom javascript that signals to vendors that are using sensitive category data that they can be triggered on this user."
          : null}
      </div>
      <div className="step-content">
      <Tabs
        defaultActiveKey="Vendor"
        onChange={(key) => setActiveTabKey(key)}
        activeKey={activeTabKey}
        className="spsg-normal opt-in-out-hooks"
      >
        <Tabs.TabPane tab="Vendor" key="Vendor">
        </Tabs.TabPane>
        <Tabs.TabPane tab="Privacy Choice" key="Privacy Choice">
        </Tabs.TabPane>
      </Tabs>
      <div className="vendor-management-table-header-bar">
        <Input
          placeholder={activeTabKey == "Vendor" ? "Search vendor name or ID" : "Search Privacy Choice"}
          type="search"
          onChange={({ target: { value } }) => {
            debouncedChangeHandler(value);
          }}
          suffix={<SearchOutlined />}
        />
       {!props.readOnly ? (
        <div className="button-grp">
          <div className={classNames("bulk-actions", selectedRowKeys.length ? "" : "disabled")}>
            <b>Bulk actions: </b>
            <Button type="primary" className="icon" onClick={() => setRemoveHooksPrompt(true)} disabled={selectedRowKeys.length == 0}>
              {wrapInInfotip(<DeleteFilled/>, "Remove Hooks")}
            </Button>
          </div>
          <Button type="primary" onClick={() => {showAddNewHookModal(true)}}>+ Add Hook</Button>
        </div>
        ) : null}
      </div>
      <Table
        className={classNames("opt-in-out-hooks", props.readOnly ? "th-white" : "")}
        pagination={{
          position: ['bottomCenter'],
          showTotal: (total) => <div>Total :{' '}<b>{total}</b></div>,
          size:'small',
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '50', '100'],
          locale: { items_per_page: ' Records per page' },
        }}
        columns={columns}
        dataSource={dataSource}
        bordered={false}
        rowKey={(record) => `${activeTabKey == "Vendor" ? record.vendorId : record.id ?? record.privacyChoice}`}
        rowSelection={!props.readOnly && rowSelection}
        locale={{ emptyText: 'No hooks added'}}
        height={'40vh'}
      />
      </div>
      {modal}
      {removeHooksPrompt ? (
        <DismissErrorModal
          modalWrapperClass="operation-confirmation"
          title={"Remove Hooks"}
          error={<><div>Are you sure you want to remove selected hooks?</div></>}
          isModalVisible={removeHooksPrompt}
          handleCancel={() => setRemoveHooksPrompt(false)}
          renderOk={true}
          okText={"Remove"}
          cancelText={"Cancel"}
          handleOk={() => removeHooks()}
          primaryAction="submit"
        />
      ) : null}
    </>
  );
};

export default OptInAndOptOut;
