import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';
import { Button } from '../../../styleguide';

export default class DetailStandardModal extends React.Component {
  static propTypes = {
    publishStandard: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  render() {
    const footer = [
      <Button key="1" onClick={this.props.closeModal} className="cancel">
        Cancel
      </Button>,
      <Button key="2" onClick={this.props.publishStandard} type="primary">
        Publish
      </Button>,
    ];
    return (
      <Modal
        onCancel={this.props.closeModal}
        className='purple new-design'
        visible
        footer={footer}
      >
        <p className="title">Standard - Publish</p>

        <div className='modal-container'>
          <p>Publishing <span>{this.props.standardName}</span> will give you the ability to apply the standard to all new and existing property sets.</p>
        </div>
      </Modal>
    );
  }
}