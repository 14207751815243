import { List, OrderedSet, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched, generateAuthToken } from '../helper.js';
import { FETCHED } from '../../constants.js';
import { USPrivacyRegulation,RegulationListError, SystemPurpose, SystemVendor } from '../../records/us_privacy_regulation_records.js';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const getBaseUrl = () => {
    return `${permissionsSvcBaseUrl}/usnat/`;
};

async function checkVendorListStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    const errors = await response.json();
    throw new List(errors).map(err => new RegulationListError(err));
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function getAccountsListAjax() {
    const url = 'vendor-list/account-list';
    if (isFetched(url)) {
      return Promise.resolve(FETCHED);
    } else {
      return fetch(getBaseUrl() + url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
          addToFetched(url);
          return List(resp.map(s => new USPrivacyRegulation(s) ));
        });
    }
}

export function getUSPrivacyRegulationAjax(id) {
  const url = `vendor-list?vendorListId=${id}`;
  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      resp.categories = resp.categories?.map(cat => {
        if(cat.type == 'SYSTEM_STACK' && !cat.vendorCategorization?.length){
          cat.vendorCategorization = cat.categories[0]?.vendorCategorization ?? {};
        }
        return cat;
      })
      return new USPrivacyRegulation(resp);
    });
}

export function cloneRegulationListAjax(accountId, vendorListId, cloneName) {
  return fetch(getBaseUrl() + `vendor-list/clone?vendorListId=${vendorListId}`, {
    method: 'POST',
    body: JSON.stringify({ name : cloneName}),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new USPrivacyRegulation(resp);
    });
}

export function deleteRegulationAjax(accountId, id) {
  return fetch(getBaseUrl() + `vendor-list?accountId=${accountId}&vendorListId=${id}`, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new USPrivacyRegulation(resp);
    });
}

export function activateDeactivateRegulationAjax(regulation) {
  return fetch(getBaseUrl() + `vendor-list/active`, {
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "_id": regulation.id,"isActive": !regulation.isActive})})
    .then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new USPrivacyRegulation(resp);
    });
}

//purposes and stacks
export function getSystemPurposesAjax() {
  const url = `admin/system_purposes`;
  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp.map(p => new SystemPurpose(p).set('defaultLegalBasis', 'OPT-OUT').set('purposeRef',{ id : p._id, systemId: p.systemId})));
    });
}

export function getSystemStacksAjax(systemPurposes) {
  const url = 'admin/system_stacks';
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return List(resp.map(s => {
          return new SystemPurpose(s)
            .set('type', 'SYSTEM_STACK')
            .set('systemStackRef', {id: s._id, systemId: s.systemId})
            .set('defaultLegalBasis', 'CONSENT')
            // .set('iabStackRef', s._id)
            .delete('id')
            .set('categories', List(s.systemPurposes.map(pId => {
              const purpose = systemPurposes.find(purpose => purpose.purposeRef.id == pId)
              return purpose.set('purposeRef', {id: purpose.id, systemId: purpose.systemId}).set('defaultLegalBasis', 'CONSENT').delete('id')
            }))
              .set('id', null)
            )
        })
        )
      });
  }
}

export function createUSPrivacyRegulationAjax(accountId, list) {
  return fetch(getBaseUrl() + 'vendor-list', {
    method: 'POST',
    body: JSON.stringify(generateRegulationData(accountId, list)),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      const updatedResp = Object.assign({}, resp);
      // updatedResp.clientSideMessageId = list.clientSideMessageId;
      return new USPrivacyRegulation(updatedResp);
    });
}

export function updateUSPrivacyRegulationAjax(accountId, list) {
  return fetch(getBaseUrl() + 'vendor-list', {
    method: 'PUT',
    body: JSON.stringify(generateRegulationData(accountId, list)),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => { return resp.json() })
    .then(resp => {
      resp.categories = resp.categories?.map(cat => {
        if(cat.type == 'SYSTEM_STACK' && !cat.vendorCategorization?.length){
          cat.vendorCategorization = cat.categories[0]?.vendorCategorization ?? {};
        }
        return cat;
      })
      return new USPrivacyRegulation(resp);
    });
}

export function verifyMSPASinatoryId(signatoryId) {
  const url = 'admin/mspa-signatory-list';
  const queryParams = `?signatoryId=${signatoryId}`
  return fetch(getBaseUrl() + url + queryParams, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
}

export function getGlobalVendorsAjax() {
  const url = 'admin/system_vendors'
   if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return OrderedSet(resp.map(vendor => new SystemVendor(vendor)));
      });
    } 
}
function generateRegulationData(accountId, list) {
  const showIdentificationList = list?.showIdentificationList
  const categories = list.categories?.map(c => {
    if(c.get("id")?.indexOf("temp_") === 0){
      c = c.set("id", null);
    }
    let id = c.id;
    if (c.systemPurposes.includes(c.id)) {
      id = null;
    }
    let category = c.toMap().delete('systemId')/*.set('vendors', c.vendorIds).delete('vendorIds')*/.delete('id').delete('systemPurposes');
    if (category.get('purposeRef')) category = category.set('purposeRef', category.get('purposeRef').id);
    if (category.get('systemStackRef')) category = category.set('systemStackRef', category.get('systemStackRef').id);
    // if (!category.get('iabStackRef')) category = category.delete('iabStackRef');
    if (!category.get('translations')) category = category.delete('translations');

    if(typeof category.get('respectGPC') === 'undefined') category = category.set('respectGPC', null);

    if (category.get('categories') && Object.keys(category.get('categories'))?.length) {
      const updatedCategories = category.get('categories').map(c => {
        //before save comes as a string
        c = c.toMap().delete('systemId')/*.set('vendors', c.vendorIds).delete('vendorIds')*/.delete('systemPurposes');
        if (c.get('id')) {
          c = c.toMap().set('_id', c.get('id')).delete('id');
          if(c.get("_id").indexOf("temp_") === 0){
            c = c.delete("_id");
          }
        } else {
          c = c.toMap().delete('id').delete('_id');
        }
        if (c.get('purposeRef')) c = c.set('purposeRef', c.get('purposeRef').id);

        //https://sourcepoint.atlassian.net/browse/DIA-3304 update sub Cats with latest vendorCategorization mapping which is available in SYSTEM_STACKS category
        const stackVendorCatsMap = _.cloneDeep(category.get('vendorCategorization'));
        Object.values(stackVendorCatsMap).forEach(vCat => {delete vCat._id});
        const stackVendorCatsArray = Object.values(stackVendorCatsMap);
        if(c.get('vendorCategorization') && Object.keys(c.get('vendorCategorization'))?.length){
          let updatedSubCatVendorCatsMap = c.get('vendorCategorization');
          Object.keys(updatedSubCatVendorCatsMap).forEach(key => {
            if(!stackVendorCatsMap[key]){
              delete updatedSubCatVendorCatsMap[key];
            }
          })
          stackVendorCatsArray.forEach(vCat => {
            if(!updatedSubCatVendorCatsMap[vCat.vendorId]){
              updatedSubCatVendorCatsMap[vCat.vendorId] = vCat;
            }
          })
          c = c.set('vendorCategorization', Object.values(updatedSubCatVendorCatsMap));
        } else{
          c = c.set('vendorCategorization', stackVendorCatsArray);
        } 
        return c
      })
      category = category.set('categories', updatedCategories)
    }

    if(category.get('vendorCategorization')){
      if(category.get('type') == 'SYSTEM_STACK'){
        category = category.set('vendorCategorization', [])
      } else {
        category = category.set('vendorCategorization', Object.values(category.get('vendorCategorization')))
      }
    }

    if (id) category = category.set('_id', id);
    return category;
  });

  const vendorsWrappers = list.vendorsWrappers
    ? list.vendorsWrappers
        .map((vw) => {
          const id = vw.id;
          let updatedVendorWrapper = vw.toMap().delete("id");
          if (id) {
            updatedVendorWrapper = updatedVendorWrapper.set("_id", id);
          }
          const vendorId = updatedVendorWrapper.get("vendorId")
          if(vendorId) {
            updatedVendorWrapper = updatedVendorWrapper.set("vendor", vendorId) // vendor: Types.ObjectId | IVendorSchema; (backend)
            updatedVendorWrapper = updatedVendorWrapper.delete("vendorId");
          }
          updatedVendorWrapper = updatedVendorWrapper.update(
            "urlMappingRules",
            (mappings) => mappings.map((patterns) => ({ patterns }))
          );
          return updatedVendorWrapper;
        })
        .toJS()
    : [];

  const data = {
    accountId: accountId.toString(),
    name: list.name,
    description: list.description,
    // vendors: list.vendors.map(v => v.id).toSet().toJS(),
    categories,
    consentScope: list.consentScope,
    shareRootDomain: list.shareRootDomain,
    // hasConsentCompensation: list.hasConsentCompensation,
    // useSpecialTreatmentP1: list.useSpecialTreatmentP1,
    // specialTreatmentP1Geo: list.specialTreatmentP1Geo,
    siteIds: list.siteIds.toSet().toJS(),
    isActive: list.isActive,
    vendorsWrappers,
    autoUpdateVendorScan: list.autoUpdateVendorScan,
    // autoUpdateIabVendors2: list.allIabAndAutoUpdate,
    cookieMaxAge: list.cookieMaxAge,
    writeFirstPartyCookiesFromServer: list.writeFirstPartyCookiesFromServer,
    type: list.type,
    defaultLegalBasis: list.defaultLegalBasis,
    // publisherCmpId: list.publisherCmpId,
    // publisherCmpId2: list.publisherCmpId2,
    // iabSpecialFeatures: (list.iabSpecialFeatures && list.iabSpecialFeatures.size)
    //   ? list.iabSpecialFeatures.toJS()
    //   : list.iabSpecialFeatures,
    // displaySpecialPurposesNFeatures: list.displaySpecialPurposesNFeatures,
    // customElementsFirst: list.customElementsFirst,
    appliesGeos: list.appliesGeos.map(geo => geo === 'CAN' ? 'CA' : geo),
    exclusionGeos: list.exclusionGeos.map(geo => geo === 'CAN' ? 'CA' : geo),
    // useVendorActionsV2: list.useVendorActionsV2,
    // publisherPurposes: list.publisherPurposes,
    // setEuconsentCookie: list.setEuconsentCookie,
    // vendorScanCategorizations: list.vendorScanCategorizations,
    // onlyCustom: list.onlyCustom,
    // includeDisclosedVendors: list.includeDisclosedVendors,
    // noServerStorage: list.noServerStorage,
    // noUniqueIdReporting:list.noUniqueIdReporting,
    signatoryId: list.signatoryId,
    legislation: list.legislation,
    metaData: list.metaData
  };

  if (list.id) {
    data._id = list.id;
  }

  return data;
}