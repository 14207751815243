import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { Tabs } from 'antd';
import {
  getDomainSets,
} from '../../../../actions/domain_set_actions';
import { getSystemAccuracy, getSystemAccuracyTimeline } from '../../../../actions/accuracy_actions';
import Loading from '../../../common/Loading.js.jsx';
import { getRules } from '../../../../actions/standard_actions';
import { getAccountOwners } from '../../../../actions/account_actions';
import AccuracyPerformanceGraph from './AccuracyPerformanceGraph';
import AccuracyInsightsTable from './AccuracyInsightsTable';

const { TabPane } = Tabs;

class PropertySetEvaluation extends React.Component {
  static propTypes = {
    getAccountOwners: PropTypes.func.isRequired,
    goToManualScoring: PropTypes.func.isRequired,
    getSystemAccuracy: PropTypes.func.isRequired,
  }

  state = {
    selectedAccount: null,
    selectedPropertySet: null,
    propertySetAccuracy: null,
    propertySetComposition: null,
    domains: [],
    accuracyMap: {},
    everageManualAccuracy: {},
  }

  onCurrentUserAvailableDo = async () => {
    if (this.props.currentUser.accountId === 22) {
      this.props.getSystemAccuracy();
      this.props.getSystemAccuracyTimeline();
      this.props.getRules();
    }
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  getEverageAccuracy = (productStatus, na) => {
    let rules = this.props.systemAccuracy;
    delete rules.status;
    delete rules.contentType;
    rules = Object.values(rules).flat();

    if (productStatus) {
      rules = rules.filter(rule => {
        const systemRule = this.props.rules.find(r => r.ruleId === rule.ruleId);
        return systemRule && (systemRule.productStatus === productStatus);
      });
    }
    if (na) {
      return parseInt((rules.map(r => r.falseUndetermined / (r.falseUndetermined + r.trueUndetermined + r.falseNegative + r.falsePositive + r.trueNegative + r.truePositive)).reduce((a,b) => a + b) / rules.length) * 100);
    } else {
      return parseInt((rules.map(r => r.overallAccuracy).reduce((a,b) => a + b) / rules.length) * 100);
    }
  }

  generateRuleTable = () => {
    const columns = [
      {
        title: 'Rule ID',
        dataIndex: 'ruleId',
        key: 'ruleId',
      },
      {
        title: 'Rule Name',
        dataIndex: 'ruleName',
        key: 'ruleName',
      },      
      {
        title: 'Property Composition Based',
        dataIndex: 'compositionBased',
        key: 'compositionBased',
      },
      {
        title: 'Manual Scores Based',
        dataIndex: 'sampleBased',
        key: 'sampleBased',
      },
    ];
    
    const dataSource = this.state.propertySetAccuracy.map(obj => {
      const rule = this.props.rules.find(r => r.ruleId === obj.ruleId);
      return {
        key: Math.random().toString(),
        ruleId: obj.ruleId,
        ruleName: rule ? rule.name : 'mock rule name',
        compositionBased: `${parseInt(obj.estimatedAccuracy * 100)}%`,
        sampleBased: this.state.accuracyMap[obj.ruleId] !== null ? `${(this.state.accuracyMap[obj.ruleId] * 100).toFixed(0)}%` : 'N/A',
      };
    });

    return { columns, dataSource };
  }


  renderEverageAccuracy = (na = false) => {
    let everageAccuracyAll = 0;
    let everageAccuracyGA = 0;
    let everageAccuracyBeta = 0;
    let everageAccuracyAlpha = 0;
    if (Object.keys(this.props.systemAccuracy).length && this.props.rules.size) {  
      everageAccuracyAll = this.getEverageAccuracy(null, na);
      everageAccuracyGA = this.getEverageAccuracy('GA', na);
      everageAccuracyBeta = this.getEverageAccuracy('Beta', na);
      everageAccuracyAlpha = this.getEverageAccuracy('Alpha', na);
    }

    return (
      <div>

        <div className='flex-row'>

        { everageAccuracyAll ?
          <div className="summary-score">
            <div className="score-title">
              <div>{`Average ${na ? 'N/A' : 'Accuracy'}`}</div>
              <div>{everageAccuracyAll}%</div>
            </div>
            <div className="progress green"><div className="amount" style={{width: (everageAccuracyAll || 0) + '%'}}></div></div>
          </div> :
          null
        }

        { everageAccuracyGA ?
          <div className="summary-score">
            <div className="score-title">
              <div>{`GA Rules ${na ? 'N/A' : 'Accuracy'}`}</div>
              <div>{everageAccuracyGA}%</div>
            </div>
            <div className="progress green"><div className="amount" style={{width: (everageAccuracyGA || 0) + '%'}}></div></div>
          </div> :
          null
        }

        { everageAccuracyBeta ?
          <div className="summary-score">
            <div className="score-title">
              <div>{`Beta Rules ${na ? 'N/A' : 'Accuracy'}`}</div>
              <div>{everageAccuracyBeta}%</div>
            </div>
            <div className="progress turquoise"><div className="amount" style={{width: (everageAccuracyBeta || 0) + '%'}}></div></div>
          </div> :
          null
        }

        { everageAccuracyAlpha ?
          <div className="summary-score">
            <div className="score-title">
              <div>{`Alpha Rules ${na ? 'N/A' : 'Accuracy'}`}</div>
              <div>{everageAccuracyAlpha}%</div>
            </div>
            <div className="progress red"><div className="amount" style={{width: (everageAccuracyAlpha || 0) + '%'}}></div></div>
          </div> :
          null
        }

        </div>
      </div>
    );
  }

  render() {
    // summary-card
    let tableData;
    if (this.state.propertySetAccuracy && this.props.rules.size) {
      tableData = this.generateRuleTable();
    }

    return (
      <div className='snapshot-evolution-container'>
        <div className="subtitle-privacy-lens">Insights</div>
        <div className='summary-card'>

            <p className='system-accuracy'>System Accuracy</p>

            { this.renderEverageAccuracy() }

            <p>N/A (%)</p>

            { this.renderEverageAccuracy(true) }

        </div>

        <div className="subtitle-privacy-lens">Accuracy Performance and N/A Evolution</div>
        <div className='summary-card accuracy-graph'>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Accuracy" key="1">
              <AccuracyPerformanceGraph 
                rules={this.props.rules} 
                systemAccuracyTimeline={this.props.systemAccuracyTimeline} 
                systemAccuracy={this.props.systemAccuracy}
              />
            </TabPane>
            <TabPane tab="N/A" key="2">
              <AccuracyPerformanceGraph 
                rules={this.props.rules} 
                systemAccuracyTimeline={this.props.systemAccuracyTimeline} 
                systemAccuracy={this.props.systemAccuracy}
                na
              />
            </TabPane>
          </Tabs>
        </div>

        <div className='detailed-view-container'>
          <div className="subtitle-privacy-lens"><span>Detailed View</span> - Accuracy And N/A Insights Across Selection</div>
          <div className='summary-card detail-view'>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Accuracy" key="1">
                <AccuracyInsightsTable
                  rules={this.props.rules} 
                  systemAccuracy={this.props.systemAccuracy}
                />
              </TabPane>
              <TabPane tab="N/A" key="2">
                <AccuracyInsightsTable
                  rules={this.props.rules}
                  systemAccuracy={this.props.systemAccuracy}
                  na
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountOwners: store.accountState.getIn(['accountOwners', 'value']),
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    rules: store.standardState.getIn(['rules', 'value']),
    systemAccuracy: store.scanAccuracyState.getIn(['systemAccuracy', 'value']),
    systemAccuracyTimeline: store.scanAccuracyState.getIn(['systemAccuracyTimeline', 'value']),
    pendingRequestsMap: new Map({
      domainSets: store.domainSetState.getIn(['domainSets', 'pending']),
      accountOwnersPending: store.accountState.getIn(['accountOwners', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
  getAccountOwners,
  getDomainSets,
  getRules,
  getSystemAccuracy,
  getSystemAccuracyTimeline,
},
)(PropertySetEvaluation);
