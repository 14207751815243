import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'antd';

import AntdFormItem from '../../../common/AntdFormItem.jsx';
import { MessageConfigRecord } from '../../../../records/message_config_records.js';

export default class MessageEditIncludeDefaultStyles extends React.Component {

  constructor (props) {
    super(props);
    this.onUpdate = this.onUpdate.bind(this);
  }

  onUpdate (event) {
    this.props.updateMessageConfig(
      this.props.messageConfig.setIn(
        [ 'data', 'style', 'includeDefaultStyles' ],
        event.target.checked
      )
    );
  }

  render () {
    const includeDefaultStyles =
      this.props.messageConfig.getIn([ 'data', 'style', 'includeDefaultStyles' ]);

    return (
      <div className="message-edit-include-default-styles">
        <h3>Include Default Message Styles</h3>
        <div className="options-styles-property">
          <div className="options-include-default-styles">
            <AntdFormItem
              fieldName="message-include-default-styles"
              initialValue={ includeDefaultStyles }
              onChange={ this.onUpdate }
              form={ this.props.form }
              editing={ this.props.editing }
              type={ 'checkbox' }
            />
          </div>
        </div>
      </div>
    );
  }

}

MessageEditIncludeDefaultStyles.propTypes = {
  messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
  updateMessageConfig: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
}
