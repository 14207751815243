import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        colSpan: PropTypes.string,
    };

    getDefaulProps = () => {
        return {
            className: "",
            onClick: function() {},
            colSpan: "1",
        };
    };

    render() {
        return (
            <td colSpan={ this.props.colSpan } onClick={ this.props.onClick } className={ ("table-cell " + this.props.className).trim() } >
              { this.props.children }
            </td>
        )
    }
}
