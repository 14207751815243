import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Collapse } from "antd";
import { Table, Input, Button, message } from "../../../styleguide";
import moment from 'moment';
import { Map, List } from "immutable";
import RecentChangesModal from "../../consent/performanceNew/RecentChangesModal";
import MessagePerformaceModal from "./MessagePerformaceModal";
import ExportToolTip from "../dataprivacy/ExportToolTip";
import {
  ResponsiveContainer,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend
} from "recharts";
import {
  LINE_GRAPH_COLOR_LIST,
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST,
  LINE_GRAPH_LEGEND_PATTERN_LIST
} from "../../../constants";
import { Link } from "react-router";
import { createCSVExportData } from "../../consent/performanceNew/helper";
import { mapReduceAvg, mapReduceSum } from "../../consent/gdpr_performance_tcfv2_v2/helper";

const { Panel } = Collapse;

export default class CCPADnsaMsg extends React.Component {
  state = {
    recentChangesModal: false,
    msgPerformanceVisible: false,
    messageSearch: "",
    searchText: "",
    lineToggle: Map({
      totalpageviews: true,
      actionoptin: true,
      actionoptout: false
    }),
    selectedRowKeys: List(),
    rechartMargin: { top: 5, right: 30, left: 20, bottom: 15 }
  };

  toggleLine = tKey => {
    let toggles = this.state.lineToggle;
    const inverse = !toggles.get(tKey);
    toggles = toggles.set(tKey, inverse);
    this.setState({ lineToggle: toggles });
  };

  setGraphType = graphType => {
    this.setState({ graphType });
  };

  showModal = smm => {
    this.setState({
      [smm]: true
    });
  };

  handleCancel = cmm => {
    this.setState({
      [cmm]: false
    });
  };

  renderTooltip = props => {
    let propertySiteIds;
    let propertyDate;
    let logData;
    let tooltipData;
    if (props.payload && props.payload.length > 0 && props.payload[0]) {
      let arrList = [];
      props.payload.map((item, i) => {
        arrList = arrList.concat(item.payload["siteIds" + i]);
      });
      let uniqueArr = [...new Set(arrList)];
      propertySiteIds = uniqueArr;
      propertyDate = props.payload[0].payload.date;
      logData = this.props.changeLogData.filter((item, i) => {
        var common = item
          .get("siteIds")
          .filter(element => propertySiteIds.includes(element));
        return (
          item.get("date") ==
            moment(propertyDate, "MMM Do").format("YYYY-MM-DD") &&
          common.length > 0
        );
      });
      tooltipData = logData.get(0);
    }

    if (tooltipData) {
      return (
        <div className="tooltip-custom">
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Change Type : </div>
            <span>{this.capitalize(tooltipData.get("operationtype"))}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Date : </div>
            <span> {tooltipData.get("date")} </span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Entity Type : </div>
            <span>{tooltipData.get("entitytype")}</span>{" "}
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Entity : </div>
            <span>{tooltipData.get("entitynames")}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Properties: </div>
            <span>{tooltipData.get("properties")}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">User : </div>
            <span>{tooltipData.get("user")}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  showTooltip = () => {
    return (
      <Tooltip
        content={this.renderTooltip}
        filterNull={true}
        active={false}
        cursor={false}
      />
    );
  };
  capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  drawGraph = graphData => {
    let lineArr = [];
    graphData.map((d, j) => {
      if (this.state.lineToggle.get("totalpageviews")) {
        lineArr.push(
          <Line
            key={"t-" + j}
            dot={false}
            yAxisId={
              this.state.lineToggle.get("actionoptin") ||
              this.state.lineToggle.get("actionoptout")
                ? "right"
                : "left"
            }
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"totalpageviews" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(0)}
          />
        );
      }
      if (this.state.lineToggle.get("actionoptin")) {
        lineArr.push(
          <Line
            key={"a-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"actionoptin" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(1)}
          />
        );
      }
      if (this.state.lineToggle.get("actionoptout")) {
        lineArr.push(
          <Line
            key={"s-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"actionoptout" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(2)}
          />
        );
      }
    });
    return lineArr;
  };

  dataFormater = number => {
    return number + "%";
  };

  handleCompareMsgs = () => {
    let msgCount = 0;
    this.state.selectedRowKeys.forEach((cm) => msgCount += cm.get('msgs').size);
    if(msgCount >= 2) {
      this.showModal("msgPerformanceVisible");
    }else{
        message.warning('Select at least 2 messages');
    }
  };

  render() {
    var tableData = this.props.ccpaDnsaData.map((items, i) =>
      items.get("messageTable")
    );
    var graphData = this.props.ccpaDnsaData.map((items, i) =>
      items.get("messageGraph")
    );
    var msgPerformance = this.props.ccpaDnsaData.map((items, i) =>
      items.get("messagePerformanceList")
    );

    let graphList = List();
    let unionList = List();
    graphData.map((g, i) => {
      unionList = unionList
        .toSet()
        .union(g.toSet())
        .toList();
    });
    let groupedByDates = unionList.groupBy(dataPoint => dataPoint.get("date"));
    groupedByDates.map((item, i) => {
      let mergeMap = Map();
      item.map(m => {
        mergeMap = mergeMap.merge(m);
      });
      graphList = graphList.push(mergeMap);
    });
    graphList = graphList.sort(
      (a, b) =>
        moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
    );

    const renderLegend = props => {
      return (
        <React.Fragment>
          {graphData.map((entry, index) => (
            <div className="legend-custom" key={`legend-${index}`}>
              <svg height="5" width="28">
                <line
                  x1="0"
                  y1="0"
                  x2="200"
                  y2="0"
                  style={{
                    stroke: "#555758",
                    strokeWidth: "5",
                    strokeDasharray: LINE_GRAPH_STROKE_PATTERN_LIST.get(index)
                  }}
                />
              </svg>
              {` Criteria ${index + 1}`}
            </div>
          ))}
        </React.Fragment>
      );
    };

    const tableTitle = (
      <div className="table-title-container">
        <div className="table-title">Active Campaign</div>
        <div>
          <div className="compare-msgs">
            <Button type="primary" onClick={() => this.handleCompareMsgs()}>
              Compare Messages
            </Button>
          </div>
          <Input
            placeholder="Search Messages"
            prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            onChange={event => {
              this.setState({ searchText: event.target.value });
            }}
          />
        </div>
      </div>
    );

    const columns = List([
      Map({
        title: "Message",
        dataIndex: "messageid",
        sorter: (a, b) =>
          a.messageid.toString().localeCompare(b.messageid.toString()),
        className: "left-align",
      }),
      Map({
        title: "Total Messages",
        dataIndex: "totalpageviews",
        sorter: (a, b) => a.totalpageviews - b.totalpageviews,
        className: "right-align",
        render: (text) => <span>{text.toLocaleString()}</span>,
      }),
      Map({
        title: "Opted In",
        dataIndex: "actionoptin",
        sorter: (a, b) => a.actionoptin - b.actionoptin,
        className: "right-align",
        render: (text, record) => (
          <div className="consent-table-metrics">
            <span className="tbl-metric-per">{record.actionoptinPer}%</span>{" "}
            <br />
            {text}
          </div>
        ),
      }),
      Map({
        title: "Opted Out",
        dataIndex: "actionoptout",
        sorter: (a, b) => a.actionoptout - b.actionoptout,
        className: "right-align",
        render: (text, record) => (
          <div className="consent-table-metrics">
            <span className="tbl-metric-per">{record.actionoptoutPer}%</span>{" "}
            <br />
            {text}
          </div>
        ),
      }),
      Map({
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={`/dialogue/scenariosv3?site_id=${record.siteid}`}
              target="_blank"
            >
              {"Need Action"}
            </Link>
          </span>
        ),
        className: "right-align",
      }),
    ]);

    const msgHeaders = columns
      .map(item => {
        return Map({ label: item.get("title"), key: item.get("dataIndex") });
      })
      .insert(3, Map({ label: "Opted Out %", key: "actionoptinPer" }))
      .insert(5, Map({ label: "Opted Out %", key: "actionoptoutPer" }))
      .pop();
    let graphTitle = graphList.size
      ? moment(graphList.first().get("date"), "YYYY-MM-DD").format("MMMM YYYY")
      : "";
    
    const metrics = List([
      {'title': "Total Volume", dataIndex: "totalpageviews", metricPerVal: mapReduceSum(tableData, 'totalpageviews'), metricAbsVal: ''},
      {'title': "Opted In", dataIndex: 'actionoptin', metricPerVal: mapReduceAvg(tableData, 'actionoptinPer'), metricAbsVal: mapReduceSum(tableData, 'actionoptin')},
      {'title': "Opted Out", dataIndex: 'actionoptout', metricPerVal: mapReduceAvg(tableData, 'actionoptoutPer'), metricAbsVal: mapReduceSum(tableData, 'actionoptout')},
    ]);

    return (
      <React.Fragment>
        <div className="property-tabs">
          {metrics.map((mt, index) =>
            <div
              key={index}
              className={`metrices-toggle-wrapper ${this.state.lineToggle.get(mt.dataIndex)? `toggle-line${index+1}-active`: `toggle-line${index+1}`}`}
              onClick={() => this.toggleLine(mt.dataIndex)}
            >
              <div className="metric-label" style={this.state.lineToggle.get(mt.dataIndex) ? { fontWeight: 600} : null}>
                {mt.title}
                {mt.titleNote ? <React.Fragment><br/><span className="metric-label-note">{mt.titleNote}</span></React.Fragment> : null}
              </div>
              <div className="metric-total">
                <span className="metric-per">{mt.metricPerVal}</span><br/>
                {mt.metricAbsVal ? <span className="metric-abs">{mt.metricAbsVal}</span> : <span className="metric-abs">&nbsp;</span>}
              </div>
            </div>)}
          <div className="property-action">
            <a onClick={() => this.showModal("recentChangesModal")}>
              View Recent Changes{" "}
              <span className="glyphicon glyphicon-menu-right consent-right-arrow"></span>
            </a>
          </div>
        </div>
        <div className="property-title-container">
          <div className="graph-desc-container">
            <span className="property-title">Message Performance</span>
            <br />
            <span className="property-desc">
              Trend over time, can be viewed on, monthly or weekly basis.
            </span>
          </div>
        </div>

        <ResponsiveContainer width="99%" height={395}>
          <LineChart
            width={730}
            height={250}
            data={graphList.toJS()}
            margin={this.state.rechartMargin}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="fancyDate">
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              type="number"
              yAxisId="left"
              domain={[0, "dataMax"]}
              tickFormatter={
                this.state.lineToggle.get("actionoptin") ||
                this.state.lineToggle.get("actionoptout")
                  ? this.dataFormater
                  : null
              }
            />
            <YAxis
              type="number"
              yAxisId="right"
              orientation="right"
              domain={[0, "dataMax"]}
            />
            {this.showTooltip()}
            <Legend verticalAlign="top" content={renderLegend} />
            {this.drawGraph(graphData)}
          </LineChart>
        </ResponsiveContainer>

        {tableTitle}
        <div className="collapsibleTable">
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            {tableData.map((item, i) => {
              let data = item.get("aggData").toJS()
              data = data.filter(m =>
                m.messageid.toString().includes(this.state.searchText)
              );

              return (
                <Panel
                  header={
                    <span>
                      {item.get("messageid")}
                      <ExportToolTip
                        filename="message"
                        selectedTabIndex={i + 1}
                        exportData={createCSVExportData(
                          msgHeaders,
                          item.get("aggData")
                        ).toJS()}
                        criterialist={this.props.criteriaList}
                        sites={this.props.sites}
                        pdfTitle={"Active Campaign"}
                      />
                    </span>
                  }
                  key={i + 1}
                >
                  <Table
                    columns={columns.toJS()}
                    dataSource={data}
                    pagination={{
                      position: ['bottomCenter'],
                      showTotal: (total) => `Total: ${total}`,
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                      locale: { items_per_page: "  Records per page" }
                    }}
                    className="consnet-tableCSS"
                    rowKey={r => r.key}
                    rowSelection={{
                      onChange: (selectedRowKeys, selectedRows) => {
                        let list = this.state.selectedRowKeys;
                        const index = list.findIndex(r => r.get("key") == i);
                        if (selectedRowKeys.length == 0) {
                          list = list.delete(index);
                        } else {
                          var getRowAbsolute = item
                            .get("aggData")
                            .filter((l, i) => selectedRowKeys.indexOf(i) != -1);
                          if (index >= 0) {
                            list = list.setIn([index, "msgs"], getRowAbsolute);
                          } else {
                            list = list.push(
                              Map({ key: i, msgs: getRowAbsolute })
                            );
                          }
                        }
                        this.setState({ selectedRowKeys: list });
                      }
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>

        <RecentChangesModal
          changeLogData={this.props.changeLogData}
          recentChangesModal={this.state.recentChangesModal}
          handleCancel={() => this.handleCancel("recentChangesModal")}
        />
        <MessagePerformaceModal
          msgPerformance={msgPerformance}
          selectedMsgs={this.state.selectedRowKeys}
          recentChangesModal={this.state.msgPerformanceVisible}
          handleCancel={() => this.handleCancel("msgPerformanceVisible")}
        />
      </React.Fragment>
    );
  }
}
