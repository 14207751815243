import { Typography } from 'antd';
import React from 'react';

export const getRecommendedActionsContent = () => {
  return (
    <div>
        <ol>
        <li>
            <strong>Inventory:</strong> List all tracking technologies and vendors operating on your properties.
        </li>
        <li>
            <strong>Vendor Identification:</strong> Identify the vendor that used the tracking technology on the site or trace how it ended up there.
        </li>
        <li>
            <strong>Vendor Relationship:</strong>
            <ol type="A">
            <li>
                If you have a relationship with the vendor:
                <ol type="i">
                <li>Ask them questions about the data used and passed by tracking tech and document the findings.</li>
                <li>Determine whether the tracking tech and data collection is necessary for the vendor to provide the services they are providing to you.</li>
                <li>
                    Ensure that appropriate contractual measures are in place (e.g., a DPA or a “service provider agreement”) addressing the data processing activity and the requirements of the applicable jurisdiction.
                </li>
                <li>Update your internal documentation (e.g., your register of processing, data mapping, etc.).</li>
                <li>Update your privacy policy, as necessary, to reflect that data type, activity, and purpose of use.</li>
                <li>
                    Ensure that the vendor is reflected in your DSAR process (including, as required, in your vendor list for disclosure to users).
                </li>
                <li>
                    Implement a notice and consent mechanism to obtain consent before collecting or passing any information.
                </li>
                <li>Keep a record of all actions and governance processes in case of an audit.</li>
                </ol>
            </li>
            <li>
                If you do not have a relationship with the vendor:
                <ol type="i">
                <li>Identify the department associated with this type of vendor.</li>
                <li>Assess the revenue and operational implications of removing the tracking tech and the vendor completely.</li>
                <ol type="1">
                    <li>
                    If removal is not a practical option:
                    <ol type="a">
                        <li>Reach out to the vendor.</li>
                        <li>Ask them questions about the data used and passed by the tracking tech and document the findings.</li>
                        <li>Update your privacy policy to declare the vendor and the tracking tech used.</li>
                        <li>Implement a robust notice and consent mechanism to obtain consent before collecting or passing any information.</li>
                        <li>Keep a record of all actions and governance processes in case of an audit.</li>
                    </ol>
                    </li>
                    <li>
                    If removal is necessary:
                    <ol type="a">
                        <li>Remove the vendor and use a scanning tool to ensure it’s removed.</li>
                        <li>Keep a record of all actions and governance processes in case of an audit.</li>
                    </ol>
                    </li>
                </ol>
                </ol>
            </li>
            </ol>
        </li>
        </ol>
    </div>
  );
};
