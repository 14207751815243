import React from "react";
import ExportPDF from "./ExportPDF";

export default class PDFDownload extends React.Component {
  render() {
    var docDefinition = {
      pageSize: "a3",
      content: [
        {
          text: this.props.title,
          style: "header",
          fontSize: 14,
          bold: true,
          margin: [0, 5, 0, 5]
        },
        this.props.citeriaExportData
          ? {
              fontSize: 10,
              table: {
                headerRows: 1,
                body: this.props.citeriaExportData.toJS()
              },
              margin: [0, 5, 0, 5]
            }
          : null,
        {
          fontSize: 10,
          table: {
            headerRows: 1,
            body: this.props.content
          }
        }
      ]
    };
    return (
      <ExportPDF
        docDefinition={docDefinition}
        iconClass="export-pdf-icon"
        filename={this.props.filename}
        onClick={this.props.onClick}
      />
    );
  }
}
