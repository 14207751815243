/* eslint-disable react/prop-types */
import { Tooltip } from 'antd';
import React from 'react';
import classNames from 'classnames';
import { ArrowDownOutlined, InfoCircleFilled } from '@ant-design/icons';

const dataKeys = {
  relative: 'Relative Score',
  averageProperty: 'Average Count (My properties)',
  averageBenchmark: 'Average Count (Benchmark)',
};

const OverviewCountValue = ({ value, isHighlighted, type = undefined /* increase | decrease */ }) => {
  const rotateIcon = {
    increase: 180,
    decrease: 0,
  }?.[type];

  if (typeof value !== 'number' && !value) {
    return <div />;
  }

  return (
    <div>
      <span
        className={classNames('overview-count-values', {
          highlight: isHighlighted,
          [type]: type,
        })}
      >
        {type && <ArrowDownOutlined rotate={rotateIcon} style={{ marginTop: '3px', fontSize: '14px' }} />}
        {value}
      </span>
    </div>
  );
};

const OverviewCount = ({
  showAverage = false,
  showRelative = false,
  dataFrom,
  dataTo,
  isHighlighted = false,
  showDiff = false,
}) => {
  const isOneDaySelected = dataFrom.date === dataTo.date;
  const rowGridClassName = classNames('overview-count-grid', {
    day: isOneDaySelected,
  });
  const dateLine = (
    <div className={`${rowGridClassName} date`}>
      <div className={'date first'}>
        <span className={classNames({ highlight: isHighlighted })}>{dataFrom?.date}</span>
      </div>
      {!isOneDaySelected && (
        <>
          <div className="dates-divider-container">
            <div className="circle" />
            <div className="dashed-line" />
            <div className="circle" />
          </div>
          <div className={'date last'}>
            <span className={classNames({ highlight: isHighlighted })}>{dataTo?.date}</span>
          </div>
        </>
      )}
    </div>
  );
  return (
    <div className="overview-count card">
      <div style={{ width: '100%' }}>
        {showAverage && (
          <div>
            <b className="title">Average Count</b>
            <div className="row gap lightblue">
              {dateLine}
              <div className={rowGridClassName}>
                <div className="overview-count-description gap">
                  <Tooltip
                    overlayClassName="diagnose--tooltip"
                    title={
                      'The average count is the total number of times on average per scan we have observed the compliance metric on your selected properties'
                    }
                  >
                    My Property
                    <InfoCircleFilled style={{ marginLeft: '5px' }} />
                  </Tooltip>
                </div>
              </div>
              <div className={rowGridClassName}>
                <div /* gap */ />
                <OverviewCountValue isHighlighted={isHighlighted} value={dataFrom?.[dataKeys.averageProperty] || 0} />
                <div /* gap */ />
                {!isOneDaySelected && (
                  <>
                    <OverviewCountValue isHighlighted={isHighlighted} value={dataTo?.[dataKeys.averageProperty] || 0} />
                    <OverviewCountValue isHighlighted />
                  </>
                )}
              </div>
            </div>
            <div className="row lightgray">
              <div className={rowGridClassName}>
                <div className="overview-count-description">
                  <Tooltip
                    overlayClassName="diagnose--tooltip"
                    title={
                      'The average count is the total number of times on average per scan we have observed the compliance metric on your selected benchmark properties'
                    }
                  >
                    Benchmark
                    <InfoCircleFilled style={{ marginLeft: '5px' }} />
                  </Tooltip>
                </div>
              </div>
              <div className={rowGridClassName}>
                <div /* gap */ />
                <OverviewCountValue value={dataFrom?.[dataKeys.averageBenchmark] || 0} />
                <div /* gap */ />
                {!isOneDaySelected && (
                  <>
                    <OverviewCountValue value={dataTo?.[dataKeys.averageBenchmark] || 0} />
                    <OverviewCountValue isHighlighted={isHighlighted} />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {showRelative && (
          <div style={showAverage && showRelative ? { marginTop: '10px' } : {}}>
            <Tooltip
              overlayClassName="diagnose--tooltip"
              title={
                'The relative score is a percentile rank that looks at the specified compliance metric and compares the average performance across your properties with the other properties in the benchmark. Example: If you have a relative score of 67% then you score better than 67% of properties in your benchmark'
              }
            >
              <b className="title">Relative Score</b>
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
            <div className="row gap lightblue">
              {dateLine}
              <div className={rowGridClassName}>
                <div /* gap */ />
                <OverviewCountValue
                  isHighlighted={isHighlighted}
                  value={dataFrom?.[dataKeys.relative] ? `${dataFrom?.[dataKeys.relative]}%` : '0%'}
                />
                <div /* gap */ />
                {!isOneDaySelected && (
                  <>
                    <OverviewCountValue
                      isHighlighted={isHighlighted}
                      value={dataTo?.[dataKeys.relative] ? `${dataTo?.[dataKeys.relative]}%` : '0%'}
                    />
                    <OverviewCountValue
                      isHighlighted={isHighlighted}
                      type={dataFrom?.[dataKeys.relative] > dataTo?.[dataKeys.relative] ? 'decrease' : 'increase'}
                      value={
                        showDiff
                          ? `${(((dataFrom?.[dataKeys.relative] - dataTo?.[dataKeys.relative]) * 100) / dataFrom?.[dataKeys.relative]).toFixed(1)}%`
                          : ''
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewCount;
