import * as types from "./action_types";
import { actionCreator } from "./helpers";
import {
  getPublisherCmpIdAjax,
  getPublishersCmpIdsAjax,
  deletePublisherCmpIdAjax,
  updatePublisherCmpIdAjax,
  createPublisherCmpIdAjax
} from "../api/consent_v2/publisher_cmp_ids";

export const getPublisherCmpId = actionCreator(
  getPublisherCmpIdAjax,
  publisherCmpIdPending,
  publisherCmpIdLoaded,
  publisherCmpIdError,
  publisherCmpIdCached
);

export const getPublishersCmpIds = actionCreator(
  getPublishersCmpIdsAjax,
  publishersCmpIdsPending,
  publishersCmpIdsLoaded,
  publishersCmpIdsError,
  publishersCmpIdsCached
);

export const deletePublisherCmpId = actionCreator(
  deletePublisherCmpIdAjax,
  publishersCmpIdsPending,
  publisherCmpIdDeleted,
  publishersCmpIdsError,
  null,
  //deletePublisherCmpIdLog, //can be added in future
);

export const createPublisherCmpId = actionCreator(
  createPublisherCmpIdAjax,
  publisherCmpIdsSavePending,
  publisherCmpIdCreated,
  publishersCmpIdsError,
  null,
  //createPublisherCmpIdLog, // can be added in future
);

export const updatePublisherCmpId = actionCreator(
  updatePublisherCmpIdAjax,
  publisherCmpIdsSavePending,
  publisherCmpIdUpdated,
  publishersCmpIdsError,
  null,
  //editPublisherCmpIdLog, //can be added in future
);

function publisherCmpIdCreated(data) {
  return {
    type: types.PUBLISHER_CMP_ID_CREATED,
    data,
  };
}

function publisherCmpIdUpdated(data) {
  return {
    type: types.PUBLISHER_CMP_ID_UPDATED,
    data,
  };
}

function publisherCmpIdsSavePending() {
  return {
    type: types.PUBLISHER_CMP_ID_SAVE_PENDING,
  };
}

function publisherCmpIdDeleted(id) {
  return {
    type: types.PUBLISHER_CMP_ID_DELETED,
    id,
  };
}

function publishersCmpIdsPending() {
  return {
    type: types.PUBLISHERS_CMP_IDS_PENDING
  };
}

function publishersCmpIdsLoaded(data) {
  return {
    type: types.PUBLISHERS_CMP_IDS_LOADED,
    data
  };
}

function publishersCmpIdsError(error) {
  return {
    type: types.PUBLISHERS_CMP_IDS_ERROR,
    error
  };
}

function publishersCmpIdsCached() {
  return {
    type: types.PUBLISHERS_CMP_IDS_CACHED
  };
}

function publisherCmpIdPending() {
  return {
    type: types.PUBLISHER_CMP_ID_PENDING
  };
}

function publisherCmpIdLoaded(data) {
  return {
    type: types.PUBLISHER_CMP_ID_LOADED,
    data
  };
}

function publisherCmpIdError(error) {
  return {
    type: types.PUBLISHER_CMP_ID_ERROR,
    error
  };
}

function publisherCmpIdCached() {
  return {
    type: types.PUBLISHER_CMP_ID_CACHED
  };
}