import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Dropdown } from 'antd';
import { Input, Select, Tooltip, Button, Toggle, Modal, Alert, LinesEllipsis } from '../../../../styleguide';
import { Link } from 'react-router';
import CustomButton from '../../../common/CustomButton.js.jsx';
import { CONSENT_SCOPES } from '../../../../constants';
import { VendorList } from '../../../../records/vendor_list_records';
import CustomGeoScopeDropDown from '../../../common/CustomGeoScopeDropDown';
import ExportVendorList from './ExportVendorList';
import { CopyFilled, DeleteFilled, CheckCircleFilled, InfoCircleFilled, CheckCircleOutlined, ClockCircleOutlined  } from '@ant-design/icons';
import { GEO_LIST_MERGED } from '../../../../constants';
import classNames from 'classnames';

const regionsAndCountries = GEO_LIST_MERGED.filter(
  c => c.type == 'country' || c.type == 'region'
);
const tcfUpdateMessages = [
  <>In order to provide more transparency to users, the TCF V2.2 has updated the names of Purposes, Special Purposes, Features, Special Features and Stacks. The legal text has been removed and replaced by user-friendly descriptions - supplemented by examples of real-use cases (illustrations). You can review these by clicking on the name of the Purposes, Special Purposes, Features, Special Features and Stacks. <b>If you want to provide supplementary illustrations you can edit these in the same section.</b></>,
  <>The IAB TCF V2.2 has introduced a new purpose, "Use Limited Data to Select Content" (Purpose 11). The IAB TCF V2.2 stacks that this purpose is relevant to (including stack 42, which combines all purposes) have been updated to include Purpose 11. There is also a new stack "Content based on limited data and content measurement" (stack 43) which combines purposes 8 and 11. <b>Please review your stack configuration in light of this change.</b></>,
  <>Legitimate interest is no longer a supported legal basis for purposes 3, 4, 5 or 6. For IAB TCF vendors that previously used legitimate interest for these purposes, the legal basis has been changed in line with the vendor's Global Vendor List registration. For custom vendors using legitimate interest for these purposes the legal basis has been updated to consent. <b>Please review your vendors that previously used legitimate interest as a legal basis for these purposes and review the legal basis configuration in light of this change.</b></>
]

const Option = Select.Option;
const { Panel } = Collapse;

export default class Header extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleSelectScope: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    toggleIsActive: PropTypes.func.isRequired,
    toggleShareRootDomain: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    vl: PropTypes.instanceOf(VendorList).isRequired,
    v2: PropTypes.bool.isRequired,
    toggleAdvancedSettings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    // this.customGeo = React.createRef();
  }

  state = {
    showAlerts : false,
    savingWithoutTcfReview: false,
    showTcfUpdates:
      this.props.isNonIab || this.props.route.path.includes('/create')
        ? false
        : this.props.vl.tcfVersion == 2.1,
    tcfUpdatesReviewStatus: [
      {
        type: null,
        status: true,
        title: 'Review Purpose Names, Descriptions and Illustrations'
      },
      {
        type: 'Review Updates',
        status: this.props.vl.tcfVersion == 2.2,
        title: 'Review Purpose 11'
      },
      {
        type: 'Review Updates',
        status: this.props.vl.tcfVersion == 2.2,
        title: 'Review Legal Bases for Purposes 3, 4, 5, 6'
      },
    ],
    showDeletedIabVendorsModal : false
  }

  componentDidUpdate(nextProps, prevState){
    // if((JSON.stringify(nextProps.vl?.toJS()) !== JSON.stringify(this.props.vl?.toJS()))){
    //   if(!this.props.showWizard && nextProps.showWizard !== this.props.showWizard){
    //     this.customGeo.current.setGeoScope();
    //   }else if(this.props.isSaveExitInCreate){
    //     this.customGeo.current.setGeoScope();
    //   }
    // }
    if(JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      if(this.state.tcfUpdatesReviewStatus.every( obj => obj.status)){
        this.props.updateState({vl: this.props.vl.set('tcfVersion', 2.2)})
        this.setState({savingWithoutTcfReview: false})
      }
    }
  }

  getRegionNames = regions => {
    //filtering for all regions from list
    return regions.filter(r => r.type === 'region');
  };

  reviewTcfUpdate = (updateIndex) => {
    let updatedReviewStatus = this.state.tcfUpdatesReviewStatus;
    updatedReviewStatus[updateIndex].status = true
    this.setState((prevState) => ({
      ...prevState,
      showTcfUpdates: false,
      savingWithoutTcfReview: false,
      tcfUpdatesReviewStatus : updatedReviewStatus
    }))
    this.props.updateActiveTab("all");
    switch(updateIndex) {
      case 1 :
        this.props.updatePuposestoFocus([11]);
        break;
      case 2 :
        this.props.updatePuposestoFocus([3,4,5,6]);
        break;
    }
  }

  reviewVlAlerts = (activeTab) => {
    this.props.updateActiveTab(activeTab);
    this.setState({showAlerts: false, showDeletedIabVendorsModal: false})
    switch(activeTab){
      case 'iab-updated':
        this.props.updatePuposestoFocus(this.props.iabPurposes?.map(p => p.iabId)?.toJS());
        break;
      case 'iab-deleted':
        this.props.updatePuposestoFocus([]);
        break;
    }
  }
  getConfirmationModal = (useCase) => {
    let header = "Are you sure you want to save your vendor list?";
    let content1 = <>Some of the IAB vendors in this vendor list have a previously configured legal basis that is no longer supported in their latest registration on the IAB TCF's Global Vendor List.<br/><br/></>;
    let content2 = <>For each purpose that has been updated, we have updated the legal basis to a new value. This new value is based on either the <b>"Default IAB Vendor Consent Type"</b> defined in the purpose settings, or, if not set, the vendor list advanced settings. If the desired legal basis is no longer supported, the purpose will be set to <b>"Not Allowed"</b><br/><br/></>; 
    let content3 = <>By clicking <b>"Confirm & Save"</b> users may be reconsented depending on the rules you have set up in the live scenario this list is applied to.<br/></>;
    let confirmText = "Confirm & Save";
    let confirmMethod = this.props.handleSave;
    let secondaryCTA = null;
    let ctaText = 'Review IAB Legal Basis Updates';
    switch(useCase){
      case 'review-deleted-vendors':
        header = "Review IAB Deleted Vendors";
        content1 = null; content2 = null;
        content3 = <>This vendor list cannot be saved. In order to proceed please review and reconfigure the IAB vendors that have been deleted from the IAB TCF's Global Vendor List using the tab <b>“Review Deleted IAB Vendors”</b></>
        confirmText = "Review IAB Deleted Vendors";
        confirmMethod = this.reviewVlAlerts.bind(this, 'iab-deleted');
      break;
      case 'review-iab-legal-basis-change-v2.1':
        content3= null;
        confirmText = "Confirm & Continue"
        confirmMethod = this.onClickSave.bind(this, true);
        secondaryCTA = this.reviewVlAlerts.bind(this, 'iab-updated');
        break;
      case 'review-iab-legal-basis-change-v2.2':  
        secondaryCTA = this.reviewVlAlerts.bind(this, 'iab-updated');
        break;
      case 'confirmation-v2.1':
        content1 = null; content2 = null;
        content3 = <>By clicking <b>“Confirm & Save”</b> your implementation will be updated to TCF V2.2. New users will start to receive TCF V2.2 consent strings and existing users with TCF V2.1 consent strings may be reconsented depending on your scenario settings.</>
      break;
      case 'confirmation-v2.2':
        content1 = null; content2 = null;
        content3 = <>By clicking <b>"Confirm & Save"</b> users may be reconsented depending on the rules you have set up in the live scenario this list is applied to if you have added new vendors or changed the legal basis configuration of any of your vendors.</>
      break;
    }
    return (
      <Modal
        className="tcf-updates-modal"
        visible={true}
        onCancel={() => this.setState({showDeletedIabVendorsModal: false})}
        footer={[secondaryCTA  ? <Button type="secondary" onClick={secondaryCTA}>{ctaText}</Button> : null,<Button type="primary" onClick={confirmMethod}>{confirmText}</Button>]}
      >
        <h3>{header}</h3><br/>
        {content1}
        {content2}
        {content3}
      </Modal>
    );
  }
  onClickSave = async (forceSave = false) => {
    let confirmationModal = null;
    const vendorsWithLegalBasisChangesExist = this.props.vl.vendorsWithLegalBasisChanges && this.props.vl.vendorsWithLegalBasisChanges.size;
    if(this.props.iabDeletedVendors){
      confirmationModal = this.getConfirmationModal('review-deleted-vendors')
    } else if(vendorsWithLegalBasisChangesExist && this.props.dateBaseTcfVersion == 2.2 && !forceSave){
      confirmationModal = this.getConfirmationModal('review-iab-legal-basis-change-v2.2')
    } else if(vendorsWithLegalBasisChangesExist && this.props.dateBaseTcfVersion == 2.1 && !forceSave) {
      confirmationModal = this.getConfirmationModal('review-iab-legal-basis-change-v2.1')
    } else if(this.props.vl.tcfVersion != 2.2 && !this.props.route.path.includes('/create') && !this.props.isNonIab){
      this.setState({showTcfUpdates: false, savingWithoutTcfReview: true, showDeletedIabVendorsModal: false})
      return;
    } else if(this.props.dateBaseTcfVersion != 2.2 && !this.props.route.path.includes('/create') && !this.props.isNonIab){
      confirmationModal = this.getConfirmationModal('confirmation-v2.1');
    } else if(!this.props.route.path.includes('/create')){
      confirmationModal = this.getConfirmationModal('confirmation-v2.2');
    } else if(this.props.isNonIab){
      this.props.handleSave();
    } else {
      this.handleConfirmAndSaveTCFv22();
    }
    this.setState({showDeletedIabVendorsModal: confirmationModal})
  }

  handleConfirmAndSaveTCFv22 = async () => { 
    await this.props.updateState({vl: this.props.vl.set('tcfVersion', 2.2)});
    this.props.handleSave()
  }

  render() {
    const { iabDeletedVendors, iabUpdatedVendors } = this.props
    const tooltipText = (
      <React.Fragment>
        <p className="tooltip-title">Single property</p>
        <p> Consent will only be applied to the same property a user has consented on.</p>
        <p className="tooltip-title">Shared property</p>
        <p>Consent will be shared across all properties on the vendor list that implemented the authenticated consent feature and website properties that share the same top level domain.</p>
      </React.Fragment>
    );

    let saveButton;
    if (!this.props.readOnly) {
      saveButton = (
        <CustomButton
          type={CustomButton.types.SUCCESS}
          onClick={() => this.onClickSave(false)}
          disabled={this.props.readOnly}
        >
          Save
        </CustomButton>
      );
    }

    const geoScope = (
      <div className="geoContainer">
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <div className="geoTitle" style={{ marginRight: 5 }}>GDPR Applies Scope:</div>
          <Tooltip title="This setting determines the geographic regions where our system will provide GDPR compliance signals that include additional fields within the tcData object. Note this setting will not impact message targeting which is managed in campaign scenarios area.">
            <div className="title">
              <i className="fas fa-info-circle" />
            </div>
          </Tooltip>
        </div>
        <CustomGeoScopeDropDown
          // ref={this.customGeo}
          // options={this.getRegionNames(regionsAndCountries)}
          // type="gdpr"
          handleLocationSave={this.props.handleLocationSave}
          vl={this.props.vl}
          disabled={this.props.readOnly}
        />
      </div >
    );

    const rightTitle = (
      <React.Fragment>
        <div className="switchContainer">
          {geoScope}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 1, }}>
              <div style={{ fontWeight: 'bold', color: 'black', marginRight: 5 }}>
                Consent scope:
              </div>
              <Tooltip title={tooltipText}>
                <div className="title">
                  <i className="fas fa-info-circle" />
                </div>
              </Tooltip>
            </div>
            <Select
              disabled={this.props.readOnly}
              value={CONSENT_SCOPES.get(this.props.vl.consentScope)}
              onChange={this.props.handleSelectScope}
              size="large"
            >
              {CONSENT_SCOPES.toList().map(s => (
                <Option key={s}>{s}</Option>
              ))}
            </Select>
          </div>
        </div>
      </React.Fragment>
    );

    const activeSwitch = (
      <div className="active-switch">
        <div>{this.props.vl.isActive ? 'Active' : 'Inactive'}</div>
        <Toggle
          onChange={this.props.toggleIsActive}
          checked={this.props.vl.isActive}
          disabled={this.props.readOnly}
        />
      </div>
    );

    const consentRoute = this.props.v2 ? 'consent_v2' : 'consent';

    //---------
    let gVLLegalBasisChangeMessage;
    let gVLDeletedMessage;
    let alertCount = 0;

    // const isIabUpdatedTabSelected = this.props.activeTab == 'iab-updated';
    // const showMoreInfo = isIabUpdatedTabSelected && iabUpdatesMoreInfo;

    // const isIabDeletedTabSelected = this.props.activeTab == 'iab-deleted';

    if (iabUpdatedVendors ) {
      const iabUpdateMessage = (
        <div>
          <b>{iabUpdatedVendors} IAB vendor(s)</b> have a previously configured legal basis that is no longer supported in their latest registration on the IAB TCF's Global Vendor List. <b>You must review these updates in the table below and make necessary updates before you can save your vendor list.</b> 
          <br/><br/>
          For each purpose that has been updated, we have updated the legal basis to a new value. This new value is based on either the "Default IAB Vendor Consent Type" defined in the purpose settings, or, if not set, the vendor list advanced settings. If the desired legal basis is no longer supported, the purpose will be set to "Not Allowed". Please review these changes and make any necessary updates before saving this Vendor List. 
          <br/><br/>
          <b>Important note:</b> Saving these changes may cause your users to be messaged to reconsent if you have set up a scenario in which either "Legal Basis Changes" or "Vendor List Additions" will trigger a reconsent within your scenario settings.
        </div>
      );
      gVLLegalBasisChangeMessage = (
        <div className="error-wrapper">
          <div className="message" >
            <LinesEllipsis maxLines={3} >{iabUpdateMessage}</LinesEllipsis>
          </div>
          <div className="action-btns">
            {
                <React.Fragment>
                  <Button  type={"primary"} onClick={() => this.reviewVlAlerts('iab-updated')}>Review IAB Legal Basis Updates</Button>
                </React.Fragment>
            }
          </div>
        </div>
      );
      alertCount++;
    }

    if(iabDeletedVendors) {
      const iabDeletedMessage = <div><b>{iabDeletedVendors} vendor(s)</b> have been deleted from the IAB TCF’s Global Vendor List. This means that they will no longer be signalled in the IAB TCF consent string, or present in the IAB TCF’s APIs and will have no mechanism for checking vendor-level consent. <b>You must remove these vendors before you can save your vendor list.</b>
      <br/><br/>
      If you wish to keep the vendors that have been removed from the IAB GVL you can add these vendors as custom vendors by selecting them in the ‘Bulk Actions’ dropdown and choosing the ‘Make Custom’ option. Additionally, you can instantly remove these vendors from your list by selecting them and clicking on the “Remove Vendors” button.
      <br/><br/>
      <b>Important note:</b> <i>If you decide to add these vendors as custom vendors please note that they will not receive signalling via the IAB TCF and therefore you will need to configure how they read vendor-level consent through alternative mechanisms.</i>
      </div>;
      gVLDeletedMessage = (
        <div className="error-wrapper">
          <div className="message">
            <LinesEllipsis maxLines={3} >{iabDeletedMessage}</LinesEllipsis>
          </div>
          <div className="action-btns">
            {
                <React.Fragment>
                  <Button  type={"primary"} onClick={() => this.reviewVlAlerts('iab-deleted')}>Review Deleted IAB Vendors</Button>
                </React.Fragment>
            }
          </div>
        </div>
      );
      alertCount++;
    }

    const vlAlerts = (
      <Collapse defaultActiveKey={[0]}>
        {iabUpdatedVendors ? <Panel header="Legal Basis Changes" >
          {gVLLegalBasisChangeMessage}
        </Panel> : null}
        {iabDeletedVendors ? <Panel header="Deleted Vendors Updates">
          {gVLDeletedMessage}
        </Panel> : null}
      </Collapse>
    );

    let isReviewComplete = this.props.vl.tcfVersion == 2.2 || this.state.tcfUpdatesReviewStatus.every( obj => obj.status);
    const alertMessage = <><b>Important note:</b> Saving these changes may cause your users to be messaged to reconsent if you have set up a scenario in which either "Legal Basis Changes" or "Vendor List Additions" will trigger a reconsent within your scenario settings.</>;
    
    const tcfVersionUpdates =  this.state.tcfUpdatesReviewStatus.map((update, idx)=> {
      return !this.state.savingWithoutTcfReview || !update.status ? (
      <div className='tcf-update'>
        {<div className="title">{update.title}</div>}
        <div className="content">{tcfUpdateMessages[idx]}</div>
        { update.type ?
          <div className='footer'>
            <div>
              {update.status ? (
                <><CheckCircleOutlined /> Reviewed</>
              ) : (
                <><ClockCircleOutlined /> Pending</>
              )}
            </div>
            <Button type="primary" onClick={() => this.reviewTcfUpdate(idx)}>{update.type}</Button>
          </div>
        : null}
      </div>
    ) : null});

    return (
      <>
      <div className="vendor-header">
        <div className="vendor-header-nav">
          <Link to={`/${this.props.filterElement(consentRoute, 'non-iab')}/vendor_lists`}>
            &lt;&nbsp;&nbsp;Back to Vendor Lists
          </Link>
          <div className="actions-wrapper">
            <div className="vl-version-update-alerts">
              { this.props.isNonIab || this.props.route.path.includes('/create') ? null :
                <div className={classNames({"tcf-updates-btn" : true, "reviewed" : isReviewComplete})} onClick={() => this.setState({showTcfUpdates: true})}>
                  Updates - TCF v2.2
                  { isReviewComplete ? <CheckCircleFilled /> : <InfoCircleFilled/> }
                </div>
              }
              {alertCount ?
                <Dropdown
                  overlay={vlAlerts}
                  trigger={['click']}
                  placement="bottomRight"
                  open={this.state.showAlerts}
                  onOpenChange={(open) => this.setState({showAlerts: open})}
                  overlayClassName='vl-alerts'
                >
                  <div className="alerts pointer"><img src={require("../../../../assets/images/privacy-lens/warning_icon_yellow.svg")}/> &nbsp; Alerts({alertCount})</div>
                </Dropdown>
              : null}
            </div>
            <div className='menu-container'>
            {!this.props.readOnly && this.props.route.path.includes('/edit') ? (
                <>
                  <div className={"edit"} onClick={this.props.handleCopy}>
                    <Tooltip title={<>Clone</>} trigger="hover">
                      <CopyFilled rotate={180} />
                    </Tooltip>
                  </div>
                  <div className={"edit"} onClick={this.props.onDelete}>
                    <Tooltip title={<>Delete</>} trigger="hover">
                      <DeleteFilled />
                    </Tooltip>
                  </div>
                </>
              ) : null}
            </div>
            <Button
              type="secondary"
              size="small"
              onClick={this.props.toggleAdvancedSettings}
              className='advanced-settings'
            >
              * Advanced settings *
            </Button>
            {activeSwitch}
            {saveButton}
          </div>
        </div>

        <div className="vendor-top">
          <div className="vendor-top-left">
            <div className="title-editor">
              <Input
                prefix={!this.props.readOnly ? <i className="fas fa-pen" /> : null}
                value={this.props.vl.name}
                onChange={e => this.props.handleChange(e, 'name')}
                placeholder="Vendor List Name..."
                className="vendor-top-title"
                disabled={this.props.readOnly}
                style={{ width: ((this.props.vl.name.length + 12) * 9) + 'px', maxWidth: '350px' }}
              />
            </div>
            {(
              this.props.route.path.includes('/edit') && (
                <div className="vendor-top-right export">
                  <ExportVendorList
                    vl={this.props.vl}
                    siteNames={this.props.siteNames}
                    iabSpecialFeatures={this.props.iabSpecialFeatures}
                    iabFeatures={this.props.iabFeatures}
                    iabSpecialPurposes={this.props.iabSpecialPurposes}
                  />
                </div>
              )
            )}
            { !this.props.readOnly && 
                <Tooltip title="Clicking on this icon will navigate you to Wizard flow">
                  <span className="edit-wizard" onClick={() => this.props.toggleWizard()}>
                    <i className="fas fa-magic"></i>
                  </span>
                </Tooltip>
            }
          </div>
          <div className="vendor-top-right setting">{rightTitle}</div>
        </div>
        <div className="vl-details-second-row">
          {this.props.id && <div className='vl-id'>ID: {this.props.id}</div>}
          <div className='vendors-count'>Total Vendors <span>{this.props.vl.vendors.size}</span></div>
        </div>
      </div>
      {this.state.showTcfUpdates ? (
        <Modal
          className="tcf-updates-modal"
          title={"TCF v2.2 Updates"}
          visible={this.state.showTcfUpdates}
          onCancel={() => this.setState({showTcfUpdates: false})}
          footer={[]}
        >
          <Alert type="warning" message={alertMessage} showIcon icon={<img src={require("../../../../assets/images/privacy-lens/warning_icon_yellow.svg")}/>}/>
          {tcfVersionUpdates}
        </Modal>
      ) : null}
      {this.state.savingWithoutTcfReview ? (
        <Modal
          className="tcf-updates-modal"
          title={"Save Vendor List"}
          visible={this.state.savingWithoutTcfReview}
          onCancel={() => this.setState({savingWithoutTcfReview: false})}
          footer={[<Button type="primary" onClick={this.handleConfirmAndSaveTCFv22}>Confirm & Save</Button>]}
        >
          <p style={{marginBottom: "10px"}}>Looks like you have not reviewed the following updates related to the TCF v2.2:</p>
          {tcfVersionUpdates}
          <p style={{marginTop: "20px"}}>By clicking <b>“Confirm & Save”</b>, we will consider all the updates as reviewed and your implementation will be updated to TCF V2.2. New users will start to receive TCF V2.2 consent strings and existing users with TCF V2.1 consent strings may be reconsented depending on your scenario settings. Please ensure you are happy with the implementation before proceeding.</p>
        </Modal>
      ) : null}
      {Boolean(this.state.showDeletedIabVendorsModal) ? (
        this.state.showDeletedIabVendorsModal
      ) : null}
      </>
    );
  }
}
