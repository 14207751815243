import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';


export default class ConfirmCancelModal extends React.Component {
  static propTypes = {
    modalVisible: PropTypes.bool.isRequired,
    handleContinueWithoutSaving: PropTypes.func.isRequired,
    handleGoBack: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Modal
        title="You have unsaved changes"
        visible={ this.props.modalVisible }
        onOk={ this.props.handleContinueWithoutSaving }
        okText='Close modal without saving'
        onCancel={ this.props.handleGoBack }
        cancelText='Go back to scenario builder'
      >
        <p>Are you sure you want to close?</p>
      </Modal>
    );
  }
}
