import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import StepSatisfyOption from './StepSatisfyOption';
import { GeoGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js'

export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(GeoGate).isRequired,
        onChange: PropTypes.func.isRequired
    };

    componentDidMount() {
        if (this.props.editing) ReactDOM.findDOMNode(this.refs.geoList).focus();
    }

    handleChange = (newData) => {
        this.props.onChange(this.props.data.merge(newData));
    };

    handleChangeSatisfy = (continueOnSatisfy) => {
        this.handleChange({ continue: continueOnSatisfy })
    };

    render() {

        var continueOnSatisfy = this.props.data.continue ? "Yes" : "No";

        if (!this.props.editing) {
            return (<div>Countries: { this.props.data.geo_list }, Continue if in Country: { continueOnSatisfy }</div>);
        } else {
            return (
                <div className="step continue-halt">

                    <label>Countries:</label>
                    <input ref="geoList" type="text" value={ this.props.data.geo_list } onChange={ (e) => this.handleChange({ geo_list: e.target.value }) }></input>

                    <label>Continue if in Country:
                      <StepSatisfyOption
                        value={ this.props.data.continue }
                        onChange={ this.handleChangeSatisfy }
                      />
                    </label>
                </div>
            );
        }
    }
}
