import 'whatwg-fetch';
import { List, OrderedSet, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';
import { VendorList, Vendor, PurposeV2 as Purpose, VendorListError, VendorWrapper, IabSpecialFeature, IabSpecialFeatureAndPurposes, VendorScanningKnownAndUnknown } from '../../records/vendor_list_records';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const getConsentBaseUrl = () => {
  return `${permissionsSvcBaseUrl}/tcfv2`;
}

export function getVendorListsAjax(onlyCustom) {
  let url = `/vendor-list/account-list`;

  if (onlyCustom) {
    url += '?onlyCustom=true';
  }

  return fetch(getConsentBaseUrl() + url, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp.map(v => new VendorList(v)));
    });
}

export function getScannedVendorsV2Ajax(siteIds, vendorListId, accountId) {
  let reqBody = { accountId, siteIds };
  if (vendorListId) {
    reqBody.vendorListId = vendorListId;
  }
  return (
    fetch(getConsentBaseUrl() + '/vendor-scan/redbud', {
      method: 'POST',
      body: JSON.stringify(reqBody),
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then((resp) => resp.json())
      .then((resp) => {
         const knownVendors = List(resp.iabVendorsFromScan.concat(resp.customVendorsFromScan)).map(obj => {
          let vendor = obj;
          if (obj.hasOwnProperty('isNew')) {
            vendor.isNew = obj.isNew;
          }
          return vendor;
        }).map(v => new Vendor(v));
        return new VendorScanningKnownAndUnknown({
          scannedVendors: knownVendors,
          vendorWithProblems: List(resp.vendorsWithProblems).map(v => new Vendor(v)),
        });
      })
  );
}

export function getScannedVendorsAjax(siteIds, vendorListId) {
  let checkForExistingVendors = '';
  if (vendorListId) {
    checkForExistingVendors = '&vendorListId=' + vendorListId;
  }
  return fetch(getConsentBaseUrl() + '/vendor-scan?siteIds=' + JSON.stringify(siteIds.toJS()) + checkForExistingVendors, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp).map(obj => {
        const vendor = obj.vendor;
        vendor.cookies = obj.cookies;
        if (obj.hasOwnProperty('isNew')) {
          vendor.isNew = obj.isNew;
        }
        return vendor;
      }).map(v => new Vendor(v));
    });
}

export function getSitesEnabledForScanAjax(accountId) {
  return fetch(getConsentBaseUrl() + '/vendor-scan/legacy/sites', {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp).map(obj => obj.siteId);
    });
}

export function getIABPurposesAjax() {
  const url = `/iab/purposes`;
  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp.map(p => new Purpose(p).set('iabPurposeRef', p._id)));
    });
}

export function getIABStacksAjax() {
  const url = '/iab/stacks';
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getConsentBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return List(resp.map(s => {
          return new Purpose(s)
            .set('type', 'IAB_STACK')
            .set('iabStackRef', s._id)
            .delete('id')
            .set('categories', List(s.iabPurposes.map(p => {
              const purpose = new Purpose(p)
              return purpose.set('iabPurposeRef', purpose.id).delete('id')
            }))
              .set('id', null)
            )
        })
        )
      });
  }
}

export function getIabSpecialFeaturesAndPurposesAjax() {
  const url = '/iab?includeSpecialFeatures=true&includeFeatures=true&includeSpecialPurposes=true';

  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new IabSpecialFeatureAndPurposes({
        specialFeatures: List(resp.specialFeatures).map(el => new IabSpecialFeature(el)),
        specialPurposes: List(resp.specialPurposes).map(el => new IabSpecialFeature(el)),
        features: List(resp.features).map(el => new IabSpecialFeature(el)),
      });
    });
}

export function getVendorListAjax(id) {
  const url = `/vendor-list?vendorListId=${id}&&handleVendorLegalBasis=true`;
  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new VendorList(resp);
    });
}

export function getGlobalVendorsAjax() {
  const url = '/vendors';
  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return OrderedSet(resp.map(vendor => new Vendor(vendor)));
    });
}

export function getGlobalVendorsHashAjax() {
  const url = '/vendors';
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getConsentBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        const vendorHash = {};
        resp.forEach((vendor) => {
          vendorHash[vendor._id] = new Vendor(vendor);
        });
        return new Map(vendorHash);
      });
  }
}

export function createVendorListAjax(accountId, list) {
  return fetch(getConsentBaseUrl() + '/vendor-list', {
    method: 'POST',
    body: JSON.stringify(generateVendorlistData(accountId, list)),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      const updatedResp = Object.assign({}, resp);
      updatedResp.clientSideMessageId = list.clientSideMessageId;
      return new VendorList(updatedResp);
    });
}

export function cloneVendorListAjax(accountId, vendorListId, cloneName) {
  return fetch(getConsentBaseUrl() + `/vendor-list/clone?vendorListId=${vendorListId}`, {
    method: 'POST',
    body: JSON.stringify({ accountId, name : cloneName}),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new VendorList(resp);
    });
}

export function updateVendorListAjax(accountId, list) {
  return fetch(getConsentBaseUrl() + '/vendor-list', {
    method: 'PUT',
    body: JSON.stringify(generateVendorlistData(accountId, list)),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => { return resp.json() })
    .then(resp => {
      return new VendorList(resp);
    });
}

async function checkVendorListStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    const errors = await response.json();
    throw new List(errors).map(err => new VendorListError(err));
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function activateDeactivateVendorListAjax(vendorList) {
  return fetch(getConsentBaseUrl() + '/vendor-list/active', {
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({'_id': vendorList.id, 'isActive': !vendorList.isActive})
  })
  .then(checkVendorListStatus)
  .then(resp => resp.json())
  .then( resp =>{
    return new VendorList(resp);
  })
}

export function deleteVendorListAjax(accountId, id) {
  return fetch(getConsentBaseUrl() + '/vendor-list/' + accountId + '/' + id, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accountId,
    }),
  })
    .then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new VendorList(resp);
    });
}

function generateVendorlistData(accountId, list) {
  const categories = list.categories.map(c => {
    let id = c.id;
    if (c.iabPurposeRefs.includes(c.id)) {
      id = null;
    }
    let category = c.toMap().delete('iabId').set('vendors', c.vendorIds).delete('vendorIds').delete('id').delete('iabPurposeRefs').delete('illustrations');
    if (!category.get('iabPurposeRef')) category = category.delete('iabPurposeRef');
    if (!category.get('iabStackRef')) category = category.delete('iabStackRef');
    if (!category.get('translations')) category = category.delete('translations');

    if (category.get('categories')) {
      const updatedCategories = category.get('categories').map(c => {
        //before save comes as a string
        c = c.toMap().delete('iabId').set('vendors', c.vendorIds).delete('vendorIds').delete('iabPurposeRefs').delete('illustrations');
        if (c.get('id')) {
          c = c.toMap().set('_id', c.get('id')).delete('id');
        } else {
          c = c.toMap().delete('id').delete('_id');
        }
        return c
      })
      category = category.set('categories', updatedCategories)
    }
    if (id) category = category.set('_id', id);
    return category;
  });

  const vendorsWrappers = list.vendorsWrappers.map(vw => {
    const id = vw.id;
    let updatedVendorWrapper = vw.toMap().delete('id');
    if (id) {
      updatedVendorWrapper = updatedVendorWrapper.set('_id', id);
    }
    updatedVendorWrapper = updatedVendorWrapper.update('urlMappingRules', mappings => mappings.map(patterns => ({ patterns })))
    return updatedVendorWrapper;
  }).toJS();

  const data = {
    accountId: accountId.toString(),
    name: list.name,
    description: list.description,
    vendors: list.vendors.map(v => v.id).toSet().toJS(),
    categories,
    consentScope: list.consentScope,
    shareRootDomain: list.shareRootDomain,
    hasConsentCompensation: list.hasConsentCompensation,
    useSpecialTreatmentP1: list.useSpecialTreatmentP1,
    specialTreatmentP1Geo: list.specialTreatmentP1Geo,
    siteIds: list.siteIds.toSet().toJS(),
    isActive: list.isActive,
    vendorsWrappers,
    autoUpdateVendorScan: list.autoUpdateVendorScan,
    autoUpdateIabVendors2: list.allIabAndAutoUpdate,
    cookieMaxAge: list.cookieMaxAge,
    writeFirstPartyCookiesFromServer: list.writeFirstPartyCookiesFromServer,
    type: list.type,
    defaultLegalBasis: list.defaultLegalBasis,
    publisherCmpId: list.publisherCmpId,
    publisherCmpId2: list.publisherCmpId2,
    iabSpecialFeatures: (list.iabSpecialFeatures && list.iabSpecialFeatures.size)
      ? list.iabSpecialFeatures.toJS()
      : list.iabSpecialFeatures,
    displaySpecialPurposesNFeatures: list.displaySpecialPurposesNFeatures,
    customElementsFirst: list.customElementsFirst,
    appliesGeos: list.appliesGeos.map(geo => geo === 'CAN' ? 'CA' : geo),
    exclusionGeos: list.exclusionGeos.map(geo => geo === 'CAN' ? 'CA' : geo),
    useVendorActionsV2: list.useVendorActionsV2,
    publisherPurposes: list.publisherPurposes,
    setEuconsentCookie: list.setEuconsentCookie,
    vendorScanCategorizations: list.vendorScanCategorizations,
    onlyCustom: list.onlyCustom,
    includeDisclosedVendors: list.includeDisclosedVendors,
    noServerStorage: list.noServerStorage,
    noUniqueIdReporting: list.noUniqueIdReporting,
    googleConsentModeV2: list.googleConsentModeV2,
    tcfVersion: list.tcfVersion
  };

  if (list.id) {
    data._id = list.id;
  }

  return data;
}