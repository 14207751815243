import { Map, List, OrderedSet } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';
import moment from 'moment';

const initialState = Map({
  vendorLists: new StoreRecord({ value: List([]) }),
  savePending: false,
  globalVendors: new StoreRecord({ value: OrderedSet([]) }),
  globalVendorsHash: new StoreRecord({ value: Map({}) }),
  purposes: new StoreRecord({ value: List([]) }),
  stacks: new StoreRecord({ value: List([]) }),
  scannedVendors: new StoreRecord({value: List([])}),
  vendorWithProblems: new StoreRecord({value: List([])}),
  enabledSitesForScan: new StoreRecord({ value: List([]) }),
  iabSpecialFeatures: new StoreRecord({ iabSpecialFeatures: List([]) }),
  iabSpecialPurposes: new StoreRecord({ iabSpecialPurposes: List([]) }),
  iabFeatures: new StoreRecord({ iabFeatures: List([]) }),
});

export default (state = initialState, action) => {
  const lists = state.getIn(['vendorLists', 'value']);
  let updatedList;
  let indexOfUpdatedVendorList = -1;

  switch(action.type) {
    case types.VENDOR_LISTS_PENDING:
      return setPending(state, ['vendorLists']);

    case types.GLOBAL_VENDORS_PENDING:
      return setPending(state, ['globalVendors']);

    case types.GLOBAL_VENDORS_LOADED:
      return setValue(state, ['globalVendors'], action.vendors);

    case types.GLOBAL_VENDORS_HASH_PENDING:
      return setPending(state, ['globalVendorsHash']);

    case types.GLOBAL_VENDORS_HASH_LOADED:
      return setValue(state, ['globalVendorsHash'], action.vendors);

    case types.SCANNED_VENDORS_PENDING:
      return setPending(state, ['scannedVendors']);

    case types.SCANNED_VENDORS_LOADED:
      return setValue(state, ['scannedVendors'], action.vendors);

    case types.UNKNOWN_VENDORS_LOADED:
        return setValue(state, ['vendorWithProblems'], action.vendors);

    case types.ENABLED_SITES_FOR_SCAN_LOADED:
      return setValue(state, ['enabledSitesForScan'], action.siteIds).setIn(['scannedVendors', 'pending'], false);

    case types.CLEAR_SCANNED_VENDORS:
      return setValue(state, ['scannedVendors'], List([]));

    case types.VENDOR_LISTS_LOADED:
      return setValue(state, ['vendorLists'], action.vendorLists.sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated))));

    case types.VENDOR_LIST_LOADED:
      // updatedList = lists.filterNot(l => l.id === action.vendorList.id).add(action.vendorList).sortBy(list => list.name.toLowerCase());
      indexOfUpdatedVendorList = lists.findIndex((vendorList) => vendorList.id == action.vendorList.id)
      updatedList = lists.set(indexOfUpdatedVendorList, action.vendorList)
      return setValue(state, ['vendorLists'], updatedList);

    case types.VENDOR_LISTS_ERROR:
      return setError(state, ['vendorLists'], action.error).set('savePending', false);
    
    case types.VENDOR_LISTS_SCAN_ERROR:
      return setError(state, ['scannedVendors'], action.error);

    case types.CLEAR_VENDOR_LIST_ERROR:
      return state.setIn(['vendorLists', 'error'], null);

    case types.VENDOR_LIST_SAVE_PENDING:
      return state.set('savePending', true);

    case types.VENDOR_LIST_CREATED:
      // updatedList = lists.add(action.vendorList).sortBy(list => list.name.toLowerCase());
      updatedList = lists.push(action.vendorList).sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated)));
      return setValue(state, ['vendorLists'], updatedList).set('savePending', false);

    case types.VENDOR_LIST_UPDATED:
      // updatedList = lists.filterNot(l => l.id === action.vendorList.id).add(action.vendorList).sortBy(l => l.name.toLowerCase());
      indexOfUpdatedVendorList = lists.findIndex((vendorList) => vendorList.id == action.vendorList.id)
      updatedList = lists.set(indexOfUpdatedVendorList, action.vendorList)
      return setValue(state, ['vendorLists'], updatedList).set('savePending', false);
    case types.VENDOR_LIST_ACTIVATION:
      indexOfUpdatedVendorList = lists.findIndex((vendorList) => vendorList.id == action.vendorList.id)
      updatedList = lists.setIn([indexOfUpdatedVendorList, 'isActive'], action.vendorList.isActive)
      return setValue(state, ['vendorLists'], updatedList).set('savePending', false);
    
    case types.VENDOR_LIST_DELETED:
      updatedList = lists.filter(l => l.id !== action.id);
      return setValue(state, ['vendorLists'], updatedList);

    case types.VENDOR_LISTS_CACHED:
      return stopPending(state, [action.property]);

    case types.PURPOSES_PENDING:
      return setPending(state, ['purposes']);

    case types.PURPOSES_LOADED:
      return setValue(state, ['purposes'], action.purposes);

    case types.PURPOSES_ERROR:
      return setError(state, ['purposes'], action.errorMessage);

    case types.STACKS_PENDING:
      return setPending(state, ['stacks']);

    case types.STACKS_LOADED:
      return setValue(state, ['stacks'], action.stacks);

    case types.STACKS_ERROR:
      return setError(state, ['stacks'], action.errorMessage);

    case types.IAB_SPECIAL_FEATURES_PENDING:
      return setPending(state, ['iabSpecialFeatures']);

    case types.IAB_SPECIAL_FEATURES_LOADED:
      return setValue(state, ['iabSpecialFeatures'], action.features);

    case types.IAB_SPECIAL_PURPOSES_LOADED:
      return setValue(state, ['iabSpecialPurposes'], action.purposes);

    case types.IAB_FEATURES_LOADED:
      return setValue(state, ['iabFeatures'], action.features);

    case types.IAB_SPECIAL_FEATURES_ERROR:
      return setError(state, ['iabSpecialFeatures'], action.errorMessage);
  }

  return state;
};