/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { DownloadOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Divider, Empty, Table, Tooltip } from 'antd';
import { browserHistory } from 'react-router';

import {
  AfterOptOutVendorTypeFilters,
  CookieDetailsColumn,
  CookieNameColumn,
  FirstAppearedScanColumn,
  InformationStorageColumn,
  LastAppearedScanColumn,
  PrevalenceColumn,
  TechnologyCategorisationColumn,
  VendorDetailsColumn,
  VendorNameColumn,
  VendorReferenceColumn,
  VendorStatusColumn,
  VendorTypeColumn,
} from '../../shared/DiagnoseColumns';

import {
  exportCSVVendorsAfterOptOut,
  getVendorManagementStatusesList,
  getVendorsAfterOptOut,
  getVendorsAfterOptOutFilter,
} from '../../../../../actions/diagnose_dashboard_actions';
import useSearchParams from '../../shared/useSearchParams';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import {
  getUniqueVendorIdsList,
  isFiltersProvided,
  mapSelectedFilters,
  requestDataDependOnTableFilters,
} from '../helpers';

const DetailedBreakdown = ({
  exportCSVVendorsAfterOptOut,
  vendorsAfterOptOutExportPending,
  getVendorsAfterOptOut,
  vendorsAfterOptOut,
  vendorsAfterOptOutPending,
  vendorsAfterOptOutFilter,
  getVendorsAfterOptOutFilter,
  selectedFilters,
  userDetails,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  // for Vendor Management
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);

  const [tableFilters, setTableFilters] = useState(null);
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');
  const getVendorsAfterOptOutData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getVendorsAfterOptOut({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
    return res;
  };

  const getVendorsAfterOptOutFilterData = () => {
    getVendorsAfterOptOutFilter(filters);
  };

  const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
    const query = {
      metric: 'Vendors after opt out',
      vendors,
      status,
      tag,
      websites: selectedFilters?.websites,
    };
    const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
    if (isCacheValid) {
      return vendorManagementStatuses;
    }
    const res = await getVendorManagementStatusesList(query);
    return res;
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      requestDataDependOnTableFilters(
        { filters: tableFilters || {}, isEnabledVendorStatusManagementFeature },
        (f, vendorId) => getVendorsAfterOptOutData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
        getVendorManagementStatusesListData,
      );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      getVendorsAfterOptOutFilterData();
    }
  }, [filters]);

  useEffect(() => {
    if (vendorManagementActivities?.length) {
      getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsList(vendorsAfterOptOut?.results) }, false);
    }
  }, [vendorManagementActivities]);

  const tableData = useMemo(() => {
    if (!isEnabledVendorStatusManagementFeature) {
      return vendorsAfterOptOut?.results;
    }

    return vendorsAfterOptOut?.results?.map((row) => {
      const rowWithVendorStatus = {
        ...row,
        vendor_status:
          vendorManagementStatuses?.filter((vendorStatus) => {
            return vendorStatus.vendor === row.vendor_id;
          }) || [],
      };
      return rowWithVendorStatus;
    });
  }, [isEnabledVendorStatusManagementFeature, vendorsAfterOptOut?.results, vendorManagementStatuses]);

  const vendorsFilter = vendorsAfterOptOutFilter?.vendors?.map((filter) => ({ text: filter, value: filter }));
  const technologiesFilter = vendorsAfterOptOutFilter?.technologies?.map((filter) => ({ text: filter, value: filter }));
  const referenceVendorsFilter = vendorsAfterOptOutFilter?.referrers?.map((filter) => ({
    text: filter,
    value: filter,
  }));
  const cookieNameFilter = vendorsAfterOptOutFilter?.cookies?.map((filter) => ({ text: filter, value: filter }));

  const columns = [
    VendorTypeColumn({
      title: () => {
        return (
          <Tooltip
            title={() => {
              return (
                <div className="information-storage-tooltip" style={{ paddingLeft: '20px' }}>
                  <div>
                    <div
                      className="vendor-type-cell-content"
                      style={{ backgroundColor: '#9124E3', display: 'inline-block', marginRight: '5px' }}
                    />
                    MSPA - The IAB Tech Lab's Global Privacy Platform includes a framework for the United States called
                    the Multi-state Privacy String (MSPS). The MSPS is a signal that notifies downstream vendors that
                    participating publishers have provided users with specific notice and choice over data processing
                    activities on their properties. Coupled with the MSPS (the technical signal) there is a
                    complementary legal framework called the Multi-State Privacy Agreement (MSPA). The MSPA is a joint
                    legal agreement across industry participants that dictates obligations of partners on how the
                    framework should be used in practice so that all participants have confidence that the framework is
                    being used appropriately and in the same way. There is no obligation for vendors to sign the MSPA as
                    they may rely on their own legal framework in conjunction with their partners, but we've shown this
                    label as it may provide you with useful information in order to assess your vendors obligations in
                    the US.
                  </div>
                  <div>
                    <div
                      className="vendor-type-cell-content"
                      style={{
                        backgroundColor: '#EBEBEB',
                        border: '2px solid #B4B4B4',
                        display: 'inline-block',
                        marginRight: '5px',
                      }}
                    />
                    No Label - the vendor is not part of the MSPA
                  </div>
                </div>
              );
            }}
            overlayClassName="information-storage-tooltip-overlay vendor-type--tooltip"
          >
            <InfoCircleFilled />
          </Tooltip>
        );
      },
      filters: AfterOptOutVendorTypeFilters,
    }),
    VendorNameColumn({ filters: vendorsFilter || [] }),
    ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    PrevalenceColumn,
    FirstAppearedScanColumn,
    LastAppearedScanColumn,
    TechnologyCategorisationColumn({ filters: technologiesFilter || [] }),
    InformationStorageColumn,
    VendorReferenceColumn({ filters: referenceVendorsFilter || [] }),
    CookieNameColumn({ filters: cookieNameFilter || [] }),
    CookieDetailsColumn({ metric: 'Vendors after opt out' }),
    VendorDetailsColumn({ metric: 'Vendors after opt out' }),
  ];

  const onTableChange = (pagination, filters, sorter) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    exportCSVVendorsAfterOptOut(filters);
  };

  const isFiltersNotApplied =
    !tableFilters?.technologies &&
    !tableFilters?.vendor_status?.length &&
    !tableFilters?.prevalence?.length &&
    !tableFilters?.information_storage?.length &&
    !tableFilters?.reference_vendors?.length &&
    !tableFilters?.technologies?.length &&
    !tableFilters?.name?.length &&
    !tableFilters?.cookies?.length;

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The table below lists the vendors that we have observed on your properties firing after opt out. The information
        detailed should help you determine whether you should take action to remove or contact these vendors; however,
        we also recommend taking into consideration whether the vendor may be permitted to process personal data even
        after an opt out (e.g., if the vendor meets the requirements of a “service provider” or a “processor” under
        applicable law). Firing after opt out does not mean that the vendor is accessing information or storing a
        cookie, however the recommendation is to scrutinise those that do this in line with regulatory guidelines.
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container">
        {vendorsAfterOptOut?.results?.length ||
        !isFiltersNotApplied ||
        vendorsAfterOptOutPending ||
        vendorManagementStatusesPending ? (
          <>
            <div className={'filters_block'}>
              <div className={'quick_filters'} />
              <div className={'downloadCSV'}>
                <Button icon={<DownloadOutlined />} onClick={exportCsv} loading={vendorsAfterOptOutExportPending} />
              </div>
            </div>
            <Table
              id="detailed-breakdown"
              scroll={{ x: 'max-content', y: 430 }}
              dataSource={tableData}
              columns={columns}
              rowClassName={(record) =>
                record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
                  ? 'diagnose-notification-highlight'
                  : ''
              }
              showSorterTooltip={false}
              rowKey={(record) => record?.vendor_id}
              pagination={{
                current: page,
                pageSize: limit,
                total: tableFilters?.vendor_status?.length && !vendorManagementStatuses.length ? 0 : vendorsAfterOptOut?.total_count,
                position: ['bottomCenter'],
                onChange: onPaginationChange,
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '100', '200'],
              }}
              onChange={onTableChange}
              loading={{
                className: 'diagnose-spinning',
                spinning: vendorsAfterOptOutPending || vendorManagementStatusesPending,
                tip: vendorsAfterOptOut?.results?.length
                  ? ''
                  : 'Processing a large query; please wait or leave it open and check later.',
                size: 'large',
              }}
            />
          </>
        ) : (
          <Empty>
            <span style={{ color: '#00000040' }}> This metric is only applicable to US Privacy Regulations</span>
          </Empty>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    vendorsAfterOptOut: store.diagnoseDashboardState.getIn(['vendorsAfterOptOut', 'value']),
    vendorsAfterOptOutPending: store.diagnoseDashboardState.get('vendorsAfterOptOutPending'),
    vendorsAfterOptOutExportPending: store.diagnoseDashboardState.get('vendorsAfterOptOutExportPending'),
    vendorsAfterOptOutFilter: store.diagnoseDashboardState.getIn(['vendorsAfterOptOutFilter', 'value']),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
    vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVendorsAfterOptOut,
      getVendorsAfterOptOutFilter,
      exportCSVVendorsAfterOptOut,
      getVendorManagementStatusesList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
