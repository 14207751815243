import React from 'react'
import { Map } from 'immutable';
import CSVLink from "../../common/CSVLink.js";
import { Upload } from 'antd';

const { Dragger } = Upload;
const exampleData = [
    ['Property', 'Type'], 
    ['example.com', 'Website'],
    ['*.example.com', 'AMP'],
    ['*tomtest1.com', 'OTT'],
    ['app.mobile', 'APP']
  ];
export default function PropertiesFileUpload(props) {

    const beforeUpload = async (file) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = e => {
            let csv = e.target.result;
            const rows = csv.slice(csv.indexOf('\n')+1).split(/\r\n|\n/);
   
            const filteredProperties = rows.map(row => row.split(',')).filter(([name,type])=> name !== '')
            const uniqueProperties = [
                ...Map(filteredProperties.map(([name,type])=> [name, {name,type}])).values(),
            ]
            props.addProperties(uniqueProperties);
        };
        // Prevent upload
        return false;
      };  
    const uploadProps = {
        name: 'file',
        accept: '.csv',
        maxCount: 1,
        beforeUpload: beforeUpload,
      };

  return (
    <Dragger {...uploadProps}>
        <p className='drop-files'>Drop file here or Click</p>
        <span>Download a </span>
        <CSVLink
        data={exampleData}
        enclosingCharacter={``}
        filename={new Date() + ' data.csv'}
        onClick={(e) => e.stopPropagation()}
        isSanitized={true}>
        Sample CSV Template 
        </CSVLink> to see an example of the format required.
    </Dragger> 
  )
}
