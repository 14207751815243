/* eslint-disable import/prefer-default-export */

export const compareStr = (pathname, comparePath, split = 'diagnose_dashboard') => {
  const result = ((pathname.split(split))[1]).localeCompare(comparePath);
  return result === 0;
};

export function formatRegions(regions) {
  const sorting = {
    'EEA:GB': 1,
    'EEA:DE': 1,
    'EEA:NO': 1,
  };
  return Object
    .keys(regions)
    .map((zone) => ({
      title: zone,
      value: zone,
      children: regions[zone]
        .map((region) => ({
          value: `${zone}:${region.value}`,
          title: region.title,
        }))
        .sort((a, b) => {
          const toNumber = (val) => {
            return typeof val === 'number' ? val : +!!val;
          };
          return toNumber(sorting?.[b.value]) - toNumber(sorting?.[a.value])
        }),
    }));
}

export function getFromLocalStorage(key, defaultValue = null) {
  const storedValue = localStorage.getItem(key);
  if (!storedValue) return defaultValue;
  try {
    return JSON.parse(storedValue);
  } catch (err) {
    return defaultValue;
  }
}
