import React, { useEffect, useState } from "react";
import { DeleteOutlined, WarningFilled } from '@ant-design/icons';
import { Collapse } from 'antd';
import { Toggle, Button, Infotip, Alert } from '../../../styleguide';
import { Translations } from '../../../records/vendor_list_records';
import { List } from "immutable";
import ReactQuill from 'react-quill';
import { element } from "prop-types";

const { Panel } = Collapse;
const customIllustrationsTranslationsWarningMessage = "We only provide language support for IAB illustrations. If you choose to display custom illustrations in the message, you will need to manually add the translations for all the languages that you want support for."

function PurposeIllustrations(props) {
  const [useIabIllustrations, setUseIabIllustrations] = useState({
    0 : props.purpose.useIabIllustration1 ?? true,
    1 : props.purpose.useIabIllustration2 ?? true
  })
  const [currentIllustration, setCurrentIllustration] = useState(List([]));
  const [activeKeys, setActiveKeys] = useState(['iab-0','iab-1']);
  const [panels,setPanels] = useState([]);

  if(props.purpose.type === 'IAB_STACK' || props.purpose.type === 'CUSTOM_STACK') {
    return null;
  }

  const handleCustomIllstrationsChange = (updatedIllustrations) =>{
    if(props.translateLanguage !== 'default') {
      const translations = props.purpose.translations || new Translations({});
      const updatedTranslations = translations.setIn(['illustrations', props.translateLanguage], updatedIllustrations)
      props.updatePurpose(props.purpose.set('translations', updatedTranslations));
    } else {
      props.updatePurpose(props.purpose.set('customIllustrations', updatedIllustrations));
    }
  }
  const addCustomIllustration = () => {
    let updatedCustomIllustrations = currentIllustration.push("")
    handleCustomIllstrationsChange(updatedCustomIllustrations);
    setActiveKeys([...new Set([...activeKeys, `custom-${updatedCustomIllustrations.size - 1}`])])
  }
  const deleteCustomIllustration = (idx) => {
    let updatedCustomIllustrations = currentIllustration.delete(idx)
    // updatedCustomIllustrations.splice(idx,1);
    handleCustomIllstrationsChange(updatedCustomIllustrations)
    setActiveKeys(activeKeys.filter(key => key !== `custom-${idx}`))
  }
  const onChange = (element, idx) => {
    handleCustomIllstrationsChange(currentIllustration.update(idx,() => element.target.innerHTML ))
  }

  const onUseIabChange = (checked,i) => {
    setUseIabIllustrations({ ...useIabIllustrations, [i]: checked})
    if(checked)
      setActiveKeys([...new Set([...activeKeys, `iab-${i}`])])
    else
      setActiveKeys(activeKeys.filter(key => key !== `iab-${i}`))
  }

  const getToggle = (i) => {
    const isDisabled =
      (useIabIllustrations[i] &&
        Object.values(useIabIllustrations).filter((v) => v).length <= 1) ||
      props.iabPurpose?.illustrations.length <= 1;
    return (
      <div  onClick={(e) => {e.stopPropagation && e.stopPropagation()}}>
        {isDisabled ? (
          <Infotip
            className="pointer"
            trigger="hover"
            title={null}
            content={"At least one IAB Illustration needs to stay selected."}
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
            destroyTooltipOnHide={true}
          >
            <span>Use this illustrations</span>
            <Toggle
              checked={useIabIllustrations[i]}
              onChange={(checked) => onUseIabChange(checked, i)}
              disabled={isDisabled}
            />
          </Infotip>
        ) : (
          <>
          <span>Use this illustrations</span>
          <Toggle
            checked={useIabIllustrations[i]}
            onChange={(checked) => onUseIabChange(checked, i)}
            disabled={isDisabled}
          />
          </>
        )}
      </div>
    );
  };

  useEffect(()=>{
    let panels = [];
    props.iabPurpose?.illustrations.forEach((illustration,idx) => {
      panels.push(
        <Panel header={`IAB Illustration(${idx+1})`} extra={getToggle(idx)} key={`iab-${idx}`}>
          <div className="description">
            {illustration}
          </div>
        </Panel>
      )
    })
    currentIllustration.forEach((illustration,idx) => {
      panels.push(
        <Panel header={`Custom Illustration(${idx+1})`} extra={!props.readOnly ? <DeleteOutlined onClick={()=>deleteCustomIllustration(idx)}/> : null} key={`custom-${idx}`}>
          <ReactQuill
            style={{ height: 140, marginBottom: 55 }}
            onKeyUp={(element) => {
              onChange(element, idx);
            }}
            value={illustration}
            formats={props.formats}
            readOnly={props.readOnly}
          />
        </Panel>
      )
    });
    setPanels(panels)
  },[currentIllustration, props.iabPurpose, useIabIllustrations, activeKeys])
 
  useEffect(()=>{
    let currentIllustration;
    if(props.translateLanguage !== 'default') {
      const translations = _.cloneDeep(props.purpose.translations) || new Translations({});
      currentIllustration = translations.getIn(['illustrations', props.translateLanguage]) || List([]);
    } else {
      currentIllustration = _.cloneDeep(props.purpose.customIllustrations) || List([]);
    }
    setCurrentIllustration(currentIllustration)
  },[props.translateLanguage, props.purpose])

  useEffect(()=>{
    props.updatePurpose(props.purpose.set('useIabIllustration1',useIabIllustrations[0]).set('useIabIllustration2',useIabIllustrations[1]))
  },[useIabIllustrations]);

  const isPurpose1 = (props.iabPurpose && props.iabPurpose?.iabId === 1)

  return (
    <>
    <label className="title">Illustration</label>
    <div className="purpose-illustrations">
      <div className="container">
        {currentIllustration?.size || Object.values(useIabIllustrations).filter((v) => v).length <= 1 ? (
          <p>By not using standard illustrations and/or including custom supplementary illustrations in your message, the TC String will be updated to indicate this to downstream vendors. This could result in some vendors refusing to work with you as it may present additional legal risk.</p>
        ) : null}
        <Collapse 
          activeKey={activeKeys}
          onChange={(value)=>setActiveKeys(value)}
          ghost
        >
          {panels}
        </Collapse>
        {currentIllustration?.size && props.translateLanguage == 'default' && !props.readOnly ? (<Alert type="warning" message={customIllustrationsTranslationsWarningMessage} showIcon icon={<WarningFilled />}/>) : null}
        {isPurpose1 || props.readOnly ? null : <Button type='secondary' onClick={addCustomIllustration}>+ Add Custom Illustration</Button>}
      </div>
    </div>
    </>
  )
}

export default PurposeIllustrations;
