import React, { useState } from 'react';
import {
  Layout,
  Button,
  Modal,
} from 'antd';
import { Input } from '../../../styleguide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import CustomFields from './configurations/CustomFields';
import TitleBody from './configurations/TitleBody';
import ChoiceOptions from './configurations/ChoiceOptions/ChoiceOptions';

const menuSet = new Set([
  { value: 'title', text: 'title' },
  { value: 'body', text: 'body' },
  { value: 'choice-options', text: 'choice options' },
  { value: 'custom-fields', text: 'additional custom fields' },
]);

const NativeBuilderModal = ({
  message,
  saveMessage,
  close,
  siteId,
  accountId,
}) => {
  const editing = Boolean(message);
  message = editing ? JSON.parse(message.message_json) : starterMsg;

  const [msg, setMessage] = useState(customFieldstoArrays(_.cloneDeep(message)));
  const [selection, setSelection] = useState('title');

  const updateItem = (path, value) => {
    console.log('path: ', path)
    console.log('value: ', value)

    const updatedMsg = traverseAndModify(msg, path, () => value);
    setMessage(updatedMsg);
  };

  const addItem = (path, item) => {
    const updatedMsg = traverseAndModify(msg, path, (s) => [...s, item]);
    setMessage(updatedMsg);
  };

  const removeItem = (path, index) => {
    const rm = (s) => [...s.slice(0, index), ...s.slice(index + 1)];
    const updatedMsg = traverseAndModify(msg, path, rm);
    setMessage(updatedMsg);
  }

  const handleMessageBuilderClose = () => {
    close(customFieldsToObjects(msg))
  }

  let selectedView;
  switch (selection) {
    case 'title':
    case 'body':
      selectedView = (
        <TitleBody
          type={selection}
          text={msg[selection].text}
          style={msg[selection].style}
          updateItem={updateItem}
          customFields={msg[selection].customFields}
          addField={(field) => addItem([selection, 'customFields'], field)}
          removeField={(i) => removeItem([selection, 'customFields'], i)}
        />
      );
      break;
    case 'choice-options':
      selectedView = (
        <ChoiceOptions
          siteId={siteId}
          accountId={accountId}
          choiceOptions={msg.actions}
          updateItem={updateItem}
          addItem={addItem}
          removeItem={removeItem}
        />
      );
      break;
    case 'custom-fields':
      selectedView = (
        <CustomFields
          fields={msg.customFields}
          updateField={(i, key, value) => updateItem(['customFields', i, key], value)}
          addField={(value) => addItem(['customFields'], value)}
          removeField={(i) => removeItem(['customFields'], i)}
        />
      );
      break;
  }

  const menuItems = [...menuSet].map(({ value, text }) => (
    <div
      id={`native-msg-menu-item-${value}`}
      className={`section-menu-item ${selection === value ? 'active' : null}`}
      onClick={() => setSelection(value)}
    >
      <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#c9c9c9" />
      {text}
    </div>
  ))
  return (
    <Modal
      wrapClassName="message-builder native-message-builder"
      closable={false}
      visible
    >
      <div className="builder-title">
        <h3>
          Builder: {msg.name}
        </h3>
        <Button
          id="save-native-msg-btn"
          type="primary"
          onClick={() => saveMessage([customFieldsToObjects(msg)], null, true)}
        >
          {editing ? 'Save Message' : 'Create Message'}
        </Button>
        <Button
          id="close-native-msg-btn"
          onClick={handleMessageBuilderClose}
          type="default"
        >
          Close
        </Button>
      </div>
      <Layout className="bulletin" style={{ minHeight: 'calc(100vh - 68px)' }}>
        <Layout>
          <Layout.Content className="notice-preview">
            <div className="content-container">
              {selectedView}
            </div>
          </Layout.Content>
          <Layout.Sider className="sidebar" width={300}>
            <div className="name-container">
              <div>
                <label htmlFor="native-msg-name-input">
                  name
                </label>
                <Input
                  id="native-msg-name-input"
                  value={msg.name}
                  onChange={({ target: { value } }) => updateItem(['name'], value)}
                  error={msg.name.trim() == "" ? "Name field can not be empty" : null}
                />
              </div>
            </div>
            <div className="section-menu">
              {menuItems}
            </div>
          </Layout.Sider>
        </Layout>
      </Layout>
    </Modal>
  );
};

function traverseAndModify(msg, path, cb) {
  const clonedMsg = _.cloneDeep(msg);
  let section = clonedMsg;
  while (path.length) {
    const key = path.shift();
    if (path.length) {
      section = section[key];
    } else {
      section[key] = cb(section[key]);
    };
  };

  return clonedMsg;
}

function customFieldstoArrays(msg) {
  return Object.entries(msg).reduce((msg, [k, v]) => {
    if (k === 'customFields') {
      msg[k] = Object.entries(v).map(([k, v]) => ({ key: k, value: v }));
    } else if (typeof v === 'object' && !Array.isArray(v)) {
      msg[k] = customFieldstoArrays(v);
    } else if (Array.isArray(v)) {
      msg[k] = v.map(item => customFieldstoArrays(item));
    } else {
      msg[k] = v;
    };

    return msg;
  }, {});
};

function customFieldsToObjects(msg) {
  return Object.entries(msg).reduce((msg, [k, v]) => {
    if (k === 'customFields') {
      msg[k] = v.reduce((msg, { key, value }) => ({ ...msg, [key]: value }), {});
    } else if (typeof v === 'object' && !Array.isArray(v)) {
      msg[k] = customFieldsToObjects(v);
    } else if (Array.isArray(v)) {
      msg[k] = v.map(customFieldsToObjects);
    } else {
      msg[k] = v;
    };

    return msg;
  }, {});
};

const starterMsg = {
  name: 'New Message',
  title: {
    text: '',
    style: {
      fontFamily: 'Arial',
      fontWeight: '400',
      fontSize: 16,
      color: '#000000',
      backgroundColor: '#ffffff'
    },
    customFields: {}
  },
  body: {
    text: '',
    style: {
      fontFamily: 'Arial',
      fontWeight: '400',
      fontSize: 14,
      color: '#000000',
      backgroundColor: '#ffffff'
    },
    customFields: {}
  },
  actions: [],
  customFields: {}
};

export default NativeBuilderModal;