/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { CloseOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Empty, Modal, Row, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import { browserHistory, withRouter } from 'react-router';

import {
  getVendorManagementActivitiesListClear,
  setIsVendorStatusMultiselectOperationActive,
  setVendorStatusFilterList,
  setVendorStatusSelectedPropertyList,
  vendorManagementActivitiesListClear,
} from '../../../../actions/diagnose_dashboard_actions';
import VendorStatusActivity from './VendorStatusActivity';
import VendorStatusPropertySelection from './VendorStatusPropertySelection';
import { formatClassName, plainOptionsStatuses, plainOptionsTags } from './helpers';

import emptyIcon from '../../../../assets/images/empty.svg';
import multiSelectIconPart from '../../../../assets/icons/multisiectIconPart.svg';
import unreviewedStatusIcon from '../../../../assets/icons/unreviwed-status.svg';
import underReviewStatusIcon from '../../../../assets/icons/under-review-status.svg';
import allowedStatusIcon from '../../../../assets/icons/allowed-status.svg';
import actionRequiredStatusIcon from '../../../../assets/icons/action-required.svg';
import allowedRiskStatusIcon from '../../../../assets/icons/allowed-risk-status.svg';
import useSearchParams from '../shared/useSearchParams';

const VendorStatusModal = ({
  defaultOpen = false,
  data,
  location,
  setVendorStatusSelectedPropertyList,
  addVendorManagementActivitiesPending,
  vendorManagementActivitiesListClear,
  isVendorStatusMultiselectOperationActive,
  getVendorManagementActivitiesListPending,
  vendorManagementActivitiesList,
  getVendorManagementActivitiesListClear,
  setIsVendorStatusMultiselectOperationActive,
  setVendorStatusFilterList,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(defaultOpen);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const searchParams = useSearchParams();

  const metric = {
    '/non_disclosed_vendors_observed': 'Non disclosed vendors observed',
    '/vendors_triggered_prior_to_consent': 'Vendors triggered prior to consent',
    '/disclosed_vendors': 'Disclosed Vendors',
    '/possible_fingerprinting': 'Possible Fingerprinting',
    '/data_leaving_the_EEA': 'Data leaving the EEA',
    '/cookies_with_long_lifespans': 'Cookies with long lifespans',
    '/all_cookies_found': 'All Cookies Found',
    '/vendors_after_opt_out': 'Vendors after opt out',
    '/tracking_tech_detected': 'Tracking Tech',
    '/tracking_tech_detected/vendor_details': 'Tracking Tech',
  }[location.pathname.split('diagnose_dashboard')[1]];
  const isAlert = ['CHANGES_DETECTED', 'CHANGED_STATUS'].includes(searchParams.type);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    getVendorManagementActivitiesListClear();
    setVendorStatusSelectedPropertyList([]);
    vendorManagementActivitiesListClear();
    setIsVendorStatusMultiselectOperationActive(false);
    setVendorStatusFilterList([...plainOptionsStatuses, ...plainOptionsTags]);
    browserHistory.replace(location.pathname);
  };

  const tooltipComponent = () => {
    const groupDataByKey = (xs, key) => {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, []);
    };
    const groupedDataByStatus = groupDataByKey(data.vendor_status, 'status');
    return (
      <div style={{ display: 'flex' }}>
        {Object.keys(groupedDataByStatus).map((statusName) => {
          const hasNewTag = groupedDataByStatus[statusName].filter((value) => value.tag === 'New').length;
          const hasChangeDetectedTag = groupedDataByStatus[statusName].filter(
            (value) => value.tag === 'Change Detected',
          ).length;
          return (
            <Tooltip
              key={statusName}
              title={() => {
                return (
                  <div className={'vendor-status-tooltip'}>
                    <div className={`vendor-status-tooltip-title ${formatClassName(statusName)}-title`}>
                      {statusName}
                    </div>
                    <div className={'vendor-status-tag-legend'}>
                      {hasNewTag ? (
                        <div className={'vendor-status-tag-legend-item new-tag'}>
                          <div className={'tag-icon'} />
                          <div>New</div>
                        </div>
                      ) : null}
                      {hasChangeDetectedTag ? (
                        <div className={'vendor-status-tag-legend-item change-detected-tag'}>
                          <div className={'tag-icon'} />
                          <div>Change Detected</div>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {groupedDataByStatus[statusName].map((vendorStatus) => {
                        return (
                          <div className={'vendor-list-status-item'} key={vendorStatus.website_name + vendorStatus.tag}>
                            <div className={'vendor-status-website-name'}>{vendorStatus.website_name}</div>
                            <div
                              className={`vendor-status-tag ${vendorStatus.tag === 'New' ? 'blue-tag' : ''} ${
                                vendorStatus.tag === 'Change Detected' ? 'yellow-tag' : ''
                              }`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }}
              overlayClassName="diagnose--tooltip vendor-status-tooltip-overlay"
              placement={'bottom'}
            >
              <div className="vendor-status-content">
                {statusName === 'Approved' && (
                  <div
                    className={'vendor-status-cell-content-approved'}
                    tabIndex="0"
                    role={'button'}
                    onClick={showModal}
                  >
                    <SVG src={allowedStatusIcon} className={'status-icon'} />
                    <div>{groupedDataByStatus[statusName].length}</div>
                  </div>
                )}
                {statusName === 'Unreviewed' && (
                  <div
                    className={'vendor-status-cell-content-unreviewed'}
                    tabIndex="0"
                    role={'button'}
                    onClick={showModal}
                  >
                    <SVG src={unreviewedStatusIcon} className={'status-icon'} />
                    <div>{groupedDataByStatus[statusName].length}</div>
                  </div>
                )}
                {statusName === 'Action Required' && (
                  <div className={'vendor-status-cell-content-action'} tabIndex="0" role={'button'} onClick={showModal}>
                    <SVG src={actionRequiredStatusIcon} className={'status-icon'} />
                    <div>{groupedDataByStatus[statusName].length}</div>
                  </div>
                )}
                {statusName === 'Under Review' && (
                  <div className={'vendor-status-cell-content-under'} tabIndex="0" role={'button'} onClick={showModal}>
                    <SVG src={underReviewStatusIcon} className={'status-icon'} />
                    <div>{groupedDataByStatus[statusName].length}</div>
                  </div>
                )}
                {statusName === 'Approved with risk' && (
                  <div className={'vendor-status-cell-content-risk'} tabIndex="0" role={'button'} onClick={showModal}>
                    <SVG src={allowedRiskStatusIcon} className={'status-icon'} />
                    <div>{groupedDataByStatus[statusName].length}</div>
                  </div>
                )}
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const multiselectModeIcon = () => {
    return (
      <div className={'multi-select-icon-block'}>
        <div className={'multi-select-icon-row'}>
          <SVG src={multiSelectIconPart} />
          <div className={'multi-select-icon-line sm'} />
        </div>
        <div className={'multi-select-icon-row'}>
          <SVG src={multiSelectIconPart} />
          <div className={'multi-select-icon-line lg'} />
        </div>
        <div className={'multi-select-icon-row'}>
          <SVG src={multiSelectIconPart} />
          <div className={'multi-select-icon-line md'} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div>{tooltipComponent()}</div>
      {isModalVisible ? (
        <Modal
          wrapClassName={'vendor-status-modal'}
          closeIcon={<CloseOutlined style={{ color: 'blue' }} />}
          title={
            <div>
              <span style={{ fontSize: '20px', lineHeight: '24px', fontWeight: '300' }}>Vendor status</span>
            </div>
          }
          open={isModalVisible}
          onCancel={handleCancel}
          className="avocado-modal"
          footer={null}
          width={1200}
        >
          <div>
            {visibleAlert && (
              <Alert
                showIcon
                message="Status uploaded Successfully"
                type="success"
                closable
                afterClose={() => setVisibleAlert(false)}
              />
            )}
            <Row style={{ margin: '10px 0 20px' }}>
              <Col
                span={12 /* 6 */}
                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '8px' }}
              >
                <p style={{ marginBottom: 0 }}>Vendor: </p>
                <p style={{ marginBottom: 0 }} className={isAlert ? 'diagnose-notification-highlight highlight' : ''}>
                  <b>{data?.name}</b>
                </p>
                {/* <Select
                                virtual
                                className={'spsg-select-multiple diagnose-dashboard-select'}
                                popupClassName={'spsg-select-multiple-drodown'}
                                menuItemSelectedIcon={<div />}
                                optionFilterProp={'name'}
                                tagRender={Tag}
                                filterOption={(input, option) => {
                                    return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0; 
}
                                }
                                value={[data?.vendor_id]}
                                onSelect={console.log}
                                onDeselect={console.log}
                                onClear={console.log}
                            >
                                {[{ title: data?.name, value: data?.vendor_id }]?.map((option) => <Option key={`option-${option.value}`} value={option.value} title={option.title}>
                                    <span className="spsg-select-dropdown-label" title={option.title}>{option.title}</span>
                                    <CheckSquareFilled />
                                </Option>)}
                            </Select> */}
              </Col>
              <Col
                span={12 /* 18 */}
                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '8px' }}
              >
                <p style={{ marginBottom: 0 }}>Metric:</p>
                <p style={{ marginBottom: 0 }} className={isAlert ? 'diagnose-notification-highlight highlight' : ''}>
                  {location.pathname === '/diagnose_dashboard/tracking_tech_detected' ||
                  location.pathname === '/diagnose_dashboard/tracking_tech_detected/vendor_details' ? (
                    <b>Tracking Tech Detected</b>
                  ) : (
                    <b>{metric}</b>
                  )}
                </p>
              </Col>
            </Row>
            <Divider style={{ borderColor: '#DADADA', marginTop: '0' }} />
            <div className={'detailed-info-block'}>
              <VendorStatusPropertySelection selectedMetric={metric} data={data} />
              <div className={'detailed-info-results'}>
                <Spin
                  spinning={addVendorManagementActivitiesPending || getVendorManagementActivitiesListPending}
                  size="small"
                >
                  {vendorManagementActivitiesList?.length ? (
                    <VendorStatusActivity selectedMetric={metric} data={data} />
                  ) : (
                    <Empty
                      image={isVendorStatusMultiselectOperationActive ? multiselectModeIcon() : <SVG src={emptyIcon} />}
                      description={
                        isVendorStatusMultiselectOperationActive ? (
                          <div className={'multi-select-operation-container'}>
                            <div className={'multi-select-operation-title'}>Multi-select Operation</div>
                            <div>Select properties from the left to change Vendor status in bulk.</div>
                          </div>
                        ) : (
                          'Please Select Any Property to view Detailed Information'
                        )
                      }
                    >
                      {isVendorStatusMultiselectOperationActive ? (
                        <Button
                          className={'cancel-multi-select-operation-btn'}
                          onClick={() => setIsVendorStatusMultiselectOperationActive(false)}
                          type="primary"
                        >
                          Cancel
                        </Button>
                      ) : null}
                    </Empty>
                  )}
                </Spin>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToProps = function (store) {
  return {
    addVendorManagementActivitiesPending: store.diagnoseDashboardState.get('addVendorManagementActivitiesPending'),
    isVendorStatusMultiselectOperationActive: store.diagnoseDashboardState.getIn([
      'isVendorStatusMultiselectOperationActive',
      'value',
    ]),
    getVendorManagementActivitiesListPending: store.diagnoseDashboardState.getIn([
      'getVendorManagementActivitiesListPending',
      'value',
    ]),
    vendorManagementActivitiesList: store.diagnoseDashboardState.getIn(['vendorManagementActivitiesList', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setVendorStatusSelectedPropertyList,
      vendorManagementActivitiesListClear,
      getVendorManagementActivitiesListClear,
      setIsVendorStatusMultiselectOperationActive,
      setVendorStatusFilterList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VendorStatusModal));
