import React from 'react';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const HiddenFields = ({
  fields = [],
  addHiddenField,
  removeHiddenField,
  updateHiddenField,
}) => {
  const handleChange = (index, key) => ({ target: { value } }) => updateHiddenField(index, key, value);

  const addHiddenFieldButton = (
    <div className="add-input" onClick={addHiddenField}>
      <div>
        <FontAwesomeIcon icon={faPlusCircle} />
      </div>
      <div>
        Add Hidden Field
      </div>
    </div>
  );
  return (
    <div className="hidden-fields-container">
      {fields.length
        ? (
          <React.Fragment>
            <div className="header-container">
              <div>
                Field Name
              </div>
              <div>
                Field Value
              </div>
            </div>
            {fields.map(({ fieldName, fieldValue }, i) => (
              <div className="field-container">
                <Input data-testid="fieldName-input" value={fieldName} onChange={handleChange(i, 'fieldName')} />
                <Input data-testid="fieldValue-input" value={fieldValue} onChange={handleChange(i, 'fieldValue')} />
                <div className="rm-btn" onClick={() => removeHiddenField(i)}>
                  {'\u2715' /* unicode for X symbol */}
                </div>
              </div>
            ))}
            {addHiddenFieldButton}
      </React.Fragment>
        )
        : (
          <div className="empty-hidden-fields-container">
            <div>
              You haven't added any hidden fields
            </div>
            {addHiddenFieldButton}
      </div>
        )}
    </div >
  );
};

export default HiddenFields;