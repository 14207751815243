
import React from 'react';
import NumberStep from './NumberStep';

export default class DelayCircularRepeat extends React.Component {
  render() {
    return (
      <NumberStep
        {...this.props}
        label={ 'Number of Times Repeat' }
      />
    );
  }
}
