import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

export default class extends React.Component {
    static propTypes = {
        value: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        focusOnMount: PropTypes.bool,
    };

    static defaultProps = { focusOnMount: false };

    componentDidMount() {
        if (this.props.focusOnMount) ReactDOM.findDOMNode(this.refs.select).focus();
    }

    handleChangeSatisfy = (event) => {
        var continueOnSatisfy = event.target.value === "Yes" ? true : false;
        this.props.onChange(continueOnSatisfy);
    };

    render() {
        var continueOnSatisfy = this.props.value ? "Yes" : "No";

        return (
            <select ref="select" value={ continueOnSatisfy } onChange={ this.handleChangeSatisfy }>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        );
    }
}
