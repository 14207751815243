import React, { useState, useEffect, useMemo } from "react";
import DismissErrorModal from "./DismissErrorModal";
import { SearchOutlined, CloseOutlined,} from '@ant-design/icons';
import { Input, Chip } from '../../styleguide';

const PropertiesDetailModal = (props) => {
  const [searchTextProperties, setSearchTextProperties] = useState("");
  const [siteAndSiteGroups, setSiteAndSiteGroups] = useState([]);

  useEffect(() => {
    if (props.siteIds?.length > 0) {
      let groups = {};
      props.siteGroups.forEach((obj) => {
        let siteIds = [];
        props.siteIds.map((s) => {
          if (obj.siteIds.includes(s)) {
            const name = props.sites.find((p) => p.get("id") === s)?.domain;
            siteIds.push(name);
            groups[obj.name] = siteIds;
          }
        });
      });

      let groupNames = Object.values(groups).reduce(
        (result, arr) => result.concat(arr),
        []
      );
      let currRegSiteNames = props.sites
        .filter((s) => props.siteIds.includes(s.id))
        .map((pr) => pr?.domain);
      let noGroupIds = currRegSiteNames.filter((s) => !groupNames.includes(s));

      groups["nogroup"] = noGroupIds.toJS();

      const filteredData = Object.keys(groups).reduce((filteredObj, group) => {
        const filteredProperties = groups[group].filter((property) =>
          property.toLowerCase().includes(searchTextProperties.toLowerCase())
        );
        if (filteredProperties.length > 0) {
          filteredObj[group] = filteredProperties;
        }
        return filteredObj;
      }, {});
      setSiteAndSiteGroups(filteredData);
    } else {
      setSiteAndSiteGroups({});
    }
  }, [props.siteIds, searchTextProperties]);

  const filteredPropertiesCount = Object.values(siteAndSiteGroups).reduce(
    (acc, arr) => acc + arr.length,
    0
  );
  const propertyDetailRegulation = (
    <>
      <div className="title">Properties ({props.siteIds?.length})</div>
      <div className="search">
        <Input
          placeholder="Search for properties"
          type="search"
          value={searchTextProperties}
          onChange={(e) => {
            setSearchTextProperties(e.target.value);
          }}
          suffix={
            searchTextProperties.trim() === "" ? (
              <SearchOutlined />
            ) : (
              <CloseOutlined onClick={() => setSearchTextProperties("")} />
            )
          }
        />
        {searchTextProperties.trim().length ? (
          <span> {filteredPropertiesCount} result(s) found</span>
        ) : null}
      </div>
      <div className="sub-heading">
        <span className="circle"></span>Filtered Properties
      </div>
      <div className="property-container top-margin">
        <div>
          {Object.keys(siteAndSiteGroups).reverse().map((key) => (
            <>
              <div>
                <b>{key === "nogroup" ? "" : key}</b>
              </div>
              <div>
                {siteAndSiteGroups[key]?.map((site) => (
                  <Chip
                    className={
                      props.filteredSiteIds?.map(sId => props.indexSiteMap[sId]).includes(site) ? "selected" : ""
                    }
                  >
                    {site}
                  </Chip>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );

  return props.showPropertiesDetailModal ? (
    <DismissErrorModal
      modalWrapperClass="properties-detail"
      title={""}
      error={propertyDetailRegulation}
      isModalVisible={props.showPropertiesDetailModal}
      handleCancel={() => props.setShowPropertiesDetailsModal(false)}
      renderOk={false}
      okText={"OK"}
      cancelText={"Close"}
      handleOk={() => props.setShowActivateErrorModal(false)}
      primaryAction="submit"
    />
  ) : null;
};

export default PropertiesDetailModal;