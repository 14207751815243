import React from 'react';
import { Slider as AntdSlider } from 'antd';

const Slider = (incomingProps) => {
  const props = {...incomingProps};
  props.className = 'spsg-slider ' + (props.className || '');

  return <AntdSlider {...props} />;
};

export default Slider;