import PropTypes from 'prop-types';
import React from 'react';
import { Radio } from 'antd';
import moment from 'moment';
import { DATEPICKERS } from '../../constants'
const { Group, Button } = Radio;

class CustomDateRange extends React.Component {
    static propTypes = {
        selectDatePicker: PropTypes.func.isRequired,
    }

    selectDatePicker = (e) => {
        let date
        let start
        let end
        if (e.target.value === DATEPICKERS.day) {
            date = moment().subtract(1, 'days')
            start = date
            end = date
        }
        else if (e.target.value === DATEPICKERS.week) {
            date = moment().subtract(1, 'weeks')
            start = moment(date).startOf('isoWeek')
            end = moment(date).endOf('isoWeek')
        }
        else if (e.target.value === DATEPICKERS.month) {
            date = moment().subtract(1, 'months')
            start = moment(date).startOf('month')
            end = moment(date).endOf('month')
        }
        this.props.selectDatePicker(e, {
            start,
            end
        })
    }

    render() {
        return (
            <div className='date-range'>
                <Group onChange={(e) => this.selectDatePicker(e)} defaultValue='DAY' buttonStyle='solid'>
                    <Button range={{ amount: 1, type: 'days' }} value='DAY'>DAY</Button>
                    <Button range={{ amount: 7, type: 'days' }} value='WEEK'>WEEK</Button>
                    <Button range={{ amount: 30, type: 'days' }} value='MONTH'>MONTH</Button>
                </Group>
            </div>
        );
    }
}

export default CustomDateRange;