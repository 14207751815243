import React from 'react';
import { Input } from 'antd';
import { Link } from 'react-router';

const CategoryImagesPM = ({
  updateSetting,
  settingKey,
  value: categoryMappings,
  pmData,
}) => {
  const onChange = (categoryName) => (e) => {
    let categoryIndex = categoryMappings.findIndex(entry => entry.category === categoryName);
    categoryIndex = categoryIndex === -1 ? categoryMappings.length : categoryIndex;

    const updatedCategoryMappings = [
      ...categoryMappings.slice(0, categoryIndex),
      { category: categoryName, url: e.target.value },
      ...categoryMappings.slice(categoryIndex + 1)
    ];

    updateSetting(settingKey, updatedCategoryMappings);
  }

  return (
    <div>
      {pmData
        ? pmData.categories.map(({ name }) => (
          <div>
            <label
              style={{ fontWeight: 500, display: 'block' }}
              htmlFor={name}
            >
              {name}
            </label>
            <Input
              style={{ width: '65%' }}
              name={name} placeholder="Image URL"
              value={(categoryMappings.find(({ category }) => category === name) || {}).url}
              onChange={onChange(name)}
            />
          </div>
        ))
        : (
          <div>
            Please <Link to="/consent_v2/vendor_lists">Apply a Vendor List</Link> to this site to map images to categories
          </div>
        )}
    </div>
  );
};

export default CategoryImagesPM;