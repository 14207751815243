import * as types from './action_types';
import store from '../store.js';
import {
  getSystemAccuracyAjax,
  getSystemAccuracyTimelineAjax,
} from '../api/consent_quality/accuracy';

import { actionCreator } from './helpers';

export const getSystemAccuracy = actionCreator(
  getSystemAccuracyAjax,
  systemAccuracyPending,
  systemAccuracyLoaded,
  systemAccuracyError,
);

export const getSystemAccuracyTimeline = actionCreator(
  getSystemAccuracyTimelineAjax,
  systemAccuracyPending,
  systemAccuracyTimelineLoaded,
  systemAccuracyError,
);

function systemAccuracyPending() {
  return ({
    type: types.SYSTEM_ACCURACY_PENDING,
  });
}

function systemAccuracyLoaded(systemAccuracy) {
  return ({
    type: types.SYSTEM_ACCURACY_LOADED,
    systemAccuracy,
  });
}

function systemAccuracyTimelineLoaded(systemAccuracy) {
  return ({
    type: types.SYSTEM_ACCURACY_TIMELINE_LOADED,
    systemAccuracy,
  });
}

function systemAccuracyError(error) {
  return ({
    type: types.SYSTEM_ACCURACY_ERROR,
    error,
  });
}