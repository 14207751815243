import * as types from './action_types';
import { 
  getDsarRequestsAjax,
  getRequestFileAjax,
  completeRequestAjax,
} from '../api/ccpa/dsar';
import { actionCreator } from './helpers';

export const getDsarRequests = actionCreator(
  getDsarRequestsAjax,
  dsarRequestsPending,
  dsarRequestsLoaded,
  dsarRequestsError,
);

export const getRequestFile = actionCreator(
  getRequestFileAjax,
  dsarRequestsPending,
  dsarFileLoaded,
  dsarRequestsError,
);

export const completeRequest = actionCreator(
  completeRequestAjax,
  dsarRequestsPending,
  requestCompleted,
  dsarRequestsError,
);

function dsarRequestsPending() {
  return {
    type: types.DSAR_REQUESTS_PENDING,
  };
}

function dsarRequestsLoaded(requests) {
  return {
    type: types.DSAR_REQUESTS_LOADED,
    requests,
  };
}

function dsarRequestsError(errorMessage) {
  return {
    type: types.DSAR_REQUESTS_ERROR,
    errorMessage,
  };
}

function dsarFileLoaded(requests) {
  return {
    type: types.DSAR_FILE_LOADED,
    requests,
  };
}

function requestCompleted(request) {
  return {
    type: types.DSAR_COMPLETED,
    request,
  };
}

export function documentUploaded(requestId) {
  return {
    type: types.DSAR_FILE_UPLOADED,
    requestId,
  };
}