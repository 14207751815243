import React, {
  useState,
  useEffect,
  useContext
} from 'react';
import { Select } from "../../../../../../../styleguide";

import { SitesContext, VendorListsContext } from '../../../../../contexts';

const { Option } = Select;

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const VendorList = ({
  siteId,
  updateSetting,
  settingKey,
  value,
  messageCategory
}) => {
  const { sites, isSiteGroup } = useContext(SitesContext);
  const vLists = useContext(VendorListsContext);


  const [selectedSite, setSelectedSite] = useState(null);

  const siteList = isNaN(siteId) ? [] : [+siteId]; 
  const siteIds = isSiteGroup ? sites.map(({ id }) => id).toJS() : siteList;
  const vendorLists = vLists ? vLists.filter(vl => vl.siteIds.some(id => siteIds.includes(id))) : [] //filtering VLs based on site ID [DIA-2571]

  useEffect(() => {
    if (vendorLists.findIndex(({ id }) => value === id) === -1) {
      vendorLists.length
        ? updateSetting(settingKey, vendorLists[0].id)
        : updateSetting(settingKey, value);
    }
  }, [value, vLists]);

  useEffect(() => {
    (async () => {
      const selectedVendorList = vendorLists.find(({ id }) => id === value) || vendorLists[0];
      setSelectedSite(isSiteGroup ? siteIds.find(id => selectedVendorList.siteIds.includes(id)) : siteId);
      updateSetting(settingKey, selectedVendorList ? selectedVendorList.id : null);
    })();
  }, [siteId, vLists]);

  const sitesToVlsMap = vendorLists.reduce((sitesToVlsMap, vl) => {
    vl.siteIds.forEach(id => {
      if (siteIds.includes(id)) {
        const site = sitesToVlsMap.find(site => site.id === id);
        if (site) {
          if (site.vls.findIndex(({ id }) => id === vl.id) === -1) {
            site.vls.push(vl);
          };
        } else {
          sitesToVlsMap.push({ id, vls: [vl] })
        };
      };
    })
    return sitesToVlsMap;
  }, []);

  const selectSite = (siteId) => {
    const vl = sitesToVlsMap.find(({ id }) => id == siteId)?.vls[0]?.id;
    setSelectedSite(siteId);
    updateSetting(settingKey, vl);
  };
  
  return (
    <div className="four-options">
      {isSiteGroup && (
        <React.Fragment>
          <h4>Site</h4>
          <div className="option-inputs">
            <Select
              style={{ width: '100%' }}
              value={selectedSite}
              onChange={selectSite}
              disabled={!vendorLists.length}
            >
              {(sites.map(({ id }) => (
                <Option key={id} value={id}>
                  {sites.find(site => site.id === id).domain}
                </Option>
              )))}
            </Select>
          </div>
        </React.Fragment>
      )}
      <h4>{messageCategory === 'usnat' ? 'Regulation Preview' : 'Vendor List Preview'}</h4>
      <div className="option-inputs">
        <Select
          style={{ width: '100%' }}
          value={value}
          onChange={(vl) => updateSetting(settingKey, vl)}
          disabled={!sitesToVlsMap.length || !sitesToVlsMap.find(({ id }) => id == selectedSite)?.vls?.length}
        >
          {sitesToVlsMap.length && sitesToVlsMap.find(({ id }) => id == selectedSite)?.vls?.map((vl) => (
            <Option key={vl.id} value={vl.id}>
              {vl.name}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default VendorList;