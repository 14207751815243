import { createAccountScan, getAccountScans, getAccountWebsites, getConsentCategories, getProxyTools } from '../api/diagnose_dashboard/scan_setup';
import * as types from './action_types';
import { actionCreator } from './helpers';

const diagnoseScanSetupListPending = () => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_PENDING,
});

const diagnoseScanSetupListLoaded = (data) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_LOADED,
  data,
});

const diagnoseScanSetupListError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_ERROR,
  errorMessage,
});

export const getDiagnoseAccountScans = actionCreator(
  getAccountScans,
  diagnoseScanSetupListPending,
  diagnoseScanSetupListLoaded,
  diagnoseScanSetupListError,
);

const getDiagnoseScanWebsitesListPending = () => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_PENDING,
});

const getDiagnoseScanWebsitesListLoaded = (data) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_LOADED,
  data,
});

const getDiagnoseScanWebsitesListError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_ERROR,
  errorMessage,
});

export const getDiagnoseAccountWebsites = actionCreator(
  getAccountWebsites,
  getDiagnoseScanWebsitesListPending,
  getDiagnoseScanWebsitesListLoaded,
  getDiagnoseScanWebsitesListError,
);

const diagnoseScanSetupConsentCategoriesPending = () => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_PENDING,
});

const diagnoseScanSetupConsentCategoriesLoaded = (data) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_LOADED,
  data,
});

const diagnoseScanSetupConsentCategoriesError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_ERROR,
  errorMessage,
});

export const getDiagnoseConsentCategories = actionCreator(
  getConsentCategories,
  diagnoseScanSetupConsentCategoriesPending,
  diagnoseScanSetupConsentCategoriesLoaded,
  diagnoseScanSetupConsentCategoriesError,
);

const getDiagnoseScanProxyToolsPending = () => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_PENDING,
});

const getDiagnoseScanProxyToolsLoaded = (data) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_LOADED,
  data,
});

const getDiagnoseScanProxyToolsError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_ERROR,
  errorMessage,
});

export const getDiagnoseProxyTools = actionCreator(
  getProxyTools,
  getDiagnoseScanProxyToolsPending,
  getDiagnoseScanProxyToolsLoaded,
  getDiagnoseScanProxyToolsError,
);

