import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List } from 'immutable';
import { BarsOutlined } from '@ant-design/icons';
import { Table, message, Radio } from 'antd';
import { browserHistory } from 'react-router';
import classNames from 'classnames';
import { 
  getDomainSets,
} from '../../../actions/domain_set_actions';
import { getStandards } from '../../../actions/standard_actions';
import { getDomainSetScores, getSetScoresIncluded } from '../../../actions/domain_score_actions';
import { User } from '../../../records/account_records';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { SearchOutlined } from '@ant-design/icons';
import Loading from '../../common/Loading.js.jsx';
import { summaryResultsCSV } from './CSV/generateCSV';
import { getDomainsWithScores } from '../../../api/consent_quality/domain_set';
import { Input } from '../../../styleguide'

class DomainSetsPage extends React.Component { 
  static propTypes = {
    currentUser: PropTypes.instanceOf(User).isRequired,
    getSetScoresIncluded: PropTypes.func.isRequired,
  }

  state = {
    handleAddScannedVendorsOpen: false,
    search: '',
    filterVal: 'all'
  }

  componentDidMount = () => {

  }

  componentWillMount() {
    const accountId = this.props.currentUser.accountId;
    this.props.getSetScoresIncluded(accountId);
  }

  detailedDomainSet(id) {
    browserHistory.push(`/privacy_lens/scoring_results/detail?id=${id}`);
  }

  updateSearch = (e) => this.setState({ search: e.target.value });

  getSummaryData = () => {
    if (!this.props.setScoresIncluded) return null;
    return this.props.setScoresIncluded.toJS();
  }
  getExport = async (e, type, domainSet) => {
    e.stopPropagation();
    const scores = await getDomainsWithScores(domainSet.id);
    summaryResultsCSV(type, scores || [],domainSet.name);
  }

  render() {
    const { search, filterVal } = this.state;
    let summaryData = this.getSummaryData();
    
    if (summaryData && search) {
      summaryData = summaryData.filter(sd => {
        return sd.name.toLowerCase().includes(search.toLowerCase());
      });
    }

    if (summaryData.length && filterVal) {
      summaryData = summaryData.filter(f => {
        let propComplete = f.numProperties <= f.scores.totalScored;
        return filterVal === 'all' || (filterVal === 'completed' && propComplete) || (filterVal === 'in-progress' && !propComplete);
      });
    }

    return !summaryData.length ? <Loading /> : (
      <div className='domain-sets-container privacy-lens scoring'>

        <div>
          <div className='title-privacy-lens'>Scoring Results</div>
          <div className="scoring-search">
            <Input
              placeholder="Search Property Sets"
              onChange={this.updateSearch}
              value={this.state.search}
              suffix={<SearchOutlined/>}
            />
          </div>

          <div className="subtitle-privacy-lens">
            Property Sets <b>({summaryData.length || 0})</b>

            <div className="sfilter">
              <div className="sfilter-title">Quick Filters:</div>
              <Radio.Group value={this.state.filterVal} onChange={e => this.setState({ filterVal: e.target.value})}>
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="completed">Scoring Completed</Radio.Button>
                <Radio.Button value="in-progress">Scoring In-Progress</Radio.Button>
              </Radio.Group>
            </div>
          </div>

          { summaryData.map((sd, i) => {
            let propertiesPercent = Math.floor(sd.scores.totalScored/sd.numProperties*100);
            let propComplete = sd.numProperties <= sd.scores.totalScored;
            if (isNaN(propertiesPercent)) propertiesPercent = 0;
            return (
              <div className="summary-card" key={sd.id} onClick={() => this.detailedDomainSet(sd.id)}>
                <div className="summary-title">
                  {sd.name}
                  <div className={propComplete ? 'stag completed' : 'stag in-progress'}>
                    {propComplete ? 'Scoring Completed' : 'Scoring In-Progress'}
                  </div>
                </div>

                <div className="summary-list">

                  <div className="summary-score">
                    <div className="score-title">
                      <div className="full-title">Avg. Score Across All Properties</div>
                    </div>
                    <div className="average-title">{sd.scores.avgScore}</div>
                  </div>

                  <div className="summary-score">
                    <div className="score-title">
                      <div>Properties Scored</div>
                      <div>{propertiesPercent}%</div>
                    </div>
                    <div className="avg-score">Avg. Score: <b>{sd.scores.avgScore}</b></div>
                    <div className="progress blue"><div className="amount" style={{width: propertiesPercent+'%'}}></div></div>
                    <div className="score-title">
                      <div>{sd.scores.totalScored}<span>/ {sd.numProperties}</span></div>
                      <div>
                        <span className="download" onClick={(e) => this.getExport(e, 'all', sd)}>
                          <FontAwesomeIcon icon={faDownload} />
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            );
          })}
          
        </div>

      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    standards: store.standardState.getIn(['standards', 'value']),
    setScoresIncluded: store.domainScoreState.getIn(['setScoresIncluded', 'value']),
    domainSetScores: store.domainScoreState.getIn(['domainSetScores', 'value']),
    pendingRequestsMap: new Map({
      domainSets: store.domainSetState.getIn(['domainSets', 'pending']),
      standards: store.standardState.getIn(['standards', 'pending']),
      domainSetScores: store.domainScoreState.getIn(['domainSetScores', 'pending']),
      setScoresIncluded: store.domainScoreState.getIn(['setScoresIncluded', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getSetScoresIncluded,
    getDomainSets,
    getStandards,
    getDomainSetScores,
  },
)(DomainSetsPage);

