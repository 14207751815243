import React, { useState } from 'react';
import { Button, Divider, InputNumber, version } from 'antd';
import { Modal, Input } from '../../../styleguide'
import { PlusOutlined } from '@ant-design/icons';

import { Partitionset, Partition } from '../../../records/partitionset_records.js';
import CampaignTypeDropdown from '../../common/CampaignTypeDropdown';
import ScenarioSelector from '../scenarios/ScenarioSelector.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';

function CreatePartitionSet({ isModalVisible, handleCancel, handleOk, propertyType, currentUser, scenarios, isMultiCampaignEnabled }) {
  const PartitionSetRecord = () => {
    let partitionSet = new Partitionset();
    const partition = new Partition();
    return partitionSet.update('partitions', (ps) => ps.push(partition));
  };

  const [partitionSet, updatePartitionSet] = useState(PartitionSetRecord());
  const [errorMsg, updateErrorMsg] = useState('');
  const [partitionPer, updatePartitionPer] = useState(['']);

  const updatePartitionSetName = (name) => {
    const udpatedPartitionSet = partitionSet.set('description', name);
    updatePartitionSet(udpatedPartitionSet);
  };

  const filteredScenarios = scenarios.filter((s) => isMultiCampaignEnabled && partitionSet.campaign_type_id === 0 ? true : s.campaign_type_id === partitionSet.campaign_type_id);

  const updateCampaignType = (campaignType) => {
    const partitions = partitionSet.partitions.map((p) => {
      const scenario = filteredScenarios.find((s) => s.get('id') === p.scenario_id);
      const campaignTypeMismatch = scenario && scenario.campaign_type_id !== campaignType;
      return campaignTypeMismatch ? p.set('scenario_id', '') : p;
    });
    const udpatedPartitionSet = partitionSet.set('partitions', partitions).set('campaign_type_id', campaignType);
    updatePartitionSet(udpatedPartitionSet);
  };

  const handleAdd = () => {
    const partition = new Partition();
    const withNewPartition = partitionSet.update('partitions', (ps) => ps.push(partition));
    updatePartitionSet(withNewPartition);

    let partitionPerArray = partitionPer;
    partitionPerArray.push('');
    updatePartitionPer(partitionPerArray);
  };

  const updatePartition = (field, index, value) => {
    let partition = partitionSet;

    if (field === 'name') {
      partition = partition.setIn(['partitions', index, 'name'], value);
    } else if (field === 'partition_percentage') {
      var v = parseInt(value);
      if (isNaN(v)) {
        v = '';
      } else if (v < 0) {
        v = 0;
      } else if (v > 100) {
        v = 100;
      }
      const partitionPerArray = Array.from(partitionPer);
      partitionPerArray.splice(index, 1, v);

      partitionPerArray.forEach((per, i) => {
        let bucketStart = 0;
        if(i > 0) {
          const prevBucketEnd = partition.getIn(['partitions', i - 1, 'ruleset', 'bucket_rules', 0, 'bucket_end']);
          bucketStart = (prevBucketEnd ? (prevBucketEnd + 1) : 0) || 0;
        }
        let bucketEnd = per * 10 + bucketStart - 1;
        if (isNaN(bucketEnd)) {
          bucketEnd = '';
        } else if (bucketEnd < 0) {
          bucketEnd = 0;
        } else if (bucketEnd > 999) {
          bucketEnd = 999;
        }

        if(bucketStart === 0 && bucketEnd === 0) {
          bucketStart = '';
          bucketEnd = '';
        }

        partition = partition.setIn(['partitions', i, 'ruleset', 'bucket_rules', 0, 'bucket_start'], bucketStart).setIn(['partitions', i, 'ruleset', 'bucket_rules', 0, 'bucket_end'], bucketEnd);
      });

      updatePartitionPer(partitionPerArray);
   } else if (field === 'scenario') {
      partition = partition.setIn(['partitions', index, 'scenario_id'], parseInt(value));
    }
    updatePartitionSet(partition);
  };

  const handleSubmit = () => {
    if (!partitionSet.get('partitions').size) {
      updateErrorMsg('Please add at least one partition');
      return;
    }

    if (
      partitionSet.get('partitions').some((p) => {
        const bucketStart = p.getIn(['ruleset', 'bucket_rules', 0, 'bucket_start']);
        const bucketEnd = p.getIn(['ruleset', 'bucket_rules', 0, 'bucket_end']);
        const isNumBucketStart = typeof bucketStart === 'number';
        const isNumBucketEnd = typeof bucketEnd === 'number';
        return !(p.get('name') && isNumBucketStart && isNumBucketEnd && p.get('scenario_id') && p.get('scenario_id') !== -1);
      })
    ) {
      updateErrorMsg("Please fill in all the partition's details");
      return;
    }

    if (partitionPer.some((p) => !p)) {
      updateErrorMsg("Please fill in all the partition's percentages");
      return;
    }

    const checkPercentage = partitionPer.reduce((pp, cpv) => pp + cpv, 0);
    if (checkPercentage > 100) {
      updateErrorMsg('Please make sure addition of partition(s) % should be less than or equal to 100');
      return;
    }

    handleOk(partitionSet);
  };

  const deletePatitionRow = (index) => {
    const updatedPartitionSet = partitionSet.update('partitions', (ps) => ps.delete(index));
    updatePartitionSet(updatedPartitionSet);

    let partitionPerArray = partitionPer;
    partitionPerArray.splice(index, 1);
    updatePartitionPer(partitionPerArray);
  };

  const campaignTypeDropdown = <CampaignTypeDropdown hideLegacyPlaceholder currentUser={currentUser} propertyType={propertyType} updateCampaignType={updateCampaignType} />;

  const error = errorMsg && <div className="error-msg"> {errorMsg} </div>;

  const showPartitionSetDetailsBlock = partitionSet.campaign_type_id || !isMultiCampaignEnabled;

  const description = (
    <div className="description-wrapper">
      Partition Set Name &nbsp;<Input value={partitionSet.description} onChange={(e) => updatePartitionSetName(e.target.value)} />
    </div>
  );

  const notSinglePartition = partitionSet.partitions.size !== 1;
  const partitionsTable = !!partitionSet.partitions.size && (
    <div className="partitions-wrapper">
      <span className="add-partition-label">Add Partitions</span>
      <table className="table partitions-table" id="partition-table">
        <thead>
          <tr>
            <th className="long-col">Name</th>
            <th className="short-col">Partition %</th>
            <th className="long-col" colSpan={2}>Scenario</th>
          </tr>
        </thead>
        <tbody>
          {partitionSet.partitions.map((partition, i) => {
            var scenarioID;
            const scenario = filteredScenarios.find((s) => s.get('id') === partition.scenario_id);
            if (scenario === undefined) {
              scenarioID = -1;
            } else {
              scenarioID = partition.scenario_id;
            }
            
            const showAddBtn = partitionSet.partitions.size === (i + 1);
            const addPartitionButton = showAddBtn && <Button shape="circle" size="small" className="add-partition-btn"  onClick={handleAdd}>+</Button>;
    
            const noAction = partitionSet.partitions.size === 1;
            const deleteButton = (
              <CustomIcon 
                size={CustomIcon.sizes.SMALL}
                type={CustomIcon.types.DELETE}
                onClick={() => noAction ? null : deletePatitionRow(i)}/>
            );

            const hideDeleteClassName = notSinglePartition ? '' : 'no-action-delete';

            return (
              <tr key={i}>
                <td>
                  <Input type="text" className="partition-input" onChange={(e) => updatePartition('name', i, e.target.value)} value={partition.name} />
                </td>
                <td>
                  <Input type="number" min={0} max={100} maxLength={3} value={partitionPer[i]} onChange={(e) => updatePartition('partition_percentage', i, e.target.value)}/>
                </td>
                <td>
                  <ScenarioSelector value={scenarioID} onChange={(val) => updatePartition('scenario', i, val)} scenarios={filteredScenarios} />
                </td>
                <td className={hideDeleteClassName}>
                  {deleteButton}
                  {addPartitionButton}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  const content = showPartitionSetDetailsBlock && (
    <React.Fragment>
      {description}
      {partitionsTable}
    </React.Fragment>
  );

  const divider = !!partitionSet.campaign_type_id && <Divider />;
  const campaignsDropdown = isMultiCampaignEnabled && (
    <React.Fragment>
      <div id="select-campaign-type"><span className="campaign-type-label">Campaign Type</span> {campaignTypeDropdown}</div>
      {divider}
    </React.Fragment>
  );

  return (
    <Modal wrapClassName="create-new-partitionset" title="New Partition" okText="Save" okButtonProps={{ disabled: !partitionSet.description }} visible={isModalVisible} onOk={handleSubmit} onCancel={() => handleCancel()}>
      {campaignsDropdown}
      {content}
      {error}
    </Modal>
  );
}

export default CreatePartitionSet;
