
const createCSV = (data, fileName) => {
  // SETUP CSV FORMAT
  let csvContent = "data:text/csv;charset=utf-8,";
  data.forEach(function(rowArray) {
    csvContent += rowArray.join(",") + "\r\n";
  });
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


export const summaryResultsCSV = (type, data, setName) => {
  const columns = ['Property ID', 'Property Name', 'Property Score'];
  const csvData = data.filter(d => {
    if (!d.hasOwnProperty('isSafe')) {
      return true;
    } else if (type === 'safe' && d.isSafe) {
      return true;
    } else if (type === 'unsafe' && !d.isSafe) {
      return true;
    } else if (type === 'all') {
      return true;
    } else {
      return false;
    }
  }).map(d => {
    return [ d.domainId, d.url, d.overAllScore || 0 ];
  });

  const content = [columns, ...csvData];
  createCSV(content, `${setName}-${type}_summary_results_.csv`);
}

export const scoreThresholdCSV = (type, score, data, setName) => {
  const columns = ['Property ID', 'Property Name', 'Property Score'];

  const csvData = data.filter(d => type !== 'all' ? true : (type === 'all' && d.scored)).filter(d => {
    const actualScore = d.overAllScore || 0;
    if (!d.hasOwnProperty('isSafe')) {
      return actualScore >= score;
    } else if (type === 'safe' && d.isSafe) {
      return actualScore >= score;
    } else if (type === 'unsafe' && !d.isSafe) {
      return actualScore >= score;
    } else if (type === 'all') {
      return actualScore >= score;
    } else {
      return false;
    }
  }).map(d => {
    return [ d.domainId, d.url, d.overAllScore || 0 ];
  });

  const content = [columns, ...csvData];
  createCSV(content, `${setName}-${type}_threshold_results.csv`);
}

export const rulePerformanceCSV = (rules, setName) => {
  const columns = ["ID", "Name", "Status", "Is Must Have", "Is Nice To Have", "Percent Passed", "Number Passed"];
  const csvData = rules.map(d => {
    return [ 
      d.ruleId, 
      d.name, 
      d.productStatus,
      (d.mustHave ? 'true' : 'false'),
      (d.niceToHave ? 'true' : 'false'),
      d.percent, 
      d.total
    ];
  });

  const content = [columns, ...csvData];
  createCSV(content, `${setName}-rule_performance_results.csv`);
}

const scoreToString = (score) => {
  let icon = 'N/A';
  if (score === 1) {
    icon = 'Pass';
  } else if (score === 0) {
    icon = 'Fail';
  }
  return icon;
}

export const propertyTableCSV = (data, rules, setName) => { 
  const columns = ['ID', 'Property', 'Region', 'Rules Passed', 'Date Scanned', 'Score'];
  const statusesToIndicate = ['Beta+', 'Beta'];
  rules.forEach(r => {
    let name = `ID: ${r.rule.ruleId}  ${r.rule.name}`;
    if (statusesToIndicate.includes(r.rule.productStatus)) {
      name += ` (${r.rule.productStatus})`;
    }
    columns.push(name);
  });

  const csvData = data.map(d => {
    const row = [ 
      d.domainId, 
      d.url, 
      d.region, 
      d.rulesPassed, 
      d.dateScanned, 
      d.score
    ];
    rules.forEach(r => {
      const ruleId = r.rule.ruleId;
      const score = d.rules[ruleId];
      row.push(scoreToString(score));
    });

    return row;
  });

  const content = [columns, ...csvData];
  createCSV(content, `${setName}-detailed_results.csv`);
};
