import { Record, List, Map } from 'immutable';
import moment from 'moment';

export const ConsentPerformanceRecord = Record({
  tableData: List([]),
  aggregateData: Map({}),
  vendorData: List([]),
  PVtableData: List([]),
  PVGraphData: List([])
});

export const GraphFilters = Record({
  startDate: moment(),
  endDate: moment(),
  siteId: null,
  granularity: 'day',
  geo: '',
  datePicker: 'DAY'
});
