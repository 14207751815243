import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getAllSitesAjax,
  createSiteAjax,
  deleteSiteAjax,
  createSiteGroupAjax,
  getSiteGroupsAjax,
  getSiteGroupAjax,
  deleteSiteGroupAjax,
  updateSiteGroupAjax,
  updateSiteAjax,
  updatePropertyGroupAjax,
  updatePGAjax
} from '../api/dialogue/sites';
import {
  createSiteLog,
  updateSiteLog,
  deleteSiteLog,
  createSiteGroupLog,
  deleteSiteGroupLog,
  updateSiteGroupLog
} from '../api/change_log'
import { hasFeature } from '../components/utils';

export const getAllSites = (options) =>{
  return( dispatch, getState) => {
    dispatch(sitesPending());
    return getAllSitesAjax(options)
    .then((resp)=>{
      const { accountState }= getState();
      const currentUser = accountState.getIn(['userDetails', 'value']);
      let sites = hasFeature(currentUser,"privacy_manager_ott") ? resp : resp.filterNot( site => site.type === 'ott');
      if( resp === "fetched") {
        dispatch(sitesCached(options))
      } else {
        dispatch(sitesLoaded(sites,options))
        return sites;
      }
    })
    .catch((resp)=>{dispatch(sitesError(resp, options))})
  }
}

export const createProperty = (domain, type, accountId, siteGroup, multiCampaignEnabled) => {
  return (dispatch, getState) => {
    dispatch(sitesPending());
    return createSiteAjax(domain, type, multiCampaignEnabled)
      .then((data) => {
        createSiteLog(getState(), data);
        dispatch(siteAdded(data));
        if (siteGroup.siteId) {
          let updatedSiteGroup = siteGroup;
          updatedSiteGroup = updatedSiteGroup.merge({
            siteIds: siteGroup.siteIds.push(data.id),
          });
          dispatch(siteGroupsPending());
          updateSiteGroupAjax(accountId, updatedSiteGroup)
            .then((res) => {
              dispatch(siteGroupUpdated(res));
            })
            .catch((resp) => dispatch(siteGroupsError(resp)));
        }
      })
      .catch((reason) => dispatch(sitesError(reason)));
  };
};

export const updateProperty = (siteId,domain, type,siteGroup,existingPropertyGroup, accountId) => {
  return (dispatch, getState) => {
    dispatch(sitesPending());
    return updateSiteAjax(siteId, {domain,type})
      .then((data) => {
        updateSiteLog(getState(), data);
        dispatch(siteUpdated(data));
        if (siteGroup && !siteGroup.siteIds.includes(data.id)) {
          let updatedSiteGroup = siteGroup;
          updatedSiteGroup = updatedSiteGroup.merge({
            siteIds: siteGroup.siteIds.push(data.id),
          });
          dispatch(siteGroupsPending());
          updateSiteGroupAjax(accountId, updatedSiteGroup)
            .then((res) => {
              dispatch(siteGroupUpdated(res));
            })
            .catch((resp) => dispatch(siteGroupsError(resp)));
        }
        if(existingPropertyGroup && existingPropertyGroup?.name !== siteGroup?.name){
          let updatedSiteGroup = existingPropertyGroup;
          const index = existingPropertyGroup.siteIds.indexOf(data.id);
          updatedSiteGroup = updatedSiteGroup.merge({
            siteIds: (index>=0)?existingPropertyGroup.siteIds.splice(index, 1):existingPropertyGroup.siteIds
          });
          dispatch(siteGroupsPending());
          updateSiteGroupAjax(accountId, updatedSiteGroup)
            .then((res) => {
              dispatch(siteGroupUpdated(res));
            })
            .catch((resp) => dispatch(siteGroupsError(resp)));
        }
      })
      .catch((reason) => dispatch(sitesError(reason)));
  };
};

export const createSite = actionCreator(
  createSiteAjax,
  sitesPending,
  siteAdded,
  sitesError,
  null,
  createSiteLog,
);

export const deleteSite = actionCreator(
  deleteSiteAjax,
  sitesPending,
  siteDeleted,
  sitesError,
  null,
  deleteSiteLog
);

export const createSiteGroup = actionCreator(
  createSiteGroupAjax,
  siteGroupsPending,
  siteGroupCreated,
  siteGroupsError,
  null,
  createSiteGroupLog
);

export const getSiteGroups = actionCreator(
  getSiteGroupsAjax,
  siteGroupsPending,
  siteGroupsLoaded,
  siteGroupsError,
);

export const getSiteGroup = actionCreator(
  getSiteGroupAjax,
  siteGroupsPending,
  siteGroupLoaded,
  siteGroupsError,
);

export const deleteSiteGroup = actionCreator(
  deleteSiteGroupAjax,
  siteGroupsPending,
  siteGroupDeleted,
  siteGroupsError,
  null,
  deleteSiteGroupLog
);

export const updateSiteGroup = actionCreator(
  updateSiteGroupAjax,
  siteGroupsPending,
  siteGroupUpdated,
  siteGroupsError,
  null,
  updateSiteGroupLog
);

export const updateSite = actionCreator(
  updateSiteAjax,
  sitesPending,
  siteUpdated,
  sitesError,
  null,
//   updateSiteLog,
);

export const updatePropertyGroup = actionCreator(
  updatePropertyGroupAjax,
  sitesPending,
  siteUpdated,
  siteGroupsError,
);

export function setSiteGroupSitesWithErrors(siteIds) {
  return {
    type: types.SET_SITE_GROUP_SITES_WITH_ERRORS,
    siteIds,
  }
}

export function dismissError() {
  return sitesError(null);
}

function sitesPending() {
  return {
    type: types.SITES_PENDING,
  };
}

function sitesCached() {
  return {
    type: types.SITES_CACHED,
  };
}

function sitesLoaded(sites) {
  return {
    type: types.SITES_LOADED,
    sites
  };
}

function siteAdded(site) {
  return {
    type: types.SITE_ADDED,
    site
  };
}

function siteUpdated(site) {
  return {
    type: types.SITE_UPDATED,
    site
  };
}

function siteDeleted(resp, id) {
  return {
    type: types.SITE_DELETED,
    id,
  };
}

function sitesError(errorMessage) {
  return {
    type: types.SITES_ERROR,
    errorMessage
  };
}

export function setCurrentSite(id) {
  return {
    type: types.SET_CURRENT_SITE,
    id,
  };
}

function siteGroupCreated(siteGroup) {
  return {
    type: types.SITE_GROUP_CREATED,
    siteGroup,
  };
}

function siteGroupUpdated(siteGroup) {
  return {
    type: types.SITE_GROUP_UPDATED,
    siteGroup,
  };
}

function siteGroupsPending() {
  return {
    type: types.SITE_GROUPS_PENDING,
  };
}

function siteGroupsCached() {
  return {
    type: types.SITE_GROUPS_CACHED,
  };
}

function siteGroupsLoaded(siteGroups) {
  return {
    type: types.SITE_GROUPS_LOADED,
    siteGroups
  };
}

function siteGroupLoaded(siteGroup) {
  return {
    type: types.SITE_GROUP_LOADED,
    siteGroup
  };
}

function siteGroupDeleted(id) {
  return {
    type: types.SITE_GROUP_DELETED,
    id,
  };
}

function siteGroupsError(errorMessage) {
  return {
    type: types.SITE_GROUPS_ERROR,
    errorMessage,
  };
}