import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';

import ScenarioSelector from '../scenarios/ScenarioSelector.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import { Partition } from '../../../records/partitionset_records.js';
import { Scenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2';

export default class PartitionRow extends React.Component {
    static propTypes = {
        partition: PropTypes.instanceOf(Partition).isRequired,
        scenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
        editing: PropTypes.bool.isRequired,
        updatePartitionRow: PropTypes.func.isRequired,
    };

    componentDidMount() {
        if (this.props.last && this.props.editing) {
            ReactDOM.findDOMNode(this.refs.partitionName).focus();
        }
    }

    handleChange = (target, event) => {
        var partition = this.props.partition;

        if (target === 'name') {
            partition = partition.set('name', event.target.value);
        } else if (target === 'scenario') {
            partition = partition.set('scenario_id', parseInt(event));
        }

        this.props.updatePartitionRow(partition);
    };

    handlePartitionPercentage = (event) => {
      const partitionPercentage = parseInt(event.target.value);
      this.props.updatePartitionPercentage(partitionPercentage);
    }

    render() {
        var partition = this.props.partition;

        var scenarioID;

        if (this.props.scenarios.find(s => s.get('id') === partition.scenario_id) === undefined) {
            scenarioID = -1;
        } else {
            scenarioID = partition.scenario_id;
        }

        if (!this.props.editing) {
            return (
                <tr>
                  <td>{ partition.name }</td>
                  <td>{ this.props.partitionPercentage }</td>
                  <td>{ scenarioID === -1 ? "[Unset]" : this.props.scenarios.find(s => s.get('id') === scenarioID).get('description') }</td>
                </tr>
            );
        } else {
            return (
                <tr>
                  <td><input ref="partitionName" type="text" onChange={ this.handleChange.bind(null, 'name') } value={ partition.name } /></td>
                  <td><input type="text" pattern="[0-9]{1,3}" onChange={ this.handlePartitionPercentage } value={ this.props.partitionPercentage } /></td>
                  <td><ScenarioSelector value={ scenarioID } onChange={ this.handleChange.bind(null, 'scenario') } scenarios={ this.props.scenarios } /></td>
                  <td><CustomIcon size={ CustomIcon.sizes.SMALL } type={ CustomIcon.types.DELETE } onClick={ this.props.deletePatitionRow }></CustomIcon></td>
                </tr>
            );
        }
    }
}
