import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CSVLink from "../../../common/CSVLink.js";
import { useActions } from '../../../../hooks';
import { Map, List } from 'immutable';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MinusOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Table, Input, Select, Checkbox, Button, message, Upload } from 'antd';
import { getRules as getRulesAction } from '../../../../actions/standard_actions';
import ChangeScoreModal from '../../scoring_results/ChangeScoreModal';
import { updateManualScores, getDomainsWithManualScores } from '../../../../api/consent_quality/domain_set';
import CreateButton from '../../../common/CreateButton.js.jsx';
import { regionsMap } from '../../../../constants';

const Search = Input.Search;


const regionsMapInverse = {
  'US New York': 'us_ny',
  'US California': 'us_ca',
  'US Colorado': 'us_co',
  'UK': 'gb',
  'France': 'fr',
  'Germany': 'de',
  'Italy': 'it',
  'Spain': 'es',
  'Denmark': 'dk',
};

export default function propertySetDetail(props) {

  if (!props.currentUser || !props.propertySet) {
    return null;
  }

  const getRules = useActions(getRulesAction);
  const [properties, setProperties] = useState([]);
  const [rules, setRules] = useState(props.propertySet.rules || props.propertySet.standard.rules.map(r => r.rule));

  useEffect(() => {
    getRules();
    if (props.accuracy) {
      setProperties(props.propertySet.properties);
    } else {
      (async () => {
        const propertiesFetched = await getDomainsWithManualScores(props.propertySet._id);
        setProperties(propertiesFetched);
      })();
    }
  }, []);

  const [addRulesAndPropertiesOpen, setAddRulesAndPropertiesOpen] = useState(false);

  const [editingPropertyUrl, setEditingPropertyUrl] = useState(null);
  const [editingPropertyRegion, setEditingPropertyRegion] = useState(null);
  const [editedScoresForSingleProperty, setEditedScoresForSingleProperty] = useState({});
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [batchEditModalOpen, setBatchEditModalOpen] = useState(false);
  const [allEditedPropertiesUrls, setAllEditedPropertiesUrls] = useState(new Set());

  const [search, setSearch] = useState('');

  const allRules = useSelector(state => state.standardState.getIn(['rules', 'value']));

  // const changeGoldenScores = (urls, scores) => {
  //   const updatedProperties = properties.map(property => {
  //     if (urls.includes(property.url)) {
  //       const currentUser = props.currentUser;
  //       const date = new Date();
  //       const manualRegionBasedScore = property.manualRegionBasedScore
  //         .set('scores', property.manualRegionBasedScore.scores.merge(scores))
  //         .set('userId', currentUser.id)
  //         .set('userName', currentUser.name)
  //         .set('dateScored', date);
  //       return property.set('manualRegionBasedScore', manualRegionBasedScore);
  //     } else {
  //       return property;
  //     }
  //   });
  //   setpropertySet(propertySet.set('properties', updatedProperties));
  // };

  const saveScoresForSingleProperty = () => {
    for (const key in editedScoresForSingleProperty) {
      if (editedScoresForSingleProperty[key] === 'notSet') {
        editedScoresForSingleProperty[key] = null;
      }
    }
    const updatedProperties = properties.map(p => {
      if (p.url === editingPropertyUrl) {
        const manualScores = p.manualRegionBasedScores || {};
        const initialRegionScores = manualScores[editingPropertyRegion] || {};
        const regionScores = Object.assign(initialRegionScores, editedScoresForSingleProperty);
        regionScores.lastScored = new Date();
        manualScores[editingPropertyRegion] = regionScores;
        p.manualRegionBasedScores = manualScores;
        setAllEditedPropertiesUrls(allEditedPropertiesUrls.add(p.url));
      }
      return p;
    });
    setProperties(updatedProperties);
    setEditingPropertyUrl(null);
    setEditingPropertyRegion(null);
    setEditedScoresForSingleProperty({});
  };

  const saveScoresForMultProperties = (scores) => {
    const updatedProperties = properties.map(p => {
      if (selectedProperties.map(sp => sp.id).includes(p._id)) {
        const manualScores = p.manualRegionBasedScores || {};
        const regions = selectedProperties.filter(sp => sp.id === p._id).map(sp => sp.region);
        regions.forEach(region => {
          const initialRegionScores = manualScores[region] || {};
          const regionScores = Object.assign(initialRegionScores, scores);
          regionScores.lastScored = new Date();
          manualScores[region] = regionScores;
        });
        p.manualRegionBasedScores = manualScores;
        selectedProperties.forEach(sp => allEditedPropertiesUrls.add(sp.url));
        setAllEditedPropertiesUrls(allEditedPropertiesUrls);
      }
      return p;
    });
    setProperties(updatedProperties);
    // unselect properties ?
  };

  const saveScoresFromCsv = (arrays) => {
    const ruleIds = arrays.shift().slice(2).map(rule => rule.split(':')[0].toLowerCase());
    const scoresMapAll = {};
    arrays.forEach(scoreArray => {
      const url = scoreArray.shift();
      const scoresMapForDomain = {};
      const scores = scoreArray.slice(1);
      ruleIds.forEach((ruleId, i) => {
        if (scores[i]) {
          scoresMapForDomain[ruleId] = parseInt(scores[i]);
        }
      });
      if (!scoresMapAll[url]) scoresMapAll[url] = {};
      if (Object.keys(scoresMapForDomain).length) {
        scoresMapAll[url][scoreArray[0]] = scoresMapForDomain;
      }
    });

    const updatedProperties = properties.map(p => {
      let edited;
      const scores = scoresMapAll[p.url];
      if (scores) {
        const regions = Object.keys(scores).map(r => r.toLowerCase());
        if (!p.manualRegionBasedScores) {
          if (Object.keys(scores).length) {
            edited = true;
            p.manualRegionBasedScores = scores;
          }
        } else {
          const manualScores = p.manualRegionBasedScores;
          regions.forEach(region => {
            const initialRegionScores = manualScores[region] || {};
            const regionScores = Object.assign(initialRegionScores, scores[region]);
            regionScores.lastScored = new Date();
            manualScores[region] = regionScores;
          });
          if (Object.keys(manualScores).length) {
            edited = true;
            p.manualRegionBasedScores = manualScores;
          }
        }
        if (edited) {
          setAllEditedPropertiesUrls(allEditedPropertiesUrls.add(p.url));
        }
      }
      return p;
    });
    setProperties(updatedProperties);
  };

  const toggleSelectedProperty = (checked, key, id, region) => {
    if (checked) {
      selectedProperties.push({ key, id, region });
    } else {
      selectedProperties.filter(property => property.key !== key);
    }
    setSelectedProperties([...selectedProperties]);
  };

  const scoreRule = (score, ruleId) => {
    const scoreToSave = score === 'notSet' ? 'notSet' : parseInt(score);
    editedScoresForSingleProperty[ruleId] = scoreToSave;
    setEditedScoresForSingleProperty({...editedScoresForSingleProperty});
  };

  const savepropertySet = () => {
  };

  const getSortedRules = () => {
    return rules.sort((r1, r2) => {
      const productStatusPoints = {
        GA: 0,
        Beta: 1,
        Alpha: 2,
      };
      const productStatus1 = productStatusPoints[r1.productStatus];
      const productStatus2 = productStatusPoints[r2.productStatus];
      return productStatus1 - productStatus2;
    });
  }

  const generateTable = () => {

    const sortedRules = getSortedRules();
    const regions = props.accuracy ? [props.propertySet.region] : props.propertySet.standard.regions;

    let columns = [
      {
        title: 'Properties',
        dataIndex: 'properties',
        key: 'properties',
        width: '300px',
        fixed: 'left',
      },
      {
        title: 'CMP ID',
        dataIndex: 'cmpId',
        key: 'cmpId',
        width: '100px',
        fixed: 'left',
      },
      {
        title: 'Rules Scored',
        dataIndex: 'rulesScored',
        key: 'rulesScored',
        width: '100px',
        fixed: 'left',
        sorter: (a, b) => {
          const num1 = parseInt(a.rulesScored.split('/')[0]);
          const num2 = parseInt(b.rulesScored.split('/')[0]);
          return num1 - num2;
        },
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        width: '100px',
        fixed: 'left',
        filters: regions.map(r => {
          return (
            {
              text: regionsMap[r],
              value: regionsMap[r],
            }
          );
        }),
        onFilter: (value, record) => record.region.indexOf(value) === 0,
      },
    ];

    const gaRulesAmt = rules.filter(r => r.productStatus === 'GA').length;
    const betaRulesAmt = rules.filter(r => r.productStatus === 'Beta').length;
    const alphaRulesAmt = rules.filter(r => r.productStatus === 'Alpha').length;

    let gaRules;
    let betaRules;
    let alphaRules;
    if (gaRulesAmt) {
      gaRules = sortedRules.slice(0, gaRulesAmt);
      gaRules = {
        title: 'GA Rules',
        children: gaRules.map(r => {
          return {
            title: r.name,
            dataIndex: r.ruleId,
            key: r.ruleId,
            width: '200px',
            filters: [
              {
                text: 'Pass',
                value: 'CheckOutlined',
              },
              {
                text: 'Fail',
                value: 'CloseOutlined',
              },
              {
                text: 'Undetermined',
                value: 'MinusOutlined',
              },
              {
                text: 'Not Aplicable',
                value: 'N/A',
              },
            ],
            onFilter: (value, record) => {
              let val = record[r.ruleId];
              if (val.type) {
                val = val.type.render.displayName;
              }
              return val === value;
            },
          };
        }),
      };
      columns.push(gaRules);
    }
    if (betaRulesAmt) {
      betaRules = sortedRules.slice(gaRulesAmt, gaRulesAmt + betaRulesAmt);
      betaRules = {
        title: 'Beta Rules',
        children: betaRules.map(r => {
          return {
            title: r.name,
            dataIndex: r.ruleId,
            key: r.ruleId,
            width: '200px',
          };
        }),
      };
      columns.push(betaRules);
    }
    if (alphaRulesAmt) {
      alphaRules = sortedRules.slice(gaRulesAmt + betaRulesAmt, gaRulesAmt + betaRulesAmt + alphaRulesAmt);
      alphaRules = {
        title: 'Alpha Rules',
        children: alphaRules.map(r => {
          return {
            title: r.name,
            dataIndex: r.ruleId,
            key: r.ruleId,
            width: '200px',
          };
        }),
      };
      columns.push(alphaRules);
    }

    columns = columns.concat([
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        width: '70px',
      },
    ]);

    // {
    //   title: 'Last Scanned',
    //   dataIndex: 'lastScanned',
    //   key: 'lastScanned',
    //   fixed: 'right',
    //   width: '200px',
    // },
    // {
    //   title: 'Last Updated',
    //   dataIndex: 'lastUpdated',
    //   key: 'lastUpdated',
    //   fixed: 'right',
    //   width: '200px',
    // },

    let filteredProperties = properties;
    if (search) {
      filteredProperties = properties.filter(p => p.url.toLowerCase().includes(search.toLowerCase()));
    }

    const dataSource = [];
    filteredProperties.forEach((p) => {
      const regions = props.accuracy ? [props.propertySet.region] : props.propertySet.standard.regions;

      regions.forEach((region) => {
  
        let detailsIcon = (
          <EditOutlined onClick={ () => { setEditingPropertyUrl(p.url); setEditingPropertyRegion(region); }} />
        );
    
        if (editingPropertyUrl && editingPropertyUrl === p.url && editingPropertyRegion === region) {
          detailsIcon = (
            <div>
              <CloseOutlined
                onClick={ () => { setEditingPropertyUrl(null); setEditingPropertyRegion(null); setEditedScoresForSingleProperty({}); }} />
              <SaveOutlined onClick={ saveScoresForSingleProperty } />
            </div>
          );
        }

        let rulesScored = 0;
        if (p.manualRegionBasedScores && p.manualRegionBasedScores[region]) {
          rules.forEach(r => {
            const ruleId = r.ruleId;
            if (p.manualRegionBasedScores[region][ruleId] !== undefined) rulesScored += 1;
          });
        }

        const dateScored = p.manualRegionBasedScores && p.manualRegionBasedScores.dateScored ? moment(p.manualRegionBasedScores.dateScored).format("LLL") : 'no score date';
        const key = p._id + region;
        const row = {
          key,
          properties: <Checkbox onChange={ (e) => toggleSelectedProperty(e.target.checked, key, p._id, region) }>{ p.url }</Checkbox>,
          cmpId: p.cmpId || <p className='no-id'>No ID</p>,
          rulesScored: `${rulesScored}/${rules.length}`,
          region: regionsMap[region],
          lastUpdated: (
            <div>
              <div>{ dateScored }</div>
              <div>{ `by ${p.manualRegionBasedScores && p.manualRegionBasedScores.userName}` }</div>
            </div>
          ),
          actions: (<div className='actions'>
            { detailsIcon }
          </div>),
        };
        
        let icon;

        sortedRules.forEach(r => {  
          const rule = r;
          const propertyScore = !p.manualRegionBasedScores || !p.manualRegionBasedScores[region] || (p.manualRegionBasedScores[region][r.ruleId] == null) ? 'notSet' : p.manualRegionBasedScores[region][r.ruleId];

          if (propertyScore === 1) {
            icon = <CheckOutlined style={{ color: 'green' }} />;
          } else if (propertyScore === 0) {
            icon = <CloseOutlined style={{ color: 'red' }} />;
          } else if (propertyScore === -1) {
            icon = 'N/A';
          } else {
            icon = <MinusOutlined style={{ color: 'gray' }} />;
          }
          if (editingPropertyUrl && editingPropertyUrl === p.url && editingPropertyRegion === region) {
            let value = editedScoresForSingleProperty[rule.ruleId];
            value = typeof value === 'undefined' ? propertyScore : value;
            icon = (
              <Select
                onChange={ (score) => scoreRule(score, rule.ruleId) }
                defaultValue='Select score'
                value={ value === 'notSet' ? value : value.toString() }
                className='select-score'
                dropdownClassName='select-score-dropdown'
              >
                <Select.Option key={ '1' } value={ '1' }><CheckOutlined style={{ color: 'green' }} /></Select.Option>
                <Select.Option key={ '0' } value={ '0' }><CloseOutlined style={{ color: 'red' }} /></Select.Option>
                <Select.Option key={ '-1' } value={ '-1' }>N/A</Select.Option>
                <Select.Option key={ 'notSet' } value={ 'notSet' }><MinusOutlined style={{ color: 'gray' }} /></Select.Option>
              </Select>
            );
          }
    
          row[rule.ruleId] = icon;
        });
      
        dataSource.push(row);
      });
    });
  
    return new Map({ dataSource, columns });
  };

  if (!properties.length) return null;
  
  const tableData = generateTable();

  const closeModal= () => {
    setAddRulesAndPropertiesOpen(false);
  }

  const saveButton = (
    <CreateButton 
      className='save-golden-set' 
      onClick={ savepropertySet } 
      disabled={ !props.propertySet.name || !props.propertySet.policy }
    >
      Save Golden Set
    </CreateButton>
  );

  let changeScoreModal;
  if (batchEditModalOpen) {
    const domainScores = properties.filter(p => selectedProperties.map(sp => sp.id).includes(p._id));
    changeScoreModal = (
      <ChangeScoreModal
        closeModal={() => setBatchEditModalOpen(false)}
        domainScores={ domainScores }
        rules={ rules }
        changeScores={ scores => saveScoresForMultProperties(scores.toJS()) }
        propertySet
      />
    );
  }

  const generateDataForCsvReport = () => {

    const tableData = generateTable();

    const dataSource = tableData.get('dataSource');

    const sortedRules = getSortedRules();
    const ruleIds = sortedRules.map(r => r.ruleId);

    let columns = [
      "Properties",
      "CMP ID",
      "Rules Scored",
      "Region",
    ];

    columns = columns.concat(sortedRules.map(r => `${r.name} (${r.productStatus})`));

    const propertiesData = dataSource.map(d => {
      const rulesData = ruleIds.map(ruleId => {
        let value = d[ruleId];
        if (value.type) {
          value = value.type.render.displayName;
        }
        if (value === 'CheckOutlined') {
          value = 'passed';
        } else if (value === 'CloseOutlined') {
          value = 'X';
        } else if (value === 'MinusOutlined') {
          value = '-';
        } else {
          value = 'N/A';
        }
        return value;
      });

      const data = [ 
        d.properties.props.children, 
        d.cmpId.props ? d.cmpId.props.children : d.cmpId, 
        d.rulesScored,
        regionsMap[d.region],
      ];

      return data.concat(rulesData);
    });

    return [columns, ...propertiesData];
  };

  const downloadReport = (
      <CSVLink
        data={ generateDataForCsvReport(tableData) }
        filename='manual_scoring_results.csv'
        className='export-csv'
        target=""
      >
        <span className='download'>
          <FontAwesomeIcon icon={faDownload} />
        </span>
      </CSVLink>
  );

  const saveManualScores = () => {
    const updatedProperties = properties.filter(p => allEditedPropertiesUrls.has(p.url)).map(p => {
      return (
        {
          domainId: p._id,
          manualRegionBasedScores: p.manualRegionBasedScores,
        }
      );
    });
    updateManualScores({ domains: updatedProperties }).then(resp => {
      if (resp[0] && resp[0].domainId) {
        message.success('Updated manual scores were successfully saved');
      }
    });
  };

  const beforeUpload = async (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = e => {
      var csv = e.target.result;

      let dataArrays = csv.split(/\r\n|\n/);
      if (!dataArrays.slice(-1)[0]) {
        dataArrays.pop();
      }
      dataArrays = dataArrays.map(line => line.split(','));

      saveScoresFromCsv(dataArrays);
    };
    // Prevent upload
    return false;
  };

  const uploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload: beforeUpload,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const generateExampleData = () => {

    const tableData = generateTable();

    let dataForCSV = new List([]);
    const dataSource = tableData.get('dataSource');

    const sortedRules = getSortedRules();
    const ruleIds = sortedRules.map(r => r.ruleId);

    let columns = [
      "Properties",
      "Region",
    ];

    columns = columns.concat(sortedRules.map(r => `${r.ruleId}: ${r.name}`));

    const propertiesData = dataSource.map(d => {
      const rulesData = ruleIds.map(ruleId => {
        let value = d[ruleId];
        if (value.type) {
          value = value.type.render.displayName;
        }
        if (value === 'CheckOutlined') {
          value = 1;
        } else if (value === 'CloseOutlined') {
          value = 0;
        } else if (value === 'MinusOutlined') {
          value = null;
        } else {
          value = -1;
        }
        return value;
      });

      const data = [ 
        d.properties.props.children, 
        regionsMapInverse[d.region],
      ];

      return data.concat(rulesData);
    });

    return [columns, ...propertiesData];
  };

  return (
    <div className='domain-sets-container privacy-lens golden-set golden-set-detail manual-scoring'>

      { saveButton }
      
      { changeScoreModal }

      <div className='flex-row go-back' onClick={ props.goBackToList }>
        <ArrowLeftOutlined />
        <p>{ props.accuracy ? 'Back to Accuracy Sets' : 'Back to Property Sets'}</p>
      </div>

      <div className='top-lane flex-row'>
        <p className="title-privacy-lens">{ props.propertySet.name }</p>
        <Button
          onClick={ () => saveManualScores(true) } 
        >
          SAVE
        </Button>
      </div>

      <div className='table-box-shadow'>
        <div className='search-container'>
          <Search
            placeholder="Search Properties"
            onChange={ (e) => { setSearch(e.target.value) } }
            className="vendor-filter"
            style={{ width: 190 }}
          />
          <div className='flex-row'>
            <div className='upload-template-container'>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Upload scores from CSV</Button>
              </Upload>
              <div className='template'>
                Download a <CSVLink
                  data={generateExampleData()}
                  filename={`${props.propertySet.name} template.csv`}
                  onClick={(e) => e.stopPropagation()}
                >
                  CSV Template
                </CSVLink> with Properties for this Set
              </div>
            </div>
            <Button
              disabled={ selectedProperties.length < 1 }
              onClick={ () => setBatchEditModalOpen(true) } 
            >
              Batch Edit
            </Button>
            { downloadReport }
          </div>

        </div>

        <Table
          className='golden-set-detail-table'
          dataSource={ tableData.get('dataSource') }
          columns={ tableData.get('columns') }
          bordered
        />
      </div>
    </div>
  );
}
