import 'whatwg-fetch';
import { fromJS, List, Map } from 'immutable';
import {
  checkStatus,
  buildURL,
  removeParam,
  CHANGE_LOG_URL_V2,
  CONSENT_PERFORMANCE_REPORT_BASEURL,
  divideByZeroErrChk,
} from '../helper.js';
import {
  VendorsConsentReportRecord,
  PropertiesReport,
  MessageTableReportRecord,
  ChangeLogRecord,
} from '../../records/consent_performance_v2_records.js';
import {
  isIncompleteDateRange,
  eliminatePMObj,
} from '../gdpr_v2/gdpr_performance_report_v2';
import { DATEPICKERS } from '../../constants';
import moment from 'moment';

export async function getAndParseConsentReportChangeLogData(
  sites,
  accountID,
  rangeSelect
) {
  let formattedData = Map({
    start: moment(
      rangeSelect.get('startDate').format('YYYY-MM-DD'),
      'YYYY-MM-DD'
    ).valueOf(),
    end: moment(
      rangeSelect.get('endDate').format('YYYY-MM-DD'),
      'YYYY-MM-DD'
    ).valueOf(),
    site_ids: sites,
  })
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();

  let fetchUrl = buildURL(CHANGE_LOG_URL_V2, formattedData);

  return fetch(fetchUrl, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) =>
      List(aggregateChangeLogData(fromJS(resp))).map((s) =>
        changeLogToRecord(s)
      )
    );
}

export async function getAndParseConsentReportMessagedData(data, rangeSelect) {
  const messagesPromises = data.map((d, i) => {
    const siteIds = d.get('siteId').toString();
    const formattedData = d
      .set('startDate', rangeSelect.get('startDate').format('YYYY-MM-DD'))
      .set('endDate', rangeSelect.get('endDate').format('YYYY-MM-DD'))
      .toMap()
      .filter((v) => (List.isList(v) ? v.size : v))
      .toJS();
    let fetchUrl =
      buildURL(
        CONSENT_PERFORMANCE_REPORT_BASEURL + '/dashboard-messages/daily',
        formattedData
      );
    return fetch(fetchUrl)
      .then(checkStatus)
      .then((resp) => resp.json())
      .then((resp) =>
        aggregateMessageData(
          fromJS(resp),
          rangeSelect,
          formattedData,
          i,
          siteIds
        )
      );
  });

  return Promise.all(messagesPromises).then((res) => List(res));
}

export async function getAndParseConsentReportVendorData(
  vendorData,
  rangeData
) {
  const formattedData = vendorData
    .set('startDate', rangeData.get('startDate').format('YYYY-MM-DD'))
    .set('endDate', rangeData.get('endDate').format('YYYY-MM-DD'))
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();
  let fetchUrl = buildURL(
    `${CONSENT_PERFORMANCE_REPORT_BASEURL}/consent/vendors/${vendorData.get(
      'siteId'
    )}`,
    formattedData
  );
  fetchUrl = removeParam('siteId', fetchUrl);
  return fetch(fetchUrl)
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) =>
      List(aggregateVendorData(fromJS(resp))).map((s, i) =>
        VendorReportToRecord(s)
      )
    );
}

export async function getAndParseConsentReportPurposeData(
  vendorData,
  rangeData
) {
  const formattedData = vendorData
    .set('startDate', rangeData.get('startDate').format('YYYY-MM-DD'))
    .set('endDate', rangeData.get('endDate').format('YYYY-MM-DD'))
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();
  let fetchUrl = buildURL(
    `${CONSENT_PERFORMANCE_REPORT_BASEURL}/consent/purposes/${vendorData.get(
      'siteId'
    )}`,
    formattedData
  );
  fetchUrl = removeParam('siteId', fetchUrl);
  return fetch(fetchUrl)
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) =>
      List(aggregatePurposesData(fromJS(resp))).map((s, i) =>
        VendorReportToRecord(s)
      )
    );
}

export async function getAndParseConsentReportPropertyData(data, rangeSelect) {
  const propertyPromises = data.map((d, i) => {
    const formattedData = d
      .set('startDate', rangeSelect.get('startDate').format('YYYY-MM-DD'))
      .set('endDate', rangeSelect.get('endDate').format('YYYY-MM-DD'))
      .toMap()
      .filter((v) => (List.isList(v) ? v.size : v))
      .toJS();
    let fetchUrl =
      buildURL(
        `${CONSENT_PERFORMANCE_REPORT_BASEURL}/dashboard-properties/daily`,
        formattedData
      );
    return fetch(fetchUrl)
      .then(checkStatus)
      .then((resp) => resp.json())
      .then((resp) => aggregatePropertyData(fromJS(resp), i, rangeSelect));
  });

  return Promise.all(propertyPromises).then((res) => List(res));
}

function aggregatePropertyData(data, index, rangeSelect) {
  let groupedByYmdOrderedMap;
  let exportData = List();
  let propertyTableData = List();
  let aggPropertyGraphData = List();
  let aggTotalVolume = 0;
  let aggMessagedUsers = 0;
  let aggFullConsent = 0;
  let aggPartialConsent = 0;
  let aggRejectAll = 0;
  let aggPropertyTableData;
  let graphKey = 0;
  const datePicker = rangeSelect.get('datePicker');

  const quartData = data
    .get('results')
    .map((dataPoint) =>
      dataPoint.set(
        'week',
        moment(dataPoint.get('ymd'), 'YYYY-MM-DD').isoWeek()
      )
    )
    .groupBy((dataPoint) => dataPoint.get('week'));

  groupedByYmdOrderedMap = data
    .get('results')
    .groupBy((dataPoint) => dataPoint.get('ymd'));

  const groupByOrderedMap =
    datePicker === DATEPICKERS.quarter ? quartData : groupedByYmdOrderedMap;

  groupByOrderedMap.map((p, i) => {
    const date = datePicker === DATEPICKERS.quarter ? p.get(0).get('ymd') : i;
    let expData = p.map((e) => {
      return Map({
        date:
          datePicker === DATEPICKERS.quarter
            ? 'Week ' + moment(p.get(0).get('ymd'), 'YYYY-MM-DD').isoWeek()
            : i,
        siteid: Number(e.get('site_id')),
        totalpageviews: Number(e.get('total_volume')),
        messagedusers: Number(e.get('total_messaged_users')),
        fullconsent: Number(e.get('property_full_consent')),
        fullconsentper:
          Number(
            (
              (Number(e.get('property_full_consent')) /
                Number(e.get('total_volume'))) *
              100
            ).toFixed(2)
          ) + '%',
        partialconsent: Number(e.get('property_partial_consent')),
        partialconsentper:
          Number(
            (
              (Number(e.get('property_partial_consent')) /
                Number(e.get('total_volume'))) *
              100
            ).toFixed(2)
          ) + '%',
        rejectedall: Number(e.get('property_rejected_all')),
        rejectedallper:
          Number(
            (
              (Number(e.get('property_rejected_all')) /
                Number(e.get('total_volume'))) *
              100
            ).toFixed(2)
          ) + '%',
      });
    });
    exportData = exportData.concat(expData);

    let stv = p
      .map((entry) => Number(entry.get('total_volume')))
      .reduce((prev, current) => prev + current);
    let stmu = p
      .map((entry) => Number(entry.get('total_messaged_users')))
      .reduce((prev, current) => prev + current);
    let spfc = p
      .map((entry) => Number(entry.get('property_full_consent')))
      .reduce((prev, current) => prev + current);
    let sppc = p
      .map((entry) => Number(entry.get('property_partial_consent')))
      .reduce((prev, current) => prev + current);
    let spra = p
      .map((entry) => Number(entry.get('property_rejected_all')))
      .reduce((prev, current) => prev + current);

    propertyTableData = propertyTableData.push(
      Map({
        date: date,
        fancyDate:
          datePicker === DATEPICKERS.quarter
            ? 'Week ' + moment(date, 'YYYY-MM-DD').isoWeek()
            : date,
        totalpageviews: stv,
        messagedusers: stmu,
        fullconsent: spfc,
        fullconsentper: Number(((spfc / stv) * 100).toFixed(2)) + '%',
        partialconsent: sppc,
        partialconsentper: Number(((sppc / stv) * 100).toFixed(2)) + '%',
        rejectedall: spra,
        rejectedallper: Number(((spra / stv) * 100).toFixed(2)) + '%',
      })
    );

    aggTotalVolume += stv;
    aggMessagedUsers += stmu;
    aggFullConsent += spfc;
    aggPartialConsent += sppc;
    aggRejectAll += spra;

    graphKey++;
    aggPropertyGraphData = aggPropertyGraphData.push(
      Map({
        key: graphKey,
        fancyDate:
          datePicker == DATEPICKERS.quarter
            ? 'Week ' + i
            : moment(i, 'YYYY-MM-DD').format('MMM Do'),
        date: date,
        ['totalpageviews' + index]: stv,
        ['messagedusers' + index]: Number(((stmu / stv) * 100).toFixed(2)),
        ['fullconsent' + index]: Number(((spfc / stv) * 100).toFixed(2)),
        ['partialconsent' + index]: Number(((sppc / stv) * 100).toFixed(2)),
        ['rejectedall' + index]: Number(((spra / stv) * 100).toFixed(2)),
        ['siteIds' + index]: p
          .map((entry) => [entry.get('site_id')])
          .reduce((prev, current) => prev.concat(current)),
      })
    );
  });

  aggPropertyTableData = Map({
    key: index,
    date: 'Criteria ' + Number(index + 1),
    totalpageviews: aggTotalVolume,
    messagedusers: aggMessagedUsers,
    fullconsent: aggFullConsent,
    partialconsent: aggPartialConsent,
    rejectedall: aggRejectAll,
    dataArr: propertyTableData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyReportToRecord(s, i)),
    exportdata: exportData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyReportToRecord(s, i)),
  });
  return Map({
    propertyTableData: propertyReportToRecord(aggPropertyTableData, index),
    propertyGraph: aggPropertyGraphData,
  });
}

function aggregatePurposesData(data) {
  let pData = List();
  let groupedByPurposesOrderedMap;
  let key = 0;
  let rows = data.get('purposeResults');
  groupedByPurposesOrderedMap = rows.groupBy((dataPoint) =>
    dataPoint.get('name')
  );
  groupedByPurposesOrderedMap.map((p, i) => {
    pData = pData.push(
      Map({
        key: key,
        name: i,
        consentcount: p
          .map((entry) => Number(entry.get('consent_count')))
          .reduce((prev, current) => prev + current),
        pvcount: p
          .map((entry) => Number(entry.get('pv_count')))
          .reduce((prev, current) => prev + current),
        consentrate: Number(
          (
            p
              .map((entry) => Number(entry.get('consent_rate')))
              .reduce((prev, current) => prev + current) / p.size
          ).toFixed(2)
        ),
        rejectionrate: Number(
          (
            100 -
            p
              .map((entry) => Number(entry.get('consent_rate')))
              .reduce((prev, current) => prev + current) /
              p.size
          ).toFixed(2)
        ),
      })
    );
    key++;
  });
  return pData.toJS();
}

function aggregateChangeLogData(data) {
  let cData = List();

  data.forEach((p, j) => {
    let siteGroupName = p.get('parent_name') ? p.get('parent_name') : 'NA';
    let operationType = p.get('op_name');
    let entityType = p.get('entity_type');
    let entityNames = p.get('entity_name');
    let properties = p.get('site_names').join(', ');
    let user = p.get('username');
    let date = p.get('y') + '-' + p.get('m') + '-' + p.get('d');
    let siteIds = p.get('site_ids');

    cData = cData.push(
      Map({
        key: j,
        siteGroupName: siteGroupName,
        operationType: operationType,
        entityType: entityType,
        entityNames: entityNames,
        properties: properties,
        user: user,
        date: date,
        siteIds: siteIds,
      })
    );
  });
  return cData
    .sort(
      (a, b) =>
        moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
        moment(a.get('date'), 'YYYY-MM-DD').valueOf()
    )
    .toJS();
}

function changeLogToRecord(data) {
  return ChangeLogRecord({
    key: data.key,
    sitegroupname: data.siteGroupName,
    operationtype: data.operationType,
    entitytype: data.entityType,
    entitynames: data.entityNames,
    properties: data.properties,
    user: data.user,
    date: data.date,
    siteIds: data.siteIds,
  });
}
function aggregateVendorData(data) {
  let vData = List();
  let key = 0;
  let groupedByVendorNamesOrderedMap;
  groupedByVendorNamesOrderedMap = data
    .get('vendorResults')
    .groupBy((dataPoint) => dataPoint.get('name'));
  groupedByVendorNamesOrderedMap.map((v, i) => {
    vData = vData.push(
      Map({
        key: key,
        name: i,
        consentcount: v
          .map((entry) => Number(entry.get('consent_count')))
          .reduce((prev, current) => prev + current),
        pvcount: v
          .map((entry) => Number(entry.get('pv_count')))
          .reduce((prev, current) => prev + current),
        consentrate: Number(
          (
            v
              .map((entry) => Number(entry.get('consent_rate')))
              .reduce((prev, current) => prev + current) / v.size
          ).toFixed(2)
        ),
        rejectionrate: Number(
          (
            100 -
            v
              .map((entry) => Number(entry.get('consent_rate')))
              .reduce((prev, current) => prev + current) /
              v.size
          ).toFixed(2)
        ),
      })
    );
    key++;
  });
  return vData.toJS();
}

function propertyReportToRecord(data, index) {
  return PropertiesReport({
    key: index,
    date: data.get('date'),
    siteid: data.get('siteid'),
    totalpageviews: data.get('totalpageviews'),
    messagedusers: data.get('messagedusers'),
    fullconsent: data.get('fullconsent'),
    fullconsentper: data.get('fullconsentper'),
    partialconsent: data.get('partialconsent'),
    partialconsentper: data.get('partialconsentper'),
    rejectedall: data.get('rejectedall'),
    rejectedallper: data.get('rejectedallper'),
    aggData: data.get('dataArr') ? data.get('dataArr') : List(),
    exportdata: data.get('exportdata') ? data.get('exportdata') : List(),
  });
}

function VendorReportToRecord(data) {
  return VendorsConsentReportRecord({
    key: data.key,
    name: data.name,
    consentcount: data.consentcount,
    pvcount: data.pvcount,
    consentrate: data.consentrate,
    rejectionrate: data.rejectionrate,
  });
}

async function aggregateMessageData(
  data,
  rangeSelect,
  formattedData,
  index,
  siteIds
) {
  let groupedByDatesOrderedMap;
  let auxData;
  let aggMessageTableData = List();
  let datePicker = rangeSelect.get('datePicker');
  let aggMsgTableData;
  let aggMsgGraphData = List();
  let graphKey = 0;
  let messagePerformanceList = List();
  let key = 0;

  let aggTotalMsgs = 0;
  let aggConsentAll = 0;
  let aggConsentSome = 0;
  let aggNoConsent = 0;
  let aggRejectAll = 0;

  let auxUrl = datePicker === DATEPICKERS.week ? 'weekly' : 'monthly';

  const quartData = data
    .get('results')
    .filter((a) => a.get('message_id') != undefined)
    .map((dataPoint) =>
      dataPoint.set(
        'week',
        moment(dataPoint.get('ymd'), 'YYYY-MM-DD').isoWeek()
      )
    )
    .groupBy((dataPoint) => dataPoint.get('week'));

  groupedByDatesOrderedMap = data
    .get('results')
    .filter((a) => a.get('message_id') != undefined)
    .groupBy((dataPoint) => dataPoint.get('ymd'));

  const groupByOrderedMap =
    datePicker === DATEPICKERS.quarter ? quartData : groupedByDatesOrderedMap;

  groupByOrderedMap.map((item, i) => {
    const date =
      datePicker === DATEPICKERS.quarter ? item.get(0).get('ymd') : i;
    let messageObj = Map({
      fancyDate: moment(i, 'YYYY-MM-DD').format('MMM Do'),
      date: i,
    });
    graphKey++;
    const groupedToEliminatePMObj = item.groupBy(
      (dataPoint) =>
        dataPoint.get('message_id') + '-' + dataPoint.get('partition_name')
    );
    let stmu = groupedToEliminatePMObj
      .map((entry) =>
        entry.get(0).get('message_id')
          ? Number(entry.get(0).get('total_messaged_users')) || 0
          : 0
      )
      .reduce((prev, current) => prev + current);
    let sca = item
      .map((entry) =>
        entry.get('message_id') ? Number(entry.get('consent_all')) || 0 : 0
      )
      .reduce((prev, current) => prev + current);
    let scs = item
      .map((entry) =>
        entry.get('message_id') ? Number(entry.get('consent_some')) || 0 : 0
      )
      .reduce((prev, current) => prev + current);
    let sra = item
      .map((entry) =>
        entry.get('message_id') ? Number(entry.get('reject_all')) || 0 : 0
      )
      .reduce((prev, current) => prev + current);
    //no consent = total messaged users -  consent_all - consent_some - reject_all
    let snc = stmu - sca - scs - sra;

    aggMsgGraphData = aggMsgGraphData.push(
      Map({
        key: graphKey,
        fancyDate:
          datePicker === DATEPICKERS.quarter
            ? 'Week ' + moment(date, 'YYYY-MM-DD').isoWeek()
            : moment(i, 'YYYY-MM-DD').format('MMM Do'),
        date: date,
        ['totalMsgs' + index]: stmu,
        ['consentAll' + index]: divideByZeroErrChk(sca, stmu),
        ['consentSome' + index]: divideByZeroErrChk(scs, stmu),
        ['noConsent' + index]: divideByZeroErrChk(snc, stmu),
        ['rejectAll' + index]: divideByZeroErrChk(sra, stmu),
        ['siteIds' + index]: [
          ...new Set(
            item
              .map((entry) =>
                entry.get('message_id') ? [entry.get('site_id')] : []
              )
              .reduce((prev, current) => prev.concat(current))
          ),
        ],
      })
    );

    groupedToEliminatePMObj.map((m) => {
      if (m.get(0).get('message_id')) {
        const sumConsentAll = m
          .map((v) =>
            v.get('message_id') ? Number(v.get('consent_all')) || 0 : 0
          )
          .reduce((prev, current) => prev + current);
        messageObj = messageObj.set(
          m
            .get(0)
            .get('message_id')
            .toString(),
          divideByZeroErrChk(
            sumConsentAll,
            Number(m.get(0).get('total_messaged_users'))
          ) || 0
        );
      }
    });
    messagePerformanceList = messagePerformanceList.push(messageObj);
  });
  const isIncompleteDate = isIncompleteDateRange(
    datePicker,
    formattedData.endDate
  );
  
  let fetchUrl =
    buildURL(
      `${CONSENT_PERFORMANCE_REPORT_BASEURL}/dashboard-messages/${auxUrl}`,
      formattedData
    );
  auxData =
    datePicker === DATEPICKERS.quarter || isIncompleteDate
      ? eliminatePMObj(data)
      : await fetch(fetchUrl)
          .then(checkStatus)
          .then((resp) => resp.json())
          .then((resp) => fromJS(resp));

  let aggWeeklyMonthlyData = auxData
    .get('results')
    .filter((a) => a.get('message_id') != undefined)
    .groupBy(
      (dataPoint) =>
        dataPoint.get('message_id') + '-' + dataPoint.get('partition_name')
    );

  aggWeeklyMonthlyData.map((a) => {
    let totalMsgs = Number(a.get(0).get('total_messaged_users')) || 0;
    let consentAll = a
      .map((entry) => Number(entry.get('consent_all')) || 0)
      .reduce((prev, current) => prev + current);
    let consentSome = a
      .map((entry) => Number(entry.get('consent_some')) || 0)
      .reduce((prev, current) => prev + current);
    let rejectAll = a
      .map((entry) => Number(entry.get('reject_all')) || 0)
      .reduce((prev, current) => prev + current);
    //no consent = total messaged users -  consent_all - consent_some - reject_all
    let noConsent = totalMsgs - consentAll - consentSome - rejectAll;

    aggTotalMsgs += totalMsgs;
    aggConsentAll += consentAll;
    aggConsentSome += consentSome;
    aggNoConsent += noConsent;
    aggRejectAll += rejectAll;

    let expandableTableData = List();
    let expandableTable = data
      .get('results')
      .filter(
        (m) =>
          m.get('message_id') != undefined &&
          m.get('message_id') == a.get(0).get('message_id') &&
          m.get('partition_name') == a.get(0).get('partition_name')
      )
      .groupBy(
        (dataPoint) =>
          dataPoint.get('ymd') +
          '-' +
          dataPoint.get('message_id') +
          '-' +
          dataPoint.get('partition_name')
      )
      .map((e, j) => {
        const totalMsgedUsers =
          Number(e.get(0).get('total_messaged_users')) || 0;
        const calConsentAll = e
          .map((entry) => Number(entry.get('consent_all')) || 0)
          .reduce((prev, current) => prev + current);
        const calConsentSome =
          e
            .map((entry) => Number(entry.get('consent_some')) || 0)
            .reduce((prev, current) => prev + current) || 0;
        const calRejectAll =
          e
            .map((entry) => Number(entry.get('reject_all')) || 0)
            .reduce((prev, current) => prev + current) || 0;
        expandableTableData = expandableTableData.push(
          Map({
            date: moment(e.get(0).get('ymd'), 'YYYY-MM-DD').format(
              'MMM Do, YYYY'
            ),
            key: j,
            messageid: Number(e.get(0).get('message_id')),
            totalMsgs: totalMsgedUsers,
            consentAll: calConsentAll,
            consentSome: calConsentSome,
            noConsent:
              totalMsgedUsers - calConsentAll - calConsentSome - calRejectAll ||
              0, //no consent = total messaged users -  consent_all - consent_some - reject_all
            rejectAll: calRejectAll,
            partitionName: e.get(0).get('partition_name'),
          })
        );
      });
    expandableTableData = expandableTableData.sort(
      (a, b) =>
        moment(b.get('date'), 'MMM Do, YYYY').valueOf() -
        moment(a.get('date'), 'MMM Do, YYYY').valueOf()
    );
    aggMessageTableData = aggMessageTableData.push(
      Map({
        key: key,
        messageid: Number(a.get(0).get('message_id')),
        totalMsgs: totalMsgs,
        consentAll: consentAll,
        consentAllPer: errExcep(consentAll, totalMsgs) + '%',
        consentSome: consentSome,
        consentSomePer: errExcep(consentSome, totalMsgs) + '%',
        noConsent: noConsent,
        noConsentPer: errExcep(noConsent, totalMsgs) + '%',
        rejectAll: rejectAll,
        rejectAllPer: errExcep(rejectAll, totalMsgs) + '%',
        partitionName: a.get(0).get('partition_name'),
        siteId: a.get(0).get('site_id'),
        dataArr: expandableTableData.map((e) => MessageTableToRecord(e)),
      })
    );
    key++;
  });
  aggMsgTableData = Map({
    key: index,
    messageid: 'Criteria ' + Number(index + 1),
    totalMsgs: aggTotalMsgs,
    consentAll: aggConsentAll,
    consentSome: aggConsentSome,
    noConsent: aggNoConsent,
    rejectAll: aggRejectAll,
    dataArr: aggMessageTableData.map((s) => MessageTableToRecord(s)),
  });
  return Map({
    messageData: MessageTableToRecord(aggMsgTableData),
    messageGraph: aggMsgGraphData,
    messagePerformanceList: messagePerformanceList,
  });
}

function MessageTableToRecord(data) {
  return MessageTableReportRecord({
    key: data.get('key'),
    date: data.get('date') ? data.get('date') : '',
    messageid: data.get('messageid'),
    partitionname: data.get('partitionName'),
    totalmsgs: data.get('totalMsgs'),
    consentall: data.get('consentAll'),
    consentallper: data.get('consentAllPer'),
    consentsome: data.get('consentSome'),
    consentsomeper: data.get('consentSomePer'),
    noconsent: data.get('noConsent'),
    noconsentper: data.get('noConsentPer'),
    rejectall: data.get('rejectAll'),
    rejectallper: data.get('rejectAllPer'),
    siteid: data.get('siteId') || null,
    aggData: data.get('dataArr') ? data.get('dataArr') : List(),
  });
}

function errExcep(n, d) {
  return d != 0 ? Number(((n / d) * 100).toFixed(2)) : 0;
}
