import React from "react";
import { Tag } from 'antd';

function PropertyTag({ type }){

   const types = { web: 'Website', app: 'App', ott: 'OTT', amp: 'AMP' };

   const label = types[type];

   return label ? (<Tag className="property-type-tag"> { label } </Tag>) : null;
}

export default PropertyTag;
