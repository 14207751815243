import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { Modal } from 'antd';

export default class DeactivateModal extends React.Component {
  static propTypes = {
    moveToDrafts: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  handleApply = () => {
    this.props.delete();
    this.props.closeModal();
  }

  goToPropertySets = () => {
    browserHistory.push(`/privacy_lens/domain_sets?standardId=${this.props.standard.id}`);
  }

  render() {
    return (
      <Modal
        onOk={this.handleApply}
        okText='DELETE PERMANENTLY'
        onCancel={this.props.closeModal}
        className='purple new-design deactvate-ps'
        visible
      >
        <p className="title">{`${this.props.propertySet.name} - Delete`}</p>

        <div className='modal-container'>
          <p>
            Once deleted, all associated data with the property set will be deleted permanently and cannot be retrieved at all.
          </p>
          <p className='question'>
            Are you sure you want to <span>Delete</span>?
          </p>
        </div>
      </Modal>
    );
  }
}
