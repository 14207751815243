import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { User } from '../../records/account_records';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Checkbox, DatePicker, Radio, Button, Input, message, TableFilterDropdown, SelectDropdown } from "../../styleguide"
import { getAccountOwners } from '../../actions/account_actions';
import { getRules } from '../../actions/standard_actions';
import { getAccountsAjax, updateAccountsAjax } from '../../api/consent_quality/account';
import moment from 'moment';
import DropdownScanAllowance from '../common/DropdownScanAllowance';
import { regionsMap } from '../../constants';
import { accountHasPrivacyLens } from '../../util';

const plAccounts = (allAccounts) => {
  return allAccounts.toJS()
    .filter(account => accountHasPrivacyLens(account.accountFeatures))
    .map(account => {
      delete account.zendeskOrganization;
      delete account.accountFeatures;
      account.clientAccountId = account.accountId;
      delete account.accountId;
      return account;
    });
};

class PlAccountManagement extends React.Component {
  static propTypes = {
    accountOwners: ImmutablePropTypes.listOf(PropTypes.instanceOf(User)).isRequired,
    getAccountOwners: PropTypes.func.isRequired,
  }

  state = {
    accounts: plAccounts(this.props.accountOwners),
    editedAccountsIds: new Set(),
    search: '',
    tableView: "Settings",
    editedAccountsWithErros: []
  }

  onSave = () => {
    const accountsToSave = this.state.accounts.filter(a => this.state.editedAccountsIds.has(a.clientAccountId)).map(account => {
      account.scanAllowance = !isNaN(parseInt(account.scanAllowance)) ? parseInt(account.scanAllowance) : '';
      return account;
    })
    const accountsWithErrors =  this.validateAccountsToSave(accountsToSave)
    if(accountsWithErrors.length > 0) {
      message.error("Please fill all details correctly for accounts " + accountsToSave.filter((account)=>accountsWithErrors.find(a=>a.accountId === account.clientAccountId)).map(a => ` ${a.accountName}`))
      return;
    }
    //XXX remove 808 after testing
    const accountId = (this.props.currentUser.masqed || this.props.currentUser.accountId === 808) ? 22 : this.props.currentUser.accountId;
    updateAccountsAjax(accountId, accountsToSave).then(resp => {
      debugger
      message.success("Changes saved successfully");
      this.setState((prevState)=>({...prevState, editedAccountsIds: new Set()}))
    })
    .catch(err=>{
      message.error("Something went wrong");
    })
  }

  validateAccountsToSave = (accountsToSave) => {
    const errorsArray = []
    accountsToSave.forEach(account => {
      let errorCount = 0;
      let error = {
        accountId: account.clientAccountId,
      }
      if(!account.regions?.length) {
        error.regionsError = "Region is required"; 
        errorCount++
      }
      if(account.scanAllowance === ''){
        error.scanAllowance = "Scan Allowance is required"
        errorCount++
      }
      if(!account.startDate){
        error.startDateError = "Start Date is required"
        errorCount++
      }
      if(!account.endDate){
        error.endDateError = "End Date is required"
        errorCount++
      }
      if(moment(account.endDate).isBefore(moment())) {
        error.endDateError = "End Date must be after the Present Date";
        errorCount++
      }
      if(moment(account.endDate).isBefore(account.startDate)){
        error.endDateError = "End Date must be after the Start Date";
        errorCount++
      }
      if(errorCount > 0) errorsArray.push(error);
    })
    this.setState({editedAccountsWithErros: errorsArray});
    return errorsArray;
  }

  componentDidMount() {
    this.props.getRules();
    const accountId = (this.props.currentUser.masqed || this.props.currentUser.accountId === 808) ? 22 : this.props.currentUser.accountId;
    getAccountsAjax(accountId).then(accounts => {
      const withPlAccounts = this.state.accounts.map(account => {
        const plAccount = accounts.find(plA => account.clientAccountId === parseInt(plA.clientAccountId));
        return plAccount || account;
      });
      this.setState({accounts: withPlAccounts});
    });
  }

  parseRuleToColumn = (rule) => {
    return {
      title: rule.name,
      dataIndex: rule.id,
      key: rule.id,
      className: "rules",
      // align: "center",
      filters: [
        { text: "All", value: "all" },
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      filterMultiple: false,
      filterDropdown : ( {filters, clearFilters, setSelectedKeys, selectedKeys, confirm, visible} ) => {
        const onChange = ({ target: { value } }) => {
          if(value === "all") {
            clearFilters();
            visible = false;
          } else {
            setSelectedKeys([value]);
          }
          confirm({closeDropdown: true})
        }
        return (
          <div className="pl-account-filters-dropdown">
            <Radio.Group
              buttonStyle="solid"
              onChange={onChange}
              value={selectedKeys.length ? selectedKeys[0] : "all"}
              defaultValue={"all"}
            >
              {filters.map((option) => (
                <Radio.Button value={option.value}>{option.text}</Radio.Button>
              ))}
            </Radio.Group>
          </div>
        );
      },
      onFilter: (value, record ) =>{
        if(value === "all") return true;
        return ( value === (record[rule.id] || false));
      },
      render: (text, record, index) => <Checkbox purple onChange={() => this.toggleRule(record.accounts.clientAccountId, rule.id)} checked={record.accounts.rules && record.accounts.rules.includes(rule.id)} />
    }
  }

  onScanAllowanceChange = (accountId, val) => {
    const editedAccounts = this.state.accounts.map(account => {
      if (accountId === account.clientAccountId) {
        account.scanAllowance = val;
      }
      return account;
    });
    const editedAccountsIds = this.state.editedAccountsIds;
    editedAccountsIds.add(accountId);
    this.setState({ editedAccountsIds, accounts: editedAccounts }); 
  }

  onRegionChange = (accountId, val) => {
    const editedAccounts = this.state.accounts.map(account => {
      if (accountId === account.clientAccountId) {
        account.regions = val;
      }
      return account;
    });
    const editedAccountsIds = this.state.editedAccountsIds;
    editedAccountsIds.add(accountId);
    this.setState({ editedAccountsIds, accounts: editedAccounts }); 
  }

  setDate = (accountId, prop, date) => {
    const editedAccounts = this.state.accounts.map(account => {
      if (accountId === account.clientAccountId) {
        account[prop] = date ? date._d : null;
      }
      if(account.startDate === null){
        account.endDate = null;
      }
      return account;
    });
    const editedAccountsIds = this.state.editedAccountsIds;
    editedAccountsIds.add(accountId);
    this.setState({ editedAccountsIds, accounts: editedAccounts }); 
  }

  toggleRule = (accountId, ruleId) => {
    const editedAccounts = this.state.accounts.map(account => {
      if (accountId === account.clientAccountId) {
        let rules = account.rules ? [...account.rules] : [];
        if (rules.includes(ruleId)) {
          rules = rules.filter(r => r !== ruleId);
        } else {
          rules.push(ruleId);
        }
        account.rules = rules;
      }
      return account;
    });
    const editedAccountsIds = this.state.editedAccountsIds;
    editedAccountsIds.add(accountId);
    this.setState({ editedAccountsIds, accounts: editedAccounts }); 
  }

  generateTable = () => {
    const GaRules = this.props.rules.filter(rule => rule.productStatus === 'GA').map(rule => this.parseRuleToColumn(rule)).toJS();
    const BetaRules = this.props.rules.filter(rule => rule.productStatus === 'Beta').map(rule => this.parseRuleToColumn(rule)).toJS();
    const AlphaRules = this.props.rules.filter(rule => rule.productStatus === 'Alpha').map(rule => this.parseRuleToColumn(rule)).toJS();
    const regionsOptions = [];
    for (const [key, value] of Object.entries(regionsMap)) {
      regionsOptions.push({label: value, value: key})
    }


    let columns = [
      {
        title: 'Accounts',
        dataIndex: 'accountName',
        key: 'accountName',
        fixed: "left",
        width: "20%",
        className: "subtitle",
        sorter: (a, b) => a.accountName.localeCompare(b.accountName),
      },
      {
        dataIndex: 'Settings',
        key: 'Settings',
        hidden: this.state.tableView === "Settings" ? false : true,
        className: "title",
        children: [
          {
            title: 'Region',
            dataIndex: 'regions',
            key: 'regions',
            className: "subtitle",
            width: "20%",
            filters: Object.entries(regionsMap).map((region)=>({text: region[1], value: region[0]})),
            filterSearch: true,
            onFilter: (value, record) => record.regions.indexOf(value) !== -1,
            filterDropdown :(props)=><TableFilterDropdown {...props} showSearch selectAllOption cancelButton multiple />, 
            render:  (text, record, index)=>{
              return (
              <>
              <SelectDropdown
                options={regionsOptions}
                onChange={(val) => this.onRegionChange(record.accounts.clientAccountId, val)}
                value={record.regions ? record.regions : []} 
                selectAll
                multiple 
                showSearch
                entityName="Regions"
              />
              <div className="validation-error-msg">{this.state.editedAccountsWithErros.find(error => error.accountId === record.accounts.clientAccountId)?.regionsError}</div>
              </>
            )},
          },
          {
            title: 'Scan Allowance per period',
            dataIndex: 'scanAllowance',
            key: 'scanAllowance',
            className: "subtitle",
            width: "20%",
            render: (text, record, index ) =>  {
              return (
              <>
                <DropdownScanAllowance
                onChange={(val) =>
                  this.onScanAllowanceChange(record.accounts.clientAccountId, val)
                }
                scanAllowance={record.scanAllowance}
                />
                <div className="validation-error-msg">{this.state.editedAccountsWithErros.find(error => error.accountId === record.accounts.clientAccountId)?.scanAllowance}</div>
              </>
            )},
            sorter : (a,b) => ((a.scanAllowance || 0) - (b.scanAllowance || 0)),
          
          },
          {
            title: 'Reporting period Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            width: "20%",
            className: "subtitle",
            render:  (text, record, index ) => (
              <>
              <DatePicker
                placeholder="Select"
                format="Do MMM[,] YYYY"
                showToday={false}
                onChange={(date) =>
                  this.setDate(record.accounts.clientAccountId, "startDate", date)
                }
                value={record.startDate ? moment(record.startDate) : ""}
              />
              <div className="validation-error-msg">{this.state.editedAccountsWithErros.find(error => error.accountId === record.accounts.clientAccountId)?.startDateError}</div>
              </>
            ),
            sorter : (a, b) => ((moment(a.startDate).valueOf() || 0) - (moment(b.startDate).valueOf() || 0)),
          },
          {
            title: 'Reporting period End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            width: "20%",
            className: "subtitle",
            render: (text, record, index ) => (
              <>
              <DatePicker
                placeholder="Select"
                format="Do MMM[,] YYYY"
                showToday={false}
                onChange={(date) =>
                  this.setDate(record.accounts.clientAccountId, "endDate", date)
                }
                value={(record.endDate ? moment(record.endDate) : "")}
                disabledDate={(currentDate) => currentDate.isBefore(moment(record.startDate)) || currentDate.isBefore(moment().subtract(1, 'days'))}
                disabled={!record.startDate}
              />
              <div className="validation-error-msg">{this.state.editedAccountsWithErros.find(error => error.accountId === record.accounts.clientAccountId)?.endDateError}</div>
              </>
            ),
             sorter : (a, b) => ((moment(a.endDate).valueOf() || 0) - (moment(b.endDate).valueOf() || 0)),
          },
        ]
      },
      {
        hidden: this.state.tableView === "GA Rules" ? false : true,
        children: GaRules,
        className: "title"
      }, 
      {
        hidden: this.state.tableView === "Alpha Rules" ? false : true,
        children: AlphaRules,
        className: "title"
      }, 
      {
        hidden: this.state.tableView === "Beta Rules" ? false : true,
        children: BetaRules,
        className: "title"
      }, 
    ];
    
    let accounts = this.state.accounts;
    if (this.state.search) {
      accounts = accounts.filter(a => a.accountName.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    const dataSource = accounts.map(account => {
      const accountData = {
        accounts: account,
        accountName : account.accountName,
        regions: account.regions ? account.regions : [],
        scanAllowance: account.scanAllowance,
        startDate: account.startDate ? moment(account.startDate) : "",
        endDate: (account.endDate ? moment(account.endDate) : ""),
      };

      this.props.rules.forEach(rule => {
        accountData[rule.id] = account.rules && account.rules.includes(rule.id)
      })

      return accountData;
    });
    columns = columns.filter(column => !column.hidden)
    return { dataSource, columns };
  }

  render() {
    if (!this.props.rules.size) return null;
    const tableData = this.generateTable();
    return (
      <>
      <div>Note: You can add or edit the properties for every account by interacting with individual cells in each column. Switch to the next section to select rules that should be visible for each account.</div>
      <div className='pl-accounts-container'>
        <div className='flex-row search-container'>
          <Input
            placeholder="Search Accounts"
            onChange={({target: { value }}) => this.setState({ search: value })}
            value={this.state.search}
            className="pl-account-search"
            suffix={<SearchOutlined/>}
          />
          <Button type="primary" onClick={ this.onSave } disabled={!this.state.editedAccountsIds.size}>Save</Button>
        </div>
        <div className="flex-row search-container">
          <div>Use the tabs below to navigate to different pages.</div>
          <div hidden={!this.state.editedAccountsIds.size}><span className='warning-icon'><img src={require("../../assets/images/privacy-lens/warning_icon_yellow.svg")}/></span>Make sure you save the changes before you leave this page.</div>
        </div>
        <div className='flex-row search-container'>
          <div className="pl-accounts-filter">
              <Radio.Group value={this.state.tableView} onChange={e => this.setState({ tableView: e.target.value})}>
                <Radio.Button value="Settings">Settings</Radio.Button>
                <Radio.Button value="GA Rules">GA Rules</Radio.Button>
                <Radio.Button value="Alpha Rules">Alpha Rules</Radio.Button>
                <Radio.Button value="Beta Rules">Beta Rules</Radio.Button>
              </Radio.Group>
          </div>
        </div>
        <div className='rules-title'>{this.state.tableView}</div>
          <Table
            dataSource={ tableData.dataSource }
            columns={ tableData.columns }
            bordered
            scroll={{ x: 400 }}
            tableLayout= "auto"
            pagination={{
              position: ['bottomCenter'],
              showTotal: (total) => `Total Accounts: ${total}`,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
              locale: { items_per_page: ' Records per page' },
            }}
            rowClassName={(record, index) =>{
              return (this.state.editedAccountsWithErros.find(error => error.accountId === record.accounts.clientAccountId) ? "row-class-error" : "")
            }}
          />
      </div>
      </>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountOwners: store.accountState.getIn(['accountOwners', 'value']),
    accountOwnersPending: store.accountState.getIn(['accountOwners', 'pending']),
    rules: store.standardState.getIn(['rules', 'value']),
  };
};

export default connect(
  mapStateToProps, { 
    getAccountOwners,
    getRules
  },
)(PlAccountManagement);
