import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { message } from 'antd';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import { Message } from '../../../records/message_records';
import WarningMessage from '../../common/WarningMessage.js.jsx';

export default class extends React.Component {

    static propTypes = {
        message: PropTypes.instanceOf(Message).isRequired,
        messageList: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        duplicateMessage: PropTypes.func.isRequired,
        deleteMessage: PropTypes.func.isRequired,
        allowEdit: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = { deleteMessagePresent: false};
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.messageList.size > this.props.messageList.size) && nextProps.messageList.some(m => m.description === this.props.message.description + ' (copy)') ) {
            message.success('Your message has been copied.', 3);
        }
    }

    render() {
        let deleteMessage;
        if (this.state.deleteMessagePresent) {
            deleteMessage = (
              <WarningMessage
                name={ this.props.message.description }
                handleDelete={ () => {
                  this.props.deleteMessage(this.props.message.site_id, this.props.message.id);
                  browserHistory.goBack();
                }}
                handleCancel={ () => this.setState({ deleteMessagePresent: false }) }
              />
            )
        }

        let buttons;
        if (this.props.allowEdit) {
          buttons = (
            <div className="buttons-group">
              <CustomIcon
                type={ CustomIcon.types.EDIT }
                onClick={ () => browserHistory.push('/dialogue/messages/edit?message_id=' + this.props.message.id + '&site_id=' + this.props.message.site_id) }
                tooltip="edit"
              />
              <CustomIcon
                type={ CustomIcon.types.COPY }
                onClick={ () => {
                  this.props.duplicateMessage(this.props.message.site_id, this.props.message.id);
                }}
                tooltip="copy"
              />
              <CustomIcon
                type={ CustomIcon.types.DELETE }
                onClick={ () => this.setState({ deleteMessagePresent: true }) }
                tooltip="delete"
              />
            </div>
          )
        }

        return (
          <div className="preview-header-bar clearfix">
            { deleteMessage }
            <div onClick={ () => browserHistory.goBack() } className="close-preview"><ArrowLeftOutlined />Close Preview</div>
            <div className="message-name"><p>{ this.props.message.description }</p></div>
            { buttons }
          </div>
        );
    }
}
