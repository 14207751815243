import React from 'react';
import { List } from 'immutable';
import { Input, Button, Radio, Chip } from '../../../../styleguide'
import { ClientIPGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { styles } from './styles';
import { SCENARIO_STEPS } from '../../scenarios/versionTwo/components/steps';

export default class IPTargeting extends React.Component {
  state = {
    condition: 'Include',
    tags: List(),
  };

  componentWillMount() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .find((r, ind) => r.get('className') == className && ind == this.props.conditionIndex);
    let data;
    if (getSavedData && this.props.isUpdate) {
      data = getSavedData;
    }

    this.setState({
      condition:
        getSavedData && data
          ? data.get('data').get('continue')
            ? 'Include'
            : 'Exclude'
          : 'Include',
      tags: getSavedData && data ? data.get('data').get('ip_list') : List(),
    });
  }

  onChangeText = (e) => {
    const stringTagsarr = e.target.value.trim().split(',');
    this.setState({ tags: List(stringTagsarr) });
  };

  onRemoveTag = (e, index) => {
    e.preventDefault();
    this.setState((prevState) => ({ tags: prevState.tags.delete(index) }));
  };
  onChangeValue = (e) => this.setState({ condition: e.target.value });
  onAddEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set(
      'data',
      new ClientIPGate({
        continue: this.state.condition == 'Include' ? true : false,
        ip_list: this.state.tags,
      })
    );
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.unshift(stepBlockData);
    this.props.onChange(this.props.activeStep, rowData);
  };

  onUpdateEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set(
      'data',
      new ClientIPGate({
        continue: this.state.condition == 'Include' ? true : false,
        ip_list: this.state.tags,
      })
    );
    const index = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.findIndex((r, ind) => r.get('className') == className && ind == this.props.conditionIndex);
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.set(index, stepBlockData);
    this.props.onChange(this.props.activeStep, rowData);
  };

  cancelCondition = () => {
    this.props.closePanel();
  };

  validateIPaddress(ipaddress) {  
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {  
      return true;
    }  
    return false; 
  } 

  render() {
    const readOnly = !(this.state.tags.size && this.state.tags.every(ip => ip && this.validateIPaddress(ip)));

    return (
      <div style={styles.scenarioConditionPanelTwo}>
        <div style={styles.scenarioInnerWrapper}>
          <span className="condition-label">Condition: </span>
          <span className="condition-title">IP Targeting</span>
          <Radio.Group
            style={styles.scenarioMarginLeft}
            defaultValue="In"
            buttonStyle="solid"
            onChange={this.onChangeValue}
            value={this.state.condition}
          >
            <Radio.Button value="Include">Include</Radio.Button>
            <Radio.Button value="Exclude">Exclude</Radio.Button>
          </Radio.Group>
          <div style={styles.IPInputContainer}>
            <div className="condition-label-two">IPs </div>
            <Input
              value={this.state.tags.toJS().toString()}
              onChange={(e) => this.onChangeText(e)}
            />
            <div style={styles.tagsWrap}>
              {this.state.tags.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    closable
                    onClose={(e) => this.onRemoveTag(e, index)}
                  >
                    {item}
                  </Chip>
                );
              })}
            </div>
          </div>
        </div>
        <div style={styles.scenarioButtonWrapper}>
          <Button
            onClick={
              this.props.buttontitle == 'Add'
                ? this.onAddEvent
                : this.onUpdateEvent
            }
            type="primary"
            size='small'
            disabled={readOnly}
          >
            {this.props.buttontitle}
          </Button>
          <Button
            onClick={() => this.cancelCondition()}
            style={styles.scenarioMarginLeft}
            type="primary"
            size='small'
            ghost
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
