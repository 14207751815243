import React from 'react';
import { CaretDownOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Checkbox, Infotip } from '../../../styleguide';
import { List } from 'immutable';

export default class FilterColumns extends React.Component {
   state = {
     columns: this.props.columns.filterNot((cols) => this.props.omitCols.includes(cols.get('dataIndex'))),
     colValues: this.props.columns.filterNot((cols) => this.props.omitCols.includes(cols.get('dataIndex'))).map((cols) => cols.get('dataIndex'))
   };

   onChange = (checkedValues) => {
     this.setState({ 'colValues': List(checkedValues)});
     this.props.setCols(List(checkedValues).concat(this.props.omitCols || List()));
   }

   render() {
    const content = <Checkbox.Group className='reports-table-filter' style={{ width: '100%' }} value={this.state.colValues.toJS()} onChange={this.onChange}>
                        {this.state.columns.map((cols, i)=> <Checkbox purple value={cols.get('dataIndex')}>{cols.get('title')}</Checkbox>)}
                    </Checkbox.Group>;

    return <Infotip overlayClassName="filter-columns-popover" placement="bottom" content={content} trigger="click">
               <Button className="view-cols-btn" icon={<TableOutlined />}>View Columns<CaretDownOutlined /></Button>
           </Infotip>
   }
}