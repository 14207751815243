import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu } from 'antd';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { browserHistory } from 'react-router';
import { getUnresolvedIncidentCount } from '../../../api/status';
import { useInterval } from '../../../hooks';

export const MenuTitle = (props) => (
  <div className={`spt-title ${!props.icon ? 'no-icon' : ''}`}>
    { props.icon && <div className="title-icon">{props.icon}</div> }
    <div className="title-name">{props.name}</div>
  </div>
);

export const MenuItem = (props) => {
  const itemContents = (
    <div className={`spt-item ${!props.icon ? 'no-icon' : ''} ${props.active ? 'active' : ''}`} onClick={props.onClick || null}>
      { props.icon && <div className="item-icon">{props.icon}</div> }
      <div className="item-name">{props.name}</div>
    </div>
  );
  return props.to ? <Link to={props.to}>{itemContents}</Link> : itemContents;
};

export const MenuItemStatus = (props) => {
  const [incident, setIncident] = useState(false);

  const fetchIncidents = async () => {
    try {
      const result = await getUnresolvedIncidentCount();
      setIncident(result.length > 0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => { fetchIncidents(); });
  // Every 2 minutes
  useInterval(() => { fetchIncidents(); }, 120 * 1000);

  const itemContents = (
    <div className={`spt-item ${!props.icon ? 'no-icon' : ''} ${props.active ? 'active' : '' }`} onClick={props.onClick || null}>
      { props.icon && <div className="item-icon">{props.icon}</div> }
      <div className={`item-name ${incident ? 'status-incident' : ''}`}>{props.name}</div>
    </div>
  );
  return props.to ? <Link to={props.to}>{itemContents}</Link> : itemContents;
};

export const Divider = () => (
  <div className="spt-menu-divider"></div>
);

export const MenuDropdown = (props) => {
  const DropdownContents = (
    <div className={"spt-drop"}>
      <div className={ classNames('spt-drop-title', props.name.toLowerCase()) }>{props.name}</div>
      <div className="spt-drop-menu">{props.children}</div>
    </div>
  );

  const className = classNames('spt-drop-box', props.className);

  return (
    <Dropdown
      overlay={DropdownContents}
      getPopupContainer={trigger => props.noParent ? document.body : trigger.parentNode}
      overlayClassName={className}
      placement={props.placement ?? 'bottomLeft'}
    >
      <a className={`spt-menu ${props.active ? 'active' : ''} ${props.className || ''}`} onClick={e => e.preventDefault()}>
        <MenuItem icon={props.icon} name={props.name} />
        <FontAwesomeIcon icon={props.noParent ? faChevronDown : faChevronRight} />
      </a>
    </Dropdown>
  );
};