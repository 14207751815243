import React from 'react';
import { List, Map } from 'immutable';
import { SCENARIO_STEPS } from '../../scenarios/versionTwo/components/steps';
import { systemKeyVals } from '../../../../scenario_constants.js';

import CustomKeyValuePairTargeting from './KeyValuePairTargeting/CustomKeyValuePairTargeting';
import SystemKeyValuePairTargeting from './KeyValuePairTargeting/SystemKeyValuePairTargeting';

export default class KeyValuePairTargeting extends React.Component {
  onSelectFilter = (inputString, option) => {
    return (
      inputString.toLowerCase() ===
      option.props.children.toLowerCase().slice(0, inputString.length)
    );
  };

  getRows() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .filter((r) => r.get('className') == className);
    let data = List();
    if (getSavedData && getSavedData.size > 0) {
      data = getSavedData.map((r, i) => {
        let key = r.get('data').get('k');
        let value = key && systemKeyVals[key] 
          ? List(
            r.get('data').get('v')
              ? r
                  .get('data')
                  .get('v')
                  .split(',')
              : []
            ) 
          : r.get('data').get('v');

        return Map({
          allow_on_satisfy: r.get('data').get('allow_on_satisfy'),
          type: r.get('data').get('type'),
          v: value,
          k: key
        });
      });
    }
    return getSavedData && data && data.size > 0 
      ? data 
      : List([ new SCENARIO_STEPS[className].Record().data ]);
  }

  render() {
    const rows = this.getRows();
    const key = rows.getIn([0, 'k']);
    if(key && systemKeyVals[key]) {
      const systemKeyVal = systemKeyVals[key];
      let selectedValue = rows.getIn([0, 'v', 0]);
      if (selectedValue) {
        selectedValue = selectedValue + ':' + (rows.getIn([0, 'allow_on_satisfy']))
      }
      return (<SystemKeyValuePairTargeting {...this.props} 
        selectedOption={selectedValue}
        label={systemKeyVal.label} 
        options={systemKeyVal.options ? systemKeyVal.options : []} />);
    }
    return (<CustomKeyValuePairTargeting {...this.props} />);
  }
}
