import React from 'react';

import Font from '../../builder/Builder/Sidebar/Settings/Components/Font';
import ColorPicker from '../../../common/ColorPickerInput.js.jsx';

const Style = ({ updateStyle, styles }) => {
  return (
    <div className="styles-container">
      <h4>style</h4>
      <h5>font</h5>
      <Font
        updateSetting={(_, newStyles) => updateStyle(newStyles)}
        value={styles}
      />
      <div className="bg-color-container">
        <h5>background color</h5>
        <ColorPicker
          value={styles.backgroundColor}
          onUpdate={(backgroundColor) => updateStyle({ backgroundColor })}
          editing={true}
        />
      </div>
    </div>
  );
};

export default Style;