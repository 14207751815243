import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import DeviceStats from './DeviceStats.js.jsx';
import PartitionStats from './PartitionStats.js.jsx';

export default class extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        campaignTitle: PropTypes.string.isRequired,
        partitionUuidToName: PropTypes.object.isRequired,
        messageIdToName: PropTypes.object.isRequired,
        handleCampaignStatsClosing: PropTypes.func.isRequired,
    };

    state = { showJsonData: false };

    toggleJsonData = () => {
        this.setState({ showJsonData: !this.state.showJsonData })
    };

    render() {
        var jsonData;

        if (this.state.showJsonData) {
            jsonData = (
                <div className="code-container stats"><pre><code>{ JSON.stringify(this.props.data, undefined, 2) }</code></pre></div>
            )
        }

        var pd = this.props.data.partition_data;
        var partitions = Object.keys(pd).map(key => pd[key]);
        var partitionsWithoutControl = [];

        var controlTable;
        for (var key in partitions) {
            if (partitions[key].partition_uuid === "control") {
                controlTable = <div className="partition-stats"><DeviceStats data={ partitions[key].partition_stats } title="Control"/></div>;
            } else {
                var partition = $.extend(true, {}, partitions[key]);
                partition.name = this.props.partitionUuidToName[partition.partition_uuid]
                partitionsWithoutControl.push(partition)
            }
        }

        return (
           <div>
            <div className="campaign-table-stats-container">
              <div className="hidden-json-button" onClick={ this.toggleJsonData }></div>

              <div className="campaign-stats">
                <DeviceStats data={ this.props.data.campaign_stats } title={ this.props.campaignTitle }/>

                { partitionsWithoutControl.map((partition, i) => {

                    return <PartitionStats key={ partition.partition_uuid } partition={ partition } messageIdToName={ this.props.messageIdToName }/>;

                })}

                { controlTable }

               </div>

             </div>

             { jsonData }

             <div className="x-close" onClick={ this.props.handleCampaignStatsClosing }>&times;</div>

            </div>
        )
    }
}
