import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import { TimeDelay } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';

export default class StepTimeDelay extends React.Component {

    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(TimeDelay).isRequired,
        onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            delay_secs: this.props.data.delay_secs,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.data) {
            this.setState({
                delay_secs: nextProps.data.delay_secs,
            })
        }
    }

    componentDidMount(){
        if (this.props.editing) ReactDOM.findDOMNode(this.refs.secs).focus();
    }

    handleChange(event) {
        const value = parseInt(event.target.value);

        if (isNaN(value)) {
            this.setState({ delay_secs: '' });
        } else {
            this.setState({ delay_secs: value });
            this.props.onChange(this.props.data.set('delay_secs', value));
        }
    }

    render() {
        if (!this.props.editing) {
            return (<div>Num seconds to delay: { this.state.delay_secs }</div>);
        } else {
            return (
                <div>
                  <label>Num seconds to delay:</label>
                  <input ref="secs" type="text" value={ this.state.delay_secs } onChange={ this.handleChange }></input>
                </div>
            );
        }
    }
}
