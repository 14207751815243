import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InfoCircleFilled } from '@ant-design/icons';
import { Divider, Image, Table, Tooltip } from 'antd';

import {
  VendorDetailsColumn,
  VendorNameColumn,
  VendorTypeColumn,
  CookieDetailsColumn,
} from '../../shared/DiagnoseColumns';

import {
  allCookiesFoundExport,
  getAllCookiesFoundFilters,
  getAllCookiesFoundList,
} from '../../../../../actions/diagnose_dashboard_actions';

import cookieNonsecure from '../../../../../assets/images/diagnose/Cookie_not_locked.svg';
import cookieIcon from '../../../../../assets/images/diagnose/cookie.svg';
import cookieFilledIcon from '../../../../../assets/images/diagnose/cookie_filled.svg';
import googleIcon from '../../../../../assets/images/diagnose/google_icon.png';
import TableFilterDropdown from '../../shared/TableFilterDropdown';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import { isFiltersProvided, mapSelectedFilters } from '../helpers';
import { browserHistory } from 'react-router';

const DetailedBreakdown = ({
  getAllCookiesFoundList,
  allCookiesFoundList,
  allCookiesFoundListPending,
  allCookiesFoundFilter,
  getAllCookiesFoundFilters,
  selectedFilters,
  allCookiesFoundExportPending,
  allCookiesFoundExport,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(
    10,
    1,
    'cookie_number_desc',
  );
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const [tableFilters, setTableFilters] = useState(null);

  const getAllCookiesFoundListData = (tableFiltersData = {}, vendorId = []) => {
    getAllCookiesFoundList({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
  };

  const getAllCookiesFoundFiltersData = () => {
    getAllCookiesFoundFilters(filters);
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      getAllCookiesFoundListData(tableFilters, searchParams?.vendor && [searchParams.vendor]);
    }
  }, [filters, tableFilters, limit, page, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      getAllCookiesFoundFiltersData();
    }
  }, [filters]);

  const vendorsFilter = allCookiesFoundFilter?.name?.map((filter) => ({ text: filter, value: filter }));

  const columns = [
    VendorTypeColumn({}),
    VendorNameColumn({ filters: vendorsFilter || [] }),
    {
      title: () => {
        return (
          <div>
            Distinct Cookies
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    This is the number of distinct cookie names that we have observed as being set on your properties
                    from this vendor. To display the cookie names please click on the cookie details.
                  </div>
                );
              }}
              overlayClassName="diagnose--tooltip information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'cookie_number',
      key: 'cookie_number',
      width: 150,
      sorter: (a, b) => a.cookie_number - b.cookie_number,
      defaultSortOrder: 'descend',
    },
    {
      title: () => {
        return (
          <div>
            Flagged
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    Cookies are flagged if they meet at least one of the following criteria: non-secure, persistent, or
                    large (over 100b).
                  </div>
                );
              }}
              overlayClassName="diagnose--tooltip information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'flagged',
      key: 'flagged',
      render: (text, record) => {
        return (
          <>
            {!record.secure && (
              <span title="Non-Secure" style={{ marginRight: '5px' }}>
                <Image alt="Non-Secure" src={cookieNonsecure} preview={false} />{' '}
              </span>
            )}
            {record.persistent && (
              <span title="Persistent Cookies" style={{ marginRight: '5px' }}>
                <Image alt="Persistent cookies" src={cookieIcon} preview={false} />{' '}
              </span>
            )}
            {record.cookie_size > 100 && (
              <span title="Large" style={{ marginRight: '5px' }}>
                <Image alt="Large" src={cookieFilledIcon} preview={false} />
              </span>
            )}
          </>
        );
      },
      filters: [
        { text: 'Large', value: 'cookie_size' },
        { text: 'Non-Secure', value: 'cookie_secure' },
        { text: 'Persistent cookies', value: 'cookie_persistent' },
      ],
      filterDropdown: (props) => (
        <TableFilterDropdown allowAllSelect dropdownClassName={'dianose-dashboard_filter'} {...props} multiple />
      ),
      width: 120,
    },
    {
      title: () => {
        return (
          <div>
            1st or 3rd Party
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    1st party cookies are created by the domain of the property selected and can only be used to track
                    user behaviour on the domain that created it. 3rd party cookies are created by a 3rd party domain
                    and can be used to track a user cross-domains.
                  </div>
                );
              }}
              overlayClassName="diagnose--tooltip information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'party_cookie',
      key: 'party_cookie',
      width: 100,
      render: (text, record) => {
        return <div>{record.party_cookie.join(', ')}</div>;
      },
    },
    {
      title: () => {
        return (
          <div>
            Cookie Category
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    The cookie category is determined using a process to distinguish the technology category that is
                    being used by the host domain that sets the cookie, and then applying these technology
                    categorisations to four buckets; “Strictly Necessary”, “Targeting/Advertising”, “Performance”,
                    “Functionality”. For any technologies that are uncategorised or if you wish to question one of the
                    categorisations please reach out to your Sourcepoint account manager.
                  </div>
                );
              }}
              overlayClassName="diagnose--tooltip information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'category',
      key: 'category',
      width: 155,
      render: (text, record) => {
        return <div>{record.category.join(', ')}</div>;
      },
    },
    {
      ...CookieDetailsColumn({ metric: 'All Cookies Found' }),
      width: 155,
    },
    {
      ...VendorDetailsColumn({ metric: 'All Cookies Found' }),
      width: 155,
    },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const onPaginationChange = (pageNumber, pageSize) => {
    handlePageChange(pageNumber);
  };

  // const exportCsv = () => {
  //     allCookiesFoundExport(filters)
  // };

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The table below lists the vendors that we have dropped cookies on your properties in the period of time
        selected. If you expand each vendor it will give you more details about the cookies we have found.
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container">
        <div className={'filters_block'}>
          <div className={'quick_filters'} />
          {/* <div className={ 'downloadCSV' }> */}
          {/*    <Button disabled icon={<DownloadOutlined />} onClick={exportCsv} loading={allCookiesFoundExportPending} /> */}
          {/* </div> */}
        </div>
        <Table
          id="detailed-breakdown"
          scroll={{ x: 'max-content', y: 430 }}
          dataSource={allCookiesFoundList?.results}
          columns={columns}
          showSorterTooltip={false}
          pagination={{
            current: page,
            pageSize: limit,
            total: allCookiesFoundList?.total_count,
            position: ['bottomCenter'],
            onChange: onPaginationChange,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
          }}
          onChange={onTableChange}
          rowKey={(record) => record?.vendor_id}
          rowClassName={(record) =>
            record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
              ? 'diagnose-notification-highlight'
              : ''
          }
          loading={{
            className: 'diagnose-spinning',
            spinning: allCookiesFoundListPending,
            tip: allCookiesFoundList?.results?.length
              ? ''
              : 'Processing a large query; please wait or leave it open and check later.',
            size: 'large',
          }}
        />
        <div className="vendor-types-legend">
          <div className="vendor-types-item">
            <div className="vendor-types-item-google">
              <Image src={googleIcon} preview={false} height={'13px'} width={'13px'} />
            </div>
            <div> - Google ATP</div>
          </div>
          <div className="vendor-types-item">
            <div className="vendor-types-item-iab" />
            <div> - IAB</div>
          </div>
          <div className="vendor-types-item">
            <div className="vendor-types-item-custom" />
            <div> - Custom</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    allCookiesFoundList: store.diagnoseDashboardState.getIn(['allCookiesFoundList', 'value']),
    allCookiesFoundListPending: store.diagnoseDashboardState.get('allCookiesFoundListPending'),
    allCookiesFoundExportPending: store.diagnoseDashboardState.get('allCookiesFoundExportPending'),
    allCookiesFoundFilter: store.diagnoseDashboardState.getIn(['allCookiesFoundFilter', 'value']),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllCookiesFoundList,
      getAllCookiesFoundFilters,
      allCookiesFoundExport,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
