import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Checkbox, Input } from 'antd';
import AutoAddNewVendorsCheckbox from './AutoAddNewVendorsCheckbox';
import ServerCookieCheckbox from './ServerCookieCheckbox';
import AllIabCheckbox from './AllIabCheckbox';
import { VendorList } from '../../../records/vendor_list_records';

export default class AdvancedSettingsModal extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    vendorScanningEnabled: PropTypes.bool.isRequired,
    getVendorsAndCategoriesForToggleIab: PropTypes.func.isRequired,
    updateParentState: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
  }

  state = {
    vl: this.props.vendorList,
    allIabAndAutoUpdate: this.props.allIabAndAutoUpdate,
    showWarning: false
  }

  toggleShareRootDomain = (e) => {
    this.setState({
      vl: this.state.vl.set('shareRootDomain', e.target.checked),
    });
  }

  toggleAutoScan = () => {
    this.setState({
      vl: this.state.vl.set('autoUpdateVendorScan', !this.state.vl.autoUpdateVendorScan),
    });
  }

  handleCancelModalChanges = () => {
    this.setState({ vl: this.props.vendorList });
    this.props.closeModal();
  }

  validateMMSDomain = (url) => {
    return /^(ftp|http|https):\/\/[^ "]+$/.test(url)
  }

  handleOnOk = () => {
    this.props.updateParentState({
      vl: this.state.vl,
      allIabAndAutoUpdate: this.state.allIabAndAutoUpdate,
    });
    this.props.closeModal();
  }

  toggleAllIabAndUpdate = () => {
    const vendorsAndCategories = this.props.getVendorsAndCategoriesForToggleIab(this.state.vl, this.state.allIabAndAutoUpdate);

    this.setState({
      vl: this.state.vl
        .set('vendors', vendorsAndCategories.get('vendors'))
        .set('categories', vendorsAndCategories.get('categories')),
      allIabAndAutoUpdate: !this.state.allIabAndAutoUpdate,
    });
  }

  setCookieMaxAge = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
        value = '';
    } else if (value < 0) {
        value = 0;
    } else if (value > 365) {
        value = 365;
    }
    if (value > 365) value = 365;
    this.setState({
      vl: this.state.vl.set('cookieMaxAge', value),
    });
  }

  toggleWriteFirstPartyCookiesFromServer = (e) => {
    this.setState({
      vl: this.state.vl.set('writeFirstPartyCookiesFromServer', e.target.checked),
      showWarning: e.target.checked
    });
  }

  render() {

    return (
      <Modal
        visible={ this.props.visible }
        onOk={ this.handleOnOk }
        onCancel={ this.handleCancelModalChanges }
        okText='Apply changes'
        className='vl-advanced-settings'
        closable={ false }
        destroyOnClose
      >

        <p className='title'>Advanced Settings</p>

        { this.props.vendorScanningEnabled ?
          <AutoAddNewVendorsCheckbox
            onChange={ this.toggleAutoScan }
            checked={ this.state.vl.autoUpdateVendorScan }
            tooltipPlacement='right'
            readOnly={ this.props.readOnly }
          /> :
          null
        }

        <AllIabCheckbox
          toggleAllIabAndUpdate={ this.toggleAllIabAndUpdate }
          allIabAndAutoUpdate={ this.state.allIabAndAutoUpdate }
          readOnly={ this.props.readOnly }
          tooltipPlacement='right'
        />

        <div className='share-root-domain-checkbox'>
          <Checkbox
            className='auto-scan'
            checked={ this.state.vl.shareRootDomain }
            onChange={ this.toggleShareRootDomain }
            disabled={ this.props.readOnly }
          >
            Write 1st Party Cookies To Root Domain
          </Checkbox>
        </div>

        <ServerCookieCheckbox
          toggleWriteFirstPartyCookiesFromServer = {this.toggleWriteFirstPartyCookiesFromServer}
          writeFirstPartyCookiesFromServer = {this.state.vl.writeFirstPartyCookiesFromServer}
          showWarning = {this.state.showWarning}
          tooltipPlacement='right'
          readOnly={ this.props.readOnly }
        />

        <div className='cookies-expiration'>
          <span>Consent cookies expiration</span>
          <Input
            value={ this.state.vl.cookieMaxAge }
            onChange={ this.setCookieMaxAge }
            disabled={ this.props.readOnly }
          />
          <span>days</span>
        </div>

      </Modal>
    );
  }
}
