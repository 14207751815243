import React, { useState, useEffect, useMemo, useImperativeHandle } from 'react';
import { Button, Radio, Tabs, Alert} from '../../../../../styleguide';
import { Divider } from "antd"
import { WarningFilled, CloseCircleFilled } from '@ant-design/icons';

const { TabPane } = Tabs;

import AddVendorsTable from './AddVendorsTable';

const AddVendors = React.forwardRef((props, ref) => {
  const {
    globalVendors,
    vendorList,
    scannedVendors,
    accountId,
    getScannedVendorsV2,
    isScanningVendors,
    isVendorScanV2Enabled,
    selectedVendors,
    updateSelectedVendors,
    updateVendorList,
    vendorWithProblems,
    indexedSacnnedVendors,
    isNonIab,
  } = props;
  
  const [showVendorsFrom, filterVendors] = useState(isVendorScanV2Enabled ? 'scan' : 'system');
  const [activeTab, changeActiveTab] = useState('scan');
  const [vendorsFromScan, updateVendorsFromScan] = useState(scannedVendors);
  const [showSelectedView, setSelectedView] = useState(false);
  const [vendorType, setVendorType] = useState('known')
  const [checkedVendors, setCheckedVendors] = useState({
    'scan' : [],
    'system' : [],
    'selected' : []
  });
  const [clearSelectedRowsKeys, setClearSelectedRowKeys] = useState(false);
  const [showVendorNumberMessage, setShowVendorNumberMessage] = useState(true);

  const updateCheckedVendors = (keys) => {
    if(showSelectedView) {
      setCheckedVendors({
        ...checkedVendors,
        'selected' : keys
      })
    } else {
      setCheckedVendors({
        ...checkedVendors,
        [activeTab] : keys
      })
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getCheckedVendorsToAdd() {
        return checkedVendors[activeTab]
     },
      addCheckedVendors() {
      addVendor(checkedVendors[activeTab]);
     },
     clearCheckedVendors() {
      setCheckedVendors({
        'scan' : [],
        'system' : [],
        'selected' : []
      });
      setClearSelectedRowKeys(true);
      setClearSelectedRowKeys(false);
     }
    };
  }, [checkedVendors]);

  const vendorsInVl = useMemo(() => {
    let vendors = vendorList.vendors.map((v) => v.id)
    return vendors;
  },[vendorList.vendors]);

  const indexedGlobalVendors = useMemo(() => {
    let indexVendors = globalVendors.reduce(function (map, obj) {
      map[obj.id] = obj
      return map;
    }, {});
    return indexVendors;
  }, [scannedVendors]);

  const vendorsNotInVl = useMemo(()=>{
    let vendors =  globalVendors.filter((v) => {
        return !vendorsInVl.includes(v.id) || (vendorsInVl.includes(v.id) && selectedVendors.includes(v.id));
      })
    return vendors  
  },[selectedVendors, vendorsInVl])

  useEffect(() => {
    const siteIds = vendorList.siteIds;
    if (showVendorsFrom === 'scan' && siteIds.size) {
      if (isVendorScanV2Enabled) {
        getScannedVendorsV2(siteIds.toJS(), vendorList.id, accountId);
      }
    }
  }, []);

  useEffect(() => {
    if(vendorsInVl.size >0){
      updateSelectedVendors(vendorsInVl);
    }
  },[])

  useEffect(() => {
    if (scannedVendors != vendorsFromScan) {
      const vendors = scannedVendors.map((v) => {
        if(indexedGlobalVendors[v.id]) {
          let vendor = indexedGlobalVendors[v.id];
          return vendor;
        }
        return v;
      });
      updateVendorsFromScan(vendors);
    }
  }, [scannedVendors]);

  const deleteVendor = (rmVendorIds) => {
    const updatedCategories = vendorList.get('categories').map((category) => {
      if (category.categories.size) {
        let stackCategories = category.categories;
        stackCategories = stackCategories.map((category) => {
          return category
            .update('vendorIds', (vendorIds) => vendorIds.filter((id) => !rmVendorIds.includes(id)))
            .update('vendorCategorization', (vc) => vc.filter(({ vendorId }) => !rmVendorIds.includes(vendorId)));
        });
        return category.set('categories', stackCategories);
      } else {
        return category
          .update('vendorIds', (vendorIds) => vendorIds.filter((id) => !rmVendorIds.includes(id)))
          .update('vendorCategorization', (vc) => vc.filter(({ vendorId }) => !rmVendorIds.includes(vendorId)));
      }
    });

    const updatedVendorsWrappers = vendorList.get('vendorsWrappers').filter((vw) => !rmVendorIds.includes(vw.vendorId));

    const updatedVendorList = vendorList
      .update('vendors', (vendors) => vendors.filter((v) => !rmVendorIds.includes(v.id)))
      .set('categories', updatedCategories)
      .set('vendorsWrappers', updatedVendorsWrappers);

    return updatedVendorList;
  };

  const onChangeTab = (key) => {
    filterVendors(key);
    changeActiveTab(key);
  };

  const allVendors = useMemo(()=>{
    let allVendors = vendorsNotInVl;	
    if (showVendorsFrom == 'scan') {	
      allVendors = vendorsFromScan.filter(v => !vendorsInVl.includes(v.id) || (vendorsInVl.includes(v.id) && selectedVendors.includes(v.id)));	
    }	
    allVendors = allVendors.filterNot(v => v.isDeletedFromGvl);
    return allVendors;
  },[vendorsFromScan,vendorsNotInVl,showVendorsFrom]);

  const navTabs = [
    {
        title:'Vendor Scan Results', 
        key:"scan",
    }, 
    {
        title:'All System Vendors',
        key:"system",
    }
  ]
  
  useEffect(() => {
    changeActiveTab(showVendorsFrom)
  },[showVendorsFrom])


  const changeSelectedView = () => {
    setSelectedView(true)
    changeActiveTab(activeTab)
  }

  const removeVendors = (removedVendors) => {
    const updatedSelVendorIds = selectedVendors.filter(v => !removedVendors.includes(v))
    updateSelectedVendors(updatedSelVendorIds);
    const updatedVendorList = deleteVendor(removedVendors);
    updateVendorList(updatedVendorList);
  }

  const addVendor = (selVendors) => {
    updateSelectedVendors([...selectedVendors,...selVendors])
  }

  const onChangeVendorType = (value) => {
    setVendorType(value)
  }

  const slVendors = useMemo(() => {
    let vendors = globalVendors.filter((v) => selectedVendors.includes(v.id)
    );
    return vendors;
  }, [selectedVendors]);

  let vendors = useMemo(() => allVendors.toJS().filter(v => !selectedVendors.includes(v.id)),[allVendors,selectedVendors]);
  vendors = isNonIab ? vendors.filter(v => !(v.iab === true && v.vendorType === 'IAB')) : vendors;
  let unkownVendors = useMemo(() => vendorWithProblems.filter(v => !selectedVendors.includes(v.id)),[vendorWithProblems,selectedVendors]);
  unkownVendors = isNonIab ? unkownVendors.filter(v => !(v.iab === true && v.vendorType === 'IAB')) : unkownVendors;

  const vendorNumbersMessage = <><b>Publishers should consider the number of Vendors they work with, and put in place a selection process.</b><br/>Providing transparency and helping to establish Legal Basis within the Framework for an unjustifiably large number of Vendors may impact users' ability to make informed choices and increase publisher and vendor legal risk.</>
  return (
    <div className="add-vendors-wrapper">
      {showVendorNumberMessage ? (<Alert type="warning" message={vendorNumbersMessage} showIcon icon={<WarningFilled />} closable closeIcon={<CloseCircleFilled onClick={() => setShowVendorNumberMessage(false)}/>} style={{marginTop: '10px'}}/>) : null}
      {!showSelectedView && <Tabs tabBarExtraContent={<Button type="primary" onClick={changeSelectedView} size="small">
            View Selected Vendors{' '}
            {slVendors.size ? `(${slVendors.size})` : ''}
          </Button>} genre="normal" activeKey={activeTab} onChange={onChangeTab}>
      {
        navTabs.map((tab) => {    
          return (<TabPane tab={tab.title} key={tab.key}>
            {tab.key === 'scan' && (<div className='vlVendorTypeBox'>
            <span>View From:</span>
            <Radio.Group style={{marginTop:'20px'}} onChange={(e) => onChangeVendorType(e.target.value)} defaultValue="known" buttonStyle="solid" value={vendorType}>
              <Radio.Button value="known">Known Vendors</Radio.Button>
              <Radio.Button value="unKnown">Unknown Vendors</Radio.Button>
            </Radio.Group>
            </div>)}
              {(vendorType === 'known' || activeTab==='system') && (
                <AddVendorsTable 
                    vendors={vendors}
                    isLoading={isScanningVendors}
                    activeKey={activeTab}
                    vendorType={vendorType}
                    showSelectedView={showSelectedView}
                    btnConfig={{title: 'Add to selected vendors', onClickEvent: addVendor, type:'primary'}}
                    indexedSacnnedVendors={indexedSacnnedVendors}
                    updateCheckedVendors={updateCheckedVendors}
                    checkedVendors={checkedVendors}
                    clearSelectedRowsKeys={clearSelectedRowsKeys}
                    isNonIab={isNonIab}
                />)}
              {vendorType === 'unKnown' && activeTab === 'scan' ? (
                <AddVendorsTable 
                 vendors={[...unkownVendors.toJS()]}
                 isLoading={isScanningVendors}
                 activeKey={activeTab}
                 vendorType={vendorType}
                 btnConfig={{title: 'Add to selected vendors', onClickEvent: addVendor, type:'primary'}}
                 showSelectedView={showSelectedView}
                 indexedSacnnedVendors={indexedSacnnedVendors}
                 updateCheckedVendors={updateCheckedVendors}
                 checkedVendors={checkedVendors}
                 clearSelectedRowsKeys={clearSelectedRowsKeys}
                 isNonIab={isNonIab}
                />) : null}
            </TabPane>)
        })
      }
      </Tabs>}
      {showSelectedView && (<div className="sl-vendors-new">
      <div className="sv-header"><span>Selected Vendors</span><span className="bck-btn" onClick={() => setSelectedView(false)}>{`< Go back`}</span></div>
      <Divider className="sv-divider"/>
        <AddVendorsTable 
            vendors={[...slVendors.toJS()]}
            isLoading={isScanningVendors}
            activeKey={activeTab}
            showSelectedView={showSelectedView}
            vendorType={vendorType}
            btnConfig={{title: 'Remove vendors', onClickEvent: removeVendors,type:'secondary'}}
            indexedSacnnedVendors={indexedSacnnedVendors}
            updateCheckedVendors={updateCheckedVendors}
            checkedVendors={checkedVendors}
            clearSelectedRowsKeys={clearSelectedRowsKeys}
            isNonIab={isNonIab}
        />
    </div>)}
    </div>
  );
})

export default AddVendors;
