import React, { Component } from 'react';
import { ArrowRightOutlined, DeleteOutlined, EyeFilled, WarningFilled } from '@ant-design/icons';
import { Modal, Divider, InputNumber, Alert } from 'antd';
import { Button } from '../../../../styleguide';
import MessageDropDown from './MessageDropDown';
import MessageDropDownSearch from './MessageDropDownSearch';
import { List, Map } from 'immutable';
import {
  getMessageDelivery,
  getMessageDelay,
  getMsgDeliveryValue,
  messageConditions,
  messageDelay,
  messageRepeat,
  calculateTimeFromSeconds,
} from '../helper';
import { styles } from './styles';
import { getParameterByName } from '../../../utils.js';
import { getMessageWithDetailAjax } from '../../../../api/dialogue/messages';
import { ViewMessage } from '../../../common/ViewMessage';
import MessagePreview from '../../messages/MessagePreview';
const messageDelayUnit = List([
  Map({ label: 'Seconds', key: 'seconds' }),
  Map({ label: 'Minutes', key: 'minutes' }),
  Map({ label: 'Hours', key: 'hours' }),
  Map({ label: 'Days', key: 'days' }),
  Map({ label: 'Weeks', key: 'weeks' }),
]);
const newMessage = Map({
  msgDelivery: 0,
  msg: '-1',
  msgDelay: 3,
  msgRepeat: 'No Repeat',
  msgDelayUnit: 'days',
  msgTimeSpan: 1,
  msgType: '',
  msgMax: 1,
  msgRepeatSecs: 1,
});
const delayUnits = ['hours', 'minutes', 'seconds'];
export default class CollapsibleStep extends React.Component {
  constructor(props) {
    super(props);
    const row = props.step.rowData;
    this.state = {
      showMessage: false,
      showAdblock: false,
      deleteStepPopupVisible: false,
      stepName: props.step.get('title') || 'Step ' + (props.index + 1),
      message: props.step.get('message'),
      msgDelay: 3,
      msgDelivery: getMsgDeliveryValue(row),
      msg: '-1',
      msgTimeSpan: 1,
      delConditionVisible: false,
      delConditionId: '',
      delConditionClassName: '',
      msgDelayUnit: 'days',
      messages: List(),
      rowCopyList: List(),
      viewMsg: Map({}),
      showWarningMessage: false,
    };
  }

  handleChange = (value, index) => {
    let data = this.state.messages;
    data = data.setIn([index, 'msgRepeat'], value);
    this.setState({ messages: data }, () =>
      this.props.onMessageListChange(this.state.messages, this.props.index)
    );
  };

  showCondition = (item, index) => {
    this.props.onSelectCondition(item.get('value'), index);
    this.props.toggelPanel(item, 'Update');
  };

  deleteStepPopupVisible = () => {
    this.setState({
      deleteStepPopupVisible: !this.state.deleteStepPopupVisible,
    });
  };

  stepOnChange = (e) => {
    this.setState({ stepName: e.target.value });
  };

  addMessage = (index) => {
    let messages = this.state.messages;
    messages = messages.push(newMessage);
    this.setState({ messages });
    this.props.onMessageListChange(messages, index);
  };

  getMsgDetails = (id) =>
    this.props.messages.find((m, i) => m.get('id') == id) || Map();

  viewMessage = (id) => {
    const viewMsg = this.getMsgDetails(id);
    this.setState({ showMessage: true, viewMsg });
    if (viewMsg.get('message_category_id') == 0) {
      const siteId = parseInt(getParameterByName('site_id', window.location));
      const isSiteGroup = !!getParameterByName('site_group_id', window.location);
      getMessageWithDetailAjax(
        siteId,
        viewMsg.get('id'),
        isSiteGroup
      ).then((resp) => this.setState({ showMessage: true, viewMsg: resp }));
    } else {
      this.setState({ showMessage: true, viewMsg });
    }
  };

  updateDelayInMessage = (delayData, message) => {
    let updatedMessage = message;
    let delayKey = getMessageDelay(delayData);
    let delay_time = delayData.get('data').get(delayKey.get('key'));
    let timeValue;

    updatedMessage = updatedMessage.set('msgDelay', delayKey.get('value'));
    if (delayKey.get('value') === 0) {
      timeValue = calculateTimeFromSeconds(delay_time);
      updatedMessage = updatedMessage.set('msgDelayUnit', timeValue.get('msgDelayUnit'));
      updatedMessage = updatedMessage.set('msgTimeSpan', timeValue.get('timeSpan'));
    } else {
      updatedMessage = updatedMessage.set('msgTimeSpan', delay_time);
    }
    return updatedMessage;
  }

  makeMesagesList = (rowData, reLoadMessages = true) => {
    let newMessage = Map({
      msgDelivery: 0,
      msg: '-1',
      msgDelay: 3,
      msgRepeat: 'No Repeat',
      msgDelayUnit: 'days',
      msgTimeSpan: 1,
      msgType: '',
      msgMax: 1,
      msgRepeatSecs: 1,
    });
    let messages = List();

    rowData && rowData.size > 0 && rowData.map((item, index) => {
        let category = item.get('category');

        if (category === 'message') {
          let message_id =
            item.get('data').message_id != null
              ? item.get('data').message_id
              : '-1';
          let selectedMessage = this.props.messages.filter(
            (item, index) => item.get('id') === message_id
          );
          let message_category_id = '';
          selectedMessage.map((item, index) => {
            message_category_id = item.get('message_category_id');
          });

          const msgDelivery = getMessageDelivery(item);
          newMessage = newMessage.set('msgDelivery', msgDelivery).set('msg', item.get('data').message_id).set('msgDelay', 3).set('msgType', message_category_id);
          if( msgDelivery === 2 ) { 
            const msgMax = item.get('data').n;
            const msgRepeatSecs = item.get('data').t;
            newMessage = newMessage.set('msgMax', msgMax).set('msgRepeatSecs', msgRepeatSecs);
          };
          if( msgDelivery === 3 ) { 
            const msgMax = item.get('data').limit;
            newMessage = newMessage.set('msgMax', msgMax);
          };
          if( msgDelivery === 4 ) { 
            const msgMax = item.get('data').n;
            newMessage = newMessage.set('msgMax', msgMax);
          };
          //const prevMessage = rowData.get(index - 1);
          const prevMessage =  rowData.find(
            (r) => r.get('category') == "delay" 
          );
          if(prevMessage) {
            newMessage = this.updateDelayInMessage(prevMessage, newMessage);
          }
          messages = messages.push(newMessage);
        }
      });
    this.setState({ messages }, () => {
      if(reLoadMessages) {
        this.props.onMessageListChange(messages, this.props.index);
      };
	});
    if(reLoadMessages) this.removeDuplicateRows(messages);
  };

  removeDuplicateRows = (messages) => {
    if (messages.size >= 1) {
      let repeat = 0;
      let index = 0;
      let repeatList = List();
      messages.map((recItem, i) => {
        if (i > 0) {
          let prevItem = messages.get(i - 1);
          let currItem = messages.get(i);

          if (prevItem.equals(currItem)) {
            repeat = repeat + 1;

            if (repeat == 1) {
              let repeatItem = Map({ rep: repeat, ind: i });
              repeatList = repeatList.push(repeatItem);
              index = index + 1;
            } else {
              repeatList = repeatList.setIn([index - 1, 'rep'], repeat);
              repeatList = repeatList.setIn([index - 1, 'ind'], i);
            }
          } else {
            repeat = 0;
          }
        }
      });

      let indexToBeRemoved = [];
      repeatList.map((item) => {
        let repeatValue = item.get('rep');
        let in_value = item.get('ind');

        messages = messages.setIn([in_value, 'msgRepeat'], repeatValue);
        for (var i = 1; i <= repeatValue; i++) {
          indexToBeRemoved.push(in_value - i);
        }
      });

      messages = messages.filter(
        (item, index) => indexToBeRemoved.includes(index) == false
      );
    }
    this.setState({ messages });
  };

  componentDidMount() {
    const rowData = this.props.step.rowData;
    this.makeMesagesList(rowData);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const nextMessages = this.props.step || Map();
    const prevMessages = prevProps.step || Map();
    const reLoadMessages = nextMessages.equals(prevMessages);

    if (this.props.messages != prevProps.messages || !reLoadMessages) {
      const rowData = this.props.step.rowData;
      this.makeMesagesList(rowData, reLoadMessages);
    }
  };

  onHandleUnit = (value, index) => {
    let data = this.state.messages;
    data = data.setIn([index, 'msgDelayUnit'], value);
    this.setState({ messages: data }, () =>
      this.props.onMessageListChange(this.state.messages, this.props.index)
    );
  };

  handleMsgDeliveryTypeChange = (value, msgId, index) => {
    let data = this.state.messages;
    const prevMessageType = data.getIn([index, 'msgDelivery']);
    if(prevMessageType === 3) {
      const nextToSessionMsgLimitMsg = data.getIn([index + 1, 'msg']);
      data = data.setIn([index, 'msg'], nextToSessionMsgLimitMsg);
      data = data.delete(index + 1);
    }
    if(value === 3) {
      const prevMessage = data.getIn([index, 'msg']);
      const updatedMessage = newMessage.set('msg', prevMessage);
      data = data.insert(index + 1, updatedMessage);
    }
    data = data.setIn([index, 'msgDelivery'], value);
    this.setState({ messages: data }, () => {
      this.props.onMessageListChange(this.state.messages, this.props.index);
    });
  };

  handleMsgDelayChange = (value, index, msgTimeSpan) => {
    let data = this.state.messages;
    data = data.setIn([index, 'msgDelay'], value);
    this.setState({ messages: data }, () => {
      this.props.onMessageListChange(this.state.messages, this.props.index);
    });
  };

  handleMsgMaxChange = (value, index) => {
    let data = this.state.messages;
    data = data.setIn([index, 'msgMax'], value);
    this.setState({ messages: data }, () => {
      this.props.onMessageListChange(this.state.messages, this.props.index);
    });
  };

  handleRepeatSecsChange = (value, index) => {
    let data = this.state.messages;
    data = data.setIn([index, 'msgRepeatSecs'], value);
    this.setState({ messages: data }, () => {
      this.props.onMessageListChange(this.state.messages, this.props.index);
    });
  };

  handleChangeTimeSpan = (value, msgDelay, msgDelayUnit, index) => {
    let data = this.state.messages;
    data = data.setIn([index, 'msgTimeSpan'], value);
    this.setState({ messages: data }, () => {
      this.handleMsgDelayChange(msgDelay, index, value);
    });
  };

  handleMsgChange = (msgId, msgDelivery, index, category_id) => {
    let data = this.state.messages;
    data = data.setIn([index, 'msg'], msgId);
    data = data.setIn([index, 'msgType'], category_id);
    const flag = (this.props.scenarioEdited.campaign_type_id === 0 && category_id === 1) ? true : false; 
    this.setState({ messages: data, showWarningMessage: flag }, () => {
      this.handleMsgDeliveryTypeChange(msgDelivery, msgId, index);
    });
  };

  removeMessage = (index) => {
    let data = this.state.messages;
    data = data.remove(index);
    this.setState({ messages: data }, () => {
      this.props.onMessageListChange(this.state.messages, this.props.index);
    });
  };

  deleteConditionhandle = (e, index, delConditionClassName) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      delConditionVisible: !this.state.delConditionVisible,
      delConditionId: index,
      delConditionClassName: delConditionClassName,
    });
  };

  closeDelConditionPopup = () => {
    this.setState({ delConditionVisible: false });
  };

  confirmDeleteCondition = () => {
    let rowData;
    if (this.state.delConditionId >= 0) {
      rowData = this.props.step.rowData.delete(this.state.delConditionId);
    } else {
      rowData = this.props.step.rowData.filterNot(
        (r) => r.className == this.state.delConditionClassName
      );
    }
    this.setState({ delConditionId: '', delConditionVisible: false }, () => {
      this.props.onChange(this.props.index, rowData);
    });
  };

  showViewMsgAction = (msg) =>
    msg != '-1' &&
    msg != null &&
    this.getMsgDetails(msg).get('message_sub_category_id') != 6;

  filterMessagesByCampaignType = (m) => {
    const campaignType = this.props.scenarioEdited.campaign_type_id;
    return campaignType === 0 ? true : m.message_category_id === parseInt(campaignType);
  }

  render() {

    const messagesOptions = this.props.messages.filter(this.filterMessagesByCampaignType);
   
    return (
      <React.Fragment>
        {this.state.messages.map((item, index) => {
          const msgDetails = item.toJS();
          const isFreqCapSelected = msgDetails.msgDelivery === 2;
          const isSessionLimitSelected = msgDetails.msgDelivery === 3;
          const isShowMessageNumTimesSelected = msgDetails.msgDelivery === 4;
          const freqCapOrSessionLimitOrShowNumtimesSelected = isFreqCapSelected || isSessionLimitSelected || isShowMessageNumTimesSelected;
          const prevMsg = this.state.messages.get(index - 1);
          const prevMsgSessionMsgLimit = prevMsg && prevMsg.get('msgDelivery') === 3;

          let selectedMsgDelivery;
          let msgIndex;
          let selectedMessage;
          let message = item.get('msg');
          if(isSessionLimitSelected) {
            const nextMsg = this.state.messages.get(index + 1) || Map({ msgDelivery: 0, msg: -1 });
            selectedMsgDelivery = nextMsg.get('msgDelivery');
            msgIndex = index + 1;
            selectedMessage = nextMsg.get('msg');
            message = selectedMessage;
          } else {
            selectedMsgDelivery = item.get('msgDelivery');
            msgIndex = index;
            selectedMessage = item.get('msg');
          }

          const msg = messagesOptions.find(m => m.id === selectedMessage);
          if(!msg) {
            selectedMessage = '-1';
            message = '-1';
          }

          const messageSelection = (
            <MessageDropDownSearch
              title="Select Message"
               options={messagesOptions}
               onSelect={(value, message_category_id) => this.handleMsgChange(value, selectedMsgDelivery, msgIndex, message_category_id)}
               defaultValue="-1"
               value={selectedMessage || '-1'}
            />
           );

          if(prevMsgSessionMsgLimit) {
            return null;
          }

          return (
          <div onClick={this.props.onChangeStep} className="conditions-box" key={index}>
            <div className="select-container-main">
              {index >= 1 && (
                <DeleteOutlined onClick={() => this.removeMessage(index)} style={styles.iconStyle}></DeleteOutlined>
              )}
              <div
                className="select-container"
                style={styles.selectContainerMessageDelivery}
              >
                <span className="condition-title">MESSAGE DELIVERY</span>
                <MessageDropDown
                  title="Show Message"
                  options={messageConditions}
                  onSelect={(value) =>
                    this.handleMsgDeliveryTypeChange(
                      value,
                      item.get('msg'),
                      index
                    )
                  }
                  defaultValue="0"
                  value={item.get('msgDelivery')}
                />
                <div className='select-message-container'>
                  {messageSelection}
                  {this.showViewMsgAction(message) && (
                    <EyeFilled onClick={() => this.viewMessage(selectedMessage)}/>               
                  )}
                </div>
                {this.state.showWarningMessage && <Alert
                  className='alert-message'
                  message={<>Don't forget to add a Consent Status condition.</>}
                  type="info"
                  showIcon
                />}
              </div>
              { freqCapOrSessionLimitOrShowNumtimesSelected && <div
                className="select-container"
                style={styles.selectContainerMsgCapAndLimit}
              >
                <div className="condition-title">&nbsp;</div>
                <div className="message-type-option">
                <div className="select-container">
                    <label className="label-style">{(isFreqCapSelected || isShowMessageNumTimesSelected) && 'Times'} &nbsp;</label>
                    <InputNumber
                      min={1}
                      defaultValue={1}
                      onChange={(value) => this.handleMsgMaxChange(value, index)}
                      value={item.get('msgMax')}
                    />
                </div>
                { isFreqCapSelected && <div className="select-container">
                    <label className="label-style">Times Every (sec)</label>
                    <InputNumber
                      min={1}
                      defaultValue={1}
                      onChange={(value) => this.handleRepeatSecsChange(value, index)}
                      value={item.get('msgRepeatSecs')}
                    />
                </div>
                }
                </div>
              </div>}
              <div
                className="select-container"
                style={styles.selectContainerMessageDelay}
              >
                <div className="condition-title">MESSAGE DELAY</div>
                <div className="msg-delay-wrapper">
                  <MessageDropDown
                    title="Hold Message By"
                    options={messageDelay}
                    onSelect={(value) =>
                      this.handleMsgDelayChange(
                        value,
                        index,
                        item.get('msgTimeSpan')
                      )
                    }
                    defaultValue={3}
                    value={item.get('msgDelay')}
                  />
                  {item.get('msgDelay') == 0 && (
                    <MessageDropDown
                      title={<span>&nbsp;</span>}
                      options={messageDelayUnit}
                      onSelect={(value) => this.onHandleUnit(value, index)}
                      defaultValue="days"
                      value={item.get('msgDelayUnit')}
                    />
                  )}
                  {item.get('msgDelay') != 3 && (
                    <div className="select-container">
                      <label className="label-style">&nbsp;</label>
                      <InputNumber
                        min={1}
                        defaultValue={1}
                        onChange={(value) =>
                          this.handleChangeTimeSpan(
                            value,
                            item.get('msgDelay'),
                            item.get('msgDelayUnit'),
                            index
                          )
                        }
                        value={item.get('msgTimeSpan')}
                      />
                    </div>
                  )}
                </div>
                {(delayUnits.includes(item.get('msgDelayUnit'))) && <Alert
                  className='time-delay-alert'
                  message='Please note that time delays are subject to a potential 20 minute variance due to server caching. This means that if you set up a time delay for 1 hour you could expect to receive a message again anywhere between 40 mins to 80 mins.'
                  type="warning"
                  showIcon
                  icon={<WarningFilled />}
                />}
                <div className="select-container">
                    <label className="label-style">&nbsp;</label>
                    <div>
                        {index + 1 === this.state.messages.size &&
                           item.get('msgType') === 3 && (
                              <Button
                                style={styles.btnAddNewMessage}
                                type="primary"
                                size='small'
                                onClick={() => this.addMessage(index)}>
                                Add New Message
                              </Button>
                        )}
                    </div>
                </div>
              </div>
              {item.get('msgType') === 3 && (
                <React.Fragment>
                  <Divider type="vertical" style={styles.dividerStyle} />

                  <div className="select-container repeat-msg">
                    <span className="condition-title">REPEAT MESSAGE</span>

                    <MessageDropDown
                      title="Repeat"
                      options={messageRepeat}
                      onSelect={(value) => this.handleChange(value, index)}
                      defaultValue="No Repeat"
                      value={item.get('msgRepeat')}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        )})}
        {this.state.showMessage && (
          <Modal
            centered
            onCancel={() => this.setState({ showMessage: false })}
            title={<div>{this.state.viewMsg.get('description')}</div>}
            footer={null}
            visible={this.state.showMessage}
            wrapClassName="scenario-modal-wrapper"
            closeIcon={
              <React.Fragment>
                Close Preview <ArrowRightOutlined />
              </React.Fragment>
            }
          >
            {this.state.viewMsg.get('message_category_id') != 0 && (
              <ViewMessage
                viewMsg={this.state.viewMsg}
                siteId={parseInt(getParameterByName('site_id', window.location))}
              />
            )}
            {this.state.viewMsg.get('message_category_id') == 0 &&
              this.state.viewMsg.message_config && (
                <MessagePreview
                  choiceConfig={this.state.viewMsg.choice_config}
                  messageConfig={this.state.viewMsg.message_config}
                  variables={this.state.viewMsg.variables}
                  siteScreenshotUrl={
                    this.state.viewMsg.message_config.data.userData
                      .customScreenshotUrl
                  }
                />
              )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
