import PropTypes from 'prop-types';
import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { message, Button } from 'antd';
import classNames from 'classnames';
import { MessageVariables } from '../../../../records/message_records';

export default class extends React.Component {
  static propTypes = {
    variables: PropTypes.instanceOf(MessageVariables),
    openVariablesModal: PropTypes.func.isRequired,
    inCustomCSS: PropTypes.bool,
  };

  static defaultProps = {
    variables: new MessageVariables({}),
    inCustomCSS: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.variables !== nextProps.variables) {
      return { variables: nextProps.variables };
    } else {
      return null;
    }
  }

  state = {
    expanded: this.props.inCustomCSS,
  }

  copyToClipboard = (name) => {
    const textarea = this[name];
    textarea.select();
    document.execCommand('copy');
    message.success(`variable ${ name } is copied`);
  }

  render() {
    let content;
    if (this.state.expanded) {
      content = (
        <div
          className={ classNames(
            'content',
            this.props.inCustomCSS ? 'in-custom-css' : '',
          )}
          onMouseLeave={ () => {
            if (!this.props.inCustomCSS) {
              this.setState({ expanded: false });
            }
          }}
        >
          <div className='variables'>
            {
              this.props.variables && this.props.variables.defaultVariables.map(v => (
                <div className='name-and-value' onClick={ () => this.copyToClipboard(v.get('name')) }>
                  <textarea
                    ref={(textarea) => {
                      const name = v.get('name');
                      this[name] = textarea;
                    }}
                    className='name'
                    value={ v.get('name') }
                  />
                  <div className='value'>({ v.get('value') })</div>
                </div>
              ))
            }
          </div>
          <Button onClick={ this.props.openVariablesModal }>+ Add new variable</Button>
        </div>
      );
    }

    let expandIcon;
    if (!this.props.inCustomCSS) {
      expandIcon = (
        <PlusCircleOutlined onClick={ () => this.setState({ expanded: !this.state.expanded })} />
      );
    }

    return (
      <div className={ classNames(
        'add-variable-dropdown-modal',
        this.state.expanded ? 'expanded' : '',
      )}>
        { expandIcon }
        { content }
      </div>
    );
  }
}
