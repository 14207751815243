import React from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { EditOutlined, DeleteFilled, CopyOutlined } from '@ant-design/icons';
import { Infotip, Toggle } from '../../../styleguide';
import {TableFilterDropdown} from '../../../styleguide';
import { shallowEqual } from "react-redux";

export const loadColumns = (deleteReport, toggleActive, duplicateReport, definitions, filterOptions, setFilteredSiteIds, setShowPropertiesDetailsModal) => {
  const tableData = [
    {
      title: 'Name',
      dataIndex: 'report_name',
      key: 'report_name',
      sorter: (a, b) => {
        if(a.report_name?.toLowerCase() < b.report_name?.toLowerCase()) { return -1; }
        if(a.report_name?.toLowerCase() > b.report_name?.toLowerCase()) { return 1; }
        return 0;
      },
      render: (name) => (
        <div style={{maxWidth: "15vw"}}>
          {name}
        </div>
      )
    },
    {
      title: 'Campaign Type',
      dataIndex: 'job_def_id',
      key: 'job_def_id',
      render: (col) => {
        let jobDef;
        if(col === 11){
          jobDef = "GDPR"
        }else if(col === 13){
          jobDef = "US Privacy (Legacy)"
        }else if(col === 15){
          jobDef = "US Multi State Privacy"
        }else{
          jobDef = "Custom Report"
        }
        return jobDef;
      },
      sorter: (a, b) => {
        if(a.job_def_id < b.job_def_id) { return -1; }
        if(a.job_def_id > b.job_def_id) { return 1; }
        return 0;
      }
      ,
      filters: [
        {
          text: "GDPR",
          value: 11,
        },
        {
          text: "US Privacy (Legacy)",
          value: 13,
        },
        {
          text: "US Multi State Privacy",
          value: 15
        }
      ],
      onFilter: (value, record) => record.job_def_id === value,
      filterDropdown: (props) => (
        <TableFilterDropdown {...props} selectAllOption />
      ),
      
    },
    {
      title: 'Recipient(s)',
      dataIndex: 'user_email',
      key: 'user_email',
      render: (col, data) => {
        let extra = null;
        const recipients = Array.isArray(data.user_email) ? data.user_email : data.user_email?.split(',');
        if (recipients?.length > 1) extra = `+${recipients.length - 1}`;
        return col?.length > 0 ? (
          <div>
            {recipients[0]}&nbsp;
            { extra ? <Infotip content={recipients?.join(', ')} placement="right" className="tooltip-popover maxWidth"><span className="rec-plus">{extra}</span></Infotip> : null }
          </div>
        ) : (
          <div>No recipients</div>
        );
      },
      sorter: (a, b) => {
        const recipeientsA = Array.isArray(a.user_email) ? a.user_email : a.user_email?.split(',');
        const recipeientsB = Array.isArray(b.user_email) ? b.user_email : b.user_email?.split(',');
        if(recipeientsA.length < recipeientsB.length) { return -1; }
        if(recipeientsA.length > recipeientsB.length) { return 1; }
        return 0;
      }
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (col, data) => {
        const freq = data.frequency;
        return freq ? freq.type.charAt(0).toUpperCase() + freq.type.slice(1).toLowerCase() : '';
      },
      sorter: (a, b) => {
        if(a.frequency?.type < b.frequency?.type) { return -1; }
        if(a.frequency?.type > b.frequency?.type) { return 1; }
        return 0;
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      defaultSortOrder : 'descend',
      render: (col, data) => moment(col).format('MMM-DD-YYYY'),
      sorter: (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
    },
    {
      title: "Properties",
      dataIndex: ["inclusion_filters", "site_id"],
      width: 150,
      align: "left",
      filters: filterOptions,
      shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord),
      onFilter: (filteredSiteId, record) => 
        filteredSiteId === "all_property"
          ? !( record.inclusion_filters.site_id && record.inclusion_filters.site_id.length)
          : record.inclusion_filters.site_id && record.inclusion_filters.site_id.includes(filteredSiteId),
      filterDropdown: (props) => (
          <TableFilterDropdown
            {...props}
            selectAllOption
            showSearch
            multiple
            dropdownClassName="us-privacy-filter"
            handleSelectedKeysOnSave={setFilteredSiteIds}
          />
        ),
      sorter: (a, b) => a.inclusion_filters.site_id?.length - b.inclusion_filters.site_id?.length,
      render: (siteIds, record) => {
        let renderValue = "All"
        if(siteIds && siteIds.length) {
          renderValue = siteIds.length
        }
        return (
          <Infotip
            className="usp-infotip count-container pointer"
            trigger="hover"
            title={null}
            content={"Click to view all properties"}
            onClick={() => setShowPropertiesDetailsModal(record)}
          >
            {renderValue}
          </Infotip>
        )
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (col, data) => {
        return (
          <div className="manage-actions">
            <span onClick={() => browserHistory.push('/manage_reports/'+data.id)}><Infotip content={"Edit"} className="tooltip-popover"><EditOutlined /></Infotip></span>
            <span onClick={() => deleteReport(data.id)}><Infotip content={"Delete"} className="tooltip-popover"><DeleteFilled /></Infotip></span>
            <span onClick={() => duplicateReport(data)}><Infotip content={"Duplicate Report"} className="tooltip-popover"><CopyOutlined /></Infotip></span>
            <span>
            <Infotip content={data.is_active ? "Deactivate Report" : "Activate Report"} className="tooltip-popover"><Toggle checked={data.is_active} onClick={() => toggleActive(data)} /></Infotip>
            </span>
          </div>

        )
      }
    },
  ];
  return tableData;
};

export const testData = [{
  name: 'GDPR Compliance Daily',
  recipients: ['chloeg@sourcepoint.com', 'mangelini@sourcepoint.com'],
  frequency: 'Monthly',
  createdDate: 1646678665001,
  lastRunDate: 1646678665000,
  nextRunDate: 1646678665000,
  nextRunStart: 1646678665000,
  nextRunEnd: 1646678665000
},
{
  name: 'Anti AdBlock Monthly',
  recipients: ['mangelini@sourcepoint.com'],
  frequency: 'Daily',
  createdDate: 1646678665002,
  lastRunDate: 1646678665000,
  nextRunDate: 1646678665000,
  nextRunStart: 1646678665000,
  nextRunEnd: 1646678665000
}];