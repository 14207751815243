import React, { Component } from 'react';
import { Radio } from '../../../../../../../../styleguide';

class Direction extends Component {
  updateDirection = (e) => {
    this.props.updateSetting(this.props.settingKey, e.target.value);
  }
  render() {
    return (
      <div className="set-direction">
        <h4>{this.props.settingsTitle}</h4>
        <div>
          <Radio.Group value={this.props.value} onChange={this.updateDirection}>
            <Radio.Button value="column">Vertical</Radio.Button>
            <Radio.Button value="row">Horizontal</Radio.Button>
          </Radio.Group>
        </div>
      </div>
    );
  }
}

export default Direction;
