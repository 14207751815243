import React from 'react';
import { Modal, Button } from 'antd';

const RestartCampaignModal = ({ visible, onClose, type }) => {
  return (
    <Modal
      visible={visible}
      title="Restart Campaign Warning"
      onClose={onClose}
      footer={[(
        <Button type="primary" onClick={onClose}>
          OK
        </Button>
      )]}
    >
      <div className="restart-campaign-modal-container">
        The {type} you've edited is part of an active campaign. In order to see the changes reflected, you must end the active campaign and start a new one.
      </div>
    </Modal>
  );
};

export default RestartCampaignModal;