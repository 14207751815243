import React, { Component } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

class Checkbox extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'spsg-checkbox ' + (props.className || '');

  if (props.purple) {
    props.className += ' purple';
    delete props.purple;
  }


    return <AntdCheckbox {...props} />;
  }
}

Checkbox.Group = AntdCheckbox.Group;
export default Checkbox;
