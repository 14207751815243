import React, { useEffect, useState, useMemo, useRef } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { Popconfirm, Button, Modal, message} from "../../../../../styleguide";
import { Steps, Divider } from 'antd';
const { Step } = Steps;

import ManagePropertiesAndScope from './ManagePropertiesAndScope';
import IabSettings from './IabSettings';
import CustomPurposesSettings from './CustomPurposesSettings';
import AddVendors from './AddVendors';
import VendorScanMapping from './VendorScanMapping';
import AddCookies from "./AddCookies";
import {
  getScannedVendors,
  getScannedVendorsV2,
  clearScannedVendors,
} from '../../../../../actions/vendor_list_actions_v2.js';
import { getParameterByName } from '../../../../utils';
import { addVendorsToVl, addCookiesToVl, VLW_STEPS } from './helper';
import { ConfirmAddVendorsFooter } from './ConfirmAddVendorsFooter';
import { PurposeV2 } from '../../../../../records/vendor_list_records';

function VendorListWizard(props) {
  const {
    visible,
    onCancel,
    vl,
    handleLocationSave,
    readOnly,
    sites,
    siteGroups,
    siteAccess,
    saveVendorList,
    iabSpecialFeatures,
    iabStacks,
    iabPurposes,
    allVendors,
    getVendorIdsForPurpose,
    scannedVendors,
    vendorWithProblems,
    pendingRequestsMap,
    currentUser: { accountId, accountFeatures },
    isSaveExitInCreate,
    hasExitedWizard,
    filterElement,
    isNonIab,
    visitedSteps,
    updateVisitedSteps
  } = props;
  const isVendorScanV2Enabled = accountFeatures.includes('vendor_scanning_v2');
  const isVendorScanV1Enabled = accountFeatures.includes('vendor_scanning');

  const [vendorList, updateVendorList] = useState(vl);
  const [intermediateVendorList, updateIntermediateVendorList] = useState(vl);

  const [current, setCurrent] = useState(0);
  const [exitStatus, updateExitStatus] = useState(false);
  const [mandatoryFieldError, updateMandatoryFieldError] = useState(null);
  const [mandatoryPropertiesFieldError, updateMandatoryPropFieldError] = useState({})
  const [intermediateCustomPurposes, updateIntermediateCustomPurposes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedVendors, updateSelectedVendors] = useState([]);
  const [selectedCookies, updateSelectedCookies] = useState([])
  const [selectedCookiesToBeRemoved, updatedSelectedCookiesToBeRemoved] = useState([])
  const [addCookieToDisclosure, updateAddCookieToDisclosure] = useState(isVendorScanV2Enabled);
  const [isAddVendorsConfirmationVisible, setAddVendorsConfirmationVisibility] = useState(false)
  const [resumeWizardFlow, setResumeWizardFlow] = useState({
    action: ()=>{},
    stepIndex: null
  }) 
  const addVendorsRef = useRef(null);
  const footerRef = useRef(null);
  const STEPS = isNonIab ?  VLW_STEPS.filter(s => s.title !== 'IAB Settings') : VLW_STEPS;

  const indexedSacnnedVendors = useMemo(() => {
    let indexVendors = scannedVendors.reduce(function (map, obj) {
      map[obj.id] = obj.vendorAdditionalDetails;
      return map;
    }, {});
    return indexVendors;
  }, [scannedVendors]);
  
  const getCurrentStep = (currentIndex) => {
    return STEPS[currentIndex].id
  };

  const setDefaultCustomPurposesNonIABVendorlist = (vendorList) => {
    let updatedVL = vendorList;
    if( window.location.pathname === '/consent_v2/vendor_lists/create' && isNonIab && vendorList.categories.size === 0) {
      updatedVL = vendorList.set('categories', new List([
        new PurposeV2({ name: 'Category 1', type: 'CUSTOM', disclosureOnly: true }),
        new PurposeV2({ name: 'Category 2', type: 'CUSTOM', defaultCustomLegalBasis: 'CONSENT' }),
        new PurposeV2({ name: 'Category 3', type: 'CUSTOM', defaultCustomLegalBasis: 'CONSENT' }),
      ])).set('vendorScanCategorizations', new List([
        new Map(
          {
            "vendorScanCategory": "Strictly Necessary",
            "vendorListCategoryName": "Category 1",
            "legalBasis": ""
          }),
        new Map({
          "vendorScanCategory": "Performance",
          "vendorListCategoryName": "Category 2",
          "legalBasis": ""
        }),
        new Map({
          "vendorScanCategory": "Targeting/Advertising",
          "vendorListCategoryName": "Category 3",
          "legalBasis": ""
        })
      ]))
    }
    return updatedVL;
  };

  useEffect(()=>{
    let updatedVendorList = setDefaultCustomPurposesNonIABVendorlist(vendorList);
    updateVendorList(updatedVendorList)
    if(window.location.pathname === '/consent_v2/vendor_lists/edit') {
      updateVisitedSteps(STEPS.map((v,i)=> i))
    }
    updateSelectedVendors(vendorList?.vendors?.map(v => v.id))
  },[])

  useEffect(()=>{
    if(isAddVendorsConfirmationVisible) footerRef.current.scrollIntoView(true);   
  },[isAddVendorsConfirmationVisible]);

  const updateVisitedStepIndex = (index) => {
    let updatedVisitedSteps = visitedSteps;
    updatedVisitedSteps.push(index);
    updateVisitedSteps([...new Set(updatedVisitedSteps)]);
  };

  const checkForVendorsToAdd = (callback, stepIndex) => {
    if(addVendorsRef.current.getCheckedVendorsToAdd().length) {
      setAddVendorsConfirmationVisibility(true);
      setResumeWizardFlow({
        action: callback,
        stepIndex: stepIndex
      })
      return true;
    }
    return false;
  }

  const handleConfirmAddVendors = async (button) => {
    switch(button){
      case 'yes':
        await addVendorsRef.current.addCheckedVendors();
        await addVendorsRef.current.clearCheckedVendors();
        message.success(<>Vendors Added Successfully</>)
        setAddVendorsConfirmationVisibility(false);
        break;
      case 'no':
        addVendorsRef.current.clearCheckedVendors();
        resumeWizardFlow.action(false, resumeWizardFlow.stepIndex);
        setAddVendorsConfirmationVisibility(false);
        break;
      case 'cancel':
        setAddVendorsConfirmationVisibility(false);
        break;
    }
  }

  const onChange = async (isCheckForSelectedVendors, stepIndex) => {
    const vlNameValid = checkErrorsPropertiesScope();
    if (!vlNameValid) { return; }

    if (getCurrentStep(current) === 'addVendors') {
      if(isCheckForSelectedVendors && checkForVendorsToAdd(onChange, stepIndex)) {
        return;
      }
      await checkVendorsNeedsToBeAdded();
    } else if(getCurrentStep(current) === 'addCookies') {
      addCookiesToVl(selectedCookies,selectedCookiesToBeRemoved, vendorList, updateVendorList)
      updatedSelectedCookiesToBeRemoved([])
    }

    setCurrent(stepIndex);
    updateVisitedStepIndex(stepIndex);
  };

  const renderIcon = (index) => {
    return <span>{index + 1}</span>;
  };

  const customDot = (dot, { status, index }) => <span>{index + 1}</span>;

  const checkErrorsPropertiesScope = () => {
    const errorMessages = {};
    const vlName = vendorList.name.trim();
    const vlAppliesGeo = vendorList?.appliesGeos;
    const vlSiteIds = vendorList?.siteIds;
    if (!vlName && getCurrentStep(current) === 'properties') {
      errorMessages.name = 'Please enter vendor list name';
      updateMandatoryPropFieldError(errorMessages);
    }
    if ((vlAppliesGeo.length === 0 || vlAppliesGeo === "") && getCurrentStep(current) === 'properties') {
      errorMessages.geoScope = 'Please select geo applies scope';
      updateMandatoryPropFieldError(errorMessages);
    }
    if (vlSiteIds.size === 0 && getCurrentStep(current) === 'properties') {
      errorMessages.properties = 'Please select properties to apply this list to to continue';
      updateMandatoryPropFieldError(errorMessages);
    }

    if(Object.keys(errorMessages).length !==0){
      return false;
    }else{
      updateMandatoryPropFieldError(errorMessages);
      return true;
    }
  };

  const checkErrorsCustomPurposes = () => {
    let error = false;
    if (
      intermediateCustomPurposes.some((p) => {
        const allTranslations = p.languageTranslations.some((t) => !(t.title));
        return !(p.title && p.legalBasis && !allTranslations);
      })
    ) {
      error = true;
      const errorMessage = 'Please fill all the fields';
      updateMandatoryFieldError(errorMessage);
    }
    return !error;
  };

  /**commenting the code for vendor scanning tab in vendor list wizard for as per ticket SP-8988  */
  // if (isVendorScanV2Enabled) {
  //   const vendorMappingStep = { title: 'Vendor Scan Mapping', subTitle: '(Optional)' };
  //   STEPS.push(vendorMappingStep);
  // }

  const checkVendorsNeedsToBeAdded = async () => {
    const checkIfVendorScanMapping = STEPS.findIndex((step) => step.title === 'Vendor Scan Mapping') === -1? false:true ;
    let updatedVendorList = vendorList;
    const vlVendorIds = vendorList.vendors.map(v => v.id).toJS();
    const allVendorsAdded = selectedVendors.length === 0 || selectedVendors.every(id => vlVendorIds.includes(id)) && vlVendorIds.every(id => selectedVendors.includes(id));
    const vendors = allVendors
      .filter((v) => selectedVendors.includes(v.id))
      .map((v) => {
        if (indexedSacnnedVendors[v.id]) {
          v = v.set("vendorAdditionalDetails",indexedSacnnedVendors[v.id])
        }
        return v;
      });

    if (!allVendorsAdded) {
      const args = {
        savedVl: vl,
        vendorList,
        getVendorIdsForPurpose,
        vendors,
        isVendorScanV2Enabled,
        addCookieToDisclosure,
        selectedVendors,
        scannedVendors,
        checkIfVendorScanMapping
      }
      updatedVendorList = await addVendorsToVl(args);
      updateVendorList(updatedVendorList);
    }
    return updatedVendorList;
  }

  const onNext = async (isCheckForSelectedVendors) => {
    if( getCurrentStep(current) === 'addVendors' && isCheckForSelectedVendors &&  checkForVendorsToAdd(onNext)) {
      return;
    }
    let moveToNextStep = true;
    switch (getCurrentStep(current)) {
      case 'properties':
        moveToNextStep = checkErrorsPropertiesScope();
        break;
      case 'customPurpose':
        moveToNextStep = checkErrorsCustomPurposes();
        break;
      case 'addVendors':
        moveToNextStep = selectedVendors.length ? await checkVendorsNeedsToBeAdded() : true;
        break;
      case 'addCookies':
        moveToNextStep = selectedCookies.length ? addCookiesToVl(selectedCookies,selectedCookiesToBeRemoved, vendorList, updateVendorList) : true;
        updatedSelectedCookiesToBeRemoved([])
        break;
    }
    if (!moveToNextStep) {
      return;
    }

    setCurrent(current + 1);
    updateVisitedStepIndex(current + 1);
    updateMandatoryFieldError(null);
    updateIntermediateVendorList(vendorList);
    if (!isSaveExitInCreate) {
      saveVendorList({ isSaveExitInCreate: true });
    }
  };

  const onPrevious = (isCheckForSelectedVendors) => {
    if (getCurrentStep(current) === 'addVendors') {
      if(isCheckForSelectedVendors && checkForVendorsToAdd(onPrevious)) {
        return;
      }
      checkVendorsNeedsToBeAdded();
    } else if (getCurrentStep(current) === 'addCookies') {
      addCookiesToVl(selectedCookies,selectedCookiesToBeRemoved, vendorList, updateVendorList)
      updatedSelectedCookiesToBeRemoved([])
    }
    setCurrent(current - 1);
    updateVisitedStepIndex(current - 1);
  };

  const redirectToVlLists = () => {
    if (getParameterByName('non_iab', window.location) === 'true') {
      browserHistory.replace('/non-iab/vendor_lists');
    } else {
      browserHistory.replace('/consent_v2/vendor_lists');
    }
  };

  const saveAndClose = async (isCheckForSelectedVendors) => {
    const vlNameValid = checkErrorsPropertiesScope();
    if (!vlNameValid) { return; }
    let updatedVendorList = vendorList;
    if (getCurrentStep(current) === 'addVendors') {
      if(isCheckForSelectedVendors && checkForVendorsToAdd(saveAndClose)) {
        return;
      }
      updatedVendorList = await checkVendorsNeedsToBeAdded();
      
    } else if (getCurrentStep(current) === 'addCookies') {
      updatedVendorList = await addCookiesToVl(selectedCookies,selectedCookiesToBeRemoved, vendorList, updateVendorList);
      updatedSelectedCookiesToBeRemoved([])
    } 
    saveVendorList({ vl: updatedVendorList, isSaveExitInCreate: true, hasExitedWizard: true, });
    onCancel();
  };

  const title = (
    <div>
      Are you sure you want to cancel? <br />
      Changes made so far will not be saved and you will be exiting <br />
      vendor list wizard flow.
    </div>
  );

  const exitWizardAndSaveVl = (updatedVl) => {
    const isMainVlUpdatedVSame = (JSON.stringify(vl.toJS()) === JSON.stringify(updatedVl.toJS()));
    if (!isMainVlUpdatedVSame) {
      saveVendorList({ vl: updatedVl });
    }
    onCancel();
  }

  const vlUpdationCheck = () => {
    let cancelButton;
    const editId = getParameterByName('id');
    const isMainVlUpdatedVSame = (JSON.stringify(vl.toJS()) === JSON.stringify(vendorList.toJS()));
    const updationOccuredBtwSteps = (JSON.stringify(intermediateVendorList.toJS()) === JSON.stringify(vendorList.toJS()));
    const multipleStepsVisited = visitedSteps.length >= 2;
    const okAction = editId || isSaveExitInCreate ? onCancel : redirectToVlLists;

    let noUpdation;
    let noPopUpAction;
    let popUpAction;
    if (multipleStepsVisited) {
      noUpdation = updationOccuredBtwSteps;
      noPopUpAction = exitWizardAndSaveVl.bind(this, vendorList);
      popUpAction = exitWizardAndSaveVl.bind(this, intermediateVendorList);
    } else {
      noUpdation = isMainVlUpdatedVSame;
      noPopUpAction = okAction;
      popUpAction = okAction;
    }

    if (noUpdation) {
      cancelButton = (
        <Button onClick={noPopUpAction}>
          Cancel
        </Button>
      );
    } else {
      cancelButton = (
        <Popconfirm
          overlayClassName="exit-wizard-confirmation"
          title={title}
          visible={exitStatus}
          trigger="hover"
          icon={null}
          onConfirm={() => popUpAction()}
          onCancel={() => updateExitStatus(false)}
          onOpenChange={(visible) => updateExitStatus(visible)}
          okText="Yes"
          cancelText="No"
        >
          <Button className="btn-cancel">Cancel</Button>
        </Popconfirm>
      );
    }

    return cancelButton;
  };

  const conditionalCloseModal = () => {
    const editId = getParameterByName('id');
    if (editId || hasExitedWizard) {
      saveAndClose(true)
    } else {
      onCancel();
    }
  };

  const renderComponent = () => {
    let component;
    switch (getCurrentStep(current)) {
      case 'properties':
        component = (
          <ManagePropertiesAndScope
            updateVendorList={updateVendorList}
            vendorList={vendorList}
            handleLocationSave={handleLocationSave}
            readOnly={readOnly}
            sites={sites}
            siteGroups={siteGroups}
            mandatoryFieldError={mandatoryPropertiesFieldError}
            siteAccess={siteAccess}
          />
        );
        break;
      case 'iabSettings':
        component = (
          <IabSettings
            readOnly={readOnly}
            updateVendorList={updateVendorList}
            vendorList={vendorList}
            iabStacks={iabStacks}
            iabPurposes={iabPurposes}
            iabSpecialFeatures={iabSpecialFeatures}
            isNonIab={isNonIab}
            filterElement={filterElement}
          />
        );
        break;
      case 'customPurpose':
        component = (
          <CustomPurposesSettings
            mandatoryFieldError={mandatoryFieldError}
            intermediateCustomPurposes={intermediateCustomPurposes}
            updateIntermediateCustomPurposes={updateIntermediateCustomPurposes}
            updateVendorList={updateVendorList}
            vendorList={vendorList}
            setSelectedCategories={setSelectedCategories}
            selectedCategories={selectedCategories}
            iabPurposes={iabPurposes}
          />
        );
        break;
      case 'addVendors':
        component = (
          <AddVendors
            ref={addVendorsRef}
            selectedVendors={selectedVendors}
            updateSelectedVendors={updateSelectedVendors}
            isVendorScanV2Enabled={isVendorScanV2Enabled}
            isVendorScanV1Enabled={isVendorScanV1Enabled}
            isScanningVendors={pendingRequestsMap.get('scannedVendors')}
            scannedVendors={scannedVendors}
            indexedSacnnedVendors={indexedSacnnedVendors}
            vendorWithProblems={vendorWithProblems}
            getScannedVendorsV2={props.getScannedVendorsV2}
            getScannedVendors={props.getScannedVendors}
            accountId={accountId}
            savedVl={vl}
            globalVendors={allVendors}
            updateVendorList={updateVendorList}
            vendorList={vendorList}
            isNonIab={isNonIab}
            filterElement={filterElement}
          />
        );
        break;
      case 5:
        component = (
          <VendorScanMapping
            globalVendors={allVendors}
            scannedVendors={scannedVendors}
            savedVl={vl}
            selectedVendors={selectedVendors}
            addCookieToDisclosure={addCookieToDisclosure}
            updateAddCookieToDisclosure={updateAddCookieToDisclosure}
            iabPurposes={iabPurposes}
            updateVendorList={updateVendorList}
            vendorList={vendorList}
          />
        );
        break;
        case 'addCookies':
          component = (
            <AddCookies
              selectedVendors={selectedVendors}
              globalVendors={allVendors}
              iabPurposes={iabPurposes}
              scannedVendors={scannedVendors}
              intermediateVendorList={intermediateVendorList}
              intermediateCustomPurposes={intermediateCustomPurposes}
              updateVendorList={updateVendorList}
              vendorList={vendorList}
              updateSelectedCookies={updateSelectedCookies}
              updatedSelectedCookiesToBeRemoved={updatedSelectedCookiesToBeRemoved}
              vl={props.vl}
              isNonIab={isNonIab}
            />
          );
        break;
    }
    return component;
  };
  const getStatus = (index) => {
    if (index === current) {
      return 'process';
    } else if (visitedSteps.includes(index)) {
      return 'finish';
    } else {
      return 'wait';
    }
  };

  const isLastStep = STEPS.length === current + 1;
  const cancelButton = vlUpdationCheck();
  const nextButton = !isLastStep && (
    <Button type="primary" onClick={() => onNext(true)}>
      Next - {STEPS[current + 1].title}
    </Button>
  );
  const prevButton = current > 0 && (
    <Button  onClick={() => onPrevious(true)}>
      Previous - {STEPS[current - 1].title}
    </Button>
  );
  const closeAndSaveButton = !isLastStep && (
    <Button type="primary" onClick={() => saveAndClose(true)}>
      Save &amp; Close
    </Button>
  );
  const closeButton = isLastStep && (
    <Button type="primary" onClick={() => saveAndClose(true)}>
      Done
    </Button>
  );

  const footer = (
    <div className="footer-wrapper" ref={footerRef}>
      {isAddVendorsConfirmationVisible ? (
        <ConfirmAddVendorsFooter
          handleConfirmAddVendors={handleConfirmAddVendors}
        />
      ) : (
        <>
          <div className="btns-left">
            {cancelButton}
            {closeAndSaveButton}
          </div>
          <div>
            {prevButton}
            {nextButton}
            {closeButton}
          </div>
        </>
      )}
    </div>
  );

  const component = renderComponent();

  const disabledTailClass = (stepIndex) => {
    return Math.max(...visitedSteps) == stepIndex && getStatus(stepIndex) == 'finish' ? 'disabledTailClass' : '';
  };

  const enabledTailClass = (stepIndex) => {
    return stepIndex == current && Math.max(...visitedSteps) > stepIndex ? 'enabledTailClass' : '';
  }

  return (
      <Modal
      wrapClassName="vendor-list-wizard"
      title={`Vendor List Wizard: ${vendorList.name}`}
      visible={visible}
      width='72%'
      maskClosable={false}
      footer={footer}
      onCancel={conditionalCloseModal}
      >
      <Steps size="small" progressDot={customDot} current={current} onChange={(stepIndex) => onChange(true, stepIndex)}>
        {STEPS.map((step, i) => (
          <Step className={[disabledTailClass(i), enabledTailClass(i)]} disabled={!visitedSteps.includes(i)} key={i} status={getStatus(i)} icon={renderIcon(i)} title={step.title} subTitle={step.subTitle} />
        ))}
      </Steps>
      <Divider />
      {component}
      </Modal>
  );
}

const mapStateToProps = function (store) {
  return {
    scannedVendors: store.vendorListState.getIn(['scannedVendors', 'value']),
    vendorWithProblems: store.vendorListState.getIn(['vendorWithProblems', 'value']),
    pendingRequestsMap: new Map({
      scannedVendors: store.vendorListState.getIn(['scannedVendors', 'pending']),
    }),
  };
};

export default connect(mapStateToProps, {
  getScannedVendorsV2,
  clearScannedVendors,
  getScannedVendors,
})(VendorListWizard);