import * as d3 from 'd3';

import RuleService from './link_color_rules/rule_service';

export default function DrawHierarchy(
  data = [],
  {
    ref = null,
    chart = {
      gap: 200,
    },
    scaleValue,
    filtersSet,
    onExpandNode,
    onToggle,
    onScale,
    onUpdateTreeSize,
    isExpandable,
  },
) {
  const margin = { top: 20, left: 26 };
  const labelWidth = 80;
  const labelHeight = 34;
  const width = '800';
  const height = '450';
  const NODE_EXPAND_CLASS = 'node-children-expand';
  const NODE_CLASS = 'vendor-trace-node-item';
  const NODE_CONTAINER_CLASS = `${NODE_CLASS}-container`;

  const rulesService = new RuleService();

  function pluck(array, key) {
    return array.map((o) => o[key]);
  }

  function renderCustomNodeView(d) {
    const svgNamespace = 'http://www.w3.org/2000/svg';
    const foreignObject = document.createElementNS(svgNamespace, 'foreignObject');
    const container = document.createElement('div');
    container.setAttribute('class', NODE_CONTAINER_CLASS);
    foreignObject.appendChild(container);
    const label = document.createElement('div');
    label.setAttribute('class', NODE_CLASS);
    label.style.borderColor = rulesService.getBorderColor(filtersSet, d);
    label.style.backgroundColor = rulesService.getNodeBackgroundColor(filtersSet, d);
    label.style.color = rulesService.getTextColor(filtersSet, d);
    label.style.opacity = rulesService.getOpacity(filtersSet, d);
    label.append(d.data.name);
    container.appendChild(label);
    if (isExpandable(d)) {
      const expandItem = document.createElement('div');
      expandItem.setAttribute('class', NODE_EXPAND_CLASS);
      expandItem.setAttribute('data-key', d.data.attributes.map_key);
      expandItem.append(d.data.children.length ? '-' : '+');
      container.appendChild(expandItem);
    }
    return foreignObject;
  }

  function handleToggle(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    onToggle(evt.target.dataset.key);
  }

  function handleHierarchyDimensionUpdate(dimensions) {
    onUpdateTreeSize({
      width: dimensions.width + chart.gap,
      height: dimensions.height + 100,
    });
  }

  /* Hierarchy data passed as array */
  const nodes = d3.hierarchy(data, (d) => d.children);

  /* Tree generation  */
  d3.tree().size([height, width]).nodeSize([40, 235])(nodes);

  const nodeMap = {};
  nodes.descendants().forEach((d) => {
    if (nodeMap[d.depth] === undefined || d.x < nodeMap[d.depth]) {
      nodeMap[d.depth] = d.x;
    }
    d.y = d.depth * 230;
    d.x -= nodeMap[d.depth];
  });

  const svg = d3.select(ref);
  const g = svg
    .append('g')
    .attr('transform', `translate(${margin.left + labelWidth / 2},${margin.top + labelHeight})`)
    .append('g')
    .attr('transform', `translate(0,0) scale(${scaleValue})`);

  const depthHash = _.uniq(pluck(nodes.descendants(), 'depth')).sort();

  /* Legend */
  const levelSVG = d3
    .select(ref)
    .select('g')
    .append('g')
    .attr('transform', `translate(-${margin.left}, -${labelHeight}) scale(${scaleValue})`)
    .attr('class', 'levels-svg');

  levelSVG
    .selectAll('g.level')
    .data(depthHash)
    .enter()
    .append('g')
    .attr('class', 'level')
    .attr('transform', (d) => `translate(${d * 230 - margin.left / 2},0)`)
    .append('text')
    .text((d) => `Layer ${Number(d) + 1}`);

  g.selectAll('.link')
    .data(nodes.descendants().slice(1))
    .enter()
    .append('path')
    .attr('class', 'vendor-trace-custom-link')
    .style('stroke', (d) => rulesService.getStrokeColor(filtersSet, d))
    .style('opacity', (d) => rulesService.getOpacity(filtersSet, d))
    .attr(
      'd',
      (d) =>
        `M${d.y - labelWidth / 2},${d.x}C${(d.y + d.parent.y) / 2},${
          d.x
        } ${(d.y + d.parent.y) / 2 - labelWidth / 2},${d.parent.x} ${d.parent.y + labelWidth / 2},${d.parent.x}`,
    );

  /* Render node */
  const node = g
    .selectAll('.node')
    .data(nodes.descendants())
    .enter()
    .append('g')
    .attr('class', (d) => `node${d.children?.length ? ' node--internal' : ' node--leaf'}`)
    .attr('transform', (d) => `translate(${d.y},${d.x})`)
    .on('click', (event, d) => {
      onExpandNode(d.data);
    });

  node
    .append(renderCustomNodeView)
    .attr('x', -labelWidth / 2)
    .attr('y', -labelHeight / 2)
    .attr('width', (d) => (d?.children?.length ? labelWidth + 28 : labelWidth))
    .attr('height', labelHeight)
    .attr('class', `${NODE_CONTAINER_CLASS}-foreignObject`);

  /* Toggle Node by map-key */
  g.selectAll(`.node > .${NODE_CONTAINER_CLASS}-foreignObject > .${NODE_CONTAINER_CLASS} > .${NODE_EXPAND_CLASS}`).on(
    'click',
    handleToggle,
  );

  handleHierarchyDimensionUpdate(d3.select('#vendor-trace-svg > g').node().getBoundingClientRect());

  function zoomed({ transform }) {
    g.attr('transform', transform);
    levelSVG.attr('transform', `translate(-${margin.left}, -${labelHeight}) scale(${transform.k})`);
  }
  const zoom = d3
    .zoom()
    .on('zoom', zoomed)
    .on('end', () => handleHierarchyDimensionUpdate(d3.select('#vendor-trace-svg > g').node().getBoundingClientRect()));

  d3.select('#zoom_in').on('click', () => {
    onScale(1);
    zoom.scaleTo(svg.transition().duration(750), 1, [0, 0]);
    document.getElementById('vendorTraceData').scrollIntoView({ behavior: 'smooth', block: 'start' });
  });

  d3.select('#zoom_out').on('click', () => {
    onScale(0.6);
    zoom.scaleBy(svg.transition().duration(750), 0.6, [0, 0]);
    document.getElementById('vendorTraceData').scrollIntoView({ behavior: 'smooth', block: 'start' });
  });
}
