import React from 'react';
import { List, Map } from 'immutable';
import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import { Select, Button, Radio, Input } from '../../../../../styleguide'
import { styles } from '../styles';
import { StringValueKVGate } from '../../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { SCENARIO_STEPS } from '../../../scenarios/versionTwo/components/steps';
import { kvStringClass } from '../../TemplateBuilder/Data';

const { Option } = Select;

const newrow = Map({
  allow_on_satisfy: true,
  type: 'contain',
  v: '',
  k: '',
});
export default class SytemKeyValuePairTargeting extends React.Component {
  onSelectFilter = (inputString, option) => {
    return (
      inputString.toLowerCase() ===
      option.props.children.toLowerCase().slice(0, inputString.length)
    );
  };

  state = {
    inc: 1,
    rows: List([newrow]),
  };

  componentWillMount() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .filter((r) => r.get('className') == className);
    let data = List();
    if (getSavedData && getSavedData.size > 0 && this.props.isUpdate) {
      data = getSavedData.map((r, i) => {
        return Map({
          allow_on_satisfy: r.get('data').get('allow_on_satisfy'),
          type: r.get('data').get('type'),
          v: r.get('data').get('v'),
          k: r.get('data').get('k'),
        });
      });
    }
    this.setState({
      rows: getSavedData && data && data.size > 0 ? data : List([newrow]),
    });
  }

  onAddEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    let rowData = this.props.scenarioEdited.steps.get(this.props.activeStep)
      .rowData;
    this.state.rows.map((row) => {
      stepBlockData = stepBlockData.set(
        'data',
        new StringValueKVGate({
          k: row.get('k'),
          v: row
            .get('v'),
          type: row.get('type'),
          allow_on_satisfy: row.get('allow_on_satisfy'),
        })
      );
      rowData = rowData.unshift(stepBlockData);
    });
    this.props.onChange(this.props.activeStep, rowData);
  };

  onUpdateEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    let rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.filterNot((r) => r.className == kvStringClass);
    this.state.rows.map((row) => {
      stepBlockData = stepBlockData.set(
        'data',
        new StringValueKVGate({
          k: row.get('k'),
          v: row
            .get('v'),
          type: row.get('type'),
          allow_on_satisfy: row.get('allow_on_satisfy'),
        })
      );
      rowData = rowData.unshift(stepBlockData);
    });
    this.props.onChange(this.props.activeStep, rowData);
  };

  cancelCondition = () => {
    this.props.closePanel();
  };

  incrementRows = () => {
    let value = this.state.inc;
    value = value + 1;
    let rows = this.state.rows.push(newrow);
    this.setState({ rows: rows });
  };
  updateRow = (index, sName, newVal) => {
    const rows = this.state.rows.setIn([index, sName], newVal);
    this.setState({ rows });
  };
  deleteRow = (index) => {
    if (this.state.rows.size > 1) {
      const rows = this.state.rows.delete(index);
      this.setState({ rows });
    }
  };
  render() {
    const valueOption = List([
      Map({ label: 'contain', value: 'contain' }),
      Map({ label: 'match', value: 'match' }),
    ]);

    const options = valueOption.map((s) => (
      <Option key={s.get('value')}>{s.get('label')}</Option>
    ));
 
    const readOnly = this.state.rows.some(r => !(r.get('k') && r.get('k').match(/^$|.*\S+.*/) && r.get('v').size || r.get('v').length));

    return (
      <div style={styles.scenarioConditionPanelCol}>
        <div style={styles.scenarioInnerWrapperTwo}>
          <span className="condition-label-two">Condition:&nbsp;</span>
          <span className="condition-title"> {this.props.conditionLabel}&nbsp;</span>
          <span>(Note: Please enter comma seperated string values)</span>
        </div>
        <div
          style={{
            display: 'flex',
            flex: '1',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          {this.state.rows.map((item, index) => (
            <div key={index} style={styles.scenarioInnerWrapperTwo}>
              <div style={styles.scenarioItemFirst}>
                <span className="condition-label-two">Pages </span>
                <Radio.Group
                  onChange={(e) =>
                    this.updateRow(index, 'allow_on_satisfy', e.target.value)
                  }
                  style={{ marginTop: '10px', width: '200px' }}
                  defaultValue={true}
                  buttonStyle="solid"
                  value={item.get('allow_on_satisfy')}
                >
                  <Radio.Button value={true}>Include</Radio.Button>
                  <Radio.Button value={false}>Exclude</Radio.Button>
                </Radio.Group>
              </div>

              <div style={styles.scenarioItemWrapperFirst}>
                <span className="condition-label-two">Key</span>
                <Input
                  value={item.get('k')}
                  onChange={(e) => this.updateRow(index, 'k', e.target.value)}
                  style={{ marginTop: '10px' }}
                />
              </div>
              <div style={styles.scenarioItemWrapper}>
                <span className="condition-label-two">Value</span>
                <Select
                  value={item.get('type')}
                  onChange={(v) => this.updateRow(index, 'type', v)}
                  placeholder="Select"
                  style={{
                    marginTop: '10px',
                  }}
                  showArrow={true}
                >
                  {options.toJS()}
                </Select>
              </div>
              <div style={styles.scenarioItemWrapper}>
                <span className="condition-label-two">String </span>
                <Input
                  onChange={(e) => this.updateRow(index, 'v', e.target.value)}
                  value={item.get('v')}
                  style={{ marginTop: '10px' }}
                  dropdownClassName="hide-scenario-multiple-dropdown"
                />
              </div>
              <div style={styles.scenarioItemWrapperRow}>
                {this.state.rows.size > 1 && (
                  <CloseCircleFilled
                    style={{
                      fontSize: '18px',
                      color: '#411F90',
                      alignSelf: 'flex-end',
                      marginLeft: '20px',
                    }}
                    onClick={() => this.deleteRow(index)} />
                )}
                {index + 1 === this.state.rows.size ? (
                  <PlusCircleFilled
                    style={{
                      fontSize: '18px',
                      color: '#411F90',
                      alignSelf: 'flex-end',
                      marginLeft: '20px',
                    }}
                    onClick={() => this.incrementRows()}></PlusCircleFilled>
                ) : (
                  <PlusCircleFilled
                    style={{
                      fontSize: '18px',
                      color: '#411F90',
                      alignSelf: 'flex-end',
                      marginLeft: '20px',
                      opacity: '0',
                    }}></PlusCircleFilled>
                )}
              </div>
            </div>
          ))}
        </div>
        <Divider />
        <div style={styles.scenarioButtonWrapperTwo}>
          <Button
            onClick={
              this.props.buttontitle == 'Add'
                ? this.onAddEvent
                : this.onUpdateEvent
            }
            type="primary"
            size='small'
            disabled={readOnly}
          >
            {this.props.buttontitle}
          </Button>{' '}
          <Button
            onClick={() => this.cancelCondition()}
            style={styles.scenarioMarginLeft}
            type="secondary"
            font="oswald"
            size='small'
            ghost
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
