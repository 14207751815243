import PropTypes from 'prop-types';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';

const Option = Select.Option;

class SiteDropdown extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.list.isRequired,
    selectSites: PropTypes.func.isRequired,
    hasAllSites: PropTypes.bool,
    defaultValue: PropTypes.string,
    hasAllAccess: PropTypes.bool,
  }

  static defaultProps = {
    hasAllSites: true,
    defaultValue: '',
    hasAllAccess: true,
  }

  render() {
    let allSitesDescription = 'all properties';
    if (!this.props.hasAllAccess) {
      const tooltipText = `To activate analytics for more sites add domain names to "Analytics Domains" in account settings. Note - adding domains is only available for admins`;
      allSitesDescription = (
        <Tooltip placement="rightTop" title={tooltipText}>
          <p>all sites bellow <span><InfoCircleOutlined /></span></p>
        </Tooltip>
      );
    }

    const sites = this.props.sites.filterNot(s => {
      const domain = s.domain ? s.domain : s;
      return domain.includes('-group-');
    });

    return (
      <div className='sites-dropdown-container'>
        <Select
          className='sites-dropdown'
          defaultValue={ this.props.hasAllSites ? 'all sites' : this.props.defaultValue }
          dropdownMatchSelectWidth={ false }
          onChange={this.props.selectSites}
        >
          { this.props.hasAllSites ? (
            <Option key={ 'all sites' } value={ 'all sites' }>
              { allSitesDescription }
            </Option>
          ) : (
            null
          )}

          { sites.map(function (site) {
            if (typeof site === 'string') return <Option key={ site } value={ site }>{ site }</Option>;
            return <Option key={ site.id } value={ site.id.toString() }>{ site.domain || site.name }</Option>;
          })}
        </Select>
      </div>
    );
  }
}

export default SiteDropdown;
