import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import $ from 'jquery';
import { generateMessagePreviewUrl, getParameterByName } from '../../utils.js';
import { MessageConfigRecord } from '../../../records/message_config_records.js';
import { ChoiceConfigRecord } from '../../../records/choice_config_records.js';

export default class MessagePreview extends React.Component {

  static propTypes = {
    messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
    choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
    editorOpen: PropTypes.bool,
    importedFonts: PropTypes.string,
    importedStyles: PropTypes.string,
    importedLinks: PropTypes.string,
    siteScreenshotUrl: PropTypes.string,
    inEditMessage: PropTypes.bool,
  }

  static defaultProps = {
    importedFonts: '',
    importedStyles: '',
    importedLinks: '',
    siteScreenshotUrl: null,
    editorOpen: false,
    inEditMessage: false,
  }

  constructor(props) {
    super(props);
    this.generateHTML = this.generateHTML.bind(this);
  }

  componentDidMount() {
      this.checkIfIframeIsLoaded();
  }

  componentWillReceiveProps(nextProps) {
      if (nextProps !== this.props) {
          this.checkIfIframeIsLoaded();
          this.forceUpdate();
      }
  }

  checkIfIframeIsLoaded() {
    if (!this.props.messageConfig || !this.props.choiceConfig ) {
      return;
    }
    // Iframe load event not firing in Chrome and Safari when src is 'about:blank'
    // React open issue https://github.com/facebook/react/issues/6541
    var intervalId = setInterval(() => {
      if (this.props.messageConfig.type === 'redirect' || this.props.messageConfig.type === 'custom') {
        return;
      }
      var iframe = this.previewIframe;
      if (iframe.contentDocument && iframe.contentDocument.readyState  == 'complete') {
          clearInterval(intervalId);
          this.onIframeLoad();
      }
    },0);
  }

  replaceDefaultVariables = (string) => {
    const { defaultVariables } = this.props.variables;
    defaultVariables.forEach((defaultVariable) => {
      const escapedName = defaultVariable.get('name').replace(/\$\$/g, '\\$\\$');
      string = string.replace(new RegExp(escapedName, 'g'), defaultVariable.get('value'));
    });
    return string;
  }

  onIframeLoad() {
    if (this.props.messageConfig.type === 'redirect' || this.props.messageConfig.type === 'custom') {
      return;
    }
    const serverSideMessageHTML = this.props.messageConfig.getServerSideHTML(this.props.choiceConfig);
    let html;
    if (this.props.messageConfig.getIn(['data', 'subType']) === 'modal') {
      const withVeil = true;
      html = this.generateHTML(serverSideMessageHTML, withVeil);
    } else {
      html = this.generateHTML(serverSideMessageHTML);
    }
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId && this.props.variables && this.props.variables.defaultVariables) {
      html = this.replaceDefaultVariables(html);
    }

    const iframe = this.previewIframe;
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    const serverSideHTMLContainer = iframeDoc.createElement('div');
    serverSideHTMLContainer.innerHTML = html;

    if(this.props.importedFonts) {
      if (iframeDoc.head.firstElementChild === null) {
        var fonts = JSON.parse(this.props.importedFonts);
        var fontNames = Object.keys(fonts);

        var newStyle = iframeDoc.createElement('style');
        for(const fontName of fontNames) {
          newStyle.appendChild(iframeDoc.createTextNode('\
            @font-face {\
              font-family: ' + fontName + ' ;\
              src: ' + fonts[fontName] + ' ;\
            }\
            body {font-family: \"' + fontName + '\"}\
          '));
        }

        iframeDoc.head.appendChild(newStyle);
      }
    }

    if (this.props.importedStyles) {
      if (!iframeDoc.head.innerHTML.includes(this.props.importedStyles)) {
        iframeDoc.head.innerHTML = iframeDoc.head.innerHTML.concat(this.props.importedStyles);
      }
    }

    if (this.props.importedLinks) {
      if (!iframeDoc.head.innerHTML.includes(this.props.importedLinks)) {
        iframeDoc.head.innerHTML = iframeDoc.head.innerHTML.concat(this.props.importedLinks);
      }
    }

    iframeDoc.body.innerHTML = '';
    const bodyStyle = {
      margin: 0,
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      overflow: 'hidden',
      background: 'transparent',
    };
    Object.assign(iframeDoc.body.style, bodyStyle);

    iframeDoc.body.appendChild(serverSideHTMLContainer);

    const $iframe = $('#preview-iframe').contents();
    const $messagePreview = $iframe.find('#sp_message_id');
    // Hiding sp_choice_type_15 - Dismiss for preview.
    $iframe.find('.sp_choice_type_15').hide();
    $messagePreview.css('display', 'block');
    $iframe.find('.sp_message_dismiss').first().click(() => {
      iframeDoc.body.innerHTML = '';
    });
  }

  generateHTML(serverSideMessageHTML, withVeil) {
    let html = serverSideMessageHTML.msg.html + '<style>' + serverSideMessageHTML.css + '</style>';
    if (withVeil) html = serverSideMessageHTML.veil.html + html;
    return html;
  }

  render() {
    const editorOpen = { 'editor-open': this.props.editorOpen };
    const iframeClassName = classNames(
      'preview-iframe',
      this.props.messageConfig.getIn(['data', 'subType']) + '-subtype',
      editorOpen,
      {'full-screen': !this.props.inEditMessage && this.props.messageConfig.getIn(['data', 'subType']) !== 'inline'},
    );

    let iframe = <iframe src='about:blank' id='preview-iframe' ref={(c) => { this.previewIframe = c; }} className={ iframeClassName } />;

    let url = this.props.siteScreenshotUrl;
    if (url !== null) {
      url = generateMessagePreviewUrl(url);
    }

    let inlinePlaceholder;
    let iframeInlineLock;
    if ( this.props.messageConfig.getIn(['data', 'subType']) === 'inline' ) {
      inlinePlaceholder = <div className='inline-placeholder' />;
      url = 'http://' + window.location.host + '/images/messaging/inline_lock_preview_screenshot.jpg';
      iframeInlineLock = iframe;
      iframe = null;
    }

    const previewContainerClassName = classNames(
      'message-preview-container',
      editorOpen,
    );

    if (!this.props.inEditMessage && this.props.messageConfig.getIn(['data', 'subType']) !== 'inline') {
      return (
        <div className='message-preview' style={{ backgroundImage: 'url(' + url + ')' }}>
          <div className='iframe-full-screen-container'>
            { iframe }
          </div>
        </div>
      );
    }

    const background = url === null && this.props.messageConfig.getIn(['data', 'subType']) !== 'inline' ? (
      <div className='site-screenshot no-url'>
        { inlinePlaceholder }
        { iframeInlineLock }
      </div>
    ) :  (
      <div className='site-screenshot' style={{ backgroundImage: 'url(' + url + ')' }} >
        { inlinePlaceholder }
        { iframeInlineLock }
      </div>
    );

    return (
      <div className='message-preview'>
        { this.props.messageConfig.type === 'redirect' || this.props.messageConfig.type === 'custom' ? (
          <div className='redirect-not-available'>
            <h1>Preview is not availabe for {this.props.messageConfig.type}</h1>
            <h2>Select another message type to see a preview</h2>
          </div>
        ) : (
          <div className={previewContainerClassName}>
            <div className="site-screenshot-container">
              {background}
            </div>
            { iframe }
          </div>
        )}
      </div>
    );
  }
}
