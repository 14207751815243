import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message as notificationMessage, Spin } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';

import { MessageConfigRecord } from '../../../records/message_config_records.js';
import { ChoiceConfigRecord } from '../../../records/choice_config_records.js';
import MessagePreview from './MessagePreview';
import EditMessageFooter from './EditMessageFooter.js.jsx';
import MessageEditForm from './editor/MessageEditForm.jsx';
import AntDesignWrapper from '../../common/AntDesignWrapper';
import { getParameterByName } from '../../utils';
import { getSiteFonts } from '../../../actions/message_editor_actions';

import {
  getMessageWithDetail,
  createMessage,
  updateMessage,
  createSiteGroupMessage,
  updateSiteGroupMessage,
} from '../../../actions/message_actions';
import { getSitePrivacyManagerList, getSiteGroupPrivacyManagerList } from '../../../actions/privacy_manager_actions';
import { setCurrentSite, getAllSites, getSiteGroup } from '../../../actions/site_actions';
import { CONSTANTS } from '../../../constants.js';
import { Site } from '../../../records/site_records.js';
import { Message, MessageVariables } from '../../../records/message_records.js';

export class MessageEditPage extends React.Component {
    static propTypes = {
        pendingRequestsMap: ImmutablePropTypes.map.isRequired,
        allSites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
        getAllSites: PropTypes.func.isRequired,
        getMessageWithDetail: PropTypes.func.isRequired,
        createMessage: PropTypes.func.isRequired,
        updateMessage: PropTypes.func.isRequired,
        getSiteFonts: PropTypes.func.isRequired,
        importedFontsStylesLinks: PropTypes.object,
        isSPUser: PropTypes.bool.isRequired,
        getSiteGroup: PropTypes.func.isRequired,
        getSitePrivacyManagerList: PropTypes.func.isRequired,
    };

    static defaultProps = {
        importedFontsStylesLinks: {},
    };

    constructor(props) {
        super(props);
        debugger
        const messageId = parseInt(props.location.query.message_id);
        const message = props.messages.find(m => m.id === messageId);

        this.state = {
            siteDomain: (!props.location.query.site_group_id && props.allSites.size) ?
                         props.allSites.find(s => s.id === parseInt(props.location.query.site_id)).domain :
                         '',
            editorOpen: false,
            messageConfig: (message && message.message_config) || new MessageConfigRecord(),
            choiceConfig: (message && message.choice_config) || new ChoiceConfigRecord(),
            description: (message && message.description) || '',
            clientSideMessageId: null,
            variables: ((message && message.variables) || new MessageVariables),
            variablesModalVisible: false,
        };
    }

    componentDidMount() {
        this.props.getAllSites();

        const siteId = this.props.location.query.site_id;
        const messageId = this.props.location.query.message_id;
        const isSiteGroup = this.props.location.query.site_group_id ? true : false;

        this.props.setCurrentSite(siteId);

        // do not make this call when creating a new message
        if (messageId) {
            this.props.getMessageWithDetail(siteId, messageId, isSiteGroup);
        }
    }

    componentWillReceiveProps(nextProps) {

      if (this.props.pendingRequestsMap.get('allSites') && !nextProps.pendingRequestsMap.get('allSites')) {
        this.setState({
          siteDomain: !this.props.location.query.site_group_id && nextProps.allSites.find(
            s => s.id === parseInt(this.props.location.query.site_id),
          ).domain || '',
        });
      }
      if (nextProps.messages.length) debugger

      const messageId = parseInt(this.props.location.query.message_id);
      const message = this.props.messages.find(m => m.id === messageId);
      const nextPropsMessage = nextProps.messages && nextProps.messages.find(m => m.id === messageId);
      if ((!message || !message.isDetailLoaded()) && nextPropsMessage && nextPropsMessage.isDetailLoaded()) {
        debugger
        this.setState({
          description: nextPropsMessage.description,
          messageConfig: nextPropsMessage.message_config,
          choiceConfig: nextPropsMessage.choice_config,
          variables: nextPropsMessage.variables,
        }, () => {
          this.props.getSiteFonts(this.state.messageConfig.data.userData.customScreenshotUrl);
        });
      }

      if (this.props.pendingRequestsMap.get('messageCreatePending') && !nextProps.pendingRequestsMap.get('messageCreatePending')) {
          const message = nextProps.messages.find(m => m.clientSideMessageId === this.state.clientSideMessageId);
          let url = '/dialogue/messages/edit?site_id=' + message.site_id + '&message_id=' + message.id;
          const siteGroupId = this.props.location.query.site_group_id;
          if (siteGroupId) {
            url += '&site_group_id=' + siteGroupId;
          }
          // use replace to be able to use back button and land on messages page instead of message/create page
          browserHistory.replace(url);
          notificationMessage.success('Your message has been saved.', 5);
      }

      if (this.props.pendingRequestsMap.get('messageUpdatePending') && !nextProps.pendingRequestsMap.get('messageUpdatePending')) {
          notificationMessage.success('Your message has been saved.', 5);
      }
    }

    toggleShowVariablesModal = () => {
      this.setState({ variablesModalVisible: !this.state.variablesModalVisible });
    }

    updateMessageConfig = (messageConfig) => {
        this.setState({ messageConfig });
    };

    updateChoiceConfig = (choiceConfig) => {
        this.setState({ choiceConfig });
    };

    updateDescription = (description) => {
        this.setState({ description });
    };

    updateState = (obj) => {
      this.setState(obj);
    }

    checkChildErrors = () => {
        let hasErrors = false;

        this.props.form.validateFields((err) => {
            if (err) {
                hasErrors = true;
                notificationMessage.error('Your input contains errors - please correct them.', 5);
            }
        });

        return hasErrors;
    };

    onSave = () => {
        if (this.checkChildErrors()) return;

        const siteId = this.props.location.query.site_id;
        const messageId = this.props.location.query.message_id;
        const siteGroupId = this.props.location.query.site_group_id;

        const messageData = {
            description: this.state.description,
            message_config: this.state.messageConfig,
            choice_config: siteGroupId ? this.updateChoiceConfigWithPmVar() : this.state.choiceConfig,
            site_id: siteId,
            id: messageId,
        };

        let message = new Message(messageData);

        this.setState({ clientSideMessageId: message.clientSideMessageId }, () => {

            if (siteGroupId) {
              message = message.set('variables', this.state.variables);
              const accountId = this.props.currentUser.accountId;
              if (!messageId) {
                  this.props.createSiteGroupMessage(accountId, siteGroupId, message);
              } else {
                  this.props.updateSiteGroupMessage(accountId, siteGroupId, message);
              }
            } else {
              if (!messageId) {
                  this.props.createMessage(message);
              } else {
                  this.props.updateMessage(message);
              }
            }
        });
    };

    updateChoiceConfigWithPmVar = () => {
      const pmOption = this.state.choiceConfig
        .get('option_list')
        .find(choice => choice.choice_option_type === 12);
      if (pmOption) {
        const pmUrWithVar = pmOption.getIn(['option_data', 'privacy_manager_iframe_url']).split('privacy_manager_id=')[0] + '$$privacyManagerIdHiddenVarriable$$';
        const updatedPmOption = pmOption.setIn(['option_data', 'privacy_manager_iframe_url'], pmUrWithVar);
        return this.state.choiceConfig.update('option_list', (list => {
          return list.map(choice => {
            if (choice.choice_option_type === 12) {
              return updatedPmOption;
            } else {
              return choice;
            }
          })
        }));
      } else {
        return this.state.choiceConfig;
      }
    }

    toggleCustomCSS = () => {
        this.setState({ editorOpen: !this.state.editorOpen });
    };

    render() {
        const editing = this.props.route.editing;
        let editorOpenVeil;
        if (this.state.editorOpen) {
            editorOpenVeil = <div className='editor-open-veil' />;
        }
        const footer = (
            <EditMessageFooter
                editing={ editing }
                savePending={ this.props.pendingRequestsMap.get('messages') }
                handleSave={ this.onSave }
                messageConfigType={ this.state.messageConfig.get(CONSTANTS.TYPE) }
                form={ this.props.form }
                siteId={ this.props.location.query.site_id }
            />
        );

        let loading;
        if (this.props.pendingRequestsMap.get('getSiteFonts')) {
            loading = (
                <Spin tip="Please wait, fonts are being loaded..." />
            );
        }

        let importedFonts, importedStyles, importedLinks;
        if (this.props.importedFontsStylesLinks) {
          importedFonts = this.props.importedFontsStylesLinks.importedFonts;
          importedStyles = this.props.importedFontsStylesLinks.importedStyles;
          importedLinks = this.props.importedFontsStylesLinks.importedLinks;
        }

        return (
          <AntDesignWrapper>
            <div className='message-edit-page'>
              { editorOpenVeil }
              { loading }

              <MessagePreview
                inEditMessage
                messageConfig={ this.state.messageConfig }
                choiceConfig={ this.state.choiceConfig }
                editorOpen={ this.state.editorOpen }
                importedFonts={ importedFonts }
                importedStyles={ importedStyles }
                importedLinks={ importedLinks }
                siteScreenshotUrl={ this.state.messageConfig.data.userData.customScreenshotUrl }
                location={ this.props.location }
                variables={ this.state.variables }
              />

              <Form id='form'>
                <MessageEditForm
                  altpay={ this.props.currentUser.accountFeatures.includes('altpay') }
                  consent={ this.props.currentUser.accountFeatures.includes('consent') || this.props.currentUser.accountFeatures.includes('tcf_v2') }
                  samba={ this.props.currentUser.accountFeatures.includes('samba') }
                  choiceConfig={ this.state.choiceConfig }
                  description={ this.state.description }
                  editing={ editing }
                  editorOpen={ this.state.editorOpen }
                  form={ this.props.form }
                  messageConfig={ this.state.messageConfig }
                  siteDomain={ this.state.siteDomain }
                  toggleCustomCSS={ this.toggleCustomCSS }
                  updateDescription={ this.updateDescription }
                  updateMessageConfig={ this.updateMessageConfig }
                  updateChoiceConfig={ this.updateChoiceConfig }
                  isSPUser={ this.props.isSPUser }
                  accountId={ this.props.currentUser.accountId }
                  variables={ this.state.variables }
                  updateParentState={ this.updateState }
                  getSiteGroup={ this.props.getSiteGroup }
                  siteGroup={ this.props.siteGroup }
                  sites={ this.props.allSites }
                  toggleShowVariablesModal={ this.toggleShowVariablesModal }
                  variablesModalVisible={ this.state.variablesModalVisible }
                  getSitePrivacyManagerList={ this.props.getSitePrivacyManagerList }
                  getSiteGroupPrivacyManagerList={ this.props.getSiteGroupPrivacyManagerList }
                  sitePrivacyManagerList={ this.props.sitePrivacyManagerList }
                />
              </Form>
              { footer }
            </div>
          </AntDesignWrapper>
        );
    }
}

const wrappedMessageEditPage = Form.create()(MessageEditPage);

const mapStateToProps = function (store){

    let siteGroup;
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      const siteGroups = store.siteState.getIn(['siteGroups', 'value']);
      siteGroup = siteGroups.find(sg => sg.id === siteGroupId);
    }

    return {
        currentUser: store.accountState.getIn(['userDetails', 'value']),
        description: store.messageEditorState.get('description'),
        messages: store.messageState.getIn(['messages', 'value']),
        allSites: store.siteState.getIn(['sites', 'value']),
        isSPUser: store.accountState.get('isSPUser'),
        importedFontsStylesLinks: store.messageEditorState.getIn(['siteStyleData', 'value']),
        siteGroup,
        sitePrivacyManagerList: store.privacyManagerState.getIn(['sitePrivacyManagerList', 'value']),
        pendingRequestsMap: Map({
            messages: store.messageState.getIn(['messages', 'pending']),
            messageCreatePending: store.messageState.get('createPending'),
            messageUpdatePending: store.messageState.get('updatePending'),
            allSites: store.siteState.getIn(['sites', 'pending']),
            getSiteFonts: store.messageEditorState.getIn(['siteStyleData', 'pending']),
        }),
    };
};

export default connect(
  mapStateToProps, {
    getAllSites,
    getMessageWithDetail,
    createMessage,
    updateMessage,
    getSiteFonts,
    setCurrentSite,
    getSiteGroup,
    createSiteGroupMessage,
    updateSiteGroupMessage,
    getSitePrivacyManagerList,
    getSiteGroupPrivacyManagerList,
  },
)(wrappedMessageEditPage);
