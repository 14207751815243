import React, {
  useState,
  useEffect,
  useContext
} from 'react';
import { Select } from 'antd';

import { PmOverrideContext } from '../../../../../../../../contexts';

const PmOverridesSelect = ({
  site,
  privacyManagers,
  masterOption,
  pmKey = 'name',
  isTcfv2 = false,
  disabled = false,
}) => {
  const {
    overridePrivacyManager,
    overrideVariables,
    clearPrivacyManagerOverride
  } = useContext(PmOverrideContext);
  const [currentPm, setCurrentPm] = useState({ [pmKey]: 'No override' });

  useEffect(() => {
    if (privacyManagers.length) {
      const override = overrideVariables.find(({ siteId }) => siteId === site.id);
      const pm = override
        ? privacyManagers.find(({ id }) => id == override.privacyManagerIdHiddenVarriable) || { [pmKey]: 'No override' }
        : { [pmKey]: 'No override' };

      if (isTcfv2) {
        if (pm[pmKey] === 'No override') {
          const clonedPm = privacyManagers.find((pm) => pm[pmKey] === masterOption);
          setOverride(clonedPm ? clonedPm.id : '');
        };
      };

      setCurrentPm(pm);
    };
  }, [privacyManagers.length, masterOption]);

  const setOverride = (pmId) => {
    let pm;
    if (!pmId) {
      pm = { [pmKey]: 'No override' };

      if (isTcfv2) {
        const clonedPm = privacyManagers.find((pm) => pm[pmKey] === masterOption) || privacyManagers[0];
        overridePrivacyManager(site.id, clonedPm.id);
      } else {
        clearPrivacyManagerOverride(site.id);
      };
    } else {
      pm = privacyManagers.find(({ id }) => id === pmId);
      overridePrivacyManager(site.id, pmId);
    }

    setCurrentPm(pm);
  };

  const selectValue = isTcfv2
    ? (currentPm[pmKey] === masterOption)
      ? null : currentPm[pmKey]
    : currentPm[pmKey];
  return (
    <React.Fragment>
      <div style={{ padding: 4 }}>
        {site.domain}
      </div>
      <div className="option-inputs">
        <Select value={selectValue} onChange={setOverride} disabled={disabled}>
          <Select.Option key="-1" value={null}>
            <i>No override</i>
          </Select.Option>
          {privacyManagers
            .filter(pm => (isTcfv2 ? pm[pmKey] : pm.id) !== masterOption)
            .map(pm => (
              <Select.Option key={pm.id} value={pm.id}>
                {pm[pmKey]}
              </Select.Option>
            ))}
        </Select>
      </div>
    </React.Fragment>
  );
}

export default PmOverridesSelect;