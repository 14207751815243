import { List, Map, isImmutable } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';
import { DomainSet } from '../../records/domain_set_records';

// const permissionsSvcBaseUrl = 'http://localhost:5000'
const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const getBaseUrl = () => {
  return `${permissionsSvcBaseUrl}/consent-quality`;
}

export const getDomainSetsAjax = async (accountId) => {
  const datePropertySetUpdated = localStorage.getItem('datePropertySetUpdated');
  let url = `/domain-set/list?accountId=${accountId}`;
  if (datePropertySetUpdated) url += `&datePropertySetUpdated=${datePropertySetUpdated}`;
  const resp = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(response => response.json());
  return resp;
}

export const getAccuracySetsAjax = async () => {
  // const datePropertySetUpdated = localStorage.getItem('datePropertySetUpdated');
  let url = `/golden-set/master`;
  // if (datePropertySetUpdated) url += `&datePropertySetUpdated=${datePropertySetUpdated}`;
  const resp = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(response => response.json());
  return resp;
};

export const getAccuracyEstimation = async (id, accountId) => {
  let url = `/accuracy/estimation?domainSetId=${id}&accountId=${accountId}`;
  const resp = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(response => response.json());
  return resp;
};

export const getComposition = async (id, accountId) => {
  let url = `/accuracy/composition?domainSetId=${id}&accountId=${accountId}`;
  const resp = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response => response.json());
  return resp;
};

export function getAverageImpressionsAjax(accountId, refresh) {
  const url = '/domain-set/get-average-imperssions/' + accountId;
  if (!refresh && isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return resp.averageImpressions;
      });
  }
}

export const getDomainsWithScores = async (domainSetId) => {
  const url = '/domain-set/domains-with-scores?domainSetId=' + domainSetId;
  const resp = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(response => response.json());
  return resp;
}

export const getDomainsWithManualScores = async (domainSetId) => {
  const datePropertySetUpdated = localStorage.getItem('datePropertySetUpdated');
  let url = '/domain-set/domains-with-manual-scores?domainSetId=' + domainSetId;
  if (datePropertySetUpdated) url += `&datePropertySetUpdated=${datePropertySetUpdated}`;

  const resp = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(response => response.json());
  return resp;
}

export const updateManualScores = async (domains) => {
  const url = '/domain-set/update-manual-scores'
  const resp = await fetch(getBaseUrl() + url, {
    method: 'put',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(domains),
  })
    .then(checkStatus)
    .then(response => {
      localStorage.setItem('datePropertySetUpdated', JSON.stringify(new Date()));
      return response.json()
    });
  return resp;
}

export const getDomainsForDomainSet = async (domainSetId) => {
  const datePropertySetUpdated = localStorage.getItem('datePropertySetUpdated');
  let url = `/domain-set/domains-limited?domainSetId=${domainSetId}`;
  if (datePropertySetUpdated) url += `&datePropertySetUpdated=${datePropertySetUpdated}`;
  const resp = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(response => response.json());
  return resp;
}

export function createDomainSetAjax(accountId, domainSet) {
  const data = parseDomainSetData(accountId, domainSet);
  delete data.id;
  return fetch(getBaseUrl() + '/domain-set/v2', {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      localStorage.setItem('datePropertySetUpdated', JSON.stringify(new Date()));
      return resp;
    });
}

export function updateDomainSetAjax(accountId, domainSet) {
  const data = parseDomainSetData(accountId, domainSet);
  if (data.id) data._id = data.id;

  delete data.id;
  return fetch(getBaseUrl() + '/domain-set/v2', {
    method: 'PUT',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      localStorage.setItem('datePropertySetUpdated', JSON.stringify(new Date()));
      return resp;
    });
}

export function deleteDomainSetAjax(id, accountId) {
  return fetch(getBaseUrl() + `/domain-set?domainSetId=${id}`, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(() => id)
}

function parseDomainSetData(accountId, domainSet) {
  //XXXall needs to be immutable
  if (domainSet.get) {
    const updatedStandards = domainSet.get('standards').map(s => s.id);
    return domainSet.toMap()
      .set('standards', updatedStandards)
      .set('accountId', accountId)
      .toJS();
  } else {
    const updatedDomainSet = { ...domainSet };
    if (!updatedDomainSet.standards) {
      updatedDomainSet.standards = [domainSet.standard._id];
    }
    delete updatedDomainSet.standard;
    updatedDomainSet.accountId = accountId;
    return updatedDomainSet;
  }
}

export const getAllAccountDomainsPerRegion = (accountId, regionId, domainSetId) => {
  // if domainSetId is passed list of domains will not included domains from this domain set
  let url = `/account-domains?accountId=${accountId}&regionId=${regionId}`;
  if (domainSetId) {
    url = `${url}&domainSetId=${domainSetId}`;
  }

  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(response => response.json());
}

export const checkDomains = async (domains) => {
  const url = `/domain-set/check-domains`;

  const response = await fetch(getBaseUrl() + url, {
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ domains }),
  }).then(checkStatus);
  return response.json();
}