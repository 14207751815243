import { List, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;
const baseUrl = `${permissionsSvcBaseUrl}/consent-quality`;

export function getAccountsAjax(accountId) {
  const url = `/list-accounts?accountId=${accountId}`;
  return fetch(baseUrl + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json());
}

export function getAccountAjax(accountId) {
  const url = `/account?accountId=${accountId}`;
  return fetch(baseUrl + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json());
}

export function updateAccountsAjax(accountId, accounts) {
  debugger
  const url = `/accounts?accountId=${accountId}`;
  return fetch(baseUrl + url, {
    method: 'PUT',
    body: JSON.stringify({ accounts }),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      debugger
      return resp.map(account => {
        if (account.rules) account.rules = account.rules.map(r => r._id);
        return account;
      });
    });
}