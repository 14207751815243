import React, { useState, useEffect } from 'react';
import { Button, Input, Select } from '../../../styleguide'
import { findMessageIdsFromScenario } from '../../utils.js';
import { fromJS } from 'immutable';
import { categoryMap, subcategoryMap } from '../../../constants'
import PrivacyChecksConfirmation from './PrivacyChecksConfirmation';
import { getTypes, envOptions as environmentOptions,getCampaignNameLatestEnv} from './helper';
import ConfirmationModal from './campaignsLists/ConfirmationModal';
const { Option } = Select;
let privacyUrl = "";
let priorityError = null;

function CreateCampaign(props) {

  const [ name, updateName ] = useState();
  const defaultCampaignType = props.isMultiCampaignEnabled ? undefined : 0;
  const [ type, updateType ] = useState(defaultCampaignType);
  const [ partitionSet, updatePartitionSet ] = useState();
  const [ env, updateEnviornment ] = useState();
  const [ priority, updatePriority ] = useState();
  const [ firstLayerMessages, updateFirstLayerMessages ] = useState([]);
  const [ pmMessages, setPmMessages ] = useState([]);
  const [ pmOttMessages, setPmOttMessages ] = useState([]);
  const [ showPrivacyCheckModal, updatePrivacyCheckModalVisibility ] = useState(false);
  const [ isReplaceCampaignWarningVisible, setIsReplaceCampaignWarningVisible ] = useState(false);

  const priorityMsg = `An Anti-Adblock campaign can not be prioritised over a GDPR message, the Anti-Adblock script will not trigger without the consent of the user.`;
  let envOptions = !props.hasStageCampaignAccess ? environmentOptions.filter(env=> env.value !== 'stage') : environmentOptions;
  envOptions = !props.hasPublicCampaignAccess ? envOptions.filter(env=> env.value !== 'public') : envOptions;

  useEffect(() => {
    return () => {
      priorityError = null;
    };
  }, []);

  const isCampaignExist = () => {
    const campaigns = env === 'stage' ? props.stageCampaigns : props.publicCampaigns;
    return campaigns.find(c => Number(c.campaign_type_id) === type);
  }

  const getPriorities = () => {
    let priorities = [];
    let gdprCampaign = -1;
    let adblockCampaign = -1;
    const isCampaignTypeExist = isCampaignExist();
    if(env) {
      let init = 0;
      const limit = env === 'stage' ? (props.stageCampaigns.length + 1) : (props.publicCampaigns.length + 1);
      let threshhold = limit > 5 ? 5 : isCampaignTypeExist ? limit - 1 : limit;
      const priorityList = env === 'stage' ? props.site.stageCampaignTypePriority : props.site.publicCampaignTypePriority;
      if(type && type === 1) {
        adblockCampaign = priorityList.indexOf(3);
        threshhold = adblockCampaign != -1 ? adblockCampaign + 1 : threshhold;
        priorityError = adblockCampaign != -1 ? priorityMsg : null;
      } else if(type && type === 3) {
        gdprCampaign = priorityList.indexOf(1);
        init = gdprCampaign != -1 ? gdprCampaign + 1 : init;
        priorityError = gdprCampaign != -1 ? priorityMsg : null;
      } else {
        gdprCampaign = -1;
        adblockCampaign = -1;
        priorityError = null;
      }

      for(var i=init; i<threshhold; i++) {
        priorities.push(i+1);
      }
    }
    return priorities;
  }

  const getPrivacyUrl = (children) => {
    for(let i = 0; i < children.length; i++) {
      const c = children[i];
      if (c.type !== 'Button') {
        getPrivacyUrl(c.children);
      } else {
        let choiceData = c.settings.choice_option.data;
        if(choiceData.privacy_manager_iframe_url != undefined){
          privacyUrl = choiceData.privacy_manager_iframe_url;
        }
      }      
    }
    return privacyUrl;   
  }

  const getPrivacyIdMessage = (component) => {
    const message_json = JSON.parse(component.message_json);
    const children = message_json.children;
    let privacyId = 0;
    let privacyUrl = getPrivacyUrl(children);
    if(privacyUrl != '') {
      privacyId = privacyUrl.split("=")[1];
    }   
    return privacyId;
  }

  const handlePrivacyModalVisibility = (visible, partitionsetId) => {
       
    let selectedPartitionSet = props.partitions.find(partition => partition.id === parseInt(partitionsetId));
    let pmMessagesFiltered = [];
    let pmOttMessagesFiltered = [];

    let partitionsScenarioId = (selectedPartitionSet != null || selectedPartitionSet != undefined) ?
      selectedPartitionSet.partitions.map(p => p.scenario_id) : [];
    let scenarios = props.scenarios.filter((scenario) => partitionsScenarioId.includes(scenario.id));
      
    const messageIds = scenarios.map(s => {
      return findMessageIdsFromScenario(fromJS(JSON.parse(s.scenario_json)));
    }).flatten();

    let messages = props.messages;
    let firstLayerMessages = messages.filter(msg => msg.message_sub_category_id === subcategoryMap['notice-tcf-v2'] && 
      msg.message_category_id === categoryMap.gdpr && messageIds.includes(msg.id));

    let pmMessages = messages.filter((msg) => 
        msg.message_sub_category_id === subcategoryMap['privacy-manager'] &&
        msg.message_category_id === categoryMap.gdpr && messageIds.includes(msg.id)
	);

    let pmOttMessages = messages.filter((msg) => msg.message_sub_category_id === subcategoryMap['privacy-manager-ott']);

    let filteredFirstLayerMessage = firstLayerMessages.filter((msg) => {
      let compliance_json = JSON.parse(fromJS(msg.compliance_json));
      let count = (compliance_json && compliance_json.length > 0) ? compliance_json.filter((item,index) => !item[index+1]).length : 0;
      let privacyIdMessage = getPrivacyIdMessage(msg);
      let pmMessage = messages.find((msg) => msg.id === parseInt(privacyIdMessage));
      if(pmMessage) {
        let compliance_json_pm = JSON.parse(fromJS(pmMessage.compliance_json));
        let pmCount = (compliance_json_pm && compliance_json_pm.length > 0) ? compliance_json_pm.filter((item,index) => !item[index+1]).length : 0;
        if(pmCount > 0) {
            pmMessagesFiltered.push(pmMessage);
        }
      }
      if(count > 0) {
        return msg;
      }
    });

    pmMessages.filter((msg) => {
      let compliance_json = JSON.parse(fromJS(msg.compliance_json));
      let count = (compliance_json && compliance_json.length > 0) ? compliance_json.filter((item,index) => !item[index+1]).length : 0;
      if(count > 0 && !pmMessagesFiltered.includes(msg)) {
        pmMessagesFiltered.push(msg);
      }
    });

    pmOttMessages.filter((msg) => {
      let compliance_json = JSON.parse(fromJS(msg.compliance_json));
      let count = (compliance_json && compliance_json.length > 0) ? compliance_json.filter((item, index) => !item[index + 1]).length : 0
      if (count > 0 && !pmOttMessagesFiltered.includes(msg)) {
        pmOttMessagesFiltered.push(msg);
      }
    });

    if(filteredFirstLayerMessage.length === 0 && pmMessagesFiltered.length === 0 && pmOttMessagesFiltered.length === 0) {
      updatePartitionSet(partitionsetId);
      updatePrivacyCheckModalVisibility(false);
    } else {
      updateFirstLayerMessages(filteredFirstLayerMessage);
      setPmMessages(pmMessagesFiltered);
      setPmOttMessages(pmOttMessagesFiltered);
      updatePrivacyCheckModalVisibility(visible);
      updatePartitionSet('');
    }
  }

  const setSelectedPartition = (partitionsetId) => {  
    handlePrivacyModalVisibility(true, partitionsetId);   
  };

  const privacyChecks = showPrivacyCheckModal && <PrivacyChecksConfirmation 
                          siteId={props.site.id}
                          siteGroup={props.siteGroup}
                          showPrivacyCheckModal={showPrivacyCheckModal}
                          firstLayerMessages={firstLayerMessages}
                          pmMessages={pmMessages}
                          pmOttMessages={pmOttMessages}
                          handlePrivacyModalVisibility={handlePrivacyModalVisibility}/>;

  let readOnly;
  if(props.isMultiCampaignEnabled) {
    const customCampaignType = type === 0 ? type.toString() : type;
    readOnly = !name || !customCampaignType || !partitionSet || !env || !priority;
  } else {
    readOnly = !name || !partitionSet || !env;
  }

  const priorities = getPriorities();

  const toggleReplaceWarning = () => {
    setIsReplaceCampaignWarningVisible(prevState => !prevState);
  }

  const createCampaign = () => {
    const isCampaignTypeExist = isCampaignExist();
    if(isCampaignTypeExist) {
      toggleReplaceWarning();
    } else {
       props.handleSaveAndActivate(env, name, partitionSet, type, priority);
    }
  }

  const setEnvironment = (env) => {
    updateEnviornment(env);
    updatePriority();
  }

  const setType = (campaignType) => {
    updateType(campaignType);
    updatePartitionSet();
    const campaigns = env === 'stage' ? props.stageCampaigns : props.publicCampaigns;
    const typedCampaignExist = campaigns.find(c => Number(c.campaign_type_id) === campaignType);
    if(typedCampaignExist) {
      updatePriority();
    }
  }

  const handleChangeReplaceConfirmed = () => {
    toggleReplaceWarning();
    const isReplace = true;
    props.handleSaveAndActivate(env, name, partitionSet, type, priority, isReplace);
  }
  
  const handleCancel = () => {
    props.onCancelCreateCampaign();
    priorityError = null;
  }

  const partitionSets = props.partitions.filter(ps => ps.campaign_type_id === type);

  const replaceableCampaign = isReplaceCampaignWarningVisible ? isCampaignExist() : { description: '' };
  const replaceCampaignMsg = `Creating and moving '${getCampaignNameLatestEnv(name)}' to ${env}, existing campaign '${replaceableCampaign.description}' will end and be replaced.`;
  const replaceCampaignModal = isReplaceCampaignWarningVisible && 
                                <ConfirmationModal
                                  campaign={replaceableCampaign}
                                  isMultiCampaignEnabled={props.isMultiCampaignEnabled} 
                                  isEndedCampaign={false}
                                  isModalVisible={isReplaceCampaignWarningVisible}
                                  handleOk={handleChangeReplaceConfirmed}
                                  handleCancel={toggleReplaceWarning}
                                  isReplaceCampaign
                                  replaceCampaignMsg={replaceCampaignMsg}/>

  const types = getTypes(props.currentUser, props.site.type);
  const isTypeNotSelected = typeof type === 'undefined';

  return (<React.Fragment>
            <div className="create-campaign">
              <div className="campaign-inner-wrapper">
                <label>Campaign Name</label>
                <Input placeholder="Name" value={name} onChange={(e) => updateName(e.target.value)}/>
              </div>

              {props.isMultiCampaignEnabled && <div className="campaign-inner-wrapper">
                <label>Type</label>
                <Select placeholder="Select" value={type} onChange={setType}>
                  {types.map((option, i) => <Option key={i} value={option.value}>{option.label}</Option>)}
                </Select>
              </div>}

              <div className="campaign-inner-wrapper">
                <label>Partition Set</label>
                <Select placeholder="Select" value={partitionSet} onChange={setSelectedPartition} disabled={isTypeNotSelected}>
                  {partitionSets.map((partition, i) => <Option key={ partition.id } value={ partition.id }>{ partition.description }</Option>)}
                </Select>
              </div>

              <div className="campaign-inner-wrapper">
                <label>Environment</label>
                <Select placeholder="Select" value={env} onChange={setEnvironment} disabled={isTypeNotSelected}>
                  {envOptions.map((env, i) => <Option key={i} value={env.value}>{env.label}</Option>)}
                </Select>
              </div>

              {props.isMultiCampaignEnabled && <div className="campaign-inner-wrapper">
                <label>Priority</label>
                <Select placeholder="Select" value={priority} onChange={(value) => updatePriority(value)} disabled={isTypeNotSelected}>
                  {priorities.map((priority, i) => <Option key={i} value={priority}>{priority}</Option>)}
                </Select>
              </div>}

              <Button type="ghost" size='small' onClick={handleCancel}>
                Cancel
               </Button>&nbsp;&nbsp;

              <Button 
                disabled={readOnly} 
                type="primary" 
                size='small'
                onClick={createCampaign}>
                Create
               </Button>
               { props.activateError && <div className="campaign-create-error">Error: { props.activateError }</div> }
               { !props.activateError && priorityError && <div className="campaign-warning">Note: { priorityError }</div>}
            </div>
            {privacyChecks}
            {replaceCampaignModal}
         </React.Fragment>);
}

export default CreateCampaign;
