import React, { useState, Fragment } from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Radio } from '../../../../styleguide';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { privacyManagerOTTViews } from 'message-preact/lib/constants';

const ViewNavigation = ({
  currentComponentIndex,
  changeCurrentComponentIndex,
  messages,
  updateSubview,
  subviewIndex,
  hideTemplatesButton,
}) => {
  const [expanded, setExpanded] = useState(true);

  const changeSubview = ({ target: { value } }) => updateSubview(value)

  return (
    <div className="toolbar navigation" style={{ width: 270 }}>
      <div>
        <h4>
          Navigate Views
        </h4>
        <div
          className={"toggle" + (expanded ? ' flip' : '')}
          onClick={() => setExpanded(!expanded)}
        >
          <FontAwesomeIcon icon={faChevronDown} size="1x" color="white" />
        </div>
      </div>
      <div style={{ height: !expanded ? 0 : 'unset' }}>
        <Radio.Group
          className="view-navigation-select"
          style={{ padding: '0 10px 10px', display: !expanded ? 'none' : 'block' }}
          value={currentComponentIndex}
          onChange={({ target }) => changeCurrentComponentIndex(target.value)}
        >
          {messages.map(({ name, id }, i) => (
            id !== privacyManagerOTTViews.CARD_DETAILS
              ? (
                <Radio key={id} value={i}>
                  {name}
                </Radio>
              )
              : (
                <Fragment>
                  <Radio value={i}>
                    {name}
                  </Radio>
                  <div style={{ display: currentComponentIndex === i ? 'flex' : 'none', justifyContent: 'flex-end' }}>
                    <Radio.Group
                      buttonStyle="solid"
                      value={subviewIndex}
                      onChange={changeSubview}
                    >
                      <Radio value="category">
                        <span style={{ fontSize: 9 }}>
                          Category Details
                        </span>
                      </Radio>
                      <Radio value="vendor">
                        <span style={{ fontSize: 9 }}>
                          Vendor Details
                        </span>
                      </Radio>
                    </Radio.Group>
                  </div>
                </Fragment>
              )
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default ViewNavigation;