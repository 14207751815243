import { Map, List } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
  requestData: StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  switch(action.type) {
    case types.DATA_REQUEST_CCPA_PENDING:
      return setPending(state, ['requestData']);

    case types.DATA_REQUEST_CCPA_LOADED:
        return setValue(state, ['requestData'], action.data);

    case types.DATA_REQUEST_CCPA_ERROR:
      return setError(state, ['requestData'], action.errorMessage);

    case types.DATA_REQUEST_CCPA_CACHED:
        return stopPending(state, ['requestData']);

  
  }
  return state;
};