import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
  domainSetScores: new StoreRecord({ value: List([]) }),
  setScoresIncluded: new StoreRecord({ value: List([]) }),
  domainScores: new StoreRecord({ value: List([]) }),
  allDomainScores: new StoreRecord({ value: List([]) }),
  standardScores: new StoreRecord({ value: List([]) }),
  changeDomainScoresPending: false,
  adminDomains: new StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  let scoresForSet;
  let scoresForSetUpdated;
  let updatedDomainScores;

  switch(action.type) {

    case types.DOMAIN_SCORES_PENDING:
      return setPending(state, ['domainScores']);

    case types.DOMAIN_SCORES_LOADED:
      const domainScores = state.getIn(['domainScores', 'value']);
      const updated = domainScores.filterNot(s => s.domainSetId === action.scores.domainSetId).push(action.scores);
      return setValue(state, ['domainScores'], updated);

    case types.DOMAIN_SCORES_ERROR:
      return setError(state, ['domainScores'], action.errorMessage);

    case types.DOMAIN_SCORES_CACHED:
      return stopPending(state, ['domainScores']);

    case types.CHANGE_DOMAIN_SCORES_PENDING:
      return state.set('changeDomainScoresPending', true);

    case types.CHANGE_DOMAIN_SCORES_REDUX:
      scoresForSet = state.getIn(['domainScores', 'value']).find(score => action.scores.domainSetId === score.domainSetId);

      scoresForSetUpdated = scoresForSet.update('scores', scores => {

        return scores.map(score => {
          if (action.scores.domainIds.includes(score.domainId)) {
            return score.merge(action.scores.rules);
          } else {
            return score;
          }
        })
      });

      updatedDomainScores = state.getIn(['domainScores', 'value']).filterNot(score => action.scores.domainSetId === score.domainSetId).push(scoresForSetUpdated);

      return state.setIn(['domainScores', 'value'], updatedDomainScores).set('changeDomainScoresPending', false);

    case types.CHANGE_DOMAIN_SCORES_LOADED:
      scoresForSet = state.getIn(['domainScores', 'value']).find(score => action.scores.domainSetId === score.domainSetId);

      scoresForSetUpdated = scoresForSet.update('scores', scores => {

        return scores.filterNot(score => action.scores.scores.map(s => s.domainId).includes(score.domainId)).concat(action.scores.scores);
      }).set('topRulesByStandard', action.scores.topRulesByStandard);

      updatedDomainScores = state.getIn(['domainScores', 'value']).filterNot(score => action.scores.domainSetId === score.domainSetId).push(scoresForSetUpdated);

      return state.setIn(['domainScores', 'value'], updatedDomainScores).set('changeDomainScoresPending', false);

    case types.ALL_DOMAIN_SCORES_PENDING:
      return setPending(state, ['allDomainScores']);

    case types.ALL_DOMAIN_SCORES_LOADED:
      return setValue(state, ['allDomainScores'], action.scores);

    case types.ALL_DOMAIN_SCORES_ERROR:
      return setError(state, ['allDomainScores'], action.errorMessage);

    case types.ALL_DOMAIN_SCORES_CACHED:
      return stopPending(state, ['allDomainScores']);

    case types.DOMAIN_SET_SCORES_PENDING:
      return setPending(state, ['domainSetScores']);

    case types.DOMAIN_SET_SCORES_LOADED:
      return setValue(state, ['domainSetScores'], action.scores);

    case types.DOMAIN_SET_SCORES_ERROR:
      return setError(state, ['domainSetScores'], action.errorMessage);

    case types.DOMAIN_SET_SCORES_CACHED:
      return stopPending(state, ['domainSetScores']);

    case types.SET_SCORES_INCLUDED_PENDING:
      return setPending(state, ['setScoresIncluded']);

    case types.SET_SCORES_INCLUDED_LOADED:
      return setValue(state, ['setScoresIncluded'], action.scores);

    case types.SET_SCORES_INCLUDED_ERROR:
      return setError(state, ['setScoresIncluded'], action.errorMessage);

    case types.SET_SCORES_INCLUDED_CACHED:
      return stopPending(state, ['setScoresIncluded']);

    case types.STANDARD_SCORES_PENDING:
      return setPending(state, ['standardScores']);

    case types.STANDARD_SCORES_LOADED:
      return setValue(state, ['standardScores'], action.scores);

    case types.STANDARD_SCORES_ERROR:
      return setError(state, ['standardScores'], action.errorMessage);

    case types.STANDARD_SCORES_CACHED:
      return stopPending(state, ['standardScores']);

    case types.ADMIN_DOMAINS_PENDING:
      return setPending(state, ['adminDomains']);

    case types.ADMIN_DOMAINS_LOADED:
      return setValue(state, ['adminDomains'], action.domains);

    case types.ADMIN_DOMAINS_ERROR:
      return setError(state, ['adminDomains'], action.errorMessage);

    case types.ADMIN_DOMAINS_CACHED:
      return stopPending(state, ['adminDomains']);

    case types.ADMIN_DOMAINS_CHANGED_PERIOD:
      const changedDomainIds = action.domains.map(d => d.domain_id);
      const updatedDomains1 = state.getIn(['adminDomains', 'value']).map(d => {
        if (changedDomainIds.includes(d.id)) {
          d = d.set('period', action.domains[0].period);
        }
        return d;
      });
      return setValue(state, ['adminDomains'], updatedDomains1);

    case types.ADMIN_DOMAINS_SENT_TO_RESCAN:
      return stopPending(state, ['adminDomains']);
  }

  return state;
};