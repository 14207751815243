import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';

import { ConsentGate as ConsentRecord } from '../../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';

const Option = Select.Option;

class ConsentGate extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    data: PropTypes.instanceOf(ConsentRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleChangeSatisfy = (continueOnSatisfy) => {
    let data = this.props.data.set('status', continueOnSatisfy);
    this.props.onChange(data);
  }

  static statuses = [
    { value: 3, label: 'Consented To All' },
    { value: 4, label: 'Not Consented To All' },
    { value: 1, label: 'Consented To Any' },
    { value: 2, label: 'Rejected To All' },
    { value: 5, label: 'Rejected To Any' },
    { value: 6, label: 'Not Rejected To Any' },
    { value: 0, label: 'No Action' },
  ];

  render() {
    const continueOnSatisfy = ConsentGate.statuses
        .filter(status => status.value === this.props.data.status)
        .map(status => status.label)[0];

    const options = ConsentGate.statuses.map(
      status => <Option key={ status.value } value={ status.value }>{ status.label }</Option>);

    if (!this.props.editing) {
      return (
        <div>
          Consent: { continueOnSatisfy }
        </div>
      );
    } else {
      return (
        <div className="step-block-editing">
          { this.props.children }
          <Select
            onChange={ this.handleChangeSatisfy }
            value={ this.props.data.status }
          >
            { options }
          </Select>
        </div>
      );
    }
  }
}

export default ConsentGate;
