import React from "react";
import { Select } from 'antd';

const Option = Select.Option;

export const Filter = ({ filterBy, ...props }) => {
  const onClick = (key ) => {
    filterBy(key);
  };

  return (
    <div {...props}>
        <div className='external filters'>
          <Select
            className='country-select'
            defaultValue='All'
            style={{ width: 120 }}
            onChange={ onClick }
          >
            <Option key="" value={""}>All</Option>
            <Option key="web" value={"web"}>Web</Option>
            <Option key="app" value={"app"}>App</Option>
            <Option key="ott" value={"ott"}>OTT</Option>
          </Select>
        </div>
    </div>
  );
};