import React, { Component } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

class Filterbox extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'spsg-filterbox ' + (props.className || '');

    return <AntdCheckbox {...props} />;
  }
}

Filterbox.Group = AntdCheckbox.Group;
export default Filterbox;
