import PropTypes from 'prop-types';
import React from 'react';
import { fromJS, List, Map } from 'immutable';
import {
  Select,
  Tooltip,
} from 'antd';

import ContinueSatisfy from './ContinueSatisfy';
// import { GeoGate as GeoGateRecord } from '../../../../../constants_scenario/scenario_records.js';
import { GEO_FILTER_LIST_DETAILS, LEGACY_EU_COUNTRY_CODES, GeoGate as GeoGateRecord} from '../../../../../constants.js';
// import { GeoGate as GeoGateRecord } from '../../../../../constants.js';


const { Option, OptGroup } = Select;

class Countries extends React.Component {
    static propTypes = {
      editing: PropTypes.bool.isRequired,
      data: PropTypes.instanceOf(GeoGateRecord).isRequired,
      onChange: PropTypes.func.isRequired,
    };

    componentWillMount() {
      let geoList = this.props.data.geo_list.toJS();
      if(geoList.includes('allEU')){
        geoList = [...new Set(geoList.concat(LEGACY_EU_COUNTRY_CODES))];
        geoList = geoList.filter(code => code !== 'allEU')
        this.handleChange(geoList)
      }
    }

    handleChange = (newData) => {
      this.props.onChange(this.props.data.set('geo_list', fromJS(newData)));
    };

    handleChangeSatisfy = (continueOnSatisfy) => {
      this.props.onChange(this.props.data.merge({ continue: continueOnSatisfy }));
    };

    onSelectFilter = (inputString, option) => {
      return inputString.toLowerCase() === option.props.children.toLowerCase().slice(0, inputString.length);
    }

    render() {
      const continueOnSatisfy = this.props.data.continue === true ? "In" : "Not In";

      const countryMaps = [
        Map({key: 'allEEA', value: 'All EEA Countries', group: 'EEA'}),
        Map({key: 'CA', value: 'All Canada', group: 'CA'})
      ]

      GEO_FILTER_LIST_DETAILS.forEach((el) => { 
        if( el && el.type === "country" && el.name !== "Canada" || el.type === "province") {
          let group = List.isList(el.region) ? el.region.last() : el.region;
          countryMaps.push(Map({ key: el.type === "province" ? `CA_${el.code}` : el.code, value: el.name, group: group }));
        }
      });

      const countryList = List(countryMaps)

      if (!this.props.editing) {
        const fullText = 'Countries ' + continueOnSatisfy + ' ' + this.props.data.geo_list.join(', ');
        const shortenedText = fullText.length > 30 ? fullText.slice(0, 30) + '...' : fullText;
        return (
          <Tooltip title={ fullText } >
            <div>
              { shortenedText }
            </div>
          </Tooltip>
        );
      } else {
        const geoList = this.props.data.geo_list.toJS();

        // DONT USE GROUPS UNTIL CAN UPDATE ANTD;
        // const countryGroups = _.groupBy(countryList, 'group');
        // const options = _.map(countryGroups, (group, label) => {
        //   return (
        //     <OptGroup key={ label } label={ label }>
        //       { group.map((country) => {
        //         return <Option key={country.key}>{country.value}</Option>;
        //       } ) }
        //     </OptGroup>
        //   );
        // });
        const options = countryList.map((country) => {
          return <Option key={country.get('key')}>{country.get('value')}</Option>;
        });


        return (
          <div className="step-block-editing">
            <div className='country-type-select-container'>
              { this.props.children }
              <ContinueSatisfy
                style={{ width: 100 }}
                value={ this.props.data.continue }
                onChange={ this.handleChangeSatisfy }
                affirmativeText='in'
                negativeText='not in'
              />
            </div>
            <Select
              className='country-select'
              mode='multiple'
              onChange={ this.handleChange }
              tokenSeparators={[',']}
              value={ geoList }
              filterOption={ this.onSelectFilter }
            >
              { options }
            </Select>
          </div>
        );
      }
    }
}

export default Countries;
