import { InfoCircleFilled } from '@ant-design/icons';
import { Slider, Tooltip } from 'antd';
import React from 'react';
import SVG from 'react-inlinesvg';

import prevalenceIcon from '../../../../assets/icons/equalizer_prevalence.svg';
import SlidingFilter from './SlidingFilter';
import { useVendorTraceContext } from './ChartFiltersContext';

const VendorTracePrevalenceFilter = ({ isDisabled }) => {
  const { prevalenceLowPoint, setPrevalence } = useVendorTraceContext();
  const onChange = (value) => {
    setPrevalence(value);
  };

  const marks = {
    0: '0',
    25: '25',
    50: '50',
    75: '75',
    100: '100',
  };
  const formatter = (value) => `${value}%`;
  return (
    <SlidingFilter className={'vendor-trace-prevalence'}>
      <div>
        <div className={'title-row'}>
          <SVG className={'prevalence-icon'} src={prevalenceIcon} />
          <div>Prevalence Range (%)</div>
          <Tooltip
            placement="topRight"
            overlayClassName="diagnose--tooltip prevalence"
            title={() => {
              return (
                <div className={'tooltip-content'}>
                  <div>
                    The prevalence is the percentage of scans where we have seen this vendor across all scans set up on
                    this url
                  </div>
                </div>
              );
            }}
          >
            <InfoCircleFilled />
          </Tooltip>
        </div>
        <div>
          <Slider
            onChange={onChange}
            min={0}
            max={100}
            marks={marks}
            value={prevalenceLowPoint}
            tooltip={{ formatter }}
            step={25}
            className={'prevalence-range-slider'}
            disabled={isDisabled}
          />
        </div>
      </div>
    </SlidingFilter>
  );
};

export default VendorTracePrevalenceFilter;
