import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { Input } from 'antd';

import CustomIcon from '../../../../../common/CustomIcon.js';

const VariableList = ({ 
  variableList, 
  handleChange, 
  handleBlur, 
  handleDelete 
}) => {
  return (
    <ul className="settings-form">
      {variableList.map((variable) => {
        const { name, value } = variable.toJS();
        return (
          <li key={name}>
            <h4 style={{ fontSize: '12px', fontWeight: '600', textTransform: 'none' }}>{name}</h4>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <Input
                value={value}
                onChange={({ target }) => handleChange({ value: target.value, variableName: name })}
                onBlur={handleBlur}
                disabled={typeof name === "string" && (name.toLowerCase().includes('hiddenvarriable') || name.toLowerCase().includes('hiddenvariable'))}
              />
              {handleDelete && (
                <CustomIcon
                  type={CustomIcon.types.DELETE}
                  onClick={() => handleDelete(name)}
                />
              )}
            </div>
          </li>
        )
      })}
    </ul>
  );
};

VariableList.propTypes = {
  variableList: PropTypes.instanceOf(List).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
}

export default VariableList;