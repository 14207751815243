import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { CloseOutlined } from '@ant-design/icons';
import { Popover, Button } from 'antd';
import classNames from 'classnames';
import { SortableElement } from 'react-sortable-hoc';

import CancelAndConfirm from './components/CancelAndConfirm';
import StepBlock from './StepBlock';
import GenericStepBlock from './GenericStepBlock';
import { Message } from '../../../../records/message_records';
// import { ScenarioRowRecord } from '../../../../components/dialogue/scenarios/versionTwo/components/steps.js';
import { SCENARIO_STEPS, ScenarioRowRecord } from './components/steps';

const SortableStepBlock = SortableElement(StepBlock);

class ScenarioRow extends React.Component {
  static propTypes = {
    rowIndex: PropTypes.number.isRequired,
    scenarioRow: PropTypes.instanceOf(ScenarioRowRecord).isRequired,
    editing: PropTypes.bool.isRequired,
    onStepSelect: PropTypes.func.isRequired,
    messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    types: ImmutablePropTypes.list.isRequired,
    onChange: PropTypes.func.isRequired,
    deleteRow: PropTypes.func.isRequired,
  };

  state = {
    showDeleteWarning: false,
  }

  componentDidUpdate = () => {
    this._isAddEvent = false;
  }

  onChange = (blockIndex, data) => {
    let rowData = this.props.scenarioRow.rowData;
    rowData = rowData.setIn([blockIndex, 'data'], data);
    this.props.onChange(rowData);
  }

  onUpdateType = (blockIndex, className) => {
    const stepBlockData = new SCENARIO_STEPS[className].Record();
    const updatedRowData = this.props.scenarioRow.rowData.set(blockIndex, stepBlockData);
    this.props.onChange(updatedRowData);
  }

  onAddEvent = (className) => {
    this._isAddEvent = true;
    const stepBlockData = new SCENARIO_STEPS[className].Record();
    const rowData = this.props.scenarioRow.rowData.push(stepBlockData);
    this.props.onChange(rowData);
  }

  deleteStepBlock = (blockIndex) => {
    const rowData = this.props.scenarioRow.rowData.delete(blockIndex);
    this.props.onChange(rowData);
  }

  showAddDropdown = () => {
    return (
      <GenericStepBlock
        types={ this.props.types }
        editing={ this.props.editing }
        onAddEvent={ (className) => this.onAddEvent(className) }
        readOnly={ this.props.readOnly }
      />
    );
  };

  toggleShowWarning = () => {
    this.setState({
      showDeleteWarning: !this.state.showDeleteWarning,
    });
  }

  deleteRow = () => {
    this.toggleShowWarning();
    this.props.deleteRow();
  }

  renderStepBlocks = (record, blockIndex) => {
    let editing = false;
    if (this.props.editing) {
      if (this.props.scenarioRow.rowData.size - 1 === blockIndex) {
        if (this._isAddEvent) {
          editing = true;
        }
      }
    }

    return (
      <SortableStepBlock
        key={ blockIndex }
        index={ blockIndex }
        record={ record }
        types={ this.props.types }
        onStepSelect={ this.props.onStepSelect }
        editing={ editing }
        messages={ this.props.messages }
        onUpdateType={ (className) => this.onUpdateType(blockIndex, className) }
        onChange={ (data) => this.onChange(blockIndex, data) }
        deleteStepBlock={ () => this.deleteStepBlock(blockIndex) }
        allowEditing={ this.props.editing }
        onShowMessagePreview={ this.props.onShowMessagePreview }
        readOnly={ this.props.readOnly }
      />
    );
  }

  render() {
    const deleteWarning = (
      <div>
        <p>Are you sure you want to delete this step?</p>
        <CancelAndConfirm
          toggleCancel={ this.toggleShowWarning }
          onConfirm={ this.deleteRow }
          confirmText='Yes, Delete'
        />
      </div>
    );
    const scenarioRowClassName = classNames('scenario-row', { editing: this.props.editing });
    return (
      <div>
        <table className="panel-table table">
          <tbody>
            <tr className={scenarioRowClassName}>
              <td className='row-index'>{ this.props.rowIndex + 1 }</td>
              <td>
                {
                  this.props.readOnly ?
                  null :
                  <Popover
                    content={deleteWarning}
                    open={this.state.showDeleteWarning}
                  >
                    <Button
                      className='delete-button row'
                      onClick={this.toggleShowWarning}
                      shape="circle"
                      icon={<CloseOutlined />}
                    />
                  </Popover>
                }
              </td>
              { this.props.scenarioRow.rowData.map(this.renderStepBlocks) }
              { this.props.readOnly ? null : this.showAddDropdown() }
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ScenarioRow;
