import React, { useState, useContext, useRef, useEffect } from 'react';
import { Form } from 'antd';
import { Modal, Table, Input } from '../../../../../../../styleguide';
import { COUNTRY_LANGUAGES } from '../../../../../../../constants';
import { hasDuplicateNames } from "../../../../builderUtils";
import { convertToCSV } from '../../../../../../utils';

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  dataSource,
  disableUploadButton,
  components,
  validationComplete,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const prevDataSourceRef = useRef(dataSource); 

  useEffect(() => {
    if (JSON.stringify(prevDataSourceRef.current) !== JSON.stringify(dataSource)) {
      validateAllFields()
    }
  }, [dataSource])

  useEffect(() => {
    if (disableUploadButton && !validationComplete.current) {
      form.validateFields().then(() => {
        disableUploadButton(false)
        validationComplete.current = true
      }).catch((error) => {
        if (error.errorFields.length > 0) {
          disableUploadButton(true)
        }
        validationComplete.current = true
      })
     }
  }, [disableUploadButton, form])

  

  const validateAllFields = () => {
    form.validateFields().then(() => {
      disableUploadButton(false)
    }).catch((error) => {
      if (error.errorFields.length > 0) {
        disableUploadButton(true)
      }
    })
  }
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      //toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
      validateAllFields()
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
      validateAllFields()
    }
  };

  const validateCustomValue = (dataIndex, value) => {
    return new Promise((resolve, reject) => {
      const duplicateExists = dataSource.some((item) => item[dataIndex] === value && item.key !== record.key);
      const hasDulicatesInData = hasDuplicateNames(dataSource);
      
      if (!duplicateExists) {
        resolve(); // Validation passes
      } if (duplicateExists && !hasDulicatesInData) {
        resolve();
      } else {
        reject('Name already exists'); // Validation fails
      }
    });
  };

  

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        value={record?.name}
        name={dataIndex}
        initialValue={record[dataIndex]} // Set initial value of the input field
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
          {
            validator: (_, value) => validateCustomValue(dataIndex, value)
          }
        ]}
      >
        <Input defaultValue={record?.name} ref={inputRef} onPressEnter={save} onBlur={save} onChange={save} />
      </Form.Item>
    ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }
  return <td {...restProps}>{childNode}</td>;
};

const TranslationModal = ({
  supportedLanguages,
  defaultLanguage,
  setShowModal,
  showModal,
  textComponentsWithTranslations,
  value,
  updateComponentName,
}) => {
  const [dataSource, setDataSource] = useState(textComponentsWithTranslations)
  const [sampleData, setSampleData] = useState([])
  const [headers, setHeaders] = useState([])
  const [disableUploadBtn, setDisableUploadBtn] = useState(false)
  const validationComplete = useRef(false)
  const defaultColumns = [
    {
      title: 'Enter component name',
      dataIndex: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: 'Preview content',
      dataIndex: 'value',
      render: (value) => <div dangerouslySetInnerHTML={{ __html: value[defaultLanguage] }} />
    },
  ];

  const uniqueNames = new Set();

  const generateUniqueName = (name) => {
    let newName = name;
    let counter = 1;

    while (uniqueNames.has(newName)) {
      newName = `${name}${counter}`;
      counter++;
    }

    uniqueNames.add(newName);
    return newName;
  };

  
  useEffect(() => {
    textComponentsWithTranslations?.forEach((component) => {
      component.name = generateUniqueName(component.name);
    });
    setDataSource(textComponentsWithTranslations)
    setUploadData(textComponentsWithTranslations)   
  }, [textComponentsWithTranslations])

  // useEffect(() => {
  //   setDataSource(dataSource)
  //   setUploadData(dataSource) 
  // },[dataSource])

  const setUploadData = (data) => {
    let sampleData = [];
    const languages = COUNTRY_LANGUAGES.filter(lang => supportedLanguages.includes(lang.code)).map(l => l.language)
    const defLanguage = COUNTRY_LANGUAGES.find(lang => defaultLanguage === lang?.code).language
    data.map((component, idx) => {
      const newData = {
        "Component": component.name,
        [defLanguage]: component.value[defaultLanguage]
      };
      languages.forEach(lang => {
        if (lang !== defLanguage) {
          const langCode = COUNTRY_LANGUAGES.find(l => l.language === lang)?.code;
          newData[lang] = component.value[langCode] || ""; // Initialize other language keys with empty string
        }
      });

      sampleData.push(newData)
    })
    const csvContent = (sampleData.length>0)?convertToCSV(sampleData):"";
    setSampleData(csvContent);
    validationComplete.current = false;
  }


  const translationUploadData = sampleData.length > 0 ? sampleData : [];
  const csvHeaders = headers.length > 0?headers:[]

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };


  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const disableUploadButton = (disabled) => {
    setDisableUploadBtn(disabled)
  }

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        dataSource: dataSource,
        disableUploadButton: disableUploadButton,
        validationComplete: validationComplete
      }),
    };
  });

  const updateComponentText = () => {
    updateComponentName(dataSource)
    setShowModal(false)
    validationComplete.current = true;
  }

  const handleDownload = () => {
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), translationUploadData], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = new Date() + 'LanguageTranslation.csv';
    a.click();
    URL.revokeObjectURL(url);
    updateComponentText()
  };

  return (
    <Modal
      wrapClassName={'translation-modal'}
      title={"Rename Components"}
      visible={showModal}
      open={showModal}
      onOk={() => handleDownload()}
      okButtonProps={{ disabled: disableUploadBtn }}
      okText={"Update & Download"}
      onCancel={() => {
        setShowModal(false)
        validationComplete.current = true
      }}>
      <div>
        <p>In order to upload bulk translations, the selected components need to have
        unique names. We have provided a unique default name for each component below.
          You can choose to accept those names or change them, and then download the file.</p>
        <h6>Selected Components: {textComponentsWithTranslations?.length}</h6>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </div>
    </Modal>
  );
};

export default TranslationModal;