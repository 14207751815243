import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Button, Select, Divider } from 'antd';
import { CloseOutlined , WarningOutlined } from '@ant-design/icons';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { VendorList } from '../../../../records/vendor_list_records';
import { Site } from '../../../../records/site_records';
import SelectSitesAndSiteGroups from '../../../common/SelectSitesAndSiteGroups';
const Option = Select.Option;

export default class ManageSites extends React.Component {
  static propTypes = {
    vendorScanning: PropTypes.bool.isRequired,
    siteAccess: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    vl: PropTypes.instanceOf(VendorList).isRequired,
    toggleScanVendors: PropTypes.func.isRequired,
    handleSelectSite: PropTypes.func.isRequired,
    removeSiteByName: PropTypes.func.isRequired,
    vendorScanningV2: PropTypes.bool.isRequired, 
    setFilterChangedVendors: PropTypes.func.isRequired,
    setFilterDeletedVendors: PropTypes.func.isRequired,
  };

  render() {

    const selectSites = (
      <div className='select-sites-container'>
        <SelectSitesAndSiteGroups
          value={this.props.vl.siteIds}
          sites={this.props.sites}
          siteGroups={this.props.siteGroups}
          onSelect={this.props.handleSelectSite}
          multiple
          disabled={this.props.readOnly}
          siteAccess={this.props.siteAccess}
          placeholder={'none'}
        />
      </div>
    );

    let scanForNewVendors;
    if (this.props.vendorScanningV2 && !this.props.readOnly) {
      scanForNewVendors = (
        <div onClick={this.props.toggleScanVendors} role="presentation">
          <p className='scan-for-new-vendors'>Website Vendor Scan</p>
        </div>
      );
    } else if (this.props.vendorScanning && !this.props.readOnly) {
      scanForNewVendors = (
        <div onClick={this.props.toggleScanVendors} role="presentation">
          <p className='scan-for-new-vendors'>Scan for new vendors</p>
        </div>
      );
    }

    

    return (
      <div className="vendor-sites">
        <div className="vendor-site-list">
          <span>Select property(s) this list applies to: </span>
        </div>
        <div className="top-of-the-table-container">
          {selectSites}
        </div>
      </div>
    );
  }
}
