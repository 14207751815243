import { Record, fromJS } from 'immutable';
import moment from 'moment';

export class ChangeLog extends Record({
  account_id: '',
  username: '',
  op_name: '',
  entity_type: '',
  entity_id: '',
  entity_name: '',
  parent_id: '',
  parent_name: '',
  site_ids: [],
  site_names: [],
  date: ''
}) {
  constructor(changeLog) {
    const cleanLog = {
      ...changeLog,
      entity_type: cleanEntityType(changeLog.entity_type),
      op_name: caseWord(changeLog.op_name),
      date: formatDate(changeLog),
      parent_id: changeLog.parent_id || 'N/A',
      parent_name: changeLog.parent_name || 'N/A',
      site_ids: changeLog.entity_type === 'site' ? [changeLog.entity_id] : changeLog.site_ids,
      site_names: changeLog.entity_type === 'site' ? [changeLog.entity_name] : changeLog.site_names,
    }
    
    super(fromJS(cleanLog));

    function cleanEntityType(entity) {
      return entity.includes('_')
        ? caseWord(entity, '_')
        : (entity.includes('-') ? caseWord(entity, '_') : caseWord(entity))
    }

    function caseWord(entity, splitOn) {
      return splitOn
        ? entity.split(splitOn).map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
        : entity[0].toUpperCase() + entity.slice(1)
    }


    function formatDate({ y, m, d, h, min = 0 }) {
      return moment(new Date(y, m - 1, d, h, min)).format('YYYY-MM-DD HH:mm') + ' GMT';
    }
  }
}