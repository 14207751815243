import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import { Input, Button } from "antd";
import { getAllSites } from "../../../actions/site_actions";
import { User } from "../../../records/account_records";
import DropdownPagesHeader from "../../common/DropdownPagesHeader";
import {
  getAccountPermissions,
  getPermissionsList,
  getAllAccounts,
  getDiagnoseProperties
} from "../../../actions/account_actions";
import ManageUsersTable from "./ManageUsersTable";
import InviteUserModal from "./InviteUserModal";
import Loading from "../../common/Loading.js";

class ManageUsers extends React.Component {
  static propTypes = {
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      allOtherUsers: ImmutablePropTypes.listOf(User).isRequired,
    }).isRequired,
    getAllAccounts: PropTypes.func.isRequired,
    getAccountPermissions: PropTypes.func.isRequired,
    getPermissionsList: PropTypes.func.isRequired,
  };

  state = {
    editingUserId: null,
    inviteModalOpen: false,
    userFilter: "",
  };
  
  componentDidMount() {
    this.props.getAccountPermissions();
    this.props.getPermissionsList();
    this.props.getDiagnoseProperties();
    if (this.props.currentUser && (this.props.currentUser.accountId === 22 || this.props.currentUser.accountId === 375)) {
      this.props.getAllAccounts();
      this.props.getAllSites({ allSites: true });
    }
  }

  toggleInviteModal = () => {
    this.setState({ inviteModalOpen: !this.state.inviteModalOpen });
  };

  closeInviteModal = () => {
    this.setState({ inviteModalOpen: false });
  };

  handleSeachUser = (e) => {
    this.setState({ userFilter: e.target.value.trim() });
  };

  updatingEditingUserId = (id) => {
    this.setState({ editingUserId: id });
  };

  render() {
    if (
      !this.props.users ||
      !this.props.currentUser ||
      (this.props.currentUser.accountId === 22 && !this.props.allAccounts.size)
    ) {
      return <Loading />;
    }
    return (
      <div>
        <DropdownPagesHeader name="Manage Users" />
        <div className="manage-users-container">
          <div className="header-container">
            <Input
              className="search-users"
              placeholder="Search users"
              value={this.state.userFilter}
              onChange={this.handleSeachUser}
            />
            <Button
              onClick={this.toggleInviteModal}
              className="invite-button"
              disabled={!!this.state.editingUserId}
            >
              + Invite user
            </Button>
          </div>
          <ManageUsersTable
            userFilter={this.state.userFilter}
            editingUserId={this.state.editingUserId}
            setEditingUserId={this.updatingEditingUserId}
            currentUser={this.props.currentUser}
            sites={this.props.sites}
            siteGroups={this.props.siteGroups}
            permissionsList={this.props.permissionsList}
            diagnoseProperties={this.props.diagnoseProperties}
            getAllSites={this.props.getAllSites}
            getAllAccounts={this.props.getAllAccounts}
            allUsers={this.props.users}
            allAccounts={this.props.allAccounts}
          />
          <InviteUserModal
            inviteModalOpen={this.state.inviteModalOpen}
            toggleInviteModal={this.toggleInviteModal}
            closeInviteModal={this.closeInviteModal}
            currentUser={this.props.currentUser}
            sites={this.props.sites}
            siteGroups={this.props.siteGroups}
            permissionsList={this.props.permissionsList}
            diagnoseProperties={this.props.diagnoseProperties || []}
            invitedUser={this.props.invitedUser}
            pendingRequestsMap={this.props.pendingRequestsMap}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(["userDetails", "value"]),
    sites: store.siteState.getIn(["sites", "value"]),
    siteGroups: store.siteState.getIn(["siteGroups", "value"]),
    permissionsList: store.accountState.getIn(["permissionsList", "value"]),
    diagnoseProperties: store.accountState.getIn(["diagnoseProperties", "value"]),
    users: store.accountState.getIn(["allUsers", "value"]),
    allAccounts: store.accountState.getIn(["allAccounts", "value"]),
    invitedUser: store.accountState.getIn(["invitedUser", "value"]),
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(["sites", "pending"]),
      invitedUser: store.accountState.getIn(["invitedUser", "pending"]),
      allAccountsPending: store.accountState.getIn(["allAccounts", "pending"]),
    }),
  };
};

export default connect(mapStateToProps, {
  getAllSites,
  getAllAccounts,
  getAccountPermissions,
  getPermissionsList,
  getDiagnoseProperties,
})(ManageUsers);
