import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Select,
  Tooltip,
  Switch,
} from 'antd';
import { Link } from 'react-router';
import CustomButton from '../../../common/CustomButton.js.jsx';
import { ConsentCompensationList } from '../../../../records/consent_compensation_records';

const Option = Select.Option;

export default class Header extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    toggleIsActive: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    vl: PropTypes.instanceOf(ConsentCompensationList).isRequired,
  }
  render() {

    let saveButton;
    if (!this.props.readOnly) {
      saveButton = (
        <CustomButton
          type={ CustomButton.types.SUCCESS }
          onClick={ this.props.handleSave }
          disabled={ this.props.readOnly }
        >
          Save
        </CustomButton>
      );
    }
    const rightTitle = (
      <React.Fragment>
        { saveButton }
      </React.Fragment>
    );

    const activeSwitch = (
      <div className="active-switch">
        <div>
          { this.props.vl.isActive ? 'Active' : 'Inactive' }
        </div>
        <Switch
          onChange={ this.props.toggleIsActive }
          checked={ this.props.vl.isActive }
          disabled={ this.props.readOnly }
        />
      </div>
    );

    return (
      <div className="vendor-header">
        { activeSwitch }

        <div className="vendor-header-nav">
          <Link to={`/consent_v2/compensation_list`}>&lt;&nbsp;&nbsp;Back to Consent Compensation Lists</Link>
        </div>

        <div className="vendor-top">
          <div className="vendor-top-left">
            <div className="title-editor">
              <i className="fas fa-pen" />
              <Input
                value={ this.props.vl.name }
                onChange={ (e) => this.props.handleChange(e, 'name') }
                placeholder="Consent Compensation List Name..."
                className="vendor-top-title"
                disabled={ this.props.readOnly }
              />
            </div>
          </div>
          <div className="vendor-top-right">
            { rightTitle }
          </div>
        </div>
      </div>
    );
  }
}
