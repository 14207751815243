import 'whatwg-fetch';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

export const masqAsClient = (accountId) => {
  return fetch(`${permissionsSvcBaseUrl}/authentication/masq`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "subAccountId": accountId })
  })
    .then(() => window.location.replace('/'))
    .catch(function(error) {
      console.log('Looks like there was a problem: \n', error);
    });
};