import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import {
  getPrivacyManagerList,
  deletePrivacyManager,
  clonePrivacyManager
} from '../../../actions/privacy_manager_actions.js';
import Loading from '../../common/Loading.js.jsx';
import PrivacyManagerRow from './PrivacyManagerRow';
import { PrivacyManager } from '../../../records/privacy_manager_records';
import CustomButton from '../../common/CustomButton.js.jsx';
import CreateButton from '../../common/CreateButton.js.jsx';
import { User } from '../../../records/account_records';

class PrivacyManagerList extends React.Component {
  static propTypes = {
    privacyManagerList: ImmutablePropTypes.listOf(PropTypes.instanceOf(PrivacyManager)).isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      v2: PropTypes.bool,
    }).isRequired,
    getPrivacyManagerList: PropTypes.func.isRequired,
    deletePrivacyManager: PropTypes.func.isRequired,
    clonePrivacyManager: PropTypes.func.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      !this.props.currentUser.siteAccess
      ? this.props.getPrivacyManagerList(this.props.currentUser.accountId)
      : this.props.getPrivacyManagerList(this.props.currentUser.accountId, this.props.currentUser.siteAccess);
    }
  }

  handleCreate = () => {
    const v2 = this.props.route.v2 ? '_v2' : '';
    browserHistory.push(`/consent${v2}/privacy_manager/create`);
  };

  render() {
    if (this.props.pendingRequestsMap.some(request => request)) {
      return <Loading />;
    }

    let readOnly = false;
    if (this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('privacy_manager_mgmt')) {
      readOnly = true;
    }

    let content;
    if (this.props.privacyManagerList.size) {
      content = (
        <ul>
          {
            this.props.privacyManagerList.map(pm => (
              <li>
                <PrivacyManagerRow
                  key={ pm.id }
                  privacyManager={ pm }
                  handleDelete={ this.props.deletePrivacyManager }
                  accountId={ this.props.currentUser.accountId }
                  readOnly={ readOnly }
                  v2={ this.props.route.v2 }
                  handleClone={this.props.clonePrivacyManager}
                />
              </li>
            ))
          }
        </ul>
      )
    } else {
      content = !readOnly
        ? content = (
          <div className='no-lists-message-container'>
            <p className='title'>
              You haven’t created a Privacy Manager yet.
            </p>
            <p className='descr'>
              Configure privacy settings by adding custom styles, branding and adding a vendor list.
            </p>
            <CustomButton className='create-button' onClick={this.handleCreate}>Get Started</CustomButton>
          </div>
        )
        : content = (
          <div className='no-lists-message-container'>
            <p className='title'>
              There are no Privacy Managers available.
            </p>
          </div>
        );
    }

    let createButton;
    if (!readOnly) {
      createButton = (
        <CreateButton onClick={this.handleCreate}>
          NEW
        </CreateButton>
      );
    }
    return (
      <div className='vendor-lists-container'>
        {createButton}
        <p className='main-title'>Privacy Manager</p>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    privacyManagerList: store.privacyManagerState.getIn(['privacyManagerList', 'value']),
    pendingRequestsMap: new Map({
      privacyManagerList: store.privacyManagerState.getIn(['privacyManagerList', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getPrivacyManagerList,
    deletePrivacyManager,
    clonePrivacyManager
  },
)(PrivacyManagerList);
