import React from 'react';
import { LeftOutlined } from '@ant-design/icons';

export default class SiteGroupRedirect extends React.Component {
  render() {
    return (
      <div className="redirect-to-group-wrapper">
         <span className="redirect-text" onClick={()=>this.props.redirectToGroup()}>
           <LeftOutlined /> Back to Property Group ({this.props.siteGroup.name})
         </span>
      </div>
    );
  }
}
