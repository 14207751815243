import PropTypes from 'prop-types';
import React from 'react';
import { numberWithCommas } from '../../../utils.js';
import CustomTable from '../../../common/CustomTable.js.jsx';
import TableBody from '../../../common/TableBody.js.jsx';
import TableRow from '../../../common/TableRow.js.jsx';
import TableCell from '../../../common/TableCell.js.jsx';


export default class extends React.Component {
    static propTypes = {
        viewCount: PropTypes.number.isRequired,
        messageName: PropTypes.string.isRequired,
    };

    render() {

        return (
            <CustomTable className="message-view-count">

             <TableBody>
               <TableRow>
                 <TableCell>{ this.props.messageName }</TableCell>
                 <TableCell>{ numberWithCommas(this.props.viewCount) }</TableCell>
               </TableRow>
             </TableBody>

           </CustomTable>
        )
    }
}
