import PropTypes from 'prop-types';
import React from 'react';
import { PrivacyManager } from '../../../records/privacy_manager_records';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button } from 'antd';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import WarningMessage from '../../common/WarningMessage.js.jsx';
import { browserHistory } from 'react-router';

export default class PrivacyManagerRow extends React.Component {
  static propTypes = {
    privacyManager: PropTypes.instanceOf(PrivacyManager).isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleClone: PropTypes.func.isRequired,
    v2: PropTypes.bool.isRequired,
  }

  state = {
    warningMessagePresent: false,
  };

  handleEdit = () => {
    const v2 = this.props.v2 ? '_v2' : '';
    browserHistory.push(`/us_privacy/privacy_manager/edit?id=` + this.props.privacyManager.id);
  };

  handleDelete = (e) => {
    //get rid of then
    e.stopPropagation();
    this.props.handleDelete(this.props.accountId, this.props.privacyManager).then(this.setState({ warningMessagePresent: false }));
  }

  handleClone = (e) => {
    e.stopPropagation();
    this.props.handleClone(this.props.accountId, this.props.privacyManager.id)
  }

  toggleWarningMessage = (e) => {
    e.stopPropagation();
    this.setState({ warningMessagePresent: !this.state.warningMessagePresent });
  }

  render() {
    let editButtonText;
    let editDropdown
    if (!this.props.readOnly) {
      const editMenu = (
        <Menu className="buttons-group">
          <Menu.Item>
            <CustomIcon
              type={CustomIcon.types.COPY}
              onClick={this.handleClone}
              tooltip="clone"
            />
          </Menu.Item>
          <Menu.Item>
            <div
              onClick={this.toggleWarningMessage}
              className='menu-item-container'
            >
              <CustomIcon
                type={CustomIcon.types.DELETE}
                onClick={this.toggleWarningMessage}
                tooltip="delete"
              />
            </div>
          </Menu.Item>
        </Menu>
      );
      editDropdown = (
        <Dropdown
          overlay={editMenu}
          onOpenChange={this.handleVisibleChange}
          overlayStyle={{ zIndex: '99999' }}
        >
          <EllipsisOutlined style={{ float: 'right' }} />
        </Dropdown>
      );
      editButtonText = 'Edit';
    } else {
      editButtonText = 'View';
    }

    let warningMessage;
    if (this.state.warningMessagePresent) {
      warningMessage = (
        <WarningMessage
          handleDelete={this.handleDelete}
          name={this.props.privacyManager.name}
          handleCancel={this.toggleWarningMessage}
        />
      )
    }

    return (
      <div
        className='vendor-list-container'
        onClick={this.handleEdit}
      >
        {warningMessage}
        <div className='name-container pm'>
          <p className='name'>{this.props.privacyManager.name}</p>
        </div>
        <div className='menu-container'>
          <Button
            onClick={this.handleEdit}
            className='edit'
          >
            {editButtonText}
          </Button>
          {editDropdown}
        </div>
      </div>
    );
  }
}
