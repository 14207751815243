import React from 'react';
import CSVLink from "../../common/CSVLink.js";
import { List, Map } from 'immutable';
import { Upload } from 'antd';
import { Domain } from '../../../records/domain_set_records';
import { message } from '../../../styleguide';

const { Dragger } = Upload;

export default class DomainsFileUpload extends React.Component {
  state = {
    uploadAction: {},
    fileMessage: null,
  }

  handleOnOk = () => {
    this.props.updateFile()
    this.props.closeModal();
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  beforeUpload = async (file) => {
    const reader = new FileReader();

    reader.readAsText(file);
    const isCSV = file.type === 'text/csv';
    if (!isCSV) {
      message.error(<><strong>{file.name}</strong>is not a .csv file.</>);
      return  Upload.LIST_IGNORE;
    }

    reader.onload = e => {
      var csv = e.target.result;

      const domainStrings = csv.split(/\r\n|\n/).filter(d => !!d);
      let ruleIds;
      if (this.props.goldenSet) {
        const properties = domainStrings.map((string, i) => {
          if (i === 0) {
            ruleIds = string.trim().split(',').splice(1);
            if (!ruleIds[ruleIds.length - 1]) ruleIds.pop();
          } else {
            const date = new Date();
            const propertyArray = string.split(',');
            if (!propertyArray[propertyArray.length - 1]) propertyArray.pop();
            let property = new Domain ({ url: propertyArray.shift(0) });
            const goldenScores = {};
            propertyArray.forEach((score, i) => {
              if (score) {
                const ruleId = ruleIds[i].toLowerCase().trim();
                goldenScores[ruleId] = parseInt(score);
              }
            });

            const manualScore = new Map({ 
              scores: new Map(goldenScores),
              userId: this.props.currentUser.id,
              userName: this.props.currentUser.name,
              dateScored: date,
            });
            return property.set('manualScore', manualScore);
          }
        });
        this.props.addDomains(properties.splice(1));
      } else {
        const domainStringsUnique = [...new Set(domainStrings.map(ds => ds.replace(/www.|http:\/\/|https:\/\//g, '')))]
        const domains = new List(domainStringsUnique.map(string => {
          let [url, impressions] = string.split(',');
          if (!impressions) impressions = null;
          //XXX unique
          return new Domain ({ url: this.isJson(url) ? JSON.parse(url) : url, impressions });
        }));
        this.props.addDomains(domains);
      }
    };
    // Prevent upload
    return false;
  };

  isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  render() {
    const uploadProps = {
      customRequest: this.dummyRequest,
      name: 'file',
      accept: '.csv',
      multiple: true,
      onDownload: this.onFileDownload,
      beforeUpload: this.beforeUpload,
    };
    let exampleData;
    if (this.props.goldenSet) {
      exampleData = [
        ['Properties', 'r1', 'r2', 'r3'],
        ['https://www.sourcepoint.com', '1', '1', '0'],
        ['https://www.ebay.com', '1', '0', '-1'],
        ['https://www.economist.com']
      ];
    } else {
      exampleData = [
        ['https://www.sourcepoint.com'],
        ['https://www.ebay.com'],
        ['https://www.economist.com']
      ];
    }
    
    return (
      <Dragger {...uploadProps} disabled={this.props.disabled}>
        <p className='drop-files'>Drop file(s) here or Click</p>
        Download a <CSVLink
          data={exampleData}
          filename={new Date() + ' data.csv'}
          onClick={(e) => e.stopPropagation()}
        >
          Sample CSV Template 
        </CSVLink> to see an example of the format required.
      </Dragger>
    );
  }
}