import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List } from 'immutable';
import classNames from 'classnames';
import moment from 'moment';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  FileOutlined,
  MinusOutlined,
  SaveOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import Loading from '../../common/Loading.js.jsx';
import { Table, Button, Input, Radio, Menu, Select, Checkbox, Dropdown, Tooltip, message } from 'antd';
import { Link } from 'react-router';
import CSVLink from "../../common/CSVLink.js";
import PDFDownload from '../../ccpa/ccpa_reporting/PDFDownload';
import FrequencyChangeModal from './FrequencyChangeModal';
import { PRIVACY_LENS_RULES_IMAGE_EXIST } from '../../../constants';

import { getRules } from '../../../actions/standard_actions';
import { getAdminDomains, changeScanPeriod, rescanProperties } from '../../../actions/domain_score_actions';

const Search = Input.Search;

class DomainSetsPage extends React.Component { 
  static propTypes = {
    getAdminDomains: PropTypes.func.isRequired,
    getRules: PropTypes.func.isRequired,
    changeScanPeriod: PropTypes.func.isRequired,
    rescanProperties: PropTypes.func.isRequired,
  }

  state = {
    search: '',
    reportType: 'All',
    undeterminedsAs: 'failed',
    selectedDomainIds: new List([]),
    frequencyChangeModalOpen: false,
    fetching: true,
    dropdownVisible: false,
    loading: true,
    tableData: null,
    hoveredRule: null,
    filteredInfo: null,
    hideCCPA: false,
    hideGDPR: false,
    selectAllActivated: false,
  }

  onCurrentUserAvailableDo = () => {
    const accountId = this.props.currentUser.accountId;
    this.props.getRules();
    this.props.getAdminDomains(accountId);
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (!prevProps.domainSet && this.props.domainSet) {
  //     this.setState({ selectedStandard: this.props.domainSet.standards.first()});
  //   }

  //   if (
  //     (this.props.domainSet && this.props.adminDomains) 
  //     && (((this.props.domainSet !== prevProps.domainSet) || (this.props.adminDomains !== prevProps.domainScores))
  //     || this.state.editScoresForDomainId !== prevState.editScoresForDomainId
  //     || this.state.editedScoresForSingleDomain !== prevState.editedScoresForSingleDomain
  //     || this.state.selectedStandard !== prevState.selectedStandard
  //     || this.state.search !== prevState.search
  //     || !this.state.tableData)
  //   )  {
  //     // if (this.state.selectedStandard !== prevState.selectedStandard) {
  //     //   this.setState({ loading: true });
  //     // }
  //     const tableData = this.generateTable();
  //     this.setState({ tableData, loading: false });
  //   }
  // }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
    // if (this.state.search !== prevState.search
    //   || prevProps.pendingRequestsMap.get('adminDomains') && !this.props.pendingRequestsMap.get('adminDomains')
    //   ) {
    //   const tableData = this.generateTable();
    //   this.setState({ tableData, loading: false });
    // }
  }

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  selectAllProperties = () => {
    if (this.state.selectAllActivated) {
      this.setState({ 
        selectedDomainIds: new List([]),
        selectAllActivated: false,
      });
    } else {
      let filteredProperties = this.props.adminDomains;
      for (const key in this.state.filteredInfo) {
        const val = this.state.filteredInfo[key];
        if (val) {
          if (key === 'scanFrequency') {
            filteredProperties = this.props.adminDomains.filter(domain => {
              return val.includes(domain.period.slice(0,2));
            });
          }
          if (key !== 'scanFrequency') {
  
            const ruleId = this.props.rules.find(r => r.id === key).ruleId;
            filteredProperties = this.props.adminDomains.filter(domain => {
              const score = domain.scores && domain.scores.get(ruleId);
              let scoreReadable;
              if (score === 1) {
                scoreReadable = 'CheckOutlined';
              } else if (score === 0) {
                scoreReadable = 'CloseOutlined';
              } else if (score === -1) {
                scoreReadable = 'N/A';
              } else {
                scoreReadable = 'MinusOutlined';
              }
  
              return val.includes(scoreReadable);
            });
          }
        }
      }
  
      this.setState({ 
        selectedDomainIds: filteredProperties.map(p => p.id),
        selectAllActivated: true,
      });
    }
  }

  generateTable = () => {

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    let selectAllButton;
    if (this.props.currentUser.accountId === 22 && this.props.currentUser.isAdmin) {
      selectAllButton = <Button onClick={ this.selectAllProperties }>{ this.state.selectAllActivated ? 'Deselect All' : 'Select All' }</Button>
    }

    let columns = List([
      Map({
        title: <div><p>Properties</p>{ selectAllButton }</div>,
        dataIndex: 'domains',
        key: 'domains',
        width: '180px',
        fixed: 'left',
      }),
      Map({
        title: 'Rules Scored',
        dataIndex: 'rulesScored',
        key: 'rulesScored',
        width: '100px',
        fixed: 'left',
        sorter: (a, b) => { 
          return parseInt(a.rulesScored.slice(0,2)) - parseInt(b.rulesScored.slice(0,2))
        },
      }),
      Map({
        title: 'Scan Frequency',
        dataIndex: 'scanFrequency',
        key: 'scanFrequency',
        width: '100px',
        fixed: 'left',
        filteredValue: filteredInfo.scanFrequency || null,
        filters: [
          { text: '30 (month)', value: '30' },
          { text: '45', value: '45' },
          { text: '60 (2 months)', value: '60' },
          { text: '75', value: '75' },
          { text: '90 (3 months)', value: '90' },
        ],
        onFilter: (value, record) => {
          const recordClean = record.scanFrequency.slice(0,2);
          return recordClean.includes(value);
        },
      }),
      Map({
        title: 'Last Scanned On',
        dataIndex: 'lastScanned',
        key: 'lastScanned',
        width: '160px',
        fixed: 'left',
        sorter: (a, b) => { 
          return new Date(a.lastScanned) - new Date(b.lastScanned)
        },
      }),
      Map({
        title: 'No of Property Sets',
        dataIndex: 'numSets',
        key: 'numSets',
        width: '100px',
        fixed: 'left',
        sorter: (a, b) => { 
          return a.numSets - b.numSets;
        },
      }),
    ]);

    let rules = this.props.rules;
    if (this.state.hideCCPA) {
      rules = rules.filterNot(r => r.type === 'CCPA')
    }
    if (this.state.hideGDPR) {
      rules = rules.filterNot(r => r.type === 'GDPR')
    }
  
    // XXX assign width to rule
    // const ruleColumnSize = 1000 - this.state.selectedStandard.rules.size
    columns = columns.concat(rules.map(r => {
      return Map({
        title: (
          <div 
            onMouseOver={ () => this.setState({ hoveredRule: r })}
            onMouseLeave={ () => this.setState({ hoveredRule: null })}
          >
            { r.name }
          </div>
        ),
        dataIndex: r.id,
        key: r.id,
        className: 'rule-column',
        filteredValue: filteredInfo[r.id] || null,
        filters: [
          { text: 'pass', value: 'CheckOutlined' },
          { text: 'fail', value: 'CloseOutlined' },
          { text: 'undetermined', value: 'N/A' },
          { text: 'not aplicable', value: 'MinusOutlined' },
        ],
        onFilter: (value, record) => {
          let recordClean = record[r.id];
          if (!recordClean) recordClean = 'MinusOutlined';
          if (record[r.id] && record[r.id] !== 'N/A') {
            recordClean = record[r.id].type.render.name;
          }
          return recordClean.includes(value);
        },
      });
    }));
  
    let domains = this.props.adminDomains;
    if (this.state.search) {
      domains = domains.filter(d => d.url.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    // domains = domains.sort((d1, d2) => {
    //   return (d2.impressions || this.props.averageImpressions) - (d1.impressions || this.props.averageImpressions);
    // });
  
    const dataSource = domains.map((d) => {
  
      let detailsIcon = (
        <EditOutlined onClick={ () => { this.setState({ editScoresForDomainId: d.id })}} />
      );
  
      if (this.state.editScoresForDomainId === d.id) {
        detailsIcon = (
          <div>
            <CloseOutlined
              onClick={ () => { this.setState({ editScoresForDomainId: null, editedScoresForSingleDomain: new Map({}) })}} />
            <SaveOutlined onClick={ this.saveScoresForSingleDomain } />
          </div>
        );
      }
      // const domainScore = this.props.adminDomains.scores.find(score => score.domainId === d.id);
      const allRules = this.props.rules.size;
      let row = Map({
        key: d.id,
        domains: d.url,
        rulesScored: `${(d.scores && d.scores.size > 1) ? d.scores.size - 1 : 0}/${allRules}`,
        scanFrequency: `${parseFloat(d.period)} Days`,
        lastScanned: d.lastScanned ? moment(d.lastScanned).format("LLL") : 'not scanned',
        numSets: d.numDomainSets,
      });
      
      this.props.rules.forEach(r => {
        let icon;

        const domainScore = d.scores;
  
        if (domainScore) {
          const score = domainScore.get(r.ruleId);
          if (score === 1) {
            icon = <CheckOutlined style={{ color: 'green' }} />;
          } else if (score === 0) {
            icon = <CloseOutlined style={{ color: 'red' }} />;
          } else if (score === -1) {
            icon = 'N/A';
          } else {
            icon = <MinusOutlined style={{ color: 'gray' }} />;
          }
        }
  
        row = row.set(r.id, icon);
      });
     
      return row;
    });
  
    return new Map({ dataSource, columns });
  }

  updateSearch = (e) => this.setState({ search: e.target.value });

  toggleSelectedDomain = (checked, id) => {
    let updated;
    if (checked) {
      updated = this.state.selectedDomainIds.push(id);
    } else {
      updated = this.state.selectedDomainIds.filterNot(id);
    }
    this.setState({ selectedDomainIds: updated });
  }

  selectDomainRule = (score, ruleId) => {
    const updated = this.state.editedScoresForSingleDomain.set(ruleId, parseInt(score));
    this.setState({ editedScoresForSingleDomain: updated });
  }

  saveScoresForSingleDomain = () => {
    // this.props.changeDomainScoresRedux({ domainSetId: this.props.domainSet.id, domainIds: new List([this.state.editScoresForDomainId]), rules: this.state.editedScoresForSingleDomain });
    this.props.changeDomainScores(this.props.domainSet.id, new List([this.state.editScoresForDomainId]), this.state.editedScoresForSingleDomain).then(() => {
      this.props.getDomainSetScores(this.props.currentUser.accountId);
    });
    this.setState({ editScoresForDomainId: null, editedScoresForSingleDomain: new Map({}) });
  }

  clearSelectedDomains = () => {
    this.setState({ selectedDomainIds: new List([]) });
  }

  rescan = () => {
    this.props.rescanProperties(this.props.currentUser.accountId, this.state.selectedDomainIds).then(r => {
      message.success(`${this.state.selectedDomainIds.size} properties were sent for rescan`);
      this.clearSelectedDomains();
    });
  }

  renderTopRuleList = (kind, topRules) => {

    const decimal = this.props.adminDomains.scores.size / 100;

    return (
      <div className='list'>
        <p>{ `Top ${kind} Rules` }</p>
        <ul>
          { topRules[kind].map(r => {
            
            if (r.top[kind] === 0) return null;
            
            const percent = decimal === 0 ? 0 : (parseInt((r.top[kind] / decimal)))

            return (
              <li>
                <p><span className='percent'>{ `${percent}% ` }</span><span className='rule-name'>{ r.name }</span></p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // generateDataForCsvReport = (tableData) => {
  //   let dataForCSV = new List([]);
  //   const dataSource = tableData.get('dataSource');
  //   const columns = tableData.get('columns');

  //   const columnsIdx = columns.map(c => c.get('dataIndex')).filterNot(idx => idx === 'actions');
  //   const columnNamesWithoutRuleIds = new List(['domains', 'impressions', 'rulesPassed', 'dateScanned']);
  //   dataForCSV = dataForCSV.push(columns.map(c => {
  //     if (c.get('dataIndex') === 'impressions') {
  //       return 'Impressions';
  //     }
  //     if (c.get('className') === 'rule-column') {
  //       const ruleName = c.get('title').props.children;
  //       const rule = this.props.rules.find(r => r.name === ruleName);
  //       if (!rule ) debugger
  //       const ruleId = rule && rule.ruleId;
  //       return `${ruleId} - ${ruleName}`;
  //     }
  //     return c.get('title');
  //   }).filterNot(name => name === 'Actions'));
  //   dataSource.forEach(ds => {

  //     const ruleNums = ds.get('rulesPassed').split(' / ').map(num => parseInt(num));
  //     let rulesPassed = ruleNums[0];
  //     const rulesAll = ruleNums[1];
  //     if (this.state.undeterminedsAs === 'passed') {
  //       let rulesUndetermined = 0;
  //       const ruleIds = columnsIdx.filterNot(idx => ['domains', 'rulesPassed', 'dateScanned', 'impressions'].includes(idx));
  //       ruleIds.forEach(ruleId => { 
  //         if (ds.get(ruleId).props && ds.get(ruleId).type.render().props.icon.name === 'minus') {
  //           rulesUndetermined += 1;
  //         }
  //       });
  //       rulesPassed += rulesUndetermined;
  //     }
      
  //     const rulesNotPassed = rulesAll - rulesPassed;
  //     if ((this.state.reportType === 'All') || (rulesNotPassed === 0 && this.state.reportType === 'White List') || (rulesNotPassed > 0 && this.state.reportType === 'Black List')) {
  //       let row = new List();
  //       columnsIdx.forEach(idx => {
  //         let value = ds.get(idx);
  //         if (!ds.get(idx)) {
  //           value = 'n/a';
  //         } else if (typeof(ds.get(idx).type) === "function") {
  //           value = ds.get(idx).props.children;
  //         }

  //         if (!columnNamesWithoutRuleIds.includes(idx)) {

  //           value = value.type && value.type.render().props.icon.name;
  //           if (value === 'check') {
  //             value = 'passed';
  //           } else if (value === 'close') {
  //             value = 'X';
  //           } else {
  //             value = 'n/a';
  //           }
  //         }
  //         row = row.push(value);
  //       });
  //       dataForCSV = dataForCSV.push(row);
  //     }
  //   });

  //   return dataForCSV.toJS();
  // }

  changeReportType = (type) => {
    this.setState({ reportType: type });
  }

  render() {    

    if (!this.props.adminDomains || !this.props.rules.size || this.props.pendingRequestsMap.get('adminDomains') ) {
      return <Loading />;
    }


    if (this.props.pendingRequestsMap.some(request => request)) return null;

    if (!this.props.adminDomains) {
      return null;
    }

    const tableData = this.generateTable();
    // const selectedDomainSetResults = this.props.domainSetScores.find(s => s.domainSetId === this.props.location.query.id);
    // const domainsPassed = selectedDomainSetResults.scores.get(this.state.selectedStandard.id);
    // let domainsScanned;
    // if (this.state.selectedStandard.policy === 'GDPR') {
    //   domainsScanned = selectedDomainSetResults.domainsScannedGDPR;
    // }
    // if (this.state.selectedStandard.policy === 'US') {
    //   domainsScanned = selectedDomainSetResults.domainsScannedCCPA;
    // }
    // const decimal = domainsScanned / 100;

    // const percent = parseInt((domainsPassed / decimal) + 0.001);
    // const topRulesStandard = this.props.adminDomains.topRulesByStandard.find(obj => obj.standardId === this.state.selectedStandard.id);
    // let topRules;
    // if (topRulesStandard) topRules = topRulesStandard.topRules;

    // let topTable;
    // if (topRules) {
    //   topTable = (
    //     <div className='top-table'>
    //       <div className='left'>
    //         <div>
    //           <p>Properties Passed All Rules</p>
    //           <p className='number'>{`${domainsPassed} (${percent}%)`}</p>
    //         </div>
    //       </div>

    //       { this.renderTopRuleList('passing', topRules) }
    //       { this.renderTopRuleList('undetermined', topRules) }
    //       { this.renderTopRuleList('failing', topRules) }

    //     </div>
    //   );
    // }

    let dataForCSV = new List([]);
    const dataSource = tableData.get('dataSource');
    const columns = tableData.get('columns')

    const columnsIdx = columns.map(c => c.get('dataIndex')).filterNot(idx => idx === 'actions');
    const columnNamesWithoutRuleIds = new List(['domains', 'impressions', 'rulesPassed', 'dateScanned']);

    dataForCSV = dataForCSV.push(columns.map(c => c.get('title')).filterNot(name => name === 'Actions'));
    dataSource.forEach(ds => {
      let row = new List();
      columnsIdx.forEach(idx => {
        let value = ds.get(idx);
        if (!columnNamesWithoutRuleIds.includes(idx)) {
          if (value) {
            value = value.props && value.props.type;
            if (value === 'check') {
              value = 'passed';
            } else if (value === 'close') {
              value = 'X';
            } else {
              value = 'n/a';
            }
          } else {
            value = '-';
          }
        }
        row = row.push(value);
      });
      dataForCSV = dataForCSV.push(row);
    });

    const date = new Date();
    const checkbox = <CheckOutlined />;

    // const menu = (
    //   <Menu className='scoring-report'>
    //     <Menu.Item>
    //       <p className='select-type'>Select List Type</p>
    //     </Menu.Item>
    //     <Menu.Item>
    //       <Button onClick={() => this.changeReportType('All')}>All</Button>{ this.state.reportType === 'All' ? checkbox : null}
    //     </Menu.Item>
    //     <Menu.Item>
    //       <Button onClick={() => this.changeReportType('White List')}>Include List</Button>{ this.state.reportType === 'White List' ? checkbox : null}
    //     </Menu.Item>
    //     <Menu.Item>
    //       <Button onClick={() => this.changeReportType('Black List')}>Exclude List</Button>{ this.state.reportType === 'Black List' ? checkbox : null}
    //     </Menu.Item>
    //     <Menu.Item>
    //       <p className='undetermined'>* Count undetermined rules as</p>
    //       <Radio.Group onChange={(e) => {this.setState({ undeterminedsAs: e.target.value })}} value={this.state.undeterminedsAs}>
    //         <Radio value={'failed'}>
    //           failed
    //         </Radio>
    //         <Radio value={'passed'}>
    //           passed
    //         </Radio>
    //       </Radio.Group>
    //     </Menu.Item>
    //     <Menu.Item>
          
    //       <CSVLink
    //         data={ this.generateDataForCsvReport(tableData) }
    //         className='export-csv'
    //         filename={date + ' data.csv'}
    //         target=""
    //         onClick={() => {
    //           this.setState({ dropdownVisible: !this.state.dropdownVisible})
    //         }}
    //       >
    //         <FileOutlined />
    //         <p className='csv'>CSV</p>
    //       </CSVLink>
          

    //       <PDFDownload
    //         content={this.generateDataForCsvReport(tableData)}
    //         title={""}
    //         filename={date + ' data.pdf'}
    //         onClick={() => {
    //           this.setState({ dropdownVisible: !this.state.dropdownVisible})
    //         }}
    //       />
    //     </Menu.Item>
    //   </Menu>
    // );

    // const downloadReport = (
    //   <div>
    //     <Dropdown
    //       visible={this.state.dropdownVisible} 
    //       overlay={menu}
    //       className='download-report'
    //     >
    //       <a className="ant-dropdown-link"  onClick={(e) => {e.stopPropagation(); this.setState({ dropdownVisible: !this.state.dropdownVisible})}}>
    //         <DownloadOutlined />{ this.state.dropdownVisible ? 'Close Dropdown' : 'Download Report' }
    //       </a>
    //     </Dropdown>
    //   </div>
    // );

    let loading;
    // if (this.state.loading) {
    //   loading = <Loading />;
    // }

    const menu1 = (
      <Menu>
        <Menu.Item onClick={ () => this.setState({ scanFrequencyModalOpen: true })}>
          <div onClick={ () => this.setState({ frequencyChangeModalOpen: true }) }>Edit Scan Frequency</div>
          
        </Menu.Item>
        <Menu.Item>
          <div onClick={ this.rescan }>Rescan</div>
        </Menu.Item>
      </Menu>
     )

     const menu2 = (
      <Menu>
        <Menu.Item>
          <Checkbox onChange={ () => this.setState({ hideCCPA: !this.state.hideCCPA })}>Hide CCPA Rules</Checkbox>
        </Menu.Item>
        <Menu.Item>
          <Checkbox onChange={ () => this.setState({ hideGDPR: !this.state.hideGDPR })}>Hide GDPR Rules</Checkbox>
        </Menu.Item>
      </Menu>
     )
      
    let frequencyChangeModal;
    if (this.state.frequencyChangeModalOpen) {
      frequencyChangeModal = (
        <FrequencyChangeModal
          updateProperties={this.props.changeScanPeriod}
          selectedDomainIds={this.state.selectedDomainIds}
          closeModal={ () => {
            this.setState({ frequencyChangeModalOpen: false });
            this.clearSelectedDomains();
          }}
          accountId={ this.props.currentUser.accountId }
        />
      );
    }

    return (
      <div className='domain-sets-container privacy-lens scoring admin-privacy-lens'>
        {/* <div className='properties-count flex-row'>
          <p>Total Properties Scanned: <span>{domainsScanned}</span></p>
          <p>Properties Passed: <span>{domainsPassed}</span></p>
          <p>Properties Failed: <span>{domainsScanned - domainsPassed}</span></p>
        </div> */}


        { loading }

        { frequencyChangeModal }

        {/* { downloadReport }

        { topTable }

       */}


        <div className='table-box-shadow'>
          <div className='search-container'>
            <Search
              placeholder="Search properties"
              onChange={this.updateSearch}
              className="vendor-filter"
              style={{ width: 190 }}
            />
            <div className='top-bar-left'>
              <p className='message'>Apply filters and select all or specific properties to perform bulk actions</p>
              <Button onClick={this.clearFilters}>Clear filters</Button>
              <Dropdown overlayClassName='bulk-actions' overlay={menu1} disabled={ !this.state.selectedDomainIds.size }>
                <Button
                  disabled={ !this.state.selectedDomainIds.size }
                >
                  Bulk Action
                </Button>
              </Dropdown>
              <Dropdown overlay={menu2}>
                <Button>
                  Hide Rule Columns
                </Button>
              </Dropdown>
            </div>
          </div>

          <Table
            bordered
            dataSource={ tableData.get('dataSource').toJS() }
            columns={ tableData.get('columns').toJS() }
            scroll={{ x: 1300 }}
            onChange={this.handleTableChange}
            rowSelection={{
              onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedDomainIds: new List(selectedRowKeys) });
              },
              selectedRowKeys: this.state.selectedDomainIds.toJS(),
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    adminDomains: store.domainScoreState.getIn(['adminDomains', 'value']),
    rules: store.standardState.getIn(['rules', 'value']),
    pendingRequestsMap: new Map({
      adminDomains: store.domainScoreState.getIn(['adminDomains', 'pending']),
      rules: store.standardState.getIn(['rules', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getAdminDomains,
    getRules,
    changeScanPeriod,
    rescanProperties,
  },
)(DomainSetsPage);

