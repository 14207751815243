import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, message, Headline, Chip,Select } from '../../../styleguide';
import { LeftOutlined, WarningOutlined } from '@ant-design/icons';
import { Map, fromJS } from 'immutable';
import { Link, browserHistory } from 'react-router';

import CreateCampaign from './CreateCampaign';
import CampaignsLists from './campaignsLists/CampaignsLists';
import CampaignType from './CampaignType';
import ConfirmationModal from "./campaignsLists/ConfirmationModal";
import DismissErrorModal from '../../common/DismissErrorModal';
import { STATUS_TYPES as STATUS, BACKEND_STATUS_TYPES } from '../../../constants';
import PropertiesUnderGroupRedirection from '../../common/PropertiesUnderGroupRedirection';

import { getParameterByName, findMessageIdsFromScenario, hasFeature } from '../../utils.js';
import { getAllSites, getSiteGroup, updateSite, updatePropertyGroup } from '../../../actions/site_actions';
import { siteAndGroupLabel } from '../helper';
import { redirectToVl,maxMsgs,getCampaignNameLatestEnv } from './helper';

import {
  getOldCampaigns,
  activateCampaign,
  activateSiteGroupCampaign,
  copyAndActivateCampaignInEnv,
  copyAndActivateSiteGroupCampaignInEnv,
  updateCampaignsViewState,
  stopSiteGroupLiveCampaign,
  stopLiveCampaign,
  getAllPublicLiveCampaigns,
  getAllStageLiveCampaigns,
  activateMultiCampaign,
  stopAndCopyCampaign,
  stopAndCopySiteGroupCampaign
} from '../../../actions/campaign_actions';
import { getPartitionsets } from '../../../actions/partitionset_actions';

import { getDraftScenarios, getPublicScenarios, getStageScenarios, getPublicOldScenarios, getStageOldScenarios } from '../../../actions/scenario_actions';
import { getDraftMessages, getPublicMessages, getStageMessages, getPublicOldMessages, getStageOldMessages } from '../../../actions/message_actions';
import { getMessageListV2Ajax } from '../../../api/dialogue/messages';

import { checkStatus } from "../../../api/helper";
import { VendorListMetaData } from  "../../../records/vendor_list_records";

import Loading from '../../common/Loading.js.jsx';
import PropertyTag from '../../common/PropertyTag';

import { getTypes } from './helper';
import { isEqual } from "lodash";

import CampaignLaunchErrorModal from "./CampaignLaunchErrorModal";
import { getUserDetails } from '../../../actions/account_actions';
import { Alert, Flex, Space, Typography } from 'antd';

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const permissionsSvcBase = process.env.REACT_APP_PERMISSIONS_API_URL;

function CampaignsPage(props) {

  const { route, currentUser } = props;

  if (!props.currentUser) {
    return null;
  }
  const hasStageCampaignAccess = (currentUser.featureAccess && currentUser.featureAccess.includes('Campaign Staging')) || currentUser.allFeatureAccess;
  const hasPublicCampaignAccess = (currentUser.featureAccess && currentUser.featureAccess.includes('Campaign Public')) || currentUser.allFeatureAccess;
  let domain;
  let domainLabel;
  let propertiesUnderPg = [];
  const isGroup = props.siteGroup;
  const siteId = parseInt(props.location.query.site_id);
  let site = props.allSites.find(s => s.id === parseInt(siteId));
  const placeHolderSiteRecord = { domain: '', type: '', id: '' };
  let propertyType;
  if (!props.location.query.site_group_id) {
    site =  props.allSites.size && site ?  site : placeHolderSiteRecord;
    domain = site.domain || '';
    domainLabel = 'Property';
    propertyType = site.type;
  } else {
    site = props.allSites.size && site ? site : placeHolderSiteRecord;
    domain = props.siteGroup && props.siteGroup.name;
    domainLabel = 'Property Group';
    propertiesUnderPg = props.siteGroup && 
                        props.siteGroup.siteIds.size && 
                        props.siteGroup.siteIds.map(id => props.allSites.find(s => s.id === id) || placeHolderSiteRecord).toJS();
  }
  const [ isCreateCampaign, toggleCampignCreate ] = useState(false);
  const [ draftMessagesV2, setDraftMessagesV2 ] = useState([]);
  const [ publicMessagesV2, setPublicMessagesV2 ] = useState([]);
  const [ stageMessagesV2, setStageMessagesV2 ] = useState([]);
  const [ activateError, setActivateError ] = useState(null);
  const [ warningMultiCampaignMessage, setWarningMultiCampaignMessage ] = useState(false);
  const [ maxMessagePerPV, updateMaxMessagePerPV ] = useState(null);

  const types = getTypes(currentUser, site.type);
  const defaultCheckedCampaignTypes = types.map(t => t.value);
  const [ checkedCampaignTypes, setCheckedCampaignTypes ] = useState(defaultCheckedCampaignTypes);

  const [ vlErrorShow, setVlErrorShow ] = useState(false);
  const [ vlMetaData, setVlMetaData ] = useState(null);
  const [ vendorListMetaData, setVendorListMetaData ] = useState({});
  const [ vlMetaDataLoading, setVlMetaDataLoading ] = useState(false);
  const [ siteErrors, setSiteErrors ] = useState(null);

  const setCampaignTypes = (list) => {
    setCheckedCampaignTypes(list);
  }

  const prevSiteId = usePrevious(siteId);

  const getData = () => {
    const siteId = parseInt(props.location.query.site_id);
    props.getAllSites();
    const siteGroupId = props.location.query.site_group_id || props.location.query.sitegroup_id;
    if (siteGroupId) {
      props.getSiteGroup(siteGroupId);
    }
    setWarningMultiCampaignMessage(props.isMultiCampaignEnabled && !props.currentUser.accountFeatures.includes('multi_campaign_default'));
    getMessages('draft');
    getMessages('public');
    getMessages('stage');
    props.getPublicOldMessages(siteId);
    props.getStageOldMessages(siteId);
    props.getPublicOldScenarios(siteId);
    props.getStageOldScenarios(siteId);

    props.getAllPublicLiveCampaigns(siteId, [ 0, 1, 2, 3, 4, 5, 6, 7 ]);
    props.getAllStageLiveCampaigns(siteId, [ 0, 1, 2, 3, 4, 5, 6, 7 ]);
    props.getPartitionsets(siteId);
    props.getDraftScenarios(siteId);
    props.getPublicScenarios(siteId);
    props.getStageScenarios(siteId);
    props.getDraftMessages(siteId);
    props.getPublicMessages(siteId);
    props.getStageMessages(siteId);
  };

  useEffect(() => {
    getUserDetails();
  });

  const getVendorListMetaDataNew = async (type,siteId) =>{
    try {
      const metaData = await getVendorListData(type,siteId)
      if(metaData.hasActiveVendorList || metaData.hasActiveConfiguration){
        setVendorListMetaData((prevState) => ({
          ...prevState,
          [type]:metaData
        }));
      } else {
        setVendorListMetaData((prevState) => {
          if(prevState[type]) delete prevState[type];
          return prevState;
        })
      }
    } catch(err) {
      message.error(`Failed to load ${type.toUpperCase()} Vendor Lists`);
      setVendorListMetaData((prevState) => {
        if(prevState[type]) delete prevState[type];
        return prevState;
      })
    }
  }

  useEffect(() => {
    if (!isEqual(siteId, prevSiteId) && props.currentUser) {
      getData();
      const hasTcfv2 = props.currentUser.accountFeatures.includes('tcf_v2');
      const hasccpa = props.currentUser.accountFeatures.includes('ccpa');
      const hasusnat = props.currentUser.accountFeatures.includes('usnat');
      const hasPreferences = props.currentUser.accountFeatures.includes('preferences');

      if (hasccpa) getVendorListMetaDataNew("ccpa",siteId);
      if (hasTcfv2) getVendorListMetaDataNew("tcfv2",siteId);
      if (hasusnat) getVendorListMetaDataNew("usnat",siteId);
      if (hasPreferences) getVendorListMetaDataNew("preferences", siteId);
    } 
  }, [siteId, props.currentUser]);

  const getVendorListData = (type,siteId) => {
    let url = null;
    if(type === 'usnat'){
      url = '/vendor-list/meta-data?siteId=' + siteId;
    } else if(type === 'preferences') {
      url = '/configuration/meta-data?propertyId=' + siteId;
    } else {
      url = '/vendor-list/get-meta-data?siteId=' + siteId;
    }
      return fetch(`${permissionsSvcBase}/${type}` + url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(checkStatus)
        .then(resp => resp.json())
        .then(resp => { return { ...resp, siteId, type } });
  }

  const getMessages = (type) => {
    if(type === 'draft'){
    getMessageListV2Ajax(BACKEND_STATUS_TYPES['draft'], props.location.query.site_id)
      .then(messages => { setDraftMessagesV2(messages) });
    }else if(type === 'stage'){
      getMessageListV2Ajax(BACKEND_STATUS_TYPES['stage'], props.location.query.site_id)
      .then(messages => { setStageMessagesV2(messages) });
    }else{
      getMessageListV2Ajax(BACKEND_STATUS_TYPES['public'], props.location.query.site_id)
      .then(messages => { setPublicMessagesV2(messages) });
    }
  };

  const handleCreateCampaign = () => {
    toggleCampignCreate((prevState) => !prevState);
  }

  const onCancelCreateCampaign = () => {
    toggleCampignCreate((prevState) => !prevState);
    setActivateError(null);
  }

  const updateSiteErros = (sitesWithErrors) => {
    let sites = [];
    if(sitesWithErrors.size === 0) return;
    sitesWithErrors.forEach(site => {
      sites.push(props.allSites.toJS().find(s => s.id === parseInt(site.get('siteId')))?.domain)
    })
    setSiteErrors(sites)
  }

  const backToPropertyGroup = () => {
    let siteGroupId = '';
    if(props.siteGroupRedirect){
      siteGroupId = `&site_group_id=${props.siteGroupRedirect.id}`;
    }
    browserHistory.push(`/dialogue/campaigns_v2?site_id=${props.siteGroupRedirect.siteId}${siteGroupId}`);
  }

  const getUpdatedCampaignTypePriority = (env, campaignType, priority, isReplace = false) => {
    const index = priority - 1;
    const siteId = props.location.query.site_id;
    let campaignTypePriority = [];
    let checkV1Campaign = [];
    const site = props.allSites.size ? props.allSites.find(s => s.id === parseInt(siteId)) : {};
    campaignTypePriority = env === 'stage' ? site.stageCampaignTypePriority : site.publicCampaignTypePriority;
    checkV1Campaign = env === 'stage' ? props.stageCampaign : props.publicCampaign;
    if(checkV1Campaign.length > 0 && campaignTypePriority.length == 0) {
      campaignTypePriority = [ 0 ];
    }
    if(isReplace) {
      const oldIndex = campaignTypePriority.indexOf(campaignType);
      if (oldIndex > -1) { 
        campaignTypePriority.splice(oldIndex, 1);
      }
	}
    campaignTypePriority.splice(index, 0, campaignType);
    return campaignTypePriority;
  }

  const getCampaignTobeDeleted = (env, type) => {
    const campaigns = env === 'stage' ? props.stageCampaign : props.publicCampaign;
    return campaigns.find(c => Number(c.campaign_type_id) == type);
  }

  const getVendorListMetaData = async (siteIds) => {
    const metaDataPromises = siteIds.map(( siteId ) => {
      const url = '/vendor-list/get-meta-data?siteId=' + siteId;
      return fetch(`${permissionsSvcBase}/tcfv2` + url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(checkStatus)
        .then(resp => resp.json())
        .then(resp => { return { ...resp, siteId, type : 'tcfv2' } });
    });

    const combinedVendorListMetaData = await Promise.all(metaDataPromises).then((res) => res);
    const mergedVendorlistMetaData = [].concat.apply([], combinedVendorListMetaData);
    const vendorListMetaData = mergedVendorlistMetaData.map(resp => new VendorListMetaData(resp));

    setVlMetaDataLoading(false);

    return vendorListMetaData;
  }

  const handleAdblockCampaignLaunch = async (campaignType, env) => {
    const siteGroupId = props.location.query.site_group_id;
    const siteId = props.location.query.site_id;
    const site = props.allSites.find(s => s.id === parseInt(siteId));
    const priorityList = env === 'public' ? site.publicCampaignTypePriority : site.stageCampaignTypePriority;

    const siteIds = siteGroupId ? props.siteGroup.siteIds : [ siteId ];

    const VlMetaData = await getVendorListMetaData(siteIds);
    let propertiesWithErrors = VlMetaData.filter(m => !m.hasActiveVendorList || !m.hasSourcepoint);
    const isActiveGdprCampaign = priorityList.includes(1);
    const isActiveAdblockCampaign = priorityList.includes(3);
    const isReplaceCondition = (campaignType === 1 && isActiveGdprCampaign) || (campaignType === 3 && isActiveAdblockCampaign);
    const isAnotherCampaignExist = (campaignType === 3 && isActiveGdprCampaign) || (campaignType === 1 && isActiveAdblockCampaign);

    let showError = false; 
    if(siteGroupId) {
      const inactiveVlProperties = propertiesWithErrors.filter(m => !m.hasActiveVendorList);
      const vlsWithoutSPVendor = propertiesWithErrors.filter(m => !m.hasSourcepoint && m.vendorListId);

      showError = (campaignType === 1 && inactiveVlProperties.length > 0) || (isAnotherCampaignExist && vlsWithoutSPVendor.length > 0);
    } else {
      showError = (campaignType === 1 && !VlMetaData[0].hasActiveVendorList) || (isAnotherCampaignExist && !VlMetaData[0].hasSourcepoint);
    }
    if(propertiesWithErrors.length > 0 && showError) {
      setVlMetaData({ launchCampaign: campaignType, isAnotherCampaignExist, propertiesWithErrors, isReplaceCondition, hasActiveGdprCampaign: isActiveGdprCampaign, hasActiveAdblockCampaign: isActiveAdblockCampaign });
      setVlErrorShow(prevState => !prevState);
      return true;
    }
    return false;
  }

  const handleSaveAndActivate = async (env, campaignName, partitionsetId, campaignType, priority, isReplace = false, isRefreshed=false) => {
    const siteGroupId = props.location.query.site_group_id;
    const siteId = props.location.query.site_id;

    if(props.isMultiCampaignEnabled && (campaignType === 1 || campaignType === 3)) {
        setVlMetaDataLoading(true);
        const preventCampaignLaunch = await handleAdblockCampaignLaunch(campaignType, env);
        if(preventCampaignLaunch) {
          return;
        }
    }

    let deleteCampaign;
    if(isReplace) {
      deleteCampaign = getCampaignTobeDeleted(env, campaignType);
    }
    const campaignPriority = props.isMultiCampaignEnabled ? 
      getUpdatedCampaignTypePriority(env, campaignType, priority, isReplace) : [];

    const partitionset = props.partitionsets.find(p => p.id === partitionsetId);
    let error;
    setActivateError(null);

    // ensure partitionset has partitions
    const partitions = partitionset.partitions;
    if (!partitions.size) {
      error = "Unable to publish the campaign - partitionset has no partitions";
      setActivateError(error);
      return;
    }
    // ensure partitionset does not have unset scenarios
    const scenarioIds = partitions.map(partition => partition.scenario_id);
    const scenarios = scenarioIds.map(id => props.draftScenarios.find(s => s.id === id));

    if (scenarios.some(s => !s)) {
      error = "Unable to publish the campaign - partitionset has an unset scenario.";
      setActivateError(error);
      return;
    }
    // ensure partitionset does not have unset messages
    const messageIds = scenarios.map(s => {
      // have to deal with JSON format to support custom scenarios
      return findMessageIdsFromScenario(fromJS(JSON.parse(s.scenario_json)));
    }).flatten();

    /* Check to see if sceanrios with no steps */
    if(!messageIds.size) {
      error = "Unable to publish the campaign - scenario(s) has no steps";
      setActivateError(error);
      return;
    }

    /* Check to see if messages all exist */
    let missing = false;
    messageIds.forEach(mId => {
      if (props.draftMessages.find(m => m.id === mId) === undefined && draftMessagesV2.find(m => m.id === mId) === undefined) {
        missing = true;
      }
    })

    if (missing) {
      error = "Unable to publish the campaign - scenario has an unset message.";
      setActivateError(error);
      return;
    }

    if (siteGroupId) {
      const siteFieldTobeUpdated = env === 'stage' ? 'stageCampaignPriority' : 'publicCampaignPriority';

      props.activateSiteGroupCampaign({
        env,
        accountId: props.currentUser.accountId,
        name: campaignName,
        partitionsetId,
        siteGroupId,
        siteId: siteId,
        campaignType,
        isMultiCampaignEnabled: props.isMultiCampaignEnabled,
        [siteFieldTobeUpdated]: campaignPriority,
        campaign: deleteCampaign,
        isCreate: true,
      }).then(() => {
        (isRefreshed)? message.success(`${getCampaignNameLatestEnv(campaignName)} successfully refreshed`):
        message.success(`${getCampaignNameLatestEnv(campaignName)} successfully created`)
        getMessages(env);
      });
    } else {
      const siteFieldTobeUpdated = env === 'stage' ? 'stage_campaign_type_priority' : 'public_campaign_type_priority';

      props.activateCampaign({ 
        env, 
        siteId: siteId, 
        name: campaignName, 
        partitionsetId,
        campaignType,
        isMultiCampaignEnabled: props.isMultiCampaignEnabled,
        priorityList: { [siteFieldTobeUpdated]: campaignPriority },
        deleteCampaign
      }).then(() =>{
        (isRefreshed)? message.success(`${getCampaignNameLatestEnv(campaignName)} successfully refreshed`):message.success(`${getCampaignNameLatestEnv(campaignName)} successfully created`)
        getMessages(env);
      });
    }
    if(!isRefreshed){
    handleCreateCampaign(campaignName);
    }
  };

  const copyAndActivateInEnv = (currentEnv,copyCampaignDetails) => {
    const siteGroupId = props.location.query.site_group_id;
    const campaignName = copyCampaignDetails.description;
    const newEnv = currentEnv === 'production' ? 'Stage' : 'Public';
    if (siteGroupId) {
      props.copyAndActivateSiteGroupCampaignInEnv({ accountId: props.currentUser.accountId, siteGroupId, currentEnv, siteId: props.location.query.site_id }).then((resp) => {
        message.success(`'${getCampaignNameLatestEnv(campaignName)}' is added in ${newEnv}`)
        getMessages(newEnv.toLowerCase());
        updateSiteErros(resp.get('sitesWithErrors'))
      });
    } else {
      props.copyAndActivateCampaignInEnv(currentEnv, props.location.query.site_id).then(() => {
        message.success(`'${getCampaignNameLatestEnv(campaignName)}' is added in ${newEnv}`)
        getMessages(newEnv.toLowerCase());
      });
    }
  };

  const isMultiCampaignEnabled = props.isMultiCampaignEnabled;

  const propertyTag = propertyType && <PropertyTag type={ propertyType }/>;

  const createCampign = isCreateCampaign ? <CreateCampaign
                                            currentUser={currentUser}
                                            site={site}
                                            siteGroup={props.siteGroup}
                                            isMultiCampaignEnabled={isMultiCampaignEnabled} 
                                            onCancelCreateCampaign={onCancelCreateCampaign}
                                            partitions={props.partitionsets}
                                            publicCampaigns={props.publicCampaign}
                                            stageCampaigns={props.stageCampaign}
                                            scenarios={props.scenarios}
                                            messages={draftMessagesV2}
                                            handleSaveAndActivate={handleSaveAndActivate}
                                            activateError={activateError}
                                            hasStageCampaignAccess={hasStageCampaignAccess}
                                            hasPublicCampaignAccess={hasPublicCampaignAccess} /> : null;

  const appliedToProperties = isGroup &&  <PropertiesUnderGroupRedirection pageKind='campaigns_v2'/>;

  const onChangeMessageLimit = (messageLimit, env) => {
    const siteId = props.location.query.site_id;
    const siteGroupId = props.location.query.site_group_id;
    let fieldTobeUpdated;
    if(siteGroupId) {
      const accountId = props.currentUser.accountId;
      props.updatePropertyGroup( accountId, { 
        siteGroupId, 
        siteId, 
        "stageMessageLimit": messageLimit,
        "publicMessageLimit": messageLimit,

      });
    } else {
      fieldTobeUpdated = env === 'stage' ? 'stage_message_limit': 'public_message_limit';
      props.updateSite( siteId, { 
      "stage_message_limit": messageLimit,'public_message_limit':messageLimit} );
    }
  }

  const updatePriorityList = (priorityList, env) => {
    const siteGroupId = props.location.query.site_group_id;
    const siteId = props.location.query.site_id;
    let fieldTobeUpdated;
    if(siteGroupId) {
      const accountId = props.currentUser.accountId;
      fieldTobeUpdated = env === 'stage' ? 'stageCampaignPriority' : 'publicCampaignPriority';
      props.updatePropertyGroup( accountId, { 
        siteGroupId, 
        siteId, 
        [fieldTobeUpdated]: priorityList
      });
    } else {
      fieldTobeUpdated = env === 'stage' ? 'stage_campaign_type_priority' : 'public_campaign_type_priority';
      props.updateSite( siteId, { [fieldTobeUpdated]: priorityList } );
    }
  }

  const copyAndEndCampaign = (copyAndEndCampaignDetails, stagePriorityList, publicPriorityList) => {
    const siteId = Number(props.location.query.site_id);
    const siteGroupId = props.location.query.site_group_id;

    const campaignName = copyAndEndCampaignDetails.description;
    const campaignType = Number(copyAndEndCampaignDetails.campaign_type_id);
    const newEnv = copyAndEndCampaignDetails.environment === 1 ? 'Stage' : 'Public';
    const priorities = copyAndEndCampaignDetails.environment === 1 ? stagePriorityList : publicPriorityList;
    const priority = priorities.indexOf(campaignType) + 1;

    if(siteGroupId) {
      props.stopAndCopySiteGroupCampaign({
        siteId,
        siteGroupId,
        accountId: props.currentUser.accountId,
        stageCampaignPriority: stagePriorityList,
        publicCampaignPriority: publicPriorityList,
        deleteCampaign: copyAndEndCampaignDetails,
        campaign: copyAndEndCampaignDetails,
        campaignType,
        env: newEnv.toLowerCase(),
        siteGroup: props.siteGroup,
      }).then((resp) => {
        message.success(`'${getCampaignNameLatestEnv(campaignName)}' is added in ${newEnv} campaigns as priority "${priority}"`)
        updateSiteErros(resp.get('sitesWithErrors'))
        getMessages(newEnv.toLowerCase());
      });
    } else {
      props.stopAndCopyCampaign({
        siteId: siteId,
        priorityLists: { 
          stage_campaign_type_priority: stagePriorityList,
          public_campaign_type_priority: publicPriorityList 
        },
        deleteCampaign: copyAndEndCampaignDetails,
      }).then(() => {
        message.success(`'${getCampaignNameLatestEnv(campaignName)}' is added in ${newEnv} campaigns as priority "${priority}"`)
        getMessages(newEnv.toLowerCase());
      });
    }
  }

  const toggleVlMetaDataVisibility = () => {
    setVlErrorShow(prevState => !prevState);
    setVlMetaData(null);
  }

  const filterCampaigns = (c) => {
    const campaignType = Number(c.campaign_type_id);
    return checkedCampaignTypes.includes(campaignType) || campaignType === 0;
  }

  const showSelectedPublicCampaigns = props.publicCampaign.filter(filterCampaigns);
  const showSelectedStageCampaigns = props.stageCampaign.filter(filterCampaigns);

  const campaignLists = <CampaignsLists
                          currentUser={currentUser}
                          accountId={props.currentUser.accountId}
                          site={site}
                          isMultiCampaignEnabled={isMultiCampaignEnabled} 
                          copyAndActivateInEnv={copyAndActivateInEnv}
                          stopSiteGroupLiveCampaign={props.stopSiteGroupLiveCampaign}
                          stopLiveCampaign={props.stopLiveCampaign}
                          publicScenarios={props.publicScenarios}
                          stageScenarios={props.stageScenarios}
                          draftScenarios={props.draftScenarios}
                          oldStageCampaigns={props.oldStageCampaigns}
                          oldPublicCampaigns={props.oldPublicCampaigns}
                          onChangeMessageLimit={onChangeMessageLimit}
                          stageCampaign={showSelectedStageCampaigns}
                          publicCampaign={showSelectedPublicCampaigns}
                          updatePriorityList={updatePriorityList}
                          copyAndEndCampaign={copyAndEndCampaign}
                          vendorListMetaData={vendorListMetaData}
                          statsLoading={props.pendingRequestsMap.get('statsData')}
                          stageMessages={stageMessagesV2}
                          publicMessages={publicMessagesV2}
                          hasStageCampaignAccess={hasStageCampaignAccess}
                          hasPublicCampaignAccess={hasPublicCampaignAccess}
                          handleSaveAndActivate={handleSaveAndActivate}
                          partitions={props.partitionsets}
                        />;

  const campaignTypes = isMultiCampaignEnabled ? <CampaignType site={site} currentUser={currentUser} setCampaignTypes={setCampaignTypes}/> : null;

  let propertyGroupRedirect;
  if(props.siteGroupRedirect) {
    propertyGroupRedirect = <div className="pg-diversion" onClick={backToPropertyGroup}><LeftOutlined /> Back to Property Group ({props.siteGroupRedirect.name})</div>;
  }

  let loading;
  if (props.pendingRequestsMap.some(request => request) || vlMetaDataLoading) {
    loading = <Loading />;
  }

  let multiCampaignMessage;
  if (warningMultiCampaignMessage) {
    multiCampaignMessage = (
      <div className='multi-campaign-messages large'>
        <div className='container'>
          <div className='warning'>
            <div><WarningOutlined /></div>
            <div className='message flex-row'>
              <div>
                To be able to launch more than one campaign, you will need to <span>replace the old snippet</span> on your <span>property/ies</span> with the <span>new snippet</span> which can be found in the
              </div>
              <div
                onClick={ () => browserHistory.push('/dialogue/sites') }
                className='snippet-link'
              >
                Properties Page
              </div>
            </div>
          </div>
        <Button
          onClick={ () => setWarningMultiCampaignMessage(false) }
        >
          ok
        </Button>
        </div>
      </div>
    );
  }

  /** Maximum message per page view  */

  const onChangeMsgLimit = () => {
    onChangeMessageLimit(maxMessagePerPV);
    unsetMaxMessagePerPv();
  }

  const unsetMaxMessagePerPv = () => updateMaxMessagePerPV(null);

  const maxMessagePageViewModal = maxMessagePerPV && <ConfirmationModal 
                              isModalVisible={!!maxMessagePerPV}
                              maxMessagePerPV={maxMessagePerPV}
                              handleOk={onChangeMsgLimit} 
                              handleCancel={unsetMaxMessagePerPv}/>

  const setMaxMessagesPerPV = (maxMessagePerPV = null) => {
    updateMaxMessagePerPV(maxMessagePerPV);
  }

  const maxMsgPerPageView =  isMultiCampaignEnabled && <span className="campaigns-msgs-pageview">
      Maximum Number of Messages per Pageview: 
      <Select className="max-msgs-per-view" value={site["publicMessageLimit"]} onChange={(messageLimit) => setMaxMessagesPerPV(messageLimit) }>
        { maxMsgs.map((m, i) => <Option key={i} value={m}>{ m }</Option>) }
      </Select>
    </span>

  const vlError = vlErrorShow && <CampaignLaunchErrorModal propertiesUnderPg={propertiesUnderPg} visible={vlErrorShow} metaData={vlMetaData} toggleVisibility={toggleVlMetaDataVisibility}/>;
  const activeVLs =  Object.values(vendorListMetaData).filter( vl => vl?.hasActiveVendorList).map(vl => <Link className="vl-name" to={redirectToVl(vl)}>{vl.name}</Link>)
  const vlattached = activeVLs.length ? activeVLs : <Alert style={{display:'inline-flex'}} type='error' showIcon message={"There are no active vendor lists associated with this property"}/>;
  debugger;
  const activePrefConfigs = Object.values(vendorListMetaData).filter( vl => vl?.hasActiveConfiguration).map(vl => <Link className="vl-name" to={redirectToVl(vl)}>{vl.name}</Link>);
  const prefConfigsAttached = activePrefConfigs.length ? activePrefConfigs : <Alert style={{display:'inline-flex'}} type='error' showIcon message={"There are no active configurations associated with this property."}/>

  const siteErrorModal = siteErrors ?
      <DismissErrorModal
        title={"Cannot Clone Campaign"}
        error= {<h4>The campaign of the following properties <b>{siteErrors.join(", ")}</b> were not cloned because the campaign was not found running on the properties.</h4>}
        isModalVisible={Boolean(siteErrors)}
        cancelText="Okay"
        handleCancel={() => setSiteErrors(null)}
      />
  : null;

  return (<div className="campaigns-container multi-campaigns">
            { multiCampaignMessage }
            { loading }
            <Headline>Campaigns</Headline>
            { propertyGroupRedirect }
            { createCampign }
            <Flex className="sceanrio-domain-wrapper" align='center' justify='space-between'>
              <Space size={4}>
                <span className="page-label">{siteAndGroupLabel(props.location.query.site_group_id)}</span>
                <span className="page-title">{domain}</span>
                { propertyTag }
              </Space>
              <Space direction='vertical' size={8}>
                {!isGroup ? <Typography.Text strong>Vendor List(s): {vlattached}</Typography.Text> : null}
                {!isGroup && hasFeature(currentUser, "preferences") ? <Typography.Text strong>Preferences:  {prefConfigsAttached}</Typography.Text> : null}
              </Space>
              { !isCreateCampaign && (
                <div className="scenario-add-options">
                  <Button type="primary" onClick={() => toggleCampignCreate((prevState) => !prevState)} disabled={!(hasPublicCampaignAccess || hasStageCampaignAccess)}>
                    New Campaign
                  </Button>
                </div>
              )}
            </Flex>
      
            { appliedToProperties }
            <div className='subheader-container'>
            { campaignTypes }
            </div>
            { campaignLists }
            {maxMsgPerPageView}
            { vlError }
            {maxMessagePageViewModal}
            {siteErrorModal}
         </div>);
}

const mapStateToProps = function (store) {
  const allSites = store.siteState.getIn(['sites', 'value']);
  const siteId = parseInt(getParameterByName('site_id', window.location));
  const site = allSites.find(site => site.id === siteId);
  let isMultiCampaignEnabled = false;
  if (site) {
    isMultiCampaignEnabled = site.multiCampaignEnabled;
  }

  let publicCampaign = [];
  let stageCampaign = [];

  const campaigns = store.campaignState.getIn(['campaigns', 'value']).filter(c => c.site_id === siteId);
  const oldCampaigns = campaigns.filter(c => c.deleted_at);
  const oldStageCampaigns = campaigns.filter(c => c.deleted_at && c.status === STATUS.STAGE);
  const oldPublicCampaigns = campaigns.filter(c => c.deleted_at && c.status === STATUS.PUBLIC);

  const livePublicCampaigns = campaigns.filter(c => c.status === STATUS.PUBLIC && c.live).sortBy(c => new Date(c.created_at).getTime());
  const liveStageCampaigns = campaigns.filter(c => c.status === STATUS.STAGE && c.live).sortBy(c => new Date(c.created_at).getTime());

  if(campaigns && campaigns.size) {
    if(isMultiCampaignEnabled) {
      publicCampaign = livePublicCampaigns.toArray();
      stageCampaign = liveStageCampaigns.toArray();
    } else {
      const pc = livePublicCampaigns.last();
      const sc = liveStageCampaigns.last();
      if(pc) {
        publicCampaign = [ pc ];
      }
      if(sc) {
        stageCampaign = [ sc ];
      }
    }
  }
  const liveCampaignIds = publicCampaign.concat(stageCampaign).map(c => c.id);
  const messages = store.messageState.getIn(['messages', 'value']).filter(m => m.site_id === siteId);
  const partitionsets = store.partitionsetState.getIn(['partitionsets', 'value']).filter(c => c.site_id == siteId);
  const scenarios = store.scenarioState.getIn(['scenarios', 'value']).filter(s => s.site_id === siteId);

  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    siteId,
    isMultiCampaignEnabled,
    allSites,
    publicCampaign,
    stageCampaign,
    oldStageCampaigns,
    oldPublicCampaigns,
    liveCampaignIds,
    oldCampaigns: oldCampaigns ? oldCampaigns: [],
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    siteGroup: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('site_group_id', window.location)),
    siteGroupRedirect: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('sitegroup_id', window.location)),
    draftScenarios: scenarios.filter(s => s.status === STATUS.DRAFT),
    publicScenarios: scenarios.filter(s => s.status === STATUS.PUBLIC),
    stageScenarios: scenarios.filter(s => s.status === STATUS.STAGE),
    draftMessages: messages.filter(m => m.status === STATUS.DRAFT),
    publicMessages: messages.filter(m => m.status === STATUS.PUBLIC),
    stageMessages: messages.filter(m => m.status === STATUS.STAGE),
    oldMessages: messages.filter(m => m.status !== STATUS.DRAFT && !m.live),
    partitionsets,
    scenarios,
    graphData: store.campaignState.get('graphData'),
    viewState: store.campaignState.get('viewState'),
    isSPUser: store.accountState.get('isSPUser'),
    pendingRequestsMap: new Map({
      allSites: store.siteState.getIn(['sites', 'pending']),
      campaigns: store.campaignState.getIn(['campaigns', 'pending']),
      messages: store.messageState.getIn(['messages', 'pending']),
      scenarios: store.scenarioState.getIn(['scenarios', 'pending']),
      partitionsets: store.partitionsetState.getIn(['partitionsets', 'pending']),
      graphData: store.campaignState.get('graphData').map(data => data.pending).some(request => request),
    })
  };
}


export default connect(
  mapStateToProps, {
  getUserDetails,
  getAllSites,
  getOldCampaigns,
  getDraftMessages,
  getPublicMessages,
  getStageMessages,
  getPublicOldMessages,
  getStageOldMessages,
  getDraftScenarios,
  getPublicScenarios,
  getStageScenarios,
  getPublicOldScenarios,
  getStageOldScenarios,
  getPartitionsets,
  activateCampaign,
  activateSiteGroupCampaign,
  copyAndActivateCampaignInEnv,
  copyAndActivateSiteGroupCampaignInEnv,
  getSiteGroup,
  stopLiveCampaign,
  stopSiteGroupLiveCampaign,
  updateSite,
  updatePropertyGroup,
  getAllPublicLiveCampaigns,
  getAllStageLiveCampaigns,
  activateMultiCampaign,
  stopAndCopyCampaign,
  stopAndCopySiteGroupCampaign
}
)(CampaignsPage);
