import 'whatwg-fetch';
import { List, OrderedSet, Map } from 'immutable';
import {
  checkStatus,
  isFetched,
  addToFetched,
  generateAuthTokenCcpa as generateAuthToken
} from '../helper.js';
import { FETCHED } from '../../constants';
import {
  VendorListCCPA as VendorList,
  Vendor,
  Purpose,
  VendorListError
} from '../../records/vendor_list_records';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const getConsentBaseUrl = () => `${permissionsSvcBaseUrl}/usnat`;

// const getConsentBaseUrl = () => {
//   return window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org';
// }
// const getConsentBaseUrl = () => {
//   // return 'https://ccpa-service.sp-prod.net/ccpa'
//   if (window.CONFIG && window.CONFIG.ccpaOrigin) {
//     return `${window.CONFIG.ccpaOrigin}/ccpa`;
//   } else {
//     return 'https://ccpa.sp-stage.net/ccpa';
//   }
// };

export function getVendorListsAjax() {

  // let url = '/vendor-list';
  let url = `/vendor-list/account-list`;

  // caching temp removed to accomodate V2
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
  return fetch(getConsentBaseUrl() + url, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return resp.map(vlist => {
        vlist.id = vlist._id;
        return vlist;
      });
    });
}

export function getScannedVendorsAjax(siteIds, vendorListId) {
  let checkForExistingVendors = '';
  if (vendorListId) {
    checkForExistingVendors = '&vendorListId=' + vendorListId;
  }
  return fetch(
    getConsentBaseUrl() +
      '/vendor-scan?siteIds=' +
      JSON.stringify(siteIds.toJS()) +
      checkForExistingVendors, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
  )
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp)
        .map(obj => {
          const vendor = obj.vendor;
          vendor.cookies = obj.cookies;
          if (obj.hasOwnProperty('isNew')) {
            vendor.isNew = obj.isNew;
          }
          return vendor;
        })
        .map(v => new Vendor(v));
    });
}

export function getSitesEnabledForScanAjax(accountId) {
  return fetch(getConsentBaseUrl() + '/vendor-scan/legacy/sites', {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp).map(obj => obj.siteId);
    });
}

export function getIABPurposesAjax() {
  const url = '/purposes/iab';
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getConsentBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return List(resp.map(p => new Purpose(p)));
      });
  }
}

export function getVendorListAjax(id) {
  const url = `/vendor-list?vendorListId=${id}`;
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      // addToFetched(url);
      return new VendorList(resp);
    });
  // }
}

export function getGlobalVendorsAjax() {
  const url = '/vendors';
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      // addToFetched(url);
      return OrderedSet(resp.map(vendor => new Vendor(vendor)));
    });
  // }
}

export function getGlobalVendorsHashAjax() {
  const url = '/vendors';
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getConsentBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        const vendorHash = {};
        resp.forEach(vendor => {
          vendorHash[vendor._id] = new Vendor(vendor);
        });
        return new Map(vendorHash);
      });
  }
}

export function createVendorListAjax(accountId, list) {
  return fetch(getConsentBaseUrl() + '/vendor-list', {
    method: 'POST',
    body: JSON.stringify(generateVendorlistData(accountId, list)),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      const updatedResp = Object.assign({}, resp);
      updatedResp.clientSideMessageId = list.clientSideMessageId;
      return new VendorList(updatedResp);
    });
}

export function cloneVendorListAjax(accountId, vendorListId, cloneName) {
  return fetch(
    getConsentBaseUrl() + `/vendor-list/clone?vendorListId=${vendorListId}`,
    {
      method: 'POST',
      body: JSON.stringify({ accountId, name : cloneName }),
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => new VendorList(resp));
}

export function updateVendorListAjax(accountId, list) {
  return fetch(getConsentBaseUrl() + '/vendor-list', {
    method: 'PUT',
    body: JSON.stringify(generateVendorlistData(accountId, list)),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkVendorListStatus)
    .then(resp => {
      return resp.json();
    })
    .then(resp => new VendorList(resp));
}

async function checkVendorListStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    const errors = await response.json();
    throw new List(errors).map(err => new VendorListError(err));
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function deleteVendorListAjax(accountId, { id }) {
  return fetch(getConsentBaseUrl() + '/vendor-list/' + accountId + '/' + id, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ accountId })
  })
    .then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(() => id);
}

function generateVendorlistData(accountId, list) {
  const categories = list.categories.map(c => {
    let id = c.id;
    if (c.iabPurposeRefs.includes(c.id)) {
      id = null;
    }
    let category = c
      .toMap()
      .delete('iabId')
      .set('vendors', c.vendorIds)
      .delete('vendorIds')
      .delete('id');
      
    if (id) category = category.set('_id', id);
    if (!category.get('translations')) category = category.delete('translations');

    return category;
  });

  const vendorsWrappers = list.vendorsWrappers
    .map(vw => {
      const id = vw.id;
      let updatedwv = vw.toMap().delete('id');
      let cookies = updatedwv.get('cookies');
      cookies = cookies.map(c => {
        const updatedCokie = c
          .toMap()
          .delete('id')
          .delete('foundOnUrls');
        return updatedCokie;
      });
      updatedwv = updatedwv.set('cookies', cookies);
      if (id) {
        updatedwv = updatedwv.set('_id', id);
      }
      return updatedwv;
    })
    .toJS();

  const data = {
    accountId: accountId.toString(),
    name: list.name,
    description: list.description,
    vendors: list.vendors
      .map(v => v.id)
      .toSet()
      .toJS(),
    categories,
    consentScope: list.consentScope,
    shareRootDomain: list.shareRootDomain,
    siteIds: list.siteIds.toSet().toJS(),
    isActive: list.isActive,
    vendorsWrappers,
    autoUpdateVendorScan: list.autoUpdateVendorScan,
    autoUpdateIabVendors2: list.allIabAndAutoUpdate,
    cookieMaxAge: list.cookieMaxAge,
    writeFirstPartyCookiesFromServer: list.writeFirstPartyCookiesFromServer,
    partitionSet: list.partitionSet,
    appliesGeos: list.appliesGeos.map(geo => geo === 'CAN' ? 'CA' : geo),
    exclusionGeos: list.exclusionGeos.map(geo => geo === 'CAN' ? 'CA' : geo),
    respectGPC: list.respectGPC
  };

  if (list.id) {
    data._id = list.id;
  }

  return data;
}
