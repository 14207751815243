import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, fromJS } from 'immutable';
import _ from 'lodash';
import SVG from 'react-inlinesvg';
import {  Icon, Alert} from 'antd';
import Loading from '../../common/Loading.js.jsx';
import { STATUS_TYPES as STATUS, BACKEND_STATUS_TYPES } from '../../../constants';
import { Table, Toggle, Select, message, Tooltip, Input } from '../../../styleguide';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import CantDeleteModal from './CantDeleteModal';
import { List } from 'immutable';
import DeletePropertyModal from './DeletePropertyModal';
import CopyCodeSnippet from './CopyCodeSnippet';
import { COUNTRY_LANGUAGES } from '../../../constants';
import { browserHistory } from 'react-router';
import { Site, SiteGroup } from '../../../records/site_records';
import { LeftOutlined, RightOutlined, CloseOutlined, CloseCircleFilled } from '@ant-design/icons';
import  alertIcon  from '../../../assets/images/privacy-lens/alert.svg';
import CheckActiveCampaignsModal from './CheckActiveCampaignsModal';
import {
  getAllPublicLiveCampaigns,
  getAllStageLiveCampaigns,
} from '../../../actions/campaign_actions';
const { Option } = Select;
import { hasFeature } from './helper';

class Properties extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    siteGroups: ImmutablePropTypes.listOf(PropTypes.instanceOf(SiteGroup)).isRequired,
    deleteSite: PropTypes.func.isRequired,
    updateSite: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    editProperty: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isMultiCampaignAvailable: PropTypes.bool.isRequired,
    isMultiCampaignAvailableDefault: PropTypes.bool.isRequired,
    propertyTypesNum: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  };
  state = {
    sites: this.props.sites,
    initialLoad: true,
    search: '',
    filterType: 'all',
    sgsContainingSite: List(),
    siteToDelete: null,
    showCodeSnippetModal: false,
    snippetProperty: {},
    isSnippetForMultiCampaign: false,
    errVisible: false,
    siteHaveActiveCampiagns: false,
    property: null,
  };
  static getDerivedStateFromProps(props, state) {
    if (props.sites != state.sites || state.initialLoad) {
      return {
        sites: state.search || state.filterType != 'all' ? state.sites : props.sites,
        initialLoad: false,
      };
    }
    if (props.error && !props.loading) {
      return { errVisible: true };
    } else if (props.loading) {
      return { errVisible: false };
    }
    return null;
  }

  handleCampaignData = (siteId) => {
     this.props.getAllPublicLiveCampaigns(siteId, [ 0, 1, 2, 3, 4, 5, 6, 7]);
     this.props.getAllStageLiveCampaigns(siteId, [ 0, 1, 2, 3, 4, 5, 6, 7]);
  }
  deleteSite =  (e, property) => {
    e.preventDefault();
    e.stopPropagation();
    const siteGroups = this.props.siteGroups.toJS();
    let sgsContainingSite = List();
    for (let sg of siteGroups) {
      for (let childSiteId of sg.siteIds) {
        if (childSiteId === property.id) {
          sgsContainingSite = sgsContainingSite.push(sg.name);
        }
      }
    }
    this.handleCampaignData(property.id);
    this.setState({property})

    if(sgsContainingSite.size){
      this.setState({ sgsContainingSite }) 
    }else{
      this.setSiteToDelete(property);
      this.setState({ siteHaveActiveCampiagns: true, })
    }
  };
  onSearch = (e) => {
    const searchValue = e.target.value ? e.target.value.toLowerCase() : '';
    let sites = this.props.sites
      .filter(
        (s) =>
          s
            .get('domain')
            .toLowerCase()
            .indexOf(searchValue) !== -1 ||
          s
            .get('id')
            .toString()
            .indexOf(searchValue) !== -1
      )
      .filter((s) => (this.state.filterType === 'all' ? s : s.get('type') === this.state.filterType));
    this.setState({
      sites,
      search: e.target.value,
    });
  };
  filterByType = (value) => {
    const sites = this.props.sites
      .filter((s) => (value === 'all' ? s : s.get('type') === value))
      .filter(
        (s) =>
          s
            .get('domain')
            .toLowerCase()
            .indexOf(this.state.search.toLowerCase()) !== -1 ||
          s
            .get('id')
            .toString()
            .indexOf(this.state.search.toLowerCase()) !== -1
      );
    this.setState({
      sites,
      filterType: value,
    });
  };
  setSiteToDelete = (siteToDelete) => this.setState({ siteToDelete });
  toggleSnippetModal = (e, property = '', multi) => {
    e.preventDefault();
    e.stopPropagation();
    const isMultiCampaign = multi || property && property.multiCampaignEnabled;
    this.setState((prevState) => ({
      showCodeSnippetModal: !prevState.showCodeSnippetModal,
      snippetProperty: !prevState.showCodeSnippetModal ? property : {},
      isSnippetForMultiCampaign: isMultiCampaign,
    }));
  }
  getPropertyGroup = (propertyId) => {
    const findPropertyGroup = this.props.siteGroups.find((pg) => pg.siteIds.includes(propertyId));
    return findPropertyGroup ? findPropertyGroup.name : '-';
  };
  deleteProperty = () => {
    this.props.deleteSite(this.state.siteToDelete.id, this.state.siteToDelete.domain).then((resp) => {
      if (resp) message.error(`Property ${this.state.siteToDelete.domain} with ID ${this.state.siteToDelete.id} is deleted`);
      this.setSiteToDelete(null);
      this.props.error != null && this.handleCloseAlert()  
    });
  };
  getLanguage = (langCode) => COUNTRY_LANGUAGES.find((lng) => lng.code === langCode).language;
  handleManageSite = (e, siteId) => {
    e.preventDefault(); 
    e.stopPropagation();
    this.props.selectSite(siteId, null);
  }
  itemRender = (current, type, originalElement) => {
    return originalElement;
  };
  handleCloseAlert = () => {
    this.setState((prevState) => ({ errVisible: !prevState.errVisible }));
  };
  switchMultiCampaign = (e, property) => {
    e.preventDefault();
    e.stopPropagation();
    if (property.multiCampaignEnabled) {
      const propertyGroup = this.getPropertyGroup(property.id);
      if (propertyGroup !== '-') {
        message.error(
          <div>
            Unable to deselect Multi-Campaign for <b>{property.domain}</b> because it is part of property group <b>{propertyGroup}</b>.
          </div>
        );
        return;
      }
    }
    this.props.updateSite(property.id, {multi_campaign_enabled: !property.multiCampaignEnabled}).then(property => {
      const sitesWithUpdated = this.state.sites.map(site => {
        if (site.id === property.id) {
          return property;
        } else {
          return site;
        }
      });
      this.setState({ sites: sitesWithUpdated });
    });
    if (!property.multiCampaignEnabled) {
      this.toggleSnippetModal(e, property, true);
      message.success(
        <div>
          Multi-Campaign is now <b>enabled</b> for <b>{property.domain}</b>.
          You can now set multiple campaigns and define priorities in the Campaigns tab.
        </div>
      );
    }
  };
  handleCancel = () => {
    this.setState({
      siteToDelete: null,
      siteHaveActiveCampiagns: false
    })
  }
  goToCampaignPage = (e, siteId) => {
    this.handleManageSite(e, siteId)
  }
  getCampaignData = () => {
    const siteId = this.state.property?.id;
      const isMultiCampaignEnabled = this.state.property?.multiCampaignEnabled
      let publicCampaign = [];
      let stageCampaign = [];
      if(this.props.campaigns.size>0){
      const campaigns = this.props.campaigns.filter(c => c.site_id === siteId);
      const livePublicCampaigns = campaigns.filter(c => c.status === STATUS.PUBLIC && c.live).sortBy(c => new Date(c.created_at).getTime());
      const liveStageCampaigns = campaigns.filter(c => c.status === STATUS.STAGE && c.live).sortBy(c => new Date(c.created_at).getTime());
      if(campaigns && campaigns.size) {
        if(isMultiCampaignEnabled) {
          publicCampaign = livePublicCampaigns.toArray();
          stageCampaign = liveStageCampaigns.toArray();
        } else {
          const pc = livePublicCampaigns.last();
          const sc = liveStageCampaigns.last();
          if(pc) {
            publicCampaign = [ pc ];
          }
          if(sc) {
            stageCampaign = [ sc ];
          }
        }
      }
    }
    return [...stageCampaign, ...publicCampaign]
  }
  render() {
    let warningMessage=""
    let campaignData = this.getCampaignData();
    const hasActiveCampaigns = !this.props.pendingRequestsMap.get('campaigns') && (campaignData.length > 0 && this.state.siteHaveActiveCampiagns)
    if((this.props.pendingRequestsMap.get('campaigns') && campaignData.length === 0) || this.state.sgsContainingSite.size !== 0) {
      warningMessage = null;
    } else {
      if(hasActiveCampaigns) {
        warningMessage = <CheckActiveCampaignsModal title="Property - Delete" okText="View Campaigns" goToCampaignPage={this.goToCampaignPage} handleCancel={this.handleCancel} siteId={this.state.property?.id} campaignData={campaignData}/> 
      }else if(this.state.siteToDelete != null) {
        warningMessage = <DeletePropertyModal handleDelete={this.deleteProperty} siteToDelete={this.state.siteToDelete} handleCancel={() => this.setSiteToDelete(null)} />
      }
    }
    var containerClass = 'properties-content-container';
    const multiCampaingTootlipText = (
      <div>
        Switch the toggle <span>on</span> if you would like to have the ability to <span>run multiple campaign</span> types on your <span>property</span>.
        Please note that you will need to also <span>update the script</span> on the property to take advantage of the multi-campaign feature.
      </div>
    );
    
    const columns = [
      {
        title: 'Property ID',
        dataIndex: 'id',
        key: 'id',
        align: 'left',
        fixed: 'left',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Property',
        dataIndex: 'domain',
        key: 'domain',
        align: 'Left',
        sorter: (a, b) => a.domain.localeCompare(b.domain),
        render: (text) =>
        <div className='max-width-property-column'>
          {text}
        </div>
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        align: 'left',
        sorter: (a, b) => a.type.localeCompare(b.type),
        render: (text, record) => <React.Fragment>{text == 'web' ? 'Website' : text.toUpperCase()}</React.Fragment>,
      }
    ];

    if (!hasFeature(this.props.accountFeatures, "hide_property_groups")) {
      columns.push(
        {
          title: 'Property Group',
          dataIndex: 'propertygroup',
          key: 'propertygroup',
          align: 'left',
          sorter: (a, b) => this.getPropertyGroup(a.id).localeCompare(this.getPropertyGroup(b.id)),
          render: (text, record) =>
          <div className='max-width-property-column'>
          {this.getPropertyGroup(record.id)}
          </div>
        },
      );
    }

    columns.push(
      {
        title: 'Creation Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'left',
        sorter: (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
        render: (text, record) => moment(text).format('YYYY-MM-DD'),
        defaultSortOrder: 'descend',
      },
    );
    
    if (this.props.isMultiCampaignAvailable && !this.props.isMultiCampaignAvailableDefault) {
      columns.push(
        {
          title: (
            <div>
              Multi-Campaign
              <Tooltip placement="bottom" title={multiCampaingTootlipText} className='multi-campaign-tooltip' color='white'>
                <InfoCircleOutlined />
              </Tooltip>
            </div>),
          dataIndex: 'multiCampaign',
          key: 'multiCampaign',
          align: 'left',
          sorter: (a, b) => {
            const first = a.multiCampaignEnabled ? 1 : 0;
            const second = b.multiCampaignEnabled ? 1 : 0;
            return first - second;
          },
          render: (text, record) => (
            <Toggle
              checked={ record.multiCampaignEnabled }
              onChange={ (c, e) => this.switchMultiCampaign(e, record) }
            />
          ),
          defaultSortOrder: 'descend',
        },
      );
    }

    columns.push(
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <div className='action-flex-container'>
            <span className="property-action-icon" onClick={(e) => this.toggleSnippetModal(e, record)}>
            <LeftOutlined /><RightOutlined />
            </span>
            <button className="avo-dialogue-icons avo-sp-edit" onClick={(e) => {
            e.stopPropagation();
            this.props.editProperty(record)}}></button>
            <button className="avo-dialogue-icons avo-sp-delete" onClick={(e) => this.deleteSite(e, record)}></button>
          </div>
        ),
      },
    );
    return (
      <div className={containerClass}>
        {warningMessage}
        <div className="properties-table-header">
          <Input purple placeholder="Search Properties" onChange={this.onSearch} style={{ width: 200 }} value={this.state.search} />
          <div>
            Filter by Type:{' '}
            <div className="properties-filter">
            <Select showSearch style={{ width: 120 }} onChange={this.filterByType} value={this.state.filterType} placeholder="Type" >
              <Option value="all">All</Option>
              {this.props.propertyTypesNum.map((p) => (
                <Option key={p.get('code')} value={p.get('code')}>
                  {p.get('label')}
                </Option>
              ))}
            </Select>
            </div>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={this.state.sites.toJS()}
          pagination={{
            position: ['bottomCenter'],
            showTotal: (total) => `Total Properties: ${total}`,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
            locale: { items_per_page: ' Records per page' },
          }}
          rowKey={(r) => r.id}
          loading={this.props.loading}
          onRow={(record) => {
            return {
              onClick: (e)=> this.handleManageSite(e, record.id)
            };
          }}
          scroll={{ x: 'min-content' }}
          tableLayout = 'auto'
        />
        {this.state.sgsContainingSite.size ? <CantDeleteModal siteGroupNames={this.state.sgsContainingSite} closeModal={() => this.setState({ sgsContainingSite: List(), siteHaveActiveCampiagns: false })} /> : null}
        {this.state.showCodeSnippetModal && <CopyCodeSnippet
          showCodeSnippetModal={this.state.showCodeSnippetModal} 
          toggleSnippetModal={this.toggleSnippetModal} 
          accountId={this.props.accountId} 
          snippetProperty={this.state.snippetProperty}
          multiCampaign={this.state.isSnippetForMultiCampaign}
          multiCampaignWarning={ this.props.isMultiCampaignAvailable && !this.props.isMultiCampaignAvailableDefault }
          accountFeatures={this.props.accountFeatures}
        />}
      </div>
    );
  }
}
const mapStateToProps = function (store) {
  return {
    campaigns: store.campaignState.getIn(['campaigns', 'value']),
    pendingRequestsMap: new Map({
      campaigns: store.campaignState.getIn(['campaigns', 'pending']),
    })
  };
}
export default connect(
  mapStateToProps, {
  getAllPublicLiveCampaigns,
  getAllStageLiveCampaigns,
}
)(Properties);