import React from 'react';
import { connect } from 'react-redux';
import ConsentSubnav from './ConsentSubnav';

class ConsentLayout extends React.Component {
  render() {
    if (!this.props.currentUser) return null;

    return (
      <div id='avocado' className='layout-container consent-layout'>

        <div className="navbar navbar-default sitesentry-subnav">
          <ConsentSubnav
            id="subnav"
            v2={this.props.location.pathname.includes('v2')}
            nonIab={this.props.location.pathname.includes('non-iab')}
            currentUser={this.props.currentUser}
          />
        </div>

        {this.props.children && React.cloneElement(this.props.children, {
          location: this.props.location
        })}

      </div>
    )
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
  };
};

export default connect(mapStateToProps)(ConsentLayout);
