import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from './CustomButton.js.jsx';

export default class extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    render() {
        return (
            <div className="import-button-container">
              <CustomButton {...this.props} type={ CustomButton.types.PLAIN } className="import-button" >{ this.props.children }</CustomButton>
            </div>
        )
    }
}
