import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { regionsMap } from '../../../../constants';

const languagesMap = {
  EN: 'English',
  DE: 'German',
  FR: 'French',
  SP: 'Spanish', 
  IT: 'Italian', 
  DU: 'Dutch', 
  SW: 'Sweedish',
};

const languageFilters = Object.values(languagesMap).map(language => {
  return ({
    text: language,
    value: language,
  });
});

const regionFilters = Object.values(regionsMap).map(region => {
  return ({
    text: region,
    value: region,
  });
});

const cmpTypesMap = {
  iab: 'IAB CMP',
  nonIab: 'Non IAB CMP',
  notDetected: 'No Notice Detected',
};

export default class AccuracyPerformanceGraph extends React.Component {
  static propTypes = {
    getAccountOwners: PropTypes.func.isRequired,
    goToManualScoring: PropTypes.func.isRequired,
  }

  state = {
  }

  generateTable = () => {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'ruleId',
        key: 'ruleId',
        sorter: (a, b) => {
          const num1 = parseInt(a.ruleId.slice(1))
          const num2 = parseInt(b.ruleId.slice(1))
          return num1 - num2;
        },
      },
      {
        title: 'Rules',
        dataIndex: 'ruleName',
        key: 'ruleName',
      },      
      {
        title: <p>Consent language <span>(If Applicable)</span></p>,
        dataIndex: 'consentLanguage',
        key: 'consentLanguage',
        filters: languageFilters,
        onFilter: (value, record) => record.consentLanguage && record.consentLanguage.startsWith(value),
        filterSearch: true,
      },
      {
        title: <p>CMP Type <span>(If Applicable)</span></p>,
        dataIndex: 'cmpType',
        key: 'cmpType',
        filters: [
          { 
            text: 'IAB CMP',
            value: 'IAB CMP',
          },
          {
            text: 'No Notice Detected',
            value: 'No Notice Detected',
          },
        ],
        onFilter: (value, record) => record.cmpType.startsWith(value),
        filterSearch: true,
      },
      {
        title: 'Scan Region',
        dataIndex: 'scanRegion',
        key: 'scanRegion',
        filters: regionFilters,
        onFilter: (value, record) => record.scanRegion.startsWith(value),
        filterSearch: true,
      },    
      {
        title: 'Accuracy',
        dataIndex: 'accuracy',
        key: 'accuracy',
        sorter: (a, b) => {
          const num1 = parseInt(a.accuracy.slice(0,-1))
          const num2 = parseInt(b.accuracy.slice(0,-1))
          return num1 - num2;
        },
      },
      {
        title: '% True Positive',
        dataIndex: 'truePositive',
        key: 'truePositive',
        sorter: (a, b) => {
          const num1 = parseInt(a.truePositive.slice(0,-1))
          const num2 = parseInt(b.truePositive.slice(0,-1))
          return num1 - num2;
        },
      },  
      {
        title: '% True Negative',
        dataIndex: 'trueNegative',
        key: 'trueNegative',
        sorter: (a, b) => {
          const num1 = parseInt(a.trueNegative.slice(0,-1))
          const num2 = parseInt(b.trueNegative.slice(0,-1))
          return num1 - num2;
        },
      }, 
      {
        title: '% True Undetermined',
        dataIndex: 'trueUndetermined',
        key: 'trueUndetermined',
        sorter: (a, b) => {
          const num1 = parseInt(a.trueUndetermined.slice(0,-1))
          const num2 = parseInt(b.trueUndetermined.slice(0,-1))
          return num1 - num2;
        },
      },   
    ];

    const ruleIds = Object.keys(this.props.systemAccuracy);
    const dataSource = [];
    
    ruleIds.forEach(ruleId => {
      const rules = this.props.systemAccuracy[ruleId];
      rules.forEach(rule => {
        const numTests = rule.truePositive + rule.trueNegative + rule.falsePositive + rule.falseNegative + rule.trueUndetermined + rule.falseUndetermined;
        const systemRule = this.props.rules.find(r => r.ruleId === ruleId);
        dataSource.push({
          key: Math.random().toString(),
          ruleId: rule.ruleId,
          ruleName: systemRule ? systemRule.name : 'not available',
          consentLanguage: languagesMap[rule.language],
          cmpType: rule.cmpType ? cmpTypesMap[rule.cmpType] : 'No Notice Detected',
          scanRegion: regionsMap[rule.region],
          accuracy: this.props.na ? `${((rule.falseUndetermined / numTests) * 100).toFixed(0)}%` : `${(rule.overallAccuracy * 100).toFixed(0)}%`,
          truePositive: `${((rule.truePositive / numTests) * 100).toFixed(0)}%`,
          trueNegative: `${((rule.trueNegative / numTests) * 100).toFixed(0)}%`,
          trueUndetermined: `${((rule.trueUndetermined / numTests) * 100).toFixed(0)}%`,
          falsePositive: `${((rule.falsePositive / numTests) * 100).toFixed(0)}%`,
          falseNegative: `${((rule.falseNegative / numTests) * 100).toFixed(0)}%`,
          falseUndetermined: `${((rule.falseUndetermined / numTests) * 100).toFixed(0)}%`,
        });
      });
    });

    return { columns, dataSource };
  }

  render() {
    if (!this.props.rules.size) return null;
    const tableData = this.generateTable();
    return (
      <div className='summary-card'>
        <Table
          dataSource={ tableData.dataSource }
          columns={ tableData.columns }
          bordered
        />
      </div>
    );
  }
}

