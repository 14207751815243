import { Map, OrderedSet } from 'immutable';

import { StoreRecord } from '../records/store_records';
import {
  setValue,
  setError,
  setPending
} from './helpers.js';
import { 
  CHANGE_LOGS_PENDING, 
  CHANGE_LOGS_LOADED, 
  CHANGE_LOGS_ERROR 
} from '../actions/action_types';

const initialState = Map({ changeLogs: new StoreRecord({ value: OrderedSet([]) }) });

export default (state = initialState, action) => {
  switch (action.type) {
    case (CHANGE_LOGS_PENDING):
      return setPending(state, ['changeLogs']);
    case (CHANGE_LOGS_LOADED):
      return setValue(state, ['changeLogs'], action.payload);
    case (CHANGE_LOGS_ERROR):
      return setError(state, ['changeLogs'], true);
  };
  return state
}