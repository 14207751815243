import { starters } from 'message-preact'

export function patchMessage(msg, isTemplate) {
  let patchedMsg = msg
  if (patchedMsg.message_sub_category_id === 7) {
    const jsonKey = isTemplate ? 'template_json' : 'message_json'
    const json = JSON.parse(patchedMsg[jsonKey])

    if (!isTemplate) {
      json.siblings = json.siblings.length === 4 ? [...json.siblings, ...starters['privacy-manager-ott'].slice(-2)] : json.siblings
      patchedMsg = {
        ...patchedMsg,
        [jsonKey]: JSON.stringify(json)
      }
    } else {
      patchedMsg = {
        ...patchedMsg,
        [jsonKey]: JSON.stringify(json.length === 4 ? [...json, ...starters['privacy-manager-ott'].slice(-2)] : json)
      }
    }
  }

  return patchedMsg
}

export const getTextAndButtonComponents = (data) => {
  const components = [];

  data.forEach((component, index) => {

    if (component.type === 'Text' || component.type === 'Button' || component.type === 'PMButton') {
      components.push(component.name);
    }

    if (component.children && component.children.length > 0) {
      const childComponents = getTextAndButtonComponents(
        component.children,
      );
      components.push(...childComponents);
    }
  });

  return components;
};

export const hasDuplicateNames = (data) => {
  const nameSet = new Set();

  for (const item of data) {
    if (nameSet.has(item.name)) {
      return true;
    } else {
      nameSet.add(item.name);
    }
  }

  return false;
}
