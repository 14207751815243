import React, { Component } from 'react';
import { Select as AntdSelect } from 'antd';
import classNames from 'classnames';

class Select extends Component {
  render() {
    const props = { ...this.props };

    const classes = {
      'spsg-error': props.error,
    }
   let className = classNames('spsg-select', classes)

    // Handle Dropdown ClassName
    if (!props.dropdownClassName) props.dropdownClassName = 'spsg-dropdown';

    // handle deprecated 'dropdownClassName'
    if (props.dropdownClassName) {
      props.popupClassName = classNames(props.popupClassName, props.dropdownClassName);
      delete props.dropdownClassName
    }

    return (
      <div className={className}>
        <AntdSelect {...props} />
      { props.error && <div className="spsg-details">{ props.error }</div> }
      </div>
    );
  }
}

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
export default Select;
