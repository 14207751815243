import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { Input, Collapse } from 'antd';
import {
  getDomainSets,
  getAccuracySets,
} from '../../../../actions/domain_set_actions';
import { getAccountOwners } from '../../../../actions/account_actions';
import PropertyCard from '../../domain_set/PropertyCard';
import ManualScoringDetail from './ManualScoringDetail.jsx';

const Search = Input.Search;

const { Panel } = Collapse;

class ManualScoring extends React.Component {
  static propTypes = {
    getAccountOwners: PropTypes.func.isRequired,
    getAccuracySets: PropTypes.func.isRequired,
  }

  state = {
    selectedAccount: null,
    selectedSet: null,
    selectedSetIsAccuracy: false,
  }

  onCurrentUserAvailableDo = async () => {
    if (this.props.currentUser.accountId === 22) {
      this.props.getAccountOwners();
      this.props.getAccuracySets();
    }
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  updateSearch = (e) => this.setState({ search: e.target.value, currentPage: 1 });

  goBackToList = () => this.setState({ selectedSet: null });

  render() {
    if (this.state.selectedSet) {
      return (
        <ManualScoringDetail
          currentUser={this.props.currentUser}
          propertySet={this.state.selectedSet}
          accuracy={this.state.selectedSetIsAccuracy}
          goBackToList={this.goBackToList}
        />
      );
    }

    let propertySets = this.props.domainSets;
    let accuracySets = this.props.accuracySets;
    if (this.state.search) {
      propertySets = propertySets.filter(ps => ps.name.toLowerCase().includes(this.state.search.toLowerCase()));
      accuracySets = accuracySets.filter(ps => ps.name.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    return (
      <div className='domain-sets-container privacy-lens manual-scoring'>
        <div className='search-container'>
          <Search
            placeholder="Search Property Set"
            onChange={this.updateSearch}
            className="vendor-filter"
            style={{ width: 190 }}
          />
        </div>
        <Collapse defaultActiveKey={['2']}>
          <Panel header={<div>Account Property Sets<span className='number'>({propertySets.length})</span></div>} key="1">
            <div className="privacy-lens-grid-properties">
              {propertySets.map((ps, i) => {
                  return <PropertyCard
                    key={'standard ' + i}
                    set={ps}
                    editDomainSet={() => this.setState({ selectedSet: ps, selectedSetIsAccuracy: false })}
                    deleteDomainSet={() => {}}
                    showCards
                    type="account"
                    accountId={this.props.currentUser.accountId}
                    limited='true'
                  />
              })}
            </div>
          </Panel>
          <Panel header={<div>System Accuracy Sets<span className='number'>({accuracySets.length})</span></div>} key="2">
            <div className="privacy-lens-grid-properties">
              {accuracySets.map((ps, i) => {
                  return <PropertyCard
                    key={'standard ' + i}
                    set={ps}
                    editDomainSet={() => this.setState({ selectedSet: ps, selectedSetIsAccuracy: true })}
                    deleteDomainSet={() => {}}
                    showCards
                    type="account"
                    accountId={this.props.currentUser.accountId}
                    limited='true'
                    accuracy
                  />
              })}
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    allAccounts: store.accountState.getIn(['allAccounts', 'value']),
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    accuracySets: store.domainSetState.getIn(['accuracySets', 'value']),
    standards: store.standardState.getIn(['standards', 'value']),
    pendingRequestsMap: new Map({
      domainSets: store.domainSetState.getIn(['domainSets', 'pending']),
      accuracySets: store.domainSetState.getIn(['accuracySets', 'pending']),
      standards: store.standardState.getIn(['standards', 'pending']),
      domainSetCreateOrUpdate: store.domainSetState.get('createOrUpdatePending'),
      allAccountsPending: store.accountState.getIn(['allAccounts', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
  getAccountOwners,
  getDomainSets,
  getAccuracySets,
},
)(ManualScoring);
