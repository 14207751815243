import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CheckCircleOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Button, Checkbox, Tooltip } from 'antd';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Vendor } from '../../../records/vendor_list_records';
import { Site } from '../../../records/site_records';
import ScannedVendorDetail from './ScannedVendorDetail';
import classNames from 'classnames';
import Loading from '../../common/Loading.js.jsx';
import { getScannedVendors, clearScannedVendors } from '../../../actions/vendor_list_actions_v2.js';
import AutoAddNewVendorsCheckbox from './AutoAddNewVendorsCheckbox';
import SelectSitesAndSiteGroups from '../../common/SelectSitesAndSiteGroups';

class VendorScanModal extends React.Component {
  static propTypes = {
    vendorListId: PropTypes.string.isRequired,
    existingSiteIds: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    sitesForScan: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    scannedVendors: ImmutablePropTypes.listOf(PropTypes.instanceOf(Vendor)).isRequired,
    autoScanOn: PropTypes.bool.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,

    getScannedVendors: PropTypes.func.isRequired,
    handleAddVendors: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    toggleAutoScan: PropTypes.func.isRequired,
    clearScannedVendors: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const update = {};

    if (prevState.scannedVendors !== nextProps.scannedVendors) {
      update.selectedVendorIds = nextProps.scannedVendors.filter(v => v.isNew).map(v => v.id);
      update.allVendorsSelected = true;
    }

    return update;
  }

  state = {
    allVendorsSelected: true,
    openedDetailVendorIds: List([]),
    siteIdsToScan: this.props.existingSiteIds,
    selectedVendorIds: List([]),
  }

  handleSelectSite = (siteIds) => {
    this.setState({
      siteIdsToScan: List(siteIds),
    });
  }

  handleScanOrAddVendors = (e, rescan = false) => {
    const siteIdsToScan = this.state.siteIdsToScan.filterNot(el => el === 'allSites');
    if (!rescan && this.props.scannedVendors.size) {
      this.props.handleAddVendors(this.getSelectedScannedVendors(), siteIdsToScan);
      this.props.clearScannedVendors();
    } else {
      this.props.getScannedVendors(siteIdsToScan, this.props.vendorListId);
    }
  }

  handleCancelModalChanges = () => {
    this.props.clearScannedVendors()
    this.props.handleCloseModal();
  }

  getSelectedScannedVendors = () => {
    return this.props.scannedVendors.filter(v => this.state.selectedVendorIds.includes(v.id));
  }

  onCheckboxChange = (e, vendorId) => {

    const checked = e.target.checked;

    let updatedVendorIds = this.state.selectedVendorIds;
    if (checked) {
      updatedVendorIds = updatedVendorIds.push(vendorId);
    } else {
      updatedVendorIds = updatedVendorIds.filterNot(vId => vId === vendorId);
    }

    this.setState({
      selectedVendorIds: updatedVendorIds,
    });
  }

  toggleAllVendorsSelected = () => {
    let selectedVendorIds;
    if (this.state.allVendorsSelected) {
      selectedVendorIds = List([]);
    } else {
      selectedVendorIds = this.props.scannedVendors.filter(v => v.isNew).map(v => v.id);
    }
    this.setState({
      allVendorsSelected: !this.state.allVendorsSelected,
      selectedVendorIds,
    });
  }

  toggleOpenVendorDetail = (id) => {
    let updatedOpenedDetailVendorIds;
    if (this.state.openedDetailVendorIds.includes(id)) {
      updatedOpenedDetailVendorIds = this.state.openedDetailVendorIds.filterNot(vId => vId == id);
    } else {
      updatedOpenedDetailVendorIds = this.state.openedDetailVendorIds.push(id);
    }
    this.setState({
      openedDetailVendorIds: updatedOpenedDetailVendorIds,
    });
  }

  renderScannedVendorName = (v) => {
    if (v.isNew) {
      return (
        <Checkbox
          onChange={ (e) => this.onCheckboxChange(e, v.id) }
          checked={ this.state.selectedVendorIds.includes(v.id) }
        >
          { v.name }
        </Checkbox>
      );
    } else {
      return v.name;
    }

  }

  renderListOfVendors = (vendors) => {

    return vendors.map(v => {
      let vendorDetail;
      const detailOpen = this.state.openedDetailVendorIds.includes(v.id);
      if (detailOpen) {
        vendorDetail = (
          <ScannedVendorDetail vendor={ v } />
        );
      }
      return (
        <li className={ classNames({'detail-open' : detailOpen}) }>
          <div className='top'>
            { this.renderScannedVendorName(v) }
            <div onClick={ () => this.toggleOpenVendorDetail(v.id) }>
              <DownOutlined />
            </div>
          </div>
          { vendorDetail }
        </li>
      );
    });
  }

  render() {
    if (this.props.pendingRequestsMap.get('sites')) {
      return <Loading />;
    }

    const vendorNum = this.state.selectedVendorIds.size;
    const okText = this.props.scannedVendors.size ? `Add ${ vendorNum } to Vendor List` : 'Begin site scan';
    const footer = (
      <React.Fragment>
        <Button onClick={ this.handleCancelModalChanges }>Cancel</Button>
        <Button
          type="primary"
          onClick={ this.handleScanOrAddVendors }
          disabled={ !this.state.siteIdsToScan.size }
        >
          { okText }
        </Button>
      </React.Fragment>
    );

    let listOfVendors;
    const newVenNum = this.props.scannedVendors.filter(v => v.isNew).size;
    if (this.props.scannedVendors.size) {
      listOfVendors = (
        <div className='list-of-vendors-container'>
          <ul className='select-all'>
            <li>
              <Checkbox
                onChange={ this.toggleAllVendorsSelected }
                checked={ this.state.allVendorsSelected }
              >
                { `All new ${ newVenNum } selected` }
              </Checkbox>
              <div className='selected-num'>
                <CheckCircleOutlined />
                <p>{ `${ this.props.scannedVendors.size } vendors found.` }</p>
              </div>
            </li>
          </ul>
          <div className='scrolable-vendors'>
            <ul className='select-all'>
              <li>
                  <p>New Vendors</p>
              </li>
            </ul>
            <ul>
              { this.renderListOfVendors(this.props.scannedVendors.filter(v => v.isNew)) }
            </ul>
            <ul className='select-all'>
              <li>
                  <p>Vendors that are already in this vendor list</p>
              </li>
            </ul>
            <ul>
              { this.renderListOfVendors(this.props.scannedVendors.filterNot(v => v.isNew)) }
            </ul>
          </div>
        </div>
      )
    }

    let rescanButton;
    if (this.state.siteIdsToScan.size && this.props.scannedVendors.size) {
      rescanButton = <p className='rescan' onClick={ (e) => this.handleScanOrAddVendors(e, true) }>Rescan sites</p>;
    }

    let loading;
    if (this.props.pendingRequestsMap.get('vendorScan')) {
      loading = <Loading />;
    }

    const tooltipText = "If a site does not appear in a dropdown, it means that it has not been set up for vendor scanning. Please contact support to have it set up."
    return (
      <Modal
        visible
        className='vendor-scan'
        footer={ footer }
        closable={ false }
      >
        { loading }
        <div className='modal-top'>
          <p className='title'>Website Vendor Scan</p>
          <p className='descr'>
            Add a few sites and we’ll search for all active 3rd party vendors.
            <Tooltip title={ tooltipText } > <InfoCircleOutlined /></Tooltip>
          </p>

          <SelectSitesAndSiteGroups
            value={ this.state.siteIdsToScan.size ? this.state.siteIdsToScan : List([]) }
            sites={ this.props.sitesForScan }
            siteGroups={ List([]) }
            onSelect={ this.handleSelectSite }
            multiple
            siteAccess={ this.props.siteAccess }
          />

          <div className='auto-add-rescan-container'>

            <AutoAddNewVendorsCheckbox
              checked={ this.props.autoScanOn }
              onChange={ this.props.toggleAutoScan }
              tooltipPlacement='right'
            />

            { rescanButton }
          </div>
        </div>

        { listOfVendors }
      </Modal>
    );
  }
}

const mapStateToProps = function (store){
  return {
    scannedVendors: store.vendorListState.getIn(['scannedVendors', 'value']),
    pendingRequestsMap: new Map({
      vendorScan: store.vendorListState.getIn(['scannedVendors', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getScannedVendors,
    clearScannedVendors,
  },
)(VendorScanModal);
