import React, { useState } from 'react';
import { Modal } from '../../../../styleguide'
import { getCampaignNameLatestEnv } from "../helper"

const ConfirmationModal = ({ 
  campaign, 
  isModalVisible, 
  isEndedCampaign, 
  handleOk, 
  handleCancel, 
  isMultiCampaignEnabled,
  isReplaceCampaign,
  replaceCampaignMsg,
  maxMessagePerPV,
  isPriorityChange,
}) => {
  let name = 'Campaign 36';
  let environment;
  let newPriority = 0;
  if(campaign && campaign._v == 1) {
    name = campaign.description;
    environment = campaign.diffEnv;
  } else if(campaign && campaign._v == 2) {
    newPriority = campaign.newPriorityIndex + 1;
    name = campaign.description;
  }
  const title = isEndedCampaign ? 'End Campaign' : 'Confirmation';
  const header = <React.Fragment>{title}</React.Fragment>;
  const endCampaignMsg = `Are you sure that you want to end '${getCampaignNameLatestEnv(name)}'?`;
  const copyCampaignMsg = `Please confirm you want to start delivering ${name} to your users in ${environment}`;
  const moveCampaignMsg = `Please confirm you want to start delivering '${getCampaignNameLatestEnv(name)}' to your users with priority ${newPriority}?`;
  const msgAsPerVersion = isMultiCampaignEnabled ? moveCampaignMsg : copyCampaignMsg;
  const maxMessagePerPVMsg = maxMessagePerPV ? `Please confirm you want to change maximum number of messages per pageview to ${maxMessagePerPV}?` : '';
  const message = isReplaceCampaign? replaceCampaignMsg:isPriorityChange? moveCampaignMsg : isEndedCampaign ? endCampaignMsg : maxMessagePerPV ? maxMessagePerPVMsg : msgAsPerVersion;

  return (
      <Modal
        className="campaign-confirm-modal"
        title={header} 
        visible={isModalVisible} 
        onOk={(e) => handleOk(e)} 
        onCancel={(e) => handleCancel(e)}
        okText="Yes"
        cancelText="No"
        closable={true}>
        {message}
      </Modal>
  );
};

export default ConfirmationModal;