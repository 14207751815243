import 'whatwg-fetch';
import { fromJS, Range, List } from 'immutable';
import moment from 'moment';
import { checkStatus } from './helper.js';

import { WhitelistRecord } from '../records/whitelist_records.js';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export async function getAndParseWhitelistGraphData(data) {
  const formattedData = data.update('start_date', start_date => start_date.format('YYYY-MM-DD'))
    .update('end_date', end_date => end_date.format('YYYY-MM-DD'))
    .toMap()
    .filter(v => List.isList(v) ? v.size : v)
    .toJS();

    const result = await fetch(`${permissionsSvsBase}/rtdp/msg/whitelist_count?start_date=${formattedData.start_date}&end_date=${formattedData.end_date}&domain=${formattedData.domain}`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        return resp.data;
      })

  return parseWhitelistGraphData(fromJS(result));
}


function populateWhitelistTableData(dates, whitelist) {
  const keys = Range(0, dates.size);

  return dates.zipWith(
    (date, whitelist, key) => ({
      'dates': date,
      'whitelist': whitelist,
      'key': key,
    }),
    whitelist,
    keys,
  ).toList();
}

function parseWhitelistGraphData(data) {
  const dates = data.map((dataPoint) => {
    const timestamp = dataPoint.get('x') * 1000;
    return moment(timestamp).utc().format('MMM D, YYYY');
  });

  const whitelist = data.map((dataPoint) =>  dataPoint.get('y') );
  const tableData = populateWhitelistTableData(dates, whitelist);

  return WhitelistRecord({
    tableData,
  });
}
