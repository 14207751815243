export function mapSummaryToMetricsList(summary) {
  const result = [];

  const sortedSummary = summary.sort((a, b) => {
    return new Date(a?.timestamp) - new Date(b?.timestamp);
    // if ((new Date(a?.timestamp).getUTCMonth() + 1) !== (new Date(b?.timestamp).getUTCMonth() + 1)) {
    //     return (new Date(a?.timestamp).getUTCMonth() + 1) - (new Date(b?.timestamp).getUTCMonth() + 1);
    // } else {
    //     return new Date(a?.timestamp).getDate() - new Date(b?.timestamp).getDate();
    // }
  });

  const metrics = {};

  for (let i = 0; i < sortedSummary?.length; i++) {
    for (let j = 0; j < sortedSummary[i]?.metrics?.length; j++) {
      const metric = sortedSummary[i]?.metrics[j];
      if (!metrics[metric.metric]) {
        metrics[metric?.metric] = {
          criteria: metric?.metric,
          relative_score: 0,
          propertiesSum: 0,
          propertiesCount: 0,
          properties: 2,
          benchmarkSum: 0,
          benchmarkCount: 0,
          benchmark: 0.9,
        };
      }
      const lastRelativeScore = sortedSummary[i]?.metrics?.find((a) => a?.metric === metric.metric)?.relative_score;
      // if (lastRelativeScore) {
      metrics[metric?.metric].relative_score = lastRelativeScore;
      // }

      if (!metrics[metric?.metric]?.relative_score_evolution) {
        metrics[metric?.metric].relative_score_evolution = [];
        metrics[metric?.metric].value_evolution = [];
        metrics[metric?.metric].benchmark_evolution = [];
      }

      metrics[metric?.metric]?.relative_score_evolution.push({
        score: metric?.relative_score,
      });

      // if (metric?.value) {
      metrics[metric?.metric].propertiesSum += metric.value;
      metrics[metric?.metric].propertiesCount += 1;

      metrics[metric?.metric]?.value_evolution.push({
        score: metric?.value,
      });
      // }

      // if (metric?.benchmark_value) {
      metrics[metric?.metric].benchmarkSum += metric.benchmark_value;
      metrics[metric?.metric].benchmarkCount += 1;

      metrics[metric?.metric]?.benchmark_evolution.push({
        score: metric?.benchmark_value,
      });
      // }
    }
  }

  for (const metric in metrics) {
    result.push({
      ...metrics[metric],
      properties:
        metric === 'Disclosed Vendors'
          ? Math.round(Math.round((metrics[metric].propertiesSum / metrics[metric].propertiesCount) * 10) / 10)
          : Math.round((metrics[metric].propertiesSum / metrics[metric].propertiesCount) * 10) / 10,
      benchmark:
        metric === 'Disclosed Vendors'
          ? Math.round(Math.round((metrics[metric].benchmarkSum / metrics[metric].benchmarkCount) * 10) / 10)
          : Math.round((metrics[metric].benchmarkSum / metrics[metric].benchmarkCount) * 10) / 10,
    });
  }
  return result;
}
