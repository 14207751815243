import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Alert } from 'antd';  

import { User } from '../../records/account_records';

class Zendesk extends React.Component {
  static propTypes = {
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      allOtherUsers: ImmutablePropTypes.listOf(User).isRequired,
    }).isRequired
  }

  render() {
    let message = "Success"
    let description = "You've been signed out of Zendesk"
    let type = "success"
    
    if (this.props.location.query.kind === "error") {
        type = "error"
        message = "Authentication Error"
        description = this.props.location.query.message + " Please contact your account administrator if you continue to have issues."
    }
    
    return (
      <React.Fragment>
        <Alert message={ message } description={ description } type={ type } showIcon />
      </React.Fragment>
    )
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value'])
  };
};

export default connect(
  mapStateToProps
)(Zendesk);