import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import _ from 'lodash';
import {
  Checkbox,
  Divider,
} from 'antd';
import { Table as AntTable, Input, Button, Select, Tooltip, Chip, TableFilterDropdown, message, Alert } from "../../../../../styleguide"
const { Option } = Select;
import { StarFilled, WarningOutlined, SearchOutlined, FilterOutlined, FilterFilled } from '@ant-design/icons';
import { List, Map, OrderedSet } from 'immutable';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { VendorList, Vendor, PurposeV2 as Purpose, VendorWrapper, CategorizationSchema } from '../../../../../records/vendor_list_records';
import TagSelect from './TagSelect';
import TableFooter from './TableFooter';
import EditPurposeModal from './EditPurposeModal';
import VendorDetailModal from './VendorDetailModal';
import CreateCustomPurposeModal from './CreateCustomPurposeModal';
import CreateIabPurposeModal from './CreateIabPurposeModal';
import IabSpecialFeaturesModal from './IabSpecialFeaturesModal';
import ManageStacksModal from './ManageStacksModal';
import { categorize } from '../../util';
import { legalBasis } from '../../helper';
import BulkEdit from './BulkEdit';
import DismissErrorModal from '../../../../common/DismissErrorModal';
import { flattenCategories } from '../wizard/helper';
import { removeDuplicates } from '../../../../../util';

const Search = Input.Search;
const purposeColumnWidth = 190;

const reorderCategories = (arr, iabPurposes) => {
  for (let i = 0; i < arr.length - 1; i++) {
    for (let j = 0; j < arr.length - i - 1; j++) {
      const firstIdx = iabPurposes.findIndex((iabPurpose) => {
        if (arr[j].type === 'IAB_STACK') {
          const categories = reorderCategories(arr[j].categories.toJS(), iabPurposes);
          const updatedPurposes = List(categories.map(ca => new Purpose(ca)));
          const updateCategory = arr.find(function (item) {
            return arr[j].name === item.name;
          }).set("categories", updatedPurposes)
          arr.splice(j, 1, updateCategory)
          return iabPurpose.name === arr[j].categories?.get(0)?.name;
        } else {
          return iabPurpose.name === arr[j].name
        }
      });
      const secondIdx = iabPurposes.findIndex((iabPurpose) => {
        if (arr[j + 1].type === 'IAB_STACK') {
          const categories = reorderCategories(arr[j + 1].categories.toJS(), iabPurposes);
          const updatedPurposes = List(categories.map(ca => new Purpose(ca)));
          const updateCategory = arr.find(function (item) {
            return arr[j + 1].name === item.name;
          }).set("categories", updatedPurposes)
          arr.splice((j + 1), 1, updateCategory)
          return iabPurpose.name === arr[j + 1].categories?.get(0)?.name;
        } else {
          return iabPurpose.name === arr[j + 1].name
        }
      });
      if (firstIdx > secondIdx) {
        const temp = arr[j];
        arr[j] = arr[j + 1];
        arr[j + 1] = temp;
      }
    }
  }
  return arr;
}

const updateVLCategory = (categories, iabPurposes) => {
  let iabStackAndPurposes = [];
  let customStackAndPurposes = List();
  categories.forEach((category) => {
    if (category.type === 'IAB_STACK' || category.type === 'IAB_PURPOSE') {
      iabStackAndPurposes.push(category);
    } else {
      customStackAndPurposes = customStackAndPurposes.push(category)
    }
  })
  const updateIabStackAndPurposes = List(reorderCategories(iabStackAndPurposes, iabPurposes));
  return updateIabStackAndPurposes.concat(customStackAndPurposes);
}
export default class Table extends React.Component {

  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    readOnly: PropTypes.bool.isRequired,
    vendorScanningEnabled: PropTypes.bool.isRequired,
    globalVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)).isRequired,
    updateVendorList: PropTypes.func.isRequired,
    toggleAddVendors: PropTypes.func.isRequired,
    toggleAllIabAndUpdate: PropTypes.func.isRequired,
    toggleAutoScan: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
    updateVendorCategorization: PropTypes.func.isRequired,
    updateActiveTab: PropTypes.func.isRequired,
  };

  state = {
    search: '',
    editingPurpose: null,
    editPurposeIndex: -1,
    stackEditingPurposeIsIn: null,
    editingPurposeStackIndex: -1,
    editVendor: null,
    editVendorId: null,
    manageStacksOpen: false,
    createCustomPurposeOpen: false,
    createIabPurposeOpen: false,
    iabSpecialFeaturesOpen: false,
    filteredInfo: null,
    bulkAction: null,
    selectedVendors: [],
    selectedVendorIds: [],
    bulkEdit: false,
    AllIabPurposesInVl: List([]),
    vendorTypeFilter: [],
    dismissModalVisible: false,
    confirmAction: ''
  };

  componentWillUnmount() {
    window._RUN_VL_FIX_ = null;
    window._VL_FIX_ENABLED = false;
  }

  componentDidMount() {
    window._ENABLE_VL_FIX_ = () => window._VL_FIX_ENABLED = true;
    // prevent horizontal scroll of the table to trigger go back or go forward in history
    ReactDOM.findDOMNode(this.refs.vendorEditTable).getElementsByClassName('ant-table-content')[0].addEventListener('mousewheel', function(e) {
      e.stopPropagation();
      var max = this.scrollWidth - this.offsetWidth; // this might change if you have dynamic content, perhaps some mutation observer will be useful here
    
      if (this.scrollLeft + e.deltaX < 0 || this.scrollLeft + e.deltaX > max) {
        e.preventDefault();
        this.scrollLeft = Math.max(0, Math.min(max, this.scrollLeft + e.deltaX));
      }
    }, false);

    let allVlIabPurposeWithIds = List([]);
    this.props.vendorList.categories.forEach((category) => {
      if (category.type?.includes('STACK')) {
        allVlIabPurposeWithIds = allVlIabPurposeWithIds.concat(category.categories.filter( c => c.type === "IAB_PURPOSE"));
      } else if (category.type === 'IAB_PURPOSE') {
        allVlIabPurposeWithIds = allVlIabPurposeWithIds.push(category);
      }
    });
    allVlIabPurposeWithIds = removeDuplicates(allVlIabPurposeWithIds, 'iabPurposeRef');
    this.setState({ ...this.state, AllIabPurposesInVl: allVlIabPurposeWithIds })
    const updatedCategories = updateVLCategory(this.props.vendorList.categories, this.props.iabPurposes);
    const updatedVendorList = this.props.vendorList.set('categories', updatedCategories)
    this.props.updateVendorList(updatedVendorList);
  }

  componentDidUpdate(prevProps) {
    const prevPropsFlat = flattenCategories(prevProps.vendorList.categories)?.map(c => c.name)?.toJS()
    const thisPropsFlat = flattenCategories(this.props.vendorList.categories)?.map(c => c.name)?.toJS()
    if (prevPropsFlat && thisPropsFlat && !(_.isEqual(prevPropsFlat, thisPropsFlat))) {
      const updatedCategories = updateVLCategory(this.props.vendorList.categories, this.props.iabPurposes);
      const updatedVendorList = this.props.vendorList.set('categories', updatedCategories)
      this.props.updateVendorList(updatedVendorList);
    }
  }

  editPurpose = (editPurpose, editPurposeIndex, stackEditingPurposeIsIn, editingPurposeStackIndex) => {
    this.setState({ editPurpose, editPurposeIndex, stackEditingPurposeIsIn, editingPurposeStackIndex });
  };

  savePurposeModal = (vl) => {
    this.props.updateVendorList(vl);
    this.editPurpose(null, -1, null, -1);
  };

  saveVendorList = (vl) => {
    this.props.updateVendorList(vl);
  }

  handleApplyVendorDetailModal = (vendorsActions, categories) => {
    let vendorsWrappers = this.props.vendorList.vendorsWrappers;
    if (vendorsWrappers.find(vendor => vendor.vendorId === vendorsActions.vendorId)) {
      const index = vendorsWrappers.findIndex(vendor => vendor.vendorId === vendorsActions.vendorId);
      vendorsWrappers = vendorsWrappers.set(index, vendorsActions);
    } else {
      vendorsWrappers = vendorsWrappers.push(vendorsActions);
    };
    this.props.updateVendorList(this.props.vendorList.merge({ vendorsWrappers, categories }));
    this.editVendor(null, -1);
  };

  filterVendorScanCategorizations = (value, purpose) => value.filter((item) =>
    item.vendorListCategoryName ? !(item.vendorListCategoryName === purpose.name) : !(item.vendorListCategoryId === purpose.id)
  )

  removeCookieCategories = (purpose) => {
    let vendorScanCategorizations = this.props.vendorList.vendorScanCategorizations.toJS();
    if (purpose.type === 'CUSTOM') {
      vendorScanCategorizations = this.filterVendorScanCategorizations(vendorScanCategorizations, purpose);
    } else if (purpose.type === 'CUSTOM_STACK') {
      purpose.categories.forEach((item) => {
        vendorScanCategorizations = item.type !== 'CUSTOM' ? this.filterVendorScanCategorizations(vendorScanCategorizations, purpose) : vendorScanCategorizations;
      })
    }
    return vendorScanCategorizations;
  }

  deletePurpose = (purposeIndex, purpose) => {
    const vendorScanCategorizations = this.removeCookieCategories(purpose);
    this.props.updateVendorList(this.props.vendorList.
      set('vendorScanCategorizations', fromJS(vendorScanCategorizations))
      .update('categories', c => c.delete(purposeIndex)));
    this.editPurpose(null, -1, null, -1);
  };

  deleteStack = (stack) => {
    const vendorScanCategorizations = this.removeCookieCategories(stack);
    const categories = this.props.vendorList.categories
      .filterNot(c => c.name === stack.name)
      .concat(stack.categories);
    this.props.updateVendorList(this.props.vendorList
      .set('vendorScanCategorizations', fromJS(vendorScanCategorizations))
      .set('categories', categories));
    this.editPurpose(null, -1, null, -1);
  }

  openCreateCustomPurposeModal = () => {
    this.setState({ createCustomPurposeOpen: true });
  };

  openCreateIabPurposeModal = () => {
    this.setState({ createIabPurposeOpen: true });
  };

  openIabSpecialFeaturesModal = () => {
    this.setState({ iabSpecialFeaturesOpen: true });
  };

  openCreateStackModal = () => {
    this.setState({ manageStacksOpen: true });
  }

  editVendor = (editVendor, editVendorId) => {
    this.setState({ editVendor, editVendorId });
  };

  deleteMultipleVendors = (rmVendorIds) => {
    const updatedCategories = this.props.vendorList
      .get('categories')
      .map(category => {
        if (category.categories.size) {
          let stackCategories = category.categories;
          stackCategories = stackCategories.map(category => {
            return category
              .update('vendorIds', vendorIds => vendorIds.filterNot(id => rmVendorIds.includes(id)))
              .update('vendorCategorization', vc => vc.filterNot(({ vendorId }) => rmVendorIds.includes(vendorId)));
          });
          return category.set('categories', stackCategories);
        } else {
          return category
            .update('vendorIds', vendorIds => vendorIds.filterNot(id => rmVendorIds.includes(id)))
            .update('vendorCategorization', vc => vc.filterNot(({ vendorId }) => rmVendorIds.includes(vendorId)));
        }
      });

    const updatedVendorsWrappers = this.props.vendorList
      .get('vendorsWrappers').filterNot(vw => rmVendorIds.includes(vw.vendorId));

    const updatedVendorList = this.props.vendorList
      .update('vendors', vendors => vendors.filterNot(v => rmVendorIds.includes(v.id)))
      .set('categories', updatedCategories)
      .set('vendorsWrappers', updatedVendorsWrappers);

    return updatedVendorList;
  }

  deleteVendor = (vendor) => {
    const updatedVendorList = this.deleteMultipleVendors([vendor.id]);
    this.props.updateVendorList(updatedVendorList);
    this.editVendor(null, null);
  };

  updateSearch = (e) => this.setState({ search: e.target.value });

  rowClassName = (record, i) => {
    if (i === 0 && !!(this.props.vendorList.categories.filter(c => c.type === 'IAB_STACK').size)) {
      return 'has-stack';
    } else {
      return '';
    }
  }

  setActiveFilter = (dataIndex, confirm, selectedKeys) => {
    const value = { [dataIndex]: selectedKeys };
    this.setState({ filteredInfo: value });
    confirm({ closeDropdown: true });
  }

  resetFilters = (clearFilters, setSelectedKeys, dataIndex) => {
    setSelectedKeys([]);
    clearFilters();
    delete this.state.filteredInfo[dataIndex];
    this.setState({
      filteredInfo: this.state.filteredInfo //to rerender
      }
    );
  };

  updatedLegalBasis = (purpose, iab, vendorId, defaultVendorBasis, defaultLegalBasis) => {
    let vendorValue = 'NOT_APPLICABLE';

    const vendorCategorization = purpose.get('vendorCategorization').find((obj) => obj.vendorId === vendorId);
    if (vendorCategorization) {
      vendorValue = !purpose.disclosureOnly
        ? vendorCategorization.type === 'DISCLOSURE_ONLY'
          ? ''
          : vendorCategorization.type
        : vendorCategorization.type === 'CONSENT' ||
          vendorCategorization.type === 'LEGITIMATE_INTEREST' ||
          !vendorCategorization.type
          ? ''
          : vendorCategorization.type;
    } else {
      if (iab && purpose.type === 'IAB_PURPOSE') {
        vendorValue = defaultVendorBasis || defaultLegalBasis;
      } else {
        const consentVendors = purpose.get('vendorIds').toJS();
        if (consentVendors.indexOf(vendorId) !== -1) {
          vendorValue = 'CONSENT';
        }
      }
    }

    return vendorValue;
  };

  getLegalBasis = (purpose, consentTypes, vendor) => {
    let legalBasis = 'NOT_APPLICABLE';
    const isCustomVendor = vendor.data.vendorType === 'CUSTOM';
    const defaultPurposeLegalBasis = isCustomVendor
      ? purpose.defaultCustomLegalBasis
      : purpose.defaultLegalBasis || this.props.vendorList.defaultLegalBasis;
    consentTypes = new List(consentTypes);
    const iabPurpose = !!purpose.iabPurposeRef;
    if (consentTypes.size || !vendor.data.iab || !iabPurpose) {
      const iab = !!vendor.data.iab;
      const vendorId = vendor.key;
      const defaultLegalBasis = defaultPurposeLegalBasis;
      const defaultVendorBasis =
        consentTypes.size === 1 ? (consentTypes.first() === 'consent' ? 'CONSENT' : 'LEGITIMATE_INTEREST') : null;
      legalBasis = this.updatedLegalBasis(purpose, iab, vendorId, defaultVendorBasis, defaultLegalBasis);
    }
    return legalBasis;
  };

  getColumnFilterProps = purpose => {
    const dataIndex = purpose.name;
    const updateLegalBasis = legalBasis.push(Map({
      label: 'Disclosure Only',
      value: 'DISCLOSURE_ONLY',
      customClass: 'lb-disclosure',
    }))
    return ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div className="vl-purpose-filter">
            <Checkbox.Group
              value={selectedKeys}
              options={updateLegalBasis.toJS()}
              onChange={(checkedValues) => setSelectedKeys(checkedValues)}
            />
            <div className="filter-footer">
              <Button type="secondary" disabled={!selectedKeys.length} onClick={() => this.resetFilters(clearFilters, setSelectedKeys, dataIndex)}>Clear Filter</Button>
              <Button type="secondary" onClick={() => this.setActiveFilter(dataIndex, confirm, selectedKeys)}>Apply</Button>
            </div>
          </div>
        )
      },
      onFilter: (value, vendor) => {
        const legalBasisValue = this.getLegalBasis(purpose, vendor[purpose.name], vendor);
        return legalBasisValue == value;
      },
      filteredValue: this.state.filteredInfo && this.state.filteredInfo[dataIndex] ? this.state.filteredInfo[dataIndex] : null,
    })
  };

  generatePurposeColumn = (purpose, ndx, stack, stackIdx) => {
    let stackDiv;
    let firstInStack;
    if (stack) {
      const stackWidth = stack.categories.size * purposeColumnWidth;
      firstInStack = stack.categories.first().name === purpose.name;
      // for ux purposes stack only exists for the first purpose in the stack
      if (firstInStack) {
        stackDiv = (
          <div
            className='stack'
            style={{ width: `${this.props.isNonIab ? `calc(100% * ${stack.categories.size} + ${21.8 * stack.categories.size}px)` : `${stackWidth}px`}` }}
            onClick={() => this.editPurpose(stack, stackIdx)}
          >
            <div>
              {stack.name}
              {stack.requireConsent && (
                <span style={{ color: 'red' }}>
                  *
                </span>
              )}
            </div>
          </div>
        );
      }
    }

    const filterProps = this.getColumnFilterProps(purpose);
    const iabPurpose = this.props.iabPurposes.find((iabPurpose) => iabPurpose.name === purpose.name);
    const numberOfVendorsWithThisPurpose = purpose.vendorCategorization.filter(categorization => categorization.type == 'CONSENT' || categorization.type == 'LEGITIMATE_INTEREST' || categorization.type === 'DISCLOSURE_ONLY').size;

    return Map({
      title: (
        <span className="th-container" id={`purpose-${iabPurpose?.iabId}`}>
          {stackDiv}
          <Tooltip title={purpose.name}>
            <div onClick={() => this.editPurpose(purpose, ndx, stack, stackIdx)} className={classNames('purpose-th', { 'in-stack': !!stack })} role="presentation">
              {iabPurpose && iabPurpose.iabId + '.'} {purpose.name} { iabPurpose?.iabId == 11 ? <div><Chip className="new-purpose">New</Chip></div> : null}
            </div>
          </Tooltip>
          <div className='vendor-count'>
            Vendors: <span>{numberOfVendorsWithThisPurpose}</span>
          </div>
        </span>
      ),
      width: `${purposeColumnWidth}px`,
      filterIcon: (active) => (
        <>
          {purpose.requireConsent && (
            <span style={{ color: 'red ', marginTop: '20px' }}>
              *
            </span>
          )}
          <FilterFilled style={{ color: active && '#00BF1A' }} />
        </>
      ),
      dataIndex: purpose.name,
      className: `${classNames('tag-selector', { 'stack-column': !!stack, [`purpose-${iabPurpose?.iabId}`]: iabPurpose })}`,
      render: (p, v) => {
        const isCustomVendor = v.data.vendorType === 'CUSTOM';
        const defaultPurposeLegalBasis = isCustomVendor
          ? purpose.defaultCustomLegalBasis
          : purpose.defaultLegalBasis || this.props.vendorList.defaultLegalBasis
        p = new List(p);
        const isIababPurpose = !!purpose.iabPurposeRef;
        const categorization = purpose.vendorCategorization.find(c => c.get('vendorId') === v.key);
        let highlightPurposeCell = false;
        if(this.props.purposesToFocus.length == 1 && this.props.purposesToFocus.includes(11) && iabPurpose?.iabId === 11){
          highlightPurposeCell = true;
        } else {
          highlightPurposeCell =   this.props.purposesToFocus.includes(iabPurpose?.iabId) && categorization && categorization.legalBasisChange;
        }

        if (p.size || !v.data.iab || !isIababPurpose) {
          return (
            <TagSelect
              updateVendorCategorization={this.props.updateVendorCategorization}
              vendorId={v.key}
              iab={!!v.data.iab}
              purpose={purpose}
              iabPurpose={iabPurpose}
              highlightPurposeCell={highlightPurposeCell}
              purposeIndex={ndx}
              readOnly={this.props.readOnly}
              vendorCategorization={this.props.vendorList.vendorCategorization}
              defaultLegalBasis={defaultPurposeLegalBasis}
              defaultVendorBasis={(p.size === 1)
                ? (p.first() === 'consent')
                  ? 'CONSENT' : 'LEGITIMATE_INTEREST'
                : null}
              isCustomVendor={isCustomVendor}
              vendor={this.props.vendorList.vendors.find(({ id }) => v.key == id)}
              fixUncategorizedVendor={this.fixUncategorizedVendor}
              purposesToFocus={this.props.purposesToFocus}
            />
          );
        } else {
          return (
            <div className={classNames({ 'not-aplicable-container': true, 'legal-basis-change': highlightPurposeCell })}>
              <span className="li-tag li-applicable">
                Not Applicable
              </span>
            </div>
          );
        }
      },
      ...filterProps,
    });
  }

  setPurposeConsentType = (data, p, v) => {
    let consentTypes = new List([]);
    if(v.iab) {
      if (v.purposes.map(p => typeof p === 'string' ? p : p.id).includes(p.iabPurposeRef)) {
        consentTypes = consentTypes.push('consent');
      }
      if (v.legIntPurposes.map(p => typeof p === 'string' ? p : p.id).includes(p.iabPurposeRef)) {
        consentTypes = consentTypes.push('legitimate');
      }
    } else {
      if(p.iabId === 1) consentTypes = consentTypes.push('consent')
    }
    return data.set(p.name, consentTypes);
  }

  getIabPurposesNotInVendorList = () => {
    let allVlIabPurposeRers = List([]);
    let allVlIabPurposeWithIds = List([]);
    this.props.vendorList.categories.forEach((category) => {
      if (category.type?.includes('STACK')) {
        allVlIabPurposeRers = allVlIabPurposeRers.concat(category.categories.filter( c => c.type === "IAB_PURPOSE").map(c => c.iabPurposeRef));
      } else if (category.type === 'IAB_PURPOSE') {
        allVlIabPurposeRers = allVlIabPurposeRers.push(category.iabPurposeRef);
      }
    });
    this.state.AllIabPurposesInVl.forEach((category) => {
      if (category.type?.includes('STACK')) {
        allVlIabPurposeWithIds = allVlIabPurposeWithIds.concat(category.categories.filter( c => c.type === "IAB_PURPOSE"));
      } else if (category.type === 'IAB_PURPOSE') {
        allVlIabPurposeWithIds = allVlIabPurposeWithIds.push(category);
      }
    });

    if (this.state.AllIabPurposesInVl?.size < this.props.iabPurposes?.size) {
      this.props.iabPurposes.forEach(purpose => {
        if (!allVlIabPurposeRers.includes(purpose.iabPurposeRef)) {
          if (!allVlIabPurposeWithIds.map(p => p.iabPurposeRef).includes(purpose.iabPurposeRef)) {
            let newPurpose = purpose.set("id", purpose.iabPurposeRef);
            allVlIabPurposeWithIds = allVlIabPurposeWithIds.push(newPurpose);
          }
        }
      })
    }

    let iabPurposesNotInVendorList = List([]);
    allVlIabPurposeWithIds.forEach(p => {
      if (!allVlIabPurposeRers.includes(p.iabPurposeRef)) {
        iabPurposesNotInVendorList = iabPurposesNotInVendorList.push(p);
      }
    });
    return iabPurposesNotInVendorList;
  }

  filterVendorList = (type) => {
    this.setState({vendorTypeFilter: type})
  }

  filterChanged = (vendor) => {
    return this.props.vendorList.vendorsWithLegalBasisChanges.includes(vendor.id);
  }

  fixUncategorizedVendor = (purpose, vendor, basis) => {
    if (!window._VL_FIX_ENABLED) {
      return;
    }

    let cleanVendor;
    if (Array.isArray(vendor.purposes)) {
      cleanVendor = (typeof vendor.purposes[0] === 'object' || typeof vendor.legIntPurposes[0] === 'object')
        ? vendor
          .update('purposes', p => p.map(({ id }) => id))
          .update('legIntPurposes', p => p.map(({ id }) => id))
        : vendor;
    } else {
      cleanVendor = (typeof vendor.purposes.get(0) === 'object' || typeof vendor.legIntPurposes.get(0) === 'object')
        ? vendor
          .update('purposes', p => p.map(({ id }) => id))
          .update('legIntPurposes', p => p.map(({ id }) => id))
        : vendor;
    }

    const vendorCat = categorize(purpose, cleanVendor, basis);

    //find cat
    let targetCategory;

    this.fixedVL.categories
      .forEach(function findCategory(category) {
        if (category.id === purpose.id) {
          targetCategory = category
        } else {
          category.categories.forEach(findCategory);
        }
      });

    const updatedCategorizations = targetCategory.vendorCategorization.update((categorizations) => {
      const updatedCategorizations = categorizations.push(vendorCat);
      return updatedCategorizations;
    });

    const updatedCategories = this.fixedVL.categories.update((categories) => {
      let targetCategory;
      categories.forEach(function findCategory(category) {
        if (category.id === purpose.id) {
          targetCategory = category
        } else {
          category.categories.forEach(findCategory);
        }
      });
      const updatedCategory = targetCategory.set('vendorCategorization', updatedCategorizations);

      return categories.map(function replaceCategory(category) {
        if (category.id === purpose.id) {
          return updatedCategory;
        }
        return category.update('categories', (categories => categories.map(replaceCategory)))
      });
    });

    this.fixedVL = this.fixedVL.set('categories', updatedCategories);

    window._RUN_VL_FIX_ = () => this.props.updateVendorList(this.fixedVL);
    window._SHOW_FIX_VL = () => console.log(this.fixedVL.toJS());
  }

  fixedVL = this.props.vendorList

  onChangeBulkAction = (operation) => {
    if (this.state.selectedVendors.length == 0) {
      message.warning('Please select one or more vendors');
      return;
    }
    switch (operation) {
      case 'edit':
        const states = { bulkAction: operation, bulkEdit: true };
        this.setState(states);
        break;
      case 'make-custom':
        this.setState({ dismissModalVisible: true, confirmAction: 'make_custom' });
        break
      case 'remove':
        this.setState({ dismissModalVisible: true, confirmAction: 'remove_vendors' });
        break;
    }
  }

  cancelBulkEdit = () => {
    this.setState({ bulkEdit: false, bulkAction: null, selectedVendors: [], selectedVendorIds: [] });
  }

  updateInBulk = (vl) => {
    this.cancelBulkEdit();
    this.props.updateVendorList(vl);
  }

  handleCancelRemoveBulk = () => {
    this.setState({ dismissModalVisible: false, confirmAction: '' });
    this.setState({ bulkAction: null, selectedVendors: [], selectedVendorIds: [] });
  }

  deleteVendors = () => {
    const rmVendorIds = this.state.selectedVendors.map(v => v.key);
    const updatedVendorList = this.deleteMultipleVendors(rmVendorIds);
    this.props.updateVendorList(updatedVendorList);
    this.handleCancelRemoveBulk();
  }

  filterDeletedVendors = (vendor) => {
    return this.props.vendorList.deletedIabVendors.map(v => v.iabVendorId).includes(vendor.id);
  }

  getReplacedCategorization = (vendorCategorization) => {
    const deletedVendors = this.props.vendorList.deletedIabVendors;

    return vendorCategorization.map(vc => {
      if (!this.state.selectedVendorIds.includes(vc.vendorId)) {
        return vc;
      }
      const cookies = vc.cookies.map(ck => {
        ck.id = null;
        return ck;
      });

      return new CategorizationSchema({
        vendorId: deletedVendors.find(dv => dv.iabVendorId == vc.vendorId).customVendorId,
        legalBasisChange: false,
        cookies,
        type: vc.type == 'NOT_ALLOWED' ? 'NOT_APPLICABLE' : vc.type,
      });
    }).filterNot(({ type }) => type === 'NOT_APPLICABLE');
  }

  replaceIabDeletedVendorsFromVl = () => {
    if (!this.state.selectedVendors.length) {
      message.warning('Please select vendors');
    }
    const deletedVendorIds = this.props.vendorList.deletedIabVendors.map(v => v.iabVendorId);
    const isNonDeletedVendorsSelected = this.state.selectedVendors.some(v => !deletedVendorIds.includes(v.key));
    if (isNonDeletedVendorsSelected) {
      message.warning('Please de-select vendors which are not deleted from Global vendor list');
      return;
    }

    const updatedCategories = this.props.vendorList
      .get('categories')
      .map(category => {
        if (category.categories.size) {
          let stackCategories = category.categories;
          stackCategories = stackCategories.map(category => {
            const updatedCategorization = this.getReplacedCategorization(category.vendorCategorization);
            return category
              .set('vendorIds', OrderedSet(updatedCategorization.map(vc => vc.vendorId)))
              .set('vendorCategorization', updatedCategorization);
          });
          return category.set('categories', stackCategories);
        } else {
          const updatedCategorization = this.getReplacedCategorization(category.vendorCategorization);
          return category
            .set('vendorIds', OrderedSet(updatedCategorization.map(vc => vc.vendorId)))
            .set('vendorCategorization', updatedCategorization);
        }
      });

    const oldVendorWrappers = this.props.vendorList.vendorsWrappers.filterNot(vw => this.state.selectedVendorIds.includes(vw.vendorId));
    const newVendorWrappers = this.props.vendorList.vendorsWrappers
      .filter(vw => this.state.selectedVendors.includes(vw.vendorId))
      .map(vw => {
        const eqCustomVendorId = this.props.vendorList.deletedIabVendors.find(dv => dv.iabVendorId == vw.vendorId).customVendorId;
        const cookies = vw.cookies.map(ck => ck.set('gvlDefined', false).set('id', null));
        return new VendorWrapper({
          ...vw.toJS(),
          id: null,
          vendorId: eqCustomVendorId,
          cookies,
          consentActions: vw.consentActions.map(ra => ra.set('id', '')),
          rejectActions: vw.rejectActions.map(ra => ra.set('id', ''))
        });
      });

    const oldVendors = this.props.vendorList.vendors.filterNot(v => this.state.selectedVendorIds.includes(v.id));

    const deletedVendorsWithNoEqCustomVendors = this.state.selectedVendors.filter((v) => !this.props.vendorList.deletedIabVendors.find(dv => dv.iabVendorId == v.key)?.customVendorId);
    if(deletedVendorsWithNoEqCustomVendors.length) {
      message.error(<>Vendor(s) <b>{deletedVendorsWithNoEqCustomVendors.map(v => v?.name).join(", ")}</b> were deleted as no custom vendor(s) exists with the same vendor name(s)</>, 5)
    }
    
    const newCustomVendors = this.state.selectedVendors.filter(v => !deletedVendorsWithNoEqCustomVendors.map(v => v.key).includes(v.key)).map(v => {
      const eqCustomVendorId = this.props.vendorList.deletedIabVendors.find(dv => dv.iabVendorId == v.key).customVendorId;
      return this.props.deletedVendorDetails.find(gdv => gdv.id == eqCustomVendorId);
    });

    message.success(<>Vendor(s) <b>{newCustomVendors.map(v => v?.name).join(", ")}</b> were successfully converted to custom vendor(s)</>, 5)

    const updatedVendorList = this.props.vendorList
      .set('vendors', oldVendors.concat(OrderedSet(newCustomVendors)))
      .set('categories', updatedCategories)
      .set('vendorsWrappers', oldVendorWrappers.concat(newVendorWrappers));

    this.props.updateVendorList(updatedVendorList);
  }

  onHandleSaveVl = () => {
    this.props.handleSave();
    this.setState({ dismissModalVisible: false, confirmAction: '' });
  }

  getIABUpdatesTabVendorCount = (tab) => {
    let count = 0;
    if (tab == 'iab-updated') {
      const updatedVendorIds = this.props.vendorList.vendorsWithLegalBasisChanges;
      count = this.props.vendorList.vendors.filter(v => updatedVendorIds.includes(v.id)).size;
    } else if (tab == 'iab-deleted') {
      const deletedVendorIds = this.props.vendorList.deletedIabVendors.map(v => v.iabVendorId);
      count = this.props.vendorList.vendors.filter(v => deletedVendorIds.includes(v.id)).size;
    }
    return count;
  }

  handleSelect = (vendor, selected, selVendors) => {
    if (selected) {
      this.setState((prevState) =>{ 
        const selectedVendorIds = [...prevState.selectedVendorIds, vendor.key];
        const selectedVendors = prevState.selectedVendors.concat(vendor).filter(v => Boolean(v) && selectedVendorIds.includes(v.key))
        return({
          selectedVendorIds,
          selectedVendors
        })
      });
    } else {
      this.setState((prevState) => {
        const index = prevState.selectedVendorIds.indexOf(vendor.key);
        const selectedVendorIds = [...prevState.selectedVendorIds.slice(0, index), ...prevState.selectedVendorIds.slice(index + 1)];
        const selectedVendors = prevState.selectedVendors.filter(v => Boolean(v) && selectedVendorIds.includes(v.key))
        return ({
          selectedVendorIds,
          selectedVendors,
        })
      });
    }
  };

  toggleSelectAll = (vendors) => {
    let updatedVendors = vendors.toJS();
    const filteredInfo = this.state.filteredInfo;
    if (filteredInfo) {
      const purposeName = Object.keys(filteredInfo)[0];
      const filterValues = filteredInfo[purposeName];
      const purpose = this.props.vendorList.categories.find(c => {
        if (c.categories.size) {
          return c.categories.find(catg => catg.name == purposeName);
        } else {
          return c.name == purposeName;
        }
      });
      updatedVendors = updatedVendors.filter(vendor => {
        const consentTypes = List(vendor[purposeName]);
        const legalBasis = this.getLegalBasis(purpose, consentTypes, vendor);
        return filterValues.includes(legalBasis);
      });
    }

    this.setState((prevState) => ({
      selectedVendorIds: prevState.selectedVendorIds.length === updatedVendors.length ? [] : updatedVendors.map((v) => v.key),
      selectedVendors: prevState.selectedVendorIds.length === updatedVendors.length ? [] : updatedVendors,
    }));
  };

  validateHandler = (value, id, message) => {
    let errorMsg = null;
    if (value === '') {
      errorMsg = message ? message : <>Field can not be empty.</>
    }
    else if (value) {
      let isNameExist = false;
      let existPurposeName;
      const filteredPurposes = this.props.vendorList.categories.filter((purpose) => purpose.type === 'CUSTOM' || purpose.type === 'CUSTOM_STACK')
      filteredPurposes.forEach((purpose) => {
        if (existPurposeName) return;
        else if (purpose.type === 'CUSTOM') {
          existPurposeName = purpose.id !== id && purpose.name.trim().toLowerCase() === value.trim().toLowerCase()
        }
        else if (purpose.type === 'CUSTOM_STACK' && purpose.categories.size !== 0) {
          existPurposeName = purpose.categories.find(category => category.id !== id && category.name.trim().toLowerCase() === value.trim().toLowerCase())
        }
      })
      isNameExist = existPurposeName ? true : false;
      errorMsg = isNameExist && (message ? message : <><strong>{value}</strong> name already exists.</>)
    }
    if (errorMsg) this.setState({ error: errorMsg });
    else this.setState({ error: null });

    return errorMsg ? errorMsg : null;
  }

  render() {
    const { iabDeletedVendors, iabUpdatedVendors } = this.props
    let vList;
    let vendorsForFilter = this.props.vendorList?.vendors;
    if (this.props.isAppleMessagingEnabled) {
      vList = this.props.vendorList.vendors.map(v => {
        const isAppleVendor = this.props.vendorList.vendorsWrappers.find(vendor => vendor.vendorId === v.id);
        if (isAppleVendor && isAppleVendor.isApple) {
          return v.set("isApple", isAppleVendor.isApple)
        } else return v.set("isApple", false)
      });

      vendorsForFilter = vList;
    } else {
      vList = this.props.vendorList.vendors;
    }

    if (this.props.activeTab == 'iab-updated') {
      vList = this.props.vendorList.vendors.filter(this.filterChanged);
    }

    if (this.props.activeTab == 'iab-deleted') {
      vList = this.props.vendorList.vendors.filter(this.filterDeletedVendors);
    }

    const isAnyIAB = Boolean(vList.some(v => v.iab));
    const isAnyAppleDataBroker = Boolean(vList.some(v => v.isApple));
    const isAnyRequireConsent = Boolean(this.props.vendorList.categories.some(c => c.requireConsent))


    const iabvendorsCount = vendorsForFilter?.filter(v => v.vendorType === 'IAB').size;
    const customVendorsCount = vendorsForFilter?.filter(v => v.vendorType === 'CUSTOM' && !v.isGoogleAtp && !v.isApple).size;
    const googleAtpVendorsCount = vendorsForFilter?.filter(v => v.vendorType === 'CUSTOM' && v.isGoogleAtp).size;
    const appleVendorsCount = vendorsForFilter?.filter(v => v.isApple).size;

    let columns = List([Map({
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'name',
      width: '200px',
      fixed: 'left',
      className: 'vendor-name-column',
      filters: [
        {
          icon: <i  className="fas fa-circle" style={{color:'#FCA015',width: 10, height: 10,  marginRight: 10}} />,
          text: `IAB Vendors (${iabvendorsCount})`,
          value: 'IAB',
        },
        {
          icon: <img  style={{ width: 10, height: 10, marginRight: 10 }} src="/images/google-icon.svg" />,
          text: `Google ATP (${googleAtpVendorsCount})`,
          value: 'CUSTOMATP',
        },
        {
          icon: <i className="fas fa-circle" style={{color:'#0098FF',width: 10, height: 10,  marginRight: 10}} />,
          text: `Custom Vendors (${customVendorsCount})`,
          value: 'CUSTOM',
        },
        {
          icon: <i className="fas fa-circle" style={{color:'#ACACAC',width: 10, height: 10,  marginRight: 10}} />,
          text: `Apple Data Broker (${appleVendorsCount})`,
          value: 'APPLE',
        },
      ].filter( f => !(this.props.isNonIab && f.value === 'IAB')).filter(f => !(!this.props.isAppleMessagingEnabled && f.value === 'APPLE')),
      filterDropdown: (props) => <TableFilterDropdown {...props} selectAllOption dropdownClassName="add-vendors-table-filter" handleSelectedKeysOnSave={this.filterVendorList} />,
      onFilter: (type, record) => {
        if (type === 'IAB') {
          return record.data.vendorType === 'IAB'
        } else if (type === 'CUSTOM') {
          return record.data.vendorType === 'CUSTOM' && !record.data.isGoogleAtp && !record.data.isApple
        } else if (type === 'CUSTOMATP') {
          return record.data.vendorType === 'CUSTOM' && record.data.isGoogleAtp === true
        } else if (type === 'APPLE') {
          return record.data.isApple === true
        }
        return true;
      },
      filteredValue: this.state.vendorTypeFilter
    })]);

    this.props.vendorList.categories.forEach((category, ndx) => {
      if (category.type === 'IAB_STACK' || category.type === 'CUSTOM_STACK') {
        if (category.type === 'IAB_STACK') {
        }

        category.categories.forEach((purpose, i) => {
          columns = columns.push(this.generatePurposeColumn(purpose, i, category, ndx));
        });
      } else {
        columns = columns.push(this.generatePurposeColumn(category, ndx));
      }
    });

    // Build out the table's row data using vendors
    let dataSource = vList.map((v) => {
      const vendorTitle = (
        <span className="td-container">
          <div onClick={() => this.editVendor(v, v.id)} className="vendor-td" role="presentation">
            {(v.isGoogleAtp && this.props.atpEnabled) && (
              <Tooltip title="Google ATP">
                <img style={{ width: 10, height: 10, marginRight: 5 }} src="/images/google-icon.svg" />
              </Tooltip>
            )}
            {v.iab || v.isApple ? (
              <span>
                {v.iab && <i className="fas fa-circle" />}
                {v.isApple && this.props.isAppleMessagingEnabled && <i className="fas fa-circle is-apple-broker" />}
                {v.name}
              </span>
            ) :
              <span>
                {!v.isGoogleAtp && <i className="fas fa-circle" style={{ color: '#0098FF', width: 10, height: 10, marginRight: 10 }} />}
                {v.name}
              </span>}
          </div>
        </span>
      );
      let data = Map({
        key: v.id,
        vendor: vendorTitle,
        name: v.name,
        data: v,
      });
      this.props.vendorList.categories.forEach(category => {

        //XXX CUSTOM_STACK
        if (category.type?.includes('STACK')) {
          category.categories.forEach(p => { data = this.setPurposeConsentType(data, p, v) });
        } else {
          data = this.setPurposeConsentType(data, category, v);
        }
      });

      return data;
    });

    // Filter vendor rows based on search
    dataSource = dataSource.filter((v) => {
      if (!this.state.search) return true;
      return v.get('name').toUpperCase().indexOf(this.state.search.toUpperCase()) !== -1;
    });

    const iabPurposesNotInThelist = this.getIabPurposesNotInVendorList();
    const hasIabPurposesToAdd = !!iabPurposesNotInThelist.size;

    const footer = (
      <TableFooter
        toggleAllIabAndUpdate={this.props.toggleAllIabAndUpdate}
        allIabAndAutoUpdate={this.props.allIabAndAutoUpdate}
        vendorList={this.props.vendorList}
        toggleAddVendors={this.props.toggleAddVendors}
        openCreateCustomPurposeModal={this.openCreateCustomPurposeModal}
        openCreateIabPurposeModal={this.openCreateIabPurposeModal}
        openIabSpecialFeaturesModal={this.openIabSpecialFeaturesModal}
        openCreateStackModal={this.openCreateStackModal}
        vendorScanningEnabled={this.props.vendorScanningEnabled}
        toggleAutoScan={this.props.toggleAutoScan}
        readOnly={this.props.readOnly}
        hasIabPurposesToAdd={hasIabPurposesToAdd}
        filterElement={this.props.filterElement}
      />
    );

    let editPurposeModal;
    if (this.state.editPurpose) {
      editPurposeModal = (
        <EditPurposeModal
          vendorList={this.props.vendorList}
          iabPurposes={this.props.iabPurposes}
          iabStacks={this.props.iabStacks}
          purpose={this.state.editPurpose}
          purposeIndex={this.state.editPurposeIndex}
          deletePurpose={this.deletePurpose}
          deleteStack={this.deleteStack}
          closeModal={() => this.editPurpose(null, -1, null, -1)}
          handleApply={this.savePurposeModal}
          allListVendors={this.props.vendorList.vendors}
          globalVendors={this.props.globalVendors}
          readOnly={this.props.readOnly}
          visible
          stackPurposeIsIn={this.state.stackEditingPurposeIsIn}
          stackIndex={this.state.editingPurposeStackIndex}
          validateHandler={this.validateHandler}
        />
      );
    }

    let editVendorModal;
    if (this.state.editVendor) {
      let vendorsWrappers = this.props.vendorList.vendorsWrappers;
      if (!vendorsWrappers.find(vendor => vendor.vendorId === this.state.editVendor.id)) {
        vendorsWrappers = List([new VendorWrapper({ vendorId: this.state.editVendor.id })]);
      }
      editVendorModal = (
        <VendorDetailModal
          vendorWrapper={vendorsWrappers.find(vendor => vendor.vendorId === this.state.editVendor.id)}
          vendor={this.state.editVendor}
          deleteVendor={this.deleteVendor}
          closeModal={() => this.editVendor(null, null)}
          handleApply={this.handleApplyVendorDetailModal}
          readOnly={this.props.readOnly}
          visible
          globalVendors={this.props.globalVendors}
          categories={this.props.vendorList.categories}
          defaultLegalBasis={this.props.vendorList.defaultLegalBasis}
          isAppleMessagingEnabled={this.props.isAppleMessagingEnabled}
          isNonIab={this.props.isNonIab}
        />
      );
    }

    let createCustomPurposeModal;
    if (this.state.createCustomPurposeOpen) {
      createCustomPurposeModal = (
        <CreateCustomPurposeModal
          closeModal={() => this.setState({ createCustomPurposeOpen: false })}
          vendorList={this.props.vendorList}
          handleApply={this.saveVendorList}
          visible={this.state.createCustomPurposeOpen}
        />
      );
    }

    let createIabPurposeModal;
    if (this.state.createIabPurposeOpen) {
      createIabPurposeModal = (
        <CreateIabPurposeModal
          closeModal={() => this.setState({ createIabPurposeOpen: false })}
          vendorList={this.props.vendorList}
          handleApply={this.saveVendorList}
          iabPurposesNotInThelist={iabPurposesNotInThelist}
          visible={this.state.createIabPurposeOpen}
        />
      );
    }

    let iabSpecialFeaturesModal;
    if (this.state.iabSpecialFeaturesOpen) {
      iabSpecialFeaturesModal = (
        <IabSpecialFeaturesModal
          closeModal={() => this.setState({ iabSpecialFeaturesOpen: false })}
          iabSpecialFeatures={this.props.iabSpecialFeatures}
          vendorList={this.props.vendorList}
          handleApply={this.saveVendorList}
          visible={this.state.iabSpecialFeaturesOpen}
          iabStacks={this.props.iabStacks}
        />
      );
    }

    let manageStacksModal;
    if (this.state.manageStacksOpen) {

      manageStacksModal = (
        <ManageStacksModal
          iabPurposes={this.props.iabPurposes}
          iabStacks={this.props.iabStacks}
          iabSpecialFeatures={this.props.iabSpecialFeatures}
          closeModal={() => this.setState({ manageStacksOpen: false })}
          vendorList={this.props.vendorList}
          handleApply={this.saveVendorList}
          visible={this.state.manageStacksOpen}
          filterElement={this.props.filterElement}
          isNonIab={this.props.isNonIab}
          iabPurposesNotInThelist={this.getIabPurposesNotInVendorList()}
          validateHandler={this.validateHandler}
        />
      );
    }

    const headerCheckbox = (
      <Checkbox
        checked={this.state.selectedVendorIds.length}
        indeterminate={
          this.state.selectedVendorIds.length > 0 && this.state.selectedVendorIds.length < dataSource.size
        }
        onChange={() => this.toggleSelectAll(dataSource)}
      />
    );

    const rowSelection = {
      selectedRowKeys: this.state.selectedVendorIds,
      onSelect: this.handleSelect,
      columnTitle: headerCheckbox,
    };

    const tabs = [
      {
        label: 'Review Deleted IAB Vendors',
        value: 'iab-deleted',
        count: this.getIABUpdatesTabVendorCount('iab-deleted'),
        show: this.props.vendorList.deletedIabVendors.size > 0,
      },
      {
        label: 'Review IAB Legal Basis Updates',
        value: 'iab-updated',
        count: this.getIABUpdatesTabVendorCount('iab-updated'),
        show: this.props.vendorList.vendorsWithLegalBasisChanges.size > 0,
      },
      {
        label: 'View All Vendors',
        value: 'all',
        count: this.props.vendorList.vendors.size,
        show: this.props.vendorList.deletedIabVendors.size || this.props.vendorList.vendorsWithLegalBasisChanges.size,
      },
    ];

    const searchVendorsInput = (
      <Input
        type="search"
        placeholder="Search vendors"
        onChange={this.updateSearch}
        className="vendor-filter"
        suffix={<SearchOutlined />}
      />
    );

    const addVendorBtnLabel = this.props.activeTab == 'iab-deleted' ? 'Remove Vendors' : 'Manage Vendors & Cookies';
    const tableTitle = (
      <React.Fragment>
        <div className='flexbox'>
          <div className="inner-wrapper">
            {searchVendorsInput}
            { 
              !this.props.readOnly ? (
                <>
                <Divider type="vertical" />
                <Button
                  type="secondary"
                  size="small"
                  className="add-vendor-btn"
                  onClick={() => this.props.activeTab == 'iab-deleted' ? this.onChangeBulkAction('remove') : this.props.toggleAddVendors()}
                  disabled={this.props.readOnly}
                >
                  {addVendorBtnLabel}
                </Button>
                <Divider type="vertical" />
                <Select
                  style={{ width: 130 }}
                  dropdownClassName="bulk-action-dropdown"
                  placeholder="Bulk Actions"
                  value={this.state.bulkAction}
                  disabled={this.props.readOnly}
                  onChange={this.onChangeBulkAction}>
                  <Option value="edit">
                    <i className="fas fa-edit td-icon" />
                    Bulk Edit
                  </Option>
                  <Option value="make-custom">
                    <StarFilled />
                    Make Custom
                  </Option>
                </Select>
                </>
              ) : null
            }
          </div>
          <div className="custom-tabs">
            {
              tabs.map((t, i) => !!t.show && (
                <div
                  key={i}
                  className={classNames({ 'active': this.props.activeTab == t.value })}
                  onClick={() => this.props.updateActiveTab(t.value)}>
                  {t.value != 'all' && <span className={`count ${t.count == 0 ? '' : 'highlight'}`}>{t.count}</span>}
                  {t.label}
                  {t.value == 'all' && <span>&nbsp;({this.props.vendorList.vendors.size}) </span>}
                </div>
              ))
            }
          </div>
        </div>
        <div>
          {iabDeletedVendors && this.props.activeTab == 'iab-deleted' ? <Alert type="error" message={<>These vendors have been deleted from the IAB TCF's Global Vendor List. This means that they will no longer be signalled in the IAB TCF consent string, or present in the IAB TC's APIs and will have no mechanism for checking vendor-level consent. In order to save this vendor list you need to remove these vendors from the list. If you wish to keep the vendors that have been removed from the IAB GVL you can convert select vendors to custom by choosing “Make Custom” in the 'Bulk Actions' dropdown. Additionally, you can instantly remove these vendors from your list by selecting them and clicking on the “Remove Vendors” button. <b>Important note:</b>  If you decide to add these vendors as custom vendors please note that they will not receive signalling via the IAB TCF and therefore you will need to configure how they read vendor-level consent through alternative mechanisms.</>} showIcon/> : null}
          {iabUpdatedVendors && this.props.activeTab == 'iab-updated'? <Alert type="error" message={<>The IAB vendors listed below have a previously configured legal basis that is no longer supported in their latest registration on the IAB TCF's Global Vendor List. For each purpose that has been updated, we have updated the legal basis to a new value. This new value is based on either the "Default IAB Vendor Consent Type" defined in the purpose settings, or, if not set, the vendor list advanced settings. If the desired legal basis is no longer supported, the purpose will be set to "Not Allowed". Please review these changes and make any necessary updates before saving this Vendor List. <b>Important note:</b> Saving these changes may cause your users to be messaged to reconsent if you have set up a scenario in which either "Legal Basis Changes" or "Vendor List Additions" will trigger a reconsent within your scenario settings.</>} showIcon/>: null}
        </div>
      </React.Fragment>
    );

    const bulkEditModal = this.state.bulkEdit && (
      <BulkEdit
        getLegalBasis={this.getLegalBasis}
        filteredInfo={this.state.filteredInfo}
        isModalVisible={this.state.bulkEdit}
        selectedVendors={this.state.selectedVendors}
        handleCancel={this.cancelBulkEdit}
        handleOk={this.updateInBulk}
        vendorList={this.props.vendorList}
        columns={columns.toJS()}
        atpEnabled={this.props.atpEnabled}
        isAppleMessagingEnabled={this.props.isAppleMessagingEnabled}
        iabPurposes={this.props.iabPurposes}
      />
    );

    let headingMessage = 'Are you sure you want to remove vendors from vendor list?';
    let errorNote = `Clicking on "Yes" will remove ${this.state.selectedVendors.length} Vendor(s) from vendor list`;
    let handleOkMethod = this.deleteVendors;
    let handleCancelMethod = this.handleCancelRemoveBulk;
    let okText = "Yes";
    let cancelText = "No";
    if (this.state.confirmAction == 'make_custom') {
      okText = 'Make Custom'
      cancelText = 'Cancel'
      headingMessage = "Are you sure you'd like to change these vendors to custom?"
      errorNote = `Clicking on "Make Custom" will change ${this.state.selectedVendors.length} Vendor(s) into Custom Vendors`;
      handleOkMethod = () => {
        this.replaceIabDeletedVendorsFromVl()
        this.setState({ dismissModalVisible: false, confirmAction: '', })
      }
    }
    const errorContent = (
      <React.Fragment>
        <div className="error-wrapper">
          <WarningOutlined />
          <div className="message-block">
            {headingMessage}
            <div className="note">{errorNote}</div>
          </div>
        </div>
      </React.Fragment>
    );
    const bulkRemoveModal = this.state.dismissModalVisible && (
      <DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title={null}
        error={errorContent}
        isModalVisible={this.state.dismissModalVisible}
        handleCancel={handleCancelMethod}
        renderOk
        okText={okText}
        cancelText={cancelText}
        handleOk={handleOkMethod}
        primaryAction="submit"
      />
    );

    let noDataText = 'No Vendors';
    if (this.props.activeTab == 'iab-updated' && !dataSource.size) {
      noDataText = 'There are no vendors under "Review IAB Updates" tab';
    } else if (this.props.activeTab == 'iab-deleted' && !dataSource.size) {
      noDataText = 'There are no vendors under "Review Deleted Vendors" tab';
    }

    const wizardExtraProps = {
      title: () => tableTitle,
      rowSelection: { ...rowSelection },
    };

    return (
      <div className="vendor-edit-table" ref="vendorEditTable">
        <div className="symbol-msg">
          {isAnyIAB && <span><i className="fas fa-circle" />IAB Vendors</span>}
          {isAnyAppleDataBroker && this.props.isAppleMessagingEnabled && <span><i className="fas fa-circle is-apple-broker" />Apple Data Broker</span>}
          {isAnyRequireConsent && (
            <>
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                *
              </span>
              <span>
                Is Required for Consent
              </span>
            </>
          )}
        </div>

        <AntTable
          {...wizardExtraProps}
          bordered={false}
          dataSource={dataSource.toJS()}
          columns={columns.toJS()}
          footer={() => footer}
          scroll={{ x: 1160 }}
          rowClassName={this.rowClassName()}
          pagination={{
            position: ['bottomCenter'],
            showTotal: (total) => `Total Vendors: ${total}`,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '75', '100'],
            locale: { items_per_page: ' Records per page' },
          }}
          locale={{ emptyText: noDataText }}
          rowKey={record => record.key}
        />
        {editPurposeModal}
        {editVendorModal}
        {createCustomPurposeModal}
        {createIabPurposeModal}
        {iabSpecialFeaturesModal}
        {manageStacksModal}
        {bulkEditModal}
        {bulkRemoveModal}
      </div>
    );
  }
}