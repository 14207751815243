import React from 'react';
import { browserHistory } from 'react-router';

import { Modal, Divider } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import { getParameterByName } from '../../utils.js';
import CustomIcon from '../../common/CustomIcon.js';

function CampaignLaunchErrorModal(props){
  
  const { propertiesUnderPg, visible, metaData: { isAnotherCampaignExist, propertiesWithErrors, launchCampaign, hasActiveAdblockCampaign, hasActiveGdprCampaign, isReplaceCondition } } = props;

  const redirectToVls = () => {
    browserHistory.push(`/consent_v2/vendor_lists`);
  }

  const redirectToVl = (vlId) => {
    browserHistory.push(`/consent_v2/vendor_lists/edit?id=${vlId}&non_iab=false`);
  }

  const siteGroupId = parseInt(getParameterByName('site_group_id', window.location));

  let gdprErrors;
  let adblockErrors;
  let divider;
  let errorNo = 1;
  let conditionalError;
  const redirectIcon = <CustomIcon type={CustomIcon.types.PREVIEW}/>;
  const campaign = launchCampaign === 1 ? 'a GDPR' : 'an Ad-block';

  if(siteGroupId) {
    const inactiveVlProperties = propertiesWithErrors.filter(m => !m.hasActiveVendorList);
    const propertiesNames = inactiveVlProperties.map(m => propertiesUnderPg.find(s => s.id === m.siteId).domain).join(', ');

    const vls = propertiesWithErrors.filter(m => !m.hasSourcepoint && m.vendorListId).map(vl => { return { vendorListId: vl.vendorListId, name: vl.name }});
    const vlsWithoutSPVendor = vls.filter((vl, index) => index === vls.findIndex(obj => JSON.stringify(obj) === JSON.stringify(vl)));

    const isSourcepointMissing = (hasActiveAdblockCampaign || hasActiveGdprCampaign) && vlsWithoutSPVendor.length > 0 && isAnotherCampaignExist;

    if(inactiveVlProperties.length > 0 && launchCampaign === 1) {
      gdprErrors = <div>{ isSourcepointMissing && <b>Error 1:</b> } You are trying to launch { campaign } campaign but the following properties <b>“{ propertiesNames }”</b> do not have an active vendor list associated with them. You can create a vendor list or add your properties to an existing vendor list by clicking on the <span className="redirection-to-vl" onClick={redirectToVls}>GDPR top menu &gt; TCF v2 &gt; Vendor List {redirectIcon}</span></div>
    }

    if(isSourcepointMissing) {
      if(gdprErrors) {
          divider = <Divider />;
          errorNo = 2;
       }
       adblockErrors = <div>
        { divider } 
        { gdprErrors && <b>Error { errorNo }:</b> } You are trying to run both a GDPR and an Anti Adblock campaign but the active vendor list(s) associated with one or more properties in your property group do not have <b>Sourcepoint</b> listed as a vendor.  Before launching the campaign, you need to add <b>Sourcepoint</b> to the below listed vendor lists, which you can get to directly by clicking the below links. Alternatively you can navigate to your vendor list by clicking on the <span className="redirection-to-vl" onClick={redirectToVls}>GDPR top menu &gt; TCF v2 &gt; Vendor List {redirectIcon}</span>
        <br/><br/>
        Vendor lists without Sourcepoint:
        { vlsWithoutSPVendor.map((vl, i) => <div className="vl-name" key={i}>{i+1}. <a onClick={() => redirectToVl(vl.vendorListId)}>{ vl.name || vl.vendorListId }</a></div>) }
       </div>;
    }

  } else {
    const property = propertiesWithErrors[0];
    const hasActiveVendorList = property.hasActiveVendorList;
    const hasSourcepoint = property.hasSourcepoint;
    const vlName = property.name;
    const vlId = property.vendorListId;

    const isSourcepointMissing = (hasActiveAdblockCampaign || hasActiveGdprCampaign) && !hasSourcepoint && !isReplaceCondition;
    conditionalError = <React.Fragment>can do so by navigating directly to your vendor list by clicking here : <span className="redirection-to-vl" onClick={() => redirectToVl(vlId)}>{vlName || vlId}{redirectIcon}</span>. Alternatively you</React.Fragment>;

    if(!hasActiveVendorList) {
       gdprErrors = <div>{isSourcepointMissing && <b>Error 1:</b>} You are trying to launch {campaign} campaign but your property does not have an active vendor list associated with it. You can create a vendor list or add your property to an existing vendor list by clicking on the <span className="redirection-to-vl" onClick={redirectToVls}>GDPR top menu &gt; TCF v2 &gt; Vendor List {redirectIcon}</span></div>;
    }
    if(isSourcepointMissing) {
       if(gdprErrors) {
          divider = <Divider />;
          errorNo = 2;
          conditionalError = '';
       }
       adblockErrors = <div>
        { divider } 
        { gdprErrors && <b>Error { errorNo }:</b> } You are trying to run both a GDPR and an Anti Adblock campaign. To do so, you need to add <b>Sourcepoint</b> to your vendor list. You {conditionalError} can navigate to your vendor list by clicking on the <span className="redirection-to-vl" onClick={redirectToVls}>GDPR top menu &gt; TCF v2 &gt; Vendor List {redirectIcon}</span>
       </div>;
    }
  }

  return (
      <Modal 
        className="campaign-launch-error-modal" 
        title={<React.Fragment><WarningOutlined /> Launch Campaign (Prerequisite)</React.Fragment>}
        visible={visible} 
        footer={null}
        onCancel={() => props.toggleVisibility()}>
          { gdprErrors }
          { adblockErrors }
      </Modal>
  );
};

export default CampaignLaunchErrorModal;