import { Collapse, Empty, Image, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import arrowDown from '../../../../../assets/icons/arrow_down.svg';
import Overview from '../Overview';
import DetailedBreakdown from './DetailedBreakdown';
import getSectionByHash, { defaultDetailedPageSectionIndices } from '../getSectionByHash';
import isSameSummaryData from '../isSameSummaryData';

const description = {
  all: 'The graph below shows how your selected properties compare to the selected benchmark properties in relation to how many cookies we have observed in scans on your properties. For more details please see the “Detailed Breakdown” section below.',
  average:
    "The average count shows the number of times on average per scan that we have observed the compliance metric you're viewing on your selected properties (average count my properties) and on your benchmark properties (average count benchmark)",
  relative:
    'The relative score is a percentile rank that looks at the specified compliance metric and compares the average performance across your properties with the average performance of your benchmark properties. The higher the relative score the better your privacy and data ethics.',
};

const AllCookiesFound = ({ summaryDiagnose, summaryPending }) => {
  return (
    <div>
      <Spin spinning={summaryPending} size="large">
        {isSameSummaryData(summaryDiagnose, 'All Cookies Found') ? (
          <Collapse
            expandIcon={(props) => <Image className={props.isActive ? 'active' : ''} src={arrowDown} preview={false} />}
            accordion
            defaultActiveKey={getSectionByHash(location?.hash, defaultDetailedPageSectionIndices)}
          >
            <Collapse.Panel header={<div className="headers secondary_header">Overview</div>}>
              <Overview description={description} summaryData={summaryDiagnose} />
            </Collapse.Panel>
            <Collapse.Panel header={<div className="headers secondary_header">Detailed Breakdown</div>}>
              <DetailedBreakdown />
            </Collapse.Panel>
          </Collapse>
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    summaryDiagnose: store.diagnoseDashboardState.getIn(['summaryDiagnose', 'value']),
    summaryPending: store.diagnoseDashboardState.get('summaryPending'),
  };
};

export default connect(mapStateToProps, null)(AllCookiesFound);
