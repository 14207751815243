import PropTypes from 'prop-types';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';

export default class AutoAddNewVendorsCheckbox extends React.Component {

  static propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  }

  render() {
    const tooltipText = 'We’ll find new vendors that get added to your site and add them to your vendor list.'

    return (
      <Tooltip title={ tooltipText } placement="bottom">
        <Checkbox
          className='auto-scan'
          checked={ this.props.checked }
          onChange={ this.props.onChange }
          disabled={ this.props.readOnly }
        >
            <span>Auto add new vendors</span>
            <InfoCircleOutlined />
        </Checkbox>
      </Tooltip>
    );
  }
}