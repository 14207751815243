import PropTypes from 'prop-types';
import React from 'react';

import BooleanStep from '../BooleanStep';
import ContinueSatisfy from '../ContinueSatisfy';
import CommonStringInput from '../CommonStringInput';

import { StringValueKVRecord } from '../../../../../../scenario_constants.js';

export default class CustomKeyValue extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(StringValueKVRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  onChange = (event, propType) => {
    const value = event.target.value;
    this.props.onChange(this.props.data.set(propType, value));
  }

  onMatchContainChange = (continueOnSatisfy) => {
    const matchOrContain = continueOnSatisfy ? 'match' : 'contain';
    const data = this.props.data.set('type', matchOrContain);
    this.props.onChange(data);
  };

  render() {
    const matchOrContain = this.props.data.type === 'match' ? true : false;
    const labelText = (
      'Key ' + this.props.data.k +
      ' Value: ' + this.props.data.v +
      ' Type: ' + this.props.data.type
    )
    return (
      <BooleanStep
        { ...this.props }
        labelText={ labelText }
        propertyName={ 'allow_on_satisfy' }
      >
        { this.props.children }
        <CommonStringInput
          label={ 'Key' }
          value={ this.props.data.k }
          onChange={ (event) => this.onChange(event, 'k') }
        />
        <CommonStringInput
          label={ 'Value' }
          value={ this.props.data.v }
          onChange={ (event) => this.onChange(event, 'v') }
        />
        <ContinueSatisfy
          value={ matchOrContain }
          onChange={ this.onMatchContainChange }
          affirmativeText={ 'match' }
          negativeText={ 'contain' }
        />
      </BooleanStep>
    );
  }
}
