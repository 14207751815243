import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import CustomButton from '../../common/CustomButton.js.jsx';
import WarningMessage from '../../common/WarningMessage.js.jsx';
import { stopLiveCampaign, stopSiteGroupLiveCampaign } from '../../../actions/campaign_actions';
import { Campaign } from '../../../records/campaign_records';
import { getParameterByName } from '../../utils';

export class StopCurrentCampaign extends React.Component {
  static propTypes = {
    campaign: PropTypes.instanceOf(Campaign).isRequired,
    readOnly: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    stopLiveCampaign: PropTypes.func.isRequired,
    stopSiteGroupLiveCampaign: PropTypes.func.isRequired,
  }

  static defaultProps = {
    campaignId: null,
    campaignName: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      warningMessagePresent: false,
    }
    this.toggleWarningMessage = this.toggleWarningMessage.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  toggleWarningMessage() {
    this.setState({ warningMessagePresent: !this.state.warningMessagePresent })
  }

  handleClick() {
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      this.props.stopSiteGroupLiveCampaign({
        siteGroupId,
        accountId: this.props.accountId,
        campaign: this.props.campaign,
      });
    } else {
      this.props.stopLiveCampaign(this.props.campaign);
    }
  }

  render() {
    let warningMessage;
    if (this.state.warningMessagePresent) {
      warningMessage = (
        <WarningMessage
          handleDelete={this.handleClick}
          name={this.props.campaign.description}
          handleCancel={this.toggleWarningMessage}
          isCampaign
        />);
    }

    return (
      <div className="stop-campaign">
        {warningMessage}
        <CustomButton
          className="stop-button"
          disabled={this.props.readOnly || this.props.pending}
          type={CustomButton.types.PLAIN}
          onClick={this.toggleWarningMessage}
        >
          END CAMPAIGN
            </CustomButton>
      </div>
    )
  }
}

const mapStateToProps = function (store) {
  return {
    pending: store.campaignState.getIn(['campaigns', 'pending']),
  };
};

export default connect(
  mapStateToProps, {
    stopLiveCampaign,
    stopSiteGroupLiveCampaign,
  }
)(StopCurrentCampaign);
