import React, { Component } from 'react';
import { Slider } from '../../../../../../../styleguide';

const MAX_MARGIN = 50;


class Margin extends Component {
  updateMargin = (type, value) => {
    const margin = JSON.parse(JSON.stringify(this.props.value));
    margin[type] = value;
    this.props.updateSetting(this.props.settingKey, margin);
  }
  render() {

    const { value, settingsTitle } = this.props;

    return (
      <div>
        <div className="four-options">
          <h4>{settingsTitle}</h4>
          
          <div className="padding-flex">
            <div className="padding-label">Top</div>
            <Slider min={0} max={MAX_MARGIN} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateMargin('marginTop', val)}
              value={typeof value.marginTop === 'number' ? value.marginTop : 0}
            />
          </div>
          <div className="padding-flex">
            <div className="padding-label">Left</div>
            <Slider min={0} max={MAX_MARGIN} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateMargin('marginLeft', val)}
              value={typeof value.marginLeft === 'number' ? value.marginLeft : 0}
            />
          </div>
          <div className="padding-flex">
            <div className="padding-label">Bottom</div>
            <Slider min={0} max={MAX_MARGIN} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateMargin('marginBottom', val)}
              value={typeof value.marginBottom === 'number' ? value.marginBottom : 0}
            />
          </div>
          <div className="padding-flex">
            <div className="padding-label">Right</div>
            <Slider min={0} max={MAX_MARGIN} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateMargin('marginRight', val)}
              value={typeof value.marginRight === 'number' ? value.marginRight : 0}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Margin;
