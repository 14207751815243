import 'whatwg-fetch';
import { fromJS, List, Map } from 'immutable';
import moment from 'moment';
import {
  checkStatus,
  divideByZeroErrChk,
  CSRF_TOKEN,
  reportingRmReqBody
} from '../helper.js';
import {
  VendorsConsentReportRecord,
  PropertiesReportV2Record,
  MessageTableReportV2Record,
} from '../../records/gdpr_performance_records_v2.js';
import { DATEPICKERS } from '../../constants';

export async function getAndParseGdprV2ReportMessagedData(data, rangeSelect) {
  debugger
  const messagesPromises = data.map((d, i) => {
    const siteIds = d.get('siteId').toString();
    let formattedData = d
      .set('startDate', rangeSelect.get('startDate').format('YYYY-MM-DD'))
      .set('endDate', rangeSelect.get('endDate').format('YYYY-MM-DD'))
      .toMap()
      .filter((v) => (List.isList(v) ? v.size : v))
      .toJS();
    formattedData = reportingRmReqBody(formattedData);
    return fetch('/api/v2/consent_reports/consent_report_messages_daily', { method: 'post', credentials: 'include', headers: { 'X-CSRF-Token': CSRF_TOKEN }, body: JSON.stringify(formattedData) })
      .then(checkStatus)
      .then((resp) => resp.json())
      .then((resp) =>
        aggregateMessageData(
          fromJS(resp),
          rangeSelect,
          formattedData,
          i,
          siteIds
        )
      );
  });

  return Promise.all(messagesPromises).then((res) => List(res));
}

export async function getAndParseGdprV2ReportSpecialFeaturesData(
  vendorData,
  rangeData
) {
  let formattedData = vendorData
    .set('startDate', rangeData.get('startDate').format('YYYY-MM-DD'))
    .set('endDate', rangeData.get('endDate').format('YYYY-MM-DD'))
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();
  delete formattedData.siteId; 
  formattedData = reportingRmReqBody(formattedData, true);
  const siteId = vendorData.get('siteId');

  return fetch('/api/v2/consent_reports/consent_report_special_features?siteId='+siteId, { method: 'post', credentials: 'include', headers: { 'X-CSRF-Token': CSRF_TOKEN }, body: JSON.stringify(formattedData) })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) =>
      List(aggregateVendorData(fromJS(resp))).map((s, i) =>
        VendorReportToRecord(s)
      )
    );
}

export async function getAndParseGdprV2ReportVendorData(vendorData, rangeData) {
  let formattedData = vendorData
    .set('startDate', rangeData.get('startDate').format('YYYY-MM-DD'))
    .set('endDate', rangeData.get('endDate').format('YYYY-MM-DD'))
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();
  delete formattedData.siteId; 
  formattedData = reportingRmReqBody(formattedData, true);
  const siteId = vendorData.get('siteId');

  return fetch('/api/v2/consent_reports/consent_report_vendors?siteId='+siteId, { method: 'post', credentials: 'include', headers: { 'X-CSRF-Token': CSRF_TOKEN }, body: JSON.stringify(formattedData) })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) =>
      List(aggregateVendorData(fromJS(resp))).map((s, i) =>
        VendorReportToRecord(s)
      )
    );
}

export async function getAndParseGdprV2ReportPurposeData(
  vendorData,
  rangeData
) {
  let formattedData = vendorData
    .set('startDate', rangeData.get('startDate').format('YYYY-MM-DD'))
    .set('endDate', rangeData.get('endDate').format('YYYY-MM-DD'))
    .toMap()
    .filter((v) => (List.isList(v) ? v.size : v))
    .toJS();
  delete formattedData.siteId; 
  formattedData = reportingRmReqBody(formattedData, true);
  const siteId = vendorData.get('siteId');

  return fetch('/api/v2/consent_reports/consent_report_purposes?siteId='+siteId, { method: 'post', credentials: 'include', headers: { 'X-CSRF-Token': CSRF_TOKEN }, body: JSON.stringify(formattedData) })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) =>
      List(aggregatePurposesData(fromJS(resp))).map((s, i) =>
        VendorReportToRecord(s)
      )
    );
}

export async function getAndParseGdprV2ReportPropertyData(data, rangeSelect) {
  const propertyPromises = data.map((d, i) => {
    let formattedData = d
      .set('startDate', rangeSelect.get('startDate').format('YYYY-MM-DD'))
      .set('endDate', rangeSelect.get('endDate').format('YYYY-MM-DD'))
      .toMap()
      .filter((v) => (List.isList(v) ? v.size : v))
      .toJS();
    formattedData = reportingRmReqBody(formattedData);
    return fetch('/api/v2/consent_reports/consent_report_properties_daily', { method: 'post', credentials: 'include', headers: { 'X-CSRF-Token': CSRF_TOKEN }, body: JSON.stringify(formattedData) })
      .then(checkStatus)
      .then((resp) => resp.json())
      .then((resp) => aggregatePropertyData(fromJS(resp), i, rangeSelect));
  });

  return Promise.all(propertyPromises).then((res) => List(res));
}

function aggregatePropertyData(data, index, rangeSelect) {
  let groupedByYmdOrderedMap;
  let exportData = List();
  let propertyTableData = List();
  let aggPropertyGraphData = List();
  let aggTotalVolume = 0;
  let aggMessagedUsers = 0;
  let aggFullConsent = 0;
  let aggPartialConsent = 0;
  let aggRejectAll = 0;
  let aggLegitimateIntObj = 0;
  let aggPropertyTableData;
  let graphKey = 0;
  const datePicker = rangeSelect.get('datePicker');

  const quartData = data
    .get('results')
    .map((dataPoint) =>
      dataPoint.set(
        'week',
        moment(dataPoint.get('ymd'), 'YYYY-MM-DD').isoWeek()
      )
    )
    .groupBy((dataPoint) => dataPoint.get('week'));

  groupedByYmdOrderedMap = data
    .get('results')
    .groupBy((dataPoint) => dataPoint.get('ymd'));
  const groupByOrderedMap =
    datePicker === DATEPICKERS.quarter ? quartData : groupedByYmdOrderedMap;
  groupByOrderedMap.map((p, i) => {
    const date = datePicker === DATEPICKERS.quarter ? p.get(0).get('ymd') : i;
    let expData = p.map((e) => {
      return Map({
        date:
          datePicker === DATEPICKERS.quarter
            ? 'Week ' + moment(p.get(0).get('ymd'), 'YYYY-MM-DD').isoWeek()
            : i,
        siteid: Number(e.get('site_id')),
        totalpageviews: Number(e.get('total_volume')),
        messagedusers: Number(e.get('total_messaged_users')),
        fullconsent: Number(e.get('property_full_consent')),
        fullconsentper:
          divideByZeroErrChk(
            Number(e.get('property_full_consent')),
            Number(e.get('total_volume'))
          ) + '%',
        partialconsent: Number(e.get('property_partial_consent')),
        partialconsentper:
          divideByZeroErrChk(
            Number(e.get('property_partial_consent')),
            Number(e.get('total_volume'))
          ) + '%',
        rejectedall: Number(e.get('property_rejected_all')),
        rejectedallper:
          divideByZeroErrChk(
            Number(e.get('property_rejected_all')),
            Number(e.get('total_volume'))
          ) + '%',
        legitimateintobj: Number(e.get('property_leg_int_opted_out')),
        legitimateintobjper:
          divideByZeroErrChk(
            Number(e.get('property_leg_int_opted_out')),
            Number(e.get('total_volume'))
          ) + '%',
      });
    });
    exportData = exportData.concat(expData);
    let stv = mapReduceSum(p, 'total_volume');
    let stmu = mapReduceSum(p, 'total_messaged_users');
    let spfc = mapReduceSum(p, 'property_full_consent');
    let sppc = mapReduceSum(p, 'property_partial_consent');
    let spra = mapReduceSum(p, 'property_rejected_all');
    let slio = mapReduceSum(p, 'property_leg_int_opted_out');
    propertyTableData = propertyTableData.push(
      Map({
        date: date,
        fancyDate:
          datePicker === DATEPICKERS.quarter
            ? 'Week ' + moment(date, 'YYYY-MM-DD').isoWeek()
            : date,
        totalpageviews: stv,
        messagedusers: stmu,
        fullconsent: spfc,
        fullconsentper: divideByZeroErrChk(spfc, stv) + '%',
        partialconsent: sppc,
        partialconsentper: divideByZeroErrChk(sppc, stv) + '%',
        rejectedall: spra,
        rejectedallper: divideByZeroErrChk(spra, stv) + '%',
        legitimateintobj: slio,
        legitimateintobjper: divideByZeroErrChk(slio, stv) + '%',
      })
    );

    aggTotalVolume += stv;
    aggMessagedUsers += stmu;
    aggFullConsent += spfc;
    aggPartialConsent += sppc;
    aggRejectAll += spra;
    aggLegitimateIntObj += slio;

    graphKey++;
    aggPropertyGraphData = aggPropertyGraphData.push(
      Map({
        key: graphKey,
        fancyDate:
          datePicker == DATEPICKERS.quarter
            ? 'Week ' + i
            : moment(i, 'YYYY-MM-DD').format('MMM Do'),
        date: date,
        ['totalpageviews' + index]: stv,
        ['messagedusers' + index]: divideByZeroErrChk(stmu, stv),
        ['fullconsent' + index]: divideByZeroErrChk(spfc, stv),
        ['partialconsent' + index]: divideByZeroErrChk(sppc, stv),
        ['rejectedall' + index]: divideByZeroErrChk(spra, stv),
        ['legitimateintobj' + index]: divideByZeroErrChk(slio, stv),
        ['siteIds' + index]: p
          .map((entry) => [entry.get('site_id')])
          .reduce((prev, current) => prev.concat(current)),
      })
    );
  });

  aggPropertyTableData = Map({
    key: index,
    date: 'Criteria ' + Number(index + 1),
    totalpageviews: aggTotalVolume,
    messagedusers: aggMessagedUsers,
    fullconsent: aggFullConsent,
    partialconsent: aggPartialConsent,
    rejectedall: aggRejectAll,
    legitimateintobj: aggLegitimateIntObj,
    dataArr: propertyTableData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyReportToRecord(s, i)),
    exportdata: exportData
      .sort(
        (a, b) =>
          moment(b.get('date'), 'YYYY-MM-DD').valueOf() -
          moment(a.get('date'), 'YYYY-MM-DD').valueOf()
      )
      .map((s, i) => propertyReportToRecord(s, i)),
  });
  return Map({
    propertyTableData: propertyReportToRecord(aggPropertyTableData, index),
    propertyGraph: aggPropertyGraphData,
  });
}

function aggregatePurposesData(data) {
  let pData = List();
  let groupedByPurposesOrderedMap;
  let key = 0;
  let rows = data.get('purposeResults');
  groupedByPurposesOrderedMap = rows.groupBy((dataPoint) =>
    dataPoint.get('name')
  );
  groupedByPurposesOrderedMap.map((p, i) => {
    pData = pData.push(
      Map({
        key: key,
        name: i,
        consentcount: mapReduceSum(p, 'consent_count'),
        unconsentcount: mapReduceSum(p, 'pv_count') - mapReduceSum(p, 'consent_count'),
        pvcount: mapReduceSum(p, 'pv_count'),
        consentrate: mapReducePercentage(p, 'consent_rate'),
        rejectionrate: mapReducePercentageRate(p, 'consent_rate'),
        legintobj: mapReduceSumToFixed(p, 'leg_int_objection_rate'),
      })
    );
    key++;
  });
  return pData.toJS();
}

function mapReduceSum(arr, key) {
  return (
    arr
      .map((entry) => Number(entry.get(key)) || 0)
      .reduce((prev, current) => prev + current) || 0
  );
}
function mapReduceSumToFixed(arr, key) {
  return mapReduceSum(arr, key).toFixed(2);
}

function aggregateVendorData(data) {
  let vData = List();
  let key = 0;
  let groupedByVendorNamesOrderedMap;
  const results = data.get('vendorResults') || data.get('sfResults');
  groupedByVendorNamesOrderedMap = results.groupBy((dataPoint) => dataPoint.get('name'));
  groupedByVendorNamesOrderedMap.map((v, i) => {
    vData = vData.push(
      Map({
        key: key,
        name: i,
        consentcount: mapReduceSum(v, 'consent_count'),
        unconsentcount: mapReduceSum(v, 'pv_count') - mapReduceSum(v, 'consent_count'),
        pvcount: mapReduceSum(v, 'pv_count'),
        consentrate: mapReducePercentage(v, 'consent_rate'),
        rejectionrate: mapReducePercentageRate(v, 'consent_rate'),
      })
    );
    key++;
  });
  return vData.toJS();
}

function mapReducePercentageRate(arr, key) {
  return Number((100 - mapReducePercentage(arr, key)).toFixed(2));
}
function mapReducePercentage(arr, key) {
  return Number(
    (
      arr
        .map((entry) => Number(entry.get(key)))
        .reduce((prev, current) => prev + current) / arr.size
    ).toFixed(2)
  );
}
function propertyReportToRecord(data, index) {
  return PropertiesReportV2Record({
    key: index,
    date: data.get('date'),
    fancyDate: data.get('fancyDate'),
    siteid: data.get('siteid'),
    totalpageviews: data.get('totalpageviews'),
    messagedusers: data.get('messagedusers'),
    fullconsent: data.get('fullconsent'),
    fullconsentper: data.get('fullconsentper'),
    partialconsent: data.get('partialconsent'),
    partialconsentper: data.get('partialconsentper'),
    rejectedall: data.get('rejectedall'),
    rejectedallper: data.get('rejectedallper'),
    legitimateintobj: data.get('legitimateintobj'),
    legitimateintobjper: data.get('legitimateintobjper'),
    aggData: data.get('dataArr') ? data.get('dataArr') : List(),
    exportdata: data.get('exportdata') ? data.get('exportdata') : List(),
  });
}

function VendorReportToRecord(data) {
  return VendorsConsentReportRecord({
    key: data.key,
    name: data.name,
    consentcount: data.consentcount,
    unconsentcount: data.unconsentcount,
    pvcount: data.pvcount,
    consentrate: data.consentrate,
    rejectionrate: data.rejectionrate,
    legintobj: data.legintobj,
  });
}
export function isIncompleteDateRange(datepicker, date) {
  switch (datepicker) {
    case 'WEEK':
      return moment().isoWeek() === moment(date, 'YYYY-MM-DD').isoWeek();
    case 'MONTH':
      return moment().month() === moment(date, 'YYYY-MM-DD').month();
  }
}
export function eliminatePMObj(data) {
  let results = List();
  data
    .get('results')
    .filter((a) => a.get('message_id') != undefined)
    .groupBy(
      (dataPoint) =>
        dataPoint.get('message_id') + '-' + dataPoint.get('partition_name')
    )
    .map((agg) => {
      const aggDaywise = agg
        .groupBy((dataPoint) => dataPoint.get('ymd'))
        .map((rpm) =>
          Map({
            ymd: '',
            site_id: rpm.get(0).get('site_id'),
            message_id: rpm.get(0).get('message_id'),
            total_messaged_users: rpm.get(0).get('total_messaged_users'),
            partition_name: rpm.get(0).get('partition_name'),
            consent_all: mapReduceSum(rpm, 'consent_all'),
            consent_some: mapReduceSum(rpm, 'consent_some'),
            reject_all: mapReduceSum(rpm, 'reject_all'),
            no_consent: mapReduceSum(rpm, 'no_consent'),
            leg_int_opted_out: mapReduceSum(rpm, 'leg_int_opted_out'),
          })
        )
        .valueSeq();
      results = results.push(
        Map({
          ymd: '',
          site_id: aggDaywise.get(0).get('site_id'),
          message_id: aggDaywise.get(0).get('message_id'),
          total_messaged_users: mapReduceSum(
            aggDaywise,
            'total_messaged_users'
          ),
          partition_name: aggDaywise.get(0).get('partition_name'),
          consent_all: mapReduceSum(aggDaywise, 'consent_all'),
          consent_some: mapReduceSum(aggDaywise, 'consent_some'),
          reject_all: mapReduceSum(aggDaywise, 'reject_all'),
          no_consent: mapReduceSum(aggDaywise, 'no_consent'),
          leg_int_opted_out: mapReduceSum(aggDaywise, 'leg_int_opted_out'),
        })
      );
    });
  return data.set('results', results);
}
async function aggregateMessageData(
  data,
  rangeSelect,
  formattedData,
  index,
  siteIds
) {
  let groupedByDatesOrderedMap;
  let auxData;
  let aggMessageTableData = List();
  let datePicker = rangeSelect.get('datePicker');
  let aggMsgTableData;
  let aggMsgGraphData = List();
  let graphKey = 0;
  let messagePerformanceList = List();
  let key = 0;

  let aggTotalMsgs = 0;
  let aggConsentAll = 0;
  let aggConsentSome = 0;
  let aggNoConsent = 0;
  let aggRejectAll = 0;
  let aggLegitimateIntObj = 0;

  let auxUrl = datePicker === DATEPICKERS.week ? '/api/v2/consent_reports/consent_report_messages_weekly' : '/api/v2/consent_reports/consent_report_messages_monthly';

  const quartData = data
    .get('results')
    .filter((a) => a.get('message_id') != undefined)
    .map((dataPoint) =>
      dataPoint.set(
        'week',
        moment(dataPoint.get('ymd'), 'YYYY-MM-DD').isoWeek()
      )
    )
    .groupBy((dataPoint) => dataPoint.get('week'));

  groupedByDatesOrderedMap = data
    .get('results')
    .filter((a) => a.get('message_id') != undefined)
    .groupBy((dataPoint) => dataPoint.get('ymd'));

  const groupByOrderedMap =
    datePicker === DATEPICKERS.quarter ? quartData : groupedByDatesOrderedMap;

  groupByOrderedMap.map((item, i) => {
    const date =
      datePicker === DATEPICKERS.quarter ? item.get(0).get('ymd') : i;
    let messageObj = Map({
      fancyDate: moment(i, 'YYYY-MM-DD').format('MMM Do'),
      date: i,
    });
    graphKey++;
    const groupedToEliminatePMObj = item.groupBy(
      (dataPoint) =>
        dataPoint.get('message_id') + '-' + dataPoint.get('partition_name')
    );
    let stmu = groupedToEliminatePMObj
      .map((entry) =>
        entry.get(0).get('message_id')
          ? Number(entry.get(0).get('total_messaged_users')) || 0
          : 0
      )
      .reduce((prev, current) => prev + current);
    let sca = item
      .map((entry) =>
        entry.get('message_id') ? Number(entry.get('consent_all')) || 0 : 0
      )
      .reduce((prev, current) => prev + current);
    let scs = item
      .map((entry) =>
        entry.get('message_id') ? Number(entry.get('consent_some')) || 0 : 0
      )
      .reduce((prev, current) => prev + current);
    let sra = item
      .map((entry) =>
        entry.get('message_id') ? Number(entry.get('reject_all')) || 0 : 0
      )
      .reduce((prev, current) => prev + current);
    //no consent = total messaged users -  consent_all - consent_some - reject_all
    let snc = stmu - sca - scs - sra;
    let slio = item
      .map((entry) =>
        entry.get('message_id')
          ? Number(entry.get('leg_int_opted_out')) || 0
          : 0
      )
      .reduce((prev, current) => prev + current);

    aggMsgGraphData = aggMsgGraphData.push(
      Map({
        key: graphKey,
        fancyDate:
          datePicker === DATEPICKERS.quarter
            ? 'Week ' + moment(date, 'YYYY-MM-DD').isoWeek()
            : moment(i, 'YYYY-MM-DD').format('MMM Do'),
        date: date,
        ['totalMsgs' + index]: stmu,
        ['consentAll' + index]: divideByZeroErrChk(sca, stmu),
        ['consentSome' + index]: divideByZeroErrChk(scs, stmu),
        ['noConsent' + index]: divideByZeroErrChk(snc, stmu),
        ['rejectAll' + index]: divideByZeroErrChk(sra, stmu),
        ['legitimateintobj' + index]: divideByZeroErrChk(slio, stmu),
        ['siteIds' + index]: [
          ...new Set(
            item
              .map((entry) =>
                entry.get('message_id') ? [entry.get('site_id')] : []
              )
              .reduce((prev, current) => prev.concat(current))
          ),
        ],
      })
    );

    groupedToEliminatePMObj.map((m) => {
      if (m.get(0).get('message_id')) {
        const sumConsentAll = m
          .map((v) =>
            v.get('message_id') ? Number(v.get('consent_all')) || 0 : 0
          )
          .reduce((prev, current) => prev + current);
        messageObj = messageObj.set(
          m
            .get(0)
            .get('message_id')
            .toString(),
          divideByZeroErrChk(
            sumConsentAll,
            Number(m.get(0).get('total_messaged_users'))
          ) || 0
        );
      }
    });
    messagePerformanceList = messagePerformanceList.push(messageObj);
  });

  const isIncompleteDate = isIncompleteDateRange(
    datePicker,
    formattedData.endDate
  );
  auxData =
    datePicker === DATEPICKERS.quarter || isIncompleteDate
      ? eliminatePMObj(data)
      : await fetch(auxUrl, { method: 'post', credentials: 'include', headers: { 'X-CSRF-Token': CSRF_TOKEN }, body: JSON.stringify(formattedData) })
          .then(checkStatus)
          .then((resp) => resp.json())
          .then((resp) => fromJS(resp));

  let aggWeeklyMonthlyData = auxData
    .get('results')
    .filter((a) => a.get('message_id') != undefined)
    .groupBy(
      (dataPoint) =>
        dataPoint.get('message_id') + '-' + dataPoint.get('partition_name')
    );
  aggWeeklyMonthlyData.map((a) => {
    let totalMsgs = Number(a.get(0).get('total_messaged_users')) || 0;
    let consentAll = mapReduceSum(a, 'consent_all');
    let consentSome = mapReduceSum(a, 'consent_some');
    let rejectAll = mapReduceSum(a, 'reject_all');
    //Formula: no consent = total messaged users -  consent_all - consent_some - reject_all
    let noConsent = totalMsgs - consentAll - consentSome - rejectAll;
    let legitimateIntObj = mapReduceSum(a, 'leg_int_opted_out');

    aggTotalMsgs += totalMsgs;
    aggConsentAll += consentAll;
    aggConsentSome += consentSome;
    aggNoConsent += noConsent;
    aggRejectAll += rejectAll;
    aggLegitimateIntObj += legitimateIntObj;

    let expandableTableData = List();
    let expandableTable = data
      .get('results')
      .filter(
        (m) =>
          m.get('message_id') != undefined &&
          m.get('message_id') == a.get(0).get('message_id') &&
          m.get('partition_name') == a.get(0).get('partition_name')
      )
      .groupBy(
        (dataPoint) =>
          dataPoint.get('ymd') +
          '-' +
          dataPoint.get('message_id') +
          '-' +
          dataPoint.get('partition_name')
      )
      .map((e, j) => {
        const totalMsgedUsers =
          Number(e.get(0).get('total_messaged_users')) || 0;
        const calConsentAll = mapReduceSum(e, 'consent_all');
        const calConsentSome = mapReduceSum(e, 'consent_some');
        const calRejectAll = mapReduceSum(e, 'reject_all');
        const calLegitimateInterestObjection = mapReduceSum(
          e,
          'leg_int_opted_out'
        );

        expandableTableData = expandableTableData.push(
          Map({
            date: moment(e.get(0).get('ymd'), 'YYYY-MM-DD').format(
              'MMM Do, YYYY'
            ),
            key: j,
            messageid: Number(e.get(0).get('message_id')),
            totalMsgs: totalMsgedUsers,
            consentAll: calConsentAll,
            consentSome: calConsentSome,
            noConsent:
              totalMsgedUsers - calConsentAll - calConsentSome - calRejectAll ||
              0, //no consent = total messaged users -  consent_all - consent_some - reject_all
            rejectAll: calRejectAll,
            legitimateIntObj: calLegitimateInterestObjection,
            partitionName: e.get(0).get('partition_name'),
          })
        );
      });
    expandableTableData = expandableTableData.sort(
      (a, b) =>
        moment(b.get('date'), 'MMM Do, YYYY').valueOf() -
        moment(a.get('date'), 'MMM Do, YYYY').valueOf()
    );
    aggMessageTableData = aggMessageTableData.push(
      Map({
        key: key,
        messageid: Number(a.get(0).get('message_id')),
        totalMsgs: totalMsgs,
        consentAll: consentAll,
        consentAllPer: divideByZeroErrChk(consentAll, totalMsgs) + '%',
        consentSome: consentSome,
        consentSomePer: divideByZeroErrChk(consentSome, totalMsgs) + '%',
        noConsent: noConsent,
        noConsentPer: divideByZeroErrChk(noConsent, totalMsgs) + '%',
        rejectAll: rejectAll,
        rejectAllPer: divideByZeroErrChk(rejectAll, totalMsgs) + '%',
        legitimateIntObj: legitimateIntObj,
        legitimateIntObjPer:
          divideByZeroErrChk(legitimateIntObj, totalMsgs) + '%',
        partitionName: a.get(0).get('partition_name'),
        siteId: a.get(0).get('site_id'),
        dataArr: expandableTableData.map((e) => MessageTableToRecord(e)),
      })
    );
    key++;
  });
  aggMsgTableData = Map({
    key: index,
    messageid: 'Criteria ' + Number(index + 1),
    totalMsgs: aggTotalMsgs,
    consentAll: aggConsentAll,
    consentSome: aggConsentSome,
    noConsent: aggNoConsent,
    rejectAll: aggRejectAll,
    legitimateIntObj: aggLegitimateIntObj,
    dataArr: aggMessageTableData.map((s) => MessageTableToRecord(s)),
  });
  return Map({
    messageData: MessageTableToRecord(aggMsgTableData),
    messageGraph: aggMsgGraphData,
    messagePerformanceList: messagePerformanceList,
  });
}

function MessageTableToRecord(data) {
  return MessageTableReportV2Record({
    key: data.get('key'),
    date: data.get('date') ? data.get('date') : '',
    messageid: data.get('messageid'),
    partitionname: data.get('partitionName'),
    totalmsgs: data.get('totalMsgs'),
    consentall: data.get('consentAll'),
    consentallper: data.get('consentAllPer'),
    consentsome: data.get('consentSome'),
    consentsomeper: data.get('consentSomePer'),
    noconsent: data.get('noConsent'),
    noconsentper: data.get('noConsentPer'),
    rejectall: data.get('rejectAll'),
    rejectallper: data.get('rejectAllPer'),
    legitimateintobj: data.get('legitimateIntObj') || 0,
    legitimateintobjper: data.get('legitimateIntObjper') || 0,
    siteid: data.get('siteId') || null,
    aggData: data.get('dataArr') ? data.get('dataArr') : List(),
  });
}
