import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';


const VendorTraceLayout = ({ children, location }) => {
    const accountState = useSelector(state => state.accountState);
    const currentUser = accountState?.getIn(['userDetails', 'value']);

    useEffect(() => {
        if (!currentUser.accountFeatures.includes('diagnose_vendor_trace')) {
            browserHistory.push('/');
        }
    }, [currentUser]);

    return children && React.cloneElement(children, {
        location,
    });
};

export default VendorTraceLayout;
