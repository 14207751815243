import PropTypes from 'prop-types';
import React from 'react';
import { Map, OrderedSet } from 'immutable';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { browserHistory } from 'react-router';
import { Headline, message } from '../../../styleguide';

import CreateForm from '../../common/CreateForm.js.jsx';
import CreateButton from '../../common/CreateButton.js.jsx';
import PageHeader from '../../common/PageHeader.js.jsx';
import SortFilter from '../../common/SortFilter.js.jsx';
import ScenarioList from './ScenarioList.js.jsx';
import ImportButton from '../../common/ImportButton.js.jsx';
import ImportForm from '../../common/ImportForm.jsx';
import Loading from '../../common/Loading.js.jsx';

import { getParameterByName } from '../../utils.js';
import { getAllSites, getSiteGroup } from '../../../actions/site_actions';
import { getDraftMessages } from '../../../actions/message_actions';
import { getDraftScenarios, createScenario, createSiteGroupScenario, importScenario } from '../../../actions/scenario_actions';
import { Scenario, CustomScenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { ScenarioV2 } from '../../../components/dialogue/scenarios/versionTwo/components/steps.js';

import { Site } from '../../../records/site_records.js';
import { User } from '../../../records/account_records.js';
import { Message } from '../../../records/message_records';
import SitesRedirect from '../campaigns/SitesRedirect';
import TemplateButton from '../scenariosNew/common/TemplateButton.jsx';

import { STATUS_TYPES as STATUS } from '../../../constants';
import { siteAndGroupLabel } from '../helper';
import SiteGroupRedirect from '../../common/SiteGroupRedirect';
import PropertiesUnderGroupRedirection from '../../common/PropertiesUnderGroupRedirection';
import { getAllMessagesAjax } from '../../../api/dialogue/messages';
import PropertyTag from '../../common/PropertyTag';

export class ScenariosPage extends React.Component {
    static propTypes = {
        scenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
        messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        pendingRequestsMap: ImmutablePropTypes.map.isRequired,
        allSites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
        scenariosForImport:ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
        route: PropTypes.shape({
          currentUser: PropTypes.instanceOf(User).isRequired,
        }).isRequired,

        getDraftScenarios: PropTypes.func.isRequired,
        getDraftMessages: PropTypes.func.isRequired,
        createScenario: PropTypes.func.isRequired,
        getAllSites: PropTypes.func.isRequired,
        importScenario: PropTypes.func.isRequired,
        getSiteGroup: PropTypes.func.isRequired,
        createSiteGroupScenario: PropTypes.func.isRequired,
    };

    state = {
        displayCreateForm: false,
        pending: false,
        displayImportMenu: false,
        filteredScenarios: this.props.scenarios,
        copyMessages: false,
        messages: []
    };

    componentDidMount() {
        this.getMessages();
        this.props.getAllSites();

        const siteGroupId = this.props.location.query.site_group_id;
        if (siteGroupId) {
          this.props.getSiteGroup(siteGroupId);
        }
        const redirectSiteGroupId = this.props.location.query.sitegroup_id;
        if(redirectSiteGroupId){
          this.props.getSiteGroup(redirectSiteGroupId);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.pendingRequestsMap) {
            if ( this.props.pendingRequestsMap.get('scenarios') && !newProps.pendingRequestsMap.get('scenarios')) {
                this.setState({ displayCreateForm: false });
            }
            if ( this.props.pendingRequestsMap.get('import') && !newProps.pendingRequestsMap.get('import')) {
                this.setState({ displayImportMenu: false });
            }
        }

        if (this.props.location.query.site_id !== newProps.location.query.site_id) {
          this.props.getDraftScenarios(newProps.location.query.site_id);
          this.props.getDraftMessages(newProps.location.query.site_id);
        }
    }

    getMessages = () => {
      const siteId = this.props.location.query.site_id;
      getAllMessagesAjax(siteId).then(messages => this.setState({ messages }))
    }

    toggleImportMenu = () => {
        this.setState({ displayImportMenu: !this.state.displayImportMenu })
    };

    importScenario = (scenario, options = { copyMessages: false }) => {
        const siteGroupId = this.props.location.query.site_group_id;
        if (siteGroupId) {
            const willImport = true;
            this.props.createSiteGroupScenario(this.props.currentUser.accountId, siteGroupId, scenario, willImport)
        } else {
          this.props.importScenario(this.props.location.query.site_id, scenario.id, options);
        }
    };

    handleDuplicateScenario = (name) => {
      const scenarios = this.props.scenarios;
      return scenarios.find(s => s.description === name);
    } 

    handleCreateScenario = (name) => {
        const duplicateScenario = this.handleDuplicateScenario(name);
        if(duplicateScenario) {
          message.warning(`Scenario with name '${name}' already exist. Please update existing or new scenario name.`);
          return;
        }

        const scenario = new ScenarioV2({
            description: name,
            site_id: this.props.location.query.site_id,
            schema_version: 2,
        });

        this.createScenario(scenario);
    };

    handleCreateCustomScenario = (name) => {
      const duplicateScenario = this.handleDuplicateScenario(name);
      if(duplicateScenario) {
        message.warning(`Scenario with name '${name}' already exist. Please update existing or new scenario name.`);
        return;
      }

      const scenario =  new CustomScenario({ description: name, site_id: this.props.location.query.site_id });

      this.createScenario(scenario);
    };

    createScenario = (scenario) => {
      const siteGroupId = this.props.location.query.site_group_id;
      if (siteGroupId) {
        const accountId = this.props.currentUser.accountId;
        this.props.createSiteGroupScenario(accountId, siteGroupId, scenario);
      } else {
        this.props.createScenario(scenario);
      }
    }

    toggleCreateForm = () => {
        this.setState({ displayCreateForm: !this.state.displayCreateForm });
    };

    onChecked = (checked) => {
      this.setState({
        copyMessages: checked,
      });
    }

    redirectToGroup = () => browserHistory.push(`${this.props.location.pathname}?site_id=${this.props.siteGroupRedirect.siteId}&site_group_id=${this.props.siteGroupRedirect.id}`);

    render() {

        let readOnly = false;
        if (this.props.currentUser.featureAccess &&
          !this.props.currentUser.featureAccess.includes('Campaign Entities')) {
          readOnly = true;
        }

        const siteId = this.props.location.query.site_id;
        let domain;
        let siteGroupSiteList;
        let siteGroupSitesWithErrors;
        let propertyType;
        if (this.props.location.query.site_group_id) {
          domain = this.props.siteGroup && this.props.siteGroup.name;
          siteGroupSiteList = <PropertiesUnderGroupRedirection pageKind='scenarios'/>;
          if (this.props.siteGroupSitesWithErrors.size) {
            siteGroupSitesWithErrors = (
              <SitesRedirect
                pageKind='scenarios'
                sites={ this.props.siteGroupSitesWithErrors.map(options => this.props.allSites.find(s => s.id === options.get('siteId')))}
                isForErrors
              />
            )
          }
        } else {
          const site = this.props.allSites.size ? this.props.allSites.find(s => s.id === parseInt(siteId)) : '';
          domain = site ? site.domain : '';
          propertyType = site ? site.type : '';
        }

        var createForm;
        var importMenuForm;

        let switchOptions;
        if (!this.props.location.query.site_group_id) {
          switchOptions = [{
            isChecked: this.state.copyMessages,
            onChecked: this.onChecked,
            popupText: 'Copy with Messages',
            disabled: false,
          }];
        }


        if (this.state.displayImportMenu) {
            let sites = this.props.allSites;
            if (this.props.currentUser.siteAccess) {
              sites = this.props.allSites.filter(s => this.props.currentUser.siteAccess.includes(s.id));
            }
            importMenuForm = (
                <ImportForm
                  siteId={ this.props.location.query.site_id }
                  type="scenario"
                  sites={ sites }
                  fetchItemsForTheSite={ this.props.getDraftScenarios }
                  storedItems={ this.props.scenariosForImport }
                  importItem={ this.importScenario }
                  closeForm={ this.toggleImportMenu }
                  switchOptions={ switchOptions }
                  visible={ this.state.displayImportMenu }
                />
            )
        }

        if (this.state.displayCreateForm) {
            createForm = <CreateForm onSubmit={ this.handleCreateScenario } hasHiddenButton={ true } onHiddenButtonClick={ this.handleCreateCustomScenario } placeholder="Scenario Name..." pending={ this.state.pending } cancel={ this.toggleCreateForm }/>;
        }

        var containerClass = "scenarios-container";
        if (this.state.displayCreateForm) containerClass += " open-create-window";

        var loading;
        if (this.props.pendingRequestsMap.some(value => value)) {
            loading = <Loading />
        }

        let importButton;
        let createButton;
        if (!readOnly) {
          importButton = <ImportButton onClick={ this.toggleImportMenu } disabled={ this.state.displayCreateForm || this.state.displayImportMenu }>IMPORT</ImportButton>;
          createButton = <CreateButton onClick={ this.toggleCreateForm } disabled={ this.state.displayCreateForm || this.state.displayImportMenu }>NEW</CreateButton>;

        }
        const accountId = this.props.currentUser.accountId;

        const propertyTag = propertyType && <PropertyTag type={ propertyType }/>;


        let backToSitesGroup;
        if(this.props.siteGroupRedirect){
          backToSitesGroup = <SiteGroupRedirect redirectToGroup={this.redirectToGroup} siteGroup={this.props.siteGroupRedirect}/>;
        }

        return (
            <div className={ containerClass }>
              { loading }
              <Headline>Scenarios</Headline>
              <div className="sceanrio-domain-wrapper">
                <span className="domain-name">
                  <span className="page-label">{siteAndGroupLabel(this.props.location.query.site_group_id)}</span>
                  <span className="page-title">{domain}</span>
                  { propertyTag }
                </span>

                <div className="scenario-add-options">
                  <TemplateButton disabled={false} className="scenario-import" onClick={this.toggleImportMenu} disabled={ this.state.displayCreateForm || this.state.displayImportMenu }>
                    Import
                  </TemplateButton>
                  <TemplateButton onClick={this.toggleCreateForm} disabled={ this.state.displayCreateForm || this.state.displayImportMenu }>
                    New
                  </TemplateButton>
                </div>
              </div>

              { createForm }
              { backToSitesGroup }
              { siteGroupSiteList }
              <SortFilter
                data={ this.props.scenarios }
                searchField={ 'description' }
                sorters={ [
                  SortFilter.dateSorter,
                  SortFilter.descriptionSorter,
                ] }
                onUpdate={ (filteredScenarios) => {
                  this.setState({ filteredScenarios })
                } }
              />
              { importMenuForm }
              { siteGroupSitesWithErrors }
              <ScenarioList
                scenarios={ this.state.filteredScenarios }
                messages={ this.state.messages }
                duplicateScenario={ this.importScenario }
                pending={ this.props.pendingRequestsMap.get('scenarios') }
                altpay={ this.props.currentUser.accountFeatures.includes('altpay') }
                consent={ this.props.currentUser.accountFeatures.includes('consent') }
                samba={ this.props.isSPUser || this.props.currentUser.accountFeatures.includes('samba') }
                welect={ this.props.isSPUser || accountId === 217 || accountId === 270 }
                consentV2={ this.props.currentUser.accountFeatures.includes('tcf_v2') }
                consentCCPA={ this.props.currentUser.accountFeatures.includes('ccpa') }
                consentUSNAT={ this.props.currentUser.accountFeatures.includes('usnat') }
                consentPreferences={ this.props.currentUser.accountFeatures.includes('preferences') }
                currentUserFeatures={ this.props.currentUser.accountFeatures }
                isSPUser={ this.props.isSPUser }
                accountId={ this.props.currentUser.accountId }
                readOnly={ readOnly }
                renderActiveCampaignWarning={this.props.renderActiveCampaignWarning}
              />
            </div>
        )
    }
}

const mapStateToProps = function(store){
    const siteId = parseInt(getParameterByName('site_id', window.location));

    return {
        currentUser: store.accountState.getIn(['userDetails', 'value']),
        scenarios: store.scenarioState.getIn(['scenarios', 'value']).filter(s => s.status === STATUS.DRAFT && s.site_id === siteId),
        messages: store.messageState.getIn(['messages', 'value']).filter(m => m.status === STATUS.DRAFT && m.site_id === siteId),
        allSites: store.siteState.getIn(['sites', 'value']),
        scenariosForImport: store.scenarioState.getIn(['scenarios', 'value']).filter(s => s.status === STATUS.DRAFT && s.site_id !== siteId),
        accountId: store.accountState.getIn(['accountId', 'value']),
        isSPUser: store.accountState.get('isSPUser'),
        siteGroup: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('site_group_id', window.location)),
        siteGroupRedirect: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('sitegroup_id', window.location)),        
        siteGroupSitesWithErrors: store.siteState.get('siteGroupSitesWithErrors'),
        pendingRequestsMap: Map({
            scenarios: store.scenarioState.getIn(['scenarios', 'pending']),
            messages: store.messageState.getIn(['messages', 'pending']),
            allSites: store.siteState.getIn(['sites', 'pending']),
            import: store.scenarioState.get('importPending'),
        }),
    };
}

export default connect(
    mapStateToProps, {
        getDraftScenarios,
        getDraftMessages,
        createScenario,
        getAllSites,
        importScenario,
        createSiteGroupScenario,
        getSiteGroup,
    }
)(ScenariosPage);
