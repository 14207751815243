import React, { Component } from 'react';
import {DatePicker as AntdDatePicker1 } from "antd";
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

const AntdDatePicker = AntdDatePicker1.generatePicker(momentGenerateConfig);

class DatePicker extends Component {
    
    render() {
        const props = {...this.props};
        props.className = 'spsg-datepicker ' + (props.className || '');
        props.dropdownClassName = 'spsg-datepicker-popup ' + (props.dropdownClassName || '');
        return (
            <AntdDatePicker {...props}/>
        )
    }   
}

DatePicker.WeekPicker = AntdDatePicker.WeekPicker;
DatePicker.MonthPicker = AntdDatePicker.MonthPicker;

export default DatePicker;
