/**Finding object in array of nested object */
export const findObjectByKeyRef = (data, nestedKey, key, value) => {
    // Search in the top-level array
    for (let i = 0; i < data.length; i++) {
        if (typeof data[i][key] === "object" && data[i][key]) {
            if (data[i][key].id === value) {
                return data[i];
            }
        } else {
            if (data[i][key] === value) {
                return data[i];
            }
        }
    }

    // Search in the nested arrays
    for (let i = 0; i < data.length; i++) {
        if (data[i][nestedKey]) {
            for (let j = 0; j < data[i][nestedKey].length; j++) {
                if (typeof data[i][nestedKey][j][key] === "object") {
                    if (data[i][nestedKey][j][key]?.id === value) {
                        return data[i][nestedKey][j];
                    }
                } else {
                    if (data[i][nestedKey][j][key] === value) {
                        return data[i][nestedKey][j];
                    }
                }
            }
        }
    }

    // Object not found
    return null;
}

/**Extracting language codes from stacks and individual purposes */
const extractTranslationLanguages = (obj) => {
    const translations = obj.translations?.names ?? obj.translations?.descriptions;
    if (translations) {
        const languageCodes = Object.keys(obj.translations.names??obj.translations.descriptions);
        return languageCodes;
    }

    const nestedCategories = obj.categories;
    if (nestedCategories && nestedCategories.length > 0) {
        nestedCategories.forEach(category => {
            extractTranslationLanguages(category);
        });
    }
}

/** This function returns all the language codes of the selected system purposes  */
export const getTranslationLanguages = (regulationCategories, systemPurposes,systemStacks) => {
    const translationLanguages = [];
    if (regulationCategories?.length > 0) {
        regulationCategories?.forEach(category => {
            if (category.type === 'SYSTEM_STACK') {
                const nestedCategories = category.categories;
                if (nestedCategories.length > 0) {
                    nestedCategories.forEach(c => {
                        const systemPurpose = findObjectByKeyRef(systemPurposes, 'categories', 'systemId', c?.purposeRef?.systemId);
                        let languageCodes = extractTranslationLanguages(systemPurpose);
                        if (languageCodes) {
                            translationLanguages.push(...languageCodes);
                        }
                    });
                } else {
                    const systemPurpose = findObjectByKeyRef(systemStacks, 'categories', 'systemId', category?.systemStackRef?.systemId);
                    let languageCodes = extractTranslationLanguages(systemPurpose);
                    if (languageCodes) {
                        translationLanguages.push(...languageCodes);
                    } 
                } 
            } else if(category.type === "SYSTEM_PURPOSE") {
                const systemPurpose = findObjectByKeyRef(systemPurposes, 'categories', 'systemId', category?.purposeRef?.systemId);
                let languageCodes = extractTranslationLanguages(systemPurpose);
                if (languageCodes) {
                    translationLanguages.push(...languageCodes);
                }
            } else if (category.type === "CUSTOM") {
                const systemPurpose = systemPurposes[0]; 
                let languageCodes = extractTranslationLanguages(systemPurpose);
                if (languageCodes) {
                    translationLanguages.push(...languageCodes);
                }
            }
        });
    }
    const uniqueTranslationLanguages = [...new Set(translationLanguages)]?.filter(l => l !== '_id');
    return uniqueTranslationLanguages;
}

export const getSelectedTranslationLanguages = (regulationCategories, systemPurposes) => {
    const translationLanguages = [];
    if (regulationCategories?.length > 0) {
        regulationCategories?.forEach(category => {
            if (category.type === 'SYSTEM_STACK') {
                const nestedCategories = category.categories;
                if (nestedCategories.length > 0) {
                    nestedCategories.forEach(c => {
                        let languageCodes = extractTranslationLanguages(c);
                        if (languageCodes) {
                            translationLanguages.push(...languageCodes);
                        }
                    });
                }
            } else {
                let languageCodes = extractTranslationLanguages(category);
                if (languageCodes) {
                    translationLanguages.push(...languageCodes);
                }
            }
        });
    }
    const uniqueTranslationLanguages = [...new Set(translationLanguages)]?.filter(l => l !== '_id');
    return uniqueTranslationLanguages;
}

export const getAllRegulationPurposes = (regulationCategories) => {
    let allRegulationPurposes = []
    regulationCategories.map(c => {
        if (c.type === 'SYSTEM_STACK') {
            allRegulationPurposes.push(c)
            c.categories.map(subcat => {
                allRegulationPurposes.push(subcat)
            })
        } else {
            allRegulationPurposes.push(c)
        }
    })
    return allRegulationPurposes;
}

export const getAllMainPurposes = (regulationCategories) => {
    let allRegulationPurposes = []
    regulationCategories.map(c => {
        if (c.type === 'SYSTEM_STACK') {
            allRegulationPurposes.push(c)
        } else {
            allRegulationPurposes.push(c)
        }
    })
    return allRegulationPurposes;
}

export const getEmptyTranslationLanguages = (translations,tab) => {
    const languages = [];

    if (tab === 1 && translations?.names) {
        for (const [lang, value] of Object.entries(translations?.names)) {
            if (value === '') {
                languages.push(lang);
            }
        }
    }

    if (tab === 2 && translations?.descriptions && translations.descriptions?.['default'] !== '') {
        for (const [lang, value] of Object.entries(translations?.descriptions)) {
            if (value === '' && !languages.includes(lang)) {
                languages.push(lang);
            }
        }
    }

    if (!tab) {
        if (translations?.names) {
            for (const [lang, value] of Object.entries(translations?.names)) {
                if (value === '') {
                    languages.push(lang);
                }
            }
        }
        if (translations?.descriptions && translations.descriptions?.['default'] !== '') {
            for (const [lang, value] of Object.entries(translations?.descriptions)) {
                if (value === '' && !languages.includes(lang)) {
                    languages.push(lang);
                }
            }
        }
    }

    return languages;
};

export const getLanguagesWithEmptyTranslation = (configurationCategories, tab) => {
    const translationLanguages = [];
    if (configurationCategories?.length > 0) {
        configurationCategories.map((category) => {
            if (category?.translations) {
                let emptyTranslationLanguages = getEmptyTranslationLanguages(category?.translations, tab);
                translationLanguages.push(...emptyTranslationLanguages)
            }
        })
    }
    const uniqueEmptyTranslationLanguages = [...new Set(translationLanguages)]?.filter(l => l !== '_id')
    return uniqueEmptyTranslationLanguages;
}


export const getUniqueObjectsByProperty = (arr, property) => {
    const uniqueObjects = [];
    const uniqueValues = new Set();

    arr.forEach(obj => {
        const value = obj[property];
        if (!uniqueValues.has(value)) {
            uniqueValues.add(value);
            uniqueObjects.push(obj);
        }
    });

    return uniqueObjects;
}
