

import { Record, List, Map, fromJS } from 'immutable';
import { Rule, Standard } from './standard_records';

export class DomainSetScore extends Record({
  domainSetId: null,
  scores: new Map({}),
  domainsScannedGDPR: 0,
  domainsScannedCCPA: 0,
}) {
  constructor(score) {
    super(fromJS(score));
  }
}


export class SetScoreIncluded extends Record({
  id: null,
  name: '',
  description: '',
  numProperties: 0,
  domainSetStatus: 'Disabled',
  activateDate: null,
  deactivateDate: null,
  region: null,
  scores: {},
  standard: {},
}) {
  constructor(domainSet) {
    const updatedDomainSet = Object.assign({}, domainSet);
    if (domainSet._id) updatedDomainSet.id = domainSet._id;
    super(updatedDomainSet);
  }
}

class SingleDomainScore extends Record({
  domainId: null,
  impressions: null,
  r1: null,
  r2: null,
  r3: null,
  r4: null,
  r5: null,
  r6: null,
  r7: null,
  r8: null,
  r9: null,
  r10: null,
  r11: null,
  r12: null,
  r13: null,
  r14: null,
  r15: null,
  r16: null,
  r17: null,
  r18: null,
  r19: null,
  r20: null,
  r21: null,
  r22: null,
  r23: null,
  r24: null,
  r25: null,
  r26: null,
  r27: null,
  r28: null,
  r29: null,
  r30: null,
  r31: null,
  r32: null,
  r33: null,
  r34: null,
  r35: null,
  r36: null,
  r37: null,
  r38: null,
  r39: null,
  r40: null,
  r41: null,
  r42: null,
  r43: null,
  r44: null,
  r45: null,
  r46: null,
  r47: null,
  r48: null,
  r49: null,
  r50: null,
  r51: null,
  r52: null,
  r53: null,
  r54: null,
  r55: null,
  r56: null,
  r57: null,
  r58: null,
  r59: null,
  r60: null,
  r61: null,
  r62: null,
  r63: null,
  r64: null,
  r65: null,
  r66: null,
  r67: null,
  r68: null,
  r69: null,
  r70: null,
  r80: null,
  r81: null,
  r82: null,
  r83: null,
  r84: null,
  r85: null,
  r86: null,
  r87: null,
  r88: null,
  r89: null,
  r90: null,
  r91: null,
  r92: null,
  r93: null,
  r94: null,
  r95: null,
  r96: null,
  r97: null,
  r98: null,
  r99: null,
  r100: null,
  standards: new Map({}),
  dateScoredGDPR: '',
  dateScoredCCPA: '',
}) {
  constructor(score) {
    super(fromJS(score));
  }
}

export class TopRules extends Record({
  passing: new List([]),
  undetermined: new List([]),
  failing: new List([]),
}) {
  constructor(topRules) {
    const updatedTopRules = Object.assign({}, topRules);
    if (topRules && topRules.passing) {
      updatedTopRules.passing = new List(topRules.passing.map(r => new Rule(r)));
      updatedTopRules.undetermined = new List(topRules.undetermined.map(r => new Rule(r)));
      updatedTopRules.failing = new List(topRules.failing.map(r => new Rule(r)));
    }

    super(updatedTopRules);
  }
}

export class TopRulesByStandard extends Record({
  standardId: null,
  topRules: new TopRules({}),
}) {
  constructor(topRulesByStandard) {
    const updatedTopRulesByStandard = Object.assign({}, topRulesByStandard);
    updatedTopRulesByStandard.topRules = new TopRules(topRulesByStandard.topRules);

    super(updatedTopRulesByStandard);
  }
}

export class DomainScore extends Record({
  domainSetId: null,
  scores: new List([]),
  topRulesByStandard: new TopRulesByStandard({}),
  domainsNotSetForScan: new List([]),
}) {
  constructor(score) {
    const updatedScore = Object.assign({}, score);
    updatedScore.scores = new List(score.scores).map(s => new SingleDomainScore(s));
    updatedScore.topRulesByStandard = new List(score.topRulesByStandard).map(st => new TopRulesByStandard(st));
    updatedScore.domainsNotSetForScan = new List(score.domainsNotSetForScan);

    super(updatedScore);
  }
}

export class ScoresForStandard extends Record({
  standardId: null,
  impressionsPassedActiveRules: null,
  impressionsPassedAllRules: null,
}) {
  constructor(score) {
    super(fromJS(score));
  }
}

export class StandardScore extends Record({
  allImpressions: null,
  averageImpressions: null,
  scores: new ScoresForStandard({}),
}) {
  constructor(score) {
    const updatedScore = Object.assign({}, score);
    if (score.scores) updatedScore.scores = new List(score.scores.map(s => new ScoresForStandard(s)));
    super(updatedScore);
  }
}
