import React, { Component } from 'react';
import { CSVLink as ReactCsvLink } from "react-csv";

class CSVLink extends Component {

    sanitizeData (data) {
        const enclosingCharacter = this.props.enclosingCharacter || `"`
        const escapeCharacter = enclosingCharacter === `"` ? `'` : `"`

        if (data === null || typeof data === 'boolean' || typeof data === 'number') {
            return data;
        }

        if (Array.isArray(data)) {
            return data.map(v => {
                return this.sanitizeData(v);
            });
        }

        if (typeof data === 'object') {
            return Object.keys(data).reduce((acc, eachKey) => {
                const sanitizedKey = this.sanitizeData(eachKey)
                acc[sanitizedKey] = this.sanitizeData(data[eachKey]);
                return acc;
            }, {});
        }

        return `${data}`
            .replace(/^[-|+|=|@]/, match => `${escapeCharacter}${match}${escapeCharacter}`)
            .replace(/"+/g, match => (match.length % 2 === 0 ? match : `${match}"`));   // find all quote instances, escape them if they are not already, the library will enclose all cells
    }

    render () {
        const { data, ...otherProps } = this.props

        if (typeof data === "string" && this.props.isSanitized !== true) {
            throw new Error("Please pass data as a JSON or an Array so that it can be sanitized against CSV injection OR isSanitized=true to bypass protections")
        }

        if (typeof enclosingCharacter === "string" && enclosingCharacter !== '"' && this.props.isSanitized !== true) {
            throw new Error("Only double quotes are supported as an enclosing character OR isSanitized=true to bypass protections")
        }

        return (
            <ReactCsvLink { ...otherProps } data={this.props.isSanitized ? data : this.sanitizeData(data)}></ReactCsvLink>
        )
    }
}

export default CSVLink