import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Label from '../../common/Label.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import PartitionsetTable from './PartitionsetTable.js.jsx';
import SummaryContainer from './summary/SummaryContainer';
import { numberWithCommas, getParameterByName } from '../../utils.js'
import { Campaign, ViewState, CampaignViewState } from '../../../records/campaign_records';
import { updateCampaignsViewState } from '../../../actions/campaign_actions';
import { CLIENTS_WITH_ADBLOCK_GRAPH_AND_POP_UP_STATS } from '../../../constants';
import SiteGroupSitesRedirect from './SiteGroupSitesRedirect';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;

export default class extends React.Component {
    static propTypes = {
        campaign: PropTypes.instanceOf(Campaign).isRequired,
        active: PropTypes.bool.isRequired,
        showCampaignStats: PropTypes.func.isRequired,
        summaryStats: PropTypes.object,
        getCampaignStats: PropTypes.func.isRequired,
        readOnly: PropTypes.bool.isRequired,
        campaignsViewState: PropTypes.instanceOf(ViewState).isRequired,
    };

    static defaultProps = {
        summaryStats: null,
    };

    toggleCampaignSummary = (e) => {
        e.preventDefault();
        const campaign = this.props.campaignsViewState.campaigns.get(this.props.campaign.id);
        const expanded = campaign ? campaign.expanded : false;
        if (!expanded && !this.props.summaryStats) {
            this.props.getCampaignStats(this.props.campaign.id);
        }
        const campaignState = new CampaignViewState({ id: this.props.campaign.id, expanded: !expanded});
        updateCampaignsViewState(this.props.campaignsViewState.setIn(['campaigns', this.props.campaign.id], campaignState));
    };

    onTabChange = (key) => {
        const campaignState = new CampaignViewState({ id: this.props.campaign.id, expanded: true, activeTab: key });
        updateCampaignsViewState(this.props.campaignsViewState.setIn(['campaigns', this.props.campaign.id], campaignState));
    };

    render() {
        var campaign = this.props.campaign;
        var detailTabs;
        var iconType;
        var tbodyClass = this.props.active ? 'active' : '';

        var labelText = this.props.campaign.environment === 1 ? "PUBLIC" : "STAGE";
        var labelClass = this.props.active ? "success" : "default";
        var label = <Label labelClass={ labelClass } text={ labelText } />

        const isSiteGroupCampaign = getParameterByName('site_group_id', window.location);

        const campaignViewState = this.props.campaignsViewState.campaigns.get(this.props.campaign.id) || new CampaignViewState({ id: this.props.campaign.id, expanded: this.props.campaign.live });

        if (campaignViewState.expanded && (this.props.summaryStats || isSiteGroupCampaign)) {
            let summaryTab;
            let partitionSummaryTab;

            // if there are other partitions in stats besides "control"
            if (this.props.summaryStats && Object.keys(this.props.summaryStats.get('partition_data')).length > 1 && !isSiteGroupCampaign) {

                partitionSummaryTab = (
                    <TabPane key="2" tab="Partition Summary">
                     <SummaryContainer {...(_.omit(this.props, ['showCampaignStats', 'summaryStats']))} manifestId={ campaign.id } partitionsetData={ campaign.partition_set.toJS() } summaryStats={ this.props.summaryStats.toJS() } />
                    </TabPane>
                )
            }

            if (isSiteGroupCampaign) {
              if (!this.props.campaign.deleted_at) {
                summaryTab = (
                  <TabPane key="1" tab="All sites">
                   <SiteGroupSitesRedirect inCampaignTable pageKind='campaigns'/>
                  </TabPane>
                )
              }
            } else {
              summaryTab = (
                <TabPane key="1" tab="Campaign Summary">
                 <SummaryContainer {...(_.omit(this.props, ['showCampaignStats', 'summaryStats']))} manifestId={ campaign.id } partitionsetData={ campaign.partition_set.toJS() } forCampaign summaryStats={ this.props.summaryStats.toJS() } />
                </TabPane>
              )
            }
            detailTabs = (
              <tr className='expanded'>
                <td colSpan="7" className="partition-header">
                  <Tabs bsStyle="pills" className="summary-tabs" id="tabs-summary" defaultActiveKey={ campaignViewState.activeTab } onChange={ this.onTabChange }>
                    { summaryTab }
                    { partitionSummaryTab }
                    <TabPane key={ 3 } tab="Partition Details">
                      <PartitionsetTable {...(_.omit(this.props, 'campaign'))} manifestId={ campaign.id } partitionsetData={ campaign.partition_set.toJS() } campaignRowStats={ campaign.stats.toJS() } />
                    </TabPane>
                  </Tabs>
                </td>
              </tr>
            )

            iconType = CustomIcon.types.COLLAPSE;
            if (!this.props.active) tbodyClass = (tbodyClass + " opened").trim();
        } else {
            iconType = CustomIcon.types.EXPAND;
        }

        let statsIcon;

        if (!isSiteGroupCampaign && this.props.isSPUser || this.props.masq || CLIENTS_WITH_ADBLOCK_GRAPH_AND_POP_UP_STATS.includes(this.props.accountId)) {
            statsIcon = (
                <CustomIcon
                  type={ CustomIcon.types.CHART }
                  onClick={ this.props.showCampaignStats.bind(null, this.props.campaign.id) }
                  disabled={ this.props.readOnly }
                  tooltip="view campaign stats"
                />
            )
        }

        let totalPv;
        if (!isSiteGroupCampaign) {
          let total = campaign.stats.reduce((total, s) => total + s.total_pv, 0)
          totalPv = (
            <td>{ numberWithCommas(total) }</td>
          )
        }

        return (

            <tbody className={ tbodyClass }>
              <tr className="partitionset-row">
                <td className="partionset-row-header">{ campaign.description }<span className="campaign-detail"><span className="campaign-id">{ campaign.id }</span><span className="partitionset-name">{ campaign.partition_set.description }</span></span></td>
                <td>{ label }</td>
                <td>{ moment(campaign.created_at).format('YYYY-MM-DD HH:mm') }</td>
                <td>{ campaign.deleted_at ? moment(campaign.deleted_at).format('YYYY-MM-DD HH:mm') : '' }</td>
                { totalPv }
                <td>
                  { statsIcon }
                  <CustomIcon
                    type={ iconType }
                    onClick={ this.toggleCampaignSummary }
                    disabled={ this.props.readOnly }
                  />
                </td>
              </tr>
              { detailTabs }
            </tbody>
        );
    }
}
