import React from "react";
import { getParameterByName } from "../../../utils";
import { getPortalOriginBasedOnEnv } from "../../../../util";

const OauthRedirectUrl = (props) => {
  
  const code = getParameterByName('code', window.location);
  let targetOrigin = getPortalOriginBasedOnEnv();

  setTimeout(() => {
    if(code) {
      window.opener.postMessage(code, targetOrigin);
      window.close();
    }
  }, 500)

  return (
    <>
      please wait while you're being authenticated.
    </>
  );
} 

export default OauthRedirectUrl;