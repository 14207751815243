import moment from 'moment';
import { Record, List, Map, fromJS } from 'immutable';
// import { systemKeyVals } from '../../../../../scenario_constants.js';
// import { SCENARIO_STEPS } from './steps';

// import { assembleAllRows } from '../parseAndSerializeRows.js';

let scenarioConsentUrl;
let scenarioCcpaUrl;
let ScenarioUsnatUrl;
let ScenarioPreferencesUrl;
if (process.env.REACT_APP_PUB_PORTAL_ENV === 'prod') {
   scenarioConsentUrl = 'https://sourcepoint.mgr.consensu.org/tcfv2';
   scenarioCcpaUrl = 'https://ccpa-service.sp-prod.net';
   ScenarioUsnatUrl = 'https://cdn.privacy-mgmt.com/usnat';
   ScenarioPreferencesUrl = 'https://cdn.privacy-mgmt.com/preferences';
} else {
   scenarioConsentUrl = 'https://cmpv2.sp-stage.net/tcfv2';
   scenarioCcpaUrl = 'https://ccpa.sp-stage.net';
   ScenarioUsnatUrl = 'https://usp.sp-stage.net';
   ScenarioPreferencesUrl = 'https://preferences.sp-stage.net';
} 

export class Scenario extends Record({
    description: '',
    id: '',
    schema_version: 0,
    site_id: '',
    status: '',
    steps: [],
    scenario_json: null,
    live: false,
    created_at: null,
    updated_at: null,
    campaign_type_id: 0,
}) {
    constructor(scenario = { created_at: Date.now(), updated_at: Date.now() }) {
        super(Object.assign({}, scenario, {
            created_at: new moment(scenario.created_at),
            updated_at: new moment(scenario.updated_at || scenario.created_at),
            campaign_type_id: Number(scenario.campaign_type_id),
        }));
    }
}

export class CustomScenario extends Scenario {
    constructor(scenario){
      super(Object.assign({ scenario_json: '{ "steps": [] }', schema_version: 1 }, scenario));
    }
}

export const IntRangeKVGate = Record({
    k: '',
    v_start: 0,
    v_end: 0,
    allow_on_satisfy: true,
})

export const StringValueKVGate = Record({
    k: '',
    v: '',
    type: 'match',
    allow_on_satisfy: true,
})

export const ReferrerRegexMatch = Record({
    regex: '',
    continue_on_satisfy: true,
})

export const DesktopUserAgentGate = Record({
    continue_on_satisfy: true,
})

export const IsAdblockedGate = Record({
    continue: true,
})

export const ChoiceOptionOIRGate = Record({
    continue: false,
})

export const ShowMessageAlways = Record({
    message_id: null,
})

export const ShowMessageOnce = Record({
    message_id: null,
})

export const ShowMessageNTimes = Record({
    message_id: null,
    n: 0,
})

export const ShowMessageFreqCap = Record({
    message_id: null,
    n: 0,
    t: 0,
})

export const DelayNPageviews = Record({
    num_pv: 0,
})

export const ModNDelay = Record({
    num_pv: 2,
})

export const SocialReferrerGate = Record({
    continue: true,
})

export const SearchReferrerGate = Record({
    continue: true,
})

export const SessionSearchReferrerGate = Record({
    continue: true,
})

export const SessionSocialReferrerGate = Record({
    continue: true,
})

export const GeoGate = Record({
    continue: true,
    geo_list: List([]),
})

export const USStateGate = Record({
  continue: true,
  state_list: List([]),
})

export const ContinueScenario = Record({
    continue: true,
})

export const ClientIPGate = Record({
    continue: true,
    ip_list: '',
})

export const AltPayCompGate = Record({
    continue: true,
})

export const SambaCompGate = Record({
    continue: true,
})

export const WelectCompGate = Record({
    continue: true,
})

export const CustomCompGate = Record({
    continue: true,
})

export const TimeDelay = Record({
    delay_secs: 0,
})

export const SessionMessageNLimit = Record({
    limit: 1,
})

export const ConsentGate = Record({
  status: 0,
  consent_origin: 'https://sourcepoint.mgr.consensu.org',
  version: 1,
});

export const ConsentGateV2 = Record({
  status: 0,
  consent_origin: scenarioConsentUrl,
  version: 2,
});

export const ConsentGateCCPA = Record({
  status: 0,
  consent_origin: scenarioCcpaUrl,
  version: 'ccpa',
});

export const ConsentGateUSNAT = Record({
  status: 0,
  consent_origin: ScenarioUsnatUrl,
  version: 'usnat',
});

export const ConsentGatePreferences = Record({
  status: 0,
  consent_origin: ScenarioPreferencesUrl,
  version: 'preferences',
});

export class ScenarioStep extends Record({ class: '', data: null }) {
  constructor(clazz, data) {
    if (clazz) {
      const stepType = clazz.slice(38);

      let StepRecord = scenarioSteps[stepType];

      if (!StepRecord) {
        StepRecord = scenarioSteps[clazz.slice(32)];
      }

      super({ class: clazz, data: new StepRecord(data) });
    } else {
      super();
    }
  }
}

export class IfStatementSequence extends Record({
  steps: new List(),
}) {
  constructor(data) {
    const steps = (data && data.steps) || [];
    super({
      steps: new List(steps.map((step) => new ScenarioStep(step.class, step.data))),
    });
  }
}

export class IfStatement extends Record({
  test: new ScenarioStep(),
  then_seq: new IfStatementSequence(),
  else_seq: new IfStatementSequence(),
}) {
  constructor(data) {
    if (data && data.test) {
      super({
        test: new ScenarioStep(data.test.class, data.test.data),
        then_seq: new IfStatementSequence(data.then_seq),
        else_seq: new IfStatementSequence(data.else_seq),
      });
    } else {
      super();
    }
  }
}

const scenarioSteps = {
    IntRangeKVGate,
    StringValueKVGate,
    ReferrerRegexMatch,
    DesktopUserAgentGate,
    IsAdblockedGate,
    ChoiceOptionOIRGate,
    ShowMessageAlways,
    ShowMessageOnce,
    ShowMessageNTimes,
    ShowMessageFreqCap,
    DelayNPageviews,
    ModNDelay,
    SocialReferrerGate,
    SearchReferrerGate,
    SessionSearchReferrerGate,
    SessionSocialReferrerGate,
    GeoGate,
    USStateGate,
    ContinueScenario,
    ClientIPGate,
    AltPayCompGate,
    SambaCompGate,
    WelectCompGate,
    CustomCompGate,
    TimeDelay,
    SessionMessageNLimit,
    IfStatement,
}
