import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { VendorList } from '../../../../../records/vendor_list_records';
import AllIabCheckbox from '../../AllIabCheckbox';
import AutoAddNewVendorsCheckbox from '../../AutoAddNewVendorsCheckbox';

export default class Table extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    readOnly: PropTypes.bool.isRequired,
    vendorScanningEnabled: PropTypes.bool.isRequired,

    toggleAddVendors: PropTypes.func.isRequired,
    createPurpose: PropTypes.func.isRequired,
    toggleAutoScan: PropTypes.func.isRequired,
    toggleAllIabAndUpdate: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.readOnly) {
      return null;
    } else {
      return (
        <div className="table-footer">
          <Button size="large" className="add-vendor-btn" onClick={ this.props.toggleAddVendors } disabled={ this.props.readOnly }>+ Add Vendor</Button>
          <Button size="large" className="add-vendor-btn add-purpose-btn" onClick={ this.props.createPurpose } disabled={ this.props.readOnly }>+ Add Purpose</Button>
        </div>
      );
    }
  }
}
