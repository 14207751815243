import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Button,
  Collapse,
  message
} from 'antd';

import SitePanel from './SitePanel';
import VariableList from './VariableList';

import { MessageVariables, IdNameValue } from '../../../../../../records/message_records';
import { SitesContext } from '../../../../contexts';

const Variables = ({ variables, updateVariables }) => {
  const { sites } = useContext(SitesContext);
  const [newVariable, setNewVariable] = useState({ name: '$$var_name$$', value: '' });

  const hasVariables = Boolean(variables.defaultVariables.size);
  return (
    <React.Fragment>
      <div className="top-form" style={{ padding: '0 10px' }}>
        <ul className="settings-form">
          <li>
            <div className="set-input" style={{ marginBottom: '10px' }}>
              <h4>Variable Name</h4>
              <Input
                value={newVariable.name}
                onChange={updateNewVariableName}
              />
            </div>
            <div className="set-input" style={{ marginBottom: '10px' }}>
              <h4>Default Value</h4>
              <Input
                value={newVariable.value}
                onChange={updateNewVariableValue}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" onClick={addVariable}>
                Add Variable
              </Button>
            </div>
          </li>
        </ul>
      </div>
      <Collapse className="settings-collapse">
        {hasVariables && makeMasterVariablesList()}
        {hasVariables && sites.map(site => (
          //SitePanel must be invoked as a function instead of component for Antd <Collapse.Panel/> to work
          SitePanel({
            id: site.id,
            domain: site.domain,
            variables,
            updateVariables,
            handleBlur
          })
        ))}
      </Collapse>
    </React.Fragment>
  );

  function makeMasterVariablesList() {
    return (
      <Collapse.Panel header="Master Variable List" >
        <VariableList
          variableList={variables.defaultVariables}
          handleBlur={handleBlur}
          handleChange={handleMasterVariablesChange}
          handleDelete={handleVariableDelete}
        />
      </Collapse.Panel>
    );
  }

  function updateNewVariableName({ target: { value } }) {
    if (!value.startsWith('$$') || !value.endsWith('$$') || value.length < 4) return;
    setNewVariable(newVariable => ({ ...newVariable, name: value }));
  }

  function updateNewVariableValue({ target: { value } }) {
    setNewVariable(newVariable => ({ ...newVariable, value }));
  }

  function addVariable() {
    const alreadyExists = Boolean(variables.defaultVariables.find(defaultVariable => (
      defaultVariable.get('name') === newVariable.name
    )));
    if (alreadyExists) {
      message.error('A variable with that name already exists');
      return;
    }

    const variable = new IdNameValue(newVariable).toMap().delete('id');
    const newlyUpdatedVariables = variables.set(
      'defaultVariables',
      variables.get('defaultVariables').unshift(variable)
    );

    setNewVariable({ name: '$$var_name$$', value: '' });
    updateVariables(newlyUpdatedVariables);
    message.success('Variable created');
  }

  function handleMasterVariablesChange({ value, variableName }) {
    const updatedVariable = variables.get('defaultVariables')
      .find(defaultVariable => defaultVariable.get('name') === variableName)
      .set('value', value);
    const indexOfVariable = variables
      .get('defaultVariables')
      .findIndex(defaultVariable => defaultVariable.get('name') === variableName);
    const newlyUpdatedVariables = variables.setIn(['defaultVariables', indexOfVariable], updatedVariable)

    updateVariables(newlyUpdatedVariables);
  }

  function handleVariableDelete(variableName) {
    const updatedDefaultVariables = variables.get('defaultVariables').filterNot(variable => variable.get('name') === variableName);
    const updatedSitesVariablesList = variables.get('sitesVariables').map(site => {
      const cleanVariables = site.get('variables').filterNot(variable => variable.get('name') === variableName);
      return site.set('variables', cleanVariables)
    });
    const newlyUpdatedVariables = variables
      .set('defaultVariables', updatedDefaultVariables)
      .set('sitesVariables', updatedSitesVariablesList);

    updateVariables(newlyUpdatedVariables);
  }

  function handleBlur() {
    updateVariables(variables)
  }
}

Variables.propTypes = {
  updateVariables: PropTypes.func.isRequired,
  variables: PropTypes.instanceOf(MessageVariables).isRequired
};

export default Variables;