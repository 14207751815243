import React, { Component } from 'react';
import { Input } from 'antd';

class Whitelist extends Component {
  changeValue = (value) => {
    const form = Object.assign({}, this.props.value.data);
    form['whitelist_url'] = value !== undefined ? value : null;
    this.props.changeValue(this.props.value.type, form);
  }

  render() {
    return (
      <div className="four-options">
        <h5>Redirect Url</h5>
        <div className="option-inputs" style={{ width: '100%', padding: '0 5px' }}>
          <Input value={this.props.value.data.whitelist_url} onChange={(e) => this.changeValue(e.target.value)} />
        </div>
      </div>
    );
  }
}

export default Whitelist;