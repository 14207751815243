import React, { useState, useEffect } from 'react';
import { fromJS } from 'immutable';
import { Select, Tooltip, Input, Button, Table, Checkbox, Infotip } from '../../../../../styleguide';
import { ConfigProvider, Divider, Tag, TreeSelect } from 'antd';
const Option = Select.Option;
import { RightOutlined, DownOutlined, CaretRightOutlined, ExportOutlined, WarningOutlined, InfoCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';

import { COUNTRY_LANGUAGES } from '../../../../../constants';
import { legalBasis, disclosureOnlyOptions } from '../../helper';
const legalBasisOptions = legalBasis.toJS();
import { diffArray } from './helper';
import { toTitleCase } from '../../../../utils';
import { preDefinedCustomPurposes } from './helper';
import { CategorizationSchema, PurposeV2 as Purpose, Translations } from '../../../../../records/vendor_list_records';
import { VENDOR_SCAN_CATEGORIZATION } from './helper';

const { SHOW_PARENT } = TreeSelect;
const areEqual = (prevProps, nextProps) => {
  return !prevProps.vendorList.equals(nextProps.vendorList);
};

function stripHtml(html) {
  return html.replace(/<[^>]*>?/gm, '');
}

const presetKeys = preDefinedCustomPurposes.map((p) => p.key);

const treeData = VENDOR_SCAN_CATEGORIZATION.filter(vsc => vsc.vendorScanCategory !== "Uncategorised").map((c) => {
  return {
    title: c.vendorScanCategory,
    value: c.vendorScanCategory,
  }
})

const getVendorScanCategory = (id) => {
 let categories = [];
 VENDOR_SCAN_CATEGORIZATION.forEach((item) => {
    if(item.associatedIabPurposesIds.length > 0 && item.associatedIabPurposesIds.includes(id)) {
      categories.push(item);
    }
  })
  return categories;
}

const CustomPurposesSettings = React.memo((props) => {
  const {
    vendorList,
    updateVendorList,
    mandatoryFieldError,
    intermediateCustomPurposes,
    updateIntermediateCustomPurposes,
    selectedCategories,
    setSelectedCategories,
    iabPurposes,
  } = props;
  const [translateLanguages, handleTranslationChange] = useState([]);
  const [noLangError, updateNoLangError] = useState('');

  const [customPurposes, updateCustomPurposes] = useState([]);
  const [expandedRowKeys, updateExpandedRowKeys] = useState([]);
  const [editKeys, updateEditKeys] = useState([]);
  const [predefinedPurposes, updatePredefinedPurposes] = useState([]);
  const [presetDropDownStatus, setPresetDropDownStatus] = useState(false);
  const [enteredValue, setEnteredValue] = useState({});
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [editIndexKey, setEditIndexKey] = useState([]);
  const parsePurpose = (p, stackId = null) => {
    let translations = [];
    const defaultDescription = stripHtml(p.description || '');
    if (p.translations) {
      Object.entries(p.translations.names).forEach(([key, value]) => {
        if(key == '_id') { return; }
        const description = stripHtml(p.translations.descriptions[key] || '');
        const languageTranslation = {
          key: `${key}-${new Date().getTime()}`,
          title: value,
          description: description,
          language: key,
        };
        translations.push(languageTranslation);
        return languageTranslation;
      });
    }

    return {
      key: p.id || `temp-${p.name}${new Date().getTime()}`,
      title: p.name,
      description: defaultDescription,
      legalBasis: p.disclosureOnly ? 'DISCLOSURE_ONLY' : (p.defaultCustomLegalBasis || 'NOT_APPLICABLE'),
      languageTranslations: translations,
      stackId,
      type: p.type,
      iabId: p.iabId
    };
  };

  const getCookieCategory  = (c) => {
    let scanCategories = [];
    iabPurposes.forEach((purpose) => {
      if(purpose.iabPurposeRef === c.iabPurposeRef){
        scanCategories = getVendorScanCategory(purpose.iabId);
      }
    });
    const colName = c.id ? 'vendorListCategoryId' : 'vendorListCategoryName';
    const colValue = c.id || c.name;
    const vendorScanCategories = []
    scanCategories.forEach( scanCategory => {
      vendorScanCategories.push(
        {
          vendorScanCategory : scanCategory.vendorScanCategory,
          [colName]: colValue,
          legalBasis: scanCategory.legalBasisValue
        }
      )
    })
    return vendorScanCategories;
  }

  useEffect(() => {
    const savedCustomPurposes = vendorList.categories
      .filter((c) => c.type == 'CUSTOM' || c.type == 'CUSTOM_STACK')
      .toJS();
    let updatedPurposes = savedCustomPurposes
      .map((p) => {
        if (p.categories.length > 0) {
          const stackId = p.id || p.name;
          return p.categories.map((sp) => parsePurpose(sp, stackId));
        } else {
          return parsePurpose(p);
        }
      })
      .flat();
    const languages = [
      ...new Set(updatedPurposes.map((p) => p.languageTranslations.map((t) => t.language.toLowerCase())).flat()),
    ];

    const vlVendorScanCategorizations = vendorList.vendorScanCategorizations.toJS();

    const modifiedPurposes = updatedPurposes.map((purpose) => {
      const cookieCategory = vlVendorScanCategorizations.filter((item) => item.vendorListCategoryId === purpose.key ||  item.vendorListCategoryName === purpose.title);
        return {...purpose, cookieCategory};
    })

    if(vlVendorScanCategorizations.length === 0) {
      let vlScanCatg = [];
      if (vendorList.categories.size) {
        vendorList.categories.forEach((c) => {
          if (c.categories.size > 0) {
            c.categories.forEach((sc) => {
              vlScanCatg = vlScanCatg.concat(getCookieCategory(sc))
            });
          } else {
            vlScanCatg = vlScanCatg.concat(getCookieCategory(c))
          }
        });
      }

      const updatedVendorList = vendorList.set('vendorScanCategorizations', fromJS(vlScanCatg));
      updateVendorList(updatedVendorList);
    }

    updatedPurposes = [...modifiedPurposes];
    
    handleTranslationChange(languages);
    updateCustomPurposes(_.cloneDeep(updatedPurposes));
    updateIntermediateCustomPurposes(_.cloneDeep(updatedPurposes));
    setSelectedCategories(_.cloneDeep(updatedPurposes));

    const presetPurposesSelectedKeys = updatedPurposes.filter((p) => presetKeys.includes(p.key)).map((p) => p.key);
    updatePredefinedPurposes(presetPurposesSelectedKeys);
  }, []);
  
  useEffect(() => {
    const identifier = setTimeout(() => {
      const purposes = Array.from(intermediateCustomPurposes);
      const nameIsPresent = purposes.find((purpose) => purpose.key !== enteredValue.key && purpose?.title?.toLowerCase().trim() === enteredValue?.name?.toLowerCase().trim())
      if(nameIsPresent) {
        setTitleIsValid(false);
        setEditIndexKey([...new Set([...editIndexKey, enteredValue.key])]);
      } 
      else{
        const keysArray = editIndexKey;
        const index = keysArray.indexOf(enteredValue.key);
        index !== -1 && keysArray.splice(index, 1);
        if(keysArray.length !== 0) setTitleIsValid(false);
        else setTitleIsValid(true);
        setEditIndexKey([...keysArray]);
      } 
    }, 500);

    return () => {
      clearTimeout(identifier);
    };
  }, [enteredValue]);

  const noLanguageSelectionMsg = 'Select languages from "Language translation" dropdown to proceed';

  const addNewPurpose = () => {
    setTitleIsValid(true);
    if (translateLanguages.length === 0) {
      updateNoLangError(noLanguageSelectionMsg);
      return;
    }

    const newKey = `temp${new Date().getTime()}`;

    updateEditKeys([...editKeys, newKey]);
    updateExpandedRowKeys([...expandedRowKeys, newKey]);

    if(intermediateCustomPurposes.length > 0 && translateLanguages.length > 0){
      const updatedIntermediatePurposes = intermediateCustomPurposes.length> 0 && intermediateCustomPurposes?.map(p => {
        const languageTranslations = p.languageTranslations.filter(l => translateLanguages.includes(l.language));
        return { ...p, languageTranslations: [...languageTranslations]}; 
      })
      let updatedInterCustomPurpose = []
      updatedInterCustomPurpose = updatedIntermediatePurposes.map(pur => {
      const languageTranslation = pur.languageTranslations.filter(lan => translateLanguages.includes(lan.language)).map(trans => trans.language);
       var filteredArray = translateLanguages.filter(function(n) {
         return languageTranslation.indexOf(n) === -1;
     });
     const newlanguageTrans = []
     filteredArray.map(lang => newlanguageTrans.push({
      key: `${lang}-${new Date().getTime()}`,
             title: '',
             description: '',
             language: lang,
     }))
     return { ...pur, languageTranslations: [...pur.languageTranslations , ...newlanguageTrans] }; 
     })

     let updatedSavedCustomPurpose = updatedInterCustomPurpose.map(pur => {
      const languageTranslation = pur.languageTranslations.filter(l => l.title !== '');
      return { ...pur, languageTranslations: [...languageTranslation]}; 
     })
     
     const purkeys = updatedIntermediatePurposes.map(p => p.key);
     updateEditKeys([...editKeys, ...purkeys]);
     updateExpandedRowKeys([...expandedRowKeys, ...purkeys]);
     updateIntermediateCustomPurposes(updatedInterCustomPurpose);
     updateCustomPurposes(updatedSavedCustomPurpose)
     setSelectedCategories(updatedInterCustomPurpose);

    }else if(intermediateCustomPurposes.length === 0 && translateLanguages.length > 0){
    let newPurpose = { key: newKey, title: '', description: '', legalBasis: '', languageTranslations: [], cookieCategory: [], type: "CUSTOM", iabId: null };
    const purposesWithLanguage = translateLanguages.map((l, li) => {
      return {
        key: `${l}-${new Date().getTime()}`,
        title: '',
        description: '',
        language: l,
      };
    });
    newPurpose.languageTranslations = purposesWithLanguage;
    const updatedPurposes = [newPurpose].concat(customPurposes);
    updateIntermediateCustomPurposes(updatedPurposes);
    setSelectedCategories(updatedPurposes);
  }else if(intermediateCustomPurposes.length > 0 && translateLanguages.length === 0){
    updateIntermediateCustomPurposes([]);
    updateCustomPurposes([])
    setSelectedCategories([]);
    updateEditKeys([]);
    updateExpandedRowKeys([]);
  }
  };

  const getLanguageTitle = (code) => {
    return COUNTRY_LANGUAGES.find((l) => l.code === code.toUpperCase()).language;
  };

  const updateField = (e, field, record, index) => {
    e.preventDefault();
    e.stopPropagation();
    const intermediateCustomPurposesClone = _.cloneDeep(intermediateCustomPurposes);

    const isParentRow = !!record.languageTranslations;
    let purposes = Array.from(intermediateCustomPurposesClone);
    if (isParentRow) {
      purposes[index][field] = e.target.value;
      (record.type === 'CUSTOM' && field === 'title') && setEnteredValue({key: record.key, name: e.target.value})
    } else {
      purposes[record.parentIndex]['languageTranslations'][index][field] = e.target.value;
    }
    updateIntermediateCustomPurposes(purposes);
    setSelectedCategories(purposes);
  };

  const updateLegalBasis = (value, index) => {
    let purposes = Array.from(intermediateCustomPurposes);
    purposes[index]['legalBasis'] = value;
    updateIntermediateCustomPurposes(purposes);
    setSelectedCategories(purposes);
  };

  const updateCookieCategory = (value, index, label, extra) => {
    let purposes = Array.from(intermediateCustomPurposes);

    let isExist = false;
    const updatedPurpose = purposes[index]['cookieCategory']
    const newCategoryObj =  { vendorScanCategory: extra.triggerValue, legalBasis:  "" };
    const hasIdPresent = (purposes[index]['key'] && purposes[index]['key'].slice(0, 4) == 'temp') ? null : purposes[index]['key'];
    if(hasIdPresent) {
      newCategoryObj['vendorListCategoryId'] = purposes[index]['key']
    } else newCategoryObj['vendorListCategoryName'] = purposes[index]['title']

    if(updatedPurpose.length === 0){
      isExist = true;
      purposes[index]['cookieCategory'].push(newCategoryObj);
    } else {
      updatedPurpose.forEach((c,idx) => { 
        if(extra.triggerValue === c.vendorScanCategory) {
          isExist = true;
          const categoryIndex = updatedPurpose.findIndex(purpose => purpose.vendorScanCategory === extra.triggerValue);
          purposes[index]['cookieCategory'].splice(categoryIndex , 1)
        }
      })
    }

    if(!isExist) purposes[index]['cookieCategory'].push(newCategoryObj)

    setSelectedCategories(purposes);
  }

  const renderLanguage = (code, record) => {
    let language = 'Default';
    let applyClass = true;
    if (!record.languageTranslations) {
      language = getLanguageTitle(code);
      applyClass = editKeys.includes(intermediateCustomPurposes[record.parentIndex].key);
    } else if (!editKeys.includes(record.key)) {
      applyClass = false;
    }
    const className = classNames({ 'edit-language': applyClass });
    return <p className={className}>{language}</p>;
  };

  const updateDiclosureOnlyCheckbox = (checked, index) => {
    let purposes = Array.from(intermediateCustomPurposes);
    purposes[index]['legalBasis'] = checked ? 'DISCLOSURE_ONLY' : 'NOT_APPLICABLE';
    updateIntermediateCustomPurposes(purposes);
  };

  const renderDisclosureOnlyCheckbox = (index, editable) => {
    const purpose = intermediateCustomPurposes[index];
    const legalBasis = purpose['legalBasis'] || undefined;
    return (
      <div className={editable?"checkbox-disclosure-only":"checkbox-disclosure-read-only"}>
        <Checkbox 
          disabled={!editable} 
          checked={legalBasis == 'DISCLOSURE_ONLY'} 
          onChange={(e) => updateDiclosureOnlyCheckbox(e.target.checked, index)}/>
      </div>
    );
  }

  const renderDescription = (value, record, index) => {
    const isParentRow = !!record.languageTranslations;
    let editable = false;
    let readOnlyLegalBasis;
    if (isParentRow) {
      const isEditing = editKeys.includes(record.key);
      editable = isEditing;
      const options = legalBasisOptions;
      const legalBasisOption = options.find((lb) => lb.value === (record.legalBasis == 'DISCLOSURE_ONLY' ? 'NOT_APPLICABLE' : record.legalBasis));
      readOnlyLegalBasis = !isEditing && legalBasisOption ? legalBasisOption.label : '';
    } else {
      const parentKey = intermediateCustomPurposes[record.parentIndex].key;
      const isEditing = editKeys.includes(parentKey);
      editable = isEditing;
    }
    return (
      <React.Fragment>
        {editable ? (
          renderInput('description', value, record, index)
        ) : (
          <div className="read-only-description">{value}</div>
        )}
        {isParentRow ?
          (editable ? renderDropDown(index,true) : <div className="read-only-legal-basis">{readOnlyLegalBasis}</div>):(renderDropDown(index,false))}
        {isParentRow && renderDisclosureOnlyCheckbox(index, editable)}
      </React.Fragment>
    );
  };

  const renderCookieCategory = (value, record, index) => {
    let cookieCategory = []; 
    let editable = false;
    let showCount = 0;
    let readOnlyCookieCategory;
    const isEditing = editKeys.includes(record.key);
    const isParentRow = !!record.languageTranslations;
    if(isParentRow){
      if(isEditing){
        editable = true
      }
    }else{
      const parentKey = intermediateCustomPurposes[record.parentIndex].key;
      if(editKeys.includes(parentKey)){
        editable = true
      }
    }
    // This is done to handle alignment issues of table
    let applyClass = false
    if(editable && !isParentRow){
      applyClass = false
    }
    if(!editable && !isParentRow){
      applyClass = true
    }

    const className = classNames(applyClass? 'empty-category-edit': 'empty-category');

    if(!record.cookieCategory) {
      return <div className={className}></div>;
    }else {
      VENDOR_SCAN_CATEGORIZATION.forEach((item) => {
      record.cookieCategory.forEach((category) => {
        if(category.vendorScanCategory === item.vendorScanCategory) {
          cookieCategory.push(category.vendorScanCategory);
          return;
        }
      })
    });
    readOnlyCookieCategory = !isEditing && cookieCategory.length !== 0 ? cookieCategory : [];
    showCount = readOnlyCookieCategory.length !== 0 && readOnlyCookieCategory.length;
 
    return (
      <React.Fragment>
        {
          (editable ? renderCookieCategoryDropDown(index) : 
          <div className="read-only-cookie-category">
            {showCount !==  0 ? 
              <div>
                <span>{readOnlyCookieCategory[0]}</span>&nbsp;
                <Infotip
                  content={() => readOnlyCookieCategory.slice(1).map(item => <p key={item}>{item}</p>)}
                  trigger="click"
                  placement="bottomRight"
                  >
                  {showCount > 1 && <span className='display-count'>{showCount > 1 ? `+${showCount - 1}` : ''}</span>}
                </Infotip>
              </div>
            : ''}
          </div>
          )
        }
      </React.Fragment>
    );
    }
  };

  const renderInput = (field, value, record, index) => {
    const errorMsg = !titleIsValid && <><strong>{value}</strong> name already exists.</>
    const error = (field === 'title' && !(record['parentIndex'] !== undefined) && editIndexKey.includes(record.key)) && errorMsg
    return (
      <Input placeholder={toTitleCase(field)} disabled={field === 'title' && record.type === 'IAB_PURPOSE'} error={error} value={value} onChange={(e) => updateField(e, field, record, index)} />
    );
  };

  const renderTitle = (value, record, index) => {
    const rowExpanded = record.languageTranslations && expandedRowKeys.includes(record.key);
    let collapseIcon = null;
    let editable = false;
    let purposeNameClassName = '';
    let stackLabel;
    const isParentRow = !!record.languageTranslations;
    if (isParentRow) {
      const isEditing = editKeys.includes(record.key);
      if (!isEditing) {
        collapseIcon =
          record.languageTranslations.length > 0 ? (
            expandedRowKeys.includes(record.key) ? (
              <DownOutlined />
            ) : (
              <RightOutlined />
            )
          ) : null;
        stackLabel = !!record.stackId && <Tag color="blue">In Stack</Tag>;
      } else {
        editable = true;
      }
    } else {
      purposeNameClassName = 'child-purpose-name';
      const parentKey = intermediateCustomPurposes[record.parentIndex].key;
      const isEditing = editKeys.includes(parentKey);
      editable = isEditing;
    }

    return (
      <div>
        {collapseIcon}
        {editable ? (
          renderInput('title', value, record, index)
        ) : (
          <span className={purposeNameClassName}>
            <span>{value}</span> {stackLabel}
          </span>
        )}
      </div>
    );
  };

  const renderDropDown = (index,visible=true) => {
    const purpose = intermediateCustomPurposes[index];
    let legalBasis = purpose && purpose['legalBasis'] || undefined;
    const className = classNames({ 'hideDropDown': !visible });
    return (
      <Select 
        placeholder='Select' 
        className={className}
        disabled={legalBasis == 'DISCLOSURE_ONLY'} 
        value={legalBasis == 'DISCLOSURE_ONLY' ? 'NOT_APPLICABLE' : legalBasis} 
        onChange={(value) => updateLegalBasis(value, index)}>
        {legalBasisOptions.map((lb) => (
          <Option key={lb.value} value={lb.value}>
            {lb.label}
          </Option>
        ))}
      </Select>
    );
  };

  const renderCookieCategoryDropDown = (index) => {
    const purpose = intermediateCustomPurposes[index];
    let disableDropdown = false;
    if (!(purpose.title.trim())) {
      disableDropdown = true;
    }
    const cookieCategory = purpose['cookieCategory'].length === 0 ? [] :
    purpose['cookieCategory'].map(item => item.vendorScanCategory);
    const tProps = {
      treeData,
      value: cookieCategory,
      onChange: (value, label, extra) => updateCookieCategory(value, index, label, extra),
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: 'Please select cookie categories',
      style: {
        width: '100%',
      },
      maxTagCount:1,
      dropdownClassName: 'cookie-category-dropdown',
      disabled: disableDropdown,
      showSearch: false,
    };
    return <TreeSelect {...tProps} />;
  }

  const editPurpose = (e, record) => {
    e.preventDefault();
    e.stopPropagation();
    setTitleIsValid(true);
    setEnteredValue({key: record.key, name: record.title})
    updateEditKeys([...new Set([...editKeys, record.key])]);
    updateExpandedRowKeys([...new Set([...expandedRowKeys, record.key])]);
  };

  const deleteEditKeys = (key) => {
    let editKeysArray = Array.from(editKeys);
    const indEdit = editKeysArray.indexOf(key);
    if (indEdit > -1) {
      editKeysArray.splice(indEdit, 1);
    }
    updateEditKeys(editKeysArray);
  };

  const deleteExpandedKeys = (key) => {
    let expandedRowKeysArray = Array.from(expandedRowKeys);
    const indExpanded = expandedRowKeysArray.indexOf(key);
    if (indExpanded > -1) {
      expandedRowKeysArray.splice(indExpanded, 1);
    }
    updateExpandedRowKeys(expandedRowKeysArray);
  };

  const movePurposeFromStack = (e, record, index) => {
    e.preventDefault();
    e.stopPropagation();
    let intPurposes = Array.from(intermediateCustomPurposes);

    let savedPurposes = Array.from(customPurposes);

    const stackIndex = vendorList.categories.findIndex((s) => (s.id || s.name) == record.stackId);
    const stack = vendorList.categories.get(stackIndex);
    const purposeIndexInStack = stack.categories.findIndex((p) => p.id === record.key || p.name === record.title);
    const purposeInStack = stack.categories.get(purposeIndexInStack);
    const updatedStack = stack.update('categories', (cs) =>
      cs.filterNot((p) => p.id === record.key || p.name === record.title)
    );
    let updatedVendorList = vendorList.setIn(['categories', stackIndex], updatedStack);
    if(updatedStack.categories.size === 0) {
      updatedVendorList = updatedVendorList.set('categories', updatedVendorList.get('categories').delete(stackIndex));
    }
    intPurposes[index]['stackId'] = null;
    savedPurposes[index]['stackId'] = null;
    let vendorScanCategorizations = vendorList.vendorScanCategorizations.toJS();
    if(record.type !== 'CUSTOM') {
      intPurposes = intPurposes.filter((purpose) => !(purpose.title === record.title))
      savedPurposes = savedPurposes.filter((purpose) => !(purpose.title === record.title))

      let vendorScanCategory;
      VENDOR_SCAN_CATEGORIZATION.forEach((item) => {
        if(item.associatedIabPurposesIds.length > 0 && item.associatedIabPurposesIds.includes(record.iabId)) {
          vendorScanCategory = item.vendorScanCategory;
        }
      })
      const isCategoryPresent = vendorScanCategorizations.find((category) => ((category?.vendorListCategoryId === record.key || category?.vendorListCategoryName === record.title) && category.vendorScanCategory === vendorScanCategory))

      if(isCategoryPresent) {
        vendorScanCategorizations = vendorScanCategorizations.filter((item) => 
          !((item?.vendorListCategoryId === record.key || item?.vendorListCategoryName === record.title) && item.vendorScanCategory !== vendorScanCategory)
        )
      } else {
        vendorScanCategorizations = vendorScanCategorizations.filter((item) => !(item?.vendorListCategoryId === record.key || item?.vendorListCategoryName === record.title));
      }
    }

    updatedVendorList = updatedVendorList.set('vendorScanCategorizations', fromJS(vendorScanCategorizations))
    .update('categories', (cs) => cs.push(purposeInStack));
    updateIntermediateCustomPurposes(intPurposes);
    setSelectedCategories(intPurposes);
    updateCustomPurposes(savedPurposes);
    updateVendorList(updatedVendorList);
  };

  const deletePurpose = (e, record, index) => {
    e.preventDefault();
    e.stopPropagation();
    let intPurposes = Array.from(intermediateCustomPurposes);
    intPurposes.splice(index, 1);
    updateIntermediateCustomPurposes(intPurposes);

    let updatedPurposes = Array.from(customPurposes);
    const purposeIndex = updatedPurposes.findIndex((p) => p.key === record.key);
    updatedPurposes.splice(purposeIndex, 1);
    updateCustomPurposes(updatedPurposes);

    deleteEditKeys(record.key);
    deleteExpandedKeys(record.key);

    let updatedVendorList = vendorList;
    const vendorListCategories = updatedVendorList.categories.filterNot((p) => p.id === record.key || p.name === record.title);
    let vendorScanCategorizations = updatedVendorList.vendorScanCategorizations.toJS();
    vendorScanCategorizations = vendorScanCategorizations.filter((item) => 
      item.vendorListCategoryName ? !(item.vendorListCategoryName === record.title) : !(item.vendorListCategoryId === record.key));

    updatedVendorList = updatedVendorList.merge({
      categories: vendorListCategories,
      vendorScanCategorizations: vendorScanCategorizations
    })
    updateVendorList(updatedVendorList);
  };

  const renderActionButtons = (value, record, index) => {
    const isEditing = editKeys.includes(record.key);
    let   editable = false;
    const isParentRow = !!record.languageTranslations;
    if(isParentRow){
      if(isEditing){
        editable = true
      }
    }else{
      const parentKey = intermediateCustomPurposes[record.parentIndex].key;
      if(editKeys.includes(parentKey)){
        editable = true
      }
    }
    //This is done to handle alignment fixes for table
    let applyClass = false
    if(editable && !isParentRow){
      if(record.parentIndex > 0){
        applyClass = true
      }else{
        applyClass = false
      }
    }
    if(!editable && !isParentRow){
      applyClass = true
    }
    const className = classNames({ 'empty-action': applyClass });
    return (
      !isEditing &&
      record.languageTranslations ? (
        <div className="action-btn-wrapper">
          <button
            title="Edit Purpose"
            className="avo-dialogue-icons avo-sp-edit"
            onClick={(e) => editPurpose(e, record)}
          />
          {record.stackId ? (
            <Tooltip
              placement="top"
              title="This purpose is in stack first move it out of the stack then you will be able to remove it"
            >
              <ExportOutlined onClick={(e) => movePurposeFromStack(e, record, index)} />
            </Tooltip>
          ) : (
            <img 
              title="Delete Purpose" 
              src={require('../../../../../assets/images/privacy-lens/delete-item.svg')} 
              className="delete-icon" 
              onClick={(e) => deletePurpose(e, record, index)} />
          )}
        </div>
      ) : <div className={className}></div>
    );
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
      className: 'purpose-title',
      render: (value, record, index) => renderTitle(value, record, index),
    },
    {
      title: () => (
        <React.Fragment>
          <div className="th-desc">Description</div>
          <div className="th-lgbs">Legal Basis</div>
          <div className="th-disclosure-only">Disclosure Only</div>
        </React.Fragment>
      ),
      dataIndex: 'description',
      key: 'description',
      width: '50%',
      className: 'custom-purposes-description',
      render: (value, record, index) => renderDescription(value, record, index),
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      width: '5%',
      className: 'purpose-language',
      render: renderLanguage,
    },
    {
      title: () => (
        <>
          Cookie Category
          <Infotip
            className="cookie-category-info"
            trigger="hover"
            placement="topLeft"
            content={`Cookies picked up in scans have 1 of these 4 cookie categories associated with them. Please associate cookie categories with this purpose in order to map cookies set by vendors that have been picked up in scans to purpose level disclosures in your vendor list.`}
          >
            <InfoCircleFilled style={{ marginLeft: '5px' }} />
          </Infotip>
        </>
      ),
      dataIndex: 'cookie_category',
      key: 'cookie_category',
      width: '20%',
      className: 'cookie-category',
      render: (value, record, index) => renderCookieCategory(value, record, index),
    },
    {
      title: ' ',
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      className: 'purpose-action',
      render: renderActionButtons,
    },
  ];

  const cancelEditing = (e, key, index) => {
    e.preventDefault();
    e.stopPropagation();

    deleteEditKeys(key);
    const intermediateCustomPurposesClone = _.cloneDeep(intermediateCustomPurposes);
    const customPurposesClone = _.cloneDeep(customPurposes);

    const purpose = intermediateCustomPurposesClone[index];
    const savedPurpose = customPurposesClone.find((p) => p.key === purpose.key);
    let updatedIntermediatePurposes = Array.from(intermediateCustomPurposesClone);

    let expandedRows = Array.from(expandedRowKeys);
    const ind = editKeys.indexOf(purpose.key);
    if (savedPurpose) {
      updatedIntermediatePurposes[index] = savedPurpose;
    } else {
      updatedIntermediatePurposes.splice(index, 1);
    }
    updateIntermediateCustomPurposes(updatedIntermediatePurposes);
    if (ind > -1) {
      expandedRows.splice(ind, 1);
    }
    updateExpandedRowKeys(expandedRows);
  };

  const validateHandler = (e, key, index, purpose) => {
    let purposes = Array.from(selectedCategories);

    if(purpose.type === 'CUSTOM') {
      const updatedCategory = purpose.cookieCategory.map((category) => {
        return (category.vendorListCategoryName) ? {...category, vendorListCategoryName: purpose.title} : category;
      })
      purposes[index]['cookieCategory'] = updatedCategory;
    }
    updateIntermediateCustomPurposes(purposes);
    handleSave(e, key, index)
  }

  const updateVendorCat = (purpose, vendors, legalBasis, shouldBeNA) => {
    const vCat = purpose.get('vendorCategorization');
    return vendors.map(vendor => {
      if(vendor.vendorType !== 'IAB'){
        const oldVendorCategorization = vCat.find((catg) => vendor.id == catg.vendorId);
        if (oldVendorCategorization) {
          return shouldBeNA ? null : oldVendorCategorization.set('type', legalBasis) ;
        } else {
          return shouldBeNA ? null : new CategorizationSchema({
            vendorId: vendor.id,
            cookies: [],
            type: legalBasis,
          })
        }
      } else {
        return null;
      }
    }).filterNot(vendorCategorization => vendorCategorization === null).toList();
  }

  const handleSave = (e, key, index) => {
    e.preventDefault();
    e.stopPropagation();

    deleteEditKeys(key);

    let updatedPurposes = _.cloneDeep(customPurposes);
    const intPurposes = _.cloneDeep(intermediateCustomPurposes);

    const purpose = updatedPurposes.find((p) => p.key == key);
    const updatedPurpose = _.cloneDeep(intPurposes[index]);

    let translations = new Translations({});
    updatedPurpose.languageTranslations.forEach((t, i) => {
      const formattedLng = t.language.toLowerCase();
      translations = translations
        .setIn(['names', formattedLng], t.title)
        .setIn(['descriptions', formattedLng], t.description);
    });
    let updatedVendorList;

    let vlVendorScanCategorizations = vendorList.vendorScanCategorizations.toJS();

    // Filter out the cookie category objects using updated purpose name or id base.
    const filteredVendorScanCategorizations = vlVendorScanCategorizations.filter((item) =>  {
      return item.vendorListCategoryName ? item.vendorListCategoryName === purpose?.title : item.vendorListCategoryId === purpose?.key;
    });

    // A comparer used to determine if two entries are equal.
    const isSameCookieCategory = (a, b) => {
      return a.vendorListCategoryName === b.vendorListCategoryName && a.vendorScanCategory === b.vendorScanCategory
    };

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) => 
      left.filter(leftValue =>
        !right.some(rightValue => 
          compareFunction(leftValue, rightValue)));

    const onlyInA = onlyInLeft(filteredVendorScanCategorizations, updatedPurpose.cookieCategory, isSameCookieCategory);
    const onlyInB = onlyInLeft(updatedPurpose.cookieCategory, filteredVendorScanCategorizations, isSameCookieCategory);

    const result = [...onlyInA, ...onlyInB];

    result.forEach((category) => {
      const index = vlVendorScanCategorizations.findIndex((item) => {
        if(category.vendorListCategoryName) {
          return (item.vendorListCategoryName === category.vendorListCategoryName ) && (item.vendorScanCategory === category.vendorScanCategory)
        }else {
          return (item.vendorListCategoryId === category.vendorListCategoryId ) && (item.vendorScanCategory === category.vendorScanCategory)
        }
      })
      index !== -1 ? vlVendorScanCategorizations.splice(index, 1) : vlVendorScanCategorizations.push(category)
    });

    if (purpose) {
      if (purpose.stackId) {
        const stackIndex = vendorList.categories.findIndex((s) => (s.id || s.name) == purpose.stackId);
        const stack = vendorList.categories.get(stackIndex);
        const value = (updatedPurpose.key && updatedPurpose.key.slice(0, 4) == 'temp') ? updatedPurpose.title : updatedPurpose.key;
        const purposeIndexInStack = stack.categories.findIndex((p) => (p.id || p.name) == value);
        let purposeInStack = stack.categories.get(purposeIndexInStack);
        const isDisclosureOnly = updatedPurpose.legalBasis == 'DISCLOSURE_ONLY';
        const isNotApplicable = updatedPurpose.legalBasis == 'NOT_APPLICABLE';
        let updatedVCat = null;
        if(purposeInStack.defaultCustomLegalBasis !== updatedPurpose.legalBasis){
          updatedVCat = updateVendorCat(purposeInStack, vendorList.vendors, updatedPurpose.legalBasis, (isDisclosureOnly || isNotApplicable));
        }
        purposeInStack = purposeInStack.merge({
          name: updatedPurpose.title,
          description: updatedPurpose.description,
          defaultCustomLegalBasis: (isDisclosureOnly || isNotApplicable) ? null : updatedPurpose.legalBasis,
          disclosureOnly: isDisclosureOnly,
          translations: updatedPurpose.languageTranslations.length > 0 ? translations : null,
        });
        if(updatedVCat !== null){
          purposeInStack = purposeInStack.set('vendorCategorization', updatedVCat);
        }
        const updatedStack = stack.setIn(['categories', purposeIndexInStack], purposeInStack);
        updatedVendorList = vendorList.set('vendorScanCategorizations', fromJS(vlVendorScanCategorizations))
        .setIn(['categories', stackIndex], updatedStack);

      } else {
        const purposeIndexInVl = vendorList.categories.findIndex((p) => p.id === updatedPurpose.key || p.name === updatedPurpose.title);
        let purposeToBeUpdated = vendorList.categories.find((p) => p.id === updatedPurpose.key || p.name === updatedPurpose.title);
        const isDisclosureOnly = updatedPurpose.legalBasis == "DISCLOSURE_ONLY";
        const isNotApplicable = updatedPurpose.legalBasis == 'NOT_APPLICABLE';
        let updatedVCat = null;
        if(purposeToBeUpdated.defaultCustomLegalBasis !== updatedPurpose.legalBasis){
          updatedVCat = updateVendorCat(purposeToBeUpdated, vendorList.vendors, updatedPurpose.legalBasis, (isDisclosureOnly || isNotApplicable));
        }
        purposeToBeUpdated = purposeToBeUpdated.merge({
          name: updatedPurpose.title,
          description: updatedPurpose.description,
          defaultCustomLegalBasis: (isDisclosureOnly || isNotApplicable) ? null : updatedPurpose.legalBasis,
          disclosureOnly: isDisclosureOnly,
          translations: updatedPurpose.languageTranslations.length > 0 ? translations : null,
        });
        if(updatedVCat !== null){
          purposeToBeUpdated = purposeToBeUpdated.set('vendorCategorization', updatedVCat);
        }
        updatedVendorList = vendorList.set('vendorScanCategorizations', fromJS(vlVendorScanCategorizations))
        .setIn(['categories', purposeIndexInVl], purposeToBeUpdated);
      }

      const purposeIndex = updatedPurposes.findIndex((p) => p.key == key);
      updatedPurposes[purposeIndex] = updatedPurpose;
      updatedPurposes = [...updatedPurposes];
    } else {
      updatedVendorList = vendorList.set('vendorScanCategorizations', fromJS(vlVendorScanCategorizations))
      .update('categories', (c) => {
        const isDisclosureOnly = updatedPurpose.legalBasis == "DISCLOSURE_ONLY";
        const isNotApplicable = updatedPurpose.legalBasis == 'NOT_APPLICABLE';
        let updatedVCat = updateVendorCat(new Purpose({}), vendorList.vendors, updatedPurpose.legalBasis, (isDisclosureOnly || isNotApplicable));
        return c.push(
          new Purpose({
            id: updatedPurpose.key,
            name: updatedPurpose.title,
            description: updatedPurpose.description,
            defaultCustomLegalBasis: (isDisclosureOnly || isNotApplicable) ? null : updatedPurpose.legalBasis,
            disclosureOnly: isDisclosureOnly,
            type: 'CUSTOM',
            translations: updatedPurpose.languageTranslations.length > 0 ? translations : null,
            vendorCategorization: updatedVCat ? updatedVCat.toJS() : List([]),
          })
        )
      });
      updatedPurposes = [...updatedPurposes, updatedPurpose];
    }
    updateVendorList(updatedVendorList);
    updateCustomPurposes(updatedPurposes);
  };

  const renderFooter = (currentPageData, key, index) => {
    let readOnlySave = false;
    const purpose = intermediateCustomPurposes.find((p) => p.key === key);
    const allTranslations = purpose.languageTranslations.some((t) => !(t.title));
    if (!(purpose.title.trim() && !purpose.title.includes(";") && purpose.legalBasis && !allTranslations && titleIsValid)) {
      readOnlySave = true;
    }
    const isEditing = editKeys.includes(key);
    return isEditing ? (
      <React.Fragment>
        <Button onClick={(e) => cancelEditing(e, key, index, purpose)}>
          Cancel
        </Button>
        <Button type="primary" disabled={readOnlySave} onClick={(e) => validateHandler(e, key, index, purpose)}>
          Save
        </Button>
      </React.Fragment>
    ) : null;
  };

  const expandedRowRender = (record, index, indent, expanded) => {
    const translationsRecords = record.languageTranslations.map((obj) => ({ ...obj, parentIndex: index }));
    const isEditing = editKeys.includes(record.key);
    return (
      <ConfigProvider renderEmpty={() => null}>
        <Table
          rowKey={row => row.key}
          footer={!isEditing ? null : (currentPageData) => renderFooter(currentPageData, record.key, index)}
          showHeader={false}
          columns={columns}
          dataSource={translationsRecords}
          pagination={false}
          className='language-translations-table'
        />
      </ConfigProvider>
    );
  };

  const handlePredefinedPurposesSelection = (purposes) => {
    const allSelected = purposes.includes('all');
    const allPurposes = preDefinedCustomPurposes.map((pp) => pp.key);
    const optionsWithAll = ['all'].concat(allPurposes);
    let selectedPurposes = [];
    if (allSelected && predefinedPurposes.length !== 0 && optionsWithAll.length !== purposes.length) {
      selectedPurposes = purposes.filter((p) => p !== 'all');
    } else if (!allSelected && predefinedPurposes.includes('all')) {
      selectedPurposes = [];
    } else if (allSelected || (!allSelected && purposes.length === allPurposes.length)) {
      selectedPurposes = optionsWithAll;
    } else {
      selectedPurposes = purposes;
    }
    
    const errorMessage = selectedPurposes.length && translateLanguages.length === 0 ? noLanguageSelectionMsg : '';
    updateNoLangError(errorMessage);

    updatePredefinedPurposes(selectedPurposes);
  };

  const addPredefinedPurposes = () => {
    const selectedPurposes = predefinedPurposes.filter((p) => p !== 'all');
    let updatedPurposesKeys = [];
    let purposesToBeUpdated = [];

    const purposesToBeAdded = selectedPurposes
      .map((p) => {
        const purposeAlreadyExist = intermediateCustomPurposes.find((ip) => ip.key === p);
        if (purposeAlreadyExist) {
          setTitleIsValid(false);
          return;
        }

        const purpose = preDefinedCustomPurposes.find((pp) => pp.key === p);
        let updatedPurpose = { ...purpose, languageTranslations: [] };
        const translations = translateLanguages.map((lng) => {
          let autoTranslation = purpose.languageTranslations.find((lt) => lt.language === lng.toUpperCase());
          autoTranslation = autoTranslation ? { ...autoTranslation, language: lng } : null;
          const emptyTranslation = {
            key: `${lng}-${new Date().getTime()}`,
            title: '',
            description: '',
            language: lng,
          };
          const translation = autoTranslation || emptyTranslation;
          return translation;
        });
        updatedPurpose.languageTranslations = translations;

        if (translations.some((t) => !(t.title && t.description))) {
          updatedPurposesKeys.push(p);
        } else {
          purposesToBeUpdated.push(updatedPurpose);
          updateCustomPurposes((prevState) => [...prevState, updatedPurpose]);
        }

        return updatedPurpose;
      })
      .filter((p) => p !== undefined);

    let updatedVendorList = vendorList;
    let vlVendorScanCategorizations = updatedVendorList.vendorScanCategorizations.toJS();
    purposesToBeUpdated.forEach((p) => {
      let translationsVl = new Translations({});
      p.languageTranslations.forEach((t) => {
        const lang = t.language.toLowerCase();
        translationsVl = translationsVl
          .setIn(['names', lang], t.title)
          .setIn(['descriptions', lang], t.description);
      });
      p.cookieCategory.forEach((c) => 
        vlVendorScanCategorizations.push(c)
      )

      updatedVendorList = updatedVendorList.set('vendorScanCategorizations', fromJS(vlVendorScanCategorizations))
      .update('categories', (c) => {
        let updatedVCat = updateVendorCat(new Purpose({}), vendorList.vendors, p.legalBasis, (isDisclosureOnly || isNotApplicable));
        return c.push(
          new Purpose({
            id: p.key,
            name: p.title,
            description: p.description,
            defaultCustomLegalBasis: p.legalBasis == 'DISCLOSURE_ONLY' || p.legalBasis == 'NOT_APPLICABLE' ? null : p.legalBasis,
            disclosureOnly: p.legalBasis == 'DISCLOSURE_ONLY',
            type: 'CUSTOM',
            iabId: null,
            translations: translationsVl,
            vendorCategorization: updatedVCat ? updatedVCat.toJS() : List([]),
          })
        )
      }
      );
    });
    updateVendorList(updatedVendorList);

    const presetKeys = preDefinedCustomPurposes.map((pp) => pp.key);
    const updatedPurposes = intermediateCustomPurposes.filter((ip) => {
      return !presetKeys.includes(ip.key) || (presetKeys.includes(ip.key) && predefinedPurposes.includes(ip.key));
    });

    const combinedPurposes = updatedPurposes.concat(purposesToBeAdded);
    updateIntermediateCustomPurposes(combinedPurposes);
    setSelectedCategories(combinedPurposes);
    updateEditKeys([...new Set([...updatedPurposesKeys])]);
    updateExpandedRowKeys([...new Set([...updatedPurposesKeys])]);
    setPresetDropDownStatus(false);
    setTitleIsValid(true);
  };

  const getRowClassName = (record) => {
    return record.languageTranslations ? (editKeys.includes(record.key) ? 'default-row' : 'readonly-default-row') : '';
  };

  const onPresetDDVisibleChange = (open) => {
    setPresetDropDownStatus(open);
    if(open) {
      updateNoLangError(noLanguageSelectionMsg);
    }
  }

  const addLanguages = (languages) => {
    handleTranslationChange(languages);
    updateNoLangError('');
  }

  const addnewReadOnly = !!editKeys.length;
  //const addnewReadOnly = !translateLanguages.length;

  const onlyDefaultPurposes = translateLanguages.length === 0; // && intermediateCustomPurposes.length > 0;

  const applyPresetBtnReadOnly = onlyDefaultPurposes ? false : !translateLanguages.length;

  const error = translateLanguages.length === 0 && noLangError && (
    <div className="no-language-select-error">
      <WarningOutlined /> {noLangError}
    </div>
  );

  const validationError = mandatoryFieldError && (
    <div className="no-language-select-error">
      <WarningOutlined /> {mandatoryFieldError}
    </div>
  );

  return (
    <div className="custom-purposes-wrapper">
      <div className="header">Custom Purposes</div>
      <div className="info">
        In this section, you will be able to create custom purposes and select which of the IAB purposes are applicable to the processing of user data for your own purposes. After adding required Language Translations you can add a new custom purpose or you can select one or more from our presets.
      </div>
      <div className="sub-header">Add Language Translation</div>
      <div className="language-selection-wrapper">
        <Select
          dropdownClassName="custom-dropdown"
          disabled={!!editKeys.length}
          mode="multiple"
          maxTagCount={1}
          maxTagTextLength={8}
          maxTagPlaceholder={`+${translateLanguages.length - 1}`}
          showArrow={true}
          className="language-translation"
          placeholder={onlyDefaultPurposes ? 'Default' : 'Select'}
          value={translateLanguages}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={addLanguages}
        >
          {COUNTRY_LANGUAGES.map((o) => (
            <Select.Option key={o.code.toLowerCase()}>{o.language}</Select.Option>
          ))}
        </Select>
        <CaretRightOutlined />
        <Button type="primary" 
        disabled={addnewReadOnly} 
        onClick={addNewPurpose}>
          Add New
        </Button>
        <span className="option-divider">or</span>
        <Select
          dropdownClassName="predefined-purposes-select"
          mode="multiple"
          maxTagCount={predefinedPurposes.includes('all') ? 0 : 1}
          maxTagTextLength={8}
          maxTagPlaceholder={predefinedPurposes.includes('all') ? 'All' : `+${predefinedPurposes.length - 1}`}
          showArrow={true}
          placeholder="Select Preset"
          value={predefinedPurposes}
          onChange={handlePredefinedPurposesSelection}
          open={presetDropDownStatus}
          onDropdownVisibleChange={onPresetDDVisibleChange}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider />
              <Button ghost disabled={applyPresetBtnReadOnly} onClick={addPredefinedPurposes}>
                Apply
              </Button>
            </div>
          )}
        >
          <Option key="all" value="all">
            All
          </Option>
          {preDefinedCustomPurposes.map((cp) => (
            <Option key={cp.key} value={cp.key}>
              {cp.title}
            </Option>
          ))}
        </Select>
      </div>
      {error}
      {validationError}
      <Table
        scroll={{x: true}}
        rowKey={record => record.key}
        locale={{
          emptyText:
            'Right now there are no custom purposes added to the list. Select languages that needs translation and click on "Add New" button.',
        }}
        columns={columns}
        dataSource={intermediateCustomPurposes}
        pagination={false}
        rowClassName={getRowClassName}
        expandable={{
          expandedRowKeys,
          expandIconColumnIndex: -1,
          expandRowByClick: true,
          rowExpandable: (record) => !!record.languageTranslations,
          expandedRowRender,
          onExpandedRowsChange: (expandedRows) => {
            const purposeKey = diffArray(expandedRows, expandedRowKeys)[0] || '';
            const isPreviouslyExpanded = expandedRowKeys.includes(purposeKey);
            const isEditing = editKeys.includes(purposeKey);

            if (isPreviouslyExpanded && isEditing) {
              return;
            }
            updateExpandedRowKeys(expandedRows);
          },
        }}
      />
    </div>
  );
}, areEqual);

export default CustomPurposesSettings;
