import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Select, Modal } from 'antd';
import { message } from '../../../../styleguide';  
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List, OrderedSet } from 'immutable';
import {
  getGlobalVendors,
  getGlobalVendorsHash,
  getSitesEnabledForScan,
  getVendorList,
  updateVendorList,
  createVendorList,
  getIABPurposes,
  getVendorLists,
  clearVendorListError,
  deleteVendorList,
  cloneVendorList
} from '../../../../actions/vendor_list_actions_ccpa.js'; 
import {
  getAllSites,
  getSiteGroups
} from '../../../../actions/site_actions.js';
import Loading from '../../../common/Loading.js.jsx';
import {
  VendorListCCPA as VendorList,
  Vendor,
  Purpose,
  VendorListError,
  VendorWrapper
} from '../../../../records/vendor_list_records';
import { Site } from '../../../../records/site_records';
import { displayErrors } from '../../helper';
import { CONSENT_SCOPES } from '../../../../constants';
import VendorScanModal from '../VendorScanModal';
import AdvancedSettingsModal from '../AdvancedSettingsModal';
import DoNotSellSettingsModal from '../DoNotSellSettingsModal';
import Header from './Header';
import ManageSites from './ManageSites';
import Table from './table/Table';
import { User } from '../../../../records/account_records';
import { getDnsMessages } from '../../../../actions/dns_actions';
import partitionset_reducer from '../../../../reducers/partitionset_reducer.js';
import { SiteToMessageMap } from '../../../../records/dns_records.js';
import AddVendorsModal from '../../../common/AddVendorsModal';
import { getParameterByName } from '../../../utils.js';
import CloneModal from '../../../common/CloneModal.jsx';
import DismissErrorModal from '../../../common/DismissErrorModal.jsx';

const Option = Select.Option;

function updateSitesInPset(partitionSet, messagesBySite) {
  // new [1,2,3]
  // old [3,4,5]

  // add [1,2]
  // remove [4,5]
  // leave as [3]

  //1.go through old,
  //if it's in the new set leave as is and remove from new,
  //if it's not in the new set remove from all partitions

  //2.got through new without old
  //add to each partition along with the first message

  const firstPartition = partitionSet.partitions.first();
  const sitesInPartitionSet = firstPartition.siteToMessageMap.map(
    obj => obj.siteId
  );
  let newSitesWithDnsMessages = messagesBySite.map(obj => obj.siteId);

  //1
  sitesInPartitionSet.map(siteId => {
    if (newSitesWithDnsMessages.includes(siteId)) {
      newSitesWithDnsMessages = newSitesWithDnsMessages.filterNot(
        id => id === siteId
      );
    } else {
      const partitions = partitionSet.partitions.map(partition => {
        const newSiteToMessageMap = partition.siteToMessageMap.filterNot(
          obj => obj.siteId === siteId
        );
        return partition.set('siteToMessageMap', newSiteToMessageMap);
      });
      partitionSet = partitionSet.set('partitions', partitions);
    }
  });

  //2
  newSitesWithDnsMessages.forEach(siteId => {
    const msg = messagesBySite
      .find(obj => obj.siteId === siteId)
      .messages.first();
    if (msg) {
      const siteToMessageMap = new SiteToMessageMap({
        siteId,
        messageId: msg.id,
        messageSettings: msg.messageSettings
      });
      const partitions = partitionSet.partitions.map(partition => {
        const newSiteToMessageMap = partition.siteToMessageMap.push(
          siteToMessageMap
        );
        return partition.set('siteToMessageMap', newSiteToMessageMap);
      });
      partitionSet = partitionSet.set('partitions', partitions);
    }
  });

  return partitionSet;
}

class VendorListEdit extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList),
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    globalVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor))
      .isRequired,
    globalVendorsHash: ImmutablePropTypes.mapOf(PropTypes.instanceOf(Vendor))
      .isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose))
      .isRequired,
    vendorLists: ImmutablePropTypes.orderedSetOf(
      PropTypes.instanceOf(VendorList)
    ).isRequired,
    vendorListError: PropTypes.instanceOf(VendorListError),
    enabledSitesForScan: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired
    }).isRequired,

    getVendorList: PropTypes.func.isRequired,
    updateVendorList: PropTypes.func.isRequired,
    getGlobalVendors: PropTypes.func.isRequired,
    getGlobalVendorsHash: PropTypes.func.isRequired,
    getIABPurposes: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    createVendorList: PropTypes.func.isRequired,
    clearVendorListError: PropTypes.func.isRequired,
    getSitesEnabledForScan: PropTypes.func.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
    getDnsMessages: PropTypes.func.isRequired
  };

  static defaultProps = {
    vendorListError: null,
    vendorList: null
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const update = {};

    if (
      !prevState.vlPending &&
      nextProps.pendingRequestsMap.get('vendorList')
    ) {
      update.vlPending = true;
    }

    if (
      prevState.vlPending &&
      !nextProps.pendingRequestsMap.get('vendorList')
    ) {
      update.vl = nextProps.vendorList;
      update.allIabAndAutoUpdate = nextProps.vendorList?.allIabAndAutoUpdate;
      update.vlPending = false;
    }

    // update partition set when sites update caused dns messages update
    if (
      nextProps.dnsMessagesBySite &&
      prevState.dnsMessagesBySite !== nextProps.dnsMessagesBySite
    ) {
      const newPSet = updateSitesInPset(
        prevState.vl.partitionSet,
        nextProps.dnsMessagesBySite
      );
      update.vl = prevState.vl.set('partitionSet', newPSet);
      update.dnsMessagesBySite = nextProps.dnsMessagesBySite;
    }

    return update;
  }

  createNewVl = () => {
    const autoNameRegex = /(New Vendor List)($| \([0-9]+\))/;
    const autoNameCount = this.props.vendorLists.filter(vl =>
      vl.name.match(autoNameRegex)
    ).size;
    let vendorList = new VendorList({});
    if (autoNameCount) {
      vendorList = vendorList.set(
        'name',
        `New Vendor List (${autoNameCount + 1})`
      );
    }
    return vendorList;
  };

  getVendorList = () => {
    if (this.props.vendorList) {
      return this.props.vendorList;
    } else {
      return this.createNewVl();
    }
  };

  state = {
    vl: this.getVendorList(),
    addVendorsOpen: false,
    vendorsToAdd: OrderedSet([]),
    saveErrorMessage: null,
    allIabAndAutoUpdate: this.props.vendorList
      ? this.props.vendorList.allIabAndAutoUpdate
      : false,
    scanVendorsOpen: false,
    scanningEnabled: false,
    scanningChosen: false,
    advancedSettingsOpen: false,
    doNotSellSettingsOpen: false,
    originalVendors: null,
    dnsMessagesBySite: null,
    vlPending: false,
    cloneVlModalOpen: false,
    deleteVlModalOpen: false
  };

  componentDidMount() {
    this.props.getGlobalVendors();
    this.props.getGlobalVendorsHash();
    this.props.getIABPurposes();
    this.props.getAllSites();
    if (this.props.location.query.id) {
      this.props.getVendorList(
        this.props.location.query.id,
        this.props.currentUser.accountId
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {

      this.setState({
        scanningEnabled: this.props.currentUser.accountFeatures.includes(
          'vendor_scanning'
        ),
        scanningChosen: this.props.currentUser.accountFeatures.includes(
          'vendor_scanning'
        ),
      });

      this.props.getSitesEnabledForScan(this.props.currentUser.accountId);
      this.props.getSiteGroups(this.props.currentUser.accountId);
      if (this.props.location.query.id) {
        this.props.getVendorList(
          this.props.location.query.id,
          this.props.currentUser.accountId
        );
      }
    }

    // need for redirecting from create route to edit
    if (!prevProps.location.query.id && this.props.location.query.id) {
      this.props.getVendorList(
        this.props.location.query.id,
        this.props.currentUser.accountId
      );
    }

    if (
      prevProps.pendingRequestsMap.get('saveVendorList') &&
      !this.props.pendingRequestsMap.get('saveVendorList')
    ) {
      if (this.props.vendorListError) {
        this.handleServerSideErrors(this.props.vendorListError);
      }
    }

    if (
      (prevProps.vendorList && prevProps.vendorList.siteIds) !==
      (this.props.vendorList && this.props.vendorList.siteIds)
    ) {
      if (this.props.currentUser.accountFeatures.includes('ccpa')) {
        this.props.getDnsMessages(
          this.props.currentUser.accountId,
          this.props.vendorList?.siteIds
        );
      }
    }

    if (prevProps.dnsMessagesBySite !== this.props.dnsMessagesBySite) {
      const newPSet = updateSitesInPset(
        this.state.vl.partitionSet,
        this.props.dnsMessagesBySite
      );
      this.setState({ vl: this.state.vl.set('partitionSet', newPSet) });
    }
  }

  parseServerSideError = data => {
    let messages = new List([]);

    if (data.errorType === 'SITE_VALIDATION_FAILED') {
      data.data.forEach(vl => {
        messages = messages.push(
          `Vendor list "${vl.name}" already applies to ${vl.intersection
            .map(id => this.props.sites.find(s => s.id === id).domain)
            .join(' and ')}.`
        );
      });

      messages = messages.push(
        'Please ensure that each site is only used in one vendor list.'
      );
    } else {
      messages = messages.push('This vendor list is invalid');
    }

    return messages;
  };

  // try to do ids and still searchable by name, name + id
  getVendorByName = name => {
    const vendor = this.props.globalVendors.find(v => v.get('name') === name);
    return vendor;
  };

  handleSelect = names => {
    //TODO try to pass ids instead of names'
    const vendors = List(names).map(n =>
      this.getVendorByName(n.slice(0, n.length / 2))
    );
    this.setState({
      vendorsToAdd: vendors
    });
  };

  handleChange = (e, property) => {
    this.setState({
      vl: this.state.vl.set(property, e.target ? e.target.value : e)
    });
  };

  updateState = obj => {
    this.setState(obj);
  };

  updateStateOfVendorList = vl => {
    this.setState({ vl });
  };

  getMultipleIabPurposeErrorMessage = () => {
    const categories = this.state.vl.categories;

    let errorMessages = new List([]);

    this.props.iabPurposes.forEach(iabP => {
      let purposesMappedToIab = List([]);

      categories.forEach((c, i) => {
        if (c.iabPurposeRefs.includes(iabP.id)) {
          purposesMappedToIab = purposesMappedToIab.push(i);
        }
      });

      if (purposesMappedToIab.size > 1) {
        const message =
          purposesMappedToIab
            .map(idx => `purpose "${categories.getIn([idx, 'name'])}" and `)
            .join('')
            .slice(0, -4) + ` map to the same IAB purpose "${iabP.name}". `;

        errorMessages = errorMessages.push(this.capitalize(message));
      }
    });

    if (errorMessages.size)
      errorMessages = errorMessages.push(
        'Please ensure that each IAB purpose is only mapped once.'
      );

    return errorMessages;
  };

  getNotAllIabPurposesMappedErrorMessage = () => {
    let errorMessages = new List([]);

    const mappedTo = this.state.vl.categories
      .map(c => c.iabPurposeRefs)
      .flatten(true)
      .toSet();

    if (mappedTo.size < this.props.iabPurposes.size) {
      errorMessages = errorMessages.push(
        'No categories map to IAB purpose ' +
          this.props.iabPurposes
            .filterNot(p => mappedTo.includes(p.id))
            .map(p => `${p.name} and `)
            .join('')
            .slice(0, -4)
            .trim() +
          '.'
      );
    }

    if (errorMessages.size)
      errorMessages = errorMessages.push(
        'Please ensure that each IAB purpose is mapped.'
      );

    return errorMessages;
  };

  capitalize = s => {
    return s[0].toUpperCase() + s.slice(1);
  };

  handleSave = () => {
    let errorMessages = new List([]);
    let errorType = '';
    const vl = this.state.vl
      .set('name', this.state.vl.name.trim())
      .set('allIabAndAutoUpdate', this.state.allIabAndAutoUpdate);

    if (
      this.props.vendorLists
        .filterNot(v => v.id === vl.id)
        .some(v => v.name === vl.name)
    ) {
      errorMessages = errorMessages.push(
        `Vendor list with name "${vl.name}" already exists. Please rename this vendor list.`
      );
      errorType = 'name';
    } else if (!vl.name) {
      errorMessages = errorMessages.push(
        'Vendor list has no name. Please name this vendor list.'
      );
      errorType = 'name';
    }

    if(vl.appliesGeos.length === 0 || vl?.appliesGeos === ""){
      errorMessages = errorMessages.push('Please select U.S. Privacy Applies Scope');
    }
    
    // const multipleIabPurposeErrorMessage = this.getMultipleIabPurposeErrorMessage();
    // if (multipleIabPurposeErrorMessage.size) {
    //   errorMessages = errorMessages.push(multipleIabPurposeErrorMessage);
    // }

    // const notAllAibPurposesMappedErrorMessage = this.getNotAllIabPurposesMappedErrorMessage();
    // if (notAllAibPurposesMappedErrorMessage.size) {
    //   errorMessages = errorMessages.push(notAllAibPurposesMappedErrorMessage);
    // }

    if (!vl.vendors.size) {
      errorMessages = errorMessages.push(
        'Vendor list has no vendors. Please add at least one vendor to vendor list.'
      );
    }

    if (errorMessages.size) {
      Modal.warning({
        title: 'Vendor list has not been saved',
        content: displayErrors(errorMessages),
        className: 'save-warning',
        onOk: () => this.handleSaveErrorModalClose(errorType)
      });
      return;
    }

    if (this.props.location.pathname === '/us_privacy/vendor_lists/create') {
      this.props.createVendorList(this.props.currentUser.accountId, vl).then(resp => {
        if(resp){
          browserHistory.replace('/us_privacy/vendor_lists/edit?id=' + resp.id);
          message.success('Your vendor list has been saved.', 5);
          if (resp.isActive) {
            message.success('Vendor list is now active');
          }
        }
      });
    } else {
      const cleanVl = this.removePreviouslyDeletedVendorsFromCategories(vl);
      this.props.updateVendorList(
        this.props.currentUser.accountId,
        cleanVl
      );
    }
  };

  removePreviouslyDeletedVendorsFromCategories = vl => {
    const existingVendorIds = vl.vendors.map(v => v.id);
    const updatedCategories = vl.get('categories').map(category => {
      return category.update('vendorIds', vendorIds =>
        vendorIds.filter(id => existingVendorIds.includes(id))
      );
    });

    return vl.set('categories', updatedCategories);
  };

  handleServerSideErrors = errors => {
 let errorMessages = null;
    if(List.isList(errors) || Array.isArray(errors)){
      errorMessages =  errors?.map(err => {
        if (err.msg) {
          return err.msg;
        } else {
          return this.parseServerSideError(err);
        }
      });
    }
    Modal.warning({
      title: 'Vendor list has not been saved',
      content: displayErrors(errorMessages),
      className: 'save-warning',
      onOk: () =>
        this.handleSaveErrorModalClose(this.props.vendorListError.errorType)
    });
  };

  nameRef = React.createRef();

  handleSaveErrorModalClose = errorType => {
    this.props.clearVendorListError();
  };

  toggleAddVendors = () => {
    this.setState({ addVendorsOpen: !this.state.addVendorsOpen });
  };

  toggleScanVendors = () => {
    this.setState({ scanVendorsOpen: !this.state.scanVendorsOpen });
  };

  toggleAdvancedSettings = () => {
    this.setState({ advancedSettingsOpen: !this.state.advancedSettingsOpen });
  };

  toggleDoNotSellSettings = () => {
    this.setState({ doNotSellSettingsOpen: !this.state.doNotSellSettingsOpen });
  };

  getVendorIdsForPurpose = (purpose, vendors) => {
    const nonIABVendorIds = purpose.vendorIds
      .map(id => this.props.globalVendors.find(v => v.id === id))
      .map(v => v.id);

    return vendors
      .filter(v => {
        const vendorsPurposesIds = v.purposes.map(p =>
          typeof p === 'string' ? p : p.id
        );

        return vendorsPurposesIds.some(id =>
          purpose.iabPurposeRefs.includes(id)
        );
      })
      .map(v => v.id)
      .concat(nonIABVendorIds);
  };

  handleSelectScope = scope => {
    const newVl = this.state.vl.set(
      'consentScope',
      _.invert(CONSENT_SCOPES.toJS())[scope]
    );
    this.setState({
      vl: newVl
    });
  };

  handleLocationSave = (value) => {
    // ccpaApplyLocation =
    //   ccpaApplyLocation != '' ? ccpaApplyLocation.split(',') : List([]);
    // exclusionLocation =
    //   exclusionLocation != '' ? exclusionLocation.split(',') : List([]);

    const newVl = this.state.vl
      .set('appliesGeos', value.appliesGeos)
      .set('exclusionGeos', value.exclusionGeos);
    this.setState({
      vl: newVl
    });
  };

  handleSelectSite = siteIds => {
    if (this.props.currentUser.accountFeatures.includes('ccpa')) {
      this.props.getDnsMessages(
        this.props.currentUser.accountId,
        siteIds
      );
    }
    this.setState({
      vl: this.state.vl.set('siteIds', List(siteIds))
    });
  };

  generateSearchableStringValue(string) {
    if (!string) return '';
    return string + string.toLowerCase();
  }

  toggleIsActive = checked => {
    this.setState({
      vl: this.state.vl.set('isActive', checked)
    });
  };

  toggleShareRootDomain = checked => {
    this.setState({
      vl: this.state.vl.set('shareRootDomain', checked)
    });
  };

  handleCloseScanVendorsModal = () => {
    this.setState({
      scanVendorsOpen: false
    });
  };

  toggleAutoScan = () => {
    this.setState({
      vl: this.state.vl.set(
        'autoUpdateVendorScan',
        !this.state.vl.autoUpdateVendorScan
      )
    });
  };

  removeSite = id => {
    const siteIds = this.state.vl.siteIds.filterNot(idx => idx === id);
    if (this.props.currentUser.accountFeatures.includes('ccpa')) {
      this.props.getDnsMessages(
        this.props.currentUser.accountId,
        siteIds
      );
    }
    this.setState({
      vl: this.state.vl.set('siteIds', siteIds)
    });
  };

  removeSiteByName = site => {
    const siteId = this.props.sites.find(s => s.domain === site).id;
    this.removeSite(siteId);
  };

  getVendorsAndCategoriesForToggleIab = (vl, allIabAndAutoUpdate) => {
    const globalVendors = this.props.globalVendors.filter(
      v => v.vendorType === 'IAB'
    );

    let vendors = vl.vendors;

    if (!allIabAndAutoUpdate) {
      if (!globalVendors.isSubset(vl.vendors)) {
        vendors = vl.vendors.concat(globalVendors);
      }
    }

    if (allIabAndAutoUpdate) {
      if (globalVendors.isSubset(vl.vendors)) {
        vendors = vl.vendors.filterNot(v => v.vendorType === 'IAB');
      }
    }

    let categories;
    if (vl.vendors.size) {
      categories = vl.categories;
    } else {
      categories = this.props.iabPurposes.map(iabP =>
        iabP.set('iabPurposeRefs', List([iabP.id])).set('id', null)
      );
    }

    categories = categories.map(c =>
      c.set('vendorIds', this.getVendorIdsForPurpose(c, vendors))
    );

    return Map({ vendors, categories });
  };

  toggleAllIabAndUpdate = () => {
    const { categories, vendors } = this.getVendorsAndCategoriesForToggleIab(
      this.state.vl,
      this.state.allIabAndAutoUpdate
    );

    this.setState({
      vl: this.state.vl.set('vendors', vendors).set('categories', categories),
      allIabAndAutoUpdate: !this.state.allIabAndAutoUpdate
    });
  };

  updateCustomVendor = (type, purposeId, vendorId, purposeIndex) => {
    let categories = this.state.vl.categories;
    const categoryIndex = purposeId
      ? categories.findIndex(item => item.id === purposeId)
      : purposeIndex;
    let category = categories.get(categoryIndex);

    const vendorIds = category.get('vendorIds');
    const legInts = category.get('customVendorsRelyingOnLegInt');
    if (type === 'consent') {
      category = category.set('vendorIds', vendorIds.add(vendorId));
      category = category.set(
        'customVendorsRelyingOnLegInt',
        legInts.delete(vendorId)
      );
    } else if (type === 'legitimate') {
      category = category.set('vendorIds', vendorIds.add(vendorId));
      category = category.set(
        'customVendorsRelyingOnLegInt',
        legInts.add(vendorId)
      );
    } else {
      category = category.set('vendorIds', vendorIds.delete(vendorId));
      category = category.set(
        'customVendorsRelyingOnLegInt',
        legInts.delete(vendorId)
      );
    }

    categories = categories.set(categoryIndex, category);
    this.setState({
      vl: this.state.vl.set('categories', categories)
    });
  };

  handleAddVendors = (
    scannedVendors = OrderedSet([]),
    scannedSitesIds = List([])
  ) => {
    let categories;
    if (this.state.vl.vendors.size) {
      categories = this.state.vl.categories;
    } else {
      categories = this.props.iabPurposes.map(iabP =>
        iabP.set('iabPurposeRefs', List([iabP.id])).set('id', null)
      );
    }

    const listVendorsWithoutRescanned = this.state.vl.vendors.filterNot(v =>
      scannedVendors.map(sv => sv.id).includes(v.id)
    );
    const allVendors = listVendorsWithoutRescanned
      .concat(this.state.vendorsToAdd)
      .concat(scannedVendors);

    categories = categories.map(c =>
      c.set('vendorIds', this.getVendorIdsForPurpose(c, allVendors))
    );

    const newVendorWrappersForScanned = scannedVendors
      .filter(v => v.isNew)
      .map(
        v => new VendorWrapper({ vendorId: v.id, cookies: v.cookies.toJS() })
      );

    const updatedOldVendorWrappers = this.state.vl.vendorsWrappers.map(vw => {
      if (scannedVendors.map(v => v.id).includes(vw.vendorId)) {
        return vw.set(
          'cookies',
          scannedVendors.find(v => v.id === vw.vendorId).cookies
        );
      } else {
        return vw;
      }
    });

    this.setState({
      vl: this.state.vl
        .set(
          'vendors',
          allVendors.sortBy(v => v.name.toLowerCase()).sortBy(v => v.vendorType)
        )
        .set('categories', categories)
        .set('siteIds', scannedSitesIds.concat(this.state.vl.siteIds).toSet())
        .set(
          'vendorsWrappers',
          updatedOldVendorWrappers.concat(newVendorWrappersForScanned)
        ),
      addVendorsOpen: false,
      scanVendorsOpen: false,
      vendorsToAdd: OrderedSet([])
    });
  };

  handleDelete = (e) => {
    e.stopPropagation();
    let vlName = this.props.vendorList.name;
    this.props.deleteVendorList(this.props.currentUser.accountId, this.props.vendorList.id).then((resp) => {
      if (resp.error) {
        this.handleServerSideErrorsForDelete(resp.error);
      } else {
        message.success(<>Vendor List <b>{vlName}</b> was successfully deleted</>)
        browserHistory.goBack();
      }
      this.setState({ deleteVlModalOpen: false });
    });
  }
  handleServerSideErrorsForDelete = (errors) => {
    // at the moment only expecting PRIVACY_MANAGERS_ASSOCIATED type of error
    const errorMessages = errors.map(err => err.msg);

    Modal.warning({
      title: 'Vendor list cannot be deleted',
      content: displayErrors(errorMessages),
      className: 'save-warning',
      onOk: () => { this.props.clearVendorListError(); },
    });
  }
  handleCopy = (e) => {
    this.setState({cloneVlModalOpen : true})
  }
  onDelete = (e) => {
    this.setState({deleteVlModalOpen : true})
  }
  getVlNameError = (oldVlName, newVlName) => {
    let nameError = null;
    const duplicateName = this.props.vendorLists.map(vl => vl.name).find(vl => vl === newVlName.trim());
    if(newVlName.trim().length === 0){
      nameError = <>Name field can not be empty</>
    }else if(duplicateName && duplicateName !== oldVlName) {
      nameError = <>Vendor List with name '<strong>{newVlName}</strong>' already exists</>
    }
    return nameError; 
  }
  cloneVl = (newVlName) => {
    this.props.cloneVendorList(this.props.currentUser.accountId, this.props.vendorList.id, newVlName).then((vendorList) => {
      if(!!this.props.vendorListError) {
        // message.error(this.props.vendorListError)
        this.handleServerSideErrors(this.props.vendorListError);
      } else {
        browserHistory.replace(`/us_privacy/vendor_lists/edit?id=${vendorList.id}`);
        message.success(<>Vendor List <b>{this.props.vendorList.name}</b> has been successfully cloned</>)
        this.props.getVendorList(
          vendorList.id,
          this.props.currentUser.accountId
        );
      }
    });
    this.setState({cloneVlModalOpen : false})
  }

  render() {
    if (
      this.props.pendingRequestsMap.some(request => request) ||
      !this.state.vl ||
      (this.props.location.pathname === '/consent/vendor_lists/edit' &&
        (!this.props.vendorList ||
          !this.props.vendorList.vendors.first().vendorType))
    ) {
      return <Loading />;
    }

    let readOnly = false;
    if (
      this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('Vendor List - CCPA')
    ) {
      readOnly = true;
    }

    let addVendorsModal;
    if (this.state.addVendorsOpen) {
      addVendorsModal = (
        <AddVendorsModal
          visible={this.state.addVendorsOpen}
          closeModal={() => this.setState({ addVendorsOpen: false })}
          vendors={this.props.globalVendors}
          updateVendorList={this.updateStateOfVendorList}
          vl={this.state.vl}
          iabPurposes={this.props.iabPurposes}
          ccpa
          currentUser={this.props.currentUser}
        />
      );
    }

    let vendorScanModal;
    if (this.state.scanVendorsOpen) {
      vendorScanModal = (
        <VendorScanModal
          vendorListId={this.state.vl.id}
          existingSiteIds={this.state.vl.siteIds.filter(id =>
            this.props.enabledSitesForScan.includes(id)
          )}
          sites={this.props.sites}
          sitesForScan={this.props.sites.filter(s =>
            this.props.enabledSitesForScan.includes(s.id)
          )}
          handleAddVendors={this.handleAddVendors}
          handleCloseModal={this.handleCloseScanVendorsModal}
          toggleAutoScan={this.toggleAutoScan}
          autoScanOn={this.state.vl.autoUpdateVendorScan}
          siteAccess={this.props.currentUser.siteAccess}
        />
      );
    }

    let advancedSettingsModal;
    if (this.state.advancedSettingsOpen) {
      advancedSettingsModal = (
        <AdvancedSettingsModal
          vendorList={this.state.vl}
          closeModal={this.toggleAdvancedSettings}
          visible={this.state.advancedSettingsOpen}
          readOnly={readOnly}
          vendorScanningEnabled={this.state.scanningEnabled}
          getVendorsAndCategoriesForToggleIab={
            this.getVendorsAndCategoriesForToggleIab
          }
          updateParentState={this.updateState}
          allIabAndAutoUpdate={this.state.allIabAndAutoUpdate}
        />
      );
    }

    let doNotSellSettingsModal;
    if (this.state.doNotSellSettingsOpen) {
      doNotSellSettingsModal = (
        <DoNotSellSettingsModal
          vendorList={this.state.vl}
          closeModal={this.toggleDoNotSellSettings}
          visible={this.state.doNotSellSettingsOpen}
          readOnly={readOnly}
          updateParentState={this.updateState}
          accountId={this.props.currentUser.accountId}
          messagesBySite={this.props.dnsMessagesBySite}
          sites={this.props.sites}
        />
      );
    }

    const mainTable = (
      <Table
        vendorList={this.state.vl}
        allVendors={this.props.globalVendorsHash}
        toggleAllIabAndUpdate={this.toggleAllIabAndUpdate}
        allIabAndAutoUpdate={this.state.allIabAndAutoUpdate}
        toggleAddVendors={this.toggleAddVendors}
        vendorScanningEnabled={this.state.scanningEnabled}
        toggleAutoScan={this.toggleAutoScan}
        readOnly={readOnly}
        updateCustomVendor={this.updateCustomVendor}
        iabPurposes={this.props.iabPurposes}
        globalVendors={this.props.globalVendors}
        updateVendorList={this.updateStateOfVendorList}
      />
    );

    let mainContent = mainTable;
    if (
      this.state.vl.vendors.size &&
      !this.state.addVendorsOpen &&
      !this.state.scanVendorsOpen
    ) {
      mainContent = mainTable;
    } else if (!this.state.vl.vendors.size) {
      mainContent = (
        <div className="add-vendors-container">
          <div className="scan-buttons">
            <div
              className="add-vendors-button"
              onClick={this.toggleAddVendors}
              role="presentation"
            >
              <p>+ Add Vendors Manually</p>
            </div>
          </div>
        </div>
      );
    }
    const cloneVlModal = this.state.cloneVlModalOpen
    ? <CloneModal 
        cloneMethod={this.cloneVl}
        name={this.props.vendorList.name}
        closeModal={ () => this.setState({cloneVlModalOpen: false})}
        getNameError={this.getVlNameError}
        entity={"Vendor List"}
        entityType={"Inactive"}
      />
    : null;
    const deleteVlModal = this.state.deleteVlModalOpen
    ?  <DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title={"Vendor List - Delete"}
        error={
        <div className="error-wrapper">
          <div className="message-block">
          Once deleted, all associated data with <b>{this.props.vendorList.name}</b> will be deleted permanently and cannot be retrieved at all.
            <div className="note"> Are you sure you want to <b>DELETE</b> ?</div>
          </div>
        </div>
        }
        isModalVisible={this.state.deleteVlModalOpen}
        handleCancel={() => this.setState({deleteVlModalOpen : false})}
        renderOk
        okText={"Delete"}
        cancelText={"Cancel"}
        handleOk={this.handleDelete}
        primaryAction="submit"
      />
    : null;

    return (
      <div className="privacy-manager-container">
        <Header
          route={this.props.route}
          vl={this.state.vl}
          handleChange={this.handleChange}
          handleLocationSave={this.handleLocationSave}
          handleSelectScope={this.handleSelectScope}
          handleSave={this.handleSave}
          toggleIsActive={this.toggleIsActive}
          toggleShareRootDomain={this.toggleShareRootDomain}
          readOnly={readOnly}
          v2={this.props.route.v2}
          toggleAdvancedSettings={this.toggleAdvancedSettings}
          handleCopy={this.handleCopy}
          onDelete={this.onDelete}
        />

        <ManageSites
          vl={this.state.vl}
          sites={this.props.sites}
          toggleScanVendors={this.toggleScanVendors}
          handleSelectSite={this.handleSelectSite}
          removeSiteByName={this.removeSiteByName}
          toggleDoNotSellSettings={this.toggleDoNotSellSettings}
          readOnly={readOnly}
          siteGroups={this.props.siteGroups}
          siteAccess={this.props.currentUser.siteAccess}
          vendorScanning={this.props.currentUser.accountFeatures.includes(
            'vendor_scanning'
          )}
          dnsButton={this.props.currentUser.accountFeatures.includes(
            'ccpa'
          )}
        />

        {mainContent}

        {vendorScanModal}
        {addVendorsModal}
        {doNotSellSettingsModal}
        {advancedSettingsModal}
        {cloneVlModal}
        {deleteVlModal}
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  const vendorLists = store.vendorListState.getIn(['vendorLists', 'value']);
  const vendorListId = getParameterByName('id');
  const sites = store.siteState.getIn(['sites', 'value']);
  let vendorList;
  if (vendorListId && vendorLists.size && sites.size) {
    vendorList = vendorLists.find(vl => vl.id === vendorListId);
  }

  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    vendorList,
    vendorLists: store.vendorListState.getIn(['vendorLists', 'value']) ?? List([]),
    vendorListError: store.vendorListState.getIn(['vendorLists', 'error']),
    globalVendors: store.vendorListState.getIn(['globalVendors', 'value']) ?? OrderedSet([]),
    globalVendorsHash: store.vendorListState.getIn([
      'globalVendorsHash',
      'value'
    ]),
    iabPurposes: store.vendorListState.getIn(['purposes', 'value']) ?? List([]),
    sites,
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    enabledSitesForScan: store.vendorListState.getIn([
      'enabledSitesForScan',
      'value'
    ]) ?? List([]),
    dnsMessagesBySite: store.dnsState.getIn(['messagesBySite', 'value']),
    pendingRequestsMap: new Map({
      vendorList: store.vendorListState.getIn(['vendorLists', 'pending']),
      saveVendorList: store.vendorListState.get('savePending'),
      globalVendors: store.vendorListState.getIn(['globalVendors', 'pending']),
      globalVendorsHash: store.vendorListState.getIn([
        'globalVendorsHash',
        'pending'
      ]),
      iabPurposes: store.vendorListState.getIn(['purposes', 'pending']),
      sites: store.siteState.getIn(['sites', 'pending']),
      dnsMessagesBySite: store.dnsState.getIn(['messagesBySite', 'pending'])
    })
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getGlobalVendors,
      getGlobalVendorsHash,
      getVendorList,
      getIABPurposes,
      getAllSites,
      updateVendorList,
      createVendorList,
      getVendorLists,
      clearVendorListError,
      getSitesEnabledForScan,
      getSiteGroups,
      getDnsMessages,
      deleteVendorList,
      cloneVendorList
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorListEdit);
