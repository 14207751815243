import React, { useState } from 'react';
import { Radio } from 'antd';

export function usePickerFormat() {
  const [pickerFormat, setPickerFormat] = useState('date');

  return [pickerFormat, setPickerFormat];
}

export const DateFormatDatePicker = 'MMM D, YYYY';

export const customPanelRender = (originalPanel) => {
  return <div className="diagnose-picker-layout">{originalPanel}</div>;
};

const DatePickerFormat = ({ pickerFormat, changePickerFormat }) => {
  const onChangeRadio = (e) => changePickerFormat(e.target.value);
  return (
    <div>
      <Radio.Group onChange={onChangeRadio} defaultValue={pickerFormat} size="small">
        <Radio.Button value="date">Date</Radio.Button>
        {/* <Radio.Button value="week">Week</Radio.Button> */}
        <Radio.Button value="month">Month</Radio.Button>
        {/* <Radio.Button value="quarter">Quarter</Radio.Button> */}
        {/* <Radio.Button value="year">Year</Radio.Button> */}
      </Radio.Group>
    </div>
  );
};

export default DatePickerFormat;
