import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { Tabs } from 'antd';
import {
  getDomainSets,
} from '../../../../actions/domain_set_actions';
import { getAccountOwners } from '../../../../actions/account_actions';
import PropertySetEvolution from './PropertySetEvolution';
import ManualScoring from './ManualScoring';
import SnapshotAndEvolution from './SnapshotAndEvolution';
import InaccuracyReport from './InaccuracyReport';

const { TabPane } = Tabs;

class AccuracyPage extends React.Component {
  static propTypes = {
    getAccountOwners: PropTypes.func.isRequired,
  }

  state = {
    selectedAccount: null,
    selectedSet: null,
    tabKey: localStorage.getItem('activeAccuracyTab') || '1',
  }

  onCurrentUserAvailableDo = async () => {
    if (this.props.currentUser.accountId === 22) this.props.getAccountOwners();
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  updateSearch = (e) => this.setState({ search: e.target.value, currentPage: 1 });

  changeTab = (activeKey) => {
    localStorage.setItem('activeAccuracyTab', activeKey);
    this.setState({ tabKey: activeKey });
  }

  render() {
    return (
      <div className='admin-accuracy-container'>
        <div class="title-privacy-lens">ADMIN<span> - ACCURACY</span></div>

        <Tabs activeKey={ this.state.tabKey } onChange={ this.changeTab }>
          <TabPane tab="Property Set Evaluation" key="1">
            <PropertySetEvolution
              goToManualScoring={ () => this.changeTab("3") }
            />
          </TabPane>
          <TabPane tab="Snapshot & Evolution" key="2">
            <SnapshotAndEvolution />
          </TabPane>
          <TabPane tab="Manual Scoring" key="3">
            <ManualScoring currentUser={this.props.currentUser}/>
          </TabPane>
          <TabPane tab="Inaccuracy Report" key="4">
            <InaccuracyReport />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    allAccounts: store.accountState.getIn(['allAccounts', 'value']),
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    standards: store.standardState.getIn(['standards', 'value']),
    pendingRequestsMap: new Map({
      domainSets: store.domainSetState.getIn(['domainSets', 'pending']),
      standards: store.standardState.getIn(['standards', 'pending']),
      domainSetCreateOrUpdate: store.domainSetState.get('createOrUpdatePending'),
      allAccountsPending: store.accountState.getIn(['allAccounts', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
  getAccountOwners,
  getDomainSets,
},
)(AccuracyPage);
