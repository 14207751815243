import React, { Component } from 'react';
import { Select } from 'antd';

const { Option } = Select;

class DefaultTab extends Component {
  render() {
    const { value, settingsTitle } = this.props;
    return (
      <div className="four-options">
      <h4>{settingsTitle}</h4>
      <div className="option-inputs">
        <Select value={value} style={{ width: '100%' }} onChange={(value) => this.props.updateSetting(this.props.settingKey, value)}>
          <Option value="purposes">Purposes</Option>
          <Option value="vendors">Vendors</Option>
          <Option value="features">Features</Option>
          <Option value="li">Legitimate Interest</Option>
        </Select>
      </div>
    </div>
    );
  }
}

export default DefaultTab;