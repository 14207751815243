import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

export default class EditableStepName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editStepName: false,
      stepName: props.step.get('title') || 'Step ' + (props.index + 1)
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.editStepName) {
      return {
        stepName: nextProps.step.get('title') || 'Step ' + (nextProps.index + 1)
      };
    }
    return null;
  }
  editStepName = () => {
    this.setState({ editStepName: !this.state.editStepName });
  };
  saveStepName = () => {
    this.editStepName();
  };
  stepOnChange = e => {
    this.setState({ stepName: e.target.value });
  };
  render() {
    return (
      <div style={{ flex: 1 }}>
        {this.state.editStepName ? (
          <div className="edit-step-wrapper">
            <Input.Group compact>
              <Input
                value={this.state.stepName}
                defaultValue={`Step ${this.props.index + 1}`}
                onChange={this.stepOnChange}
              />
              <Button icon={<CheckOutlined />} onClick={this.saveStepName}></Button>
              <Button icon={<CloseOutlined />} onClick={this.editStepName}></Button>
            </Input.Group>
          </div>
        ) : (
          <div
          //onClick={this.editStepName}
          >
            {this.state.stepName}
          </div>
        )}
      </div>
    );
  }
}
