import { Select } from "antd"
import React from "react"

const PreferencesPreviewSelect = ({
  siteId,
  preferencesLists,
  updateSetting,
  value,
  settingKey,
}) => {
  siteId = +siteId

  const selected = preferencesLists.find(({ id }) => id === value)
    || (preferencesLists.find(({ propertyIds, isActive }) => (propertyIds.includes((siteId))) && isActive))
    || (preferencesLists.find(({ propertyIds }) => propertyIds.includes(siteId)) ? preferencesLists.find(({ propertyIds }) => propertyIds.includes(siteId)) : null)

  return (
    <div className="four-options">
      <Select
        style={{ width: '100%' }}
        value={selected.id}
        onChange={(id) => updateSetting(settingKey, id)}
      >
        {preferencesLists.filter(({ propertyIds }) => propertyIds.includes(siteId)).map(({ id, name }) => (
          <Select.Option
            key={id}
            id={id}
          >
            {name}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default PreferencesPreviewSelect