import { Record, List } from 'immutable';

export const cssStyles = Record({
  fontFamily: 'Proxima-Nova',
  saveButtonBG: '#1434DE',
  cancelButtonBG: '#FFFFFF',
  enableAllBg: '#FFFFFF',
  rejectAllBg: '#FFFFFF',
  buttonRadius: 20,
  saveText: '#FFFFFF',
  cancelText: '#000000',
  rejectText: '#000000',
  enableAllText: '#000000',
  rejectAllText: '#000000',
  modalRadius: 10,
  customCss: '',
});

export const CategoryView = Record({
  categoryId: null,
  name: '',
  description: '',
});

export class PrivacyManager extends Record({
  id: null,
  name: 'New Privacy Manager',
  accountId: null,
  logoUrl: '',
  title: 'Privacy Manager',
  description: 'Help us provide you with a better web experience. Publishers and partners set cookies and collect information from your browser to provide you with relevant content and advertising that helps better understand their audiences.',
  privacyPolicyText: 'Privacy Policy',
  privacyPolicyUrl: 'https://www.sourcepoint.com',
  enableAllText: 'Accept All',
  rejectAllText: 'Reject All',
  purposeTabText: 'Purposes',
  vendorTabText: 'Site Vendors',
  purposeTabLegIntTitle: 'LEGITIMATE INTEREST',
  purposeTabLegIntDescription: 'These vendors require this purpose to be enabled to provide their service, and it cannot be disabled from the privacy manager. A vendor may allow you to manage your data on their company website.',
  vendorTabPurposeTitle: 'RELYING ON CONSENT FOR',
  vendorTabLegIntPurposeTitle: 'RELYING ON LEGITIMATE INTEREST FOR',
  vendorTabLegIntPurposetDescription: 'This vendor requires these purposes to be enabled to provide their service, and cannot be disabled from the privacy manager. This vendor may allow you to manage your data on their company website.',
  showConsentButtonsBottom: false,
  tabLevelVendorToggle: false,
  showRejectAll: false,
  consentLanguage: 'EN',
  saveAndExitText: 'Save & Exit',
  cancelText: 'Cancel',
  vendorListId: null,
  cssStyles: null,
  hideVendorList: false,
  enableVendorOptIn: true,
  defaultOptedIn: false,

  triStatePurposes: false,
  defaultPurposeSelectionNeutral: null,
  consentToggleText: 'On',
  rejectToggleText: 'Off',

  vendorListTitleText: 'Vendors on site',
  hideVendorListUnderPurpose: false,
  categoryViews: List([]),
  displaySubVendorsUrl: false,
  displayLegInt: false,
  hidePrivacyUrls: false,
  displayFeatures: false
}) {
  constructor(privacyManager) {
    const updatedPrivacyManager = (Object.assign({}, privacyManager));
    updatedPrivacyManager.cssStyles = new cssStyles(privacyManager.cssStyles);
    updatedPrivacyManager.id = privacyManager._id;
    updatedPrivacyManager.vendorListId = privacyManager.vendorList && privacyManager.vendorList._id;
    if (privacyManager.categoryViews) {
      updatedPrivacyManager.categoryViews = List(privacyManager.categoryViews.map(cv => new CategoryView(cv)));
    }

    super(updatedPrivacyManager);
  }
}

export class SitePrivacyManager extends Record({
  id: null,
  siteId: null,
  siteGroupId: null,
  name: 'New Privacy Manager',
}) {
  constructor(sitePrivacyManager) {
    const updatedSitePrivacyManager = (Object.assign({}, sitePrivacyManager));
    updatedSitePrivacyManager.id = sitePrivacyManager._id;
    super(updatedSitePrivacyManager);
  }
  equals(otherSitePrivacyManagerRecord) {
    return (this.id === otherSitePrivacyManagerRecord.id
    && this.siteGroupIG === otherSitePrivacyManagerRecord.siteGroupIG
    && this.siteId === otherSitePrivacyManagerRecord.siteId);
  }
  hashCode() {
    //using this.id causes error
    //using the same hashCode is a hack but equals methoud is enought to build a correct Set
    return 1;
  }
}
