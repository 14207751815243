import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
    static propTypes = {
        hover: PropTypes.string.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = { className: "" };

    render() {

        var className = ("hovertip " + this.props.className).trim()

        return (
            <span className={ className } data-tip={ this.props.hover }>{ this.props.children }</span>
        );
    }
}
