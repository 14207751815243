import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { DownloadOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Divider, Table, Tooltip } from 'antd';
import { browserHistory } from 'react-router';

import {
  PrevalenceColumn,
  TechnologyCategorisationColumn,
  VendorDetailsColumn,
  VendorNameColumn,
  VendorReferenceColumn,
  VendorStatusColumn,
  VendorTypeColumn,
} from '../../shared/DiagnoseColumns';

import {
  getPossibleFingerprintingFilters,
  getPossibleFingerprintingList,
  getVendorManagementStatusesList,
  possibleFingerprintingExport,
} from '../../../../../actions/diagnose_dashboard_actions';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import {
  getUniqueVendorIdsList,
  isFiltersProvided,
  mapSelectedFilters,
  requestDataDependOnTableFilters,
} from '../helpers';

const DetailedBreakdown = ({
  possibleFingerprintingExport,
  getPossibleFingerprintingList,
  possibleFingerprintingList,
  possibleFingerprintingListPending,
  possibleFingerprintingFilter,
  getPossibleFingerprintingFilters,
  selectedFilters,
  possibleFingerprintingExportPending,
  userDetails,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const [tableFilters, setTableFilters] = useState(null);
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');

  const getPossibleFingerprintingListData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getPossibleFingerprintingList({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
    return res;
  };

  const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
    const query = {
      metric: 'Possible Fingerprinting',
      vendors,
      status,
      tag,
      websites: selectedFilters?.websites,
    };
    const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
    if (isCacheValid) {
      return vendorManagementStatuses;
    }
    const res = await getVendorManagementStatusesList(query);
    return res;
  };

  const getPossibleFingerprintingFiltersData = () => {
    getPossibleFingerprintingFilters(filters);
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      requestDataDependOnTableFilters(
        { filters: tableFilters || {}, isEnabledVendorStatusManagementFeature },
        (f, vendorId) => getPossibleFingerprintingListData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
        getVendorManagementStatusesListData,
      );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      getPossibleFingerprintingFiltersData();
    }
  }, [filters]);

  useEffect(() => {
    if (vendorManagementActivities?.length) {
      getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsList(possibleFingerprintingList?.results) }, false);
    }
  }, [vendorManagementActivities]);

  const vendorsFilter = possibleFingerprintingFilter?.vendors?.map((filter) => ({ text: filter, value: filter }));
  const technologiesFilter = possibleFingerprintingFilter?.technologies?.map((filter) => ({
    text: filter,
    value: filter,
  }));
  const referenceVendorsFilter = possibleFingerprintingFilter?.reference_vendors?.map((filter) => ({
    text: filter,
    value: filter,
  }));

  const columns = [
    VendorTypeColumn({}),
    VendorNameColumn({ filters: vendorsFilter || [] }),
    ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    TechnologyCategorisationColumn({ filters: technologiesFilter || [] }),
    {
      title: () => {
        return (
          <div>
            Registered for Special Feature 2
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    Special Feature 2 on the IAB TCF is “Actively scan device characteristics for identification”. The
                    information we collect to determine whether a vendor is possibly fingerprinting directly corresponds
                    to the information mentioned in the IAB TCF under this special feature. Publishers can choose
                    whether or not to include this in their CMPs and vendors should only be using this feature when a
                    user opts in on the CMP.
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'use_geo_data',
      key: 'use_geo_data',
      sorter: (a, b) => a.name.localeCompare(b.name),
      // render: (text) => (<div> {text ? 'Yes' : 'No'} </div>),
    },
    PrevalenceColumn,
    VendorReferenceColumn({ filters: referenceVendorsFilter || [] }),
    VendorDetailsColumn({ metric: 'Possible Fingerprinting' }),
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const tableData = useMemo(() => {
    if (!isEnabledVendorStatusManagementFeature) {
      return possibleFingerprintingList?.results;
    }

    return possibleFingerprintingList?.results?.map((row) => {
      const rowWithVendorStatus = {
        ...row,
        vendor_status:
          vendorManagementStatuses?.filter((vendorStatus) => {
            return vendorStatus.vendor === row.vendor_id;
          }) || [],
      };
      return rowWithVendorStatus;
    });
  }, [isEnabledVendorStatusManagementFeature, possibleFingerprintingList?.results, vendorManagementStatuses]);

  const onPaginationChange = (pageNumber, pageSize) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    possibleFingerprintingExport(filters);
  };

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The detailed breakdown allows you to identify vendors observed on your property that are accessing information
        that could be used for fingerprinting
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container">
        <div className={'filters_block'}>
          <div className={'quick_filters'} />
          <div className={'downloadCSV'}>
            <Button icon={<DownloadOutlined />} onClick={exportCsv} loading={possibleFingerprintingExportPending} />
          </div>
        </div>
        <Table
          id="detailed-breakdown"
          scroll={{ x: 'max-content', y: 430 }}
          dataSource={tableData}
          columns={columns}
          showSorterTooltip={false}
          rowKey={(record) => record?.vendor_id}
          rowClassName={(record) =>
            record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
              ? 'diagnose-notification-highlight'
              : ''
          }
          pagination={{
            current: page,
            pageSize: limit,
            total: tableFilters?.vendor_status?.length && !vendorManagementStatuses.length ? 0 : possibleFingerprintingList?.total_count,
            position: ['bottomCenter'],
            onChange: onPaginationChange,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
          }}
          onChange={onTableChange}
          loading={{
            className: 'diagnose-spinning',
            spinning: possibleFingerprintingListPending || vendorManagementStatusesPending,
            tip: possibleFingerprintingList?.results?.length
              ? ''
              : 'Processing a large query; please wait or leave it open and check later.',
            size: 'large',
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    possibleFingerprintingList: store.diagnoseDashboardState.getIn(['possibleFingerprintingList', 'value']),
    possibleFingerprintingListPending: store.diagnoseDashboardState.get('possibleFingerprintingListPending'),
    possibleFingerprintingExportPending: store.diagnoseDashboardState.get('possibleFingerprintingExportPending'),
    possibleFingerprintingFilter: store.diagnoseDashboardState.getIn(['possibleFingerprintingFilter', 'value']),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
    vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPossibleFingerprintingList,
      getPossibleFingerprintingFilters,
      possibleFingerprintingExport,
      getVendorManagementStatusesList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
