import React, { useState, useEffect } from 'react';

import { useActions } from '../../../hooks';
import { browserHistory } from 'react-router';

import { Tabs } from 'antd';

import { 
  getGoldenSets as getGoldenSetsAction,
} from '../../../actions/golden_set_actions';

const { TabPane } = Tabs;

const GoldenScoreTabs = () => {
  const [getGoldenSets] = useActions([getGoldenSetsAction]);
  useEffect(() => {
    getGoldenSets();
  }, []);

  const initialActiveKey = (window.location.pathname.includes('rule_performance') || window.location.pathname.includes('golden_set_comparison')) ? '2' : '1';

  const reroute = (tabKey) => {
    if (tabKey === '1') {
      browserHistory.push('/privacy_lens/golden_score/');
    } else {
      browserHistory.push('/privacy_lens/golden_score/rule_performance');
    }
  };

  return (
    <div className='golden-set'>
      <Tabs defaultActiveKey={ initialActiveKey } onChange={ (tabKey) => reroute(tabKey)}>
        <TabPane tab="Manage Golden Sets" key="1">
        </TabPane>
        <TabPane tab="Performance" key="2">
        </TabPane>
      </Tabs>
    </div>
  );
}

export default GoldenScoreTabs;