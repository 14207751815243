import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    };

    render() {
        return (
            <div>
              <div className="campaign-summary-number">{ this.props.value }</div>
              <div className="campaign-summary-description">{ this.props.description }</div>
            </div>
        )
    }
}
