import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button } from 'antd';
import { VendorList } from '../../../records/vendor_list_records';
import { Partition as PartitionRecord, SiteToMessageMap } from '../../../records/dns_records';
import Partition from './Partition';

import { connect } from 'react-redux';
import { getDnsMessages } from '../../../actions/dns_actions';
import { List } from 'immutable';

export default class DoNotSellSettingsModal extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    vendorScanningEnabled: PropTypes.bool.isRequired,
    getVendorsAndCategoriesForToggleIab: PropTypes.func.isRequired,
    updateParentState: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const update = {};

    if (nextProps.vl !== prevState.vl) {
      update.vl = nextProps.vendorList;
    }

    return update;
  }

  state = {
    vl: this.props.vendorList,
    showWarning: false,
  }

  updatePartition = (i, partition) => {
    const updatedPartitions = this.state.vl.partitionSet.partitions.set(i, partition);

    this.props.updateParentState({
      vl: this.state.vl.set('partitionSet', this.state.vl.partitionSet.set('partitions', updatedPartitions)),
    });
  }

  handleCancelModalChanges = () => {
    this.setState({ vl: this.props.vendorList });
    this.props.closeModal();
  }

  handleOnOk = () => {
    this.props.updateParentState({
      vl: this.state.vl,
    });
    this.props.closeModal();
  }

  addPartition = () => {
    const allPartitionsWeight = this.props.vendorList.partitionSet.partitions.map(p => p.weight).reduce((total, num) => (total + num), 0);
    let siteToMessageMapList = new List([]);
    // new List([113, 221, 1]).forEach(siteId => {
    //XXX stage test
    this.state.vl.siteIds.forEach(siteId => {
      const msg = this.props.messagesBySite.find(obj => obj.siteId === siteId).messages.first();
      if (msg) {
        const siteToMessageMap = new SiteToMessageMap({
          siteId,
          messageId: msg.id,
          messageSettings: msg.messageSettings,
        });
  
        siteToMessageMapList = siteToMessageMapList.push(siteToMessageMap);
      }
    })

    const newPartition = new PartitionRecord({
      weight: 100 - allPartitionsWeight, 
      siteToMessageMap: siteToMessageMapList,
    })

    const updatedPartitions = this.state.vl.partitionSet.partitions.push(newPartition);

    this.props.updateParentState({
      vl: this.state.vl.set('partitionSet', this.state.vl.partitionSet.set('partitions', updatedPartitions)),
    });
  }

  render() {    
    return (
      <Modal
        visible
        onOk={ this.handleOnOk }
        onCancel={ this.handleCancelModalChanges }
        okText='Apply changes'
        className='do-not-sell-modal'
        closable={ false }
        destroyOnClose
      >
        <p className='title'>Do Not Sell Settings</p>
        <div className='partitions'>
          {
            this.state.vl.partitionSet.partitions.map((p, i) => (
              <div className='dns-partition' key={i}>
                <p className='partition-title'>{ `Partition ${i + 1}` }</p>
                <Partition 
                  partition={ p } 
                  vendorList={ this.state.vl }
                  messagesBySite={ this.props.messagesBySite }
                  updatePartition={ (partition) => this.updatePartition(i, partition)}
                  sites={ this.props.sites }
                />
              </div>
            ))
          }
          <Button onClick={ this.addPartition }>Add Partition</Button>
        </div>
      </Modal>
    );
  }
}

