import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Tag, Tooltip } from 'antd';

const CellContentWithPrimaryTag = ({ className, content = '', tooltipContent = '', tooltipTitle }) => {
  const contentRef = useRef(null);
  const [isContentLong, setIsContentLong] = useState(false);

  const cellView = (
    <div className={`${className}-content`} ref={contentRef}>
      {content}
    </div>
  );

  useEffect(() => {
    setIsContentLong(contentRef?.current?.offsetWidth < contentRef?.current?.scrollWidth);
  }, [contentRef.current]);

  return (
    <div className={className}>
      {!tooltipContent && isContentLong ? (
        <Tooltip
          placement="right"
          autoAdjustOverflow
          title={() => {
            return <div className="detailed-breakdown-cell-tooltip-content">{content}</div>;
          }}
          overlayClassName="detailed-breakdown-cell-tooltip-overlay"
        >
          {cellView}
        </Tooltip>
      ) : (
        cellView
      )}
      {tooltipContent ? (
        <Tooltip
          placement="right"
          autoAdjustOverflow
          title={() => {
            return <div className="detailed-breakdown-cell-tooltip-content">{`${content}; ${tooltipContent}`}</div>;
          }}
          overlayClassName="detailed-breakdown-cell-tooltip-overlay"
        >
          <Tag className={'primary'}>{tooltipTitle}</Tag>
        </Tooltip>
      ) : null}
    </div>
  );
};

CellContentWithPrimaryTag.propTypes = {
  className: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string,
  content: PropTypes.string,
};

CellContentWithPrimaryTag.defaultProps = {
  content: '',
  tooltipContent: '',
};

export default CellContentWithPrimaryTag;
