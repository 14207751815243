import React, { useEffect, useState, useMemo } from "react";
import RegionSelectorTree from "./RegionSelectorTree";
import { region_title_index_map } from "../../../constants";
import { Select, Button } from "../../../styleguide";
import { Divider } from "antd";
import { findCommonElementsInTwoArrays, arraySubtraction } from "../../utils";

const RegionSelectorTreeSelect = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState(props.selectedCodes);
  const [geoScope, setGeoScope] = useState({
    appliesGeos :  [],
    exclusionGeos :  []
  });
  const [codesForUI, setCodesForUI] = useState([])

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const customlabel = region_title_index_map[value];
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <span
        className="ant-select-selection-item"
        onMouseDown={onPreventMouseDown}
      >
        {customlabel}
      </span>
    );
  };

  const handleOnApply = () => {
    props.setSelectedCodes(selectedCodes);
    setIsDropdownOpen(false);
  };
  
  const handleOnCancel = () => {
    setSelectedCodes(props.selectedCodes);
    setIsDropdownOpen(false);
  };

  useEffect(()=>{
    setSelectedCodes(props.selectedCodes)
  },[isDropdownOpen]);

  useEffect(()=>{
    let geoScopesCopy = {
      appliesGeos :  [],
      exclusionGeos :  []
    }
    let codesForUI = [];

    props.treeOptions.forEach( (option) => {
      const childCodes = option.children.map( c => c.code);
      const selectedChildCodes =findCommonElementsInTwoArrays(childCodes, props.selectedCodes);
      if(selectedChildCodes.length > (childCodes.length / 2)) {
        geoScopesCopy.appliesGeos = geoScopesCopy.appliesGeos.filter(geo => !selectedChildCodes.includes(geo))
        geoScopesCopy.appliesGeos.push(option.key)
        geoScopesCopy.exclusionGeos = [...new Set(geoScopesCopy.exclusionGeos.concat(arraySubtraction(childCodes,props.selectedCodes)))]
      } else {
        geoScopesCopy.appliesGeos = [...new Set(geoScopesCopy.appliesGeos.concat(selectedChildCodes))]
        geoScopesCopy.exclusionGeos = geoScopesCopy.exclusionGeos.filter(geo => !selectedChildCodes.includes(geo))
      }
    })
    setGeoScope(geoScopesCopy);

    if(props.treeOptions.every(option => geoScopesCopy.appliesGeos.includes(option.key)) && geoScopesCopy.exclusionGeos.length == 0){
      codesForUI = ['ALL']
    } else {
      props.treeOptions.forEach(option => {
        const chidCodes = option.children.map(c => c.code)
        if(geoScopesCopy.appliesGeos.includes(option.key) && findCommonElementsInTwoArrays(chidCodes, geoScopesCopy.exclusionGeos).length == 0){
          codesForUI.unshift(option.key)
        } else {
          codesForUI = codesForUI.concat(findCommonElementsInTwoArrays(chidCodes, selectedCodes))
        }
      })
    }
    setCodesForUI(codesForUI)
  },[props.selectedCodes, props.treeOptions])

  return (
    <Select
      style={{ width: "100%" }}
      placement="bottomLeft"
      className="regions-select spsg-select-multiple"
      dropdownClassName="regions-select"
      value={codesForUI}
      open={isDropdownOpen}
      mode="multiple"
      onDropdownVisibleChange={setIsDropdownOpen}
      maxTagPlaceholder={(omitted) => `+${omitted.length}...`}
      maxTagCount={2}
      tagRender={tagRender}
      showArrow
      dropdownRender={() => (
        <>
          <RegionSelectorTree
            isIncludeExcludeStructure={false}
            setGeoScope={setGeoScope}
            appliesGeos={[]}
            exclusionGeos={[]}
            updatedCodes={() => {}}
            codes={[]}
            selectedCodes={selectedCodes ?? []}
            setSelectedCodes={setSelectedCodes}
            entity="regions"
            treeOptions={props.treeOptions}
          />
          <Divider />
          <div className="regions-select-dropdown-footer">
            <Button onClick={handleOnCancel} className="cancel">Cancel</Button>
            <Button onClick={handleOnApply} type="primary" disabled={selectedCodes.length == 0}>Apply</Button>
          </div>
        </>
      )}
    />
  )
}

export default RegionSelectorTreeSelect;