import React from 'react';
import { Tag, Popover } from 'antd';

const SiteColumnContent = (sites) => {
  const tags = sites.map(tag => (
    <span className="changelog-tag-container-portal">
      <Tag color="blue">
        {tag}
      </Tag>
    </span>
  ))
  const popoverContent = (
    <div className="changelog-popover-content-portal">
      {tags}
    </div>
  );

  return (
    <span data-test="site-column">
      {tags.length > 4
        ? (
          <Popover
            content={popoverContent}
            trigger="hover"
          >
            {tags.slice(0, 4)}
            <br />
            {`...and ${tags.length - 4} more`}
          </Popover>
        )
        : tags}
    </span>
  )
}

export default SiteColumnContent;