import React from "react";
import { Table, Collapse } from "antd";
import { Map, List } from "immutable";
import moment from 'moment';
import RecentChangesModal from "./RecentChangesModal";
import ExportToolTip from "../../ccpa/dataprivacy/ExportToolTip";

import {
  ResponsiveContainer,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend
} from "recharts";
import {
  LINE_GRAPH_COLOR_LIST,
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST,
  LINE_GRAPH_LEGEND_PATTERN_LIST
} from "../../../constants";
import {
  absDataFormater,
  percentDataFormater,
  createCSVExportData
} from "./helper";
import CustomPopover from "../../common/CustomPopover";
import { propertyTooltipContent } from "./intructionalText";

const { Panel } = Collapse;

export default class ConsentPerformanceGraph extends React.PureComponent {
  state = {
    recentChangesModal: false,
    lineToggle: Map({
      totalpageviews: true,
      messagedusers: false,
      fullconsent: true,
      partialconsent: false,
      rejectedall: false
    }),
    propertyTableData: List(),
    rechartMargin: { top: 5, right: 30, left: 20, bottom: 15 },
    tooltipVisibility: "visible",
    proValConvert: "number"
  };

  toggleLine = tKey => {
    let toggles = this.state.lineToggle;
    const inverse = !toggles.get(tKey);
    toggles = toggles.set(tKey, inverse);
    this.setState({ lineToggle: toggles });
  };

  setGraphType = graphType => {
    this.setState({ graphType });
  };

  itemRender = (current, type, originalElement) => {
    return originalElement;
  };
  showModal = () => {
    this.setState({
      recentChangesModal: true
    });
  };
  handleCancel = e => {
    this.setState({
      recentChangesModal: false
    });
  };
  capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  renderTooltip = props => {
    let propertySiteIds;
    let propertyDate;
    let logData;
    let tooltipData;
    if (props.payload && props.payload.length > 0 && props.payload[0]) {
      let arrList = [];
      props.payload.map((item, i) => {
        arrList = arrList.concat(item.payload["siteIds" + i]);
      });
      let uniqueArr = [...new Set(arrList)];
      propertySiteIds = uniqueArr;
      propertyDate = props.payload[0].payload.date;
      logData = this.props.changeLogData.filter((item, i) => {
        var common = item
          .get("siteIds")
          .filter(element => propertySiteIds.includes(element));
        return (
          item.get("date") ==
            moment(propertyDate, "MMM Do").format("YYYY-MM-DD") &&
          common.length > 0
        );
      });
      tooltipData = logData.get(0);
    }

    if (tooltipData) {
      return (
        <div className="tooltip-custom">
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Change Type : </div>
            <span>{this.capitalize(tooltipData.get("operationtype"))}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Date : </div>
            <span> {tooltipData.get("date")} </span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Entity Type : </div>
            <span>{tooltipData.get("entitytype")}</span>{" "}
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Entity : </div>
            <span>{tooltipData.get("entitynames")}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Properties: </div>
            <span>{tooltipData.get("properties")}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">User : </div>
            <span>{tooltipData.get("user")}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  showTooltip = () => {
    return (
      <Tooltip
        content={this.renderTooltip}
        filterNull={true}
        active={false}
        cursor={false}
      />
    );
  };

  drawGraph = graphData => {
    let lineArr = [];
    graphData.map((d, j) => {
      if (this.state.lineToggle.get("totalpageviews")) {
        lineArr.push(
          <Line
            key={"t-" + j}
            dot={false}
            yAxisId={
              this.state.lineToggle.get("messagedusers") ||
              this.state.lineToggle.get("fullconsent") ||
              this.state.lineToggle.get("partialconsent") ||
              this.state.lineToggle.get("rejectedall")
                ? "right"
                : "left"
            }
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            // activeDot={{ onClick:this.showModal }}
            dataKey={"totalpageviews" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(0)}
          />
        );
      }
      if (this.state.lineToggle.get("messagedusers")) {
        lineArr.push(
          <Line
            key={"a-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            // activeDot={{ onClick:this.showModal }}
            dataKey={"messagedusers" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(1)}
          />
        );
      }
      if (this.state.lineToggle.get("fullconsent")) {
        lineArr.push(
          <Line
            key={"s-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            // activeDot={{ onClick:this.showModal }}
            dataKey={"fullconsent" + j}
            stroke={LINE_GRAPH_COLOR_LIST.get(2)}
          />
        );
      }
      if (this.state.lineToggle.get("partialconsent")) {
        lineArr.push(
          <Line
            key={"n-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"partialconsent" + j}
            // activeDot={{ onClick:this.showModal }}
            stroke={LINE_GRAPH_COLOR_LIST.get(3)}
          />
        );
      }
      if (this.state.lineToggle.get("rejectedall")) {
        lineArr.push(
          <Line
            key={"r-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"rejectedall" + j}
            // activeDot={{ onClick:this.showModal }}
            stroke={LINE_GRAPH_COLOR_LIST.get(4)}
          />
        );
      }
    });
    return lineArr;
  };
  setPropertyValConvert = value => {
    this.setState({ proValConvert: value });
  };
  getPropertyName = siteId => {
    let indexGroup = this.props.siteGroups.findIndex(item => {
      return item.get("siteId") === siteId;
    });
    let index = this.props.sites.findIndex(item => {
      return item.get("id") === siteId;
    });
    let value =
      indexGroup >= 0
        ? this.props.siteGroups.getIn([indexGroup, "name"])
        : this.props.sites.getIn([index, "domain"]);
    return value;
  };
  render() {
    var tableData = this.props.consentPropertyData.map(items =>
      items.get("propertyTableData")
    );
    var graphData = this.props.consentPropertyData.map(items =>
      items.get("propertyGraph")
    );

    let graphList = List();
    let unionList = List();
    graphData.map(g => {
      unionList = unionList
        .toSet()
        .union(g.toSet())
        .toList();
    });

    let groupedByDates = unionList.groupBy(dataPoint => dataPoint.get("date"));
    groupedByDates.map(item => {
      let mergeMap = Map();
      item.map(m => {
        mergeMap = mergeMap.merge(m);
      });
      graphList = graphList.push(mergeMap);
    });
    graphList = graphList.sort(
      (a, b) =>
        moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
    );

    let graphTitle = graphList.size
      ? moment(graphList.first().get("date")).format("MMMM YYYY")
      : "";
    const renderLegend = () => {
      return (
        <React.Fragment>
          {graphData.map((entry, index) => (
            <div className="legend-custom" key={`legend-${index}`}>
              <svg height="5" width="28">
                <line
                  x1="0"
                  y1="0"
                  x2="200"
                  y2="0"
                  style={{
                    stroke: "#555758",
                    strokeWidth: "5",
                    strokeDasharray: LINE_GRAPH_STROKE_PATTERN_LIST.get(index)
                  }}
                />
              </svg>
              {` Criteria ${index + 1}`}
            </div>
          ))}
        </React.Fragment>
      );
    };

    const tableTitle = (
      <div className="table-title-container">
        <div className="table-title">
          Consent Performance Details{" "}
          <CustomPopover tooltipContent={propertyTooltipContent} />
        </div>
        <div className="table-title-convert">
          <button
            className={this.state.proValConvert == "number" ? "active" : ""}
            onClick={() => this.setPropertyValConvert("number")}
          >
            Number
          </button>
          <button
            className={this.state.proValConvert == "percentage" ? "active" : ""}
            onClick={() => this.setPropertyValConvert("percentage")}
          >
            Percentage
          </button>
        </div>
      </div>
    );

    const columns = List([
      Map({
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) =>
          moment(a.date, "YYYY-MM-DD").valueOf() -
          moment(b.date, "YYYY-MM-DD").valueOf(),
        className: "left-align",
        width: "10%",
        defaultSortOrder: "descend"
      }),
      Map({
        title: "Total Views",
        dataIndex: "totalpageviews",
        sorter: (a, b) => a.totalpageviews - b.totalpageviews,
        className: "right-align",
        width: "20%",
        render: text => <span>{text.toLocaleString()}</span>
      }),
      Map({
        title: "Messaged Users",
        dataIndex: "messagedusers",
        sorter: (a, b) => a.messagedusers - b.messagedusers,
        className: "right-align",
        width: "20%",
        render: text => <span>{text.toLocaleString()}</span>
      }),
      Map({
        title: "Full Consent",
        dataIndex: "fullconsent",
        sorter: (a, b) => a.fullconsent - b.fullconsent,
        className: "right-align",
        width: "15%",
        render: text =>
          this.state.proValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%"
      }),
      Map({
        title: "Partial Consent",
        dataIndex: "partialconsent",
        sorter: (a, b) => a.partialconsent - b.partialconsent,
        className: "right-align",
        width: "20%",
        render: text =>
          this.state.proValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%"
      }),
      Map({
        title: "Reject All",
        dataIndex: "rejectedall",
        sorter: (a, b) => a.rejectedall - b.rejectedall,
        className: "right-align",
        width: "15%",
        render: text =>
          this.state.proValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%"
      })
    ]);
    let propertyHeaders = columns
      .map(item =>
        Map({ label: item.get("title"), key: item.get("dataIndex") })
      )
      .insert(1, Map({ label: "Property/Property Group", key: "propertyname" }))
      .insert(5, Map({ label: "Full consent %", key: "fullconsentper" }))
      .insert(7, Map({ label: "Partial Consent %", key: "partialconsentper" }))
      .insert(9, Map({ label: "Reject All %", key: "rejectedallper" }));
    return (
      <React.Fragment>
        <div className="property-tabs">
          <div
            className={`property-params ${
              this.state.lineToggle.get("totalpageviews")
                ? "toggle-line1-active"
                : "toggle-line1"
            }`}
            onClick={() => this.toggleLine("totalpageviews")}
          >
            Total Volume
          </div>
          <div
            className={`property-params ${
              this.state.lineToggle.get("messagedusers")
                ? "toggle-line2-active"
                : "toggle-line2"
            }`}
            onClick={() => this.toggleLine("messagedusers")}
          >
            Messaged Users
          </div>
          <div
            className={`property-params ${
              this.state.lineToggle.get("fullconsent")
                ? "toggle-line3-active"
                : "toggle-line3"
            }`}
            onClick={() => this.toggleLine("fullconsent")}
          >
            Full consent
          </div>
          <div
            className={`property-params ${
              this.state.lineToggle.get("partialconsent")
                ? "toggle-line4-active"
                : "toggle-line4"
            }`}
            onClick={() => this.toggleLine("partialconsent")}
          >
            Partial Consent
          </div>
          <div
            className={`property-params ${
              this.state.lineToggle.get("rejectedall")
                ? "toggle-line5-active"
                : "toggle-line5"
            }`}
            onClick={() => this.toggleLine("rejectedall")}
          >
            Reject All
          </div>
          <div className="instructionalText">
            <CustomPopover tooltipContent={propertyTooltipContent} />
          </div>
          <div className="property-action">
            <a onClick={this.showModal}>
              View Recent Changes{" "}
              <span className="glyphicon glyphicon-menu-right consent-right-arrow"></span>
            </a>
          </div>
        </div>
        <div className="property-title-container">
          <div className="graph-desc-container">
            <span className="property-title">Consent Performance</span>
            <br />
            <span className="property-desc">
              Trend over time, can be viewed on monthly or weekly basis.
            </span>
          </div>
        </div>

        <ResponsiveContainer width="99%" height={395}>
          <LineChart
            width={730}
            height={250}
            data={graphList.toJS()}
            margin={this.state.rechartMargin}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="fancyDate">
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              type="number"
              yAxisId="left"
              domain={[0, "dataMax"]}
              tickFormatter={
                this.state.lineToggle.get("messagedusers") ||
                this.state.lineToggle.get("fullconsent") ||
                this.state.lineToggle.get("partialconsent") ||
                this.state.lineToggle.get("rejectedall")
                  ? percentDataFormater
                  : absDataFormater
              }
            />
            <YAxis
              type="number"
              yAxisId="right"
              orientation="right"
              domain={[0, "dataMax"]}
              tickFormatter={absDataFormater}
            />
            {this.showTooltip()}
            {/* <ReferenceLine x={moment().format('MMM Do')} stroke="#000" isFront={true} label="Today" strokeDasharray="1 1" /> */}
            <Legend verticalAlign="top" content={renderLegend} />
            {this.drawGraph(graphData)}
          </LineChart>
        </ResponsiveContainer>

        {tableTitle}
        <div className="collapsibleTable">
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            {tableData.map((item, i) => {
              const exportData = item.get("exportdata").map(e => {
                let exp = e.set(
                  "propertyname",
                  this.getPropertyName(e.get("siteid"))
                );
                return exp;
              });
              let percentage = item.get("aggData").map(c => {
                let n = c;
                n = c
                  .set(
                    "fullconsent",
                    Number(
                      (c.get("fullconsent") / c.get("totalpageviews")) * 100
                    )
                  )
                  .set(
                    "partialconsent",
                    Number(
                      (c.get("partialconsent") / c.get("totalpageviews")) * 100
                    )
                  )
                  .set(
                    "rejectedall",
                    Number(
                      (c.get("rejectedall") / c.get("totalpageviews")) * 100
                    )
                  );
                return n;
              });
              let data =
                this.state.proValConvert == "number"
                  ? item.get("aggData").toJS()
                  : percentage.toJS();
              const headerCustom = (
                <React.Fragment>
                  {item.get("date")}
                  <ExportToolTip
                    filename="property"
                    selectedTabIndex={i + 1}
                    exportData={createCSVExportData(
                      propertyHeaders,
                      exportData
                    ).toJS()}
                    criterialist={this.props.criteriaList}
                    sites={this.props.sites}
                    pdfTitle={"Consent Performance Details"}
                  />
                </React.Fragment>
              );
              return (
                <Panel header={headerCustom} key={i + 1}>
                  <Table
                    columns={columns.toJS()}
                    dataSource={data}
                    pagination={{
                      itemRender: this.itemRender,
                      size: "small",
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                      locale: { items_per_page: "" }
                    }}
                    className="consnet-table"
                    rowKey={r => r.key}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>

        <RecentChangesModal
          changeLogData={this.props.changeLogData}
          recentChangesModal={this.state.recentChangesModal}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    );
  }
}
