import React, { useState, useEffect, useMemo } from 'react';
import { Tabs, Button, Input, Alert } from '../../../../../../styleguide';
import { Modal, Form } from 'antd';
import { DownloadOutlined, PlusOutlined, SearchOutlined, WarningFilled, CloseCircleFilled, EditFilled,ExclamationCircleFilled } from '@ant-design/icons';
import VendorCookieUpload from './VendorCookieUpload';
import SelectedView from './SelectedViewContainer';
import CookieTable from './CookieTable';
import { isArray } from 'lodash';
import CookieForm from './CookieForm';
import { getVendorTypeIcon, flattenCategories, calculateCookieAddedVia, isGoogleAdproductPresent, reduceCookiesForTableDate, getIabPurposesForGvlVendor } from '../helper';

const { TabPane } = Tabs;

let showInitialMsg = true;
const initialMessage = `Vendors that have been added from the IAB TCF GVL may have made cookie disclosures as part of the framework. These cookies will automatically be added to your vendor list and you can see them under "Selected Cookies".`
const durations = ['day','year','month','minute','hour','session'];
const initialValues = { 
  cookieName: '',
  vendor: '',
  domain: '',
  purposes: [],
  duration : {
    number: '',
    suffix: 'day'
  }
}
const AddCookies = (props) => {
    const { 
      selectedVendors,
      globalVendors,
      scannedVendors,
      vendorList,
      updateSelectedCookies,
      updatedSelectedCookiesToBeRemoved,
      isNonIab
    } = props
    const [cookies, setCookies] = useState([]);
    const [selectedCookies, setSelectedCookies] = useState([]);
    const [seltectCookiesCache, setSelectedCookiesCache] = useState(null);
    const [checkedCookieIds, setCheckedCookieIds] = useState([]);
    const [checkedUploadedCookiesIds,setCheckedUploadedCookieIds] = useState([]);
    const [activeTab, setActiveTab] = useState()
    const [searchText, setSearchText] = useState('')
    const [uploadType, setUploadType] = useState('manual')
    const [showSelectedView, setShowSelectedView] = useState(false);
    const [viewTemplateDownloader,setViewTemplateDownloader] = useState(false);
    const [filteredPurposes, setFilteredPurposes] = useState([]);
    const [purposesTreeData, setPurposesTreeData] = useState([]);
    const [purposeOptions, setPurposeOptions] = useState([]);
    const [uploadedCookies, setUploadedCookies] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isImportCookiesSelected, setIsImportCookiesSelected] = useState(false);
    const [uploadedCookiesCache, setUploadedCookiesCache] = useState(null);
    const [tableId, setTableId] = useState('import-cookies-table');
    const [submitBtnType,setSubmitBtnType] = useState('submit')
    const [cookieToEdit,setCookieToEdit] = useState(null);
    const [selectedVendorCategoryMap, setSelectedVendorCategoryMap] = useState({})
    const [uploadedVendorsWithInvalidCookieData, setUploadedVendorsWithInvalidCookieData] = useState([]);
    const flatCategories =  useMemo(() => flattenCategories(vendorList.categories),[vendorList.categories]); 
  
    const getCategoriesFromVendorCategory = (vendorCategories) => {
      let categories = new Set();
      vendorCategories.forEach(vendorCategory =>{
        props.vendorList.vendorScanCategorizations.forEach(scanCategory => {
          if(vendorCategory === scanCategory.get("vendorScanCategory")){
            categories.add(
              scanCategory.get("vendorListCategoryId")
                ? flatCategories.find(
                    (c) => scanCategory.get("vendorListCategoryId") === c.id
                  )?.name
                : scanCategory.get("vendorListCategoryName")
            );
          }
        })
      })
      return [...categories];
    }

    const selectedScannedVendors = useMemo(
      () =>
        scannedVendors.toJS().filter((vendor) => selectedVendors.includes(vendor.id)),
      [selectedVendors]
    );

    const selectedGloablVendors = useMemo(
      () =>
        globalVendors.toJS().filter((vendor) => selectedVendors.includes(vendor.id)),
      [selectedVendors]
    );

    const allSelectedVendors = useMemo(
      () => selectedGloablVendors.map(item => {
        const matched_item = selectedScannedVendors.find(i => i.id === item.id);
        return matched_item ? matched_item : item;
      }),
      [selectedGloablVendors]
    );

    const indexedVendors = useMemo(
      () =>
        vendorList.vendors.toJS().reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
      [vendorList.vendors]
    );

    const setCookieScanResults = (cookiesInVl, categoriesMap) => {
      let cookies = [];
      let googleAdProduct = isGoogleAdproductPresent(vendorList.vendors);
      let googleAdProductPurposes = googleAdProduct ? googleAdProduct.purposes.map((p) => typeof p === 'string' ? p : p.id ) : [];
      let flatCategoriesJS = flatCategories.toJS();
      selectedScannedVendors.forEach(vendor => {
        let vendorCategories = [];
        if(vendor.vendorType === 'IAB') {
          let vendorIabPurposeRefs = getIabPurposesForGvlVendor(indexedVendors[vendor.id])
          vendorCategories = flatCategoriesJS.filter( p => vendorIabPurposeRefs.includes(p.iabPurposeRef)).map(p => p.name);
        } else if(googleAdProduct && vendor.isGoogleAtp) {
          let vendorIabPurposeRefs = googleAdProductPurposes;
          vendorCategories = flatCategoriesJS.filter( p => vendorIabPurposeRefs.includes(p.iabPurposeRef)).map(p => p.name);
        } else {
          vendorCategories = getCategoriesFromVendorCategory(vendor.vendorAdditionalDetails.vendorCategory);
        }
        categoriesMap[vendor.id] = vendorCategories;
        if(vendorCategories.length) {
          vendor.cookies?.forEach(cookie=>{
            // if(!iabDeclaredCookies.map(cookie => cookie.name).includes(cookie.name)) -> might need later based on requirement
            cookies.push({
              ...cookie,
              cookieId: Math.random(),
              vendorId: vendor.id,
              vendorName: vendor.name,
              added_via: 'Scan Results',
              gvlDefined:  false,
              fromScan: true,
              manuallyAdded: false,
              categories: vendorCategories,
              vendorIcon: getVendorTypeIcon(vendor)
            })
          })
        }
      })
      setCookies(cookies.filter( cookie => !cookiesInVl.find( cookieInVl => cookieInVl.name === cookie.name && cookieInVl.added_via === cookie.added_via && cookieInVl.vendorId === cookie.vendorId)));
    }

    useEffect(()=>{
      //extract already added cookies from categories -> vendorCategorization
      //start
      // const flatCategories = flattenCategories(vendorList.categories);
      let cookiesWithCategories = [];
      flatCategories.map(({ name, vendorCategorization }) => {
        vendorCategorization.forEach(({ cookies, vendorId }) => {
          const vendor = indexedVendors[vendorId]; 
          // const vendor = vendorList.vendors.find(v => v.id == vendorId);
          cookies.forEach(cookie => {
            cookiesWithCategories.push({
              ...cookie,
              category: name,
              cookieId: Math.random(),
              vendorId,
              vendorName: vendor.name ?? 'No vendor found',
              vendorIcon: getVendorTypeIcon(vendor)
            });
          });
        });
      });

      let initialCookies = cookiesWithCategories.map((c) => ({
        ...c,
        added_via: calculateCookieAddedVia(c)
      }))
      const data = reduceCookiesForTableDate(initialCookies);
      const updatedInitialCookies = Object.keys(data).map(key => {
        return data[key];
      })
      setSelectedCookies(updatedInitialCookies)
      //end

      let googleAdProduct = isGoogleAdproductPresent(vendorList.vendors);
      let googleAdProductPurposes = googleAdProduct ? googleAdProduct.purposes.map((p) => typeof p === 'string' ? p : p.id ) : [];
      let flatCategoriesJS = flatCategories.toJS();
      const iabDeclaredCookies = [];
      let categoriesMap = {};
      selectedGloablVendors.forEach(vendor => {
        let vendorIabPurposeRefs = [];
        if(googleAdProduct && vendor.isGoogleAtp) {
          vendorIabPurposeRefs = googleAdProductPurposes;
        } else {
          vendorIabPurposeRefs = getIabPurposesForGvlVendor(vendor);
        }
        let vendorCategories = flatCategoriesJS.filter(p => vendorIabPurposeRefs.includes(p.iabPurposeRef));
        categoriesMap[vendor.id] = vendorCategories.map(p => p.name);
        vendor.cookies?.forEach(cookie=>{
          if(!updatedInitialCookies.map(c => c.name).includes(cookie.name)) {
            vendorCategories = vendorCategories.filter(p => cookie.iabPurposeRefs.includes(p.iabPurposeRef)).map(p => p.name);
            iabDeclaredCookies.push({
              ...cookie,
              cookieId: Math.random(),
              added_via: 'IAB Declared',
              categories: vendorCategories,
              vendorId: vendor.id,
              vendorName: vendor.name,
              gvlDefined: cookie.gvlDefined ?? false,
              fromScan: false,
              manuallyAdded: false,
              vendorIcon: getVendorTypeIcon(vendor)
            })
          }
        })
      })
      //set scanned cookies
      setCookieScanResults(updatedInitialCookies, categoriesMap);
      setSelectedVendorCategoryMap(categoriesMap)
      if(iabDeclaredCookies.length > 0 ) {
        setSelectedCookies([...updatedInitialCookies,...iabDeclaredCookies]);
      }
    },[])

    useEffect(()=>{
      updateSelectedCookies(selectedCookies);
      setUploadedCookies(uploadedCookies.map(c => validateCookieData(c,uploadedCookies)));
    },[selectedCookies]);
  
    const [form] = Form.useForm();
    const [addManuallyForm] = Form.useForm();

    useEffect(() => {
      const selectedPurposes = [];
      vendorList.categories.size !== 0 && vendorList.categories.forEach((purpose) => {
        if(purpose.categories.size !== 0) {
          purpose.categories.forEach(p => selectedPurposes.push(p))
        } else selectedPurposes.push(purpose);
      });

      const treeData = {
        title: 'All',
        value: 'all',
        children: []
      }
      selectedPurposes.forEach((purpose) => {
        treeData.children.push({'title': purpose.name, 'value': purpose.name, 'type': purpose.type, 'iabPurposeRef': purpose.iabPurposeRef})
      });

      const data = selectedPurposes.length === 0 ? selectedPurposes : [treeData];

      setPurposesTreeData(data);
      setFilteredPurposes(selectedPurposes);
    },[vendorList.categories])

    const onChangeTab = (value) => {
        setActiveTab(value);
        value === 1 && setIsImportCookiesSelected(false);
        value === 2 && setIsImportCookiesSelected(true);
        setSearchText('');
    }

    const onChangeSearch = ({target : {value}}) => {
      setSearchText(value);
    }

    const isPositiveInteger = (str) => {
      if (typeof str !== 'string') {
        return false;
      }
      const num = Number(str);
      if (Number.isInteger(num) && num > 0) {
        return true;
      }
      return false;
    }

    const validateCookieData = (cookieInfo, uploadedCookies) => {
      const pattern = /^[a-zA-Z0-9-._~*]*$/i;
      let errors = {};

      const duplicateUploadedCookies = typeof cookieInfo.name === "string" && uploadedCookies.filter(c => c.manuallyAdded === true && c.vendorId === cookieInfo.vendorId && c.name === cookieInfo.name.trim() && c.cookieId !== cookieInfo.cookieId);

      if(typeof cookieInfo.name !== "string" || cookieInfo.name.trim() === "") {
        errors.cookieName = true;
      }
      if(typeof cookieInfo.name === "string" && selectedCookies.filter(c => c.manuallyAdded === true && c.vendorId === cookieInfo.vendorId).map(c => c.name).includes(cookieInfo.name.trim())) {
        errors.cookieName = "Cookie name already exists.";
      } else if(duplicateUploadedCookies && duplicateUploadedCookies.length) {
        errors.cookieName = "Duplicate Cookie Name.";
      }
      if(!pattern.test(cookieInfo.domain)) {
        errors.domain = true;
      }
      if(cookieInfo?.vendorName === '' || !cookieInfo.vendorId) {
        errors.vendorName = true;
      }

      let vendorExists = allSelectedVendors.find((v) => v.name === cookieInfo?.vendorName);
      if(cookieInfo?.vendorName !== '' && !vendorExists){
        errors.vendorName = true;
      }
    
      if(cookieInfo.cookieLifeSpan === '') {
        errors.cookieLifeSpan = true;
      }
      if(typeof(cookieInfo.cookieLifeSpan) === 'string') {
        const cookieDuration = cookieInfo.cookieLifeSpan.trim().split(' ');
        const number = String(cookieDuration[1]).toLowerCase();
        const duration = String(cookieDuration[0]).toLowerCase();
        if((cookieDuration.length === 1 && duration !== 'session') || cookieDuration.length > 2) {
          errors.cookieLifeSpan = true;
        } else if (cookieDuration.length === 2){
          if(!isPositiveInteger(number) || !durations.includes(duration)) {
            errors.cookieLifeSpan  = true;
          }           
        }
      }
      if(cookieInfo.categories.length === 0) {
        errors.categories = true;
      };
      cookieInfo.errors =  errors; 
      return {...cookieInfo};
    }

    const getCookieLifeSpan = (duration_row="", durationType_row="") => {
      let durationType = "";
      let duration = ""
      let lastCharacter = durationType_row.charAt(durationType_row.length - 1);
      let sanitizedDurationType = (lastCharacter === 's')?durationType_row.slice(0,-1):durationType_row;
      if(durations.includes(sanitizedDurationType.toLowerCase())){
        durationType = sanitizedDurationType?durations.find(d => d === sanitizedDurationType?.toLowerCase()):'day';
        duration = (duration_row && duration_row!=='')?duration_row.trim():''
      }
      return {durationType, duration}
    }
    
    const addVendorCookies = (data) => {
      const cookies = []
      const vendorsWithInvalidData =  new Set()
      data.map((row) => {
        const {duration, durationType} = getCookieLifeSpan(row[4]?.trim(),row[5]?.trim());
        const vendor = allSelectedVendors.find((v) => v.name === row[0].trim());
        const vendorId = vendor?.id;
        const categories = row[3].split("; ").filter( cat => {
          if(flatCategories.map(p => p.name).includes(cat.trim())) {
            return true;
          } else if(row[3]?.trim()) {
            // vendorsWithInvalidData.add(vendorId)
            return false
          } 
          return false;
        })

        const cookieData = 
          {
            cookieId: Math.random(),
            name: row[1]?.trim(),
            cookieLifeSpan: `${durationType} ${duration}`,
            domain: row[2]?.trim(),
            vendorName: row[0]?.trim(),
            vendorId: (vendorId)?vendorId:row[0]?.trim(),
            categories: categories,
            added_via: 'Manual',
            vendorIcon: getVendorTypeIcon(vendor),
            errors: {},
            gvlDefined: false,
            fromScan: false,
            manuallyAdded: true,
            isImported: true
            
          }
        if(!cookies.filter(cookie => cookie.vendorId === cookieData.vendorId).map(cookie => cookie.name).includes(cookieData.name)) {
          const modifyCookieData = validateCookieData(cookieData, cookies);
          if(Object.keys(modifyCookieData.errors).length) {
            vendorsWithInvalidData.add(modifyCookieData.vendorId)
          }
          cookies.push(modifyCookieData);
        }
      })
      setUploadedVendorsWithInvalidCookieData([...vendorsWithInvalidData])
      let cookiesWithVendors = cookies.filter(c => c.vendorName !== '');
      setUploadedCookies(cookiesWithVendors);
      setIsImportCookiesSelected(true);
    }

    const onFinish = (values, isEdit) => {
      // updatedSelectedCookiesToBeRemoved([]);
      if(submitBtnType === 'submit'){
        const vendor = vendorList.vendors.find((vendor) => vendor.id === values.vendor)?.toJS();
        const purposes = values.purposes.includes('all') ? purposeOptions[0].children.map(purpose => purpose.value) : filteredPurposes.filter((purpose) => 
        values.purposes.includes(purpose.name)).map(purpose => purpose.name)
        if(cookieToEdit) {
          updatedSelectedCookiesToBeRemoved((c) => [...c,...cookieToEdit])
        }
        const cookieData = [
          {
            cookieId: Math.random(),
            name: values.cookieName,
            cookieLifeSpan: values.duration.suffix === 'session' ? 'session' : `${values.duration.suffix} ${values.duration.number}`,
            domain: values.domain,
            vendorName: vendor.name,
            vendorId: vendor.id,
            categories: purposes,
            added_via: 'Manual',
            vendorIcon: getVendorTypeIcon(vendor),
            errors: {},
            gvlDefined: false,
            fromScan: false,
            manuallyAdded: true,
            isImported: isEdit && cookieToEdit ? cookieToEdit[0].isImported : false
          }
        ];
        setSelectedCookies([
          ...cookieData,
          ...selectedCookies
        ])
        setIsModalVisible(false);
        addManuallyForm.resetFields();
      }else{
      onSave()
      }
      setCheckedUploadedCookieIds([]);
      setCheckedCookieIds([]);
      setUploadedCookiesCache(null)
      setSelectedCookiesCache(null)
      form.resetFields();
    }

    const onSave = () => {
      const values = form.getFieldsValue();
      const vendor = vendorList.vendors.find((vendor) => vendor.id === values.vendor)?.toJS();
      const purposes = values.purposes.includes('all') ? purposeOptions[0].children.map(purpose => purpose.value) : filteredPurposes.filter((purpose) => 
       values.purposes.includes(purpose.name) 
      ).map(purpose => purpose.name)
      const cookieData = 
        {
          cookieId: Math.random(),
          name: values.cookieName,
          cookieLifeSpan: values.duration.suffix === 'session' ? 'session' : `${values.duration.suffix} ${values.duration.number}`,
          domain: values.domain,
          vendorName: vendor.name,
          vendorId: vendor.id,
          categories: purposes,
          added_via: 'Manual',
          vendorIcon: getVendorTypeIcon(vendor),
          errors: {},
          gvlDefined: false,
          fromScan: false,
          manuallyAdded: true,
          isImported: true
        };
      const cookies = [cookieData, ...uploadedCookies].map(c => validateCookieData(c,[...uploadedCookies, cookieData]));
      setUploadedCookies(cookies);
      setIsModalVisible(false);
    }

    const showTemplateDownloader =(flag) => {
      setViewTemplateDownloader(flag)
    }
    const handleAddToSelectedCookies = (flag) => {
      // updatedSelectedCookiesToBeRemoved([])
      if(searchText) {
        setSearchText('')
      } 
      if(flag){
        const checkedCookies = uploadedCookies.filter(cookie => checkedUploadedCookiesIds.includes(cookie.cookieId));
        setSelectedCookies([
          ...selectedCookies,
          ...checkedCookies
        ])
        setUploadedCookies(uploadedCookies.filter(cookie => !checkedUploadedCookiesIds.includes(cookie.cookieId)))
        setCheckedUploadedCookieIds([])
      }else {
        const checkedCookies = cookies.filter(cookie => checkedCookieIds.includes(cookie.cookieId));
        setSelectedCookies([
          ...selectedCookies,
          ...checkedCookies
        ])
        setCookies(cookies.filter(cookie => !checkedCookieIds.includes(cookie.cookieId)))
        setCheckedCookieIds([])
      }
    }

    const handleRemoveCookies = (removeIds) => {
      const checkedCookies = selectedCookies.filter(cookie => removeIds.includes(cookie.cookieId));
      // setCookies([
      //   ...cookies,
      //   ...checkedCookies
      // ])
      setCookieScanResults(selectedCookies.filter(cookie => !removeIds.includes(cookie.cookieId)), selectedVendorCategoryMap);
      updatedSelectedCookiesToBeRemoved((c) => [...c,...checkedCookies].filter((v,i,a)=>a.findIndex(v2=>['name','vendorId','added_via'].every(k=>v2[k] ===v[k]))===i));
      setSelectedCookies(selectedCookies.filter(cookie => !removeIds.includes(cookie.cookieId)));

      let removedUploadedCookies = [];
      checkedCookies.forEach(cookie => {
        if(cookie.isImported === true) {
          removedUploadedCookies.push(cookie)
        }
      })
      setUploadedCookies([
        ...removedUploadedCookies,
        ...uploadedCookies,
      ])
    }
    
    const showEditModal = (row, index) => {
      // updatedSelectedCookiesToBeRemoved([]);
      const domain = typeof(row.domain) === 'string' ? row.domain : row.domain?.props?.children[0];
      const cookieName = typeof(row.name) === 'string' ? row.name : row.domain?.props?.children[0];
      const purposes = isArray(row.purpose) ? row.purpose : [];
      let duration = row.duration && typeof(row.duration) === 'string' ? row.duration : row.duration?.props?.children[0];
      let cookieLifeSpan = {number: '', suffix: 'day'};
      if(duration){
      if(duration.split(' ')[0] === 'session') {
        cookieLifeSpan = {number: '', suffix: 'session'}
      }else {
        cookieLifeSpan = {number: duration.split(' ')[1], suffix: duration.split(' ')[0] ? duration.split(' ')[0] : 'day'}
      }
      }
      form.setFieldsValue({ 
        cookieName,
        vendor: row.vendorId,
        domain,
        purposes,
        duration : cookieLifeSpan,
      })
      if(isImportCookiesSelected) {
        const updatedCookies = uploadedCookies.filter((cookie) => !(cookie.cookieId === row.cookieId) )
        const cookieToEdit = uploadedCookies.filter((cookie) => (cookie.cookieId === row.cookieId) )
        setUploadedCookiesCache(uploadedCookies);
        setUploadedCookies(updatedCookies);
        setCookieToEdit(cookieToEdit);
      }else {
        const updatedCookies = selectedCookies.filter(cookie =>  !(cookie.name === row.name && cookie.vendorId === row.vendorId && cookie.manuallyAdded === true))
        const cookieToEdit = selectedCookies.filter(cookie =>  cookie.name === row.name && cookie.vendorId === row.vendorId && cookie.manuallyAdded === true)
        setSelectedCookiesCache(selectedCookies);
        setSelectedCookies(updatedCookies)
        setCookieToEdit(cookieToEdit);
      }

      setIsModalVisible(true);
    }

    const handleAddManuallyCancel = () => {
      if(isImportCookiesSelected) {
        setUploadedCookies(uploadedCookiesCache ?? uploadedCookies);
      } else {
        setSelectedCookies(seltectCookiesCache ?? selectedCookies);
        setCookieToEdit(null);
      }
      setIsModalVisible(false);
      form.resetFields();
      setUploadedCookiesCache(null)
      setSelectedCookiesCache(null)
    }

    const selectedCookiesHandler = () => {
      setShowSelectedView(true);
      setIsModalVisible(false);
      setTableId('cookies-config-table');
      setIsImportCookiesSelected(false);
      handleAddManuallyCancel();
    }
    return (
        <div className="add-cookies-wrapper">
         {showInitialMsg && !isNonIab ? <Alert type="warning" message={initialMessage}showIcon icon={<WarningFilled />} closable closeIcon={<CloseCircleFilled onClick={() => showInitialMsg = false}/>}/> : null} 
        {!showSelectedView && (<Tabs genre='classic' tabBarExtraContent={<Button type="primary" size="small" onClick={selectedCookiesHandler}>
        View Selected Cookies{ selectedCookies.length ? `(${selectedCookies.length})` : null}
        </Button>} defaultActiveKey={1} activeKey={activeTab} onChange={onChangeTab}>
          <TabPane tab={"Cookies Scan Result"} key={"1"}>
              <div className='header-section'>
              <span className='cookie-note'>The cookies below have been picked up in scans on the properties that are associated with this vendor list in the last 7 days. You can review these cookies on a per vendor basis and choose whether or not to disclose these in your vendor list.</span>
              <div className='action-wrapper'>
                  <Input suffix={<SearchOutlined />} type="search" placeholder="Search Vendor or Cookie" value={searchText} onChange={onChangeSearch} />
                  <Button className="btn-add-cookie" type="primary" size="small" onClick={() => handleAddToSelectedCookies(false)} disabled={!checkedCookieIds.length}>Add to Selected Cookies{checkedCookieIds.length ? `(${checkedCookieIds.length})` : null}</Button>
               </div>
               <CookieTable
                  tableId='cookies-scan-table'
                  cookies={cookies}
                  checkedCookieIds={checkedCookieIds}
                  setCheckedCookieIds={setCheckedCookieIds}
                  purposesTreeData={purposesTreeData}
                  allCategories={flattenCategories(vendorList.categories).map(c => c.name)}
                  searchText={searchText}
                  isNonIab={isNonIab}
               />
              </div>
          </TabPane>
          <TabPane tab={"Add/Import Cookies"} key={"2"}>
              <div className='header-section'>
              <span className='cookie-note'>If you would like to add new cookies not recognized by the system, you can do so manually or import a .csv file with the cookie list.</span>
                <b> Once you have added all the new cookies, make sure you move them to your Selected cookies list</b>
              <div className='action-wrapper'>
                  <Button className="add-manually-btn" type="primary" size="small" onClick={() => setUploadType('manual')}><PlusOutlined /> Add Manually</Button>
                  <Button className="add-manually-btn import-btn" type="primary" size="small" onClick={() => setUploadType('import')}><DownloadOutlined /> Import</Button>
              </div>
              <div className='wrap'>
                <div className={`arrow ${uploadType}`}></div>
                <div className={`arrow-cover ${uploadType}`}></div>
                <div className="box">
                  {uploadType === 'manual' && (
                    <CookieForm 
                      setSubmitBtnType={setSubmitBtnType}
                      onFinish={(values) => onFinish(values,false)}
                      form={addManuallyForm}
                      initialValues={initialValues}
                      selectedVendors={vendorList.get('vendors')}
                      purposes={purposesTreeData}
                      selectedCookies={selectedCookies}
                      purposeOptions={purposeOptions}
                      setPurposeOptions={setPurposeOptions}
                    />
                  )}
                  {uploadType === 'import' && (<div>
                    <VendorCookieUpload 
                      viewTemplateDownloader={viewTemplateDownloader} 
                      showTemplateDownloader={showTemplateDownloader} 
                      vendors={allSelectedVendors} 
                      addVendorCookies={addVendorCookies}
                      selectedVendorCategoryMap={selectedVendorCategoryMap}
                    />
                  </div>)}
                </div>
              </div>
               </div>
               <div className='uploaded-cookies'>
                <label>Uploaded Cookies</label>
                {uploadedCookies.length>0 || isModalVisible ? (
                  <>
                  <div>You can correct the <ExclamationCircleFilled style={{color: 'red',padding:'0px'}} /> Invalid Data by editing the Cookies. Use <EditFilled style={{padding:0}}/> to Edit Cookies. Only cookies with complete/valid data can be added to Selected Vendors list.</div>
                <div className='action-wrapper'>
                  <Input suffix={<SearchOutlined />} type="search" placeholder="Search vendor or cookie" value={searchText} onChange={onChangeSearch} />
                  <Button className="btn-add-cookie" type="primary" size="small" onClick={() => handleAddToSelectedCookies(true)} disabled={!checkedUploadedCookiesIds.length}>Add to Selected Cookies {checkedUploadedCookiesIds.length ? `(${checkedUploadedCookiesIds.length})` : null}</Button>
               </div>
                  <CookieTable
                    tableId={'import-cookies-table'}
                    cookies={uploadedCookies}
                    checkedCookieIds={checkedUploadedCookiesIds}
                    setCheckedCookieIds={setCheckedUploadedCookieIds}
                    purposesTreeData={purposesTreeData}
                    showEditModal={showEditModal}
                    showManuallyAddedCookies={true}
                    allCategories={flattenCategories(vendorList.categories).map(c => c.name)}
                    searchText={searchText}
                    isNonIab={isNonIab}
                    defaultExpandedRowKeys={uploadedVendorsWithInvalidCookieData}
                  /></>
                ):(
                  <div className="empty-cookies">Uploaded cookies data will appear here</div>
                )}
                </div>
          </TabPane>
        </Tabs>)}
        {showSelectedView && 
        <SelectedView 
          sectionTitle="Selected Cookies"
          sectionSubTitle={<>Use <EditFilled style={{padding:0}}/> to Edit Cookies. Only manually added/imported cookies are editable.</>}
          searchPlaceholder="Search Vendor or Cookie"
          btnTitle="Remove Cookies"
          setShowSelectedView={setShowSelectedView}
          setSelectedCookies={setSelectedCookies}
          selectedCookies={selectedCookies}
          handleRemoveCookies={handleRemoveCookies}
          showEditModal={showEditModal}
          purposesTreeData={purposesTreeData}
          setTableId={setTableId}
          setIsImportCookiesSelected={setIsImportCookiesSelected}
          allCategories={flattenCategories(vendorList.categories).map(c => c.name)}
          handleAddManuallyCancel={handleAddManuallyCancel}
          isNonIab={isNonIab}
          />}
        {isModalVisible && <Modal
          visible={isModalVisible}
          getContainer={document.getElementById(tableId)}
          footer={null}
        >
          <CookieForm 
            form={form} 
            cancel
            save={isImportCookiesSelected ? true : false}
            isSelectedCookie={isImportCookiesSelected}
            setSubmitBtnType={setSubmitBtnType}
            type='primary'
            buttonTitle={isImportCookiesSelected ? 'Add to Selected Cookies' : 'Save'}
            onFinish={(values) => onFinish(values,true)}
            onCancel={handleAddManuallyCancel} 
            initialValues={initialValues}
            selectedVendors={vendorList.get('vendors')}
            purposes={purposesTreeData}
            selectedCookies={selectedCookies}
            purposeOptions={purposeOptions}
            setPurposeOptions={setPurposeOptions}  
          />
            
        </Modal>}
        </div>
    )
}

export default AddCookies;
