import React, { useState, useEffect } from 'react'
import { PlusSquareOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd'

import {  checkStatus } from "../../api/helper"
import VendorRowItem from './VendorRowItem'

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const AddVendorModal = (props) => {

    const [vendors, setVendors] = useState({
        0: {
            name: "",
            policyUrl: "",
            vendorType: "CUSTOM"
        }
    })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        0: ""
    })

    useEffect(() => {
        const postSystemVendors = async () => {
            
            const URL = (props.category.includes('gdpr')) ? `${permissionsSvcBaseUrl}/tcfv2` : `${permissionsSvcBaseUrl}/ccpa`

            const res = await fetch(`${URL}/vendors/bulk`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json',
                },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify({
                    accountId: props.accountId,
                    vendors: Object.values(vendors)
                })
            })
                .then(checkStatus)
                .then(res => res.json())
                .catch(console.log)

            closeModalAndRefetch()
        }
        if(loading === true) {
            postSystemVendors()
        }
    }, [loading, vendors])


    const closeModalAndRefetch = () => {
        setLoading(false)
        props.showModal(false)
        resetModalState()
    }

    const resetModalState = () => {
        setVendors({
            0: {
                name: "",
                policyUrl: "",
                vendorType: "CUSTOM"
            }
        })
        setErrors({
            0: ""
        })
    }

    const handleAdd = () => {
        setVendors({...vendors, [Object.keys(vendors).length]: {
            name: "",
            policyUrl: "",
            vendorType: "CUSTOM"
        }})
        setErrors({ ...errors, [Object.keys(errors).length]: "" })
    }

    const handleOk = () => {
        let hasErrors = false
        Object.values(vendors).forEach((vendor, index) => {
            Object.keys(vendor).forEach(key => {
                if(vendor[key].length === 0) {
                    hasErrors = true
                    setErrors({ ...errors, [index]: "One or more of the required fields are empty."})
                }
            })
        })
        if(!hasErrors) {
            setLoading(true)
        }
    }

    const handleChange = (index, key, value) => {
        setVendors({
            ...vendors,
            [index]: {
                ...vendors[index],
                [key]: value
            }
        })
    }

    return (
        <Modal
            title="Add custom vendor"
            visible={props.visible}
            onOk={handleOk}
            onCancel={closeModalAndRefetch}
            footer={[
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Save
                </Button>,
            ]}
        >
            <React.Fragment>
                {
                    Object.keys(vendors).map((key) => (
                        <VendorRowItem
                            data={vendors[key]}
                            index={key}
                            key={key}
                            handleChange={handleChange} 
                            errors={errors}/>
                    ))
                }
            </React.Fragment>
            <Button shape="circle" icon={<PlusSquareOutlined />} onClick={handleAdd} />
        </Modal>
    );
}

export default AddVendorModal