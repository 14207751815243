import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import PropertyTag from './PropertyTag';

export default class PageHeader extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    let classes = classNames('messaging-page-header', this.props.className);
    const propertyTag = this.props.propertyType && <PropertyTag type={ this.props.propertyType }/>;

    return (
      <h1 className={ classes }>
        <span className="page-label">{ this.props.label }</span>
        <span className="page-title">{ this.props.title }</span>
        { propertyTag }
      </h1>
    );
  }
}
