import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List, fromJS } from 'immutable';
import { browserHistory } from 'react-router';
import { Headline, message, Input, Select, Button, Popconfirm } from '../../../styleguide';

import { connect } from 'react-redux';
import { getAllSites, getSiteGroup } from '../../../actions/site_actions';
import TemplateButton from './common/TemplateButton.jsx';
import { Site } from '../../../records/site_records.js';
import { User } from '../../../records/account_records.js';
import { dataTemplates } from './TemplateBuilder/Data';
import ScenarioList from './ScenarioList';
import SelectTemplateModal from './SelectTemplateModal';
import SitesRedirect from '../campaigns/SitesRedirect';
import { STATUS_TYPES as STATUS, CAMPAIGN_TYPES, CAMPAIGNS_MAP as CAMPAIGNS, BACKEND_STATUS_TYPES } from '../../../constants';
import { getParameterByName } from '../../utils.js';
import { Scenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import {
  getDraftScenarios,
  importScenario,
  deleteScenario,
  deleteSiteGroupScenario,
  createSiteGroupScenario,
  updateSiteGroupScenario,
  updateScenario,
} from '../../../actions/scenario_actions';
import { siteAndGroupLabel } from '../helper';
import SiteGroupRedirect from '../../common/SiteGroupRedirect';
import PropertiesUnderGroupRedirection from '../../common/PropertiesUnderGroupRedirection';
import PropertyTag from '../../common/PropertyTag';
import UpdateCampaignType from '../../common/UpdateCampaignType';
import Loading from '../../common/Loading.js.jsx';
import DismissErrorModal from '../../common/DismissErrorModal';
import CampaignsCheckboxFilter from '../../common/CampaignsCheckboxFilter';
import ImportForm from '../../common/ImportForm';
import { getAllMessagesAjax } from '../../../api/dialogue/messages';

const columnsTemplate = List([
  Map({
    title: 'Types',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => a.type.length - b.type.length,
  }),
  Map({
    title: 'Goals',
    dataIndex: 'goal',
    key: 'goal',
    sorter: (a, b) => a.goal.length - b.goal.length,
    render: (text) => (
      <span style={{ color: 'black', fontSize: '14px' }}>{text}</span>
    ),
  }),
]);
const { Search } = Input;
const { Option } = Select;

export class ScenarioDashboard extends Component {
  static propTypes = {
    scenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario))
      .isRequired,
    allSites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    currentUser: PropTypes.instanceOf(User).isRequired,

    getAllSites: PropTypes.func.isRequired,
    getDraftScenarios: PropTypes.func.isRequired,
    importScenario: PropTypes.func.isRequired,
    deleteScenario: PropTypes.func,
    getSiteGroup: PropTypes.func.isRequired,
    createSiteGroupScenario: PropTypes.func.isRequired,
    updateSiteGroupScenario: PropTypes.func.isRequired,
    updateScenario: PropTypes.func.isRequired,
  };

  static defaultProps = {
    deleteScenario: _.noop,
  };

  defaultCampaignTypesSelected = () => {
    const defaultCheckedCampaignTypes = CAMPAIGN_TYPES.map(t => t.value);
    return defaultCheckedCampaignTypes;
  }

  constructor(props) {
    super(props);
    this.state = {
      showtemplatemodal: false,
      sortValue: 'updated_at',
      datatemplates: dataTemplates,
      filteredScenarios: props.scenarios,
      initialLoad: true,
      sortedInfo: {
        order: 'descend',
        columnKey: 'updated_at',
      },
      search: '',
      editId: '',
      messages: [],
      editCampaignType: '',
      checkedCampaignTypes: this.defaultCampaignTypesSelected(),
      isFiltered: false,
      displayImport: false,
      copyMessages: false,
    };
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.getAllSites();
      const siteGroupId = this.props.location.query.site_group_id;
      const redirectSiteGroupId = this.props.location.query.sitegroup_id;
      if (siteGroupId) {
        this.props.getSiteGroup(siteGroupId);
      }
      if(redirectSiteGroupId){
         this.props.getSiteGroup(redirectSiteGroupId);
      }
      this.getMessages();
    }
  }

  componentDidUpdate = (oldProps, prevState) => {
    if (!oldProps.currentUser && this.props.currentUser) {
      this.props.getAllSites();
      const siteGroupId = this.props.location.query.site_group_id;
      const redirectSiteGroupId = this.props.location.query.sitegroup_id;
      if (siteGroupId) {
        this.props.getSiteGroup(siteGroupId);
      }
      if(redirectSiteGroupId){
         this.props.getSiteGroup(redirectSiteGroupId);
      }
      this.getMessages(); 
    }
    if (this.props.location?.query?.site_id !== oldProps?.location.query?.site_id) {
      this.getMessages(); 
    }
  }

  getMessages = async (type = 'draft') => {
    try {
      const siteId = this.props.location.query.site_id;
      const messages = await getAllMessagesAjax(siteId, BACKEND_STATUS_TYPES[type])
      this.setState({ messages });
    } catch (err) {
      message.error('Could not load messages, try again');
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.scenarios.size != state.filteredScenarios.size ||
      state.initialLoad
    ) {
      const site = props.allSites.size ? props.allSites.find((s) => s.id === parseInt(props.siteId)) : null;
      const isMultiCampaignEnabled = site ? site.multiCampaignEnabled : false;

      let filteredScenarios = state.search || state.isFiltered
        ? state.filteredScenarios
        : props.scenarios
      return {
        filteredScenarios: filteredScenarios.filter((s) => isMultiCampaignEnabled ? true : s.campaign_type_id === 0),
      };
    }
    return null;
  }

  openTemplateModal = () => {
    this.setState({ showtemplatemodal: true });
  };

  handleChange = (value) => {
    switch (value) {
      case 'name':
        var dataSource = this.props.scenarios.sort((a, b) =>
          a.description.localeCompare(b.description)
        );
        this.setState({
          filteredScenarios: dataSource,
          sortValue: value,
          sortedInfo: {
            order: 'ascend',
            columnKey: 'description',
          },
        });
        break;
      case 'creationdate':
        var dataSource = this.props.scenarios.sort(
          (a, b) =>
            moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
        );
        this.setState({
          filteredScenarios: dataSource,
          sortValue: value,
          sortedInfo: {
            order: 'descend',
            columnKey: 'created_at',
          },
        });
        break;
      case 'lastupdated':
        var dataSource = this.props.scenarios.sort(
          (a, b) =>
            moment(a.updated_at, 'MM-DD-YYYY').valueOf() -
            moment(b.updated_at, 'MM-DD-YYYY').valueOf()
        );
        this.setState({ filteredScenarios: dataSource, sortValue: value });
        break;
    }
  };

  onCancelModal = () => {
    this.setState({ showtemplatemodal: false });
  };

  filterValueTemplates = (searchkey) => {
    let dataSource = dataTemplates;
    dataSource = dataSource.filter((vw) => {
      if (!searchkey) return true;
      return vw.type.toUpperCase().indexOf(searchkey.toUpperCase()) !== -1;
    });

    this.setState({ datatemplates: dataSource });
  };

  editScenario = (index) => {
    const siteId = parseInt(getParameterByName('site_id', window.location));
    const siteGroupId = this.props.location.query.site_group_id;
    let url =
      '/dialogue/scenarios/edit?site_id=' +
      siteId +
      (siteGroupId ? '&site_group_id=' + siteGroupId : '') +
      '&id=' +
      index +
      '&template=' +
      false;
    browserHistory.push(url);
  };

  handleDuplicateScenario = (name) => {
    const scenarios = this.props.scenarios;
    return scenarios.find(s => s.description === name);
  } 

  importScenario = (scenario, copyMessages) => {
    const originSceanrioDescription = scenario.description;
    const newScenarioDescription = originSceanrioDescription + ' (copy)';
    const duplicateScenario = this.handleDuplicateScenario(newScenarioDescription);
    if(duplicateScenario) {
      message.warning(`New scenario name will be '${newScenarioDescription}' which is already exist. Please update name of '${originSceanrioDescription}' or '${newScenarioDescription}' scenario.`);
      return;
    }

    const siteGroupId = this.props.location.query.site_group_id;
    if (siteGroupId) {
      const updatedScenario = fromJS(scenario).set('description', newScenarioDescription);
      const willImport = true;
      this.props.createSiteGroupScenario(
        this.props.currentUser.accountId,
        siteGroupId,
        fromJS(updatedScenario),
        willImport
      );
      this.setState({ initialLoad: true, displayImport: false, copyMessages: false });
      message.success('Your scenario copied successfully');
      this.getMessages();
    } else {
      const options = typeof copyMessages === 'object' ? copyMessages : { copyMessages };
      this.props.importScenario(this.props.location.query.site_id, scenario.id, options)
        .then(() => {
          this.setState({ initialLoad: true, displayImport: false, copyMessages: false });
          message.success('Your scenario copied successfully');
          this.getMessages();
        });
    }
  };

  handleDelete = (scenario) => {
    message.success('Your scenario deleted successfully');
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      this.props.deleteSiteGroupScenario(
        siteGroupId,
        this.props.currentUser.accountId,
        scenario
      );
    } else {
      const siteId = parseInt(getParameterByName('site_id', window.location));
      this.props
        .deleteScenario(scenario.id, scenario.description, siteId)
        .then(() => this.setState({ initialLoad: true }));
    }
  };

  onChangeCopy = (checked, record) => {
    this.setState({ [record.id]: checked });
  };

  handleSortChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  redirectToGroup = () => browserHistory.push(`${this.props.location.pathname}?site_id=${this.props.siteGroupRedirect.siteId}&site_group_id=${this.props.siteGroupRedirect.id}`);

  setEditId = (id = '') => {
    this.setState({ editId: id });
  }

  preventCampaignTypeUpdate = (scenario, campaignTypeId) => {
    let mismatchMsgFound = false;
    scenario.steps.filter((s) => {
      const messages = s.rowData.filter(r => r.category === 'message');
       messages.forEach((r) => {
        if(r.data && r.data.message_id) {
          const message = this.state.messages.find(m => m.id === r.data.message_id);
          if(message && message.message_category_id !== campaignTypeId) {
            mismatchMsgFound = true;
            return;
          }
        } else if(r.data && !r.data.message_id) {
            mismatchMsgFound = true;
            return;
        }
      });
    });
    return mismatchMsgFound;
  }

  updateCampaignId = async (scenarioId, campaignTypeId) => {
    const scenario = this.state.filteredScenarios.find(s => s.id === scenarioId).set('campaign_type_id', campaignTypeId);

    const showMsgMismatchErr = this.preventCampaignTypeUpdate(scenario, campaignTypeId);
    if(showMsgMismatchErr) {
      this.toggleCampaignError(campaignTypeId);
      return;
    }

    const siteGroupId = this.props.location.query.site_group_id;
    let updatePromise;
    if (siteGroupId) {
      const accountId = this.props.currentUser.accountId;
      const status = await this.props.updateSiteGroupScenario(siteGroupId, accountId, scenario);
      if(status === 'success') {
        this.setEditId();
        message.success(`Scenario '${scenario.description}' updated with campaign type '${CAMPAIGNS[campaignTypeId]}' successfully`);
      } else {
        message.error(`Scenario '${scenario.description}' failed to update, please try again`);
      }
    } else {
        this.props.updateScenario(scenario)
        .then(() => {
          this.setEditId();
          message.success(`Scenario '${scenario.description}' updated with campaign type '${CAMPAIGNS[campaignTypeId]}' successfully`);
        });
    }
  }

  toggleCampaignError = (editCampaignType = '') => {
    this.setState({ editCampaignType });
  }

  updateSearch = (e) => {
    this.setState({ search: e.target.value }, () => this.updateAndFilterSceanrios());
  };

  setCampaignTypes = (checkedCampaignTypes) => {
    this.setState({ checkedCampaignTypes, isFiltered: true }, () => this.updateAndFilterSceanrios());
  }

  updateAndFilterSceanrios = () => {
    let filteredScenarios = this.props.scenarios.filter((vw) => {
      return (
        (vw
          .get('description')
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        moment(vw.get('created_at'))
          .format('MMM-DD-YYYY')
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1) && 
        this.state.checkedCampaignTypes.includes(vw.campaign_type_id)
      );
    });
    this.setState({ filteredScenarios });
  }

  toggleImport = () => {
    const copyMesages = !this.state.displayImport ? false : true;
    this.setState((prevState) => ({ displayImport: !prevState.displayImport, copyMessages: copyMesages }));
  };

  onChecked = (checked) => {
    this.setState({
      copyMessages: checked,
    });
  }

  render() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    
    const siteId = this.props.location.query.site_id;
    let domain;
    let siteGroupSiteList;
    let siteGroupSitesWithErrors;
    const siteGroupId = this.props.location.query.site_group_id;
    let propertyType;
    const site = this.props.allSites.size ? this.props.allSites.find((s) => s.id === parseInt(siteId)) : '';
    const isMultiCampaignEnabled = site ? site.multiCampaignEnabled : false;
    if (siteGroupId) {
      domain = this.props.siteGroup && this.props.siteGroup.name;
      siteGroupSiteList = <PropertiesUnderGroupRedirection pageKind='scenariosv3'/>;
      if (this.props.siteGroupSitesWithErrors.size) {
        siteGroupSitesWithErrors = (
          <SitesRedirect
            pageKind="scenariosv3"
            sites={this.props.siteGroupSitesWithErrors.map((options) =>
              this.props.allSites.find((s) => s.id === options.get('siteId'))
            )}
            isForErrors
          />
        );
      }
    } else {
      domain = site ? site.domain : '';
      propertyType = site ? site.type : '';
    }

    let columns = List([
      Map({
        title: 'Name',
        width: '25%',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => {
          const str1 = typeof(a.description) === 'object' ? a.description?.props?.children[0] :  a.description;
          const str2 = typeof(b.description) === 'object' ? b.description?.props?.children[0] :  b.description;
          return str1.localeCompare(str2);
        },
        sortOrder:
          this.state.sortedInfo.columnKey === 'description' &&
          this.state.sortedInfo.order,
      }),
      Map({
        title: 'Creation Date',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) =>
          moment(a.created_at).valueOf() - moment(b.created_at).valueOf(),
        sortOrder:
          this.state.sortedInfo.columnKey === 'created_at' &&
          this.state.sortedInfo.order,
        render: (text) => moment(text).format('MMM-DD-YYYY'),
      }),
      Map({
        title: 'Last Updated',
        dataIndex: 'updated_at',
        key: 'updated_at',
        defaultSortOrder: 'descend',
        sortOrder:
          this.state.sortedInfo.columnKey === 'updated_at' &&
          this.state.sortedInfo.order,
        sorter: (a, b) => moment(a.updated_at || a.created_at).valueOf() - moment(b.updated_at || b.created_at).valueOf(),
        render: (text) => (text ? moment(text).format('MMM-DD-YYYY') : '-'),
      }),
      Map({
        title: 'Action',
        key: 'x',
        render: (text, record, index) => {
          return (
            <div className="actionContainer">
              <button className="avo-dialogue-icons avo-sp-edit" title="Edit Scenario" onClick={() => this.editScenario(record.id)}/>
              {siteGroupId ? (
                <button className="avo-dialogue-icons avo-sp-copy" onClick={() => this.importScenario(record)} />
              ) : (
                <Popconfirm
                  overlayClassName="delete-step-popup"
                  title={`Copy scenario with messages?`}
                  okText="YES"
                  cancelText="NO"
                  trigger="hover"
                  onCancel={() => this.importScenario(record, false)}
                  onConfirm={() => this.importScenario(record, true)}
                >
                  <button className="avo-dialogue-icons avo-sp-copy"/>
                </Popconfirm>
              )}
              <Popconfirm
                overlayClassName="delete-step-popup"
                title={`Scenario will be deleted`}
                okText="YES"
                cancelText="NO"
                trigger="hover"
                onConfirm={() => this.handleDelete(record)}
              >
                <button className="avo-dialogue-icons avo-sp-delete"/>
              </Popconfirm>
            </div>
          );
        },
      }),
    ]);

   if(isMultiCampaignEnabled) {
      const campaignTypeCol = (
        Map({
          title: 'Campaign Type',
          dataIndex: 'campaign_type_id',
          key: 'campaign_type_id',
          sorter: (a, b) => CAMPAIGNS[a.campaign_type_id].localeCompare(CAMPAIGNS[b.campaign_type_id]),
          sortOrder: this.state.sortedInfo.columnKey === 'campaign_type_id' && this.state.sortedInfo.order,
          render: (value, record) => 
            <UpdateCampaignType
              currentUser={currentUser}
              propertyType={propertyType}
              campaignTypeCode={value} 
              recordId={record.id} 
              editId={this.state.editId}
              updateCampaignType={this.updateCampaignId}
              setEditId={this.setEditId}/>,
        })
      );
      columns = columns.insert(1, campaignTypeCol);
    }

    let backToSitesGroup;
    if(this.props.siteGroupRedirect){
        backToSitesGroup = <SiteGroupRedirect redirectToGroup={this.redirectToGroup} siteGroup={this.props.siteGroupRedirect}/>;
    }

    const propertyTag = propertyType && <PropertyTag type={ propertyType }/>;

    let loading;
    if (this.props.pendingRequestsMap.some(request => request)) {
      loading = <Loading />;
    }
    
    let messageType;
    const campaignType = CAMPAIGNS[this.state.editCampaignType] || '';
    if(campaignType) {
      messageType = campaignType.split(' ')[0] || '';
    }
    const errorMessage = <div>
      The <b>scenario</b> you are trying to <b>edit</b> has <b>more</b> than <b>one type of message or message type</b> that is <b>not compatible</b> with <b>{ campaignType }</b>. <br/><br/>
      To <b>assign</b> a campaign type <b>{ campaignType }</b> to this scenario, you need to ensure that the <b>messages</b> are <b>{ messageType } messages</b>.
    </div>;
    const dismissError = <DismissErrorModal error={ errorMessage } isModalVisible={ !!this.state.editCampaignType } title="Update Campaign Type" handleCancel={ () => this.toggleCampaignError("") }/>

    const campaignFilters = isMultiCampaignEnabled && <CampaignsCheckboxFilter currentUser={currentUser} propertyType={propertyType} setCampaignTypes={this.setCampaignTypes}/>;

    let sites = this.props.allSites;
    if (this.props.currentUser.siteAccess) {
      sites = this.props.allSites.filter(s => this.props.currentUser.siteAccess.includes(s.id));
    }

    let switchOptions;
    if (!this.props.location.query.site_group_id) {
      switchOptions = [{
        isChecked: this.state.copyMessages,
        onChecked: this.onChecked,
        popupText: 'Copy with Messages',
        disabled: false,
      }];
    }

    const importScenario = this.state.displayImport && (
      <ImportForm
        siteId={ siteId }
        type="scenario"
        sites={ sites }
        fetchItemsForTheSite={ this.props.getDraftScenarios }
        storedItems={ this.props.scenariosForImport }
        importItem={ this.importScenario }
        closeForm={ this.toggleImport }
        switchOptions={ switchOptions }
        visible={ this.state.displayImport }
        isMultiCampaignEnabled={ isMultiCampaignEnabled }
      />
    );

    return (
      <div className="scenario-v2">
        <div className="scenarios-container scenarios-outer-wrapper">
          { backToSitesGroup }
          <Headline>Scenarios</Headline>
          <div className="sceanrio-domain-wrapper">
            <span className="domain-name">
              <span className="page-label">{siteAndGroupLabel(this.props.location.query.site_group_id)}</span>
              <span className="page-title">{domain}</span>
              { propertyTag }
            </span>

            <div className="scenario-add-options">
              <Button disabled={false} type="secondary" onClick={this.toggleImport}>
                Import
              </Button>
              <Button onClick={this.openTemplateModal} disabled={false} type="primary" style={{marginLeft: '20px'}}>
                New
              </Button>
            </div>
          </div>
          { campaignFilters }
          { siteGroupSiteList }
          <div className="scenario-dashboard-container">
            <div className="search-container">
              {/* <Select
                onChange={this.handleChange}
                defaultValue="name"
                className="search-bar"
                placeholder="Sort By"
                value={this.state.sortValue}
              >
                <Option value="name">Name</Option>
                <Option value="creationdate">Creation Date</Option>
                <Option value="lastupdated">Last Updated</Option>
              </Select> */}
              <Input
                placeholder="Search.."
                onChange={this.updateSearch}
                className="search-bar"
                value={this.state.search}
              />
            </div>
          </div>
          <div className="site-card-border-less-wrapper">
            <ScenarioList
              sortValue={this.state.sortValue}
              dataSource={this.state.filteredScenarios.toJS()}
              columns={columns.toJS()}
              handleSortChange={this.handleSortChange}
              messages={this.state.messages}
            />
            {this.state.showtemplatemodal && <SelectTemplateModal
              visible={this.state.showtemplatemodal}
              onCancelModal={this.onCancelModal}
              columnsTemplate={columnsTemplate.toJS()}
              onFilter={this.filterValueTemplates}
              dataSource={this.state.datatemplates}
              currentUser={currentUser}
              propertyType={propertyType}
              isMultiCampaignEnabled={isMultiCampaignEnabled}
            />}
          </div>
        </div>
        { loading }
        { dismissError }
        { importScenario }
      </div>
    );
  }
}

const mapStateToProps = function(store) {
  const siteId = parseInt(getParameterByName('site_id', window.location));
  return {
    siteId,
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    scenarios: store.scenarioState
      .getIn(['scenarios', 'value'])
      .filter((s) => s.status === STATUS.DRAFT && s.site_id === siteId),
    allSites: store.siteState.getIn(['sites', 'value']),
    accountId: store.accountState.getIn(['accountId', 'value']),
    siteGroup: store.siteState
      .getIn(['siteGroups', 'value'])
      .find((sg) => sg.id === getParameterByName('site_group_id', window.location)),
    siteGroupRedirect: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('sitegroup_id', window.location)),
    siteGroupSitesWithErrors: store.siteState.get('siteGroupSitesWithErrors'),
    scenariosForImport: store.scenarioState.getIn(['scenarios', 'value']).filter(s => s.status === STATUS.DRAFT && s.site_id !== siteId),
    pendingRequestsMap: Map({
      scenarios: store.scenarioState.getIn(['scenarios', 'pending']),
      allSites: store.siteState.getIn(['sites', 'pending']),
    }),
  };
};
export default connect(mapStateToProps, {
  getDraftScenarios,
  getAllSites,
  importScenario,
  deleteScenario,
  deleteSiteGroupScenario,
  getSiteGroup,
  createSiteGroupScenario,
  updateSiteGroupScenario,
  updateScenario,
})(ScenarioDashboard);
