import React, { useState, useEffect } from 'react';
import { List, fromJS } from 'immutable';

import { Select, Table, Tooltip, Checkbox } from 'antd';
const Option = Select.Option;
import { InfoCircleFilled } from '@ant-design/icons';
import { categorize } from '../../util.js';

import { VENDOR_SCAN_CATEGORIZATION, getVCLegalBasisByPurpose } from './helper';

const categorization = VENDOR_SCAN_CATEGORIZATION.map((sc) => {
  return { ...sc, vendorListCategoryIdnNames: [] };
});

function VendorScanMapping(props) {
  const {
    vendorList,
    updateVendorList,
    iabPurposes,
    addCookieToDisclosure,
    updateAddCookieToDisclosure,
    selectedVendors,
    savedVl,
    scannedVendors,
    globalVendors,
  } = props;

  const isGoogleAdproductPresent = savedVl.vendors.find((vendor) => vendor.iab && vendor.iabId === 755);

  const vendors = globalVendors.map((vendor) => {
    const scannedVendor = scannedVendors.find(v => v.id == vendor.id);
    if (scannedVendor) {
      vendor = vendor.merge({ cookies: scannedVendor.cookies });
    }
    return vendor;
  });
  const [vendorScanCategorizations, updateVendorScanCategorizations] = useState(categorization);
  const [purposes, updatePurposes] = useState([]);

  const getPurpose = (c) => {
    const iabPurpose = iabPurposes.find(p => p.iabPurposeRef == c.iabPurposeRef);
    const iabId = iabPurpose ? iabPurpose.iabId : null;
    const colName = c.id ? 'vendorListCategoryId' : 'vendorListCategoryName';
    const colValue = c.id || c.name;
    return {
      purpose: c.name,
      [colName]: colValue,
      type: c.type,
      iabId: c.iabId || iabId,
      disclosureOnly: c.disclosureOnly,
    };
  };

  useEffect(() => {
    let vlPurposes = [];
    if (vendorList.categories.size) {
      vendorList.categories.forEach((c) => {
        if (c.categories.size > 0) {
          c.categories.forEach((sc) => {
            vlPurposes.push(getPurpose(sc));
          });
        } else {
          vlPurposes.push(getPurpose(c));
        }
      });
    } else {
      iabPurposes.toJS().forEach((c) => {
        const updatedCategory = { ...c, id: null };
        vlPurposes.push(getPurpose(updatedCategory));
      });
    }
    updatePurposes(vlPurposes);

    const vlVendorScanCategorizations = vendorList.vendorScanCategorizations.toJS();
    if (vlVendorScanCategorizations.length > 0) {
      const updatedVendorScanCategorizations = vendorScanCategorizations.map((vc) => {
        const mappedPurposes = vlVendorScanCategorizations.filter((vlvc) => {
          const purpose = vlPurposes.find((p) => {
            return (
              (p.vendorListCategoryId &&
                vlvc.vendorListCategoryId &&
                p.vendorListCategoryId == vlvc.vendorListCategoryId) ||
              (p.vendorListCategoryName &&
                vlvc.vendorListCategoryName &&
                p.vendorListCategoryName == vlvc.vendorListCategoryName)
            );
          });
          return vlvc.vendorScanCategory == vc.vendorScanCategory && purpose;
        });
        const vendorListCategoryIdnNames = mappedPurposes.map((p) => {
          return p.vendorListCategoryId || p.vendorListCategoryName;
        });
        return { ...vc, vendorListCategoryIdnNames };
      });
      updateVendorScanCategorizations(updatedVendorScanCategorizations);
    } else {
      let vlScanCatg = [];
      const updatedVendorScanCategorizations = vendorScanCategorizations.map((vc) => {
        const mappedPurposes = vlPurposes.filter((p) => vc.associatedIabPurposesIds.includes(p.iabId));
        const vendorListCategoryIdnNames = mappedPurposes.map((p) => {
          const colName = p.vendorListCategoryId ? 'vendorListCategoryId' : 'vendorListCategoryName';
          const colValue = p.vendorListCategoryId ? p.vendorListCategoryId : p.vendorListCategoryName;
          vlScanCatg.push({
            vendorScanCategory: vc.vendorScanCategory,
            legalBasis: vc.legalBasisValue,
            [colName]: colValue,
          });
          return p.vendorListCategoryId || p.vendorListCategoryName;
        });
        return { ...vc, vendorListCategoryIdnNames };
      });
      updateVendorScanCategorizations(updatedVendorScanCategorizations);
      const updatedVendorList = vendorList.set('vendorScanCategorizations', fromJS(vlScanCatg));
      updateVendorList(updatedVendorList);
    }
  }, []);

  const getFlattenCategories = () => {
    let vlPurposes = [];
    if (savedVl.categories.size) {
      savedVl.categories.forEach((c) => {
        if (c.categories.size > 0) {
          c.categories.forEach((sc) => {
            vlPurposes.push(sc);
          });
        } else {
          vlPurposes.push(c);
        }
      });
    }
    return vlPurposes;
  };

  const flattenCategories = getFlattenCategories();

  const addCookiesToDisclosure = () => {
    let categories = vendorList.categories;
    categories = categories.map(function updateCats(c) {
      const vlPurpose = flattenCategories.find((fc) => (fc.id || fc.name) == (c.id || c.name));
      const updatedCategory = c
        .update('vendorCategorization', (vc) => {
          const newCategorizations = vc.map((categ) => {
            const isSelectedVendor = selectedVendors.includes(categ.vendorId);
            if (isSelectedVendor) {
              const isExistingVendor = savedVl.vendors.find((v) => selectedVendors.includes(v.id));
              const scannedCookies = vendors
                .find((v) => v.id == categ.vendorId)
                .cookies.map((sc) => {
                  return {
                    ...sc.toJS(),
                    key: Math.random(),
                    id: Math.random(),
                    vendorId: categ.vendorId,
                    category: c.name,
                  };
                });
              if (isExistingVendor) {
                const categorization = vlPurpose.vendorCategorization.find((vlCat) =>
                  selectedVendors.includes(vlCat.vendorId)
                );
                if (categorization) {
                  const filteredCookies = categorization.cookies.filterNot((fc) =>
                    scannedCookies.find((sc) => sc.name == fc.name)
                  );
                  const updatedCookies = filteredCookies.concat(scannedCookies);
                  categ = categorization.set('cookies', updatedCookies);
                } else {
                  categ = categ.set('cookies', scannedCookies);
                }
                return categorization;
              } else {
                categ = categ.set('cookies', scannedCookies);
              }
            }
            return categ;
          });

          return newCategorizations;
        })
        .update('categories', (cats) => cats.map(updateCats));
      return updatedCategory;
    });

    const wrappers = vendorList.vendorsWrappers.map((vw) => {
      const selectedVendor = selectedVendors.includes(vw.vendorId);
      if (selectedVendor) {
        const isExistingVendor = savedVl.vendorsWrappers.find((svw) => svw.vendorId == vw.vendorId);
        const scannedCookies = vendors.find((v) => v.id == vw.vendorId).cookies;
        if (isExistingVendor) {
          const filteredCookies = isExistingVendor.cookies.filterNot((fc) =>
            scannedCookies.find((sc) => sc.name == fc.name)
          );
          const updatedCookies = filteredCookies.concat(scannedCookies);
          return isExistingVendor.set('cookies', updatedCookies);
        } else {
          vw = vw.set('cookies', scannedCookies);
        }
      }
      return vw;
    });

    const updatedVendorList = vendorList.set('categories', categories).set('vendorsWrappers', wrappers);
    return updatedVendorList;
  };

  const rmCookiesFromDisclose = () => {
    let categories = vendorList.categories;
    categories = categories.map(function updateCats(c) {
      const vlPurpose = flattenCategories.find((fc) => (fc.id || fc.name) == (c.id || c.name));
      const updatedCategory = c
        .update('vendorCategorization', (vc) => {
          const newCategorizations = vc.map((categ) => {
            const isSelectedVendor = selectedVendors.includes(categ.vendorId);
            if (isSelectedVendor) {
              const isExistingVendor = savedVl.vendors.find((v) => selectedVendors.includes(v.id));
              if (isExistingVendor) {
                const categorization = vlPurpose.vendorCategorization.find((vlCat) =>
                  selectedVendors.includes(vlCat.vendorId)
                );
                if (categorization) {
                  categ = categorization;
                } else {
                  categ = categ.set('cookies', List());
                }
                return categorization;
              } else {
                categ = categ.set('cookies', List());
              }
            }
            return categ;
          });

          return newCategorizations;
        })
        .update('categories', (cats) => cats.map(updateCats));
      return updatedCategory;
    });

    const wrappers = vendorList.vendorsWrappers.map((vw) => {
      const selectedVendor = selectedVendors.includes(vw.vendorId);
      if (selectedVendor) {
        const isExistingVendor = savedVl.vendorsWrappers.find((svw) => svw.vendorId == vw.vendorId);
        if (isExistingVendor) {
          return isExistingVendor;
        } else {
          vw = vw.set('cookies', List());
        }
      }
      return vw;
    });

    const updatedVendorList = vendorList.set('categories', categories).set('vendorsWrappers', wrappers);
    return updatedVendorList;
  };

  const handleAddCookieToDisclosure = (e) => {
    updateAddCookieToDisclosure(e.target.checked);
    const updatedVendorList = e.target.checked ? addCookiesToDisclosure() : rmCookiesFromDisclose();
    updateVendorList(updatedVendorList);
  };

  const renderLegalBasis = (value, categorization) => {
    return <span className={categorization.cssClass}>{value}</span>;
  };

  const updateVlVendorScanCategorization = (categorization) => {
    const filteredVendorScanCategorization = vendorList.vendorScanCategorizations
      .toJS()
      .filter((vsc) => vsc.vendorScanCategory != categorization.vendorScanCategory);
    const updatedCategorization = categorization.vendorListCategoryIdnNames.map((vsc) => {
      const purpose = purposes.find((p) => vsc == (p.vendorListCategoryId || p.vendorListCategoryName));
      const colName = purpose.vendorListCategoryId ? 'vendorListCategoryId' : 'vendorListCategoryName';
      const colValue = purpose.vendorListCategoryId ? purpose.vendorListCategoryId : purpose.vendorListCategoryName;
      return {
        vendorScanCategory: categorization.vendorScanCategory,
        legalBasis: categorization.legalBasisValue,
        [colName]: colValue,
      };
    });
    const vlVendorScanCategorizations = fromJS(filteredVendorScanCategorization.concat(updatedCategorization));
    return vendorList.set('vendorScanCategorizations', vlVendorScanCategorizations);
  };

  const handlePurposes = (values, index) => {
    let categorization = _.cloneDeep(vendorScanCategorizations);
    categorization[index].vendorListCategoryIdnNames = values;

    let updatedVendorList = updateVlVendorScanCategorization(categorization[index]);
    const oldCategorizedPurposes = vendorScanCategorizations[index].vendorListCategoryIdnNames;
    const selectedDeselectedValue = oldCategorizedPurposes.filter(x => !values.includes(x)).concat(values.filter(x => !oldCategorizedPurposes.includes(x)))[0];

    if (oldCategorizedPurposes.length < values.length) {
      updatedVendorList = onPurposeSelect(selectedDeselectedValue, updatedVendorList);
    } else {
      updatedVendorList = onPurposeDeselect(selectedDeselectedValue, updatedVendorList);
    }

    updateVendorList(updatedVendorList);
    updateVendorScanCategorizations(categorization);
  };

  const getCategorization = (vendor, category, updatedVendorList) => {
    let scanVCLegalBasis;
    if (updatedVendorList.vendorScanCategorizations.size && vendor.vendorType == 'CUSTOM') {
      const purpose = updatedVendorList.vendorScanCategorizations
        .toJS()
        .find(
          (vc) =>
            (vc.vendorListCategoryId || vc.vendorListCategoryName) ==
            (category.id || category.name)
        );
      scanVCLegalBasis = purpose ? purpose.legalBasis : undefined;
    } else if (
      !updatedVendorList.vendorScanCategorizations.size &&
      category.type == 'IAB_PURPOSE' &&
      vendor.vendorType == 'CUSTOM'
    ) {
      const vendorScanMapping = getVCLegalBasisByPurpose(c.iabId);
      scanVCLegalBasis = vendorScanMapping.legalBasisValue;
    }
    return categorize(
      category,
      {
        id: vendor.id,
        vendorType: vendor.vendorType,
        name: vendor.name,
        purposes: vendor.purposes.map(({ id }) => id),
        legIntPurposes: vendor.legIntPurposes.map(({ id }) => id),
        cookies: vendor.cookies,
      },
      updatedVendorList.defaultLegalBasis,
      scanVCLegalBasis
    );
  };

  const onPurposeSelect = (value, updatedVendorList) => {
    const customSelectedVendors = vendors.filter((v) => {
      if (isGoogleAdproductPresent) {
        return selectedVendors.includes(v.id) && v.vendorType == 'CUSTOM' && !v.isGoogleAtp;
      }
      return selectedVendors.includes(v.id) && v.vendorType == 'CUSTOM';
    });
    let categories = updatedVendorList.categories;
    categories = categories.map(function updateCats(c) {
      const selectedPurpose = (c.id || c.name) == value;
      const updatedCategory = c
        .update('vendorCategorization', (vc) => {
          if (!selectedPurpose) {
            return vc;
          }
          const newCategorizations = customSelectedVendors
            .filter((sv) => !vc.find((vcv) => vcv.vendorId == sv.id))
            .map((vendor) => {
              return getCategorization(vendor, c, updatedVendorList);
            });
          const oldCategorizations = vc.map((categ) => {
            let vendor = customSelectedVendors.find((v) => v.id == categ.vendorId);
            if (vendor) {
              const cookiesFromScan = vendor.cookies;
              const filteredCookies = categ.cookies.filterNot((c) => {
                return cookiesFromScan.find((sc) => sc.name == c.name);
              });
              cookies = filteredCookies.concat(cookiesFromScan);
              vendor = vendor.set('cookies', cookies);
              return getCategorization(vendor, c, updatedVendorList);
            }
            return categ;
          });
          return newCategorizations.concat(oldCategorizations).toList();
        })
        .update('categories', (cats) => cats.map(updateCats));
      return updatedCategory;
    });
    return updatedVendorList.set('categories', categories);
  };

  const onPurposeDeselect = (value, updatedVendorList) => {
    let categories = updatedVendorList.categories;
    const customSelectedVendorsIds = updatedVendorList.vendors
      .filter((v) => {
        if (isGoogleAdproductPresent) {
          return selectedVendors.includes(v.id) && v.vendorType == 'CUSTOM' && !v.isGoogleAtp;
        }
        return selectedVendors.includes(v.id) && v.vendorType == 'CUSTOM';
      })
      .map((v) => v.id);
    categories = categories.map(function updateCats(c) {
      const deselectedPurpose = (c.id || c.name) == value;
      let purposeMappedAgainstCategories = [];
      if (deselectedPurpose) {
        purposeMappedAgainstCategories = vendorScanCategorizations.filter((vsc) =>
          vsc.vendorListCategoryIdnNames.includes(value)
        );
      }
      const updatedCategory = c
        .update('vendorCategorization', (vc) => {
          const newCategorizations = vc.filter((categ) => {
            if (
              customSelectedVendorsIds.includes(categ.vendorId) &&
              deselectedPurpose &&
              purposeMappedAgainstCategories.length == 0
            ) {
              return false;
            }
            return true;
          });
          return newCategorizations.toList();
        })
        .update('categories', (cats) => cats.map(updateCats));
      return updatedCategory;
    });
    return updatedVendorList.set('categories', categories);
  };

  const renderPurposes = (value, categorization, index) => {
    let oppLegalBasis;
    let keepIabPurposes = true;
    let skipDisclosureOnly = false;
    if (categorization.legalBasisValue == 'DISCLOSURE_ONLY') {
      oppLegalBasis = 'CONSENT';
      keepIabPurposes = false;
    } else {
      oppLegalBasis = 'DISCLOSURE_ONLY';
      skipDisclosureOnly = true;
    }
    const flattenedPurposes = vendorScanCategorizations
      .filter((vc) => vc.legalBasisValue == oppLegalBasis)
      .map((vc) => vc.vendorListCategoryIdnNames)
      .flat();
    const uniquePurposes = [...new Set(flattenedPurposes)];
    const filteredPurposes = purposes.filter(
      (p) => {
        return !uniquePurposes.includes(p.vendorListCategoryId || p.vendorListCategoryName) &&
          (keepIabPurposes ? true : p.type !== 'IAB_PURPOSE') &&
          (skipDisclosureOnly ? !p.disclosureOnly : p.disclosureOnly);
      }
    );
    return (
      <Select
        dropdownClassName="custom-dropdown"
        mode="multiple"
        maxTagCount={1}
        maxTagTextLength={35}
        maxTagPlaceholder={`+${categorization.vendorListCategoryIdnNames.length - 1}`}
        placeholder="Select Purpose(s)"
        notFoundContent="No purposes for selection as they can not be mapped against multiple legal basis"
        showArrow={true}
        value={value}
        onChange={(values) => handlePurposes(values, index)}
      >
        {filteredPurposes.map((p, pi) => (
          <Option key={pi} value={p.vendorListCategoryId || p.vendorListCategoryName}>
            {p.purpose}
          </Option>
        ))}
      </Select>
    );
  };

  const columns = [
    {
      title: 'Vendor Scan Category',
      dataIndex: 'vendorScanCategory',
      key: 'vendorScanCategory',
      width: '25%',
    },
    {
      title: 'Purposes',
      dataIndex: 'vendorListCategoryIdnNames',
      key: 'vendorListCategoryIdnNames',
      render: renderPurposes,
      width: '50%',
    },
    {
      title: 'Legal Basis',
      dataIndex: 'legalBasis',
      key: 'legalBasis',
      render: renderLegalBasis,
      width: '25%',
    },
  ];

  const cookieDisclosureInfo =
    'By selecting this option, cookies identified via scanning will be added to the cookie disclosure that will display below vendors in the vendor list section of the Privacy Manager';

  return (
    <div className="vendor-category-mapping-wrapper">
      <span className="header">Vendor Scan Mapping</span>
      <span className="recommended-settings-note">
        <b>Note:</b> Recommended settings are applied, you can change those settings below. You can apply more granular
        mappings at a vendor level at any time in the vendor settings panel.
      </span>
      <span className="note">
        Vendors identified via scanning are associated to vendor scan categories by default. To associate these vendors
        to IAB and custom purposes you can either leverage recommended mapping described below or you can apply
        different settings by changing the drop down values below. You can apply more granular mapping at a vendor level
        at any time in the vendor settings panel.
      </span>
      {/* <div className="cookie-disclosure-wrapper">
        <Checkbox checked={addCookieToDisclosure} defaultChecked onChange={handleAddCookieToDisclosure}>
          Add cookies returned via scans to cookie disclosure
        </Checkbox>
        <Tooltip overlayClassName="cookie-disclosure-tooltip" placement="bottomLeft" title={cookieDisclosureInfo}>
          <InfoCircleFilled />
        </Tooltip>
      </div> */}
      <Table rowKey={record => record.vendorScanCategory} dataSource={vendorScanCategorizations} columns={columns} pagination={false} />
    </div>
  );
}

export default VendorScanMapping;
