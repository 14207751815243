import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import StepSatisfyOption from './StepSatisfyOption';
import { StringValueKVGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js'

export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(StringValueKVGate).isRequired,
        onChange: PropTypes.func.isRequired
    };

    componentDidMount() {
        if (this.props.editing) ReactDOM.findDOMNode(this.refs.key).focus();
    }

    handleChange = (newData) => {
        this.props.onChange(this.props.data.merge(newData));
    };

    handleChangeSatisfy = (continueOnSatisfy) => {
        this.handleChange({ allow_on_satisfy: continueOnSatisfy })
    };

    handleTypeChange = (e) => {
        this.handleChange({ type: e.target.value })
    };

    capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }

    render() {
        var continueOnSatisfy = this.props.data.allow_on_satisfy ? "Yes" : "No";

        if (!this.props.editing) {
            // this.props.data.type is null when scenario without "type" is duplicated
            return (
                <div>
                  Key: { this.props.data.k },
                  Value: { this.props.data.v },
                  Type: { this.props.data.type ? this.capitalize(this.props.data.type) : 'Match' },
                  Continue On Match: { continueOnSatisfy }
                </div>
            );
        } else {
            return (
                <div className="step kv-gate continue-halt">
                  <label>Key:</label>
                  <input ref="key" type="text" value={ this.props.data.k } onChange={ (e) => this.handleChange({ k: e.target.value }) }></input>

                  <label>Value:</label>
                  <input type="text" value={ this.props.data.v } onChange={ (e) => this.handleChange({ v: e.target.value }) }></input>

                  <label>Type:
                    <select value={ this.props.data.type } onChange={ this.handleTypeChange }>
                      <option value='match'>Match</option>
                      <option value='contain'>Contain</option>
                    </select>
                  </label>

                  <label>Continue On Match:
                    <StepSatisfyOption
                      value={ this.props.data.allow_on_satisfy }
                      onChange={ this.handleChangeSatisfy }
                    />
                  </label>

                </div>
            );
        }
    }
}
