import React from 'react';
import MessageStep from './MessageStep';

export default class ShowMessageAlways extends React.Component {
  render() {
    return (
      <MessageStep
        {...this.props}
        label={ 'Show Message Always' }
      />
    );
  }
}
