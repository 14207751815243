import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'antd';

import ContinueSatisfy from './ContinueSatisfy';

import { ContinueRecord, ContinueOnSatisfyRecord } from '../../../../../constants.js';

export default class BooleanStep extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    data: PropTypes.oneOfType([
      PropTypes.instanceOf(ContinueRecord).isRequired,
      PropTypes.instanceOf(ContinueOnSatisfyRecord).isRequired,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    labelText: PropTypes.string.isRequired,
    affirmativeText: PropTypes.string,
    negativeText: PropTypes.string,
    propertyName: PropTypes.string,
  };

  static defaultProps = {
    affirmativeText: 'Yes',
    negativeText: 'No',
    propertyName: 'continue',
  }

  handleChangeSatisfy = (continueOnSatisfy) => {
    const data = this.props.data.set(this.props.propertyName, continueOnSatisfy);
    this.props.onChange(data);
  };

  render() {
    const dataContinue = this.props.data.get(this.props.propertyName);
    const readContinueText = dataContinue ? this.props.affirmativeText : this.props.negativeText;

    if (!this.props.editing) {
      const fullText = this.props.labelText + ': ' + readContinueText;
      const shortenedText = fullText.length > 30 ? fullText.slice(0, 30) + '...' : fullText;
      return (
        <Tooltip title={ fullText } >
          <div>
            { shortenedText }
          </div>
        </Tooltip>
      );
    } else {
      return (
        <div>
          { this.props.children }
          <ContinueSatisfy
            value={ dataContinue }
            onChange={ this.handleChangeSatisfy }
            affirmativeText={ this.props.affirmativeText }
            negativeText={ this.props.negativeText }
          />
        </div>
      );
    }
  }
}
