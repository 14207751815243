import { useState } from 'react';

export default function useTableDisplayConfig(initialLimit = 10, initialPage = 1, initialOrder = 'name_asc') {
  const [limit, setLimit] = useState(initialLimit);
  const [page, setPage] = useState(initialPage);
  const [orderBy, setOrderBy] = useState(initialOrder);

  const handleLimit = (newLimit) => {
    if (newLimit !== limit) {
      setPage(1);
      setLimit(newLimit);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleOrder = (sorter) => {
    if (!_.isEmpty(sorter)) {
      const orderVal = `${sorter.field}_${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
      if (orderVal !== orderBy) {
        setPage(1);
      }
      setOrderBy(orderVal);
    }
  };

  return { limit, page, orderBy, handleLimit, handlePageChange, handleOrder, setOrderBy, setLimit };
}
