import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';
import { CategoryView } from '../../../records/privacy_manager_records';
import { Purpose } from '../../../records/vendor_list_records';
import ConfigurePurposeTab from '../vendor_list/ConfigurePurposeTab';

export default class OverridePurposeNamingModal extends React.Component {
  static propTypes = {
    purpose: PropTypes.instanceOf(Purpose).isRequired,
    closeModal: PropTypes.func.isRequired,
    handleApply: PropTypes.func.isRequired,
    categoryView: PropTypes.instanceOf(CategoryView).isRequired,
    readOnly: PropTypes.bool.isRequired,
  }

  state = {
    categoryView: this.props.categoryView,
  }

  updatePurpose = (categoryView) => {
    this.setState({ categoryView });
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  handleOnOk = () => {
    this.props.closeModal();
    const cv = this.state.categoryView;
    this.props.handleApply(this.state.categoryView
                            .set('name', cv.name ? cv.name : this.props.purpose.name)
                            .set('description', cv.description ? cv.description : this.props.purpose.description)
                          );
  }

  render() {
    return (
      <Modal
        onOk={ this.handleOnOk }
        onCancel={ this.handleCancelModalChanges }
        okText='Apply changes'
        className='edit-purpose'
        closable={ false }
        destroyOnClose
        visible
      >
        <ConfigurePurposeTab
          overrideNameAndDescriptionOnly
          purpose={ this.props.purpose }
          categoryView={ this.state.categoryView }
          updatePurpose={ this.updatePurpose }
          readOnly={ this.props.readOnly }
        />
      </Modal>
    );
  }
}
