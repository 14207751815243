import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Button } from 'antd';
import { ConsentCompensationList } from '../../../records/consent_compensation_records';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import WarningMessage from '../../common/WarningMessage.js.jsx';
import { displayErrors } from '../helper';
import IsActive from '../../common/IsActive';
import Loading from '../../common/Loading.js.jsx';

export default class ConsentCompensationListRow extends React.Component {
  static propTypes = {
    consentCompensationList: PropTypes.instanceOf(ConsentCompensationList).isRequired,
    handleDelete: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
  }

  state = {
    warningMessagePresent: false,
  };

  handleEdit = () => {
    browserHistory.push('/consent_v2/compensation_list/edit?id=' + this.props.consentCompensationList.id);
  };

  handleDelete = (e) => {
    //get rid of then
    e.stopPropagation();
    this.props.handleDelete(this.props.accountId, this.props.consentCompensationList)
      .then((resp) => {
        this.setState({ warningMessagePresent: false });
      });
  }

  toggleWarningMessage = (e) => {
    e.stopPropagation();
    this.setState({ warningMessagePresent: !this.state.warningMessagePresent });
  }

  render() {
    let editButtonText;
    let editDropdown
    if (!this.props.readOnly) {
      const editMenu = (
        <Menu className="buttons-group">
          <Menu.Item>
            <div
              onClick={ this.toggleWarningMessage }
              className='menu-item-container'
            >
              <CustomIcon
                type={ CustomIcon.types.DELETE }
                onClick={ this.toggleWarningMessage }
              />
            </div>
          </Menu.Item>
        </Menu>
      );
      editDropdown = (
        <Dropdown
          overlay={ editMenu }
          onOpenChange={ this.handleVisibleChange }
        >
          <EllipsisOutlined
            style={{
              float: 'right',
            }} />
        </Dropdown>
      );
      editButtonText = 'Edit';
    } else {
      editButtonText = 'View';
    }


    let warningMessage;
    if (this.state.warningMessagePresent) {
        warningMessage = <WarningMessage handleDelete={ this.handleDelete } name={ this.props.consentCompensationList.name } handleCancel={ this.toggleWarningMessage } />
    }

    const activeStatus = (
      <IsActive
        isActive={ this.props.consentCompensationList.isActive }
      />
    );

    let loading;
    if (this.props.pending) {
      loading = <Loading />;
    }

    return (
      <div
        className='vendor-list-container'
        onClick={ this.handleEdit }
      >
        { loading }
        { warningMessage }
        <div className='name-container pm'>
          <p className='name'>{ this.props.consentCompensationList.name }</p>
          { activeStatus }
        </div>
        <div className='menu-container'>
          <Button
            onClick={ this.handleEdit }
            className='edit'
          >
            { editButtonText }
          </Button>
          { editDropdown }
        </div>
      </div>
    );
  }
}
