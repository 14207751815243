import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Tabs, Button } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Purpose, Vendor } from '../../../../../records/vendor_list_records';
import ConfigurePurposeTab from '../../ConfigurePurposeTab';
import ActionsTab from '../../../../common/ActionsTab';

const TabPane = Tabs.TabPane;

export default class EditPurposeModal extends React.Component {
  static propTypes = {
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)).isRequired,
    purpose: PropTypes.instanceOf(Purpose).isRequired,
    closeModal: PropTypes.func.isRequired,
    handleApply: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    allListVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    globalVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    readOnly: PropTypes.bool.isRequired,
    purposeIndex: PropTypes.number.isRequired,
    deletePurpose: PropTypes.func.isRequired,
  }

  state = {
    extraUrlField: false,
    purpose: this.props.purpose,
  }

  updatePurpose = (purpose) => {
    this.setState({ purpose });
  }

  updateActions = (actions, actionType) => {
    const purpose = this.state.purpose.set(actionType, actions);
    this.setState({ purpose });
  }

  handleCancelModalChanges = () => {
    this.setState({ purpose: this.props.purpose });
    this.props.closeModal();
  }

  handleOnOk = () => {
    this.props.handleApply(this.state.purpose);
  }

  handleRemove = () => {
    this.props.deletePurpose(this.props.purposeIndex);
  }

  updateActionExtension = (type, key) => (value) => {
    const updatedPurpose = this.state.purpose.update(`${type}ActionExtension`, (ext) => ({ ...ext, [key]: value }));
    this.setState({ purpose: updatedPurpose });
  }

  render() {
    const p = this.state.purpose;

    // TODO: upgrade antd and use okButtonProps={{ disabled: true }}

    return (
      <Modal
        destroyOnClose
        visible={this.props.visible}
        className='edit-purpose'
        closable={false}
        width={700}
        footer={[
          <Button key="back" onClick={this.handleCancelModalChanges}>Cancel</Button>,
          <Button className="purpose-delete" key="delete" onClick={this.handleRemove}>Remove</Button>,
          <Button key="submit" type="primary" onClick={this.handleOnOk}>Apply changes</Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Configure Purpose" key="1">
            <ConfigurePurposeTab
              purpose={p}
              updatePurpose={this.updatePurpose}
              iabPurposes={this.props.iabPurposes}
              allListVendors={this.props.allListVendors}
              globalVendors={this.props.globalVendors}
              readOnly={this.props.readOnly}
              actionCookies={this.props.actionCookies}
              updateActionCookies={() => { }}
            />
          </TabPane>
          <TabPane tab="Consent Actions" key="2">
            <ActionsTab
              isCcpa
              readOnly={this.props.readOnly}
              actions={p.consentActions}
              geoTargeting={p.consentActionExtension.geoTargeting}
              onStatusChangeOnly={p.consentActionExtension.onStatusChangeOnly}
              actionCookies={p.consentActionExtension.cookies}
              updateActions={(actions) => this.updateActions(actions, 'consentActions')}
              updateGeoTargeting={this.updateActionExtension('consent', 'geoTargeting')}
              updateOnStatusChangeOnly={this.updateActionExtension('consent', 'onStatusChangeOnly')}
              updateActionCookies={this.updateActionExtension('consent', 'cookies')}
            />
          </TabPane>
          <TabPane tab="Reject Actions" key="3">
            <ActionsTab
              readOnly={this.props.readOnly}
              isRejectActions
              isCcpa
              actionsType="rejectActions"
              actions={p.rejectActions}
              geoTargeting={p.rejectActionExtension.geoTargeting}
              onStatusChangeOnly={p.rejectActionExtension.onStatusChangeOnly}
              actionCookies={p.rejectActionExtension.cookies}
              updateActions={(actions) => this.updateActions(actions, 'rejectActions')}
              updateGeoTargeting={this.updateActionExtension('reject', 'geoTargeting')}
              updateOnStatusChangeOnly={this.updateActionExtension('reject', 'onStatusChangeOnly')}
              updateActionCookies={this.updateActionExtension('reject', 'cookies')}
            />
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}
