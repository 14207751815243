import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';

import CustomButton from '../../common/CustomButton.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import { clearCampaignViewState } from '../../../actions/campaign_actions';

export default class extends React.Component {
  static propTypes = {
    site: PropTypes.object,
    clearCampaignViewState: PropTypes.func,
    onDelete: PropTypes.func,
  };

  handleManageSite = () => {
    clearCampaignViewState();
    browserHistory.push('/dialogue/campaigns?site_id=' + this.props.site.id);
  };

  render() {
    const deleteButton = (typeof this.props.onDelete === 'function') &&
    <td>
      <CustomIcon
        type={ CustomIcon.types.DELETE }
        onClick={ this.props.onDelete }
        tooltip="delete"
      />
    </td>;

    return (
      <tr>
        <td onClick={ this.handleManageSite }>{ this.props.site.domain || this.props.site.name }</td>
        { deleteButton }
      </tr>
    );
  }
}
