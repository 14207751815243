import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ pages, handlePageClick }) => {
  return (<div className="pagination-container">
    <ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pages}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      onPageChange={(data) => handlePageClick(data)}
      containerClassName={'pagination'}
      activeClassName={'active'}
    />
  </div>
  );
};

export default Pagination;
