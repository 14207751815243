import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Site from './Site.js.jsx';
import SiteGroup from './SiteGroup.jsx';
import WarningMessage from '../../common/WarningMessage.js.jsx';

export default class SiteList extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    siteGroups: PropTypes.bool,
    delete: PropTypes.func.isRequired,
    
  }

  static defaultProps = {
    siteGroups: false,
  }

  state = {
    siteToDelete: null,
  };

  setSiteToDelete = (siteToDelete) => {
    this.setState({ siteToDelete });
  };

  delete = (siteToDelete) => {
    this.props.delete(siteToDelete.id, siteToDelete.siteId, siteToDelete.domain || siteToDelete.name);
    this.setSiteToDelete(null);
  };

  render() {
    let dataLossWarning;
    if (this.props.siteGroups) {
      dataLossWarning = <p>All shared campaigns, messages and scenarios will still be available for use within the individual sites that were in this group.</p>;
    } else {
      dataLossWarning = <p>Data and configurations will be saved if you recreate the site.</p>;
    }
    const warningMessage = this.state.siteToDelete != null && (
      <WarningMessage
        handleDelete={ this.delete.bind(this, this.state.siteToDelete) }
        name={ this.state.siteToDelete.domain || this.state.siteToDelete.name }
        handleCancel={ this.setSiteToDelete.bind(this, null) }
        dataLossWarning={ dataLossWarning }
      />
    );
    const sitesWithoutSiteGroups = this.props.sites.filter(s => s.type !== 'property_group' && (s.domain && !s.domain.includes('-group-')));

    return (
      <div className="redesign">
        <table className="table site-list card">
          <tbody>
            {
              this.props.siteGroups ?
              this.props.sites.map((site, i) => (
                <SiteGroup
                  key={ site.id }
                  sg={ site }
                  onDelete={
                    this.props.isAdmin ?
                    this.setSiteToDelete.bind(this, site) :
                    null
                  }
                  setSiteGroupToEdit={ this.props.setSiteGroupToEdit }
                />
              )) :
                 
             this.props.filterKey? 
             sitesWithoutSiteGroups.filter((site) => (site.type == this.props.filterKey)).map((site, i) => {
                return(
                <Site
                  key={ site.id }
                  site={ site }
                  onDelete={
                    this.props.isAdmin ?
                    this.setSiteToDelete.bind(this, site) :
                    null
                  }
                />
              )
                })
                :
                sitesWithoutSiteGroups.map((site, i) => {
                  return(
                  <Site
                    key={ site.id }
                    site={ site }
                    onDelete={
                      this.props.isAdmin ?
                      this.setSiteToDelete.bind(this, site) :
                      null
                    }
                  />
                )
                  })
            }
          </tbody>
        </table>
        { warningMessage }
      </div>
    );
  }
}