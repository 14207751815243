// getConsentCompensationListsAjax,
// getConsentCompensationListAjax,
// createConsentCompensationListAjax,
// updateConsentCompensationListAjax,
// deleteConsentCompensationListAjax,


import 'whatwg-fetch';
import { List, OrderedSet, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from './helper.js';
import { FETCHED } from '../constants';
import { ConsentCompensationList } from '../records/consent_compensation_records';
import { Vendor, VendorListError } from '../records/vendor_list_records';

// const getConsentCompensationListBaseUrl = () => {
//   return window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org';
// }
const getConsentCompensationListBaseUrl = () => {
  // return 'http://localhost:5000'; 
  // return 'https://consent-compensation-api.sp-stage.net'
    // return 'https://consent-compensation-api.sp-prod.net'

  if (window.CONFIG && window.CONFIG.consentCompensationOrigin) {
    return window.CONFIG.consentCompensationOrigin;
  } else {
    return 'https://consent-compensation-api.sp-stage.net';
  }
};

// const getConsentCompensationListBaseUrl = () => 'https://sourcepoint.mgr.consensu.org';

// const getConsentCompensationListBaseUrl = () => 'http://192.168.7.45:3000';

// const getConsentCompensationListBaseUrl = () => 'https://cmp.sp-stage.net';

export function getPartnersAjax() {
  const url = '/vendors';
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
    return fetch(window.CONFIG.consentOrigin + url)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        // addToFetched(url);
        return OrderedSet(resp.map(vendor => new Vendor(vendor)))
          .filter(v => v.vendorType === "IAB");
      });
  // }
}

export function getConsentCompensationListsAjax(accountId, siteAccess) {
  let url = '/consent-compensation-list/' + accountId;
  if (siteAccess) {
    url += '?siteIds=' + JSON.stringify(siteAccess);
  }

  // caching temp removed to accomodate V2
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
    return fetch(getConsentCompensationListBaseUrl() + url)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        // addToFetched(url);
        return OrderedSet(resp.map(v => new ConsentCompensationList(v) ))
      });
  // }
}

export function getConsentCompensationListAjax(id, accountId) {
  const url = `/consent-compensation-list?consentCompensationListId=${id}`;
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
    return fetch(getConsentCompensationListBaseUrl() + url)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        // addToFetched(url);
        return new ConsentCompensationList(resp);
      });
  // }
}

export function createConsentCompensationListAjax(accountId, list) {
  return fetch(getConsentCompensationListBaseUrl() + '/consent-compensation-list', {
    method: 'POST',
    body: JSON.stringify(generateConsentCompensationListData(accountId, list)),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(checkConsentCompensationListStatus)
    .then(resp => resp.json())
    .then(resp => {
      const updatedResp = Object.assign({}, resp);
      return new ConsentCompensationList(updatedResp);
    });
}

export function updateConsentCompensationListAjax(accountId, list) {
  return fetch(getConsentCompensationListBaseUrl() + '/consent-compensation-list', {
    method: 'PUT',
    body: JSON.stringify(generateConsentCompensationListData(accountId, list)),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(checkConsentCompensationListStatus)
    .then(resp => {return resp.json()})
    .then(resp => new ConsentCompensationList(resp));
}

async function checkConsentCompensationListStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    const errors = await response.json();
    throw new List(errors).map(err => new VendorListError(err));
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function deleteConsentCompensationListAjax(accountId, { id }) {
  return fetch(getConsentCompensationListBaseUrl() + '/consent-compensation-list/' + accountId + '/' + id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ accountId }),
  })
    .then(checkConsentCompensationListStatus)
    .then(resp => resp.json())
    .then(() => id)
    .catch((e) => {
    });
}


function generateConsentCompensationListData(accountId, list) {

  const vendorsWrappers = list.vendorsWrappers.map(vw => {
    const id = vw.id;
    let updatedwv = vw.toMap().delete('id');
    if (id) {
      updatedwv = updatedwv.set('_id', id);
    }
    return updatedwv;
  }).toJS();
  const data = {
    accountId: accountId.toString(),
    name: list.name,
    description: list.description,
    siteIds: list.siteIds.toSet().toJS(),
    isActive: list.isActive,
    vendorsWrappers,
  };

  if (list.id) {
    data._id = list.id;
  }

  if (list.isDeleted) {
    data.isDeleted = true;
  }

  return data;
}