import React from 'react';
import { List, Map, fromJS } from 'immutable';
import { Select, Button } from 'antd';
import { Modal } from '../../../styleguide';
import {
    ArrowLeftOutlined,
  } from '@ant-design/icons';
const { Option } = Select

export default class PublisherPurposesModal extends React.Component {

    state = {
        vl: this.props.vendorList,
    }

    setConfigureValue = (id, value) => this.setState({ [id]: value });

    componentDidMount() {
        let consentPurposes = List();
        let legIntPurposes = List();
        let publisherPurposes = fromJS(this.state.vl.get('publisherPurposes'));
        if (publisherPurposes != undefined && publisherPurposes.get('consentPurposes') != undefined && publisherPurposes.get('legIntPurposes') != undefined) {
            consentPurposes = fromJS(publisherPurposes.get('consentPurposes'));
            legIntPurposes = fromJS(publisherPurposes.get('legIntPurposes'));
            this.props.iabPurposes.map((item) => {
                const iabId = item.get('iabId');
                if (consentPurposes.includes(iabId)) {
                    this.setConfigureValue(iabId, 'CONSENT');
                } else if (legIntPurposes.includes(iabId)) {
                    this.setConfigureValue(iabId, 'LEGITIMATE_INTEREST');
                } else {
                    this.setConfigureValue(iabId, 'NOT_APPLICABLE');
                }
            })
        } else {
            this.props.iabPurposes.map((item) => {
                const iabId = item.get('iabId');
                this.setConfigureValue(iabId, 'Select');
            })
        }
    }

    saveConfig = () => {
        let consentPurposes = List();
        let legIntPurposes = List();
        this.props.iabPurposes.map((item) => {
            const iabId = item.get('iabId');
            const consentType = this.state[iabId];
            if (consentType === 'CONSENT') {
                consentPurposes = consentPurposes.push(iabId);
            } else if (consentType === 'LEGITIMATE_INTEREST') {
                legIntPurposes = legIntPurposes.push(iabId);
            }
        })
        let publisherPurposes = new Map({ 'consentPurposes': consentPurposes.toJS(), 'legIntPurposes': legIntPurposes.toJS() });
        this.props.updatePublisherPurposesConsent(publisherPurposes);
        this.props.closeModal();
    }
    
  renderFooter = () => (
    <div className="footer-container">
      <div className="footer-btn-container">
        <Button onClick={this.props.closeModal}>{this.props.readOnly? "Close" : "Cancel"}</Button>
       {!this.props.readOnly ? <Button type="primary" onClick={this.saveConfig}>Apply</Button> : null}
      </div>
    </div>
  );


    render() {
        return (
            <Modal
                title="Manage Publisher Purposes"
                visible={this.props.visible}
                className='vl-publisher-purpose'
                closable={true}
                onCancel={this.props.closeModal}
                footer={this.renderFooter()}
                destroyOnClose
            >
                <div className="content-container">
                    <div onClick={() => this.props.closeModal()} className="content-title">
                        <ArrowLeftOutlined className="arrow-style" />
                        <span className="title">Advanced Settings</span>
                    </div>
                    <div className="content-subTitle">
                        To act on the basis of our publisher confirmation that they have a valid legal basis for all the
                        processing of the user data for the following purposes.
                    </div>
                </div>
                <div className="content-subTitle-two">Configure the Publisher's purposes and legal basis for the following purposes</div>
                <div className="config-popup-header">
                    <div className="header-title-one">Purposes</div>
                    <div className="header-title-two">Legal Basis</div>
                </div>
                <div className="purpose-list-parentContainer">
                    {this.props.iabPurposes.map((item, index) => {
                        let id = item.get('iabId')
                        return (<div key={id} className="purpose-list-container">
                            <div className="purpose-name">{item.get('name')}</div>
                            <Select
                                className="purpose-select"
                                onChange={(value) => this.setConfigureValue(id, value)}
                                value={this.state[id] ? this.state[id] : ''}
                                disabled={this.props.readOnly}
                            >
                                <Option value="CONSENT">
                                    <span className="li-tag li-consent">User Consent</span>
                                </Option>
                                <Option value="LEGITIMATE_INTEREST">
                                    <span className="li-tag li-legitimate">Legitimate Interest</span>
                                </Option>
                                <Option value="NOT_APPLICABLE">
                                    <span className="li-tag li-applicable">Not Applicable</span>
                                </Option>
                            </Select>
                        </div>)
                    })}</div>
            </Modal>
        )
    }
}