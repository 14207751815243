import PropTypes from 'prop-types';
import React from 'react';
import ValueWithDescription from './ValueWithDescription';

import { Row, Col } from 'antd';

export default class extends React.Component {
    static propTypes = {
        values: PropTypes.array.isRequired,
    };

    render() {
        return (
            <div>
              <Row className="summary-header-row">
                { this.props.values.map((value, i) => {
                    return (
                        <Col span={ 8 } key={ i }>
                          <ValueWithDescription value={ value[0] } description={ value[1] }/>
                        </Col>
                    )
                })}
              </Row>
            </div>
        )
    }
}
