import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map, List } from 'immutable';
import { 
  getRulePepformanceData as getRulePepformanceDataAction,
} from '../../../actions/golden_set_actions';
import { Tabs, Select, Input, Table, Collapse } from 'antd';
import GoldenScoreTabs from './GoldenScoreTabs';
import { useActions } from '../../../hooks';
import PerformanceHeader from './PerformanceHeader';
import AcronysTooltip from './AcronymsTooltip';

const Search = Input.Search;
const { Panel } = Collapse;

export default function RulePerformance(props) {

  const getRulePepformanceData = useActions(getRulePepformanceDataAction);

  const goldenSet = useSelector(state => state.goldenSetState.getIn(['goldenSets', 'value'])).find(gs => gs.id === props.location.query.id);
  const rulePerformanceData = useSelector(state => state.goldenSetState.getIn(['rulePerformanceData', 'value'])).find(data => data.goldenSetId === props.location.query.id);

  useEffect(() => {
    if (goldenSet) {
      getRulePepformanceData(goldenSet.id);
    }
  }, [goldenSet]);

  const [search, setSearch] = useState('');

  const generateTable = () => {

    const columns = [
      {
        title: 'Rule',
        dataIndex: 'rule',
        key: 'rule',
      },
      {
        title: 'No of Properties',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Accuracy',
        dataIndex: 'accuracy',
        key: 'accuracy',
      },
      {
        title: 'Precision',
        dataIndex: 'precision',
        key: 'precision',
      },
      {
        title: 'Recall',
        dataIndex: 'recall',
        key: 'recall',
      },
      {
        title: 'F1',
        dataIndex: 'f1',
        key: 'f1',
      },
      {
        title: 'TP',
        dataIndex: 'tp',
        key: 'tp',
      },
      {
        title: 'TN',
        dataIndex: 'tn',
        key: 'tn',
      },
      {
        title: 'TU',
        dataIndex: 'tu',
        key: 'tu',
      },
      {
        title: 'FP',
        dataIndex: 'fp',
        key: 'fp',
      },
      {
        title: 'FN',
        dataIndex: 'fn',
        key: 'fn',
      },
      {
        title: 'FU',
        dataIndex: 'fu',
        key: 'fu',
      },
    ];

    let rules = goldenSet.rules;
    if (search) {
      rules = rules.filter(r => r.name.toLowerCase().includes(search.toLowerCase()));
    }
    let dataSource;
    if (rulePerformanceData) {
      dataSource = rules.toJS().map((r) => {
        const data = rulePerformanceData.rules.find(obj => obj.ruleId === r.ruleId);
        return {
          key: r.id,
          rule: r.name,
          number: data.numDomainsScored || '0',
          accuracy: (data.accuracy || data.accuracy === 0) && (data.accuracy * 100).toFixed(2) + '%' || 'N/A',
          precision: (data.precision || data.precision === 0) && (data.precision * 100).toFixed(2) + '%' || 'N/A',
          recall: (data.recall || data.recall === 0) && (data.recall * 100).toFixed(2) + '%' || 'N/A',
          f1: (data.F1 || data.F1 === 0) && (data.F1 * 100).toFixed(2) + '%'  || 'N/A',
          tp: data.TP,
          tn: data.TN,
          tu: data.TU,
          fp: data.FP,
          fn: data.FN,
          fu: data.FU,
        }
      });
    } else {
      dataSource = {};
    }
  
    return { dataSource, columns };
  };

  const renderTopRules = (rulesArray, property) => {
    return (
      <div>
        { rulesArray.map(rule => {
          return (
            <div className='flex-row'>
              <div>{ (rule[property] * 100).toFixed(2) + '%'}</div>
              <div className='rule-name'>{ rule.ruleName }</div>
            </div>
          )
        })}
      </div>
    )
  }

  let table;
  let acronysTooltip;
  if (goldenSet && rulePerformanceData) {
    const tableData = generateTable();
    table = (
      <div className='table-box-shadow'>
        <div className='search-container'>
          <Search
            placeholder="Search Rules"
            onChange={ (e) => { setSearch(e.target.value)} }
            className="vendor-filter"
            style={{ width: 190 }}
          />
        </div>

        <Table
          bordered={ false }
          dataSource={ tableData.dataSource }
          columns={ tableData.columns }
          className='rule-performance-table'
        />
      </div>
    );

    acronysTooltip = <AcronysTooltip />;
  }

  let top;
  if (rulePerformanceData) {
    top = (
      <div className='top-container-gs-performance'>
        <div className='avg'>
          <p className='title'>Overall performance of the current scoring against the Golden Set</p>
          <div className='flex-row avg-list'>
            <div>
              <p>Accuracy</p>
              <div>{(rulePerformanceData.avgPerformance.avgAccuracy * 100).toFixed(2) + '%'}</div>
            </div>
            <div>
              <p>Precision</p>
              <div>{(rulePerformanceData.avgPerformance.avgPrecision * 100).toFixed(2) + '%'}</div>
            </div>
            <div>
              <p>Recall</p>
              <div>{(rulePerformanceData.avgPerformance.avgRecall * 100).toFixed(2) + '%'}</div>
            </div>
            <div>
              <p>F1</p>
              <div>{(rulePerformanceData.avgPerformance.avgF1 * 100).toFixed(2) + '%'}</div>
            </div>
          </div>
        </div>
        <div className='underperforming'>
          <Collapse defaultActiveKey={['1']} >
            <Panel header="Top 3 Underperforming Rules" key="1">
              <div className='flex-row underperforming-list'>
                <div>
                  <p>Accuracy</p>
                  { renderTopRules(rulePerformanceData.underperformingRules.minAccuracyArray, 'accuracy') }
                </div>
                <div>
                  <p>Precision</p>
                  { renderTopRules(rulePerformanceData.underperformingRules.minPrecisionArray, 'precision') }
                </div>
                <div>
                  <p>Recall</p>
                  { renderTopRules(rulePerformanceData.underperformingRules.minRecallArray, 'recall') }
                </div>
                <div>
                  <p>F1</p>
                  { renderTopRules(rulePerformanceData.underperformingRules.minF1Array, 'F1') }
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    )
  }

  return (
    <div className='domain-sets-container privacy-lens golden-set'>
      <GoldenScoreTabs />
      <PerformanceHeader rulePerformance goldenSetId={ goldenSet ? goldenSet.id : props.location.query.id } />
      { acronysTooltip }
      { top }
      { table }
    </div>
  );
}