import React, { Component } from 'react';
import { Slider } from '../../../../../../../styleguide';
import ColorPickerInput from '../../../../../../common/ColorPickerInput.js.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderStyle } from '@fortawesome/free-solid-svg-icons';

class Border extends Component {
  updateBorder = (type, value) => {
    const border = JSON.parse(JSON.stringify(this.props.value));
    border[type] = value;
    this.props.updateSetting(this.props.settingKey, border);
  }
  render() {

    const { value, settingsTitle } = this.props;

    return (
      <div>
        <div className="four-options">
          <h4>{settingsTitle}</h4>
          <div className="border-flex">
            <div className="border-label">Size</div>
            <Slider min={0} max={10} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateBorder('borderWidth', val)}
              value={typeof value.borderWidth === 'number' ? value.borderWidth : 0}
            />
          </div>
          <div className="border-flex">
            <div className="border-label">Color</div>
            <div className="border-color">
              <ColorPickerInput
                value={ value.borderColor }
                onUpdate={(value) => this.updateBorder('borderColor', value)}
                editing={ true }
              />
            </div>
          </div>
          <h3>Rounded Corners</h3>
          <div className="padding-flex radius">
            <div className="padding-label"><FontAwesomeIcon icon={faBorderStyle} /></div>
            <Slider min={0} max={20} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateBorder('borderTopLeftRadius', val)}
              value={typeof value.borderTopLeftRadius === 'number' ? value.borderTopLeftRadius : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label"><FontAwesomeIcon icon={faBorderStyle} rotation={90} /></div>
            <Slider min={0} max={20} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateBorder('borderTopRightRadius', val)}
              value={typeof value.borderTopRightRadius === 'number' ? value.borderTopRightRadius : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label"><FontAwesomeIcon icon={faBorderStyle} rotation={270} /></div>
            <Slider min={0} max={20} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateBorder('borderBottomLeftRadius', val)}
              value={typeof value.borderBottomLeftRadius === 'number' ? value.borderBottomLeftRadius : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label"><FontAwesomeIcon icon={faBorderStyle} rotation={180} /></div>
            <Slider min={0} max={20} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateBorder('borderBottomRightRadius', val)}
              value={typeof value.borderBottomRightRadius === 'number' ? value.borderBottomRightRadius : 0}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Border;
