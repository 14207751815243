import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { InfoCircleFilled, FilterFilled } from '@ant-design/icons';
import SlidingFilter from './SlidingFilter';

const CheckboxGroup = Checkbox.Group;
const plainOptions = [
  { label: 'Disclosed vendors', value: 'disclosed' },
  { label: 'Disclosed triggering non-disclosed', value: 'disclosed-triggering-non-disclosed' },
  { label: 'Non-disclosed', value: 'non-disclosed' },
];
export const allOptionsList = ['disclosed', 'disclosed-triggering-non-disclosed', 'non-disclosed'];

const VendorTraceNonDisclosedFilter = ({ onChangeDisclosed, checkedList }) => {
  const indeterminate = useMemo(() => {
    return !checkedList.length || !(checkedList.length === allOptionsList.length);
  }, [checkedList]);
  const checkAll = useMemo(() => {
    return checkedList.length === allOptionsList.length;
  }, [checkedList]);

  const onCheckAllChange = (e) => {
    onChangeDisclosed(e.target.checked ? allOptionsList : []);
  };

  return (
    <SlidingFilter className={'vendor-trace-non-disclosed-filter'} type="left">
      <div className={'vendor-trace-non-disclosed-filter-container'}>
        <div className={'title-row'}>
          <FilterFilled />
          <div>Vendor disclosure</div>
          <Tooltip
            overlayClassName="diagnose--tooltip vendor-trace-prevalence-filter-tooltip-overlay"
            title={() => {
              return (
                <div className={'tooltip-content'}>
                  <div>
                    <p>Vendors highlighted in green are disclosed on the vendor list.</p>
                    <p>
                      Those highlighted in amber are disclosed on the vendor list but triggering vendors that aren't.
                    </p>
                    <p>Vendors highlighted in red are not disclosed in your vendor list.</p>
                  </div>
                </div>
              );
            }}
          >
            <InfoCircleFilled />
          </Tooltip>
        </div>
        <div className={'filter-block'}>
          <div className={'color-tags-container'}>
            <div className={'color-tag'} style={{ opacity: 0 }} />
            <div className={'color-tag'} style={{ backgroundColor: '#03A233' }} />
            <div className={'color-tag'} style={{ backgroundColor: '#FFC62B' }} />
            <div className={'color-tag'} style={{ backgroundColor: '#CC483B' }} />
          </div>
          <div className={'checkbox-list'}>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              All
            </Checkbox>
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChangeDisclosed} />
          </div>
        </div>
      </div>
    </SlidingFilter>
  );
};

export default VendorTraceNonDisclosedFilter;
