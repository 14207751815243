import { CategorizationSchema } from "../../../records/vendor_list_records";
import { GCM_ACTION_TYPE } from '../../../constants';
import { Action, TagManager } from '../../../records/vendor_list_records';

export const categorize = (category, vendor, defaultLegalBasis, customConfig) => {
  let cat;
  if (category.type === 'IAB_PURPOSE' || category.type === 'CUSTOM') {
    if (vendor.vendorType === 'IAB') {
      if (//flexible
        vendor.purposes.find(pId => pId === category.iabPurposeRef)
        && vendor.legIntPurposes.find(pId => pId === category.iabPurposeRef)
      ) {
        
        cat = new CategorizationSchema({
          vendorId: vendor.id,
          cookies: vendor.cookies && vendor.cookies.size ? vendor.cookies.filter(c => c.iabPurposeRefs.includes(category.iabPurposeRef)) : [],
          type: category.defaultLegalBasis || defaultLegalBasis || 'CONSENT',
        });
      } else {//non flexible
        cat = new CategorizationSchema({
          vendorId: vendor.id,
          cookies: vendor.cookies && vendor.cookies.size ? vendor.cookies.filter(c => c.iabPurposeRefs.includes(category.iabPurposeRef)) : [],
          type: vendor.purposes.find(pId => pId === category.iabPurposeRef)
            ? 'CONSENT'
            : vendor.legIntPurposes.find(pId => pId === category.iabPurposeRef)
              ? 'LEGITIMATE_INTEREST' : 'NOT_APPLICABLE',
        });
      };
    } else {//custom
      cat = new CategorizationSchema({
        vendorId: vendor.id,
        cookies: vendor.cookies && vendor.cookies.size ? vendor.cookies : [],
        type: customConfig ? customConfig : category.defaultCustomLegalBasis || 'NOT_APPLICABLE',
      })
    };
  } else {
    cat = new CategorizationSchema({
      vendorId: vendor.id,
      cookies: [],
      type: (vendor.vendorType === 'IAB')
        ? 'NOT_APPLICABLE'
        : category.disclosureOnly
          ? 'NOT_APPLICABLE'
          : category.defaultCustomLegalBasis || 'NOT_APPLICABLE',
    });
  };
  return cat;
}

export const fixUncategorizedVendors = (vl, defaultLegalBasis) => {
  const vlJS = vl.toJS();

  const missingVendorCats = vlJS.categories.reduce(function getMissingVendorCats(missingVendorCats, category) {
    if (category.type === 'CUSTOM') {
      return missingVendorCats;
    };

    if (!category.categories.length) {
      const missingVendors = vlJS.vendors.reduce((missingVendors, vendor) => {
        if (vendor.vendorType === 'CUSTOM') {
          return missingVendors;
        };

        
        if (!category.vendorCategorization.find((vc) => vc.vendorId === vendor.id)) {
          const legalBasis = category.defaultLegalBasis || defaultLegalBasis;
          missingVendors.push(categorize(category, vendor, legalBasis));
        };

        return missingVendors.filter(({ type }) => type !== 'NOT_APPLICABLE');
      }, []);

      missingVendorCats.push({ categoryId: category.id, missingVendorCats: missingVendors });
    } else {
      const missingStacksVendorCats = category.categories.reduce(getMissingVendorCats, []);
      missingVendorCats.push(...missingStacksVendorCats.map(v => ({ ...v, parentCategoryId: category.id })));
    };

    return missingVendorCats;
  }, []);

  return vl.update('categories', (categories) => {
    let correctedCategories = categories;
    missingVendorCats.forEach((categoryMissing) => {
      let correctedCategory
      if (categoryMissing.parentCategoryId) {
        correctedCategory = correctedCategories
          .find(c => c.id === categoryMissing.parentCategoryId)
          .update('categories', (categories) => {
            return categories.map(c => {
              return (c.id === categoryMissing.categoryId)
                ? c.update('vendorCategorization', (vc) => vc.concat(categoryMissing.missingVendorCats))
                : c
            })
          });
      } else {
        correctedCategory = correctedCategories
          .find(c => c.id === categoryMissing.categoryId)
          .update('vendorCategorization', (vc) => vc.concat(categoryMissing.missingVendorCats));
      };

      const index = correctedCategories.findIndex((c) => c.id === correctedCategory.id)
      correctedCategories = correctedCategories.set(index, correctedCategory);
    });

    return correctedCategories;
  });
}

export const addGcmActions = (purpose, gcmCategory) => {
  let newConsentActions = purpose.consentActions.filterNot(a => a.type === GCM_ACTION_TYPE)
  let newRejectActions = purpose.rejectActions.filterNot(a => a.type === GCM_ACTION_TYPE)

  if (gcmCategory && gcmCategory !== 'None') {
    const newConsentAction = new Action({ 
      type: GCM_ACTION_TYPE, 
      tagManager: new TagManager({ name: 'consent', key: gcmCategory, value: 'granted' }) 
    })
    newConsentActions = newConsentActions.push(newConsentAction)

    const newRejectAction = new Action({ 
      type: GCM_ACTION_TYPE,  
      tagManager: new TagManager({ name: 'consent', key: gcmCategory, value: 'denied' }) 
    })
    newRejectActions = newRejectActions.push(newRejectAction)
  }

  purpose = purpose.set('consentActions', newConsentActions)
  purpose = purpose.set('rejectActions', newRejectActions)

  return purpose;
}