import React from 'react';
import { List, Map, fromJS } from 'immutable';
import { Select, Radio, Button } from '../../../../styleguide';
import { styles } from './styles';
import { GeoGate as GeoGateRecord, USStateGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { SCENARIO_STEPS } from '../../scenarios/versionTwo/components/steps';
import { GEO_FILTER_LIST_DETAILS, LEGACY_EU_COUNTRY_CODES } from '../../../../constants.js';
import { stateList } from '../helper';

const { Option } = Select;

class GeoTargeting extends React.Component {
  state = {
    conditiontwo: '',
    conditionone: 'In',
    selectedOption: List(),
    isStateGeo: !!this.props.state,
  };

  onSelectFilter = (inputString, option) => {
    return (
      inputString.toLowerCase() ===
      option.props.children.toLowerCase().slice(0, inputString.length)
    );
  };

  componentWillMount() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .find((r, ind) => r.get('className') == className && ind == this.props.conditionIndex);
    let data;
    if (getSavedData && this.props.isUpdate) {
      data = getSavedData;
    }
    const isStateGeo = !!this.props.state;
    let selectedOptions = getSavedData && data ? isStateGeo ? data.get('data').get('state_list') : data.get('data').get('geo_list') : List();
    
    if(selectedOptions.includes('allEU')){
      selectedOptions = [...new Set(selectedOptions.toJS().concat(LEGACY_EU_COUNTRY_CODES))];
      selectedOptions = List(selectedOptions.filter(code => code !== 'allEU'))
    }

    this.setState({
      isStateGeo,
      selectedOption: selectedOptions,
      conditionone:
        getSavedData && data
          ? data.get('data').get('continue')
            ? 'In'
            : 'Not In'
          : 'In',
    });
  }

  onSelectOption = (option) => {
    this.setState({ selectedOption: List(option) });
  };

  cancelCondition = () => {
    this.props.closePanel();
  };
  onChangeValue = (e) => {
    this.setState({ conditionone: e.target.value });
  };

  onAddEvent = () => {
    const Record = this.state.isStateGeo ? USStateGate : GeoGateRecord;
    const geo_field =  this.state.isStateGeo ? 'state_list' : 'geo_list';
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set(
      'data',
      new Record({
        continue: this.state.conditionone == 'In' ? true : false,
        [geo_field]: this.state.selectedOption,
      })
    );
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.unshift(stepBlockData);
    this.props.onChange(this.props.activeStep, rowData);
  };

  onUpdateEvent = () => {
    const Record = this.state.isStateGeo ? USStateGate : GeoGateRecord;
    const geo_field =  this.state.isStateGeo ? 'state_list' : 'geo_list';
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set(
      'data',
      new Record({
        continue: this.state.conditionone == 'In' ? true : false,
        [geo_field]: this.state.selectedOption,
      })
    );
    const index = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.findIndex((r, ind) => r.get('className') == className && ind == this.props.conditionIndex);
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.set(index, stepBlockData);
    this.props.onChange(this.props.activeStep, rowData);
  };

  renderOptions = () => {
    let options;
    if(this.state.isStateGeo) {
      options = fromJS(stateList);
    } else {
      const countryMaps = [
        Map({ key: 'allEEA', value: 'All EEA Countries + UK', group: 'EEA' }),
        Map({ key: 'CA', value: 'All Canada', group: 'CA' })
      ];

      GEO_FILTER_LIST_DETAILS.forEach((el) => { 
        if( el && el.type === "country" && el.name !== "Canada" || el.type === "province") {
          let group = List.isList(el.region) ? el.region.last() : el.region;
          countryMaps.push(Map({ key: el.type === "province" ? `CA_${el.code}` : el.code, value: el.name, group: group }));
        }
      });

      options = List(countryMaps);
    }
    options = options.map((el) => (
      <Option key={el.get('key')} value={el.get('key')}>
        {el.get('value')}
      </Option>
    ));
    return options;
  }

  render() {
    const options = this.renderOptions();
    const title = this.state.isStateGeo ? 'US State' : 'Country';

    return (
      <div style={styles.scenarioConditionPanelTwo}>
        <div style={styles.scenarioInnerWrapper}>
          <span className="condition-label">Condition: </span>
          <span className="condition-title">Geo Targeting ({title})</span>
          <Radio.Group
            style={styles.scenarioMarginLeft}
            defaultValue="In"
            buttonStyle="solid"
            onChange={this.onChangeValue}
            value={this.state.conditionone}
          >
            <Radio.Button value="In">In</Radio.Button>
            <Radio.Button value="Not In">Not In</Radio.Button>
          </Radio.Group>
          <div id="condition-dropdown">
            <label>Geo Targeting</label>
            <Select
              mode="tags"
              placeholder="Please select"
              onChange={this.onSelectOption}
              filterOption={this.onSelectFilter}
              maxTagCount={1}
              value={this.state.selectedOption.toJS()}
              showArrow={true}
              style={styles.conditionDropdown}
            >
              {options.toJS()}
            </Select>
          </div>
        </div>
        <div style={styles.scenarioButtonWrapper}>
          <Button
            onClick={
              this.props.buttontitle == 'Add'
                ? this.onAddEvent
                : this.onUpdateEvent
            }
            type="primary"
            size='small'
            disabled={!this.state.selectedOption.size}
          >
            {this.props.buttontitle}
          </Button>
          <Button
            onClick={this.cancelCondition}
            style={styles.scenarioMarginLeft}
            type="secondary"
            font="oswald"
            size='small'
            ghost
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default GeoTargeting;
