import 'whatwg-fetch';
import { checkStatus } from './helper.js';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

export function getZendeskJwtAjax() {
    // get all users with permissions
    const url = `${permissionsSvcBaseUrl}/zendesk/token`;
    return fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
          return resp.token;
        });
  }