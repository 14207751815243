import React from 'react';
import { browserHistory } from 'react-router';
import { Modal, Button } from 'antd';
import { fromJS } from 'immutable';

function PrivacyChecksConfirmation(props) {
  const goToMessages = (url, message) => {
	browserHistory.push({
	  pathname: `/dialogue/${url}`,
	  query: props.siteGroup ? {site_group_id: props.siteGroup.id, site_id: props.siteId} : {site_id: props.siteId},
	  state: { message: message }
	});
  }

   return <Modal
          title="Privacy Check Confirmation"
          visible={props.showPrivacyCheckModal}
          onCancel={()=> props.handlePrivacyModalVisibility(false)}
          footer={null}
          className="multi-campaign-privacy"
        >
          <p className="campaign-note">Please perform 'Privacy Check' before launching the campaign that are related to
            IAB TCF Policies.</p>
          <p className="campaign-title">All Campaigns with TCFv2 messages must have privacy check review completed.</p>
          <div style={{ marginTop: '10px' }}>
            <div className="subheading-container">
              <div className="campaign-subheading">Message Type</div>
              <div  className="campaign-subheading">Privacy Check Required</div>
              <div className="blank-div"></div>
            </div>
            
              {props.firstLayerMessages.map((msg, mi) => {
                let compliance_json = JSON.parse(fromJS(msg.compliance_json));
                let count = (compliance_json.length > 0) ? compliance_json.filter((item,index)=> !item[index+1]).length : 0;
                return (<div className="content-container" key={mi}>
                <div className="content-title">{msg.description}</div>
                <div className="content-description">{count}</div>
                <div className="blank-div">
                  <Button onClick={() => goToMessages('messages/tcfv2',msg)} type="primary">View Status</Button></div>
                </div>)
              })}

             { props.pmMessages.map((msg, mi) => {
                 let compliance_json = JSON.parse(fromJS(msg.compliance_json));
                 let count = (compliance_json.length > 0) ? compliance_json.filter((item,index) => !item[index+1]).length : 0;
                 return (<div className="content-container" key={mi}>
                   <div className="content-title">Privacy Manager</div>
                   <div className="content-description">{count}</div>
                   <div className="blank-div"><Button onClick={() => goToMessages('privacy-manager/tcfv2',msg)} type="primary">View Status</Button></div>
                  </div>)})
              }

            { props.pmOttMessages.map((msg) => {
              let compliance_json = JSON.parse(fromJS(msg.compliance_json));
              let count = (compliance_json.length > 0) ? compliance_json.filter((item, index) => !item[index + 1]).length : 0
              return (
                <div className="content-container">
                  <div className="content-title">Privacy Manager OTT</div>
                  <div className="content-description">{count}</div>
                  <div className="blank-div">
                    <Button onClick={() => goToMessages('privacy-manager/ott', msg)} type="primary">
                      View Status
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        </Modal>
}

export default PrivacyChecksConfirmation;