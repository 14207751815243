import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import { Chip, Button, Popconfirm } from "../../../styleguide";
import { CloseOutlined, EyeFilled } from "@ant-design/icons";
import { GEO_LIST_TREE_STRUCTURE, region_title_index_map } from "../../../constants";
import _ from "lodash";

const region_key_index_map = GEO_LIST_TREE_STRUCTURE.reduce((map, item) => {
  item.children.forEach((child) => {
    if (!map[child.code]) {
      map[child.code] = [child.key];
    } else if (Array.isArray(map[child.code])) {
      map[child.code].push(child.key);
    } else {
      map[child.code] = [map[child.code], child.key];
    }
  });
  return map;
}, {});

const SelectedRegionsView = (props) => {
  const [selectedTerritories, setSelectedTerritories] = useState([
    {
      key: "US",
      included: [],
      excluded: [],
    },
    {
      key: "EEA",
      title: "EEA + UK",
      included: [],
      excluded: [],
    },
    {
      key: "CA",
      included: [],
      excluded: [],
    },
    {
      key: "OTHER",
      included: [],
      excluded: [],
    },
  ]);
  const [regionViewStateMap, setRegionViewStateMap] = useState({
    US: { showIncludes: true, count: 5 },
    EEA: { showIncludes: true, count: 5 },
    CA: { showIncludes: true, count: 5 },
    OTHER: { showIncludes: true, count: 5 },
  });

  useEffect(() => {
    let selectedTerritories = [
      {
        key: "US",
        included: [],
        excluded: [],
      },
      {
        key: "EEA",
        title: "EEA + UK",
        included: [],
        excluded: [],
      },
      {
        key: "CA",
        included: [],
        excluded: [],
      },
      {
        key: "OTHER",
        included: [],
        excluded: [],
      },
    ];
    props.selectedCodes.forEach((code) => {
      const keys = region_key_index_map[code];
      const regions = keys?.map((key) => key.split(";")[0]);
      regions?.forEach((region) => {
        let includedCodes = selectedTerritories.find(
          (t) => t.key === region
        ).included;
        includedCodes.push(code);
        selectedTerritories.find((t) => t.key === region).excluded =
          GEO_LIST_TREE_STRUCTURE.find((o) => o.code === region)
            .children.filter((c) => !includedCodes.includes(c.code))
            .map((c) => c.code);
      });
    });
    setSelectedTerritories(selectedTerritories);
    if(props.setSelectedTerritoriesData) props.setSelectedTerritoriesData(selectedTerritories);
  }, [props.selectedCodes]);

  const flipTerritoryView = (territoryKey) => {
    const dupli = _.cloneDeep(regionViewStateMap);
    dupli[territoryKey].showIncludes = !dupli[territoryKey].showIncludes;
    dupli[territoryKey].count = 5;
    setRegionViewStateMap(dupli);
  };

  const handleViewMore = (territoryKey, length) => {
    const dupli = _.cloneDeep(regionViewStateMap);
    dupli[territoryKey].count = 1000; // any number that is greater than the possible number of regions in a region
    setRegionViewStateMap(dupli);
  };

  const handleViewLess = (territoryKey) => {
    const dupli = _.cloneDeep(regionViewStateMap);
    dupli[territoryKey].count = 5;
    setRegionViewStateMap(dupli);
  };

  const removeTerritory = (removeCode) => {
    props.setSelectedCodes(props.selectedCodes.filter((code) => removeCode !== code));
  };

  const addTerritory = (addCode, excludedCodes, territoryKey) => {
    props.setSelectedCodes([...new Set([...props.selectedCodes, addCode])]);
    if (!excludedCodes.filter((code) => code !== addCode).length) {
      flipTerritoryView(territoryKey);
    }
  };

  const removeAllFromTerritory = (removeCodes) => {
    props.setSelectedCodes(
      props.selectedCodes.filter((code) => !removeCodes.includes(code))
    );
  };

  const renderSelectedTerritories = (territory) => {
    const territoryKey = territory.key;
    const regionsStateMapLocal = regionViewStateMap;
    const includedChips = territory.included.map((code) => (
      <Chip
        closable
        onClose={(e) => removeTerritory(code)}
        className="green-chips"
      >
        {props.addIconsToTheseCodes && props.addIconsToTheseCodes?.includes(code) ? <i className="fas fa-circle" style={{ color: "#411F90", width: 10, height: 10, marginRight: 10 }}/> : null}{region_title_index_map[code]}
      </Chip>
    ));
    const excludedChips = territory.excluded.map((code) => (
      <Chip
        closable
        onClose={(e) => addTerritory(code, territory.excluded, territoryKey)}
        className="red-chips"
      >
        {props.addIconsToTheseCodes && props.addIconsToTheseCodes?.includes(code) ? <i className="fas fa-circle" style={{ color: "#411F90", width: 10, height: 10, marginRight: 10 }}/> : null}{region_title_index_map[code]}
      </Chip>
    ));

    const isAllIncluded =
      includedChips.length ===
      GEO_LIST_TREE_STRUCTURE.find((o) => o.code === territoryKey).children
        .length;
    if (isAllIncluded) {
      regionsStateMapLocal[territoryKey].showIncludes = true;
    }

    let chips = regionsStateMapLocal[territoryKey].showIncludes
      ? includedChips
      : excludedChips;
    let viewMoreButton = null;
    const l = chips.length;
    if (l > 5) {
      viewMoreButton =
        regionsStateMapLocal[territoryKey].count > 5 ? (
          <Button
            onClick={() => handleViewLess(territoryKey)}
            className={`${
              regionsStateMapLocal[territoryKey].showIncludes
                ? "green-btn"
                : "red-btn"
            }`}
          >
            -
          </Button>
        ) : (
          <Button
            onClick={() => handleViewMore(territoryKey, l)}
            className={`${
              regionsStateMapLocal[territoryKey].showIncludes
                ? "green-btn"
                : "red-btn"
            }`}
          >
            +{l - 5}
          </Button>
        );
    }
    chips = chips.slice(0, regionsStateMapLocal[territoryKey].count);

    return includedChips.length ? (
      <div className="region-group">
        <div className="group-name">
          {territory.title ?? territoryKey}{" "}
          <CloseOutlined
            onClick={(e) => removeAllFromTerritory(territory.included)}
          />
        </div>
        <div className="include-exclude">
          <div>
            {regionsStateMapLocal[territoryKey].showIncludes
              ? `Includes(${territory.included.length})`
              : `Excludes(${territory.excluded.length})`}
          </div>
          {isAllIncluded ? (
            <div>All included</div>
          ) : (
            <div
              className="pointer"
              onClick={() => flipTerritoryView(territoryKey)}
            >
              <EyeFilled />{" "}
              {regionsStateMapLocal[territoryKey].showIncludes
                ? `Show Excluded(${territory.excluded.length})`
                : `Show Included(${territory.included.length})`}
            </div>
          )}
        </div>
        <div className="group-regions">
          {chips}
          {viewMoreButton}
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="selected-regions">
      <div className="heading">
        <b>Selected</b>
        {props.selectedCodes.length ? (
          <Popconfirm
            overlayClassName="exit-wizard-confirmation"
            title={
              <div>
                Are you sure you want to remove all selected {props.entity}?
              </div>
            }
            placement="topRight"
            trigger="click"
            icon={null}
            onConfirm={() => props.setSelectedCodes([])}
            okText="Yes"
            cancelText="No"
          >
            <b className="clear-all"> Clear All X</b>
          </Popconfirm>
        ) : null}
      </div>
      <Divider />
      {props.selectedRegionsLegend && props.selectedCodes.length ? <div style={{textAlign:'right'}}> {props.selectedRegionsLegend}</div> : null}
      <div
        className={`selected-regions-container ${
          props.selectedCodes.length ? "" : "centered"
        }`}
      >
        {props.selectedCodes.length ? (
          selectedTerritories.map((territory) =>
            renderSelectedTerritories(territory)
          )
        ) : (
          <div>Selected {props.entity} will appear here.</div>
        )}
      </div>
    </div>
  );
};
export default React.memo(SelectedRegionsView, (prevProps, nextProps) =>
  _.isEqual(prevProps.selectedCodes, nextProps.selectedCodes)
);
