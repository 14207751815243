import { Map } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const initialState = Map({
  actionStatusData: new StoreRecord(),
  vendorsData: new StoreRecord(),
});

export default (state = initialState, action) => {
  switch(action.type) {
    case types.CONSENT_REPORT_ACTION_STATUS_PENDING:
      return setPending(state, ['actionStatusData']);

    case types.CONSENT_REPORT_ACTION_STATUS_LOADED:
      return setValue(state, ['actionStatusData'], action.data);

    case types.CONSENT_REPORT_ACTION_STATUS_ERROR:
      return setError(state, ['actionStatusData'], action.errorMessage);

    case types.CONSENT_REPORT_VENDORS_PENDING:
      return setPending(state, ['vendorsData']);

    case types.CONSENT_REPORT_VENDORS_LOADED:
      return setValue(state, ['vendorsData'], action.data);

    case types.CONSENT_REPORT_VENDORS_ERROR:
      return setError(state, ['vendorsData'], action.errorMessage);
  }
  return state;
};
