import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getDomainSetsAjax,
  createDomainSetAjax,
  updateDomainSetAjax,
  deleteDomainSetAjax,
  getAverageImpressionsAjax,
  getAccuracySetsAjax,
} from '../api/consent_quality/domain_set';

export const getDomainSets = actionCreator(
  getDomainSetsAjax,
  domainSetsPending,
  domainSetsLoaded,
  domainSetsError,
  domainSetsCached,
);

export const createDomainSet = actionCreator(
  createDomainSetAjax,
  domainSetsCreaateOrUpdatePending,
  domainSetCreated,
  domainSetsError,
);

export const updateDomainSet = actionCreator(
  updateDomainSetAjax,
  domainSetsCreaateOrUpdatePending,
  domainSetUpdated,
  domainSetsError,
);

export const deleteDomainSet = actionCreator(
  deleteDomainSetAjax,
  domainSetsPending,
  domainSetDeleted,
  domainSetsError,
);

export const getAverageImpressions = actionCreator(
  getAverageImpressionsAjax,
  getAverageImpressionsPending,
  averageImpressionsLoaded,
  averageImpressionsError,
  averageImpressionsCached,
);

export const getAccuracySets = actionCreator(
  getAccuracySetsAjax,
  accuracySetsPending,
  accuracySetsLoaded,
  accuracySetsError,
);

function domainSetsPending() {
  return {
    type: types.DOMAIN_SETS_PENDING,
  };
}

function domainSetsCreaateOrUpdatePending() {
  return {
    type: types.DOMAIN_SET_CREATE_OR_UPDATE_PENDING,
  };
}

function domainSetsLoaded(domainSets) {
  return {
    type: types.DOMAIN_SETS_LOADED,
    domainSets,
  };
}

function domainSetsError(error) {
  return {
    type: types.DOMAIN_SETS_ERROR,
    error,
  };
}

function domainSetCreated(domainSet) {
  return {
    type: types.DOMAIN_SET_CREATED,
    domainSet,
  };
}

function domainSetUpdated(domainSet) {
  return {
    type: types.DOMAIN_SET_UPDATED,
    domainSet,
  };
}

function domainSetDeleted(id) {
  return {
    type: types.DOMAIN_SET_DELETED,
    id,
  };
}

function domainSetsCached() {
  return {
    type: types.DOMAIN_SETS_CACHED,
  };
}

function getAverageImpressionsPending() {
  return {
    type: types.GET_AVERAGE_IMPRESSIONS_PENDING,
  };
}

function averageImpressionsLoaded(impressions) {
  return {
    type: types.AVERAGE_IMPRESSIONS_LOADED,
    impressions,
  };
}

function averageImpressionsCached() {
  return {
    type: types.AVERAGE_IMPRESSIONS_CACHED,
  };
}

function averageImpressionsError(error) {
  return {
    type: types.AVERAGE_IMPRESSIONS_ERROR,
    error,
  };
}

function accuracySetsPending() {
  return {
    type: types.ACCURACY_SETS_PENDING,
  };
}

function accuracySetsLoaded(domainSets) {
  return {
    type: types.ACCURACY_SETS_LOADED,
    domainSets,
  };
}

function accuracySetsError(error) {
  return {
    type: types.ACCURACY_SETS_ERROR,
    error,
  };
}
