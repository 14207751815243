import { CheckOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { Select } from '../../../../styleguide';

const { Option, OptGroup } = Select;

export const singleOptionRenderer = (option) => {
  return (
    <Option
      key={`option-${option.value}`}
      value={option.value}
      title={option.title}
      disabled={option?.disabled || false}
    >
      <span className="spsg-select-dropdown-label" title={option.title}>
        {option.title}
      </span>
      <CheckOutlined />
    </Option>
  );
};

export const nestedOptionsRenderer = (option, selected, singleOptionRender) => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setToggle((prev) => !prev);
  };
  const isSelectedChild = option.children.some(({ value }) => selected.includes(value));
  return (
    <OptGroup
      key={option.value}
      value={option.value}
      label={
        <div tabIndex={0} role="button" className="ant-select-item-option-content" onClick={handleToggle}>
          <div
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            tabIndex={0}
            role="button"
            onClick={handleToggle}
          >
            {toggle ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
            <span className="spsg-select-dropdown-label" style={{ color: 'initial' }} title={option.title}>
              {option.title}
            </span>
          </div>
          <CheckOutlined className={isSelectedChild ? 'selected' : 'hidden'} />
        </div>
      }
    >
      {toggle && option.children.map((opt) => singleOptionRender(opt))}
    </OptGroup>
  );
};

export default {
  singleOptionRenderer,
  nestedOptionsRenderer,
};
