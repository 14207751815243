import PropTypes from 'prop-types';
import React from 'react';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import { fromJS } from 'immutable';

import ScenarioRow from './ScenarioRow';
import { ScenarioRowRecord } from './components/steps.js';

const SortableScenarioRow = SortableContainer(ScenarioRow);

class RowWithSortableSteps extends React.Component {
  static propTypes = {
    scenarioRow: PropTypes.instanceOf(ScenarioRowRecord).isRequired,
    editing: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onSortEnd = (indexObj) => {
    const oldIndex = indexObj.oldIndex;
    const newIndex = indexObj.newIndex;

    const rowData = this.props.scenarioRow.rowData.toArray();
    const newRowData = fromJS(arrayMove(rowData, oldIndex, newIndex));

    this.props.onChange(newRowData);
  };

  render() {
    return (
      <SortableScenarioRow
        { ...this.props }
        axis='x'
        pressDelay={ 200 }
        useDragHandle={ this.props.editing ? false : true }
        onSortEnd={ (oldIndex, newIndex) => this.onSortEnd(oldIndex, newIndex) }
      />
    );
  }
}

export default RowWithSortableSteps;
