import React, { Component } from 'react';
import { Slider } from '../../../../../../../styleguide';

class BorderRadius extends Component {
  updateRadius = (type, value) => {
    const radius = JSON.parse(JSON.stringify(this.props.value));
    radius[type] = value;
    this.props.updateSetting(this.props.settingKey, radius);
  }
  render() {

    const { value, settingsTitle } = this.props;

    return (
      <div>
        <div className="four-options">
          <h4>{settingsTitle}</h4>
          
          <div className="padding-flex radius">
            <div className="padding-label">Top Left</div>
            <Slider min={0} max={20} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateRadius('topleft', val)}
              value={typeof value.topleft === 'number' ? value.topleft : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label">Top Right</div>
            <Slider min={0} max={20} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateRadius('topright', val)}
              value={typeof value.topright === 'number' ? value.topright : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label">Bottom Left</div>
            <Slider min={0} max={20} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateRadius('bottomleft', val)}
              value={typeof value.bottomleft === 'number' ? value.bottomleft : 0}
            />
          </div>
          <div className="padding-flex radius">
            <div className="padding-label">Bottom Right</div>
            <Slider min={0} max={20} step={1} tipFormatter={l=>l+'px'}
              onChange={val => this.updateRadius('bottomright', val)}
              value={typeof value.bottomright === 'number' ? value.bottomright : 0}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BorderRadius;
