import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { Divider, ConfigProvider } from 'antd';
import { Select, Modal, Input, Button, Table } from '../../../styleguide';
import { getParameterByName } from '../../utils.js';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CampaignTypeDropdown from '../../common/CampaignTypeDropdown';
const Search = Input.Search;

export default class SelectTemplateModal extends React.Component {
  state = {
    dataSource: this.props.dataSource,
    selectedCampaignType: 0,
  };

  handleCreateMessage = (record) => {
    const siteId = parseInt(getParameterByName('site_id', window.location));
    const siteGroupId = getParameterByName('site_group_id', window.location);
    let url =
      '/dialogue/scenarios/edit?site_id=' +
      siteId +
      (siteGroupId ? '&site_group_id=' + siteGroupId : '') +
      '&id=' +
      record.key +
      '&template=' +
      true;
    browserHistory.push(url);
  };

  createNewTemplate = () => {
    const siteId = parseInt(getParameterByName('site_id', window.location));
    const siteGroupId = getParameterByName('site_group_id', window.location);
    let url = '/dialogue/scenarios/create?site_id=' + siteId + (siteGroupId ? '&site_group_id=' + siteGroupId : '') + '&campaign_type_id=' + this.state.selectedCampaignType;
    browserHistory.push(url);
  };

  updateSearch = (e) => {
    let dataSource = this.props.dataSource;
    dataSource = dataSource.filter((vw) => {
      if (!e.target.value) return true;
      return (
        vw
          .get('type')
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ dataSource });
  };

  updateCampaignType = (type) => {
    this.setState({ selectedCampaignType: type });
  }

  filteredTemplates = (template) => {
    return this.props.isMultiCampaignEnabled ? template.get("campaignType") === this.state.selectedCampaignType : true;
  }

  render() {
    const campaignTypeSelection = this.props.isMultiCampaignEnabled ? (
      <React.Fragment>
        <span className="campaign-type-label">Campaign Type</span>
        <CampaignTypeDropdown 
          hideLegacyPlaceholder 
          currentUser={this.props.currentUser} 
          propertyType={this.props.propertyType} 
          updateCampaignType={this.updateCampaignType}/>
      </React.Fragment>
	) : (
      <Search
        placeholder="Search.."
        onChange={this.updateSearch}
        className="search-bar"
      />
    );

    const readOnly = this.props.isMultiCampaignEnabled ? !this.state.selectedCampaignType : false;
    const addNewBtn = (
      <div className="scenario-v2 search-container">
        {campaignTypeSelection}
        <Button
          className="create-template"
          type="primary"
          size='small'
          onClick={this.createNewTemplate}
          ghost
          disabled={readOnly}>
          Create New
        </Button>
      </div>
    );

    const templates = this.state.dataSource.filter(template => this.props.isMultiCampaignEnabled ? template.get("campaignType") === this.state.selectedCampaignType : true).toJS();
    const templateUseMsg = templates.length > 0 && <React.Fragment>Click on <b>'Create New'</b> to build new scenario or select from <b>templates</b> below.</React.Fragment>

    return (
      <Modal
        className="select-template-modal"
        title={<div style={{ color: 'white' }}>New Scenario</div>}
        visible={this.props.visible}
        footer={false}
        onCancel={this.props.onCancelModal}
        closable={true}
      >
        {addNewBtn}
        {templates.length > 0 && <Divider />}
        {templateUseMsg}
        {<ConfigProvider renderEmpty={() => null}>
          <Table
            bordered
            showHeader={!this.props.isMultiCampaignEnabled}
            columns={this.props.columnsTemplate}
            pagination={false}
            dataSource={templates}
            rowClassName="select-row"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  this.handleCreateMessage(record);
                }, // click row
              };
            }}
          />
        </ConfigProvider>}
      </Modal>

    );
  }
}
