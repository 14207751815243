import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { DeleteOutlined } from '@ant-design/icons';
import { Table, Input, Button, Alert } from 'antd';

import WarningMessage from '../common/WarningMessage.js.jsx';
import { getUserDomains, addDomain, deleteDomain } from '../../actions/account_actions';
import { Domain, User } from '../../records/account_records';
import { getAllSites } from '../../actions/site_actions';
import Loading from '../common/Loading.js.jsx';
import DropdownPagesHeader from '../common/DropdownPagesHeader';

class Domains extends React.Component {
  static propTypes = {
    domains: ImmutablePropTypes.listOf(PropTypes.instanceOf(Domain)).isRequired,
    getUserDomains: PropTypes.func.isRequired,
    addDomain: PropTypes.func.isRequired,
    deleteDomain: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
  }

  state = {
    domainToDelete: null,
    domainToAdd: '',
    alertError: '',
  };

  componentDidMount() {
    this.props.getUserDomains();
    this.props.getAllSites();
  }

  handleAddDomain = () => {
    if (!this.validateDomain(this.state.domainToAdd)) {
      this.setState({ alertError: `${ this.state.domainToAdd } is not a valid domain`});
      return;
    }
    if (this.props.currentUser.siteAccess) {
      //TODO: fetch for siteAccess
      if (this.canAccessDomain(this.state.domainToAdd)) {
        this.props.addDomain(this.state.domainToAdd);
      } else {
        this.setState({ alertError: `you don't have access to ${ this.state.domainToAdd }`});
      }
    } else {
      this.props.addDomain(this.state.domainToAdd);
    }
    this.setState({ domainToAdd: '' });
    // TODO: success message when domain is added (now comes from rails on refresh)
  }

  canAccessDomain = (domain) => {
    const siteAccessDomains = List(this.props.currentUser.siteAccess.map(id => this.props.sites.find(s => s.id === id).domain));
    const rootDomains = siteAccessDomains
      .filter(d => d.includes('*.'))
      .map(d => d.slice(2));

    return siteAccessDomains.includes(domain) || this.isSubdomain(domain, rootDomains);
  }

  validateDomain(str) {
    var re = /(\*\.)?([a-zA-Z0-9-]+\.)+([a-zA-Z0-9]+)/g;
    const found = str.match(re);
    if (found && found[0].length === str.length) {
      return true;
    } else {
      return false;
    }
  }

  isSubdomain = (domain, rootDomains) => {
    for (let i = 0; i < rootDomains.size; i++) {
      const rootDomain = rootDomains.get(i);
      if (domain.slice(- (rootDomain.length + 1)) === (`.${rootDomain}`)) {
        return true;
      }
    }
    return false;
  }

  setDomainToDelete = (d) => {
    this.setState({ domainToDelete: d });
  }

  deleteDomain = () => {
    this.props.deleteDomain(this.state.domainToDelete.id);
    this.setState({ domainToDelete: null });
  }

  handleInputChange = (e) => {
    if (e.target.value.trim() && this.state.alertError) {
      this.setState({ alertError: '' });
    }
    this.setState({ domainToAdd: e.target.value });
  }

  renderFooter = () => {
    return (
      <div className='table-footer'>
        <Input onChange={ this.handleInputChange } value={ this.state.domainToAdd } />
        <Button type="primary" onClick={ this.handleAddDomain }>Add Domain</Button>
      </div>
    );
  }

  render() {
    if (!this.props.currentUser) return null;

    if (this.props.pendingRequestsMap.some(request => request) || !this.props.domains) {
      return <Loading />;
    }

    const columns = List([Map({
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
    }), Map({
      title: 'Remove',
      dataIndex: 'remove',
      key: 'remove',
    })]);

    let domains = this.props.domains;
    if (this.props.currentUser.siteAccess) {
      domains = this.props.domains.filter(d => this.canAccessDomain(d.name));
    }
    const dataSource = domains.map(d => {
      return Map({
        domain: d.name,
        remove: <DeleteOutlined onClick={ () => this.setDomainToDelete(d) } />,
      });
    });

    const table = (
      <Table
        className="domains-table"
        dataSource={ dataSource.toJS() }
        columns={ columns.toJS() }
        pagination={ false }
        footer={ this.renderFooter }
      />
    );

    const warningMessage = this.state.domainToDelete != null && (
      <WarningMessage
        handleDelete={ this.deleteDomain }
        name={ this.state.domainToDelete.name }
        handleCancel={ () => { this.setState({ domainToDelete: null })}}
      />
    );

    let alert;
    if (this.state.alertError) {
      alert = (
        <Alert
          message={ this.state.alertError }
          type="error"
          onClose={ () => this.setState({ alertError: '' })}
        />
      )
    }

    return (
      <div>
        <DropdownPagesHeader name='Adblock Analytics Domains' />
        <div className='domains-container'>
          <p>Add domains to track adblock analytics for these domains</p>
          { table }
          { warningMessage }
          { alert }
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    domains: store.accountState.getIn(['domains','value']),
    sites: store.siteState.getIn(['sites', 'value']),
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      domains: store.accountState.getIn(['domains','pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    addDomain,
    deleteDomain,
    getUserDomains,
    getAllSites,
  },
)(Domains);
