import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { Select, Tabs } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { getDataRequestData } from '../../../actions/data_request_ccpa_actions';
import { getConsentReportChangeLogData } from '../../../actions/consent_performance_actions_v1.js';
import { getAllSites, getSiteGroups } from '../../../actions/site_actions.js';
import Loading from '../../common/Loading.js.jsx';
import { User } from '../../../records/account_records';
import { ConsentFilters } from '../../../records/ccpa_dnsa_reporting_records.js';
import CustomDateRangeSelect from '../../common/CustomDateRangeSelect';
import CustomDatePicker from '../../reports/CustomDatePicker';
import RequestPerformanceGraph from './RequestPerformanceReportgraph';

import CustomCriteriaSelect from '../../ccpa/ccpa_reporting/CustomCriteriaSelect';
import {
  GEO_FILTER_LIST,
  BROWSERS_LIST,
  DEVICES_LIST,
  OS_FAMILY_LIST,
  GEO_FILTER_LIST_DETAILS,
} from '../../../constants';
import {
  defaultWeekStart,
  defaultWeekEnd,
} from '../../consent/gdpr_performance_tcfv2/helper.js';

const regions = GEO_FILTER_LIST.filter((_, i) => i !== 0);
const { TabPane } = Tabs;
const { Option } = Select;
const countriesCodes = GEO_FILTER_LIST_DETAILS.filter(
  (c) => c.type == 'country'
).map((c) => c.code);
const regionsAndCountriesAll = GEO_FILTER_LIST_DETAILS.filterNot(g => g.type == 'province').map((g) =>
  g.type == 'region'
    ? 'region:' + g.code
    : g.type == 'state'
      ? 'state:' + g.code
      : g.code
);

const regionValueList = List(['region:EU', 'region:EEA', 'region:NA']);

const browsers = BROWSERS_LIST;
const devices = DEVICES_LIST;
const os = OS_FAMILY_LIST;
const newConsentFiltersRecord = new ConsentFilters({
  startDate: defaultWeekStart,
  endDate: defaultWeekEnd,
  geo: countriesCodes.toJS(),
  device: devices.toJS(),
  browser: browsers.toJS(),
  os: os.toJS(),
  state: List(['CA']),
});
const rangeSelect = Map({
  startDate: defaultWeekStart,
  endDate: defaultWeekEnd,
  datePicker: 'WEEK',
});
let newConsentFilters, newVendorFilters;

class DataPrivacy extends React.Component {
  static propTypes = {
    getAllSites: PropTypes.func.isRequired,
    sites: ImmutablePropTypes.list.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      consentFilters: newConsentFiltersRecord,
      vendorFilters: newVendorFilters,
      datePicker: 'WEEK',
      selectedSite: 'Select a property',
      criteriaList: List(),
      globalCriteriaList: List(),
      messagedTableData: List(),
      rangeSelect: rangeSelect,
      siteId: List(),
      geo: regionsAndCountriesAll,
      device: devices,
      browser: browsers,
      os: os,
      vendorRegions: List(),
      vendorProperty: 'Select Property',
      showTopLevelCriteria: true,
      siteGroupsSelected: List([]),
      prevSelectedSites: List([]),
      requestType: 'All',
      selectedTab: '1',
      firstLoad: true,
      toggleLine: false,
      selectedDefaultTab: 'All',
      prevSelectedRegions: regionValueList,
      prevSelectedCountries: countriesCodes,
    };
  }

  async componentDidMount() {
    let sites = await this.props.getAllSites();

    if (sites) {
      sites = this.getSitesWithoutSiteGroups(sites);
      this.getInitialView(sites);
    } else {
      this.getInitialView(this.getSitesWithoutSiteGroups(this.props.sites));
    }
  }

  initialiseConsentFilter = () =>
    new ConsentFilters({
      startDate: rangeSelect.get('startDate'),
      endDate: rangeSelect.get('endDate'),
      geo: countriesCodes.toJS(),
      device: devices.toJS(),
      browser: browsers.toJS(),
      os: os.toJS(),
      state: List(['CA']),
    });

  getInitialView(sites) {
    let valueList = sites.map((s) => s.id.toString());
    newConsentFilters = this.state.consentFilters.set(
      'siteId',
      valueList.toJS()
    );
    this.updateConsentState(newConsentFilters);

    let list = this.state.globalCriteriaList.push(this.state.consentFilters);
    const newConsentFiltersRecord = this.initialiseConsentFilter();
    this.setState(
      {
        globalCriteriaList: list,
        siteId: List(),
        geo: regionsAndCountriesAll,
        device: devices,
        browser: browsers,
        os: os,
        consentFilters: newConsentFiltersRecord,
        siteGroupsSelected: List([]),
        prevSelectedSites: List([]),
        prevSelectedCountries: countriesCodes,
        prevSelectedRegions: regionValueList,
      },
      () => {
        let getSelectedSiteIds = List(
          this.state.globalCriteriaList
            .map((c, i) => List(c.get('siteId')))
            .reduce((prev, current) =>
              prev
                .toSet()
                .union(current.toSet())
                .toList()
            )
        );
        this.props.getDataRequestData(
          this.state.globalCriteriaList,
          this.state.rangeSelect,
          this.state.requestType,
          this.state.selectedTab
        );
      }
    );
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  renderTab = (title, value = 0) => {
    return (
      <div className="request-tab-container">
        <p className="title">{title}</p>
        <p className="value">{value}</p>
      </div>
    );
  };

  getSitesWithoutSiteGroups = (sites) => {
    //XXX not filtering for all sites
    return sites.filterNot((s) => s.type === 'property_group');
  };

  onChangeTab = (index) => {
    this.setState({ selectedTab: index }, () => {
      this.props.getDataRequestData(
        this.state.criteriaList,
        this.state.rangeSelect,
        this.state.requestType,
        this.state.selectedTab
      );
    });
  };

  updateCriteriaListCb = (criteriaList) => {
    this.setState({ requestType: this.state.requestType, firstLoad: false });
    this.setState({ criteriaList }, () => {
      let getSelectedSiteIds = List(
        this.state.criteriaList
          .map((c, i) => List(c.get('siteId')))
          .reduce((prev, current) =>
            prev
              .toSet()
              .union(current.toSet())
              .toList()
          )
      );

      this.props.getDataRequestData(
        this.state.criteriaList,
        this.state.rangeSelect,
        this.state.requestType,
        this.state.selectedTab
      );

      this.props.getConsentReportChangeLogData(
        getSelectedSiteIds,
        this.props.currentUser.accountId,
        this.state.rangeSelect
      );
    });
  };

  onChangeTabDefault = (index) => {
    switch (index) {
      case '1':
        this.handleChangeDefault('All');
        break;
      case '2':
        this.handleChangeDefault('Do not sell');
        break;
      case '3':
        this.handleChangeDefault('Delete my data');
        break;
      case '4':
        this.handleChangeDefault('DSAR');
        break;
    }
  };

  renderSection(total, total_recieved, propertyTableGraph) {
    return (
      <div className="consent-tabs-container">
        <div class="data-request-tab">
          <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
            <TabPane
              tab={this.renderTab('Total request', total.toLocaleString())}
              key="1"
            >
              <div className="consentblock card">
                {this.loadCriteria(propertyTableGraph)}
              </div>
            </TabPane>
            <TabPane
              tab={this.renderTab(
                'Recieved/Initial',
                total_recieved.toLocaleString()
              )}
              key="2"
            >
              <div className="consentblock card">
                {this.loadCriteria(propertyTableGraph)}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }

  renderRequestSection = (section) => {
    var tableData = this.props.propertyData.map((items, i) =>
      items.get('dataRequestTableData')
    );
    var total = 0;
    var total_recieved = 0;
    var dsar_array, dns_array, delete_array;

    let { sites } = this.props;
    if (this.props.currentUser.siteAccess) {
      sites = sites.filter((s) =>
        this.props.currentUser.siteAccess.includes(s.id)
      );
    }

    let propertyTableGraph =
      this.props.pendingRequestsMap.get('requestData') ||
        this.props.pendingRequestsMap.get('changeLogData') ? (
          <Loading />
        ) : (
          <RequestPerformanceGraph
            ref="child"
            firstLoad={this.state.firstLoad}
            section={section}
            sites={sites}
            criteriaList={this.state.criteriaList}
            changeLogData={this.props.changeLogData}
            consentPropertyData={this.props.propertyData}
            selectedDefaultTab={this.state.selectedDefaultTab}
            selectedTab={this.state.selectedTab}
          />
        );
    switch (section) {
      case 'All':
        total =
          tableData !== undefined && tableData.size != 0
            ? tableData
              .map((entry) => Number(entry.get('totalAll')))
              .reduce((prev, current) => prev + current)
            : 0;
        total_recieved =
          tableData !== undefined && tableData.size != 0
            ? tableData
              .map((entry) => Number(entry.get('totalRecieved')))
              .reduce((prev, current) => prev + current)
            : 0;
        return (
          <div className="consent-tabs-container">
            <div class="data-request-tab">
              <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
                <TabPane
                  tab={this.renderTab('Total request', total.toLocaleString())}
                  key="1"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  tab={this.renderTab(
                    'Recieved/Initial',
                    total_recieved.toLocaleString()
                  )}
                  key="2"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        );
        break;

      case 'Do not sell':
        total =
          dns_array !== undefined && dns_array.size != 0
            ? dns_array
              .map((entry) => Number(entry.get('total')))
              .reduce((prev, current) => prev + current)
            : 0;
        total_recieved =
          dns_array !== undefined && dns_array.size != 0
            ? dns_array
              .map((entry) => Number(entry.get('totalRecieved')))
              .reduce((prev, current) => prev + current)
            : 0;
        return (
          <div className="consent-tabs-container">
            <div class="data-request-tab">
              <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
                <TabPane
                  tab={this.renderTab('Total request', total.toLocaleString())}
                  key="1"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  tab={this.renderTab(
                    'Recieved/Initial',
                    total_recieved.toLocaleString()
                  )}
                  key="2"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        );
        break;
      case 'Delete my data':
        total =
          delete_array !== undefined && delete_array.size != 0
            ? delete_array
              .map((entry) => Number(entry.get('total')))
              .reduce((prev, current) => prev + current)
            : 0;
        total_recieved =
          delete_array !== undefined && delete_array.size != 0
            ? delete_array
              .map((entry) => Number(entry.get('totalRecieved')))
              .reduce((prev, current) => prev + current)
            : 0;
        return (
          <div className="consent-tabs-container">
            <div class="data-request-tab">
              <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
                <TabPane
                  tab={this.renderTab('Total request', total.toLocaleString())}
                  key="1"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  tab={this.renderTab(
                    'Recieved/Initial',
                    total_recieved.toLocaleString()
                  )}
                  key="2"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        );
        break;
      case 'DSAR':
        total =
          dsar_array !== undefined && dsar_array.size != 0
            ? dsar_array
              .map((entry) => Number(entry.get('total')))
              .reduce((prev, current) => prev + current)
            : 0;
        total_recieved =
          dsar_array !== undefined && dsar_array.size != 0
            ? dsar_array
              .map((entry) => Number(entry.get('totalRecieved')))
              .reduce((prev, current) => prev + current)
            : 0;
        var total_completed =
          dsar_array !== undefined && dsar_array.size != 0
            ? dsar_array
              .map((entry) => Number(entry.get('totalcompleted')))
              .reduce((prev, current) => prev + current)
            : 0;
        var total_uploaded =
          dsar_array !== undefined && dsar_array.size != 0
            ? dsar_array
              .map((entry) => Number(entry.get('totaluploaded')))
              .reduce((prev, current) => prev + current)
            : 0;
        var total_delievered =
          dsar_array !== undefined && dsar_array.size != 0
            ? dsar_array
              .map((entry) => Number(entry.get('totaldelievered')))
              .reduce((prev, current) => prev + current)
            : 0;
        return (
          <div className="consent-tabs-container">
            <div class="data-request-tab">
              <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
                <TabPane
                  tab={this.renderTab('Total request', total.toLocaleString())}
                  key="1"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  tab={this.renderTab(
                    'Recieved/Initial',
                    total_recieved.toLocaleString()
                  )}
                  key="2"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  tab={this.renderTab(
                    'File Uploaded',
                    total_uploaded.toLocaleString()
                  )}
                  key="3"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  tab={this.renderTab(
                    'Completed',
                    total_completed.toLocaleString()
                  )}
                  key="4"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  tab={this.renderTab(
                    'Delivered',
                    total_delievered.toLocaleString()
                  )}
                  key="5"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        );
        break;
      case 'DefaultView':
        let total_all,
          total_dsr,
          total_dns,
          total_delete = 0;

        total_all =
          tableData !== undefined && tableData.size != 0
            ? tableData
              .map((entry) => Number(entry.get('totalAll')))
              .reduce((prev, current) => prev + current)
            : 0;
        total_dsr =
          dsar_array !== undefined && dsar_array.size != 0
            ? dsar_array
              .map((entry) => Number(entry.get('total')))
              .reduce((prev, current) => prev + current)
            : 0;
        total_dns =
          dns_array !== undefined && dns_array.size != 0
            ? dns_array
              .map((entry) => Number(entry.get('total')))
              .reduce((prev, current) => prev + current)
            : 0;
        total_delete =
          delete_array !== undefined && delete_array.size != 0
            ? delete_array
              .map((entry) => Number(entry.get('total')))
              .reduce((prev, current) => prev + current)
            : 0;

        return (
          <div className="consent-tabs-container">
            <div class="data-request-tab">
              <Tabs defaultActiveKey="1" onChange={this.onChangeTabDefault}>
                <TabPane
                  tab={this.renderTab('Total request', total_all)}
                  key="1"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  onChange={this.onChangeTabDefault}
                  tab={this.renderTab('Do not sell', total_dns)}
                  key="2"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane
                  tab={this.renderTab('Delete my Data', total_delete)}
                  key="3"
                >
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
                <TabPane tab={this.renderTab('DSAR', total_dsr)} key="4">
                  <div className="consentblock card">
                    {this.loadCriteria(propertyTableGraph)}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        );
        break;
      default:
        return (
          <div class="data-request-tab">
            <Tabs defaultActiveKey="1">
              <TabPane tab={this.renderTab('Total request', 0)} key="1">
                <div className="consentblock card"></div>
              </TabPane>
            </Tabs>
          </div>
        );
    }
  };

  updateConsentState = (newConsentFilters) => {
    this.setState({ consentFilters: newConsentFilters });
  };

  getPropertyName = (siteId) => {
    let index = this.props.sites.findIndex((item) => {
      return item.get('id') === Number(siteId);
    });
    let value = this.props.sites.getIn([index, 'domain']);
    return value;
  };

  loadCriteria = (loadComponent) => {
    if (
      this.state.criteriaList.size === 0 &&
      this.state.globalCriteriaList.size === 0
    ) {
      return (
        <div className="adblock card no-sites-message">
          <p>
            Please add the criteria(s) to generate a request performance report.
          </p>
        </div>
      );
    } else {
      return loadComponent;
    }
  };

  handleSelectAll = (setArrKey, value) => {
    let siteGroupsSelected = List([]);
    let resultSiteIds;
    let sites = this.props.sites;
    if (this.props.currentUser.siteAccess) {
      sites = this.props.sites.filter((s) =>
        this.props.currentUser.siteAccess.includes(s.id)
      );
    }
    sites = this.getSitesWithoutSiteGroups(sites);

    if (setArrKey == 'siteId') {
      resultSiteIds = value
        .map((id) => {
          if (id.slice(0, 11) === 'site-group:') {
            siteGroupsSelected = siteGroupsSelected.push(id);
            return this.getSiteGroupSiteIds(Number(id.slice(11)));
          } else {
            return id;
          }
        })
        .flatten(true)
        .map(String)
        .toSet()
        .toList();
      this.state.siteGroupsSelected.forEach((sg) => {
        if (!siteGroupsSelected.includes(sg)) {
          resultSiteIds = resultSiteIds.filterNot((id) => {
            return this.getSiteGroupSiteIds(Number(sg.slice(11))).includes(
              Number(id)
            );
          });
        }
      });
      this.state.prevSelectedSites.forEach((ss) => {
        if (!value.includes(ss)) {
          let index = resultSiteIds.findIndex((item) => {
            return item == ss;
          });
          resultSiteIds = resultSiteIds.delete(index);
          this.props.siteGroups.map((sg) => {
            if (sg.siteIds.includes(Number(ss))) {
              let ind = siteGroupsSelected.findIndex((item) => {
                return item == 'site-group:' + sg.siteId;
              });
              siteGroupsSelected = siteGroupsSelected.delete(ind);
            }
          });
        }
      });
      value = siteGroupsSelected
        .toSet()
        .union(resultSiteIds.toSet())
        .toList();
    }

    const checkForAll = value.some((id) => id === 'All');
    let valueList;
    const ddObj = {
      geo: regions,
      device: devices,
      browser: browsers,
      os: os,
      siteId: sites
        .map((s) => s.id.toString())
        .concat(this.props.siteGroups.map((sg) => 'site-group:' + sg.siteId)),
    };

    if (value && value.size && checkForAll) {
      if (value.size === ddObj[setArrKey].size + 1) {
        valueList = List();
      } else {
        if (setArrKey == 'siteId') {
          siteGroupsSelected = this.props.siteGroups.map(
            (sg) => 'site-group:' + sg.siteId.toString()
          );
        }
        valueList = ddObj[setArrKey];
      }
    } else {
      valueList = value;
    }
    this.setState({ [setArrKey]: valueList, siteGroupsSelected }, () => {
      if (setArrKey == 'siteId') {
        valueList = valueList.filterNot(
          (sg) => sg.slice(0, 11) === 'site-group:'
        );
        this.setState({ prevSelectedSites: valueList.toJS() });
      }
      newConsentFilters = this.state.consentFilters.set(
        setArrKey,
        valueList.toJS()
      );
      this.updateConsentState(newConsentFilters);
    });
  };

  selectDates = (dates, datePicker) => {
    newConsentFilters = this.state.consentFilters
      .set('startDate', dates.start)
      .set('endDate', dates.end);
    let rangeSelect = this.state.rangeSelect
      .set('startDate', dates.start)
      .set('endDate', dates.end)
      .set('datePicker', datePicker);
    this.setState(
      { datePicker, rangeSelect, consentFilters: newConsentFilters },
      () => {
        if (
          this.state.criteriaList.size < 3 &&
          this.state.criteriaList.size > 0 &&
          this.state.consentFilters
        ) {
          let getSelectedSiteIds = List(
            this.state.criteriaList
              .map((c, i) => List(c.get('siteId')))
              .reduce((prev, current) =>
                prev
                  .toSet()
                  .union(current.toSet())
                  .toList()
              )
          );
          this.props.getDataRequestData(
            this.state.criteriaList,
            this.state.rangeSelect,
            this.state.requestType,
            this.state.selectedTab
          );
          this.props.getConsentReportChangeLogData(
            getSelectedSiteIds,
            this.props.currentUser.accountId,
            this.state.rangeSelect
          );
        }
      }
    );
  };

  selectDatePicker = (event, dates) => {
    this.selectDates(dates, event.target.value);
  };

  addCriteria = () => {
    this.setState({ requestType: this.state.requestType, firstLoad: false });

    if (
      this.state.criteriaList.size < 3 &&
      this.state.consentFilters &&
      this.state.consentFilters.get('siteId').length > 0 &&
      this.state.consentFilters.get('geo').length > 0
    ) {
      let list = this.state.criteriaList.push(this.state.consentFilters);
      const newConsentFiltersRecord = new ConsentFilters({
        startDate: this.state.rangeSelect.get('startDate'),
        endDate: this.state.rangeSelect.get('endDate'),
        geo: regions.toJS(),
        device: devices.toJS(),
        browser: browsers.toJS(),
        os: os.toJS(),
      });
      this.setState(
        {
          criteriaList: list,
          siteId: List(),
          geo: regions,
          device: devices,
          browser: browsers,
          os: os,
          consentFilters: newConsentFiltersRecord,
        },
        () => {
          let getSelectedSiteIds = List(
            this.state.criteriaList
              .map((c, i) => List(c.get('siteId')))
              .reduce((prev, current) =>
                prev
                  .toSet()
                  .union(current.toSet())
                  .toList()
              )
          );
          this.props.getDataRequestData(
            this.state.criteriaList,
            this.state.rangeSelect,
            this.state.requestType,
            this.state.selectedTab
          );
          this.props.getConsentReportChangeLogData(
            getSelectedSiteIds,
            this.props.currentUser.accountId,
            this.state.rangeSelect
          );
        }
      );
    }
  };

  toggleLine() {
    this.refs.child.changeLine();
  }

  handleChange = (value) => {
    this.setState({ requestType: value.label });
    this.props.getDataRequestData(
      this.state.criteriaList,
      this.state.rangeSelect,
      value.key,
      this.state.selectedTab
    );
    this.renderRequestSection(value.label);
    this.toggleLine();
  };

  handleChangeDefault = (value) => {
    this.setState({ selectedDefaultTab: value });
    this.props.getDataRequestData(
      this.state.globalCriteriaList,
      this.state.rangeSelect,
      value,
      this.state.selectedTab
    );
    this.renderRequestSection(value);
    this.toggleLine();
  };

  render() {
    let { sites, siteGroups } = this.props;
    if (this.props.currentUser.siteAccess) {
      sites = sites.filter((s) =>
        this.props.currentUser.siteAccess.includes(s.id)
      );
      siteGroups = siteGroups
        .filter(({ siteIds }) => siteIds.toJS().every((id) => sites.toJS().find(site => site.id == id)));
    }

    sites = this.getSitesWithoutSiteGroups(sites);


    let section = this.state.firstLoad ? 'DefaultView' : this.state.requestType;
    return (
      <div className="consent-performance">
        <CustomCriteriaSelect
          sites={sites}
          siteGroups={siteGroups}
          showTopLevelCriteria={this.state.showTopLevelCriteria}
          updateCriteriaListCb={this.updateCriteriaListCb}
          rangeSelect={this.state.rangeSelect}
          showState={true}
          accountFeatures={this.props.currentUser.accountFeatures}
        />
        {this.state.criteriaList.size > 0 && !this.state.firstLoad && (
          <div class="request-type-container">
            <div class="data-request-select-container">
              Request type
              <Select
                labelInValue
                defaultValue={{ key: 'All' }}
                style={{ width: 180 }}
                onChange={this.handleChange}
              >
                <Option value="All">All</Option>
                <Option value="dns">Do not sell</Option>
                <Option value="DELETE">Delete my data</Option>
                <Option value="DSAR">DSAR</Option>
              </Select>
            </div>
            <div className="ccpa-filter">
              <CustomDateRangeSelect selectDatePicker={this.selectDatePicker} />
              <CustomDatePicker
                className="cp-picker"
                selectDates={this.selectDates}
                format="MM/DD/YY"
                limit={60}
                disabled={this.props.pendingRequestsMap.some(
                  (request) => request
                )}
                disabledDateOffset={1}
                selectedDatePicker={this.state.rangeSelect.get('datePicker')}
              />
            </div>
          </div>
        )}

        {this.renderRequestSection(section)}
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    propertyData: store.dataRequestReducer.getIn(['requestData', 'value']),
    changeLogData: store.consentPerformanceReportState.getIn([
      'changeLogData',
      'value',
    ]),
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      propertyData: store.dataRequestReducer.getIn(['requestData', 'pending']),
      changeLogData: store.consentPerformanceReportState.getIn([
        'changeLogData',
        'pending',
      ]),
    }),
  };
};

export default connect(mapStateToProps, {
  getAllSites,
  getConsentReportChangeLogData,
  getDataRequestData,
  getSiteGroups,
})(DataPrivacy);
