import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Tabs, Button } from 'antd';
import { Vendor, VendorWrapper } from '../../../../../records/vendor_list_records';
import ActionsTab from '../../ActionsTab';
import VendorCookiesTab from '../../VendorCookiesTab';

const TabPane = Tabs.TabPane;

export default class VendorDetailModal extends React.Component {
  static propTypes = {
    vendorWrapper: PropTypes.instanceOf(VendorWrapper).isRequired,
    vendor: PropTypes.instanceOf(Vendor).isRequired,
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    handleApply: PropTypes.func.isRequired,
    deleteVendor: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  }

  state = {
    vendorWrapper: this.props.vendorWrapper,
  }

  updateVendorsActions = (actions, actionType) => {
    const vendorWrapper = this.state.vendorWrapper.set(actionType, actions);
    this.setState({ vendorWrapper });
  }

  handleCancelModalChanges = () => {
    this.setState({ vendorWrapper: this.props.vendorWrapper });
    this.props.closeModal();
  }

  handleApply = () => {
    this.props.handleApply(this.state.vendorWrapper);
  }

  handleRemove = () => {
    this.props.deleteVendor(this.props.vendor);
  }

  renderFooter = () => {
    if (this.props.readOnly) {
      return null;
    } else {
      return (
        [
          <Button key="back" onClick={this.handleCancelModalChanges}>Cancel</Button>,
          <Button className="purpose-delete" key="delete" onClick={this.handleRemove}>Remove</Button>,
          <Button key="submit" type="primary" onClick={this.handleApply}>Apply changes</Button>,
        ]
      );
    }
  }

  render() {
    return (
      <Modal
        onOk={ this.handleApply }
        visible={ this.props.visible }
        onCancel={ this.handleCancelModalChanges }
        className='edit-purpose'
        closable={ false }
        destroyOnClose
        footer={ this.renderFooter() }
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Vendor Details" key="1">
            <VendorCookiesTab
              cookies={ this.props.vendorWrapper.cookies }
              vendor={ this.props.vendor }
            />
          </TabPane>
          <TabPane tab="Consent Actions" key="2">
            <ActionsTab
              actions={ this.state.vendorWrapper.consentActions }
              updateActions={ (actions) => this.updateVendorsActions(actions, 'consentActions') }
              readOnly={ this.props.readOnly }
            />
          </TabPane>
          <TabPane tab="Reject Actions" key="3">
            <ActionsTab
              actions={ this.state.vendorWrapper.rejectActions }
              updateActions={ (actions) => this.updateVendorsActions(actions, 'rejectActions') }
              actionType='rejectActions'
              readOnly={ this.props.readOnly }
            />
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}
