import moment from 'moment';

// eslint-disable-next-line camelcase
export function isFiltersProvided({ properties, region, dateFrom, dateTo }) {
  return !!properties && !!region && !!dateFrom && !!dateTo;
}

export function mapSelectedFilters(selectedFilters) {
  return {
    dateFrom: moment(selectedFilters?.start)?.format('YYYY-MM-DD') || '',
    dateTo: moment(selectedFilters?.end)?.format('YYYY-MM-DD') || '',
    benchmarks: selectedFilters?.benchmark_websites?.join(';') || '', 
    properties: selectedFilters?.websites?.join(';') || '',          
    region: selectedFilters?.regions?.join(';') || '',               
  };
}

export function getUniqueVendorIdsList(detailMetricData) {
  return [...new Set(detailMetricData.map((item) => item.vendor_id))];
}

export async function requestDataDependOnTableFilters(
  { filters, isEnabledVendorStatusManagementFeature },
  detailDataRequest,
  vendorManagementRequest,
) {
  function prepareVendorIds(list) {
    return [...new Set(list.map((item) => item.vendor))];
  }

  if (!isEnabledVendorStatusManagementFeature) {
    detailDataRequest(filters);
  } else if (filters?.vendor_status?.length) {
    const tag = filters?.vendor_status?.filter((filter) => ['Change Detected', 'New'].includes(filter));
    const status = filters?.vendor_status?.filter((filter) => !['Change Detected', 'New'].includes(filter));
    const vendorManagementData = await vendorManagementRequest({ status, tag, vendors: [] });
    if (vendorManagementData.length) {
      const vendorIds = prepareVendorIds(vendorManagementData);
      detailDataRequest(filters, vendorIds);
    }
  } else {
    const detailsData = await detailDataRequest(filters);
    if (detailsData?.results?.length) {
      vendorManagementRequest({ ...filters, vendors: getUniqueVendorIdsList(detailsData?.results) });
    }
  }
}
