import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { Modal } from 'antd';
import { message, Button } from '../../../styleguide';

export default class DeleteNotLinked extends React.Component {
  static propTypes = {
    moveToDraft: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  goToPropertySets = () => {
    browserHistory.push(`/privacy_lens/domain_sets?standardId=${this.props.standard.id}`);
  }

  deleteStandard = () => {
    this.props.deleteStandard(this.props.accountId, this.props.standard.id || this.props.standard._id).then((standard) => {
      message.success(<>Standard <strong>{standard.name}</strong> was successfully deleted</>)
    });
  }  

  render() {
    const footer = [
      <Button key="1" onClick={this.props.closeModal} className="cancel">
        Cancel
      </Button>
    ];
    if (this.props.standard.isActive && this.props.standard.appliedToDomainSetIds?.length === 0) {
      footer.push(
        <Button key="2" onClick={this.props.moveToDraft} type="secondary">
          Move to Drafts
        </Button>
      );
    }
    footer.push(          
      <Button key="3" onClick={this.deleteStandard} type="primary">
        DELETE PERMANENTLY
      </Button>
    );
    return (
      <Modal
        onOk={this.handleApply}
        okText='Save'
        onCancel={this.props.closeModal}
        className='purple new-design'
        visible
        footer={footer}
      >
        <p className="title">Standard - Delete</p>

        <div className='modal-container'>
          <p>
            Once deleted, all associated data with <span>{this.props.standard.name}</span> will be deleted permanently and cannot be retrieved at all. Instead, you can also opt to move standard to DRAFT.
          </p>
        </div>
      </Modal>
    );
  }
}