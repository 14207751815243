import React from 'react';
import { List, Map } from 'immutable';
import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import { Select, Button, Radio, Input, Infotip } from '../../../../styleguide';
import { styles } from './styles';
import { ReferrerRegexMatch } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import {
  convertStringsToregex,
  getRegexType,
  getSplitStringsFromRegex
} from '../helper';
import { SCENARIO_STEPS } from '../../scenarios/versionTwo/components/steps';
import { urlMatchClass } from '../TemplateBuilder/Data';

const { Option } = Select;

const newrow = Map({
  continue_on_satisfy: true,
  strings: List(),
  regex: 'beginWith',
});

export default class PageUrlMatch extends React.Component {
  state = {
    inc: 1,
    rows: List([newrow]),
  };

  componentWillMount() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .filter((r) => r.get('className') == className);
    let data = List();
    if (getSavedData && getSavedData.size > 0 && this.props.isUpdate) {
      data = getSavedData.map((r, i) => {
        return Map({
          continue_on_satisfy: r.get('data').get('continue_on_satisfy'),
          strings: List(
            r.get('data').get('regex')
              ? getSplitStringsFromRegex(r.get('data').get('regex'))
              : []
          ),
          regex: getRegexType(r.get('data').get('regex')),
          only_regex: getRegexType(r.get('data').get('regex')) === 'regex'
        });
      });
    }
    this.setState({
      rows: getSavedData && data && data.size > 0 ? data : List([newrow]),
    });
  }

  incrementRows = () => {
    let value = this.state.inc;
    value = value + 1;
    let rows = this.state.rows.push(newrow);
    this.setState({ rows: rows });
  };

  cancelCondition = () => {
    this.props.closePanel();
  };

  updateRow = (index, sName, newVal) => {
    let rows = this.state.rows;
    if (sName === 'regex') {
      const currentRegex = rows.getIn([index, 'regex'])
      const currentStrings = rows.getIn([index, 'strings'])

      if (newVal === 'regex' && newVal !== currentRegex) {
        // if we're switching to regex, convert "strings" list into list of just the translated regex
        rows = rows.setIn([index, 'strings'], List([convertStringsToregex(currentStrings, currentRegex)]));
      } else if (currentRegex === 'regex' && newVal !== 'regex') {
        // if we're switching from regex, convert the regex in "strings" list 0 to multiple key words
        rows = rows.setIn([index, 'strings'], List(getSplitStringsFromRegex(currentStrings.get(0))));
      }
    }

    if (sName === 'strings' && rows.getIn([index, 'regex']) === 'regex') {
      // if type is regex, we need to disable the other options
      const newRegex = newVal.toJS().toString()
      rows = rows.setIn([index, 'only_regex'], newRegex.length > 0 && getRegexType(newRegex) === 'regex')
    }

    rows = rows.setIn([index, sName], newVal);

    this.setState({ rows });
  };

  deleteRow = (index) => {
    if (this.state.rows.size > 1) {
      const rows = this.state.rows.delete(index);
      this.setState({ rows });
    }
  };

  onAddEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    let rowData = this.props.scenarioEdited.steps.get(this.props.activeStep)
      .rowData;
    this.state.rows.map((row) => {
      stepBlockData = stepBlockData.set(
        'data',
        new ReferrerRegexMatch({
          regex: convertStringsToregex(row.get('strings'), row.get('regex')),
          continue_on_satisfy: row.get('continue_on_satisfy'),
        })
      );
      rowData = rowData.unshift(stepBlockData);
    });
    this.props.onChange(this.props.activeStep, rowData);
  };

  onUpdateEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    let rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.filterNot((r) => r.className == urlMatchClass);
    this.state.rows.map((row) => {
      stepBlockData = stepBlockData.set(
        'data',
        new ReferrerRegexMatch({
          regex: convertStringsToregex(row.get('strings'), row.get('regex')),
          continue_on_satisfy: row.get('continue_on_satisfy'),
        })
      );
      rowData = rowData.unshift(stepBlockData);
    });
    this.props.onChange(this.props.activeStep, rowData);
  };

  render() {
    const valueOption = List([
      Map({ label: 'Begins with', value: 'beginWith' }),
      Map({ label: 'Ends with', value: 'endsWith' }),
      Map({ label: 'Contains', value: 'contains' }),
      Map({ label: 'RegEx', value: 'regex' }),
    ]);

    const readOnly = this.state.rows.some(r => !(r.get('strings').size && r.get('strings').every(s => s.match(/^\S*$/))));

    return (
      <div style={styles.scenarioConditionPanelCol}>
        <div style={styles.scenarioInnerWrapperTwo}>
          <span className="condition-label-two">Condition:&nbsp; </span>
          <span className="condition-title"> {this.props.conditionLabel}</span>
          <span style={{ marginLeft: '5px' }} className="condition-label-two">
            {`(Add or copy multiple strings seprated by comma and avoid spaces in values)`}
          </span>
        </div>
        {this.state.rows.map((item, index) => (
          <div key={index} style={styles.scenarioInnerWrapperTwo}>
            <div style={styles.scenarioItemFirst}>
              <Radio.Group
                value={item.get('continue_on_satisfy')}
                onChange={(e) =>
                  this.updateRow(index, 'continue_on_satisfy', e.target.value)
                }
                style={{ marginTop: '10px' }}
                defaultValue={true}
                buttonStyle="solid"
              >
                <Radio.Button value={true}>Include</Radio.Button>
                <Radio.Button value={false}>Exclude</Radio.Button>
              </Radio.Group>
            </div>
            <div style={styles.valueWrapper}>
              <span className="condition-label-two">URL</span>
              <Select
                value={item.get('regex')}
                onChange={(value) => this.updateRow(index, 'regex', value)}
                placeholder="Select"
                showArrow={true}
              >
                {
                  valueOption.map((s) => (
                    <Option disabled={s.get('value') !== 'regex' && item.get('only_regex')} key={s.get('value')}>{s.get('label')}</Option>
                  ))
                }
              </Select>
            </div>
            <div style={styles.stringWrapper}>
              <span className="condition-label-two">Values</span>
              { item.get('regex') === 'regex' ? 
                <Input 
                  style={{ width: '100%' }} 
                  value={ item.getIn(['strings', 0]) } 
                  onChange={(e) => this.updateRow(index, 'strings', List([e.target.value]))}
                />
               : <Infotip title="Generated RegEx:" content={ <span className="scenario-condition-popover-regex">{ convertStringsToregex(item.get('strings'), item.get('regex')) }</span> }>
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    value={item.get('strings').toJS()}
                    onChange={(v) => this.updateRow(index, 'strings', List(v))}
                    tokenSeparators={[',']}
                    dropdownClassName="hide-scenario-multiple-dropdown"
                  ></Select>
                 </Infotip>
              }
            </div>
            {this.state.rows.size > 1 && (
              <CloseCircleFilled
                style={{
                  fontSize: '18px',
                  color: '#411F90',
                  alignSelf: 'flex-end',
                  marginLeft: '20px',
                }}
                onClick={() => this.deleteRow(index)} />
            )}
            {index + 1 === this.state.rows.size ? (
              <PlusCircleFilled
                style={{
                  fontSize: '18px',
                  color: '#411F90',
                  alignSelf: 'flex-end',
                  marginLeft: '20px',
                }}
                onClick={() => this.incrementRows()}></PlusCircleFilled>
            ) : (
              <PlusCircleFilled
                style={{
                  fontSize: '18px',
                  color: '#411F90',
                  alignSelf: 'flex-end',
                  marginLeft: '20px',
                  opacity: '0',
                }}></PlusCircleFilled>
            )}
          </div>
        ))}
        <Divider />
        <div style={styles.scenarioButtonWrapperTwo}>
          <Button
            onClick={
              this.props.buttontitle == 'Add'
                ? this.onAddEvent
                : this.onUpdateEvent
            }
            type="primary"
            size='small'
            disabled={readOnly}
          >
            {this.props.buttontitle}
          </Button>
          <Button
            onClick={() => this.cancelCondition()}
            style={styles.scenarioMarginLeft}
            type="secondary"
            size='small'
            ghost
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
