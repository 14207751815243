import React from 'react';

import ImmutablePropTypes from 'react-immutable-proptypes';
import { Tooltip } from 'antd';

const MessageDescription = (props) => {
  return <Tooltip placement="topLeft" title={ 'ID: ' + props.message.get('id') }>{ props.message.get('description') }</Tooltip>;
};

//TODO: use message record once campaigns page refactoring is merged
MessageDescription.propTypes = {
  message: ImmutablePropTypes.map.isRequired,
};

export default MessageDescription;
