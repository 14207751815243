import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Typography, Divider, Spin, Button, Tooltip, Modal } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import {
    getDiagnoseSummary,
    setAvailableMetrics,
    setRegionsList,
    setMetricsList,
    setWebsitePropertiesListFilters,
} from '../../../actions/diagnose_dashboard_actions';
import { getPropertiesData, getRegionsData } from '../../../api/diagnose_dashboard/diagnose_dashboard';
import { accountFeaturesToAvailableDiagnoseMetrics } from '../../../helpers/diagnose/metrics';
import DashboardFilters from '../../diagnose/dashboard/dashboard_filters/DashboardFilters';
import RecommendationsWindow from '../../diagnose/dashboard/recommendations-window/RecommendationsWindow';
import { getFromLocalStorage } from '../../../helpers/diagnose/utils';
import useSearchParams from '../../diagnose/dashboard/shared/useSearchParams';
import { getRecommendedActionsContent } from './helper';

const DiagnoseDashboardLayout = ({ children, location, summaryPending, getDiagnoseSummary, setRegionsList, selectedMetrics, selectedFilters, setWebsitePropertiesListFilters, setAvailableMetrics, setMetricsList, regionsList, propertiesList }) => {
    const accountState = useSelector((state) => state.accountState);
    const currentUser = accountState?.getIn(['userDetails', 'value']);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoadingFiltersData, setLoadingFiltersData] = useState(false);
    const searchParams = useSearchParams();
    const [modal, contextHolder] = Modal.useModal();
    const metrics = useMemo(() => {
        const path = location.pathname.split('diagnose_dashboard')[1];

        if (!path) {
            return selectedMetrics;
        }
        return {
            '/non_disclosed_vendors_observed': ['Non disclosed vendors observed'],
            '/vendors_triggered_prior_to_consent': ['Vendors triggered prior to consent'],
            '/disclosed_vendors': ['Disclosed Vendors'],
            '/possible_fingerprinting': ['Possible Fingerprinting'],
            '/data_leaving_the_EEA': ['Data leaving the EEA'],
            '/cookies_with_long_lifespans': ['Cookies with long lifespans'],
            '/all_cookies_found': ['All Cookies Found'],
            '/vendors_after_opt_out': ['Vendors after opt out'],
            '/tracking_tech_detected': ['Tracking Tech'],
        }[path];
    }, [location.pathname, selectedMetrics]);

    // Apply Local Storage Data for same AccountId
    const storedFilters = useMemo(() => {
        const defaultDatesToApply = [moment().subtract(1, 'week'), moment()];
        const filters = {
            properties: [],
            dates: defaultDatesToApply,
            regions: [],
            benchmarks: [],
        };
        if (localStorage.getItem('spAccountId') !== null && localStorage.getItem('spAccountId') === currentUser.accountId.toString()) {
            filters.properties = getFromLocalStorage('diagnoseSelectedProperties', []);
            filters.dates = getFromLocalStorage('diagnoseSelectedDates', defaultDatesToApply);
            filters.benchmarks = getFromLocalStorage('diagnoseSelectedBenchmark', []);

            const storedRegions = getFromLocalStorage('diagnoseSelectedRegions', []);
            const isRegionWithZone = storedRegions.some((storedRegion) => storedRegion.includes(':'));
            filters.regions = isRegionWithZone ? storedRegions : [];

            if (!isRegionWithZone) {
                localStorage.setItem('diagnoseSelectedRegions', JSON.stringify([]));
                localStorage.setItem('diagnoseSelectedBenchmark', JSON.stringify([]));
                filters.regions = [];
                filters.benchmarks = [];
            }
        } else {
            localStorage.setItem('diagnoseSelectedProperties', JSON.stringify([]));
            localStorage.setItem('diagnoseSelectedRegions', JSON.stringify([]));
            localStorage.setItem('diagnoseSelectedBenchmark', JSON.stringify([]));
            localStorage.setItem('diagnoseSelectedDates', JSON.stringify(defaultDatesToApply));
            localStorage.setItem('spAccountId', currentUser.accountId);
        }

        return filters;
    }, []);

    const getMetricsAccordingToRegions = useCallback((regionsCheck) => {
        let result = [...metrics];
        if (regionsCheck.every((region) => !region.includes('US:')) && metrics.length > 1) {
            const selectedWithOptOut = metrics.includes('Vendors after opt out');
            if (selectedWithOptOut) {
                const noOptOut = metrics.filter((metric) => metric !== 'Vendors after opt out');
                result = noOptOut;
            }
        }
        return result;
    }, [metrics]);

    const listenToScroll = () => {
        const heightToShowFrom = 110;
        const winScroll = document.querySelector('.spt-content').scrollTop;
        if (winScroll > heightToShowFrom) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.querySelector('.spt-content').addEventListener('scroll', listenToScroll);
        return () => document.querySelector('.spt-content').removeEventListener('scroll', listenToScroll);
    }, []);

    const getFiltersData = async () => {
        setLoadingFiltersData(true);
        const [propertiesData, regionsData] = await Promise.all([getPropertiesData(), getRegionsData()]);
        propertiesData && setWebsitePropertiesListFilters(propertiesData);
        regionsData && setRegionsList(regionsData);
        setLoadingFiltersData(false);
    };

    useEffect(() => {
        const availableMetrics = accountFeaturesToAvailableDiagnoseMetrics(currentUser.accountFeatures, currentUser.allFeatureAccess || currentUser.featureAccess);
        setAvailableMetrics(availableMetrics);
        setMetricsList(availableMetrics);
        if (currentUser.accountFeatures.includes('diagnose_dashboard') && ((currentUser.featureAccess && currentUser.featureAccess.includes('Diagnose Dashboard')) || currentUser.allFeatureAccess)) {
            getFiltersData();
        } else {
            browserHistory.push('/');
        }
    }, [currentUser]);

    const getSummary = (filters, filteredMetrics = null) => {
        getDiagnoseSummary({
            ...filters,
            metrics: filteredMetrics,
        });
    };

    const handleApply = (filters) => {
        const metricsFiltered = getMetricsAccordingToRegions(filters.regions);
        getSummary(filters, metricsFiltered);
    };

    useEffect(() => {
        if (!Object.keys(searchParams).length
            && !summaryPending
            && metrics?.length
            && Object.keys(selectedFilters).length
            && selectedFilters?.websites?.length
            && selectedFilters?.regions?.length
            && selectedFilters?.end
            && selectedFilters?.start) {
            getSummary(selectedFilters, getMetricsAccordingToRegions(selectedFilters.regions));
        }
    }, [metrics]);

    const isMetricNameHighlighted = metrics.length === 1 && location?.query?.metric?.toLowerCase() === metrics[0].toLowerCase();

    const openRecommendationsModal = () => {
        modal.confirm({
            title:"Recommended Actions",
            icon: null,
            content: getRecommendedActionsContent(),
            width: 1000,
            okText: "OK",
            closable: true,
            cancelButtonProps: { style: { display: 'none' } },
        });
    };

    return (
        <div className={'diagnose_container'}>
            <div className="diagnose_dashboard">
            <div className={'diagnose_dashboard-back-and-page-name-block'}>
                {location.pathname !== '/diagnose_dashboard' &&
                    <Link className={classNames('link_back', { small: isVisible })} to={'/diagnose_dashboard'}>
                    <LeftOutlined /> Back to Summary
                    </Link>
                }
                {isVisible && (
                    <div>
                    <Typography.Title className={`diagnose_dashboard-page-name-block ${isMetricNameHighlighted && 'diagnose-notification-highlight highlight'}`}>
                        {location.pathname === '/diagnose_dashboard' ? 'Diagnose Dashboard' : 
                        location.pathname === '/diagnose_dashboard/tracking_tech_detected' ? 'Tracking Tech Detected' : metrics?.[0]}
                    </Typography.Title>
                    </div>
                )}
                </div>

                { 
                location.pathname !== '/diagnose_dashboard' && 
                !location.pathname.includes('/diagnose_dashboard/tracking_tech_detected') &&
                <RecommendationsWindow />
                }

                <div className='diagnose_dasboard-title'>
                    <Typography.Title className={`diagnose_dashboard-page-name-block ${isMetricNameHighlighted && 'diagnose-notification-highlight highlight'}`}>
                        {location.pathname === '/diagnose_dashboard' ? 'Diagnose Dashboard' : 
                        location.pathname === '/diagnose_dashboard/tracking_tech_detected' ? 'Tracking Tech Detected' : metrics?.[0]}
                    </Typography.Title>
                    
                    {location.pathname === '/diagnose_dashboard/tracking_tech_detected' && (
                        <div>
                        <Button type='default' onClick={openRecommendationsModal}>Recommended Actions</Button>
                        <Tooltip title='Discover tips on actions to take while or after analyzing the provided metric data'>
                            <Button type="link" icon={<InfoCircleOutlined size={"small"}/>}/>
                        </Tooltip>   
                        </div>
                    )}
                </div>
                <Spin spinning={isLoadingFiltersData && !!Object.keys(searchParams).length} size="large">
                { !!Object.keys(regionsList)?.length && !!propertiesList?.length || !Object.keys(searchParams).length ? (
                    <>
                        <div className="diagnose_dashboard-main-filters-hint">Use the filters below to narrow down the selection:</div>
                        <div className="diagnose_dashboard-main-filters-block">
                                <DashboardFilters 
                                    regionsList={regionsList}
                                    propertiesList={propertiesList}
                                    selectedFilters={selectedFilters}
                                    storedFilters={storedFilters}
                                    searchParams={searchParams}
                                    onApply={handleApply}
                                />
                            <Divider className={'filters_divider'} />
                        </div>
                    </>
                ) : null }
                <div className='diagnose-dashboard-content-wrapper'>
                {children && React.cloneElement(children, {
                    location,
                })}
                </div>
                {contextHolder}
                </Spin>
            </div>
        </div>
    );
};

const mapStateToProps = function (store) {
    return {
        summaryDiagnose: store.diagnoseDashboardState.getIn(['summaryDiagnose', 'value']),
        summaryPending: store.diagnoseDashboardState.get('summaryPending'),
        selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
        selectedMetrics: store.diagnoseDashboardState.getIn(['selectedMetrics', 'value']),
        regionsList: store.diagnoseDashboardState.getIn(['regionsList', 'value']),
        propertiesList: store.diagnoseDashboardState.getIn(['websitePropertiesList', 'value']),
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getDiagnoseSummary,
        setRegionsList,
        setWebsitePropertiesListFilters,
        setAvailableMetrics,
        setMetricsList,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    areStatePropsEqual: (next, prev) => _.isEqual(next, prev),
})(DiagnoseDashboardLayout);
