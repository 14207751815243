import React, { useState } from 'react';
import { Select, Button, Collapse, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import CustomFields from '../CustomFields';
import PrivacyManagerConfig from './PrivacyManagerConfig';
import Style from '../Style';

const { Panel } = Collapse;

export const choiceTypeMap = new Map([
  [11, 'accept all'],
  [13, 'reject all'],
  [12, 'show privacy manager'],
  [15, 'dismiss']
]);

const ChoiceOptions = ({
  accountId,
  siteId,
  choiceOptions,
  addItem,
  updateItem,
  removeItem,
}) => {
  const [choiceOptionToAdd, setChoiceOptionToAdd] = useState(11);
  return (
    <div>
      <h3>choice options</h3>
      <div className="choice-options-adder">
        <Select
          id="native-msg-choice-options-adder"
          data-testid="native-msg-choice-options-adder"
          style={{ width: '80%', textTransform: 'capitalize' }}
          value={choiceOptionToAdd}
          onSelect={c => setChoiceOptionToAdd(c)}
        >
          {[...choiceTypeMap].map(([value, text], i) => (
            <Select.Option
              key={i}
              style={{ textTransform: 'capitalize' }}
              id={`native-msg-choice-${text}`}
              value={value}
            >
              {text.charAt(0).toUpperCase() + text.slice(1)}
            </Select.Option>
          ))}
        </Select>
        <Button
          id="native-msg-add-item"
          type="primary"
          onClick={() => addItem(['actions'], generateNewOption(choiceOptionToAdd))}
        >
          Add
        </Button>
      </div>
      <div className="choice-options-container">
        {(choiceOptions.length === 0)
          ? (
            <div>
              <i>No choice options have been created</i>
            </div>
          )
          : (
            <div className="collapse-container">
              {choiceOptions.map(({
                choiceType,
                text,
                style,
                customFields,
                url
              }, i) => (
                  <Collapse
                    key={i}
                    id={`native-msg-choice-collapse-${text}`}
                    accordion="false">
                    <Panel
                      header={choiceTypeMap.get(choiceType).toUpperCase()}
                      forceRender={choiceType === 12}
                    >
                      <div className={`poop`}>
                        {choiceType === 12 && (
                          <PrivacyManagerConfig
                            siteId={siteId}
                            accountId={accountId}
                            url={url}
                            updatePmValue={(url) => updateItem(['actions', i, 'url'], url)}
                          />
                        )}
                        <div className="text-input-container">
                          <label htmlFor="text-input">
                            text
                        </label>
                          <Input
                            id="native-msg-choice-opt-input"
                            placeholder={`Add choice option text`}
                            value={text}
                            onChange={({ target: { value } }) => updateItem(['actions', i, 'text'], value)}
                          />
                        </div>
                        <Style
                          updateStyle={(newStyles) => updateItem(['actions', i, 'style'], { ...style, ...newStyles })}
                          styles={style}
                        />
                        <div className="custom-fields-container">
                          <CustomFields
                            titleSize="sm"
                            fields={customFields}
                            addField={(value) => addItem(['actions', i, 'customFields'], value)}
                            removeField={(fieldIndex) => removeItem(['actions', i, 'customFields'], fieldIndex)}
                            updateField={(fieldIndex, key, value) => updateItem(['actions', i, 'customFields', fieldIndex, key], value)}
                          />
                        </div>
                      </div>
                    </Panel>
                    <div className="trash-icon-container">
                      <FontAwesomeIcon
                        id="native-msg-delete-choice-btn"
                        icon={faTrash}
                        size="sm"
                        onClick={() => removeItem(['actions'], i)}
                      />
                    </div>
                  </Collapse>
                )
              )}
            </div>
          )}
      </div>
    </div>
  );
};

function generateNewOption(type) {
  return {
    text: '',
    style: {
      fontFamily: 'Arial',
      fontSize: 16,
      fontWeight: '400',
      color: '#000000',
      backgroundColor: '#ffffff'
    },
    customFields: [],
    choiceType: type,
  };
}

export default ChoiceOptions;