import React from 'react';
import { connect } from 'react-redux';
import SdksSubnav from './SdksSubnav';

class SdksLayout extends React.Component {
  render() {
    return (
      <div id='avocado' className='layout-container sdk-layout'>

        <div className="navbar navbar-default sitesentry-subnav">
          <SdksSubnav id="subnav" />
        </div>

        {this.props.children && React.cloneElement(this.props.children, {
          location: this.props.location
        })}

      </div>
    )
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
  };
};

export default connect(mapStateToProps)(SdksLayout);
