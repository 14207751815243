import PropTypes from 'prop-types';
import React from 'react';
import { List, Map } from 'immutable';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Modal, Tabs, Button, notification, Input, Checkbox } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { PurposeV2 as Purpose, VendorList } from '../../../../../records/vendor_list_records';
import CustomStacksTab from './CustomStacksTab';

const TabPane = Tabs.TabPane;

export default class ManageStacksModal extends React.Component {
  static propTypes = {
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)).isRequired,
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    closeModal: PropTypes.func.isRequired,
    handleApply: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    validateHandler: PropTypes.func.isRequired,
  }

  state = {
    vl: this.props.vendorList,
    customStackName: '',
    customStackDescription: '',
    customPurposeName: '',
    selectedCustomPurposeIds: List([]),
    customPurposesCreated: Map({}),
    existingPurposesToAddToNewStack: List([]),
  }

  handleCancelModalChanges = () => {
    this.setState({ vl: this.props.vendorList });
    this.props.closeModal();
  }

  handleOnOk = () => {
    this.props.handleApply(this.state.vl);
    this.props.closeModal();
  }

  updateCategoriesLocally = (categories) => {
    this.setState((prevState) => ({
      ...prevState,
      vl: prevState.vl.set("categories", categories),
    }));
  };

  removeStack = (stack) => {
    this.updateCategoriesLocally(this.state.vl.categories
      .filterNot(c => c.name === stack.name)
      .concat(stack.categories.filterNot(c => this.props.iabPurposesNotInThelist.map(p => p.iabPurposeRef).includes(c.iabPurposeRef))));
   
    if((stack.iabId || this.props.iabStacks.find(s => s.iabStackRef == stack.iabStackRef).iabId) === 1) {
      this.setState((prevState) => ({
        ...prevState,
        vl: prevState.vl.set(
          "iabSpecialFeatures",
          List([])
        ),
      }));
    }
  }

  checkIfPurposesExistInOtherStacks = (newStack, showErrors = true) => {
    let intersections = new Map({});
    newStack.categories.forEach(newStackPurpose => {

      this.state.vl.categories.filter(c => c.type === 'IAB_STACK' || c.type === 'CUSTOM_STACK').forEach(existing => {
        if (!intersections.get(existing.name)) {
          intersections = intersections.set(existing.name, new List([]));
        }
        existing.categories.forEach(existingPurpose => {
          if (newStackPurpose.iabPurposeRef === existingPurpose.iabPurposeRef) {
            intersections = intersections.update(existing.name, (purposes) => purposes.push(existingPurpose));
          }
        });
      });
    });

    intersections = intersections.filter(i => i.size > 0);

    if (intersections.size) {
      let errorMessage = `Cannot add ${newStack.name} stack. Purpose `;
      intersections.forEach((purposes, stackName) => {
        purposes.forEach((purpose, idx) => {
          errorMessage += `${purpose.name}`;
          if (purposes.size > 1) {
            if (idx === purposes.size - 2) {
              errorMessage += ' and ';
            } else if (idx < purposes.size - 2) {
              errorMessage += ', ';
            }
          }
        });
        errorMessage = errorMessage += ` already exist in ${stackName} stack.`;
        if (showErrors) {
          notification.error({
            message: errorMessage,
            duration: null,
          });
        }
      });
      return true;
    } else {
      return false;
    }
  }

  addStack = (stack) => {
    if (this.checkIfPurposesExistInOtherStacks(stack)) {
      return;
    }

    const filteredCategories = this.state.vl.categories
      .filterNot(existingPurpose => stack.categories.map(c => c.iabPurposeRef).includes(existingPurpose.iabPurposeRef));

    const existingCategoriesForStack = this.state.vl.categories
      .filter(existingPurpose => stack.categories.map(c => c.iabPurposeRef).includes(existingPurpose.iabPurposeRef));

    const allCategoriesForStack = stack.categories.map(stackCategory => {
      if (existingCategoriesForStack.map(existingCategory => existingCategory.iabPurposeRef).includes(stackCategory.iabPurposeRef)) {
        return existingCategoriesForStack.find(s => s.iabPurposeRef === stackCategory.iabPurposeRef);
      } else if(this.props.iabPurposesNotInThelist.map(iabPurpose => iabPurpose.iabPurposeRef).includes(stackCategory.iabPurposeRef)) {
        let removedPurpose = this.props.iabPurposesNotInThelist.find(s=>s.iabPurposeRef === stackCategory.iabPurposeRef)
        removedPurpose = removedPurpose.id === removedPurpose.iabPurposeRef ? removedPurpose.set("id", null) : removedPurpose;
        return removedPurpose;
      } else {
        return stackCategory;
      }
    });

    const lastStackIndex = this.state.vl.categories.findLastIndex(c => c.type === 'IAB_STACK');
    const categoriesWithNewStack = filteredCategories.insert(lastStackIndex + 1, stack.set('categories', allCategoriesForStack));
    if (stack.iabId === 1) {
      this.setState((prevState) => ({
        ...prevState,
        vl: prevState.vl.set(
          "iabSpecialFeatures",
          this.props.iabSpecialFeatures.map((feature) => feature.id)
        ),
      }));
    }
    this.updateCategoriesLocally(categoriesWithNewStack);
  }

  createCustomPurpose = (stackIdx) => {
    const newPurpose = new Purpose({ name: this.state.customPurposeName, type: 'CUSTOM' });
    this.setState({
      customPurposesCreated: this.state.customPurposesCreated.update(p => p.set(stackIdx, p.push(newPurpose))),
      customPurposeName: '',
    });
  }

  render() {

    const addedIabStacks = this.state.vl.categories.filter(c => c.type === 'IAB_STACK');
    const addedIabStacksToDisplay = addedIabStacks.size
      ? addedIabStacks.map(stack => {
        return (
          <div>
            <div className='stack-container'>
              <p>{stack.name}</p>
              <MinusCircleOutlined onClick={() => this.removeStack(stack)} />
            </div>
            <div className='purposes-container'>
              {
                stack.categories.map(c => {
                  return <p className='purpose'>{c.name}</p>
                })
              }
            </div>
          </div>
        );
      })
      : <p className='no-iab-stacks-added'>No IAB stacks are added to this vendor list</p>

    const iabStacksWitoutAdded = this.props.iabStacks.filterNot(s => this.state.vl.categories.map(c => c.iabStackRef).includes(s.iabStackRef));
    const iabStacksToAdd = iabStacksWitoutAdded.filterNot(s => this.checkIfPurposesExistInOtherStacks(s, false));
    const iabStacksWithCollisions = iabStacksWitoutAdded.filterNot(stack => iabStacksToAdd.map(s => s.iabStackRef).includes(stack.iabStackRef));

    const iabStacksToAddToDisplay = iabStacksToAdd.size
      ? <div className='iab-stacks-not-selected'>
        {
          iabStacksToAdd.map(stack => {
            return (
              <div>
                <div className='stack-container'>
                  <p>{stack.name}</p>
                  <PlusCircleOutlined onClick={() => this.addStack(stack)} />
                </div>
                <div className='purposes-container'>
                  {
                    stack.categories.map(c => {
                      return <p className='purpose'>{c.name}</p>
                    })
                  }
                </div>
              </div>
            );
          })
        }
      </div>
      : <p className='no-iab-stacks-added'>No IAB stacks without IAB purposes already included in stacks in your list</p>

    const iabStacksWithCollisionsToDisplay = iabStacksWithCollisions.size
      ? <div className='iab-stacks-not-selected'>
        {
          iabStacksWithCollisions.map(stack => {
            return (
              <div>
                <div className='stack-container'>
                  <p>{stack.name}</p>
                  <Button className='colliding-purposes' onClick={() => this.addStack(stack)}>
                    See colliding purposes
                  </Button>
                </div>
                <div className='purposes-container'>
                  {
                    stack.categories.map(c => {
                      return <p className='purpose'>{c.name}</p>
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      : <p className='no-iab-stacks-added'>No IAB stacks with IAB purposes already included in stacks in your list</p>

    return (
      <Modal
        className='manage-stacks'
        destroyOnClose
        visible={this.props.visible}
        closable={false}
        footer={[
          <Button key="back" onClick={this.handleCancelModalChanges}>Cancel</Button>,
          <Button key="submit" type="primary" onClick={this.handleOnOk}>Apply changes</Button>,
        ]}
      >
        <Tabs defaultActiveKey={this.props.filterElement("1", "2")}>
          {this.props.filterElement(

            <TabPane tab="IAB Stacks" key="1">
              <div className='title'>IAB Stacks in your list</div>
              <div>
                {addedIabStacksToDisplay}

                <div className='title not-selected'>IAB stacks that can be added to your list (no overlapping purposes with stacks in your list)</div>
                {iabStacksToAddToDisplay}

                <div className='title not-selected'>IAB stacks that cannot be added to your list because one or more IAB purposes already exist in IAB stacks in your list</div>
                {iabStacksWithCollisionsToDisplay}
              </div>
            </TabPane>
          )}
          <TabPane className="custom-stacks-panel" tab="Custom Stacks" key="2">
            <CustomStacksTab
              vendorList={this.state.vl}
              updateCategoriesLocally={this.updateCategoriesLocally}
              className='custom-stacks-tab'
              iabPurposes={this.props.iabPurposes}
              iabPurposesNotInThelist={this.props.iabPurposesNotInThelist}
              isNonIab={this.props.isNonIab}
              validateHandler={this.props.validateHandler}
            />
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}
