import React, { Component } from 'react';
import { Button } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTrashAlt, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

// const placeholder = document.createElement("div");
// placeholder.className = "placeholder";

let dragged, over;

class Children extends Component {
  state = {
    dragging: false
  }
  dragStart(e) {
    this.setState({ dragging: true });
    dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', dragged);
  }
  dragEnd(e) {
    dragged.style.display = 'flex';
    if (over) {
      const index = Number(dragged.dataset.id);
      const newIndex = Number(over.dataset.id);
      const components = this.props.components || [];
      if (newIndex < components.length) this.props.moveIntoChild(index, newIndex);
      over.id = '';
    }
    this.setState({ dragging: false });
  }
  dragOver(e) {
    e.preventDefault();
    dragged.style.display = "none";
    if (e.target.className !== 'row-child dragging') return;
    over = e.target;
    e.target.id = 'drag-id';
  }
  dragLeave(e) {
    e.preventDefault();
    e.target.id = '';
    over = undefined;
  }
  render() {
    const components = this.props.components || [];
    const divClass = this.state.dragging ? 'row-child dragging' : 'row-child';

    const childList = components.map((component, index) => (
      <div
        className={divClass}
        data-id={index}
        key={index}
        draggable="true"
        onDragLeave={this.dragLeave}
        onDragOver={this.dragOver}
        onDragEnd={this.dragEnd.bind(this)}
        onDragStart={this.dragStart.bind(this)}
        onMouseOver={()=> this.props.highlight(index)}
        onMouseOut={()=> this.props.highlight(null)}
      >
        <div className="child-move">
          <FontAwesomeIcon className={index === 0 ? 'disabled' : ''} icon={faArrowUp} onClick={() => this.props.moveChild(index, index - 1)} />
          <FontAwesomeIcon className={index === (components.length - 1) ? 'disabled' : ''} icon={faArrowDown} onClick={() => this.props.moveChild(index, index + 1)} />
        </div>
        <div className="child-name" onClick={() => this.props.goToChild(index)}><i className="fas fa-box-open" />{component.name}<span>{component.type}</span></div>
        <div className="child-actions">
          <FontAwesomeIcon icon={faClone} onClick={() => this.props.cloneComponent(index)} />
          <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.props.removeComponent(index)} />
        </div>
      </div>
    ));
    const noChildren = ['Text'];
    const canChildren = noChildren.indexOf(this.props.current.type) < 0;

    const rows = components.length ? childList : <div className="no-children">{canChildren ? 'None' : 'This component cannot have children'}</div>;
    let addComponent;
    if (canChildren) {
      addComponent = (
        <div className="add-component-button">
          <Button type="primary" onClick={this.props.showModal}>+ Add Component</Button>
        </div>
      );
    }
    return (
      <div className="children">
        <div id="folder">
          <div className="rows" draggable="false">
            {rows}
          </div>
        </div>
        {addComponent}
      </div>
    );
  }
}

export default Children;
