import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button } from 'antd';
import CreateButton from '../../common/CreateButton.js.jsx';
import SiteList from './SiteList.js.jsx';
import {
  createSite,
  createSiteGroup,
  getSiteGroups,
  updateSiteGroup,
  getAllSites,
  deleteSiteGroup,
  deleteSite,
  dismissError,
} from '../../../actions/site_actions';
import CreateSiteGroupModal from './CreateSiteGroupModal';
import { User } from '../../../records/account_records';
import { SiteGroup, Site } from '../../../records/site_records';
import CreateSiteModal from './CreateSiteModal';
import { Filter } from '../../Filter.js';
import CantDeleteModal from './CantDeleteModal.jsx';

class SitesPage extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    createSite: PropTypes.func.isRequired,
    pending: PropTypes.bool.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      isMasq: PropTypes.bool.isRequired,
    }).isRequired,
    createSiteGroup: PropTypes.func.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
    updateSiteGroup: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    deleteSiteGroup: PropTypes.func.isRequired,
    deleteSite: PropTypes.func.isRequired,
    dismissError: PropTypes.func.isRequired,
  }

  state = {
    displayCreateSiteModal: false,
    displayCreateSiteGroupModal: false,
    siteGroupToEdit: null,
    filterKey: '',
    sgsContainingSite: [],
  };

  componentDidMount() {
    debugger
    this.props.getAllSites();
  }

  componentDidUpdate(prevProps) {
    debugger
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getSiteGroups(this.props.currentUser.accountId);
    }
  }

  componentWillUnmount() {
    this.props.dismissError();
  }

  handleAddSite = (name, type) => {
    this.props.createSite(name, type);
  };

  setSiteGroupToEdit = (id) => {
    this.setState({ siteGroupToEdit: id });
  }

  toggleAddSiteForm = () => {
    this.setState({ displayCreateSiteModal: !this.state.displayCreateSiteModal });
  };

  toggleCreateSiteGroupModal = () => {
    this.setState({ displayCreateSiteGroupModal: !this.state.displayCreateSiteGroupModal });
  };

  getUserSiteGroups = () => {
    if (this.props.currentUser.siteAccess) {
      return this.props.siteGroups.filter(sg => {
        return sg.siteIds.every(id => {
          return this.props.currentUser.siteAccess.includes(id);
        });
      });
    } else {
      return this.props.siteGroups;
    }
  }

  siteFilterHandler = (key) => {
    this.setState({ filterKey: key });
  }

  deleteSite = (siteId, ...rest) => {
    const siteGroups = this.props.siteGroups.toJS();
    const sgsContainingSite = [];
    for (let sg of siteGroups) {
      for (let childSiteId of sg.siteIds) {
        if (childSiteId === siteId) {
          sgsContainingSite.push(sg.name);
        };
      };
    };

    sgsContainingSite.length
      ? this.setState({ sgsContainingSite })
      : this.props.deleteSite(siteId, ...rest);
  }

  render() {
    var createForm;
    var containerClass = "redesign sites-container";

    let addSiteButton;
    if (this.props.currentUser.isAdmin) {
      addSiteButton = <CreateButton onClick={this.toggleAddSiteForm} >+ Create a property</CreateButton>;
    }

    let modal;
    if (this.state.displayCreateSiteGroupModal || this.state.siteGroupToEdit) {
      modal = (
        <CreateSiteGroupModal
          visible={this.state.displayCreateSiteGroupModal || this.state.siteGroupToEdit}
          closeModal={this.state.displayCreateSiteGroupModal ? this.toggleCreateSiteGroupModal : this.setSiteGroupToEdit.bind(null, null)}
          sites={this.props.sites}
          createOrUpdateSiteGroup={this.state.displayCreateSiteGroupModal ? this.props.createSiteGroup : this.props.updateSiteGroup}
          accountId={this.props.currentUser.accountId}
          siteGroup={this.state.siteGroupToEdit || new SiteGroup({})}
          siteAccess={this.props.currentUser.siteAccess}
        />
      );
    }


    if (this.state.displayCreateSiteModal) {
      modal = (
        <CreateSiteModal
          visible={this.state.displayCreateSiteModal}
          closeModal={this.toggleAddSiteForm}
          sites={this.props.sites}
          createOrUpdateSite={this.handleAddSite}
          accountId={this.props.currentUser.accountId}
          siteAccess={this.props.currentUser.siteAccess}
        />
      );
    }

    let sitesHeader;
    const userSiteGroups = this.getUserSiteGroups();
    let siteGroups, filter;
    sitesHeader = <div>
      <p className='title'>Properties</p>
      <Filter className='filter-icon' filterBy={(key) => this.siteFilterHandler(key)} />
    </div>;

    if (userSiteGroups && userSiteGroups.size) {
      siteGroups = (
        <React.Fragment>
          <p className='title'>Grouped Properties</p>
          <SiteList
            isAdmin={this.props.currentUser.isAdmin}
            sites={userSiteGroups}
            delete={this.props.deleteSiteGroup.bind(this, this.props.currentUser.accountId)}
            siteGroups
            setSiteGroupToEdit={this.setSiteGroupToEdit}
          />
        </React.Fragment>
      )
    }

    var errorMessage;
    if (this.props.error) {
      errorMessage = <p className="error">{this.props.error.message.slice(0, 1).toUpperCase() + this.props.error.message.slice(1)}</p>;
    }

    let sites = this.props.sites;
    sites = sites.filterNot(s => {
      return this.props.siteGroups.map(sg => sg.siteId).includes(s.id);
    });
    if (this.props.currentUser.siteAccess) {
      sites = sites.filter(s => this.props.currentUser.siteAccess.includes(s.id));
    }
    const addSiteGroupButton = (
      <div className="top-button-container">
        <Button onClick={this.toggleCreateSiteGroupModal} className='create-site-group'>Create a property group</Button>
      </div>
    );

    return (
      <div className={containerClass}>
        {addSiteGroupButton}
        {addSiteButton}
        {createForm}
        {modal}
        {errorMessage}
        {siteGroups}
        {sitesHeader}
        asdfasdfasdfasdf
        <SiteList
          isAdmin={this.props.currentUser.isAdmin}
          sites={sites}
          delete={this.deleteSite}
          filterKey={this.state.filterKey}
        />
        <CantDeleteModal
          siteGroupNames={this.state.sgsContainingSite}
          closeModal={() => this.setState({ sgsContainingSite: [] })}
        />
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    pending: store.siteState.getIn(['sites', 'pending']),
    error: store.siteState.getIn(['sites', 'error']),
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
  };
};

export default connect(mapStateToProps, {
  createSite,
  createSiteGroup,
  getSiteGroups,
  updateSiteGroup,
  getAllSites,
  deleteSiteGroup,
  deleteSite,
  dismissError,
})(SitesPage);