import React from 'react';
import { Select } from '../../../../../../../styleguide';

const { Option } = Select;

const ActionOptionsIOS = ({
  value,
  settingsTitle,
  settingKey,
  updateSetting
}) => {
  return (
    <div className="four-options">
      <h4>{settingsTitle}</h4>
      <div className="option-inputs">
        <Select value={value} style={{ width: '100%' }} onChange={(value) => updateSetting(settingKey, value)}>
          <Option value="SHOW_ATT_MSG">Show ATT Message</Option>
          <Option value="CANCEL">Cancel</Option>
        </Select>
      </div>
    </div>
  );
};

export default ActionOptionsIOS;