import React from "react";
import { Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import Logo from "../../assets/images/spt-logo.png";

const SubmissionMessage = () => {
  return (
    <div id="free-scan-container">
      <div id="free-scan-form-container" className="success">
        <div className="success-flex">
          <div className="spl-logo">
            <img src={Logo} alt="" />
          </div>
          <div className="success-alert">
            <Space size="middle">
              <span className="success-text">Successfully Submitted</span>
              <CheckOutlined />
            </Space>
          </div>
          <div className="email-text">
            You should receive an email with your scan results shortly.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionMessage;
