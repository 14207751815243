import React, { Component } from 'react';
import { Popover, Button, Select } from "antd";
import { PRIVACY_MANAGER_URL } from '../../../../../../../../constants.js';

const { Option } = Select;

let scenarioConsentUrl;
let scenarioCcpaUrl;
let ScenarioUsnatUrl;
if (process.env.REACT_APP_PUB_PORTAL_ENV === 'prod') {
  scenarioConsentUrl = 'https://sourcepoint.mgr.consensu.org/tcfv2';
  scenarioCcpaUrl = 'https://ccpa-service.sp-prod.net';
  ScenarioUsnatUrl = 'https://cdn.privacy-mgmt.com/usnat';
} else {
  scenarioConsentUrl = 'https://cmpv2.sp-stage.net/tcfv2';
  scenarioCcpaUrl = 'https://ccpa.sp-stage.net';
  ScenarioUsnatUrl = 'https://usp.sp-stage.net';
}
export default class LinkPM extends Component {
  state = {
    show: false,
    selected: null,
    pm: null
  }
  promiseInfo = {}
  show = async () => {
    return new Promise((resolve, reject) => {
      this.promiseInfo = { resolve, reject };
      this.setState({ show: true, selected: null, pm: null });
    });
  }
  hide = () => this.setState({ show: false })
  changeValue = (value) => {
    const pm = {};
    pm['privacy_manager_iframe_url'] = value;
    pm['consent_origin'] = scenarioConsentUrl;
    pm['button_text'] = JSON.stringify(Date.now());
    this.setState({ selected: value || null, pm: value ? pm : null });
  }
  selectValue = () => {
    const { resolve, reject } = this.promiseInfo;
    this.hide();
    if (this.state.pm) {
      this.props.updateActions(this.state.pm);
      resolve(this.state.pm);
    } else {
      reject();
    }
  }
  changeDefaultTab = (tab, url) => {
    const newUrl = url.replace('&default_tab=vendors', '');
    if (tab === 'vendors') {
      this.changeValue(`${newUrl}&default_tab=vendors`);
    } else {
      this.changeValue(newUrl);
    }
  }
  generateUrl = (option) => `${option.message_url}/privacy-manager/index.html?message_id=${option.id}`;
  render() {
    const { show } = this.state;
    const { resolve, reject } = this.promiseInfo;
    const selectedValue = this.state.selected || '';
    const menu = (
      <div style={{ padding: '8px 0', width: '200px' }}>
        <h5>Privacy Manager</h5>
        <Select value={selectedValue.replace('&default_tab=vendors', '')} style={{ width: '100%' }} onChange={this.changeValue}>
          <Option value={null}><i>None</i></Option>
          {this.props.options.map((opt) => (
            <Option key={opt.id} value={this.generateUrl(opt)}>{opt.description}</Option>
          ))}
        </Select>
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Button onClick={() => { this.hide(); reject(); }}>Cancel</Button>
          <Button style={{ marginLeft: '5px' }} type="primary" onClick={this.selectValue}>OK</Button>
        </div>
      </div>
    );
    return (
      <button style={{ width: 'auto' }} className="ql-linkPrivacyManager">
        <Popover open={show} placement="bottom" title="Select Privacy Manager:" content={menu} trigger="click">
          <span>PM</span>
        </Popover>
      </button>
    );
  }
}
