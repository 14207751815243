import React, { useState } from 'react';
import { DownOutlined,UpOutlined} from "@ant-design/icons"

const CollapsiblePanel = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const togglePanel = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="collapsible-panel">
      <div className="collapsible-panel-header" onClick={togglePanel}>
        <h3>{title}</h3>
        <span>{isExpanded ? <UpOutlined /> : <DownOutlined />}</span>
      </div>
      {isExpanded && (
        <div className="collapsible-panel-content">
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapsiblePanel;
