import React from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Popover } from "antd";

export default class CustomPopover extends React.Component {
  render() {
    return (
      <Popover
        overlayClassName={this.props.style || "instructional-popover"}
        content={this.props.tooltipContent}
        trigger="hover" // can be hover, click, focus
        placement="bottom"
        title={this.props.title || null}
      >
        <LegacyIcon
          type={this.props.icon || "info-circle"}
          theme={this.props.theme || 'outlined'}
          style={{
            fontSize: "14px",
            marginLeft: "1%",
            color: this.props.iconColor || "#899AB4",
          }}
        />
      </Popover>
    );
  }
}
