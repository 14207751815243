import React, { useState, useEffect } from 'react'
import { parse } from 'json2csv';
import _ from 'lodash';
import { Button, Select } from "../../../../../../styleguide"
import { DownloadOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons"
import CSVLink from "../../../../../common/CSVLink.js";
import { getVendorTypeIcon } from "../helper";
import { Tag, Upload } from 'antd';

const { Dragger } = Upload;

export default function VendorCookieUpload(props) {

  const beforeUpload = async (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = e => {
      let csv = e.target.result;
      const rows = csv.slice(csv.indexOf('\n')+1).split(/\r\n|\n/);
      const filteredVendorCookies = rows.map(row => (row.search("\t")!==-1)?row.split('\t'):row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)).filter(([vname,cname,domain,duration])=> vname!=='')
      const santizedCookieRows = filteredVendorCookies.map(arr => arr.map(item => item.replaceAll('"','')))
      props.addVendorCookies(santizedCookieRows);
    };
    // Prevent upload
    return false;
  }; 
  const uploadParams = {
    name: 'file',
    accept: '.csv',
    maxCount: 1,
    openFileDialogOnClick:true,
    beforeUpload:beforeUpload
  };
  const [value, setValue] = useState([]);
  const [selectedVendor, setSelectedVendors] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [uploadProps, setUploadProps] = useState(uploadParams)
  const [showSuccessMessage, setShowSuccessMsg] = useState('hidden')
      

    useEffect(() => {
        setVendorsData()   
    }, [selectedVendor])

    const handleClick = (e) => {
      props.showTemplateDownloader(true)
      e.stopPropagation()
    } 

  const onSearch = (value) => {
  };

  const onRemove = (v) => {
    const slVendors = selectedVendor.filter(item => item!==v);
    setSelectedVendors(slVendors)
  }

  const setVendorsData = () => {
    let sampleData = [];
    let selVendors = [...new Set(selectedVendor)];
    selVendors.map((vendor, idx) => {
      const durations = ['days','years','months','minutes','hours','session'];
      let duration_type = _.sample(durations);
      sampleData.push({
        "Vendor Name": `${vendor.name}`,"Cookie Name":`cookie_${idx+1}`, "Domain":"www.domain.com","Purposes" : `${props.selectedVendorCategoryMap[vendor.id].join("; ")}`, "Duration":`${duration_type == 'session' ? '' : idx+1}`, "Duration Type":`${duration_type}`
      })
    })
    setSampleData(sampleData);
  }

  const cookieUploadData = sampleData.length > 0 ? sampleData :[];

  const onChange = (newValue) => {
    setShowSuccessMsg('hidden')
    const vendor = props?.vendors.find(v => v.name === newValue[0])
    setSelectedVendors((prevState) => [...prevState,vendor])
    setValue([]);
  }

  const handleClose = (e) => {
    props.showTemplateDownloader(false)
    setShowSuccessMsg('hidden')
  }
  
  const selVendors = [...new Set(selectedVendor)]
 
  return (
    <Dragger className='upload-div' {...uploadProps}>
        <div>
        <p className='drop-files'>Drop file(s) here or Click </p>
        <span>Download a <span className="download-link" onClick={(e) => handleClick(e)}>Sample CSV Template</span> to see an example of the format required. </span>
        {props.viewTemplateDownloader && (<div onClick={(e) => e.stopPropagation()} className='csv-template-downloader'>
          <CloseOutlined className="close-icon" onClick={(e) => handleClose(e)} />
          <label>CSV Template Downloader</label>
          <p className="note">Select all the vendors that you would like to import cookies for before downloading the CSV Template.</p>
          <div className="select-vendor">
          <div className="sel-tag-container">
          {selVendors?.map((v) => {
          const icon = getVendorTypeIcon(v);
          return <Tag closable={true} onClose={() => onRemove(v)} className="tag-style">{icon} {v.name}</Tag>
          })}
          <Select
              mode="multiple"
              showSearch
              value={value}
              dropdownClassName='selected-vendors-options'
              placeholder="Type vendor name here.."
              optionFilterProp="children"
              onChange={(newValue) => onChange(newValue)}
              onSearch={onSearch}
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
             >
            {props?.vendors.map((v,i) => {
              const icon = getVendorTypeIcon(v);
                return(
                <Option value={v?.name}>{icon} {v?.name}</Option>
                )
            })}
          </Select>
          </div>
          <div className="csv-container">
          <Button disabled={selectedVendor.length<1} type="primary" size="small">
          <CSVLink
          separator={","}
          style={{color:'white'}}
          data={cookieUploadData}
          enclosingCharacter={`"`}
          filename={'Bulk_Cookie_Upload.csv'}
          onClick={(e) => {
            const uploadPropsChanged = {...uploadProps,openFileDialogOnClick:true}
            setUploadProps(uploadPropsChanged)
            setShowSuccessMsg('visible')
            e.stopPropagation()
          }}
          ><DownloadOutlined />Download CSV Template</CSVLink></Button>
           <div className="success-alert" style={{visibility : showSuccessMessage}}>
          <CheckOutlined />
          <span className="success-text">Successfully Downloaded</span>
          </div> 
          </div>
          </div>
        </div>)}
        </div>
    </Dragger> 
  )
}
