import 'whatwg-fetch';
import { fromJS, Range, List } from 'immutable';
import moment from 'moment';
import { checkStatus } from './helper.js';

import { DashboardRecord } from '../records/dashboard_records.js';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export async function getAndParseDashboardGraphData(data) {
  const formattedData = data.update('start_date', start_date => start_date.format('YYYY-MM-DD'))
    .update('end_date', end_date => end_date.format('YYYY-MM-DD'))
    .toMap()
    .filter(v => List.isList(v) ? v.size : v)
    .toJS();

    Date.prototype.getJulian = function() {
      return (this / 86400000) - (this.getTimezoneOffset() / 1440) + 2440587.5;
    }
    const start_date = new Date(formattedData.start_date.toString());
    const end_date = new Date(formattedData.end_date.toString());
    let endDateTime = new Date(new Date(end_date.toString()).setHours(23, 59, 59));

    endDateTime.setDate(endDateTime.getDate() + 1);
    
    const startTs = start_date.getTime();
    const endTs = endDateTime.getTime();
    let group_by;
    if ((end_date.getJulian() - start_date.getJulian()) <= 7) {
      group_by = 'ymdh';
    } else {
      group_by = 'ymd';
    }
    let queryToAppend = `&groupBy=${group_by}`;
    if (formattedData.client_domain) {
      const domain = formattedData.client_domain.replace(/^\*/, '%');
      queryToAppend += `&domain=${domain}`;
    }
    const adblock_rate_series = await fetch(`${permissionsSvsBase}/rtdp/pageviews/adblock_rate_series?startDate=${startTs}&endDate=${endTs}${queryToAppend}`, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
          return resp.data;
        })
    
    const rates = {
      desktop_breakdown: {},
      mobile_breakdown: {},
      desktop_pageviews: 0,
      adblock_desktop_pageviews: 0,
      adblock_desktop_rate: 0,
      mobile_pageviews: 0,
      adblock_mobile_pageviews: 0,
      adblock_mobile_rate: 0
    }

    Number.prototype.toFixedNumber = function(digits, base){
      var pow = Math.pow(base||10, digits);
      return Math.round(this*pow) / pow;
    }
    
    adblock_rate_series.forEach(point => {
      const timestamp = point[0]
      const desktop_pageviews = point[1]
      const adblock_desktop_pageviews = point[2]
      const mobile_pageviews = point[3]
      const adblock_mobile_pageviews = point[4]
      rates.desktop_pageviews += desktop_pageviews
      rates.adblock_desktop_pageviews += adblock_desktop_pageviews
      rates.mobile_pageviews += mobile_pageviews
      rates.adblock_mobile_pageviews += adblock_mobile_pageviews
      rates.desktop_breakdown[timestamp] = desktop_pageviews > 0 ? parseFloat(adblock_desktop_pageviews / desktop_pageviews).toFixedNumber(4) : null;
      rates.mobile_breakdown[timestamp] = mobile_pageviews > 0 ? parseFloat(adblock_mobile_pageviews / mobile_pageviews).toFixedNumber(4) : null;
    });

    
    rates.adblock_desktop_rate = rates.desktop_pageviews > 0 ? parseFloat(rates.adblock_desktop_pageviews / rates.desktop_pageviews).toFixedNumber(4) : null;
    rates.adblock_mobile_rate = rates.mobile_pageviews > 0 ? parseFloat(rates.adblock_mobile_pageviews / rates.mobile_pageviews).toFixedNumber(4) : null;
    
    const chart_data = {
      adblock: rates,
    }

    return parseGraphData(fromJS(chart_data));
};


function combineDatesAndData(dates, mobileBreakdown, desktopBreakdown) {
  return dates.zipWith(
    (date, mobileRate, desktopRate) => ({
      'dates': date.format('YYYY-MM-DD, HH:mm'),
      'mobile': mobileRate,
      'desktop': desktopRate,
    }),
    mobileBreakdown,
    desktopBreakdown,
  ).toList();
}

function parseGraphData(rawData) {
  const desktopBreakdown = rawData.getIn(['adblock', 'desktop_breakdown'])
    .sortBy((v, k) => k)
    .map((data) => data == null ? null : Number((data * 100).toFixedNumber(2)))
    .toList();
  const mobileBreakdown = rawData.getIn(['adblock', 'mobile_breakdown'])
    .sortBy((v, k) => k)
    .map((data) => data == null ? null : Number((data * 100).toFixedNumber(2)))
    .toList();
  const dates = rawData.getIn(['adblock', 'desktop_breakdown'])
    .keySeq()
    .toList()
    .sort()
    .map(timestamp => moment(Number (timestamp)).utc());
    
  const graphData = combineDatesAndData(dates, mobileBreakdown, desktopBreakdown);
  const tableData = [{
    device: 'desktop',
    pageviews: rawData.getIn(['adblock','desktop_pageviews']).toLocaleString(),
    adblock_pageviews: rawData.getIn(['adblock','adblock_desktop_pageviews']).toLocaleString(),
    adblock_rate: (rawData.getIn(['adblock','adblock_desktop_rate']) * 100).toFixedNumber(2) + '%',
  }, {
    device: 'mobile',
    pageviews: rawData.getIn(['adblock','mobile_pageviews']).toLocaleString(),
    adblock_pageviews: rawData.getIn(['adblock','adblock_mobile_pageviews']).toLocaleString(),
    adblock_rate: (rawData.getIn(['adblock','adblock_mobile_rate']) * 100).toFixedNumber(2) + '%',
  }];

  const result = DashboardRecord({
    tableData,
    graphData,
  });
  return result;
}
