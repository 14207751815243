import React, {useState, useRef,useEffect,useMemo, useImperativeHandle} from 'react';
import { Infotip, TableFilterDropdown,Tooltip } from '../../../../../styleguide';
import { Table, Input, Button } from "../../../../../styleguide";
import SVG from 'react-inlinesvg';
import { InfoCircleFilled, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebouncedSearch } from '../../../../../hooks';
import { getVendorTypeIcon } from './helper';
import persistentCookieIcon from '../../../../../assets/icons/persistent_cookie.svg';
import sessionCookieIcon from '../../../../../assets/icons/session_cookie.svg';
import localStorageIcon from '../../../../../assets/icons/local_storage.svg';
import fingerPrintIcon from '../../../../../assets/icons/finger_printing.svg';
import { trimString } from '../../../../utils';
import { FREQUENCY_MAP_CLASSNAMES} from './helper';

const renderVendorName = (value, vendor) => {
  const icon = getVendorTypeIcon(vendor);
  return (
    <span title={value}>
      {icon} {trimString(value, 20)}
    </span>
  );
};
const infoStorageAcessInfo = (
  <div id="info-storage-access-popup">
    <div className="info-item"><SVG src={sessionCookieIcon} /> Session Cookies</div>
    <div className="info-item"><SVG src={persistentCookieIcon} /> Persistent Cookies</div>
    <div className="info-item"><SVG src={localStorageIcon} /> Local Storage</div>
    <div className="info-item"><SVG src={fingerPrintIcon} /> Fingerprint</div>
  </div>
);

const renderFrequency = (value) => {
  return value ? <span className={FREQUENCY_MAP_CLASSNAMES[value]}>{value}%</span> : '-';
};
const renderCookieCategory = (value) => {
  return value && value.length > 0 ? (
    <div>
      <span>
        {value && value.length > 0 && value[0]}
      </span>
      <Infotip
        content={() => value.map((cookieCategory) => <p>{cookieCategory}</p>)}
        trigger="click"
        placement="bottomRight"
        className="vendor-list-wizard"
      >
        {value && value.length > 1 ? (
          <span className="more-tag">{`+${value.length - 1}`}</span>
        ) : null}
      </Infotip>
    </div>
  ) : null;
};

const AddVendorsTable = (props,ref) => {
    const {
        vendors,
        isLoading,
        showSelectedView,
        btnConfig,
        vendorType,
        activeKey,
        indexedSacnnedVendors,
        updateCheckedVendors,
        checkedVendors,
        clearSelectedRowsKeys,
        isNonIab
    } = props;
 
    const [addDisabledBtn,setAddDisabledBtn] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState(showSelectedView ? checkedVendors.selected : checkedVendors[activeKey]);
    const [search, setSearch] = useState('');
    const [storageAccessFilter , handleStorageAccessFilter] = useState([])
    const [vendorTypeFilter, setVendorTypeFilter] = useState([])
    const [cookieCategoryFilter, setCookieCategoryFilter] = useState([]);
    const [dataSource, setDataSource] = useState(vendors.map((v)=>{
      if(indexedSacnnedVendors[v.id]) {
        v.vendorAdditionalDetails = indexedSacnnedVendors[v.id]
      }
      return v;
    }));

    useEffect(()=>{
      setAddDisabledBtn(!selectedRowKeys.length)
      updateCheckedVendors(selectedRowKeys);
    },[selectedRowKeys])

    useEffect(()=>{
      setSelectedRowKeys(showSelectedView?checkedVendors.selected : checkedVendors[activeKey])
    },[activeKey])
  
    useEffect(()=>{
      if(clearSelectedRowsKeys) {
        setSelectedRowKeys([])
      }
    },[clearSelectedRowsKeys])

    //debouncing search for performance 
    const debouncedChangeHandler = useDebouncedSearch(setSearch, 700);


    useEffect(()=>{
      const dataSource = vendors.map((v)=>{
        if(indexedSacnnedVendors[v.id]) {
          v.vendorAdditionalDetails = indexedSacnnedVendors[v.id]
        }
        return v;
      }).filter(vendor => {
        let isVendorTypeFiltered = false;
        let isCookieCategoryFiltered = false;
        let isStorageAccessFiltered = false;
        let isSearchFiltered = false;
        const searchTrimmed = search.trim();
        if(search === '' || vendor.name.toLowerCase().indexOf(searchTrimmed.trim().toLowerCase()) !== -1) {
          isSearchFiltered = true;
        }
        if(vendorTypeFilter.length > 0) {
          if(vendorTypeFilter[0] === 'IAB'){
             isVendorTypeFiltered =  vendor.iab === true && vendor.vendorType === 'IAB'
          }else if(vendorTypeFilter[0] === 'CUSTOM'){
            isVendorTypeFiltered =  vendor.iab === false &&
            vendor.vendorType === 'CUSTOM' &&
            (vendor.isGoogleAtp === false || vendor.isGoogleAtp === null || vendor.isGoogleAtp === undefined)
          }else if(vendorTypeFilter[0] === 'GOOGLE-ATP'){
            isVendorTypeFiltered =  vendor.iab === false && vendor.vendorType === 'CUSTOM' && vendor.isGoogleAtp === true
          }else{
            isVendorTypeFiltered =  true
          }
        } else {
          isVendorTypeFiltered = true
        }
        if (cookieCategoryFilter.length === 0 || vendor?.vendorAdditionalDetails?.vendorCategory.includes(cookieCategoryFilter[0])) {
          isCookieCategoryFiltered = true;
        }
        if (storageAccessFilter.length === 0 || vendor?.vendorAdditionalDetails && storageAccessFilter.some(sa => vendor?.vendorAdditionalDetails[sa]) ) {
          isStorageAccessFiltered = true;
        }
        return isVendorTypeFiltered && isCookieCategoryFiltered && isStorageAccessFiltered && isSearchFiltered;
      })
      setDataSource(dataSource);
    },[vendorTypeFilter, storageAccessFilter, cookieCategoryFilter, search, vendors]);

    const columns = [
      {
        title: 'Vendor',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        width:150,
        className: 'vendor-name',
        render: renderVendorName,
        //ellipsis: true,
        filters: [
          {
            icon: <i  className="fas fa-circle" style={{color:'#FCA015',width: 10, height: 10,  marginRight: 10}} />,
            text: 'IAB Vendors',
            value: 'IAB',
          },
          {
            icon: <img  style={{ width: 10, height: 10, marginRight: 10 }} src="/images/google-icon.svg" />,
            text: 'Google ATP',
            value: 'GOOGLE-ATP',
          },
          {
            icon: <i className="fas fa-circle" style={{color:'#0098FF',width: 10, height: 10,  marginRight: 10}} />,
            text: 'Custom Vendors',
            value: 'CUSTOM',
          },
        ].filter( f => !(isNonIab && f.value === 'IAB')),
        filterDropdown: (props) => <TableFilterDropdown {...props} selectAllOption dropdownClassName="add-vendors-table-filter" handleSelectedKeysOnSave={setVendorTypeFilter}/>,
      },
      {
        title: "Prevalence (My properties)",
        dataIndex: ['vendorAdditionalDetails', 'sitesAvgPrevalencePercent'],
        sorter: (a, b) => {
          const firstRow = a?.vendorAdditionalDetails ? a?.vendorAdditionalDetails?.sitesAvgPrevalencePercent : 0;
          const secondRow = b?.vendorAdditionalDetails ? b?.vendorAdditionalDetails?.sitesAvgPrevalencePercent : 0;
          return firstRow - secondRow;
        },
        render: renderFrequency,
      },
      {
        title: "Prevalence (Dataset)",
        dataIndex: ['vendorAdditionalDetails', 'dataSetPrevalencePercent'],
        sorter: (a, b) => {
          const firstRow = a?.vendorAdditionalDetails ? a?.vendorAdditionalDetails?.dataSetPrevalencePercent : 0;
          const secondRow = b?.vendorAdditionalDetails ? b?.vendorAdditionalDetails?.dataSetPrevalencePercent : 0;
          return firstRow - secondRow;
        },
        render: renderFrequency,
      },
      {
        title: "Cookie Category",
        dataIndex: ['vendorAdditionalDetails', 'vendorCategory'],
        render: renderCookieCategory,
        width: 230,
        filters: [
          { text: 'Performance', value: 'Performance'},
          { text: 'Strictly Necessary', value: 'Strictly Necessary'},
          { text: 'Targeting/Advertising', value: 'Targeting/Advertising'},
          { text: 'Functionality', value: 'Functionality'},
          { text: 'Uncategorised', value: 'Uncategorised'},
        ],
        filterDropdown: (props) => <TableFilterDropdown {...props} selectAllOption dropdownClassName="add-vendors-table-filter" handleSelectedKeysOnSave={setCookieCategoryFilter}/>,
        // onFilter: (value, record) => (value !== 'ALL') ? record?.vendorAdditionalDetails?.vendorCategory.includes(value): true,
      },
      // {
      //   title: 'Technology',
      //   dataIndex: ['vendorAdditionalDetails', 'vendorTechnology'],
      //   key: 'technology',
      //   width: 150,
      //   render: (value) => value || '-',
      //   filters: vendorsFromScan.toJS().reduce((techs, current) => [
      //     ...techs,
      //     ...current.vendorAdditionalDetails.vendorTechnology
      //       .filter(tech => !techs.map(({ value }) => value).includes(tech))
      //       .map(tech => ({ text: tech, value: tech }))
      //   ], []),
      //   onFilter: (value, record) => record.vendorAdditionalDetails.vendorTechnology.includes(value),
      //   ellipsis: true,
      // },
      {
        title: () => (
          <>
            Information Storage/Access
            <Infotip
              overlayClassName="vl-wizard-info-storage"
              trigger="hover"
              placement="topRight"
              title={null}
              content={infoStorageAcessInfo}
            >
              <InfoCircleFilled style={{marginLeft:'5px'}} />
            </Infotip>
          </>
        ),
        dataIndex: 'vendorAdditionalDetails',
        width: 250,
        render: (vendorAdditionalDetails) => (
          vendorAdditionalDetails
            && (vendorAdditionalDetails.setsSessionCookies || vendorAdditionalDetails.setsPersistentCookies || vendorAdditionalDetails.localStorage || vendorAdditionalDetails.fingerPrinting)
            ? (
              <React.Fragment>
                {vendorAdditionalDetails.setsSessionCookies && <Infotip title={null} content={<>Session Cookie</>} className="vendor-list-wizard arrow"><SVG className="svg-custom" src={sessionCookieIcon} />{" "}</Infotip>}
                {vendorAdditionalDetails.setsPersistentCookies && <Infotip title={null} content={<>Persistent Cookie</>} className="vendor-list-wizard arrow"><SVG className="svg-custom" src={persistentCookieIcon} />{" "}</Infotip>}
                {vendorAdditionalDetails.localStorage && <Infotip title={null} content={<>Local Storage</>} className="vendor-list-wizard arrow"><SVG className="svg-custom" src={localStorageIcon} />{" "}</Infotip>}
                {vendorAdditionalDetails.fingerPrinting && <Infotip title={null} content={<>Fingerprint</>} className="vendor-list-wizard arrow"><SVG className="svg-custom" src={fingerPrintIcon} />{" "}</Infotip>}
              </React.Fragment>
            )
            : (
              '-'
            )
        ),
        filters: [
          {
            icon: <SVG className="svg-custom" src={sessionCookieIcon} />,
            text: 'Session Cookie',
            value: 'setsSessionCookies',
          },
          {
            icon: <SVG className="svg-custom" src={persistentCookieIcon} />,
            text: 'Persistent Cookie',
            value: 'setsPersistentCookies'
          },
          {
            icon: <SVG className="svg-custom" src={localStorageIcon} />,
            text: 'Local Storage',
            value: 'localStorage'
          },
          {
            icon: <SVG className="svg-custom" src={fingerPrintIcon} />,
            text: 'Fingerprint',
            value: 'fingerPrinting',
          }
        ],
        filterDropdown: (props) => <TableFilterDropdown {...props} selectAllOption dropdownClassName="add-vendors-table-filter" multiple handleSelectedKeysOnSave={handleStorageAccessFilter}/>,
        onFilter: (value, record) => record.vendorAdditionalDetails && record.vendorAdditionalDetails[value],
      },
    ]

    const rowSelection = {
        selectedRowKeys,
        onSelect : (record, selected, selectedRows, nativeEvent) => {
          if(selected) {
            setSelectedRowKeys((selectedKeys) => [... new Set(selectedKeys.concat(record.id))])
          } else {
            setSelectedRowKeys((selectedKeys) => selectedKeys.filter(key => key !== record.id))
          }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if(selected){
                setSelectedRowKeys((selectedKeys) => [...new Set(selectedKeys.concat(dataSource.map(v => v.id)))]);
            }else{
                setSelectedRowKeys((selectedKeys) => selectedKeys.filter((key) => !dataSource.map(v => v.id).includes(key)));
            }
        },
        checkStrictly: false
    };

    const addSelectedVendor = () => {
        const onClickEvent = btnConfig.onClickEvent
        onClickEvent(selectedRowKeys)
        setAddDisabledBtn(true)
        setSelectedRowKeys([])
    }

    return(
      <>
      <div className={!showSelectedView?'action-wrapper':'sv-search'}>
        <Input
          type="search"
          placeholder="Search Vendors"
          className="sv-search-input"
          onChange={(e) => {
            debouncedChangeHandler(e.target.value);
          }}
          suffix={<SearchOutlined />}
        />
        {(vendorType === 'known' || showSelectedView || activeKey === 'system') && <Button disabled={addDisabledBtn} onClick={addSelectedVendor} type={btnConfig.type} size="small">{btnConfig.title}{selectedRowKeys.length ? `(${selectedRowKeys.length})` : null}</Button>}
      </div>
        <Table
            className="vl-table"
            pagination={{
                position: ['bottomCenter'],
                showTotal: (total) => total !== vendors.length ? <div>Vendors :{' '}<b>{total}</b>{' '}out of{' '}<b>{vendors.length}</b></div> : <div>Vendors :{' '}<b>{vendors.length}</b></div>,
                size:'small',
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '50', '75', '100'],
                locale: { items_per_page: ' Records per page' },
            }}
            locale={{ emptyText: isLoading ? 'Scanning for vendors... ' : 'There is no data available for the current selected properties' }}
            loading={isLoading}
            rowKey={(record) => `${record.id}`}
            scroll={{ x: 'max-content', y: 300}}
            dataSource={dataSource}
            columns={columns}
            tableLayout = 'auto'
            rowSelection={(vendorType === 'known' || showSelectedView || activeKey === 'system') && rowSelection}
        />
        </>
    )
}

export default React.memo(AddVendorsTable);
