import React from 'react';
import { Select } from '../../../../../../../styleguide';
import { constants } from 'message-preact';

const { Option } = Select;

const Navigation = ({
  settingsTitle,
  settingKey,
  updateSetting,
  value,
}) => {
  return (
    <div className="four-options">
      <h4>{settingsTitle}</h4>
      <div className="option-inputs">
        <Select value={value} style={{ width: '100%' }} onChange={(value) => updateSetting(settingKey, value)}>
          <Option value={constants.pmOTTViews.HOME}>Home</Option>
          <Option value={constants.pmOTTViews.CATEGORIES}>Categories</Option>
          <Option value={constants.pmOTTViews.VENDORS}>Vendors</Option>
          <Option value={constants.pmOTTViews.PRIVACY_POLICY}>Privacy Policy</Option>
          <Option value={constants.pmOTTViews.CUSTOM_VIEW}>Custom View</Option>
        </Select>
      </div>
    </div>
  );
};

export default Navigation;