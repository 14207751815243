import React from 'react';
import { ConfigProvider } from 'antd';
import { DatePicker, Select } from '../../styleguide';
import moment from 'moment';
import en_GB from 'antd/lib/locale/en_GB.js'; // 	using en_GB instead of enUS; to start the week calender from Monday instead of Sunday, English (United Kingdom)
import PropTypes from 'prop-types';
import { RangeDates } from '../../records/reports_records.js';
import { DATEPICKERS } from '../../constants';
moment.updateLocale("en", { week: { dow: 1 } }); // To start the week calender from Monday instead of Sunday globally
import { Map, List, fromJS } from 'immutable';
const { Option } = Select;
const DATE_13MONTHS_BEFORE_TODAY =  moment().subtract(13, "months")

export default class DatePickerReports extends React.Component {
  static propTypes = {
    selectDates: PropTypes.func.isRequired,
    limit: PropTypes.number,
    defaultValue: PropTypes.instanceOf(RangeDates),
    disabled: PropTypes.bool,
    disabledDateOffset: PropTypes.number,
    selectedDatePicker: PropTypes.string,
  };

  static defaultProps = {
    limit: Infinity,
    disabled: false,
    disabledDateOffset: 0,
  };

  handleRangeChange = (date, dateString) => {
    let start;
    let end;
    const { selectedDatePicker } = this.props;
    if (selectedDatePicker === DATEPICKERS.day) {
      start = date;
      end = date;
    } else if (selectedDatePicker === DATEPICKERS.week) {
      start =
        DATE_13MONTHS_BEFORE_TODAY.diff(moment(date).startOf("isoWeek")) > 0
          ? DATE_13MONTHS_BEFORE_TODAY
          : moment(date).startOf("isoWeek");
      end =
        moment().diff(moment(date).endOf('isoWeek')) < 0
          ? moment().subtract(1, 'days')
          : moment(date).endOf('isoWeek');
    } else if (selectedDatePicker === DATEPICKERS.month) {
      start =
      DATE_13MONTHS_BEFORE_TODAY.diff(moment(date).startOf("month")) > 0
        ? DATE_13MONTHS_BEFORE_TODAY
        : moment(date).startOf("month");
      end =
        moment().diff(moment(date).endOf('month')) < 0
          ? moment().subtract(1, 'days')
          : moment(date).endOf('month');
    }
    this.props.selectDates({ start, end }, this.props.selectedDatePicker);
  };

  selectQuarter = (value) => {
    const startOfQaurter = moment(value.split('-')[0], 'YYYY')
      .quarter(value.split('-')[1])
      .startOf('quarter');
    const start =
      DATE_13MONTHS_BEFORE_TODAY.diff(startOfQaurter) > 0
        ? DATE_13MONTHS_BEFORE_TODAY
        : startOfQaurter;
    const end =
      ( (moment().year() === start.year()) && (moment().quarter() === start.quarter()) )
        ? moment().subtract(1, 'days')
        : moment(value.split('-')[0], 'YYYY')
            .quarter(value.split('-')[1])
            .endOf('quarter');
    this.props.selectDates({ start, end }, this.props.selectedDatePicker);
  };

  disabledDate = (current) => {
    return (
      current.isBefore(DATE_13MONTHS_BEFORE_TODAY) ||
      (this.props.selectedDatePicker === "WEEK"
        ? current.diff(moment().subtract(1, "days")) > 0
        : current.diff(moment()) > 0)
    );
  };

  getQuarters = () => {
    const d = new Date();
    const y = d.getFullYear();
    const m = d.getMonth();
    const quarters = [1, 2, 3, 4];
    let options = List([]);

    for (var pastYear = y - 2; pastYear < y; pastYear++) {
      quarters.map((q) => {
        const startDate = moment(pastYear, 'YYYY').quarter(q).startOf('quarter');
        const endDate = moment(pastYear, 'YYYY').quarter(q).endOf('quarter');
        if (endDate.diff(d, 'months') >= -12) {
          options = options.push(
            Map({
              year: pastYear,
              quarter: q,
              startMonth: startDate.format('MMM'),
              endMonth: endDate.format('MMM'),
            })
          );
        }
      });
    }
    quarters.slice(0, parseInt(m / 3 + 1)).forEach((q) => {
      const startDate = moment(y, 'YYYY').quarter(q).startOf('quarter');
      const endDate = moment(y, 'YYYY').quarter(q).endOf('quarter');
      if (endDate.diff(d, 'months') >= -12 || startDate.diff(d, 'months') >= -12) {
        options = options.push(
          Map({
            year: y,
            quarter: q,
            startMonth: startDate.format('MMM'),
            endMonth: endDate.format('MMM'),
          })
        );
      }
    });
    return options.reverse().map((q, i) => (
      <Option key={i} value={`${q.get('year')}-${q.get('quarter')}`}>
        {q.get('year')} Q{q.get('quarter')} ({q.get('startMonth')}-
        {q.get('endMonth')})
      </Option>
    ));
  };

  render() {
    let Picker;
    let defaultValue = moment();
    if (this.props.selectedDatePicker === 'WEEK') {
      defaultValue = moment().subtract(1, 'days');
      Picker = DatePicker.WeekPicker;
    } else if (this.props.selectedDatePicker === 'MONTH') {
      Picker = DatePicker.MonthPicker;
    } else {
      Picker = DatePicker;
    }
    const pickerProps = {
      disabled: this.props.disabled,
      allowClear: false,
      size: 'large',
      onChange: this.handleRangeChange,
      disabledDate: this.disabledDate,
      defaultValue: defaultValue,
      dropdownClassName: 'spsg-datepicker-popup',
      className: 'spsg-datepicker'
    };
    const quarterDefaultValue =
      new Date().getFullYear() + '-' + moment().quarter();
    return (
      <ConfigProvider locale={en_GB}>
        {this.props.selectedDatePicker === 'QUARTER' ? (
          <Select
            placeholder="Select Quarter"
            defaultValue={quarterDefaultValue}
            className="quarterly-date-picker-select"
            optionFilterProp="children"
            disabled={false}
            onChange={this.selectQuarter}
            filterOption={(i, o) =>
              o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
            }
          >
            {this.getQuarters()}
          </Select>
        ) : (
          <Picker {...pickerProps} />
        )}
      </ConfigProvider>
    );
  }
}
