import React from 'react';
import { Switch } from 'antd';

const Toggle = (incomingProps) => {
  const props = {...incomingProps};
  props.className = 'spsg-toggle ' + (props.className || '');

  return <Switch {...props} />;
};

export default Toggle;
