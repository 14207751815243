import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getDomainSetScoresAjax,
  getSetScoresIncludedAjax,
  getDomainScoresAjax,
  getAllDomainScoresAjax,
  getStandardScoresAjax,
  changeDomainScoresAjax,
  getAdminDomainsAjax,
  changeScanPeriodAjax,
  rescanPropertiesAjax,
} from '../api/consent_quality/score';

export const getDomainSetScores = actionCreator(
  getDomainSetScoresAjax,
  domainSetScoresPending,
  domainSetScoresLoaded,
  domainSetScoresError,
  domainSetScoresCached,
);

export const getSetScoresIncluded = actionCreator(
  getSetScoresIncludedAjax,
  setScoresIncludedPending,
  setScoresIncludedLoaded,
  setScoresIncludedError,
  setScoresIncludedCached,
);

export const getDomainScores = actionCreator(
  getDomainScoresAjax,
  domainScoresPending,
  domainScoresLoaded,
  domainScoresError,
  domainScoresCached,
);

export const getAllDomainScores = actionCreator(
  getAllDomainScoresAjax,
  allDomainScoresPending,
  allDomainScoresLoaded,
  allDomainScoresError,
  allDomainScoresCached,
);

export const getStandardScores = actionCreator(
  getStandardScoresAjax,
  standardScoresPending,
  standardScoresLoaded,
  standardScoresError,
  standardScoresCached,
);

export const changeDomainScores = actionCreator(
  changeDomainScoresAjax,
  changeDomainScoresPending,
  changeDomainScoresLoaded,
  domainScoresError,
);

export const getAdminDomains = actionCreator(
  getAdminDomainsAjax,
  adminDomainsPending,
  adminDomainsLoaded,
  adminDomainsError,
  adminDomainsCached,
);

export const changeScanPeriod = actionCreator(
  changeScanPeriodAjax,
  adminDomainsPending,
  adminDomainsChangedPeriod,
  adminDomainsError,
);

export const rescanProperties = actionCreator(
  rescanPropertiesAjax,
  adminDomainsPending,
  propertiesSentToRescan,
  adminDomainsError,
);

export function adminDomainsChangedPeriod(domains) {
  return {
    type: types.ADMIN_DOMAINS_CHANGED_PERIOD,
    domains,
  };
}

export function propertiesSentToRescan(domains) {
  return {
    type: types.ADMIN_DOMAINS_SENT_TO_RESCAN,
    domains,
  };
}

export function adminDomainsPending() {
  return {
    type: types.ADMIN_DOMAINS_PENDING,
  };
}

function adminDomainsLoaded(domains) {
  return {
    type: types.ADMIN_DOMAINS_LOADED,
    domains,
  };
}

function adminDomainsError(error) {
  return {
    type: types.ADMIN_DOMAINS_ERROR,
    error,
  };
}

function adminDomainsCached() {
  return {
    type: types.ADMIN_DOMAINS_CACHED,
  };
}

function domainSetScoresPending() {
  return {
    type: types.DOMAIN_SET_SCORES_PENDING,
  };
}

function domainSetScoresLoaded(scores) {
  return {
    type: types.DOMAIN_SET_SCORES_LOADED,
    scores,
  };
}

function domainSetScoresError(error) {
  return {
    type: types.DOMAIN_SET_SCORES_ERROR,
    error,
  };
}

function domainScoresPending() {
  return {
    type: types.DOMAIN_SCORES_PENDING,
  };
}

function domainScoresLoaded(scores) {
  return {
    type: types.DOMAIN_SCORES_LOADED,
    scores,
  };
}

function domainScoresError(error) {
  return {
    type: types.DOMAIN_SCORES_ERROR,
    error,
  };
}

function allDomainScoresPending() {
  return {
    type: types.ALL_DOMAIN_SCORES_PENDING,
  };
}

function allDomainScoresLoaded(scores) {
  return {
    type: types.ALL_DOMAIN_SCORES_LOADED,
    scores,
  };
}

function allDomainScoresError(error) {
  return {
    type: types.ALL_DOMAIN_SCORES_ERROR,
    error,
  };
}

function standardScoresPending() {
  return {
    type: types.STANDARD_SCORES_PENDING,
  };
}

function standardScoresLoaded(scores) {
  return {
    type: types.STANDARD_SCORES_LOADED,
    scores,
  };
}

function standardScoresError(error) {
  return {
    type: types.STANDARD_SCORES_ERROR,
    error,
  };
}

function changeDomainScoresPending() {
  return {
    type: types.CHANGE_DOMAIN_SCORES_PENDING,
  };
}

export function changeDomainScoresRedux(scores) {
  return {
    type: types.CHANGE_DOMAIN_SCORES_REDUX,
    scores,
  };
}

function changeDomainScoresLoaded(scores) {
  return {
    type: types.CHANGE_DOMAIN_SCORES_LOADED,
    scores,
  };
}

function setScoresIncludedPending() {
  return {
    type: types.SET_SCORES_INCLUDED_PENDING,
  };
}

function setScoresIncludedLoaded(scores) {
  return {
    type: types.SET_SCORES_INCLUDED_LOADED,
    scores,
  };
}

function setScoresIncludedError(error) {
  return {
    type: types.SET_SCORES_INCLUDED_ERROR,
    error,
  };
}

function setScoresIncludedCached() {
  return {
    type: types.SET_SCORES_INCLUDED_CACHED,
  };
}

function domainSetScoresCached() {
  return {
    type: types.DOMAIN_SET_SCORES_CACHED,
  };
}

function domainScoresCached() {
  return {
    type: types.DOMAIN_SCORES_CACHED,
  };
}

function allDomainScoresCached() {
  return {
    type: types.ALL_DOMAIN_SCORES_CACHED,
  };
}

function standardScoresCached() {
  return {
    type: types.STANDARD_SCORES_CACHED,
  };
}