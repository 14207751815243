import React, { useMemo, useEffect, useState } from 'react';
import { Divider, Image, List, Table, Tooltip } from 'antd';
import { VendorStatusColumn } from '../../shared/DiagnoseColumns';
import useSearchParams from '../../shared/useSearchParams';
import { getUniqueVendorIdsList, isFiltersProvided, mapSelectedFilters, requestDataDependOnTableFilters } from '../helpers';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getTrackingTechDetectedFilters,
  getTrackingTechDetectedList,
  getVendorManagementStatusesList,
} from '../../../../../actions/diagnose_dashboard_actions';
import { browserHistory } from 'react-router';
import { isEqual } from 'lodash';
import TableFilterDropdown from '../../shared/TableFilterDropdown';
import BlockOutlined from '../../../../../assets/images/diagnose/BlockOutlined.svg';
import CommentOutline from '../../../../../assets/images/diagnose/CommentOutline.svg';
import CookielessOutlined from '../../../../../assets/images/diagnose/CookielessOutlined.svg';
import RetweetOutline from '../../../../../assets/images/diagnose/RetweetOutline.svg';
import { InfoCircleFilled } from '@ant-design/icons';


const DetailedBreakdown = ({
  selectedFilters,
  userDetails,
  trackingTechDetectedFilters,
  getTrackingTechDetectedFilters,
  trackingTechDetectedList,
  trackingTechDetectedListPending,
  getTrackingTechDetectedList,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const searchParams = useSearchParams();
  const [tableFilters, setTableFilters] = useState(null);
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');
  const accountId = userDetails.accountId;

  const getTrackingTechDetectedListData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getTrackingTechDetectedList({
      ...filters,
      accountId,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
    return res;
  };

  const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
    const query = {
      metric: 'Tracking Tech',
      vendors,
      status,
      tag,
      websites: selectedFilters?.websites,
    };
    const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
    if (isCacheValid) {
      return vendorManagementStatuses;
    }
    const res = await getVendorManagementStatusesList(query);
    return res;
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      requestDataDependOnTableFilters(
        { filters: tableFilters || {}, isEnabledVendorStatusManagementFeature },
        (f, vendorId) => getTrackingTechDetectedListData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
        getVendorManagementStatusesListData,
      );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy, accountId]);

  const getVendorsPriorToConsentFiltersData = () => {
    getTrackingTechDetectedFilters(filters);
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      getVendorsPriorToConsentFiltersData();
    }
  }, [filters]);

  useEffect(() => {
    if (vendorManagementActivities?.length) {
      getVendorManagementStatusesListData(
        { vendors: getUniqueVendorIdsList(trackingTechDetectedList?.results) },
        false,
      );
    }
  }, [vendorManagementActivities]);

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);

    let finalFilters = filters;
    if (filters.tech_detected) {
      finalFilters = tranformedFilters(filters);
    }
    setTableFilters(finalFilters);
    browserHistory.replace(location.pathname);
  };

  const tranformedFilters = (filters) => {
    const transformed = { ...filters };

    if (transformed.tech_detected) {
      transformed.use_pixels = transformed.tech_detected.includes('use_pixels');
      transformed.use_session_replay = transformed.tech_detected.includes('use_session_replay');
      transformed.use_cookieless = transformed.tech_detected.includes('use_cookieless');
      transformed.use_chatbots = transformed.tech_detected.includes('use_chatbots');
    }

    delete transformed.tech_detected;

    return transformed;
  };

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const vendorsFilter = trackingTechDetectedFilters?.vendors?.map((filter) => ({ text: filter, value: filter }));
  const technologiesFilter = trackingTechDetectedFilters?.categories?.map((filter) => ({
    text: filter,
    value: filter,
  }));

  const columns = [
    {
      title: 'Vendor Name',
      dataIndex: 'name',
      className: 'vendor_name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: vendorsFilter,
      filterDropdown: (props) => (
        <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} showSearch multiple />
      ),
      filterSearch: true,
      fixed: 'left',
      width: 120,
      render: (text, vendor) => (
        <div style={{ cursor: 'pointer', padding: '10px', borderRadius: '5px' }} onClick={() => handleRowClick(vendor)}>
          {text}
        </div>
      ),
    },
    ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    {
      title: 'Vendor Categorization',
      dataIndex: 'categories',
      key: 'categories',
      sorter: (a, b) => a.categories && a.categories.join().localeCompare(b.categories),
      filters: technologiesFilter,
      filterDropdown: (props) => (
        <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} showSearch multiple />
      ),
      width: 300,
    },
    {
      title: () => {
        return (
          <div>
            Tracking Tech Detected
            <Tooltip
              title={() => {
                return (
                  <div className="information-storage-tooltip">
                    <div className="information-storage-item">
                      <div className="information-storage-item-tech">
                        <Image src={BlockOutlined} preview={false} />
                      </div>
                      <div className="information-storage-item-name">
                        <b>Pixels</b>
                        <span>Small trackers used in websites/emails for user behavior analytics and marketing.</span>
                      </div>
                    </div>
                    <Divider style={{ borderColor: '#DAE4EB', width: '100%' }} />
                    <div className="information-storage-item">
                      <div className="information-storage-item-tech">
                        <Image src={CookielessOutlined} preview={false} />
                      </div>
                      <div className="information-storage-item-name">
                        <b>Cookieless IDs</b>
                        <span>Unique identifiers used to track users across sites without the use of cookies.</span>
                      </div>
                    </div>
                    <Divider style={{ borderColor: '#DAE4EB', width: '100%' }} />
                    <div className="information-storage-item">
                      <div className="information-storage-item-tech">
                        <Image src={CommentOutline} preview={false} />
                      </div>
                      <div className="information-storage-item-name">
                        <b>Chatbots</b>
                        <span>Tech, often AI-powered, used to interact with users and automate support.</span>
                      </div>
                    </div>
                    <Divider style={{ borderColor: '#DAE4EB', width: '100%' }} />
                    <div className="information-storage-item">
                      <div className="information-storage-item-tech">
                        <Image src={RetweetOutline} preview={false} />
                      </div>
                      <div className="information-storage-item-name">
                        <b>Session Replay</b>
                        <span>Tech that records user actions on sites/apps to understand behavior.</span>
                      </div>
                    </div>
                  </div>
                );
              }}
              overlayClassName="information-storage-tooltip-overlay"
            >
              <InfoCircleFilled style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
        );
      },
      key: 'tech_detected',
      filters: [
        { text: 'Pixels', value: 'use_pixels', icon: <Image alt="Pixels" src={BlockOutlined} preview={false} /> },
        {
          text: 'Cookieless IDs',
          value: 'use_cookieless',
          icon: <Image alt="Pixels" src={CookielessOutlined} preview={false} />,
        },
        { text: 'Chatbots', value: 'use_chatbots', icon: <Image alt="Pixels" src={CommentOutline} preview={false} /> },
        {
          text: 'Session Replay',
          value: 'use_session_replay',
          icon: <Image alt="Pixels" src={RetweetOutline} preview={false} />,
        },
      ],
      render: (text, record) => {
        return (
          <>
            {record.use_pixels && (
              <span title="Pixels" style={{ marginRight: '5px' }}>
                <Image alt="Pixels" src={BlockOutlined} preview={false} />{' '}
              </span>
            )}
            {record.use_cookieless && (
              <span title="Cookieless" style={{ marginRight: '5px' }}>
                <Image alt="Cookieless" src={CookielessOutlined} preview={false} />{' '}
              </span>
            )}
            {record.use_chatbots && (
              <span title="Chatbots" style={{ marginRight: '5px' }}>
                <Image alt="Chatbots" src={CommentOutline} preview={false} />{' '}
              </span>
            )}
            {record.use_session_replay && (
              <span title="Session Replay" style={{ marginRight: '5px' }}>
                <Image alt="Session replay" src={RetweetOutline} preview={false} />{' '}
              </span>
            )}
          </>
        );
      },
      filterDropdown: (props) => (
        <TableFilterDropdown dropdownClassName={'dianose-dashboard_filter'} {...props} allowAllSelect multiple />
      ),
      width: 250,
    },
  ];

  const handleRowClick = (vendor) => {
    browserHistory.replace(`/diagnose_dashboard/tracking_tech_detected/vendor_details?id=${vendor.vendor_id}`);
  };

  const tableData = useMemo(() => {
    if (!isEnabledVendorStatusManagementFeature) {
      return trackingTechDetectedList?.results;
    }

    return trackingTechDetectedList?.results?.map((row) => {
      const rowWithVendorStatus = {
        ...row,
        vendor_status:
          vendorManagementStatuses?.filter((vendorStatus) => {
            return vendorStatus.vendor === row.vendor_id;
          }) || [],
      };
      return rowWithVendorStatus;
    });
  }, [isEnabledVendorStatusManagementFeature, trackingTechDetectedList?.results, vendorManagementStatuses]);

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The table below lists the vendors that we have observed utilizing tracking technologies on your properties in
        the selected time period. Tracking technologies include pixels, cookieless IDs, chatbots and session replays.
        Click on each vendor to find out more about the specific tracking technologies detected.
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container">
        <div className={'filters_block'}></div>
        <Table
          rowClassName={'pointer'}
          scroll={{ x: 'max-content', y: 630 }}
          showSorterTooltip={false}
          pagination={{
            current: page,
            pageSize: limit,
            total:
              tableFilters?.vendor_status?.length && !vendorManagementStatuses.length
                ? 0
                : trackingTechDetectedList?.total_count,
            position: ['bottomCenter'],
            onChange: onPaginationChange,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
          }}
          columns={columns}
          dataSource={tableData}
          onChange={onTableChange}
          loading={{
            className: 'diagnose-spinning',
            spinning: trackingTechDetectedListPending || vendorManagementStatusesPending,
            tip: trackingTechDetectedList?.results?.length
              ? ''
              : 'Processing a large query; please wait or leave it open and check later.',
            size: 'large',
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  trackingTechDetectedList: store.diagnoseDashboardState.getIn(['trackingTechDetectedList', 'value']),
  trackingTechDetectedListPending: store.diagnoseDashboardState.get('trackingTechDetectedListPending'),
  vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
  vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
  vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
  vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
  selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
  trackingTechDetectedFilters: store.diagnoseDashboardState.getIn(['trackingTechDetectedFilters', 'value']),
  userDetails: store.accountState.getIn(['userDetails', 'value']),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTrackingTechDetectedList,
      getVendorManagementStatusesList,
      getTrackingTechDetectedFilters,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
