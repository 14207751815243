import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Collapse } from 'antd';

import MessageEditDescription from './MessageEditDescription.js.jsx';
import MessageEditIncludeDefaultStyles from './MessageEditIncludeDefaultStyles.js.jsx';
import MessageEditIsConsentStyles from './MessageEditIsConsentStyles';
import MessageEditTypePicker from './MessageEditTypePicker.js.jsx';
import MessageEditChoiceSelect from './MessageEditChoiceSelect.jsx';
import MessageEditCustomStyles from './MessageEditCustomStyles.js.jsx';
import MessageVariables from './MessageVariables';

import HeadlineBody from './HeadlineBody.jsx';
import Styles from './Styles.js.jsx';

import { MessageConfigRecord } from '../../../../records/message_config_records.js';
import { ChoiceConfigRecord } from '../../../../records/choice_config_records.js';
import { TYPES, SUBTYPE, CONSTANTS } from '../../../../constants.js';

const Panel = Collapse.Panel;

export default class MessageEditForm extends React.Component {
  static propTypes = {
    altpay: PropTypes.bool.isRequired,
    choiceConfig: PropTypes.instanceOf(ChoiceConfigRecord).isRequired,
    className: PropTypes.string,
    description: PropTypes.string.isRequired,
    editing: PropTypes.bool.isRequired,
    editorOpen: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
    siteDomain: PropTypes.string,
    toggleCustomCSS: PropTypes.func.isRequired,
    updateChoiceConfig: PropTypes.func.isRequired,
    updateDescription: PropTypes.func.isRequired,
    updateMessageConfig: PropTypes.func.isRequired,
    isSPUser: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    consent: PropTypes.bool.isRequired,
    samba: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    className: '',
    siteDomain: '',
  }

  updateTemplate = (mc) => {
    let newMC = mc;
    if (mc.data.style.template === 'consent') {
      newMC = mc
        .setIn(['data', 'style', 'backdrop', 'backgroundColor'], 'rgba(51,56,63,0.85)')
        .setIn(['data', 'style', 'general', 'backgroundColor'], '#F7F8FA')
        .setIn(['data', 'style', 'title', 'fontSize'], '25px')
        .setIn(['data', 'style', 'title', 'color'], 'black')
        .setIn(['data', 'style', 'body', 'color'], '#30425C')
        .setIn(['data', 'style', 'buttons', 'hoverColor'], 'black')
        .setIn(['data', 'style', 'buttons', 'hoverBackgroundColor'], 'white');
    }
    this.props.updateMessageConfig(newMC);
  }

  render() {
    const editing = this.props.editing;
    const includeDefaultStyles =
      this.props.messageConfig.getIn([ 'data', 'style', 'includeDefaultStyles' ]);
    const formClassName = classNames('redesign', 'message-edit-page-container', { 'editor-open': this.props.editorOpen });

    const subType = this.props.messageConfig.getIn(['data', CONSTANTS.SUBTYPE]);
    const isRedirectOrCustomHTML = subType === TYPES.REDIRECT || subType === SUBTYPE.HTML;

    return (
      <div className={formClassName} id='message-edit-page-container'>
        <Collapse defaultActiveKey={['description']} accordion>
          <Panel header="MESSAGE" key="description" >
            <MessageEditDescription
              description={ this.props.description }
              onUpdate={ this.props.updateDescription }
              editing={ editing }
              form={ this.props.form }
              messageConfig={ this.props.messageConfig }
              updateMessageConfig={ this.props.updateMessageConfig }
              siteDomain={ this.props.siteDomain }
              siteScreenshotUrl={ this.props.messageConfig ? this.props.messageConfig.data.userData.customScreenshotUrl : null }
              subType={ this.props.messageConfig.getIn(['data', CONSTANTS.SUBTYPE]) }
            />
            <MessageEditTypePicker
              messageConfig={ this.props.messageConfig }
              updateMessageConfig={ this.props.updateMessageConfig }
              choiceConfig={ this.props.choiceConfig }
              updateChoiceConfig={ this.props.updateChoiceConfig }
              editing={ editing }
              form={ this.props.form }
              isSPUser={ this.props.isSPUser }
            />
          </Panel>
          {
            isRedirectOrCustomHTML ? null :
            <Panel header="TEXT" key="header-body" >
              <HeadlineBody
                messageConfig={ this.props.messageConfig }
                updateMessageConfig={ this.props.updateMessageConfig }
                editing={ editing }
                form={ this.props.form }
                variables={ this.props.variables }
                openVariablesModal={ this.props.toggleShowVariablesModal }
              />
            </Panel>
          }
          {
            isRedirectOrCustomHTML ? null :
            <Panel header="CHOICE OPTIONS" key="choice-options" >
              <MessageEditChoiceSelect
                altpay={ this.props.altpay }
                consent={ this.props.consent }
                editing={ this.props.editing }
                form={ this.props.form }
                choiceConfig={ this.props.choiceConfig }
                updateChoiceConfig={ this.props.updateChoiceConfig }
                isSPUser={ this.props.isSPUser }
                accountId={ this.props.accountId }
                samba={ this.props.samba }
                siteGroup={ this.props.siteGroup }
                sites={ this.props.sites }
                getSitePrivacyManagerList={ this.props.getSitePrivacyManagerList }
                getSiteGroupPrivacyManagerList={ this.props.getSiteGroupPrivacyManagerList }
                sitePrivacyManagerList={ this.props.sitePrivacyManagerList }
                updateParentState={ this.props.updateParentState }
                variables={ this.props.variables }
              />
            </Panel>
          }
          {
            isRedirectOrCustomHTML ? null :
            <Panel header="STYLES" key="styles" >
              <MessageEditIncludeDefaultStyles
                messageConfig={ this.props.messageConfig }
                updateMessageConfig={ this.props.updateMessageConfig }
                editing={ editing }
                form={ this.props.form }
              />
              { this.props.consent ? (
                <MessageEditIsConsentStyles
                  messageConfig={ this.props.messageConfig }
                  updateMessageConfig={ this.updateTemplate }
                  editing={ editing }
                  form={ this.props.form }
                />
              ) : null}
              <Styles
                messageConfig={ this.props.messageConfig }
                updateMessageConfig={ this.props.updateMessageConfig }
                editing={ editing && includeDefaultStyles }
                form={ this.props.form }
              />
            </Panel>
          }
          {
            subType === TYPES.REDIRECT ? null :
            <MessageVariables
              variables={ this.props.variables }
              updateParentState={ this.props.updateParentState }
              editing={ this.props.editing }
              form={ this.props.form }
              getSiteGroup={ this.props.getSiteGroup }
              sites={ this.props.sites }
              siteGroup={ this.props.siteGroup } 
              toggleShowVariablesModal={ this.props.toggleShowVariablesModal }
              variablesModalVisible={ this.props.variablesModalVisible }
            />
          }
          {
            subType === TYPES.REDIRECT ? null :
            <MessageEditCustomStyles
              messageConfig={ this.props.messageConfig }
              updateMessageConfig={ this.props.updateMessageConfig }
              editing={ this.props.editing }
              editorOpen={ this.props.editorOpen }
              toggleCustomCSS={ this.props.toggleCustomCSS }
              form={ this.props.form }
              variables={ this.props.variables }
              openVariablesModal={ this.props.toggleShowVariablesModal }
            />
          }
        </Collapse>
      </div>
    );
  }
}
