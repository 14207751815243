import React from 'react';
import { List, Map } from 'immutable';
import { Select, Button } from '../../../../styleguide'
import { styles } from './styles';
// import { styles } from '../../../../records/styles';

import { ContinueScenario } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
// import { SCENARIO_STEPS } from '../../../../scenario_constants.js';
import { SCENARIO_STEPS } from '../../scenarios/versionTwo/components/steps';


const { Option } = Select;

export default class BooleanStep extends React.Component {
  onSelectFilter = (inputString, option) => {
    return (
      inputString.toLowerCase() ===
      option.props.children.toLowerCase().slice(0, inputString.length)
    );
  };

  componentWillMount() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .find((r, ind) => r.get('className') == className && ind == this.props.conditionIndex);
    let data;
    if (getSavedData && this.props.isUpdate) {
      data = getSavedData;
    }
    this.setState({
      selectedOption:
        getSavedData && data
          ? data.get('data').get('continue')
            ? 'Yes'
            : 'No'
          : 'Yes',
    });
  }

  state = {
    condition: '',
    selectedOption: '',
  };

  cancelCondition = () => {
    this.props.closePanel();
  };

  onChangeOption = (option) => {
    let conditionTag = `${this.props.conditionLabel}:${option}`;
    this.setState({ condition: conditionTag, selectedOption: option });
  };

  onAddEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set(
      'data',
      new ContinueScenario({
        continue: this.state.selectedOption == 'Yes' ? true : false,
      })
    );
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.unshift(stepBlockData);
    this.props.onChange(this.props.activeStep, rowData);
  };

  onUpdateEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    stepBlockData = stepBlockData.set(
      'data',
      new ContinueScenario({
        continue: this.state.selectedOption == 'Yes' ? true : false,
      })
    );
    const index = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.findIndex((r, ind) => r.get('className') == className && ind == this.props.conditionIndex);
    const rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.set(index, stepBlockData);
    this.props.onChange(this.props.activeStep, rowData);
  };

  render() {
    const booleanOption = List([
      Map({ label: 'Yes', value: 'Yes' }),
      Map({ label: 'No', value: 'No' }),
    ]);
    const options = booleanOption.map((s) => (
      <Option key={s.get('value')}>{s.get('label')}</Option>
    ));
    return (
      <div style={styles.scenarioConditionPanelTwo}>
        <div style={styles.scenarioInnerWrapper}>
          <span className="condition-label">Condition: </span>
          <span className="condition-title">{this.props.conditionLabel}</span>
          <div id="condition-dropdown">
            <label>{this.props.conditionLabel}</label>
            <Select
              value={this.state.selectedOption}
              onChange={(option) => this.onChangeOption(option)}
              placeholder="Please select"
              filterOption={this.onSelectFilter}
              showArrow={true}
            >
              {options.toJS()}
            </Select>
          </div>
        </div>
        <div style={styles.scenarioButtonWrapper}>
          <Button
            onClick={
              this.props.buttontitle == 'Add'
                ? this.onAddEvent
                : this.onUpdateEvent
            }
            type="primary"
            size='small'
          >
            {this.props.buttontitle}
          </Button>
          <Button
            onClick={() => this.cancelCondition()}
            style={styles.scenarioMarginLeft}
            type="secondary"
            font="oswald"
            size='small'
            ghost
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
