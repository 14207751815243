import { Record, List, Map } from 'immutable';
import { Partitionset } from './partitionset_records.js';
import { STATUS_TYPES as STATUS } from '../constants';


export const StatsForPartition = Record({
    fwd_ab_tr: 0,
    rev_ab_tr: 0,
    total_abp: 0,
    total_msg: 0,
    total_pv: 0,
});

export class Campaign extends Record({
    description: '',
    account_id: 22,
    environment: 1,
    id: null,
    site_id: null,
    stats: new Map({}),
    partition_set: new Partitionset(),
    created_at: '',
    deleted_at: '',
    campaign_type_id: 0,
    _v: 1
}) {
    get status() {
        return this.environment === 1 ? STATUS.PUBLIC : STATUS.STAGE;
    }
    get live() {
        return !this.deleted_at;
    }
    equals(campaignRecord) {
        return this.id === campaignRecord.id
    }
    hashCode() {
        return this.id;
    }
}

export const CampaignViewState = Record({
    expanded: false,
    id: null,
    activeTab: '1',
    expandedPartitionUuids: List([]),
});

export const ViewState = Record({
    oldCampaignsOpen: false,
    campaigns: Map({}),
    topOffSet: 0,
});

export const PartitionsetGraphData = Record({
    adblock: List([]),
    messages: List([]),
});
