import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Select } from 'antd';
import AntdFormItem from '../../../common/AntdFormItem.jsx';
import CustomButton from '../../../common/CustomButton.js.jsx';
import { MessageConfigRecord } from '../../../../records/message_config_records.js';

const Option = Select.Option;

export default class extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    description: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    messageConfig: PropTypes.instanceOf(MessageConfigRecord).isRequired,
    updateMessageConfig: PropTypes.func.isRequired,
    siteScreenshotUrl: PropTypes.string,
    subType: PropTypes.oneOf(['modal', 'inline', 'top', 'bottom', 'redirect', 'html']).isRequired,
  };

  static defaultProps = {
    className: '',
    siteScreenshotUrl: '',
  };

  handleChange = (event) => {
    this.props.onUpdate(event.target.value);
  };

  updateDefaultScreenshotUrl = () => {
    this.props.updateMessageConfig(this.updateScreenshotUrl(this.props.siteDomain));
    this.screenshotUrlInput.props.form.setFieldsValue({
      'custom-screenshot-url': this.props.siteDomain,
    });
  };

  updateScreenshotUrl = (url) => {
    let newUrl = url;

    if (url !== null) {
      const urlParser = document.createElement('a');
      urlParser.href = url;

      if (urlParser.host === window.location.host) {
        urlParser.href = urlParser.protocol + '//' + url;
      }

      newUrl = urlParser.href;
    }

    return this.props.messageConfig.setIn([ 'data', 'userData', 'customScreenshotUrl'], newUrl);
  };

  updateScreenshotUrlInput = (event) => {
    this.props.updateMessageConfig(this.updateScreenshotUrl(event.target.value));
  };

  updateScreenshotUrlClick = () => {
    const value = this.screenshotUrlInput.props.form.getFieldValue('custom-screenshot-url');
    this.props.updateMessageConfig(this.updateScreenshotUrl(value));
  };

  clearUrl = () => {
    this.props.updateMessageConfig(this.updateScreenshotUrl(null));
    this.screenshotUrlInput.props.form.setFieldsValue({
      'custom-screenshot-url': '',
    });
  };

  onDelayChange = (e) => {
    const fieldToBeUpdated = this.props.messageConfig.data.options.delayType === 'seconds' ? 'delayAfterSeconds' : 'delayAfterScroll';
    const fieldToBeZeroed = fieldToBeUpdated === 'delayAfterSeconds' ? 'delayAfterScroll' : 'delayAfterSeconds';
    const newVal = parseInt(e.target.value);
    const mc = this.props.messageConfig
      .setIn([ 'data', 'options', fieldToBeUpdated], newVal)
      .setIn([ 'data', 'options', fieldToBeZeroed], 0)
      .setIn([ 'data', 'options', 'detectExitIntent'], false);
    this.props.updateMessageConfig(mc);
  };

  handleDelaySelect = (value) => {
    let mc = this.props.messageConfig
      .setIn([ 'data', 'options', 'delayType'], value);
    if (value === 'none') {
      mc = mc
        .setIn([ 'data', 'options', 'delayAfterSeconds'], 0)
        .setIn([ 'data', 'options', 'delayAfterScroll'], 0)
        .setIn([ 'data', 'options', 'detectExitIntent'], false);
    } else if (value === 'exit') {
        mc = mc.setIn([ 'data', 'options', 'detectExitIntent'], true);
    }
    console.warn(mc.data.options);
    this.props.updateMessageConfig(mc);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const className = classNames(this.props.className, 'message-description');

    let isUpdateDisabled;
    if (this.screenshotUrlInput) {
      isUpdateDisabled = this.props.siteScreenshotUrl === this.screenshotUrlInput.props.form.getFieldValue('custom-screenshot-url');
    }

    const isInlineOrRedirect = this.props.subType === 'inline' || this.props.subType === 'redirect';
    let customUrlComponent;
    if (!isInlineOrRedirect) {
      customUrlComponent = (
        <div className='custom-url-component'>
          <div className='screenshot-url'>
            <h3>Screenshot URL</h3>
            <AntdFormItem
              ref={ AntdFormItem => { this.screenshotUrlInput = AntdFormItem; } }
              className='screenshot-input'
              fieldName="custom-screenshot-url"
              initialValue={ this.props.siteScreenshotUrl }
              form={ this.props.form }
              editing={ this.props.editing }
              required={ false }
              placeholder=""
              onPressEnter={ this.updateScreenshotUrlInput }
            />
          </div>
          <div className='update-message-button-container'>
            <CustomButton
              className='update-message button'
              size={ CustomButton.sizes.MEDIUM }
              onClick={ this.updateScreenshotUrlClick }
              disabled={ isUpdateDisabled }
            >
              Update URL
            </CustomButton>
            {
              this.props.siteScreenshotUrl ? (
                <CustomButton
                  className='no-url button'
                  size={ CustomButton.sizes.MEDIUM }
                  onClick={ this.clearUrl }
                  disabled={ this.props.siteScreenshotUrl === null }
                >
                  No Screenshot
                </CustomButton>
              ) : (
                <CustomButton
                  className='no-url button'
                  size={ CustomButton.sizes.MEDIUM }
                  onClick={ this.updateDefaultScreenshotUrl }
                >
                  Use Site Domain
                </CustomButton>
              )
            }
          </div>
        </div>
      );
    }

    let delayInput = null;
    const delayType = this.props.messageConfig.data.options.delayType;
    if (delayType === 'seconds') {
      delayInput = (
        <div>
          <AntdFormItem
            fieldName="delay-seconds"
            initialValue={ this.props.messageConfig.data.options.delayAfterSeconds }
            onChange={ this.onDelayChange }
            form={ this.props.form }
            editing={ this.props.editing }
            placeholder="Delay After Seconds"
            isNumeric={ true }
            min={0}
          />
        </div>
      );
    } else if (delayType === 'scroll') {
      delayInput = (
        <div>
          <AntdFormItem
            fieldName="delay-scroll"
            initialValue={ this.props.messageConfig.data.options.delayAfterScroll }
            onChange={ this.onDelayChange }
            form={ this.props.form }
            editing={ this.props.editing }
            placeholder="Delay After Scroll Percent"
            isNumeric={ true }
            min={0}
            max={100}
          />
          <span className='percent'>%</span>
        </div>
      );
    }

    return (
      <div className={ className }>
        <div className='message-name'>
          <h3>Message Name</h3>
          <AntdFormItem
            fieldName="message-description"
            initialValue={ this.props.description }
            onChange={ this.handleChange }
            form={ this.props.form }
            editing={ this.props.editing }
            placeholder="Your message name"
          />
        </div>
        <div className='triggers'>
          <h3>Delay After</h3>
          <Select
            className='delay-select'
            value={ delayType }
            onChange={ this.handleDelaySelect }
          >
            <Option value='none'>None</Option>
            <Option value='seconds'>Seconds</Option>
            <Option value='scroll'>Scroll Percent</Option>
            <Option value='exit'>Exit</Option>
          </Select>
          { delayInput }
        </div>
        { customUrlComponent }
      </div>
    );
  }
}
