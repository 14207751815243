import React, { Component } from 'react';
import { Button } from 'antd';
import { getColumns } from './PropertyTableColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { propertyTableCSV } from '../../CSV/generateCSV';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Input } from '../../../../../styleguide';

export default class PropertyTable extends Component {
  state = {
    tableData: [],
    search: null,
    filter: null
  }
  componentDidMount = () => {
    const tableData = this.generateTableData();
  }
  generateTableData = () => {
    const { scores, scoreData } = this.props;

    const rules = scoreData.standard?.rules || [];

    let domains = scores;
    domains = domains.sort((d1, d2) => {
      return (d2.impressions || this.props.averageImpressions) - (d1.impressions || this.props.averageImpressions);
    });

    const dataSource = domains.map((d) => {
      const domainInfo = {...d};

      const ruleScores = d.ruleScores || {};
      let passedCount = 0;
      Object.keys(ruleScores).forEach(key => {
        if (ruleScores[key] === 1) passedCount++;
      });


      /* HANDLE GENERAL INFO */
      domainInfo.score = d.overAllScore || 0;
      domainInfo.scored = d.scored || false;
      domainInfo.isSafe = d.isSafe === true ? true : false;
      domainInfo.dateScanned = d.lastScanned;
      domainInfo.rulesPassed = passedCount;

      /* HANDLE RULES */
      domainInfo.rules = {};
      rules.forEach(r => {
        domainInfo.rules[r.rule.ruleId] = ruleScores[r.rule.ruleId];
      });

      return domainInfo;
    });

    this.setState({
      tableData: dataSource
    })
  }
  getExport = (filteredData) => {
    const rules = this.props.scoreData.standard?.rules || [];
    propertyTableCSV(filteredData, rules, this.props.selectedSetName);
  }
  setFilter = filter => this.setState({ filter })
  updateSearch = (e) => this.setState({ search: e.target.value });
  render() {
    const { search, filter } = this.state;
    const { scoreData, threshold } = this.props;

    let tableData = this.state.tableData;
    if (filter) {
      tableData = tableData.filter(td => {
        if (filter === 'safe' && td.isSafe) return true;
        if (filter === 'unsafe' && !td.isSafe) return true;
        if (filter === 'meet' && td.score >= threshold) return true;
        if (filter === 'dont' && td.score < threshold) return true;
        if (filter === 'scored' && td.scored === true) return true;
        if (filter === 'unscored' && td.scored === false) return true;
        return false;
      });
    }
    if (search) {
      tableData = tableData.filter(td => {
        if (td.url.toLowerCase().includes(search.toLowerCase())) return true;
        return false;
      });
    }
    const rules = scoreData.standard?.rules || [];
    const columns = getColumns(rules);
    return (
      <div className="properties-tbl">
        <Input
          placeholder="Search Properties"
          onChange={this.updateSearch}
          value={search}
          style={{ width: 190 }}
          suffix={<SearchOutlined/>}
        />
        <div className="tbl-filters">
          { (filter || search) && (
            <span className="clear" onClick={() => this.setState({ filter: null, search: null })}>
              <FontAwesomeIcon icon={faTimes} /> Clear All Filters
            </span>
          )}
          <Button className={filter === 'scored' ? 'active' : ''} onClick={() => this.setFilter('scored')}>Scored</Button>
          <Button className={filter === 'unscored' ? 'active' : ''} onClick={() => this.setFilter('unscored')}>Unscored</Button>
          <span className="download" onClick={() => this.getExport(tableData)}>
            <FontAwesomeIcon icon={faDownload} />
          </span>
        </div>
        <Table
          bordered
          columns={columns}
          rowKey={record => record._id}
          dataSource={tableData}
          pagination={{
            position: ['bottomCenter'],
            showTotal: (total) => `Total Properties: ${total}`,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
            locale: { items_per_page: ' Records per page' },
          }}
          scroll={{ x: 'max-content' }}
        />

      </div>
    );
  }
}
