import PropTypes from 'prop-types';
import React from 'react';

export const choicesRateValueShape = PropTypes.shape({
    key: PropTypes.string,
    choice: PropTypes.string,
    clicks: PropTypes.string,
    rate: PropTypes.string,
    value: PropTypes.string,
    description: PropTypes.string,
});

export const campaignValueDataShape = PropTypes.shape({
    wlpv: PropTypes.arrayOf(PropTypes.number),
    rapv: PropTypes.arrayOf(PropTypes.number),
    dcmp: PropTypes.arrayOf(PropTypes.number),
    off: PropTypes.arrayOf(PropTypes.number),
});

export const choicesChartsShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    perc: PropTypes.number.isRequired
});
