import React from 'react';
import { Radio } from 'antd';

const LabelPosition = ({ 
  updateSetting, 
  settingKey,
  settingsTitle,
  value,
  customOptions: [pos1, pos2] = ['top', 'side'],
 }) => {
  return (
    <div className="set-direction">
      <h4>{settingsTitle}</h4>
      <div>
        <Radio.Group value={value} onChange={({ target: { value } }) => updateSetting(settingKey, value)}>
          <Radio.Button value={pos1}>{pos1}</Radio.Button>
          <Radio.Button value={pos2}>{pos2}</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
}

export default LabelPosition;
