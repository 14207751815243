import React, { useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { COUNTRY_LANGUAGES } from '../../../../../constants';
import { CloseCircleFilled } from '@ant-design/icons';

const groupMissing = (data) => {
  let missing = {};
  data.forEach(d => {
    if (missing[d.id]) {
      missing[d.id].titles.push(d.title);   
    } else {
      missing[d.id] = {
        ...d,
        titles: [d.title]
      }
    }
  });
  return missing;
}


const SupportedLanguagesModal = ({
  visible,
  handleCancel,
  handleOk,
  missing,
}) => {

  const languages = COUNTRY_LANGUAGES.toJS();


  const actualMissing = groupMissing(missing);

  return (
    <Modal
      title="Missing Language Support"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      width={800}
      okText="Save & Continue"
      cancelText="Cancel"
    >
      <div className="add-component-modal">
        <p>
          Some of your supported languages are missing translations for the following components, please make sure you have a translation 
          for each of your supported languages.
        </p>
        <div className="missing-container">
          <div className="missing-languages">
            <div className="missing-header">
              <div className="missing-name">Component (Type)</div>
              <div className="missing-language">Translation</div>
              <div className="missing-settings">Missing Settings</div>
            </div>
            {Object.keys(actualMissing).map((o, i) => {
              const c = actualMissing[o];
              const lang = languages.find(l => l.code === c.language);
              return lang && lang.language ? (
                <div className="missing-language" key={'missing-l-' + i}>
                  <div className="missing-name">{c.name} ({c.type})</div>
                  <div className="missing-language">{lang.language} ({c.language})</div>
                  <div className="missing-settings">
                    <Tooltip placement="bottom" title={<div>{c.titles.map(t => <div>{t}</div>)}</div>}><CloseCircleFilled style={{ color: '#FF2626', marginRight: '10px' }} />Missing Settings ({c.titles.length})</Tooltip>
                  </div>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SupportedLanguagesModal;