import PropTypes from 'prop-types';
import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Select, Modal, Input } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List, OrderedSet, is } from 'immutable';
import { ConsentCompensationList, VendorWrapper, History } from '../../../../records/consent_compensation_records';
import { Vendor } from '../../../../records/vendor_list_records';
import { Site } from '../../../../records/site_records';
import { User } from '../../../../records/account_records';

const Search = Input.Search;
const Option = Select.Option;

export default class AddVendorsModal extends React.Component {
  static propTypes = {
    consentCompensationList: PropTypes.instanceOf(ConsentCompensationList),
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    vendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    consentCompensationLists: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(ConsentCompensationList)).isRequired,
    enabledSitesForScan: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,

    getConsentCompensationList: PropTypes.func.isRequired,
    updateVendorList: PropTypes.func.isRequired,
    getVendors: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    createConsentCompensationList: PropTypes.func.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
  }

  static defaultProps = {
    consentCompensationList: null,
  }

  state = {
    vendorsToAdd: OrderedSet([]),
    search: '',
  }

  updateSearch = (e) => this.setState({ search: e.target.value });

  getVendorByName = (name) => {
    const vendor = this.props.vendors.find(v => v.get('name') === name);
    return vendor;
  }

  handleSelect = (names) => {
    const vendors = List(names).map(n => this.getVendorByName(n.slice(0, n.length/2)));
    this.setState({
      vendorsToAdd: vendors,
    });
  }

  handleAddVendors = () => {
    const vendorsWrappers = this.state.vendorsToAdd.map(v => new VendorWrapper({ vendorId: v.id}));
    const updatedVendorList = this.props.vl.set('vendorsWrappers', this.props.vl.get('vendorsWrappers').concat(vendorsWrappers));
    this.setState({
      vendorsToAdd: OrderedSet([]),
    });
    this.props.updateVendorList(updatedVendorList);
    this.props.closeModal();
  }

  generateSearchableStringValue(string) {
    if (!string) return '';
    return string + string.toLowerCase();
  }

  handleCloseAddVendorsModal = () => {
    this.setState({
      vendorsToAdd: OrderedSet([]),
    });
    this.props.closeModal();
  }

  render() {
    const vendorsToAdd = this.state.vendorsToAdd.map(v => this.generateSearchableStringValue(v.name)).toJS();
    const vendorsWithoutSelected = this.props.vendors.filterNot(gv => this.props.vl.vendorsWrappers.map(vw => vw.vendorId).includes(gv.id));
    const vendorsInSelect = vendorsWithoutSelected.filter(vendor => {
      const name = vendor.get('name');
      if (this.state.search) {
        if (!name.toLowerCase().includes(this.state.search.toLowerCase())) {
          return false;
        }
      }
      return name !== '' && name !== null && name !== undefined;
    }).map(vendor => {
      return (
        <Option key={ this.generateSearchableStringValue(vendor.get('name')) } >
          { vendor.get('name') }
        </Option>
      );
    });

    const searchBar = (
      <Search
        placeholder="Search vendors"
        onChange={this.updateSearch}
        className="vendor-filter"
      />
    );

    return (
      <Modal
        visible={ this.props.visible }
        onOk={ this.handleAddVendors }
        onCancel={ this.handleCloseAddVendorsModal }
        okText='+ Add Vendors'
        className='add-vendors edit-vendor-compensation'
        closable={ false }
      >
        <p className='title'>Add Vendors</p>

        <div className='flex-row' ref={this.myRef} >

          { searchBar }

          <Select
            value={ vendorsToAdd }
            onChange={ this.handleSelect }
            mode='multiple'
            tokenSeparators={ [','] }
            dropdownClassName={`add-vendors edit-vendor-compensation ${this.props.visible ? 'visible' : 'not-visible'}`}
            open
          >
            { vendorsInSelect }
          </Select>

          <div className='selected-vendors'>
            <p className='title-3'>Selected Vendors</p>

            {
              this.state.vendorsToAdd.size ?
                this.state.vendorsToAdd.map(v => {
                  return (
                    <div className='vendor-container flex-row'>
                      <div>{ v.name }</div>
                      <CloseCircleFilled
                        onClick={ () => this.setState({ vendorsToAdd: this.state.vendorsToAdd.filterNot(vendor => vendor.name === v.name)})} />
                    </div>
                  );
                }) :
                <p>Currently there are no vendors selected</p>
            }
          </div>
        </div>
      </Modal>
    );
  }
}