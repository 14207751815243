import * as types from '../actions/action_types';

import { Map } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue } from './helpers.js';

export const initialState = Map({
    vendorTraceFilters: new StoreRecord({ value: {} }),
    consentTypeFilter: new StoreRecord({ value: '' }),
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SELECTED_VENDOR_TRACE_FILTERS:
            return setValue(state, ['vendorTraceFilters'], action.vendorTraceFilters);

        case types.SET_CONSENT_TYPE_FILTER:
            return setValue(state, ['consentTypeFilter'], action.consentTypeFilter);

        case types.RESET_VENDOR_TRACE:
            return initialState;
    }
    return state;
}
