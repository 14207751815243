import React, { useEffect, useState } from "react";
import { Button, ConfigProvider, Input,Table,Form } from 'antd';
import { SaveOutlined, CloseOutlined,EditOutlined} from '@ant-design/icons';
import { checkStatus } from "../../api/helper"
import enUS from 'antd/lib/locale/en_US';
import AddVendorModal from './AddVendorModal';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

function VendorManagement(props) {
    if (!props.currentUser) {
      return null;
    }

    const [vendors, setVendors] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [modalVisible, showModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();

    useEffect(() => {

        const URL = (props.category.includes('gdpr')) ? `${permissionsSvcBaseUrl}/tcfv2` : `${permissionsSvcBaseUrl}/ccpa`

        const fetchSystemVendors = async () => {
            const vendors = await fetch(`${URL}/vendors?select=vendorType, policyUrl, name`, {
                  credentials: 'include',
                  mode: 'cors',
                  headers: {
                    'Content-Type': 'application/json',
                  },
            })
                .then(checkStatus)
                .then(res => res.json())
            
            setLoading(false)
            setVendors(vendors)
        }
        if (!modalVisible || loading === true) {
            fetchSystemVendors()
        }
    }, [modalVisible,loading])

    
    const saveVendors = async (vendor) => {

        const URL = (props.category.includes('gdpr')) ? `${permissionsSvcBaseUrl}/tcfv2` : `${permissionsSvcBaseUrl}/ccpa`

        const res = await fetch(`${URL}/vendors`, {
                method:'PUT',
                mode: "cors",
                cache: "no-cache",
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json',
                },
                redirect: "follow",
                referrer: "no-referrer",
              body: JSON.stringify({
                accountId: props.currentUser.accountId,
                _id:vendor.id,
                id:vendor.id,
                name:vendor.Name,
                policyUrl:vendor.PolicyUrl,
                vendorType:'CUSTOM'
            })
        })
            .then(checkStatus)
            .then(res => res.json())
            .catch((e) => console.log(e))          
    }

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      }) => {
        const inputNode = <Input />;
        return (
          <td {...restProps}>
            {editing ? (
              <Form.Item
                name={dataIndex}
                style={{
                  margin: 0,
                }}
                rules={dataIndex === 'PolicyUrl'?[
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                  {
                    required: true,
                    pattern: new RegExp(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g),
                    message: `Please enter valid privacy url`
                  }
                ]:[
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ]}
              >
                {inputNode}
              </Form.Item>
            ) : (
              children
            )}
          </td>
        );
      };

    const edit = (record) => {
        form.setFieldsValue({
          Name:'',
          PolicyUrl: '',
          ...record,
        });
        setEditingKey(record.key);
      };
    
      const cancel = () => {
        setEditingKey('');
      };
    

    const handleAlphabeticalSort = (a, b, key) => {
        try{
            const first = a[key].toUpperCase();
            const second = b[key].toUpperCase();

            if (first < second) return -1;
            if (first > second) return 1;
            return 0;
        } catch(err) {
            return -1
        }
    }

    const isEditing = (record) => record.key === editingKey;

    const vendorColumns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            width: '15%',
            editable:true,
            render: (text) => (
              <div className="rowStyle">
                {text}
              </div>
            ),
            sorter: (a, b) => handleAlphabeticalSort(a, b, 'Name'),
            defaultSortOrder:'ascend'
        },
        {
          title: 'ID',
          dataIndex: 'id',
          width: '15%',
          editable:false,
          render: (text) => (
            <div className="rowStyle">
              {text}
            </div>
          ),
          sorter: (a, b) => handleAlphabeticalSort(a, b, 'Name'),
          defaultSortOrder:'ascend'
      },
        {
            title: 'Privacy url',
            dataIndex: 'PolicyUrl',
            key: 'PolicyUrl',
            width: '20%',
            render: (text) => (
              <div className="rowStyle">
                {text}
              </div>
            ),
            editable: true,
        },
        {
            title: 'Type',
            dataIndex: 'vendorType',
            key: 'vendorType',
            editable:false,
            width: '2%',
            filters: [{
                text: 'IAB',
                value: 'IAB',
            }, {
                text: 'CUSTOM',
                value: 'CUSTOM',
            }],
            onFilter: (value, record) => record.vendorType === value
        },
        {
            dataIndex: 'operation',
            width: '20%',
            align:'center',
            render: (_, record) => {
              const editable = isEditing(record);
              const showEditing = (record.vendorType === 'CUSTOM') && (props.category === 'gdprV2')
              return showEditing?editable ? (
                <span>
                  <SaveOutlined
                    onClick={() => save(record.key)}
                    style={{
                      marginRight: 8,
                      fontSize:'14px'
                    }}
                  >
                    Save
                  </SaveOutlined>
                 <CloseOutlined style={{
                      fontSize:'14px'
                    }} onClick={cancel}>Cancel</CloseOutlined>                
                </span>
              ) : (
                <EditOutlined
                 className="avo-dialogue-icons avo-sp-edit"
                 onClick={() => edit(record)}/>
              ):null;
            },
          },
    ];

    const mergedColumns = vendorColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
    
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });
  
    let reg
    try {
        reg = new RegExp(searchQuery, 'gi')
    } catch (e) {
        console.log(e)
    }

    const save = async (key) => {
        try {
          const row = await form.validateFields();
          const newData = [...filteredVendors];
          const index = newData.findIndex((item) => key === item.key);
    
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setLoading(true)
            saveVendors(newData[index])
            setEditingKey('');
          } else {
            newData.push(row);
            setEditingKey('');
          }
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
        }
      };

    const filteredVendors = vendors
        .filter(({ name }) => name.match(reg))
        .map((row,i) => ({
            key: i.toString(),
            id:row._id,
            Name: row.name,
            PolicyUrl: row.policyUrl,
            vendorType: row.vendorType
        }))

    return (
        <div className='vendor-management-page'>
            <AddVendorModal visible={modalVisible} category={props.category} showModal={showModal} accountId={props.currentUser.accountId}/>
            <div className="search-container">
                <Input
                    className='search-bar'
                    placeholder="Search name"
                    value={searchQuery}
                    onChange={(e) => { setSearchQuery(e.target.value) }}
                />
            </div>
            <ConfigProvider locale={enUS}>
            <Form form={form} component={false}>
                <Table
                    components={{
                    body: {
                      cell: EditableCell,
                    },
                    }}
                    columns={mergedColumns}
                    className="vl-editable-row"
                    dataSource={filteredVendors}
                    loading={loading}
                />
            </Form>
            </ConfigProvider>
            <Button 
                onClick={() => showModal(true)} 
                type="primary" 
                style={{ marginBottom: 16 }}>
                    Add custom vendor
            </Button>
        </div>
    );
}

export default VendorManagement