import { CloseOutlined } from '@ant-design/icons';
import React from 'react';

const Tag = (props) => {
  const { label, value, closable = false, onClose, noClass = false, className = "" } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <span
      {...(noClass ? {} : { className: `ant-select-selection-item ${className}` })}
      onMouseDown={onPreventMouseDown}
      title={value}
    >
      {label}
      {closable && <CloseOutlined onClick={onClose} />}
    </span>
  );
};

export default Tag;
