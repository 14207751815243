import * as types from './action_types';
import { getAndParseAdblockGraphData } from '../api/adblock';

const adblockGraphPending = () => ({
  type: types.ADBLOCK_PENDING,
});

const adblockGraphLoaded = data => ({
  type: types.ADBLOCK_LOADED,
  data,
});

const adblockGraphLoadError = errorMessage => ({
  type: types.ADBLOCK_ERROR,
  errorMessage,
});

export const getAdblockGraphData = (data) => {
  return (dispatch) => {
    dispatch(adblockGraphPending());
    return getAndParseAdblockGraphData(data).then(data => {
      dispatch(adblockGraphLoaded(data));
    }).catch(reason => {
      return dispatch(adblockGraphLoadError(reason));
    });
  };
};