import PropTypes from "prop-types";
import React from "react";
import { User } from "../../records/account_records";
import { Link } from "react-router";

const AdblockSubnav = ({ currentUser: { accountFeatures, accountId } }) => {
  const hasFeature = (feature) =>
    accountFeatures.includes(feature);

  const getActiveStatus = (pagePath) =>
    window.location.pathname === pagePath ? "active" : "inactive";
  return (
    <div className="adblock-report subnav">
      <ul className="list-inline">
        {hasFeature("adblock") && (
          <li>
            <Link className={getActiveStatus("/adblock_dashboard")} to="/adblock_dashboard">
              Dashboard
            </Link>
          </li>
        )}
        {hasFeature("adblock") && (
          <li>
            <Link className={getActiveStatus("/adblock")} to="/adblock">
              Ad Block Report
            </Link>
          </li>
        )}
        {hasFeature("altpay") && (
          <li>
            <Link className={getActiveStatus("/altpay")} to="/altpay">
              Altpay Report
            </Link>
          </li>
        )}
        {hasFeature("adblock") && (
          <li>
            <Link className={getActiveStatus("/whitelist")} to="/whitelist">
              Whitelist Report
            </Link>
          </li>
        )}
        {hasFeature("tcf_v2") && (
          <li>
            <Link
              className={`${getActiveStatus("/gdprtcfv2_report")}`}
              to="/gdprtcfv2_report"
            >
              GDPR Performance
            </Link>
          </li>
        )}
        {hasFeature("ccpa") && (
          <li>
            <Link
              className={`${getActiveStatus("/us_privacy_report")}`}
              to="/us_privacy_report"
            >
              U.S. Privacy Performance
            </Link>
          </li>
        )}
        {hasFeature("sar") && (
          <li>
            <Link
              className={`${getActiveStatus("/us_privacy/datarequest")}`}
              to="/us_privacy/datarequest"
            >
              Subject Access Requests
            </Link>
          </li>
        )}
        <li>
          <Link
            className={getActiveStatus("/custom_report")}
            to="/custom_report"
          >
            Request a Report
          </Link>
        </li>
      </ul>
    </div>
  );
};

AdblockSubnav.propTypes = {
  currentUser: PropTypes.instanceOf(User).isRequired,
  isMasq: PropTypes.bool.isRequired,
};

export default AdblockSubnav;
