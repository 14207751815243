import PropTypes from 'prop-types';
import React from 'react';
import { Select, Input } from 'antd';
import { VendorList } from '../../../records/vendor_list_records';
import { Partition as PartitionRecord, SiteToMessageMap } from '../../../records/dns_records';

const { Option } = Select

export default class Partition extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    updateParentState: PropTypes.func.isRequired,
    updatePartition: PropTypes.func.isRequired,
  }

  state = {
    vl: this.props.vendorList,
    showWarning: false,
  }

  // export const SiteToMessageMap = Record({
  //   siteId: null,
  //   messageId: null,
  //   messageSettings: null,
  // })

  handleMessageChange = (siteId, messageId) => {
    const index = this.props.partition.siteToMessageMap.findIndex(obj => obj.siteId === siteId);
    const messageSettings = this.props.messagesBySite.find(messagesBySiteObj => messagesBySiteObj.siteId === siteId).messages.find(m => m.id === messageId).messageSettings;
    const siteToMessageMap = this.props.partition.siteToMessageMap.set(index, new SiteToMessageMap({
      siteId,
      messageId,
      messageSettings,
    }))
    this.props.updatePartition(this.props.partition.set('siteToMessageMap', siteToMessageMap));
  }

  changeGeo = (value) => {
    this.props.updatePartition(this.props.partition.set('geoTargeting', value));
  }

  changeWeight = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
        value = '';
    } else if (value < 0) {
        value = 0;
    } else if (value > 365) {
        value = 365;
    }
    if (value > 100) value = 100;
    this.props.updatePartition(this.props.partition.set('weight', value));
  }

  getSiteDomain = (id) => {
    return this.props.sites.find(s => s.id === id).domain;
  }

  render() {
    let partition = this.props.partition;

    // XXX 2!!! <div className='site'>{ this.getSiteDomain(siteToMessages.siteId) }</div>

    if (!this.props.messagesBySite) {
      return null;
    }

    return (
      <div>
        <div>
          <div className='row'>
            <p>Weight:</p>
            <Input value={ partition.weight } onChange={ this.changeWeight } />
          </div>
          <div className='row'>
            <p>Geo Targeting:</p>
            <Select value={ partition.geoTargeting } onChange={ this.changeGeo }>
              <Option key='ALL_US'>All US</Option>
              <Option key='CA'>CA</Option>
            </Select>
          </div>
          <div className='site-to-message'>

            { this.props.messagesBySite.map((siteToMessages, index) => {
              const messages = siteToMessages.messages;
              if (messages.size) {
                const selectedMessageId = partition.siteToMessageMap.find(obj => {
                  return obj.siteId === siteToMessages.siteId
                }).messageId;
                const messageExists = messages.some(m => m.id === selectedMessageId);
                let selectedMessage;
                if (messageExists) {
                  selectedMessage = selectedMessageId;
                } else {
                  selectedMessage = messages.first().id;
                }
                return(
                  <div className='row' key={index}>
                    <div className='site'>{ this.getSiteDomain(siteToMessages.siteId) }</div>
                    <Select value={ selectedMessage } onChange={ (messageId) => this.handleMessageChange(siteToMessages.siteId, messageId) }>
                      { 
                        messages.map(m => {
                          return (<Option key={ m.id } value={ m.id }>{ m.description }</Option>)
                        })
                      }
                    </Select>
                  </div>
                )
              } else {
                return (
                  <div className='row' key={index}>
                    <div className='site'>{ this.getSiteDomain(siteToMessages.siteId) }</div>
                    <div><p className='no-message'>NO CCPA MESSAGE SETUP</p></div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}