import 'whatwg-fetch';
import { List, OrderedSet, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';
import { VendorList, Vendor, Purpose, VendorListError } from '../../records/vendor_list_records';

const getConsentBaseUrl = () => {
  return window.CONFIG && window.CONFIG.consentOrigin || 'https://sourcepoint.mgr.consensu.org';
}

// const getConsentBaseUrl = () => 'https://sourcepoint.mgr.consensu.org';

// const getConsentBaseUrl = () => 'http://192.168.7.45:3000';

// const getConsentBaseUrl = () => 'https://cmp.sp-stage.net';

export function getVendorListsAjax(accountId, siteAccess) {
  let url = '/vendor-list/' + accountId;
  if (siteAccess) {
    url += '?siteIds=' + JSON.stringify(siteAccess);
  }

  // caching temp removed to accomodate V2
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
    return fetch(getConsentBaseUrl() + url)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        // addToFetched(url);
        return OrderedSet(resp.map(v => new VendorList(v) ))
      });
  // }
}

export function getScannedVendorsAjax(siteIds, vendorListId) {
  let checkForExistingVendors = '';
  if (vendorListId) {
    checkForExistingVendors = '&vendorListId=' + vendorListId;
  }
  return fetch(getConsentBaseUrl() + '/vendor-scan?siteIds=' + JSON.stringify(siteIds.toJS()) + checkForExistingVendors)
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      const vendors = List(resp).map(obj => {
        const vendor = obj.vendor;
        vendor.cookies = obj.cookies;

        if (obj.hasOwnProperty('isNew')) {
          vendor.isNew = obj.isNew;
        }
        return vendor;
      }).map(v => new Vendor(v));
      return vendors;
    });
}

export function getSitesEnabledForScanAjax(accountId) {
  return fetch(getConsentBaseUrl() + '/vendor-scan/legacy/sites')
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp).map(obj => obj.siteId);
    });
}

export function getIABPurposesAjax() {
  const url = '/purposes/iab';
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
    return fetch(getConsentBaseUrl() + url)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        // addToFetched(url);
        return List(resp.map(p => new Purpose(p) ));
      });
  // }
}

export function getVendorListAjax(id, accountId) {
  const url = `/vendor-list?vendorListId=${id}`;
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
    return fetch(getConsentBaseUrl() + url)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        // addToFetched(url);
        return new VendorList(resp);
      });
  // }
}

export function getGlobalVendorsAjax() {
  const url = '/vendors';
  // if (isFetched(url)) {
  //   return Promise.resolve(FETCHED);
  // } else {
    return fetch(getConsentBaseUrl() + url)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        // addToFetched(url);
        return OrderedSet(resp.map(vendor => new Vendor(vendor)));
      });
  // }
}

export function getGlobalVendorsHashAjax() {
  const url = '/vendors';
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getConsentBaseUrl() + url)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        const vendorHash = {};
        resp.forEach((vendor) => {
          vendorHash[vendor._id] = new Vendor(vendor);
        });
        return new Map(vendorHash);
      });
  }
}

export function createVendorListAjax(accountId, list) {
  return fetch(getConsentBaseUrl() + '/vendor-list', {
    method: 'POST',
    body: JSON.stringify(generateVendorlistData(accountId, list)),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => {
      const updatedResp = Object.assign({}, resp);
      updatedResp.clientSideMessageId = list.clientSideMessageId;
      return new VendorList(updatedResp);
    });
}

export function cloneVendorListAjax(accountId, vendorListId) {
  return fetch(getConsentBaseUrl() + `/vendor-list/clone?vendorListId=${ vendorListId }`, {
    method: 'POST',
    body: JSON.stringify({ accountId }),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(resp => new VendorList(resp));
}

export function updateVendorListAjax(accountId, list) {
  return fetch(getConsentBaseUrl() + '/vendor-list', {
    method: 'PUT',
    body: JSON.stringify(generateVendorlistData(accountId, list)),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(checkVendorListStatus)
    .then(resp => {return resp.json()})
    .then(resp => new VendorList(resp));
}

async function checkVendorListStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    const errors = await response.json();
    throw new List(errors).map(err => new VendorListError(err));
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function deleteVendorListAjax(accountId, { id }) {
  return fetch(getConsentBaseUrl() + '/vendor-list/' + accountId + '/' + id, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ accountId }),
  })
    .then(checkVendorListStatus)
    .then(resp => resp.json())
    .then(() => id);
}


function generateVendorlistData(accountId, list) {
  const categories = list.categories.map(c => {
    let id = c.id;
    if (c.iabPurposeRefs.includes(c.id)) {
      id = null;
    }
    let category = c.toMap().delete('iabId').set('vendors', c.vendorIds).delete('vendorIds').delete('id');
    if (id) category = category.set('_id', id);
    return category;
  });

  const vendorsWrappers = list.vendorsWrappers.map(vw => {
    const id = vw.id;
    let updatedwv = vw.toMap().delete('id');
    let cookies = updatedwv.get('cookies');

    cookies = cookies.map(c => {
      let updatedCokie = c.toMap().delete('id').delete('foundOnUrls');
      return updatedCokie;
    })
    updatedwv = updatedwv.set('cookies', cookies)
    if (id) {
      updatedwv = updatedwv.set('_id', id);
    }
    return updatedwv;
  }).toJS();

  const data = {
    accountId: accountId.toString(),
    name: list.name,
    description: list.description,
    vendors: list.vendors.map(v => v.id).toSet().toJS(),
    categories,
    consentScope: list.consentScope,
    shareRootDomain: list.shareRootDomain,
    siteIds: list.siteIds.toSet().toJS(),
    isActive: list.isActive,
    vendorsWrappers,
    autoUpdateVendorScan: list.autoUpdateVendorScan,
    autoUpdateIabVendors: list.allIabAndAutoUpdate,
    cookieMaxAge: list.cookieMaxAge,
    writeFirstPartyCookiesFromServer: list.writeFirstPartyCookiesFromServer,
  };

  if (list.id) {
    data._id = list.id;
  }

  return data;
}
