import React from "react";
import { Divider } from  "antd";
import SVG from 'react-inlinesvg';
import { Tooltip } from '../../../../styleguide';
import moment from 'moment';
import { WarningOutlined} from '@ant-design/icons';
import { Droppable, Draggable } from "react-beautiful-dnd";
import { campaignTypes } from "../helper";
import refreshIcon from '../../../../assets/icons/refresh.svg';
import cloneIcon from '../../../../assets/icons/clone.svg';
import stopIcon from '../../../../assets/icons/stop.svg';

import { numberWithCommas } from "../../../utils";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 6% 0`,

  // change background colour if dragging
  //background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? "lightblue" : "#E8F0F6",
  padding: "2%",
  flex: 1,
  background:"#F4F6F8 0% 0% no-repeat padding-box",
  borderRadius: "18px",
});


function CampaignsList(props) {
  const messages = props.list.messages;

  const getStatistics = (campaignId, statName) => {
    let value = 0;
    if(props.statsData && props.statsData[campaignId] && !props.statsData[campaignId].pending && props.statsData[campaignId]['value']) {
      value = props.statsData[campaignId]['value']['campaign_stats'][statName];
    }
    return numberWithCommas(value);
  }

  const getCampaignType = (campaignTypeId) => {
    return campaignTypes.find(c => c.value === parseInt(campaignTypeId)).label;
  }

  const sortCampaigns = () => {
    var sortOrder = props.list.sortOrder;
    const campaigns =  props.list.campaigns.sort(function (a, b) {
      return sortOrder.indexOf(Number(a.campaign_type_id)) - sortOrder.indexOf(Number(b.campaign_type_id));
    });
   return campaigns;
  }

  const campaigns = sortCampaigns();

  const getVlBasedOnCampType = (type) => {
    switch(type) {
      case 1:
        return props.vendorListMetaData['tcfv2'];
      case 2: 
        return props.vendorListMetaData['ccpa'];
      case 6:
        return props.vendorListMetaData['usnat'];
      default:
        return null;
    }
  } 

  const showCloneModal = (campaign,env) => {
     props.cloneCampaigns(campaign.id,env);
  }

  const refreshCampaign = (selectedCampaign) => {
    props.onRefresh(selectedCampaign)
  }

  const confirmCampaignEndAction = (selectedCampaign) => {
    props.confirmCampaignEndAction(selectedCampaign);
  };

 
  return (
        <Droppable isDropDisabled={props.isDropDisabled} droppableId={ props.list.id.toString() }>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className={`campaign-list ${props.list.campaignType}`}
            >
              { props.list.header }
              {campaigns.map((campaign,index) => {
                const campaignData = campaign.toJS();
                const diffEnv = campaign?.environment === 1? 'Stage': 'Public'
                const scenarios = props.list.scenarios.filter(s => campaignData.partition_set.partitions.map(ps => ps.scenario_id).includes(s.id));
                const scenarioMessages = [];
                scenarios.forEach((scenario) => {
                  const scenarioSteps =  scenario?.steps?.toJS();
                  scenarioSteps.forEach((row) => {
                    const newArr = row.rowData.filter((r) => r.category === 'message');
                    scenarioMessages.push(newArr[0]?.data?.message_id);
                  })
                });
                const finalMessages = scenarioMessages?.map((msg) => messages?.find(m=>m.id === msg)).filter(msg => Boolean(msg))
                campaign.messages = finalMessages
                const campaignTypeCode = Number(campaign.campaign_type_id);
                const campaignType = campaignTypeCode === 0 ? 'No Type' : getCampaignType(campaignTypeCode);
                const legacyCssClass = campaignTypeCode === 0 ? 'legacy' : '';
                const campaignName = campaign.name ? campaign.name : campaign.description;
                let updatedCampaignName = campaignName;
                if(campaignName && campaignName?.match(/\(([^)]+)\)/g)){
                 const matches = [...campaignName?.match(/\(([^)]+)\)/g)];
                 updatedCampaignName = `${campaignName?.split("(")[0]} ${matches[matches.length - 1]}` 
                }
                campaign.vl = getVlBasedOnCampType(campaignTypeCode);
               //  debugger
               // XXX
                let priority;
                if (props.list.sortOrder) {
                 priority = props.list.sortOrder.indexOf(Number(campaign.campaign_type_id)) + 1;
                }
                if( priority && priority === 0) { priority = 1; }
                const tooltipContent = `No Type campaigns are "untyped" and won't deliver messages with the new script or SDK; this campaign should be ended and a new one started with a type`;
                const campaignTypeLabel = props.isMultiCampaignEnabled && (legacyCssClass ? <Tooltip title={ tooltipContent }>
                     <span className={`campaign-type-label ${legacyCssClass}`}>
                         <WarningOutlined /> { campaignType }
                     </span>
                   </Tooltip> 
                   : <span className='campaign-type-label'>{ campaignType }</span>);
 
                return(
                  <>
                  <Draggable isDragDisabled={false} direction="vertical" key={campaign.id} draggableId={campaign.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="campaign-card"
                      >
                        {props.isMultiCampaignEnabled && campaignTypeCode !== 0 && <div className="campaign-priority"><span>Priority { priority }</span></div>}
                        <div className="campaign-details"> 
                        <div {...provided.dragHandleProps}>
                          <div className="campaign-title">
                            <span className="campaign-name" title={updatedCampaignName}>{updatedCampaignName}</span>
                          </div>
                          <Divider/>
                          <div className="campaign-card-details">
                            <div className="campaign-details-block">
                              <div className="detail-1">
                               <span className="date-label title">START DATE & TIME</span>
                               <span className="date-value value">{moment(campaign.created_at).format('YYYY-MM-DD HH:mm')}</span>
                              </div>
                              {campaignTypeLabel && <div className="detail-1">
                               <span className="date-label title">COMPLIANCE TYPE</span>
                               <span className="date-value value">{ campaignTypeLabel }</span>
                              </div>}
                            </div>
                          </div>
                        </div>
                        <div className="action-container">
                          <span onClick={() => props.onClickCampaign(campaign, index)} className="link">View More</span>
                          <div className="action-buttons">
                            <Tooltip title="Stop Campaign"><div onClick={(e) => confirmCampaignEndAction(campaign)}><SVG src={stopIcon} /></div></Tooltip>
                            <Tooltip title="Refresh Campaign"><div onClick={() => refreshCampaign(campaign)} ><SVG src={refreshIcon}/></div></Tooltip>
                            <Tooltip title={`Clone to ${diffEnv} Campaign`}><div onClick={() => showCloneModal(campaign,props.list.id)}><SVG src={cloneIcon} /></div></Tooltip>
                          </div>
                        </div>
                       </div>  
                      </div>
                    )}
                  </Draggable>
                  </>
                )
              })}
              { provided.placeholder }
            </div>
          )}
        </Droppable>
  );
}

export default CampaignsList;