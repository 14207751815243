import 'whatwg-fetch';
import { fromJS, Range, List } from 'immutable';
import moment from 'moment';
import { checkStatus } from './helper.js';

import { AdblockRecord } from '../records/adblock_records.js';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export function getAndParseAdblockGraphData(data) {
  const formattedData = data.update('start_date', start_date => start_date.format('YYYY-MM-DD'))
                            .update('end_date', end_date => end_date.format('YYYY-MM-DD'))
                            .toMap()
                            .filter(v => List.isList(v) ? v.size : v)
                            .toJS();
  const granularity = data.get('granularity');
  const geo = data.get('geo');

  const filters = {};
  if (formattedData.client_domain) {
    filters.client_domain = formattedData.client_domain;
  }
  if (formattedData.geo) {
    filters.geo = geo;
  }

  return pageViewQuery(formattedData.start_date, formattedData.end_date, filters, formattedData.traffic_type, 'adblocker_detected', 'pageviews', formattedData.group_by, granularity)
}

async function pageViewQuery(start_date, end_date, filters, traffic_type, segment_by, pageview_type, group_by, granularity) {
  Date.prototype.getJulian = function() {
    return (this / 86400000) - (this.getTimezoneOffset() / 1440) + 2440587.5;
  }
  start_date = new Date(start_date.toString());
  end_date = new Date(end_date.toString());
  let endDateTime = new Date(new Date(end_date.toString()).setHours(23, 59, 59));
  endDateTime.setDate(endDateTime.getDate() + 1)
  
  const startTs = start_date.getTime();
  const endTs = endDateTime.getTime();

  if (!group_by && (end_date.getJulian() - start_date.getJulian()) <= 7) {
    group_by = 'ymdh';
  } else {
    group_by = 'ymd';
  }

  if (segment_by === '') {
    segment_by = null;
  }
  let queryToAppend = `&groupBy=${group_by}`;
  // if not filters[:client_domain].nil?
  if (filters.client_domain) {
    if (typeof filters.client_domain[0] === 'string') {
      const domain = filters.client_domain.replace(/^\*/, '%');
      queryToAppend = queryToAppend + `&domain=${domain}`;
    } else {
      const domains = (filters.client_domain[0].values.map(d => d.replace(/^\*/, '%'))).toJSON();
      queryToAppend = queryToAppend + `&domains=${domains}`;
    }
  }

  if (filters.geo) {
    queryToAppend = queryToAppend + `&geo=${filters.geo}`;
  }

  const result_hash = {
    results: {},
    segments: [],
  }

  let data;
  if (!segment_by) {
    result_hash.segments.push('Pageviews');
    data = await fetch(`${permissionsSvsBase}/rtdp/pageviews/series?startDate=${startTs}&endDate=${endTs}${queryToAppend}`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        return resp.data;
      })

  } else if (segment_by === 'adblocker_detected') {
    result_hash.segments.push("Adblocker");
    result_hash.segments.push("Total Pageviews");

    data = await fetch(`${permissionsSvsBase}/rtdp/pageviews/series?startDate=${startTs}&endDate=${endTs}${queryToAppend}`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        return resp.data;
      })
  } else if (segment_by === 'geo') {
    data = await fetch(`${permissionsSvsBase}/rtdp/pageviews/series_by_geo?startDate=${startTs}&endDate=${endTs}${queryToAppend}`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        return resp.data;
      })
  }

  data.forEach(point => {
    let i = 0;
    const timestamp = point[i]
    i += 1;
    let geo;
    if (segment_by === 'geo') {
      geo = point[i]
      i += 1;
    }
    const desktop = point[i];
    i += 1;
    const mobile = point[i];
    i += 1
    const abp_desktop = point[i];
    i += 1
    const abp_mobile = point[i];

    let total_pageviews;
    let total_adblock;
    if (!segment_by) {
      if (traffic_type === 'desktop') {
        total_pageviews = desktop
      } else if (traffic_type === 'mobile') {
        total_pageviews = mobile
      } else {
        total_pageviews = (desktop + mobile)
      }

      result_hash.results[timestamp] = {
        Pageviews: total_pageviews
      }
    } else if (segment_by === 'adblocker_detected') {
      if (traffic_type === 'desktop') {
        total_pageviews = desktop;
        total_adblock = abp_desktop;
      } else if (traffic_type === 'mobile') {
        total_pageviews = mobile
        total_adblock = abp_mobile
      } else {
        total_pageviews = (desktop + mobile)
        total_adblock = (abp_desktop + abp_mobile)
      }

      result_hash.results[timestamp] = {};
      result_hash.results[timestamp][result_hash.segments[0]] = total_adblock;
      result_hash.results[timestamp][result_hash.segments[1]] = total_pageviews;

    } else if (segment_by === 'geo') {
      if (!result_hash.segments.indcludes(geo)) {
        result_hash.segments.push(geo);
      }

      if (traffic_type === 'desktop') {
        total_pageviews = desktop
      } else if (traffic_type === 'mobile') {
        total_pageviews = mobile
      } else {
        total_pageviews = (desktop + mobile)
      }

      if (!result_hash.results.timestamp) {
        result_hash.results.timestamp = {}
      }
      result_hash.results.timestamp = Object.assign(result_hash.results.timestamp, {geo: total_pageviews});
    }
  })

  // If segmenting by geo, make sure each segment has a value
  result_hash.segments.forEach(segment => {
    for (const [timestamp, values] of Object.entries(result_hash.results)) {
      if (!values[segment]) {
        values[segment] = 0
      }
    }
  })

  return parseGraphData(fromJS(result_hash), granularity)
}


function reduceValues(originalMap, accumulatedValue) {
  return originalMap
    .valueSeq()
    .map(
      (data) => data.reduce((total, value) => total + value.get(accumulatedValue), 0),
    )
    .toList();
}

function populateTableData(dates, blockedPVs, nonBlockedPVs, totalPVs, blockRate) {
  const ids = Range(0, dates.size);

  return dates.zipWith(
    (date, blockedPV, nonBlockedPV, totalPV, blockRate, id) => ({
      'dates': date,
      'blockedPVs': blockedPV,
      'nonBlockedPVs': nonBlockedPV,
      'totalPVs': totalPV,
      'blockRate': Number(blockRate),
      'id': id,
    }),
    blockedPVs,
    nonBlockedPVs,
    totalPVs,
    blockRate,
    ids,
  ).toList();
}

function groupGraphData(sortedGroupedByDates) {
  const dates = sortedGroupedByDates
    .keySeq()
    .map((date) => date.format('MMM D, YYYY'))
    .toList();
  const blockedPVs = reduceValues(sortedGroupedByDates, 'Adblocker');

  const totalPVs = reduceValues(sortedGroupedByDates, 'Total Pageviews');

  const nonBlockedPVs = blockedPVs.zipWith(
    (blockedPV, totalPV) => (
      totalPV - blockedPV
    ),
    totalPVs,
  );

  const blockRate = blockedPVs.zipWith(
    (blockedPV, totalPVs) => (
      isNaN((blockedPV/totalPVs)) ? '0.00' : ((blockedPV/totalPVs) * 100).toFixed(2)
    ),
    totalPVs,
  );

  const tableData = populateTableData(dates, blockedPVs, nonBlockedPVs, totalPVs, blockRate);

  return AdblockRecord({
    tableData,
  });
}

function parseGraphData(rawData, granularity) {
  const graphData = rawData.get('results');

  const groupedByDates = graphData.groupBy(
    (k, timestamp) => moment(Number (timestamp)).utc().startOf(granularity),
  );

  const sortedGroupedByDates = groupedByDates.sortBy((v, timestamp) => {
    return timestamp;
  });
  const result = groupGraphData(sortedGroupedByDates);
  return result;
}
