
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import { EllipsisOutlined } from '@ant-design/icons';

import { Dropdown, Menu, Collapse } from 'antd';
import { Button, Input, message, Modal } from '../../../../styleguide';

import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import { fromJS } from 'immutable';

import { LIST_OF_STEP_TYPES, systemKeyVals } from './ScenarioConstants.js';
import SortableRowList from './SortableRowList';
import PopoverButton from '../../../common/PopoverButton.jsx';
import WarningMessage from '../../../common/WarningMessage.js.jsx';
import CustomIcon from '../../../common/CustomIcon.js.jsx';
import ConfirmCancelModal from '../../../common/ConfirmCancelModal';

import { 
  deleteScenario, 
  deleteSiteGroupScenario, 
  updateScenario, 
  updateSiteGroupScenario, 
  createSiteGroupScenario 
} from '../../../../actions/scenario_actions';
import { ScenarioV2 as ScenarioV2Record, ScenarioRowRecord } from './components/steps.js';
import { Message } from '../../../../records/message_records.js';
import { getParameterByName } from '../../../utils';
import { getSiteGroups } from '../../../../actions/site_actions';
import { dialogueTypes } from '../../../../constants.js';

const { Panel } = Collapse;

const SortableRowContainer = SortableContainer(SortableRowList);

export class ScenarioV2 extends React.Component {
  static propTypes = {
    scenario: PropTypes.instanceOf(ScenarioV2Record).isRequired,
    messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    displayInCampaigns: PropTypes.bool,
    active: PropTypes.bool,
    duplicateScenario: PropTypes.func,
    updateScenario: PropTypes.func,
    deleteScenario: PropTypes.func,
    altpay: PropTypes.bool.isRequired,
    samba: PropTypes.bool.isRequired,
    welect: PropTypes.bool,
    consent: PropTypes.bool.isRequired,
    consentV2: PropTypes.bool.isRequired,
    consentCCPA: PropTypes.bool.isRequired,
    consentUSNAT: PropTypes.bool.isRequired,
    consentPreferences: PropTypes.bool.isRequired,
    activeKey: PropTypes.string,
    onShowMessagePreview: PropTypes.func,
    createSiteGroupScenario: PropTypes.func,
    getSiteGroups: PropTypes.func,
    currentUserFeatures: PropTypes.arrayOf(PropTypes.string),
    isSPUser: PropTypes.bool
  };

  static defaultProps = {
    displayInCampaigns: false,
    active: false,
    duplicateScenario: _.noop,
    updateScenario: _.noop,
    deleteScenario: _.noop,
    welect: false,
    activeKey: null,
    onShowMessagePreview: _.noop,
    createSiteGroupScenario: _.noop,
    getSiteGroups: _.noop,
  };

  state = {
    editing: false,
    jsonExpanded: false,
    scenarioEdited: this.props.scenario,
    warningMessagePresent: false,
    copyMessages: false,
    dropdownVisible: false,
    activeKey: this.props.activeKey,
    modalVisible: false,
  };

  componentWillReceiveProps(newProps) {
    if (newProps.scenario && !newProps.pending) {
      this.setState({ scenarioEdited: newProps.scenario});
    }
  }

  componentDidMount() {
    if (this.props.activeKey !== null) {
      this.setState({
        editing: true,
      });
    }
  }

  onChecked = (checked) => {
    this.setState({
      copyMessages: checked,
    });
  };

  onStepSelect = () => {
    const updated = this.state.scenarioEdited.set('description', event.target.value);
    this.setState({ scenarioEdited: updated });
  }

  onChange = (rowIndex, data) => {
    let steps = this.state.scenarioEdited.get('steps');
    steps = steps.setIn([rowIndex, 'rowData'], data);

    const updated = this.state.scenarioEdited.set('steps', steps);
    this.setState({ scenarioEdited: updated });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const rows = this.state.scenarioEdited.steps.toArray();
    const newSteps = fromJS(arrayMove(rows, oldIndex, newIndex));
    const newScenarioEdited = this.state.scenarioEdited.set('steps', newSteps);

    this.setState({
      scenarioEdited: newScenarioEdited,
    });
  };

  toggleWarningMessage = () => {
    if (getParameterByName('site_group_id', location)) {
      this.setState({ warningMessagePresent: !this.state.warningMessagePresent });
    } else {
      this.props.getSiteGroups(this.props.accountId, getParameterByName('site_id', location)).then((siteGroups) => {
        debugger
        if (siteGroups.size) {
          const siteGroupNames = siteGroups.map(sg => sg.name).join(', ').trim();

          Modal.error({
            title: 'Error',
            content: `Scenario ${ this.props.scenario.description } cannot be deleted because the site is part of site group ${ siteGroupNames }.`,
            className: 'site-group-error',
          });
        } else {
          this.setState({ warningMessagePresent: !this.state.warningMessagePresent });
        }
      });
    }
  };

  handleAddStep = () => {
    const updatedSteps = this.state.scenarioEdited.steps.push(new ScenarioRowRecord());
    const updated = this.state.scenarioEdited.set('steps', updatedSteps);
    this.setState({ scenarioEdited: updated });
  };

  handleDeleteStep = (rowIndex) => {
    const updatedSteps = this.state.scenarioEdited.steps.delete(rowIndex);
    const updated = this.state.scenarioEdited.set('steps', updatedSteps);
    this.setState({ scenarioEdited: updated });
  }

  handleDuplicateScenario = (name) => {
    const scenarios = this.props.draftScenarios;
    return scenarios.find(s => s.description === name);
  } 

  handleSave = () => {
    const scenario = this.state.scenarioEdited;

    const duplicateScenario = this.handleDuplicateScenario(scenario.description);
    if(duplicateScenario && duplicateScenario.id != scenario.id) {
      message.warning(`Scenario with name '${scenario.description}' already exist. Please update existing or new scenario name.`);
      return;
    }

    const { renderActiveCampaignWarning } = this.props;

    let error = false;
    scenario.steps.forEach((row, i) => {
      if (row.rowData.last().category !== 'message') {
        const index = i + 1;
        message.error('Row ' + index + ' must end with message');
        error = true;
      }
    });
    const updatedSteps = scenario.steps.filter(step => step.rowData.size);
    const scenarioToBeSaved = scenario.set('steps', updatedSteps);
    if (!error) {
      message.success('Your scenario has been saved!');
      const siteGroupId = getParameterByName('site_group_id', location);
      if (siteGroupId) {
        this.props.updateSiteGroupScenario(siteGroupId, this.props.accountId, scenarioToBeSaved);
      } else {
        this.props.updateScenario(scenarioToBeSaved);
      };

      renderActiveCampaignWarning(scenario.id, dialogueTypes.SCENARIO);
    }
  };

  handleDelete = (e) => {
    e.stopPropagation();
    const siteGroupId = getParameterByName('site_group_id', location);
    if (siteGroupId) {
      this.props.deleteSiteGroupScenario(siteGroupId, this.props.accountId, this.props.scenario);
    } else {
      const { id , description, site_id } = this.props.scenario
      this.props.deleteScenario(id , description, site_id);
    }
  };

  handleDescriptionChange = (event) => {
    const updated = this.state.scenarioEdited.set('description', event.target.value);
    this.setState({ scenarioEdited: updated });
  };

  toggleEditing = () => {
    this.setState({ editing: !this.state.editing });
  }

  closeCollapse = () => {
    ReactDOM.findDOMNode(this.collapse).getElementsByClassName('ant-collapse-header')[0].click();
    this.setState({
      scenarioEdited: this.props.scenario,
    });
  }

  handleCancelEditing = () => {
    if (this.state.scenarioEdited !== this.props.scenario) {
      this.setState({
        modalVisible: true,
      });
    } else {
      this.closeCollapse();
    }
  };

  handleVisibleChange = (flag) => {
    this.setState({ dropdownVisible: flag });
  }

  handleContinueWithoutSaving = () => {
    this.closeCollapse();
    this.setState({ modalVisible: false });
  }

  handleGoBackToScenario = () => {
    this.setState({ modalVisible: false });
  }

  copyScenario = () => {
    const originSceanrioDescription = this.props.scenario.get('description');
    const newScenarioDescription = originSceanrioDescription + ' (copy)';
    const duplicateScenario = this.handleDuplicateScenario(newScenarioDescription);
    if(duplicateScenario) {
      message.warning(`New scenario name will be '${newScenarioDescription}' which is already exist. Please update name of '${originSceanrioDescription}' or '${newScenarioDescription}' scenario.`);
      return;
    }

    const siteGroupId = getParameterByName('site_group_id', location);
    if (siteGroupId) {
      this.props.createSiteGroupScenario(this.props.accountId, siteGroupId, this.props.scenario.set('description', newScenarioDescription));
    } else {
      this.props.duplicateScenario(this.props.scenario, {copyMessages: this.state.copyMessages });
    }
  }

  render() {
    let warningMessage;
    if (this.state.warningMessagePresent) {
      warningMessage = (
        <WarningMessage
          handleDelete={ this.handleDelete }
          name={ this.state.scenarioEdited.description }
          handleCancel={ this.toggleWarningMessage }
        />
      );
    }

    const description = this.state.editing ? (
      <Input
        className='v2-description'
        value={ this.state.scenarioEdited.description }
        onChange={ this.handleDescriptionChange }
        disabled={ this.props.readOnly }
      />
    ) : null;

    let switchOptions;
    if (!getParameterByName('site_group_id', location)) {
      switchOptions = [{
        isChecked: this.state.copyMessages,
        onChecked: this.onChecked,
        popupText: 'Copy with Messages',
        disabled: false,
      }];
    }

    let editDropdownMenu;
    if (!this.props.readOnly) {
      const editMenu = (
        <Menu className="buttons-group">
          <Menu.Item>
            <PopoverButton
              onClick={ this.copyScenario }
              switchOptions={ switchOptions }
              type={ PopoverButton.types.COPY }
            />
          </Menu.Item>
          <Menu.Item>
            <CustomIcon
              type={ CustomIcon.types.DELETE }
              onClick={ this.toggleWarningMessage }
              tooltip="delete"
            />
          </Menu.Item>
        </Menu>
      );

      editDropdownMenu = (
        <Dropdown
          className='edit-dropdown-menu'
          overlay={editMenu}
          onOpenChange={ this.handleVisibleChange }
          visible={ this.state.dropdownVisible }
        >
          <EllipsisOutlined
            style={{
              float: 'right',
            }} />
        </Dropdown>
      );
    }

    const panelClass = 'scenario-v2';

    const dateCreated = (
      <div className='date-created'>
        {this.props.scenario.created_at.format('MMM-D-YYYY')}
      </div>
    );

    const header = (
      <div className="v2-header">
        { dateCreated }
      </div>
    );

    const addCancelSaveButtons = (this.state.editing && !this.props.readOnly) ? (
      <div className="v2-buttons">
        <Button
          className='add'
          onClick={ this.handleAddStep }
        >
          + Add a step
        </Button>
        <div className='cancel-save-container'>
          <Button
            className='cancel'
            onClick={ this.handleCancelEditing }
          >
            Close
          </Button>
          <Button
            className='save'
            onClick={ this.handleSave }
          >
            Save
          </Button>
        </div>
      </div>
    ) : null;

    let types = LIST_OF_STEP_TYPES;
    if (!this.props.altpay) {
      types = types.filterNot(step => step.includes('AltPay'));
    }
    if (!this.props.samba) {
      types = types.filterNot(step => step.includes('Samba'));
    }
    if (!this.props.welect) {
      types = types.filterNot(step => step.includes('Welect'));
    }
    if (!this.props.consent) {
      types = types.filterNot(step => /ConsentGate$/.test(step));
    }
    if (!this.props.consentV2) {
      types = types.filterNot(step => step.includes('ConsentGateV2'));
    }
    if (!this.props.consentCCPA) {
      types = types.filterNot(step => step.includes('ConsentGateCCPA'));
    }
    if (!this.props.consentUSNAT) {
      types = types.filterNot(step => step.includes('ConsentGateUSNAT'));
    }
    if (!this.props.consentPreferences) {
      types = types.filterNot(step => step.includes('ConsentGatePreferences'));
    }

    if (!this.props.isSPUser) {
      Object.entries(systemKeyVals)
        .forEach(([key, { featureFlag }]) => {
          if(featureFlag && !this.props.currentUserFeatures.includes(featureFlag)) {
            types = types.filterNot(step => step.includes(`:${key}`));
          }
        });
    }

    const scenarioOutput = this.state.scenarioEdited.steps.size ? (
      <SortableRowContainer
        axis='y'
        pressDelay={ 100 }
        useDragHandle={ this.state.editing ? false : true }
        onSortEnd={ this.onSortEnd }
        steps={ this.state.scenarioEdited.steps }
        editing={ this.state.editing }
        messages={ this.props.messages }
        onStepSelect={ this.onStepSelect }
        onChange={ this.onChange }
        deleteRow={ this.handleDeleteStep }
        types={ types }
        onShowMessagePreview={ this.props.onShowMessagePreview }
        readOnly={ this.props.readOnly }
      />
    ) : (
      <div className='no-scenario'>
        <p>
          No Scenario
        </p>
      </div>
    );

    const confirmCancelModal = (
      <ConfirmCancelModal
        modalVisible={ this.state.modalVisible }
        handleContinueWithoutSaving={ this.handleContinueWithoutSaving }
        handleGoBack={ this.handleGoBackToScenario }
      />
    );

    const panelBodyClass = classNames('panel-body', { 'scenario-not-active': this.props.displayInCampaigns && !this.props.active });
    return (
      <div className="scen-box">
        { description }
        { header }
        { confirmCancelModal }
        <Collapse
          ref={collapse => this.collapse = collapse}
          onChange={ this.toggleEditing }
        >
          <Panel
            key={ this.props.activeKey }
            header={ this.state.editing ? '' : this.state.scenarioEdited.description }
          >
            { editDropdownMenu }
            <div className={ panelClass }>
              <div className={ panelBodyClass }>
                { scenarioOutput }
              </div>
              { warningMessage }
              { addCancelSaveButtons }
            </div>
          </Panel>
        </Collapse>
      </div>
    );

  }
}

export default connect(function (){return {};}, { deleteScenario, deleteSiteGroupScenario, updateScenario, updateSiteGroupScenario, createSiteGroupScenario, getSiteGroups })(ScenarioV2);

