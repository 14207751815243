import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';
import { Partitionset as PartitionsetRecord } from '../../../records/partitionset_records.js';
import Partitionset from './Partitionset.js.jsx';
import DismissErrorModal from '../../common/DismissErrorModal';
import { CAMPAIGNS_MAP } from '../../../constants';

class PartitionsetList extends React.Component {
    static propTypes = {
        partitionsets: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(PartitionsetRecord)).isRequired,
    };

    state = { editId: '', editCampaignType: '' };

    setEditId = (editId = '') => this.setState({ editId });

    toggleCampaignError = (editCampaignType = '') => this.setState({ editCampaignType });

    getFilteredSceanrios = (campaignTypeId) => {
      return this.props.scenarios.filter(s => this.props.isMultiCampaignEnabled && campaignTypeId === 0 ? true : s.campaign_type_id === campaignTypeId);
    }

    render() {
       let messageType;
       const campaignType = CAMPAIGNS_MAP[this.state.editCampaignType] || '';
       if(campaignType) {
         messageType = campaignType.split(' ')[0] || '';
       }
       const errorMessage = (
         <div> 
           The <b>partition set</b> you are trying to <b>edit</b> has <b>more</b> than <b>one type of scenario or scenario type</b> that is <b>not compatible</b> with <b>{ campaignType }</b>. <br/><br/>
           To <b>assign</b> a campaign type <b>{ campaignType }</b> to this partition set, you need to ensure that the <b>scenarios</b> are <b>{ messageType } scenarios</b>.
         </div>
       );

       const dismissError = !!this.state.editCampaignType && <DismissErrorModal error={ errorMessage } isModalVisible={ !!this.state.editCampaignType } title="Update Campaign Type" handleCancel={() =>this.toggleCampaignError("") }/>

        return (
            <div id='partiton-sets'>
                {this.props.partitionsets.map((partitionset) =>
                    <Partitionset
                        key={partitionset.get('id')}
                        partitionset={partitionset}
                        {..._.omit(this.props, 'partitionsets', 'scenarios')}
                        renderActiveCampaignWarning={this.props.renderActiveCampaignWarning}
                        editId={this.state.editId}
                        setEditId={this.setEditId}
                        toggleCampaignError={this.toggleCampaignError}
                        scenarios={this.getFilteredSceanrios(partitionset.campaign_type_id)}
                    />
                )}
                {dismissError}
            </div>
        );
    }
}

export default PartitionsetList;