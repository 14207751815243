import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { Component } from 'react'
import { vendorCountType } from '../../../../../../../../constants'

import { Infotip, Button } from '../../../../../../../../styleguide'



class VendorCount extends Component {
  state = {
    show: false,
  }

  promiseInfo = {}

  show = async () => {
    return new Promise((resolve, reject) => {
      this.promiseInfo = { resolve, reject }
      let newState = {
        show: true,
      }

      this.setState(newState)
    })
  }

  hide = () => {
    try {
    } catch (_) {
      this.promiseInfo.reject()
    }
    this.setState({ show: false })
  }

  selectVendorsCountType = async (value) => {
    this.hide()
    return this.promiseInfo.resolve(vendorCountType(value))
  }

  render() {
    const { selectVendorsCountType } = this

    const menu = (
      <div>
        <div style={{ display: 'flex' }}>
          <Button type="primary" style={{ marginRight: 5 }} onClick={() => selectVendorsCountType(0)}>
            All Vendors
          </Button>
          <Button type="primary" onClick={() => selectVendorsCountType(1)}>
            IAB TCF Registered Vendors
          </Button>
        </div>
        <div
          style={{
            paddingTop: 5,
            display: 'flex',
            justifyContent: 'end'
          }}>
          <Button type="tertiary" size="small" onClick={this.hide}>
            Cancel
          </Button>
        </div>
      </div>
    )

    const tooltipText = 'As part of the requirements of the TCF, first layer messages are now required to display the number of third party Vendors (which may also include Vendors not participating in the Framework). Please add the vendor count widget to your description to meet this requirement. You can choose whether to count only IAB TCF Registered Vendors or all Vendors associated with your vendor list for the associated property in the options below.'
    return (
      <button style={{ width: 'auto' }} className="ql-vendor-count">
        <Infotip
          open={this.state.show}
          placement="bottomLeft"
          className="vc-infotip"
          title={(
            <div style={{ display: 'flex', alignItems: 'center', }}>
              <div style={{ marginRight: 5 }}>
                Select vendor type to count:
              </div>
              <Tooltip title={tooltipText}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          )}
          content={menu}
          trigger="click"
        >
          <span>VC</span>
        </Infotip>
      </button>
    )
  }
}

export default VendorCount