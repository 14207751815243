import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, OrderedSet, fromJS,List } from 'immutable';
import classNames from 'classnames';
import AntDesignWrapper from '../../common/AntDesignWrapper';
import CampaignOverview from './CampaignOverview';
import CampaignsOverviewTable from './CampaignsOverviewTable.js.jsx'
import CampaignAdd from './CampaignAdd.js.jsx'
import PageHeader from '../../common/PageHeader.js.jsx';
import CampaignStats from './stats-table/CampaignStats.js.jsx';
import CreateButton from '../../common/CreateButton.js.jsx';
import CustomButton from '../../common/CustomButton.js.jsx';
import Loading from '../../common/Loading.js.jsx';

import {
  getLiveStageCampaign,
  getLivePublicCampaign,
  getOldCampaigns,
  getCampaignStats,
  activateCampaign,
  activateSiteGroupCampaign,
  copyAndActivateCampaignInEnv,
  copyAndActivateSiteGroupCampaignInEnv,
  updateCampaignsViewState,
} from '../../../actions/campaign_actions';
import { getDraftScenarios, getPublicScenarios, getStageScenarios, getPublicOldScenarios, getStageOldScenarios } from '../../../actions/scenario_actions';
import { getPartitionsets } from '../../../actions/partitionset_actions';
import { getDraftMessages, getPublicMessages, getStageMessages, getPublicOldMessages, getStageOldMessages } from '../../../actions/message_actions';
import { getAllSites, getSiteGroup } from '../../../actions/site_actions';
import { Campaign } from '../../../records/campaign_records';
import { Message } from '../../../records/message_records';
import { Scenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2';
import { Partitionset } from '../../../records/partitionset_records';
import { STATUS_TYPES as STATUS, BACKEND_STATUS_TYPES, messageEndpoints } from '../../../constants';
import { getParameterByName, findMessageIdsFromScenario } from '../../utils.js';
import { Site } from '../../../records/site_records.js';
import SiteGroupSitesRedirect from './SiteGroupSitesRedirect';
import SitesRedirect from '../campaigns/SitesRedirect';
import { siteAndGroupLabel } from '../helper';
import SiteGroupRedirect from '../../common/SiteGroupRedirect';

class CampaignsPage extends React.Component {
  static propTypes = {
    route: PropTypes.shape({
      isMasq: PropTypes.bool.isRequired,
    }).isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    publicCampaign: PropTypes.instanceOf(Campaign),
    stageCampaign: PropTypes.instanceOf(Campaign),
    oldCampaigns: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Campaign)),
    statsData: ImmutablePropTypes.map.isRequired,
    graphData: ImmutablePropTypes.map.isRequired,
    draftMessages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    publicMessages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    stageMessages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    oldMessages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    draftScenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
    publicScenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
    stageScenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
    oldScenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
    partitionsets: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Partitionset)).isRequired,
    allSites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,

    getAllSites: PropTypes.func.isRequired,
    getLiveStageCampaign: PropTypes.func.isRequired,
    getLivePublicCampaign: PropTypes.func.isRequired,
    getOldCampaigns: PropTypes.func.isRequired,
    getPublicMessages: PropTypes.func.isRequired,
    getDraftMessages: PropTypes.func.isRequired,
    getStageMessages: PropTypes.func.isRequired,
    getPublicOldMessages: PropTypes.func.isRequired,
    getStageOldMessages: PropTypes.func.isRequired,
    getDraftScenarios: PropTypes.func.isRequired,
    getPublicScenarios: PropTypes.func.isRequired,
    getStageScenarios: PropTypes.func.isRequired,
    getPublicOldScenarios: PropTypes.func.isRequired,
    getStageOldScenarios: PropTypes.func.isRequired,
    getPartitionsets: PropTypes.func.isRequired,
    getCampaignStats: PropTypes.func.isRequired,
    activateCampaign: PropTypes.func.isRequired,
    activateSiteGroupCampaign: PropTypes.func.isRequired,
    copyAndActivateCampaignInEnv: PropTypes.func.isRequired,
    copyAndActivateSiteGroupCampaignInEnv: PropTypes.func.isRequired,
    getSiteGroup: PropTypes.func.isRequired,
  };

  static defaultProps = {
    publicCampaign: null,
    stageCampaign: null,
    oldCampaigns: null,
  };

  state = {
    campaignWithOpenStatsId: null,
    displayActivateMenu: false,
    activateError: null,
    v2DraftMessages: [],
    showPrivacyCheckModal:false,
    firstLayerMessages:List(),
    pmMessages:List()
  };

  componentDidMount() {
    window.scrollTo(0, this.props.viewState.topOffSet);

    this.getMessages();
    this.props.getAllSites();

    const siteGroupId = this.props.location.query.site_group_id;
    if (siteGroupId) {
      this.props.getSiteGroup(siteGroupId);
    }
    const redirectSiteGroupId = this.props.location.query.sitegroup_id;
    if(redirectSiteGroupId){
       this.props.getSiteGroup(redirectSiteGroupId);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.pendingRequestsMap) { 
      if (!newProps.pendingRequestsMap.get('campaigns')) {
        this.setState({ displayActivateMenu: false });
      }
    }
  }

  getMessages = (type) => {
    fetch(`${messageEndpoints.MESSAGES}?object_status=${BACKEND_STATUS_TYPES['draft']}&site_id=${this.props.location.query.site_id}`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(r => r.json()).then(messages => {
      this.setState({ v2DraftMessages: messages.data.message_list });
    }).catch(() => console.log('Error loading v2 draft messages'));
  };

  copyAndActivateInEnv = (currentEnv) => {
    const siteGroupId = this.props.location.query.site_group_id;
    if (siteGroupId) {
      this.props.copyAndActivateSiteGroupCampaignInEnv({ accountId: this.props.currentUser.accountId, siteGroupId, currentEnv, siteId: this.props.location.query.site_id });
    } else {
      this.props.copyAndActivateCampaignInEnv(currentEnv, this.props.location.query.site_id);
    }
  };

  handleSaveAndActivate = (env, campaignName, partitionsetId) => {
    const partitionset = this.props.partitionsets.find(p => p.id === partitionsetId);

    this.setState({ activateError: null })

    // ensure partitionset has partitions
    const partitions = partitionset.partitions;
    if (!partitions.size) {
      this.setState({ activateError: "Unable to publish the campaign - partitionset has no partitions" })
      return;
    }
    // ensure partitionset does not have unset scenarios
    const scenarioIds = partitions.map(partition => partition.scenario_id);
    const scenarios = scenarioIds.map(id => this.props.draftScenarios.find(s => s.id === id));

    if (scenarios.some(s => !s)) {
      this.setState({ activateError: "Unable to publish the campaign - partitionset has an unset scenario." });
      return;
    }
    // ensure partitionset does not have unset messages
    const messageIds = scenarios.map(s => {
      // have to deal with JSON format to support custom scenarios
      return findMessageIdsFromScenario(fromJS(JSON.parse(s.scenario_json)));
    }).flatten();

    /* Check to see if messages all exist */
    let missing = false;
    messageIds.forEach(mId => {
      if (this.props.draftMessages.find(m => m.id === mId) === undefined && this.state.v2DraftMessages.find(m => m.id === mId) === undefined) {
        missing = true;
      }
    })

    if (missing) {
      this.setState({ activateError: "Unable to publish the campaign - scenario has an unset message." });
      return;
    }

    const siteGroupId = this.props.location.query.site_group_id;
    if (siteGroupId) {
      this.props.activateSiteGroupCampaign({
        env,
        accountId: this.props.currentUser.accountId,
        name: campaignName,
        partitionsetId,
        siteGroupId,
        siteId: this.props.location.query.site_id
      });
    } else {
      this.props.activateCampaign({ 
        env, 
        siteId: this.props.location.query.site_id, 
        name: campaignName, 
        partitionsetId 
      });
    }

  };

  toggleOldCampaigns = () => {
    const siteId = this.props.location.query.site_id;
    updateCampaignsViewState(this.props.viewState.set('oldCampaignsOpen', !this.props.viewState.oldCampaignsOpen));

    this.props.getOldCampaigns(siteId);
    this.props.getPublicOldMessages(siteId);
    this.props.getStageOldMessages(siteId);
    this.props.getPublicOldScenarios(siteId);
    this.props.getStageOldScenarios(siteId);
  };

  showCampaignStats = (campaignId) => {
    if (!this.props.statsData.getIn([campaignId, 'value'])) {
      this.props.getCampaignStats(campaignId);
    }

    this.setState({ campaignWithOpenStatsId: campaignId });
  };

  handleCampaignStatsClosing = () => {
    this.setState({ campaignWithOpenStatsId: null });
  };

  toggleActivateMenu = () => {
    this.setState({ displayActivateMenu: !this.state.displayActivateMenu })
  };

  parseCampaignData = (campaign, messages) => {
    const partitionUuidToName = campaign.partition_set.partitions.reduce((map, partition) => {
      return map.set(partition.uuid, partition.name);
    }, Map({}));

    const messageIdToName = messages.reduce((map, message) => {
      return map.set(message.id, message.description);
    }, Map({}));
    return Map({
      name: campaign.description,
      partitionUuidToName: partitionUuidToName,
      messageIdToName: messageIdToName,
    });
  };

  redirectToGroup = () => {
    browserHistory.push(`${this.props.location.pathname}?site_id=${this.props.siteGroupRedirect.siteId}&site_group_id=${this.props.siteGroupRedirect.id}`);
  }

  goToMessages = (url) => {
    browserHistory.push(`/dialogue/${url}?site_id=` + this.props.location.query.site_id);
  }

  render() {
    let readOnlyPublic = false;
    let readOnlyAll = false;
    if (this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('public_and_stage_campaign_mgmt')) {
      readOnlyPublic = true;
      if (!this.props.currentUser.featureAccess.includes('stage_campaign_mgmt')) {
        readOnlyAll = true;
      }
    }

    const siteId = this.props.location.query.site_id;

    let domain;
    let siteGroupSitesWithErrors;
    if (!this.props.location.query.site_group_id) {
      domain = this.props.allSites.size ? this.props.allSites.find(s => s.id === parseInt(siteId)).domain : '';
    } else {
      domain = this.props.siteGroup && this.props.siteGroup.name;
      if (this.props.siteGroupSitesWithErrors.size) {
        siteGroupSitesWithErrors = (
          <SitesRedirect
            pageKind='campaigns'
            sites={this.props.siteGroupSitesWithErrors.map(options => this.props.allSites.find(s => s.id === options.get('siteId')))}
            isForErrors
          />
        )
      }
    }

    const oldCampaigns = this.props.oldCampaigns;
    const publicCampaign = this.props.publicCampaign;
    const stageCampaign = this.props.stageCampaign;

    const overviewTableProps = {
      showCampaignStats: this.showCampaignStats,
      campaignsStats: this.props.statsData,
      getCampaignStats: this.props.getCampaignStats,
      siteId,
      campaignsViewState: this.props.viewState,
      accountId: this.props.currentUser.accountId,
      isSPUser: this.props.isSPUser,
      masq: this.props.route.isMasq,
    }

    let campaignOverview;
    if (publicCampaign && !this.props.location.query.site_group_id) {
      campaignOverview = (
        <CampaignOverview
          campaign={publicCampaign}
          siteId={siteId}
          accountId={this.props.currentUser.accountId}
          graphData={this.props.graphData.get(publicCampaign.id) && this.props.graphData.getIn([publicCampaign.id, 'value']) && this.props.graphData.getIn([publicCampaign.id, 'value'])}
        />
      )
    }

    let publicCampaignDiv;
    if (publicCampaign) {
      publicCampaignDiv = (
        <div>
          {campaignOverview}
          <CampaignsOverviewTable
            campaigns={OrderedSet([publicCampaign])}
            messages={this.props.publicMessages}
            scenarios={this.props.publicScenarios}
            env="production"
            copyAndActivateInEnv={this.copyAndActivateInEnv}
            readOnly={readOnlyPublic || readOnlyAll}
            {...overviewTableProps}
          />
        </div>
      )
    }

    let stageCampaignDiv;
    if (stageCampaign) {
      stageCampaignDiv = (
        <div>
          <CampaignsOverviewTable
            campaigns={OrderedSet([stageCampaign])}
            messages={this.props.stageMessages}
            scenarios={this.props.stageScenarios}
            env="stage"
            copyAndActivateInEnv={this.copyAndActivateInEnv}
            readOnly={readOnlyAll}
            {...overviewTableProps}
          />
        </div>
      )
    }

    let siteGroupSiteList;
    if (!publicCampaign && !stageCampaign && this.props.location.query.site_group_id) {
      siteGroupSiteList = (
        <SiteGroupSitesRedirect pageKind='campaigns' />
      )
    }

    let noActiveCampaignsMessage;

    if (!publicCampaign && !stageCampaign) {
      noActiveCampaignsMessage = <p className="no-campaigns-message">There are no active campaigns.</p>
    }
    const showOldCampaigns = this.props.viewState.oldCampaignsOpen;

    const toggleOldCapmpaignsButton = (
      <CustomButton onClick={this.toggleOldCampaigns} disabled={showOldCampaigns && !this.props.oldCampaigns} type={CustomButton.types.PLAIN} className="toggle-old-campaigns">{showOldCampaigns ? "Hide" : "Show"} Ended Campaigns</CustomButton>
    )

    let oldCampaignsDiv;
    let noPreviousCampaignsMessage;

    if (showOldCampaigns) {
      if (oldCampaigns && oldCampaigns.size) {
        oldCampaignsDiv = (
          <div>
            <CampaignsOverviewTable
              campaigns={oldCampaigns}
              messages={this.props.oldMessages}
              scenarios={this.props.oldScenarios}
              campaignRowStats={oldCampaigns.stats}
              headerPresent={false}
              {...overviewTableProps}
            />
          </div>
        );
      } else if (this.props.pendingRequestsMap.get('campaigns')) {
        oldCampaignsDiv = <div className="loading"><p>Loading previous campaigns...</p></div>;
      } else {
        noPreviousCampaignsMessage = <p className="no-campaigns-message">There are no ended campaigns.</p>;
      }
    }

    let statsTable;
    const campaignWithOpenStatsId = this.state.campaignWithOpenStatsId;

    if (campaignWithOpenStatsId && this.props.statsData.getIn([campaignWithOpenStatsId, 'value'])) {
      let campaignsDataForStats;

      if (publicCampaign && publicCampaign.id === campaignWithOpenStatsId) {
        campaignsDataForStats = this.parseCampaignData(publicCampaign, this.props.publicMessages);
      } else if (stageCampaign && stageCampaign.id === campaignWithOpenStatsId) {
        campaignsDataForStats = this.parseCampaignData(stageCampaign, this.props.stageMessages);
      } else {
        const campaign = oldCampaigns.find(campaign => campaign.id === campaignWithOpenStatsId);
        campaignsDataForStats = this.parseCampaignData(campaign, this.props.oldMessages);
      }

      statsTable = <CampaignStats
        data={this.props.statsData.getIn([campaignWithOpenStatsId, 'value']).toJS()}
        campaignTitle={campaignsDataForStats.get('name')}
        partitionUuidToName={campaignsDataForStats.get('partitionUuidToName').toJS()}
        messageIdToName={campaignsDataForStats.get('messageIdToName').toJS()}
        handleCampaignStatsClosing={this.handleCampaignStatsClosing}
      />
    }

    let activateMenu;
    if (this.state.displayActivateMenu) {
      activateMenu = (
        <CampaignAdd
          siteId={siteId}
          scenarios={this.props.scenarios}
          messages={this.state.v2DraftMessages}
          partitions={this.props.partitionsets}
          hideActivateMenu={this.toggleActivateMenu}
          handleSaveAndActivate={this.handleSaveAndActivate}
          activateError={this.state.activateError}
          stageOnly={readOnlyPublic && !readOnlyAll}
        />
      )
    }

    const containerClassName = classNames('campaigns-container', { 'open-activate': this.state.displayActivateMenu });

    let loading;
    if (this.props.pendingRequestsMap.some(request => request)) {
      loading = <Loading />;
    }

    let createCampaignButton;
    if (!readOnlyAll) {
      createCampaignButton = (
        <CreateButton
          onClick={this.toggleActivateMenu}
          disabled={this.state.readOnly || this.state.displayActivateMenu}
        >
          NEW!!!!!!!
        </CreateButton>
      )
    }
    let backToSitesGroup;
    if(this.props.siteGroupRedirect){
        backToSitesGroup = <SiteGroupRedirect redirectToGroup={this.redirectToGroup} siteGroup={this.props.siteGroupRedirect}/>;
    }
    return (
      <AntDesignWrapper>
        <div className={containerClassName}>
          {loading}
          {backToSitesGroup}
          <PageHeader title={domain} label={siteAndGroupLabel(this.props.location.query.site_group_id)}/>
          {createCampaignButton}
          {activateMenu}
          {siteGroupSitesWithErrors}
          {publicCampaignDiv}
          {stageCampaignDiv}
          {noActiveCampaignsMessage}
          {toggleOldCapmpaignsButton}
          {oldCampaignsDiv}
          {noPreviousCampaignsMessage}
          {siteGroupSiteList}
          {statsTable}
        </div>
      </AntDesignWrapper>
    )
  }
}

const mapStateToProps = function (store) {

  const siteId = parseInt(getParameterByName('site_id', window.location));

  const messages = store.messageState.getIn(['messages', 'value']).filter(m => m.site_id === siteId);
  const scenarios = store.scenarioState.getIn(['scenarios', 'value']).filter(s => s.site_id === siteId);
  const campaigns = store.campaignState.getIn(['campaigns', 'value']).filter(c => c.site_id === siteId);
  const partitionsets = store.partitionsetState.getIn(['partitionsets', 'value']).filter(c => c.site_id == siteId);
  
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    allSites: store.siteState.getIn(['sites', 'value']),
    publicCampaign: campaigns.filter(c => c.status === STATUS.PUBLIC && c.live).sortBy(c => new Date(c.created_at).getTime()).last(),
    stageCampaign: campaigns.filter(c => c.status === STATUS.STAGE && c.live).sortBy(c => new Date(c.created_at).getTime()).last(),
    oldCampaigns: campaigns.filter(c => c.deleted_at),
    draftMessages: messages.filter(m => m.status === STATUS.DRAFT),
    publicMessages: messages.filter(m => m.status === STATUS.PUBLIC),
    stageMessages: messages.filter(m => m.status === STATUS.STAGE),
    oldMessages: messages.filter(m => m.status !== STATUS.DRAFT && !m.live),
    draftScenarios: scenarios.filter(s => s.status === STATUS.DRAFT),
    publicScenarios: scenarios.filter(s => s.status === STATUS.PUBLIC),
    stageScenarios: scenarios.filter(s => s.status === STATUS.STAGE),
    oldScenarios: scenarios.filter(s => s.status !== STATUS.DRAFT && !s.live),
    partitionsets,
    scenarios,
    statsData: store.campaignState.get('statsData'),
    graphData: store.campaignState.get('graphData'),
    viewState: store.campaignState.get('viewState'),
    isSPUser: store.accountState.get('isSPUser'),
    siteGroup: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('site_group_id', window.location)),
    siteGroupRedirect: store.siteState.getIn(['siteGroups', 'value']).find(sg => sg.id === getParameterByName('sitegroup_id', window.location)),
    siteGroupSitesWithErrors: store.siteState.get('siteGroupSitesWithErrors'),
    pendingRequestsMap: new Map({
      allSites: store.siteState.getIn(['sites', 'pending']),
      campaigns: store.campaignState.getIn(['campaigns', 'pending']),
      messages: store.messageState.getIn(['messages', 'pending']),
      scenarios: store.scenarioState.getIn(['scenarios', 'pending']),
      partitionsets: store.partitionsetState.getIn(['partitionsets', 'pending']),
      statsData: store.campaignState.get('statsData').map(data => data.pending).some(request => request),
      graphData: store.campaignState.get('graphData').map(data => data.pending).some(request => request),
    })
  };
}

export default connect(
  mapStateToProps, {
  getAllSites,
  getLiveStageCampaign,
  getLivePublicCampaign,
  getOldCampaigns,
  getDraftMessages,
  getPublicMessages,
  getStageMessages,
  getPublicOldMessages,
  getStageOldMessages,
  getDraftScenarios,
  getPublicScenarios,
  getStageScenarios,
  getPublicOldScenarios,
  getStageOldScenarios,
  getPartitionsets,
  getCampaignStats,
  activateCampaign,
  activateSiteGroupCampaign,
  copyAndActivateCampaignInEnv,
  copyAndActivateSiteGroupCampaignInEnv,
  getSiteGroup,
}
)(CampaignsPage);
