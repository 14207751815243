import * as types from './action_types';
import { actionCreator } from './helpers';
import { getListOfReportsAjax } from '../api/custom_report';

export const getListOfReports = actionCreator(
  getListOfReportsAjax,
  reportsListPending,
  reportsListLoaded,
  reportsListError,
);

function reportsListPending() {
  return {
    type: types.REPORTS_LIST_PENDING,
  };
}

function reportsListLoaded(data) {
  return {
    type: types.REPORTS_LIST_LOADED,
    data,
  };
}

function reportsListError(errorMessage) {
  return {
    type: types.REPORTS_LIST_ERROR,
    errorMessage,
  };
}

export function addRequestToSubmitted(request) {
  return {
    type: types.REQUEST_SUBMITTED,
    request,
  };
}
