import PropTypes from 'prop-types';
import React from 'react';

import MessageStep from './MessageStep';
import CommonNumCount from './CommonNumCount';
import { ShowMessageNTimesRecord } from '../../../../../scenario_constants.js';

export default class MessageShowNumTimes extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(ShowMessageNTimesRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleNumChange = (value) => {
    this.props.onChange(this.props.data.set('n', value));
  }

  render() {
    return (
      <MessageStep
        {...this.props}
        label={ 'Show Message ' + this.props.data.n + ' Times' }
      >
        { this.props.children }
        <CommonNumCount
          value={ this.props.data.n }
          onChange={ this.handleNumChange }
          label={ 'Show N Times' }
        />
      </MessageStep>
    );
  }
}
