import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as d3 from 'd3';
import { connect } from 'react-redux';

import useSearchParams from '../../dashboard/shared/useSearchParams';
import VendorDetails from './VendorDetails';
import VendorTraceNonDisclosedFilter, {
  allOptionsList as defaultDisclosureOptions,
} from './VendorTraceNonDisclosedFilter';
import VendorTracePrevalenceFilter from './VendorTracePrevalenceFilter';
import VendorTraceChartActions from './VendorTraceChartActions';
import { exportVendorDataFromVendorTrace } from '../../../../api/diagnose_dashboard/vendor_trace';
import DrawHierarchy from './DrawHierarchy';
import { isNodeWithinPrevalenceFilter, isTreeAfterConsent, useVendorTraceContext } from './ChartFiltersContext';
import VendorTraceTrackersFilter, { allOptionsList as defaultTraceTrackerOptions } from './VendorTraceTrackersFilter';

export const CHART_WIDTH_GAP = 200;

const VendorTraceChart = ({
  /* props */
  highlightFilter,
  consentTypeDescription,
  selectedVendorTraceFilters,
  consentTypeFilter,
}) => {
  const searchParams = useSearchParams();
  const {
    prevalenceLowPoint,
    vendorName,
    isTreeStateCondenced,
    vendorsData: filteredData,
    setCollapseNode,
    isBeforeActionConsentCategory,
  } = useVendorTraceContext();
  const ref = useRef();
  const wrapper = useRef();

  const [wrapperInner, setWrapper] = useState({
    scroll: 0,
    width: 600,
  });
  const [disclosedFilter, setDisclosedFilter] = useState(defaultDisclosureOptions);
  const [traceTracking, setTraceTracking] = useState(defaultTraceTrackerOptions);
  const [chartHeight, setChartHeight] = useState(1000);
  const [chartWidth, setChartWidth] = useState(600);
  const [scaleValue, setScaleValue] = useState(0.6);
  const [modalDetails, setModalDetails] = useState(null);

  const filtersSet = useMemo(
    () => ({
      prevalence_filter: [prevalenceLowPoint, 100],
      disclosure_highlight: disclosedFilter,
      tracking_highlight: traceTracking,
      vendor_name: vendorName,
      highlight_filter: highlightFilter,
      search_by_vendor_name_mode: isTreeStateCondenced,
      highlight: searchParams.key,
      highlightPriorToConsent: !isBeforeActionConsentCategory,
    }),
    [
      prevalenceLowPoint,
      disclosedFilter,
      traceTracking,
      vendorName,
      highlightFilter,
      isTreeStateCondenced,
      searchParams?.key,
      isBeforeActionConsentCategory,
      consentTypeFilter,
    ],
  );

  const handleUpdateTree = ({ width, height }) => {
    setChartHeight(height);
    setChartWidth(width);
    setWrapper({
      scroll: wrapper.current.scrollLeft,
      width: wrapper.current.clientWidth,
    });
  };

  const handleNodeExpand = (nodeData) => {
    setModalDetails(nodeData.attributes);
  };

  const refreshChart = () => {
    d3.selectAll('.chart #vendor-trace-svg > *').remove();
    if (filteredData && Object.keys(filteredData).length) {
      DrawHierarchy(filteredData, {
        ref: ref.current,
        chart: {
          gap: CHART_WIDTH_GAP,
        },
        scaleValue,
        filtersSet,
        onExpandNode: handleNodeExpand,
        onToggle: (mapKey) => setCollapseNode(mapKey),
        onScale: setScaleValue,
        onUpdateTreeSize: handleUpdateTree,
        isExpandable: (d) =>
          !!d.data.attributes.referred?.length &&
          !vendorName &&
          d.data.attributes.referred.some((refered) => isNodeWithinPrevalenceFilter(refered, prevalenceLowPoint)) &&
          (isBeforeActionConsentCategory || isTreeAfterConsent(d.data)),
      });
    }
  };

  useEffect(() => {
    refreshChart();
  }, [filteredData, highlightFilter, vendorName, disclosedFilter, traceTracking]);

  const handleDetailsClose = () => {
    setModalDetails(null);
  };

  const handleMoveLayers = (evt) => {
    const isPrevButton = evt.currentTarget.id === 'prev-layers-button';
    const currentScrollPosition = wrapper.current?.scrollLeft;
    const wrapperWidth = wrapper.current?.clientWidth - CHART_WIDTH_GAP;
    wrapper.current.scrollLeft = isPrevButton
      ? currentScrollPosition - wrapperWidth
      : currentScrollPosition + wrapperWidth;
  };

  const handleExport = async ({ vendor_id, vendor_name, map_key, trace_level }) => {
    await exportVendorDataFromVendorTrace({
      regions: selectedVendorTraceFilters.region,
      dateFrom: selectedVendorTraceFilters.scannedPeriod.dateFrom,
      dateTo: selectedVendorTraceFilters.scannedPeriod.dateTo,
      scheduledScanId: selectedVendorTraceFilters.scannedUrl,
      consentFilter: consentTypeFilter.split(','),
      vendorId: vendor_id,
      vendorName: vendor_name,
      mapKey: map_key,
      traceLevel: trace_level,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (wrapper.current) {
        setWrapper({
          scroll: wrapper.current.scrollLeft,
          width: wrapper.current.clientWidth,
        });
      }
    };

    if (wrapper?.current) {
      wrapper.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapper?.current) {
        wrapper.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const isPrevDisabled = chartWidth <= wrapperInner.width || !wrapperInner.scroll;
  const isNextDisabled = chartWidth <= wrapperInner.width || wrapperInner.scroll + wrapperInner.width >= chartWidth;

  const highlightFilterComponent =
    {
      is_disclosed: (
        <VendorTraceNonDisclosedFilter checkedList={disclosedFilter} onChangeDisclosed={setDisclosedFilter} />
      ),
      is_tracking_detected: <VendorTraceTrackersFilter checkedList={traceTracking} onChange={setTraceTracking} />,
    }?.[highlightFilter] || null;

  return (
    <div>
      <div className={'vendorTraceTreeDescriptionWrapper'}>
        <div className={'vendorTraceTreeDescription'}>{consentTypeDescription}</div>
        <div className={'show-more-layers-buttons'}>
          <Button
            className={'show-more-layers-button'}
            onClick={handleMoveLayers}
            id={'prev-layers-button'}
            shape="circle"
            disabled={isPrevDisabled}
            icon={<LeftOutlined />}
          />
          <Button
            className={'show-more-layers-button'}
            onClick={handleMoveLayers}
            id={'next-layers-button'}
            shape="circle"
            disabled={isNextDisabled}
            icon={<RightOutlined />}
          />
        </div>
      </div>
      <div id="vendorTraceTreeWrapper" ref={wrapper}>
        <div
          className="chart"
          style={{
            height: chartHeight,
            width: chartWidth,
            position: 'relative',
          }}
        >
          <VendorTracePrevalenceFilter isDisabled={vendorName} />
          {highlightFilterComponent}
          <svg width={'100%'} height={'100%'} ref={ref} id={'vendor-trace-svg'} />
          <VendorTraceChartActions scaleValue={scaleValue} selectedVendorName={vendorName} />
          <VendorDetails
            data={modalDetails}
            property={selectedVendorTraceFilters?.propertyName}
            onClose={handleDetailsClose}
            onExport={handleExport}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    selectedVendorTraceFilters: store.diagnoseVendorTraceState.getIn(['vendorTraceFilters', 'value']),
    consentTypeFilter: store.diagnoseVendorTraceState.getIn(['consentTypeFilter', 'value']),
  };
};

export default connect(mapStateToProps, null)(VendorTraceChart);
