import React from 'react';
import BooleanStep from './BooleanStep';

export default class SessionSearchReferrerGate extends React.Component {
  render() {
    return (
      <BooleanStep
        {...this.props}
        labelText={ 'Session Search Referrer Gate' }
      />
    );
  }
}
