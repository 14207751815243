import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Modal, Select, message } from '../../../../styleguide';

const CopyJsModal = ({
  visible,
  onClose,
  pms,
  messageSubcategory,
}) => {
  const [selectedPmId, setSelectedPmId] = useState('');

  useEffect(setToDefault, [pms.length]);

  function setToDefault() {
    if (pms.length) {
      setSelectedPmId(pms[0].id);
    };
  };

  const createSnippet = () => {
      return messageSubcategory === "us_pm" ? `window._sp_.usnat.loadPrivacyManagerModal(${selectedPmId})` : `window._sp_.loadPrivacyManagerModal(${selectedPmId})`;
  }

  const copySnippetToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = createSnippet();
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success('Copied to clipboard');
  };

  return (
    <Modal
      title='Copy snippet to clipboard'
      visible={visible}
      onCancel={() => {
        onClose();
        setToDefault()
      }}
      footer={null}
    >
      <div className="url-modal-container">
        <div>
          <label>Select Privacy Manager</label>
          <Select value={selectedPmId} onChange={setSelectedPmId}>
            {pms.map(({ id, description }) => (
              <Select.Option key={id} value={id}>
                {description}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <label>Generated Snippet</label>
          <div className="url-container">
            <div>
              {pms.length ? createSnippet(selectedPmId) : ''}
            </div>
            <FontAwesomeIcon
              icon={faCopy}
              size="lg"
              style={{ cursor: 'pointer' }}
              onClick={copySnippetToClipboard}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CopyJsModal;