/* eslint-disable no-alert */
require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch, {
  retries: 2,
  retryDelay: 500,
});

import {
  checkStatus,
  buildUrlWithVersion,
  DIAGNOSE_DASHBOARD_BASEURL,
  exportCSVResponse,
  getVersion,
} from '../helper.js';
import { fetchRetryWrapper } from './helpers.js';

const VENDORS_AFTER_OPT_OUT_DATA = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/`;
const VENDORS_AFTER_OPT_OUT_FILTERS = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/filters`;
const VENDORS_AFTER_OPT_OUT_EXPORT = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/export`;
const VENDORS_AFTER_OPT_OUT_VENDOR_DATA = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/vendor`;
const VENDORS_AFTER_OPT_OUT_VENDOR_WEBSITE = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/website`;
const VENDORS_AFTER_OPT_OUT_VENDOR_EXPORT = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/website/export`;
const VENDORS_AFTER_OPT_OUT_COOKIE_DATA = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/cookies`;
const VENDORS_AFTER_OPT_OUT_COOKIE_FILTERS = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/cookies/filters`;
const VENDORS_AFTER_OPT_OUT_COOKIE_EXPORT = `${DIAGNOSE_DASHBOARD_BASEURL}/details/after-opt-out/cookies/export`;


function getParamsForGivenUrl(url, params) {
  const baseParams = {
    date_from: params.dateFrom,
    date_to: params.dateTo,
    region: params.region || '',
  };
  const commonParams = {
    ...baseParams,
    properties: params.properties || '',
    // This is optional it could be or not, some urls even not accept benchmarks.
    ...(params?.benchmarks ? { benchmarks: params.benchmarks || '' } : {}),
  };
  const paginationParams = () => ({
    page: params.page,
    limit: params.limit,
    order_by: params.orderBy,
  });

  return {
    [VENDORS_AFTER_OPT_OUT_DATA]: {
      async_: true,
    },
    [VENDORS_AFTER_OPT_OUT_FILTERS]: {
      ...commonParams,
    },
    [VENDORS_AFTER_OPT_OUT_EXPORT]: {
      ...commonParams,
    },
    [VENDORS_AFTER_OPT_OUT_VENDOR_DATA]: {
      ...baseParams,
      vendor_id: params.vendorId,
    },
    [VENDORS_AFTER_OPT_OUT_VENDOR_WEBSITE]: {
      ...baseParams,
      properties: params.website || '',
      vendor_id: params.vendorId,
    },
    [VENDORS_AFTER_OPT_OUT_VENDOR_EXPORT]: {
      ...baseParams,
      properties: params.website || '',
      vendor_id: params.vendorId,
    },
    [VENDORS_AFTER_OPT_OUT_COOKIE_DATA]: {
      ...commonParams,
      ...paginationParams(),
      vendor_id: params.vendorId,
      cookie_name: params.cookieNames || '',
      cookie_party_cookie: params.partyCookie || '',
      cookie_flagged: params.isCookieFlagged,
      cookie_category: params.cookieCategories || '',
      cookie_same_site: params.cookieSameSite || '',
      cookie_host: params.cookieHost || '',
      cookie_persistent: params.isCookiePersistent,
      cookie_secure: params.isCookieSecure,
    },
    [VENDORS_AFTER_OPT_OUT_COOKIE_FILTERS]: {
      ...commonParams,
      vendor_id: params.vendorId,
    },
    [VENDORS_AFTER_OPT_OUT_COOKIE_EXPORT]: {
      ...commonParams,
      vendor_id: params.vendorId,
    },
  }[url];
}

function tableFiltersProcessing({ tableFiltersData = {} }) {
  return Object.entries(tableFiltersData).reduce((acc, [filterKey, filterValue]) => {
    if (filterKey === 'information_storage' && filterValue) {
      // eslint-disable-next-line no-unused-expressions
      filterValue?.forEach((v) => {
        acc[v] = true;
      });
      return acc;
    }
    if (filterKey === 'prevalence' && filterValue) {
      acc.lowest_risk = filterValue[0] ?? 0;
      acc.highest_risk = filterValue[1] ?? 0;
      return acc
    }
    if (filterKey === 'vendor_status' && filterValue) {
      return acc;
    }
    if (filterValue) {
      acc[filterKey] = filterValue ?? [];
    }
    return acc;
  }, {});
}

export function getVendorsAfterOptOutData(params = {}) {
  return fetchRetryWrapper(buildUrlWithVersion(
    VENDORS_AFTER_OPT_OUT_DATA,
    getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_DATA, params),
    'details',
  ),
  {
    method: 'post',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      _version: getVersion('details'),
      date_from: params.dateFrom,
      date_to: params.dateTo,
      region: params.region.split(';'),
      properties: params.properties.split(';'),
      ...(params?.benchmarks ? { benchmarks: params.properties.split(';') } : {}),
      page: params.page,
      limit: params.limit,
      order_by: params.orderBy,
      async_: true,
      ...(params?.vendorId?.length ? { vendor_id: params.vendorId } : {}),
      ...tableFiltersProcessing(params),
    }),
  })
  .then((resp) => resp.json())
  .catch((err) => {
      console.log(err);
      alert(err.response ? `Error on get 'Vendors after opt out' data: status ${err.response.status}` : err);
  });
}

export function getVendorsAfterOptOutFilters(params = {}) {
  return fetch(
    buildUrlWithVersion(
      VENDORS_AFTER_OPT_OUT_FILTERS,
      getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_FILTERS, params),
      'details',
    ),
    {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.status === 502 || err.response.status === 504) {
          alert(
            `${
              'The query has timed out. Please ' +
              'revisit the criteria you have chosen and choose ' +
              'a smaller date range and/or less websites in your selection.' +
              ' (error status '
            }${err.response.status})`,
          );
        } else {
          alert(`Error on get filters: status ${err.response.status}`);
        }
      } else {
        alert(err);
      }
      throw err;
    });
}

export function exportCSVVendorsAfterOptOut(params = {}) {
  return fetch(
    buildUrlWithVersion(
      VENDORS_AFTER_OPT_OUT_EXPORT,
      getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_EXPORT, params),
      'details',
    ),
    {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'content-type': 'text/csv;charset=UTF-8',
      },
    },
  )
    .then(checkStatus)
    .then((response) => response.blob())
    .then(exportCSVResponse(`vendors_after_opt_out(${params.dateFrom}/${params.dateTo})`))
    .catch((err) => {
      console.log(err);
      alert(err.response ? `Export Error: status ${err.response.status}` : err);
    });
}

export function getVendorAfterOptOutVendorData(params = {}) {
  return fetch(
      buildUrlWithVersion(
          VENDORS_AFTER_OPT_OUT_VENDOR_DATA,
          getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_VENDOR_DATA, params),
          'details',
      ),
      {
          credentials: 'include',
          mode: 'cors',
          headers: {
          },
      },
  )
      .then(checkStatus)
      .then((resp) => resp.json())
      .catch((err) => {
          console.log(err);
          alert(err);
      });
}

export function getVendorAfterOptOutVendorWebsite(params = {}) {
  return fetch(
      buildUrlWithVersion(
          VENDORS_AFTER_OPT_OUT_VENDOR_WEBSITE,
          getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_VENDOR_WEBSITE, params),
          'details',
      ),
      {
          credentials: 'include',
          mode: 'cors',
          headers: {
          },
      },
  )
      .then(checkStatus)
      .then((resp) => resp.json())
      .catch((err) => {
          console.log(err);
          alert(err);
      });
}

export function exportCSVVendorsAfterOptOutVendorWebsite(params = {}) {
  return fetch(
      buildUrlWithVersion(
          VENDORS_AFTER_OPT_OUT_VENDOR_EXPORT,
          getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_VENDOR_EXPORT, params),
          'details',
      ),
      {
          credentials: 'include',
          mode: 'cors',
          headers: {
              'content-type': 'text/csv;charset=UTF-8',
          },
      },
  )
      .then(checkStatus)
      .then((response) => response.blob())
      .then(exportCSVResponse(`vendor_details_after_opt_out_(${params.dateFrom}/${params.dateTo})`))
      .catch((err) => {
          console.log(err);
          if (err.response) {
              if (err.response.status === 502 || err.response.status === 504) {
                  alert(`${'The data selected is too large to download to csv. ' +
                      'Try selecting a smaller date range or reach out to your customer success manager.' +
                      ' (error status '}${err.response.status})`);
              } else {
                  alert(`Error on export data: status ${err.response.status}`);
              }
          } else {
              alert(err);
          }
      });
}

export function getVendorsAfterOptOutCookiesData(params = {}) {
  return fetch(
    buildUrlWithVersion(
      VENDORS_AFTER_OPT_OUT_COOKIE_DATA,
      getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_COOKIE_DATA, params),
      'details',
    ),
    {
      credentials: 'include',
      mode: 'cors',
      headers: {},
    },
  )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.status === 502 || err.response.status === 504) {
          alert(
            `${
              'The query has timed out. Please ' +
              'revisit the criteria you have chosen and choose ' +
              'a smaller date range and/or less websites in your selection.' +
              ' (error status '
            }${err.response.status})`,
          );
        } else {
          alert(
            `Error on get 'Vendors after opt out' data: status ${err.response.status}`,
          );
        }
      } else {
        alert(err);
      }
    });
}

export function getVendorsAfterOptOutCookiesFilters(params = {}) {
  return fetch(
    buildUrlWithVersion(
      VENDORS_AFTER_OPT_OUT_COOKIE_FILTERS,
      getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_COOKIE_FILTERS, params),
      'details',
    ),
    {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.status === 502 || err.response.status === 504) {
          alert(
            `${
              'The query has timed out. Please ' +
              'revisit the criteria you have chosen and choose ' +
              'a smaller date range and/or less websites in your selection.' +
              ' (error status '
            }${err.response.status})`,
          );
        } else {
          alert(`Error on get filters: status ${err.response.status}`);
        }
      } else {
        alert(err);
      }
    });
}

export function exportCSVVendorsAfterOptOutCookies(params = {}) {
  return fetch(
      buildUrlWithVersion(
          VENDORS_AFTER_OPT_OUT_COOKIE_EXPORT,
          getParamsForGivenUrl(VENDORS_AFTER_OPT_OUT_COOKIE_EXPORT, params),
          'details',
      ),
      {
          credentials: 'include',
          mode: 'cors',
          headers: {
              'content-type': 'text/csv;charset=UTF-8',
          },
      },
  )
      .then(checkStatus)
      .then((response) => response.blob())
      .then(exportCSVResponse(`$${params.vendorName}(${params.dateFrom}/${params.dateTo})`))
      .catch((err) => {
          console.log(err);
          if (err.response) {
              if (err.response.status === 502 || err.response.status === 504) {
                  alert(`${'The data selected is too large to download to csv. ' +
                      'Try selecting a smaller date range or reach out to your customer success manager.' +
                      ' (error status '}${err.response.status})`);
              } else {
                  alert(`Error on export data: status ${err.response.status}`);
              }
          } else {
              alert(err);
          }
      });
}
