import moment from 'moment';
import { Record, fromJS, List } from 'immutable';

export const Partition = Record({
    name: '',
    ruleset: fromJS({
        bucket_rules: [
            { bucket_start: "", bucket_end: "" }
        ]
    }),
    scenario_id: '',
    uuid: '',
})

export class Partitionset extends Record({
    description: '',
    id: '',
    schema_version: '',
    site_id: '',
    status: '',
    partitions: List([]),
    live: false,
    created_at: null,
    campaign_type_id: 0,
}) {
    constructor(partitionset = { created_at: Date.now() }) {
        super(Object.assign({}, partitionset, {
            created_at: new moment(partitionset.created_at),
        }));
    }
}
