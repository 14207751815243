import { Record, List } from 'immutable';
import moment from 'moment';

export const WhitelistRecord = Record({
  tableData: List([]),
});

export const GraphFilters = Record({
  start_date: moment(),
  end_date: moment(),
  domain: null,
  granularity: 'day',
});
