import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getNotificationsData, markNotificationsRead } from '../../../api/diagnose_dashboard/notifications';
import { accountFeaturesToAvailableDiagnoseMetrics } from '../../../helpers/diagnose/metrics';
import { Select, Tabs } from '../../../styleguide';
import EmailPreferencesModal from './components/EmailPreferencesModal';
import NotificationTabItem from './components/NotificationTabItem';
import { checkNotificationTypesToPermissions, formatNotificationsData } from './helpers';

const TAB = {
  NEW: 'new',
  REVIEWED: 'reviewed',
};

const DiagnoseNotifications = () => {
  const accountState = useSelector((state) => state.accountState);
  const currentUser = accountState?.getIn(['userDetails', 'value']);
  const metrics = useMemo(() => {
    return accountFeaturesToAvailableDiagnoseMetrics(
      currentUser.accountFeatures,
      currentUser.allFeatureAccess || currentUser.featureAccess,
    );
  }, [currentUser.accountFeatures, currentUser.allFeatureAccess, currentUser.featureAccess]);
  const NotificationTypes = useMemo(
    () => checkNotificationTypesToPermissions(currentUser.accountFeatures),
    [currentUser.accountFeatures],
  );
  const [activeTab, setActiveTab] = useState(TAB.NEW);
  const [activeFilter, setActiveFilter] = useState(NotificationTypes[0].value);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [result, setResult] = useState({});
  const PageSize = 50;

  const getNotifications = useCallback(
    async ({ tab, filter, page = 1, pageSize = PageSize }) => {
      let type = filter;
      if (filter === NotificationTypes[0].value) {
        // eslint-disable-next-line no-unused-vars
        const [all, ...types] = NotificationTypes;
        type = types.map(({ value }) => value).join(';');
      }

      const getReviewed =
        (!tab || tab === TAB.REVIEWED) &&
        getNotificationsData({ metric: metrics.join(';'), type, page, isSeen: true, pageSize });
      const getNew =
        (!tab || tab === TAB.NEW) &&
        getNotificationsData({ metric: metrics.join(';'), type, page, isSeen: false, pageSize });
      const [newData, reviewedData] = await Promise.allSettled([getNew, getReviewed]);
      return {
        [TAB.NEW]: newData?.value,
        [TAB.REVIEWED]: reviewedData?.value,
      };
    },
    [metrics],
  );

  const startGetNotifications = async (query, setLoadingState, /* options */ { withReset } = {}) => {
    if (withReset) {
      setResult({});
    }
    setLoadingState(true);
    const notifications = await getNotifications(query);

    setResult((results) => ({
      [TAB.NEW]: formatNotificationsData(notifications?.[TAB.NEW], results?.[TAB.NEW]),
      [TAB.REVIEWED]: formatNotificationsData(notifications?.[TAB.REVIEWED], results?.[TAB.REVIEWED]),
    }));
    setLoadingState(false);
  };

  const handleFilter = async (selectedFilter) => {
    setActiveFilter(selectedFilter);
    startGetNotifications({ tab: null, filter: selectedFilter }, setLoading, { withReset: true });
  };

  const handleActiveTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const getAdditionalData = useCallback(async () => {
    if (PageSize * result[activeTab]?.page < result[activeTab]?.count) {
      await startGetNotifications(
        { tab: activeTab, filter: activeFilter, page: result[activeTab]?.page + 1 },
        setLoadingMore,
      );
    }
  }, [activeTab, activeFilter, result[activeTab]?.page]);

  const onMarkNotificationsRead = async (body, /* options */ { refetchList = false } = {}) => {
    await markNotificationsRead(body);
    if (refetchList) {
      await startGetNotifications({ tab: null, filter: activeFilter }, setLoading, { withReset: refetchList });
    }
  };

  useEffect(() => {
    startGetNotifications({ tab: null, filter: activeFilter }, setLoading);
  }, []);

  const items = [
    {
      key: TAB.NEW,
      label: (
        <span className="tab-new">
          New<span className="count">{result?.[TAB.NEW]?.count || 0}</span>
        </span>
      ),
      disabled: isLoadingMore,
      children: (
        <NotificationTabItem
          result={result?.[TAB.NEW]}
          isLoadingMore={isLoadingMore}
          isLoading={isLoading}
          activeTab={activeTab}
          getAdditionalData={getAdditionalData}
          markNotificationsRead={onMarkNotificationsRead}
        />
      ),
    },
    {
      key: TAB.REVIEWED,
      label: <span>Reviewed</span>,
      disabled: isLoadingMore,
      children: (
        <NotificationTabItem
          result={result?.[TAB.REVIEWED]}
          isLoadingMore={isLoadingMore}
          isLoading={isLoading}
          activeTab={activeTab}
          getAdditionalData={getAdditionalData}
          markNotificationsRead={onMarkNotificationsRead}
        />
      ),
    },
  ];

  return (
    <div className={'diagnose_container diagnose_notifications'}>
      <div className="headers main_header">
        <div className="header-container">
          Diagnose Alerts
          <EmailPreferencesModal currentUser={currentUser} notificationTypes={NotificationTypes} />
        </div>
      </div>
      <Tabs
        className="diagnose-overview"
        onChange={handleActiveTab}
        activeKey={activeTab}
        animated
        tabBarExtraContent={{
          right: (
            <div className="tab-bar-extra-content">
              <label>
                <span>Filter by:</span>
                <Select
                  className="diagnose-dashboard-select"
                  disabled={isLoading || isLoadingMore}
                  options={NotificationTypes}
                  onChange={handleFilter}
                  value={activeFilter}
                  label="Filters"
                />
              </label>
            </div>
          ),
        }}
        items={items}
      />
    </div>
  );
};

export default DiagnoseNotifications;
