import React, { Component } from 'react';
import { Radio as AntdRadio } from 'antd';

const RadioGroup = (incomingProps) => {
    const props = {...incomingProps};
    props.className = 'spsg-radio-group ' + (props.className || '');
    return <AntdRadio.Group {...props} />;
};

const Radio = (incomingProps) => {
    const props = {...incomingProps};
    props.className = 'spsg-radio ' + (props.className || '');

    return <AntdRadio {...props} />;
}

Radio.Group = RadioGroup;
Radio.Button = AntdRadio.Button;
export default Radio;
