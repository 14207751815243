export default (state, { type, load }) => {
	switch (type) {
		case 'UPDATE ROOT':
			return { ...state, root: load }
		case 'UPDATE MESSAGE':
			return { ...state, message: load }
		case 'UPDATE MESSAGE VARIABLES':
			return { ...state, messageVariables: load }
		case 'UPDATE SELECTED VIEW':
			return { ...state, selectedView: load }
		case 'UPDATE CURRENT COMPONENT PATH':
			return { ...state, currentComponentPath: load }
		case 'UPDATE ACTIVE KEY':
			return { ...state, activeKey: load }
		case 'SHOW SUPPORTED LANGUAGES MODAL':
			return { ...state, supportedLanguagesModalVisible: true, supportedLanguagesMissing: load }
		case 'HIDE SUPPORTED LANGUAGES MODAL':
			return { ...state, supportedLanguagesModalVisible: false, supportedLanguagesMissing: [] }
		case 'UPDATE PRIVACY COMPLIANCE STATUS':
			return { ...state, privacyComplianceStatus: load }
		case 'SHOW COMPLETE CHECK PRIVACY MODAL':
			return { ...state, showCompleteCheckPrivacyModal: load }
		case 'UPDATE CURRENT COMPONENT PATH':
			return { ...state, currentComponentPath: load }
		case 'CONFIRM PRIVACY CHECK':
		case 'UPDATE SETTING':
		case 'UPDATE VIEW':
			return { ...state, ...load }
		case 'UPDATE_TEMPLATE_PARAMS':
			return {
				...state,
				templateParams: load,
				chooseTemplateTypeModalVisible: false,
			}
		case 'SHOW_CHOOSE_TEMPLATE_MODAL_VISIBLE':
			return { ...state, chooseTemplateTypeModalVisible: load }
		case 'UPDATE_TEMPLATE_NAME':
			return {
				...state,
				templateParams: {
					...state.templateParams,
					name: load
				}
			}
		default:
			throw Error(`Bad action type: ${type}`)
	}
}