import React from 'react';
import { Link } from 'react-router';

export default class ConsentSubnav extends React.Component {
  render() {
    let vendorListRoute;
    let privacyManageLink;
    let consentCompensationLink;
    if (this.props.v2) {
      vendorListRoute = `/consent_v2/vendor_lists`;
      if (this.props.currentUser.accountFeatures.includes('consent_compensation')) {
        consentCompensationLink = <li><Link to='/consent_v2/compensation_list' activeClassName="active" >Consent Compensation</Link></li>;
      }
    } else if (this.props.nonIab) {
      vendorListRoute = `/non-iab/vendor_lists`;
    } else {
      vendorListRoute = `/consent/vendor_lists`;
      privacyManageLink = <li><Link to='/consent/privacy_manager' activeClassName="active" >Privacy Manager</Link></li>;
    }

    return (
      <div className="dialogue subnav">
        <ul className="list-inline">
          <li>
            <Link to={vendorListRoute} activeClassName="active" >
              Vendor List
            </Link>
          </li>
          {consentCompensationLink}
          {privacyManageLink}
        </ul>
      </div>
    );
  }
}