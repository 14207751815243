import _ from 'lodash';
import { numberWithCommas } from '../../utils.js';

export function getTotalChoicesForPartition(partition) {

    var choicesArray = getChoicesArrayForPartition(partition);

    return _.sumBy(choicesArray, (obj) => obj.count);
}

export function getTotalChoicesForCampaign(partitions) {

    var choicesArray = getChoicesArrayForCampaign(partitions);

    return _.sumBy(choicesArray, (obj) => obj.count);
}

export function getChoiceCountByTypeForPartition(partition) {

    var choicesArray = getChoicesArrayForPartition(partition);

    var choicesByType = _.groupBy(choicesArray, 'choice_type_id');

    return getChoiceCount(choicesByType);
}

export function getChoiceCountByTypeForCampaign(partitions) {

    var choicesArray = getChoicesArrayForCampaign(partitions);

    var choicesByType = _.groupBy(choicesArray, 'choice_type_id');

    return getChoiceCount(choicesByType);
}

export function getValuesForChoiceTypeForPartition(partition) {
    var postEngagementData = getPostEngagementDataForPartition(partition);
    var choiceCount = getChoiceCountByTypeForPartition(partition);
    return _.mapValues(choiceCount, (count, choiceType) => {
        if (choiceType === "3" || choiceType === "4" || choiceType === "5") {
            return numberWithCommas(count.countComplete);
        } else {
            return numberWithCommas(postEngagementData[choiceType]);
        }
    })
}

export function getValuesForChoiceTypeForCampaign(partitions) {
    var postEngagementData = getPostEngagementDataForCampaign(partitions);
    var choiceCount = getChoiceCountByTypeForCampaign(partitions);
    return _.mapValues(choiceCount, (count, choiceType) => {
        if (choiceType === "3" || choiceType === "4" || choiceType === "5") {
            return numberWithCommas(count.countComplete);
        } else {
            return numberWithCommas(postEngagementData[choiceType]);
        }
    })
}

export function getTotalValues(valueObject) {
    return {
        totalWLPV: _.sum(valueObject.wlpv),
        totalRAPV: _.sum(valueObject.rapv),
        totalAltAd: valueObject.dcmp[0],
        totalAltPay: valueObject.dcmp[2] || 0,
    }
}

export function getCampaignValueData(partitions) {
    var valuesArray = partitions.map(p => getTotalValues(p.value_data));
    // valuesArray = [{totalWLPV: 10, totalRAPV: 5 ...}, {totalWLPV: 20, ...}, ...]
    return valuesArray.reduce((total, next) => {
        return _.mapValues(total, (value, key) => {
            return value + next[key];
        })
    })
}

export function getCampaignWhitelist(partitions) {
    var wlpvArray = partitions.map(p => p.value_data.wlpv.slice());
    // wlpvArray = [[3,5,0, ...], [1,0,4, ...], ...]
    return wlpvArray.reduce((total, next) => {
        return total.map((el, i) => {
            return el + next[i]
        })
    })
}

function getChoicesArrayForPartition(partition) {

    var messages = _.values(partition.message_data);

    return _.flatten(messages.map(message => _.values(message.choice_data)));
}

function getChoicesArrayForCampaign(partitions) {

    var messages = _.flatten(_.values((partitions).map(partition => _.values(partition.message_data))));

    return _.flattenDeep(messages.map(message => _.values(message.choice_data)));
}

function getChoiceCount(choicesByType) {

  return _.mapValues(choicesByType, choicesArray => {
      return {
          count: _.sumBy(choicesArray, (obj) => obj.count),
          countComplete: _.sumBy(choicesArray, (obj) => obj.count_complete)
      }
  })
}

function getPostEngagementDataForPartition(partition) {

    var choicesArray = getChoicesArrayForPartition(partition);

    var postEngagementByType = _.mapValues(partition.post_engagement_data, (obj) => {
        var adBlockedPV = obj.abp_desktop_pv_count + obj.abp_mobile_pv_count;
        return obj.flag_bits === 2 ? adBlockedPV : obj.total_pv_count - adBlockedPV;
    })

    var choiceTypes = _.groupBy(getChoicesArrayForPartition(partition), 'choice_type_id');

    return mapPostEngagementDataToChoiceTypes(choiceTypes, postEngagementByType);
}

function getPostEngagementDataForCampaign(partitions) {

    var choicesArray = getChoicesArrayForCampaign(partitions);

    var postEngagementArray = _.flatten(_.values(partitions).map(partition => _.values(partition.post_engagement_data)));

    var postEngagementByType = _.mapValues(_.groupBy(postEngagementArray, 'flag_bits'), (arr, flag_bits) => {

        return arr.reduce((total, el) => {
            var adBlockedPV = el.abp_desktop_pv_count + el.abp_mobile_pv_count;
            if (flag_bits === "2") {
                return total + adBlockedPV;
            } else {
                return total + el.total_pv_count - adBlockedPV;
            }
        }, 0)
    })

    var choiceTypes = _.groupBy(getChoicesArrayForCampaign(partitions), 'choice_type_id');

    return mapPostEngagementDataToChoiceTypes(choiceTypes, postEngagementByType);
}

function mapPostEngagementDataToChoiceTypes(choiceTypes, postEngagementData) {

    return _.mapValues(choiceTypes, (count, choiceType) => {
          var flagBits = Math.pow(2, choiceType - 1);
          return postEngagementData[flagBits] || 0;
    })
}
