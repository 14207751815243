import React from 'react';

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';

export default class extends React.Component {
    render() {
        return (
            <ConfigProvider locale={ enUS }>
              { this.props.children }
            </ConfigProvider>
        );
    }
}
