import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { browserHistory } from 'react-router';

import {
  VendorTypeColumn,
  CookieNameColumn,
  InformationStorageColumn,
  LastAppearedScanColumn,
  PrevalenceBenchMarkColumn,
  PrevalenceColumn,
  TechnologyCategorisationColumn,
  VendorDetailsColumn,
  VendorNameColumn,
  VendorReferenceColumn,
  VendorStatusColumn,
} from '../../shared/DiagnoseColumns';

import {
  getVendorManagementStatusesList,
  getVendorsNotOnVendorFilter,
  getVendorsNotOnVendorList,
  vendorsNotOnVendorExport,
} from '../../../../../actions/diagnose_dashboard_actions';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import {
  getUniqueVendorIdsList,
  isFiltersProvided,
  mapSelectedFilters,
  requestDataDependOnTableFilters,
} from '../helpers';

const DetailedBreakdown = ({
  userDetails,
  getVendorsNotOnVendorList,
  vendorsNotOnVendorList,
  vendorsNotOnVendorListPending,
  vendorsNotOnVendorFilter,
  getVendorsNotOnVendorFilter,
  selectedFilters,
  vendorsNotOnVendorExport,
  vendorsNotOnVendorExportPending,
  getVendorManagementStatusesList,
  vendorManagementStatuses,
  vendorManagementStatusesPending,
  vendorManagementActivities,
  vendorManagementStatusesQuery,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const [tableFilters, setTableFilters] = useState(null);
  const isEnabledVendorStatusManagementFeature = userDetails.accountFeatures.includes('diagnose_vendor_management');

  const getVendorsNotOnVendorListData = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getVendorsNotOnVendorList({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
    });
    return res;
  };

  const getVendorManagementStatusesListData = async ({ vendors = [], status = [], tag = [] }, checkCache = true) => {
    const query = {
      metric: 'Non disclosed vendors observed',
      vendors,
      status,
      tag,
      websites: selectedFilters?.websites,
    };
    const isCacheValid = checkCache && isEqual(vendorManagementStatusesQuery, query);
    if (isCacheValid) {
      return vendorManagementStatuses;
    }
    const res = await getVendorManagementStatusesList(query);
    return res;
  };

  const getVendorsNotOnVendorFiltersData = () => {
    getVendorsNotOnVendorFilter(filters);
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      requestDataDependOnTableFilters(
        { filters: tableFilters || {}, isEnabledVendorStatusManagementFeature },
        (f, vendorId) => getVendorsNotOnVendorListData(f, searchParams?.vendor ? [searchParams?.vendor] : vendorId),
        getVendorManagementStatusesListData,
      );
    }
  }, [filters, isEnabledVendorStatusManagementFeature, tableFilters, page, limit, orderBy]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setTableFilters(null);
      getVendorsNotOnVendorFiltersData();
    }
  }, [filters]);

  useEffect(() => {
    if (vendorManagementActivities?.length) {
      getVendorManagementStatusesListData({ vendors: getUniqueVendorIdsList(vendorsNotOnVendorList?.results) }, false);
    }
  }, [vendorManagementActivities]);

  const vendorsFilter = vendorsNotOnVendorFilter?.vendors?.map((filter) => ({ text: filter, value: filter }));
  const technologiesFilter = vendorsNotOnVendorFilter?.technologies?.map((filter) => ({ text: filter, value: filter }));
  const cookiesFilter = vendorsNotOnVendorFilter?.cookies?.map((filter) => ({ text: filter, value: filter }));
  const referenceVendorsFilter = vendorsNotOnVendorFilter?.reference_vendors?.map((filter) => ({
    text: filter,
    value: filter,
  }));

  const columns = [
    VendorTypeColumn({}),
    VendorNameColumn({ filters: vendorsFilter || [] }),
    ...(isEnabledVendorStatusManagementFeature ? [VendorStatusColumn({ searchParams })] : []),
    TechnologyCategorisationColumn({ filters: technologiesFilter || [] }),
    PrevalenceColumn,
    PrevalenceBenchMarkColumn,
    LastAppearedScanColumn,
    InformationStorageColumn,
    CookieNameColumn({ filters: cookiesFilter || [] }),
    VendorReferenceColumn({ filters: referenceVendorsFilter || [] }),
    VendorDetailsColumn({ metric: 'Non disclosed vendors observed' }),
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
    setTableFilters(filters);
    browserHistory.replace(location.pathname);
  };

  const tableData = useMemo(() => {
    if (!isEnabledVendorStatusManagementFeature) {
      return vendorsNotOnVendorList?.results;
    }

    return vendorsNotOnVendorList?.results?.map((row) => {
      const rowWithVendorStatus = {
        ...row,
        vendor_status:
          vendorManagementStatuses?.filter((vendorStatus) => {
            return vendorStatus.vendor === row.vendor_id;
          }) || [],
      };
      return rowWithVendorStatus;
    });
  }, [isEnabledVendorStatusManagementFeature, vendorsNotOnVendorList?.results, vendorManagementStatuses]);

  const onPaginationChange = (pageNumber, pageSize) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    vendorsNotOnVendorExport(filters);
  };

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The table below lists the vendors that we have observed on your properties that are not disclosed in your vendor
        list in the period of time selected. The information detailed should help you determine whether the vendor
        should be added to your vendor list or blocked.
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container">
        <div className={'filters_block'}>
          <div className={'quick_filters'} />
          <div className={'downloadCSV'}>
            <Button icon={<DownloadOutlined />} onClick={exportCsv} loading={vendorsNotOnVendorExportPending} />
          </div>
        </div>
        <Table
          id="detailed-breakdown"
          scroll={{ x: 'max-content', y: 430 }}
          dataSource={tableData}
          columns={columns}
          showSorterTooltip={false}
          rowClassName={(record) =>
            record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
              ? 'diagnose-notification-highlight'
              : ''
          }
          rowKey={(record) =>
            record?.vendor_id +
            record.vendor_type +
            record.reference_vendors.length +
            record.technologies.length +
            record.cookies.length
          }
          pagination={{
            current: page,
            pageSize: limit,
            total: tableFilters?.vendor_status?.length && !vendorManagementStatuses.length ? 0 : vendorsNotOnVendorList?.total_count,
            position: ['bottomCenter'],
            onChange: onPaginationChange,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
          }}
          onChange={onTableChange}
          loading={{
            className: 'diagnose-spinning',
            spinning: vendorsNotOnVendorListPending || vendorManagementStatusesPending,
            tip: vendorsNotOnVendorList?.results?.length
              ? ''
              : 'Processing a large query; please wait or leave it open and check later.',
            size: 'large',
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    vendorsNotOnVendorList: store.diagnoseDashboardState.getIn(['vendorsNotOnVendorList', 'value']),
    vendorsNotOnVendorListPending: store.diagnoseDashboardState.get('vendorsNotOnVendorListPending'),
    vendorsNotOnVendorExportPending: store.diagnoseDashboardState.get('vendorsNotOnVendorExportPending'),
    vendorsNotOnVendorFilter: store.diagnoseDashboardState.getIn(['vendorsNotOnVendorFilter', 'value']),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
    vendorManagementStatuses: store.diagnoseDashboardState.getIn(['vendorManagementStatuses', 'value']),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    vendorManagementStatusesQuery: store.diagnoseDashboardState.getIn(['vendorManagementStatusesParams']),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVendorsNotOnVendorList,
      getVendorsNotOnVendorFilter,
      vendorsNotOnVendorExport,
      getVendorManagementStatusesList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
