import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { Modal, Alert } from 'antd';
import DownloadScoringResults from './DownloadScoringResults';

export default class DeactivateModal extends React.Component {
  static propTypes = {
    moveToDrafts: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  handleApply = () => {
    this.props.deactivate();
    this.props.closeModal();
  }

  goToPropertySets = () => {
    browserHistory.push(`/privacy_lens/domain_sets?standardId=${this.props.standard.id}`);
  }

  getDaysBeforeEndOfPeriod = () => {
    const PERIOD = 30;
    const todayDate = moment();
    const acviteDays = todayDate.diff(moment(this.props.propertySet.activateDate), 'days');
    return PERIOD - (acviteDays % PERIOD);
  }

  render() {
    return (
      <Modal
        onOk={this.handleApply}
        okText='Deactivate'
        onCancel={this.props.closeModal}
        className='purple new-design deactvate-ps'
        visible
      >
        <p className="title">{`${this.props.propertySet.name} - Deativate`}</p>

        <div className='modal-container'>
          <p>
            {`If you choose to deactivate ${this.props.propertySet.name}, scanning for this property set will be stopped in the end of this period in ${this.getDaysBeforeEndOfPeriod()} days from now.`}
          </p>
          <p>You will also lose access to the scoring results in the end of this period. You can download the generated scoring results now.</p>
          <DownloadScoringResults propertySet={this.props.propertySet} />
          <p className='question'>
            Are you sure you want to <span>Deactivate?</span>
          </p>
          <Alert message='For current period, PL allowance is already consumed for this property set. Change in PL allowance will be reflected in next period.' type="warning" showIcon />
        </div>
      </Modal>
    );
  }
}
