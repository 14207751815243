import { Map, List, OrderedSet } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
  consentCompensationLists: new StoreRecord({ value: OrderedSet([]) }),
  partners: new StoreRecord({ value: OrderedSet([]) }),
  savePending: false,
});

export default (state = initialState, action) => {
  const lists = state.getIn(['consentCompensationLists', 'value']);
  let updatedList;

  switch(action.type) {
    case types.GET_PARTNERS_PENDING:
      return setPending(state, ['partners']);

    case types.PARTNERS_LOADED:
      return setValue(state, ['partners'], action.partners);

   case types.GET_PARTNERS_ERROR:
      return setError(state, ['partners'], action.error)

    case types.CONSENT_COMPENSATION_LISTS_PENDING:
      return setPending(state, ['consentCompensationLists']);

    case types.CONSENT_COMPENSATION_LISTS_LOADED:
      return setValue(state, ['consentCompensationLists'], action.consentCompensationLists.sortBy(l => l.name.toLowerCase()));

    case types.CONSENT_COMPENSATION_LIST_LOADED:
      updatedList = lists.filterNot(l => l.id === action.consentCompensationList.id).add(action.consentCompensationList).sortBy(list => list.name.toLowerCase());
      return setValue(state, ['consentCompensationLists'], updatedList);

    case types.CONSENT_COMPENSATION_LISTS_ERROR:
      return setError(state, ['consentCompensationLists'], action.error).set('savePending', false);

    case types.CLEAR_CONSENT_COMPENSATION_LISTS_ERROR:
        return state.setIn(['consentCompensationLists', 'error'], null);

    case types.CONSENT_COMPENSATION_LIST_SAVE_PENDING:
      return state.set('savePending', true);

    case types.CONSENT_COMPENSATION_LIST_CREATED:
      updatedList = lists.add(action.consentCompensationList).sortBy(list => list.name.toLowerCase());
      return setValue(state, ['consentCompensationLists'], updatedList).set('savePending', false);

    case types.CONSENT_COMPENSATION_LIST_UPDATED:
      updatedList = lists.filterNot(l => l.id === action.consentCompensationList.id).add(action.consentCompensationList).sortBy(l => l.name.toLowerCase());
      return setValue(state, ['consentCompensationLists'], updatedList).set('savePending', false);

    case types.CONSENT_COMPENSATION_LIST_DELETED:
      updatedList = lists.filter(l => l.id !== action.id);
      return setValue(state, ['consentCompensationLists'], updatedList);

    case types.CONSENT_COMPENSATION_LISTS_CACHED:
      return stopPending(state, [action.property]);
  }

  return state;
};
