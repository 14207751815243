import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import {
  Select,
  Tooltip,
} from 'antd';

import ContinueSatisfy from './ContinueSatisfy';
import { USStateGate as USStateGateRecord } from '../../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';

const { Option } = Select;

class States extends React.Component {
    static propTypes = {
      editing: PropTypes.bool.isRequired,
      data: PropTypes.instanceOf(USStateGateRecord).isRequired,
      onChange: PropTypes.func.isRequired,
    };

    handleChange = (newData) => {
      this.props.onChange(this.props.data.set('state_list', fromJS(newData)));
    };

    handleChangeSatisfy = (continueOnSatisfy) => {
      this.props.onChange(this.props.data.merge({ continue: continueOnSatisfy }));
    };

    onSelectFilter = (inputString, option) => {
      return inputString.toLowerCase() === option.props.children.toLowerCase().slice(0, inputString.length);
    }

    render() {
      const continueOnSatisfy = this.props.data.continue === true ? "In" : "Not In";

      const stateList = [
        {key: 'All', value: 'All US'},
        {key: "CA", value: "California"},
        {key: "AL", value: "Alabama"},
        {key: "AK", value: "Alaska"},
        {key: "AZ", value: "Arizona"},
        {key: "AR", value: "Arkansas"},
        {key: "CO", value: "Colorado"},
        {key: "CT", value: "Connecticut"},
        {key: "DE", value: "Delaware"},
        {key: "DC", value: "District Of Columbia"},
        {key: "FL", value: "Florida"},
        {key: "GA", value: "Georgia"},
        {key: "HI", value: "Hawaii"},
        {key: "ID", value: "Idaho"},
        {key: "IL", value: "Illinois"},
        {key: "IN", value: "Indiana"},
        {key: "IA", value: "Iowa"},
        {key: "KS", value: "Kansas"},
        {key: "KY", value: "Kentucky"},
        {key: "LA", value: "Louisiana"},
        {key: "ME", value: "Maine"},
        {key: "MD", value: "Maryland"},
        {key: "MA", value: "Massachusetts"},
        {key: "MI", value: "Michigan"},
        {key: "MN", value: "Minnesota"},
        {key: "MS", value: "Mississippi"},
        {key: "MO", value: "Missouri"},
        {key: "MT", value: "Montana"},
        {key: "NE", value: "Nebraska"},
        {key: "NV", value: "Nevada"},
        {key: "NH", value: "New Hampshire"},
        {key: "NJ", value: "New Jersey"},
        {key: "NM", value: "New Mexico"},
        {key: "NY", value: "New York"},
        {key: "NC", value: "North Carolina"},
        {key: "ND", value: "North Dakota"},
        {key: "OH", value: "Ohio"},
        {key: "OK", value: "Oklahoma"},
        {key: "OR", value: "Oregon"},
        {key: "PA", value: "Pennsylvania"},
        {key: "RI", value: "Rhode Island"},
        {key: "SC", value: "South Carolina"},
        {key: "SD", value: "South Dakota"},
        {key: "TN", value: "Tennessee"},
        {key: "TX", value: "Texas"},
        {key: "UT", value: "Utah"},
        {key: "VT", value: "Vermont"},
        {key: "VA", value: "Virginia"},
        {key: "WA", value: "Washington"},
        {key: "WV", value: "West Virginia"},
        {key: "WI", value: "Wisconsin"},
        {key: "WY", value: "Wyoming"}
      ];

      if (!this.props.editing) {
        const fullText = 'States ' + continueOnSatisfy + ' ' + this.props.data.state_list.join(', ');
        const shortenedText = fullText.length > 30 ? fullText.slice(0, 30) + '...' : fullText;
        return (
          <Tooltip title={ fullText } >
            <div>
              { shortenedText }
            </div>
          </Tooltip>
        );
      } else {
        const geoList = this.props.data.state_list.toJS();

        const options = stateList.map((state) => {
          return <Option key={state.key}>{state.value}</Option>;
        });


        return (
          <div className="step-block-editing">
            <div className='country-type-select-container'>
              { this.props.children }
              <ContinueSatisfy
                style={{ width: 100 }}
                value={ this.props.data.continue }
                onChange={ this.handleChangeSatisfy }
                affirmativeText='in'
                negativeText='not in'
              />
            </div>
            <Select
              className='country-select'
              mode='multiple'
              onChange={ this.handleChange }
              tokenSeparators={[',']}
              value={ geoList }
              filterOption={ this.onSelectFilter }
            >
              { options }
            </Select>
          </div>
        );
      }
    }
}

export default States;
