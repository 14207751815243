import { List } from 'immutable';
import * as types from './action_types';
import {
  getPartitionsetsAjax,
  createPartitionsetAjax,
  createSiteGroupPartitionsetAjax,
  updatePartitionsetAjax,
  updateSiteGroupPartitionsetAjax,
  deletePartitionsetAjax,
  deleteSiteGroupPartitionsetAjax,
} from '../api/dialogue/partitionsets';
import { importPartitionsetAjax } from '../api/dialogue/import';
import { getScenariosAfterImport } from './scenario_actions.js';
import { getMessagesAfterImport } from './message_actions.js';
import { actionCreator } from './helpers';
import { setSiteGroupSitesWithErrors } from './site_actions';
import {
  createPartitionLog,
  deletePartitionLog,
  editPartitionLog,
  createSiteGroupPartitionLog,
  deleteSiteGroupPartitionLog,
  editSiteGroupPartitionLog
} from '../api/change_log'

export const getPartitionsets = actionCreator(
  getPartitionsetsAjax,
  partitionsetsPending,
  partitionsetsLoaded,
  partitionsetsError,
  partitionsetsCached,
)

export const createPartitionset = actionCreator(
  createPartitionsetAjax,
  partitionsetsPending,
  createPartitionsetLoaded,
  partitionsetsError,
  null,
  createPartitionLog
)

export function createSiteGroupPartitionset(siteGroupId, accountId, pset, willImport) {
  return async (dispatch, getState) => {
    try {
      dispatch(partitionsetsPending());
      if (willImport) dispatch(partitionsetImportPending());
      await createSiteGroupPartitionsetAjax(siteGroupId, accountId, pset)
        .then((resp) => {
          createSiteGroupPartitionLog(getState(), siteGroupId, resp)
          dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
          dispatch(createPartitionsetLoaded(resp.get('partitionsets')));
        });
      return 'success';
    } catch(error) {
      dispatch(partitionsetsError(error));
      return 'error';
    }
  }
}

export const updatePartitionset = actionCreator(
  updatePartitionsetAjax,
  partitionsetsPending,
  updatePartitionsetLoaded,
  partitionsetsError,
  null,
  editPartitionLog
)

export function updateSiteGroupPartitionset(siteGroupId, accountId, pset) {
  return async (dispatch, getState) => {
    try {
      dispatch(partitionsetsPending());
      await updateSiteGroupPartitionsetAjax(siteGroupId, accountId, pset)
      .then((resp) => {
        editSiteGroupPartitionLog(getState(), siteGroupId, pset)
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(updatePartitionsetLoaded(resp.get('partitionsets')));
      });
      return 'success';
    } catch(error) {
      dispatch(partitionsetsError(error));
      return 'error';
    }
  }
}

export const deletePartitionset = actionCreator(
  deletePartitionsetAjax,
  partitionsetsPending,
  partitionsetDeleted,
  partitionsetsError,
  null,
  deletePartitionLog
)

export function deleteSiteGroupPartitionset(siteGroupId, accountId, psetId, psetName) {
  return (dispatch, getState) => {
    dispatch(partitionsetsPending());
    deleteSiteGroupPartitionsetAjax(siteGroupId, accountId, psetId)
      .then((resp) => {
        deleteSiteGroupPartitionLog(getState(), siteGroupId, psetId, psetName)
        dispatch(setSiteGroupSitesWithErrors(resp.get('sitesWithErrors')));
        dispatch(partitionsetDeleted(resp.get('partitionsetIds')));
      })
      .catch((resp) => {
        dispatch(partitionsetsError(resp));
      });
  }
}

export function importPartitionset(siteId, partitionsetId, options) {
  return (dispatch) => {
    dispatch(partitionsetImportPending());
    return importPartitionsetAjax(siteId, partitionsetId, options).then((partitionset) => {
      Promise.all([
        getScenariosAfterImport(siteId)(dispatch), getMessagesAfterImport(siteId)(dispatch)
      ]).then(() => {
        dispatch(partitionsetImported(partitionset));
      }).catch((reason) => {
        dispatch(partitionsetsError(reason));
      });
    }).catch((reason) => {
      dispatch(partitionsetsError(reason));
    });
  };
}

function partitionsetsPending() {
  return {
    type: types.PARTITIONSETS_PENDING,
  };
}

function partitionsetsCached() {
  return {
    type: types.PARTITIONSETS_CACHED,
  };
}

function partitionsetsLoaded(partitionsets) {
  return {
    type: types.PARTITIONSETS_LOADED,
    partitionsets,
  };
}

function partitionsetsError(errorMessage) {
  return {
    type: types.PARTITIONSETS_ERROR,
    errorMessage,
  };
}

function createPartitionsetLoaded(partitionset) {
  return {
    type: types.CREATE_PARTITIONSET_LOADED,
    partitionset,
  };
}

function updatePartitionsetLoaded(partitionset) {
  return {
    type: types.UPDATE_PARTITIONSET_LOADED,
    partitionset,
  };
}

function partitionsetDeleted(IdOrIds) {
  const ids = (new List([IdOrIds])).flatten();
  return {
    type: types.PARTITIONSET_DELETED,
    ids,
  };
}

function partitionsetImportPending() {
  return {
    type: types.PARTITIONSET_IMPORT_PENDING,
  };
}

function partitionsetImported(partitionset) {
  return {
    type: types.PARTITIONSET_IMPORTED,
    partitionset,
  };
}
