import 'whatwg-fetch';
import { fromJS, List, Map, OrderedSet } from 'immutable';
import { CSRF_TOKEN, checkStatus, toFormData, isFetched, addToFetched } from '../helper';
import { FETCHED } from '../../constants';
import { Campaign, StatsForPartition, PartitionsetGraphData } from '../../records/campaign_records.js';
import { partitionsetToRecord } from './partitionsets';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export function getProdCampaignsForMultSitesAjax(siteIds, env = 1) {
  const campaignsPromises = siteIds.map(siteId => {
      return fetch(`${permissionsSvsBase}/msg/get_site_campaign_list?site_id=${siteId}&env=${env}`, {
        credentials: 'include',
        mode: 'cors',
        headers: {
        'Content-Type': 'application/json',
        },
      }).then(checkStatus)
          .then(resp => resp.json())
          .then(resp => OrderedSet(resp.map(c => campaignToRecord(c))))
  })
  return Promise.all(campaignsPromises).then(campaigns => {
    return OrderedSet(campaigns).flatten(true);
  })
}

function getCampaignsList(siteId, envNum, campaignTypeId) {
    let url = `/msg/get_site_campaign_list?env=${envNum}&site_id=${siteId}`;
    if (campaignTypeId) url =`${url}&campaign_type_id=${campaignTypeId}`
    if (isFetched(url)) {
        return Promise.resolve(FETCHED);
    } else {
        return fetch(permissionsSvsBase + url, {
            credentials: 'include',
            mode: 'cors',
            headers: {
            'Content-Type': 'application/json',
            },
        }).then(checkStatus)
            .then(resp => resp.json())
            .then(resp => {
                addToFetched(url);
                // debugger
                const result = OrderedSet(resp.data.site_campaign_list).map(campaign => campaignToRecord(campaign));
                return result;
            })
    }
}

export async function getActiveCampaignData(siteId, env) {
    const envToPass = env === "stage" ? 2 : 1;
    const campaigns = await getCampaignsList(siteId, envToPass);
    const campaign = campaigns[0];

    if (!campaign || (campaign && campaign.deleted_at)) {
        return null;
    }

    campaign.stats = null;
    campaign.stats = await getCampaignStatsMessagingTotal(campaign);
    
    return campaignToRecord(campaign);
}

async function getMessagingTotal(campaign) {
    // debugger
    // return Promise.resolve({})

    const url = `/rtdp/msg/total?manifest_id=${campaign.id}`;
    if (isFetched(url)) {
        return Promise.resolve(FETCHED);
    } else {
        return fetch(permissionsSvsBase + url, {
            credentials: 'include',
            mode: 'cors',
            headers: {
            'Content-Type': 'application/json',
            },
        }).then(checkStatus)
            .then(resp => resp.json())
            .then(resp => {
                addToFetched(url);
                debugger
                return resp.data;
            })
    }
}

async function getCampaignStatsMessagingTotal(campaign) {
    //XXX
    // return await getMessagingTotal(campaign);
    return {};
}

export async function getLiveCampaignsData(siteId, campaignTypes, env) {
    //debugger
  const campaignPromises = campaignTypes.map((d, i) => {
      //debugger
    return getLiveCampaignsDataByType(siteId, d, env)
  });
  const combinedCampaigns = await Promise.all(campaignPromises).then((res) => res);
  const mergedCampaigns = [].concat.apply([], combinedCampaigns);
  const campaignsList = OrderedSet(mergedCampaigns).map(campaign => campaignToRecord(campaign));
  return campaignsList;
}

export async function getLiveCampaignsDataByType(siteId, campaignType, env) {
    //debugger
    const campaigns = await getCampaignsList(siteId, env, campaignType);
    //debugger
    const campaignsWithStats = [];

    for (const campaign of campaigns.toJS()) {
        campaign.stats = null;
        campaign.stats = await getCampaignStatsMessagingTotal(campaign);
        // debugger
        campaignsWithStats.push(campaign);
    }
    // if (campaigns.size) debugger
    // debugger
    return campaignsWithStats;
}

export async function getOldCampaignsAjax(siteId) {

    let prodCampaigns = await getCampaignsList(siteId, 1);
    if (prodCampaigns.length && !prodCampaigns[0]['deleted_at']) {
        prodCampaigns = prodCampaigns.slice(1);
    }

    let stageCampaigns = await getCampaignsList(siteId, 2);
    if (stageCampaigns.length && !stageCampaigns[0]['deleted_at']) {
        stageCampaigns = stageCampaigns.slice(1);
    }

    campaigns = prodCampaigns.concat(stageCampaigns);
    campaigns.sort((firstEl, secondEl) => sortByDate(firstEl, secondEl) );

    const campaignsWithStats = [];

    for (campaign of campaigns) {
        campaign.stats = null;
        campaign.stats = await getCampaignStatsMessagingTotal(campaign);
        campaignsWithStats.push(campaign);
    }

    return campaignsWithStats;
}

export function getCampaignGraphData(campaignId) {
    // return Promise.resolve({})

    const url = '/rtdp/msg/partition_series?manifest_id=' + campaignId;
    if (isFetched(url)) {
        return Promise.resolve(FETCHED);
    } else {
        return fetch(permissionsSvsBase + url, {
            credentials: 'include',
            mode: 'cors',
            headers: {
            'Content-Type': 'application/json',
            },
        }).then(checkStatus)
            .then(resp => resp.json())
            .then(resp => {
                addToFetched(url);
                return parseCampaignGraphData(resp.data);
            })
    }
}

function parseCampaignGraphData(graphData) {
    return fromJS(graphData).map((v) => PartitionsetGraphData({ adblock: List(v.get("desktop_ab_pct")), messages: List(v.get("total_msg"))}));
}

export async function getCampaignStatsAjax(campaignId) {
    // return Promise.resolve({})
    const url = '/rtdp/msg/campaign_stats?manifest_id=' + campaignId;
    if (isFetched(url)) {
        return Promise.resolve(FETCHED);
    } else {
        const checkStatus = await fetch(permissionsSvsBase + url, {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const resp = await checkStatus(checkStatus);
        const resp_1 = resp.json();
        addToFetched(url);
        return fromJS(resp_1.data);
    }
}

export function activateCampaignAjax(options) {
    const env = options.env === "stage" ? 2 : 1;
    const type = options.campaignType ? options.campaignType : 0;
    const url = `/msg/start_campaign?site_id=${options.siteId}&env=${env}&name=${options.name}&partitionset_id=${options.partitionsetId}&campaign_type_id=${type}`;
    return fetch(permissionsSvsBase + url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
        'Content-Type': 'application/json',
        },
        method: 'POST',
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
            const activeCampaigns = resp.data.site.manifests;
            let campaign = {};
            for (const [key, value] of Object.entries(activeCampaigns)) {
                if (parseInt(key) === type) {
                    campaign = value
                    campaign.campaign_type_id = type;
                    break;
                }
            }
            debugger
            return campaignToRecord(campaign)
        })
}

export function activateSiteGroupCampaignAjax(options) {
    let { accountId, siteGroupId, name, env, partitionsetId, campaignType } = options;
    let campaignTypeValue = 0;
    if(campaignType) {
      campaignTypeValue = campaignType;
    }
    env = env === "stage" ? 2 : 1;
    const url = `${ permissionsSvsBase }/site-group/start-campaign?siteGroupId=${ siteGroupId }&env=${ env }&name=${ name }&partitionSetId=${ partitionsetId }&campaignType=${campaignTypeValue}`;
    return fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
        'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ accountId }),
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
          return fromJS({
            campaigns: OrderedSet(resp.campaignResponseObjs.map(c => campaignToRecord(c))),
            sitesWithErrors: resp.sitesWithErrors,
            siteGroup: resp.siteGroup,
          });
        })
}

export function copyAndActivateSiteGroupCampaignInEnvAjax(options) {
    const { accountId, siteGroupId, currentEnv, campaignType } = options;

    let campaignTypeValue = 0;
    if(campaignType) {
       campaignTypeValue = campaignType;
    }

    var sourceEnv, destEnv;
    if (currentEnv === "stage") {
        sourceEnv =  2;
        destEnv = 1;
    } else {
        sourceEnv = 1;
        destEnv = 2;
    }

    const url = `${ permissionsSvsBase }/site-group/xenv_activate?siteGroupId=${ siteGroupId }&sourceEnv=${ sourceEnv }&destEnv=${ destEnv }&campaignType=${ campaignTypeValue }`;

    return fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
        'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ accountId }),
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
          return fromJS({
            campaigns: OrderedSet(resp.campaignResponseObjs.map(c => campaignToRecord(c))),
            sitesWithErrors: resp.sitesWithErrors,
          });
        })
}

export function copyAndActivateCampaignInEnvAjax(currentEnv, siteId, campaign_type_id) {
    var sourceEnv, destEnv;
    if (currentEnv === "stage") {
        sourceEnv =  2;
        destEnv = 1;
    } else {
        sourceEnv = 1;
        destEnv = 2;
    }
    const campaignType = campaign_type_id || 0;
    
    const url = `/msg/xenv_activate?site_id=${siteId}&source_env=${sourceEnv}&dest_env=${destEnv}&campaign_type_id=${campaignType}`;
    return fetch(permissionsSvsBase + url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
        'Content-Type': 'application/json',
        },
        method: 'POST',
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
            const activeCampaigns = resp.data.site.manifests;
            let campaign = {};
            for (const [key, value] of Object.entries(activeCampaigns)) {
                if (parseInt(key) === campaignType) {
                    campaign = value
                    campaign.campaign_type_id = campaignType;
                    break;
                }
            }
            return campaignToRecord(campaign)
        })
}

export function stopLiveCampaignAjax(campaign) {
    return fetch(`${permissionsSvsBase}/msg/stop_campaign?site_id=${campaign.site_id}&campaign_id=${campaign.id}&manifest_id=${campaign.id}&env=${campaign.environment}`, {
        credentials: 'include',
        mode: 'cors',
        headers: {
        'Content-Type': 'application/json',
        },
        method: 'POST',
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => campaignToRecord(resp.data.campaign));
}

export function stopSiteGroupLiveCampaignAjax(options) {
    let { accountId, siteGroupId, campaign } = options;
    const url = `${ permissionsSvsBase }/site-group/stop-campaign?siteGroupId=${ siteGroupId }&campaignId=${ campaign.id }&env=${ campaign.environment }`;

    return fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
        'Content-Type': 'application/json',
        },
        method: 'POST',
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
          return fromJS({
            campaigns: OrderedSet(resp.campaignResponseObjs.map(c => campaignToRecord(c))),
            sitesWithErrors: resp.sitesWithErrors,
          });
        });
}

export function campaignToRecord(campaign) {
    if (!campaign) return null;
    const partitionset = partitionsetToRecord(campaign.partition_set || campaign.partitionset_data);
    const stats = Map(campaign.stats).map(v => new StatsForPartition(v));
    campaign.id = campaign.manifest_id || campaign.id;
    campaign.description = campaign.name || campaign.description;
    delete campaign.manifest_id;
    delete campaign.name;
    if (!campaign.environment) campaign.environment = campaign.status < 4 ? 1 : 2;
    campaign.campaign_type_id = Number(campaign.campaign_type_id) || 0;
    return new Campaign(campaign).merge({ partition_set: partitionset, stats });
}

export function getRpmCookie(siteId) {
    return fetch('/api/v1/user/get_rpm_cookies?site_id=' + siteId, {
        credentials: 'include',
    }).then(checkStatus)
        .then(resp => resp.json())
}

export function setRpmCookie(siteId, rpm) {
    return fetch('/api/v1/user/set_rpm_cookies?site_id=' + siteId, {
        credentials: 'include',
        headers: { 'X-CSRF-Token': CSRF_TOKEN },
        method: 'POST',
        body: toFormData({ rpm: rpm.toJS() })
    }).then(checkStatus)
        .then(resp => resp.json())
}

function sortByDate (valueA, valueB) {
    // diff returns the time between two moments. We want to order by
    // closest to now first. When we diff one moment against a second
    // moment it returns a positive number if the first moment is
    // closest to now. Sorting should return a -1 if valueA comes
    // before valueB in the order. Thus, we diff valueB against
    // valueA. If valueB is closer to now we will return a positive
    // number, meaning valueB comes first in order. If valueA is
    // closer to now we will return a negative number, meaning valueA
    // comes first in order.
    return (new moment(valueB)).diff(new moment(valueA));
}