import React, { useContext } from 'react';
import { Toggle as AntSwitch } from '../../../../../../../../styleguide';
import { NeutralPmContext } from '../../../../../../../contexts';

const Switch = ({
  value,
  updateSetting,
  settingKey,
  settingsTitle,
}) => {
  const includesNeutralPm = useContext(NeutralPmContext)
  return (
    <div className="set-switch">
      <h4>{settingsTitle}</h4>
      <div>
        <AntSwitch
          disabled={settingKey === 'usePurModel' && includesNeutralPm}
          checked={value}
          onChange={(checked) => updateSetting(settingKey, checked)}
        />
      </div>
    </div>
  );
}

export default Switch;
