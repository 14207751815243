/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { CloseOutlined, WarningFilled } from '@ant-design/icons';

import { Modal, Button, Table, Toggle, SelectDropdown, Tag } from '../../../../styleguide';
import {
  createNotificationEmailPreferences,
  getNotificationEmailPreferences,
  updateNotificationEmailPreferences,
} from '../../../../api/diagnose_dashboard/notifications';

const emailColumn = {
  title: 'Email',
  dataIndex: 'email',
  key: 'email',
  render: (text) => {
    return <div>{text}</div>;
  },
  width: 220,
};

function EmailPreferencesModal({ notificationTypes, currentUser }) {
  const { email = '', accountId = '' } = currentUser || {};
  const [first_name = '', last_name = ''] = (currentUser?.name || '').split(' ');
  const [isLoading, setLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [all, ...initialNotificationTypes] = notificationTypes;
  const [savedUserPreferences, setSavedUserPreferences] = useState({
    email,
    first_name,
    last_name,
    emailing_enabled: true,
    preferences: [],
    initialStatus: true,
  });

  useEffect(() => {
    const getDefaultUserPreferences = async () => {
      try {
        setLoading(true);
        const users = await getNotificationEmailPreferences({ email });
        if (users.length) {
          setSavedUserPreferences({ ...users?.[0], initialStatus: users?.[0].emailing_enabled });
        } else {
          await createNotificationEmailPreferences(savedUserPreferences, accountId);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (currentUser.accountId === 22 || !currentUser.masqed) {
      getDefaultUserPreferences();
    }
  }, []);

  const handleToggleEmailPreferences = (newState) => {
    setSavedUserPreferences((userPreferences) => ({ ...userPreferences, emailing_enabled: newState }));
  };

  const handlePreferences = (newState) => {
    setSavedUserPreferences((userPreferences) => ({ ...userPreferences, preferences: newState }));
  };

  const columns = [
    emailColumn,
    {
      title: 'Alert Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        return (
          <SelectDropdown
            className="alert-type-select"
            options={notificationTypes}
            onChange={handlePreferences}
            value={
              initialNotificationTypes.every((pref) => record.preferences.includes(pref.value))
                ? notificationTypes
                : record.preferences
            }
            multiple
            tagRender={Tag}
            selectAll={false}
            showSearch={false}
          />
        );
      },
      width: 290,
    },
    {
      title: 'Action',
      dataIndex: 'emailing_enabled',
      key: 'emailing_enabled',
      render: (text) => (
        <>
          <Toggle
            id={'notification-list-toggle'}
            size="small"
            checked={text}
            title={text ? 'Running' : 'Paused'}
            onChange={handleToggleEmailPreferences}
          />
          <span>{text ? 'Running' : 'Paused'}</span>
        </>
      ),
      width: 190,
    },
  ];

  const openEmailPreferences = () => setModalVisible(true);
  const closeEmailPreferences = () => setModalVisible(false);
  const saveEmailPreferences = async () => {
    if (savedUserPreferences.emailing_enabled !== savedUserPreferences.initialStatus) {
      setShowConfirmation(true);
    } else {
      setLoading(true);
      await updateNotificationEmailPreferences(savedUserPreferences, accountId);
      setLoading(false);
      setModalVisible(false);
    }
  };

  const confirmEmailPreferences = async () => {
    setLoading(true);
    await updateNotificationEmailPreferences(savedUserPreferences, accountId);
    setLoading(false);
    setShowConfirmation(false);
    setModalVisible(false);
  };

  return (
    <>
      <Button
        disabled={!(currentUser.accountId === 22 || !currentUser.masqed)}
        onClick={openEmailPreferences}
        type="secondary"
      >
        Email Preferences
      </Button>
      <Modal
        className="diagnose-email-preferences-modal avocado-modal"
        closeIcon={<CloseOutlined />}
        title={<p style={{ margin: 0 }}>Email Preferences</p>}
        open={isModalVisible}
        onCancel={closeEmailPreferences}
        footer={null}
        destroyOnClose
        width={769}
      >
        <div className="content">
          <p>
            You can select all the alerts that you would like to get notified of via email below. At any point, you can
            choose to pause the emails by clicking on pause icon.
          </p>
          <Table
            dataSource={[savedUserPreferences]}
            bordered={false}
            rowKey={(record) => record?.email}
            scroll={{ y: 280 }}
            columns={columns}
            pagination={false}
            showSorterTooltip={false}
            loading={isLoading}
          />
          <div className="actions-container">
            {showConfirmation ? (
              <div className="submit-confirmation">
                <span>
                  <WarningFilled style={{ fontSize: '16px', color: '#FF9100', marginRight: '10px' }} />
                  Your email notification will be paused. Do you want to continue ?
                </span>
                <div className="confirmation-actions">
                  <Button disabled={isLoading} onClick={closeEmailPreferences} type="secondary">
                    Cancel
                  </Button>
                  <Button disabled={isLoading} loading={isLoading} onClick={confirmEmailPreferences} type="primary">
                    Confirm
                  </Button>
                </div>
              </div>
            ) : (
              <div className="actions">
                <Button disabled={isLoading} loading={isLoading} onClick={saveEmailPreferences} type="primary">
                  Save Preferences
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EmailPreferencesModal;
