import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { getCcpaDnsReportingAction } from '../../../actions/ccpa_reporting_actions.js';
import { getConsentReportChangeLogData } from '../../../actions/consent_performance_actions_v1.js';
import { getAllSites, getSiteGroups } from '../../../actions/site_actions.js';
import Loading from '../../common/Loading.js.jsx';
import CustomDateRangeSelect from '../../common/CustomDateRangeSelect';
import DatePickerReports from '../../common/DatePickerReports';
import { User } from '../../../records/account_records';
import CCPADnsaProperty from './CCPADnsaProperty';
import CCPADnsaMsg from './CCPADnsaMsg';
import CustomCriteriaSelect from './CustomCriteriaSelect';
import {
  defaultWeekStart,
  defaultWeekEnd,
} from '../../consent/gdpr_performance_tcfv2/helper.js';
import { Headline, Tabs } from '../../../styleguide';
const { TabPane } = Tabs;

class CCPAReporting extends React.PureComponent {
  static propTypes = {
    getAllSites: PropTypes.func.isRequired,
    sites: ImmutablePropTypes.list.isRequired,
    getConsentReportPropertyActionData: PropTypes.func.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const rangeSelect = Map({
      startDate: defaultWeekStart,
      endDate: defaultWeekEnd,
      datePicker: 'WEEK',
    });

    this.state = {
      datePicker: 'WEEK',
      criteriaList: List(),
      rangeSelect: rangeSelect,
      showTopLevelCriteria: true,
    };
  }

  componentDidMount() {
    this.props.getAllSites();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getSiteGroups(this.props.currentUser.accountId);
    }
  }

  selectDates = (dates, datePicker) => {
    let rangeSelect = this.state.rangeSelect
      .set('startDate', dates.start)
      .set('endDate', dates.end)
      .set('datePicker', datePicker);
    let criteriaList = this.state.criteriaList.map((c) =>
      c.set('startDate', dates.start).set('endDate', dates.end)
    );
    this.setState({ datePicker, rangeSelect, criteriaList }, () => {
      if (
        this.state.criteriaList.size <= 3 &&
        this.state.criteriaList.size > 0
      ) {
        let getSelectedSiteIds = List(
          this.state.criteriaList
            .map((c, i) => List(c.get('siteId')))
            .reduce((prev, current) =>
              prev
                .toSet()
                .union(current.toSet())
                .toList()
            )
        );
        this.props.getCcpaDnsReportingAction(
          this.state.criteriaList,
          this.state.rangeSelect
        );
        this.props.getConsentReportChangeLogData(
          getSelectedSiteIds,
          this.props.currentUser.accountId,
          this.state.rangeSelect
        );
      }
    });
  };

  selectDatePicker = (event, dates) => {
    this.selectDates(dates, event.target.value);
  };

  loadCriteria = (loadComponent) => {
    if (this.state.criteriaList.size === 0) {
      return (
        <div className="no-report-message">
          <p>Please add the criteria(s) to generate a U.S. Privacy report.</p>
        </div>
      );
    } else {
      return loadComponent;
    }
  };

  updateCriteriaListCb = (criteriaList) => {
    this.setState({ criteriaList }, () => {
      let getSelectedSiteIds = List(
        this.state.criteriaList
          .map((c) => List(c.get('siteId')))
          .reduce((prev, current) =>
            prev
              .toSet()
              .union(current.toSet())
              .toList()
          )
      );
      this.props.getCcpaDnsReportingAction(
        this.state.criteriaList,
        this.state.rangeSelect
      );
      this.props.getConsentReportChangeLogData(
        getSelectedSiteIds,
        this.props.currentUser.accountId,
        this.state.rangeSelect
      );
    });
  };

  render() {
    let { sites, siteGroups } = this.props;
    if (this.props.currentUser.siteAccess) {
      sites = sites.filter((s) =>
        this.props.currentUser.siteAccess.includes(s.id)
      );
      siteGroups = siteGroups
        .filter(({ siteIds }) => siteIds.toJS().every((id) => sites.toJS().find(site => site.id == id)));
    }
    const operations = (
      <div className="external filters">
        <CustomDateRangeSelect selectDatePicker={this.selectDatePicker} />
        <DatePickerReports
          className="cp-picker"
          selectDates={this.selectDates}
          format="MM/DD/YY"
          limit={60}
          disabled={this.props.pendingRequestsMap.some((request) => request)}
          disabledDateOffset={1}
          selectedDatePicker={this.state.rangeSelect.get('datePicker')}
        />
      </div>
    );
    let loading;
    let isLoading = false;
    if (
      this.props.pendingRequestsMap.get('sites') ||
      this.props.pendingRequestsMap.get('accountId')
    ) {
      loading = <Loading />;
      isLoading = true;
    }
    let propertyTableGraph =
      this.props.pendingRequestsMap.get('changeLogData') ||
        this.props.pendingRequestsMap.get('ccpaDnsaData') ? (
          <Loading />
        ) : (
          <CCPADnsaProperty
            ccpaDnsaData={this.props.ccpaDnsaData}
            changeLogData={this.props.changeLogData}
            criteriaList={this.state.criteriaList}
            sites={sites}
          />
        );
    let messageTableGraph =
      this.props.pendingRequestsMap.get('ccpaDnsaData') ||
        this.props.pendingRequestsMap.get('changeLogData') ? (
          <Loading />
        ) : (
          <CCPADnsaMsg
            ccpaDnsaData={this.props.ccpaDnsaData}
            changeLogData={this.props.changeLogData}
            criteriaList={this.state.criteriaList}
            sites={sites}
          />
        );
    return (
      <div className="consent-performance-v2">
        <Headline>U.S. Privacy Campaign Dashboard</Headline>
        <CustomCriteriaSelect
          sites={sites}
          siteGroups={siteGroups}
          showTopLevelCriteria={this.state.showTopLevelCriteria}
          updateCriteriaListCb={this.updateCriteriaListCb}
          rangeSelect={this.state.rangeSelect}
          showState={true}
          accountFeatures={this.props.currentUser.accountFeatures}
        />
        {isLoading ? loading : ''}
        <div className="consent-tabs-container">
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={operations}
            onChange={this.showTopLevelCriteria}
            className="spsg-normal"
          >
            <TabPane tab="Property" key="1">
              <div className="consentblock card">
                {this.loadCriteria(propertyTableGraph)}
              </div>
            </TabPane>
            <TabPane tab="Messages" key="2">
              <div className="consentblock card">
                {this.loadCriteria(messageTableGraph)}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    changeLogData: store.consentPerformanceReportState.getIn([
      'changeLogData',
      'value',
    ]),
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    ccpaDnsaData: store.ccpaDnsaPerformanceState.getIn([
      'ccpaDnsaData',
      'value',
    ]),
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      changeLogData: store.consentPerformanceReportState.getIn([
        'changeLogData',
        'pending',
      ]),
      ccpaDnsaData: store.ccpaDnsaPerformanceState.getIn([
        'ccpaDnsaData',
        'pending',
      ]),
    }),
  };
};

export default connect(mapStateToProps, {
  getAllSites,
  getConsentReportChangeLogData,
  getSiteGroups,
  getCcpaDnsReportingAction,
})(CCPAReporting);
