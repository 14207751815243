import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { Button, ConfigProvider, Input, Table } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { User } from '../../records/account_records';

import { masqAsClient } from '../../api/masq';
import { getAccountOwners } from '../../actions/account_actions';

export class MasqPage extends React.Component {
  static propTypes = {
    accountOwners: ImmutablePropTypes.listOf(PropTypes.instanceOf(User)).isRequired,
    getAccountOwners: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleAlphabeticalSort = this.handleAlphabeticalSort.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onHandleMasqClick = this.onHandleMasqClick.bind(this);

    this.state = {
      searchText: '',
      data: this.props.accountOwners,
    };
  }

  componentDidMount() {
    this.props.getAccountOwners();
  }

  onInputChange(e) {
    this.setState({
      searchText: e.target.value,
    });
  }

  onClear() {
    this.setState({
      searchText: '',
      data: this.props.accountOwners,
    });
  }

  onHandleMasqClick(accountId) {
    masqAsClient(accountId);
  }

  handleAlphabeticalSort(a, b, key) {
    const first = a[key].toUpperCase();
    const second = b[key].toUpperCase();

    if(first < second) return -1;
    if(first > second) return 1;
    return 0;
  }

  render() {
    if (!this.props.currentUser) return null;

    if (this.props.currentUser.accountId !== 22 && this.props.currentUser.accountId !== 375 && !this.props.currentUser.masqed) {
      browserHistory.replace('/');
    }
    let table;
    if (this.props.accountOwners) {
      const columns = [{
        title: 'Account Name',
        dataIndex: 'accountName',
        key: 'accountName',
        width: '33%',
        sorter: (a, b) => this.handleAlphabeticalSort(a, b, 'accountName'),
      }, {
        title: 'Account Id',
        dataIndex: 'accountId',
        key: 'accountId',
        width: '33%',
        sorter: (a, b) => a - b,
        render: accountId => <Button onClick={() => this.onHandleMasqClick(accountId)}>{accountId}</Button>
      }];

      let accounts = this.props.accountOwners;
      if (this.state.searchText) {
        accounts = this.props.accountOwners.filter(a => a.accountName.toLowerCase().includes(this.state.searchText.toLowerCase()) || String(a.accountId).includes(this.state.searchText.toLowerCase()))
      }
      if (this.props.currentUser.accountAccess) {
        accounts = accounts.filter(a => this.props.currentUser.accountAccess.includes(a.accountId));
      }
      const dataSource = accounts.map(account => {
        return {
          key: account.accountId,
          accountId: account.accountId,
          accountName: account.accountName,
        };
      });

      table = (
        <ConfigProvider locale={enUS}>
          <Table
            dataSource={ dataSource.toJS() }
            columns={ columns }
            pagination={ 30 }
            onChange={ this.onClick }
          />
        </ConfigProvider>
      );
    }

    return(
      <div className='masq-page'>
        <div className="search-container">
          <Input
            className='search-bar'
            ref={ el => this.searchInput = el }
            placeholder="Search"
            value={ this.state.searchText }
            onChange={ this.onInputChange }
          />
          <Button
            className='clear-button'
            type="primary"
            onClick={ this.onClear }
          >
            Clear
          </Button>
        </div>
        <div className='data-table'>
          { table }
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountOwners: store.accountState.getIn(['accountOwners', 'value']),
    accountOwnersPending: store.accountState.getIn(['accountOwners', 'pending']),
  };
};

export default connect(
  mapStateToProps, {
    getAccountOwners,
  },
)(MasqPage);
