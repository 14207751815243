import React, { Component } from 'react';
import { Slider } from '../../../../../../../styleguide';

const Opacity = (props) => {
  const { value, settingsTitle } = props;
  return (
    <div>
      <div className="set-flexalign">
        <h4>{settingsTitle}</h4>
      </div>
      <div className="option-inputs" style={{ padding: '0 10px', marginTop: '20px' }}>
        <Slider min={0} max={1} step={0.05}
          onChange={value => props.updateSetting(props.settingKey, value)}
          value={typeof value === 'number' ? value : 0}
        />
      </div>
    </div>
  );
};

export default Opacity;