import React from 'react';
import { TreeSelect as AntdTreeSelect } from 'antd';
import classNames from 'classnames';

const TreeSelect = (props) => {
    const propsData = { ...props };

    const classes = {
      'spsg-error': propsData.error,
    }
    let className = classNames('spsg-treeSelect', classes)

    // Handle Dropdown ClassName
    propsData.dropdownClassName = 'spsg-treeSelect-dropdown ' + (propsData.dropdownClassName || '');
    return (
      <div className={className}>
        <AntdTreeSelect  {...propsData} />
      { propsData.error && <div className="spsg-details">{ propsData.error }</div> }
      </div>
    );
}

TreeSelect.SHOW_PARENT = AntdTreeSelect.SHOW_PARENT;
export default TreeSelect;
