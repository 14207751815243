import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { Table as AntTable, Input, Button, Select } from 'antd';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { ConsentCompensationList, History } from '../../../../records/consent_compensation_records';
import EditVendorModal from './EditVendorModal';
import { CURRENCY_TO_SYMBOL } from '../../../../constants';
import CustomIcon from '../../../common/CustomIcon.js.jsx';

const Search = Input.Search;
const Option = Select.Option;

export default class Table extends React.Component {
  static propTypes = {
    consentCompensationList: PropTypes.instanceOf(ConsentCompensationList).isRequired,
    readOnly: PropTypes.bool.isRequired,
    changedStateByVendor: ImmutablePropTypes.map.isRequired,

    updateConsentCompensationList: PropTypes.func.isRequired,
    toggleAddVendors: PropTypes.func.isRequired,
    setChangedVendorState: PropTypes.func.isRequired,
  };

  state = {
    search: '',
    editVendorId: null,
    categoryFilter: 'All',
    rateEditingVenodrId: null,
    rate: null,
  };

  intersection = (arr1, arr2) => {
    const res = arr1.filter(e => arr2.includes(e));
    return res.size > 0;
  };

  updateSearch = (e) => this.setState({ search: e.target.value });

  removeVendor = (vendorId) => {
    const vendorsWrappers = this.props.consentCompensationList.get('vendorsWrappers').map(vw => {
      if (vw.vendorId === vendorId) {
        return vw.set('isDeleted', true);
      } else {
        return vw;
      }
    })

    const updatedList = this.props.consentCompensationList.set('vendorsWrappers', vendorsWrappers);
    this.props.updateConsentCompensationList(updatedList);
  }

  handleRateChange = (propName, vId, currentState) => {
    const updatedState = currentState.set(propName, this.state.rateEditing);
    this.props.setChangedVendorState(vId, updatedState);
    this.setState({ rateEditingVenodrId: null, rate: '' });
  }

  cancelRateChange = () => {
    this.setState({ rateEditingVenodrId: null, rate: '' });
  }

  handleAlphabeticalSort(a, b) {
    const first = a.toUpperCase();
    const second = b.toUpperCase();

    if(first < second) return -1;
    if(first > second) return 1;
    return 0;
  }

  render() {
    const vendorWrappers = this.props.consentCompensationList.vendorsWrappers.filterNot(vw => vw.isDeleted);

    const searchBar = (
      <Search
        placeholder="Search vendors"
        onChange={this.updateSearch}
        className="vendor-filter"
        style={{ width: 160 }}
      />
    )

    const categorySelector = (
      <div className='category-selector flex-row'>
        <p>Filter by:</p>
        <Select
          value={ this.state.categoryFilter }
          onChange={ (c) => this.setState({ categoryFilter: c}) }
        > 
          <Option value='All'>All</Option>
          <Option value='DATA_PARTNER'>Data Partner</Option>
          <Option value='SSP'>SSP</Option>
          <Option value='DSP'>DSP</Option>
        </Select>
      </div>
    )

    const columns = List([
      Map({
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => {
          if (!a.status.props.className.includes('green') && b.status.props.className.includes('green')) {
            return 1;
          } else if (a.status.props.className.includes('green') && !b.status.props.className.includes('green')){
            return - 1;
          } else {
            return 0;
          }
        },
      }),
      Map({
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          return this.handleAlphabeticalSort(a.name.props.children, b.name.props.children);
        }
      }),
      Map({
        title: 'Active Rate',
        dataIndex: 'activeRate',
        key: 'activeRate',
      }),
      Map({
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        sorter: (a, b) => {
          return this.handleAlphabeticalSort(a.category, b.category);
        }
      }),
      Map({
        title: 'Delete',
        dataIndex: 'delete',
        key: 'delete',
      }),
    ]);

    // Build out the table's row data using vendors
    let dataSource = vendorWrappers.map((vw) => {

      const name = (
        <div onClick={() => this.setState({ editVendorId: vw.vendorId })} className="vendor-name">
          { this.props.partners.find(p => p.id === vw.vendorId).name }
        </div>
      );

      let rate = 'n/a';
      let value;
      let propName;

      const currentState = this.props.changedStateByVendor.get(vw.vendorId) || vw.history.last();
      
      if (currentState && currentState.isActive) {
        if (currentState.rateType === 'CPM') {
          value = currentState.cpmAmount;
          propName = 'cpmAmount';
          rate = CURRENCY_TO_SYMBOL.get(currentState.currency) + ` ${value.toString()}`;
        } else {
          value = currentState.percentage;
          rate = `${value.toString()} %`;
          propName = 'percentage';
        }
      }

      // if (vw.vendorId === this.state.rateEding) {

      if (vw.vendorId === this.state.rateEditingVenodrId) {
        rate =
        (<div className='edit-rate flex-row'>
          <Input 
            value={ this.state.rateEditing } 
            onChange={ (e) => this.setState({ rateEditing: e.target.value }) }
          />
          <Button className='cancel' onClick={ this.cancelRateChange }>Cancel</Button>
          <Button className='save' onClick={ (e) => this.handleRateChange(propName, vw.vendorId, currentState) } type="primary">Save</Button>
        </div>)
      } else {
        rate =
          (<div className='edit-rate' onClick={ () => this.setState({ rateEditingVenodrId: vw.vendorId, rateEditing: value }) }>
            { rate }
            <CustomIcon
              type={ CustomIcon.types.EDIT }
              tooltip="edit"
            />
          </div>)
      }

      const isActive = currentState ? currentState.isActive : true;
      const status = <i className={`fas fa-circle ${ isActive ? 'green' : 'gray'}`} />

      return Map({
        key: vw.vendorId,
        status,
        name,
        category: currentState ? currentState.category : 'Data Partner',
        activeRate: currentState ? rate : 'n/a',
        delete: <DeleteOutlined onClick={ () => this.removeVendor(vw.vendorId) } />,
      });
    });

    // Filter vendor rows based on search
    dataSource = dataSource.filter((vw) => {
      if (!this.state.search) return true;
      return vw.get('name').props.children.toUpperCase().indexOf(this.state.search.toUpperCase()) !== -1;
    });

    // Filter vendor rows based on category
    dataSource = dataSource.filter((vw) => {
      if (this.state.categoryFilter === 'All') return true;
      return vw.get('category') === this.state.categoryFilter;
    });

    let editVendorModal;
    if (this.state.editVendorId) {

      const vw = this.props.consentCompensationList.vendorsWrappers.find(vw => vw.vendorId === this.state.editVendorId)
      const currentState = this.props.changedStateByVendor.get(this.state.editVendorId) || vw.history.last() || new History()

      editVendorModal = (
        <EditVendorModal
          currentState={ currentState }
          setChangedVendorState={ this.props.setChangedVendorState }
          vendorWrapper={ vw }
          vendor={ this.props.partners.find(p => p.id === this.state.editVendorId) }
          closeModal={ () => this.setState({ editVendorId: null }) }
          readOnly={ this.props.readOnly }
          visible
        />
      );
    }

    return (
      <div className="consent-compensation-table-container">
        <div className='flex-row table-top'>
          <Button size="large" className="add-vendor-btn" onClick={ this.props.toggleAddVendors } disabled={ this.props.readOnly }>+ Add Vendor</Button>
          <div className='flex-row'>
            { searchBar }
            { categorySelector }
          </div>
        </div>
        <AntTable
          bordered={ false }
          dataSource={ dataSource.toJS() }
          columns={ columns.toJS() }
        />
        { editVendorModal }
      </div>
    );
  }
}
