import { nativeOtt } from 'message-preact';
import React from 'react';
import { useRef } from 'react';
import { NOTICE_URL } from '../../constants.js';
import { getSubCategory, replaceWithVariables } from '../../util.js';

export const ViewMessage = ({ viewMsg, siteId, isNativeOtt }) => {

  const iframeRef = useRef(null);

  const updateIframe = (messageJSON, variables) => {
    const myIframe = iframeRef.current;
    const isMessageNativeOtt =
      isNativeOtt === undefined
        ? getSubCategory(viewMsg.get("message_sub_category_id")) === "native-ott"
        : isNativeOtt;
    if (isMessageNativeOtt) {
      const components = messageJSON.children;
      myIframe.contentWindow.postMessage(JSON.stringify({
        components: replaceWithVariables(components, variables),
        current: [0],
        siteId,
        currentView: nativeOtt.constants.HOME,
        currentComponentPath: [0],
        root: messageJSON
      }), '*');

      return
    }

    const component = messageJSON.siblings || [messageJSON];
    myIframe.contentWindow.postMessage(JSON.stringify({
      components: replaceWithVariables(component, variables),
      current: [0],
      siteId: siteId
    }), '*');
  };

  const buildIframeUrl = () => {
    const subCategory = getSubCategory(viewMessage.message_sub_category_id, viewMessage.message_category_id);
    let url = `${NOTICE_URL}/${subCategory}/index.html?type=full&site_id=${siteId}`;
    if (viewMessage.message_category_id === 6 && viewMessage.message_sub_category_id === 1) {
      url += '&is_usnat_notice=true'
    }
    return url;
  }

  const viewMessage = viewMsg.toJS();

  return (
    <div className="scenario-view-message-wrapper">
      <iframe
        ref={iframeRef}
        title="scenario-message-iframe"
        onLoad={() => updateIframe(JSON.parse(viewMessage.message_json), viewMessage.variables)}
        id={`builder-preview-scenario-${viewMessage.id}`}
        src={buildIframeUrl()}
      />
    </div>
  );
};