import 'whatwg-fetch';
import { List } from 'immutable';
import _ from 'lodash';
import { checkStatus } from './helper.js';
import { OffersSoldByDay, OfferTotal, OfferDetails, Price, Converter } from '../records/altpay_records.js';


// ALTPAY PAGE

export function getAltpaySold(accountId, siteIds) {
    const altpayPromises = siteIds.map(siteId => {
        return fetch('https://altpay.sp-prod.net/altpay/report/1/tx_summary_by_day?site_id=' + siteId + '&account_id=' + accountId, {
            credentials: 'include',
        }).then(checkStatus)
            .then(resp => resp.json())
            .then(resp => resp.data.data)
    })
    return Promise.all(altpayPromises).then(values => {
        return altpayToRecord(_.compact(_.flatten(values)))
    })
}

export function getRateExchangeApi() {
    return fetch('http://data.fixer.io/api/latest?base=USD&access_key=7830fa626117af7fceab470233cfd07d')
        .then(checkStatus)
        .then(resp => resp.json())
        .then(resp => exchangeRatesToRecord(resp.rates))
}

function exchangeRatesToRecord(data) {
    return new Converter({
      EUR: 1/data.EUR,
      CHF: 1/data.CHF,
      GBP: 1/data.GBP,
    })
}

function altpayToRecord(offersArray) {
    const offersByDay = List(offersArray);

    return offersByDay.map(byDay => {

        const soldOffers = List(byDay.sold_offers).map(offer => {
            const offerDetails = OfferDetails({
                id: offer.offer.id,
                siteId: offer.offer.site_id,
                entitlementId: offer.offer.entitlement_id,
                price: new Price({ amount: offer.offer.amount, currency: offer.offer.currency }),
                description: offer.offer.description,
            });
            return (
                OfferTotal({
                    offerDetails: offerDetails,
                    numSold: offer.num_sold,
                    totalPrice: new Price({ amount: offer.total_value, currency: offer.currency }),
                })
            );
        });

        return (
            OffersSoldByDay({
                timestampSec: byDay.timestamp_sec,
                timestampHr: byDay.timestamp_hr,
                soldOffers,
            })
        );
    });
}
