import React, { Component } from 'react';
import { Select } from 'antd';

const { Option } = Select;

class ToggleType extends Component {


  render() {

    const { value, settingsTitle } = this.props;

    return (
      <div className="four-options">
      <h4>{settingsTitle}</h4>
      <div className="option-inputs">
        <Select value={value} style={{ width: '100%' }} onChange={(value) => this.props.updateSetting(this.props.settingKey, value)}>
          <Option value="basic"><i>Toggle</i></Option>
          <Option value="neutral"><i>Neutral Toggle</i></Option>
        </Select>
      </div>
    </div>
    );
  }
}

export default ToggleType;