/* eslint-disable class-methods-use-this */
import { CONDENCE_MODE } from '../ChartFiltersContext';

export default class ConsentRule {
  isSupport(filters, node) {
    return filters.highlightPriorToConsent && node.data?.attributes?.is_prior_to_consent;
  }

  getStrokeColor(filters, node, result) {
    return filters.search_by_vendor_name_mode === CONDENCE_MODE.SEARCHED
      ? '#E5E5E5'
      : `${result.at(result.length - 1)}60`;
  }
  getBorderColor(filters, node, result) {
    return filters.search_by_vendor_name_mode === CONDENCE_MODE.SEARCHED
      ? '#E5E5E5'
      : `${result.at(result.length - 1)}60`;
  }
  getTextColor(filters, node, result) {
    return filters.search_by_vendor_name_mode === CONDENCE_MODE.SEARCHED
      ? '#C4C4C4'
      : `${result.at(result.length - 1)}60`;
  }
  getNodeBackgroundColor(filters, node, result) {
    return filters.search_by_vendor_name_mode === CONDENCE_MODE.SEARCHED ? '#fff' : `${result.at(result.length - 1)}60`;
  }
}
