import React, { useState } from 'react';
import { Modal } from 'antd';
import { publicComponents, privateComponentsList } from './AddComponent/componentsList';
import { useEffect } from 'react';
import _ from 'lodash';

const MissingRequiredModal = ({
  visible,
  handleCancel,
  handleOk,
  views,
}) => {
  const viewsWithMissingList = views.reduce((viewsWithMissing, currentView) => {
    return currentView.components.some(({ added }) => !added)
      ? [...viewsWithMissing, currentView.name]
      : viewsWithMissing;
  }, []);

  const [selectedView, setSelectedView] = useState('');
  useEffect(() => setSelectedView(viewsWithMissingList[0]),  [viewsWithMissingList[0]]);

  const missingComponentsList = (views
    .find(({ name }) => name === selectedView) || { components: [] }).components
    .filter(({ added }) => !added)
    .map((component) => {
      const matchingComponent = [...publicComponents, ...privateComponentsList].find(c => c.title === component.schema.type);
      return {
        ...matchingComponent,
        presentationalTitle: (component.configuration && component.configuration.name)
          ? component.configuration.name
          : matchingComponent.presentationalTitle || matchingComponent.title,
      }
    });

  const missingViewsList = (
    <div className="missing-views-list">
      You're missing required components in the following views: {viewsWithMissingList.map((view, i) => (
        <React.Fragment>
          <span
            key={view}
            className={(selectedView === view )? 'selected' : 'not-selected'}
            onClick={(selectedView !== view) ? () => setSelectedView(view) : _.noop}
          >
            {view}
          </span>
          {i < viewsWithMissingList.length - 1 ? ', ' : ''}
        </React.Fragment>
      ))}
    </div>
  )
  return (
    <Modal
      title="Missing Required Components"
      visible={visible}
      onCancel={handleCancel}
      onOk={() => handleOk(selectedView)}
      width={800}
      okText="Add Components"
      cancelText="Close"
    >
      <div className="add-component-modal">
        <div className="components-grid">
          {missingViewsList}
          <div className={`components-holder missing-components-holder ${missingComponentsList.length > 4 ? "extra-rows" : ''}`}>
            {missingComponentsList.map((c, i) => (
              <div
                key={c.title + i}
                className="component-item missing-component-item"
                key={'comp-s-' + i}
              >
                <div className="frame">
                  <div className="ci-icon">{c.icon}</div>
                  <div className="ci-title">{c.presentationalTitle || c.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MissingRequiredModal;