import React, { Component } from 'react';
import { Slider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { scoreThresholdCSV, summaryResultsCSV } from '../../CSV/generateCSV';

export default class PropertyListDownload extends Component {
  getExport = async (type) => {
    const { scores, selectedSetName } = this.props;
    scoreThresholdCSV(type, this.props.threshold, scores || [], selectedSetName);
  }
  render() {
    const { scoreData, meetingThreshold, selectedSetName, scores } = this.props;

    const sliderMarks = {
      0: '0',
      50: '50',
      100: '100',
    };

    const percent = Math.round((meetingThreshold / scoreData.scores.totalScored) * 100) ;

    return (
      <div className="pl-all">
        <div className="pl-download">
          <div className="download">
            <h2>Properties above threshold</h2>
          </div>
          <div className="sldr">
            <p>Use the slider below to define your score threshold.</p>
            <Slider 
              marks={sliderMarks} 
              defaultValue={50} 
              value={this.props.threshold}
              onChange={this.props.updateThreshold}
            />
          </div>
          <div className="scoreboard">
            <div className="score">
              <div className="score-info">
                <h4>Properties Meeting Threshold</h4>
                <span className="download" onClick={() => this.getExport('all')}>
                  <FontAwesomeIcon icon={faDownload} />
                </span>
                <div className="avg-score">Avg. Score: <b>{scoreData.scores.meetThreshold.avgScore}</b></div>
                <div className="score-amnt">
                  <div className="score-count">{meetingThreshold.filtered}<div>of {meetingThreshold.total}</div></div>
                  <div className="progress blue"><div className="amount" style={{width: meetingThreshold.percent + '%'}} /></div>
                </div>
              </div>
              <div className="score-percent blue">{meetingThreshold.percent}<i>%</i></div>
            </div>
          </div>
        </div>
        <div className="pl-download second">
          <div className="download">
            <h2>Property List Download</h2>
          </div>
          <div className="scoreboard">
            <div className="scr-title">
              <div className="ttl">
                <b>Safe Properties</b>
                <div>Properties passing all the "Must Have" rules</div>
              </div>
              <div className="score">
                <div className="score-info">
                  <h4>Safe Properties</h4>
                  <span className="download" onClick={() => summaryResultsCSV('safe', scores, selectedSetName)}>
                    <FontAwesomeIcon icon={faDownload} />
                  </span>
                  <div className="avg-score">Avg. Score: <b>{scoreData.scores.safe.avgScore}</b></div>
                  <div className="score-amnt">
                    <div className="score-count">{scoreData.scores.safe.count}<div>of {scoreData.scores.totalScored}</div></div>
                    <div className="progress green"><div className="amount" style={{width: scoreData.scores.safe.percent + '%'}} /></div>
                  </div>
                </div>
                <div className="score-percent green">{scoreData.scores.safe.percent}<i>%</i></div>
              </div>
            </div>
            
            <div className="scr-title">
              <div className="ttl">
                <b>Unsafe Properties</b>
                <div>Properties that fail one or more "Must Have" rules</div>
              </div>
              <div className="score">
                <div className="score-info">
                  <h4>Unsafe Properties</h4>
                  <span className="download" onClick={() => summaryResultsCSV('unsafe', scores, selectedSetName)}>
                    <FontAwesomeIcon icon={faDownload} />
                  </span>
                  <div className="avg-score">Avg. Score: <b>{scoreData.scores.unsafe.avgScore}</b></div>
                  <div className="score-amnt">
                    <div className="score-count">{scoreData.scores.unsafe.count}<div>of {scoreData.scores.totalScored}</div></div>
                    <div className="progress red"><div className="amount" style={{width: scoreData.scores.unsafe.percent + '%'}} /></div>
                  </div>
                </div>
                <div className="score-percent red">{scoreData.scores.unsafe.percent}<i>%</i></div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
    );
  }
}
