import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Divider, Table, Tooltip, Button, Radio } from 'antd';
import { InfoCircleFilled, DownloadOutlined } from '@ant-design/icons';

import {
  VendorTypeColumn,
  TechnologyCategorisationColumn,
  PrevalenceColumn,
  VendorNameColumn,
  PrevalenceBenchMarkColumn,
} from '../../shared/DiagnoseColumns';

import {
  getDisclosedVendors,
  getDisclosedVendorsFilter,
  disclosedVendorsExport,
} from '../../../../../actions/diagnose_dashboard_actions';
import CellContentWithPrimaryTag from '../../shared/CellContentWithPrimaryText';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import useSearchParams from '../../shared/useSearchParams';
import { isFiltersProvided, mapSelectedFilters } from '../helpers';
import { browserHistory } from 'react-router';

const DetailedBreakdown = ({
  getDisclosedVendors,
  disclosedVendors,
  disclosedVendorsPending,
  disclosedVendorsFilter,
  getDisclosedVendorsFilter,
  selectedFilters,
  disclosedVendorsExportPending,
  disclosedVendorsExport,
}) => {
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder, setOrderBy } = useTableDisplayConfig(
    10,
    1,
    'prevalence_asc',
  );
  const searchParams = useSearchParams();
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);

  const [filteredInfo, setfilteredInfo] = useState(null);
  const [sortedInfo, setsortedInfo] = useState({ order: 'ascend', columnKey: 'prevalence' });

  const [prevalenceTab, setPrevalenceTab] = useState('full');

  const getDisclosedVendorsData = (currentPrevalenceTab, tableFiltersData = {}, vendorId) => {
    getDisclosedVendors({
      ...filters,
      page,
      limit,
      orderBy,
      tableFiltersData,
      currentPrevalenceTab,
      vendorId,
    });
  };

  const getDisclosedVendorsFiltersData = () => {
    getDisclosedVendorsFilter({ ...filters, currentPrevalenceTab: prevalenceTab });
  };

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      getDisclosedVendorsData(prevalenceTab, filteredInfo || {}, searchParams?.vendor && [searchParams.vendor]);
    }
  }, [filters, page, limit, orderBy, filteredInfo, prevalenceTab]);

  useEffect(() => {
    if (isFiltersProvided(filters)) {
      setfilteredInfo(null);
      getDisclosedVendorsFiltersData();
    }
  }, [filters, prevalenceTab]);

  const vendorsFilter = disclosedVendorsFilter?.vendors?.map((filter) => ({ text: filter, value: filter }));
  const technologiesFilter = disclosedVendorsFilter?.technologies?.map((filter) => ({ text: filter, value: filter }));

  const columns = [
    {
      title: '',
      children: [
        {
          ...VendorTypeColumn({}),
          filteredValue: filteredInfo && (filteredInfo.vendor_type || null),
        },
        {
          ...VendorNameColumn({ filters: vendorsFilter || [] }),
          sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
          filteredValue: filteredInfo && (filteredInfo.name || null),
          render: (text) => (
            <Tooltip
              placement="leftTop"
              title={() => {
                return <div className="detailed-breakdown-cell-tooltip-content">{text}</div>;
              }}
              overlayClassName="diagnose--tooltip detailed-breakdown-cell-tooltip-overlay"
            >
              <div>{text}</div>
            </Tooltip>
          ),
        },
        {
          className: 'prevalence-rate-comparison-column',
          title: 'Prevalence Rate Comparison',
          // key: 'technologies',
          render: (text, record) =>
            record.prevalence !== null || record.prevalence_benchmark !== null ? (
              <Tooltip
                placement="bottomLeft"
                title={() => {
                  return (
                    <div className={'prevalence-rate-comparison-cell-tooltip-content'}>
                      <div className={'tooltip-header'}>Vendor prevalence</div>
                      <div>
                        <div>Your properties</div>
                        <div>
                          <b>{Math.round(record.prevalence)}%</b>
                        </div>
                      </div>
                      <div>
                        <div>Benchmark properties</div>
                        <div>
                          <b>{Math.round(record.prevalence_benchmark)}%</b>
                        </div>
                      </div>
                    </div>
                  );
                }}
                overlayClassName="diagnose--tooltip prevalence-rate-comparison-cell-tooltip-overlay"
              >
                <div className={'prevalence-rate-comparison-cell'}>
                  <div className={'prevalence-rate-cell'} style={{ width: `${record.prevalence}%` }} />
                  <div
                    className={'prevalence-benchmark-rate-cell'}
                    style={{ width: `${record.prevalence_benchmark}%` }}
                  />
                </div>
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Vendor prevalence in Scans',
      children: [
        {
          ...PrevalenceColumn,
          width: undefined,
          title: () => (
            <div className="prevalence-title-cell">
              <div className="vendor-type-cell-content" style={{ backgroundColor: '#09b3a5' }} />
              <div>Your properties</div>
            </div>
          ),
          sortOrder: sortedInfo && sortedInfo.columnKey === 'prevalence' && sortedInfo.order,
          filteredValue: filteredInfo && (filteredInfo.prevalence || null),
          render: (text) =>
            text !== null && (
              <div className={'prevalence-cell'}>
                <div>{Math.round(text)}</div> %
              </div>
            ),
        },
        {
          ...PrevalenceBenchMarkColumn,
          width: undefined,
          title: () => (
            <div className="prevalence-title-cell">
              <div className="vendor-type-cell-content" style={{ backgroundColor: '#d9e9ff' }} />
              <div>Benchmark</div>
            </div>
          ),
          filteredValue: filteredInfo && (filteredInfo.prevalence_benchmark || null),
          sortOrder: sortedInfo && sortedInfo.columnKey === 'prevalence_benchmark' && sortedInfo.order,
          render: (text) =>
            text !== null && (
              <div className={'prevalence-benchmark-cell'}>
                <div>{Math.round(text)}</div> %
              </div>
            ),
        },
      ],
    },
    {
      title: '',
      children: [
        {
          ...TechnologyCategorisationColumn({ filters: technologiesFilter || [] }),
          width: undefined,
          className: 'technologies-column',
          sortOrder: sortedInfo && sortedInfo.columnKey === 'technologies' && sortedInfo.order,
          filteredValue: filteredInfo && (filteredInfo.technologies || null),
          render: (text) => {
            const [firstWord, ...restWords] = text;
            return (
              <CellContentWithPrimaryTag
                className={'technologies-cell'}
                content={firstWord}
                tooltipTitle={`+${restWords.length}`}
                tooltipContent={restWords.join('; ')}
              />
            );
          },
        },
      ],
    },
  ];

  const onTableChange = (pagination, filters, sorter) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);

    setsortedInfo(sorter);
    setfilteredInfo(filters);
    browserHistory.replace(location.pathname);
  };

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const exportCsv = () => {
    disclosedVendorsExport({ ...filters, currentPrevalenceTab: prevalenceTab });
  };

  const changePrevalenceTab = (tab) => {
    handlePageChange(1);
    setfilteredInfo(null);
    setsortedInfo(null);
    const newSortedInfo =
      tab === 'properties'
        ? { order: 'descend', columnKey: 'prevalence_benchmark' }
        : { order: 'ascend', columnKey: 'prevalence' };
    setsortedInfo(newSortedInfo);
    const newOrderBy = tab === 'properties' ? 'prevalence_benchmark_desc' : 'prevalence_asc';
    setOrderBy(newOrderBy);
    setPrevalenceTab(tab);
    browserHistory.replace(location.pathname);
  };

  return (
    <div className={'detailed-breakdown-container'}>
      <div>
        The detailed breakdown shows you the prevalence of the vendors we have observed across your properties and your
        benchmark properties so you can make an informed decision about which vendors to disclose on your vendor list.
      </div>
      <Divider />
      <div className="detailed-breakdown-table-container disclosed-vendors-table-container">
        <div className={'disclosed-vendors-tabs-container'}>
          <div className={'disclosed-vendors-header'}>
            <div className={'headers secondary_header'}>Prevalence</div>
            <Tooltip
              overlayClassName="diagnose--tooltip"
              title={
                'Prevalence is the count of the scans on the selected properties containing the vendor over all scans on the selected properties'
              }
            >
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div className={'disclosed-vendors-tabs'}>
            <Radio.Group
              defaultValue={prevalenceTab}
              onChange={(e) => {
                changePrevalenceTab(e.target.value);
              }}
            >
              <Radio.Button value="full">My disclosed vendors</Radio.Button>
              <Radio.Button value="properties">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '5px' }}>Other vendors</div>
                  <Tooltip
                    overlayClassName="diagnose--tooltip"
                    title={
                      'These vendors are vendors we have observed on your properties or your benchmark’s properties that are not disclosed already on your CMP vendor list'
                    }
                  >
                    <InfoCircleFilled />
                  </Tooltip>
                </div>
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className={'filters_block'}>
          <div className={'quick_filters'} />
          <div className={'downloadCSV'}>
            <Button icon={<DownloadOutlined />} onClick={exportCsv} loading={disclosedVendorsExportPending} />
          </div>
        </div>
        <Table
          id="detailed-breakdown"
          dataSource={disclosedVendors?.results}
          scroll={{ x: 700, y: 430 }}
          rowKey={(record) => record?.vendor_id}
          rowClassName={(record) =>
            record.vendor_id === searchParams?.vendor && searchParams?.type === 'NEW_VENDOR'
              ? 'diagnose-notification-highlight'
              : ''
          }
          columns={columns}
          showSorterTooltip={false}
          pagination={{
            current: page,
            pageSize: limit,
            total: disclosedVendors?.total_count,
            position: ['bottomCenter'],
            onChange: onPaginationChange,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
          }}
          onChange={onTableChange}
          loading={{
            className: 'diagnose-spinning',
            spinning: disclosedVendorsPending,
            tip: disclosedVendors?.results?.length
              ? ''
              : 'Processing a large query; please wait or leave it open and check later.',
            size: 'large',
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    disclosedVendors: store.diagnoseDashboardState.getIn(['disclosedVendors', 'value']),
    disclosedVendorsPending: store.diagnoseDashboardState.get('disclosedVendorsPending'),
    disclosedVendorsExportPending: store.diagnoseDashboardState.get('disclosedVendorsExportPending'),
    disclosedVendorsFilter: store.diagnoseDashboardState.getIn(['disclosedVendorsFilter', 'value']),
    selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDisclosedVendors,
      getDisclosedVendorsFilter,
      disclosedVendorsExport,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);
