import React from 'react';
import { Select } from '../../../styleguide';
const { Option } = Select;

export default class PropertyGroupSelect extends React.Component {
    render() {
        return (
            <Select
                defaultValue={this.props.defaultValue}
                value={this.props.value}
                onChange={(value) => this.props.onChange(this.props.state, value)}
            >
                {this.props.options.map((p, pi) => (
                    <Option key={pi} value={p.get('value') || p.get('siteId')}>
                        {p.get('label') || p.get('name')}
                    </Option>
                ))}
            </Select>
        );
    }
}