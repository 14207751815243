import React from 'react';
import CustomButton from '../common/CustomButton.js.jsx';
import CustomRangePicker from './CustomRangePicker';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Loading from '../common/Loading.js.jsx';
import SelectCampaigns from './SelectCampaigns';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { Select, Input, Modal } from 'antd';
import { StoreRecord } from '../../records/store_records';
import { UrlParams, ReportParams, RequestWithParams } from '../../records/reports_records.js';
import { User } from '../../records/account_records';
import { connect } from 'react-redux';
import { getAllSites, getSiteGroups } from '../../actions/site_actions.js';
import { getProdCampaignsForMultSites } from '../../actions/campaign_actions.js';
import { getListOfReports, addRequestToSubmitted } from '../../actions/custom_report_actions.js';
import { emailCustomReport } from '../../actions/account_actions.js';
import SelectSitesAndSiteGroups from '../common/SelectSitesAndSiteGroups';
import { validateEmail } from '../utils';

const { Option } = Select;

export class CustomReport extends React.Component {
  static propTypes = {
    reportsList: ImmutablePropTypes.list.isRequired,
    sites: ImmutablePropTypes.list.isRequired,
    campaigns: ImmutablePropTypes.orderedSet.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    emailReport: PropTypes.instanceOf(StoreRecord).isRequired,
    requestsSubmitted: ImmutablePropTypes.listOf(PropTypes.instanceOf(RequestWithParams)).isRequired,
    accountId: PropTypes.number.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      isMasq: PropTypes.bool.isRequired,
    }).isRequired,

    getListOfReports: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
    getProdCampaignsForMultSites: PropTypes.func.isRequired,
    emailCustomReport: PropTypes.func.isRequired,
    addRequestToSubmitted: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const update = {};

    if (!prevState.email && nextProps.currentUser) {
      update.email = nextProps.route.isMasq ? '' : nextProps.currentUser.email;
    }

    if (nextProps.emailReport.pending) {
      update.requestPending = true;
    }

    if (prevState.requestPending) {
      let alertMessage;
      if (nextProps.emailReport.value) {
        alertMessage = 'Report request successfully submitted. You will receive the results at ' + prevState.email + ' once they are complete.';
      } else if (nextProps.emailReport.error) {
        alertMessage = nextProps.emailReport.error;
      }
      update.alertMessage = alertMessage;
      update.requestPending = false;
    }

    if (prevState.reportParams.get('account_id') !== nextProps.currentUser.accountId) {
      update.reportParams = prevState.reportParams.set('account_id', nextProps.currentUser.accountId);
    }

    return update;
  }

  state = {
    selectedReport: null,
    reportParams: new ReportParams(),
    email: this.props.route.isMasq ? '' : this.props.currentUser && this.props.currentUser.email,
    sites: this.props.sites,
    alertMessage: '',
    requestPending: false,
    requestsSubmitted: List([]),
  };

  componentDidMount() {
    this.props.getAllSites();
    if (this.props.sites.size) {
      this.props.getProdCampaignsForMultSites(this.getUserSites().map(s => s.id));
    }
    if (this.props.currentUser) {
      this.props.getListOfReports(this.props.currentUser.accountId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getListOfReports(this.props.currentUser.accountId);
    }

    if (!prevProps.sites.size && this.props.sites.size) {
      this.props.getProdCampaignsForMultSites(this.getUserSites().map(s => s.id));
    }

    if (prevProps.currentUser.accountId !== this.props.currentUser.accountId) {
      this.props.getSiteGroups(this.props.currentUser.accountId);
    }
  }

  getUserSites = () => {
    if (this.props.currentUser.siteAccess) {
      return this.props.sites.filter(s => this.props.currentUser.siteAccess.includes(s.id));
    } else {
      return this.props.sites;
    }
  }

  getUserSiteGroups = () => {
    if (this.props.currentUser.siteAccess) {
      return this.props.siteGroups.filter(sg => {
        return sg.siteIds.every(id => {
          return this.props.currentUser.siteAccess.includes(id);
        });
      });
    } else {
      return this.props.siteGroups;
    }
  }

  selectReport = (idx) => {
    this.setState({
      selectedReport: idx,
    });
  }

  selectDates = (dates) => {
    this.setState({ reportParams: this.state.reportParams.set('start_date', dates.start.format('YYYY-MM-DD')).set('end_date', dates.end.format('YYYY-MM-DD')) });
  }

  selectSites = (siteIds, paramName) => {
    let sites = siteIds;
    let reportsParams = this.state.reportParams;
    if (paramName === 'multi_domain_name') {
      sites = this.getSiteDomainsFromIds(siteIds);
      reportsParams = reportsParams.set('multi_site_id', siteIds);
    }
    this.setState({ reportParams: reportsParams.set(paramName, sites) });
  }

  selectCampaigns = (campaignIds) => {
    const paramName = Array.isArray(campaignIds) ? 'multi_manifest_id' : 'manifest_id';
    this.setState({ reportParams: this.state.reportParams.set(paramName, campaignIds) });
  }

  getSiteDomainsFromIds = (siteIds) => {
    const domains = siteIds.map(id => {
      let domain = this.getUserSites().find(s => s.id === id).domain;
      domain = domain.replace('*', '%');
      return domain;
    });

    return domains;
  }

  emailReport = () => {
    const report = this.filteredReports().get(this.state.selectedReport);
    let args = this.state.reportParams;
    if (args.multi_site_id) {
      args = args.set('multi_site_id', List(args.multi_site_id).filterNot(id => id === 'allSites').toJS());
    }

    args = args.toMap().filter((v,k) => report.params.includes(k));

    const params = new UrlParams({
      user_name: this.props.currentUser.name,
      user_email: this.state.email,
      args,
    });

    const requestWithParams = new RequestWithParams({ endpoint: report.endpoint, params });
    const duplicateRequest = this.props.requestsSubmitted.find(v => {
      return v.endpoint === requestWithParams.endpoint && JSON.stringify(v.params.args) === JSON.stringify(requestWithParams.params.args);
    });
    if (duplicateRequest) {
      this.setState({ alertMessage: 'Report with these parameters was already requested. You will receive the results at ' + duplicateRequest.params.user_email + ' once they are complete.' });
      return;
    }

    this.props.emailCustomReport(report.endpoint, params);
    this.props.addRequestToSubmitted(requestWithParams);
  }

  validateParamsExist = () => {
    const params = this.filteredReports().get(this.state.selectedReport).params;
    return this.state.reportParams.every((v,k) => {
      if (params.includes(k)) {
        return Array.isArray(v) ? v.length : v;
      } else {
        return true;
      }
    });
  }

  filteredReports = () => {
    let reportsList = this.props.reportsList;
    if (this.props.currentUser.accountId !== 22 && !this.props.route.isMasq) {
      reportsList = this.props.reportsList.filter(r => r.is_public);
    }
    return reportsList;
  }

  renderSelectSites = (idx, param) => {
    let labelName = 'Select Sites';
    let multi = true;
    let paramToSet = 'multi_site_id';
    if (param === 'site_id') {
      labelName = 'Select Site';
      multi = false;
      paramToSet = 'site_id';
    }
    return (
      <div className={ 'param' } key={ idx }>
        <label>{ labelName }</label>
        <SelectSitesAndSiteGroups
          value={ List(this.state.reportParams.get(paramToSet)) || (multi ? List([]) : '') }
          sites={ this.props.sites }
          siteGroups={ this.props.siteGroups }
          onSelect={ (siteIds) => this.selectSites(siteIds, param) }
          multiple={ multi }
          siteAccess={ this.props.currentUser.siteAccess }
        />
      </div>
    );
  }

  render() {
    if (this.props.pendingRequestsMap.some(request => request)) {
      return <Loading />;
    }

    let reportContent;
    let description;

    if (this.state.selectedReport !== null) {
      const report = this.filteredReports().get(this.state.selectedReport);
      description = <div className="report-description"><p className="descr">{ report.description }</p></div>;

      reportContent = (
        <div className="section report-content">
          <div className='title'>
            <p className='title'>Report Content</p>
            <p className='descr'>Select the information you want to receive in your report</p>
          </div>

          <div className='params'>
            {
              report.params.map((param, i) => {

                if (param === 'start_date') {
                  return (
                    <div className='param' key={ i }>
                      <label>Select Dates</label>
                      <CustomRangePicker limit={ report.day_limit } selectDates={ this.selectDates } />
                    </div>
                  )
                } else if (param === 'site_id' || param === 'multi_site_id') {
                  return this.renderSelectSites(i, param);
                } else if (param === 'manifest_id' || param === 'multi_manifest_id') {
                  const multi = param === 'multi_manifest_id';
                  return (
                    <div className='param' key={ i }>
                      <label>{ multi ? 'Select Campaigns' : 'Select Campaign' }</label>
                      <SelectCampaigns
                        sites={ this.getUserSites() }
                        campaigns={ this.props.campaigns }
                        onSelect={ this.selectCampaigns }
                        multiple={ multi }
                        value={ this.state.reportParams.get(param) || (multi ? [] : '') }
                      />
                    </div>
                  )
                } else if (param === 'multi_domain_name') {
                  return this.renderSelectSites(i, param);
                }
              })
            }
          </div>
        </div>
      )
    }

    const alert = (
      <Modal
        visible={ !!this.state.alertMessage }
        closable
        onCancel={ () => this.setState({ alertMessage: null }) }
        footer={ null }
      >
        { this.state.alertMessage }
      </Modal>
    );

    return (
      <div className='custom-reposts-container card'>

        { alert }

        <div className='main'>
          <div className='main-title'>
            <p className='title'>Request a Report</p>
            <p className='descr'>Setup a custom report to have sent to your inbox.</p>
          </div>
          <div className='report'>
            <div className='section report-type'>
              <div className='title'>
                <p className='title'>Report Type</p>
              </div>
              <div className='param'>
                <Select
                  className='report-dropdown'
                  defaultValue='Select Report'
                  dropdownMatchSelectWidth={ false }
                  onChange={ this.selectReport }
                  placeholder='Select Report'
                >
                  { this.filteredReports().map((report, i) => <Option key={ i } value={ i }>{ report.job_name }</Option> )}
                </Select>
              </div>
              { description }
            </div>
          </div>

          { reportContent }

          <div className='section send-report'>
            <div className='title'>
              <p className='title'>Send Report</p>
              <p className='descr'>Enter email you would like to send this report to.</p>
            </div>
            <div className='param'>
              <label>Email address</label>
              <Input
                className='email-input'
                onChange={ (e) => this.setState({ email: e.target.value }) }
                value={ this.state.email }
              />
            </div>
          </div>
        </div>

        <div className='bottom'>
          <CustomButton
            type={ CustomButton.types.PRIMARY }
            onClick={ this.emailReport }
            disabled={ this.state.selectedReport === null || !this.validateParamsExist() || !validateEmail(this.state.email) }
          >
            Request a report
          </CustomButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    reportsList: store.customReportsState.getIn(['reportsList', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    campaigns: store.campaignState.getIn(['campaigns', 'value']),
    emailReport: store.accountState.get('emailReport'),
    requestsSubmitted: store.customReportsState.get('requestsSubmitted'),
    accountId: store.accountState.getIn(['accountId', 'value']),
    pendingRequestsMap: Map({
      reportsList: store.customReportsState.getIn(['reportsList', 'pending']),
      sites: store.siteState.getIn(['sites', 'pending']),
      campaigns: store.campaignState.getIn(['campaigns', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getListOfReports,
    getAllSites,
    getSiteGroups,
    getProdCampaignsForMultSites,
    emailCustomReport,
    addRequestToSubmitted,
  },
)(CustomReport);
