import PropTypes from 'prop-types';
import React from 'react';
import { Vendor } from '../../../records/vendor_list_records';

const ScannedVendorDetail = (props) => {

  let siteUrl;
  if (props.vendor.siteUrl) {
    siteUrl = (
      <div>
        <p className='title'>Site URL: </p>
        <a href={ props.vendor.siteUrl }>{ props.vendor.siteUrl }</a>
      </div>
    );
  }

  return (
    <div className='scanned-vendor-detail-container'>
      <p>{ props.vendor.description }</p>
      <div>
        <p className='title'>Found on URL(s): </p>
        { props.vendor.cookies.flatMap(c => c.foundOnUrls).toSet().map(url => {
          return <a href={ 'http://' + url } target="_blank">{ url }</a>;
        }) }
      </div>
      <div>
        <p className='title'>Cookies found: </p>
        { props.vendor.cookies.map(c => c.name).map(n => {
          return <p>{ n }</p>;
        }) }
      </div>
      { siteUrl }
    </div>
  );
};

ScannedVendorDetail.propTypes = {
  vendor: PropTypes.instanceOf(Vendor).isRequired,
};

export default ScannedVendorDetail;
