import React, { Component } from 'react';
import { Popover } from 'antd';

class Infotip extends Component {
  render() {
    const props = { ...this.props };
    props.overlayClassName = 'spsg-infotip ' + (props.className || '');

    return <Popover {...props} />;
  }
}

export default Infotip;
