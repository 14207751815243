/* eslint-disable react/prop-types */
import { Collapse, Empty, Image, Spin } from 'antd';
import React, { useRef, useState } from 'react';

import { Button } from '../../../../styleguide';
import { createNotificationLink, generateDescription, generateTitle, getType } from '../helpers';
import { NotificationDetailsPage } from './NotificationDetails';

import arrowDown from '../../../../assets/icons/arrow_down.svg';

const NotificationTabItem = ({
  activeTab,
  isLoading,
  isLoadingMore,
  result = {},
  getAdditionalData,
  markNotificationsRead,
}) => {
  const [isUpdating, setUpdating] = useState(false);
  const [activeCollapse, setActiveCollapse] = useState(null);
  const scrollRef = useRef(0);
  const ContainerHeight = 800;

  const onScroll = (e) => {
    const containerCurrentPosition = e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
    if (
      scrollRef.current <= e.currentTarget.scrollTop &&
      !isLoadingMore &&
      containerCurrentPosition <= ContainerHeight + 200
    ) {
      getAdditionalData();
    }
    scrollRef.current = e.currentTarget.scrollTop;
  };

  const { count, page, ...notificationData } = result;

  const onMarkAll = async () => {
    setUpdating(true);
    await markNotificationsRead({ read_all: true }, { refetchList: true });
    setUpdating(false);
  };

  return (
    <Spin spinning={isLoading} size="large">
      {Object.keys(notificationData).length ? (
        <>
          {activeTab === 'new' ? (
            <div className="tab-actions_container">
              <Button loading={isUpdating} onClick={onMarkAll} type="link" size="small">
                Mark all as read
              </Button>
            </div>
          ) : null}
          <div style={{ height: ContainerHeight, overflow: 'scroll' }} onScroll={onScroll}>
            <Collapse
              destroyInactivePanel
              activeKey={activeCollapse || [...Object.keys(notificationData)]}
              onChange={setActiveCollapse}
              ghost
              bordered={false}
              className="notifications-collapse"
              expandIcon={(props) => (
                <Image className={props.isActive ? 'active' : ''} src={arrowDown} preview={false} />
              )}
            >
              {Object.entries(notificationData).map(([label, data]) =>
                data?.length ? (
                  <Collapse.Panel key={label} header={label} id={label}>
                    {data.map((item) => (
                      <NotificationDetailsPage
                        key={item.id}
                        onNotificationClick={() => activeTab === 'new' && markNotificationsRead({ ids: [item.id] })}
                        link={createNotificationLink(item?.type, item?.parameters)}
                        title={generateTitle(item?.type, item?.parameters)}
                        type={getType(item?.type, item?.parameters?.metric)}
                        description={generateDescription(item?.type, item?.parameters)}
                        date={item?.created_at}
                      />
                    ))}
                  </Collapse.Panel>
                ) : null,
              )}
            </Collapse>
            {isLoadingMore ? (
              <Spin spinning={isLoadingMore} size="large">
                <div style={{ height: 80 }} />
              </Spin>
            ) : null}
          </div>
        </>
      ) : (
        <Empty description={!isLoading && 'No Alerts'} />
      )}
    </Spin>
  );
};

export default NotificationTabItem;
