import { ConfigProvider, DatePicker, Table } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { List } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getUserDomains } from '../../actions/account_actions';
import { getDashboardGraphData } from '../../actions/dashboard_actions.js';
import { getAllSites } from '../../actions/site_actions';
import { GRAPH_COLORS } from '../../constants.js';
import { User } from '../../records/account_records';
import { GraphFilters } from '../../records/dashboard_records.js';
import { Headline } from '../../styleguide';
import Loading from '../common/Loading.js.jsx';
import SiteDropdown from '../common/SiteDropdown.jsx';
import { getDomainsForSiteAccess } from '../utils';

const { RangePicker } = DatePicker;
moment().format();
let graphData, tableData, newGraphFilters;

export class DashboardPage extends React.Component {
  static propTypes = {
    getDashboardGraphData: PropTypes.func.isRequired,
    pending: PropTypes.bool.isRequired,
    getAllSites: PropTypes.func.isRequired,
    currentUser: PropTypes.instanceOf(User),
    clientDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  constructor(props) {
    super(props);
    const now = new Date();
    const today = moment(now);

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(now.getDate() - 7);
    const oneWeekAgoMoment = moment(oneWeekAgo);

    const newGraphFiltersRecord = new GraphFilters({
      start_date: oneWeekAgoMoment,
      end_date: today,
    });

    this.updateState = this.updateState.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.disabledDate = this.disabledDate.bind(this);
    this.selectSites = this.selectSites.bind(this);

    this.state = {
      graphFilters: newGraphFiltersRecord,
    };
  }

  componentDidMount() {
    if (this.props.currentUser) {
      if (!this.props.currentUser.siteAccess) {
        this.props.getDashboardGraphData(this.state.graphFilters);
      }
      this.props.getUserDomains();
      this.props.getAllSites();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getUserDomains();
      this.props.getAllSites();
      if (!this.props.currentUser.siteAccess) {
        this.props.getDashboardGraphData(this.state.graphFilters);
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.sites.size && newProps.sites.size) {
      let graphFilters = this.state.graphFilters;
      if (this.props.currentUser.siteAccess) {
        graphFilters = graphFilters.set('client_domain', getDomainsForSiteAccess(List(this.props.currentUser.siteAccess), newProps.sites, List(this.props.domains)));
      }
      this.props.getDashboardGraphData(graphFilters);
    }
  }

  updateState(graphFilters) {
    this.setState({ graphFilters }, function () {
      this.props.getDashboardGraphData(this.state.graphFilters);
    });
  }

  handleDateChange(dates) {
    const start = dates[0];
    const end = dates[1];
    newGraphFilters = this.state.graphFilters.set('start_date', start).set('end_date', end);
    this.updateState(newGraphFilters);
  }

  disabledDate(current) {
    // Cannot select days after today
    return current.valueOf() > Date.now();
  }

  selectSites(sites) {
    if (sites === 'all properties') {
      if (this.props.currentUser.siteAccess){
        sites = getDomainsForSiteAccess(List(this.props.currentUser.siteAccess), this.props.sites, List(this.props.domains));
      } else {
        sites = '';
      }
    } else {
      sites = this.props.domains.find(d => d.id === parseInt(sites)).name;
    }
    
    newGraphFilters = this.state.graphFilters.set('client_domain', sites);
    this.updateState(newGraphFilters);
  }

  render() {

    let table,
      graph,
      noData;

    if (this.props.dashboardData) {
      graphData = this.props.dashboardData.graphData.toJS();
      tableData = this.props.dashboardData.tableData.map((t, i) => ({ ...t, key: i}));

      graph = (
        <ResponsiveContainer width='100%' height={395}>
          <LineChart
            data={graphData}
            width={1100}
            height={395}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <Line
              type="monotone"
              dataKey="desktop"
              stroke={ GRAPH_COLORS.lightBlue }
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="mobile"
              stroke={ GRAPH_COLORS.teal }
              dot={false}
            />
            <XAxis dataKey="dates" />
            <CartesianGrid stroke="#ccc" />
            <YAxis />
            <Tooltip />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      );

      const columns = [{
        title: 'Device',
        dataIndex: 'device',
        key: 'device',
        width: '25%',
      }, {
        title: 'Affected Pageviews',
        dataIndex: 'adblock_pageviews',
        key: 'adblock_pageviews',
        width: '25%',
      }, {
        title: 'Total Pageviews',
        dataIndex: 'pageviews',
        key: 'pageviews',
        width: '25%',
      }, {
        title: 'Adblock Rate',
        dataIndex: 'adblock_rate',
        key: 'adblock_rate',
        width: '25%',
      }];
      table = (
        <Table
          dataSource={ tableData }
          columns={ columns }
          pagination={ false }
          bordered
        />
      );
    } else {
      noData = <h4 className='no-data'>No data to display</h4>;
    }

    let loading;
    if (this.props.pending) {
      loading = <Loading />;
    }

    let domains = List(this.props.domains);

    if (this.props.currentUser.siteAccess) {
      domains = getDomainsForSiteAccess(List(this.props.currentUser.siteAccess), this.props.sites, domains);
    }

    return (
      <div className='metrics-graph dashboard-page'>
        <Headline>Adblock Dashboard</Headline>
        <ConfigProvider locale={ enUS }>
          <SiteDropdown
            sites={ domains }
            selectSites={ this.selectSites }
            hasAllAccess={ !this.props.currentUser.siteAccess }
          />
        </ConfigProvider>
        <div className='external filters'>
          <ConfigProvider locale={enUS}>
            <RangePicker
              disabled={ this.props.pending }
              allowClear={false}
              size='large'
              className='range-picker'
              onChange={ this.handleDateChange }
              defaultValue={ [this.state.graphFilters.start_date, this.state.graphFilters.end_date] }
              format={'MMM D, YYYY'}
              disabledDate={ this.disabledDate }
            />
          </ConfigProvider>
        </div>

        <div className='adblock card'>
          { loading }
          { graph }
          { noData }
        </div>
        <div className='data-table card'>
          { table }
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    domains: store.accountState.getIn(['domains','value']),
    dashboardData: store.dashboardState.getIn(['dashboardData', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    pending: store.dashboardState.getIn(['dashboardData', 'pending']),
  };
};

export default connect(
  mapStateToProps, {
    getDashboardGraphData,
    getAllSites,
    getUserDomains,
  },
)(DashboardPage);
