import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

export const initialState = Map({
    systemAccuracy: new StoreRecord({ value: {} }),
    systemAccuracyTimeline: new StoreRecord({ value: {} }),
});

export default (state = initialState, action) => {

  switch(action.type) {

      case types.SYSTEM_ACCURACY_PENDING:
          return setPending(state, ['systemAccuracy']);

      case types.SYSTEM_ACCURACY_LOADED:
          return setValue(state, ['systemAccuracy'], action.systemAccuracy);

    case types.SYSTEM_ACCURACY_TIMELINE_LOADED:
    return setValue(state, ['systemAccuracyTimeline'], action.systemAccuracy);

      case types.SYSTEM_ACCURACY_CACHED:
          return stopPending(state, ['systemAccuracy']);

      case types.SYSTEM_ACCURACY_ERROR:
        return setError(state, ['systemAccuracy'], action.errors);

      }

    return state;
};
      
