import PropTypes from 'prop-types';
import React from 'react';
import { Radio } from '../../styleguide';
import { DATEPICKERS } from '../../constants';
import {
  defaultWeekStart,
  defaultWeekEnd,
  defaultMonthStart,
  defaultMonthEnd,
  defaultQuarterStart,
  defaultQuarterEnd,
} from '../consent/gdpr_performance_tcfv2/helper.js';
const { Group, Button } = Radio;

class CustomDateRangeSelect extends React.Component {
  static propTypes = {
    selectDatePicker: PropTypes.func.isRequired,
  };

  selectDatePicker = (e) => {
    let start;
    let end;
    if (e.target.value === DATEPICKERS.week) {
      start = defaultWeekStart;
      end = defaultWeekEnd;
    } else if (e.target.value === DATEPICKERS.month) {
      start = defaultMonthStart;
      end = defaultMonthEnd;
    } else if (e.target.value === DATEPICKERS.quarter) {
      start = defaultQuarterStart;
      end = defaultQuarterEnd;
    }
    this.props.selectDatePicker(e, {
      start,
      end,
    });
  };

  render() {
    return (
      <div className="date-range">
        <Group
          onChange={(e) => this.selectDatePicker(e)}
          defaultValue="WEEK"
          buttonStyle="solid"
        >
          <Button range={{ amount: 7, type: 'days' }} value="WEEK">
            Week
          </Button>
          <Button range={{ amount: 30, type: 'days' }} value="MONTH">
            Month
          </Button>
          {this.props.showQuarterly && (
            <Button range={{ amount: 91, type: 'days' }} value="QUARTER">
              Quarter
            </Button>
          )}
        </Group>
      </div>
    );
  }
}

export default CustomDateRangeSelect;
