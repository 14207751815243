import 'whatwg-fetch';
import { checkStatus } from '../helper';
import { messageToRecord } from './messages';
import { scenarioToRecord } from './scenarios';
import { partitionsetToRecord } from './partitionsets';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

// IMPORT

export function importMessageAjax(siteId, itemId) {
    return importItem(siteId, itemId, "message", messageToRecord);
}

export function importScenarioAjax(siteId, itemId, options) {
    return importScenario(siteId, itemId, options);
}

export function importPartitionsetAjax(siteId, itemId, options) {
    return importPartitionset(siteId, itemId, options);
}

function importFetch(url, convertToRecord, itemKind) {
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
    'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => convertToRecord(resp['data'][`${itemKind}_data`]));
}


function importItem(siteId, itemId, itemKind, convertToRecord) {
    const url = '/msg/clone_' + itemKind + '?destination_site_id=' + siteId + '&' + itemKind + '_id=' + itemId;
    return importFetch(url, convertToRecord, itemKind);
}

function importScenario(siteId, itemId, options) {
    const url = '/msg/clone_scenario?destination_site_id=' + siteId + '&scenario_id=' + itemId + '&clone_messages=' + options.copyMessages;
    return importFetch(url, scenarioToRecord, 'scenario');
}

function importPartitionset(siteId, itemId, options) {
    const url = '/msg/clone_partitionset?destination_site_id=' + siteId + '&partitionset_id=' + itemId + '&clone_scenarios=' + options.copyScenarios + '&clone_messages=' + options.copyMessages;
    return importFetch(url, partitionsetToRecord, 'partitionset');
}
