import React, { useContext, useEffect } from 'react';
import { Select } from 'antd';

import PrivacyManagerComp from './Types/PrivacyManager/PrivacyManager';
import MessagePrivacyManager from './Types/PrivacyManager/MessagePrivacyManager';
import CustomJSComp from './Types/CustomJS';
import RedirectComp from './Types/Redirect';
import WhitelistComp from './Types/Whitelist';

import { 
  SaveAndExit,
  Accept,
  Reject,
  AcceptAll,
  RejectAll,
  PrivacyManager,
  Dismiss,
  ContinueWithAdblocker,
  ExecJS,
  Redirect,
  Whitelist,
  Cancel,
} from '../../../../../../../../records/choice_config_records';
import { PmOverrideContext, SelectedPrivacyManager, IsApp } from '../../../../../../contexts';

const { Option, OptGroup } = Select;

let scenarioConsentUrl;
let scenarioCcpaUrl;
let ScenarioUsnatUrl;
if (process.env.REACT_APP_PUB_PORTAL_ENV === 'prod') {
  scenarioConsentUrl = 'https://sourcepoint.mgr.consensu.org/tcfv2';
  scenarioCcpaUrl = 'https://ccpa-service.sp-prod.net';
  ScenarioUsnatUrl = 'https://cdn.privacy-mgmt.com/usnat';
} else {
  scenarioConsentUrl = 'https://cmpv2.sp-stage.net/tcfv2';
  scenarioCcpaUrl = 'https://ccpa.sp-stage.net';
  ScenarioUsnatUrl = 'https://usp.sp-stage.net';
}

const ChoiceOptions = ({
  settingKey,
  updateSetting,
  settingsTitle,
  value,
  pmList,
  category,
  messageCategory,
  messageSubcategory,
  pmMessages,
  accountId,
  features,
  siteGroupId
}) => {
  const { setDefaultPrivacyManagerVariable } = useContext(PmOverrideContext);
  const { settingKey: selectedPmSettingKey, selectedPrivacyManager } = useContext(SelectedPrivacyManager);
  const isApp = useContext(IsApp);

  const isTopLevelPmSetting = selectedPmSettingKey === settingKey;

  const changeValue = (value, data, optionalKeypairs = {}) => {
    data = (data && data.button_text) ? data : {};
    if (category === 'gdpr') {
      data['consent_origin'] = scenarioConsentUrl;
    } else if (category === 'usnat') {
      data['consent_origin'] = ScenarioUsnatUrl;
    } else {
      data['consent_origin'] = scenarioCcpaUrl;
    }

    if (value === 'Accept') data = new Accept(data).toJS();
    if (value === 'Reject') data = new Reject(data).toJS();
    if (value === 'SE') data = new SaveAndExit(data).toJS();
    if (value === 2) data = new Cancel(data).toJS();
    if (value === 11) data = new AcceptAll(data).toJS();
    if (value === 12) data = new PrivacyManager(data).toJS();
    if (value === 13) data = new RejectAll(data).toJS();
    if (value === 15) data = new Dismiss(data).toJS();
    if (value === 6) data = new ContinueWithAdblocker(data).toJS();
    if (value === 9) data = new ExecJS(data).toJS();
    if (value === 5) data = new Redirect(data).toJS();
    if (value === 1) data = new Whitelist(data).toJS();
    if (data.hasOwnProperty('enabled')) delete data.enabled;
    data.button_text = JSON.stringify(Date.now());
    const dataValue = value === null ? {} : data;

    updateSetting(settingKey, {
      type: value,
      data: dataValue,
      ...optionalKeypairs
    });
  }

  const hasFeature = (feature) => {
    return features && (features.includes(feature))
  };

  useEffect(() => {
    // mimic initial choice of an option if we're restricting it to just PM
    if (isTopLevelPmSetting && value.type !== 12) {
      changeValue(12);
    }
  }, [isTopLevelPmSetting, value.type]);

  const isFromCCPA = category === 'ccpa' && messageSubcategory === 'notice';
  const isFromAdblock = category === 'adblock' && messageSubcategory === 'notice';
  const isFromCustom = category === 'custom' && messageSubcategory === 'custom';
  const isFromCCPASar = category === 'ccpa' && messageSubcategory === 'sar';

  const formatPmListForCcpa = (pmList) => {
    return pmList.toJS().map((pm) => {
      return {
        ...pm,
        description: pm.name,
        message_sub_category_id: 12
      }
    })
  }
  
  return (
    <div className="four-options">
      {!isTopLevelPmSetting && (
        <React.Fragment>
          <h4>{settingsTitle}</h4>
          <div className="option-inputs">
            <Select value={value.type} style={{ width: '100%' }} onChange={changeValue}>
              <Option value={null}><i>None</i></Option>
              {!isFromAdblock && !isFromCustom && (
                <OptGroup label="Consent Options">
                  {messageCategory !== 'usnat' && <Option value={'SE'}>Accept Legitimate Interest Only</Option>}
                  {messageSubcategory === 'us_pm' && <Option value={'SE'}>Save & Exit</Option>}
                  <Option value={11}>Accept All</Option>
                  <Option value={13}>Reject All</Option>
                  {messageSubcategory === 'us_pm' && <Option value={2}>Cancel</Option>}
                  {messageSubcategory !== 'us_pm' && <Option value={12}>Show Privacy Manager</Option>}
                </OptGroup>
              )}
              {hasFeature('adblock_v2') && isFromAdblock && !isFromCustom && (
                <OptGroup label="Adblock Options">
                  <Option value={1}>White list</Option>
                  <Option value={6}>Continue width Adblocker</Option>
                </OptGroup>
              )}
              <OptGroup label="Choice Options">
                {hasFeature('custom_choices') && (
                  <Option value={9}>
                    Custom {!isApp ? 'Javascript' : 'Action'}
                  </Option>
                )}
                {hasFeature('custom_choices') && messageSubcategory !== 'us_pm' && <Option value={5}>Redirect</Option>}
                {messageSubcategory !== 'us_pm' && <Option value={15}>Dismiss Message</Option>}
              </OptGroup>
            </Select>
          </div>
        </React.Fragment>
      )}
      <div className="option-details">
        {value.type === 12 && !['notice-tcf-v2', 'notice_non_iab', 'usnat_notice'].includes(messageSubcategory) && !isFromCCPA && (
          <PrivacyManagerComp
            options={pmList}
            changeValue={changeValue}
            value={value}
            setDefaultPrivacyManagerVariable={setDefaultPrivacyManagerVariable}
            category={category}
            isSiteGroup={Boolean(siteGroupId)}
          />
        )}
        {value.type === 12 && ['notice-tcf-v2', 'notice_non_iab'].includes(messageSubcategory) && !isFromCCPA && (
          <MessagePrivacyManager
            options={pmMessages.filter(m => m.message_category_id !== 6)}
            changeValue={changeValue}
            value={value}
            category={category}
            messageSubcategory={messageSubcategory}
            disablePmSelect={settingKey !== selectedPmSettingKey}
          />
        )}
        {value.type === 12 && isFromCCPA && (
          <MessagePrivacyManager
            options={pmMessages.filter(m => m.message_sub_category_id === 12).concat(formatPmListForCcpa(pmList))}
            changeValue={changeValue}
            value={value}
            category={category}
            messageSubcategory={messageSubcategory}
            disablePmSelect={settingKey !== selectedPmSettingKey}
          />
        )}
        {value.type === 12 && messageCategory === 'usnat' && (
          <MessagePrivacyManager
            options={pmMessages.filter(m => m.message_sub_category_id === 2).concat(formatPmListForCcpa(pmList))}
            changeValue={changeValue}
            value={value}
            category={category}
            messageSubcategory={messageSubcategory}
            disablePmSelect={settingKey !== selectedPmSettingKey}
          />
        )}
        {(value.type === 9) && (
          <CustomJSComp
            changeValue={changeValue}
            value={value}
            isApp={isApp}
          />
        )}
        {value.type === 5 && <RedirectComp changeValue={changeValue} value={value} />}
        {value.type === 1 && <WhitelistComp changeValue={changeValue} value={value} />}
      </div>
    </div>
  );
};

export default ChoiceOptions;
