import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option, OptGroup } = Select;

export default class SelectSites extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.list.isRequired,
    campaigns: ImmutablePropTypes.orderedSet.isRequired,
    onSelect: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
  }

  static defaultProps = {
    multiple: false,
    disabled: false,
  }

  generateSearchableStringValue(campaignId) {
    if (!campaignId) return '';
    const campaign = this.props.campaigns.find(c => c.id === campaignId);
    return campaign.description + campaign.description.toLowerCase() + 'ID:' + campaign.id.toString();
  }

  handleSelect = (campaigns) => {
    let campaignIds;
    if (Array.isArray(campaigns)) {
      campaignIds = campaigns.map(c => parseInt(c.split('ID:')[1]));
    } else {
      campaignIds = parseInt(campaigns.split('ID:')[1]);
    }
    this.props.onSelect(campaignIds);
  }

  render() {
    const selectProps = {
      onChange: this.handleSelect,
    };
    if (this.props.multiple) {
      selectProps.value = this.props.value.map(id => this.generateSearchableStringValue(id));
      selectProps.mode = 'multiple';
      selectProps.tokenSeparators = [','];
      selectProps.placeholder = 'Choose one or more campaigns';
    } else {
      selectProps.value = this.generateSearchableStringValue(this.props.value);
    }
    return (
      <Select
        { ...selectProps }
      >
        {
          this.props.sites.map(s => {

            return (
              <OptGroup key={ s.id } label={ s.domain }>
                {
                  this.props.campaigns.filter((c) => c.site_id === s.id).map(c => {
                    return <Option key={ this.generateSearchableStringValue(c.id) }>{ c.description }</Option>
                  })
                }
              </OptGroup>
            )
          })
        }
      </Select>
    );
  }
}
