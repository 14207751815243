import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import { browserHistory } from 'react-router';
import {
  getConsentCompensationLists,
  deleteConsentCompensationList,
} from '../../../actions/consent_compensation_actions';
import Loading from '../../common/Loading.js.jsx';
import ConsentCompensationListRow from './ConsentCompensationListRow';
import { ConsentCompensationList as ConsentCompensationListRecord } from '../../../records/consent_compensation_records';
import { User } from '../../../records/account_records';
import CustomButton from '../../common/CustomButton.js.jsx';
import CreateButton from '../../common/CreateButton.js.jsx';

class ConsentCompensationLists extends React.Component {
  static propTypes = {
    consentCompensationLists: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(ConsentCompensationListRecord)).isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    route: PropTypes.shape({
      v2: PropTypes.bool,
    }).isRequired,
    currentUser: PropTypes.instanceOf(User),
    getConsentCompensationLists: PropTypes.func.isRequired,
    deleteConsentCompensationList: PropTypes.func.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      if (!this.props.currentUser.siteAccess) {
        this.props.getConsentCompensationLists(this.props.currentUser.accountId);
      } else {
        this.props.getConsentCompensationLists(this.props.currentUser.accountId, this.props.currentUser.siteAccess);
      }
    }
  }

  handleCreate = () => {
    browserHistory.push(`/consent_v2/compensation_list/create`);
  };

  render() {
    if (this.props.pendingRequestsMap.some(request => request)) {
      return <Loading />;
    }

    //XXX change to consent feature
    let readOnly = false;
    if (this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('compensation_list_mgmt')) {
      readOnly = true;
    }

    let content;
    if (this.props.consentCompensationLists.size) {
      content = (
        <ul>
          {
            this.props.consentCompensationLists.map(l => (
              <li key={ l.id }>
                <ConsentCompensationListRow
                  consentCompensationList={ l }
                  handleDelete={ this.props.deleteConsentCompensationList }
                  accountId={ this.props.currentUser.accountId }
                  pending={ this.props.savePending }
                  readOnly={ readOnly }
                />
              </li>
            ))
          }
        </ul>
      );
    } else {
      if (!readOnly) {
        content = (
          <div className='no-lists-message-container'>
            <p className='title'>
              You haven’t created a Consent Compensation List yet.
            </p>
            <CustomButton className='create-button' onClick={ this.handleCreate }>Get Started</CustomButton>
          </div>
        );
      } else {
        content = (
          <div className='no-lists-message-container'>
            <p className='title'>
              There are no Consent Compensation Lists available.
            </p>
          </div>
        );
      }
    }

    let createButton;
    if (!readOnly) {
      createButton = <CreateButton onClick={ this.handleCreate }>NEW</CreateButton>;
    }
    return (
      <div className='vendor-lists-container'>
        { createButton }
        <p className='main-title'>Vendor Lists</p>
        { content }
      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    consentCompensationLists: store.consentCompensationListState.getIn(['consentCompensationLists', 'value']),
    pendingRequestsMap: new Map({
      consentCompensationLists: store.consentCompensationListState.getIn(['consentCompensationLists', 'pending']),
    }),
    savePending: store.consentCompensationListState.get('savePending'),
  };
};

export default connect(
  mapStateToProps, {
    getConsentCompensationLists,
    deleteConsentCompensationList,
  },
)(ConsentCompensationLists);
