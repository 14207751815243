import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { WarningOutlined } from "@ant-design/icons"
import { Input, Button, Modal } from '../../../../styleguide';
import { environment } from '../helper';

const CampaignCloneModal = ({ 
  campaign, 
  isModalVisible,  
  confirmCloneCampaigns, 
  closeModal, 
  isReplaceCampaign,
}) => {
  const footer = [
    <Button key="1" onClick={closeModal} className="cancel">
      Cancel
    </Button>,
    <Button key="2" onClick={confirmCloneCampaigns} type="primary">
      Clone
    </Button>,
  ];
 
 const replacementMessages = isReplaceCampaign;
 const campaignName = campaign.name ? campaign.name : campaign.description;
 const updatedCampaignName = campaignName.replace(/(\([\w\d ]*\))+/g, '')
 const currentEnv = campaign.diffEnv === 'Public'?'Stage':'Public'
 const campaignNameEnv = campaign.diffEnv === 'Public'?'Stage':'Production'
 const dest = replacementMessages?.destination === 'production'? 'public': 'stage';

  return (
    <Modal
      className='clone-modal'
      visible={isModalVisible} 
      footer={footer}
      closable={true}
      onCancel={closeModal}
    >
      <p className="title">Clone from {currentEnv} to {campaign?.diffEnv}</p>
      <div className='clone-modal-container'>
        <p className="camp-header">Please confirm that you want to clone the following campaign from the {currentEnv} to the {campaign.diffEnv} environment.</p>
          {replacementMessages && <div className='warning-message'><WarningOutlined style={{color:'#FF9100'}}  />
          Note: {replacementMessages.type} type campaign already exists in {dest} campaign. This clone will override the current {dest} {replacementMessages.type} campaign.
          </div>}
        <div className="camp-container">
        <div className='camp-name'>Campaign Name: <span>{updatedCampaignName}</span></div>
        <Input
            className='camp-input'
            placeholder={`${updatedCampaignName} (Clone from ${campaignNameEnv})`}
            disabled={true}
         />
        </div>
      </div>
    </Modal>
  );
}
CampaignCloneModal.propTypes = {
  cloneMethod: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getNameError: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired 
}
export default CampaignCloneModal;