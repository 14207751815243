import React from 'react';
import { Card } from 'antd';

class SdkIos extends React.Component {


  render() {
    return (
         <div>
           <Card>
             <h2>iOS</h2>
             <h3>How To Install</h3>
             <h3>Cocoapods</h3>
             <p>On your project's <code>Podfile</code> add the following line to your app's target:</p>
             <pre>{`pod ConsentViewController`}</pre>

             <h3>Quick start</h3>
             <p>You'll first have to set up a campaign on <a href='https://analytics.sourcepoint.com/'>SourcePoint's portal</a>. Ask one of our Sales team members and they will be happy to help you there. Also, if you have any question, our <a href='https://help.sourcepoint.com/dialogue'>Help Portal</a> might have the answer for you.</p>
             <p>On your app, all you need to do is instantiate <code>ConsentViewController</code> with your account id and site name, set its callbacks and call <code>ConsentViewController.loadMessage</code>. For example:</p>
             <pre>{`
              var consentViewController: ConsentViewController!

              do {
                  let consentViewController = try ConsentViewController(
                      accountId: <ACCOUNT ID>,
                      siteName: "SITE NAME",
                      stagingCampaign: false
                  )
               
                  consentViewController.onMessageReady = { controller in
                      parentView.addSubview(controller.view)
                      controller.view.frame = parentView.bounds
                  }
               
                  consentViewController.onInteractionComplete = { controller in
                      do {
                          print(try controller.getIABPurposeConsents([3]))
                          controller.view.removeFromSuperview()
                      } catch { print(error) }
                  }
               
                  consentViewController.onErrorOccurred = { error in
                      consentViewController.view.removeFromSuperview()
                  }
               
                  consentViewController.loadMessage()
               } catch { print(error) }
             `}</pre>
           <h3>Getting help</h3>
           <p>The complete source code can be found in our public <a href='https://github.com/SourcePointUSA/ios-cmp-app/'>GitHub repository</a></p>
           </Card>
         </div>
     );
   }
}

export default SdkIos