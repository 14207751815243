import React, { Component } from 'react';
import { Popconfirm as AntdPopconfirm } from 'antd';

class Popconfirm extends Component {
  render() {
    const props = { ...this.props };
    props.overlayClassName += ' spsg-popconfirm';

    return <AntdPopconfirm {...props} />;
  }
}

export default Popconfirm;
