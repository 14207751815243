import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { PlusSquareOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import { Action, TagManager } from '../../../records/vendor_list_records';

export default class ActionsTab extends React.Component {
  static propTypes = {
    updateActions: PropTypes.func.isRequired,
    actionType: PropTypes.string,
    actions: ImmutablePropTypes.listOf(PropTypes.instanceOf(Action)).isRequired,
    readOnly: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    actionType: 'consentActions',
  }

  handleCustomJSChange = (e) => {
    const value = e.target.value;
    const actionIdx = this.props.actions.findIndex(a => a.type === 'inline' && !a.tagManager);

    if (!value) {
      this.removeAction(actionIdx);
      return;
    }

    if (actionIdx > -1) {
      this.updateAction(actionIdx, 'js', value);
    } else {
      const newActions = this.props.actions.push(new Action({ type: 'inline', js: value }));
      this.props.updateActions(newActions);
    }
  }

  handleTagManagerChange = (e) => {
    const value = e.target.value;
    const actionIdx = this.props.actions.findIndex(a => a.type === 'inline' && a.tagManager);
    const googleTagManagerJS = `window.dataLayer && window.dataLayer.push({ 'event': ${value} });`;

    if (!value) {
      this.removeAction(actionIdx);
      return;
    }

    if (actionIdx > -1) {
      let actions = this.props.actions;
      actions = actions.update(actionIdx, action => action.set('js', googleTagManagerJS).set('tagManager', action.tagManager.set('value', value)));

      this.props.updateActions(actions);
    } else {
      const newActions = this.props.actions.push(new Action({ type: 'inline', js: googleTagManagerJS, tagManager: new TagManager({ name: 'google', key: 'event', value }) }));
      this.props.updateActions(newActions);
    }
  }

  handleUrlActionChange = (e, actionIdx) => {
    const value = e.target.value;
    if (!value) {
      this.removeAction(actionIdx);
      return;
    }
    this.updateAction(actionIdx, 'url', value);
  }

  addUrlInput = () => {
    // find index of first inline type and insert before it in the end of external types
    let actionIdx = this.props.actions.findIndex(a => a.type === 'inline');
    if (actionIdx < 0) actionIdx = this.props.actions.size;
    const newActions = this.props.actions.insert(actionIdx, new Action({ type: 'external' }).set('url', ''));
    this.props.updateActions(newActions);
  }

  updateAction = (actionIdx, propertyName, value) => {
    let actions = this.props.actions;

    if (value) {
      actions = actions.update(actionIdx, action => action.set(propertyName, value));
    }

    this.props.updateActions(actions);
  }

  removeAction = (actionIdx) => {
    let actions = this.props.actions;
    actions = actions.remove(actionIdx);

    this.props.updateActions(actions);
  }


  render() {
    const customJSActions = this.props.actions
      .filter(customAction => customAction.type === 'inline' && !customAction.tagManager);

    const value = customJSActions.size ? customJSActions.first().js : '';
    const customJSInput = (
      <Input.TextArea
        value={ value }
        onChange={ (e) => this.handleCustomJSChange(e, 'js')}
        autosize={{ minRows: 4, maxRows: 100 }}
        disabled={ this.props.readOnly }
      />
    );

    let tagManager;
    let urls;

    if (this.props.actionType === 'consentActions') {
      const tagManagerActions = this.props.actions.filter(ca => ca.type === 'inline' && ca.tagManager);
      const value = tagManagerActions.size ? tagManagerActions.first().tagManager.value : '';
      const tagManagerInput = (
        <Input.TextArea
          value={ value }
          onChange={ (e) => this.handleTagManagerChange(e)}
          placeholder='Add Value...'
          disabled={ this.props.readOnly }
        />
      );

      tagManager = (
        <React.Fragment>
          <label className='tag-manager title'>Google Tag Manager</label>
          <div className='tag-manager-key-val-container'>
            <label>Key</label>
            <Input disabled value='event' />
          </div>
          <div className='tag-manager-key-val-container'>
            <label>Value</label>
            { tagManagerInput }
          </div>
        </React.Fragment>
      );

      const urlConsentActions = this.props.actions.filter(ca => ca.type === 'external');
      let urlConsentInputs;

      if (urlConsentActions.size) {
        urlConsentInputs = (
          <React.Fragment>
            {
              urlConsentActions.map((actions, idx) => {
                return (
                  <Input
                    key={ idx }
                    value={ actions.url }
                    onChange={ (e) => this.handleUrlActionChange(e, idx)}
                    disabled={ this.props.readOnly }
                  />
                );
              })
            }
          </React.Fragment>
        );
      }

      urls = (
        <React.Fragment>
          <label className='title url'>Inline Url</label>
          { urlConsentInputs }
          <div className='button-container'>
            <Button shape="circle" icon={<PlusSquareOutlined />} onClick={ () => this.addUrlInput() } disabled={ this.props.readOnly } />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className='edit-purpose-tab-container'>
        <div className='consent-actions'>

          <label className='title'>Custom Javascript</label>
          { customJSInput }

          { tagManager }

          { urls }

        </div>
      </div>
    );
  }
}
