import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { connect } from 'react-redux';
import CSVLink from "../../common/CSVLink.js";
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';

import { getConsentReportActionStatusData, getConsentReportVendorsData } from '../../../actions/consent_performance_actions.js';
import { ConsentPerformanceRecord, GraphFilters } from '../../../records/consent_performance_records.js';
import { getAllSites } from '../../../actions/site_actions.js';
import Loading from '../../common/Loading.js.jsx';
import SiteAutocomplete from '../../common/SiteAutocomplete';
import AllLocations from '../../common/AllLocations';
import CustomDateRange from '../../common/CustomDateRange';
import CustomDatePicker from '../../reports/CustomDatePicker';
import { User } from '../../../records/account_records';

import ConsentVendor from './ConsentVendor';
import ConsentActions from './ConsentActions';
import ConsentAggregate from './ConsentAggregate';

let graphData, tableData, newGraphFilters;

class ConsentPerformanceReport extends React.Component {
  static propTypes = {
    consentPerformanceData: PropTypes.instanceOf(ConsentPerformanceRecord),
    getConsentReportActionStatusData: PropTypes.func.isRequired,
    getConsentReportVendorsData: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    sites: ImmutablePropTypes.list.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    actionStatusData: new ConsentPerformanceRecord(),
  };

  constructor(props) {
    super(props);
    const newGraphFiltersRecord = new GraphFilters({
      startDate: moment().subtract(1, 'days'),
      endDate: moment().subtract(1, 'days'),
      datePicker: 'DAY',
    });

    this.state = {
      graphFilters: newGraphFiltersRecord,
      selectedSite: 'Select a property',
    };
  }

  componentDidMount() {
    if (this.state.graphFilters.siteId) {
      this.props.getConsentReportActionStatusData(this.state.graphFilters);
      this.props.getConsentReportVendorsData(this.state.graphFilters);
    }

    this.props.getAllSites().then(()=>{
      const cpSiteId = localStorage.getItem('cpSiteId') || null;
      if (cpSiteId) this.selectSites(cpSiteId);
    });
  }

  updateState = (newGraphFilters) => {
    this.setState({graphFilters: newGraphFilters}, function () {
      this.props.getConsentReportActionStatusData(this.state.graphFilters);
      this.props.getConsentReportVendorsData(this.state.graphFilters);
    });
  };

  selectDates = (dates, datePicker) => {
    newGraphFilters = this.state.graphFilters
      .set('startDate', dates.start)
      .set('endDate', dates.end)
      .set('datePicker', datePicker);
    this.updateState(newGraphFilters);
  };

  selectDatePicker = (event, dates) => {
    this.selectDates(dates, event.target.value)
  }

  selectCountry = (event) => {
    const geoFilter = event === 'All' ? '' : event;
    newGraphFilters = this.state.graphFilters.set('geo', geoFilter);
    this.updateState(newGraphFilters);
  };

  selectSites = (event) => {
    const siteId = this.props.sites.find((site) => site.id === Number(event));
    if (siteId !== undefined) {
      localStorage.setItem('cpSiteId', event);
      this.setState({ selectedSite : event });
      newGraphFilters = this.state.graphFilters.set('siteId', siteId.get('id'));
      this.updateState(newGraphFilters);
    } else {
      localStorage.removeItem(event);
    }
  };

  render() {
    let noData = false, exportCSV;

    let actionStatusGraph;
    if (this.props.actionStatusData
      && this.props.actionStatusData.get('tableData').size
      && !this.props.pendingRequestsMap.get('actionStatusData')
    ) {
      const loading = this.props.pendingRequestsMap.get('actionStatusData') || this.props.pendingRequestsMap.get('sites')
      actionStatusGraph = (
        <React.Fragment>
          <ConsentAggregate
            data={ this.props.actionStatusData }
            loading={ loading }
            accountId={ this.props.currentUser.accountId }
            picker={ this.state.graphFilters.get('datePicker') }
          />
          <ConsentActions data={this.props.actionStatusData} loading={loading} picker={this.state.graphFilters.get('datePicker')}/>
        </React.Fragment>
      )

      tableData = this.props.actionStatusData.tableData.toJS();
      graphData = tableData.map((data) => _.omit(data, 'key'));

      const date = new Date();
      exportCSV = (
        <div className="export-csv-blue">
          <CSVLink data={graphData} className="export-csv" filename={date + ' data.csv'} target="">
            <i className="fas fa-download" />Export Report
          </CSVLink>
        </div>
      );

    } else {
      noData = true;
    }

    let vendorsGraph;
    if (this.props.vendorsData
      && this.props.vendorsData.vendorData
      && this.props.vendorsData.vendorData.size
      && !this.props.pendingRequestsMap.get('vendorsData')
      && !this.props.pendingRequestsMap.get('actionStatusData')
    ) {
      vendorsGraph = (
        <ConsentVendor
          loading={ this.props.pendingRequestsMap.get('vendorData') || this.props.pendingRequestsMap.get('sites') }
          filters={ this.state.graphFilters }
          data={ this.props.vendorsData }
        />
      );
    }

    let loading;
    let isLoading = false;
    if (this.props.pendingRequestsMap.get('actionStatusData') || this.props.pendingRequestsMap.get('sites')) {
      loading = <Loading />;
      isLoading = true;
    }

    let sites = this.props.sites;
    if (this.props.currentUser.siteAccess) {
      sites = this.props.sites.filter(s => this.props.currentUser.siteAccess.includes(s.id));
    }

    let selectSiteMessage;
    if (this.state.selectedSite === 'Select a property') {
      selectSiteMessage = (
        <div className='adblock card no-sites-message'><p>No property selected.</p><p>Please select a property to generate a consent performance report.</p></div>
      )
    }

    return (
      <div className='consent-performance'>
        <ConfigProvider locale={enUS}>
          <div className='sites-dropdown-container'>
            <SiteAutocomplete
              sites={sites}
              selectSites={this.selectSites}
              hasAllSites={false}
              value={this.state.selectedSite}
            />
            <AllLocations selectCountry={this.selectCountry} />
          </div>
        </ConfigProvider>
        <div className='external filters'>
          <CustomDateRange selectDatePicker={this.selectDatePicker} />
          <CustomDatePicker
            className="cp-picker"
            selectDates={this.selectDates}
            format="MM/DD/YY"
            limit={60}
            disabled={this.props.pendingRequestsMap.some(request => request)}
            disabledDateOffset={1}
            selectedDatePicker={this.state.graphFilters.get('datePicker')}
          />
        </div>
        { actionStatusGraph }
        { vendorsGraph }
        {isLoading ? loading : ''}
        {/* {!isLoading && !noData ? exportCSV : ''} */}
        {!isLoading && noData && !selectSiteMessage ? <div className='adblock card'><h4 className='no-data'>No data to display</h4></div> : ''}
        { selectSiteMessage }
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    actionStatusData: store.consentPerformanceState.getIn(['actionStatusData', 'value']),
    vendorsData: store.consentPerformanceState.getIn(['vendorsData', 'value']),
    pendingRequestsMap: Map({
      actionStatusData: store.consentPerformanceState.getIn(['actionStatusData', 'pending']),
      vendorsData: store.consentPerformanceState.getIn(['vendorsData', 'pending']),
      sites: store.siteState.getIn(['sites', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getConsentReportActionStatusData,
    getConsentReportVendorsData,
    getAllSites,
  },
)(ConsentPerformanceReport);
