import React  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PurposeV2 as Purpose } from '../../../../../records/vendor_list_records';
import { Select } from '../../../../../styleguide';
const Option = Select.Option;

export default class TagSelect extends React.Component {
  static propTypes = {
    readOnly: PropTypes.bool.isRequired,
    vendorId: PropTypes.string.isRequired,
    purpose: PropTypes.instanceOf(Purpose).isRequired,
    purposeIndex: PropTypes.number.isRequired,
    updateVendorCategorization: PropTypes.func.isRequired,
    iab: PropTypes.bool.isRequired,
  };

  handleChange = (value) => {
    this.props.updateVendorCategorization(value, this.props.purpose.name, this.props.vendorId);
  };

  render() {
    const {
      purpose,
      iabPurpose,
      highlightPurposeCell,
      vendorId,
      iab,
      defaultLegalBasis,
      defaultVendorBasis,
      isCustomVendor,
      vendor,
      fixUncategorizedVendor,
    } = this.props;
    let vendorValue = 'NOT_APPLICABLE';

    const vendorCategorization = purpose.get('vendorCategorization')?.find(obj => obj.vendorId === vendorId);
    if (vendorCategorization) {
      vendorValue = !purpose.disclosureOnly
        ? (vendorCategorization.type === "DISCLOSURE_ONLY")
          ? 'DISCLOSURE_ONLY' : vendorCategorization.type
        : (vendorCategorization.type === 'CONSENT' || vendorCategorization.type === 'LEGITIMATE_INTEREST' || !vendorCategorization.type)
          ? '' : vendorCategorization.type
    } else {
      if (iab && purpose.type === 'IAB_PURPOSE') {
        vendorValue = defaultVendorBasis || defaultLegalBasis;
        fixUncategorizedVendor(purpose, vendor, vendorValue);
      } else {
        const consentVendors = purpose.get('vendorIds').toJS();
        if (consentVendors.indexOf(vendorId) !== -1) {
          vendorValue = 'CONSENT';
        };
      };
    };

    let options = ([
      <Option key="CONSENT" value="CONSENT">
        <span className="li-tag li-consent">User Consent</span>
      </Option>,
      <Option key="LEGITIMATE_INTEREST" value="LEGITIMATE_INTEREST">
        <span className="li-tag li-interest">Legitimate Interest</span>
      </Option>
    ]);
    if([3,4,5,6].includes(iabPurpose?.iabId)){
      options.pop() //remove LI for purposes 3,4,5,6
    }

    if (purpose.disclosureOnly) {
      options = (
        <Option key="DISCLOSURE_ONLY" value="DISCLOSURE_ONLY">
          <span className="li-tag li-disclosure">Disclosure Only</span>
        </Option>
      );
    } else if (defaultVendorBasis) {
      options = (
        <Option key={defaultVendorBasis} value={defaultVendorBasis}>
          <span className={`li-tag li-${defaultVendorBasis === 'CONSENT' ? 'consent' : 'interest'}`}>
            {(defaultVendorBasis === 'CONSENT') ? 'User Consent' : 'Legitimate Interest'}
          </span>
        </Option>
      );
    };

    const notOption = (isCustomVendor || purpose.type === 'CUSTOM')
      ? (
        <Option key="NOT_APPLICABLE" value="NOT_APPLICABLE">
          <span className="li-tag li-applicable">
            Not Applicable
          </span>
        </Option>
      )
      : (
        <Option key="NOT_ALLOWED" value="NOT_ALLOWED">
          <span className="li-tag li-allowed">
            Not Allowed
          </span >
        </Option >
      );

    return (
      <Select
        className={ classNames({ 'vlist-li-select': true, 'legal-basis-change' : highlightPurposeCell }) }
        defaultValue=""
        value={vendorValue}
        style={{ width: '100%' }}
        onChange={this.handleChange}
        disabled={this.props.readOnly}
      >
        {options}
        {notOption}
      </Select>
    );
  }
}
