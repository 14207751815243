import React from 'react';
import { Table } from 'antd';

export const WebsiteScanTable = (props) => (
  <Table
    dataSource={props.dataSource}
    columns={props.columns}
    size="small"
    rowKey={(record) => record.key || Math.random()}
    pagination={false}
    rowClassName={(record) => { 
        return props.rowClassName
        ? props.rowClassName
        : !record.colorSchemeApplies
        ? ''
        : record.isNew === true
        ? 'cookie-new'
        : record.modified === true
        ? 'cookie-modified'
        : ''}
    }
    scroll={{ x: true }}
  />
);
