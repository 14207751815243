import React, { useState, useEffect, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Divider, Menu, Input, Button, Checkbox, Space } from "antd";
import classNames from 'classnames';
import { useDebouncedSearch } from "../../hooks";

const TableFilterDropdown = React.memo(({
  prefixCls,
  filters,
  clearFilters,
  setSelectedKeys,
  selectedKeys,
  confirm,
  visible,
  showSearch,
  selectAllOption,
  cancelButton,
  dropdownClassName,
  multiple,
  handleSelectedKeysOnSave //when multiple selections have to be handled at once and outside onFilter
}) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedKeys);
  const [filterOptions, setFilterOptions] = useState(filters);
  const [selectAll, setSelectAll] = useState(true);
  const [selectAllOptions, setSelectAllOptions] = useState(multiple);
  const [searchValue, setSearchValue] = useState("");
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  const handleSelectAllChange = (val) => {
    if(multiple) {
      setSelectAll(!selectAll);
      setSelectAllOptions(!selectAllOptions);
    } else {
      setSelectAll(!selectAll);
      setSelectedOptions([]);
      setSelectedKeys([])
      handleSelectedKeysOnSave && handleSelectedKeysOnSave([])
      confirm({ closeDropdown: true });
  }
  };

  const handleCancel = (val) => {
    if(selectedKeys.length === 0) {
      setSelectedOptions(filters.map((f) => f.value));
      setSelectAll(true);
    } else {
      setSelectedOptions(selectedKeys);
      setSearchValue("");
    }
    confirm({ closeDropdown: true });
  };

  const handleSave = (val) => {
    if ((selectedOptions.length === 0 ) || (selectedOptions.length === filters.length)) {
      setSelectAll(true);
      setSelectAllOptions(true);
      clearFilters();
      handleSelectedKeysOnSave && handleSelectedKeysOnSave([]);
    } else {
      setSelectedKeys(selectedOptions);
      handleSelectedKeysOnSave && handleSelectedKeysOnSave(selectedOptions);
    }
    confirm({ closeDropdown: true });
    setSearchValue("");
  };

  const onSelect = (item) => {
    if(multiple) {
      const exists = selectedOptions?.includes(item);
      if (exists) {
        const newValue = [...selectedOptions].filter((o) => o !== item);
        setSelectedOptions(newValue);
      } else {
        const tempValue = [...selectedOptions];
        tempValue.push(item);
        setSelectedOptions(tempValue);
      }
    } else {
      setSelectAll(false);
      setSelectedOptions([item]);
      setSelectedKeys([item]);
      handleSelectedKeysOnSave && handleSelectedKeysOnSave([item]);
      confirm({ closeDropdown: true });
    }
  };

  const handleClear = () => {
    setSelectedOptions([]);
    setSelectedKeys([]);
    handleSelectedKeysOnSave && handleSelectedKeysOnSave([])
    clearFilters();
    setSelectAllOptions(false)
    if(!multiple) {
      setSelectAll(true);
    }
    confirm({ closeDropdown: true });
  };

  useEffect(() => {
    if (selectAllOptions) {
      setSelectedOptions(filters.map((f) => f.value));
    } else {
      setSelectedOptions([]);
    }
  }, [selectAllOptions]);

  useEffect(() => {
    if(multiple) {
      if (selectedOptions.length < filters.length) {
        setSelectAll(false);
      } else if (selectedOptions.length === filters.length) {
        setSelectAll(true);
      }
    } else {
      setSearchValue("");
    }
  }, [selectedOptions]);

  useEffect(() => {
    setFilterOptions(
      filters.filter((f) => {
        return f.text.toLowerCase().includes(searchValue.toLowerCase());
      })
    );
  }, [searchValue, filters]);

  useEffect(()=>{
    if(selectedKeys.length === 0) {
      setSelectedOptions(filters.map((f) => f.value));
      setSelectAll(true);
    } else {
      setSelectedOptions(selectedKeys);
    }
  },[selectedKeys])

  let className = classNames('spsg-filter-dropdown', dropdownClassName)

  return (
    <>
      <div className={className}>
        {showSearch && (
          <div className="list-search">
            <Input
              placeholder="Search"
              onChange={({ target: { value } }) => {
                debouncedChangeHandler(value);
              }}
            />
            <SearchOutlined />
          </div>
        )}
        <Menu selectedKeys={selectedOptions}>
          {selectAllOption && (
            <>
              <Menu.Item
                onClick={handleSelectAllChange}
                className={selectAll ? "selactive" : ""}
              >
                {multiple ? "Select all" : "All"}
                {multiple && <Checkbox checked={selectAll} />}
              </Menu.Item>
              <Divider className="filter-divider" />
            </>
          )}
          {filterOptions.map((o) => (
            <Menu.Item
              key={o.value}
              className={selectedOptions.includes(o.value) ? "selactive" : ""}
              onClick={() => onSelect(o.value)}
            >
              <Space>
                {o.icon ? o.icon : null}
                {o.text}
              </Space>
              { multiple && <Checkbox checked={selectedOptions.includes(o.value)} />}
            </Menu.Item>
          ))}
        </Menu>
        <div className="filter-button-group">
          {cancelButton ? (
            <Button onClick={handleCancel} type="secondary">
              Cancel
            </Button>
          ) : (
            <Button onClick={handleClear} type="secondary">
              Reset
            </Button>
          )}
         {multiple && <Button onClick={handleSave} type="primary">
            Apply
          </Button>}
        </div>
      </div>
    </>
  );
});

export default TableFilterDropdown;