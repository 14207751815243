
import React from 'react';
import NumberStep from './NumberStep';

export default class MessageSessionNumLimit extends React.Component {
  render() {
    return (
      <NumberStep
        {...this.props}
        label={ 'Message Limit' }
        propertyName={ 'limit' }
      />
    );
  }
}
