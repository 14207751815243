import PropTypes from 'prop-types';
import React from 'react';
import SiteDropdown from '../common/SiteDropdown.jsx';
import { getAltpayData, getRateExchange } from '../../actions/altpay_actions.js';
import { Table } from 'antd';
import moment from 'moment';
import { OffersSoldByDay, Price, Converter } from '../../records/altpay_records.js';
import { List, Map, Set } from 'immutable';
import { connect } from 'react-redux';
import Loading from '../common/Loading.js.jsx';
import ImmutablePropTypes from 'react-immutable-proptypes'
import AntDesignWrapper from '../common/AntDesignWrapper.jsx';
import { getAllSites } from '../../actions/site_actions.js';
import { CURRENCIES } from '../../constants.js';
import { User } from '../../records/account_records';

class AltPayPage extends React.Component {

  static propTypes = {
    getAltpayData: PropTypes.func.isRequired,
    altpayData: ImmutablePropTypes.listOf(PropTypes.instanceOf(OffersSoldByDay)).isRequired,
    getAllSites: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    sites: ImmutablePropTypes.list.isRequired,
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    getRateExchange: PropTypes.func.isRequired,
    converter: PropTypes.instanceOf(Converter).isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    inEditMessage: false,
  }

  constructor(props) {
    super(props);

    this.getCurrenciesQuantity = this.getCurrenciesQuantity.bind(this);
    this.generateDataSource = this.generateDataSource.bind(this);
    this.selectSites = this.selectSites.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      hasMultipleCurrencies: false,
      sortOrders: Map({
        day: null,
        offerId: null,
        price: null,
        numSold: null,
        totalValue: null,
        totalUSD: null,
      }),
      totals: Map({
        numSold: null,
        totalValue: null,
        totalUSD: null,
        day: 'total',
      }),
    };
  }

  componentDidMount() {
    this.props.getRateExchange();
    this.props.getAllSites();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.altpayData !== this.props.altpayData && newProps.altpayData.size) {
      const hasMultipleCurrencies = this.getCurrenciesQuantity(newProps.altpayData) > 1;
      this.setState({ totals: this.getTotals(newProps.altpayData, hasMultipleCurrencies), hasMultipleCurrencies });
    }

    if (((newProps.currentUser !== this.props.currentUser && this.props.sites.size) || (newProps.sites !== this.props.sites && this.props.currentUser))) {
      let siteIds = newProps.sites.map(site => site.id);
      if (this.props.currentUser.siteAccess) {
        siteIds = siteIds.filter(id => this.props.currentUser.siteAccess.includes(id));
      }
      this.props.getAltpayData(newProps.currentUser.accountId, siteIds);
    }
  }

  selectSites(event) {
    let ids;
    if (event === 'all properties') {
      if (this.props.currentUser.siteAccess) {
        ids = this.props.sites.map(site => site.id).filter(id => this.props.currentUser.siteAccess.includes(id));
      } else {
        ids = this.props.sites.map(site => site.id);
      }
    } else {
      ids = List([Number(event)]);
    }
    this.props.getAltpayData(this.props.currentUser.accountId, ids);
  }

  getAmountInUSD(price) {
    return this.props.converter.toUSD(price).amount;
  }

  getCurrenciesQuantity(altpayData) {
    let currencies = Set([]);
    altpayData.forEach((offersSoldByDay) => {
      currencies = currencies.union(offersSoldByDay.soldOffers.map((soldOffer) => soldOffer.totalPrice.currency));
    });
    return currencies.size;
  }

  getReadableDate(time) {
    return moment.utc(time.timestampHr).format('MMM D YYYY');
  }

  getTotals(altpayData, hasMultipleCurrencies){
    const numSold = altpayData.map((offersSoldByDay) => {
      return offersSoldByDay.soldOffers.map((offerInfo) => {
        return offerInfo.numSold;
      });
    }).flatten(true).reduce((el, total) => total + el, 0);

    const totalPrices = altpayData.map((offersSoldByDay) => {
      return offersSoldByDay.soldOffers.map((offerInfo) => {
        return offerInfo.totalPrice;
      });
    }).flatten(true);

    const currency = hasMultipleCurrencies ? CURRENCIES.USD : totalPrices.first().currency;
    let totalValue = this.getTotalValue(totalPrices, currency);

    return Map({ totalValue, totalUSD: totalValue, numSold });
  }

  getTotalValue(totalPrices, currency) {
    return totalPrices.map(price => {
      return currency === CURRENCIES.USD ? this.getAmountInUSD(price) : price.amount;
    }).reduce((el, total) => total + el, 0).toFixed(2) + ' ' + currency;
  }

  generateDataSource() {

    const dataSource = this.props.altpayData.map((offersSoldByDay, i) => {
      const day = this.getReadableDate(offersSoldByDay);
      return offersSoldByDay.soldOffers.map((offerInfo) => {
        return Map({
          key: day + offerInfo.offerDetails.id + i,
          day,
          offerId: offerInfo.offerDetails.id,
          price: offerInfo.offerDetails.price.amount.toFixed(2) + ' ' + offerInfo.offerDetails.price.currency,
          description: offerInfo.offerDetails.description,
          numSold: offerInfo.numSold,
          totalValue: offerInfo.totalPrice.amount.toFixed(2) + ' ' + offerInfo.totalPrice.currency,
          totalUSD: this.getAmountInUSD(offerInfo.totalPrice).toFixed(2) + ' ' + CURRENCIES.USD,
        });
      });
    }).flatten(true);

    return dataSource.push(Map({
      key: 'total',
      day: 'Total',
      offerId: '',
      price: '',
      description: '',
      numSold: this.state.totals.get('numSold'),
      totalValue: this.state.hasMultipleCurrencies ? '' : this.state.totals.get('totalValue'),
      totalUSD: this.state.hasMultipleCurrencies ? this.state.totals.get('totalValue') : '',
    }));
  }

  rowClassName(record) {
      if (record.key === 'total') return 'total';
  }

  priceSorter(a, b, key) {
    return this.keepTotalRowInTheBottom(a, b, key) || this.getAmountInUSD(this.stringToPrice(a)) - this.getAmountInUSD(this.stringToPrice(b));
  }

  numberSorter(a, b, key) {
    return this.keepTotalRowInTheBottom(a, b, key) || a - b;
  }

  keepTotalRowInTheBottom(a, b, key) {
    if (!a || a === this.state.totals.get(key)) {
      return this.state.sortOrders.get(key) === 'descend' ? -1 : 1
    } else if (!b || b === this.state.totals.get(key)) {
      return this.state.sortOrders.get(key) === 'descend' ? 1 : -1
    } else {
      return false
    }
  }

  onChange(pagination, filters, sorter) {
    this.setState({ sortOrders: this.state.sortOrders.set(sorter.columnKey, sorter.order) })
  }

  stringToPrice(string) {
    return new Price({ amount: Number(string.slice(0, -4)), currency: string.slice(-3)})
  }

  render() {

    const dayFilter = this.props.altpayData.map(offersSoldByDay => {
      const day = this.getReadableDate(offersSoldByDay);
      return Map({ text: day, value: day });
    }).toSet();

    const offerIdFilter = this.props.altpayData.map(offersSoldByDay => {
      return offersSoldByDay.soldOffers.map(offerInfo => {
        const id = offerInfo.offerDetails.id;
        return  Map({ text: id, value: id });
      });
    }).flatten(true).toSet();

    const priceFilter = this.props.altpayData.map(offersSoldByDay => {
      return offersSoldByDay.soldOffers.map(offerInfo => {
        const price = offerInfo.offerDetails.price.amount.toFixed(2) + ' ' + offerInfo.offerDetails.price.currency;
        return  Map({ text: price, value: price });
      });
    }).flatten(true).toSet();

    const descriptionFilter = this.props.altpayData.map(offersSoldByDay => {
      return offersSoldByDay.soldOffers.map(offerInfo => {
        const description = offerInfo.offerDetails.description;
        return Map({ text: description, value: description });
      });
    }).flatten(true).toSet();

    let columns = List([Map({
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      sorter: (a, b) => {
        const aForSort = a === 'Total' ? '' : new Date(a.day).getTime();
        const bForSort = b === 'Total' ? '' : new Date(b.day).getTime();
        return this.numberSorter(aForSort, bForSort, 'day');
      },
      filters: dayFilter,
      onFilter: (value, record) => {
        return record.day === value;
      },
    }), Map({
      title: 'Offer ID',
      dataIndex: 'offerId',
      key: 'offerId',
      filters: offerIdFilter,
      onFilter: (value, record) => {
        return record.offerId.toString() === value;
      },
      sorter: (a, b) => this.numberSorter(a.offerId, b.offerId, 'offerId'),
    }), Map({
      title: 'Offer Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => this.priceSorter(a.price, b.price, 'price'),
      filters: priceFilter,
      onFilter: (value, record) => {
        return record.price === value;
      },
    }),Map({
      title: 'Offer Description',
      dataIndex: 'description',
      key: 'description',
      className: 'description',
      filters: descriptionFilter,
      onFilter: (value, record) => {
        return record.description === value;
      },
    }),Map({
      title: 'Offers Sold',
      dataIndex: 'numSold',
      key: 'numSold',
      sorter: (a, b) => this.numberSorter(a.numSold, b.numSold, 'numSold'),
    }),Map({
      title: 'Total Value',
      dataIndex: 'totalValue',
      key: 'totalValue',
      sorter: (a, b) => this.priceSorter(a.totalValue, b.totalValue, 'totalValue'),
    })]);
    if (this.state.hasMultipleCurrencies) {
      columns = columns.push({
        title: 'Total USD',
        dataIndex: 'totalUSD',
        key: 'totalUSD',
        sorter: (a, b) => this.priceSorter(a.totalUSD, b.totalUSD, 'totalUSD'),
      });
    }

    let loading;
    let table;
    let noOffersMessage;

    if (this.props.pendingRequestsMap.some(request => request)) {
      loading = <Loading />;
    } else if (this.props.altpayData.size) {
      table = (
        <AntDesignWrapper>
          <Table
            className="altpay-table card"
            dataSource={ this.generateDataSource().toJS() }
            columns={ columns.toJS() }
            pagination={ false }
            rowClassName={ this.rowClassName }
            onChange={ this.onChange }
          />
        </AntDesignWrapper>
      );
    } else {
      noOffersMessage = 'There were no Alt Pay offers sold.';
    }

    return (
      <div className='altpay'>
        <SiteDropdown
          sites={ this.props.currentUser.siteAccess ? this.props.sites.filter(s => this.props.currentUser.siteAccess.includes(s.id)) : this.props.sites }
          selectSites={ this.selectSites }
        />

        { table }
        { loading }
        { noOffersMessage }

      </div>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    altpayData: store.altpayState.getIn(['altpayData', 'value']),
    converter: store.altpayState.getIn(['converter', 'value']),
    sites: store.siteState.getIn(['sites', 'value']),
    pendingRequestsMap: Map({
      altpayData: store.altpayState.getIn(['altpayData', 'pending']),
      converter: store.altpayState.getIn(['converter', 'pending']),
      sites: store.siteState.getIn(['sites', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getAllSites,
    getAltpayData,
    getRateExchange,
  },
)(AltPayPage);
