import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Input, Button } from '../../styleguide'
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const SORT_STATES = [ null, true, false ];

export default class SortFilter extends React.Component {

  static propTypes = {
    data: ImmutablePropTypes.iterable.isRequired,
    searchField: PropTypes.string,
    sorters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sort: PropTypes.func.isRequired,
      default: PropTypes.bool,
    })),
    onUpdate: PropTypes.func.isRequired,
  };

  static sortByCharCode (valueA, valueB) {
    // move valueB before valueA if valueB has a smaller char code.
    return String(valueA) > String(valueB) ? 1 : -1;
  }

  static sortByDate (valueA, valueB) {
    // diff returns the time between two moments. We want to order by
    // closest to now first. When we diff one moment against a second
    // moment it returns a positive number if the first moment is
    // closest to now. Sorting should return a -1 if valueA comes
    // before valueB in the order. Thus, we diff valueB against
    // valueA. If valueB is closer to now we will return a positive
    // number, meaning valueB comes first in order. If valueA is
    // closer to now we will return a negative number, meaning valueA
    // comes first in order.
    return (new moment(valueB)).diff(new moment(valueA));
  }

  static descriptionSorter = {
    field: 'description',
    name: 'A-Z',
    sort: SortFilter.sortByCharCode,
  };

  static dateSorter = {
    field: 'created_at',
    name: 'Date',
    sort: SortFilter.sortByDate,
    default: true,
  };

  state = {
    searchValue: '',
    sortValue: null,
    sortUp: null,
  };

  render () {
    return (
      <div className='sort-filter-wrapper'>
        <Input
          value={ this.state.searchValue }
          onChange={ this._onSearchValueUpdate }
          suffix={<SearchOutlined />}
        />
        { this.props.sorters.map((sorter, i) => (
          <Button
            key={ i }
            size='small'
            icon={<LegacyIcon
              type={
                this.state.sortValue === i ? (
                  this.state.sortUp ? 'up' : 'down'
                ) : null
              } />}
            onClick={ this._onSortValueUpdate.bind(this, i) }
          >
            { sorter.name }
          </Button>
        )) }
      </div>
    );
  }

  componentDidMount () {
    const defaultSorterIndex =
        this.props.sorters.findIndex((sorter) => sorter.default);

    if (defaultSorterIndex > -1) {
      this._onSortValueUpdate(defaultSorterIndex);
    }
  }

  componentDidUpdate (lastProps) {
    // make sure we properly filter new incoming data
    if (!lastProps.data.equals(this.props.data)) {
      this._onUpdate();
    }
  }

  _onSearchValueUpdate = (event) => {
    this.setState({ searchValue: event.target.value }, this._onUpdate);
  };

  _onSortValueUpdate = (i) => {
    this.setState((prevState) => {
      let sortValue, sortUp;

      if (prevState.sortValue === i) {
        // toggle through sort states
        sortValue = i;

        const index = SORT_STATES.indexOf(prevState.sortUp);
        const nextIndex = (index % SORT_STATES.length) + 1;

        sortUp = SORT_STATES[nextIndex];
        // if sortUp is null then turn off sorting
        if (sortUp == null) sortValue = null;
      } else {
        sortValue = i;
        sortUp = true;
      }

      return { sortValue, sortUp };
    }, this._onUpdate);
  };

  _onUpdate = () => {
    let resultData = this.props.data;

    if (this.state.searchValue) {
      resultData = resultData.filter((value) => {
        return (value.get(this.props.searchField) || '').toLowerCase()
          .indexOf(this.state.searchValue.toLowerCase()) > -1;
      });
    }

    if (this.state.sortValue != null) {
      const index = this.state.sortValue;
      const sorter = this.props.sorters[index];

      resultData = resultData.sort((valueA, valueB) => {
        // flip the argument order when sorting down
        const inputValueA = this.state.sortUp ? valueA : valueB;
        const inputValueB = this.state.sortUp ? valueB : valueA;

        return sorter.sort(
          inputValueA.get(sorter.field),
          inputValueB.get(sorter.field),
        );
      });
    }

    this.props.onUpdate(resultData);
  };

}
