import React, { useState } from "react";
import { Divider } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Table, Button } from "../../../../../../styleguide";
import CookieTable from "./CookieTable";

const SelectedView = (props) => {
  const {
    sectionTitle,
    sectionSubTitle,
    searchPlaceholder,
    btnTitle,
    setShowSelectedView,
    handleRemoveCookies,
    selectedCookies,
    showEditModal,
    purposesTreeData,
    setTableId,
    setIsImportCookiesSelected,
    allCategories,
    handleAddManuallyCancel,
    isNonIab
  } = props;
  const [checkedCookieIds, setCheckedCookieIds] = useState([]);
  const [searchText, setSearchText] = useState('')
  
  const onChangeSearch = ({target: {value}}) => {
    setSearchText(value)
  }
  return (
    <div className="sl-vendors-new">
      <div className="sv-header">
        <span>{sectionTitle}</span>
        <span
          className="bck-btn"
          onClick={() => {
            setShowSelectedView(false);
            setSearchText('');
            setTableId('import-cookies-table');
            setIsImportCookiesSelected(true);
            handleAddManuallyCancel();
          }
          }
        >{`< Go back`}</span>
      </div>
      <div className="sv-note">{sectionSubTitle}</div>
      <div className="sv-search">
        <Input
          type="search"
          placeholder={searchPlaceholder}
          suffix={<SearchOutlined />}
          className="sv-search-input"
          value={searchText}
          onChange={onChangeSearch}
        ></Input>
        <Button className="btn-remove-cookie" type="secondary" size="small" onClick={() =>{
          handleRemoveCookies(checkedCookieIds);
          setCheckedCookieIds([]);
          setSearchText('');
          }} disabled={!checkedCookieIds.length}>
          {btnTitle}{checkedCookieIds.length ? `(${checkedCookieIds.length})` : null}
        </Button>
      </div>
      <CookieTable
        cookies={selectedCookies}
        checkedCookieIds={checkedCookieIds}
        setCheckedCookieIds={setCheckedCookieIds}
        hasAddedViaColum={true}
        showEditModal={showEditModal}
        purposesTreeData={purposesTreeData}
        showManuallyAddedCookies={true}
        allCategories={allCategories}
        searchText={searchText}
        isNonIab={isNonIab}
      />
    </div>
  );
};

export default SelectedView;
