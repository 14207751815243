import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { Modal, Tabs, Button } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { PurposeV2 as Purpose, Vendor, CategorizationSchema } from '../../../../../records/vendor_list_records';
import ConfigurePurposeTab from '../../ConfigurePurposeTab';
import { GCM_ACTION_TYPE } from '../../../../../constants';
import ActionsTab from '../../../../common/ActionsTab';
import { addGcmActions } from '../../util'

const TabPane = Tabs.TabPane;
const { confirm } = Modal;

export default class EditPurposeModal extends React.Component {
  static propTypes = {
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)).isRequired,
    purpose: PropTypes.instanceOf(Purpose).isRequired,
    closeModal: PropTypes.func.isRequired,
    handleApply: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    allListVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    globalVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    readOnly: PropTypes.bool.isRequired,
    purposeIndex: PropTypes.number.isRequired,
    deletePurpose: PropTypes.func.isRequired,
    deleteStack: PropTypes.func.isRequired,
    stackPurposeIsIn: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)),
    stackIndex: PropTypes.number,
    validateHandler: PropTypes.func.isRequired,
    removeCookieCategories: PropTypes.func.isRequired,
  }

  state = {
    extraUrlField: false,
    purpose: this.props.purpose,
    vl: this.props.vendorList,
    purposeIndex: this.props.purposeIndex,
    error: null
  }

  updateCategoriesInVl = (categories, purpose) => {
    let vendorScanCategorizations = this.state.vl.vendorScanCategorizations.toJS();
    if(purpose && purpose.type !== 'CUSTOM') {
      vendorScanCategorizations = vendorScanCategorizations.filter((item) =>
        item.vendorListCategoryName ? !(item.vendorListCategoryName === purpose.name) : !(item.vendorListCategoryId === purpose.id)
      )
      this.setState({
        vl: this.state.vl
        .set('vendorScanCategorizations', fromJS(vendorScanCategorizations))    
        .update('categories', () => categories),
      });
    }else {
      this.setState({
        vl: this.state.vl.update('categories', () => categories),
      });
    }
  }

  updatePurpose = (purpose) => {
    this.setState({ purpose });
  }

  updateActions = (actions, actionType) => {
    const purpose = this.state.purpose.set(actionType, actions);
    this.setState({ purpose });
  }


  updateActionExtension = (type, key) => (value) => {
    const updatedPurpose = this.state.purpose.update(`${type}ActionExtension`, (ext) => ({ ...ext, [key]: value }));
    this.setState({ purpose: updatedPurpose });
  }

  decrementIdx = () => {
    this.setState({ purposeIndex: this.state.purposeIndex - 1 });
  }

  handleCancelModalChanges = () => {
    this.setState({ purpose: this.props.purpose });
    this.props.closeModal();
  }

  handleOnOk = () => {
    let { purpose } = this.state;
    const { vl: { vendors } } = this.state;
    const isExist = purpose.type === 'CUSTOM' && this.props.validateHandler(purpose.name, purpose.id);
    
    if(isExist) {
      this.setState({error: isExist })
      return
    };
    purpose = purpose
      .update('consentActionExtension', (ext) => {
        return {
          ...ext,
          cookies: ext.cookies.filter(rmBadCookies),
        }
      })
      .update('rejectActionExtension', (ext) => {
        return {
          ...ext,
          cookies: ext.cookies.filter(rmBadCookies)
        }
      });

    function rmBadCookies(cookie) {
      return (
        cookie.key
        && cookie.value
        && (cookie.session || cookie.maxAge)
      );
    };

    if (purpose.disclosureOnly !== this.props.purpose.disclosureOnly) {
      purpose = purpose.update('vendorCategorization', (vCats) => {
        return vCats.map(cat => cat.set('type', 'NOT_APPLICABLE'))
      });
    };

    // remove Google Consent Mode actions if we are disclosure only
    if (purpose.disclosureOnly) {
      purpose = addGcmActions(purpose)
    }

    if (purpose.defaultCustomLegalBasis && !purpose.disclosureOnly) {
      const newCategorizations = vendors
        .filter(({ vendorType }) => vendorType === 'CUSTOM')
        .filterNot(({ id }) => purpose.vendorCategorization.findIndex(({ vendorId }) => vendorId === id) !== -1)
        .map(({ id }) => new CategorizationSchema({ vendorId: id, type: purpose.defaultCustomLegalBasis, cookies: [] }))

      purpose = purpose.update('vendorCategorization', vc => vc.concat(newCategorizations))
    }

    if (this.props.stackPurposeIsIn) {
      const updatedStack = this.props.stackPurposeIsIn.setIn(['categories', this.state.purposeIndex], purpose);
      this.props.handleApply(this.state.vl.setIn(['categories', this.props.stackIndex], updatedStack));
    } else if (purpose.type === 'IAB_STACK' || purpose.type === 'CUSTOM_STACK') {
      // if there were changes to stack actions apply these changes to each purpose of this stacks
      const changeToConsentActions = purpose.consentActions.toString() !== this.props.purpose.consentActions.toString();
      const changeToRejectActions = purpose.rejectActions.toString() !== this.props.purpose.rejectActions.toString();
      if (!changeToConsentActions && !changeToRejectActions) {
        this.props.handleApply(this.state.vl.setIn(['categories', this.state.purposeIndex], purpose));
        return
      }
      const message1 = 'There were changes made to ';
      const message2 = '. These changes will be applied to every purpose included into this stack';
      let messageChanged;
      if (changeToConsentActions && changeToRejectActions) {
        messageChanged = 'Consent Actions and Reject Actions';
      } else if (changeToConsentActions) {
        messageChanged = 'Consent Actions';
      } else if (changeToRejectActions) {
        messageChanged = 'Reject Actions';
      }
      const confirmMessage = message1 + messageChanged + message2;
      confirm({
        title: 'Apply to all purposes?',
        content: confirmMessage,
        onOk: () => {
          let updatedCategories = purpose.categories;
          if (changeToConsentActions) {
            updatedCategories = updatedCategories.map(c => {
              return c.set('consentActions', purpose.consentActions);
            });
          }
          if (changeToRejectActions) {
            updatedCategories = updatedCategories.map(c => {
              return c.set('rejectActions', purpose.rejectActions);
            });
          }

          const updatedStack = purpose.set('categories', updatedCategories);
          this.props.handleApply(this.state.vl.setIn(['categories', this.state.purposeIndex], updatedStack));
        },
        onCancel() {
          return;
        },
      });

    } else {
      this.props.handleApply(this.state.vl.setIn(['categories', this.state.purposeIndex], purpose));
    }
    this.setState({error: null})
  }

  handleRemove = () => {
    if (this.props.purpose.type === 'IAB_STACK' || this.props.purpose.type === 'CUSTOM_STACK') {
      this.props.deleteStack(this.props.purpose);
    } else {
      this.props.deletePurpose(this.props.purposeIndex, this.props.purpose);
    }
  }

  render() {
    const p = this.state.purpose;

    // TODO: upgrade antd and use okButtonProps={{ disabled: true }}
    let removePurposeButton;
    if (!this.props.stackPurposeIsIn) {
      removePurposeButton = <Button className="purpose-delete" key="delete" onClick={this.handleRemove}>Remove</Button>;
    }
    return (
      <Modal
        visible={this.props.visible}
        className='edit-purpose'
        width="700px"
        closable={false}
        destroyOnClose
        footer={
          this.props.readOnly ? [
          <Button key="back" onClick={this.handleCancelModalChanges}>Close</Button>,
          ]
          : [
            <Button key="back" onClick={this.handleCancelModalChanges}>Cancel</Button>,
            removePurposeButton,
            <Button key="submit" type="primary" onClick={this.handleOnOk}>Apply changes</Button>,
          ]
      }
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={(p.type === 'IAB_STACK' || p.type === 'CUSTOM_STACK') ? "Configure Stack" : "Configure Purpose"} key="1">
            <ConfigurePurposeTab
              vendorList={this.state.vl}
              updateCategoriesInVl={this.updateCategoriesInVl}
              purpose={p}
              updatePurpose={this.updatePurpose}
              iabPurposes={this.props.iabPurposes}
              iabStacks={this.props.iabStacks}
              allListVendors={this.props.allListVendors}
              globalVendors={this.props.globalVendors}
              readOnly={this.props.readOnly}
              purposeIndex={this.state.purposeIndex}
              decrementIdx={this.decrementIdx}
              error={this.state.error}
              validateHandler={this.props.validateHandler}
              isInStack={this.props.stackPurposeIsIn}
            />
          </TabPane>
          <TabPane tab="Consent Actions" key="2">
            <ActionsTab
              actions={p.consentActions}
              updateActions={(actions) => this.updateActions(actions, 'consentActions')}
              geoTargeting={p.consentActionExtension.geoTargeting}
              onStatusChangeOnly={p.consentActionExtension.onStatusChangeOnly}
              onNewUser={p.consentActionExtension.onNewUser}
              actionCookies={p.consentActionExtension.cookies}
              updateGeoTargeting={this.updateActionExtension('consent', 'geoTargeting')}
              updateOnStatusChangeOnly={this.updateActionExtension('consent', 'onStatusChangeOnly')}
              updateOnNewUser={this.updateActionExtension('consent', 'onNewUser')}
              updateActionCookies={this.updateActionExtension('consent', 'cookies')}
              readOnly={this.props.readOnly}
            />
          </TabPane>
          <TabPane tab="Reject Actions" key="3">
            <ActionsTab
              actions={p.rejectActions}
              updateActions={(actions) => this.updateActions(actions, 'rejectActions')}
              geoTargeting={p.rejectActionExtension.geoTargeting}
              onStatusChangeOnly={p.rejectActionExtension.onStatusChangeOnly}
              onNewUser={p.rejectActionExtension.onNewUser}
              actionCookies={p.rejectActionExtension.cookies}
              updateGeoTargeting={this.updateActionExtension('reject', 'geoTargeting')}
              updateOnStatusChangeOnly={this.updateActionExtension('reject', 'onStatusChangeOnly')}
              updateOnNewUser={this.updateActionExtension('reject', 'onNewUser')}
              updateActionCookies={this.updateActionExtension('reject', 'cookies')}
              readOnly={this.props.readOnly}
              isRejectActions
            />
          </TabPane>
        </Tabs>

      </Modal>
    );
  }
}
