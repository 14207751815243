import PropTypes from 'prop-types';
import React from 'react';
import { Toggle, Infotip } from '../../styleguide'
import classNames from 'classnames';

const types = {};
types.COPY = 'avo-sp-copy';

export default class PopoverButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.keys(types).map(key => types[key])).isRequired,
    switchOptions: PropTypes.array,
  }

  static defaultProps = {
    popupText: '',
    switchOptions: null,
  }

  render() {
    const className = classNames('avo-dialogue-icons', this.props.type);
    const button = (
      <button
        className={ className }
        onClick={ this.props.onClick }
      />
    )
    if (this.props.switchOptions) {
     const content = this.props.switchOptions.map((obj) => {
        return (
          <div key={ obj.popupText }>
            <p>{ obj.popupText }</p>
            <Toggle
              checked={ obj.isChecked }
              onChange={ obj.onChecked }
              disabled={ obj.disabled }
            />
          </div>
        );
      });
      return (
        <Infotip
          className="tooltip-popover"
          content={content}
        >
          { button }
        </Infotip>
      );
    } else {
      return button;
    }
  }
}

PopoverButton.types = types;
