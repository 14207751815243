import 'whatwg-fetch';
import { fromJS, OrderedSet } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper';
import { FETCHED } from '../../constants';
import { Partitionset, Partition } from '../../records/partitionset_records.js';
import _ from 'lodash';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export const getPartitionsetsAjax = (siteId) => {
    const url = '/msg/get_partitionset_list?site_id=' + siteId;
    if (isFetched(url)) {
        return Promise.resolve(FETCHED);
    } else {
        return fetch(permissionsSvsBase + url, {
            credentials: 'include',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
        }).then(checkStatus)
            .then(resp => resp.json())
            .then(resp => {
                addToFetched(url);
                const partitionsets = resp.data.partitionset_data_list.map(pset => partitionsetToRecord(pset));
                return OrderedSet(partitionsets);
            })
    }
};

export const createPartitionsetAjax = (partitionset) => {
    const psetJson = JSON.stringify({ name: partitionset.description, partitions: partitionset.partitions });
    const url = '/msg/create_partitionset?site_id=' + partitionset.site_id;
    return fetch(permissionsSvsBase + url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
            description: partitionset.description,
            partitionset_json: psetJson,
            campaign_type_id: partitionset.campaign_type_id || 0
        })
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => partitionsetToRecord(resp.data.partitionset_data))
}

export const createSiteGroupPartitionsetAjax = (siteGroupId, accountId, partitionset) => {
    const psetJson = JSON.stringify({ name: partitionset.description, partitions: partitionset.partitions });
    const partitionSetBody = {
        description: partitionset.description,
        partitionset_json: psetJson,
        campaign_type_id: partitionset.campaign_type_id || 0
    }
    const url = `${permissionsSvsBase}/site-group/partition-set?siteGroupId=${siteGroupId}`;
    return fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
            accountId,
            partitionSetBody,
        }),
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
            return fromJS({
                partitionsets: OrderedSet(resp.partitionSetResponseObjs.map(s => partitionsetToRecord(s))),
                sitesWithErrors: resp.sitesWithErrors,
            })
        })
}

export const updatePartitionsetAjax = (partitionset) => {

    const psetJson = JSON.stringify({ name: partitionset.description, partitions: partitionset.partitions });
    const campaignTypeId = partitionset.campaign_type_id || 0;

    const postBody = {
        id: partitionset.id,
        description: partitionset.description,
        partitionset_json: psetJson,
        campaign_type_id: campaignTypeId,
      };

    const url = '/msg/update_partitionset?id=' + partitionset.id + '&campaign_type_id=' + campaignTypeId;
    return fetch(permissionsSvsBase + url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(postBody),
    })
        .then(checkStatus)
        .then(() => partitionset)
}

export const updateSiteGroupPartitionsetAjax = (siteGroupId, accountId, partitionset) => {
    const psetJson = JSON.stringify({
        name: partitionset.description,
        partitions: partitionset.partitions
    });

    const partitionSetBody = {
        description: partitionset.description,
        partitionset_json: psetJson,
        id: partitionset.id,
        campaign_type_id: partitionset.campaign_type_id || 0,
    };
    const url = `${permissionsSvsBase}/site-group/partition-set?siteGroupId=${siteGroupId}`;
    return fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
            accountId,
            partitionSetBody,
        }),
    }).then(checkStatus)
        .then(resp => resp.json())
        .then(resp => {
            return fromJS({
                partitionsets: OrderedSet(resp.partitionSetResponseObjs.map(s => partitionsetToRecord(s))),
                sitesWithErrors: resp.sitesWithErrors,
            })
        })
}

export const deletePartitionsetAjax = (partitionsetId) => {
    const url = '/msg/delete_partitionset?partitionset_id=' + partitionsetId
    return fetch(permissionsSvsBase + url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
    }).then(checkStatus)
        .then(() => partitionsetId)
};

export const deleteSiteGroupPartitionsetAjax = (
    siteGroupId,
    accountId,
    partitionSetId
) => {
    const url = `${permissionsSvsBase}/site-group/partition-set?siteGroupId=${siteGroupId}&partitionSetId=${partitionSetId}`;
    return fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
        body: JSON.stringify({ accountId })
    })
        .then(checkStatus)
        .then(resp => resp.json())
        .then((resp) => {
            return fromJS({
                partitionsetIds: resp.deletedPartitionSets.map(s => s.partitionSetId),
                sitesWithErrors: resp.sitesWithErrors,
            })
        })
}; 

export function partitionsetToRecord(pset) {
    // debugger
    // XXX
    if (!pset.partitionset_json) return pset;
    const parsed = JSON.parse(pset.partitionset_json);
    let partitions = parsed.partitions;
    partitions = fromJS(partitions.map(p => new Partition(p).update('ruleset', (r => fromJS(r)))));

    let psetForRecord = pset;

    psetForRecord.partitions = partitions;
    psetForRecord = _.omit(pset, ['partitionset_json', 'updated_at']);
    // return new Partitionset(psetForRecord);
    const result = new Partitionset(psetForRecord)

    return result
}
