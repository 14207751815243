import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from "react-router";
import { accountHasPrivacyLens } from '../util';

class HomePage extends React.PureComponent {

  componentDidMount() {
    if (this.props.currentUser) {
      this.redirect();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.redirect();
    }
  }

  redirect = () => {
    let { currentUser } = this.props;
    let dashboardDefaultPath = '';
    const hasDiagnoseDashboardAccess = (currentUser.accountFeatures.includes('diagnose_dashboard') && ((currentUser.featureAccess && currentUser.featureAccess.includes('Diagnose Dashboard') || currentUser.allFeatureAccess)));

    // if dialogue (gdpr, ccpa, usnat, adblock) then default landing page should be diagnose, else if diagnose use that
    if(currentUser.accountFeatures.includes('tcf_v2')) {
      dashboardDefaultPath = '/gdprtcfv2_report'
    } else if(currentUser.accountFeatures.includes('ccpa')) {
      dashboardDefaultPath = '/us_privacy_report'
    } else if(currentUser.accountFeatures.includes('usnat')) {
      dashboardDefaultPath = '/us_privacy/regulations'
    } else if(currentUser.accountFeatures.includes('non_iab_vl')) {
      dashboardDefaultPath = '/non-iab/vendor_lists'
    } else if(currentUser.accountFeatures.includes('adblock')) {
      dashboardDefaultPath = '/adblock'
    } else if(hasDiagnoseDashboardAccess) {
      dashboardDefaultPath = '/diagnose/alerts';
    } else if(accountHasPrivacyLens(currentUser.accountFeatures)) {
      dashboardDefaultPath = '/privacy_lens/scoring_results'
    } 
    browserHistory.push(dashboardDefaultPath);
  }

  render() {
    return null;
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
  };
};

export default connect(mapStateToProps)(HomePage);
