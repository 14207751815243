import React, { Component,Fragment, useEffect } from 'react';
import { FilterFilled } from '@ant-design/icons';
import { Popover, Button, Checkbox,Divider } from 'antd';

 const CustomFilter = (props) => {
 
 const [checkedListMsg, setMsgCheckedList] = React.useState(props.defaultCheckedList.messageType);
 const [checkedListTemp, setTempCheckedList] = React.useState(props.defaultCheckedList.templateType);
 const [checkedListStatus, setStatusCheckedList] = React.useState(props.defaultCheckedList.status);
 const [indeterminateMsg, setIndeterminateMsg] = React.useState(false);
 const [indeterminateTemp, setIndeterminateTemp] = React.useState(false);
 const [indeterminateStatus, setIndeterminateStatus] = React.useState(false);
 const [indeterminateSelectAll, setIndeterminateSelectAll] = React.useState(false);
 const [selectAllType, setSelectAllType] = React.useState(true)
 const [checkAllMsgType, setCheckAllMsgType] = React.useState(true);
 const [checkAllTempType, setCheckAllTempType] = React.useState(true);
 const [checkAllStatus, setCheckAllStatus] = React.useState(true);
 const [visible, setVisible] = React.useState(false)

  const onApplyChanges = () => {
    props.filterCondition(checkedListMsg,checkedListTemp,checkedListStatus);
  };

  const onChange = (list,type) => {
    let typeOptions = props.options[type]
    let options
    switch(type){
      case "messageType":
        options = typeOptions.map(o => o.value)
        setMsgCheckedList(list);
        setIndeterminateMsg(!!list.length && list.length < options.length);
        setCheckAllMsgType(list.length === options.length);
      break;
      case "templateType":
        options = typeOptions.map(o => o.value)
        setTempCheckedList(list);
        setIndeterminateTemp(!!list.length && list.length < options.length);
        setCheckAllTempType(list.length === options.length);
      break;
      case "status":
        options = typeOptions.map(o => o.value)
        setStatusCheckedList(list);
        setIndeterminateStatus(!!list.length && list.length < options.length);
        setCheckAllStatus(list.length === options.length);
      break;
    }  
  };

  const onCheckAllChange = (e,type) => {
    let typeOptions = props.options[type]
    let options = typeOptions.map(o => o.value)
    switch(type){
      case "messageType":
      setMsgCheckedList(e.target.checked ? options : []);
      setIndeterminateMsg(false);
      setCheckAllMsgType(e.target.checked);
      break;
      case "templateType":
        setTempCheckedList(e.target.checked ? options : []);
        setIndeterminateTemp(false);
        setCheckAllTempType(e.target.checked);
      break;
      case "status":
        setStatusCheckedList(e.target.checked ? options : []);
        setIndeterminateStatus(false);
        setCheckAllStatus(e.target.checked);
      break;
    }  
  };

  const onSelectAllChange = (e) => {
      setSelectAllType(e.target.checked)
      setIndeterminateSelectAll(false);
      let keys = Object.keys(props.options)
      keys.map(k => {
        onCheckAllChange(e, k)
      })
  }

  const handleVisibleChange = (visible) => {
    setVisible(visible)
  }

  const onCancel = () => {
    setVisible(false)
  }

  const content = (
    <div>
      <Checkbox 
      indeterminate={indeterminateMsg} 
      onChange={(e) => onCheckAllChange(e,"messageType")} 
      checked={checkAllMsgType}>
        Message Type
      </Checkbox>
      <Checkbox.Group
        style={{marginLeft:'20px'}}
        options={props.options.messageType}
        value={checkedListMsg}
        onChange={(e) => onChange(e,"messageType")}
      />
      {props.options.templateType && (
      <React.Fragment>
      <Checkbox 
      indeterminate={indeterminateTemp} 
      onChange={(e) => onCheckAllChange(e,"templateType")} 
      checked={checkAllTempType}>
        Template Type
      </Checkbox>
      <Checkbox.Group
        style={{marginLeft:'20px'}}
        options={props.options.templateType}
        value={checkedListTemp}
        onChange={(e) => onChange(e,"templateType")}
      />
      </React.Fragment>
      )}
       <Checkbox 
      indeterminate={indeterminateStatus} 
      onChange={(e) => onCheckAllChange(e,"status")} 
      checked={checkAllStatus}>
        Status
      </Checkbox>
      <Checkbox.Group
        style={{marginLeft:'20px'}}
        options={props.options.status}
        value={checkedListStatus}
        onChange={(e) => onChange(e,"status")}
      />
       <Divider />
      <div className="btnContainer">
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onApplyChanges} className="aplyBtn">
          Apply
        </Button>
      </div>
    </div>
  );


  return (
      <Popover
        overlayClassName="filter-popup"
        content={content}
        open={visible}
        onOpenChange={handleVisibleChange}
        title={
          <div className="titleContainer">
            <div className="title1">Filter</div>         
          </div>
        }
        placement="bottom"
      >
        <FilterFilled
          className="icon-style"
        />
      </Popover>
    );
  }
  export default CustomFilter;