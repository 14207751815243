import { Record, List } from 'immutable';
import moment from 'moment';

export const AdblockRecord = Record({
  tableData: List([]),
});

export const GraphFilters = Record({
  filters: List([]),
  pageview_type: null,
  traffic_type: 'desktop',
  segment_by: 'adblocker_detected',
  start_date: moment(),
  end_date: moment(),
  client_domain: null,
  granularity: 'day',
  geo: null,
});
