import React from 'react';
import { Select } from '../../styleguide';
import Logo from '../../assets/images/spt-logo.png';
import CautionBar from '../navbar/CautionBar';
import DiagnoseNotificationsPopover from '../diagnose/notifications/components/NotificationsPopover';

const { Option } = Select;

export default class SubNavigation extends React.Component {
  onChange = (value) => {
    const vals = value.split('-');
    if (vals[0] === 'site') {
      this.props.selectSite(vals[1], null);
    } else if (vals[0] === 'sitegroup') {
      this.props.selectSite(vals[1], vals[2]);
    }
  }
  hasFeature = (feature) => {
    // if (this.props.currentUser.accountId === 22 && feature !== 'recovery') return true;
    return this.props.currentUser.accountFeatures.includes(feature);
  }

  hasFeatureAndUserAccess = (feature, userFeature) => {
    const currentUser = this.props.currentUser;
    return this.hasFeature(feature) && ((currentUser.featureAccess && currentUser.featureAccess.includes(userFeature)) || currentUser.allFeatureAccess);
  }

  render() {
    const { sites, siteGroups, siteId, siteGroupId, currentUser, showSiteDropdown, showDiagnoseNotifications } = this.props;
    
    const hasFeatureAccess = this.hasFeature('tcf_v2') || this.hasFeature('non_iab_vl') || this.hasFeature('ccpa');
    const hasCampaignEntitiesAccess = hasFeatureAccess && (currentUser.featureAccess && currentUser.featureAccess.includes('Campaign Entities')) || currentUser.allFeatureAccess;

    let siteValue;
    if (siteGroupId) {
      siteValue = `sitegroup-${siteId}-${siteGroupId}`;
    } else if (siteId) {
      siteValue = `site-${siteId}`;
    }

    const isMasq = !!this.props.currentUser?.masqed;
    const hasDiagnoseAccess = (this.hasFeature('diagnose_dashboard') || this.hasFeature('diagnose_vendor_trace'));

    return (
      <div className="spt-subnav">
        <img src={Logo} alt="" />

        <CautionBar
          isMasq={isMasq}
          isCanary={!!this.props.isCanary}
          isLocalEnv={!!this.props.isLocalEnv}
          accountName={this.props.currentUser.accountName}
        />

        <div className="spt-top-menu">
          { hasDiagnoseAccess && showDiagnoseNotifications ? <DiagnoseNotificationsPopover /> : null }
          { hasCampaignEntitiesAccess && showSiteDropdown ? (
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a Site"
              optionFilterProp="children"
              onChange={this.onChange}
              value={siteValue}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              { sites.map((s) => <Option key={`site-${s.id}`} value={`site-${s.id}`}>{s.domain}</Option>)}
              { siteGroups.map((s) => <Option key={`sitegroup-${s.siteId}-${s.id}`} value={`sitegroup-${s.siteId}-${s.id}`}>{s.name}</Option>)}
            </Select>
          ) : null }
        </div>

      </div>
    );
  }
}
