import React from 'react';
import PropTypes from 'prop-types';

export default function CautionBar(props) {
  if (!props.isLocalEnv && (props.isMasq || props.isCanary)) {
    let message;
    if (props.isMasq && props.isCanary) {
      message = <span>Logged into client profile <b>{ props.accountName }</b> AND on <b>PROD DATABASE</b></span>;
    } else if (props.isMasq) {
      message = <span><b>CAUTION:</b> You are logged into client profile <b>{ props.accountName }</b></span>;
    } else if (props.isCanary) {
      message = <span><b>CAUTION:</b> You are on Canary Server using <b>PRODUCTION DATABASE</b></span>;
    }

    return (
      <div className="caution-bar">
        <div data-show="true" className="ant-alert ant-alert-error">
          <i className="anticon anticon-exclamation-circle ant-alert-icon" />
          <span className="ant-alert-message">
            { message }
          </span>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

CautionBar.propTypes = {
  isLocalEnv: PropTypes.bool.isRequired,
  isMasq: PropTypes.bool.isRequired,
  isCanary: PropTypes.bool.isRequired,
  accountName: PropTypes.string.isRequired,
};
