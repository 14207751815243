import PropTypes from 'prop-types';
import React from 'react';
import { percentOfNum, numberWithCommas } from '../../../utils.js';
import TableRow from '../../../common/TableRow.js.jsx';
import TableCell from '../../../common/TableCell.js.jsx';

export default class extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string.isRequired,
        totalChoices: PropTypes.number.isRequired,
        totalChoicesCompleted: PropTypes.number.isRequired,
        viewCount: PropTypes.number.isRequired,
        whiteListPresent: PropTypes.bool.isRequired,
    };

    static defaultProps = { className: "" };

    render() {
        return (
            <TableRow className={ this.props.className }>
              <TableCell>{ this.props.title }</TableCell>
              <TableCell>{ numberWithCommas(this.props.totalChoices) }</TableCell>
              <TableCell>{ percentOfNum(this.props.totalChoices, this.props.viewCount) }</TableCell>
              <TableCell>{ this.props.whiteListPresent ? "N/A" : numberWithCommas(this.props.totalChoicesCompleted) }</TableCell>
              <TableCell>{ this.props.whiteListPresent ? "N/A" : percentOfNum(this.props.totalChoicesCompleted, this.props.viewCount) }</TableCell>
            </TableRow>
        )
    }
}
