import React from 'react';
import { Row, Col, Tooltip as AntTooltip } from 'antd';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { ConsentPerformanceRecord } from '../../../records/consent_performance_records.js';
import { DATEPICKERS } from '../../../constants'

const Tooltip = ({text}) => (
  <AntTooltip placement="bottom" title={text} overlayClassName="cp-tooltip">
    <i className="fas fa-info-circle" />
  </AntTooltip>
);
Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
};


export default class ConsentAggregate extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(ConsentPerformanceRecord),
    loading: PropTypes.bool,
  };

  static defaultProps = {
    data: new ConsentPerformanceRecord(),
    loading: true,
  };

  percent = (num, tot) => {
    const percent = (num/tot)*100;
    if (percent < 1) return percent.toFixed(2)+'%';
    return Math.round(percent)+'%';
  };

  strPercent = (num) => {
    const percent = parseFloat(num.replace('%', ''));
    if (percent < 1) return percent.toFixed(2)+'%';
    return Math.round(percent)+'%';
  };

  sumPV = (data) => {
    const obj = {
      total_pv_consent_all: 0,
      total_pv_consent_any: 0,
      total_pv_consent_none: 0,
      total_pv_consent_some: 0,
      total_pv_rejected: 0,
      previouslyConsentedNone: 0,
    };
    for (let i = 0; i < data.length; i++) {
      obj.total_pv_consent_all += data[i].total_pv_consent_all;
      obj.total_pv_consent_any += data[i].total_pv_consent_any;
      obj.total_pv_consent_none += data[i].total_pv_consent_none;
      obj.total_pv_consent_some += data[i].total_pv_consent_some;
      obj.total_pv_rejected += data[i].total_pv_rejected;
      obj.previouslyConsentedNone += data[i].previouslyConsentedNone;
    }
    return Map(obj);
  }

  render() {

    let aggTable;

    if (this.props.data) {

      const aggData = this.props.data.aggregateData;

      const pvData = this.props.data.PVtableData;
      
      const pvAgg = this.sumPV(this.props.picker === DATEPICKERS.day ? pvData.toJS() : [pvData.toJS()]);

      const totalMessagedUsers = aggData.getIn(['total', 'totalMessagedUsers']);
      let totalMessagedUsersCol;
      let noActionRow;
      let consentedAllActionPercent;
      let consentedSomeActionPercent;
      let rejectAllActionPercent;
      let noActionPercent;

      if (totalMessagedUsers) {
        totalMessagedUsersCol = (
          <Col span={9}>
            <div className="stat-box">
              <h1>{ totalMessagedUsers.toLocaleString() }</h1>
              <div>
                <span>Messaged users</span>
                <Tooltip text="Total unique users who saw a message" />
              </div>
            </div>
          </Col>
        );

        noActionRow = (
          <Row className="agg-row">
            <Col span={12}>No action<Tooltip text="Total unique users who saw message but took no consent action" /></Col>
            <Col span={10}>{aggData.getIn(['total', 'noAction']).toLocaleString()}</Col>
            <Col span={2}>
              <span className="agg-count">
                {this.percent(aggData.getIn(['total', 'noAction']), totalMessagedUsers)}
              </span>
            </Col>
          </Row>
        );

        consentedAllActionPercent = (
          <Col span={2}>
            <span className="agg-count">
              { this.percent(aggData.getIn(['total', 'totalConsentedUsers']), totalMessagedUsers) }
            </span>
          </Col>
        );

        consentedSomeActionPercent = (
          <Col span={2}>
            <span className="agg-count">
              {this.percent(aggData.getIn(['total', 'consentSome']), totalMessagedUsers)}
            </span>
          </Col>
        );

        rejectAllActionPercent = (
          <Col span={2}>
            <span className="agg-count">
              {this.percent(aggData.getIn(['total', 'totalRejectedUsers']), totalMessagedUsers)}
            </span>
          </Col>
        );
      }

      aggTable = (
        <div className="card agg-card" style={{display: (this.props.loading ? 'none' : 'block')}}>
          <Row>
            <Col className="agg-col agg-col-left" span={8}>
              <h1 className="agg-title-left">Pageview stats*</h1>
              <Row className="agg-row">
                <Col span={12}>Total Pageviews <Tooltip text="Total pageviews" /></Col>
                <Col span={10}>{aggData.getIn(['total', 'total_pv_count']).toLocaleString()}</Col>
                <Col span={2} />
              </Row>
              <Row className="agg-row">
                <Col span={12}>No Consent Data <Tooltip text="Total pageviews where the user has no consent data" /></Col>
                <Col span={10}>{pvAgg.getIn(['total_pv_consent_none']).toLocaleString()}</Col>
                <Col span={2}>
                  <span className="agg-count">
                    {this.percent(pvAgg.getIn(['total_pv_consent_none']), aggData.getIn(['total', 'total_pv_count']))}
                  </span>
                </Col>
              </Row>
              <Row className="agg-row">
                <Col span={12}>Consented all <Tooltip text="Total pageviews where the user is consented to all vendors and purposes" /></Col>
                <Col span={10}>{pvAgg.getIn(['total_pv_consent_all']).toLocaleString()}</Col>
                <Col span={2}>
                  <span className="agg-count">
                    {this.percent(pvAgg.getIn(['total_pv_consent_all']), aggData.getIn(['total', 'total_pv_count']))}
                  </span>
                </Col>
              </Row>
              <Row className="agg-row">
                <Col span={12}>Consented some <Tooltip text="Total pageviews where the user is consented to some vendors and purposes" /></Col>
                <Col span={10}>{pvAgg.getIn(['total_pv_consent_some']).toLocaleString()}</Col>
                <Col span={2}>
                  <span className="agg-count">
                    {this.percent(pvAgg.getIn(['total_pv_consent_some']), aggData.getIn(['total', 'total_pv_count']))}
                  </span>
                </Col>
              </Row>
              <Row className="agg-row">
                <Col span={12}>Rejected All<Tooltip text="Total pageviews where the user has rejected all consent previously" /></Col>
                <Col span={10}>{pvAgg.getIn(['total_pv_rejected']).toLocaleString()}</Col>
                <Col span={2}>
                  <span className="agg-count">
                    {this.percent(pvAgg.getIn(['total_pv_rejected']), aggData.getIn(['total', 'total_pv_count']))}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={16} className="agg-col-right">
              <Row className="agg-row-top">
                <Col span={6}>
                  <h1>User stats*</h1>
                </Col>
                <Col span={8}>
                  <div className="stat-box">
                    <h1>{aggData.getIn(['total', 'totalUsers']).toLocaleString()}</h1>
                    <div>
                      <span>Total users</span>
                      <Tooltip text="Total unique users" />
                    </div>
                  </div>
                </Col>
                { totalMessagedUsersCol }
              </Row>
              <Row className="agg-row-bottom">
                <Col className="agg-col agg-col-left" span={12}>
                  <h4>Consent status</h4>
                  <Row className="agg-row">
                    <Col span={12}>No Consent Data <Tooltip text="Total unique users who have not taken any consent actions yet" /></Col>
                    <Col span={10}>{pvAgg.getIn(['previouslyConsentedNone']).toLocaleString()}</Col>
                    <Col span={2}>
                      <span className="agg-count">
                        {this.percent(pvAgg.getIn(['previouslyConsentedNone']), aggData.getIn(['total', 'totalUsers']))}
                      </span>
                    </Col>
                  </Row>
                  <Row className="agg-row">
                    <Col span={12}>Consented all <Tooltip text="Total unique users who have previously consented to all vendors and purposes" /></Col>
                    <Col span={10}>{aggData.getIn(['total', 'totalAlreadyConsented']).toLocaleString()}</Col>
                    <Col span={2}>
                      <span className="agg-count">
                        {this.percent(aggData.getIn(['total', 'totalAlreadyConsented']), aggData.getIn(['total', 'totalUsers']))}
                      </span>
                    </Col>
                  </Row>
                  <Row className="agg-row">
                    <Col span={12}>Consented some <Tooltip text="Total users unique who have previously consented to some vendors and purposes" /></Col>
                    <Col span={10}>{aggData.getIn(['total', 'totalIncompleteConsent']).toLocaleString()}</Col>
                    <Col span={2}>
                      <span className="agg-count">
                        {this.percent(aggData.getIn(['total', 'totalIncompleteConsent']), aggData.getIn(['total', 'totalUsers']))}
                      </span>
                    </Col>
                  </Row>
                  <Row className="agg-row">
                    <Col span={12}>Rejected All<Tooltip text="Total unique users who have previously rejected consent for all vendors or purposes" /></Col>
                    <Col span={10}>{aggData.getIn(['total', 'totalRejectedAll']).toLocaleString()}</Col>
                    <Col span={2}>
                      <span className="agg-count">
                        {this.percent(aggData.getIn(['total', 'totalRejectedAll']), aggData.getIn(['total', 'totalUsers']))}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col className="agg-col agg-col-right" span={12}>
                  <h4>Consent actions</h4>
                  <Row className="agg-row">
                    <Col span={12}>Consented all <Tooltip text="Total unique users who consented to all vendors and purposes in the message or privacy manager" /></Col>
                    <Col span={10}>{aggData.getIn(['total', 'totalConsentedUsers']).toLocaleString()}</Col>
                    { consentedAllActionPercent }
                  </Row>
                  <Row className="agg-row">
                    <Col span={12}>Consented some <Tooltip text="Total unique users who consented to some vendors and purposes either in the message or privacy manager" /></Col>
                    <Col span={10}>{aggData.getIn(['total', 'consentSome']).toLocaleString()}</Col>
                    { consentedSomeActionPercent }
                  </Row>
                  <Row className="agg-row">
                    <Col span={12}>Reject all <Tooltip text="Total unique users who rejected all vendors and purposes either in the message or privacy manager" /></Col>
                    <Col span={10}>{aggData.getIn(['total', 'totalRejectedUsers']).toLocaleString()}</Col>
                    { rejectAllActionPercent }
                  </Row>
                  { noActionRow }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div>{aggTable}</div>
    );
  }
}
