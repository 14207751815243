import { Record, List } from "immutable";
import moment from 'moment';

export const PropertiesReport = Record({
  key: null,
  date: "",
  siteid: null,
  propertyname: null,
  totalpageviews: 0,
  messagedusers: 0,
  fullconsent: 0,
  fullconsentper: "",
  partialconsent: 0,
  partialconsentper: "",
  rejectedall: 0,
  rejectedallper: "",
  aggData: List(),
  exportdata: List()
});

export const VendorsConsentReportRecord = Record({
  key: null,
  name: "",
  consentcount: 0,
  pvcount: 0,
  consentrate: 0,
  rejectionrate: 0
});

export const MessageConsentReportRecord = Record({
  key: null,
  date: "",
  totalmsgseen: 0,
  messageid: 0,
  totalactions: 0,
  actionconsentall: 0,
  actionrejectall: 0,
  actionconsentsome: 0,
  actionconsentany: 0,
  actionrejectany: 0
});
export const ChangeLogRecord = Record({
  key: null,
  sitegroupname: "",
  operationtype: "",
  entitytype: "",
  entitynames: "",
  properties: "",
  user: "",
  date: "",
  siteIds: List()
});

export const MessageTableReportRecord = Record({
  key: null,
  date: "",
  messageid: "",
  partitionname: "",
  totalmsgs: 0,
  consentall: 0,
  consentallper: "",
  consentsome: 0,
  consentsomeper: "",
  noconsent: 0,
  noconsentper: "",
  rejectall: 0,
  rejectallper: "",
  siteid: null,
  aggData: List()
});

export const VendorFilters = Record({
  startDate: moment(),
  endDate: moment(),
  siteId: "",
  geo: List([])
});
