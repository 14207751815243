import React from 'react';
import { COUNTRIES, US_STATES } from '../../../constants';
import { SelectDropdown, Select } from '../../../styleguide';
import { renderInfoTip } from '../../consent/us_privacy_regulation/helper';

const statesAndCountries = COUNTRIES.concat(US_STATES.map(({ code, ...res }) => { return {...res, code: 'STATE_' + code} })).toJS();
const geoFilter = statesAndCountries
  .map(geo => ({
    ...geo,
    region: typeof (geo.region) === 'string' ? [geo.region] : geo.region
  }))

function makeCodesArray(regionCode, codes) {
  const updatedCodes = [];
  updatedCodes.push(...geoFilter.filter(({ region }) => region.includes(regionCode)).map(({ code }) => code));
  updatedCodes.push(...(codes
    .filter(code => code !== regionCode)
    .filter(code => !geoFilter.find(geo => geo.code === code).region.includes(regionCode))
  ));

  return updatedCodes;
}

const GeoTargetingSelect = ({ geoTargeting, updateGeoTargeting, readOnly }) => {
  const { ruleBoolean, geoList } = geoTargeting;

  const updateCodes = (codes) => {
    let updatedCodes;
    if (codes.includes('EEA')) {
      updatedCodes = makeCodesArray('EEA', codes);
    } else {
      updatedCodes = codes
    };

    updateGeoTargeting({ ...geoTargeting, geoList: updatedCodes.sort((a, b) => a > b ? 1 : -1) });
  }

  const regionsOptions = [
    {
      label: "EEA",
      value: 'EEA',
    }
  ]
  const commonOptions = [
    {
      label: "USA",
      value: 'US',
    },
    {
      label: "California",
      value: 'STATE_CA',
    }
  ]
  const stateOptions = US_STATES.filter(({ name }) => name !== 'California').map((option, si) => ({label: option.name, value: `STATE_${option.code}`, key: si}))
  const countryOptions = COUNTRIES.filter(({ code }) => code !== 'US').map((option, ci) => ({label: option.name, value: option.code, key: ci}))
  const options = [
    {
      label: "Regions",
      options: regionsOptions,
    },
    {
      label: "Common",
      options: commonOptions,
    },
    {
      label: "States",
      options: stateOptions,
    },
    {
      label: "Countries",
      options: countryOptions,
    },
  ]
  return (
    <div className="geotargeting-container">
      <label className="title">
        Geolocation
        {renderInfoTip("Select regions below that you wish the configured hooks to apply to (IN) or be excluded from (NOT IN). If no regions are chosen the hook will apply regardless of region.")}
      </label>
      <div className="geotargeting-select-container">
        <Select
          className="geotargeting-bool"
          value={ruleBoolean}
          onChange={value => updateGeoTargeting({ ...geoTargeting, ruleBoolean: value })}
          disabled={readOnly}
        >
          <Select.Option value={true}>
            IN
          </Select.Option>
          <Select.Option value={false}>
            NOT IN
          </Select.Option>
        </Select>
        {/* <Select
          className="geotargeting-list"
          mode="multiple"
          value={geoList}
          onChange={updateCodes}
          filterOption
          optionFilterProp="searchValue"
          disabled={readOnly}
        >
          <Select.OptGroup label="Regions">
            <Select.Option value="EEA" searchValue="EEA" checked={true}>
              EEA
            </Select.Option>
            <Select.Option value="EU" searchValue="EU">
              EU
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Common">
            <Select.Option value="US" searchValue="united states of america">
              USA
            </Select.Option>
            <Select.Option value="STATE_CA" searchValue="california">
              California
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="States">
            {US_STATES
              .filter(({ name }) => name !== 'California')
              .map((option, si) => (
                <Select.Option key={si} value={'STATE_' +  option.code} searchValue={option.name}>
                  {option.name}
                </Select.Option>
              ))}
          </Select.OptGroup>
          <Select.OptGroup label="Countries">
            {COUNTRIES
              .filter(({ code }) => code !== 'US')
              .map((option, ci) => (
                <Select.Option key={ci} value={option.code} searchValue={option.name}>
                  {option.name}
                </Select.Option>
              ))}
          </Select.OptGroup>
        </Select> */}
        <SelectDropdown
          options={options}
          onChange={updateCodes}
          value={geoList}
          multiple
          groupOptions
          showSearch
          directUpdate
        />
      </div>
    </div>
  );
};

export default GeoTargetingSelect;