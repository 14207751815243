import PropTypes from 'prop-types';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Checkbox } from '../../../styleguide';
export default class AllIabCheckbox extends React.Component {
  static propTypes = {
    toggleAllIabAndUpdate: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  }

  render() {
    return (
      <div className='add-iab-checkbox-container'>
        <Tooltip
          title='Will add all the vendors from the iab global vendor list to your vendor list and keep them updated daily'
          placement="bottom"
        >
          <Checkbox
            disabled={ this.props.readOnly }
            onChange={ this.props.toggleAllIabAndUpdate }
            checked={ this.props.allIabAndAutoUpdate }
          >
            Add all IAB Vendors
          </Checkbox>
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    );
  }
}
