import React from "react";
import { Modal, Table } from "../../../styleguide";
import moment from 'moment';
import { Map, List } from "immutable";
import CustomPopover from "../../common/CustomPopover";
import { createCSVExportData } from "./helper";
import ExportPopoverContent from "../../ccpa/ccpa_reporting/ExportPopoverContent";
const columns = List([
  Map({
    title: "Site Group Name",
    dataIndex: "sitegroupname",
    className: "left-align",
    width: "10%",
    sorter: (a, b) => a.sitegroupname.localeCompare(b.sitegroupname)
  }),
  Map({
    title: "Operation Type",
    dataIndex: "operationtype",
    className: "left-align",
    width: "15%",
    sorter: (a, b) => a.operationtype.localeCompare(b.operationtype)
  }),
  Map({
    title: "Entity Type",
    dataIndex: "entitytype",
    className: "left-align",
    width: "15%",
    sorter: (a, b) => a.entitytype.localeCompare(b.entitytype)
  }),
  Map({
    title: "Entity Name",
    dataIndex: "entitynames",
    className: "left-align",
    width: "15%",
    sorter: (a, b) => a.entitynames.localeCompare(b.entitynames)
  }),
  Map({
    title: "Properties",
    dataIndex: "properties",
    className: "left-align",
    width: "20%",
    sorter: (a, b) => a.properties.localeCompare(b.properties)
  }),
  Map({
    title: "User",
    dataIndex: "user",
    className: "left-align",
    width: "10%",
    sorter: (a, b) => a.user.localeCompare(b.user)
  }),
  Map({
    title: () => <React.Fragment>Date<br/>(YYYY-MM-DD)</React.Fragment>,
    dataIndex: "date",
    className: "left-align",
    width: "15%",
    sorter: (a, b) =>
      moment(a.date, "YYYY-MM-DD").valueOf() -
      moment(b.date, "YYYY-MM-DD").valueOf(),
    defaultSortOrder: "descend"
  })
]);

export default class RecentChangesModal extends React.Component {
  render() {
    const changeLogHeaders = columns.map((item, i) => {
      return Map({ label: item.get("title"), key: item.get("dataIndex") });
    });
    const tableTitle = <CustomPopover
                          tooltipContent={
                            <ExportPopoverContent
                               filename="change_log"
                               exportData={createCSVExportData(changeLogHeaders, this.props.changeLogData)}
                               pdfTitle={"Recent Changes"}/>
                           }
                          style="export-popover"
                          icon="download"
                          iconColor="#108ee9"
                          title={<span className="popover-title">Export Type Selection</span>}
                      />;
    return (
      <Modal
        title={<span>Recent Changes</span>}
        visible={this.props.recentChangesModal}
        onCancel={this.props.handleCancel}
        footer={null}
        className="recentChangeModal"
      >
        <Table
          columns={columns.toJS()}
          title={() => tableTitle}
          dataSource={this.props.changeLogData.toJS()}
          className="consnet-tableCSS"
          pagination={{
            position: ['bottomCenter'],
            showTotal: (total) => `Total: ${total}`,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            locale: { items_per_page: " Records per page" }
          }}
          scroll={{ y: 450}}
          />
      </Modal>
    );
  }
}
