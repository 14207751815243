import PropTypes from 'prop-types';
import React from 'react';
import { CustomScenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import CustomButton from '../../common/CustomButton.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';

export default class ScenarioJson extends React.Component {

  static propTypes = {
    customScenario: PropTypes.instanceOf(CustomScenario).isRequired,
    editing: PropTypes.bool.isRequired,
    handleCancelEditing: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    updateScenarioEdited: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const newScenario = this.props.customScenario.set('scenario_json', event.target.value);
    this.props.updateScenarioEdited(newScenario);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {

    const json = this.props.customScenario.get('scenario_json');

    if (this.props.editing) {
      return (
        <div>
          <pre>
            <textarea className="scenario-json-textarea" ref="scenario-json" type="text" value={ json } onChange={ this.handleChange } />
          </pre>
          <div className="panel-buttons">
            <CustomButton type={ CustomButton.types.SUCCESS } size={ CustomButton.sizes.MEDIUM } onClick={ this.props.handleSave } >SAVE</CustomButton>
            <CustomButton type={ CustomButton.types.DANGER } size={ CustomButton.sizes.MEDIUM } onClick={ this.props.handleCancelEditing }>Cancel</CustomButton>
          </div>
        </div>
      )
    } else {
      let preClassName;
      let iconType;
      if (this.state.expanded) {
        preClassName = "expanded";
        iconType = CustomIcon.types.COLLAPSE;
      } else {
        iconType = CustomIcon.types.EXPAND;
      }
      return (
        <div className="scenario-code-container"><pre className={ preClassName }><code>{ JSON.stringify(JSON.parse(json), undefined, 2) }</code></pre><CustomIcon type={ iconType } onClick={ this.toggleExpanded } /></div>
      )
    }
  }
}
