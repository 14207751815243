import PropTypes from 'prop-types';
import React from 'react';
import { Tabs } from 'antd';
import { Modal, Button } from '../../../../../styleguide';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { flattenCategories, updateCategoryCookies, deleteCookies } from '../wizard/helper';
import { Vendor, VendorWrapper, CategorizationSchema } from '../../../../../records/vendor_list_records';
import ActionsTab from '../../../../common/ActionsTab';
import VendorDetailsTab from '../../VendorDetailsTab';
import CookieConfigTab from '../../CookieConfigTab';
import UrlMappingTab from '../../UrlMappingTab';

const { TabPane } = Tabs;

export default class VendorDetailModal extends React.Component {
  static propTypes = {
    vendorWrapper: PropTypes.instanceOf(VendorWrapper).isRequired,
    vendor: PropTypes.instanceOf(Vendor).isRequired,
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    handleApply: PropTypes.func.isRequired,
    deleteVendor: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    categories: ImmutablePropTypes.list,
  }

  state = {
    vendorWrapper: this.props.vendorWrapper,
    categories: this.props.categories,
  }

  updateVendorsActions = (actions, actionType) => {
    const vendorWrapper = this.state.vendorWrapper.set(actionType, actions);
    this.setState({ vendorWrapper });
  }

  handleCancelModalChanges = () => {
    this.setState({ vendorWrapper: this.props.vendorWrapper });
    this.props.closeModal();
  }

  handleApply = () => {
    const updatedWrapper = this.state.vendorWrapper
      .update('urlMappingRules', (rules) => {
        return rules
          .map(rule => rule.filter(statement => statement.urlPattern))
          .filter(rule => rule.length)
      })
      .update('consentActionExtension', (ext) => {
        return {
          ...ext,
          cookies: ext.cookies.filter(rmBadCookies)
        }
      })
      .update('rejectActionExtension', (ext) => {
        return {
          ...ext,
          cookies: ext.cookies.filter(rmBadCookies)
        }
      });

    function rmBadCookies(cookie) {
      return (
        cookie.key
        && cookie.value
        && (cookie.session || cookie.maxAge)
      );
    };

    this.props.handleApply(updatedWrapper, this.state.categories);
  }

  handleRemove = () => {
    this.props.deleteVendor(this.props.vendor);
  }

  renderFooter = () => 
      this.props.readOnly
      ? [
          <Button key="back" onClick={this.handleCancelModalChanges}>Close</Button>,
        ]
      : 
        [
          <Button key="back" onClick={this.handleCancelModalChanges}>Cancel</Button>,
          <Button className="purpose-delete" key="delete" onClick={this.handleRemove}>Remove</Button>,
          <Button key="submit" type="primary" onClick={this.handleApply}>Apply changes</Button>,
        ]

  saveCookiesToVl = (cookies, cookiesToDelete) => {
    const { categories, vendorWrapper } = this.state;
    const { defaultLegalBasis } = this.props;
    const flatCategories = flattenCategories(categories)
    const updatedCookies = cookies.map(c => ({
      ...c,
      cookieCategoryId: (flatCategories.find(({ name }) => name === c.category) || { id: '' }).id,
    }));
    const updatedVendorWrapper = vendorWrapper.set('cookies', new List(updatedCookies));
    const updatedCategories = cookiesToDelete?.length
      ? deleteCookies(cookiesToDelete, categories, vendorWrapper.vendorId)
      : updateCategoryCookies(updatedCookies, categories, this.props.vendor, defaultLegalBasis);
    
    this.setState({ vendorWrapper: updatedVendorWrapper, categories: updatedCategories });
  }

  updateVendorDescription = (description) => {
    const updatedWrapper = this.state.vendorWrapper.set('description', description);
    this.setState({ vendorWrapper: updatedWrapper });
  }

  updateActionExtension = (type, key) => (value) => {
    const updatedWrapper = this.state.vendorWrapper.update(`${type}ActionExtension`, (ext) => ({ ...ext, [key]: value }));
    this.setState({ vendorWrapper: updatedWrapper });
  }

  updateVendorAppleDataBroker = (value) => {
    const updatedWrapper = this.state.vendorWrapper.set('isApple', value);
    this.setState({ vendorWrapper: updatedWrapper });
  }

  updateCustomLabel = (labelNumber, value) => {
    const updatedWrapper = this.state.vendorWrapper.set(`isCustomVendorLabel${labelNumber}`, value);
    this.setState({ vendorWrapper: updatedWrapper });
  }

  updateExcludeFromVendorCount = (value) => {
    const updatedWrapper = this.state.vendorWrapper.set('excludeFromCount', value)
    this.setState({ vendorWrapper: updatedWrapper });
  }

  render() {
    const { vendorWrapper, categories } = this.state;
    const flatCategories = flattenCategories(categories);
    let cookiesWithCategories = new List([]);
    flatCategories.map(({ name, vendorCategorization }) => {
      vendorCategorization.forEach(({ cookies, vendorId }) => {
        if (vendorId === this.props.vendor.id) {
          cookies.forEach(cookie => {
            cookiesWithCategories = cookiesWithCategories.push({...cookie, category: name});
          });
        }
      });
    });
    
    if(!cookiesWithCategories.size) {
      flatCategories.map(({ name }) => {
        this.props.vendor.cookies.forEach((vendorCookie) => {
          if(vendorCookie) {
            cookiesWithCategories = cookiesWithCategories.push({...vendorCookie.toJS(), category: name });
          };
        });
      });
    };

    return (
      <Modal
        title={`Vendor Details - ${this.props.vendor.name}`}
        onOk={this.handleApply}
        visible={this.props.visible}
        onCancel={this.handleCancelModalChanges}
        className='edit-purpose'
        closable={false}
        destroyOnClose
        footer={this.renderFooter()}
        width={930}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Details" key="1">
            <VendorDetailsTab
              cookies={this.props.vendorWrapper.cookies}
              vendor={this.props.vendor}
              description={this.state.vendorWrapper.description}
              isApple={this.state.vendorWrapper.isApple || false}
              excludeFromCount={this.state.vendorWrapper.excludeFromCount || false}
              isCustomVendorLabel1={this.state.vendorWrapper.isCustomVendorLabel1 || false}
              isCustomVendorLabel2={this.state.vendorWrapper.isCustomVendorLabel2 || false}
              isCustomVendorLabel3={this.state.vendorWrapper.isCustomVendorLabel3 || false}
              globalVendors={this.props.globalVendors}
              updateVendorDescription={this.updateVendorDescription}
              updateVendorAppleDataBroker={this.updateVendorAppleDataBroker}
              updateCustomLabel={this.updateCustomLabel}
              updateExcludeFromVendorCount={this.updateExcludeFromVendorCount}
              isAppleMessagingEnabled={this.props.isAppleMessagingEnabled}
              readOnly={this.props.readOnly}
            />
          </TabPane>
          <TabPane tab="Consent Actions" key="2">
            <ActionsTab
              actions={this.state.vendorWrapper.consentActions}
              updateActions={(actions) => this.updateVendorsActions(actions, 'consentActions')}
              geoTargeting={vendorWrapper.consentActionExtension.geoTargeting}
              onStatusChangeOnly={vendorWrapper.consentActionExtension.onStatusChangeOnly}
              onNewUser={vendorWrapper.consentActionExtension.onNewUser}
              actionCookies={vendorWrapper.consentActionExtension.cookies}
              updateGeoTargeting={this.updateActionExtension('consent', 'geoTargeting')}
              updateOnStatusChangeOnly={this.updateActionExtension('consent', 'onStatusChangeOnly')}
              updateOnNewUser={this.updateActionExtension('consent', 'onNewUser')}
              updateActionCookies={this.updateActionExtension('consent', 'cookies')}
              readOnly={this.props.readOnly}
            />
          </TabPane>
          <TabPane tab="Reject Actions" key="3">
            <ActionsTab
              actions={this.state.vendorWrapper.rejectActions}
              updateActions={(actions) => this.updateVendorsActions(actions, 'rejectActions')}
              geoTargeting={this.state.vendorWrapper.rejectActionExtension.geoTargeting}
              onStatusChangeOnly={this.state.vendorWrapper.rejectActionExtension.onStatusChangeOnly}
              onNewUser={this.state.vendorWrapper.rejectActionExtension.onNewUser}
              actionCookies={this.state.vendorWrapper.rejectActionExtension.cookies}
              updateGeoTargeting={this.updateActionExtension('reject', 'geoTargeting')}
              updateOnStatusChangeOnly={this.updateActionExtension('reject', 'onStatusChangeOnly')}
              updateOnNewUser={this.updateActionExtension('reject', 'onNewUser')}
              updateActionCookies={this.updateActionExtension('reject', 'cookies')}
              readOnly={this.props.readOnly}
              isRejectActions
            />
          </TabPane>
          <TabPane tab="Cookies" key="4">
            <CookieConfigTab
              saveCookiesToVl={this.saveCookiesToVl}
              initialCookies={cookiesWithCategories.toJS()}
              cookieCategories={flattenCategories(this.props.categories).toJS()}
              vendorId={this.props.vendor.id}
              vendor={this.props.vendor}
              isNonIab={this.props.isNonIab}
              readOnly={this.props.readOnly}
            />
          </TabPane>
          <TabPane tab="URL Mappings" key="5">
            <UrlMappingTab
              patterns={this.state.vendorWrapper.urlMappingRules}
              updatePatterns={(patterns) => this.updateVendorsActions(patterns, 'urlMappingRules')}
              readOnly={this.props.readOnly}
            />
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}
