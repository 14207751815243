import 'whatwg-fetch';
import { List, OrderedSet, Map, fromJS } from 'immutable';
import _ from 'lodash';
import { 
  checkStatus, 
  isFetched, 
  addToFetched, 
} from '../helper';
import { CHOICE_TYPES, BACKEND_STATUS_TYPES, STATUS_TYPES as STATUS, FETCHED } from '../../constants';
import { Message, MetaData, SubType, MessageVariables } from '../../records/message_records.js';
import {
  Backdrop,
  Body,
  ContentData,
  CustomHTML,
  customHTMLCallback,
  Data,
  General,
  MessageConfigRecord,
  MsgContent,
  Options,
  Style,
  Title,
  UserData
} from "../../records/message_config_records.js";
import {
  AcceptAll,
  AltAdVideo,
  AltPay,
  ChoiceConfigRecord,
  ContinueWithAdblocker,
  ExecJS,
  ListOption,
  Login,
  PrivacyManager,
  PubCustom,
  Recovery,
  Redirect,
  RejectAll,
  Samba,
  Whitelist,
  Welect,
  ChoiceOptions,
  Dismiss
} from "../../records/choice_config_records.js";

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

// FONTGRABBER
export function phantomFontGrabber(url) {
  return Promise.resolve({});
  // TODO: look into
  // return fetch("/api/v1/dialogue/site_style_data_grabber" + "?url=" + url, {
  //   credentials: "include"
  // })
  //   .then(checkStatus)
  //   .then(resp => resp.json());
}

export function getMessageWithDetailAjax(siteId, messageId, isSiteGroup) {
  if (isSiteGroup) {
    return Promise.all([
      getMessageWithConfig(messageId),
      getChoiceConfig(siteId, messageId),
      getVariables(messageId)
    ]).then(respArr => {
      if (respArr[0] === FETCHED) return Promise.resolve(FETCHED);
      const message = respArr[0];
      const choiceConfig = respArr[1];
      const variables = respArr[2];
      return message
        .merge({ choice_config: choiceConfig })
        .merge({ variables });
    });
  } else {
    return Promise.all([
      getMessageWithConfig(messageId),
      getChoiceConfig(siteId, messageId)
    ]).then(respArr => {
      if (respArr[0] === FETCHED) return Promise.resolve(FETCHED);
      const message = respArr[0];
      const choiceConfig = respArr[1];
      return message.merge({ choice_config: choiceConfig });
    });
  }
}

function getMessageWithConfig(messageId) {
  const url = "/msg/get_message?message_id=" + messageId;

  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      addToFetched(url);
      const messageConfig = buildMessageConfig(JSON.parse(resp.data.message.message_json));
      const messageObject = resp.data.message;
      messageObject.message_config = messageConfig;
      return messageToRecord(messageObject);
    })
    .catch(err => {
      console.log(err);
    });
}

function getChoiceConfig(siteId, messageId) {
  const url = "/msg/get_choice_options?message_id=" + messageId + "&site_id=" + siteId;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      addToFetched(url);
      return buildChoiceConfig(resp.data.data);
    })
    .catch(err => {
      console.log(err);
    });
}

export function getVariables(messageId) {
  return fetch(permissionsSvsBase + "/site-group/message-variables?messageId=" + messageId, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return new MessageVariables(resp);
    })
    .catch(err => {
      console.log(err);
    });
}

export const fetchMessages = (status, siteId) => {
  const backendStatus = BACKEND_STATUS_TYPES[status];
  const url = "/msg/get_message_list?object_status=" + backendStatus + "&site_id=" + siteId;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      addToFetched(url);
      return OrderedSet(resp.data.message_list.map(message => messageToRecord(message, status)));
    });
};

export const getMessageListV2Ajax = (status = 1, siteId) => {
  const url = "/msg/v2/get_message_list?object_status=" + status + "&site_id=" + siteId;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      addToFetched(url);
      return resp.data.message_list;
    })
    .catch(() => console.log('Error loading v2 draft messages'));
};

export const getMessages = (status, siteId) => {
  const backendStatus = BACKEND_STATUS_TYPES[status];
  const url = "/msg/get_message_list?object_status=" + backendStatus + "&site_id=" + siteId;
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetchMessages(status, siteId);
  }
};

export const getAllMessagesAjax = (siteId, object_status = 1) => {
  const url = `/msg/get_all_message_list?object_status=${object_status}&site_id=${siteId}`;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      addToFetched(url);
      const messages = OrderedSet(resp.data.message_list.map(message => newMessageToRecord(message, 'draft')));
      return messages;
    });
};

export const getMessagesAfterImportAjax = siteId => {
  const url = "/msg/get_message_list?object_status=1&site_id=" + siteId;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return OrderedSet(resp.data.message_list.map(message => messageToRecord(message)));
    });
};

const postMessage = (url, message) => {
  return fetch(permissionsSvsBase + "/msg" + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: "POST",
    body: JSON.stringify(serializeMessage(message))
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      const respMessage = resp.data.message;
      respMessage.clientSideMessageId = message.clientSideMessageId;
      return messageToRecord(respMessage).merge({
        message_config: message.message_config,
        choice_config: message.choice_config.merge({ message_id: respMessage.id }),
      });
    });
};

export const createMessageAjax = message => {
  const url = "/create_message?site_id=" + message.site_id;
  return postMessage(url, message);
};

export const updateMessageAjax = message => {
  const url = "/update_message?site_id=" + message.site_id + "&message_id=" + message.id;
  return postMessage(url, message);
};

export const createSiteGroupMessageAjax = (accountId, siteGroupId, message) => {
  const body = new Map({
    accountId,
    msgBlob: serializeMessage(message),
    defaultVariables:
      (message.variables && message.variables.defaultVariables) || [],
    sitesVariables:
      (message.variables && message.variables.sitesVariables) || []
  });
  return fetch(
    `${permissionsSvsBase}/site-group/message?siteGroupId=${siteGroupId}`,
    {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      method: "POST",
      body: JSON.stringify(body)
    }
  )
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      resp.messageResponseObjs[0].clientSideMessageId =
        message.clientSideMessageId;

      return fromJS({
        messages: OrderedSet(
          resp.messageResponseObjs.map((m, i) => {
            if (i === 0) {
              return messageToRecord(m)
                .merge({
                  message_config: message.message_config,
                  choice_config: message.choice_config.merge({
                    message_id: m.id
                  })
                })
                .merge({ variables: message.variables });
            } else {
              return messageToRecord(m);
            }
          })
        ),
        sitesWithErrors: resp.sitesWithErrors
      });
    });
};

export const updateSiteGroupMessageAjax = (accountId, siteGroupId, message) => {
  const body = new Map({
    accountId,
    messageId: parseInt(message.id),
    msgBlob: serializeMessage(message),
    defaultVariables: message.variables && message.variables.defaultVariables,
    sitesVariables: message.variables && message.variables.sitesVariables
  });
  return fetch(
    `${permissionsSvsBase}/site-group/message?siteGroupId=${siteGroupId}`,
    {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      method: "PUT",
      body: JSON.stringify(body)
    }
  )
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return fromJS({
        messages: OrderedSet(
          resp.messageResponseObjs.map((m, i) => {
            if (i === 0) {
              return messageToRecord(m)
                .merge({
                  message_config: message.message_config,
                  choice_config: message.choice_config.merge({
                    message_id: m.id
                  })
                })
                .merge({ variables: message.variables });
            } else {
              return messageToRecord(m);
            }
          })
        ),
        sitesWithErrors: resp.sitesWithErrors
      });
    });
};

export function serializeMessage(message) {
  const messageConfig = message.message_config;
  const choiceConfig = message.choice_config;
  const dataToPost = {
    description: message.description,
    message_schema_version: 1,
    message_json: JSON.stringify(messageConfig),
    choice_json: JSON.stringify(getChoiceConfigOptionsForPOST(choiceConfig))
  };

  if (messageConfig.type === "redirect") {
    dataToPost.message_html = {
      message_html: "",
      veil_html: "",
      class_names: [],
      choice_class_names: [],
      ids: [],
      css: ""
    };
  } else {
    const serverSideConfigData = messageConfig.getServerSideHTML(choiceConfig)
      .messageConfigData;
    dataToPost.message_html = serverSideConfigData;
  }

  return dataToPost;
}

function getChoiceConfigOptionsForPOST(choiceConfig) {
  // conversion to map is used to regain ability to change keys and delete properties
  let options = choiceConfig.get("options").toMap();
  options = options
    .filter(option => {
      return option.get("enabled");
    })
    .mapEntries(([k, v]) => [CHOICE_TYPES[k], v.toMap().delete("enabled")]);

  let option_list = choiceConfig.get("option_list");
  option_list = option_list.map(list_option => {
    let option_data = list_option.get("option_data");
    option_data = option_data.toMap().delete("enabled");
    return list_option.set("option_data", option_data);
  });

  if (!option_list.some(option => option.choice_option_type === 15)) {
    let dismissOptionData = new Dismiss({
      button_text: "Dismiss"
    });

    dismissOptionData = dismissOptionData.toMap().delete("enabled");

    option_list = option_list.push(
      new ListOption({
        choice_option_type: 15,
        option_data: dismissOptionData
      })
    );
  }
  return choiceConfig.set("options", options).set("option_list", option_list);
}

export const deleteMessageAjax = (siteId, { id }) => {
  if (!id) throw Error("id missing for query");
  const url = "/msg/delete_message?message_id=" + id + "&site_id=" + siteId;
  return fetch(permissionsSvsBase + url, {
    credentials: "include",
    headers: { "X-CSRF-Token": CSRF_TOKEN },
    method: "DELETE"
  })
    .then(checkStatus)
    .then(() => id);
};

export const deleteSiteGroupMessageAjax = (
  siteGroupId,
  accountId,
  messageId
) => {
  const url = `${permissionsSvsBase}/site-group/message?siteGroupId=${siteGroupId}&messageId=${messageId}`;
  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: "DELETE",
    body: JSON.stringify({ accountId })
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return fromJS({
        messageIds: resp.deletedMessages.map(m => m.messageId),
        sitesWithErrors: resp.sitesWithErrors
      });
    });
};

export function messageToRecord(message, status) {
  let msg_metadata = new MetaData(message.msg_metadata).set(
    "data",
    new SubType(message.msg_metadata.data)
  );
  if (!status) {
    status = message.status;
    if (typeof status !== "string") {
      status = _.invert(BACKEND_STATUS_TYPES)[message.status];
    }
  }
  status = status || _.invert(BACKEND_STATUS_TYPES)[message.status];
  let cleanStatus = status;
  if (status.includes("Old")) {
    cleanStatus = status.slice(0, -3);
  } else {
    if (status !== STATUS.DRAFT) {
      message.live = true;
    }
  }
  message.status = cleanStatus;
  return new Message(
    _.omit(message, ["deleted_at", "updated_at", "message_json"])
  ).set("msg_metadata", msg_metadata);
}

export function newMessageToRecord(message, status) {
  if (!status) {
    status = message.status;
    if (typeof status !== "string") {
      status = _.invert(BACKEND_STATUS_TYPES)[message.status];
    }
  }
  status = status || _.invert(BACKEND_STATUS_TYPES)[message.status];
  let cleanStatus = status;
  if (status.includes("Old")) {
    cleanStatus = status.slice(0, -3);
  } else {
    if (status !== STATUS.DRAFT) {
      message.live = true;
    }
  }
  message.status = cleanStatus;
  return new Message(
    _.omit(message, ["deleted_at", "updated_at"])
  );
}

export function newScenarioMsgToRecord(message, status) {
  if (!status) {
    status = message.status;
    if (typeof status !== "string") {
      status = _.invert(BACKEND_STATUS_TYPES)[message.status];
    }
  }
  status = status || _.invert(BACKEND_STATUS_TYPES)[message.status];
  let cleanStatus = status;
  if (status.includes("Old")) {
    cleanStatus = status.slice(0, -3);
  } else {
    if (status !== STATUS.DRAFT) {
      message.live = true;
    }
  }
  message.status = cleanStatus;
  return new Message(_.omit(message, ["deleted_at", "updated_at"]));
}

function buildMessageConfig(mc) {
  const optionsData = {
    destinationSelector: mc.data.options.destinationSelector,
    removePageElementsSelector: mc.data.options.removePageElementsSelector,
    destination: mc.data.options.destination,
    delayType: mc.data.options.delayType,
    delayAfterSeconds: mc.data.options.delayAfterSeconds,
    delayAfterScroll: mc.data.options.delayAfterScroll,
    detectExitIntent: mc.data.options.detectExitIntent
  };

  if (mc.data.options.custom) {
    optionsData.custom = new CustomHTML(mc.data.options.custom);
  }

  const options = new Options(optionsData);

  const messageConfig = new MessageConfigRecord({
    version: mc.version,
    type: mc.type,
    data: new Data({
      subType: mc.data.subType,
      msgContent: new MsgContent({
        contentType: mc.data.msgContent.contentType,
        contentData: new ContentData({
          title: mc.data.msgContent.contentData.title,
          body: mc.data.msgContent.contentData.body
        })
      }),
      theme: mc.data.theme,
      style: new Style({
        includeDefaultStyles: mc.data.style.includeDefaultStyles,
        template: mc.data.style.template,
        definedStyles: mc.data.style.definedStyles,
        title: new Title({
          fontSize: mc.data.style.title.fontSize,
          color: mc.data.style.title.color,
          backgroundColor: mc.data.style.title.backgroundColor
        }),
        body: new Body({
          fontSize: mc.data.style.body.fontSize,
          color: mc.data.style.body.color,
          backgroundColor: mc.data.style.body.backgroundColor
        }),
        backdrop: new Backdrop({
          backgroundColor: mc.data.style.backdrop.backgroundColor
        }),
        general: new General({
          backgroundColor: mc.data.style.general.backgroundColor,
          border: mc.data.style.general.border,
          closeButtonColor: mc.data.style.general.closeButtonColor,
          zIndex: mc.data.style.general.zIndex || 10000
        }),
        customStyles: mc.data.style.customStyles
      }),
      options: options,
      userData: new UserData({
        customScreenshotUrl: mc.data.userData.customScreenshotUrl
      })
    })
  });

  verifyConfigValue(messageConfig);

  return messageConfig;
}

function buildChoiceConfig(cc) {
  let options = new ChoiceOptions();
  let option_list = new List([]);

  const whitelist = cc.options[CHOICE_TYPES.whitelist];
  if (whitelist) {
    options = options.set(
      "whitelist",
      new Whitelist({
        button_text: whitelist.button_text,
        whitelist_url: whitelist.whitelist_url,
        enabled: true
      })
    );
  }

  const recovery = cc.options[CHOICE_TYPES.recovery];
  if (recovery) {
    options = options.set(
      "recovery",
      new Recovery({
        button_text: recovery.button_text,
        enabled: true
      })
    );
  }

  const altAdVideo = cc.options[CHOICE_TYPES.altAdVideo];
  if (altAdVideo) {
    options = options.set(
      "altAdVideo",
      new AltAdVideo({
        button_text: altAdVideo.button_text,
        adframe_url: altAdVideo.adframe_url,
        adcall_url: altAdVideo.adcall_url,
        comp_period_sec: altAdVideo.comp_period_sec,
        language: altAdVideo.language,
        v: altAdVideo.v,
        enabled: true
      })
    );
  }

  const pubCustom = cc.options[CHOICE_TYPES.pubCustom];
  if (pubCustom) {
    options = options.set(
      "pubCustom",
      new PubCustom({
        button_text: pubCustom.button_text,
        iframe_url: pubCustom.iframe_url,
        comp_period_sec: pubCustom.comp_period_sec,
        enabled: true
      })
    );
  }

  const redirect = cc.options[CHOICE_TYPES.redirect];
  if (redirect) {
    options = options.set(
      "redirect",
      new Redirect({
        button_text: redirect.button_text,
        redirect_url: redirect.redirect_url,
        enabled: true
      })
    );
  }

  const continueWithAdblocker = cc.options[CHOICE_TYPES.continueWithAdblocker];
  if (continueWithAdblocker) {
    options = options.set(
      "continueWithAdblocker",
      new ContinueWithAdblocker({
        button_text: continueWithAdblocker.button_text,
        enabled: true
      })
    );
  }

  const altPay = cc.options[CHOICE_TYPES.altPay];
  if (altPay) {
    options = options.set(
      "altPay",
      new AltPay({
        button_text: altPay.button_text,
        altpay_iframe_url: altPay.altpay_iframe_url || "",
        offer_ids: List(altPay.offer_ids),
        enabled: true
      })
    );
  }

  const execJS = cc.options[CHOICE_TYPES.execJS];
  if (execJS) {
    options = options.set(
      "execJS",
      new ExecJS({
        button_text: execJS.button_text,
        js_fn_name: execJS.js_fn_name,
        comp_period_sec: execJS.comp_period_sec,
        enabled: true
      })
    );
  }

  const login = cc.options[CHOICE_TYPES.login];
  if (login) {
    options = options.set(
      "login",
      new Login({
        button_text: login.button_text,
        enabled: true
      })
    );
  }

  const welect = cc.options[CHOICE_TYPES.welect];
  if (welect) {
    options = options.set(
      "welect",
      new Welect({
        button_text: welect.button_text,
        api_key: welect.api_key,
        product_id: welect.product_id,
        layout: welect.layout || "",
        comp_period_sec: welect.comp_period_sec,
        enabled: true
      })
    );
  }

  const samba = cc.options[CHOICE_TYPES.samba];
  if (samba) {
    options = options.set(
      "samba",
      new Samba({
        button_text: samba.button_text,
        comp_period_sec: samba.comp_period_sec,
        enabled: true
      })
    );
  }

  const option_list_array = cc.option_list.map(option => {
    let record;
    const data = option.option_data;
    switch (option.choice_option_type) {
      case CHOICE_TYPES.whitelist:
        record = new Whitelist({
          button_text: data.button_text,
          whitelist_url: data.whitelist_url,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.recovery:
        record = new Recovery({
          button_text: data.button_text,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.altAdVideo:
        record = new AltAdVideo({
          button_text: data.button_text,
          adframe_url: data.adframe_url,
          adcall_url: data.adcall_url,
          comp_period_sec: data.comp_period_sec,
          language: data.language,
          v: data.v,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.pubCustom:
        record = new PubCustom({
          button_text: data.button_text,
          iframe_url: data.iframe_url,
          comp_period_sec: data.comp_period_sec,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.redirect:
        record = new Redirect({
          button_text: data.button_text,
          redirect_url: data.redirect_url,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.continueWithAdblocker:
        record = new ContinueWithAdblocker({
          button_text: data.button_text,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.altPay:
        record = new AltPay({
          button_text: data.button_text,
          altpay_iframe_url: data.altpay_iframe_url || "",
          offer_ids: List(data.offer_ids),
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.execJS:
        record = new ExecJS({
          button_text: data.button_text,
          js_fn_name: data.js_fn_name,
          comp_period_sec: data.comp_period_sec,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.welect:
        record = new Welect({
          button_text: data.button_text,
          api_key: data.api_key,
          product_id: data.product_id,
          layout: data.layout || "",
          comp_period_sec: data.comp_period_sec,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.acceptAll:
        record = new AcceptAll({
          button_text: data.button_text,
          consent_language: data.consent_language || "EN",
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.rejectAll:
        record = new RejectAll({
          button_text: data.button_text,
          consent_language: data.consent_language || "EN",
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.privacyManager:
        record = new PrivacyManager({
          button_text: data.button_text,
          privacy_manager_iframe_url: data.privacy_manager_iframe_url,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.samba:
        record = new Samba({
          button_text: data.button_text,
          comp_period_sec: data.comp_period_sec,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.samba:
        record = new Samba({
          button_text: data.button_text,
          comp_period_sec: data.comp_period_sec,
          enabled: true
        });
        option.option_data = record;
        break;
      case CHOICE_TYPES.consentDismiss:
        record = new Dismiss({
          button_text: "Dismiss",
          enabled: true
        });
        option.option_data = record;
        break;
    }

    return new ListOption({
      choice_option_type: option.choice_option_type,
      option_data: option.option_data
    });
  });

  option_list = List(option_list_array);

  const choiceConfig = ChoiceConfigRecord({
    message_id: cc.message_id,
    render_style: cc.render_style,
    options,
    option_list
  });

  verifyConfigValue(choiceConfig);

  return choiceConfig;
}

function verifyType(key, val) {
  return verificationMap[key]
    ? verificationMap[key](val)
    : typeof val === "string";
}

function verifyConfigValue(obj) {
  obj.forEach((val, key) => {
    if (key === "offer_ids") {
      verifyType(key, val);
      return;
    }

    if (obj.get(key) === null) {
      obj.set(key, "");
    }

    var val = obj.get(key);

    if (key !== "userData" && typeof val === "object") {
      verifyConfigValue(val);
    } else {
      if (!verifyType(key, val)) {
        throw new Error(`"${key}" has invalid value of ${val}`);
      }
    }
  });
}

const verificationMap = {
  version: val => typeof val === "number",
  userData: val => typeof val === "object",
  includeDefaultStyles: val =>
    typeof val === "undefined" || typeof val === "boolean",
  template: val => typeof val === "undefined" || typeof val === "string",
  definedStyles: val => typeof val === "boolean",
  message_id: val => typeof val === "number",
  render_style: val => typeof val === "number",
  comp_period_sec: val => typeof val === "number",
  destinationSelector: val =>
    typeof val === "undefined" || typeof val === "string",
  destination: val => typeof val === "undefined" || typeof val === "string",
  removePageElementsSelector: val =>
    typeof val === "undefined" || typeof val === "string",
  v: val => typeof val === "number",
  enabled: val => typeof val === "boolean",
  hideDismiss: val => typeof val === "boolean",
  customStyles: val => typeof val === "undefined" || typeof val === "string",
  zIndex: val => typeof val === "number",
  offer_ids: val => typeof val === "object",
  choice_option_type: val => typeof val === "number",
  delayType: val => typeof val === "undefined" || typeof val === "string",
  delayAfterSeconds: val =>
    typeof val === "undefined" || typeof val === "number",
  delayAfterScroll: val =>
    typeof val === "undefined" || typeof val === "number",
  detectExitIntent: val =>
    typeof val === "undefined" || typeof val === "boolean",
  custom: val => typeof val === "undefined" || typeof val === "object",
  callback: val => typeof val === "undefined" || typeof val === "object",
  invokeJS: val => typeof val === "undefined" || typeof val === "boolean"
};
