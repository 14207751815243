import React from 'react';
import PropTypes from 'prop-types';
import {
  Table as AntTable,
  Input,
} from 'antd';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { VendorList, Vendor, Purpose, VendorWrapper } from '../../../../../records/vendor_list_records';
import TagSelect from './TagSelect';
import TableFooter from './TableFooter';
import EditPurposeModal from './EditPurposeModal';
import VendorDetailModal from './VendorDetailModal';

const Search = Input.Search;

export default class Table extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    readOnly: PropTypes.bool.isRequired,
    vendorScanningEnabled: PropTypes.bool.isRequired,
    globalVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)).isRequired,

    updateVendorList: PropTypes.func.isRequired,
    toggleAddVendors: PropTypes.func.isRequired,
    toggleAllIabAndUpdate: PropTypes.func.isRequired,
    toggleAutoScan: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
    updateCustomVendor: PropTypes.func.isRequired,
  };

  state = {
    search: '',
    editingPurpose: null,
    editPurposeIndex: -1,
    editVendor: null,
    editVendorId: null,
  };

  intersection = (arr1, arr2) => {
    const res = arr1.filter(e => arr2.includes(e));
    return res.size > 0;
  };

  editPurpose = (editPurpose, editPurposeIndex) => {
    this.setState({ editPurpose, editPurposeIndex });
  };

  savePurposeModal = (purposeIndex, purpose) => {
    this.props.updateVendorList(this.props.vendorList.setIn(['categories', purposeIndex], purpose));
    this.editPurpose(null, -1);
  };

  handleApplyVendorDetailModal = (vendorsActions) => {
    let vendorsWrappers = this.props.vendorList.vendorsWrappers;
    if (vendorsWrappers.find(vendor => vendor.vendorId === vendorsActions.vendorId)) {
      const index = vendorsWrappers.findIndex(vendor => vendor.vendorId === vendorsActions.vendorId);
      vendorsWrappers = vendorsWrappers.set(index, vendorsActions);
    } else {
      vendorsWrappers = vendorsWrappers.push(vendorsActions);
    }
    this.props.updateVendorList(this.props.vendorList.set('vendorsWrappers', vendorsWrappers));
    this.editVendor(null, -1);
  };

  deletePurpose = (purposeIndex) => {
    this.props.updateVendorList(this.props.vendorList.update('categories', c => c.delete(purposeIndex)));
    this.editPurpose(null, -1);
  };

  createPurpose = () => {
    this.props.updateVendorList(this.props.vendorList.update('categories', c => c.push(new Purpose({ name: 'purpose' + (c.size + 1) }))));
  };

  editVendor = (editVendor, editVendorId) => {
    this.setState({ editVendor, editVendorId });
  };

  deleteVendor = (vendor) => {
    const updatedCategories = this.props.vendorList
      .get('categories')
      .map(category => {
        return category.update('vendorIds', vendorIds => vendorIds.filterNot(id => id === vendor.id));
      });

    const updatedVendorList = this.props.vendorList
      .update('vendors', vendors => vendors.filterNot(v => v.id === vendor.id))
      .set('categories', updatedCategories);

    this.props.updateVendorList(updatedVendorList);
    this.editVendor(null, null);
  };

  updateSearch = (e) => this.setState({ search: e.target.value });

  render() {

    const vList = this.props.vendorList.vendors;
    const purposes = this.props.vendorList.categories;

    // Build out the table columns
    let columns = List([Map({
      title: (
        <Search
          placeholder="Search vendors"
          onChange={this.updateSearch}
          className="vendor-filter"
          style={{ width: 160 }}
        />
      ),
      dataIndex: 'vendor',
      key: 'name',
      width: '200px',
      fixed: 'left',
    })]);
    columns = columns.concat(purposes.map((purpose, ndx) => {
      return (Map({
        title: (
          <span className="th-container">
            <i className="fas fa-edit" />
            <div onClick={() => this.editPurpose(purpose, ndx)} className="purpose-th" role="presentation">
              {purpose.name}
            </div>
          </span>
        ),
        width: '180px',
        dataIndex: purpose.name,
        render: (p, v) => {
          const iabPurpose = purpose.iabPurposeRefs.size ? true : false;
          if (p === 'consent' && v.data.iab) {
            return <span className="li-tag li-consent">User Consent</span>;
          } else if (p === 'legitimate' && v.data.iab) {
            return <span className="li-tag li-interest">Legitimate Interest</span>;
          } else if (!v.data.iab || !iabPurpose) {
            return (
              <TagSelect
                updateCustomVendor={this.props.updateCustomVendor}
                vendorId={v.key}
                purpose={purpose}
                purposeIndex={ndx}
                readOnly={this.props.readOnly}
              />
            );
          } else {
            return <span className="li-tag li-applicable">Not Applicable</span>;
          }
        },
      }));
    }));

    // Build out the table's row data using vendors
    let dataSource = vList.map((v) => {

      const vendorTitle = (
        <span className="td-container">
          <i className="fas fa-edit td-icon" />
          <div onClick={() => this.editVendor(v, v.id)} className="vendor-td" role="presentation">
            {v.iab ? <span><i className="fas fa-circle" />{v.name}</span> : v.name}
          </div>
        </span>
      );

      let data = Map({
        key: v.id,
        vendor: vendorTitle,
        name: v.name,
        data: v,
      });
      purposes.map((p) => {
        if (this.intersection(p.iabPurposeRefs, v.purposes)) {
          data = data.set(p.name, 'consent');
        } else if (this.intersection(p.iabPurposeRefs, v.legIntPurposes)) {
          data = data.set(p.name, 'legitimate');
        } else {
          data = data.set(p.name, '');
        }
      });
      return data;
    });

    // Filter vendor rows based on search
    dataSource = dataSource.filter((v) => {
      if (!this.state.search) return true;
      return v.get('name').toUpperCase().indexOf(this.state.search.toUpperCase()) !== -1;
    });

    const footer = (
      <TableFooter
        toggleAllIabAndUpdate={this.props.toggleAllIabAndUpdate}
        allIabAndAutoUpdate={this.props.allIabAndAutoUpdate}
        vendorList={this.props.vendorList}
        toggleAddVendors={this.props.toggleAddVendors}
        createPurpose={this.createPurpose}
        vendorScanningEnabled={this.props.vendorScanningEnabled}
        toggleAutoScan={this.props.toggleAutoScan}
        readOnly={this.props.readOnly}
      />
    );

    let editPurposeModal;
    if (this.state.editPurpose) {
      editPurposeModal = (
        <EditPurposeModal
          iabPurposes={this.props.iabPurposes}
          purpose={this.state.editPurpose}
          purposeIndex={this.state.editPurposeIndex}
          deletePurpose={this.deletePurpose}
          closeModal={() => this.editPurpose(null, -1)}
          handleApply={(purpose) => this.savePurposeModal(this.state.editPurposeIndex, purpose)}
          allListVendors={this.props.vendorList.vendors}
          globalVendors={this.props.globalVendors}
          readOnly={this.props.readOnly}
          visible
        />
      );
    }

    let editVendorModal;
    if (this.state.editVendor) {
      let vendorsWrappers = this.props.vendorList.vendorsWrappers;
      if (!vendorsWrappers.find(vendor => vendor.vendorId === this.state.editVendor.id)) {
        vendorsWrappers = List([new VendorWrapper({ vendorId: this.state.editVendor.id })]);
      }
      editVendorModal = (
        <VendorDetailModal
          vendorWrapper={vendorsWrappers.find(vendor => vendor.vendorId === this.state.editVendor.id)}
          vendor={this.state.editVendor}
          deleteVendor={this.deleteVendor}
          closeModal={() => this.editVendor(null, null)}
          handleApply={this.handleApplyVendorDetailModal}
          readOnly={this.props.readOnly}
          visible
        />
      );
    }

    return (
      <div className="vendor-edit-table">
        <AntTable
          bordered={false}
          dataSource={dataSource.toJS()}
          columns={columns.toJS()}
          footer={() => footer}
          scroll={{ x: 1160 }}
        />
        {editPurposeModal}
        {editVendorModal}
      </div>
    );
  }
}
