import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getGoldenSetsAjax,
  getGoldenSetAjax,
  createGoldenSetAjax,
  updateGoldenSetAjax,
  deleteGoldenSetAjax,
  getRulePepformanceDataAjax,
  changeGoldenScoresAjax,
} from '../api/consent_quality/golden_set';

export const getRulePepformanceData = actionCreator(
  getRulePepformanceDataAjax,
  ruleRepformanceDataPending,
  ruleRepformanceDataLoaded,
  ruleRepformanceDataError,
  ruleRepformanceDataCached,
);

export const getGoldenSets = actionCreator(
  getGoldenSetsAjax,
  goldenSetsPending,
  goldenSetsLoaded,
  goldenSetsError,
  goldenSetsCached,
);

export const getGoldenSet = actionCreator(
  getGoldenSetAjax,
  goldenSetsPending,
  goldenSetLoaded,
  goldenSetsError,
  goldenSetsCached,
);

export const createGoldenSet = actionCreator(
  createGoldenSetAjax,
  goldenSetsCreaateOrUpdatePending,
  goldenSetCreated,
  goldenSetsError,
);

export const updateGoldenSet = actionCreator(
  updateGoldenSetAjax,
  goldenSetsCreaateOrUpdatePending,
  goldenSetUpdated,
  goldenSetsError,
);

export const deleteGoldenSet = actionCreator(
  deleteGoldenSetAjax,
  goldenSetsPending,
  goldenSetDeleted,
  goldenSetsError,
);

export const changeGoldenScores = actionCreator(
  changeGoldenScoresAjax,
  goldenSetsPending,
  goldenSetUpdated,
  goldenSetsError,
);

function goldenSetsPending() {
  return {
    type: types.GOLDEN_SETS_PENDING,
  };
}

function goldenSetsCreaateOrUpdatePending() {
  return {
    type: types.GOLDEN_SET_CREATE_OR_UPDATE_PENDING,
  };
}

function goldenSetsLoaded(goldenSets) {
  return {
    type: types.GOLDEN_SETS_LOADED,
    goldenSets,
  };
}

function goldenSetLoaded(goldenSet) {
  return {
    type: types.GOLDEN_SET_LOADED,
    goldenSet,
  };
}

function goldenSetsError(error) {
  return {
    type: types.GOLDEN_SETS_ERROR,
    error,
  };
}

function goldenSetCreated(goldenSet) {
  return {
    type: types.GOLDEN_SET_CREATED,
    goldenSet,
  };
}

function goldenSetUpdated(goldenSet) {
  return {
    type: types.GOLDEN_SET_UPDATED,
    goldenSet,
  };
}

function goldenSetDeleted(id) {
  return {
    type: types.GOLDEN_SET_DELETED,
    id,
  };
}

function goldenSetsCached() {
  return {
    type: types.GOLDEN_SETS_CACHED,
  };
}

function ruleRepformanceDataPending() {
  return {
    type: types.RULE_PERFORMANCE_DATA_PENDING,
  };
}

function ruleRepformanceDataLoaded(data) {
  return {
    type: types.RULE_PERFORMANCE_DATA_LOADED,
    data,
  };
}

function ruleRepformanceDataError(error) {
  return {
    type: types.RULE_PERFORMANCE_DATA_ERROR,
    error,
  };
}

function ruleRepformanceDataCached() {
  return {
    type: types.RULE_PERFORMANCE_DATA_CACHED,
  };
}