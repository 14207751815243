import React, { useEffect, useReducer, useState } from 'react'
import { browserHistory } from 'react-router';
import { useSelector } from 'react-redux'
import { nativeOtt } from 'message-preact'
import _ from 'lodash';
import Modal from 'antd/lib/modal/Modal'
import { Layout, Popover } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import {
  Infotip,
  Input,
  Button,
  Tooltip
} from '../../../styleguide';

import PrivacyComplianceModal from '../builder/Builder/PrivacyComplianceModal'
import SupportedLanguagesModal from '../builder/Builder/Sidebar/SupportedLanguagesModal'
import ViewNavigation from '../builder/Builder/ViewNavigation'
import Sidebar from '../builder/Builder/Sidebar/Sidebar'
import Templates from '../builder/Builder/Templates/Templates';

import { checkSupportedLanguages, replaceWithVariables } from '../../../util'
import { NOTICE_URL, privacyManagerComplianceList, categoryMap, subcategoryMap } from '../../../constants'
import CustomIcon from '../../common/CustomIcon.js';
import { CurrentMessageViewContext, CurrentUserContext } from '../../contexts'
import { DefaultLanguageContext } from '../contexts'
import { MessageVariables } from '../../../records/message_records'
import reducer from './reducer'

const { Content } = Layout
const { starter, constants, settingKeys } = nativeOtt

const conditionalButtonSettings = [
  'showPrivacyPolicyBtn',
  'showCustomBtn',
  'showCategoriesBtn',
  'showVendorsBtn',
  'showRejectAllBtn',
  'showSaveAndExitBtn',
  'showAcceptAllBtn'
]

const NativeOttBuilder = ({
  messageToEdit,
  isPreview,
  siteId,
  sitesUnderSg,
  closeBuilder,
  accountId,
  saveMessage,
  saveTemplate,
  isTemplate,
  messageCategory,
  createTemplate,
  deleteTemplate,
  templates,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    root: messageToEdit
      ? JSON.parse(isTemplate ? messageToEdit.template_json : messageToEdit.message_json)
      : starter,
    message: messageToEdit
      ? JSON.parse(isTemplate ? messageToEdit.template_json : messageToEdit.message_json).children
      : cleanButtons(starter.children),
    messageVariables: messageToEdit ? messageToEdit.variables : new MessageVariables,
    currentComponentPath: [0],
    selectedView: constants.HOME,
    activeKey: 'global-settings',
    supportedLanguagesMissing: [],
    privacyComplianceStatus: (messageToEdit && !isTemplate)
      ? JSON.parse(messageToEdit.message_json).settings.compliance_status
      : false,
    privacyComplianceMessages: (messageToEdit && !isTemplate) ? JSON.parse(messageToEdit.compliance_json) : [],
    previousValue: null,
    templateParams: { name: '', isSystemTemplate: false, isDraft: false },
    supportedLanguagesModalVisible: false,
    showCompleteCheckPrivacyModal: false,
    chooseTemplateTypeModalVisible: false,
  })
  const {
    root,
    message,
    supportedLanguagesMissing,
    supportedLanguagesModalVisible,
    privacyComplianceStatus,
    privacyComplianceMessages,
    showCompleteCheckPrivacyModal,
    previousValue,
    messageVariables,
    selectedView,
    currentComponentPath,
    activeKey,
    templateParams,
    chooseTemplateTypeModalVisible,
  } = state

  const currentUser = useSelector((state) => state.accountState.getIn(['userDetails', 'value']));
  const [selectTemplates, setSelectTemplates] = useState(messageToEdit ? false : true);
  const closeTemplates = () => {setSelectTemplates(false)};

  function cleanButtons(message) {
    const clonedMessage = _.cloneDeep(message)
    conditionalButtonSettings.forEach((setting) => {
      if (starter.settings[setting] === false) {
        const buttonIndex = clonedMessage
          .find(({ id }) => id === constants.HOME)
          .children
          .findIndex(({ id }) => id === getKey(setting))

        clonedMessage
          .find(({ id }) => id === constants.HOME)
          .children
          .splice(buttonIndex, 1)
      }
    })

    return clonedMessage
  }

  function getKey(setting) {
    switch (setting) {
      case 'showCustomBtn':
        return 'NavCustomButton'
      case 'showPrivacyPolicyBtn':
        return 'NavPrivacyPolicyButton'
      case 'showCategoriesBtn':
        return 'NavCategoriesButton'
      case 'showVendorsBtn':
        return 'NavVendorsButton'
      case 'showRejectAllBtn':
        return 'RejectAllButton'
      case 'showSaveAndExitBtn':
        return 'SaveAndExitButton'
      case 'showAcceptAllBtn':
        return 'AcceptAllButton'
      default:
        throw Error(`Bad settings key: ${setting}`)
    }
  }

  useEffect(() => {
    window.addEventListener('message', applyListener, false)
    return () => window.removeEventListener('message', applyListener)
  }, [])

  useEffect(() => {
    updateIframe();
  }, [message, selectedView, root])

  useEffect(() => {
    if (messageToEdit) {
      const vendorDetailsView = message.find(({ id }) => id === 'VendorDetailsView')
      const messageClone = _.cloneDeep(message)

      const inject = (id) => {
        if (!vendorDetailsView.children.find((child) => child.id === id)) {
          const child = starter.children
            .find((child) => child.id === 'VendorDetailsView').children
            .find((child) => child.id === id)

          const clonedChild = _.cloneDeep(child)

          messageClone
            .find(({ id }) => id === 'VendorDetailsView').children
            .push(clonedChild)

          return true
        } else {
          return false
        }
      }

      const injections = [
        inject('PurposesHeaderText'),
        inject('DataCategoriesHeaderText'),
        inject('CookiesHeaderText'),
        inject('RetentionText'),
        inject('DataCategoriesListHeaderText'),
        inject('PrivacyPolicyText'),
        inject('LegitimateInterestDiscloureText'),
      ]

      if (injections.some(bool => bool === true)) {
        dispatch({ type: 'UPDATE MESSAGE', load: messageClone })
      }
    }
  }, [])

  const updateIframe = () => {
    const myIframe = document.getElementById('builder-preview')
    myIframe.contentWindow.postMessage(JSON.stringify({
      components: replaceWithVariables(message, messageVariables),
      currentView: selectedView,
      currentComponentPath,
      root,
      siteId: sitesUnderSg.size
        ? sitesUnderSg.toJS()[0].id
        : siteId,
      isTemplate,
    }), '*')
  }

  const getIndex = () => {
    return Object.values(message).findIndex(({ id }) => id === selectedView)
  }

  const changeView = (i) => {
    const view = Object.values(message)[i].id
    dispatch({ type: 'UPDATE VIEW', load: { currentComponentPath: [0], selectedView: view } })
  }

  const updateViewBtn = (setting, value) => {
    const key = getKey(setting)
    const clonedMessage = _.cloneDeep(message)

    const buttonIndex = clonedMessage
      .find(({ id }) => id === constants.HOME)
      .children
      .findIndex(({ id }) => id === key)
    if (value === false) {
      clonedMessage
        .find(({ id }) => id === constants.HOME)
        .children
        .splice(buttonIndex, 1)
    } else {
      const buttonRef = starter.children
        .find(({ id }) => id === constants.HOME)
        .children
        .find(({ id }) => id === key)
      const button = _.cloneDeep(buttonRef)

      clonedMessage
        .find(({ id }) => id === constants.HOME)
        .children
        .push(button)
    }

    dispatch({ type: "UPDATE MESSAGE", load: clonedMessage })
  }

  const updateGlobalSetting = (setting, value) => {
    if (conditionalButtonSettings.includes(setting)) {
      updateViewBtn(setting, value)
    }

    const clonedRoot = _.cloneDeep(root)
    clonedRoot.settings = { ...clonedRoot.settings, [setting]: value }
    return dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
  }

  const resetCompliance = (value) => {
    if (value !== null && previousValue !== null && (JSON.stringify(value) !== JSON.stringify(previousValue))) {
      dispatch({ type: 'UPDATE PRIVACY COMPLIANCE STATUS', load: false })
      confirmPrivacyCheck(false)
    }
  }

  const updateSetting = (setting, value, multiSettingUpdate) => {
    if ([...conditionalButtonSettings, 'vendorList', 'border'].includes(setting)) {
      return updateGlobalSetting(setting, value)
    }

    if (setting.includes('global')) {
      const updateComponentSetting = (component, componentType, setting, cb) => {
        if ((component.settings.hasOwnProperty(setting) || component.settings.style.hasOwnProperty(setting) || (setting === 'border' && (componentType === 'LongButton' || componentType === 'NativeButton'))) && (componentType ? component.type === componentType : true)) {
          component.settings = cb(component.settings, component.type);
        }
        if (component.children) {
          component.children.forEach((child) => updateComponentSetting(child, componentType, setting, cb));
        }
      }
      const clonedRoot = _.cloneDeep(root);
      const clonedMessage = _.cloneDeep(message);
      if (setting === 'globalBackgroundColor') {
        clonedMessage.forEach((view) => {
          view.settings.style.backgroundColor = value;
          view.settings.backgroundColor = value;
        })
        clonedRoot.settings = { ...clonedRoot.settings, globalBackgroundColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalFont') {
        const { fontFamily, fontSize, fontSizeRem, fontWeight, color } = value

        clonedMessage.forEach((component) => {
          updateComponentSetting(component, null, 'font', (settings, type) => {
            const updatedFont = {
              ...settings.style.font,
              fontFamily,
              fontSize,
              fontSizeRem,
              fontWeight,
              color,
            };
            const updatedSettings = {
              ...settings,
              style: {
                ...settings.style,
                font: updatedFont
              },
              font: updatedFont
            };
            if (type === 'Slider') {
              updatedSettings.style.activeFont = updatedFont;
              updatedSettings.activeFont = updatedFont;
            }
            return updatedSettings;
          })
      });

        clonedRoot.settings = { ...clonedRoot.settings, globalFont: { fontFamily, fontSize, fontSizeRem, fontWeight, color } }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalButtonUnfocusColor') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'NativeButton', 'onUnfocusBackgroundColor', (settings) => ({
          ...settings,
          style: { ...settings.style, onUnfocusBackgroundColor: value },
          onUnfocusBackgroundColor: value,
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalButtonUnfocusColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalButtonFocusColor') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'NativeButton', 'onFocusBackgroundColor', (settings) => ({
          ...settings,
          style: { ...settings.style, onFocusBackgroundColor: value },
          onFocusBackgroundColor: value,
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalButtonFocusColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalButtonUnfocusFontColor') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'NativeButton', 'onUnfocusTextColor', (settings) => ({
          ...settings,
          style: { ...settings.style, onUnfocusTextColor: value },
          onUnfocusTextColor: value,
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalButtonUnfocusFontColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalButtonFocusFontColor') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'NativeButton', 'onFocusTextColor', (settings) => ({
          ...settings,
          style: { ...settings.style, onFocusTextColor: value },
          onFocusTextColor: value,
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalButtonFocusFontColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalButtonBorder') {
        const { borderWidth, borderWidthRem, borderColor, borderTopLeftRadius, borderTopLeftRadiusRem, borderTopRightRadius, borderTopRightRadiusRem, borderBottomLeftRadius, borderBottomLeftRadiusRem, borderBottomRightRadius, borderBottomRightRadiusRem, borderStyle } = value

        clonedMessage.forEach((component) => updateComponentSetting(component, 'NativeButton', 'border', (settings) => ({
          ...settings,
          border: { ...settings.style.border, borderWidth, borderWidthRem, borderColor, borderTopLeftRadius, borderTopLeftRadiusRem, borderTopRightRadius, borderTopRightRadiusRem, borderBottomLeftRadius, borderBottomLeftRadiusRem, borderBottomRightRadius, borderBottomRightRadiusRem, borderStyle },
          style: { ...settings.style, border: { ...settings.style.border, borderWidth, borderWidthRem, borderColor, borderTopLeftRadius, borderTopLeftRadiusRem, borderTopRightRadius, borderTopRightRadiusRem, borderBottomLeftRadius, borderBottomLeftRadiusRem, borderBottomRightRadius, borderBottomRightRadiusRem, borderStyle } },
        })))
        
        clonedRoot.settings = { ...clonedRoot.settings, globalButtonBorder: { borderWidth, borderWidthRem, borderColor, borderTopLeftRadius, borderTopLeftRadiusRem, borderTopRightRadius, borderTopRightRadiusRem, borderBottomLeftRadius, borderBottomLeftRadiusRem, borderBottomRightRadius, borderBottomRightRadiusRem, borderStyle } }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalLongButtonUnfocusColor') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'LongButton', 'onUnfocusBackgroundColor', (settings) => ({
          ...settings,
          style: { ...settings.style, onUnfocusBackgroundColor: value },
          onUnfocusBackgroundColor: value,
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalLongButtonUnfocusColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalLongButtonFocusColor') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'LongButton', 'onFocusBackgroundColor', (settings) => ({
          ...settings,
          style: { ...settings.style, onFocusBackgroundColor: value },
          onFocusBackgroundColor: value,
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalLongButtonFocusColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalLongButtonUnfocusFontColor') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'LongButton', 'onUnfocusTextColor', (settings) => ({
          ...settings,
          style: { ...settings.style, onUnfocusTextColor: value },
          onUnfocusTextColor: value,
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalLongButtonUnfocusFontColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalLongButtonFocusFontColor') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'LongButton', 'onFocusTextColor', (settings) => ({
          ...settings,
          style: { ...settings.style, onFocusTextColor: value },
          onFocusTextColor: value,
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalLongButtonFocusFontColor: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalLongButtonBorder') {
        const { borderWidth, borderWidthRem, borderColor, borderTopLeftRadius, borderTopLeftRadiusRem, borderTopRightRadius, borderTopRightRadiusRem, borderBottomLeftRadius, borderBottomLeftRadiusRem, borderBottomRightRadius, borderBottomRightRadiusRem, borderStyle } = value

        clonedMessage.forEach((component) => updateComponentSetting(component, 'LongButton', 'border', (settings) => {
          return {
          ...settings,
          border: { ...settings.style.border, borderWidth, borderWidthRem, borderColor, borderTopLeftRadius, borderTopLeftRadiusRem, borderTopRightRadius, borderTopRightRadiusRem, borderBottomLeftRadius, borderBottomLeftRadiusRem, borderBottomRightRadius, borderBottomRightRadiusRem, borderStyle },
          style: { ...settings.style, border: { ...settings.style.border, borderWidth, borderWidthRem, borderColor, borderTopLeftRadius, borderTopLeftRadiusRem, borderTopRightRadius, borderTopRightRadiusRem, borderBottomLeftRadius, borderBottomLeftRadiusRem, borderBottomRightRadius, borderBottomRightRadiusRem, borderStyle } },
        }}))
        clonedRoot.settings = { ...clonedRoot.settings, globalLongButtonBorder: { borderWidth, borderWidthRem, borderColor, borderTopLeftRadius, borderTopLeftRadiusRem, borderTopRightRadius, borderTopRightRadiusRem, borderBottomLeftRadius, borderBottomLeftRadiusRem, borderBottomRightRadius, borderBottomRightRadiusRem, borderStyle } }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      } else if (setting === 'globalLogo') {
        clonedMessage.forEach((component) => updateComponentSetting(component, 'NativeImage', 'src', (settings) => ({
          ...settings,
          style: { ...settings.style, url: value, src: value },
          url: value,
          src: value
        })))
        clonedRoot.settings = { ...clonedRoot.settings, globalLogo: value }
        clonedRoot.children = clonedMessage

        dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
        dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
        return
      }
    }

    const clonedComponent = _.cloneDeep(message.find(({ id }) => id === selectedView))
    const componentRef = clonedComponent

    const recursiveUpdateSetting = (components, ndx, setting, value) => {
      ndx = ndx || 0
      const more = currentComponentPath.length > (ndx + 1)
      const currentIndex = currentComponentPath[ndx]

      if (more) {
        return recursiveUpdateSetting(components[currentIndex].children, ndx + 1, setting, value)
      }
      if (settingKeys.style.includes(setting)|| setting === 'widthRem' ) {
        components[currentIndex].settings.style[setting] = value
        components[currentIndex].settings[setting] = value;
        if (components[currentIndex].type === 'Slider' && setting === 'font') {
          components[currentIndex].settings.style.activeFont = value
          components[currentIndex].settings.activeFont = value
        }
        if (setting === 'url' || setting === 'src') {
          components[currentIndex].settings.style.url = value
          components[currentIndex].settings.url = value
          components[currentIndex].settings.style.src = value
          components[currentIndex].settings.src = value
        }

        resetCompliance(value)
      } else if (setting === 'languagesAll') {
        components[currentIndex].settings.style[setting] = value.text
        components[currentIndex].settings.text = value.text
        components[currentIndex].settings.languages = value.languagesClone
      } else if (settingKeys.options.includes(setting)) {
        components[currentIndex].settings[setting] = value
        resetCompliance(value)
      } else if (setting === 'componentName') {
        components[currentIndex].name = value
      }
    }

    recursiveUpdateSetting([componentRef], 0, setting, value)

    if (multiSettingUpdate) {
      Object.entries(multiSettingUpdate).forEach(([setting, value]) => {
        recursiveUpdateSetting([componentRef], 0, setting, value)
      })
    }

    const index = message.findIndex(({ id }) => id === selectedView)
    const clonedMessage = [...message]
    clonedMessage.splice(index, 1, componentRef)
    dispatch({ type: 'UPDATE SETTING', load: { message: clonedMessage, previousValue: value }, })
  }

  const applyListener = ({ data = null }) => {
    switch (data.action) {
      case 'dev.templates':
      // return this.openTemplates()
      case 'dev.setCurrent':
        dispatch({ type: 'UPDATE CURRENT COMPONENT PATH', load: data.newCurrent })
        changeSidebarTab('settings')
    }
  }

  const changeSidebarTab = (activeKey) => dispatch({ type: 'UPDATE ACTIVE KEY', load: activeKey })

  const updateDefaultLanguage = (newLanguage) => {
    const clonedRoot = _.cloneDeep(root)
    const clonedMessage = _.cloneDeep(message)

    clonedRoot.settings = { ...clonedRoot.settings, defaultLanguage: newLanguage }

    function populateDefault(message) {
      message.settings.selectedLanguage = newLanguage
      if (message.settings.languages && message.settings.languages[newLanguage]) {
        Object.entries(message.settings.languages[newLanguage]).forEach(([key, value]) => {
          message.settings[key] = value
        })
      }
      message.children && message.children.forEach(c => populateDefault(c))
    }

    Object.values(clonedMessage).forEach(populateDefault)

    dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
    dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
  }

  const updateSupportedLanguages = (newLanguage) => {
    const clonedRoot = _.cloneDeep(root)
    clonedRoot.settings = { ...clonedRoot.settings, supportedLanguages: newLanguage }

    dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
  }

  const updatePreviewLanguage = (newLanguage) => {
    const clonedRoot = _.cloneDeep(root)
    const clonedMessage = _.cloneDeep(message)
    clonedRoot.settings = { ...clonedRoot.settings, previewLanguage: newLanguage }

    function populateDefault(message) {
      message.settings.selectedLanguage = newLanguage
      if (message.settings.languages && message.settings.languages[newLanguage]) {
        Object.entries(message.settings.languages[newLanguage]).forEach(([key, value]) => {
          message.settings[key] = value
        })
      }
      message.children && message.children.forEach(c => populateDefault(c))
    }

    Object.values(clonedMessage).forEach(populateDefault)

    dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
    dispatch({ type: 'UPDATE MESSAGE', load: clonedMessage })
  }

  const getCurrent = (ndx, components, path) => {
    const more = currentComponentPath.length > (ndx + 1)
    const currentIndex = currentComponentPath[ndx]
    const currentComponentSelected = components[currentIndex]
    path.push({ index: currentIndex, name: currentComponentSelected.name })

    return more
      ? getCurrent(ndx + 1, currentComponentSelected.children, path)
      : { currentComponentSelected, path }
  }

  const saveCompliance = (compliance_list) => {
    const clonedRoot = _.cloneDeep(root)
    clonedRoot.settings.compliance_list = compliance_list.toJS()
    dispatch({ type: 'UPDATE ROOT', load: clonedRoot })
  }

  const confirmPrivacyCheck = (privacyComplianceStatus) => {
    const clonedRoot = _.cloneDeep(root)
    clonedRoot.settings.compliance_status = privacyComplianceStatus
    dispatch({
      type: 'CONFIRM PRIVACY CHECK',
      load: {
        root: clonedRoot,
        showCompleteCheckPrivacyModal: false,
        privacyComplianceStatus
      }
    })
  }

  const copyUrlSetting = (children) => {
    const clonedChildren = _.cloneDeep(children)

    const update = (child) => {
      const { url } = child.settings
      if (url) {
        child.settings.src = url
        child.settings.style.src = url
      }

      if (child.children) {
        child.children.forEach(update)
      }
    }

    clonedChildren.forEach(update)
    return clonedChildren
  }

  const createOrUpdateMsg = (updatedTemplateParams, skipLanguageValidation) => {
    const wrapper = {
      ..._.cloneDeep(root),
      name: message.find(({ id }) => id === constants.HOME).name,
      children: copyUrlSetting(message)
    }

    if (!skipLanguageValidation) {
      const supportedLanguagesMissing = message
        .map(view => checkSupportedLanguages(root.settings.supportedLanguages, view))
        .reduce((supportedLanguagesMissing, curr) => [...supportedLanguagesMissing, ...curr], [])
      if (supportedLanguagesMissing.length) {
        return dispatch({ type: 'SHOW SUPPORTED LANGUAGES MODAL', load: supportedLanguagesMissing })
      }
    }

    if (!wrapper.settings.compliance_list) {
      const complianceList = messageCategory === 'gdpr' ? privacyManagerComplianceList.toJS().map(({ id, status }) => ({ [id]: status })) : []
      wrapper.settings.compliance_list = complianceList
    }

    const { isDraft, isSystemTemplate } = updatedTemplateParams || templateParams
    const templateParamsToSave = {
      is_draft: isDraft,
      is_system_template: isSystemTemplate,
    }
    
    if (isTemplate) {
      saveMessage([wrapper], null, templateParamsToSave)
    } else if (updatedTemplateParams && !isTemplate) {
      saveTemplate([wrapper], messageVariables, templateParamsToSave)
    } else {
      saveMessage([wrapper], messageVariables)
    }
  }

  const saveTemplateType = (status, type) => {
    const updatedTemplateParams = {
      ...templateParams,
      isSystemTemplate: status,
      isDraft: type
    };
    dispatch({
      type: 'UPDATE_TEMPLATE_PARAMS',
      load: updatedTemplateParams
    })
    createOrUpdateMsg(updatedTemplateParams)
  }

  const createTemplateBtn = () => {
    let templateButton
    if (!messageToEdit) {
      if (accountId === 22) {
        templateButton = (
          <Popover
            placement="bottom"
            title={(
              <div>
                <span style={{ textAlign: 'center' }}>
                  Template will be saved in draft state.
                </span>
                <br />
                <span>Select the type of template</span>
              </div>
            )}
            content={(
              <div className="template-type-selection">
                <Button
                  onClick={() => saveTemplateType(true, true)}
                  type="ghost"
                >
                  System Template
                </Button>
                <Button onClick={() => saveTemplateType(false, true)}
                  type="ghost"
                >
                  Account Template
                </Button>
              </div>
            )}
            open={chooseTemplateTypeModalVisible}
            onOpenChange={visible => dispatch({ type: 'SHOW_CHOOSE_TEMPLATE_MODAL_VISIBLE', load: visible })}
            trigger="click"
          >
            <Button
              type="primary"
              onClick={null}
            >
              Create Template
            </Button>
          </Popover>
        )
      } else {
        templateButton = (
          <Button
            type="primary"
            onClick={() => saveTemplateType(false, true)}
          >
            Create Template
          </Button>
        )
      }
    } else {
      templateButton = (
        <Button
          type="primary"
          onClick={() => saveTemplateType(message.is_systemTemplate, message.is_draft)}
        >
          Save Template
        </Button>
      )
    }

    return templateButton
  }

  const { currentComponentSelected, path } = getCurrent(0, [_.cloneDeep(message.find(({ id }) => id === selectedView))], [])

  const handleMessageBuilderClose = () => {
    let updatedMessage = _.cloneDeep(root);
    updatedMessage.children = message;
    closeBuilder(updatedMessage)
  }

  const setFromTemplate = (messages) => {
      dispatch({ type: 'UPDATE MESSAGE', load: _.cloneDeep(messages[0].children) })
      dispatch({ type: 'UPDATE ROOT', load: _.cloneDeep(messages[0]) })
  }


  const iframeContent = (
    <Content className="notice-preview" style={{ marginTop: '35px' }}>
      <div id="iframe-holder">
        <iframe
          title="privacy-manager-iframe"
          onLoad={updateIframe}
          id='builder-preview'
          src={`${NOTICE_URL}/native-ott/index.html?&type=dev${messageCategory === 'ccpa' ? '&is_ccpa=true' : ''}`}
          key={siteId}
        />
      </div>
      <ViewNavigation
        messages={message}
        currentComponentIndex={getIndex()}
        changeCurrentComponentIndex={changeView}
      />
    </Content>
  );
  const content = message ? iframeContent : null;

  const redirectToSystemTemplates = () => {
    browserHistory.push(`/admin/system_templates`);
  };

  const systemOrDraft = accountId === 22 ? 'System Template' : 'Save As Draft';
  const accountOrPublic = accountId === 22 ? 'Account Template' : 'Save As Public';

  const templateContent = (
    <React.Fragment>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: '10px', justifyContent: "flex-end" }}>
        <Button
          onClick={() => accountId === 22 ? saveTemplateType(true, true) : saveTemplateType(false, true)}
          type="ghost"
          style={{ marginRight: '5px' }}
        >
          {systemOrDraft}
        </Button>
        <Button onClick={() => accountId === 22 ? saveTemplateType(false, true) : saveTemplateType(false, false)} type="ghost">
          {accountOrPublic}
        </Button>
      </div>
    </React.Fragment>
  );

  const isAccessToTemplates = (currentUser.featureAccess && currentUser.featureAccess.size && currentUser.featureAccess.includes('templates')) ||
    !currentUser.featureAccess;

  return (
    <React.Fragment>
      <div className="builder-title">
        <h3>
          Builder: {message.find(({ id }) => id === constants.HOME).name}
        </h3>
        {(
          isTemplate
            ? createTemplateBtn()
            : (
              <Button
                onClick={() => createOrUpdateMsg()}
                type="primary"
                disabled={isPreview}
              >
                {messageToEdit ? "Save Message" : "Create Message"}
              </Button>
            )
        )}
        <Button onClick={handleMessageBuilderClose} type="default">
          Close
        </Button>
        {(!isTemplate && messageCategory === 'gdpr') && (
          <PrivacyComplianceModal
            title="Check if all the compliance points are addressed"
            complianceStatus={privacyComplianceStatus}
            complianceMessages={privacyComplianceMessages}
            saveCompliance={saveCompliance}
            messageSubcategory="native-ott"
            handleCompPrivacyModal={(visible) => dispatch({ type: 'SHOW COMPLETE CHECK PRIVACY MODAL', load: visible })}
          />
        )}
        {!isTemplate && isAccessToTemplates && (
          <Infotip
            placement="bottom"
            className='save-template'
            title={
              <React.Fragment>
                <span style={{ textAlign: 'center', fontSize: '18px' }}>
                  <b>Save as Template</b>
                </span>
                <br />
                <div>
                  Templates can now be managed in the
                  <span className="redirect-system-templates" onClick={redirectToSystemTemplates}>
                    Manage Templates
                    <CustomIcon type={CustomIcon.types.PREVIEW} />
                    <br />
                  </span>
                  section available in the Super Admin menu.
                </div>
              </React.Fragment>
            }
            content={templateContent}
            visible={chooseTemplateTypeModalVisible}
            onVisibleChange={visible => dispatch({ type: 'SHOW_CHOOSE_TEMPLATE_MODAL_VISIBLE', load: visible })}
            trigger="click"
          >
            <Button
              type="primary"
              shape="circle"
            >
              <Tooltip title="Save as Template"><FontAwesomeIcon icon={faUpload} /></Tooltip>
            </Button>
          </Infotip>
        )}
      </div>
      <Layout className="bulletin" style={{ minHeight: 'calc(100vh - 68px)' }}>
        <Layout>
          <Templates
            templates={templates}
            accountId={currentUser.accountId}
            setFromTemplate={setFromTemplate}
            visible={selectTemplates}
            siteId={siteId}
            isTemplate={isTemplate}
            currentUser={currentUser}
            messageSubcategory="native-ott"
            messageCategory={messageCategory}
            deleteTemplate={deleteTemplate}
            handleCancel={closeTemplates}
          />
          { content }
          <DefaultLanguageContext.Provider
            value={{
              defaultLanguage: root.settings.defaultLanguage,
              previewLanguage: root.settings.previewLanguage,
              supportedLanguages: root.settings.supportedLanguages,
              updateDefaultLanguage,
              updatePreviewLanguage,
              updateSupportedLanguages,
            }}
          >
            <CurrentMessageViewContext.Provider value={{ currentView: selectedView, pmData: {}, }}>
              <Sidebar
                current={currentComponentSelected}
                setCurrent={(newCurrentComponentPath) => dispatch({ type: 'UPDATE CURRENT COMPONENT PATH', load: newCurrentComponentPath })}
                path={path}
                changeTab={changeSidebarTab}
                isNativeOtt
                activeKey={activeKey}
                currentUser={currentUser}
                updateSetting={updateSetting}
                accountId={accountId}
                siteId={siteId}
                messageSubcategory="native-ott"
                messageCategory={messageCategory}
                updateVariables={(messageVariables) => dispatch({ type: 'UPDATE MESSAGE VARIABLES', load: messageVariables })}
                variables={messageVariables}
                rootCurrent={root}
                components={message}
              />
            </CurrentMessageViewContext.Provider>
          </DefaultLanguageContext.Provider>
        </Layout>
      </Layout>
      <SupportedLanguagesModal
        visible={supportedLanguagesModalVisible}
        missing={supportedLanguagesMissing}
        handleCancel={() => dispatch({ type: 'HIDE SUPPORTED LANGUAGES MODAL' })}
        handleOk={() => {
          dispatch({ type: 'HIDE SUPPORTED LANGUAGES MODAL' })
          createOrUpdateMsg(null, 'skip')
        }}
      />
      <Modal
        visible={showCompleteCheckPrivacyModal}
        footer={[(
          <Button onClick={() => confirmPrivacyCheck(privacyComplianceStatus)} key="back">
            CANCEL
          </Button>
        ),
        (
          <Button onClick={() => confirmPrivacyCheck(true)} key="submit" type="primary">
            CONFIRM PRIVACY CHECK REVIEW
          </Button>
        )
        ]}
      >
        <h4>Privacy Check Completed</h4>
        <div>All compiance rules are achieved for this message.</div>
        <div>Please confirm to finalize the privacy check review.</div>
      </Modal>
    </React.Fragment>
  )
}

export default NativeOttBuilder

window.branch = 'mr-fix-stale-text-home'