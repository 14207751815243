import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import { percentOfNum, numberWithCommas } from '../../../utils.js';
import MessageStats from './MessageStats.js.jsx';
import DeviceStats from './DeviceStats.js.jsx';
import CustomTable from '../../../common/CustomTable.js.jsx';
import TableBody from '../../../common/TableBody.js.jsx';
import TableRow from '../../../common/TableRow.js.jsx';
import TableCell from '../../../common/TableCell.js.jsx';
import TableHeader from '../../../common/TableHeader.js.jsx';
import TableCellHeader from '../../../common/TableCellHeader.js.jsx';
import PostEngagementStats from './PostEngagementStats.js.jsx';

export default class extends React.Component {
    static propTypes = {
        partition: PropTypes.object.isRequired,
        messageIdToName: PropTypes.object.isRequired,
    };

    render() {
        var partition = this.props.partition;
        var ps = partition.partition_stats;
        var totalViewsOfAllMessages = 0;
        var totalViewsOfAllMessagesWithChoices = 0;
        var partitionHasChoice = false;
        var partitionHasWhiteListChoice = false;
        var totalChoicesOfAllMessages = 0;
        var totalCompletedChoicesOfAllMessages = 0;


        for (var key in partition.message_data) {
            var message = partition.message_data[key];
            totalViewsOfAllMessages += message.view_count;
            if (Object.keys(message.choice_data).length) totalViewsOfAllMessagesWithChoices += message.view_count;

            for (var key in message.choice_data) {
                var choice = message.choice_data[key];
                partitionHasChoice = true;
                if (choice.choice_type_id === 1) partitionHasWhiteListChoice = true;
                totalChoicesOfAllMessages += choice.count;
                totalCompletedChoicesOfAllMessages += choice.count_complete;
            }
        }

        var postEngagementData;
        var pe = partition.post_engagement_data;
        // postEngagementData = (
        //     <tbody>
        //       <tr className="total post-choice">
        //         <td>Total Post-choice</td>
        //         <td>{ numberWithCommas(pe.total_post_wl_pv) }</td>
        //         <td>{ numberWithCommas(pe.abp_post_wl_pv) }</td>
        //         <td>{ pe.total_post_wl_pv ? percentFormat(pe.abp_post_wl_pv * 100 / pe.total_post_wl_pv) : 0 }</td>
        //         <td></td>
        //       </tr>
        //     </tbody>
        // )

        return (

            <div className="partition-stats clearfix">

              <DeviceStats data={ ps } title={ partition.name } postEngagementData={ postEngagementData }/>

              { Object.keys(partition.message_data).length > 0 ?

                  <div className="messaging-tables">

                    <CustomTable className="message-header">
                      <TableHeader>
                       <TableRow>
                         <TableCellHeader>Message</TableCellHeader>
                         <TableCellHeader>Views</TableCellHeader>
                         <TableCellHeader>Choice</TableCellHeader>
                         <TableCellHeader>Count</TableCellHeader>
                         <TableCellHeader>Ratio</TableCellHeader>
                         <TableCellHeader>Completed</TableCellHeader>
                         <TableCellHeader>Ratio</TableCellHeader>
                       </TableRow>
                      </TableHeader>
                    </CustomTable>


                    { Object.keys(partition.message_data).map(key => partition.message_data[key]).map((message) => {
                        message = $.extend(true, {}, message);

                        message.name = this.props.messageIdToName[message.message_id];
                        return <MessageStats key={ message.message_id } message={ message }/>
                    })}


                    <CustomTable>
                      <TableBody>
                        <TableRow className="total">
                          <TableCell>Total</TableCell>
                          <TableCell>{ numberWithCommas(totalViewsOfAllMessages) }</TableCell>
                          <TableCell></TableCell>
                          <TableCell>{ partitionHasChoice ? numberWithCommas(totalChoicesOfAllMessages) : null }</TableCell>
                          <TableCell>{ partitionHasChoice ?  percentOfNum(totalChoicesOfAllMessages, totalViewsOfAllMessagesWithChoices) : null }</TableCell>
                          <TableCell>{ partitionHasChoice ? partitionHasWhiteListChoice ? "N/A" : numberWithCommas(totalCompletedChoicesOfAllMessages) : null }</TableCell>
                          <TableCell>{ partitionHasChoice ? partitionHasWhiteListChoice ? "N/A" :  percentOfNum(totalCompletedChoicesOfAllMessages, totalViewsOfAllMessagesWithChoices) : null }</TableCell>
                        </TableRow>
                      </TableBody>
                    </CustomTable>

                  </div>

                  : null
                }

                <PostEngagementStats data={ pe } />

            </div>
        )
    }
}
