import PropTypes from 'prop-types';
import React from 'react';
import {
  List as ListView,
  Tabs
} from 'antd';
import { Modal, Input, Checkbox, Button, Select, Infotip} from "../../styleguide";
import {
  CheckOutlined,
  CloseCircleOutlined,
  SettingOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, OrderedSet, Map } from 'immutable';
import {
  VendorList,
  Vendor,
  Purpose,
  PurposeV2,
  VendorWrapper,
} from '../../records/vendor_list_records';
import { categorize } from '../consent/vendor_list_v2/util';

const Search = Input.Search;
const Option = Select.Option;
const { TabPane } = Tabs;

const titleInfoVendor = List([
  Map({ key: "IAB", title: '', subTitle: '' }),
  Map({ key: "CUSTOM", title: 'Configure Custom Purposes and Legal Basis', subTitle: 'Use Custom Vendor Defaults' }),
  Map({ key: "CUSTOMATP", title: 'Configure Google ATP Purposes and Legal Basis', subTitle: 'Use Google ATP Vendor Defaults' }),
])

export default class AddVendorsModalV2 extends React.Component {
  static propTypes = {
    vl: PropTypes.instanceOf(VendorList).isRequired,
    vendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor))
      .isRequired,
    visible: PropTypes.bool.isRequired,
    updateVendorList: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    iabPurposes: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor))
      .isRequired,
    ccpa: PropTypes.bool.isRequired,
    v2: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    consentCompensationList: null,
  };

  state = {
    vendorsToAdd: OrderedSet([]),
    categories: List(),
    search: '',
    vl: this.props.vl,
    selectIabVendors: true,
    activeKey: this.props.filterElement('IAB', 'CUSTOM'),
    popupVisible: false,
    selectedVendorIds: List(),
    checkforCustom: false,
    checkforCustomATP: false,
    showGoogleAdWraningMsg: false,
    configForCustom: List(),
    configForCustomATP: List(),
    applyConfig: false,
    vendorsWithoutSelected: this.props.vendors.filterNot((gv) => this.props.vl.vendors.map((v) => v.id).includes(gv.id)),
  };

  updateSearch = (e) => this.setState({ ['search' + this.state.activeKey]: e.target.value });

  getVendorByName = (name) => (this.props.vendors.find((v) => v.get('name') === name));

  onChangeTab = (key) => this.setState({ activeKey: key });

  handleSelect = (vendorId) => {
    let ids = this.state.selectedVendorIds;
    if (!ids.includes(vendorId)) {
      ids = ids.push(vendorId);
    } else {
      let index = ids.findIndex((id) => id === vendorId);
      ids = ids.remove(index);
    }

    const vendors = ids.map((id) =>
      this.props.vendors.find((v) => v.id === id)
    );

    this.setState({
      vendorsToAdd: vendors,
      selectedVendorIds: ids,
    });
  };

  getVendorIdsForPurpose = (purpose, vendors) => {
    const nonIABVendorIds = purpose.vendorIds
      .map((id) => this.props.vendors.find((v) => v.id === id))
      .map((v) => v.id);

    return vendors
      .filter((v) => {
        const purposeIdsOfVendor = v.purposes.map((p) =>
          typeof p === 'string' ? p : p.id
        );
        if (this.props.v2) {
          return purposeIdsOfVendor.some((id) => id === purpose.iabPurposeRef);
        } else {
          return purposeIdsOfVendor.some((id) =>
            purpose.iabPurposeRefs.includes(id)
          );
        }
      })
      .map((v) => v.id)
      .concat(nonIABVendorIds);
  };

  handleAddVendors = (
    scannedVendors = OrderedSet([]),
    scannedSitesIds = List([])
  ) => {
    let categories;

    if (this.props.vl.vendors.size) {
      categories = this.props.vl.categories;
    } else {
      if (this.props.ccpa) {
        categories = new List([
          new Purpose({ name: 'Category 1', type: 'CUSTOM' }),
          new Purpose({ name: 'Category 2', type: 'CUSTOM' }),
          new Purpose({ name: 'Category 3', type: 'CUSTOM' }),
        ]);
      } else if (!this.props.filterElement(true)) {
        categories = new List([
          new PurposeV2({ name: 'Category 1', type: 'CUSTOM' }),
          new PurposeV2({ name: 'Category 2', type: 'CUSTOM' }),
          new PurposeV2({ name: 'Category 3', type: 'CUSTOM' }),
        ]);
      } else {
        categories = this.props.iabPurposes.map((iabP) => {
          return iabP.set('id', null);
        });
      }
    }

    const listVendorsWithoutRescanned = this.props.vl.vendors.filterNot((v) =>
      scannedVendors.map((sv) => sv.id).includes(v.id)
    );
    const allVendors = listVendorsWithoutRescanned
      .concat(this.state.vendorsToAdd)
      .concat(scannedVendors);

    if (this.props.ccpa) {
      categories = categories.map((c) =>
        c.set('vendorIds', this.getVendorIdsForPurpose(c, allVendors))
      );
    } else {
      const { getVendorIdsForPurpose, state } = this;
      categories = categories.map(function updateCats(c, index) {

        const updatedCategory = c
          .set('vendorIds', getVendorIdsForPurpose(c, allVendors))
          .update('vendorCategorization', (vc) => {
            const newCategorizations = state.vendorsToAdd
              .map((vendor) => {
                let vendorTypeValue = (vendor.vendorType === 'CUSTOM' && vendor.isGoogleAtp) ? 'CUSTOMATP' : 'CUSTOM'
                let consentType = state[c.get('name') + vendorTypeValue + 'APPLY'];
                return categorize(
                  c,
                  {
                    id: vendor.id,
                    vendorType: vendor.vendorType,
                    purposes: vendor.purposes.map(({ id }) => id),
                    legIntPurposes: vendor.legIntPurposes.map(({ id }) => id),
                  },
                  state.vl.defaultLegalBasis,
                  consentType === 'NOT_ALLOWED' || consentType === undefined ? 'NOT_APPLICABLE' : consentType
                )
              }
              )
              .filterNot(({ type }) => type === 'NOT_APPLICABLE');
            return vc.concat(newCategorizations);
          })
          .update('categories', (cats) => cats.map(updateCats));

        return updatedCategory;
      });
    }

    const newVendorWrappersForScanned = scannedVendors
      .filter((v) => v.isNew)
      .map(
        (v) => new VendorWrapper({ vendorId: v.id, cookies: v.cookies.toJS() })
      );
    const vendorsWithoutScanned = this.state.vendorsToAdd.filterNot((v) =>
      scannedVendors.map((sv) => sv.id).includes(v.id)
    );
    const allNewVendorWrappers = vendorsWithoutScanned
      .map((v) => new VendorWrapper({ vendorId: v.id }))
      .concat(newVendorWrappersForScanned);

    const updatedOldVendorWrappers = this.props.vl.vendorsWrappers.map((vw) => {
      if (scannedVendors.map((v) => v.id).includes(vw.vendorId)) {
        return vw.set(
          'cookies',
          scannedVendors.find((v) => v.id === vw.vendorId).cookies
        );
      } else {
        return vw;
      }
    });

    const updatedVendorList = this.props.vl
      .set(
        'vendors',
        allVendors
          .sortBy((v) => v.name.toLowerCase())
          .sortBy((v) => v.vendorType)
      )
      .set('categories', categories)
      .set('siteIds', scannedSitesIds.concat(this.props.vl.siteIds).toSet())
      .set(
        'vendorsWrappers',
        updatedOldVendorWrappers.concat(allNewVendorWrappers)
      );

    this.setState({
      vendorsToAdd: OrderedSet([]),
    });
    this.props.updateVendorList(updatedVendorList);
    this.props.closeModal();
  };

  handleCloseAddVendorsModal = () => {
    this.setState({
      vendorsToAdd: OrderedSet([]),
    });
    this.props.closeModal();
  };

  componentDidMount() {
    let categories = List();
    let vendorList = this.props.vl;
    vendorList.categories.map(item => {
      if (item.categories.size > 0) {
        item.categories.map((childcategory => {
          categories = categories.push(childcategory)
        }))
      } else {
        if (item.type === 'IAB_PURPOSE' || item.type === 'CUSTOM') {
          categories = categories.push(item)
        }
      }
    })
    this.setState({ categories }, () => this.initializeData())
  }



  initializeData = () => {
    let isGoogleAdproductPresent = this.props.vl.get('vendors').find((item) => item.get('iab') && item.get('iabId') === 755)
    if (isGoogleAdproductPresent != undefined) {
      this.setState({ checkforCustomATP: true }, () => {
        this.state.categories.map((item, index) => {
          let categoryId = item.get('name')
          let vendorDetail = item.get('vendorCategorization').find((purpose) => purpose.get('vendorId') === isGoogleAdproductPresent.get('id'))
          if (vendorDetail != undefined && vendorDetail.get('type') !== 'NOT_ALLOWED') {
            this.setState({
              [categoryId + 'CUSTOMATP']: vendorDetail.get('type'),
              [categoryId + 'CUSTOMATP' + 'APPLY']: vendorDetail.get('type')
            })
          } else {
            this.setState({
              [categoryId + 'CUSTOMATP']: 'NOT_APPLICABLE',
              [categoryId + 'CUSTOMATP' + 'APPLY']: 'NOT_APPLICABLE'
            })
          }
        })
      })

    }
    this.setState({ checkforCustom: true }, () => {
      this.state.categories.map((item, index) => {
        let categoryId = item.get('name')
        if (item.get('defaultCustomLegalBasis') != null) {
          this.setState({
            [categoryId + 'CUSTOM']: item.get('defaultCustomLegalBasis'),
            [categoryId + 'CUSTOM' + 'APPLY']: item.get('defaultCustomLegalBasis')
          })
        } else {
          this.setState({
            [categoryId + 'CUSTOM']: 'NOT_APPLICABLE',
            [categoryId + 'CUSTOM' + 'APPLY']: 'NOT_APPLICABLE'
          })
        }
      })
    })
    //  return true
  }

  getFilteredVendors = (vendors) => {
    return vendors.filter((vendor) => {
      const name = vendor.get('name');
      if (this.state['search' + this.state.activeKey]) {
        if (!name.toLowerCase().includes(this.state['search' + this.state.activeKey].toLowerCase())) {
          return false;
        }
      }
      return name !== '' && name !== null && name !== undefined;
    });
  };

  addAllIab = () => {
    const allIabVendorsWithoutSelected = this.props.vendors
      .filterNot((gv) => this.props.vl.vendors.map((v) => v.id).includes(gv.id))
      .filter((v) => v.vendorType === 'IAB');

    let vendorsToAdd = this.state.vendorsToAdd;

    vendorsToAdd = vendorsToAdd.concat(allIabVendorsWithoutSelected).toOrderedSet().toList();;
    const ids = vendorsToAdd.map((v) => v.id);
    this.setState({
      vendorsToAdd,
      selectedVendorIds: ids.toList(),
    });
  };

  addAllCustomATP = () => {
    let allATPVendorsWithoutSelected = this.props.vendors
      .filterNot((gv) => this.props.vl.vendors.map((v) => v.id).includes(gv.id))
      .filter((v) => v.vendorType === 'CUSTOM' && v.isGoogleAtp === true);

    let vendorsToAdd = this.state.vendorsToAdd

    vendorsToAdd = vendorsToAdd.concat(allATPVendorsWithoutSelected).toOrderedSet().toList()

    const ids = vendorsToAdd.map((v) => v.id);
    this.setState({
      vendorsToAdd,
      selectedVendorIds: ids.toList(),
    });
  };

  handleVisibleChange = (visible) => this.setState({ popupVisible: visible });

  closeConfigurePopUp = (type) => {
    this.setState({ popupVisible: false, showGoogleAdWraningMsg: false, ['applyConfig' + type]: false })
  }

  removeAllSelectedTypeVendors = (vendorType, isGoogleAtp) => {
    const vendorsToAdd = this.state.vendorsToAdd.filterNot(
      (vendor) =>
        vendor.vendorType == vendorType && vendor.isGoogleAtp === isGoogleAtp
    );
    const selectedVendorIds = vendorsToAdd.map((v) => v.id).toList();
    this.setState({
      vendorsToAdd,
      selectedVendorIds,
    });
  };

  removeVendor = (id) => {
    const vendorsToAdd = this.state.vendorsToAdd.filterNot(
      (vendor) => vendor.id === id
    );
    let ids = this.state.selectedVendorIds;
    let index = ids.findIndex((ID) => ID === id);
    ids = ids.remove(index);
    this.setState({
      vendorsToAdd,
      selectedVendorIds: ids,
    });
  };

  searchBar = (title) => (
    <Input
      placeholder={`Search available ${title}`}
      onChange={this.updateSearch}
      suffix={<SearchOutlined />}
    />
  );

  setCustomValue = (e) => {
    this.setState({ checkforCustom: e.target.checked }, () => {
      this.state.categories.map((item, index) => {
        ((item.get('defaultCustomLegalBasis') != null) ?
          this.setConfigureValue(item.get('name'), item.get('defaultCustomLegalBasis')) : this.setConfigureValue(item.get('name'), 'NOT_APPLICABLE'))
      })
    })
  }

  setCustomValueATP = (e) => {
    let isGoogleAdproductPresent = this.props.vl.get('vendors').find((item) => item.get('iab') && item.get('iabId') === 755)

    if (isGoogleAdproductPresent != undefined) {
      this.setState({ checkforCustomATP: e.target.checked, showGoogleAdWraningMsg: false }, () => {
        this.state.categories.map((item, index) => {
          let id = item.get('name')
          let vendorDetail = item.get('vendorCategorization').find((purpose) => purpose.get('vendorId') === isGoogleAdproductPresent.get('id'))
          if ((vendorDetail != undefined) && vendorDetail.get('type') != 'NOT_ALLOWED') {
            this.setConfigureValue(id, vendorDetail.get('type'))
          } else {
            this.setConfigureValue(id, 'NOT_APPLICABLE')
          }
        })
      })
    } else {
      this.setState({ showGoogleAdWraningMsg: true, checkforCustomATP: false })
    }
  }

  setConfigureValue = (id, value) => this.setState({ [id + this.state.activeKey]: value })

  applyConfig = (activeTab) => {
    this.state.categories.map((item, index) => {
      let categoryId = item.get('name')
      this.setState({ [categoryId + activeTab + 'APPLY']: this.state[categoryId + activeTab] })
    })
    this.setState({ popupVisible: false, ['applyConfig' + activeTab]: true })
  }

  render() {

    const vendorsWithoutSelected = this.state.vendorsWithoutSelected;

    let iabVendors = List([]);
    let customVendors = List([]);
    let customATPVendors = List([]);
    let title = titleInfoVendor.filter((item) => item.get("key") === this.state.activeKey).get(0)
    let activeTab = this.state.activeKey
    let className = activeTab === 'CUSTOMATP' ? (this.state.checkforCustomATP ? 'purpose-select-disabled' : 'purpose-select') :
      (this.state.checkforCustom ? 'purpose-select-disabled' : 'purpose-select')

    const popupContent = (
      <div className="config-popup-container">
        <div className="config-checkbox-container">
          {(activeTab === 'CUSTOM') ? (
            <Checkbox purple  name="custom" checked={this.state.checkforCustom} onChange={(e) => this.setCustomValue(e)}></Checkbox>
          ) : (
              <Checkbox purple  name="customATP" checked={this.state.checkforCustomATP} onChange={(e) => this.setCustomValueATP(e)}></Checkbox>
            )}
          {title.get('subTitle')}
        </div>
        <div className="line-style"></div>
        <div className="config-popup-header">
          <div className="header-title-one">
            Purposes
          </div>
          <div className="header-title-two">
            Legal Basis
          </div>
        </div>
        {this.state.showGoogleAdWraningMsg && activeTab === 'CUSTOMATP' && (<div className="warning-message-container">
          You have to add 'Google Ad Product' Vendor to the list to enable and apply custom ATP vendor defaults
        </div>)}
        <div className="purpose-list-parentContainer">
          {this.state.categories.map((item, index) => {
            let id = item.get('name')
            return (<div className="purpose-list-container">
              <div className="purpose-name">{item.get('name')}</div>
              <Select
                className={className}
                disabled={activeTab === 'CUSTOMATP' ? this.state.checkforCustomATP : this.state.checkforCustom}
                onChange={(value) => this.setConfigureValue(id, value)}
                value={this.state[id + this.state.activeKey] ? this.state[id + this.state.activeKey] : 'Select'}
              >
                <Option value="CONSENT">
                  <span className="li-tag li-consent">User Consent</span>
                </Option>
                <Option value="LEGITIMATE_INTEREST">
                  <span className="li-tag li-legitimate">Legitimate Interest</span>
                </Option>
                <Option value="NOT_APPLICABLE">
                  <span className="li-tag li-applicable">Not Applicable</span>
                </Option>
              </Select>
            </div>)
          })}</div>
        <div className="config-popup-footer">
          <Button onClick={() => this.closeConfigurePopUp(activeTab)}>Cancel</Button>
          <Button onClick={() => this.applyConfig(activeTab)} type="primary">Apply</Button>
        </div>
      </div>
    );

    if (vendorsWithoutSelected.size) {
      vendorsWithoutSelected.forEach((vendor) => {
        if (vendor.vendorType === 'IAB') {
          iabVendors = iabVendors.push(vendor);
        } else if (vendor.vendorType === 'CUSTOM') {
          if (vendor.isGoogleAtp) {
            customATPVendors = customATPVendors.push(vendor)
          } else {
            customVendors = customVendors.push(vendor);
          }
        }
      });
    }

    let filteredIabVendors = this.getFilteredVendors(iabVendors)
    let filteredCustomVendors = this.getFilteredVendors(customVendors)
    let filteredCustomATPVendors = this.getFilteredVendors(customATPVendors)

    const vendorType =
      this.state.activeKey == 'CUSTOMATP' ? 'CUSTOM' : this.state.activeKey;
    const isGoogleAtp = this.state.activeKey == 'CUSTOMATP' ? true : false;
    const selectedVendors = this.state.vendorsToAdd.filter(
      (v) => v.vendorType == vendorType && v.isGoogleAtp === isGoogleAtp
    );
    const tabs = List([
      this.props.filterElement(Map({
        key: 'IAB',
        title: `IAB Vendors (${iabVendors.size})`,
        searchTitle: 'IAB vendors',
        vendors: filteredIabVendors,
        icon: null,
      })),
      Map({
        key: 'CUSTOM',
        title: `Custom Vendors (${customVendors.size})`,
        searchTitle: 'Custom vendors',
        vendors: filteredCustomVendors,
        icon: null,
      }),
      Map({
        key: 'CUSTOMATP',
        title: `Custom ATP Vendors (${customATPVendors.size})`,
        searchTitle: 'Custom ATP vendors',
        vendors: filteredCustomATPVendors,
        icon: (
          <img
            className="google-icon"
            src="/images/google-icon.svg"
          />
        ),
      }),
    ])
      //removes first tab if null
      .filter(tab => tab);
    return (
      <Modal
        visible={this.props.visible}
        title={`Add Vendors`}
        onOk={() => this.handleAddVendors()}
        onCancel={this.handleCloseAddVendorsModal}
        okText="Add Vendors"
        wrapClassName="add-vendors edit-vendor-compensationv2"
        width={900}
        closable={false}
      >
        <div className="vendor-tab-container">
          <div className="vendor-tab-subcontainer">
            <div className="tab-maintitle">Select vendors to add to the list</div>
            <Tabs
              className="vendor-list-v2"
              defaultActiveKey={this.props.filterElement('IAB', 'CUSTOM')}
              onChange={(key) => this.onChangeTab(key)}
              activeKey={this.state.activeKey}
            >
              {tabs.map((t, ti) => (
                <TabPane className="config-tab" tab={<span className="tab-title">{t.get('title')}</span>} key={t.get('key')}>
                  <div className="vendor-tab">
                    <div className="vendor-tab-container">
                      {this.searchBar(t.get('searchTitle'))}
                      {activeTab === 'CUSTOMATP' ? (
                        <Button
                          size='small'
                          className="toggle-add-iab-new"
                          onClick={this.addAllCustomATP}
                        >
                          {t.get('icon')} Add All ATP Vendors
                        </Button>
                      ) : (activeTab === 'IAB') ? <Button
                        size='small'
                        className="toggle-add-iab-new"
                        onClick={this.addAllIab}
                      >
                        Add All IAB Vendors
                    </Button> : null}
                    </div>
                    <ListView className="vendor-v2-listview">
                      {t.get('vendors').map((vendor, vi) => (
                        <ListView.Item
                          key={vi}
                          onClick={() => this.handleSelect(vendor.get('id'))}
                        >
                          <div className="vendor-tab-container">
                            <div className="vendor-item-container">
                              {t.get('icon')} {vendor.get('name')}
                            </div>
                            <div>
                              <CheckOutlined
                                style={{
                                  color: this.state.vendorsToAdd.some(
                                    (v) => v.get('id') === vendor.get('id')
                                  )
                                    ? '#411F90'
                                    : 'lightgray',
                                  fontWeight: this.state.vendorsToAdd.some(
                                    (v) => v.get('id') === vendor.get('id')
                                  )
                                    ? 'bolder'
                                    : 'light',
                                }}
                              />
                            </div>
                          </div>
                        </ListView.Item>
                      ))}
                    </ListView>
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </div>
          <div className="selected-vendors-container container-style">
            {this.state.activeKey != 'IAB' && <Infotip
              className="config-popup-vendor"
              open={this.state.popupVisible}
              onOpenChange={(visible) => this.handleVisibleChange(visible)}
              content={popupContent}
              trigger="click"
              placement="bottomRight"
            >
              <div className="setting-title-container">
                <SettingOutlined className="setting-icon" />
                <span className="title-container">{title.get('title')}</span>
              </div>
            </Infotip>}
            {(selectedVendors.size > 0) ? (
              <div className="line-style-new">
                <div className="title-3 top-title">
                  {`Selected Vendors (${selectedVendors.size})`}
                </div>
                <div
                  className="remove-style"
                  onClick={() =>
                    this.removeAllSelectedTypeVendors(vendorType, isGoogleAtp)
                  }
                >
                  Remove All
               </div>
              </div>
            ) : (
                <div className="line-style-new">
                  <div className="title-3 top-title">
                    {`Selected Vendors`}
                  </div>
                </div>
              )}
            <div className="selected-vendors-new">
              {selectedVendors.size ? (
                selectedVendors.map((v, vi) =>
                  (
                    <div key={vi} className="vendor-container flex-row">
                      <div>{v.name}</div>
                      <CloseCircleOutlined
                        theme="filled"
                        onClick={() => this.removeVendor(v.id)}
                      />
                    </div>
                  )
                )
              ) : (
                  <p className="no-selected-vendors">
                    Currently there are no vendors selected. Please add vendor to
                    the list of vendors
                  </p>
                )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
