import React from 'react';
import { Divider} from 'antd';
import { Select, Input } from '../../../../styleguide'
const { Option } = Select;
const { Search } = Input;
let index = 0;
export default class MessageDropDownSearch extends React.Component {
  onSelectMessage = value => {
    let selectedMessage = this.props.options.filter(
      (item, index) => item.get('id') === value
    );
    let message_category_id = '';
    selectedMessage.map((item, index) => {
      message_category_id = item.get('message_category_id');
    });
    this.props.onSelect(value, message_category_id);
  };
  render() {
    const { options, title, onSelect, defaultValue, value } = this.props;

    let messageOptions = [{label: '[unset]', value: '-1'}];
    options.forEach(o => {
      messageOptions.push({label: `${o.get('id')} - ${o.get('description')}`, value: o.get('id')})
    });

    return (
      <div className="select-container">
        <label className="label-style">Select Message</label>
        <Select
          defaultValue={defaultValue}
          value={value}
          onChange={value => this.onSelectMessage(value)}
          showSearch
          optionFilterProp="label"
          options={messageOptions}
        />
      </div>
    );
  }
}
