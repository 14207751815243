import * as types from '../actions/action_types';

import { Map } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

export const initialState = Map({
    zendesk_token: new StoreRecord({ value: "" }),
});

export default (state = initialState, action) => {
    switch(action.type) {
        case types.INTEGRATIONS_ZENDESK_PENDING:
            return setPending(state, ['zendesk_token']);

        case types.INTEGRATIONS_ZENDESK_LOADED:
            return setValue(state, ['zendesk_token'], action.jwt)

        case types.INTEGRATIONS_ZENDESK_ERROR:
            return setError(state, ['zendesk_token'], action.errorMessage && action.errorMessage.response.status);
    }

    return state;
}