import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Table, DatePicker, Radio } from 'antd';
import dayjs from 'dayjs'

import makeColumns from './makeColumns';
import { ChangeLog } from '../../../records/changelog_records';
import errorUnauthorized from './errorUnauthorized';

export const ChangeLogPage = ({
  changeLogs,
  fetchPending,
  accountId,
  getChangeLogs
}) => {
  const lastDay = dayjs().subtract(1, 'days').startOf('day');
  const lastWeek = dayjs().subtract(7, 'days').startOf('day');
  const lastMonth = dayjs().subtract(30, 'days').startOf('day');

  const [radioStatuses, setRadioStatuses] = useState({ yesterday: false, last7: true, last30: false });
  const [datePickerDates, setDatePickerDates] = useState([lastWeek, lastDay]);

  useEffect(() => {
    // getChangeLogs({ account_id: accountId });
  }, [getChangeLogs, accountId]);

  const yesterday = {
    option: 'yesterday',
    times: [lastDay.toDate(), lastDay.toDate()]
  };
  const last7 = {
    option: 'last7',
    times: [lastWeek.toDate(), lastDay.toDate()]
  };
  const last30 = {
    option: 'last30',
    times: [lastMonth.toDate(), lastDay.toDate()]
  };

  return (
    <div className="changelog-container">
      <div className="time-picker">
        <Radio.Group
          disabled={fetchPending}
          onChange={({ target }) => handleRadioSelect(target.value)}
        >
          <Radio.Button name="yesterday" checked={radioStatuses.yesterday} value={yesterday}>1D</Radio.Button>
          <Radio.Button checked={radioStatuses.last7} value={last7}>7D</Radio.Button>
          <Radio.Button checked={radioStatuses.last30} value={last30}>30D</Radio.Button>
        </Radio.Group>
        <DatePicker.RangePicker
          disabled={fetchPending}
          value={datePickerDates}
          onChange={handleDateRangeSelect}
        />
      </div>
      <Table
        dataSource={changeLogs.toJS().map((log) => ({ ...log, key: Math.random() }))}
        columns={makeColumns(changeLogs)}
        pagination={{ pageSize: 20 }}
        loading={fetchPending}
      />
    </div>
  );



  function handleRadioSelect({ option, times: [start, end] }) {
    setRadioStatuses({
      last7: false,
      yesterday: false,
      last30: false,
      [option]: true
    });
    setDatePickerDates([dayjs(start), dayjs(end)]);
    getChangeLogs({ account_id: accountId, start, end })
  }

  function handleDateRangeSelect([start, end]) {
    setRadioStatuses({
      last7: false,
      yesterday: false,
      last30: false,
    });

    setDatePickerDates([start, end]);
    getChangeLogs({
      account_id: accountId,
      start: start.startOf('day').toDate().getTime(),
      end: end.endOf('day').toDate().getTime(),
    });
  }
}

ChangeLogPage.propTypes = {
  changeLogs: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(ChangeLog)),
  fetchPending: PropTypes.bool,
  getChangeLogs: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired
}

export default errorUnauthorized(ChangeLogPage);