import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';
const Option = Select.Option;

export default class extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        initialValue: PropTypes.string.isRequired,
        fieldLabel: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        editing: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        className: ""
    };

    handleChange = (value) => {
      this.props.onChange(value)
    };

    render() {
        return(
            <div className={ this.props.className }>
                <label>{ this.props.fieldLabel }</label>
                  <Select
                    className="message-language-select"
                    size="large"
                    defaultValue={ this.props.initialValue }
                    onChange={ this.handleChange }
                    disabled={ !this.props.editing }
                  >
                      <Option value="en">English</Option>
                      <Option value="de">German</Option>
                      <Option value="fr">French</Option>
                      <Option value="se">Swedish</Option>
                  </Select>
            </div>
        )
    }
}
