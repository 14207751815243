import React, { Component } from 'react';
import { Select, Switch } from 'antd';
import ColorPickerInput from '../../../../../../common/ColorPickerInput.js.jsx';

const { Option } = Select;

class Links extends Component {
  updateLinks = (type, value) => {
    const font = JSON.parse(JSON.stringify(this.props.value));
    font[type] = value;
    this.props.updateSetting(this.props.settingKey, font);
  }
  render() {

    const { value, settingsTitle } = this.props;

    return (
      <div className="four-options">
        <h4>{settingsTitle}</h4>
        <div className="option-inputs">
          <div>
            <Select value={value.fontWeight} onChange={val => this.updateLinks('fontWeight', val)} style={{ width: '100%'}}>
              <Option value="300" style={{fontWeight: '300'}}>Light</Option>
              <Option value="400" style={{fontWeight: '400'}}>Normal</Option>
              <Option value="500" style={{fontWeight: '500'}}>Medium</Option>
              <Option value="600" style={{fontWeight: '600'}}>Semi-bold</Option>
              <Option value="700" style={{fontWeight: '700'}}>Bold</Option>
              <Option value="800" style={{fontWeight: '800'}}>Heavy</Option>
            </Select>
            <h5>Weight</h5>
          </div>
          <div>
            <div style={{ marginBottom: '14px' }}>
              <ColorPickerInput
                value={ value.color }
                onUpdate={(value) => this.updateLinks('color', value)}
                editing={ true }
              />
            </div>
            <h5>Color</h5>
          </div>
          <div style={{ height: '30px', lineHeight: '30px', flex: '0.5' }}>
            <Switch 
              checked={value.textDecoration === 'underline'}
              onChange={(checked) => this.updateLinks('textDecoration', checked ? 'underline' : 'none' )}
              style={{ margin: '4px 0 14px 0' }}
            />
            <h5>Underline?</h5>
          </div>
        </div>
      </div>
    );
  }
}

export default Links;