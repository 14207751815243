import React from "react";

const propertyTooltipNumberContent = (
  <React.Fragment>
    <b>Property - When viewing numbers</b>
    <br />
    <b>Total Volume:</b> The number of visits to the property. <br />
    <b>Messaged Users:</b> The number of users who received a message on the
    view. <br /> <b>Full Consent:</b> The number of views where the
    user has consented to all purposes and vendors.
    <br /> <b>Partial Consent:</b> The number of views where the user was
    consented to some vendors and purposes but not all of the current vendors
    and purposes. <br /> <b>Reject all:</b> The number of views where the
    user had rejected all consent. <br />
  </React.Fragment>
);
export const propertyTooltipPercentageContent = (
  <React.Fragment>
    <b>Property - When viewing percent</b> <br />
    <b>Total Volume:</b> The number of visits to the property. <br />
    <b>Messaged Users:</b> The number of users who received a message on the
    view. <br /> <b>Full Consent:</b> The percentage of total views
    where the user has consented to all purposes and vendors. <br />
    <b>Partial Consent:</b>
    The percentage of total views where the user has consented to some of
    the purposes and vendors but not all of the current vendors and purposes.
    <br /> <b>Reject all:</b> The percentage of total views where the user
    has rejected all purposes and vendors.
  </React.Fragment>
);
export const propertyTooltipContent = (
  <React.Fragment>
    {propertyTooltipNumberContent}
    <br />
    {propertyTooltipPercentageContent}
    <br />
  </React.Fragment>
);
export const propertyTooltipContentV2 = (
  <React.Fragment>
    {propertyTooltipNumberContent}
    <b>Legitimate Interest Objections:</b> The number of views from the
    user who have legitimate interest objections too some purposes. <br />
    <br />
    {propertyTooltipPercentageContent}
    <br />
    <b>Legitimate Interest Objections:</b> The percent of total views where
    the users have legitimate interest objections too some purposes. <br />
  </React.Fragment>
);
export const messageTooltipNumberContent = (
  <React.Fragment>
    <b>Messages - When viewing numbers</b> <br /> <b>Messaged Users: </b>
    The number of users who were messaged on the property.
    <br />
    <b>Consent all:</b> The number of users who consented to all vendors and
    purposes after seeing a message.
    <br /> <b>Consent some:</b> The number of users who consented to some of the
    purposes and vendors after seeing a consent message (likely by making
    changes in the Privacy Manager). <br /> <b>No Consent:</b> The number of
    users who did not express a consent preference after seeing a consent
    message (May not have returned to the site). <br />
    <b>Reject all:</b> The number of users who rejected all vendors and purposes
    after seeing a consent message. <br />
  </React.Fragment>
);
export const messageTooltipPercentageContent = (
  <React.Fragment>
    <b>Messages - When viewing percentage </b>
    <br />
    <b>Messaged Users:</b> The number of users who were messaged on the
    property. <br /> <b>Consent all:</b> The percentage of users who consented
    to all vendors and purposes after seeing a message. <br />
    <b>Consent some:</b> The number of users who consented to some of the
    purposes and vendors after seeing a consent message (likely by making
    changes in the Privacy Manager). <br />
    <b>No Consent:</b> The number of users who did not express a consent
    preference after seeing a consent message (May not have returned to the
    site).
    <br /> <b>Reject all:</b> The percentage of users who rejected all vendors
    and purposes after seeing a consent message.
  </React.Fragment>
);
export const messageTooltipContent = (
  <React.Fragment>
    {messageTooltipNumberContent}
    <br />
    {messageTooltipPercentageContent}
  </React.Fragment>
);
export const messageTooltipContentV2 = (
  <React.Fragment>
    {messageTooltipNumberContent}
    <b>Legitimate Interest Objections:</b> The number of users objecting to some
    purpose with legal basis of legitimate interest after seeing the message.
    <br />
    <br />
    {messageTooltipPercentageContent}
    <br /> <b>Legitimate Interest Objections:</b> The percentage of users
    objecting to some purpose with legal basis of legitimate interest after
    seeing the message.
  </React.Fragment>
);
export const vendorsTooltipContent = (
  <React.Fragment>
    <b>Vendor</b> <br />
    <b>Consent Rate:</b> The percentage of views for which the user had
    consented to this vendor. Low percentages may indicate the vendor was
    recently added to the vendor list for the property or users are explicitly
    rejecting this vendor at a high rate.
    <br/><b>Unconsented Rate:</b> The percentage of views for which the user had not
    registered a consent preference. A high rate may indicate that the vendor
    was recently added to the vendor list for the property users are explicitly
    rejecting this vendor at a high rate.
    <br /> <b>Consented Views:</b> The number of views from users who had given
    consent for processing by this vendor. <br />
  </React.Fragment>
);
export const vendorsTooltipContentV2 = (
  <React.Fragment>
    <b>Vendor</b> <br />
    <b>Consent Rate:</b> The percentage of views for which the user had
    consented to this vendor. Low percentages may indicate the vendor was
    recently added to the vendor list for the property or users are explicitly
    rejecting this vendor at a high rate. <br />
    <b>Unconsented Rate:</b> The percentage of views for which the user had not
    registered a consent preference. A high rate may indicate that the vendor
    was recently added to the vendor list for the property users are explicitly
    rejecting this vendor at a high rate.
    <br /> <b>Consented Views:</b> The number of views from users who had given
    consent for processing by this vendor. <br />
    <b>Unconsented Views:</b> The number of views from users who have not given consent for processing by this vendor.
  </React.Fragment>
);
export const purposesTooltipContent = (
  <React.Fragment>
    <b>Purpose</b> <br />
    <b>Consent Rate:</b> The percentage of views for which the user had
    consented to this Purpose. Low percentages may indicate the Purpose was
    recently added to the notifications for the property users are explicitly
    rejecting this purpose at a high rate.
    <br/><b>Unconsented Rate:</b> The percentage of views for which the user had not
    registered a consent preference for this purpose. A high rate may indicate
    that the purpose was recently added to notifications for the property or
    users are explicitly rejecting this purpose at a high rate.
    <br /> <b>Consented Views:</b> The number of views from users who had given
    consent for processing for this purpose.
 </React.Fragment>
);
export const purposesTooltipContentV2 = (
  <React.Fragment>
    <b>Purpose</b> <br />
    <b>Consent Rate:</b> The percentage of views for which the user had
    consented to this Purpose. Low percentages may indicate the Purpose was
    recently added to the notifications for the property users are explicitly
    rejecting this purpose at a high rate. <br />
    <b>Unconsented Rate:</b> The percentage of views for which the user had not
    registered a consent preference for this purpose. A high rate may indicate
    that the purpose was recently added to notifications for the property or
    users are explicitly rejecting this purpose at a high rate.
    <br /> <b>Consented Views:</b> The number of views from users who had given
    consent for processing for this purpose.
    <br/><b>Unconsented Views:</b> The number of views from users who have not given consent for processing for this purpose.
    <br/><b>Legitimate Interest Objection Rate:</b> The percentage of views from users who objected to processing of the purpose under the Legitimate Interest legal basis.
 </React.Fragment>
);
export const specialPurposesTooltipContent = (
  <React.Fragment>
    <b>Special Features</b> <br />
    <b>Consent Rate:</b> The percentage of views for which the user had
    consented to this Special Feature. Low percentages may indicate the Special
    Feature was recently added to the notifications for the property users are
    explicitly rejecting this Special Feature at a high rate. <br />
    <b>Unconsented Rate:</b> The percentage of views for which the user had not
    registered a consent preference for this Special Feature. A high rate may
    indicate that the Special Feature was recently added to notifications for
    the property or users are explicitly rejecting this Special Feature at a
    high rate.
    <br /> <b>Consented Views:</b> The number of views from users who had given
    consent for processing for this Special Feature.
    <br /> <b>Unconsented Views:</b> The number of views from users who had not given
    consent for processing for this Special Feature.
  </React.Fragment>
);
export const tabTooltipContent = (
  <React.Fragment>
    <b>Properties</b>
    <br /> These metrics relate to visits and how many views there
    were from users who were either fully consented, partially consented, no
    consent, or had rejected consent. Use these metrics to see how particular
    properties are performing or to compare consented views across
    properties.
    <br /> <b>Messages</b>
    <br /> These metrics relate to the actions that the user has taken after
    seeing a message: Did they consent to all purposes and vendors, consent to
    some, not provide any consent, or reject consent to all purposes and
    vendors. User these metrics to see how well messages are helping you get get
    consent from a user, or compare how different messages are performing.
    <br /> <b>Vendors/Purposes</b>
    <br /> These metrics relates to views where a particular purpose or
    vendors is consented to by the user. Use these metrics to see if there are
    problems getting and maintaining consent for a particular vendor or purpose.
  </React.Fragment>
);

export const tabTooltipContentV2 = (
  <React.Fragment>
    {tabTooltipContent}
    <br /> <b>Special Features</b>
    <br /> These metrics relate to the views where a particular Special Feature
    has been consented to by the user. Use these metrics to determine if there
    are problems getting and maintaining consent for a particular Special
    Feature.
  </React.Fragment>
);
