import React, { useEffect, useState } from 'react';
import { usePrevious } from '../../../../../hooks';
import { List } from 'immutable';

import { Select, Checkbox, Alert } from '../../../../../styleguide';
import { Collapse, Tag, Transfer, Radio} from 'antd'
import { WarningFilled } from '@ant-design/icons';
const Option = Select.Option;
const { Panel } = Collapse;

import { GEO_FILTER_LIST_DETAILS } from '../../../../../constants';
import { addStack } from './helper';

function IabSettings(props) {
  const { vendorList, iabSpecialFeatures, updateVendorList, readOnly, iabStacks, iabPurposes } = props;
  const addedIabStacks = vendorList.categories
    .filter((c) => c.type === 'IAB_STACK')
    .map((c) => {
      const stackIabId = iabStacks.find(s => s.iabStackRef == c.iabStackRef).iabId;
      return c.iabId || stackIabId;
    })
    .toJS();
  const [selectedStackKeys, updateSelectedStackKeys] = useState(addedIabStacks);
  const previousSelectedStackKeys = usePrevious(selectedStackKeys) ?? [];

  useEffect(()=>{
    if(selectedStackKeys.includes(1)) {
      updateVendorList(vendorList.set('iabSpecialFeatures', iabSpecialFeatures.map(feature => feature.id)))
    }
  }, [selectedStackKeys]);

  useEffect(()=>{
    if(selectedStackKeys.includes(1)) {
      updateVendorList(vendorList.set('iabSpecialFeatures', iabSpecialFeatures.map(feature => feature.id)))
    }
  }, [selectedStackKeys]);

  useEffect(()=>{
    if(!previousSelectedStackKeys.includes(1) && selectedStackKeys.includes(1)) {
      updateVendorList(vendorList.set('iabSpecialFeatures', iabSpecialFeatures.map(feature => feature.id)))
    } else if(previousSelectedStackKeys.includes(1) && !selectedStackKeys.includes(1)) {
      updateVendorList(vendorList.set('iabSpecialFeatures', List([])))
    }
  }, [selectedStackKeys]);

  const onChangeLegalBasis = (e) => {
    const updatedVendorList = vendorList.set('defaultLegalBasis', e.target.value);
    updateVendorList(updatedVendorList);
  };

  const toggleFeature = (checked, featureid) => {
    const features = vendorList.iabSpecialFeatures;
    let updatedFeatures;
    if (checked) {
      updatedFeatures = features.push(featureid);
    } else {
      if(selectedStackKeys.includes(1)) return; // if stack 1 is selected, iab special features are mandatory 
      updatedFeatures = features.filterNot((id) => id === featureid);
    }
    const updatedVendorList = vendorList.set('iabSpecialFeatures', updatedFeatures);
    updateVendorList(updatedVendorList);
  };

  const toggleUseSpecialTreatmentP1 = (e) => {
    const updatedVendorList = vendorList.set('useSpecialTreatmentP1', e.target.checked);
    updateVendorList(updatedVendorList);
  };

  const toggleSpecPurpFeat = (e) => {
    const updatedVendorList = vendorList.set('displaySpecialPurposesNFeatures', e.target.checked);
    updateVendorList(updatedVendorList);
  };

  const toggleCustomElementsFirst = (e) => {
    const updatedVendorList = vendorList.set('customElementsFirst', e.target.checked);
    updateVendorList(updatedVendorList);
  };

  const toggleSetEuconsentCookie = (e) => {
    const updatedVendorList = vendorList.set('setEuconsentCookie', e.target.checked);
    updateVendorList(updatedVendorList);
  };

  const setP1Geos = (selected) => {
    const updatedVendorList = vendorList.set('specialTreatmentP1Geo', List(selected));
    updateVendorList(updatedVendorList);
  };

  const renderIabStacks = (stack) => {
    return (
      <div>
        {stack.name}
        <div className="purposes-container">
          {stack.categories.map((c, ci) => {
            return <Tag key={ci}>{c.name}</Tag>;
          })}
        </div>
      </div>
    );
  };

  const updateCategories = (categories) => {
    const updatedVendorList = vendorList.set('categories', categories);
    updateVendorList(updatedVendorList);
  };

  const removeStack = (stacks) => {
    let updatedVendorList = vendorList;
    stacks.map(stack => {
      updatedVendorList = updatedVendorList.set('categories', updatedVendorList.categories.filterNot((c) => c.name === stack.name).concat(stack.categories));
    });
    updateVendorList(updatedVendorList);
  };

  const handleStacks = (nextTargetKeys, direction, moveKeys) => {
    let updatedVendorList = vendorList;
    if (direction === 'right') {
      const updatedCategoriesWithNewStack = moveKeys.map((stackIabId) => {
        const stack = iabStacks.find((s) => s.iabId === stackIabId);
        const categoriesWithNewStack = addStack(updatedVendorList, stack, iabPurposes);
        if (categoriesWithNewStack) {
          updatedVendorList = vendorList.set('categories', categoriesWithNewStack);
        }
        return categoriesWithNewStack;
      });
      const isConflict = updatedCategoriesWithNewStack.some(catgs => catgs == undefined);
      if(!isConflict) {
        updateCategories(updatedCategoriesWithNewStack[updatedCategoriesWithNewStack.length - 1]);
        updateSelectedStackKeys(nextTargetKeys);
      }
    } else {
      const stacks = moveKeys.map((stackIabId) => {
       return iabStacks.find((s) => s.iabId === stackIabId);
      });
      removeStack(stacks);
      updateSelectedStackKeys(nextTargetKeys);
    }
  };

  const renderHeader = [
    ({ selectedCount, totalCount }) => <span>Stacks Available for Selection ({totalCount})</span>,
    ({ selectedCount, totalCount }) => <span>Selected Stacks ({totalCount})</span>,
  ];

  const geoList = GEO_FILTER_LIST_DETAILS.filterNot((geo) => geo.code === 'NA' || geo.code === 'CA' || geo.type == 'province');
  
  let stackSelectedNotice = 'No IAB stacks are added';
  const selectedStacksCount = selectedStackKeys.length;
  if(selectedStacksCount) {
    stackSelectedNotice = `${selectedStacksCount} IAB Stack${selectedStacksCount == 1 ? '' : 's'} ${selectedStacksCount == 1 ? 'is' : 'are'} added`;
  }

  return (
    <div className="iab-settings-wrapper">
      <span className="legal-basis-header">Vendor Legal Basis</span>
      <span className="legal-basis-info">
        Where vendors have selected a flexible legal basis for a particular purpose, you can restrict the legal basis to
        "Consent" or "Legitimate Interest"
      </span>
      <Radio.Group buttonStyle="solid" value={vendorList.defaultLegalBasis} onChange={onChangeLegalBasis}>
        {/* <Radio value={1}>Vendor Defaults</Radio> */}
        <Radio key="CONSENT" value="CONSENT">Consent</Radio>
        <Radio key="LEGITIMATE_INTEREST" value="LEGITIMATE_INTEREST">Legitimate Interest</Radio>
      </Radio.Group>
      <span className="legal-basis-note">
        <b>Note:</b> Selecting "Consent" or "Legitimate Interest" will create a longer consent string. You may wish to
        apply purpose restrictions for specific purposes in the "Advanced Settings" section or for specific vendors only
        in the vendor setting panel which will be available once you have gone through all the steps of the wizard.
      </span>

      <Collapse bordered={false} ghost>
        <Panel header="Add IAB Special Features" key="1">
          {iabSpecialFeatures.map((f) => (
            <div key={f.id} className="purpose-container">
              <Checkbox onChange={({ target : {checked}}) => toggleFeature(checked, f.id)} checked={vendorList.iabSpecialFeatures.includes(f.id)} />
              <div className="feature">
                <div className="title">{f.name}</div>
                <div className="description">{f.description} </div>
              </div>
            </div>
          ))}
          {selectedStackKeys.includes(1) ? (
            <Alert
              showIcon={true}
              type="warning"
              message="Stack one has been chosen and has special features on by default. In order to remove special features, please pick a different stack."
              icon={<WarningFilled />}
            />
          ) : null}
        </Panel>
        <Panel header={`Manage IAB Stacks (${stackSelectedNotice})`} key="2">
          <div className="iab-stacks-info">
            IAB stacks that can be added to your list (no overlapping purposes with stacks in your list)
          </div>
          <div className="iab-stacks-note">
            Note: You will not be able to add some of the IAB stacks from available section (left panel) because one or
            more IAB purposes already exist in the selected stacks (right panel).
          </div>
          <Transfer
            dataSource={iabStacks.toJS()}
            showSearch
            showSelectAll={false}
            targetKeys={selectedStackKeys}
            onChange={handleStacks}
            render={renderIabStacks}
            rowKey={(record) => record.iabId}
            selectAllLabels={renderHeader}
            filterOption={(searchValue, item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1}
            locale={{
              notFoundContent: 'No stack',
              searchPlaceholder: 'Search',
              filter: null,
            }}
          />
        </Panel>
        <Panel className="iab-settings-panel" header="Advanced IAB Settings" key="3">
          <div key='spl-treatment' className="iab-settings-row">
            <Checkbox
              checked={vendorList.useSpecialTreatmentP1}
              onChange={toggleUseSpecialTreatmentP1}
              disabled={readOnly}
            >
              Use Special Treatment for Purpose One in Countries
            </Checkbox>
            <div>
              <Select
                className="country-select"
                mode="multiple"
                onChange={setP1Geos}
                tokenSeparators={[',']}
                value={vendorList.useSpecialTreatmentP1 ? vendorList.specialTreatmentP1Geo.toJS() : []}
                disabled={!vendorList.useSpecialTreatmentP1}
                filterOption
                optionFilterProp="searchValue"
              >
                {geoList.map((country) => {
                  return (
                    <Option key={country.code} searchValue={country.name}>
                      {country.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div key='spl-purposes' className="iab-settings-row">
            <Checkbox
              checked={vendorList.displaySpecialPurposesNFeatures}
              disabled={readOnly}
              onChange={toggleSpecPurpFeat}
            >
              Display Special Purposes, Features, and Discloure Only Vendors in Message
            </Checkbox>
          </div>
          <div key='purpose1-cutm-ele' className="iab-settings-row">
            <Checkbox checked={vendorList.customElementsFirst} onChange={toggleCustomElementsFirst} disabled={readOnly}>
              List IAB Purpose 1 and Custom Elements first in the message
            </Checkbox>
          </div>
          <div key='store-cookie' className="iab-settings-row">
            <Checkbox checked={vendorList.setEuconsentCookie} onChange={toggleSetEuconsentCookie} disabled={readOnly}>
              Store euconsent-v2 first party cookie
            </Checkbox>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default IabSettings;
