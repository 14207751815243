import React, { Component } from 'react';
import { Tabs as AntdTabs } from 'antd';

class Tabs extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'spsg-tabs ' + (props.className || '');

    if (props.genre) {
      props.className += ' spsg-' + props.genre;
      delete props.genre;
    }

    return <AntdTabs {...props} />;
  }
}

class TabsPane extends Component {
  render() {
    const props = { ...this.props };
    props.className = 'spsg-tabs-pane ' + (props.className || '');

    return <AntdTabs.TabPane {...props} />;
  }
}

Tabs.TabPane = TabsPane;
export default Tabs;
