import React, { useState } from "react";
import { Button, Input, Popconfirm, SelectDropdown } from "../index.js";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import SVG from "react-inlinesvg";
import cancelIcon from "../../assets/icons/cross.svg";
import saveIcon from "../../assets/icons/check.svg";
import { renderTruncatedTags, wrapInInfotip } from "../../components/consent/us_privacy_regulation/helper.js";
import classNames from "classnames";

const AddEditDelete = (props) => {
  const [editMode, setEditMode] = useState(props.openInEditMode);
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);
  const [value, setValue] = useState(props.value ?? "");
  const [error, setError] = useState(null);

  const handleCancel = () => {
    setValue(props.value);
    setEditMode(false);
    if(props.handleCancelEdit) {
      props.handleCancelEdit();
    }
  };

  const handleDelete = () => {
    props.handleSave(null);
    setValue(null);
    setEditMode(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const hanldeAdd = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    const error = props.validate(value);
    if (!Boolean(error)) {
      props.handleSave(value);
      setError(null);
      setEditMode(false);
    } else {
      setError(error);
    }
  };

  let btnGroupClasses = {
    editMode: false,
    savedValueMode: false,
    addValueMode: false
  }
  if (editMode) {
    btnGroupClasses.editMode = true;
  } else if (value) {
    btnGroupClasses.savedValueMode = true;
  } else {
    btnGroupClasses.addValueMode = true;
  }

  const buttonGroup = (
    <>
      <div className={classNames("btn-group", btnGroupClasses.editMode ? null : "hide")}>
        <Button
          className="icon nobackground"
          type="tertiary"
          onClick={handleSave}
        >
          <SVG src={saveIcon} />
        </Button>
        <Button
          className="icon nobackground"
          type="tertiary"
          onClick={handleCancel}
        >
          <SVG src={cancelIcon} />
        </Button>
      </div>
      <div className={classNames("btn-group", btnGroupClasses.savedValueMode ? null : "hide")}>
        <Button type="primary" onClick={handleEdit} className="icon">
          {wrapInInfotip(<EditFilled/>, "Edit")}
        </Button>
        <Popconfirm
          title={`Are you sure remove the ${props.entityName}?`}
          open={deletePopConfirmVisible}
          trigger="click"
          icon={null}
          onConfirm={handleDelete}
          onCancel={() => setDeletePopConfirmVisible(false)}
          onOpenChange={(visible) => setDeletePopConfirmVisible(visible)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" className="icon">
            {wrapInInfotip(<DeleteFilled />, "Delete")}
          </Button>
        </Popconfirm>
      </div>
      <div className={classNames("btn-group", btnGroupClasses.addValueMode ? null : "hide")}>
        <Button type="primary" onClick={hanldeAdd}>
          Add +
        </Button>
      </div>
    </>
  );

  const suffixNode = !(editMode || value) ? props.suffixNode : null;

  const inputControl = props.dropdownInput ? (
    editMode ?
    <SelectDropdown 
      // className={"opt-in-select"}
      // dropdownClassName={"opt-in-dropdown"}
      value={value}
      disabled={!editMode}
      options={props.dropdownOptions}
      onChange={(value) => setValue(value)}
      selectAll
      multiple
      entityName={props.entityName}
      error={error}
      directUpdate
    />
    : renderTruncatedTags(value?.map(v => props.dropdownOptions.find(option => option.value == v)?.label) ?? [])
  ) : (
    !editMode && props.plainText ? <div className="saved-value-div">{value}</div> :
    <Input
      error={error}
      value={value}
      disabled={!editMode}
      onChange={({ target: { value } }) => setValue(value)}
    />
  );

  return (
    <>
      <div className={classNames("add-edit-delete-component", props.spaceBetween ? 'space-between' : null )}>
        {editMode || value ? inputControl : null}
        {buttonGroup}
      </div>
      {suffixNode}
    </>
  );
};

export default AddEditDelete;
