import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';

const Tooltip = (incomingProps) => {
  const props = {...incomingProps};
  props.overlayClassName = 'spsg-tooltip ' + (props.className || '');
  
  return <AntdTooltip {...props} />;
};

export default Tooltip;
