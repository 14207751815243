import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { EllipsisOutlined, WarningOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Button } from 'antd';
import { VendorList } from '../../../records/vendor_list_records';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import DismissErrorModal from '../../common/DismissErrorModal.jsx';
import { displayErrors } from '../helper';
import IsActive from '../../common/IsActive';
import Loading from '../../common/Loading.js.jsx';
import { CopyFilled, DeleteFilled } from '@ant-design/icons';
import CloneModal from '../../common/CloneModal';
import { message } from '../../../styleguide';

export default class VendorListRow extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    handleDelete: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    clearVendorListError: PropTypes.func.isRequired,
    cloneVendorList: PropTypes.func.isRequired,
    v2: PropTypes.bool.isRequired,
    getVlNameError: PropTypes.func.isRequired
  }

  state = {
    warningMessagePresent: false,
    cloneVlModalOpen: false,
  };

  handleEdit = () => {
    browserHistory.push(`/us_privacy/vendor_lists/edit?id=` + this.props.vendorList.id);
  };

  handleDelete = (e) => {
    //get rid of then
    e.stopPropagation();
    this.props.handleDelete(this.props.accountId, this.props.vendorList.id).then((resp) => {
      if (resp.error) {
        this.handleServerSideErrors(resp.error);
      } else {
        message.success(<>Vendor List <b>{this.props.vendorList.name}</b> was successfully deleted</>)
      }
      this.setState({ warningMessagePresent: false });
    });
  }

  handleServerSideErrors = (errors) => {
    // at the moment only expecting PRIVACY_MANAGERS_ASSOCIATED type of error
    const errorMessages = errors.map(err => err.msg);

    Modal.warning({
      title: 'Vendor list cannot be deleted',
      content: displayErrors(errorMessages),
      className: 'save-warning',
      onOk: () => { this.props.clearVendorListError(); },
    });
  }

  toggleWarningMessage = (e) => {
    e.stopPropagation();
    this.setState({ warningMessagePresent: !this.state.warningMessagePresent });
  }

  handleCopy = (e) => {
    e.stopPropagation();
    this.setState({cloneVlModalOpen : true})
  }

  cloneVl = (newVlName) => {
    this.props.cloneVendorList(this.props.accountId, this.props.vendorList.id, newVlName).then((vendorList) => {
      if(!!this.props.vendorListError) {
        this.handleServerSideErrors(this.props.vendorListError);
      } else {
        message.success(<>Vendor List <b>{this.props.vendorList.name}</b> has been successfully cloned</>)
      }
    });
    this.setState({cloneVlModalOpen : false})
  }

  onDelete = (e) => {
    e.stopPropagation();
    this.toggleWarningMessage(e);
  }

  render() {

    const headingMessage = <>Once deleted, all associated data with <b>{this.props.vendorList.name}</b> will be deleted permanently and cannot be retrieved at all.</>;
    const errorNote = <> Are you sure you want to <b>DELETE</b> ?</>;
    const okText = "Delete";
    const cancelText = "Cancel";

    const errorContent = (
      <React.Fragment>
        <div className="error-wrapper">
          <WarningOutlined />
          <div className="message-block">
            {headingMessage}
            <div className="note">{errorNote}</div>
          </div>
        </div>
      </React.Fragment>
    );

    let warningMessage;
    if (this.state.warningMessagePresent) {
       warningMessage = <DismissErrorModal
       modalWrapperClass="operation-confirmation"
       title={"Vendor List - Delete"}
       error={errorContent}
       isModalVisible={this.state.warningMessagePresent}
       handleCancel={this.toggleWarningMessage}
       renderOk
       okText={okText}
       cancelText={cancelText}
       handleOk={this.handleDelete}
       primaryAction="submit"
     />
    }

    const activeStatus = (
      <IsActive
        isActive={ this.props.vendorList.isActive }
      />
    );

    let loading;
    if (this.props.pending) {
      loading = <Loading />;
    }

    return (
      <div
        className='vendor-list-container'
        onClick={ !this.state.cloneVlModalOpen && !warningMessage ? this.handleEdit : null}
      >
        { loading }
        { warningMessage }
        { this.state.cloneVlModalOpen
        ? <CloneModal 
            cloneMethod={this.cloneVl}
            name={this.props.vendorList.name}
            closeModal={ () => this.setState({cloneVlModalOpen: false})}
            getNameError={this.props.getVlNameError}
            entity={"Vendor List"}
            entityType={"Inactive"}
          />
        : null }
        <div className='name-container pm'>
          <p className='name'>{ this.props.vendorList.name }</p>
          { activeStatus }
        </div>
        {!this.props.readOnly ? (
          <div className="menu-container">
            <div className={"edit"} onClick={this.handleCopy}>
              <CopyFilled rotate={180} />
              <>Clone</>
            </div>
            <div className={"edit"} onClick={this.onDelete}>
              <DeleteFilled />
              <>Delete</>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
