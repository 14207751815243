import PropTypes from 'prop-types';
import React from 'react';
import StepSatisfyOption from './StepSatisfyOption';
import { ClientIPGate } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js'

class StepClientIPGate extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    data: PropTypes.instanceOf(ClientIPGate).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (this.props.editing) (this.refs.IPList).focus();
  }

  handleChange (newData) {
    this.props.onChange(this.props.data.merge(newData));
  }

  render() {
    var continueOnSatisfy = this.props.data.continue ? 'Yes ': 'No';

    if (!this.props.editing) {
      return (<div>IPs: { this.props.data.ip_list }, Continue if Client IP in List: { continueOnSatisfy }</div>);
    } else {
      return (
        <div className="step continue-halt">

          <label>IPs:</label>
          <input ref="IPList" type="text" value={ this.props.data.ip_list } onChange={ (e) => this.handleChange({ ip_list: e.target.value }) } />

          <label>Continue if Client IP in List:
          <StepSatisfyOption
            value={ this.props.data.continue }
            onChange={ (continueOnSatisfy) => this.handleChange({ continue: continueOnSatisfy }) }
          />
          </label>
        </div>
      );
    }
  }
}
export default StepClientIPGate;
