import React from "react";

export const tabTooltipContent = (
  <React.Fragment>
    <b>Page Views Status</b>
    <br/>Compare views across properties and how particular properties are performing in terms of monetization. See total views and number of views from users who either fully opted in, are opted in for legitimate interest only, previously consented to all – but to an older vendor list, rejected all, or have another status.
    <br /><b>Unique Users Status</b><br />
    Understand the composition of users who have recently visited your properties in the selected time period.
    <br /><b>Messaged Users Status</b><br/>
    Compare how well messages are getting users to the desired state by tracking a user's actions after seeing a message. Did the user fully opt-in to all purposes and vendors, allow legitimate interest only, reject all processing, fail to take any action (bounce) or take some other action?
    {/* <br /><b>Vendors/Purposes</b><br/>
    View which purpose or vendor has been consented by the user and if there are problems receiving and maintaining consent for either vendor or purpose.
    <br /><b>Special Features</b><br/>
    Determine if there are problems receiving and maintaining consent for any special features you have configured. */}
  </React.Fragment>
);

export const messageTooltipContent = (
  <React.Fragment>
    <b>Messaged Users Status</b><br/>
    For the property(ies) and time period selected<br/>
    <b>Total Users : </b> Number of users who visited (when viewing by numbers only)<br/>
    <b>Messaged Users:</b> Count of total unique users within the configured time period who received a message.<br/>
    <b>Accept All : </b> Count of total unique users within the configured time period who received a message and then accepted all vendors and purposes.<br/>
    <b>Reject All : </b> Count of total unique users within the configured time period who received a message and then rejected all vendors and purposes.<br/>
    <b>Legitimate Interest Only : </b> Count of total unique users within the configured time period who received a message and then clicked “save and exit” on the privacy manager if you have legitimate interest as a legal basis for at least one of your purposes.<br/>
    <b>Bounces : </b> Views from users that came to the site, saw a message, but did not make an action or revisit the site in that day.<br/>
    <b>All other choices : </b> Count of total unique users within the configured time period who received a message and then made choices on the privacy manager that do not fall into the categories “accept all”, “reject all”, “previously accepted all” or “legitimate interest only”.<br/>
    <b>No Action : </b> Count of total unique users within the configured time period who received a message and then took no action.<br/>
  </React.Fragment>
);

export const viewsStatusTooltipContent = (
  <React.Fragment>
    <b>Page Views Status</b><br/>
    For the property(ies) and time period selected<br/>
    <b>Total : </b> Count of total page views.<br/>
    <b>Accept All : </b> Count of total page views across users who have accepted all purposes and vendors on the most recent version of the vendor list.<br/>
    <b>Reject All : </b> Count of total page views across users who have rejected all purposes and vendors across any version of the vendor list.<br/>
    <b>Legitimate Interest Only : </b> Count of total page views across users who have clicked “save and exit” across any version of the vendor list if you have legitimate interest as a legal basis for at least one of your purposes.<br/>
    <b>Previous Accept All : </b> Count of total page views across users who have accepted all purposes and vendors on a previous version of the vendor list. These users may be eligible to receive a re-consent experience.<br/>
    <b>All other choices : </b> Count of total page views across users who have made choices on the privacy manager that do not fall into the categories “accept all”, “reject all”, “previously accepted all” or “legitimate interest only” on any version of the vendor list.<br/>
    <b>No choices made : </b> Count of total page views across users who have not made any choices on the consent banner on any version of the vendor list.<br/>
  </React.Fragment>
);

export const userStatusTooltipContent = (
  <React.Fragment>
    <b>Unique Users Status</b><br/>
    For the property(ies) and time period selected<br/>
    <b>Total : </b> Count of total unique users. <br/>
    <b>Accept All : </b> Count of total unique users who have accepted all purposes and vendors on the most recent version of the vendor list.<br/>
    <b>Reject All : </b> Count of total unique users who have rejected all purposes and vendors across any version of the vendor list.<br/>
    <b>Legitimate Interest Only : </b> Count of total unique users who have clicked “save and exit” across any version of the vendor list if you have legitimate interest as a legal basis for at least one of your purposes.<br/>
    <b>Previous Accept All : </b> Count of total unique users who have accepted all purposes and vendors on a previous version of the vendor list. These users may be eligible to receive a re-consent experience.<br/>
    <b>All other choices : </b> Count of total unique users who have made choices on the privacy manager that do not fall into the categories “accept all”, “reject all”, “previously accepted all” or “legitimate interest only” on any version of the vendor list.<br/>
    <b>No choices made : </b> Count of total unique users who have not made any choices on the consent banner on any version of the vendor list.<br/>
  </React.Fragment>
);

export const vendorTooltipContent = (
  <React.Fragment>
    <b>Vendors</b><br/>
    For the property(ies) and time period selected<br/>
    <b>Total Views:</b> The number of views where the vendor was part of the vendor list.<br/>
    <b>Full Opt-in (“absolute” number):</b> The number of views where the user has fully opted -in to the vendor.<br/>
    <b>Full Opt-in (percentage):</b> The percentage of total views where the user has fully opted-in to the vendor.<br/>
    <b>Legitimate Interest Only (“absolute” number):</b> The number of views where the user has not given consent but allowed processing based on legitimate interest for the vendor. This would typically happen when the user accesses the Privacy Manager and saves the default selection.<br/>
    <b>Legitimate Interest Only (percentage):</b> The percentage of total views where the user has not given consent for the vendor but allowed processing based on legitimate interest for the vendor. This would typically happen when the user accesses the Privacy Manager and saves the default selection.<br/>
    <b>Reject All (“absolute” number):</b> The number of views where the user has rejected data processing for the vendor for both consent and legitimate interest legal bases.<br/>
    <b>Reject All (percentage):</b> The percentage of views where the user has rejected data processing for the vendor for both consent and legitimate interest legal bases.<br/>
   </React.Fragment>
);

export const purposesTooltipContent = (
  <React.Fragment>
    <b>Purposes</b><br/>
    For the property(ies) and time period selected<br/>
    <b>Total Views:</b> The number of views where the purpose was part of the consent preference selection.<br/>
    <b>Full Opt-in (“absolute” number):</b> The number of views where the user has fully opted into the purpose.<br/>
    <b>Full Opt-in (percentage):</b> The percentage of total views where the user has fully opted into the purpose.<br/>
    <b>Legitimate Interest Only (“absolute” number):</b> The number of views where the user has not given consent but allowed processing based on legitimate interest for the purpose. This would typically happen when the user accesses the Privacy Manager and saves the default selection.<br/>
    <b>Legitimate Interest Only (percentage):</b> The percentage of total views where the user has not given consent for the purpose but allowed processing based on legitimate interest for the purpose. This would typically happen when the user accesses the Privacy Manager and saves the default selection.<br/>
    <b>Reject All (“absolute” number):</b> The number of views where the user has rejected data processing for the purpose for both consent and legitimate interest legal bases.<br/>
    <b>Reject All (percentage):</b> The percentage of views where the user has rejected data processing for the purpose for both consent and legitimate interest legal bases.<br/>
   </React.Fragment>
);

export const specialFeaturesTooltipContent = (
  <React.Fragment>
    <b>Special Features</b><br/>
    For the property(ies) and time period selected<br/>
    <b>Total Views:</b> The number of views where the special feature was part of the consent preference selection.<br/>
    <b>Full Opt-in (“absolute” number):</b> The number of views where the user has fully opted into the special feature.<br/>
    <b>Full Opt-in (percentage):</b> The percentage of total views where the user has fully opted into the special feature.<br/>
    <b>Legitimate Interest Only (“absolute” number):</b> The number of views where the user has not given consent but allowed processing based on legitimate interest for the special feature. This would typically happen when the user accesses the Privacy Manager and saves the default selection.<br/>
    <b>Legitimate Interest Only (percentage):</b> The percentage of total views where the user has not given consent for the purpose but allowed processing based on legitimate interest for the special feature. This would typically happen when the user accesses the Privacy Manager and saves the default selection.<br/>
    <b>Reject All (“absolute” number):</b> The number of views where the user has rejected data processing for the special feature for both consent and legitimate interest legal bases.<br/>
    <b>Reject All (percentage):</b> The percentage of views where the user has rejected data processing for the special feature for both consent and legitimate interest legal bases.<br/>
  </React.Fragment>
);
