import React, { Component } from 'react';
import { Modal, Input } from '../../../../../../styleguide';
import _ from 'lodash';

import { publicComponents, privateComponentsList } from './componentsList';

class AddComponent extends Component {
  state = { term: '' }

  addToNotice = ({ title: componentName }) => {
    this.props.addToNotice({ componentName });
    this.props.handleCancel();
  };

  addRequiredToNotice = ({
    title: componentName,
    schema,
    configuration = {},
  }) => {
    this.props.addToNotice({
      componentName,
      opts: {
        ...schema,
        ...configuration,
        settings: {
          ...schema.settings,
          ...configuration.settings,
        }
      }
    });
    this.props.handleCancel();
  }

  makeGridItem = (component, i, onClick) => (
    <div
      key={component.title}
      className="component-item"
      key={'comp-s-' + i}
      onClick={() => onClick(component)}
    >
      <div className={`frame ${component.added ? 'added' : ''}`}>
        <div className="ci-icon">{component.icon}</div>
        <div className="ci-title">{component.presentationalTitle || component.title}</div>
      </div>
    </div>
  );

  gatherType = (isAdblock) => {
    const { current } = this.props;
    const type = current && current.type ? current.type : null;
    if (isAdblock && type === 'Notice') return 'Adblock';
    return type;
  }

  gatherGrandparents = (isAdblock) => {
    const grandparents = (this.props.grandparents || []).map(g => {
      if (g === 'Notice' && isAdblock) return 'Adblock';
      return g;
    });
    return grandparents;
  }

  render() {
    const isAdblock = this.props.messageCategory === 'adblock' && this.props.messageSubcategory === 'notice' ? true : false;
    const type = this.gatherType(isAdblock);
    const grandparents = this.gatherGrandparents(isAdblock);

    let newList = publicComponents.filter((component) => {

      const search = component.title.toLowerCase().includes(this.state.term.toLowerCase());
      const hasGrandparent = component.grandparents
        ? grandparents.some(grandparent => component.grandparents.has(grandparent))
        : true;
      return (
        component.parents.has(type)
        && search
        && hasGrandparent
      );
    });

    if (this.props.messageCategory === 'ccpa' && this.props.messageSubcategory === 'notice') {
      newList = newList.filter(component => component.title !== 'Stacks' && component.title !== 'Categories');
    }

    if (this.props.messageSubcategory === 'usnat_notice') {
      newList = newList.filter(component => component.title !== 'PMUSNeutral');
    }

    if (this.props.messageCategory === 'usnat') {
      newList = newList.filter(component => component.title !== 'PMButton');
    }

    const requiredComponentsList = (this.props.requiredComponents
      .find(({ name }) => name === this.props.currentView) || { components: [] }).components
      .map((component) => {
        const matchingComponent = [...publicComponents, ...privateComponentsList].find(c => c.title === component.schema.type);
        return {
          ...matchingComponent,
          presentationalTitle: (component.configuration && component.configuration.name)
            ? component.configuration.name
            : matchingComponent.presentationalTitle || matchingComponent.title,
          added: component.added,
          schema: component.schema,
          configuration: component.configuration,
        }
      })
      .filter(component => component.presentationalTitle.toLowerCase().includes(this.state.term.toLowerCase()));
    return (
      <Modal
        title={`Add Component to ${this.props.currentView}`}
        className="add-component-modal"
        visible={this.props.visible}
        onCancel={this.props.handleCancel}
        width={800}
        footer={null}
      >
        <div className="components-grid">
          <Input
            type="search"
            className="components-search"
            size="large"
            value={this.state.term}
            onChange={(e) => this.setState({ term: e.target.value })}
            placeholder="Search components..."
          />
          <div className="components-holder">
            {newList.map((c, i) => this.makeGridItem(c, i, this.addToNotice))}
            {this.props.requiredComponents.find(({ name }) => name === this.props.currentView) && (
              <React.Fragment>
                <div className="required-hr">
                  <div>Required Components</div>
                </div>
                {requiredComponentsList.map((c, i) => this.makeGridItem(c, i, c.added ? _.noop : this.addRequiredToNotice))}
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddComponent;
