import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VendorTraceFilters from './vendor_trace_filters/VendorTraceFilters';
import VendorTraceOverview from './vendor_trace_overview/VendorTraceOverview';
import {
  getMaxTraceLevelData,
  getVendorTraceData,
  getConsentTypesData,
} from '../../../api/diagnose_dashboard/vendor_trace';
import {
  resetVendorTrace,
  setConsentTypeFilter,
  setSelectedVendorTraceFilters,
} from '../../../actions/diagnose_vendor_trace_actions';
import { Spin } from 'antd';
import useSearchParams from '../dashboard/shared/useSearchParams';
import { useVendorTraceContext, withVendorTraceData } from './vendor_trace_overview/ChartFiltersContext';

const VendorTraceDashboard = ({
  setSelectedVendorTraceFilters,
  setConsentTypeFilter,
  consentTypeFilter,
  selectedVendorTraceFilters,
  resetVendorTrace,
}) => {
  const [maxTraceLevelLoading, setMaxTraceLevelLoading] = useState(false);
  const [consentTypesLoading, setConsentTypesLoading] = useState(false);
  const [vendorTraceDataLoading, setVendorTraceDataLoading] = useState(false);
  const [consentTypes, setConsentTypes] = useState([]);
  const { setVendorsData, setBeforeActionConsentCategory } = useVendorTraceContext();
  const searchParams = useSearchParams();

  const VendorTraceOverviewRef = useRef(null);
  const executeScroll = () => VendorTraceOverviewRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const getConsentTypes = async (filters) => {
    const DefinitionsList = {
      'Before action': 'Vendors that are triggered before the user has made any consent actions.',
      'Accept all':
        'All vendors are displayed. Those activated after a user has ‘accepted all’ are highlighted, while those ‘before’ this action remain greyed out.',
      'Reject all':
        'All vendors are displayed. Those activated after a user has ‘rejected all’ are highlighted, while those ‘before’ this action remain greyed out',
      'Opt out':
        'All vendors are displayed. Those activated after a user has ‘opted out of the sale and sharing of their personal information’ are highlighted, while those ‘before’ this action remain greyed out.',
    };
    try {
      setConsentTypesLoading(true);
      const resp = await getConsentTypesData({
        dateFrom: filters.scannedPeriod.dateFrom,
        dateTo: filters.scannedPeriod.dateTo,
        scheduledScanId: filters.scannedUrl,
        regions: filters.region,
      });
      const inlineConsentTypes = (consentCategory) => consentCategory.consent_types.map(({ id }) => id).join(',');
      const mapedConsentTypes = resp
        .map((it) => ({ value: inlineConsentTypes(it), title: it.name, htmlTitle: DefinitionsList[it.name] }))
        .sort((a, b) => {
          if (a.title === 'Before action') return -1;
          if (b.title === 'Before action') return 1;
          return 0;
        });
      setBeforeActionConsentCategory(
        mapedConsentTypes.find((consentType) => consentType.title === 'Before action')?.value,
      );
      const highlightConsent =
        searchParams.consent_type &&
        mapedConsentTypes?.find((consentCategory) => consentCategory?.value?.includes(searchParams.consent_type));

      const selectedConsentType = highlightConsent ? highlightConsent.value : mapedConsentTypes[0].value;
      setConsentTypeFilter(selectedConsentType);
      setConsentTypes(mapedConsentTypes);
      return selectedConsentType;
    } catch (error) {
      console.log(error);
    } finally {
      setConsentTypesLoading(false);
    }
  };

  const getMaxTraceLevel = async (filters, consentType) => {
    try {
      setMaxTraceLevelLoading(true);
      const response = await getMaxTraceLevelData({
        dateFrom: filters.scannedPeriod.dateFrom,
        dateTo: filters.scannedPeriod.dateTo,
        scheduledScanId: filters.scannedUrl,
        regions: filters.region,
        consentFilter: consentType?.split(','),
      });
      return response.max_level;
    } catch (error) {
      console.log(`Error retrieving max trace level ${error}`);
    } finally {
      setMaxTraceLevelLoading(false);
    }
    return 0;
  };

  const getVendorTrace = async (tracelLevelDepth, filters, consentType) => {
    setVendorsData([]);
    try {
      setVendorTraceDataLoading(true);
      const promises = Array.from({ length: tracelLevelDepth }, (_, i) =>
        getVendorTraceData({
          dateFrom: filters.scannedPeriod.dateFrom,
          dateTo: filters.scannedPeriod.dateTo,
          scheduledScanId: filters.scannedUrl,
          traceLevel: i + 1,
          regions: filters.region,
          consentFilter: consentType?.split(','),
        }),
      );
      const response = await Promise.all(promises);
      setVendorsData(response);
    } catch (error) {
      console.log(`Error retrieving vendor data ${error}`);
    } finally {
      setVendorTraceDataLoading(false);
    }
  };

  const handleApply = async (filters) => {
    setSelectedVendorTraceFilters(filters);
    const initialConsentType = await getConsentTypes(filters);
    const maxTraceLevel = await getMaxTraceLevel(filters, initialConsentType);
    await getVendorTrace(maxTraceLevel, filters, initialConsentType);
    executeScroll();
  };

  const handleChangeConsentType = async (newConsent) => {
    setConsentTypeFilter(newConsent);
    const maxTraceLevel = await getMaxTraceLevel(selectedVendorTraceFilters, newConsent);
    await getVendorTrace(maxTraceLevel, selectedVendorTraceFilters, newConsent);
  };

  useEffect(() => {
    return () => {
      resetVendorTrace();
    };
  }, []);

  return (
    <div className={'diagnose_container'}>
      <div className="diagnose_dashboard vendor_trace_dashboard">
        <div className="headers main_header">Vendor Trace</div>
        <div className="diagnose_dashboard-main-filters-hint">
          The vendor trace allows sites to assess the vendors they have direct relationships and contracts with and to
          share information about a vulnerability with them that is coming from the vendors in their supply chain.
        </div>
        <div className="diagnose_dashboard-main-filters-block">
          <VendorTraceFilters onApply={handleApply} />
        </div>
        <div ref={VendorTraceOverviewRef} id={'vendorTraceData'}>
          <Spin spinning={consentTypesLoading} size="large" wrapperClassName={'vendor-trace-data-loading'}>
            <VendorTraceOverview
              isDataLoading={maxTraceLevelLoading || vendorTraceDataLoading}
              consentTypes={consentTypes}
              enableDisclosureFilter={!selectedVendorTraceFilters?.region?.includes('US')}
              defaultConsentType={consentTypeFilter}
              onChangeConsentType={handleChangeConsentType}
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = function (store) {
  return {
    consentTypeFilter: store.diagnoseVendorTraceState.getIn(['consentTypeFilter', 'value']),
    selectedVendorTraceFilters: store.diagnoseVendorTraceState.getIn(['vendorTraceFilters', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedVendorTraceFilters,
      setConsentTypeFilter,
      resetVendorTrace,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withVendorTraceData(VendorTraceDashboard));
