import React from 'react';
import { Table, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line } from 'recharts';
import moment from 'moment';
import { Map, List } from 'immutable';

import { cleanPercent } from '../../utils';
import { ConsentPerformanceRecord } from '../../../records/consent_performance_records.js';
import { DATEPICKERS } from '../../../constants'

export default class ConsentActions extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(ConsentPerformanceRecord),
    loading: PropTypes.bool,
  };

  static defaultProps = {
    data: new ConsentPerformanceRecord(),
    loading: true,
  };

  state = {
    lineToggle: Map({
      'all': true,
      'mu': true,
      'na': true,
      'ca': true,
      'cm': true,
      'cp': true,
      'ra': true,
      'rm': true,
      'rp': true,
      'pa': true,
      'ps': true,
      'pr': true,
    }),
  };

  toggleLine = (tKey) => {
    let toggles = this.state.lineToggle;
    const inverse = !toggles.get(tKey);

    if (tKey === 'all') {
      toggles = toggles.map(() => inverse);
    } else {
      toggles = toggles.set(tKey, inverse);
    }

    this.setState({lineToggle: toggles});
  };

  formatPercent = (str) => {
    if (!str) return '';
    const newStr = str.replace('(', '').replace(')', '').replace('%', '');
    const [amount, percent] = newStr.split(' ');
    return <span className="tbl-percent"><span>{Number(percent) ? percent : 0}%</span>({Number(amount) ? amount : 0})</span>;
  };

  mergeData = (data) => {
    let tableData = this.mapByDate(data.tableData);
    const pvData = data.PVGraphData;
    
    // if()

    pvData.forEach((item) => {
      item.forEach((val, key) => {
        tableData = tableData.setIn([item.get('date'), key], val);
      });
    });
    return tableData.toList();
  };

  mapByDate = (arr) => {
    let dateMap = Map();
    arr.forEach((row) => {
      dateMap = dateMap.set(row.date, Map(row));
    });
    return dateMap;
  }

  render() {

    let table, chart;

    if (this.props.data && !this.props.loading) {

      const tableData = this.mergeData(this.props.data);

      let consentTable = tableData.map((data) => {
        return data.set('fancyDate', moment(data.get('date')).format('MMM D, YYYY'));
      });
      consentTable = consentTable.sort((a, b) => {
        return moment.utc(a.get('date')).diff(moment.utc(b.get('date')));
      });
      let chartData = tableData.map((data) => {
        const messagedUsers = Number(data.get('totalMessagedUsers')) || Number(data.get('totalPotentialUsers'));

        const usersActions = data.get('usersActions') || (data.get('consentTotal') + data.get('rejectTotal') + data.get('consentSome'));
        const noAction = Math.max(0, messagedUsers - usersActions);

        return Map({
          'date' : moment(data.get('date')).format('MMM Do.'),
          'date_number' : data.get('date'),
          'Messaged users' : cleanPercent(messagedUsers, data.get('totalUsers')),
          'No action' : cleanPercent(noAction, messagedUsers),
          'Consented all' : cleanPercent(data.get('consentTotal'), messagedUsers),
          'Consented from Message' : cleanPercent(data.get('consentMessage'), data.get('consentTotal')),
          'Consented from Privacy Manager' : cleanPercent(data.get('consentPrivacyManger'), data.get('consentTotal')),
          'Rejected all' : cleanPercent(data.get('rejectTotal'), messagedUsers),
          'Rejected from Message' : cleanPercent(data.get('rejectMessage'), data.get('rejectTotal')),
          'Rejected from Privacy Manager' : cleanPercent(data.get('rejectPrivacyManager'), data.get('rejectTotal')),
          'Previously consented all' : cleanPercent(data.get('previouslyConsentedAll'), data.get('totalUsers')),
          'Previously consented some' : cleanPercent(data.get('previouslyIncompleteConsent'), data.get('totalUsers')),
          'Previously rejected any' : cleanPercent(data.get('previouslyRejectedAll'), data.get('totalUsers')),
        });
      });
      chartData = chartData.sort((a, b) => {
        return moment.utc(a.get('date_number')).diff(moment.utc(b.get('date_number')));
      });

      const columns = List([Map({
        title: 'Date',
        dataIndex: 'fancyDate',
        key: 'fancyDate',
        width: '8%',
        sorter: (a, b) => moment.utc(a.date).diff(moment.utc(b.date)),
      }), Map({
        title: 'Consent',
        children: List([ Map({
          title: 'Consent Messages',
          dataIndex: 'consentMessagePercent',
          key: 'consentMessage',
          width: '8%',
          sorter: (a, b) => parseFloat(a['consentMessage']) - parseFloat(b['consentMessage']),
          render: (text) => this.formatPercent(text),
        }), Map({
          title: 'Consent Privacy Manager',
          dataIndex: 'consentPrivacyMangerPercent',
          key: 'consentPrivacyManger',
          width: '8%',
          sorter: (a, b) => parseFloat(a['consentPrivacyManger']) - parseFloat(b['consentPrivacyManger']),
          render: (text) => this.formatPercent(text),
        })]),
      }), Map({
        title: 'Reject',
        children: List([ Map({
          title: 'Reject Messages',
          dataIndex: 'rejectMessagePercent',
          key: 'rejectMessage',
          width: '8%',
          sorter: (a, b) => parseFloat(a['rejectMessage']) - parseFloat(b['rejectMessage']),
          render: (text) => this.formatPercent(text),
        }), Map({
          title: 'Reject Privacy Manager',
          dataIndex: 'rejectPrivacyManagerPercent',
          key: 'rejectPrivacyManager',
          width: '8%',
          sorter: (a, b) => parseFloat(a['rejectPrivacyManager']) - parseFloat(b['rejectPrivacyManager']),
          render: (text) => this.formatPercent(text),
        })]),
      })]);

      table = (
        <Table
          bordered
          dataSource={consentTable.toJS()}
          columns={columns.toJS()}
          pagination={{pageSize: 10}}
          rowKey={r => r.date}
        />
      );
      chart = (
        <div className="line-chart">
          <div className="chart-graph">
            <ResponsiveContainer width='99%' height={395}>
              <LineChart data={chartData.toJS()}>
                <XAxis dy={15} dataKey="date" />
                <YAxis dx={-15} tickFormatter={(tick)=>tick+'%'} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip formatter={(v) => v+'%'} />
                {this.state.lineToggle.get('pr') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Previously rejected any" stroke="#E4D246" /> : null}
                {this.state.lineToggle.get('ps') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Previously consented some" stroke="#B59D6E" /> : null}
                {this.state.lineToggle.get('pa') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Previously consented all" stroke="#FAAD14" /> : null}

                {this.state.lineToggle.get('rp') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Rejected from Privacy Manager" stroke="#D6A7B1" /> : null}
                {this.state.lineToggle.get('rm') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Rejected from Message" stroke="#B66677" /> : null}
                {this.state.lineToggle.get('ra') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Rejected all" stroke="#FF3F69" /> : null}

                {this.state.lineToggle.get('cp') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Consented from Privacy Manager" stroke="#0CD6C4" /> : null}
                {this.state.lineToggle.get('cm') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Consented from Message" stroke="#108B21" /> : null}
                {this.state.lineToggle.get('ca') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Consented all" stroke="#0AD326" /> : null}

                {this.state.lineToggle.get('na') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="No action" stroke="#AAAAAA" /> : null}
                {this.state.lineToggle.get('mu') ? <Line strokeWidth={2} dot={false} type="monotone" dataKey="Messaged users" stroke="#1370FB" /> : null}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-legend">
            <div>
              <Checkbox checked={this.state.lineToggle.get('all')} onChange={()=>this.toggleLine('all')} className="lg-all">Select all</Checkbox>
              <div className="legend-spacer" />
              <Checkbox checked={this.state.lineToggle.get('mu')} onChange={()=>this.toggleLine('mu')} className="lg-mu">Messaged users</Checkbox>
              <Checkbox checked={this.state.lineToggle.get('na')} onChange={()=>this.toggleLine('na')} className="lg-na">No action</Checkbox>
              <div className="legend-spacer" />
              <Checkbox checked={this.state.lineToggle.get('ca')} onChange={()=>this.toggleLine('ca')} className="lg-ca">Consented all</Checkbox>
              <Checkbox checked={this.state.lineToggle.get('cm')} onChange={()=>this.toggleLine('cm')} className="lg-cm">Consented from Message</Checkbox>
              <Checkbox checked={this.state.lineToggle.get('cp')} onChange={()=>this.toggleLine('cp')} className="lg-cp">Consented from Privacy Manager</Checkbox>
              <div className="legend-spacer" />
              <Checkbox checked={this.state.lineToggle.get('ra')} onChange={()=>this.toggleLine('ra')} className="lg-ra">Rejected all</Checkbox>
              <Checkbox checked={this.state.lineToggle.get('rm')} onChange={()=>this.toggleLine('rm')} className="lg-rm">Rejected from Message</Checkbox>
              <Checkbox checked={this.state.lineToggle.get('rp')} onChange={()=>this.toggleLine('rp')} className="lg-rp">Rejected from Privacy Manager</Checkbox>
              <div className="legend-spacer" />
              <Checkbox checked={this.state.lineToggle.get('pa')} onChange={()=>this.toggleLine('pa')} className="lg-pa">Previously consented all</Checkbox>
              <Checkbox checked={this.state.lineToggle.get('ps')} onChange={()=>this.toggleLine('ps')} className="lg-ps">Previously consented some</Checkbox>
              <Checkbox checked={this.state.lineToggle.get('pr')} onChange={()=>this.toggleLine('pr')} className="lg-pr">Previously rejected any</Checkbox>
            </div>
          </div>
        </div>
      );

    }

    return (
      <div className="adblock card line-chart-container" style={{display: (this.props.loading ? 'none' : 'block')}}>
        {chart}
        {table}
      </div>
    );
  }
}
