import React, { useEffect, useState } from 'react';
import {Modal, Table, Select } from '../../../../../styleguide'
const { Option } = Select;
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { List } from 'immutable';
import { getVendorTypeIcon } from '../wizard/helper';
import { trimString } from '../../../../utils';
import { legalBasis, legalBasisMap } from '../../helper';
const lbOptions = legalBasis.toJS();
import { CategorizationSchema } from '../../../../../records/vendor_list_records';

const BulkEdit = ({
  isModalVisible,
  handleOk,
  handleCancel,
  vendorList,
  selectedVendors,
  atpEnabled,
  isAppleMessagingEnabled,
  filteredInfo,
  getLegalBasis,
  iabPurposes
}) => {
  const [purposes, updatePurposes] = useState([]);
  const [overriddenLegalBasis, updateOverriddenLegalBasis] = useState({});
  const [vl, updateVl] = useState(vendorList);
  const [vendors, updateVendors] = useState(selectedVendors);

  const updateVendorCategorization = (vlCategories, type, purposeName, vendorId) => {
    let categories = new List([]);
    vlCategories.forEach((c) => {
      if (c.type === 'IAB_STACK' || c.type === 'CUSTOM_STACK') {
        categories = categories.concat(c.categories);
      } else {
        categories = categories.push(c);
      }
    });

    const categoryIndex = categories.findIndex((item) => item.name === purposeName);
    let category = categories.get(categoryIndex);

    category = category.update('vendorCategorization', (vendorCategorization) => {
      const vendorCategorizationItemIndex = vendorCategorization.findIndex((obj) => obj.vendorId === vendorId);
      if (vendorCategorizationItemIndex !== -1) {
        vendorCategorization = vendorCategorization.update(vendorCategorizationItemIndex, (vc) => {
          return new CategorizationSchema({
            type,
            vendorId,
            cookies: vc.cookies || [],
          });
        });
      } else {
        vendorCategorization = vendorCategorization.push(new CategorizationSchema({ type, vendorId, cookies: [] }));
      }

      return vendorCategorization;
    });

    const vendorIds = category.get('vendorIds');

    if (type === 'CONSENT' || type === 'LEGITIMATE_INTEREST') {
      category = category.set('vendorIds', vendorIds.add(vendorId));
    } else {
      category = category.set('vendorIds', vendorIds.delete(vendorId));
    }

    categories = categories.set(categoryIndex, category);

    categories = vlCategories.map((vlC) => {
      if (vlC.type === 'IAB_STACK' || vlC.type === 'CUSTOM_STACK') {
        const stackCategories = vlC.categories;

        return vlC.set(
          'categories',
          stackCategories.map((stackCategory) => {
            return categories.find((c) => c.name === stackCategory.name);
          })
        );
      } else {
        return categories.find((c) => c.name === vlC.name);
      }
    });

    return categories;
  };

  const onChangeLegalBasis = (purposeName, purposeIdnName, value) => {
    const updatedOverriddenLegalBasis = { ...overriddenLegalBasis, [purposeIdnName]: value };

    let updatedVendors = [...vendors];
    let categories = vl.categories;

    updatedVendors = updatedVendors.map((v) => {
      let vendor = { ...v };
      const { data: { vendorType } } = vendor
      const possibleLegalBasis = vendor.legalBasisChange[purposeIdnName].possibleLegalBasis;
      const changedLegalBasis = vendor.legalBasisChange[purposeIdnName].changedLegalBasis;

      const isChangeRequired =
        (possibleLegalBasis.includes(value) && value !== changedLegalBasis)
        || (value === 'NOT_APPLICABLE' && vendorType === 'IAB' && possibleLegalBasis.length > 1);
      if (isChangeRequired) {
        const adjustedValue = value === 'NOT_APPLICABLE' && vendorType === 'IAB' ? 'NOT_ALLOWED' : value;
        vendor.legalBasisChange[purposeIdnName].changedLegalBasis = adjustedValue
        categories = updateVendorCategorization(categories, adjustedValue, purposeName, v.key);
      }
      return vendor;
    });

    const updatedVendorList = vl.set('categories', categories);

    updateVl(updatedVendorList);
    updateVendors(updatedVendors);
    updateOverriddenLegalBasis(updatedOverriddenLegalBasis);
  };

  const generatePurposeColumn = (purpose) => {
    const purposeIdnName = purpose.id || purpose.name;
    const enforcedLegalBasis = overriddenLegalBasis[purposeIdnName] || null;
    const unsupportedVendors =
      enforcedLegalBasis &&
      vendors.filter((v) => {
        return (
          (v.legalBasisChange && v.legalBasisChange[purpose.id || purpose.name].changedLegalBasis !== enforcedLegalBasis)
          && (enforcedLegalBasis !== 'NOT_APPLICABLE')
        );
      }).length;
    let unsupportedVendorsNote;
    if (unsupportedVendors) {
      unsupportedVendorsNote = (
        <span className="unsupporting-warning">
          <span className="vendor-count">{unsupportedVendors} Vendors:</span> Does not support Legal Basis
        </span>
      );
    }
    const iabPurpose = iabPurposes.find((iabPurpose) => iabPurpose.name === purpose.name);
    let legalBasisOptions = lbOptions;

    if([3,4,5,6].includes(iabPurpose?.iabId)){
      legalBasisOptions = legalBasisOptions.filter( lb => lb.value !== 'LEGITIMATE_INTEREST')
    }
    return {
      title: (
        <div className="th-bulk-actions">
          <span className="purpose-name">{iabPurpose && iabPurpose.iabId + '. '}{purpose.name}</span>
          <Select
            placeholder="Select"
            value={enforcedLegalBasis}
            onChange={(value) => onChangeLegalBasis(purpose.name, purposeIdnName, value)}
          >
            {legalBasisOptions.map((l, i) => (
              <Option key={i} value={l.value}>
                {l.value === 'NOT_APPLICABLE' ? `${l.label}/Not Allowed` : l.label}
              </Option>
            ))}
          </Select>
          {unsupportedVendorsNote}
        </div>
      ),
      dataIndex: purpose.name,
      width: 300,
      ellipsis: true,
      render: (consentTypes, vendor) => {
        const legalBasisValue =
          vendor.legalBasisChange && vendor.legalBasisChange[purpose.id || purpose.name].changedLegalBasis;
        const legalBasis = legalBasisMap[legalBasisValue];
        let icon;
        let customClass = '';
        if (!!enforcedLegalBasis) {
          const supported = (legalBasisValue === enforcedLegalBasis) || (legalBasisValue === 'NOT_ALLOWED' && enforcedLegalBasis === 'NOT_APPLICABLE')
          icon = supported ? <CheckOutlined /> : <CloseOutlined />;
          customClass = supported ? 'supported' : 'not-supported';
        }
        return (
          <div className={`td-custom ${customClass}`}>
            {icon}
            {legalBasis}
          </div>
        );
      },
    };
  };

  const getPossibleLegalBasis = (purpose, vendor) => {
    const isCustomVendor = vendor.data.vendorType === 'CUSTOM';
    const defaultVendorBasis =
      vendor[purpose.name].length === 1
        ? vendor[purpose.name][0] === 'consent'
          ? 'CONSENT'
          : 'LEGITIMATE_INTEREST'
        : null;
    let legalBasis = ['NOT_APPLICABLE'];
    const iabPurpose = !!purpose.iabPurposeRef;
    if (vendor[purpose.name].length || !vendor.data.iab || !iabPurpose) {
      legalBasis = ['CONSENT', 'LEGITIMATE_INTEREST'];
      if (purpose.disclosureOnly) {
        legalBasis = ['DISCLOSURE_ONLY'];
      } else if (defaultVendorBasis) {
        legalBasis = [defaultVendorBasis];
      }
      if (isCustomVendor || purpose.type === 'CUSTOM') {
        legalBasis.push('NOT_APPLICABLE');
      } else {
        legalBasis.push('NOT_ALLOWED');
      }
    }
    return legalBasis;
  };

  useEffect(() => {
    let purposes = [];
    let purposeLvlLegalBasis = {};
    vendorList.categories.forEach((category) => {
      if (category.type === 'IAB_STACK' || category.type === 'CUSTOM_STACK') {
        category.categories.forEach((purpose) => {
          purposes.push(purpose);
          purposeLvlLegalBasis[purpose.id || purpose.name] = null;
        });
      } else {
        purposes.push(category);
        purposeLvlLegalBasis[category.id || category.name] = null;
      }
    });

    let updatedVendors = [...vendors];
    updatedVendors = updatedVendors.map((v) => {
      v = { ...v, legalBasisChange: {} };
      purposes.forEach((p) => {
        const legalBasisValue = getLegalBasis(p, v[p.name], v);
        const possibleLegalBasis = getPossibleLegalBasis(p, v);
        v.legalBasisChange[p.id || p.name] = {
          possibleLegalBasis: possibleLegalBasis,
          initialLegalBasis: legalBasisValue,
          changedLegalBasis: legalBasisValue,
        };
      });
      return v;
    });

    updateVendors(updatedVendors);
    updateOverriddenLegalBasis(purposeLvlLegalBasis);
    updatePurposes(purposes);
  }, []);

  const renderVendorName = (value, vendor) => {
    const icon = getVendorTypeIcon(vendor?.data);
    return (
      <span title={value}>
        {icon} {trimString(value, 20)}
      </span>
    );
  };

  let columns = [
    {
      title: `Vendors (${selectedVendors.length})`,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 190,
      ellipsis: true,
      render: renderVendorName,
    },
  ];

  if (
    filteredInfo &&
    !_.isEmpty(filteredInfo) &&
    filteredInfo[Object.keys(filteredInfo)[0]].length &&
    purposes.length
  ) {
    const purposeName = Object.keys(filteredInfo)[0];
    const category = purposes.find((p) => p.name == purposeName);
    columns.push(generatePurposeColumn(category));
  } else {
    purposes.forEach((category) => {
      columns.push(generatePurposeColumn(category));
    });
  }

  return (
    <Modal
      className="vl-bulk-edit"
      title="Bulk Edit"
      visible={isModalVisible}
      okText="Update"
      onOk={() => handleOk(vl)}
      onCancel={() => handleCancel()}
      width={'60%'}
    >
      <div className="info">
        <span className="note">Note:</span> If the vendor does not support legal basis it will not change.
      </div>
      <Table scroll={{ x: 'max-content' }} rowKey={(record) => record.key} columns={columns} dataSource={vendors} />
    </Modal>
  );
};

export default BulkEdit;
