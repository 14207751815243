import React, { Component } from 'react';
import { Input } from 'antd';

class Dimensions extends Component {
  updateDimensions = (type, value) => {
    const dimensions = JSON.parse(JSON.stringify(this.props.value));
    dimensions[type] = value;
    this.props.updateSetting(this.props.settingKey, dimensions);
  }
  render() {
    return (
      <div className="four-options">
        <h4>{this.props.settingsTitle}</h4>
        <div className="option-inputs">
          <div>
            <Input value={this.props.value.flex} onChange={(e) => this.updateDimensions('flex', e.target.value)} />
            <h5>Flex</h5>
          </div>
          <div>
            <Input value={this.props.value.width} onChange={(e) => this.updateDimensions('width', e.target.value)} />
            <h5>Width</h5>
          </div>
          <div>
            <Input value={this.props.value.height} onChange={(e) => this.updateDimensions('height', e.target.value)} />
            <h5>Height</h5>
          </div>
        </div>
      </div>
    );
  }
}

export default Dimensions;
