import React from 'react';
import { Input } from 'antd';

import CustomFields from './CustomFields';
import Style from './Style';

const TitleBody = ({
  type,
  text,
  style,
  updateItem,
  customFields,
  addField,
  removeField,
}) => {
  return (
    <div>
      <h3>{type}</h3>
      <div className="text-input-container">
        <label htmlFor="text-input">
          {type} text
        </label>
        <Input
          id="native-msg-text-input"
          placeholder={`Add ${type} text`}
          value={text}
          onChange={({ target: { value } }) => updateItem([type, 'text'], value)}
        />
      </div>
      <Style
        styles={style}
        updateStyle={(newStyles) => updateItem([type, 'style', ], { ...style, ...newStyles})}
      />
      <div className="custom-fields-container">
        <CustomFields
          fields={customFields}
          addField={addField}
          removeField={removeField}
          updateField={(i, key, value) => updateItem([type, 'customFields', i, key], value)}
        />
      </div>
    </div>
  );
};

export default TitleBody;