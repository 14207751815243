import React, { useState } from 'react';
import { LeftOutlined } from '@ant-design/icons';

import classNames from 'classnames';

const SlidingFilter = ({ className, style = undefined, children, type = 'right' }) => {
  const [toggle, setToggle] = useState(true);
  const handleToggle = () => setToggle((prev) => !prev);

  return (
    <div
      style={style}
      className={classNames(
        'sliding-filters-layout',
        {
          [type]: type,
          closed: !toggle,
        },
        className,
      )}
    >
      <button type="button" onClick={handleToggle}>
        <LeftOutlined className="slider-icon" />
      </button>
      <div>{children}</div>
    </div>
  );
};

export default SlidingFilter;
