import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../hooks';
import { getLiveStageCampaign, getLivePublicCampaign } from '../../actions/campaign_actions';
import { getPartitionsets } from '../../actions/partitionset_actions';
import { getDraftScenarios, getPublicScenarios, getStageScenarios } from '../../actions/scenario_actions';
import { dialogueTypes } from '../../constants';
import { getDraftMessages, getPublicMessages, getStageMessages } from '../../actions/message_actions';
import RestartCampaignModal from './campaigns/RestartCampaignModal';

const activeCampaignWrapper = (Component) => (props) => {
  const [modalProps, setModalProps] = useState({ visible: false, type: null });
  const { site_id: siteId } = props.router.location.query;

  const actions = useActions([
    getLiveStageCampaign,
    getLivePublicCampaign,
    getPartitionsets,
    getDraftScenarios,
    getPublicScenarios,
    getStageScenarios,
    getDraftMessages,
    getPublicMessages,
    getStageMessages,
  ]);

  useEffect(() => {
    actions.forEach(action => action(siteId))
  }, [siteId]);

  const { activePartitions, activeScenarios, activeMessages } = useSelector(({ campaignState, partitionsetState, scenarioState, }) => {
    const campaigns = campaignState.getIn(['campaigns', 'value']).filter(c => c.site_id == siteId).toJS();
    const partitions = partitionsetState.getIn(['partitionsets', 'value']).filter(p => p.site_id == siteId).toJS();
    const scenarios = scenarioState.getIn(['scenarios', 'value']).filter(s => s.site_id == siteId).toJS();

    const activePartitions = [];
    const activeScenarios = [];
    const activeMessages = [];
    if (campaigns.length) {
      // debugger
      activePartitions.push(...new Set(campaigns.map(({ partition_set: p }) => p.description)));
      activeScenarios.push(...partitions.reduce((activeScenarios, partition) => {
        return [...activeScenarios, ...partition.partitions.map(({ scenario_id: id }) => id)]
      }, []));
      
      activeMessages.push(...scenarios
        .filter(({ id }) => activeScenarios.includes(id))
        .reduce((activeMessages, { scenario_json: scenarioJson }) => {
          return [
            ...activeMessages,
            ...JSON.parse(scenarioJson).steps
              .map((step) => step.data && step.data.message_id)
              .filter(msgId => msgId)
          ];
        }, []));
    };

    return { activePartitions, activeScenarios, activeMessages };
  });

  const renderActiveCampaignWarning = (id, type) => {
    // debugger
    let activeComponents;
    switch (type) {
      case dialogueTypes.MESSAGE:
        activeComponents = activeMessages;
        break;
      case dialogueTypes.PARTITION:
        activeComponents = activePartitions;
        break;
      case dialogueTypes.SCENARIO:
        activeComponents = activeScenarios;
        break;
    };

    if (activeComponents.includes(id)) {
      setModalProps({ visible: true, type });
    };
  }
  return (
    <React.Fragment>
      <Component {...props} renderActiveCampaignWarning={renderActiveCampaignWarning} />
      <RestartCampaignModal
        visible={modalProps.visible}
        type={modalProps.type}
        onClose={() => setModalProps({ visible: false, type: null })}
      />
    </React.Fragment>
  );
};

export default activeCampaignWrapper;