import { BulbOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { compareStr } from '../../../../helpers/diagnose/utils';

const RecommendationsWindow = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onChange = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`recommendations-window ${isOpen ? 'open' : ''}`}>
      <button className={'recommendations-window-btn'} onClick={onChange}>
        {!isOpen && <LeftOutlined />}
        <BulbOutlined />
        <div>Recommendations</div>
        <RightOutlined />
      </button>
      <div className={'recommendations-window-info'}>
        {compareStr(location.pathname, '/non_disclosed_vendors_observed') &&
          'Use the information below to decide whether to add vendors to your vendor list, or to get in touch with your partners to alert them to remove these vendors from being triggered on your website.'}
        {compareStr(location.pathname, '/vendors_triggered_prior_to_consent') &&
          'We recommend reviewing all vendors triggered prior to consent with your data privacy team to carry out a risk assessment and moving as many as possible behind consent. Any vendors triggered prior to consent that are using cookies or other identifiers should be scrutinised.'}
        {compareStr(location.pathname, '/disclosed_vendors') &&
          'For vendors with very low prevalence on your website, we recommend removing these vendors from your CMP vendor list as a way to reduce exposure to privacy risk. You can use the “Other vendors” section to see if there are vendors that could be worthwhile adding if they have a high prevalence on your benchmark.'}
        {compareStr(location.pathname, '/possible_fingerprinting') &&
          'We recommend reaching out to vendors who are possibly fingerprinting and asking them to explain their methodology to make sure this is something your data privacy team is okay with. Alternatively, you can remove these vendors directly from your CMP vendor list'}
        {compareStr(location.pathname, '/data_leaving_the_EEA') &&
          "We recommend using these insights in combination with other factors (such as whether they are possibly fingerprinting or non-disclosed) to determine whether further investigation into the vendor's cross EEA data transfers is necessary. If it is, we recommend reaching out to the vendor in question and asking them to share details on where their servers are located and what information they are passing to these servers. You can also remove these vendors directly from your vendor list."}
        {compareStr(location.pathname, '/cookies_with_long_lifespans') &&
          'We recommend reaching out to vendors who are dropping cookies over 13 months and asking them to stop, or to remove these vendors from your website via the CMP vendor list'}
        {compareStr(location.pathname, '/all_cookies_found') &&
          'We encourage you to check through the list of cookies that are being set on users on your websites and review any that are marked as flagged. We would also encourage you to update your cookie disclosures in your CMP and cookie policy to provide transparency to users over what the cookies are used for.'}
        {compareStr(location.pathname, '/vendors_after_opt_out') &&
          'We recommend reviewing all vendors firing after opt out with your data privacy team to carry out a risk assessment and remove or contact these vendors. Any vendors firing after opt out that are dropping cookies or using other identifiers should be scrutinised.'}
      </div>
    </div>
  );
};

export default RecommendationsWindow;
