import PropTypes from 'prop-types';
import React from 'react';

import AntdFormItem from '../../../common/AntdFormItem.jsx';

export default function MessageEditZIndex(props) {

    return (
      <div className="message-edit-z-index">
        <h3>Message z-index</h3>
        <div className="options-styles-property">
          <div className="options-z-index">
            <AntdFormItem
              fieldName="message-z-index"
              initialValue={ props.value }
              onChange={ props.onUpdate }
              form={ props.form }
              editing={ props.editing }
              isNumeric
            />
          </div>
        </div>
      </div>
    );
}

MessageEditZIndex.propTypes = {
    editing: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
}
