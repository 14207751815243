/* eslint-disable */
export default class VendorNameRule {
  childMatchVendorName(name, children) {
    for (let child of children) {
      if (child.name === name || this.childMatchVendorName(name, child.children)) {
        return true;
      }
    }

    return false;
  }

  isSupport(filters, node) {
    if (!filters.vendor_name) {
      return false;
    }

    if (filters.vendor_name === node.data?.attributes.vendor_name) {
      return true;
    }

    if (this.childMatchVendorName(filters.vendor_name, node.data.children)) {
      return true;
    }

    return false;
  }
  // getStrokeColor(filters, node, result) {
  //   return filters.vendor_name === node.data?.attributes.vendor_name ? '#41ff6d' : result.at(result.length - 1);
  // }
  getBorderColor(filters, node, result) {
    return filters.vendor_name === node.data?.attributes.vendor_name ? '#41ff6d' : result.at(result.length - 1);
  }
  getTextColor(filters, node, result) {
    return filters.vendor_name === node.data?.attributes.vendor_name ? '#2D2D2D' : result.at(result.length - 1);
  }
  getNodeBackgroundColor(filters, node, result) {
    return filters.vendor_name === node.data?.attributes.vendor_name ? '#41ff6d' : result.at(result.length - 1);
  }
}
