/* eslint-disable */
export default class DefaultRule {
  isSupport(filters, node) {
    return true;
  }
  getStrokeColor(filters, node) {
    return '#DFDFED';
  }
  getBorderColor() {
    return '#DFDFED';
  }
  getTextColor() {
    return '#2D2D2D';
  }
  getNodeBackgroundColor() {
    return '#ffffff';
  }
  getOpacity() {
    return 1;
  }
}
