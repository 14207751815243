import React, { Component } from 'react';
import { Radio, Select, Input, Toggle as AntSwitch } from '../../../../../../../../styleguide';

const { Option } = Select;

class Type extends Component {
  updateType = (e) => {
    this.props.updateSetting(this.props.settingKey, e.target.value);
  }
  updateTypeSettings = (type, value) => {
    const typeSettings = { ...this.props.allSettings.type_settings };
    typeSettings[type] = value;
    this.props.updateSetting('type_settings', typeSettings);
  }
  render() {
    const typeSettings = { ...this.props.allSettings.type_settings };
    return (
      <div className="set-type">
        <h4>{this.props.settingsTitle}</h4>
        <div>
          <Radio.Group value={this.props.value} onChange={this.updateType}>
            <Radio.Button value="modal">Modal</Radio.Button>
            <Radio.Button value="top">Top</Radio.Button>
            <Radio.Button value="bottom">Bottom</Radio.Button>
            <Radio.Button value="inline">Inline</Radio.Button>
          </Radio.Group>
        </div>
        { this.props.value === 'inline' ? (
          <React.Fragment>
            <div className="option-inputs">
              <div style={{paddingLeft: '3px'}}>
                <Select value={typeSettings.targetType} onChange={val => this.updateTypeSettings('targetType', val)} style={{ width: '100%'}}>
                  <Option value="id">ID</Option>
                  <Option value="class">Class</Option>
                </Select>
                <h5>Target Type</h5>
              </div>
              <div>
                <Input value={typeSettings.targetName} onChange={e => this.updateTypeSettings('targetName', e.target.value)} />
                <h5>Target Name</h5>
              </div>
            </div>
            <div class="set-switch">
              <h4 style={{paddingLeft: '8px'}}>Dismissable?</h4>
              <div style={{paddingRight: '8px'}}><AntSwitch checked={typeSettings.dismissable || false} onChange={(checked) => this.updateTypeSettings('dismissable', checked)} /></div>
            </div>
          </React.Fragment>
        ) : null }
      </div>
    );
  }
}

export default Type;