import React, { useEffect,useState } from 'react';
import { Select } from 'antd';
import { getMessageListV2Ajax } from '../../../../../api/dialogue/messages';
import { getParameterByName } from '../../../../utils';
import { BACKEND_STATUS_TYPES } from '../../../../../constants';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

const PrivacyManagerConfig = ({
  siteId,
  accountId,
  url,
  updatePmValue,
}) => {
  const [pmList, setPmList] = useState([]);

  useEffect(() => {
    getMessageListV2Ajax(BACKEND_STATUS_TYPES['draft'], siteId)
    .then(messages => {
      const subCategory = window.location.href.indexOf('gdpr') >= 0 ? 2 : 12;
      const pmMessages = messages.filter((msg) => msg.message_sub_category_id === subCategory)
      setPmList(pmMessages);
    });
  }, []);

  useEffect(() => {
    if (!url && pmList.length) {
      updatePmValue(generateUrl(pmList[0].id))
    };
  }, 
  [pmList.length]);

  const extractPmId = (url) => {
    const [defaultPm] = pmList;
    return Number(url
      ? getParameterByName('message_id', url)
      : defaultPm
        ? defaultPm.id
        : '');
  };

  function generateUrl(pmId) {
    return pmList.find(({ id }) => pmId === id).message_url + `/privacy-manager/index.html?&message_id=${pmId}`;
  };

  return (
    <div className="privacy-manager-select-container">
      <label>
        Privacy Manager
      </label>
      <Select
        id="native-msg-select-pm"
        style={{ width: '100%' }}
        placeholder="Select Privacy Manager"
        value={extractPmId(url)}  
        onChange={(id) => updatePmValue(generateUrl(id))}
      >
        {pmList.map((pm) => (
          <Select.Option key={`native-msg-pm-option-${pm.id}`} value={pm.id}>
            {pm.description}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default PrivacyManagerConfig;