import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Table, Input, Collapse } from "antd";
import { Map, List } from "immutable";
import RecentChangesModal from "./RecentChangesModal";
import MessagePerformaceModal from "./MessagePerformaceModal";
import ExportToolTip from "../../ccpa/dataprivacy/ExportToolTip";
import {
  ResponsiveContainer,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend
} from "recharts";
import {
  LINE_GRAPH_COLOR_LIST,
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST,
  LINE_GRAPH_LEGEND_PATTERN_LIST
} from "../../../constants";
import { Link } from "react-router";
import {
  errExcep,
  absDataFormater,
  percentDataFormater,
  createCSVExportData
} from "./helper";
import CustomPopover from "../../common/CustomPopover";
import { messageTooltipContent } from "./intructionalText";

const { Panel } = Collapse;

export default class ConsentPerformanceMsg extends React.Component {
  state = {
    recentChangesModal: false,
    msgPerformanceVisible: false,
    messageSearch: "",
    searchText: "",
    lineToggle: Map({
      totalMsgs: true,
      consentAll: true,
      consentSome: false,
      noConsent: false,
      rejectAll: false
    }),
    messagedTableData: List(),
    msgValConvert: "number",
    selectedRowKeys: List(),
    rechartMargin: { top: 5, right: 30, left: 20, bottom: 15 }
  };

  toggleLine = tKey => {
    let toggles = this.state.lineToggle;
    const inverse = !toggles.get(tKey);
    toggles = toggles.set(tKey, inverse);
    this.setState({ lineToggle: toggles });
  };

  setGraphType = graphType => {
    this.setState({ graphType });
  };

  showModal = smm => {
    this.setState({
      [smm]: true
    });
  };

  handleCancel = cmm => {
    this.setState({
      [cmm]: false
    });
  };

  renderTooltip = props => {
    let propertySiteIds;
    let propertyDate;
    let logData;
    let tooltipData;
    if (props.payload && props.payload.length > 0 && props.payload[0]) {
      let arrList = [];
      props.payload.map((item, i) => {
        arrList = arrList.concat(item.payload["siteIds" + i]);
      });
      let uniqueArr = [...new Set(arrList)];
      propertySiteIds = uniqueArr;
      propertyDate = props.payload[0].payload.date;
      logData = this.props.changeLogData.filter((item, i) => {
        var common = item
          .get("siteIds")
          .filter(element => propertySiteIds.includes(element));
        return item.get("date") == propertyDate && common.length > 0;
      });
      tooltipData = logData.get(0);
    }

    if (tooltipData) {
      return (
        <div className="tooltip-custom">
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Change Type : </div>
            <span>{this.capitalize(tooltipData.get("operationtype"))}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Date : </div>
            <span> {tooltipData.get("date")} </span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Entity Type : </div>
            <span>{tooltipData.get("entitytype")}</span>{" "}
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Entity : </div>
            <span>{tooltipData.get("entitynames")}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">Properties: </div>
            <span>{tooltipData.get("properties")}</span>
          </div>
          <div className="tooltip-entity-container">
            <div className="tooltip-entities">User : </div>
            <span>{tooltipData.get("user")}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  showTooltip = () => {
    return (
      <Tooltip
        content={this.renderTooltip}
        filterNull={true}
        active={false}
        cursor={false}
      />
    );
  };
  capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  drawGraph = graphData => {
    let lineArr = [];
    graphData.map((d, j) => {
      if (this.state.lineToggle.get("totalMsgs")) {
        lineArr.push(
          <Line
            key={"t-" + j}
            dot={false}
            yAxisId={
              this.state.lineToggle.get("consentAll") ||
              this.state.lineToggle.get("consentSome") ||
              this.state.lineToggle.get("noConsent") ||
              this.state.lineToggle.get("rejectAll")
                ? "right"
                : "left"
            }
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"totalMsgs" + j}
            // activeDot={{ onClick: () => this.showModal('recentChangesModal') }}
            stroke={LINE_GRAPH_COLOR_LIST.get(0)}
          />
        );
      }
      if (this.state.lineToggle.get("consentAll")) {
        lineArr.push(
          <Line
            key={"a-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"consentAll" + j}
            // activeDot={{ onClick: () => this.showModal('recentChangesModal') }}
            stroke={LINE_GRAPH_COLOR_LIST.get(1)}
          />
        );
      }
      if (this.state.lineToggle.get("consentSome")) {
        lineArr.push(
          <Line
            key={"s-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"consentSome" + j}
            // activeDot={{ onClick: () => this.showModal('recentChangesModal') }}
            stroke={LINE_GRAPH_COLOR_LIST.get(2)}
          />
        );
      }
      if (this.state.lineToggle.get("noConsent")) {
        lineArr.push(
          <Line
            key={"n-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"noConsent" + j}
            // activeDot={{ onClick: () => this.showModal('recentChangesModal') }}
            stroke={LINE_GRAPH_COLOR_LIST.get(3)}
          />
        );
      }
      if (this.state.lineToggle.get("rejectAll")) {
        lineArr.push(
          <Line
            key={"r-" + j}
            dot={false}
            yAxisId="left"
            strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(j)}
            strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(j)}
            type="monotone"
            dataKey={"rejectAll" + j}
            // activeDot={{ onClick: () => this.showModal('recentChangesModal') }}
            stroke={LINE_GRAPH_COLOR_LIST.get(4)}
          />
        );
      }
    });
    return lineArr;
  };

  setMsgValConvert = value => {
    this.setState({ msgValConvert: value });
  };
  dataFormater = number => {
    return number + "%";
  };
  render() {
    var tableData = this.props.messageConsentData.map((items, i) =>
      items.get("messageData")
    );
    var graphData = this.props.messageConsentData.map((items, i) =>
      items.get("messageGraph")
    );
    var msgPerformance = this.props.messageConsentData.map((items, i) =>
      items.get("messagePerformanceList")
    );

    let graphList = List();
    let unionList = List();
    graphData.map((g, i) => {
      unionList = unionList
        .toSet()
        .union(g.toSet())
        .toList();
    });
    let groupedByDates = unionList.groupBy(dataPoint => dataPoint.get("date"));
    groupedByDates.map((item, i) => {
      let mergeMap = Map();
      item.map((m, j) => {
        mergeMap = mergeMap.merge(m);
      });
      graphList = graphList.push(mergeMap);
    });
    graphList = graphList.sort(
      (a, b) =>
        moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
    );

    const renderLegend = props => {
      return (
        <React.Fragment>
          {graphData.map((entry, index) => (
            <div className="legend-custom" key={`legend-${index}`}>
              <svg height="5" width="28">
                <line
                  x1="0"
                  y1="0"
                  x2="200"
                  y2="0"
                  style={{
                    stroke: "#555758",
                    strokeWidth: "5",
                    strokeDasharray: LINE_GRAPH_STROKE_PATTERN_LIST.get(index)
                  }}
                />
              </svg>
              {` Criteria ${index + 1}`}
            </div>
          ))}
        </React.Fragment>
      );
    };

    const tableTitle = (
      <div className="table-title-container">
        <div className="table-title">
          Active Campaign{" "}
          <CustomPopover tooltipContent={messageTooltipContent} />
        </div>
        <div className="table-title-convert">
          <button
            className={this.state.msgValConvert == "number" ? "active" : ""}
            onClick={() => this.setMsgValConvert("number")}
          >
            Number
          </button>
          <button
            className={this.state.msgValConvert == "percentage" ? "active" : ""}
            onClick={() => this.setMsgValConvert("percentage")}
          >
            Percentage
          </button>
        </div>
        <div className="compare-msgs">
          <button onClick={() => this.showModal("msgPerformanceVisible")}>
            Compare Messages
          </button>
        </div>
        <Input
          placeholder="Search Messages"
          prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          onChange={event => {
            this.setState({ searchText: event.target.value });
          }}
        />
      </div>
    );

    const columns = List([
      Map({
        title: "Partition",
        dataIndex: "partitionname",
        sorter: (a, b) => a.partitionname.localeCompare(b.partitionname),
        className: "left-align"
      }),
      Map({
        title: "Message ID",
        dataIndex: "messageid",
        sorter: (a, b) =>
          a.messageid.toString().localeCompare(b.messageid.toString()),
        className: "left-align"
      }),
      Map({
        title: "Total Messages",
        dataIndex: "totalmsgs",
        sorter: (a, b) => a.totalmsgs - b.totalmsgs,
        className: "right-align",
        render: text => <span>{text.toLocaleString()}</span>
      }),
      Map({
        title: "Consent All",
        dataIndex: "consentall",
        sorter: (a, b) => a.consentall - b.consentall,
        className: "right-align",
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%"
      }),
      Map({
        title: "Consent Some",
        dataIndex: "consentsome",
        sorter: (a, b) => a.consentsome - b.consentsome,
        className: "right-align",
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%"
      }),
      Map({
        title: "No Consent",
        dataIndex: "noconsent",
        sorter: (a, b) => a.noconsent - b.noconsent,
        className: "right-align",
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%"
      }),
      Map({
        title: "Reject All",
        dataIndex: "rejectall",
        sorter: (a, b) => a.rejectall - b.rejectall,
        className: "right-align",
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%"
      }),
      Map({
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={`/dialogue/scenariosv3?site_id=${record.siteid}`}
              target="_blank"
            >
              {"Need Action"}
            </Link>
          </span>
        ),
        className: "right-align"
      })
    ]);

    let msgHeaders = columns
      .map(item =>
        Map({ label: item.get("title"), key: item.get("dataIndex") })
      )
      .pop()
      .insert(4, Map({ label: "Consent All %", key: "consentallper" }))
      .insert(6, Map({ label: "Consent Some %", key: "consentsomeper" }))
      .insert(8, Map({ label: "No Consent %", key: "noconsentper" }))
      .insert(10, Map({ label: "Reject All %", key: "rejectallper" }));
    let graphTitle = graphList.size
      ? moment(graphList.first().get("date")).format("MMMM YYYY")
      : "";
    return (
      <React.Fragment>
        <div className="property-tabs">
          <div
            className={`property-params ${
              this.state.lineToggle.get("totalMsgs")
                ? "toggle-line1-active"
                : "toggle-line1"
            }`}
            onClick={() => this.toggleLine("totalMsgs")}
          >
            Messaged Users
          </div>
          <div
            className={`property-params ${
              this.state.lineToggle.get("consentAll")
                ? "toggle-line2-active"
                : "toggle-line2"
            }`}
            onClick={() => this.toggleLine("consentAll")}
          >
            Consent All
          </div>
          <div
            className={`property-params ${
              this.state.lineToggle.get("consentSome")
                ? "toggle-line3-active"
                : "toggle-line3"
            }`}
            onClick={() => this.toggleLine("consentSome")}
          >
            Consent Some
          </div>
          <div
            className={`property-params ${
              this.state.lineToggle.get("noConsent")
                ? "toggle-line4-active"
                : "toggle-line4"
            }`}
            onClick={() => this.toggleLine("noConsent")}
          >
            No Consent
          </div>
          <div
            className={`property-params ${
              this.state.lineToggle.get("rejectAll")
                ? "toggle-line5-active"
                : "toggle-line5"
            }`}
            onClick={() => this.toggleLine("rejectAll")}
          >
            Reject All
          </div>
          <div className="instructionalText">
            <CustomPopover tooltipContent={messageTooltipContent} />
          </div>
          <div className="property-action">
            <a onClick={() => this.showModal("recentChangesModal")}>
              View Recent Changes{" "}
              <span className="glyphicon glyphicon-menu-right consent-right-arrow"></span>
            </a>
          </div>
        </div>
        <div className="property-title-container">
          <div className="graph-desc-container">
            <span className="property-title">Message Performance</span>
            <br />
            <span className="property-desc">
              Trend over time, can be viewed on, monthly or weekly basis.
            </span>
          </div>
        </div>

        <ResponsiveContainer width="99%" height={395}>
          <LineChart
            width={730}
            height={250}
            data={graphList.toJS()}
            margin={this.state.rechartMargin}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="fancyDate">
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              type="number"
              yAxisId="left"
              domain={[0, "dataMax"]}
              tickFormatter={
                this.state.lineToggle.get("consentAll") ||
                this.state.lineToggle.get("consentSome") ||
                this.state.lineToggle.get("noConsent") ||
                this.state.lineToggle.get("rejectAll")
                  ? percentDataFormater
                  : absDataFormater
              }
            />
            <YAxis
              type="number"
              yAxisId="right"
              orientation="right"
              domain={[0, "dataMax"]}
              tickFormatter={absDataFormater}
            />
            {/* <YAxis type="number" domain={[0, 'dataMax']} /> */}
            {this.showTooltip()}
            {/* <ReferenceLine x={moment().format('MMM Do')} stroke="#000" isFront={true} label="Today" strokeDasharray="1 1" /> */}
            <Legend verticalAlign="top" content={renderLegend} />
            {this.drawGraph(graphData)}
          </LineChart>
        </ResponsiveContainer>

        {tableTitle}

        <div className="collapsibleTable">
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            {tableData.map((item, i) => {
              let percentage = item.get("aggData").map(c => {
                let n = c;
                n = c
                  .set(
                    "consentall",
                    errExcep(c.get("consentall"), c.get("totalmsgs"))
                  )
                  .set(
                    "consentsome",
                    errExcep(c.get("consentsome"), c.get("totalmsgs"))
                  )
                  .set(
                    "noconsent",
                    errExcep(c.get("noconsent"), c.get("totalmsgs"))
                  )
                  .set(
                    "rejectall",
                    errExcep(c.get("rejectall"), c.get("totalmsgs"))
                  );
                return n;
              });
              let data =
                this.state.msgValConvert == "number"
                  ? item.get("aggData").toJS()
                  : percentage.toJS();
              data = data.filter(m =>
                m.messageid.toString().includes(this.state.searchText)
              );
              return (
                <Panel
                  header={
                    <span>
                      {item.get("messageid")}
                      <ExportToolTip
                        filename="message"
                        selectedTabIndex={i + 1}
                        exportData={createCSVExportData(
                          msgHeaders,
                          item.get("aggData")
                        ).toJS()}
                        criterialist={this.props.criteriaList}
                        sites={this.props.sites}
                        pdfTitle={"Active Campaign"}
                      />
                    </span>
                  }
                  key={i + 1}
                >
                  <Table
                    columns={columns.toJS()}
                    dataSource={data}
                    pagination={{
                      itemRender: this.itemRender,
                      size: "small",
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                      locale: { items_per_page: "" }
                    }}
                    className="consnet-table"
                    rowKey={r => r.key}
                    rowSelection={{
                      onChange: (selectedRowKeys, selectedRows) => {
                        let list = this.state.selectedRowKeys;
                        const index = list.findIndex(r => r.get("key") == i);
                        if (selectedRowKeys.length == 0) {
                          list = list.delete(i);
                        } else {
                          var getRowAbsolute = item
                            .get("aggData")
                            .filter((l, i) => selectedRowKeys.indexOf(i) != -1);
                          if (index >= 0) {
                            list = list.setIn([index, "msgs"], getRowAbsolute);
                          } else {
                            list = list.push(
                              Map({ key: i, msgs: getRowAbsolute })
                            );
                          }
                        }
                        this.setState({ selectedRowKeys: list });
                      }
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>

        <RecentChangesModal
          changeLogData={this.props.changeLogData}
          recentChangesModal={this.state.recentChangesModal}
          handleCancel={() => this.handleCancel("recentChangesModal")}
        />
        <MessagePerformaceModal
          msgPerformance={msgPerformance}
          selectedMsgs={this.state.selectedRowKeys}
          recentChangesModal={this.state.msgPerformanceVisible}
          handleCancel={() => this.handleCancel("msgPerformanceVisible")}
        />
      </React.Fragment>
    );
  }
}
