import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../../../common/CustomButton.js';

export default class extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    return (
      <div className="select-button-container">
        <CustomButton {...this.props} className={`select-button ${this.props.className}`}>
          {this.props.children}
        </CustomButton>
      </div>
    );
  }
}
