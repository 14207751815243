import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import _ from 'lodash';

import { EllipsisOutlined } from '@ant-design/icons';

import { message, Dropdown, Menu } from 'antd';

import ScenarioStep from './steps/ScenarioStep.js.jsx';

import CustomButton from '../../common/CustomButton.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import PopoverButton from '../../common/PopoverButton.jsx';
import WarningMessage from '../../common/WarningMessage.js.jsx';
import ScenarioJson from './ScenarioJson';

import { deleteScenario, deleteSiteGroupScenario, updateScenario, updateSiteGroupScenario } from '../../../actions/scenario_actions';
import { Scenario as ScenarioRecord, ScenarioStep as StepRecord } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { Message } from '../../../records/message_records.js';
import { getParameterByName } from '../../utils';

class Scenario extends React.Component {
  static propTypes = {
    scenario: PropTypes.instanceOf(ScenarioRecord).isRequired,
    messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    displayInCampaigns: PropTypes.bool,
    active: PropTypes.bool,
    duplicateScenario: PropTypes.func,
    updateScenario: PropTypes.func,
    updateSiteGroupScenario: PropTypes.func,
    altpay: PropTypes.bool.isRequired,
    welect: PropTypes.bool,
  };

  static defaultProps = {
      displayInCampaigns: false,
      active: false,
      duplicateScenario: _.noop,
      updateScenario: _.noop,
      updateSiteGroupScenario: _.noop,
      welect: false,
  };

  state = {
      editing: false,
      jsonExpanded: false,
      scenarioEdited: this.props.scenario,
      warningMessagePresent: false,
      copyMessages: false,
      visible: false,
  };

  componentWillReceiveProps(newProps) {
      if (newProps.scenario && !newProps.pending) {
          this.setState({ scenarioEdited: newProps.scenario, editing: false, warningMessagePresent: false });
      }
  }

  componentDidUpdate(prevProps, prevState) {
      if (!prevState.editing && this.state.editing) {
          ReactDOM.findDOMNode(this.refs.scenarioName).focus();
      }
  }

  toggleWarningMessage = () => {
      this.setState({ warningMessagePresent: !this.state.warningMessagePresent });
  };

  handleScenarioStepEvent = (index, eventType, eventData) => {
    let steps = this.state.scenarioEdited.get('steps');

    if (eventType === "delete") {
      steps = steps.delete(index);
    }

    if (eventType === "stepUpdate") {
      steps = steps.set(index, eventData);
    }

    if (eventType === "sortUp") {
      const targetIndex = index - 1;
      steps = steps.set(targetIndex, steps.get(index)).set(index, steps.get(targetIndex));
    }

    if (eventType === "sortDown") {
      const targetIndex = index + 1;
      steps = steps.set(targetIndex, steps.get(index)).set(index, steps.get(targetIndex));
    }

    const updated = this.state.scenarioEdited.set('steps', steps);
    this.setState({ scenarioEdited: updated });
  };

  updateScenarioEdited = (newScenario) => {
      this.setState({ scenarioEdited: newScenario });
  };

  handleEdit = () => {
    if (this.state.editing) return;
    this.setState({ editing: true });
  };

  handleAddStep = () => {
    const updated = this.state.scenarioEdited.update('steps', (steps => steps.push(new StepRecord())));
    this.setState({ editing: true, scenarioEdited: updated });
  };

  handleSave = () => {
    let scenario = this.state.scenarioEdited;

    // Remove any steps that do not have a type chosen
    scenario = this.state.scenarioEdited.update('steps', steps => steps.filter(step => step.class));

    let error = false;
    scenario.steps.forEach((obj) => {
      if (obj.class === 'com.sourcepoint.msgsvc.scenario.steps.ModNDelay' && obj.data.num_pv < 2) {
        message.error('Circular repeat cannot have a value less than 2');
        error = true;
      }
    });
    if (error) return;

    this.setState({ scenarioEdited: scenario }, () => {
      const siteGroupId = getParameterByName('site_group_id', window.location);
      if (siteGroupId) {
        this.props.updateSiteGroupScenario(this.props.accountId, scenario);
      } else {
        this.props.updateScenario(scenario);
      }
    });
  };

  handleDelete = (e) => {
    e.stopPropagation();
    const siteGroupId = getParameterByName('site_group_id', window.location);
    if (siteGroupId) {
      this.props.deleteSiteGroupScenario(siteGroupId, this.props.accountId, this.props.scenario.get('id'));
    } else {
      this.props.deleteScenario(this.props.scenario.get('id'));
    }
  };

  handleDescriptionChange = (event) => {
    const updated = this.state.scenarioEdited.set('description', event.target.value);
    this.setState({ scenarioEdited: updated });
  };

  handleCancelEditing = () => {
     this.setState({
         editing: false,
         scenarioEdited: this.props.scenario,
     });
  };

  onChecked = (checked) => {
    this.setState({
      copyMessages: checked,
    });
  };

  renderScenarioStep = (step, i) => {
      return (
          <ScenarioStep
              key={ i }
              index={ i }
              total={ this.state.scenarioEdited.get('steps').size }
              step={ step }
              editing={ this.state.editing }
              messages={ this.props.messages }
              onEvent={ (eventType, step) => this.handleScenarioStepEvent(i, eventType, step) }
              altpay={ this.props.altpay }
              samba={ this.props.samba }
              welect={ this.props.welect }
          />
      );
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  }

  onStepSelect = (event) => {
    const updated = this.state.scenarioEdited.set('description', event.target.value);
    this.setState({ scenarioEdited: updated });
  }

  onChange = (rowIndex, data) => {
    let steps = this.state.scenarioEdited.get('steps');
    steps = steps.setIn([rowIndex, 'rowData'], data);

    const updated = this.state.scenarioEdited.set('steps', steps);
    this.setState({ scenarioEdited: updated });
  }

  render() {
    let warningMessage;
    if (this.state.warningMessagePresent) {
        warningMessage = <WarningMessage handleDelete={ this.handleDelete } name={ this.state.scenarioEdited.description } handleCancel={ this.toggleWarningMessage } />;
    }

    let description;
    let buttons;
    let panelClass;
    let editButtonClassName;
    let editDropdownMenu;

    if (!this.props.displayInCampaigns) {
        editButtonClassName="";
        if (this.state.editing) {
            editButtonClassName = "active";
            description = (
              <input ref="scenarioName" type="text" value={ this.state.scenarioEdited.description } onChange={ this.handleDescriptionChange }></input>
            );
            buttons = (
                <div className="panel-buttons">
                  <CustomButton type={ CustomButton.types.DEFAULT } size={ CustomButton.sizes.MEDIUM } onClick={ this.handleAddStep } >+&nbsp;&nbsp;ADD STEP</CustomButton>

                  <CustomButton type={ CustomButton.types.DANGER } size={ CustomButton.sizes.MEDIUM } onClick={ this.handleCancelEditing }>Cancel</CustomButton>

                  <CustomButton type={ CustomButton.types.SUCCESS } size={ CustomButton.sizes.MEDIUM } onClick={ this.handleSave } >SAVE</CustomButton>
                </div>
            );
        } else {
            description = (
              <h4>{ this.state.scenarioEdited.description }</h4>
            );
        }

        const switchOptions = [{
          isChecked: this.state.copyMessages,
          onChecked: this.onChecked,
          popupText: 'Copy with Messages',
          disabled: false,
        }];

        const editMenu = (
          <Menu className="buttons-group">
            <Menu.Item>
              <CustomIcon
                type={ CustomIcon.types.EDIT }
                onClick={ this.handleEdit }
                className={ editButtonClassName }
                tooltip="edit"
              />
            </Menu.Item>
            <Menu.Item>
              <PopoverButton
                onClick={ () => {
                  this.props.duplicateScenario(this.props.scenario, {copyMessages: this.state.copyMessages });
                }}
                switchOptions={ switchOptions }
                type={ PopoverButton.types.COPY }
              />
            </Menu.Item>
            <Menu.Item>
              <CustomIcon
                type={ CustomIcon.types.DELETE }
                onClick={ this.toggleWarningMessage }
                tooltip="delete"
              />
            </Menu.Item>
          </Menu>
        );

        editDropdownMenu = (
          <Dropdown
            className='edit-dropdown-menu'
            overlay={editMenu}
            onOpenChange={ this.handleVisibleChange }
            visible={ this.state.visible }
          >
              <EllipsisOutlined
                style={{
                  float: 'right',
                }} />
          </Dropdown>
        );

        var header = (
          <div className="panel-heading">
            { description }
            { editDropdownMenu }
          </div>
        );
        panelClass = classNames('scenario', 'panel', 'panel-default', 'card', 'v1');
    } else {
        panelClass = classNames('scenario', 'panel', 'panel-default', 'card', 'v1', 'scenario-in-campaign', { 'ended': this.props.active });
    }
    const panelBodyClass = classNames('panel-body', { 'scenario-not-active': this.props.displayInCampaigns && !this.props.active });

    const scenarioOutput = this.props.scenario.get('schema_version') === 1 ? (
      <ScenarioJson
        customScenario={ this.state.scenarioEdited }
        editing={ this.state.editing }
        handleCancelEditing={ this.handleCancelEditing }
        handleSave={ this.handleSave }
        updateScenarioEdited={ this.updateScenarioEdited }
      />
    ) : (
      <div>
        <table className="panel-table table">
          <thead>
            <tr>
              <th></th>
              <th>Step Type</th>
              <th>Step Parameters</th>
            </tr>
          </thead>
          <tbody>
            { this.state.scenarioEdited.steps.map(this.renderScenarioStep) }
          </tbody>
        </table>
        { buttons }
      </div>
    );
    return (
      <div className={ panelClass } >
        { header }
        <div className={ panelBodyClass }>
          { scenarioOutput }
          </div>
          { warningMessage }
        </div>
      );

  }
}

export default connect(function(){return {}}, { deleteScenario, deleteSiteGroupScenario, updateScenario, updateSiteGroupScenario, })(Scenario);
