import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Input } from 'antd';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { SiteGroup, Site } from '../../../records/site_records';
import SelectSitesAndSiteGroups from '../../common/SelectSitesAndSiteGroups';

export default class CreateSiteGroupModal extends React.Component {
  static propTypes = {
    createOrUpdateSiteGroup: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    siteGroup: PropTypes.instanceOf(SiteGroup).isRequired,
    siteAccess: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
  }

  state = {
    name: this.props.siteGroup.name,
    description: this.props.siteGroup.description,
    siteIds: this.props.siteGroup.siteIds,
    siteNames: new List([])
  }

  createSiteGroup = () => {
    let siteGroup = this.props.siteGroup || new SiteGroup({});
    siteGroup = siteGroup.merge({
      name: this.state.name,
      description: this.state.description,
      siteIds: this.state.siteIds
    });
    this.props.createOrUpdateSiteGroup(this.props.accountId, siteGroup, this.state.siteNames.toJS());
    this.props.closeModal();
  };

  handleSelectSite = (siteIds) => {
    const siteNames = siteIds.map(id => this.props.sites.find(site => site.id === id).domain)
    this.setState({
      siteIds: List(siteIds),
      siteNames: List(siteNames)
    });
  }

  render() {
    const title = this.props.siteGroup.id ? 'Edit the property group' : 'Create a property group';
    return (
      <Modal
        visible={ this.props.visible }
        onOk={ this.createSiteGroup }
        onCancel={ this.props.closeModal }
        okText={ this.props.siteGroup ? 'Save Changes' : 'Create site group' }
        destroyOnClose
        className='shared-modal create-site-group'
      >
        <div>
          <label className='title'>{ title }</label>
          <p>Manage scenarios, messages and campaigns for several sites at once.</p>
          <label>Property group name</label>
          <Input value={ this.state.name } onChange={ (e) => this.setState({ name: e.target.value })} />
          <label>Description (optional)</label>
          <Input value={ this.state.description } onChange={ (e) => this.setState({ description: e.target.value })} />
          <label>Add properties to group
            <SelectSitesAndSiteGroups
              value={ this.state.siteIds }
              sites={ this.props.sites }
              siteGroups={ List([]) }
              onSelect={ this.handleSelectSite }
              multiple
              siteAccess={ this.props.siteAccess }
            />
          </label>
        </div>
      </Modal>
    );
  }
}
