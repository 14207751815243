import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { percentOfNum, numberWithCommas } from '../../../utils.js';
import CustomTable from '../../../common/CustomTable.js.jsx';
import TableBody from '../../../common/TableBody.js.jsx';
import TableRow from '../../../common/TableRow.js.jsx';
import TableCell from '../../../common/TableCell.js.jsx';
import TableHeader from '../../../common/TableHeader.js.jsx';
import TableCellHeader from '../../../common/TableCellHeader.js.jsx';

var CHOICE_NAMES_READABLE = {
  1: "Whitelist",
  2: "Recovery",
  3: "Whitelist & Recovery",
  4: "Alt Ad - Video",
  8: "Pub Custom",
  9: "Whitelist & Pub Custom",
  16: "Redirect",
  32: "Continue with Adblocker",
  64: "No Choice",
  128: "Login",
  1024: "Alt Pay"
}

export default class extends React.Component {
  static propTypes = {
      data: PropTypes.object.isRequired,
  };

  render() {
      var data = _.filter(this.props.data, ((v, k) => CHOICE_NAMES_READABLE[k]))
      if (!Object.keys(data).length) return null;

      var allChoicePV = 0;
      var allChoiceBlockedPV = 0;

      if (Object.keys(data).length > 1) {
          for (var key in data) {
              allChoicePV += data[key].total_pv_count;
              allChoiceBlockedPV += data[key].abp_desktop_pv_count;
              allChoiceBlockedPV += data[key].abp_mobile_pv_count;
          }
      }

      return (
          <CustomTable className="post-choice-table">
            <TableHeader>
              <TableRow>
                <TableCellHeader>Post Choice</TableCellHeader>
                <TableCellHeader>PVs</TableCellHeader>
                <TableCellHeader>Adblocked PVs</TableCellHeader>
                <TableCellHeader>Block Ratio</TableCellHeader>
              </TableRow>
            </TableHeader>
            <TableBody>

              { Object.keys(data).map(key => data[key]).map(choice => {

                var totalAdblockPV = choice.abp_desktop_pv_count + choice.abp_mobile_pv_count
                return (
                    <TableRow key={ choice.flag_bits }>
                      <TableCell>{ CHOICE_NAMES_READABLE[choice.flag_bits] }</TableCell>
                      <TableCell>{ numberWithCommas(choice.total_pv_count) }</TableCell>
                      <TableCell>{ numberWithCommas(totalAdblockPV) }</TableCell>
                      <TableCell>{ percentOfNum(totalAdblockPV, choice.total_pv_count) }</TableCell>
                    </TableRow>
                )
              })}

              <TableRow className="total">
                <TableCell>Total</TableCell>
                <TableCell>{ numberWithCommas(allChoicePV) }</TableCell>
                <TableCell>{ numberWithCommas(allChoiceBlockedPV) }</TableCell>
                <TableCell>{ percentOfNum(allChoiceBlockedPV, allChoicePV) }</TableCell>
              </TableRow>

            </TableBody>
          </CustomTable>
      )
  }
}
