/* eslint-disable class-methods-use-this */
export default class MetricNameRule {
  isSupport(filters, node) {
    if (filters.highlight_filter === 'is_tracking_detected') {
      return filters.tracking_highlight.some((trackingHighlight) => node.data.attributes[trackingHighlight]);
    }
    return false;
  }
  getStrokeColor() {
    return '#CC483B';
  }
  getBorderColor() {
    return '#CC483B';
  }
}
