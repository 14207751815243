import PropTypes from 'prop-types';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Checkbox } from '../../../styleguide';

export default class ServerCookieCheckbox extends React.Component {
    static propTypes = {
        toggleWriteFirstPartyCookiesFromServer: PropTypes.func.isRequired,
        writeFirstPartyCookiesFromServer: PropTypes.bool.isRequired,
        tooltipPlacement: PropTypes.oneOf(['right', 'bottom']).isRequired,
    }

    render() {
        return (
            <div className='mms-domain'>
                <Checkbox
                    className='auto-scan'
                    checked={this.props.writeFirstPartyCookiesFromServer}
                    onChange={this.props.toggleWriteFirstPartyCookiesFromServer}
                    disabled={this.props.readOnly}
                >
                    Write 1st Party Cookies from the server
                    <Tooltip
                        title={<span>Used to set first-party cookies on the site's top-level domain. Requires a domain to be setup as the same top-level domain of your site. Please refer to our
                            <a href="https://docs.sourcepoint.com/hc/en-us/articles/4405397441043-Configure-subdomain-with-CNAME-DNS-record" target="_blank" rel="noopener noreferrer"> help article</a> for instructions </span>} placement="right">
                        <InfoCircleOutlined />
                    </Tooltip>
                </Checkbox>
                {this.props.showWarning && <p className='invalid-url'>Requires a domain to be setup with the same top-level domain of your site; that CNAME's to mms.sp-prod.net.</p>}
            </div>
        );
    }
}
