import { Map, List } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';

const initialState = Map({
  reportsList: new StoreRecord({ value: List([]) }),
  requestsSubmitted: List([]),
});

export default (state = initialState, action) => {
  switch(action.type) {
    case types.REPORTS_LIST_PENDING:
      return setPending(state, ['reportsList']);

    case types.REPORTS_LIST_LOADED:
      return setValue(state, ['reportsList'], action.data);

    case types.REPORTS_LIST_ERROR:
      return setError(state, ['reportsList'], action.errorMessage);

    case types.REQUEST_SUBMITTED:
      return state.set('requestsSubmitted', state.get('requestsSubmitted').push(action.request));
  }
  return state;
};
