import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Icon, List as ListView } from 'antd';
import { Modal, Input, Table, Tooltip, Checkbox } from '../../../styleguide';
import { List } from 'immutable';
import { SiteGroup, Site } from '../../../records/site_records';
import Filter from './Filter';
import { CheckOutlined, CloseCircleFilled, SearchOutlined } from '@ant-design/icons';

const { Search } = Input;

export default class AddPropertiesToPG extends React.Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    siteGroup: PropTypes.instanceOf(SiteGroup).isRequired,
    showAddProperties: PropTypes.bool.isRequired,
    toggleAddProperties: PropTypes.func.isRequired,
    updatePropertyGroup: PropTypes.func.isRequired,
    propertyTypesNum: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  };
  state = {
    selectedProperties: List(),
    searchProperties: '',
    properties: this.props.sites,
    updated: true,
    startCampaign: true
  };
  updatePropertyGroup = () => {
    let siteGroup = this.props.siteGroup || new SiteGroup({});
    siteGroup = siteGroup.merge({
      siteIds: this.props.siteGroup.siteIds.concat(
        this.state.selectedProperties.map((p) => p.id)
      ),
    });
    this.props.updatePropertyGroup(this.props.accountId, siteGroup, this.state.startCampaign);
    this.props.toggleAddProperties();
  };
  removeSelectProperty = (propertyId) => {
    this.setState((prevState) => ({
      selectedProperties: prevState.selectedProperties.filterNot(
        (p) => p.id == propertyId
      ),
    }));
  };
  onSearchProperties = (e) =>
    this.setState({ searchProperties: e.target.value });

  getFilteredProperties = () =>
    this.state.properties.filter(
      (p) =>
        p
          .get('domain')
          .toLowerCase()
          .indexOf(this.state.searchProperties.toLowerCase()) !== -1
    );

  setProperties = (properties) => this.setState({ properties });
  toggleStartCampaign = (e) => this.setState({ 'startCampaign' : e.target.checked});
  toggleProperties = (property) => {
   const index = this.state.selectedProperties.findIndex((p) => p.id == property.id);
   const selectedProperties = index==-1 ? this.state.selectedProperties.push(new Site(property)): this.state.selectedProperties.delete(index);
   this.setState({selectedProperties });
  }
  render() {
    const startCampaignTooltipText = "If the site group has an active campaign; when new sites  are added to the site group a campaign for them will automatically be started. If there is no active campaign for the site group it will have no effect and a new one will not be started";
    return (
      <Modal
        wrapClassName="property-group-properties"
        title={`Add Properties to ${this.props.siteGroup.name}`}
        visible={this.props.showAddProperties}
        onOk={() => this.updatePropertyGroup()}
        okText="Save"
        okButtonProps={{ disabled: !this.state.selectedProperties.size }}
        onCancel={() => this.props.toggleAddProperties()}
      >
        <div className="property-group-add-properties">
          <div className="add-properties-list">
            <div className="add-properties-header">
              List of properties which are not included in this or any other
              property groups ({this.state.properties.size})
            </div>
            <div className="action-items">
              <Input
                placeholder="Search Properties"
                onChange={this.onSearchProperties}
                style={{ width: 200 }}
                value={this.state.searchProperties}
                suffix={<SearchOutlined />}
              />
              <Filter
                properties={this.props.sites}
                setProperties={this.setProperties}
                propertyTypesNum={this.props.propertyTypesNum}
              />
            </div>
            <ListView
              className="add-properties-list"
              itemLayout="horizontal"
              dataSource={this.getFilteredProperties().toJS()}
              renderItem={(item) => (
                            <ListView.Item key={item.id} onClick={()=>this.toggleProperties(item)}>
                              <span>{item.domain}</span>
                               <CheckOutlined
                                     style={{
                                             color: this.state.selectedProperties.some( p => p.id == item.id) ? '#411F90' : 'lightgray',
                                             fontWeight: this.state.selectedProperties.some( p => p.id == item.id) ? 'bolder' : 'light',
                                          }}
                              />
                            </ListView.Item>
                          )}
          />
          </div>
          <div className="add-properties-selected">
            <div className="selected-properties-header">
              Selected Properties
            </div>
            <div className="campaign-setting">
                <Tooltip title={startCampaignTooltipText}><Checkbox purple checked={this.state.startCampaign} onChange={this.toggleStartCampaign}></Checkbox></Tooltip> 
                Start campaign for newly added sites
            </div>
            <span className="note-text">
              {this.state.selectedProperties.size ? (
                <ListView
                  itemLayout="horizontal"
                  dataSource={this.state.selectedProperties.toJS()}
                  renderItem={(item) => (
                    <ListView.Item
                      actions={[ <CloseCircleFilled onClick={() => this.removeSelectProperty(item.id)}/> ]}
                    >
                      <ListView.Item.Meta title={item.domain} />
                    </ListView.Item>
                  )}
                />
              ) : (
                'Currently there are no properties selected, please add properties.'
              )}
            </span>
          </div>
        </div>
      </Modal>
    );
  }
}
