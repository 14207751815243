import React, { useState, useEffect, } from 'react';
import { Select } from '../../../../../../../../../../styleguide';
import { Spin } from 'antd';
 
import PmOverridesSelect from './PmOverridesSelect'
import { getParameterByName, mergePmChoiceOptions } from '../../../../../../../../../utils';
import { useV2SiteGroupPms } from '../../../../../../../../../../hooks';
import { useContext } from 'react';
import { SitesContext, PmOverrideContext, SelectedPrivacyManager } from '../../../../../../../../contexts';
import { NOTICE_URL, CCPA_NOTICE_URL, CCPA_PRIVACY_MANAGER_URL } from '../../../../../../../../../../constants';

const { Option } = Select;

const MessagePrivacyManager = ({
  options,
  value,
  changeValue,
  disablePmSelect,
  category,
  messageSubcategory
}) => {
  const [selection, setSelection] = useState(null);
  const { status, response } = useV2SiteGroupPms(messageSubcategory);
  const { sites, isSiteGroup } = useContext(SitesContext);
  const { setDefaultPrivacyManagerVariable } = useContext(PmOverrideContext);
  const { selectedPrivacyManager } = useContext(SelectedPrivacyManager);

  useEffect(() => {
    const valuePmUrl = isSiteGroup ? value.master_privacy_manager_iframe_url : (value.data && value.data.privacy_manager_iframe_url);
    const selectedPmUrl = isSiteGroup ? selectedPrivacyManager.master_privacy_manager_iframe_url : (selectedPrivacyManager.data && selectedPrivacyManager.data.privacy_manager_iframe_url);
    if (!valuePmUrl) {
      // if we have no value and we have options, set the value
      if (options.length > 0) {
        //why is it always first?
        updateValue(generateUrl(options[0].id));
      }
    } else if (valuePmUrl !== selectedPmUrl) {
      // if we have a value but its different than the top level setting, call update value to correct that
      updateValue(valuePmUrl);
    }
  }, [options.length]);

  useEffect(() => {
    //after pms are loaded, this will fire and set the dropdown to the correct pm if there is one selected
    const pmSelection = getParameterByName('message_id', value.master_privacy_manager_iframe_url || value.data.privacy_manager_iframe_url) ||
      getParameterByName('privacy_manager_id', value.master_privacy_manager_iframe_url || value.data.privacy_manager_iframe_url);
    if (selection !== pmSelection) {
      setSelection(pmSelection);
    }
  }, [options.length, value])

  const updateValue = (newValue) => {
    let { type, data } = value;
    let optionalKeypairs;
    let messageId = getParameterByName('message_id', newValue) || getParameterByName('privacy_manager_id', newValue)

    data = {
      ...data,
      privacy_manager_iframe_url: newValue !== undefined ? newValue : null
    }

    if (isSiteGroup) {
      if (filteredOptions.map(opt => opt.id).includes(Number(messageId))) setDefaultPrivacyManagerVariable(messageId);
      // use pm url as "master"
      optionalKeypairs = { master_privacy_manager_iframe_url: data.privacy_manager_iframe_url }
      // update pm url with variable
      data = {
        ...data,
        privacy_manager_iframe_url: newValue ? generateUrl('$$privacyManagerIdHiddenVarriable$$') : '',
      };
    }
    // if we're disabling pm select, use the top level pm choice
    if (disablePmSelect) {
      ({ type, data, ...optionalKeypairs } = mergePmChoiceOptions(selectedPrivacyManager, { type, data, ...optionalKeypairs }));
    }

    changeValue(type, data, optionalKeypairs);

    setSelection(messageId);
  };

  const generateUrl = (id) => {
    switch(category) {
      case 'ccpa':
        if (isNaN(id) && id !== '$$privacyManagerIdHiddenVarriable$$') {
          return `${CCPA_PRIVACY_MANAGER_URL}?privacy_manager_id=${id}`;
        } else {
          return `${CCPA_NOTICE_URL}/ccpa_pm/index.html?message_id=${id}`;
        }
        break;
      case 'usnat':
        return `${NOTICE_URL}/us_pm/index.html?message_id=${id}`;
        break;
      default:
        return `${NOTICE_URL}/privacy-manager/index.html?message_id=${id}`;
    }
  }

  const changeDefaultTab = (tab, url) => {
    const newUrl = url
      .replace('&pmTab=purposes-li', '')
      .replace('&pmTab=purposes', '')
      .replace('&pmTab=features', '')
      .replace('&pmTab=li', '')
      .replace('&pmTab=vendors-li', '')
      .replace('&pmTab=vendors', '')
      .replace('&view=HOME', '')
      .replace('&view=CUSTOM_VIEW', '');

    const queryParamVal = ['CUSTOM_VIEW', 'HOME'].includes(tab) ? 'view' : 'pmTab'
    tab ? updateValue(`${newUrl}&${queryParamVal}=${tab}`) : updateValue(newUrl);
  };

  const getTabValue = (urlValue) => {
    if (urlValue.includes('&pmTab=vendors')) {
      return urlValue.includes('&pmTab=vendors-li') ? 'vendors-li' : 'vendors';
    } else if (urlValue.includes('&pmTab=features')) {
      return 'features';
    } else if (urlValue.includes('&view=HOME')) {
      return 'HOME'
    } else if (urlValue.includes('&view=CUSTOM_VIEW')) {
      return 'CUSTOM_VIEW'
    } else {
      return urlValue.includes('&pmTab=purposes-li') ? 'purposes-li' : 'purposes';
    };
  }

  const handlePmChange = (newPmId) => {
    updateValue(generateUrl(newPmId));
  };

  const urlValue = (
    (isSiteGroup ? value.master_privacy_manager_iframe_url : '')
    || value.data.privacy_manager_iframe_url
    || ''
  );

  const isFromCCPA = category === 'ccpa';
  const isFromUSNAT = category === 'usnat';

  let filteredOptions = options.filter(o => {
    if (isFromCCPA && o.message_sub_category_id === 12) return true;
    if (!isFromCCPA && o.message_sub_category_id !== 12) return true;
    return false;
  })

  filteredOptions = filteredOptions.filter(o => {
    if (isFromUSNAT && o.message_category_id === 6) return true;
    if (!isFromUSNAT && o.message_category_id !== 6) return true;
    return false;
  })

  const masterOption = (filteredOptions.length && selection) ? filteredOptions.find(({ id }) => id == selection) : null;

  const urlValueNoTabs = urlValue
    .replace('&pmTab=purposes-li', '')
    .replace('&pmTab=purposes', '')
    .replace('&pmTab=features', '')
    .replace('&pmTab=li', '')
    .replace('&pmTab=vendors-li', '')
    .replace('&pmTab=vendors', '');

  const selectedPmError = (filteredOptions.length && selection) && (!filteredOptions.map(opt => opt.id).includes(Number(selection)) && !filteredOptions.map(opt => opt.id).includes(selection))
  console.log(selectedPmError)
  return (
    <Spin spinning={status === 'PENDING'}>
      <div className="four-options">
        <h4>Privacy Manager</h4>
        <div className="option-inputs">
          <Select
            style={{ width: '100%' }}
            value={(selectedPmError ? "None Selected" : selection) || undefined}
            onChange={handlePmChange}
            disabled={disablePmSelect}
            status={(selectedPmError) ? 'error' : null}
          >
            {filteredOptions.length === 0 && (
              <Option value={null}>
                <i>None Selected</i>
              </Option>
            )}
            {filteredOptions.map((opt) => (
              <Option key={opt.id} value={`${opt.id}`}>
                {opt.description}
              </Option>
            ))}
          </Select>
        </div>
        {urlValue && messageSubcategory !== 'usnat_notice' && (
          <React.Fragment>
            <h4>Default PM Tab/View</h4>
            <div className="option-inputs">
              <Select
                style={{ width: '100%' }}
                value={getTabValue(urlValue) === 'li' ? 'purposes' : getTabValue(urlValue)}
                onChange={val => changeDefaultTab(val, urlValue)}
                options={[
                  {
                    label: "PM Tabs",
                    options: [
                      { label: "Purposes (Consent)", value: 'purposes' },
                      { label: 'Purposes (Legitimate Interest)', value: 'purposes-li' },
                      { label: 'Vendors (Consent)', value: 'vendors' },
                      { label: 'Vendors (Legitimate Interest)', value: 'vendors-li' },
                      { label: 'Features', value: 'features' },
                    ]
                  },
                  {
                    label: "PM Views",
                    options: [
                      { label: 'Privacy Manager Main View', value: 'HOME' },
                      { label: 'Privacy Manager Custom View', value: 'CUSTOM_VIEW' },
                    ]
                  }
                ]}
              />
            </div>
          </React.Fragment>
        )}
        {(urlValue && response.length) && (
          <React.Fragment>
            <h4>Overrides</h4>
            {sites.map(site => (
              <PmOverridesSelect
                key={site.id}
                site={site}
                privacyManagers={response.find(({ siteId }) => siteId == site.id).msgs || []}
                masterOption={masterOption ? masterOption.description : ''}
                pmKey={'description'}
                isTcfv2={!isFromCCPA}
                disabled={disablePmSelect}
              />
            ))}
          </React.Fragment>
        )}
      </div>
    </Spin>
  );
}

export default MessagePrivacyManager;;