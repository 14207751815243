import React, { useMemo, useState, useEffect } from "react";
import { Popover, Divider, Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "../../styleguide";
import RegionSelectorTree from "./RegionSelector/RegionSelectorTree";
import { region_title_index_map } from "../../constants";
import { GEO_LIST_TREE_STRUCTURE, LEGACY_EU_COUNTRY_CODES } from "../../constants";
import { findCommonElementsInTwoArrays, arraySubtraction } from "../utils";

const CustomGeoScopeDropDown = (props) => {
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [geoScopes, setGeoScopes] = useState({
    appliesGeos: props.vl.appliesGeos ?? [],
    exclusionGeos: props.vl.exclusionGeos ?? [],
  });
  const [viewApplies, setViewApplies] = useState(4);
  const [viewExcludes, setViewExcludes] = useState(4);

  const handleVisibleChange = (visible) => setShowSearchPopup(visible);

  const removeAllExcluded = (e) => {
    e.stopPropagation();
    let geoScopesCopy = {
      ...geoScopes,
      exclusionGeos: [],
    };
    setGeoScopes(geoScopesCopy);
    applyChanges(geoScopesCopy);
  };

  const removeAllIncluded = (e) => {
    e.stopPropagation();
    let geoScopesCopy = {
      ...geoScopes,
      appliesGeos: [],
      exclusionGeos: [],
    };
    setGeoScopes(geoScopesCopy);
    applyChanges(geoScopesCopy);
    setSelectedCodes([]);
  };

  const applyChanges = (value) => {
    props.handleLocationSave(value);
  };

  const setGeoScope = (value) => {
    setGeoScopes(value);
  };

  const handleViewMore = (type, e) => {
    e.stopPropagation();
    if (type === "viewApplies") setViewApplies(1000);
    if (type === "viewExcludes") setViewExcludes(1000);
  };

  const handleViewLess = (type, e) => {
    e.stopPropagation();
    if (type === "viewApplies") setViewApplies(4);
    if (type === "viewExcludes") setViewExcludes(4);
  };

  const findAllRegionCodes = (list, parentKey) => {
    const parentNode = list.find(node => node.key === parentKey);

    if (parentNode && parentNode.children) {
        return parentNode.children.map(child => child.code);
    }

    return [];
  }

  const removeRegion = (removeCode, type, e) => {
    e.stopPropagation();

    let geoScopesCopy = geoScopes;
    if (type === "viewApplies") {
      geoScopesCopy.appliesGeos = geoScopesCopy.appliesGeos.filter(
        (code) => removeCode !== code
      );
      const codesToRemove = findAllRegionCodes(GEO_LIST_TREE_STRUCTURE, removeCode);
      if(codesToRemove.length){
        geoScopesCopy.exclusionGeos = geoScopesCopy.exclusionGeos.filter( code => !codesToRemove.includes(code))
      }
    }
    if (type === "viewExcludes") {
      geoScopesCopy.exclusionGeos = geoScopesCopy.exclusionGeos.filter(
        (code) => removeCode !== code
      );
    }
    setGeoScope(geoScopesCopy);
    applyChanges(geoScopesCopy);
  };

  const renderSelectedTerritories = (codes, viewCountType) => {
    let chips = codes && Array.isArray(codes) ? codes.map((code) => (
      <Tag
        className="tagStyle"
        closable={!props.disabled}
        onClose={(e) => removeRegion(code, viewCountType, e)}
        key={code}
      >
        {region_title_index_map[code]}
      </Tag>
    )) : [];
    let viewMoreButton = null;
    const l = chips.length;
    if (l > 4) {
      viewMoreButton =
        (viewCountType === "viewApplies" ? viewApplies : viewExcludes) > 4 ? (
          <span
            className="showMore"
            onClick={(e) => handleViewLess(viewCountType, e)}
            type="primary"
          >
            -
          </span>
        ) : (
          <span
            className="showMore"
            onClick={(e) => handleViewMore(viewCountType, e)}
            type="primary"
          >
            +{l - 4}
          </span>
        );
    }
    chips = chips.slice(
      0,
      viewCountType === "viewApplies" ? viewApplies : viewExcludes
    );

    return chips.length ? (
        <>
          {chips}
          {viewMoreButton}
        </>
    ) : null;
  };

  const content = (
    <div className="popover-content-container">
      <RegionSelectorTree
        isIncludeExcludeStructure={true}
        setGeoScope={setGeoScope}
        appliesGeos={geoScopes.appliesGeos}
        exclusionGeos={geoScopes.exclusionGeos}
        updatedCodes={() => {}}
        codes={[]}
        selectedCodes={selectedCodes}
        setSelectedCodes={setSelectedCodes}
        entity="regions"
      />
      <Divider className="divider footer-divider" />
      <div className="button-wrapper">
        <Button
          type="secondary"
          size="small"
          onClick={(e) => {
            removeAllIncluded(e);
          }}
        >
          Clear
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          size="small"
          onClick={() => {
            applyChanges(geoScopes);
            setShowSearchPopup(false);
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  const includesChips = useMemo(
    () => renderSelectedTerritories(props.vl?.appliesGeos, "viewApplies"),
    [props.vl?.appliesGeos, viewApplies]
  );
  const excludesChips = useMemo(
    () => renderSelectedTerritories(props.vl?.exclusionGeos, "viewExcludes"),
    [props.vl?.exclusionGeos, viewExcludes]
  );

  useEffect(()=>{
      //geos
    if(props.vl?.appliesGeos.includes("ALL_EU")) {
      let selectedCodes = [];
      //to support existing VLs with ALL_EU applies scope
      if(props.vl?.appliesGeos.includes("ALL_EU")){
        selectedCodes = LEGACY_EU_COUNTRY_CODES.filter( code => !props.vl?.exclusionGeos.includes(code));
      }

      GEO_LIST_TREE_STRUCTURE.forEach((option) => {
        if (props.vl?.appliesGeos.includes(option.key)) {
          const childCodes = option.children.map(c => c.code);
          const filteredChildCodes = childCodes.filter(code => !props.vl?.exclusionGeos.includes(code));
          selectedCodes = selectedCodes.concat(filteredChildCodes);
        } else {
          const childCodes = option.children.map(c => c.code);
          const filteredChildCodes = childCodes.filter(code => props.vl?.appliesGeos.includes(code) && !props.vl?.exclusionGeos.includes(code));
          selectedCodes = selectedCodes.concat(filteredChildCodes)
        }
      });

      //include canadian provinces
      if(props.vl?.appliesGeos.includes('ALL_OTHER') && !props.vl?.exclusionGeos.includes('CAN')){
        selectedCodes = selectedCodes.concat(GEO_LIST_TREE_STRUCTURE.find(geo => geo.key == 'CAN').children.map(c => c.code))
      }

      let geoScopesCopy = {
        appliesGeos :  [],
        exclusionGeos :  []
      }
      GEO_LIST_TREE_STRUCTURE.forEach( (option) => {
        const childCodes = option.children.map( c => c.code);
        const selectedChildCodes =findCommonElementsInTwoArrays(childCodes, selectedCodes);
        if(selectedChildCodes.length > (childCodes.length / 2)) {
          geoScopesCopy.appliesGeos = geoScopesCopy.appliesGeos.filter(geo => !selectedChildCodes.includes(geo))
          geoScopesCopy.appliesGeos.push(option.key)
          geoScopesCopy.exclusionGeos = [...new Set(geoScopesCopy.exclusionGeos.concat(arraySubtraction(childCodes,selectedCodes)))]
        } else {
          geoScopesCopy.appliesGeos = [...new Set(geoScopesCopy.appliesGeos.concat(selectedChildCodes))]
          geoScopesCopy.exclusionGeos = geoScopesCopy.exclusionGeos.filter(geo => !selectedChildCodes.includes(geo))
        }
      })
      applyChanges(geoScopesCopy);
    }
  },[]);

  useEffect(() => {
    let selectedCodesCopy = [];
    GEO_LIST_TREE_STRUCTURE.forEach((option) => {
      if (props.vl?.appliesGeos.includes(option.key)) {
        const childCodes = option.children.map((c) => c.code);
        const filteredChildCodes = childCodes.filter(
          (code) => !props.vl?.exclusionGeos.includes(code)
        );
        selectedCodesCopy = selectedCodesCopy.concat(filteredChildCodes);
      } else {
        const childCodes = option.children.map((c) => c.code);
        const filteredChildCodes = childCodes.filter(
          (code) =>
            props.vl?.appliesGeos.includes(code) &&
            !props.vl?.exclusionGeos.includes(code)
        );
        selectedCodesCopy = selectedCodesCopy.concat(filteredChildCodes);
      }
    });
    if(props.vl?.appliesGeos.includes('ALL')){
      geoScopes.appliesGeos = GEO_LIST_TREE_STRUCTURE.map(region => region.key).filter( region => !props.vl?.exclusionGeos?.includes(region));
      geoScopes.exclusionGeos = props.vl?.exclusionGeos ??  []
      applyChanges(geoScopes)
    }
    if (!_.isEqual(selectedCodesCopy, selectedCodes)) {
      setSelectedCodes(selectedCodesCopy);
    }

  }, [props.vl?.appliesGeos, props.vl?.exclusionGeos]);

  return (
    <Popover
      open={showSearchPopup && !props.disabled}
      trigger="click"
      placement={props.placement || "bottomLeft"}
      content={content}
      overlayClassName="geo-scope-popover"
      onOpenChange={(visible) => handleVisibleChange(visible)}
      {...props.extraProps}
    >
      <div className="select-locations">
        {props.vl?.appliesGeos.length > 0 ||
        props.vl?.exclusionGeos.length > 0 ? (
          <div>
            {props.vl?.appliesGeos.length > 0 && (
              <div className="tagContainer">
                <span className="title"> Includes:</span>
                {includesChips}
                {!props.disabled && (
                  <CloseCircleOutlined
                    onClick={(e) => removeAllIncluded(e)}
                    className="cross-circle"
                  />
                )}
              </div>
            )}
            {props.vl?.exclusionGeos.length > 0 && (
              <div>
                <Divider className="divider" />
                <div className="tagContainer">
                  <span className="title"> Excludes:</span>
                  {excludesChips}
                  {!props.disabled && (
                    <CloseCircleOutlined
                      onClick={(e) => removeAllExcluded(e)}
                      className="cross-circle"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <span className="title">Select Locations</span>
        )}
      </div>
    </Popover>
  );
};

export default CustomGeoScopeDropDown;
