import React, { Component } from 'react';
import { Layout, Breadcrumb } from 'antd';
import Settings from './Settings/Settings';
import Children from './Children/Children';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBoxOpen } from '@fortawesome/free-solid-svg-icons';

import Variables from './Variables';
import { SitesContext } from '../../../contexts';
import HiddenFields from './HiddenFields';

const { Sider } = Layout;

class Sidebar extends Component {
  state = {
    collapsed: false,
  };

  pathToArray = (ndx) => {
    const paths = this.props.path.slice(0, ndx + 1);
    return paths.map((p) => p.index);
  }

  goToComponent = (index) => {
    if (index + 1 !== this.props.path.length) {
      const path = this.pathToArray(index);
      this.props.setCurrent(path);
      this.props.changeTab('settings');
    }
  }

  addTabClass = (key) => key === this.props.activeKey ? 'tab-active' : ''

  onCollapse = (collapsed) => this.setState({ collapsed })

  renderSelectedTab = () => {
    const children = this.props.current.children || [];
    switch (this.props.activeKey) {
      case 'structure':
        return (
          <Children
            showModal={this.props.showModal}
            goToChild={this.props.goToChild}
            removeComponent={this.props.removeComponent}
            components={children}
            current={this.props.current}
            moveChild={this.props.moveChild}
            moveIntoChild={this.props.moveIntoChild}
            cloneComponent={this.props.cloneComponent}
            changeTab={this.props.changeTab}
            highlight={this.props.highlight}
          />
        );
      case 'settings':
        return (
          <Settings
            updateSetting={this.props.updateSetting}
            updateBulkSetting={this.props.updateBulkSetting}
            removeComponent={this.props.removeComponent}
            current={this.props.current}
            siteId={this.props.siteId}
            accountId={this.props.currentUser.accountId || this.props.accountId}
            features={this.props.currentUser.accountFeatures || []}
            messageSubcategory={this.props.messageSubcategory}
            messageCategory={this.props.messageCategory}
            pmMessages={this.props.pmMessages}
            handleSampleMessagesModal={this.props.handleSampleMessagesModal}
            path={this.props.path}
            subviewIndex={this.props.subviewIndex}
            components={this.props.components}
            editing={this.props.editing}
            preferencesLists={this.props.preferencesLists}
          />
        );
      case 'global-settings':
        //global settings is for OTT
        return (
          <Settings
            updateSetting={this.props.updateSetting}
            removeComponent={this.props.removeComponent}
            current={this.props.rootCurrent}
            siteId={this.props.siteId}
            accountId={this.props.accountId}
            features={this.props.currentUser.accountFeatures || []}
            messageSubcategory={this.props.messageSubcategory}
            pmMessages={this.props.pmMessages}
            handleSampleMessagesModal={this.props.handleSampleMessagesModal}
            path={this.props.path}
            subviewIndex={this.props.subviewIndex}
            messageCategory={this.props.messageCategory}
            components={this.props.components}
            editing={this.props.editing}
            isGlobalSettings
          />
        );
      case 'variables':
        return (
          <Variables
            variables={this.props.variables}
            updateVariables={this.props.updateVariables}
          />
        );
      case 'hiddenFields':
        return (
          <HiddenFields
            fields={this.props.components[0].settings.hiddenFields}
            {...this.props.hiddenFieldFunctions}
          />
        )
    }
  }

  render() {
    const paths = this.props.path;
    return (
      <Sider className="sidebar" width={360}>
        <div id="crumbs">
          <Breadcrumb>
            {paths.map((p, index) => (
              <Breadcrumb.Item
                key={'crumb-path-' + index}
                onClick={() => this.goToComponent(index)}
              >
                {(index === 0)
                  ? (
                    <React.Fragment>
                      <FontAwesomeIcon icon={faHome} />
                      {p.name || 'Home'}
                    </React.Fragment>
                  )
                  : p.name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
        <div className="sidebar-tabs">
          {(!this.props.isNativeOtt) && (
            <div
              className={this.addTabClass('structure')}
              onClick={() => this.props.changeTab('structure')}
            >
              <FontAwesomeIcon icon={faBoxOpen} />
              <span className="comp-count">{this.props.current.children.length}</span>
            </div>
          )}
          {this.props.isNativeOtt && (
            <div
              className={`global-settings ${this.addTabClass('global-settings')}`}
              onClick={() => this.props.changeTab('global-settings')}
            >
              Global Settings
            </div>
          )}
          <div
            className={this.addTabClass('settings')}
            onClick={() => this.props.changeTab('settings')}
          >
            Settings
          </div>
          <SitesContext.Consumer>
            {({ isSiteGroup }) => isSiteGroup && (
              <div
                className={this.addTabClass('variables')}
                onClick={() => this.props.changeTab('variables')}
              >
                Variables
              </div>
            )}
          </SitesContext.Consumer>
          {(this.props.messageSubcategory === 'sar') && (
            <div
              className={this.addTabClass('hiddenFields')}
              onClick={() => this.props.changeTab('hiddenFields')}
            >
              Hidden Fields
            </div>
          )}
        </div>
        <div style={{ maxHeight: 'calc(100vh - 107px)', overflowY: 'auto' }}>
          {this.renderSelectedTab()}
        </div>
      </Sider>
    );
  }
}

export default Sidebar;
