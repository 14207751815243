import React, { useState } from 'react'
import _ from 'lodash';
import { Modal, Input, Table, message, Infotip } from '../../../styleguide';
import PropertiesFileUpload from './PropertiesFileUpload'
import { SiteGroup } from '../../../records/site_records';
import TooltipContents from './TooltipContents';
import { useSelector } from 'react-redux';
import { hasFeature } from '../../utils';
const { Search } = Input;

const columns = [
  {
    title: 'Property',
    dataIndex: 'property',
    key: 'property',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    align:'right'
  }
];


export default function UploadBulkProperties(props) {

    const [properties, setProperties ] = useState([])
    const [propertySearch, setPropertySearch] = useState('')
    const [propertiesCounter, setPropertiesCounter] = useState(0)
    const accountState = useSelector((state) => state.accountState);
    const currentUser = accountState?.getIn(["userDetails", "value"]);

    const updateSearch = (e)=> {
      setPropertySearch(e.target.value)
    }

    const getTypeCode = (type) =>
    props.propertyTypesNum.find((pt) => pt.get('code') === type).get('value');
    
    const handleOnClick = ()=>{
        const newPropertiesSet = properties.map((property)=> { 
          let type = property.type.toLowerCase()
          if(type === 'website'){
            type = type.slice(0,3);
            type = getTypeCode(type);
          }else if(type === 'ott' || type === 'app' || type === 'amp' ){
            type = getTypeCode(type);
          }
          return {
            name: property.name.toLowerCase().trim(),
            type
          }
        })
        props.createOrUpdateSite(newPropertiesSet, new SiteGroup({}))
        props.toggleBulkPropertyModal()
        message.success(`All properties successfully uploaded.`);
    }

    const numberOfPropertiesHasError = (arr) => {
      const numError = arr.filter((p)=> 
       !(_.isEmpty(p.errorMessages))
      )
      setPropertiesCounter(numError.length)
    }

    const deleteProperty = (name) => {
      const filteredProperties = properties.filter((property) => property.name !== name);
      numberOfPropertiesHasError(filteredProperties)
      setProperties(filteredProperties)
    }

    const isDuplicateProperty =  props.sites.map((p) => p.domain)

    const validatePropertiesAndTypes = (name, propertyType) =>{
      const regx = /^[a-zA-Z0-9-._~*]*$/i;
      let errorMessages = {};
      let type ='';

      if (propertyType.toLowerCase() === "ott") {
        let hasOttPMFeature = hasFeature(currentUser, "privacy_manager_ott");
        if (!hasOttPMFeature) {
          errorMessages.OttFeatureNotEnabled =
            "Please enable OTT feature to upload OTT properties.";
        }
      }
      if(name.length > 30 ){
        errorMessages.nameLong = 'Property name is too long.';
      }
      if(!regx.test(name)){
        errorMessages.invalidCharacters = 'Property name has invalid characters.'
      }

      if(isDuplicateProperty.includes(name)){
        errorMessages.duplicateProperty = 'Property name already exists.';
      }
      if(!propertyType){
        errorMessages.emptyType = 'Property type is required, type must be (Website, APP, OTT, AMP).';
      }
      if(propertyType){
        type = propertyType.toLowerCase()
        if(type === 'website'){
          type = type.charAt(0).toUpperCase() + type.slice(1);
        }else if(type === 'ott' || type === 'app' || type === 'amp' ){
          type = type.toUpperCase();
        }else {
          errorMessages.invalidType = 'Property type must be (Website, OTT, APP, AMP).'
        }
      }

      return { name, errorMessages, type}
    }

    const addProperties = (data)=>{
      const getFilterProperties = data.map(property=>{
          return validatePropertiesAndTypes(property.name, property.type)
      });

      numberOfPropertiesHasError(getFilterProperties);
      if(propertySearch !== ''){
        setPropertySearch('')
      }
      setProperties(getFilterProperties);
    }

    const generatePropertyTable = () => {
      let propertiesSet = properties;
      if (propertySearch) {
        propertiesSet = properties.filter((property) => property.name.toLowerCase().includes(propertySearch.toLowerCase()));
      }

      const dataSource = propertiesSet.map((property) => {
        const propertyName =  _.isEmpty(property.errorMessages) ? property.name : 
          <span>
            {property.name}
            <Infotip  
              className= 'tooltip-popover'
              content={<TooltipContents message={property?.errorMessages}/>}
              trigger="hover" // can be hover, click, focus
              placement="right">
              <img src={require('../../../assets/images/privacy-lens/alert.svg')} className="warning-url"/>
            </Infotip>
          </span> 
        
        return {
            key: new Date() + property.name,
            property: propertyName,
            type: property?.type,
            action: (<div className='actions'>
            <img src={require('../../../assets/images/privacy-lens/delete-item.svg')} className="delete-icon" onClick={() => deleteProperty(property.name)} />
          </div>)
        }
      });
      return {dataSource};       
    }

  let tablePropertyData  = generatePropertyTable();

  return (
    <Modal
        title="Add Properties"
        okText='Upload'
        onOk={handleOnClick}
        visible={props.visibleBulkProperty}
        okButtonProps={{ disabled: properties.length === 0 || propertiesCounter !== 0  }}
        onCancel={props.toggleBulkPropertyModal}
        className='new-domain-set new-standard'
      >
        <div className='modal-container modal-new-standard'>
          <div className='add-property-set-container new-set-container' >
            <div className="body-property-set">
              <div className="action-title">Import CSV file to add the list of properties.</div>
              <div className="add-properties">Import File</div>
              <div className="wrap">
                  <div className="arrow"></div>
                  <div className="arrow-cover"></div>
                  <div className="box">
                      <PropertiesFileUpload sites={props.sites} addProperties={addProperties} />
                  </div>
              </div>
              <div className='search-container'>
                <Search
                  placeholder="Search Property"
                  onChange={updateSearch}
                  className="bulk-filter"
                  value={propertySearch}
                />
                {propertiesCounter !== 0  && <span className="warning-text"><img src={require('../../../assets/images/privacy-lens/alert.svg')}/>{propertiesCounter} Properties have issues</span>}
              </div>
              <Table
                  pagination={{
                    position: ['bottomCenter'],
                    showTotal: (total) => `Total Properties: ${total}`,
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    locale: { items_per_page: ' Records per page' },
                  }}
                  scroll={{ y: 240 }}
                  bordered
                  className='bulk-sets-table'
                  columns={columns}
                  dataSource={tablePropertyData.dataSource}
              />
            </div>
          </div>
        </div>
    </Modal>
  )
}
