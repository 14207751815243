import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';

class CancelAndConfirm extends React.Component {
  static propTypes = {
    toggleCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    confirmText: PropTypes.string,
  };

  static defaultProps = {
    confirmText: 'Save',
  };

  render() {
    return (
      <div className='save-and-cancel-container'>
        <Button
          onClick={ this.props.toggleCancel }
        >
          Cancel
        </Button>
        <Button
          onClick={ this.props.onConfirm }
          className='confirm'
        >
          { this.props.confirmText }
        </Button>
      </div>
    );
  }
}

export default CancelAndConfirm;
