import React from 'react';
import { List } from 'immutable';
import moment from 'moment'; 
import { CheckOutlined } from '@ant-design/icons';
import { Icon, Popover, List as ListView } from 'antd';
import { Input, Modal, Select, message } from '../../../styleguide';
import PropertyFormRow from './PropertyFormRow';
import PropertyGroupSelect from './PropertyGroupSelect';
import { createdInPast } from './helper';
import { SiteGroup } from '../../../records/site_records';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
const { TextArea, Search } = Input;

export default class AddPropertyGroup extends React.Component {
  state = {
    propertyGroupName: '',
    propertyDesc: '',
    type: 'All',
    createdBy: '',
    sortedBy: '1',
    createdInPast: 'All',
    allSites: List(),
    propertyGroup: '',
    showSearchPopup: false,
    searchText: '',
    selectedPropertyListIds: List(),
    selectedPropertyList: List(),
  };

  componentDidMount() {
    const { editPropertyGroup, sites } = this.props;
    let allFilteredSites = sites.filter((item) => editPropertyGroup.get('siteIds').includes(item.get('id')));

    if (editPropertyGroup != undefined) {
      this.setState({
        propertyGroupName: editPropertyGroup.name,
        propertyDesc: editPropertyGroup.description,
        allSites: allFilteredSites,
      });
    }

    if (!this.props.editing) {
      this.setState({ propertyGroupName: '', propertyDesc: '' });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  removeSite = (id) => {
    let filteredSites = this.state.allSites.filter((s) => s.get('id') != id);
    this.setState({ allSites: filteredSites });
  };

  removeAllSites = () => {
    let filteredSites = this.state.allSites.clear();
    this.setState({ allSites: filteredSites });
  };
  duplicatePG = () => this.props.siteGroups.map((sg) => sg.name).includes(this.state.propertyGroupName);
  createSiteGroup = () => {
    const isGroupExist = this.duplicatePG();
    if (isGroupExist) {
      message.warning('Property Group already exist');
    } else {
      let siteGroup = new SiteGroup({});
      siteGroup = siteGroup.merge({
        name: this.state.propertyGroupName,
        description: this.state.propertyDesc,
        multiCampaignEnabled: this.props.isMultiCampaignAvailableDefault
      });
      this.props.createPropertyGroup(this.props.accountId, siteGroup).then(() => {
        if(this.props.isMultiCampaignAvailableDefault) {
          this.props.updateSites();
        }
        message.success(`Property Group '${this.state.propertyGroupName}' created successfully`);
	  });
      this.props.toggleAddPropertyGroup();
    }
  };

  filterByType = (property) => {
    let type = property.get('type');
    let selectedType = this.state.type;
    return selectedType != 'All' ? type === selectedType : true;
  };

  filterByCreatedBy = (property) => {
    let date = property.get('createdAt');
    let selectedOption = this.state.createdInPast;
    let timeAndUnit = selectedOption.split(' ');
    return selectedOption != 'All' ? moment(date).isAfter(moment().subtract(timeAndUnit[0], timeAndUnit[1])) : true;
  };

  sortByType = (a, b) => {
    let sortedBy = this.state.sortedBy;
    switch (sortedBy) {
      case '1':
        return a.get('id') > b.get('id') ? -1 : 1;
      case '2':
        return a.get('id') > b.get('id') ? 1 : -1;
      case '3':
        return a.get('domain') > b.get('domain') ? 1 : -1;
      case '4':
        return a.get('domain') > b.get('domain') ? -1 : 1;
    }
    return true;
  };

  editPropertyGroup = () => {
    const isGroupExist = this.duplicatePG();
    if (this.props.editPropertyGroup.name != this.state.propertyGroupName && isGroupExist) {
      message.warning('Property Group already exist');
    } else {
      let { propertyGroupName, propertyDesc, allSites } = this.state;
      let allSitedIds = List();
      allSites.map((item) => {
        allSitedIds = allSitedIds.push(item.get('id'));
      });
      let editedPGroup = this.props.editPropertyGroup.merge({
        name: propertyGroupName,
        description: propertyDesc,
        siteIds: allSitedIds,
      });
      this.props.updatePropertyGroup(this.props.accountId, editedPGroup, false);
      this.props.toggleAddPropertyGroup();
      message.success(`Property Group '${propertyGroupName}' updated`);
    }
  };

  showSearch = (e) => {
    this.setState({ showSearchPopup: true, searchText: e.target.value });
  };

  searchProperty = (property) => {
    let searchText = this.state.searchText;
    return searchText != '' ? property.get('domain').includes(searchText) : true;
  };

  selectProperty = (item, index) => {
    let propertyList = this.state.selectedPropertyListIds;
    let propertySelectedList = this.state.selectedPropertyList;
    if (!propertyList.includes(item.get('id'))) {
      propertyList = propertyList.push(item.get('id'));
      propertySelectedList = propertySelectedList.push(item);
    } else {
      let index = propertyList.findIndex((id) => id === item.get('id'));
      propertyList = propertyList.remove(index);
      propertySelectedList = propertySelectedList.remove(index);
    }

    this.setState({
      selectedPropertyListIds: propertyList,
      selectedPropertyList: propertySelectedList,
      showSearchPopup: true,
    });
  };

  setValues = (field, value) => {
    if (field === 'domain') {
      if (/^[a-zA-Z0-9-._~*]*$/i.test(value)) {
        this.setState({ [field]: value });
      }
    } else {
      this.setState({ [field]: value, fiterType: field });
    }
  };

  removeSelected = () => {
    let filteredSites = this.state.allSites.filter((s) => !this.state.selectedPropertyListIds.includes(s.get('id')));
    this.setState({
      allSites: filteredSites,
      selectedPropertyListIds: List(),
      selectedPropertyList: List(),
    });
  };

  resetFilter = () => {
    this.setState({
      type: 'All',
      createdBy: '',
      sortedBy: '1',
      createdInPast: 'All',
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ showSearchPopup: visible });
  };

  render() {
    const { editing, editPropertyGroup } = this.props;
    let allSites = this.state.allSites
      .filter(this.filterByType)
      .sort(this.sortByType)
      .filter(this.filterByCreatedBy)
      .filter(this.searchProperty);

    const content = (
      <div>
        <div className="popover-content-container">
          <div className="popover-content-title">
            Found {allSites.size} properties {this.state.selectedPropertyListIds.size} selected
          </div>
          {/* <div className="popover-content-title" style={{ marginLeft: '35px' }}>
            Created By
          </div> */}
          <div className="removeAll-title" onClick={() => this.removeSelected()}>
            Remove Selected
          </div>
        </div>
        <ListView>
          {allSites.size > 0 &&
            allSites.map((item, index) => (
              <ListView.Item key={index}>
                <div className="popover-content-container">
                  <div style={{ flex: 1, fontSize: '12px' }}>{item.get('domain')}</div>
                  {/* <div style={{ flex: 1, fontSize: '12px' }}>John Doe</div> */}
                  <div>
                    {
                      <CheckOutlined
                        style={{
                          fontSize: '15px',
                          fontWeight: '800',
                          color: this.state.selectedPropertyListIds.size > 0 && this.state.selectedPropertyListIds.includes(item.get('id')) ? '#411F90' : 'grey',
                        }}
                        onClick={(e) => this.selectProperty(item, index)}
                      />
                    }
                  </div>
                </div>
              </ListView.Item>
            ))}
        </ListView>
      </div>
    );

    let title = editing ? `Edit Property Group ${this.state.propertyGroupName}` : 'Add New Property Group';

    return (
      <Modal wrapClassName={editing ? 'property-group-modal' : 'property-modal'} title={title} visible={this.props.showAddPropertyGroup} onOk={editing ? this.editPropertyGroup : this.createSiteGroup} okText="Save" okButtonProps={{ disabled: !this.state.propertyGroupName }} onCancel={() => this.props.toggleAddPropertyGroup()}>
        <PropertyFormRow label="Property Group" component={<Input value={this.state.propertyGroupName} name="propertyGroupName" onChange={this.onChange} />} />
        <PropertyFormRow label="Description (optional)" component={<TextArea className='description-area' value={this.state.propertyDesc} rows={3} name="propertyDesc" onChange={this.onChange} style={{ width: '100%', height: 'auto' }} />} />
        {editing ? (
          <React.Fragment>
            <div className="pg-title">Remove properties from the Property Group</div>
            <div className="pg-subtitle">You can only remove properties which are in this property group.</div>

            <div className="pg-mainContainer">
              <div className="pg-subContainer">
                <div className="pg-search-title">Search Properties</div>
                <div onClick={() => this.resetFilter()} className="pg-clear-filter">
                  Clear filters
                </div>
              </div>
              <div className="pg-select-container">
                <div className="pg-select">
                  <div style={{ marginLeft: '0px' }} className="pg-select-label">
                    Type
                  </div>
                  <Select defaultValue={'All'} value={this.state.type} onChange={(value) => this.setValues('type', value)}>
                    <Select.Option value="All">All</Select.Option>
                    {this.props.propertyTypesNum.map((p, i) => (
                      <Select.Option key={i} value={p.get('code')}>
                        {p.get('label')}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {/* <div className="pg-select">
                  <div className="pg-select-label">Created By</div>
                  <PropertyGroupSelect
                    value={this.state.type}
                    defaultValue="web"
                    options={propertyTypesNum}
                    onChange={this.setValues}
                    state="type"
                  />
                </div> */}
                <div className="pg-select">
                  <div className="pg-select-label">Sort By</div>
                  <Select defaultValue={'Most Recent first'} value={this.state.sortedBy} onChange={(value) => this.setValues('sortedBy', value)}>
                    <Select.Option value="1">Most Recent First</Select.Option>
                    <Select.Option value="2">Oldest first</Select.Option>
                    <Select.Option value="3">Alphabetical (A-Z)</Select.Option>
                    <Select.Option value="4">Alphabetical (Z-A)</Select.Option>
                  </Select>
                </div>
                <div className="pg-select">
                  <div className="pg-select-label">Created In Past</div>
                  <PropertyGroupSelect value={this.state.createdInPast} defaultValue="All" options={createdInPast} onChange={this.setValues} state="createdInPast" />
                </div>
              </div>
              <Popover open={this.state.showSearchPopup} trigger="click" placement="bottomLeft" content={content} overlayClassName="search-popover" onOpenChange={(visible) => this.handleVisibleChange(visible)}>
                <Input className="search-properties" placeholder="Search Properties" onChange={(e) => this.showSearch(e)} onFocus={() => this.setState({ showSearchPopup: true })} suffix={<SearchOutlined />} />
              </Popover>
            </div>
            <span className="property-count-title">
              {allSites.toJS().length} Properties |{' '}
              <span onClick={() => this.removeAllSites()} className="removeAll-title">
                Remove All Properties
              </span>
            </span>
            {allSites.size > 0 && (
              <div className="pg-propertyContainer">
                {allSites.map((s, si) => (
                  <div className="pg-propertyItem" key={si}>
                    {s.get('domain')}
                    <CloseCircleFilled onClick={() => this.removeSite(s.get('id'))} className="pg-propertyIcon"/>                  
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        ) : null}
      </Modal>
    );
  }
}
