import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
  standards: new StoreRecord({ value: List([]) }),
  rules: new StoreRecord({ value: List([]) }),
  topTenRules: new StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  let updatedStandards;
  const standards = state.getIn(['standards', 'value']);
  switch(action.type) {

    case types.RULES_PENDING:
      return setPending(state, ['rules']);

    case types.RULES_LOADED:
      return setValue(state, ['rules'], action.rules);

    case types.RULES_ERROR:
      return setError(state, ['rules'], action.errorMessage);

    case types.TOP_TEN_RULES_PENDING:
      return setPending(state, ['topTenRules']);

    case types.TOP_TEN_RULES_LOADED:
      return setValue(state, ['topTenRules'], action.rules);

    case types.TOP_TEN_RULES_ERROR:
      return setError(state, ['topTenRules'], action.errorMessage);

    case types.TOP_TEN_RULES_CACHED:
      return stopPending(state, ['topTenRules']);

    case types.STANDARDS_PENDING:
      return setPending(state, ['standards']);

    case types.STANDARDS_LOADED:
      return setValue(state, ['standards'], action.standards.sortBy(s => s.name.toLowerCase()));

    case types.STANDARDS_ERROR:
      return setError(state, ['standards'], action.errorMessage);

    case types.STANDARDS_CACHED:
      return stopPending(state, ['standards']);

    case types.STANDARD_CREATED:
      updatedStandards = standards.push(action.standard).sortBy(s => s.name.toLowerCase());
      return setValue(state, ['standards'], updatedStandards);

    case types.STANDARD_UPDATED:
      updatedStandards = standards.filterNot(s => s.id === action.standard.id).push(action.standard).sortBy(l => l.name.toLowerCase());
      return setValue(state, ['standards'], updatedStandards);

    case types.STANDARD_DELETED:
      updatedStandards = standards.filterNot(s => s.id === action.id);
      return setValue(state, ['standards'], updatedStandards);
  }

  return state;
};
