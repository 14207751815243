import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';


class Reporting extends React.Component {
  
  render() {
    
    return (
      <div className='vendor-lists-container'>
        
        <p className='main-title'>Reporting</p>
        
      </div>
    );
  }
}



export default Reporting;
