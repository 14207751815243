import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { decimalRound, numberWithCommas } from '../../utils.js'
import Scenario from '../scenarios/Scenario.js.jsx';
import CustomIcon from '../../common/CustomIcon.js.jsx';
import { Tooltip } from 'antd';

import { Message } from '../../../records/message_records';
import { Scenario as ScenarioRecord } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2';
import { ViewState } from '../../../records/campaign_records';
import { updateCampaignsViewState } from '../../../actions/campaign_actions';

export default class extends React.Component {
    static propTypes = {
        scenario: PropTypes.instanceOf(ScenarioRecord).isRequired,
        messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
        active: PropTypes.bool.isRequired,
        row: PropTypes.object.isRequired,
        statsForRow: PropTypes.object.isRequired,
        manifestId: PropTypes.number.isRequired,
        campaignsViewState: PropTypes.instanceOf(ViewState).isRequired,
    };

    static defaultProps = {
        statsForRow: {
            total_pv: 0,
            total_abp: 0,
            total_msg: 0,
        },
    };

    toggleScenario = () => {
        const expandedPartitionUuids = this.props.campaignsViewState.campaigns.get(this.props.manifestId).expandedPartitionUuids;
        let newExpandedPartitionUuids ;
        if (expandedPartitionUuids.some(uuid => uuid === this.props.row.uuid)) {
            newExpandedPartitionUuids = expandedPartitionUuids.filterNot(uuid => uuid === this.props.row.uuid);
        } else {
            newExpandedPartitionUuids = expandedPartitionUuids.push(this.props.row.uuid);
        }

        const topOffSet = window.pageYOffset || document.documentElement.scrollTop;

        updateCampaignsViewState(this.props.campaignsViewState
            .setIn(['campaigns', this.props.manifestId, 'expandedPartitionUuids'], newExpandedPartitionUuids)
            .set('topOffSet', topOffSet));
    };

    render() {
        var row = this.props.row
        var bucketPercentage = ((row.ruleset.bucket_rules[0].bucket_end - row.ruleset.bucket_rules[0].bucket_start + 1) / 1000) * 100;
        var adblockRate = (this.props.statsForRow.total_abp / (this.props.statsForRow.total_pv || 1)) * 100;

        var scenarioDetails;
        var iconType;
        var tbodyClass = "";
        if (this.props.campaignsViewState.campaigns.get(this.props.manifestId).expandedPartitionUuids.some(uuid => uuid === this.props.row.uuid)) {
            scenarioDetails = (
              <tr>
                <td colSpan="10" className="scenario">
                  <Scenario
                    displayInCampaigns
                    scenario={ this.props.scenario }
                    messages={ this.props.messages }
                    active={ this.props.active }
                    altpay
                  />
                </td>
              </tr>
            );

            iconType = CustomIcon.types.COLLAPSE;
            tbodyClass = "opened";
        } else {
            iconType = CustomIcon.types.EXPAND;
        }

        return (
         <tbody className={ tbodyClass }>
            <tr className="partition-row">
              <td><Tooltip placement="topRight" title={ row.uuid }>{ row.name }</Tooltip></td>
              <td>{ row.ruleset.bucket_rules[0].bucket_start } - { row.ruleset.bucket_rules[0].bucket_end }</td>
              <td>{ decimalRound(bucketPercentage) }%</td>
              <td>{ this.props.scenario.description }</td>
              <td>{ numberWithCommas(this.props.statsForRow.total_pv) }</td>
              <td>{ numberWithCommas(this.props.statsForRow.total_msg) }</td>
              <td>{ decimalRound(adblockRate) }%</td>
              <td><CustomIcon type={ iconType } onClick={ this.toggleScenario } className="show-hide-scenario"></CustomIcon></td>
            </tr>
            { scenarioDetails }
          </tbody>
       )
    }
}
