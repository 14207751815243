import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Alert } from 'antd';  

import { getPermissionsList } from '../../actions/account_actions';
import { getZendeskJwt } from '../../actions/integrations_actions';

import Loading from '../common/Loading.js.jsx';

import { User } from '../../records/account_records';

class Zendesk extends React.Component {
  static propTypes = {
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
      allOtherUsers: ImmutablePropTypes.listOf(User).isRequired,
    }).isRequired,
    getPermissionsList: PropTypes.func.isRequired,
    getZendeskJwt: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.getZendeskJwt()
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.pendingRequestsMap.get('zendeskPending') === true && this.props.pendingRequestsMap.get('zendeskPending') === false) {
      if (this.props.zendeskToken) {
        window.location = "https://sourcepoint.zendesk.com/access/jwt" + `?jwt=${this.props.zendeskToken}&return_to=${this.props.location.query.return_to}`
      }
    }
  }

  render() {
    if (this.props.pendingRequestsMap.get('zendeskPending') === true || this.props.zendeskToken !== "") {
      return (
        <Loading />
      )
    }

    let message = "An error has occured, please try again. Contact your account administrator if the error persists"
    
    if (this.props.zendeskError === 401) {
      message = "Your account does not have access to Zendesk ticketing, please contact your account manager for access."
    } else if (this.props.zendeskError === 403) {
      message = "Your user does not have access to Zendesk ticketing, please contact your account manager for access."
    }
    
    return (
      <React.Fragment>
        <Alert message="Zendesk Authentication Error" description={ message } type="error" showIcon />
      </React.Fragment>
    )
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    permissionsList: store.accountState.getIn(['permissionsList', 'value']),
    pendingRequestsMap: Map({
      zendeskPending: store.integrations.getIn(['zendesk_token', 'pending']),
      permissionsList: store.accountState.getIn(['permissionsList', 'pending']),
    }),
    zendeskError: store.integrations.getIn(['zendesk_token', 'error']),
    zendeskToken: store.integrations.getIn(['zendesk_token', 'value'])
  };
};

export default connect(
  mapStateToProps, {
    getPermissionsList,
    getZendeskJwt
  },
)(Zendesk);