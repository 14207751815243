import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { SiteGroup } from '../../../records/site_records';

import { clearCampaignViewState } from '../../../actions/campaign_actions';

export default class extends React.Component {
  static propTypes = {
    sg: PropTypes.instanceOf(SiteGroup),
    clearCampaignViewState: PropTypes.func,
    onDelete: PropTypes.func,
  };

  state = {
    showEditMenue: false,
  }

  handleManageSiteGrop = () => {
    clearCampaignViewState();
    browserHistory.push(`/dialogue/campaigns?site_id=${this.props.sg.siteId}&site_group_id=${this.props.sg.id}`);
  };

  handleDelete = (e) => {
    e.stopPropagation();
    this.props.onDelete();
  }

  handleEdit = (e) => {
    e.stopPropagation();
    this.props.setSiteGroupToEdit(this.props.sg)
  }

  render() {
    const editMenu = (
      <Menu className="edit-menu">
        <Menu.Item>
          <p className='site-group-edit' onClick={ this.handleEdit }>Edit</p>
        </Menu.Item>
        <Menu.Item>
          <p className='site-group-delete' onClick={ this.handleDelete }>Delete</p>
        </Menu.Item>
      </Menu>
    );

    const deleteButton = (typeof this.props.onDelete === 'function') &&
    <td className='edit'>
      <Dropdown
        className='edit-dropdown-menu'
        overlay={ editMenu }
        onClick={ (e) => e.stopPropagation() }
      >
        <EllipsisOutlined />
      </Dropdown>
    </td>;

    return (
      <tr onClick={ this.handleManageSiteGrop }>
        <td>{ this.props.sg.name }</td>
        { deleteButton }
      </tr>
    );
  }
}
