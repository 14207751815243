import React, { useState } from "react";
import { Checkbox } from '../../styleguide'
import { getFlagBasedCampaignTypes } from '../utils';
const CheckboxGroup = Checkbox.Group;

function CampaignsCheckboxFilter({ currentUser, propertyType, setCampaignTypes }){
  const types = getFlagBasedCampaignTypes(currentUser, propertyType);
  const defaultCheckedCampaignTypes = types.map(t => t.value);

  const [ checkedList, setCheckedList ] = useState(defaultCheckedCampaignTypes);
  const [ indeterminate, setIndeterminate ] = useState(false);
  const [ checkAll, setCheckAll ] = useState(true);

  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < defaultCheckedCampaignTypes.length);
    setCheckAll(list.length === defaultCheckedCampaignTypes.length);
    setCampaignTypes(list);
  };

  const onCheckAllChange = e => {
    const list = e.target.checked ? defaultCheckedCampaignTypes : [];
    setCheckedList(list);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setCampaignTypes(list);
  };

  return (
    <div className="campaign-types-selection">
      <Checkbox purple indeterminate={indeterminate} defaultChecked={true} onChange={onCheckAllChange} checked={checkAll}>
        All
      </Checkbox>
      <CheckboxGroup options={types} value={checkedList} onChange={onChange} />
    </div>
  );
};

export default CampaignsCheckboxFilter;
