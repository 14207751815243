import { Record, List } from 'immutable';

export const History = Record({
  id: null,
  isActive: true,
  startDate: '',
  endDate: '',
  dealType: 'DIRECT',
  rateType: 'CPM',
  currency: 'DOLLARS',
  cpmAmount: 0,
  percentage: 0,
  category: 'DATA_PARTNER',
});

export class VendorWrapper extends Record({
  id: null,
  vendorId: null,
  history: List([]),
  isDeleted: false,
}) {
  constructor(vendorWrapper) {
    const updatedVendorWrapper = Object.assign({}, vendorWrapper);

    if (vendorWrapper._id) updatedVendorWrapper.id = vendorWrapper._id;

    if (vendorWrapper.history) {
      updatedVendorWrapper.history = List(vendorWrapper.history.map(h => new History(h)));
    }

    super(updatedVendorWrapper);
  }
}

export class ConsentCompensationList extends Record({
  id: null,
  accountId: null,
  name: 'New Consent Compensation List',
  siteIds: List([]),
  isActive: false,
  vendorsWrappers: List([]),
  isDeleted: false,
}) {
  constructor(ccl) {
    const updatedCcl = Object.assign({}, ccl);
    if (Object.keys(ccl).length) {
      updatedCcl.id = ccl._id;
      updatedCcl.siteIds = List(ccl.siteIds);
      updatedCcl.vendorsWrappers = List(ccl.vendorsWrappers).map(vw => new VendorWrapper(vw));
    }
    super(updatedCcl);
  }

  equals(ccl) {
    return this.id === ccl.id;
  }

  hashCode() {
    // Convert id to 32bit integer to avoid "maximum call stack size exceeded"
    // error caused by OrderedSet comparison
    return convertToHashCode(this.id);
  }
}

function convertToHashCode(string) {
  // Convert string to 32bit integer
  var hash = 0, i, chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr   = string.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0;
  }
  return hash;
}
