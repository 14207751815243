import React from "react";
import { Modal, Table } from "../../../styleguide";
import { createCSVExportData } from "../performanceNew/helper";
import CustomPopover from "../../common/CustomPopover";
import ExportPopoverContent from "../../ccpa/ccpa_reporting/ExportPopoverContent";
import { List } from 'immutable';
import FilterColumns from './FilterColumns';

export default class MessagePerformanceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      omitCols: List(['date', 'messagename', 'siteid']),
      msgCols: this.props.columns,
    };
  }

  setMsgCols = (filteredCols) => {
    this.setState({msgCols: this.props.columns.filter((cols) => filteredCols.includes(cols.get('dataIndex')))});
  }

  render() {
    const filteredMsgData = this.props.msgData.get('aggData').filter((item) => item.get('messageid') === this.props.msgData.get('messageid'))
    const exportReport = 
            <CustomPopover
              tooltipContent={
                <ExportPopoverContent
                  filename="message_performance"
                  exportData={createCSVExportData(
                    this.props.msgHeaders,
                    filteredMsgData || List([])
                  )}
                  pdfTitle={"Messaged Users Performance Details "}
                />
              }
              style="export-popover"
              icon="download"
              iconColor="#108ee9"
              title={
                <span
                  style={{
                    fontSize: "11px",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 100
                  }}
                >
                  Export Type Selection
                </span>
              }
            />;
    return (
      <Modal
        title={
          <span>
            Messaged Users Performance Details of{" "}
            {`${this.props.msgData.get('messageid') || ""}/${this.props.msgData.get('messagename') || ""}`}
          </span>
        }
        visible={this.props.status}
        onCancel={this.props.handleCancel}
        footer={null}
        className="msgDesModal"
      >
        <div className="msg-info-wrapper">
          <div className="partition-det">
            <span><b>Property:</b> {this.props.msgData.get('siteid')}</span>
          </div>
          <FilterColumns columns={this.props.columns} omitCols={this.state.omitCols} setCols={this.setMsgCols}/>
          {exportReport}
        </div>           
        <Table
          columns={this.state.msgCols.toJS()}
          dataSource={filteredMsgData.toJS() || []}
          className="consnet-tableCSS"
          pagination={{
            total: filteredMsgData ? filteredMsgData.length : 0,
            position: ['bottomCenter'],
            showTotal: (total) => `Total: ${total}`,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            locale: { items_per_page: "  Records per page" }
          }}
        />
      </Modal>
    );
  }
}
