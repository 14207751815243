import PropTypes from 'prop-types';
import React from 'react';

import { StringValueKVRecord } from '../../../../../../scenario_constants.js';

import { Select } from 'antd';

export default class SystemKeyValue extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(StringValueKVRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  onChange = (value) => {
    this.props.onChange(this.props.data.set('v', value));
  }

  render() {
    const options = this.props.options.map(
      option => <Select.Option key={ option.value } value={ option.value }>{ option.label }</Select.Option>);

    if (!this.props.editing) {
      let selectedOption = this.props.options.find(o => o.value === this.props.data.v);
      let valLabel = selectedOption ? selectedOption.label : this.props.v;

      return (
        <div>
          { this.props.label } : { valLabel }
        </div>
      );
    } else {
      return (
        <div className="step-block-editing">
          { this.props.children }
          <Select
            onChange={ this.onChange }
            value={ this.props.data.v }
          >
            { options }
          </Select>
        </div>
      );
    }
  }
}
