import { callbackify } from "util";

export const styles = {
    popupTitle: {
        color: '#79858F',
        fontSize: '16px',
        marginLeft: '10px',
    },
    listContentStyle: {
        maxHeight: 'calc(-200px + 100vh)',
        overflowY: 'scroll',
    },
    listContentPrivacyStyle: {
        height: '50vh',
        overflowY: 'scroll',
        paddingBottom: '150px'
    },
    iconStyle: {
        marginRight: '10px',
        fontSize:'18px'
    },
    checkContainerStyle:{
        display:'flex',
        alignItems:'center'
    },
    itemPaddingActive: {
        padding: "10px",       
    },
    itemPaddingInActive: {
        padding: "10px",
        backgroundColor:'#FFECEC'
    },
    listItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1
    },
    listItemContainerDesc: {
        display: 'flex',
        flex: 0.9,
        flexDirection: 'column'
    },
    listItemTitle: {
        color: '#212121',
        fontSize: '12px',
        fontWeight: '600',
        marginBottom: '5px'
    },
    closeIcon: {
        fontSize: '20px',
        color: '#ACB8CA',
        marginRight: '0px',
        cursor: 'pointer'
    },
    listItemDescription: {
        fontSize: '12px',
    },
    toolTipStyle: {
        fontSize: '12px',
        color:'gray',
        padding:'2px'
    },
    tooltip:{
        fontSize:'12px',
        color:'gray',
        marginLeft:'5px'
    },
    listItemStatus: {
        display: 'flex',
        flex: 0.1
    },
    headingTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    btnPrivacyStyleConf: {
        backgroundColor: '#52C41A',
        color: 'white',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    btnPrivacyStyleOther: {
        backgroundColor: 'red',
        color: 'white',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    btnPrivacyStyleUnConf: {
        backgroundColor: '#FF9148',
        color: 'white',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    popupMainTitle: {
        color: '#212121',
        fontSize: '16px',
        marginLeft: '10px',
        flex: 1
    },
    circle:{
        backgroundColor:'white',
        color:'red',
        borderRadius:'60px',
        height:'100%',
        width:'100%',
        display:'flex',
        padding:'7px',
        alignItems:'center',
        justifyContent:'center',
        marginLeft:'5px'
    },
    footerStyle:{
        display:'flex',
        flex:1,
        flexDirection:'row',
        justifyContent:'flex-end',
        paddingTop:'15px',
        paddingRight:'5px'
    },
    cmpPrivacyStyle:{
        backgroundColor: '#52C41A',
        color: 'white',
    },
    cmpPrivacyDisabledStyle:{
        backgroundColor: '#F1F3F6',
        color: 'darkgray',
        border:'darkgray'
    }
};