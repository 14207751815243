import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose } from 'redux';

import DropdownPagesHeader from '../../common/DropdownPagesHeader';

import { ChangeLog } from '../../../records/changelog_records';
import { getChangeLogs } from '../../../actions/change_log_actions';

const errorUnathorized = (Component) => {
  const WrappedChangedLog = (props) => {
    if (!props.currentUser) return null;

    let content;
    if (props.fetchError) {
      content = (
        <div className="error-msg">
          Error fetching change logs
        </div>
      )
    } else if (
      props.currentUser.featureAccess
      && !props.currentUser.featureAccess.includes('Change log')
    ) {
      content = (
        <div className="error-msg">
          Not authorized to view change logs
        </div>
      )
    } else {
      content = <Component {...props} accountId={props.currentUser.accountId}/>
    }

    return (
      <div className="changelog">
        <DropdownPagesHeader name="Change Log" />
        {content}
      </div>
    )
  }

  WrappedChangedLog.propTypes = {
    changeLogs: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(ChangeLog)),
    fetchError: PropTypes.bool,
    fetchPending: PropTypes.bool,
    getChangeLogs: PropTypes.func.isRequired,
    account_id: PropTypes.number
  };

  return WrappedChangedLog
}

const mapStateToProps = ({ changeLogsState, accountState }) => ({
  currentUser: accountState.getIn(['userDetails', 'value']),
  changeLogs: changeLogsState.getIn(['changeLogs', 'value']),
  fetchError: changeLogsState.getIn(['changeLogs', 'error']),
  fetchPending: changeLogsState.getIn(['changeLogs', 'pending']),
});

export default compose(
  connect(mapStateToProps, { getChangeLogs }),
  errorUnathorized
);