import { Record, List } from 'immutable';
import { CURRENCIES } from '../constants.js';

export class Converter extends Record({
  EUR: null,
  CHF: null,
  GBP: null,
  USD: 1,
}) {
  toUSD(price) {
    return price.update('amount', amt => amt * this.get(price.currency))
                .set('currency', CURRENCIES.USD);
  }
}

export const OffersSoldByDay = Record({
  timestampSec: '',
  timestampHr: '',
  soldOffers: List([]),
});

export const Price = Record({
  amount: 0,
  currency: 'USD',
});

export const OfferDetails = Record({
  id: null,
  siteId: null,
  entitlementId: null,
  price: new Price(),
  description: '',
});

export const OfferTotal = Record({
  offerDetails: new OfferDetails(),
  numSold: 0,
  totalPrice: new Price(),
});
