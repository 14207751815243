import PropTypes from 'prop-types';
import React from 'react';
import { Map, List } from 'immutable';
import _ from 'lodash';

import SummaryHeader from './SummaryHeader';
import ChoiceBreakdown from './ChoiceBreakdown';
import ValueBreakdown from './ValueBreakdown';
import CustomButton from '../../../common/CustomButton.js.jsx';

import { percentOfNum, numberWithCommas } from '../../../utils.js';
import { getTotalChoicesForPartition, getChoiceCountByTypeForPartition, getValuesForChoiceTypeForPartition, getTotalChoicesForCampaign, getChoiceCountByTypeForCampaign, getValuesForChoiceTypeForCampaign, getCampaignValueData, getTotalValues, getCampaignWhitelist } from '../campaign_utils.js';

import { Select } from 'antd';

import { CHOICE_NAMES_READABLE } from '../../../../constants.js';
const Option = Select.Option;

const CHOICE_TYPE_TO_DESCRIPTION = {
    1: "PVs",
    2: "PVs",
    3: "Videos",
    4: "Completed",
    5: "Redirected",
    6: "PVs",
    7: "Logged in"
}

export default class extends React.Component {
    static propTypes = {
        partitionsetData: PropTypes.object.isRequired,
        scenarios: PropTypes.object.isRequired,
        messages: PropTypes.object.isRequired,
        active: PropTypes.bool.isRequired,
        summaryStats: PropTypes.object.isRequired,
        forCampaign: PropTypes.bool,
        accountId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        forCampaign: false,
    };

    constructor(props) {
        super(props);
        var selectedPartitionUuid;
        if (props.partitionsetData.partitions.length) {
          selectedPartitionUuid = props.partitionsetData.partitions[0].uuid;
        }

        this.state = {
          showScenario: false,
          selectedPartitionUuid,
          showLegacy: false,
        };
    }

    setSelectedDropdown = (value) => {
        this.setState({ selectedPartitionUuid: value });
    };

    generateChoiceTableDatasource = () => {
        var partitions = _.values(this.props.summaryStats.partition_data);
        var partition = _.find(partitions, (partition) => {
            return partition.partition_uuid === this.state.selectedPartitionUuid;
        });

        var totalChoices;
        var choiceCountByType;
        var valuesForChoiceType;
        var totalMessages;
        if ( !this.props.forCampaign ) {
            totalChoices = getTotalChoicesForPartition(partition);
            choiceCountByType = getChoiceCountByTypeForPartition(partition);
            valuesForChoiceType = getValuesForChoiceTypeForPartition(partition);
            totalMessages = partition.partition_stats.total_msg;
        } else {
            totalChoices = getTotalChoicesForCampaign(partitions);
            choiceCountByType = getChoiceCountByTypeForCampaign(partitions);
            valuesForChoiceType = getValuesForChoiceTypeForCampaign(partitions);
            totalMessages = this.props.summaryStats.campaign_stats.total_msg;
        }

        var dataSource = Object.keys(choiceCountByType).map(choiceType => {
            var obj = {};
            obj.key = choiceType;
            obj.choice = CHOICE_NAMES_READABLE[choiceType];
            obj.clicks = numberWithCommas(choiceCountByType[choiceType].count);
            obj.rate = percentOfNum(choiceCountByType[choiceType].count, totalMessages);
            obj.value = valuesForChoiceType[choiceType];
            obj.description = CHOICE_TYPE_TO_DESCRIPTION[choiceType];
            return obj;
        })

        var total = {};
        total.key = "total";
        total.choice = "Total";
        total.clicks = numberWithCommas(totalChoices);
        total.rate = percentOfNum(totalChoices, totalMessages);
        total.value = "";
        total.description = "";
        total.className = "total"

        dataSource.push(total);
        return dataSource;
    };

    getHeaderData = () => {
        var partitions = _.values(this.props.summaryStats.partition_data);
        var partition = _.find(partitions, (partition) => {
            return partition.partition_uuid === this.state.selectedPartitionUuid
        });

        var campaignStats;
        var totalChoices;

        if ( !this.props.forCampaign ) {
            campaignStats = Object.assign({}, this.props.summaryStats.partition_data[this.state.selectedPartitionUuid].partition_stats);
            totalChoices = getTotalChoicesForPartition(partition);
        } else {
            campaignStats = Object.assign({}, this.props.summaryStats.campaign_stats);
            totalChoices = getTotalChoicesForCampaign(partitions);
        }

        return { campaignStats, totalChoices }
    };

    getValuesTableData = () => {
        var partitions = _.values(this.props.summaryStats.partition_data);
        if (!partitions) return {};

        if ( this.props.forCampaign ) {
            return {
                totalValues: getCampaignValueData(partitions),
                whitelistValues: getCampaignWhitelist(partitions),
            }
        } else {
            var partition = _.find(partitions, (partition) => {
                return partition.partition_uuid === this.state.selectedPartitionUuid
            });
            return {
                totalValues: getTotalValues(partition.value_data),
                whitelistValues: partition.value_data.wlpv,
            }
        };
    };

    isLegacyClient = () => {
        const legacyClients = List([345, 200]);
        return legacyClients.includes(this.props.accountId);
    };

    switchVersion = () => {
        this.setState({ showLegacy: !this.state.showLegacy });
    };

    render() {

        var partitionDropdown;

        if ( !this.props.forCampaign ) {

            partitionDropdown = (
                <div className="partition-selector">
                  <p>Selected partition: </p>
                  <Select
                    defaultValue={ this.state.selectedPartitionUuid }
                    onChange={ this.setSelectedDropdown }
                    dropdownClassName="campaign-summary"
                    dropdownMatchSelectWidth={ false }
                  >
                    { this.props.partitionsetData.partitions.map(partition => {
                        if (partition.partition_uuid === "control") return null;
                        return (
                            <Option key={ partition.uuid } value={ partition.uuid }>{ partition.name }</Option>
                        )
                    })}
                  </Select>
                </div>
            )
        }

        if (!this.props.forCampaign && !this.props.summaryStats.partition_data[this.state.selectedPartitionUuid]) {
            return (
              <div className="campaign-summary-container">
                { partitionDropdown }
                <p className="no-data-comment">There is no data for this partition.</p>
              </div>
            )
        }

        var headerData = this.getHeaderData();
        var divisionLine;
        let noChoicesMessage;
        var tables;

        if ( headerData.totalChoices) {

            if (this.state.showLegacy) {
                tables = (
                  <div className="tables">
                    <div className="breakdown-table-container old-version">
                      <ChoiceBreakdown choicesRateValueArray={ this.generateChoiceTableDatasource() } />
                    </div>
                  </div>
                );
            } else {
                var partitions = _.values(this.props.summaryStats.partition_data);
                if (partitions.length && partitions[0].value_data) {
                    const valuesData = this.getValuesTableData()
                    tables = (
                        <div className="tables clearfix">
                          <div className="values-table-container">
                            <h2>Value</h2>
                            <ValueBreakdown
                              siteId={ this.props.siteId }
                              totalValues={ Map(valuesData.totalValues) }
                              whitelistValues={ List(valuesData.whitelistValues) }
                              campaignCreateDate={ this.props.campaign.created_at }
                              campaignId={ this.props.campaign.id }
                            />
                          </div>
                        </div>
                    );
                }
            }
        };

        if (tables) divisionLine = <div className="devision-line"></div>;
        if (!tables) noChoicesMessage = <div className="no-choices-message" >There were no choices made</div>;

        let legacyButton;
        if (this.isLegacyClient() && tables) {
            legacyButton = (
                <CustomButton onClick={ this.switchVersion } type={ CustomButton.types.PLAIN }>
                    { this.state.showLegacy ? "Switch to Value Report" : "Switch to Legacy" }
                </CustomButton>
            )
        }

        return (
            <div className="campaign-summary-container">

              { partitionDropdown }

              <div className="campaign-summary">

              <SummaryHeader
                totalChoices={ headerData.totalChoices }
                campaignStats={ headerData.campaignStats }
                accountId={ this.props.accountId }
              />

              { divisionLine }

              { legacyButton }

              { tables }
              { noChoicesMessage }

            </div>
          </div>
        )
    }
}
