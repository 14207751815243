import React, { Component } from 'react';
import { Input, Select } from 'antd';

import { COUNTRY_LANGUAGES, defaultDisclosureText } from '../../../../../../../constants';

const { Option } = Select;

class InputLanguage extends Component {
  state = {
    consentDisclosureModified: {},
  }

  componentDidMount = () => {
    const { value, allSettings: { languages = {} } } = this.props;

    if (Object.keys(languages).length === 0) {
      this.updateText({ target: { value } });
    }
  }

  updateText = ({ target: { value } }) => {
    const {
      settingKey,
      defaultLanguage,
      updateSetting,
      allSettings,
      messageSubcategory,
      settingsTitle,
      allSettings: {
        languages = {},
      },
    } = this.props;

    const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;
    const languagesClone = Object.assign({}, languages || {});
    if (!languagesClone[selectedLanguage]) {
      languagesClone[selectedLanguage] = {};
    };
    languagesClone[selectedLanguage][settingKey] = value;
    
    if (messageSubcategory !== 'native-ott' || (messageSubcategory === 'native-ott' && defaultLanguage !== selectedLanguage)) {
      updateSetting('languages', languagesClone);
    } else if (messageSubcategory === 'native-ott' && defaultLanguage === selectedLanguage) {
      updateSetting(settingKey, value, { languages: languagesClone });
    } 

    if (settingKey === "consentDisclosure" && value !== undefined) {
      this.setState(prevState => ({
        consentDisclosureModified: {
          ...prevState.consentDisclosureModified,
          [selectedLanguage]: true
        }
      }));
    }
  }

  render() {
    const {
      updateSetting,
      value,
      defaultLanguage,
      settingsTitle,
      settingKey,
      messageSubcategory,
      messageCategory,
      allSettings,
      allSettings: {
        languages = {},
      }
    } = this.props;

    const selectedLanguage = allSettings.selectedLanguage || defaultLanguage;
    const currentValue = (defaultLanguage === selectedLanguage)
      ? ((languages[selectedLanguage] !== undefined) ?
        (languages[selectedLanguage][settingKey] !== undefined ? languages[selectedLanguage][settingKey] : value) : value)
      : (languages[selectedLanguage] !== undefined)
        ? languages[selectedLanguage][settingKey]
        : '';

    const defaultConsentDisclosure = defaultDisclosureText[selectedLanguage.toLowerCase()];
    let input;
    if (settingKey === "consentDisclosure") {
      input = <Input.TextArea value={this.state.consentDisclosureModified[selectedLanguage] ? currentValue : currentValue || defaultConsentDisclosure} onChange={this.updateText} autoSize/>
    } else {
      input = <Input value={currentValue} onChange={this.updateText} />
    }

    return (
      <div className="input-language-container">
        {/* {(messageSubcategory === 'native-ott' && messageCategory === 'gdpr' && settingKey === 'text') && (
          <div className="screen" />
        )} */}
        <div className="set-input set-languages">
          <h4>{settingsTitle}</h4>
          { input }
        </div>
        <div className="four-options">
          <div className="text-language">
            <h4>Language </h4>
            <Select
              value={selectedLanguage}
              onChange={(language => updateSetting('selectedLanguage', language))}
            >
              {COUNTRY_LANGUAGES.map(({ code, language }) => <Option key={code}>{language}</Option>)}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}

export default InputLanguage;
