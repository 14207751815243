export const SITES_PENDING = "SITES_PENDING";
export const SITES_CACHED = "SITES_CACHED";
export const SITES_LOADED = "SITES_LOADED";
export const SITE_ADDED = "SITE_ADDED";
export const SITE_DELETED = "SITE_DELETED";
export const SITES_ERROR = "SITES_ERROR";
export const SET_CURRENT_SITE = "SET_CURRENT_SITE";
export const SITE_UPDATED = "SITE_UPDATED";

export const USER_DETAILS_PENDING = "USER_DETAILS_PENDING";
export const USER_DETAILS_LOADED = "USER_DETAILS_LOADED";
export const USER_DETAILS_CACHED = "USER_DETAILS_CACHED";
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const ACCOUNT_PERMISSIONS_PENDING = "ACCOUNT_PERMISSIONS_PENDING";
export const ACCOUNT_PERMISSIONS_LOADED = "ACCOUNT_PERMISSIONS_LOADEDG";
export const ACCOUNT_PERMISSIONS_ERROR = "ACCOUNT_PERMISSIONS_ERROR";
export const PERMISSIONS_LIST_PENDING = "PERMISSIONS_LIST_PENDING";
export const PERMISSIONS_LIST_LOADED = "PERMISSIONS_LIST_LOADED";
export const PERMISSIONS_LIST_ERROR = "PERMISSIONS_LIST_ERROR";
export const DIAGNOSE_LIST_PENDING = "DIAGNOSE_LIST_PENDING";
export const DIAGNOSE_LIST_LOADED = "DIAGNOSE_LIST_LOADED";
export const DIAGNOSE_LIST_ERROR = "DIAGNOSE_LIST_ERROR";
export const CLEAR_USER_DETAILS_ERROR = "CLEAR_USER_DETAILS_ERROR";
export const UPDATE_USER_PENDING = "UPDATE_USER_PENDING";
export const ADD_TO_FETCHED = "ADD_TO_FETCHED";
export const EMAIL_REPORT_PENDING = "EMAIL_REPORT_PENDING";
export const EMAIL_REPORT_SUCCESS = "EMAIL_REPORT_SUCCESS";
export const EMAIL_REPORT_ERROR = "EMAIL_REPORT_ERROR";
export const MESSAGES_PENDING = "MESSAGES_PENDING";
export const MESSAGE_CREATE_PENDING = "MESSAGE_CREATE_PENDING";
export const MESSAGE_UPDATE_PENDING = "MESSAGES_UPDATE_PENDING";
export const MESSAGE_IMPORT_PENDING = "MESSAGE_IMPORT_PENDING";
export const MESSAGES_CACHED = "MESSAGES_CACHED";
export const MESSAGES_LOADED = "MESSAGES_LOADED";
export const MESSAGES_ERROR = "MESSAGES_ERROR";
export const MESSAGES_DELETED = "MESSAGES_DELETED";
export const DRAFT_MESSAGE_IMPORTED = "DRAFT_MESSAGE_IMPORTED";
export const MESSAGE_WITH_DETAIL_LOADED = "MESSAGE_WITH_DETAIL_LOADED";
export const MESSAGE_CREATED = "MESSAGE_CREATED";
export const MESSAGE_UPDATED = "MESSAGE_UPDATED";

export const MESSAGES_PENDING_V2 = "MESSAGES_PENDING_V2";
export const MESSAGES_LOADED_V2 = "MESSAGES_LOADED_V2";
export const MESSAGES_ERROR_V2 = "MESSAGES_ERROR_V2";

export const SCENARIOS_PENDING = "SCENARIOS_PENDING";
export const SCENARIOS_CACHED = "SCENARIOS_CACHED";
export const SCENARIOS_LOADED = "SCENARIOS_LOADED";
export const SCENARIOS_ERROR = "SCENARIOS_ERROR";
export const CREATE_SCENARIO_LOADED = "CREATE_SCENARIO_LOADED";
export const UPDATE_SCENARIO_LOADED = "UPDATE_SCENARIO_LOADED";
export const SCENARIO_DELETED = "SCENARIO_DELETED";
export const SCENARIO_IMPORT_PENDING = "SCENARIO_IMPORT_PENDING";
export const SCENARIO_IMPORTED = "SCENARIO_IMPORTED";

export const PARTITIONSETS_PENDING = "PARTITIONSETS_PENDING";
export const PARTITIONSETS_CACHED = "PARTITIONSETS_CACHED";
export const PARTITIONSETS_LOADED = "PARTITIONSETS_LOADED";
export const PARTITIONSETS_ERROR = "PARTITIONSETS_ERROR";
export const CREATE_PARTITIONSET_LOADED = "CREATE_PARTITIONSET_LOADED";
export const UPDATE_PARTITIONSET_LOADED = "UPDATE_PARTITIONSET_LOADED";
export const PARTITIONSET_DELETED = "PARTITIONSET_DELETED";
export const PARTITIONSET_IMPORT_PENDING = "PARTITIONSET_IMPORT_PENDING";
export const PARTITIONSET_IMPORTED = "PARTITIONSET_IMPORTED";

export const ADBLOCK_PENDING = "ADBLOCK_PENDING";
export const ADBLOCK_LOADED = "ADBLOCK_LOADED";
export const ADBLOCK_ERROR = "ADBLOCK_ERROR";

export const WHITELIST_PENDING = "WHITELIST_PENDING";
export const WHITELIST_LOADED = "WHITELIST_LOADED";
export const WHITELIST_ERROR = "WHITELIST_ERROR";

export const CONSENT_REPORT_PENDING = "CONSENT_REPORT_PENDING";
export const CONSENT_REPORT_LOADED = "CONSENT_REPORT_LOADED";
export const CONSENT_REPORT_ERROR = "CONSENT_REPORT_ERROR";

export const CONSENT_REPORT_ACTION_STATUS_PENDING =
  "CONSENT_REPORT_ACTION_STATUS_PENDING";
export const CONSENT_REPORT_ACTION_STATUS_LOADED =
  "CONSENT_REPORT_ACTION_STATUS_LOADED";
export const CONSENT_REPORT_ACTION_STATUS_ERROR =
  "CONSENT_REPORT_ACTION_STATUS_ERROR";
export const CONSENT_REPORT_VENDORS_PENDING = "CONSENT_REPORT_VENDORS_PENDING";
export const CONSENT_REPORT_VENDORS_LOADED = "CONSENT_REPORT_VENDORS_LOADED";
export const CONSENT_REPORT_VENDORS_ERROR = "CONSENT_REPORT_VENDORS_ERROR";

export const CONSENT_REPORT_PROPERTY_PENDING =
  "CONSENT_REPORT_PROPERTY_PENDING";
export const CONSENT_REPORT_PROPERTY_LOADED = "CONSENT_REPORT_PROPERTY_LOADED";
export const CONSENT_REPORT_PROPERTY_ERROR = "CONSENT_REPORT_PROPERTY_ERROR";
export const CONSENT_REPORT_PROPERTY_CACHED = "CONSENT_REPORT_PROPERTY_CACHED";
export const CONSENT_REPORT_VENDOR_PENDING = "CONSENT_REPORT_VENDOR_PENDING";
export const CONSENT_REPORT_VENDOR_LOADED = "CONSENT_REPORT_VENDOR_LOADED";
export const CONSENT_REPORT_VENDOR_ERROR = "CONSENT_REPORT_VENDOR_ERROR";
export const CONSENT_REPORT_VENDOR_CACHED = "CONSENT_REPORT_VENDOR_CACHED";
export const CONSENT_REPORT_MESSAGE_PENDING = "CONSENT_REPORT_MESSAGE_PENDING";
export const CONSENT_REPORT_MESSAGE_LOADED = "CONSENT_REPORT_MESSAGE_LOADED";
export const CONSENT_REPORT_MESSAGE_ERROR = "CONSENT_REPORT_MESSAGE_ERROR";
export const CONSENT_REPORT_MESSAGE_CACHED = "CONSENT_REPORT_MESSAGE_CACHED";
export const CONSENT_REPORT_PURPOSE_PENDING = "CONSENT_REPORT_PURPOSE_PENDING";
export const CONSENT_REPORT_PURPOSE_LOADED = "CONSENT_REPORT_PURPOSE_LOADED";
export const CONSENT_REPORT_PURPOSE_ERROR = "CONSENT_REPORT_PURPOSE_ERROR";
export const CONSENT_REPORT_PURPOSE_CACHED = "CONSENT_REPORT_PURPOSE_CACHED";
export const CONSENT_REPORT_CHANGELOG_PENDING =
  "CONSENT_REPORT_CHANGELOG_PENDING";
export const CONSENT_REPORT_CHANGELOG_LOADED =
  "CONSENT_REPORT_CHANGELOG_LOADED";
export const CONSENT_REPORT_CHANGELOG_ERROR = "CONSENT_REPORT_CHANGELOG_ERROR";
export const CONSENT_REPORT_CHANGELOG_CACHED =
  "CONSENT_REPORT_CHANGELOG_CACHED";

export const CAMPAIGNS_PENDING = "CAMPAIGNS_PENDING";
export const CAMPAIGNS_CACHED = "CAMPAIGNS_CACHED";
export const CAMPAIGNS_LOADED = "CAMPAIGNS_LOADED";
export const CAMPAIGN_STOPPED = "CAMPAIGN_STOPPED";
export const CAMPAIGN_STOPPED_UPDATE = "CAMPAIGN_STOPPED_UPDATE";
export const CAMPAIGNS_ERROR = "CAMPAIGNS_ERROR";
export const CAMPAIGN_STATS_PENDING = "CAMPAIGN_STATS_PENDING";
export const CAMPAIGN_STATS_CACHED = "CAMPAIGN_STATS_CACHED";
export const CAMPAIGN_STATS_LOADED = "CAMPAIGN_STATS_LOADED";
export const CAMPAIGN_STATS_ERROR = "CAMPAIGN_STATS_ERROR";
export const CAMPAIGN_GRAPH_PENDING = "CAMPAIGN_GRAPH_PENDING";
export const CAMPAIGN_GRAPH_CACHED = "CAMPAIGN_GRAPH_CACHED";
export const CAMPAIGN_GRAPH_LOADED = "CAMPAIGN_GRAPH_LOADED";
export const CAMPAIGN_GRAPH_ERROR = "CAMPAIGN_GRAPH_ERROR";

export const ALTPAY_PENDING = "ALTPAY_PENDING";
export const ALTPAY_LOADED = "ALTPAY_LOADED";
export const ALTPAY_ERROR = "ALTPAY_ERROR";
export const EXCHANGE_PENDING = "EXCHANGE_PENDING";
export const EXCHANGE_LOADED = "EXCHANGE_LOADED";
export const EXCHANGE_ERROR = "EXCHANGE_ERROR";

export const SITE_FONTS_PENDING = "SITE_FONTS_PENDING";
export const SITE_FONTS_LOADED = "SITE_FONTS_LOADED";
export const SITE_FONTS_ERROR = "SITE_FONTS_ERROR";

export const UPDATE_CAMPAIGNS_VIEW_STATE = "UPDATE_CAMPAIGNS_VIEW_STATE";
export const CLEAR_CAMPAIGN_VIEW_STATE = "CLEAR_CAMPAIGN_VIEW_STATE";

export const ARCHIVE_SCENARIOS = "ARCHIVE_SCENARIOS";
export const ARCHIVE_MESSAGES = "ARCHIVE_MESSAGES";

export const DASHBOARD_PENDING = "DASHBOARD_PENDING";
export const DASHBOARD_LOADED = "DASHBOARD_LOADED";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";

export const REPORTS_LIST_PENDING = "REPORTS_LIST_PENDING";
export const REPORTS_LIST_LOADED = "REPORTS_LIST_LOADED";
export const REPORTS_LIST_ERROR = "REPORTS_LIST_ERROR";
export const REQUEST_SUBMITTED = "REQUEST_SUBMITTED";

export const VENDOR_LISTS_PENDING = "VENDOR_LISTS_PENDING";
export const GLOBAL_VENDORS_PENDING = "GLOBAL_VENDORS_PENDING";
export const GLOBAL_VENDORS_LOADED = "GLOBAL_VENDORS_LOADED";
export const MSPS_SYSTEM_VENDORS_PENDING = "MSPS_SYSTEM_VENDORS_PENDING";
export const MSPS_SYSTEM_VENDORS_LOADED = "MSPS_SYSTEM_VENDORS_LOADED";
export const GLOBAL_VENDORS_HASH_PENDING = "GLOBAL_VENDORS_HASH_PENDING";
export const GLOBAL_VENDORS_HASH_LOADED = "GLOBAL_VENDORS_HASH_LOADED";
export const SCANNED_VENDORS_PENDING = "SCANNED_VENDORS_PENDING";
export const SCANNED_VENDORS_LOADED = "SCANNED_VENDORS_LOADED";
export const UNKNOWN_VENDORS_LOADED = "UNKNOWN_VENDORS_LOADED";
export const CLEAR_SCANNED_VENDORS = "CLEAR_SCANNED_VENDORS";
export const ENABLED_SITES_FOR_SCAN_LOADED = "ENABLED_SITES_FOR_SCAN_LOADED";
export const VENDOR_LISTS_LOADED = "VENDOR_LISTS_LOADED";
export const VENDOR_LISTS_ERROR = "VENDOR_LISTS_ERROR";
export const VENDOR_LISTS_SCAN_ERROR = "VENDOR_LISTS_SCAN_ERROR";
export const VENDOR_LIST_CREATED = "VENDOR_LIST_CREATED";
export const VENDOR_LIST_UPDATED = "VENDOR_LIST_UPDATED";
export const VENDOR_LIST_ACTIVATION = "VENDOR_LIST_ACTIVATION";
export const VENDOR_LIST_DELETED = "VENDOR_LIST_DELETED";
export const VENDOR_LIST_SAVE_PENDING = "VENDOR_LIST_SAVE_PENDING";
export const VENDOR_LIST_LOADED = "VENDOR_LIST_LOADED";
export const PURPOSES_PENDING = "PURPOSES_PENDING";
export const PURPOSES_LOADED = "PURPOSES_LOADED";
export const PURPOSES_ERROR = "PURPOSES_ERROR";

export const STACKS_PENDING = "STACKS_PENDING";
export const STACKS_LOADED = "STACKS_LOADED";
export const STACKS_ERROR = "STACKS_ERROR";

export const IAB_SPECIAL_FEATURES_PENDING = "IAB_SPECIAL_FEATURES_PENDING";
export const IAB_SPECIAL_FEATURES_LOADED = "IAB_SPECIAL_FEATURES_LOADED";
export const IAB_SPECIAL_FEATURES_ERROR = "IAB_SPECIAL_FEATURES_ERROR";
export const IAB_SPECIAL_PURPOSES_LOADED = "IAB_SPECIAL_PURPOSES_LOADED";
export const IAB_FEATURES_LOADED = "IAB_FEATURES_LOADED";

export const CLEAR_VENDOR_LIST_ERROR = "CLEAR_VENDOR_LIST_ERROR";
export const VENDOR_LISTS_CACHED = "VENDOR_LISTS_CACHED";

export const PRIVACY_MANAGER_LIST_PENDING = "PRIVACY_MANAGER_LIST_PENDING";
export const PRIVACY_MANAGER_LIST_LOADED = "PRIVACY_MANAGER_LIST_LOADED";
export const PRIVACY_MANAGER_LIST_ERROR = "PRIVACY_MANAGER_LIST_ERROR";
export const PRIVACY_MANAGER_LOADED = "PRIVACY_MANAGER_LOADED";
export const PRIVACY_MANAGER_CREATED = "PRIVACY_MANAGER_CREATED";
export const PRIVACY_MANAGER_UPDATED = "PRIVACY_MANAGER_UPDATED";
export const PRIVACY_MANAGER_DELETED = "PRIVACY_MANAGER_DELETED";
export const PRIVACY_MANAGER_SAVE_PENDING = "PRIVACY_MANAGER_SAVE_PENDING";
export const SITE_PRIVACY_MANAGER_LIST_PENDING =
  "SITE_PRIVACY_MANAGER_LIST_PENDING";
export const SITE_PRIVACY_MANAGER_LIST_LOADED =
  "SITE_PRIVACY_MANAGER_LIST_LOADED";
export const SITE_PRIVACY_MANAGER_LIST_ERROR =
  "SITE_PRIVACY_MANAGER_LIST_ERROR";
export const DUMP_SITE_PRIVACY_MANAGER_LIST = "DUMP_SITE_PRIVACY_MANAGER_LIST";

export const USER_ACCESS_CHANGED = "USER_ACCESS_CHANGED";
export const USERS_PENDING = "USERS_PENDING";
export const USERS_ERROR = "USERS_ERROR";
export const INVITED_USER_PENDING = "INVITED_USER_PENDING";
export const INVITED_USER_ERROR = "INVITED_USER_ERROR";
export const USER_INVITED = "USER_INVITED";
export const USER_DELETED = "USER_DELETED";

export const DOMAINS_LOADED = "DOMAINS_LOADED";
export const DOMAIN_ADDED = "DOMAIN_ADDED";
export const DOMAIN_DELETED = "DOMAIN_DELETED";
export const DOMAINS_PENDING = "DOMAINS_PENDING";
export const DOMAINS_ERROR = "DOMAINS_ERROR";

export const SITE_GROUPS_PENDING = "SITE_GROUPS_PENDING";
export const SITE_GROUPS_CACHED = "SITE_GROUPS_CACHED";
export const SITE_GROUPS_LOADED = "SITE_GROUPS_LOADED";
export const SITE_GROUP_CREATED = "SITE_GROUP_CREATED";
export const SITE_GROUP_UPDATED = "SITE_GROUP_UPDATED";
export const SITE_GROUP_DELETED = "SITE_GROUP_DELETED";
export const SITE_GROUPS_ERROR = "SITE_GROUPS_ERROR";
export const SITE_GROUP_LOADED = "SITE_GROUP_LOADED";
export const SET_SITE_GROUP_SITES_WITH_ERRORS =
  "SET_SITE_GROUP_SITES_WITH_ERRORS";

export const CREATE_ACCOUNT_PENDING = "CREATE_ACCOUNT_PENDING";
export const ACCOUNT_CREATED = "ACCOUNT_CREATED";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";

export const CHANGE_LOGS_PENDING = "CHANGE_LOGS_PENDING";
export const CHANGE_LOGS_LOADED = "CHANGE_LOGS_LOADED";
export const CHANGE_LOGS_ERROR = "CHANGE_LOGS_ERROR";

export const SET_MASQ_USER_NAME = "SET_MASQ_USER_NAME";

export const ACCOUNT_OWNERS_PENDING = "ACCOUNT_OWNERS_PENDING";
export const ACCOUNT_OWNERS_ERROR = "ACCOUNT_OWNERS_ERROR";
export const ACCOUNT_OWNERS_LOADED = "ACCOUNT_OWNERS_LOADED";
export const ACCOUNT_FEATURES_UPDATED = "ACCOUNT_FEATURES_UPDATED";

export const ACCOUNTS_PENDING = "ACCOUNTS_PENDING";
export const ACCOUNTS_ERROR = "ACCOUNTS_ERROR";
export const ACCOUNTS_LOADED = "ACCOUNTS_LOADED";

export const DNS_MESSAGES_PENDING = "DNS_MESSAGES_PENDING";
export const DNS_MESSAGES_LOADED = "DNS_MESSAGES_LOADED";
export const DNS_MESSAGES_ERROR = "DNS_MESSAGES_ERROR";

export const DATA_REQUEST_CCPA_PENDING = "DATA_REQUEST_CCPA_PENDING";
export const DATA_REQUEST_CCPA_LOADED = "DATA_REQUEST_CCPA_LOADED";
export const DATA_REQUEST_CCPA_ERROR = "DATA_REQUEST_CCPA_ERROR";
export const DATA_REQUEST_CCPA_CACHED = "DATA_REQUEST_CCPA_CACHED";
export const CCPA_REPORTING_PENDING = "CCPA_REPORTING_PENDING";
export const CCPA_REPORTING_LOADED = "CCPA_REPORTING_LOADED";
export const CCPA_REPORTING_ERROR = "CCPA_REPORTING_ERROR";
export const CCPA_REPORTING_CACHED = "CCPA_REPORTING_CACHED";
export const DSAR_REQUESTS_PENDING = "DSAR_REQUESTS_PENDING";
export const DSAR_REQUESTS_LOADED = "DSAR_REQUESTS_LOADED";
export const DSAR_REQUESTS_ERROR = "DSAR_REQUESTS_ERROR";

export const DSAR_FILE_LOADED = "DSAR_FILE_LOADED";
export const DSAR_FILE_UPLOADED = "DSAR_FILE_UPLOADED";
export const DSAR_COMPLETED = "DSAR_COMPLETED";

export const CONSENT_COMPENSATION_LISTS_PENDING = "CONSENT_COMPENSATION_LISTS_PENDING"
export const CONSENT_COMPENSATION_LISTS_LOADED = "CONSENT_COMPENSATION_LISTS_LOADED"
export const CONSENT_COMPENSATION_LISTS_ERROR = "CONSENT_COMPENSATION_LISTS_ERROR"
export const CONSENT_COMPENSATION_LIST_CREATED = "CONSENT_COMPENSATION_LIST_CREATED"
export const CONSENT_COMPENSATION_LIST_UPDATED = "CONSENT_COMPENSATION_LIST_UPDATED"
export const CONSENT_COMPENSATION_LIST_DELETED = "CONSENT_COMPENSATION_LIST_DELETED"
export const CONSENT_COMPENSATION_LIST_SAVE_PENDING = "CONSENT_COMPENSATION_LIST_SAVE_PENDING"
export const CONSENT_COMPENSATION_LIST_LOADED = "CONSENT_COMPENSATION_LIST_LOADED"
export const CLEAR_CONSENT_COMPENSATION_LIST_ERROR = "CLEAR_CONSENT_COMPENSATION_LIST_ERROR"
export const CONSENT_COMPENSATION_LISTS_CACHED = "CONSENT_COMPENSATION_LISTS_CACHED"
export const GET_PARTNERS_PENDING = "GET_PARTNERS_PENDING"
export const PARTNERS_LOADED = "PARTNERS_LOADED"
export const GET_PARTNERS_ERROR = "GET_PARTNERS_ERROR"
export const CLEAR_CONSENT_COMPENSATION_LISTS_ERROR = "CLEAR_CONSENT_COMPENSATION_LISTS_ERROR";

export const DOMAIN_SETS_PENDING = "DOMAIN_SETS_PENDING"
export const DOMAIN_SETS_LOADED = "DOMAIN_SETS_LOADED"
export const DOMAIN_SETS_ERROR = "DOMAIN_SETS_ERROR"
export const DOMAIN_SET_CREATED = "DOMAIN_SET_CREATED"
export const DOMAIN_SET_UPDATED = "DOMAIN_SET_UPDATED"
export const DOMAIN_SET_DELETED = "DOMAIN_SET_DELETED"
export const DOMAIN_SET_CREATE_OR_UPDATE_PENDING = "DOMAIN_SET_CREATE_OR_UPDATE_PENDING"

export const GOLDEN_SETS_PENDING = "GOLDEN_SETS_PENDING"
export const GOLDEN_SETS_LOADED = "GOLDEN_SETS_LOADED"
export const GOLDEN_SET_LOADED = "GOLDEN_SET_LOADED"
export const GOLDEN_SETS_CACHED = "GOLDEN_SETS_CACHED"
export const GOLDEN_SETS_ERROR = "GOLDEN_SETS_ERROR"
export const GOLDEN_SET_CREATED = "GOLDEN_SET_CREATED"
export const GOLDEN_SET_UPDATED = "GOLDEN_SET_UPDATED"
export const GOLDEN_SET_DELETED = "GOLDEN_SET_DELETED"
export const GOLDEN_SET_CREATE_OR_UPDATE_PENDING = "GOLDEN_SET_CREATE_OR_UPDATE_PENDING"
export const RULE_PERFORMANCE_DATA_PENDING = "RULE_PERFORMANCE_DATA_PENDING"
export const RULE_PERFORMANCE_DATA_LOADED = "RULE_PERFORMANCE_DATA_LOADED"
export const RULE_PERFORMANCE_DATA_ERROR = "RULE_PERFORMANCE_DATA_ERROR"
export const RULE_PERFORMANCE_DATA_CACHED = "RULE_PERFORMANCE_DATA_CACHED"

export const STANDARDS_PENDING = "STANDARDS_PENDING"
export const STANDARDS_LOADED = "STANDARDS_LOADED"
export const STANDARDS_ERROR = "STANDARDS_ERROR"
export const STANDARD_CREATED = "STANDARD_CREATED"
export const STANDARD_UPDATED = "STANDARD_UPDATED"
export const STANDARD_DELETED = "STANDARD_DELETED"
export const RULES_PENDING = "RULES_PENDING"
export const RULES_LOADED = "RULES_LOADED"
export const RULES_ERROR = "RULES_ERROR"
export const TOP_TEN_RULES_PENDING = "TOP_TEN_RULES_PENDING"
export const TOP_TEN_RULES_LOADED = "TOP_TEN_RULES_LOADED"
export const TOP_TEN_RULES_ERROR = "TOP_TEN_RULES_ERROR"
export const DOMAIN_SET_SCORES_LOADED = "DOMAIN_SET_SCORES_LOADED"
export const DOMAIN_SCORES_LOADED = "DOMAIN_SCORES_LOADED"
export const DOMAIN_SCORES_PENDING = "DOMAIN_SCORES_PENDING"
export const DOMAIN_SET_SCORES_PENDING = "DOMAIN_SET_SCORES_PENDING"
export const DOMAIN_SET_SCORES_ERROR = "DOMAIN_SET_SCORES_ERROR"
export const DOMAIN_SCORES_ERROR = "DOMAIN_SCORES_ERROR"
export const STANDARD_SCORES_PENDING = "STANDARD_SCORES_PENDING"
export const STANDARD_SCORES_LOADED = "STANDARD_SCORES_LOADED"
export const STANDARD_SCORES_ERROR = "STANDARD_SCORES_ERROR"
export const ALL_DOMAIN_SCORES_PENDING = "ALL_DOMAIN_SCORES_PENDING"
export const ALL_DOMAIN_SCORES_LOADED = "ALL_DOMAIN_SCORES_LOADED"
export const ALL_DOMAIN_SCORES_ERROR = "ALL_DOMAIN_SCORES_ERROR"
export const CHANGE_DOMAIN_SCORES_PENDING = "CHANGE_DOMAIN_SCORES_PENDING"
export const CHANGE_DOMAIN_SCORES_LOADED = "CHANGE_DOMAIN_SCORES_LOADED"
export const CHANGE_DOMAIN_SCORES_REDUX = "CHANGE_DOMAIN_SCORES_REDUX"
export const DOMAIN_SET_SCORES_CACHED = "DOMAIN_SET_SCORES_CACHED";
export const DOMAIN_SCORES_CACHED = "DOMAIN_SCORES_CACHED";
export const ALL_DOMAIN_SCORES_CACHED = "ALL_DOMAIN_SCORES_CACHED";
export const STANDARD_SCORES_CACHED = "STANDARD_SCORES_CACHED";
export const DOMAIN_SETS_CACHED = "DOMAIN_SETS_CACHED";
export const TOP_TEN_RULES_CACHED = "TOP_TEN_RULES_CACHED";
export const STANDARDS_CACHED = "STANDARDS_CACHED";
export const GET_AVERAGE_IMPRESSIONS_PENDING = "GET_AVERAGE_IMPRESSIONS_PENDING";
export const AVERAGE_IMPRESSIONS_LOADED = "AVERAGE_IMPRESSIONS_LOADED";
export const AVERAGE_IMPRESSIONS_CACHED = "AVERAGE_IMPRESSIONS_CACHED";
export const AVERAGE_IMPRESSIONS_ERROR = "AVERAGE_IMPRESSIONS_ERROR";
export const SET_SCORES_INCLUDED_PENDING = "SET_SCORES_INCLUDED_PENDING"
export const SET_SCORES_INCLUDED_ERROR = "SET_SCORES_INCLUDED_ERROR"
export const SET_SCORES_INCLUDED_LOADED = "SET_SCORES_INCLUDED_LOADED"
export const SET_SCORES_INCLUDED_CACHED = "SET_SCORES_INCLUDED_CACHED"
export const ADMIN_DOMAINS_PENDING = "ADMIN_DOMAINS_PENDING";
export const ADMIN_DOMAINS_LOADED = "ADMIN_DOMAINS_LOADED";
export const ADMIN_DOMAINS_CACHED = "ADMIN_DOMAINS_CACHED";
export const ADMIN_DOMAINS_ERROR = "ADMIN_DOMAINS_ERROR";
export const ADMIN_DOMAINS_CHANGED_PERIOD = "ADMIN_DOMAINS_CHANGED_PERIOD";
export const ADMIN_DOMAINS_SENT_TO_RESCAN = "ADMIN_DOMAINS_SENT_TO_RESCAN";

export const ACCURACY_SETS_PENDING = "ACCURACY_SETS_PENDING";
export const ACCURACY_SETS_LOADED = "ACCURACY_SETS_LOADED";
export const ACCURACY_SETS_ERROR = "ACCURACY_SETS_ERROR";

export const GDPRV2_REPORT_PROPERTY_PENDING = "GDPRV2_REPORT_PROPERTY_PENDING";
export const GDPRV2_REPORT_PROPERTY_LOADED = "GDPRV2_REPORT_PROPERTY_LOADED";
export const GDPRV2_REPORT_PROPERTY_ERROR = "GDPRV2_REPORT_PROPERTY_ERROR";
export const GDPRV2_REPORT_PROPERTY_CACHED = "GDPRV2_REPORT_PROPERTY_CACHED";
export const GDPRV2_REPORT_VENDOR_PENDING = "GDPRV2_REPORT_VENDOR_PENDING";
export const GDPRV2_REPORT_VENDOR_LOADED = "GDPRV2_REPORT_VENDOR_LOADED";
export const GDPRV2_REPORT_VENDOR_ERROR = "GDPRV2_REPORT_VENDOR_ERROR";
export const GDPRV2_REPORT_VENDOR_CACHED = "GDPRV2_REPORT_VENDOR_CACHED";
export const GDPRV2_REPORT_SPECIAL_FEATURE_PENDING =
  "GDPRV2_REPORT_SPECIAL_FEATURE_PENDING";
export const GDPRV2_REPORT_SPECIAL_FEATURE_LOADED =
  "GDPRV2_REPORT_SPECIAL_FEATURE_LOADED";
export const GDPRV2_REPORT_SPECIAL_FEATURE_ERROR =
  "GDPRV2_REPORT_SPECIAL_FEATURE_ERROR";
export const GDPRV2_REPORT_SPECIAL_FEATURE_CACHED =
  "GDPRV2_REPORT_SPECIAL_FEATURE_CACHED";
export const GDPRV2_REPORT_MESSAGE_PENDING = "GDPRV2_REPORT_MESSAGE_PENDING";
export const GDPRV2_REPORT_MESSAGE_LOADED = "GDPRV2_REPORT_MESSAGE_LOADED";
export const GDPRV2_REPORT_MESSAGE_ERROR = "GDPRV2_REPORT_MESSAGE_ERROR";
export const GDPRV2_REPORT_MESSAGE_CACHED = "GDPRV2_REPORT_MESSAGE_CACHED";
export const GDPRV2_REPORT_PURPOSE_PENDING = "GDPRV2_REPORT_PURPOSE_PENDING";
export const GDPRV2_REPORT_PURPOSE_LOADED = "GDPRV2_REPORT_PURPOSE_LOADED";
export const GDPRV2_REPORT_PURPOSE_ERROR = "GDPRV2_REPORT_PURPOSE_ERROR";
export const GDPRV2_REPORT_PURPOSE_CACHED = "GDPRV2_REPORT_PURPOSE_CACHED";

export const GDPRV2_V2_REPORT_VENDOR_PENDING = "GDPRV2_V2_REPORT_VENDOR_PENDING";
export const GDPRV2_V2_REPORT_VENDOR_LOADED = "GDPRV2_V2_REPORT_VENDOR_LOADED";
export const GDPRV2_V2_REPORT_VENDOR_ERROR = "GDPRV2_V2_REPORT_VENDOR_ERROR";
export const GDPRV2_V2_REPORT_VENDOR_CACHED = "GDPRV2_V2_REPORT_VENDOR_CACHED";
export const GDPRV2_V2_REPORT_SPECIAL_FEATURE_PENDING =
  "GDPRV2_V2_REPORT_SPECIAL_FEATURE_PENDING";
export const GDPRV2_V2_REPORT_SPECIAL_FEATURE_LOADED =
  "GDPRV2_V2_REPORT_SPECIAL_FEATURE_LOADED";
export const GDPRV2_V2_REPORT_SPECIAL_FEATURE_ERROR =
  "GDPRV2_V2_REPORT_SPECIAL_FEATURE_ERROR";
export const GDPRV2_V2_REPORT_SPECIAL_FEATURE_CACHED =
  "GDPRV2_V2_REPORT_SPECIAL_FEATURE_CACHED";
export const GDPRV2_V2_REPORT_MESSAGE_PENDING = "GDPRV2_V2_REPORT_MESSAGE_PENDING";
export const GDPRV2_V2_REPORT_MESSAGE_LOADED = "GDPRV2_V2_REPORT_MESSAGE_LOADED";
export const GDPRV2_V2_REPORT_MESSAGE_ERROR = "GDPRV2_V2_REPORT_MESSAGE_ERROR";
export const GDPRV2_V2_REPORT_MESSAGE_CACHED = "GDPRV2_V2_REPORT_MESSAGE_CACHED";
export const GDPRV2_V2_REPORT_PVUSERS_PENDING = "GDPRV2_V2_REPORT_PVUSERS_PENDING";
export const GDPRV2_V2_REPORT_PVUSERS_LOADED = "GDPRV2_V2_REPORT_PVUSERS_LOADED";
export const GDPRV2_V2_REPORT_PVUSERS_ERROR = "GDPRV2_V2_REPORT_PVUSERS_ERROR";
export const GDPRV2_V2_REPORT_PVUSERS_CACHED = "GDPRV2_V2_REPORT_PVUSERS_CACHED";
export const GDPRV2_V2_REPORT_PURPOSE_PENDING = "GDPRV2_V2_REPORT_PURPOSE_PENDING";
export const GDPRV2_V2_REPORT_PURPOSE_LOADED = "GDPRV2_V2_REPORT_PURPOSE_LOADED";
export const GDPRV2_V2_REPORT_PURPOSE_ERROR = "GDPRV2_V2_REPORT_PURPOSE_ERROR";
export const GDPRV2_V2_REPORT_PURPOSE_CACHED = "GDPRV2_V2_REPORT_PURPOSE_CACHED";

export const PUBLISHER_CMP_ID_PENDING = "PUBLISHER_CMP_ID_PENDING";
export const PUBLISHER_CMP_ID_LOADED = "PUBLISHER_CMP_ID_LOADED";
export const PUBLISHER_CMP_ID_ERROR = "PUBLISHER_CMP_ID_ERROR";
export const PUBLISHER_CMP_ID_CACHED = "PUBLISHER_CMP_ID_CACHED";
export const PUBLISHERS_CMP_IDS_PENDING = "PUBLISHERS_CMP_IDS_PENDING";
export const PUBLISHERS_CMP_IDS_LOADED = "PUBLISHERS_CMP_IDS_LOADED";
export const PUBLISHERS_CMP_IDS_ERROR = "PUBLISHERS_CMP_IDS_ERROR";
export const PUBLISHERS_CMP_IDS_CACHED = "PUBLISHERS_CMP_IDS_CACHED";
export const PUBLISHER_CMP_ID_DELETED = "PUBLISHER_CMP_ID_DELETED";
export const PUBLISHER_CMP_ID_SAVE_PENDING = "PUBLISHER_CMP_ID_SAVE_PENDING";
export const PUBLISHER_CMP_ID_UPDATED = "PUBLISHER_CMP_ID_UPDATED";
export const PUBLISHER_CMP_ID_CREATED = "PUBLISHER_CMP_ID_CREATED";

export const INTEGRATIONS_ZENDESK_LOADED = "INTEGRATIONS_ZENDESK_LOADED";
export const INTEGRATIONS_ZENDESK_PENDING = "INTEGRATIONS_ZENDESK_PENDING";
export const INTEGRATIONS_ZENDESK_ERROR = "INTEGRATIONS_ZENDESK_ERROR"

// Diagnose dashboard
export const PROPERTIES_PENDING = "PROPERTIES_PENDING";
export const PROPERTIES_LOADED = "PROPERTIES_LOADED";
export const PROPERTIES_ERROR = "PROPERTIES_ERROR";

export const SUMMARY_PENDING = "SUMMARY_PENDING";
export const SUMMARY_LOADED = "SUMMARY_LOADED";
export const SUMMARY_ERROR = "SUMMARY_ERROR";

export const VENDORS_NOT_ON_VENDORS_LIST_PENDING = "VENDORS_NOT_ON_VENDORS_LIST_PENDING";
export const VENDORS_NOT_ON_VENDORS_LIST_LOADED = "VENDORS_NOT_ON_VENDORS_LIST_LOADED";
export const VENDORS_NOT_ON_VENDORS_LIST_ERROR = "VENDORS_NOT_ON_VENDORS_LIST_ERROR";

export const VENDORS_NOT_ON_VENDOR_EXPORT_PENDING = "VENDORS_NOT_ON_VENDOR_EXPORT_PENDING";
export const VENDORS_NOT_ON_VENDOR_EXPORT_LOADED = "VENDORS_NOT_ON_VENDOR_EXPORT_LOADED";
export const VENDORS_NOT_ON_VENDOR_EXPORT_ERROR = "VENDORS_NOT_ON_VENDOR_EXPORT_ERROR";

export const VENDORS_NOT_ON_VENDORS_FILTERS_PENDING = "VENDORS_NOT_ON_VENDORS_FILTERS_PENDING";
export const VENDORS_NOT_ON_VENDORS_FILTERS_LOADED = "VENDORS_NOT_ON_VENDORS_FILTERS_LOADED";
export const VENDORS_NOT_ON_VENDORS_FILTERS_ERROR = "VENDORS_NOT_ON_VENDORS_FILTERS_ERROR";

export const DISCLOSED_VENDORS_PENDING = "DISCLOSED_VENDORS_PENDING";
export const DISCLOSED_VENDORS_LOADED = "DISCLOSED_VENDORS_LOADED";
export const DISCLOSED_VENDORS_ERROR = "DISCLOSED_VENDORS_ERROR";

export const DISCLOSED_VENDORS_FILTERS_PENDING = "DISCLOSED_VENDORS_FILTERS_PENDING";
export const DISCLOSED_VENDORS_FILTERS_LOADED = "DISCLOSED_VENDORS_FILTERS_LOADED";
export const DISCLOSED_VENDORS_FILTERS_ERROR = "DISCLOSED_VENDORS_FILTERS_ERROR";

export const DISCLOSED_VENDORS_EXPORT_PENDING = "DISCLOSED_VENDORS_EXPORT_PENDING";
export const DISCLOSED_VENDORS_EXPORT_LOADED = "DISCLOSED_VENDORS_EXPORT_LOADED";
export const DISCLOSED_VENDORS_EXPORT_ERROR = "DISCLOSED_VENDORS_EXPORT_ERROR";

export const VENDORS_PRIOR_TO_CONSENT_LIST_PENDING = "VENDORS_PRIOR_TO_CONSENT_LIST_PENDING";
export const VENDORS_PRIOR_TO_CONSENT_LIST_LOADED = "VENDORS_PRIOR_TO_CONSENT_LIST_LOADED";
export const VENDORS_PRIOR_TO_CONSENT_LIST_ERROR = "VENDORS_PRIOR_TO_CONSENT_LIST_ERROR";

export const VENDORS_PRIOR_TO_CONSENT_EXPORT_PENDING = "VENDORS_PRIOR_TO_CONSENT_EXPORT_PENDING";
export const VENDORS_PRIOR_TO_CONSENT_EXPORT_LOADED = "VENDORS_PRIOR_TO_CONSENT_EXPORT_LOADED";
export const VENDORS_PRIOR_TO_CONSENT_EXPORT_ERROR = "VENDORS_PRIOR_TO_CONSENT_EXPORT_ERROR";

export const VENDORS_PRIOR_TO_CONSENT_FILTERS_PENDING = "VENDORS_PRIOR_TO_CONSENT_FILTERS_PENDING";
export const VENDORS_PRIOR_TO_CONSENT_FILTERS_LOADED = "VENDORS_PRIOR_TO_CONSENT_FILTERS_LOADED";
export const VENDORS_PRIOR_TO_CONSENT_FILTERS_ERROR = "VENDORS_PRIOR_TO_CONSENT_FILTERS_ERROR";

export const POSSIBLE_FINGERPRINTING_FILTERS_PENDING = "POSSIBLE_FINGERPRINTING_FILTERS_PENDING";
export const POSSIBLE_FINGERPRINTING_FILTERS_LOADED = "POSSIBLE_FINGERPRINTING_FILTERS_LOADED";
export const POSSIBLE_FINGERPRINTING_FILTERS_ERROR = "POSSIBLE_FINGERPRINTING_FILTERS_ERROR";

export const POSSIBLE_FINGERPRINTING_LIST_PENDING = "POSSIBLE_FINGERPRINTING_LIST_PENDING";
export const POSSIBLE_FINGERPRINTING_LIST_LOADED = "POSSIBLE_FINGERPRINTING_LIST_LOADED";
export const POSSIBLE_FINGERPRINTING_LIST_ERROR = "POSSIBLE_FINGERPRINTING_LIST_ERROR";

export const POSSIBLE_FINGERPRINTING_EXPORT_PENDING = "POSSIBLE_FINGERPRINTING_EXPORT_PENDING";
export const POSSIBLE_FINGERPRINTING_EXPORT_LOADED = "POSSIBLE_FINGERPRINTING_EXPORT_LOADED";
export const POSSIBLE_FINGERPRINTING_EXPORT_ERROR = "POSSIBLE_FINGERPRINTING_EXPORT_ERROR";


export const TRACKING_TECH_DETECTED_LIST_PENDING = "TRACKING_TECH_DETECTED_LIST_PENDING";
export const TRACKING_TECH_DETECTED_LIST_LOADED = "TRACKING_TECH_DETECTED_LIST_LOADED";
export const TRACKING_TECH_DETECTED_LIST_ERROR = "TRACKING_TECH_DETECTED_LIST_ERROR";

export const TRACKING_TECH_DETECTED_FILTERS_PENDING = "TRACKING_TECH_DETECTED_FILTERS_PENDING";
export const TRACKING_TECH_DETECTED_FILTERS_LOADED = "TRACKING_TECH_DETECTED_FILTERS_LOADED";
export const TRACKING_TECH_DETECTED_FILTERS_ERROR = "TRACKING_TECH_DETECTED_FILTERS_ERROR";

export const COOKIELESS_TECH_DETECTED_LIST_PENDING = "COOKIELESS_TECH_DETECTED_LIST_PENDING";
export const COOKIELESS_TECH_DETECTED_LIST_LOADED = "COOKIELESS_TECH_DETECTED_LIST_LOADED";
export const COOKIELESS_TECH_DETECTED_LIST_ERROR = "COOKIELESS_TECH_DETECTED_LIST_ERROR";

export const PIXELS_TECH_DETECTED_LIST_PENDING = "PIXELS_TECH_DETECTED_LIST_PENDING";
export const PIXELS_TECH_DETECTED_LIST_LOADED = "PIXELS_TECH_DETECTED_LIST_LOADED";
export const PIXELS_TECH_DETECTED_LIST_ERROR = "PIXELS_TECH_DETECTED_LIST_ERROR";

export const PIXELS_PARAMS_TECH_DETECTED_LIST_PENDING = "PIXELS_PARAMS_TECH_DETECTED_LIST_PENDING";
export const PIXELS_PARAMS_TECH_DETECTED_LIST_LOADED = "PIXELS_PARAMS_TECH_DETECTED_LIST_LOADED";
export const PIXELS_PARAMS_TECH_DETECTED_LIST_ERROR = "PIXELS_PARAMS_TECH_DETECTED_LIST_ERROR";

export const CHATBOTS_TECH_DETECTED_LIST_PENDING = "CHATBOTS_TECH_DETECTED_LIST_PENDING";
export const CHATBOTS_TECH_DETECTED_LIST_LOADED = "CHATBOTS_TECH_DETECTED_LIST_LOADED";
export const CHATBOTS_TECH_DETECTED_LIST_ERROR = "CHATBOTS_TECH_DETECTED_LIST_ERROR";

export const SESSION_REPLAY_TECH_DETECTED_LIST_PENDING = "SESSION_REPLAY_TECH_DETECTED_LIST_PENDING";
export const SESSION_REPLAY_TECH_DETECTED_LIST_LOADED = "SESSION_REPLAY_TECH_DETECTED_LIST_LOADED";
export const SESSION_REPLAY_TECH_DETECTED_LIST_ERROR = "SESSION_REPLAY_TECH_DETECTED_LIST_ERROR";

export const PIXELS_TECH_HAR_FILE_EXPORT_PENDING = "PIXELS_TECH_HAR_FILE_EXPORT_PENDING";
export const PIXELS_TECH_HAR_FILE_EXPORT_LOADED = "PIXELS_TECH_HAR_FILE_EXPORT_LOADED";
export const PIXELS_TECH_HAR_FILE_EXPORT_ERROR = "PIXELS_TECH_HAR_FILE_EXPORT_ERROR";

export const COOKIELESS_TECH_HAR_FILE_EXPORT_PENDING = "COOKIELESS_TECH_HAR_FILE_EXPORT_PENDING";
export const COOKIELESS_TECH_HAR_FILE_EXPORT_LOADED = "COOKIELESS_TECH_HAR_FILE_EXPORT_LOADED";
export const COOKIELESS_TECH_HAR_FILE_EXPORT_ERROR = "COOKIELESS_TECH_HAR_FILE_EXPORT_ERROR";

export const CHATBOT_TECH_HAR_FILE_EXPORT_PENDING = "CHATBOT_TECH_HAR_FILE_EXPORT_PENDING";
export const CHATBOT_TECH_HAR_FILE_EXPORT_LOADED = "CHATBOT_TECH_HAR_FILE_EXPORT_LOADED";
export const CHATBOT_TECH_HAR_FILE_EXPORT_ERROR = "CHATBOT_TECH_HAR_FILE_EXPORT_ERROR";

export const SESSION_REPLAY_TECH_HAR_FILE_EXPORT_PENDING = "SESSION_REPLAY_TECH_HAR_FILE_EXPORT_PENDING";
export const SESSION_REPLAY_TECH_HAR_FILE_EXPORT_LOADED = "SESSION_REPLAY_TECH_HAR_FILE_EXPORT_LOADED";
export const SESSION_REPLAY_TECH_HAR_FILE_EXPORT_ERROR = "SESSION_REPLAY_TECH_HAR_FILE_EXPORT_ERROR";


export const VENDORS_AFTER_OPT_OUT_PENDING = "VENDORS_AFTER_OPT_OUT_PENDING";
export const VENDORS_AFTER_OPT_OUT_LOADED = "VENDORS_AFTER_OPT_OUT_LOADED";
export const VENDORS_AFTER_OPT_OUT_ERROR = "VENDORS_AFTER_OPT_OUT_ERROR";

export const VENDORS_AFTER_OPT_OUT_FILTERS_PENDING = "VENDORS_AFTER_OPT_OUT_FILTERS_PENDING";
export const VENDORS_AFTER_OPT_OUT_FILTERS_LOADED = "VENDORS_AFTER_OPT_OUT_FILTERS_LOADED";
export const VENDORS_AFTER_OPT_OUT_FILTERS_ERROR = "VENDORS_AFTER_OPT_OUT_FILTERS_ERROR";

export const VENDORS_AFTER_OPT_OUT_EXPORT_PENDING = "VENDORS_AFTER_OPT_OUT_EXPORT_PENDING";
export const VENDORS_AFTER_OPT_OUT_EXPORT_LOADED = "VENDORS_AFTER_OPT_OUT_EXPORT_LOADED";
export const VENDORS_AFTER_OPT_OUT_EXPORT_ERROR = "VENDORS_AFTER_OPT_OUT_EXPORT_ERROR";

export const VENDORS_AFTER_OPT_OUT_VENDOR_DATA_PENDING = "VENDORS_AFTER_OPT_OUT_VENDOR_DATA_PENDING";
export const VENDORS_AFTER_OPT_OUT_VENDOR_DATA_LOADED = "VENDORS_AFTER_OPT_OUT_VENDOR_DATA_LOADED";
export const VENDORS_AFTER_OPT_OUT_VENDOR_DATA_ERROR = "VENDORS_AFTER_OPT_OUT_VENDOR_DATA_ERROR";
export const VENDORS_AFTER_OPT_OUT_VENDOR_DATA_CLEAR = "VENDORS_AFTER_OPT_OUT_VENDOR_DATA_CLEAR";

export const VENDORS_AFTER_OPT_OUT_WEBSITES_PENDING = "VENDORS_AFTER_OPT_OUT_WEBSITES_PENDING";
export const VENDORS_AFTER_OPT_OUT_WEBSITES_LOADED = "VENDORS_AFTER_OPT_OUT_WEBSITES_LOADED";
export const VENDORS_AFTER_OPT_OUT_WEBSITES_ERROR = "VENDORS_AFTER_OPT_OUT_WEBSITES_ERROR";
export const VENDORS_AFTER_OPT_OUT_WEBSITES_CLEAR = "VENDORS_AFTER_OPT_OUT_WEBSITES_CLEAR";

export const VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_PENDING = "VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_PENDING";
export const VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_LOADED = "VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_LOADED";
export const VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_ERROR = "VENDORS_AFTER_OPT_OUT_WEBSITES_EXPORT_ERROR";

export const VENDORS_AFTER_OPT_OUT_COOKIES_PENDING = "VENDORS_AFTER_OPT_OUT_COOKIES_PENDING";
export const VENDORS_AFTER_OPT_OUT_COOKIES_LOADED = "VENDORS_AFTER_OPT_OUT_COOKIES_LOADED";
export const VENDORS_AFTER_OPT_OUT_COOKIES_ERROR = "VENDORS_AFTER_OPT_OUT_COOKIES_ERROR";

export const VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_PENDING = "VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_PENDING";
export const VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_LOADED = "VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_LOADED";
export const VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_ERROR = "VENDORS_AFTER_OPT_OUT_COOKIES_FILTERS_ERROR";

export const VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_PENDING = "VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_PENDING";
export const VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_LOADED = "VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_LOADED";
export const VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_ERROR = "VENDORS_AFTER_OPT_OUT_COOKIES_EXPORT_ERROR";

export const SET_METRICS_LIST = "SET_METRICS_LIST";
export const SET_AVAILABLE_METRICS = "SET_AVAILABLE_METRICS";
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_REGIONS_LIST = "SET_REGIONS_LIST";

export const DATA_LEAVING_EEA_EXPORT_PENDING = "DATA_LEAVING_EEA_EXPORT_PENDING";
export const DATA_LEAVING_EEA_EXPORT_LOADED = "DATA_LEAVING_EEA_EXPORT_LOADED";
export const DATA_LEAVING_EEA_EXPORT_ERROR = "DATA_LEAVING_EEA_EXPORT_ERROR";

export const DATA_LEAVING_EEA_FILTERS_PENDING = "DATA_LEAVING_EEA_FILTERS_PENDING";
export const DATA_LEAVING_EEA_FILTERS_LOADED = "DATA_LEAVING_EEA_FILTERS_LOADED";
export const DATA_LEAVING_EEA_FILTERS_ERROR = "DATA_LEAVING_EEA_FILTERS_ERROR";

export const DATA_LEAVING_EEA_LIST_PENDING = "DATA_LEAVING_EEA_LIST_PENDING";
export const DATA_LEAVING_EEA_LIST_LOADED = "DATA_LEAVING_EEA_LIST_LOADED";
export const DATA_LEAVING_EEA_LIST_ERROR = "DATA_LEAVING_EEA_LIST_ERROR";

export const COOKIES_WITH_LONG_LIFESPANS_EXPORT_PENDING = "COOKIES_WITH_LONG_LIFESPANS_EXPORT_PENDING";
export const COOKIES_WITH_LONG_LIFESPANS_EXPORT_LOADED = "COOKIES_WITH_LONG_LIFESPANS_EXPORT_LOADED";
export const COOKIES_WITH_LONG_LIFESPANS_EXPORT_ERROR = "COOKIES_WITH_LONG_LIFESPANS_EXPORT_ERROR";

export const COOKIES_WITH_LONG_LIFESPANS_FILTERS_PENDING = "COOKIES_WITH_LONG_LIFESPANS_FILTERS_PENDING";
export const COOKIES_WITH_LONG_LIFESPANS_FILTERS_LOADED = "COOKIES_WITH_LONG_LIFESPANS_FILTERS_LOADED";
export const COOKIES_WITH_LONG_LIFESPANS_FILTERS_ERROR = "COOKIES_WITH_LONG_LIFESPANS_FILTERS_ERROR";

export const COOKIES_WITH_LONG_LIFESPANS_LIST_PENDING = "COOKIES_WITH_LONG_LIFESPANS_LIST_PENDING";
export const COOKIES_WITH_LONG_LIFESPANS_LIST_LOADED = "COOKIES_WITH_LONG_LIFESPANS_LIST_LOADED";
export const COOKIES_WITH_LONG_LIFESPANS_LIST_ERROR = "COOKIES_WITH_LONG_LIFESPANS_LIST_ERROR";

export const ALL_COOKIES_FOUND_LIST_PENDING = "ALL_COOKIES_FOUND_LIST_PENDING";
export const ALL_COOKIES_FOUND_LIST_LOADED = "ALL_COOKIES_FOUND_LIST_LOADED";
export const ALL_COOKIES_FOUND_LIST_ERROR = "ALL_COOKIES_FOUND_LIST_ERROR";

export const ALL_COOKIES_FOUND_FILTERS_PENDING = "ALL_COOKIES_FOUND_FILTERS_PENDING";
export const ALL_COOKIES_FOUND_FILTERS_LOADED = "ALL_COOKIES_FOUND_FILTERS_LOADED";
export const ALL_COOKIES_FOUND_FILTERS_ERROR = "ALL_COOKIES_FOUND_FILTERS_ERROR";

export const ALL_COOKIES_FOUND_EXPORT_PENDING = "ALL_COOKIES_FOUND_EXPORT_PENDING";
export const ALL_COOKIES_FOUND_EXPORT_LOADED = "ALL_COOKIES_FOUND_EXPORT_LOADED";
export const ALL_COOKIES_FOUND_EXPORT_ERROR = "ALL_COOKIES_FOUND_EXPORT_ERROR";

export const DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_PENDING = 'DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_PENDING';
export const DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_LOADED = 'DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_LOADED';
export const DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_ERROR = 'DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_ERROR';
export const DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_PENDING = 'DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_PENDING';
export const DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_LOADED = 'DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_LOADED';
export const DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_ERROR = 'DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_ERROR';
export const DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_PENDING = 'DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_PENDING';
export const DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_LOADED = 'DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_LOADED';
export const DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_ERROR = 'DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_ERROR';
export const DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_PENDING = 'DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_PENDING';
export const DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_LOADED = 'DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_LOADED';
export const DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_ERROR = 'DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_ERROR';

//for Google Ad Manager Free Site Scan
export const FREE_SCAN_DETAILS_PENDING = 'FREE_SCAN_DETAILS_PENDING';
export const FREE_SCAN_DETAILS_ADDED = 'FREE_SCAN_DETAILS_ADDED';
export const FREE_SCAN_DETAILS_ERROR = 'FREE_SCAN_DETAILS_ERROR';
//End

export const SYSTEM_ACCURACY_PENDING = "SYSTEM_ACCURACY_PENDING";
export const SYSTEM_ACCURACY_LOADED = "SYSTEM_ACCURACY_LOADED";
export const SYSTEM_ACCURACY_TIMELINE_LOADED = "SYSTEM_ACCURACY_TIMELINE_LOADED";
export const SYSTEM_ACCURACY_CACHED = "SYSTEM_ACCURACY_CACHED";
export const SYSTEM_ACCURACY_ERROR = "SYSTEM_ACCURACY_ERROR";

export const PRIVACY_LENS_ACCOUNTS_PENDING = "PRIVACY_LENS_ACCOUNTS_PENDING";
export const PRIVACY_LENS_ACCOUNTS_LOADED = "PRIVACY_LENS_ACCOUNTS_LOADED";
export const PRIVACY_LENS_ACCOUNTS_ERROR = "PRIVACY_LENS_ACCOUNTS_ERROR";
export const VENDORS_DETAILS_PENDING = "VENDORS_DETAILS_PENDING";
export const VENDORS_DETAILS_LOADED = "VENDORS_DETAILS_LOADED";
export const VENDORS_DETAILS_ERROR = "VENDORS_DETAILS_ERROR";
export const VENDORS_DETAILS_CLEAR = "VENDORS_DETAILS_CLEAR";

export const VENDORS_WEBSITE_DETAILS_PENDING = "VENDORS_WEBSITE_DETAILS_PENDING";
export const VENDORS_WEBSITE_DETAILS_LOADED = "VENDORS_WEBSITE_DETAILS_LOADED";
export const VENDORS_WEBSITE_DETAILS_ERROR = "VENDORS_WEBSITE_DETAILS_ERROR";
export const VENDORS_WEBSITE_DETAILS_CLEAR = "VENDORS_WEBSITE_DETAILS_CLEAR";

export const VENDOR_DETAILS_EXPORT_PENDING = "VENDOR_DETAILS_EXPORT_PENDING";
export const VENDOR_DETAILS_EXPORT_LOADED = "VENDOR_DETAILS_EXPORT_LOADED";
export const VENDOR_DETAILS_EXPORT_ERROR = "VENDOR_DETAILS_EXPORT_ERROR";

export const COOKIE_DETAILS_LIST_PENDING = "COOKIE_DETAILS_LIST_PENDING";
export const COOKIE_DETAILS_LIST_LOADED = "COOKIE_DETAILS_LIST_LOADED";
export const COOKIE_DETAILS_LIST_ERROR = "COOKIE_DETAILS_LIST_ERROR";

export const COOKIE_DETAILS_LIST_FILTERS_PENDING = "COOKIE_DETAILS_LIST_FILTERS_PENDING";
export const COOKIE_DETAILS_LIST_FILTERS_LOADED = "COOKIE_DETAILS_LIST_FILTERS_LOADED";
export const COOKIE_DETAILS_LIST_FILTERS_ERROR = "COOKIE_DETAILS_LIST_FILTERS_ERROR";

export const COOKIE_DETAILS_EXPORT_PENDING = "COOKIE_DETAILS_EXPORT_PENDING";
export const COOKIE_DETAILS_EXPORT_LOADED = "COOKIE_DETAILS_EXPORT_LOADED";
export const COOKIE_DETAILS_EXPORT_ERROR = "COOKIE_DETAILS_EXPORT_ERROR";

export const SET_SELECTED_VENDOR_TRACE_FILTERS = "SET_SELECTED_VENDOR_TRACE_FILTERS";
export const SET_CONSENT_TYPE_FILTER = "SET_CONSENT_TYPE_FILTER";
export const RESET_VENDOR_TRACE = "RESET_VENDOR_TRACE";

export const SET_WEBSITE_PROPERTIES_LIST = 'SET_WEBSITE_PROPERTIES_LIST';

export const SET_VENDOR_STATUS_SELECTED_PROPERTY_LIST = "VENDOR_STATUS_SELECTED_PROPERTY_LIST";

export const VENDOR_MANAGEMENT_STATUSES_PENDING = "VENDOR_MANAGEMENT_STATUSES_PENDING";
export const VENDOR_MANAGEMENT_STATUSES_LOADED = "VENDOR_MANAGEMENT_STATUSES_LOADED";
export const VENDOR_MANAGEMENT_STATUSES_ERROR = "VENDOR_MANAGEMENT_STATUSES_ERROR";

export const GET_VENDOR_MANAGEMENT_ACTIVITIES_LIST = "GET_VENDOR_MANAGEMENT_ACTIVITIES_LIST";
export const VENDOR_MANAGEMENT_ACTIVITIES_LIST_PENDING = "VENDOR_MANAGEMENT_ACTIVITIES_LIST_PENDING";
export const GET_VENDOR_MANAGEMENT_ACTIVITIES_LIST_CLEAR = "GET_VENDOR_MANAGEMENT_ACTIVITIES_LIST_CLEAR";

export const ADD_VENDOR_MANAGEMENT_ACTIVITIES_PENDING = "ADD_VENDOR_MANAGEMENT_ACTIVITIES_PENDING";
export const ADD_VENDOR_MANAGEMENT_ACTIVITIES_LOADED = "ADD_VENDOR_MANAGEMENT_ACTIVITIES_LOADED";
export const ADD_VENDOR_MANAGEMENT_ACTIVITIES_ERROR = "ADD_VENDOR_MANAGEMENT_ACTIVITIES_ERROR";
export const VENDOR_MANAGEMENT_ACTIVITIES_LIST_CLEAR = "VENDOR_MANAGEMENT_ACTIVITIES_LIST_CLEAR";
export const SET_IS_VENDOR_STATUS_MULTISELECT_OPERATION_ACTIVE = "SET_IS_VENDOR_STATUS_MULTISELECT_OPERATION_ACTIVE";

export const SET_VENDOR_STATUS_FILTER_LIST = "SET_VENDOR_STATUS_FILTER_LIST";

//us privacy regulation
export const US_PRIVACY_ACCOUNT_LIST_PENDING = "US_PRIVACY_ACCOUNT_LIST_PENDING"
export const US_PRIVACY_ACCOUNT_LIST_LOADED = "US_PRIVACY_ACCOUNT_LIST_LOADED"
export const US_PRIVACY_ACCOUNT_LIST_CACHED = "US_PRIVACY_ACCOUNT_LIST_CACHED"
export const US_PRIVACY_ACCOUNT_LIST_ERROR = "US_PRIVACY_ACCOUNT_LIST_ERROR"
export const US_PRIVACY_ACCOUNT_LIST_CREATED = "US_PRIVACY_ACCOUNT_LIST_CREATED"
export const US_PRIVACY_ACCOUNT_LIST_UPDATED = "US_PRIVACY_ACCOUNT_LIST_UPDATED"
export const US_PRIVACY_ACCOUNT_LIST_DELETED = "US_PRIVACY_ACCOUNT_LIST_DELETED"
export const REGULATION_CREATED = "REGULATION_CREATED"
export const REGULATION_PENDING = "REGULATION_PENDING"
export const REGULATION_DELETED = "REGULATION_DELETED"
export const REGULATION_UPDATED = "REGULATION_UPDATED"
export const REGULATION_LOADED = "REGULATION_LOADED"
export const REGULATION_SAVE_PENDING = "REGULATION_SAVE_PENDING"
export const REGULATION_ERROR = "REGULATION_ERROR"
export const CLEAR_REGULATIN_ERROR = "CLEAR_REGULATIN_ERROR";
export const SYSTEM_PURPOSES_PENDING = "SYSTEM_PURPOSES_PENDING";
export const SYSTEM_PURPOSES_LOADED = "SYSTEM_PURPOSES_LOADED";
export const SYSTEM_PURPOSES_ERROR = "SYSTEM_PURPOSES_ERROR";
export const SYSTEM_STACKS_PENDING = "SYSTEM_STACKS_PENDING";
export const SYSTEM_STACKS_LOADED = "SYSTEM_STACKS_LOADED";
export const SYSTEM_STACKS_ERROR = "SYSTEM_STACKS_ERROR";

//preferences
export const VENDOR_CONNECTION_PENDING = "VENDOR_CONNECTION_PENDING";
export const VENDOR_CONNECTIONS_LOADED = "VENDOR_CONNECTIONS_LOADED";
export const VENDOR_CONNECTION_ERROR = "VENDOR_CONNECTION_ERROR";
export const CLEAR_VENDOR_CONNECTION_ERROR = "CLEAR_VENDOR_CONNECTION_ERROR";
export const PREFERENCES_CACHED = "PREFERENCES_CACHED";
export const PREFERENCE_CONFIGURATION_PENDING = "PREFERENCE_CONFIGURATION_PENDING";
export const PREFERENCE_CONFIGURATION_LIST_LOADED = "PREFERENCE_CONFIGURATION_LIST_LOADED";
export const PREFERENCE_CONFIGURATION_ERROR = "PREFERENCE_CONFIGURATION_ERROR";
export const CLEAR_PREFERENCE_CONFIGURATION_ERROR = "CLEAR_PREFERENCE_CONFIGURATION_ERROR";
export const PREFERENCE_CONFIGURATION_LOADED = "PREFERENCE_CONFIGURATION_LOADED";
export const PREFERENCE_CONFIGURATION_SAVE_PENDING = "PREFERENCE_CONFIGURATION_SAVE_PENDING";
export const PREFERENCE_CONFIGURATION_CREATED = "PREFERENCE_CONFIGURATION_CREATED";
export const PREFERENCE_CONFIGURATION_UPDATED = "PREFERENCE_CONFIGURATION_UPDATED";
export const PREFERENCE_CONFIGURATION_DELETED = "PREFERENCE_CONFIGURATION_DELETED";

export const VENDORS_PENDING = "VENDORS_PENDING";
export const VENDORS_LOADED = "VENDORS_LOADED";
export const VENDORS_ERROR = "VENDORS_ERROR";

export const VENDOR_CONNECTION_SAVE_PENDING = "VENDOR_CONNECTION_SAVE_PENDING";
export const VENDOR_CONNECTION_CREATED = "VENDORS_CONNECTION_CREATED";
export const VENDOR_CONNECTION_UPDATED = "VENDORS_CONNECTION_UPDATED";
export const VENDOR_CONNECTION_DELETED = "VENDORS_CONNECTION_DELETED";
export const VENDORS_CONNECTION_SAVE_ERROR = "VENDORS_CONNECTION_SAVE_ERROR";

