import 'whatwg-fetch';
import { checkStatus } from '../helper.js';
import { List, OrderedSet } from 'immutable';
import { PrivacyManager, SitePrivacyManager } from '../../records/privacy_manager_records';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const getConsentBaseUrl = () => `${permissionsSvcBaseUrl}/ccpa`;

export function getSitePrivacyManagerListAjax(accountId, siteId) {
  return fetch(getConsentBaseUrl() + '/privacy-manager/' + accountId + '?siteId=' + siteId, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => OrderedSet(resp.map(pm => {
      pm.siteId = siteId;
      return new SitePrivacyManager(pm);
    })));
}

export function getSiteGroupPrivacyManagerListAjax(accountId, siteIds, siteGroupId) {
  return fetch(getConsentBaseUrl() + '/privacy-manager/' + accountId + '?siteGroup=true&siteIds=' + JSON.stringify(siteIds), {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => OrderedSet(resp.map(pm => {
      pm.siteGroupId = siteGroupId;
      return new SitePrivacyManager(pm);
    })));
}

export function getPrivacyManagerListAjax(accountId, siteAccess) {
  let url = '/privacy-manager/' + accountId;
  if (siteAccess) {
    url += '?siteIds=' + JSON.stringify(siteAccess);
  }

  return fetch(getConsentBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => List(resp.map(pm => new PrivacyManager(pm))))
}

export function getPrivacyManagerAjax(id, accountId) {
  let url = getConsentBaseUrl() + '/privacy-manager?privacyManagerId=' + id;

  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => new PrivacyManager(resp));
}

export function createPrivacyManagerAjax(accountId, pm) {
  const data = pm.toMap().delete('id').set('vendorList', pm.vendorListId).delete('vendorListId').toJS();
  data.accountId = accountId;
  return fetch(getConsentBaseUrl() + '/privacy-manager', {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => new PrivacyManager(resp))
}

export function updatePrivacyManagerAjax(accountId, pm) {
  const data = pm.toMap().set('_id', pm.id).delete('id').set('vendorList', pm.vendorListId).delete('vendorListId').toJS();
  data.accountId = accountId;
  return fetch(getConsentBaseUrl() + '/privacy-manager', {
    method: 'PUT',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => new PrivacyManager(resp))
}

export function deletePrivacyManagerAjax(accountId, { id }) {
  return fetch(getConsentBaseUrl() + '/privacy-manager/' + accountId + '/' + id, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accountId,
    }),
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(() => id)
}

export async function clonePrivacyManagerAjax(accountId, privacyManagerId) {
  const url = `${getConsentBaseUrl()}/privacy-manager/clone`
  const fetchOpts = {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      accountId,
      privacyManagerId 
    }),
  }
  
  const resp = await fetch(url, fetchOpts)
  checkStatus(resp)
  return new PrivacyManager(await resp.json())
}