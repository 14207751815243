import React, { Component } from 'react';
import { Dropdown, Menu, Input, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';

class FilterSelect extends Component {
  state = {
    visible: false,
    all: false,
    group: {},
    filterText: '',
    allFilter: {}
  }

  setInitialValues(){
    if(this.props?.value?.length === this.props?.options?.length){
      if(this.props.type === 'geo'){
      this.setState(prevState => ({
        allFilter: {
            ...prevState.allFilter,
            [this.props.type]: true
        },
        group:{
          'EEA':true,
          'EU':true,
          'NA':true,
          'OTHER':true
        }
      }))
    }else{
      this.setState(prevState => ({
        allFilter: {
            ...prevState.allFilter,
            [this.props.type]: true
        },
      }))
    }
    }
  }

  componentDidMount(){
    this.setInitialValues()
  }

  componentDidUpdate(prevProps){
      if(prevProps.value !== this.props.value){
        this.setInitialValues()
      }
  }
  
  onSelect = (item) => {
    if (this.props.multiple) {
      this.toggleMultiple(item);
    } else {
      this.props.onChange(item);
      this.setState({ visible: false });
    }
  }
  toggleMultiple = (item) => {
    let tempValue = [...this.props.value];
    const exists = this.props.value?.includes(item);

    if (exists) {
      tempValue = tempValue.filter(o => o !== item)
    } else {
      tempValue.push(item)
    }
    this.props.onChange(tempValue);

    if(this.props.options.length === tempValue.length) {
      this.setState({ allFilter:{[this.props.type]: true},})
    } else {
      this.setState({ allFilter:{[this.props.type]: false},})
    }
  }
  whatValue = (value) => {
    if (this.props.multiple && this.props.allowAll && (this.state.allFilter[this.props.type] === true || value.length === this.props.options.length)) {
      return 'All';
    } else if (this.props.multiple) {
      let actualValue = value;
      if (this.props.keypair) {
        actualValue = actualValue.map(v => {
          const pair = this.props.options.find(o => o.value === v);
          return pair ? pair.name : 'N/A';
        });
      }
      return actualValue?.length > 1 ? `${actualValue[0]} +${actualValue.length - 1}` : actualValue[0];
    } else {
      return value;
    }
  }
  toggleAll = () => {
    const type = this.props.type;
    if (this.state.allFilter[type]) {
      this.setState({
        allFilter:{[type]: false},
        group: {
          'EEA':false,
          'EU':false,
          'NA':false,
          'OTHER':false
        },
      }, () => {
        this.props.onChange([]);
      })
    } else {
      this.setState({
        allFilter:{[type]: false},
        group: {
          'EEA':true,
          'EU':true,
          'NA':true,
          'OTHER':true
        },
      }, () => {
        this.props.onChange(this.props.keypair ? this.props.options.map(o => o.value) : this.props.options);
      })
    }
  }
  toggleGroup = (group) => {
    let grp = this.state.group
    if (grp[group.name]) {
     this.setState(prevState => ({
        allFilter:{[this.props.type]: false},
        group: {                   
            ...prevState.group,
            [group.name]: false
        }
    }),() => {
      const val = group?.value || [];
      let tempValue = [...this.props.value];
      tempValue = tempValue.filter(t => !val.includes(t));
      this.props.onChange(tempValue)
    }) 
    } else {
      this.setState(prevState => ({
        group: {
            ...prevState.group,
            [group.name]: true
        },
      }), () => {
        const val = group?.value || [];
        this.onSelect([...val])
        const newVal = [...val, ...this.props.value];
        const unique = new Set(newVal);
        if(this.props.options.length === unique.size) {
          this.setState({allFilter: {[this.props.type]: true}})
        } else {
          this.setState({allFilter: {[this.props.type]: false}})
        }
        this.props.onChange([...unique]);
      })
  }}
  onVisibleChange = visible => this.setState({ visible })
  render() {
    const props = { ...this.props };
    props.className = `${props.className || ''} spsg-filter-select`;
    const options = props.options?.filter(o => {
      if (props.keypair) {
        return this.state.filterText?.length > 0 ? (o?.name || '').toLowerCase().includes(this.state.filterText?.toLowerCase()) : true;
      } else {
        return this.state.filterText?.length > 0 ? o?.toString().toLowerCase().includes(this.state.filterText?.toLowerCase()) : true;
      }
    })

    const menu = (
      <Menu selectedKeys={props.multiple ? props.value : [props.value]}>
        <div className="list-search">
          <Input value={this.state.filterText} onChange={e => this.setState({ filterText: e.target.value})} placeholder="Search"/>
          <FontAwesomeIcon icon={faSearch} />
        </div>
        { props.allowAll && props.multiple ? (
          <Menu.Item onClick={this.toggleAll} className={this.state.allFilter[this.props?.type] ? 'selactive' : ''}><Checkbox checked={this.state.allFilter[this.props?.type]} />Select All</Menu.Item>
        ) : null}
        {props.group && props.group.map((grp) => {
          return(
            <Menu.Item onClick={() => this.toggleGroup(grp)} className={this.state.group[grp.name] ? 'selactive' : ''}><Checkbox checked={this.state.group[grp.name]} />{grp.name}</Menu.Item>
          )
        })}
        { options.map(o => props.keypair ? (
          <Menu.Item key={o.value} className={(props.value?.toString() || '').includes(o.value) || this.state.allFilter[this.props?.type] ? 'selactive' : ''} onClick={() => this.onSelect(o.value)}>
            {props.multiple && <Checkbox checked={props.value?.includes(o.value)} /> }
            {o.name}
          </Menu.Item>
        ) : (
          <Menu.Item key={o} className={(props.value?.toString() || '').includes(o) ? 'selactive' : ''} onClick={() => this.onSelect(o)}>
            {props.multiple && <Checkbox checked={props.value?.includes(o)} /> }
            {o}
          </Menu.Item>
        ))}
      </Menu>
    );

    if (this.state.visible) props.className += ' dropped';
    if (this.props.disabled) props.className += ' disabled';

    const realValue = props.keypair && !props.multiple ? props.options.find(o => o.value === props.value)?.name : props.value;
   
    return (
      <Dropdown 
        open={this.state.visible} 
        onOpenChange={this.onVisibleChange} 
        overlayClassName="spsg-filter-dropdown" 
        trigger={['click']}
        disabled={this.props.disabled}
        overlay={menu}
      >
        <div className={props.className}>
          <div className="place">{props.placeholder}</div>
          { realValue?.length > 0 || (!isNaN(realValue) && realValue?.toString().length > 0) ? (
            <div className="box-wrapper"><div className="box">{this.whatValue(realValue)}</div></div>
          ) : (
            <div className="box-place">{props.placevalue}</div>
          ) }
          <div className="arrow"><FontAwesomeIcon icon={faChevronDown} /></div>
        </div>
      </Dropdown>
    );
  }
}

export default FilterSelect;
