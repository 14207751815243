import { Record, List, fromJS } from 'immutable';
import { Rule } from './standard_records.js';
import { Domain } from './domain_set_records';

export class GoldenSet extends Record({
  id: null,
  name: '',
  description: '',
  properties: List([]),
  rules: List([]),
  dateCreated: '',
  dateUpdated: '',
  policy: null,
}) {
  constructor(goldenSet) {
    const updatedGoldenSet = Object.assign({}, goldenSet);
    if (goldenSet._id) updatedGoldenSet.id = goldenSet._id;

    if (goldenSet.properties) {
      updatedGoldenSet.properties = List(goldenSet.properties.map(d => new Domain(d)));
    }
    if (goldenSet.rules) {
      updatedGoldenSet.rules = List(goldenSet.rules.map(r => new Rule(r)));
    }
    super(updatedGoldenSet);
  }
}