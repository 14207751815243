import Immutable, { Record, List, Map } from "immutable";
import moment from 'moment';

export const propertyDnsaRecord = Record({
  key: null,
  date: "",
  totalpageviews: 0,
  pvoptin: 0,
  pvoptout: 0,
  pvoptinPer: 0,
  pvoptoutPer: 0,
  aggData: List(),
});

export const MessageDnsaRecord = Record({
  key: null,
  totalpageviews: 0,
  actionoptin: 0,
  actionoptinPer: 0,
  actionoptout: 0,
  actionoptoutPer: 0,
  dsrrateperuser: 0,
  messageid: 0,
  partition: null,
  aggData: List(),
  siteid: "",
});

export const ChangeLogRecord = Record({
  key: null,
  sitegroupname: "",
  operationtype: "",
  entitytype: "",
  entitynames: "",
  properties: "",
  user: "",
  date: "",
  siteIds: List(),
});

export const ConsentFilters = Record({
  startDate: moment()
    .subtract(1, "weeks")
    .startOf("isoWeek"),
  endDate: moment()
    .subtract(1, "weeks")
    .endOf("isoWeek"),
  siteId: List([]),
  state: List([]),
  geo: List([]),
  device: List([]),
  browser: List([]),
  os: List([]),
});
