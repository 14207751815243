import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Select, Modal, message } from 'antd';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List, OrderedSet, is } from 'immutable';
import { vendorListError } from '../../../../records/vendor_list_records';

import { 
  getPartners, 
  getConsentCompensationList, 
  updateConsentCompensationList, 
  createConsentCompensationList, 
  getConsentCompensationLists,
  deleteConsentCompensationList,
  clearConsentCompensationListError,
} from '../../../../actions/consent_compensation_actions.js';

import { getAllSites, getSiteGroups } from '../../../../actions/site_actions.js';
import Loading from '../../../common/Loading.js.jsx';
import { ConsentCompensationList, VendorWrapper, History } from '../../../../records/consent_compensation_records';
import { Vendor } from '../../../../records/vendor_list_records';
import { Site } from '../../../../records/site_records';
import { displayErrors } from '../../helper';
import Header from './Header';
import ManageSites from './ManageSites';
import Table from './Table';
import AddVendorsModal from './AddVendorsModal';
import { User } from '../../../../records/account_records';

const Option = Select.Option;

class ConsentCompensationListEdit extends React.Component {
  static propTypes = {
    consentCompensationList: PropTypes.instanceOf(ConsentCompensationList),
    pendingRequestsMap: ImmutablePropTypes.map.isRequired,
    partners: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)).isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    consentCompensationLists: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(ConsentCompensationList)).isRequired,
    enabledSitesForScan: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
    route: PropTypes.shape({
      currentUser: PropTypes.instanceOf(User).isRequired,
    }).isRequired,

    getConsentCompensationList: PropTypes.func.isRequired,
    updateConsentCompensationList: PropTypes.func.isRequired,
    getPartners: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    createConsentCompensationList: PropTypes.func.isRequired,
    getSiteGroups: PropTypes.func.isRequired,
  }

  static defaultProps = {
    consentCompensationList: null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const update = {};

    if (((!prevState.vl || !prevState.vl.id) && nextProps.consentCompensationList && nextProps.consentCompensationList.id) || (prevState.savePending && !nextProps.pendingRequestsMap.get('saveConsentCompensationList'))) {
      update.vl = nextProps.consentCompensationList;
    }

    return update;
  }

  createNewCCl = () => {
    const autoNameRegex = /(New Consent Compensation List)($| \([0-9]+\))/;
    const autoNameCount = this.props.consentCompensationLists.filter(vl => vl.name.match(autoNameRegex)).size;
    let consentCompensationList = new ConsentCompensationList({});
    if (autoNameCount) {
      consentCompensationList = consentCompensationList.set('name', `New Consent Compensation List (${autoNameCount + 1})`);
    }
    return consentCompensationList;
  };

  getConsentCompensationList = () => {
    if (this.props.consentCompensationList) {
      return this.props.consentCompensationList;
    } else {
      return this.createNewCCl();
    }
  }

  state = {
    vl: this.getConsentCompensationList(),
    addVendorsOpen: false,
    vendorsToAdd: OrderedSet([]),
    saveErrorMessage: null,
    originalVendors: null,
    changedStateByVendor: Map({}),
    savePending: false,
  }

  componentDidMount() {
    this.props.getPartners();
    this.props.getAllSites();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.props.getSiteGroups(this.props.currentUser.accountId);
      if (this.props.location.query.id) this.props.getConsentCompensationList(this.props.location.query.id, this.props.currentUser.accountId);
    }
  }

  componentDidUpdate(prevProps) {
    // need for redirecting from create route to edit
    if (!prevProps.location.query.id && this.props.location.query.id) {
      this.props.getConsentCompensationList(this.props.location.query.id, this.props.currentUser.accountId);
    }

    if (prevProps.pendingRequestsMap.get('saveConsentCompensationList') && !this.props.pendingRequestsMap.get('saveConsentCompensationList')) {
      if (this.props.consentCompensationListError) {

        this.handleServerSideErrors(this.props.consentCompensationListError);

      } else {
        this.setState({ savePending: false });
        message.success('Your consent compensation list has been saved.', 5);
      }
    }
  }

  setChangedVendorState = (vId, vendorState) => {
    this.setState({ changedStateByVendor: this.state.changedStateByVendor.set(vId, vendorState) })
  }

  parseServerSideError = (data) => {
    let messages = new List([]);

    if (data.errorType === 'SITE_VALIDATION_FAILED') {
      data.data.forEach(vl => {
        messages = messages.push(`Vendor list "${vl.name}" already applies to ${vl.intersection.map(id => this.props.sites.find(s => s.id === id).domain).join(' and ')}.`);
      });

      messages = messages.push('Please ensure that each site is only used in one active consent compensation list.');

    } else {
      messages = messages.push('This vendor list is invalid');
    }

    return messages;
  }

  handleChange = (e, property) => {
    this.setState({ vl: this.state.vl.set(property, e.target ? e.target.value : e) });
  }

  updateState = (obj) => {
    this.setState(obj);
  }

  updateStateOfConsentCompensationList = (vl) => {
    this.setState({ vl });
  }

  capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  }

  addToVendorHistory = () => {
    const vendorsWrappers = this.state.vl.vendorsWrappers.map(vw => {

      const date = Date.now();
      const newState = this.state.changedStateByVendor.get(vw.vendorId);
      if (newState) {
        let history = vw.history;
        if (history.size) {
          history = history.update(history.size - 1, el => {
            let updatedEl = el.set('endDate', date);
            if (!updatedEl.isActive) {
              updatedEl = updatedEl.set('dealType', '').set('rateType', '').set('currency', '').set('cpmAmount', 0).set('percentage', 0);
            }
            return updatedEl;
          });
        } 
        return vw.set('history', history.push(newState.set('startDate', date)));
      } else if (!vw.history.size) {
        return vw.update('history', (h => h.push(new History().set('startDate', date))));
      } else {
        return vw;
      }
    });
    return this.state.vl.set('vendorsWrappers', vendorsWrappers);
  }

  handleSave = () => {
    let vl = this.addToVendorHistory(this.state.vl);

    let errorMessages = new List([]);
    let errorType = '';

    vl = vl.set('name', this.state.vl.name.trim())

    if (this.props.consentCompensationLists.filterNot(v => v.id === vl.id).some(v => v.name === vl.name)) {
      errorMessages = errorMessages.push(`Vendor list with name "${vl.name}" already exists. Please rename this vendor list.`);
      errorType = 'name';
    } else if (!vl.name) {
      errorMessages = errorMessages.push('Vendor list has no name. Please name this vendor list.');
      errorType = 'name';
    }

    if (!vl.vendorsWrappers.size) {
      errorMessages = errorMessages.push('Vendor list has no vendors. Please add at least one vendor to vendor list.');
    }

    if (errorMessages.size) {

      Modal.warning({
        title: 'Vendor list has not been saved',
        content: displayErrors(errorMessages),
        className: 'save-warning',
        onOk: () => this.handleSaveErrorModalClose(errorType),
      });
      return;
    }

    if (this.props.location.pathname === "/consent_v2/compensation_list/create") {
      this.props.createConsentCompensationList(this.props.currentUser.accountId, vl).then((resp) => {
        browserHistory.push('/consent_v2/compensation_list/edit?id=' + resp.id);
      });
    } else {
      this.setState({ savePending: true });
      this.props.updateConsentCompensationList(this.props.currentUser.accountId, vl);
    }
  }

  handleServerSideErrors = (errors) => {
    const errorMessages = errors.map(err => {
      if (err.msg) {
        return err.msg;
      } else {
        return this.parseServerSideError(err);
      }
    });

    Modal.warning({
      title: 'Vendor list has not been saved',
      content: displayErrors(errorMessages),
      className: 'save-warning',
      onOk: () => this.handleSaveErrorModalClose(),
    });
  }

  handleSaveErrorModalClose = (errorType) => {
    this.props.clearConsentCompensationListError();
  }

  toggleAddVendors = () => {
    this.setState({ addVendorsOpen: !this.state.addVendorsOpen });
  }

  handleSelectSite = (siteIds) => {
    this.setState({
      vl: this.state.vl.set('siteIds', List(siteIds)),
    });
  }

  toggleIsActive = (checked) => {
    this.setState({
      vl: this.state.vl.set('isActive', checked),
    });
  }

  handleCloseAddVendorsModal = () => {
    this.setState({
      addVendorsOpen: false,
      vendorsToAdd: OrderedSet([]),
    });
  }

  removeSite = (id) => {
    this.setState({
      vl: this.state.vl.update('siteIds', ids => ids.filterNot(idx => idx === id)),
    });
  }

  removeSiteByName = (site) => {
    const siteId = this.props.sites.find(s => s.domain === site).id;
    this.removeSite(siteId);
  }

  render() {
    if (this.props.pendingRequestsMap.some(request => request) || !this.props.sites.size || !this.state.vl
    || (this.props.location.pathname === "/consent/vendor_lists/edit" && (!this.props.consentCompensationList || !this.props.consentCompensationList.vendors.first().vendorType)))
    {
      return <Loading />;
    }

    let readOnly = false;
    if (this.props.currentUser.featureAccess &&
      !this.props.currentUser.featureAccess.includes('Vendor List - GDPR') || !this.props.currentUser.featureAccess.includes('Vendor List - CCPA')) {
      readOnly = true;
    }

    let addVendorsModal;
    if (this.state.addVendorsOpen) {
      addVendorsModal = (
        <AddVendorsModal
          visible={ this.state.addVendorsOpen }
          closeModal={ () => this.setState({ addVendorsOpen: false }) }
          vendors={ this.props.partners }
          updateVendorList={ this.updateStateOfConsentCompensationList }
          vl={ this.state.vl }
        />
      );
    }

    const mainTable = (
      <Table
        partners={ this.props.partners }
        consentCompensationList={ this.state.vl }
        toggleAddVendors={ this.toggleAddVendors }
        readOnly={ readOnly }
        changedStateByVendor={ this.state.changedStateByVendor }
        setChangedVendorState={ this.setChangedVendorState }
        updateConsentCompensationList={ this.updateStateOfConsentCompensationList }
      />
    );

    let mainContent = mainTable;
    if (this.state.vl.vendorsWrappers.size && !this.state.addVendorsOpen) {
      mainContent = mainTable;
    } else if (!this.state.vl.vendorsWrappers.size) {
      mainContent = (
        <div className='add-vendors-container' >
          <div className='scan-buttons'>
            <div className='add-vendors-button' onClick={ this.toggleAddVendors } role="presentation">
              <p>Add Vendors</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='consent-compensation-container'>

        <Header
          route={ this.props.route }
          vl={ this.state.vl }
          handleChange={ this.handleChange }
          handleSave={ this.handleSave }
          toggleIsActive={ this.toggleIsActive }
          readOnly={ readOnly }
          v2={ this.props.route.v2 }
        />

        <ManageSites
          vl={ this.state.vl }
          sites={ this.props.sites }
          handleSelectSite={ this.handleSelectSite }
          removeSiteByName={ this.removeSiteByName }
          readOnly={ readOnly }
          siteGroups={ this.props.siteGroups }
          siteAccess={ this.props.currentUser.siteAccess }
        />

        { mainContent }

        { addVendorsModal }
      </div>
    );
  }
}

const mapStateToProps = function (store){
  const consentCompensationLists = store.consentCompensationListState.getIn(['consentCompensationLists', 'value']);
  const consentCompensationListId = window.location.search ? window.location.search.split('=')[1] : '';
  const sites = store.siteState.getIn(['sites', 'value']);
  let consentCompensationList;
  if (consentCompensationListId && consentCompensationLists.size && sites.size) {
    consentCompensationList = consentCompensationLists.find(vl => vl.id === consentCompensationListId);
  }

  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    consentCompensationList,
    consentCompensationLists: store.consentCompensationListState.getIn(['consentCompensationLists', 'value']),
    partners: store.consentCompensationListState.getIn(['partners', 'value']),
    sites,
    siteGroups: store.siteState.getIn(['siteGroups', 'value']),
    enabledSitesForScan: store.consentCompensationListState.getIn(['enabledSitesForScan', 'value']),
    consentCompensationListError: store.consentCompensationListState.getIn(['consentCompensationLists', 'error']),
    pendingRequestsMap: new Map({
      consentCompensationList: store.consentCompensationListState.getIn(['consentCompensationLists', 'pending']),
      saveConsentCompensationList: store.consentCompensationListState.get('savePending'),
      partners: store.consentCompensationListState.getIn(['partners', 'pending']),
      sites: store.siteState.getIn(['sites', 'pending']),
    }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getPartners,
    getConsentCompensationList,
    getAllSites,
    updateConsentCompensationList,
    createConsentCompensationList,
    getConsentCompensationLists,
    deleteConsentCompensationList,
    getSiteGroups,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsentCompensationListEdit);
