import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import useSearchParams from '../../dashboard/shared/useSearchParams';
import { useSelector } from 'react-redux';

const SET_PREVALENCE = 'SET_PREVALENCE';
const SET_VENDOR_NAME = 'SET_VENDOR_NAME';
const SET_COLLAPSE = 'SET_COLLAPSE';
const SET_COLLAPSE_NODE = 'SET_COLLAPSE_NODE';
const CHANGE_VENDOR_NAME_SEARCH_APPEARANCE = 'CHANGE_VENDOR_NAME_SEARCH_APPEARANCE';
const RESET = 'RESET';
const CONDENCE_MODE = {
  SEARCHED: 'searched',
  ALL: 'all',
};
const intialFilters = {
  prevalenceLowPoint: 0,
  vendorName: null,
  collapse: [],
  isTreeStateCondenced: CONDENCE_MODE.SEARCHED,
};
const initialState = {
  vendorsData: {},
  vendorsNames: [],
  ...intialFilters,
};

const VendorTraceContext = createContext(initialState);

function filtersReducer(state, action) {
  switch (action.type) {
    case SET_PREVALENCE:
      return { ...intialFilters, prevalenceLowPoint: action.payload };
    case SET_VENDOR_NAME: {
      if (action.isBeforeActionConsentCategory) {
        return {
          ...intialFilters,
          vendorName: action.payload,
          isTreeStateCondenced: action.payload ? CONDENCE_MODE.SEARCHED : CONDENCE_MODE.ALL,
        };
      }
      return {
        ...intialFilters,
        vendorName: action.payload,
        isTreeStateCondenced: CONDENCE_MODE.SEARCHED,
      };
    }
    case SET_COLLAPSE:
      return {
        ...intialFilters,
        collapse: action.payload,
      };
    case SET_COLLAPSE_NODE:
      if (!Array.isArray(state.collapse)) {
        return { ...intialFilters, collapse: [action.payload] };
      }
      return {
        ...intialFilters,
        prevalenceLowPoint: state.prevalenceLowPoint,
        collapse: state.collapse.includes(action.payload)
          ? state.collapse.filter((c) => c !== action.payload)
          : [...state.collapse, action.payload],
      };
    case CHANGE_VENDOR_NAME_SEARCH_APPEARANCE:
      return { ...state, isTreeStateCondenced: action.payload };
    case RESET:
      return { ...intialFilters };
    default:
      return state;
  }
}

function useVendorTraceFilters() {
  const [filters, changeFiltersDispatch] = useReducer(filtersReducer, intialFilters);
  const resetFilters = () => changeFiltersDispatch({ type: RESET });
  const setPrevalence = (prevalence) => changeFiltersDispatch({ type: SET_PREVALENCE, payload: prevalence });
  const setVendorName = (vendorName, isBeforeActionConsentCategory) =>
    changeFiltersDispatch({ type: SET_VENDOR_NAME, payload: vendorName, isBeforeActionConsentCategory });
  const setCollapse = (collapse) => changeFiltersDispatch({ type: SET_COLLAPSE, payload: collapse });
  const setCollapseNode = (collapse) => changeFiltersDispatch({ type: SET_COLLAPSE_NODE, payload: collapse });
  const changeVendorNameSearchAppearance = (newApperance) =>
    changeFiltersDispatch({ type: CHANGE_VENDOR_NAME_SEARCH_APPEARANCE, payload: newApperance });

  return {
    filters,
    actions: {
      resetFilters,
      setPrevalence,
      setVendorName,
      setCollapse,
      setCollapseNode,
      changeVendorNameSearchAppearance,
    },
  };
}

function generateTreeData(vendors) {
  const vendorMap = new Map(
    vendors.map((vendor) => [
      vendor.map_key,
      {
        name: vendor.vendor_name,
        attributes: {
          ...vendor,
          referred: [],
          referrer: '',
        },
        children: [],
      },
    ]),
  );
  let root;
  vendors.forEach((vendor) => {
    const parentalKey = vendor.parental_map_key;
    const key = vendor.map_key;
    if (parentalKey) {
      const parent = vendorMap.get(parentalKey);
      if (parent) {
        const currentVendor = vendorMap.get(key);
        currentVendor.attributes.referrer = parent.attributes.vendor_name;
        parent.children.push(currentVendor);
        parent.attributes.referred = parent.children;
      }
    } else {
      root = vendorMap.get(key);
    }
  });

  return root || {};
}

/* Filters */
function isNodeWithinVendorNameFilter(node, vendorNameFilter) {
  return vendorNameFilter && node.attributes.vendor_name === vendorNameFilter;
}

function nestedFilterByVendorName(node, vendorNameFilter) {
  const filteredChildren = [];
  node.children.forEach((child) => {
    if (isNodeWithinVendorNameFilter(child, vendorNameFilter)) {
      filteredChildren.push({
        ...child,
        children: nestedFilterByVendorName(child, vendorNameFilter),
      });
    } else if (
      child.children.some((nestedChildNode) => isNodeWithinVendorNameFilter(nestedChildNode, vendorNameFilter))
    ) {
      filteredChildren.push({
        ...child,
        children: nestedFilterByVendorName(child, vendorNameFilter),
      });
    } else {
      const ch = nestedFilterByVendorName(child, vendorNameFilter);
      if (ch.length) {
        filteredChildren.push({
          ...child,
          children: ch,
        });
      }
    }
  });
  return filteredChildren;
}

export function isNodeWithinPrevalenceFilter(node, prevalenceFilter) {
  const prevalence = Math.round(node.attributes.relative_prevalence * 100);
  return prevalenceFilter ? prevalence >= prevalenceFilter && prevalence <= 100 : true;
}

function nestedFilterByPrevalence(node, prevalenceFilter) {
  return node?.children?.reduce((accumulated, child) => {
    if (
      isNodeWithinPrevalenceFilter(child, prevalenceFilter) ||
      child.children.some((nestedChildNode) => isNodeWithinPrevalenceFilter(nestedChildNode, prevalenceFilter))
    ) {
      accumulated.push({
        ...child,
        children: nestedFilterByPrevalence(child, prevalenceFilter),
      });
    }
    return accumulated;
  }, []);
}

function isNodeWithinCollapseFilter(node, collapseFilter) {
  return collapseFilter.includes(node.attributes.map_key);
}

function nestedCollapseDataOfChildrens(node, collapseFilter) {
  return node?.children?.map((child) => {
    return {
      ...child,
      children: isNodeWithinCollapseFilter(child, collapseFilter)
        ? []
        : nestedCollapseDataOfChildrens(child, collapseFilter),
    };
  });
}

function buildHighlightChain(vendors, searchParams) {
  if (!(searchParams.key || searchParams.vendor)) return [];
  const initialCurrentVendor = vendors.find(
    (vendor) => vendor.map_key === searchParams.key && vendor.parental_map_key === searchParams.parental,
  );

  if (!initialCurrentVendor) return [];
  const vendorCalls = searchParams?.vendor_calls?.split(',') || [];
  const buildChain = (vendor, chain = []) => {
    const newChain = [vendor.map_key, ...chain];
    const parentVendor = vendors.find((vendorTrace) => vendorTrace.map_key === vendor.parental_map_key);
    return parentVendor ? buildChain(parentVendor, newChain) : newChain;
  };

  return [...buildChain(initialCurrentVendor), ...vendorCalls];
}

export function isNodeAfterConsent(child) {
  return !child.attributes.is_prior_to_consent;
}
export function isTreeAfterConsent(data) {
  return data.attributes.referred.some((vendor) => isNodeAfterConsent(vendor) || isTreeAfterConsent(vendor));
}

function priorToConsentCollapse(node) {
  const filteredChildren = [];
  node.children.forEach((child) => {
    if (isNodeAfterConsent(child)) {
      filteredChildren.push({
        ...child,
        children: priorToConsentCollapse(child),
      });
    } else if (child.children.some(isNodeAfterConsent)) {
      filteredChildren.push({
        ...child,
        children: priorToConsentCollapse(child),
      });
    } else {
      const ch = priorToConsentCollapse(child);
      if (ch.length) {
        filteredChildren.push({
          ...child,
          children: ch,
        });
      }
    }
  });
  return filteredChildren;
}
/* End Filters */
function useVendorTraceContext() {
  return useContext(VendorTraceContext);
}

function withVendorTraceData(Component) {
  return function VendorTraceContextProvider(props) {
    const consentTypeFilter = useSelector((store) =>
      store.diagnoseVendorTraceState.getIn(['consentTypeFilter', 'value']),
    );
    const searchParams = useSearchParams();
    const { filters, actions: filtersActions } = useVendorTraceFilters();
    const [vendorsData, setVendorsData] = useState({});
    const [vendorsList, setVendorsList] = useState([]);
    const [highlightChain, setHighlightChain] = useState([]);
    const [layer2Nodes, setLayer2Nodes] = useState([]);
    const [beforeActionConsentCategory, setBeforeActionConsentCategory] = useState(null);
    const isBeforeActionConsentCategory = useMemo(
      () => beforeActionConsentCategory === consentTypeFilter,
      [beforeActionConsentCategory, consentTypeFilter],
    );
    const collapseAfterActionData =
      !isBeforeActionConsentCategory && filters.isTreeStateCondenced !== CONDENCE_MODE.ALL;
    const setCollapseAll = () => {
      filtersActions.setCollapse(layer2Nodes);
    };

    const prepareVendorsDataToSet = (vendors) => {
      if (!vendors.length) {
        setVendorsData({});
        setHighlightChain([]);
        filtersActions.resetFilters();
      }
      const vendorsFlat = vendors.flat();
      setLayer2Nodes(vendors.find((level) => level?.[0]?.trace_level === 2)?.map((vendor) => vendor.map_key) || []);
      setHighlightChain(buildHighlightChain(vendorsFlat, searchParams));
      setVendorsList(vendorsFlat);
      setVendorsData(generateTreeData(vendorsFlat));
    };

    const isAllCollapsed = useMemo(() => {
      return _.isEqual(filters.collapse, layer2Nodes);
    }, [layer2Nodes, filters.collapse]);

    const vendorsNames = useMemo(() => {
      return vendorsList.reduce((result, vendor) => {
        if (!result.some((existVendor) => existVendor.title === vendor.vendor_name)) {
          result.push({
            title: vendor.vendor_name,
            value: vendor.vendor_name,
            disabled: collapseAfterActionData && vendor.is_prior_to_consent,
          });
        }
        return result;
      }, []);
    }, [collapseAfterActionData, vendorsList]);

    const vendorsDataFiltered = useMemo(() => {
      let result = vendorsData;
      if (!Object.keys(result).length || (filters.vendorName && filters.isTreeStateCondenced === CONDENCE_MODE.ALL)) {
        return result;
      }
      if (
        Object.keys(result).length &&
        !isBeforeActionConsentCategory &&
        filters.isTreeStateCondenced !== CONDENCE_MODE.ALL
      ) {
        result = { ...result, children: priorToConsentCollapse(result) };
      }
      /* highlight & sort */
      if (searchParams.key) {
        const vendorCalls = searchParams?.vendor_calls?.split(',') || [];

        const filterHighlightChain = (node) => {
          const { referredLength, ...nodeAttributes } = node?.attributes || {};
          const isVendorCalled = vendorCalls.includes(nodeAttributes.map_key);
          const isInChain = highlightChain.includes(nodeAttributes.map_key);
          return {
            ...node,
            attributes: {
              ...node.attributes,
              highlighted: isInChain,
            },
            children:
              !isInChain || isVendorCalled
                ? []
                : node.children
                    .map(filterHighlightChain)
                    .sort((a, b) => +b.attributes.highlighted - +a.attributes.highlighted),
          };
        };
        result = filterHighlightChain(result);
      }
      /* filter by searched vendor_name */
      if (filters.vendorName && filters.isTreeStateCondenced === CONDENCE_MODE.SEARCHED) {
        result = { ...result, children: nestedFilterByVendorName(result, filters.vendorName) };
      }
      /* filter by low point of prevalence */
      if (filters.prevalenceLowPoint) {
        result = {
          ...result,
          children: nestedFilterByPrevalence(result, filters.prevalenceLowPoint),
        };
      }
      /* collapse nodes */
      if (filters.collapse?.length) {
        result = {
          ...result,
          children: isNodeWithinCollapseFilter(result, filters.collapse)
            ? []
            : nestedCollapseDataOfChildrens(result, filters.collapse),
        };
      }
      return result;
    }, [vendorsData, collapseAfterActionData, filters]);

    return (
      <VendorTraceContext.Provider
        value={{
          ...filters,
          ...filtersActions,
          setCollapseAll,
          setVendorsData: prepareVendorsDataToSet,
          setBeforeActionConsentCategory,
          vendorsData: vendorsDataFiltered,
          vendorsNames,
          isBeforeActionConsentCategory,
          isAllCollapsed,
        }}
      >
        <Component {...props} />
      </VendorTraceContext.Provider>
    );
  };
}

export { CONDENCE_MODE, useVendorTraceContext, withVendorTraceData };
