import React from "react";
import { Modal, Table } from "../../../styleguide";
import { Map, List } from "immutable";
import moment from 'moment';
import {
  ResponsiveContainer,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend
} from "recharts";
import {
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST
} from "../../../constants";
import { Link } from "react-router";
import { createCSVExportData } from "../../consent/performanceNew/helper";
import CustomPopover from "../../common/CustomPopover";
import ExportPopoverContent from "./ExportPopoverContent";

export default class MessagePerformaceModal extends React.Component {

  drawGraph = tableData => {
    let lineArr = [];
    tableData.map((d, j) => {
      lineArr.push(
        <Line
          key={"c-" + j}
          strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(
            d.get("criteriaId") - 1
          )}
          strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(
            d.get("criteriaId") - 1
          )}
          type="monotone"
          dataKey={d.get("messageid") + "-" + d.get("criteriaId")}
          stroke={"#" + ((Math.random() * 0xffffff) << 0).toString(16)}
        />
      );
    });
    return lineArr;
  };

  dataFormater = number => {
    return number + "%";
  };
  render() {
    const columns = List([
      Map({
        title: "Criteria",
        dataIndex: "criteriaId",
        className: "left-align",
        render: (text) => <span>Criteria {text}</span>,
      }),
      Map({
        title: "Message",
        dataIndex: "messageid",
        className: "left-align",
        sorter: (a, b) => a.messageid - b.messageid,
      }),
      Map({
        title: "Total Messages",
        dataIndex: "totalmsgs",
        className: "right-align",
        sorter: (a, b) => a.totalmsgs - b.totalmsgs,
        render: (text) => <span>{text.toLocaleString()}</span>,
      }),
      Map({
        title: "Opted In",
        dataIndex: "actionoptin",
        className: "right-align",
        sorter: (a, b) => a.actionoptin - b.actionoptin,
        render: (text, record) => (
          <div className="consent-table-metrics">
            <span className="tbl-metric-per">{record.actionoptinPer}%</span>{" "}
            <br />
            {text}
          </div>
        ),
      }),
      Map({
        title: "Opted Out",
        dataIndex: "actionoptout",
        className: "right-align",
        sorter: (a, b) => a.actionoptout - b.actionoptout,
        render: (text, record) => (
          <div className="consent-table-metrics">
            <span className="tbl-metric-per">{record.actionoptoutPer}%</span>{" "}
            <br />
            {text}
          </div>
        ),
      }),
      Map({
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={`/dialogue/scenariosv3?site_id=${record.siteid}`}
              target="_blank"
            >
              Need
              {window.innerWidth <= 1300 ? " " : <br />}
              Action
            </Link>
          </span>
        ),
        className: "right-align",
      }),
    ]);

    let tableData = List();
    this.props.selectedMsgs.map((item, i) => {
      item.get("msgs").map((m, j) => {
        tableData = tableData.push(
          Map({
            key: i + "-" + j,
            criteriaId: item.get('key') + 1,
            messageid: m.get("messageid"),
            totalmsgs: m.get("totalpageviews"),
            actionoptin: m.get("actionoptin"),
            actionoptinPer: m.get("actionoptinPer"),
            actionoptout: m.get("actionoptout"),
            actionoptoutPer: m.get("actionoptoutPer"),
            siteid: m.get("siteid")
          })
        );
      });
    });
    let graphData = List();
    tableData.map(t => {
      let criteriaId = t.get("criteriaId");
      let messageid = t.get("messageid").toString();
      let getCriteriaData = this.props.msgPerformance.get(criteriaId - 1);
      getCriteriaData.map(p => {
        if (p.get(messageid) || p.get(messageid) == 0) {
          const index = graphData.findIndex(
            r => r.get("date") == p.get("date")
          );
          if (index >= 0) {
            graphData = graphData.setIn(
              [index, [messageid + "-" + criteriaId]],
              p.get(messageid)
            );
          } else {
            graphData = graphData.push(
              Map({
                date: p.get("date"),
                fancyDate: moment(p.get("date"), "YYYY-MM-DD").format("MMM Do"),
                [messageid + "-" + criteriaId]: p.get(messageid)
              })
            );
          }
        }
      });
    });

    graphData = graphData.sort(
      (a, b) =>
        moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
    );

    let graphTitle = graphData.size
      ? moment(graphData.first().get("date"), "YYYY-MM-DD").format("MMMM YYYY")
      : "";
    const msgHeaders = columns
      .map(item => {
        return Map({ label: item.get("title"), key: item.get("dataIndex") });
      })
      .insert(4, Map({ label: "Opted In %", key: "actionoptinPer" }))
      .insert(6, Map({ label: "Opted Out %", key: "actionoptoutPer" }))
      .pop();
    return (
      <Modal
        title={
          <span>
            Message Performance
            <CustomPopover
              tooltipContent={
                <ExportPopoverContent
                  filename="message_performance"
                  exportData={createCSVExportData(msgHeaders, tableData)}
                  pdfTitle={"Message Performance"}
                />
              }
              style="export-popover"
              icon="download"
              iconColor="#108ee9"
              title={
                <span className="popover-title">Export Type Selection</span>
              }
            />
          </span>
        }
        visible={this.props.recentChangesModal}
        onCancel={this.props.handleCancel}
        footer={null}
        className="recentChangeModal"
      >
        <span className="modal-title">Opted Out</span>
        <ResponsiveContainer width="99%" height={395}>
          <LineChart
            width={730}
            height={250}
            data={graphData.toJS()}
            margin={{ top: 5, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="fancyDate">
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              type="number"
              domain={[0, "dataMax"]}
              tickFormatter={this.dataFormater}
            />
            <Tooltip formatter={value => value + "%"} />
            <ReferenceLine
              x={moment().format("MMM Do")}
              stroke="#000"
              isFront={true}
              label="Today"
              strokeDasharray="1 1"
            />
            <Legend verticalAlign="top" height={36} />
            {this.drawGraph(tableData)}
          </LineChart>
        </ResponsiveContainer>
        <Table
          columns={columns.toJS()}
          dataSource={tableData.toJS()}
          className="consnet-tableCSS"
          pagination={false}
        />
      </Modal>
    );
  }
}
