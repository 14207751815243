import 'whatwg-fetch';
import { OrderedSet, fromJS, Map } from 'immutable';
import {
  checkStatus,
  isFetched,
  addToFetched,
} from '../helper';
import {
  BACKEND_STATUS_TYPES,
  FETCHED,
  STATUS_TYPES as STATUS,
} from '../../constants.js';
import {
  Scenario,
  CustomScenario,
  ScenarioStep,
} from '../../components/dialogue/scenarios/versionTwo/components/scenario_records_2';

import {
  ScenarioV2,
} from '../../components/dialogue/scenarios/versionTwo/components/steps';

import { serializeRows } from '../../components/dialogue/scenarios/versionTwo/parseAndSerializeRows.js';

const permissionsSvsBase = process.env.REACT_APP_PERMISSIONS_API_URL;

export const fetchScenarios = (status, siteId) => {
  const backendStatus = BACKEND_STATUS_TYPES[status];
  const url =
    '/msg/get_scenario_list?object_status=' + backendStatus + '&site_id=' + siteId;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => {
      addToFetched(url);
      const scenarios = resp.data.scenario_data_list.map((scenario) =>
        scenarioToRecord(scenario, status)
      );
      return OrderedSet(scenarios);
    });
};

export const getScenarios = (status, siteId) => {
  const backendStatus = BACKEND_STATUS_TYPES[status];
  const url =
    '/msg/get_scenario_list?object_status=' + backendStatus + '&site_id=' + siteId;
    if (isFetched(url)) {
      return Promise.resolve(FETCHED);
    } else {
      return fetchScenarios(status, siteId);
    }
};

export const getScenariosAfterImportAjax = (siteId) => {
  const url = '/msg/get_scenario_list?object_status=1&site_id=' + siteId;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => {
      const scenarios = resp.data.scenario_data_list.map((scenario) => scenarioToRecord(scenario));
      return OrderedSet(scenarios);
    });
};

export const createScenarioAjax = (scenario) => {
  const data = {
    description: scenario.get('description'),
    scenario_json: JSON.stringify({ steps: scenario.get('steps') }),
    campaign_type_id: scenario.get('campaign_type_id') || 0,
  };
  if (scenario.get('schema_version'))
    data.schema_version = scenario.schema_version;
  const url = '/msg/create_scenario?site_id=' + scenario.site_id;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => {
      return scenarioToRecord(resp.data.scenario_data)
    });
};

export const createSiteGroupScenarioAjax = (
  accountId,
  siteGroupId,
  scenario
) => {
  const scenarioBody = {
    description: scenario.get('description'),
    scenario_json: scenario.get('scenario_json')
      ? scenario.get('scenario_json')
      : JSON.stringify({ steps: scenario.get('steps') }),
    campaign_type_id: scenario.get('campaign_type_id') || 0,
  };
  if (scenario.get('schema_version'))
    scenarioBody.schema_version = scenario.schema_version;

  const url = `${permissionsSvsBase}/site-group/scenario?siteGroupId=${siteGroupId}`;
  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      scenarioBody,
      accountId,
    }),
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => {
      return fromJS({
        scenarios: OrderedSet(
          resp.scenarioResponseObjs.map((s) => scenarioToRecord(s))
        ),
        sitesWithErrors: resp.sitesWithErrors,
      });
    });
};

export const createUpdateScenarioAjax = (scenario) => {
  let scenarioJson = JSON.stringify({ steps: scenario.get('steps') });
  scenarioJson = serializeRows(scenario.get('steps'));
  const data = {
    description: scenario.get('description'),
    scenario_json: scenarioJson,
    campaign_type_id: scenario.get('campaign_type_id') || 0,
  };
  if (scenario.get('schema_version'))
    data.schema_version = scenario.schema_version;
  const url = '/msg/create_scenario?site_id=' + scenario.site_id;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => {
      return scenarioToRecord(resp.data.scenario_data)
    });
};

export const updateScenarioAjax = (scenario) => {
  let scenarioJson = JSON.stringify({ steps: scenario.get('steps') });
  if (scenario.schema_version === 1) {
    scenarioJson = scenario.get('scenario_json');
  } else if (scenario.schema_version === 2) {
    scenarioJson = serializeRows(scenario.get('steps'));
  }
  const data = {
    id: scenario.get('id'),
    description: scenario.get('description'),
    scenario_json: scenarioJson,
    campaign_type_id: scenario.get('campaign_type_id') || 0,
  };
  const url = '/msg/update_scenario';
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => scenarioToRecord(resp.data.scenario_data));
};

export const createUpdateSiteGroupScenarioAjax = (
  accountId,
  siteGroupId,
  scenario
) => {
  let scenarioJson = JSON.stringify({ steps: scenario.get('steps') });
  if (scenario.schema_version === 1) {
    scenarioJson = scenario.get('scenario_json');
  } else if (scenario.schema_version === 2) {
    scenarioJson = serializeRows(scenario.get('steps'));
  }

  const scenarioBody = {
    description: scenario.get('description'),
    scenario_json: scenarioJson,
    campaign_type_id: scenario.get('campaign_type_id') || 0,
  };

  if (scenario.get('schema_version'))
    scenarioBody.schema_version = scenario.schema_version;

  const url = `${permissionsSvsBase}/site-group/scenario?siteGroupId=${siteGroupId}`;
  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      scenarioBody,
      accountId,
    }),
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => {
      return fromJS({
        scenarios: OrderedSet(
          resp.scenarioResponseObjs.map((s) => scenarioToRecord(s))
        ),
        sitesWithErrors: resp.sitesWithErrors,
      });
    });
};

export const updateSiteGroupScenarioAjax = (
  siteGroupId,
  accountId,
  scenario
) => {
  let scenarioJson = JSON.stringify({ steps: scenario.get('steps') });
  if (scenario.schema_version === 1) {
    scenarioJson = scenario.get('scenario_json');
  } else if (scenario.schema_version === 2) {
    scenarioJson = serializeRows(scenario.get('steps'));
  }

  const scenarioBody = {
    id: scenario.get('id'),
    description: scenario.get('description'),
    scenario_json: scenarioJson,
    campaign_type_id: scenario.get('campaign_type_id') || 0,
  };

  const url = `${permissionsSvsBase}/site-group/scenario?siteGroupId=${siteGroupId}`;
  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({
      scenarioBody,
      accountId,
    }),
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => {
      return fromJS({
        scenarios: OrderedSet(
          resp.scenarioResponseObjs.map((s) => scenarioToRecord(s))
        ),
        sitesWithErrors: resp.sitesWithErrors,
      });
    });
};

export const deleteScenarioAjax = (scenarioId) => {
  const url = '/msg/delete_scenario?scenario_id=' + scenarioId;
  return fetch(permissionsSvsBase + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(checkStatus)
    .then(() => scenarioId);
};

export const deleteSiteGroupScenarioAjax = (
  siteGroupId,
  accountId,
  scenarioId
) => {
  const url = `${permissionsSvsBase}/site-group/scenario?siteGroupId=${siteGroupId}&scenarioId=${scenarioId}`;
  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    body: JSON.stringify({ accountId }),
  })
    .then(checkStatus)
    .then((resp) => resp.json())
    .then((resp) => {
      return fromJS({
        scenarioIds: resp.deletedScenarios.map((s) => s.scenarioId),
        sitesWithErrors: resp.sitesWithErrors,
      });
    });
};

export function scenarioToRecord(scenario, status = STATUS.DRAFT) {
  let scenarioForRecord = scenario;
  let cleanStatus = status;
  if (status.includes('Old')) {
    cleanStatus = status.slice(0, -3);
  } else {
    if (status !== STATUS.DRAFT) {
      scenarioForRecord.live = true;
    }
  }
  scenarioForRecord.status = cleanStatus;

  if (scenario.schema_version === 1) {
    return new CustomScenario(scenarioForRecord);
  } else if (scenario.schema_version === 2 || scenario.schema_version === 0) {
    return new ScenarioV2(scenarioForRecord);
  } else {
    const parsedSteps = JSON.parse(scenario.scenario_json).steps;
    const steps = fromJS(
      parsedSteps.map((s) => {
        return new ScenarioStep(s.class, s.data);
      })
    );
    scenarioForRecord.steps = steps;
    return new Scenario(scenarioForRecord);
  }
}
