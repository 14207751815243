import { Collapse, Image, Popover, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router';

import arrowDown from '../../../../assets/icons/arrow_down.svg';
import BellIcon from '../../../../assets/icons/bell_icon.svg';
import { Button, Toggle } from '../../../../styleguide';
import { NotificationDetailsPopover } from './NotificationDetails';
import { accountFeaturesToAvailableDiagnoseMetrics } from '../../../../helpers/diagnose/metrics';
import { useSelector } from 'react-redux';
import {
  checkNotificationTypesToPermissions,
  createNotificationLink,
  formatNotificationsData,
  generateDescription,
  generateTitle,
  getType,
} from '../helpers';
import { getNotificationsData, markNotificationsRead } from '../../../../api/diagnose_dashboard/notifications';

function NotificationsPopover() {
  const [showPopover, setShowPopover] = useState(false);
  const [showUnreadOnly, setShowUnreadOnly] = useState(true);
  const [activeCollapse, setActiveCollapse] = useState(null);
  const [isUpdating, setUpdating] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [unseenCount, setUnseenCount] = useState(0);
  const [result, setResult] = useState({});
  const { page: unused1, count: unused2, ...notificationData } = result;
  const accountState = useSelector((state) => state.accountState);
  const currentUser = accountState?.getIn(['userDetails', 'value']);
  const metrics = useMemo(() => {
    return accountFeaturesToAvailableDiagnoseMetrics(
      currentUser.accountFeatures,
      currentUser.allFeatureAccess || currentUser.featureAccess,
    );
  }, [currentUser.accountFeatures, currentUser.allFeatureAccess, currentUser.featureAccess]);
  const NotificationTypes = useMemo(
    () => checkNotificationTypesToPermissions(currentUser.accountFeatures),
    [currentUser.accountFeatures],
  );

  const getNotifications = useCallback(
    async (isSeen) => {
      setLoading(true);
      const [all, ...types] = NotificationTypes;
      const response = await getNotificationsData({
        metric: metrics.join(';'),
        type: types.map(({ value }) => value).join(';'),
        page: 1,
        isSeen: isSeen ? false : undefined,
        pageSize: 80,
        ...(isSeen ? { sort_order: 1 } : {}),
      });
      const formattedData = formatNotificationsData(response);
      setResult(formattedData);
      if (isSeen) {
        setUnseenCount(formattedData.count);
      }

      const { page: unused1, count: unused2, ...collapseKeys } = formattedData;
      setActiveCollapse(Object.keys(collapseKeys) || []);
      setLoading(false);
    },
    [metrics],
  );

  useEffect(() => {
    getNotifications(showUnreadOnly);
  }, [showUnreadOnly]);

  const onMarkNotificationsRead = async () => {
    setUpdating(true);
    await markNotificationsRead({
      ids: Object.values(notificationData)
        .flat()
        .map((notification) => notification.id),
    });
    await getNotifications(showUnreadOnly);
    setUpdating(false);
  };

  const markNotificationRead = async (id, index, label) => {
    await markNotificationsRead({ ids: [id] });
    setUnseenCount((count) => count - 1);
    if (showUnreadOnly) {
      setResult((curResult) => ({
        ...curResult,
        [label]: [...curResult[label].slice(0, index), ...curResult[label].slice(index + 1)],
      }));
    } else {
      setResult((curResult) => ({
        ...curResult,
        [label]: [
          ...curResult[label].slice(0, index),
          {
            ...curResult[label][index],
            is_seen: true,
          },
          ...curResult[label].slice(index + 1),
        ],
      }));
    }
    setShowPopover(false);
  };

  const TooltipContent = () => (
    <div>
      <div className="tab-actions_container">
        <div style={{ width: '100%' }}>
          <h4>Alerts</h4>
        </div>
        <div style={{ width: '100%' }}>
          <label htmlFor="notification-list-toggle" className="tab-actions_action">
            <Toggle
              id="notification-list-toggle"
              size="small"
              checked={showUnreadOnly}
              disabled={isLoading}
              title={'Show only unread'}
              onChange={(newState) => setShowUnreadOnly(newState)}
            />
            <span className="notification-list-toggle_toggle">Show only unread</span>
          </label>
          <div className="tab-actions_action" style={{ textAlign: 'right' }}>
            <Button loading={isUpdating} onClick={onMarkNotificationsRead} type="link">
              Mark all as read
            </Button>
          </div>
        </div>
      </div>
      <Collapse
        activeKey={activeCollapse}
        ghost
        bordered={false}
        onChange={setActiveCollapse}
        expandIcon={(props) => <Image className={props.isActive ? 'active' : ''} src={arrowDown} preview={false} />}
        className="notifications-collapse notifications-collapse-popover"
      >
        {Object.entries(notificationData).map(([label, data]) => (
          <Collapse.Panel key={label} header={label}>
            {data.map((item, idx) => (
              <NotificationDetailsPopover
                key={item.id}
                onNotificationClick={() => markNotificationRead(item.id, idx, label)}
                link={createNotificationLink(item?.type, item?.parameters)}
                title={generateTitle(item?.type, item?.parameters)}
                type={getType(item?.type, item?.parameters?.metric)}
                status={!item?.is_seen && 'new'}
                description={generateDescription(item?.type, item?.parameters)}
                date={item?.created_at}
              />
            ))}
          </Collapse.Panel>
        ))}
      </Collapse>
      <div className="diagnose-notifications-tooltip--footer">
        <Link to="/diagnose/alerts">
          <Button type="link">View all alerts</Button>
        </Link>
      </div>
    </div>
  );

  const handlePopoverOpenChange = (evt) => {
    setShowPopover(evt);
  };

  return (
    <Popover
      title={TooltipContent}
      open={showPopover}
      onOpenChange={handlePopoverOpenChange}
      overlayClassName="diagnose--tooltip diagnose-notifications-tooltip"
      placement="bottomRight"
      trigger="hover"
      arrow={{ pointAtCenter: true }}
    >
      <div className="notification-bell-icon">
        <span className="count">
          <Spin spinning={isLoading} size="small">
            {+unseenCount > 99 ? '99+' : +unseenCount || 0}
          </Spin>
        </span>
        <SVG src={BellIcon} />
      </div>
    </Popover>
  );
}

export default NotificationsPopover;
