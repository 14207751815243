/* eslint-disable class-methods-use-this */
export default class PrevalenceRule {
  prevalenceColoring(filters, node) {
    const prevalence = Math.round(node.data.attributes.relative_prevalence * 100);
    if (prevalence >= 0 && prevalence <= 24) {
      return '#DFDFED';
    } else if (prevalence >= 25 && prevalence <= 49) {
      return '#C1C1DC';
    } else if (prevalence >= 50 && prevalence <= 74) {
      return '#A2A2CB';
    } else if (prevalence >= 75 && prevalence <= 99) {
      return '#8383BA';
    }
    return '#6565A9';
  }
  isSupport(filters, node) {
    if (!filters.metric_name) {
      const prevalence = Math.round(node.data.attributes.relative_prevalence * 100);
      if (prevalence >= filters.prevalence_filter[0] && prevalence <= filters.prevalence_filter[1]) {
        return true;
      }
    }
    return false;
  }
  getStrokeColor(filters, node) {
    return this.prevalenceColoring(filters, node);
  }
  getBorderColor(filters, node) {
    return this.prevalenceColoring(filters, node);
  }
}
