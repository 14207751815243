import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import CampaignGraph from './CampaignGraph';


export default class CampaignGraphAdblock extends CampaignGraph {

  static propTypes = {
    data: ImmutablePropTypes.map.isRequired,
    width: PropTypes.number.isRequired,
  }

  tickToPercentNum(num) {
      const percentNum = num * 100;
      if (percentNum < 1 && percentNum !== 0) {
        return percentNum.toFixed(2) + '%';
      } else {
        return percentNum.toFixed(0) + '%';
      }
  }

  generateYAxes() {
    const yAxes = super.generateYAxes();
    return List([yAxes.first().setIn(['ticks', 'callback'], num => this.tickToPercentNum(num))]);
  }

  generateOptions() {
    let options = super.generateOptions();

    options = options.setIn(['tooltips', 'callbacks', 'label'], ((item, data) => {
      const name = data.datasets[item.datasetIndex].label;
      return (name + ': ' + (item.yLabel * 100).toFixed(2) + ' percent');
    }));

    return options.setIn(['scales', 'yAxes'], this.generateYAxes())
  }
}
