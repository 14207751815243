import PropTypes from 'prop-types';
import React from 'react';
import { percentOfNum, numberWithCommas } from '../../../utils.js';
import CustomTable from '../../../common/CustomTable.js.jsx';
import TableBody from '../../../common/TableBody.js.jsx';
import TableRow from '../../../common/TableRow.js.jsx';
import TableCell from '../../../common/TableCell.js.jsx';
import TableHeader from '../../../common/TableHeader.js.jsx';
import TableCellHeader from '../../../common/TableCellHeader.js.jsx';

export default class extends React.Component {
  static propTypes = {
      data: PropTypes.object.isRequired,
      postEngagementData: PropTypes.node,
      title: PropTypes.node.isRequired
  };

  static defaultProps = {
      postEngagementData: null,
  };

  render() {
      var data = this.props.data;

      return (
        <div>
          <h4>{ this.props.title }</h4>
          <CustomTable>
            <TableHeader>
              <TableRow>
                <TableCellHeader>Device</TableCellHeader>
                <TableCellHeader>PVs</TableCellHeader>
                <TableCellHeader>Adblocked PVs</TableCellHeader>
                <TableCellHeader>Block Ratio</TableCellHeader>
                <TableCellHeader>Messages</TableCellHeader>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>Desktop</TableCell>
                <TableCell>{ numberWithCommas(data.desktop_pv) }</TableCell>
                <TableCell>{ numberWithCommas(data.abp_desktop_pv) }</TableCell>
                <TableCell>{ percentOfNum(data.abp_desktop_pv, data.desktop_pv) }</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mobile</TableCell>
                <TableCell>{ numberWithCommas(data.mobile_pv) }</TableCell>
                <TableCell>{ numberWithCommas(data.abp_mobile_pv) }</TableCell>
                <TableCell>{ percentOfNum(data.abp_mobile_pv, data.mobile_pv) }</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow className="total">
                <TableCell>Total</TableCell>
                <TableCell>{ numberWithCommas(data.total_pv) }</TableCell>
                <TableCell>{ numberWithCommas(data.abp_desktop_pv + data.abp_mobile_pv) }</TableCell>
                <TableCell>{ percentOfNum((data.abp_desktop_pv + data.abp_mobile_pv), data.total_pv) }</TableCell>
                <TableCell>{ this.props.title === "Control" ? null : numberWithCommas(data.total_msg) }</TableCell>
              </TableRow>
            </TableBody>
            { this.props.postEngagementData }
          </CustomTable>
        </div>
      )
  }
}
