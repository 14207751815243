import 'whatwg-fetch';
import { List, OrderedSet, Map } from 'immutable';
import { checkStatus, isFetched, addToFetched } from '../helper.js';
import { FETCHED } from '../../constants';
import { SiteToMessages } from '../../records/dns_records';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const ccpaBaseUrl = `${permissionsSvcBaseUrl}/ccpa`;

export function getDnsMessagesAjax(accountId, siteIds) {
  // //XXX stage test
  // siteIds = [113, 221, 1]
  const url = `/dns/messages?&siteIds=${JSON.stringify(siteIds)}`

  return fetch(ccpaBaseUrl + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp.map(obj => new SiteToMessages(obj)));
    });
}
