/* eslint-disable */
export default class DisclosedMetricRule {
  isSupport(filters, node) {
    if (filters.highlight_filter === 'is_disclosed') {
      for (const value of filters.disclosure_highlight) {
        if (value === 'disclosed' && node.data.attributes.is_disclosed) {
          if (node.data.attributes.referred.length) {
            let isAllChildrenDisclosed = true;
            for (const child of node.data.attributes.referred) {
              if (!child?.data?.attributes?.is_disclosed) {
                isAllChildrenDisclosed = false;
              }
            }
            return isAllChildrenDisclosed;
          }
          return true;
        }
      }
    }
    return false;
  }
  getStrokeColor() {
    return '#03A233';
  }
  getBorderColor() {
    return '#03A233';
  }
}
