import React from "react";
import moment from 'moment';
import { Modal, Table } from "antd";
import { Map, List, fromJS } from "immutable";
import {
  ResponsiveContainer,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend
} from "recharts";
import {
  LINE_GRAPH_STROKE_PATTERN_LIST,
  LINE_GRAPH_STROKE_WIDTH_LIST
} from "../../../constants";
import { Link } from "react-router";
import {
  getMsgPercentageTable,
  createCSVExportData
} from "../performanceNew/helper";
import CustomPopover from "../../common/CustomPopover";
import ExportPopoverContent from "../../ccpa/ccpa_reporting/ExportPopoverContent";

export default class MessagePerformaceModal extends React.Component {
  state = {
    msgValConvert: "number"
  };

  drawGraph = tableData => {
    let lineArr = [];
    tableData.map((d, j) => {
      lineArr.push(
        <Line
          key={"c-" + j}
          strokeWidth={LINE_GRAPH_STROKE_WIDTH_LIST.get(
            d.get("criteriaId") - 1
          )}
          strokeDasharray={LINE_GRAPH_STROKE_PATTERN_LIST.get(
            d.get("criteriaId") - 1
          )}
          type="monotone"
          dataKey={d.get("messageid") + "-" + d.get("criteriaId")}
          stroke={"#" + ((Math.random() * 0xffffff) << 0).toString(16)}
        />
      );
    });
    return lineArr;
  };

  setMsgValConvert = value => {
    this.setState({ msgValConvert: value });
  };
  dataFormater = number => {
    return number + "%";
  };
  render() {
    const columns = List([
      Map({
        title: "Criteria",
        dataIndex: "criteriaId",
        className: "left-align",
        render: (text, record) => <span>Criteria {text}</span>,
        width: "10%"
      }),
      Map({
        title: "Partition",
        dataIndex: "partitionname",
        className: "left-align",
        sorter: (a, b) => a.partitionname.localeCompare(b.partitionname),
        width: "10%"
      }),
      Map({
        title: "Message",
        dataIndex: "messageid",
        className: "left-align",
        sorter: (a, b) => a.messageid - b.messageid,
        width: "10%"
      }),
      Map({
        title: "Total Messages",
        dataIndex: "totalmsgs",
        className: "right-align",
        sorter: (a, b) => a.totalmsgs - b.totalmsgs,
        render: text => <span>{text.toLocaleString()}</span>,
        width: "10%"
      }),
      Map({
        title: "Consent All",
        dataIndex: "consentall",
        className: "right-align",
        sorter: (a, b) => a.consentall - b.consentall,
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%",
        width: "10%"
      }),
      Map({
        title: "Consent Some",
        dataIndex: "consentsome",
        className: "right-align",
        sorter: (a, b) => a.consentsome - b.consentsome,
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%",
        width: "10%"
      }),
      Map({
        title: "No Consent",
        dataIndex: "noconsent",
        className: "right-align",
        sorter: (a, b) => a.noconsent - b.noconsent,
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%",
        width: "10%"
      }),
      Map({
        title: "Reject All",
        dataIndex: "rejectall",
        className: "right-align",
        sorter: (a, b) => a.rejectall - b.rejectall,
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%",
        width: "10%"
      }),
      Map({
        title: "Legitimate Interest Objection Action",
        dataIndex: "legitimateintobj",
        sorter: (a, b) => a.legitimateintobj - b.legitimateintobj,
        className: "right-align",
        render: text =>
          this.state.msgValConvert == "number"
            ? text.toLocaleString()
            : text.toFixed(2) + "%",
        width: "10%"
      }),
      Map({
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            {record.siteid ? (
              <Link
                to={`/dialogue/scenarios?site_id=${record.siteid}`}
                target="_blank"
              >
                Need
                {window.innerWidth <= 1300 ? " " : <br />}
                Action
              </Link>
            ) : null}
          </span>
        ),
        className: "right-align",
        width: "10%"
      })
    ]);

    const tableTitle = (
      <div className="table-title-container">
        <div className="table-title-convert">
          <button
            className={this.state.msgValConvert == "number" ? "active" : ""}
            onClick={() => this.setMsgValConvert("number")}
          >
            Number
          </button>
          <button
            className={this.state.msgValConvert == "percentage" ? "active" : ""}
            onClick={() => this.setMsgValConvert("percentage")}
          >
            Percentage
          </button>
        </div>
      </div>
    );

    let tableData = List();
    this.props.selectedMsgs.map((item, i) => {
      item.get("msgs").map((m, j) => {
        tableData = tableData.push(
          Map({
            key: i + "-" + j,
            criteriaId: i + 1,
            messageid: m.get("messageid"),
            partitionname: m.get("partitionname"),
            totalmsgs: m.get("totalmsgs"),
            consentall: m.get("consentall"),
            consentallper: m.get("consentallper"),
            consentsome: m.get("consentsome"),
            consentsomeper: m.get("consentsomeper"),
            noconsent: m.get("noconsent"),
            noconsentper: m.get("noconsentper"),
            rejectall: m.get("rejectall"),
            rejectallper: m.get("rejectallper"),
            legitimateintobj: m.get("legitimateintobj"),
            legitimateintobjper: m.get("legitimateintobjper"),
            siteid: m.get("siteid"),
            aggData: m.get("aggData")
          })
        );
      });
    });

    let graphData = List();
    tableData.map(t => {
      let criteriaId = t.get("criteriaId");
      let messageid = t.get("messageid").toString();
      let getCriteriaData = this.props.msgPerformance.get(criteriaId - 1);

      getCriteriaData.map(p => {
        if (p.get(messageid)) {
          const index = graphData.findIndex(
            r => r.get("date") == p.get("date")
          );
          if (index >= 0) {
            graphData = graphData.setIn(
              [index, [messageid + "-" + criteriaId]],
              p.get(messageid)
            );
          } else {
            graphData = graphData.push(
              Map({
                date: p.get("date"),
                fancyDate: p.get("fancyDate"),
                [messageid + "-" + criteriaId]: p.get(messageid)
              })
            );
          }
        }
      });
    });

    let percentage = getMsgPercentageTable(tableData);
    graphData = graphData.sort(
      (a, b) =>
        moment(a.get("date")).valueOf() - moment(b.get("date")).valueOf()
    );
    let graphTitle = graphData.size
      ? moment(graphData.first().get("date")).format("MMMM YYYY")
      : "";
    const msgHeaders = columns
      .map(item =>
        Map({ label: item.get("title"), key: item.get("dataIndex") })
      )
      .pop()
      .insert(5, Map({ label: "Consent All %", key: "consentallper" }))
      .insert(7, Map({ label: "Consent Some %", key: "consentsomeper" }))
      .insert(9, Map({ label: "No Consent %", key: "noconsentper" }))
      .insert(11, Map({ label: "Reject All %", key: "rejectallper" }))
      .insert(
        13,
        Map({
          label: "Legitimate Interest Objection Action %",
          key: "legitimateintobjper"
        })
      );
    return (
      <Modal
        title={
          <span>
            Message Performance{" "}
            <CustomPopover
              tooltipContent={
                <ExportPopoverContent
                  filename="message_performance"
                  exportData={createCSVExportData(msgHeaders, tableData)}
                  pdfTitle={"Message Performance"}
                />
              }
              style="export-popover"
              icon="download"
              iconColor="#108ee9"
              title={
                <span
                  style={{
                    fontSize: "11px",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 100
                  }}
                >
                  Export Type Selection
                </span>
              }
            />
          </span>
        }
        visible={this.props.recentChangesModal}
        onCancel={this.props.handleCancel}
        footer={null}
        className="recentChangeModal"
      >
        <span className="modal-title">Consent All</span>
        <ResponsiveContainer width="99%" height={395}>
          <LineChart
            width={730}
            height={250}
            data={graphData.toJS()}
            margin={{ top: 5, right: 30, left: 20, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="fancyDate">
              <Label value={graphTitle} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              type="number"
              domain={[0, "dataMax"]}
              tickFormatter={this.dataFormater}
            />
            <Tooltip formatter={value => value + "%"} />
            <ReferenceLine
              x={moment().format("MMM Do")}
              stroke="#000"
              isFront={true}
              label="Today"
              strokeDasharray="1 1"
            />
            <Legend verticalAlign="top" height={36} />
            {this.drawGraph(tableData)}
          </LineChart>
        </ResponsiveContainer>
        <Table
          columns={columns.toJS()}
          dataSource={
            this.state.msgValConvert == "number"
              ? tableData.toJS()
              : percentage.toJS()
          }
          title={() => tableTitle}
          className="consnet-tableCSS"
          pagination={false}
          expandIconAsCell={false}
          expandIconColumnIndex={0}
          expandedRowRender={record => {
            const expTableColumns = columns
              .setIn([2, "title"], "Date")
              .setIn([2, "dataIndex"], "date");
            const dataSource =
              this.state.msgValConvert == "number"
                ? fromJS(record.aggData).map(t =>
                    t.set("criteriaId", record.criteriaId)
                  )
                : getMsgPercentageTable(fromJS(record.aggData)).map(t =>
                    t.set("criteriaId", record.criteriaId)
                  );
            return (
              <div className="table-seperator">
                <Table
                  columns={expTableColumns.toJS()}
                  dataSource={dataSource.toJS()}
                  className="consnet-table"
                  showHeader={false}
                />
              </div>
            );
          }}
        />
      </Modal>
    );
  }
}
