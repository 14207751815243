import { Record, List } from "immutable";
import Immutable from "immutable";

let idCounter = 1;

export const SubType = Record({
  subType: "",
});

export const MetaData = Record({
  data: new SubType(),
  type: "",
  version: "",
});

export class Message extends Record({
  description: "",
  id: "",
  created_at: "",
  message_category_id: "",
  message_sub_category_id: "",
  message_json: "",
  message_schema_version: "",
  msg_metadata: new MetaData(),
  public_id: "",
  site_id: "",
  status: "",
  message_config: null,
  choice_config: null,
  live: false,
  clientSideMessageId: null,
  message_choice: null,
  variables: null,
}) {
  constructor(message) {
    if (!message.clientSideMessageId) {
      message.clientSideMessageId = idCounter++;
    }

    super(Immutable.fromJS(message));
  }
  isDetailLoaded() {
    return this.message_config && this.choice_config ? true : false;
  }
  equals(messageRecord) {
    return this.id === messageRecord.id;
  }
  hashCode() {
    return this.id;
  }
}

export class IdNameValue extends Record({
  id: "",
  name: "",
  value: "",
}) {
  constructor(obj) {
    const updatedObj = Object.assign({}, obj);
    updatedObj.id = obj._id;
    super(updatedObj);
  }
}

export class SiteVariables extends Record({
  siteId: "",
  variables: List([]),
}) {
  constructor(sv) {
    const updatedSV = Object.assign({}, sv);
    if (updatedSV.variables && !List.isList(sv.variables)) {
      updatedSV.variables = List(sv.variables.map((v) => new IdNameValue(v)));
    }
    super(updatedSV);
  }
}

export class MessageVariables extends Record({
  defaultVariables: List([]),
  sitesVariables: List([]),
}) {
  constructor(mv) {
    const updatedMV = Object.assign({}, mv);
    if (updatedMV.defaultVariables) {
      updatedMV.defaultVariables = List(
        mv.defaultVariables.map((v) => new IdNameValue(v))
      );
      updatedMV.sitesVariables = List(
        mv.sitesVariables.map((sv) => new SiteVariables(sv))
      );
    }
    super(updatedMV);
  }
}
