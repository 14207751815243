import * as types from './action_types';
import { getAltpaySold, getRateExchangeApi } from '../api/altpay';

export const getAltpayData = (accoutId, siteIds) => {
    return (dispatch) => {
        dispatch(altpayPending());
        return getAltpaySold(accoutId, siteIds).then(data => {
            dispatch(altpayLoaded(data));
        }).catch(reason => {
            return dispatch(altpayLoadError(reason));
        });
    };
};

export const getRateExchange = () => {
    return (dispatch) => {
        dispatch(exchangePending());
        return getRateExchangeApi().then(data => {
            dispatch(exchangeLoaded(data));
        }).catch(reason => {
            return dispatch(exchangeError(reason));
        });
    };
};

const altpayPending = () => ({
    type: types.ALTPAY_PENDING,
});

const altpayLoaded = data => ({
    type: types.ALTPAY_LOADED,
    data,
});

const altpayLoadError = errorMessage => ({
    type: types.ALTPAY_ERROR,
    errorMessage,
});

const exchangePending = () => ({
    type: types.EXCHANGE_PENDING,
});

const exchangeLoaded = data => ({
    type: types.EXCHANGE_LOADED,
    data,
});

const exchangeError = errorMessage => ({
    type: types.EXCHANGE_ERROR,
    errorMessage,
});
