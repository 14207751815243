import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import StepChoiceOptionOIRGate from './StepChoiceOptionOIRGate.js.jsx';
import StepDelayNPageviews from './StepDelayNPageviews.js.jsx';
import StepIntRangeKVGate from './StepIntRangeKVGate.js.jsx';
import StepIsAdblockedGate from './StepIsAblockedGate.js.jsx';
import StepIsDesktopUserAgentGate from './StepIsDesktopUserAgentGate.js.jsx';
import StepModNDelay from './StepModNDelay.js.jsx';
import StepShowMessageAlways from './StepShowMessageAlways.js.jsx';
import StepShowMessageNTimes from './StepShowMessageNTimes.js.jsx';
import StepShowMessageFreqCap from './StepShowMessageFreqCap';
import StepShowMessageOnce from './StepShowMessageOnce.js.jsx';
import StepStringValueKVGate from './StepStringValueKVGate.js.jsx';
import StepReferrerRegexMatch from './StepReferrerRegexMatch.js.jsx';
import StepSearchReferrerGate from './StepSearchReferrerGate';
import StepSocialReferrerGate from './StepSocialReferrerGate';
import StepSessionSearchReferrerGate from './StepSessionSearchReferrerGate';
import StepSessionSocialReferrerGate from './StepSessionSocialReferrerGate';
import StepGeoGate from './StepGeoGate';
import StepClientIPGate from './StepClientIPGate';
import StepAltPayCompGate from './StepAltPayCompGate';
import StepWelectCompGate from './StepWelectCompGate';
import StepCustomCompGate from './StepCustomCompGate';
import StepTimeDelay from './StepTimeDelay';
import StepSessionMessageNLimit from './StepSessionMessageNLimit';
import StepIfStatement from './StepIfStatement.js.jsx';

import StepTypeSelector from '../StepTypeSelector.js.jsx';

import CustomIcon from '../../../common/CustomIcon.js.jsx';

import { ScenarioStep } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { Message } from '../../../../records/message_records';

var getReadableType = function(clazz) {
  if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews') {
    return 'Delay PV : N Simple';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.ModNDelay') {
    return 'Delay PV : N Circular Repeat';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate') {
    return 'Continue/Halt : If Match String Value (K/V)';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.IntRangeKVGate') {
    return 'Continue/Halt : If Match In Integer Range (K/V)';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways') {
    return 'Message : Show Always';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce') {
    return 'Message : Show Once';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes') {
    return 'Message : Show N Times';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap') {
    return 'Message : Frequency Cap';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate') {
    return 'Continue/Halt : If Adblocked PV';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate') {
    return 'Continue/Halt : If Desktop UA';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.ChoiceOptionOIRGate') {
    return 'Continue/Halt : If Opt-In Recovery';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch') {
    return 'Continue/Halt : Page URL Regex Match';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.SearchReferrerGate') {
    return 'Continue/Halt : Search Referrer Gate';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.SocialReferrerGate') {
    return 'Continue/Halt : Social Referrer Gate';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.SessionSearchReferrerGate') {
    return 'Continue/Halt : Session Search Referrer Gate';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.SessionSocialReferrerGate') {
    return 'Continue/Halt : Session Social Referrer Gate';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.GeoGate') {
    return 'Continue/Halt : If in Country';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.ClientIPGate') {
    return 'Continue/Halt : If Client IP in list';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.AltPayCompGate') {
    return 'Continue/Halt : If AltPay Comp Gate';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.WelectCompGate') {
    return 'Continue/Halt : If Welect Comp Gate';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.CustomCompGate') {
    return 'Continue/Halt : If Custom Comp Gate';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay') {
    return 'Time Delay';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit') {
    return 'Session Message Number Limit';
  } else if (clazz === 'com.sourcepoint.msgsvc.scenario.IfStatement') {
    return 'If Then Else Step Branching';
  }
};

const stepComponents = {
  StepDelayNPageviews,
  StepModNDelay,
  StepStringValueKVGate,
  StepIntRangeKVGate,
  StepShowMessageAlways,
  StepShowMessageOnce,
  StepShowMessageNTimes,
  StepShowMessageFreqCap,
  StepDesktopUserAgentGate: StepIsDesktopUserAgentGate,
  StepReferrerRegexMatch,
  StepSearchReferrerGate,
  StepSocialReferrerGate,
  StepSessionSearchReferrerGate,
  StepSessionSocialReferrerGate,
  StepChoiceOptionOIRGate,
  StepGeoGate,
  StepClientIPGate,
  StepIsAdblockedGate,
  StepAltPayCompGate,
  StepWelectCompGate,
  StepCustomCompGate,
  StepTimeDelay,
  StepSessionMessageNLimit,
  StepIfStatement,
};

export default class extends React.Component {
  static propTypes = {
    step: PropTypes.instanceOf(ScenarioStep).isRequired,
    index: PropTypes.number,
    editing: PropTypes.bool.isRequired,
    total: PropTypes.number,
    messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    altpay: PropTypes.bool.isRequired,
    welect: PropTypes.bool.isRequired,

    onEvent: PropTypes.func.isRequired,
    canDelete: PropTypes.bool,
    leftCellStyles: PropTypes.object,
  };

  static defaultProps = {
    canDelete: true,
  };

  handleTypeChange = (clazz) => {
    const step = new ScenarioStep(clazz);

    this.props.onEvent('stepUpdate', step);
  };

  handleDataChange = (data) => {
    this.props.onEvent('stepUpdate', this.props.step.set('data', data));
  };

  handleDelete = () => {
    this.props.onEvent('delete');
  };

  renderSettingsComponent = (clazz) => {
    if (!clazz) return null;

    const stepProps = {
      editing: this.props.editing,
      data: this.props.step.data,
      onChange: this.handleDataChange,
      messages: this.props.messages,
      altpay: this.props.altpay,
      welect: this.props.welect,
      leftCellStyles: this.props.leftCellStyles,
    };

    const stepType = 'Step' + clazz.slice(38);

    let StepComponent = stepComponents[stepType];

    if (!StepComponent) {
      StepComponent = stepComponents['Step' + clazz.slice(32)];
    }

    return <StepComponent { ...stepProps } />
  };

  renderSortUp = () => {
    if (this.props.index == null || this.props.index == 0) {
      return null;
    } else {
      return (<CustomIcon type={CustomIcon.types.ARROW_UP} onClick={ this.props.onEvent.bind(this, 'sortUp') } ></CustomIcon>)
    }
  };

  renderSortDown = () => {
    if (this.props.index == null || (this.props.index + 1) == this.props.total) {
      return null;
    } else {
      return (<CustomIcon type={CustomIcon.types.ARROW_DOWN} onClick={ this.props.onEvent.bind(this, 'sortDown') } ></CustomIcon>)
    }
  };

  render() {
    if (this.props.step.class === "com.sourcepoint.msgsvc.scenario.steps.ContinueScenario") {
      return null;
    }

    const deleteButton = this.props.canDelete && (
      <CustomIcon size={ CustomIcon.sizes.SMALL } type={ CustomIcon.types.DELETE } onClick={ this.handleDelete }></CustomIcon>
    );

    const indexSpan = this.props.index != null && (
        <span>{ this.props.index + 1 }</span>
    );

    const indexCell = <td style={ this.props.leftCellStyles }>{ indexSpan }</td>;

    const stepSelectorCell = this.props.editing ?
      (<td style={ this.props.leftCellStyles }><StepTypeSelector value={ this.props.step.class } onChange={ this.handleTypeChange } altpay={ this.props.altpay } welect={ this.props.welect }/></td>) :
      (<td style={ this.props.leftCellStyles }>{ getReadableType(this.props.step.class) }</td>);

    const editCell = this.props.editing && (
      <td>
        { deleteButton }
        { this.renderSortDown() }
        { this.renderSortUp() }
      </td>
    );

      let output;

      if (this.props.step.class === 'com.sourcepoint.msgsvc.scenario.IfStatement') {
          output = (
            <React.Fragment>
              <tr>
                { indexCell }
                { stepSelectorCell }
                <td></td>
                { editCell }
              </tr>
              { this.renderSettingsComponent(this.props.step.class) }
            </React.Fragment>
          );
      } else {
        output = (
          <tr>
            { indexCell }
            { stepSelectorCell }
            <td>{ this.renderSettingsComponent(this.props.step.class) }</td>
            { editCell }
          </tr>
        );
      }

    return output;
  }
}
