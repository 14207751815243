import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Input } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { SiteGroup, Site } from '../../../records/site_records';

export default class CreateSiteModal extends React.Component {
  static propTypes = {
    createOrUpdateSite: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    sites: ImmutablePropTypes.listOf(PropTypes.instanceOf(Site)).isRequired,
    siteGroup: PropTypes.instanceOf(SiteGroup).isRequired,
    siteAccess: ImmutablePropTypes.listOf(PropTypes.number).isRequired,
  }

  state = {
    name: '',
    type: "1"
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleOnClick = (e) => {
    e.preventDefault();
    this.props.createOrUpdateSite(this.state.name, this.state.type);
    this.props.closeModal();
  };

  handleChange = (event) => {
    const { name: field, value } = event.target;
    if (/^[a-zA-Z0-9-._~*]*$/i.test(value)) {
      this.setState({ [field]: value });
    };
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        onOk={this.handleOnClick}
        onCancel={this.props.closeModal}
        okText={this.props.site ? 'Save Changes' : 'Create property'}
        destroyOnClose
        className='shared-modal create-site-group'
      >
        <div>
          <label className='title'>Create a property</label>
          <label>Property name</label>
          <Input value={this.state.name} name="name" onChange={(e) => this.handleChange(e)} />
          <label>Type
          <select onChange={(e) => this.handleChange(e)} name="type" >
              <option value="1">Website</option>
              <option value="2"> App</option>
              <option value="3"> OTT</option>
            </select>
          </label>
        </div>
      </Modal>
    );
  }
}