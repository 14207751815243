import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { SessionMessageNLimit } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';

export default class StepSessionMessageNLimit extends React.Component {
    static propTypes = {
      editing: PropTypes.bool.isRequired,
      data: PropTypes.instanceOf(SessionMessageNLimit).isRequired,
      onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
      super(props);
      this.state = {
        limit: this.props.data.limit,
      };
      this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
      if (this.props.editing) ReactDOM.findDOMNode(this.refs.num_limit).focus();
    }

    componentWillReceiveProps(nextProps){
      if (nextProps.data) {
        this.setState({
          limit: nextProps.data.limit,
        });
      }
    }

    handleChange(event) {
      const value = parseInt(event.target.value);

      if (isNaN(value)) {
        this.setState({ limit: '' });
      } else {
        this.setState({ limit: value });
        this.props.onChange(this.props.data.set('limit', value));
      }
    }

    render() {
      const labelText = 'Max Number of Messages Shown in Session:';
      if (!this.props.editing) {
        return (<div> {labelText} { this.state.limit }</div>);
      } else {
        return (
          <div>
            <label>{labelText}</label>
            <input ref="num_limit" type="text" value={ this.state.limit } onChange={ this.handleChange }></input>
          </div>
        );
      }
    }
}
