import React, { Component } from 'react';
import { message as antMessage } from '../../../styleguide';
import { Modal } from "antd";
import _ from 'lodash';

import { log } from '../../../util';
import { SitesContext } from '../contexts';
import { categoryMap, newMessageEndpoints, subcategoryMap } from '../../../constants';
import { patchMessage } from './builderUtils';

export default class BuilderModal extends Component {
  state = { templates: [] }

  componentDidMount = () => {
    this.getAllTemplates();
  }

  getAllTemplates = () => {
    const category = categoryMap[this.props.messageCategory];
    const subCategory = subcategoryMap[this.props.messageSubcategory];
    const type = this.allTypes(category, subCategory);
    this.getTemplates(type, category, subCategory);

  };

  keyByVal = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  allTypes = (messageCategory, messageSubcategory) => {
    messageCategory = this.keyByVal(categoryMap, messageCategory);
    messageSubcategory = this.keyByVal(subcategoryMap, messageSubcategory);
    switch (messageSubcategory) {
      case 'notice':
        if (messageCategory === 'ccpa') {
          return 'ccpa'
        } else if (messageCategory === 'usnat') {
          return 'usnat_notice'
        } else if (messageCategory === 'preferences') {
          return 'preferences'
        } else {
          return 'adblock';
        }
      case 'sar':
        return 'sar';
      case 'ios':
        return 'ios14-ios';
      case 'custom':
        return 'custom-custom';
      case 'ccpa_pm':
        return 'ccpa-ccpa_pm';
      case 'us_pm':
        return 'usnat-us_pm';
      case 'usnat_notice':
        return 'usnat_notice';
      case 'dsarRetrieval':
        return 'dsar';
      case 'notice-tcf-v2':
        return 'gdpr-tcfv2-notice';
      case 'privacy-manager':
        if (messageCategory === 'gdpr') {
          return 'gdpr-tcfv2-privacy-manager';
        } else if (messageCategory === 'usnat') {
          return 'usnat-us_pm';
        }
      case 'privacy-manager-ott':
        return 'gdpr-tcfv2-privacy-manager-ott';
      case 'notice_non_iab':
        return 'gdpr-notice_non_iab'
      case 'privacy_manager_non_iab':
        return 'gdpr-privacy_manager_non_iab';
      case 'ccpa_ott':
        return 'ccpa_ott';
      case 'native-ott':
        return 'native-ott';
      case 'native-ott-ccpa':
        return 'native-ott-ccpa';
      case 'preferences':
        return 'preferences'
    }
  }
  getTemplates = async (type, category, subCategory) => {
    const url = `${newMessageEndpoints.MESSAGES_CATEGORY}?category=${category}&sub_category=${subCategory}&is_draft=false`;
    const templates = await (await (await fetch(url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })).json()).data.message_templates;
    let newtemplates = [];
    templates
      .filter(t => t.message_category_id === category)
      .map(m => {
        return patchMessage(m, true)
      })
      .map(template => {
        const template_json = JSON.parse(template.template_json);
        let isArray = Array.isArray(template_json);
        let templateJSON = (isArray) ? JSON.stringify(template_json) : JSON.stringify(Array(template_json));
        let template_type = template.is_system_template ? 'System' : 'Account';
        newtemplates.push({ id: template.id, template_name: template.name, template_json: templateJSON, accountBased: !template.is_system_template, type: type, template_type: template_type });
      });

    this.setState({ templates: newtemplates });
  };

  deleteTemplate = async templateId => {
    try {
      const url = `${newMessageEndpoints.DELETE}?template_id=${templateId}`;
      await fetch(url, {
        method: 'post',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      this.getAllTemplates()

    } catch (err) {
      antMessage.error('Could not remove message, try again');
    }
  };

  stripPmFromTemplate = (message) => {
    const clonedMessage = _.cloneDeep(message);
    strip(clonedMessage[0]);

    return clonedMessage

    function strip(child) {
      if (child.settings
        && child.settings.choice_option
        && child.settings.choice_option.data
        && child.settings.choice_option.data.privacy_manager_iframe_url
      ) delete child.settings.choice_option.data.privacy_manager_iframe_url;
      if (child.children) child.children.forEach(strip)
    }
  }

  createTemplate = async (messageJSON, type) => {
    const clonedMessage = this.stripPmFromTemplate(messageJSON);
    try {
      const message = await fetch(`${window.CONFIG.TEMPLATE_SVC}/template/${type}?account_id=${this.props.accountId}`, {
        method: 'post',
        body: JSON.stringify({
          "template_name": clonedMessage[0].name.trim(),
          "template_json": JSON.stringify(clonedMessage)
        })
      });

      if (message) {
        antMessage.success('Template successfully created');
        this.setState({ templates: [] }, () => this.getAllTemplates());
      };
    } catch (err) {
      log(err);
      antMessage.error('Could not create template, try again');
    };
  }

  render() {
    const { children, messageCategory } = this.props;
    const { templates } = this.state;
    const { createTemplate, deleteTemplate } = this;
    return (
      <Modal
        visible
        wrapClassName="message-builder"
        footer={null}
        title={null}
        maskClosable={false}
        closable={false}
      >
        <SitesContext.Consumer>
          {({ sites, previewSites }) => (
            children({
              createTemplate,
              deleteTemplate,
              sites,
              previewSites,
              templates,
              messageCategory,
            })
          )}
        </SitesContext.Consumer>
      </Modal>
    );
  }
}