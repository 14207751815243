import React from 'react'
import PropTypes from "prop-types";
import { Modal } from '../../../styleguide';

const CheckActiveCampaignsModal = (props) => {
  const { title, okText, goToCampaignPage, handleCancel, siteId, campaignData } = props;

  return (
    <Modal
        wrapClassName="property-delete-modal"
        title={title ? title : null}
        visible={true}
        okText={okText ? okText : "Delete"}
        onOk={(e) => goToCampaignPage(e,siteId)}
        onCancel={handleCancel}
      >
        <div className='modal-container'>
          <p className='error-message'>
            Properties with active public and/or stage campaigns can not be deleted. If you’d like to delete this property, please end the following active campaigns before attempting to delete the property again.
          </p>
          <div className='campaigns-set-wrapper'>
            <p className='warning-message'>
              <img src={require('../../../assets/images/privacy-lens/warning-icon.svg')}
                alt="warning"
              />
              The following <strong>active campaigns</strong> are using this property:
            </p>
            <div className='campaigns-sets'>
              { campaignData?.map(campaign => <div key={campaign.id} className='domain-set'>{campaign.description}</div>) }
            </div>
          </div>
        </div>
      </Modal>
  )
}

CheckActiveCampaignsModal.propTypes = {
    title: PropTypes.string.isRequired,
    okText: PropTypes.string,
    goToCampaignPage: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    campaignData: PropTypes.array.isRequired
}


export default CheckActiveCampaignsModal;