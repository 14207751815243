import PropTypes from 'prop-types';
import React from 'react'
import ReactDOM from 'react-dom';

import CustomButton from './CustomButton.js.jsx';

export default class extends React.Component {
    static propTypes = {
        placeholder: PropTypes.string.isRequired,
        onSubmit: PropTypes.func.isRequired,
        pending: PropTypes.bool.isRequired,
        hasHiddenButton: PropTypes.bool,
        cancel: PropTypes.func.isRequired,
    };

    static defaultProps = {
        hasHiddenButton: false
    };

    state = { name: "" };

    componentDidMount() {
        ReactDOM.findDOMNode(this.refs.name).focus();
        window.scroll(0, 0);
    }

    handleNameChange = (e) => {
        this.setState({ name: e.target.value })
    };

    handleOnClick = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.name);
    };

    render() {
        var disabled = !this.state.name || this.state.name.trim().length === 0 || this.props.pending;

        var hiddenButton;
        if (this.props.hasHiddenButton) {
          hiddenButton = (
              <div className="secret-custom-scenario" onClick={ this.props.onHiddenButtonClick.bind(null, this.state.name ) }></div>
          )
        }

        return (
          <div className='add-import-menu-container'>
          <table className="table add-menu">
            <tbody>
              <tr>
                <td>
                  <input ref="name" type="text" placeholder={ this.props.placeholder } onChange={ this.handleNameChange} />
                </td>
                <td className='create-cancel-button-container'>
                  <CustomButton className="cancel-activate-campaign" type={ CustomButton.types.PLAIN } onClick={ this.props.cancel }>CANCEL</CustomButton>
                  <CustomButton className="create-button" disabled={ disabled } onClick={ this.handleOnClick } >CREATE</CustomButton>
                </td>
              </tr>
            </tbody>
          </table>
          { hiddenButton }
        </div>
       )
    }
}
