import { List, Map } from 'immutable';
import {
  ConsentGateV2,
  DesktopUserAgentGate,
  IsAdblockedGate,
  StringValueKVGate,
  ReferrerRegexMatch,
  ShowMessageOnce,
  DelayNPageviews,
  TimeDelay,
  ShowMessageAlways,
  ConsentGateCCPA,
  ConsentGateUSNAT,
  USStateGate,
} from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { systemKeyValList } from '../../../../scenario_constants.js';

import ConsentStatus from '../Conditions/ConsentStatus';
import KeyValuePairTargeting from '../Conditions/KeyValuePairTargeting';
import NumRangeTargeting from '../Conditions/NumRangeTargeting';
import DeviceGate from '../Conditions/DeviceGate';
import IPTargeting from '../Conditions/IPTargeting';
import BooleanStep from '../Conditions/BooleanStep';
import PageUrlMatch from '../Conditions/PageUrlMatch';
import GeoTargeting from '../Conditions/GeoTargeting';

import { GeoGateRecord } from '../../../../constants.js';

let scenarioConsentUrl;
let scenarioCcpaUrl;
let ScenarioUsnatUrl;
if (process.env.REACT_APP_PUB_PORTAL_ENV === 'prod') {
   scenarioConsentUrl = 'https://sourcepoint.mgr.consensu.org/tcfv2';
   scenarioCcpaUrl = 'https://ccpa-service.sp-prod.net';
   ScenarioUsnatUrl = 'https://cdn.privacy-mgmt.com/usnat';
} else {
   scenarioConsentUrl = 'https://cmpv2.sp-stage.net/tcfv2';
   scenarioCcpaUrl = 'https://ccpa.sp-stage.net';
   ScenarioUsnatUrl = 'https://usp.sp-stage.net';
} 

export const consentGateClass =
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGate';
export const consentGateGdprV2Class =
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2';
export const ConsentGateCcpaClass =
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA';
export const ConsentGateUsnatClass =
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateUSNAT';
export const ConsentGatePreferencesClass =
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGatePreferences';
export const kvStringClass =
  'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate';
export const urlMatchClass =
  'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch'; 

let nextId = 1;

export const listData = List([
  Map({
    id: nextId++,
    title: 'Geo targeting (Country)',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
    conditionComponent: GeoTargeting
  }),
  Map({
    id: nextId++,
    title: 'Geo targeting (US State)',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.USStateGate',
    conditionComponent: GeoTargeting,
    extraProps: {
      state: true
    },
  }),
  Map({
    id: nextId++,
    title: 'Consent Status (GDPR)',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2',
    version: 2,
    conditionComponent: ConsentStatus,
    extraProps: {
      act: 'GDPR'
    },
    featureFlag: 'tcf_v2',
  }),
  Map({
    id: nextId++,
    title: 'Consent Status (U.S. Privacy)',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA',
    version: 'ccpa',
    conditionComponent: ConsentStatus,
    extraProps: {
      act: 'CCPA'
    },
    featureFlag: 'ccpa',
  }),
  Map({
    id: nextId++,
    title: 'Consent Status U.S. MultiState Privacy',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateUSNAT',
    version: 'usnat',
    conditionComponent: ConsentStatus,
    extraProps: {
      act: 'usnat'
    },
    featureFlag: 'usnat',
  }),
  Map({
    id: nextId++,
    title: 'Consent Status (Preferences)',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGatePreferences',
    version: 'preferences',
    conditionComponent: ConsentStatus,
    extraProps: {
      act: 'preferences'
    },
    featureFlag: 'preferences',
  }),
  Map({
    id: nextId++,
    title: 'Adblock Status',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate',
    conditionComponent: BooleanStep,
    featureFlag: 'adblock_v2',
  }),
  Map({
    id: nextId++,
    title: 'Device',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
    conditionComponent: DeviceGate
  }),
  Map({
    id: nextId++,
    title: 'Key Value Pair Targeting',
    isChecked: false,
    className: kvStringClass,
    conditionComponent: KeyValuePairTargeting
  }),
  // Map({
  //   id: 7,
  //   value: "",
  //   title: "Cookie value targeting",
  //   isChecked: false,
  //   className:,
  // }),
  // Map({
  //   id: nextId++,
  //   title: 'Social gate',
  //   isChecked: false,
  //   className: 'com.sourcepoint.msgsvc.scenario.steps.SocialReferrerGate',
  //   conditionComponent: BooleanStep,
  // }),
  // Map({
  //   id: nextId++,
  //   title: 'First Page View Only search gate',
  //   isChecked: false,
  //   className:
  //     'com.sourcepoint.msgsvc.scenario.steps.SessionSearchReferrerGate',
  //   conditionComponent: BooleanStep,
  // }),
  // Map({
  //   id: nextId++,
  //   title: 'First Page View Only social gate',
  //   isChecked: false,
  //   className:
  //     'com.sourcepoint.msgsvc.scenario.steps.SessionSocialReferrerGate',
  //   conditionComponent: BooleanStep,
  // }),
  //Map({
   // id: nextId++,
  //  title: 'Opt in recovery guide',
  //  isChecked: false,
  //  className: 'com.sourcepoint.msgsvc.scenario.steps.ChoiceOptionOIRGate',
  //  conditionComponent: BooleanStep,
  //}),
  //Map({
    //id: nextId++,
    //title: 'Ip targeting',
    //isChecked: false,
    //className: 'com.sourcepoint.msgsvc.scenario.steps.ClientIPGate',
    //conditionComponent: IPTargeting
  //}),
  Map({
    id: nextId++,
    title: 'Page URL Match',
    isChecked: false,
    className: 'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch',
    conditionComponent: PageUrlMatch
  }),
  //Map({
  //  id: nextId++,
  //  value: '',
  //  title: 'Number Range Targeting',
  //  isChecked: false,
  //  className: 'com.sourcepoint.msgsvc.scenario.steps.IntRangeKVGate',
  //  conditionComponent: NumRangeTargeting
 // })
].concat(
  systemKeyValList
    .map(({ key, featureFlag, label }) => {
      return Map({
        id: nextId++,
        featureFlag,
        title: label,
        isChecked: false,
        className: `${kvStringClass}:${key}`,
        conditionComponent: KeyValuePairTargeting
      });
    })
));

export const stepList = List([
  Map({
    title: '',
    conditions: List(),
    message: List([
      Map({
        msgDelivery: 'Show Message Always',
        msg: '-1',
        msgDelay: 'None',
        msgRepeat: 'No Repeat',
        msgDelayUnit: 'Seconds',
        msgTimeSpan: 1,
      }),
    ]),
  }),
]);

export const dataTemplates = List([
  Map({
    key: 't1',
    type: 'Scenario for GDPR Campaign - With Re-consent',
    campaignType: 1,
    goal: 'Consent All',
    stepList: List([
      Map({
        title: 'Message new users who have no consent',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 0,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Once',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
      Map({
        title: 'Message new users who have no consent',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 5,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Once',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't2',
    type: 'Scenario Basic GDPR Campaign',
    campaignType: 1,
    goal: 'Consent All',
    stepList: List([
      Map({
        title: 'Message new users who have no consent',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 0,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Once',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
      Map({
        title: 'Message new users who have no consent',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 5,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Once',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: 4011,
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't3',
    type:
      'Scenario for AdBlock messaging with Key Value Pair Subscriber Targeting - locked modal',
    campaignType: 3,
    goal: 'Adblock_ Locked Modal',
    stepList: List([
      Map({
        title: 'Message new users who are using Adblocker',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            value: new IsAdblockedGate({
              continue: true,
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate',
          }),
          Map({
            value: new DesktopUserAgentGate({
              continue_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
          }),
          Map({
            value: new StringValueKVGate({
              k: 'age',
              v: 'test',
              type: 'match',
              allow_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate',
          }),
        ]),
        messages: List([
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
      Map({
        title: 'Message new users who have no consent',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 5,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2',
          }),
        ]),
        messages: List([
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay',
            value: new TimeDelay({
              delay_secs: 2,
            }),
          }),
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: 4011,
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't4',
    type:
      'Scenario for AdBlock messaging with Key Value Pair Subscriber Targeting - dismissible modal',
    campaignType: 3,
    goal: 'Adblock_ Locked Modal',
    stepList: List([
      Map({
        title: 'Message new users who are using Adblocker',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            value: new IsAdblockedGate({
              continue: true,
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate',
          }),
          Map({
            value: new DesktopUserAgentGate({
              continue_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
          }),
          Map({
            value: new StringValueKVGate({
              k: 'age',
              v: 'test',
              type: 'match',
              allow_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate',
          }),
        ]),
        messages: List([
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
      Map({
        title: 'Message new users who have no consent',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 5,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2',
          }),
        ]),
        messages: List([
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay',
            value: new TimeDelay({
              delay_secs: 2,
            }),
          }),
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't5',
    type: 'Anti-Adblock Messaging with URL Exclusion Targeting',
    campaignType: 3,
    goal: 'Adblock',
    stepList: List([
      Map({
        title: 'Message new users who are using Adblocker',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            value: new IsAdblockedGate({
              continue: true,
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate',
          }),
          Map({
            value: new DesktopUserAgentGate({
              continue_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
          }),
          Map({
            value: new ReferrerRegexMatch({
              regex: 'test',
              continue_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch',
          }),
        ]),
        messages: List([
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't6',
    type: 'Anti-Adblock Messaging with a page view delay of one',
    campaignType: 3,
    goal: 'Adblock',
    stepList: List([
      Map({
        title: 'Message new users who are using Adblocker',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            value: new IsAdblockedGate({
              continue: true,
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate',
          }),
          Map({
            value: new DesktopUserAgentGate({
              continue_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
          }),
          Map({
            value: new StringValueKVGate({
              k: 'age',
              v: 'test',
              type: 'match',
              allow_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate',
          }),
        ]),
        messages: List([
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews',
            value: new DelayNPageviews({
              num_pv: 1,
            }),
          }),
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
      Map({
        title: 'Message new users who have no consent',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 5,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2',
          }),
        ]),
        messages: List([
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.TimeDelay',
            value: new TimeDelay({
              delay_secs: 2,
            }),
          }),
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce',
            value: new ShowMessageOnce({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't7',
    type: 'Simple GDPR Scenario With Re-Consent (All EEA)',
    campaignType: 1,
    goal: '',
    stepList: List([
      Map({
        title: '',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 0,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Always',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
      Map({
        title: '',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 7,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Always',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't8',
    type: 'Simple GDPR Scenario (All EEA)',
    campaignType: 1,
    goal: '',
    stepList: List([
      Map({
        title: '',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 0,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Always',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't9',
    type: 'Simple GDPR Scenario (Device Type)',
    campaignType: 1,
    goal: '',
    stepList: List([
      Map({
        title: '',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 0,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
          Map({
            value: new DesktopUserAgentGate({
              continue_on_satisfy: true,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Always',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
      Map({
        title: '',
        conditions: List([
          Map({
            id: 1,
            value: new GeoGateRecord({
              continue: true,
              geo_list: List(['allEEA']),
            }),
            title: 'Geo targeting',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.GeoGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateV2({
              status: 0,
              consent_origin: scenarioConsentUrl,
              version: 2,
            }),
            title: 'Consent Status (GDPR)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGate',
          }),
          Map({
            value: new DesktopUserAgentGate({
              continue_on_satisfy: false,
            }),
            className:
              'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Always',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't10',
    type: 'U.S.Privacy Show Message Always (CA Only)',
    campaignType: 2,
    goal: '',
    stepList: List([
      Map({
        title: '',
        conditions: List([
          Map({
            id: 1,
            value: new USStateGate({
              continue: true,
              state_list: List(['CA']),
            }),
            title: 'Geo targeting (US State)',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.USStateGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateCCPA({
              status: 0,
              consent_origin: scenarioCcpaUrl,
              version: 'ccpa',
            }),
            title: 'Consent Status (U.S. Privacy)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Always',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't11',
    type: 'U.S.Privacy Show Message Always (Entire U.S.)',
    campaignType: 2,
    goal: '',
    stepList: List([
      Map({
        title: '',
        conditions: List([
          Map({
            id: 1,
            value: new USStateGate({
              continue: true,
              state_list: List(['All']),
            }),
            title: 'Geo targeting (US State)',
            isChecked: true,
            className: 'com.sourcepoint.msgsvc.scenario.steps.USStateGate',
          }),
          Map({
            id: 2,
            value: new ConsentGateCCPA({
              status: 0,
              consent_origin: scenarioCcpaUrl,
              version: 'ccpa',
            }),
            title: 'Consent Status (U.S. Privacy)',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Always',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't12',
    type: 'iOS IDFA',
    campaignType: 4,
    goal: '',
    stepList: List([
      Map({
        title: '',
        conditions: List([
          Map({
            id: 1,
            value: new StringValueKVGate({
              k: '_sp_idfa_status',
              v: 'unknown',
              type: 'match',
              allow_on_satisfy: true,
            }),
            title: 'IDFA Gate',
            isChecked: false,
            className: 'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate:_sp_idfa_status',
          }),
        ]),
        messages: List([
          Map({
            key: 1,
            label: 'Show Message Always',
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
  Map({
    key: 't13',
    type: 'Anti-Adblocking (Web Only)',
    campaignType: 3,
    goal: 'AdBlock',
    stepList: List([
      Map({
        title: '',
        conditions: List([
          Map({
            value: new IsAdblockedGate({
              continue: true,
            }),
            className: 'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate',
          }),
        ]),
        messages: List([
          Map({
            className: 'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways',
            value: new ShowMessageAlways({
              message_id: '-1',
            }),
          }),
        ]),
      }),
    ]),
  }),
]);

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
