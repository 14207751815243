import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { List, Map, is } from 'immutable';
import { SearchOutlined, WarningFilled, CheckOutlined } from '@ant-design/icons';
import { Modal, Alert} from 'antd';
import { Table, Button, message, Select, Input } from '../../../styleguide';
import { DomainSet, Domain } from '../../../records/domain_set_records';
import DomainsFileUpload from './DomainsFileUpload';
import { getAccountAjax } from '../../../api/consent_quality/account';
import { getAllAccountDomainsPerRegion } from '../../../api/consent_quality/domain_set';
import { getStandards } from '../../../actions/standard_actions';
import { regionsMap } from '../../../constants';
import CSVLink from "../../common/CSVLink.js";
import Loading from '../../common/Loading.js.jsx';

class NewDomainSetModal extends React.Component {
  static propTypes = {
    createDomainSet: PropTypes.func.isRequired,
    updateDomainSet: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  state = {
    domainSet: this.props.domainSet || new DomainSet({}),
    defaultRulesState: true,
    domainSetsToAdd: new List([]),
    highlightedStandard: null,
    addDomainManually: false,
    domainsInput: '',
    updatingDomain: null,
    search: '',
    hideRuleDetails: true,
    propertiesToDelete: [],
    standardImpactCollapse: false,
    addPropertiesCollapse: false,
    activationCollapse: false,
    domainNumAllowanceForThisSet: 0,
    contractEndDate: new Date(),
    regions: Object.keys(regionsMap),
    duplicateDomains: [],
    invalidDomains: [],
    domainsInOterSets: new Set(),
    numDomainsInOtherSets: 0,
    validationErrors: { domainName: null, standard: null, region: null, addDomains : null },
    domainsUpdated: false,
    loading: false,
  }

  componentDidMount() {
    getAccountAjax(this.props.accountId).then(account => {

      getAllAccountDomainsPerRegion(this.props.accountId, this.props.domainSet.region || 'GB', this.props.domainSet.id).then((otherActiveDomains) => {
        
        const domainsInOterSets = new Set();
        if (!this.props.new) {
          otherActiveDomains.domains.forEach(url => {
            domainsInOterSets.add(url);
          });
        }
        this.setState({ 
          domainsInOterSets,
        });

        let domainNumAllowanceForThisSet;
        if (account.scanAllowance !== -1) {
          const numDomainsInOtherSets = this.setNumDomainsInOtherSets(this.state.domainSet.domains);
          domainNumAllowanceForThisSet = (account.scanAllowance - otherActiveDomains.totalCount) + (this.state.domainSet.domains.size - numDomainsInOtherSets);
        }
        let updatedDomainSet = this.state.domainSet;
        if (this.props.new) {
          if (account.scanAllowance === -1 || domainNumAllowanceForThisSet > 0) {
            updatedDomainSet = this.state.domainSet.set('domainSetStatus', 'Active').set('activateDate', new Date());
          } else {
            updatedDomainSet = this.state.domainSet.set('domainSetStatus', 'Disabled');
          }
        }

        this.setState({ 
          domainNumAllowanceForThisSet,
          scanAllowance: account.scanAllowance,
          contractEndDate: account.endDate,
          domainSet: updatedDomainSet,
          regions: account.regions,
        });
  
        if (this.props.activate) {
          this.activate();
        }
      });
    });
  }

  componentDidUpdate = (oldProps, prevState) => {
      if (prevState.domainSet.domains.size < this.state.domainSet.domains.size) {
        if ((this.state.scanAllowance !== -1 && (this.state.domainNumAllowanceForThisSet < this.state.domainSet.domains.size)) && this.state.domainSet.domainStatus !== 'Disabled') {
          const updatedDomainSet = this.state.domainSet.set('domainSetStatus', 'Disabled');
          this.setState({ 
            domainSet: updatedDomainSet,
          });
        }
      }
  }

  setDomainsInOtherSets = (region) => {
    getAllAccountDomainsPerRegion(this.props.accountId, region, this.props.domainSet.id).then((resp) => {
      const domainsInOterSets = new Set();
      resp.domains.forEach(url => {
        domainsInOterSets.add(url);
      });
      this.setState({
        domainsInOterSets,
      });
    });
  }

  getDeactivateDate = () => {
    const PERIOD = 30;
    const todayDate = moment();
    const acviteDays = todayDate.diff(moment(this.state.domainSet.get('activateDate')), 'days');
    const daysBeforePeriodEnds = PERIOD - (acviteDays % PERIOD);
    const datePeriodEnds = todayDate.add(daysBeforePeriodEnds, 'days');
    return datePeriodEnds._d
  }

  updateSearch = (e) => this.setState({ search: e.target.value });

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  validateDomainSet = (updatedDomainSet) => {
    const { domainSet } = this.state;

    const duplicateName = this.props.allDomainSets.filter(a => a.domainSetStatus !== "DeletedPending").map(ds => ds.name).find(ds => ds === domainSet.name)
    this.setState({validationErrors: {domainName: null, standard: null, region: null, addDomains: null}})

    let domainName = null;
    let region = !domainSet.region ? "Region must be selected" : null;
    let standard = !domainSet.standards.size ? "Standard must be selected" : null;
    let addDomains = !domainSet.domains.size ? "Add atleast one domain in property set" : null;

    if(updatedDomainSet?.name.trim().length === 0) {
      domainName = <>Name field can not be empty</>
    } else if(duplicateName && duplicateName !== this.props.domainSet.name) {
      domainName = <>Property set with name <strong>{domainSet.name}</strong> already exists</>
    }
    this.setState({validationErrors: {domainName, standard, region, addDomains}})
    return domainName || region || standard || addDomains;
  }

  handleApply = () => {
    let updated = this.state.domainSet;

    if(this.validateDomainSet(updated)){
      return;
    }

    if (!this.state.domainsUpdated) {
      updated = updated.toMap().delete('domains');
    }

    if (this.state.domainSet.deactivateDate === '') {
      updated = updated.set('deactivateDate', null);
    }
    if (this.state.domainSet.activateDate === '') {
      updated = updated.set('activateDate', null);
    }
    this.setState({ loading: true });
    if (this.props.activate) {
      this.props.updateDomainSet(this.props.accountId, updated).then(resp => {
        message.success(<><strong>{resp.name}</strong> activated successfully</>);
        this.props.setPlUsage();
        this.props.getStandards(this.props.accountId, false, true);
        this.props.switchDomainsTab("1");
        this.setState({ loading: false });
        this.props.closeModal();
      });
    } else if (this.props.new) {
      this.props.createDomainSet(this.props.accountId, updated).then(resp => {
        message.success(<><strong>{resp.name}</strong> added successfully</>);
        this.props.setPlUsage();
        this.props.getStandards(this.props.accountId, false, true);
        this.props.switchDomainsTab(resp.domainSetStatus === "Disabled" ? "2" : "1");
        this.setState({ loading: false });
        this.props.closeModal();
      });
    } else {
      this.props.updateDomainSet(this.props.accountId, updated).then(resp => {
        message.success(<><strong>{resp.name}</strong> updated successfully</>);
        this.props.setPlUsage();
        this.props.getStandards(this.props.accountId, false, true);
        this.setState({ loading: false });
        this.props.closeModal();
      });
    }
  }

  onDomainNameChange = (e) => {
    this.setState({ domainSet: this.state.domainSet.set('name', e.target.value) },() => {
      const {domainSet, validationErrors} = this.state;
      if(domainSet.name.trim().length > 0) this.setState({validationErrors : { ...validationErrors, domainName : null }})
    })
  }

  selectRegion = (region) => {
    this.setDomainsInOtherSets(region);
    this.setState({ domainSet: this.state.domainSet.set('region', region)},() => {
      const {domainSet, validationErrors} = this.state;
      if(domainSet.region) this.setState({validationErrors : { ...validationErrors, region : null }})
    });
  }

  selectStandards = (standardIds) => {
    const standards = this.props.standards.filter(s => standardIds.includes(s.id));
    this.setState({ domainSet: this.state.domainSet.set('standards', standards) },() => {
      const {domainSet, validationErrors} = this.state;
      if(domainSet.standards.size) this.setState({validationErrors : { ...validationErrors, standard : null }})
    });
  }

  removeStandard = (id) => {
    const updatedStandards = this.state.domainSet.standards.filterNot(s => s.id === id);
    this.setState({ domainSet: this.state.domainSet.set('standards', updatedStandards) });
  }

  addDomainsManually = (e) => {
    e.stopPropagation();
    const domainsToAdd = this.state.domainsInput.replace(/\s/g, '').split(',').filter(d => !!d);
    const domainsToAddUnique = [...new Set(domainsToAdd.map(ds => ds.replace(/www.|http:\/\/|https:\/\//g, '')))].map(domain => {
      return new Domain({ url: domain });
    });

    this.addDomains(domainsToAddUnique);
  }

  addDomains = (domains) => {
    const regx = /^[a-zA-Z0-9][a-zA-Z0-9-.~*]*[.][a-zA-Z0-9-.~*]*$/i;
    const alreadyAddedDomains = this.state.domainSet.domains.map(domain => domain.url.toLowerCase());
    const duplicateDomains = [];
    const invalidDomains = [];

    domains.forEach(domain => {
      if(alreadyAddedDomains.includes(domain.url.toLowerCase())){
        duplicateDomains.push(domain.url)
      }
      if (!regx.test(domain.url.toLowerCase())) {
        invalidDomains.push(domain.url)
      }
    })
    
    // The check should be done in frontend, uncomment to see if backend doesn't acccept some domain
    // checkDomains(domains.map(d => d.url)).then(resp => {
    // });

    const updatedDomainSet = this.state.domainSet.update('domains', ds => ds.concat(domains.filter(domain => !duplicateDomains.includes(domain.url) && !invalidDomains.includes(domain.url))));

    this.setState({duplicateDomains, invalidDomains},()=>{
      const { validationErrors } = this.state
      this.setState({
        domainSet: updatedDomainSet,
        domainsInput: '',
        validationErrors: { ...validationErrors, addDomains : updatedDomainSet.domains.size <= 0 && validationErrors.addDomains },
        domainsUpdated: true,
      });
    });
    this.setNumDomainsInOtherSets(updatedDomainSet.domains);
  }

  deleteDomain = (url) => {
    const filtered = this.state.domainSet.domains.filterNot(d => d.url === url);
    this.setState({
      domainSet: this.state.domainSet.set('domains', filtered),
      domainsUpdated: true,
    });
    this.setNumDomainsInOtherSets(filtered);
  }

  generatePropertyTable = () => {
    let domains = this.state.domainSet.domains;

    const columns = List([
      Map({
        title: <div>Total Properties ({domains.size})</div>,
        dataIndex: 'url',
        key: 'url',
      }),

      Map({
        title: '',
        dataIndex: 'actions',
        key: 'actions',
      }),
    ]);

    if (this.state.search) {
      domains = domains.filter(d => d.url.toLowerCase().includes(this.state.search.toLowerCase()));
    }
    const nonUSADomains = domains.filter(d => !d.url.endsWith(".com"));
    let dataSource = domains.map((d, i) => {

      const propertyUrl = d.url;

      return Map({
        key: d.url,
        url: propertyUrl,
        actions: (this.props.readOnly || this.state.loading) ? null : (<div className='actions'>
          <img src={require('../../../assets/images/privacy-lens/delete-item.svg')} className="delete-icon" onClick={() => this.deleteDomain(d.url)} />
        </div>),
      });
    });

    dataSource = dataSource.unshift(Map({
      key: 'remove actions',
      url: '',
      actions: (this.props.readOnly || this.state.loading) ? null : (<div className='remove-actions'>
        <span className="remove-selected-properties" onClick={() => this.removeSelectedProperties()}>Remove Selected</span>
      </div>),
    }))

    return new Map({ dataSource, columns });
  }


  generateRuleDetailsTable = () => {

    const columns = List([
      Map({
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        type: 'checkbox',
      }),
      Map({
        title: 'Must Have',
        dataIndex: 'mustHave',
        key: 'mustHave',
      }),
      Map({
        title: 'Nice To Have',
        dataIndex: 'niceToHave',
        key: 'niceToHave',
      }),
    ]);

    const dataSource = this.state.domainSet.standards.size > 0 && this.state.domainSet.standards.get(0).rules.map(r => {
      return Map({
        key: r.rule.name,
        name: r.rule.name,
        mustHave: <span className={!r.niceToHave && "checked-rules"}></span>, //TODO: double check logic for rules mustHave/niceToHave
        niceToHave: <span className={r.niceToHave && "checked-rules"}></span>,
      });
    });
    return new Map({ dataSource, columns });
  }

  showRuleDetails = () => {
    this.setState({ hideRuleDetails: !this.state.hideRuleDetails })
  }

  setDeactivateDate = (key) => {
    if (key === 'repeatScan') {
      this.setState({ domainSet: this.state.domainSet.set('deactivateDate', null).set('domainSetStatus', 'Active') });
    } else {
      const deactivateDate = this.getDeactivateDate();
      this.setState({ domainSet: this.state.domainSet.set('deactivateDate', deactivateDate).set('domainSetStatus', 'DisabledPending') });
    }
  }

  deactivate = () => {
    let updatedSet = this.state.domainSet;
    if (this.props.new || this.props.domainSet.domainSetStatus === 'Disabled') {
      updatedSet = updatedSet.set('domainSetStatus', 'Disabled');
    } else {
      updatedSet = updatedSet.set('domainSetStatus', 'DisabledPending').set('deactivateDate', this.getDeactivateDate())
    }
    if (this.props.new) {
      updatedSet = updatedSet.set('activateDate', null);
    }
    this.setState({ domainSet: updatedSet });
  }

  activate = () => {
    const activateDate = this.props.new ? new Date() : this.props.domainSet.domainSetStatus !== 'Disabled' ? this.props.domainSet.activateDate : new Date();
    this.setState({ 
      domainSet: this.state.domainSet
        .set('domainSetStatus', 'Active')
        .set('activateDate', activateDate)
    });
  }

  removeSelectedProperties = () => {
    const filtered = this.state.domainSet.domains.filter(d => !this.state.propertiesToDelete.includes(d.url));
    this.setState({
      domainSet: this.state.domainSet.set('domains', filtered),
      domainsUpdated: true,
    });
  }

  setNumDomainsInOtherSets = (thisSetDomains) => {
    let numDomainsInOtherSets = 0;
    thisSetDomains.map(d => d.url).forEach(url => {
      if (this.state.domainsInOterSets.has(url)) {
        numDomainsInOtherSets += 1;
      }
    });
    this.setState({
      numDomainsInOtherSets,
    });
    return numDomainsInOtherSets;
  }
  renderDownloadBadDomainsLink = () => {
    const data = this.state.invalidDomains.map(domain => [domain]);
    data.unshift(['Incorrect Format Domains']);
    return (
      <span className='incorrect-domains-link'>
        <CSVLink
        data={data}
        enclosingCharacter={``}
        filename={'Incorrect Format Domains ' + new Date().toDateString() + '.csv'}
        onClick={(e) => e.stopPropagation()}
        isSanitized={true}>
        Click here 
        </CSVLink> for complete list.
      </span>
    );
  }

  render() {
    const domainSet = this.state.domainSet;
    const regions = Object.values(regionsMap);
    const { validationErrors }= this.state
    let rules;
    if (!this.state.domainSet.standards)
    if (this.state.domainSet.standards.size) {
      const standard = this.state.highlightedStandard || this.state.domainSet.standards.first();
      rules =
        (<div className='right' key='right'>
          <p className='title-4' key={'title-4'}>{`Standard ${standard.name} rules (${standard.rules.size})`}</p>
          {standard.rules.map(r => (
            <p key={r.rule.name}>{r.rule.name}</p>
          ))}
        </div>);
    }

    let addDomainsContent;
    if (this.state.addDomainManually) {
      addDomainsContent = (
        <div className='manual-add-domains'>
          <p className='domains-title' key='domains-title'>
            <span>Domains </span>{`(${this.state.domainSet.domains.size} Domains Added)`}
          </p>
          <div className='add-domains-input'>
            <p className='comment' key='comment'><span>Add domains. </span>(To add multiple domains separate by comma)</p>

            <Input
              disabled={this.state.loading}
              value={this.state.domainsInput}
              onChange={(e) => this.setState({ domainsInput: e.target.value })}
            />
            <button
              disabled={this.state.loading}
              onClick={(e) => this.addDomainsManually(e)}
              className={this.state.addDomainManually ? 'selected' : null}
              disabled={!this.state.domainsInput}
            >
              Add
            </button>
          </div>
        </div>
      )
    } else {
      addDomainsContent = <div className="wrap">
        <div className="arrow"></div>
        <div className="arrow-cover"></div>
        <div className="box">
          <DomainsFileUpload addDomains={this.addDomains} disabled={this.state.loading}/>
        </div>
      </div>;
    }

    const tablePropertyData = this.generatePropertyTable();
    const tableRuleDetailsData = this.generateRuleDetailsTable();

    let warningMessage;
    let greenMessage;
    let redMessage;
    let warningMessage2;
    let duplicateDomainsMessage;
    let invalidDomainsMessage;
    let warningMessage3;
    if (this.state.scanAllowance !== -1) {
      warningMessage3 = "If you choose to deactivate this property set after this cycle, you'll lose the scoring result once this cycle ends. If you need to access the scoring result, make sure you download it before the property set deactivates.";
      if (this.state.domainNumAllowanceForThisSet === (this.state.domainSet.domains.size - this.state.numDomainsInOtherSets)) {
        warningMessage = 'Adding more properties will take you beyond the PL Usage for this period. If you do so, the property set will be saved as Inactive.'
      } else if (this.state.domainNumAllowanceForThisSet < (this.state.domainSet.domains.size - this.state.numDomainsInOtherSets)) {
        if (this.state.domainNumAllowanceForThisSet < (this.props.domainSet.domains.size - this.state.numDomainsInOtherSets)) {
          redMessage = 'You have exhausted the PL Usage for this period. This property set will be saved in the Inactive state.';
        } else {
          redMessage = `The number of properties added is over your PL allowance. Remove ${this.state.domainSet.domains.size - this.state.numDomainsInOtherSets - this.state.domainNumAllowanceForThisSet} properties to continue.`;
        }
        warningMessage2 = <span>You cannot activate this property set since the number of properties is greater than your PL allowance for this period. If you need to increase your PL allowance get in touch with <span className='account-manager-link' onClick={ () => window.open("https://docs.sourcepoint.com/hc/en-us/requests/new") } >Account Manager</span></span>;
      } else if (this.state.domainNumAllowanceForThisSet > (this.state.domainSet.domains.size - this.state.numDomainsInOtherSets)) {
        greenMessage = `You can add up to ${this.state.domainNumAllowanceForThisSet - (this.state.domainSet.domains.size - this.state.numDomainsInOtherSets)} properties before you cross PL Usage for the current period.`;
      }
    }

    let deactivatingInMessage;
    let activeInfoMessage = "Active property sets will be scanned for the current period";
    if(domainSet.domainSetStatus === 'DisabledPending') {
      let deactivateDays = moment(domainSet.deactivateDate).diff(moment(),'days') + 1;
      deactivatingInMessage = <span> Deactivate in <strong>{deactivateDays} Days</strong></span>
    } else if(domainSet.domainSetStatus === 'Disabled') {
      activeInfoMessage = "Inactive property sets will not be scanned for the current period. It can be activated later as well"
    }
    if(this.state.duplicateDomains.length > 0) {
      duplicateDomainsMessage = <>Properties {this.state.duplicateDomains.map((domain,i,arr) =>(<strong>{domain}{arr.length === 1 || i === arr.length -1 ? " " : ", "} </strong>))}already exist</>
    }
    if(this.state.invalidDomains.length > 0) {
      invalidDomainsMessage = <><strong>{this.state.invalidDomains.length} domain(s)</strong> were not uploaded due to incorrect format. {this.renderDownloadBadDomainsLink()}</>
    }

    const isInactiveEditMode = (this.props.domainSet.domainSetStatus === 'Disabled' && !this.props.new && !this.props.activate);

    const validationErrorAlert = validationErrors.domainName || validationErrors.standard || validationErrors.region || validationErrors.addDomains ? (
      <Alert
        className='validation-errors'
        type='error'
        message="Pleae fill or select the following items"
        description={<ul>{Object.values(validationErrors).map((err)=>(err && <li>{err}</li>))}</ul>}
        icon={<WarningFilled/>}
        showIcon
      />
    ) : null;

    return (
      <Modal
        className='new-domain-set new-standard'
        closable
        onCancel={this.handleCancelModalChanges}
        visible={this.props.visible}
        footer={
          [
            <Button className="cancel" onClick={this.handleCancelModalChanges}>Cancel</Button>,
            (this.props.readOnly) ? null : <Button onClick={this.handleApply} type="primary" disabled={ this.props.readOnly || this.state.loading}>{this.props.activate ? 'Activate' : 'Save'}</Button>,
          ]
        }
      >
        <p className='domain-set-title'>{this.props.readOnly ? 'View Property Set' : this.props.domainSet.id ? this.props.activate ? 'Property Set - Activation' : 'Edit Property Set' : 'Add New Property Set'}</p>
        <div className='modal-container'>
          <div className='domain-name-modal'>Name</div>
          <Input
            disabled={this.props.readOnly || this.state.loading}
            value={domainSet.name}
            onChange={this.onDomainNameChange}
            className="domain-name"
            error={this.state.validationErrors.domainName}
          />

          <div className='add-property-set-container'>
            <div className="header-property-set" onClick={() => this.setState({ standardImpactCollapse: !this.state.standardImpactCollapse })}>< img src={require('../../../assets/images/privacy-lens/arrow-down.svg')}
              alt="expand or collapse"
              className="arrow-domain-set"
              style={{ transform: this.state.standardImpactCollapse ? 'rotate(0deg) scale(0.9)' : 'rotate(180deg) scale(0.9)' }}
            />Standard Impact on Available Properties</div>
            {!this.state.standardImpactCollapse &&
              <div className="body-property-set">
                <div className="action-title">Select the standard that applies to this property set</div>
                <Select
                  value={this.state.domainSet.standards.map(s => s.id).toJS()}
                  onChange={this.selectStandards}
                  tokenSeparators={[',']}
                  disabled={this.props.readOnly || this.state.loading}
                  error={this.state.validationErrors.standard}
                  showSearch
                  filterOption={(input, option)=>option.children.toLowerCase().includes(input.toLowerCase())}
                  menuItemSelectedIcon={<CheckOutlined />}
                >
                  {this.props.standards.filter(s => s.isActive).map((s, i) => (
                    <Select.Option key={i + '-'+s.id} value={s.id}>{s.name}</Select.Option>
                  ))}
                </Select>
                {this.state.domainSet.standards.size > 0 && this.state.domainSet.standards.toJS()[0].rules &&
                  <div className="rule-details">
                    <span className="view-rules-details" onClick={() => this.showRuleDetails()}>{this.state.hideRuleDetails ? 'View Rule Details' : 'Hide Rule Details'}</span>
                    {!this.state.hideRuleDetails && (<div className="wrap-rules"><div className="arrow-rules"></div>
                      <div className="arrow-cover-rules"></div>
                      <div className="box-rules"><Table
                        className='rule-details-table'
                        bordered={true}
                        dataSource={tableRuleDetailsData.get('dataSource').toJS()}
                        columns={tableRuleDetailsData.get('columns').toJS()}
                        pagination={false}
                      />
                      </div>
                    </div>)}
                  </div>
                }

                <div className="action-title">The properties in this property set will be scanned using IPs for the below region</div>
                <Select
                  disabled={ !this.props.new }
                  value={this.state.domainSet.region}
                  onChange={this.selectRegion}
                  tokenSeparators={[',']}
                  error={this.state.validationErrors.region}
                  showSearch
                  optionFilterProp="label"
                  options={this.state.regions.map((r,i) => ({label: regionsMap[r], value: r}))}
                  menuItemSelectedIcon={<CheckOutlined />}
                />
            </div>}
          </div>

          {
            this.props.activate  ? null : (
              <div className='add-property-set-container' >
                <div className="header-property-set" onClick={() => this.setState({ addPropertiesCollapse: !this.state.addPropertiesCollapse })}><img src={require('../../../assets/images/privacy-lens/arrow-down.svg')}
                  alt="expand or collapse"
                  className="arrow-domain-set"
                  style={{ transform: this.state.addPropertiesCollapse ? 'rotate(0deg) scale(0.9)' : 'rotate(180deg) scale(0.9)' }}
                />{this.props.readOnly ? 'View Properties' : 'Add Properties'}</div>
                {!this.state.addPropertiesCollapse && <div className="body-property-set">
                {
                  (this.props.readOnly) ? null : (<div>
                    
                    <div className="action-title">Import CSV file or manually add the list of properties that you'd like to measure.</div>
                    
                    { warningMessage && !isInactiveEditMode ? <Alert message={warningMessage} type="warning" showIcon /> : null }
                    { greenMessage ? <Alert message={greenMessage} type="success" showIcon /> : null }
                    { redMessage && !isInactiveEditMode ? <Alert message={redMessage} type="error" showIcon /> : null }
      
                    <div
                      disabled={this.state.loading}
                      onClick={() => this.setState({ addDomainManually: false })}
                      style={{ borderBottom: this.state.addDomainManually ? '2px solid #D4D4D4' : '2px solid #6565A9' }}
                      className="add-properties"
                    >
                      <img src={require('../../../assets/images/privacy-lens/download.svg')}
                        alt="Import CSV file"
                        className="cvs-icons"
                      />Import File
                    </div>
                    <div
                      disabled={this.state.loading}
                      onClick={this.state.loading ? () => {} : () => this.setState({ addDomainManually: true })}
                      className="add-properties"
                      style={{ borderBottom: this.state.addDomainManually ? '2px solid #6565A9' : '2px solid #D4D4D4' }}
                    >
                      <img src={require('../../../assets/images/privacy-lens/plus.svg')}
                        alt="Add CSV file manually"
                        className="cvs-icons"
                      />Add Manually
                    </div>
                    {addDomainsContent}
                  </div>)}
                    
                  <div>
    
                    <div className='search-container'>
                      <Input
                        disabled={this.state.loading}
                        placeholder="Search Property"
                        onChange={this.updateSearch}
                        style={{ width: '300px' }}
                        suffix={<SearchOutlined />}
                      />
                    </div>
                   { (this.state.duplicateDomains.length > 0) && <Alert message={duplicateDomainsMessage} type="error" showIcon className='info-message'/>}
                   { (this.state.invalidDomains.length > 0) && <Alert message={invalidDomainsMessage} type="error" showIcon className='info-message'/>}
                    <Table
                      rowSelection={{
                        type: 'checkbox',
                        ...{
                          onChange: (selectedRowKeys, selectedRows) => {
                            this.setState({ propertiesToDelete: selectedRowKeys })
                          }
                        }
                      }}
                      pagination={{
                        position: ['bottomCenter'],
                        showTotal: () => `Total Properties: ${domainSet.domains.size}`,
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30'],
                        locale: { items_per_page: ' Records per page' },
                      }}
                      className='domain-sets-table'
                      bordered={false}
                      dataSource={tablePropertyData.get('dataSource').toJS()}
                      columns={tablePropertyData.get('columns').toJS()}
                      footer={ () => (this.state.validationErrors.addDomains && <div className='domains-validation-error'>{this.state.validationErrors.addDomains}</div>)}
                    />
                  </div>
                </div>}
              </div>
            )
          }
          
        { 
          (this.props.domainSet.domainSetStatus !== 'Disabled' || this.props.activate) &&
          <div className='add-property-set-container' >
            <div className="header-property-set" onClick={() => this.setState({ activationCollapse: !this.state.activationCollapse })}><img src={require('../../../assets/images/privacy-lens/arrow-down.svg')}
              alt="expand or collapse"
              className="arrow-domain-set"
              style={{ transform: this.state.activationCollapse ? 'rotate(0deg) scale(0.9)' : 'rotate(180deg) scale(0.9)' }}
            />Activation</div>
            {!this.state.activationCollapse && <div className="body-property-set">
              
              {
                (this.props.activate || !this.props.new) ? null : (
                  <div>
                    <div className="action-title">Select the state of Property set upon saving.</div>
                    <div className='buttons flex-row'>
                      <Button
                        className={(domainSet.domainSetStatus !== 'Disabled') ? 'selected' : ''}
                        onClick={this.activate}
                        disabled={this.state.scanAllowance !== -1 && this.state.domainNumAllowanceForThisSet < this.state.domainSet.domains.size}
                      >
                        Active
                      </Button>
                      <Button
                        className={domainSet.domainSetStatus === 'Disabled' ? 'selected' : ''}
                        onClick={this.deactivate}
                      >
                        Inactive
                      </Button>
                    </div>
                    {
                      this.state.scanAllowance === -1 ? null :
                        <div>
                          <Alert message={activeInfoMessage} type="info" showIcon className='info-message'/>
                        </div>
                    }
                  </div>
                )
              }

              { domainSet.domainSetStatus !== 'Disabled' &&
                <>
                <div className="action-title">Do you want to repeat scan for this property set for upcoming periods?</div>
                <Select
                  defaultValue={(this.props.domainSet.domainSetStatus !== 'Disabled' || this.props.activate )&& (this.props.domainSet.domainSetStatus === 'DisabledPending' ? 'doNotRepeatScan' : 'repeatScan' )}
                  onChange={this.setDeactivateDate}
                  disabled={domainSet.domainSetStatus === 'Disabled' || this.props.readOnly || this.state.loading}
                >
                  <Select.Option key='repeatScan' value='repeatScan'>{'Yes, repeat for all upcoming period'}</Select.Option>
                  <Select.Option key='doNotRepeatScan' value='doNotRepeatScan'>{'No, deactivate property set after this period.'}</Select.Option>
                </Select>
                {deactivatingInMessage}
                </>
              }

              { warningMessage2 ? <Alert message={warningMessage2} type="warning" showIcon className='info-message'/> : null }
              
              { this.state.scanAllowance !== -1 && domainSet.domainSetStatus === 'DisabledPending' && <Alert message={warningMessage3} type="warning" showIcon className='info-message'/> }
            </div>}
          </div>
        }
        {validationErrorAlert}
        </div>
        { this.state.loading ? <Loading /> : null }
      </Modal>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    pendingRequestsMap: new Map({
      domainSets: store.domainSetState.getIn(['domainSets', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
    getStandards,
},
)(NewDomainSetModal);
