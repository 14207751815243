import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Map, List } from 'immutable';
import PerformanceHeader from './PerformanceHeader';
import GoldenScoreTabs from './GoldenScoreTabs';
import { Table, Input } from 'antd';
import AcronysTooltip from './AcronymsTooltip';

const Search = Input.Search
export default function GoldenSetComparison(props) {

  const goldenSet = useSelector(state => state.goldenSetState.getIn(['goldenSets', 'value'])).find(gs => gs.id === props.location.query.id);

  const [search, setSearch] = useState('');

  const generateTable = () => {

    let columns = List([
      Map({
        title: 'Properties',
        dataIndex: 'properties',
        key: 'properties',
        width: '150px',
        fixed: 'left',
      }),
      Map({
        title: 'Scan Scored',
        dataIndex: 'scanScored',
        key: 'scanScored',
        width: '90px',
        fixed: 'left',
      }),
      Map({
        title: 'Golden Scored',
        dataIndex: 'goldenScored',
        key: 'goldenScored',
        width: '90px',
        fixed: 'left',
      }),
    ]);

    columns = columns.concat(goldenSet.rules.map(r => {
      return Map({
        title: r.name,
        dataIndex: r.ruleId,
        key: r.ruleId,
        className: classNames('rule-column', {'more-than-four': goldenSet.rules.size > 4 } ),
      });
    }));


    let properties = goldenSet.properties;
    if (search) {
      properties = properties.filter(p => p.url.toLowerCase().includes(search.toLowerCase()));
    }

    const dataSource = properties.map((p) => {

      let row = Map({
        key: p.id,
        properties: p.url,
      });
      
      let icon;
      let iconText;
      let iconClass;

      let scanScored = 0;
      let goldenScored = 0;

      goldenSet.rules.forEach(r => {  
        const goldenScore = p.manualScore.get('scores').get(r.ruleId);
        let scannedScore = false;
        if (p.scores) {
          scannedScore = p.scores.get(r.ruleId);

          if (typeof goldenScore !== 'undefined') goldenScored++;
          if (typeof scannedScore !== 'undefined') scanScored++;

          if (goldenScore != null && scannedScore != null) {
            if (goldenScore === scannedScore) {
              iconClass='icon green';
              if (goldenScore === 1) {
                iconText = 'TP';
              } else if (goldenScore === 0) {
                iconText = 'TN';
              } else if (goldenScore === -1) {
                iconText = 'TU';
              }
            } else {
              iconClass='icon red';
              if (scannedScore === 1) {
                iconText = 'FP';
              } else if (scannedScore === 0) {
                iconText = 'FN';
              } else if (scannedScore === -1) {
                iconText = 'FU';
              }
            }
          } else {
            iconClass = '';
            iconText = '-';
          }

          icon = <div className={ iconClass }>{ iconText }</div>
          row = row.set(r.ruleId, icon);
        } else {
          row = row.set(r.ruleId, "NOT SCANNED");
        }
      });

      row = row.set('scanScored', `${scanScored}/${goldenSet.rules.size}`);
      row = row.set('goldenScored', `${goldenScored}/${goldenSet.rules.size}`);
           
      return row;
    });
  
    return new Map({ dataSource, columns });
  };

  let content;
  if (goldenSet) {
    const tableData = generateTable();
    content = (
      <div>
        <div className='table-box-shadow'>
          <div className='search-container'>
            <Search
              placeholder="Search Properties"
              onChange={ (e) => { setSearch(e.target.value)} }
              className="vendor-filter"
              style={{ width: 190 }}
            />
          </div>

          <Table
            bordered={ false }
            dataSource={ tableData.get('dataSource').toJS() }
            columns={ tableData.get('columns').toJS() }
            scroll={{ x: 1000 }}
            className='golden-set-comparison'
          />
        </div>
        <AcronysTooltip color />
      </div>
    );
  }

  return (
    <div className='domain-sets-container privacy-lens golden-set'>
      <GoldenScoreTabs />
      <PerformanceHeader goldenSetId={ goldenSet ? goldenSet.id : props.location.query.id } />
      { content }
    </div>
  );
}