import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import CSVLink from "../../../common/CSVLink.js";
import { Input, Select, Button, Progress, Table } from 'antd';
import {
  getDomainSets,
} from '../../../../actions/domain_set_actions';
import { getDomainsWithManualScores, getAccuracyEstimation, getComposition } from '../../../../api/consent_quality/domain_set';
import Loading from '../../../common/Loading.js.jsx';
import { getRules } from '../../../../actions/standard_actions';
import { getAccountOwners } from '../../../../actions/account_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { accountHasPrivacyLens } from '../../../../util';

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const regionsMap = {
  us_ny: 'US New York', 
  us_ca: 'US California',
  us_co: 'US Colorado',
  gb: 'UK',
  fr: 'France',
  de: 'Germany',
  it: 'Italy',
  es: 'Spain',
  dk: 'Denmark',
};

const languagesAndCMPMap = {
  EN: 'English',
  DE: 'German',
  FR: 'French',
  SP: 'Spanish', 
  IT: 'Italian', 
  DU: 'Dutch', 
  SW: 'Sweedish',
  noLang: 'no Language',
  iab: 'IAB CMP',
  nonIab: 'Non IAB Consent Notice',
};

const Search = Input.Search;

class PropertySetEvaluation extends React.Component {
  static propTypes = {
    getAccountOwners: PropTypes.func.isRequired,
    goToManualScoring: PropTypes.func.isRequired,
  }

  state = {
    selectedAccount: null,
    selectedPropertySet: null,
    propertySetAccuracy: null,
    propertySetComposition: null,
    domains: [],
    accuracyMap: {},
    everageManualAccuracy: {},
  }

  onCurrentUserAvailableDo = async () => {
    if (this.props.currentUser.accountId === 22) {
      this.props.getAccountOwners();
      this.props.getRules();
    }
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  updateSearch = (e) => this.setState({ search: e.target.value, currentPage: 1 });

  handlePageClick = (data) => {
    this.setState({ currentPage: data.selected + 1 })
  }

  handleSelectAccount = (accountId) => {
    this.props.getDomainSets(accountId);
    this.setState({ selectedAccountId: accountId });
  }

  handleSelectPropertySet = (ps) => {
    this.setState({ selectedPropertySet: ps });
    getDomainsWithManualScores(JSON.parse(ps)._id).then(domains => {
      this.setState({ domains });
      const accuracyMap = this.generateAccuracyMap(domains);
      if (Object.keys(accuracyMap).length) {
        const everageManualAccuracy = this.generateEverageManualAccuracy(accuracyMap);
        this.setState({ accuracyMap, everageManualAccuracy });
      } else {
        this.setState({ accuracyMap: {}, everageManualAccuracy: {} });
      }
    });
  }

  generateAccuracyMap = (domains) => {
    const propertySet = JSON.parse(this.state.selectedPropertySet);
    const ruleIds = propertySet.standard.rules.map(r => r.rule.ruleId);
    const regions = propertySet.standard.regions;
    const accuracyMap = {};
    ruleIds.forEach(ruleId => {
      let numCompared = 0;
      let numAccurate = 0;
      domains.forEach(domain => {
        if (domain.manualRegionBasedScores && domain.regionBasedScores) {
          regions.forEach(region => {
            if (domain.regionBasedScores[region] && domain.manualRegionBasedScores[region]) {
              const scannedScore = domain.regionBasedScores[region][ruleId];
              const manualScore = domain.manualRegionBasedScores[region][ruleId];
              if (scannedScore !== null && scannedScore !== undefined && manualScore !== null && manualScore !== undefined) {
                if (scannedScore === manualScore) {
                  numAccurate += 1;
                }
                numCompared += 1;
              }
            }
          });
        }
      });
      if (numCompared) {
        accuracyMap[ruleId] = numAccurate / numCompared;
      }
    });
    return accuracyMap;
  }

  generateEverageManualAccuracy = (accuracyMap) => {
    const everageManualAccuracy = {
      GA: null,
      Beta: null,
      Alpha: null,
    };
    const numRules = {
      GA: 0,
      Beta: 0,
      Alpha: 0,
    };

    for (const ruleId in accuracyMap) {
      const productStatus = this.props.rules.find(r => r.ruleId === ruleId).productStatus;
      everageManualAccuracy[productStatus] = (everageManualAccuracy[productStatus] || 0) + accuracyMap[ruleId];
      numRules[productStatus] = numRules[productStatus] + 1;
    }

    const availableProdStatuses = [];
    for (const productStatus in everageManualAccuracy) {
      if (numRules[productStatus]) {
        availableProdStatuses.push(productStatus);
        everageManualAccuracy[productStatus] = ((everageManualAccuracy[productStatus] / numRules[productStatus]) * 100).toFixed(0);
      }
    }

    if (availableProdStatuses.length > 1) {
      availableProdStatuses.forEach(productStatus => {
        everageManualAccuracy.across = (everageManualAccuracy.across || 0) + parseInt(everageManualAccuracy[productStatus]);
      });
      everageManualAccuracy.across = (everageManualAccuracy.across / availableProdStatuses.length).toFixed(0);
    }
    return everageManualAccuracy;
  }

  fetchPropertySetAccuracyAndComposition = async () => {
    const setId = JSON.parse(this.state.selectedPropertySet)._id;
    const propertySetAccuracy = await getAccuracyEstimation(setId, this.state.selectedAccountId);
    const propertySetComposition = await getComposition(setId, this.state.selectedAccountId);
  
    this.setState({ propertySetComposition, propertySetAccuracy });
  }

  renderPieChart = (type) => {
    let data;
    if (type === 'cmp') {
      data = this.state.propertySetComposition.cmpTypeSplit.map(obj => {
        return { name: obj.name, value: obj.percent };
      });
    } else {
      data = this.state.propertySetComposition.consentLanguageSplit.map(obj => {
        return { name: obj.language, value: obj.percent };
      });
    }
    
    const COLORS = ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#0099C6', '#0099C6', '#BFC4CA'];
    
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    return (
        <div className='flex-row pie-container'>
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
          <ul className='labels'>
            {
              data.map(({name, value}, idx) => {
                return (
                  <li style={{ color: COLORS[idx] }}>
                    <span>{ `${languagesAndCMPMap[name]}  ${(value * 100).toFixed(0)}%`}</span>
                  </li>
                );
              })
            }
          </ul>
        </div>
    ) 
  }

  generatePieChart = (type) => {
    let data;
    if (type === 'cmp') {
      data = this.state.propertySetComposition.cmpTypeSplit.map(obj => {
        return { type: obj.name, value: obj.percent };
      });
    } else {
      data = this.state.propertySetComposition.consentLanguageSplit.map(obj => {
        return { type: obj.language, value: obj.percent };
      });
    }

    const config = {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 0.5,
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [
        {
          type: 'element-active',
        },
      ],
    };
    return config;
  }

  generateRuleTable = () => {
    const columns = [
      {
        title: 'Rule ID',
        dataIndex: 'ruleId',
        key: 'ruleId',
      },
      {
        title: 'Rule Name',
        dataIndex: 'ruleName',
        key: 'ruleName',
      },      
      {
        title: 'Property Composition Based',
        dataIndex: 'compositionBased',
        key: 'compositionBased',
      },
      {
        title: 'Manual Scores Based',
        dataIndex: 'sampleBased',
        key: 'sampleBased',
      },
    ];
    
    const dataSource = this.state.propertySetAccuracy.map(obj => {
      const rule = this.props.rules.find(r => r.ruleId === obj.ruleId);
      return {
        key: Math.random().toString(),
        ruleId: obj.ruleId,
        ruleName: rule ? rule.name : 'mock rule name',
        compositionBased: obj.estimatedAccuracy ? `${parseInt(obj.estimatedAccuracy * 100).toFixed(0)}%` : 'N/A',
        sampleBased: this.state.accuracyMap[obj.ruleId] !== null ? `${(this.state.accuracyMap[obj.ruleId] * 100).toFixed(0)}%` : 'N/A',
      };
    });

    return { columns, dataSource };
  }

  getEverageAccuracy = (productStatus) => {
    let rules = this.state.propertySetAccuracy;
    if (productStatus) {
      rules = rules.filter(rule => (this.props.rules.find(r => r.ruleId === rule.ruleId).productStatus) === productStatus);
    }
    return parseInt((rules.map(r => r.estimatedAccuracy).reduce((a,b) => a + b, 0) / rules.length) * 100);
  }

  renderEverageAccuracy = (type) => {
    let everageAccuracyAll = 50;
    let everageAccuracyGA = 0;
    let everageAccuracyBeta = 0;
    let everageAccuracyAlpha = 0;
    if (type === 'composition') {
      if (this.state.propertySetAccuracy) {  
        everageAccuracyAll = this.getEverageAccuracy();
        everageAccuracyGA = this.getEverageAccuracy('GA');
        everageAccuracyBeta = this.getEverageAccuracy('Beta');
        everageAccuracyAlpha = this.getEverageAccuracy('Alpha');
      }
    } else {
      everageAccuracyAll = this.state.everageManualAccuracy.across;
      everageAccuracyGA = this.state.everageManualAccuracy.GA;
      everageAccuracyBeta = this.state.everageManualAccuracy.Beta;
      everageAccuracyAlpha = this.state.everageManualAccuracy.Alpha;
    }
    return (
      <div>
        { everageAccuracyAll ? 
          <div className="summary-score">
            <div className="score-title">
              <div>Average Across Measured Rules</div>
              <div>{everageAccuracyAll}%</div>
            </div>
            <div className="progress blue"><div className="amount" style={{width: (everageAccuracyAll || 0) + '%'}}></div></div>
          </div> :
          null
        }

        <div className='by-status flex-row'>

        { everageAccuracyGA ?
          <div className="summary-score small">
            <div className="score-title small">
              <div>GA Rules</div>
              <div>{everageAccuracyGA}%</div>
            </div>
            <div className="progress green small"><div className="amount" style={{width: (everageAccuracyGA || 0) + '%'}}></div></div>
          </div> :
          null
        }

        { everageAccuracyBeta ?
          <div className="summary-score small">
            <div className="score-title small">
              <div>Beta Rules</div>
              <div>{everageAccuracyBeta}%</div>
            </div>
            <div className="progress turquoise small"><div className="amount" style={{width: (everageAccuracyBeta || 0) + '%'}}></div></div>
          </div> :
          null
        }

        { everageAccuracyAlpha ?
          <div className="summary-score small">
            <div className="score-title small">
              <div>Alpha Rules</div>
              <div>{everageAccuracyAlpha}%</div>
            </div>
            <div className="progress red small"><div className="amount" style={{width: (everageAccuracyAlpha || 0) + '%'}}></div></div>
          </div> :
          null
        }

        </div>
      </div>
    );
  }

  generateDataForCsvReport = (tableData) => {
    const columns = [
      'Rule ID',
      'Rule Name',
      'Property Composition Based',
      'Manual Scores Based',
    ];

    const dataSource = tableData.dataSource;

    const data = dataSource.map(d => {
      return [ 
        d.ruleId,
        d.ruleName,
        d.compositionBased, 
        d.sampleBased, 
      ];
    });

    return [columns, ...data];
  };

  render() {
    let tableData;
    let downloadReport;
    if (this.state.propertySetAccuracy && this.props.rules.size) {
      tableData = this.generateRuleTable();
      downloadReport = (
        <CSVLink
          data={ this.generateDataForCsvReport(tableData) }
          filename='rule_optimization.csv'
          className='export-csv'
          target=""
        >
          <span className='download'>
            <FontAwesomeIcon icon={faDownload} />
          </span>
        </CSVLink>
      );
    }

    return (
      <div>
        <div className='selects-container'>
          <div className='flex-row'>
            <Select
              value={ this.state.selectedAccountId }
              showSearch
              onChange={ this.handleSelectAccount }
              placeholder='Select Account'
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                this.props.accountOwners.filter(a => accountHasPrivacyLens(a.accountFeatures)).map((a) => {
                  return <Select.Option key={ a.accountId } value={ a.accountId } >{ a.accountName }</Select.Option>
                })
              }
            </Select>
          
            <Select
              value={ this.state.selectedPropertySet }
              onChange={ this.handleSelectPropertySet }
              placeholder='Select Property Set'
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                this.props.domainSets.map((d) => {
                  return <Select.Option key={ d._id } value={ JSON.stringify(d) }>{ d.name }</Select.Option>
                })
              }
            </Select>
            <Button onClick={ this.fetchPropertySetAccuracyAndComposition } >APPLY</Button>
          </div>
          <div>
            <p><span>Note:</span> Select account and property set to evaluate the overall accuracy and the accuracy per rule.</p>
          </div>
        </div>

        { this.state.propertySetAccuracy && this.state.propertySetComposition && this.props.rules.size ? 

          <div className='propertySetData'>
            <div className="summary-container">
            <div className="subtitle-privacy-lens"><span>Summary</span> - Accuracy Estimate</div>
            <div className='estimates-container summary-card flex-row'>

              <div className='composition'>
                <p>Estimate based on set composition</p>

                { this.renderEverageAccuracy('composition') }

              </div>

              <div className='manual'>
                <p>Estimate based on Manual Scoring</p>

                { Object.keys(this.state.everageManualAccuracy).length ?
                  this.renderEverageAccuracy('manual') :

                  <div className='pink-container'>
                    <p>There are no properties scored for this set.</p>
                    <p>Acess property sets for <span onClick={ this.props.goToManualScoring }>Manual Scoring</span></p>
                    <p>Score a sample of the properties in the property set in the “Accuracy Set Management” tab</p>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className='compostion-container summary-card'>
            <div className="subtitle-privacy-lens composition">Property Set Composition</div>

            <div className='flex-row data-container'>
              <div className='left'>
                <div className='flex-row'>
                  <div className='val-container'>
                    <div>Number of Properties</div>
                    <div className='value'>{JSON.parse(this.state.selectedPropertySet).numProperties}</div>
                  </div>
                </div>

                <div className='flex-row'>
                  <div className='val-container'>
                    <div>Bot Detection</div>
                    <div className='value'>{this.state.propertySetComposition.percentBotDetection}</div>
                  </div>

                  <div className='val-container'>
                    <div>Regions</div>
                    <div className='value'>{JSON.parse(this.state.selectedPropertySet).standard.regions.map(r => regionsMap[r]).join(', ')}</div>
                  </div>
                </div>
              </div>

              <div className='middle'>
                <div className='title-pie'>Consent Language Split</div>
                { this.renderPieChart('language') }
              </div>

              <div className='right'>
              <div className='title-pie'>CMP Type</div>
                { this.renderPieChart('cmp') }
              </div>
            </div>
          </div>

          <div className='detailed-view-container'>
            <div className="subtitle-privacy-lens"><span>Detailed View</span> - Accuracy Estimate</div>
            <div className='summary-card'>
              <div className='downpload-container search-container p-l-admin'>
              { downloadReport }
              </div>
              <Table
                dataSource={ tableData.dataSource }
                columns={ tableData.columns }
                bordered
              />
            </div>
          </div>
        </div> 

        : this.state.selectedPropertySet ? <Loading /> : null }
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    accountOwners: store.accountState.getIn(['accountOwners', 'value']),
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    rules: store.standardState.getIn(['rules', 'value']),
    pendingRequestsMap: new Map({
      domainSets: store.domainSetState.getIn(['domainSets', 'pending']),
      accountOwnersPending: store.accountState.getIn(['accountOwners', 'pending']),
    }),
  };
};

export default connect(
  mapStateToProps, {
  getAccountOwners,
  getDomainSets,
  getRules,
},
)(PropertySetEvaluation);
