import * as types from '../actions/action_types';

import { Map, OrderedSet } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
    partitionsets: new StoreRecord({ value: OrderedSet([]) }),
    importPending: false,
});

export default (state = initialState, action) => {
    const partitionsets = state.getIn(['partitionsets', 'value']);
    let updatedpartitionsets;
    switch(action.type) {

        case types.PARTITIONSETS_PENDING:
            return setPending(state, ['partitionsets']);

        case types.PARTITIONSETS_CACHED:
            return stopPending(state, ['partitionsets']);

        case types.PARTITIONSETS_LOADED:
            return setValue(state, ['partitionsets'], partitionsets.concat(action.partitionsets).sortBy(p => p.description.toLowerCase()));

        case types.PARTITIONSETS_ERROR:
            return setError(state, ['partitionsets'], action.errorMessage);

        case types.CREATE_PARTITIONSET_LOADED:
            if (OrderedSet.isOrderedSet(action.partitionset)) {
              updatedpartitionsets = partitionsets.merge(action.partitionset);
            } else {
              updatedpartitionsets = partitionsets.add(action.partitionset);
            }
            return setValue(state, ['partitionsets'], updatedpartitionsets.sortBy(partitionset => partitionset.get('description').toLowerCase())).set('importPending', false);

        case types.UPDATE_PARTITIONSET_LOADED:
            if (OrderedSet.isOrderedSet(action.partitionset)) {
              updatedpartitionsets = partitionsets.filterNot(partitionset => action.partitionset.map(p => p.id).includes(partitionset.id)).merge(action.partitionset);
            } else {
              updatedpartitionsets = partitionsets.filterNot(s => s.id === action.partitionset.id).add(action.partitionset);
            }
            return setValue(state, ['partitionsets'], updatedpartitionsets.sortBy(partitionset => partitionset.get('description').toLowerCase()));

        case types.PARTITIONSET_DELETED:
            const withoutDeleted = partitionsets.filterNot(p => action.ids.includes(p.get('id')));
            return setValue(state, ['partitionsets'], withoutDeleted);

        case types.PARTITIONSET_IMPORT_PENDING:
            return state.set('importPending', true);

        case types.PARTITIONSET_IMPORTED:
            const withImported = partitionsets.add(action.partitionset).sortBy(partitionset => partitionset.get('description').toLowerCase());
            return setValue(state, ['partitionsets'], withImported).set('importPending', false);
    }

    return state;
};
