import React from 'react';
import { Modal, Tag, Button } from 'antd';

const CantDeleteModal = ({ siteGroupNames, closeModal }) => {
  return (
    <Modal
      title="Can't Delete"
      visible={siteGroupNames.length !== 0}
      onCancel={closeModal}
      footer={[(
        <Button type="primary" onClick={closeModal}>
          Close
        </Button>
      )]}
    >
      <div className="cant-delete-container-modal">
        <div>
          This property can't be deleted because it falls under the following property groups:
        </div>
        <div className="sg-names-container">
          {siteGroupNames.map((name) => <Tag>{name}</Tag>)}
        </div>
        <div>
          Please remove the site from all property groups it falls under before attemping to delete
        </div>
      </div>
    </Modal>
  );
};

export default CantDeleteModal;