import PropTypes from 'prop-types';
import React from 'react';

var validateColor = function (value) {
  //allow transparent
  if (value === 'transparent') return true;

  // if value doesn't start with #, it isn't valid
  if (!/^#/.test(value)) return false;

  var hexValue = value.substring(1);
  return (typeof hexValue === 'string') && hexValue.length === 6 && !isNaN(parseInt(hexValue, 16));
};

export default class ColorPicker extends React.Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  state = {
    error: false,
    value: this.props.value,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  handleChange = (event) => {
    const colorHex = event.target.value;
    if (!validateColor(colorHex)) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }

    this.setState({ value: colorHex });
    this.props.onUpdate(colorHex);
  };

  render() {
    const spanStyle = {
      background: this.state.value,
    };

    let parentClasses = 'options-color-picker';
    parentClasses += this.state.error ? ' options-error' : '';

    return (
      <div className={parentClasses}>
        <input type="text" value={this.state.value} onChange={this.handleChange} disabled={!this.props.editing} />
        <input type="color" value={this.state.value} onChange={this.handleChange} disabled={!this.props.editing} />
        <span style={spanStyle}></span>
      </div>
    );
  }
}
