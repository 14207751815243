import React, { Component } from 'react';
import { Popconfirm, Tooltip, Chip, Button, message } from '../../../../styleguide'
import { getTagData, trimTag } from '../helper';
import { styles } from './styles';
import { kvStringClass, urlMatchClass, ConsentGateCcpaClass, ConsentGateUsnatClass, consentGateGdprV2Class, consentGateClass } from './Data';
export default class Conditions extends Component {
  state = {
    delConditionVisible: false,
    delConditionId: '',
    delConditionClassName: '',
    showAllConditions: false,
    isConsentToAllPresent: false,
  };

  componentDidUpdate(prevProps){
    if(prevProps.step.rowData !== this.props.step.rowData) {
      const isAvailable = this.props.step.rowData.find(
        (r) => r.data.status === 3
      )  
      const campaignId = this.props.scenarioEdited.campaign_type_id 
      let isConsentPresent = false;
      this.props.step.rowData.forEach((r, idx) => {
        if(this.props.isTemplate === false && (campaignId === 1 && (idx === 0 && r.category !== 'condition'))) {
          isConsentPresent = true;
        } else if(this.props.isTemplate === false && campaignId === 0){
          const message = this.props.messages.find(m => (m.id === r.data.message_id && m.message_category_id === 1))
          if (message) {
            if((idx === 0 && r.category !== 'condition')) {
              isConsentPresent = true;
            } else if(r.category === 'condition'){
              isConsentPresent = ((r.className !== consentGateGdprV2Class || r.className !== consentGateClass) || r.className !== ConsentGateCcpaClass || r.className !== ConsentGateUsnatClass);
            }
          }
        }
      })
      if(isConsentPresent) {
        this.props.updateStepIndex(this.props.index);
      }
      if(isAvailable) {
        this.setState({isConsentToAllPresent: true});
        this.props.updateStepIndex(this.props.index);
      } 
    }
  }

  deleteConditionhandle = (e, index, delConditionClassName) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      delConditionVisible: !this.state.delConditionVisible,
      delConditionId: index,
      delConditionClassName: delConditionClassName,
    });
  };

  closeDelConditionPopup = () => {
    this.setState({ delConditionVisible: false });
  };

  confirmDeleteCondition = () => {
    let rowData;
    if (this.state.delConditionId >= 0) {
      rowData = this.props.step.rowData.delete(this.state.delConditionId);
    } else {
      rowData = this.props.step.rowData.filterNot(
        (r) => r.className == this.state.delConditionClassName
      );
    }
    this.setState({ delConditionId: '', delConditionVisible: false }, () => {
      this.props.onChange(this.props.index, rowData);
    });
  };
  toggleConditionChips = () => {
    this.setState((prevState) => ({
      showAllConditions: !prevState.showAllConditions,
    }));
  };
  render() {
    const filterConditions = this.props.step.rowData.filter(
      (r) => r.category == 'condition'
    );

    const kvConditions = this.props.step.rowData.filter(
      (r) => r.category == 'condition' && r.className == kvStringClass
    );

    const urlConditions = this.props.step.rowData.filter(
      (r) => r.category == 'condition' && r.className == urlMatchClass
    );

    const deletePopConfirm = (
      <Popconfirm
        title={`Condition will be deleted`}
        okText="YES"
        visible={this.state.delConditionVisible}
        cancelText="NO"
        onCancel={this.closeDelConditionPopup}
        onConfirm={this.confirmDeleteCondition}
      ></Popconfirm>
    );
    let showCount = 0;
    if (urlConditions.size > 0) {
      showCount++;
    }
    if (kvConditions.size > 0) {
      showCount++;
    }
    const flag = (this.props.stepIndex.length) ? this.props.stepIndex.includes(this.props.index) : false;
    let messageContent;
    if(this.state.isConsentToAllPresent && flag) {
      messageContent = (<span style={styles.errorConditionStyle}>The <strong>Consent to All</strong> option has been deprecated and will no longer be supported for the GDPR TCF consent gate. Choose a different <strong>Consent Gate</strong> option.</span>)
    } else if(!flag) {
      messageContent = null;
    } else if((filterConditions.size === 0 || filterConditions.size !== 0) && this.props.showErrorMessage) {
      messageContent = (<span style={styles.errorConditionStyle}>Please add a consent status step under conditions to save this scenario.</span>)
    } 
    return (
      <div className="step-conditions-wrapper">
        {deletePopConfirm}
        <span style={{ marginLeft: '5px' }} className="condition-title">
          CONDITION
        </span>
        {messageContent}
        <div style={styles.conditionDiv}>
          {urlConditions && urlConditions.size > 0 && (
            <Tooltip title={getTagData('urlMultiple', urlConditions)}>
              <Chip
                closable
                onClick={(e) => this.props.showConditionPanel(e, urlMatchClass, '', this.props.index)}
                onClose={(e) =>
                  this.deleteConditionhandle(e, -1, urlMatchClass)
                }
              >
                {trimTag(getTagData('urlMultiple', urlConditions))}
              </Chip>
            </Tooltip>
          )}
          {kvConditions && kvConditions.size > 0 && (
            <Tooltip title={getTagData('kvMultiple', kvConditions)}>
              <Chip
                closable
                onClick={(e) => this.props.showConditionPanel(e, kvStringClass, '', this.props.index)}
                onClose={(e) =>
                  this.deleteConditionhandle(e, -1, kvStringClass)
                }
              >
                {trimTag(getTagData('kvMultiple', kvConditions))}
              </Chip>
            </Tooltip>
          )}
          {filterConditions.size != 0 ? (
            filterConditions.map((item, index) => {
              if (
                item.className != kvStringClass &&
                item.className != urlMatchClass
              ) {
                showCount++;
                return (
                  <Tooltip
                    key={index}
                    title={getTagData(item.className, item.data)}
                  >
                    <Chip
                      closable
                      key={index}
                      onClick={(e) => {
                        this.props.showConditionPanel(
                          e,
                          item.className,
                          item.data.version,
                          this.props.index,
                          index
                        )}
                      }
                      onClose={(e) =>
                        this.deleteConditionhandle(e, index, item)
                      }
                      style={
                        !(this.state.showAllConditions || showCount <= 3) ? { display: 'none' } :
                        (item.data.status === 3 && {border:  '2px solid #FF2626', backgroundColor: '#FFD2D2'})
                      }
                    >
                      {trimTag(getTagData(item.className, item.data))}
                    </Chip>
                  </Tooltip>
                );
              }
              return null;
            })
          ) : 
          ((!flag || !this.props.showErrorMessage) && <span style={styles.emptyConditionStyle}>
               Add scenario conditions that applies to this step
           </span>)
          }
          {!this.state.showAllConditions && showCount > 3 && (
            <Button onClick={this.toggleConditionChips}>
              +{showCount - 3}
            </Button>
          )}
          {!filterConditions.size && <br/>}
          {this.props.showConditionDropdownState !== this.props.index && <Button ghost type='secondary' size='small' className="add-condition-btn" onClick={() => this.props.showConditionDropdown(this.props.index)}>Add Condition</Button>}
        </div>
      </div>
    );
  }
}
