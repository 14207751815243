import { Map, List } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending } from './helpers.js';
import { Converter } from '../records/altpay_records.js';


const initialState = Map({
    altpayData: new StoreRecord({ value: List([]) }),
    converter: new StoreRecord({ value: new Converter() }),
})

export default (state = initialState, action) => {
  switch(action.type) {
    case types.ALTPAY_PENDING:
      return setPending(state, ['altpayData']);

    case types.ALTPAY_LOADED:
      return setValue(state, ['altpayData'], action.data.sortBy(soldByDay => soldByDay.timestampSec));

    case types.ALTPAY_ERROR:
      return setError(state, ['altpayData'], action.errorMessage);

    case types.EXCHANGE_PENDING:
      return setPending(state, ['converter']);

    case types.EXCHANGE_LOADED:
      return setValue(state, ['converter'], action.data);

    case types.EXCHANGE_ERROR:
      return setError(state, ['converter'], action.errorMessage);
  }
  return state;
};
