import React, { useMemo, useState } from 'react';
import { Spin, Divider, Empty } from 'antd';
import { browserHistory } from 'react-router';
import VendorTraceChart from './VendorTraceChart';
import useSearchParams from '../../dashboard/shared/useSearchParams';
import DashboardSelectDropdown from '../../dashboard/shared/DashboardSelect';
import { tagRenderer } from '../vendor_trace_filters/VendorTraceFilters';
import { singleOptionRenderer } from '../vendor_trace_filters/SelectOptionRenderers';
import { useVendorTraceContext } from './ChartFiltersContext';

const VendorTraceOverview = ({
  isDataLoading,
  consentTypes,
  defaultConsentType,
  enableDisclosureFilter,
  onChangeConsentType,
}) => {
  const [highlightFilter, setHighlightFilter] = useState([]);
  const {
    vendorsNames,
    vendorsData: vendorTraces,
    vendorName: selectedVendorName,
    isBeforeActionConsentCategory,
    setVendorName,
    resetFilters,
  } = useVendorTraceContext();

  const filters = useMemo(() => {
    return [
      {
        title: 'Tracking methods detected',
        value: 'is_tracking_detected',
        htmlTitle:
          'This setting displays all the vendors that are storing identifiers via cookies, local storage or possibly fingerprinting vendors on the vendor trace.',
      },
      // disable for US
      ...(enableDisclosureFilter
        ? [
            {
              title: 'Vendor disclosure',
              value: 'is_disclosed',
              htmlTitle:
                'This setting overlays colour coding onto the vendor trace that displays for whether or not the vendors we have picked up are disclosed on your vendor list.',
            },
          ]
        : []),
    ];
  }, [enableDisclosureFilter]);
  const selectedConsentType = useMemo(() => {
    return consentTypes.find(({ value }) => defaultConsentType.includes(value));
  }, [consentTypes, defaultConsentType]);

  const searchParams = useSearchParams();

  const handleChangeConsentType = (key) => {
    if (key && key !== defaultConsentType) {
      if (Object.keys(searchParams).length) {
        browserHistory.replace(location.pathname);
      }
      resetFilters();
      setHighlightFilter([]);
      onChangeConsentType(key);
    }
  };
  /* selected metric */
  const onSelectMetric = (metric) => {
    if (Object.keys(searchParams).length) {
      browserHistory.replace(location.pathname);
    }
    setHighlightFilter(metric && !highlightFilter.includes(metric) ? [metric] : []);
  };
  /* select particular vendor to filter and launch filtering */
  const onSelectVendorName = (
    vendorName,
    isNewName = true /* select dropdown returns object same as true in that case */,
  ) => {
    const newVendorName = vendorName && selectedVendorName !== vendorName ? vendorName : null;
    if (isNewName) {
      setVendorName(newVendorName, isBeforeActionConsentCategory);
    }
    if (Object.keys(searchParams).length) {
      browserHistory.replace(location.pathname);
    }
  };

  return (
    <div className={'vendor_trace_container'} id={'wrapper'}>
      {consentTypes?.length ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 20px',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                columnGap: '12px',
              }}
            >
              <span>Consent Status:</span>
              <DashboardSelectDropdown
                showSearch={false}
                showAll={false}
                allowClear={false}
                options={consentTypes}
                selected={defaultConsentType ? [defaultConsentType] : []}
                onSelect={handleChangeConsentType}
                placeholder={'Consent Type'}
                tagRender={tagRenderer}
                singleOptionRender={singleOptionRenderer}
              />
            </div>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                columnGap: '20px',
              }}
            >
              <DashboardSelectDropdown
                showAll={false}
                options={vendorsNames}
                selected={selectedVendorName || []}
                onSelect={onSelectVendorName}
                onClear={onSelectVendorName}
                placeholder={'Search Vendor'}
                tagRender={tagRenderer}
                singleOptionRender={singleOptionRenderer}
              />
              <DashboardSelectDropdown
                showSearch={false}
                showAll={false}
                options={filters}
                selected={highlightFilter}
                onSelect={onSelectMetric}
                onClear={onSelectMetric}
                placeholder={'Select a filter'}
                tagRender={tagRenderer}
                singleOptionRender={singleOptionRenderer}
              />
            </div>
          </div>
          <Divider style={{ margin: '0', borderColor: '#d3d9dd' }} />
          <Spin spinning={isDataLoading} size="large" wrapperClassName={'vendor-trace-data-loading'}>
            <div style={{ padding: '20px' }}>
              {!isDataLoading && Object.keys(vendorTraces).length ? (
                <VendorTraceChart
                  consentTypeDescription={selectedConsentType?.htmlTitle}
                  highlightFilter={highlightFilter?.[0]}
                  selectedVendorName={selectedVendorName}
                />
              ) : null}
              {!isDataLoading && !Object.keys(vendorTraces).length ? (
                <Empty description={<span>No data for the selected consent status.</span>} />
              ) : null}
            </div>
          </Spin>
        </>
      ) : (
        <Empty description={<span>Select property to view data</span>} />
      )}
    </div>
  );
};

export default VendorTraceOverview;
