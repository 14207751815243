import React, { useMemo, useCallback } from 'react';
import { Table, Tooltip, Button, Tag } from 'antd';
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';




export const getPixelsColumns = ({ showParamterModal, showModal, handleHarFileDownload, vendorId }) => [
    {
        title: (
            <div className="vendor-details-table-header-title">
                Pixels Name
                <Tooltip title="Identifier for a tracking pixel that shows where the pixel is coming from(host) and the specific data it tracks">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'name',
        dataIndex: 'name',
        render: (text) => (
            <Tooltip
                placement="right"
                autoAdjustOverflow
                title={<div className="detailed-breakdown-cell-tooltip-content">{text}</div>}
                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
            >
                <div style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {text}
                </div>
            </Tooltip>
        ),
        width: 300,
    },
    {
        title: (
            <div className="vendor-details-table-header-title">
                Parameters Passed
                <Tooltip title="Shows the specific data sent with each pixel request for tracking or personalization purposes.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        render: (record) => (
            <div className="vendor-details-paramters-btn">
                <button className="anchor-button" onClick={() => showParamterModal({ record, vendorId })}>
                    View
                </button>
            </div>
        ),
    },
    {
        title: (
            <div className="vendor-details-table-header-title">
                Scan URL
                <Tooltip title="Breakdown of all URLs containing the pixel during scanning - it also includes the HAR file for tracing the pixel's origin and data flow.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'scan_urls',
        dataIndex: 'scan_urls',
        render: (record, row) => {
            const urlCount = record.length;
            const firstUrl = record[0];
            const remainingUrlCount = urlCount - 1;

            return (
                <div className="vendor-details-tooltip">
                    {remainingUrlCount > 0 ? (
                        <div className="vendor-details-scanurl">
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={<div className="detailed-breakdown-cell-tooltip-content">{firstUrl}</div>}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <div style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {firstUrl}
                                </div>
                            </Tooltip>
                            <Tag
                                className="primary"
                                onClick={() => showModal({ record, vendorId, type: 'pixelsScanUrl', techId: row.pixel_id })}
                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                            >
                                +{remainingUrlCount}
                            </Tag>
                        </div>
                    ) : (
                        <div className="vendor-details-scanurl">
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={<div className="detailed-breakdown-cell-tooltip-content">{firstUrl}</div>}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <div style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {firstUrl}
                                </div>
                            </Tooltip>
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={<div className="detailed-breakdown-cell-tooltip-content">Download HAR File</div>}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <Button
                                    type="text"
                                    size="large"
                                    onClick={() => handleHarFileDownload({ record, vendorId, techId: row.pixel_id })}
                                >
                                    <DownloadOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            );
        },
    },
];

export const getCookielesColumns = ({ showModal, handleHarFileDownload, vendorId }) => [
    {
        title: (
            <div className="vendor-details-table-header-title">
                Cookieles IDs Name
                <Tooltip title="The name of the cookieless ID.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'name',
        dataIndex: 'name',
        render: (text) => {
            return (
                <Tooltip
                    placement="right"
                    autoAdjustOverflow
                    title={() => {
                        return <div className="detailed-breakdown-cell-tooltip-content">{text}</div>;
                    }}
                    overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                >
                    <div
                        className=""
                        style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text}
                    </div>
                </Tooltip>
            );
        },
        width: 300,
    },
    {
        title: (
            <div className="vendor-details-table-header-title">
                Prevalence
                <Tooltip title="Shows how often we observed this cookieless ID while scanning.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'prevalence',
        dataIndex: 'prevalence',
        render: (record) => {
            return <span>{record}%</span>;
        },
    },
    {
        title: (
            <div className="vendor-details-table-header-title">
                Scan URL
                <Tooltip title="Breakdown of all URLs containing the cookieles ID during scanning - it also includes the HAR file for tracing the cookieless's origin and data flow.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'scan_urls',
        dataIndex: 'scan_urls',
        render: (record, row) => {
            const urlCount = record.length;
            const firstUrl = record[0];
            const remainingUrlCount = urlCount - 1;

            return (
                <div className="vendor-details-tooltip">
                    {remainingUrlCount > 0 ? (
                        <div className="vendor-details-scanurl">
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">{firstUrl}</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <div
                                    className=""
                                    style={{
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {firstUrl}
                                </div>
                            </Tooltip>

                            <Tag
                                className={'primary'}
                                onClick={() => showModal({ record, vendorId, type: 'cookielessScanUrl', techId: row.cookieless_id })}
                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                            >
                                +{remainingUrlCount}
                            </Tag>
                        </div>
                    ) : (
                        <div className="vendor-details-scanurl">
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">{firstUrl}</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <div
                                    className=""
                                    style={{
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {firstUrl}
                                </div>
                            </Tooltip>
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">Download Har File</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <Button
                                    type="text"
                                    size="large"
                                    onClick={() => handleHarFileDownload({ record, vendorId, techId: row.cookieless_id })}
                                >
                                    <DownloadOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            );
        },
    },
];

export const getChatbotColumn = ({ showModal, handleHarFileDownload, vendorId }) => [
    {
        title: (
            <div className="vendor-details-table-header-title">
                Chatbot Name
                <Tooltip title="The name of the chatbot">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'name',
        dataIndex: 'name',
        render: (text) => {
            return (
                <Tooltip
                    placement="right"
                    autoAdjustOverflow
                    title={() => {
                        return <div className="detailed-breakdown-cell-tooltip-content">{text}</div>;
                    }}
                    overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                >
                    <div
                        className=""
                        style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text}
                    </div>
                </Tooltip>
            );
        },
        width: 300,
    },
    {
        title: (
            <div className="vendor-details-table-header-title">
                Prevalence
                <Tooltip title="Shows how often we observed this chatbot while scanning.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'prevalence',
        dataIndex: 'prevalence',
        render: (record) => {
            return <span>{record}%</span>;
        },
    },
    {
        title: (
            <div className="vendor-details-table-header-title">
                Scan URL
                <Tooltip title="Breakdown of all URLs containing the chatbot during scanning - it also includes the HAR file for tracing the chatbot's origin and data flow.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'scan_urls',
        dataIndex: 'scan_urls',
        render: (record, row) => {
            const urlCount = record.length;
            const firstUrl = record[0];
            const remainingUrlCount = urlCount - 1;

            return (
                <div className="vendor-details-tooltip">
                    {remainingUrlCount > 0 ? (
                        <div className="vendor-details-scanurl">
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">{firstUrl}</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <div
                                    className=""
                                    style={{
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {firstUrl}
                                </div>
                            </Tooltip>

                            <Tag
                                className={'primary'}
                                onClick={() => showModal({ record, vendorId, type: 'chatbotsScanUrl', techId: row.chatbots_id })}
                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                            >
                                +{remainingUrlCount}
                            </Tag>
                        </div>
                    ) : (
                        <div className="vendor-details-scanurl">
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">{firstUrl}</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <div
                                    className=""
                                    style={{
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {firstUrl}
                                </div>
                            </Tooltip>
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">Download Har File</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <Button
                                    type="text"
                                    size="large"
                                    onClick={() => handleHarFileDownload({ record, vendorId, techId: row.chatbots_id })}
                                >
                                    <DownloadOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            );
        },
    },
];

export const getSessionReplayColumn = ({ showModal, handleHarFileDownload, vendorId }) => [
    {
        title: (
            <div className="vendor-details-table-header-title">
                Session Replay Name
                <Tooltip title="The name of the session replay technology.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'name',
        dataIndex: 'name',
        render: (text) => {
            return (
                <Tooltip
                    placement="right"
                    autoAdjustOverflow
                    title={() => {
                        return <div className="detailed-breakdown-cell-tooltip-content">{text}</div>;
                    }}
                    overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                >
                    <div
                        className=""
                        style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text}
                    </div>
                </Tooltip>
            );
        },
        width: 300,
    },
    {
        title: (
            <div className="vendor-details-table-header-title">
                Prevalence
                <Tooltip title="Shows how often we observed this session replay while scanning.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'prevalence',
        dataIndex: 'prevalence',
        render: (record) => {
            return <span>{record}%</span>;
        },
    },
    {
        title: (
            <div className="vendor-details-table-header-title">
                Scan URL
                <Tooltip title="Breakdown of all URLs containing the session replay during scanning - it also includes the HAR file for tracing the session replays's origin and data flow.">
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
            </div>
        ),
        key: 'scan_urls',
        dataIndex: 'scan_urls',
        render: (record, row) => {
            const urlCount = record.length;
            const firstUrl = record[0];
            const remainingUrlCount = urlCount - 1;

            return (
                <div className="vendor-details-tooltip">
                    {remainingUrlCount > 0 ? (
                        <div className="vendor-details-scanurl">
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">{firstUrl}</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <div
                                    className=""
                                    style={{
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {firstUrl}
                                </div>
                            </Tooltip>

                            <Tag
                                className={'primary'}
                                onClick={() =>
                                    showModal({ record, vendorId, type: 'sessionReplayScanUrl', techId: row.session_replay_id })
                                }
                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                            >
                                +{remainingUrlCount}
                            </Tag>
                        </div>
                    ) : (
                        <div className="vendor-details-scanurl">
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">{firstUrl}</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <div
                                    className=""
                                    style={{
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {firstUrl}
                                </div>
                            </Tooltip>
                            <Tooltip
                                placement="right"
                                autoAdjustOverflow
                                title={() => {
                                    return <div className="detailed-breakdown-cell-tooltip-content">Download Har File</div>;
                                }}
                                overlayClassName="detailed-breakdown-cell-tooltip-overlay"
                            >
                                <Button
                                    type="text"
                                    size="large"
                                    onClick={() => handleHarFileDownload({ record, vendorId, techId: row.session_replay_id })}
                                >
                                    <DownloadOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            );
        },
    },
];