import PropTypes from 'prop-types';
import React from 'react';
import { List, Map, is } from 'immutable';
import classNames from 'classnames';
import { SearchOutlined, WarningFilled } from '@ant-design/icons';
import { Modal, Radio, Select, Table, Progress, Alert, Popover, Tag } from 'antd';
import { Button, Chip, Input, message } from '../../../styleguide'
import { Standard, StandardRule } from '../../../records/standard_records';
import { PRIVACY_LENS_RULES_IMAGE_EXIST } from '../../../constants';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { getAccountAjax } from '../../../api/consent_quality/account';

const standardCategories = [
  'Consent Action',
  'Consent Notice',
  'Data Subject Request',
  'IAB Transparency & Consent Framework',
  'IAB US Privacy Framework',
  'Information Storage and Access',
  'Opt Out',
  'Privacy Policy',
];

export default class DetailStandardModal extends React.Component {
  static propTypes = {
    createStandard: PropTypes.func.isRequired,
    updateStandard: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  getInventoryScore = (standard = this.state.standard) => {
    const rules = standard.rules.map(r => { return { rule: { ruleId: r.rule.ruleId }}}).toJS();
    const obj = {
      accountId: this.props.accountId,
    };
    if (rules.length) {
      obj.rules = rules;
    } else {
      this.setState({ inventoryScore: 100 })
    }
    this.props.getStandardScores(obj)
  }

  getSortedRules = (rules) => {
    let sortedRules = new List([]);
    standardCategories.forEach(category => {
      const categoryRules = rules.filter(r => r.category === category);
      sortedRules = sortedRules.concat(categoryRules);
    });

    const standardRulesArray = this.props.standard.rules.map(r => r.rule.ruleId).toJS();

    sortedRules = sortedRules.map(rule => {
      if (standardRulesArray.includes(rule.ruleId)) {
        return this.props.standard.rules.find(sr => sr.rule.ruleId === rule.ruleId);
      }
      return rule;
    });

    return sortedRules;

    // const standardRulesArray = this.props.standard.rules.map(r => r.rule.ruleId).toJS();

    // const otherRules = rules.filter(rule => !standardRulesArray.includes(rule.ruleId))
    // return this.props.standard.rules.concat(otherRules);
  }

  state = {
    standard: this.props.standard || new Standard({}),
    defaultRulesState: true,
    ruleIdsToAdd: new List([]),
    inventoryScore: this.props.standard.scores.systemSummaryScores && this.props.standard.scores.systemSummaryScores.safe.percent || 100,
    selectAll: true,
    isRegulationDropdownVisible: false,
    standardImpact: 'system',
    tableData: null,
    sortedRules: this.getSortedRules(this.props.rules),
    search: '',
    name: this.props.standard.name,
    validationErrors: { standardName: null, standardRules: null},
    saveStandardConfirmation: false
  }

  componentDidMount() {
    this.props.getStandardScores({ 
      accountId: this.props.accountId,
    });
    getAccountAjax(this.props.accountId).then(account => {
      const sortedRules = this.state.sortedRules.filter(r => {
        const rule = r.rule || r;
        return account.rules.includes(rule.id);
      });
      this.setState({ sortedRules });
      const tableData = this.generateRulesTable();
      this.setState({ tableData });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.standard.rules !== this.state.standard.rules) 
      || (prevState.search !== this.state.search)
      || (prevProps.standardScoresPending && !this.props.standardScoresPending)
      ) {
        // if(prevState.standard.rules !== this.state.standard.rules)debugger
      if (prevProps.standardScoresPending && !this.props.standardScoresPending) {
        const summaryScores = this.props.standardScores[this.state.standardImpact].summaryScores;
        if (summaryScores) {
          this.setState({ inventoryScore: summaryScores.safe.percent });
        }
      }
      const tableData = this.generateRulesTable();
      this.setState({ tableData });
    }
  }

  handleCancelModalChanges = () => {
    this.props.closeModal();
  }

  handleStandardSave = () => {
    this.setState({ validationErrors: { standardName: null, standardRules: null}})
    if(this.state.standard.appliedToDomainSets.length > 0) {
      this.setState({saveStandardConfirmation: true})
    } else {
      this.handleStandardSaveConfirm();
    }
  }

  handleStandardSaveConfirm = () => {
    if(this.props.standard.isActive) {
      this.publish()
    } else {
      this.moveToDraft()
    }
    this.setState({saveStandardConfirmation: false})
  }

  handleStandardSaveCancel = () => {
    this.setState({saveStandardConfirmation: false})
  }

  publish = () => {
    this.handleApply(true);
  }

  moveToDraft = () => {
    this.handleApply(false);
  }

  onStandardNameChange = (e) => {
   this.setState({ name: e.target.value}, () => {
    if(this.state.name.trim().length > 0) this.setState({validationErrors : {...this.state.validationErrors, standardName : null}})
   })
  }
  handleApply = async (isActive) => {
    //standard validation
    let nameError = this.props.getStandardNameError(this.props.standard.name,this.state.name);
    let ruleError = this.state.standard.rules.size === 0 ? "Add at least one rule to the standard" : null
    if(nameError || ruleError){
      this.setState({validationErrors : {standardName: nameError, standardRules: ruleError}});
      return;
    }

    const standardWithName = this.state.standard.set('name', this.state.name).set('isActive', isActive);
    if (this.props.new) {
      this.props.createStandard(this.props.accountId, standardWithName).then((standard) => {
        message.success(<>Standard <strong>{standard.name}</strong> was successfully created</>)
        this.props.changeStandardsTypeTab(isActive ? "1" : "2");
      });
    } else {

      this.props.updateStandard(this.props.accountId, standardWithName).then(async (standard) => {
        if (standardWithName.appliedToDomainSetIds.length) {
          await this.props.getSetScoresIncluded(this.props.accountId, true);
        }
        message.success(<>Standard <strong>{standard.name}</strong> was successfully updated</>)
      });
    }
    this.props.closeModal();
  }

  changeShare = (e) => {
    this.setState({ standard: this.state.standard.set('share', e.target.value) });
  }
               
  changeWeight = ({target: {value}}, rule, blur) => {
    const { sortedRules } = this.state;

    const newWeight = value == "" ? value : parseInt(value);
    
    if( !blur && newWeight !== "" && isNaN(newWeight) || newWeight < 0 || newWeight > 100) {
      return;
    }

    const updatedRules = sortedRules.update(rs => {
      return rs.map(r => {
        const ruleToUpdate = r.rule ? r.rule : r
        if (ruleToUpdate.id === rule.id && newWeight <= 100) {
          return r.set('weight', blur && !newWeight ? r.rule.systemWeight : newWeight);
        } 
        return r;
      });
    });
    const standardRules = this.state.standard.rules.map(r => {
      if (rule.id === r.rule.id && newWeight <= 100){
        return r.set('weight', blur && !newWeight ? r.rule.systemWeight : newWeight);
      } else {
        return r;
      }
    });
    // const updatedStandard = standard;

    const updatedStandard = this.state.standard.set('rules', standardRules);
    this.setState({
      standard: updatedStandard,
      sortedRules: updatedRules,
      tableData: this.generateRulesTable(updatedStandard, sortedRules),
    });
  }

  generateRulesTable = (standard = this.state.standard, sortedRules = this.state.sortedRules) => {

    const columns = List([
      Map({
        title: 'ID',
        key: 'ruleId',
        dataIndex: 'ruleId',
        width: 50,
      }),
      Map({
        title: 'Name',
        key: 'rules',
        dataIndex: 'rules',
        width: 270,
      }),
      Map({
        title: (<div className="regulation">Regulation
        </div>),
        key: 'regulation',
        dataIndex: 'regulation',
        filters: [{
          text: 'GDPR',
          value: 'GDPR',
        }, {
            text: 'CCPA',
            value: 'CCPA',
        }, {
            text: 'ePrivacy',
            value: 'ePrivacy',
        }],
        onFilter: (value, record) => record.children[0].regulation.props.children.includes(value),
        width: 100,
      }),
      Map({
        title: 'Must Have',
        key: 'mustHave',
        dataIndex: 'mustHave',
        width: 50,
      }),
      Map({
        title: 'Nice to Have',
        key: 'niceToHave',
        dataIndex: 'niceToHave',
        width: 50,
      }),
      Map({
        title: 'Not Required',
        key: 'notRequired',
        dataIndex: 'notRequired',
        width: 50,
      }),
      Map({
        title: 'Weights',
        key: 'weights',
        dataIndex: 'weights',
      }),
      Map({
        title: '% Properties pass',
        key: 'percent',
        dataIndex: 'percent',
        width: 120,
      }),
    ]);

    let allRules = sortedRules;

    if (this.state.search) {
      allRules = allRules.filter(rule => {
        const name = rule.rule ? rule.rule.name : rule.name;
        const ruleId = rule.rule ? rule.rule.ruleId : rule.ruleId;
        return name.toLowerCase().includes(this.state.search.toLowerCase()) || ruleId.toLowerCase().includes(this.state.search.toLocaleLowerCase());
      });
    }
    
    const allowedStatuses = ['GA', 'Beta+', 'Beta'];
    if (this.props.accountId !== 22) allowedStatuses.push('Alpha');

    allRules = allRules.filter(rule => {
      const productStatus = rule.rule ? rule.rule.productStatus : rule.productStatus;
      return allowedStatuses.includes(productStatus);
    });

    // this.setState({ soretedRules: allRules })

    let dataSource = new List([]);
  
    standardCategories.forEach(category => {
      const categoryRules = allRules.filter(r => (r.category || r.rule.category) === category);
      if (categoryRules.size) {
        dataSource = dataSource.push(
          new Map({
              key: category,
              rules: category,
              isCategory: true,
              children: categoryRules.map((r, i) => {
                const rule = r.ruleId ? r : r.rule;
                const isStandardRule = !!r.rule;
                const niceToHave = r.ruleId ? null : r.niceToHave;
                const mustHave = r.ruleId ? null : !r.niceToHave;
                const notRequired = r.ruleId ? true : false;
                let ruleWeight;
                let weightColor;
                if (r.ruleId) {
                  weightColor = 'darkGrey';
                  ruleWeight = r.systemWeight;
                } else {
                  weightColor = 'black';
                  ruleWeight = r.weight;
                  if (!r.weight) weightColor = 'darkGrey';
                }
                const domainsPassPercent = (this.props.standardScores && this.props.standardScores[this.state.standardImpact] && this.props.standardScores[this.state.standardImpact].byRuleScores[rule.ruleId].passPercent) || 0;
          
                let content;
                if (PRIVACY_LENS_RULES_IMAGE_EXIST.includes(rule.ruleId)) {
                  let path = '/images/privacy_lens_rules/';
                  path += rule.ruleId;
                  content = (<div>
                    <p className="rule-title-popover">{rule.name}</p>
                    <p>{rule.description}</p>
          
                    <div className='rule-container'>
                      <img src={path + ' - pass.png'} alt={`${rule.ruleId} pass image`}/>
                      <img src={path + ' - fail.png'} alt={`${rule.ruleId} fail image`}/>
                    </div>
                  </div>
                  )
                } else {
                  content = (
                    <div className='rule-popover'>
                      <p className='rule-title-popover'>{rule.name}</p>
                      <p>{rule.description}</p>
                    </div>
                  )
                }
                const ruleName = (
                  <Popover overlayClassName="std-details-popover" placement="right" content={content} trigger="click" className='td-rules'>
                    <LegacyIcon type="eye"/>
                    <p className='rule-title'>{rule.name}</p>
                  </Popover>
                );
          
                const addRule = (rId, ruleRequirement) => {
                  const rules = this.props.rules;
                  let rule = rules.find(r => r.id === rId)
                  const standardRule = new StandardRule({ rule: rule.toJS(), niceToHave: ruleRequirement === 'niceToHave' ? true : false, weight: r.systemWeight });
                  const newStandardRules = this.state.standard.rules.push(standardRule);
                  const updatedStandard = standard.set('rules', newStandardRules);
                  const updatedRules = sortedRules.update(rs => {
                    return rs.map(r => {
                      if (r.id === rId) {
                        return standardRule;
                      } 
                      return r;
                    });
                  });
                  this.setState({
                    sortedRules: updatedRules,
                    standard: updatedStandard,
                    tableData: this.generateRulesTable(updatedStandard, sortedRules),
                    validationErrors: {...this.state.validationErrors, standardRules: updatedStandard.rules.size <= 0 && this.state.validationErrors.standardRules}
                  });
                }
          
                const editRule = (rule, ruleRequirement) => {
                  let updatedRule;
                  const rules = this.state.standard.rules.map(r => {
                    if (rule.id === r.rule.id){
                      if(ruleRequirement === 'notRequired'){
                        updatedRule = r.set('isActive', false);
                      } else if (ruleRequirement === 'niceToHave'){
                        updatedRule = r.set('niceToHave', true);
                      } else {
                        updatedRule = r.set('niceToHave', false)
                      }
                      return updatedRule;
                    }
                    return r;
                  });
                  const updatedRules = sortedRules.update(rs => {
                    return rs.map(r => {
                      if (r.rule && r.rule.id === rule.id) {
                        return updatedRule;
                      } 
                      return r;
                    });
                  });
                  const updatedStandard = this.state.standard.set('rules', rules);
                  this.setState({
                    sortedRules: updatedRules,
                    standard: updatedStandard,
                    tableData: this.generateRulesTable(updatedStandard, sortedRules),
                  });
                }
          
                const deleteRule = (rule) => {
                  const filtered = this.state.standard.rules.filter(r => rule.id !== r.rule.id);
                  const updatedRules = sortedRules.update(rs => {
                    return rs.map(r => {
                      if (r.rule && r.rule.id === rule.id) {
                        return rule;
                      } 
                      return r;
                    });
                  });
                  const updatedStandard = this.state.standard.set('rules', filtered);
                  this.setState({
                    sortedRules: updatedRules,
                    standard: updatedStandard,
                    tableData: this.generateRulesTable(updatedStandard, sortedRules),
                  });
                }
          
                const changeRuleRequirement = (e, rule) => {
                  const standardRulesArray = this.state.standard.rules.map(r => r.rule.ruleId).toJS();
                  const ruleRequirement = e.target.name;
                  if (!standardRulesArray.includes(rule.ruleId)){
                      addRule(rule.id, ruleRequirement)
                  } else if (ruleRequirement === 'notRequired'){
                      deleteRule(rule)
                  } else {
                      editRule(rule, ruleRequirement)
                  }
                };
          
                const name = <div>{ rule.productStatus !== "GA" ? <Tag color="volcano">{rule.productStatus}</Tag> : null }{ruleName}</div>;
          
                return Map({
                  key: rule.ruleId,
                  ruleId: rule.ruleId,
                  rules: name,
                  regulation: (<p className='rule-type'>{rule.regulations.join(', ')}</p>),
                  mustHave: (<Radio.Button name="mustHave" checked={mustHave} value="mustHave"  onClick={(e) => changeRuleRequirement(e, rule)} disabled={this.props.readOnly}></Radio.Button>),
                  niceToHave: (<Radio.Button name="niceToHave" checked={niceToHave} value="niceToHave" onClick={(e) => changeRuleRequirement(e, rule)}disabled={this.props.readOnly}></Radio.Button>),
                  notRequired: (<Radio.Button name="notRequired" checked={notRequired} value="notRequired" onClick={(e) => changeRuleRequirement(e, rule)}disabled={this.props.readOnly}></Radio.Button>),
                  weights: (ruleWeight !== null && <Input style={{color: weightColor}} value={ruleWeight} className='standard-weights' onChange={(e) => this.changeWeight(e, rule, false)} onBlur={(e) => this.changeWeight(e, rule, true)} disabled={this.props.readOnly || !isStandardRule} />),
                  percent: <Progress
                    className={classNames({ 'red': domainsPassPercent < 40 })}
                    percent={domainsPassPercent}
                  />,
                  category: rule.category, 
                  name: rule.name,
                });
              })
          })
        )
      }
    });
  
    return new Map({ dataSource, columns });
  }

  getRowClassName = (record) => {
    return record.isCategory ? 'category-header' : '';
  }

  render() {
    const standard = this.state.standard;
    const tableData = this.state.tableData;
    const { validationErrors } = this.state;

    if (!tableData) return null;

    let table;
    if (tableData.get('dataSource').size) {
      table = (
        <Table
          bordered={true}
          dataSource={tableData.get('dataSource').toJS()}
          columns={tableData.get('columns').toJS()}
          pagination={false}
          scroll={{ y: 540 }}
          expandable={{
            defaultExpandAllRows: true
          }}
          rowClassName={this.getRowClassName}
        />
      );
    } else {
      table = (
        <Table
          bordered={true}
          columns={tableData.get('columns').toJS()}
          pagination={false}
        />
      );
    }

    let inventory;
    if (this.state.sortedRules.size) {
      // const isNoScores = Object.keys(standard.scores).length === 0;
      // const standardImpactOnProperties = this.state.standardImpact === 'system' ? !isNoScores && standard.scores.systemSummaryScores.safe.percent || 0 : !isNoScores && standard.scores.accountSummaryScores.safe.percent || 0;
      inventory = (
        <div className='inventory'>
          <div>
            {this.state.standard.scores && <Select
              onChange={(value) => this.setState({ standardImpact: value })}
              value={this.state.standardImpact}
            >

              <Select.Option value="system" key="all-sourcepoint-system-properties">All Sourcepoint system properties</Select.Option>
              <Select.Option value="account" key="account-scored-properties">Account scored properties</Select.Option>
            </Select>}

          </div>
          <Progress percent={this.state.inventoryScore} className={classNames({ 'red': this.state.inventoryScore < 40 })} />
          {/* <p className='status-title'>Total: 15,000 Properties</p> */} 
          {/* TODO: add properties count */}
          <Button
            className="refresh-btn"
            onClick={() => this.getInventoryScore(this.state.standard)}
          >
            <img src={require('../../../assets/images/privacy-lens/refresh-icon.svg')}
              alt="refresh"
              className="refresh-icon"
            />Refresh Status
          </Button>
        </div>
      )
    }

    let title;
    if (this.props.standard) {
      if (!this.props.standard.id) {
        title = 'Add New Standard';
      } else if (this.props.readOnly) {
        title = 'View Standard';
      } else {
        title = 'Edit Standard';
      }
    }

    const editStandardAlertMessage = (
      <>
        <p>
          Editing standard{" "}
          <span className="standard-name">{standard.name}</span> will affect the
          following property sets :{" "}
          {standard.appliedToDomainSets.map((ds) => (
            <Chip className="warning-tag">{ds}</Chip>
          ))}
        </p>
        {this.state.saveStandardConfirmation && (
          <div className="save-confirmation">
            <p>
              Are you sure you want to edit{" "}
              <span className="standard-name">{standard.name}</span> standard?
            </p>
            <div className="save-confirmation-column">
              <Button
                className="cancel"
                onClick={this.handleStandardSaveCancel}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={this.handleStandardSaveConfirm}>
                Confirm
              </Button>
            </div>
          </div>
        )}
      </>
    );
    const editStandardAlert = <Alert message={editStandardAlertMessage} type="warning" showIcon />;

    const disabled = this.props.readOnly
    
    const validationErrorAlert = validationErrors.standardName || validationErrors.standardRules ? (
      <Alert
        className='validation-errors'
        type='error'
        message="Pleae fill or select the following items"
        description={<ul>{Object.values(validationErrors).map((err)=>(err && <li>{err}</li>))}</ul>}
        icon={<WarningFilled/>}
        showIcon
      />
    ) : null;

    return (
      <Modal
        className='new-standard '
        closable={true}
        onCancel={this.props.closeModal}
        destroyOnClose
        visible={this.props.visible}
        footer={ this.state.saveStandardConfirmation ? editStandardAlert : 
          [
            <Button onClick={this.handleCancelModalChanges} className="cancel" >Cancel</Button>,
            this.props.readOnly ? null :
              (this.props.new ?
                [<Button disabled={disabled} className="draft" onClick={this.moveToDraft} type="secondary">Move To Draft</Button>,
                <Button disabled={disabled} className='publish' onClick={this.publish} type="primary">Publish</Button>,]
              :
              <Button disabled={disabled} className='publish' onClick={this.handleStandardSave} type="primary">Save</Button>),
          ]
        }
      >
        <p className="standard-title-modal">{title}</p>
        { standard.appliedToDomainSets.length && !this.state.saveStandardConfirmation ? editStandardAlert : null }
        <div className='modal-container'>

          <div className='standard-name-modal'>Name</div>
          <Input
            value={this.state.name}
            className='standard-name'
            onChange={this.onStandardNameChange}
            disabled={this.props.readOnly}
            error={validationErrors.standardName}
          />

          <div className="standard-impact">
            <div className='standard-name-modal'>Standard impact on available properties</div>
            <p className='description-modal'>You can quantify the volume of properties that would pass your standard and the rules below. To do so, select what to run the standard against</p>
            {inventory}
          </div>

          <div className='rules-container'>
            <div className='standard-name-modal'>{this.props.readOnly ? 'Rules': 'Add Rules'}</div>
            {validationErrors.standardRules && <div className='rules-validation-error'>{validationErrors.standardRules}</div>}
            <div className='flex-space-between title-3'>
            </div>
            <div className='rules-bordered'>
              <div className='search-row'>
                <Input
                  placeholder="Search Rules"
                  onChange={(e) => this.setState({ search: e.target.value })}
                  className="rule-search"
                  suffix={<SearchOutlined />}
                />
                <div className="rule-count">
                  Total <span>{this.state.sortedRules.size}</span> Rules | <span>{standard.rules.size}</span> Rules Added
                </div>
              </div>
              {table}
            </div>
          </div>
          {validationErrorAlert}
        </div>
      </Modal>
    );
  }
}