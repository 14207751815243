import PropTypes from 'prop-types';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Select, Tooltip } from 'antd';
import { Input } from '../../../../../styleguide'
import { PurposeV2 as Purpose, VendorList } from '../../../../../records/vendor_list_records';
import { GCM_CATEGORIES } from '../../../../../constants';
import { addGcmActions } from '../../util'

export default class CreateCustomPurposeModal extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    closeModal: PropTypes.func.isRequired,
    handleApply: PropTypes.func.isRequired,
  }

  state = {
    name: '',
    description: '',
    error: null
  }

  handleOnOk = () => {
    let purpose = new Purpose({
      id: `temp-${new Date().getTime()}`,
      name: this.state.name,
      description: this.state.description,
      type: 'CUSTOM',
    })

    if (this.state.gcmCategory) {
      purpose = addGcmActions(purpose, this.state.gcmCategory)
    }

    const purposeNameExist = this.props.vendorList.categories.find((p) => {
      if(p.type === 'CUSTOM'){
        return p.id !== purpose.id && p.name.trim().toLowerCase() === this.state.name.trim().toLowerCase()
      }
      else if(p.type === 'CUSTOM_STACK' && p.categories.size !== 0) {
        return p.categories.find(category => category.id !== purpose.id && category.name.trim().toLowerCase() === this.state.name.trim().toLowerCase())
      }
    });

    if(purposeNameExist || this.state.name === '') {
      const errorMsg = purposeNameExist ? <><strong>{this.state.name}</strong> name already exists.</> : <>Name field can not be empty.</>
      this.setState({error: errorMsg});
    }else if(this.state.name.includes(";")){
      this.setState({error: <>Name cannot include character '<b>;</b>'</>})
    }else {
      this.props.handleApply(this.props.vendorList.update('categories', c => c.push(purpose)));
      this.setState({error: null});
      this.props.closeModal();
    }
  }

  render() {
    const gcmUnusedCategories = this.props.vendorList.getUnusedGcmCategories()

    return (
      <Modal
        className='add-custom-purpose'
        destroyOnClose
        visible={this.props.visible}
        closable={false}
        okText='Create purpose'
        onOk={this.handleOnOk}
        onCancel={this.props.closeModal}
      >
        <div>
          <label>
            Name:
            <Input
              className="custom-purpose-name"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value, error: null })}
              error={this.state.error}
            />
          </label>
          <label>
            Description:
            <Input
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
            />
          </label>
          <label>
            Google Consent Mode Category
            <Select
              value={this.state.gcmCategory || 'None'}
              onChange={(value) => this.setState({ gcmCategory: value })}
              placeholder='None'
            >
              <Select.Option key='None'>None</Select.Option>
              { GCM_CATEGORIES.map((category) => (
                <Select.Option key={category} disabled={gcmUnusedCategories.indexOf(category) < 0}>{category}</Select.Option>
              )) }
            </Select>
            <Tooltip title={"Tie this Custom Purpose to a Google Consent Mode category"} placement="right">
              <InfoCircleOutlined />
            </Tooltip>
          </label>
        </div>
      </Modal>
    )
  }
}
