import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Progress } from 'antd';
import { Map } from 'immutable';
import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Input, FilterSelect } from '../../../styleguide'
import CreateButton from '../../common/CreateButton.js.jsx';
import DomainSetModal from './DomainSetModal';
import {
  getDomainSets,
  createDomainSet,
  updateDomainSet,
  deleteDomainSet,
} from '../../../actions/domain_set_actions';
import { DomainSet } from '../../../records/domain_set_records';
import Loading from '../../common/Loading.js.jsx';
import { getStandards, getRules } from '../../../actions/standard_actions';
import { getParameterByName } from '../../utils';
import DomainSetsGrouped from './DomainSetsGrouped';
import { getAccountAjax } from '../../../api/consent_quality/account';
import { getAllAccountDomainsPerRegion } from '../../../api/consent_quality/domain_set';

const TabPane = Tabs.TabPane;

class DomainSetsPage extends React.Component { 
  static propTypes = {
    createDomainSet: PropTypes.func.isRequired,
    updateDomainSet: PropTypes.func.isRequired,
    deleteDomainSet: PropTypes.func.isRequired,
    getStandards: PropTypes.func.isRequired,
  }

  state = {
    openedDomainSet: null,
    newDomainSetModal: false,
    propertySets: [],
    loading: true,
    loadingModal: false,
    selectedPropertySet: null,
    setType: "1",
    activateModal: false,
    plUsage: {
      plUsage: 0,
      scanAllowance: 0,
    },
    selectedStandards: [],
    readOnly: true,
    search: "",
  }

  setLoadingModal = (val) => {
    this.setState({ loadingModal: val, readOnly: val });
  }

  setReadOnly = () => {
    const readOnly = (
      !this.props.currentUser.masqed && (this.props.currentUser.accountFeatures.includes('privacy_lens_read_only')
      || (!this.props.currentUser.allFeatureAccess && (this.props.currentUser.featureAccess && this.props.currentUser.featureAccess.includes('Privacy Lens - Read Only'))))
    );
    if (!readOnly) {
      this.setState({ readOnly: false });
    }
  }

  onCurrentUserAvailableDo = async () => {
    this.setReadOnly();
    const accountId = this.props.currentUser.accountId;
    this.props.getDomainSets(accountId).then(() => {
      this.setPlUsage();
    });
    this.props.getRules(accountId);
    await this.props.getStandards(accountId, true);
    this.setState({ 
      standards: this.props.standards,
      loading: false,
      selectedStandards: getParameterByName('standardId', window.location) ? [getParameterByName('standardId', window.location)] : this.props.standards.map(s => (s._id || s.id)).toJS(),
    });
  }

  setPlUsage = () => {
    getAccountAjax(this.props.currentUser.accountId).then(account => {
      getAllAccountDomainsPerRegion(this.props.currentUser.accountId, 'GB').then(({totalCount}) => {
        this.setState({
          plUsage: { scanAllowance: account.scanAllowance, plUsage: totalCount },
          contractEndDate: account.endDate,
        });
      });
    });
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.onCurrentUserAvailableDo()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.currentUser && this.props.currentUser) {
      this.onCurrentUserAvailableDo();
    }
  }

  openStandardModal = (standard) => {
    this.setState({ openedStandard: standard });
  }

  changeTab = (activeKey) => {
    this.setState({ setType: activeKey });
  }

  setOpenedDomainSet = (openedDomainSet, acivate) => {
    this.setState({ 
      openedDomainSet,
      activateModal: acivate,
    });
  }

  filterSetsForStandard = (propertySets) => {
    return propertySets.filter((set) => {
      const standardId = set.standard ? set.standard._id : (set.standards[0]._id || set.standards[0].id);
      return this.state.selectedStandards.includes(standardId);
    });
  }

  handleSelectStandard = (selectedStandards) => {
    this.setState({ selectedStandards });
  }

  updateSearch = ({ target: {value} }) => {
    this.setState({ search: value });
  }

  render() {
    const { loading, loadingModal } = this.state;

    let domainsSets = this.props.domainSets.filter(ds => ds.domainSetStatus !== 'DeletedPending');

    domainsSets = this.filterSetsForStandard(domainsSets);
    if (this.state.search) {
      domainsSets = domainsSets.filter(ds => ds.name.toLowerCase().includes(this.state.search.toLowerCase()));
    }
    if (this.props.pendingRequestsMap.get('domainSets') || this.props.pendingRequestsMap.get('standards')) {
      return <Loading/>;
    }

    let domainSetModal;
    if (this.state.openedDomainSet) {
      domainSetModal = (
        <DomainSetModal
          closeModal={() => this.setState({ openedDomainSet: null, newDomainSetModal: false, activateModal: false })}
          standards={this.state.standards || []}
          createDomainSet={this.props.createDomainSet}
          updateDomainSet={this.props.updateDomainSet}
          accountId={this.props.currentUser.accountId}
          domainSet={this.state.openedDomainSet}
          new={this.state.newDomainSetModal}
          activate={this.state.activateModal}
          setPlUsage={this.setPlUsage}
          readOnly={this.state.readOnly}
          visible={!!this.state.openedDomainSet}
          switchDomainsTab={this.changeTab}
          allDomainSets={this.props.domainSets}
        />
      );
    }

    const activeSets = [];
    const inactiveSets = [];
    domainsSets.forEach(ds => {
      if (ds.domainSetStatus !== 'Disabled') {
        activeSets.push(ds);
      } else {
        inactiveSets.push(ds);
      }
    });

    const searchAndFilter = (
      <div className='search-container'>
        <Input
          placeholder="Search Property Sets"
          onChange={this.updateSearch}
          value={this.state.search}
          suffix={<SearchOutlined />}
        />
      </div>);
  let plUsagePercent;
  if (this.state.plUsage.scanAllowance !== -1) {
    plUsagePercent = parseInt((this.state.plUsage.plUsage / this.state.plUsage.scanAllowance) * 100)
  }

  return loading ? <Loading /> : (
    <div>
     { loadingModal ? <Loading /> : null }
     <div className={ classNames('domain-sets-container', 'privacy-lens', { 'loading-modal': loadingModal }) }>
      {domainSetModal}
      <div>
      <div className='title-privacy-lens'>PROPERTY SETS</div>
      <CreateButton disabled={!this.state.standards || this.state.readOnly} onClick={() => this.setState({ openedDomainSet: new DomainSet({}), newDomainSetModal: true })}>
        Add New Property Set
      </CreateButton>
      </div>
        {
          this.state.readOnly ? 
          null : 
          <div className={ classNames('pl-usage', { green: plUsagePercent <= 25 }, { yellow: plUsagePercent > 25 && plUsagePercent <= 75 }, { red: plUsagePercent > 75 }) }>
            <div className='pl-usage-top'>
              <div>{'PL Usage: '}</div><div className='right'>{this.state.plUsage.scanAllowance === -1 ? 'Unlimited' : `${plUsagePercent}%`}</div>
            </div>
            <Progress percent={plUsagePercent} />

            {/* {this.state.plUsage.scanAllowance === -1 ? 'PL Usage: unlimited' : `used ${this.state.plUsage.plUsage} out of ${this.state.plUsage.scanAllowance} PL allowance`} */}
          </div>
        }
      <div className='container-pl-usage-standards'>
          {searchAndFilter}
        <div className='standards'>
          <FilterSelect
            allowAll
            multiple
            placeholder="Standards Applied:"
            value={this.state.selectedStandards}
            onChange={this.handleSelectStandard}
            type="standards"
            options={this.props.standards.map((s,i)=>({value : s._id || s.id, name: s.name})).toJS()}
            keypair
          />
        </div>
      </div>
        <Tabs activeKey={this.state.setType} onChange={this.changeTab}>
          <TabPane tab={`Active (${activeSets.length})`} key="1">
            <DomainSetsGrouped 
              domainSets={activeSets}
              allDomainSets={this.props.domainSets}
              currentUser={this.props.currentUser}
              switchDomainsTab={() => this.changeTab('2')}
              setOpenedDomainSet={this.setOpenedDomainSet}
              setPlUsage={this.setPlUsage}
              contractEndDate={this.state.contractEndDate}
              active
              readOnly={this.state.readOnly}
              setLoadingModal={this.setLoadingModal}
            />
          </TabPane>
          <TabPane tab={`Inactive (${inactiveSets.length})`} key="2">
          <DomainSetsGrouped 
            domainSets={inactiveSets}
            allDomainSets={this.props.domainSets}
            currentUser={this.props.currentUser}
            switchDomainsTab={() => this.changeTab('1')}
            setOpenedDomainSet={this.setOpenedDomainSet}
            setPlUsage={this.setPlUsage}
            contractEndDate={this.state.contractEndDate}
            readOnly={this.state.readOnly}
            setLoadingModal={this.setLoadingModal}
          />
          </TabPane>
        </Tabs>
      </div>
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    domainSets: store.domainSetState.getIn(['domainSets', 'value']),
    standards: store.standardState.getIn(['standards', 'value']),
    pendingRequestsMap: new Map({
      domainSets: store.domainSetState.getIn(['domainSets', 'pending']),
      standards: store.standardState.getIn(['standards', 'pending']),
      domainSetCreateOrUpdate: store.domainSetState.get('createOrUpdatePending'),
    }),
  };
};

export default connect(
  mapStateToProps, {
  getStandards,
  getDomainSets,
  createDomainSet,
  updateDomainSet,
  deleteDomainSet,
  getRules,
},
)(DomainSetsPage);