import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Scenario } from '../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { Select } from '../../../styleguide';

const Option = Select.Option;

export default class ScenarioSelector extends React.Component {
    static propTypes = {
        scenarios: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Scenario)).isRequired,
        value: PropTypes.number,
        onChange: PropTypes.func
    };

    state = {
        value: this.props.value
    };

    handleChange = (val) => {
        this.setState({ value: parseInt(val) });
        this.props.onChange(val);
    };

    render() {
        return (
            <Select value={ this.state.value === -1 ? '[Unset]' : this.state.value } onChange={ this.handleChange }>
                <Option value="-1">[Unset]</Option>
                { this.props.scenarios.map(function(scenario, i) {
                    return (<Option key={ scenario.get('id') } value={ scenario.get('id') }>{ scenario.get('description') }</Option>);
                }.bind(this))}
            </Select>
        );
    }
}
