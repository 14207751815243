import React from 'react';
import { Input, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const CustomFields = ({
  fields = [],
  addField,
  removeField,
  updateField,
  titleSize,
}) => {
  return (
    <div>
      {(titleSize === 'sm')
        ? (<h4>additional custom options</h4>)
        : (<h3>additional custom options</h3>)}
      <div className="add-field-btn-container">
        <Button
          id="add-field-btn"
          onClick={() => addField({
            key: `Key ${(fields.length || 0) + 1}`,
            value: `Value ${(fields.length || 0) + 1}`
          })}
        >
          ADD CUSTOM FIELD
        </Button>
      </div>
      <div className="custom-field-container">
        {(fields.length === 0)
          ? (
            <div>
              <i>No custom fields have been created</i>
            </div>
          )
          : fields.map(({ key, value }, i) => (
            <div key={i} className="custom-field-row">
              <div className="key-container">
                <label htmlFor={`key ${i}`}>
                  Key
                  </label>
                <Input
                  id={`native-msg-key-input-${i}`}
                  data-testid={`native-msg-key-input-${i}`}
                  value={key}
                  onChange={({ target: { value } }) => updateField(i, 'key', value)}
                />
              </div>
              <div className="value-container">
                <label htmlFor={`value ${i}`}>
                  Value
                  </label>
                <Input
                  id={`native-msg-value-input-${i}`}
                  data-testid={`native-msg-value-input-${i}`}
                  value={value}
                  onChange={({ target: { value } }) => updateField(i, 'value', value)}
                />
              </div>
              <div
                id={`native-msg-trash-field-${i}`}
                data-testid={`native-msg-trash-field-${i}`}
                className="trash-container"
                onClick={() => removeField(i)}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default CustomFields;