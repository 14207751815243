import React from 'react';
import { styles } from '../Conditions/styles';

export default class ConditionCommonWrapper extends React.Component {
  render() {
    if(!this.props.children) {
       return null;
    }

    return (
      <div
        id="condition-common-wrapper"
        style={styles.scenarioConditionPanelCommon}
      >
        {this.props.children}
      </div>
    );
  }
}
