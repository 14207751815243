import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import Loading from '../../../common/Loading.js.jsx';
import { Link } from 'react-router';
import { Standard } from '../../../../records/standard_records';
import { regionsMap } from '../../../../constants';
import moment from 'moment';
import { 
  getDomainSets,
  getAverageImpressions,
} from '../../../../actions/domain_set_actions';
import { getDomainScores, getSetScoresIncluded, getDomainSetScores, changeDomainScores, changeDomainScoresRedux } from '../../../../actions/domain_score_actions';
import { User } from '../../../../records/account_records';
import PropertyTable from './Components/PropertyTable';
import RulePerformance from './Components/RulePerformance';
import PropertyListDownload from './Components/PropertyListDownload';
import { getDomainsWithScores } from '../../../../api/consent_quality/domain_set';
import { Select, Input , Alert} from 'antd';
import { FilterSelect } from '../../../../styleguide';
const { Option } = Select;


class DetailedResults extends React.Component {
  static propTypes = {
    currentUser: PropTypes.instanceOf(User).isRequired
  }

  state = {
    scores: null,
    threshold: 50,
    domainSetId: window.location.search ? window.location.search.split('=')[1] : ''
  }

  componentDidMount = () => {
    this.loadData();
  }
  componentDidUpdate = (prevProps) => {
    const domainSetId = window.location.search ? window.location.search.split('=')[1] : '';
    if (domainSetId !== this.state.domainSetId) {
      this.setState({ domainSetId, scores: null }, this.loadData);
    }
  };
  loadData = async () => {
    const accountId = this.props.currentUser.accountId;
    this.props.getSetScoresIncluded(accountId);
    const scores = await getDomainsWithScores(this.state.domainSetId);
    this.setState({ scores: scores || [] });
  }
  getSummaryData = () => {
    if (!this.props.setScoresIncluded) return null;
    return this.props.setScoresIncluded.toJS();
  }
  getAllData = () => {
    if (!this.props.allScoresIncluded) return null;
    return this.props.allScoresIncluded.toJS();
  }
  updateDomainSet = (val) => {
    browserHistory.push('/privacy_lens/scoring_results/detail?id='+val);
  }
  calculateThresholdScores = () => {
    const total = this.state.scores.filter(s => s.scored ? true : false);
    const filtered = this.state.scores.filter(s => s.scored && s.overAllScore >= this.state.threshold);

    return {
      filtered: filtered.length,
      total: total.length,
      percent: Math.round((filtered.length / total.length) * 100)
    }
  };
  scoreStatus = (selectedSet) => {
    if (!selectedSet) return 'pending';
    const totalProperties = selectedSet?.numProperties || 0;
    const scoredProperties = selectedSet?.scores?.totalScored || 0;

    if (scoredProperties >= totalProperties) {
      return 'completed';
    } else {
      return 'pending';
    }
  }

  updateThreshold = threshold => this.setState({ threshold })
  render() {
    const { domainSetId, scores } = this.state;
    let summaryData = this.getSummaryData();
    let allData = this.getAllData();
    const isLoading = summaryData && scores && allData ? false : true;

    const meetingThreshold = !isLoading ? this.calculateThresholdScores(summaryData) : null;

    const selectedSet = allData.find(a => {
      return a.id === this.state.domainSetId;
    });

    /* FIND REGION */
    let region = null;
    if (selectedSet?.region) {
      region= regionsMap[selectedSet.region];
    }

    /* Handle Alerts */
    const difference = null;
    let showWarningAlert = false;
    const scoreStatus = this.scoreStatus(selectedSet);
    if (selectedSet?.domainSetStatus === 'DisabledPending') {
      const PERIOD = 30;
      const todayDate = moment();
      const acviteDays = todayDate.diff(moment(selectedSet?.activateDate), 'days');
      const daysBeforePeriodEnds = PERIOD - (acviteDays % PERIOD);
      showWarningAlert = 'deactivate in ' + daysBeforePeriodEnds + ' days';
    }

    return isLoading ? <Loading /> : (
      <div className="detailed-results">

        <Link to="/privacy_lens/scoring_results">
          <div className="back">
            <span />Back to Scoring Results
          </div>
        </Link>

        <div className="ps-info">
          <div className="ps-item">
            <div className="psi-label">Property Set</div>
            <FilterSelect
              keypair
              placeholder="" 
              placevalue=""
              value={this.state.domainSetId}
              onChange={this.updateDomainSet}
              options={(allData || []).map(d => {
                return { name: d.name, value: d.id };
              })}
            />
          </div>
          <div className="ps-item">
            <div className="psi-label">Standard</div>
            <Input value={selectedSet?.standard?.name} disabled={true} />
          </div>
          { region && (
            <div className="ps-item">
              <div className="psi-label">Region Applied</div>
              <Input value={region} disabled={true} />
            </div>
          )}
        </div>

        <div className="ps-divide" />

        <div className="ps-title">Detailed Result</div>

        { scoreStatus === 'completed' ? (
          <Alert message={(
            <div>
              <div><b>Scoring completed!</b></div>
              { selectedSet?.domainSetStatus === 'DisabledPending' ? <div> Scoring for current period is done. You can download the results before this period ends.</div> : null }
            </div>
          )} type="success" closable />
        ) : null }
        
        { scoreStatus === 'pending' ? (
          <Alert message={(
            <div>
              <div><b>Scores are being generated</b></div>
              <div>Scoring is in-progress. Results downloaded right now will not have complete data.</div>
            </div>
          )} type="warning" closable />
        ) : null }

        { showWarningAlert ? (
          <Alert message={(
            <span>This property set will <b>{showWarningAlert}</b>. You'll lose the scoring result once this period ends. If you need to access the scoring result, make sure you download it before the property set deactivates.</span>
          )} type="warning" showIcon closable />
        ) : null }

        <RulePerformance 
          scoreData={summaryData}
          selectedSetName={selectedSet?.name}
        /> 

        <PropertyTable 
          scoreData={summaryData} 
          scores={scores}
          threshold={this.state.threshold}
          selectedSetName={selectedSet?.name}
        />

      </div>
    );
  }
}


const mapStateToProps = function (store){
  const domainSetId = window.location.search ? window.location.search.split('=')[1] : '';
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    setScoresIncluded: store.domainScoreState.getIn(['setScoresIncluded', 'value']).find(s => s.id === domainSetId),
    allScoresIncluded: store.domainScoreState.getIn(['setScoresIncluded', 'value']),
  };
};

export default connect(
  mapStateToProps, {
    getSetScoresIncluded,
  },
)(DetailedResults);

