import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Select } from '../../styleguide';
import { List } from 'immutable';

const { Option, OptGroup } = Select;

export default class SelectSitesAndSiteGroups extends React.Component {
  static propTypes = {
    sites: ImmutablePropTypes.list.isRequired,
    siteGroups: ImmutablePropTypes.list.isRequired,
    onSelect: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    value: ImmutablePropTypes.list.isRequired,
    hasAccessToAllSitesField: PropTypes.bool,
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    multiple: false,
    disabled: false,
  }

  state = {
    siteGroupsSelected: List([]),
  }

  getUserSites = () => {
    if (this.props.siteAccess) {
      return this.props.sites.filter(s => {
        if (s === 'allSites') return true;
        return this.props.siteAccess.includes(s.id);
      });
    } else {
      return this.props.sites;
    }
  }

  getUserSiteGroups = () => {
    if (this.props.siteAccess) {
      return this.props.siteGroups.filter(sg => {
        return sg.siteIds.every(id => {
          return this.props.siteAccess.includes(id);
        });
      });
    } else {
      return this.props.siteGroups;
    }
  }

  getSitesWithoutSiteGroups = (sites) => {
    //XXX not filtering for all sites
    return sites.filterNot(s => s.type === 'property_group')
  }

  getSiteGroupSiteIds = (id) => {
    return this.props.siteGroups.find(sg => sg.id === id).siteIds;
  }

  handleSelect = (siteIds) => {
    let resultSiteIds;
    let siteGroupsSelected = List([]);
    if (Array.isArray(siteIds)) {
      siteIds = List(siteIds);
      if (siteIds.some(id => id === 'all access')) {
        resultSiteIds = List(['all access']);
      } else if (siteIds.some(id => id === 'allSites')) {
        resultSiteIds = this.getSitesWithoutSiteGroups(this.getUserSites(this.props.sites)).map(s => s.id);
      } else if (this.props.value.some(id => id === 'allSites') && !siteIds.some(id => id === 'allSites')) {
        this.setState({ siteGroupsSelected: List([]) });
        resultSiteIds = List([]);
      } else {
        resultSiteIds = siteIds.map(id => {
          if (id.slice(0, 11) === 'site-group:') {
            siteGroupsSelected = siteGroupsSelected.push(id);
            return this.getSiteGroupSiteIds(id.slice(11));
          } else if (id === 'all access') {
            return id;
          } else {
            return parseInt(id);
          }
        }).flatten(true);

        this.state.siteGroupsSelected.forEach(sg => {
          if (!siteGroupsSelected.includes(sg)) {
            resultSiteIds = resultSiteIds.filterNot(id => this.getSiteGroupSiteIds(sg.slice(11)).includes(id));
          }
        });
      }
      resultSiteIds = resultSiteIds.toSet().toJS();

    } else {
      resultSiteIds = parseInt(siteIds);
    }

    this.setState({ siteGroupsSelected });
    this.props.onSelect(resultSiteIds);
  }

  filterSites = (inputString, option) => {
    return inputString.toLowerCase() === option.props.children.toLowerCase().slice(0, inputString.length);
  }

  filterSiteTypes = (sites, type) => {
    return sites.filter(site => site.type == type)
      .map(site => <Option key={site.id} searchValue={site.domain.toLowerCase()}>{site.domain.toLowerCase()}</Option>);
  }

  render() {
    if (this.props.value === 'not set') return null;

    const selectProps = {
      onChange: this.handleSelect,
      disabled: this.props.disabled,
      dropdownClassName: this.props.customDropdownClass || '',
      showArrow: true,
    };
    if (this.props.multiple) {
      selectProps.value = this.props.value
        .filter(id => id === 'all access' || this.props.sites.find(s => s.id === id))
        .map(id => id.toString())
        .concat(this.state.siteGroupsSelected).toSet().toJS();
      selectProps.mode = 'multiple';
      selectProps.tokenSeparators = [','];
      let placeholder;
      if (this.props.placeholder === 'none') {
        placeholder = '';
      } else if (!this.props.placeholder) {
        placeholder = 'Choose one or more properties'
      }
      selectProps.placeholder = placeholder;
      selectProps.filterOption = true;
      selectProps.optionFilterProp = 'searchValue';
    } else {
      selectProps.value = this.props.value;
      selectProps.filterOption = true;
      selectProps.optionFilterProp = 'searchValue';
    }

    let sites = this.getUserSites(this.props.sites);
    sites = _.uniqBy(this.getSitesWithoutSiteGroups(sites).toJS(), ({ id }) => id);

    // let options = sites.map(site => <Option key={ site.id } >{ site.domain.toLowerCase() +"->"+site.type}</Option>);

    let options = List([]);
    let optionsWeb = this.filterSiteTypes(sites, "web");
    let optionsApp = this.filterSiteTypes(sites, "app");
    let optionsOTT = this.filterSiteTypes(sites, "ott");
    let optionsAMP = this.filterSiteTypes(sites, "amp");


    const siteGroups = this.getUserSiteGroups(this.siteGroups);

    // if (siteGroups.size) {
    options = List([
      <OptGroup key="groups" label='Grouped properties'>
        {siteGroups.map(sg => <Option key={'site-group:' + sg.id} searchValue={sg.name} >{sg.name}</Option>)}
      </OptGroup>,
      <OptGroup key="app" label='App properties'>
        {optionsApp}
      </OptGroup>,
      <OptGroup key="web" label='Web properties'>
        {optionsWeb}
      </OptGroup>,
      optionsOTT.length?
      <OptGroup key="ott" label='OTT properties'>
        {optionsOTT}
      </OptGroup>: null,
      optionsAMP.length?
      <OptGroup key="amp" label='AMP properties'>
        {optionsAMP}
      </OptGroup>: null,
    ]);
    // }

    if (this.props.sites.size) {
      options = options.unshift(<Option key={'allSites'}>Select all properties</Option>);
    }

    if (this.props.hasAccessToAllSitesField) {
      options = options.unshift(<Option key={'all access'}>Access to all properties</Option>);
    }

    return (
      <Select
        {...selectProps}
      >
        {options}
      </Select>
    );
  }
}
