import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import BooleanStep from './BooleanStep';
import CommonStringInput from './CommonStringInput';
import { IPGateRecord } from '../../../../../constants.js';

export default class ClientIPGate extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(IPGateRecord).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  onChange = (event) => {
    const value = event.target.value;
    const ip_list = fromJS(value.split(','));
    const newData = this.props.data.set('ip_list', ip_list);
    this.props.onChange(newData);
  }

  render() {
    const IpListToString = this.props.data.ip_list.join(',')
    return (
      <BooleanStep
        { ...this.props }
        labelText={ 'Client in IP List ' + IpListToString }
      >
        { this.props.children }
        <CommonStringInput
          label={ 'IPs' }
          value={ IpListToString }
          onChange={ (event) => this.onChange(event) }
        />
      </BooleanStep>
    );
  }
}
