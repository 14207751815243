import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';

import { Modal, Radio, message, Button } from '../../../../../styleguide';
import AddVendors from '../wizard/AddVendors';
import {
  getScannedVendors,
  getScannedVendorsV2,
  clearScannedVendors,
} from '../../../../../actions/vendor_list_actions_v2.js';
import { addVendorsToVl, addCookiesToVl } from '../wizard/helper';
import AddCookies from '../wizard/AddCookies';
import { ConfirmAddVendorsFooter } from '../wizard/ConfirmAddVendorsFooter';

const defaultValue = 'vendors';

function AddVendorsModal(props) {
  const {
    visible,
    onCancel,
    vl,
    allVendors,
    getVendorIdsForPurpose,
    scannedVendors,
    vendorWithProblems,
    pendingRequestsMap,
    saveVendorList,
    currentUser: { accountId, accountFeatures },
    filterElement,
    isNonIab
  } = props;

  const isVendorScanV2Enabled = accountFeatures.includes('vendor_scanning_v2');
  const isVendorScanV1Enabled = accountFeatures.includes('vendor_scanning');

  const [vendorList, updateVendorList] = useState(vl);
  const [selectedVendors, updateSelectedVendors] = useState([]);
  const [showAddCookies, setShowAddCookies] = useState(defaultValue);
  const [selectedCookies, updateSelectedCookies] = useState([])
  const [selectedCookiesToBeRemoved, updatedSelectedCookiesToBeRemoved] = useState([])
  const [addCookieToDisclosure, updateAddCookieToDisclosure] = useState(isVendorScanV2Enabled);
  const [isAddVendorsConfirmationVisible, setAddVendorsConfirmationVisibility] = useState(false);
  const [flow, setFlow] = useState({'callback':()=>{}})
  const addVendorsRef = useRef(null);
  const footerRef = useRef(null);

  const indexedSacnnedVendors = useMemo(() => {
    let indexVendors = scannedVendors.reduce(function (map, obj) {
      map[obj.id] = obj.vendorAdditionalDetails;
      return map;
    }, {});
    return indexVendors;
  }, [scannedVendors]);

  const checkVendorsNeedsToBeAdded = async () => {
    const checkIfVendorScanMapping = false;
    let updatedVendorList = vendorList;
    const vlVendorIds = vendorList.vendors.map(v => v.id).toJS();
    const allVendorsAdded = selectedVendors.length === 0 || selectedVendors.every(id => vlVendorIds.includes(id)) && vlVendorIds.every(id => selectedVendors.includes(id));
    const vendors = allVendors
    .filter((v) => selectedVendors.includes(v.id))
    .map((v) => {
      if (indexedSacnnedVendors[v.id]) {
        v = v.set("vendorAdditionalDetails",indexedSacnnedVendors[v.id])
      }
      return v;
    });

    if (!allVendorsAdded) {
      const args = {
        savedVl: vl,
        vendorList,
        getVendorIdsForPurpose,
        vendors,
        isVendorScanV2Enabled,
        addCookieToDisclosure,
        selectedVendors,
        scannedVendors,
        checkIfVendorScanMapping
      }
      updatedVendorList = await addVendorsToVl(args);
      updateVendorList(updatedVendorList);
    }
    return updatedVendorList;
  }

  // useEffect( ()=>{
  //   addCookiesToVl(selectedCookies,selectedCookiesToBeRemoved, vendorList, updateVendorList)
  // },[selectedCookies,selectedCookiesToBeRemoved])
  
  useEffect( () => {
    checkVendorsNeedsToBeAdded();
    // addCookiesToVl(selectedCookies,selectedCookiesToBeRemoved, vendorList, updateVendorList);
  },[selectedVendors]);

  useEffect(()=>{
    if(isAddVendorsConfirmationVisible) footerRef.current.scrollIntoView(true);   
  },[isAddVendorsConfirmationVisible]);

  const handleAddVendors = async (isCheckForSelectedVendors) => {
    if(isCheckForSelectedVendors && addVendorsRef.current.getCheckedVendorsToAdd().length) {
      setAddVendorsConfirmationVisibility(true);
      setFlow({'callback' : handleAddVendors})
      return;
    }
    let updatedVendorList = await checkVendorsNeedsToBeAdded();
    updatedVendorList = await addCookiesToVl(selectedCookies,selectedCookiesToBeRemoved, updatedVendorList, updateVendorList);
    saveVendorList({vl:updatedVendorList})
    onCancel();
  }

  const onShowCookies = (isCheckForSelectedVendors, value)  =>{
    if(value === 'cookies' && isCheckForSelectedVendors && addVendorsRef.current.getCheckedVendorsToAdd().length) {
      setAddVendorsConfirmationVisibility(true);
      setFlow({'callback' : onShowCookies})
      return;
    }
    setShowAddCookies(value);
  }
  useEffect(()=>{
    addCookiesToVl(selectedCookies,selectedCookiesToBeRemoved, vendorList, updateVendorList);
    updatedSelectedCookiesToBeRemoved([]);
  },[showAddCookies]);

  const cookiesViewSelection = (
    <React.Fragment>
      <Radio.Group value={showAddCookies} onChange={({target : {value}}) => onShowCookies(true, value)}>
        <Radio value="vendors">Vendors</Radio>
        <Radio value="cookies">Cookies</Radio>
      </Radio.Group>
    </React.Fragment>
  );

  const addVendors = (
    <AddVendors
      ref={addVendorsRef}
      selectedVendors={selectedVendors}
      updateSelectedVendors={updateSelectedVendors}
      isVendorScanV2Enabled={isVendorScanV2Enabled}
      isVendorScanV1Enabled={isVendorScanV1Enabled}
      isScanningVendors={pendingRequestsMap.get('scannedVendors')}
      scannedVendors={scannedVendors}
      indexedSacnnedVendors={indexedSacnnedVendors}
      vendorWithProblems={vendorWithProblems}
      getScannedVendorsV2={props.getScannedVendorsV2}
      getScannedVendors={props.getScannedVendors}
      accountId={accountId}
      savedVl={vl}
      globalVendors={allVendors}
      updateVendorList={updateVendorList}
      vendorList={vendorList}
      isNonIab={isNonIab}
      />
  );

  const addCookies = (
    <AddCookies
      selectedVendors={selectedVendors}
      globalVendors={allVendors}
      scannedVendors={scannedVendors}
      updateVendorList={updateVendorList}
      vendorList={vendorList}
      updateSelectedCookies={updateSelectedCookies}
      updatedSelectedCookiesToBeRemoved={updatedSelectedCookiesToBeRemoved}
      vl={vl}
      isNonIab={isNonIab}
  />
  )

  const component = showAddCookies === 'cookies' ? addCookies : addVendors;

  const handleConfirmAddVendors = (button) => {
    switch(button){
      case 'yes':
        addVendorsRef.current.addCheckedVendors();
        addVendorsRef.current.clearCheckedVendors();
        message.success(<>Vendors Added Successfully</>)
        setAddVendorsConfirmationVisibility(false);
        break;
      case 'no':
        addVendorsRef.current.clearCheckedVendors();
        setAddVendorsConfirmationVisibility(false);
        flow.callback(false, 'cookies')
        break;
      case 'cancel':
        setAddVendorsConfirmationVisibility(false);
        break;
    }
  }

  return (
    <Modal
      wrapClassName="vendor-list-wizard add-vendors-v2"
      title={`Manage Vendors & Cookies`}
      visible={visible}
      maskClosable={false}
      width="72%"
      onCancel={onCancel}
      footer={
        isAddVendorsConfirmationVisible ? (
          <div ref={footerRef}>
            <ConfirmAddVendorsFooter
              handleConfirmAddVendors={handleConfirmAddVendors}
            />
          </div>
        ) : (
          <div>
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => handleAddVendors(showAddCookies === 'vendors')}>
              Save
            </Button>
          </div>
        )
      }
    >
      {cookiesViewSelection}
      {component}
    </Modal>
  );
}

const mapStateToProps = function (store) {
  return {
    scannedVendors: store.vendorListState.getIn(['scannedVendors', 'value']),
    vendorWithProblems: store.vendorListState.getIn(['vendorWithProblems', 'value']),
    pendingRequestsMap: new Map({
      scannedVendors: store.vendorListState.getIn(['scannedVendors', 'pending']),
    }),
  };
};

export default connect(mapStateToProps, {
  getScannedVendorsV2,
  clearScannedVendors,
  getScannedVendors,
})(AddVendorsModal);
