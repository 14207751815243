import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import ReactDOM from 'react-dom';

import MessageSelector from '../../messages/MessageSelector.js.jsx';
import CustomIcon from '../../../common/CustomIcon.js.jsx';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { ShowMessageOnce } from '../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { Message } from '../../../../records/message_records';
import MessageDescription from './MessageDescription';


export default class extends React.Component {
    static propTypes = {
        editing: PropTypes.bool.isRequired,
        data: PropTypes.instanceOf(ShowMessageOnce).isRequired,
        onChange: PropTypes.func.isRequired,
        messages: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Message)).isRequired,
    };

    componentDidMount() {
        if (this.props.editing) ReactDOM.findDOMNode(this.refs.message).focus();
    }

    handleChange = (event) => {
        const data = this.props.data.set('message_id', (parseInt(event.target.value) || undefined));
        this.props.onChange(data);
    };

    render() {
        var messageID = this.props.data.message_id;

        const message = this.props.messages.find(message => message.get('id') === messageID);

        let previewIcon;
        if (message && message.getIn(['msg_metadata', 'type']) !== "redirect") {
            previewIcon = (
                <CustomIcon
                  type={ CustomIcon.types.PREVIEW }
                  onClick={ () => browserHistory.push('/dialogue/message_preview?message_id=' + messageID + '&site_id=' + message.site_id) }
                />
            )
        }

        if (!this.props.editing) {
            return (
                <div>
                  <span className="message-to-preview">Message: {
                    message ?
                      <MessageDescription message={ message } /> :
                      "UNKNOWN"
                    }
                  </span>
                  { previewIcon }
                </div>
            );
        } else {
            return (
                <div className="step message-show-once">
                  <label>Message:</label>
                  <MessageSelector ref="message" value={ message ? messageID : -1 } messages={ this.props.messages } onChange={ this.handleChange }/>
                </div>
            );
        }
    }
}
