import React from 'react';
import { Map, List } from 'immutable';
import { DefaultVendorCat } from '../../../records/vendor_list_records';
import { ExclamationCircleFilled } from '@ant-design/icons';

export const durationTypes = List(['session', 'day', 'hour', 'minute']);

export const vendorCategories = List([
  'Functionality',
  'Advertising/Targeting',
  'Performance',
  'Strictly Necessary',
  'Uncategorized',
]);

export const legalBasis = List([
  Map({
    label: 'User Consent',
    value: 'CONSENT',
    customClass: 'lb-consent',
  }),
  Map({
    label: 'Legitimate Interest',
    value: 'LEGITIMATE_INTEREST',
    customClass: 'lb-legitimate',
  }),
  Map({
    label: 'Not Applicable',
    value: 'NOT_APPLICABLE',
    customClass: 'lb-not-applicable',
  }),
  Map({
    label: 'Not Allowed',
    value: 'NOT_ALLOWED',
    customClass: 'lb-not-applicable',
  }),
]);

export const disclosureOnlyOptions = List([
  Map({
    label: 'Disclosure Only',
    value: 'DISCLOSURE_ONLY',
    customClass: 'lb-disclosure',
  }),
  Map({
    label: 'Not Applicable',
    value: 'NOT_APPLICABLE',
    customClass: 'lb-not-applicable',
  }),
]);


const extraLegalBasis = [
  {
    label: 'Disclosure Only',
    value: 'DISCLOSURE_ONLY',
    customClass: 'lb-disclosure',
  },
  {
    label: 'Not Allowed',
    value: 'NOT_ALLOWED',
    customClass: 'lb-not-allowed',
  }
];

const allLegalBasis = legalBasis.toJS().concat(extraLegalBasis);

export const legalBasisMap = allLegalBasis.reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {});

export function getCookieDuration(cookieLifeSpan) {
  let unit = 'session';
  let interval = '';
  if (cookieLifeSpan != null) {
    const duration = Number(cookieLifeSpan);
    let checkForMinutes = duration % 60;
    let checkForHours = duration % 3600;
    let checkForDays = duration % 86400;
    unit = 'day';
    if (checkForDays === 0) {
      interval = duration / 86400;
    } else if (checkForHours === 0) {
      interval = duration / 3600;
      unit = 'hour';
    } else if (checkForMinutes === 0) {
      interval = duration / 60;
      unit = 'minute';
    } else {
      interval = duration / 86400;
    }
  }
  return { unit, interval: interval != '' ? Number(interval.toFixed(2)) : '' };
}

export const renderCannotUpdateRegulationError = (currentRegulation, serverErrors = [], entity) => {
  let conflictReason = null;
  let conflictProperties = null;
  let conflictResolution = null;
  let note = null;
  if(currentRegulation?.siteIds?.length < 1) {
    conflictReason = <><b>CONFLICT</b>: No Property are present in the {entity}.</>
    conflictResolution = <><b>RESOLUTION:</b>&nbsp;Edit the {entity} and Add Properties in <b>{entity == 'Regulation' ? 'Name &' : null} Properties</b> section.</>
  } else {
    conflictReason = <><b>CONFLICT</b>: {serverErrors.reduce((accumulator, element) => { return accumulator + element.properties.length}, 0)} property(s) are already present in an Active {entity}.</>
    conflictProperties = <div className="sub-note-container">{serverErrors.map((err) => {
      return (
        <div className="regulation-site-error">
          <div>
            <span className="reg-title">{entity}:</span>
            <b>{err?.regulationName}</b>
          </div>
          <div>
            <span className="reg-title">Properties:</span>
            {err?.properties.join(", ")}
          </div>
        </div>
      );
    })}</div>;
    conflictResolution = <><b>RESOLUTION:</b>&nbsp;Remove the conflicting property from Active {entity}s or Deactivate the conflicting {entity}s to Activate this {entity}.</>
    note = `Note: Property can only be mapped to one Active ${entity} at a time.`;
  }

  return   (
    <React.Fragment>
      <div className="note"><b>{currentRegulation?.name} </b>cannot be activated due to the following reasons:</div>
      <div className="note-container">
      <div>{conflictReason}</div>
      {note ? <div className="sub-note"><ExclamationCircleFilled className="icon" />{note}</div> : null}
          {conflictProperties}
      <div className="bottom-note">{conflictResolution}</div>
      </div>
    </React.Fragment>
  );
}

const parseServerSideError = (data, indexSiteNameMap) => {
  let errorData = [];

  if (data.errorType === 'SITE_VALIDATION_FAILED') {
    data?.data?.map(vl => {
      let regulationName = vl?.get('name');
      let properties = vl.intersection.map(id => indexSiteNameMap[id]);
      if(List.isList(properties)) properties = properties.toJS()
      errorData.push({regulationName, properties})
    });
  } 
  return errorData;
};

export const handleServerSideErrors = (errors,indexSiteNameMap) => {
  let errorMessages = null;
  if(Array.isArray(errors) || List.isList(errors)){
    errorMessages =  errors?.map(err => {
      if (err.msg) {
        return err.msg;
      } else {
        return parseServerSideError(err, indexSiteNameMap);
      }
    });
    if(List.isList(errorMessages)) errorMessages = errorMessages.toJS();
    errorMessages = errorMessages.flat();
  }
  return errorMessages;
};
