import { Map, List } from 'immutable';

import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

const initialState = Map({
  propertyData: StoreRecord({ value: List([]) }),
  vendorsData: StoreRecord({ value: List([]) }),
  messageData: StoreRecord({ value: List([]) }),
  purposeData: StoreRecord({ value: List([]) }),
  changeLogData : StoreRecord({ value: List([]) }),
});

export default (state = initialState, action) => {
  switch(action.type) {
    case types.CONSENT_REPORT_PROPERTY_PENDING:
      return setPending(state, ['propertyData']);

    case types.CONSENT_REPORT_PROPERTY_LOADED:
        return setValue(state, ['propertyData'], action.data);

    case types.CONSENT_REPORT_PROPERTY_ERROR:
      return setError(state, ['propertyData'], action.errorMessage);

    case types.CONSENT_REPORT_PROPERTY_CACHED:
        return stopPending(state, ['propertyData']);

    case types.CONSENT_REPORT_VENDOR_PENDING:
      return setPending(state, ['vendorsData']);

    case types.CONSENT_REPORT_VENDOR_LOADED:
      return setValue(state, ['vendorsData'], List(action.data));

    case types.CONSENT_REPORT_VENDOR_ERROR:
      return setError(state, ['vendorsData'], action.errorMessage);

    case types.CONSENT_REPORT_VENDOR_CACHED:
      return setError(state, ['vendorsData']);

    case types.CONSENT_REPORT_MESSAGE_PENDING:
        return setPending(state, ['messageData']);
  
    case types.CONSENT_REPORT_MESSAGE_LOADED:
      return setValue(state, ['messageData'], action.data);

    case types.CONSENT_REPORT_MESSAGE_ERROR:
      return setError(state, ['messageData'], action.errorMessage);

    case types.CONSENT_REPORT_MESSAGE_CACHED:
      return setError(state, ['messageData']);

    case types.CONSENT_REPORT_PURPOSE_PENDING:
        return setPending(state, ['purposeData']);
  
    case types.CONSENT_REPORT_PURPOSE_LOADED:
      return setValue(state, ['purposeData'], action.data);

    case types.CONSENT_REPORT_PURPOSE_ERROR:
      return setError(state, ['purposeData'], action.errorMessage);

    case types.CONSENT_REPORT_PURPOSE_CACHED:
      return setError(state, ['purposeData']);

    case types.CONSENT_REPORT_CHANGELOG_PENDING:
        return setPending(state, ['changeLogData']);
  
    case types.CONSENT_REPORT_CHANGELOG_LOADED:
      return setValue(state, ['changeLogData'], action.data);

    case types.CONSENT_REPORT_CHANGELOG_ERROR:
      return setError(state, ['changeLogData'], action.errorMessage);

    case types.CONSENT_REPORT_CHANGELOG_CACHED:
      return setError(state, ['changeLogData']);
  }
  return state;
};