import React, { Component } from 'react';
import AceEditor from 'react-ace';
import { Input } from 'antd';
import 'brace/theme/monokai';
import 'brace/mode/javascript';

class CustomJS extends Component {
  changeValue = (value) => {
    const form = Object.assign({}, this.props.value.data);
    form['js_fn_name'] = value !== undefined ? value : null;
    this.props.changeValue(this.props.value.type, form);
  }

  render() {
    const { isApp } = this.props;
    const editor = isApp
      ? (
        <>
          <h5>Custom Action Value</h5>
          <Input
            value={this.props.value.data.js_fn_name}
            onChange={e => this.changeValue(e.target.value)}
          />
        </>
      )
      : (
        <>
          <h5>Custom Javascript</h5>
          <div style={{ width: '100%', padding: '0 5px' }}>
            <AceEditor
              ref={AceEditor => { this.customEditor = AceEditor; }}
              value={this.props.value.data.js_fn_name}
              mode='javascript'
              theme='monokai'
              readOnly={false}
              width='100%'
              height="200px"
              focus={true}
              onChange={this.changeValue}
            />
          </div>
        </>
      )
    return (
      <div className="four-options">
        {editor}
      </div>
    );
  }
}

export default CustomJS;