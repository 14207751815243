import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getVendorListsAjax,
  getIABPurposesAjax,
  getScannedVendorsAjax,
  getSitesEnabledForScanAjax,
  getVendorListAjax,
  getGlobalVendorsAjax,
  getGlobalVendorsHashAjax,
  createVendorListAjax,
  updateVendorListAjax,
  deleteVendorListAjax,
  cloneVendorListAjax,
} from '../api/ccpa/vendor_list';
import { getAllSitesAjax } from '../api/dialogue/sites';
import { createVendorListLog, editVendorListLog, deleteVendorListLog } from '../api/change_log';

export const getScannedVendors = actionCreator(
  getScannedVendorsAjax,
  scannedVendorsPending,
  scannedVendorsLoaded,
  vendorListsError,
);

export const getSitesEnabledForScan = actionCreator(
  getSitesEnabledForScanAjax,
  scannedVendorsPending,
  enabledSitesForScanLoaded,
  vendorListsError,
);

export const getVendorLists = actionCreator(
  getVendorListsAjax,
  vendorListsPending,
  vendorListsLoaded,
  vendorListsError,
);

export const getIABPurposes = actionCreator(
  getIABPurposesAjax,
  purposesPending,
  purposesLoaded,
  purposesError,
  vendorListsCached.bind(null, 'purposes'),
);

export const cloneVendorList = actionCreator(
  cloneVendorListAjax,
  vendorListsPending,
  vendorListCreated,
  vendorListsError
);

export function getVendorList(id, accountId) {
  return (dispatch, getState) => {

    dispatch(vendorListsPending());

    getVendorListAjax(id, accountId).then((vl) => {
      let vendorList = vl;
      // if (vendorList === 'fetched') {
      //   vendorList = getState().vendorListState.getIn(['vendorLists', 'value']).find(vl => vl.id === id);
      // }

      getAllSitesAjax().then((s) => {
        let sites = s;
        if (sites === 'fetched') {
          sites = getState().siteState.getIn(['sites', 'value']).filter(s => vendorList.siteIds.includes(s.id));
        }

        const sitesWithoutDeleted = vendorList.siteIds.filter(id => sites.map(s => s.id).includes(id));
        dispatch(vendorListLoaded(vendorList.set('siteIds', sitesWithoutDeleted)));
      });

    }).catch((resp) => dispatch(vendorListsError(resp)));
  };
}

export const getGlobalVendors = actionCreator(
  getGlobalVendorsAjax,
  globalVendorsPending,
  globalVendorsLoaded,
  vendorListsError,
  vendorListsCached.bind(null, 'globalVendors'),
);

export const getGlobalVendorsHash = actionCreator(
  getGlobalVendorsHashAjax,
  globalVendorsHashPending,
  globalVendorsHashLoaded,
  vendorListsError,
  vendorListsCached.bind(null, 'globalVendorsHash'),
);

export const createVendorList = actionCreator(
  createVendorListAjax,
  vendorListSavePending,
  vendorListCreated,
  vendorListsError,
  null,
  createVendorListLog
);

export const updateVendorList = actionCreator(
  updateVendorListAjax,
  vendorListSavePending,
  vendorListUpdated,
  vendorListsError,
  null,
  editVendorListLog
);

export const deleteVendorList = actionCreator(
  deleteVendorListAjax,
  vendorListsPending,
  vendorListDeleted,
  vendorListsError,
  null,
  deleteVendorListLog
);

function vendorListsPending() {
  return {
    type: types.VENDOR_LISTS_PENDING,
  };
}

function globalVendorsPending() {
  return {
    type: types.GLOBAL_VENDORS_PENDING,
  };
}

function globalVendorsLoaded(vendors) {
  return {
    type: types.GLOBAL_VENDORS_LOADED,
    vendors,
  };
}

function globalVendorsHashPending() {
  return {
    type: types.GLOBAL_VENDORS_HASH_PENDING,
  };
}

function globalVendorsHashLoaded(vendors) {
  return {
    type: types.GLOBAL_VENDORS_HASH_LOADED,
    vendors,
  };
}

function scannedVendorsPending() {
  return {
    type: types.SCANNED_VENDORS_PENDING,
  };
}

function enabledSitesForScanLoaded(siteIds) {
  return {
    type: types.ENABLED_SITES_FOR_SCAN_LOADED,
    siteIds,
  };
}

function scannedVendorsLoaded(vendors) {
  return {
    type: types.SCANNED_VENDORS_LOADED,
    vendors,
  };
}

export function clearScannedVendors() {
  return {
    type: types.CLEAR_SCANNED_VENDORS,
  };
}

function vendorListsLoaded(vendorLists) {
  return {
    type: types.VENDOR_LISTS_LOADED,
    vendorLists,
  };
}

function vendorListsError(error) {
  return {
    type: types.VENDOR_LISTS_ERROR,
    error,
  };
}

export function clearVendorListError() {
  return {
    type: types.CLEAR_VENDOR_LIST_ERROR,
  };
}

function vendorListCreated(vendorList) {
  return {
    type: types.VENDOR_LIST_CREATED,
    vendorList,
  };
}

function vendorListUpdated(vendorList) {
  return {
    type: types.VENDOR_LIST_UPDATED,
    vendorList,
  };
}

function vendorListDeleted(vendorList) {
  return {
    type: types.VENDOR_LIST_DELETED,
    id: vendorList.id,
  };
}

function vendorListSavePending() {
  return {
    type: types.VENDOR_LIST_SAVE_PENDING,
  };
}

function vendorListLoaded(vendorList) {
  return {
    type: types.VENDOR_LIST_LOADED,
    vendorList,
  };
}

function vendorListsCached(property) {
    return {
        type: types.VENDOR_LISTS_CACHED,
        property,
    }
}

function purposesPending() {
  return {
    type: types.PURPOSES_PENDING,
  };
}

function purposesLoaded(purposes) {
  return {
    type: types.PURPOSES_LOADED,
    purposes,
  };
}

function purposesError(errorMessage) {
  return {
    type: types.PURPOSES_ERROR,
    errorMessage,
  };
}
