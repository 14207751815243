import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router'

const NavbarItem = ({ className, active, href, text }) => {
  const classes = classNames(className, { active });
  return (
    <li className={classes}>
      <Link to={href}>
        {text}
      </Link>
    </li>
  );
}

NavbarItem.propTypes = {
  className: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
}

NavbarItem.defaultProps = {
  active: false,
}

export default NavbarItem;