import * as types from './action_types';
import {
  getZendeskJwtAjax
} from '../api/integrations';

import { actionCreator } from './helpers';

export const getZendeskJwt = actionCreator(
  getZendeskJwtAjax,
  () => ({ type: types.INTEGRATIONS_ZENDESK_PENDING }),
  (jwt) => ({ type: types.INTEGRATIONS_ZENDESK_LOADED, jwt }),
  (errorMessage) => ({ type: types.INTEGRATIONS_ZENDESK_ERROR, errorMessage }),
);