import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

import { Map, List } from 'immutable';

const initialState = Map({
  sites: StoreRecord({ value: List([]) }),
  sitesNameMap: StoreRecord({value: Map({})}),
  currentSite: new StoreRecord(),
  siteGroups: StoreRecord({ value: List([]) }),
  siteGroupSitesWithErrors: List([]),
})

export default (state = initialState, action) => {
  const siteGroups = state.getIn(['siteGroups', 'value']);
  let updatedSG;

  switch(action.type) {

    case types.SITES_PENDING:
      return setPending(state, ['sites']);

    case types.SITES_CACHED:
      return stopPending(state, ['sites']);

    case types.SITES_LOADED:
      const indexSiteNameMap =  
        action.sites.reduce(function (map, obj) {
          map[obj.id] = obj.domain;
          return map;
        }, {})
      state = setValue(state, ['sitesNameMap'], Map(indexSiteNameMap));
      state = setValue(state, ['sites'], List(action.sites).sortBy(site => site.domain.toLowerCase()));
      break;
    
    case types.SITE_ADDED:
      var sites = state.getIn(['sites', 'value']).push(action.site);
      var sortedSites = sites.sortBy(site => site.domain.toLowerCase());
      state = setValue(state, ['sitesNameMap'], state.getIn(['sitesNameMap', 'value'])?.set(String(action.site.id), action.site.domain));
      state = setValue(state, ['sites'], sortedSites);
      break; 

    case types.SITE_DELETED:
      var remainingSites = state.getIn(['sites', 'value'])
        .filterNot(site => site.id === action.id);
      state = setValue(state, ['sitesNameMap'], state.getIn(['sitesNameMap', 'value'])?.delete(String(action.id)));
      state = setValue(state, ['sites'], remainingSites);
      break;

    case types.SITES_ERROR:
      return setError(state, ['sites'], action.errorMessage);

    case types.SET_CURRENT_SITE:
      return setValue(state, ['currentSite'], action.id);

    case types.SITE_GROUPS_PENDING:
      return setPending(state, ['siteGroups']);

    case types.SITE_GROUPS_CACHED:
      return stopPending(state, ['siteGroups']);

    case types.SITE_GROUPS_LOADED:
      return setValue(state, ['siteGroups'], action.siteGroups.sortBy(sg => sg.name.toLowerCase()));

    case types.SITE_GROUP_CREATED:
      updatedSG = siteGroups.push(action.siteGroup).sortBy(sg => sg.name.toLowerCase());
      return setValue(state, ['siteGroups'], updatedSG);

    case types.SITE_GROUP_UPDATED:
    case types.SITE_GROUP_LOADED:
      updatedSG = siteGroups.filterNot(sg => sg.id === action.siteGroup.id).push(action.siteGroup).sortBy(sg => sg.name.toLowerCase());
      return setValue(state, ['siteGroups'], updatedSG);

    case types.SITE_GROUP_DELETED:
      updatedSG = siteGroups.filterNot(sg => sg.id === action.id);
      return setValue(state, ['siteGroups'], updatedSG);

    case types.SET_SITE_GROUP_SITES_WITH_ERRORS:
      return state.set('siteGroupSitesWithErrors', action.siteIds);

    case types.SITE_UPDATED:
      var sites = state.getIn(['sites', 'value']);
      var updatedSites = sites.filterNot(s => s.id === action.site.id).push(action.site).sortBy(s => s.domain.toLowerCase());
      var sortedSites = updatedSites.sortBy(site => site.domain.toLowerCase());
      state = setValue(state, ['sitesNameMap'], state.getIn(['sitesNameMap', 'value']).set(String(action.site.id), action.site.domain));
      state = setValue(state, ['sites'], sortedSites);
      break;

  }

  return state;
};
