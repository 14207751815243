import React from 'react'; 
import { SortableElement } from 'react-sortable-hoc';

import { CustomPanel } from './CustomPanel';
import Conditions from './Conditions';
import CollapsibleStep from './CollapsibleStep';

export const SortableStepElement = SortableElement((props) => {
  const {
    item,
    index,
    ind,
    updateActiveStep,
    setActiveKey,
    header,
    showConditionPanel,
    onChange,
    showConditionDropdown,
    showConditionDropdownState,
    activeStep,
    activeKeys,
    scenarioEdited,
    messages,
    onMessageListChange,
    renderConditionDropDownAndPanel,
    stepIndex,
    showErrorMessage,
    updateStepIndex,
    isTemplate,
    ...res
  } = props;
  return (
    <CustomPanel
      {...res}
      onPanelClick={(event) => updateActiveStep(ind, event)}
      onClick={(event) => setActiveKey(ind.toString(), event)}
      header={header}
      headerConditions={
        <Conditions
          step={item}
          index={ind}
          showConditionPanel={showConditionPanel}
          onChange={onChange}
          showConditionDropdown={showConditionDropdown}
          showConditionDropdownState={showConditionDropdownState}
          showErrorMessage={showErrorMessage}
          stepIndex={stepIndex}
          updateStepIndex={updateStepIndex}
          scenarioEdited={scenarioEdited}
          messages={messages}
          isTemplate={isTemplate}
        />
      }
      key={ind.toString()}
      index={ind}
      showArrow={false}
      activeStep={activeStep}
      collapseOpen={activeKeys.includes(ind.toString())}
      isActive={activeKeys.includes(ind.toString())}
      conditionPanel={renderConditionDropDownAndPanel(ind)}
      stepIndex={stepIndex}
    >
      <CollapsibleStep
        key={ind}
        step={item}
        index={ind}
        scenarioEdited={scenarioEdited}
        onChange={onChange}
        messages={messages}
        onMessageListChange={onMessageListChange}
        onChangeStep={(e) => updateActiveStep(ind, e)}
      />
    </CustomPanel>
  );
});
