import Immutable, { Record, List, Map } from "immutable";

export const DataRequestReport = Record({
  key: null,
  date: "",
  requestType: "",
  total: 0,
  totalRecieved: 0,
  totalrequest: 0,
  validrequest: 0,
  validrequestper: 0,
  invalidrequest: 0,
  invalidrequestper: 0,
  overdue: 0,
  overdueper: 0,
  withindue: 0,
  withindueper: 0,
  totaluploaded: 0,
  totalcompleted: 0,
  totaldelievered: 0,
  aggData: List()
});

export const DataRequestReportExport = Record({
  key: null,
  date: "",
  criteria: "",
  requestType: "",
  total: 0,
  totalrequest: 0,
  validrequest: 0,
  validrequestper: 0,
  invalidrequest: 0,
  invalidrequestper: 0,
  overdue: 0,
  overdueper: 0,
  withindue: 0,
  withindueper: 0
});

export const DataRequestReportAll = Record({
  key: null,
  date: "",
  totalRequest: 0,
  totalDSAR: 0,
  totalDNS: 0,
  totalDelete: 0,
  totalRecieved: 0,
  totalAll: 0,
  aggData: List(),
  exportData: List()
});
