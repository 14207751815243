import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { InfoCircleFilled, FilterFilled } from '@ant-design/icons';
import SlidingFilter from './SlidingFilter';

const CheckboxGroup = Checkbox.Group;
const plainOptions = [
  { label: 'Cookies', value: 'is_cookie_present' },
  { label: 'Persistent cookies', value: 'is_cookie_persistent' },
  { label: 'Local storage', value: 'is_local_storage' },
  { label: 'Fingerprinting', value: 'is_fingerprinting' },
];
export const allOptionsList = ['is_cookie_present', 'is_cookie_persistent', 'is_local_storage', 'is_fingerprinting'];

const VendorTraceTrackersFilter = ({ onChange, checkedList }) => {
  const indeterminate = useMemo(() => {
    return checkedList.length && !(checkedList.length === allOptionsList.length);
  }, [checkedList]);
  const checkAll = useMemo(() => {
    return checkedList.length === allOptionsList.length;
  }, [checkedList]);

  const onCheckAllChange = (e) => {
    onChange(e.target.checked ? allOptionsList : []);
  };

  return (
    <SlidingFilter className={'vendor-trace-non-disclosed-filter'} style={{ height: '162px' }} type="left">
      <div className={'vendor-trace-non-disclosed-filter-container'}>
        <div className={'title-row'}>
          <FilterFilled />
          <div>Tracking methods detected</div>
          <Tooltip
            overlayClassName="diagnose--tooltip vendor-trace-prevalence-filter-tooltip-overlay"
            title={() => {
              return (
                <div className={'tooltip-content'}>
                  <div>
                    This feature allows you to prioritize the review of high-risk vendors that store identifiers through
                    cookies or local storage or utilize fingerprinting techniques
                  </div>
                </div>
              );
            }}
          >
            <InfoCircleFilled />
          </Tooltip>
        </div>
        <div className={'filter-block'}>
          <div className={'checkbox-list'}>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              All
            </Checkbox>
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
          </div>
        </div>
      </div>
    </SlidingFilter>
  );
};

export default VendorTraceTrackersFilter;
