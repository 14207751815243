import React from 'react';
import BooleanStep from './BooleanStep';

export default class SambaGate extends React.Component {
  render() {
    return (
      <BooleanStep
        {...this.props}
        labelText={ 'Samba' }
      />
    );
  }
}
