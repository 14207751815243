import React, { useEffect, useMemo, useState } from "react";
import { Divider } from "antd";
import { Chip, Button, Tree, Popconfirm } from "../../styleguide";
import _ from "lodash";

const EntitySelector = (props) => {
  const [ selectedKeys, setSelectedKeys ] = useState([]);
  const [ selectedCodes, setSelectedCodes ] = useState([]);
  const [ viewCount, setViewCount ] = useState(10);

  const region_title_index_map = useMemo(() => props.options.reduce((result, item) => {result[item.code] = item.title;return result;}, {}),[]);
  const region_key_index_map = useMemo(() => props.options.reduce((result, item) => {result[item.code] = item.key;return result;}, {}),[]);

  useEffect(()=>{
    let selKeys = selectedCodes.map(code => region_key_index_map[code]);
    setSelectedKeys(selKeys);
    props.updatedCodes(selectedCodes)
  },[selectedCodes]);

  useEffect(()=>{
    setSelectedCodes(props.codes);
  },[])


  const handleViewMore = () => {
    setViewCount(1000);
  };
  const handleViewLess = () => {
    setViewCount(10);
  };
  const removeRegion = (removeCode) => {
    setSelectedCodes(selectedCodes.filter(code => removeCode !== code))
  };

  const renderSelectedTerritories = () => {
    let chips = selectedCodes.map( code => (
      <Chip closable onClose={(e) => removeRegion(code)}>
        {region_title_index_map[code]}
      </Chip>
    ))
    let viewMoreButton = null;
    const l = chips.length;
    if(l > 10) {
      viewMoreButton = viewCount > 10 ? 
      <Button onClick={() => handleViewLess()} type="primary">-</Button> :
      <Button onClick={() => handleViewMore()} type="primary">+{l - 10}</Button>
    }
    chips = chips.slice(0, viewCount)

    return chips.length ? (
      <div className="region-group">
        <div className="group-regions">
          {chips}
          {viewMoreButton}
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      <div className={`regions-section`}>
        <div className="regions-selection">
          <Tree
            treeOptions={props.options}
            selectedKeys={selectedKeys}
            selectedCodes={selectedCodes}
            setSelectedCodes={setSelectedCodes}
            isNested={false}
            all={"Select All " + props.entity}
            rootClass="do-not-indent"
          />
        </div>
        <div className="selected-regions">
          <div className="heading">
            <b>Selected</b>
            {selectedCodes.length ? (
              <Popconfirm
                overlayClassName="exit-wizard-confirmation"
                title={
                  <div>
                    Are you sure you want to remove all selected {props.entity}?
                  </div>
                }
                placement="topRight"
                trigger="click"
                icon={null}
                onConfirm={() => setSelectedCodes([])}
                okText="Yes"
                cancelText="No"
              >
                <b className="clear-all"> Clear All X</b>
              </Popconfirm>
            ) : null}
          </div>
          <Divider />
          <div
            className={`selected-regions-container ${
              selectedCodes.length ? "" : "centered"
            }`}
          >
            {selectedCodes.length ? (
                renderSelectedTerritories()
            ) : (
              <div>Selected {props.entity} will appear here.</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(EntitySelector, (prevProps, nextProps) => _.isEqual(prevProps.codes, nextProps.codes))
