import React from 'react';
import { Dropdown, Menu, Progress } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment';
import classNames from 'classnames';

const PropertyCard = ({ set, editDomainSet, activateDomainSet, deactivateDomainSet, deleteDomainSet, showCards, accountId, type, limited, accuracy, contractEndDate, readOnly, active }) => {

  const editMenu = (
    <Menu className="buttons-group" style={{ zIndex: 100 }}>
      <Menu.Item className='menu-item-set'
        onClick={() => editDomainSet(set)}>
        {readOnly ? 'View' : 'Edit'}
      </Menu.Item>
      { set.domainSetStatus === 'Active' && !readOnly ? 
        <Menu.Item className='menu-item-set'
          onClick={() => deactivateDomainSet(set)}>
          Deactivate
        </Menu.Item>
        : null
      }
      { !readOnly ? 
        <Menu.Item className='menu-item-set'
          onClick={() => deleteDomainSet(set._id)}>
          Delete
        </Menu.Item>
        : null
      }
    </Menu>
  );

  const copyIcon = (
    <img src="/images/copy-icon.svg"
      alt="copy"
      onClick={() => copyStandard(set)}
      style={{ float: 'right', transform: 'scale(0.8)', cursor: 'pointer' }}
    />
  );

  const getActiveStatus = () => {
    // if (set.name === 'test2')debugger
    let classString;
    let textString;
    if (set.domainSetStatus === 'Active') {
      textString = 'Active';
      classString = 'tag green'
    } else if (set.domainSetStatus === 'Disabled') {
      // if (set.deactivateDate) {
      //   textString = `Deactivated on ${deactivateDate.format('MMM DD, YYYY')}`;
      //   classString = 'tag gray'
      // }
    } else {
      const PERIOD = 30; 
      const todayDate = moment();
      const acviteDays = todayDate.diff(moment(set.activateDate), 'days');
      const daysBeforePeriodEnds = PERIOD - (acviteDays % PERIOD);
      textString = `Deactivating in ${daysBeforePeriodEnds} days`;
      classString = 'tag red';
    }

    return { classString, textString };
  };

  return (
    <div className={"property-card"}
      style={{
        display: showCards ? 'block' : 'none', border: '1px solid #D4D4D4'
      }} >
      <div className="property-card-header"
        style={{ background: '#E9DFFF', color: '#2D2D2D' }}
      >
        <div className="property-card-title" title={set.name}>{set.name}</div>
        <div className='flex-row'>
          { set.domainSetStatus !== 'Disabled' || readOnly ? 
            null: 
            <div onClick={() => activateDomainSet(set)} className='activate-button'>Activate</div>
          }
          {limited ? null : type === 'account' ? (
          <Dropdown
            overlay={editMenu}
            className='set-menu-container'
          >
            <EllipsisOutlined
              style={{
                float: 'right',
                transform: 'rotate(90deg)'
              }} />
          </Dropdown>) : copyIcon
          }
        </div>
      </div>
      <div className="property-card-body" onClick={() => editDomainSet(set)}>
        <div className='flex-row'>
          <div className="properties-number">Standard : <span title={limited ? '' : set.standard ? set.standard.name : set.standards[0].name}>{limited ? '' : set.standard ? set.standard.name : set.standards[0].name}</span></div>
          <div className="properties-number">No of Properties : <span>{set.standard ? set.numProperties : set.properties.length}</span></div>
        </div>
        <div className="property-dates">
          <div
            className="date-first-column">
            <img src={require('../../../assets/images/privacy-lens/calendar.svg')} alt="calendar" className="img-calendar" />
            <span>Created Date</span> <div className="date">{set.dateCreated ? moment(set.dateCreated).format('MMM DD, YYYY') : 'N/A'}</div>
          </div>
          <div className="date-second-column"><img src={require('../../../assets/images/privacy-lens/calendar.svg')} alt="calendar" className="img-calendar" /><span>Last Edited Date </span><span className="date">{set.dateUpdated ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' }).format(new Date(set.dateUpdated)) : '-'}</span></div>
        </div>
        <div className={ getActiveStatus().classString}>
          {getActiveStatus().textString}
        </div>
        <div className={classNames('percent-properties-scanned', { inactive: !active })}>
          <p>% of properties scanned in current period</p>
          <Progress percent={parseInt((set.numPropertiesScanned / set.numProperties) * 100)} />
        </div>
      </div>
    </div>
  )
}

export default PropertyCard;
